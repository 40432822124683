import { gql, useApolloClient } from '@apollo/client';
import {
  GetUsersQuery,
  GetUsersQueryVariables,
} from '../../__generated__/queries-auth';

export const GET_USERS = gql`
  query GetUsers(
    $first: UnsignedInt = 1000
    $skip: UnsignedInt = 0
    $where: UserFilterInput
  ) {
    users(first: $first, skip: $skip, where: $where) {
      id
      name
      createdAt
      email
      roles
      googleId
    }
    userCount(where: $where)
  }
`;

export const useUsersGetter = () => {
  const client = useApolloClient();

  return async (name: string) => {
    const { data, errors } = await client.query<
      GetUsersQuery,
      GetUsersQueryVariables
    >({
      query: GET_USERS,
      variables: { where: { name_contains: name } },
    });

    if (errors?.length) {
      throw errors[0];
    }
    return data?.users;
  };
};
