import { Box, SxProps, Typography } from '@mui/material';
import { ImageLoader } from '@prismamedia/one-components';
import { FC, useEffect, useState } from 'react';
import { theme } from 'theme';
import { getRailroadImage } from '../utils';
import { RailroadStatusBar } from './RailroadStatusBar';
import { getColorFromDecimal, getWorkFlow, HalfPage } from './utils';

export const RailroadIssueHalfPage: FC<{
  page: HalfPage | null;
  previousPage: HalfPage | null;
  nextPage: HalfPage | null;
  sx?: SxProps;
  pageWidth: number;
  withInfos?: boolean;
}> = ({ page, previousPage, nextPage, sx, pageWidth, withInfos }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (!page?.filename) return;
    getRailroadImage(page.filename)
      .then((res) => {
        const urlCreator = window.URL || window.webkitURL;
        const src = urlCreator.createObjectURL(res);
        setImageSrc(src);
      })
      .catch(() => {});
  }, [page?.filename]);

  const pageIsEven = Number(page?.pageNumber || 0) % 2 === 0;

  const workflow = getWorkFlow(page);

  if (!page) return null;

  return (
    <Box>
      {withInfos && (
        <>
          <RailroadStatusBar
            value={page.sheet?.id}
            previousValue={previousPage?.sheet?.id}
            nextValue={nextPage?.sheet?.id}
            pageNumber={page.pageNumber}
            label={page.sheet?.name}
            sx={{ mb: 0.5 }}
            color={getColorFromDecimal(page.sheet?.color)}
          />
          <RailroadStatusBar
            value={page.indesignFilename}
            previousValue={previousPage?.indesignFilename}
            nextValue={nextPage?.indesignFilename}
            pageNumber={page.pageNumber}
            label={page.indesignFilename}
            sx={{ mb: 1 }}
            color={page.indesignFilename ? theme.palette.grey[400] : undefined}
          />
          <Typography
            sx={{
              width: '100%',
              textAlign: pageIsEven ? 'left' : 'right',
              fontSize: 10,
              height: 15,
            }}
          >
            {workflow?.label_workflowStep}
          </Typography>
        </>
      )}
      <Box
        sx={{
          border: `${pageWidth * 0.02}px solid ${
            getColorFromDecimal(Number(workflow?.workflowStepColor)) ||
            theme.palette.common.black
          }`,
          display: 'flex',
          ...sx,
        }}
      >
        <ImageLoader
          src={imageSrc}
          loaderSize={20}
          sx={{ height: 'auto !important' }}
        />
      </Box>
      <Typography
        sx={{
          width: '100%',
          textAlign: pageIsEven ? 'left' : 'right',
          fontSize: 10,
        }}
      >
        {page.pageNumber}
      </Typography>
    </Box>
  );
};
