import { Typography } from '@mui/material';
import { SimpleSelect } from '@prismamedia/one-components';
import { useGetUnits } from 'apollo/queries/units.topic.graphql';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
  useGetPrintIssues,
  useGetPrintPublications,
} from './issuePage.topic.graphql';

export const IssueSelector: FC<{
  selectedIssue: string | undefined;
  setSelectedIssue: Dispatch<SetStateAction<string | undefined>>;
}> = ({ selectedIssue, setSelectedIssue }) => {
  const [selectedUnit, setSelectedUnit] = useState<string | undefined>(
    undefined,
  );
  const [selectedPrintPublication, setSelectedPrintPublication] = useState<
    string | undefined
  >(undefined);

  const { data: unitsData, error: unitError } = useGetUnits();

  const {
    data: printPublicationsData,
    error: printPublicationsError,
  } = useGetPrintPublications({
    first: 1000,
    where: { brand: { unit: { id: selectedUnit } } },
  });

  const { data: printIssuesData, error: printIssuesError } = useGetPrintIssues(
    {
      first: 1000,
      where: { printPublication: { id: selectedPrintPublication } },
    },
    !selectedPrintPublication,
  );

  const error = printIssuesError || printPublicationsError || unitError;

  useEffect(() => {
    if (!selectedIssue || !printPublicationsData) return;
    setSelectedPrintPublication(
      printPublicationsData.printPublications.find((printPublication) =>
        printPublication.printIssues
          .map(({ id }) => id)
          .includes(selectedIssue),
      )?.id,
    );
  }, [printPublicationsData, selectedIssue]);

  if (error) {
    return <Typography color="error">{error.message}</Typography>;
  }

  return (
    <>
      <SimpleSelect
        label="Pôle"
        size="small"
        value={selectedUnit || ''}
        sx={{ minWidth: 150 }}
        onChange={(_, unit) => {
          setSelectedUnit(unit || undefined);
          setSelectedPrintPublication(undefined);
          setSelectedIssue(undefined);
        }}
        options={[
          { value: '', label: '-' },
          ...(unitsData?.units || []).map(({ id, title }) => ({
            value: id,
            label: title,
          })),
        ]}
      />
      <SimpleSelect
        label="Magazine"
        size="small"
        value={selectedPrintPublication || ''}
        sx={{ minWidth: 150, ml: 2 }}
        onChange={(_, printPublication) => {
          setSelectedPrintPublication(printPublication || undefined);
          setSelectedIssue(undefined);
        }}
        options={[
          { value: '', label: '-' },
          ...(printPublicationsData?.printPublications || []).map(
            ({ id, title }) => ({
              value: id,
              label: title,
            }),
          ),
        ]}
      />
      <SimpleSelect
        label="Parution"
        size="small"
        disabled={!selectedPrintPublication}
        value={selectedIssue || ''}
        sx={{ minWidth: 150, ml: 2 }}
        onChange={(_, printIssue) => {
          setSelectedIssue(printIssue || undefined);
        }}
        options={[
          { value: '', label: '-' },
          // eslint-disable-next-line fp/no-mutating-methods
          ...[...(printIssuesData?.printIssues || [])]
            .sort((issueA, issueB) => {
              return issueB.title.localeCompare(issueA.title, undefined, {
                numeric: true,
                sensitivity: 'base',
              });
            })
            .map(({ id, title }) => ({
              value: id,
              label: title,
            })),
        ]}
      />
    </>
  );
};
