import { Box, SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { theme } from 'theme';
import { backgroundIsLight, MARGIN_BETWEEN_TWO_PAGES } from './utils';

const GAP_BETWEEN_DIFFERENT_VALUES = 0.2;

export const RailroadStatusBar: FC<{
  value: string | undefined | null;
  previousValue: string | undefined | null;
  nextValue: string | undefined | null;
  label: string | undefined | null;
  pageNumber: string;
  sx?: SxProps;
  color?: string;
}> = ({ value, previousValue, nextValue, pageNumber, color, sx, label }) => {
  const pageIsEven = Number(pageNumber || 0) % 2 === 0;

  const extendsToPreviousPage = pageIsEven && value && value === previousValue;
  const extendsToNextPage = !pageIsEven && value && value === nextValue;

  const gapBeforePreviousPage =
    !pageIsEven && previousValue && value && previousValue !== value;
  const gapBeforeNextPage =
    pageIsEven && nextValue && value && nextValue !== value;

  return (
    <Box
      sx={{
        width: `calc(100% + ${
          extendsToPreviousPage
            ? theme.spacing(MARGIN_BETWEEN_TWO_PAGES / 2)
            : 0
        }px + ${
          extendsToNextPage ? theme.spacing(MARGIN_BETWEEN_TWO_PAGES / 2) : 0
        }px) - ${
          gapBeforePreviousPage || gapBeforeNextPage
            ? theme.spacing(GAP_BETWEEN_DIFFERENT_VALUES)
            : 0
        }px)`,
        height: 15,
        bgcolor: color,
        ml: extendsToPreviousPage
          ? -1
          : gapBeforePreviousPage
          ? GAP_BETWEEN_DIFFERENT_VALUES
          : undefined,
        mr: extendsToNextPage
          ? -1
          : gapBeforeNextPage
          ? GAP_BETWEEN_DIFFERENT_VALUES
          : undefined,
        display: 'flex',
        alignItems: 'center',
        px: 1,
        ...sx,
      }}
    >
      {(value !== previousValue || value !== nextValue) && (
        <Typography
          sx={{
            width: '100%',
            fontSize: 10,
            color:
              color && backgroundIsLight(color)
                ? theme.palette.common.black
                : theme.palette.common.white,
            textAlign:
              value !== nextValue
                ? value !== previousValue
                  ? 'center'
                  : 'right'
                : undefined,
          }}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};
