import { ImageGalleryItem } from '@prismamedia/one-components';
import { SearchPhotosPhoto } from 'apollo/queries/photos.photo.graphql';
import React, { FC, MouseEventHandler } from 'react';
import { getPreview } from 'utils/getPreview';
import { GalleryItemInfos } from '../GalleryItemInfos';

interface PhotoItemProps {
  photo: Pick<
    SearchPhotosPhoto,
    'metadataByName' | 'updatedAt' | 'previews' | 'publicUrl'
  >;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

export const PhotoItem: FC<PhotoItemProps> = ({
  photo,
  onClick,
  className,
}) => {
  return (
    <ImageGalleryItem
      className={className}
      src={getPreview(photo)}
      fallbacks={[{ src: photo.publicUrl }]}
      infos={<GalleryItemInfos photo={photo} />}
      onClick={onClick}
    />
  );
};
