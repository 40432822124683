import { auth } from './auth';

/*
  explicit private endpoints that do require authorization token
*/
const authedEndpoinds = [
  config.API_ONE_AUTH,
  config.API_ONE_IFRAMELY,
  config.API_ONE_IMAGE,
  config.API_ONE_PHOTO,
  config.API_ONE_TOPIC,
];

/*
  explicit public endpoints that do not require authorization token
*/
const unauthedEndpoints = [`${config.API_ONE_AUTH}/login`];

export async function fetchWithAuth(
  reqInfo: RequestInfo,
  reqInit?: RequestInit,
) {
  const targetEndpoint =
    typeof reqInfo === 'string'
      ? reqInfo
      : reqInfo instanceof URL
      ? reqInfo.href
      : reqInfo.url;

  const authed = authedEndpoinds.some((p) =>
    targetEndpoint.toLowerCase().startsWith(p.toLowerCase()),
  );
  const unauthed = unauthedEndpoints.some((p) =>
    targetEndpoint.toLowerCase().startsWith(p.toLowerCase()),
  );

  if (authed && !unauthed) {
    const token = await auth.getJwt();
    if (token) {
      const req =
        typeof reqInfo === 'string'
          ? new Request(reqInfo, { ...reqInit, mode: 'cors' })
          : reqInfo instanceof URL
          ? new Request(reqInfo.href, { ...reqInit, mode: 'cors' })
          : reqInfo;

      req.headers.set('Authorization', `Bearer ${token}`);

      return fetch(req);
    }
  }

  return fetch(reqInfo, reqInit);
}
