import { SxProps } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import { RailroadIssueHalfPage } from './RailroadIssueHalfPage';
import { HalfPage } from './utils';

export const RailroadIssueFullPage: FC<{
  pages: (HalfPage | null)[];
  doublePage: (HalfPage | null)[];
  pageWidth: number;
  paddingFirstPage?: boolean;
  withInfos?: boolean;
  sx?: SxProps;
}> = ({ pages, doublePage, sx, pageWidth, paddingFirstPage, withInfos }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        pl:
          paddingFirstPage && Number(doublePage[0]?.pageNumber || 0) === 1
            ? `${pageWidth}px`
            : undefined,
        ...sx,
      }}
    >
      {doublePage.map((page, i) => (
        <RailroadIssueHalfPage
          withInfos={withInfos}
          key={i}
          page={page}
          previousPage={page && pages[Number(page.pageNumber) - 2]}
          nextPage={page && pages[Number(page.pageNumber)]}
          pageWidth={pageWidth}
          sx={{
            width: pageWidth,
            minHeight: pageWidth * 1.1,
          }}
        />
      ))}
    </Box>
  );
};
