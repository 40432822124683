import { Paper } from '@mui/material';
import { FC } from 'react';
import { RailroadIssueFullPage } from './Railroad/RailroadIssueFullPage';
import { HalfPage } from './Railroad/utils';
import { useGetRailRoadPages } from './utils';

export const Railroad: FC<{
  selectedIssue: string;
}> = ({ selectedIssue }) => {
  const { pages } = useGetRailRoadPages(selectedIssue);

  if (!pages?.length) {
    return null;
  }

  const doublePages = pages?.reduce<(HalfPage | null)[][]>((prev, card) => {
    const lastBatch = prev[prev.length - 1];
    if (!lastBatch || lastBatch.length === 2 || prev.length === 1) {
      return [...prev, [card]];
    }
    // eslint-disable-next-line immutable/no-mutation
    prev[prev.length - 1] = [...lastBatch, card];
    return prev;
  }, []);

  return (
    <Paper sx={{ display: 'flex', overflowX: 'auto', p: 2, m: 2, mb: 0 }}>
      {doublePages?.map((doublePage, i) => (
        <RailroadIssueFullPage
          pages={pages}
          key={i}
          doublePage={doublePage}
          pageWidth={100}
          sx={{ mr: 2 }}
        />
      ))}
    </Paper>
  );
};
