import { LoginPage, UserDialog } from '@prismamedia/one-components';
import { ArticleAutoHistory } from 'pages/ArticleAutoHistory';
import { IssuePage } from 'pages/IssuePage';
import { RailroadPage } from 'pages/RailroadPage';
import { ComponentType, FC } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  Switch,
} from 'react-router-dom';
import { useUsersGetter } from './apollo/queries/users.auth.graphql';
import { ArticleAutoWrapper } from './pages/ArticleAuto';
import { CreateArticle, EditArticle } from './pages/ArticleForm';
import { ArticleHistory } from './pages/ArticleHistory';
import { LocationPage } from './pages/LocationPage';
import { MultiSubjectAutoWrapper } from './pages/MultiSubjectAuto';
import { PrintTemplate } from './pages/PrintTemplate';
import { CreateSubject, EditSubject } from './pages/SubjectForm';
import { SubjectListPage } from './pages/SubjectListPage';
import { SubjectPhotos } from './pages/SubjectPhotos';
import { auth } from './utils/auth';
import { useLocationChange } from './utils/useLocationChange';

export const replaceParams = (
  path: string,
  params: Record<string, string | undefined>,
): string => {
  const keys = Object.entries(params);
  let newPath = path;
  keys.forEach(([left, right = '']) => {
    newPath = newPath.replace(`:${left}`, right);
  });
  return newPath.replace(/\(.*\)/, '');
};

export enum paths {
  SUBJECT_LIST = '/subject/list',
  SUBJECT_ADD = '/subject/add',
  SUBJECT_EDIT = '/subject/edit/:id',
  ISSUE = '/issue',
  RAILROAD = '/railroad',
  LOCATION = '/location',
  SUBJECT_ASSETS = '/subject/edit/:id/assets',
  LOGIN = '/login',
  ARTICLE_ADD = '/article/add/:assignmentId',
  ARTICLE_EDIT = '/article/edit/:id',
  ARTICLE_AUTO_ADD = '/article/auto/add/:assignmentId',
  ARTICLE_AUTO_EDIT = '/article/auto/edit/:id',
  ARTICLE_AUTO_HISTORY = '/article/auto/history/:idRawArticle',
  MULTI_SUBJECT_AUTO_EDIT = '/multisubject/auto/edit/:printHeadingId/:originId',
  ARTICLE_HISTORY = '/article/history/:idRawArticle',
  PRINT_TEMPLATE = '/location/:printHeadingId',
}

interface PrivateRouteProps extends RouteProps {
  component: ComponentType<RouteComponentProps>;
}

const PrivateRoute = ({ component: Component, ...rest }: PrivateRouteProps) => {
  const getUsers = useUsersGetter();

  return (
    <Route
      {...rest}
      render={(props) =>
        auth.isAuthenticated() ? (
          <>
            <Component {...props} />
            <UserDialog auth={auth} searchUsers={getUsers} />
          </>
        ) : (
          <Redirect to={{ pathname: paths.LOGIN }} />
        )
      }
    />
  );
};

export const Routing: FC = () => {
  useLocationChange();

  return (
    <Switch>
      <Route
        exact
        path={paths.LOGIN}
        render={(props) => (
          <LoginPage
            {...props}
            homePath={paths.SUBJECT_LIST}
            loginPath={paths.LOGIN}
            auth={auth}
          />
        )}
      />
      <PrivateRoute
        exact
        path={paths.SUBJECT_LIST}
        component={SubjectListPage}
      />
      <PrivateRoute exact path={paths.ISSUE} component={IssuePage} />
      <PrivateRoute exact path={paths.RAILROAD} component={RailroadPage} />
      <PrivateRoute exact path={paths.SUBJECT_EDIT} component={EditSubject} />
      <PrivateRoute exact path={paths.SUBJECT_ADD} component={CreateSubject} />
      <PrivateRoute exact path={paths.ARTICLE_ADD} component={CreateArticle} />
      <PrivateRoute exact path={paths.ARTICLE_EDIT} component={EditArticle} />
      <PrivateRoute
        exact
        path={paths.ARTICLE_AUTO_ADD}
        component={ArticleAutoWrapper}
      />
      <PrivateRoute
        exact
        path={paths.ARTICLE_AUTO_EDIT}
        component={ArticleAutoWrapper}
      />
      <PrivateRoute
        exact
        path={paths.ARTICLE_AUTO_HISTORY}
        component={ArticleAutoHistory}
      />
      <PrivateRoute
        exact
        path={paths.MULTI_SUBJECT_AUTO_EDIT}
        component={MultiSubjectAutoWrapper}
      />
      <PrivateRoute
        exact
        path={paths.ARTICLE_HISTORY}
        component={ArticleHistory}
      />
      <PrivateRoute exact path={paths.LOCATION} component={LocationPage} />
      <PrivateRoute
        exact
        path={paths.PRINT_TEMPLATE}
        component={PrintTemplate}
      />
      <PrivateRoute
        exact
        path={paths.SUBJECT_ASSETS}
        component={SubjectPhotos}
      />
      <PrivateRoute component={() => <Redirect to={paths.SUBJECT_LIST} />} />
    </Switch>
  );
};
