import { MenuBook } from '@mui/icons-material';
import { AppBar, Box, Typography } from '@mui/material';
import {
  HomeAppBar,
  PageWithDrawer,
  useAppBarHeight,
  useOnMount,
} from '@prismamedia/one-components';
import { OneDrawerMenu } from 'components/OneDrawerMenu';
import { RailroadIssueFullPage } from 'pages/IssuePage/Railroad/RailroadIssueFullPage';
import {
  HalfPage,
  MARGIN_BETWEEN_TWO_PAGES,
} from 'pages/IssuePage/Railroad/utils';
import { useGetRailRoadPages } from 'pages/IssuePage/utils';
import { FC, useEffect, useState } from 'react';
import { theme } from 'theme';
import { StringParam, useQueryParam } from 'use-query-params';
import { auth } from 'utils/auth';
import { FILTER_BAR_HEIGHT, FilterBar } from './FilterBar';

export const RailroadPage: FC = () => {
  const appBarHeight = useAppBarHeight();
  const [selectedIssue, setSelectedIssue] = useState<string | undefined>(
    undefined,
  );
  const [selectedIssueUrl, setSelectedIssueUrl] = useQueryParam(
    'issue',
    StringParam,
  );
  const [loadingUrlParams, setLoadingUrlParams] = useState(true);

  const { pages, loading, error } = useGetRailRoadPages(selectedIssue);

  useOnMount(() => {
    selectedIssueUrl && setSelectedIssue(selectedIssueUrl);
    setTimeout(() => setLoadingUrlParams(false));
  });

  useEffect(() => {
    setSelectedIssueUrl(selectedIssue, 'replaceIn');
  }, [selectedIssue, setSelectedIssueUrl]);

  const doublePages = pages?.reduce<(HalfPage | null)[][]>((prev, card) => {
    const lastBatch = prev[prev.length - 1];
    if (!lastBatch || lastBatch.length === 2 || prev.length === 1) {
      return [...prev, [card]];
    }
    // eslint-disable-next-line immutable/no-mutation
    prev[prev.length - 1] = [...lastBatch, card];
    return prev;
  }, []);

  return (
    <>
      <HomeAppBar
        appDrawerMenu={<OneDrawerMenu />}
        brandMenu={<Typography variant="h6">Chemin de fer</Typography>}
        currentUser={auth.user}
        disconnectUser={auth.logout}
      />
      <AppBar position="fixed" color="default" sx={{ top: appBarHeight }}>
        <FilterBar
          selectedIssue={selectedIssue}
          setSelectedIssue={setSelectedIssue}
        />
      </AppBar>
      <PageWithDrawer
        fullWidth
        sx={{ minHeight: '100%' }}
        paddingTop={FILTER_BAR_HEIGHT}
        error={error}
        loading={loading}
      >
        {selectedIssue ? (
          !loadingUrlParams &&
          (pages?.length ? (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                alignSelf: 'center',
                pt: 2,
                pl: 2,
              }}
            >
              {doublePages?.map((doublePage, i) => (
                <RailroadIssueFullPage
                  paddingFirstPage
                  withInfos
                  key={i}
                  pages={pages}
                  doublePage={doublePage}
                  pageWidth={200}
                  sx={{ mr: MARGIN_BETWEEN_TWO_PAGES, mb: 2 }}
                />
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: theme.palette.primary.main,
              }}
            >
              <Box sx={{ fontSize: 25 }}>
                Aucun chemin de fer pour cette parution
              </Box>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              color: theme.palette.primary.main,
            }}
          >
            <MenuBook sx={{ fontSize: 40, mb: 2 }} />
            <Box sx={{ fontSize: 25 }}>
              Sélectionnez un magazine et une parution
            </Box>
          </Box>
        )}
      </PageWithDrawer>
    </>
  );
};
