import { Article } from '@mui/icons-material';
import { Box, ButtonBase, Paper, Typography } from '@mui/material';
import { InfiniteTable } from '@prismamedia/one-components';
import {
  IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading_assignments_Assignment,
  PrintHeadingOrderingInput,
} from '__generated__/queries-topic';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { paths, replaceParams } from 'routing';
import { theme } from 'theme';
import { getColorByStatus, getStatusName } from 'utils/rawArticleStatus';
import { Filters } from './filters';
import { useGetPrintHeadings } from './issuePage.topic.graphql';
import { PhotoStatus } from './PhotoStatus';

export interface RowItem {
  printHeading?: { id: string; title: string };
  assignment: IssuePage_GetPrintHeadingsQuery_query_printHeadings_PrintHeading_assignments_Assignment;
}

export const Table: FC<{
  selectedIssue: string;
  filters: Filters;
}> = ({ selectedIssue, filters }) => {
  const history = useHistory();

  const { data, loading, error } = useGetPrintHeadings({
    first: 1000,
    where: {
      printIssue: { id: selectedIssue },
      assignmentCount_gt: 0,
      id: filters.printHeading,
    },
    orderBy: [PrintHeadingOrderingInput.order_ASC],
  });

  const list = data?.printHeadings.reduce<RowItem[]>(
    (acc, printHeading) => [
      ...acc,
      ...printHeading.assignments
        .filter(
          (assignment) =>
            (!filters.photoStatus ||
              assignment.mediaSearchStatus === filters.photoStatus) &&
            (!filters.textStatus ||
              assignment.rawArticle?.lastVersion?.status ===
                filters.textStatus),
        )
        .map((assignment, index) => ({
          assignment,
          ...(index === 0
            ? {
                printHeading: {
                  id: printHeading.id,
                  title: printHeading.title,
                },
              }
            : {}),
        })),
    ],
    [],
  );

  return (
    <Paper
      sx={{
        flex: 1,
        mx: 2,
        mt: 2,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }}
    >
      <InfiniteTable
        list={list}
        loading={loading}
        error={error}
        rowHeight={50}
        columns={[
          {
            label: 'TÊTIÈRE',
            key: 'heading',
            width: 200,
            cellDataGetter: ({ rowData }: { rowData: RowItem }) =>
              rowData.printHeading?.title,
          },
          {
            label: 'SUJET',
            key: 'subject',
            width: 300,
            cellRenderer: (rowData: RowItem) => {
              return (
                <ButtonBase
                  sx={{
                    flex: 1,
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                  }}
                  onClick={() =>
                    history.push(
                      replaceParams(paths.SUBJECT_EDIT, {
                        id: rowData.assignment.subject.id,
                      }),
                    )
                  }
                >
                  <Typography
                    variant="body2"
                    sx={{
                      px: 2,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      lineHeight: '16px',
                      maxHeight: '32px',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      textAlign: 'left',
                    }}
                  >
                    {rowData.assignment.subject.title}
                  </Typography>
                </ButtonBase>
              );
            },
          },
          {
            label: 'COMMENTAIRE',
            key: 'comment',
            width: 200,
            cellDataGetter: ({ rowData }: { rowData: RowItem }) =>
              rowData.assignment.subject.comment,
          },
          {
            label: 'TEXTE',
            key: 'text',
            width: 100,
            flexGrow: 1,
            cellRenderer: (rowData: RowItem) => {
              const article = rowData.assignment.rawArticle?.lastVersion;
              return (
                <ButtonBase
                  sx={{
                    flex: 1,
                    alignSelf: 'stretch',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    px: 2,
                    fontSize: 14,
                  }}
                  onClick={() =>
                    history.push(
                      rowData.assignment.rawArticle
                        ? replaceParams(paths.ARTICLE_EDIT, {
                            id: rowData.assignment.rawArticle.id,
                          })
                        : replaceParams(paths.ARTICLE_ADD, {
                            assignmentId: rowData.assignment.id,
                          }),
                    )
                  }
                >
                  <Box
                    sx={{
                      color: article && getColorByStatus(article.status),
                      fontWeight: 'bold',
                      width: 100,
                      flexShrink: 0,
                    }}
                  >
                    {article && getStatusName(article.status)}
                  </Box>
                  <Article
                    sx={{
                      color: article
                        ? getColorByStatus(article.status)
                        : theme.palette.grey[400],
                    }}
                  />
                  {article && (
                    <Box
                      sx={{
                        ml: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        lineHeight: '16px',
                        maxHeight: '32px',
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                        textAlign: 'left',
                      }}
                    >
                      {article.title}
                    </Box>
                  )}
                </ButtonBase>
              );
            },
          },
          {
            label: 'PHOTO',
            key: 'photo',
            width: 140,
            cellRenderer: (rowData: RowItem) => (
              <PhotoStatus
                rowData={rowData}
                onClick={() =>
                  history.push(
                    replaceParams(paths.SUBJECT_ASSETS, {
                      id: rowData.assignment.subject.id,
                    }),
                  )
                }
              />
            ),
          },
        ]}
      />
    </Paper>
  );
};
