import { IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_pages_CenshareRailroadByNamePagesResultType } from '__generated__/queries-topic';

export const MARGIN_BETWEEN_TWO_PAGES = 2;

export interface Sheet {
  id: string;
  color: number;
  name: string;
}

export interface HalfPage
  extends IssuePage_GetRailroadPagesQuery_query_getCenshareRailroadByName_CenshareRailroadByNameResultType_pages_CenshareRailroadByNamePagesResultType {
  sheet: Sheet | undefined;
}

export const getWorkFlow = (page: HalfPage | null) => {
  try {
    return JSON.parse(page?.workflow || '') as Record<string, unknown>;
  } catch (e) {
    return undefined;
  }
};

export const getColorFromDecimal = (decimalColor: number | undefined) =>
  decimalColor ? `#${decimalColor.toString(16)}` : undefined;

const hexToRgb = (hex: string) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const replacedHex = hex.replace(shorthandRegex, (m, r, g, b) => {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(replacedHex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 0,
        g: 0,
        b: 0,
      };
};

export const backgroundIsLight = (hexColor: string) => {
  const { r, g, b } = hexToRgb(hexColor);
  return r * 0.299 + g * 0.587 + b * 0.114 > 186;
};
