export const download = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  // eslint-disable-next-line immutable/no-mutation
  a.style.display = 'none';
  // eslint-disable-next-line immutable/no-mutation
  a.href = url;
  // eslint-disable-next-line immutable/no-mutation
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
