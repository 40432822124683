import { gql, useQuery } from '@apollo/client';
import {
  IssuePage_GetElisaPdfQuery,
  IssuePage_GetElisaPdfQueryVariables,
  IssuePage_GetPrintHeadingsQuery,
  IssuePage_GetPrintHeadingsQueryVariables,
  IssuePage_GetPrintIssueQuery,
  IssuePage_GetPrintIssueQueryVariables,
  IssuePage_GetPrintIssuesQuery,
  IssuePage_GetPrintIssuesQueryVariables,
  IssuePage_GetPrintPublicationsQuery,
  IssuePage_GetPrintPublicationsQueryVariables,
  IssuePage_GetRailroadPagesQuery,
  IssuePage_GetRailroadPagesQueryVariables,
} from '__generated__/queries-topic';
import { useQueryPromise } from 'utils/useQueryPromise';

// Print publications

const GET_PRINT_PUBLICATIONS = gql`
  query IssuePage_GetPrintPublications(
    $where: PrintPublicationFilterInput
    $orderBy: [PrintPublicationOrderingInput!]
    $skip: UnsignedInt
    $first: UnsignedInt!
  ) {
    printPublications(
      where: $where
      first: $first
      orderBy: $orderBy
      skip: $skip
    ) {
      id
      title
      printIssues(first: 1000) {
        id
      }
    }
  }
`;

export const useGetPrintPublications = (
  variables: IssuePage_GetPrintPublicationsQueryVariables,
) => {
  return useQuery<
    IssuePage_GetPrintPublicationsQuery,
    IssuePage_GetPrintPublicationsQueryVariables
  >(GET_PRINT_PUBLICATIONS, { variables });
};

// Print issues

const GET_PRINT_ISSUES = gql`
  query IssuePage_GetPrintIssues(
    $where: PrintIssueFilterInput
    $orderBy: [PrintIssueOrderingInput!]
    $skip: UnsignedInt
    $first: UnsignedInt!
  ) {
    printIssues(where: $where, first: $first, orderBy: $orderBy, skip: $skip) {
      id
      title
    }
  }
`;

export const useGetPrintIssues = (
  variables: IssuePage_GetPrintIssuesQueryVariables,
  skip?: boolean,
) => {
  return useQuery<
    IssuePage_GetPrintIssuesQuery,
    IssuePage_GetPrintIssuesQueryVariables
  >(GET_PRINT_ISSUES, { variables, skip });
};

const GET_PRINT_ISSUE = gql`
  query IssuePage_GetPrintIssue($where: PrintIssueUniqueFilterInput!) {
    printIssue(where: $where) {
      id
      title
      printPublication {
        id
        key
      }
    }
  }
`;

export const usePrintIssue = (
  variables: IssuePage_GetPrintIssueQueryVariables,
  skip?: boolean,
) => {
  return useQuery<
    IssuePage_GetPrintIssueQuery,
    IssuePage_GetPrintIssueQueryVariables
  >(GET_PRINT_ISSUE, { variables, skip });
};

export const useGetPrintIssue = () =>
  useQueryPromise<
    IssuePage_GetPrintIssueQuery,
    IssuePage_GetPrintIssueQueryVariables
  >(GET_PRINT_ISSUE);

// Print headings

const GET_PRINT_HEADINGS = gql`
  query IssuePage_GetPrintHeadings(
    $where: PrintHeadingFilterInput
    $orderBy: [PrintHeadingOrderingInput!]
    $skip: UnsignedInt
    $first: UnsignedInt!
  ) {
    printHeadings(
      where: $where
      first: $first
      orderBy: $orderBy
      skip: $skip
    ) {
      id
      title
      assignments(first: 1000) {
        id
        mediaSearchStatus
        pageNumber
        subject {
          id
          title
          comment
        }
        rawArticle {
          id
          lastVersion {
            id
            status
            title
          }
        }
      }
    }
  }
`;

export const useGetPrintHeadings = (
  variables: IssuePage_GetPrintHeadingsQueryVariables,
  skip?: boolean,
) => {
  return useQuery<
    IssuePage_GetPrintHeadingsQuery,
    IssuePage_GetPrintHeadingsQueryVariables
  >(GET_PRINT_HEADINGS, { variables, skip });
};

// RailroadPages pages

const GET_RAILROAD_PAGES = gql`
  query IssuePage_GetRailroadPages($brandKey: String!, $issue: String!) {
    getCenshareRailroadByName(brandKey: $brandKey, issue: $issue) {
      sheets
      pages {
        filename
        indesignFilename
        pageNumber
        workflow
      }
    }
  }
`;

export const useGetCenshareRailroadByName = (
  variables: IssuePage_GetRailroadPagesQueryVariables,
  skip?: boolean,
) => {
  return useQuery<
    IssuePage_GetRailroadPagesQuery,
    IssuePage_GetRailroadPagesQueryVariables
  >(GET_RAILROAD_PAGES, { variables, skip });
};

// Pdf

const GET_ELISA_PDF = gql`
  query IssuePage_GetElisaPdf($brandKey: String!, $issue: Int!) {
    getElisaPdf(brandKey: $brandKey, issue: $issue) {
      link
    }
  }
`;

export const useGetElisaPdf = (
  variables: IssuePage_GetElisaPdfQueryVariables,
  skip?: boolean,
) => {
  return useQuery<
    IssuePage_GetElisaPdfQuery,
    IssuePage_GetElisaPdfQueryVariables
  >(GET_ELISA_PDF, { variables, skip });
};
