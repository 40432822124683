import { Drawer, Tab, Tabs } from '@mui/material';
import classNames from 'classnames';
import { parse, stringify } from 'query-string';
import { omit } from 'ramda';
import { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  MediaSearchStatus,
  RawArticleStatus,
  UnitSpeedDial_GetUnitsQuery_query_units_Unit,
} from '../../__generated__/queries-topic';
import { AppBar } from '../../components/AppBar';
import { FAButton } from '../../components/Button/FAButton';
import { UnitSpeedDial } from '../../components/SpeedDial/Unit';
import { paths, replaceParams } from '../../routing';
import { auth } from '../../utils/auth';
import {
  addNamespaceToUrlFilters,
  convertStringValueToNumberParams,
  filterNamespacedUrlFilters,
} from '../../utils/namespaceUrlFilters';
import { useStyles } from './styles';
import { SubjectList } from './SubjectList';
import { SubjectListFilters } from './SubjectList/Filters';
import { SubjectListByIssue } from './SubjectListByIssue';
import { SubjectListByIssueFilters } from './SubjectListByIssue/Filters';
import { IssueSelector } from './SubjectListByIssue/IssueSelector';

const SUBJECT_LIST_FILTER_NAMESPACE = 'SubjectList';

const typeToIdx = (type: string) => {
  switch (type) {
    case 'issue':
      return 1;
    case 'photo':
      return 2;
    default:
      return 0;
  }
};

const idxToType = (idx: number) => {
  switch (idx) {
    case 1:
      return 'issue';
    case 2:
      return 'photo';
    default:
      return 'list';
  }
};

const makeArray = (param: string[] | string | undefined): string[] => {
  if (!param) {
    return [];
  }
  if (Array.isArray(param)) {
    return param;
  }
  return [param];
};

export const SubjectListPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { search } = useLocation();
  const params = parse(search) as { [key: string]: string };

  const addFilterParams = (values: Record<string, string | string[]>) => {
    history.push({
      pathname: paths.SUBJECT_LIST,
      search: stringify(
        {
          ...params,
          ...addNamespaceToUrlFilters(values, SUBJECT_LIST_FILTER_NAMESPACE),
        },
        {
          encode: false,
        },
      ),
    });
  };

  const resetQueries = (type: string) => () => {
    history.push({
      pathname: paths.SUBJECT_LIST,
      search: stringify(
        omit([`${SUBJECT_LIST_FILTER_NAMESPACE}_${type}`], params),
      ),
    });
  };

  const goToSubjectEdition = (
    unit: UnitSpeedDial_GetUnitsQuery_query_units_Unit,
  ) => {
    history.push({
      pathname: replaceParams(paths.SUBJECT_ADD, { unitId: unit.id }),
      search: stringify({ ...params, unit: unit.id }),
    });
  };

  const onSearch = (value: string) => {
    addFilterParams({ search: value });
  };

  // global subject listing params
  const subjectListParams = filterNamespacedUrlFilters(
    params,
    SUBJECT_LIST_FILTER_NAMESPACE,
  );

  const selectedUnitId = params.unit;
  const selectedCategoryIds = makeArray(subjectListParams.cat);
  const selectedLocationIds = makeArray(subjectListParams.loc);
  const selectedTextIds = makeArray(
    subjectListParams.text,
  ) as RawArticleStatus[];
  // Lorsque la recherche contient uniquement un number, il faut clear les quotes qui ont été rajouté par
  // la méthode filterNamespacedUrlFilters pour ne pas envoyer "'3'" mais plutôt "3"
  const searchString = (convertStringValueToNumberParams(
    subjectListParams.search,
  ) || '') as string;

  // by issue subject listing params
  const selectedIssueTextIds = makeArray(
    subjectListParams.issueText,
  ) as RawArticleStatus[];
  const selectedPrintPublication = (subjectListParams.printPublication ||
    '') as string;
  const selectedIssue = (subjectListParams.printIssue || '') as string;
  const showEmpty =
    (subjectListParams.empty && subjectListParams.empty === "'1'") || false;
  const selectedMediaSearchStatuses = makeArray(
    subjectListParams.issueMedia,
  ) as MediaSearchStatus[];

  const tabIdx = typeToIdx(subjectListParams.type as string);

  return (
    <div className={classes.root}>
      <AppBar
        title="Gestion des sujets"
        avatarUrl={auth.user?.avatarUrl}
        withSearchBar={tabIdx !== typeToIdx('issue')}
        onSearchBarChange={onSearch}
        onClearSearch={resetQueries('search')}
        searchValue={searchString}
      >
        <Tabs
          value={tabIdx}
          onChange={(event, value) => {
            // Uncomment to activate link to the new issue page
            // if (value === 1) {
            //   history.push(paths.ISSUE);
            //   return;
            // }
            addFilterParams({ type: idxToType(value) });
          }}
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab
            label={<span className={classes.tabLabel}>Sujets</span>}
            className={classes.tab}
          />
          <Tab
            label={<span className={classes.tabLabel}>Parutions</span>}
            className={classes.tab}
            classes={{ labelIcon: classes.tabLabel }}
          />
        </Tabs>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: classNames(
            classes.drawerPaper,
            (tabIdx === 0 || tabIdx === 2) && classes.drawerWidthGlobal,
            tabIdx === 1 && classes.drawerWidthIssue,
          ),
        }}
      >
        <div className={classes.toolbar} />
        <div className={classes.toolbar} />
        {tabIdx === 0 && (
          <SubjectListFilters
            categoryIds={selectedCategoryIds}
            locationIds={selectedLocationIds}
            textStatuses={selectedTextIds}
            unitId={selectedUnitId}
            addFilter={addFilterParams}
            afterLocationSearchChange={resetQueries('loc')}
          />
        )}
        {tabIdx === 1 && (
          <SubjectListByIssueFilters
            printIssueId={selectedIssue}
            printPublicationId={selectedPrintPublication}
            selectedTexts={selectedIssueTextIds}
            onTextChange={(statuses) =>
              addFilterParams({ issueText: statuses })
            }
            selectedMediaSearchStatus={selectedMediaSearchStatuses}
            onMediaSearchStatusChange={(statuses) =>
              addFilterParams({ issueMedia: statuses })
            }
            showEmpty={showEmpty}
            onEmptyChange={(e) => {
              addFilterParams({
                empty: e.target.checked ? '1' : '0',
              });
            }}
          />
        )}
      </Drawer>
      <main
        className={classNames(
          classes.content,
          (tabIdx === 0 || tabIdx === 2) && classes.contentPaddingGlobal,
          tabIdx === 1 && classes.contentPaddingIssue,
        )}
      >
        {tabIdx === 0 && (
          <SubjectList
            unit={selectedUnitId}
            categories={selectedCategoryIds}
            locations={selectedLocationIds}
            textStatusIds={selectedTextIds}
            searchString={searchString}
          />
        )}
        {tabIdx === 1 && (
          <>
            <div className={classes.issueFilter}>
              <IssueSelector
                selectedPrintPublication={selectedPrintPublication}
                selectedIssue={selectedIssue}
                addFilter={addFilterParams}
                unitId={selectedUnitId}
              />
            </div>
            <SubjectListByIssue
              selectedTexts={selectedIssueTextIds}
              selectedMediaSearchStatuses={selectedMediaSearchStatuses}
              issueId={selectedIssue}
              showEmpty={showEmpty}
            />
          </>
        )}
      </main>
      {selectedUnitId ? (
        <FAButton
          linkProps={{
            to: paths.SUBJECT_ADD,
            params: { unitId: selectedUnitId },
          }}
        />
      ) : (
        <UnitSpeedDial onUnitClick={goToSubjectEdition} />
      )}
    </div>
  );
};
