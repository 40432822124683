import { Replay } from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { SimpleSelect } from '@prismamedia/one-components';
import {
  MediaSearchStatus,
  PrintHeadingOrderingInput,
} from '__generated__/queries-topic';
import { Dispatch, FC, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { paths } from 'routing';
import { theme } from 'theme';
import {
  getLabelByStatus,
  getOrderedMediaSearchStatus,
} from 'utils/mediaSearchStatus';
import { getOrderedStatusList, getStatusName } from 'utils/rawArticleStatus';
import { Filters } from './filters';
import { useGetPrintHeadings } from './issuePage.topic.graphql';
import { IssueSelector } from './IssueSelector';

export const FILTER_BAR_HEIGHT = 60;

export const FilterBar: FC<{
  selectedIssue: string | undefined;
  setSelectedIssue: Dispatch<SetStateAction<string | undefined>>;
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
}> = ({ selectedIssue, setSelectedIssue, filters, setFilters }) => {
  const history = useHistory();

  const { data: printHeadingsData } = useGetPrintHeadings(
    {
      first: 1000,
      where: { printIssue: { id: selectedIssue }, assignmentCount_gt: 0 },
      orderBy: [PrintHeadingOrderingInput.order_ASC],
    },
    !selectedIssue,
  );

  return (
    <Box
      sx={{
        height: FILTER_BAR_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        px: 3,
        overflowX: 'auto',
      }}
    >
      <ToggleButtonGroup
        value="issues"
        size="small"
        color="primary"
        exclusive
        onChange={(_, value) => {
          if (value === 'subjects') {
            history.push(paths.SUBJECT_LIST);
          }
        }}
      >
        <ToggleButton value="subjects">Sujets</ToggleButton>
        <ToggleButton
          value="issues"
          sx={{
            bgcolor: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.common.white} !important`,
          }}
        >
          Parutions
        </ToggleButton>
      </ToggleButtonGroup>

      <Divider orientation="vertical" sx={{ mx: 2, height: 40 }} />

      <IssueSelector
        selectedIssue={selectedIssue}
        setSelectedIssue={setSelectedIssue}
      />

      {!!selectedIssue && (
        <>
          <Divider orientation="vertical" sx={{ mx: 2, height: 40 }} />

          <SimpleSelect
            label="Têtière"
            size="small"
            value={filters.printHeading || ''}
            sx={{ minWidth: 100 }}
            onChange={(_, printHeading) =>
              setFilters((prev) => ({
                ...prev,
                printHeading: printHeading || undefined,
              }))
            }
            options={[
              { value: '', label: '-' },
              ...(printHeadingsData?.printHeadings || []).map(
                ({ id, title }) => ({
                  value: id,
                  label: title,
                }),
              ),
            ]}
          />
          <SimpleSelect
            label="Texte"
            size="small"
            value={filters.textStatus || ''}
            sx={{ minWidth: 100, ml: 2 }}
            onChange={(_, textStatus) =>
              setFilters((prev) => ({
                ...prev,
                textStatus: textStatus || undefined,
              }))
            }
            options={[
              { value: '', label: '-' },
              ...getOrderedStatusList().map((status) => ({
                value: status,
                label: getStatusName(status),
              })),
            ]}
          />
          <SimpleSelect
            label="Photo"
            size="small"
            value={filters.photoStatus || ''}
            sx={{ minWidth: 100, ml: 2 }}
            onChange={(_, photoStatus) =>
              setFilters((prev) => ({
                ...prev,
                photoStatus: (photoStatus as MediaSearchStatus) || undefined,
              }))
            }
            options={[
              { value: '', label: '-' },
              ...getOrderedMediaSearchStatus().map((status) => ({
                value: status,
                label: getLabelByStatus(status),
              })),
            ]}
          />
          {(filters.printHeading ||
            filters.textStatus ||
            filters.photoStatus) && (
            <Tooltip title="Réinitialiser les filtres" sx={{ ml: 'auto' }}>
              <IconButton
                onClick={() =>
                  setFilters((prev) => ({
                    ...prev,
                    printHeading: undefined,
                    textStatus: undefined,
                    photoStatus: undefined,
                  }))
                }
              >
                <Replay />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </Box>
  );
};
