import { MediaSearchStatus } from '__generated__/queries-topic';

export interface Filters {
  printHeading?: string;
  textStatus?: string;
  photoStatus?: MediaSearchStatus;
}

export const filtersAreDefault = (filters: Filters) =>
  !filters.printHeading && !filters.textStatus && !filters.photoStatus;

export const getDefaultFilters = () => ({});
