import { Box } from '@mui/material';
import { IssueSelector } from 'pages/IssuePage/IssueSelector';
import { Dispatch, FC, SetStateAction } from 'react';

export const FILTER_BAR_HEIGHT = 60;

export const FilterBar: FC<{
  selectedIssue: string | undefined;
  setSelectedIssue: Dispatch<SetStateAction<string | undefined>>;
}> = ({ selectedIssue, setSelectedIssue }) => {
  return (
    <Box
      sx={{
        height: FILTER_BAR_HEIGHT,
        display: 'flex',
        alignItems: 'center',
        px: 3,
        overflowX: 'auto',
      }}
    >
      <IssueSelector
        selectedIssue={selectedIssue}
        setSelectedIssue={setSelectedIssue}
      />
    </Box>
  );
};
