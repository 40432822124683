import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: string; output: string; }
  JSONObject: { input: { [key: string]: any }; output: { [key: string]: any }; }
};

/** "Agency" resource's node */
export type Agency = {
  __typename?: 'Agency';
  /** The internal ID */
  _id: Scalars['Int']['output'];
  /** "ArchiveAgency.agency"'s inverse relation */
  archiveAgencies: Array<ArchiveAgency>;
  /** "ArchiveAgency.agency"'s inverse relation count */
  archiveAgencyCount: Scalars['Int']['output'];
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  /** "Photo.agency"'s inverse relation count */
  photoCount: Scalars['Int']['output'];
  /** "Photo.agency"'s inverse relation */
  photos: Array<Photo>;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};


/** "Agency" resource's node */
export type AgencyArchiveAgenciesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<ArchiveAgencyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchiveAgencyWhereInput>;
};


/** "Agency" resource's node */
export type AgencyArchiveAgencyCountArgs = {
  where?: InputMaybe<ArchiveAgencyWhereInput>;
};


/** "Agency" resource's node */
export type AgencyPhotoCountArgs = {
  where?: InputMaybe<PhotoWhereInput>;
};


/** "Agency" resource's node */
export type AgencyPhotosArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoWhereInput>;
};

export type AgencyCreateInput = {
  /** Actions for the "Agency.archiveAgencies" relation: "ArchiveAgency.agency"'s inverse relation */
  archiveAgencies?: InputMaybe<AgencyNestedArchiveAgenciesCreateInput>;
  label: Scalars['String']['input'];
  name: Scalars['String']['input'];
  /** Actions for the "Agency.photos" relation: "Photo.agency"'s inverse relation */
  photos?: InputMaybe<AgencyNestedPhotosCreateInput>;
};

export type AgencyNestedArchiveAgenciesCreateInput = {
  /** Connect existing "ArchiveAgency" nodes to the new "Agency" node, through the "Agency.archiveAgencies" relation. */
  connect?: InputMaybe<Array<ArchiveAgencyWhereUniqueInput>>;
  /** Create new "ArchiveAgency" nodes and connect them to the new "Agency" node, through the "Agency.archiveAgencies" relation. */
  create?: InputMaybe<Array<ArchiveAgencyWithForcedAgencyCreateInput>>;
};

export type AgencyNestedArchiveAgenciesUpdateInput = {
  /** Connect existing "ArchiveAgency" nodes to the existing "Agency" node, through the "Agency.archiveAgencies" relation. */
  connect?: InputMaybe<Array<ArchiveAgencyWhereUniqueInput>>;
  /** Create new "ArchiveAgency" nodes and connect them to the existing "Agency" node, through the "Agency.archiveAgencies" relation. */
  create?: InputMaybe<Array<ArchiveAgencyWithForcedAgencyCreateInput>>;
  /** Delete existing "ArchiveAgency" nodes from the "Agency.archiveAgencies" relation. */
  delete?: InputMaybe<Array<ArchiveAgencyWithForcedAgencyWhereUniqueInput>>;
  /** Update existing "ArchiveAgency" nodes and connect them to the existing "Agency" node, through the "Agency.archiveAgencies" relation. */
  update?: InputMaybe<Array<AgencyNestedUpdateArchiveAgenciesUpdateInput>>;
  /** Upsert existing "ArchiveAgency" nodes and connect them to the existing "Agency" node, through the "Agency.archiveAgencies" relation. */
  upsert?: InputMaybe<Array<AgencyNestedUpsertArchiveAgenciesUpdateInput>>;
};

export type AgencyNestedPhotosCreateInput = {
  /** Connect existing "Photo" nodes to the new "Agency" node, through the "Agency.photos" relation. */
  connect?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Create new "Photo" nodes and connect them to the new "Agency" node, through the "Agency.photos" relation. */
  create?: InputMaybe<Array<PhotoWithForcedAgencyCreateInput>>;
};

export type AgencyNestedPhotosUpdateInput = {
  /** Connect existing "Photo" nodes to the existing "Agency" node, through the "Agency.photos" relation. */
  connect?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Create new "Photo" nodes and connect them to the existing "Agency" node, through the "Agency.photos" relation. */
  create?: InputMaybe<Array<PhotoWithForcedAgencyCreateInput>>;
  /** Delete existing "Photo" nodes from the "Agency.photos" relation. */
  delete?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Disconnect existing "Photo" nodes of the "Agency.photos" relation. */
  disconnect?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Update existing "Photo" nodes and connect them to the existing "Agency" node, through the "Agency.photos" relation. */
  update?: InputMaybe<Array<AgencyNestedUpdatePhotosUpdateInput>>;
  /** Upsert existing "Photo" nodes and connect them to the existing "Agency" node, through the "Agency.photos" relation. */
  upsert?: InputMaybe<Array<AgencyNestedUpsertPhotosUpdateInput>>;
};

export type AgencyNestedUpdateArchiveAgenciesUpdateInput = {
  data: ArchiveAgencyWithForcedAgencyUpdateInput;
  where: ArchiveAgencyWithOptionalAgencyWhereUniqueInput;
};

export type AgencyNestedUpdatePhotosUpdateInput = {
  data: PhotoWithForcedAgencyUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type AgencyNestedUpsertArchiveAgenciesUpdateInput = {
  create: ArchiveAgencyWithForcedAgencyCreateInput;
  update: ArchiveAgencyWithForcedAgencyUpdateInput;
  where: ArchiveAgencyWithOptionalAgencyWhereUniqueInput;
};

export type AgencyNestedUpsertPhotosUpdateInput = {
  create: PhotoWithForcedAgencyCreateInput;
  update: PhotoWithForcedAgencyUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum AgencyOrderByInput {
  _id_ASC = '_id_ASC',
  _id_DESC = '_id_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  label_ASC = 'label_ASC',
  label_DESC = 'label_DESC',
  name_ASC = 'name_ASC',
  name_DESC = 'name_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC'
}

export type AgencyUpdateInput = {
  /** Actions for the "Agency.archiveAgencies" relation: "ArchiveAgency.agency"'s inverse relation */
  archiveAgencies?: InputMaybe<AgencyNestedArchiveAgenciesUpdateInput>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Agency.photos" relation: "Photo.agency"'s inverse relation */
  photos?: InputMaybe<AgencyNestedPhotosUpdateInput>;
};

/** Filters the "Agency" nodes by specifying some conditions */
export type AgencyWhereInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  _id_gt?: InputMaybe<Scalars['Int']['input']>;
  _id_gte?: InputMaybe<Scalars['Int']['input']>;
  _id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _id_lt?: InputMaybe<Scalars['Int']['input']>;
  _id_lte?: InputMaybe<Scalars['Int']['input']>;
  _id_not?: InputMaybe<Scalars['Int']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  AND?: InputMaybe<Array<AgencyWhereInput>>;
  archiveAgencies_some?: InputMaybe<ArchiveAgencyWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_ends_with?: InputMaybe<Scalars['String']['input']>;
  label_gt?: InputMaybe<Scalars['String']['input']>;
  label_gte?: InputMaybe<Scalars['String']['input']>;
  label_in?: InputMaybe<Array<Scalars['String']['input']>>;
  label_lt?: InputMaybe<Scalars['String']['input']>;
  label_lte?: InputMaybe<Scalars['String']['input']>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  label_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  label_starts_with?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  NOT?: InputMaybe<AgencyWhereInput>;
  OR?: InputMaybe<Array<AgencyWhereInput>>;
  photos_some?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/**
 * Identifies exactly one "Agency" node with one of these unique values:
 * - _id
 * - name
 * - label
 */
export type AgencyWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** "Archive" resource's node */
export type Archive = {
  __typename?: 'Archive';
  /** The internal ID */
  _id: Scalars['Int']['output'];
  /** get the access rights of the archive */
  accesses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** get the agencies of the archive */
  agencies?: Maybe<Array<Maybe<InformationAgency>>>;
  /** "ArchiveAgency.archive"'s inverse relation */
  archiveAgencies: Array<ArchiveAgency>;
  /** "ArchiveAgency.archive"'s inverse relation count */
  archiveAgencyCount: Scalars['Int']['output'];
  /** The internal ID */
  archiveId?: Maybe<Scalars['Int']['output']>;
  /** "Category.archive"'s inverse relation */
  categories: Array<Category>;
  /** "Category.archive"'s inverse relation count */
  categoryCount: Scalars['Int']['output'];
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** "DeliverySubject.archive"'s inverse relation count */
  deliverySubjectCount: Scalars['Int']['output'];
  /** "DeliverySubject.archive"'s inverse relation */
  deliverySubjects: Array<DeliverySubject>;
  /** "GcImportSetting.archive"'s inverse relation */
  gcImportSetting?: Maybe<GcImportSetting>;
  hasCategories?: Maybe<Scalars['Boolean']['output']>;
  hasCategory?: Maybe<Scalars['Boolean']['output']>;
  /** The public ID, an UUID v4 */
  id: Scalars['ID']['output'];
  /** "ImportQueue.archive"'s inverse relation count */
  importQueueCount: Scalars['Int']['output'];
  /** "ImportQueue.archive"'s inverse relation */
  importQueues: Array<ImportQueue>;
  /** get the title of the archive */
  key?: Maybe<ArchiveKeyType>;
  /** The archive label */
  label?: Maybe<Scalars['String']['output']>;
  /** "MetadataAccessRights.archive"'s inverse relation */
  metadataAccessRightsList: Array<MetadataAccessRights>;
  /** "MetadataAccessRights.archive"'s inverse relation count */
  metadataAccessRightsListCount: Scalars['Int']['output'];
  /** "MetadataAddlModelInfo.archive"'s inverse relation */
  metadataAddlModelInfoList: Array<MetadataAddlModelInfo>;
  /** "MetadataAddlModelInfo.archive"'s inverse relation count */
  metadataAddlModelInfoListCount: Scalars['Int']['output'];
  /** "MetadataArchiveChild.archive"'s inverse relation */
  metadataArchiveChildList: Array<MetadataArchiveChild>;
  /** "MetadataArchiveChild.archive"'s inverse relation count */
  metadataArchiveChildListCount: Scalars['Int']['output'];
  /** "MetadataArchiveParent.archive"'s inverse relation */
  metadataArchiveParentList: Array<MetadataArchiveParent>;
  /** "MetadataArchiveParent.archive"'s inverse relation count */
  metadataArchiveParentListCount: Scalars['Int']['output'];
  /** "MetadataAssetState.archive"'s inverse relation */
  metadataAssetStateList: Array<MetadataAssetState>;
  /** "MetadataAssetState.archive"'s inverse relation count */
  metadataAssetStateListCount: Scalars['Int']['output'];
  /** "MetadataAttributionName.archive"'s inverse relation */
  metadataAttributionNameList: Array<MetadataAttributionName>;
  /** "MetadataAttributionName.archive"'s inverse relation count */
  metadataAttributionNameListCount: Scalars['Int']['output'];
  /** "MetadataAttributionUrl.archive"'s inverse relation */
  metadataAttributionUrlList: Array<MetadataAttributionUrl>;
  /** "MetadataAttributionUrl.archive"'s inverse relation count */
  metadataAttributionUrlListCount: Scalars['Int']['output'];
  /** "MetadataAudience.archive"'s inverse relation */
  metadataAudienceList: Array<MetadataAudience>;
  /** "MetadataAudience.archive"'s inverse relation count */
  metadataAudienceListCount: Scalars['Int']['output'];
  /** "MetadataAuthorsPosition.archive"'s inverse relation */
  metadataAuthorsPositionList: Array<MetadataAuthorsPosition>;
  /** "MetadataAuthorsPosition.archive"'s inverse relation count */
  metadataAuthorsPositionListCount: Scalars['Int']['output'];
  /** "MetadataBackupName.archive"'s inverse relation */
  metadataBackupNameList: Array<MetadataBackupName>;
  /** "MetadataBackupName.archive"'s inverse relation count */
  metadataBackupNameListCount: Scalars['Int']['output'];
  /** "MetadataBitmapGrayscalePicture.archive"'s inverse relation */
  metadataBitmapGrayscalePictureList: Array<MetadataBitmapGrayscalePicture>;
  /** "MetadataBitmapGrayscalePicture.archive"'s inverse relation count */
  metadataBitmapGrayscalePictureListCount: Scalars['Int']['output'];
  /** "MetadataBrand.archive"'s inverse relation */
  metadataBrandList: Array<MetadataBrand>;
  /** "MetadataBrand.archive"'s inverse relation count */
  metadataBrandListCount: Scalars['Int']['output'];
  /** "MetadataBrandPrisma.archive"'s inverse relation */
  metadataBrandPrismaList: Array<MetadataBrandPrisma>;
  /** "MetadataBrandPrisma.archive"'s inverse relation count */
  metadataBrandPrismaListCount: Scalars['Int']['output'];
  /** "MetadataBrandShortName.archive"'s inverse relation */
  metadataBrandShortNameList: Array<MetadataBrandShortName>;
  /** "MetadataBrandShortName.archive"'s inverse relation count */
  metadataBrandShortNameListCount: Scalars['Int']['output'];
  /** "MetadataCaptionWriter.archive"'s inverse relation */
  metadataCaptionWriterList: Array<MetadataCaptionWriter>;
  /** "MetadataCaptionWriter.archive"'s inverse relation count */
  metadataCaptionWriterListCount: Scalars['Int']['output'];
  /** "MetadataCategory.archive"'s inverse relation */
  metadataCategoryList: Array<MetadataCategory>;
  /** "MetadataCategory.archive"'s inverse relation count */
  metadataCategoryListCount: Scalars['Int']['output'];
  /** "MetadataCategoryPrisma.archive"'s inverse relation */
  metadataCategoryPrismaList: Array<MetadataCategoryPrisma>;
  /** "MetadataCategoryPrisma.archive"'s inverse relation count */
  metadataCategoryPrismaListCount: Scalars['Int']['output'];
  /** "MetadataCertificate.archive"'s inverse relation */
  metadataCertificateList: Array<MetadataCertificate>;
  /** "MetadataCertificate.archive"'s inverse relation count */
  metadataCertificateListCount: Scalars['Int']['output'];
  /** "MetadataCiAdrCity.archive"'s inverse relation */
  metadataCiAdrCityList: Array<MetadataCiAdrCity>;
  /** "MetadataCiAdrCity.archive"'s inverse relation count */
  metadataCiAdrCityListCount: Scalars['Int']['output'];
  /** "MetadataCiAdrCtry.archive"'s inverse relation */
  metadataCiAdrCtryList: Array<MetadataCiAdrCtry>;
  /** "MetadataCiAdrCtry.archive"'s inverse relation count */
  metadataCiAdrCtryListCount: Scalars['Int']['output'];
  /** "MetadataCiAdrExtadr.archive"'s inverse relation */
  metadataCiAdrExtadrList: Array<MetadataCiAdrExtadr>;
  /** "MetadataCiAdrExtadr.archive"'s inverse relation count */
  metadataCiAdrExtadrListCount: Scalars['Int']['output'];
  /** "MetadataCiAdrPcode.archive"'s inverse relation */
  metadataCiAdrPcodeList: Array<MetadataCiAdrPcode>;
  /** "MetadataCiAdrPcode.archive"'s inverse relation count */
  metadataCiAdrPcodeListCount: Scalars['Int']['output'];
  /** "MetadataCiAdrRegion.archive"'s inverse relation */
  metadataCiAdrRegionList: Array<MetadataCiAdrRegion>;
  /** "MetadataCiAdrRegion.archive"'s inverse relation count */
  metadataCiAdrRegionListCount: Scalars['Int']['output'];
  /** "MetadataCiEmailWork.archive"'s inverse relation */
  metadataCiEmailWorkList: Array<MetadataCiEmailWork>;
  /** "MetadataCiEmailWork.archive"'s inverse relation count */
  metadataCiEmailWorkListCount: Scalars['Int']['output'];
  /** "MetadataCiTelWork.archive"'s inverse relation */
  metadataCiTelWorkList: Array<MetadataCiTelWork>;
  /** "MetadataCiTelWork.archive"'s inverse relation count */
  metadataCiTelWorkListCount: Scalars['Int']['output'];
  /** "MetadataCity.archive"'s inverse relation */
  metadataCityList: Array<MetadataCity>;
  /** "MetadataCity.archive"'s inverse relation count */
  metadataCityListCount: Scalars['Int']['output'];
  /** "MetadataCiUrlWork.archive"'s inverse relation */
  metadataCiUrlWorkList: Array<MetadataCiUrlWork>;
  /** "MetadataCiUrlWork.archive"'s inverse relation count */
  metadataCiUrlWorkListCount: Scalars['Int']['output'];
  /** "MetadataClassify.archive"'s inverse relation */
  metadataClassifyList: Array<MetadataClassify>;
  /** "MetadataClassify.archive"'s inverse relation count */
  metadataClassifyListCount: Scalars['Int']['output'];
  /** "MetadataCompany.archive"'s inverse relation */
  metadataCompanyList: Array<MetadataCompany>;
  /** "MetadataCompany.archive"'s inverse relation count */
  metadataCompanyListCount: Scalars['Int']['output'];
  /** "MetadataCompanyShortName.archive"'s inverse relation */
  metadataCompanyShortNameList: Array<MetadataCompanyShortName>;
  /** "MetadataCompanyShortName.archive"'s inverse relation count */
  metadataCompanyShortNameListCount: Scalars['Int']['output'];
  /** "MetadataConfidentiality.archive"'s inverse relation */
  metadataConfidentialityList: Array<MetadataConfidentiality>;
  /** "MetadataConfidentiality.archive"'s inverse relation count */
  metadataConfidentialityListCount: Scalars['Int']['output'];
  /** "MetadataContainedIn.archive"'s inverse relation */
  metadataContainedInList: Array<MetadataContainedIn>;
  /** "MetadataContainedIn.archive"'s inverse relation count */
  metadataContainedInListCount: Scalars['Int']['output'];
  /** "MetadataContentMediaKind.archive"'s inverse relation */
  metadataContentMediaKindList: Array<MetadataContentMediaKind>;
  /** "MetadataContentMediaKind.archive"'s inverse relation count */
  metadataContentMediaKindListCount: Scalars['Int']['output'];
  /** "MetadataContentValue.archive"'s inverse relation */
  metadataContentValueList: Array<MetadataContentValue>;
  /** "MetadataContentValue.archive"'s inverse relation count */
  metadataContentValueListCount: Scalars['Int']['output'];
  /** "MetadataCopyrightLayer.archive"'s inverse relation */
  metadataCopyrightLayerList: Array<MetadataCopyrightLayer>;
  /** "MetadataCopyrightLayer.archive"'s inverse relation count */
  metadataCopyrightLayerListCount: Scalars['Int']['output'];
  /** "MetadataCountryCode.archive"'s inverse relation */
  metadataCountryCodeList: Array<MetadataCountryCode>;
  /** "MetadataCountryCode.archive"'s inverse relation count */
  metadataCountryCodeListCount: Scalars['Int']['output'];
  /** "MetadataCountry.archive"'s inverse relation */
  metadataCountryList: Array<MetadataCountry>;
  /** "MetadataCountry.archive"'s inverse relation count */
  metadataCountryListCount: Scalars['Int']['output'];
  /** "MetadataCreatedTime.archive"'s inverse relation */
  metadataCreatedTimeList: Array<MetadataCreatedTime>;
  /** "MetadataCreatedTime.archive"'s inverse relation count */
  metadataCreatedTimeListCount: Scalars['Int']['output'];
  /** "MetadataCreator.archive"'s inverse relation */
  metadataCreatorList: Array<MetadataCreator>;
  /** "MetadataCreator.archive"'s inverse relation count */
  metadataCreatorListCount: Scalars['Int']['output'];
  /** "MetadataCreatorTool.archive"'s inverse relation */
  metadataCreatorToolList: Array<MetadataCreatorTool>;
  /** "MetadataCreatorTool.archive"'s inverse relation count */
  metadataCreatorToolListCount: Scalars['Int']['output'];
  /** "MetadataCredit.archive"'s inverse relation */
  metadataCreditList: Array<MetadataCredit>;
  /** "MetadataCredit.archive"'s inverse relation count */
  metadataCreditListCount: Scalars['Int']['output'];
  /** "MetadataCustomField14.archive"'s inverse relation */
  metadataCustomField14List: Array<MetadataCustomField14>;
  /** "MetadataCustomField14.archive"'s inverse relation count */
  metadataCustomField14ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField15.archive"'s inverse relation */
  metadataCustomField15List: Array<MetadataCustomField15>;
  /** "MetadataCustomField15.archive"'s inverse relation count */
  metadataCustomField15ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField16.archive"'s inverse relation */
  metadataCustomField16List: Array<MetadataCustomField16>;
  /** "MetadataCustomField16.archive"'s inverse relation count */
  metadataCustomField16ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField17.archive"'s inverse relation */
  metadataCustomField17List: Array<MetadataCustomField17>;
  /** "MetadataCustomField17.archive"'s inverse relation count */
  metadataCustomField17ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField20.archive"'s inverse relation */
  metadataCustomField20List: Array<MetadataCustomField20>;
  /** "MetadataCustomField20.archive"'s inverse relation count */
  metadataCustomField20ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField5.archive"'s inverse relation */
  metadataCustomField5List: Array<MetadataCustomField5>;
  /** "MetadataCustomField5.archive"'s inverse relation count */
  metadataCustomField5ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField6.archive"'s inverse relation */
  metadataCustomField6List: Array<MetadataCustomField6>;
  /** "MetadataCustomField6.archive"'s inverse relation count */
  metadataCustomField6ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField7.archive"'s inverse relation */
  metadataCustomField7List: Array<MetadataCustomField7>;
  /** "MetadataCustomField7.archive"'s inverse relation count */
  metadataCustomField7ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField9.archive"'s inverse relation */
  metadataCustomField9List: Array<MetadataCustomField9>;
  /** "MetadataCustomField9.archive"'s inverse relation count */
  metadataCustomField9ListCount: Scalars['Int']['output'];
  /** "MetadataDateCreated.archive"'s inverse relation */
  metadataDateCreatedList: Array<MetadataDateCreated>;
  /** "MetadataDateCreated.archive"'s inverse relation count */
  metadataDateCreatedListCount: Scalars['Int']['output'];
  /** "MetadataDateTimeDigitized.archive"'s inverse relation */
  metadataDateTimeDigitizedList: Array<MetadataDateTimeDigitized>;
  /** "MetadataDateTimeDigitized.archive"'s inverse relation count */
  metadataDateTimeDigitizedListCount: Scalars['Int']['output'];
  /** "MetadataDateTimeOriginal.archive"'s inverse relation */
  metadataDateTimeOriginalList: Array<MetadataDateTimeOriginal>;
  /** "MetadataDateTimeOriginal.archive"'s inverse relation count */
  metadataDateTimeOriginalListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryAccount.archive"'s inverse relation */
  metadataDeliveryAccountList: Array<MetadataDeliveryAccount>;
  /** "MetadataDeliveryAccount.archive"'s inverse relation count */
  metadataDeliveryAccountListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryCompany.archive"'s inverse relation */
  metadataDeliveryCompanyList: Array<MetadataDeliveryCompany>;
  /** "MetadataDeliveryCompany.archive"'s inverse relation count */
  metadataDeliveryCompanyListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryCopyright.archive"'s inverse relation */
  metadataDeliveryCopyrightList: Array<MetadataDeliveryCopyright>;
  /** "MetadataDeliveryCopyright.archive"'s inverse relation count */
  metadataDeliveryCopyrightListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDateFolder.archive"'s inverse relation */
  metadataDeliveryDateFolderList: Array<MetadataDeliveryDateFolder>;
  /** "MetadataDeliveryDateFolder.archive"'s inverse relation count */
  metadataDeliveryDateFolderListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDateTime.archive"'s inverse relation */
  metadataDeliveryDateTimeList: Array<MetadataDeliveryDateTime>;
  /** "MetadataDeliveryDateTime.archive"'s inverse relation count */
  metadataDeliveryDateTimeListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDcCreator.archive"'s inverse relation */
  metadataDeliveryDcCreatorList: Array<MetadataDeliveryDcCreator>;
  /** "MetadataDeliveryDcCreator.archive"'s inverse relation count */
  metadataDeliveryDcCreatorListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDcRights.archive"'s inverse relation */
  metadataDeliveryDcRightsList: Array<MetadataDeliveryDcRights>;
  /** "MetadataDeliveryDcRights.archive"'s inverse relation count */
  metadataDeliveryDcRightsListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryFileType.archive"'s inverse relation */
  metadataDeliveryFileTypeList: Array<MetadataDeliveryFileType>;
  /** "MetadataDeliveryFileType.archive"'s inverse relation count */
  metadataDeliveryFileTypeListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryFolder.archive"'s inverse relation */
  metadataDeliveryFolderList: Array<MetadataDeliveryFolder>;
  /** "MetadataDeliveryFolder.archive"'s inverse relation count */
  metadataDeliveryFolderListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryKind.archive"'s inverse relation */
  metadataDeliveryKindList: Array<MetadataDeliveryKind>;
  /** "MetadataDeliveryKind.archive"'s inverse relation count */
  metadataDeliveryKindListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPath.archive"'s inverse relation */
  metadataDeliveryPathList: Array<MetadataDeliveryPath>;
  /** "MetadataDeliveryPath.archive"'s inverse relation count */
  metadataDeliveryPathListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPersonShownintheImage.archive"'s inverse relation */
  metadataDeliveryPersonShownintheImageList: Array<MetadataDeliveryPersonShownintheImage>;
  /** "MetadataDeliveryPersonShownintheImage.archive"'s inverse relation count */
  metadataDeliveryPersonShownintheImageListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPhotoshopCredit.archive"'s inverse relation */
  metadataDeliveryPhotoshopCreditList: Array<MetadataDeliveryPhotoshopCredit>;
  /** "MetadataDeliveryPhotoshopCredit.archive"'s inverse relation count */
  metadataDeliveryPhotoshopCreditListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPhotoshopSource.archive"'s inverse relation */
  metadataDeliveryPhotoshopSourceList: Array<MetadataDeliveryPhotoshopSource>;
  /** "MetadataDeliveryPhotoshopSource.archive"'s inverse relation count */
  metadataDeliveryPhotoshopSourceListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryService.archive"'s inverse relation */
  metadataDeliveryServiceList: Array<MetadataDeliveryService>;
  /** "MetadataDeliveryService.archive"'s inverse relation count */
  metadataDeliveryServiceListCount: Scalars['Int']['output'];
  /** "MetadataDeliverySubject.archive"'s inverse relation */
  metadataDeliverySubjectList: Array<MetadataDeliverySubject>;
  /** "MetadataDeliverySubject.archive"'s inverse relation count */
  metadataDeliverySubjectListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryUnderSubject.archive"'s inverse relation */
  metadataDeliveryUnderSubjectList: Array<MetadataDeliveryUnderSubject>;
  /** "MetadataDeliveryUnderSubject.archive"'s inverse relation count */
  metadataDeliveryUnderSubjectListCount: Scalars['Int']['output'];
  /** "MetadataDepartment.archive"'s inverse relation */
  metadataDepartmentList: Array<MetadataDepartment>;
  /** "MetadataDepartment.archive"'s inverse relation count */
  metadataDepartmentListCount: Scalars['Int']['output'];
  /** "MetadataDescription.archive"'s inverse relation */
  metadataDescriptionList: Array<MetadataDescription>;
  /** "MetadataDescription.archive"'s inverse relation count */
  metadataDescriptionListCount: Scalars['Int']['output'];
  /** "MetadataDigitalAssetUrl.archive"'s inverse relation */
  metadataDigitalAssetUrlList: Array<MetadataDigitalAssetUrl>;
  /** "MetadataDigitalAssetUrl.archive"'s inverse relation count */
  metadataDigitalAssetUrlListCount: Scalars['Int']['output'];
  /** "MetadataDivision.archive"'s inverse relation */
  metadataDivisionList: Array<MetadataDivision>;
  /** "MetadataDivision.archive"'s inverse relation count */
  metadataDivisionListCount: Scalars['Int']['output'];
  /** "MetadataDocumentText.archive"'s inverse relation */
  metadataDocumentTextList: Array<MetadataDocumentText>;
  /** "MetadataDocumentText.archive"'s inverse relation count */
  metadataDocumentTextListCount: Scalars['Int']['output'];
  /** "MetadataEdition.archive"'s inverse relation */
  metadataEditionList: Array<MetadataEdition>;
  /** "MetadataEdition.archive"'s inverse relation count */
  metadataEditionListCount: Scalars['Int']['output'];
  /** "MetadataEditorialVersion.archive"'s inverse relation */
  metadataEditorialVersionList: Array<MetadataEditorialVersion>;
  /** "MetadataEditorialVersion.archive"'s inverse relation count */
  metadataEditorialVersionListCount: Scalars['Int']['output'];
  /** "MetadataEditStatus.archive"'s inverse relation */
  metadataEditStatusList: Array<MetadataEditStatus>;
  /** "MetadataEditStatus.archive"'s inverse relation count */
  metadataEditStatusListCount: Scalars['Int']['output'];
  /** "MetadataEnvironnementPhoto.archive"'s inverse relation */
  metadataEnvironnementPhotoList: Array<MetadataEnvironnementPhoto>;
  /** "MetadataEnvironnementPhoto.archive"'s inverse relation count */
  metadataEnvironnementPhotoListCount: Scalars['Int']['output'];
  /** "MetadataEquipmentInstitution.archive"'s inverse relation */
  metadataEquipmentInstitutionList: Array<MetadataEquipmentInstitution>;
  /** "MetadataEquipmentInstitution.archive"'s inverse relation count */
  metadataEquipmentInstitutionListCount: Scalars['Int']['output'];
  /** "MetadataEquipmentManufacturer.archive"'s inverse relation */
  metadataEquipmentManufacturerList: Array<MetadataEquipmentManufacturer>;
  /** "MetadataEquipmentManufacturer.archive"'s inverse relation count */
  metadataEquipmentManufacturerListCount: Scalars['Int']['output'];
  /** "MetadataEvent.archive"'s inverse relation */
  metadataEventList: Array<MetadataEvent>;
  /** "MetadataEvent.archive"'s inverse relation count */
  metadataEventListCount: Scalars['Int']['output'];
  /** "MetadataExif.archive"'s inverse relation */
  metadataExifList: Array<MetadataExif>;
  /** "MetadataExif.archive"'s inverse relation count */
  metadataExifListCount: Scalars['Int']['output'];
  /** "MetadataFabStorage.archive"'s inverse relation */
  metadataFabStorageList: Array<MetadataFabStorage>;
  /** "MetadataFabStorage.archive"'s inverse relation count */
  metadataFabStorageListCount: Scalars['Int']['output'];
  /** "MetadataFileCheckFor.archive"'s inverse relation */
  metadataFileCheckForList: Array<MetadataFileCheckFor>;
  /** "MetadataFileCheckFor.archive"'s inverse relation count */
  metadataFileCheckForListCount: Scalars['Int']['output'];
  /** "MetadataFileInfoBitsPerPixel.archive"'s inverse relation */
  metadataFileInfoBitsPerPixelList: Array<MetadataFileInfoBitsPerPixel>;
  /** "MetadataFileInfoBitsPerPixel.archive"'s inverse relation count */
  metadataFileInfoBitsPerPixelListCount: Scalars['Int']['output'];
  /** "MetadataFileInfoFrames.archive"'s inverse relation */
  metadataFileInfoFramesList: Array<MetadataFileInfoFrames>;
  /** "MetadataFileInfoFrames.archive"'s inverse relation count */
  metadataFileInfoFramesListCount: Scalars['Int']['output'];
  /** "MetadataFileInfoPhotometricInterpretation.archive"'s inverse relation */
  metadataFileInfoPhotometricInterpretationList: Array<MetadataFileInfoPhotometricInterpretation>;
  /** "MetadataFileInfoPhotometricInterpretation.archive"'s inverse relation count */
  metadataFileInfoPhotometricInterpretationListCount: Scalars['Int']['output'];
  /** "MetadataFileInfoTransferSyntax.archive"'s inverse relation */
  metadataFileInfoTransferSyntaxList: Array<MetadataFileInfoTransferSyntax>;
  /** "MetadataFileInfoTransferSyntax.archive"'s inverse relation count */
  metadataFileInfoTransferSyntaxListCount: Scalars['Int']['output'];
  /** "MetadataFileStage.archive"'s inverse relation */
  metadataFileStageList: Array<MetadataFileStage>;
  /** "MetadataFileStage.archive"'s inverse relation count */
  metadataFileStageListCount: Scalars['Int']['output'];
  /** "MetadataFileStatus.archive"'s inverse relation */
  metadataFileStatusList: Array<MetadataFileStatus>;
  /** "MetadataFileStatus.archive"'s inverse relation count */
  metadataFileStatusListCount: Scalars['Int']['output'];
  /** "MetadataFileType.archive"'s inverse relation */
  metadataFileTypeList: Array<MetadataFileType>;
  /** "MetadataFileType.archive"'s inverse relation count */
  metadataFileTypeListCount: Scalars['Int']['output'];
  /** "MetadataFixtureIdentifier.archive"'s inverse relation */
  metadataFixtureIdentifierList: Array<MetadataFixtureIdentifier>;
  /** "MetadataFixtureIdentifier.archive"'s inverse relation count */
  metadataFixtureIdentifierListCount: Scalars['Int']['output'];
  /** "MetadataFormat.archive"'s inverse relation */
  metadataFormatList: Array<MetadataFormat>;
  /** "MetadataFormat.archive"'s inverse relation count */
  metadataFormatListCount: Scalars['Int']['output'];
  /** "MetadataGpsLatitude.archive"'s inverse relation */
  metadataGpsLatitudeList: Array<MetadataGpsLatitude>;
  /** "MetadataGpsLatitude.archive"'s inverse relation count */
  metadataGpsLatitudeListCount: Scalars['Int']['output'];
  /** "MetadataGpsLongitude.archive"'s inverse relation */
  metadataGpsLongitudeList: Array<MetadataGpsLongitude>;
  /** "MetadataGpsLongitude.archive"'s inverse relation count */
  metadataGpsLongitudeListCount: Scalars['Int']['output'];
  /** "MetadataGpsProcessingMethod.archive"'s inverse relation */
  metadataGpsProcessingMethodList: Array<MetadataGpsProcessingMethod>;
  /** "MetadataGpsProcessingMethod.archive"'s inverse relation count */
  metadataGpsProcessingMethodListCount: Scalars['Int']['output'];
  /** "MetadataHeader.archive"'s inverse relation */
  metadataHeaderList: Array<MetadataHeader>;
  /** "MetadataHeader.archive"'s inverse relation count */
  metadataHeaderListCount: Scalars['Int']['output'];
  /** "MetadataHeaderPrisma.archive"'s inverse relation */
  metadataHeaderPrismaList: Array<MetadataHeaderPrisma>;
  /** "MetadataHeaderPrisma.archive"'s inverse relation count */
  metadataHeaderPrismaListCount: Scalars['Int']['output'];
  /** "MetadataHeadline.archive"'s inverse relation */
  metadataHeadlineList: Array<MetadataHeadline>;
  /** "MetadataHeadline.archive"'s inverse relation count */
  metadataHeadlineListCount: Scalars['Int']['output'];
  /** "MetadataHistory.archive"'s inverse relation */
  metadataHistoryList: Array<MetadataHistory>;
  /** "MetadataHistory.archive"'s inverse relation count */
  metadataHistoryListCount: Scalars['Int']['output'];
  /** "MetadataIccProfile.archive"'s inverse relation */
  metadataIccProfileList: Array<MetadataIccProfile>;
  /** "MetadataIccProfile.archive"'s inverse relation count */
  metadataIccProfileListCount: Scalars['Int']['output'];
  /** "MetadataIdAssignment.archive"'s inverse relation */
  metadataIdAssignmentList: Array<MetadataIdAssignment>;
  /** "MetadataIdAssignment.archive"'s inverse relation count */
  metadataIdAssignmentListCount: Scalars['Int']['output'];
  /** "MetadataIdMediaContent.archive"'s inverse relation */
  metadataIdMediaContentList: Array<MetadataIdMediaContent>;
  /** "MetadataIdMediaContent.archive"'s inverse relation count */
  metadataIdMediaContentListCount: Scalars['Int']['output'];
  /** "MetadataIdPhotoPrisma.archive"'s inverse relation */
  metadataIdPhotoPrismaList: Array<MetadataIdPhotoPrisma>;
  /** "MetadataIdPhotoPrisma.archive"'s inverse relation count */
  metadataIdPhotoPrismaListCount: Scalars['Int']['output'];
  /** "MetadataIdSubject.archive"'s inverse relation */
  metadataIdSubjectList: Array<MetadataIdSubject>;
  /** "MetadataIdSubject.archive"'s inverse relation count */
  metadataIdSubjectListCount: Scalars['Int']['output'];
  /** "MetadataImageNotes.archive"'s inverse relation */
  metadataImageNotesList: Array<MetadataImageNotes>;
  /** "MetadataImageNotes.archive"'s inverse relation count */
  metadataImageNotesListCount: Scalars['Int']['output'];
  /** "MetadataImageUniqueId.archive"'s inverse relation */
  metadataImageUniqueIdList: Array<MetadataImageUniqueId>;
  /** "MetadataImageUniqueId.archive"'s inverse relation count */
  metadataImageUniqueIdListCount: Scalars['Int']['output'];
  /** "MetadataInstructions.archive"'s inverse relation */
  metadataInstructionsList: Array<MetadataInstructions>;
  /** "MetadataInstructions.archive"'s inverse relation count */
  metadataInstructionsListCount: Scalars['Int']['output'];
  /** "MetadataIntellectualGenre.archive"'s inverse relation */
  metadataIntellectualGenreList: Array<MetadataIntellectualGenre>;
  /** "MetadataIntellectualGenre.archive"'s inverse relation count */
  metadataIntellectualGenreListCount: Scalars['Int']['output'];
  /** "MetadataIsoSpeedRatings.archive"'s inverse relation */
  metadataIsoSpeedRatingsList: Array<MetadataIsoSpeedRatings>;
  /** "MetadataIsoSpeedRatings.archive"'s inverse relation count */
  metadataIsoSpeedRatingsListCount: Scalars['Int']['output'];
  /** "MetadataIssue.archive"'s inverse relation */
  metadataIssueList: Array<MetadataIssue>;
  /** "MetadataIssue.archive"'s inverse relation count */
  metadataIssueListCount: Scalars['Int']['output'];
  /** "MetadataJobId.archive"'s inverse relation */
  metadataJobIdList: Array<MetadataJobId>;
  /** "MetadataJobId.archive"'s inverse relation count */
  metadataJobIdListCount: Scalars['Int']['output'];
  /** "MetadataJobProcessing.archive"'s inverse relation */
  metadataJobProcessingList: Array<MetadataJobProcessing>;
  /** "MetadataJobProcessing.archive"'s inverse relation count */
  metadataJobProcessingListCount: Scalars['Int']['output'];
  /** "MetadataLayoutPictureEditing.archive"'s inverse relation */
  metadataLayoutPictureEditingList: Array<MetadataLayoutPictureEditing>;
  /** "MetadataLayoutPictureEditing.archive"'s inverse relation count */
  metadataLayoutPictureEditingListCount: Scalars['Int']['output'];
  /** "MetadataLayoutStorage.archive"'s inverse relation */
  metadataLayoutStorageList: Array<MetadataLayoutStorage>;
  /** "MetadataLayoutStorage.archive"'s inverse relation count */
  metadataLayoutStorageListCount: Scalars['Int']['output'];
  /** "MetadataLicense.archive"'s inverse relation */
  metadataLicenseList: Array<MetadataLicense>;
  /** "MetadataLicense.archive"'s inverse relation count */
  metadataLicenseListCount: Scalars['Int']['output'];
  /** "MetadataLinks.archive"'s inverse relation */
  metadataLinksList: Array<MetadataLinks>;
  /** "MetadataLinks.archive"'s inverse relation count */
  metadataLinksListCount: Scalars['Int']['output'];
  /** "MetadataLocalCaption.archive"'s inverse relation */
  metadataLocalCaptionList: Array<MetadataLocalCaption>;
  /** "MetadataLocalCaption.archive"'s inverse relation count */
  metadataLocalCaptionListCount: Scalars['Int']['output'];
  /** "MetadataLocation.archive"'s inverse relation */
  metadataLocationList: Array<MetadataLocation>;
  /** "MetadataLocation.archive"'s inverse relation count */
  metadataLocationListCount: Scalars['Int']['output'];
  /** "MetadataMake.archive"'s inverse relation */
  metadataMakeList: Array<MetadataMake>;
  /** "MetadataMake.archive"'s inverse relation count */
  metadataMakeListCount: Scalars['Int']['output'];
  /** "MetadataManifest.archive"'s inverse relation */
  metadataManifestList: Array<MetadataManifest>;
  /** "MetadataManifest.archive"'s inverse relation count */
  metadataManifestListCount: Scalars['Int']['output'];
  /** "MetadataMarked.archive"'s inverse relation */
  metadataMarkedList: Array<MetadataMarked>;
  /** "MetadataMarked.archive"'s inverse relation count */
  metadataMarkedListCount: Scalars['Int']['output'];
  /** "MetadataMasterDocumentId.archive"'s inverse relation */
  metadataMasterDocumentIdList: Array<MetadataMasterDocumentId>;
  /** "MetadataMasterDocumentId.archive"'s inverse relation count */
  metadataMasterDocumentIdListCount: Scalars['Int']['output'];
  /** "MetadataMaxAvailHeight.archive"'s inverse relation */
  metadataMaxAvailHeightList: Array<MetadataMaxAvailHeight>;
  /** "MetadataMaxAvailHeight.archive"'s inverse relation count */
  metadataMaxAvailHeightListCount: Scalars['Int']['output'];
  /** "MetadataMaxAvailWidth.archive"'s inverse relation */
  metadataMaxAvailWidthList: Array<MetadataMaxAvailWidth>;
  /** "MetadataMaxAvailWidth.archive"'s inverse relation count */
  metadataMaxAvailWidthListCount: Scalars['Int']['output'];
  /** "MetadataMinorModelAgeDisclosure.archive"'s inverse relation */
  metadataMinorModelAgeDisclosureList: Array<MetadataMinorModelAgeDisclosure>;
  /** "MetadataMinorModelAgeDisclosure.archive"'s inverse relation count */
  metadataMinorModelAgeDisclosureListCount: Scalars['Int']['output'];
  /** "MetadataModelAge.archive"'s inverse relation */
  metadataModelAgeList: Array<MetadataModelAge>;
  /** "MetadataModelAge.archive"'s inverse relation count */
  metadataModelAgeListCount: Scalars['Int']['output'];
  /** "MetadataModel.archive"'s inverse relation */
  metadataModelList: Array<MetadataModel>;
  /** "MetadataModel.archive"'s inverse relation count */
  metadataModelListCount: Scalars['Int']['output'];
  /** "MetadataModelReleaseId.archive"'s inverse relation */
  metadataModelReleaseIdList: Array<MetadataModelReleaseId>;
  /** "MetadataModelReleaseId.archive"'s inverse relation count */
  metadataModelReleaseIdListCount: Scalars['Int']['output'];
  /** "MetadataModelReleaseStatus.archive"'s inverse relation */
  metadataModelReleaseStatusList: Array<MetadataModelReleaseStatus>;
  /** "MetadataModelReleaseStatus.archive"'s inverse relation count */
  metadataModelReleaseStatusListCount: Scalars['Int']['output'];
  /** "MetadataMorePermissions.archive"'s inverse relation */
  metadataMorePermissionsList: Array<MetadataMorePermissions>;
  /** "MetadataMorePermissions.archive"'s inverse relation count */
  metadataMorePermissionsListCount: Scalars['Int']['output'];
  /** "MetadataObjectCycle.archive"'s inverse relation */
  metadataObjectCycleList: Array<MetadataObjectCycle>;
  /** "MetadataObjectCycle.archive"'s inverse relation count */
  metadataObjectCycleListCount: Scalars['Int']['output'];
  /** "MetadataOrganisationInImageName.archive"'s inverse relation */
  metadataOrganisationInImageNameList: Array<MetadataOrganisationInImageName>;
  /** "MetadataOrganisationInImageName.archive"'s inverse relation count */
  metadataOrganisationInImageNameListCount: Scalars['Int']['output'];
  /** "MetadataOriginalColor.archive"'s inverse relation */
  metadataOriginalColorList: Array<MetadataOriginalColor>;
  /** "MetadataOriginalColor.archive"'s inverse relation count */
  metadataOriginalColorListCount: Scalars['Int']['output'];
  /** "MetadataOriginalIccProfile.archive"'s inverse relation */
  metadataOriginalIccProfileList: Array<MetadataOriginalIccProfile>;
  /** "MetadataOriginalIccProfile.archive"'s inverse relation count */
  metadataOriginalIccProfileListCount: Scalars['Int']['output'];
  /** "MetadataOriginalName.archive"'s inverse relation */
  metadataOriginalNameList: Array<MetadataOriginalName>;
  /** "MetadataOriginalName.archive"'s inverse relation count */
  metadataOriginalNameListCount: Scalars['Int']['output'];
  /** "MetadataOriginalWeigth.archive"'s inverse relation */
  metadataOriginalWeigthList: Array<MetadataOriginalWeigth>;
  /** "MetadataOriginalWeigth.archive"'s inverse relation count */
  metadataOriginalWeigthListCount: Scalars['Int']['output'];
  /** "MetadataOwnerId.archive"'s inverse relation */
  metadataOwnerIdList: Array<MetadataOwnerId>;
  /** "MetadataOwnerId.archive"'s inverse relation count */
  metadataOwnerIdListCount: Scalars['Int']['output'];
  /** "MetadataOwner.archive"'s inverse relation */
  metadataOwnerList: Array<MetadataOwner>;
  /** "MetadataOwner.archive"'s inverse relation count */
  metadataOwnerListCount: Scalars['Int']['output'];
  /** "MetadataPage.archive"'s inverse relation */
  metadataPageList: Array<MetadataPage>;
  /** "MetadataPage.archive"'s inverse relation count */
  metadataPageListCount: Scalars['Int']['output'];
  /** "MetadataPagePrisma.archive"'s inverse relation */
  metadataPagePrismaList: Array<MetadataPagePrisma>;
  /** "MetadataPagePrisma.archive"'s inverse relation count */
  metadataPagePrismaListCount: Scalars['Int']['output'];
  /** "MetadataPaintBasedCorrections.archive"'s inverse relation */
  metadataPaintBasedCorrectionsList: Array<MetadataPaintBasedCorrections>;
  /** "MetadataPaintBasedCorrections.archive"'s inverse relation count */
  metadataPaintBasedCorrectionsListCount: Scalars['Int']['output'];
  /** "MetadataPatientDob.archive"'s inverse relation */
  metadataPatientDobList: Array<MetadataPatientDob>;
  /** "MetadataPatientDob.archive"'s inverse relation count */
  metadataPatientDobListCount: Scalars['Int']['output'];
  /** "MetadataPatientId.archive"'s inverse relation */
  metadataPatientIdList: Array<MetadataPatientId>;
  /** "MetadataPatientId.archive"'s inverse relation count */
  metadataPatientIdListCount: Scalars['Int']['output'];
  /** "MetadataPatientName.archive"'s inverse relation */
  metadataPatientNameList: Array<MetadataPatientName>;
  /** "MetadataPatientName.archive"'s inverse relation count */
  metadataPatientNameListCount: Scalars['Int']['output'];
  /** "MetadataPatientSex.archive"'s inverse relation */
  metadataPatientSexList: Array<MetadataPatientSex>;
  /** "MetadataPatientSex.archive"'s inverse relation count */
  metadataPatientSexListCount: Scalars['Int']['output'];
  /** "MetadataPersonInImage.archive"'s inverse relation */
  metadataPersonInImageList: Array<MetadataPersonInImage>;
  /** "MetadataPersonInImage.archive"'s inverse relation count */
  metadataPersonInImageListCount: Scalars['Int']['output'];
  /** "MetadataPhotoStorage.archive"'s inverse relation */
  metadataPhotoStorageList: Array<MetadataPhotoStorage>;
  /** "MetadataPhotoStorage.archive"'s inverse relation count */
  metadataPhotoStorageListCount: Scalars['Int']['output'];
  /** "MetadataPrepressPictureEditing.archive"'s inverse relation */
  metadataPrepressPictureEditingList: Array<MetadataPrepressPictureEditing>;
  /** "MetadataPrepressPictureEditing.archive"'s inverse relation count */
  metadataPrepressPictureEditingListCount: Scalars['Int']['output'];
  /** "MetadataPriceLevel.archive"'s inverse relation */
  metadataPriceLevelList: Array<MetadataPriceLevel>;
  /** "MetadataPriceLevel.archive"'s inverse relation count */
  metadataPriceLevelListCount: Scalars['Int']['output'];
  /** "MetadataPrintingProfile.archive"'s inverse relation */
  metadataPrintingProfileList: Array<MetadataPrintingProfile>;
  /** "MetadataPrintingProfile.archive"'s inverse relation count */
  metadataPrintingProfileListCount: Scalars['Int']['output'];
  /** "MetadataPrismaProduction.archive"'s inverse relation */
  metadataPrismaProductionList: Array<MetadataPrismaProduction>;
  /** "MetadataPrismaProduction.archive"'s inverse relation count */
  metadataPrismaProductionListCount: Scalars['Int']['output'];
  /** "MetadataProcessHistory.archive"'s inverse relation */
  metadataProcessHistoryList: Array<MetadataProcessHistory>;
  /** "MetadataProcessHistory.archive"'s inverse relation count */
  metadataProcessHistoryListCount: Scalars['Int']['output'];
  /** "MetadataProcessParameter.archive"'s inverse relation */
  metadataProcessParameterList: Array<MetadataProcessParameter>;
  /** "MetadataProcessParameter.archive"'s inverse relation count */
  metadataProcessParameterListCount: Scalars['Int']['output'];
  /** "MetadataProcessStatus.archive"'s inverse relation */
  metadataProcessStatusList: Array<MetadataProcessStatus>;
  /** "MetadataProcessStatus.archive"'s inverse relation count */
  metadataProcessStatusListCount: Scalars['Int']['output'];
  /** "MetadataProduct.archive"'s inverse relation */
  metadataProductList: Array<MetadataProduct>;
  /** "MetadataProduct.archive"'s inverse relation count */
  metadataProductListCount: Scalars['Int']['output'];
  /** "MetadataProductShortName.archive"'s inverse relation */
  metadataProductShortNameList: Array<MetadataProductShortName>;
  /** "MetadataProductShortName.archive"'s inverse relation count */
  metadataProductShortNameListCount: Scalars['Int']['output'];
  /** "MetadataProducts.archive"'s inverse relation */
  metadataProductsList: Array<MetadataProducts>;
  /** "MetadataProducts.archive"'s inverse relation count */
  metadataProductsListCount: Scalars['Int']['output'];
  /** "MetadataProduits.archive"'s inverse relation */
  metadataProduitsList: Array<MetadataProduits>;
  /** "MetadataProduits.archive"'s inverse relation count */
  metadataProduitsListCount: Scalars['Int']['output'];
  /** "MetadataProgramVersion.archive"'s inverse relation */
  metadataProgramVersionList: Array<MetadataProgramVersion>;
  /** "MetadataProgramVersion.archive"'s inverse relation count */
  metadataProgramVersionListCount: Scalars['Int']['output'];
  /** "MetadataPropertyReleaseId.archive"'s inverse relation */
  metadataPropertyReleaseIdList: Array<MetadataPropertyReleaseId>;
  /** "MetadataPropertyReleaseId.archive"'s inverse relation count */
  metadataPropertyReleaseIdListCount: Scalars['Int']['output'];
  /** "MetadataPropertyReleaseStatus.archive"'s inverse relation */
  metadataPropertyReleaseStatusList: Array<MetadataPropertyReleaseStatus>;
  /** "MetadataPropertyReleaseStatus.archive"'s inverse relation count */
  metadataPropertyReleaseStatusListCount: Scalars['Int']['output'];
  /** "MetadataPublisher.archive"'s inverse relation */
  metadataPublisherList: Array<MetadataPublisher>;
  /** "MetadataPublisher.archive"'s inverse relation count */
  metadataPublisherListCount: Scalars['Int']['output'];
  /** "MetadataPublishingIssue.archive"'s inverse relation */
  metadataPublishingIssueList: Array<MetadataPublishingIssue>;
  /** "MetadataPublishingIssue.archive"'s inverse relation count */
  metadataPublishingIssueListCount: Scalars['Int']['output'];
  /** "MetadataPublishingSubject.archive"'s inverse relation */
  metadataPublishingSubjectList: Array<MetadataPublishingSubject>;
  /** "MetadataPublishingSubject.archive"'s inverse relation count */
  metadataPublishingSubjectListCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaBy.archive"'s inverse relation */
  metadataQualifiedUsePrismaByList: Array<MetadataQualifiedUsePrismaBy>;
  /** "MetadataQualifiedUsePrismaBy.archive"'s inverse relation count */
  metadataQualifiedUsePrismaByListCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaDate.archive"'s inverse relation */
  metadataQualifiedUsePrismaDateList: Array<MetadataQualifiedUsePrismaDate>;
  /** "MetadataQualifiedUsePrismaDate.archive"'s inverse relation count */
  metadataQualifiedUsePrismaDateListCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaDuration.archive"'s inverse relation */
  metadataQualifiedUsePrismaDurationList: Array<MetadataQualifiedUsePrismaDuration>;
  /** "MetadataQualifiedUsePrismaDuration.archive"'s inverse relation count */
  metadataQualifiedUsePrismaDurationListCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaSupport.archive"'s inverse relation */
  metadataQualifiedUsePrismaSupportList: Array<MetadataQualifiedUsePrismaSupport>;
  /** "MetadataQualifiedUsePrismaSupport.archive"'s inverse relation count */
  metadataQualifiedUsePrismaSupportListCount: Scalars['Int']['output'];
  /** "MetadataRating.archive"'s inverse relation */
  metadataRatingList: Array<MetadataRating>;
  /** "MetadataRating.archive"'s inverse relation count */
  metadataRatingListCount: Scalars['Int']['output'];
  /** "MetadataReferenceDate.archive"'s inverse relation */
  metadataReferenceDateList: Array<MetadataReferenceDate>;
  /** "MetadataReferenceDate.archive"'s inverse relation count */
  metadataReferenceDateListCount: Scalars['Int']['output'];
  /** "MetadataReferenceNumber.archive"'s inverse relation */
  metadataReferenceNumberList: Array<MetadataReferenceNumber>;
  /** "MetadataReferenceNumber.archive"'s inverse relation count */
  metadataReferenceNumberListCount: Scalars['Int']['output'];
  /** "MetadataReferenceService.archive"'s inverse relation */
  metadataReferenceServiceList: Array<MetadataReferenceService>;
  /** "MetadataReferenceService.archive"'s inverse relation count */
  metadataReferenceServiceListCount: Scalars['Int']['output'];
  /** "MetadataReleaseDate.archive"'s inverse relation */
  metadataReleaseDateList: Array<MetadataReleaseDate>;
  /** "MetadataReleaseDate.archive"'s inverse relation count */
  metadataReleaseDateListCount: Scalars['Int']['output'];
  /** "MetadataReleaseTime.archive"'s inverse relation */
  metadataReleaseTimeList: Array<MetadataReleaseTime>;
  /** "MetadataReleaseTime.archive"'s inverse relation count */
  metadataReleaseTimeListCount: Scalars['Int']['output'];
  /** "MetadataRequiredPermission.archive"'s inverse relation */
  metadataRequiredPermissionList: Array<MetadataRequiredPermission>;
  /** "MetadataRequiredPermission.archive"'s inverse relation count */
  metadataRequiredPermissionListCount: Scalars['Int']['output'];
  /** "MetadataResolutionKind.archive"'s inverse relation */
  metadataResolutionKindList: Array<MetadataResolutionKind>;
  /** "MetadataResolutionKind.archive"'s inverse relation count */
  metadataResolutionKindListCount: Scalars['Int']['output'];
  /** "MetadataRights.archive"'s inverse relation */
  metadataRightsList: Array<MetadataRights>;
  /** "MetadataRights.archive"'s inverse relation count */
  metadataRightsListCount: Scalars['Int']['output'];
  /** "MetadataRoyaltyfree.archive"'s inverse relation */
  metadataRoyaltyfreeList: Array<MetadataRoyaltyfree>;
  /** "MetadataRoyaltyfree.archive"'s inverse relation count */
  metadataRoyaltyfreeListCount: Scalars['Int']['output'];
  /** "MetadataScene.archive"'s inverse relation */
  metadataSceneList: Array<MetadataScene>;
  /** "MetadataScene.archive"'s inverse relation count */
  metadataSceneListCount: Scalars['Int']['output'];
  /** "MetadataSection.archive"'s inverse relation */
  metadataSectionList: Array<MetadataSection>;
  /** "MetadataSection.archive"'s inverse relation count */
  metadataSectionListCount: Scalars['Int']['output'];
  /** "MetadataSentTo.archive"'s inverse relation */
  metadataSentToList: Array<MetadataSentTo>;
  /** "MetadataSentTo.archive"'s inverse relation count */
  metadataSentToListCount: Scalars['Int']['output'];
  /** "MetadataSerialNumber.archive"'s inverse relation */
  metadataSerialNumberList: Array<MetadataSerialNumber>;
  /** "MetadataSerialNumber.archive"'s inverse relation count */
  metadataSerialNumberListCount: Scalars['Int']['output'];
  /** "MetadataSeriesDateTime.archive"'s inverse relation */
  metadataSeriesDateTimeList: Array<MetadataSeriesDateTime>;
  /** "MetadataSeriesDateTime.archive"'s inverse relation count */
  metadataSeriesDateTimeListCount: Scalars['Int']['output'];
  /** "MetadataSeriesDescription.archive"'s inverse relation */
  metadataSeriesDescriptionList: Array<MetadataSeriesDescription>;
  /** "MetadataSeriesDescription.archive"'s inverse relation count */
  metadataSeriesDescriptionListCount: Scalars['Int']['output'];
  /** "MetadataSeriesModality.archive"'s inverse relation */
  metadataSeriesModalityList: Array<MetadataSeriesModality>;
  /** "MetadataSeriesModality.archive"'s inverse relation count */
  metadataSeriesModalityListCount: Scalars['Int']['output'];
  /** "MetadataSeriesNumber.archive"'s inverse relation */
  metadataSeriesNumberList: Array<MetadataSeriesNumber>;
  /** "MetadataSeriesNumber.archive"'s inverse relation count */
  metadataSeriesNumberListCount: Scalars['Int']['output'];
  /** "MetadataShortUniqueId.archive"'s inverse relation */
  metadataShortUniqueIdList: Array<MetadataShortUniqueId>;
  /** "MetadataShortUniqueId.archive"'s inverse relation count */
  metadataShortUniqueIdListCount: Scalars['Int']['output'];
  /** "MetadataSource.archive"'s inverse relation */
  metadataSourceList: Array<MetadataSource>;
  /** "MetadataSource.archive"'s inverse relation count */
  metadataSourceListCount: Scalars['Int']['output'];
  /** "MetadataState.archive"'s inverse relation */
  metadataStateList: Array<MetadataState>;
  /** "MetadataState.archive"'s inverse relation count */
  metadataStateListCount: Scalars['Int']['output'];
  /** "MetadataStudyDateTime.archive"'s inverse relation */
  metadataStudyDateTimeList: Array<MetadataStudyDateTime>;
  /** "MetadataStudyDateTime.archive"'s inverse relation count */
  metadataStudyDateTimeListCount: Scalars['Int']['output'];
  /** "MetadataStudyDescription.archive"'s inverse relation */
  metadataStudyDescriptionList: Array<MetadataStudyDescription>;
  /** "MetadataStudyDescription.archive"'s inverse relation count */
  metadataStudyDescriptionListCount: Scalars['Int']['output'];
  /** "MetadataStudyId.archive"'s inverse relation */
  metadataStudyIdList: Array<MetadataStudyId>;
  /** "MetadataStudyId.archive"'s inverse relation count */
  metadataStudyIdListCount: Scalars['Int']['output'];
  /** "MetadataStudyPhysician.archive"'s inverse relation */
  metadataStudyPhysicianList: Array<MetadataStudyPhysician>;
  /** "MetadataStudyPhysician.archive"'s inverse relation count */
  metadataStudyPhysicianListCount: Scalars['Int']['output'];
  /** "MetadataSubjectCode.archive"'s inverse relation */
  metadataSubjectCodeList: Array<MetadataSubjectCode>;
  /** "MetadataSubjectCode.archive"'s inverse relation count */
  metadataSubjectCodeListCount: Scalars['Int']['output'];
  /** "MetadataSubject.archive"'s inverse relation */
  metadataSubjectList: Array<MetadataSubject>;
  /** "MetadataSubject.archive"'s inverse relation count */
  metadataSubjectListCount: Scalars['Int']['output'];
  /** "MetadataSubjectPrisma.archive"'s inverse relation */
  metadataSubjectPrismaList: Array<MetadataSubjectPrisma>;
  /** "MetadataSubjectPrisma.archive"'s inverse relation count */
  metadataSubjectPrismaListCount: Scalars['Int']['output'];
  /** "MetadataSupplementalCategories.archive"'s inverse relation */
  metadataSupplementalCategoriesList: Array<MetadataSupplementalCategories>;
  /** "MetadataSupplementalCategories.archive"'s inverse relation count */
  metadataSupplementalCategoriesListCount: Scalars['Int']['output'];
  /** "MetadataTargetVersion.archive"'s inverse relation */
  metadataTargetVersionList: Array<MetadataTargetVersion>;
  /** "MetadataTargetVersion.archive"'s inverse relation count */
  metadataTargetVersionListCount: Scalars['Int']['output'];
  /** "MetadataTitle.archive"'s inverse relation */
  metadataTitleList: Array<MetadataTitle>;
  /** "MetadataTitle.archive"'s inverse relation count */
  metadataTitleListCount: Scalars['Int']['output'];
  /** "MetadataToneCurve.archive"'s inverse relation */
  metadataToneCurveList: Array<MetadataToneCurve>;
  /** "MetadataToneCurve.archive"'s inverse relation count */
  metadataToneCurveListCount: Scalars['Int']['output'];
  /** "MetadataTransferredByEmail.archive"'s inverse relation */
  metadataTransferredByEmailList: Array<MetadataTransferredByEmail>;
  /** "MetadataTransferredByEmail.archive"'s inverse relation count */
  metadataTransferredByEmailListCount: Scalars['Int']['output'];
  /** "MetadataTransferredByFullName.archive"'s inverse relation */
  metadataTransferredByFullNameList: Array<MetadataTransferredByFullName>;
  /** "MetadataTransferredByFullName.archive"'s inverse relation count */
  metadataTransferredByFullNameListCount: Scalars['Int']['output'];
  /** "MetadataTransferredBy.archive"'s inverse relation */
  metadataTransferredByList: Array<MetadataTransferredBy>;
  /** "MetadataTransferredBy.archive"'s inverse relation count */
  metadataTransferredByListCount: Scalars['Int']['output'];
  /** "MetadataTransmissionReference.archive"'s inverse relation */
  metadataTransmissionReferenceList: Array<MetadataTransmissionReference>;
  /** "MetadataTransmissionReference.archive"'s inverse relation count */
  metadataTransmissionReferenceListCount: Scalars['Int']['output'];
  /** "MetadataType.archive"'s inverse relation */
  metadataTypeList: Array<MetadataType>;
  /** "MetadataType.archive"'s inverse relation count */
  metadataTypeListCount: Scalars['Int']['output'];
  /** "MetadataUndersubject.archive"'s inverse relation */
  metadataUndersubjectList: Array<MetadataUndersubject>;
  /** "MetadataUndersubject.archive"'s inverse relation count */
  metadataUndersubjectListCount: Scalars['Int']['output'];
  /** "MetadataUnderUnderSubject.archive"'s inverse relation */
  metadataUnderUnderSubjectList: Array<MetadataUnderUnderSubject>;
  /** "MetadataUnderUnderSubject.archive"'s inverse relation count */
  metadataUnderUnderSubjectListCount: Scalars['Int']['output'];
  /** "MetadataUniqueId.archive"'s inverse relation */
  metadataUniqueIdList: Array<MetadataUniqueId>;
  /** "MetadataUniqueId.archive"'s inverse relation count */
  metadataUniqueIdListCount: Scalars['Int']['output'];
  /** "MetadataUnit.archive"'s inverse relation */
  metadataUnitList: Array<MetadataUnit>;
  /** "MetadataUnit.archive"'s inverse relation count */
  metadataUnitListCount: Scalars['Int']['output'];
  /** "MetadataUnitShortName.archive"'s inverse relation */
  metadataUnitShortNameList: Array<MetadataUnitShortName>;
  /** "MetadataUnitShortName.archive"'s inverse relation count */
  metadataUnitShortNameListCount: Scalars['Int']['output'];
  /** "MetadataUploadedByFullName.archive"'s inverse relation */
  metadataUploadedByFullNameList: Array<MetadataUploadedByFullName>;
  /** "MetadataUploadedByFullName.archive"'s inverse relation count */
  metadataUploadedByFullNameListCount: Scalars['Int']['output'];
  /** "MetadataUploadedBy.archive"'s inverse relation */
  metadataUploadedByList: Array<MetadataUploadedBy>;
  /** "MetadataUploadedBy.archive"'s inverse relation count */
  metadataUploadedByListCount: Scalars['Int']['output'];
  /** "MetadataUploadTime.archive"'s inverse relation */
  metadataUploadTimeList: Array<MetadataUploadTime>;
  /** "MetadataUploadTime.archive"'s inverse relation count */
  metadataUploadTimeListCount: Scalars['Int']['output'];
  /** "MetadataUrgency.archive"'s inverse relation */
  metadataUrgencyList: Array<MetadataUrgency>;
  /** "MetadataUrgency.archive"'s inverse relation count */
  metadataUrgencyListCount: Scalars['Int']['output'];
  /** "MetadataUsageTerms.archive"'s inverse relation */
  metadataUsageTermsList: Array<MetadataUsageTerms>;
  /** "MetadataUsageTerms.archive"'s inverse relation count */
  metadataUsageTermsListCount: Scalars['Int']['output'];
  /** "MetadataUserComment.archive"'s inverse relation */
  metadataUserCommentList: Array<MetadataUserComment>;
  /** "MetadataUserComment.archive"'s inverse relation count */
  metadataUserCommentListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined195.archive"'s inverse relation */
  metadataUserDefined195List: Array<MetadataUserDefined195>;
  /** "MetadataUserDefined195.archive"'s inverse relation count */
  metadataUserDefined195ListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined237.archive"'s inverse relation */
  metadataUserDefined237List: Array<MetadataUserDefined237>;
  /** "MetadataUserDefined237.archive"'s inverse relation count */
  metadataUserDefined237ListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined238.archive"'s inverse relation */
  metadataUserDefined238List: Array<MetadataUserDefined238>;
  /** "MetadataUserDefined238.archive"'s inverse relation count */
  metadataUserDefined238ListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined239.archive"'s inverse relation */
  metadataUserDefined239List: Array<MetadataUserDefined239>;
  /** "MetadataUserDefined239.archive"'s inverse relation count */
  metadataUserDefined239ListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined242.archive"'s inverse relation */
  metadataUserDefined242List: Array<MetadataUserDefined242>;
  /** "MetadataUserDefined242.archive"'s inverse relation count */
  metadataUserDefined242ListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined62.archive"'s inverse relation */
  metadataUserDefined62List: Array<MetadataUserDefined62>;
  /** "MetadataUserDefined62.archive"'s inverse relation count */
  metadataUserDefined62ListCount: Scalars['Int']['output'];
  /** "MetadataValid.archive"'s inverse relation */
  metadataValidList: Array<MetadataValid>;
  /** "MetadataValid.archive"'s inverse relation count */
  metadataValidListCount: Scalars['Int']['output'];
  /** "MetadataVersion.archive"'s inverse relation */
  metadataVersionList: Array<MetadataVersion>;
  /** "MetadataVersion.archive"'s inverse relation count */
  metadataVersionListCount: Scalars['Int']['output'];
  /** "MetadataWebStatement.archive"'s inverse relation */
  metadataWebStatementList: Array<MetadataWebStatement>;
  /** "MetadataWebStatement.archive"'s inverse relation count */
  metadataWebStatementListCount: Scalars['Int']['output'];
  /** "MetadataWorkflowKind.archive"'s inverse relation */
  metadataWorkflowKindList: Array<MetadataWorkflowKind>;
  /** "MetadataWorkflowKind.archive"'s inverse relation count */
  metadataWorkflowKindListCount: Scalars['Int']['output'];
  /** "MetadataXmpFileStamps.archive"'s inverse relation */
  metadataXmpFileStampsList: Array<MetadataXmpFileStamps>;
  /** "MetadataXmpFileStamps.archive"'s inverse relation count */
  metadataXmpFileStampsListCount: Scalars['Int']['output'];
  /** "MetadataXmpHistory.archive"'s inverse relation */
  metadataXmpHistoryList: Array<MetadataXmpHistory>;
  /** "MetadataXmpHistory.archive"'s inverse relation count */
  metadataXmpHistoryListCount: Scalars['Int']['output'];
  /** The archive name */
  name?: Maybe<ArchiveKeyType>;
  /** "Photo.archive"'s inverse relation count */
  photoCount: Scalars['Int']['output'];
  /** "PhotoMetadata.archive"'s inverse relation count */
  photoMetadataCount: Scalars['Int']['output'];
  /** "PhotoMetadata.archive"'s inverse relation */
  photoMetadatas: Array<PhotoMetadata>;
  /** "Photo.archive"'s inverse relation */
  photos: Array<Photo>;
  /** get the title of the archive */
  title?: Maybe<Scalars['String']['output']>;
  /** The archive type */
  type?: Maybe<ArchiveType>;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};


/** "Archive" resource's node */
export type ArchiveArchiveAgenciesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<ArchiveAgencyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchiveAgencyWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveArchiveAgencyCountArgs = {
  where?: InputMaybe<ArchiveAgencyWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveCategoriesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<CategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveCategoryCountArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveDeliverySubjectCountArgs = {
  where?: InputMaybe<DeliverySubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveDeliverySubjectsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<DeliverySubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliverySubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveImportQueueCountArgs = {
  where?: InputMaybe<ImportQueueWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveImportQueuesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<ImportQueueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ImportQueueWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAccessRightsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAccessRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAccessRightsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAccessRightsListCountArgs = {
  where?: InputMaybe<MetadataAccessRightsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAddlModelInfoListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAddlModelInfoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAddlModelInfoWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAddlModelInfoListCountArgs = {
  where?: InputMaybe<MetadataAddlModelInfoWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataArchiveChildListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataArchiveChildOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataArchiveChildWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataArchiveChildListCountArgs = {
  where?: InputMaybe<MetadataArchiveChildWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataArchiveParentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataArchiveParentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataArchiveParentWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataArchiveParentListCountArgs = {
  where?: InputMaybe<MetadataArchiveParentWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAssetStateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAssetStateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAssetStateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAssetStateListCountArgs = {
  where?: InputMaybe<MetadataAssetStateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAttributionNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAttributionNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAttributionNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAttributionNameListCountArgs = {
  where?: InputMaybe<MetadataAttributionNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAttributionUrlListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAttributionUrlOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAttributionUrlWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAttributionUrlListCountArgs = {
  where?: InputMaybe<MetadataAttributionUrlWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAudienceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAudienceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAudienceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAudienceListCountArgs = {
  where?: InputMaybe<MetadataAudienceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAuthorsPositionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAuthorsPositionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAuthorsPositionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataAuthorsPositionListCountArgs = {
  where?: InputMaybe<MetadataAuthorsPositionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataBackupNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBackupNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBackupNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataBackupNameListCountArgs = {
  where?: InputMaybe<MetadataBackupNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataBitmapGrayscalePictureListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBitmapGrayscalePictureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataBitmapGrayscalePictureListCountArgs = {
  where?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataBrandListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataBrandListCountArgs = {
  where?: InputMaybe<MetadataBrandWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataBrandPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandPrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataBrandPrismaListCountArgs = {
  where?: InputMaybe<MetadataBrandPrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataBrandShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandShortNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataBrandShortNameListCountArgs = {
  where?: InputMaybe<MetadataBrandShortNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCaptionWriterListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCaptionWriterOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCaptionWriterWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCaptionWriterListCountArgs = {
  where?: InputMaybe<MetadataCaptionWriterWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCategoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCategoryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCategoryListCountArgs = {
  where?: InputMaybe<MetadataCategoryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCategoryPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCategoryPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCategoryPrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCategoryPrismaListCountArgs = {
  where?: InputMaybe<MetadataCategoryPrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCertificateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCertificateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCertificateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCertificateListCountArgs = {
  where?: InputMaybe<MetadataCertificateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiAdrCityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrCityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrCityWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiAdrCityListCountArgs = {
  where?: InputMaybe<MetadataCiAdrCityWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiAdrCtryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrCtryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrCtryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiAdrCtryListCountArgs = {
  where?: InputMaybe<MetadataCiAdrCtryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiAdrExtadrListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrExtadrOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiAdrExtadrListCountArgs = {
  where?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiAdrPcodeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrPcodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiAdrPcodeListCountArgs = {
  where?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiAdrRegionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrRegionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrRegionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiAdrRegionListCountArgs = {
  where?: InputMaybe<MetadataCiAdrRegionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiEmailWorkListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiEmailWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiEmailWorkWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiEmailWorkListCountArgs = {
  where?: InputMaybe<MetadataCiEmailWorkWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiTelWorkListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiTelWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiTelWorkWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiTelWorkListCountArgs = {
  where?: InputMaybe<MetadataCiTelWorkWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCityWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCityListCountArgs = {
  where?: InputMaybe<MetadataCityWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiUrlWorkListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiUrlWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiUrlWorkWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCiUrlWorkListCountArgs = {
  where?: InputMaybe<MetadataCiUrlWorkWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataClassifyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataClassifyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataClassifyWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataClassifyListCountArgs = {
  where?: InputMaybe<MetadataClassifyWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCompanyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCompanyWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCompanyListCountArgs = {
  where?: InputMaybe<MetadataCompanyWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCompanyShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCompanyShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCompanyShortNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCompanyShortNameListCountArgs = {
  where?: InputMaybe<MetadataCompanyShortNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataConfidentialityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataConfidentialityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataConfidentialityWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataConfidentialityListCountArgs = {
  where?: InputMaybe<MetadataConfidentialityWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataContainedInListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContainedInOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContainedInWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataContainedInListCountArgs = {
  where?: InputMaybe<MetadataContainedInWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataContentMediaKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContentMediaKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContentMediaKindWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataContentMediaKindListCountArgs = {
  where?: InputMaybe<MetadataContentMediaKindWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataContentValueListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContentValueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContentValueWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataContentValueListCountArgs = {
  where?: InputMaybe<MetadataContentValueWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCopyrightLayerListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCopyrightLayerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCopyrightLayerWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCopyrightLayerListCountArgs = {
  where?: InputMaybe<MetadataCopyrightLayerWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCountryCodeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCountryCodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCountryCodeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCountryCodeListCountArgs = {
  where?: InputMaybe<MetadataCountryCodeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCountryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCountryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCountryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCountryListCountArgs = {
  where?: InputMaybe<MetadataCountryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCreatedTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatedTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatedTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCreatedTimeListCountArgs = {
  where?: InputMaybe<MetadataCreatedTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCreatorListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatorWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCreatorListCountArgs = {
  where?: InputMaybe<MetadataCreatorWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCreatorToolListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatorToolOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatorToolWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCreatorToolListCountArgs = {
  where?: InputMaybe<MetadataCreatorToolWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCreditListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreditOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreditWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCreditListCountArgs = {
  where?: InputMaybe<MetadataCreditWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField14ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField14OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField14WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField14ListCountArgs = {
  where?: InputMaybe<MetadataCustomField14WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField15ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField15OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField15WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField15ListCountArgs = {
  where?: InputMaybe<MetadataCustomField15WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField16ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField16OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField16WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField16ListCountArgs = {
  where?: InputMaybe<MetadataCustomField16WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField17ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField17OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField17WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField17ListCountArgs = {
  where?: InputMaybe<MetadataCustomField17WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField20ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField20OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField20WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField20ListCountArgs = {
  where?: InputMaybe<MetadataCustomField20WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField5ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField5OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField5WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField5ListCountArgs = {
  where?: InputMaybe<MetadataCustomField5WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField6ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField6OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField6WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField6ListCountArgs = {
  where?: InputMaybe<MetadataCustomField6WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField7ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField7OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField7WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField7ListCountArgs = {
  where?: InputMaybe<MetadataCustomField7WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField9ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField9OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField9WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataCustomField9ListCountArgs = {
  where?: InputMaybe<MetadataCustomField9WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDateCreatedListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateCreatedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateCreatedWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDateCreatedListCountArgs = {
  where?: InputMaybe<MetadataDateCreatedWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDateTimeDigitizedListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateTimeDigitizedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDateTimeDigitizedListCountArgs = {
  where?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDateTimeOriginalListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateTimeOriginalOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDateTimeOriginalListCountArgs = {
  where?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryAccountListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryAccountOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryAccountWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryAccountListCountArgs = {
  where?: InputMaybe<MetadataDeliveryAccountWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryCompanyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryCompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryCompanyListCountArgs = {
  where?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryCopyrightListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryCopyrightOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryCopyrightListCountArgs = {
  where?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryDateFolderListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDateFolderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryDateFolderListCountArgs = {
  where?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryDateTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryDateTimeListCountArgs = {
  where?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryDcCreatorListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDcCreatorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryDcCreatorListCountArgs = {
  where?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryDcRightsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDcRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryDcRightsListCountArgs = {
  where?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryFileTypeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryFileTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryFileTypeListCountArgs = {
  where?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryFolderListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryFolderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryFolderWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryFolderListCountArgs = {
  where?: InputMaybe<MetadataDeliveryFolderWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryKindWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryKindListCountArgs = {
  where?: InputMaybe<MetadataDeliveryKindWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryPathListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPathOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPathWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryPathListCountArgs = {
  where?: InputMaybe<MetadataDeliveryPathWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryPersonShownintheImageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryPersonShownintheImageListCountArgs = {
  where?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryPhotoshopCreditListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryPhotoshopCreditListCountArgs = {
  where?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryPhotoshopSourceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryPhotoshopSourceListCountArgs = {
  where?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryServiceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryServiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryServiceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryServiceListCountArgs = {
  where?: InputMaybe<MetadataDeliveryServiceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliverySubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliverySubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliverySubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliverySubjectListCountArgs = {
  where?: InputMaybe<MetadataDeliverySubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryUnderSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryUnderSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDeliveryUnderSubjectListCountArgs = {
  where?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDepartmentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDepartmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDepartmentWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDepartmentListCountArgs = {
  where?: InputMaybe<MetadataDepartmentWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDescriptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDescriptionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDescriptionListCountArgs = {
  where?: InputMaybe<MetadataDescriptionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDigitalAssetUrlListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDigitalAssetUrlOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDigitalAssetUrlListCountArgs = {
  where?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDivisionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDivisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDivisionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDivisionListCountArgs = {
  where?: InputMaybe<MetadataDivisionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDocumentTextListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDocumentTextOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDocumentTextWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataDocumentTextListCountArgs = {
  where?: InputMaybe<MetadataDocumentTextWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEditionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEditionListCountArgs = {
  where?: InputMaybe<MetadataEditionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEditorialVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditorialVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditorialVersionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEditorialVersionListCountArgs = {
  where?: InputMaybe<MetadataEditorialVersionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEditStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditStatusWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEditStatusListCountArgs = {
  where?: InputMaybe<MetadataEditStatusWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEnvironnementPhotoListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEnvironnementPhotoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEnvironnementPhotoListCountArgs = {
  where?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEquipmentInstitutionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEquipmentInstitutionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEquipmentInstitutionListCountArgs = {
  where?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEquipmentManufacturerListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEquipmentManufacturerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEquipmentManufacturerListCountArgs = {
  where?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEventListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEventOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEventWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataEventListCountArgs = {
  where?: InputMaybe<MetadataEventWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataExifListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataExifOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataExifWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataExifListCountArgs = {
  where?: InputMaybe<MetadataExifWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFabStorageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFabStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFabStorageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFabStorageListCountArgs = {
  where?: InputMaybe<MetadataFabStorageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileCheckForListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileCheckForOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileCheckForWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileCheckForListCountArgs = {
  where?: InputMaybe<MetadataFileCheckForWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileInfoBitsPerPixelListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoBitsPerPixelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileInfoBitsPerPixelListCountArgs = {
  where?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileInfoFramesListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoFramesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoFramesWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileInfoFramesListCountArgs = {
  where?: InputMaybe<MetadataFileInfoFramesWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileInfoPhotometricInterpretationListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileInfoPhotometricInterpretationListCountArgs = {
  where?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileInfoTransferSyntaxListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoTransferSyntaxOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileInfoTransferSyntaxListCountArgs = {
  where?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileStageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileStageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileStageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileStageListCountArgs = {
  where?: InputMaybe<MetadataFileStageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileStatusWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileStatusListCountArgs = {
  where?: InputMaybe<MetadataFileStatusWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileTypeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileTypeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFileTypeListCountArgs = {
  where?: InputMaybe<MetadataFileTypeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFixtureIdentifierListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFixtureIdentifierOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFixtureIdentifierListCountArgs = {
  where?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFormatListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFormatOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFormatWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataFormatListCountArgs = {
  where?: InputMaybe<MetadataFormatWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataGpsLatitudeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsLatitudeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsLatitudeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataGpsLatitudeListCountArgs = {
  where?: InputMaybe<MetadataGpsLatitudeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataGpsLongitudeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsLongitudeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsLongitudeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataGpsLongitudeListCountArgs = {
  where?: InputMaybe<MetadataGpsLongitudeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataGpsProcessingMethodListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsProcessingMethodOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataGpsProcessingMethodListCountArgs = {
  where?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataHeaderListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeaderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeaderWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataHeaderListCountArgs = {
  where?: InputMaybe<MetadataHeaderWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataHeaderPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeaderPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeaderPrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataHeaderPrismaListCountArgs = {
  where?: InputMaybe<MetadataHeaderPrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataHeadlineListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeadlineOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeadlineWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataHeadlineListCountArgs = {
  where?: InputMaybe<MetadataHeadlineWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataHistoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHistoryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataHistoryListCountArgs = {
  where?: InputMaybe<MetadataHistoryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIccProfileListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIccProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIccProfileWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIccProfileListCountArgs = {
  where?: InputMaybe<MetadataIccProfileWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIdAssignmentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdAssignmentWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIdAssignmentListCountArgs = {
  where?: InputMaybe<MetadataIdAssignmentWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIdMediaContentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdMediaContentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdMediaContentWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIdMediaContentListCountArgs = {
  where?: InputMaybe<MetadataIdMediaContentWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIdPhotoPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdPhotoPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIdPhotoPrismaListCountArgs = {
  where?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIdSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdSubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIdSubjectListCountArgs = {
  where?: InputMaybe<MetadataIdSubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataImageNotesListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataImageNotesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataImageNotesWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataImageNotesListCountArgs = {
  where?: InputMaybe<MetadataImageNotesWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataImageUniqueIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataImageUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataImageUniqueIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataImageUniqueIdListCountArgs = {
  where?: InputMaybe<MetadataImageUniqueIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataInstructionsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataInstructionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataInstructionsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataInstructionsListCountArgs = {
  where?: InputMaybe<MetadataInstructionsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIntellectualGenreListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIntellectualGenreOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIntellectualGenreWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIntellectualGenreListCountArgs = {
  where?: InputMaybe<MetadataIntellectualGenreWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIsoSpeedRatingsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIsoSpeedRatingsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIsoSpeedRatingsListCountArgs = {
  where?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIssueListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIssueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIssueWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataIssueListCountArgs = {
  where?: InputMaybe<MetadataIssueWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataJobIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataJobIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataJobIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataJobIdListCountArgs = {
  where?: InputMaybe<MetadataJobIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataJobProcessingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataJobProcessingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataJobProcessingWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataJobProcessingListCountArgs = {
  where?: InputMaybe<MetadataJobProcessingWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLayoutPictureEditingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLayoutPictureEditingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLayoutPictureEditingListCountArgs = {
  where?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLayoutStorageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLayoutStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLayoutStorageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLayoutStorageListCountArgs = {
  where?: InputMaybe<MetadataLayoutStorageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLicenseListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLicenseOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLicenseWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLicenseListCountArgs = {
  where?: InputMaybe<MetadataLicenseWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLinksListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLinksOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLinksWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLinksListCountArgs = {
  where?: InputMaybe<MetadataLinksWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLocalCaptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLocalCaptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLocalCaptionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLocalCaptionListCountArgs = {
  where?: InputMaybe<MetadataLocalCaptionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLocationListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLocationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLocationWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataLocationListCountArgs = {
  where?: InputMaybe<MetadataLocationWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMakeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMakeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMakeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMakeListCountArgs = {
  where?: InputMaybe<MetadataMakeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataManifestListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataManifestOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataManifestWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataManifestListCountArgs = {
  where?: InputMaybe<MetadataManifestWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMarkedListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMarkedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMarkedWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMarkedListCountArgs = {
  where?: InputMaybe<MetadataMarkedWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMasterDocumentIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMasterDocumentIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMasterDocumentIdListCountArgs = {
  where?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMaxAvailHeightListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMaxAvailHeightOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMaxAvailHeightListCountArgs = {
  where?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMaxAvailWidthListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMaxAvailWidthOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMaxAvailWidthListCountArgs = {
  where?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMinorModelAgeDisclosureListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMinorModelAgeDisclosureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMinorModelAgeDisclosureListCountArgs = {
  where?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataModelAgeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelAgeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelAgeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataModelAgeListCountArgs = {
  where?: InputMaybe<MetadataModelAgeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataModelListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataModelListCountArgs = {
  where?: InputMaybe<MetadataModelWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataModelReleaseIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelReleaseIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelReleaseIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataModelReleaseIdListCountArgs = {
  where?: InputMaybe<MetadataModelReleaseIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataModelReleaseStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelReleaseStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataModelReleaseStatusListCountArgs = {
  where?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMorePermissionsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMorePermissionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMorePermissionsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataMorePermissionsListCountArgs = {
  where?: InputMaybe<MetadataMorePermissionsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataObjectCycleListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataObjectCycleOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataObjectCycleWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataObjectCycleListCountArgs = {
  where?: InputMaybe<MetadataObjectCycleWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOrganisationInImageNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOrganisationInImageNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOrganisationInImageNameListCountArgs = {
  where?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOriginalColorListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalColorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalColorWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOriginalColorListCountArgs = {
  where?: InputMaybe<MetadataOriginalColorWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOriginalIccProfileListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalIccProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOriginalIccProfileListCountArgs = {
  where?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOriginalNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOriginalNameListCountArgs = {
  where?: InputMaybe<MetadataOriginalNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOriginalWeigthListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalWeigthOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalWeigthWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOriginalWeigthListCountArgs = {
  where?: InputMaybe<MetadataOriginalWeigthWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOwnerIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOwnerIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOwnerIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOwnerIdListCountArgs = {
  where?: InputMaybe<MetadataOwnerIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOwnerListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOwnerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOwnerWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataOwnerListCountArgs = {
  where?: InputMaybe<MetadataOwnerWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPageListCountArgs = {
  where?: InputMaybe<MetadataPageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPagePrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPagePrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPagePrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPagePrismaListCountArgs = {
  where?: InputMaybe<MetadataPagePrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPaintBasedCorrectionsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPaintBasedCorrectionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPaintBasedCorrectionsListCountArgs = {
  where?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPatientDobListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientDobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientDobWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPatientDobListCountArgs = {
  where?: InputMaybe<MetadataPatientDobWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPatientIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPatientIdListCountArgs = {
  where?: InputMaybe<MetadataPatientIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPatientNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPatientNameListCountArgs = {
  where?: InputMaybe<MetadataPatientNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPatientSexListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientSexOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientSexWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPatientSexListCountArgs = {
  where?: InputMaybe<MetadataPatientSexWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPersonInImageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPersonInImageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPersonInImageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPersonInImageListCountArgs = {
  where?: InputMaybe<MetadataPersonInImageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPhotoStorageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPhotoStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPhotoStorageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPhotoStorageListCountArgs = {
  where?: InputMaybe<MetadataPhotoStorageWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPrepressPictureEditingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrepressPictureEditingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPrepressPictureEditingListCountArgs = {
  where?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPriceLevelListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPriceLevelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPriceLevelWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPriceLevelListCountArgs = {
  where?: InputMaybe<MetadataPriceLevelWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPrintingProfileListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrintingProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrintingProfileWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPrintingProfileListCountArgs = {
  where?: InputMaybe<MetadataPrintingProfileWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPrismaProductionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrismaProductionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrismaProductionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPrismaProductionListCountArgs = {
  where?: InputMaybe<MetadataPrismaProductionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProcessHistoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessHistoryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProcessHistoryListCountArgs = {
  where?: InputMaybe<MetadataProcessHistoryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProcessParameterListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessParameterOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessParameterWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProcessParameterListCountArgs = {
  where?: InputMaybe<MetadataProcessParameterWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProcessStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessStatusWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProcessStatusListCountArgs = {
  where?: InputMaybe<MetadataProcessStatusWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProductListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProductListCountArgs = {
  where?: InputMaybe<MetadataProductWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProductShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductShortNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProductShortNameListCountArgs = {
  where?: InputMaybe<MetadataProductShortNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProductsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProductsListCountArgs = {
  where?: InputMaybe<MetadataProductsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProduitsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProduitsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProduitsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProduitsListCountArgs = {
  where?: InputMaybe<MetadataProduitsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProgramVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProgramVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProgramVersionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataProgramVersionListCountArgs = {
  where?: InputMaybe<MetadataProgramVersionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPropertyReleaseIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPropertyReleaseIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPropertyReleaseIdListCountArgs = {
  where?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPropertyReleaseStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPropertyReleaseStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPropertyReleaseStatusListCountArgs = {
  where?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPublisherListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublisherOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublisherWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPublisherListCountArgs = {
  where?: InputMaybe<MetadataPublisherWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPublishingIssueListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublishingIssueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublishingIssueWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPublishingIssueListCountArgs = {
  where?: InputMaybe<MetadataPublishingIssueWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPublishingSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublishingSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublishingSubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataPublishingSubjectListCountArgs = {
  where?: InputMaybe<MetadataPublishingSubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataQualifiedUsePrismaByListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataQualifiedUsePrismaByListCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataQualifiedUsePrismaDateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataQualifiedUsePrismaDateListCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataQualifiedUsePrismaDurationListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataQualifiedUsePrismaDurationListCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataQualifiedUsePrismaSupportListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataQualifiedUsePrismaSupportListCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataRatingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRatingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRatingWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataRatingListCountArgs = {
  where?: InputMaybe<MetadataRatingWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataReferenceDateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceDateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataReferenceDateListCountArgs = {
  where?: InputMaybe<MetadataReferenceDateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataReferenceNumberListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceNumberWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataReferenceNumberListCountArgs = {
  where?: InputMaybe<MetadataReferenceNumberWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataReferenceServiceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceServiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceServiceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataReferenceServiceListCountArgs = {
  where?: InputMaybe<MetadataReferenceServiceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataReleaseDateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReleaseDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReleaseDateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataReleaseDateListCountArgs = {
  where?: InputMaybe<MetadataReleaseDateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataReleaseTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReleaseTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReleaseTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataReleaseTimeListCountArgs = {
  where?: InputMaybe<MetadataReleaseTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataRequiredPermissionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRequiredPermissionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRequiredPermissionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataRequiredPermissionListCountArgs = {
  where?: InputMaybe<MetadataRequiredPermissionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataResolutionKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataResolutionKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataResolutionKindWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataResolutionKindListCountArgs = {
  where?: InputMaybe<MetadataResolutionKindWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataRightsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRightsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataRightsListCountArgs = {
  where?: InputMaybe<MetadataRightsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataRoyaltyfreeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRoyaltyfreeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataRoyaltyfreeListCountArgs = {
  where?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSceneListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSceneOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSceneWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSceneListCountArgs = {
  where?: InputMaybe<MetadataSceneWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSectionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSectionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSectionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSectionListCountArgs = {
  where?: InputMaybe<MetadataSectionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSentToListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSentToOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSentToWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSentToListCountArgs = {
  where?: InputMaybe<MetadataSentToWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSerialNumberListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSerialNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSerialNumberWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSerialNumberListCountArgs = {
  where?: InputMaybe<MetadataSerialNumberWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSeriesDateTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSeriesDateTimeListCountArgs = {
  where?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSeriesDescriptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSeriesDescriptionListCountArgs = {
  where?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSeriesModalityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesModalityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesModalityWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSeriesModalityListCountArgs = {
  where?: InputMaybe<MetadataSeriesModalityWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSeriesNumberListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesNumberWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSeriesNumberListCountArgs = {
  where?: InputMaybe<MetadataSeriesNumberWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataShortUniqueIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataShortUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataShortUniqueIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataShortUniqueIdListCountArgs = {
  where?: InputMaybe<MetadataShortUniqueIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSourceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSourceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSourceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSourceListCountArgs = {
  where?: InputMaybe<MetadataSourceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataStateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataStateListCountArgs = {
  where?: InputMaybe<MetadataStateWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataStudyDateTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyDateTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataStudyDateTimeListCountArgs = {
  where?: InputMaybe<MetadataStudyDateTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataStudyDescriptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyDescriptionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataStudyDescriptionListCountArgs = {
  where?: InputMaybe<MetadataStudyDescriptionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataStudyIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataStudyIdListCountArgs = {
  where?: InputMaybe<MetadataStudyIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataStudyPhysicianListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyPhysicianOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyPhysicianWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataStudyPhysicianListCountArgs = {
  where?: InputMaybe<MetadataStudyPhysicianWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSubjectCodeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectCodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectCodeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSubjectCodeListCountArgs = {
  where?: InputMaybe<MetadataSubjectCodeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSubjectListCountArgs = {
  where?: InputMaybe<MetadataSubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSubjectPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectPrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSubjectPrismaListCountArgs = {
  where?: InputMaybe<MetadataSubjectPrismaWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSupplementalCategoriesListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSupplementalCategoriesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataSupplementalCategoriesListCountArgs = {
  where?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTargetVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTargetVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTargetVersionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTargetVersionListCountArgs = {
  where?: InputMaybe<MetadataTargetVersionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTitleListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTitleOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTitleWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTitleListCountArgs = {
  where?: InputMaybe<MetadataTitleWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataToneCurveListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataToneCurveOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataToneCurveWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataToneCurveListCountArgs = {
  where?: InputMaybe<MetadataToneCurveWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTransferredByEmailListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByEmailOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByEmailWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTransferredByEmailListCountArgs = {
  where?: InputMaybe<MetadataTransferredByEmailWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTransferredByFullNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByFullNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTransferredByFullNameListCountArgs = {
  where?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTransferredByListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTransferredByListCountArgs = {
  where?: InputMaybe<MetadataTransferredByWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTransmissionReferenceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransmissionReferenceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTransmissionReferenceListCountArgs = {
  where?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTypeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTypeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataTypeListCountArgs = {
  where?: InputMaybe<MetadataTypeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUndersubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUndersubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUndersubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUndersubjectListCountArgs = {
  where?: InputMaybe<MetadataUndersubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUnderUnderSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnderUnderSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUnderUnderSubjectListCountArgs = {
  where?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUniqueIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUniqueIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUniqueIdListCountArgs = {
  where?: InputMaybe<MetadataUniqueIdWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUnitListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnitOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnitWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUnitListCountArgs = {
  where?: InputMaybe<MetadataUnitWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUnitShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnitShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnitShortNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUnitShortNameListCountArgs = {
  where?: InputMaybe<MetadataUnitShortNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUploadedByFullNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadedByFullNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUploadedByFullNameListCountArgs = {
  where?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUploadedByListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadedByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadedByWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUploadedByListCountArgs = {
  where?: InputMaybe<MetadataUploadedByWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUploadTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUploadTimeListCountArgs = {
  where?: InputMaybe<MetadataUploadTimeWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUrgencyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUrgencyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUrgencyWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUrgencyListCountArgs = {
  where?: InputMaybe<MetadataUrgencyWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUsageTermsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUsageTermsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUsageTermsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUsageTermsListCountArgs = {
  where?: InputMaybe<MetadataUsageTermsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserCommentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserCommentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserCommentWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserCommentListCountArgs = {
  where?: InputMaybe<MetadataUserCommentWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined195ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined195OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined195WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined195ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined195WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined237ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined237OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined237WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined237ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined237WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined238ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined238OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined238WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined238ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined238WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined239ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined239OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined239WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined239ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined239WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined242ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined242OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined242WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined242ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined242WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined62ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined62OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined62WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataUserDefined62ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined62WhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataValidListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataValidOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataValidWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataValidListCountArgs = {
  where?: InputMaybe<MetadataValidWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataVersionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataVersionListCountArgs = {
  where?: InputMaybe<MetadataVersionWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataWebStatementListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataWebStatementOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWebStatementWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataWebStatementListCountArgs = {
  where?: InputMaybe<MetadataWebStatementWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataWorkflowKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataWorkflowKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWorkflowKindWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataWorkflowKindListCountArgs = {
  where?: InputMaybe<MetadataWorkflowKindWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataXmpFileStampsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataXmpFileStampsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataXmpFileStampsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataXmpFileStampsListCountArgs = {
  where?: InputMaybe<MetadataXmpFileStampsWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataXmpHistoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataXmpHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataXmpHistoryWhereInput>;
};


/** "Archive" resource's node */
export type ArchiveMetadataXmpHistoryListCountArgs = {
  where?: InputMaybe<MetadataXmpHistoryWhereInput>;
};


/** "Archive" resource's node */
export type ArchivePhotoCountArgs = {
  where?: InputMaybe<PhotoWhereInput>;
};


/** "Archive" resource's node */
export type ArchivePhotoMetadataCountArgs = {
  where?: InputMaybe<PhotoMetadataWhereInput>;
};


/** "Archive" resource's node */
export type ArchivePhotoMetadatasArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoMetadataOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoMetadataWhereInput>;
};


/** "Archive" resource's node */
export type ArchivePhotosArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoWhereInput>;
};

/** "ArchiveAgency" resource's node */
export type ArchiveAgency = {
  __typename?: 'ArchiveAgency';
  /** The internal ID */
  _id: Scalars['Int']['output'];
  agency: Agency;
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};

export type ArchiveAgencyCreateInput = {
  /** Actions for the "ArchiveAgency.agency" relation */
  agency: ArchiveAgencyNestedAgencyCreateInput;
  /** Actions for the "ArchiveAgency.archive" relation */
  archive: ArchiveAgencyNestedArchiveCreateInput;
};

export type ArchiveAgencyNestedAgencyCreateInput = {
  /** Connect an existing "Agency" node to the new "ArchiveAgency" node, through the "ArchiveAgency.agency" relation. */
  connect?: InputMaybe<AgencyWhereUniqueInput>;
  /** Create a new "Agency" node and connect it to the new "ArchiveAgency" node, through the "ArchiveAgency.agency" relation. */
  create?: InputMaybe<AgencyCreateInput>;
  /** Update an existing "Agency" node and connect it to the new "ArchiveAgency" node, through the "ArchiveAgency.agency" relation. */
  update?: InputMaybe<ArchiveAgencyNestedUpdateAgencyCreateInput>;
  /** Create or update a "Agency" node and connect it to the new "ArchiveAgency" node, through the "ArchiveAgency.agency" relation. */
  upsert?: InputMaybe<ArchiveAgencyNestedUpsertAgencyCreateInput>;
};

export type ArchiveAgencyNestedAgencyUpdateInput = {
  /** Connect an existing "Agency" node to the existing "ArchiveAgency" node, through the "ArchiveAgency.agency" relation. */
  connect?: InputMaybe<AgencyWhereUniqueInput>;
  /** Create a new "Agency" node and connect it to the existing "ArchiveAgency" node, through the "ArchiveAgency.agency" relation. */
  create?: InputMaybe<AgencyCreateInput>;
  /** Update an existing "Agency" node and connect it to the existing "ArchiveAgency" node, through the "ArchiveAgency.agency" relation. */
  update?: InputMaybe<ArchiveAgencyNestedUpdateAgencyUpdateInput>;
  /** Create or update a "Agency" node and connect it to the existing "ArchiveAgency" node, through the "ArchiveAgency.agency" relation. */
  upsert?: InputMaybe<ArchiveAgencyNestedUpsertAgencyUpdateInput>;
};

export type ArchiveAgencyNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "ArchiveAgency" node, through the "ArchiveAgency.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "ArchiveAgency" node, through the "ArchiveAgency.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "ArchiveAgency" node, through the "ArchiveAgency.archive" relation. */
  update?: InputMaybe<ArchiveAgencyNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "ArchiveAgency" node, through the "ArchiveAgency.archive" relation. */
  upsert?: InputMaybe<ArchiveAgencyNestedUpsertArchiveCreateInput>;
};

export type ArchiveAgencyNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "ArchiveAgency" node, through the "ArchiveAgency.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "ArchiveAgency" node, through the "ArchiveAgency.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "ArchiveAgency" node, through the "ArchiveAgency.archive" relation. */
  update?: InputMaybe<ArchiveAgencyNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "ArchiveAgency" node, through the "ArchiveAgency.archive" relation. */
  upsert?: InputMaybe<ArchiveAgencyNestedUpsertArchiveUpdateInput>;
};

export type ArchiveAgencyNestedUpdateAgencyCreateInput = {
  data: AgencyUpdateInput;
  where: AgencyWhereUniqueInput;
};

export type ArchiveAgencyNestedUpdateAgencyUpdateInput = {
  data: AgencyUpdateInput;
  where: AgencyWhereUniqueInput;
};

export type ArchiveAgencyNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type ArchiveAgencyNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type ArchiveAgencyNestedUpsertAgencyCreateInput = {
  create: AgencyCreateInput;
  update: AgencyUpdateInput;
  where: AgencyWhereUniqueInput;
};

export type ArchiveAgencyNestedUpsertAgencyUpdateInput = {
  create: AgencyCreateInput;
  update: AgencyUpdateInput;
  where: AgencyWhereUniqueInput;
};

export type ArchiveAgencyNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type ArchiveAgencyNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export enum ArchiveAgencyOrderByInput {
  _id_ASC = '_id_ASC',
  _id_DESC = '_id_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC'
}

export type ArchiveAgencyUpdateInput = {
  /** Actions for the "ArchiveAgency.agency" relation */
  agency?: InputMaybe<ArchiveAgencyNestedAgencyUpdateInput>;
  /** Actions for the "ArchiveAgency.archive" relation */
  archive?: InputMaybe<ArchiveAgencyNestedArchiveUpdateInput>;
};

/** Filters the "ArchiveAgency" nodes by specifying some conditions */
export type ArchiveAgencyWhereInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  _id_gt?: InputMaybe<Scalars['Int']['input']>;
  _id_gte?: InputMaybe<Scalars['Int']['input']>;
  _id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _id_lt?: InputMaybe<Scalars['Int']['input']>;
  _id_lte?: InputMaybe<Scalars['Int']['input']>;
  _id_not?: InputMaybe<Scalars['Int']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  agency?: InputMaybe<AgencyWhereInput>;
  AND?: InputMaybe<Array<ArchiveAgencyWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  NOT?: InputMaybe<ArchiveAgencyWhereInput>;
  OR?: InputMaybe<Array<ArchiveAgencyWhereInput>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/**
 * Identifies exactly one "ArchiveAgency" node with one of these unique combinations of values:
 * - _id
 * - agency, archive
 */
export type ArchiveAgencyWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  agency?: InputMaybe<AgencyWhereUniqueInput>;
  archive?: InputMaybe<ArchiveWhereUniqueInput>;
};

export type ArchiveAgencyWithForcedAgencyCreateInput = {
  /** Actions for the "ArchiveAgency.archive" relation */
  archive: ArchiveAgencyNestedArchiveCreateInput;
};

export type ArchiveAgencyWithForcedAgencyUpdateInput = {
  /** Actions for the "ArchiveAgency.archive" relation */
  archive?: InputMaybe<ArchiveAgencyNestedArchiveUpdateInput>;
};

/** Given a forced "agency", identifies exactly one "ArchiveAgency" node. */
export type ArchiveAgencyWithForcedAgencyWhereUniqueInput = {
  archive: ArchiveWhereUniqueInput;
};

export type ArchiveAgencyWithForcedArchiveCreateInput = {
  /** Actions for the "ArchiveAgency.agency" relation */
  agency: ArchiveAgencyNestedAgencyCreateInput;
};

export type ArchiveAgencyWithForcedArchiveUpdateInput = {
  /** Actions for the "ArchiveAgency.agency" relation */
  agency?: InputMaybe<ArchiveAgencyNestedAgencyUpdateInput>;
};

/** Given a forced "archive", identifies exactly one "ArchiveAgency" node. */
export type ArchiveAgencyWithForcedArchiveWhereUniqueInput = {
  agency: AgencyWhereUniqueInput;
};

/**
 * Given a known "agency", identifies exactly one "ArchiveAgency" node with one of these unique combinations of values:
 * - _id
 * - agency (optional), archive
 */
export type ArchiveAgencyWithOptionalAgencyWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  agency?: InputMaybe<AgencyWhereUniqueInput>;
  archive?: InputMaybe<ArchiveWhereUniqueInput>;
};

/**
 * Given a known "archive", identifies exactly one "ArchiveAgency" node with one of these unique combinations of values:
 * - _id
 * - agency, archive (optional)
 */
export type ArchiveAgencyWithOptionalArchiveWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  agency?: InputMaybe<AgencyWhereUniqueInput>;
  archive?: InputMaybe<ArchiveWhereUniqueInput>;
};

export type ArchiveCreateInput = {
  /** Actions for the "Archive.archiveAgencies" relation: "ArchiveAgency.archive"'s inverse relation */
  archiveAgencies?: InputMaybe<ArchiveNestedArchiveAgenciesCreateInput>;
  /** The internal ID */
  archiveId?: InputMaybe<Scalars['Int']['input']>;
  /** Actions for the "Archive.categories" relation: "Category.archive"'s inverse relation */
  categories?: InputMaybe<ArchiveNestedCategoriesCreateInput>;
  /** Actions for the "Archive.deliverySubjects" relation: "DeliverySubject.archive"'s inverse relation */
  deliverySubjects?: InputMaybe<ArchiveNestedDeliverySubjectsCreateInput>;
  /** Actions for the "Archive.gcImportSetting" relation: "GcImportSetting.archive"'s inverse relation */
  gcImportSetting?: InputMaybe<ArchiveNestedGcImportSettingCreateInput>;
  hasCategories?: InputMaybe<Scalars['Boolean']['input']>;
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Actions for the "Archive.importQueues" relation: "ImportQueue.archive"'s inverse relation */
  importQueues?: InputMaybe<ArchiveNestedImportQueuesCreateInput>;
  /** The archive label */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Archive.metadataAccessRightsList" relation: "MetadataAccessRights.archive"'s inverse relation */
  metadataAccessRightsList?: InputMaybe<ArchiveNestedMetadataAccessRightsListCreateInput>;
  /** Actions for the "Archive.metadataAddlModelInfoList" relation: "MetadataAddlModelInfo.archive"'s inverse relation */
  metadataAddlModelInfoList?: InputMaybe<ArchiveNestedMetadataAddlModelInfoListCreateInput>;
  /** Actions for the "Archive.metadataArchiveChildList" relation: "MetadataArchiveChild.archive"'s inverse relation */
  metadataArchiveChildList?: InputMaybe<ArchiveNestedMetadataArchiveChildListCreateInput>;
  /** Actions for the "Archive.metadataArchiveParentList" relation: "MetadataArchiveParent.archive"'s inverse relation */
  metadataArchiveParentList?: InputMaybe<ArchiveNestedMetadataArchiveParentListCreateInput>;
  /** Actions for the "Archive.metadataAssetStateList" relation: "MetadataAssetState.archive"'s inverse relation */
  metadataAssetStateList?: InputMaybe<ArchiveNestedMetadataAssetStateListCreateInput>;
  /** Actions for the "Archive.metadataAttributionNameList" relation: "MetadataAttributionName.archive"'s inverse relation */
  metadataAttributionNameList?: InputMaybe<ArchiveNestedMetadataAttributionNameListCreateInput>;
  /** Actions for the "Archive.metadataAttributionUrlList" relation: "MetadataAttributionUrl.archive"'s inverse relation */
  metadataAttributionUrlList?: InputMaybe<ArchiveNestedMetadataAttributionUrlListCreateInput>;
  /** Actions for the "Archive.metadataAudienceList" relation: "MetadataAudience.archive"'s inverse relation */
  metadataAudienceList?: InputMaybe<ArchiveNestedMetadataAudienceListCreateInput>;
  /** Actions for the "Archive.metadataAuthorsPositionList" relation: "MetadataAuthorsPosition.archive"'s inverse relation */
  metadataAuthorsPositionList?: InputMaybe<ArchiveNestedMetadataAuthorsPositionListCreateInput>;
  /** Actions for the "Archive.metadataBackupNameList" relation: "MetadataBackupName.archive"'s inverse relation */
  metadataBackupNameList?: InputMaybe<ArchiveNestedMetadataBackupNameListCreateInput>;
  /** Actions for the "Archive.metadataBitmapGrayscalePictureList" relation: "MetadataBitmapGrayscalePicture.archive"'s inverse relation */
  metadataBitmapGrayscalePictureList?: InputMaybe<ArchiveNestedMetadataBitmapGrayscalePictureListCreateInput>;
  /** Actions for the "Archive.metadataBrandList" relation: "MetadataBrand.archive"'s inverse relation */
  metadataBrandList?: InputMaybe<ArchiveNestedMetadataBrandListCreateInput>;
  /** Actions for the "Archive.metadataBrandPrismaList" relation: "MetadataBrandPrisma.archive"'s inverse relation */
  metadataBrandPrismaList?: InputMaybe<ArchiveNestedMetadataBrandPrismaListCreateInput>;
  /** Actions for the "Archive.metadataBrandShortNameList" relation: "MetadataBrandShortName.archive"'s inverse relation */
  metadataBrandShortNameList?: InputMaybe<ArchiveNestedMetadataBrandShortNameListCreateInput>;
  /** Actions for the "Archive.metadataCaptionWriterList" relation: "MetadataCaptionWriter.archive"'s inverse relation */
  metadataCaptionWriterList?: InputMaybe<ArchiveNestedMetadataCaptionWriterListCreateInput>;
  /** Actions for the "Archive.metadataCategoryList" relation: "MetadataCategory.archive"'s inverse relation */
  metadataCategoryList?: InputMaybe<ArchiveNestedMetadataCategoryListCreateInput>;
  /** Actions for the "Archive.metadataCategoryPrismaList" relation: "MetadataCategoryPrisma.archive"'s inverse relation */
  metadataCategoryPrismaList?: InputMaybe<ArchiveNestedMetadataCategoryPrismaListCreateInput>;
  /** Actions for the "Archive.metadataCertificateList" relation: "MetadataCertificate.archive"'s inverse relation */
  metadataCertificateList?: InputMaybe<ArchiveNestedMetadataCertificateListCreateInput>;
  /** Actions for the "Archive.metadataCiAdrCityList" relation: "MetadataCiAdrCity.archive"'s inverse relation */
  metadataCiAdrCityList?: InputMaybe<ArchiveNestedMetadataCiAdrCityListCreateInput>;
  /** Actions for the "Archive.metadataCiAdrCtryList" relation: "MetadataCiAdrCtry.archive"'s inverse relation */
  metadataCiAdrCtryList?: InputMaybe<ArchiveNestedMetadataCiAdrCtryListCreateInput>;
  /** Actions for the "Archive.metadataCiAdrExtadrList" relation: "MetadataCiAdrExtadr.archive"'s inverse relation */
  metadataCiAdrExtadrList?: InputMaybe<ArchiveNestedMetadataCiAdrExtadrListCreateInput>;
  /** Actions for the "Archive.metadataCiAdrPcodeList" relation: "MetadataCiAdrPcode.archive"'s inverse relation */
  metadataCiAdrPcodeList?: InputMaybe<ArchiveNestedMetadataCiAdrPcodeListCreateInput>;
  /** Actions for the "Archive.metadataCiAdrRegionList" relation: "MetadataCiAdrRegion.archive"'s inverse relation */
  metadataCiAdrRegionList?: InputMaybe<ArchiveNestedMetadataCiAdrRegionListCreateInput>;
  /** Actions for the "Archive.metadataCiEmailWorkList" relation: "MetadataCiEmailWork.archive"'s inverse relation */
  metadataCiEmailWorkList?: InputMaybe<ArchiveNestedMetadataCiEmailWorkListCreateInput>;
  /** Actions for the "Archive.metadataCiTelWorkList" relation: "MetadataCiTelWork.archive"'s inverse relation */
  metadataCiTelWorkList?: InputMaybe<ArchiveNestedMetadataCiTelWorkListCreateInput>;
  /** Actions for the "Archive.metadataCityList" relation: "MetadataCity.archive"'s inverse relation */
  metadataCityList?: InputMaybe<ArchiveNestedMetadataCityListCreateInput>;
  /** Actions for the "Archive.metadataCiUrlWorkList" relation: "MetadataCiUrlWork.archive"'s inverse relation */
  metadataCiUrlWorkList?: InputMaybe<ArchiveNestedMetadataCiUrlWorkListCreateInput>;
  /** Actions for the "Archive.metadataClassifyList" relation: "MetadataClassify.archive"'s inverse relation */
  metadataClassifyList?: InputMaybe<ArchiveNestedMetadataClassifyListCreateInput>;
  /** Actions for the "Archive.metadataCompanyList" relation: "MetadataCompany.archive"'s inverse relation */
  metadataCompanyList?: InputMaybe<ArchiveNestedMetadataCompanyListCreateInput>;
  /** Actions for the "Archive.metadataCompanyShortNameList" relation: "MetadataCompanyShortName.archive"'s inverse relation */
  metadataCompanyShortNameList?: InputMaybe<ArchiveNestedMetadataCompanyShortNameListCreateInput>;
  /** Actions for the "Archive.metadataConfidentialityList" relation: "MetadataConfidentiality.archive"'s inverse relation */
  metadataConfidentialityList?: InputMaybe<ArchiveNestedMetadataConfidentialityListCreateInput>;
  /** Actions for the "Archive.metadataContainedInList" relation: "MetadataContainedIn.archive"'s inverse relation */
  metadataContainedInList?: InputMaybe<ArchiveNestedMetadataContainedInListCreateInput>;
  /** Actions for the "Archive.metadataContentMediaKindList" relation: "MetadataContentMediaKind.archive"'s inverse relation */
  metadataContentMediaKindList?: InputMaybe<ArchiveNestedMetadataContentMediaKindListCreateInput>;
  /** Actions for the "Archive.metadataContentValueList" relation: "MetadataContentValue.archive"'s inverse relation */
  metadataContentValueList?: InputMaybe<ArchiveNestedMetadataContentValueListCreateInput>;
  /** Actions for the "Archive.metadataCopyrightLayerList" relation: "MetadataCopyrightLayer.archive"'s inverse relation */
  metadataCopyrightLayerList?: InputMaybe<ArchiveNestedMetadataCopyrightLayerListCreateInput>;
  /** Actions for the "Archive.metadataCountryCodeList" relation: "MetadataCountryCode.archive"'s inverse relation */
  metadataCountryCodeList?: InputMaybe<ArchiveNestedMetadataCountryCodeListCreateInput>;
  /** Actions for the "Archive.metadataCountryList" relation: "MetadataCountry.archive"'s inverse relation */
  metadataCountryList?: InputMaybe<ArchiveNestedMetadataCountryListCreateInput>;
  /** Actions for the "Archive.metadataCreatedTimeList" relation: "MetadataCreatedTime.archive"'s inverse relation */
  metadataCreatedTimeList?: InputMaybe<ArchiveNestedMetadataCreatedTimeListCreateInput>;
  /** Actions for the "Archive.metadataCreatorList" relation: "MetadataCreator.archive"'s inverse relation */
  metadataCreatorList?: InputMaybe<ArchiveNestedMetadataCreatorListCreateInput>;
  /** Actions for the "Archive.metadataCreatorToolList" relation: "MetadataCreatorTool.archive"'s inverse relation */
  metadataCreatorToolList?: InputMaybe<ArchiveNestedMetadataCreatorToolListCreateInput>;
  /** Actions for the "Archive.metadataCreditList" relation: "MetadataCredit.archive"'s inverse relation */
  metadataCreditList?: InputMaybe<ArchiveNestedMetadataCreditListCreateInput>;
  /** Actions for the "Archive.metadataCustomField14List" relation: "MetadataCustomField14.archive"'s inverse relation */
  metadataCustomField14List?: InputMaybe<ArchiveNestedMetadataCustomField14ListCreateInput>;
  /** Actions for the "Archive.metadataCustomField15List" relation: "MetadataCustomField15.archive"'s inverse relation */
  metadataCustomField15List?: InputMaybe<ArchiveNestedMetadataCustomField15ListCreateInput>;
  /** Actions for the "Archive.metadataCustomField16List" relation: "MetadataCustomField16.archive"'s inverse relation */
  metadataCustomField16List?: InputMaybe<ArchiveNestedMetadataCustomField16ListCreateInput>;
  /** Actions for the "Archive.metadataCustomField17List" relation: "MetadataCustomField17.archive"'s inverse relation */
  metadataCustomField17List?: InputMaybe<ArchiveNestedMetadataCustomField17ListCreateInput>;
  /** Actions for the "Archive.metadataCustomField20List" relation: "MetadataCustomField20.archive"'s inverse relation */
  metadataCustomField20List?: InputMaybe<ArchiveNestedMetadataCustomField20ListCreateInput>;
  /** Actions for the "Archive.metadataCustomField5List" relation: "MetadataCustomField5.archive"'s inverse relation */
  metadataCustomField5List?: InputMaybe<ArchiveNestedMetadataCustomField5ListCreateInput>;
  /** Actions for the "Archive.metadataCustomField6List" relation: "MetadataCustomField6.archive"'s inverse relation */
  metadataCustomField6List?: InputMaybe<ArchiveNestedMetadataCustomField6ListCreateInput>;
  /** Actions for the "Archive.metadataCustomField7List" relation: "MetadataCustomField7.archive"'s inverse relation */
  metadataCustomField7List?: InputMaybe<ArchiveNestedMetadataCustomField7ListCreateInput>;
  /** Actions for the "Archive.metadataCustomField9List" relation: "MetadataCustomField9.archive"'s inverse relation */
  metadataCustomField9List?: InputMaybe<ArchiveNestedMetadataCustomField9ListCreateInput>;
  /** Actions for the "Archive.metadataDateCreatedList" relation: "MetadataDateCreated.archive"'s inverse relation */
  metadataDateCreatedList?: InputMaybe<ArchiveNestedMetadataDateCreatedListCreateInput>;
  /** Actions for the "Archive.metadataDateTimeDigitizedList" relation: "MetadataDateTimeDigitized.archive"'s inverse relation */
  metadataDateTimeDigitizedList?: InputMaybe<ArchiveNestedMetadataDateTimeDigitizedListCreateInput>;
  /** Actions for the "Archive.metadataDateTimeOriginalList" relation: "MetadataDateTimeOriginal.archive"'s inverse relation */
  metadataDateTimeOriginalList?: InputMaybe<ArchiveNestedMetadataDateTimeOriginalListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryAccountList" relation: "MetadataDeliveryAccount.archive"'s inverse relation */
  metadataDeliveryAccountList?: InputMaybe<ArchiveNestedMetadataDeliveryAccountListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryCompanyList" relation: "MetadataDeliveryCompany.archive"'s inverse relation */
  metadataDeliveryCompanyList?: InputMaybe<ArchiveNestedMetadataDeliveryCompanyListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryCopyrightList" relation: "MetadataDeliveryCopyright.archive"'s inverse relation */
  metadataDeliveryCopyrightList?: InputMaybe<ArchiveNestedMetadataDeliveryCopyrightListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryDateFolderList" relation: "MetadataDeliveryDateFolder.archive"'s inverse relation */
  metadataDeliveryDateFolderList?: InputMaybe<ArchiveNestedMetadataDeliveryDateFolderListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryDateTimeList" relation: "MetadataDeliveryDateTime.archive"'s inverse relation */
  metadataDeliveryDateTimeList?: InputMaybe<ArchiveNestedMetadataDeliveryDateTimeListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryDcCreatorList" relation: "MetadataDeliveryDcCreator.archive"'s inverse relation */
  metadataDeliveryDcCreatorList?: InputMaybe<ArchiveNestedMetadataDeliveryDcCreatorListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryDcRightsList" relation: "MetadataDeliveryDcRights.archive"'s inverse relation */
  metadataDeliveryDcRightsList?: InputMaybe<ArchiveNestedMetadataDeliveryDcRightsListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryFileTypeList" relation: "MetadataDeliveryFileType.archive"'s inverse relation */
  metadataDeliveryFileTypeList?: InputMaybe<ArchiveNestedMetadataDeliveryFileTypeListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryFolderList" relation: "MetadataDeliveryFolder.archive"'s inverse relation */
  metadataDeliveryFolderList?: InputMaybe<ArchiveNestedMetadataDeliveryFolderListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryKindList" relation: "MetadataDeliveryKind.archive"'s inverse relation */
  metadataDeliveryKindList?: InputMaybe<ArchiveNestedMetadataDeliveryKindListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryPathList" relation: "MetadataDeliveryPath.archive"'s inverse relation */
  metadataDeliveryPathList?: InputMaybe<ArchiveNestedMetadataDeliveryPathListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryPersonShownintheImageList" relation: "MetadataDeliveryPersonShownintheImage.archive"'s inverse relation */
  metadataDeliveryPersonShownintheImageList?: InputMaybe<ArchiveNestedMetadataDeliveryPersonShownintheImageListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryPhotoshopCreditList" relation: "MetadataDeliveryPhotoshopCredit.archive"'s inverse relation */
  metadataDeliveryPhotoshopCreditList?: InputMaybe<ArchiveNestedMetadataDeliveryPhotoshopCreditListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryPhotoshopSourceList" relation: "MetadataDeliveryPhotoshopSource.archive"'s inverse relation */
  metadataDeliveryPhotoshopSourceList?: InputMaybe<ArchiveNestedMetadataDeliveryPhotoshopSourceListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryServiceList" relation: "MetadataDeliveryService.archive"'s inverse relation */
  metadataDeliveryServiceList?: InputMaybe<ArchiveNestedMetadataDeliveryServiceListCreateInput>;
  /** Actions for the "Archive.metadataDeliverySubjectList" relation: "MetadataDeliverySubject.archive"'s inverse relation */
  metadataDeliverySubjectList?: InputMaybe<ArchiveNestedMetadataDeliverySubjectListCreateInput>;
  /** Actions for the "Archive.metadataDeliveryUnderSubjectList" relation: "MetadataDeliveryUnderSubject.archive"'s inverse relation */
  metadataDeliveryUnderSubjectList?: InputMaybe<ArchiveNestedMetadataDeliveryUnderSubjectListCreateInput>;
  /** Actions for the "Archive.metadataDepartmentList" relation: "MetadataDepartment.archive"'s inverse relation */
  metadataDepartmentList?: InputMaybe<ArchiveNestedMetadataDepartmentListCreateInput>;
  /** Actions for the "Archive.metadataDescriptionList" relation: "MetadataDescription.archive"'s inverse relation */
  metadataDescriptionList?: InputMaybe<ArchiveNestedMetadataDescriptionListCreateInput>;
  /** Actions for the "Archive.metadataDigitalAssetUrlList" relation: "MetadataDigitalAssetUrl.archive"'s inverse relation */
  metadataDigitalAssetUrlList?: InputMaybe<ArchiveNestedMetadataDigitalAssetUrlListCreateInput>;
  /** Actions for the "Archive.metadataDivisionList" relation: "MetadataDivision.archive"'s inverse relation */
  metadataDivisionList?: InputMaybe<ArchiveNestedMetadataDivisionListCreateInput>;
  /** Actions for the "Archive.metadataDocumentTextList" relation: "MetadataDocumentText.archive"'s inverse relation */
  metadataDocumentTextList?: InputMaybe<ArchiveNestedMetadataDocumentTextListCreateInput>;
  /** Actions for the "Archive.metadataEditionList" relation: "MetadataEdition.archive"'s inverse relation */
  metadataEditionList?: InputMaybe<ArchiveNestedMetadataEditionListCreateInput>;
  /** Actions for the "Archive.metadataEditorialVersionList" relation: "MetadataEditorialVersion.archive"'s inverse relation */
  metadataEditorialVersionList?: InputMaybe<ArchiveNestedMetadataEditorialVersionListCreateInput>;
  /** Actions for the "Archive.metadataEditStatusList" relation: "MetadataEditStatus.archive"'s inverse relation */
  metadataEditStatusList?: InputMaybe<ArchiveNestedMetadataEditStatusListCreateInput>;
  /** Actions for the "Archive.metadataEnvironnementPhotoList" relation: "MetadataEnvironnementPhoto.archive"'s inverse relation */
  metadataEnvironnementPhotoList?: InputMaybe<ArchiveNestedMetadataEnvironnementPhotoListCreateInput>;
  /** Actions for the "Archive.metadataEquipmentInstitutionList" relation: "MetadataEquipmentInstitution.archive"'s inverse relation */
  metadataEquipmentInstitutionList?: InputMaybe<ArchiveNestedMetadataEquipmentInstitutionListCreateInput>;
  /** Actions for the "Archive.metadataEquipmentManufacturerList" relation: "MetadataEquipmentManufacturer.archive"'s inverse relation */
  metadataEquipmentManufacturerList?: InputMaybe<ArchiveNestedMetadataEquipmentManufacturerListCreateInput>;
  /** Actions for the "Archive.metadataEventList" relation: "MetadataEvent.archive"'s inverse relation */
  metadataEventList?: InputMaybe<ArchiveNestedMetadataEventListCreateInput>;
  /** Actions for the "Archive.metadataExifList" relation: "MetadataExif.archive"'s inverse relation */
  metadataExifList?: InputMaybe<ArchiveNestedMetadataExifListCreateInput>;
  /** Actions for the "Archive.metadataFabStorageList" relation: "MetadataFabStorage.archive"'s inverse relation */
  metadataFabStorageList?: InputMaybe<ArchiveNestedMetadataFabStorageListCreateInput>;
  /** Actions for the "Archive.metadataFileCheckForList" relation: "MetadataFileCheckFor.archive"'s inverse relation */
  metadataFileCheckForList?: InputMaybe<ArchiveNestedMetadataFileCheckForListCreateInput>;
  /** Actions for the "Archive.metadataFileInfoBitsPerPixelList" relation: "MetadataFileInfoBitsPerPixel.archive"'s inverse relation */
  metadataFileInfoBitsPerPixelList?: InputMaybe<ArchiveNestedMetadataFileInfoBitsPerPixelListCreateInput>;
  /** Actions for the "Archive.metadataFileInfoFramesList" relation: "MetadataFileInfoFrames.archive"'s inverse relation */
  metadataFileInfoFramesList?: InputMaybe<ArchiveNestedMetadataFileInfoFramesListCreateInput>;
  /** Actions for the "Archive.metadataFileInfoPhotometricInterpretationList" relation: "MetadataFileInfoPhotometricInterpretation.archive"'s inverse relation */
  metadataFileInfoPhotometricInterpretationList?: InputMaybe<ArchiveNestedMetadataFileInfoPhotometricInterpretationListCreateInput>;
  /** Actions for the "Archive.metadataFileInfoTransferSyntaxList" relation: "MetadataFileInfoTransferSyntax.archive"'s inverse relation */
  metadataFileInfoTransferSyntaxList?: InputMaybe<ArchiveNestedMetadataFileInfoTransferSyntaxListCreateInput>;
  /** Actions for the "Archive.metadataFileStageList" relation: "MetadataFileStage.archive"'s inverse relation */
  metadataFileStageList?: InputMaybe<ArchiveNestedMetadataFileStageListCreateInput>;
  /** Actions for the "Archive.metadataFileStatusList" relation: "MetadataFileStatus.archive"'s inverse relation */
  metadataFileStatusList?: InputMaybe<ArchiveNestedMetadataFileStatusListCreateInput>;
  /** Actions for the "Archive.metadataFileTypeList" relation: "MetadataFileType.archive"'s inverse relation */
  metadataFileTypeList?: InputMaybe<ArchiveNestedMetadataFileTypeListCreateInput>;
  /** Actions for the "Archive.metadataFixtureIdentifierList" relation: "MetadataFixtureIdentifier.archive"'s inverse relation */
  metadataFixtureIdentifierList?: InputMaybe<ArchiveNestedMetadataFixtureIdentifierListCreateInput>;
  /** Actions for the "Archive.metadataFormatList" relation: "MetadataFormat.archive"'s inverse relation */
  metadataFormatList?: InputMaybe<ArchiveNestedMetadataFormatListCreateInput>;
  /** Actions for the "Archive.metadataGpsLatitudeList" relation: "MetadataGpsLatitude.archive"'s inverse relation */
  metadataGpsLatitudeList?: InputMaybe<ArchiveNestedMetadataGpsLatitudeListCreateInput>;
  /** Actions for the "Archive.metadataGpsLongitudeList" relation: "MetadataGpsLongitude.archive"'s inverse relation */
  metadataGpsLongitudeList?: InputMaybe<ArchiveNestedMetadataGpsLongitudeListCreateInput>;
  /** Actions for the "Archive.metadataGpsProcessingMethodList" relation: "MetadataGpsProcessingMethod.archive"'s inverse relation */
  metadataGpsProcessingMethodList?: InputMaybe<ArchiveNestedMetadataGpsProcessingMethodListCreateInput>;
  /** Actions for the "Archive.metadataHeaderList" relation: "MetadataHeader.archive"'s inverse relation */
  metadataHeaderList?: InputMaybe<ArchiveNestedMetadataHeaderListCreateInput>;
  /** Actions for the "Archive.metadataHeaderPrismaList" relation: "MetadataHeaderPrisma.archive"'s inverse relation */
  metadataHeaderPrismaList?: InputMaybe<ArchiveNestedMetadataHeaderPrismaListCreateInput>;
  /** Actions for the "Archive.metadataHeadlineList" relation: "MetadataHeadline.archive"'s inverse relation */
  metadataHeadlineList?: InputMaybe<ArchiveNestedMetadataHeadlineListCreateInput>;
  /** Actions for the "Archive.metadataHistoryList" relation: "MetadataHistory.archive"'s inverse relation */
  metadataHistoryList?: InputMaybe<ArchiveNestedMetadataHistoryListCreateInput>;
  /** Actions for the "Archive.metadataIccProfileList" relation: "MetadataIccProfile.archive"'s inverse relation */
  metadataIccProfileList?: InputMaybe<ArchiveNestedMetadataIccProfileListCreateInput>;
  /** Actions for the "Archive.metadataIdAssignmentList" relation: "MetadataIdAssignment.archive"'s inverse relation */
  metadataIdAssignmentList?: InputMaybe<ArchiveNestedMetadataIdAssignmentListCreateInput>;
  /** Actions for the "Archive.metadataIdMediaContentList" relation: "MetadataIdMediaContent.archive"'s inverse relation */
  metadataIdMediaContentList?: InputMaybe<ArchiveNestedMetadataIdMediaContentListCreateInput>;
  /** Actions for the "Archive.metadataIdPhotoPrismaList" relation: "MetadataIdPhotoPrisma.archive"'s inverse relation */
  metadataIdPhotoPrismaList?: InputMaybe<ArchiveNestedMetadataIdPhotoPrismaListCreateInput>;
  /** Actions for the "Archive.metadataIdSubjectList" relation: "MetadataIdSubject.archive"'s inverse relation */
  metadataIdSubjectList?: InputMaybe<ArchiveNestedMetadataIdSubjectListCreateInput>;
  /** Actions for the "Archive.metadataImageNotesList" relation: "MetadataImageNotes.archive"'s inverse relation */
  metadataImageNotesList?: InputMaybe<ArchiveNestedMetadataImageNotesListCreateInput>;
  /** Actions for the "Archive.metadataImageUniqueIdList" relation: "MetadataImageUniqueId.archive"'s inverse relation */
  metadataImageUniqueIdList?: InputMaybe<ArchiveNestedMetadataImageUniqueIdListCreateInput>;
  /** Actions for the "Archive.metadataInstructionsList" relation: "MetadataInstructions.archive"'s inverse relation */
  metadataInstructionsList?: InputMaybe<ArchiveNestedMetadataInstructionsListCreateInput>;
  /** Actions for the "Archive.metadataIntellectualGenreList" relation: "MetadataIntellectualGenre.archive"'s inverse relation */
  metadataIntellectualGenreList?: InputMaybe<ArchiveNestedMetadataIntellectualGenreListCreateInput>;
  /** Actions for the "Archive.metadataIsoSpeedRatingsList" relation: "MetadataIsoSpeedRatings.archive"'s inverse relation */
  metadataIsoSpeedRatingsList?: InputMaybe<ArchiveNestedMetadataIsoSpeedRatingsListCreateInput>;
  /** Actions for the "Archive.metadataIssueList" relation: "MetadataIssue.archive"'s inverse relation */
  metadataIssueList?: InputMaybe<ArchiveNestedMetadataIssueListCreateInput>;
  /** Actions for the "Archive.metadataJobIdList" relation: "MetadataJobId.archive"'s inverse relation */
  metadataJobIdList?: InputMaybe<ArchiveNestedMetadataJobIdListCreateInput>;
  /** Actions for the "Archive.metadataJobProcessingList" relation: "MetadataJobProcessing.archive"'s inverse relation */
  metadataJobProcessingList?: InputMaybe<ArchiveNestedMetadataJobProcessingListCreateInput>;
  /** Actions for the "Archive.metadataLayoutPictureEditingList" relation: "MetadataLayoutPictureEditing.archive"'s inverse relation */
  metadataLayoutPictureEditingList?: InputMaybe<ArchiveNestedMetadataLayoutPictureEditingListCreateInput>;
  /** Actions for the "Archive.metadataLayoutStorageList" relation: "MetadataLayoutStorage.archive"'s inverse relation */
  metadataLayoutStorageList?: InputMaybe<ArchiveNestedMetadataLayoutStorageListCreateInput>;
  /** Actions for the "Archive.metadataLicenseList" relation: "MetadataLicense.archive"'s inverse relation */
  metadataLicenseList?: InputMaybe<ArchiveNestedMetadataLicenseListCreateInput>;
  /** Actions for the "Archive.metadataLinksList" relation: "MetadataLinks.archive"'s inverse relation */
  metadataLinksList?: InputMaybe<ArchiveNestedMetadataLinksListCreateInput>;
  /** Actions for the "Archive.metadataLocalCaptionList" relation: "MetadataLocalCaption.archive"'s inverse relation */
  metadataLocalCaptionList?: InputMaybe<ArchiveNestedMetadataLocalCaptionListCreateInput>;
  /** Actions for the "Archive.metadataLocationList" relation: "MetadataLocation.archive"'s inverse relation */
  metadataLocationList?: InputMaybe<ArchiveNestedMetadataLocationListCreateInput>;
  /** Actions for the "Archive.metadataMakeList" relation: "MetadataMake.archive"'s inverse relation */
  metadataMakeList?: InputMaybe<ArchiveNestedMetadataMakeListCreateInput>;
  /** Actions for the "Archive.metadataManifestList" relation: "MetadataManifest.archive"'s inverse relation */
  metadataManifestList?: InputMaybe<ArchiveNestedMetadataManifestListCreateInput>;
  /** Actions for the "Archive.metadataMarkedList" relation: "MetadataMarked.archive"'s inverse relation */
  metadataMarkedList?: InputMaybe<ArchiveNestedMetadataMarkedListCreateInput>;
  /** Actions for the "Archive.metadataMasterDocumentIdList" relation: "MetadataMasterDocumentId.archive"'s inverse relation */
  metadataMasterDocumentIdList?: InputMaybe<ArchiveNestedMetadataMasterDocumentIdListCreateInput>;
  /** Actions for the "Archive.metadataMaxAvailHeightList" relation: "MetadataMaxAvailHeight.archive"'s inverse relation */
  metadataMaxAvailHeightList?: InputMaybe<ArchiveNestedMetadataMaxAvailHeightListCreateInput>;
  /** Actions for the "Archive.metadataMaxAvailWidthList" relation: "MetadataMaxAvailWidth.archive"'s inverse relation */
  metadataMaxAvailWidthList?: InputMaybe<ArchiveNestedMetadataMaxAvailWidthListCreateInput>;
  /** Actions for the "Archive.metadataMinorModelAgeDisclosureList" relation: "MetadataMinorModelAgeDisclosure.archive"'s inverse relation */
  metadataMinorModelAgeDisclosureList?: InputMaybe<ArchiveNestedMetadataMinorModelAgeDisclosureListCreateInput>;
  /** Actions for the "Archive.metadataModelAgeList" relation: "MetadataModelAge.archive"'s inverse relation */
  metadataModelAgeList?: InputMaybe<ArchiveNestedMetadataModelAgeListCreateInput>;
  /** Actions for the "Archive.metadataModelList" relation: "MetadataModel.archive"'s inverse relation */
  metadataModelList?: InputMaybe<ArchiveNestedMetadataModelListCreateInput>;
  /** Actions for the "Archive.metadataModelReleaseIdList" relation: "MetadataModelReleaseId.archive"'s inverse relation */
  metadataModelReleaseIdList?: InputMaybe<ArchiveNestedMetadataModelReleaseIdListCreateInput>;
  /** Actions for the "Archive.metadataModelReleaseStatusList" relation: "MetadataModelReleaseStatus.archive"'s inverse relation */
  metadataModelReleaseStatusList?: InputMaybe<ArchiveNestedMetadataModelReleaseStatusListCreateInput>;
  /** Actions for the "Archive.metadataMorePermissionsList" relation: "MetadataMorePermissions.archive"'s inverse relation */
  metadataMorePermissionsList?: InputMaybe<ArchiveNestedMetadataMorePermissionsListCreateInput>;
  /** Actions for the "Archive.metadataObjectCycleList" relation: "MetadataObjectCycle.archive"'s inverse relation */
  metadataObjectCycleList?: InputMaybe<ArchiveNestedMetadataObjectCycleListCreateInput>;
  /** Actions for the "Archive.metadataOrganisationInImageNameList" relation: "MetadataOrganisationInImageName.archive"'s inverse relation */
  metadataOrganisationInImageNameList?: InputMaybe<ArchiveNestedMetadataOrganisationInImageNameListCreateInput>;
  /** Actions for the "Archive.metadataOriginalColorList" relation: "MetadataOriginalColor.archive"'s inverse relation */
  metadataOriginalColorList?: InputMaybe<ArchiveNestedMetadataOriginalColorListCreateInput>;
  /** Actions for the "Archive.metadataOriginalIccProfileList" relation: "MetadataOriginalIccProfile.archive"'s inverse relation */
  metadataOriginalIccProfileList?: InputMaybe<ArchiveNestedMetadataOriginalIccProfileListCreateInput>;
  /** Actions for the "Archive.metadataOriginalNameList" relation: "MetadataOriginalName.archive"'s inverse relation */
  metadataOriginalNameList?: InputMaybe<ArchiveNestedMetadataOriginalNameListCreateInput>;
  /** Actions for the "Archive.metadataOriginalWeigthList" relation: "MetadataOriginalWeigth.archive"'s inverse relation */
  metadataOriginalWeigthList?: InputMaybe<ArchiveNestedMetadataOriginalWeigthListCreateInput>;
  /** Actions for the "Archive.metadataOwnerIdList" relation: "MetadataOwnerId.archive"'s inverse relation */
  metadataOwnerIdList?: InputMaybe<ArchiveNestedMetadataOwnerIdListCreateInput>;
  /** Actions for the "Archive.metadataOwnerList" relation: "MetadataOwner.archive"'s inverse relation */
  metadataOwnerList?: InputMaybe<ArchiveNestedMetadataOwnerListCreateInput>;
  /** Actions for the "Archive.metadataPageList" relation: "MetadataPage.archive"'s inverse relation */
  metadataPageList?: InputMaybe<ArchiveNestedMetadataPageListCreateInput>;
  /** Actions for the "Archive.metadataPagePrismaList" relation: "MetadataPagePrisma.archive"'s inverse relation */
  metadataPagePrismaList?: InputMaybe<ArchiveNestedMetadataPagePrismaListCreateInput>;
  /** Actions for the "Archive.metadataPaintBasedCorrectionsList" relation: "MetadataPaintBasedCorrections.archive"'s inverse relation */
  metadataPaintBasedCorrectionsList?: InputMaybe<ArchiveNestedMetadataPaintBasedCorrectionsListCreateInput>;
  /** Actions for the "Archive.metadataPatientDobList" relation: "MetadataPatientDob.archive"'s inverse relation */
  metadataPatientDobList?: InputMaybe<ArchiveNestedMetadataPatientDobListCreateInput>;
  /** Actions for the "Archive.metadataPatientIdList" relation: "MetadataPatientId.archive"'s inverse relation */
  metadataPatientIdList?: InputMaybe<ArchiveNestedMetadataPatientIdListCreateInput>;
  /** Actions for the "Archive.metadataPatientNameList" relation: "MetadataPatientName.archive"'s inverse relation */
  metadataPatientNameList?: InputMaybe<ArchiveNestedMetadataPatientNameListCreateInput>;
  /** Actions for the "Archive.metadataPatientSexList" relation: "MetadataPatientSex.archive"'s inverse relation */
  metadataPatientSexList?: InputMaybe<ArchiveNestedMetadataPatientSexListCreateInput>;
  /** Actions for the "Archive.metadataPersonInImageList" relation: "MetadataPersonInImage.archive"'s inverse relation */
  metadataPersonInImageList?: InputMaybe<ArchiveNestedMetadataPersonInImageListCreateInput>;
  /** Actions for the "Archive.metadataPhotoStorageList" relation: "MetadataPhotoStorage.archive"'s inverse relation */
  metadataPhotoStorageList?: InputMaybe<ArchiveNestedMetadataPhotoStorageListCreateInput>;
  /** Actions for the "Archive.metadataPrepressPictureEditingList" relation: "MetadataPrepressPictureEditing.archive"'s inverse relation */
  metadataPrepressPictureEditingList?: InputMaybe<ArchiveNestedMetadataPrepressPictureEditingListCreateInput>;
  /** Actions for the "Archive.metadataPriceLevelList" relation: "MetadataPriceLevel.archive"'s inverse relation */
  metadataPriceLevelList?: InputMaybe<ArchiveNestedMetadataPriceLevelListCreateInput>;
  /** Actions for the "Archive.metadataPrintingProfileList" relation: "MetadataPrintingProfile.archive"'s inverse relation */
  metadataPrintingProfileList?: InputMaybe<ArchiveNestedMetadataPrintingProfileListCreateInput>;
  /** Actions for the "Archive.metadataPrismaProductionList" relation: "MetadataPrismaProduction.archive"'s inverse relation */
  metadataPrismaProductionList?: InputMaybe<ArchiveNestedMetadataPrismaProductionListCreateInput>;
  /** Actions for the "Archive.metadataProcessHistoryList" relation: "MetadataProcessHistory.archive"'s inverse relation */
  metadataProcessHistoryList?: InputMaybe<ArchiveNestedMetadataProcessHistoryListCreateInput>;
  /** Actions for the "Archive.metadataProcessParameterList" relation: "MetadataProcessParameter.archive"'s inverse relation */
  metadataProcessParameterList?: InputMaybe<ArchiveNestedMetadataProcessParameterListCreateInput>;
  /** Actions for the "Archive.metadataProcessStatusList" relation: "MetadataProcessStatus.archive"'s inverse relation */
  metadataProcessStatusList?: InputMaybe<ArchiveNestedMetadataProcessStatusListCreateInput>;
  /** Actions for the "Archive.metadataProductList" relation: "MetadataProduct.archive"'s inverse relation */
  metadataProductList?: InputMaybe<ArchiveNestedMetadataProductListCreateInput>;
  /** Actions for the "Archive.metadataProductShortNameList" relation: "MetadataProductShortName.archive"'s inverse relation */
  metadataProductShortNameList?: InputMaybe<ArchiveNestedMetadataProductShortNameListCreateInput>;
  /** Actions for the "Archive.metadataProductsList" relation: "MetadataProducts.archive"'s inverse relation */
  metadataProductsList?: InputMaybe<ArchiveNestedMetadataProductsListCreateInput>;
  /** Actions for the "Archive.metadataProduitsList" relation: "MetadataProduits.archive"'s inverse relation */
  metadataProduitsList?: InputMaybe<ArchiveNestedMetadataProduitsListCreateInput>;
  /** Actions for the "Archive.metadataProgramVersionList" relation: "MetadataProgramVersion.archive"'s inverse relation */
  metadataProgramVersionList?: InputMaybe<ArchiveNestedMetadataProgramVersionListCreateInput>;
  /** Actions for the "Archive.metadataPropertyReleaseIdList" relation: "MetadataPropertyReleaseId.archive"'s inverse relation */
  metadataPropertyReleaseIdList?: InputMaybe<ArchiveNestedMetadataPropertyReleaseIdListCreateInput>;
  /** Actions for the "Archive.metadataPropertyReleaseStatusList" relation: "MetadataPropertyReleaseStatus.archive"'s inverse relation */
  metadataPropertyReleaseStatusList?: InputMaybe<ArchiveNestedMetadataPropertyReleaseStatusListCreateInput>;
  /** Actions for the "Archive.metadataPublisherList" relation: "MetadataPublisher.archive"'s inverse relation */
  metadataPublisherList?: InputMaybe<ArchiveNestedMetadataPublisherListCreateInput>;
  /** Actions for the "Archive.metadataPublishingIssueList" relation: "MetadataPublishingIssue.archive"'s inverse relation */
  metadataPublishingIssueList?: InputMaybe<ArchiveNestedMetadataPublishingIssueListCreateInput>;
  /** Actions for the "Archive.metadataPublishingSubjectList" relation: "MetadataPublishingSubject.archive"'s inverse relation */
  metadataPublishingSubjectList?: InputMaybe<ArchiveNestedMetadataPublishingSubjectListCreateInput>;
  /** Actions for the "Archive.metadataQualifiedUsePrismaByList" relation: "MetadataQualifiedUsePrismaBy.archive"'s inverse relation */
  metadataQualifiedUsePrismaByList?: InputMaybe<ArchiveNestedMetadataQualifiedUsePrismaByListCreateInput>;
  /** Actions for the "Archive.metadataQualifiedUsePrismaDateList" relation: "MetadataQualifiedUsePrismaDate.archive"'s inverse relation */
  metadataQualifiedUsePrismaDateList?: InputMaybe<ArchiveNestedMetadataQualifiedUsePrismaDateListCreateInput>;
  /** Actions for the "Archive.metadataQualifiedUsePrismaDurationList" relation: "MetadataQualifiedUsePrismaDuration.archive"'s inverse relation */
  metadataQualifiedUsePrismaDurationList?: InputMaybe<ArchiveNestedMetadataQualifiedUsePrismaDurationListCreateInput>;
  /** Actions for the "Archive.metadataQualifiedUsePrismaSupportList" relation: "MetadataQualifiedUsePrismaSupport.archive"'s inverse relation */
  metadataQualifiedUsePrismaSupportList?: InputMaybe<ArchiveNestedMetadataQualifiedUsePrismaSupportListCreateInput>;
  /** Actions for the "Archive.metadataRatingList" relation: "MetadataRating.archive"'s inverse relation */
  metadataRatingList?: InputMaybe<ArchiveNestedMetadataRatingListCreateInput>;
  /** Actions for the "Archive.metadataReferenceDateList" relation: "MetadataReferenceDate.archive"'s inverse relation */
  metadataReferenceDateList?: InputMaybe<ArchiveNestedMetadataReferenceDateListCreateInput>;
  /** Actions for the "Archive.metadataReferenceNumberList" relation: "MetadataReferenceNumber.archive"'s inverse relation */
  metadataReferenceNumberList?: InputMaybe<ArchiveNestedMetadataReferenceNumberListCreateInput>;
  /** Actions for the "Archive.metadataReferenceServiceList" relation: "MetadataReferenceService.archive"'s inverse relation */
  metadataReferenceServiceList?: InputMaybe<ArchiveNestedMetadataReferenceServiceListCreateInput>;
  /** Actions for the "Archive.metadataReleaseDateList" relation: "MetadataReleaseDate.archive"'s inverse relation */
  metadataReleaseDateList?: InputMaybe<ArchiveNestedMetadataReleaseDateListCreateInput>;
  /** Actions for the "Archive.metadataReleaseTimeList" relation: "MetadataReleaseTime.archive"'s inverse relation */
  metadataReleaseTimeList?: InputMaybe<ArchiveNestedMetadataReleaseTimeListCreateInput>;
  /** Actions for the "Archive.metadataRequiredPermissionList" relation: "MetadataRequiredPermission.archive"'s inverse relation */
  metadataRequiredPermissionList?: InputMaybe<ArchiveNestedMetadataRequiredPermissionListCreateInput>;
  /** Actions for the "Archive.metadataResolutionKindList" relation: "MetadataResolutionKind.archive"'s inverse relation */
  metadataResolutionKindList?: InputMaybe<ArchiveNestedMetadataResolutionKindListCreateInput>;
  /** Actions for the "Archive.metadataRightsList" relation: "MetadataRights.archive"'s inverse relation */
  metadataRightsList?: InputMaybe<ArchiveNestedMetadataRightsListCreateInput>;
  /** Actions for the "Archive.metadataRoyaltyfreeList" relation: "MetadataRoyaltyfree.archive"'s inverse relation */
  metadataRoyaltyfreeList?: InputMaybe<ArchiveNestedMetadataRoyaltyfreeListCreateInput>;
  /** Actions for the "Archive.metadataSceneList" relation: "MetadataScene.archive"'s inverse relation */
  metadataSceneList?: InputMaybe<ArchiveNestedMetadataSceneListCreateInput>;
  /** Actions for the "Archive.metadataSectionList" relation: "MetadataSection.archive"'s inverse relation */
  metadataSectionList?: InputMaybe<ArchiveNestedMetadataSectionListCreateInput>;
  /** Actions for the "Archive.metadataSentToList" relation: "MetadataSentTo.archive"'s inverse relation */
  metadataSentToList?: InputMaybe<ArchiveNestedMetadataSentToListCreateInput>;
  /** Actions for the "Archive.metadataSerialNumberList" relation: "MetadataSerialNumber.archive"'s inverse relation */
  metadataSerialNumberList?: InputMaybe<ArchiveNestedMetadataSerialNumberListCreateInput>;
  /** Actions for the "Archive.metadataSeriesDateTimeList" relation: "MetadataSeriesDateTime.archive"'s inverse relation */
  metadataSeriesDateTimeList?: InputMaybe<ArchiveNestedMetadataSeriesDateTimeListCreateInput>;
  /** Actions for the "Archive.metadataSeriesDescriptionList" relation: "MetadataSeriesDescription.archive"'s inverse relation */
  metadataSeriesDescriptionList?: InputMaybe<ArchiveNestedMetadataSeriesDescriptionListCreateInput>;
  /** Actions for the "Archive.metadataSeriesModalityList" relation: "MetadataSeriesModality.archive"'s inverse relation */
  metadataSeriesModalityList?: InputMaybe<ArchiveNestedMetadataSeriesModalityListCreateInput>;
  /** Actions for the "Archive.metadataSeriesNumberList" relation: "MetadataSeriesNumber.archive"'s inverse relation */
  metadataSeriesNumberList?: InputMaybe<ArchiveNestedMetadataSeriesNumberListCreateInput>;
  /** Actions for the "Archive.metadataShortUniqueIdList" relation: "MetadataShortUniqueId.archive"'s inverse relation */
  metadataShortUniqueIdList?: InputMaybe<ArchiveNestedMetadataShortUniqueIdListCreateInput>;
  /** Actions for the "Archive.metadataSourceList" relation: "MetadataSource.archive"'s inverse relation */
  metadataSourceList?: InputMaybe<ArchiveNestedMetadataSourceListCreateInput>;
  /** Actions for the "Archive.metadataStateList" relation: "MetadataState.archive"'s inverse relation */
  metadataStateList?: InputMaybe<ArchiveNestedMetadataStateListCreateInput>;
  /** Actions for the "Archive.metadataStudyDateTimeList" relation: "MetadataStudyDateTime.archive"'s inverse relation */
  metadataStudyDateTimeList?: InputMaybe<ArchiveNestedMetadataStudyDateTimeListCreateInput>;
  /** Actions for the "Archive.metadataStudyDescriptionList" relation: "MetadataStudyDescription.archive"'s inverse relation */
  metadataStudyDescriptionList?: InputMaybe<ArchiveNestedMetadataStudyDescriptionListCreateInput>;
  /** Actions for the "Archive.metadataStudyIdList" relation: "MetadataStudyId.archive"'s inverse relation */
  metadataStudyIdList?: InputMaybe<ArchiveNestedMetadataStudyIdListCreateInput>;
  /** Actions for the "Archive.metadataStudyPhysicianList" relation: "MetadataStudyPhysician.archive"'s inverse relation */
  metadataStudyPhysicianList?: InputMaybe<ArchiveNestedMetadataStudyPhysicianListCreateInput>;
  /** Actions for the "Archive.metadataSubjectCodeList" relation: "MetadataSubjectCode.archive"'s inverse relation */
  metadataSubjectCodeList?: InputMaybe<ArchiveNestedMetadataSubjectCodeListCreateInput>;
  /** Actions for the "Archive.metadataSubjectList" relation: "MetadataSubject.archive"'s inverse relation */
  metadataSubjectList?: InputMaybe<ArchiveNestedMetadataSubjectListCreateInput>;
  /** Actions for the "Archive.metadataSubjectPrismaList" relation: "MetadataSubjectPrisma.archive"'s inverse relation */
  metadataSubjectPrismaList?: InputMaybe<ArchiveNestedMetadataSubjectPrismaListCreateInput>;
  /** Actions for the "Archive.metadataSupplementalCategoriesList" relation: "MetadataSupplementalCategories.archive"'s inverse relation */
  metadataSupplementalCategoriesList?: InputMaybe<ArchiveNestedMetadataSupplementalCategoriesListCreateInput>;
  /** Actions for the "Archive.metadataTargetVersionList" relation: "MetadataTargetVersion.archive"'s inverse relation */
  metadataTargetVersionList?: InputMaybe<ArchiveNestedMetadataTargetVersionListCreateInput>;
  /** Actions for the "Archive.metadataTitleList" relation: "MetadataTitle.archive"'s inverse relation */
  metadataTitleList?: InputMaybe<ArchiveNestedMetadataTitleListCreateInput>;
  /** Actions for the "Archive.metadataToneCurveList" relation: "MetadataToneCurve.archive"'s inverse relation */
  metadataToneCurveList?: InputMaybe<ArchiveNestedMetadataToneCurveListCreateInput>;
  /** Actions for the "Archive.metadataTransferredByEmailList" relation: "MetadataTransferredByEmail.archive"'s inverse relation */
  metadataTransferredByEmailList?: InputMaybe<ArchiveNestedMetadataTransferredByEmailListCreateInput>;
  /** Actions for the "Archive.metadataTransferredByFullNameList" relation: "MetadataTransferredByFullName.archive"'s inverse relation */
  metadataTransferredByFullNameList?: InputMaybe<ArchiveNestedMetadataTransferredByFullNameListCreateInput>;
  /** Actions for the "Archive.metadataTransferredByList" relation: "MetadataTransferredBy.archive"'s inverse relation */
  metadataTransferredByList?: InputMaybe<ArchiveNestedMetadataTransferredByListCreateInput>;
  /** Actions for the "Archive.metadataTransmissionReferenceList" relation: "MetadataTransmissionReference.archive"'s inverse relation */
  metadataTransmissionReferenceList?: InputMaybe<ArchiveNestedMetadataTransmissionReferenceListCreateInput>;
  /** Actions for the "Archive.metadataTypeList" relation: "MetadataType.archive"'s inverse relation */
  metadataTypeList?: InputMaybe<ArchiveNestedMetadataTypeListCreateInput>;
  /** Actions for the "Archive.metadataUndersubjectList" relation: "MetadataUndersubject.archive"'s inverse relation */
  metadataUndersubjectList?: InputMaybe<ArchiveNestedMetadataUndersubjectListCreateInput>;
  /** Actions for the "Archive.metadataUnderUnderSubjectList" relation: "MetadataUnderUnderSubject.archive"'s inverse relation */
  metadataUnderUnderSubjectList?: InputMaybe<ArchiveNestedMetadataUnderUnderSubjectListCreateInput>;
  /** Actions for the "Archive.metadataUniqueIdList" relation: "MetadataUniqueId.archive"'s inverse relation */
  metadataUniqueIdList?: InputMaybe<ArchiveNestedMetadataUniqueIdListCreateInput>;
  /** Actions for the "Archive.metadataUnitList" relation: "MetadataUnit.archive"'s inverse relation */
  metadataUnitList?: InputMaybe<ArchiveNestedMetadataUnitListCreateInput>;
  /** Actions for the "Archive.metadataUnitShortNameList" relation: "MetadataUnitShortName.archive"'s inverse relation */
  metadataUnitShortNameList?: InputMaybe<ArchiveNestedMetadataUnitShortNameListCreateInput>;
  /** Actions for the "Archive.metadataUploadedByFullNameList" relation: "MetadataUploadedByFullName.archive"'s inverse relation */
  metadataUploadedByFullNameList?: InputMaybe<ArchiveNestedMetadataUploadedByFullNameListCreateInput>;
  /** Actions for the "Archive.metadataUploadedByList" relation: "MetadataUploadedBy.archive"'s inverse relation */
  metadataUploadedByList?: InputMaybe<ArchiveNestedMetadataUploadedByListCreateInput>;
  /** Actions for the "Archive.metadataUploadTimeList" relation: "MetadataUploadTime.archive"'s inverse relation */
  metadataUploadTimeList?: InputMaybe<ArchiveNestedMetadataUploadTimeListCreateInput>;
  /** Actions for the "Archive.metadataUrgencyList" relation: "MetadataUrgency.archive"'s inverse relation */
  metadataUrgencyList?: InputMaybe<ArchiveNestedMetadataUrgencyListCreateInput>;
  /** Actions for the "Archive.metadataUsageTermsList" relation: "MetadataUsageTerms.archive"'s inverse relation */
  metadataUsageTermsList?: InputMaybe<ArchiveNestedMetadataUsageTermsListCreateInput>;
  /** Actions for the "Archive.metadataUserCommentList" relation: "MetadataUserComment.archive"'s inverse relation */
  metadataUserCommentList?: InputMaybe<ArchiveNestedMetadataUserCommentListCreateInput>;
  /** Actions for the "Archive.metadataUserDefined195List" relation: "MetadataUserDefined195.archive"'s inverse relation */
  metadataUserDefined195List?: InputMaybe<ArchiveNestedMetadataUserDefined195ListCreateInput>;
  /** Actions for the "Archive.metadataUserDefined237List" relation: "MetadataUserDefined237.archive"'s inverse relation */
  metadataUserDefined237List?: InputMaybe<ArchiveNestedMetadataUserDefined237ListCreateInput>;
  /** Actions for the "Archive.metadataUserDefined238List" relation: "MetadataUserDefined238.archive"'s inverse relation */
  metadataUserDefined238List?: InputMaybe<ArchiveNestedMetadataUserDefined238ListCreateInput>;
  /** Actions for the "Archive.metadataUserDefined239List" relation: "MetadataUserDefined239.archive"'s inverse relation */
  metadataUserDefined239List?: InputMaybe<ArchiveNestedMetadataUserDefined239ListCreateInput>;
  /** Actions for the "Archive.metadataUserDefined242List" relation: "MetadataUserDefined242.archive"'s inverse relation */
  metadataUserDefined242List?: InputMaybe<ArchiveNestedMetadataUserDefined242ListCreateInput>;
  /** Actions for the "Archive.metadataUserDefined62List" relation: "MetadataUserDefined62.archive"'s inverse relation */
  metadataUserDefined62List?: InputMaybe<ArchiveNestedMetadataUserDefined62ListCreateInput>;
  /** Actions for the "Archive.metadataValidList" relation: "MetadataValid.archive"'s inverse relation */
  metadataValidList?: InputMaybe<ArchiveNestedMetadataValidListCreateInput>;
  /** Actions for the "Archive.metadataVersionList" relation: "MetadataVersion.archive"'s inverse relation */
  metadataVersionList?: InputMaybe<ArchiveNestedMetadataVersionListCreateInput>;
  /** Actions for the "Archive.metadataWebStatementList" relation: "MetadataWebStatement.archive"'s inverse relation */
  metadataWebStatementList?: InputMaybe<ArchiveNestedMetadataWebStatementListCreateInput>;
  /** Actions for the "Archive.metadataWorkflowKindList" relation: "MetadataWorkflowKind.archive"'s inverse relation */
  metadataWorkflowKindList?: InputMaybe<ArchiveNestedMetadataWorkflowKindListCreateInput>;
  /** Actions for the "Archive.metadataXmpFileStampsList" relation: "MetadataXmpFileStamps.archive"'s inverse relation */
  metadataXmpFileStampsList?: InputMaybe<ArchiveNestedMetadataXmpFileStampsListCreateInput>;
  /** Actions for the "Archive.metadataXmpHistoryList" relation: "MetadataXmpHistory.archive"'s inverse relation */
  metadataXmpHistoryList?: InputMaybe<ArchiveNestedMetadataXmpHistoryListCreateInput>;
  /** The archive name */
  name?: InputMaybe<ArchiveKeyType>;
  /** Actions for the "Archive.photoMetadatas" relation: "PhotoMetadata.archive"'s inverse relation */
  photoMetadatas?: InputMaybe<ArchiveNestedPhotoMetadatasCreateInput>;
  /** Actions for the "Archive.photos" relation: "Photo.archive"'s inverse relation */
  photos?: InputMaybe<ArchiveNestedPhotosCreateInput>;
  /** The archive type */
  type?: InputMaybe<ArchiveType>;
};

export enum ArchiveKeyType {
  CAM_LIVRAISON = 'CAM_LIVRAISON',
  CAM_SUJETS = 'CAM_SUJETS',
  CBG_SUJETS = 'CBG_SUJETS',
  CLE_SUJETS = 'CLE_SUJETS',
  CTM_ARCHIVES = 'CTM_ARCHIVES',
  CTM_SUJETS = 'CTM_SUJETS',
  FAP_BEAUTE_ARCHIVES = 'FAP_BEAUTE_ARCHIVES',
  FAP_CUISINE_ARCHIVES = 'FAP_CUISINE_ARCHIVES',
  FAP_DECO_ARCHIVES = 'FAP_DECO_ARCHIVES',
  FATV_SUJETS = 'FATV_SUJETS',
  GAL_PEOPLE = 'GAL_PEOPLE',
  GALA_LIVRAISON = 'GALA_LIVRAISON',
  GEO_ARCHIVES = 'GEO_ARCHIVES',
  HBZ_ARCHIVE = 'HBZ_ARCHIVE',
  HBZ_LIVRAISON = 'HBZ_LIVRAISON',
  HBZ_SUJETS = 'HBZ_SUJETS',
  MAD_SUJETS = 'MAD_SUJETS',
  NEON_SUJETS = 'NEON_SUJETS',
  NGE_ARCHIVE = 'NGE_ARCHIVE',
  NGE_SUJETS = 'NGE_SUJETS',
  NR_PERSONNALITES_ARCHIVES = 'NR_PERSONNALITES_ARCHIVES',
  PECO_LIVRAISON = 'PECO_LIVRAISON',
  PECO_SUJETS = 'PECO_SUJETS',
  PECO_WEB = 'PECO_WEB',
  PFEM_LIVRAISON = 'PFEM_LIVRAISON',
  PFEM_SUJETS = 'PFEM_SUJETS',
  PGAL_SUJETS = 'PGAL_SUJETS',
  PGEO_SUJETS = 'PGEO_SUJETS',
  PTVS_ARCHIVES_TV = 'PTVS_ARCHIVES_TV',
  PTVS_LIVRAISON = 'PTVS_LIVRAISON',
  PTVS_SUJETS = 'PTVS_SUJETS',
  TEST_UNIT = 'TEST_UNIT',
  TRV_CUI = 'TRV_CUI',
  TRV_PHOTOS = 'TRV_PHOTOS',
  TRV_WEB_GRAPHIX = 'TRV_WEB_GRAPHIX',
  TVS_SUJETS = 'TVS_SUJETS',
  VOI_ARCHIVES = 'VOI_ARCHIVES',
  VOI_BASE_PEOPLE = 'VOI_BASE_PEOPLE',
  VOI_BEAUTE = 'VOI_BEAUTE',
  VOI_MODE = 'VOI_MODE',
  VOI_SCOOP = 'VOI_SCOOP',
  VOICI_LIVRAISON = 'VOICI_LIVRAISON',
  VOICI_SUJETS = 'VOICI_SUJETS'
}

export type ArchiveNestedArchiveAgenciesCreateInput = {
  /** Connect existing "ArchiveAgency" nodes to the new "Archive" node, through the "Archive.archiveAgencies" relation. */
  connect?: InputMaybe<Array<ArchiveAgencyWhereUniqueInput>>;
  /** Create new "ArchiveAgency" nodes and connect them to the new "Archive" node, through the "Archive.archiveAgencies" relation. */
  create?: InputMaybe<Array<ArchiveAgencyWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedArchiveAgenciesUpdateInput = {
  /** Connect existing "ArchiveAgency" nodes to the existing "Archive" node, through the "Archive.archiveAgencies" relation. */
  connect?: InputMaybe<Array<ArchiveAgencyWhereUniqueInput>>;
  /** Create new "ArchiveAgency" nodes and connect them to the existing "Archive" node, through the "Archive.archiveAgencies" relation. */
  create?: InputMaybe<Array<ArchiveAgencyWithForcedArchiveCreateInput>>;
  /** Delete existing "ArchiveAgency" nodes from the "Archive.archiveAgencies" relation. */
  delete?: InputMaybe<Array<ArchiveAgencyWithForcedArchiveWhereUniqueInput>>;
  /** Update existing "ArchiveAgency" nodes and connect them to the existing "Archive" node, through the "Archive.archiveAgencies" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateArchiveAgenciesUpdateInput>>;
  /** Upsert existing "ArchiveAgency" nodes and connect them to the existing "Archive" node, through the "Archive.archiveAgencies" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertArchiveAgenciesUpdateInput>>;
};

export type ArchiveNestedCategoriesCreateInput = {
  /** Connect existing "Category" nodes to the new "Archive" node, through the "Archive.categories" relation. */
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Create new "Category" nodes and connect them to the new "Archive" node, through the "Archive.categories" relation. */
  create?: InputMaybe<Array<CategoryWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedCategoriesUpdateInput = {
  /** Connect existing "Category" nodes to the existing "Archive" node, through the "Archive.categories" relation. */
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Create new "Category" nodes and connect them to the existing "Archive" node, through the "Archive.categories" relation. */
  create?: InputMaybe<Array<CategoryWithForcedArchiveCreateInput>>;
  /** Delete existing "Category" nodes from the "Archive.categories" relation. */
  delete?: InputMaybe<Array<CategoryWithForcedArchiveWhereUniqueInput>>;
  /** Update existing "Category" nodes and connect them to the existing "Archive" node, through the "Archive.categories" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateCategoriesUpdateInput>>;
  /** Upsert existing "Category" nodes and connect them to the existing "Archive" node, through the "Archive.categories" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertCategoriesUpdateInput>>;
};

export type ArchiveNestedDeliverySubjectsCreateInput = {
  /** Connect existing "DeliverySubject" nodes to the new "Archive" node, through the "Archive.deliverySubjects" relation. */
  connect?: InputMaybe<Array<DeliverySubjectWhereUniqueInput>>;
  /** Create new "DeliverySubject" nodes and connect them to the new "Archive" node, through the "Archive.deliverySubjects" relation. */
  create?: InputMaybe<Array<DeliverySubjectWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedDeliverySubjectsUpdateInput = {
  /** Connect existing "DeliverySubject" nodes to the existing "Archive" node, through the "Archive.deliverySubjects" relation. */
  connect?: InputMaybe<Array<DeliverySubjectWhereUniqueInput>>;
  /** Create new "DeliverySubject" nodes and connect them to the existing "Archive" node, through the "Archive.deliverySubjects" relation. */
  create?: InputMaybe<Array<DeliverySubjectWithForcedArchiveCreateInput>>;
  /** Delete existing "DeliverySubject" nodes from the "Archive.deliverySubjects" relation. */
  delete?: InputMaybe<Array<DeliverySubjectWithForcedArchiveWhereUniqueInput>>;
  /** Update existing "DeliverySubject" nodes and connect them to the existing "Archive" node, through the "Archive.deliverySubjects" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateDeliverySubjectsUpdateInput>>;
  /** Upsert existing "DeliverySubject" nodes and connect them to the existing "Archive" node, through the "Archive.deliverySubjects" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertDeliverySubjectsUpdateInput>>;
};

export type ArchiveNestedGcImportSettingCreateInput = {
  /** Connect an existing "GcImportSetting" node to the new "Archive" node, through the "Archive.gcImportSetting" relation. */
  connect?: InputMaybe<GcImportSettingWhereUniqueInput>;
  /** Create a new "GcImportSetting" node and connect it to the new "Archive" node, through the "Archive.gcImportSetting" relation. */
  create?: InputMaybe<GcImportSettingWithForcedArchiveCreateInput>;
};

export type ArchiveNestedGcImportSettingUpdateInput = {
  /** Connect an existing "GcImportSetting" node to the existing "Archive" node, through the "Archive.gcImportSetting" relation. */
  connect?: InputMaybe<GcImportSettingWhereUniqueInput>;
  /** Create a new "GcImportSetting" node and connect it to the existing "Archive" node, through the "Archive.gcImportSetting" relation. */
  create?: InputMaybe<GcImportSettingWithForcedArchiveCreateInput>;
  /** Delete an existing "GcImportSetting" node from the "Archive.gcImportSetting" relation. */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update an existing "GcImportSetting" node and connect it to the existing "Archive" node, through the "Archive.gcImportSetting" relation. */
  update?: InputMaybe<ArchiveNestedUpdateGcImportSettingUpdateInput>;
  /** Upsert an existing "GcImportSetting" node and connect it to the existing "Archive" node, through the "Archive.gcImportSetting" relation. */
  upsert?: InputMaybe<ArchiveNestedUpsertGcImportSettingUpdateInput>;
};

export type ArchiveNestedImportQueuesCreateInput = {
  /** Connect existing "ImportQueue" nodes to the new "Archive" node, through the "Archive.importQueues" relation. */
  connect?: InputMaybe<Array<ImportQueueWhereUniqueInput>>;
  /** Create new "ImportQueue" nodes and connect them to the new "Archive" node, through the "Archive.importQueues" relation. */
  create?: InputMaybe<Array<ImportQueueWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedImportQueuesUpdateInput = {
  /** Connect existing "ImportQueue" nodes to the existing "Archive" node, through the "Archive.importQueues" relation. */
  connect?: InputMaybe<Array<ImportQueueWhereUniqueInput>>;
  /** Create new "ImportQueue" nodes and connect them to the existing "Archive" node, through the "Archive.importQueues" relation. */
  create?: InputMaybe<Array<ImportQueueWithForcedArchiveCreateInput>>;
  /** Delete existing "ImportQueue" nodes from the "Archive.importQueues" relation. */
  delete?: InputMaybe<Array<ImportQueueWhereUniqueInput>>;
  /** Disconnect existing "ImportQueue" nodes of the "Archive.importQueues" relation. */
  disconnect?: InputMaybe<Array<ImportQueueWhereUniqueInput>>;
  /** Update existing "ImportQueue" nodes and connect them to the existing "Archive" node, through the "Archive.importQueues" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateImportQueuesUpdateInput>>;
  /** Upsert existing "ImportQueue" nodes and connect them to the existing "Archive" node, through the "Archive.importQueues" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertImportQueuesUpdateInput>>;
};

export type ArchiveNestedMetadataAccessRightsListCreateInput = {
  /** Connect existing "MetadataAccessRights" nodes to the new "Archive" node, through the "Archive.metadataAccessRightsList" relation. */
  connect?: InputMaybe<Array<MetadataAccessRightsWhereUniqueInput>>;
  /** Create new "MetadataAccessRights" nodes and connect them to the new "Archive" node, through the "Archive.metadataAccessRightsList" relation. */
  create?: InputMaybe<Array<MetadataAccessRightsWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataAccessRightsListUpdateInput = {
  /** Connect existing "MetadataAccessRights" nodes to the existing "Archive" node, through the "Archive.metadataAccessRightsList" relation. */
  connect?: InputMaybe<Array<MetadataAccessRightsWhereUniqueInput>>;
  /** Create new "MetadataAccessRights" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAccessRightsList" relation. */
  create?: InputMaybe<Array<MetadataAccessRightsWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataAccessRights" nodes from the "Archive.metadataAccessRightsList" relation. */
  delete?: InputMaybe<Array<MetadataAccessRightsWhereUniqueInput>>;
  /** Update existing "MetadataAccessRights" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAccessRightsList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataAccessRightsListUpdateInput>>;
  /** Upsert existing "MetadataAccessRights" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAccessRightsList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataAccessRightsListUpdateInput>>;
};

export type ArchiveNestedMetadataAddlModelInfoListCreateInput = {
  /** Connect existing "MetadataAddlModelInfo" nodes to the new "Archive" node, through the "Archive.metadataAddlModelInfoList" relation. */
  connect?: InputMaybe<Array<MetadataAddlModelInfoWhereUniqueInput>>;
  /** Create new "MetadataAddlModelInfo" nodes and connect them to the new "Archive" node, through the "Archive.metadataAddlModelInfoList" relation. */
  create?: InputMaybe<Array<MetadataAddlModelInfoWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataAddlModelInfoListUpdateInput = {
  /** Connect existing "MetadataAddlModelInfo" nodes to the existing "Archive" node, through the "Archive.metadataAddlModelInfoList" relation. */
  connect?: InputMaybe<Array<MetadataAddlModelInfoWhereUniqueInput>>;
  /** Create new "MetadataAddlModelInfo" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAddlModelInfoList" relation. */
  create?: InputMaybe<Array<MetadataAddlModelInfoWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataAddlModelInfo" nodes from the "Archive.metadataAddlModelInfoList" relation. */
  delete?: InputMaybe<Array<MetadataAddlModelInfoWhereUniqueInput>>;
  /** Update existing "MetadataAddlModelInfo" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAddlModelInfoList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataAddlModelInfoListUpdateInput>>;
  /** Upsert existing "MetadataAddlModelInfo" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAddlModelInfoList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataAddlModelInfoListUpdateInput>>;
};

export type ArchiveNestedMetadataArchiveChildListCreateInput = {
  /** Connect existing "MetadataArchiveChild" nodes to the new "Archive" node, through the "Archive.metadataArchiveChildList" relation. */
  connect?: InputMaybe<Array<MetadataArchiveChildWhereUniqueInput>>;
  /** Create new "MetadataArchiveChild" nodes and connect them to the new "Archive" node, through the "Archive.metadataArchiveChildList" relation. */
  create?: InputMaybe<Array<MetadataArchiveChildWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataArchiveChildListUpdateInput = {
  /** Connect existing "MetadataArchiveChild" nodes to the existing "Archive" node, through the "Archive.metadataArchiveChildList" relation. */
  connect?: InputMaybe<Array<MetadataArchiveChildWhereUniqueInput>>;
  /** Create new "MetadataArchiveChild" nodes and connect them to the existing "Archive" node, through the "Archive.metadataArchiveChildList" relation. */
  create?: InputMaybe<Array<MetadataArchiveChildWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataArchiveChild" nodes from the "Archive.metadataArchiveChildList" relation. */
  delete?: InputMaybe<Array<MetadataArchiveChildWhereUniqueInput>>;
  /** Update existing "MetadataArchiveChild" nodes and connect them to the existing "Archive" node, through the "Archive.metadataArchiveChildList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataArchiveChildListUpdateInput>>;
  /** Upsert existing "MetadataArchiveChild" nodes and connect them to the existing "Archive" node, through the "Archive.metadataArchiveChildList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataArchiveChildListUpdateInput>>;
};

export type ArchiveNestedMetadataArchiveParentListCreateInput = {
  /** Connect existing "MetadataArchiveParent" nodes to the new "Archive" node, through the "Archive.metadataArchiveParentList" relation. */
  connect?: InputMaybe<Array<MetadataArchiveParentWhereUniqueInput>>;
  /** Create new "MetadataArchiveParent" nodes and connect them to the new "Archive" node, through the "Archive.metadataArchiveParentList" relation. */
  create?: InputMaybe<Array<MetadataArchiveParentWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataArchiveParentListUpdateInput = {
  /** Connect existing "MetadataArchiveParent" nodes to the existing "Archive" node, through the "Archive.metadataArchiveParentList" relation. */
  connect?: InputMaybe<Array<MetadataArchiveParentWhereUniqueInput>>;
  /** Create new "MetadataArchiveParent" nodes and connect them to the existing "Archive" node, through the "Archive.metadataArchiveParentList" relation. */
  create?: InputMaybe<Array<MetadataArchiveParentWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataArchiveParent" nodes from the "Archive.metadataArchiveParentList" relation. */
  delete?: InputMaybe<Array<MetadataArchiveParentWhereUniqueInput>>;
  /** Update existing "MetadataArchiveParent" nodes and connect them to the existing "Archive" node, through the "Archive.metadataArchiveParentList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataArchiveParentListUpdateInput>>;
  /** Upsert existing "MetadataArchiveParent" nodes and connect them to the existing "Archive" node, through the "Archive.metadataArchiveParentList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataArchiveParentListUpdateInput>>;
};

export type ArchiveNestedMetadataAssetStateListCreateInput = {
  /** Connect existing "MetadataAssetState" nodes to the new "Archive" node, through the "Archive.metadataAssetStateList" relation. */
  connect?: InputMaybe<Array<MetadataAssetStateWhereUniqueInput>>;
  /** Create new "MetadataAssetState" nodes and connect them to the new "Archive" node, through the "Archive.metadataAssetStateList" relation. */
  create?: InputMaybe<Array<MetadataAssetStateWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataAssetStateListUpdateInput = {
  /** Connect existing "MetadataAssetState" nodes to the existing "Archive" node, through the "Archive.metadataAssetStateList" relation. */
  connect?: InputMaybe<Array<MetadataAssetStateWhereUniqueInput>>;
  /** Create new "MetadataAssetState" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAssetStateList" relation. */
  create?: InputMaybe<Array<MetadataAssetStateWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataAssetState" nodes from the "Archive.metadataAssetStateList" relation. */
  delete?: InputMaybe<Array<MetadataAssetStateWhereUniqueInput>>;
  /** Update existing "MetadataAssetState" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAssetStateList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataAssetStateListUpdateInput>>;
  /** Upsert existing "MetadataAssetState" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAssetStateList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataAssetStateListUpdateInput>>;
};

export type ArchiveNestedMetadataAttributionNameListCreateInput = {
  /** Connect existing "MetadataAttributionName" nodes to the new "Archive" node, through the "Archive.metadataAttributionNameList" relation. */
  connect?: InputMaybe<Array<MetadataAttributionNameWhereUniqueInput>>;
  /** Create new "MetadataAttributionName" nodes and connect them to the new "Archive" node, through the "Archive.metadataAttributionNameList" relation. */
  create?: InputMaybe<Array<MetadataAttributionNameWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataAttributionNameListUpdateInput = {
  /** Connect existing "MetadataAttributionName" nodes to the existing "Archive" node, through the "Archive.metadataAttributionNameList" relation. */
  connect?: InputMaybe<Array<MetadataAttributionNameWhereUniqueInput>>;
  /** Create new "MetadataAttributionName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAttributionNameList" relation. */
  create?: InputMaybe<Array<MetadataAttributionNameWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataAttributionName" nodes from the "Archive.metadataAttributionNameList" relation. */
  delete?: InputMaybe<Array<MetadataAttributionNameWhereUniqueInput>>;
  /** Update existing "MetadataAttributionName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAttributionNameList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataAttributionNameListUpdateInput>>;
  /** Upsert existing "MetadataAttributionName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAttributionNameList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataAttributionNameListUpdateInput>>;
};

export type ArchiveNestedMetadataAttributionUrlListCreateInput = {
  /** Connect existing "MetadataAttributionUrl" nodes to the new "Archive" node, through the "Archive.metadataAttributionUrlList" relation. */
  connect?: InputMaybe<Array<MetadataAttributionUrlWhereUniqueInput>>;
  /** Create new "MetadataAttributionUrl" nodes and connect them to the new "Archive" node, through the "Archive.metadataAttributionUrlList" relation. */
  create?: InputMaybe<Array<MetadataAttributionUrlWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataAttributionUrlListUpdateInput = {
  /** Connect existing "MetadataAttributionUrl" nodes to the existing "Archive" node, through the "Archive.metadataAttributionUrlList" relation. */
  connect?: InputMaybe<Array<MetadataAttributionUrlWhereUniqueInput>>;
  /** Create new "MetadataAttributionUrl" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAttributionUrlList" relation. */
  create?: InputMaybe<Array<MetadataAttributionUrlWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataAttributionUrl" nodes from the "Archive.metadataAttributionUrlList" relation. */
  delete?: InputMaybe<Array<MetadataAttributionUrlWhereUniqueInput>>;
  /** Update existing "MetadataAttributionUrl" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAttributionUrlList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataAttributionUrlListUpdateInput>>;
  /** Upsert existing "MetadataAttributionUrl" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAttributionUrlList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataAttributionUrlListUpdateInput>>;
};

export type ArchiveNestedMetadataAudienceListCreateInput = {
  /** Connect existing "MetadataAudience" nodes to the new "Archive" node, through the "Archive.metadataAudienceList" relation. */
  connect?: InputMaybe<Array<MetadataAudienceWhereUniqueInput>>;
  /** Create new "MetadataAudience" nodes and connect them to the new "Archive" node, through the "Archive.metadataAudienceList" relation. */
  create?: InputMaybe<Array<MetadataAudienceWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataAudienceListUpdateInput = {
  /** Connect existing "MetadataAudience" nodes to the existing "Archive" node, through the "Archive.metadataAudienceList" relation. */
  connect?: InputMaybe<Array<MetadataAudienceWhereUniqueInput>>;
  /** Create new "MetadataAudience" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAudienceList" relation. */
  create?: InputMaybe<Array<MetadataAudienceWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataAudience" nodes from the "Archive.metadataAudienceList" relation. */
  delete?: InputMaybe<Array<MetadataAudienceWhereUniqueInput>>;
  /** Update existing "MetadataAudience" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAudienceList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataAudienceListUpdateInput>>;
  /** Upsert existing "MetadataAudience" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAudienceList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataAudienceListUpdateInput>>;
};

export type ArchiveNestedMetadataAuthorsPositionListCreateInput = {
  /** Connect existing "MetadataAuthorsPosition" nodes to the new "Archive" node, through the "Archive.metadataAuthorsPositionList" relation. */
  connect?: InputMaybe<Array<MetadataAuthorsPositionWhereUniqueInput>>;
  /** Create new "MetadataAuthorsPosition" nodes and connect them to the new "Archive" node, through the "Archive.metadataAuthorsPositionList" relation. */
  create?: InputMaybe<Array<MetadataAuthorsPositionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataAuthorsPositionListUpdateInput = {
  /** Connect existing "MetadataAuthorsPosition" nodes to the existing "Archive" node, through the "Archive.metadataAuthorsPositionList" relation. */
  connect?: InputMaybe<Array<MetadataAuthorsPositionWhereUniqueInput>>;
  /** Create new "MetadataAuthorsPosition" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAuthorsPositionList" relation. */
  create?: InputMaybe<Array<MetadataAuthorsPositionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataAuthorsPosition" nodes from the "Archive.metadataAuthorsPositionList" relation. */
  delete?: InputMaybe<Array<MetadataAuthorsPositionWhereUniqueInput>>;
  /** Update existing "MetadataAuthorsPosition" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAuthorsPositionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataAuthorsPositionListUpdateInput>>;
  /** Upsert existing "MetadataAuthorsPosition" nodes and connect them to the existing "Archive" node, through the "Archive.metadataAuthorsPositionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataAuthorsPositionListUpdateInput>>;
};

export type ArchiveNestedMetadataBackupNameListCreateInput = {
  /** Connect existing "MetadataBackupName" nodes to the new "Archive" node, through the "Archive.metadataBackupNameList" relation. */
  connect?: InputMaybe<Array<MetadataBackupNameWhereUniqueInput>>;
  /** Create new "MetadataBackupName" nodes and connect them to the new "Archive" node, through the "Archive.metadataBackupNameList" relation. */
  create?: InputMaybe<Array<MetadataBackupNameWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataBackupNameListUpdateInput = {
  /** Connect existing "MetadataBackupName" nodes to the existing "Archive" node, through the "Archive.metadataBackupNameList" relation. */
  connect?: InputMaybe<Array<MetadataBackupNameWhereUniqueInput>>;
  /** Create new "MetadataBackupName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBackupNameList" relation. */
  create?: InputMaybe<Array<MetadataBackupNameWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataBackupName" nodes from the "Archive.metadataBackupNameList" relation. */
  delete?: InputMaybe<Array<MetadataBackupNameWhereUniqueInput>>;
  /** Update existing "MetadataBackupName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBackupNameList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataBackupNameListUpdateInput>>;
  /** Upsert existing "MetadataBackupName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBackupNameList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataBackupNameListUpdateInput>>;
};

export type ArchiveNestedMetadataBitmapGrayscalePictureListCreateInput = {
  /** Connect existing "MetadataBitmapGrayscalePicture" nodes to the new "Archive" node, through the "Archive.metadataBitmapGrayscalePictureList" relation. */
  connect?: InputMaybe<Array<MetadataBitmapGrayscalePictureWhereUniqueInput>>;
  /** Create new "MetadataBitmapGrayscalePicture" nodes and connect them to the new "Archive" node, through the "Archive.metadataBitmapGrayscalePictureList" relation. */
  create?: InputMaybe<Array<MetadataBitmapGrayscalePictureWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataBitmapGrayscalePictureListUpdateInput = {
  /** Connect existing "MetadataBitmapGrayscalePicture" nodes to the existing "Archive" node, through the "Archive.metadataBitmapGrayscalePictureList" relation. */
  connect?: InputMaybe<Array<MetadataBitmapGrayscalePictureWhereUniqueInput>>;
  /** Create new "MetadataBitmapGrayscalePicture" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBitmapGrayscalePictureList" relation. */
  create?: InputMaybe<Array<MetadataBitmapGrayscalePictureWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataBitmapGrayscalePicture" nodes from the "Archive.metadataBitmapGrayscalePictureList" relation. */
  delete?: InputMaybe<Array<MetadataBitmapGrayscalePictureWhereUniqueInput>>;
  /** Update existing "MetadataBitmapGrayscalePicture" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBitmapGrayscalePictureList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataBitmapGrayscalePictureListUpdateInput>>;
  /** Upsert existing "MetadataBitmapGrayscalePicture" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBitmapGrayscalePictureList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataBitmapGrayscalePictureListUpdateInput>>;
};

export type ArchiveNestedMetadataBrandListCreateInput = {
  /** Connect existing "MetadataBrand" nodes to the new "Archive" node, through the "Archive.metadataBrandList" relation. */
  connect?: InputMaybe<Array<MetadataBrandWhereUniqueInput>>;
  /** Create new "MetadataBrand" nodes and connect them to the new "Archive" node, through the "Archive.metadataBrandList" relation. */
  create?: InputMaybe<Array<MetadataBrandWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataBrandListUpdateInput = {
  /** Connect existing "MetadataBrand" nodes to the existing "Archive" node, through the "Archive.metadataBrandList" relation. */
  connect?: InputMaybe<Array<MetadataBrandWhereUniqueInput>>;
  /** Create new "MetadataBrand" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBrandList" relation. */
  create?: InputMaybe<Array<MetadataBrandWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataBrand" nodes from the "Archive.metadataBrandList" relation. */
  delete?: InputMaybe<Array<MetadataBrandWhereUniqueInput>>;
  /** Update existing "MetadataBrand" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBrandList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataBrandListUpdateInput>>;
  /** Upsert existing "MetadataBrand" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBrandList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataBrandListUpdateInput>>;
};

export type ArchiveNestedMetadataBrandPrismaListCreateInput = {
  /** Connect existing "MetadataBrandPrisma" nodes to the new "Archive" node, through the "Archive.metadataBrandPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataBrandPrismaWhereUniqueInput>>;
  /** Create new "MetadataBrandPrisma" nodes and connect them to the new "Archive" node, through the "Archive.metadataBrandPrismaList" relation. */
  create?: InputMaybe<Array<MetadataBrandPrismaWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataBrandPrismaListUpdateInput = {
  /** Connect existing "MetadataBrandPrisma" nodes to the existing "Archive" node, through the "Archive.metadataBrandPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataBrandPrismaWhereUniqueInput>>;
  /** Create new "MetadataBrandPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBrandPrismaList" relation. */
  create?: InputMaybe<Array<MetadataBrandPrismaWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataBrandPrisma" nodes from the "Archive.metadataBrandPrismaList" relation. */
  delete?: InputMaybe<Array<MetadataBrandPrismaWhereUniqueInput>>;
  /** Update existing "MetadataBrandPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBrandPrismaList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataBrandPrismaListUpdateInput>>;
  /** Upsert existing "MetadataBrandPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBrandPrismaList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataBrandPrismaListUpdateInput>>;
};

export type ArchiveNestedMetadataBrandShortNameListCreateInput = {
  /** Connect existing "MetadataBrandShortName" nodes to the new "Archive" node, through the "Archive.metadataBrandShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataBrandShortNameWhereUniqueInput>>;
  /** Create new "MetadataBrandShortName" nodes and connect them to the new "Archive" node, through the "Archive.metadataBrandShortNameList" relation. */
  create?: InputMaybe<Array<MetadataBrandShortNameWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataBrandShortNameListUpdateInput = {
  /** Connect existing "MetadataBrandShortName" nodes to the existing "Archive" node, through the "Archive.metadataBrandShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataBrandShortNameWhereUniqueInput>>;
  /** Create new "MetadataBrandShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBrandShortNameList" relation. */
  create?: InputMaybe<Array<MetadataBrandShortNameWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataBrandShortName" nodes from the "Archive.metadataBrandShortNameList" relation. */
  delete?: InputMaybe<Array<MetadataBrandShortNameWhereUniqueInput>>;
  /** Update existing "MetadataBrandShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBrandShortNameList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataBrandShortNameListUpdateInput>>;
  /** Upsert existing "MetadataBrandShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataBrandShortNameList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataBrandShortNameListUpdateInput>>;
};

export type ArchiveNestedMetadataCaptionWriterListCreateInput = {
  /** Connect existing "MetadataCaptionWriter" nodes to the new "Archive" node, through the "Archive.metadataCaptionWriterList" relation. */
  connect?: InputMaybe<Array<MetadataCaptionWriterWhereUniqueInput>>;
  /** Create new "MetadataCaptionWriter" nodes and connect them to the new "Archive" node, through the "Archive.metadataCaptionWriterList" relation. */
  create?: InputMaybe<Array<MetadataCaptionWriterWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCaptionWriterListUpdateInput = {
  /** Connect existing "MetadataCaptionWriter" nodes to the existing "Archive" node, through the "Archive.metadataCaptionWriterList" relation. */
  connect?: InputMaybe<Array<MetadataCaptionWriterWhereUniqueInput>>;
  /** Create new "MetadataCaptionWriter" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCaptionWriterList" relation. */
  create?: InputMaybe<Array<MetadataCaptionWriterWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCaptionWriter" nodes from the "Archive.metadataCaptionWriterList" relation. */
  delete?: InputMaybe<Array<MetadataCaptionWriterWhereUniqueInput>>;
  /** Update existing "MetadataCaptionWriter" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCaptionWriterList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCaptionWriterListUpdateInput>>;
  /** Upsert existing "MetadataCaptionWriter" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCaptionWriterList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCaptionWriterListUpdateInput>>;
};

export type ArchiveNestedMetadataCategoryListCreateInput = {
  /** Connect existing "MetadataCategory" nodes to the new "Archive" node, through the "Archive.metadataCategoryList" relation. */
  connect?: InputMaybe<Array<MetadataCategoryWhereUniqueInput>>;
  /** Create new "MetadataCategory" nodes and connect them to the new "Archive" node, through the "Archive.metadataCategoryList" relation. */
  create?: InputMaybe<Array<MetadataCategoryWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCategoryListUpdateInput = {
  /** Connect existing "MetadataCategory" nodes to the existing "Archive" node, through the "Archive.metadataCategoryList" relation. */
  connect?: InputMaybe<Array<MetadataCategoryWhereUniqueInput>>;
  /** Create new "MetadataCategory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCategoryList" relation. */
  create?: InputMaybe<Array<MetadataCategoryWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCategory" nodes from the "Archive.metadataCategoryList" relation. */
  delete?: InputMaybe<Array<MetadataCategoryWhereUniqueInput>>;
  /** Update existing "MetadataCategory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCategoryList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCategoryListUpdateInput>>;
  /** Upsert existing "MetadataCategory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCategoryList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCategoryListUpdateInput>>;
};

export type ArchiveNestedMetadataCategoryPrismaListCreateInput = {
  /** Connect existing "MetadataCategoryPrisma" nodes to the new "Archive" node, through the "Archive.metadataCategoryPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataCategoryPrismaWhereUniqueInput>>;
  /** Create new "MetadataCategoryPrisma" nodes and connect them to the new "Archive" node, through the "Archive.metadataCategoryPrismaList" relation. */
  create?: InputMaybe<Array<MetadataCategoryPrismaWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCategoryPrismaListUpdateInput = {
  /** Connect existing "MetadataCategoryPrisma" nodes to the existing "Archive" node, through the "Archive.metadataCategoryPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataCategoryPrismaWhereUniqueInput>>;
  /** Create new "MetadataCategoryPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCategoryPrismaList" relation. */
  create?: InputMaybe<Array<MetadataCategoryPrismaWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCategoryPrisma" nodes from the "Archive.metadataCategoryPrismaList" relation. */
  delete?: InputMaybe<Array<MetadataCategoryPrismaWhereUniqueInput>>;
  /** Update existing "MetadataCategoryPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCategoryPrismaList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCategoryPrismaListUpdateInput>>;
  /** Upsert existing "MetadataCategoryPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCategoryPrismaList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCategoryPrismaListUpdateInput>>;
};

export type ArchiveNestedMetadataCertificateListCreateInput = {
  /** Connect existing "MetadataCertificate" nodes to the new "Archive" node, through the "Archive.metadataCertificateList" relation. */
  connect?: InputMaybe<Array<MetadataCertificateWhereUniqueInput>>;
  /** Create new "MetadataCertificate" nodes and connect them to the new "Archive" node, through the "Archive.metadataCertificateList" relation. */
  create?: InputMaybe<Array<MetadataCertificateWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCertificateListUpdateInput = {
  /** Connect existing "MetadataCertificate" nodes to the existing "Archive" node, through the "Archive.metadataCertificateList" relation. */
  connect?: InputMaybe<Array<MetadataCertificateWhereUniqueInput>>;
  /** Create new "MetadataCertificate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCertificateList" relation. */
  create?: InputMaybe<Array<MetadataCertificateWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCertificate" nodes from the "Archive.metadataCertificateList" relation. */
  delete?: InputMaybe<Array<MetadataCertificateWhereUniqueInput>>;
  /** Update existing "MetadataCertificate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCertificateList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCertificateListUpdateInput>>;
  /** Upsert existing "MetadataCertificate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCertificateList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCertificateListUpdateInput>>;
};

export type ArchiveNestedMetadataCiAdrCityListCreateInput = {
  /** Connect existing "MetadataCiAdrCity" nodes to the new "Archive" node, through the "Archive.metadataCiAdrCityList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCityWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCity" nodes and connect them to the new "Archive" node, through the "Archive.metadataCiAdrCityList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCityWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCiAdrCityListUpdateInput = {
  /** Connect existing "MetadataCiAdrCity" nodes to the existing "Archive" node, through the "Archive.metadataCiAdrCityList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCityWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCity" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrCityList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCityWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCiAdrCity" nodes from the "Archive.metadataCiAdrCityList" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrCityWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrCity" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrCityList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCiAdrCityListUpdateInput>>;
  /** Upsert existing "MetadataCiAdrCity" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrCityList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCiAdrCityListUpdateInput>>;
};

export type ArchiveNestedMetadataCiAdrCtryListCreateInput = {
  /** Connect existing "MetadataCiAdrCtry" nodes to the new "Archive" node, through the "Archive.metadataCiAdrCtryList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCtryWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCtry" nodes and connect them to the new "Archive" node, through the "Archive.metadataCiAdrCtryList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCtryWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCiAdrCtryListUpdateInput = {
  /** Connect existing "MetadataCiAdrCtry" nodes to the existing "Archive" node, through the "Archive.metadataCiAdrCtryList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCtryWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCtry" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrCtryList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCtryWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCiAdrCtry" nodes from the "Archive.metadataCiAdrCtryList" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrCtryWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrCtry" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrCtryList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCiAdrCtryListUpdateInput>>;
  /** Upsert existing "MetadataCiAdrCtry" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrCtryList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCiAdrCtryListUpdateInput>>;
};

export type ArchiveNestedMetadataCiAdrExtadrListCreateInput = {
  /** Connect existing "MetadataCiAdrExtadr" nodes to the new "Archive" node, through the "Archive.metadataCiAdrExtadrList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrExtadrWhereUniqueInput>>;
  /** Create new "MetadataCiAdrExtadr" nodes and connect them to the new "Archive" node, through the "Archive.metadataCiAdrExtadrList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrExtadrWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCiAdrExtadrListUpdateInput = {
  /** Connect existing "MetadataCiAdrExtadr" nodes to the existing "Archive" node, through the "Archive.metadataCiAdrExtadrList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrExtadrWhereUniqueInput>>;
  /** Create new "MetadataCiAdrExtadr" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrExtadrList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrExtadrWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCiAdrExtadr" nodes from the "Archive.metadataCiAdrExtadrList" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrExtadrWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrExtadr" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrExtadrList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCiAdrExtadrListUpdateInput>>;
  /** Upsert existing "MetadataCiAdrExtadr" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrExtadrList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCiAdrExtadrListUpdateInput>>;
};

export type ArchiveNestedMetadataCiAdrPcodeListCreateInput = {
  /** Connect existing "MetadataCiAdrPcode" nodes to the new "Archive" node, through the "Archive.metadataCiAdrPcodeList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrPcodeWhereUniqueInput>>;
  /** Create new "MetadataCiAdrPcode" nodes and connect them to the new "Archive" node, through the "Archive.metadataCiAdrPcodeList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrPcodeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCiAdrPcodeListUpdateInput = {
  /** Connect existing "MetadataCiAdrPcode" nodes to the existing "Archive" node, through the "Archive.metadataCiAdrPcodeList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrPcodeWhereUniqueInput>>;
  /** Create new "MetadataCiAdrPcode" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrPcodeList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrPcodeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCiAdrPcode" nodes from the "Archive.metadataCiAdrPcodeList" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrPcodeWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrPcode" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrPcodeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCiAdrPcodeListUpdateInput>>;
  /** Upsert existing "MetadataCiAdrPcode" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrPcodeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCiAdrPcodeListUpdateInput>>;
};

export type ArchiveNestedMetadataCiAdrRegionListCreateInput = {
  /** Connect existing "MetadataCiAdrRegion" nodes to the new "Archive" node, through the "Archive.metadataCiAdrRegionList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrRegionWhereUniqueInput>>;
  /** Create new "MetadataCiAdrRegion" nodes and connect them to the new "Archive" node, through the "Archive.metadataCiAdrRegionList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrRegionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCiAdrRegionListUpdateInput = {
  /** Connect existing "MetadataCiAdrRegion" nodes to the existing "Archive" node, through the "Archive.metadataCiAdrRegionList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrRegionWhereUniqueInput>>;
  /** Create new "MetadataCiAdrRegion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrRegionList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrRegionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCiAdrRegion" nodes from the "Archive.metadataCiAdrRegionList" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrRegionWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrRegion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrRegionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCiAdrRegionListUpdateInput>>;
  /** Upsert existing "MetadataCiAdrRegion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiAdrRegionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCiAdrRegionListUpdateInput>>;
};

export type ArchiveNestedMetadataCiEmailWorkListCreateInput = {
  /** Connect existing "MetadataCiEmailWork" nodes to the new "Archive" node, through the "Archive.metadataCiEmailWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiEmailWorkWhereUniqueInput>>;
  /** Create new "MetadataCiEmailWork" nodes and connect them to the new "Archive" node, through the "Archive.metadataCiEmailWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiEmailWorkWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCiEmailWorkListUpdateInput = {
  /** Connect existing "MetadataCiEmailWork" nodes to the existing "Archive" node, through the "Archive.metadataCiEmailWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiEmailWorkWhereUniqueInput>>;
  /** Create new "MetadataCiEmailWork" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiEmailWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiEmailWorkWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCiEmailWork" nodes from the "Archive.metadataCiEmailWorkList" relation. */
  delete?: InputMaybe<Array<MetadataCiEmailWorkWhereUniqueInput>>;
  /** Update existing "MetadataCiEmailWork" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiEmailWorkList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCiEmailWorkListUpdateInput>>;
  /** Upsert existing "MetadataCiEmailWork" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiEmailWorkList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCiEmailWorkListUpdateInput>>;
};

export type ArchiveNestedMetadataCiTelWorkListCreateInput = {
  /** Connect existing "MetadataCiTelWork" nodes to the new "Archive" node, through the "Archive.metadataCiTelWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiTelWorkWhereUniqueInput>>;
  /** Create new "MetadataCiTelWork" nodes and connect them to the new "Archive" node, through the "Archive.metadataCiTelWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiTelWorkWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCiTelWorkListUpdateInput = {
  /** Connect existing "MetadataCiTelWork" nodes to the existing "Archive" node, through the "Archive.metadataCiTelWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiTelWorkWhereUniqueInput>>;
  /** Create new "MetadataCiTelWork" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiTelWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiTelWorkWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCiTelWork" nodes from the "Archive.metadataCiTelWorkList" relation. */
  delete?: InputMaybe<Array<MetadataCiTelWorkWhereUniqueInput>>;
  /** Update existing "MetadataCiTelWork" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiTelWorkList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCiTelWorkListUpdateInput>>;
  /** Upsert existing "MetadataCiTelWork" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiTelWorkList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCiTelWorkListUpdateInput>>;
};

export type ArchiveNestedMetadataCityListCreateInput = {
  /** Connect existing "MetadataCity" nodes to the new "Archive" node, through the "Archive.metadataCityList" relation. */
  connect?: InputMaybe<Array<MetadataCityWhereUniqueInput>>;
  /** Create new "MetadataCity" nodes and connect them to the new "Archive" node, through the "Archive.metadataCityList" relation. */
  create?: InputMaybe<Array<MetadataCityWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCityListUpdateInput = {
  /** Connect existing "MetadataCity" nodes to the existing "Archive" node, through the "Archive.metadataCityList" relation. */
  connect?: InputMaybe<Array<MetadataCityWhereUniqueInput>>;
  /** Create new "MetadataCity" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCityList" relation. */
  create?: InputMaybe<Array<MetadataCityWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCity" nodes from the "Archive.metadataCityList" relation. */
  delete?: InputMaybe<Array<MetadataCityWhereUniqueInput>>;
  /** Update existing "MetadataCity" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCityList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCityListUpdateInput>>;
  /** Upsert existing "MetadataCity" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCityList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCityListUpdateInput>>;
};

export type ArchiveNestedMetadataCiUrlWorkListCreateInput = {
  /** Connect existing "MetadataCiUrlWork" nodes to the new "Archive" node, through the "Archive.metadataCiUrlWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiUrlWorkWhereUniqueInput>>;
  /** Create new "MetadataCiUrlWork" nodes and connect them to the new "Archive" node, through the "Archive.metadataCiUrlWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiUrlWorkWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCiUrlWorkListUpdateInput = {
  /** Connect existing "MetadataCiUrlWork" nodes to the existing "Archive" node, through the "Archive.metadataCiUrlWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiUrlWorkWhereUniqueInput>>;
  /** Create new "MetadataCiUrlWork" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiUrlWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiUrlWorkWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCiUrlWork" nodes from the "Archive.metadataCiUrlWorkList" relation. */
  delete?: InputMaybe<Array<MetadataCiUrlWorkWhereUniqueInput>>;
  /** Update existing "MetadataCiUrlWork" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiUrlWorkList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCiUrlWorkListUpdateInput>>;
  /** Upsert existing "MetadataCiUrlWork" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCiUrlWorkList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCiUrlWorkListUpdateInput>>;
};

export type ArchiveNestedMetadataClassifyListCreateInput = {
  /** Connect existing "MetadataClassify" nodes to the new "Archive" node, through the "Archive.metadataClassifyList" relation. */
  connect?: InputMaybe<Array<MetadataClassifyWhereUniqueInput>>;
  /** Create new "MetadataClassify" nodes and connect them to the new "Archive" node, through the "Archive.metadataClassifyList" relation. */
  create?: InputMaybe<Array<MetadataClassifyWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataClassifyListUpdateInput = {
  /** Connect existing "MetadataClassify" nodes to the existing "Archive" node, through the "Archive.metadataClassifyList" relation. */
  connect?: InputMaybe<Array<MetadataClassifyWhereUniqueInput>>;
  /** Create new "MetadataClassify" nodes and connect them to the existing "Archive" node, through the "Archive.metadataClassifyList" relation. */
  create?: InputMaybe<Array<MetadataClassifyWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataClassify" nodes from the "Archive.metadataClassifyList" relation. */
  delete?: InputMaybe<Array<MetadataClassifyWhereUniqueInput>>;
  /** Update existing "MetadataClassify" nodes and connect them to the existing "Archive" node, through the "Archive.metadataClassifyList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataClassifyListUpdateInput>>;
  /** Upsert existing "MetadataClassify" nodes and connect them to the existing "Archive" node, through the "Archive.metadataClassifyList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataClassifyListUpdateInput>>;
};

export type ArchiveNestedMetadataCompanyListCreateInput = {
  /** Connect existing "MetadataCompany" nodes to the new "Archive" node, through the "Archive.metadataCompanyList" relation. */
  connect?: InputMaybe<Array<MetadataCompanyWhereUniqueInput>>;
  /** Create new "MetadataCompany" nodes and connect them to the new "Archive" node, through the "Archive.metadataCompanyList" relation. */
  create?: InputMaybe<Array<MetadataCompanyWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCompanyListUpdateInput = {
  /** Connect existing "MetadataCompany" nodes to the existing "Archive" node, through the "Archive.metadataCompanyList" relation. */
  connect?: InputMaybe<Array<MetadataCompanyWhereUniqueInput>>;
  /** Create new "MetadataCompany" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCompanyList" relation. */
  create?: InputMaybe<Array<MetadataCompanyWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCompany" nodes from the "Archive.metadataCompanyList" relation. */
  delete?: InputMaybe<Array<MetadataCompanyWhereUniqueInput>>;
  /** Update existing "MetadataCompany" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCompanyList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCompanyListUpdateInput>>;
  /** Upsert existing "MetadataCompany" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCompanyList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCompanyListUpdateInput>>;
};

export type ArchiveNestedMetadataCompanyShortNameListCreateInput = {
  /** Connect existing "MetadataCompanyShortName" nodes to the new "Archive" node, through the "Archive.metadataCompanyShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataCompanyShortNameWhereUniqueInput>>;
  /** Create new "MetadataCompanyShortName" nodes and connect them to the new "Archive" node, through the "Archive.metadataCompanyShortNameList" relation. */
  create?: InputMaybe<Array<MetadataCompanyShortNameWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCompanyShortNameListUpdateInput = {
  /** Connect existing "MetadataCompanyShortName" nodes to the existing "Archive" node, through the "Archive.metadataCompanyShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataCompanyShortNameWhereUniqueInput>>;
  /** Create new "MetadataCompanyShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCompanyShortNameList" relation. */
  create?: InputMaybe<Array<MetadataCompanyShortNameWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCompanyShortName" nodes from the "Archive.metadataCompanyShortNameList" relation. */
  delete?: InputMaybe<Array<MetadataCompanyShortNameWhereUniqueInput>>;
  /** Update existing "MetadataCompanyShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCompanyShortNameList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCompanyShortNameListUpdateInput>>;
  /** Upsert existing "MetadataCompanyShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCompanyShortNameList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCompanyShortNameListUpdateInput>>;
};

export type ArchiveNestedMetadataConfidentialityListCreateInput = {
  /** Connect existing "MetadataConfidentiality" nodes to the new "Archive" node, through the "Archive.metadataConfidentialityList" relation. */
  connect?: InputMaybe<Array<MetadataConfidentialityWhereUniqueInput>>;
  /** Create new "MetadataConfidentiality" nodes and connect them to the new "Archive" node, through the "Archive.metadataConfidentialityList" relation. */
  create?: InputMaybe<Array<MetadataConfidentialityWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataConfidentialityListUpdateInput = {
  /** Connect existing "MetadataConfidentiality" nodes to the existing "Archive" node, through the "Archive.metadataConfidentialityList" relation. */
  connect?: InputMaybe<Array<MetadataConfidentialityWhereUniqueInput>>;
  /** Create new "MetadataConfidentiality" nodes and connect them to the existing "Archive" node, through the "Archive.metadataConfidentialityList" relation. */
  create?: InputMaybe<Array<MetadataConfidentialityWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataConfidentiality" nodes from the "Archive.metadataConfidentialityList" relation. */
  delete?: InputMaybe<Array<MetadataConfidentialityWhereUniqueInput>>;
  /** Update existing "MetadataConfidentiality" nodes and connect them to the existing "Archive" node, through the "Archive.metadataConfidentialityList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataConfidentialityListUpdateInput>>;
  /** Upsert existing "MetadataConfidentiality" nodes and connect them to the existing "Archive" node, through the "Archive.metadataConfidentialityList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataConfidentialityListUpdateInput>>;
};

export type ArchiveNestedMetadataContainedInListCreateInput = {
  /** Connect existing "MetadataContainedIn" nodes to the new "Archive" node, through the "Archive.metadataContainedInList" relation. */
  connect?: InputMaybe<Array<MetadataContainedInWhereUniqueInput>>;
  /** Create new "MetadataContainedIn" nodes and connect them to the new "Archive" node, through the "Archive.metadataContainedInList" relation. */
  create?: InputMaybe<Array<MetadataContainedInWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataContainedInListUpdateInput = {
  /** Connect existing "MetadataContainedIn" nodes to the existing "Archive" node, through the "Archive.metadataContainedInList" relation. */
  connect?: InputMaybe<Array<MetadataContainedInWhereUniqueInput>>;
  /** Create new "MetadataContainedIn" nodes and connect them to the existing "Archive" node, through the "Archive.metadataContainedInList" relation. */
  create?: InputMaybe<Array<MetadataContainedInWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataContainedIn" nodes from the "Archive.metadataContainedInList" relation. */
  delete?: InputMaybe<Array<MetadataContainedInWhereUniqueInput>>;
  /** Update existing "MetadataContainedIn" nodes and connect them to the existing "Archive" node, through the "Archive.metadataContainedInList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataContainedInListUpdateInput>>;
  /** Upsert existing "MetadataContainedIn" nodes and connect them to the existing "Archive" node, through the "Archive.metadataContainedInList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataContainedInListUpdateInput>>;
};

export type ArchiveNestedMetadataContentMediaKindListCreateInput = {
  /** Connect existing "MetadataContentMediaKind" nodes to the new "Archive" node, through the "Archive.metadataContentMediaKindList" relation. */
  connect?: InputMaybe<Array<MetadataContentMediaKindWhereUniqueInput>>;
  /** Create new "MetadataContentMediaKind" nodes and connect them to the new "Archive" node, through the "Archive.metadataContentMediaKindList" relation. */
  create?: InputMaybe<Array<MetadataContentMediaKindWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataContentMediaKindListUpdateInput = {
  /** Connect existing "MetadataContentMediaKind" nodes to the existing "Archive" node, through the "Archive.metadataContentMediaKindList" relation. */
  connect?: InputMaybe<Array<MetadataContentMediaKindWhereUniqueInput>>;
  /** Create new "MetadataContentMediaKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataContentMediaKindList" relation. */
  create?: InputMaybe<Array<MetadataContentMediaKindWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataContentMediaKind" nodes from the "Archive.metadataContentMediaKindList" relation. */
  delete?: InputMaybe<Array<MetadataContentMediaKindWhereUniqueInput>>;
  /** Update existing "MetadataContentMediaKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataContentMediaKindList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataContentMediaKindListUpdateInput>>;
  /** Upsert existing "MetadataContentMediaKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataContentMediaKindList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataContentMediaKindListUpdateInput>>;
};

export type ArchiveNestedMetadataContentValueListCreateInput = {
  /** Connect existing "MetadataContentValue" nodes to the new "Archive" node, through the "Archive.metadataContentValueList" relation. */
  connect?: InputMaybe<Array<MetadataContentValueWhereUniqueInput>>;
  /** Create new "MetadataContentValue" nodes and connect them to the new "Archive" node, through the "Archive.metadataContentValueList" relation. */
  create?: InputMaybe<Array<MetadataContentValueWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataContentValueListUpdateInput = {
  /** Connect existing "MetadataContentValue" nodes to the existing "Archive" node, through the "Archive.metadataContentValueList" relation. */
  connect?: InputMaybe<Array<MetadataContentValueWhereUniqueInput>>;
  /** Create new "MetadataContentValue" nodes and connect them to the existing "Archive" node, through the "Archive.metadataContentValueList" relation. */
  create?: InputMaybe<Array<MetadataContentValueWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataContentValue" nodes from the "Archive.metadataContentValueList" relation. */
  delete?: InputMaybe<Array<MetadataContentValueWhereUniqueInput>>;
  /** Update existing "MetadataContentValue" nodes and connect them to the existing "Archive" node, through the "Archive.metadataContentValueList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataContentValueListUpdateInput>>;
  /** Upsert existing "MetadataContentValue" nodes and connect them to the existing "Archive" node, through the "Archive.metadataContentValueList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataContentValueListUpdateInput>>;
};

export type ArchiveNestedMetadataCopyrightLayerListCreateInput = {
  /** Connect existing "MetadataCopyrightLayer" nodes to the new "Archive" node, through the "Archive.metadataCopyrightLayerList" relation. */
  connect?: InputMaybe<Array<MetadataCopyrightLayerWhereUniqueInput>>;
  /** Create new "MetadataCopyrightLayer" nodes and connect them to the new "Archive" node, through the "Archive.metadataCopyrightLayerList" relation. */
  create?: InputMaybe<Array<MetadataCopyrightLayerWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCopyrightLayerListUpdateInput = {
  /** Connect existing "MetadataCopyrightLayer" nodes to the existing "Archive" node, through the "Archive.metadataCopyrightLayerList" relation. */
  connect?: InputMaybe<Array<MetadataCopyrightLayerWhereUniqueInput>>;
  /** Create new "MetadataCopyrightLayer" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCopyrightLayerList" relation. */
  create?: InputMaybe<Array<MetadataCopyrightLayerWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCopyrightLayer" nodes from the "Archive.metadataCopyrightLayerList" relation. */
  delete?: InputMaybe<Array<MetadataCopyrightLayerWhereUniqueInput>>;
  /** Update existing "MetadataCopyrightLayer" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCopyrightLayerList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCopyrightLayerListUpdateInput>>;
  /** Upsert existing "MetadataCopyrightLayer" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCopyrightLayerList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCopyrightLayerListUpdateInput>>;
};

export type ArchiveNestedMetadataCountryCodeListCreateInput = {
  /** Connect existing "MetadataCountryCode" nodes to the new "Archive" node, through the "Archive.metadataCountryCodeList" relation. */
  connect?: InputMaybe<Array<MetadataCountryCodeWhereUniqueInput>>;
  /** Create new "MetadataCountryCode" nodes and connect them to the new "Archive" node, through the "Archive.metadataCountryCodeList" relation. */
  create?: InputMaybe<Array<MetadataCountryCodeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCountryCodeListUpdateInput = {
  /** Connect existing "MetadataCountryCode" nodes to the existing "Archive" node, through the "Archive.metadataCountryCodeList" relation. */
  connect?: InputMaybe<Array<MetadataCountryCodeWhereUniqueInput>>;
  /** Create new "MetadataCountryCode" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCountryCodeList" relation. */
  create?: InputMaybe<Array<MetadataCountryCodeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCountryCode" nodes from the "Archive.metadataCountryCodeList" relation. */
  delete?: InputMaybe<Array<MetadataCountryCodeWhereUniqueInput>>;
  /** Update existing "MetadataCountryCode" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCountryCodeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCountryCodeListUpdateInput>>;
  /** Upsert existing "MetadataCountryCode" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCountryCodeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCountryCodeListUpdateInput>>;
};

export type ArchiveNestedMetadataCountryListCreateInput = {
  /** Connect existing "MetadataCountry" nodes to the new "Archive" node, through the "Archive.metadataCountryList" relation. */
  connect?: InputMaybe<Array<MetadataCountryWhereUniqueInput>>;
  /** Create new "MetadataCountry" nodes and connect them to the new "Archive" node, through the "Archive.metadataCountryList" relation. */
  create?: InputMaybe<Array<MetadataCountryWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCountryListUpdateInput = {
  /** Connect existing "MetadataCountry" nodes to the existing "Archive" node, through the "Archive.metadataCountryList" relation. */
  connect?: InputMaybe<Array<MetadataCountryWhereUniqueInput>>;
  /** Create new "MetadataCountry" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCountryList" relation. */
  create?: InputMaybe<Array<MetadataCountryWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCountry" nodes from the "Archive.metadataCountryList" relation. */
  delete?: InputMaybe<Array<MetadataCountryWhereUniqueInput>>;
  /** Update existing "MetadataCountry" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCountryList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCountryListUpdateInput>>;
  /** Upsert existing "MetadataCountry" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCountryList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCountryListUpdateInput>>;
};

export type ArchiveNestedMetadataCreatedTimeListCreateInput = {
  /** Connect existing "MetadataCreatedTime" nodes to the new "Archive" node, through the "Archive.metadataCreatedTimeList" relation. */
  connect?: InputMaybe<Array<MetadataCreatedTimeWhereUniqueInput>>;
  /** Create new "MetadataCreatedTime" nodes and connect them to the new "Archive" node, through the "Archive.metadataCreatedTimeList" relation. */
  create?: InputMaybe<Array<MetadataCreatedTimeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCreatedTimeListUpdateInput = {
  /** Connect existing "MetadataCreatedTime" nodes to the existing "Archive" node, through the "Archive.metadataCreatedTimeList" relation. */
  connect?: InputMaybe<Array<MetadataCreatedTimeWhereUniqueInput>>;
  /** Create new "MetadataCreatedTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreatedTimeList" relation. */
  create?: InputMaybe<Array<MetadataCreatedTimeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCreatedTime" nodes from the "Archive.metadataCreatedTimeList" relation. */
  delete?: InputMaybe<Array<MetadataCreatedTimeWhereUniqueInput>>;
  /** Update existing "MetadataCreatedTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreatedTimeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCreatedTimeListUpdateInput>>;
  /** Upsert existing "MetadataCreatedTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreatedTimeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCreatedTimeListUpdateInput>>;
};

export type ArchiveNestedMetadataCreatorListCreateInput = {
  /** Connect existing "MetadataCreator" nodes to the new "Archive" node, through the "Archive.metadataCreatorList" relation. */
  connect?: InputMaybe<Array<MetadataCreatorWhereUniqueInput>>;
  /** Create new "MetadataCreator" nodes and connect them to the new "Archive" node, through the "Archive.metadataCreatorList" relation. */
  create?: InputMaybe<Array<MetadataCreatorWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCreatorListUpdateInput = {
  /** Connect existing "MetadataCreator" nodes to the existing "Archive" node, through the "Archive.metadataCreatorList" relation. */
  connect?: InputMaybe<Array<MetadataCreatorWhereUniqueInput>>;
  /** Create new "MetadataCreator" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreatorList" relation. */
  create?: InputMaybe<Array<MetadataCreatorWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCreator" nodes from the "Archive.metadataCreatorList" relation. */
  delete?: InputMaybe<Array<MetadataCreatorWhereUniqueInput>>;
  /** Update existing "MetadataCreator" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreatorList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCreatorListUpdateInput>>;
  /** Upsert existing "MetadataCreator" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreatorList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCreatorListUpdateInput>>;
};

export type ArchiveNestedMetadataCreatorToolListCreateInput = {
  /** Connect existing "MetadataCreatorTool" nodes to the new "Archive" node, through the "Archive.metadataCreatorToolList" relation. */
  connect?: InputMaybe<Array<MetadataCreatorToolWhereUniqueInput>>;
  /** Create new "MetadataCreatorTool" nodes and connect them to the new "Archive" node, through the "Archive.metadataCreatorToolList" relation. */
  create?: InputMaybe<Array<MetadataCreatorToolWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCreatorToolListUpdateInput = {
  /** Connect existing "MetadataCreatorTool" nodes to the existing "Archive" node, through the "Archive.metadataCreatorToolList" relation. */
  connect?: InputMaybe<Array<MetadataCreatorToolWhereUniqueInput>>;
  /** Create new "MetadataCreatorTool" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreatorToolList" relation. */
  create?: InputMaybe<Array<MetadataCreatorToolWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCreatorTool" nodes from the "Archive.metadataCreatorToolList" relation. */
  delete?: InputMaybe<Array<MetadataCreatorToolWhereUniqueInput>>;
  /** Update existing "MetadataCreatorTool" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreatorToolList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCreatorToolListUpdateInput>>;
  /** Upsert existing "MetadataCreatorTool" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreatorToolList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCreatorToolListUpdateInput>>;
};

export type ArchiveNestedMetadataCreditListCreateInput = {
  /** Connect existing "MetadataCredit" nodes to the new "Archive" node, through the "Archive.metadataCreditList" relation. */
  connect?: InputMaybe<Array<MetadataCreditWhereUniqueInput>>;
  /** Create new "MetadataCredit" nodes and connect them to the new "Archive" node, through the "Archive.metadataCreditList" relation. */
  create?: InputMaybe<Array<MetadataCreditWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCreditListUpdateInput = {
  /** Connect existing "MetadataCredit" nodes to the existing "Archive" node, through the "Archive.metadataCreditList" relation. */
  connect?: InputMaybe<Array<MetadataCreditWhereUniqueInput>>;
  /** Create new "MetadataCredit" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreditList" relation. */
  create?: InputMaybe<Array<MetadataCreditWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCredit" nodes from the "Archive.metadataCreditList" relation. */
  delete?: InputMaybe<Array<MetadataCreditWhereUniqueInput>>;
  /** Update existing "MetadataCredit" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreditList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCreditListUpdateInput>>;
  /** Upsert existing "MetadataCredit" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCreditList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCreditListUpdateInput>>;
};

export type ArchiveNestedMetadataCustomField14ListCreateInput = {
  /** Connect existing "MetadataCustomField14" nodes to the new "Archive" node, through the "Archive.metadataCustomField14List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField14WhereUniqueInput>>;
  /** Create new "MetadataCustomField14" nodes and connect them to the new "Archive" node, through the "Archive.metadataCustomField14List" relation. */
  create?: InputMaybe<Array<MetadataCustomField14WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCustomField14ListUpdateInput = {
  /** Connect existing "MetadataCustomField14" nodes to the existing "Archive" node, through the "Archive.metadataCustomField14List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField14WhereUniqueInput>>;
  /** Create new "MetadataCustomField14" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField14List" relation. */
  create?: InputMaybe<Array<MetadataCustomField14WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCustomField14" nodes from the "Archive.metadataCustomField14List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField14WhereUniqueInput>>;
  /** Update existing "MetadataCustomField14" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField14List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCustomField14ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField14" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField14List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCustomField14ListUpdateInput>>;
};

export type ArchiveNestedMetadataCustomField15ListCreateInput = {
  /** Connect existing "MetadataCustomField15" nodes to the new "Archive" node, through the "Archive.metadataCustomField15List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField15WhereUniqueInput>>;
  /** Create new "MetadataCustomField15" nodes and connect them to the new "Archive" node, through the "Archive.metadataCustomField15List" relation. */
  create?: InputMaybe<Array<MetadataCustomField15WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCustomField15ListUpdateInput = {
  /** Connect existing "MetadataCustomField15" nodes to the existing "Archive" node, through the "Archive.metadataCustomField15List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField15WhereUniqueInput>>;
  /** Create new "MetadataCustomField15" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField15List" relation. */
  create?: InputMaybe<Array<MetadataCustomField15WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCustomField15" nodes from the "Archive.metadataCustomField15List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField15WhereUniqueInput>>;
  /** Update existing "MetadataCustomField15" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField15List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCustomField15ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField15" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField15List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCustomField15ListUpdateInput>>;
};

export type ArchiveNestedMetadataCustomField16ListCreateInput = {
  /** Connect existing "MetadataCustomField16" nodes to the new "Archive" node, through the "Archive.metadataCustomField16List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField16WhereUniqueInput>>;
  /** Create new "MetadataCustomField16" nodes and connect them to the new "Archive" node, through the "Archive.metadataCustomField16List" relation. */
  create?: InputMaybe<Array<MetadataCustomField16WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCustomField16ListUpdateInput = {
  /** Connect existing "MetadataCustomField16" nodes to the existing "Archive" node, through the "Archive.metadataCustomField16List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField16WhereUniqueInput>>;
  /** Create new "MetadataCustomField16" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField16List" relation. */
  create?: InputMaybe<Array<MetadataCustomField16WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCustomField16" nodes from the "Archive.metadataCustomField16List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField16WhereUniqueInput>>;
  /** Update existing "MetadataCustomField16" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField16List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCustomField16ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField16" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField16List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCustomField16ListUpdateInput>>;
};

export type ArchiveNestedMetadataCustomField17ListCreateInput = {
  /** Connect existing "MetadataCustomField17" nodes to the new "Archive" node, through the "Archive.metadataCustomField17List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField17WhereUniqueInput>>;
  /** Create new "MetadataCustomField17" nodes and connect them to the new "Archive" node, through the "Archive.metadataCustomField17List" relation. */
  create?: InputMaybe<Array<MetadataCustomField17WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCustomField17ListUpdateInput = {
  /** Connect existing "MetadataCustomField17" nodes to the existing "Archive" node, through the "Archive.metadataCustomField17List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField17WhereUniqueInput>>;
  /** Create new "MetadataCustomField17" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField17List" relation. */
  create?: InputMaybe<Array<MetadataCustomField17WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCustomField17" nodes from the "Archive.metadataCustomField17List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField17WhereUniqueInput>>;
  /** Update existing "MetadataCustomField17" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField17List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCustomField17ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField17" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField17List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCustomField17ListUpdateInput>>;
};

export type ArchiveNestedMetadataCustomField20ListCreateInput = {
  /** Connect existing "MetadataCustomField20" nodes to the new "Archive" node, through the "Archive.metadataCustomField20List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField20WhereUniqueInput>>;
  /** Create new "MetadataCustomField20" nodes and connect them to the new "Archive" node, through the "Archive.metadataCustomField20List" relation. */
  create?: InputMaybe<Array<MetadataCustomField20WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCustomField20ListUpdateInput = {
  /** Connect existing "MetadataCustomField20" nodes to the existing "Archive" node, through the "Archive.metadataCustomField20List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField20WhereUniqueInput>>;
  /** Create new "MetadataCustomField20" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField20List" relation. */
  create?: InputMaybe<Array<MetadataCustomField20WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCustomField20" nodes from the "Archive.metadataCustomField20List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField20WhereUniqueInput>>;
  /** Update existing "MetadataCustomField20" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField20List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCustomField20ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField20" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField20List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCustomField20ListUpdateInput>>;
};

export type ArchiveNestedMetadataCustomField5ListCreateInput = {
  /** Connect existing "MetadataCustomField5" nodes to the new "Archive" node, through the "Archive.metadataCustomField5List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField5WhereUniqueInput>>;
  /** Create new "MetadataCustomField5" nodes and connect them to the new "Archive" node, through the "Archive.metadataCustomField5List" relation. */
  create?: InputMaybe<Array<MetadataCustomField5WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCustomField5ListUpdateInput = {
  /** Connect existing "MetadataCustomField5" nodes to the existing "Archive" node, through the "Archive.metadataCustomField5List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField5WhereUniqueInput>>;
  /** Create new "MetadataCustomField5" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField5List" relation. */
  create?: InputMaybe<Array<MetadataCustomField5WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCustomField5" nodes from the "Archive.metadataCustomField5List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField5WhereUniqueInput>>;
  /** Update existing "MetadataCustomField5" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField5List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCustomField5ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField5" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField5List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCustomField5ListUpdateInput>>;
};

export type ArchiveNestedMetadataCustomField6ListCreateInput = {
  /** Connect existing "MetadataCustomField6" nodes to the new "Archive" node, through the "Archive.metadataCustomField6List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField6WhereUniqueInput>>;
  /** Create new "MetadataCustomField6" nodes and connect them to the new "Archive" node, through the "Archive.metadataCustomField6List" relation. */
  create?: InputMaybe<Array<MetadataCustomField6WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCustomField6ListUpdateInput = {
  /** Connect existing "MetadataCustomField6" nodes to the existing "Archive" node, through the "Archive.metadataCustomField6List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField6WhereUniqueInput>>;
  /** Create new "MetadataCustomField6" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField6List" relation. */
  create?: InputMaybe<Array<MetadataCustomField6WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCustomField6" nodes from the "Archive.metadataCustomField6List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField6WhereUniqueInput>>;
  /** Update existing "MetadataCustomField6" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField6List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCustomField6ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField6" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField6List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCustomField6ListUpdateInput>>;
};

export type ArchiveNestedMetadataCustomField7ListCreateInput = {
  /** Connect existing "MetadataCustomField7" nodes to the new "Archive" node, through the "Archive.metadataCustomField7List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField7WhereUniqueInput>>;
  /** Create new "MetadataCustomField7" nodes and connect them to the new "Archive" node, through the "Archive.metadataCustomField7List" relation. */
  create?: InputMaybe<Array<MetadataCustomField7WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCustomField7ListUpdateInput = {
  /** Connect existing "MetadataCustomField7" nodes to the existing "Archive" node, through the "Archive.metadataCustomField7List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField7WhereUniqueInput>>;
  /** Create new "MetadataCustomField7" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField7List" relation. */
  create?: InputMaybe<Array<MetadataCustomField7WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCustomField7" nodes from the "Archive.metadataCustomField7List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField7WhereUniqueInput>>;
  /** Update existing "MetadataCustomField7" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField7List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCustomField7ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField7" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField7List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCustomField7ListUpdateInput>>;
};

export type ArchiveNestedMetadataCustomField9ListCreateInput = {
  /** Connect existing "MetadataCustomField9" nodes to the new "Archive" node, through the "Archive.metadataCustomField9List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField9WhereUniqueInput>>;
  /** Create new "MetadataCustomField9" nodes and connect them to the new "Archive" node, through the "Archive.metadataCustomField9List" relation. */
  create?: InputMaybe<Array<MetadataCustomField9WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataCustomField9ListUpdateInput = {
  /** Connect existing "MetadataCustomField9" nodes to the existing "Archive" node, through the "Archive.metadataCustomField9List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField9WhereUniqueInput>>;
  /** Create new "MetadataCustomField9" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField9List" relation. */
  create?: InputMaybe<Array<MetadataCustomField9WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataCustomField9" nodes from the "Archive.metadataCustomField9List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField9WhereUniqueInput>>;
  /** Update existing "MetadataCustomField9" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField9List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataCustomField9ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField9" nodes and connect them to the existing "Archive" node, through the "Archive.metadataCustomField9List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataCustomField9ListUpdateInput>>;
};

export type ArchiveNestedMetadataDateCreatedListCreateInput = {
  /** Connect existing "MetadataDateCreated" nodes to the new "Archive" node, through the "Archive.metadataDateCreatedList" relation. */
  connect?: InputMaybe<Array<MetadataDateCreatedWhereUniqueInput>>;
  /** Create new "MetadataDateCreated" nodes and connect them to the new "Archive" node, through the "Archive.metadataDateCreatedList" relation. */
  create?: InputMaybe<Array<MetadataDateCreatedWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDateCreatedListUpdateInput = {
  /** Connect existing "MetadataDateCreated" nodes to the existing "Archive" node, through the "Archive.metadataDateCreatedList" relation. */
  connect?: InputMaybe<Array<MetadataDateCreatedWhereUniqueInput>>;
  /** Create new "MetadataDateCreated" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDateCreatedList" relation. */
  create?: InputMaybe<Array<MetadataDateCreatedWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDateCreated" nodes from the "Archive.metadataDateCreatedList" relation. */
  delete?: InputMaybe<Array<MetadataDateCreatedWhereUniqueInput>>;
  /** Update existing "MetadataDateCreated" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDateCreatedList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDateCreatedListUpdateInput>>;
  /** Upsert existing "MetadataDateCreated" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDateCreatedList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDateCreatedListUpdateInput>>;
};

export type ArchiveNestedMetadataDateTimeDigitizedListCreateInput = {
  /** Connect existing "MetadataDateTimeDigitized" nodes to the new "Archive" node, through the "Archive.metadataDateTimeDigitizedList" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeDigitizedWhereUniqueInput>>;
  /** Create new "MetadataDateTimeDigitized" nodes and connect them to the new "Archive" node, through the "Archive.metadataDateTimeDigitizedList" relation. */
  create?: InputMaybe<Array<MetadataDateTimeDigitizedWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDateTimeDigitizedListUpdateInput = {
  /** Connect existing "MetadataDateTimeDigitized" nodes to the existing "Archive" node, through the "Archive.metadataDateTimeDigitizedList" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeDigitizedWhereUniqueInput>>;
  /** Create new "MetadataDateTimeDigitized" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDateTimeDigitizedList" relation. */
  create?: InputMaybe<Array<MetadataDateTimeDigitizedWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDateTimeDigitized" nodes from the "Archive.metadataDateTimeDigitizedList" relation. */
  delete?: InputMaybe<Array<MetadataDateTimeDigitizedWhereUniqueInput>>;
  /** Update existing "MetadataDateTimeDigitized" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDateTimeDigitizedList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDateTimeDigitizedListUpdateInput>>;
  /** Upsert existing "MetadataDateTimeDigitized" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDateTimeDigitizedList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDateTimeDigitizedListUpdateInput>>;
};

export type ArchiveNestedMetadataDateTimeOriginalListCreateInput = {
  /** Connect existing "MetadataDateTimeOriginal" nodes to the new "Archive" node, through the "Archive.metadataDateTimeOriginalList" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeOriginalWhereUniqueInput>>;
  /** Create new "MetadataDateTimeOriginal" nodes and connect them to the new "Archive" node, through the "Archive.metadataDateTimeOriginalList" relation. */
  create?: InputMaybe<Array<MetadataDateTimeOriginalWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDateTimeOriginalListUpdateInput = {
  /** Connect existing "MetadataDateTimeOriginal" nodes to the existing "Archive" node, through the "Archive.metadataDateTimeOriginalList" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeOriginalWhereUniqueInput>>;
  /** Create new "MetadataDateTimeOriginal" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDateTimeOriginalList" relation. */
  create?: InputMaybe<Array<MetadataDateTimeOriginalWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDateTimeOriginal" nodes from the "Archive.metadataDateTimeOriginalList" relation. */
  delete?: InputMaybe<Array<MetadataDateTimeOriginalWhereUniqueInput>>;
  /** Update existing "MetadataDateTimeOriginal" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDateTimeOriginalList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDateTimeOriginalListUpdateInput>>;
  /** Upsert existing "MetadataDateTimeOriginal" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDateTimeOriginalList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDateTimeOriginalListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryAccountListCreateInput = {
  /** Connect existing "MetadataDeliveryAccount" nodes to the new "Archive" node, through the "Archive.metadataDeliveryAccountList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryAccountWhereUniqueInput>>;
  /** Create new "MetadataDeliveryAccount" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryAccountList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryAccountWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryAccountListUpdateInput = {
  /** Connect existing "MetadataDeliveryAccount" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryAccountList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryAccountWhereUniqueInput>>;
  /** Create new "MetadataDeliveryAccount" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryAccountList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryAccountWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryAccount" nodes from the "Archive.metadataDeliveryAccountList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryAccountWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryAccount" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryAccountList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryAccountListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryAccount" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryAccountList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryAccountListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryCompanyListCreateInput = {
  /** Connect existing "MetadataDeliveryCompany" nodes to the new "Archive" node, through the "Archive.metadataDeliveryCompanyList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCompanyWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCompany" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryCompanyList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCompanyWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryCompanyListUpdateInput = {
  /** Connect existing "MetadataDeliveryCompany" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryCompanyList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCompanyWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCompany" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryCompanyList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCompanyWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryCompany" nodes from the "Archive.metadataDeliveryCompanyList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryCompanyWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryCompany" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryCompanyList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryCompanyListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryCompany" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryCompanyList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryCompanyListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryCopyrightListCreateInput = {
  /** Connect existing "MetadataDeliveryCopyright" nodes to the new "Archive" node, through the "Archive.metadataDeliveryCopyrightList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCopyrightWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCopyright" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryCopyrightList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCopyrightWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryCopyrightListUpdateInput = {
  /** Connect existing "MetadataDeliveryCopyright" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryCopyrightList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCopyrightWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCopyright" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryCopyrightList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCopyrightWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryCopyright" nodes from the "Archive.metadataDeliveryCopyrightList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryCopyrightWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryCopyright" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryCopyrightList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryCopyrightListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryCopyright" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryCopyrightList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryCopyrightListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryDateFolderListCreateInput = {
  /** Connect existing "MetadataDeliveryDateFolder" nodes to the new "Archive" node, through the "Archive.metadataDeliveryDateFolderList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateFolder" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryDateFolderList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateFolderWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryDateFolderListUpdateInput = {
  /** Connect existing "MetadataDeliveryDateFolder" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryDateFolderList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateFolder" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDateFolderList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateFolderWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryDateFolder" nodes from the "Archive.metadataDeliveryDateFolderList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDateFolderWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDateFolder" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDateFolderList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryDateFolderListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDateFolder" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDateFolderList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryDateFolderListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryDateTimeListCreateInput = {
  /** Connect existing "MetadataDeliveryDateTime" nodes to the new "Archive" node, through the "Archive.metadataDeliveryDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateTimeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateTime" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateTimeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryDateTimeListUpdateInput = {
  /** Connect existing "MetadataDeliveryDateTime" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateTimeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateTimeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryDateTime" nodes from the "Archive.metadataDeliveryDateTimeList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDateTimeWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDateTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDateTimeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryDateTimeListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDateTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDateTimeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryDateTimeListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryDcCreatorListCreateInput = {
  /** Connect existing "MetadataDeliveryDcCreator" nodes to the new "Archive" node, through the "Archive.metadataDeliveryDcCreatorList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcCreatorWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcCreator" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryDcCreatorList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcCreatorWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryDcCreatorListUpdateInput = {
  /** Connect existing "MetadataDeliveryDcCreator" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryDcCreatorList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcCreatorWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcCreator" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDcCreatorList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcCreatorWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryDcCreator" nodes from the "Archive.metadataDeliveryDcCreatorList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDcCreatorWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDcCreator" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDcCreatorList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryDcCreatorListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDcCreator" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDcCreatorList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryDcCreatorListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryDcRightsListCreateInput = {
  /** Connect existing "MetadataDeliveryDcRights" nodes to the new "Archive" node, through the "Archive.metadataDeliveryDcRightsList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcRightsWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcRights" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryDcRightsList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcRightsWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryDcRightsListUpdateInput = {
  /** Connect existing "MetadataDeliveryDcRights" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryDcRightsList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcRightsWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcRights" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDcRightsList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcRightsWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryDcRights" nodes from the "Archive.metadataDeliveryDcRightsList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDcRightsWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDcRights" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDcRightsList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryDcRightsListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDcRights" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryDcRightsList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryDcRightsListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryFileTypeListCreateInput = {
  /** Connect existing "MetadataDeliveryFileType" nodes to the new "Archive" node, through the "Archive.metadataDeliveryFileTypeList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFileTypeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFileType" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryFileTypeList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFileTypeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryFileTypeListUpdateInput = {
  /** Connect existing "MetadataDeliveryFileType" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryFileTypeList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFileTypeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFileType" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryFileTypeList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFileTypeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryFileType" nodes from the "Archive.metadataDeliveryFileTypeList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryFileTypeWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryFileType" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryFileTypeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryFileTypeListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryFileType" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryFileTypeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryFileTypeListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryFolderListCreateInput = {
  /** Connect existing "MetadataDeliveryFolder" nodes to the new "Archive" node, through the "Archive.metadataDeliveryFolderList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFolder" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryFolderList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFolderWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryFolderListUpdateInput = {
  /** Connect existing "MetadataDeliveryFolder" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryFolderList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFolder" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryFolderList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFolderWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryFolder" nodes from the "Archive.metadataDeliveryFolderList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryFolderWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryFolder" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryFolderList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryFolderListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryFolder" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryFolderList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryFolderListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryKindListCreateInput = {
  /** Connect existing "MetadataDeliveryKind" nodes to the new "Archive" node, through the "Archive.metadataDeliveryKindList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryKindWhereUniqueInput>>;
  /** Create new "MetadataDeliveryKind" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryKindList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryKindWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryKindListUpdateInput = {
  /** Connect existing "MetadataDeliveryKind" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryKindList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryKindWhereUniqueInput>>;
  /** Create new "MetadataDeliveryKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryKindList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryKindWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryKind" nodes from the "Archive.metadataDeliveryKindList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryKindWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryKindList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryKindListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryKindList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryKindListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryPathListCreateInput = {
  /** Connect existing "MetadataDeliveryPath" nodes to the new "Archive" node, through the "Archive.metadataDeliveryPathList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPathWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPath" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryPathList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPathWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryPathListUpdateInput = {
  /** Connect existing "MetadataDeliveryPath" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryPathList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPathWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPath" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPathList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPathWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryPath" nodes from the "Archive.metadataDeliveryPathList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPathWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPath" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPathList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryPathListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPath" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPathList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryPathListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryPersonShownintheImageListCreateInput = {
  /** Connect existing "MetadataDeliveryPersonShownintheImage" nodes to the new "Archive" node, through the "Archive.metadataDeliveryPersonShownintheImageList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPersonShownintheImage" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryPersonShownintheImageList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryPersonShownintheImageListUpdateInput = {
  /** Connect existing "MetadataDeliveryPersonShownintheImage" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryPersonShownintheImageList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPersonShownintheImage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPersonShownintheImageList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryPersonShownintheImage" nodes from the "Archive.metadataDeliveryPersonShownintheImageList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPersonShownintheImage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPersonShownintheImageList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryPersonShownintheImageListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPersonShownintheImage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPersonShownintheImageList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryPersonShownintheImageListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryPhotoshopCreditListCreateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopCredit" nodes to the new "Archive" node, through the "Archive.metadataDeliveryPhotoshopCreditList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopCredit" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryPhotoshopCreditList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryPhotoshopCreditListUpdateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopCredit" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryPhotoshopCreditList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopCredit" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPhotoshopCreditList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryPhotoshopCredit" nodes from the "Archive.metadataDeliveryPhotoshopCreditList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPhotoshopCredit" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPhotoshopCreditList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryPhotoshopCreditListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPhotoshopCredit" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPhotoshopCreditList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryPhotoshopCreditListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryPhotoshopSourceListCreateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopSource" nodes to the new "Archive" node, through the "Archive.metadataDeliveryPhotoshopSourceList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopSource" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryPhotoshopSourceList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryPhotoshopSourceListUpdateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopSource" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryPhotoshopSourceList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopSource" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPhotoshopSourceList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryPhotoshopSource" nodes from the "Archive.metadataDeliveryPhotoshopSourceList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPhotoshopSource" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPhotoshopSourceList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryPhotoshopSourceListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPhotoshopSource" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryPhotoshopSourceList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryPhotoshopSourceListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryServiceListCreateInput = {
  /** Connect existing "MetadataDeliveryService" nodes to the new "Archive" node, through the "Archive.metadataDeliveryServiceList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryServiceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryService" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryServiceList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryServiceWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryServiceListUpdateInput = {
  /** Connect existing "MetadataDeliveryService" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryServiceList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryServiceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryService" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryServiceList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryServiceWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryService" nodes from the "Archive.metadataDeliveryServiceList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryServiceWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryService" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryServiceList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryServiceListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryService" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryServiceList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryServiceListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliverySubjectListCreateInput = {
  /** Connect existing "MetadataDeliverySubject" nodes to the new "Archive" node, through the "Archive.metadataDeliverySubjectList" relation. */
  connect?: InputMaybe<Array<MetadataDeliverySubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliverySubject" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliverySubjectList" relation. */
  create?: InputMaybe<Array<MetadataDeliverySubjectWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliverySubjectListUpdateInput = {
  /** Connect existing "MetadataDeliverySubject" nodes to the existing "Archive" node, through the "Archive.metadataDeliverySubjectList" relation. */
  connect?: InputMaybe<Array<MetadataDeliverySubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliverySubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliverySubjectList" relation. */
  create?: InputMaybe<Array<MetadataDeliverySubjectWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliverySubject" nodes from the "Archive.metadataDeliverySubjectList" relation. */
  delete?: InputMaybe<Array<MetadataDeliverySubjectWhereUniqueInput>>;
  /** Update existing "MetadataDeliverySubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliverySubjectList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliverySubjectListUpdateInput>>;
  /** Upsert existing "MetadataDeliverySubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliverySubjectList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliverySubjectListUpdateInput>>;
};

export type ArchiveNestedMetadataDeliveryUnderSubjectListCreateInput = {
  /** Connect existing "MetadataDeliveryUnderSubject" nodes to the new "Archive" node, through the "Archive.metadataDeliveryUnderSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliveryUnderSubject" nodes and connect them to the new "Archive" node, through the "Archive.metadataDeliveryUnderSubjectList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryUnderSubjectWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDeliveryUnderSubjectListUpdateInput = {
  /** Connect existing "MetadataDeliveryUnderSubject" nodes to the existing "Archive" node, through the "Archive.metadataDeliveryUnderSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliveryUnderSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryUnderSubjectList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryUnderSubjectWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDeliveryUnderSubject" nodes from the "Archive.metadataDeliveryUnderSubjectList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryUnderSubjectWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryUnderSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryUnderSubjectList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDeliveryUnderSubjectListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryUnderSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDeliveryUnderSubjectList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDeliveryUnderSubjectListUpdateInput>>;
};

export type ArchiveNestedMetadataDepartmentListCreateInput = {
  /** Connect existing "MetadataDepartment" nodes to the new "Archive" node, through the "Archive.metadataDepartmentList" relation. */
  connect?: InputMaybe<Array<MetadataDepartmentWhereUniqueInput>>;
  /** Create new "MetadataDepartment" nodes and connect them to the new "Archive" node, through the "Archive.metadataDepartmentList" relation. */
  create?: InputMaybe<Array<MetadataDepartmentWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDepartmentListUpdateInput = {
  /** Connect existing "MetadataDepartment" nodes to the existing "Archive" node, through the "Archive.metadataDepartmentList" relation. */
  connect?: InputMaybe<Array<MetadataDepartmentWhereUniqueInput>>;
  /** Create new "MetadataDepartment" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDepartmentList" relation. */
  create?: InputMaybe<Array<MetadataDepartmentWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDepartment" nodes from the "Archive.metadataDepartmentList" relation. */
  delete?: InputMaybe<Array<MetadataDepartmentWhereUniqueInput>>;
  /** Update existing "MetadataDepartment" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDepartmentList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDepartmentListUpdateInput>>;
  /** Upsert existing "MetadataDepartment" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDepartmentList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDepartmentListUpdateInput>>;
};

export type ArchiveNestedMetadataDescriptionListCreateInput = {
  /** Connect existing "MetadataDescription" nodes to the new "Archive" node, through the "Archive.metadataDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataDescriptionWhereUniqueInput>>;
  /** Create new "MetadataDescription" nodes and connect them to the new "Archive" node, through the "Archive.metadataDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataDescriptionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDescriptionListUpdateInput = {
  /** Connect existing "MetadataDescription" nodes to the existing "Archive" node, through the "Archive.metadataDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataDescriptionWhereUniqueInput>>;
  /** Create new "MetadataDescription" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataDescriptionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDescription" nodes from the "Archive.metadataDescriptionList" relation. */
  delete?: InputMaybe<Array<MetadataDescriptionWhereUniqueInput>>;
  /** Update existing "MetadataDescription" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDescriptionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDescriptionListUpdateInput>>;
  /** Upsert existing "MetadataDescription" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDescriptionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDescriptionListUpdateInput>>;
};

export type ArchiveNestedMetadataDigitalAssetUrlListCreateInput = {
  /** Connect existing "MetadataDigitalAssetUrl" nodes to the new "Archive" node, through the "Archive.metadataDigitalAssetUrlList" relation. */
  connect?: InputMaybe<Array<MetadataDigitalAssetUrlWhereUniqueInput>>;
  /** Create new "MetadataDigitalAssetUrl" nodes and connect them to the new "Archive" node, through the "Archive.metadataDigitalAssetUrlList" relation. */
  create?: InputMaybe<Array<MetadataDigitalAssetUrlWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDigitalAssetUrlListUpdateInput = {
  /** Connect existing "MetadataDigitalAssetUrl" nodes to the existing "Archive" node, through the "Archive.metadataDigitalAssetUrlList" relation. */
  connect?: InputMaybe<Array<MetadataDigitalAssetUrlWhereUniqueInput>>;
  /** Create new "MetadataDigitalAssetUrl" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDigitalAssetUrlList" relation. */
  create?: InputMaybe<Array<MetadataDigitalAssetUrlWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDigitalAssetUrl" nodes from the "Archive.metadataDigitalAssetUrlList" relation. */
  delete?: InputMaybe<Array<MetadataDigitalAssetUrlWhereUniqueInput>>;
  /** Update existing "MetadataDigitalAssetUrl" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDigitalAssetUrlList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDigitalAssetUrlListUpdateInput>>;
  /** Upsert existing "MetadataDigitalAssetUrl" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDigitalAssetUrlList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDigitalAssetUrlListUpdateInput>>;
};

export type ArchiveNestedMetadataDivisionListCreateInput = {
  /** Connect existing "MetadataDivision" nodes to the new "Archive" node, through the "Archive.metadataDivisionList" relation. */
  connect?: InputMaybe<Array<MetadataDivisionWhereUniqueInput>>;
  /** Create new "MetadataDivision" nodes and connect them to the new "Archive" node, through the "Archive.metadataDivisionList" relation. */
  create?: InputMaybe<Array<MetadataDivisionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDivisionListUpdateInput = {
  /** Connect existing "MetadataDivision" nodes to the existing "Archive" node, through the "Archive.metadataDivisionList" relation. */
  connect?: InputMaybe<Array<MetadataDivisionWhereUniqueInput>>;
  /** Create new "MetadataDivision" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDivisionList" relation. */
  create?: InputMaybe<Array<MetadataDivisionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDivision" nodes from the "Archive.metadataDivisionList" relation. */
  delete?: InputMaybe<Array<MetadataDivisionWhereUniqueInput>>;
  /** Update existing "MetadataDivision" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDivisionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDivisionListUpdateInput>>;
  /** Upsert existing "MetadataDivision" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDivisionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDivisionListUpdateInput>>;
};

export type ArchiveNestedMetadataDocumentTextListCreateInput = {
  /** Connect existing "MetadataDocumentText" nodes to the new "Archive" node, through the "Archive.metadataDocumentTextList" relation. */
  connect?: InputMaybe<Array<MetadataDocumentTextWhereUniqueInput>>;
  /** Create new "MetadataDocumentText" nodes and connect them to the new "Archive" node, through the "Archive.metadataDocumentTextList" relation. */
  create?: InputMaybe<Array<MetadataDocumentTextWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataDocumentTextListUpdateInput = {
  /** Connect existing "MetadataDocumentText" nodes to the existing "Archive" node, through the "Archive.metadataDocumentTextList" relation. */
  connect?: InputMaybe<Array<MetadataDocumentTextWhereUniqueInput>>;
  /** Create new "MetadataDocumentText" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDocumentTextList" relation. */
  create?: InputMaybe<Array<MetadataDocumentTextWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataDocumentText" nodes from the "Archive.metadataDocumentTextList" relation. */
  delete?: InputMaybe<Array<MetadataDocumentTextWhereUniqueInput>>;
  /** Update existing "MetadataDocumentText" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDocumentTextList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataDocumentTextListUpdateInput>>;
  /** Upsert existing "MetadataDocumentText" nodes and connect them to the existing "Archive" node, through the "Archive.metadataDocumentTextList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataDocumentTextListUpdateInput>>;
};

export type ArchiveNestedMetadataEditionListCreateInput = {
  /** Connect existing "MetadataEdition" nodes to the new "Archive" node, through the "Archive.metadataEditionList" relation. */
  connect?: InputMaybe<Array<MetadataEditionWhereUniqueInput>>;
  /** Create new "MetadataEdition" nodes and connect them to the new "Archive" node, through the "Archive.metadataEditionList" relation. */
  create?: InputMaybe<Array<MetadataEditionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataEditionListUpdateInput = {
  /** Connect existing "MetadataEdition" nodes to the existing "Archive" node, through the "Archive.metadataEditionList" relation. */
  connect?: InputMaybe<Array<MetadataEditionWhereUniqueInput>>;
  /** Create new "MetadataEdition" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEditionList" relation. */
  create?: InputMaybe<Array<MetadataEditionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataEdition" nodes from the "Archive.metadataEditionList" relation. */
  delete?: InputMaybe<Array<MetadataEditionWhereUniqueInput>>;
  /** Update existing "MetadataEdition" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEditionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataEditionListUpdateInput>>;
  /** Upsert existing "MetadataEdition" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEditionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataEditionListUpdateInput>>;
};

export type ArchiveNestedMetadataEditorialVersionListCreateInput = {
  /** Connect existing "MetadataEditorialVersion" nodes to the new "Archive" node, through the "Archive.metadataEditorialVersionList" relation. */
  connect?: InputMaybe<Array<MetadataEditorialVersionWhereUniqueInput>>;
  /** Create new "MetadataEditorialVersion" nodes and connect them to the new "Archive" node, through the "Archive.metadataEditorialVersionList" relation. */
  create?: InputMaybe<Array<MetadataEditorialVersionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataEditorialVersionListUpdateInput = {
  /** Connect existing "MetadataEditorialVersion" nodes to the existing "Archive" node, through the "Archive.metadataEditorialVersionList" relation. */
  connect?: InputMaybe<Array<MetadataEditorialVersionWhereUniqueInput>>;
  /** Create new "MetadataEditorialVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEditorialVersionList" relation. */
  create?: InputMaybe<Array<MetadataEditorialVersionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataEditorialVersion" nodes from the "Archive.metadataEditorialVersionList" relation. */
  delete?: InputMaybe<Array<MetadataEditorialVersionWhereUniqueInput>>;
  /** Update existing "MetadataEditorialVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEditorialVersionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataEditorialVersionListUpdateInput>>;
  /** Upsert existing "MetadataEditorialVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEditorialVersionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataEditorialVersionListUpdateInput>>;
};

export type ArchiveNestedMetadataEditStatusListCreateInput = {
  /** Connect existing "MetadataEditStatus" nodes to the new "Archive" node, through the "Archive.metadataEditStatusList" relation. */
  connect?: InputMaybe<Array<MetadataEditStatusWhereUniqueInput>>;
  /** Create new "MetadataEditStatus" nodes and connect them to the new "Archive" node, through the "Archive.metadataEditStatusList" relation. */
  create?: InputMaybe<Array<MetadataEditStatusWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataEditStatusListUpdateInput = {
  /** Connect existing "MetadataEditStatus" nodes to the existing "Archive" node, through the "Archive.metadataEditStatusList" relation. */
  connect?: InputMaybe<Array<MetadataEditStatusWhereUniqueInput>>;
  /** Create new "MetadataEditStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEditStatusList" relation. */
  create?: InputMaybe<Array<MetadataEditStatusWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataEditStatus" nodes from the "Archive.metadataEditStatusList" relation. */
  delete?: InputMaybe<Array<MetadataEditStatusWhereUniqueInput>>;
  /** Update existing "MetadataEditStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEditStatusList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataEditStatusListUpdateInput>>;
  /** Upsert existing "MetadataEditStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEditStatusList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataEditStatusListUpdateInput>>;
};

export type ArchiveNestedMetadataEnvironnementPhotoListCreateInput = {
  /** Connect existing "MetadataEnvironnementPhoto" nodes to the new "Archive" node, through the "Archive.metadataEnvironnementPhotoList" relation. */
  connect?: InputMaybe<Array<MetadataEnvironnementPhotoWhereUniqueInput>>;
  /** Create new "MetadataEnvironnementPhoto" nodes and connect them to the new "Archive" node, through the "Archive.metadataEnvironnementPhotoList" relation. */
  create?: InputMaybe<Array<MetadataEnvironnementPhotoWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataEnvironnementPhotoListUpdateInput = {
  /** Connect existing "MetadataEnvironnementPhoto" nodes to the existing "Archive" node, through the "Archive.metadataEnvironnementPhotoList" relation. */
  connect?: InputMaybe<Array<MetadataEnvironnementPhotoWhereUniqueInput>>;
  /** Create new "MetadataEnvironnementPhoto" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEnvironnementPhotoList" relation. */
  create?: InputMaybe<Array<MetadataEnvironnementPhotoWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataEnvironnementPhoto" nodes from the "Archive.metadataEnvironnementPhotoList" relation. */
  delete?: InputMaybe<Array<MetadataEnvironnementPhotoWhereUniqueInput>>;
  /** Update existing "MetadataEnvironnementPhoto" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEnvironnementPhotoList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataEnvironnementPhotoListUpdateInput>>;
  /** Upsert existing "MetadataEnvironnementPhoto" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEnvironnementPhotoList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataEnvironnementPhotoListUpdateInput>>;
};

export type ArchiveNestedMetadataEquipmentInstitutionListCreateInput = {
  /** Connect existing "MetadataEquipmentInstitution" nodes to the new "Archive" node, through the "Archive.metadataEquipmentInstitutionList" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentInstitutionWhereUniqueInput>>;
  /** Create new "MetadataEquipmentInstitution" nodes and connect them to the new "Archive" node, through the "Archive.metadataEquipmentInstitutionList" relation. */
  create?: InputMaybe<Array<MetadataEquipmentInstitutionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataEquipmentInstitutionListUpdateInput = {
  /** Connect existing "MetadataEquipmentInstitution" nodes to the existing "Archive" node, through the "Archive.metadataEquipmentInstitutionList" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentInstitutionWhereUniqueInput>>;
  /** Create new "MetadataEquipmentInstitution" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEquipmentInstitutionList" relation. */
  create?: InputMaybe<Array<MetadataEquipmentInstitutionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataEquipmentInstitution" nodes from the "Archive.metadataEquipmentInstitutionList" relation. */
  delete?: InputMaybe<Array<MetadataEquipmentInstitutionWhereUniqueInput>>;
  /** Update existing "MetadataEquipmentInstitution" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEquipmentInstitutionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataEquipmentInstitutionListUpdateInput>>;
  /** Upsert existing "MetadataEquipmentInstitution" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEquipmentInstitutionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataEquipmentInstitutionListUpdateInput>>;
};

export type ArchiveNestedMetadataEquipmentManufacturerListCreateInput = {
  /** Connect existing "MetadataEquipmentManufacturer" nodes to the new "Archive" node, through the "Archive.metadataEquipmentManufacturerList" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentManufacturerWhereUniqueInput>>;
  /** Create new "MetadataEquipmentManufacturer" nodes and connect them to the new "Archive" node, through the "Archive.metadataEquipmentManufacturerList" relation. */
  create?: InputMaybe<Array<MetadataEquipmentManufacturerWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataEquipmentManufacturerListUpdateInput = {
  /** Connect existing "MetadataEquipmentManufacturer" nodes to the existing "Archive" node, through the "Archive.metadataEquipmentManufacturerList" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentManufacturerWhereUniqueInput>>;
  /** Create new "MetadataEquipmentManufacturer" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEquipmentManufacturerList" relation. */
  create?: InputMaybe<Array<MetadataEquipmentManufacturerWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataEquipmentManufacturer" nodes from the "Archive.metadataEquipmentManufacturerList" relation. */
  delete?: InputMaybe<Array<MetadataEquipmentManufacturerWhereUniqueInput>>;
  /** Update existing "MetadataEquipmentManufacturer" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEquipmentManufacturerList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataEquipmentManufacturerListUpdateInput>>;
  /** Upsert existing "MetadataEquipmentManufacturer" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEquipmentManufacturerList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataEquipmentManufacturerListUpdateInput>>;
};

export type ArchiveNestedMetadataEventListCreateInput = {
  /** Connect existing "MetadataEvent" nodes to the new "Archive" node, through the "Archive.metadataEventList" relation. */
  connect?: InputMaybe<Array<MetadataEventWhereUniqueInput>>;
  /** Create new "MetadataEvent" nodes and connect them to the new "Archive" node, through the "Archive.metadataEventList" relation. */
  create?: InputMaybe<Array<MetadataEventWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataEventListUpdateInput = {
  /** Connect existing "MetadataEvent" nodes to the existing "Archive" node, through the "Archive.metadataEventList" relation. */
  connect?: InputMaybe<Array<MetadataEventWhereUniqueInput>>;
  /** Create new "MetadataEvent" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEventList" relation. */
  create?: InputMaybe<Array<MetadataEventWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataEvent" nodes from the "Archive.metadataEventList" relation. */
  delete?: InputMaybe<Array<MetadataEventWhereUniqueInput>>;
  /** Update existing "MetadataEvent" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEventList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataEventListUpdateInput>>;
  /** Upsert existing "MetadataEvent" nodes and connect them to the existing "Archive" node, through the "Archive.metadataEventList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataEventListUpdateInput>>;
};

export type ArchiveNestedMetadataExifListCreateInput = {
  /** Connect existing "MetadataExif" nodes to the new "Archive" node, through the "Archive.metadataExifList" relation. */
  connect?: InputMaybe<Array<MetadataExifWhereUniqueInput>>;
  /** Create new "MetadataExif" nodes and connect them to the new "Archive" node, through the "Archive.metadataExifList" relation. */
  create?: InputMaybe<Array<MetadataExifWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataExifListUpdateInput = {
  /** Connect existing "MetadataExif" nodes to the existing "Archive" node, through the "Archive.metadataExifList" relation. */
  connect?: InputMaybe<Array<MetadataExifWhereUniqueInput>>;
  /** Create new "MetadataExif" nodes and connect them to the existing "Archive" node, through the "Archive.metadataExifList" relation. */
  create?: InputMaybe<Array<MetadataExifWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataExif" nodes from the "Archive.metadataExifList" relation. */
  delete?: InputMaybe<Array<MetadataExifWhereUniqueInput>>;
  /** Update existing "MetadataExif" nodes and connect them to the existing "Archive" node, through the "Archive.metadataExifList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataExifListUpdateInput>>;
  /** Upsert existing "MetadataExif" nodes and connect them to the existing "Archive" node, through the "Archive.metadataExifList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataExifListUpdateInput>>;
};

export type ArchiveNestedMetadataFabStorageListCreateInput = {
  /** Connect existing "MetadataFabStorage" nodes to the new "Archive" node, through the "Archive.metadataFabStorageList" relation. */
  connect?: InputMaybe<Array<MetadataFabStorageWhereUniqueInput>>;
  /** Create new "MetadataFabStorage" nodes and connect them to the new "Archive" node, through the "Archive.metadataFabStorageList" relation. */
  create?: InputMaybe<Array<MetadataFabStorageWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataFabStorageListUpdateInput = {
  /** Connect existing "MetadataFabStorage" nodes to the existing "Archive" node, through the "Archive.metadataFabStorageList" relation. */
  connect?: InputMaybe<Array<MetadataFabStorageWhereUniqueInput>>;
  /** Create new "MetadataFabStorage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFabStorageList" relation. */
  create?: InputMaybe<Array<MetadataFabStorageWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataFabStorage" nodes from the "Archive.metadataFabStorageList" relation. */
  delete?: InputMaybe<Array<MetadataFabStorageWhereUniqueInput>>;
  /** Update existing "MetadataFabStorage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFabStorageList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataFabStorageListUpdateInput>>;
  /** Upsert existing "MetadataFabStorage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFabStorageList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataFabStorageListUpdateInput>>;
};

export type ArchiveNestedMetadataFileCheckForListCreateInput = {
  /** Connect existing "MetadataFileCheckFor" nodes to the new "Archive" node, through the "Archive.metadataFileCheckForList" relation. */
  connect?: InputMaybe<Array<MetadataFileCheckForWhereUniqueInput>>;
  /** Create new "MetadataFileCheckFor" nodes and connect them to the new "Archive" node, through the "Archive.metadataFileCheckForList" relation. */
  create?: InputMaybe<Array<MetadataFileCheckForWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataFileCheckForListUpdateInput = {
  /** Connect existing "MetadataFileCheckFor" nodes to the existing "Archive" node, through the "Archive.metadataFileCheckForList" relation. */
  connect?: InputMaybe<Array<MetadataFileCheckForWhereUniqueInput>>;
  /** Create new "MetadataFileCheckFor" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileCheckForList" relation. */
  create?: InputMaybe<Array<MetadataFileCheckForWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataFileCheckFor" nodes from the "Archive.metadataFileCheckForList" relation. */
  delete?: InputMaybe<Array<MetadataFileCheckForWhereUniqueInput>>;
  /** Update existing "MetadataFileCheckFor" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileCheckForList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataFileCheckForListUpdateInput>>;
  /** Upsert existing "MetadataFileCheckFor" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileCheckForList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataFileCheckForListUpdateInput>>;
};

export type ArchiveNestedMetadataFileInfoBitsPerPixelListCreateInput = {
  /** Connect existing "MetadataFileInfoBitsPerPixel" nodes to the new "Archive" node, through the "Archive.metadataFileInfoBitsPerPixelList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWhereUniqueInput>>;
  /** Create new "MetadataFileInfoBitsPerPixel" nodes and connect them to the new "Archive" node, through the "Archive.metadataFileInfoBitsPerPixelList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataFileInfoBitsPerPixelListUpdateInput = {
  /** Connect existing "MetadataFileInfoBitsPerPixel" nodes to the existing "Archive" node, through the "Archive.metadataFileInfoBitsPerPixelList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWhereUniqueInput>>;
  /** Create new "MetadataFileInfoBitsPerPixel" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoBitsPerPixelList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataFileInfoBitsPerPixel" nodes from the "Archive.metadataFileInfoBitsPerPixelList" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoBitsPerPixel" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoBitsPerPixelList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataFileInfoBitsPerPixelListUpdateInput>>;
  /** Upsert existing "MetadataFileInfoBitsPerPixel" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoBitsPerPixelList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataFileInfoBitsPerPixelListUpdateInput>>;
};

export type ArchiveNestedMetadataFileInfoFramesListCreateInput = {
  /** Connect existing "MetadataFileInfoFrames" nodes to the new "Archive" node, through the "Archive.metadataFileInfoFramesList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoFramesWhereUniqueInput>>;
  /** Create new "MetadataFileInfoFrames" nodes and connect them to the new "Archive" node, through the "Archive.metadataFileInfoFramesList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoFramesWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataFileInfoFramesListUpdateInput = {
  /** Connect existing "MetadataFileInfoFrames" nodes to the existing "Archive" node, through the "Archive.metadataFileInfoFramesList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoFramesWhereUniqueInput>>;
  /** Create new "MetadataFileInfoFrames" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoFramesList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoFramesWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataFileInfoFrames" nodes from the "Archive.metadataFileInfoFramesList" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoFramesWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoFrames" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoFramesList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataFileInfoFramesListUpdateInput>>;
  /** Upsert existing "MetadataFileInfoFrames" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoFramesList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataFileInfoFramesListUpdateInput>>;
};

export type ArchiveNestedMetadataFileInfoPhotometricInterpretationListCreateInput = {
  /** Connect existing "MetadataFileInfoPhotometricInterpretation" nodes to the new "Archive" node, through the "Archive.metadataFileInfoPhotometricInterpretationList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWhereUniqueInput>>;
  /** Create new "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the new "Archive" node, through the "Archive.metadataFileInfoPhotometricInterpretationList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataFileInfoPhotometricInterpretationListUpdateInput = {
  /** Connect existing "MetadataFileInfoPhotometricInterpretation" nodes to the existing "Archive" node, through the "Archive.metadataFileInfoPhotometricInterpretationList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWhereUniqueInput>>;
  /** Create new "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoPhotometricInterpretationList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataFileInfoPhotometricInterpretation" nodes from the "Archive.metadataFileInfoPhotometricInterpretationList" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoPhotometricInterpretationList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataFileInfoPhotometricInterpretationListUpdateInput>>;
  /** Upsert existing "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoPhotometricInterpretationList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataFileInfoPhotometricInterpretationListUpdateInput>>;
};

export type ArchiveNestedMetadataFileInfoTransferSyntaxListCreateInput = {
  /** Connect existing "MetadataFileInfoTransferSyntax" nodes to the new "Archive" node, through the "Archive.metadataFileInfoTransferSyntaxList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWhereUniqueInput>>;
  /** Create new "MetadataFileInfoTransferSyntax" nodes and connect them to the new "Archive" node, through the "Archive.metadataFileInfoTransferSyntaxList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataFileInfoTransferSyntaxListUpdateInput = {
  /** Connect existing "MetadataFileInfoTransferSyntax" nodes to the existing "Archive" node, through the "Archive.metadataFileInfoTransferSyntaxList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWhereUniqueInput>>;
  /** Create new "MetadataFileInfoTransferSyntax" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoTransferSyntaxList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataFileInfoTransferSyntax" nodes from the "Archive.metadataFileInfoTransferSyntaxList" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoTransferSyntax" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoTransferSyntaxList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataFileInfoTransferSyntaxListUpdateInput>>;
  /** Upsert existing "MetadataFileInfoTransferSyntax" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileInfoTransferSyntaxList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataFileInfoTransferSyntaxListUpdateInput>>;
};

export type ArchiveNestedMetadataFileStageListCreateInput = {
  /** Connect existing "MetadataFileStage" nodes to the new "Archive" node, through the "Archive.metadataFileStageList" relation. */
  connect?: InputMaybe<Array<MetadataFileStageWhereUniqueInput>>;
  /** Create new "MetadataFileStage" nodes and connect them to the new "Archive" node, through the "Archive.metadataFileStageList" relation. */
  create?: InputMaybe<Array<MetadataFileStageWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataFileStageListUpdateInput = {
  /** Connect existing "MetadataFileStage" nodes to the existing "Archive" node, through the "Archive.metadataFileStageList" relation. */
  connect?: InputMaybe<Array<MetadataFileStageWhereUniqueInput>>;
  /** Create new "MetadataFileStage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileStageList" relation. */
  create?: InputMaybe<Array<MetadataFileStageWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataFileStage" nodes from the "Archive.metadataFileStageList" relation. */
  delete?: InputMaybe<Array<MetadataFileStageWhereUniqueInput>>;
  /** Update existing "MetadataFileStage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileStageList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataFileStageListUpdateInput>>;
  /** Upsert existing "MetadataFileStage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileStageList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataFileStageListUpdateInput>>;
};

export type ArchiveNestedMetadataFileStatusListCreateInput = {
  /** Connect existing "MetadataFileStatus" nodes to the new "Archive" node, through the "Archive.metadataFileStatusList" relation. */
  connect?: InputMaybe<Array<MetadataFileStatusWhereUniqueInput>>;
  /** Create new "MetadataFileStatus" nodes and connect them to the new "Archive" node, through the "Archive.metadataFileStatusList" relation. */
  create?: InputMaybe<Array<MetadataFileStatusWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataFileStatusListUpdateInput = {
  /** Connect existing "MetadataFileStatus" nodes to the existing "Archive" node, through the "Archive.metadataFileStatusList" relation. */
  connect?: InputMaybe<Array<MetadataFileStatusWhereUniqueInput>>;
  /** Create new "MetadataFileStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileStatusList" relation. */
  create?: InputMaybe<Array<MetadataFileStatusWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataFileStatus" nodes from the "Archive.metadataFileStatusList" relation. */
  delete?: InputMaybe<Array<MetadataFileStatusWhereUniqueInput>>;
  /** Update existing "MetadataFileStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileStatusList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataFileStatusListUpdateInput>>;
  /** Upsert existing "MetadataFileStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileStatusList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataFileStatusListUpdateInput>>;
};

export type ArchiveNestedMetadataFileTypeListCreateInput = {
  /** Connect existing "MetadataFileType" nodes to the new "Archive" node, through the "Archive.metadataFileTypeList" relation. */
  connect?: InputMaybe<Array<MetadataFileTypeWhereUniqueInput>>;
  /** Create new "MetadataFileType" nodes and connect them to the new "Archive" node, through the "Archive.metadataFileTypeList" relation. */
  create?: InputMaybe<Array<MetadataFileTypeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataFileTypeListUpdateInput = {
  /** Connect existing "MetadataFileType" nodes to the existing "Archive" node, through the "Archive.metadataFileTypeList" relation. */
  connect?: InputMaybe<Array<MetadataFileTypeWhereUniqueInput>>;
  /** Create new "MetadataFileType" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileTypeList" relation. */
  create?: InputMaybe<Array<MetadataFileTypeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataFileType" nodes from the "Archive.metadataFileTypeList" relation. */
  delete?: InputMaybe<Array<MetadataFileTypeWhereUniqueInput>>;
  /** Update existing "MetadataFileType" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileTypeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataFileTypeListUpdateInput>>;
  /** Upsert existing "MetadataFileType" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFileTypeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataFileTypeListUpdateInput>>;
};

export type ArchiveNestedMetadataFixtureIdentifierListCreateInput = {
  /** Connect existing "MetadataFixtureIdentifier" nodes to the new "Archive" node, through the "Archive.metadataFixtureIdentifierList" relation. */
  connect?: InputMaybe<Array<MetadataFixtureIdentifierWhereUniqueInput>>;
  /** Create new "MetadataFixtureIdentifier" nodes and connect them to the new "Archive" node, through the "Archive.metadataFixtureIdentifierList" relation. */
  create?: InputMaybe<Array<MetadataFixtureIdentifierWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataFixtureIdentifierListUpdateInput = {
  /** Connect existing "MetadataFixtureIdentifier" nodes to the existing "Archive" node, through the "Archive.metadataFixtureIdentifierList" relation. */
  connect?: InputMaybe<Array<MetadataFixtureIdentifierWhereUniqueInput>>;
  /** Create new "MetadataFixtureIdentifier" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFixtureIdentifierList" relation. */
  create?: InputMaybe<Array<MetadataFixtureIdentifierWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataFixtureIdentifier" nodes from the "Archive.metadataFixtureIdentifierList" relation. */
  delete?: InputMaybe<Array<MetadataFixtureIdentifierWhereUniqueInput>>;
  /** Update existing "MetadataFixtureIdentifier" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFixtureIdentifierList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataFixtureIdentifierListUpdateInput>>;
  /** Upsert existing "MetadataFixtureIdentifier" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFixtureIdentifierList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataFixtureIdentifierListUpdateInput>>;
};

export type ArchiveNestedMetadataFormatListCreateInput = {
  /** Connect existing "MetadataFormat" nodes to the new "Archive" node, through the "Archive.metadataFormatList" relation. */
  connect?: InputMaybe<Array<MetadataFormatWhereUniqueInput>>;
  /** Create new "MetadataFormat" nodes and connect them to the new "Archive" node, through the "Archive.metadataFormatList" relation. */
  create?: InputMaybe<Array<MetadataFormatWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataFormatListUpdateInput = {
  /** Connect existing "MetadataFormat" nodes to the existing "Archive" node, through the "Archive.metadataFormatList" relation. */
  connect?: InputMaybe<Array<MetadataFormatWhereUniqueInput>>;
  /** Create new "MetadataFormat" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFormatList" relation. */
  create?: InputMaybe<Array<MetadataFormatWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataFormat" nodes from the "Archive.metadataFormatList" relation. */
  delete?: InputMaybe<Array<MetadataFormatWhereUniqueInput>>;
  /** Update existing "MetadataFormat" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFormatList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataFormatListUpdateInput>>;
  /** Upsert existing "MetadataFormat" nodes and connect them to the existing "Archive" node, through the "Archive.metadataFormatList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataFormatListUpdateInput>>;
};

export type ArchiveNestedMetadataGpsLatitudeListCreateInput = {
  /** Connect existing "MetadataGpsLatitude" nodes to the new "Archive" node, through the "Archive.metadataGpsLatitudeList" relation. */
  connect?: InputMaybe<Array<MetadataGpsLatitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLatitude" nodes and connect them to the new "Archive" node, through the "Archive.metadataGpsLatitudeList" relation. */
  create?: InputMaybe<Array<MetadataGpsLatitudeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataGpsLatitudeListUpdateInput = {
  /** Connect existing "MetadataGpsLatitude" nodes to the existing "Archive" node, through the "Archive.metadataGpsLatitudeList" relation. */
  connect?: InputMaybe<Array<MetadataGpsLatitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLatitude" nodes and connect them to the existing "Archive" node, through the "Archive.metadataGpsLatitudeList" relation. */
  create?: InputMaybe<Array<MetadataGpsLatitudeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataGpsLatitude" nodes from the "Archive.metadataGpsLatitudeList" relation. */
  delete?: InputMaybe<Array<MetadataGpsLatitudeWhereUniqueInput>>;
  /** Update existing "MetadataGpsLatitude" nodes and connect them to the existing "Archive" node, through the "Archive.metadataGpsLatitudeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataGpsLatitudeListUpdateInput>>;
  /** Upsert existing "MetadataGpsLatitude" nodes and connect them to the existing "Archive" node, through the "Archive.metadataGpsLatitudeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataGpsLatitudeListUpdateInput>>;
};

export type ArchiveNestedMetadataGpsLongitudeListCreateInput = {
  /** Connect existing "MetadataGpsLongitude" nodes to the new "Archive" node, through the "Archive.metadataGpsLongitudeList" relation. */
  connect?: InputMaybe<Array<MetadataGpsLongitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLongitude" nodes and connect them to the new "Archive" node, through the "Archive.metadataGpsLongitudeList" relation. */
  create?: InputMaybe<Array<MetadataGpsLongitudeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataGpsLongitudeListUpdateInput = {
  /** Connect existing "MetadataGpsLongitude" nodes to the existing "Archive" node, through the "Archive.metadataGpsLongitudeList" relation. */
  connect?: InputMaybe<Array<MetadataGpsLongitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLongitude" nodes and connect them to the existing "Archive" node, through the "Archive.metadataGpsLongitudeList" relation. */
  create?: InputMaybe<Array<MetadataGpsLongitudeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataGpsLongitude" nodes from the "Archive.metadataGpsLongitudeList" relation. */
  delete?: InputMaybe<Array<MetadataGpsLongitudeWhereUniqueInput>>;
  /** Update existing "MetadataGpsLongitude" nodes and connect them to the existing "Archive" node, through the "Archive.metadataGpsLongitudeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataGpsLongitudeListUpdateInput>>;
  /** Upsert existing "MetadataGpsLongitude" nodes and connect them to the existing "Archive" node, through the "Archive.metadataGpsLongitudeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataGpsLongitudeListUpdateInput>>;
};

export type ArchiveNestedMetadataGpsProcessingMethodListCreateInput = {
  /** Connect existing "MetadataGpsProcessingMethod" nodes to the new "Archive" node, through the "Archive.metadataGpsProcessingMethodList" relation. */
  connect?: InputMaybe<Array<MetadataGpsProcessingMethodWhereUniqueInput>>;
  /** Create new "MetadataGpsProcessingMethod" nodes and connect them to the new "Archive" node, through the "Archive.metadataGpsProcessingMethodList" relation. */
  create?: InputMaybe<Array<MetadataGpsProcessingMethodWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataGpsProcessingMethodListUpdateInput = {
  /** Connect existing "MetadataGpsProcessingMethod" nodes to the existing "Archive" node, through the "Archive.metadataGpsProcessingMethodList" relation. */
  connect?: InputMaybe<Array<MetadataGpsProcessingMethodWhereUniqueInput>>;
  /** Create new "MetadataGpsProcessingMethod" nodes and connect them to the existing "Archive" node, through the "Archive.metadataGpsProcessingMethodList" relation. */
  create?: InputMaybe<Array<MetadataGpsProcessingMethodWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataGpsProcessingMethod" nodes from the "Archive.metadataGpsProcessingMethodList" relation. */
  delete?: InputMaybe<Array<MetadataGpsProcessingMethodWhereUniqueInput>>;
  /** Update existing "MetadataGpsProcessingMethod" nodes and connect them to the existing "Archive" node, through the "Archive.metadataGpsProcessingMethodList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataGpsProcessingMethodListUpdateInput>>;
  /** Upsert existing "MetadataGpsProcessingMethod" nodes and connect them to the existing "Archive" node, through the "Archive.metadataGpsProcessingMethodList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataGpsProcessingMethodListUpdateInput>>;
};

export type ArchiveNestedMetadataHeaderListCreateInput = {
  /** Connect existing "MetadataHeader" nodes to the new "Archive" node, through the "Archive.metadataHeaderList" relation. */
  connect?: InputMaybe<Array<MetadataHeaderWhereUniqueInput>>;
  /** Create new "MetadataHeader" nodes and connect them to the new "Archive" node, through the "Archive.metadataHeaderList" relation. */
  create?: InputMaybe<Array<MetadataHeaderWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataHeaderListUpdateInput = {
  /** Connect existing "MetadataHeader" nodes to the existing "Archive" node, through the "Archive.metadataHeaderList" relation. */
  connect?: InputMaybe<Array<MetadataHeaderWhereUniqueInput>>;
  /** Create new "MetadataHeader" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHeaderList" relation. */
  create?: InputMaybe<Array<MetadataHeaderWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataHeader" nodes from the "Archive.metadataHeaderList" relation. */
  delete?: InputMaybe<Array<MetadataHeaderWhereUniqueInput>>;
  /** Update existing "MetadataHeader" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHeaderList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataHeaderListUpdateInput>>;
  /** Upsert existing "MetadataHeader" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHeaderList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataHeaderListUpdateInput>>;
};

export type ArchiveNestedMetadataHeaderPrismaListCreateInput = {
  /** Connect existing "MetadataHeaderPrisma" nodes to the new "Archive" node, through the "Archive.metadataHeaderPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataHeaderPrismaWhereUniqueInput>>;
  /** Create new "MetadataHeaderPrisma" nodes and connect them to the new "Archive" node, through the "Archive.metadataHeaderPrismaList" relation. */
  create?: InputMaybe<Array<MetadataHeaderPrismaWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataHeaderPrismaListUpdateInput = {
  /** Connect existing "MetadataHeaderPrisma" nodes to the existing "Archive" node, through the "Archive.metadataHeaderPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataHeaderPrismaWhereUniqueInput>>;
  /** Create new "MetadataHeaderPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHeaderPrismaList" relation. */
  create?: InputMaybe<Array<MetadataHeaderPrismaWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataHeaderPrisma" nodes from the "Archive.metadataHeaderPrismaList" relation. */
  delete?: InputMaybe<Array<MetadataHeaderPrismaWhereUniqueInput>>;
  /** Update existing "MetadataHeaderPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHeaderPrismaList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataHeaderPrismaListUpdateInput>>;
  /** Upsert existing "MetadataHeaderPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHeaderPrismaList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataHeaderPrismaListUpdateInput>>;
};

export type ArchiveNestedMetadataHeadlineListCreateInput = {
  /** Connect existing "MetadataHeadline" nodes to the new "Archive" node, through the "Archive.metadataHeadlineList" relation. */
  connect?: InputMaybe<Array<MetadataHeadlineWhereUniqueInput>>;
  /** Create new "MetadataHeadline" nodes and connect them to the new "Archive" node, through the "Archive.metadataHeadlineList" relation. */
  create?: InputMaybe<Array<MetadataHeadlineWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataHeadlineListUpdateInput = {
  /** Connect existing "MetadataHeadline" nodes to the existing "Archive" node, through the "Archive.metadataHeadlineList" relation. */
  connect?: InputMaybe<Array<MetadataHeadlineWhereUniqueInput>>;
  /** Create new "MetadataHeadline" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHeadlineList" relation. */
  create?: InputMaybe<Array<MetadataHeadlineWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataHeadline" nodes from the "Archive.metadataHeadlineList" relation. */
  delete?: InputMaybe<Array<MetadataHeadlineWhereUniqueInput>>;
  /** Update existing "MetadataHeadline" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHeadlineList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataHeadlineListUpdateInput>>;
  /** Upsert existing "MetadataHeadline" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHeadlineList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataHeadlineListUpdateInput>>;
};

export type ArchiveNestedMetadataHistoryListCreateInput = {
  /** Connect existing "MetadataHistory" nodes to the new "Archive" node, through the "Archive.metadataHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataHistoryWhereUniqueInput>>;
  /** Create new "MetadataHistory" nodes and connect them to the new "Archive" node, through the "Archive.metadataHistoryList" relation. */
  create?: InputMaybe<Array<MetadataHistoryWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataHistoryListUpdateInput = {
  /** Connect existing "MetadataHistory" nodes to the existing "Archive" node, through the "Archive.metadataHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataHistoryWhereUniqueInput>>;
  /** Create new "MetadataHistory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHistoryList" relation. */
  create?: InputMaybe<Array<MetadataHistoryWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataHistory" nodes from the "Archive.metadataHistoryList" relation. */
  delete?: InputMaybe<Array<MetadataHistoryWhereUniqueInput>>;
  /** Update existing "MetadataHistory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHistoryList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataHistoryListUpdateInput>>;
  /** Upsert existing "MetadataHistory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataHistoryList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataHistoryListUpdateInput>>;
};

export type ArchiveNestedMetadataIccProfileListCreateInput = {
  /** Connect existing "MetadataIccProfile" nodes to the new "Archive" node, through the "Archive.metadataIccProfileList" relation. */
  connect?: InputMaybe<Array<MetadataIccProfileWhereUniqueInput>>;
  /** Create new "MetadataIccProfile" nodes and connect them to the new "Archive" node, through the "Archive.metadataIccProfileList" relation. */
  create?: InputMaybe<Array<MetadataIccProfileWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataIccProfileListUpdateInput = {
  /** Connect existing "MetadataIccProfile" nodes to the existing "Archive" node, through the "Archive.metadataIccProfileList" relation. */
  connect?: InputMaybe<Array<MetadataIccProfileWhereUniqueInput>>;
  /** Create new "MetadataIccProfile" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIccProfileList" relation. */
  create?: InputMaybe<Array<MetadataIccProfileWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataIccProfile" nodes from the "Archive.metadataIccProfileList" relation. */
  delete?: InputMaybe<Array<MetadataIccProfileWhereUniqueInput>>;
  /** Update existing "MetadataIccProfile" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIccProfileList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataIccProfileListUpdateInput>>;
  /** Upsert existing "MetadataIccProfile" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIccProfileList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataIccProfileListUpdateInput>>;
};

export type ArchiveNestedMetadataIdAssignmentListCreateInput = {
  /** Connect existing "MetadataIdAssignment" nodes to the new "Archive" node, through the "Archive.metadataIdAssignmentList" relation. */
  connect?: InputMaybe<Array<MetadataIdAssignmentWhereUniqueInput>>;
  /** Create new "MetadataIdAssignment" nodes and connect them to the new "Archive" node, through the "Archive.metadataIdAssignmentList" relation. */
  create?: InputMaybe<Array<MetadataIdAssignmentWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataIdAssignmentListUpdateInput = {
  /** Connect existing "MetadataIdAssignment" nodes to the existing "Archive" node, through the "Archive.metadataIdAssignmentList" relation. */
  connect?: InputMaybe<Array<MetadataIdAssignmentWhereUniqueInput>>;
  /** Create new "MetadataIdAssignment" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdAssignmentList" relation. */
  create?: InputMaybe<Array<MetadataIdAssignmentWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataIdAssignment" nodes from the "Archive.metadataIdAssignmentList" relation. */
  delete?: InputMaybe<Array<MetadataIdAssignmentWhereUniqueInput>>;
  /** Update existing "MetadataIdAssignment" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdAssignmentList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataIdAssignmentListUpdateInput>>;
  /** Upsert existing "MetadataIdAssignment" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdAssignmentList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataIdAssignmentListUpdateInput>>;
};

export type ArchiveNestedMetadataIdMediaContentListCreateInput = {
  /** Connect existing "MetadataIdMediaContent" nodes to the new "Archive" node, through the "Archive.metadataIdMediaContentList" relation. */
  connect?: InputMaybe<Array<MetadataIdMediaContentWhereUniqueInput>>;
  /** Create new "MetadataIdMediaContent" nodes and connect them to the new "Archive" node, through the "Archive.metadataIdMediaContentList" relation. */
  create?: InputMaybe<Array<MetadataIdMediaContentWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataIdMediaContentListUpdateInput = {
  /** Connect existing "MetadataIdMediaContent" nodes to the existing "Archive" node, through the "Archive.metadataIdMediaContentList" relation. */
  connect?: InputMaybe<Array<MetadataIdMediaContentWhereUniqueInput>>;
  /** Create new "MetadataIdMediaContent" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdMediaContentList" relation. */
  create?: InputMaybe<Array<MetadataIdMediaContentWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataIdMediaContent" nodes from the "Archive.metadataIdMediaContentList" relation. */
  delete?: InputMaybe<Array<MetadataIdMediaContentWhereUniqueInput>>;
  /** Update existing "MetadataIdMediaContent" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdMediaContentList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataIdMediaContentListUpdateInput>>;
  /** Upsert existing "MetadataIdMediaContent" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdMediaContentList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataIdMediaContentListUpdateInput>>;
};

export type ArchiveNestedMetadataIdPhotoPrismaListCreateInput = {
  /** Connect existing "MetadataIdPhotoPrisma" nodes to the new "Archive" node, through the "Archive.metadataIdPhotoPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataIdPhotoPrismaWhereUniqueInput>>;
  /** Create new "MetadataIdPhotoPrisma" nodes and connect them to the new "Archive" node, through the "Archive.metadataIdPhotoPrismaList" relation. */
  create?: InputMaybe<Array<MetadataIdPhotoPrismaWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataIdPhotoPrismaListUpdateInput = {
  /** Connect existing "MetadataIdPhotoPrisma" nodes to the existing "Archive" node, through the "Archive.metadataIdPhotoPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataIdPhotoPrismaWhereUniqueInput>>;
  /** Create new "MetadataIdPhotoPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdPhotoPrismaList" relation. */
  create?: InputMaybe<Array<MetadataIdPhotoPrismaWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataIdPhotoPrisma" nodes from the "Archive.metadataIdPhotoPrismaList" relation. */
  delete?: InputMaybe<Array<MetadataIdPhotoPrismaWhereUniqueInput>>;
  /** Update existing "MetadataIdPhotoPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdPhotoPrismaList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataIdPhotoPrismaListUpdateInput>>;
  /** Upsert existing "MetadataIdPhotoPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdPhotoPrismaList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataIdPhotoPrismaListUpdateInput>>;
};

export type ArchiveNestedMetadataIdSubjectListCreateInput = {
  /** Connect existing "MetadataIdSubject" nodes to the new "Archive" node, through the "Archive.metadataIdSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataIdSubjectWhereUniqueInput>>;
  /** Create new "MetadataIdSubject" nodes and connect them to the new "Archive" node, through the "Archive.metadataIdSubjectList" relation. */
  create?: InputMaybe<Array<MetadataIdSubjectWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataIdSubjectListUpdateInput = {
  /** Connect existing "MetadataIdSubject" nodes to the existing "Archive" node, through the "Archive.metadataIdSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataIdSubjectWhereUniqueInput>>;
  /** Create new "MetadataIdSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdSubjectList" relation. */
  create?: InputMaybe<Array<MetadataIdSubjectWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataIdSubject" nodes from the "Archive.metadataIdSubjectList" relation. */
  delete?: InputMaybe<Array<MetadataIdSubjectWhereUniqueInput>>;
  /** Update existing "MetadataIdSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdSubjectList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataIdSubjectListUpdateInput>>;
  /** Upsert existing "MetadataIdSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIdSubjectList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataIdSubjectListUpdateInput>>;
};

export type ArchiveNestedMetadataImageNotesListCreateInput = {
  /** Connect existing "MetadataImageNotes" nodes to the new "Archive" node, through the "Archive.metadataImageNotesList" relation. */
  connect?: InputMaybe<Array<MetadataImageNotesWhereUniqueInput>>;
  /** Create new "MetadataImageNotes" nodes and connect them to the new "Archive" node, through the "Archive.metadataImageNotesList" relation. */
  create?: InputMaybe<Array<MetadataImageNotesWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataImageNotesListUpdateInput = {
  /** Connect existing "MetadataImageNotes" nodes to the existing "Archive" node, through the "Archive.metadataImageNotesList" relation. */
  connect?: InputMaybe<Array<MetadataImageNotesWhereUniqueInput>>;
  /** Create new "MetadataImageNotes" nodes and connect them to the existing "Archive" node, through the "Archive.metadataImageNotesList" relation. */
  create?: InputMaybe<Array<MetadataImageNotesWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataImageNotes" nodes from the "Archive.metadataImageNotesList" relation. */
  delete?: InputMaybe<Array<MetadataImageNotesWhereUniqueInput>>;
  /** Update existing "MetadataImageNotes" nodes and connect them to the existing "Archive" node, through the "Archive.metadataImageNotesList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataImageNotesListUpdateInput>>;
  /** Upsert existing "MetadataImageNotes" nodes and connect them to the existing "Archive" node, through the "Archive.metadataImageNotesList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataImageNotesListUpdateInput>>;
};

export type ArchiveNestedMetadataImageUniqueIdListCreateInput = {
  /** Connect existing "MetadataImageUniqueId" nodes to the new "Archive" node, through the "Archive.metadataImageUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataImageUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataImageUniqueId" nodes and connect them to the new "Archive" node, through the "Archive.metadataImageUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataImageUniqueIdWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataImageUniqueIdListUpdateInput = {
  /** Connect existing "MetadataImageUniqueId" nodes to the existing "Archive" node, through the "Archive.metadataImageUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataImageUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataImageUniqueId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataImageUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataImageUniqueIdWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataImageUniqueId" nodes from the "Archive.metadataImageUniqueIdList" relation. */
  delete?: InputMaybe<Array<MetadataImageUniqueIdWhereUniqueInput>>;
  /** Update existing "MetadataImageUniqueId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataImageUniqueIdList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataImageUniqueIdListUpdateInput>>;
  /** Upsert existing "MetadataImageUniqueId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataImageUniqueIdList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataImageUniqueIdListUpdateInput>>;
};

export type ArchiveNestedMetadataInstructionsListCreateInput = {
  /** Connect existing "MetadataInstructions" nodes to the new "Archive" node, through the "Archive.metadataInstructionsList" relation. */
  connect?: InputMaybe<Array<MetadataInstructionsWhereUniqueInput>>;
  /** Create new "MetadataInstructions" nodes and connect them to the new "Archive" node, through the "Archive.metadataInstructionsList" relation. */
  create?: InputMaybe<Array<MetadataInstructionsWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataInstructionsListUpdateInput = {
  /** Connect existing "MetadataInstructions" nodes to the existing "Archive" node, through the "Archive.metadataInstructionsList" relation. */
  connect?: InputMaybe<Array<MetadataInstructionsWhereUniqueInput>>;
  /** Create new "MetadataInstructions" nodes and connect them to the existing "Archive" node, through the "Archive.metadataInstructionsList" relation. */
  create?: InputMaybe<Array<MetadataInstructionsWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataInstructions" nodes from the "Archive.metadataInstructionsList" relation. */
  delete?: InputMaybe<Array<MetadataInstructionsWhereUniqueInput>>;
  /** Update existing "MetadataInstructions" nodes and connect them to the existing "Archive" node, through the "Archive.metadataInstructionsList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataInstructionsListUpdateInput>>;
  /** Upsert existing "MetadataInstructions" nodes and connect them to the existing "Archive" node, through the "Archive.metadataInstructionsList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataInstructionsListUpdateInput>>;
};

export type ArchiveNestedMetadataIntellectualGenreListCreateInput = {
  /** Connect existing "MetadataIntellectualGenre" nodes to the new "Archive" node, through the "Archive.metadataIntellectualGenreList" relation. */
  connect?: InputMaybe<Array<MetadataIntellectualGenreWhereUniqueInput>>;
  /** Create new "MetadataIntellectualGenre" nodes and connect them to the new "Archive" node, through the "Archive.metadataIntellectualGenreList" relation. */
  create?: InputMaybe<Array<MetadataIntellectualGenreWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataIntellectualGenreListUpdateInput = {
  /** Connect existing "MetadataIntellectualGenre" nodes to the existing "Archive" node, through the "Archive.metadataIntellectualGenreList" relation. */
  connect?: InputMaybe<Array<MetadataIntellectualGenreWhereUniqueInput>>;
  /** Create new "MetadataIntellectualGenre" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIntellectualGenreList" relation. */
  create?: InputMaybe<Array<MetadataIntellectualGenreWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataIntellectualGenre" nodes from the "Archive.metadataIntellectualGenreList" relation. */
  delete?: InputMaybe<Array<MetadataIntellectualGenreWhereUniqueInput>>;
  /** Update existing "MetadataIntellectualGenre" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIntellectualGenreList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataIntellectualGenreListUpdateInput>>;
  /** Upsert existing "MetadataIntellectualGenre" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIntellectualGenreList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataIntellectualGenreListUpdateInput>>;
};

export type ArchiveNestedMetadataIsoSpeedRatingsListCreateInput = {
  /** Connect existing "MetadataIsoSpeedRatings" nodes to the new "Archive" node, through the "Archive.metadataIsoSpeedRatingsList" relation. */
  connect?: InputMaybe<Array<MetadataIsoSpeedRatingsWhereUniqueInput>>;
  /** Create new "MetadataIsoSpeedRatings" nodes and connect them to the new "Archive" node, through the "Archive.metadataIsoSpeedRatingsList" relation. */
  create?: InputMaybe<Array<MetadataIsoSpeedRatingsWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataIsoSpeedRatingsListUpdateInput = {
  /** Connect existing "MetadataIsoSpeedRatings" nodes to the existing "Archive" node, through the "Archive.metadataIsoSpeedRatingsList" relation. */
  connect?: InputMaybe<Array<MetadataIsoSpeedRatingsWhereUniqueInput>>;
  /** Create new "MetadataIsoSpeedRatings" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIsoSpeedRatingsList" relation. */
  create?: InputMaybe<Array<MetadataIsoSpeedRatingsWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataIsoSpeedRatings" nodes from the "Archive.metadataIsoSpeedRatingsList" relation. */
  delete?: InputMaybe<Array<MetadataIsoSpeedRatingsWhereUniqueInput>>;
  /** Update existing "MetadataIsoSpeedRatings" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIsoSpeedRatingsList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataIsoSpeedRatingsListUpdateInput>>;
  /** Upsert existing "MetadataIsoSpeedRatings" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIsoSpeedRatingsList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataIsoSpeedRatingsListUpdateInput>>;
};

export type ArchiveNestedMetadataIssueListCreateInput = {
  /** Connect existing "MetadataIssue" nodes to the new "Archive" node, through the "Archive.metadataIssueList" relation. */
  connect?: InputMaybe<Array<MetadataIssueWhereUniqueInput>>;
  /** Create new "MetadataIssue" nodes and connect them to the new "Archive" node, through the "Archive.metadataIssueList" relation. */
  create?: InputMaybe<Array<MetadataIssueWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataIssueListUpdateInput = {
  /** Connect existing "MetadataIssue" nodes to the existing "Archive" node, through the "Archive.metadataIssueList" relation. */
  connect?: InputMaybe<Array<MetadataIssueWhereUniqueInput>>;
  /** Create new "MetadataIssue" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIssueList" relation. */
  create?: InputMaybe<Array<MetadataIssueWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataIssue" nodes from the "Archive.metadataIssueList" relation. */
  delete?: InputMaybe<Array<MetadataIssueWhereUniqueInput>>;
  /** Update existing "MetadataIssue" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIssueList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataIssueListUpdateInput>>;
  /** Upsert existing "MetadataIssue" nodes and connect them to the existing "Archive" node, through the "Archive.metadataIssueList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataIssueListUpdateInput>>;
};

export type ArchiveNestedMetadataJobIdListCreateInput = {
  /** Connect existing "MetadataJobId" nodes to the new "Archive" node, through the "Archive.metadataJobIdList" relation. */
  connect?: InputMaybe<Array<MetadataJobIdWhereUniqueInput>>;
  /** Create new "MetadataJobId" nodes and connect them to the new "Archive" node, through the "Archive.metadataJobIdList" relation. */
  create?: InputMaybe<Array<MetadataJobIdWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataJobIdListUpdateInput = {
  /** Connect existing "MetadataJobId" nodes to the existing "Archive" node, through the "Archive.metadataJobIdList" relation. */
  connect?: InputMaybe<Array<MetadataJobIdWhereUniqueInput>>;
  /** Create new "MetadataJobId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataJobIdList" relation. */
  create?: InputMaybe<Array<MetadataJobIdWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataJobId" nodes from the "Archive.metadataJobIdList" relation. */
  delete?: InputMaybe<Array<MetadataJobIdWhereUniqueInput>>;
  /** Update existing "MetadataJobId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataJobIdList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataJobIdListUpdateInput>>;
  /** Upsert existing "MetadataJobId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataJobIdList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataJobIdListUpdateInput>>;
};

export type ArchiveNestedMetadataJobProcessingListCreateInput = {
  /** Connect existing "MetadataJobProcessing" nodes to the new "Archive" node, through the "Archive.metadataJobProcessingList" relation. */
  connect?: InputMaybe<Array<MetadataJobProcessingWhereUniqueInput>>;
  /** Create new "MetadataJobProcessing" nodes and connect them to the new "Archive" node, through the "Archive.metadataJobProcessingList" relation. */
  create?: InputMaybe<Array<MetadataJobProcessingWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataJobProcessingListUpdateInput = {
  /** Connect existing "MetadataJobProcessing" nodes to the existing "Archive" node, through the "Archive.metadataJobProcessingList" relation. */
  connect?: InputMaybe<Array<MetadataJobProcessingWhereUniqueInput>>;
  /** Create new "MetadataJobProcessing" nodes and connect them to the existing "Archive" node, through the "Archive.metadataJobProcessingList" relation. */
  create?: InputMaybe<Array<MetadataJobProcessingWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataJobProcessing" nodes from the "Archive.metadataJobProcessingList" relation. */
  delete?: InputMaybe<Array<MetadataJobProcessingWhereUniqueInput>>;
  /** Update existing "MetadataJobProcessing" nodes and connect them to the existing "Archive" node, through the "Archive.metadataJobProcessingList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataJobProcessingListUpdateInput>>;
  /** Upsert existing "MetadataJobProcessing" nodes and connect them to the existing "Archive" node, through the "Archive.metadataJobProcessingList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataJobProcessingListUpdateInput>>;
};

export type ArchiveNestedMetadataLayoutPictureEditingListCreateInput = {
  /** Connect existing "MetadataLayoutPictureEditing" nodes to the new "Archive" node, through the "Archive.metadataLayoutPictureEditingList" relation. */
  connect?: InputMaybe<Array<MetadataLayoutPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataLayoutPictureEditing" nodes and connect them to the new "Archive" node, through the "Archive.metadataLayoutPictureEditingList" relation. */
  create?: InputMaybe<Array<MetadataLayoutPictureEditingWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataLayoutPictureEditingListUpdateInput = {
  /** Connect existing "MetadataLayoutPictureEditing" nodes to the existing "Archive" node, through the "Archive.metadataLayoutPictureEditingList" relation. */
  connect?: InputMaybe<Array<MetadataLayoutPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataLayoutPictureEditing" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLayoutPictureEditingList" relation. */
  create?: InputMaybe<Array<MetadataLayoutPictureEditingWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataLayoutPictureEditing" nodes from the "Archive.metadataLayoutPictureEditingList" relation. */
  delete?: InputMaybe<Array<MetadataLayoutPictureEditingWhereUniqueInput>>;
  /** Update existing "MetadataLayoutPictureEditing" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLayoutPictureEditingList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataLayoutPictureEditingListUpdateInput>>;
  /** Upsert existing "MetadataLayoutPictureEditing" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLayoutPictureEditingList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataLayoutPictureEditingListUpdateInput>>;
};

export type ArchiveNestedMetadataLayoutStorageListCreateInput = {
  /** Connect existing "MetadataLayoutStorage" nodes to the new "Archive" node, through the "Archive.metadataLayoutStorageList" relation. */
  connect?: InputMaybe<Array<MetadataLayoutStorageWhereUniqueInput>>;
  /** Create new "MetadataLayoutStorage" nodes and connect them to the new "Archive" node, through the "Archive.metadataLayoutStorageList" relation. */
  create?: InputMaybe<Array<MetadataLayoutStorageWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataLayoutStorageListUpdateInput = {
  /** Connect existing "MetadataLayoutStorage" nodes to the existing "Archive" node, through the "Archive.metadataLayoutStorageList" relation. */
  connect?: InputMaybe<Array<MetadataLayoutStorageWhereUniqueInput>>;
  /** Create new "MetadataLayoutStorage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLayoutStorageList" relation. */
  create?: InputMaybe<Array<MetadataLayoutStorageWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataLayoutStorage" nodes from the "Archive.metadataLayoutStorageList" relation. */
  delete?: InputMaybe<Array<MetadataLayoutStorageWhereUniqueInput>>;
  /** Update existing "MetadataLayoutStorage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLayoutStorageList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataLayoutStorageListUpdateInput>>;
  /** Upsert existing "MetadataLayoutStorage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLayoutStorageList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataLayoutStorageListUpdateInput>>;
};

export type ArchiveNestedMetadataLicenseListCreateInput = {
  /** Connect existing "MetadataLicense" nodes to the new "Archive" node, through the "Archive.metadataLicenseList" relation. */
  connect?: InputMaybe<Array<MetadataLicenseWhereUniqueInput>>;
  /** Create new "MetadataLicense" nodes and connect them to the new "Archive" node, through the "Archive.metadataLicenseList" relation. */
  create?: InputMaybe<Array<MetadataLicenseWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataLicenseListUpdateInput = {
  /** Connect existing "MetadataLicense" nodes to the existing "Archive" node, through the "Archive.metadataLicenseList" relation. */
  connect?: InputMaybe<Array<MetadataLicenseWhereUniqueInput>>;
  /** Create new "MetadataLicense" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLicenseList" relation. */
  create?: InputMaybe<Array<MetadataLicenseWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataLicense" nodes from the "Archive.metadataLicenseList" relation. */
  delete?: InputMaybe<Array<MetadataLicenseWhereUniqueInput>>;
  /** Update existing "MetadataLicense" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLicenseList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataLicenseListUpdateInput>>;
  /** Upsert existing "MetadataLicense" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLicenseList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataLicenseListUpdateInput>>;
};

export type ArchiveNestedMetadataLinksListCreateInput = {
  /** Connect existing "MetadataLinks" nodes to the new "Archive" node, through the "Archive.metadataLinksList" relation. */
  connect?: InputMaybe<Array<MetadataLinksWhereUniqueInput>>;
  /** Create new "MetadataLinks" nodes and connect them to the new "Archive" node, through the "Archive.metadataLinksList" relation. */
  create?: InputMaybe<Array<MetadataLinksWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataLinksListUpdateInput = {
  /** Connect existing "MetadataLinks" nodes to the existing "Archive" node, through the "Archive.metadataLinksList" relation. */
  connect?: InputMaybe<Array<MetadataLinksWhereUniqueInput>>;
  /** Create new "MetadataLinks" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLinksList" relation. */
  create?: InputMaybe<Array<MetadataLinksWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataLinks" nodes from the "Archive.metadataLinksList" relation. */
  delete?: InputMaybe<Array<MetadataLinksWhereUniqueInput>>;
  /** Update existing "MetadataLinks" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLinksList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataLinksListUpdateInput>>;
  /** Upsert existing "MetadataLinks" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLinksList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataLinksListUpdateInput>>;
};

export type ArchiveNestedMetadataLocalCaptionListCreateInput = {
  /** Connect existing "MetadataLocalCaption" nodes to the new "Archive" node, through the "Archive.metadataLocalCaptionList" relation. */
  connect?: InputMaybe<Array<MetadataLocalCaptionWhereUniqueInput>>;
  /** Create new "MetadataLocalCaption" nodes and connect them to the new "Archive" node, through the "Archive.metadataLocalCaptionList" relation. */
  create?: InputMaybe<Array<MetadataLocalCaptionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataLocalCaptionListUpdateInput = {
  /** Connect existing "MetadataLocalCaption" nodes to the existing "Archive" node, through the "Archive.metadataLocalCaptionList" relation. */
  connect?: InputMaybe<Array<MetadataLocalCaptionWhereUniqueInput>>;
  /** Create new "MetadataLocalCaption" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLocalCaptionList" relation. */
  create?: InputMaybe<Array<MetadataLocalCaptionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataLocalCaption" nodes from the "Archive.metadataLocalCaptionList" relation. */
  delete?: InputMaybe<Array<MetadataLocalCaptionWhereUniqueInput>>;
  /** Update existing "MetadataLocalCaption" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLocalCaptionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataLocalCaptionListUpdateInput>>;
  /** Upsert existing "MetadataLocalCaption" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLocalCaptionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataLocalCaptionListUpdateInput>>;
};

export type ArchiveNestedMetadataLocationListCreateInput = {
  /** Connect existing "MetadataLocation" nodes to the new "Archive" node, through the "Archive.metadataLocationList" relation. */
  connect?: InputMaybe<Array<MetadataLocationWhereUniqueInput>>;
  /** Create new "MetadataLocation" nodes and connect them to the new "Archive" node, through the "Archive.metadataLocationList" relation. */
  create?: InputMaybe<Array<MetadataLocationWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataLocationListUpdateInput = {
  /** Connect existing "MetadataLocation" nodes to the existing "Archive" node, through the "Archive.metadataLocationList" relation. */
  connect?: InputMaybe<Array<MetadataLocationWhereUniqueInput>>;
  /** Create new "MetadataLocation" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLocationList" relation. */
  create?: InputMaybe<Array<MetadataLocationWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataLocation" nodes from the "Archive.metadataLocationList" relation. */
  delete?: InputMaybe<Array<MetadataLocationWhereUniqueInput>>;
  /** Update existing "MetadataLocation" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLocationList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataLocationListUpdateInput>>;
  /** Upsert existing "MetadataLocation" nodes and connect them to the existing "Archive" node, through the "Archive.metadataLocationList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataLocationListUpdateInput>>;
};

export type ArchiveNestedMetadataMakeListCreateInput = {
  /** Connect existing "MetadataMake" nodes to the new "Archive" node, through the "Archive.metadataMakeList" relation. */
  connect?: InputMaybe<Array<MetadataMakeWhereUniqueInput>>;
  /** Create new "MetadataMake" nodes and connect them to the new "Archive" node, through the "Archive.metadataMakeList" relation. */
  create?: InputMaybe<Array<MetadataMakeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataMakeListUpdateInput = {
  /** Connect existing "MetadataMake" nodes to the existing "Archive" node, through the "Archive.metadataMakeList" relation. */
  connect?: InputMaybe<Array<MetadataMakeWhereUniqueInput>>;
  /** Create new "MetadataMake" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMakeList" relation. */
  create?: InputMaybe<Array<MetadataMakeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataMake" nodes from the "Archive.metadataMakeList" relation. */
  delete?: InputMaybe<Array<MetadataMakeWhereUniqueInput>>;
  /** Update existing "MetadataMake" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMakeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataMakeListUpdateInput>>;
  /** Upsert existing "MetadataMake" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMakeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataMakeListUpdateInput>>;
};

export type ArchiveNestedMetadataManifestListCreateInput = {
  /** Connect existing "MetadataManifest" nodes to the new "Archive" node, through the "Archive.metadataManifestList" relation. */
  connect?: InputMaybe<Array<MetadataManifestWhereUniqueInput>>;
  /** Create new "MetadataManifest" nodes and connect them to the new "Archive" node, through the "Archive.metadataManifestList" relation. */
  create?: InputMaybe<Array<MetadataManifestWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataManifestListUpdateInput = {
  /** Connect existing "MetadataManifest" nodes to the existing "Archive" node, through the "Archive.metadataManifestList" relation. */
  connect?: InputMaybe<Array<MetadataManifestWhereUniqueInput>>;
  /** Create new "MetadataManifest" nodes and connect them to the existing "Archive" node, through the "Archive.metadataManifestList" relation. */
  create?: InputMaybe<Array<MetadataManifestWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataManifest" nodes from the "Archive.metadataManifestList" relation. */
  delete?: InputMaybe<Array<MetadataManifestWhereUniqueInput>>;
  /** Update existing "MetadataManifest" nodes and connect them to the existing "Archive" node, through the "Archive.metadataManifestList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataManifestListUpdateInput>>;
  /** Upsert existing "MetadataManifest" nodes and connect them to the existing "Archive" node, through the "Archive.metadataManifestList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataManifestListUpdateInput>>;
};

export type ArchiveNestedMetadataMarkedListCreateInput = {
  /** Connect existing "MetadataMarked" nodes to the new "Archive" node, through the "Archive.metadataMarkedList" relation. */
  connect?: InputMaybe<Array<MetadataMarkedWhereUniqueInput>>;
  /** Create new "MetadataMarked" nodes and connect them to the new "Archive" node, through the "Archive.metadataMarkedList" relation. */
  create?: InputMaybe<Array<MetadataMarkedWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataMarkedListUpdateInput = {
  /** Connect existing "MetadataMarked" nodes to the existing "Archive" node, through the "Archive.metadataMarkedList" relation. */
  connect?: InputMaybe<Array<MetadataMarkedWhereUniqueInput>>;
  /** Create new "MetadataMarked" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMarkedList" relation. */
  create?: InputMaybe<Array<MetadataMarkedWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataMarked" nodes from the "Archive.metadataMarkedList" relation. */
  delete?: InputMaybe<Array<MetadataMarkedWhereUniqueInput>>;
  /** Update existing "MetadataMarked" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMarkedList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataMarkedListUpdateInput>>;
  /** Upsert existing "MetadataMarked" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMarkedList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataMarkedListUpdateInput>>;
};

export type ArchiveNestedMetadataMasterDocumentIdListCreateInput = {
  /** Connect existing "MetadataMasterDocumentId" nodes to the new "Archive" node, through the "Archive.metadataMasterDocumentIdList" relation. */
  connect?: InputMaybe<Array<MetadataMasterDocumentIdWhereUniqueInput>>;
  /** Create new "MetadataMasterDocumentId" nodes and connect them to the new "Archive" node, through the "Archive.metadataMasterDocumentIdList" relation. */
  create?: InputMaybe<Array<MetadataMasterDocumentIdWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataMasterDocumentIdListUpdateInput = {
  /** Connect existing "MetadataMasterDocumentId" nodes to the existing "Archive" node, through the "Archive.metadataMasterDocumentIdList" relation. */
  connect?: InputMaybe<Array<MetadataMasterDocumentIdWhereUniqueInput>>;
  /** Create new "MetadataMasterDocumentId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMasterDocumentIdList" relation. */
  create?: InputMaybe<Array<MetadataMasterDocumentIdWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataMasterDocumentId" nodes from the "Archive.metadataMasterDocumentIdList" relation. */
  delete?: InputMaybe<Array<MetadataMasterDocumentIdWhereUniqueInput>>;
  /** Update existing "MetadataMasterDocumentId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMasterDocumentIdList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataMasterDocumentIdListUpdateInput>>;
  /** Upsert existing "MetadataMasterDocumentId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMasterDocumentIdList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataMasterDocumentIdListUpdateInput>>;
};

export type ArchiveNestedMetadataMaxAvailHeightListCreateInput = {
  /** Connect existing "MetadataMaxAvailHeight" nodes to the new "Archive" node, through the "Archive.metadataMaxAvailHeightList" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailHeightWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailHeight" nodes and connect them to the new "Archive" node, through the "Archive.metadataMaxAvailHeightList" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailHeightWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataMaxAvailHeightListUpdateInput = {
  /** Connect existing "MetadataMaxAvailHeight" nodes to the existing "Archive" node, through the "Archive.metadataMaxAvailHeightList" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailHeightWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailHeight" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMaxAvailHeightList" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailHeightWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataMaxAvailHeight" nodes from the "Archive.metadataMaxAvailHeightList" relation. */
  delete?: InputMaybe<Array<MetadataMaxAvailHeightWhereUniqueInput>>;
  /** Update existing "MetadataMaxAvailHeight" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMaxAvailHeightList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataMaxAvailHeightListUpdateInput>>;
  /** Upsert existing "MetadataMaxAvailHeight" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMaxAvailHeightList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataMaxAvailHeightListUpdateInput>>;
};

export type ArchiveNestedMetadataMaxAvailWidthListCreateInput = {
  /** Connect existing "MetadataMaxAvailWidth" nodes to the new "Archive" node, through the "Archive.metadataMaxAvailWidthList" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailWidthWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailWidth" nodes and connect them to the new "Archive" node, through the "Archive.metadataMaxAvailWidthList" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailWidthWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataMaxAvailWidthListUpdateInput = {
  /** Connect existing "MetadataMaxAvailWidth" nodes to the existing "Archive" node, through the "Archive.metadataMaxAvailWidthList" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailWidthWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailWidth" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMaxAvailWidthList" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailWidthWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataMaxAvailWidth" nodes from the "Archive.metadataMaxAvailWidthList" relation. */
  delete?: InputMaybe<Array<MetadataMaxAvailWidthWhereUniqueInput>>;
  /** Update existing "MetadataMaxAvailWidth" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMaxAvailWidthList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataMaxAvailWidthListUpdateInput>>;
  /** Upsert existing "MetadataMaxAvailWidth" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMaxAvailWidthList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataMaxAvailWidthListUpdateInput>>;
};

export type ArchiveNestedMetadataMinorModelAgeDisclosureListCreateInput = {
  /** Connect existing "MetadataMinorModelAgeDisclosure" nodes to the new "Archive" node, through the "Archive.metadataMinorModelAgeDisclosureList" relation. */
  connect?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWhereUniqueInput>>;
  /** Create new "MetadataMinorModelAgeDisclosure" nodes and connect them to the new "Archive" node, through the "Archive.metadataMinorModelAgeDisclosureList" relation. */
  create?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataMinorModelAgeDisclosureListUpdateInput = {
  /** Connect existing "MetadataMinorModelAgeDisclosure" nodes to the existing "Archive" node, through the "Archive.metadataMinorModelAgeDisclosureList" relation. */
  connect?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWhereUniqueInput>>;
  /** Create new "MetadataMinorModelAgeDisclosure" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMinorModelAgeDisclosureList" relation. */
  create?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataMinorModelAgeDisclosure" nodes from the "Archive.metadataMinorModelAgeDisclosureList" relation. */
  delete?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWhereUniqueInput>>;
  /** Update existing "MetadataMinorModelAgeDisclosure" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMinorModelAgeDisclosureList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataMinorModelAgeDisclosureListUpdateInput>>;
  /** Upsert existing "MetadataMinorModelAgeDisclosure" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMinorModelAgeDisclosureList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataMinorModelAgeDisclosureListUpdateInput>>;
};

export type ArchiveNestedMetadataModelAgeListCreateInput = {
  /** Connect existing "MetadataModelAge" nodes to the new "Archive" node, through the "Archive.metadataModelAgeList" relation. */
  connect?: InputMaybe<Array<MetadataModelAgeWhereUniqueInput>>;
  /** Create new "MetadataModelAge" nodes and connect them to the new "Archive" node, through the "Archive.metadataModelAgeList" relation. */
  create?: InputMaybe<Array<MetadataModelAgeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataModelAgeListUpdateInput = {
  /** Connect existing "MetadataModelAge" nodes to the existing "Archive" node, through the "Archive.metadataModelAgeList" relation. */
  connect?: InputMaybe<Array<MetadataModelAgeWhereUniqueInput>>;
  /** Create new "MetadataModelAge" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelAgeList" relation. */
  create?: InputMaybe<Array<MetadataModelAgeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataModelAge" nodes from the "Archive.metadataModelAgeList" relation. */
  delete?: InputMaybe<Array<MetadataModelAgeWhereUniqueInput>>;
  /** Update existing "MetadataModelAge" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelAgeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataModelAgeListUpdateInput>>;
  /** Upsert existing "MetadataModelAge" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelAgeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataModelAgeListUpdateInput>>;
};

export type ArchiveNestedMetadataModelListCreateInput = {
  /** Connect existing "MetadataModel" nodes to the new "Archive" node, through the "Archive.metadataModelList" relation. */
  connect?: InputMaybe<Array<MetadataModelWhereUniqueInput>>;
  /** Create new "MetadataModel" nodes and connect them to the new "Archive" node, through the "Archive.metadataModelList" relation. */
  create?: InputMaybe<Array<MetadataModelWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataModelListUpdateInput = {
  /** Connect existing "MetadataModel" nodes to the existing "Archive" node, through the "Archive.metadataModelList" relation. */
  connect?: InputMaybe<Array<MetadataModelWhereUniqueInput>>;
  /** Create new "MetadataModel" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelList" relation. */
  create?: InputMaybe<Array<MetadataModelWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataModel" nodes from the "Archive.metadataModelList" relation. */
  delete?: InputMaybe<Array<MetadataModelWhereUniqueInput>>;
  /** Update existing "MetadataModel" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataModelListUpdateInput>>;
  /** Upsert existing "MetadataModel" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataModelListUpdateInput>>;
};

export type ArchiveNestedMetadataModelReleaseIdListCreateInput = {
  /** Connect existing "MetadataModelReleaseId" nodes to the new "Archive" node, through the "Archive.metadataModelReleaseIdList" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseId" nodes and connect them to the new "Archive" node, through the "Archive.metadataModelReleaseIdList" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseIdWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataModelReleaseIdListUpdateInput = {
  /** Connect existing "MetadataModelReleaseId" nodes to the existing "Archive" node, through the "Archive.metadataModelReleaseIdList" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelReleaseIdList" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseIdWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataModelReleaseId" nodes from the "Archive.metadataModelReleaseIdList" relation. */
  delete?: InputMaybe<Array<MetadataModelReleaseIdWhereUniqueInput>>;
  /** Update existing "MetadataModelReleaseId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelReleaseIdList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataModelReleaseIdListUpdateInput>>;
  /** Upsert existing "MetadataModelReleaseId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelReleaseIdList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataModelReleaseIdListUpdateInput>>;
};

export type ArchiveNestedMetadataModelReleaseStatusListCreateInput = {
  /** Connect existing "MetadataModelReleaseStatus" nodes to the new "Archive" node, through the "Archive.metadataModelReleaseStatusList" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseStatus" nodes and connect them to the new "Archive" node, through the "Archive.metadataModelReleaseStatusList" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseStatusWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataModelReleaseStatusListUpdateInput = {
  /** Connect existing "MetadataModelReleaseStatus" nodes to the existing "Archive" node, through the "Archive.metadataModelReleaseStatusList" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelReleaseStatusList" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseStatusWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataModelReleaseStatus" nodes from the "Archive.metadataModelReleaseStatusList" relation. */
  delete?: InputMaybe<Array<MetadataModelReleaseStatusWhereUniqueInput>>;
  /** Update existing "MetadataModelReleaseStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelReleaseStatusList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataModelReleaseStatusListUpdateInput>>;
  /** Upsert existing "MetadataModelReleaseStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataModelReleaseStatusList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataModelReleaseStatusListUpdateInput>>;
};

export type ArchiveNestedMetadataMorePermissionsListCreateInput = {
  /** Connect existing "MetadataMorePermissions" nodes to the new "Archive" node, through the "Archive.metadataMorePermissionsList" relation. */
  connect?: InputMaybe<Array<MetadataMorePermissionsWhereUniqueInput>>;
  /** Create new "MetadataMorePermissions" nodes and connect them to the new "Archive" node, through the "Archive.metadataMorePermissionsList" relation. */
  create?: InputMaybe<Array<MetadataMorePermissionsWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataMorePermissionsListUpdateInput = {
  /** Connect existing "MetadataMorePermissions" nodes to the existing "Archive" node, through the "Archive.metadataMorePermissionsList" relation. */
  connect?: InputMaybe<Array<MetadataMorePermissionsWhereUniqueInput>>;
  /** Create new "MetadataMorePermissions" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMorePermissionsList" relation. */
  create?: InputMaybe<Array<MetadataMorePermissionsWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataMorePermissions" nodes from the "Archive.metadataMorePermissionsList" relation. */
  delete?: InputMaybe<Array<MetadataMorePermissionsWhereUniqueInput>>;
  /** Update existing "MetadataMorePermissions" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMorePermissionsList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataMorePermissionsListUpdateInput>>;
  /** Upsert existing "MetadataMorePermissions" nodes and connect them to the existing "Archive" node, through the "Archive.metadataMorePermissionsList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataMorePermissionsListUpdateInput>>;
};

export type ArchiveNestedMetadataObjectCycleListCreateInput = {
  /** Connect existing "MetadataObjectCycle" nodes to the new "Archive" node, through the "Archive.metadataObjectCycleList" relation. */
  connect?: InputMaybe<Array<MetadataObjectCycleWhereUniqueInput>>;
  /** Create new "MetadataObjectCycle" nodes and connect them to the new "Archive" node, through the "Archive.metadataObjectCycleList" relation. */
  create?: InputMaybe<Array<MetadataObjectCycleWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataObjectCycleListUpdateInput = {
  /** Connect existing "MetadataObjectCycle" nodes to the existing "Archive" node, through the "Archive.metadataObjectCycleList" relation. */
  connect?: InputMaybe<Array<MetadataObjectCycleWhereUniqueInput>>;
  /** Create new "MetadataObjectCycle" nodes and connect them to the existing "Archive" node, through the "Archive.metadataObjectCycleList" relation. */
  create?: InputMaybe<Array<MetadataObjectCycleWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataObjectCycle" nodes from the "Archive.metadataObjectCycleList" relation. */
  delete?: InputMaybe<Array<MetadataObjectCycleWhereUniqueInput>>;
  /** Update existing "MetadataObjectCycle" nodes and connect them to the existing "Archive" node, through the "Archive.metadataObjectCycleList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataObjectCycleListUpdateInput>>;
  /** Upsert existing "MetadataObjectCycle" nodes and connect them to the existing "Archive" node, through the "Archive.metadataObjectCycleList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataObjectCycleListUpdateInput>>;
};

export type ArchiveNestedMetadataOrganisationInImageNameListCreateInput = {
  /** Connect existing "MetadataOrganisationInImageName" nodes to the new "Archive" node, through the "Archive.metadataOrganisationInImageNameList" relation. */
  connect?: InputMaybe<Array<MetadataOrganisationInImageNameWhereUniqueInput>>;
  /** Create new "MetadataOrganisationInImageName" nodes and connect them to the new "Archive" node, through the "Archive.metadataOrganisationInImageNameList" relation. */
  create?: InputMaybe<Array<MetadataOrganisationInImageNameWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataOrganisationInImageNameListUpdateInput = {
  /** Connect existing "MetadataOrganisationInImageName" nodes to the existing "Archive" node, through the "Archive.metadataOrganisationInImageNameList" relation. */
  connect?: InputMaybe<Array<MetadataOrganisationInImageNameWhereUniqueInput>>;
  /** Create new "MetadataOrganisationInImageName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOrganisationInImageNameList" relation. */
  create?: InputMaybe<Array<MetadataOrganisationInImageNameWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataOrganisationInImageName" nodes from the "Archive.metadataOrganisationInImageNameList" relation. */
  delete?: InputMaybe<Array<MetadataOrganisationInImageNameWhereUniqueInput>>;
  /** Update existing "MetadataOrganisationInImageName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOrganisationInImageNameList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataOrganisationInImageNameListUpdateInput>>;
  /** Upsert existing "MetadataOrganisationInImageName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOrganisationInImageNameList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataOrganisationInImageNameListUpdateInput>>;
};

export type ArchiveNestedMetadataOriginalColorListCreateInput = {
  /** Connect existing "MetadataOriginalColor" nodes to the new "Archive" node, through the "Archive.metadataOriginalColorList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalColorWhereUniqueInput>>;
  /** Create new "MetadataOriginalColor" nodes and connect them to the new "Archive" node, through the "Archive.metadataOriginalColorList" relation. */
  create?: InputMaybe<Array<MetadataOriginalColorWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataOriginalColorListUpdateInput = {
  /** Connect existing "MetadataOriginalColor" nodes to the existing "Archive" node, through the "Archive.metadataOriginalColorList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalColorWhereUniqueInput>>;
  /** Create new "MetadataOriginalColor" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalColorList" relation. */
  create?: InputMaybe<Array<MetadataOriginalColorWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataOriginalColor" nodes from the "Archive.metadataOriginalColorList" relation. */
  delete?: InputMaybe<Array<MetadataOriginalColorWhereUniqueInput>>;
  /** Update existing "MetadataOriginalColor" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalColorList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataOriginalColorListUpdateInput>>;
  /** Upsert existing "MetadataOriginalColor" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalColorList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataOriginalColorListUpdateInput>>;
};

export type ArchiveNestedMetadataOriginalIccProfileListCreateInput = {
  /** Connect existing "MetadataOriginalIccProfile" nodes to the new "Archive" node, through the "Archive.metadataOriginalIccProfileList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalIccProfileWhereUniqueInput>>;
  /** Create new "MetadataOriginalIccProfile" nodes and connect them to the new "Archive" node, through the "Archive.metadataOriginalIccProfileList" relation. */
  create?: InputMaybe<Array<MetadataOriginalIccProfileWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataOriginalIccProfileListUpdateInput = {
  /** Connect existing "MetadataOriginalIccProfile" nodes to the existing "Archive" node, through the "Archive.metadataOriginalIccProfileList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalIccProfileWhereUniqueInput>>;
  /** Create new "MetadataOriginalIccProfile" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalIccProfileList" relation. */
  create?: InputMaybe<Array<MetadataOriginalIccProfileWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataOriginalIccProfile" nodes from the "Archive.metadataOriginalIccProfileList" relation. */
  delete?: InputMaybe<Array<MetadataOriginalIccProfileWhereUniqueInput>>;
  /** Update existing "MetadataOriginalIccProfile" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalIccProfileList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataOriginalIccProfileListUpdateInput>>;
  /** Upsert existing "MetadataOriginalIccProfile" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalIccProfileList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataOriginalIccProfileListUpdateInput>>;
};

export type ArchiveNestedMetadataOriginalNameListCreateInput = {
  /** Connect existing "MetadataOriginalName" nodes to the new "Archive" node, through the "Archive.metadataOriginalNameList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalNameWhereUniqueInput>>;
  /** Create new "MetadataOriginalName" nodes and connect them to the new "Archive" node, through the "Archive.metadataOriginalNameList" relation. */
  create?: InputMaybe<Array<MetadataOriginalNameWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataOriginalNameListUpdateInput = {
  /** Connect existing "MetadataOriginalName" nodes to the existing "Archive" node, through the "Archive.metadataOriginalNameList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalNameWhereUniqueInput>>;
  /** Create new "MetadataOriginalName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalNameList" relation. */
  create?: InputMaybe<Array<MetadataOriginalNameWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataOriginalName" nodes from the "Archive.metadataOriginalNameList" relation. */
  delete?: InputMaybe<Array<MetadataOriginalNameWhereUniqueInput>>;
  /** Update existing "MetadataOriginalName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalNameList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataOriginalNameListUpdateInput>>;
  /** Upsert existing "MetadataOriginalName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalNameList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataOriginalNameListUpdateInput>>;
};

export type ArchiveNestedMetadataOriginalWeigthListCreateInput = {
  /** Connect existing "MetadataOriginalWeigth" nodes to the new "Archive" node, through the "Archive.metadataOriginalWeigthList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalWeigthWhereUniqueInput>>;
  /** Create new "MetadataOriginalWeigth" nodes and connect them to the new "Archive" node, through the "Archive.metadataOriginalWeigthList" relation. */
  create?: InputMaybe<Array<MetadataOriginalWeigthWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataOriginalWeigthListUpdateInput = {
  /** Connect existing "MetadataOriginalWeigth" nodes to the existing "Archive" node, through the "Archive.metadataOriginalWeigthList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalWeigthWhereUniqueInput>>;
  /** Create new "MetadataOriginalWeigth" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalWeigthList" relation. */
  create?: InputMaybe<Array<MetadataOriginalWeigthWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataOriginalWeigth" nodes from the "Archive.metadataOriginalWeigthList" relation. */
  delete?: InputMaybe<Array<MetadataOriginalWeigthWhereUniqueInput>>;
  /** Update existing "MetadataOriginalWeigth" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalWeigthList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataOriginalWeigthListUpdateInput>>;
  /** Upsert existing "MetadataOriginalWeigth" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOriginalWeigthList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataOriginalWeigthListUpdateInput>>;
};

export type ArchiveNestedMetadataOwnerIdListCreateInput = {
  /** Connect existing "MetadataOwnerId" nodes to the new "Archive" node, through the "Archive.metadataOwnerIdList" relation. */
  connect?: InputMaybe<Array<MetadataOwnerIdWhereUniqueInput>>;
  /** Create new "MetadataOwnerId" nodes and connect them to the new "Archive" node, through the "Archive.metadataOwnerIdList" relation. */
  create?: InputMaybe<Array<MetadataOwnerIdWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataOwnerIdListUpdateInput = {
  /** Connect existing "MetadataOwnerId" nodes to the existing "Archive" node, through the "Archive.metadataOwnerIdList" relation. */
  connect?: InputMaybe<Array<MetadataOwnerIdWhereUniqueInput>>;
  /** Create new "MetadataOwnerId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOwnerIdList" relation. */
  create?: InputMaybe<Array<MetadataOwnerIdWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataOwnerId" nodes from the "Archive.metadataOwnerIdList" relation. */
  delete?: InputMaybe<Array<MetadataOwnerIdWhereUniqueInput>>;
  /** Update existing "MetadataOwnerId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOwnerIdList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataOwnerIdListUpdateInput>>;
  /** Upsert existing "MetadataOwnerId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOwnerIdList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataOwnerIdListUpdateInput>>;
};

export type ArchiveNestedMetadataOwnerListCreateInput = {
  /** Connect existing "MetadataOwner" nodes to the new "Archive" node, through the "Archive.metadataOwnerList" relation. */
  connect?: InputMaybe<Array<MetadataOwnerWhereUniqueInput>>;
  /** Create new "MetadataOwner" nodes and connect them to the new "Archive" node, through the "Archive.metadataOwnerList" relation. */
  create?: InputMaybe<Array<MetadataOwnerWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataOwnerListUpdateInput = {
  /** Connect existing "MetadataOwner" nodes to the existing "Archive" node, through the "Archive.metadataOwnerList" relation. */
  connect?: InputMaybe<Array<MetadataOwnerWhereUniqueInput>>;
  /** Create new "MetadataOwner" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOwnerList" relation. */
  create?: InputMaybe<Array<MetadataOwnerWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataOwner" nodes from the "Archive.metadataOwnerList" relation. */
  delete?: InputMaybe<Array<MetadataOwnerWhereUniqueInput>>;
  /** Update existing "MetadataOwner" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOwnerList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataOwnerListUpdateInput>>;
  /** Upsert existing "MetadataOwner" nodes and connect them to the existing "Archive" node, through the "Archive.metadataOwnerList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataOwnerListUpdateInput>>;
};

export type ArchiveNestedMetadataPageListCreateInput = {
  /** Connect existing "MetadataPage" nodes to the new "Archive" node, through the "Archive.metadataPageList" relation. */
  connect?: InputMaybe<Array<MetadataPageWhereUniqueInput>>;
  /** Create new "MetadataPage" nodes and connect them to the new "Archive" node, through the "Archive.metadataPageList" relation. */
  create?: InputMaybe<Array<MetadataPageWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPageListUpdateInput = {
  /** Connect existing "MetadataPage" nodes to the existing "Archive" node, through the "Archive.metadataPageList" relation. */
  connect?: InputMaybe<Array<MetadataPageWhereUniqueInput>>;
  /** Create new "MetadataPage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPageList" relation. */
  create?: InputMaybe<Array<MetadataPageWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPage" nodes from the "Archive.metadataPageList" relation. */
  delete?: InputMaybe<Array<MetadataPageWhereUniqueInput>>;
  /** Update existing "MetadataPage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPageList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPageListUpdateInput>>;
  /** Upsert existing "MetadataPage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPageList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPageListUpdateInput>>;
};

export type ArchiveNestedMetadataPagePrismaListCreateInput = {
  /** Connect existing "MetadataPagePrisma" nodes to the new "Archive" node, through the "Archive.metadataPagePrismaList" relation. */
  connect?: InputMaybe<Array<MetadataPagePrismaWhereUniqueInput>>;
  /** Create new "MetadataPagePrisma" nodes and connect them to the new "Archive" node, through the "Archive.metadataPagePrismaList" relation. */
  create?: InputMaybe<Array<MetadataPagePrismaWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPagePrismaListUpdateInput = {
  /** Connect existing "MetadataPagePrisma" nodes to the existing "Archive" node, through the "Archive.metadataPagePrismaList" relation. */
  connect?: InputMaybe<Array<MetadataPagePrismaWhereUniqueInput>>;
  /** Create new "MetadataPagePrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPagePrismaList" relation. */
  create?: InputMaybe<Array<MetadataPagePrismaWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPagePrisma" nodes from the "Archive.metadataPagePrismaList" relation. */
  delete?: InputMaybe<Array<MetadataPagePrismaWhereUniqueInput>>;
  /** Update existing "MetadataPagePrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPagePrismaList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPagePrismaListUpdateInput>>;
  /** Upsert existing "MetadataPagePrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPagePrismaList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPagePrismaListUpdateInput>>;
};

export type ArchiveNestedMetadataPaintBasedCorrectionsListCreateInput = {
  /** Connect existing "MetadataPaintBasedCorrections" nodes to the new "Archive" node, through the "Archive.metadataPaintBasedCorrectionsList" relation. */
  connect?: InputMaybe<Array<MetadataPaintBasedCorrectionsWhereUniqueInput>>;
  /** Create new "MetadataPaintBasedCorrections" nodes and connect them to the new "Archive" node, through the "Archive.metadataPaintBasedCorrectionsList" relation. */
  create?: InputMaybe<Array<MetadataPaintBasedCorrectionsWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPaintBasedCorrectionsListUpdateInput = {
  /** Connect existing "MetadataPaintBasedCorrections" nodes to the existing "Archive" node, through the "Archive.metadataPaintBasedCorrectionsList" relation. */
  connect?: InputMaybe<Array<MetadataPaintBasedCorrectionsWhereUniqueInput>>;
  /** Create new "MetadataPaintBasedCorrections" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPaintBasedCorrectionsList" relation. */
  create?: InputMaybe<Array<MetadataPaintBasedCorrectionsWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPaintBasedCorrections" nodes from the "Archive.metadataPaintBasedCorrectionsList" relation. */
  delete?: InputMaybe<Array<MetadataPaintBasedCorrectionsWhereUniqueInput>>;
  /** Update existing "MetadataPaintBasedCorrections" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPaintBasedCorrectionsList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPaintBasedCorrectionsListUpdateInput>>;
  /** Upsert existing "MetadataPaintBasedCorrections" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPaintBasedCorrectionsList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPaintBasedCorrectionsListUpdateInput>>;
};

export type ArchiveNestedMetadataPatientDobListCreateInput = {
  /** Connect existing "MetadataPatientDob" nodes to the new "Archive" node, through the "Archive.metadataPatientDobList" relation. */
  connect?: InputMaybe<Array<MetadataPatientDobWhereUniqueInput>>;
  /** Create new "MetadataPatientDob" nodes and connect them to the new "Archive" node, through the "Archive.metadataPatientDobList" relation. */
  create?: InputMaybe<Array<MetadataPatientDobWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPatientDobListUpdateInput = {
  /** Connect existing "MetadataPatientDob" nodes to the existing "Archive" node, through the "Archive.metadataPatientDobList" relation. */
  connect?: InputMaybe<Array<MetadataPatientDobWhereUniqueInput>>;
  /** Create new "MetadataPatientDob" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientDobList" relation. */
  create?: InputMaybe<Array<MetadataPatientDobWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPatientDob" nodes from the "Archive.metadataPatientDobList" relation. */
  delete?: InputMaybe<Array<MetadataPatientDobWhereUniqueInput>>;
  /** Update existing "MetadataPatientDob" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientDobList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPatientDobListUpdateInput>>;
  /** Upsert existing "MetadataPatientDob" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientDobList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPatientDobListUpdateInput>>;
};

export type ArchiveNestedMetadataPatientIdListCreateInput = {
  /** Connect existing "MetadataPatientId" nodes to the new "Archive" node, through the "Archive.metadataPatientIdList" relation. */
  connect?: InputMaybe<Array<MetadataPatientIdWhereUniqueInput>>;
  /** Create new "MetadataPatientId" nodes and connect them to the new "Archive" node, through the "Archive.metadataPatientIdList" relation. */
  create?: InputMaybe<Array<MetadataPatientIdWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPatientIdListUpdateInput = {
  /** Connect existing "MetadataPatientId" nodes to the existing "Archive" node, through the "Archive.metadataPatientIdList" relation. */
  connect?: InputMaybe<Array<MetadataPatientIdWhereUniqueInput>>;
  /** Create new "MetadataPatientId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientIdList" relation. */
  create?: InputMaybe<Array<MetadataPatientIdWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPatientId" nodes from the "Archive.metadataPatientIdList" relation. */
  delete?: InputMaybe<Array<MetadataPatientIdWhereUniqueInput>>;
  /** Update existing "MetadataPatientId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientIdList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPatientIdListUpdateInput>>;
  /** Upsert existing "MetadataPatientId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientIdList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPatientIdListUpdateInput>>;
};

export type ArchiveNestedMetadataPatientNameListCreateInput = {
  /** Connect existing "MetadataPatientName" nodes to the new "Archive" node, through the "Archive.metadataPatientNameList" relation. */
  connect?: InputMaybe<Array<MetadataPatientNameWhereUniqueInput>>;
  /** Create new "MetadataPatientName" nodes and connect them to the new "Archive" node, through the "Archive.metadataPatientNameList" relation. */
  create?: InputMaybe<Array<MetadataPatientNameWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPatientNameListUpdateInput = {
  /** Connect existing "MetadataPatientName" nodes to the existing "Archive" node, through the "Archive.metadataPatientNameList" relation. */
  connect?: InputMaybe<Array<MetadataPatientNameWhereUniqueInput>>;
  /** Create new "MetadataPatientName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientNameList" relation. */
  create?: InputMaybe<Array<MetadataPatientNameWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPatientName" nodes from the "Archive.metadataPatientNameList" relation. */
  delete?: InputMaybe<Array<MetadataPatientNameWhereUniqueInput>>;
  /** Update existing "MetadataPatientName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientNameList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPatientNameListUpdateInput>>;
  /** Upsert existing "MetadataPatientName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientNameList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPatientNameListUpdateInput>>;
};

export type ArchiveNestedMetadataPatientSexListCreateInput = {
  /** Connect existing "MetadataPatientSex" nodes to the new "Archive" node, through the "Archive.metadataPatientSexList" relation. */
  connect?: InputMaybe<Array<MetadataPatientSexWhereUniqueInput>>;
  /** Create new "MetadataPatientSex" nodes and connect them to the new "Archive" node, through the "Archive.metadataPatientSexList" relation. */
  create?: InputMaybe<Array<MetadataPatientSexWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPatientSexListUpdateInput = {
  /** Connect existing "MetadataPatientSex" nodes to the existing "Archive" node, through the "Archive.metadataPatientSexList" relation. */
  connect?: InputMaybe<Array<MetadataPatientSexWhereUniqueInput>>;
  /** Create new "MetadataPatientSex" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientSexList" relation. */
  create?: InputMaybe<Array<MetadataPatientSexWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPatientSex" nodes from the "Archive.metadataPatientSexList" relation. */
  delete?: InputMaybe<Array<MetadataPatientSexWhereUniqueInput>>;
  /** Update existing "MetadataPatientSex" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientSexList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPatientSexListUpdateInput>>;
  /** Upsert existing "MetadataPatientSex" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPatientSexList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPatientSexListUpdateInput>>;
};

export type ArchiveNestedMetadataPersonInImageListCreateInput = {
  /** Connect existing "MetadataPersonInImage" nodes to the new "Archive" node, through the "Archive.metadataPersonInImageList" relation. */
  connect?: InputMaybe<Array<MetadataPersonInImageWhereUniqueInput>>;
  /** Create new "MetadataPersonInImage" nodes and connect them to the new "Archive" node, through the "Archive.metadataPersonInImageList" relation. */
  create?: InputMaybe<Array<MetadataPersonInImageWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPersonInImageListUpdateInput = {
  /** Connect existing "MetadataPersonInImage" nodes to the existing "Archive" node, through the "Archive.metadataPersonInImageList" relation. */
  connect?: InputMaybe<Array<MetadataPersonInImageWhereUniqueInput>>;
  /** Create new "MetadataPersonInImage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPersonInImageList" relation. */
  create?: InputMaybe<Array<MetadataPersonInImageWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPersonInImage" nodes from the "Archive.metadataPersonInImageList" relation. */
  delete?: InputMaybe<Array<MetadataPersonInImageWhereUniqueInput>>;
  /** Update existing "MetadataPersonInImage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPersonInImageList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPersonInImageListUpdateInput>>;
  /** Upsert existing "MetadataPersonInImage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPersonInImageList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPersonInImageListUpdateInput>>;
};

export type ArchiveNestedMetadataPhotoStorageListCreateInput = {
  /** Connect existing "MetadataPhotoStorage" nodes to the new "Archive" node, through the "Archive.metadataPhotoStorageList" relation. */
  connect?: InputMaybe<Array<MetadataPhotoStorageWhereUniqueInput>>;
  /** Create new "MetadataPhotoStorage" nodes and connect them to the new "Archive" node, through the "Archive.metadataPhotoStorageList" relation. */
  create?: InputMaybe<Array<MetadataPhotoStorageWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPhotoStorageListUpdateInput = {
  /** Connect existing "MetadataPhotoStorage" nodes to the existing "Archive" node, through the "Archive.metadataPhotoStorageList" relation. */
  connect?: InputMaybe<Array<MetadataPhotoStorageWhereUniqueInput>>;
  /** Create new "MetadataPhotoStorage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPhotoStorageList" relation. */
  create?: InputMaybe<Array<MetadataPhotoStorageWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPhotoStorage" nodes from the "Archive.metadataPhotoStorageList" relation. */
  delete?: InputMaybe<Array<MetadataPhotoStorageWhereUniqueInput>>;
  /** Update existing "MetadataPhotoStorage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPhotoStorageList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPhotoStorageListUpdateInput>>;
  /** Upsert existing "MetadataPhotoStorage" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPhotoStorageList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPhotoStorageListUpdateInput>>;
};

export type ArchiveNestedMetadataPrepressPictureEditingListCreateInput = {
  /** Connect existing "MetadataPrepressPictureEditing" nodes to the new "Archive" node, through the "Archive.metadataPrepressPictureEditingList" relation. */
  connect?: InputMaybe<Array<MetadataPrepressPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataPrepressPictureEditing" nodes and connect them to the new "Archive" node, through the "Archive.metadataPrepressPictureEditingList" relation. */
  create?: InputMaybe<Array<MetadataPrepressPictureEditingWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPrepressPictureEditingListUpdateInput = {
  /** Connect existing "MetadataPrepressPictureEditing" nodes to the existing "Archive" node, through the "Archive.metadataPrepressPictureEditingList" relation. */
  connect?: InputMaybe<Array<MetadataPrepressPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataPrepressPictureEditing" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPrepressPictureEditingList" relation. */
  create?: InputMaybe<Array<MetadataPrepressPictureEditingWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPrepressPictureEditing" nodes from the "Archive.metadataPrepressPictureEditingList" relation. */
  delete?: InputMaybe<Array<MetadataPrepressPictureEditingWhereUniqueInput>>;
  /** Update existing "MetadataPrepressPictureEditing" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPrepressPictureEditingList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPrepressPictureEditingListUpdateInput>>;
  /** Upsert existing "MetadataPrepressPictureEditing" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPrepressPictureEditingList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPrepressPictureEditingListUpdateInput>>;
};

export type ArchiveNestedMetadataPriceLevelListCreateInput = {
  /** Connect existing "MetadataPriceLevel" nodes to the new "Archive" node, through the "Archive.metadataPriceLevelList" relation. */
  connect?: InputMaybe<Array<MetadataPriceLevelWhereUniqueInput>>;
  /** Create new "MetadataPriceLevel" nodes and connect them to the new "Archive" node, through the "Archive.metadataPriceLevelList" relation. */
  create?: InputMaybe<Array<MetadataPriceLevelWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPriceLevelListUpdateInput = {
  /** Connect existing "MetadataPriceLevel" nodes to the existing "Archive" node, through the "Archive.metadataPriceLevelList" relation. */
  connect?: InputMaybe<Array<MetadataPriceLevelWhereUniqueInput>>;
  /** Create new "MetadataPriceLevel" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPriceLevelList" relation. */
  create?: InputMaybe<Array<MetadataPriceLevelWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPriceLevel" nodes from the "Archive.metadataPriceLevelList" relation. */
  delete?: InputMaybe<Array<MetadataPriceLevelWhereUniqueInput>>;
  /** Update existing "MetadataPriceLevel" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPriceLevelList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPriceLevelListUpdateInput>>;
  /** Upsert existing "MetadataPriceLevel" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPriceLevelList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPriceLevelListUpdateInput>>;
};

export type ArchiveNestedMetadataPrintingProfileListCreateInput = {
  /** Connect existing "MetadataPrintingProfile" nodes to the new "Archive" node, through the "Archive.metadataPrintingProfileList" relation. */
  connect?: InputMaybe<Array<MetadataPrintingProfileWhereUniqueInput>>;
  /** Create new "MetadataPrintingProfile" nodes and connect them to the new "Archive" node, through the "Archive.metadataPrintingProfileList" relation. */
  create?: InputMaybe<Array<MetadataPrintingProfileWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPrintingProfileListUpdateInput = {
  /** Connect existing "MetadataPrintingProfile" nodes to the existing "Archive" node, through the "Archive.metadataPrintingProfileList" relation. */
  connect?: InputMaybe<Array<MetadataPrintingProfileWhereUniqueInput>>;
  /** Create new "MetadataPrintingProfile" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPrintingProfileList" relation. */
  create?: InputMaybe<Array<MetadataPrintingProfileWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPrintingProfile" nodes from the "Archive.metadataPrintingProfileList" relation. */
  delete?: InputMaybe<Array<MetadataPrintingProfileWhereUniqueInput>>;
  /** Update existing "MetadataPrintingProfile" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPrintingProfileList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPrintingProfileListUpdateInput>>;
  /** Upsert existing "MetadataPrintingProfile" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPrintingProfileList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPrintingProfileListUpdateInput>>;
};

export type ArchiveNestedMetadataPrismaProductionListCreateInput = {
  /** Connect existing "MetadataPrismaProduction" nodes to the new "Archive" node, through the "Archive.metadataPrismaProductionList" relation. */
  connect?: InputMaybe<Array<MetadataPrismaProductionWhereUniqueInput>>;
  /** Create new "MetadataPrismaProduction" nodes and connect them to the new "Archive" node, through the "Archive.metadataPrismaProductionList" relation. */
  create?: InputMaybe<Array<MetadataPrismaProductionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPrismaProductionListUpdateInput = {
  /** Connect existing "MetadataPrismaProduction" nodes to the existing "Archive" node, through the "Archive.metadataPrismaProductionList" relation. */
  connect?: InputMaybe<Array<MetadataPrismaProductionWhereUniqueInput>>;
  /** Create new "MetadataPrismaProduction" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPrismaProductionList" relation. */
  create?: InputMaybe<Array<MetadataPrismaProductionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPrismaProduction" nodes from the "Archive.metadataPrismaProductionList" relation. */
  delete?: InputMaybe<Array<MetadataPrismaProductionWhereUniqueInput>>;
  /** Update existing "MetadataPrismaProduction" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPrismaProductionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPrismaProductionListUpdateInput>>;
  /** Upsert existing "MetadataPrismaProduction" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPrismaProductionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPrismaProductionListUpdateInput>>;
};

export type ArchiveNestedMetadataProcessHistoryListCreateInput = {
  /** Connect existing "MetadataProcessHistory" nodes to the new "Archive" node, through the "Archive.metadataProcessHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataProcessHistoryWhereUniqueInput>>;
  /** Create new "MetadataProcessHistory" nodes and connect them to the new "Archive" node, through the "Archive.metadataProcessHistoryList" relation. */
  create?: InputMaybe<Array<MetadataProcessHistoryWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataProcessHistoryListUpdateInput = {
  /** Connect existing "MetadataProcessHistory" nodes to the existing "Archive" node, through the "Archive.metadataProcessHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataProcessHistoryWhereUniqueInput>>;
  /** Create new "MetadataProcessHistory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProcessHistoryList" relation. */
  create?: InputMaybe<Array<MetadataProcessHistoryWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataProcessHistory" nodes from the "Archive.metadataProcessHistoryList" relation. */
  delete?: InputMaybe<Array<MetadataProcessHistoryWhereUniqueInput>>;
  /** Update existing "MetadataProcessHistory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProcessHistoryList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataProcessHistoryListUpdateInput>>;
  /** Upsert existing "MetadataProcessHistory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProcessHistoryList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataProcessHistoryListUpdateInput>>;
};

export type ArchiveNestedMetadataProcessParameterListCreateInput = {
  /** Connect existing "MetadataProcessParameter" nodes to the new "Archive" node, through the "Archive.metadataProcessParameterList" relation. */
  connect?: InputMaybe<Array<MetadataProcessParameterWhereUniqueInput>>;
  /** Create new "MetadataProcessParameter" nodes and connect them to the new "Archive" node, through the "Archive.metadataProcessParameterList" relation. */
  create?: InputMaybe<Array<MetadataProcessParameterWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataProcessParameterListUpdateInput = {
  /** Connect existing "MetadataProcessParameter" nodes to the existing "Archive" node, through the "Archive.metadataProcessParameterList" relation. */
  connect?: InputMaybe<Array<MetadataProcessParameterWhereUniqueInput>>;
  /** Create new "MetadataProcessParameter" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProcessParameterList" relation. */
  create?: InputMaybe<Array<MetadataProcessParameterWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataProcessParameter" nodes from the "Archive.metadataProcessParameterList" relation. */
  delete?: InputMaybe<Array<MetadataProcessParameterWhereUniqueInput>>;
  /** Update existing "MetadataProcessParameter" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProcessParameterList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataProcessParameterListUpdateInput>>;
  /** Upsert existing "MetadataProcessParameter" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProcessParameterList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataProcessParameterListUpdateInput>>;
};

export type ArchiveNestedMetadataProcessStatusListCreateInput = {
  /** Connect existing "MetadataProcessStatus" nodes to the new "Archive" node, through the "Archive.metadataProcessStatusList" relation. */
  connect?: InputMaybe<Array<MetadataProcessStatusWhereUniqueInput>>;
  /** Create new "MetadataProcessStatus" nodes and connect them to the new "Archive" node, through the "Archive.metadataProcessStatusList" relation. */
  create?: InputMaybe<Array<MetadataProcessStatusWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataProcessStatusListUpdateInput = {
  /** Connect existing "MetadataProcessStatus" nodes to the existing "Archive" node, through the "Archive.metadataProcessStatusList" relation. */
  connect?: InputMaybe<Array<MetadataProcessStatusWhereUniqueInput>>;
  /** Create new "MetadataProcessStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProcessStatusList" relation. */
  create?: InputMaybe<Array<MetadataProcessStatusWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataProcessStatus" nodes from the "Archive.metadataProcessStatusList" relation. */
  delete?: InputMaybe<Array<MetadataProcessStatusWhereUniqueInput>>;
  /** Update existing "MetadataProcessStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProcessStatusList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataProcessStatusListUpdateInput>>;
  /** Upsert existing "MetadataProcessStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProcessStatusList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataProcessStatusListUpdateInput>>;
};

export type ArchiveNestedMetadataProductListCreateInput = {
  /** Connect existing "MetadataProduct" nodes to the new "Archive" node, through the "Archive.metadataProductList" relation. */
  connect?: InputMaybe<Array<MetadataProductWhereUniqueInput>>;
  /** Create new "MetadataProduct" nodes and connect them to the new "Archive" node, through the "Archive.metadataProductList" relation. */
  create?: InputMaybe<Array<MetadataProductWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataProductListUpdateInput = {
  /** Connect existing "MetadataProduct" nodes to the existing "Archive" node, through the "Archive.metadataProductList" relation. */
  connect?: InputMaybe<Array<MetadataProductWhereUniqueInput>>;
  /** Create new "MetadataProduct" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProductList" relation. */
  create?: InputMaybe<Array<MetadataProductWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataProduct" nodes from the "Archive.metadataProductList" relation. */
  delete?: InputMaybe<Array<MetadataProductWhereUniqueInput>>;
  /** Update existing "MetadataProduct" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProductList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataProductListUpdateInput>>;
  /** Upsert existing "MetadataProduct" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProductList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataProductListUpdateInput>>;
};

export type ArchiveNestedMetadataProductShortNameListCreateInput = {
  /** Connect existing "MetadataProductShortName" nodes to the new "Archive" node, through the "Archive.metadataProductShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataProductShortNameWhereUniqueInput>>;
  /** Create new "MetadataProductShortName" nodes and connect them to the new "Archive" node, through the "Archive.metadataProductShortNameList" relation. */
  create?: InputMaybe<Array<MetadataProductShortNameWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataProductShortNameListUpdateInput = {
  /** Connect existing "MetadataProductShortName" nodes to the existing "Archive" node, through the "Archive.metadataProductShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataProductShortNameWhereUniqueInput>>;
  /** Create new "MetadataProductShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProductShortNameList" relation. */
  create?: InputMaybe<Array<MetadataProductShortNameWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataProductShortName" nodes from the "Archive.metadataProductShortNameList" relation. */
  delete?: InputMaybe<Array<MetadataProductShortNameWhereUniqueInput>>;
  /** Update existing "MetadataProductShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProductShortNameList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataProductShortNameListUpdateInput>>;
  /** Upsert existing "MetadataProductShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProductShortNameList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataProductShortNameListUpdateInput>>;
};

export type ArchiveNestedMetadataProductsListCreateInput = {
  /** Connect existing "MetadataProducts" nodes to the new "Archive" node, through the "Archive.metadataProductsList" relation. */
  connect?: InputMaybe<Array<MetadataProductsWhereUniqueInput>>;
  /** Create new "MetadataProducts" nodes and connect them to the new "Archive" node, through the "Archive.metadataProductsList" relation. */
  create?: InputMaybe<Array<MetadataProductsWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataProductsListUpdateInput = {
  /** Connect existing "MetadataProducts" nodes to the existing "Archive" node, through the "Archive.metadataProductsList" relation. */
  connect?: InputMaybe<Array<MetadataProductsWhereUniqueInput>>;
  /** Create new "MetadataProducts" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProductsList" relation. */
  create?: InputMaybe<Array<MetadataProductsWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataProducts" nodes from the "Archive.metadataProductsList" relation. */
  delete?: InputMaybe<Array<MetadataProductsWhereUniqueInput>>;
  /** Update existing "MetadataProducts" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProductsList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataProductsListUpdateInput>>;
  /** Upsert existing "MetadataProducts" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProductsList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataProductsListUpdateInput>>;
};

export type ArchiveNestedMetadataProduitsListCreateInput = {
  /** Connect existing "MetadataProduits" nodes to the new "Archive" node, through the "Archive.metadataProduitsList" relation. */
  connect?: InputMaybe<Array<MetadataProduitsWhereUniqueInput>>;
  /** Create new "MetadataProduits" nodes and connect them to the new "Archive" node, through the "Archive.metadataProduitsList" relation. */
  create?: InputMaybe<Array<MetadataProduitsWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataProduitsListUpdateInput = {
  /** Connect existing "MetadataProduits" nodes to the existing "Archive" node, through the "Archive.metadataProduitsList" relation. */
  connect?: InputMaybe<Array<MetadataProduitsWhereUniqueInput>>;
  /** Create new "MetadataProduits" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProduitsList" relation. */
  create?: InputMaybe<Array<MetadataProduitsWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataProduits" nodes from the "Archive.metadataProduitsList" relation. */
  delete?: InputMaybe<Array<MetadataProduitsWhereUniqueInput>>;
  /** Update existing "MetadataProduits" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProduitsList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataProduitsListUpdateInput>>;
  /** Upsert existing "MetadataProduits" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProduitsList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataProduitsListUpdateInput>>;
};

export type ArchiveNestedMetadataProgramVersionListCreateInput = {
  /** Connect existing "MetadataProgramVersion" nodes to the new "Archive" node, through the "Archive.metadataProgramVersionList" relation. */
  connect?: InputMaybe<Array<MetadataProgramVersionWhereUniqueInput>>;
  /** Create new "MetadataProgramVersion" nodes and connect them to the new "Archive" node, through the "Archive.metadataProgramVersionList" relation. */
  create?: InputMaybe<Array<MetadataProgramVersionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataProgramVersionListUpdateInput = {
  /** Connect existing "MetadataProgramVersion" nodes to the existing "Archive" node, through the "Archive.metadataProgramVersionList" relation. */
  connect?: InputMaybe<Array<MetadataProgramVersionWhereUniqueInput>>;
  /** Create new "MetadataProgramVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProgramVersionList" relation. */
  create?: InputMaybe<Array<MetadataProgramVersionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataProgramVersion" nodes from the "Archive.metadataProgramVersionList" relation. */
  delete?: InputMaybe<Array<MetadataProgramVersionWhereUniqueInput>>;
  /** Update existing "MetadataProgramVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProgramVersionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataProgramVersionListUpdateInput>>;
  /** Upsert existing "MetadataProgramVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataProgramVersionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataProgramVersionListUpdateInput>>;
};

export type ArchiveNestedMetadataPropertyReleaseIdListCreateInput = {
  /** Connect existing "MetadataPropertyReleaseId" nodes to the new "Archive" node, through the "Archive.metadataPropertyReleaseIdList" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseId" nodes and connect them to the new "Archive" node, through the "Archive.metadataPropertyReleaseIdList" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseIdWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPropertyReleaseIdListUpdateInput = {
  /** Connect existing "MetadataPropertyReleaseId" nodes to the existing "Archive" node, through the "Archive.metadataPropertyReleaseIdList" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPropertyReleaseIdList" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseIdWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPropertyReleaseId" nodes from the "Archive.metadataPropertyReleaseIdList" relation. */
  delete?: InputMaybe<Array<MetadataPropertyReleaseIdWhereUniqueInput>>;
  /** Update existing "MetadataPropertyReleaseId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPropertyReleaseIdList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPropertyReleaseIdListUpdateInput>>;
  /** Upsert existing "MetadataPropertyReleaseId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPropertyReleaseIdList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPropertyReleaseIdListUpdateInput>>;
};

export type ArchiveNestedMetadataPropertyReleaseStatusListCreateInput = {
  /** Connect existing "MetadataPropertyReleaseStatus" nodes to the new "Archive" node, through the "Archive.metadataPropertyReleaseStatusList" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseStatus" nodes and connect them to the new "Archive" node, through the "Archive.metadataPropertyReleaseStatusList" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseStatusWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPropertyReleaseStatusListUpdateInput = {
  /** Connect existing "MetadataPropertyReleaseStatus" nodes to the existing "Archive" node, through the "Archive.metadataPropertyReleaseStatusList" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPropertyReleaseStatusList" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseStatusWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPropertyReleaseStatus" nodes from the "Archive.metadataPropertyReleaseStatusList" relation. */
  delete?: InputMaybe<Array<MetadataPropertyReleaseStatusWhereUniqueInput>>;
  /** Update existing "MetadataPropertyReleaseStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPropertyReleaseStatusList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPropertyReleaseStatusListUpdateInput>>;
  /** Upsert existing "MetadataPropertyReleaseStatus" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPropertyReleaseStatusList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPropertyReleaseStatusListUpdateInput>>;
};

export type ArchiveNestedMetadataPublisherListCreateInput = {
  /** Connect existing "MetadataPublisher" nodes to the new "Archive" node, through the "Archive.metadataPublisherList" relation. */
  connect?: InputMaybe<Array<MetadataPublisherWhereUniqueInput>>;
  /** Create new "MetadataPublisher" nodes and connect them to the new "Archive" node, through the "Archive.metadataPublisherList" relation. */
  create?: InputMaybe<Array<MetadataPublisherWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPublisherListUpdateInput = {
  /** Connect existing "MetadataPublisher" nodes to the existing "Archive" node, through the "Archive.metadataPublisherList" relation. */
  connect?: InputMaybe<Array<MetadataPublisherWhereUniqueInput>>;
  /** Create new "MetadataPublisher" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPublisherList" relation. */
  create?: InputMaybe<Array<MetadataPublisherWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPublisher" nodes from the "Archive.metadataPublisherList" relation. */
  delete?: InputMaybe<Array<MetadataPublisherWhereUniqueInput>>;
  /** Update existing "MetadataPublisher" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPublisherList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPublisherListUpdateInput>>;
  /** Upsert existing "MetadataPublisher" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPublisherList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPublisherListUpdateInput>>;
};

export type ArchiveNestedMetadataPublishingIssueListCreateInput = {
  /** Connect existing "MetadataPublishingIssue" nodes to the new "Archive" node, through the "Archive.metadataPublishingIssueList" relation. */
  connect?: InputMaybe<Array<MetadataPublishingIssueWhereUniqueInput>>;
  /** Create new "MetadataPublishingIssue" nodes and connect them to the new "Archive" node, through the "Archive.metadataPublishingIssueList" relation. */
  create?: InputMaybe<Array<MetadataPublishingIssueWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPublishingIssueListUpdateInput = {
  /** Connect existing "MetadataPublishingIssue" nodes to the existing "Archive" node, through the "Archive.metadataPublishingIssueList" relation. */
  connect?: InputMaybe<Array<MetadataPublishingIssueWhereUniqueInput>>;
  /** Create new "MetadataPublishingIssue" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPublishingIssueList" relation. */
  create?: InputMaybe<Array<MetadataPublishingIssueWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPublishingIssue" nodes from the "Archive.metadataPublishingIssueList" relation. */
  delete?: InputMaybe<Array<MetadataPublishingIssueWhereUniqueInput>>;
  /** Update existing "MetadataPublishingIssue" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPublishingIssueList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPublishingIssueListUpdateInput>>;
  /** Upsert existing "MetadataPublishingIssue" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPublishingIssueList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPublishingIssueListUpdateInput>>;
};

export type ArchiveNestedMetadataPublishingSubjectListCreateInput = {
  /** Connect existing "MetadataPublishingSubject" nodes to the new "Archive" node, through the "Archive.metadataPublishingSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataPublishingSubjectWhereUniqueInput>>;
  /** Create new "MetadataPublishingSubject" nodes and connect them to the new "Archive" node, through the "Archive.metadataPublishingSubjectList" relation. */
  create?: InputMaybe<Array<MetadataPublishingSubjectWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataPublishingSubjectListUpdateInput = {
  /** Connect existing "MetadataPublishingSubject" nodes to the existing "Archive" node, through the "Archive.metadataPublishingSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataPublishingSubjectWhereUniqueInput>>;
  /** Create new "MetadataPublishingSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPublishingSubjectList" relation. */
  create?: InputMaybe<Array<MetadataPublishingSubjectWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataPublishingSubject" nodes from the "Archive.metadataPublishingSubjectList" relation. */
  delete?: InputMaybe<Array<MetadataPublishingSubjectWhereUniqueInput>>;
  /** Update existing "MetadataPublishingSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPublishingSubjectList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataPublishingSubjectListUpdateInput>>;
  /** Upsert existing "MetadataPublishingSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataPublishingSubjectList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataPublishingSubjectListUpdateInput>>;
};

export type ArchiveNestedMetadataQualifiedUsePrismaByListCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaBy" nodes to the new "Archive" node, through the "Archive.metadataQualifiedUsePrismaByList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaByWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaBy" nodes and connect them to the new "Archive" node, through the "Archive.metadataQualifiedUsePrismaByList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaByWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataQualifiedUsePrismaByListUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaBy" nodes to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaByList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaByWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaBy" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaByList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaByWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaBy" nodes from the "Archive.metadataQualifiedUsePrismaByList" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaByWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaBy" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaByList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataQualifiedUsePrismaByListUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaBy" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaByList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataQualifiedUsePrismaByListUpdateInput>>;
};

export type ArchiveNestedMetadataQualifiedUsePrismaDateListCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDate" nodes to the new "Archive" node, through the "Archive.metadataQualifiedUsePrismaDateList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDate" nodes and connect them to the new "Archive" node, through the "Archive.metadataQualifiedUsePrismaDateList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataQualifiedUsePrismaDateListUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDate" nodes to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaDateList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaDateList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaDate" nodes from the "Archive.metadataQualifiedUsePrismaDateList" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaDate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaDateList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataQualifiedUsePrismaDateListUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaDate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaDateList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataQualifiedUsePrismaDateListUpdateInput>>;
};

export type ArchiveNestedMetadataQualifiedUsePrismaDurationListCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDuration" nodes to the new "Archive" node, through the "Archive.metadataQualifiedUsePrismaDurationList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDuration" nodes and connect them to the new "Archive" node, through the "Archive.metadataQualifiedUsePrismaDurationList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataQualifiedUsePrismaDurationListUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDuration" nodes to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaDurationList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDuration" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaDurationList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaDuration" nodes from the "Archive.metadataQualifiedUsePrismaDurationList" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaDuration" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaDurationList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataQualifiedUsePrismaDurationListUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaDuration" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaDurationList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataQualifiedUsePrismaDurationListUpdateInput>>;
};

export type ArchiveNestedMetadataQualifiedUsePrismaSupportListCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaSupport" nodes to the new "Archive" node, through the "Archive.metadataQualifiedUsePrismaSupportList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaSupport" nodes and connect them to the new "Archive" node, through the "Archive.metadataQualifiedUsePrismaSupportList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataQualifiedUsePrismaSupportListUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaSupport" nodes to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaSupportList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaSupport" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaSupportList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaSupport" nodes from the "Archive.metadataQualifiedUsePrismaSupportList" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaSupport" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaSupportList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataQualifiedUsePrismaSupportListUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaSupport" nodes and connect them to the existing "Archive" node, through the "Archive.metadataQualifiedUsePrismaSupportList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataQualifiedUsePrismaSupportListUpdateInput>>;
};

export type ArchiveNestedMetadataRatingListCreateInput = {
  /** Connect existing "MetadataRating" nodes to the new "Archive" node, through the "Archive.metadataRatingList" relation. */
  connect?: InputMaybe<Array<MetadataRatingWhereUniqueInput>>;
  /** Create new "MetadataRating" nodes and connect them to the new "Archive" node, through the "Archive.metadataRatingList" relation. */
  create?: InputMaybe<Array<MetadataRatingWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataRatingListUpdateInput = {
  /** Connect existing "MetadataRating" nodes to the existing "Archive" node, through the "Archive.metadataRatingList" relation. */
  connect?: InputMaybe<Array<MetadataRatingWhereUniqueInput>>;
  /** Create new "MetadataRating" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRatingList" relation. */
  create?: InputMaybe<Array<MetadataRatingWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataRating" nodes from the "Archive.metadataRatingList" relation. */
  delete?: InputMaybe<Array<MetadataRatingWhereUniqueInput>>;
  /** Update existing "MetadataRating" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRatingList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataRatingListUpdateInput>>;
  /** Upsert existing "MetadataRating" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRatingList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataRatingListUpdateInput>>;
};

export type ArchiveNestedMetadataReferenceDateListCreateInput = {
  /** Connect existing "MetadataReferenceDate" nodes to the new "Archive" node, through the "Archive.metadataReferenceDateList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceDateWhereUniqueInput>>;
  /** Create new "MetadataReferenceDate" nodes and connect them to the new "Archive" node, through the "Archive.metadataReferenceDateList" relation. */
  create?: InputMaybe<Array<MetadataReferenceDateWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataReferenceDateListUpdateInput = {
  /** Connect existing "MetadataReferenceDate" nodes to the existing "Archive" node, through the "Archive.metadataReferenceDateList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceDateWhereUniqueInput>>;
  /** Create new "MetadataReferenceDate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReferenceDateList" relation. */
  create?: InputMaybe<Array<MetadataReferenceDateWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataReferenceDate" nodes from the "Archive.metadataReferenceDateList" relation. */
  delete?: InputMaybe<Array<MetadataReferenceDateWhereUniqueInput>>;
  /** Update existing "MetadataReferenceDate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReferenceDateList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataReferenceDateListUpdateInput>>;
  /** Upsert existing "MetadataReferenceDate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReferenceDateList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataReferenceDateListUpdateInput>>;
};

export type ArchiveNestedMetadataReferenceNumberListCreateInput = {
  /** Connect existing "MetadataReferenceNumber" nodes to the new "Archive" node, through the "Archive.metadataReferenceNumberList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceNumberWhereUniqueInput>>;
  /** Create new "MetadataReferenceNumber" nodes and connect them to the new "Archive" node, through the "Archive.metadataReferenceNumberList" relation. */
  create?: InputMaybe<Array<MetadataReferenceNumberWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataReferenceNumberListUpdateInput = {
  /** Connect existing "MetadataReferenceNumber" nodes to the existing "Archive" node, through the "Archive.metadataReferenceNumberList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceNumberWhereUniqueInput>>;
  /** Create new "MetadataReferenceNumber" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReferenceNumberList" relation. */
  create?: InputMaybe<Array<MetadataReferenceNumberWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataReferenceNumber" nodes from the "Archive.metadataReferenceNumberList" relation. */
  delete?: InputMaybe<Array<MetadataReferenceNumberWhereUniqueInput>>;
  /** Update existing "MetadataReferenceNumber" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReferenceNumberList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataReferenceNumberListUpdateInput>>;
  /** Upsert existing "MetadataReferenceNumber" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReferenceNumberList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataReferenceNumberListUpdateInput>>;
};

export type ArchiveNestedMetadataReferenceServiceListCreateInput = {
  /** Connect existing "MetadataReferenceService" nodes to the new "Archive" node, through the "Archive.metadataReferenceServiceList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceServiceWhereUniqueInput>>;
  /** Create new "MetadataReferenceService" nodes and connect them to the new "Archive" node, through the "Archive.metadataReferenceServiceList" relation. */
  create?: InputMaybe<Array<MetadataReferenceServiceWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataReferenceServiceListUpdateInput = {
  /** Connect existing "MetadataReferenceService" nodes to the existing "Archive" node, through the "Archive.metadataReferenceServiceList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceServiceWhereUniqueInput>>;
  /** Create new "MetadataReferenceService" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReferenceServiceList" relation. */
  create?: InputMaybe<Array<MetadataReferenceServiceWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataReferenceService" nodes from the "Archive.metadataReferenceServiceList" relation. */
  delete?: InputMaybe<Array<MetadataReferenceServiceWhereUniqueInput>>;
  /** Update existing "MetadataReferenceService" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReferenceServiceList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataReferenceServiceListUpdateInput>>;
  /** Upsert existing "MetadataReferenceService" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReferenceServiceList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataReferenceServiceListUpdateInput>>;
};

export type ArchiveNestedMetadataReleaseDateListCreateInput = {
  /** Connect existing "MetadataReleaseDate" nodes to the new "Archive" node, through the "Archive.metadataReleaseDateList" relation. */
  connect?: InputMaybe<Array<MetadataReleaseDateWhereUniqueInput>>;
  /** Create new "MetadataReleaseDate" nodes and connect them to the new "Archive" node, through the "Archive.metadataReleaseDateList" relation. */
  create?: InputMaybe<Array<MetadataReleaseDateWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataReleaseDateListUpdateInput = {
  /** Connect existing "MetadataReleaseDate" nodes to the existing "Archive" node, through the "Archive.metadataReleaseDateList" relation. */
  connect?: InputMaybe<Array<MetadataReleaseDateWhereUniqueInput>>;
  /** Create new "MetadataReleaseDate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReleaseDateList" relation. */
  create?: InputMaybe<Array<MetadataReleaseDateWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataReleaseDate" nodes from the "Archive.metadataReleaseDateList" relation. */
  delete?: InputMaybe<Array<MetadataReleaseDateWhereUniqueInput>>;
  /** Update existing "MetadataReleaseDate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReleaseDateList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataReleaseDateListUpdateInput>>;
  /** Upsert existing "MetadataReleaseDate" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReleaseDateList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataReleaseDateListUpdateInput>>;
};

export type ArchiveNestedMetadataReleaseTimeListCreateInput = {
  /** Connect existing "MetadataReleaseTime" nodes to the new "Archive" node, through the "Archive.metadataReleaseTimeList" relation. */
  connect?: InputMaybe<Array<MetadataReleaseTimeWhereUniqueInput>>;
  /** Create new "MetadataReleaseTime" nodes and connect them to the new "Archive" node, through the "Archive.metadataReleaseTimeList" relation. */
  create?: InputMaybe<Array<MetadataReleaseTimeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataReleaseTimeListUpdateInput = {
  /** Connect existing "MetadataReleaseTime" nodes to the existing "Archive" node, through the "Archive.metadataReleaseTimeList" relation. */
  connect?: InputMaybe<Array<MetadataReleaseTimeWhereUniqueInput>>;
  /** Create new "MetadataReleaseTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReleaseTimeList" relation. */
  create?: InputMaybe<Array<MetadataReleaseTimeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataReleaseTime" nodes from the "Archive.metadataReleaseTimeList" relation. */
  delete?: InputMaybe<Array<MetadataReleaseTimeWhereUniqueInput>>;
  /** Update existing "MetadataReleaseTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReleaseTimeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataReleaseTimeListUpdateInput>>;
  /** Upsert existing "MetadataReleaseTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataReleaseTimeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataReleaseTimeListUpdateInput>>;
};

export type ArchiveNestedMetadataRequiredPermissionListCreateInput = {
  /** Connect existing "MetadataRequiredPermission" nodes to the new "Archive" node, through the "Archive.metadataRequiredPermissionList" relation. */
  connect?: InputMaybe<Array<MetadataRequiredPermissionWhereUniqueInput>>;
  /** Create new "MetadataRequiredPermission" nodes and connect them to the new "Archive" node, through the "Archive.metadataRequiredPermissionList" relation. */
  create?: InputMaybe<Array<MetadataRequiredPermissionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataRequiredPermissionListUpdateInput = {
  /** Connect existing "MetadataRequiredPermission" nodes to the existing "Archive" node, through the "Archive.metadataRequiredPermissionList" relation. */
  connect?: InputMaybe<Array<MetadataRequiredPermissionWhereUniqueInput>>;
  /** Create new "MetadataRequiredPermission" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRequiredPermissionList" relation. */
  create?: InputMaybe<Array<MetadataRequiredPermissionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataRequiredPermission" nodes from the "Archive.metadataRequiredPermissionList" relation. */
  delete?: InputMaybe<Array<MetadataRequiredPermissionWhereUniqueInput>>;
  /** Update existing "MetadataRequiredPermission" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRequiredPermissionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataRequiredPermissionListUpdateInput>>;
  /** Upsert existing "MetadataRequiredPermission" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRequiredPermissionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataRequiredPermissionListUpdateInput>>;
};

export type ArchiveNestedMetadataResolutionKindListCreateInput = {
  /** Connect existing "MetadataResolutionKind" nodes to the new "Archive" node, through the "Archive.metadataResolutionKindList" relation. */
  connect?: InputMaybe<Array<MetadataResolutionKindWhereUniqueInput>>;
  /** Create new "MetadataResolutionKind" nodes and connect them to the new "Archive" node, through the "Archive.metadataResolutionKindList" relation. */
  create?: InputMaybe<Array<MetadataResolutionKindWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataResolutionKindListUpdateInput = {
  /** Connect existing "MetadataResolutionKind" nodes to the existing "Archive" node, through the "Archive.metadataResolutionKindList" relation. */
  connect?: InputMaybe<Array<MetadataResolutionKindWhereUniqueInput>>;
  /** Create new "MetadataResolutionKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataResolutionKindList" relation. */
  create?: InputMaybe<Array<MetadataResolutionKindWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataResolutionKind" nodes from the "Archive.metadataResolutionKindList" relation. */
  delete?: InputMaybe<Array<MetadataResolutionKindWhereUniqueInput>>;
  /** Update existing "MetadataResolutionKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataResolutionKindList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataResolutionKindListUpdateInput>>;
  /** Upsert existing "MetadataResolutionKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataResolutionKindList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataResolutionKindListUpdateInput>>;
};

export type ArchiveNestedMetadataRightsListCreateInput = {
  /** Connect existing "MetadataRights" nodes to the new "Archive" node, through the "Archive.metadataRightsList" relation. */
  connect?: InputMaybe<Array<MetadataRightsWhereUniqueInput>>;
  /** Create new "MetadataRights" nodes and connect them to the new "Archive" node, through the "Archive.metadataRightsList" relation. */
  create?: InputMaybe<Array<MetadataRightsWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataRightsListUpdateInput = {
  /** Connect existing "MetadataRights" nodes to the existing "Archive" node, through the "Archive.metadataRightsList" relation. */
  connect?: InputMaybe<Array<MetadataRightsWhereUniqueInput>>;
  /** Create new "MetadataRights" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRightsList" relation. */
  create?: InputMaybe<Array<MetadataRightsWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataRights" nodes from the "Archive.metadataRightsList" relation. */
  delete?: InputMaybe<Array<MetadataRightsWhereUniqueInput>>;
  /** Update existing "MetadataRights" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRightsList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataRightsListUpdateInput>>;
  /** Upsert existing "MetadataRights" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRightsList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataRightsListUpdateInput>>;
};

export type ArchiveNestedMetadataRoyaltyfreeListCreateInput = {
  /** Connect existing "MetadataRoyaltyfree" nodes to the new "Archive" node, through the "Archive.metadataRoyaltyfreeList" relation. */
  connect?: InputMaybe<Array<MetadataRoyaltyfreeWhereUniqueInput>>;
  /** Create new "MetadataRoyaltyfree" nodes and connect them to the new "Archive" node, through the "Archive.metadataRoyaltyfreeList" relation. */
  create?: InputMaybe<Array<MetadataRoyaltyfreeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataRoyaltyfreeListUpdateInput = {
  /** Connect existing "MetadataRoyaltyfree" nodes to the existing "Archive" node, through the "Archive.metadataRoyaltyfreeList" relation. */
  connect?: InputMaybe<Array<MetadataRoyaltyfreeWhereUniqueInput>>;
  /** Create new "MetadataRoyaltyfree" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRoyaltyfreeList" relation. */
  create?: InputMaybe<Array<MetadataRoyaltyfreeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataRoyaltyfree" nodes from the "Archive.metadataRoyaltyfreeList" relation. */
  delete?: InputMaybe<Array<MetadataRoyaltyfreeWhereUniqueInput>>;
  /** Update existing "MetadataRoyaltyfree" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRoyaltyfreeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataRoyaltyfreeListUpdateInput>>;
  /** Upsert existing "MetadataRoyaltyfree" nodes and connect them to the existing "Archive" node, through the "Archive.metadataRoyaltyfreeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataRoyaltyfreeListUpdateInput>>;
};

export type ArchiveNestedMetadataSceneListCreateInput = {
  /** Connect existing "MetadataScene" nodes to the new "Archive" node, through the "Archive.metadataSceneList" relation. */
  connect?: InputMaybe<Array<MetadataSceneWhereUniqueInput>>;
  /** Create new "MetadataScene" nodes and connect them to the new "Archive" node, through the "Archive.metadataSceneList" relation. */
  create?: InputMaybe<Array<MetadataSceneWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSceneListUpdateInput = {
  /** Connect existing "MetadataScene" nodes to the existing "Archive" node, through the "Archive.metadataSceneList" relation. */
  connect?: InputMaybe<Array<MetadataSceneWhereUniqueInput>>;
  /** Create new "MetadataScene" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSceneList" relation. */
  create?: InputMaybe<Array<MetadataSceneWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataScene" nodes from the "Archive.metadataSceneList" relation. */
  delete?: InputMaybe<Array<MetadataSceneWhereUniqueInput>>;
  /** Update existing "MetadataScene" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSceneList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSceneListUpdateInput>>;
  /** Upsert existing "MetadataScene" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSceneList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSceneListUpdateInput>>;
};

export type ArchiveNestedMetadataSectionListCreateInput = {
  /** Connect existing "MetadataSection" nodes to the new "Archive" node, through the "Archive.metadataSectionList" relation. */
  connect?: InputMaybe<Array<MetadataSectionWhereUniqueInput>>;
  /** Create new "MetadataSection" nodes and connect them to the new "Archive" node, through the "Archive.metadataSectionList" relation. */
  create?: InputMaybe<Array<MetadataSectionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSectionListUpdateInput = {
  /** Connect existing "MetadataSection" nodes to the existing "Archive" node, through the "Archive.metadataSectionList" relation. */
  connect?: InputMaybe<Array<MetadataSectionWhereUniqueInput>>;
  /** Create new "MetadataSection" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSectionList" relation. */
  create?: InputMaybe<Array<MetadataSectionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSection" nodes from the "Archive.metadataSectionList" relation. */
  delete?: InputMaybe<Array<MetadataSectionWhereUniqueInput>>;
  /** Update existing "MetadataSection" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSectionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSectionListUpdateInput>>;
  /** Upsert existing "MetadataSection" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSectionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSectionListUpdateInput>>;
};

export type ArchiveNestedMetadataSentToListCreateInput = {
  /** Connect existing "MetadataSentTo" nodes to the new "Archive" node, through the "Archive.metadataSentToList" relation. */
  connect?: InputMaybe<Array<MetadataSentToWhereUniqueInput>>;
  /** Create new "MetadataSentTo" nodes and connect them to the new "Archive" node, through the "Archive.metadataSentToList" relation. */
  create?: InputMaybe<Array<MetadataSentToWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSentToListUpdateInput = {
  /** Connect existing "MetadataSentTo" nodes to the existing "Archive" node, through the "Archive.metadataSentToList" relation. */
  connect?: InputMaybe<Array<MetadataSentToWhereUniqueInput>>;
  /** Create new "MetadataSentTo" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSentToList" relation. */
  create?: InputMaybe<Array<MetadataSentToWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSentTo" nodes from the "Archive.metadataSentToList" relation. */
  delete?: InputMaybe<Array<MetadataSentToWhereUniqueInput>>;
  /** Update existing "MetadataSentTo" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSentToList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSentToListUpdateInput>>;
  /** Upsert existing "MetadataSentTo" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSentToList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSentToListUpdateInput>>;
};

export type ArchiveNestedMetadataSerialNumberListCreateInput = {
  /** Connect existing "MetadataSerialNumber" nodes to the new "Archive" node, through the "Archive.metadataSerialNumberList" relation. */
  connect?: InputMaybe<Array<MetadataSerialNumberWhereUniqueInput>>;
  /** Create new "MetadataSerialNumber" nodes and connect them to the new "Archive" node, through the "Archive.metadataSerialNumberList" relation. */
  create?: InputMaybe<Array<MetadataSerialNumberWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSerialNumberListUpdateInput = {
  /** Connect existing "MetadataSerialNumber" nodes to the existing "Archive" node, through the "Archive.metadataSerialNumberList" relation. */
  connect?: InputMaybe<Array<MetadataSerialNumberWhereUniqueInput>>;
  /** Create new "MetadataSerialNumber" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSerialNumberList" relation. */
  create?: InputMaybe<Array<MetadataSerialNumberWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSerialNumber" nodes from the "Archive.metadataSerialNumberList" relation. */
  delete?: InputMaybe<Array<MetadataSerialNumberWhereUniqueInput>>;
  /** Update existing "MetadataSerialNumber" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSerialNumberList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSerialNumberListUpdateInput>>;
  /** Upsert existing "MetadataSerialNumber" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSerialNumberList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSerialNumberListUpdateInput>>;
};

export type ArchiveNestedMetadataSeriesDateTimeListCreateInput = {
  /** Connect existing "MetadataSeriesDateTime" nodes to the new "Archive" node, through the "Archive.metadataSeriesDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDateTimeWhereUniqueInput>>;
  /** Create new "MetadataSeriesDateTime" nodes and connect them to the new "Archive" node, through the "Archive.metadataSeriesDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataSeriesDateTimeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSeriesDateTimeListUpdateInput = {
  /** Connect existing "MetadataSeriesDateTime" nodes to the existing "Archive" node, through the "Archive.metadataSeriesDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDateTimeWhereUniqueInput>>;
  /** Create new "MetadataSeriesDateTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataSeriesDateTimeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSeriesDateTime" nodes from the "Archive.metadataSeriesDateTimeList" relation. */
  delete?: InputMaybe<Array<MetadataSeriesDateTimeWhereUniqueInput>>;
  /** Update existing "MetadataSeriesDateTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesDateTimeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSeriesDateTimeListUpdateInput>>;
  /** Upsert existing "MetadataSeriesDateTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesDateTimeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSeriesDateTimeListUpdateInput>>;
};

export type ArchiveNestedMetadataSeriesDescriptionListCreateInput = {
  /** Connect existing "MetadataSeriesDescription" nodes to the new "Archive" node, through the "Archive.metadataSeriesDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDescriptionWhereUniqueInput>>;
  /** Create new "MetadataSeriesDescription" nodes and connect them to the new "Archive" node, through the "Archive.metadataSeriesDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataSeriesDescriptionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSeriesDescriptionListUpdateInput = {
  /** Connect existing "MetadataSeriesDescription" nodes to the existing "Archive" node, through the "Archive.metadataSeriesDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDescriptionWhereUniqueInput>>;
  /** Create new "MetadataSeriesDescription" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataSeriesDescriptionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSeriesDescription" nodes from the "Archive.metadataSeriesDescriptionList" relation. */
  delete?: InputMaybe<Array<MetadataSeriesDescriptionWhereUniqueInput>>;
  /** Update existing "MetadataSeriesDescription" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesDescriptionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSeriesDescriptionListUpdateInput>>;
  /** Upsert existing "MetadataSeriesDescription" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesDescriptionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSeriesDescriptionListUpdateInput>>;
};

export type ArchiveNestedMetadataSeriesModalityListCreateInput = {
  /** Connect existing "MetadataSeriesModality" nodes to the new "Archive" node, through the "Archive.metadataSeriesModalityList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesModalityWhereUniqueInput>>;
  /** Create new "MetadataSeriesModality" nodes and connect them to the new "Archive" node, through the "Archive.metadataSeriesModalityList" relation. */
  create?: InputMaybe<Array<MetadataSeriesModalityWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSeriesModalityListUpdateInput = {
  /** Connect existing "MetadataSeriesModality" nodes to the existing "Archive" node, through the "Archive.metadataSeriesModalityList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesModalityWhereUniqueInput>>;
  /** Create new "MetadataSeriesModality" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesModalityList" relation. */
  create?: InputMaybe<Array<MetadataSeriesModalityWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSeriesModality" nodes from the "Archive.metadataSeriesModalityList" relation. */
  delete?: InputMaybe<Array<MetadataSeriesModalityWhereUniqueInput>>;
  /** Update existing "MetadataSeriesModality" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesModalityList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSeriesModalityListUpdateInput>>;
  /** Upsert existing "MetadataSeriesModality" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesModalityList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSeriesModalityListUpdateInput>>;
};

export type ArchiveNestedMetadataSeriesNumberListCreateInput = {
  /** Connect existing "MetadataSeriesNumber" nodes to the new "Archive" node, through the "Archive.metadataSeriesNumberList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesNumberWhereUniqueInput>>;
  /** Create new "MetadataSeriesNumber" nodes and connect them to the new "Archive" node, through the "Archive.metadataSeriesNumberList" relation. */
  create?: InputMaybe<Array<MetadataSeriesNumberWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSeriesNumberListUpdateInput = {
  /** Connect existing "MetadataSeriesNumber" nodes to the existing "Archive" node, through the "Archive.metadataSeriesNumberList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesNumberWhereUniqueInput>>;
  /** Create new "MetadataSeriesNumber" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesNumberList" relation. */
  create?: InputMaybe<Array<MetadataSeriesNumberWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSeriesNumber" nodes from the "Archive.metadataSeriesNumberList" relation. */
  delete?: InputMaybe<Array<MetadataSeriesNumberWhereUniqueInput>>;
  /** Update existing "MetadataSeriesNumber" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesNumberList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSeriesNumberListUpdateInput>>;
  /** Upsert existing "MetadataSeriesNumber" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSeriesNumberList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSeriesNumberListUpdateInput>>;
};

export type ArchiveNestedMetadataShortUniqueIdListCreateInput = {
  /** Connect existing "MetadataShortUniqueId" nodes to the new "Archive" node, through the "Archive.metadataShortUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataShortUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataShortUniqueId" nodes and connect them to the new "Archive" node, through the "Archive.metadataShortUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataShortUniqueIdWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataShortUniqueIdListUpdateInput = {
  /** Connect existing "MetadataShortUniqueId" nodes to the existing "Archive" node, through the "Archive.metadataShortUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataShortUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataShortUniqueId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataShortUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataShortUniqueIdWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataShortUniqueId" nodes from the "Archive.metadataShortUniqueIdList" relation. */
  delete?: InputMaybe<Array<MetadataShortUniqueIdWhereUniqueInput>>;
  /** Update existing "MetadataShortUniqueId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataShortUniqueIdList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataShortUniqueIdListUpdateInput>>;
  /** Upsert existing "MetadataShortUniqueId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataShortUniqueIdList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataShortUniqueIdListUpdateInput>>;
};

export type ArchiveNestedMetadataSourceListCreateInput = {
  /** Connect existing "MetadataSource" nodes to the new "Archive" node, through the "Archive.metadataSourceList" relation. */
  connect?: InputMaybe<Array<MetadataSourceWhereUniqueInput>>;
  /** Create new "MetadataSource" nodes and connect them to the new "Archive" node, through the "Archive.metadataSourceList" relation. */
  create?: InputMaybe<Array<MetadataSourceWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSourceListUpdateInput = {
  /** Connect existing "MetadataSource" nodes to the existing "Archive" node, through the "Archive.metadataSourceList" relation. */
  connect?: InputMaybe<Array<MetadataSourceWhereUniqueInput>>;
  /** Create new "MetadataSource" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSourceList" relation. */
  create?: InputMaybe<Array<MetadataSourceWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSource" nodes from the "Archive.metadataSourceList" relation. */
  delete?: InputMaybe<Array<MetadataSourceWhereUniqueInput>>;
  /** Update existing "MetadataSource" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSourceList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSourceListUpdateInput>>;
  /** Upsert existing "MetadataSource" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSourceList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSourceListUpdateInput>>;
};

export type ArchiveNestedMetadataStateListCreateInput = {
  /** Connect existing "MetadataState" nodes to the new "Archive" node, through the "Archive.metadataStateList" relation. */
  connect?: InputMaybe<Array<MetadataStateWhereUniqueInput>>;
  /** Create new "MetadataState" nodes and connect them to the new "Archive" node, through the "Archive.metadataStateList" relation. */
  create?: InputMaybe<Array<MetadataStateWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataStateListUpdateInput = {
  /** Connect existing "MetadataState" nodes to the existing "Archive" node, through the "Archive.metadataStateList" relation. */
  connect?: InputMaybe<Array<MetadataStateWhereUniqueInput>>;
  /** Create new "MetadataState" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStateList" relation. */
  create?: InputMaybe<Array<MetadataStateWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataState" nodes from the "Archive.metadataStateList" relation. */
  delete?: InputMaybe<Array<MetadataStateWhereUniqueInput>>;
  /** Update existing "MetadataState" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStateList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataStateListUpdateInput>>;
  /** Upsert existing "MetadataState" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStateList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataStateListUpdateInput>>;
};

export type ArchiveNestedMetadataStudyDateTimeListCreateInput = {
  /** Connect existing "MetadataStudyDateTime" nodes to the new "Archive" node, through the "Archive.metadataStudyDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataStudyDateTimeWhereUniqueInput>>;
  /** Create new "MetadataStudyDateTime" nodes and connect them to the new "Archive" node, through the "Archive.metadataStudyDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataStudyDateTimeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataStudyDateTimeListUpdateInput = {
  /** Connect existing "MetadataStudyDateTime" nodes to the existing "Archive" node, through the "Archive.metadataStudyDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataStudyDateTimeWhereUniqueInput>>;
  /** Create new "MetadataStudyDateTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataStudyDateTimeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataStudyDateTime" nodes from the "Archive.metadataStudyDateTimeList" relation. */
  delete?: InputMaybe<Array<MetadataStudyDateTimeWhereUniqueInput>>;
  /** Update existing "MetadataStudyDateTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyDateTimeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataStudyDateTimeListUpdateInput>>;
  /** Upsert existing "MetadataStudyDateTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyDateTimeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataStudyDateTimeListUpdateInput>>;
};

export type ArchiveNestedMetadataStudyDescriptionListCreateInput = {
  /** Connect existing "MetadataStudyDescription" nodes to the new "Archive" node, through the "Archive.metadataStudyDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataStudyDescriptionWhereUniqueInput>>;
  /** Create new "MetadataStudyDescription" nodes and connect them to the new "Archive" node, through the "Archive.metadataStudyDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataStudyDescriptionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataStudyDescriptionListUpdateInput = {
  /** Connect existing "MetadataStudyDescription" nodes to the existing "Archive" node, through the "Archive.metadataStudyDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataStudyDescriptionWhereUniqueInput>>;
  /** Create new "MetadataStudyDescription" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataStudyDescriptionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataStudyDescription" nodes from the "Archive.metadataStudyDescriptionList" relation. */
  delete?: InputMaybe<Array<MetadataStudyDescriptionWhereUniqueInput>>;
  /** Update existing "MetadataStudyDescription" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyDescriptionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataStudyDescriptionListUpdateInput>>;
  /** Upsert existing "MetadataStudyDescription" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyDescriptionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataStudyDescriptionListUpdateInput>>;
};

export type ArchiveNestedMetadataStudyIdListCreateInput = {
  /** Connect existing "MetadataStudyId" nodes to the new "Archive" node, through the "Archive.metadataStudyIdList" relation. */
  connect?: InputMaybe<Array<MetadataStudyIdWhereUniqueInput>>;
  /** Create new "MetadataStudyId" nodes and connect them to the new "Archive" node, through the "Archive.metadataStudyIdList" relation. */
  create?: InputMaybe<Array<MetadataStudyIdWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataStudyIdListUpdateInput = {
  /** Connect existing "MetadataStudyId" nodes to the existing "Archive" node, through the "Archive.metadataStudyIdList" relation. */
  connect?: InputMaybe<Array<MetadataStudyIdWhereUniqueInput>>;
  /** Create new "MetadataStudyId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyIdList" relation. */
  create?: InputMaybe<Array<MetadataStudyIdWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataStudyId" nodes from the "Archive.metadataStudyIdList" relation. */
  delete?: InputMaybe<Array<MetadataStudyIdWhereUniqueInput>>;
  /** Update existing "MetadataStudyId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyIdList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataStudyIdListUpdateInput>>;
  /** Upsert existing "MetadataStudyId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyIdList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataStudyIdListUpdateInput>>;
};

export type ArchiveNestedMetadataStudyPhysicianListCreateInput = {
  /** Connect existing "MetadataStudyPhysician" nodes to the new "Archive" node, through the "Archive.metadataStudyPhysicianList" relation. */
  connect?: InputMaybe<Array<MetadataStudyPhysicianWhereUniqueInput>>;
  /** Create new "MetadataStudyPhysician" nodes and connect them to the new "Archive" node, through the "Archive.metadataStudyPhysicianList" relation. */
  create?: InputMaybe<Array<MetadataStudyPhysicianWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataStudyPhysicianListUpdateInput = {
  /** Connect existing "MetadataStudyPhysician" nodes to the existing "Archive" node, through the "Archive.metadataStudyPhysicianList" relation. */
  connect?: InputMaybe<Array<MetadataStudyPhysicianWhereUniqueInput>>;
  /** Create new "MetadataStudyPhysician" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyPhysicianList" relation. */
  create?: InputMaybe<Array<MetadataStudyPhysicianWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataStudyPhysician" nodes from the "Archive.metadataStudyPhysicianList" relation. */
  delete?: InputMaybe<Array<MetadataStudyPhysicianWhereUniqueInput>>;
  /** Update existing "MetadataStudyPhysician" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyPhysicianList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataStudyPhysicianListUpdateInput>>;
  /** Upsert existing "MetadataStudyPhysician" nodes and connect them to the existing "Archive" node, through the "Archive.metadataStudyPhysicianList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataStudyPhysicianListUpdateInput>>;
};

export type ArchiveNestedMetadataSubjectCodeListCreateInput = {
  /** Connect existing "MetadataSubjectCode" nodes to the new "Archive" node, through the "Archive.metadataSubjectCodeList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectCodeWhereUniqueInput>>;
  /** Create new "MetadataSubjectCode" nodes and connect them to the new "Archive" node, through the "Archive.metadataSubjectCodeList" relation. */
  create?: InputMaybe<Array<MetadataSubjectCodeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSubjectCodeListUpdateInput = {
  /** Connect existing "MetadataSubjectCode" nodes to the existing "Archive" node, through the "Archive.metadataSubjectCodeList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectCodeWhereUniqueInput>>;
  /** Create new "MetadataSubjectCode" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSubjectCodeList" relation. */
  create?: InputMaybe<Array<MetadataSubjectCodeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSubjectCode" nodes from the "Archive.metadataSubjectCodeList" relation. */
  delete?: InputMaybe<Array<MetadataSubjectCodeWhereUniqueInput>>;
  /** Update existing "MetadataSubjectCode" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSubjectCodeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSubjectCodeListUpdateInput>>;
  /** Upsert existing "MetadataSubjectCode" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSubjectCodeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSubjectCodeListUpdateInput>>;
};

export type ArchiveNestedMetadataSubjectListCreateInput = {
  /** Connect existing "MetadataSubject" nodes to the new "Archive" node, through the "Archive.metadataSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectWhereUniqueInput>>;
  /** Create new "MetadataSubject" nodes and connect them to the new "Archive" node, through the "Archive.metadataSubjectList" relation. */
  create?: InputMaybe<Array<MetadataSubjectWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSubjectListUpdateInput = {
  /** Connect existing "MetadataSubject" nodes to the existing "Archive" node, through the "Archive.metadataSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectWhereUniqueInput>>;
  /** Create new "MetadataSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSubjectList" relation. */
  create?: InputMaybe<Array<MetadataSubjectWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSubject" nodes from the "Archive.metadataSubjectList" relation. */
  delete?: InputMaybe<Array<MetadataSubjectWhereUniqueInput>>;
  /** Update existing "MetadataSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSubjectList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSubjectListUpdateInput>>;
  /** Upsert existing "MetadataSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSubjectList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSubjectListUpdateInput>>;
};

export type ArchiveNestedMetadataSubjectPrismaListCreateInput = {
  /** Connect existing "MetadataSubjectPrisma" nodes to the new "Archive" node, through the "Archive.metadataSubjectPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectPrismaWhereUniqueInput>>;
  /** Create new "MetadataSubjectPrisma" nodes and connect them to the new "Archive" node, through the "Archive.metadataSubjectPrismaList" relation. */
  create?: InputMaybe<Array<MetadataSubjectPrismaWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSubjectPrismaListUpdateInput = {
  /** Connect existing "MetadataSubjectPrisma" nodes to the existing "Archive" node, through the "Archive.metadataSubjectPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectPrismaWhereUniqueInput>>;
  /** Create new "MetadataSubjectPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSubjectPrismaList" relation. */
  create?: InputMaybe<Array<MetadataSubjectPrismaWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSubjectPrisma" nodes from the "Archive.metadataSubjectPrismaList" relation. */
  delete?: InputMaybe<Array<MetadataSubjectPrismaWhereUniqueInput>>;
  /** Update existing "MetadataSubjectPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSubjectPrismaList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSubjectPrismaListUpdateInput>>;
  /** Upsert existing "MetadataSubjectPrisma" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSubjectPrismaList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSubjectPrismaListUpdateInput>>;
};

export type ArchiveNestedMetadataSupplementalCategoriesListCreateInput = {
  /** Connect existing "MetadataSupplementalCategories" nodes to the new "Archive" node, through the "Archive.metadataSupplementalCategoriesList" relation. */
  connect?: InputMaybe<Array<MetadataSupplementalCategoriesWhereUniqueInput>>;
  /** Create new "MetadataSupplementalCategories" nodes and connect them to the new "Archive" node, through the "Archive.metadataSupplementalCategoriesList" relation. */
  create?: InputMaybe<Array<MetadataSupplementalCategoriesWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataSupplementalCategoriesListUpdateInput = {
  /** Connect existing "MetadataSupplementalCategories" nodes to the existing "Archive" node, through the "Archive.metadataSupplementalCategoriesList" relation. */
  connect?: InputMaybe<Array<MetadataSupplementalCategoriesWhereUniqueInput>>;
  /** Create new "MetadataSupplementalCategories" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSupplementalCategoriesList" relation. */
  create?: InputMaybe<Array<MetadataSupplementalCategoriesWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataSupplementalCategories" nodes from the "Archive.metadataSupplementalCategoriesList" relation. */
  delete?: InputMaybe<Array<MetadataSupplementalCategoriesWhereUniqueInput>>;
  /** Update existing "MetadataSupplementalCategories" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSupplementalCategoriesList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataSupplementalCategoriesListUpdateInput>>;
  /** Upsert existing "MetadataSupplementalCategories" nodes and connect them to the existing "Archive" node, through the "Archive.metadataSupplementalCategoriesList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataSupplementalCategoriesListUpdateInput>>;
};

export type ArchiveNestedMetadataTargetVersionListCreateInput = {
  /** Connect existing "MetadataTargetVersion" nodes to the new "Archive" node, through the "Archive.metadataTargetVersionList" relation. */
  connect?: InputMaybe<Array<MetadataTargetVersionWhereUniqueInput>>;
  /** Create new "MetadataTargetVersion" nodes and connect them to the new "Archive" node, through the "Archive.metadataTargetVersionList" relation. */
  create?: InputMaybe<Array<MetadataTargetVersionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataTargetVersionListUpdateInput = {
  /** Connect existing "MetadataTargetVersion" nodes to the existing "Archive" node, through the "Archive.metadataTargetVersionList" relation. */
  connect?: InputMaybe<Array<MetadataTargetVersionWhereUniqueInput>>;
  /** Create new "MetadataTargetVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTargetVersionList" relation. */
  create?: InputMaybe<Array<MetadataTargetVersionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataTargetVersion" nodes from the "Archive.metadataTargetVersionList" relation. */
  delete?: InputMaybe<Array<MetadataTargetVersionWhereUniqueInput>>;
  /** Update existing "MetadataTargetVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTargetVersionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataTargetVersionListUpdateInput>>;
  /** Upsert existing "MetadataTargetVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTargetVersionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataTargetVersionListUpdateInput>>;
};

export type ArchiveNestedMetadataTitleListCreateInput = {
  /** Connect existing "MetadataTitle" nodes to the new "Archive" node, through the "Archive.metadataTitleList" relation. */
  connect?: InputMaybe<Array<MetadataTitleWhereUniqueInput>>;
  /** Create new "MetadataTitle" nodes and connect them to the new "Archive" node, through the "Archive.metadataTitleList" relation. */
  create?: InputMaybe<Array<MetadataTitleWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataTitleListUpdateInput = {
  /** Connect existing "MetadataTitle" nodes to the existing "Archive" node, through the "Archive.metadataTitleList" relation. */
  connect?: InputMaybe<Array<MetadataTitleWhereUniqueInput>>;
  /** Create new "MetadataTitle" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTitleList" relation. */
  create?: InputMaybe<Array<MetadataTitleWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataTitle" nodes from the "Archive.metadataTitleList" relation. */
  delete?: InputMaybe<Array<MetadataTitleWhereUniqueInput>>;
  /** Update existing "MetadataTitle" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTitleList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataTitleListUpdateInput>>;
  /** Upsert existing "MetadataTitle" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTitleList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataTitleListUpdateInput>>;
};

export type ArchiveNestedMetadataToneCurveListCreateInput = {
  /** Connect existing "MetadataToneCurve" nodes to the new "Archive" node, through the "Archive.metadataToneCurveList" relation. */
  connect?: InputMaybe<Array<MetadataToneCurveWhereUniqueInput>>;
  /** Create new "MetadataToneCurve" nodes and connect them to the new "Archive" node, through the "Archive.metadataToneCurveList" relation. */
  create?: InputMaybe<Array<MetadataToneCurveWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataToneCurveListUpdateInput = {
  /** Connect existing "MetadataToneCurve" nodes to the existing "Archive" node, through the "Archive.metadataToneCurveList" relation. */
  connect?: InputMaybe<Array<MetadataToneCurveWhereUniqueInput>>;
  /** Create new "MetadataToneCurve" nodes and connect them to the existing "Archive" node, through the "Archive.metadataToneCurveList" relation. */
  create?: InputMaybe<Array<MetadataToneCurveWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataToneCurve" nodes from the "Archive.metadataToneCurveList" relation. */
  delete?: InputMaybe<Array<MetadataToneCurveWhereUniqueInput>>;
  /** Update existing "MetadataToneCurve" nodes and connect them to the existing "Archive" node, through the "Archive.metadataToneCurveList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataToneCurveListUpdateInput>>;
  /** Upsert existing "MetadataToneCurve" nodes and connect them to the existing "Archive" node, through the "Archive.metadataToneCurveList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataToneCurveListUpdateInput>>;
};

export type ArchiveNestedMetadataTransferredByEmailListCreateInput = {
  /** Connect existing "MetadataTransferredByEmail" nodes to the new "Archive" node, through the "Archive.metadataTransferredByEmailList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByEmailWhereUniqueInput>>;
  /** Create new "MetadataTransferredByEmail" nodes and connect them to the new "Archive" node, through the "Archive.metadataTransferredByEmailList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByEmailWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataTransferredByEmailListUpdateInput = {
  /** Connect existing "MetadataTransferredByEmail" nodes to the existing "Archive" node, through the "Archive.metadataTransferredByEmailList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByEmailWhereUniqueInput>>;
  /** Create new "MetadataTransferredByEmail" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransferredByEmailList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByEmailWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataTransferredByEmail" nodes from the "Archive.metadataTransferredByEmailList" relation. */
  delete?: InputMaybe<Array<MetadataTransferredByEmailWhereUniqueInput>>;
  /** Update existing "MetadataTransferredByEmail" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransferredByEmailList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataTransferredByEmailListUpdateInput>>;
  /** Upsert existing "MetadataTransferredByEmail" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransferredByEmailList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataTransferredByEmailListUpdateInput>>;
};

export type ArchiveNestedMetadataTransferredByFullNameListCreateInput = {
  /** Connect existing "MetadataTransferredByFullName" nodes to the new "Archive" node, through the "Archive.metadataTransferredByFullNameList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByFullNameWhereUniqueInput>>;
  /** Create new "MetadataTransferredByFullName" nodes and connect them to the new "Archive" node, through the "Archive.metadataTransferredByFullNameList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByFullNameWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataTransferredByFullNameListUpdateInput = {
  /** Connect existing "MetadataTransferredByFullName" nodes to the existing "Archive" node, through the "Archive.metadataTransferredByFullNameList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByFullNameWhereUniqueInput>>;
  /** Create new "MetadataTransferredByFullName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransferredByFullNameList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByFullNameWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataTransferredByFullName" nodes from the "Archive.metadataTransferredByFullNameList" relation. */
  delete?: InputMaybe<Array<MetadataTransferredByFullNameWhereUniqueInput>>;
  /** Update existing "MetadataTransferredByFullName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransferredByFullNameList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataTransferredByFullNameListUpdateInput>>;
  /** Upsert existing "MetadataTransferredByFullName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransferredByFullNameList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataTransferredByFullNameListUpdateInput>>;
};

export type ArchiveNestedMetadataTransferredByListCreateInput = {
  /** Connect existing "MetadataTransferredBy" nodes to the new "Archive" node, through the "Archive.metadataTransferredByList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByWhereUniqueInput>>;
  /** Create new "MetadataTransferredBy" nodes and connect them to the new "Archive" node, through the "Archive.metadataTransferredByList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataTransferredByListUpdateInput = {
  /** Connect existing "MetadataTransferredBy" nodes to the existing "Archive" node, through the "Archive.metadataTransferredByList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByWhereUniqueInput>>;
  /** Create new "MetadataTransferredBy" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransferredByList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataTransferredBy" nodes from the "Archive.metadataTransferredByList" relation. */
  delete?: InputMaybe<Array<MetadataTransferredByWhereUniqueInput>>;
  /** Update existing "MetadataTransferredBy" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransferredByList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataTransferredByListUpdateInput>>;
  /** Upsert existing "MetadataTransferredBy" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransferredByList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataTransferredByListUpdateInput>>;
};

export type ArchiveNestedMetadataTransmissionReferenceListCreateInput = {
  /** Connect existing "MetadataTransmissionReference" nodes to the new "Archive" node, through the "Archive.metadataTransmissionReferenceList" relation. */
  connect?: InputMaybe<Array<MetadataTransmissionReferenceWhereUniqueInput>>;
  /** Create new "MetadataTransmissionReference" nodes and connect them to the new "Archive" node, through the "Archive.metadataTransmissionReferenceList" relation. */
  create?: InputMaybe<Array<MetadataTransmissionReferenceWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataTransmissionReferenceListUpdateInput = {
  /** Connect existing "MetadataTransmissionReference" nodes to the existing "Archive" node, through the "Archive.metadataTransmissionReferenceList" relation. */
  connect?: InputMaybe<Array<MetadataTransmissionReferenceWhereUniqueInput>>;
  /** Create new "MetadataTransmissionReference" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransmissionReferenceList" relation. */
  create?: InputMaybe<Array<MetadataTransmissionReferenceWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataTransmissionReference" nodes from the "Archive.metadataTransmissionReferenceList" relation. */
  delete?: InputMaybe<Array<MetadataTransmissionReferenceWhereUniqueInput>>;
  /** Update existing "MetadataTransmissionReference" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransmissionReferenceList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataTransmissionReferenceListUpdateInput>>;
  /** Upsert existing "MetadataTransmissionReference" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTransmissionReferenceList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataTransmissionReferenceListUpdateInput>>;
};

export type ArchiveNestedMetadataTypeListCreateInput = {
  /** Connect existing "MetadataType" nodes to the new "Archive" node, through the "Archive.metadataTypeList" relation. */
  connect?: InputMaybe<Array<MetadataTypeWhereUniqueInput>>;
  /** Create new "MetadataType" nodes and connect them to the new "Archive" node, through the "Archive.metadataTypeList" relation. */
  create?: InputMaybe<Array<MetadataTypeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataTypeListUpdateInput = {
  /** Connect existing "MetadataType" nodes to the existing "Archive" node, through the "Archive.metadataTypeList" relation. */
  connect?: InputMaybe<Array<MetadataTypeWhereUniqueInput>>;
  /** Create new "MetadataType" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTypeList" relation. */
  create?: InputMaybe<Array<MetadataTypeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataType" nodes from the "Archive.metadataTypeList" relation. */
  delete?: InputMaybe<Array<MetadataTypeWhereUniqueInput>>;
  /** Update existing "MetadataType" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTypeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataTypeListUpdateInput>>;
  /** Upsert existing "MetadataType" nodes and connect them to the existing "Archive" node, through the "Archive.metadataTypeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataTypeListUpdateInput>>;
};

export type ArchiveNestedMetadataUndersubjectListCreateInput = {
  /** Connect existing "MetadataUndersubject" nodes to the new "Archive" node, through the "Archive.metadataUndersubjectList" relation. */
  connect?: InputMaybe<Array<MetadataUndersubjectWhereUniqueInput>>;
  /** Create new "MetadataUndersubject" nodes and connect them to the new "Archive" node, through the "Archive.metadataUndersubjectList" relation. */
  create?: InputMaybe<Array<MetadataUndersubjectWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUndersubjectListUpdateInput = {
  /** Connect existing "MetadataUndersubject" nodes to the existing "Archive" node, through the "Archive.metadataUndersubjectList" relation. */
  connect?: InputMaybe<Array<MetadataUndersubjectWhereUniqueInput>>;
  /** Create new "MetadataUndersubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUndersubjectList" relation. */
  create?: InputMaybe<Array<MetadataUndersubjectWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUndersubject" nodes from the "Archive.metadataUndersubjectList" relation. */
  delete?: InputMaybe<Array<MetadataUndersubjectWhereUniqueInput>>;
  /** Update existing "MetadataUndersubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUndersubjectList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUndersubjectListUpdateInput>>;
  /** Upsert existing "MetadataUndersubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUndersubjectList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUndersubjectListUpdateInput>>;
};

export type ArchiveNestedMetadataUnderUnderSubjectListCreateInput = {
  /** Connect existing "MetadataUnderUnderSubject" nodes to the new "Archive" node, through the "Archive.metadataUnderUnderSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataUnderUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataUnderUnderSubject" nodes and connect them to the new "Archive" node, through the "Archive.metadataUnderUnderSubjectList" relation. */
  create?: InputMaybe<Array<MetadataUnderUnderSubjectWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUnderUnderSubjectListUpdateInput = {
  /** Connect existing "MetadataUnderUnderSubject" nodes to the existing "Archive" node, through the "Archive.metadataUnderUnderSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataUnderUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataUnderUnderSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUnderUnderSubjectList" relation. */
  create?: InputMaybe<Array<MetadataUnderUnderSubjectWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUnderUnderSubject" nodes from the "Archive.metadataUnderUnderSubjectList" relation. */
  delete?: InputMaybe<Array<MetadataUnderUnderSubjectWhereUniqueInput>>;
  /** Update existing "MetadataUnderUnderSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUnderUnderSubjectList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUnderUnderSubjectListUpdateInput>>;
  /** Upsert existing "MetadataUnderUnderSubject" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUnderUnderSubjectList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUnderUnderSubjectListUpdateInput>>;
};

export type ArchiveNestedMetadataUniqueIdListCreateInput = {
  /** Connect existing "MetadataUniqueId" nodes to the new "Archive" node, through the "Archive.metadataUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataUniqueId" nodes and connect them to the new "Archive" node, through the "Archive.metadataUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataUniqueIdWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUniqueIdListUpdateInput = {
  /** Connect existing "MetadataUniqueId" nodes to the existing "Archive" node, through the "Archive.metadataUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataUniqueId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataUniqueIdWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUniqueId" nodes from the "Archive.metadataUniqueIdList" relation. */
  delete?: InputMaybe<Array<MetadataUniqueIdWhereUniqueInput>>;
  /** Update existing "MetadataUniqueId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUniqueIdList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUniqueIdListUpdateInput>>;
  /** Upsert existing "MetadataUniqueId" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUniqueIdList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUniqueIdListUpdateInput>>;
};

export type ArchiveNestedMetadataUnitListCreateInput = {
  /** Connect existing "MetadataUnit" nodes to the new "Archive" node, through the "Archive.metadataUnitList" relation. */
  connect?: InputMaybe<Array<MetadataUnitWhereUniqueInput>>;
  /** Create new "MetadataUnit" nodes and connect them to the new "Archive" node, through the "Archive.metadataUnitList" relation. */
  create?: InputMaybe<Array<MetadataUnitWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUnitListUpdateInput = {
  /** Connect existing "MetadataUnit" nodes to the existing "Archive" node, through the "Archive.metadataUnitList" relation. */
  connect?: InputMaybe<Array<MetadataUnitWhereUniqueInput>>;
  /** Create new "MetadataUnit" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUnitList" relation. */
  create?: InputMaybe<Array<MetadataUnitWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUnit" nodes from the "Archive.metadataUnitList" relation. */
  delete?: InputMaybe<Array<MetadataUnitWhereUniqueInput>>;
  /** Update existing "MetadataUnit" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUnitList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUnitListUpdateInput>>;
  /** Upsert existing "MetadataUnit" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUnitList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUnitListUpdateInput>>;
};

export type ArchiveNestedMetadataUnitShortNameListCreateInput = {
  /** Connect existing "MetadataUnitShortName" nodes to the new "Archive" node, through the "Archive.metadataUnitShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataUnitShortNameWhereUniqueInput>>;
  /** Create new "MetadataUnitShortName" nodes and connect them to the new "Archive" node, through the "Archive.metadataUnitShortNameList" relation. */
  create?: InputMaybe<Array<MetadataUnitShortNameWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUnitShortNameListUpdateInput = {
  /** Connect existing "MetadataUnitShortName" nodes to the existing "Archive" node, through the "Archive.metadataUnitShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataUnitShortNameWhereUniqueInput>>;
  /** Create new "MetadataUnitShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUnitShortNameList" relation. */
  create?: InputMaybe<Array<MetadataUnitShortNameWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUnitShortName" nodes from the "Archive.metadataUnitShortNameList" relation. */
  delete?: InputMaybe<Array<MetadataUnitShortNameWhereUniqueInput>>;
  /** Update existing "MetadataUnitShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUnitShortNameList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUnitShortNameListUpdateInput>>;
  /** Upsert existing "MetadataUnitShortName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUnitShortNameList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUnitShortNameListUpdateInput>>;
};

export type ArchiveNestedMetadataUploadedByFullNameListCreateInput = {
  /** Connect existing "MetadataUploadedByFullName" nodes to the new "Archive" node, through the "Archive.metadataUploadedByFullNameList" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByFullNameWhereUniqueInput>>;
  /** Create new "MetadataUploadedByFullName" nodes and connect them to the new "Archive" node, through the "Archive.metadataUploadedByFullNameList" relation. */
  create?: InputMaybe<Array<MetadataUploadedByFullNameWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUploadedByFullNameListUpdateInput = {
  /** Connect existing "MetadataUploadedByFullName" nodes to the existing "Archive" node, through the "Archive.metadataUploadedByFullNameList" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByFullNameWhereUniqueInput>>;
  /** Create new "MetadataUploadedByFullName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUploadedByFullNameList" relation. */
  create?: InputMaybe<Array<MetadataUploadedByFullNameWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUploadedByFullName" nodes from the "Archive.metadataUploadedByFullNameList" relation. */
  delete?: InputMaybe<Array<MetadataUploadedByFullNameWhereUniqueInput>>;
  /** Update existing "MetadataUploadedByFullName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUploadedByFullNameList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUploadedByFullNameListUpdateInput>>;
  /** Upsert existing "MetadataUploadedByFullName" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUploadedByFullNameList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUploadedByFullNameListUpdateInput>>;
};

export type ArchiveNestedMetadataUploadedByListCreateInput = {
  /** Connect existing "MetadataUploadedBy" nodes to the new "Archive" node, through the "Archive.metadataUploadedByList" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByWhereUniqueInput>>;
  /** Create new "MetadataUploadedBy" nodes and connect them to the new "Archive" node, through the "Archive.metadataUploadedByList" relation. */
  create?: InputMaybe<Array<MetadataUploadedByWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUploadedByListUpdateInput = {
  /** Connect existing "MetadataUploadedBy" nodes to the existing "Archive" node, through the "Archive.metadataUploadedByList" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByWhereUniqueInput>>;
  /** Create new "MetadataUploadedBy" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUploadedByList" relation. */
  create?: InputMaybe<Array<MetadataUploadedByWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUploadedBy" nodes from the "Archive.metadataUploadedByList" relation. */
  delete?: InputMaybe<Array<MetadataUploadedByWhereUniqueInput>>;
  /** Update existing "MetadataUploadedBy" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUploadedByList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUploadedByListUpdateInput>>;
  /** Upsert existing "MetadataUploadedBy" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUploadedByList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUploadedByListUpdateInput>>;
};

export type ArchiveNestedMetadataUploadTimeListCreateInput = {
  /** Connect existing "MetadataUploadTime" nodes to the new "Archive" node, through the "Archive.metadataUploadTimeList" relation. */
  connect?: InputMaybe<Array<MetadataUploadTimeWhereUniqueInput>>;
  /** Create new "MetadataUploadTime" nodes and connect them to the new "Archive" node, through the "Archive.metadataUploadTimeList" relation. */
  create?: InputMaybe<Array<MetadataUploadTimeWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUploadTimeListUpdateInput = {
  /** Connect existing "MetadataUploadTime" nodes to the existing "Archive" node, through the "Archive.metadataUploadTimeList" relation. */
  connect?: InputMaybe<Array<MetadataUploadTimeWhereUniqueInput>>;
  /** Create new "MetadataUploadTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUploadTimeList" relation. */
  create?: InputMaybe<Array<MetadataUploadTimeWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUploadTime" nodes from the "Archive.metadataUploadTimeList" relation. */
  delete?: InputMaybe<Array<MetadataUploadTimeWhereUniqueInput>>;
  /** Update existing "MetadataUploadTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUploadTimeList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUploadTimeListUpdateInput>>;
  /** Upsert existing "MetadataUploadTime" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUploadTimeList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUploadTimeListUpdateInput>>;
};

export type ArchiveNestedMetadataUrgencyListCreateInput = {
  /** Connect existing "MetadataUrgency" nodes to the new "Archive" node, through the "Archive.metadataUrgencyList" relation. */
  connect?: InputMaybe<Array<MetadataUrgencyWhereUniqueInput>>;
  /** Create new "MetadataUrgency" nodes and connect them to the new "Archive" node, through the "Archive.metadataUrgencyList" relation. */
  create?: InputMaybe<Array<MetadataUrgencyWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUrgencyListUpdateInput = {
  /** Connect existing "MetadataUrgency" nodes to the existing "Archive" node, through the "Archive.metadataUrgencyList" relation. */
  connect?: InputMaybe<Array<MetadataUrgencyWhereUniqueInput>>;
  /** Create new "MetadataUrgency" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUrgencyList" relation. */
  create?: InputMaybe<Array<MetadataUrgencyWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUrgency" nodes from the "Archive.metadataUrgencyList" relation. */
  delete?: InputMaybe<Array<MetadataUrgencyWhereUniqueInput>>;
  /** Update existing "MetadataUrgency" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUrgencyList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUrgencyListUpdateInput>>;
  /** Upsert existing "MetadataUrgency" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUrgencyList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUrgencyListUpdateInput>>;
};

export type ArchiveNestedMetadataUsageTermsListCreateInput = {
  /** Connect existing "MetadataUsageTerms" nodes to the new "Archive" node, through the "Archive.metadataUsageTermsList" relation. */
  connect?: InputMaybe<Array<MetadataUsageTermsWhereUniqueInput>>;
  /** Create new "MetadataUsageTerms" nodes and connect them to the new "Archive" node, through the "Archive.metadataUsageTermsList" relation. */
  create?: InputMaybe<Array<MetadataUsageTermsWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUsageTermsListUpdateInput = {
  /** Connect existing "MetadataUsageTerms" nodes to the existing "Archive" node, through the "Archive.metadataUsageTermsList" relation. */
  connect?: InputMaybe<Array<MetadataUsageTermsWhereUniqueInput>>;
  /** Create new "MetadataUsageTerms" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUsageTermsList" relation. */
  create?: InputMaybe<Array<MetadataUsageTermsWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUsageTerms" nodes from the "Archive.metadataUsageTermsList" relation. */
  delete?: InputMaybe<Array<MetadataUsageTermsWhereUniqueInput>>;
  /** Update existing "MetadataUsageTerms" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUsageTermsList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUsageTermsListUpdateInput>>;
  /** Upsert existing "MetadataUsageTerms" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUsageTermsList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUsageTermsListUpdateInput>>;
};

export type ArchiveNestedMetadataUserCommentListCreateInput = {
  /** Connect existing "MetadataUserComment" nodes to the new "Archive" node, through the "Archive.metadataUserCommentList" relation. */
  connect?: InputMaybe<Array<MetadataUserCommentWhereUniqueInput>>;
  /** Create new "MetadataUserComment" nodes and connect them to the new "Archive" node, through the "Archive.metadataUserCommentList" relation. */
  create?: InputMaybe<Array<MetadataUserCommentWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUserCommentListUpdateInput = {
  /** Connect existing "MetadataUserComment" nodes to the existing "Archive" node, through the "Archive.metadataUserCommentList" relation. */
  connect?: InputMaybe<Array<MetadataUserCommentWhereUniqueInput>>;
  /** Create new "MetadataUserComment" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserCommentList" relation. */
  create?: InputMaybe<Array<MetadataUserCommentWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUserComment" nodes from the "Archive.metadataUserCommentList" relation. */
  delete?: InputMaybe<Array<MetadataUserCommentWhereUniqueInput>>;
  /** Update existing "MetadataUserComment" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserCommentList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUserCommentListUpdateInput>>;
  /** Upsert existing "MetadataUserComment" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserCommentList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUserCommentListUpdateInput>>;
};

export type ArchiveNestedMetadataUserDefined195ListCreateInput = {
  /** Connect existing "MetadataUserDefined195" nodes to the new "Archive" node, through the "Archive.metadataUserDefined195List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined195WhereUniqueInput>>;
  /** Create new "MetadataUserDefined195" nodes and connect them to the new "Archive" node, through the "Archive.metadataUserDefined195List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined195WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUserDefined195ListUpdateInput = {
  /** Connect existing "MetadataUserDefined195" nodes to the existing "Archive" node, through the "Archive.metadataUserDefined195List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined195WhereUniqueInput>>;
  /** Create new "MetadataUserDefined195" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined195List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined195WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUserDefined195" nodes from the "Archive.metadataUserDefined195List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined195WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined195" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined195List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUserDefined195ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined195" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined195List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUserDefined195ListUpdateInput>>;
};

export type ArchiveNestedMetadataUserDefined237ListCreateInput = {
  /** Connect existing "MetadataUserDefined237" nodes to the new "Archive" node, through the "Archive.metadataUserDefined237List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined237WhereUniqueInput>>;
  /** Create new "MetadataUserDefined237" nodes and connect them to the new "Archive" node, through the "Archive.metadataUserDefined237List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined237WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUserDefined237ListUpdateInput = {
  /** Connect existing "MetadataUserDefined237" nodes to the existing "Archive" node, through the "Archive.metadataUserDefined237List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined237WhereUniqueInput>>;
  /** Create new "MetadataUserDefined237" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined237List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined237WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUserDefined237" nodes from the "Archive.metadataUserDefined237List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined237WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined237" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined237List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUserDefined237ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined237" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined237List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUserDefined237ListUpdateInput>>;
};

export type ArchiveNestedMetadataUserDefined238ListCreateInput = {
  /** Connect existing "MetadataUserDefined238" nodes to the new "Archive" node, through the "Archive.metadataUserDefined238List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined238WhereUniqueInput>>;
  /** Create new "MetadataUserDefined238" nodes and connect them to the new "Archive" node, through the "Archive.metadataUserDefined238List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined238WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUserDefined238ListUpdateInput = {
  /** Connect existing "MetadataUserDefined238" nodes to the existing "Archive" node, through the "Archive.metadataUserDefined238List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined238WhereUniqueInput>>;
  /** Create new "MetadataUserDefined238" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined238List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined238WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUserDefined238" nodes from the "Archive.metadataUserDefined238List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined238WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined238" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined238List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUserDefined238ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined238" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined238List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUserDefined238ListUpdateInput>>;
};

export type ArchiveNestedMetadataUserDefined239ListCreateInput = {
  /** Connect existing "MetadataUserDefined239" nodes to the new "Archive" node, through the "Archive.metadataUserDefined239List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined239WhereUniqueInput>>;
  /** Create new "MetadataUserDefined239" nodes and connect them to the new "Archive" node, through the "Archive.metadataUserDefined239List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined239WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUserDefined239ListUpdateInput = {
  /** Connect existing "MetadataUserDefined239" nodes to the existing "Archive" node, through the "Archive.metadataUserDefined239List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined239WhereUniqueInput>>;
  /** Create new "MetadataUserDefined239" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined239List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined239WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUserDefined239" nodes from the "Archive.metadataUserDefined239List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined239WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined239" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined239List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUserDefined239ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined239" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined239List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUserDefined239ListUpdateInput>>;
};

export type ArchiveNestedMetadataUserDefined242ListCreateInput = {
  /** Connect existing "MetadataUserDefined242" nodes to the new "Archive" node, through the "Archive.metadataUserDefined242List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined242WhereUniqueInput>>;
  /** Create new "MetadataUserDefined242" nodes and connect them to the new "Archive" node, through the "Archive.metadataUserDefined242List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined242WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUserDefined242ListUpdateInput = {
  /** Connect existing "MetadataUserDefined242" nodes to the existing "Archive" node, through the "Archive.metadataUserDefined242List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined242WhereUniqueInput>>;
  /** Create new "MetadataUserDefined242" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined242List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined242WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUserDefined242" nodes from the "Archive.metadataUserDefined242List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined242WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined242" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined242List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUserDefined242ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined242" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined242List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUserDefined242ListUpdateInput>>;
};

export type ArchiveNestedMetadataUserDefined62ListCreateInput = {
  /** Connect existing "MetadataUserDefined62" nodes to the new "Archive" node, through the "Archive.metadataUserDefined62List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined62WhereUniqueInput>>;
  /** Create new "MetadataUserDefined62" nodes and connect them to the new "Archive" node, through the "Archive.metadataUserDefined62List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined62WithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataUserDefined62ListUpdateInput = {
  /** Connect existing "MetadataUserDefined62" nodes to the existing "Archive" node, through the "Archive.metadataUserDefined62List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined62WhereUniqueInput>>;
  /** Create new "MetadataUserDefined62" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined62List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined62WithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataUserDefined62" nodes from the "Archive.metadataUserDefined62List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined62WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined62" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined62List" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataUserDefined62ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined62" nodes and connect them to the existing "Archive" node, through the "Archive.metadataUserDefined62List" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataUserDefined62ListUpdateInput>>;
};

export type ArchiveNestedMetadataValidListCreateInput = {
  /** Connect existing "MetadataValid" nodes to the new "Archive" node, through the "Archive.metadataValidList" relation. */
  connect?: InputMaybe<Array<MetadataValidWhereUniqueInput>>;
  /** Create new "MetadataValid" nodes and connect them to the new "Archive" node, through the "Archive.metadataValidList" relation. */
  create?: InputMaybe<Array<MetadataValidWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataValidListUpdateInput = {
  /** Connect existing "MetadataValid" nodes to the existing "Archive" node, through the "Archive.metadataValidList" relation. */
  connect?: InputMaybe<Array<MetadataValidWhereUniqueInput>>;
  /** Create new "MetadataValid" nodes and connect them to the existing "Archive" node, through the "Archive.metadataValidList" relation. */
  create?: InputMaybe<Array<MetadataValidWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataValid" nodes from the "Archive.metadataValidList" relation. */
  delete?: InputMaybe<Array<MetadataValidWhereUniqueInput>>;
  /** Update existing "MetadataValid" nodes and connect them to the existing "Archive" node, through the "Archive.metadataValidList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataValidListUpdateInput>>;
  /** Upsert existing "MetadataValid" nodes and connect them to the existing "Archive" node, through the "Archive.metadataValidList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataValidListUpdateInput>>;
};

export type ArchiveNestedMetadataVersionListCreateInput = {
  /** Connect existing "MetadataVersion" nodes to the new "Archive" node, through the "Archive.metadataVersionList" relation. */
  connect?: InputMaybe<Array<MetadataVersionWhereUniqueInput>>;
  /** Create new "MetadataVersion" nodes and connect them to the new "Archive" node, through the "Archive.metadataVersionList" relation. */
  create?: InputMaybe<Array<MetadataVersionWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataVersionListUpdateInput = {
  /** Connect existing "MetadataVersion" nodes to the existing "Archive" node, through the "Archive.metadataVersionList" relation. */
  connect?: InputMaybe<Array<MetadataVersionWhereUniqueInput>>;
  /** Create new "MetadataVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataVersionList" relation. */
  create?: InputMaybe<Array<MetadataVersionWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataVersion" nodes from the "Archive.metadataVersionList" relation. */
  delete?: InputMaybe<Array<MetadataVersionWhereUniqueInput>>;
  /** Update existing "MetadataVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataVersionList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataVersionListUpdateInput>>;
  /** Upsert existing "MetadataVersion" nodes and connect them to the existing "Archive" node, through the "Archive.metadataVersionList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataVersionListUpdateInput>>;
};

export type ArchiveNestedMetadataWebStatementListCreateInput = {
  /** Connect existing "MetadataWebStatement" nodes to the new "Archive" node, through the "Archive.metadataWebStatementList" relation. */
  connect?: InputMaybe<Array<MetadataWebStatementWhereUniqueInput>>;
  /** Create new "MetadataWebStatement" nodes and connect them to the new "Archive" node, through the "Archive.metadataWebStatementList" relation. */
  create?: InputMaybe<Array<MetadataWebStatementWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataWebStatementListUpdateInput = {
  /** Connect existing "MetadataWebStatement" nodes to the existing "Archive" node, through the "Archive.metadataWebStatementList" relation. */
  connect?: InputMaybe<Array<MetadataWebStatementWhereUniqueInput>>;
  /** Create new "MetadataWebStatement" nodes and connect them to the existing "Archive" node, through the "Archive.metadataWebStatementList" relation. */
  create?: InputMaybe<Array<MetadataWebStatementWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataWebStatement" nodes from the "Archive.metadataWebStatementList" relation. */
  delete?: InputMaybe<Array<MetadataWebStatementWhereUniqueInput>>;
  /** Update existing "MetadataWebStatement" nodes and connect them to the existing "Archive" node, through the "Archive.metadataWebStatementList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataWebStatementListUpdateInput>>;
  /** Upsert existing "MetadataWebStatement" nodes and connect them to the existing "Archive" node, through the "Archive.metadataWebStatementList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataWebStatementListUpdateInput>>;
};

export type ArchiveNestedMetadataWorkflowKindListCreateInput = {
  /** Connect existing "MetadataWorkflowKind" nodes to the new "Archive" node, through the "Archive.metadataWorkflowKindList" relation. */
  connect?: InputMaybe<Array<MetadataWorkflowKindWhereUniqueInput>>;
  /** Create new "MetadataWorkflowKind" nodes and connect them to the new "Archive" node, through the "Archive.metadataWorkflowKindList" relation. */
  create?: InputMaybe<Array<MetadataWorkflowKindWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataWorkflowKindListUpdateInput = {
  /** Connect existing "MetadataWorkflowKind" nodes to the existing "Archive" node, through the "Archive.metadataWorkflowKindList" relation. */
  connect?: InputMaybe<Array<MetadataWorkflowKindWhereUniqueInput>>;
  /** Create new "MetadataWorkflowKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataWorkflowKindList" relation. */
  create?: InputMaybe<Array<MetadataWorkflowKindWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataWorkflowKind" nodes from the "Archive.metadataWorkflowKindList" relation. */
  delete?: InputMaybe<Array<MetadataWorkflowKindWhereUniqueInput>>;
  /** Update existing "MetadataWorkflowKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataWorkflowKindList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataWorkflowKindListUpdateInput>>;
  /** Upsert existing "MetadataWorkflowKind" nodes and connect them to the existing "Archive" node, through the "Archive.metadataWorkflowKindList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataWorkflowKindListUpdateInput>>;
};

export type ArchiveNestedMetadataXmpFileStampsListCreateInput = {
  /** Connect existing "MetadataXmpFileStamps" nodes to the new "Archive" node, through the "Archive.metadataXmpFileStampsList" relation. */
  connect?: InputMaybe<Array<MetadataXmpFileStampsWhereUniqueInput>>;
  /** Create new "MetadataXmpFileStamps" nodes and connect them to the new "Archive" node, through the "Archive.metadataXmpFileStampsList" relation. */
  create?: InputMaybe<Array<MetadataXmpFileStampsWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataXmpFileStampsListUpdateInput = {
  /** Connect existing "MetadataXmpFileStamps" nodes to the existing "Archive" node, through the "Archive.metadataXmpFileStampsList" relation. */
  connect?: InputMaybe<Array<MetadataXmpFileStampsWhereUniqueInput>>;
  /** Create new "MetadataXmpFileStamps" nodes and connect them to the existing "Archive" node, through the "Archive.metadataXmpFileStampsList" relation. */
  create?: InputMaybe<Array<MetadataXmpFileStampsWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataXmpFileStamps" nodes from the "Archive.metadataXmpFileStampsList" relation. */
  delete?: InputMaybe<Array<MetadataXmpFileStampsWhereUniqueInput>>;
  /** Update existing "MetadataXmpFileStamps" nodes and connect them to the existing "Archive" node, through the "Archive.metadataXmpFileStampsList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataXmpFileStampsListUpdateInput>>;
  /** Upsert existing "MetadataXmpFileStamps" nodes and connect them to the existing "Archive" node, through the "Archive.metadataXmpFileStampsList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataXmpFileStampsListUpdateInput>>;
};

export type ArchiveNestedMetadataXmpHistoryListCreateInput = {
  /** Connect existing "MetadataXmpHistory" nodes to the new "Archive" node, through the "Archive.metadataXmpHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataXmpHistoryWhereUniqueInput>>;
  /** Create new "MetadataXmpHistory" nodes and connect them to the new "Archive" node, through the "Archive.metadataXmpHistoryList" relation. */
  create?: InputMaybe<Array<MetadataXmpHistoryWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedMetadataXmpHistoryListUpdateInput = {
  /** Connect existing "MetadataXmpHistory" nodes to the existing "Archive" node, through the "Archive.metadataXmpHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataXmpHistoryWhereUniqueInput>>;
  /** Create new "MetadataXmpHistory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataXmpHistoryList" relation. */
  create?: InputMaybe<Array<MetadataXmpHistoryWithForcedArchiveCreateInput>>;
  /** Delete existing "MetadataXmpHistory" nodes from the "Archive.metadataXmpHistoryList" relation. */
  delete?: InputMaybe<Array<MetadataXmpHistoryWhereUniqueInput>>;
  /** Update existing "MetadataXmpHistory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataXmpHistoryList" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdateMetadataXmpHistoryListUpdateInput>>;
  /** Upsert existing "MetadataXmpHistory" nodes and connect them to the existing "Archive" node, through the "Archive.metadataXmpHistoryList" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertMetadataXmpHistoryListUpdateInput>>;
};

export type ArchiveNestedPhotoMetadatasCreateInput = {
  /** Connect existing "PhotoMetadata" nodes to the new "Archive" node, through the "Archive.photoMetadatas" relation. */
  connect?: InputMaybe<Array<PhotoMetadataWhereUniqueInput>>;
  /** Create new "PhotoMetadata" nodes and connect them to the new "Archive" node, through the "Archive.photoMetadatas" relation. */
  create?: InputMaybe<Array<PhotoMetadataWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedPhotoMetadatasUpdateInput = {
  /** Connect existing "PhotoMetadata" nodes to the existing "Archive" node, through the "Archive.photoMetadatas" relation. */
  connect?: InputMaybe<Array<PhotoMetadataWhereUniqueInput>>;
  /** Create new "PhotoMetadata" nodes and connect them to the existing "Archive" node, through the "Archive.photoMetadatas" relation. */
  create?: InputMaybe<Array<PhotoMetadataWithForcedArchiveCreateInput>>;
  /** Delete existing "PhotoMetadata" nodes from the "Archive.photoMetadatas" relation. */
  delete?: InputMaybe<Array<PhotoMetadataWhereUniqueInput>>;
  /** Update existing "PhotoMetadata" nodes and connect them to the existing "Archive" node, through the "Archive.photoMetadatas" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdatePhotoMetadatasUpdateInput>>;
  /** Upsert existing "PhotoMetadata" nodes and connect them to the existing "Archive" node, through the "Archive.photoMetadatas" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertPhotoMetadatasUpdateInput>>;
};

export type ArchiveNestedPhotosCreateInput = {
  /** Connect existing "Photo" nodes to the new "Archive" node, through the "Archive.photos" relation. */
  connect?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Create new "Photo" nodes and connect them to the new "Archive" node, through the "Archive.photos" relation. */
  create?: InputMaybe<Array<PhotoWithForcedArchiveCreateInput>>;
};

export type ArchiveNestedPhotosUpdateInput = {
  /** Connect existing "Photo" nodes to the existing "Archive" node, through the "Archive.photos" relation. */
  connect?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Create new "Photo" nodes and connect them to the existing "Archive" node, through the "Archive.photos" relation. */
  create?: InputMaybe<Array<PhotoWithForcedArchiveCreateInput>>;
  /** Delete existing "Photo" nodes from the "Archive.photos" relation. */
  delete?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Update existing "Photo" nodes and connect them to the existing "Archive" node, through the "Archive.photos" relation. */
  update?: InputMaybe<Array<ArchiveNestedUpdatePhotosUpdateInput>>;
  /** Upsert existing "Photo" nodes and connect them to the existing "Archive" node, through the "Archive.photos" relation. */
  upsert?: InputMaybe<Array<ArchiveNestedUpsertPhotosUpdateInput>>;
};

export type ArchiveNestedUpdateArchiveAgenciesUpdateInput = {
  data: ArchiveAgencyWithForcedArchiveUpdateInput;
  where: ArchiveAgencyWithOptionalArchiveWhereUniqueInput;
};

export type ArchiveNestedUpdateCategoriesUpdateInput = {
  data: CategoryWithForcedArchiveUpdateInput;
  where: CategoryWithOptionalArchiveWhereUniqueInput;
};

export type ArchiveNestedUpdateDeliverySubjectsUpdateInput = {
  data: DeliverySubjectWithForcedArchiveUpdateInput;
  where: DeliverySubjectWithOptionalArchiveWhereUniqueInput;
};

export type ArchiveNestedUpdateGcImportSettingUpdateInput = {
  data: GcImportSettingWithForcedArchiveUpdateInput;
  where: GcImportSettingWithOptionalArchiveWhereUniqueInput;
};

export type ArchiveNestedUpdateImportQueuesUpdateInput = {
  data: ImportQueueWithForcedArchiveUpdateInput;
  where: ImportQueueWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataAccessRightsListUpdateInput = {
  data: MetadataAccessRightsWithForcedArchiveUpdateInput;
  where: MetadataAccessRightsWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataAddlModelInfoListUpdateInput = {
  data: MetadataAddlModelInfoWithForcedArchiveUpdateInput;
  where: MetadataAddlModelInfoWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataArchiveChildListUpdateInput = {
  data: MetadataArchiveChildWithForcedArchiveUpdateInput;
  where: MetadataArchiveChildWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataArchiveParentListUpdateInput = {
  data: MetadataArchiveParentWithForcedArchiveUpdateInput;
  where: MetadataArchiveParentWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataAssetStateListUpdateInput = {
  data: MetadataAssetStateWithForcedArchiveUpdateInput;
  where: MetadataAssetStateWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataAttributionNameListUpdateInput = {
  data: MetadataAttributionNameWithForcedArchiveUpdateInput;
  where: MetadataAttributionNameWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataAttributionUrlListUpdateInput = {
  data: MetadataAttributionUrlWithForcedArchiveUpdateInput;
  where: MetadataAttributionUrlWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataAudienceListUpdateInput = {
  data: MetadataAudienceWithForcedArchiveUpdateInput;
  where: MetadataAudienceWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataAuthorsPositionListUpdateInput = {
  data: MetadataAuthorsPositionWithForcedArchiveUpdateInput;
  where: MetadataAuthorsPositionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataBackupNameListUpdateInput = {
  data: MetadataBackupNameWithForcedArchiveUpdateInput;
  where: MetadataBackupNameWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataBitmapGrayscalePictureListUpdateInput = {
  data: MetadataBitmapGrayscalePictureWithForcedArchiveUpdateInput;
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataBrandListUpdateInput = {
  data: MetadataBrandWithForcedArchiveUpdateInput;
  where: MetadataBrandWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataBrandPrismaListUpdateInput = {
  data: MetadataBrandPrismaWithForcedArchiveUpdateInput;
  where: MetadataBrandPrismaWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataBrandShortNameListUpdateInput = {
  data: MetadataBrandShortNameWithForcedArchiveUpdateInput;
  where: MetadataBrandShortNameWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCaptionWriterListUpdateInput = {
  data: MetadataCaptionWriterWithForcedArchiveUpdateInput;
  where: MetadataCaptionWriterWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCategoryListUpdateInput = {
  data: MetadataCategoryWithForcedArchiveUpdateInput;
  where: MetadataCategoryWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCategoryPrismaListUpdateInput = {
  data: MetadataCategoryPrismaWithForcedArchiveUpdateInput;
  where: MetadataCategoryPrismaWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCertificateListUpdateInput = {
  data: MetadataCertificateWithForcedArchiveUpdateInput;
  where: MetadataCertificateWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCiAdrCityListUpdateInput = {
  data: MetadataCiAdrCityWithForcedArchiveUpdateInput;
  where: MetadataCiAdrCityWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCiAdrCtryListUpdateInput = {
  data: MetadataCiAdrCtryWithForcedArchiveUpdateInput;
  where: MetadataCiAdrCtryWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCiAdrExtadrListUpdateInput = {
  data: MetadataCiAdrExtadrWithForcedArchiveUpdateInput;
  where: MetadataCiAdrExtadrWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCiAdrPcodeListUpdateInput = {
  data: MetadataCiAdrPcodeWithForcedArchiveUpdateInput;
  where: MetadataCiAdrPcodeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCiAdrRegionListUpdateInput = {
  data: MetadataCiAdrRegionWithForcedArchiveUpdateInput;
  where: MetadataCiAdrRegionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCiEmailWorkListUpdateInput = {
  data: MetadataCiEmailWorkWithForcedArchiveUpdateInput;
  where: MetadataCiEmailWorkWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCiTelWorkListUpdateInput = {
  data: MetadataCiTelWorkWithForcedArchiveUpdateInput;
  where: MetadataCiTelWorkWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCityListUpdateInput = {
  data: MetadataCityWithForcedArchiveUpdateInput;
  where: MetadataCityWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCiUrlWorkListUpdateInput = {
  data: MetadataCiUrlWorkWithForcedArchiveUpdateInput;
  where: MetadataCiUrlWorkWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataClassifyListUpdateInput = {
  data: MetadataClassifyWithForcedArchiveUpdateInput;
  where: MetadataClassifyWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCompanyListUpdateInput = {
  data: MetadataCompanyWithForcedArchiveUpdateInput;
  where: MetadataCompanyWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCompanyShortNameListUpdateInput = {
  data: MetadataCompanyShortNameWithForcedArchiveUpdateInput;
  where: MetadataCompanyShortNameWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataConfidentialityListUpdateInput = {
  data: MetadataConfidentialityWithForcedArchiveUpdateInput;
  where: MetadataConfidentialityWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataContainedInListUpdateInput = {
  data: MetadataContainedInWithForcedArchiveUpdateInput;
  where: MetadataContainedInWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataContentMediaKindListUpdateInput = {
  data: MetadataContentMediaKindWithForcedArchiveUpdateInput;
  where: MetadataContentMediaKindWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataContentValueListUpdateInput = {
  data: MetadataContentValueWithForcedArchiveUpdateInput;
  where: MetadataContentValueWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCopyrightLayerListUpdateInput = {
  data: MetadataCopyrightLayerWithForcedArchiveUpdateInput;
  where: MetadataCopyrightLayerWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCountryCodeListUpdateInput = {
  data: MetadataCountryCodeWithForcedArchiveUpdateInput;
  where: MetadataCountryCodeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCountryListUpdateInput = {
  data: MetadataCountryWithForcedArchiveUpdateInput;
  where: MetadataCountryWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCreatedTimeListUpdateInput = {
  data: MetadataCreatedTimeWithForcedArchiveUpdateInput;
  where: MetadataCreatedTimeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCreatorListUpdateInput = {
  data: MetadataCreatorWithForcedArchiveUpdateInput;
  where: MetadataCreatorWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCreatorToolListUpdateInput = {
  data: MetadataCreatorToolWithForcedArchiveUpdateInput;
  where: MetadataCreatorToolWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCreditListUpdateInput = {
  data: MetadataCreditWithForcedArchiveUpdateInput;
  where: MetadataCreditWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCustomField14ListUpdateInput = {
  data: MetadataCustomField14WithForcedArchiveUpdateInput;
  where: MetadataCustomField14WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCustomField15ListUpdateInput = {
  data: MetadataCustomField15WithForcedArchiveUpdateInput;
  where: MetadataCustomField15WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCustomField16ListUpdateInput = {
  data: MetadataCustomField16WithForcedArchiveUpdateInput;
  where: MetadataCustomField16WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCustomField17ListUpdateInput = {
  data: MetadataCustomField17WithForcedArchiveUpdateInput;
  where: MetadataCustomField17WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCustomField20ListUpdateInput = {
  data: MetadataCustomField20WithForcedArchiveUpdateInput;
  where: MetadataCustomField20WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCustomField5ListUpdateInput = {
  data: MetadataCustomField5WithForcedArchiveUpdateInput;
  where: MetadataCustomField5WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCustomField6ListUpdateInput = {
  data: MetadataCustomField6WithForcedArchiveUpdateInput;
  where: MetadataCustomField6WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCustomField7ListUpdateInput = {
  data: MetadataCustomField7WithForcedArchiveUpdateInput;
  where: MetadataCustomField7WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataCustomField9ListUpdateInput = {
  data: MetadataCustomField9WithForcedArchiveUpdateInput;
  where: MetadataCustomField9WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDateCreatedListUpdateInput = {
  data: MetadataDateCreatedWithForcedArchiveUpdateInput;
  where: MetadataDateCreatedWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDateTimeDigitizedListUpdateInput = {
  data: MetadataDateTimeDigitizedWithForcedArchiveUpdateInput;
  where: MetadataDateTimeDigitizedWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDateTimeOriginalListUpdateInput = {
  data: MetadataDateTimeOriginalWithForcedArchiveUpdateInput;
  where: MetadataDateTimeOriginalWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryAccountListUpdateInput = {
  data: MetadataDeliveryAccountWithForcedArchiveUpdateInput;
  where: MetadataDeliveryAccountWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryCompanyListUpdateInput = {
  data: MetadataDeliveryCompanyWithForcedArchiveUpdateInput;
  where: MetadataDeliveryCompanyWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryCopyrightListUpdateInput = {
  data: MetadataDeliveryCopyrightWithForcedArchiveUpdateInput;
  where: MetadataDeliveryCopyrightWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryDateFolderListUpdateInput = {
  data: MetadataDeliveryDateFolderWithForcedArchiveUpdateInput;
  where: MetadataDeliveryDateFolderWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryDateTimeListUpdateInput = {
  data: MetadataDeliveryDateTimeWithForcedArchiveUpdateInput;
  where: MetadataDeliveryDateTimeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryDcCreatorListUpdateInput = {
  data: MetadataDeliveryDcCreatorWithForcedArchiveUpdateInput;
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryDcRightsListUpdateInput = {
  data: MetadataDeliveryDcRightsWithForcedArchiveUpdateInput;
  where: MetadataDeliveryDcRightsWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryFileTypeListUpdateInput = {
  data: MetadataDeliveryFileTypeWithForcedArchiveUpdateInput;
  where: MetadataDeliveryFileTypeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryFolderListUpdateInput = {
  data: MetadataDeliveryFolderWithForcedArchiveUpdateInput;
  where: MetadataDeliveryFolderWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryKindListUpdateInput = {
  data: MetadataDeliveryKindWithForcedArchiveUpdateInput;
  where: MetadataDeliveryKindWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryPathListUpdateInput = {
  data: MetadataDeliveryPathWithForcedArchiveUpdateInput;
  where: MetadataDeliveryPathWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryPersonShownintheImageListUpdateInput = {
  data: MetadataDeliveryPersonShownintheImageWithForcedArchiveUpdateInput;
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryPhotoshopCreditListUpdateInput = {
  data: MetadataDeliveryPhotoshopCreditWithForcedArchiveUpdateInput;
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryPhotoshopSourceListUpdateInput = {
  data: MetadataDeliveryPhotoshopSourceWithForcedArchiveUpdateInput;
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryServiceListUpdateInput = {
  data: MetadataDeliveryServiceWithForcedArchiveUpdateInput;
  where: MetadataDeliveryServiceWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliverySubjectListUpdateInput = {
  data: MetadataDeliverySubjectWithForcedArchiveUpdateInput;
  where: MetadataDeliverySubjectWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDeliveryUnderSubjectListUpdateInput = {
  data: MetadataDeliveryUnderSubjectWithForcedArchiveUpdateInput;
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDepartmentListUpdateInput = {
  data: MetadataDepartmentWithForcedArchiveUpdateInput;
  where: MetadataDepartmentWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDescriptionListUpdateInput = {
  data: MetadataDescriptionWithForcedArchiveUpdateInput;
  where: MetadataDescriptionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDigitalAssetUrlListUpdateInput = {
  data: MetadataDigitalAssetUrlWithForcedArchiveUpdateInput;
  where: MetadataDigitalAssetUrlWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDivisionListUpdateInput = {
  data: MetadataDivisionWithForcedArchiveUpdateInput;
  where: MetadataDivisionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataDocumentTextListUpdateInput = {
  data: MetadataDocumentTextWithForcedArchiveUpdateInput;
  where: MetadataDocumentTextWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataEditionListUpdateInput = {
  data: MetadataEditionWithForcedArchiveUpdateInput;
  where: MetadataEditionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataEditorialVersionListUpdateInput = {
  data: MetadataEditorialVersionWithForcedArchiveUpdateInput;
  where: MetadataEditorialVersionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataEditStatusListUpdateInput = {
  data: MetadataEditStatusWithForcedArchiveUpdateInput;
  where: MetadataEditStatusWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataEnvironnementPhotoListUpdateInput = {
  data: MetadataEnvironnementPhotoWithForcedArchiveUpdateInput;
  where: MetadataEnvironnementPhotoWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataEquipmentInstitutionListUpdateInput = {
  data: MetadataEquipmentInstitutionWithForcedArchiveUpdateInput;
  where: MetadataEquipmentInstitutionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataEquipmentManufacturerListUpdateInput = {
  data: MetadataEquipmentManufacturerWithForcedArchiveUpdateInput;
  where: MetadataEquipmentManufacturerWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataEventListUpdateInput = {
  data: MetadataEventWithForcedArchiveUpdateInput;
  where: MetadataEventWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataExifListUpdateInput = {
  data: MetadataExifWithForcedArchiveUpdateInput;
  where: MetadataExifWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataFabStorageListUpdateInput = {
  data: MetadataFabStorageWithForcedArchiveUpdateInput;
  where: MetadataFabStorageWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataFileCheckForListUpdateInput = {
  data: MetadataFileCheckForWithForcedArchiveUpdateInput;
  where: MetadataFileCheckForWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataFileInfoBitsPerPixelListUpdateInput = {
  data: MetadataFileInfoBitsPerPixelWithForcedArchiveUpdateInput;
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataFileInfoFramesListUpdateInput = {
  data: MetadataFileInfoFramesWithForcedArchiveUpdateInput;
  where: MetadataFileInfoFramesWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataFileInfoPhotometricInterpretationListUpdateInput = {
  data: MetadataFileInfoPhotometricInterpretationWithForcedArchiveUpdateInput;
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataFileInfoTransferSyntaxListUpdateInput = {
  data: MetadataFileInfoTransferSyntaxWithForcedArchiveUpdateInput;
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataFileStageListUpdateInput = {
  data: MetadataFileStageWithForcedArchiveUpdateInput;
  where: MetadataFileStageWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataFileStatusListUpdateInput = {
  data: MetadataFileStatusWithForcedArchiveUpdateInput;
  where: MetadataFileStatusWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataFileTypeListUpdateInput = {
  data: MetadataFileTypeWithForcedArchiveUpdateInput;
  where: MetadataFileTypeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataFixtureIdentifierListUpdateInput = {
  data: MetadataFixtureIdentifierWithForcedArchiveUpdateInput;
  where: MetadataFixtureIdentifierWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataFormatListUpdateInput = {
  data: MetadataFormatWithForcedArchiveUpdateInput;
  where: MetadataFormatWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataGpsLatitudeListUpdateInput = {
  data: MetadataGpsLatitudeWithForcedArchiveUpdateInput;
  where: MetadataGpsLatitudeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataGpsLongitudeListUpdateInput = {
  data: MetadataGpsLongitudeWithForcedArchiveUpdateInput;
  where: MetadataGpsLongitudeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataGpsProcessingMethodListUpdateInput = {
  data: MetadataGpsProcessingMethodWithForcedArchiveUpdateInput;
  where: MetadataGpsProcessingMethodWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataHeaderListUpdateInput = {
  data: MetadataHeaderWithForcedArchiveUpdateInput;
  where: MetadataHeaderWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataHeaderPrismaListUpdateInput = {
  data: MetadataHeaderPrismaWithForcedArchiveUpdateInput;
  where: MetadataHeaderPrismaWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataHeadlineListUpdateInput = {
  data: MetadataHeadlineWithForcedArchiveUpdateInput;
  where: MetadataHeadlineWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataHistoryListUpdateInput = {
  data: MetadataHistoryWithForcedArchiveUpdateInput;
  where: MetadataHistoryWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataIccProfileListUpdateInput = {
  data: MetadataIccProfileWithForcedArchiveUpdateInput;
  where: MetadataIccProfileWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataIdAssignmentListUpdateInput = {
  data: MetadataIdAssignmentWithForcedArchiveUpdateInput;
  where: MetadataIdAssignmentWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataIdMediaContentListUpdateInput = {
  data: MetadataIdMediaContentWithForcedArchiveUpdateInput;
  where: MetadataIdMediaContentWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataIdPhotoPrismaListUpdateInput = {
  data: MetadataIdPhotoPrismaWithForcedArchiveUpdateInput;
  where: MetadataIdPhotoPrismaWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataIdSubjectListUpdateInput = {
  data: MetadataIdSubjectWithForcedArchiveUpdateInput;
  where: MetadataIdSubjectWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataImageNotesListUpdateInput = {
  data: MetadataImageNotesWithForcedArchiveUpdateInput;
  where: MetadataImageNotesWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataImageUniqueIdListUpdateInput = {
  data: MetadataImageUniqueIdWithForcedArchiveUpdateInput;
  where: MetadataImageUniqueIdWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataInstructionsListUpdateInput = {
  data: MetadataInstructionsWithForcedArchiveUpdateInput;
  where: MetadataInstructionsWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataIntellectualGenreListUpdateInput = {
  data: MetadataIntellectualGenreWithForcedArchiveUpdateInput;
  where: MetadataIntellectualGenreWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataIsoSpeedRatingsListUpdateInput = {
  data: MetadataIsoSpeedRatingsWithForcedArchiveUpdateInput;
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataIssueListUpdateInput = {
  data: MetadataIssueWithForcedArchiveUpdateInput;
  where: MetadataIssueWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataJobIdListUpdateInput = {
  data: MetadataJobIdWithForcedArchiveUpdateInput;
  where: MetadataJobIdWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataJobProcessingListUpdateInput = {
  data: MetadataJobProcessingWithForcedArchiveUpdateInput;
  where: MetadataJobProcessingWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataLayoutPictureEditingListUpdateInput = {
  data: MetadataLayoutPictureEditingWithForcedArchiveUpdateInput;
  where: MetadataLayoutPictureEditingWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataLayoutStorageListUpdateInput = {
  data: MetadataLayoutStorageWithForcedArchiveUpdateInput;
  where: MetadataLayoutStorageWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataLicenseListUpdateInput = {
  data: MetadataLicenseWithForcedArchiveUpdateInput;
  where: MetadataLicenseWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataLinksListUpdateInput = {
  data: MetadataLinksWithForcedArchiveUpdateInput;
  where: MetadataLinksWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataLocalCaptionListUpdateInput = {
  data: MetadataLocalCaptionWithForcedArchiveUpdateInput;
  where: MetadataLocalCaptionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataLocationListUpdateInput = {
  data: MetadataLocationWithForcedArchiveUpdateInput;
  where: MetadataLocationWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataMakeListUpdateInput = {
  data: MetadataMakeWithForcedArchiveUpdateInput;
  where: MetadataMakeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataManifestListUpdateInput = {
  data: MetadataManifestWithForcedArchiveUpdateInput;
  where: MetadataManifestWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataMarkedListUpdateInput = {
  data: MetadataMarkedWithForcedArchiveUpdateInput;
  where: MetadataMarkedWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataMasterDocumentIdListUpdateInput = {
  data: MetadataMasterDocumentIdWithForcedArchiveUpdateInput;
  where: MetadataMasterDocumentIdWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataMaxAvailHeightListUpdateInput = {
  data: MetadataMaxAvailHeightWithForcedArchiveUpdateInput;
  where: MetadataMaxAvailHeightWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataMaxAvailWidthListUpdateInput = {
  data: MetadataMaxAvailWidthWithForcedArchiveUpdateInput;
  where: MetadataMaxAvailWidthWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataMinorModelAgeDisclosureListUpdateInput = {
  data: MetadataMinorModelAgeDisclosureWithForcedArchiveUpdateInput;
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataModelAgeListUpdateInput = {
  data: MetadataModelAgeWithForcedArchiveUpdateInput;
  where: MetadataModelAgeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataModelListUpdateInput = {
  data: MetadataModelWithForcedArchiveUpdateInput;
  where: MetadataModelWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataModelReleaseIdListUpdateInput = {
  data: MetadataModelReleaseIdWithForcedArchiveUpdateInput;
  where: MetadataModelReleaseIdWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataModelReleaseStatusListUpdateInput = {
  data: MetadataModelReleaseStatusWithForcedArchiveUpdateInput;
  where: MetadataModelReleaseStatusWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataMorePermissionsListUpdateInput = {
  data: MetadataMorePermissionsWithForcedArchiveUpdateInput;
  where: MetadataMorePermissionsWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataObjectCycleListUpdateInput = {
  data: MetadataObjectCycleWithForcedArchiveUpdateInput;
  where: MetadataObjectCycleWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataOrganisationInImageNameListUpdateInput = {
  data: MetadataOrganisationInImageNameWithForcedArchiveUpdateInput;
  where: MetadataOrganisationInImageNameWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataOriginalColorListUpdateInput = {
  data: MetadataOriginalColorWithForcedArchiveUpdateInput;
  where: MetadataOriginalColorWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataOriginalIccProfileListUpdateInput = {
  data: MetadataOriginalIccProfileWithForcedArchiveUpdateInput;
  where: MetadataOriginalIccProfileWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataOriginalNameListUpdateInput = {
  data: MetadataOriginalNameWithForcedArchiveUpdateInput;
  where: MetadataOriginalNameWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataOriginalWeigthListUpdateInput = {
  data: MetadataOriginalWeigthWithForcedArchiveUpdateInput;
  where: MetadataOriginalWeigthWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataOwnerIdListUpdateInput = {
  data: MetadataOwnerIdWithForcedArchiveUpdateInput;
  where: MetadataOwnerIdWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataOwnerListUpdateInput = {
  data: MetadataOwnerWithForcedArchiveUpdateInput;
  where: MetadataOwnerWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPageListUpdateInput = {
  data: MetadataPageWithForcedArchiveUpdateInput;
  where: MetadataPageWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPagePrismaListUpdateInput = {
  data: MetadataPagePrismaWithForcedArchiveUpdateInput;
  where: MetadataPagePrismaWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPaintBasedCorrectionsListUpdateInput = {
  data: MetadataPaintBasedCorrectionsWithForcedArchiveUpdateInput;
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPatientDobListUpdateInput = {
  data: MetadataPatientDobWithForcedArchiveUpdateInput;
  where: MetadataPatientDobWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPatientIdListUpdateInput = {
  data: MetadataPatientIdWithForcedArchiveUpdateInput;
  where: MetadataPatientIdWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPatientNameListUpdateInput = {
  data: MetadataPatientNameWithForcedArchiveUpdateInput;
  where: MetadataPatientNameWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPatientSexListUpdateInput = {
  data: MetadataPatientSexWithForcedArchiveUpdateInput;
  where: MetadataPatientSexWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPersonInImageListUpdateInput = {
  data: MetadataPersonInImageWithForcedArchiveUpdateInput;
  where: MetadataPersonInImageWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPhotoStorageListUpdateInput = {
  data: MetadataPhotoStorageWithForcedArchiveUpdateInput;
  where: MetadataPhotoStorageWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPrepressPictureEditingListUpdateInput = {
  data: MetadataPrepressPictureEditingWithForcedArchiveUpdateInput;
  where: MetadataPrepressPictureEditingWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPriceLevelListUpdateInput = {
  data: MetadataPriceLevelWithForcedArchiveUpdateInput;
  where: MetadataPriceLevelWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPrintingProfileListUpdateInput = {
  data: MetadataPrintingProfileWithForcedArchiveUpdateInput;
  where: MetadataPrintingProfileWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPrismaProductionListUpdateInput = {
  data: MetadataPrismaProductionWithForcedArchiveUpdateInput;
  where: MetadataPrismaProductionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataProcessHistoryListUpdateInput = {
  data: MetadataProcessHistoryWithForcedArchiveUpdateInput;
  where: MetadataProcessHistoryWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataProcessParameterListUpdateInput = {
  data: MetadataProcessParameterWithForcedArchiveUpdateInput;
  where: MetadataProcessParameterWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataProcessStatusListUpdateInput = {
  data: MetadataProcessStatusWithForcedArchiveUpdateInput;
  where: MetadataProcessStatusWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataProductListUpdateInput = {
  data: MetadataProductWithForcedArchiveUpdateInput;
  where: MetadataProductWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataProductShortNameListUpdateInput = {
  data: MetadataProductShortNameWithForcedArchiveUpdateInput;
  where: MetadataProductShortNameWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataProductsListUpdateInput = {
  data: MetadataProductsWithForcedArchiveUpdateInput;
  where: MetadataProductsWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataProduitsListUpdateInput = {
  data: MetadataProduitsWithForcedArchiveUpdateInput;
  where: MetadataProduitsWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataProgramVersionListUpdateInput = {
  data: MetadataProgramVersionWithForcedArchiveUpdateInput;
  where: MetadataProgramVersionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPropertyReleaseIdListUpdateInput = {
  data: MetadataPropertyReleaseIdWithForcedArchiveUpdateInput;
  where: MetadataPropertyReleaseIdWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPropertyReleaseStatusListUpdateInput = {
  data: MetadataPropertyReleaseStatusWithForcedArchiveUpdateInput;
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPublisherListUpdateInput = {
  data: MetadataPublisherWithForcedArchiveUpdateInput;
  where: MetadataPublisherWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPublishingIssueListUpdateInput = {
  data: MetadataPublishingIssueWithForcedArchiveUpdateInput;
  where: MetadataPublishingIssueWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataPublishingSubjectListUpdateInput = {
  data: MetadataPublishingSubjectWithForcedArchiveUpdateInput;
  where: MetadataPublishingSubjectWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataQualifiedUsePrismaByListUpdateInput = {
  data: MetadataQualifiedUsePrismaByWithForcedArchiveUpdateInput;
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataQualifiedUsePrismaDateListUpdateInput = {
  data: MetadataQualifiedUsePrismaDateWithForcedArchiveUpdateInput;
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataQualifiedUsePrismaDurationListUpdateInput = {
  data: MetadataQualifiedUsePrismaDurationWithForcedArchiveUpdateInput;
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataQualifiedUsePrismaSupportListUpdateInput = {
  data: MetadataQualifiedUsePrismaSupportWithForcedArchiveUpdateInput;
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataRatingListUpdateInput = {
  data: MetadataRatingWithForcedArchiveUpdateInput;
  where: MetadataRatingWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataReferenceDateListUpdateInput = {
  data: MetadataReferenceDateWithForcedArchiveUpdateInput;
  where: MetadataReferenceDateWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataReferenceNumberListUpdateInput = {
  data: MetadataReferenceNumberWithForcedArchiveUpdateInput;
  where: MetadataReferenceNumberWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataReferenceServiceListUpdateInput = {
  data: MetadataReferenceServiceWithForcedArchiveUpdateInput;
  where: MetadataReferenceServiceWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataReleaseDateListUpdateInput = {
  data: MetadataReleaseDateWithForcedArchiveUpdateInput;
  where: MetadataReleaseDateWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataReleaseTimeListUpdateInput = {
  data: MetadataReleaseTimeWithForcedArchiveUpdateInput;
  where: MetadataReleaseTimeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataRequiredPermissionListUpdateInput = {
  data: MetadataRequiredPermissionWithForcedArchiveUpdateInput;
  where: MetadataRequiredPermissionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataResolutionKindListUpdateInput = {
  data: MetadataResolutionKindWithForcedArchiveUpdateInput;
  where: MetadataResolutionKindWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataRightsListUpdateInput = {
  data: MetadataRightsWithForcedArchiveUpdateInput;
  where: MetadataRightsWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataRoyaltyfreeListUpdateInput = {
  data: MetadataRoyaltyfreeWithForcedArchiveUpdateInput;
  where: MetadataRoyaltyfreeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSceneListUpdateInput = {
  data: MetadataSceneWithForcedArchiveUpdateInput;
  where: MetadataSceneWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSectionListUpdateInput = {
  data: MetadataSectionWithForcedArchiveUpdateInput;
  where: MetadataSectionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSentToListUpdateInput = {
  data: MetadataSentToWithForcedArchiveUpdateInput;
  where: MetadataSentToWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSerialNumberListUpdateInput = {
  data: MetadataSerialNumberWithForcedArchiveUpdateInput;
  where: MetadataSerialNumberWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSeriesDateTimeListUpdateInput = {
  data: MetadataSeriesDateTimeWithForcedArchiveUpdateInput;
  where: MetadataSeriesDateTimeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSeriesDescriptionListUpdateInput = {
  data: MetadataSeriesDescriptionWithForcedArchiveUpdateInput;
  where: MetadataSeriesDescriptionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSeriesModalityListUpdateInput = {
  data: MetadataSeriesModalityWithForcedArchiveUpdateInput;
  where: MetadataSeriesModalityWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSeriesNumberListUpdateInput = {
  data: MetadataSeriesNumberWithForcedArchiveUpdateInput;
  where: MetadataSeriesNumberWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataShortUniqueIdListUpdateInput = {
  data: MetadataShortUniqueIdWithForcedArchiveUpdateInput;
  where: MetadataShortUniqueIdWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSourceListUpdateInput = {
  data: MetadataSourceWithForcedArchiveUpdateInput;
  where: MetadataSourceWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataStateListUpdateInput = {
  data: MetadataStateWithForcedArchiveUpdateInput;
  where: MetadataStateWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataStudyDateTimeListUpdateInput = {
  data: MetadataStudyDateTimeWithForcedArchiveUpdateInput;
  where: MetadataStudyDateTimeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataStudyDescriptionListUpdateInput = {
  data: MetadataStudyDescriptionWithForcedArchiveUpdateInput;
  where: MetadataStudyDescriptionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataStudyIdListUpdateInput = {
  data: MetadataStudyIdWithForcedArchiveUpdateInput;
  where: MetadataStudyIdWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataStudyPhysicianListUpdateInput = {
  data: MetadataStudyPhysicianWithForcedArchiveUpdateInput;
  where: MetadataStudyPhysicianWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSubjectCodeListUpdateInput = {
  data: MetadataSubjectCodeWithForcedArchiveUpdateInput;
  where: MetadataSubjectCodeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSubjectListUpdateInput = {
  data: MetadataSubjectWithForcedArchiveUpdateInput;
  where: MetadataSubjectWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSubjectPrismaListUpdateInput = {
  data: MetadataSubjectPrismaWithForcedArchiveUpdateInput;
  where: MetadataSubjectPrismaWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataSupplementalCategoriesListUpdateInput = {
  data: MetadataSupplementalCategoriesWithForcedArchiveUpdateInput;
  where: MetadataSupplementalCategoriesWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataTargetVersionListUpdateInput = {
  data: MetadataTargetVersionWithForcedArchiveUpdateInput;
  where: MetadataTargetVersionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataTitleListUpdateInput = {
  data: MetadataTitleWithForcedArchiveUpdateInput;
  where: MetadataTitleWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataToneCurveListUpdateInput = {
  data: MetadataToneCurveWithForcedArchiveUpdateInput;
  where: MetadataToneCurveWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataTransferredByEmailListUpdateInput = {
  data: MetadataTransferredByEmailWithForcedArchiveUpdateInput;
  where: MetadataTransferredByEmailWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataTransferredByFullNameListUpdateInput = {
  data: MetadataTransferredByFullNameWithForcedArchiveUpdateInput;
  where: MetadataTransferredByFullNameWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataTransferredByListUpdateInput = {
  data: MetadataTransferredByWithForcedArchiveUpdateInput;
  where: MetadataTransferredByWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataTransmissionReferenceListUpdateInput = {
  data: MetadataTransmissionReferenceWithForcedArchiveUpdateInput;
  where: MetadataTransmissionReferenceWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataTypeListUpdateInput = {
  data: MetadataTypeWithForcedArchiveUpdateInput;
  where: MetadataTypeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUndersubjectListUpdateInput = {
  data: MetadataUndersubjectWithForcedArchiveUpdateInput;
  where: MetadataUndersubjectWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUnderUnderSubjectListUpdateInput = {
  data: MetadataUnderUnderSubjectWithForcedArchiveUpdateInput;
  where: MetadataUnderUnderSubjectWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUniqueIdListUpdateInput = {
  data: MetadataUniqueIdWithForcedArchiveUpdateInput;
  where: MetadataUniqueIdWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUnitListUpdateInput = {
  data: MetadataUnitWithForcedArchiveUpdateInput;
  where: MetadataUnitWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUnitShortNameListUpdateInput = {
  data: MetadataUnitShortNameWithForcedArchiveUpdateInput;
  where: MetadataUnitShortNameWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUploadedByFullNameListUpdateInput = {
  data: MetadataUploadedByFullNameWithForcedArchiveUpdateInput;
  where: MetadataUploadedByFullNameWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUploadedByListUpdateInput = {
  data: MetadataUploadedByWithForcedArchiveUpdateInput;
  where: MetadataUploadedByWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUploadTimeListUpdateInput = {
  data: MetadataUploadTimeWithForcedArchiveUpdateInput;
  where: MetadataUploadTimeWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUrgencyListUpdateInput = {
  data: MetadataUrgencyWithForcedArchiveUpdateInput;
  where: MetadataUrgencyWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUsageTermsListUpdateInput = {
  data: MetadataUsageTermsWithForcedArchiveUpdateInput;
  where: MetadataUsageTermsWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUserCommentListUpdateInput = {
  data: MetadataUserCommentWithForcedArchiveUpdateInput;
  where: MetadataUserCommentWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUserDefined195ListUpdateInput = {
  data: MetadataUserDefined195WithForcedArchiveUpdateInput;
  where: MetadataUserDefined195WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUserDefined237ListUpdateInput = {
  data: MetadataUserDefined237WithForcedArchiveUpdateInput;
  where: MetadataUserDefined237WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUserDefined238ListUpdateInput = {
  data: MetadataUserDefined238WithForcedArchiveUpdateInput;
  where: MetadataUserDefined238WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUserDefined239ListUpdateInput = {
  data: MetadataUserDefined239WithForcedArchiveUpdateInput;
  where: MetadataUserDefined239WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUserDefined242ListUpdateInput = {
  data: MetadataUserDefined242WithForcedArchiveUpdateInput;
  where: MetadataUserDefined242WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataUserDefined62ListUpdateInput = {
  data: MetadataUserDefined62WithForcedArchiveUpdateInput;
  where: MetadataUserDefined62WhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataValidListUpdateInput = {
  data: MetadataValidWithForcedArchiveUpdateInput;
  where: MetadataValidWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataVersionListUpdateInput = {
  data: MetadataVersionWithForcedArchiveUpdateInput;
  where: MetadataVersionWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataWebStatementListUpdateInput = {
  data: MetadataWebStatementWithForcedArchiveUpdateInput;
  where: MetadataWebStatementWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataWorkflowKindListUpdateInput = {
  data: MetadataWorkflowKindWithForcedArchiveUpdateInput;
  where: MetadataWorkflowKindWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataXmpFileStampsListUpdateInput = {
  data: MetadataXmpFileStampsWithForcedArchiveUpdateInput;
  where: MetadataXmpFileStampsWhereUniqueInput;
};

export type ArchiveNestedUpdateMetadataXmpHistoryListUpdateInput = {
  data: MetadataXmpHistoryWithForcedArchiveUpdateInput;
  where: MetadataXmpHistoryWhereUniqueInput;
};

export type ArchiveNestedUpdatePhotoMetadatasUpdateInput = {
  data: PhotoMetadataWithForcedArchiveUpdateInput;
  where: PhotoMetadataWhereUniqueInput;
};

export type ArchiveNestedUpdatePhotosUpdateInput = {
  data: PhotoWithForcedArchiveUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type ArchiveNestedUpsertArchiveAgenciesUpdateInput = {
  create: ArchiveAgencyWithForcedArchiveCreateInput;
  update: ArchiveAgencyWithForcedArchiveUpdateInput;
  where: ArchiveAgencyWithOptionalArchiveWhereUniqueInput;
};

export type ArchiveNestedUpsertCategoriesUpdateInput = {
  create: CategoryWithForcedArchiveCreateInput;
  update: CategoryWithForcedArchiveUpdateInput;
  where: CategoryWithOptionalArchiveWhereUniqueInput;
};

export type ArchiveNestedUpsertDeliverySubjectsUpdateInput = {
  create: DeliverySubjectWithForcedArchiveCreateInput;
  update: DeliverySubjectWithForcedArchiveUpdateInput;
  where: DeliverySubjectWithOptionalArchiveWhereUniqueInput;
};

export type ArchiveNestedUpsertGcImportSettingUpdateInput = {
  create: GcImportSettingWithForcedArchiveCreateInput;
  update: GcImportSettingWithForcedArchiveUpdateInput;
  where: GcImportSettingWithOptionalArchiveWhereUniqueInput;
};

export type ArchiveNestedUpsertImportQueuesUpdateInput = {
  create: ImportQueueWithForcedArchiveCreateInput;
  update: ImportQueueWithForcedArchiveUpdateInput;
  where: ImportQueueWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataAccessRightsListUpdateInput = {
  create: MetadataAccessRightsWithForcedArchiveCreateInput;
  update: MetadataAccessRightsWithForcedArchiveUpdateInput;
  where: MetadataAccessRightsWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataAddlModelInfoListUpdateInput = {
  create: MetadataAddlModelInfoWithForcedArchiveCreateInput;
  update: MetadataAddlModelInfoWithForcedArchiveUpdateInput;
  where: MetadataAddlModelInfoWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataArchiveChildListUpdateInput = {
  create: MetadataArchiveChildWithForcedArchiveCreateInput;
  update: MetadataArchiveChildWithForcedArchiveUpdateInput;
  where: MetadataArchiveChildWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataArchiveParentListUpdateInput = {
  create: MetadataArchiveParentWithForcedArchiveCreateInput;
  update: MetadataArchiveParentWithForcedArchiveUpdateInput;
  where: MetadataArchiveParentWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataAssetStateListUpdateInput = {
  create: MetadataAssetStateWithForcedArchiveCreateInput;
  update: MetadataAssetStateWithForcedArchiveUpdateInput;
  where: MetadataAssetStateWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataAttributionNameListUpdateInput = {
  create: MetadataAttributionNameWithForcedArchiveCreateInput;
  update: MetadataAttributionNameWithForcedArchiveUpdateInput;
  where: MetadataAttributionNameWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataAttributionUrlListUpdateInput = {
  create: MetadataAttributionUrlWithForcedArchiveCreateInput;
  update: MetadataAttributionUrlWithForcedArchiveUpdateInput;
  where: MetadataAttributionUrlWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataAudienceListUpdateInput = {
  create: MetadataAudienceWithForcedArchiveCreateInput;
  update: MetadataAudienceWithForcedArchiveUpdateInput;
  where: MetadataAudienceWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataAuthorsPositionListUpdateInput = {
  create: MetadataAuthorsPositionWithForcedArchiveCreateInput;
  update: MetadataAuthorsPositionWithForcedArchiveUpdateInput;
  where: MetadataAuthorsPositionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataBackupNameListUpdateInput = {
  create: MetadataBackupNameWithForcedArchiveCreateInput;
  update: MetadataBackupNameWithForcedArchiveUpdateInput;
  where: MetadataBackupNameWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataBitmapGrayscalePictureListUpdateInput = {
  create: MetadataBitmapGrayscalePictureWithForcedArchiveCreateInput;
  update: MetadataBitmapGrayscalePictureWithForcedArchiveUpdateInput;
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataBrandListUpdateInput = {
  create: MetadataBrandWithForcedArchiveCreateInput;
  update: MetadataBrandWithForcedArchiveUpdateInput;
  where: MetadataBrandWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataBrandPrismaListUpdateInput = {
  create: MetadataBrandPrismaWithForcedArchiveCreateInput;
  update: MetadataBrandPrismaWithForcedArchiveUpdateInput;
  where: MetadataBrandPrismaWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataBrandShortNameListUpdateInput = {
  create: MetadataBrandShortNameWithForcedArchiveCreateInput;
  update: MetadataBrandShortNameWithForcedArchiveUpdateInput;
  where: MetadataBrandShortNameWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCaptionWriterListUpdateInput = {
  create: MetadataCaptionWriterWithForcedArchiveCreateInput;
  update: MetadataCaptionWriterWithForcedArchiveUpdateInput;
  where: MetadataCaptionWriterWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCategoryListUpdateInput = {
  create: MetadataCategoryWithForcedArchiveCreateInput;
  update: MetadataCategoryWithForcedArchiveUpdateInput;
  where: MetadataCategoryWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCategoryPrismaListUpdateInput = {
  create: MetadataCategoryPrismaWithForcedArchiveCreateInput;
  update: MetadataCategoryPrismaWithForcedArchiveUpdateInput;
  where: MetadataCategoryPrismaWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCertificateListUpdateInput = {
  create: MetadataCertificateWithForcedArchiveCreateInput;
  update: MetadataCertificateWithForcedArchiveUpdateInput;
  where: MetadataCertificateWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCiAdrCityListUpdateInput = {
  create: MetadataCiAdrCityWithForcedArchiveCreateInput;
  update: MetadataCiAdrCityWithForcedArchiveUpdateInput;
  where: MetadataCiAdrCityWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCiAdrCtryListUpdateInput = {
  create: MetadataCiAdrCtryWithForcedArchiveCreateInput;
  update: MetadataCiAdrCtryWithForcedArchiveUpdateInput;
  where: MetadataCiAdrCtryWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCiAdrExtadrListUpdateInput = {
  create: MetadataCiAdrExtadrWithForcedArchiveCreateInput;
  update: MetadataCiAdrExtadrWithForcedArchiveUpdateInput;
  where: MetadataCiAdrExtadrWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCiAdrPcodeListUpdateInput = {
  create: MetadataCiAdrPcodeWithForcedArchiveCreateInput;
  update: MetadataCiAdrPcodeWithForcedArchiveUpdateInput;
  where: MetadataCiAdrPcodeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCiAdrRegionListUpdateInput = {
  create: MetadataCiAdrRegionWithForcedArchiveCreateInput;
  update: MetadataCiAdrRegionWithForcedArchiveUpdateInput;
  where: MetadataCiAdrRegionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCiEmailWorkListUpdateInput = {
  create: MetadataCiEmailWorkWithForcedArchiveCreateInput;
  update: MetadataCiEmailWorkWithForcedArchiveUpdateInput;
  where: MetadataCiEmailWorkWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCiTelWorkListUpdateInput = {
  create: MetadataCiTelWorkWithForcedArchiveCreateInput;
  update: MetadataCiTelWorkWithForcedArchiveUpdateInput;
  where: MetadataCiTelWorkWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCityListUpdateInput = {
  create: MetadataCityWithForcedArchiveCreateInput;
  update: MetadataCityWithForcedArchiveUpdateInput;
  where: MetadataCityWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCiUrlWorkListUpdateInput = {
  create: MetadataCiUrlWorkWithForcedArchiveCreateInput;
  update: MetadataCiUrlWorkWithForcedArchiveUpdateInput;
  where: MetadataCiUrlWorkWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataClassifyListUpdateInput = {
  create: MetadataClassifyWithForcedArchiveCreateInput;
  update: MetadataClassifyWithForcedArchiveUpdateInput;
  where: MetadataClassifyWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCompanyListUpdateInput = {
  create: MetadataCompanyWithForcedArchiveCreateInput;
  update: MetadataCompanyWithForcedArchiveUpdateInput;
  where: MetadataCompanyWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCompanyShortNameListUpdateInput = {
  create: MetadataCompanyShortNameWithForcedArchiveCreateInput;
  update: MetadataCompanyShortNameWithForcedArchiveUpdateInput;
  where: MetadataCompanyShortNameWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataConfidentialityListUpdateInput = {
  create: MetadataConfidentialityWithForcedArchiveCreateInput;
  update: MetadataConfidentialityWithForcedArchiveUpdateInput;
  where: MetadataConfidentialityWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataContainedInListUpdateInput = {
  create: MetadataContainedInWithForcedArchiveCreateInput;
  update: MetadataContainedInWithForcedArchiveUpdateInput;
  where: MetadataContainedInWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataContentMediaKindListUpdateInput = {
  create: MetadataContentMediaKindWithForcedArchiveCreateInput;
  update: MetadataContentMediaKindWithForcedArchiveUpdateInput;
  where: MetadataContentMediaKindWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataContentValueListUpdateInput = {
  create: MetadataContentValueWithForcedArchiveCreateInput;
  update: MetadataContentValueWithForcedArchiveUpdateInput;
  where: MetadataContentValueWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCopyrightLayerListUpdateInput = {
  create: MetadataCopyrightLayerWithForcedArchiveCreateInput;
  update: MetadataCopyrightLayerWithForcedArchiveUpdateInput;
  where: MetadataCopyrightLayerWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCountryCodeListUpdateInput = {
  create: MetadataCountryCodeWithForcedArchiveCreateInput;
  update: MetadataCountryCodeWithForcedArchiveUpdateInput;
  where: MetadataCountryCodeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCountryListUpdateInput = {
  create: MetadataCountryWithForcedArchiveCreateInput;
  update: MetadataCountryWithForcedArchiveUpdateInput;
  where: MetadataCountryWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCreatedTimeListUpdateInput = {
  create: MetadataCreatedTimeWithForcedArchiveCreateInput;
  update: MetadataCreatedTimeWithForcedArchiveUpdateInput;
  where: MetadataCreatedTimeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCreatorListUpdateInput = {
  create: MetadataCreatorWithForcedArchiveCreateInput;
  update: MetadataCreatorWithForcedArchiveUpdateInput;
  where: MetadataCreatorWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCreatorToolListUpdateInput = {
  create: MetadataCreatorToolWithForcedArchiveCreateInput;
  update: MetadataCreatorToolWithForcedArchiveUpdateInput;
  where: MetadataCreatorToolWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCreditListUpdateInput = {
  create: MetadataCreditWithForcedArchiveCreateInput;
  update: MetadataCreditWithForcedArchiveUpdateInput;
  where: MetadataCreditWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCustomField14ListUpdateInput = {
  create: MetadataCustomField14WithForcedArchiveCreateInput;
  update: MetadataCustomField14WithForcedArchiveUpdateInput;
  where: MetadataCustomField14WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCustomField15ListUpdateInput = {
  create: MetadataCustomField15WithForcedArchiveCreateInput;
  update: MetadataCustomField15WithForcedArchiveUpdateInput;
  where: MetadataCustomField15WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCustomField16ListUpdateInput = {
  create: MetadataCustomField16WithForcedArchiveCreateInput;
  update: MetadataCustomField16WithForcedArchiveUpdateInput;
  where: MetadataCustomField16WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCustomField17ListUpdateInput = {
  create: MetadataCustomField17WithForcedArchiveCreateInput;
  update: MetadataCustomField17WithForcedArchiveUpdateInput;
  where: MetadataCustomField17WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCustomField20ListUpdateInput = {
  create: MetadataCustomField20WithForcedArchiveCreateInput;
  update: MetadataCustomField20WithForcedArchiveUpdateInput;
  where: MetadataCustomField20WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCustomField5ListUpdateInput = {
  create: MetadataCustomField5WithForcedArchiveCreateInput;
  update: MetadataCustomField5WithForcedArchiveUpdateInput;
  where: MetadataCustomField5WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCustomField6ListUpdateInput = {
  create: MetadataCustomField6WithForcedArchiveCreateInput;
  update: MetadataCustomField6WithForcedArchiveUpdateInput;
  where: MetadataCustomField6WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCustomField7ListUpdateInput = {
  create: MetadataCustomField7WithForcedArchiveCreateInput;
  update: MetadataCustomField7WithForcedArchiveUpdateInput;
  where: MetadataCustomField7WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataCustomField9ListUpdateInput = {
  create: MetadataCustomField9WithForcedArchiveCreateInput;
  update: MetadataCustomField9WithForcedArchiveUpdateInput;
  where: MetadataCustomField9WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDateCreatedListUpdateInput = {
  create: MetadataDateCreatedWithForcedArchiveCreateInput;
  update: MetadataDateCreatedWithForcedArchiveUpdateInput;
  where: MetadataDateCreatedWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDateTimeDigitizedListUpdateInput = {
  create: MetadataDateTimeDigitizedWithForcedArchiveCreateInput;
  update: MetadataDateTimeDigitizedWithForcedArchiveUpdateInput;
  where: MetadataDateTimeDigitizedWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDateTimeOriginalListUpdateInput = {
  create: MetadataDateTimeOriginalWithForcedArchiveCreateInput;
  update: MetadataDateTimeOriginalWithForcedArchiveUpdateInput;
  where: MetadataDateTimeOriginalWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryAccountListUpdateInput = {
  create: MetadataDeliveryAccountWithForcedArchiveCreateInput;
  update: MetadataDeliveryAccountWithForcedArchiveUpdateInput;
  where: MetadataDeliveryAccountWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryCompanyListUpdateInput = {
  create: MetadataDeliveryCompanyWithForcedArchiveCreateInput;
  update: MetadataDeliveryCompanyWithForcedArchiveUpdateInput;
  where: MetadataDeliveryCompanyWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryCopyrightListUpdateInput = {
  create: MetadataDeliveryCopyrightWithForcedArchiveCreateInput;
  update: MetadataDeliveryCopyrightWithForcedArchiveUpdateInput;
  where: MetadataDeliveryCopyrightWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryDateFolderListUpdateInput = {
  create: MetadataDeliveryDateFolderWithForcedArchiveCreateInput;
  update: MetadataDeliveryDateFolderWithForcedArchiveUpdateInput;
  where: MetadataDeliveryDateFolderWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryDateTimeListUpdateInput = {
  create: MetadataDeliveryDateTimeWithForcedArchiveCreateInput;
  update: MetadataDeliveryDateTimeWithForcedArchiveUpdateInput;
  where: MetadataDeliveryDateTimeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryDcCreatorListUpdateInput = {
  create: MetadataDeliveryDcCreatorWithForcedArchiveCreateInput;
  update: MetadataDeliveryDcCreatorWithForcedArchiveUpdateInput;
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryDcRightsListUpdateInput = {
  create: MetadataDeliveryDcRightsWithForcedArchiveCreateInput;
  update: MetadataDeliveryDcRightsWithForcedArchiveUpdateInput;
  where: MetadataDeliveryDcRightsWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryFileTypeListUpdateInput = {
  create: MetadataDeliveryFileTypeWithForcedArchiveCreateInput;
  update: MetadataDeliveryFileTypeWithForcedArchiveUpdateInput;
  where: MetadataDeliveryFileTypeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryFolderListUpdateInput = {
  create: MetadataDeliveryFolderWithForcedArchiveCreateInput;
  update: MetadataDeliveryFolderWithForcedArchiveUpdateInput;
  where: MetadataDeliveryFolderWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryKindListUpdateInput = {
  create: MetadataDeliveryKindWithForcedArchiveCreateInput;
  update: MetadataDeliveryKindWithForcedArchiveUpdateInput;
  where: MetadataDeliveryKindWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryPathListUpdateInput = {
  create: MetadataDeliveryPathWithForcedArchiveCreateInput;
  update: MetadataDeliveryPathWithForcedArchiveUpdateInput;
  where: MetadataDeliveryPathWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryPersonShownintheImageListUpdateInput = {
  create: MetadataDeliveryPersonShownintheImageWithForcedArchiveCreateInput;
  update: MetadataDeliveryPersonShownintheImageWithForcedArchiveUpdateInput;
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryPhotoshopCreditListUpdateInput = {
  create: MetadataDeliveryPhotoshopCreditWithForcedArchiveCreateInput;
  update: MetadataDeliveryPhotoshopCreditWithForcedArchiveUpdateInput;
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryPhotoshopSourceListUpdateInput = {
  create: MetadataDeliveryPhotoshopSourceWithForcedArchiveCreateInput;
  update: MetadataDeliveryPhotoshopSourceWithForcedArchiveUpdateInput;
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryServiceListUpdateInput = {
  create: MetadataDeliveryServiceWithForcedArchiveCreateInput;
  update: MetadataDeliveryServiceWithForcedArchiveUpdateInput;
  where: MetadataDeliveryServiceWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliverySubjectListUpdateInput = {
  create: MetadataDeliverySubjectWithForcedArchiveCreateInput;
  update: MetadataDeliverySubjectWithForcedArchiveUpdateInput;
  where: MetadataDeliverySubjectWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDeliveryUnderSubjectListUpdateInput = {
  create: MetadataDeliveryUnderSubjectWithForcedArchiveCreateInput;
  update: MetadataDeliveryUnderSubjectWithForcedArchiveUpdateInput;
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDepartmentListUpdateInput = {
  create: MetadataDepartmentWithForcedArchiveCreateInput;
  update: MetadataDepartmentWithForcedArchiveUpdateInput;
  where: MetadataDepartmentWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDescriptionListUpdateInput = {
  create: MetadataDescriptionWithForcedArchiveCreateInput;
  update: MetadataDescriptionWithForcedArchiveUpdateInput;
  where: MetadataDescriptionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDigitalAssetUrlListUpdateInput = {
  create: MetadataDigitalAssetUrlWithForcedArchiveCreateInput;
  update: MetadataDigitalAssetUrlWithForcedArchiveUpdateInput;
  where: MetadataDigitalAssetUrlWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDivisionListUpdateInput = {
  create: MetadataDivisionWithForcedArchiveCreateInput;
  update: MetadataDivisionWithForcedArchiveUpdateInput;
  where: MetadataDivisionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataDocumentTextListUpdateInput = {
  create: MetadataDocumentTextWithForcedArchiveCreateInput;
  update: MetadataDocumentTextWithForcedArchiveUpdateInput;
  where: MetadataDocumentTextWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataEditionListUpdateInput = {
  create: MetadataEditionWithForcedArchiveCreateInput;
  update: MetadataEditionWithForcedArchiveUpdateInput;
  where: MetadataEditionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataEditorialVersionListUpdateInput = {
  create: MetadataEditorialVersionWithForcedArchiveCreateInput;
  update: MetadataEditorialVersionWithForcedArchiveUpdateInput;
  where: MetadataEditorialVersionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataEditStatusListUpdateInput = {
  create: MetadataEditStatusWithForcedArchiveCreateInput;
  update: MetadataEditStatusWithForcedArchiveUpdateInput;
  where: MetadataEditStatusWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataEnvironnementPhotoListUpdateInput = {
  create: MetadataEnvironnementPhotoWithForcedArchiveCreateInput;
  update: MetadataEnvironnementPhotoWithForcedArchiveUpdateInput;
  where: MetadataEnvironnementPhotoWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataEquipmentInstitutionListUpdateInput = {
  create: MetadataEquipmentInstitutionWithForcedArchiveCreateInput;
  update: MetadataEquipmentInstitutionWithForcedArchiveUpdateInput;
  where: MetadataEquipmentInstitutionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataEquipmentManufacturerListUpdateInput = {
  create: MetadataEquipmentManufacturerWithForcedArchiveCreateInput;
  update: MetadataEquipmentManufacturerWithForcedArchiveUpdateInput;
  where: MetadataEquipmentManufacturerWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataEventListUpdateInput = {
  create: MetadataEventWithForcedArchiveCreateInput;
  update: MetadataEventWithForcedArchiveUpdateInput;
  where: MetadataEventWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataExifListUpdateInput = {
  create: MetadataExifWithForcedArchiveCreateInput;
  update: MetadataExifWithForcedArchiveUpdateInput;
  where: MetadataExifWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataFabStorageListUpdateInput = {
  create: MetadataFabStorageWithForcedArchiveCreateInput;
  update: MetadataFabStorageWithForcedArchiveUpdateInput;
  where: MetadataFabStorageWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataFileCheckForListUpdateInput = {
  create: MetadataFileCheckForWithForcedArchiveCreateInput;
  update: MetadataFileCheckForWithForcedArchiveUpdateInput;
  where: MetadataFileCheckForWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataFileInfoBitsPerPixelListUpdateInput = {
  create: MetadataFileInfoBitsPerPixelWithForcedArchiveCreateInput;
  update: MetadataFileInfoBitsPerPixelWithForcedArchiveUpdateInput;
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataFileInfoFramesListUpdateInput = {
  create: MetadataFileInfoFramesWithForcedArchiveCreateInput;
  update: MetadataFileInfoFramesWithForcedArchiveUpdateInput;
  where: MetadataFileInfoFramesWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataFileInfoPhotometricInterpretationListUpdateInput = {
  create: MetadataFileInfoPhotometricInterpretationWithForcedArchiveCreateInput;
  update: MetadataFileInfoPhotometricInterpretationWithForcedArchiveUpdateInput;
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataFileInfoTransferSyntaxListUpdateInput = {
  create: MetadataFileInfoTransferSyntaxWithForcedArchiveCreateInput;
  update: MetadataFileInfoTransferSyntaxWithForcedArchiveUpdateInput;
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataFileStageListUpdateInput = {
  create: MetadataFileStageWithForcedArchiveCreateInput;
  update: MetadataFileStageWithForcedArchiveUpdateInput;
  where: MetadataFileStageWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataFileStatusListUpdateInput = {
  create: MetadataFileStatusWithForcedArchiveCreateInput;
  update: MetadataFileStatusWithForcedArchiveUpdateInput;
  where: MetadataFileStatusWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataFileTypeListUpdateInput = {
  create: MetadataFileTypeWithForcedArchiveCreateInput;
  update: MetadataFileTypeWithForcedArchiveUpdateInput;
  where: MetadataFileTypeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataFixtureIdentifierListUpdateInput = {
  create: MetadataFixtureIdentifierWithForcedArchiveCreateInput;
  update: MetadataFixtureIdentifierWithForcedArchiveUpdateInput;
  where: MetadataFixtureIdentifierWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataFormatListUpdateInput = {
  create: MetadataFormatWithForcedArchiveCreateInput;
  update: MetadataFormatWithForcedArchiveUpdateInput;
  where: MetadataFormatWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataGpsLatitudeListUpdateInput = {
  create: MetadataGpsLatitudeWithForcedArchiveCreateInput;
  update: MetadataGpsLatitudeWithForcedArchiveUpdateInput;
  where: MetadataGpsLatitudeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataGpsLongitudeListUpdateInput = {
  create: MetadataGpsLongitudeWithForcedArchiveCreateInput;
  update: MetadataGpsLongitudeWithForcedArchiveUpdateInput;
  where: MetadataGpsLongitudeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataGpsProcessingMethodListUpdateInput = {
  create: MetadataGpsProcessingMethodWithForcedArchiveCreateInput;
  update: MetadataGpsProcessingMethodWithForcedArchiveUpdateInput;
  where: MetadataGpsProcessingMethodWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataHeaderListUpdateInput = {
  create: MetadataHeaderWithForcedArchiveCreateInput;
  update: MetadataHeaderWithForcedArchiveUpdateInput;
  where: MetadataHeaderWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataHeaderPrismaListUpdateInput = {
  create: MetadataHeaderPrismaWithForcedArchiveCreateInput;
  update: MetadataHeaderPrismaWithForcedArchiveUpdateInput;
  where: MetadataHeaderPrismaWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataHeadlineListUpdateInput = {
  create: MetadataHeadlineWithForcedArchiveCreateInput;
  update: MetadataHeadlineWithForcedArchiveUpdateInput;
  where: MetadataHeadlineWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataHistoryListUpdateInput = {
  create: MetadataHistoryWithForcedArchiveCreateInput;
  update: MetadataHistoryWithForcedArchiveUpdateInput;
  where: MetadataHistoryWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataIccProfileListUpdateInput = {
  create: MetadataIccProfileWithForcedArchiveCreateInput;
  update: MetadataIccProfileWithForcedArchiveUpdateInput;
  where: MetadataIccProfileWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataIdAssignmentListUpdateInput = {
  create: MetadataIdAssignmentWithForcedArchiveCreateInput;
  update: MetadataIdAssignmentWithForcedArchiveUpdateInput;
  where: MetadataIdAssignmentWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataIdMediaContentListUpdateInput = {
  create: MetadataIdMediaContentWithForcedArchiveCreateInput;
  update: MetadataIdMediaContentWithForcedArchiveUpdateInput;
  where: MetadataIdMediaContentWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataIdPhotoPrismaListUpdateInput = {
  create: MetadataIdPhotoPrismaWithForcedArchiveCreateInput;
  update: MetadataIdPhotoPrismaWithForcedArchiveUpdateInput;
  where: MetadataIdPhotoPrismaWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataIdSubjectListUpdateInput = {
  create: MetadataIdSubjectWithForcedArchiveCreateInput;
  update: MetadataIdSubjectWithForcedArchiveUpdateInput;
  where: MetadataIdSubjectWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataImageNotesListUpdateInput = {
  create: MetadataImageNotesWithForcedArchiveCreateInput;
  update: MetadataImageNotesWithForcedArchiveUpdateInput;
  where: MetadataImageNotesWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataImageUniqueIdListUpdateInput = {
  create: MetadataImageUniqueIdWithForcedArchiveCreateInput;
  update: MetadataImageUniqueIdWithForcedArchiveUpdateInput;
  where: MetadataImageUniqueIdWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataInstructionsListUpdateInput = {
  create: MetadataInstructionsWithForcedArchiveCreateInput;
  update: MetadataInstructionsWithForcedArchiveUpdateInput;
  where: MetadataInstructionsWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataIntellectualGenreListUpdateInput = {
  create: MetadataIntellectualGenreWithForcedArchiveCreateInput;
  update: MetadataIntellectualGenreWithForcedArchiveUpdateInput;
  where: MetadataIntellectualGenreWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataIsoSpeedRatingsListUpdateInput = {
  create: MetadataIsoSpeedRatingsWithForcedArchiveCreateInput;
  update: MetadataIsoSpeedRatingsWithForcedArchiveUpdateInput;
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataIssueListUpdateInput = {
  create: MetadataIssueWithForcedArchiveCreateInput;
  update: MetadataIssueWithForcedArchiveUpdateInput;
  where: MetadataIssueWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataJobIdListUpdateInput = {
  create: MetadataJobIdWithForcedArchiveCreateInput;
  update: MetadataJobIdWithForcedArchiveUpdateInput;
  where: MetadataJobIdWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataJobProcessingListUpdateInput = {
  create: MetadataJobProcessingWithForcedArchiveCreateInput;
  update: MetadataJobProcessingWithForcedArchiveUpdateInput;
  where: MetadataJobProcessingWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataLayoutPictureEditingListUpdateInput = {
  create: MetadataLayoutPictureEditingWithForcedArchiveCreateInput;
  update: MetadataLayoutPictureEditingWithForcedArchiveUpdateInput;
  where: MetadataLayoutPictureEditingWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataLayoutStorageListUpdateInput = {
  create: MetadataLayoutStorageWithForcedArchiveCreateInput;
  update: MetadataLayoutStorageWithForcedArchiveUpdateInput;
  where: MetadataLayoutStorageWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataLicenseListUpdateInput = {
  create: MetadataLicenseWithForcedArchiveCreateInput;
  update: MetadataLicenseWithForcedArchiveUpdateInput;
  where: MetadataLicenseWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataLinksListUpdateInput = {
  create: MetadataLinksWithForcedArchiveCreateInput;
  update: MetadataLinksWithForcedArchiveUpdateInput;
  where: MetadataLinksWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataLocalCaptionListUpdateInput = {
  create: MetadataLocalCaptionWithForcedArchiveCreateInput;
  update: MetadataLocalCaptionWithForcedArchiveUpdateInput;
  where: MetadataLocalCaptionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataLocationListUpdateInput = {
  create: MetadataLocationWithForcedArchiveCreateInput;
  update: MetadataLocationWithForcedArchiveUpdateInput;
  where: MetadataLocationWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataMakeListUpdateInput = {
  create: MetadataMakeWithForcedArchiveCreateInput;
  update: MetadataMakeWithForcedArchiveUpdateInput;
  where: MetadataMakeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataManifestListUpdateInput = {
  create: MetadataManifestWithForcedArchiveCreateInput;
  update: MetadataManifestWithForcedArchiveUpdateInput;
  where: MetadataManifestWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataMarkedListUpdateInput = {
  create: MetadataMarkedWithForcedArchiveCreateInput;
  update: MetadataMarkedWithForcedArchiveUpdateInput;
  where: MetadataMarkedWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataMasterDocumentIdListUpdateInput = {
  create: MetadataMasterDocumentIdWithForcedArchiveCreateInput;
  update: MetadataMasterDocumentIdWithForcedArchiveUpdateInput;
  where: MetadataMasterDocumentIdWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataMaxAvailHeightListUpdateInput = {
  create: MetadataMaxAvailHeightWithForcedArchiveCreateInput;
  update: MetadataMaxAvailHeightWithForcedArchiveUpdateInput;
  where: MetadataMaxAvailHeightWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataMaxAvailWidthListUpdateInput = {
  create: MetadataMaxAvailWidthWithForcedArchiveCreateInput;
  update: MetadataMaxAvailWidthWithForcedArchiveUpdateInput;
  where: MetadataMaxAvailWidthWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataMinorModelAgeDisclosureListUpdateInput = {
  create: MetadataMinorModelAgeDisclosureWithForcedArchiveCreateInput;
  update: MetadataMinorModelAgeDisclosureWithForcedArchiveUpdateInput;
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataModelAgeListUpdateInput = {
  create: MetadataModelAgeWithForcedArchiveCreateInput;
  update: MetadataModelAgeWithForcedArchiveUpdateInput;
  where: MetadataModelAgeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataModelListUpdateInput = {
  create: MetadataModelWithForcedArchiveCreateInput;
  update: MetadataModelWithForcedArchiveUpdateInput;
  where: MetadataModelWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataModelReleaseIdListUpdateInput = {
  create: MetadataModelReleaseIdWithForcedArchiveCreateInput;
  update: MetadataModelReleaseIdWithForcedArchiveUpdateInput;
  where: MetadataModelReleaseIdWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataModelReleaseStatusListUpdateInput = {
  create: MetadataModelReleaseStatusWithForcedArchiveCreateInput;
  update: MetadataModelReleaseStatusWithForcedArchiveUpdateInput;
  where: MetadataModelReleaseStatusWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataMorePermissionsListUpdateInput = {
  create: MetadataMorePermissionsWithForcedArchiveCreateInput;
  update: MetadataMorePermissionsWithForcedArchiveUpdateInput;
  where: MetadataMorePermissionsWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataObjectCycleListUpdateInput = {
  create: MetadataObjectCycleWithForcedArchiveCreateInput;
  update: MetadataObjectCycleWithForcedArchiveUpdateInput;
  where: MetadataObjectCycleWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataOrganisationInImageNameListUpdateInput = {
  create: MetadataOrganisationInImageNameWithForcedArchiveCreateInput;
  update: MetadataOrganisationInImageNameWithForcedArchiveUpdateInput;
  where: MetadataOrganisationInImageNameWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataOriginalColorListUpdateInput = {
  create: MetadataOriginalColorWithForcedArchiveCreateInput;
  update: MetadataOriginalColorWithForcedArchiveUpdateInput;
  where: MetadataOriginalColorWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataOriginalIccProfileListUpdateInput = {
  create: MetadataOriginalIccProfileWithForcedArchiveCreateInput;
  update: MetadataOriginalIccProfileWithForcedArchiveUpdateInput;
  where: MetadataOriginalIccProfileWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataOriginalNameListUpdateInput = {
  create: MetadataOriginalNameWithForcedArchiveCreateInput;
  update: MetadataOriginalNameWithForcedArchiveUpdateInput;
  where: MetadataOriginalNameWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataOriginalWeigthListUpdateInput = {
  create: MetadataOriginalWeigthWithForcedArchiveCreateInput;
  update: MetadataOriginalWeigthWithForcedArchiveUpdateInput;
  where: MetadataOriginalWeigthWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataOwnerIdListUpdateInput = {
  create: MetadataOwnerIdWithForcedArchiveCreateInput;
  update: MetadataOwnerIdWithForcedArchiveUpdateInput;
  where: MetadataOwnerIdWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataOwnerListUpdateInput = {
  create: MetadataOwnerWithForcedArchiveCreateInput;
  update: MetadataOwnerWithForcedArchiveUpdateInput;
  where: MetadataOwnerWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPageListUpdateInput = {
  create: MetadataPageWithForcedArchiveCreateInput;
  update: MetadataPageWithForcedArchiveUpdateInput;
  where: MetadataPageWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPagePrismaListUpdateInput = {
  create: MetadataPagePrismaWithForcedArchiveCreateInput;
  update: MetadataPagePrismaWithForcedArchiveUpdateInput;
  where: MetadataPagePrismaWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPaintBasedCorrectionsListUpdateInput = {
  create: MetadataPaintBasedCorrectionsWithForcedArchiveCreateInput;
  update: MetadataPaintBasedCorrectionsWithForcedArchiveUpdateInput;
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPatientDobListUpdateInput = {
  create: MetadataPatientDobWithForcedArchiveCreateInput;
  update: MetadataPatientDobWithForcedArchiveUpdateInput;
  where: MetadataPatientDobWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPatientIdListUpdateInput = {
  create: MetadataPatientIdWithForcedArchiveCreateInput;
  update: MetadataPatientIdWithForcedArchiveUpdateInput;
  where: MetadataPatientIdWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPatientNameListUpdateInput = {
  create: MetadataPatientNameWithForcedArchiveCreateInput;
  update: MetadataPatientNameWithForcedArchiveUpdateInput;
  where: MetadataPatientNameWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPatientSexListUpdateInput = {
  create: MetadataPatientSexWithForcedArchiveCreateInput;
  update: MetadataPatientSexWithForcedArchiveUpdateInput;
  where: MetadataPatientSexWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPersonInImageListUpdateInput = {
  create: MetadataPersonInImageWithForcedArchiveCreateInput;
  update: MetadataPersonInImageWithForcedArchiveUpdateInput;
  where: MetadataPersonInImageWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPhotoStorageListUpdateInput = {
  create: MetadataPhotoStorageWithForcedArchiveCreateInput;
  update: MetadataPhotoStorageWithForcedArchiveUpdateInput;
  where: MetadataPhotoStorageWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPrepressPictureEditingListUpdateInput = {
  create: MetadataPrepressPictureEditingWithForcedArchiveCreateInput;
  update: MetadataPrepressPictureEditingWithForcedArchiveUpdateInput;
  where: MetadataPrepressPictureEditingWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPriceLevelListUpdateInput = {
  create: MetadataPriceLevelWithForcedArchiveCreateInput;
  update: MetadataPriceLevelWithForcedArchiveUpdateInput;
  where: MetadataPriceLevelWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPrintingProfileListUpdateInput = {
  create: MetadataPrintingProfileWithForcedArchiveCreateInput;
  update: MetadataPrintingProfileWithForcedArchiveUpdateInput;
  where: MetadataPrintingProfileWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPrismaProductionListUpdateInput = {
  create: MetadataPrismaProductionWithForcedArchiveCreateInput;
  update: MetadataPrismaProductionWithForcedArchiveUpdateInput;
  where: MetadataPrismaProductionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataProcessHistoryListUpdateInput = {
  create: MetadataProcessHistoryWithForcedArchiveCreateInput;
  update: MetadataProcessHistoryWithForcedArchiveUpdateInput;
  where: MetadataProcessHistoryWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataProcessParameterListUpdateInput = {
  create: MetadataProcessParameterWithForcedArchiveCreateInput;
  update: MetadataProcessParameterWithForcedArchiveUpdateInput;
  where: MetadataProcessParameterWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataProcessStatusListUpdateInput = {
  create: MetadataProcessStatusWithForcedArchiveCreateInput;
  update: MetadataProcessStatusWithForcedArchiveUpdateInput;
  where: MetadataProcessStatusWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataProductListUpdateInput = {
  create: MetadataProductWithForcedArchiveCreateInput;
  update: MetadataProductWithForcedArchiveUpdateInput;
  where: MetadataProductWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataProductShortNameListUpdateInput = {
  create: MetadataProductShortNameWithForcedArchiveCreateInput;
  update: MetadataProductShortNameWithForcedArchiveUpdateInput;
  where: MetadataProductShortNameWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataProductsListUpdateInput = {
  create: MetadataProductsWithForcedArchiveCreateInput;
  update: MetadataProductsWithForcedArchiveUpdateInput;
  where: MetadataProductsWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataProduitsListUpdateInput = {
  create: MetadataProduitsWithForcedArchiveCreateInput;
  update: MetadataProduitsWithForcedArchiveUpdateInput;
  where: MetadataProduitsWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataProgramVersionListUpdateInput = {
  create: MetadataProgramVersionWithForcedArchiveCreateInput;
  update: MetadataProgramVersionWithForcedArchiveUpdateInput;
  where: MetadataProgramVersionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPropertyReleaseIdListUpdateInput = {
  create: MetadataPropertyReleaseIdWithForcedArchiveCreateInput;
  update: MetadataPropertyReleaseIdWithForcedArchiveUpdateInput;
  where: MetadataPropertyReleaseIdWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPropertyReleaseStatusListUpdateInput = {
  create: MetadataPropertyReleaseStatusWithForcedArchiveCreateInput;
  update: MetadataPropertyReleaseStatusWithForcedArchiveUpdateInput;
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPublisherListUpdateInput = {
  create: MetadataPublisherWithForcedArchiveCreateInput;
  update: MetadataPublisherWithForcedArchiveUpdateInput;
  where: MetadataPublisherWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPublishingIssueListUpdateInput = {
  create: MetadataPublishingIssueWithForcedArchiveCreateInput;
  update: MetadataPublishingIssueWithForcedArchiveUpdateInput;
  where: MetadataPublishingIssueWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataPublishingSubjectListUpdateInput = {
  create: MetadataPublishingSubjectWithForcedArchiveCreateInput;
  update: MetadataPublishingSubjectWithForcedArchiveUpdateInput;
  where: MetadataPublishingSubjectWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataQualifiedUsePrismaByListUpdateInput = {
  create: MetadataQualifiedUsePrismaByWithForcedArchiveCreateInput;
  update: MetadataQualifiedUsePrismaByWithForcedArchiveUpdateInput;
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataQualifiedUsePrismaDateListUpdateInput = {
  create: MetadataQualifiedUsePrismaDateWithForcedArchiveCreateInput;
  update: MetadataQualifiedUsePrismaDateWithForcedArchiveUpdateInput;
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataQualifiedUsePrismaDurationListUpdateInput = {
  create: MetadataQualifiedUsePrismaDurationWithForcedArchiveCreateInput;
  update: MetadataQualifiedUsePrismaDurationWithForcedArchiveUpdateInput;
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataQualifiedUsePrismaSupportListUpdateInput = {
  create: MetadataQualifiedUsePrismaSupportWithForcedArchiveCreateInput;
  update: MetadataQualifiedUsePrismaSupportWithForcedArchiveUpdateInput;
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataRatingListUpdateInput = {
  create: MetadataRatingWithForcedArchiveCreateInput;
  update: MetadataRatingWithForcedArchiveUpdateInput;
  where: MetadataRatingWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataReferenceDateListUpdateInput = {
  create: MetadataReferenceDateWithForcedArchiveCreateInput;
  update: MetadataReferenceDateWithForcedArchiveUpdateInput;
  where: MetadataReferenceDateWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataReferenceNumberListUpdateInput = {
  create: MetadataReferenceNumberWithForcedArchiveCreateInput;
  update: MetadataReferenceNumberWithForcedArchiveUpdateInput;
  where: MetadataReferenceNumberWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataReferenceServiceListUpdateInput = {
  create: MetadataReferenceServiceWithForcedArchiveCreateInput;
  update: MetadataReferenceServiceWithForcedArchiveUpdateInput;
  where: MetadataReferenceServiceWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataReleaseDateListUpdateInput = {
  create: MetadataReleaseDateWithForcedArchiveCreateInput;
  update: MetadataReleaseDateWithForcedArchiveUpdateInput;
  where: MetadataReleaseDateWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataReleaseTimeListUpdateInput = {
  create: MetadataReleaseTimeWithForcedArchiveCreateInput;
  update: MetadataReleaseTimeWithForcedArchiveUpdateInput;
  where: MetadataReleaseTimeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataRequiredPermissionListUpdateInput = {
  create: MetadataRequiredPermissionWithForcedArchiveCreateInput;
  update: MetadataRequiredPermissionWithForcedArchiveUpdateInput;
  where: MetadataRequiredPermissionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataResolutionKindListUpdateInput = {
  create: MetadataResolutionKindWithForcedArchiveCreateInput;
  update: MetadataResolutionKindWithForcedArchiveUpdateInput;
  where: MetadataResolutionKindWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataRightsListUpdateInput = {
  create: MetadataRightsWithForcedArchiveCreateInput;
  update: MetadataRightsWithForcedArchiveUpdateInput;
  where: MetadataRightsWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataRoyaltyfreeListUpdateInput = {
  create: MetadataRoyaltyfreeWithForcedArchiveCreateInput;
  update: MetadataRoyaltyfreeWithForcedArchiveUpdateInput;
  where: MetadataRoyaltyfreeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSceneListUpdateInput = {
  create: MetadataSceneWithForcedArchiveCreateInput;
  update: MetadataSceneWithForcedArchiveUpdateInput;
  where: MetadataSceneWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSectionListUpdateInput = {
  create: MetadataSectionWithForcedArchiveCreateInput;
  update: MetadataSectionWithForcedArchiveUpdateInput;
  where: MetadataSectionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSentToListUpdateInput = {
  create: MetadataSentToWithForcedArchiveCreateInput;
  update: MetadataSentToWithForcedArchiveUpdateInput;
  where: MetadataSentToWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSerialNumberListUpdateInput = {
  create: MetadataSerialNumberWithForcedArchiveCreateInput;
  update: MetadataSerialNumberWithForcedArchiveUpdateInput;
  where: MetadataSerialNumberWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSeriesDateTimeListUpdateInput = {
  create: MetadataSeriesDateTimeWithForcedArchiveCreateInput;
  update: MetadataSeriesDateTimeWithForcedArchiveUpdateInput;
  where: MetadataSeriesDateTimeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSeriesDescriptionListUpdateInput = {
  create: MetadataSeriesDescriptionWithForcedArchiveCreateInput;
  update: MetadataSeriesDescriptionWithForcedArchiveUpdateInput;
  where: MetadataSeriesDescriptionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSeriesModalityListUpdateInput = {
  create: MetadataSeriesModalityWithForcedArchiveCreateInput;
  update: MetadataSeriesModalityWithForcedArchiveUpdateInput;
  where: MetadataSeriesModalityWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSeriesNumberListUpdateInput = {
  create: MetadataSeriesNumberWithForcedArchiveCreateInput;
  update: MetadataSeriesNumberWithForcedArchiveUpdateInput;
  where: MetadataSeriesNumberWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataShortUniqueIdListUpdateInput = {
  create: MetadataShortUniqueIdWithForcedArchiveCreateInput;
  update: MetadataShortUniqueIdWithForcedArchiveUpdateInput;
  where: MetadataShortUniqueIdWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSourceListUpdateInput = {
  create: MetadataSourceWithForcedArchiveCreateInput;
  update: MetadataSourceWithForcedArchiveUpdateInput;
  where: MetadataSourceWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataStateListUpdateInput = {
  create: MetadataStateWithForcedArchiveCreateInput;
  update: MetadataStateWithForcedArchiveUpdateInput;
  where: MetadataStateWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataStudyDateTimeListUpdateInput = {
  create: MetadataStudyDateTimeWithForcedArchiveCreateInput;
  update: MetadataStudyDateTimeWithForcedArchiveUpdateInput;
  where: MetadataStudyDateTimeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataStudyDescriptionListUpdateInput = {
  create: MetadataStudyDescriptionWithForcedArchiveCreateInput;
  update: MetadataStudyDescriptionWithForcedArchiveUpdateInput;
  where: MetadataStudyDescriptionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataStudyIdListUpdateInput = {
  create: MetadataStudyIdWithForcedArchiveCreateInput;
  update: MetadataStudyIdWithForcedArchiveUpdateInput;
  where: MetadataStudyIdWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataStudyPhysicianListUpdateInput = {
  create: MetadataStudyPhysicianWithForcedArchiveCreateInput;
  update: MetadataStudyPhysicianWithForcedArchiveUpdateInput;
  where: MetadataStudyPhysicianWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSubjectCodeListUpdateInput = {
  create: MetadataSubjectCodeWithForcedArchiveCreateInput;
  update: MetadataSubjectCodeWithForcedArchiveUpdateInput;
  where: MetadataSubjectCodeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSubjectListUpdateInput = {
  create: MetadataSubjectWithForcedArchiveCreateInput;
  update: MetadataSubjectWithForcedArchiveUpdateInput;
  where: MetadataSubjectWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSubjectPrismaListUpdateInput = {
  create: MetadataSubjectPrismaWithForcedArchiveCreateInput;
  update: MetadataSubjectPrismaWithForcedArchiveUpdateInput;
  where: MetadataSubjectPrismaWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataSupplementalCategoriesListUpdateInput = {
  create: MetadataSupplementalCategoriesWithForcedArchiveCreateInput;
  update: MetadataSupplementalCategoriesWithForcedArchiveUpdateInput;
  where: MetadataSupplementalCategoriesWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataTargetVersionListUpdateInput = {
  create: MetadataTargetVersionWithForcedArchiveCreateInput;
  update: MetadataTargetVersionWithForcedArchiveUpdateInput;
  where: MetadataTargetVersionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataTitleListUpdateInput = {
  create: MetadataTitleWithForcedArchiveCreateInput;
  update: MetadataTitleWithForcedArchiveUpdateInput;
  where: MetadataTitleWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataToneCurveListUpdateInput = {
  create: MetadataToneCurveWithForcedArchiveCreateInput;
  update: MetadataToneCurveWithForcedArchiveUpdateInput;
  where: MetadataToneCurveWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataTransferredByEmailListUpdateInput = {
  create: MetadataTransferredByEmailWithForcedArchiveCreateInput;
  update: MetadataTransferredByEmailWithForcedArchiveUpdateInput;
  where: MetadataTransferredByEmailWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataTransferredByFullNameListUpdateInput = {
  create: MetadataTransferredByFullNameWithForcedArchiveCreateInput;
  update: MetadataTransferredByFullNameWithForcedArchiveUpdateInput;
  where: MetadataTransferredByFullNameWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataTransferredByListUpdateInput = {
  create: MetadataTransferredByWithForcedArchiveCreateInput;
  update: MetadataTransferredByWithForcedArchiveUpdateInput;
  where: MetadataTransferredByWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataTransmissionReferenceListUpdateInput = {
  create: MetadataTransmissionReferenceWithForcedArchiveCreateInput;
  update: MetadataTransmissionReferenceWithForcedArchiveUpdateInput;
  where: MetadataTransmissionReferenceWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataTypeListUpdateInput = {
  create: MetadataTypeWithForcedArchiveCreateInput;
  update: MetadataTypeWithForcedArchiveUpdateInput;
  where: MetadataTypeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUndersubjectListUpdateInput = {
  create: MetadataUndersubjectWithForcedArchiveCreateInput;
  update: MetadataUndersubjectWithForcedArchiveUpdateInput;
  where: MetadataUndersubjectWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUnderUnderSubjectListUpdateInput = {
  create: MetadataUnderUnderSubjectWithForcedArchiveCreateInput;
  update: MetadataUnderUnderSubjectWithForcedArchiveUpdateInput;
  where: MetadataUnderUnderSubjectWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUniqueIdListUpdateInput = {
  create: MetadataUniqueIdWithForcedArchiveCreateInput;
  update: MetadataUniqueIdWithForcedArchiveUpdateInput;
  where: MetadataUniqueIdWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUnitListUpdateInput = {
  create: MetadataUnitWithForcedArchiveCreateInput;
  update: MetadataUnitWithForcedArchiveUpdateInput;
  where: MetadataUnitWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUnitShortNameListUpdateInput = {
  create: MetadataUnitShortNameWithForcedArchiveCreateInput;
  update: MetadataUnitShortNameWithForcedArchiveUpdateInput;
  where: MetadataUnitShortNameWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUploadedByFullNameListUpdateInput = {
  create: MetadataUploadedByFullNameWithForcedArchiveCreateInput;
  update: MetadataUploadedByFullNameWithForcedArchiveUpdateInput;
  where: MetadataUploadedByFullNameWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUploadedByListUpdateInput = {
  create: MetadataUploadedByWithForcedArchiveCreateInput;
  update: MetadataUploadedByWithForcedArchiveUpdateInput;
  where: MetadataUploadedByWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUploadTimeListUpdateInput = {
  create: MetadataUploadTimeWithForcedArchiveCreateInput;
  update: MetadataUploadTimeWithForcedArchiveUpdateInput;
  where: MetadataUploadTimeWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUrgencyListUpdateInput = {
  create: MetadataUrgencyWithForcedArchiveCreateInput;
  update: MetadataUrgencyWithForcedArchiveUpdateInput;
  where: MetadataUrgencyWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUsageTermsListUpdateInput = {
  create: MetadataUsageTermsWithForcedArchiveCreateInput;
  update: MetadataUsageTermsWithForcedArchiveUpdateInput;
  where: MetadataUsageTermsWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUserCommentListUpdateInput = {
  create: MetadataUserCommentWithForcedArchiveCreateInput;
  update: MetadataUserCommentWithForcedArchiveUpdateInput;
  where: MetadataUserCommentWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUserDefined195ListUpdateInput = {
  create: MetadataUserDefined195WithForcedArchiveCreateInput;
  update: MetadataUserDefined195WithForcedArchiveUpdateInput;
  where: MetadataUserDefined195WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUserDefined237ListUpdateInput = {
  create: MetadataUserDefined237WithForcedArchiveCreateInput;
  update: MetadataUserDefined237WithForcedArchiveUpdateInput;
  where: MetadataUserDefined237WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUserDefined238ListUpdateInput = {
  create: MetadataUserDefined238WithForcedArchiveCreateInput;
  update: MetadataUserDefined238WithForcedArchiveUpdateInput;
  where: MetadataUserDefined238WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUserDefined239ListUpdateInput = {
  create: MetadataUserDefined239WithForcedArchiveCreateInput;
  update: MetadataUserDefined239WithForcedArchiveUpdateInput;
  where: MetadataUserDefined239WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUserDefined242ListUpdateInput = {
  create: MetadataUserDefined242WithForcedArchiveCreateInput;
  update: MetadataUserDefined242WithForcedArchiveUpdateInput;
  where: MetadataUserDefined242WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataUserDefined62ListUpdateInput = {
  create: MetadataUserDefined62WithForcedArchiveCreateInput;
  update: MetadataUserDefined62WithForcedArchiveUpdateInput;
  where: MetadataUserDefined62WhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataValidListUpdateInput = {
  create: MetadataValidWithForcedArchiveCreateInput;
  update: MetadataValidWithForcedArchiveUpdateInput;
  where: MetadataValidWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataVersionListUpdateInput = {
  create: MetadataVersionWithForcedArchiveCreateInput;
  update: MetadataVersionWithForcedArchiveUpdateInput;
  where: MetadataVersionWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataWebStatementListUpdateInput = {
  create: MetadataWebStatementWithForcedArchiveCreateInput;
  update: MetadataWebStatementWithForcedArchiveUpdateInput;
  where: MetadataWebStatementWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataWorkflowKindListUpdateInput = {
  create: MetadataWorkflowKindWithForcedArchiveCreateInput;
  update: MetadataWorkflowKindWithForcedArchiveUpdateInput;
  where: MetadataWorkflowKindWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataXmpFileStampsListUpdateInput = {
  create: MetadataXmpFileStampsWithForcedArchiveCreateInput;
  update: MetadataXmpFileStampsWithForcedArchiveUpdateInput;
  where: MetadataXmpFileStampsWhereUniqueInput;
};

export type ArchiveNestedUpsertMetadataXmpHistoryListUpdateInput = {
  create: MetadataXmpHistoryWithForcedArchiveCreateInput;
  update: MetadataXmpHistoryWithForcedArchiveUpdateInput;
  where: MetadataXmpHistoryWhereUniqueInput;
};

export type ArchiveNestedUpsertPhotoMetadatasUpdateInput = {
  create: PhotoMetadataWithForcedArchiveCreateInput;
  update: PhotoMetadataWithForcedArchiveUpdateInput;
  where: PhotoMetadataWhereUniqueInput;
};

export type ArchiveNestedUpsertPhotosUpdateInput = {
  create: PhotoWithForcedArchiveCreateInput;
  update: PhotoWithForcedArchiveUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum ArchiveOrderByInput {
  _id_ASC = '_id_ASC',
  _id_DESC = '_id_DESC',
  archiveId_ASC = 'archiveId_ASC',
  archiveId_DESC = 'archiveId_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  hasCategories_ASC = 'hasCategories_ASC',
  hasCategories_DESC = 'hasCategories_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  label_ASC = 'label_ASC',
  label_DESC = 'label_DESC',
  name_ASC = 'name_ASC',
  name_DESC = 'name_DESC',
  type_ASC = 'type_ASC',
  type_DESC = 'type_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC'
}

export enum ArchiveType {
  ARCHIVE = 'ARCHIVE',
  DELIVERY = 'DELIVERY',
  NEWS = 'NEWS',
  SUJET = 'SUJET'
}

export type ArchiveUpdateInput = {
  /** Actions for the "Archive.archiveAgencies" relation: "ArchiveAgency.archive"'s inverse relation */
  archiveAgencies?: InputMaybe<ArchiveNestedArchiveAgenciesUpdateInput>;
  /** Actions for the "Archive.categories" relation: "Category.archive"'s inverse relation */
  categories?: InputMaybe<ArchiveNestedCategoriesUpdateInput>;
  /** Actions for the "Archive.deliverySubjects" relation: "DeliverySubject.archive"'s inverse relation */
  deliverySubjects?: InputMaybe<ArchiveNestedDeliverySubjectsUpdateInput>;
  /** Actions for the "Archive.gcImportSetting" relation: "GcImportSetting.archive"'s inverse relation */
  gcImportSetting?: InputMaybe<ArchiveNestedGcImportSettingUpdateInput>;
  hasCategories?: InputMaybe<Scalars['Boolean']['input']>;
  /** Actions for the "Archive.importQueues" relation: "ImportQueue.archive"'s inverse relation */
  importQueues?: InputMaybe<ArchiveNestedImportQueuesUpdateInput>;
  /** The archive label */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Archive.metadataAccessRightsList" relation: "MetadataAccessRights.archive"'s inverse relation */
  metadataAccessRightsList?: InputMaybe<ArchiveNestedMetadataAccessRightsListUpdateInput>;
  /** Actions for the "Archive.metadataAddlModelInfoList" relation: "MetadataAddlModelInfo.archive"'s inverse relation */
  metadataAddlModelInfoList?: InputMaybe<ArchiveNestedMetadataAddlModelInfoListUpdateInput>;
  /** Actions for the "Archive.metadataArchiveChildList" relation: "MetadataArchiveChild.archive"'s inverse relation */
  metadataArchiveChildList?: InputMaybe<ArchiveNestedMetadataArchiveChildListUpdateInput>;
  /** Actions for the "Archive.metadataArchiveParentList" relation: "MetadataArchiveParent.archive"'s inverse relation */
  metadataArchiveParentList?: InputMaybe<ArchiveNestedMetadataArchiveParentListUpdateInput>;
  /** Actions for the "Archive.metadataAssetStateList" relation: "MetadataAssetState.archive"'s inverse relation */
  metadataAssetStateList?: InputMaybe<ArchiveNestedMetadataAssetStateListUpdateInput>;
  /** Actions for the "Archive.metadataAttributionNameList" relation: "MetadataAttributionName.archive"'s inverse relation */
  metadataAttributionNameList?: InputMaybe<ArchiveNestedMetadataAttributionNameListUpdateInput>;
  /** Actions for the "Archive.metadataAttributionUrlList" relation: "MetadataAttributionUrl.archive"'s inverse relation */
  metadataAttributionUrlList?: InputMaybe<ArchiveNestedMetadataAttributionUrlListUpdateInput>;
  /** Actions for the "Archive.metadataAudienceList" relation: "MetadataAudience.archive"'s inverse relation */
  metadataAudienceList?: InputMaybe<ArchiveNestedMetadataAudienceListUpdateInput>;
  /** Actions for the "Archive.metadataAuthorsPositionList" relation: "MetadataAuthorsPosition.archive"'s inverse relation */
  metadataAuthorsPositionList?: InputMaybe<ArchiveNestedMetadataAuthorsPositionListUpdateInput>;
  /** Actions for the "Archive.metadataBackupNameList" relation: "MetadataBackupName.archive"'s inverse relation */
  metadataBackupNameList?: InputMaybe<ArchiveNestedMetadataBackupNameListUpdateInput>;
  /** Actions for the "Archive.metadataBitmapGrayscalePictureList" relation: "MetadataBitmapGrayscalePicture.archive"'s inverse relation */
  metadataBitmapGrayscalePictureList?: InputMaybe<ArchiveNestedMetadataBitmapGrayscalePictureListUpdateInput>;
  /** Actions for the "Archive.metadataBrandList" relation: "MetadataBrand.archive"'s inverse relation */
  metadataBrandList?: InputMaybe<ArchiveNestedMetadataBrandListUpdateInput>;
  /** Actions for the "Archive.metadataBrandPrismaList" relation: "MetadataBrandPrisma.archive"'s inverse relation */
  metadataBrandPrismaList?: InputMaybe<ArchiveNestedMetadataBrandPrismaListUpdateInput>;
  /** Actions for the "Archive.metadataBrandShortNameList" relation: "MetadataBrandShortName.archive"'s inverse relation */
  metadataBrandShortNameList?: InputMaybe<ArchiveNestedMetadataBrandShortNameListUpdateInput>;
  /** Actions for the "Archive.metadataCaptionWriterList" relation: "MetadataCaptionWriter.archive"'s inverse relation */
  metadataCaptionWriterList?: InputMaybe<ArchiveNestedMetadataCaptionWriterListUpdateInput>;
  /** Actions for the "Archive.metadataCategoryList" relation: "MetadataCategory.archive"'s inverse relation */
  metadataCategoryList?: InputMaybe<ArchiveNestedMetadataCategoryListUpdateInput>;
  /** Actions for the "Archive.metadataCategoryPrismaList" relation: "MetadataCategoryPrisma.archive"'s inverse relation */
  metadataCategoryPrismaList?: InputMaybe<ArchiveNestedMetadataCategoryPrismaListUpdateInput>;
  /** Actions for the "Archive.metadataCertificateList" relation: "MetadataCertificate.archive"'s inverse relation */
  metadataCertificateList?: InputMaybe<ArchiveNestedMetadataCertificateListUpdateInput>;
  /** Actions for the "Archive.metadataCiAdrCityList" relation: "MetadataCiAdrCity.archive"'s inverse relation */
  metadataCiAdrCityList?: InputMaybe<ArchiveNestedMetadataCiAdrCityListUpdateInput>;
  /** Actions for the "Archive.metadataCiAdrCtryList" relation: "MetadataCiAdrCtry.archive"'s inverse relation */
  metadataCiAdrCtryList?: InputMaybe<ArchiveNestedMetadataCiAdrCtryListUpdateInput>;
  /** Actions for the "Archive.metadataCiAdrExtadrList" relation: "MetadataCiAdrExtadr.archive"'s inverse relation */
  metadataCiAdrExtadrList?: InputMaybe<ArchiveNestedMetadataCiAdrExtadrListUpdateInput>;
  /** Actions for the "Archive.metadataCiAdrPcodeList" relation: "MetadataCiAdrPcode.archive"'s inverse relation */
  metadataCiAdrPcodeList?: InputMaybe<ArchiveNestedMetadataCiAdrPcodeListUpdateInput>;
  /** Actions for the "Archive.metadataCiAdrRegionList" relation: "MetadataCiAdrRegion.archive"'s inverse relation */
  metadataCiAdrRegionList?: InputMaybe<ArchiveNestedMetadataCiAdrRegionListUpdateInput>;
  /** Actions for the "Archive.metadataCiEmailWorkList" relation: "MetadataCiEmailWork.archive"'s inverse relation */
  metadataCiEmailWorkList?: InputMaybe<ArchiveNestedMetadataCiEmailWorkListUpdateInput>;
  /** Actions for the "Archive.metadataCiTelWorkList" relation: "MetadataCiTelWork.archive"'s inverse relation */
  metadataCiTelWorkList?: InputMaybe<ArchiveNestedMetadataCiTelWorkListUpdateInput>;
  /** Actions for the "Archive.metadataCityList" relation: "MetadataCity.archive"'s inverse relation */
  metadataCityList?: InputMaybe<ArchiveNestedMetadataCityListUpdateInput>;
  /** Actions for the "Archive.metadataCiUrlWorkList" relation: "MetadataCiUrlWork.archive"'s inverse relation */
  metadataCiUrlWorkList?: InputMaybe<ArchiveNestedMetadataCiUrlWorkListUpdateInput>;
  /** Actions for the "Archive.metadataClassifyList" relation: "MetadataClassify.archive"'s inverse relation */
  metadataClassifyList?: InputMaybe<ArchiveNestedMetadataClassifyListUpdateInput>;
  /** Actions for the "Archive.metadataCompanyList" relation: "MetadataCompany.archive"'s inverse relation */
  metadataCompanyList?: InputMaybe<ArchiveNestedMetadataCompanyListUpdateInput>;
  /** Actions for the "Archive.metadataCompanyShortNameList" relation: "MetadataCompanyShortName.archive"'s inverse relation */
  metadataCompanyShortNameList?: InputMaybe<ArchiveNestedMetadataCompanyShortNameListUpdateInput>;
  /** Actions for the "Archive.metadataConfidentialityList" relation: "MetadataConfidentiality.archive"'s inverse relation */
  metadataConfidentialityList?: InputMaybe<ArchiveNestedMetadataConfidentialityListUpdateInput>;
  /** Actions for the "Archive.metadataContainedInList" relation: "MetadataContainedIn.archive"'s inverse relation */
  metadataContainedInList?: InputMaybe<ArchiveNestedMetadataContainedInListUpdateInput>;
  /** Actions for the "Archive.metadataContentMediaKindList" relation: "MetadataContentMediaKind.archive"'s inverse relation */
  metadataContentMediaKindList?: InputMaybe<ArchiveNestedMetadataContentMediaKindListUpdateInput>;
  /** Actions for the "Archive.metadataContentValueList" relation: "MetadataContentValue.archive"'s inverse relation */
  metadataContentValueList?: InputMaybe<ArchiveNestedMetadataContentValueListUpdateInput>;
  /** Actions for the "Archive.metadataCopyrightLayerList" relation: "MetadataCopyrightLayer.archive"'s inverse relation */
  metadataCopyrightLayerList?: InputMaybe<ArchiveNestedMetadataCopyrightLayerListUpdateInput>;
  /** Actions for the "Archive.metadataCountryCodeList" relation: "MetadataCountryCode.archive"'s inverse relation */
  metadataCountryCodeList?: InputMaybe<ArchiveNestedMetadataCountryCodeListUpdateInput>;
  /** Actions for the "Archive.metadataCountryList" relation: "MetadataCountry.archive"'s inverse relation */
  metadataCountryList?: InputMaybe<ArchiveNestedMetadataCountryListUpdateInput>;
  /** Actions for the "Archive.metadataCreatedTimeList" relation: "MetadataCreatedTime.archive"'s inverse relation */
  metadataCreatedTimeList?: InputMaybe<ArchiveNestedMetadataCreatedTimeListUpdateInput>;
  /** Actions for the "Archive.metadataCreatorList" relation: "MetadataCreator.archive"'s inverse relation */
  metadataCreatorList?: InputMaybe<ArchiveNestedMetadataCreatorListUpdateInput>;
  /** Actions for the "Archive.metadataCreatorToolList" relation: "MetadataCreatorTool.archive"'s inverse relation */
  metadataCreatorToolList?: InputMaybe<ArchiveNestedMetadataCreatorToolListUpdateInput>;
  /** Actions for the "Archive.metadataCreditList" relation: "MetadataCredit.archive"'s inverse relation */
  metadataCreditList?: InputMaybe<ArchiveNestedMetadataCreditListUpdateInput>;
  /** Actions for the "Archive.metadataCustomField14List" relation: "MetadataCustomField14.archive"'s inverse relation */
  metadataCustomField14List?: InputMaybe<ArchiveNestedMetadataCustomField14ListUpdateInput>;
  /** Actions for the "Archive.metadataCustomField15List" relation: "MetadataCustomField15.archive"'s inverse relation */
  metadataCustomField15List?: InputMaybe<ArchiveNestedMetadataCustomField15ListUpdateInput>;
  /** Actions for the "Archive.metadataCustomField16List" relation: "MetadataCustomField16.archive"'s inverse relation */
  metadataCustomField16List?: InputMaybe<ArchiveNestedMetadataCustomField16ListUpdateInput>;
  /** Actions for the "Archive.metadataCustomField17List" relation: "MetadataCustomField17.archive"'s inverse relation */
  metadataCustomField17List?: InputMaybe<ArchiveNestedMetadataCustomField17ListUpdateInput>;
  /** Actions for the "Archive.metadataCustomField20List" relation: "MetadataCustomField20.archive"'s inverse relation */
  metadataCustomField20List?: InputMaybe<ArchiveNestedMetadataCustomField20ListUpdateInput>;
  /** Actions for the "Archive.metadataCustomField5List" relation: "MetadataCustomField5.archive"'s inverse relation */
  metadataCustomField5List?: InputMaybe<ArchiveNestedMetadataCustomField5ListUpdateInput>;
  /** Actions for the "Archive.metadataCustomField6List" relation: "MetadataCustomField6.archive"'s inverse relation */
  metadataCustomField6List?: InputMaybe<ArchiveNestedMetadataCustomField6ListUpdateInput>;
  /** Actions for the "Archive.metadataCustomField7List" relation: "MetadataCustomField7.archive"'s inverse relation */
  metadataCustomField7List?: InputMaybe<ArchiveNestedMetadataCustomField7ListUpdateInput>;
  /** Actions for the "Archive.metadataCustomField9List" relation: "MetadataCustomField9.archive"'s inverse relation */
  metadataCustomField9List?: InputMaybe<ArchiveNestedMetadataCustomField9ListUpdateInput>;
  /** Actions for the "Archive.metadataDateCreatedList" relation: "MetadataDateCreated.archive"'s inverse relation */
  metadataDateCreatedList?: InputMaybe<ArchiveNestedMetadataDateCreatedListUpdateInput>;
  /** Actions for the "Archive.metadataDateTimeDigitizedList" relation: "MetadataDateTimeDigitized.archive"'s inverse relation */
  metadataDateTimeDigitizedList?: InputMaybe<ArchiveNestedMetadataDateTimeDigitizedListUpdateInput>;
  /** Actions for the "Archive.metadataDateTimeOriginalList" relation: "MetadataDateTimeOriginal.archive"'s inverse relation */
  metadataDateTimeOriginalList?: InputMaybe<ArchiveNestedMetadataDateTimeOriginalListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryAccountList" relation: "MetadataDeliveryAccount.archive"'s inverse relation */
  metadataDeliveryAccountList?: InputMaybe<ArchiveNestedMetadataDeliveryAccountListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryCompanyList" relation: "MetadataDeliveryCompany.archive"'s inverse relation */
  metadataDeliveryCompanyList?: InputMaybe<ArchiveNestedMetadataDeliveryCompanyListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryCopyrightList" relation: "MetadataDeliveryCopyright.archive"'s inverse relation */
  metadataDeliveryCopyrightList?: InputMaybe<ArchiveNestedMetadataDeliveryCopyrightListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryDateFolderList" relation: "MetadataDeliveryDateFolder.archive"'s inverse relation */
  metadataDeliveryDateFolderList?: InputMaybe<ArchiveNestedMetadataDeliveryDateFolderListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryDateTimeList" relation: "MetadataDeliveryDateTime.archive"'s inverse relation */
  metadataDeliveryDateTimeList?: InputMaybe<ArchiveNestedMetadataDeliveryDateTimeListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryDcCreatorList" relation: "MetadataDeliveryDcCreator.archive"'s inverse relation */
  metadataDeliveryDcCreatorList?: InputMaybe<ArchiveNestedMetadataDeliveryDcCreatorListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryDcRightsList" relation: "MetadataDeliveryDcRights.archive"'s inverse relation */
  metadataDeliveryDcRightsList?: InputMaybe<ArchiveNestedMetadataDeliveryDcRightsListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryFileTypeList" relation: "MetadataDeliveryFileType.archive"'s inverse relation */
  metadataDeliveryFileTypeList?: InputMaybe<ArchiveNestedMetadataDeliveryFileTypeListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryFolderList" relation: "MetadataDeliveryFolder.archive"'s inverse relation */
  metadataDeliveryFolderList?: InputMaybe<ArchiveNestedMetadataDeliveryFolderListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryKindList" relation: "MetadataDeliveryKind.archive"'s inverse relation */
  metadataDeliveryKindList?: InputMaybe<ArchiveNestedMetadataDeliveryKindListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryPathList" relation: "MetadataDeliveryPath.archive"'s inverse relation */
  metadataDeliveryPathList?: InputMaybe<ArchiveNestedMetadataDeliveryPathListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryPersonShownintheImageList" relation: "MetadataDeliveryPersonShownintheImage.archive"'s inverse relation */
  metadataDeliveryPersonShownintheImageList?: InputMaybe<ArchiveNestedMetadataDeliveryPersonShownintheImageListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryPhotoshopCreditList" relation: "MetadataDeliveryPhotoshopCredit.archive"'s inverse relation */
  metadataDeliveryPhotoshopCreditList?: InputMaybe<ArchiveNestedMetadataDeliveryPhotoshopCreditListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryPhotoshopSourceList" relation: "MetadataDeliveryPhotoshopSource.archive"'s inverse relation */
  metadataDeliveryPhotoshopSourceList?: InputMaybe<ArchiveNestedMetadataDeliveryPhotoshopSourceListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryServiceList" relation: "MetadataDeliveryService.archive"'s inverse relation */
  metadataDeliveryServiceList?: InputMaybe<ArchiveNestedMetadataDeliveryServiceListUpdateInput>;
  /** Actions for the "Archive.metadataDeliverySubjectList" relation: "MetadataDeliverySubject.archive"'s inverse relation */
  metadataDeliverySubjectList?: InputMaybe<ArchiveNestedMetadataDeliverySubjectListUpdateInput>;
  /** Actions for the "Archive.metadataDeliveryUnderSubjectList" relation: "MetadataDeliveryUnderSubject.archive"'s inverse relation */
  metadataDeliveryUnderSubjectList?: InputMaybe<ArchiveNestedMetadataDeliveryUnderSubjectListUpdateInput>;
  /** Actions for the "Archive.metadataDepartmentList" relation: "MetadataDepartment.archive"'s inverse relation */
  metadataDepartmentList?: InputMaybe<ArchiveNestedMetadataDepartmentListUpdateInput>;
  /** Actions for the "Archive.metadataDescriptionList" relation: "MetadataDescription.archive"'s inverse relation */
  metadataDescriptionList?: InputMaybe<ArchiveNestedMetadataDescriptionListUpdateInput>;
  /** Actions for the "Archive.metadataDigitalAssetUrlList" relation: "MetadataDigitalAssetUrl.archive"'s inverse relation */
  metadataDigitalAssetUrlList?: InputMaybe<ArchiveNestedMetadataDigitalAssetUrlListUpdateInput>;
  /** Actions for the "Archive.metadataDivisionList" relation: "MetadataDivision.archive"'s inverse relation */
  metadataDivisionList?: InputMaybe<ArchiveNestedMetadataDivisionListUpdateInput>;
  /** Actions for the "Archive.metadataDocumentTextList" relation: "MetadataDocumentText.archive"'s inverse relation */
  metadataDocumentTextList?: InputMaybe<ArchiveNestedMetadataDocumentTextListUpdateInput>;
  /** Actions for the "Archive.metadataEditionList" relation: "MetadataEdition.archive"'s inverse relation */
  metadataEditionList?: InputMaybe<ArchiveNestedMetadataEditionListUpdateInput>;
  /** Actions for the "Archive.metadataEditorialVersionList" relation: "MetadataEditorialVersion.archive"'s inverse relation */
  metadataEditorialVersionList?: InputMaybe<ArchiveNestedMetadataEditorialVersionListUpdateInput>;
  /** Actions for the "Archive.metadataEditStatusList" relation: "MetadataEditStatus.archive"'s inverse relation */
  metadataEditStatusList?: InputMaybe<ArchiveNestedMetadataEditStatusListUpdateInput>;
  /** Actions for the "Archive.metadataEnvironnementPhotoList" relation: "MetadataEnvironnementPhoto.archive"'s inverse relation */
  metadataEnvironnementPhotoList?: InputMaybe<ArchiveNestedMetadataEnvironnementPhotoListUpdateInput>;
  /** Actions for the "Archive.metadataEquipmentInstitutionList" relation: "MetadataEquipmentInstitution.archive"'s inverse relation */
  metadataEquipmentInstitutionList?: InputMaybe<ArchiveNestedMetadataEquipmentInstitutionListUpdateInput>;
  /** Actions for the "Archive.metadataEquipmentManufacturerList" relation: "MetadataEquipmentManufacturer.archive"'s inverse relation */
  metadataEquipmentManufacturerList?: InputMaybe<ArchiveNestedMetadataEquipmentManufacturerListUpdateInput>;
  /** Actions for the "Archive.metadataEventList" relation: "MetadataEvent.archive"'s inverse relation */
  metadataEventList?: InputMaybe<ArchiveNestedMetadataEventListUpdateInput>;
  /** Actions for the "Archive.metadataExifList" relation: "MetadataExif.archive"'s inverse relation */
  metadataExifList?: InputMaybe<ArchiveNestedMetadataExifListUpdateInput>;
  /** Actions for the "Archive.metadataFabStorageList" relation: "MetadataFabStorage.archive"'s inverse relation */
  metadataFabStorageList?: InputMaybe<ArchiveNestedMetadataFabStorageListUpdateInput>;
  /** Actions for the "Archive.metadataFileCheckForList" relation: "MetadataFileCheckFor.archive"'s inverse relation */
  metadataFileCheckForList?: InputMaybe<ArchiveNestedMetadataFileCheckForListUpdateInput>;
  /** Actions for the "Archive.metadataFileInfoBitsPerPixelList" relation: "MetadataFileInfoBitsPerPixel.archive"'s inverse relation */
  metadataFileInfoBitsPerPixelList?: InputMaybe<ArchiveNestedMetadataFileInfoBitsPerPixelListUpdateInput>;
  /** Actions for the "Archive.metadataFileInfoFramesList" relation: "MetadataFileInfoFrames.archive"'s inverse relation */
  metadataFileInfoFramesList?: InputMaybe<ArchiveNestedMetadataFileInfoFramesListUpdateInput>;
  /** Actions for the "Archive.metadataFileInfoPhotometricInterpretationList" relation: "MetadataFileInfoPhotometricInterpretation.archive"'s inverse relation */
  metadataFileInfoPhotometricInterpretationList?: InputMaybe<ArchiveNestedMetadataFileInfoPhotometricInterpretationListUpdateInput>;
  /** Actions for the "Archive.metadataFileInfoTransferSyntaxList" relation: "MetadataFileInfoTransferSyntax.archive"'s inverse relation */
  metadataFileInfoTransferSyntaxList?: InputMaybe<ArchiveNestedMetadataFileInfoTransferSyntaxListUpdateInput>;
  /** Actions for the "Archive.metadataFileStageList" relation: "MetadataFileStage.archive"'s inverse relation */
  metadataFileStageList?: InputMaybe<ArchiveNestedMetadataFileStageListUpdateInput>;
  /** Actions for the "Archive.metadataFileStatusList" relation: "MetadataFileStatus.archive"'s inverse relation */
  metadataFileStatusList?: InputMaybe<ArchiveNestedMetadataFileStatusListUpdateInput>;
  /** Actions for the "Archive.metadataFileTypeList" relation: "MetadataFileType.archive"'s inverse relation */
  metadataFileTypeList?: InputMaybe<ArchiveNestedMetadataFileTypeListUpdateInput>;
  /** Actions for the "Archive.metadataFixtureIdentifierList" relation: "MetadataFixtureIdentifier.archive"'s inverse relation */
  metadataFixtureIdentifierList?: InputMaybe<ArchiveNestedMetadataFixtureIdentifierListUpdateInput>;
  /** Actions for the "Archive.metadataFormatList" relation: "MetadataFormat.archive"'s inverse relation */
  metadataFormatList?: InputMaybe<ArchiveNestedMetadataFormatListUpdateInput>;
  /** Actions for the "Archive.metadataGpsLatitudeList" relation: "MetadataGpsLatitude.archive"'s inverse relation */
  metadataGpsLatitudeList?: InputMaybe<ArchiveNestedMetadataGpsLatitudeListUpdateInput>;
  /** Actions for the "Archive.metadataGpsLongitudeList" relation: "MetadataGpsLongitude.archive"'s inverse relation */
  metadataGpsLongitudeList?: InputMaybe<ArchiveNestedMetadataGpsLongitudeListUpdateInput>;
  /** Actions for the "Archive.metadataGpsProcessingMethodList" relation: "MetadataGpsProcessingMethod.archive"'s inverse relation */
  metadataGpsProcessingMethodList?: InputMaybe<ArchiveNestedMetadataGpsProcessingMethodListUpdateInput>;
  /** Actions for the "Archive.metadataHeaderList" relation: "MetadataHeader.archive"'s inverse relation */
  metadataHeaderList?: InputMaybe<ArchiveNestedMetadataHeaderListUpdateInput>;
  /** Actions for the "Archive.metadataHeaderPrismaList" relation: "MetadataHeaderPrisma.archive"'s inverse relation */
  metadataHeaderPrismaList?: InputMaybe<ArchiveNestedMetadataHeaderPrismaListUpdateInput>;
  /** Actions for the "Archive.metadataHeadlineList" relation: "MetadataHeadline.archive"'s inverse relation */
  metadataHeadlineList?: InputMaybe<ArchiveNestedMetadataHeadlineListUpdateInput>;
  /** Actions for the "Archive.metadataHistoryList" relation: "MetadataHistory.archive"'s inverse relation */
  metadataHistoryList?: InputMaybe<ArchiveNestedMetadataHistoryListUpdateInput>;
  /** Actions for the "Archive.metadataIccProfileList" relation: "MetadataIccProfile.archive"'s inverse relation */
  metadataIccProfileList?: InputMaybe<ArchiveNestedMetadataIccProfileListUpdateInput>;
  /** Actions for the "Archive.metadataIdAssignmentList" relation: "MetadataIdAssignment.archive"'s inverse relation */
  metadataIdAssignmentList?: InputMaybe<ArchiveNestedMetadataIdAssignmentListUpdateInput>;
  /** Actions for the "Archive.metadataIdMediaContentList" relation: "MetadataIdMediaContent.archive"'s inverse relation */
  metadataIdMediaContentList?: InputMaybe<ArchiveNestedMetadataIdMediaContentListUpdateInput>;
  /** Actions for the "Archive.metadataIdPhotoPrismaList" relation: "MetadataIdPhotoPrisma.archive"'s inverse relation */
  metadataIdPhotoPrismaList?: InputMaybe<ArchiveNestedMetadataIdPhotoPrismaListUpdateInput>;
  /** Actions for the "Archive.metadataIdSubjectList" relation: "MetadataIdSubject.archive"'s inverse relation */
  metadataIdSubjectList?: InputMaybe<ArchiveNestedMetadataIdSubjectListUpdateInput>;
  /** Actions for the "Archive.metadataImageNotesList" relation: "MetadataImageNotes.archive"'s inverse relation */
  metadataImageNotesList?: InputMaybe<ArchiveNestedMetadataImageNotesListUpdateInput>;
  /** Actions for the "Archive.metadataImageUniqueIdList" relation: "MetadataImageUniqueId.archive"'s inverse relation */
  metadataImageUniqueIdList?: InputMaybe<ArchiveNestedMetadataImageUniqueIdListUpdateInput>;
  /** Actions for the "Archive.metadataInstructionsList" relation: "MetadataInstructions.archive"'s inverse relation */
  metadataInstructionsList?: InputMaybe<ArchiveNestedMetadataInstructionsListUpdateInput>;
  /** Actions for the "Archive.metadataIntellectualGenreList" relation: "MetadataIntellectualGenre.archive"'s inverse relation */
  metadataIntellectualGenreList?: InputMaybe<ArchiveNestedMetadataIntellectualGenreListUpdateInput>;
  /** Actions for the "Archive.metadataIsoSpeedRatingsList" relation: "MetadataIsoSpeedRatings.archive"'s inverse relation */
  metadataIsoSpeedRatingsList?: InputMaybe<ArchiveNestedMetadataIsoSpeedRatingsListUpdateInput>;
  /** Actions for the "Archive.metadataIssueList" relation: "MetadataIssue.archive"'s inverse relation */
  metadataIssueList?: InputMaybe<ArchiveNestedMetadataIssueListUpdateInput>;
  /** Actions for the "Archive.metadataJobIdList" relation: "MetadataJobId.archive"'s inverse relation */
  metadataJobIdList?: InputMaybe<ArchiveNestedMetadataJobIdListUpdateInput>;
  /** Actions for the "Archive.metadataJobProcessingList" relation: "MetadataJobProcessing.archive"'s inverse relation */
  metadataJobProcessingList?: InputMaybe<ArchiveNestedMetadataJobProcessingListUpdateInput>;
  /** Actions for the "Archive.metadataLayoutPictureEditingList" relation: "MetadataLayoutPictureEditing.archive"'s inverse relation */
  metadataLayoutPictureEditingList?: InputMaybe<ArchiveNestedMetadataLayoutPictureEditingListUpdateInput>;
  /** Actions for the "Archive.metadataLayoutStorageList" relation: "MetadataLayoutStorage.archive"'s inverse relation */
  metadataLayoutStorageList?: InputMaybe<ArchiveNestedMetadataLayoutStorageListUpdateInput>;
  /** Actions for the "Archive.metadataLicenseList" relation: "MetadataLicense.archive"'s inverse relation */
  metadataLicenseList?: InputMaybe<ArchiveNestedMetadataLicenseListUpdateInput>;
  /** Actions for the "Archive.metadataLinksList" relation: "MetadataLinks.archive"'s inverse relation */
  metadataLinksList?: InputMaybe<ArchiveNestedMetadataLinksListUpdateInput>;
  /** Actions for the "Archive.metadataLocalCaptionList" relation: "MetadataLocalCaption.archive"'s inverse relation */
  metadataLocalCaptionList?: InputMaybe<ArchiveNestedMetadataLocalCaptionListUpdateInput>;
  /** Actions for the "Archive.metadataLocationList" relation: "MetadataLocation.archive"'s inverse relation */
  metadataLocationList?: InputMaybe<ArchiveNestedMetadataLocationListUpdateInput>;
  /** Actions for the "Archive.metadataMakeList" relation: "MetadataMake.archive"'s inverse relation */
  metadataMakeList?: InputMaybe<ArchiveNestedMetadataMakeListUpdateInput>;
  /** Actions for the "Archive.metadataManifestList" relation: "MetadataManifest.archive"'s inverse relation */
  metadataManifestList?: InputMaybe<ArchiveNestedMetadataManifestListUpdateInput>;
  /** Actions for the "Archive.metadataMarkedList" relation: "MetadataMarked.archive"'s inverse relation */
  metadataMarkedList?: InputMaybe<ArchiveNestedMetadataMarkedListUpdateInput>;
  /** Actions for the "Archive.metadataMasterDocumentIdList" relation: "MetadataMasterDocumentId.archive"'s inverse relation */
  metadataMasterDocumentIdList?: InputMaybe<ArchiveNestedMetadataMasterDocumentIdListUpdateInput>;
  /** Actions for the "Archive.metadataMaxAvailHeightList" relation: "MetadataMaxAvailHeight.archive"'s inverse relation */
  metadataMaxAvailHeightList?: InputMaybe<ArchiveNestedMetadataMaxAvailHeightListUpdateInput>;
  /** Actions for the "Archive.metadataMaxAvailWidthList" relation: "MetadataMaxAvailWidth.archive"'s inverse relation */
  metadataMaxAvailWidthList?: InputMaybe<ArchiveNestedMetadataMaxAvailWidthListUpdateInput>;
  /** Actions for the "Archive.metadataMinorModelAgeDisclosureList" relation: "MetadataMinorModelAgeDisclosure.archive"'s inverse relation */
  metadataMinorModelAgeDisclosureList?: InputMaybe<ArchiveNestedMetadataMinorModelAgeDisclosureListUpdateInput>;
  /** Actions for the "Archive.metadataModelAgeList" relation: "MetadataModelAge.archive"'s inverse relation */
  metadataModelAgeList?: InputMaybe<ArchiveNestedMetadataModelAgeListUpdateInput>;
  /** Actions for the "Archive.metadataModelList" relation: "MetadataModel.archive"'s inverse relation */
  metadataModelList?: InputMaybe<ArchiveNestedMetadataModelListUpdateInput>;
  /** Actions for the "Archive.metadataModelReleaseIdList" relation: "MetadataModelReleaseId.archive"'s inverse relation */
  metadataModelReleaseIdList?: InputMaybe<ArchiveNestedMetadataModelReleaseIdListUpdateInput>;
  /** Actions for the "Archive.metadataModelReleaseStatusList" relation: "MetadataModelReleaseStatus.archive"'s inverse relation */
  metadataModelReleaseStatusList?: InputMaybe<ArchiveNestedMetadataModelReleaseStatusListUpdateInput>;
  /** Actions for the "Archive.metadataMorePermissionsList" relation: "MetadataMorePermissions.archive"'s inverse relation */
  metadataMorePermissionsList?: InputMaybe<ArchiveNestedMetadataMorePermissionsListUpdateInput>;
  /** Actions for the "Archive.metadataObjectCycleList" relation: "MetadataObjectCycle.archive"'s inverse relation */
  metadataObjectCycleList?: InputMaybe<ArchiveNestedMetadataObjectCycleListUpdateInput>;
  /** Actions for the "Archive.metadataOrganisationInImageNameList" relation: "MetadataOrganisationInImageName.archive"'s inverse relation */
  metadataOrganisationInImageNameList?: InputMaybe<ArchiveNestedMetadataOrganisationInImageNameListUpdateInput>;
  /** Actions for the "Archive.metadataOriginalColorList" relation: "MetadataOriginalColor.archive"'s inverse relation */
  metadataOriginalColorList?: InputMaybe<ArchiveNestedMetadataOriginalColorListUpdateInput>;
  /** Actions for the "Archive.metadataOriginalIccProfileList" relation: "MetadataOriginalIccProfile.archive"'s inverse relation */
  metadataOriginalIccProfileList?: InputMaybe<ArchiveNestedMetadataOriginalIccProfileListUpdateInput>;
  /** Actions for the "Archive.metadataOriginalNameList" relation: "MetadataOriginalName.archive"'s inverse relation */
  metadataOriginalNameList?: InputMaybe<ArchiveNestedMetadataOriginalNameListUpdateInput>;
  /** Actions for the "Archive.metadataOriginalWeigthList" relation: "MetadataOriginalWeigth.archive"'s inverse relation */
  metadataOriginalWeigthList?: InputMaybe<ArchiveNestedMetadataOriginalWeigthListUpdateInput>;
  /** Actions for the "Archive.metadataOwnerIdList" relation: "MetadataOwnerId.archive"'s inverse relation */
  metadataOwnerIdList?: InputMaybe<ArchiveNestedMetadataOwnerIdListUpdateInput>;
  /** Actions for the "Archive.metadataOwnerList" relation: "MetadataOwner.archive"'s inverse relation */
  metadataOwnerList?: InputMaybe<ArchiveNestedMetadataOwnerListUpdateInput>;
  /** Actions for the "Archive.metadataPageList" relation: "MetadataPage.archive"'s inverse relation */
  metadataPageList?: InputMaybe<ArchiveNestedMetadataPageListUpdateInput>;
  /** Actions for the "Archive.metadataPagePrismaList" relation: "MetadataPagePrisma.archive"'s inverse relation */
  metadataPagePrismaList?: InputMaybe<ArchiveNestedMetadataPagePrismaListUpdateInput>;
  /** Actions for the "Archive.metadataPaintBasedCorrectionsList" relation: "MetadataPaintBasedCorrections.archive"'s inverse relation */
  metadataPaintBasedCorrectionsList?: InputMaybe<ArchiveNestedMetadataPaintBasedCorrectionsListUpdateInput>;
  /** Actions for the "Archive.metadataPatientDobList" relation: "MetadataPatientDob.archive"'s inverse relation */
  metadataPatientDobList?: InputMaybe<ArchiveNestedMetadataPatientDobListUpdateInput>;
  /** Actions for the "Archive.metadataPatientIdList" relation: "MetadataPatientId.archive"'s inverse relation */
  metadataPatientIdList?: InputMaybe<ArchiveNestedMetadataPatientIdListUpdateInput>;
  /** Actions for the "Archive.metadataPatientNameList" relation: "MetadataPatientName.archive"'s inverse relation */
  metadataPatientNameList?: InputMaybe<ArchiveNestedMetadataPatientNameListUpdateInput>;
  /** Actions for the "Archive.metadataPatientSexList" relation: "MetadataPatientSex.archive"'s inverse relation */
  metadataPatientSexList?: InputMaybe<ArchiveNestedMetadataPatientSexListUpdateInput>;
  /** Actions for the "Archive.metadataPersonInImageList" relation: "MetadataPersonInImage.archive"'s inverse relation */
  metadataPersonInImageList?: InputMaybe<ArchiveNestedMetadataPersonInImageListUpdateInput>;
  /** Actions for the "Archive.metadataPhotoStorageList" relation: "MetadataPhotoStorage.archive"'s inverse relation */
  metadataPhotoStorageList?: InputMaybe<ArchiveNestedMetadataPhotoStorageListUpdateInput>;
  /** Actions for the "Archive.metadataPrepressPictureEditingList" relation: "MetadataPrepressPictureEditing.archive"'s inverse relation */
  metadataPrepressPictureEditingList?: InputMaybe<ArchiveNestedMetadataPrepressPictureEditingListUpdateInput>;
  /** Actions for the "Archive.metadataPriceLevelList" relation: "MetadataPriceLevel.archive"'s inverse relation */
  metadataPriceLevelList?: InputMaybe<ArchiveNestedMetadataPriceLevelListUpdateInput>;
  /** Actions for the "Archive.metadataPrintingProfileList" relation: "MetadataPrintingProfile.archive"'s inverse relation */
  metadataPrintingProfileList?: InputMaybe<ArchiveNestedMetadataPrintingProfileListUpdateInput>;
  /** Actions for the "Archive.metadataPrismaProductionList" relation: "MetadataPrismaProduction.archive"'s inverse relation */
  metadataPrismaProductionList?: InputMaybe<ArchiveNestedMetadataPrismaProductionListUpdateInput>;
  /** Actions for the "Archive.metadataProcessHistoryList" relation: "MetadataProcessHistory.archive"'s inverse relation */
  metadataProcessHistoryList?: InputMaybe<ArchiveNestedMetadataProcessHistoryListUpdateInput>;
  /** Actions for the "Archive.metadataProcessParameterList" relation: "MetadataProcessParameter.archive"'s inverse relation */
  metadataProcessParameterList?: InputMaybe<ArchiveNestedMetadataProcessParameterListUpdateInput>;
  /** Actions for the "Archive.metadataProcessStatusList" relation: "MetadataProcessStatus.archive"'s inverse relation */
  metadataProcessStatusList?: InputMaybe<ArchiveNestedMetadataProcessStatusListUpdateInput>;
  /** Actions for the "Archive.metadataProductList" relation: "MetadataProduct.archive"'s inverse relation */
  metadataProductList?: InputMaybe<ArchiveNestedMetadataProductListUpdateInput>;
  /** Actions for the "Archive.metadataProductShortNameList" relation: "MetadataProductShortName.archive"'s inverse relation */
  metadataProductShortNameList?: InputMaybe<ArchiveNestedMetadataProductShortNameListUpdateInput>;
  /** Actions for the "Archive.metadataProductsList" relation: "MetadataProducts.archive"'s inverse relation */
  metadataProductsList?: InputMaybe<ArchiveNestedMetadataProductsListUpdateInput>;
  /** Actions for the "Archive.metadataProduitsList" relation: "MetadataProduits.archive"'s inverse relation */
  metadataProduitsList?: InputMaybe<ArchiveNestedMetadataProduitsListUpdateInput>;
  /** Actions for the "Archive.metadataProgramVersionList" relation: "MetadataProgramVersion.archive"'s inverse relation */
  metadataProgramVersionList?: InputMaybe<ArchiveNestedMetadataProgramVersionListUpdateInput>;
  /** Actions for the "Archive.metadataPropertyReleaseIdList" relation: "MetadataPropertyReleaseId.archive"'s inverse relation */
  metadataPropertyReleaseIdList?: InputMaybe<ArchiveNestedMetadataPropertyReleaseIdListUpdateInput>;
  /** Actions for the "Archive.metadataPropertyReleaseStatusList" relation: "MetadataPropertyReleaseStatus.archive"'s inverse relation */
  metadataPropertyReleaseStatusList?: InputMaybe<ArchiveNestedMetadataPropertyReleaseStatusListUpdateInput>;
  /** Actions for the "Archive.metadataPublisherList" relation: "MetadataPublisher.archive"'s inverse relation */
  metadataPublisherList?: InputMaybe<ArchiveNestedMetadataPublisherListUpdateInput>;
  /** Actions for the "Archive.metadataPublishingIssueList" relation: "MetadataPublishingIssue.archive"'s inverse relation */
  metadataPublishingIssueList?: InputMaybe<ArchiveNestedMetadataPublishingIssueListUpdateInput>;
  /** Actions for the "Archive.metadataPublishingSubjectList" relation: "MetadataPublishingSubject.archive"'s inverse relation */
  metadataPublishingSubjectList?: InputMaybe<ArchiveNestedMetadataPublishingSubjectListUpdateInput>;
  /** Actions for the "Archive.metadataQualifiedUsePrismaByList" relation: "MetadataQualifiedUsePrismaBy.archive"'s inverse relation */
  metadataQualifiedUsePrismaByList?: InputMaybe<ArchiveNestedMetadataQualifiedUsePrismaByListUpdateInput>;
  /** Actions for the "Archive.metadataQualifiedUsePrismaDateList" relation: "MetadataQualifiedUsePrismaDate.archive"'s inverse relation */
  metadataQualifiedUsePrismaDateList?: InputMaybe<ArchiveNestedMetadataQualifiedUsePrismaDateListUpdateInput>;
  /** Actions for the "Archive.metadataQualifiedUsePrismaDurationList" relation: "MetadataQualifiedUsePrismaDuration.archive"'s inverse relation */
  metadataQualifiedUsePrismaDurationList?: InputMaybe<ArchiveNestedMetadataQualifiedUsePrismaDurationListUpdateInput>;
  /** Actions for the "Archive.metadataQualifiedUsePrismaSupportList" relation: "MetadataQualifiedUsePrismaSupport.archive"'s inverse relation */
  metadataQualifiedUsePrismaSupportList?: InputMaybe<ArchiveNestedMetadataQualifiedUsePrismaSupportListUpdateInput>;
  /** Actions for the "Archive.metadataRatingList" relation: "MetadataRating.archive"'s inverse relation */
  metadataRatingList?: InputMaybe<ArchiveNestedMetadataRatingListUpdateInput>;
  /** Actions for the "Archive.metadataReferenceDateList" relation: "MetadataReferenceDate.archive"'s inverse relation */
  metadataReferenceDateList?: InputMaybe<ArchiveNestedMetadataReferenceDateListUpdateInput>;
  /** Actions for the "Archive.metadataReferenceNumberList" relation: "MetadataReferenceNumber.archive"'s inverse relation */
  metadataReferenceNumberList?: InputMaybe<ArchiveNestedMetadataReferenceNumberListUpdateInput>;
  /** Actions for the "Archive.metadataReferenceServiceList" relation: "MetadataReferenceService.archive"'s inverse relation */
  metadataReferenceServiceList?: InputMaybe<ArchiveNestedMetadataReferenceServiceListUpdateInput>;
  /** Actions for the "Archive.metadataReleaseDateList" relation: "MetadataReleaseDate.archive"'s inverse relation */
  metadataReleaseDateList?: InputMaybe<ArchiveNestedMetadataReleaseDateListUpdateInput>;
  /** Actions for the "Archive.metadataReleaseTimeList" relation: "MetadataReleaseTime.archive"'s inverse relation */
  metadataReleaseTimeList?: InputMaybe<ArchiveNestedMetadataReleaseTimeListUpdateInput>;
  /** Actions for the "Archive.metadataRequiredPermissionList" relation: "MetadataRequiredPermission.archive"'s inverse relation */
  metadataRequiredPermissionList?: InputMaybe<ArchiveNestedMetadataRequiredPermissionListUpdateInput>;
  /** Actions for the "Archive.metadataResolutionKindList" relation: "MetadataResolutionKind.archive"'s inverse relation */
  metadataResolutionKindList?: InputMaybe<ArchiveNestedMetadataResolutionKindListUpdateInput>;
  /** Actions for the "Archive.metadataRightsList" relation: "MetadataRights.archive"'s inverse relation */
  metadataRightsList?: InputMaybe<ArchiveNestedMetadataRightsListUpdateInput>;
  /** Actions for the "Archive.metadataRoyaltyfreeList" relation: "MetadataRoyaltyfree.archive"'s inverse relation */
  metadataRoyaltyfreeList?: InputMaybe<ArchiveNestedMetadataRoyaltyfreeListUpdateInput>;
  /** Actions for the "Archive.metadataSceneList" relation: "MetadataScene.archive"'s inverse relation */
  metadataSceneList?: InputMaybe<ArchiveNestedMetadataSceneListUpdateInput>;
  /** Actions for the "Archive.metadataSectionList" relation: "MetadataSection.archive"'s inverse relation */
  metadataSectionList?: InputMaybe<ArchiveNestedMetadataSectionListUpdateInput>;
  /** Actions for the "Archive.metadataSentToList" relation: "MetadataSentTo.archive"'s inverse relation */
  metadataSentToList?: InputMaybe<ArchiveNestedMetadataSentToListUpdateInput>;
  /** Actions for the "Archive.metadataSerialNumberList" relation: "MetadataSerialNumber.archive"'s inverse relation */
  metadataSerialNumberList?: InputMaybe<ArchiveNestedMetadataSerialNumberListUpdateInput>;
  /** Actions for the "Archive.metadataSeriesDateTimeList" relation: "MetadataSeriesDateTime.archive"'s inverse relation */
  metadataSeriesDateTimeList?: InputMaybe<ArchiveNestedMetadataSeriesDateTimeListUpdateInput>;
  /** Actions for the "Archive.metadataSeriesDescriptionList" relation: "MetadataSeriesDescription.archive"'s inverse relation */
  metadataSeriesDescriptionList?: InputMaybe<ArchiveNestedMetadataSeriesDescriptionListUpdateInput>;
  /** Actions for the "Archive.metadataSeriesModalityList" relation: "MetadataSeriesModality.archive"'s inverse relation */
  metadataSeriesModalityList?: InputMaybe<ArchiveNestedMetadataSeriesModalityListUpdateInput>;
  /** Actions for the "Archive.metadataSeriesNumberList" relation: "MetadataSeriesNumber.archive"'s inverse relation */
  metadataSeriesNumberList?: InputMaybe<ArchiveNestedMetadataSeriesNumberListUpdateInput>;
  /** Actions for the "Archive.metadataShortUniqueIdList" relation: "MetadataShortUniqueId.archive"'s inverse relation */
  metadataShortUniqueIdList?: InputMaybe<ArchiveNestedMetadataShortUniqueIdListUpdateInput>;
  /** Actions for the "Archive.metadataSourceList" relation: "MetadataSource.archive"'s inverse relation */
  metadataSourceList?: InputMaybe<ArchiveNestedMetadataSourceListUpdateInput>;
  /** Actions for the "Archive.metadataStateList" relation: "MetadataState.archive"'s inverse relation */
  metadataStateList?: InputMaybe<ArchiveNestedMetadataStateListUpdateInput>;
  /** Actions for the "Archive.metadataStudyDateTimeList" relation: "MetadataStudyDateTime.archive"'s inverse relation */
  metadataStudyDateTimeList?: InputMaybe<ArchiveNestedMetadataStudyDateTimeListUpdateInput>;
  /** Actions for the "Archive.metadataStudyDescriptionList" relation: "MetadataStudyDescription.archive"'s inverse relation */
  metadataStudyDescriptionList?: InputMaybe<ArchiveNestedMetadataStudyDescriptionListUpdateInput>;
  /** Actions for the "Archive.metadataStudyIdList" relation: "MetadataStudyId.archive"'s inverse relation */
  metadataStudyIdList?: InputMaybe<ArchiveNestedMetadataStudyIdListUpdateInput>;
  /** Actions for the "Archive.metadataStudyPhysicianList" relation: "MetadataStudyPhysician.archive"'s inverse relation */
  metadataStudyPhysicianList?: InputMaybe<ArchiveNestedMetadataStudyPhysicianListUpdateInput>;
  /** Actions for the "Archive.metadataSubjectCodeList" relation: "MetadataSubjectCode.archive"'s inverse relation */
  metadataSubjectCodeList?: InputMaybe<ArchiveNestedMetadataSubjectCodeListUpdateInput>;
  /** Actions for the "Archive.metadataSubjectList" relation: "MetadataSubject.archive"'s inverse relation */
  metadataSubjectList?: InputMaybe<ArchiveNestedMetadataSubjectListUpdateInput>;
  /** Actions for the "Archive.metadataSubjectPrismaList" relation: "MetadataSubjectPrisma.archive"'s inverse relation */
  metadataSubjectPrismaList?: InputMaybe<ArchiveNestedMetadataSubjectPrismaListUpdateInput>;
  /** Actions for the "Archive.metadataSupplementalCategoriesList" relation: "MetadataSupplementalCategories.archive"'s inverse relation */
  metadataSupplementalCategoriesList?: InputMaybe<ArchiveNestedMetadataSupplementalCategoriesListUpdateInput>;
  /** Actions for the "Archive.metadataTargetVersionList" relation: "MetadataTargetVersion.archive"'s inverse relation */
  metadataTargetVersionList?: InputMaybe<ArchiveNestedMetadataTargetVersionListUpdateInput>;
  /** Actions for the "Archive.metadataTitleList" relation: "MetadataTitle.archive"'s inverse relation */
  metadataTitleList?: InputMaybe<ArchiveNestedMetadataTitleListUpdateInput>;
  /** Actions for the "Archive.metadataToneCurveList" relation: "MetadataToneCurve.archive"'s inverse relation */
  metadataToneCurveList?: InputMaybe<ArchiveNestedMetadataToneCurveListUpdateInput>;
  /** Actions for the "Archive.metadataTransferredByEmailList" relation: "MetadataTransferredByEmail.archive"'s inverse relation */
  metadataTransferredByEmailList?: InputMaybe<ArchiveNestedMetadataTransferredByEmailListUpdateInput>;
  /** Actions for the "Archive.metadataTransferredByFullNameList" relation: "MetadataTransferredByFullName.archive"'s inverse relation */
  metadataTransferredByFullNameList?: InputMaybe<ArchiveNestedMetadataTransferredByFullNameListUpdateInput>;
  /** Actions for the "Archive.metadataTransferredByList" relation: "MetadataTransferredBy.archive"'s inverse relation */
  metadataTransferredByList?: InputMaybe<ArchiveNestedMetadataTransferredByListUpdateInput>;
  /** Actions for the "Archive.metadataTransmissionReferenceList" relation: "MetadataTransmissionReference.archive"'s inverse relation */
  metadataTransmissionReferenceList?: InputMaybe<ArchiveNestedMetadataTransmissionReferenceListUpdateInput>;
  /** Actions for the "Archive.metadataTypeList" relation: "MetadataType.archive"'s inverse relation */
  metadataTypeList?: InputMaybe<ArchiveNestedMetadataTypeListUpdateInput>;
  /** Actions for the "Archive.metadataUndersubjectList" relation: "MetadataUndersubject.archive"'s inverse relation */
  metadataUndersubjectList?: InputMaybe<ArchiveNestedMetadataUndersubjectListUpdateInput>;
  /** Actions for the "Archive.metadataUnderUnderSubjectList" relation: "MetadataUnderUnderSubject.archive"'s inverse relation */
  metadataUnderUnderSubjectList?: InputMaybe<ArchiveNestedMetadataUnderUnderSubjectListUpdateInput>;
  /** Actions for the "Archive.metadataUniqueIdList" relation: "MetadataUniqueId.archive"'s inverse relation */
  metadataUniqueIdList?: InputMaybe<ArchiveNestedMetadataUniqueIdListUpdateInput>;
  /** Actions for the "Archive.metadataUnitList" relation: "MetadataUnit.archive"'s inverse relation */
  metadataUnitList?: InputMaybe<ArchiveNestedMetadataUnitListUpdateInput>;
  /** Actions for the "Archive.metadataUnitShortNameList" relation: "MetadataUnitShortName.archive"'s inverse relation */
  metadataUnitShortNameList?: InputMaybe<ArchiveNestedMetadataUnitShortNameListUpdateInput>;
  /** Actions for the "Archive.metadataUploadedByFullNameList" relation: "MetadataUploadedByFullName.archive"'s inverse relation */
  metadataUploadedByFullNameList?: InputMaybe<ArchiveNestedMetadataUploadedByFullNameListUpdateInput>;
  /** Actions for the "Archive.metadataUploadedByList" relation: "MetadataUploadedBy.archive"'s inverse relation */
  metadataUploadedByList?: InputMaybe<ArchiveNestedMetadataUploadedByListUpdateInput>;
  /** Actions for the "Archive.metadataUploadTimeList" relation: "MetadataUploadTime.archive"'s inverse relation */
  metadataUploadTimeList?: InputMaybe<ArchiveNestedMetadataUploadTimeListUpdateInput>;
  /** Actions for the "Archive.metadataUrgencyList" relation: "MetadataUrgency.archive"'s inverse relation */
  metadataUrgencyList?: InputMaybe<ArchiveNestedMetadataUrgencyListUpdateInput>;
  /** Actions for the "Archive.metadataUsageTermsList" relation: "MetadataUsageTerms.archive"'s inverse relation */
  metadataUsageTermsList?: InputMaybe<ArchiveNestedMetadataUsageTermsListUpdateInput>;
  /** Actions for the "Archive.metadataUserCommentList" relation: "MetadataUserComment.archive"'s inverse relation */
  metadataUserCommentList?: InputMaybe<ArchiveNestedMetadataUserCommentListUpdateInput>;
  /** Actions for the "Archive.metadataUserDefined195List" relation: "MetadataUserDefined195.archive"'s inverse relation */
  metadataUserDefined195List?: InputMaybe<ArchiveNestedMetadataUserDefined195ListUpdateInput>;
  /** Actions for the "Archive.metadataUserDefined237List" relation: "MetadataUserDefined237.archive"'s inverse relation */
  metadataUserDefined237List?: InputMaybe<ArchiveNestedMetadataUserDefined237ListUpdateInput>;
  /** Actions for the "Archive.metadataUserDefined238List" relation: "MetadataUserDefined238.archive"'s inverse relation */
  metadataUserDefined238List?: InputMaybe<ArchiveNestedMetadataUserDefined238ListUpdateInput>;
  /** Actions for the "Archive.metadataUserDefined239List" relation: "MetadataUserDefined239.archive"'s inverse relation */
  metadataUserDefined239List?: InputMaybe<ArchiveNestedMetadataUserDefined239ListUpdateInput>;
  /** Actions for the "Archive.metadataUserDefined242List" relation: "MetadataUserDefined242.archive"'s inverse relation */
  metadataUserDefined242List?: InputMaybe<ArchiveNestedMetadataUserDefined242ListUpdateInput>;
  /** Actions for the "Archive.metadataUserDefined62List" relation: "MetadataUserDefined62.archive"'s inverse relation */
  metadataUserDefined62List?: InputMaybe<ArchiveNestedMetadataUserDefined62ListUpdateInput>;
  /** Actions for the "Archive.metadataValidList" relation: "MetadataValid.archive"'s inverse relation */
  metadataValidList?: InputMaybe<ArchiveNestedMetadataValidListUpdateInput>;
  /** Actions for the "Archive.metadataVersionList" relation: "MetadataVersion.archive"'s inverse relation */
  metadataVersionList?: InputMaybe<ArchiveNestedMetadataVersionListUpdateInput>;
  /** Actions for the "Archive.metadataWebStatementList" relation: "MetadataWebStatement.archive"'s inverse relation */
  metadataWebStatementList?: InputMaybe<ArchiveNestedMetadataWebStatementListUpdateInput>;
  /** Actions for the "Archive.metadataWorkflowKindList" relation: "MetadataWorkflowKind.archive"'s inverse relation */
  metadataWorkflowKindList?: InputMaybe<ArchiveNestedMetadataWorkflowKindListUpdateInput>;
  /** Actions for the "Archive.metadataXmpFileStampsList" relation: "MetadataXmpFileStamps.archive"'s inverse relation */
  metadataXmpFileStampsList?: InputMaybe<ArchiveNestedMetadataXmpFileStampsListUpdateInput>;
  /** Actions for the "Archive.metadataXmpHistoryList" relation: "MetadataXmpHistory.archive"'s inverse relation */
  metadataXmpHistoryList?: InputMaybe<ArchiveNestedMetadataXmpHistoryListUpdateInput>;
  /** The archive name */
  name?: InputMaybe<ArchiveKeyType>;
  /** Actions for the "Archive.photoMetadatas" relation: "PhotoMetadata.archive"'s inverse relation */
  photoMetadatas?: InputMaybe<ArchiveNestedPhotoMetadatasUpdateInput>;
  /** Actions for the "Archive.photos" relation: "Photo.archive"'s inverse relation */
  photos?: InputMaybe<ArchiveNestedPhotosUpdateInput>;
  /** The archive type */
  type?: InputMaybe<ArchiveType>;
};

/** Filters the "Archive" nodes by specifying some conditions */
export type ArchiveWhereInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  _id_gt?: InputMaybe<Scalars['Int']['input']>;
  _id_gte?: InputMaybe<Scalars['Int']['input']>;
  _id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _id_lt?: InputMaybe<Scalars['Int']['input']>;
  _id_lte?: InputMaybe<Scalars['Int']['input']>;
  _id_not?: InputMaybe<Scalars['Int']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  AND?: InputMaybe<Array<ArchiveWhereInput>>;
  archiveAgencies_some?: InputMaybe<ArchiveAgencyWhereInput>;
  archiveId?: InputMaybe<Scalars['Int']['input']>;
  archiveId_gt?: InputMaybe<Scalars['Int']['input']>;
  archiveId_gte?: InputMaybe<Scalars['Int']['input']>;
  archiveId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  archiveId_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  archiveId_lt?: InputMaybe<Scalars['Int']['input']>;
  archiveId_lte?: InputMaybe<Scalars['Int']['input']>;
  archiveId_not?: InputMaybe<Scalars['Int']['input']>;
  archiveId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  categories_some?: InputMaybe<CategoryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  deliverySubjects_some?: InputMaybe<DeliverySubjectWhereInput>;
  gcImportSetting?: InputMaybe<GcImportSettingWhereInput>;
  gcImportSetting_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  hasCategories?: InputMaybe<Scalars['Boolean']['input']>;
  hasCategories_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  hasCategories_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  hasCategories_not?: InputMaybe<Scalars['Boolean']['input']>;
  hasCategories_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  importQueues_some?: InputMaybe<ImportQueueWhereInput>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_ends_with?: InputMaybe<Scalars['String']['input']>;
  label_gt?: InputMaybe<Scalars['String']['input']>;
  label_gte?: InputMaybe<Scalars['String']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  label_lt?: InputMaybe<Scalars['String']['input']>;
  label_lte?: InputMaybe<Scalars['String']['input']>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  label_starts_with?: InputMaybe<Scalars['String']['input']>;
  metadataAccessRightsList_some?: InputMaybe<MetadataAccessRightsWhereInput>;
  metadataAddlModelInfoList_some?: InputMaybe<MetadataAddlModelInfoWhereInput>;
  metadataArchiveChildList_some?: InputMaybe<MetadataArchiveChildWhereInput>;
  metadataArchiveParentList_some?: InputMaybe<MetadataArchiveParentWhereInput>;
  metadataAssetStateList_some?: InputMaybe<MetadataAssetStateWhereInput>;
  metadataAttributionNameList_some?: InputMaybe<MetadataAttributionNameWhereInput>;
  metadataAttributionUrlList_some?: InputMaybe<MetadataAttributionUrlWhereInput>;
  metadataAudienceList_some?: InputMaybe<MetadataAudienceWhereInput>;
  metadataAuthorsPositionList_some?: InputMaybe<MetadataAuthorsPositionWhereInput>;
  metadataBackupNameList_some?: InputMaybe<MetadataBackupNameWhereInput>;
  metadataBitmapGrayscalePictureList_some?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
  metadataBrandList_some?: InputMaybe<MetadataBrandWhereInput>;
  metadataBrandPrismaList_some?: InputMaybe<MetadataBrandPrismaWhereInput>;
  metadataBrandShortNameList_some?: InputMaybe<MetadataBrandShortNameWhereInput>;
  metadataCaptionWriterList_some?: InputMaybe<MetadataCaptionWriterWhereInput>;
  metadataCategoryList_some?: InputMaybe<MetadataCategoryWhereInput>;
  metadataCategoryPrismaList_some?: InputMaybe<MetadataCategoryPrismaWhereInput>;
  metadataCertificateList_some?: InputMaybe<MetadataCertificateWhereInput>;
  metadataCiAdrCityList_some?: InputMaybe<MetadataCiAdrCityWhereInput>;
  metadataCiAdrCtryList_some?: InputMaybe<MetadataCiAdrCtryWhereInput>;
  metadataCiAdrExtadrList_some?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
  metadataCiAdrPcodeList_some?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
  metadataCiAdrRegionList_some?: InputMaybe<MetadataCiAdrRegionWhereInput>;
  metadataCiEmailWorkList_some?: InputMaybe<MetadataCiEmailWorkWhereInput>;
  metadataCiTelWorkList_some?: InputMaybe<MetadataCiTelWorkWhereInput>;
  metadataCityList_some?: InputMaybe<MetadataCityWhereInput>;
  metadataCiUrlWorkList_some?: InputMaybe<MetadataCiUrlWorkWhereInput>;
  metadataClassifyList_some?: InputMaybe<MetadataClassifyWhereInput>;
  metadataCompanyList_some?: InputMaybe<MetadataCompanyWhereInput>;
  metadataCompanyShortNameList_some?: InputMaybe<MetadataCompanyShortNameWhereInput>;
  metadataConfidentialityList_some?: InputMaybe<MetadataConfidentialityWhereInput>;
  metadataContainedInList_some?: InputMaybe<MetadataContainedInWhereInput>;
  metadataContentMediaKindList_some?: InputMaybe<MetadataContentMediaKindWhereInput>;
  metadataContentValueList_some?: InputMaybe<MetadataContentValueWhereInput>;
  metadataCopyrightLayerList_some?: InputMaybe<MetadataCopyrightLayerWhereInput>;
  metadataCountryCodeList_some?: InputMaybe<MetadataCountryCodeWhereInput>;
  metadataCountryList_some?: InputMaybe<MetadataCountryWhereInput>;
  metadataCreatedTimeList_some?: InputMaybe<MetadataCreatedTimeWhereInput>;
  metadataCreatorList_some?: InputMaybe<MetadataCreatorWhereInput>;
  metadataCreatorToolList_some?: InputMaybe<MetadataCreatorToolWhereInput>;
  metadataCreditList_some?: InputMaybe<MetadataCreditWhereInput>;
  metadataCustomField14List_some?: InputMaybe<MetadataCustomField14WhereInput>;
  metadataCustomField15List_some?: InputMaybe<MetadataCustomField15WhereInput>;
  metadataCustomField16List_some?: InputMaybe<MetadataCustomField16WhereInput>;
  metadataCustomField17List_some?: InputMaybe<MetadataCustomField17WhereInput>;
  metadataCustomField20List_some?: InputMaybe<MetadataCustomField20WhereInput>;
  metadataCustomField5List_some?: InputMaybe<MetadataCustomField5WhereInput>;
  metadataCustomField6List_some?: InputMaybe<MetadataCustomField6WhereInput>;
  metadataCustomField7List_some?: InputMaybe<MetadataCustomField7WhereInput>;
  metadataCustomField9List_some?: InputMaybe<MetadataCustomField9WhereInput>;
  metadataDateCreatedList_some?: InputMaybe<MetadataDateCreatedWhereInput>;
  metadataDateTimeDigitizedList_some?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
  metadataDateTimeOriginalList_some?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
  metadataDeliveryAccountList_some?: InputMaybe<MetadataDeliveryAccountWhereInput>;
  metadataDeliveryCompanyList_some?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
  metadataDeliveryCopyrightList_some?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
  metadataDeliveryDateFolderList_some?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
  metadataDeliveryDateTimeList_some?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
  metadataDeliveryDcCreatorList_some?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
  metadataDeliveryDcRightsList_some?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
  metadataDeliveryFileTypeList_some?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
  metadataDeliveryFolderList_some?: InputMaybe<MetadataDeliveryFolderWhereInput>;
  metadataDeliveryKindList_some?: InputMaybe<MetadataDeliveryKindWhereInput>;
  metadataDeliveryPathList_some?: InputMaybe<MetadataDeliveryPathWhereInput>;
  metadataDeliveryPersonShownintheImageList_some?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
  metadataDeliveryPhotoshopCreditList_some?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
  metadataDeliveryPhotoshopSourceList_some?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
  metadataDeliveryServiceList_some?: InputMaybe<MetadataDeliveryServiceWhereInput>;
  metadataDeliverySubjectList_some?: InputMaybe<MetadataDeliverySubjectWhereInput>;
  metadataDeliveryUnderSubjectList_some?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
  metadataDepartmentList_some?: InputMaybe<MetadataDepartmentWhereInput>;
  metadataDescriptionList_some?: InputMaybe<MetadataDescriptionWhereInput>;
  metadataDigitalAssetUrlList_some?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
  metadataDivisionList_some?: InputMaybe<MetadataDivisionWhereInput>;
  metadataDocumentTextList_some?: InputMaybe<MetadataDocumentTextWhereInput>;
  metadataEditionList_some?: InputMaybe<MetadataEditionWhereInput>;
  metadataEditorialVersionList_some?: InputMaybe<MetadataEditorialVersionWhereInput>;
  metadataEditStatusList_some?: InputMaybe<MetadataEditStatusWhereInput>;
  metadataEnvironnementPhotoList_some?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
  metadataEquipmentInstitutionList_some?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
  metadataEquipmentManufacturerList_some?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
  metadataEventList_some?: InputMaybe<MetadataEventWhereInput>;
  metadataExifList_some?: InputMaybe<MetadataExifWhereInput>;
  metadataFabStorageList_some?: InputMaybe<MetadataFabStorageWhereInput>;
  metadataFileCheckForList_some?: InputMaybe<MetadataFileCheckForWhereInput>;
  metadataFileInfoBitsPerPixelList_some?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
  metadataFileInfoFramesList_some?: InputMaybe<MetadataFileInfoFramesWhereInput>;
  metadataFileInfoPhotometricInterpretationList_some?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
  metadataFileInfoTransferSyntaxList_some?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
  metadataFileStageList_some?: InputMaybe<MetadataFileStageWhereInput>;
  metadataFileStatusList_some?: InputMaybe<MetadataFileStatusWhereInput>;
  metadataFileTypeList_some?: InputMaybe<MetadataFileTypeWhereInput>;
  metadataFixtureIdentifierList_some?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
  metadataFormatList_some?: InputMaybe<MetadataFormatWhereInput>;
  metadataGpsLatitudeList_some?: InputMaybe<MetadataGpsLatitudeWhereInput>;
  metadataGpsLongitudeList_some?: InputMaybe<MetadataGpsLongitudeWhereInput>;
  metadataGpsProcessingMethodList_some?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
  metadataHeaderList_some?: InputMaybe<MetadataHeaderWhereInput>;
  metadataHeaderPrismaList_some?: InputMaybe<MetadataHeaderPrismaWhereInput>;
  metadataHeadlineList_some?: InputMaybe<MetadataHeadlineWhereInput>;
  metadataHistoryList_some?: InputMaybe<MetadataHistoryWhereInput>;
  metadataIccProfileList_some?: InputMaybe<MetadataIccProfileWhereInput>;
  metadataIdAssignmentList_some?: InputMaybe<MetadataIdAssignmentWhereInput>;
  metadataIdMediaContentList_some?: InputMaybe<MetadataIdMediaContentWhereInput>;
  metadataIdPhotoPrismaList_some?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
  metadataIdSubjectList_some?: InputMaybe<MetadataIdSubjectWhereInput>;
  metadataImageNotesList_some?: InputMaybe<MetadataImageNotesWhereInput>;
  metadataImageUniqueIdList_some?: InputMaybe<MetadataImageUniqueIdWhereInput>;
  metadataInstructionsList_some?: InputMaybe<MetadataInstructionsWhereInput>;
  metadataIntellectualGenreList_some?: InputMaybe<MetadataIntellectualGenreWhereInput>;
  metadataIsoSpeedRatingsList_some?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
  metadataIssueList_some?: InputMaybe<MetadataIssueWhereInput>;
  metadataJobIdList_some?: InputMaybe<MetadataJobIdWhereInput>;
  metadataJobProcessingList_some?: InputMaybe<MetadataJobProcessingWhereInput>;
  metadataLayoutPictureEditingList_some?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
  metadataLayoutStorageList_some?: InputMaybe<MetadataLayoutStorageWhereInput>;
  metadataLicenseList_some?: InputMaybe<MetadataLicenseWhereInput>;
  metadataLinksList_some?: InputMaybe<MetadataLinksWhereInput>;
  metadataLocalCaptionList_some?: InputMaybe<MetadataLocalCaptionWhereInput>;
  metadataLocationList_some?: InputMaybe<MetadataLocationWhereInput>;
  metadataMakeList_some?: InputMaybe<MetadataMakeWhereInput>;
  metadataManifestList_some?: InputMaybe<MetadataManifestWhereInput>;
  metadataMarkedList_some?: InputMaybe<MetadataMarkedWhereInput>;
  metadataMasterDocumentIdList_some?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
  metadataMaxAvailHeightList_some?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
  metadataMaxAvailWidthList_some?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
  metadataMinorModelAgeDisclosureList_some?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
  metadataModelAgeList_some?: InputMaybe<MetadataModelAgeWhereInput>;
  metadataModelList_some?: InputMaybe<MetadataModelWhereInput>;
  metadataModelReleaseIdList_some?: InputMaybe<MetadataModelReleaseIdWhereInput>;
  metadataModelReleaseStatusList_some?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
  metadataMorePermissionsList_some?: InputMaybe<MetadataMorePermissionsWhereInput>;
  metadataObjectCycleList_some?: InputMaybe<MetadataObjectCycleWhereInput>;
  metadataOrganisationInImageNameList_some?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
  metadataOriginalColorList_some?: InputMaybe<MetadataOriginalColorWhereInput>;
  metadataOriginalIccProfileList_some?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
  metadataOriginalNameList_some?: InputMaybe<MetadataOriginalNameWhereInput>;
  metadataOriginalWeigthList_some?: InputMaybe<MetadataOriginalWeigthWhereInput>;
  metadataOwnerIdList_some?: InputMaybe<MetadataOwnerIdWhereInput>;
  metadataOwnerList_some?: InputMaybe<MetadataOwnerWhereInput>;
  metadataPageList_some?: InputMaybe<MetadataPageWhereInput>;
  metadataPagePrismaList_some?: InputMaybe<MetadataPagePrismaWhereInput>;
  metadataPaintBasedCorrectionsList_some?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
  metadataPatientDobList_some?: InputMaybe<MetadataPatientDobWhereInput>;
  metadataPatientIdList_some?: InputMaybe<MetadataPatientIdWhereInput>;
  metadataPatientNameList_some?: InputMaybe<MetadataPatientNameWhereInput>;
  metadataPatientSexList_some?: InputMaybe<MetadataPatientSexWhereInput>;
  metadataPersonInImageList_some?: InputMaybe<MetadataPersonInImageWhereInput>;
  metadataPhotoStorageList_some?: InputMaybe<MetadataPhotoStorageWhereInput>;
  metadataPrepressPictureEditingList_some?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
  metadataPriceLevelList_some?: InputMaybe<MetadataPriceLevelWhereInput>;
  metadataPrintingProfileList_some?: InputMaybe<MetadataPrintingProfileWhereInput>;
  metadataPrismaProductionList_some?: InputMaybe<MetadataPrismaProductionWhereInput>;
  metadataProcessHistoryList_some?: InputMaybe<MetadataProcessHistoryWhereInput>;
  metadataProcessParameterList_some?: InputMaybe<MetadataProcessParameterWhereInput>;
  metadataProcessStatusList_some?: InputMaybe<MetadataProcessStatusWhereInput>;
  metadataProductList_some?: InputMaybe<MetadataProductWhereInput>;
  metadataProductShortNameList_some?: InputMaybe<MetadataProductShortNameWhereInput>;
  metadataProductsList_some?: InputMaybe<MetadataProductsWhereInput>;
  metadataProduitsList_some?: InputMaybe<MetadataProduitsWhereInput>;
  metadataProgramVersionList_some?: InputMaybe<MetadataProgramVersionWhereInput>;
  metadataPropertyReleaseIdList_some?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
  metadataPropertyReleaseStatusList_some?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
  metadataPublisherList_some?: InputMaybe<MetadataPublisherWhereInput>;
  metadataPublishingIssueList_some?: InputMaybe<MetadataPublishingIssueWhereInput>;
  metadataPublishingSubjectList_some?: InputMaybe<MetadataPublishingSubjectWhereInput>;
  metadataQualifiedUsePrismaByList_some?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
  metadataQualifiedUsePrismaDateList_some?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
  metadataQualifiedUsePrismaDurationList_some?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
  metadataQualifiedUsePrismaSupportList_some?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
  metadataRatingList_some?: InputMaybe<MetadataRatingWhereInput>;
  metadataReferenceDateList_some?: InputMaybe<MetadataReferenceDateWhereInput>;
  metadataReferenceNumberList_some?: InputMaybe<MetadataReferenceNumberWhereInput>;
  metadataReferenceServiceList_some?: InputMaybe<MetadataReferenceServiceWhereInput>;
  metadataReleaseDateList_some?: InputMaybe<MetadataReleaseDateWhereInput>;
  metadataReleaseTimeList_some?: InputMaybe<MetadataReleaseTimeWhereInput>;
  metadataRequiredPermissionList_some?: InputMaybe<MetadataRequiredPermissionWhereInput>;
  metadataResolutionKindList_some?: InputMaybe<MetadataResolutionKindWhereInput>;
  metadataRightsList_some?: InputMaybe<MetadataRightsWhereInput>;
  metadataRoyaltyfreeList_some?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
  metadataSceneList_some?: InputMaybe<MetadataSceneWhereInput>;
  metadataSectionList_some?: InputMaybe<MetadataSectionWhereInput>;
  metadataSentToList_some?: InputMaybe<MetadataSentToWhereInput>;
  metadataSerialNumberList_some?: InputMaybe<MetadataSerialNumberWhereInput>;
  metadataSeriesDateTimeList_some?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
  metadataSeriesDescriptionList_some?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
  metadataSeriesModalityList_some?: InputMaybe<MetadataSeriesModalityWhereInput>;
  metadataSeriesNumberList_some?: InputMaybe<MetadataSeriesNumberWhereInput>;
  metadataShortUniqueIdList_some?: InputMaybe<MetadataShortUniqueIdWhereInput>;
  metadataSourceList_some?: InputMaybe<MetadataSourceWhereInput>;
  metadataStateList_some?: InputMaybe<MetadataStateWhereInput>;
  metadataStudyDateTimeList_some?: InputMaybe<MetadataStudyDateTimeWhereInput>;
  metadataStudyDescriptionList_some?: InputMaybe<MetadataStudyDescriptionWhereInput>;
  metadataStudyIdList_some?: InputMaybe<MetadataStudyIdWhereInput>;
  metadataStudyPhysicianList_some?: InputMaybe<MetadataStudyPhysicianWhereInput>;
  metadataSubjectCodeList_some?: InputMaybe<MetadataSubjectCodeWhereInput>;
  metadataSubjectList_some?: InputMaybe<MetadataSubjectWhereInput>;
  metadataSubjectPrismaList_some?: InputMaybe<MetadataSubjectPrismaWhereInput>;
  metadataSupplementalCategoriesList_some?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
  metadataTargetVersionList_some?: InputMaybe<MetadataTargetVersionWhereInput>;
  metadataTitleList_some?: InputMaybe<MetadataTitleWhereInput>;
  metadataToneCurveList_some?: InputMaybe<MetadataToneCurveWhereInput>;
  metadataTransferredByEmailList_some?: InputMaybe<MetadataTransferredByEmailWhereInput>;
  metadataTransferredByFullNameList_some?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
  metadataTransferredByList_some?: InputMaybe<MetadataTransferredByWhereInput>;
  metadataTransmissionReferenceList_some?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
  metadataTypeList_some?: InputMaybe<MetadataTypeWhereInput>;
  metadataUndersubjectList_some?: InputMaybe<MetadataUndersubjectWhereInput>;
  metadataUnderUnderSubjectList_some?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
  metadataUniqueIdList_some?: InputMaybe<MetadataUniqueIdWhereInput>;
  metadataUnitList_some?: InputMaybe<MetadataUnitWhereInput>;
  metadataUnitShortNameList_some?: InputMaybe<MetadataUnitShortNameWhereInput>;
  metadataUploadedByFullNameList_some?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
  metadataUploadedByList_some?: InputMaybe<MetadataUploadedByWhereInput>;
  metadataUploadTimeList_some?: InputMaybe<MetadataUploadTimeWhereInput>;
  metadataUrgencyList_some?: InputMaybe<MetadataUrgencyWhereInput>;
  metadataUsageTermsList_some?: InputMaybe<MetadataUsageTermsWhereInput>;
  metadataUserCommentList_some?: InputMaybe<MetadataUserCommentWhereInput>;
  metadataUserDefined195List_some?: InputMaybe<MetadataUserDefined195WhereInput>;
  metadataUserDefined237List_some?: InputMaybe<MetadataUserDefined237WhereInput>;
  metadataUserDefined238List_some?: InputMaybe<MetadataUserDefined238WhereInput>;
  metadataUserDefined239List_some?: InputMaybe<MetadataUserDefined239WhereInput>;
  metadataUserDefined242List_some?: InputMaybe<MetadataUserDefined242WhereInput>;
  metadataUserDefined62List_some?: InputMaybe<MetadataUserDefined62WhereInput>;
  metadataValidList_some?: InputMaybe<MetadataValidWhereInput>;
  metadataVersionList_some?: InputMaybe<MetadataVersionWhereInput>;
  metadataWebStatementList_some?: InputMaybe<MetadataWebStatementWhereInput>;
  metadataWorkflowKindList_some?: InputMaybe<MetadataWorkflowKindWhereInput>;
  metadataXmpFileStampsList_some?: InputMaybe<MetadataXmpFileStampsWhereInput>;
  metadataXmpHistoryList_some?: InputMaybe<MetadataXmpHistoryWhereInput>;
  name?: InputMaybe<ArchiveKeyType>;
  name_in?: InputMaybe<Array<InputMaybe<ArchiveKeyType>>>;
  name_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  name_not?: InputMaybe<ArchiveKeyType>;
  name_not_in?: InputMaybe<Array<InputMaybe<ArchiveKeyType>>>;
  NOT?: InputMaybe<ArchiveWhereInput>;
  OR?: InputMaybe<Array<ArchiveWhereInput>>;
  photoMetadatas_some?: InputMaybe<PhotoMetadataWhereInput>;
  photos_some?: InputMaybe<PhotoWhereInput>;
  type?: InputMaybe<ArchiveType>;
  type_in?: InputMaybe<Array<InputMaybe<ArchiveType>>>;
  type_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  type_not?: InputMaybe<ArchiveType>;
  type_not_in?: InputMaybe<Array<InputMaybe<ArchiveType>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/**
 * Identifies exactly one "Archive" node with one of these unique values:
 * - _id
 * - id
 * - archiveId
 */
export type ArchiveWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  archiveId?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

/** "Category" resource's node */
export type Category = {
  __typename?: 'Category';
  /** The internal ID */
  _id: Scalars['Int']['output'];
  archive: Archive;
  /** "Category.parent"'s inverse relation count */
  childCount: Scalars['Int']['output'];
  /** "Category.parent"'s inverse relation */
  children: Array<Category>;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The public ID, an UUID v4 */
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<Category>;
  /** "Photo.category"'s inverse relation count */
  photoCount: Scalars['Int']['output'];
  /** "Photo.category"'s inverse relation */
  photos: Array<Photo>;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};


/** "Category" resource's node */
export type CategoryChildCountArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


/** "Category" resource's node */
export type CategoryChildrenArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<CategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


/** "Category" resource's node */
export type CategoryPhotoCountArgs = {
  where?: InputMaybe<PhotoWhereInput>;
};


/** "Category" resource's node */
export type CategoryPhotosArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoWhereInput>;
};

export type CategoryCreateInput = {
  /** Actions for the "Category.archive" relation */
  archive: CategoryNestedArchiveCreateInput;
  /** Actions for the "Category.children" relation: "Category.parent"'s inverse relation */
  children?: InputMaybe<CategoryNestedChildrenCreateInput>;
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Category.parent" relation */
  parent?: InputMaybe<CategoryNestedParentCreateInput>;
  /** Actions for the "Category.photos" relation: "Photo.category"'s inverse relation */
  photos?: InputMaybe<CategoryNestedPhotosCreateInput>;
};

export type CategoryNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "Category" node, through the "Category.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "Category" node, through the "Category.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "Category" node, through the "Category.archive" relation. */
  update?: InputMaybe<CategoryNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "Category" node, through the "Category.archive" relation. */
  upsert?: InputMaybe<CategoryNestedUpsertArchiveCreateInput>;
};

export type CategoryNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "Category" node, through the "Category.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "Category" node, through the "Category.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "Category" node, through the "Category.archive" relation. */
  update?: InputMaybe<CategoryNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "Category" node, through the "Category.archive" relation. */
  upsert?: InputMaybe<CategoryNestedUpsertArchiveUpdateInput>;
};

export type CategoryNestedChildrenCreateInput = {
  /** Connect existing "Category" nodes to the new "Category" node, through the "Category.children" relation. */
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Create new "Category" nodes and connect them to the new "Category" node, through the "Category.children" relation. */
  create?: InputMaybe<Array<CategoryWithForcedParentCreateInput>>;
};

export type CategoryNestedChildrenUpdateInput = {
  /** Connect existing "Category" nodes to the existing "Category" node, through the "Category.children" relation. */
  connect?: InputMaybe<Array<CategoryWhereUniqueInput>>;
  /** Create new "Category" nodes and connect them to the existing "Category" node, through the "Category.children" relation. */
  create?: InputMaybe<Array<CategoryWithForcedParentCreateInput>>;
  /** Delete existing "Category" nodes from the "Category.children" relation. */
  delete?: InputMaybe<Array<CategoryWithForcedParentWhereUniqueInput>>;
  /** Disconnect existing "Category" nodes of the "Category.children" relation. */
  disconnect?: InputMaybe<Array<CategoryWithForcedParentWhereUniqueInput>>;
  /** Update existing "Category" nodes and connect them to the existing "Category" node, through the "Category.children" relation. */
  update?: InputMaybe<Array<CategoryNestedUpdateChildrenUpdateInput>>;
  /** Upsert existing "Category" nodes and connect them to the existing "Category" node, through the "Category.children" relation. */
  upsert?: InputMaybe<Array<CategoryNestedUpsertChildrenUpdateInput>>;
};

export type CategoryNestedParentCreateInput = {
  /** Connect an existing "Category" node to the new "Category" node, through the "Category.parent" relation. */
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  /** Create a new "Category" node and connect it to the new "Category" node, through the "Category.parent" relation. */
  create?: InputMaybe<CategoryCreateInput>;
  /** Update an existing "Category" node and connect it to the new "Category" node, through the "Category.parent" relation. */
  update?: InputMaybe<CategoryNestedUpdateParentCreateInput>;
  /** Create or update a "Category" node and connect it to the new "Category" node, through the "Category.parent" relation. */
  upsert?: InputMaybe<CategoryNestedUpsertParentCreateInput>;
};

export type CategoryNestedParentUpdateInput = {
  /** Connect an existing "Category" node to the existing "Category" node, through the "Category.parent" relation. */
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  /** Create a new "Category" node and connect it to the existing "Category" node, through the "Category.parent" relation. */
  create?: InputMaybe<CategoryCreateInput>;
  /** Disconnect the current connected "Category" node, if any, of the "Category.parent" relation. */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update an existing "Category" node and connect it to the existing "Category" node, through the "Category.parent" relation. */
  update?: InputMaybe<CategoryNestedUpdateParentUpdateInput>;
  /** Create or update a "Category" node and connect it to the existing "Category" node, through the "Category.parent" relation. */
  upsert?: InputMaybe<CategoryNestedUpsertParentUpdateInput>;
};

export type CategoryNestedPhotosCreateInput = {
  /** Connect existing "Photo" nodes to the new "Category" node, through the "Category.photos" relation. */
  connect?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Create new "Photo" nodes and connect them to the new "Category" node, through the "Category.photos" relation. */
  create?: InputMaybe<Array<PhotoWithForcedCategoryCreateInput>>;
};

export type CategoryNestedPhotosUpdateInput = {
  /** Connect existing "Photo" nodes to the existing "Category" node, through the "Category.photos" relation. */
  connect?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Create new "Photo" nodes and connect them to the existing "Category" node, through the "Category.photos" relation. */
  create?: InputMaybe<Array<PhotoWithForcedCategoryCreateInput>>;
  /** Delete existing "Photo" nodes from the "Category.photos" relation. */
  delete?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Disconnect existing "Photo" nodes of the "Category.photos" relation. */
  disconnect?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Update existing "Photo" nodes and connect them to the existing "Category" node, through the "Category.photos" relation. */
  update?: InputMaybe<Array<CategoryNestedUpdatePhotosUpdateInput>>;
  /** Upsert existing "Photo" nodes and connect them to the existing "Category" node, through the "Category.photos" relation. */
  upsert?: InputMaybe<Array<CategoryNestedUpsertPhotosUpdateInput>>;
};

export type CategoryNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type CategoryNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type CategoryNestedUpdateChildrenUpdateInput = {
  data: CategoryWithForcedParentUpdateInput;
  where: CategoryWithOptionalParentWhereUniqueInput;
};

export type CategoryNestedUpdateParentCreateInput = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryNestedUpdateParentUpdateInput = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryNestedUpdatePhotosUpdateInput = {
  data: PhotoWithForcedCategoryUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type CategoryNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type CategoryNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type CategoryNestedUpsertChildrenUpdateInput = {
  create: CategoryWithForcedParentCreateInput;
  update: CategoryWithForcedParentUpdateInput;
  where: CategoryWithOptionalParentWhereUniqueInput;
};

export type CategoryNestedUpsertParentCreateInput = {
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryNestedUpsertParentUpdateInput = {
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type CategoryNestedUpsertPhotosUpdateInput = {
  create: PhotoWithForcedCategoryCreateInput;
  update: PhotoWithForcedCategoryUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum CategoryOrderByInput {
  _id_ASC = '_id_ASC',
  _id_DESC = '_id_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  label_ASC = 'label_ASC',
  label_DESC = 'label_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC'
}

export type CategoryUpdateInput = {
  /** Actions for the "Category.archive" relation */
  archive?: InputMaybe<CategoryNestedArchiveUpdateInput>;
  /** Actions for the "Category.children" relation: "Category.parent"'s inverse relation */
  children?: InputMaybe<CategoryNestedChildrenUpdateInput>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Category.parent" relation */
  parent?: InputMaybe<CategoryNestedParentUpdateInput>;
  /** Actions for the "Category.photos" relation: "Photo.category"'s inverse relation */
  photos?: InputMaybe<CategoryNestedPhotosUpdateInput>;
};

/** Filters the "Category" nodes by specifying some conditions */
export type CategoryWhereInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  _id_gt?: InputMaybe<Scalars['Int']['input']>;
  _id_gte?: InputMaybe<Scalars['Int']['input']>;
  _id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _id_lt?: InputMaybe<Scalars['Int']['input']>;
  _id_lte?: InputMaybe<Scalars['Int']['input']>;
  _id_not?: InputMaybe<Scalars['Int']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  AND?: InputMaybe<Array<CategoryWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  children_some?: InputMaybe<CategoryWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_ends_with?: InputMaybe<Scalars['String']['input']>;
  label_gt?: InputMaybe<Scalars['String']['input']>;
  label_gte?: InputMaybe<Scalars['String']['input']>;
  label_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  label_lt?: InputMaybe<Scalars['String']['input']>;
  label_lte?: InputMaybe<Scalars['String']['input']>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  label_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  label_starts_with?: InputMaybe<Scalars['String']['input']>;
  NOT?: InputMaybe<CategoryWhereInput>;
  OR?: InputMaybe<Array<CategoryWhereInput>>;
  parent?: InputMaybe<CategoryWhereInput>;
  parent_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  photos_some?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/**
 * Identifies exactly one "Category" node with one of these unique combinations of values:
 * - _id
 * - id
 * - archive, label, parent
 */
export type CategoryWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  archive?: InputMaybe<ArchiveWhereUniqueInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<CategoryWhereUniqueInput>;
};

export type CategoryWithForcedArchiveCreateInput = {
  /** Actions for the "Category.children" relation: "Category.parent"'s inverse relation */
  children?: InputMaybe<CategoryNestedChildrenCreateInput>;
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Category.parent" relation */
  parent?: InputMaybe<CategoryNestedParentCreateInput>;
  /** Actions for the "Category.photos" relation: "Photo.category"'s inverse relation */
  photos?: InputMaybe<CategoryNestedPhotosCreateInput>;
};

export type CategoryWithForcedArchiveUpdateInput = {
  /** Actions for the "Category.children" relation: "Category.parent"'s inverse relation */
  children?: InputMaybe<CategoryNestedChildrenUpdateInput>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Category.parent" relation */
  parent?: InputMaybe<CategoryNestedParentUpdateInput>;
  /** Actions for the "Category.photos" relation: "Photo.category"'s inverse relation */
  photos?: InputMaybe<CategoryNestedPhotosUpdateInput>;
};

/** Given a forced "archive", identifies exactly one "Category" node. */
export type CategoryWithForcedArchiveWhereUniqueInput = {
  label: Scalars['String']['input'];
  parent: CategoryWhereUniqueInput;
};

export type CategoryWithForcedParentCreateInput = {
  /** Actions for the "Category.archive" relation */
  archive: CategoryNestedArchiveCreateInput;
  /** Actions for the "Category.children" relation: "Category.parent"'s inverse relation */
  children?: InputMaybe<CategoryNestedChildrenCreateInput>;
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Category.photos" relation: "Photo.category"'s inverse relation */
  photos?: InputMaybe<CategoryNestedPhotosCreateInput>;
};

export type CategoryWithForcedParentUpdateInput = {
  /** Actions for the "Category.archive" relation */
  archive?: InputMaybe<CategoryNestedArchiveUpdateInput>;
  /** Actions for the "Category.children" relation: "Category.parent"'s inverse relation */
  children?: InputMaybe<CategoryNestedChildrenUpdateInput>;
  label?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Category.photos" relation: "Photo.category"'s inverse relation */
  photos?: InputMaybe<CategoryNestedPhotosUpdateInput>;
};

/** Given a forced "parent", identifies exactly one "Category" node. */
export type CategoryWithForcedParentWhereUniqueInput = {
  archive: ArchiveWhereUniqueInput;
  label: Scalars['String']['input'];
};

/**
 * Given a known "archive", identifies exactly one "Category" node with one of these unique combinations of values:
 * - _id
 * - id
 * - archive (optional), label, parent
 */
export type CategoryWithOptionalArchiveWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  archive?: InputMaybe<ArchiveWhereUniqueInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<CategoryWhereUniqueInput>;
};

/**
 * Given a known "parent", identifies exactly one "Category" node with one of these unique combinations of values:
 * - _id
 * - id
 * - archive, label, parent (optional)
 */
export type CategoryWithOptionalParentWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  archive?: InputMaybe<ArchiveWhereUniqueInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<CategoryWhereUniqueInput>;
};

/** "DeliverySubject" resource's node */
export type DeliverySubject = {
  __typename?: 'DeliverySubject';
  /** The internal ID */
  _id: Scalars['Int']['output'];
  agency: Scalars['String']['output'];
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  /** "Photo.deliverySubject"'s inverse relation count */
  photoCount: Scalars['Int']['output'];
  /** "Photo.deliverySubject"'s inverse relation */
  photos: Array<Photo>;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};


/** "DeliverySubject" resource's node */
export type DeliverySubjectPhotoCountArgs = {
  where?: InputMaybe<PhotoWhereInput>;
};


/** "DeliverySubject" resource's node */
export type DeliverySubjectPhotosArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoWhereInput>;
};

export type DeliverySubjectCreateInput = {
  agency: Scalars['String']['input'];
  /** Actions for the "DeliverySubject.archive" relation */
  archive: DeliverySubjectNestedArchiveCreateInput;
  name: Scalars['String']['input'];
  /** Actions for the "DeliverySubject.photos" relation: "Photo.deliverySubject"'s inverse relation */
  photos?: InputMaybe<DeliverySubjectNestedPhotosCreateInput>;
};

export type DeliverySubjectNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "DeliverySubject" node, through the "DeliverySubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "DeliverySubject" node, through the "DeliverySubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "DeliverySubject" node, through the "DeliverySubject.archive" relation. */
  update?: InputMaybe<DeliverySubjectNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "DeliverySubject" node, through the "DeliverySubject.archive" relation. */
  upsert?: InputMaybe<DeliverySubjectNestedUpsertArchiveCreateInput>;
};

export type DeliverySubjectNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "DeliverySubject" node, through the "DeliverySubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "DeliverySubject" node, through the "DeliverySubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "DeliverySubject" node, through the "DeliverySubject.archive" relation. */
  update?: InputMaybe<DeliverySubjectNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "DeliverySubject" node, through the "DeliverySubject.archive" relation. */
  upsert?: InputMaybe<DeliverySubjectNestedUpsertArchiveUpdateInput>;
};

export type DeliverySubjectNestedPhotosCreateInput = {
  /** Connect existing "Photo" nodes to the new "DeliverySubject" node, through the "DeliverySubject.photos" relation. */
  connect?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Create new "Photo" nodes and connect them to the new "DeliverySubject" node, through the "DeliverySubject.photos" relation. */
  create?: InputMaybe<Array<PhotoWithForcedDeliverySubjectCreateInput>>;
};

export type DeliverySubjectNestedPhotosUpdateInput = {
  /** Connect existing "Photo" nodes to the existing "DeliverySubject" node, through the "DeliverySubject.photos" relation. */
  connect?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Create new "Photo" nodes and connect them to the existing "DeliverySubject" node, through the "DeliverySubject.photos" relation. */
  create?: InputMaybe<Array<PhotoWithForcedDeliverySubjectCreateInput>>;
  /** Delete existing "Photo" nodes from the "DeliverySubject.photos" relation. */
  delete?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Disconnect existing "Photo" nodes of the "DeliverySubject.photos" relation. */
  disconnect?: InputMaybe<Array<PhotoWhereUniqueInput>>;
  /** Update existing "Photo" nodes and connect them to the existing "DeliverySubject" node, through the "DeliverySubject.photos" relation. */
  update?: InputMaybe<Array<DeliverySubjectNestedUpdatePhotosUpdateInput>>;
  /** Upsert existing "Photo" nodes and connect them to the existing "DeliverySubject" node, through the "DeliverySubject.photos" relation. */
  upsert?: InputMaybe<Array<DeliverySubjectNestedUpsertPhotosUpdateInput>>;
};

export type DeliverySubjectNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type DeliverySubjectNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type DeliverySubjectNestedUpdatePhotosUpdateInput = {
  data: PhotoWithForcedDeliverySubjectUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type DeliverySubjectNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type DeliverySubjectNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type DeliverySubjectNestedUpsertPhotosUpdateInput = {
  create: PhotoWithForcedDeliverySubjectCreateInput;
  update: PhotoWithForcedDeliverySubjectUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum DeliverySubjectOrderByInput {
  _id_ASC = '_id_ASC',
  _id_DESC = '_id_DESC',
  agency_ASC = 'agency_ASC',
  agency_DESC = 'agency_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  name_ASC = 'name_ASC',
  name_DESC = 'name_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC'
}

export type DeliverySubjectUpdateInput = {
  agency?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "DeliverySubject.archive" relation */
  archive?: InputMaybe<DeliverySubjectNestedArchiveUpdateInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "DeliverySubject.photos" relation: "Photo.deliverySubject"'s inverse relation */
  photos?: InputMaybe<DeliverySubjectNestedPhotosUpdateInput>;
};

/** Filters the "DeliverySubject" nodes by specifying some conditions */
export type DeliverySubjectWhereInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  _id_gt?: InputMaybe<Scalars['Int']['input']>;
  _id_gte?: InputMaybe<Scalars['Int']['input']>;
  _id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _id_lt?: InputMaybe<Scalars['Int']['input']>;
  _id_lte?: InputMaybe<Scalars['Int']['input']>;
  _id_not?: InputMaybe<Scalars['Int']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  agency?: InputMaybe<Scalars['String']['input']>;
  agency_contains?: InputMaybe<Scalars['String']['input']>;
  agency_ends_with?: InputMaybe<Scalars['String']['input']>;
  agency_gt?: InputMaybe<Scalars['String']['input']>;
  agency_gte?: InputMaybe<Scalars['String']['input']>;
  agency_in?: InputMaybe<Array<Scalars['String']['input']>>;
  agency_lt?: InputMaybe<Scalars['String']['input']>;
  agency_lte?: InputMaybe<Scalars['String']['input']>;
  agency_not?: InputMaybe<Scalars['String']['input']>;
  agency_not_contains?: InputMaybe<Scalars['String']['input']>;
  agency_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  agency_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  agency_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  agency_starts_with?: InputMaybe<Scalars['String']['input']>;
  AND?: InputMaybe<Array<DeliverySubjectWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  NOT?: InputMaybe<DeliverySubjectWhereInput>;
  OR?: InputMaybe<Array<DeliverySubjectWhereInput>>;
  photos_some?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/**
 * Identifies exactly one "DeliverySubject" node with one of these unique combinations of values:
 * - _id
 * - agency, archive, name
 */
export type DeliverySubjectWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  agency?: InputMaybe<Scalars['String']['input']>;
  archive?: InputMaybe<ArchiveWhereUniqueInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type DeliverySubjectWithForcedArchiveCreateInput = {
  agency: Scalars['String']['input'];
  name: Scalars['String']['input'];
  /** Actions for the "DeliverySubject.photos" relation: "Photo.deliverySubject"'s inverse relation */
  photos?: InputMaybe<DeliverySubjectNestedPhotosCreateInput>;
};

export type DeliverySubjectWithForcedArchiveUpdateInput = {
  agency?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "DeliverySubject.photos" relation: "Photo.deliverySubject"'s inverse relation */
  photos?: InputMaybe<DeliverySubjectNestedPhotosUpdateInput>;
};

/** Given a forced "archive", identifies exactly one "DeliverySubject" node. */
export type DeliverySubjectWithForcedArchiveWhereUniqueInput = {
  agency: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

/**
 * Given a known "archive", identifies exactly one "DeliverySubject" node with one of these unique combinations of values:
 * - _id
 * - agency, archive (optional), name
 */
export type DeliverySubjectWithOptionalArchiveWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  agency?: InputMaybe<Scalars['String']['input']>;
  archive?: InputMaybe<ArchiveWhereUniqueInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** "GcImportSetting" resource's node */
export type GcImportSetting = {
  __typename?: 'GcImportSetting';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  enabled?: Maybe<Scalars['Boolean']['output']>;
  /** The ID */
  id: Scalars['Int']['output'];
  period?: Maybe<Scalars['Int']['output']>;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};

export type GcImportSettingCreateInput = {
  /** Actions for the "GcImportSetting.archive" relation */
  archive: GcImportSettingNestedArchiveCreateInput;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['Int']['input']>;
};

export type GcImportSettingNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "GcImportSetting" node, through the "GcImportSetting.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "GcImportSetting" node, through the "GcImportSetting.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "GcImportSetting" node, through the "GcImportSetting.archive" relation. */
  update?: InputMaybe<GcImportSettingNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "GcImportSetting" node, through the "GcImportSetting.archive" relation. */
  upsert?: InputMaybe<GcImportSettingNestedUpsertArchiveCreateInput>;
};

export type GcImportSettingNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "GcImportSetting" node, through the "GcImportSetting.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "GcImportSetting" node, through the "GcImportSetting.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "GcImportSetting" node, through the "GcImportSetting.archive" relation. */
  update?: InputMaybe<GcImportSettingNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "GcImportSetting" node, through the "GcImportSetting.archive" relation. */
  upsert?: InputMaybe<GcImportSettingNestedUpsertArchiveUpdateInput>;
};

export type GcImportSettingNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type GcImportSettingNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type GcImportSettingNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type GcImportSettingNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export enum GcImportSettingOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  enabled_ASC = 'enabled_ASC',
  enabled_DESC = 'enabled_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  period_ASC = 'period_ASC',
  period_DESC = 'period_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC'
}

export type GcImportSettingUpdateInput = {
  /** Actions for the "GcImportSetting.archive" relation */
  archive?: InputMaybe<GcImportSettingNestedArchiveUpdateInput>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['Int']['input']>;
};

/** Filters the "GcImportSetting" nodes by specifying some conditions */
export type GcImportSettingWhereInput = {
  AND?: InputMaybe<Array<GcImportSettingWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  enabled_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_not?: InputMaybe<Scalars['Boolean']['input']>;
  enabled_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  NOT?: InputMaybe<GcImportSettingWhereInput>;
  OR?: InputMaybe<Array<GcImportSettingWhereInput>>;
  period?: InputMaybe<Scalars['Int']['input']>;
  period_gt?: InputMaybe<Scalars['Int']['input']>;
  period_gte?: InputMaybe<Scalars['Int']['input']>;
  period_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  period_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  period_lt?: InputMaybe<Scalars['Int']['input']>;
  period_lte?: InputMaybe<Scalars['Int']['input']>;
  period_not?: InputMaybe<Scalars['Int']['input']>;
  period_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/**
 * Identifies exactly one "GcImportSetting" node with one of these unique values:
 * - id
 * - archive
 */
export type GcImportSettingWhereUniqueInput = {
  archive?: InputMaybe<ArchiveWhereUniqueInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

export type GcImportSettingWithForcedArchiveCreateInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['Int']['input']>;
};

export type GcImportSettingWithForcedArchiveUpdateInput = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  period?: InputMaybe<Scalars['Int']['input']>;
};

/**
 * Given a known "archive", identifies exactly one "GcImportSetting" node with one of these unique values:
 * - id
 * - archive (optional)
 */
export type GcImportSettingWithOptionalArchiveWhereUniqueInput = {
  archive?: InputMaybe<ArchiveWhereUniqueInput>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** "ImportQueue" resource's node */
export type ImportQueue = {
  __typename?: 'ImportQueue';
  /** The internal ID */
  _id: Scalars['Int']['output'];
  agency?: Maybe<Scalars['String']['output']>;
  archive?: Maybe<Archive>;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  filename: Scalars['String']['output'];
  /** The date of photo importation */
  importedAt?: Maybe<Scalars['DateTime']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Photo>;
  retry?: Maybe<Scalars['Int']['output']>;
  s3key: Scalars['String']['output'];
  size?: Maybe<Scalars['Int']['output']>;
  /** import status */
  status: ImportQueueStatusType;
  subject?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};

export type ImportQueueCreateInput = {
  agency?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "ImportQueue.archive" relation */
  archive?: InputMaybe<ImportQueueNestedArchiveCreateInput>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  filename: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "ImportQueue.photo" relation */
  photo?: InputMaybe<ImportQueueNestedPhotoCreateInput>;
  retry?: InputMaybe<Scalars['Int']['input']>;
  s3key: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
  /** import status */
  status: ImportQueueStatusType;
  subject?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ImportQueueNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "ImportQueue" node, through the "ImportQueue.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "ImportQueue" node, through the "ImportQueue.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "ImportQueue" node, through the "ImportQueue.archive" relation. */
  update?: InputMaybe<ImportQueueNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "ImportQueue" node, through the "ImportQueue.archive" relation. */
  upsert?: InputMaybe<ImportQueueNestedUpsertArchiveCreateInput>;
};

export type ImportQueueNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "ImportQueue" node, through the "ImportQueue.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "ImportQueue" node, through the "ImportQueue.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Disconnect the current connected "Archive" node, if any, of the "ImportQueue.archive" relation. */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update an existing "Archive" node and connect it to the existing "ImportQueue" node, through the "ImportQueue.archive" relation. */
  update?: InputMaybe<ImportQueueNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "ImportQueue" node, through the "ImportQueue.archive" relation. */
  upsert?: InputMaybe<ImportQueueNestedUpsertArchiveUpdateInput>;
};

export type ImportQueueNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "ImportQueue" node, through the "ImportQueue.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "ImportQueue" node, through the "ImportQueue.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "ImportQueue" node, through the "ImportQueue.photo" relation. */
  update?: InputMaybe<ImportQueueNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "ImportQueue" node, through the "ImportQueue.photo" relation. */
  upsert?: InputMaybe<ImportQueueNestedUpsertPhotoCreateInput>;
};

export type ImportQueueNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "ImportQueue" node, through the "ImportQueue.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "ImportQueue" node, through the "ImportQueue.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Disconnect the current connected "Photo" node, if any, of the "ImportQueue.photo" relation. */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update an existing "Photo" node and connect it to the existing "ImportQueue" node, through the "ImportQueue.photo" relation. */
  update?: InputMaybe<ImportQueueNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "ImportQueue" node, through the "ImportQueue.photo" relation. */
  upsert?: InputMaybe<ImportQueueNestedUpsertPhotoUpdateInput>;
};

export type ImportQueueNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type ImportQueueNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type ImportQueueNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type ImportQueueNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type ImportQueueNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type ImportQueueNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type ImportQueueNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type ImportQueueNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum ImportQueueOrderByInput {
  _id_ASC = '_id_ASC',
  _id_DESC = '_id_DESC',
  agency_ASC = 'agency_ASC',
  agency_DESC = 'agency_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  duration_ASC = 'duration_ASC',
  duration_DESC = 'duration_DESC',
  filename_ASC = 'filename_ASC',
  filename_DESC = 'filename_DESC',
  importedAt_ASC = 'importedAt_ASC',
  importedAt_DESC = 'importedAt_DESC',
  message_ASC = 'message_ASC',
  message_DESC = 'message_DESC',
  retry_ASC = 'retry_ASC',
  retry_DESC = 'retry_DESC',
  s3key_ASC = 's3key_ASC',
  s3key_DESC = 's3key_DESC',
  size_ASC = 'size_ASC',
  size_DESC = 'size_DESC',
  status_ASC = 'status_ASC',
  status_DESC = 'status_DESC',
  subject_ASC = 'subject_ASC',
  subject_DESC = 'subject_DESC',
  type_ASC = 'type_ASC',
  type_DESC = 'type_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC'
}

export enum ImportQueueStatusType {
  DELETED = 'DELETED',
  FAILED = 'FAILED',
  FINISHED = 'FINISHED',
  PENDING = 'PENDING'
}

export type ImportQueueUpdateInput = {
  agency?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "ImportQueue.archive" relation */
  archive?: InputMaybe<ImportQueueNestedArchiveUpdateInput>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "ImportQueue.photo" relation */
  photo?: InputMaybe<ImportQueueNestedPhotoUpdateInput>;
  retry?: InputMaybe<Scalars['Int']['input']>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  /** import status */
  status?: InputMaybe<ImportQueueStatusType>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "ImportQueue" nodes by specifying some conditions */
export type ImportQueueWhereInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  _id_gt?: InputMaybe<Scalars['Int']['input']>;
  _id_gte?: InputMaybe<Scalars['Int']['input']>;
  _id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _id_lt?: InputMaybe<Scalars['Int']['input']>;
  _id_lte?: InputMaybe<Scalars['Int']['input']>;
  _id_not?: InputMaybe<Scalars['Int']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  agency?: InputMaybe<Scalars['String']['input']>;
  agency_contains?: InputMaybe<Scalars['String']['input']>;
  agency_ends_with?: InputMaybe<Scalars['String']['input']>;
  agency_gt?: InputMaybe<Scalars['String']['input']>;
  agency_gte?: InputMaybe<Scalars['String']['input']>;
  agency_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  agency_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  agency_lt?: InputMaybe<Scalars['String']['input']>;
  agency_lte?: InputMaybe<Scalars['String']['input']>;
  agency_not?: InputMaybe<Scalars['String']['input']>;
  agency_not_contains?: InputMaybe<Scalars['String']['input']>;
  agency_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  agency_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  agency_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  agency_starts_with?: InputMaybe<Scalars['String']['input']>;
  AND?: InputMaybe<Array<ImportQueueWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  archive_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  duration_gt?: InputMaybe<Scalars['Int']['input']>;
  duration_gte?: InputMaybe<Scalars['Int']['input']>;
  duration_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  duration_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  duration_lt?: InputMaybe<Scalars['Int']['input']>;
  duration_lte?: InputMaybe<Scalars['Int']['input']>;
  duration_not?: InputMaybe<Scalars['Int']['input']>;
  duration_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filename_contains?: InputMaybe<Scalars['String']['input']>;
  filename_ends_with?: InputMaybe<Scalars['String']['input']>;
  filename_gt?: InputMaybe<Scalars['String']['input']>;
  filename_gte?: InputMaybe<Scalars['String']['input']>;
  filename_in?: InputMaybe<Array<Scalars['String']['input']>>;
  filename_lt?: InputMaybe<Scalars['String']['input']>;
  filename_lte?: InputMaybe<Scalars['String']['input']>;
  filename_not?: InputMaybe<Scalars['String']['input']>;
  filename_not_contains?: InputMaybe<Scalars['String']['input']>;
  filename_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  filename_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  filename_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  filename_starts_with?: InputMaybe<Scalars['String']['input']>;
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  importedAt_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  importedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  message?: InputMaybe<Scalars['String']['input']>;
  message_contains?: InputMaybe<Scalars['String']['input']>;
  message_ends_with?: InputMaybe<Scalars['String']['input']>;
  message_gt?: InputMaybe<Scalars['String']['input']>;
  message_gte?: InputMaybe<Scalars['String']['input']>;
  message_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  message_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  message_lt?: InputMaybe<Scalars['String']['input']>;
  message_lte?: InputMaybe<Scalars['String']['input']>;
  message_not?: InputMaybe<Scalars['String']['input']>;
  message_not_contains?: InputMaybe<Scalars['String']['input']>;
  message_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  message_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  message_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  message_starts_with?: InputMaybe<Scalars['String']['input']>;
  NOT?: InputMaybe<ImportQueueWhereInput>;
  OR?: InputMaybe<Array<ImportQueueWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  photo_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  retry?: InputMaybe<Scalars['Int']['input']>;
  retry_gt?: InputMaybe<Scalars['Int']['input']>;
  retry_gte?: InputMaybe<Scalars['Int']['input']>;
  retry_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  retry_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  retry_lt?: InputMaybe<Scalars['Int']['input']>;
  retry_lte?: InputMaybe<Scalars['Int']['input']>;
  retry_not?: InputMaybe<Scalars['Int']['input']>;
  retry_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  s3key_contains?: InputMaybe<Scalars['String']['input']>;
  s3key_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3key_gt?: InputMaybe<Scalars['String']['input']>;
  s3key_gte?: InputMaybe<Scalars['String']['input']>;
  s3key_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3key_lt?: InputMaybe<Scalars['String']['input']>;
  s3key_lte?: InputMaybe<Scalars['String']['input']>;
  s3key_not?: InputMaybe<Scalars['String']['input']>;
  s3key_not_contains?: InputMaybe<Scalars['String']['input']>;
  s3key_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  s3key_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  s3key_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  s3key_starts_with?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  size_gt?: InputMaybe<Scalars['Int']['input']>;
  size_gte?: InputMaybe<Scalars['Int']['input']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  size_lt?: InputMaybe<Scalars['Int']['input']>;
  size_lte?: InputMaybe<Scalars['Int']['input']>;
  size_not?: InputMaybe<Scalars['Int']['input']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  status?: InputMaybe<ImportQueueStatusType>;
  status_in?: InputMaybe<Array<ImportQueueStatusType>>;
  status_not?: InputMaybe<ImportQueueStatusType>;
  status_not_in?: InputMaybe<Array<ImportQueueStatusType>>;
  subject?: InputMaybe<Scalars['String']['input']>;
  subject_contains?: InputMaybe<Scalars['String']['input']>;
  subject_ends_with?: InputMaybe<Scalars['String']['input']>;
  subject_gt?: InputMaybe<Scalars['String']['input']>;
  subject_gte?: InputMaybe<Scalars['String']['input']>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subject_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  subject_lt?: InputMaybe<Scalars['String']['input']>;
  subject_lte?: InputMaybe<Scalars['String']['input']>;
  subject_not?: InputMaybe<Scalars['String']['input']>;
  subject_not_contains?: InputMaybe<Scalars['String']['input']>;
  subject_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subject_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  subject_starts_with?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_contains?: InputMaybe<Scalars['String']['input']>;
  type_ends_with?: InputMaybe<Scalars['String']['input']>;
  type_gt?: InputMaybe<Scalars['String']['input']>;
  type_gte?: InputMaybe<Scalars['String']['input']>;
  type_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  type_lt?: InputMaybe<Scalars['String']['input']>;
  type_lte?: InputMaybe<Scalars['String']['input']>;
  type_not?: InputMaybe<Scalars['String']['input']>;
  type_not_contains?: InputMaybe<Scalars['String']['input']>;
  type_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  type_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  type_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/**
 * Identifies exactly one "ImportQueue" node with one of these unique values:
 * - _id
 * - s3key
 * - photo
 */
export type ImportQueueWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  photo?: InputMaybe<PhotoWhereUniqueInput>;
  s3key?: InputMaybe<Scalars['String']['input']>;
};

export type ImportQueueWithForcedArchiveCreateInput = {
  agency?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  filename: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "ImportQueue.photo" relation */
  photo?: InputMaybe<ImportQueueNestedPhotoCreateInput>;
  retry?: InputMaybe<Scalars['Int']['input']>;
  s3key: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
  /** import status */
  status: ImportQueueStatusType;
  subject?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ImportQueueWithForcedArchiveUpdateInput = {
  agency?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "ImportQueue.photo" relation */
  photo?: InputMaybe<ImportQueueNestedPhotoUpdateInput>;
  retry?: InputMaybe<Scalars['Int']['input']>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  /** import status */
  status?: InputMaybe<ImportQueueStatusType>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ImportQueueWithForcedPhotoCreateInput = {
  agency?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "ImportQueue.archive" relation */
  archive?: InputMaybe<ImportQueueNestedArchiveCreateInput>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  filename: Scalars['String']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  retry?: InputMaybe<Scalars['Int']['input']>;
  s3key: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
  /** import status */
  status: ImportQueueStatusType;
  subject?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type ImportQueueWithForcedPhotoUpdateInput = {
  agency?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "ImportQueue.archive" relation */
  archive?: InputMaybe<ImportQueueNestedArchiveUpdateInput>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  retry?: InputMaybe<Scalars['Int']['input']>;
  s3key?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  /** import status */
  status?: InputMaybe<ImportQueueStatusType>;
  subject?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/**
 * Given a known "photo", identifies exactly one "ImportQueue" node with one of these unique values:
 * - _id
 * - s3key
 * - photo (optional)
 */
export type ImportQueueWithOptionalPhotoWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  photo?: InputMaybe<PhotoWhereUniqueInput>;
  s3key?: InputMaybe<Scalars['String']['input']>;
};

export type InformationAgency = {
  __typename?: 'InformationAgency';
  key?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
};

/** "Metadata" resource's node */
export type Metadata = {
  __typename?: 'Metadata';
  /** The internal ID */
  _id: Scalars['Int']['output'];
  /** The public ID, an UUID v4 */
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  legacyName?: Maybe<Scalars['String']['output']>;
  /** "MetadataAccessRights.metadata"'s inverse relation */
  metadataAccessRightsList: Array<MetadataAccessRights>;
  /** "MetadataAccessRights.metadata"'s inverse relation count */
  metadataAccessRightsListCount: Scalars['Int']['output'];
  /** "MetadataAddlModelInfo.metadata"'s inverse relation */
  metadataAddlModelInfoList: Array<MetadataAddlModelInfo>;
  /** "MetadataAddlModelInfo.metadata"'s inverse relation count */
  metadataAddlModelInfoListCount: Scalars['Int']['output'];
  /** "MetadataArchiveChild.metadata"'s inverse relation */
  metadataArchiveChildList: Array<MetadataArchiveChild>;
  /** "MetadataArchiveChild.metadata"'s inverse relation count */
  metadataArchiveChildListCount: Scalars['Int']['output'];
  /** "MetadataArchiveParent.metadata"'s inverse relation */
  metadataArchiveParentList: Array<MetadataArchiveParent>;
  /** "MetadataArchiveParent.metadata"'s inverse relation count */
  metadataArchiveParentListCount: Scalars['Int']['output'];
  /** "MetadataAssetState.metadata"'s inverse relation */
  metadataAssetStateList: Array<MetadataAssetState>;
  /** "MetadataAssetState.metadata"'s inverse relation count */
  metadataAssetStateListCount: Scalars['Int']['output'];
  /** "MetadataAttributionName.metadata"'s inverse relation */
  metadataAttributionNameList: Array<MetadataAttributionName>;
  /** "MetadataAttributionName.metadata"'s inverse relation count */
  metadataAttributionNameListCount: Scalars['Int']['output'];
  /** "MetadataAttributionUrl.metadata"'s inverse relation */
  metadataAttributionUrlList: Array<MetadataAttributionUrl>;
  /** "MetadataAttributionUrl.metadata"'s inverse relation count */
  metadataAttributionUrlListCount: Scalars['Int']['output'];
  /** "MetadataAudience.metadata"'s inverse relation */
  metadataAudienceList: Array<MetadataAudience>;
  /** "MetadataAudience.metadata"'s inverse relation count */
  metadataAudienceListCount: Scalars['Int']['output'];
  /** "MetadataAuthorsPosition.metadata"'s inverse relation */
  metadataAuthorsPositionList: Array<MetadataAuthorsPosition>;
  /** "MetadataAuthorsPosition.metadata"'s inverse relation count */
  metadataAuthorsPositionListCount: Scalars['Int']['output'];
  /** "MetadataBackupName.metadata"'s inverse relation */
  metadataBackupNameList: Array<MetadataBackupName>;
  /** "MetadataBackupName.metadata"'s inverse relation count */
  metadataBackupNameListCount: Scalars['Int']['output'];
  /** "MetadataBitmapGrayscalePicture.metadata"'s inverse relation */
  metadataBitmapGrayscalePictureList: Array<MetadataBitmapGrayscalePicture>;
  /** "MetadataBitmapGrayscalePicture.metadata"'s inverse relation count */
  metadataBitmapGrayscalePictureListCount: Scalars['Int']['output'];
  /** "MetadataBrand.metadata"'s inverse relation */
  metadataBrandList: Array<MetadataBrand>;
  /** "MetadataBrand.metadata"'s inverse relation count */
  metadataBrandListCount: Scalars['Int']['output'];
  /** "MetadataBrandPrisma.metadata"'s inverse relation */
  metadataBrandPrismaList: Array<MetadataBrandPrisma>;
  /** "MetadataBrandPrisma.metadata"'s inverse relation count */
  metadataBrandPrismaListCount: Scalars['Int']['output'];
  /** "MetadataBrandShortName.metadata"'s inverse relation */
  metadataBrandShortNameList: Array<MetadataBrandShortName>;
  /** "MetadataBrandShortName.metadata"'s inverse relation count */
  metadataBrandShortNameListCount: Scalars['Int']['output'];
  /** "MetadataCaptionWriter.metadata"'s inverse relation */
  metadataCaptionWriterList: Array<MetadataCaptionWriter>;
  /** "MetadataCaptionWriter.metadata"'s inverse relation count */
  metadataCaptionWriterListCount: Scalars['Int']['output'];
  /** "MetadataCategory.metadata"'s inverse relation */
  metadataCategoryList: Array<MetadataCategory>;
  /** "MetadataCategory.metadata"'s inverse relation count */
  metadataCategoryListCount: Scalars['Int']['output'];
  /** "MetadataCategoryPrisma.metadata"'s inverse relation */
  metadataCategoryPrismaList: Array<MetadataCategoryPrisma>;
  /** "MetadataCategoryPrisma.metadata"'s inverse relation count */
  metadataCategoryPrismaListCount: Scalars['Int']['output'];
  /** "MetadataCertificate.metadata"'s inverse relation */
  metadataCertificateList: Array<MetadataCertificate>;
  /** "MetadataCertificate.metadata"'s inverse relation count */
  metadataCertificateListCount: Scalars['Int']['output'];
  /** "MetadataCiAdrCity.metadata"'s inverse relation */
  metadataCiAdrCityList: Array<MetadataCiAdrCity>;
  /** "MetadataCiAdrCity.metadata"'s inverse relation count */
  metadataCiAdrCityListCount: Scalars['Int']['output'];
  /** "MetadataCiAdrCtry.metadata"'s inverse relation */
  metadataCiAdrCtryList: Array<MetadataCiAdrCtry>;
  /** "MetadataCiAdrCtry.metadata"'s inverse relation count */
  metadataCiAdrCtryListCount: Scalars['Int']['output'];
  /** "MetadataCiAdrExtadr.metadata"'s inverse relation */
  metadataCiAdrExtadrList: Array<MetadataCiAdrExtadr>;
  /** "MetadataCiAdrExtadr.metadata"'s inverse relation count */
  metadataCiAdrExtadrListCount: Scalars['Int']['output'];
  /** "MetadataCiAdrPcode.metadata"'s inverse relation */
  metadataCiAdrPcodeList: Array<MetadataCiAdrPcode>;
  /** "MetadataCiAdrPcode.metadata"'s inverse relation count */
  metadataCiAdrPcodeListCount: Scalars['Int']['output'];
  /** "MetadataCiAdrRegion.metadata"'s inverse relation */
  metadataCiAdrRegionList: Array<MetadataCiAdrRegion>;
  /** "MetadataCiAdrRegion.metadata"'s inverse relation count */
  metadataCiAdrRegionListCount: Scalars['Int']['output'];
  /** "MetadataCiEmailWork.metadata"'s inverse relation */
  metadataCiEmailWorkList: Array<MetadataCiEmailWork>;
  /** "MetadataCiEmailWork.metadata"'s inverse relation count */
  metadataCiEmailWorkListCount: Scalars['Int']['output'];
  /** "MetadataCiTelWork.metadata"'s inverse relation */
  metadataCiTelWorkList: Array<MetadataCiTelWork>;
  /** "MetadataCiTelWork.metadata"'s inverse relation count */
  metadataCiTelWorkListCount: Scalars['Int']['output'];
  /** "MetadataCity.metadata"'s inverse relation */
  metadataCityList: Array<MetadataCity>;
  /** "MetadataCity.metadata"'s inverse relation count */
  metadataCityListCount: Scalars['Int']['output'];
  /** "MetadataCiUrlWork.metadata"'s inverse relation */
  metadataCiUrlWorkList: Array<MetadataCiUrlWork>;
  /** "MetadataCiUrlWork.metadata"'s inverse relation count */
  metadataCiUrlWorkListCount: Scalars['Int']['output'];
  /** "MetadataClassify.metadata"'s inverse relation */
  metadataClassifyList: Array<MetadataClassify>;
  /** "MetadataClassify.metadata"'s inverse relation count */
  metadataClassifyListCount: Scalars['Int']['output'];
  /** "MetadataCompany.metadata"'s inverse relation */
  metadataCompanyList: Array<MetadataCompany>;
  /** "MetadataCompany.metadata"'s inverse relation count */
  metadataCompanyListCount: Scalars['Int']['output'];
  /** "MetadataCompanyShortName.metadata"'s inverse relation */
  metadataCompanyShortNameList: Array<MetadataCompanyShortName>;
  /** "MetadataCompanyShortName.metadata"'s inverse relation count */
  metadataCompanyShortNameListCount: Scalars['Int']['output'];
  /** "MetadataConfidentiality.metadata"'s inverse relation */
  metadataConfidentialityList: Array<MetadataConfidentiality>;
  /** "MetadataConfidentiality.metadata"'s inverse relation count */
  metadataConfidentialityListCount: Scalars['Int']['output'];
  /** "MetadataContainedIn.metadata"'s inverse relation */
  metadataContainedInList: Array<MetadataContainedIn>;
  /** "MetadataContainedIn.metadata"'s inverse relation count */
  metadataContainedInListCount: Scalars['Int']['output'];
  /** "MetadataContentMediaKind.metadata"'s inverse relation */
  metadataContentMediaKindList: Array<MetadataContentMediaKind>;
  /** "MetadataContentMediaKind.metadata"'s inverse relation count */
  metadataContentMediaKindListCount: Scalars['Int']['output'];
  /** "MetadataContentValue.metadata"'s inverse relation */
  metadataContentValueList: Array<MetadataContentValue>;
  /** "MetadataContentValue.metadata"'s inverse relation count */
  metadataContentValueListCount: Scalars['Int']['output'];
  /** "MetadataCopyrightLayer.metadata"'s inverse relation */
  metadataCopyrightLayerList: Array<MetadataCopyrightLayer>;
  /** "MetadataCopyrightLayer.metadata"'s inverse relation count */
  metadataCopyrightLayerListCount: Scalars['Int']['output'];
  /** "MetadataCountryCode.metadata"'s inverse relation */
  metadataCountryCodeList: Array<MetadataCountryCode>;
  /** "MetadataCountryCode.metadata"'s inverse relation count */
  metadataCountryCodeListCount: Scalars['Int']['output'];
  /** "MetadataCountry.metadata"'s inverse relation */
  metadataCountryList: Array<MetadataCountry>;
  /** "MetadataCountry.metadata"'s inverse relation count */
  metadataCountryListCount: Scalars['Int']['output'];
  /** "MetadataCreatedTime.metadata"'s inverse relation */
  metadataCreatedTimeList: Array<MetadataCreatedTime>;
  /** "MetadataCreatedTime.metadata"'s inverse relation count */
  metadataCreatedTimeListCount: Scalars['Int']['output'];
  /** "MetadataCreator.metadata"'s inverse relation */
  metadataCreatorList: Array<MetadataCreator>;
  /** "MetadataCreator.metadata"'s inverse relation count */
  metadataCreatorListCount: Scalars['Int']['output'];
  /** "MetadataCreatorTool.metadata"'s inverse relation */
  metadataCreatorToolList: Array<MetadataCreatorTool>;
  /** "MetadataCreatorTool.metadata"'s inverse relation count */
  metadataCreatorToolListCount: Scalars['Int']['output'];
  /** "MetadataCredit.metadata"'s inverse relation */
  metadataCreditList: Array<MetadataCredit>;
  /** "MetadataCredit.metadata"'s inverse relation count */
  metadataCreditListCount: Scalars['Int']['output'];
  /** "MetadataCustomField14.metadata"'s inverse relation */
  metadataCustomField14List: Array<MetadataCustomField14>;
  /** "MetadataCustomField14.metadata"'s inverse relation count */
  metadataCustomField14ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField15.metadata"'s inverse relation */
  metadataCustomField15List: Array<MetadataCustomField15>;
  /** "MetadataCustomField15.metadata"'s inverse relation count */
  metadataCustomField15ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField16.metadata"'s inverse relation */
  metadataCustomField16List: Array<MetadataCustomField16>;
  /** "MetadataCustomField16.metadata"'s inverse relation count */
  metadataCustomField16ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField17.metadata"'s inverse relation */
  metadataCustomField17List: Array<MetadataCustomField17>;
  /** "MetadataCustomField17.metadata"'s inverse relation count */
  metadataCustomField17ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField20.metadata"'s inverse relation */
  metadataCustomField20List: Array<MetadataCustomField20>;
  /** "MetadataCustomField20.metadata"'s inverse relation count */
  metadataCustomField20ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField5.metadata"'s inverse relation */
  metadataCustomField5List: Array<MetadataCustomField5>;
  /** "MetadataCustomField5.metadata"'s inverse relation count */
  metadataCustomField5ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField6.metadata"'s inverse relation */
  metadataCustomField6List: Array<MetadataCustomField6>;
  /** "MetadataCustomField6.metadata"'s inverse relation count */
  metadataCustomField6ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField7.metadata"'s inverse relation */
  metadataCustomField7List: Array<MetadataCustomField7>;
  /** "MetadataCustomField7.metadata"'s inverse relation count */
  metadataCustomField7ListCount: Scalars['Int']['output'];
  /** "MetadataCustomField9.metadata"'s inverse relation */
  metadataCustomField9List: Array<MetadataCustomField9>;
  /** "MetadataCustomField9.metadata"'s inverse relation count */
  metadataCustomField9ListCount: Scalars['Int']['output'];
  /** "MetadataDateCreated.metadata"'s inverse relation */
  metadataDateCreatedList: Array<MetadataDateCreated>;
  /** "MetadataDateCreated.metadata"'s inverse relation count */
  metadataDateCreatedListCount: Scalars['Int']['output'];
  /** "MetadataDateTimeDigitized.metadata"'s inverse relation */
  metadataDateTimeDigitizedList: Array<MetadataDateTimeDigitized>;
  /** "MetadataDateTimeDigitized.metadata"'s inverse relation count */
  metadataDateTimeDigitizedListCount: Scalars['Int']['output'];
  /** "MetadataDateTimeOriginal.metadata"'s inverse relation */
  metadataDateTimeOriginalList: Array<MetadataDateTimeOriginal>;
  /** "MetadataDateTimeOriginal.metadata"'s inverse relation count */
  metadataDateTimeOriginalListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryAccount.metadata"'s inverse relation */
  metadataDeliveryAccountList: Array<MetadataDeliveryAccount>;
  /** "MetadataDeliveryAccount.metadata"'s inverse relation count */
  metadataDeliveryAccountListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryCompany.metadata"'s inverse relation */
  metadataDeliveryCompanyList: Array<MetadataDeliveryCompany>;
  /** "MetadataDeliveryCompany.metadata"'s inverse relation count */
  metadataDeliveryCompanyListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryCopyright.metadata"'s inverse relation */
  metadataDeliveryCopyrightList: Array<MetadataDeliveryCopyright>;
  /** "MetadataDeliveryCopyright.metadata"'s inverse relation count */
  metadataDeliveryCopyrightListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDateFolder.metadata"'s inverse relation */
  metadataDeliveryDateFolderList: Array<MetadataDeliveryDateFolder>;
  /** "MetadataDeliveryDateFolder.metadata"'s inverse relation count */
  metadataDeliveryDateFolderListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDateTime.metadata"'s inverse relation */
  metadataDeliveryDateTimeList: Array<MetadataDeliveryDateTime>;
  /** "MetadataDeliveryDateTime.metadata"'s inverse relation count */
  metadataDeliveryDateTimeListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDcCreator.metadata"'s inverse relation */
  metadataDeliveryDcCreatorList: Array<MetadataDeliveryDcCreator>;
  /** "MetadataDeliveryDcCreator.metadata"'s inverse relation count */
  metadataDeliveryDcCreatorListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDcRights.metadata"'s inverse relation */
  metadataDeliveryDcRightsList: Array<MetadataDeliveryDcRights>;
  /** "MetadataDeliveryDcRights.metadata"'s inverse relation count */
  metadataDeliveryDcRightsListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryFileType.metadata"'s inverse relation */
  metadataDeliveryFileTypeList: Array<MetadataDeliveryFileType>;
  /** "MetadataDeliveryFileType.metadata"'s inverse relation count */
  metadataDeliveryFileTypeListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryFolder.metadata"'s inverse relation */
  metadataDeliveryFolderList: Array<MetadataDeliveryFolder>;
  /** "MetadataDeliveryFolder.metadata"'s inverse relation count */
  metadataDeliveryFolderListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryKind.metadata"'s inverse relation */
  metadataDeliveryKindList: Array<MetadataDeliveryKind>;
  /** "MetadataDeliveryKind.metadata"'s inverse relation count */
  metadataDeliveryKindListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPath.metadata"'s inverse relation */
  metadataDeliveryPathList: Array<MetadataDeliveryPath>;
  /** "MetadataDeliveryPath.metadata"'s inverse relation count */
  metadataDeliveryPathListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPersonShownintheImage.metadata"'s inverse relation */
  metadataDeliveryPersonShownintheImageList: Array<MetadataDeliveryPersonShownintheImage>;
  /** "MetadataDeliveryPersonShownintheImage.metadata"'s inverse relation count */
  metadataDeliveryPersonShownintheImageListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPhotoshopCredit.metadata"'s inverse relation */
  metadataDeliveryPhotoshopCreditList: Array<MetadataDeliveryPhotoshopCredit>;
  /** "MetadataDeliveryPhotoshopCredit.metadata"'s inverse relation count */
  metadataDeliveryPhotoshopCreditListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPhotoshopSource.metadata"'s inverse relation */
  metadataDeliveryPhotoshopSourceList: Array<MetadataDeliveryPhotoshopSource>;
  /** "MetadataDeliveryPhotoshopSource.metadata"'s inverse relation count */
  metadataDeliveryPhotoshopSourceListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryService.metadata"'s inverse relation */
  metadataDeliveryServiceList: Array<MetadataDeliveryService>;
  /** "MetadataDeliveryService.metadata"'s inverse relation count */
  metadataDeliveryServiceListCount: Scalars['Int']['output'];
  /** "MetadataDeliverySubject.metadata"'s inverse relation */
  metadataDeliverySubjectList: Array<MetadataDeliverySubject>;
  /** "MetadataDeliverySubject.metadata"'s inverse relation count */
  metadataDeliverySubjectListCount: Scalars['Int']['output'];
  /** "MetadataDeliveryUnderSubject.metadata"'s inverse relation */
  metadataDeliveryUnderSubjectList: Array<MetadataDeliveryUnderSubject>;
  /** "MetadataDeliveryUnderSubject.metadata"'s inverse relation count */
  metadataDeliveryUnderSubjectListCount: Scalars['Int']['output'];
  /** "MetadataDepartment.metadata"'s inverse relation */
  metadataDepartmentList: Array<MetadataDepartment>;
  /** "MetadataDepartment.metadata"'s inverse relation count */
  metadataDepartmentListCount: Scalars['Int']['output'];
  /** "MetadataDescription.metadata"'s inverse relation */
  metadataDescriptionList: Array<MetadataDescription>;
  /** "MetadataDescription.metadata"'s inverse relation count */
  metadataDescriptionListCount: Scalars['Int']['output'];
  /** "MetadataDigitalAssetUrl.metadata"'s inverse relation */
  metadataDigitalAssetUrlList: Array<MetadataDigitalAssetUrl>;
  /** "MetadataDigitalAssetUrl.metadata"'s inverse relation count */
  metadataDigitalAssetUrlListCount: Scalars['Int']['output'];
  /** "MetadataDivision.metadata"'s inverse relation */
  metadataDivisionList: Array<MetadataDivision>;
  /** "MetadataDivision.metadata"'s inverse relation count */
  metadataDivisionListCount: Scalars['Int']['output'];
  /** "MetadataDocumentText.metadata"'s inverse relation */
  metadataDocumentTextList: Array<MetadataDocumentText>;
  /** "MetadataDocumentText.metadata"'s inverse relation count */
  metadataDocumentTextListCount: Scalars['Int']['output'];
  /** "MetadataEdition.metadata"'s inverse relation */
  metadataEditionList: Array<MetadataEdition>;
  /** "MetadataEdition.metadata"'s inverse relation count */
  metadataEditionListCount: Scalars['Int']['output'];
  /** "MetadataEditorialVersion.metadata"'s inverse relation */
  metadataEditorialVersionList: Array<MetadataEditorialVersion>;
  /** "MetadataEditorialVersion.metadata"'s inverse relation count */
  metadataEditorialVersionListCount: Scalars['Int']['output'];
  /** "MetadataEditStatus.metadata"'s inverse relation */
  metadataEditStatusList: Array<MetadataEditStatus>;
  /** "MetadataEditStatus.metadata"'s inverse relation count */
  metadataEditStatusListCount: Scalars['Int']['output'];
  /** "MetadataEnvironnementPhoto.metadata"'s inverse relation */
  metadataEnvironnementPhotoList: Array<MetadataEnvironnementPhoto>;
  /** "MetadataEnvironnementPhoto.metadata"'s inverse relation count */
  metadataEnvironnementPhotoListCount: Scalars['Int']['output'];
  /** "MetadataEquipmentInstitution.metadata"'s inverse relation */
  metadataEquipmentInstitutionList: Array<MetadataEquipmentInstitution>;
  /** "MetadataEquipmentInstitution.metadata"'s inverse relation count */
  metadataEquipmentInstitutionListCount: Scalars['Int']['output'];
  /** "MetadataEquipmentManufacturer.metadata"'s inverse relation */
  metadataEquipmentManufacturerList: Array<MetadataEquipmentManufacturer>;
  /** "MetadataEquipmentManufacturer.metadata"'s inverse relation count */
  metadataEquipmentManufacturerListCount: Scalars['Int']['output'];
  /** "MetadataEvent.metadata"'s inverse relation */
  metadataEventList: Array<MetadataEvent>;
  /** "MetadataEvent.metadata"'s inverse relation count */
  metadataEventListCount: Scalars['Int']['output'];
  /** "MetadataExif.metadata"'s inverse relation */
  metadataExifList: Array<MetadataExif>;
  /** "MetadataExif.metadata"'s inverse relation count */
  metadataExifListCount: Scalars['Int']['output'];
  /** "MetadataFabStorage.metadata"'s inverse relation */
  metadataFabStorageList: Array<MetadataFabStorage>;
  /** "MetadataFabStorage.metadata"'s inverse relation count */
  metadataFabStorageListCount: Scalars['Int']['output'];
  /** "MetadataFileCheckFor.metadata"'s inverse relation */
  metadataFileCheckForList: Array<MetadataFileCheckFor>;
  /** "MetadataFileCheckFor.metadata"'s inverse relation count */
  metadataFileCheckForListCount: Scalars['Int']['output'];
  /** "MetadataFileInfoBitsPerPixel.metadata"'s inverse relation */
  metadataFileInfoBitsPerPixelList: Array<MetadataFileInfoBitsPerPixel>;
  /** "MetadataFileInfoBitsPerPixel.metadata"'s inverse relation count */
  metadataFileInfoBitsPerPixelListCount: Scalars['Int']['output'];
  /** "MetadataFileInfoFrames.metadata"'s inverse relation */
  metadataFileInfoFramesList: Array<MetadataFileInfoFrames>;
  /** "MetadataFileInfoFrames.metadata"'s inverse relation count */
  metadataFileInfoFramesListCount: Scalars['Int']['output'];
  /** "MetadataFileInfoPhotometricInterpretation.metadata"'s inverse relation */
  metadataFileInfoPhotometricInterpretationList: Array<MetadataFileInfoPhotometricInterpretation>;
  /** "MetadataFileInfoPhotometricInterpretation.metadata"'s inverse relation count */
  metadataFileInfoPhotometricInterpretationListCount: Scalars['Int']['output'];
  /** "MetadataFileInfoTransferSyntax.metadata"'s inverse relation */
  metadataFileInfoTransferSyntaxList: Array<MetadataFileInfoTransferSyntax>;
  /** "MetadataFileInfoTransferSyntax.metadata"'s inverse relation count */
  metadataFileInfoTransferSyntaxListCount: Scalars['Int']['output'];
  /** "MetadataFileStage.metadata"'s inverse relation */
  metadataFileStageList: Array<MetadataFileStage>;
  /** "MetadataFileStage.metadata"'s inverse relation count */
  metadataFileStageListCount: Scalars['Int']['output'];
  /** "MetadataFileStatus.metadata"'s inverse relation */
  metadataFileStatusList: Array<MetadataFileStatus>;
  /** "MetadataFileStatus.metadata"'s inverse relation count */
  metadataFileStatusListCount: Scalars['Int']['output'];
  /** "MetadataFileType.metadata"'s inverse relation */
  metadataFileTypeList: Array<MetadataFileType>;
  /** "MetadataFileType.metadata"'s inverse relation count */
  metadataFileTypeListCount: Scalars['Int']['output'];
  /** "MetadataFixtureIdentifier.metadata"'s inverse relation */
  metadataFixtureIdentifierList: Array<MetadataFixtureIdentifier>;
  /** "MetadataFixtureIdentifier.metadata"'s inverse relation count */
  metadataFixtureIdentifierListCount: Scalars['Int']['output'];
  /** "MetadataFormat.metadata"'s inverse relation */
  metadataFormatList: Array<MetadataFormat>;
  /** "MetadataFormat.metadata"'s inverse relation count */
  metadataFormatListCount: Scalars['Int']['output'];
  /** "MetadataGpsLatitude.metadata"'s inverse relation */
  metadataGpsLatitudeList: Array<MetadataGpsLatitude>;
  /** "MetadataGpsLatitude.metadata"'s inverse relation count */
  metadataGpsLatitudeListCount: Scalars['Int']['output'];
  /** "MetadataGpsLongitude.metadata"'s inverse relation */
  metadataGpsLongitudeList: Array<MetadataGpsLongitude>;
  /** "MetadataGpsLongitude.metadata"'s inverse relation count */
  metadataGpsLongitudeListCount: Scalars['Int']['output'];
  /** "MetadataGpsProcessingMethod.metadata"'s inverse relation */
  metadataGpsProcessingMethodList: Array<MetadataGpsProcessingMethod>;
  /** "MetadataGpsProcessingMethod.metadata"'s inverse relation count */
  metadataGpsProcessingMethodListCount: Scalars['Int']['output'];
  /** "MetadataHeader.metadata"'s inverse relation */
  metadataHeaderList: Array<MetadataHeader>;
  /** "MetadataHeader.metadata"'s inverse relation count */
  metadataHeaderListCount: Scalars['Int']['output'];
  /** "MetadataHeaderPrisma.metadata"'s inverse relation */
  metadataHeaderPrismaList: Array<MetadataHeaderPrisma>;
  /** "MetadataHeaderPrisma.metadata"'s inverse relation count */
  metadataHeaderPrismaListCount: Scalars['Int']['output'];
  /** "MetadataHeadline.metadata"'s inverse relation */
  metadataHeadlineList: Array<MetadataHeadline>;
  /** "MetadataHeadline.metadata"'s inverse relation count */
  metadataHeadlineListCount: Scalars['Int']['output'];
  /** "MetadataHistory.metadata"'s inverse relation */
  metadataHistoryList: Array<MetadataHistory>;
  /** "MetadataHistory.metadata"'s inverse relation count */
  metadataHistoryListCount: Scalars['Int']['output'];
  /** "MetadataIccProfile.metadata"'s inverse relation */
  metadataIccProfileList: Array<MetadataIccProfile>;
  /** "MetadataIccProfile.metadata"'s inverse relation count */
  metadataIccProfileListCount: Scalars['Int']['output'];
  /** "MetadataIdAssignment.metadata"'s inverse relation */
  metadataIdAssignmentList: Array<MetadataIdAssignment>;
  /** "MetadataIdAssignment.metadata"'s inverse relation count */
  metadataIdAssignmentListCount: Scalars['Int']['output'];
  /** "MetadataIdMediaContent.metadata"'s inverse relation */
  metadataIdMediaContentList: Array<MetadataIdMediaContent>;
  /** "MetadataIdMediaContent.metadata"'s inverse relation count */
  metadataIdMediaContentListCount: Scalars['Int']['output'];
  /** "MetadataIdPhotoPrisma.metadata"'s inverse relation */
  metadataIdPhotoPrismaList: Array<MetadataIdPhotoPrisma>;
  /** "MetadataIdPhotoPrisma.metadata"'s inverse relation count */
  metadataIdPhotoPrismaListCount: Scalars['Int']['output'];
  /** "MetadataIdSubject.metadata"'s inverse relation */
  metadataIdSubjectList: Array<MetadataIdSubject>;
  /** "MetadataIdSubject.metadata"'s inverse relation count */
  metadataIdSubjectListCount: Scalars['Int']['output'];
  /** "MetadataImageNotes.metadata"'s inverse relation */
  metadataImageNotesList: Array<MetadataImageNotes>;
  /** "MetadataImageNotes.metadata"'s inverse relation count */
  metadataImageNotesListCount: Scalars['Int']['output'];
  /** "MetadataImageUniqueId.metadata"'s inverse relation */
  metadataImageUniqueIdList: Array<MetadataImageUniqueId>;
  /** "MetadataImageUniqueId.metadata"'s inverse relation count */
  metadataImageUniqueIdListCount: Scalars['Int']['output'];
  /** "MetadataInstructions.metadata"'s inverse relation */
  metadataInstructionsList: Array<MetadataInstructions>;
  /** "MetadataInstructions.metadata"'s inverse relation count */
  metadataInstructionsListCount: Scalars['Int']['output'];
  /** "MetadataIntellectualGenre.metadata"'s inverse relation */
  metadataIntellectualGenreList: Array<MetadataIntellectualGenre>;
  /** "MetadataIntellectualGenre.metadata"'s inverse relation count */
  metadataIntellectualGenreListCount: Scalars['Int']['output'];
  /** "MetadataIsoSpeedRatings.metadata"'s inverse relation */
  metadataIsoSpeedRatingsList: Array<MetadataIsoSpeedRatings>;
  /** "MetadataIsoSpeedRatings.metadata"'s inverse relation count */
  metadataIsoSpeedRatingsListCount: Scalars['Int']['output'];
  /** "MetadataIssue.metadata"'s inverse relation */
  metadataIssueList: Array<MetadataIssue>;
  /** "MetadataIssue.metadata"'s inverse relation count */
  metadataIssueListCount: Scalars['Int']['output'];
  /** "MetadataJobId.metadata"'s inverse relation */
  metadataJobIdList: Array<MetadataJobId>;
  /** "MetadataJobId.metadata"'s inverse relation count */
  metadataJobIdListCount: Scalars['Int']['output'];
  /** "MetadataJobProcessing.metadata"'s inverse relation */
  metadataJobProcessingList: Array<MetadataJobProcessing>;
  /** "MetadataJobProcessing.metadata"'s inverse relation count */
  metadataJobProcessingListCount: Scalars['Int']['output'];
  /** "MetadataLayoutPictureEditing.metadata"'s inverse relation */
  metadataLayoutPictureEditingList: Array<MetadataLayoutPictureEditing>;
  /** "MetadataLayoutPictureEditing.metadata"'s inverse relation count */
  metadataLayoutPictureEditingListCount: Scalars['Int']['output'];
  /** "MetadataLayoutStorage.metadata"'s inverse relation */
  metadataLayoutStorageList: Array<MetadataLayoutStorage>;
  /** "MetadataLayoutStorage.metadata"'s inverse relation count */
  metadataLayoutStorageListCount: Scalars['Int']['output'];
  /** "MetadataLicense.metadata"'s inverse relation */
  metadataLicenseList: Array<MetadataLicense>;
  /** "MetadataLicense.metadata"'s inverse relation count */
  metadataLicenseListCount: Scalars['Int']['output'];
  /** "MetadataLinks.metadata"'s inverse relation */
  metadataLinksList: Array<MetadataLinks>;
  /** "MetadataLinks.metadata"'s inverse relation count */
  metadataLinksListCount: Scalars['Int']['output'];
  /** "MetadataLocalCaption.metadata"'s inverse relation */
  metadataLocalCaptionList: Array<MetadataLocalCaption>;
  /** "MetadataLocalCaption.metadata"'s inverse relation count */
  metadataLocalCaptionListCount: Scalars['Int']['output'];
  /** "MetadataLocation.metadata"'s inverse relation */
  metadataLocationList: Array<MetadataLocation>;
  /** "MetadataLocation.metadata"'s inverse relation count */
  metadataLocationListCount: Scalars['Int']['output'];
  /** "MetadataMake.metadata"'s inverse relation */
  metadataMakeList: Array<MetadataMake>;
  /** "MetadataMake.metadata"'s inverse relation count */
  metadataMakeListCount: Scalars['Int']['output'];
  /** "MetadataManifest.metadata"'s inverse relation */
  metadataManifestList: Array<MetadataManifest>;
  /** "MetadataManifest.metadata"'s inverse relation count */
  metadataManifestListCount: Scalars['Int']['output'];
  /** "MetadataMarked.metadata"'s inverse relation */
  metadataMarkedList: Array<MetadataMarked>;
  /** "MetadataMarked.metadata"'s inverse relation count */
  metadataMarkedListCount: Scalars['Int']['output'];
  /** "MetadataMasterDocumentId.metadata"'s inverse relation */
  metadataMasterDocumentIdList: Array<MetadataMasterDocumentId>;
  /** "MetadataMasterDocumentId.metadata"'s inverse relation count */
  metadataMasterDocumentIdListCount: Scalars['Int']['output'];
  /** "MetadataMaxAvailHeight.metadata"'s inverse relation */
  metadataMaxAvailHeightList: Array<MetadataMaxAvailHeight>;
  /** "MetadataMaxAvailHeight.metadata"'s inverse relation count */
  metadataMaxAvailHeightListCount: Scalars['Int']['output'];
  /** "MetadataMaxAvailWidth.metadata"'s inverse relation */
  metadataMaxAvailWidthList: Array<MetadataMaxAvailWidth>;
  /** "MetadataMaxAvailWidth.metadata"'s inverse relation count */
  metadataMaxAvailWidthListCount: Scalars['Int']['output'];
  /** "MetadataMinorModelAgeDisclosure.metadata"'s inverse relation */
  metadataMinorModelAgeDisclosureList: Array<MetadataMinorModelAgeDisclosure>;
  /** "MetadataMinorModelAgeDisclosure.metadata"'s inverse relation count */
  metadataMinorModelAgeDisclosureListCount: Scalars['Int']['output'];
  /** "MetadataModelAge.metadata"'s inverse relation */
  metadataModelAgeList: Array<MetadataModelAge>;
  /** "MetadataModelAge.metadata"'s inverse relation count */
  metadataModelAgeListCount: Scalars['Int']['output'];
  /** "MetadataModel.metadata"'s inverse relation */
  metadataModelList: Array<MetadataModel>;
  /** "MetadataModel.metadata"'s inverse relation count */
  metadataModelListCount: Scalars['Int']['output'];
  /** "MetadataModelReleaseId.metadata"'s inverse relation */
  metadataModelReleaseIdList: Array<MetadataModelReleaseId>;
  /** "MetadataModelReleaseId.metadata"'s inverse relation count */
  metadataModelReleaseIdListCount: Scalars['Int']['output'];
  /** "MetadataModelReleaseStatus.metadata"'s inverse relation */
  metadataModelReleaseStatusList: Array<MetadataModelReleaseStatus>;
  /** "MetadataModelReleaseStatus.metadata"'s inverse relation count */
  metadataModelReleaseStatusListCount: Scalars['Int']['output'];
  /** "MetadataMorePermissions.metadata"'s inverse relation */
  metadataMorePermissionsList: Array<MetadataMorePermissions>;
  /** "MetadataMorePermissions.metadata"'s inverse relation count */
  metadataMorePermissionsListCount: Scalars['Int']['output'];
  /** "MetadataObjectCycle.metadata"'s inverse relation */
  metadataObjectCycleList: Array<MetadataObjectCycle>;
  /** "MetadataObjectCycle.metadata"'s inverse relation count */
  metadataObjectCycleListCount: Scalars['Int']['output'];
  /** "MetadataOrganisationInImageName.metadata"'s inverse relation */
  metadataOrganisationInImageNameList: Array<MetadataOrganisationInImageName>;
  /** "MetadataOrganisationInImageName.metadata"'s inverse relation count */
  metadataOrganisationInImageNameListCount: Scalars['Int']['output'];
  /** "MetadataOriginalColor.metadata"'s inverse relation */
  metadataOriginalColorList: Array<MetadataOriginalColor>;
  /** "MetadataOriginalColor.metadata"'s inverse relation count */
  metadataOriginalColorListCount: Scalars['Int']['output'];
  /** "MetadataOriginalIccProfile.metadata"'s inverse relation */
  metadataOriginalIccProfileList: Array<MetadataOriginalIccProfile>;
  /** "MetadataOriginalIccProfile.metadata"'s inverse relation count */
  metadataOriginalIccProfileListCount: Scalars['Int']['output'];
  /** "MetadataOriginalName.metadata"'s inverse relation */
  metadataOriginalNameList: Array<MetadataOriginalName>;
  /** "MetadataOriginalName.metadata"'s inverse relation count */
  metadataOriginalNameListCount: Scalars['Int']['output'];
  /** "MetadataOriginalWeigth.metadata"'s inverse relation */
  metadataOriginalWeigthList: Array<MetadataOriginalWeigth>;
  /** "MetadataOriginalWeigth.metadata"'s inverse relation count */
  metadataOriginalWeigthListCount: Scalars['Int']['output'];
  /** "MetadataOwnerId.metadata"'s inverse relation */
  metadataOwnerIdList: Array<MetadataOwnerId>;
  /** "MetadataOwnerId.metadata"'s inverse relation count */
  metadataOwnerIdListCount: Scalars['Int']['output'];
  /** "MetadataOwner.metadata"'s inverse relation */
  metadataOwnerList: Array<MetadataOwner>;
  /** "MetadataOwner.metadata"'s inverse relation count */
  metadataOwnerListCount: Scalars['Int']['output'];
  /** "MetadataPage.metadata"'s inverse relation */
  metadataPageList: Array<MetadataPage>;
  /** "MetadataPage.metadata"'s inverse relation count */
  metadataPageListCount: Scalars['Int']['output'];
  /** "MetadataPagePrisma.metadata"'s inverse relation */
  metadataPagePrismaList: Array<MetadataPagePrisma>;
  /** "MetadataPagePrisma.metadata"'s inverse relation count */
  metadataPagePrismaListCount: Scalars['Int']['output'];
  /** "MetadataPaintBasedCorrections.metadata"'s inverse relation */
  metadataPaintBasedCorrectionsList: Array<MetadataPaintBasedCorrections>;
  /** "MetadataPaintBasedCorrections.metadata"'s inverse relation count */
  metadataPaintBasedCorrectionsListCount: Scalars['Int']['output'];
  /** "MetadataPatientDob.metadata"'s inverse relation */
  metadataPatientDobList: Array<MetadataPatientDob>;
  /** "MetadataPatientDob.metadata"'s inverse relation count */
  metadataPatientDobListCount: Scalars['Int']['output'];
  /** "MetadataPatientId.metadata"'s inverse relation */
  metadataPatientIdList: Array<MetadataPatientId>;
  /** "MetadataPatientId.metadata"'s inverse relation count */
  metadataPatientIdListCount: Scalars['Int']['output'];
  /** "MetadataPatientName.metadata"'s inverse relation */
  metadataPatientNameList: Array<MetadataPatientName>;
  /** "MetadataPatientName.metadata"'s inverse relation count */
  metadataPatientNameListCount: Scalars['Int']['output'];
  /** "MetadataPatientSex.metadata"'s inverse relation */
  metadataPatientSexList: Array<MetadataPatientSex>;
  /** "MetadataPatientSex.metadata"'s inverse relation count */
  metadataPatientSexListCount: Scalars['Int']['output'];
  /** "MetadataPersonInImage.metadata"'s inverse relation */
  metadataPersonInImageList: Array<MetadataPersonInImage>;
  /** "MetadataPersonInImage.metadata"'s inverse relation count */
  metadataPersonInImageListCount: Scalars['Int']['output'];
  /** "MetadataPhotoStorage.metadata"'s inverse relation */
  metadataPhotoStorageList: Array<MetadataPhotoStorage>;
  /** "MetadataPhotoStorage.metadata"'s inverse relation count */
  metadataPhotoStorageListCount: Scalars['Int']['output'];
  /** "MetadataPrepressPictureEditing.metadata"'s inverse relation */
  metadataPrepressPictureEditingList: Array<MetadataPrepressPictureEditing>;
  /** "MetadataPrepressPictureEditing.metadata"'s inverse relation count */
  metadataPrepressPictureEditingListCount: Scalars['Int']['output'];
  /** "MetadataPriceLevel.metadata"'s inverse relation */
  metadataPriceLevelList: Array<MetadataPriceLevel>;
  /** "MetadataPriceLevel.metadata"'s inverse relation count */
  metadataPriceLevelListCount: Scalars['Int']['output'];
  /** "MetadataPrintingProfile.metadata"'s inverse relation */
  metadataPrintingProfileList: Array<MetadataPrintingProfile>;
  /** "MetadataPrintingProfile.metadata"'s inverse relation count */
  metadataPrintingProfileListCount: Scalars['Int']['output'];
  /** "MetadataPrismaProduction.metadata"'s inverse relation */
  metadataPrismaProductionList: Array<MetadataPrismaProduction>;
  /** "MetadataPrismaProduction.metadata"'s inverse relation count */
  metadataPrismaProductionListCount: Scalars['Int']['output'];
  /** "MetadataProcessHistory.metadata"'s inverse relation */
  metadataProcessHistoryList: Array<MetadataProcessHistory>;
  /** "MetadataProcessHistory.metadata"'s inverse relation count */
  metadataProcessHistoryListCount: Scalars['Int']['output'];
  /** "MetadataProcessParameter.metadata"'s inverse relation */
  metadataProcessParameterList: Array<MetadataProcessParameter>;
  /** "MetadataProcessParameter.metadata"'s inverse relation count */
  metadataProcessParameterListCount: Scalars['Int']['output'];
  /** "MetadataProcessStatus.metadata"'s inverse relation */
  metadataProcessStatusList: Array<MetadataProcessStatus>;
  /** "MetadataProcessStatus.metadata"'s inverse relation count */
  metadataProcessStatusListCount: Scalars['Int']['output'];
  /** "MetadataProduct.metadata"'s inverse relation */
  metadataProductList: Array<MetadataProduct>;
  /** "MetadataProduct.metadata"'s inverse relation count */
  metadataProductListCount: Scalars['Int']['output'];
  /** "MetadataProductShortName.metadata"'s inverse relation */
  metadataProductShortNameList: Array<MetadataProductShortName>;
  /** "MetadataProductShortName.metadata"'s inverse relation count */
  metadataProductShortNameListCount: Scalars['Int']['output'];
  /** "MetadataProducts.metadata"'s inverse relation */
  metadataProductsList: Array<MetadataProducts>;
  /** "MetadataProducts.metadata"'s inverse relation count */
  metadataProductsListCount: Scalars['Int']['output'];
  /** "MetadataProduits.metadata"'s inverse relation */
  metadataProduitsList: Array<MetadataProduits>;
  /** "MetadataProduits.metadata"'s inverse relation count */
  metadataProduitsListCount: Scalars['Int']['output'];
  /** "MetadataProgramVersion.metadata"'s inverse relation */
  metadataProgramVersionList: Array<MetadataProgramVersion>;
  /** "MetadataProgramVersion.metadata"'s inverse relation count */
  metadataProgramVersionListCount: Scalars['Int']['output'];
  /** "MetadataPropertyReleaseId.metadata"'s inverse relation */
  metadataPropertyReleaseIdList: Array<MetadataPropertyReleaseId>;
  /** "MetadataPropertyReleaseId.metadata"'s inverse relation count */
  metadataPropertyReleaseIdListCount: Scalars['Int']['output'];
  /** "MetadataPropertyReleaseStatus.metadata"'s inverse relation */
  metadataPropertyReleaseStatusList: Array<MetadataPropertyReleaseStatus>;
  /** "MetadataPropertyReleaseStatus.metadata"'s inverse relation count */
  metadataPropertyReleaseStatusListCount: Scalars['Int']['output'];
  /** "MetadataPublisher.metadata"'s inverse relation */
  metadataPublisherList: Array<MetadataPublisher>;
  /** "MetadataPublisher.metadata"'s inverse relation count */
  metadataPublisherListCount: Scalars['Int']['output'];
  /** "MetadataPublishingIssue.metadata"'s inverse relation */
  metadataPublishingIssueList: Array<MetadataPublishingIssue>;
  /** "MetadataPublishingIssue.metadata"'s inverse relation count */
  metadataPublishingIssueListCount: Scalars['Int']['output'];
  /** "MetadataPublishingSubject.metadata"'s inverse relation */
  metadataPublishingSubjectList: Array<MetadataPublishingSubject>;
  /** "MetadataPublishingSubject.metadata"'s inverse relation count */
  metadataPublishingSubjectListCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaBy.metadata"'s inverse relation */
  metadataQualifiedUsePrismaByList: Array<MetadataQualifiedUsePrismaBy>;
  /** "MetadataQualifiedUsePrismaBy.metadata"'s inverse relation count */
  metadataQualifiedUsePrismaByListCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaDate.metadata"'s inverse relation */
  metadataQualifiedUsePrismaDateList: Array<MetadataQualifiedUsePrismaDate>;
  /** "MetadataQualifiedUsePrismaDate.metadata"'s inverse relation count */
  metadataQualifiedUsePrismaDateListCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaDuration.metadata"'s inverse relation */
  metadataQualifiedUsePrismaDurationList: Array<MetadataQualifiedUsePrismaDuration>;
  /** "MetadataQualifiedUsePrismaDuration.metadata"'s inverse relation count */
  metadataQualifiedUsePrismaDurationListCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaSupport.metadata"'s inverse relation */
  metadataQualifiedUsePrismaSupportList: Array<MetadataQualifiedUsePrismaSupport>;
  /** "MetadataQualifiedUsePrismaSupport.metadata"'s inverse relation count */
  metadataQualifiedUsePrismaSupportListCount: Scalars['Int']['output'];
  /** "MetadataRating.metadata"'s inverse relation */
  metadataRatingList: Array<MetadataRating>;
  /** "MetadataRating.metadata"'s inverse relation count */
  metadataRatingListCount: Scalars['Int']['output'];
  /** "MetadataReferenceDate.metadata"'s inverse relation */
  metadataReferenceDateList: Array<MetadataReferenceDate>;
  /** "MetadataReferenceDate.metadata"'s inverse relation count */
  metadataReferenceDateListCount: Scalars['Int']['output'];
  /** "MetadataReferenceNumber.metadata"'s inverse relation */
  metadataReferenceNumberList: Array<MetadataReferenceNumber>;
  /** "MetadataReferenceNumber.metadata"'s inverse relation count */
  metadataReferenceNumberListCount: Scalars['Int']['output'];
  /** "MetadataReferenceService.metadata"'s inverse relation */
  metadataReferenceServiceList: Array<MetadataReferenceService>;
  /** "MetadataReferenceService.metadata"'s inverse relation count */
  metadataReferenceServiceListCount: Scalars['Int']['output'];
  /** "MetadataReleaseDate.metadata"'s inverse relation */
  metadataReleaseDateList: Array<MetadataReleaseDate>;
  /** "MetadataReleaseDate.metadata"'s inverse relation count */
  metadataReleaseDateListCount: Scalars['Int']['output'];
  /** "MetadataReleaseTime.metadata"'s inverse relation */
  metadataReleaseTimeList: Array<MetadataReleaseTime>;
  /** "MetadataReleaseTime.metadata"'s inverse relation count */
  metadataReleaseTimeListCount: Scalars['Int']['output'];
  /** "MetadataRequiredPermission.metadata"'s inverse relation */
  metadataRequiredPermissionList: Array<MetadataRequiredPermission>;
  /** "MetadataRequiredPermission.metadata"'s inverse relation count */
  metadataRequiredPermissionListCount: Scalars['Int']['output'];
  /** "MetadataResolutionKind.metadata"'s inverse relation */
  metadataResolutionKindList: Array<MetadataResolutionKind>;
  /** "MetadataResolutionKind.metadata"'s inverse relation count */
  metadataResolutionKindListCount: Scalars['Int']['output'];
  /** "MetadataRights.metadata"'s inverse relation */
  metadataRightsList: Array<MetadataRights>;
  /** "MetadataRights.metadata"'s inverse relation count */
  metadataRightsListCount: Scalars['Int']['output'];
  /** "MetadataRoyaltyfree.metadata"'s inverse relation */
  metadataRoyaltyfreeList: Array<MetadataRoyaltyfree>;
  /** "MetadataRoyaltyfree.metadata"'s inverse relation count */
  metadataRoyaltyfreeListCount: Scalars['Int']['output'];
  /** "MetadataScene.metadata"'s inverse relation */
  metadataSceneList: Array<MetadataScene>;
  /** "MetadataScene.metadata"'s inverse relation count */
  metadataSceneListCount: Scalars['Int']['output'];
  /** "MetadataSection.metadata"'s inverse relation */
  metadataSectionList: Array<MetadataSection>;
  /** "MetadataSection.metadata"'s inverse relation count */
  metadataSectionListCount: Scalars['Int']['output'];
  /** "MetadataSentTo.metadata"'s inverse relation */
  metadataSentToList: Array<MetadataSentTo>;
  /** "MetadataSentTo.metadata"'s inverse relation count */
  metadataSentToListCount: Scalars['Int']['output'];
  /** "MetadataSerialNumber.metadata"'s inverse relation */
  metadataSerialNumberList: Array<MetadataSerialNumber>;
  /** "MetadataSerialNumber.metadata"'s inverse relation count */
  metadataSerialNumberListCount: Scalars['Int']['output'];
  /** "MetadataSeriesDateTime.metadata"'s inverse relation */
  metadataSeriesDateTimeList: Array<MetadataSeriesDateTime>;
  /** "MetadataSeriesDateTime.metadata"'s inverse relation count */
  metadataSeriesDateTimeListCount: Scalars['Int']['output'];
  /** "MetadataSeriesDescription.metadata"'s inverse relation */
  metadataSeriesDescriptionList: Array<MetadataSeriesDescription>;
  /** "MetadataSeriesDescription.metadata"'s inverse relation count */
  metadataSeriesDescriptionListCount: Scalars['Int']['output'];
  /** "MetadataSeriesModality.metadata"'s inverse relation */
  metadataSeriesModalityList: Array<MetadataSeriesModality>;
  /** "MetadataSeriesModality.metadata"'s inverse relation count */
  metadataSeriesModalityListCount: Scalars['Int']['output'];
  /** "MetadataSeriesNumber.metadata"'s inverse relation */
  metadataSeriesNumberList: Array<MetadataSeriesNumber>;
  /** "MetadataSeriesNumber.metadata"'s inverse relation count */
  metadataSeriesNumberListCount: Scalars['Int']['output'];
  /** "MetadataShortUniqueId.metadata"'s inverse relation */
  metadataShortUniqueIdList: Array<MetadataShortUniqueId>;
  /** "MetadataShortUniqueId.metadata"'s inverse relation count */
  metadataShortUniqueIdListCount: Scalars['Int']['output'];
  /** "MetadataSource.metadata"'s inverse relation */
  metadataSourceList: Array<MetadataSource>;
  /** "MetadataSource.metadata"'s inverse relation count */
  metadataSourceListCount: Scalars['Int']['output'];
  /** "MetadataState.metadata"'s inverse relation */
  metadataStateList: Array<MetadataState>;
  /** "MetadataState.metadata"'s inverse relation count */
  metadataStateListCount: Scalars['Int']['output'];
  /** "MetadataStudyDateTime.metadata"'s inverse relation */
  metadataStudyDateTimeList: Array<MetadataStudyDateTime>;
  /** "MetadataStudyDateTime.metadata"'s inverse relation count */
  metadataStudyDateTimeListCount: Scalars['Int']['output'];
  /** "MetadataStudyDescription.metadata"'s inverse relation */
  metadataStudyDescriptionList: Array<MetadataStudyDescription>;
  /** "MetadataStudyDescription.metadata"'s inverse relation count */
  metadataStudyDescriptionListCount: Scalars['Int']['output'];
  /** "MetadataStudyId.metadata"'s inverse relation */
  metadataStudyIdList: Array<MetadataStudyId>;
  /** "MetadataStudyId.metadata"'s inverse relation count */
  metadataStudyIdListCount: Scalars['Int']['output'];
  /** "MetadataStudyPhysician.metadata"'s inverse relation */
  metadataStudyPhysicianList: Array<MetadataStudyPhysician>;
  /** "MetadataStudyPhysician.metadata"'s inverse relation count */
  metadataStudyPhysicianListCount: Scalars['Int']['output'];
  /** "MetadataSubjectCode.metadata"'s inverse relation */
  metadataSubjectCodeList: Array<MetadataSubjectCode>;
  /** "MetadataSubjectCode.metadata"'s inverse relation count */
  metadataSubjectCodeListCount: Scalars['Int']['output'];
  /** "MetadataSubject.metadata"'s inverse relation */
  metadataSubjectList: Array<MetadataSubject>;
  /** "MetadataSubject.metadata"'s inverse relation count */
  metadataSubjectListCount: Scalars['Int']['output'];
  /** "MetadataSubjectPrisma.metadata"'s inverse relation */
  metadataSubjectPrismaList: Array<MetadataSubjectPrisma>;
  /** "MetadataSubjectPrisma.metadata"'s inverse relation count */
  metadataSubjectPrismaListCount: Scalars['Int']['output'];
  /** "MetadataSupplementalCategories.metadata"'s inverse relation */
  metadataSupplementalCategoriesList: Array<MetadataSupplementalCategories>;
  /** "MetadataSupplementalCategories.metadata"'s inverse relation count */
  metadataSupplementalCategoriesListCount: Scalars['Int']['output'];
  /** "MetadataTargetVersion.metadata"'s inverse relation */
  metadataTargetVersionList: Array<MetadataTargetVersion>;
  /** "MetadataTargetVersion.metadata"'s inverse relation count */
  metadataTargetVersionListCount: Scalars['Int']['output'];
  /** "MetadataTitle.metadata"'s inverse relation */
  metadataTitleList: Array<MetadataTitle>;
  /** "MetadataTitle.metadata"'s inverse relation count */
  metadataTitleListCount: Scalars['Int']['output'];
  /** "MetadataToneCurve.metadata"'s inverse relation */
  metadataToneCurveList: Array<MetadataToneCurve>;
  /** "MetadataToneCurve.metadata"'s inverse relation count */
  metadataToneCurveListCount: Scalars['Int']['output'];
  /** "MetadataTransferredByEmail.metadata"'s inverse relation */
  metadataTransferredByEmailList: Array<MetadataTransferredByEmail>;
  /** "MetadataTransferredByEmail.metadata"'s inverse relation count */
  metadataTransferredByEmailListCount: Scalars['Int']['output'];
  /** "MetadataTransferredByFullName.metadata"'s inverse relation */
  metadataTransferredByFullNameList: Array<MetadataTransferredByFullName>;
  /** "MetadataTransferredByFullName.metadata"'s inverse relation count */
  metadataTransferredByFullNameListCount: Scalars['Int']['output'];
  /** "MetadataTransferredBy.metadata"'s inverse relation */
  metadataTransferredByList: Array<MetadataTransferredBy>;
  /** "MetadataTransferredBy.metadata"'s inverse relation count */
  metadataTransferredByListCount: Scalars['Int']['output'];
  /** "MetadataTransmissionReference.metadata"'s inverse relation */
  metadataTransmissionReferenceList: Array<MetadataTransmissionReference>;
  /** "MetadataTransmissionReference.metadata"'s inverse relation count */
  metadataTransmissionReferenceListCount: Scalars['Int']['output'];
  /** "MetadataType.metadata"'s inverse relation */
  metadataTypeList: Array<MetadataType>;
  /** "MetadataType.metadata"'s inverse relation count */
  metadataTypeListCount: Scalars['Int']['output'];
  /** "MetadataUndersubject.metadata"'s inverse relation */
  metadataUndersubjectList: Array<MetadataUndersubject>;
  /** "MetadataUndersubject.metadata"'s inverse relation count */
  metadataUndersubjectListCount: Scalars['Int']['output'];
  /** "MetadataUnderUnderSubject.metadata"'s inverse relation */
  metadataUnderUnderSubjectList: Array<MetadataUnderUnderSubject>;
  /** "MetadataUnderUnderSubject.metadata"'s inverse relation count */
  metadataUnderUnderSubjectListCount: Scalars['Int']['output'];
  /** "MetadataUniqueId.metadata"'s inverse relation */
  metadataUniqueIdList: Array<MetadataUniqueId>;
  /** "MetadataUniqueId.metadata"'s inverse relation count */
  metadataUniqueIdListCount: Scalars['Int']['output'];
  /** "MetadataUnit.metadata"'s inverse relation */
  metadataUnitList: Array<MetadataUnit>;
  /** "MetadataUnit.metadata"'s inverse relation count */
  metadataUnitListCount: Scalars['Int']['output'];
  /** "MetadataUnitShortName.metadata"'s inverse relation */
  metadataUnitShortNameList: Array<MetadataUnitShortName>;
  /** "MetadataUnitShortName.metadata"'s inverse relation count */
  metadataUnitShortNameListCount: Scalars['Int']['output'];
  /** "MetadataUploadedByFullName.metadata"'s inverse relation */
  metadataUploadedByFullNameList: Array<MetadataUploadedByFullName>;
  /** "MetadataUploadedByFullName.metadata"'s inverse relation count */
  metadataUploadedByFullNameListCount: Scalars['Int']['output'];
  /** "MetadataUploadedBy.metadata"'s inverse relation */
  metadataUploadedByList: Array<MetadataUploadedBy>;
  /** "MetadataUploadedBy.metadata"'s inverse relation count */
  metadataUploadedByListCount: Scalars['Int']['output'];
  /** "MetadataUploadTime.metadata"'s inverse relation */
  metadataUploadTimeList: Array<MetadataUploadTime>;
  /** "MetadataUploadTime.metadata"'s inverse relation count */
  metadataUploadTimeListCount: Scalars['Int']['output'];
  /** "MetadataUrgency.metadata"'s inverse relation */
  metadataUrgencyList: Array<MetadataUrgency>;
  /** "MetadataUrgency.metadata"'s inverse relation count */
  metadataUrgencyListCount: Scalars['Int']['output'];
  /** "MetadataUsageTerms.metadata"'s inverse relation */
  metadataUsageTermsList: Array<MetadataUsageTerms>;
  /** "MetadataUsageTerms.metadata"'s inverse relation count */
  metadataUsageTermsListCount: Scalars['Int']['output'];
  /** "MetadataUserComment.metadata"'s inverse relation */
  metadataUserCommentList: Array<MetadataUserComment>;
  /** "MetadataUserComment.metadata"'s inverse relation count */
  metadataUserCommentListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined195.metadata"'s inverse relation */
  metadataUserDefined195List: Array<MetadataUserDefined195>;
  /** "MetadataUserDefined195.metadata"'s inverse relation count */
  metadataUserDefined195ListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined237.metadata"'s inverse relation */
  metadataUserDefined237List: Array<MetadataUserDefined237>;
  /** "MetadataUserDefined237.metadata"'s inverse relation count */
  metadataUserDefined237ListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined238.metadata"'s inverse relation */
  metadataUserDefined238List: Array<MetadataUserDefined238>;
  /** "MetadataUserDefined238.metadata"'s inverse relation count */
  metadataUserDefined238ListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined239.metadata"'s inverse relation */
  metadataUserDefined239List: Array<MetadataUserDefined239>;
  /** "MetadataUserDefined239.metadata"'s inverse relation count */
  metadataUserDefined239ListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined242.metadata"'s inverse relation */
  metadataUserDefined242List: Array<MetadataUserDefined242>;
  /** "MetadataUserDefined242.metadata"'s inverse relation count */
  metadataUserDefined242ListCount: Scalars['Int']['output'];
  /** "MetadataUserDefined62.metadata"'s inverse relation */
  metadataUserDefined62List: Array<MetadataUserDefined62>;
  /** "MetadataUserDefined62.metadata"'s inverse relation count */
  metadataUserDefined62ListCount: Scalars['Int']['output'];
  /** "MetadataValid.metadata"'s inverse relation */
  metadataValidList: Array<MetadataValid>;
  /** "MetadataValid.metadata"'s inverse relation count */
  metadataValidListCount: Scalars['Int']['output'];
  /** "MetadataVersion.metadata"'s inverse relation */
  metadataVersionList: Array<MetadataVersion>;
  /** "MetadataVersion.metadata"'s inverse relation count */
  metadataVersionListCount: Scalars['Int']['output'];
  /** "MetadataWebStatement.metadata"'s inverse relation */
  metadataWebStatementList: Array<MetadataWebStatement>;
  /** "MetadataWebStatement.metadata"'s inverse relation count */
  metadataWebStatementListCount: Scalars['Int']['output'];
  /** "MetadataWorkflowKind.metadata"'s inverse relation */
  metadataWorkflowKindList: Array<MetadataWorkflowKind>;
  /** "MetadataWorkflowKind.metadata"'s inverse relation count */
  metadataWorkflowKindListCount: Scalars['Int']['output'];
  /** "MetadataXmpFileStamps.metadata"'s inverse relation */
  metadataXmpFileStampsList: Array<MetadataXmpFileStamps>;
  /** "MetadataXmpFileStamps.metadata"'s inverse relation count */
  metadataXmpFileStampsListCount: Scalars['Int']['output'];
  /** "MetadataXmpHistory.metadata"'s inverse relation */
  metadataXmpHistoryList: Array<MetadataXmpHistory>;
  /** "MetadataXmpHistory.metadata"'s inverse relation count */
  metadataXmpHistoryListCount: Scalars['Int']['output'];
  name: MetadataNameEnumType;
  namespace: Scalars['String']['output'];
  /** "PhotoMetadata.metadata"'s inverse relation count */
  photoMetadataCount: Scalars['Int']['output'];
  /** "PhotoMetadata.metadata"'s inverse relation */
  photoMetadatas: Array<PhotoMetadata>;
  structName?: Maybe<Scalars['String']['output']>;
  /** whether is this metadata value is a list or not, for display usage */
  type: MetadataValueTypeEnum;
};


/** "Metadata" resource's node */
export type MetadataMetadataAccessRightsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAccessRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAccessRightsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAccessRightsListCountArgs = {
  where?: InputMaybe<MetadataAccessRightsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAddlModelInfoListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAddlModelInfoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAddlModelInfoWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAddlModelInfoListCountArgs = {
  where?: InputMaybe<MetadataAddlModelInfoWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataArchiveChildListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataArchiveChildOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataArchiveChildWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataArchiveChildListCountArgs = {
  where?: InputMaybe<MetadataArchiveChildWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataArchiveParentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataArchiveParentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataArchiveParentWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataArchiveParentListCountArgs = {
  where?: InputMaybe<MetadataArchiveParentWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAssetStateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAssetStateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAssetStateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAssetStateListCountArgs = {
  where?: InputMaybe<MetadataAssetStateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAttributionNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAttributionNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAttributionNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAttributionNameListCountArgs = {
  where?: InputMaybe<MetadataAttributionNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAttributionUrlListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAttributionUrlOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAttributionUrlWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAttributionUrlListCountArgs = {
  where?: InputMaybe<MetadataAttributionUrlWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAudienceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAudienceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAudienceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAudienceListCountArgs = {
  where?: InputMaybe<MetadataAudienceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAuthorsPositionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAuthorsPositionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAuthorsPositionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataAuthorsPositionListCountArgs = {
  where?: InputMaybe<MetadataAuthorsPositionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataBackupNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBackupNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBackupNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataBackupNameListCountArgs = {
  where?: InputMaybe<MetadataBackupNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataBitmapGrayscalePictureListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBitmapGrayscalePictureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataBitmapGrayscalePictureListCountArgs = {
  where?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataBrandListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataBrandListCountArgs = {
  where?: InputMaybe<MetadataBrandWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataBrandPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandPrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataBrandPrismaListCountArgs = {
  where?: InputMaybe<MetadataBrandPrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataBrandShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandShortNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataBrandShortNameListCountArgs = {
  where?: InputMaybe<MetadataBrandShortNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCaptionWriterListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCaptionWriterOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCaptionWriterWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCaptionWriterListCountArgs = {
  where?: InputMaybe<MetadataCaptionWriterWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCategoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCategoryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCategoryListCountArgs = {
  where?: InputMaybe<MetadataCategoryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCategoryPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCategoryPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCategoryPrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCategoryPrismaListCountArgs = {
  where?: InputMaybe<MetadataCategoryPrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCertificateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCertificateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCertificateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCertificateListCountArgs = {
  where?: InputMaybe<MetadataCertificateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiAdrCityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrCityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrCityWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiAdrCityListCountArgs = {
  where?: InputMaybe<MetadataCiAdrCityWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiAdrCtryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrCtryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrCtryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiAdrCtryListCountArgs = {
  where?: InputMaybe<MetadataCiAdrCtryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiAdrExtadrListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrExtadrOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiAdrExtadrListCountArgs = {
  where?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiAdrPcodeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrPcodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiAdrPcodeListCountArgs = {
  where?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiAdrRegionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrRegionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrRegionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiAdrRegionListCountArgs = {
  where?: InputMaybe<MetadataCiAdrRegionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiEmailWorkListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiEmailWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiEmailWorkWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiEmailWorkListCountArgs = {
  where?: InputMaybe<MetadataCiEmailWorkWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiTelWorkListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiTelWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiTelWorkWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiTelWorkListCountArgs = {
  where?: InputMaybe<MetadataCiTelWorkWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCityWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCityListCountArgs = {
  where?: InputMaybe<MetadataCityWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiUrlWorkListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiUrlWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiUrlWorkWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCiUrlWorkListCountArgs = {
  where?: InputMaybe<MetadataCiUrlWorkWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataClassifyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataClassifyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataClassifyWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataClassifyListCountArgs = {
  where?: InputMaybe<MetadataClassifyWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCompanyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCompanyWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCompanyListCountArgs = {
  where?: InputMaybe<MetadataCompanyWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCompanyShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCompanyShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCompanyShortNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCompanyShortNameListCountArgs = {
  where?: InputMaybe<MetadataCompanyShortNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataConfidentialityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataConfidentialityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataConfidentialityWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataConfidentialityListCountArgs = {
  where?: InputMaybe<MetadataConfidentialityWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataContainedInListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContainedInOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContainedInWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataContainedInListCountArgs = {
  where?: InputMaybe<MetadataContainedInWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataContentMediaKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContentMediaKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContentMediaKindWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataContentMediaKindListCountArgs = {
  where?: InputMaybe<MetadataContentMediaKindWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataContentValueListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContentValueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContentValueWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataContentValueListCountArgs = {
  where?: InputMaybe<MetadataContentValueWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCopyrightLayerListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCopyrightLayerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCopyrightLayerWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCopyrightLayerListCountArgs = {
  where?: InputMaybe<MetadataCopyrightLayerWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCountryCodeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCountryCodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCountryCodeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCountryCodeListCountArgs = {
  where?: InputMaybe<MetadataCountryCodeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCountryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCountryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCountryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCountryListCountArgs = {
  where?: InputMaybe<MetadataCountryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCreatedTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatedTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatedTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCreatedTimeListCountArgs = {
  where?: InputMaybe<MetadataCreatedTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCreatorListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatorWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCreatorListCountArgs = {
  where?: InputMaybe<MetadataCreatorWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCreatorToolListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatorToolOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatorToolWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCreatorToolListCountArgs = {
  where?: InputMaybe<MetadataCreatorToolWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCreditListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreditOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreditWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCreditListCountArgs = {
  where?: InputMaybe<MetadataCreditWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField14ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField14OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField14WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField14ListCountArgs = {
  where?: InputMaybe<MetadataCustomField14WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField15ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField15OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField15WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField15ListCountArgs = {
  where?: InputMaybe<MetadataCustomField15WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField16ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField16OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField16WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField16ListCountArgs = {
  where?: InputMaybe<MetadataCustomField16WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField17ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField17OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField17WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField17ListCountArgs = {
  where?: InputMaybe<MetadataCustomField17WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField20ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField20OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField20WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField20ListCountArgs = {
  where?: InputMaybe<MetadataCustomField20WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField5ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField5OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField5WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField5ListCountArgs = {
  where?: InputMaybe<MetadataCustomField5WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField6ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField6OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField6WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField6ListCountArgs = {
  where?: InputMaybe<MetadataCustomField6WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField7ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField7OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField7WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField7ListCountArgs = {
  where?: InputMaybe<MetadataCustomField7WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField9ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField9OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField9WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataCustomField9ListCountArgs = {
  where?: InputMaybe<MetadataCustomField9WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDateCreatedListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateCreatedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateCreatedWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDateCreatedListCountArgs = {
  where?: InputMaybe<MetadataDateCreatedWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDateTimeDigitizedListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateTimeDigitizedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDateTimeDigitizedListCountArgs = {
  where?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDateTimeOriginalListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateTimeOriginalOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDateTimeOriginalListCountArgs = {
  where?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryAccountListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryAccountOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryAccountWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryAccountListCountArgs = {
  where?: InputMaybe<MetadataDeliveryAccountWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryCompanyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryCompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryCompanyListCountArgs = {
  where?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryCopyrightListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryCopyrightOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryCopyrightListCountArgs = {
  where?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryDateFolderListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDateFolderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryDateFolderListCountArgs = {
  where?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryDateTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryDateTimeListCountArgs = {
  where?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryDcCreatorListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDcCreatorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryDcCreatorListCountArgs = {
  where?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryDcRightsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDcRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryDcRightsListCountArgs = {
  where?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryFileTypeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryFileTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryFileTypeListCountArgs = {
  where?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryFolderListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryFolderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryFolderWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryFolderListCountArgs = {
  where?: InputMaybe<MetadataDeliveryFolderWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryKindWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryKindListCountArgs = {
  where?: InputMaybe<MetadataDeliveryKindWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryPathListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPathOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPathWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryPathListCountArgs = {
  where?: InputMaybe<MetadataDeliveryPathWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryPersonShownintheImageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryPersonShownintheImageListCountArgs = {
  where?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryPhotoshopCreditListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryPhotoshopCreditListCountArgs = {
  where?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryPhotoshopSourceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryPhotoshopSourceListCountArgs = {
  where?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryServiceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryServiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryServiceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryServiceListCountArgs = {
  where?: InputMaybe<MetadataDeliveryServiceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliverySubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliverySubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliverySubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliverySubjectListCountArgs = {
  where?: InputMaybe<MetadataDeliverySubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryUnderSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryUnderSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDeliveryUnderSubjectListCountArgs = {
  where?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDepartmentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDepartmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDepartmentWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDepartmentListCountArgs = {
  where?: InputMaybe<MetadataDepartmentWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDescriptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDescriptionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDescriptionListCountArgs = {
  where?: InputMaybe<MetadataDescriptionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDigitalAssetUrlListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDigitalAssetUrlOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDigitalAssetUrlListCountArgs = {
  where?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDivisionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDivisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDivisionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDivisionListCountArgs = {
  where?: InputMaybe<MetadataDivisionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDocumentTextListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDocumentTextOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDocumentTextWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataDocumentTextListCountArgs = {
  where?: InputMaybe<MetadataDocumentTextWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEditionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEditionListCountArgs = {
  where?: InputMaybe<MetadataEditionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEditorialVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditorialVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditorialVersionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEditorialVersionListCountArgs = {
  where?: InputMaybe<MetadataEditorialVersionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEditStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditStatusWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEditStatusListCountArgs = {
  where?: InputMaybe<MetadataEditStatusWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEnvironnementPhotoListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEnvironnementPhotoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEnvironnementPhotoListCountArgs = {
  where?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEquipmentInstitutionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEquipmentInstitutionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEquipmentInstitutionListCountArgs = {
  where?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEquipmentManufacturerListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEquipmentManufacturerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEquipmentManufacturerListCountArgs = {
  where?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEventListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEventOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEventWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataEventListCountArgs = {
  where?: InputMaybe<MetadataEventWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataExifListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataExifOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataExifWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataExifListCountArgs = {
  where?: InputMaybe<MetadataExifWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFabStorageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFabStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFabStorageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFabStorageListCountArgs = {
  where?: InputMaybe<MetadataFabStorageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileCheckForListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileCheckForOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileCheckForWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileCheckForListCountArgs = {
  where?: InputMaybe<MetadataFileCheckForWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileInfoBitsPerPixelListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoBitsPerPixelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileInfoBitsPerPixelListCountArgs = {
  where?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileInfoFramesListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoFramesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoFramesWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileInfoFramesListCountArgs = {
  where?: InputMaybe<MetadataFileInfoFramesWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileInfoPhotometricInterpretationListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileInfoPhotometricInterpretationListCountArgs = {
  where?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileInfoTransferSyntaxListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoTransferSyntaxOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileInfoTransferSyntaxListCountArgs = {
  where?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileStageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileStageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileStageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileStageListCountArgs = {
  where?: InputMaybe<MetadataFileStageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileStatusWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileStatusListCountArgs = {
  where?: InputMaybe<MetadataFileStatusWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileTypeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileTypeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFileTypeListCountArgs = {
  where?: InputMaybe<MetadataFileTypeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFixtureIdentifierListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFixtureIdentifierOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFixtureIdentifierListCountArgs = {
  where?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFormatListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFormatOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFormatWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataFormatListCountArgs = {
  where?: InputMaybe<MetadataFormatWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataGpsLatitudeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsLatitudeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsLatitudeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataGpsLatitudeListCountArgs = {
  where?: InputMaybe<MetadataGpsLatitudeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataGpsLongitudeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsLongitudeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsLongitudeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataGpsLongitudeListCountArgs = {
  where?: InputMaybe<MetadataGpsLongitudeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataGpsProcessingMethodListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsProcessingMethodOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataGpsProcessingMethodListCountArgs = {
  where?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataHeaderListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeaderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeaderWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataHeaderListCountArgs = {
  where?: InputMaybe<MetadataHeaderWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataHeaderPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeaderPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeaderPrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataHeaderPrismaListCountArgs = {
  where?: InputMaybe<MetadataHeaderPrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataHeadlineListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeadlineOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeadlineWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataHeadlineListCountArgs = {
  where?: InputMaybe<MetadataHeadlineWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataHistoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHistoryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataHistoryListCountArgs = {
  where?: InputMaybe<MetadataHistoryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIccProfileListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIccProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIccProfileWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIccProfileListCountArgs = {
  where?: InputMaybe<MetadataIccProfileWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIdAssignmentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdAssignmentWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIdAssignmentListCountArgs = {
  where?: InputMaybe<MetadataIdAssignmentWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIdMediaContentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdMediaContentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdMediaContentWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIdMediaContentListCountArgs = {
  where?: InputMaybe<MetadataIdMediaContentWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIdPhotoPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdPhotoPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIdPhotoPrismaListCountArgs = {
  where?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIdSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdSubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIdSubjectListCountArgs = {
  where?: InputMaybe<MetadataIdSubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataImageNotesListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataImageNotesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataImageNotesWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataImageNotesListCountArgs = {
  where?: InputMaybe<MetadataImageNotesWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataImageUniqueIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataImageUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataImageUniqueIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataImageUniqueIdListCountArgs = {
  where?: InputMaybe<MetadataImageUniqueIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataInstructionsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataInstructionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataInstructionsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataInstructionsListCountArgs = {
  where?: InputMaybe<MetadataInstructionsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIntellectualGenreListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIntellectualGenreOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIntellectualGenreWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIntellectualGenreListCountArgs = {
  where?: InputMaybe<MetadataIntellectualGenreWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIsoSpeedRatingsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIsoSpeedRatingsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIsoSpeedRatingsListCountArgs = {
  where?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIssueListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIssueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIssueWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataIssueListCountArgs = {
  where?: InputMaybe<MetadataIssueWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataJobIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataJobIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataJobIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataJobIdListCountArgs = {
  where?: InputMaybe<MetadataJobIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataJobProcessingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataJobProcessingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataJobProcessingWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataJobProcessingListCountArgs = {
  where?: InputMaybe<MetadataJobProcessingWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLayoutPictureEditingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLayoutPictureEditingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLayoutPictureEditingListCountArgs = {
  where?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLayoutStorageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLayoutStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLayoutStorageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLayoutStorageListCountArgs = {
  where?: InputMaybe<MetadataLayoutStorageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLicenseListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLicenseOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLicenseWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLicenseListCountArgs = {
  where?: InputMaybe<MetadataLicenseWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLinksListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLinksOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLinksWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLinksListCountArgs = {
  where?: InputMaybe<MetadataLinksWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLocalCaptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLocalCaptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLocalCaptionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLocalCaptionListCountArgs = {
  where?: InputMaybe<MetadataLocalCaptionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLocationListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLocationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLocationWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataLocationListCountArgs = {
  where?: InputMaybe<MetadataLocationWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMakeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMakeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMakeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMakeListCountArgs = {
  where?: InputMaybe<MetadataMakeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataManifestListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataManifestOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataManifestWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataManifestListCountArgs = {
  where?: InputMaybe<MetadataManifestWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMarkedListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMarkedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMarkedWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMarkedListCountArgs = {
  where?: InputMaybe<MetadataMarkedWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMasterDocumentIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMasterDocumentIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMasterDocumentIdListCountArgs = {
  where?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMaxAvailHeightListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMaxAvailHeightOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMaxAvailHeightListCountArgs = {
  where?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMaxAvailWidthListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMaxAvailWidthOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMaxAvailWidthListCountArgs = {
  where?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMinorModelAgeDisclosureListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMinorModelAgeDisclosureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMinorModelAgeDisclosureListCountArgs = {
  where?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataModelAgeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelAgeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelAgeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataModelAgeListCountArgs = {
  where?: InputMaybe<MetadataModelAgeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataModelListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataModelListCountArgs = {
  where?: InputMaybe<MetadataModelWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataModelReleaseIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelReleaseIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelReleaseIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataModelReleaseIdListCountArgs = {
  where?: InputMaybe<MetadataModelReleaseIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataModelReleaseStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelReleaseStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataModelReleaseStatusListCountArgs = {
  where?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMorePermissionsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMorePermissionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMorePermissionsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataMorePermissionsListCountArgs = {
  where?: InputMaybe<MetadataMorePermissionsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataObjectCycleListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataObjectCycleOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataObjectCycleWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataObjectCycleListCountArgs = {
  where?: InputMaybe<MetadataObjectCycleWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOrganisationInImageNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOrganisationInImageNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOrganisationInImageNameListCountArgs = {
  where?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOriginalColorListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalColorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalColorWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOriginalColorListCountArgs = {
  where?: InputMaybe<MetadataOriginalColorWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOriginalIccProfileListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalIccProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOriginalIccProfileListCountArgs = {
  where?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOriginalNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOriginalNameListCountArgs = {
  where?: InputMaybe<MetadataOriginalNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOriginalWeigthListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalWeigthOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalWeigthWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOriginalWeigthListCountArgs = {
  where?: InputMaybe<MetadataOriginalWeigthWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOwnerIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOwnerIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOwnerIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOwnerIdListCountArgs = {
  where?: InputMaybe<MetadataOwnerIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOwnerListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOwnerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOwnerWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataOwnerListCountArgs = {
  where?: InputMaybe<MetadataOwnerWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPageListCountArgs = {
  where?: InputMaybe<MetadataPageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPagePrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPagePrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPagePrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPagePrismaListCountArgs = {
  where?: InputMaybe<MetadataPagePrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPaintBasedCorrectionsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPaintBasedCorrectionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPaintBasedCorrectionsListCountArgs = {
  where?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPatientDobListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientDobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientDobWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPatientDobListCountArgs = {
  where?: InputMaybe<MetadataPatientDobWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPatientIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPatientIdListCountArgs = {
  where?: InputMaybe<MetadataPatientIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPatientNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPatientNameListCountArgs = {
  where?: InputMaybe<MetadataPatientNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPatientSexListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientSexOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientSexWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPatientSexListCountArgs = {
  where?: InputMaybe<MetadataPatientSexWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPersonInImageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPersonInImageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPersonInImageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPersonInImageListCountArgs = {
  where?: InputMaybe<MetadataPersonInImageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPhotoStorageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPhotoStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPhotoStorageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPhotoStorageListCountArgs = {
  where?: InputMaybe<MetadataPhotoStorageWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPrepressPictureEditingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrepressPictureEditingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPrepressPictureEditingListCountArgs = {
  where?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPriceLevelListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPriceLevelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPriceLevelWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPriceLevelListCountArgs = {
  where?: InputMaybe<MetadataPriceLevelWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPrintingProfileListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrintingProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrintingProfileWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPrintingProfileListCountArgs = {
  where?: InputMaybe<MetadataPrintingProfileWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPrismaProductionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrismaProductionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrismaProductionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPrismaProductionListCountArgs = {
  where?: InputMaybe<MetadataPrismaProductionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProcessHistoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessHistoryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProcessHistoryListCountArgs = {
  where?: InputMaybe<MetadataProcessHistoryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProcessParameterListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessParameterOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessParameterWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProcessParameterListCountArgs = {
  where?: InputMaybe<MetadataProcessParameterWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProcessStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessStatusWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProcessStatusListCountArgs = {
  where?: InputMaybe<MetadataProcessStatusWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProductListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProductListCountArgs = {
  where?: InputMaybe<MetadataProductWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProductShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductShortNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProductShortNameListCountArgs = {
  where?: InputMaybe<MetadataProductShortNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProductsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProductsListCountArgs = {
  where?: InputMaybe<MetadataProductsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProduitsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProduitsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProduitsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProduitsListCountArgs = {
  where?: InputMaybe<MetadataProduitsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProgramVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProgramVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProgramVersionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataProgramVersionListCountArgs = {
  where?: InputMaybe<MetadataProgramVersionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPropertyReleaseIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPropertyReleaseIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPropertyReleaseIdListCountArgs = {
  where?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPropertyReleaseStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPropertyReleaseStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPropertyReleaseStatusListCountArgs = {
  where?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPublisherListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublisherOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublisherWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPublisherListCountArgs = {
  where?: InputMaybe<MetadataPublisherWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPublishingIssueListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublishingIssueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublishingIssueWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPublishingIssueListCountArgs = {
  where?: InputMaybe<MetadataPublishingIssueWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPublishingSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublishingSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublishingSubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataPublishingSubjectListCountArgs = {
  where?: InputMaybe<MetadataPublishingSubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataQualifiedUsePrismaByListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataQualifiedUsePrismaByListCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataQualifiedUsePrismaDateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataQualifiedUsePrismaDateListCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataQualifiedUsePrismaDurationListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataQualifiedUsePrismaDurationListCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataQualifiedUsePrismaSupportListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataQualifiedUsePrismaSupportListCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataRatingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRatingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRatingWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataRatingListCountArgs = {
  where?: InputMaybe<MetadataRatingWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataReferenceDateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceDateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataReferenceDateListCountArgs = {
  where?: InputMaybe<MetadataReferenceDateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataReferenceNumberListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceNumberWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataReferenceNumberListCountArgs = {
  where?: InputMaybe<MetadataReferenceNumberWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataReferenceServiceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceServiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceServiceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataReferenceServiceListCountArgs = {
  where?: InputMaybe<MetadataReferenceServiceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataReleaseDateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReleaseDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReleaseDateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataReleaseDateListCountArgs = {
  where?: InputMaybe<MetadataReleaseDateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataReleaseTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReleaseTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReleaseTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataReleaseTimeListCountArgs = {
  where?: InputMaybe<MetadataReleaseTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataRequiredPermissionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRequiredPermissionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRequiredPermissionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataRequiredPermissionListCountArgs = {
  where?: InputMaybe<MetadataRequiredPermissionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataResolutionKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataResolutionKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataResolutionKindWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataResolutionKindListCountArgs = {
  where?: InputMaybe<MetadataResolutionKindWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataRightsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRightsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataRightsListCountArgs = {
  where?: InputMaybe<MetadataRightsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataRoyaltyfreeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRoyaltyfreeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataRoyaltyfreeListCountArgs = {
  where?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSceneListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSceneOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSceneWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSceneListCountArgs = {
  where?: InputMaybe<MetadataSceneWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSectionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSectionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSectionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSectionListCountArgs = {
  where?: InputMaybe<MetadataSectionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSentToListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSentToOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSentToWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSentToListCountArgs = {
  where?: InputMaybe<MetadataSentToWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSerialNumberListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSerialNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSerialNumberWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSerialNumberListCountArgs = {
  where?: InputMaybe<MetadataSerialNumberWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSeriesDateTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSeriesDateTimeListCountArgs = {
  where?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSeriesDescriptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSeriesDescriptionListCountArgs = {
  where?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSeriesModalityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesModalityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesModalityWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSeriesModalityListCountArgs = {
  where?: InputMaybe<MetadataSeriesModalityWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSeriesNumberListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesNumberWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSeriesNumberListCountArgs = {
  where?: InputMaybe<MetadataSeriesNumberWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataShortUniqueIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataShortUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataShortUniqueIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataShortUniqueIdListCountArgs = {
  where?: InputMaybe<MetadataShortUniqueIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSourceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSourceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSourceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSourceListCountArgs = {
  where?: InputMaybe<MetadataSourceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataStateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataStateListCountArgs = {
  where?: InputMaybe<MetadataStateWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataStudyDateTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyDateTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataStudyDateTimeListCountArgs = {
  where?: InputMaybe<MetadataStudyDateTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataStudyDescriptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyDescriptionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataStudyDescriptionListCountArgs = {
  where?: InputMaybe<MetadataStudyDescriptionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataStudyIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataStudyIdListCountArgs = {
  where?: InputMaybe<MetadataStudyIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataStudyPhysicianListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyPhysicianOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyPhysicianWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataStudyPhysicianListCountArgs = {
  where?: InputMaybe<MetadataStudyPhysicianWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSubjectCodeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectCodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectCodeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSubjectCodeListCountArgs = {
  where?: InputMaybe<MetadataSubjectCodeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSubjectListCountArgs = {
  where?: InputMaybe<MetadataSubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSubjectPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectPrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSubjectPrismaListCountArgs = {
  where?: InputMaybe<MetadataSubjectPrismaWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSupplementalCategoriesListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSupplementalCategoriesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataSupplementalCategoriesListCountArgs = {
  where?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTargetVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTargetVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTargetVersionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTargetVersionListCountArgs = {
  where?: InputMaybe<MetadataTargetVersionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTitleListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTitleOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTitleWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTitleListCountArgs = {
  where?: InputMaybe<MetadataTitleWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataToneCurveListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataToneCurveOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataToneCurveWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataToneCurveListCountArgs = {
  where?: InputMaybe<MetadataToneCurveWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTransferredByEmailListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByEmailOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByEmailWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTransferredByEmailListCountArgs = {
  where?: InputMaybe<MetadataTransferredByEmailWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTransferredByFullNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByFullNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTransferredByFullNameListCountArgs = {
  where?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTransferredByListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTransferredByListCountArgs = {
  where?: InputMaybe<MetadataTransferredByWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTransmissionReferenceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransmissionReferenceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTransmissionReferenceListCountArgs = {
  where?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTypeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTypeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataTypeListCountArgs = {
  where?: InputMaybe<MetadataTypeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUndersubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUndersubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUndersubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUndersubjectListCountArgs = {
  where?: InputMaybe<MetadataUndersubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUnderUnderSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnderUnderSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUnderUnderSubjectListCountArgs = {
  where?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUniqueIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUniqueIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUniqueIdListCountArgs = {
  where?: InputMaybe<MetadataUniqueIdWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUnitListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnitOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnitWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUnitListCountArgs = {
  where?: InputMaybe<MetadataUnitWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUnitShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnitShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnitShortNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUnitShortNameListCountArgs = {
  where?: InputMaybe<MetadataUnitShortNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUploadedByFullNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadedByFullNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUploadedByFullNameListCountArgs = {
  where?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUploadedByListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadedByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadedByWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUploadedByListCountArgs = {
  where?: InputMaybe<MetadataUploadedByWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUploadTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUploadTimeListCountArgs = {
  where?: InputMaybe<MetadataUploadTimeWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUrgencyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUrgencyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUrgencyWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUrgencyListCountArgs = {
  where?: InputMaybe<MetadataUrgencyWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUsageTermsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUsageTermsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUsageTermsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUsageTermsListCountArgs = {
  where?: InputMaybe<MetadataUsageTermsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserCommentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserCommentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserCommentWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserCommentListCountArgs = {
  where?: InputMaybe<MetadataUserCommentWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined195ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined195OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined195WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined195ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined195WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined237ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined237OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined237WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined237ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined237WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined238ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined238OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined238WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined238ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined238WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined239ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined239OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined239WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined239ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined239WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined242ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined242OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined242WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined242ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined242WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined62ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined62OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined62WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataUserDefined62ListCountArgs = {
  where?: InputMaybe<MetadataUserDefined62WhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataValidListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataValidOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataValidWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataValidListCountArgs = {
  where?: InputMaybe<MetadataValidWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataVersionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataVersionListCountArgs = {
  where?: InputMaybe<MetadataVersionWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataWebStatementListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataWebStatementOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWebStatementWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataWebStatementListCountArgs = {
  where?: InputMaybe<MetadataWebStatementWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataWorkflowKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataWorkflowKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWorkflowKindWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataWorkflowKindListCountArgs = {
  where?: InputMaybe<MetadataWorkflowKindWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataXmpFileStampsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataXmpFileStampsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataXmpFileStampsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataXmpFileStampsListCountArgs = {
  where?: InputMaybe<MetadataXmpFileStampsWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataXmpHistoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataXmpHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataXmpHistoryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataMetadataXmpHistoryListCountArgs = {
  where?: InputMaybe<MetadataXmpHistoryWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataPhotoMetadataCountArgs = {
  where?: InputMaybe<PhotoMetadataWhereInput>;
};


/** "Metadata" resource's node */
export type MetadataPhotoMetadatasArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoMetadataOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoMetadataWhereInput>;
};

/** "MetadataAccessRights" resource's node */
export type MetadataAccessRights = {
  __typename?: 'MetadataAccessRights';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataAccessRightsCreateInput = {
  /** Actions for the "MetadataAccessRights.archive" relation */
  archive: MetadataAccessRightsNestedArchiveCreateInput;
  /** Actions for the "MetadataAccessRights.metadata" relation */
  metadata: MetadataAccessRightsNestedMetadataCreateInput;
  /** Actions for the "MetadataAccessRights.photo" relation */
  photo: MetadataAccessRightsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAccessRightsNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataAccessRights" node, through the "MetadataAccessRights.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataAccessRights" node, through the "MetadataAccessRights.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataAccessRights" node, through the "MetadataAccessRights.archive" relation. */
  update?: InputMaybe<MetadataAccessRightsNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataAccessRights" node, through the "MetadataAccessRights.archive" relation. */
  upsert?: InputMaybe<MetadataAccessRightsNestedUpsertArchiveCreateInput>;
};

export type MetadataAccessRightsNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.archive" relation. */
  update?: InputMaybe<MetadataAccessRightsNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.archive" relation. */
  upsert?: InputMaybe<MetadataAccessRightsNestedUpsertArchiveUpdateInput>;
};

export type MetadataAccessRightsNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataAccessRights" node, through the "MetadataAccessRights.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataAccessRights" node, through the "MetadataAccessRights.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataAccessRights" node, through the "MetadataAccessRights.metadata" relation. */
  update?: InputMaybe<MetadataAccessRightsNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataAccessRights" node, through the "MetadataAccessRights.metadata" relation. */
  upsert?: InputMaybe<MetadataAccessRightsNestedUpsertMetadataCreateInput>;
};

export type MetadataAccessRightsNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.metadata" relation. */
  update?: InputMaybe<MetadataAccessRightsNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.metadata" relation. */
  upsert?: InputMaybe<MetadataAccessRightsNestedUpsertMetadataUpdateInput>;
};

export type MetadataAccessRightsNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataAccessRights" node, through the "MetadataAccessRights.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataAccessRights" node, through the "MetadataAccessRights.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataAccessRights" node, through the "MetadataAccessRights.photo" relation. */
  update?: InputMaybe<MetadataAccessRightsNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataAccessRights" node, through the "MetadataAccessRights.photo" relation. */
  upsert?: InputMaybe<MetadataAccessRightsNestedUpsertPhotoCreateInput>;
};

export type MetadataAccessRightsNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.photo" relation. */
  update?: InputMaybe<MetadataAccessRightsNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataAccessRights" node, through the "MetadataAccessRights.photo" relation. */
  upsert?: InputMaybe<MetadataAccessRightsNestedUpsertPhotoUpdateInput>;
};

export type MetadataAccessRightsNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAccessRightsNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAccessRightsNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAccessRightsNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAccessRightsNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAccessRightsNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAccessRightsNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAccessRightsNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAccessRightsNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAccessRightsNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAccessRightsNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAccessRightsNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataAccessRightsOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataAccessRightsUpdateInput = {
  /** Actions for the "MetadataAccessRights.archive" relation */
  archive?: InputMaybe<MetadataAccessRightsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAccessRights.metadata" relation */
  metadata?: InputMaybe<MetadataAccessRightsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAccessRights.photo" relation */
  photo?: InputMaybe<MetadataAccessRightsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataAccessRights" nodes by specifying some conditions */
export type MetadataAccessRightsWhereInput = {
  AND?: InputMaybe<Array<MetadataAccessRightsWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataAccessRightsWhereInput>;
  OR?: InputMaybe<Array<MetadataAccessRightsWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataAccessRights" node. */
export type MetadataAccessRightsWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataAccessRightsWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataAccessRights.metadata" relation */
  metadata: MetadataAccessRightsNestedMetadataCreateInput;
  /** Actions for the "MetadataAccessRights.photo" relation */
  photo: MetadataAccessRightsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAccessRightsWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataAccessRights.metadata" relation */
  metadata?: InputMaybe<MetadataAccessRightsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAccessRights.photo" relation */
  photo?: InputMaybe<MetadataAccessRightsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAccessRightsWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataAccessRights.archive" relation */
  archive: MetadataAccessRightsNestedArchiveCreateInput;
  /** Actions for the "MetadataAccessRights.photo" relation */
  photo: MetadataAccessRightsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAccessRightsWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataAccessRights.archive" relation */
  archive?: InputMaybe<MetadataAccessRightsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAccessRights.photo" relation */
  photo?: InputMaybe<MetadataAccessRightsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAccessRightsWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataAccessRights.archive" relation */
  archive: MetadataAccessRightsNestedArchiveCreateInput;
  /** Actions for the "MetadataAccessRights.metadata" relation */
  metadata: MetadataAccessRightsNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAccessRightsWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataAccessRights.archive" relation */
  archive?: InputMaybe<MetadataAccessRightsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAccessRights.metadata" relation */
  metadata?: InputMaybe<MetadataAccessRightsNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataAddlModelInfo" resource's node */
export type MetadataAddlModelInfo = {
  __typename?: 'MetadataAddlModelInfo';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataAddlModelInfoCreateInput = {
  /** Actions for the "MetadataAddlModelInfo.archive" relation */
  archive: MetadataAddlModelInfoNestedArchiveCreateInput;
  /** Actions for the "MetadataAddlModelInfo.metadata" relation */
  metadata: MetadataAddlModelInfoNestedMetadataCreateInput;
  /** Actions for the "MetadataAddlModelInfo.photo" relation */
  photo: MetadataAddlModelInfoNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAddlModelInfoNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.archive" relation. */
  update?: InputMaybe<MetadataAddlModelInfoNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.archive" relation. */
  upsert?: InputMaybe<MetadataAddlModelInfoNestedUpsertArchiveCreateInput>;
};

export type MetadataAddlModelInfoNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.archive" relation. */
  update?: InputMaybe<MetadataAddlModelInfoNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.archive" relation. */
  upsert?: InputMaybe<MetadataAddlModelInfoNestedUpsertArchiveUpdateInput>;
};

export type MetadataAddlModelInfoNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.metadata" relation. */
  update?: InputMaybe<MetadataAddlModelInfoNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.metadata" relation. */
  upsert?: InputMaybe<MetadataAddlModelInfoNestedUpsertMetadataCreateInput>;
};

export type MetadataAddlModelInfoNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.metadata" relation. */
  update?: InputMaybe<MetadataAddlModelInfoNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.metadata" relation. */
  upsert?: InputMaybe<MetadataAddlModelInfoNestedUpsertMetadataUpdateInput>;
};

export type MetadataAddlModelInfoNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.photo" relation. */
  update?: InputMaybe<MetadataAddlModelInfoNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.photo" relation. */
  upsert?: InputMaybe<MetadataAddlModelInfoNestedUpsertPhotoCreateInput>;
};

export type MetadataAddlModelInfoNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.photo" relation. */
  update?: InputMaybe<MetadataAddlModelInfoNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataAddlModelInfo" node, through the "MetadataAddlModelInfo.photo" relation. */
  upsert?: InputMaybe<MetadataAddlModelInfoNestedUpsertPhotoUpdateInput>;
};

export type MetadataAddlModelInfoNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAddlModelInfoNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAddlModelInfoNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAddlModelInfoNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAddlModelInfoNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAddlModelInfoNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAddlModelInfoNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAddlModelInfoNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAddlModelInfoNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAddlModelInfoNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAddlModelInfoNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAddlModelInfoNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataAddlModelInfoOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataAddlModelInfoUpdateInput = {
  /** Actions for the "MetadataAddlModelInfo.archive" relation */
  archive?: InputMaybe<MetadataAddlModelInfoNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAddlModelInfo.metadata" relation */
  metadata?: InputMaybe<MetadataAddlModelInfoNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAddlModelInfo.photo" relation */
  photo?: InputMaybe<MetadataAddlModelInfoNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataAddlModelInfo" nodes by specifying some conditions */
export type MetadataAddlModelInfoWhereInput = {
  AND?: InputMaybe<Array<MetadataAddlModelInfoWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataAddlModelInfoWhereInput>;
  OR?: InputMaybe<Array<MetadataAddlModelInfoWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataAddlModelInfo" node. */
export type MetadataAddlModelInfoWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataAddlModelInfoWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataAddlModelInfo.metadata" relation */
  metadata: MetadataAddlModelInfoNestedMetadataCreateInput;
  /** Actions for the "MetadataAddlModelInfo.photo" relation */
  photo: MetadataAddlModelInfoNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAddlModelInfoWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataAddlModelInfo.metadata" relation */
  metadata?: InputMaybe<MetadataAddlModelInfoNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAddlModelInfo.photo" relation */
  photo?: InputMaybe<MetadataAddlModelInfoNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAddlModelInfoWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataAddlModelInfo.archive" relation */
  archive: MetadataAddlModelInfoNestedArchiveCreateInput;
  /** Actions for the "MetadataAddlModelInfo.photo" relation */
  photo: MetadataAddlModelInfoNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAddlModelInfoWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataAddlModelInfo.archive" relation */
  archive?: InputMaybe<MetadataAddlModelInfoNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAddlModelInfo.photo" relation */
  photo?: InputMaybe<MetadataAddlModelInfoNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAddlModelInfoWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataAddlModelInfo.archive" relation */
  archive: MetadataAddlModelInfoNestedArchiveCreateInput;
  /** Actions for the "MetadataAddlModelInfo.metadata" relation */
  metadata: MetadataAddlModelInfoNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAddlModelInfoWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataAddlModelInfo.archive" relation */
  archive?: InputMaybe<MetadataAddlModelInfoNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAddlModelInfo.metadata" relation */
  metadata?: InputMaybe<MetadataAddlModelInfoNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataArchiveChild" resource's node */
export type MetadataArchiveChild = {
  __typename?: 'MetadataArchiveChild';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataArchiveChildCreateInput = {
  /** Actions for the "MetadataArchiveChild.archive" relation */
  archive: MetadataArchiveChildNestedArchiveCreateInput;
  /** Actions for the "MetadataArchiveChild.metadata" relation */
  metadata: MetadataArchiveChildNestedMetadataCreateInput;
  /** Actions for the "MetadataArchiveChild.photo" relation */
  photo: MetadataArchiveChildNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveChildNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.archive" relation. */
  update?: InputMaybe<MetadataArchiveChildNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.archive" relation. */
  upsert?: InputMaybe<MetadataArchiveChildNestedUpsertArchiveCreateInput>;
};

export type MetadataArchiveChildNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.archive" relation. */
  update?: InputMaybe<MetadataArchiveChildNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.archive" relation. */
  upsert?: InputMaybe<MetadataArchiveChildNestedUpsertArchiveUpdateInput>;
};

export type MetadataArchiveChildNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.metadata" relation. */
  update?: InputMaybe<MetadataArchiveChildNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.metadata" relation. */
  upsert?: InputMaybe<MetadataArchiveChildNestedUpsertMetadataCreateInput>;
};

export type MetadataArchiveChildNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.metadata" relation. */
  update?: InputMaybe<MetadataArchiveChildNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.metadata" relation. */
  upsert?: InputMaybe<MetadataArchiveChildNestedUpsertMetadataUpdateInput>;
};

export type MetadataArchiveChildNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.photo" relation. */
  update?: InputMaybe<MetadataArchiveChildNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataArchiveChild" node, through the "MetadataArchiveChild.photo" relation. */
  upsert?: InputMaybe<MetadataArchiveChildNestedUpsertPhotoCreateInput>;
};

export type MetadataArchiveChildNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.photo" relation. */
  update?: InputMaybe<MetadataArchiveChildNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataArchiveChild" node, through the "MetadataArchiveChild.photo" relation. */
  upsert?: InputMaybe<MetadataArchiveChildNestedUpsertPhotoUpdateInput>;
};

export type MetadataArchiveChildNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataArchiveChildNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataArchiveChildNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataArchiveChildNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataArchiveChildNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataArchiveChildNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataArchiveChildNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataArchiveChildNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataArchiveChildNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataArchiveChildNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataArchiveChildNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataArchiveChildNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataArchiveChildOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataArchiveChildUpdateInput = {
  /** Actions for the "MetadataArchiveChild.archive" relation */
  archive?: InputMaybe<MetadataArchiveChildNestedArchiveUpdateInput>;
  /** Actions for the "MetadataArchiveChild.metadata" relation */
  metadata?: InputMaybe<MetadataArchiveChildNestedMetadataUpdateInput>;
  /** Actions for the "MetadataArchiveChild.photo" relation */
  photo?: InputMaybe<MetadataArchiveChildNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataArchiveChild" nodes by specifying some conditions */
export type MetadataArchiveChildWhereInput = {
  AND?: InputMaybe<Array<MetadataArchiveChildWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataArchiveChildWhereInput>;
  OR?: InputMaybe<Array<MetadataArchiveChildWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataArchiveChild" node. */
export type MetadataArchiveChildWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataArchiveChildWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataArchiveChild.metadata" relation */
  metadata: MetadataArchiveChildNestedMetadataCreateInput;
  /** Actions for the "MetadataArchiveChild.photo" relation */
  photo: MetadataArchiveChildNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveChildWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataArchiveChild.metadata" relation */
  metadata?: InputMaybe<MetadataArchiveChildNestedMetadataUpdateInput>;
  /** Actions for the "MetadataArchiveChild.photo" relation */
  photo?: InputMaybe<MetadataArchiveChildNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveChildWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataArchiveChild.archive" relation */
  archive: MetadataArchiveChildNestedArchiveCreateInput;
  /** Actions for the "MetadataArchiveChild.photo" relation */
  photo: MetadataArchiveChildNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveChildWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataArchiveChild.archive" relation */
  archive?: InputMaybe<MetadataArchiveChildNestedArchiveUpdateInput>;
  /** Actions for the "MetadataArchiveChild.photo" relation */
  photo?: InputMaybe<MetadataArchiveChildNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveChildWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataArchiveChild.archive" relation */
  archive: MetadataArchiveChildNestedArchiveCreateInput;
  /** Actions for the "MetadataArchiveChild.metadata" relation */
  metadata: MetadataArchiveChildNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveChildWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataArchiveChild.archive" relation */
  archive?: InputMaybe<MetadataArchiveChildNestedArchiveUpdateInput>;
  /** Actions for the "MetadataArchiveChild.metadata" relation */
  metadata?: InputMaybe<MetadataArchiveChildNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataArchiveParent" resource's node */
export type MetadataArchiveParent = {
  __typename?: 'MetadataArchiveParent';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataArchiveParentCreateInput = {
  /** Actions for the "MetadataArchiveParent.archive" relation */
  archive: MetadataArchiveParentNestedArchiveCreateInput;
  /** Actions for the "MetadataArchiveParent.metadata" relation */
  metadata: MetadataArchiveParentNestedMetadataCreateInput;
  /** Actions for the "MetadataArchiveParent.photo" relation */
  photo: MetadataArchiveParentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveParentNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.archive" relation. */
  update?: InputMaybe<MetadataArchiveParentNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.archive" relation. */
  upsert?: InputMaybe<MetadataArchiveParentNestedUpsertArchiveCreateInput>;
};

export type MetadataArchiveParentNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.archive" relation. */
  update?: InputMaybe<MetadataArchiveParentNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.archive" relation. */
  upsert?: InputMaybe<MetadataArchiveParentNestedUpsertArchiveUpdateInput>;
};

export type MetadataArchiveParentNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.metadata" relation. */
  update?: InputMaybe<MetadataArchiveParentNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.metadata" relation. */
  upsert?: InputMaybe<MetadataArchiveParentNestedUpsertMetadataCreateInput>;
};

export type MetadataArchiveParentNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.metadata" relation. */
  update?: InputMaybe<MetadataArchiveParentNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.metadata" relation. */
  upsert?: InputMaybe<MetadataArchiveParentNestedUpsertMetadataUpdateInput>;
};

export type MetadataArchiveParentNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.photo" relation. */
  update?: InputMaybe<MetadataArchiveParentNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataArchiveParent" node, through the "MetadataArchiveParent.photo" relation. */
  upsert?: InputMaybe<MetadataArchiveParentNestedUpsertPhotoCreateInput>;
};

export type MetadataArchiveParentNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.photo" relation. */
  update?: InputMaybe<MetadataArchiveParentNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataArchiveParent" node, through the "MetadataArchiveParent.photo" relation. */
  upsert?: InputMaybe<MetadataArchiveParentNestedUpsertPhotoUpdateInput>;
};

export type MetadataArchiveParentNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataArchiveParentNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataArchiveParentNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataArchiveParentNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataArchiveParentNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataArchiveParentNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataArchiveParentNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataArchiveParentNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataArchiveParentNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataArchiveParentNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataArchiveParentNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataArchiveParentNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataArchiveParentOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataArchiveParentUpdateInput = {
  /** Actions for the "MetadataArchiveParent.archive" relation */
  archive?: InputMaybe<MetadataArchiveParentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataArchiveParent.metadata" relation */
  metadata?: InputMaybe<MetadataArchiveParentNestedMetadataUpdateInput>;
  /** Actions for the "MetadataArchiveParent.photo" relation */
  photo?: InputMaybe<MetadataArchiveParentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataArchiveParent" nodes by specifying some conditions */
export type MetadataArchiveParentWhereInput = {
  AND?: InputMaybe<Array<MetadataArchiveParentWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataArchiveParentWhereInput>;
  OR?: InputMaybe<Array<MetadataArchiveParentWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataArchiveParent" node. */
export type MetadataArchiveParentWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataArchiveParentWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataArchiveParent.metadata" relation */
  metadata: MetadataArchiveParentNestedMetadataCreateInput;
  /** Actions for the "MetadataArchiveParent.photo" relation */
  photo: MetadataArchiveParentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveParentWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataArchiveParent.metadata" relation */
  metadata?: InputMaybe<MetadataArchiveParentNestedMetadataUpdateInput>;
  /** Actions for the "MetadataArchiveParent.photo" relation */
  photo?: InputMaybe<MetadataArchiveParentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveParentWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataArchiveParent.archive" relation */
  archive: MetadataArchiveParentNestedArchiveCreateInput;
  /** Actions for the "MetadataArchiveParent.photo" relation */
  photo: MetadataArchiveParentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveParentWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataArchiveParent.archive" relation */
  archive?: InputMaybe<MetadataArchiveParentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataArchiveParent.photo" relation */
  photo?: InputMaybe<MetadataArchiveParentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveParentWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataArchiveParent.archive" relation */
  archive: MetadataArchiveParentNestedArchiveCreateInput;
  /** Actions for the "MetadataArchiveParent.metadata" relation */
  metadata: MetadataArchiveParentNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataArchiveParentWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataArchiveParent.archive" relation */
  archive?: InputMaybe<MetadataArchiveParentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataArchiveParent.metadata" relation */
  metadata?: InputMaybe<MetadataArchiveParentNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataAssetState" resource's node */
export type MetadataAssetState = {
  __typename?: 'MetadataAssetState';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataAssetStateCreateInput = {
  /** Actions for the "MetadataAssetState.archive" relation */
  archive: MetadataAssetStateNestedArchiveCreateInput;
  /** Actions for the "MetadataAssetState.metadata" relation */
  metadata: MetadataAssetStateNestedMetadataCreateInput;
  /** Actions for the "MetadataAssetState.photo" relation */
  photo: MetadataAssetStateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAssetStateNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataAssetState" node, through the "MetadataAssetState.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataAssetState" node, through the "MetadataAssetState.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataAssetState" node, through the "MetadataAssetState.archive" relation. */
  update?: InputMaybe<MetadataAssetStateNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataAssetState" node, through the "MetadataAssetState.archive" relation. */
  upsert?: InputMaybe<MetadataAssetStateNestedUpsertArchiveCreateInput>;
};

export type MetadataAssetStateNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataAssetState" node, through the "MetadataAssetState.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataAssetState" node, through the "MetadataAssetState.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataAssetState" node, through the "MetadataAssetState.archive" relation. */
  update?: InputMaybe<MetadataAssetStateNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataAssetState" node, through the "MetadataAssetState.archive" relation. */
  upsert?: InputMaybe<MetadataAssetStateNestedUpsertArchiveUpdateInput>;
};

export type MetadataAssetStateNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataAssetState" node, through the "MetadataAssetState.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataAssetState" node, through the "MetadataAssetState.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataAssetState" node, through the "MetadataAssetState.metadata" relation. */
  update?: InputMaybe<MetadataAssetStateNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataAssetState" node, through the "MetadataAssetState.metadata" relation. */
  upsert?: InputMaybe<MetadataAssetStateNestedUpsertMetadataCreateInput>;
};

export type MetadataAssetStateNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataAssetState" node, through the "MetadataAssetState.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataAssetState" node, through the "MetadataAssetState.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataAssetState" node, through the "MetadataAssetState.metadata" relation. */
  update?: InputMaybe<MetadataAssetStateNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataAssetState" node, through the "MetadataAssetState.metadata" relation. */
  upsert?: InputMaybe<MetadataAssetStateNestedUpsertMetadataUpdateInput>;
};

export type MetadataAssetStateNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataAssetState" node, through the "MetadataAssetState.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataAssetState" node, through the "MetadataAssetState.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataAssetState" node, through the "MetadataAssetState.photo" relation. */
  update?: InputMaybe<MetadataAssetStateNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataAssetState" node, through the "MetadataAssetState.photo" relation. */
  upsert?: InputMaybe<MetadataAssetStateNestedUpsertPhotoCreateInput>;
};

export type MetadataAssetStateNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataAssetState" node, through the "MetadataAssetState.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataAssetState" node, through the "MetadataAssetState.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataAssetState" node, through the "MetadataAssetState.photo" relation. */
  update?: InputMaybe<MetadataAssetStateNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataAssetState" node, through the "MetadataAssetState.photo" relation. */
  upsert?: InputMaybe<MetadataAssetStateNestedUpsertPhotoUpdateInput>;
};

export type MetadataAssetStateNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAssetStateNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAssetStateNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAssetStateNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAssetStateNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAssetStateNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAssetStateNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAssetStateNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAssetStateNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAssetStateNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAssetStateNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAssetStateNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataAssetStateOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataAssetStateUpdateInput = {
  /** Actions for the "MetadataAssetState.archive" relation */
  archive?: InputMaybe<MetadataAssetStateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAssetState.metadata" relation */
  metadata?: InputMaybe<MetadataAssetStateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAssetState.photo" relation */
  photo?: InputMaybe<MetadataAssetStateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataAssetState" nodes by specifying some conditions */
export type MetadataAssetStateWhereInput = {
  AND?: InputMaybe<Array<MetadataAssetStateWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataAssetStateWhereInput>;
  OR?: InputMaybe<Array<MetadataAssetStateWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataAssetState" node. */
export type MetadataAssetStateWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataAssetStateWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataAssetState.metadata" relation */
  metadata: MetadataAssetStateNestedMetadataCreateInput;
  /** Actions for the "MetadataAssetState.photo" relation */
  photo: MetadataAssetStateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAssetStateWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataAssetState.metadata" relation */
  metadata?: InputMaybe<MetadataAssetStateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAssetState.photo" relation */
  photo?: InputMaybe<MetadataAssetStateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAssetStateWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataAssetState.archive" relation */
  archive: MetadataAssetStateNestedArchiveCreateInput;
  /** Actions for the "MetadataAssetState.photo" relation */
  photo: MetadataAssetStateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAssetStateWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataAssetState.archive" relation */
  archive?: InputMaybe<MetadataAssetStateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAssetState.photo" relation */
  photo?: InputMaybe<MetadataAssetStateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAssetStateWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataAssetState.archive" relation */
  archive: MetadataAssetStateNestedArchiveCreateInput;
  /** Actions for the "MetadataAssetState.metadata" relation */
  metadata: MetadataAssetStateNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAssetStateWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataAssetState.archive" relation */
  archive?: InputMaybe<MetadataAssetStateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAssetState.metadata" relation */
  metadata?: InputMaybe<MetadataAssetStateNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataAttributionName" resource's node */
export type MetadataAttributionName = {
  __typename?: 'MetadataAttributionName';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataAttributionNameCreateInput = {
  /** Actions for the "MetadataAttributionName.archive" relation */
  archive: MetadataAttributionNameNestedArchiveCreateInput;
  /** Actions for the "MetadataAttributionName.metadata" relation */
  metadata: MetadataAttributionNameNestedMetadataCreateInput;
  /** Actions for the "MetadataAttributionName.photo" relation */
  photo: MetadataAttributionNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionNameNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataAttributionName" node, through the "MetadataAttributionName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataAttributionName" node, through the "MetadataAttributionName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataAttributionName" node, through the "MetadataAttributionName.archive" relation. */
  update?: InputMaybe<MetadataAttributionNameNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataAttributionName" node, through the "MetadataAttributionName.archive" relation. */
  upsert?: InputMaybe<MetadataAttributionNameNestedUpsertArchiveCreateInput>;
};

export type MetadataAttributionNameNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.archive" relation. */
  update?: InputMaybe<MetadataAttributionNameNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.archive" relation. */
  upsert?: InputMaybe<MetadataAttributionNameNestedUpsertArchiveUpdateInput>;
};

export type MetadataAttributionNameNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataAttributionName" node, through the "MetadataAttributionName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataAttributionName" node, through the "MetadataAttributionName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataAttributionName" node, through the "MetadataAttributionName.metadata" relation. */
  update?: InputMaybe<MetadataAttributionNameNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataAttributionName" node, through the "MetadataAttributionName.metadata" relation. */
  upsert?: InputMaybe<MetadataAttributionNameNestedUpsertMetadataCreateInput>;
};

export type MetadataAttributionNameNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.metadata" relation. */
  update?: InputMaybe<MetadataAttributionNameNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.metadata" relation. */
  upsert?: InputMaybe<MetadataAttributionNameNestedUpsertMetadataUpdateInput>;
};

export type MetadataAttributionNameNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataAttributionName" node, through the "MetadataAttributionName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataAttributionName" node, through the "MetadataAttributionName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataAttributionName" node, through the "MetadataAttributionName.photo" relation. */
  update?: InputMaybe<MetadataAttributionNameNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataAttributionName" node, through the "MetadataAttributionName.photo" relation. */
  upsert?: InputMaybe<MetadataAttributionNameNestedUpsertPhotoCreateInput>;
};

export type MetadataAttributionNameNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.photo" relation. */
  update?: InputMaybe<MetadataAttributionNameNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataAttributionName" node, through the "MetadataAttributionName.photo" relation. */
  upsert?: InputMaybe<MetadataAttributionNameNestedUpsertPhotoUpdateInput>;
};

export type MetadataAttributionNameNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAttributionNameNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAttributionNameNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAttributionNameNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAttributionNameNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAttributionNameNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAttributionNameNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAttributionNameNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAttributionNameNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAttributionNameNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAttributionNameNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAttributionNameNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataAttributionNameOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataAttributionNameUpdateInput = {
  /** Actions for the "MetadataAttributionName.archive" relation */
  archive?: InputMaybe<MetadataAttributionNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAttributionName.metadata" relation */
  metadata?: InputMaybe<MetadataAttributionNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAttributionName.photo" relation */
  photo?: InputMaybe<MetadataAttributionNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataAttributionName" nodes by specifying some conditions */
export type MetadataAttributionNameWhereInput = {
  AND?: InputMaybe<Array<MetadataAttributionNameWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataAttributionNameWhereInput>;
  OR?: InputMaybe<Array<MetadataAttributionNameWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataAttributionName" node. */
export type MetadataAttributionNameWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataAttributionNameWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataAttributionName.metadata" relation */
  metadata: MetadataAttributionNameNestedMetadataCreateInput;
  /** Actions for the "MetadataAttributionName.photo" relation */
  photo: MetadataAttributionNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionNameWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataAttributionName.metadata" relation */
  metadata?: InputMaybe<MetadataAttributionNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAttributionName.photo" relation */
  photo?: InputMaybe<MetadataAttributionNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionNameWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataAttributionName.archive" relation */
  archive: MetadataAttributionNameNestedArchiveCreateInput;
  /** Actions for the "MetadataAttributionName.photo" relation */
  photo: MetadataAttributionNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionNameWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataAttributionName.archive" relation */
  archive?: InputMaybe<MetadataAttributionNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAttributionName.photo" relation */
  photo?: InputMaybe<MetadataAttributionNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionNameWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataAttributionName.archive" relation */
  archive: MetadataAttributionNameNestedArchiveCreateInput;
  /** Actions for the "MetadataAttributionName.metadata" relation */
  metadata: MetadataAttributionNameNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionNameWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataAttributionName.archive" relation */
  archive?: InputMaybe<MetadataAttributionNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAttributionName.metadata" relation */
  metadata?: InputMaybe<MetadataAttributionNameNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataAttributionUrl" resource's node */
export type MetadataAttributionUrl = {
  __typename?: 'MetadataAttributionUrl';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataAttributionUrlCreateInput = {
  /** Actions for the "MetadataAttributionUrl.archive" relation */
  archive: MetadataAttributionUrlNestedArchiveCreateInput;
  /** Actions for the "MetadataAttributionUrl.metadata" relation */
  metadata: MetadataAttributionUrlNestedMetadataCreateInput;
  /** Actions for the "MetadataAttributionUrl.photo" relation */
  photo: MetadataAttributionUrlNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionUrlNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.archive" relation. */
  update?: InputMaybe<MetadataAttributionUrlNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.archive" relation. */
  upsert?: InputMaybe<MetadataAttributionUrlNestedUpsertArchiveCreateInput>;
};

export type MetadataAttributionUrlNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.archive" relation. */
  update?: InputMaybe<MetadataAttributionUrlNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.archive" relation. */
  upsert?: InputMaybe<MetadataAttributionUrlNestedUpsertArchiveUpdateInput>;
};

export type MetadataAttributionUrlNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.metadata" relation. */
  update?: InputMaybe<MetadataAttributionUrlNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.metadata" relation. */
  upsert?: InputMaybe<MetadataAttributionUrlNestedUpsertMetadataCreateInput>;
};

export type MetadataAttributionUrlNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.metadata" relation. */
  update?: InputMaybe<MetadataAttributionUrlNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.metadata" relation. */
  upsert?: InputMaybe<MetadataAttributionUrlNestedUpsertMetadataUpdateInput>;
};

export type MetadataAttributionUrlNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.photo" relation. */
  update?: InputMaybe<MetadataAttributionUrlNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.photo" relation. */
  upsert?: InputMaybe<MetadataAttributionUrlNestedUpsertPhotoCreateInput>;
};

export type MetadataAttributionUrlNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.photo" relation. */
  update?: InputMaybe<MetadataAttributionUrlNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataAttributionUrl" node, through the "MetadataAttributionUrl.photo" relation. */
  upsert?: InputMaybe<MetadataAttributionUrlNestedUpsertPhotoUpdateInput>;
};

export type MetadataAttributionUrlNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAttributionUrlNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAttributionUrlNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAttributionUrlNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAttributionUrlNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAttributionUrlNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAttributionUrlNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAttributionUrlNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAttributionUrlNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAttributionUrlNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAttributionUrlNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAttributionUrlNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataAttributionUrlOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataAttributionUrlUpdateInput = {
  /** Actions for the "MetadataAttributionUrl.archive" relation */
  archive?: InputMaybe<MetadataAttributionUrlNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAttributionUrl.metadata" relation */
  metadata?: InputMaybe<MetadataAttributionUrlNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAttributionUrl.photo" relation */
  photo?: InputMaybe<MetadataAttributionUrlNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataAttributionUrl" nodes by specifying some conditions */
export type MetadataAttributionUrlWhereInput = {
  AND?: InputMaybe<Array<MetadataAttributionUrlWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataAttributionUrlWhereInput>;
  OR?: InputMaybe<Array<MetadataAttributionUrlWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataAttributionUrl" node. */
export type MetadataAttributionUrlWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataAttributionUrlWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataAttributionUrl.metadata" relation */
  metadata: MetadataAttributionUrlNestedMetadataCreateInput;
  /** Actions for the "MetadataAttributionUrl.photo" relation */
  photo: MetadataAttributionUrlNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionUrlWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataAttributionUrl.metadata" relation */
  metadata?: InputMaybe<MetadataAttributionUrlNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAttributionUrl.photo" relation */
  photo?: InputMaybe<MetadataAttributionUrlNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionUrlWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataAttributionUrl.archive" relation */
  archive: MetadataAttributionUrlNestedArchiveCreateInput;
  /** Actions for the "MetadataAttributionUrl.photo" relation */
  photo: MetadataAttributionUrlNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionUrlWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataAttributionUrl.archive" relation */
  archive?: InputMaybe<MetadataAttributionUrlNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAttributionUrl.photo" relation */
  photo?: InputMaybe<MetadataAttributionUrlNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionUrlWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataAttributionUrl.archive" relation */
  archive: MetadataAttributionUrlNestedArchiveCreateInput;
  /** Actions for the "MetadataAttributionUrl.metadata" relation */
  metadata: MetadataAttributionUrlNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAttributionUrlWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataAttributionUrl.archive" relation */
  archive?: InputMaybe<MetadataAttributionUrlNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAttributionUrl.metadata" relation */
  metadata?: InputMaybe<MetadataAttributionUrlNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataAudience" resource's node */
export type MetadataAudience = {
  __typename?: 'MetadataAudience';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataAudienceCreateInput = {
  /** Actions for the "MetadataAudience.archive" relation */
  archive: MetadataAudienceNestedArchiveCreateInput;
  /** Actions for the "MetadataAudience.metadata" relation */
  metadata: MetadataAudienceNestedMetadataCreateInput;
  /** Actions for the "MetadataAudience.photo" relation */
  photo: MetadataAudienceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAudienceNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataAudience" node, through the "MetadataAudience.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataAudience" node, through the "MetadataAudience.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataAudience" node, through the "MetadataAudience.archive" relation. */
  update?: InputMaybe<MetadataAudienceNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataAudience" node, through the "MetadataAudience.archive" relation. */
  upsert?: InputMaybe<MetadataAudienceNestedUpsertArchiveCreateInput>;
};

export type MetadataAudienceNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataAudience" node, through the "MetadataAudience.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataAudience" node, through the "MetadataAudience.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataAudience" node, through the "MetadataAudience.archive" relation. */
  update?: InputMaybe<MetadataAudienceNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataAudience" node, through the "MetadataAudience.archive" relation. */
  upsert?: InputMaybe<MetadataAudienceNestedUpsertArchiveUpdateInput>;
};

export type MetadataAudienceNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataAudience" node, through the "MetadataAudience.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataAudience" node, through the "MetadataAudience.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataAudience" node, through the "MetadataAudience.metadata" relation. */
  update?: InputMaybe<MetadataAudienceNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataAudience" node, through the "MetadataAudience.metadata" relation. */
  upsert?: InputMaybe<MetadataAudienceNestedUpsertMetadataCreateInput>;
};

export type MetadataAudienceNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataAudience" node, through the "MetadataAudience.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataAudience" node, through the "MetadataAudience.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataAudience" node, through the "MetadataAudience.metadata" relation. */
  update?: InputMaybe<MetadataAudienceNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataAudience" node, through the "MetadataAudience.metadata" relation. */
  upsert?: InputMaybe<MetadataAudienceNestedUpsertMetadataUpdateInput>;
};

export type MetadataAudienceNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataAudience" node, through the "MetadataAudience.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataAudience" node, through the "MetadataAudience.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataAudience" node, through the "MetadataAudience.photo" relation. */
  update?: InputMaybe<MetadataAudienceNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataAudience" node, through the "MetadataAudience.photo" relation. */
  upsert?: InputMaybe<MetadataAudienceNestedUpsertPhotoCreateInput>;
};

export type MetadataAudienceNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataAudience" node, through the "MetadataAudience.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataAudience" node, through the "MetadataAudience.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataAudience" node, through the "MetadataAudience.photo" relation. */
  update?: InputMaybe<MetadataAudienceNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataAudience" node, through the "MetadataAudience.photo" relation. */
  upsert?: InputMaybe<MetadataAudienceNestedUpsertPhotoUpdateInput>;
};

export type MetadataAudienceNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAudienceNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAudienceNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAudienceNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAudienceNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAudienceNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAudienceNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAudienceNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAudienceNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAudienceNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAudienceNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAudienceNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataAudienceOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataAudienceUpdateInput = {
  /** Actions for the "MetadataAudience.archive" relation */
  archive?: InputMaybe<MetadataAudienceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAudience.metadata" relation */
  metadata?: InputMaybe<MetadataAudienceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAudience.photo" relation */
  photo?: InputMaybe<MetadataAudienceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataAudience" nodes by specifying some conditions */
export type MetadataAudienceWhereInput = {
  AND?: InputMaybe<Array<MetadataAudienceWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataAudienceWhereInput>;
  OR?: InputMaybe<Array<MetadataAudienceWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataAudience" node. */
export type MetadataAudienceWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataAudienceWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataAudience.metadata" relation */
  metadata: MetadataAudienceNestedMetadataCreateInput;
  /** Actions for the "MetadataAudience.photo" relation */
  photo: MetadataAudienceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAudienceWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataAudience.metadata" relation */
  metadata?: InputMaybe<MetadataAudienceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAudience.photo" relation */
  photo?: InputMaybe<MetadataAudienceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAudienceWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataAudience.archive" relation */
  archive: MetadataAudienceNestedArchiveCreateInput;
  /** Actions for the "MetadataAudience.photo" relation */
  photo: MetadataAudienceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAudienceWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataAudience.archive" relation */
  archive?: InputMaybe<MetadataAudienceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAudience.photo" relation */
  photo?: InputMaybe<MetadataAudienceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAudienceWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataAudience.archive" relation */
  archive: MetadataAudienceNestedArchiveCreateInput;
  /** Actions for the "MetadataAudience.metadata" relation */
  metadata: MetadataAudienceNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAudienceWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataAudience.archive" relation */
  archive?: InputMaybe<MetadataAudienceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAudience.metadata" relation */
  metadata?: InputMaybe<MetadataAudienceNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataAuthorsPosition" resource's node */
export type MetadataAuthorsPosition = {
  __typename?: 'MetadataAuthorsPosition';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataAuthorsPositionCreateInput = {
  /** Actions for the "MetadataAuthorsPosition.archive" relation */
  archive: MetadataAuthorsPositionNestedArchiveCreateInput;
  /** Actions for the "MetadataAuthorsPosition.metadata" relation */
  metadata: MetadataAuthorsPositionNestedMetadataCreateInput;
  /** Actions for the "MetadataAuthorsPosition.photo" relation */
  photo: MetadataAuthorsPositionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAuthorsPositionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.archive" relation. */
  update?: InputMaybe<MetadataAuthorsPositionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.archive" relation. */
  upsert?: InputMaybe<MetadataAuthorsPositionNestedUpsertArchiveCreateInput>;
};

export type MetadataAuthorsPositionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.archive" relation. */
  update?: InputMaybe<MetadataAuthorsPositionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.archive" relation. */
  upsert?: InputMaybe<MetadataAuthorsPositionNestedUpsertArchiveUpdateInput>;
};

export type MetadataAuthorsPositionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.metadata" relation. */
  update?: InputMaybe<MetadataAuthorsPositionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.metadata" relation. */
  upsert?: InputMaybe<MetadataAuthorsPositionNestedUpsertMetadataCreateInput>;
};

export type MetadataAuthorsPositionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.metadata" relation. */
  update?: InputMaybe<MetadataAuthorsPositionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.metadata" relation. */
  upsert?: InputMaybe<MetadataAuthorsPositionNestedUpsertMetadataUpdateInput>;
};

export type MetadataAuthorsPositionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.photo" relation. */
  update?: InputMaybe<MetadataAuthorsPositionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.photo" relation. */
  upsert?: InputMaybe<MetadataAuthorsPositionNestedUpsertPhotoCreateInput>;
};

export type MetadataAuthorsPositionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.photo" relation. */
  update?: InputMaybe<MetadataAuthorsPositionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataAuthorsPosition" node, through the "MetadataAuthorsPosition.photo" relation. */
  upsert?: InputMaybe<MetadataAuthorsPositionNestedUpsertPhotoUpdateInput>;
};

export type MetadataAuthorsPositionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAuthorsPositionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAuthorsPositionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAuthorsPositionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAuthorsPositionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAuthorsPositionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAuthorsPositionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAuthorsPositionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataAuthorsPositionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAuthorsPositionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataAuthorsPositionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataAuthorsPositionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataAuthorsPositionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataAuthorsPositionUpdateInput = {
  /** Actions for the "MetadataAuthorsPosition.archive" relation */
  archive?: InputMaybe<MetadataAuthorsPositionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAuthorsPosition.metadata" relation */
  metadata?: InputMaybe<MetadataAuthorsPositionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAuthorsPosition.photo" relation */
  photo?: InputMaybe<MetadataAuthorsPositionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataAuthorsPosition" nodes by specifying some conditions */
export type MetadataAuthorsPositionWhereInput = {
  AND?: InputMaybe<Array<MetadataAuthorsPositionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataAuthorsPositionWhereInput>;
  OR?: InputMaybe<Array<MetadataAuthorsPositionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataAuthorsPosition" node. */
export type MetadataAuthorsPositionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataAuthorsPositionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataAuthorsPosition.metadata" relation */
  metadata: MetadataAuthorsPositionNestedMetadataCreateInput;
  /** Actions for the "MetadataAuthorsPosition.photo" relation */
  photo: MetadataAuthorsPositionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAuthorsPositionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataAuthorsPosition.metadata" relation */
  metadata?: InputMaybe<MetadataAuthorsPositionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataAuthorsPosition.photo" relation */
  photo?: InputMaybe<MetadataAuthorsPositionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAuthorsPositionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataAuthorsPosition.archive" relation */
  archive: MetadataAuthorsPositionNestedArchiveCreateInput;
  /** Actions for the "MetadataAuthorsPosition.photo" relation */
  photo: MetadataAuthorsPositionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAuthorsPositionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataAuthorsPosition.archive" relation */
  archive?: InputMaybe<MetadataAuthorsPositionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAuthorsPosition.photo" relation */
  photo?: InputMaybe<MetadataAuthorsPositionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAuthorsPositionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataAuthorsPosition.archive" relation */
  archive: MetadataAuthorsPositionNestedArchiveCreateInput;
  /** Actions for the "MetadataAuthorsPosition.metadata" relation */
  metadata: MetadataAuthorsPositionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataAuthorsPositionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataAuthorsPosition.archive" relation */
  archive?: InputMaybe<MetadataAuthorsPositionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataAuthorsPosition.metadata" relation */
  metadata?: InputMaybe<MetadataAuthorsPositionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataBackupName" resource's node */
export type MetadataBackupName = {
  __typename?: 'MetadataBackupName';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataBackupNameCreateInput = {
  /** Actions for the "MetadataBackupName.archive" relation */
  archive: MetadataBackupNameNestedArchiveCreateInput;
  /** Actions for the "MetadataBackupName.metadata" relation */
  metadata: MetadataBackupNameNestedMetadataCreateInput;
  /** Actions for the "MetadataBackupName.photo" relation */
  photo: MetadataBackupNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBackupNameNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataBackupName" node, through the "MetadataBackupName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataBackupName" node, through the "MetadataBackupName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataBackupName" node, through the "MetadataBackupName.archive" relation. */
  update?: InputMaybe<MetadataBackupNameNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataBackupName" node, through the "MetadataBackupName.archive" relation. */
  upsert?: InputMaybe<MetadataBackupNameNestedUpsertArchiveCreateInput>;
};

export type MetadataBackupNameNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataBackupName" node, through the "MetadataBackupName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataBackupName" node, through the "MetadataBackupName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataBackupName" node, through the "MetadataBackupName.archive" relation. */
  update?: InputMaybe<MetadataBackupNameNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataBackupName" node, through the "MetadataBackupName.archive" relation. */
  upsert?: InputMaybe<MetadataBackupNameNestedUpsertArchiveUpdateInput>;
};

export type MetadataBackupNameNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataBackupName" node, through the "MetadataBackupName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataBackupName" node, through the "MetadataBackupName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataBackupName" node, through the "MetadataBackupName.metadata" relation. */
  update?: InputMaybe<MetadataBackupNameNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataBackupName" node, through the "MetadataBackupName.metadata" relation. */
  upsert?: InputMaybe<MetadataBackupNameNestedUpsertMetadataCreateInput>;
};

export type MetadataBackupNameNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataBackupName" node, through the "MetadataBackupName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataBackupName" node, through the "MetadataBackupName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataBackupName" node, through the "MetadataBackupName.metadata" relation. */
  update?: InputMaybe<MetadataBackupNameNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataBackupName" node, through the "MetadataBackupName.metadata" relation. */
  upsert?: InputMaybe<MetadataBackupNameNestedUpsertMetadataUpdateInput>;
};

export type MetadataBackupNameNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataBackupName" node, through the "MetadataBackupName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataBackupName" node, through the "MetadataBackupName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataBackupName" node, through the "MetadataBackupName.photo" relation. */
  update?: InputMaybe<MetadataBackupNameNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataBackupName" node, through the "MetadataBackupName.photo" relation. */
  upsert?: InputMaybe<MetadataBackupNameNestedUpsertPhotoCreateInput>;
};

export type MetadataBackupNameNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataBackupName" node, through the "MetadataBackupName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataBackupName" node, through the "MetadataBackupName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataBackupName" node, through the "MetadataBackupName.photo" relation. */
  update?: InputMaybe<MetadataBackupNameNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataBackupName" node, through the "MetadataBackupName.photo" relation. */
  upsert?: InputMaybe<MetadataBackupNameNestedUpsertPhotoUpdateInput>;
};

export type MetadataBackupNameNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBackupNameNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBackupNameNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBackupNameNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBackupNameNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBackupNameNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBackupNameNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBackupNameNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBackupNameNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBackupNameNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBackupNameNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBackupNameNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataBackupNameOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataBackupNameUpdateInput = {
  /** Actions for the "MetadataBackupName.archive" relation */
  archive?: InputMaybe<MetadataBackupNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBackupName.metadata" relation */
  metadata?: InputMaybe<MetadataBackupNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataBackupName.photo" relation */
  photo?: InputMaybe<MetadataBackupNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataBackupName" nodes by specifying some conditions */
export type MetadataBackupNameWhereInput = {
  AND?: InputMaybe<Array<MetadataBackupNameWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataBackupNameWhereInput>;
  OR?: InputMaybe<Array<MetadataBackupNameWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataBackupName" node. */
export type MetadataBackupNameWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataBackupNameWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataBackupName.metadata" relation */
  metadata: MetadataBackupNameNestedMetadataCreateInput;
  /** Actions for the "MetadataBackupName.photo" relation */
  photo: MetadataBackupNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBackupNameWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataBackupName.metadata" relation */
  metadata?: InputMaybe<MetadataBackupNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataBackupName.photo" relation */
  photo?: InputMaybe<MetadataBackupNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBackupNameWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataBackupName.archive" relation */
  archive: MetadataBackupNameNestedArchiveCreateInput;
  /** Actions for the "MetadataBackupName.photo" relation */
  photo: MetadataBackupNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBackupNameWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataBackupName.archive" relation */
  archive?: InputMaybe<MetadataBackupNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBackupName.photo" relation */
  photo?: InputMaybe<MetadataBackupNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBackupNameWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataBackupName.archive" relation */
  archive: MetadataBackupNameNestedArchiveCreateInput;
  /** Actions for the "MetadataBackupName.metadata" relation */
  metadata: MetadataBackupNameNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBackupNameWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataBackupName.archive" relation */
  archive?: InputMaybe<MetadataBackupNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBackupName.metadata" relation */
  metadata?: InputMaybe<MetadataBackupNameNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataBitmapGrayscalePicture" resource's node */
export type MetadataBitmapGrayscalePicture = {
  __typename?: 'MetadataBitmapGrayscalePicture';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataBitmapGrayscalePictureCreateInput = {
  /** Actions for the "MetadataBitmapGrayscalePicture.archive" relation */
  archive: MetadataBitmapGrayscalePictureNestedArchiveCreateInput;
  /** Actions for the "MetadataBitmapGrayscalePicture.metadata" relation */
  metadata: MetadataBitmapGrayscalePictureNestedMetadataCreateInput;
  /** Actions for the "MetadataBitmapGrayscalePicture.photo" relation */
  photo: MetadataBitmapGrayscalePictureNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBitmapGrayscalePictureNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.archive" relation. */
  update?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.archive" relation. */
  upsert?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpsertArchiveCreateInput>;
};

export type MetadataBitmapGrayscalePictureNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.archive" relation. */
  update?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.archive" relation. */
  upsert?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpsertArchiveUpdateInput>;
};

export type MetadataBitmapGrayscalePictureNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.metadata" relation. */
  update?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.metadata" relation. */
  upsert?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpsertMetadataCreateInput>;
};

export type MetadataBitmapGrayscalePictureNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.metadata" relation. */
  update?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.metadata" relation. */
  upsert?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpsertMetadataUpdateInput>;
};

export type MetadataBitmapGrayscalePictureNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.photo" relation. */
  update?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.photo" relation. */
  upsert?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpsertPhotoCreateInput>;
};

export type MetadataBitmapGrayscalePictureNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.photo" relation. */
  update?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataBitmapGrayscalePicture" node, through the "MetadataBitmapGrayscalePicture.photo" relation. */
  upsert?: InputMaybe<MetadataBitmapGrayscalePictureNestedUpsertPhotoUpdateInput>;
};

export type MetadataBitmapGrayscalePictureNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBitmapGrayscalePictureNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBitmapGrayscalePictureNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBitmapGrayscalePictureNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBitmapGrayscalePictureNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBitmapGrayscalePictureNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBitmapGrayscalePictureNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBitmapGrayscalePictureNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBitmapGrayscalePictureNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBitmapGrayscalePictureNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBitmapGrayscalePictureNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBitmapGrayscalePictureNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataBitmapGrayscalePictureOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataBitmapGrayscalePictureUpdateInput = {
  /** Actions for the "MetadataBitmapGrayscalePicture.archive" relation */
  archive?: InputMaybe<MetadataBitmapGrayscalePictureNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBitmapGrayscalePicture.metadata" relation */
  metadata?: InputMaybe<MetadataBitmapGrayscalePictureNestedMetadataUpdateInput>;
  /** Actions for the "MetadataBitmapGrayscalePicture.photo" relation */
  photo?: InputMaybe<MetadataBitmapGrayscalePictureNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataBitmapGrayscalePicture" nodes by specifying some conditions */
export type MetadataBitmapGrayscalePictureWhereInput = {
  AND?: InputMaybe<Array<MetadataBitmapGrayscalePictureWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
  OR?: InputMaybe<Array<MetadataBitmapGrayscalePictureWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataBitmapGrayscalePicture" node. */
export type MetadataBitmapGrayscalePictureWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataBitmapGrayscalePictureWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataBitmapGrayscalePicture.metadata" relation */
  metadata: MetadataBitmapGrayscalePictureNestedMetadataCreateInput;
  /** Actions for the "MetadataBitmapGrayscalePicture.photo" relation */
  photo: MetadataBitmapGrayscalePictureNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBitmapGrayscalePictureWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataBitmapGrayscalePicture.metadata" relation */
  metadata?: InputMaybe<MetadataBitmapGrayscalePictureNestedMetadataUpdateInput>;
  /** Actions for the "MetadataBitmapGrayscalePicture.photo" relation */
  photo?: InputMaybe<MetadataBitmapGrayscalePictureNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBitmapGrayscalePictureWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataBitmapGrayscalePicture.archive" relation */
  archive: MetadataBitmapGrayscalePictureNestedArchiveCreateInput;
  /** Actions for the "MetadataBitmapGrayscalePicture.photo" relation */
  photo: MetadataBitmapGrayscalePictureNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBitmapGrayscalePictureWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataBitmapGrayscalePicture.archive" relation */
  archive?: InputMaybe<MetadataBitmapGrayscalePictureNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBitmapGrayscalePicture.photo" relation */
  photo?: InputMaybe<MetadataBitmapGrayscalePictureNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBitmapGrayscalePictureWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataBitmapGrayscalePicture.archive" relation */
  archive: MetadataBitmapGrayscalePictureNestedArchiveCreateInput;
  /** Actions for the "MetadataBitmapGrayscalePicture.metadata" relation */
  metadata: MetadataBitmapGrayscalePictureNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBitmapGrayscalePictureWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataBitmapGrayscalePicture.archive" relation */
  archive?: InputMaybe<MetadataBitmapGrayscalePictureNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBitmapGrayscalePicture.metadata" relation */
  metadata?: InputMaybe<MetadataBitmapGrayscalePictureNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataBrand" resource's node */
export type MetadataBrand = {
  __typename?: 'MetadataBrand';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataBrandCreateInput = {
  /** Actions for the "MetadataBrand.archive" relation */
  archive: MetadataBrandNestedArchiveCreateInput;
  /** Actions for the "MetadataBrand.metadata" relation */
  metadata: MetadataBrandNestedMetadataCreateInput;
  /** Actions for the "MetadataBrand.photo" relation */
  photo: MetadataBrandNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataBrand" node, through the "MetadataBrand.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataBrand" node, through the "MetadataBrand.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataBrand" node, through the "MetadataBrand.archive" relation. */
  update?: InputMaybe<MetadataBrandNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataBrand" node, through the "MetadataBrand.archive" relation. */
  upsert?: InputMaybe<MetadataBrandNestedUpsertArchiveCreateInput>;
};

export type MetadataBrandNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataBrand" node, through the "MetadataBrand.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataBrand" node, through the "MetadataBrand.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataBrand" node, through the "MetadataBrand.archive" relation. */
  update?: InputMaybe<MetadataBrandNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataBrand" node, through the "MetadataBrand.archive" relation. */
  upsert?: InputMaybe<MetadataBrandNestedUpsertArchiveUpdateInput>;
};

export type MetadataBrandNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataBrand" node, through the "MetadataBrand.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataBrand" node, through the "MetadataBrand.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataBrand" node, through the "MetadataBrand.metadata" relation. */
  update?: InputMaybe<MetadataBrandNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataBrand" node, through the "MetadataBrand.metadata" relation. */
  upsert?: InputMaybe<MetadataBrandNestedUpsertMetadataCreateInput>;
};

export type MetadataBrandNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataBrand" node, through the "MetadataBrand.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataBrand" node, through the "MetadataBrand.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataBrand" node, through the "MetadataBrand.metadata" relation. */
  update?: InputMaybe<MetadataBrandNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataBrand" node, through the "MetadataBrand.metadata" relation. */
  upsert?: InputMaybe<MetadataBrandNestedUpsertMetadataUpdateInput>;
};

export type MetadataBrandNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataBrand" node, through the "MetadataBrand.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataBrand" node, through the "MetadataBrand.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataBrand" node, through the "MetadataBrand.photo" relation. */
  update?: InputMaybe<MetadataBrandNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataBrand" node, through the "MetadataBrand.photo" relation. */
  upsert?: InputMaybe<MetadataBrandNestedUpsertPhotoCreateInput>;
};

export type MetadataBrandNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataBrand" node, through the "MetadataBrand.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataBrand" node, through the "MetadataBrand.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataBrand" node, through the "MetadataBrand.photo" relation. */
  update?: InputMaybe<MetadataBrandNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataBrand" node, through the "MetadataBrand.photo" relation. */
  upsert?: InputMaybe<MetadataBrandNestedUpsertPhotoUpdateInput>;
};

export type MetadataBrandNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBrandNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBrandNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBrandNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataBrandOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

/** "MetadataBrandPrisma" resource's node */
export type MetadataBrandPrisma = {
  __typename?: 'MetadataBrandPrisma';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataBrandPrismaCreateInput = {
  /** Actions for the "MetadataBrandPrisma.archive" relation */
  archive: MetadataBrandPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataBrandPrisma.metadata" relation */
  metadata: MetadataBrandPrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataBrandPrisma.photo" relation */
  photo: MetadataBrandPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandPrismaNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.archive" relation. */
  update?: InputMaybe<MetadataBrandPrismaNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.archive" relation. */
  upsert?: InputMaybe<MetadataBrandPrismaNestedUpsertArchiveCreateInput>;
};

export type MetadataBrandPrismaNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.archive" relation. */
  update?: InputMaybe<MetadataBrandPrismaNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.archive" relation. */
  upsert?: InputMaybe<MetadataBrandPrismaNestedUpsertArchiveUpdateInput>;
};

export type MetadataBrandPrismaNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.metadata" relation. */
  update?: InputMaybe<MetadataBrandPrismaNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataBrandPrismaNestedUpsertMetadataCreateInput>;
};

export type MetadataBrandPrismaNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.metadata" relation. */
  update?: InputMaybe<MetadataBrandPrismaNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataBrandPrismaNestedUpsertMetadataUpdateInput>;
};

export type MetadataBrandPrismaNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.photo" relation. */
  update?: InputMaybe<MetadataBrandPrismaNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.photo" relation. */
  upsert?: InputMaybe<MetadataBrandPrismaNestedUpsertPhotoCreateInput>;
};

export type MetadataBrandPrismaNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.photo" relation. */
  update?: InputMaybe<MetadataBrandPrismaNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataBrandPrisma" node, through the "MetadataBrandPrisma.photo" relation. */
  upsert?: InputMaybe<MetadataBrandPrismaNestedUpsertPhotoUpdateInput>;
};

export type MetadataBrandPrismaNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandPrismaNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandPrismaNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandPrismaNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandPrismaNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBrandPrismaNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBrandPrismaNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandPrismaNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandPrismaNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandPrismaNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandPrismaNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBrandPrismaNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataBrandPrismaOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataBrandPrismaUpdateInput = {
  /** Actions for the "MetadataBrandPrisma.archive" relation */
  archive?: InputMaybe<MetadataBrandPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBrandPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataBrandPrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataBrandPrisma.photo" relation */
  photo?: InputMaybe<MetadataBrandPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataBrandPrisma" nodes by specifying some conditions */
export type MetadataBrandPrismaWhereInput = {
  AND?: InputMaybe<Array<MetadataBrandPrismaWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataBrandPrismaWhereInput>;
  OR?: InputMaybe<Array<MetadataBrandPrismaWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataBrandPrisma" node. */
export type MetadataBrandPrismaWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataBrandPrismaWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataBrandPrisma.metadata" relation */
  metadata: MetadataBrandPrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataBrandPrisma.photo" relation */
  photo: MetadataBrandPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandPrismaWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataBrandPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataBrandPrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataBrandPrisma.photo" relation */
  photo?: InputMaybe<MetadataBrandPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandPrismaWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataBrandPrisma.archive" relation */
  archive: MetadataBrandPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataBrandPrisma.photo" relation */
  photo: MetadataBrandPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandPrismaWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataBrandPrisma.archive" relation */
  archive?: InputMaybe<MetadataBrandPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBrandPrisma.photo" relation */
  photo?: InputMaybe<MetadataBrandPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandPrismaWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataBrandPrisma.archive" relation */
  archive: MetadataBrandPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataBrandPrisma.metadata" relation */
  metadata: MetadataBrandPrismaNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandPrismaWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataBrandPrisma.archive" relation */
  archive?: InputMaybe<MetadataBrandPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBrandPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataBrandPrismaNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataBrandShortName" resource's node */
export type MetadataBrandShortName = {
  __typename?: 'MetadataBrandShortName';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataBrandShortNameCreateInput = {
  /** Actions for the "MetadataBrandShortName.archive" relation */
  archive: MetadataBrandShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataBrandShortName.metadata" relation */
  metadata: MetadataBrandShortNameNestedMetadataCreateInput;
  /** Actions for the "MetadataBrandShortName.photo" relation */
  photo: MetadataBrandShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandShortNameNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.archive" relation. */
  update?: InputMaybe<MetadataBrandShortNameNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.archive" relation. */
  upsert?: InputMaybe<MetadataBrandShortNameNestedUpsertArchiveCreateInput>;
};

export type MetadataBrandShortNameNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.archive" relation. */
  update?: InputMaybe<MetadataBrandShortNameNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.archive" relation. */
  upsert?: InputMaybe<MetadataBrandShortNameNestedUpsertArchiveUpdateInput>;
};

export type MetadataBrandShortNameNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.metadata" relation. */
  update?: InputMaybe<MetadataBrandShortNameNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.metadata" relation. */
  upsert?: InputMaybe<MetadataBrandShortNameNestedUpsertMetadataCreateInput>;
};

export type MetadataBrandShortNameNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.metadata" relation. */
  update?: InputMaybe<MetadataBrandShortNameNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.metadata" relation. */
  upsert?: InputMaybe<MetadataBrandShortNameNestedUpsertMetadataUpdateInput>;
};

export type MetadataBrandShortNameNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.photo" relation. */
  update?: InputMaybe<MetadataBrandShortNameNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataBrandShortName" node, through the "MetadataBrandShortName.photo" relation. */
  upsert?: InputMaybe<MetadataBrandShortNameNestedUpsertPhotoCreateInput>;
};

export type MetadataBrandShortNameNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.photo" relation. */
  update?: InputMaybe<MetadataBrandShortNameNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataBrandShortName" node, through the "MetadataBrandShortName.photo" relation. */
  upsert?: InputMaybe<MetadataBrandShortNameNestedUpsertPhotoUpdateInput>;
};

export type MetadataBrandShortNameNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandShortNameNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandShortNameNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandShortNameNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandShortNameNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBrandShortNameNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBrandShortNameNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandShortNameNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataBrandShortNameNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandShortNameNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataBrandShortNameNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataBrandShortNameNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataBrandShortNameOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataBrandShortNameUpdateInput = {
  /** Actions for the "MetadataBrandShortName.archive" relation */
  archive?: InputMaybe<MetadataBrandShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBrandShortName.metadata" relation */
  metadata?: InputMaybe<MetadataBrandShortNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataBrandShortName.photo" relation */
  photo?: InputMaybe<MetadataBrandShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataBrandShortName" nodes by specifying some conditions */
export type MetadataBrandShortNameWhereInput = {
  AND?: InputMaybe<Array<MetadataBrandShortNameWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataBrandShortNameWhereInput>;
  OR?: InputMaybe<Array<MetadataBrandShortNameWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataBrandShortName" node. */
export type MetadataBrandShortNameWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataBrandShortNameWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataBrandShortName.metadata" relation */
  metadata: MetadataBrandShortNameNestedMetadataCreateInput;
  /** Actions for the "MetadataBrandShortName.photo" relation */
  photo: MetadataBrandShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandShortNameWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataBrandShortName.metadata" relation */
  metadata?: InputMaybe<MetadataBrandShortNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataBrandShortName.photo" relation */
  photo?: InputMaybe<MetadataBrandShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandShortNameWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataBrandShortName.archive" relation */
  archive: MetadataBrandShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataBrandShortName.photo" relation */
  photo: MetadataBrandShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandShortNameWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataBrandShortName.archive" relation */
  archive?: InputMaybe<MetadataBrandShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBrandShortName.photo" relation */
  photo?: InputMaybe<MetadataBrandShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandShortNameWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataBrandShortName.archive" relation */
  archive: MetadataBrandShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataBrandShortName.metadata" relation */
  metadata: MetadataBrandShortNameNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandShortNameWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataBrandShortName.archive" relation */
  archive?: InputMaybe<MetadataBrandShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBrandShortName.metadata" relation */
  metadata?: InputMaybe<MetadataBrandShortNameNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandUpdateInput = {
  /** Actions for the "MetadataBrand.archive" relation */
  archive?: InputMaybe<MetadataBrandNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBrand.metadata" relation */
  metadata?: InputMaybe<MetadataBrandNestedMetadataUpdateInput>;
  /** Actions for the "MetadataBrand.photo" relation */
  photo?: InputMaybe<MetadataBrandNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataBrand" nodes by specifying some conditions */
export type MetadataBrandWhereInput = {
  AND?: InputMaybe<Array<MetadataBrandWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataBrandWhereInput>;
  OR?: InputMaybe<Array<MetadataBrandWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataBrand" node. */
export type MetadataBrandWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataBrandWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataBrand.metadata" relation */
  metadata: MetadataBrandNestedMetadataCreateInput;
  /** Actions for the "MetadataBrand.photo" relation */
  photo: MetadataBrandNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataBrand.metadata" relation */
  metadata?: InputMaybe<MetadataBrandNestedMetadataUpdateInput>;
  /** Actions for the "MetadataBrand.photo" relation */
  photo?: InputMaybe<MetadataBrandNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataBrand.archive" relation */
  archive: MetadataBrandNestedArchiveCreateInput;
  /** Actions for the "MetadataBrand.photo" relation */
  photo: MetadataBrandNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataBrand.archive" relation */
  archive?: InputMaybe<MetadataBrandNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBrand.photo" relation */
  photo?: InputMaybe<MetadataBrandNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataBrand.archive" relation */
  archive: MetadataBrandNestedArchiveCreateInput;
  /** Actions for the "MetadataBrand.metadata" relation */
  metadata: MetadataBrandNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataBrandWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataBrand.archive" relation */
  archive?: InputMaybe<MetadataBrandNestedArchiveUpdateInput>;
  /** Actions for the "MetadataBrand.metadata" relation */
  metadata?: InputMaybe<MetadataBrandNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCaptionWriter" resource's node */
export type MetadataCaptionWriter = {
  __typename?: 'MetadataCaptionWriter';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCaptionWriterCreateInput = {
  /** Actions for the "MetadataCaptionWriter.archive" relation */
  archive: MetadataCaptionWriterNestedArchiveCreateInput;
  /** Actions for the "MetadataCaptionWriter.metadata" relation */
  metadata: MetadataCaptionWriterNestedMetadataCreateInput;
  /** Actions for the "MetadataCaptionWriter.photo" relation */
  photo: MetadataCaptionWriterNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCaptionWriterNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.archive" relation. */
  update?: InputMaybe<MetadataCaptionWriterNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.archive" relation. */
  upsert?: InputMaybe<MetadataCaptionWriterNestedUpsertArchiveCreateInput>;
};

export type MetadataCaptionWriterNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.archive" relation. */
  update?: InputMaybe<MetadataCaptionWriterNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.archive" relation. */
  upsert?: InputMaybe<MetadataCaptionWriterNestedUpsertArchiveUpdateInput>;
};

export type MetadataCaptionWriterNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.metadata" relation. */
  update?: InputMaybe<MetadataCaptionWriterNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.metadata" relation. */
  upsert?: InputMaybe<MetadataCaptionWriterNestedUpsertMetadataCreateInput>;
};

export type MetadataCaptionWriterNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.metadata" relation. */
  update?: InputMaybe<MetadataCaptionWriterNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.metadata" relation. */
  upsert?: InputMaybe<MetadataCaptionWriterNestedUpsertMetadataUpdateInput>;
};

export type MetadataCaptionWriterNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.photo" relation. */
  update?: InputMaybe<MetadataCaptionWriterNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.photo" relation. */
  upsert?: InputMaybe<MetadataCaptionWriterNestedUpsertPhotoCreateInput>;
};

export type MetadataCaptionWriterNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.photo" relation. */
  update?: InputMaybe<MetadataCaptionWriterNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCaptionWriter" node, through the "MetadataCaptionWriter.photo" relation. */
  upsert?: InputMaybe<MetadataCaptionWriterNestedUpsertPhotoUpdateInput>;
};

export type MetadataCaptionWriterNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCaptionWriterNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCaptionWriterNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCaptionWriterNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCaptionWriterNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCaptionWriterNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCaptionWriterNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCaptionWriterNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCaptionWriterNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCaptionWriterNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCaptionWriterNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCaptionWriterNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCaptionWriterOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCaptionWriterUpdateInput = {
  /** Actions for the "MetadataCaptionWriter.archive" relation */
  archive?: InputMaybe<MetadataCaptionWriterNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCaptionWriter.metadata" relation */
  metadata?: InputMaybe<MetadataCaptionWriterNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCaptionWriter.photo" relation */
  photo?: InputMaybe<MetadataCaptionWriterNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCaptionWriter" nodes by specifying some conditions */
export type MetadataCaptionWriterWhereInput = {
  AND?: InputMaybe<Array<MetadataCaptionWriterWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCaptionWriterWhereInput>;
  OR?: InputMaybe<Array<MetadataCaptionWriterWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCaptionWriter" node. */
export type MetadataCaptionWriterWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCaptionWriterWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCaptionWriter.metadata" relation */
  metadata: MetadataCaptionWriterNestedMetadataCreateInput;
  /** Actions for the "MetadataCaptionWriter.photo" relation */
  photo: MetadataCaptionWriterNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCaptionWriterWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCaptionWriter.metadata" relation */
  metadata?: InputMaybe<MetadataCaptionWriterNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCaptionWriter.photo" relation */
  photo?: InputMaybe<MetadataCaptionWriterNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCaptionWriterWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCaptionWriter.archive" relation */
  archive: MetadataCaptionWriterNestedArchiveCreateInput;
  /** Actions for the "MetadataCaptionWriter.photo" relation */
  photo: MetadataCaptionWriterNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCaptionWriterWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCaptionWriter.archive" relation */
  archive?: InputMaybe<MetadataCaptionWriterNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCaptionWriter.photo" relation */
  photo?: InputMaybe<MetadataCaptionWriterNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCaptionWriterWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCaptionWriter.archive" relation */
  archive: MetadataCaptionWriterNestedArchiveCreateInput;
  /** Actions for the "MetadataCaptionWriter.metadata" relation */
  metadata: MetadataCaptionWriterNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCaptionWriterWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCaptionWriter.archive" relation */
  archive?: InputMaybe<MetadataCaptionWriterNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCaptionWriter.metadata" relation */
  metadata?: InputMaybe<MetadataCaptionWriterNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCategory" resource's node */
export type MetadataCategory = {
  __typename?: 'MetadataCategory';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCategoryCreateInput = {
  /** Actions for the "MetadataCategory.archive" relation */
  archive: MetadataCategoryNestedArchiveCreateInput;
  /** Actions for the "MetadataCategory.metadata" relation */
  metadata: MetadataCategoryNestedMetadataCreateInput;
  /** Actions for the "MetadataCategory.photo" relation */
  photo: MetadataCategoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCategory" node, through the "MetadataCategory.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCategory" node, through the "MetadataCategory.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCategory" node, through the "MetadataCategory.archive" relation. */
  update?: InputMaybe<MetadataCategoryNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCategory" node, through the "MetadataCategory.archive" relation. */
  upsert?: InputMaybe<MetadataCategoryNestedUpsertArchiveCreateInput>;
};

export type MetadataCategoryNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCategory" node, through the "MetadataCategory.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCategory" node, through the "MetadataCategory.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCategory" node, through the "MetadataCategory.archive" relation. */
  update?: InputMaybe<MetadataCategoryNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCategory" node, through the "MetadataCategory.archive" relation. */
  upsert?: InputMaybe<MetadataCategoryNestedUpsertArchiveUpdateInput>;
};

export type MetadataCategoryNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCategory" node, through the "MetadataCategory.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCategory" node, through the "MetadataCategory.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCategory" node, through the "MetadataCategory.metadata" relation. */
  update?: InputMaybe<MetadataCategoryNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCategory" node, through the "MetadataCategory.metadata" relation. */
  upsert?: InputMaybe<MetadataCategoryNestedUpsertMetadataCreateInput>;
};

export type MetadataCategoryNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCategory" node, through the "MetadataCategory.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCategory" node, through the "MetadataCategory.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCategory" node, through the "MetadataCategory.metadata" relation. */
  update?: InputMaybe<MetadataCategoryNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCategory" node, through the "MetadataCategory.metadata" relation. */
  upsert?: InputMaybe<MetadataCategoryNestedUpsertMetadataUpdateInput>;
};

export type MetadataCategoryNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCategory" node, through the "MetadataCategory.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCategory" node, through the "MetadataCategory.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCategory" node, through the "MetadataCategory.photo" relation. */
  update?: InputMaybe<MetadataCategoryNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCategory" node, through the "MetadataCategory.photo" relation. */
  upsert?: InputMaybe<MetadataCategoryNestedUpsertPhotoCreateInput>;
};

export type MetadataCategoryNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCategory" node, through the "MetadataCategory.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCategory" node, through the "MetadataCategory.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCategory" node, through the "MetadataCategory.photo" relation. */
  update?: InputMaybe<MetadataCategoryNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCategory" node, through the "MetadataCategory.photo" relation. */
  upsert?: InputMaybe<MetadataCategoryNestedUpsertPhotoUpdateInput>;
};

export type MetadataCategoryNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCategoryNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCategoryNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCategoryNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCategoryNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCategoryNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCategoryNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCategoryNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCategoryNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCategoryNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCategoryNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCategoryNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCategoryOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

/** "MetadataCategoryPrisma" resource's node */
export type MetadataCategoryPrisma = {
  __typename?: 'MetadataCategoryPrisma';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCategoryPrismaCreateInput = {
  /** Actions for the "MetadataCategoryPrisma.archive" relation */
  archive: MetadataCategoryPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataCategoryPrisma.metadata" relation */
  metadata: MetadataCategoryPrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataCategoryPrisma.photo" relation */
  photo: MetadataCategoryPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryPrismaNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.archive" relation. */
  update?: InputMaybe<MetadataCategoryPrismaNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.archive" relation. */
  upsert?: InputMaybe<MetadataCategoryPrismaNestedUpsertArchiveCreateInput>;
};

export type MetadataCategoryPrismaNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.archive" relation. */
  update?: InputMaybe<MetadataCategoryPrismaNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.archive" relation. */
  upsert?: InputMaybe<MetadataCategoryPrismaNestedUpsertArchiveUpdateInput>;
};

export type MetadataCategoryPrismaNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.metadata" relation. */
  update?: InputMaybe<MetadataCategoryPrismaNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataCategoryPrismaNestedUpsertMetadataCreateInput>;
};

export type MetadataCategoryPrismaNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.metadata" relation. */
  update?: InputMaybe<MetadataCategoryPrismaNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataCategoryPrismaNestedUpsertMetadataUpdateInput>;
};

export type MetadataCategoryPrismaNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.photo" relation. */
  update?: InputMaybe<MetadataCategoryPrismaNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.photo" relation. */
  upsert?: InputMaybe<MetadataCategoryPrismaNestedUpsertPhotoCreateInput>;
};

export type MetadataCategoryPrismaNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.photo" relation. */
  update?: InputMaybe<MetadataCategoryPrismaNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCategoryPrisma" node, through the "MetadataCategoryPrisma.photo" relation. */
  upsert?: InputMaybe<MetadataCategoryPrismaNestedUpsertPhotoUpdateInput>;
};

export type MetadataCategoryPrismaNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCategoryPrismaNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCategoryPrismaNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCategoryPrismaNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCategoryPrismaNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCategoryPrismaNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCategoryPrismaNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCategoryPrismaNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCategoryPrismaNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCategoryPrismaNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCategoryPrismaNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCategoryPrismaNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCategoryPrismaOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCategoryPrismaUpdateInput = {
  /** Actions for the "MetadataCategoryPrisma.archive" relation */
  archive?: InputMaybe<MetadataCategoryPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCategoryPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataCategoryPrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCategoryPrisma.photo" relation */
  photo?: InputMaybe<MetadataCategoryPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCategoryPrisma" nodes by specifying some conditions */
export type MetadataCategoryPrismaWhereInput = {
  AND?: InputMaybe<Array<MetadataCategoryPrismaWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCategoryPrismaWhereInput>;
  OR?: InputMaybe<Array<MetadataCategoryPrismaWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCategoryPrisma" node. */
export type MetadataCategoryPrismaWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCategoryPrismaWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCategoryPrisma.metadata" relation */
  metadata: MetadataCategoryPrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataCategoryPrisma.photo" relation */
  photo: MetadataCategoryPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryPrismaWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCategoryPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataCategoryPrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCategoryPrisma.photo" relation */
  photo?: InputMaybe<MetadataCategoryPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryPrismaWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCategoryPrisma.archive" relation */
  archive: MetadataCategoryPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataCategoryPrisma.photo" relation */
  photo: MetadataCategoryPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryPrismaWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCategoryPrisma.archive" relation */
  archive?: InputMaybe<MetadataCategoryPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCategoryPrisma.photo" relation */
  photo?: InputMaybe<MetadataCategoryPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryPrismaWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCategoryPrisma.archive" relation */
  archive: MetadataCategoryPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataCategoryPrisma.metadata" relation */
  metadata: MetadataCategoryPrismaNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryPrismaWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCategoryPrisma.archive" relation */
  archive?: InputMaybe<MetadataCategoryPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCategoryPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataCategoryPrismaNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryUpdateInput = {
  /** Actions for the "MetadataCategory.archive" relation */
  archive?: InputMaybe<MetadataCategoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCategory.metadata" relation */
  metadata?: InputMaybe<MetadataCategoryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCategory.photo" relation */
  photo?: InputMaybe<MetadataCategoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCategory" nodes by specifying some conditions */
export type MetadataCategoryWhereInput = {
  AND?: InputMaybe<Array<MetadataCategoryWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCategoryWhereInput>;
  OR?: InputMaybe<Array<MetadataCategoryWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCategory" node. */
export type MetadataCategoryWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCategoryWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCategory.metadata" relation */
  metadata: MetadataCategoryNestedMetadataCreateInput;
  /** Actions for the "MetadataCategory.photo" relation */
  photo: MetadataCategoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCategory.metadata" relation */
  metadata?: InputMaybe<MetadataCategoryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCategory.photo" relation */
  photo?: InputMaybe<MetadataCategoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCategory.archive" relation */
  archive: MetadataCategoryNestedArchiveCreateInput;
  /** Actions for the "MetadataCategory.photo" relation */
  photo: MetadataCategoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCategory.archive" relation */
  archive?: InputMaybe<MetadataCategoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCategory.photo" relation */
  photo?: InputMaybe<MetadataCategoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCategory.archive" relation */
  archive: MetadataCategoryNestedArchiveCreateInput;
  /** Actions for the "MetadataCategory.metadata" relation */
  metadata: MetadataCategoryNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCategoryWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCategory.archive" relation */
  archive?: InputMaybe<MetadataCategoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCategory.metadata" relation */
  metadata?: InputMaybe<MetadataCategoryNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCertificate" resource's node */
export type MetadataCertificate = {
  __typename?: 'MetadataCertificate';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCertificateCreateInput = {
  /** Actions for the "MetadataCertificate.archive" relation */
  archive: MetadataCertificateNestedArchiveCreateInput;
  /** Actions for the "MetadataCertificate.metadata" relation */
  metadata: MetadataCertificateNestedMetadataCreateInput;
  /** Actions for the "MetadataCertificate.photo" relation */
  photo: MetadataCertificateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCertificateNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCertificate" node, through the "MetadataCertificate.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCertificate" node, through the "MetadataCertificate.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCertificate" node, through the "MetadataCertificate.archive" relation. */
  update?: InputMaybe<MetadataCertificateNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCertificate" node, through the "MetadataCertificate.archive" relation. */
  upsert?: InputMaybe<MetadataCertificateNestedUpsertArchiveCreateInput>;
};

export type MetadataCertificateNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCertificate" node, through the "MetadataCertificate.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCertificate" node, through the "MetadataCertificate.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCertificate" node, through the "MetadataCertificate.archive" relation. */
  update?: InputMaybe<MetadataCertificateNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCertificate" node, through the "MetadataCertificate.archive" relation. */
  upsert?: InputMaybe<MetadataCertificateNestedUpsertArchiveUpdateInput>;
};

export type MetadataCertificateNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCertificate" node, through the "MetadataCertificate.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCertificate" node, through the "MetadataCertificate.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCertificate" node, through the "MetadataCertificate.metadata" relation. */
  update?: InputMaybe<MetadataCertificateNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCertificate" node, through the "MetadataCertificate.metadata" relation. */
  upsert?: InputMaybe<MetadataCertificateNestedUpsertMetadataCreateInput>;
};

export type MetadataCertificateNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCertificate" node, through the "MetadataCertificate.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCertificate" node, through the "MetadataCertificate.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCertificate" node, through the "MetadataCertificate.metadata" relation. */
  update?: InputMaybe<MetadataCertificateNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCertificate" node, through the "MetadataCertificate.metadata" relation. */
  upsert?: InputMaybe<MetadataCertificateNestedUpsertMetadataUpdateInput>;
};

export type MetadataCertificateNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCertificate" node, through the "MetadataCertificate.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCertificate" node, through the "MetadataCertificate.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCertificate" node, through the "MetadataCertificate.photo" relation. */
  update?: InputMaybe<MetadataCertificateNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCertificate" node, through the "MetadataCertificate.photo" relation. */
  upsert?: InputMaybe<MetadataCertificateNestedUpsertPhotoCreateInput>;
};

export type MetadataCertificateNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCertificate" node, through the "MetadataCertificate.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCertificate" node, through the "MetadataCertificate.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCertificate" node, through the "MetadataCertificate.photo" relation. */
  update?: InputMaybe<MetadataCertificateNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCertificate" node, through the "MetadataCertificate.photo" relation. */
  upsert?: InputMaybe<MetadataCertificateNestedUpsertPhotoUpdateInput>;
};

export type MetadataCertificateNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCertificateNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCertificateNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCertificateNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCertificateNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCertificateNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCertificateNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCertificateNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCertificateNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCertificateNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCertificateNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCertificateNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCertificateOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCertificateUpdateInput = {
  /** Actions for the "MetadataCertificate.archive" relation */
  archive?: InputMaybe<MetadataCertificateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCertificate.metadata" relation */
  metadata?: InputMaybe<MetadataCertificateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCertificate.photo" relation */
  photo?: InputMaybe<MetadataCertificateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCertificate" nodes by specifying some conditions */
export type MetadataCertificateWhereInput = {
  AND?: InputMaybe<Array<MetadataCertificateWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCertificateWhereInput>;
  OR?: InputMaybe<Array<MetadataCertificateWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCertificate" node. */
export type MetadataCertificateWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCertificateWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCertificate.metadata" relation */
  metadata: MetadataCertificateNestedMetadataCreateInput;
  /** Actions for the "MetadataCertificate.photo" relation */
  photo: MetadataCertificateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCertificateWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCertificate.metadata" relation */
  metadata?: InputMaybe<MetadataCertificateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCertificate.photo" relation */
  photo?: InputMaybe<MetadataCertificateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCertificateWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCertificate.archive" relation */
  archive: MetadataCertificateNestedArchiveCreateInput;
  /** Actions for the "MetadataCertificate.photo" relation */
  photo: MetadataCertificateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCertificateWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCertificate.archive" relation */
  archive?: InputMaybe<MetadataCertificateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCertificate.photo" relation */
  photo?: InputMaybe<MetadataCertificateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCertificateWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCertificate.archive" relation */
  archive: MetadataCertificateNestedArchiveCreateInput;
  /** Actions for the "MetadataCertificate.metadata" relation */
  metadata: MetadataCertificateNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCertificateWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCertificate.archive" relation */
  archive?: InputMaybe<MetadataCertificateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCertificate.metadata" relation */
  metadata?: InputMaybe<MetadataCertificateNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCiAdrCity" resource's node */
export type MetadataCiAdrCity = {
  __typename?: 'MetadataCiAdrCity';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCiAdrCityCreateInput = {
  /** Actions for the "MetadataCiAdrCity.archive" relation */
  archive: MetadataCiAdrCityNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrCity.metadata" relation */
  metadata: MetadataCiAdrCityNestedMetadataCreateInput;
  /** Actions for the "MetadataCiAdrCity.photo" relation */
  photo: MetadataCiAdrCityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCityNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.archive" relation. */
  update?: InputMaybe<MetadataCiAdrCityNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.archive" relation. */
  upsert?: InputMaybe<MetadataCiAdrCityNestedUpsertArchiveCreateInput>;
};

export type MetadataCiAdrCityNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.archive" relation. */
  update?: InputMaybe<MetadataCiAdrCityNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.archive" relation. */
  upsert?: InputMaybe<MetadataCiAdrCityNestedUpsertArchiveUpdateInput>;
};

export type MetadataCiAdrCityNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.metadata" relation. */
  update?: InputMaybe<MetadataCiAdrCityNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.metadata" relation. */
  upsert?: InputMaybe<MetadataCiAdrCityNestedUpsertMetadataCreateInput>;
};

export type MetadataCiAdrCityNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.metadata" relation. */
  update?: InputMaybe<MetadataCiAdrCityNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.metadata" relation. */
  upsert?: InputMaybe<MetadataCiAdrCityNestedUpsertMetadataUpdateInput>;
};

export type MetadataCiAdrCityNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.photo" relation. */
  update?: InputMaybe<MetadataCiAdrCityNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.photo" relation. */
  upsert?: InputMaybe<MetadataCiAdrCityNestedUpsertPhotoCreateInput>;
};

export type MetadataCiAdrCityNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.photo" relation. */
  update?: InputMaybe<MetadataCiAdrCityNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCiAdrCity" node, through the "MetadataCiAdrCity.photo" relation. */
  upsert?: InputMaybe<MetadataCiAdrCityNestedUpsertPhotoUpdateInput>;
};

export type MetadataCiAdrCityNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrCityNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrCityNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrCityNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrCityNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrCityNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrCityNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrCityNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrCityNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrCityNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrCityNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrCityNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCiAdrCityOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCiAdrCityUpdateInput = {
  /** Actions for the "MetadataCiAdrCity.archive" relation */
  archive?: InputMaybe<MetadataCiAdrCityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrCity.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrCityNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiAdrCity.photo" relation */
  photo?: InputMaybe<MetadataCiAdrCityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCiAdrCity" nodes by specifying some conditions */
export type MetadataCiAdrCityWhereInput = {
  AND?: InputMaybe<Array<MetadataCiAdrCityWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCiAdrCityWhereInput>;
  OR?: InputMaybe<Array<MetadataCiAdrCityWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCiAdrCity" node. */
export type MetadataCiAdrCityWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCiAdrCityWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCiAdrCity.metadata" relation */
  metadata: MetadataCiAdrCityNestedMetadataCreateInput;
  /** Actions for the "MetadataCiAdrCity.photo" relation */
  photo: MetadataCiAdrCityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCityWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCiAdrCity.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrCityNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiAdrCity.photo" relation */
  photo?: InputMaybe<MetadataCiAdrCityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCityWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCiAdrCity.archive" relation */
  archive: MetadataCiAdrCityNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrCity.photo" relation */
  photo: MetadataCiAdrCityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCityWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCiAdrCity.archive" relation */
  archive?: InputMaybe<MetadataCiAdrCityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrCity.photo" relation */
  photo?: InputMaybe<MetadataCiAdrCityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCityWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCiAdrCity.archive" relation */
  archive: MetadataCiAdrCityNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrCity.metadata" relation */
  metadata: MetadataCiAdrCityNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCityWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCiAdrCity.archive" relation */
  archive?: InputMaybe<MetadataCiAdrCityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrCity.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrCityNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCiAdrCtry" resource's node */
export type MetadataCiAdrCtry = {
  __typename?: 'MetadataCiAdrCtry';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCiAdrCtryCreateInput = {
  /** Actions for the "MetadataCiAdrCtry.archive" relation */
  archive: MetadataCiAdrCtryNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrCtry.metadata" relation */
  metadata: MetadataCiAdrCtryNestedMetadataCreateInput;
  /** Actions for the "MetadataCiAdrCtry.photo" relation */
  photo: MetadataCiAdrCtryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCtryNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.archive" relation. */
  update?: InputMaybe<MetadataCiAdrCtryNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.archive" relation. */
  upsert?: InputMaybe<MetadataCiAdrCtryNestedUpsertArchiveCreateInput>;
};

export type MetadataCiAdrCtryNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.archive" relation. */
  update?: InputMaybe<MetadataCiAdrCtryNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.archive" relation. */
  upsert?: InputMaybe<MetadataCiAdrCtryNestedUpsertArchiveUpdateInput>;
};

export type MetadataCiAdrCtryNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.metadata" relation. */
  update?: InputMaybe<MetadataCiAdrCtryNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.metadata" relation. */
  upsert?: InputMaybe<MetadataCiAdrCtryNestedUpsertMetadataCreateInput>;
};

export type MetadataCiAdrCtryNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.metadata" relation. */
  update?: InputMaybe<MetadataCiAdrCtryNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.metadata" relation. */
  upsert?: InputMaybe<MetadataCiAdrCtryNestedUpsertMetadataUpdateInput>;
};

export type MetadataCiAdrCtryNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.photo" relation. */
  update?: InputMaybe<MetadataCiAdrCtryNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.photo" relation. */
  upsert?: InputMaybe<MetadataCiAdrCtryNestedUpsertPhotoCreateInput>;
};

export type MetadataCiAdrCtryNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.photo" relation. */
  update?: InputMaybe<MetadataCiAdrCtryNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCiAdrCtry" node, through the "MetadataCiAdrCtry.photo" relation. */
  upsert?: InputMaybe<MetadataCiAdrCtryNestedUpsertPhotoUpdateInput>;
};

export type MetadataCiAdrCtryNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrCtryNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrCtryNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrCtryNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrCtryNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrCtryNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrCtryNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrCtryNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrCtryNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrCtryNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrCtryNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrCtryNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCiAdrCtryOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCiAdrCtryUpdateInput = {
  /** Actions for the "MetadataCiAdrCtry.archive" relation */
  archive?: InputMaybe<MetadataCiAdrCtryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrCtry.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrCtryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiAdrCtry.photo" relation */
  photo?: InputMaybe<MetadataCiAdrCtryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCiAdrCtry" nodes by specifying some conditions */
export type MetadataCiAdrCtryWhereInput = {
  AND?: InputMaybe<Array<MetadataCiAdrCtryWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCiAdrCtryWhereInput>;
  OR?: InputMaybe<Array<MetadataCiAdrCtryWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCiAdrCtry" node. */
export type MetadataCiAdrCtryWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCiAdrCtryWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCiAdrCtry.metadata" relation */
  metadata: MetadataCiAdrCtryNestedMetadataCreateInput;
  /** Actions for the "MetadataCiAdrCtry.photo" relation */
  photo: MetadataCiAdrCtryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCtryWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCiAdrCtry.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrCtryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiAdrCtry.photo" relation */
  photo?: InputMaybe<MetadataCiAdrCtryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCtryWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCiAdrCtry.archive" relation */
  archive: MetadataCiAdrCtryNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrCtry.photo" relation */
  photo: MetadataCiAdrCtryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCtryWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCiAdrCtry.archive" relation */
  archive?: InputMaybe<MetadataCiAdrCtryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrCtry.photo" relation */
  photo?: InputMaybe<MetadataCiAdrCtryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCtryWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCiAdrCtry.archive" relation */
  archive: MetadataCiAdrCtryNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrCtry.metadata" relation */
  metadata: MetadataCiAdrCtryNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrCtryWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCiAdrCtry.archive" relation */
  archive?: InputMaybe<MetadataCiAdrCtryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrCtry.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrCtryNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCiAdrExtadr" resource's node */
export type MetadataCiAdrExtadr = {
  __typename?: 'MetadataCiAdrExtadr';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCiAdrExtadrCreateInput = {
  /** Actions for the "MetadataCiAdrExtadr.archive" relation */
  archive: MetadataCiAdrExtadrNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrExtadr.metadata" relation */
  metadata: MetadataCiAdrExtadrNestedMetadataCreateInput;
  /** Actions for the "MetadataCiAdrExtadr.photo" relation */
  photo: MetadataCiAdrExtadrNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrExtadrNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.archive" relation. */
  update?: InputMaybe<MetadataCiAdrExtadrNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.archive" relation. */
  upsert?: InputMaybe<MetadataCiAdrExtadrNestedUpsertArchiveCreateInput>;
};

export type MetadataCiAdrExtadrNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.archive" relation. */
  update?: InputMaybe<MetadataCiAdrExtadrNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.archive" relation. */
  upsert?: InputMaybe<MetadataCiAdrExtadrNestedUpsertArchiveUpdateInput>;
};

export type MetadataCiAdrExtadrNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.metadata" relation. */
  update?: InputMaybe<MetadataCiAdrExtadrNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.metadata" relation. */
  upsert?: InputMaybe<MetadataCiAdrExtadrNestedUpsertMetadataCreateInput>;
};

export type MetadataCiAdrExtadrNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.metadata" relation. */
  update?: InputMaybe<MetadataCiAdrExtadrNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.metadata" relation. */
  upsert?: InputMaybe<MetadataCiAdrExtadrNestedUpsertMetadataUpdateInput>;
};

export type MetadataCiAdrExtadrNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.photo" relation. */
  update?: InputMaybe<MetadataCiAdrExtadrNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.photo" relation. */
  upsert?: InputMaybe<MetadataCiAdrExtadrNestedUpsertPhotoCreateInput>;
};

export type MetadataCiAdrExtadrNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.photo" relation. */
  update?: InputMaybe<MetadataCiAdrExtadrNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCiAdrExtadr" node, through the "MetadataCiAdrExtadr.photo" relation. */
  upsert?: InputMaybe<MetadataCiAdrExtadrNestedUpsertPhotoUpdateInput>;
};

export type MetadataCiAdrExtadrNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrExtadrNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrExtadrNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrExtadrNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrExtadrNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrExtadrNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrExtadrNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrExtadrNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrExtadrNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrExtadrNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrExtadrNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrExtadrNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCiAdrExtadrOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCiAdrExtadrUpdateInput = {
  /** Actions for the "MetadataCiAdrExtadr.archive" relation */
  archive?: InputMaybe<MetadataCiAdrExtadrNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrExtadr.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrExtadrNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiAdrExtadr.photo" relation */
  photo?: InputMaybe<MetadataCiAdrExtadrNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCiAdrExtadr" nodes by specifying some conditions */
export type MetadataCiAdrExtadrWhereInput = {
  AND?: InputMaybe<Array<MetadataCiAdrExtadrWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
  OR?: InputMaybe<Array<MetadataCiAdrExtadrWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCiAdrExtadr" node. */
export type MetadataCiAdrExtadrWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCiAdrExtadrWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCiAdrExtadr.metadata" relation */
  metadata: MetadataCiAdrExtadrNestedMetadataCreateInput;
  /** Actions for the "MetadataCiAdrExtadr.photo" relation */
  photo: MetadataCiAdrExtadrNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrExtadrWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCiAdrExtadr.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrExtadrNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiAdrExtadr.photo" relation */
  photo?: InputMaybe<MetadataCiAdrExtadrNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrExtadrWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCiAdrExtadr.archive" relation */
  archive: MetadataCiAdrExtadrNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrExtadr.photo" relation */
  photo: MetadataCiAdrExtadrNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrExtadrWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCiAdrExtadr.archive" relation */
  archive?: InputMaybe<MetadataCiAdrExtadrNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrExtadr.photo" relation */
  photo?: InputMaybe<MetadataCiAdrExtadrNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrExtadrWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCiAdrExtadr.archive" relation */
  archive: MetadataCiAdrExtadrNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrExtadr.metadata" relation */
  metadata: MetadataCiAdrExtadrNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrExtadrWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCiAdrExtadr.archive" relation */
  archive?: InputMaybe<MetadataCiAdrExtadrNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrExtadr.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrExtadrNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCiAdrPcode" resource's node */
export type MetadataCiAdrPcode = {
  __typename?: 'MetadataCiAdrPcode';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCiAdrPcodeCreateInput = {
  /** Actions for the "MetadataCiAdrPcode.archive" relation */
  archive: MetadataCiAdrPcodeNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrPcode.metadata" relation */
  metadata: MetadataCiAdrPcodeNestedMetadataCreateInput;
  /** Actions for the "MetadataCiAdrPcode.photo" relation */
  photo: MetadataCiAdrPcodeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrPcodeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.archive" relation. */
  update?: InputMaybe<MetadataCiAdrPcodeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.archive" relation. */
  upsert?: InputMaybe<MetadataCiAdrPcodeNestedUpsertArchiveCreateInput>;
};

export type MetadataCiAdrPcodeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.archive" relation. */
  update?: InputMaybe<MetadataCiAdrPcodeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.archive" relation. */
  upsert?: InputMaybe<MetadataCiAdrPcodeNestedUpsertArchiveUpdateInput>;
};

export type MetadataCiAdrPcodeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.metadata" relation. */
  update?: InputMaybe<MetadataCiAdrPcodeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.metadata" relation. */
  upsert?: InputMaybe<MetadataCiAdrPcodeNestedUpsertMetadataCreateInput>;
};

export type MetadataCiAdrPcodeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.metadata" relation. */
  update?: InputMaybe<MetadataCiAdrPcodeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.metadata" relation. */
  upsert?: InputMaybe<MetadataCiAdrPcodeNestedUpsertMetadataUpdateInput>;
};

export type MetadataCiAdrPcodeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.photo" relation. */
  update?: InputMaybe<MetadataCiAdrPcodeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.photo" relation. */
  upsert?: InputMaybe<MetadataCiAdrPcodeNestedUpsertPhotoCreateInput>;
};

export type MetadataCiAdrPcodeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.photo" relation. */
  update?: InputMaybe<MetadataCiAdrPcodeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCiAdrPcode" node, through the "MetadataCiAdrPcode.photo" relation. */
  upsert?: InputMaybe<MetadataCiAdrPcodeNestedUpsertPhotoUpdateInput>;
};

export type MetadataCiAdrPcodeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrPcodeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrPcodeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrPcodeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrPcodeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrPcodeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrPcodeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrPcodeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrPcodeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrPcodeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrPcodeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrPcodeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCiAdrPcodeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCiAdrPcodeUpdateInput = {
  /** Actions for the "MetadataCiAdrPcode.archive" relation */
  archive?: InputMaybe<MetadataCiAdrPcodeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrPcode.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrPcodeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiAdrPcode.photo" relation */
  photo?: InputMaybe<MetadataCiAdrPcodeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCiAdrPcode" nodes by specifying some conditions */
export type MetadataCiAdrPcodeWhereInput = {
  AND?: InputMaybe<Array<MetadataCiAdrPcodeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
  OR?: InputMaybe<Array<MetadataCiAdrPcodeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCiAdrPcode" node. */
export type MetadataCiAdrPcodeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCiAdrPcodeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCiAdrPcode.metadata" relation */
  metadata: MetadataCiAdrPcodeNestedMetadataCreateInput;
  /** Actions for the "MetadataCiAdrPcode.photo" relation */
  photo: MetadataCiAdrPcodeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrPcodeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCiAdrPcode.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrPcodeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiAdrPcode.photo" relation */
  photo?: InputMaybe<MetadataCiAdrPcodeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrPcodeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCiAdrPcode.archive" relation */
  archive: MetadataCiAdrPcodeNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrPcode.photo" relation */
  photo: MetadataCiAdrPcodeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrPcodeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCiAdrPcode.archive" relation */
  archive?: InputMaybe<MetadataCiAdrPcodeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrPcode.photo" relation */
  photo?: InputMaybe<MetadataCiAdrPcodeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrPcodeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCiAdrPcode.archive" relation */
  archive: MetadataCiAdrPcodeNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrPcode.metadata" relation */
  metadata: MetadataCiAdrPcodeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrPcodeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCiAdrPcode.archive" relation */
  archive?: InputMaybe<MetadataCiAdrPcodeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrPcode.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrPcodeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCiAdrRegion" resource's node */
export type MetadataCiAdrRegion = {
  __typename?: 'MetadataCiAdrRegion';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCiAdrRegionCreateInput = {
  /** Actions for the "MetadataCiAdrRegion.archive" relation */
  archive: MetadataCiAdrRegionNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrRegion.metadata" relation */
  metadata: MetadataCiAdrRegionNestedMetadataCreateInput;
  /** Actions for the "MetadataCiAdrRegion.photo" relation */
  photo: MetadataCiAdrRegionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrRegionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.archive" relation. */
  update?: InputMaybe<MetadataCiAdrRegionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.archive" relation. */
  upsert?: InputMaybe<MetadataCiAdrRegionNestedUpsertArchiveCreateInput>;
};

export type MetadataCiAdrRegionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.archive" relation. */
  update?: InputMaybe<MetadataCiAdrRegionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.archive" relation. */
  upsert?: InputMaybe<MetadataCiAdrRegionNestedUpsertArchiveUpdateInput>;
};

export type MetadataCiAdrRegionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.metadata" relation. */
  update?: InputMaybe<MetadataCiAdrRegionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.metadata" relation. */
  upsert?: InputMaybe<MetadataCiAdrRegionNestedUpsertMetadataCreateInput>;
};

export type MetadataCiAdrRegionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.metadata" relation. */
  update?: InputMaybe<MetadataCiAdrRegionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.metadata" relation. */
  upsert?: InputMaybe<MetadataCiAdrRegionNestedUpsertMetadataUpdateInput>;
};

export type MetadataCiAdrRegionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.photo" relation. */
  update?: InputMaybe<MetadataCiAdrRegionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.photo" relation. */
  upsert?: InputMaybe<MetadataCiAdrRegionNestedUpsertPhotoCreateInput>;
};

export type MetadataCiAdrRegionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.photo" relation. */
  update?: InputMaybe<MetadataCiAdrRegionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCiAdrRegion" node, through the "MetadataCiAdrRegion.photo" relation. */
  upsert?: InputMaybe<MetadataCiAdrRegionNestedUpsertPhotoUpdateInput>;
};

export type MetadataCiAdrRegionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrRegionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrRegionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrRegionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrRegionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrRegionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrRegionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrRegionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiAdrRegionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrRegionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiAdrRegionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiAdrRegionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCiAdrRegionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCiAdrRegionUpdateInput = {
  /** Actions for the "MetadataCiAdrRegion.archive" relation */
  archive?: InputMaybe<MetadataCiAdrRegionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrRegion.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrRegionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiAdrRegion.photo" relation */
  photo?: InputMaybe<MetadataCiAdrRegionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCiAdrRegion" nodes by specifying some conditions */
export type MetadataCiAdrRegionWhereInput = {
  AND?: InputMaybe<Array<MetadataCiAdrRegionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCiAdrRegionWhereInput>;
  OR?: InputMaybe<Array<MetadataCiAdrRegionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCiAdrRegion" node. */
export type MetadataCiAdrRegionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCiAdrRegionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCiAdrRegion.metadata" relation */
  metadata: MetadataCiAdrRegionNestedMetadataCreateInput;
  /** Actions for the "MetadataCiAdrRegion.photo" relation */
  photo: MetadataCiAdrRegionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrRegionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCiAdrRegion.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrRegionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiAdrRegion.photo" relation */
  photo?: InputMaybe<MetadataCiAdrRegionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrRegionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCiAdrRegion.archive" relation */
  archive: MetadataCiAdrRegionNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrRegion.photo" relation */
  photo: MetadataCiAdrRegionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrRegionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCiAdrRegion.archive" relation */
  archive?: InputMaybe<MetadataCiAdrRegionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrRegion.photo" relation */
  photo?: InputMaybe<MetadataCiAdrRegionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrRegionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCiAdrRegion.archive" relation */
  archive: MetadataCiAdrRegionNestedArchiveCreateInput;
  /** Actions for the "MetadataCiAdrRegion.metadata" relation */
  metadata: MetadataCiAdrRegionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiAdrRegionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCiAdrRegion.archive" relation */
  archive?: InputMaybe<MetadataCiAdrRegionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiAdrRegion.metadata" relation */
  metadata?: InputMaybe<MetadataCiAdrRegionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCiEmailWork" resource's node */
export type MetadataCiEmailWork = {
  __typename?: 'MetadataCiEmailWork';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCiEmailWorkCreateInput = {
  /** Actions for the "MetadataCiEmailWork.archive" relation */
  archive: MetadataCiEmailWorkNestedArchiveCreateInput;
  /** Actions for the "MetadataCiEmailWork.metadata" relation */
  metadata: MetadataCiEmailWorkNestedMetadataCreateInput;
  /** Actions for the "MetadataCiEmailWork.photo" relation */
  photo: MetadataCiEmailWorkNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiEmailWorkNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.archive" relation. */
  update?: InputMaybe<MetadataCiEmailWorkNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.archive" relation. */
  upsert?: InputMaybe<MetadataCiEmailWorkNestedUpsertArchiveCreateInput>;
};

export type MetadataCiEmailWorkNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.archive" relation. */
  update?: InputMaybe<MetadataCiEmailWorkNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.archive" relation. */
  upsert?: InputMaybe<MetadataCiEmailWorkNestedUpsertArchiveUpdateInput>;
};

export type MetadataCiEmailWorkNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.metadata" relation. */
  update?: InputMaybe<MetadataCiEmailWorkNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.metadata" relation. */
  upsert?: InputMaybe<MetadataCiEmailWorkNestedUpsertMetadataCreateInput>;
};

export type MetadataCiEmailWorkNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.metadata" relation. */
  update?: InputMaybe<MetadataCiEmailWorkNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.metadata" relation. */
  upsert?: InputMaybe<MetadataCiEmailWorkNestedUpsertMetadataUpdateInput>;
};

export type MetadataCiEmailWorkNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.photo" relation. */
  update?: InputMaybe<MetadataCiEmailWorkNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.photo" relation. */
  upsert?: InputMaybe<MetadataCiEmailWorkNestedUpsertPhotoCreateInput>;
};

export type MetadataCiEmailWorkNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.photo" relation. */
  update?: InputMaybe<MetadataCiEmailWorkNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCiEmailWork" node, through the "MetadataCiEmailWork.photo" relation. */
  upsert?: InputMaybe<MetadataCiEmailWorkNestedUpsertPhotoUpdateInput>;
};

export type MetadataCiEmailWorkNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiEmailWorkNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiEmailWorkNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiEmailWorkNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiEmailWorkNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiEmailWorkNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiEmailWorkNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiEmailWorkNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiEmailWorkNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiEmailWorkNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiEmailWorkNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiEmailWorkNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCiEmailWorkOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCiEmailWorkUpdateInput = {
  /** Actions for the "MetadataCiEmailWork.archive" relation */
  archive?: InputMaybe<MetadataCiEmailWorkNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiEmailWork.metadata" relation */
  metadata?: InputMaybe<MetadataCiEmailWorkNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiEmailWork.photo" relation */
  photo?: InputMaybe<MetadataCiEmailWorkNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCiEmailWork" nodes by specifying some conditions */
export type MetadataCiEmailWorkWhereInput = {
  AND?: InputMaybe<Array<MetadataCiEmailWorkWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCiEmailWorkWhereInput>;
  OR?: InputMaybe<Array<MetadataCiEmailWorkWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCiEmailWork" node. */
export type MetadataCiEmailWorkWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCiEmailWorkWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCiEmailWork.metadata" relation */
  metadata: MetadataCiEmailWorkNestedMetadataCreateInput;
  /** Actions for the "MetadataCiEmailWork.photo" relation */
  photo: MetadataCiEmailWorkNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiEmailWorkWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCiEmailWork.metadata" relation */
  metadata?: InputMaybe<MetadataCiEmailWorkNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiEmailWork.photo" relation */
  photo?: InputMaybe<MetadataCiEmailWorkNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiEmailWorkWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCiEmailWork.archive" relation */
  archive: MetadataCiEmailWorkNestedArchiveCreateInput;
  /** Actions for the "MetadataCiEmailWork.photo" relation */
  photo: MetadataCiEmailWorkNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiEmailWorkWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCiEmailWork.archive" relation */
  archive?: InputMaybe<MetadataCiEmailWorkNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiEmailWork.photo" relation */
  photo?: InputMaybe<MetadataCiEmailWorkNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiEmailWorkWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCiEmailWork.archive" relation */
  archive: MetadataCiEmailWorkNestedArchiveCreateInput;
  /** Actions for the "MetadataCiEmailWork.metadata" relation */
  metadata: MetadataCiEmailWorkNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiEmailWorkWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCiEmailWork.archive" relation */
  archive?: InputMaybe<MetadataCiEmailWorkNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiEmailWork.metadata" relation */
  metadata?: InputMaybe<MetadataCiEmailWorkNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCiTelWork" resource's node */
export type MetadataCiTelWork = {
  __typename?: 'MetadataCiTelWork';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCiTelWorkCreateInput = {
  /** Actions for the "MetadataCiTelWork.archive" relation */
  archive: MetadataCiTelWorkNestedArchiveCreateInput;
  /** Actions for the "MetadataCiTelWork.metadata" relation */
  metadata: MetadataCiTelWorkNestedMetadataCreateInput;
  /** Actions for the "MetadataCiTelWork.photo" relation */
  photo: MetadataCiTelWorkNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiTelWorkNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.archive" relation. */
  update?: InputMaybe<MetadataCiTelWorkNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.archive" relation. */
  upsert?: InputMaybe<MetadataCiTelWorkNestedUpsertArchiveCreateInput>;
};

export type MetadataCiTelWorkNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.archive" relation. */
  update?: InputMaybe<MetadataCiTelWorkNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.archive" relation. */
  upsert?: InputMaybe<MetadataCiTelWorkNestedUpsertArchiveUpdateInput>;
};

export type MetadataCiTelWorkNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.metadata" relation. */
  update?: InputMaybe<MetadataCiTelWorkNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.metadata" relation. */
  upsert?: InputMaybe<MetadataCiTelWorkNestedUpsertMetadataCreateInput>;
};

export type MetadataCiTelWorkNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.metadata" relation. */
  update?: InputMaybe<MetadataCiTelWorkNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.metadata" relation. */
  upsert?: InputMaybe<MetadataCiTelWorkNestedUpsertMetadataUpdateInput>;
};

export type MetadataCiTelWorkNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.photo" relation. */
  update?: InputMaybe<MetadataCiTelWorkNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCiTelWork" node, through the "MetadataCiTelWork.photo" relation. */
  upsert?: InputMaybe<MetadataCiTelWorkNestedUpsertPhotoCreateInput>;
};

export type MetadataCiTelWorkNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.photo" relation. */
  update?: InputMaybe<MetadataCiTelWorkNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCiTelWork" node, through the "MetadataCiTelWork.photo" relation. */
  upsert?: InputMaybe<MetadataCiTelWorkNestedUpsertPhotoUpdateInput>;
};

export type MetadataCiTelWorkNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiTelWorkNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiTelWorkNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiTelWorkNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiTelWorkNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiTelWorkNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiTelWorkNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiTelWorkNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiTelWorkNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiTelWorkNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiTelWorkNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiTelWorkNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCiTelWorkOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCiTelWorkUpdateInput = {
  /** Actions for the "MetadataCiTelWork.archive" relation */
  archive?: InputMaybe<MetadataCiTelWorkNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiTelWork.metadata" relation */
  metadata?: InputMaybe<MetadataCiTelWorkNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiTelWork.photo" relation */
  photo?: InputMaybe<MetadataCiTelWorkNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCiTelWork" nodes by specifying some conditions */
export type MetadataCiTelWorkWhereInput = {
  AND?: InputMaybe<Array<MetadataCiTelWorkWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCiTelWorkWhereInput>;
  OR?: InputMaybe<Array<MetadataCiTelWorkWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCiTelWork" node. */
export type MetadataCiTelWorkWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCiTelWorkWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCiTelWork.metadata" relation */
  metadata: MetadataCiTelWorkNestedMetadataCreateInput;
  /** Actions for the "MetadataCiTelWork.photo" relation */
  photo: MetadataCiTelWorkNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiTelWorkWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCiTelWork.metadata" relation */
  metadata?: InputMaybe<MetadataCiTelWorkNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiTelWork.photo" relation */
  photo?: InputMaybe<MetadataCiTelWorkNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiTelWorkWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCiTelWork.archive" relation */
  archive: MetadataCiTelWorkNestedArchiveCreateInput;
  /** Actions for the "MetadataCiTelWork.photo" relation */
  photo: MetadataCiTelWorkNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiTelWorkWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCiTelWork.archive" relation */
  archive?: InputMaybe<MetadataCiTelWorkNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiTelWork.photo" relation */
  photo?: InputMaybe<MetadataCiTelWorkNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiTelWorkWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCiTelWork.archive" relation */
  archive: MetadataCiTelWorkNestedArchiveCreateInput;
  /** Actions for the "MetadataCiTelWork.metadata" relation */
  metadata: MetadataCiTelWorkNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiTelWorkWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCiTelWork.archive" relation */
  archive?: InputMaybe<MetadataCiTelWorkNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiTelWork.metadata" relation */
  metadata?: InputMaybe<MetadataCiTelWorkNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCity" resource's node */
export type MetadataCity = {
  __typename?: 'MetadataCity';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCityCreateInput = {
  /** Actions for the "MetadataCity.archive" relation */
  archive: MetadataCityNestedArchiveCreateInput;
  /** Actions for the "MetadataCity.metadata" relation */
  metadata: MetadataCityNestedMetadataCreateInput;
  /** Actions for the "MetadataCity.photo" relation */
  photo: MetadataCityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCityNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCity" node, through the "MetadataCity.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCity" node, through the "MetadataCity.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCity" node, through the "MetadataCity.archive" relation. */
  update?: InputMaybe<MetadataCityNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCity" node, through the "MetadataCity.archive" relation. */
  upsert?: InputMaybe<MetadataCityNestedUpsertArchiveCreateInput>;
};

export type MetadataCityNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCity" node, through the "MetadataCity.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCity" node, through the "MetadataCity.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCity" node, through the "MetadataCity.archive" relation. */
  update?: InputMaybe<MetadataCityNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCity" node, through the "MetadataCity.archive" relation. */
  upsert?: InputMaybe<MetadataCityNestedUpsertArchiveUpdateInput>;
};

export type MetadataCityNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCity" node, through the "MetadataCity.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCity" node, through the "MetadataCity.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCity" node, through the "MetadataCity.metadata" relation. */
  update?: InputMaybe<MetadataCityNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCity" node, through the "MetadataCity.metadata" relation. */
  upsert?: InputMaybe<MetadataCityNestedUpsertMetadataCreateInput>;
};

export type MetadataCityNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCity" node, through the "MetadataCity.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCity" node, through the "MetadataCity.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCity" node, through the "MetadataCity.metadata" relation. */
  update?: InputMaybe<MetadataCityNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCity" node, through the "MetadataCity.metadata" relation. */
  upsert?: InputMaybe<MetadataCityNestedUpsertMetadataUpdateInput>;
};

export type MetadataCityNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCity" node, through the "MetadataCity.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCity" node, through the "MetadataCity.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCity" node, through the "MetadataCity.photo" relation. */
  update?: InputMaybe<MetadataCityNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCity" node, through the "MetadataCity.photo" relation. */
  upsert?: InputMaybe<MetadataCityNestedUpsertPhotoCreateInput>;
};

export type MetadataCityNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCity" node, through the "MetadataCity.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCity" node, through the "MetadataCity.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCity" node, through the "MetadataCity.photo" relation. */
  update?: InputMaybe<MetadataCityNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCity" node, through the "MetadataCity.photo" relation. */
  upsert?: InputMaybe<MetadataCityNestedUpsertPhotoUpdateInput>;
};

export type MetadataCityNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCityNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCityNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCityNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCityNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCityNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCityNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCityNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCityNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCityNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCityNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCityNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCityOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCityUpdateInput = {
  /** Actions for the "MetadataCity.archive" relation */
  archive?: InputMaybe<MetadataCityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCity.metadata" relation */
  metadata?: InputMaybe<MetadataCityNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCity.photo" relation */
  photo?: InputMaybe<MetadataCityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCity" nodes by specifying some conditions */
export type MetadataCityWhereInput = {
  AND?: InputMaybe<Array<MetadataCityWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCityWhereInput>;
  OR?: InputMaybe<Array<MetadataCityWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCity" node. */
export type MetadataCityWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCityWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCity.metadata" relation */
  metadata: MetadataCityNestedMetadataCreateInput;
  /** Actions for the "MetadataCity.photo" relation */
  photo: MetadataCityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCityWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCity.metadata" relation */
  metadata?: InputMaybe<MetadataCityNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCity.photo" relation */
  photo?: InputMaybe<MetadataCityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCityWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCity.archive" relation */
  archive: MetadataCityNestedArchiveCreateInput;
  /** Actions for the "MetadataCity.photo" relation */
  photo: MetadataCityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCityWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCity.archive" relation */
  archive?: InputMaybe<MetadataCityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCity.photo" relation */
  photo?: InputMaybe<MetadataCityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCityWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCity.archive" relation */
  archive: MetadataCityNestedArchiveCreateInput;
  /** Actions for the "MetadataCity.metadata" relation */
  metadata: MetadataCityNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCityWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCity.archive" relation */
  archive?: InputMaybe<MetadataCityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCity.metadata" relation */
  metadata?: InputMaybe<MetadataCityNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCiUrlWork" resource's node */
export type MetadataCiUrlWork = {
  __typename?: 'MetadataCiUrlWork';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCiUrlWorkCreateInput = {
  /** Actions for the "MetadataCiUrlWork.archive" relation */
  archive: MetadataCiUrlWorkNestedArchiveCreateInput;
  /** Actions for the "MetadataCiUrlWork.metadata" relation */
  metadata: MetadataCiUrlWorkNestedMetadataCreateInput;
  /** Actions for the "MetadataCiUrlWork.photo" relation */
  photo: MetadataCiUrlWorkNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiUrlWorkNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.archive" relation. */
  update?: InputMaybe<MetadataCiUrlWorkNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.archive" relation. */
  upsert?: InputMaybe<MetadataCiUrlWorkNestedUpsertArchiveCreateInput>;
};

export type MetadataCiUrlWorkNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.archive" relation. */
  update?: InputMaybe<MetadataCiUrlWorkNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.archive" relation. */
  upsert?: InputMaybe<MetadataCiUrlWorkNestedUpsertArchiveUpdateInput>;
};

export type MetadataCiUrlWorkNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.metadata" relation. */
  update?: InputMaybe<MetadataCiUrlWorkNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.metadata" relation. */
  upsert?: InputMaybe<MetadataCiUrlWorkNestedUpsertMetadataCreateInput>;
};

export type MetadataCiUrlWorkNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.metadata" relation. */
  update?: InputMaybe<MetadataCiUrlWorkNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.metadata" relation. */
  upsert?: InputMaybe<MetadataCiUrlWorkNestedUpsertMetadataUpdateInput>;
};

export type MetadataCiUrlWorkNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.photo" relation. */
  update?: InputMaybe<MetadataCiUrlWorkNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.photo" relation. */
  upsert?: InputMaybe<MetadataCiUrlWorkNestedUpsertPhotoCreateInput>;
};

export type MetadataCiUrlWorkNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.photo" relation. */
  update?: InputMaybe<MetadataCiUrlWorkNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCiUrlWork" node, through the "MetadataCiUrlWork.photo" relation. */
  upsert?: InputMaybe<MetadataCiUrlWorkNestedUpsertPhotoUpdateInput>;
};

export type MetadataCiUrlWorkNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiUrlWorkNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiUrlWorkNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiUrlWorkNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiUrlWorkNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiUrlWorkNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiUrlWorkNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiUrlWorkNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCiUrlWorkNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiUrlWorkNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCiUrlWorkNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCiUrlWorkNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCiUrlWorkOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCiUrlWorkUpdateInput = {
  /** Actions for the "MetadataCiUrlWork.archive" relation */
  archive?: InputMaybe<MetadataCiUrlWorkNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiUrlWork.metadata" relation */
  metadata?: InputMaybe<MetadataCiUrlWorkNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiUrlWork.photo" relation */
  photo?: InputMaybe<MetadataCiUrlWorkNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCiUrlWork" nodes by specifying some conditions */
export type MetadataCiUrlWorkWhereInput = {
  AND?: InputMaybe<Array<MetadataCiUrlWorkWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCiUrlWorkWhereInput>;
  OR?: InputMaybe<Array<MetadataCiUrlWorkWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCiUrlWork" node. */
export type MetadataCiUrlWorkWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCiUrlWorkWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCiUrlWork.metadata" relation */
  metadata: MetadataCiUrlWorkNestedMetadataCreateInput;
  /** Actions for the "MetadataCiUrlWork.photo" relation */
  photo: MetadataCiUrlWorkNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiUrlWorkWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCiUrlWork.metadata" relation */
  metadata?: InputMaybe<MetadataCiUrlWorkNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCiUrlWork.photo" relation */
  photo?: InputMaybe<MetadataCiUrlWorkNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiUrlWorkWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCiUrlWork.archive" relation */
  archive: MetadataCiUrlWorkNestedArchiveCreateInput;
  /** Actions for the "MetadataCiUrlWork.photo" relation */
  photo: MetadataCiUrlWorkNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiUrlWorkWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCiUrlWork.archive" relation */
  archive?: InputMaybe<MetadataCiUrlWorkNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiUrlWork.photo" relation */
  photo?: InputMaybe<MetadataCiUrlWorkNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiUrlWorkWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCiUrlWork.archive" relation */
  archive: MetadataCiUrlWorkNestedArchiveCreateInput;
  /** Actions for the "MetadataCiUrlWork.metadata" relation */
  metadata: MetadataCiUrlWorkNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCiUrlWorkWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCiUrlWork.archive" relation */
  archive?: InputMaybe<MetadataCiUrlWorkNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCiUrlWork.metadata" relation */
  metadata?: InputMaybe<MetadataCiUrlWorkNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataClassify" resource's node */
export type MetadataClassify = {
  __typename?: 'MetadataClassify';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataClassifyCreateInput = {
  /** Actions for the "MetadataClassify.archive" relation */
  archive: MetadataClassifyNestedArchiveCreateInput;
  /** Actions for the "MetadataClassify.metadata" relation */
  metadata: MetadataClassifyNestedMetadataCreateInput;
  /** Actions for the "MetadataClassify.photo" relation */
  photo: MetadataClassifyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataClassifyNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataClassify" node, through the "MetadataClassify.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataClassify" node, through the "MetadataClassify.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataClassify" node, through the "MetadataClassify.archive" relation. */
  update?: InputMaybe<MetadataClassifyNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataClassify" node, through the "MetadataClassify.archive" relation. */
  upsert?: InputMaybe<MetadataClassifyNestedUpsertArchiveCreateInput>;
};

export type MetadataClassifyNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataClassify" node, through the "MetadataClassify.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataClassify" node, through the "MetadataClassify.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataClassify" node, through the "MetadataClassify.archive" relation. */
  update?: InputMaybe<MetadataClassifyNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataClassify" node, through the "MetadataClassify.archive" relation. */
  upsert?: InputMaybe<MetadataClassifyNestedUpsertArchiveUpdateInput>;
};

export type MetadataClassifyNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataClassify" node, through the "MetadataClassify.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataClassify" node, through the "MetadataClassify.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataClassify" node, through the "MetadataClassify.metadata" relation. */
  update?: InputMaybe<MetadataClassifyNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataClassify" node, through the "MetadataClassify.metadata" relation. */
  upsert?: InputMaybe<MetadataClassifyNestedUpsertMetadataCreateInput>;
};

export type MetadataClassifyNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataClassify" node, through the "MetadataClassify.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataClassify" node, through the "MetadataClassify.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataClassify" node, through the "MetadataClassify.metadata" relation. */
  update?: InputMaybe<MetadataClassifyNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataClassify" node, through the "MetadataClassify.metadata" relation. */
  upsert?: InputMaybe<MetadataClassifyNestedUpsertMetadataUpdateInput>;
};

export type MetadataClassifyNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataClassify" node, through the "MetadataClassify.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataClassify" node, through the "MetadataClassify.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataClassify" node, through the "MetadataClassify.photo" relation. */
  update?: InputMaybe<MetadataClassifyNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataClassify" node, through the "MetadataClassify.photo" relation. */
  upsert?: InputMaybe<MetadataClassifyNestedUpsertPhotoCreateInput>;
};

export type MetadataClassifyNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataClassify" node, through the "MetadataClassify.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataClassify" node, through the "MetadataClassify.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataClassify" node, through the "MetadataClassify.photo" relation. */
  update?: InputMaybe<MetadataClassifyNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataClassify" node, through the "MetadataClassify.photo" relation. */
  upsert?: InputMaybe<MetadataClassifyNestedUpsertPhotoUpdateInput>;
};

export type MetadataClassifyNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataClassifyNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataClassifyNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataClassifyNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataClassifyNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataClassifyNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataClassifyNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataClassifyNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataClassifyNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataClassifyNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataClassifyNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataClassifyNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataClassifyOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataClassifyUpdateInput = {
  /** Actions for the "MetadataClassify.archive" relation */
  archive?: InputMaybe<MetadataClassifyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataClassify.metadata" relation */
  metadata?: InputMaybe<MetadataClassifyNestedMetadataUpdateInput>;
  /** Actions for the "MetadataClassify.photo" relation */
  photo?: InputMaybe<MetadataClassifyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataClassify" nodes by specifying some conditions */
export type MetadataClassifyWhereInput = {
  AND?: InputMaybe<Array<MetadataClassifyWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataClassifyWhereInput>;
  OR?: InputMaybe<Array<MetadataClassifyWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataClassify" node. */
export type MetadataClassifyWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataClassifyWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataClassify.metadata" relation */
  metadata: MetadataClassifyNestedMetadataCreateInput;
  /** Actions for the "MetadataClassify.photo" relation */
  photo: MetadataClassifyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataClassifyWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataClassify.metadata" relation */
  metadata?: InputMaybe<MetadataClassifyNestedMetadataUpdateInput>;
  /** Actions for the "MetadataClassify.photo" relation */
  photo?: InputMaybe<MetadataClassifyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataClassifyWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataClassify.archive" relation */
  archive: MetadataClassifyNestedArchiveCreateInput;
  /** Actions for the "MetadataClassify.photo" relation */
  photo: MetadataClassifyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataClassifyWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataClassify.archive" relation */
  archive?: InputMaybe<MetadataClassifyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataClassify.photo" relation */
  photo?: InputMaybe<MetadataClassifyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataClassifyWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataClassify.archive" relation */
  archive: MetadataClassifyNestedArchiveCreateInput;
  /** Actions for the "MetadataClassify.metadata" relation */
  metadata: MetadataClassifyNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataClassifyWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataClassify.archive" relation */
  archive?: InputMaybe<MetadataClassifyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataClassify.metadata" relation */
  metadata?: InputMaybe<MetadataClassifyNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCompany" resource's node */
export type MetadataCompany = {
  __typename?: 'MetadataCompany';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCompanyCreateInput = {
  /** Actions for the "MetadataCompany.archive" relation */
  archive: MetadataCompanyNestedArchiveCreateInput;
  /** Actions for the "MetadataCompany.metadata" relation */
  metadata: MetadataCompanyNestedMetadataCreateInput;
  /** Actions for the "MetadataCompany.photo" relation */
  photo: MetadataCompanyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCompany" node, through the "MetadataCompany.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCompany" node, through the "MetadataCompany.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCompany" node, through the "MetadataCompany.archive" relation. */
  update?: InputMaybe<MetadataCompanyNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCompany" node, through the "MetadataCompany.archive" relation. */
  upsert?: InputMaybe<MetadataCompanyNestedUpsertArchiveCreateInput>;
};

export type MetadataCompanyNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCompany" node, through the "MetadataCompany.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCompany" node, through the "MetadataCompany.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCompany" node, through the "MetadataCompany.archive" relation. */
  update?: InputMaybe<MetadataCompanyNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCompany" node, through the "MetadataCompany.archive" relation. */
  upsert?: InputMaybe<MetadataCompanyNestedUpsertArchiveUpdateInput>;
};

export type MetadataCompanyNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCompany" node, through the "MetadataCompany.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCompany" node, through the "MetadataCompany.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCompany" node, through the "MetadataCompany.metadata" relation. */
  update?: InputMaybe<MetadataCompanyNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCompany" node, through the "MetadataCompany.metadata" relation. */
  upsert?: InputMaybe<MetadataCompanyNestedUpsertMetadataCreateInput>;
};

export type MetadataCompanyNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCompany" node, through the "MetadataCompany.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCompany" node, through the "MetadataCompany.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCompany" node, through the "MetadataCompany.metadata" relation. */
  update?: InputMaybe<MetadataCompanyNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCompany" node, through the "MetadataCompany.metadata" relation. */
  upsert?: InputMaybe<MetadataCompanyNestedUpsertMetadataUpdateInput>;
};

export type MetadataCompanyNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCompany" node, through the "MetadataCompany.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCompany" node, through the "MetadataCompany.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCompany" node, through the "MetadataCompany.photo" relation. */
  update?: InputMaybe<MetadataCompanyNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCompany" node, through the "MetadataCompany.photo" relation. */
  upsert?: InputMaybe<MetadataCompanyNestedUpsertPhotoCreateInput>;
};

export type MetadataCompanyNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCompany" node, through the "MetadataCompany.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCompany" node, through the "MetadataCompany.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCompany" node, through the "MetadataCompany.photo" relation. */
  update?: InputMaybe<MetadataCompanyNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCompany" node, through the "MetadataCompany.photo" relation. */
  upsert?: InputMaybe<MetadataCompanyNestedUpsertPhotoUpdateInput>;
};

export type MetadataCompanyNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCompanyNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCompanyNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCompanyNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCompanyNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCompanyNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCompanyNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCompanyNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCompanyNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCompanyNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCompanyNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCompanyNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCompanyOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

/** "MetadataCompanyShortName" resource's node */
export type MetadataCompanyShortName = {
  __typename?: 'MetadataCompanyShortName';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCompanyShortNameCreateInput = {
  /** Actions for the "MetadataCompanyShortName.archive" relation */
  archive: MetadataCompanyShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataCompanyShortName.metadata" relation */
  metadata: MetadataCompanyShortNameNestedMetadataCreateInput;
  /** Actions for the "MetadataCompanyShortName.photo" relation */
  photo: MetadataCompanyShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyShortNameNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.archive" relation. */
  update?: InputMaybe<MetadataCompanyShortNameNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.archive" relation. */
  upsert?: InputMaybe<MetadataCompanyShortNameNestedUpsertArchiveCreateInput>;
};

export type MetadataCompanyShortNameNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.archive" relation. */
  update?: InputMaybe<MetadataCompanyShortNameNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.archive" relation. */
  upsert?: InputMaybe<MetadataCompanyShortNameNestedUpsertArchiveUpdateInput>;
};

export type MetadataCompanyShortNameNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.metadata" relation. */
  update?: InputMaybe<MetadataCompanyShortNameNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.metadata" relation. */
  upsert?: InputMaybe<MetadataCompanyShortNameNestedUpsertMetadataCreateInput>;
};

export type MetadataCompanyShortNameNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.metadata" relation. */
  update?: InputMaybe<MetadataCompanyShortNameNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.metadata" relation. */
  upsert?: InputMaybe<MetadataCompanyShortNameNestedUpsertMetadataUpdateInput>;
};

export type MetadataCompanyShortNameNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.photo" relation. */
  update?: InputMaybe<MetadataCompanyShortNameNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.photo" relation. */
  upsert?: InputMaybe<MetadataCompanyShortNameNestedUpsertPhotoCreateInput>;
};

export type MetadataCompanyShortNameNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.photo" relation. */
  update?: InputMaybe<MetadataCompanyShortNameNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCompanyShortName" node, through the "MetadataCompanyShortName.photo" relation. */
  upsert?: InputMaybe<MetadataCompanyShortNameNestedUpsertPhotoUpdateInput>;
};

export type MetadataCompanyShortNameNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCompanyShortNameNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCompanyShortNameNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCompanyShortNameNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCompanyShortNameNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCompanyShortNameNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCompanyShortNameNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCompanyShortNameNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCompanyShortNameNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCompanyShortNameNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCompanyShortNameNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCompanyShortNameNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCompanyShortNameOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCompanyShortNameUpdateInput = {
  /** Actions for the "MetadataCompanyShortName.archive" relation */
  archive?: InputMaybe<MetadataCompanyShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCompanyShortName.metadata" relation */
  metadata?: InputMaybe<MetadataCompanyShortNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCompanyShortName.photo" relation */
  photo?: InputMaybe<MetadataCompanyShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCompanyShortName" nodes by specifying some conditions */
export type MetadataCompanyShortNameWhereInput = {
  AND?: InputMaybe<Array<MetadataCompanyShortNameWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCompanyShortNameWhereInput>;
  OR?: InputMaybe<Array<MetadataCompanyShortNameWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCompanyShortName" node. */
export type MetadataCompanyShortNameWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCompanyShortNameWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCompanyShortName.metadata" relation */
  metadata: MetadataCompanyShortNameNestedMetadataCreateInput;
  /** Actions for the "MetadataCompanyShortName.photo" relation */
  photo: MetadataCompanyShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyShortNameWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCompanyShortName.metadata" relation */
  metadata?: InputMaybe<MetadataCompanyShortNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCompanyShortName.photo" relation */
  photo?: InputMaybe<MetadataCompanyShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyShortNameWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCompanyShortName.archive" relation */
  archive: MetadataCompanyShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataCompanyShortName.photo" relation */
  photo: MetadataCompanyShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyShortNameWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCompanyShortName.archive" relation */
  archive?: InputMaybe<MetadataCompanyShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCompanyShortName.photo" relation */
  photo?: InputMaybe<MetadataCompanyShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyShortNameWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCompanyShortName.archive" relation */
  archive: MetadataCompanyShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataCompanyShortName.metadata" relation */
  metadata: MetadataCompanyShortNameNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyShortNameWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCompanyShortName.archive" relation */
  archive?: InputMaybe<MetadataCompanyShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCompanyShortName.metadata" relation */
  metadata?: InputMaybe<MetadataCompanyShortNameNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyUpdateInput = {
  /** Actions for the "MetadataCompany.archive" relation */
  archive?: InputMaybe<MetadataCompanyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCompany.metadata" relation */
  metadata?: InputMaybe<MetadataCompanyNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCompany.photo" relation */
  photo?: InputMaybe<MetadataCompanyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCompany" nodes by specifying some conditions */
export type MetadataCompanyWhereInput = {
  AND?: InputMaybe<Array<MetadataCompanyWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCompanyWhereInput>;
  OR?: InputMaybe<Array<MetadataCompanyWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCompany" node. */
export type MetadataCompanyWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCompanyWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCompany.metadata" relation */
  metadata: MetadataCompanyNestedMetadataCreateInput;
  /** Actions for the "MetadataCompany.photo" relation */
  photo: MetadataCompanyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCompany.metadata" relation */
  metadata?: InputMaybe<MetadataCompanyNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCompany.photo" relation */
  photo?: InputMaybe<MetadataCompanyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCompany.archive" relation */
  archive: MetadataCompanyNestedArchiveCreateInput;
  /** Actions for the "MetadataCompany.photo" relation */
  photo: MetadataCompanyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCompany.archive" relation */
  archive?: InputMaybe<MetadataCompanyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCompany.photo" relation */
  photo?: InputMaybe<MetadataCompanyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCompany.archive" relation */
  archive: MetadataCompanyNestedArchiveCreateInput;
  /** Actions for the "MetadataCompany.metadata" relation */
  metadata: MetadataCompanyNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCompanyWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCompany.archive" relation */
  archive?: InputMaybe<MetadataCompanyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCompany.metadata" relation */
  metadata?: InputMaybe<MetadataCompanyNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataConfidentiality" resource's node */
export type MetadataConfidentiality = {
  __typename?: 'MetadataConfidentiality';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataConfidentialityCreateInput = {
  /** Actions for the "MetadataConfidentiality.archive" relation */
  archive: MetadataConfidentialityNestedArchiveCreateInput;
  /** Actions for the "MetadataConfidentiality.metadata" relation */
  metadata: MetadataConfidentialityNestedMetadataCreateInput;
  /** Actions for the "MetadataConfidentiality.photo" relation */
  photo: MetadataConfidentialityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataConfidentialityNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.archive" relation. */
  update?: InputMaybe<MetadataConfidentialityNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.archive" relation. */
  upsert?: InputMaybe<MetadataConfidentialityNestedUpsertArchiveCreateInput>;
};

export type MetadataConfidentialityNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.archive" relation. */
  update?: InputMaybe<MetadataConfidentialityNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.archive" relation. */
  upsert?: InputMaybe<MetadataConfidentialityNestedUpsertArchiveUpdateInput>;
};

export type MetadataConfidentialityNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.metadata" relation. */
  update?: InputMaybe<MetadataConfidentialityNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.metadata" relation. */
  upsert?: InputMaybe<MetadataConfidentialityNestedUpsertMetadataCreateInput>;
};

export type MetadataConfidentialityNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.metadata" relation. */
  update?: InputMaybe<MetadataConfidentialityNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.metadata" relation. */
  upsert?: InputMaybe<MetadataConfidentialityNestedUpsertMetadataUpdateInput>;
};

export type MetadataConfidentialityNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.photo" relation. */
  update?: InputMaybe<MetadataConfidentialityNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataConfidentiality" node, through the "MetadataConfidentiality.photo" relation. */
  upsert?: InputMaybe<MetadataConfidentialityNestedUpsertPhotoCreateInput>;
};

export type MetadataConfidentialityNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.photo" relation. */
  update?: InputMaybe<MetadataConfidentialityNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataConfidentiality" node, through the "MetadataConfidentiality.photo" relation. */
  upsert?: InputMaybe<MetadataConfidentialityNestedUpsertPhotoUpdateInput>;
};

export type MetadataConfidentialityNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataConfidentialityNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataConfidentialityNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataConfidentialityNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataConfidentialityNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataConfidentialityNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataConfidentialityNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataConfidentialityNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataConfidentialityNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataConfidentialityNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataConfidentialityNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataConfidentialityNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataConfidentialityOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataConfidentialityUpdateInput = {
  /** Actions for the "MetadataConfidentiality.archive" relation */
  archive?: InputMaybe<MetadataConfidentialityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataConfidentiality.metadata" relation */
  metadata?: InputMaybe<MetadataConfidentialityNestedMetadataUpdateInput>;
  /** Actions for the "MetadataConfidentiality.photo" relation */
  photo?: InputMaybe<MetadataConfidentialityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataConfidentiality" nodes by specifying some conditions */
export type MetadataConfidentialityWhereInput = {
  AND?: InputMaybe<Array<MetadataConfidentialityWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataConfidentialityWhereInput>;
  OR?: InputMaybe<Array<MetadataConfidentialityWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataConfidentiality" node. */
export type MetadataConfidentialityWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataConfidentialityWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataConfidentiality.metadata" relation */
  metadata: MetadataConfidentialityNestedMetadataCreateInput;
  /** Actions for the "MetadataConfidentiality.photo" relation */
  photo: MetadataConfidentialityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataConfidentialityWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataConfidentiality.metadata" relation */
  metadata?: InputMaybe<MetadataConfidentialityNestedMetadataUpdateInput>;
  /** Actions for the "MetadataConfidentiality.photo" relation */
  photo?: InputMaybe<MetadataConfidentialityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataConfidentialityWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataConfidentiality.archive" relation */
  archive: MetadataConfidentialityNestedArchiveCreateInput;
  /** Actions for the "MetadataConfidentiality.photo" relation */
  photo: MetadataConfidentialityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataConfidentialityWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataConfidentiality.archive" relation */
  archive?: InputMaybe<MetadataConfidentialityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataConfidentiality.photo" relation */
  photo?: InputMaybe<MetadataConfidentialityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataConfidentialityWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataConfidentiality.archive" relation */
  archive: MetadataConfidentialityNestedArchiveCreateInput;
  /** Actions for the "MetadataConfidentiality.metadata" relation */
  metadata: MetadataConfidentialityNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataConfidentialityWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataConfidentiality.archive" relation */
  archive?: InputMaybe<MetadataConfidentialityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataConfidentiality.metadata" relation */
  metadata?: InputMaybe<MetadataConfidentialityNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataContainedIn" resource's node */
export type MetadataContainedIn = {
  __typename?: 'MetadataContainedIn';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataContainedInCreateInput = {
  /** Actions for the "MetadataContainedIn.archive" relation */
  archive: MetadataContainedInNestedArchiveCreateInput;
  /** Actions for the "MetadataContainedIn.metadata" relation */
  metadata: MetadataContainedInNestedMetadataCreateInput;
  /** Actions for the "MetadataContainedIn.photo" relation */
  photo: MetadataContainedInNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContainedInNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataContainedIn" node, through the "MetadataContainedIn.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataContainedIn" node, through the "MetadataContainedIn.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataContainedIn" node, through the "MetadataContainedIn.archive" relation. */
  update?: InputMaybe<MetadataContainedInNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataContainedIn" node, through the "MetadataContainedIn.archive" relation. */
  upsert?: InputMaybe<MetadataContainedInNestedUpsertArchiveCreateInput>;
};

export type MetadataContainedInNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.archive" relation. */
  update?: InputMaybe<MetadataContainedInNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.archive" relation. */
  upsert?: InputMaybe<MetadataContainedInNestedUpsertArchiveUpdateInput>;
};

export type MetadataContainedInNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataContainedIn" node, through the "MetadataContainedIn.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataContainedIn" node, through the "MetadataContainedIn.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataContainedIn" node, through the "MetadataContainedIn.metadata" relation. */
  update?: InputMaybe<MetadataContainedInNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataContainedIn" node, through the "MetadataContainedIn.metadata" relation. */
  upsert?: InputMaybe<MetadataContainedInNestedUpsertMetadataCreateInput>;
};

export type MetadataContainedInNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.metadata" relation. */
  update?: InputMaybe<MetadataContainedInNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.metadata" relation. */
  upsert?: InputMaybe<MetadataContainedInNestedUpsertMetadataUpdateInput>;
};

export type MetadataContainedInNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataContainedIn" node, through the "MetadataContainedIn.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataContainedIn" node, through the "MetadataContainedIn.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataContainedIn" node, through the "MetadataContainedIn.photo" relation. */
  update?: InputMaybe<MetadataContainedInNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataContainedIn" node, through the "MetadataContainedIn.photo" relation. */
  upsert?: InputMaybe<MetadataContainedInNestedUpsertPhotoCreateInput>;
};

export type MetadataContainedInNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.photo" relation. */
  update?: InputMaybe<MetadataContainedInNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataContainedIn" node, through the "MetadataContainedIn.photo" relation. */
  upsert?: InputMaybe<MetadataContainedInNestedUpsertPhotoUpdateInput>;
};

export type MetadataContainedInNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContainedInNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContainedInNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContainedInNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContainedInNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataContainedInNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataContainedInNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContainedInNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContainedInNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContainedInNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContainedInNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataContainedInNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataContainedInOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataContainedInUpdateInput = {
  /** Actions for the "MetadataContainedIn.archive" relation */
  archive?: InputMaybe<MetadataContainedInNestedArchiveUpdateInput>;
  /** Actions for the "MetadataContainedIn.metadata" relation */
  metadata?: InputMaybe<MetadataContainedInNestedMetadataUpdateInput>;
  /** Actions for the "MetadataContainedIn.photo" relation */
  photo?: InputMaybe<MetadataContainedInNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataContainedIn" nodes by specifying some conditions */
export type MetadataContainedInWhereInput = {
  AND?: InputMaybe<Array<MetadataContainedInWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataContainedInWhereInput>;
  OR?: InputMaybe<Array<MetadataContainedInWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataContainedIn" node. */
export type MetadataContainedInWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataContainedInWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataContainedIn.metadata" relation */
  metadata: MetadataContainedInNestedMetadataCreateInput;
  /** Actions for the "MetadataContainedIn.photo" relation */
  photo: MetadataContainedInNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContainedInWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataContainedIn.metadata" relation */
  metadata?: InputMaybe<MetadataContainedInNestedMetadataUpdateInput>;
  /** Actions for the "MetadataContainedIn.photo" relation */
  photo?: InputMaybe<MetadataContainedInNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContainedInWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataContainedIn.archive" relation */
  archive: MetadataContainedInNestedArchiveCreateInput;
  /** Actions for the "MetadataContainedIn.photo" relation */
  photo: MetadataContainedInNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContainedInWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataContainedIn.archive" relation */
  archive?: InputMaybe<MetadataContainedInNestedArchiveUpdateInput>;
  /** Actions for the "MetadataContainedIn.photo" relation */
  photo?: InputMaybe<MetadataContainedInNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContainedInWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataContainedIn.archive" relation */
  archive: MetadataContainedInNestedArchiveCreateInput;
  /** Actions for the "MetadataContainedIn.metadata" relation */
  metadata: MetadataContainedInNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContainedInWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataContainedIn.archive" relation */
  archive?: InputMaybe<MetadataContainedInNestedArchiveUpdateInput>;
  /** Actions for the "MetadataContainedIn.metadata" relation */
  metadata?: InputMaybe<MetadataContainedInNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataContentMediaKind" resource's node */
export type MetadataContentMediaKind = {
  __typename?: 'MetadataContentMediaKind';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataContentMediaKindCreateInput = {
  /** Actions for the "MetadataContentMediaKind.archive" relation */
  archive: MetadataContentMediaKindNestedArchiveCreateInput;
  /** Actions for the "MetadataContentMediaKind.metadata" relation */
  metadata: MetadataContentMediaKindNestedMetadataCreateInput;
  /** Actions for the "MetadataContentMediaKind.photo" relation */
  photo: MetadataContentMediaKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentMediaKindNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.archive" relation. */
  update?: InputMaybe<MetadataContentMediaKindNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.archive" relation. */
  upsert?: InputMaybe<MetadataContentMediaKindNestedUpsertArchiveCreateInput>;
};

export type MetadataContentMediaKindNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.archive" relation. */
  update?: InputMaybe<MetadataContentMediaKindNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.archive" relation. */
  upsert?: InputMaybe<MetadataContentMediaKindNestedUpsertArchiveUpdateInput>;
};

export type MetadataContentMediaKindNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.metadata" relation. */
  update?: InputMaybe<MetadataContentMediaKindNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.metadata" relation. */
  upsert?: InputMaybe<MetadataContentMediaKindNestedUpsertMetadataCreateInput>;
};

export type MetadataContentMediaKindNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.metadata" relation. */
  update?: InputMaybe<MetadataContentMediaKindNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.metadata" relation. */
  upsert?: InputMaybe<MetadataContentMediaKindNestedUpsertMetadataUpdateInput>;
};

export type MetadataContentMediaKindNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.photo" relation. */
  update?: InputMaybe<MetadataContentMediaKindNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.photo" relation. */
  upsert?: InputMaybe<MetadataContentMediaKindNestedUpsertPhotoCreateInput>;
};

export type MetadataContentMediaKindNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.photo" relation. */
  update?: InputMaybe<MetadataContentMediaKindNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataContentMediaKind" node, through the "MetadataContentMediaKind.photo" relation. */
  upsert?: InputMaybe<MetadataContentMediaKindNestedUpsertPhotoUpdateInput>;
};

export type MetadataContentMediaKindNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContentMediaKindNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContentMediaKindNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContentMediaKindNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContentMediaKindNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataContentMediaKindNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataContentMediaKindNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContentMediaKindNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContentMediaKindNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContentMediaKindNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContentMediaKindNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataContentMediaKindNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataContentMediaKindOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataContentMediaKindUpdateInput = {
  /** Actions for the "MetadataContentMediaKind.archive" relation */
  archive?: InputMaybe<MetadataContentMediaKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataContentMediaKind.metadata" relation */
  metadata?: InputMaybe<MetadataContentMediaKindNestedMetadataUpdateInput>;
  /** Actions for the "MetadataContentMediaKind.photo" relation */
  photo?: InputMaybe<MetadataContentMediaKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataContentMediaKind" nodes by specifying some conditions */
export type MetadataContentMediaKindWhereInput = {
  AND?: InputMaybe<Array<MetadataContentMediaKindWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataContentMediaKindWhereInput>;
  OR?: InputMaybe<Array<MetadataContentMediaKindWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataContentMediaKind" node. */
export type MetadataContentMediaKindWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataContentMediaKindWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataContentMediaKind.metadata" relation */
  metadata: MetadataContentMediaKindNestedMetadataCreateInput;
  /** Actions for the "MetadataContentMediaKind.photo" relation */
  photo: MetadataContentMediaKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentMediaKindWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataContentMediaKind.metadata" relation */
  metadata?: InputMaybe<MetadataContentMediaKindNestedMetadataUpdateInput>;
  /** Actions for the "MetadataContentMediaKind.photo" relation */
  photo?: InputMaybe<MetadataContentMediaKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentMediaKindWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataContentMediaKind.archive" relation */
  archive: MetadataContentMediaKindNestedArchiveCreateInput;
  /** Actions for the "MetadataContentMediaKind.photo" relation */
  photo: MetadataContentMediaKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentMediaKindWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataContentMediaKind.archive" relation */
  archive?: InputMaybe<MetadataContentMediaKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataContentMediaKind.photo" relation */
  photo?: InputMaybe<MetadataContentMediaKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentMediaKindWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataContentMediaKind.archive" relation */
  archive: MetadataContentMediaKindNestedArchiveCreateInput;
  /** Actions for the "MetadataContentMediaKind.metadata" relation */
  metadata: MetadataContentMediaKindNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentMediaKindWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataContentMediaKind.archive" relation */
  archive?: InputMaybe<MetadataContentMediaKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataContentMediaKind.metadata" relation */
  metadata?: InputMaybe<MetadataContentMediaKindNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataContentValue" resource's node */
export type MetadataContentValue = {
  __typename?: 'MetadataContentValue';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataContentValueCreateInput = {
  /** Actions for the "MetadataContentValue.archive" relation */
  archive: MetadataContentValueNestedArchiveCreateInput;
  /** Actions for the "MetadataContentValue.metadata" relation */
  metadata: MetadataContentValueNestedMetadataCreateInput;
  /** Actions for the "MetadataContentValue.photo" relation */
  photo: MetadataContentValueNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentValueNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataContentValue" node, through the "MetadataContentValue.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataContentValue" node, through the "MetadataContentValue.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataContentValue" node, through the "MetadataContentValue.archive" relation. */
  update?: InputMaybe<MetadataContentValueNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataContentValue" node, through the "MetadataContentValue.archive" relation. */
  upsert?: InputMaybe<MetadataContentValueNestedUpsertArchiveCreateInput>;
};

export type MetadataContentValueNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataContentValue" node, through the "MetadataContentValue.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataContentValue" node, through the "MetadataContentValue.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataContentValue" node, through the "MetadataContentValue.archive" relation. */
  update?: InputMaybe<MetadataContentValueNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataContentValue" node, through the "MetadataContentValue.archive" relation. */
  upsert?: InputMaybe<MetadataContentValueNestedUpsertArchiveUpdateInput>;
};

export type MetadataContentValueNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataContentValue" node, through the "MetadataContentValue.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataContentValue" node, through the "MetadataContentValue.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataContentValue" node, through the "MetadataContentValue.metadata" relation. */
  update?: InputMaybe<MetadataContentValueNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataContentValue" node, through the "MetadataContentValue.metadata" relation. */
  upsert?: InputMaybe<MetadataContentValueNestedUpsertMetadataCreateInput>;
};

export type MetadataContentValueNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataContentValue" node, through the "MetadataContentValue.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataContentValue" node, through the "MetadataContentValue.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataContentValue" node, through the "MetadataContentValue.metadata" relation. */
  update?: InputMaybe<MetadataContentValueNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataContentValue" node, through the "MetadataContentValue.metadata" relation. */
  upsert?: InputMaybe<MetadataContentValueNestedUpsertMetadataUpdateInput>;
};

export type MetadataContentValueNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataContentValue" node, through the "MetadataContentValue.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataContentValue" node, through the "MetadataContentValue.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataContentValue" node, through the "MetadataContentValue.photo" relation. */
  update?: InputMaybe<MetadataContentValueNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataContentValue" node, through the "MetadataContentValue.photo" relation. */
  upsert?: InputMaybe<MetadataContentValueNestedUpsertPhotoCreateInput>;
};

export type MetadataContentValueNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataContentValue" node, through the "MetadataContentValue.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataContentValue" node, through the "MetadataContentValue.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataContentValue" node, through the "MetadataContentValue.photo" relation. */
  update?: InputMaybe<MetadataContentValueNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataContentValue" node, through the "MetadataContentValue.photo" relation. */
  upsert?: InputMaybe<MetadataContentValueNestedUpsertPhotoUpdateInput>;
};

export type MetadataContentValueNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContentValueNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContentValueNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContentValueNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContentValueNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataContentValueNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataContentValueNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContentValueNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataContentValueNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContentValueNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataContentValueNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataContentValueNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataContentValueOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataContentValueUpdateInput = {
  /** Actions for the "MetadataContentValue.archive" relation */
  archive?: InputMaybe<MetadataContentValueNestedArchiveUpdateInput>;
  /** Actions for the "MetadataContentValue.metadata" relation */
  metadata?: InputMaybe<MetadataContentValueNestedMetadataUpdateInput>;
  /** Actions for the "MetadataContentValue.photo" relation */
  photo?: InputMaybe<MetadataContentValueNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataContentValue" nodes by specifying some conditions */
export type MetadataContentValueWhereInput = {
  AND?: InputMaybe<Array<MetadataContentValueWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataContentValueWhereInput>;
  OR?: InputMaybe<Array<MetadataContentValueWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataContentValue" node. */
export type MetadataContentValueWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataContentValueWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataContentValue.metadata" relation */
  metadata: MetadataContentValueNestedMetadataCreateInput;
  /** Actions for the "MetadataContentValue.photo" relation */
  photo: MetadataContentValueNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentValueWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataContentValue.metadata" relation */
  metadata?: InputMaybe<MetadataContentValueNestedMetadataUpdateInput>;
  /** Actions for the "MetadataContentValue.photo" relation */
  photo?: InputMaybe<MetadataContentValueNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentValueWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataContentValue.archive" relation */
  archive: MetadataContentValueNestedArchiveCreateInput;
  /** Actions for the "MetadataContentValue.photo" relation */
  photo: MetadataContentValueNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentValueWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataContentValue.archive" relation */
  archive?: InputMaybe<MetadataContentValueNestedArchiveUpdateInput>;
  /** Actions for the "MetadataContentValue.photo" relation */
  photo?: InputMaybe<MetadataContentValueNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentValueWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataContentValue.archive" relation */
  archive: MetadataContentValueNestedArchiveCreateInput;
  /** Actions for the "MetadataContentValue.metadata" relation */
  metadata: MetadataContentValueNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataContentValueWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataContentValue.archive" relation */
  archive?: InputMaybe<MetadataContentValueNestedArchiveUpdateInput>;
  /** Actions for the "MetadataContentValue.metadata" relation */
  metadata?: InputMaybe<MetadataContentValueNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCopyrightLayer" resource's node */
export type MetadataCopyrightLayer = {
  __typename?: 'MetadataCopyrightLayer';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCopyrightLayerCreateInput = {
  /** Actions for the "MetadataCopyrightLayer.archive" relation */
  archive: MetadataCopyrightLayerNestedArchiveCreateInput;
  /** Actions for the "MetadataCopyrightLayer.metadata" relation */
  metadata: MetadataCopyrightLayerNestedMetadataCreateInput;
  /** Actions for the "MetadataCopyrightLayer.photo" relation */
  photo: MetadataCopyrightLayerNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCopyrightLayerNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.archive" relation. */
  update?: InputMaybe<MetadataCopyrightLayerNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.archive" relation. */
  upsert?: InputMaybe<MetadataCopyrightLayerNestedUpsertArchiveCreateInput>;
};

export type MetadataCopyrightLayerNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.archive" relation. */
  update?: InputMaybe<MetadataCopyrightLayerNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.archive" relation. */
  upsert?: InputMaybe<MetadataCopyrightLayerNestedUpsertArchiveUpdateInput>;
};

export type MetadataCopyrightLayerNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.metadata" relation. */
  update?: InputMaybe<MetadataCopyrightLayerNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.metadata" relation. */
  upsert?: InputMaybe<MetadataCopyrightLayerNestedUpsertMetadataCreateInput>;
};

export type MetadataCopyrightLayerNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.metadata" relation. */
  update?: InputMaybe<MetadataCopyrightLayerNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.metadata" relation. */
  upsert?: InputMaybe<MetadataCopyrightLayerNestedUpsertMetadataUpdateInput>;
};

export type MetadataCopyrightLayerNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.photo" relation. */
  update?: InputMaybe<MetadataCopyrightLayerNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.photo" relation. */
  upsert?: InputMaybe<MetadataCopyrightLayerNestedUpsertPhotoCreateInput>;
};

export type MetadataCopyrightLayerNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.photo" relation. */
  update?: InputMaybe<MetadataCopyrightLayerNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCopyrightLayer" node, through the "MetadataCopyrightLayer.photo" relation. */
  upsert?: InputMaybe<MetadataCopyrightLayerNestedUpsertPhotoUpdateInput>;
};

export type MetadataCopyrightLayerNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCopyrightLayerNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCopyrightLayerNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCopyrightLayerNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCopyrightLayerNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCopyrightLayerNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCopyrightLayerNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCopyrightLayerNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCopyrightLayerNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCopyrightLayerNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCopyrightLayerNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCopyrightLayerNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCopyrightLayerOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCopyrightLayerUpdateInput = {
  /** Actions for the "MetadataCopyrightLayer.archive" relation */
  archive?: InputMaybe<MetadataCopyrightLayerNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCopyrightLayer.metadata" relation */
  metadata?: InputMaybe<MetadataCopyrightLayerNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCopyrightLayer.photo" relation */
  photo?: InputMaybe<MetadataCopyrightLayerNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCopyrightLayer" nodes by specifying some conditions */
export type MetadataCopyrightLayerWhereInput = {
  AND?: InputMaybe<Array<MetadataCopyrightLayerWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCopyrightLayerWhereInput>;
  OR?: InputMaybe<Array<MetadataCopyrightLayerWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCopyrightLayer" node. */
export type MetadataCopyrightLayerWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCopyrightLayerWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCopyrightLayer.metadata" relation */
  metadata: MetadataCopyrightLayerNestedMetadataCreateInput;
  /** Actions for the "MetadataCopyrightLayer.photo" relation */
  photo: MetadataCopyrightLayerNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCopyrightLayerWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCopyrightLayer.metadata" relation */
  metadata?: InputMaybe<MetadataCopyrightLayerNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCopyrightLayer.photo" relation */
  photo?: InputMaybe<MetadataCopyrightLayerNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCopyrightLayerWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCopyrightLayer.archive" relation */
  archive: MetadataCopyrightLayerNestedArchiveCreateInput;
  /** Actions for the "MetadataCopyrightLayer.photo" relation */
  photo: MetadataCopyrightLayerNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCopyrightLayerWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCopyrightLayer.archive" relation */
  archive?: InputMaybe<MetadataCopyrightLayerNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCopyrightLayer.photo" relation */
  photo?: InputMaybe<MetadataCopyrightLayerNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCopyrightLayerWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCopyrightLayer.archive" relation */
  archive: MetadataCopyrightLayerNestedArchiveCreateInput;
  /** Actions for the "MetadataCopyrightLayer.metadata" relation */
  metadata: MetadataCopyrightLayerNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCopyrightLayerWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCopyrightLayer.archive" relation */
  archive?: InputMaybe<MetadataCopyrightLayerNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCopyrightLayer.metadata" relation */
  metadata?: InputMaybe<MetadataCopyrightLayerNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCountry" resource's node */
export type MetadataCountry = {
  __typename?: 'MetadataCountry';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** "MetadataCountryCode" resource's node */
export type MetadataCountryCode = {
  __typename?: 'MetadataCountryCode';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCountryCodeCreateInput = {
  /** Actions for the "MetadataCountryCode.archive" relation */
  archive: MetadataCountryCodeNestedArchiveCreateInput;
  /** Actions for the "MetadataCountryCode.metadata" relation */
  metadata: MetadataCountryCodeNestedMetadataCreateInput;
  /** Actions for the "MetadataCountryCode.photo" relation */
  photo: MetadataCountryCodeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryCodeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCountryCode" node, through the "MetadataCountryCode.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCountryCode" node, through the "MetadataCountryCode.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCountryCode" node, through the "MetadataCountryCode.archive" relation. */
  update?: InputMaybe<MetadataCountryCodeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCountryCode" node, through the "MetadataCountryCode.archive" relation. */
  upsert?: InputMaybe<MetadataCountryCodeNestedUpsertArchiveCreateInput>;
};

export type MetadataCountryCodeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.archive" relation. */
  update?: InputMaybe<MetadataCountryCodeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.archive" relation. */
  upsert?: InputMaybe<MetadataCountryCodeNestedUpsertArchiveUpdateInput>;
};

export type MetadataCountryCodeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCountryCode" node, through the "MetadataCountryCode.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCountryCode" node, through the "MetadataCountryCode.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCountryCode" node, through the "MetadataCountryCode.metadata" relation. */
  update?: InputMaybe<MetadataCountryCodeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCountryCode" node, through the "MetadataCountryCode.metadata" relation. */
  upsert?: InputMaybe<MetadataCountryCodeNestedUpsertMetadataCreateInput>;
};

export type MetadataCountryCodeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.metadata" relation. */
  update?: InputMaybe<MetadataCountryCodeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.metadata" relation. */
  upsert?: InputMaybe<MetadataCountryCodeNestedUpsertMetadataUpdateInput>;
};

export type MetadataCountryCodeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCountryCode" node, through the "MetadataCountryCode.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCountryCode" node, through the "MetadataCountryCode.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCountryCode" node, through the "MetadataCountryCode.photo" relation. */
  update?: InputMaybe<MetadataCountryCodeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCountryCode" node, through the "MetadataCountryCode.photo" relation. */
  upsert?: InputMaybe<MetadataCountryCodeNestedUpsertPhotoCreateInput>;
};

export type MetadataCountryCodeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.photo" relation. */
  update?: InputMaybe<MetadataCountryCodeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCountryCode" node, through the "MetadataCountryCode.photo" relation. */
  upsert?: InputMaybe<MetadataCountryCodeNestedUpsertPhotoUpdateInput>;
};

export type MetadataCountryCodeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCountryCodeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCountryCodeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCountryCodeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCountryCodeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCountryCodeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCountryCodeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCountryCodeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCountryCodeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCountryCodeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCountryCodeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCountryCodeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCountryCodeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCountryCodeUpdateInput = {
  /** Actions for the "MetadataCountryCode.archive" relation */
  archive?: InputMaybe<MetadataCountryCodeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCountryCode.metadata" relation */
  metadata?: InputMaybe<MetadataCountryCodeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCountryCode.photo" relation */
  photo?: InputMaybe<MetadataCountryCodeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCountryCode" nodes by specifying some conditions */
export type MetadataCountryCodeWhereInput = {
  AND?: InputMaybe<Array<MetadataCountryCodeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCountryCodeWhereInput>;
  OR?: InputMaybe<Array<MetadataCountryCodeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCountryCode" node. */
export type MetadataCountryCodeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCountryCodeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCountryCode.metadata" relation */
  metadata: MetadataCountryCodeNestedMetadataCreateInput;
  /** Actions for the "MetadataCountryCode.photo" relation */
  photo: MetadataCountryCodeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryCodeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCountryCode.metadata" relation */
  metadata?: InputMaybe<MetadataCountryCodeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCountryCode.photo" relation */
  photo?: InputMaybe<MetadataCountryCodeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryCodeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCountryCode.archive" relation */
  archive: MetadataCountryCodeNestedArchiveCreateInput;
  /** Actions for the "MetadataCountryCode.photo" relation */
  photo: MetadataCountryCodeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryCodeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCountryCode.archive" relation */
  archive?: InputMaybe<MetadataCountryCodeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCountryCode.photo" relation */
  photo?: InputMaybe<MetadataCountryCodeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryCodeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCountryCode.archive" relation */
  archive: MetadataCountryCodeNestedArchiveCreateInput;
  /** Actions for the "MetadataCountryCode.metadata" relation */
  metadata: MetadataCountryCodeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryCodeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCountryCode.archive" relation */
  archive?: InputMaybe<MetadataCountryCodeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCountryCode.metadata" relation */
  metadata?: InputMaybe<MetadataCountryCodeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryCreateInput = {
  /** Actions for the "MetadataCountry.archive" relation */
  archive: MetadataCountryNestedArchiveCreateInput;
  /** Actions for the "MetadataCountry.metadata" relation */
  metadata: MetadataCountryNestedMetadataCreateInput;
  /** Actions for the "MetadataCountry.photo" relation */
  photo: MetadataCountryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCountry" node, through the "MetadataCountry.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCountry" node, through the "MetadataCountry.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCountry" node, through the "MetadataCountry.archive" relation. */
  update?: InputMaybe<MetadataCountryNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCountry" node, through the "MetadataCountry.archive" relation. */
  upsert?: InputMaybe<MetadataCountryNestedUpsertArchiveCreateInput>;
};

export type MetadataCountryNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCountry" node, through the "MetadataCountry.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCountry" node, through the "MetadataCountry.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCountry" node, through the "MetadataCountry.archive" relation. */
  update?: InputMaybe<MetadataCountryNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCountry" node, through the "MetadataCountry.archive" relation. */
  upsert?: InputMaybe<MetadataCountryNestedUpsertArchiveUpdateInput>;
};

export type MetadataCountryNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCountry" node, through the "MetadataCountry.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCountry" node, through the "MetadataCountry.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCountry" node, through the "MetadataCountry.metadata" relation. */
  update?: InputMaybe<MetadataCountryNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCountry" node, through the "MetadataCountry.metadata" relation. */
  upsert?: InputMaybe<MetadataCountryNestedUpsertMetadataCreateInput>;
};

export type MetadataCountryNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCountry" node, through the "MetadataCountry.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCountry" node, through the "MetadataCountry.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCountry" node, through the "MetadataCountry.metadata" relation. */
  update?: InputMaybe<MetadataCountryNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCountry" node, through the "MetadataCountry.metadata" relation. */
  upsert?: InputMaybe<MetadataCountryNestedUpsertMetadataUpdateInput>;
};

export type MetadataCountryNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCountry" node, through the "MetadataCountry.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCountry" node, through the "MetadataCountry.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCountry" node, through the "MetadataCountry.photo" relation. */
  update?: InputMaybe<MetadataCountryNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCountry" node, through the "MetadataCountry.photo" relation. */
  upsert?: InputMaybe<MetadataCountryNestedUpsertPhotoCreateInput>;
};

export type MetadataCountryNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCountry" node, through the "MetadataCountry.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCountry" node, through the "MetadataCountry.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCountry" node, through the "MetadataCountry.photo" relation. */
  update?: InputMaybe<MetadataCountryNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCountry" node, through the "MetadataCountry.photo" relation. */
  upsert?: InputMaybe<MetadataCountryNestedUpsertPhotoUpdateInput>;
};

export type MetadataCountryNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCountryNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCountryNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCountryNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCountryNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCountryNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCountryNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCountryNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCountryNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCountryNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCountryNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCountryNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCountryOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCountryUpdateInput = {
  /** Actions for the "MetadataCountry.archive" relation */
  archive?: InputMaybe<MetadataCountryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCountry.metadata" relation */
  metadata?: InputMaybe<MetadataCountryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCountry.photo" relation */
  photo?: InputMaybe<MetadataCountryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCountry" nodes by specifying some conditions */
export type MetadataCountryWhereInput = {
  AND?: InputMaybe<Array<MetadataCountryWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCountryWhereInput>;
  OR?: InputMaybe<Array<MetadataCountryWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCountry" node. */
export type MetadataCountryWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCountryWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCountry.metadata" relation */
  metadata: MetadataCountryNestedMetadataCreateInput;
  /** Actions for the "MetadataCountry.photo" relation */
  photo: MetadataCountryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCountry.metadata" relation */
  metadata?: InputMaybe<MetadataCountryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCountry.photo" relation */
  photo?: InputMaybe<MetadataCountryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCountry.archive" relation */
  archive: MetadataCountryNestedArchiveCreateInput;
  /** Actions for the "MetadataCountry.photo" relation */
  photo: MetadataCountryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCountry.archive" relation */
  archive?: InputMaybe<MetadataCountryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCountry.photo" relation */
  photo?: InputMaybe<MetadataCountryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCountry.archive" relation */
  archive: MetadataCountryNestedArchiveCreateInput;
  /** Actions for the "MetadataCountry.metadata" relation */
  metadata: MetadataCountryNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCountryWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCountry.archive" relation */
  archive?: InputMaybe<MetadataCountryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCountry.metadata" relation */
  metadata?: InputMaybe<MetadataCountryNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCreatedTime" resource's node */
export type MetadataCreatedTime = {
  __typename?: 'MetadataCreatedTime';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCreatedTimeCreateInput = {
  /** Actions for the "MetadataCreatedTime.archive" relation */
  archive: MetadataCreatedTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataCreatedTime.metadata" relation */
  metadata: MetadataCreatedTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataCreatedTime.photo" relation */
  photo: MetadataCreatedTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatedTimeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.archive" relation. */
  update?: InputMaybe<MetadataCreatedTimeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.archive" relation. */
  upsert?: InputMaybe<MetadataCreatedTimeNestedUpsertArchiveCreateInput>;
};

export type MetadataCreatedTimeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.archive" relation. */
  update?: InputMaybe<MetadataCreatedTimeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.archive" relation. */
  upsert?: InputMaybe<MetadataCreatedTimeNestedUpsertArchiveUpdateInput>;
};

export type MetadataCreatedTimeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.metadata" relation. */
  update?: InputMaybe<MetadataCreatedTimeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.metadata" relation. */
  upsert?: InputMaybe<MetadataCreatedTimeNestedUpsertMetadataCreateInput>;
};

export type MetadataCreatedTimeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.metadata" relation. */
  update?: InputMaybe<MetadataCreatedTimeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.metadata" relation. */
  upsert?: InputMaybe<MetadataCreatedTimeNestedUpsertMetadataUpdateInput>;
};

export type MetadataCreatedTimeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.photo" relation. */
  update?: InputMaybe<MetadataCreatedTimeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCreatedTime" node, through the "MetadataCreatedTime.photo" relation. */
  upsert?: InputMaybe<MetadataCreatedTimeNestedUpsertPhotoCreateInput>;
};

export type MetadataCreatedTimeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.photo" relation. */
  update?: InputMaybe<MetadataCreatedTimeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCreatedTime" node, through the "MetadataCreatedTime.photo" relation. */
  upsert?: InputMaybe<MetadataCreatedTimeNestedUpsertPhotoUpdateInput>;
};

export type MetadataCreatedTimeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatedTimeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatedTimeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatedTimeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatedTimeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreatedTimeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreatedTimeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatedTimeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatedTimeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatedTimeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatedTimeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreatedTimeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCreatedTimeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCreatedTimeUpdateInput = {
  /** Actions for the "MetadataCreatedTime.archive" relation */
  archive?: InputMaybe<MetadataCreatedTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCreatedTime.metadata" relation */
  metadata?: InputMaybe<MetadataCreatedTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCreatedTime.photo" relation */
  photo?: InputMaybe<MetadataCreatedTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCreatedTime" nodes by specifying some conditions */
export type MetadataCreatedTimeWhereInput = {
  AND?: InputMaybe<Array<MetadataCreatedTimeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCreatedTimeWhereInput>;
  OR?: InputMaybe<Array<MetadataCreatedTimeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCreatedTime" node. */
export type MetadataCreatedTimeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCreatedTimeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCreatedTime.metadata" relation */
  metadata: MetadataCreatedTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataCreatedTime.photo" relation */
  photo: MetadataCreatedTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatedTimeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCreatedTime.metadata" relation */
  metadata?: InputMaybe<MetadataCreatedTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCreatedTime.photo" relation */
  photo?: InputMaybe<MetadataCreatedTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatedTimeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCreatedTime.archive" relation */
  archive: MetadataCreatedTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataCreatedTime.photo" relation */
  photo: MetadataCreatedTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatedTimeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCreatedTime.archive" relation */
  archive?: InputMaybe<MetadataCreatedTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCreatedTime.photo" relation */
  photo?: InputMaybe<MetadataCreatedTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatedTimeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCreatedTime.archive" relation */
  archive: MetadataCreatedTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataCreatedTime.metadata" relation */
  metadata: MetadataCreatedTimeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatedTimeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCreatedTime.archive" relation */
  archive?: InputMaybe<MetadataCreatedTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCreatedTime.metadata" relation */
  metadata?: InputMaybe<MetadataCreatedTimeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreateInput = {
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  label: Scalars['String']['input'];
  legacyName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Metadata.metadataAccessRightsList" relation: "MetadataAccessRights.metadata"'s inverse relation */
  metadataAccessRightsList?: InputMaybe<MetadataNestedMetadataAccessRightsListCreateInput>;
  /** Actions for the "Metadata.metadataAddlModelInfoList" relation: "MetadataAddlModelInfo.metadata"'s inverse relation */
  metadataAddlModelInfoList?: InputMaybe<MetadataNestedMetadataAddlModelInfoListCreateInput>;
  /** Actions for the "Metadata.metadataArchiveChildList" relation: "MetadataArchiveChild.metadata"'s inverse relation */
  metadataArchiveChildList?: InputMaybe<MetadataNestedMetadataArchiveChildListCreateInput>;
  /** Actions for the "Metadata.metadataArchiveParentList" relation: "MetadataArchiveParent.metadata"'s inverse relation */
  metadataArchiveParentList?: InputMaybe<MetadataNestedMetadataArchiveParentListCreateInput>;
  /** Actions for the "Metadata.metadataAssetStateList" relation: "MetadataAssetState.metadata"'s inverse relation */
  metadataAssetStateList?: InputMaybe<MetadataNestedMetadataAssetStateListCreateInput>;
  /** Actions for the "Metadata.metadataAttributionNameList" relation: "MetadataAttributionName.metadata"'s inverse relation */
  metadataAttributionNameList?: InputMaybe<MetadataNestedMetadataAttributionNameListCreateInput>;
  /** Actions for the "Metadata.metadataAttributionUrlList" relation: "MetadataAttributionUrl.metadata"'s inverse relation */
  metadataAttributionUrlList?: InputMaybe<MetadataNestedMetadataAttributionUrlListCreateInput>;
  /** Actions for the "Metadata.metadataAudienceList" relation: "MetadataAudience.metadata"'s inverse relation */
  metadataAudienceList?: InputMaybe<MetadataNestedMetadataAudienceListCreateInput>;
  /** Actions for the "Metadata.metadataAuthorsPositionList" relation: "MetadataAuthorsPosition.metadata"'s inverse relation */
  metadataAuthorsPositionList?: InputMaybe<MetadataNestedMetadataAuthorsPositionListCreateInput>;
  /** Actions for the "Metadata.metadataBackupNameList" relation: "MetadataBackupName.metadata"'s inverse relation */
  metadataBackupNameList?: InputMaybe<MetadataNestedMetadataBackupNameListCreateInput>;
  /** Actions for the "Metadata.metadataBitmapGrayscalePictureList" relation: "MetadataBitmapGrayscalePicture.metadata"'s inverse relation */
  metadataBitmapGrayscalePictureList?: InputMaybe<MetadataNestedMetadataBitmapGrayscalePictureListCreateInput>;
  /** Actions for the "Metadata.metadataBrandList" relation: "MetadataBrand.metadata"'s inverse relation */
  metadataBrandList?: InputMaybe<MetadataNestedMetadataBrandListCreateInput>;
  /** Actions for the "Metadata.metadataBrandPrismaList" relation: "MetadataBrandPrisma.metadata"'s inverse relation */
  metadataBrandPrismaList?: InputMaybe<MetadataNestedMetadataBrandPrismaListCreateInput>;
  /** Actions for the "Metadata.metadataBrandShortNameList" relation: "MetadataBrandShortName.metadata"'s inverse relation */
  metadataBrandShortNameList?: InputMaybe<MetadataNestedMetadataBrandShortNameListCreateInput>;
  /** Actions for the "Metadata.metadataCaptionWriterList" relation: "MetadataCaptionWriter.metadata"'s inverse relation */
  metadataCaptionWriterList?: InputMaybe<MetadataNestedMetadataCaptionWriterListCreateInput>;
  /** Actions for the "Metadata.metadataCategoryList" relation: "MetadataCategory.metadata"'s inverse relation */
  metadataCategoryList?: InputMaybe<MetadataNestedMetadataCategoryListCreateInput>;
  /** Actions for the "Metadata.metadataCategoryPrismaList" relation: "MetadataCategoryPrisma.metadata"'s inverse relation */
  metadataCategoryPrismaList?: InputMaybe<MetadataNestedMetadataCategoryPrismaListCreateInput>;
  /** Actions for the "Metadata.metadataCertificateList" relation: "MetadataCertificate.metadata"'s inverse relation */
  metadataCertificateList?: InputMaybe<MetadataNestedMetadataCertificateListCreateInput>;
  /** Actions for the "Metadata.metadataCiAdrCityList" relation: "MetadataCiAdrCity.metadata"'s inverse relation */
  metadataCiAdrCityList?: InputMaybe<MetadataNestedMetadataCiAdrCityListCreateInput>;
  /** Actions for the "Metadata.metadataCiAdrCtryList" relation: "MetadataCiAdrCtry.metadata"'s inverse relation */
  metadataCiAdrCtryList?: InputMaybe<MetadataNestedMetadataCiAdrCtryListCreateInput>;
  /** Actions for the "Metadata.metadataCiAdrExtadrList" relation: "MetadataCiAdrExtadr.metadata"'s inverse relation */
  metadataCiAdrExtadrList?: InputMaybe<MetadataNestedMetadataCiAdrExtadrListCreateInput>;
  /** Actions for the "Metadata.metadataCiAdrPcodeList" relation: "MetadataCiAdrPcode.metadata"'s inverse relation */
  metadataCiAdrPcodeList?: InputMaybe<MetadataNestedMetadataCiAdrPcodeListCreateInput>;
  /** Actions for the "Metadata.metadataCiAdrRegionList" relation: "MetadataCiAdrRegion.metadata"'s inverse relation */
  metadataCiAdrRegionList?: InputMaybe<MetadataNestedMetadataCiAdrRegionListCreateInput>;
  /** Actions for the "Metadata.metadataCiEmailWorkList" relation: "MetadataCiEmailWork.metadata"'s inverse relation */
  metadataCiEmailWorkList?: InputMaybe<MetadataNestedMetadataCiEmailWorkListCreateInput>;
  /** Actions for the "Metadata.metadataCiTelWorkList" relation: "MetadataCiTelWork.metadata"'s inverse relation */
  metadataCiTelWorkList?: InputMaybe<MetadataNestedMetadataCiTelWorkListCreateInput>;
  /** Actions for the "Metadata.metadataCityList" relation: "MetadataCity.metadata"'s inverse relation */
  metadataCityList?: InputMaybe<MetadataNestedMetadataCityListCreateInput>;
  /** Actions for the "Metadata.metadataCiUrlWorkList" relation: "MetadataCiUrlWork.metadata"'s inverse relation */
  metadataCiUrlWorkList?: InputMaybe<MetadataNestedMetadataCiUrlWorkListCreateInput>;
  /** Actions for the "Metadata.metadataClassifyList" relation: "MetadataClassify.metadata"'s inverse relation */
  metadataClassifyList?: InputMaybe<MetadataNestedMetadataClassifyListCreateInput>;
  /** Actions for the "Metadata.metadataCompanyList" relation: "MetadataCompany.metadata"'s inverse relation */
  metadataCompanyList?: InputMaybe<MetadataNestedMetadataCompanyListCreateInput>;
  /** Actions for the "Metadata.metadataCompanyShortNameList" relation: "MetadataCompanyShortName.metadata"'s inverse relation */
  metadataCompanyShortNameList?: InputMaybe<MetadataNestedMetadataCompanyShortNameListCreateInput>;
  /** Actions for the "Metadata.metadataConfidentialityList" relation: "MetadataConfidentiality.metadata"'s inverse relation */
  metadataConfidentialityList?: InputMaybe<MetadataNestedMetadataConfidentialityListCreateInput>;
  /** Actions for the "Metadata.metadataContainedInList" relation: "MetadataContainedIn.metadata"'s inverse relation */
  metadataContainedInList?: InputMaybe<MetadataNestedMetadataContainedInListCreateInput>;
  /** Actions for the "Metadata.metadataContentMediaKindList" relation: "MetadataContentMediaKind.metadata"'s inverse relation */
  metadataContentMediaKindList?: InputMaybe<MetadataNestedMetadataContentMediaKindListCreateInput>;
  /** Actions for the "Metadata.metadataContentValueList" relation: "MetadataContentValue.metadata"'s inverse relation */
  metadataContentValueList?: InputMaybe<MetadataNestedMetadataContentValueListCreateInput>;
  /** Actions for the "Metadata.metadataCopyrightLayerList" relation: "MetadataCopyrightLayer.metadata"'s inverse relation */
  metadataCopyrightLayerList?: InputMaybe<MetadataNestedMetadataCopyrightLayerListCreateInput>;
  /** Actions for the "Metadata.metadataCountryCodeList" relation: "MetadataCountryCode.metadata"'s inverse relation */
  metadataCountryCodeList?: InputMaybe<MetadataNestedMetadataCountryCodeListCreateInput>;
  /** Actions for the "Metadata.metadataCountryList" relation: "MetadataCountry.metadata"'s inverse relation */
  metadataCountryList?: InputMaybe<MetadataNestedMetadataCountryListCreateInput>;
  /** Actions for the "Metadata.metadataCreatedTimeList" relation: "MetadataCreatedTime.metadata"'s inverse relation */
  metadataCreatedTimeList?: InputMaybe<MetadataNestedMetadataCreatedTimeListCreateInput>;
  /** Actions for the "Metadata.metadataCreatorList" relation: "MetadataCreator.metadata"'s inverse relation */
  metadataCreatorList?: InputMaybe<MetadataNestedMetadataCreatorListCreateInput>;
  /** Actions for the "Metadata.metadataCreatorToolList" relation: "MetadataCreatorTool.metadata"'s inverse relation */
  metadataCreatorToolList?: InputMaybe<MetadataNestedMetadataCreatorToolListCreateInput>;
  /** Actions for the "Metadata.metadataCreditList" relation: "MetadataCredit.metadata"'s inverse relation */
  metadataCreditList?: InputMaybe<MetadataNestedMetadataCreditListCreateInput>;
  /** Actions for the "Metadata.metadataCustomField14List" relation: "MetadataCustomField14.metadata"'s inverse relation */
  metadataCustomField14List?: InputMaybe<MetadataNestedMetadataCustomField14ListCreateInput>;
  /** Actions for the "Metadata.metadataCustomField15List" relation: "MetadataCustomField15.metadata"'s inverse relation */
  metadataCustomField15List?: InputMaybe<MetadataNestedMetadataCustomField15ListCreateInput>;
  /** Actions for the "Metadata.metadataCustomField16List" relation: "MetadataCustomField16.metadata"'s inverse relation */
  metadataCustomField16List?: InputMaybe<MetadataNestedMetadataCustomField16ListCreateInput>;
  /** Actions for the "Metadata.metadataCustomField17List" relation: "MetadataCustomField17.metadata"'s inverse relation */
  metadataCustomField17List?: InputMaybe<MetadataNestedMetadataCustomField17ListCreateInput>;
  /** Actions for the "Metadata.metadataCustomField20List" relation: "MetadataCustomField20.metadata"'s inverse relation */
  metadataCustomField20List?: InputMaybe<MetadataNestedMetadataCustomField20ListCreateInput>;
  /** Actions for the "Metadata.metadataCustomField5List" relation: "MetadataCustomField5.metadata"'s inverse relation */
  metadataCustomField5List?: InputMaybe<MetadataNestedMetadataCustomField5ListCreateInput>;
  /** Actions for the "Metadata.metadataCustomField6List" relation: "MetadataCustomField6.metadata"'s inverse relation */
  metadataCustomField6List?: InputMaybe<MetadataNestedMetadataCustomField6ListCreateInput>;
  /** Actions for the "Metadata.metadataCustomField7List" relation: "MetadataCustomField7.metadata"'s inverse relation */
  metadataCustomField7List?: InputMaybe<MetadataNestedMetadataCustomField7ListCreateInput>;
  /** Actions for the "Metadata.metadataCustomField9List" relation: "MetadataCustomField9.metadata"'s inverse relation */
  metadataCustomField9List?: InputMaybe<MetadataNestedMetadataCustomField9ListCreateInput>;
  /** Actions for the "Metadata.metadataDateCreatedList" relation: "MetadataDateCreated.metadata"'s inverse relation */
  metadataDateCreatedList?: InputMaybe<MetadataNestedMetadataDateCreatedListCreateInput>;
  /** Actions for the "Metadata.metadataDateTimeDigitizedList" relation: "MetadataDateTimeDigitized.metadata"'s inverse relation */
  metadataDateTimeDigitizedList?: InputMaybe<MetadataNestedMetadataDateTimeDigitizedListCreateInput>;
  /** Actions for the "Metadata.metadataDateTimeOriginalList" relation: "MetadataDateTimeOriginal.metadata"'s inverse relation */
  metadataDateTimeOriginalList?: InputMaybe<MetadataNestedMetadataDateTimeOriginalListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryAccountList" relation: "MetadataDeliveryAccount.metadata"'s inverse relation */
  metadataDeliveryAccountList?: InputMaybe<MetadataNestedMetadataDeliveryAccountListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryCompanyList" relation: "MetadataDeliveryCompany.metadata"'s inverse relation */
  metadataDeliveryCompanyList?: InputMaybe<MetadataNestedMetadataDeliveryCompanyListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryCopyrightList" relation: "MetadataDeliveryCopyright.metadata"'s inverse relation */
  metadataDeliveryCopyrightList?: InputMaybe<MetadataNestedMetadataDeliveryCopyrightListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryDateFolderList" relation: "MetadataDeliveryDateFolder.metadata"'s inverse relation */
  metadataDeliveryDateFolderList?: InputMaybe<MetadataNestedMetadataDeliveryDateFolderListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryDateTimeList" relation: "MetadataDeliveryDateTime.metadata"'s inverse relation */
  metadataDeliveryDateTimeList?: InputMaybe<MetadataNestedMetadataDeliveryDateTimeListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryDcCreatorList" relation: "MetadataDeliveryDcCreator.metadata"'s inverse relation */
  metadataDeliveryDcCreatorList?: InputMaybe<MetadataNestedMetadataDeliveryDcCreatorListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryDcRightsList" relation: "MetadataDeliveryDcRights.metadata"'s inverse relation */
  metadataDeliveryDcRightsList?: InputMaybe<MetadataNestedMetadataDeliveryDcRightsListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryFileTypeList" relation: "MetadataDeliveryFileType.metadata"'s inverse relation */
  metadataDeliveryFileTypeList?: InputMaybe<MetadataNestedMetadataDeliveryFileTypeListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryFolderList" relation: "MetadataDeliveryFolder.metadata"'s inverse relation */
  metadataDeliveryFolderList?: InputMaybe<MetadataNestedMetadataDeliveryFolderListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryKindList" relation: "MetadataDeliveryKind.metadata"'s inverse relation */
  metadataDeliveryKindList?: InputMaybe<MetadataNestedMetadataDeliveryKindListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryPathList" relation: "MetadataDeliveryPath.metadata"'s inverse relation */
  metadataDeliveryPathList?: InputMaybe<MetadataNestedMetadataDeliveryPathListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryPersonShownintheImageList" relation: "MetadataDeliveryPersonShownintheImage.metadata"'s inverse relation */
  metadataDeliveryPersonShownintheImageList?: InputMaybe<MetadataNestedMetadataDeliveryPersonShownintheImageListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryPhotoshopCreditList" relation: "MetadataDeliveryPhotoshopCredit.metadata"'s inverse relation */
  metadataDeliveryPhotoshopCreditList?: InputMaybe<MetadataNestedMetadataDeliveryPhotoshopCreditListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryPhotoshopSourceList" relation: "MetadataDeliveryPhotoshopSource.metadata"'s inverse relation */
  metadataDeliveryPhotoshopSourceList?: InputMaybe<MetadataNestedMetadataDeliveryPhotoshopSourceListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryServiceList" relation: "MetadataDeliveryService.metadata"'s inverse relation */
  metadataDeliveryServiceList?: InputMaybe<MetadataNestedMetadataDeliveryServiceListCreateInput>;
  /** Actions for the "Metadata.metadataDeliverySubjectList" relation: "MetadataDeliverySubject.metadata"'s inverse relation */
  metadataDeliverySubjectList?: InputMaybe<MetadataNestedMetadataDeliverySubjectListCreateInput>;
  /** Actions for the "Metadata.metadataDeliveryUnderSubjectList" relation: "MetadataDeliveryUnderSubject.metadata"'s inverse relation */
  metadataDeliveryUnderSubjectList?: InputMaybe<MetadataNestedMetadataDeliveryUnderSubjectListCreateInput>;
  /** Actions for the "Metadata.metadataDepartmentList" relation: "MetadataDepartment.metadata"'s inverse relation */
  metadataDepartmentList?: InputMaybe<MetadataNestedMetadataDepartmentListCreateInput>;
  /** Actions for the "Metadata.metadataDescriptionList" relation: "MetadataDescription.metadata"'s inverse relation */
  metadataDescriptionList?: InputMaybe<MetadataNestedMetadataDescriptionListCreateInput>;
  /** Actions for the "Metadata.metadataDigitalAssetUrlList" relation: "MetadataDigitalAssetUrl.metadata"'s inverse relation */
  metadataDigitalAssetUrlList?: InputMaybe<MetadataNestedMetadataDigitalAssetUrlListCreateInput>;
  /** Actions for the "Metadata.metadataDivisionList" relation: "MetadataDivision.metadata"'s inverse relation */
  metadataDivisionList?: InputMaybe<MetadataNestedMetadataDivisionListCreateInput>;
  /** Actions for the "Metadata.metadataDocumentTextList" relation: "MetadataDocumentText.metadata"'s inverse relation */
  metadataDocumentTextList?: InputMaybe<MetadataNestedMetadataDocumentTextListCreateInput>;
  /** Actions for the "Metadata.metadataEditionList" relation: "MetadataEdition.metadata"'s inverse relation */
  metadataEditionList?: InputMaybe<MetadataNestedMetadataEditionListCreateInput>;
  /** Actions for the "Metadata.metadataEditorialVersionList" relation: "MetadataEditorialVersion.metadata"'s inverse relation */
  metadataEditorialVersionList?: InputMaybe<MetadataNestedMetadataEditorialVersionListCreateInput>;
  /** Actions for the "Metadata.metadataEditStatusList" relation: "MetadataEditStatus.metadata"'s inverse relation */
  metadataEditStatusList?: InputMaybe<MetadataNestedMetadataEditStatusListCreateInput>;
  /** Actions for the "Metadata.metadataEnvironnementPhotoList" relation: "MetadataEnvironnementPhoto.metadata"'s inverse relation */
  metadataEnvironnementPhotoList?: InputMaybe<MetadataNestedMetadataEnvironnementPhotoListCreateInput>;
  /** Actions for the "Metadata.metadataEquipmentInstitutionList" relation: "MetadataEquipmentInstitution.metadata"'s inverse relation */
  metadataEquipmentInstitutionList?: InputMaybe<MetadataNestedMetadataEquipmentInstitutionListCreateInput>;
  /** Actions for the "Metadata.metadataEquipmentManufacturerList" relation: "MetadataEquipmentManufacturer.metadata"'s inverse relation */
  metadataEquipmentManufacturerList?: InputMaybe<MetadataNestedMetadataEquipmentManufacturerListCreateInput>;
  /** Actions for the "Metadata.metadataEventList" relation: "MetadataEvent.metadata"'s inverse relation */
  metadataEventList?: InputMaybe<MetadataNestedMetadataEventListCreateInput>;
  /** Actions for the "Metadata.metadataExifList" relation: "MetadataExif.metadata"'s inverse relation */
  metadataExifList?: InputMaybe<MetadataNestedMetadataExifListCreateInput>;
  /** Actions for the "Metadata.metadataFabStorageList" relation: "MetadataFabStorage.metadata"'s inverse relation */
  metadataFabStorageList?: InputMaybe<MetadataNestedMetadataFabStorageListCreateInput>;
  /** Actions for the "Metadata.metadataFileCheckForList" relation: "MetadataFileCheckFor.metadata"'s inverse relation */
  metadataFileCheckForList?: InputMaybe<MetadataNestedMetadataFileCheckForListCreateInput>;
  /** Actions for the "Metadata.metadataFileInfoBitsPerPixelList" relation: "MetadataFileInfoBitsPerPixel.metadata"'s inverse relation */
  metadataFileInfoBitsPerPixelList?: InputMaybe<MetadataNestedMetadataFileInfoBitsPerPixelListCreateInput>;
  /** Actions for the "Metadata.metadataFileInfoFramesList" relation: "MetadataFileInfoFrames.metadata"'s inverse relation */
  metadataFileInfoFramesList?: InputMaybe<MetadataNestedMetadataFileInfoFramesListCreateInput>;
  /** Actions for the "Metadata.metadataFileInfoPhotometricInterpretationList" relation: "MetadataFileInfoPhotometricInterpretation.metadata"'s inverse relation */
  metadataFileInfoPhotometricInterpretationList?: InputMaybe<MetadataNestedMetadataFileInfoPhotometricInterpretationListCreateInput>;
  /** Actions for the "Metadata.metadataFileInfoTransferSyntaxList" relation: "MetadataFileInfoTransferSyntax.metadata"'s inverse relation */
  metadataFileInfoTransferSyntaxList?: InputMaybe<MetadataNestedMetadataFileInfoTransferSyntaxListCreateInput>;
  /** Actions for the "Metadata.metadataFileStageList" relation: "MetadataFileStage.metadata"'s inverse relation */
  metadataFileStageList?: InputMaybe<MetadataNestedMetadataFileStageListCreateInput>;
  /** Actions for the "Metadata.metadataFileStatusList" relation: "MetadataFileStatus.metadata"'s inverse relation */
  metadataFileStatusList?: InputMaybe<MetadataNestedMetadataFileStatusListCreateInput>;
  /** Actions for the "Metadata.metadataFileTypeList" relation: "MetadataFileType.metadata"'s inverse relation */
  metadataFileTypeList?: InputMaybe<MetadataNestedMetadataFileTypeListCreateInput>;
  /** Actions for the "Metadata.metadataFixtureIdentifierList" relation: "MetadataFixtureIdentifier.metadata"'s inverse relation */
  metadataFixtureIdentifierList?: InputMaybe<MetadataNestedMetadataFixtureIdentifierListCreateInput>;
  /** Actions for the "Metadata.metadataFormatList" relation: "MetadataFormat.metadata"'s inverse relation */
  metadataFormatList?: InputMaybe<MetadataNestedMetadataFormatListCreateInput>;
  /** Actions for the "Metadata.metadataGpsLatitudeList" relation: "MetadataGpsLatitude.metadata"'s inverse relation */
  metadataGpsLatitudeList?: InputMaybe<MetadataNestedMetadataGpsLatitudeListCreateInput>;
  /** Actions for the "Metadata.metadataGpsLongitudeList" relation: "MetadataGpsLongitude.metadata"'s inverse relation */
  metadataGpsLongitudeList?: InputMaybe<MetadataNestedMetadataGpsLongitudeListCreateInput>;
  /** Actions for the "Metadata.metadataGpsProcessingMethodList" relation: "MetadataGpsProcessingMethod.metadata"'s inverse relation */
  metadataGpsProcessingMethodList?: InputMaybe<MetadataNestedMetadataGpsProcessingMethodListCreateInput>;
  /** Actions for the "Metadata.metadataHeaderList" relation: "MetadataHeader.metadata"'s inverse relation */
  metadataHeaderList?: InputMaybe<MetadataNestedMetadataHeaderListCreateInput>;
  /** Actions for the "Metadata.metadataHeaderPrismaList" relation: "MetadataHeaderPrisma.metadata"'s inverse relation */
  metadataHeaderPrismaList?: InputMaybe<MetadataNestedMetadataHeaderPrismaListCreateInput>;
  /** Actions for the "Metadata.metadataHeadlineList" relation: "MetadataHeadline.metadata"'s inverse relation */
  metadataHeadlineList?: InputMaybe<MetadataNestedMetadataHeadlineListCreateInput>;
  /** Actions for the "Metadata.metadataHistoryList" relation: "MetadataHistory.metadata"'s inverse relation */
  metadataHistoryList?: InputMaybe<MetadataNestedMetadataHistoryListCreateInput>;
  /** Actions for the "Metadata.metadataIccProfileList" relation: "MetadataIccProfile.metadata"'s inverse relation */
  metadataIccProfileList?: InputMaybe<MetadataNestedMetadataIccProfileListCreateInput>;
  /** Actions for the "Metadata.metadataIdAssignmentList" relation: "MetadataIdAssignment.metadata"'s inverse relation */
  metadataIdAssignmentList?: InputMaybe<MetadataNestedMetadataIdAssignmentListCreateInput>;
  /** Actions for the "Metadata.metadataIdMediaContentList" relation: "MetadataIdMediaContent.metadata"'s inverse relation */
  metadataIdMediaContentList?: InputMaybe<MetadataNestedMetadataIdMediaContentListCreateInput>;
  /** Actions for the "Metadata.metadataIdPhotoPrismaList" relation: "MetadataIdPhotoPrisma.metadata"'s inverse relation */
  metadataIdPhotoPrismaList?: InputMaybe<MetadataNestedMetadataIdPhotoPrismaListCreateInput>;
  /** Actions for the "Metadata.metadataIdSubjectList" relation: "MetadataIdSubject.metadata"'s inverse relation */
  metadataIdSubjectList?: InputMaybe<MetadataNestedMetadataIdSubjectListCreateInput>;
  /** Actions for the "Metadata.metadataImageNotesList" relation: "MetadataImageNotes.metadata"'s inverse relation */
  metadataImageNotesList?: InputMaybe<MetadataNestedMetadataImageNotesListCreateInput>;
  /** Actions for the "Metadata.metadataImageUniqueIdList" relation: "MetadataImageUniqueId.metadata"'s inverse relation */
  metadataImageUniqueIdList?: InputMaybe<MetadataNestedMetadataImageUniqueIdListCreateInput>;
  /** Actions for the "Metadata.metadataInstructionsList" relation: "MetadataInstructions.metadata"'s inverse relation */
  metadataInstructionsList?: InputMaybe<MetadataNestedMetadataInstructionsListCreateInput>;
  /** Actions for the "Metadata.metadataIntellectualGenreList" relation: "MetadataIntellectualGenre.metadata"'s inverse relation */
  metadataIntellectualGenreList?: InputMaybe<MetadataNestedMetadataIntellectualGenreListCreateInput>;
  /** Actions for the "Metadata.metadataIsoSpeedRatingsList" relation: "MetadataIsoSpeedRatings.metadata"'s inverse relation */
  metadataIsoSpeedRatingsList?: InputMaybe<MetadataNestedMetadataIsoSpeedRatingsListCreateInput>;
  /** Actions for the "Metadata.metadataIssueList" relation: "MetadataIssue.metadata"'s inverse relation */
  metadataIssueList?: InputMaybe<MetadataNestedMetadataIssueListCreateInput>;
  /** Actions for the "Metadata.metadataJobIdList" relation: "MetadataJobId.metadata"'s inverse relation */
  metadataJobIdList?: InputMaybe<MetadataNestedMetadataJobIdListCreateInput>;
  /** Actions for the "Metadata.metadataJobProcessingList" relation: "MetadataJobProcessing.metadata"'s inverse relation */
  metadataJobProcessingList?: InputMaybe<MetadataNestedMetadataJobProcessingListCreateInput>;
  /** Actions for the "Metadata.metadataLayoutPictureEditingList" relation: "MetadataLayoutPictureEditing.metadata"'s inverse relation */
  metadataLayoutPictureEditingList?: InputMaybe<MetadataNestedMetadataLayoutPictureEditingListCreateInput>;
  /** Actions for the "Metadata.metadataLayoutStorageList" relation: "MetadataLayoutStorage.metadata"'s inverse relation */
  metadataLayoutStorageList?: InputMaybe<MetadataNestedMetadataLayoutStorageListCreateInput>;
  /** Actions for the "Metadata.metadataLicenseList" relation: "MetadataLicense.metadata"'s inverse relation */
  metadataLicenseList?: InputMaybe<MetadataNestedMetadataLicenseListCreateInput>;
  /** Actions for the "Metadata.metadataLinksList" relation: "MetadataLinks.metadata"'s inverse relation */
  metadataLinksList?: InputMaybe<MetadataNestedMetadataLinksListCreateInput>;
  /** Actions for the "Metadata.metadataLocalCaptionList" relation: "MetadataLocalCaption.metadata"'s inverse relation */
  metadataLocalCaptionList?: InputMaybe<MetadataNestedMetadataLocalCaptionListCreateInput>;
  /** Actions for the "Metadata.metadataLocationList" relation: "MetadataLocation.metadata"'s inverse relation */
  metadataLocationList?: InputMaybe<MetadataNestedMetadataLocationListCreateInput>;
  /** Actions for the "Metadata.metadataMakeList" relation: "MetadataMake.metadata"'s inverse relation */
  metadataMakeList?: InputMaybe<MetadataNestedMetadataMakeListCreateInput>;
  /** Actions for the "Metadata.metadataManifestList" relation: "MetadataManifest.metadata"'s inverse relation */
  metadataManifestList?: InputMaybe<MetadataNestedMetadataManifestListCreateInput>;
  /** Actions for the "Metadata.metadataMarkedList" relation: "MetadataMarked.metadata"'s inverse relation */
  metadataMarkedList?: InputMaybe<MetadataNestedMetadataMarkedListCreateInput>;
  /** Actions for the "Metadata.metadataMasterDocumentIdList" relation: "MetadataMasterDocumentId.metadata"'s inverse relation */
  metadataMasterDocumentIdList?: InputMaybe<MetadataNestedMetadataMasterDocumentIdListCreateInput>;
  /** Actions for the "Metadata.metadataMaxAvailHeightList" relation: "MetadataMaxAvailHeight.metadata"'s inverse relation */
  metadataMaxAvailHeightList?: InputMaybe<MetadataNestedMetadataMaxAvailHeightListCreateInput>;
  /** Actions for the "Metadata.metadataMaxAvailWidthList" relation: "MetadataMaxAvailWidth.metadata"'s inverse relation */
  metadataMaxAvailWidthList?: InputMaybe<MetadataNestedMetadataMaxAvailWidthListCreateInput>;
  /** Actions for the "Metadata.metadataMinorModelAgeDisclosureList" relation: "MetadataMinorModelAgeDisclosure.metadata"'s inverse relation */
  metadataMinorModelAgeDisclosureList?: InputMaybe<MetadataNestedMetadataMinorModelAgeDisclosureListCreateInput>;
  /** Actions for the "Metadata.metadataModelAgeList" relation: "MetadataModelAge.metadata"'s inverse relation */
  metadataModelAgeList?: InputMaybe<MetadataNestedMetadataModelAgeListCreateInput>;
  /** Actions for the "Metadata.metadataModelList" relation: "MetadataModel.metadata"'s inverse relation */
  metadataModelList?: InputMaybe<MetadataNestedMetadataModelListCreateInput>;
  /** Actions for the "Metadata.metadataModelReleaseIdList" relation: "MetadataModelReleaseId.metadata"'s inverse relation */
  metadataModelReleaseIdList?: InputMaybe<MetadataNestedMetadataModelReleaseIdListCreateInput>;
  /** Actions for the "Metadata.metadataModelReleaseStatusList" relation: "MetadataModelReleaseStatus.metadata"'s inverse relation */
  metadataModelReleaseStatusList?: InputMaybe<MetadataNestedMetadataModelReleaseStatusListCreateInput>;
  /** Actions for the "Metadata.metadataMorePermissionsList" relation: "MetadataMorePermissions.metadata"'s inverse relation */
  metadataMorePermissionsList?: InputMaybe<MetadataNestedMetadataMorePermissionsListCreateInput>;
  /** Actions for the "Metadata.metadataObjectCycleList" relation: "MetadataObjectCycle.metadata"'s inverse relation */
  metadataObjectCycleList?: InputMaybe<MetadataNestedMetadataObjectCycleListCreateInput>;
  /** Actions for the "Metadata.metadataOrganisationInImageNameList" relation: "MetadataOrganisationInImageName.metadata"'s inverse relation */
  metadataOrganisationInImageNameList?: InputMaybe<MetadataNestedMetadataOrganisationInImageNameListCreateInput>;
  /** Actions for the "Metadata.metadataOriginalColorList" relation: "MetadataOriginalColor.metadata"'s inverse relation */
  metadataOriginalColorList?: InputMaybe<MetadataNestedMetadataOriginalColorListCreateInput>;
  /** Actions for the "Metadata.metadataOriginalIccProfileList" relation: "MetadataOriginalIccProfile.metadata"'s inverse relation */
  metadataOriginalIccProfileList?: InputMaybe<MetadataNestedMetadataOriginalIccProfileListCreateInput>;
  /** Actions for the "Metadata.metadataOriginalNameList" relation: "MetadataOriginalName.metadata"'s inverse relation */
  metadataOriginalNameList?: InputMaybe<MetadataNestedMetadataOriginalNameListCreateInput>;
  /** Actions for the "Metadata.metadataOriginalWeigthList" relation: "MetadataOriginalWeigth.metadata"'s inverse relation */
  metadataOriginalWeigthList?: InputMaybe<MetadataNestedMetadataOriginalWeigthListCreateInput>;
  /** Actions for the "Metadata.metadataOwnerIdList" relation: "MetadataOwnerId.metadata"'s inverse relation */
  metadataOwnerIdList?: InputMaybe<MetadataNestedMetadataOwnerIdListCreateInput>;
  /** Actions for the "Metadata.metadataOwnerList" relation: "MetadataOwner.metadata"'s inverse relation */
  metadataOwnerList?: InputMaybe<MetadataNestedMetadataOwnerListCreateInput>;
  /** Actions for the "Metadata.metadataPageList" relation: "MetadataPage.metadata"'s inverse relation */
  metadataPageList?: InputMaybe<MetadataNestedMetadataPageListCreateInput>;
  /** Actions for the "Metadata.metadataPagePrismaList" relation: "MetadataPagePrisma.metadata"'s inverse relation */
  metadataPagePrismaList?: InputMaybe<MetadataNestedMetadataPagePrismaListCreateInput>;
  /** Actions for the "Metadata.metadataPaintBasedCorrectionsList" relation: "MetadataPaintBasedCorrections.metadata"'s inverse relation */
  metadataPaintBasedCorrectionsList?: InputMaybe<MetadataNestedMetadataPaintBasedCorrectionsListCreateInput>;
  /** Actions for the "Metadata.metadataPatientDobList" relation: "MetadataPatientDob.metadata"'s inverse relation */
  metadataPatientDobList?: InputMaybe<MetadataNestedMetadataPatientDobListCreateInput>;
  /** Actions for the "Metadata.metadataPatientIdList" relation: "MetadataPatientId.metadata"'s inverse relation */
  metadataPatientIdList?: InputMaybe<MetadataNestedMetadataPatientIdListCreateInput>;
  /** Actions for the "Metadata.metadataPatientNameList" relation: "MetadataPatientName.metadata"'s inverse relation */
  metadataPatientNameList?: InputMaybe<MetadataNestedMetadataPatientNameListCreateInput>;
  /** Actions for the "Metadata.metadataPatientSexList" relation: "MetadataPatientSex.metadata"'s inverse relation */
  metadataPatientSexList?: InputMaybe<MetadataNestedMetadataPatientSexListCreateInput>;
  /** Actions for the "Metadata.metadataPersonInImageList" relation: "MetadataPersonInImage.metadata"'s inverse relation */
  metadataPersonInImageList?: InputMaybe<MetadataNestedMetadataPersonInImageListCreateInput>;
  /** Actions for the "Metadata.metadataPhotoStorageList" relation: "MetadataPhotoStorage.metadata"'s inverse relation */
  metadataPhotoStorageList?: InputMaybe<MetadataNestedMetadataPhotoStorageListCreateInput>;
  /** Actions for the "Metadata.metadataPrepressPictureEditingList" relation: "MetadataPrepressPictureEditing.metadata"'s inverse relation */
  metadataPrepressPictureEditingList?: InputMaybe<MetadataNestedMetadataPrepressPictureEditingListCreateInput>;
  /** Actions for the "Metadata.metadataPriceLevelList" relation: "MetadataPriceLevel.metadata"'s inverse relation */
  metadataPriceLevelList?: InputMaybe<MetadataNestedMetadataPriceLevelListCreateInput>;
  /** Actions for the "Metadata.metadataPrintingProfileList" relation: "MetadataPrintingProfile.metadata"'s inverse relation */
  metadataPrintingProfileList?: InputMaybe<MetadataNestedMetadataPrintingProfileListCreateInput>;
  /** Actions for the "Metadata.metadataPrismaProductionList" relation: "MetadataPrismaProduction.metadata"'s inverse relation */
  metadataPrismaProductionList?: InputMaybe<MetadataNestedMetadataPrismaProductionListCreateInput>;
  /** Actions for the "Metadata.metadataProcessHistoryList" relation: "MetadataProcessHistory.metadata"'s inverse relation */
  metadataProcessHistoryList?: InputMaybe<MetadataNestedMetadataProcessHistoryListCreateInput>;
  /** Actions for the "Metadata.metadataProcessParameterList" relation: "MetadataProcessParameter.metadata"'s inverse relation */
  metadataProcessParameterList?: InputMaybe<MetadataNestedMetadataProcessParameterListCreateInput>;
  /** Actions for the "Metadata.metadataProcessStatusList" relation: "MetadataProcessStatus.metadata"'s inverse relation */
  metadataProcessStatusList?: InputMaybe<MetadataNestedMetadataProcessStatusListCreateInput>;
  /** Actions for the "Metadata.metadataProductList" relation: "MetadataProduct.metadata"'s inverse relation */
  metadataProductList?: InputMaybe<MetadataNestedMetadataProductListCreateInput>;
  /** Actions for the "Metadata.metadataProductShortNameList" relation: "MetadataProductShortName.metadata"'s inverse relation */
  metadataProductShortNameList?: InputMaybe<MetadataNestedMetadataProductShortNameListCreateInput>;
  /** Actions for the "Metadata.metadataProductsList" relation: "MetadataProducts.metadata"'s inverse relation */
  metadataProductsList?: InputMaybe<MetadataNestedMetadataProductsListCreateInput>;
  /** Actions for the "Metadata.metadataProduitsList" relation: "MetadataProduits.metadata"'s inverse relation */
  metadataProduitsList?: InputMaybe<MetadataNestedMetadataProduitsListCreateInput>;
  /** Actions for the "Metadata.metadataProgramVersionList" relation: "MetadataProgramVersion.metadata"'s inverse relation */
  metadataProgramVersionList?: InputMaybe<MetadataNestedMetadataProgramVersionListCreateInput>;
  /** Actions for the "Metadata.metadataPropertyReleaseIdList" relation: "MetadataPropertyReleaseId.metadata"'s inverse relation */
  metadataPropertyReleaseIdList?: InputMaybe<MetadataNestedMetadataPropertyReleaseIdListCreateInput>;
  /** Actions for the "Metadata.metadataPropertyReleaseStatusList" relation: "MetadataPropertyReleaseStatus.metadata"'s inverse relation */
  metadataPropertyReleaseStatusList?: InputMaybe<MetadataNestedMetadataPropertyReleaseStatusListCreateInput>;
  /** Actions for the "Metadata.metadataPublisherList" relation: "MetadataPublisher.metadata"'s inverse relation */
  metadataPublisherList?: InputMaybe<MetadataNestedMetadataPublisherListCreateInput>;
  /** Actions for the "Metadata.metadataPublishingIssueList" relation: "MetadataPublishingIssue.metadata"'s inverse relation */
  metadataPublishingIssueList?: InputMaybe<MetadataNestedMetadataPublishingIssueListCreateInput>;
  /** Actions for the "Metadata.metadataPublishingSubjectList" relation: "MetadataPublishingSubject.metadata"'s inverse relation */
  metadataPublishingSubjectList?: InputMaybe<MetadataNestedMetadataPublishingSubjectListCreateInput>;
  /** Actions for the "Metadata.metadataQualifiedUsePrismaByList" relation: "MetadataQualifiedUsePrismaBy.metadata"'s inverse relation */
  metadataQualifiedUsePrismaByList?: InputMaybe<MetadataNestedMetadataQualifiedUsePrismaByListCreateInput>;
  /** Actions for the "Metadata.metadataQualifiedUsePrismaDateList" relation: "MetadataQualifiedUsePrismaDate.metadata"'s inverse relation */
  metadataQualifiedUsePrismaDateList?: InputMaybe<MetadataNestedMetadataQualifiedUsePrismaDateListCreateInput>;
  /** Actions for the "Metadata.metadataQualifiedUsePrismaDurationList" relation: "MetadataQualifiedUsePrismaDuration.metadata"'s inverse relation */
  metadataQualifiedUsePrismaDurationList?: InputMaybe<MetadataNestedMetadataQualifiedUsePrismaDurationListCreateInput>;
  /** Actions for the "Metadata.metadataQualifiedUsePrismaSupportList" relation: "MetadataQualifiedUsePrismaSupport.metadata"'s inverse relation */
  metadataQualifiedUsePrismaSupportList?: InputMaybe<MetadataNestedMetadataQualifiedUsePrismaSupportListCreateInput>;
  /** Actions for the "Metadata.metadataRatingList" relation: "MetadataRating.metadata"'s inverse relation */
  metadataRatingList?: InputMaybe<MetadataNestedMetadataRatingListCreateInput>;
  /** Actions for the "Metadata.metadataReferenceDateList" relation: "MetadataReferenceDate.metadata"'s inverse relation */
  metadataReferenceDateList?: InputMaybe<MetadataNestedMetadataReferenceDateListCreateInput>;
  /** Actions for the "Metadata.metadataReferenceNumberList" relation: "MetadataReferenceNumber.metadata"'s inverse relation */
  metadataReferenceNumberList?: InputMaybe<MetadataNestedMetadataReferenceNumberListCreateInput>;
  /** Actions for the "Metadata.metadataReferenceServiceList" relation: "MetadataReferenceService.metadata"'s inverse relation */
  metadataReferenceServiceList?: InputMaybe<MetadataNestedMetadataReferenceServiceListCreateInput>;
  /** Actions for the "Metadata.metadataReleaseDateList" relation: "MetadataReleaseDate.metadata"'s inverse relation */
  metadataReleaseDateList?: InputMaybe<MetadataNestedMetadataReleaseDateListCreateInput>;
  /** Actions for the "Metadata.metadataReleaseTimeList" relation: "MetadataReleaseTime.metadata"'s inverse relation */
  metadataReleaseTimeList?: InputMaybe<MetadataNestedMetadataReleaseTimeListCreateInput>;
  /** Actions for the "Metadata.metadataRequiredPermissionList" relation: "MetadataRequiredPermission.metadata"'s inverse relation */
  metadataRequiredPermissionList?: InputMaybe<MetadataNestedMetadataRequiredPermissionListCreateInput>;
  /** Actions for the "Metadata.metadataResolutionKindList" relation: "MetadataResolutionKind.metadata"'s inverse relation */
  metadataResolutionKindList?: InputMaybe<MetadataNestedMetadataResolutionKindListCreateInput>;
  /** Actions for the "Metadata.metadataRightsList" relation: "MetadataRights.metadata"'s inverse relation */
  metadataRightsList?: InputMaybe<MetadataNestedMetadataRightsListCreateInput>;
  /** Actions for the "Metadata.metadataRoyaltyfreeList" relation: "MetadataRoyaltyfree.metadata"'s inverse relation */
  metadataRoyaltyfreeList?: InputMaybe<MetadataNestedMetadataRoyaltyfreeListCreateInput>;
  /** Actions for the "Metadata.metadataSceneList" relation: "MetadataScene.metadata"'s inverse relation */
  metadataSceneList?: InputMaybe<MetadataNestedMetadataSceneListCreateInput>;
  /** Actions for the "Metadata.metadataSectionList" relation: "MetadataSection.metadata"'s inverse relation */
  metadataSectionList?: InputMaybe<MetadataNestedMetadataSectionListCreateInput>;
  /** Actions for the "Metadata.metadataSentToList" relation: "MetadataSentTo.metadata"'s inverse relation */
  metadataSentToList?: InputMaybe<MetadataNestedMetadataSentToListCreateInput>;
  /** Actions for the "Metadata.metadataSerialNumberList" relation: "MetadataSerialNumber.metadata"'s inverse relation */
  metadataSerialNumberList?: InputMaybe<MetadataNestedMetadataSerialNumberListCreateInput>;
  /** Actions for the "Metadata.metadataSeriesDateTimeList" relation: "MetadataSeriesDateTime.metadata"'s inverse relation */
  metadataSeriesDateTimeList?: InputMaybe<MetadataNestedMetadataSeriesDateTimeListCreateInput>;
  /** Actions for the "Metadata.metadataSeriesDescriptionList" relation: "MetadataSeriesDescription.metadata"'s inverse relation */
  metadataSeriesDescriptionList?: InputMaybe<MetadataNestedMetadataSeriesDescriptionListCreateInput>;
  /** Actions for the "Metadata.metadataSeriesModalityList" relation: "MetadataSeriesModality.metadata"'s inverse relation */
  metadataSeriesModalityList?: InputMaybe<MetadataNestedMetadataSeriesModalityListCreateInput>;
  /** Actions for the "Metadata.metadataSeriesNumberList" relation: "MetadataSeriesNumber.metadata"'s inverse relation */
  metadataSeriesNumberList?: InputMaybe<MetadataNestedMetadataSeriesNumberListCreateInput>;
  /** Actions for the "Metadata.metadataShortUniqueIdList" relation: "MetadataShortUniqueId.metadata"'s inverse relation */
  metadataShortUniqueIdList?: InputMaybe<MetadataNestedMetadataShortUniqueIdListCreateInput>;
  /** Actions for the "Metadata.metadataSourceList" relation: "MetadataSource.metadata"'s inverse relation */
  metadataSourceList?: InputMaybe<MetadataNestedMetadataSourceListCreateInput>;
  /** Actions for the "Metadata.metadataStateList" relation: "MetadataState.metadata"'s inverse relation */
  metadataStateList?: InputMaybe<MetadataNestedMetadataStateListCreateInput>;
  /** Actions for the "Metadata.metadataStudyDateTimeList" relation: "MetadataStudyDateTime.metadata"'s inverse relation */
  metadataStudyDateTimeList?: InputMaybe<MetadataNestedMetadataStudyDateTimeListCreateInput>;
  /** Actions for the "Metadata.metadataStudyDescriptionList" relation: "MetadataStudyDescription.metadata"'s inverse relation */
  metadataStudyDescriptionList?: InputMaybe<MetadataNestedMetadataStudyDescriptionListCreateInput>;
  /** Actions for the "Metadata.metadataStudyIdList" relation: "MetadataStudyId.metadata"'s inverse relation */
  metadataStudyIdList?: InputMaybe<MetadataNestedMetadataStudyIdListCreateInput>;
  /** Actions for the "Metadata.metadataStudyPhysicianList" relation: "MetadataStudyPhysician.metadata"'s inverse relation */
  metadataStudyPhysicianList?: InputMaybe<MetadataNestedMetadataStudyPhysicianListCreateInput>;
  /** Actions for the "Metadata.metadataSubjectCodeList" relation: "MetadataSubjectCode.metadata"'s inverse relation */
  metadataSubjectCodeList?: InputMaybe<MetadataNestedMetadataSubjectCodeListCreateInput>;
  /** Actions for the "Metadata.metadataSubjectList" relation: "MetadataSubject.metadata"'s inverse relation */
  metadataSubjectList?: InputMaybe<MetadataNestedMetadataSubjectListCreateInput>;
  /** Actions for the "Metadata.metadataSubjectPrismaList" relation: "MetadataSubjectPrisma.metadata"'s inverse relation */
  metadataSubjectPrismaList?: InputMaybe<MetadataNestedMetadataSubjectPrismaListCreateInput>;
  /** Actions for the "Metadata.metadataSupplementalCategoriesList" relation: "MetadataSupplementalCategories.metadata"'s inverse relation */
  metadataSupplementalCategoriesList?: InputMaybe<MetadataNestedMetadataSupplementalCategoriesListCreateInput>;
  /** Actions for the "Metadata.metadataTargetVersionList" relation: "MetadataTargetVersion.metadata"'s inverse relation */
  metadataTargetVersionList?: InputMaybe<MetadataNestedMetadataTargetVersionListCreateInput>;
  /** Actions for the "Metadata.metadataTitleList" relation: "MetadataTitle.metadata"'s inverse relation */
  metadataTitleList?: InputMaybe<MetadataNestedMetadataTitleListCreateInput>;
  /** Actions for the "Metadata.metadataToneCurveList" relation: "MetadataToneCurve.metadata"'s inverse relation */
  metadataToneCurveList?: InputMaybe<MetadataNestedMetadataToneCurveListCreateInput>;
  /** Actions for the "Metadata.metadataTransferredByEmailList" relation: "MetadataTransferredByEmail.metadata"'s inverse relation */
  metadataTransferredByEmailList?: InputMaybe<MetadataNestedMetadataTransferredByEmailListCreateInput>;
  /** Actions for the "Metadata.metadataTransferredByFullNameList" relation: "MetadataTransferredByFullName.metadata"'s inverse relation */
  metadataTransferredByFullNameList?: InputMaybe<MetadataNestedMetadataTransferredByFullNameListCreateInput>;
  /** Actions for the "Metadata.metadataTransferredByList" relation: "MetadataTransferredBy.metadata"'s inverse relation */
  metadataTransferredByList?: InputMaybe<MetadataNestedMetadataTransferredByListCreateInput>;
  /** Actions for the "Metadata.metadataTransmissionReferenceList" relation: "MetadataTransmissionReference.metadata"'s inverse relation */
  metadataTransmissionReferenceList?: InputMaybe<MetadataNestedMetadataTransmissionReferenceListCreateInput>;
  /** Actions for the "Metadata.metadataTypeList" relation: "MetadataType.metadata"'s inverse relation */
  metadataTypeList?: InputMaybe<MetadataNestedMetadataTypeListCreateInput>;
  /** Actions for the "Metadata.metadataUndersubjectList" relation: "MetadataUndersubject.metadata"'s inverse relation */
  metadataUndersubjectList?: InputMaybe<MetadataNestedMetadataUndersubjectListCreateInput>;
  /** Actions for the "Metadata.metadataUnderUnderSubjectList" relation: "MetadataUnderUnderSubject.metadata"'s inverse relation */
  metadataUnderUnderSubjectList?: InputMaybe<MetadataNestedMetadataUnderUnderSubjectListCreateInput>;
  /** Actions for the "Metadata.metadataUniqueIdList" relation: "MetadataUniqueId.metadata"'s inverse relation */
  metadataUniqueIdList?: InputMaybe<MetadataNestedMetadataUniqueIdListCreateInput>;
  /** Actions for the "Metadata.metadataUnitList" relation: "MetadataUnit.metadata"'s inverse relation */
  metadataUnitList?: InputMaybe<MetadataNestedMetadataUnitListCreateInput>;
  /** Actions for the "Metadata.metadataUnitShortNameList" relation: "MetadataUnitShortName.metadata"'s inverse relation */
  metadataUnitShortNameList?: InputMaybe<MetadataNestedMetadataUnitShortNameListCreateInput>;
  /** Actions for the "Metadata.metadataUploadedByFullNameList" relation: "MetadataUploadedByFullName.metadata"'s inverse relation */
  metadataUploadedByFullNameList?: InputMaybe<MetadataNestedMetadataUploadedByFullNameListCreateInput>;
  /** Actions for the "Metadata.metadataUploadedByList" relation: "MetadataUploadedBy.metadata"'s inverse relation */
  metadataUploadedByList?: InputMaybe<MetadataNestedMetadataUploadedByListCreateInput>;
  /** Actions for the "Metadata.metadataUploadTimeList" relation: "MetadataUploadTime.metadata"'s inverse relation */
  metadataUploadTimeList?: InputMaybe<MetadataNestedMetadataUploadTimeListCreateInput>;
  /** Actions for the "Metadata.metadataUrgencyList" relation: "MetadataUrgency.metadata"'s inverse relation */
  metadataUrgencyList?: InputMaybe<MetadataNestedMetadataUrgencyListCreateInput>;
  /** Actions for the "Metadata.metadataUsageTermsList" relation: "MetadataUsageTerms.metadata"'s inverse relation */
  metadataUsageTermsList?: InputMaybe<MetadataNestedMetadataUsageTermsListCreateInput>;
  /** Actions for the "Metadata.metadataUserCommentList" relation: "MetadataUserComment.metadata"'s inverse relation */
  metadataUserCommentList?: InputMaybe<MetadataNestedMetadataUserCommentListCreateInput>;
  /** Actions for the "Metadata.metadataUserDefined195List" relation: "MetadataUserDefined195.metadata"'s inverse relation */
  metadataUserDefined195List?: InputMaybe<MetadataNestedMetadataUserDefined195ListCreateInput>;
  /** Actions for the "Metadata.metadataUserDefined237List" relation: "MetadataUserDefined237.metadata"'s inverse relation */
  metadataUserDefined237List?: InputMaybe<MetadataNestedMetadataUserDefined237ListCreateInput>;
  /** Actions for the "Metadata.metadataUserDefined238List" relation: "MetadataUserDefined238.metadata"'s inverse relation */
  metadataUserDefined238List?: InputMaybe<MetadataNestedMetadataUserDefined238ListCreateInput>;
  /** Actions for the "Metadata.metadataUserDefined239List" relation: "MetadataUserDefined239.metadata"'s inverse relation */
  metadataUserDefined239List?: InputMaybe<MetadataNestedMetadataUserDefined239ListCreateInput>;
  /** Actions for the "Metadata.metadataUserDefined242List" relation: "MetadataUserDefined242.metadata"'s inverse relation */
  metadataUserDefined242List?: InputMaybe<MetadataNestedMetadataUserDefined242ListCreateInput>;
  /** Actions for the "Metadata.metadataUserDefined62List" relation: "MetadataUserDefined62.metadata"'s inverse relation */
  metadataUserDefined62List?: InputMaybe<MetadataNestedMetadataUserDefined62ListCreateInput>;
  /** Actions for the "Metadata.metadataValidList" relation: "MetadataValid.metadata"'s inverse relation */
  metadataValidList?: InputMaybe<MetadataNestedMetadataValidListCreateInput>;
  /** Actions for the "Metadata.metadataVersionList" relation: "MetadataVersion.metadata"'s inverse relation */
  metadataVersionList?: InputMaybe<MetadataNestedMetadataVersionListCreateInput>;
  /** Actions for the "Metadata.metadataWebStatementList" relation: "MetadataWebStatement.metadata"'s inverse relation */
  metadataWebStatementList?: InputMaybe<MetadataNestedMetadataWebStatementListCreateInput>;
  /** Actions for the "Metadata.metadataWorkflowKindList" relation: "MetadataWorkflowKind.metadata"'s inverse relation */
  metadataWorkflowKindList?: InputMaybe<MetadataNestedMetadataWorkflowKindListCreateInput>;
  /** Actions for the "Metadata.metadataXmpFileStampsList" relation: "MetadataXmpFileStamps.metadata"'s inverse relation */
  metadataXmpFileStampsList?: InputMaybe<MetadataNestedMetadataXmpFileStampsListCreateInput>;
  /** Actions for the "Metadata.metadataXmpHistoryList" relation: "MetadataXmpHistory.metadata"'s inverse relation */
  metadataXmpHistoryList?: InputMaybe<MetadataNestedMetadataXmpHistoryListCreateInput>;
  name: MetadataNameEnumType;
  namespace: Scalars['String']['input'];
  /** Actions for the "Metadata.photoMetadatas" relation: "PhotoMetadata.metadata"'s inverse relation */
  photoMetadatas?: InputMaybe<MetadataNestedPhotoMetadatasCreateInput>;
  structName?: InputMaybe<Scalars['String']['input']>;
  /** whether is this metadata value is a list or not, for display usage */
  type: MetadataValueTypeEnum;
};

/** "MetadataCreator" resource's node */
export type MetadataCreator = {
  __typename?: 'MetadataCreator';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCreatorCreateInput = {
  /** Actions for the "MetadataCreator.archive" relation */
  archive: MetadataCreatorNestedArchiveCreateInput;
  /** Actions for the "MetadataCreator.metadata" relation */
  metadata: MetadataCreatorNestedMetadataCreateInput;
  /** Actions for the "MetadataCreator.photo" relation */
  photo: MetadataCreatorNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCreator" node, through the "MetadataCreator.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCreator" node, through the "MetadataCreator.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCreator" node, through the "MetadataCreator.archive" relation. */
  update?: InputMaybe<MetadataCreatorNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCreator" node, through the "MetadataCreator.archive" relation. */
  upsert?: InputMaybe<MetadataCreatorNestedUpsertArchiveCreateInput>;
};

export type MetadataCreatorNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCreator" node, through the "MetadataCreator.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCreator" node, through the "MetadataCreator.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCreator" node, through the "MetadataCreator.archive" relation. */
  update?: InputMaybe<MetadataCreatorNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCreator" node, through the "MetadataCreator.archive" relation. */
  upsert?: InputMaybe<MetadataCreatorNestedUpsertArchiveUpdateInput>;
};

export type MetadataCreatorNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCreator" node, through the "MetadataCreator.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCreator" node, through the "MetadataCreator.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCreator" node, through the "MetadataCreator.metadata" relation. */
  update?: InputMaybe<MetadataCreatorNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCreator" node, through the "MetadataCreator.metadata" relation. */
  upsert?: InputMaybe<MetadataCreatorNestedUpsertMetadataCreateInput>;
};

export type MetadataCreatorNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCreator" node, through the "MetadataCreator.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCreator" node, through the "MetadataCreator.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCreator" node, through the "MetadataCreator.metadata" relation. */
  update?: InputMaybe<MetadataCreatorNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCreator" node, through the "MetadataCreator.metadata" relation. */
  upsert?: InputMaybe<MetadataCreatorNestedUpsertMetadataUpdateInput>;
};

export type MetadataCreatorNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCreator" node, through the "MetadataCreator.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCreator" node, through the "MetadataCreator.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCreator" node, through the "MetadataCreator.photo" relation. */
  update?: InputMaybe<MetadataCreatorNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCreator" node, through the "MetadataCreator.photo" relation. */
  upsert?: InputMaybe<MetadataCreatorNestedUpsertPhotoCreateInput>;
};

export type MetadataCreatorNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCreator" node, through the "MetadataCreator.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCreator" node, through the "MetadataCreator.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCreator" node, through the "MetadataCreator.photo" relation. */
  update?: InputMaybe<MetadataCreatorNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCreator" node, through the "MetadataCreator.photo" relation. */
  upsert?: InputMaybe<MetadataCreatorNestedUpsertPhotoUpdateInput>;
};

export type MetadataCreatorNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatorNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatorNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatorNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatorNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreatorNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreatorNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatorNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatorNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatorNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatorNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreatorNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCreatorOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

/** "MetadataCreatorTool" resource's node */
export type MetadataCreatorTool = {
  __typename?: 'MetadataCreatorTool';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCreatorToolCreateInput = {
  /** Actions for the "MetadataCreatorTool.archive" relation */
  archive: MetadataCreatorToolNestedArchiveCreateInput;
  /** Actions for the "MetadataCreatorTool.metadata" relation */
  metadata: MetadataCreatorToolNestedMetadataCreateInput;
  /** Actions for the "MetadataCreatorTool.photo" relation */
  photo: MetadataCreatorToolNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorToolNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.archive" relation. */
  update?: InputMaybe<MetadataCreatorToolNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.archive" relation. */
  upsert?: InputMaybe<MetadataCreatorToolNestedUpsertArchiveCreateInput>;
};

export type MetadataCreatorToolNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.archive" relation. */
  update?: InputMaybe<MetadataCreatorToolNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.archive" relation. */
  upsert?: InputMaybe<MetadataCreatorToolNestedUpsertArchiveUpdateInput>;
};

export type MetadataCreatorToolNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.metadata" relation. */
  update?: InputMaybe<MetadataCreatorToolNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.metadata" relation. */
  upsert?: InputMaybe<MetadataCreatorToolNestedUpsertMetadataCreateInput>;
};

export type MetadataCreatorToolNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.metadata" relation. */
  update?: InputMaybe<MetadataCreatorToolNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.metadata" relation. */
  upsert?: InputMaybe<MetadataCreatorToolNestedUpsertMetadataUpdateInput>;
};

export type MetadataCreatorToolNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.photo" relation. */
  update?: InputMaybe<MetadataCreatorToolNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCreatorTool" node, through the "MetadataCreatorTool.photo" relation. */
  upsert?: InputMaybe<MetadataCreatorToolNestedUpsertPhotoCreateInput>;
};

export type MetadataCreatorToolNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.photo" relation. */
  update?: InputMaybe<MetadataCreatorToolNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCreatorTool" node, through the "MetadataCreatorTool.photo" relation. */
  upsert?: InputMaybe<MetadataCreatorToolNestedUpsertPhotoUpdateInput>;
};

export type MetadataCreatorToolNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatorToolNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatorToolNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatorToolNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatorToolNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreatorToolNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreatorToolNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatorToolNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreatorToolNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatorToolNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreatorToolNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreatorToolNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCreatorToolOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCreatorToolUpdateInput = {
  /** Actions for the "MetadataCreatorTool.archive" relation */
  archive?: InputMaybe<MetadataCreatorToolNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCreatorTool.metadata" relation */
  metadata?: InputMaybe<MetadataCreatorToolNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCreatorTool.photo" relation */
  photo?: InputMaybe<MetadataCreatorToolNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCreatorTool" nodes by specifying some conditions */
export type MetadataCreatorToolWhereInput = {
  AND?: InputMaybe<Array<MetadataCreatorToolWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCreatorToolWhereInput>;
  OR?: InputMaybe<Array<MetadataCreatorToolWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCreatorTool" node. */
export type MetadataCreatorToolWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCreatorToolWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCreatorTool.metadata" relation */
  metadata: MetadataCreatorToolNestedMetadataCreateInput;
  /** Actions for the "MetadataCreatorTool.photo" relation */
  photo: MetadataCreatorToolNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorToolWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCreatorTool.metadata" relation */
  metadata?: InputMaybe<MetadataCreatorToolNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCreatorTool.photo" relation */
  photo?: InputMaybe<MetadataCreatorToolNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorToolWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCreatorTool.archive" relation */
  archive: MetadataCreatorToolNestedArchiveCreateInput;
  /** Actions for the "MetadataCreatorTool.photo" relation */
  photo: MetadataCreatorToolNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorToolWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCreatorTool.archive" relation */
  archive?: InputMaybe<MetadataCreatorToolNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCreatorTool.photo" relation */
  photo?: InputMaybe<MetadataCreatorToolNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorToolWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCreatorTool.archive" relation */
  archive: MetadataCreatorToolNestedArchiveCreateInput;
  /** Actions for the "MetadataCreatorTool.metadata" relation */
  metadata: MetadataCreatorToolNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorToolWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCreatorTool.archive" relation */
  archive?: InputMaybe<MetadataCreatorToolNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCreatorTool.metadata" relation */
  metadata?: InputMaybe<MetadataCreatorToolNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorUpdateInput = {
  /** Actions for the "MetadataCreator.archive" relation */
  archive?: InputMaybe<MetadataCreatorNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCreator.metadata" relation */
  metadata?: InputMaybe<MetadataCreatorNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCreator.photo" relation */
  photo?: InputMaybe<MetadataCreatorNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCreator" nodes by specifying some conditions */
export type MetadataCreatorWhereInput = {
  AND?: InputMaybe<Array<MetadataCreatorWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCreatorWhereInput>;
  OR?: InputMaybe<Array<MetadataCreatorWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCreator" node. */
export type MetadataCreatorWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCreatorWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCreator.metadata" relation */
  metadata: MetadataCreatorNestedMetadataCreateInput;
  /** Actions for the "MetadataCreator.photo" relation */
  photo: MetadataCreatorNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCreator.metadata" relation */
  metadata?: InputMaybe<MetadataCreatorNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCreator.photo" relation */
  photo?: InputMaybe<MetadataCreatorNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCreator.archive" relation */
  archive: MetadataCreatorNestedArchiveCreateInput;
  /** Actions for the "MetadataCreator.photo" relation */
  photo: MetadataCreatorNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCreator.archive" relation */
  archive?: InputMaybe<MetadataCreatorNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCreator.photo" relation */
  photo?: InputMaybe<MetadataCreatorNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCreator.archive" relation */
  archive: MetadataCreatorNestedArchiveCreateInput;
  /** Actions for the "MetadataCreator.metadata" relation */
  metadata: MetadataCreatorNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreatorWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCreator.archive" relation */
  archive?: InputMaybe<MetadataCreatorNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCreator.metadata" relation */
  metadata?: InputMaybe<MetadataCreatorNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCredit" resource's node */
export type MetadataCredit = {
  __typename?: 'MetadataCredit';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCreditCreateInput = {
  /** Actions for the "MetadataCredit.archive" relation */
  archive: MetadataCreditNestedArchiveCreateInput;
  /** Actions for the "MetadataCredit.metadata" relation */
  metadata: MetadataCreditNestedMetadataCreateInput;
  /** Actions for the "MetadataCredit.photo" relation */
  photo: MetadataCreditNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreditNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCredit" node, through the "MetadataCredit.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCredit" node, through the "MetadataCredit.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCredit" node, through the "MetadataCredit.archive" relation. */
  update?: InputMaybe<MetadataCreditNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCredit" node, through the "MetadataCredit.archive" relation. */
  upsert?: InputMaybe<MetadataCreditNestedUpsertArchiveCreateInput>;
};

export type MetadataCreditNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCredit" node, through the "MetadataCredit.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCredit" node, through the "MetadataCredit.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCredit" node, through the "MetadataCredit.archive" relation. */
  update?: InputMaybe<MetadataCreditNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCredit" node, through the "MetadataCredit.archive" relation. */
  upsert?: InputMaybe<MetadataCreditNestedUpsertArchiveUpdateInput>;
};

export type MetadataCreditNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCredit" node, through the "MetadataCredit.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCredit" node, through the "MetadataCredit.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCredit" node, through the "MetadataCredit.metadata" relation. */
  update?: InputMaybe<MetadataCreditNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCredit" node, through the "MetadataCredit.metadata" relation. */
  upsert?: InputMaybe<MetadataCreditNestedUpsertMetadataCreateInput>;
};

export type MetadataCreditNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCredit" node, through the "MetadataCredit.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCredit" node, through the "MetadataCredit.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCredit" node, through the "MetadataCredit.metadata" relation. */
  update?: InputMaybe<MetadataCreditNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCredit" node, through the "MetadataCredit.metadata" relation. */
  upsert?: InputMaybe<MetadataCreditNestedUpsertMetadataUpdateInput>;
};

export type MetadataCreditNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCredit" node, through the "MetadataCredit.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCredit" node, through the "MetadataCredit.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCredit" node, through the "MetadataCredit.photo" relation. */
  update?: InputMaybe<MetadataCreditNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCredit" node, through the "MetadataCredit.photo" relation. */
  upsert?: InputMaybe<MetadataCreditNestedUpsertPhotoCreateInput>;
};

export type MetadataCreditNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCredit" node, through the "MetadataCredit.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCredit" node, through the "MetadataCredit.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCredit" node, through the "MetadataCredit.photo" relation. */
  update?: InputMaybe<MetadataCreditNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCredit" node, through the "MetadataCredit.photo" relation. */
  upsert?: InputMaybe<MetadataCreditNestedUpsertPhotoUpdateInput>;
};

export type MetadataCreditNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreditNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreditNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreditNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreditNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreditNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreditNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreditNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCreditNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreditNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCreditNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCreditNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCreditOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCreditUpdateInput = {
  /** Actions for the "MetadataCredit.archive" relation */
  archive?: InputMaybe<MetadataCreditNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCredit.metadata" relation */
  metadata?: InputMaybe<MetadataCreditNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCredit.photo" relation */
  photo?: InputMaybe<MetadataCreditNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCredit" nodes by specifying some conditions */
export type MetadataCreditWhereInput = {
  AND?: InputMaybe<Array<MetadataCreditWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCreditWhereInput>;
  OR?: InputMaybe<Array<MetadataCreditWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCredit" node. */
export type MetadataCreditWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCreditWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCredit.metadata" relation */
  metadata: MetadataCreditNestedMetadataCreateInput;
  /** Actions for the "MetadataCredit.photo" relation */
  photo: MetadataCreditNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreditWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCredit.metadata" relation */
  metadata?: InputMaybe<MetadataCreditNestedMetadataUpdateInput>;
  /** Actions for the "MetadataCredit.photo" relation */
  photo?: InputMaybe<MetadataCreditNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreditWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCredit.archive" relation */
  archive: MetadataCreditNestedArchiveCreateInput;
  /** Actions for the "MetadataCredit.photo" relation */
  photo: MetadataCreditNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreditWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCredit.archive" relation */
  archive?: InputMaybe<MetadataCreditNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCredit.photo" relation */
  photo?: InputMaybe<MetadataCreditNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreditWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCredit.archive" relation */
  archive: MetadataCreditNestedArchiveCreateInput;
  /** Actions for the "MetadataCredit.metadata" relation */
  metadata: MetadataCreditNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCreditWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCredit.archive" relation */
  archive?: InputMaybe<MetadataCreditNestedArchiveUpdateInput>;
  /** Actions for the "MetadataCredit.metadata" relation */
  metadata?: InputMaybe<MetadataCreditNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCustomField14" resource's node */
export type MetadataCustomField14 = {
  __typename?: 'MetadataCustomField14';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCustomField14CreateInput = {
  /** Actions for the "MetadataCustomField14.archive" relation */
  archive: MetadataCustomField14NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField14.metadata" relation */
  metadata: MetadataCustomField14NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField14.photo" relation */
  photo: MetadataCustomField14NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField14NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCustomField14" node, through the "MetadataCustomField14.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCustomField14" node, through the "MetadataCustomField14.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCustomField14" node, through the "MetadataCustomField14.archive" relation. */
  update?: InputMaybe<MetadataCustomField14NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCustomField14" node, through the "MetadataCustomField14.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField14NestedUpsertArchiveCreateInput>;
};

export type MetadataCustomField14NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.archive" relation. */
  update?: InputMaybe<MetadataCustomField14NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField14NestedUpsertArchiveUpdateInput>;
};

export type MetadataCustomField14NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCustomField14" node, through the "MetadataCustomField14.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCustomField14" node, through the "MetadataCustomField14.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCustomField14" node, through the "MetadataCustomField14.metadata" relation. */
  update?: InputMaybe<MetadataCustomField14NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCustomField14" node, through the "MetadataCustomField14.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField14NestedUpsertMetadataCreateInput>;
};

export type MetadataCustomField14NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.metadata" relation. */
  update?: InputMaybe<MetadataCustomField14NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField14NestedUpsertMetadataUpdateInput>;
};

export type MetadataCustomField14NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCustomField14" node, through the "MetadataCustomField14.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCustomField14" node, through the "MetadataCustomField14.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCustomField14" node, through the "MetadataCustomField14.photo" relation. */
  update?: InputMaybe<MetadataCustomField14NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCustomField14" node, through the "MetadataCustomField14.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField14NestedUpsertPhotoCreateInput>;
};

export type MetadataCustomField14NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.photo" relation. */
  update?: InputMaybe<MetadataCustomField14NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCustomField14" node, through the "MetadataCustomField14.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField14NestedUpsertPhotoUpdateInput>;
};

export type MetadataCustomField14NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField14NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField14NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField14NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField14NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField14NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField14NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField14NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField14NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField14NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField14NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField14NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCustomField14OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCustomField14UpdateInput = {
  /** Actions for the "MetadataCustomField14.archive" relation */
  archive?: InputMaybe<MetadataCustomField14NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField14.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField14NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField14.photo" relation */
  photo?: InputMaybe<MetadataCustomField14NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCustomField14" nodes by specifying some conditions */
export type MetadataCustomField14WhereInput = {
  AND?: InputMaybe<Array<MetadataCustomField14WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCustomField14WhereInput>;
  OR?: InputMaybe<Array<MetadataCustomField14WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCustomField14" node. */
export type MetadataCustomField14WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCustomField14WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCustomField14.metadata" relation */
  metadata: MetadataCustomField14NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField14.photo" relation */
  photo: MetadataCustomField14NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField14WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCustomField14.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField14NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField14.photo" relation */
  photo?: InputMaybe<MetadataCustomField14NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField14WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCustomField14.archive" relation */
  archive: MetadataCustomField14NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField14.photo" relation */
  photo: MetadataCustomField14NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField14WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCustomField14.archive" relation */
  archive?: InputMaybe<MetadataCustomField14NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField14.photo" relation */
  photo?: InputMaybe<MetadataCustomField14NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField14WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCustomField14.archive" relation */
  archive: MetadataCustomField14NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField14.metadata" relation */
  metadata: MetadataCustomField14NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField14WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCustomField14.archive" relation */
  archive?: InputMaybe<MetadataCustomField14NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField14.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField14NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCustomField15" resource's node */
export type MetadataCustomField15 = {
  __typename?: 'MetadataCustomField15';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCustomField15CreateInput = {
  /** Actions for the "MetadataCustomField15.archive" relation */
  archive: MetadataCustomField15NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField15.metadata" relation */
  metadata: MetadataCustomField15NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField15.photo" relation */
  photo: MetadataCustomField15NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField15NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCustomField15" node, through the "MetadataCustomField15.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCustomField15" node, through the "MetadataCustomField15.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCustomField15" node, through the "MetadataCustomField15.archive" relation. */
  update?: InputMaybe<MetadataCustomField15NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCustomField15" node, through the "MetadataCustomField15.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField15NestedUpsertArchiveCreateInput>;
};

export type MetadataCustomField15NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.archive" relation. */
  update?: InputMaybe<MetadataCustomField15NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField15NestedUpsertArchiveUpdateInput>;
};

export type MetadataCustomField15NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCustomField15" node, through the "MetadataCustomField15.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCustomField15" node, through the "MetadataCustomField15.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCustomField15" node, through the "MetadataCustomField15.metadata" relation. */
  update?: InputMaybe<MetadataCustomField15NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCustomField15" node, through the "MetadataCustomField15.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField15NestedUpsertMetadataCreateInput>;
};

export type MetadataCustomField15NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.metadata" relation. */
  update?: InputMaybe<MetadataCustomField15NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField15NestedUpsertMetadataUpdateInput>;
};

export type MetadataCustomField15NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCustomField15" node, through the "MetadataCustomField15.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCustomField15" node, through the "MetadataCustomField15.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCustomField15" node, through the "MetadataCustomField15.photo" relation. */
  update?: InputMaybe<MetadataCustomField15NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCustomField15" node, through the "MetadataCustomField15.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField15NestedUpsertPhotoCreateInput>;
};

export type MetadataCustomField15NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.photo" relation. */
  update?: InputMaybe<MetadataCustomField15NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCustomField15" node, through the "MetadataCustomField15.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField15NestedUpsertPhotoUpdateInput>;
};

export type MetadataCustomField15NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField15NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField15NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField15NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField15NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField15NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField15NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField15NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField15NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField15NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField15NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField15NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCustomField15OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCustomField15UpdateInput = {
  /** Actions for the "MetadataCustomField15.archive" relation */
  archive?: InputMaybe<MetadataCustomField15NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField15.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField15NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField15.photo" relation */
  photo?: InputMaybe<MetadataCustomField15NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCustomField15" nodes by specifying some conditions */
export type MetadataCustomField15WhereInput = {
  AND?: InputMaybe<Array<MetadataCustomField15WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCustomField15WhereInput>;
  OR?: InputMaybe<Array<MetadataCustomField15WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCustomField15" node. */
export type MetadataCustomField15WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCustomField15WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCustomField15.metadata" relation */
  metadata: MetadataCustomField15NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField15.photo" relation */
  photo: MetadataCustomField15NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField15WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCustomField15.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField15NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField15.photo" relation */
  photo?: InputMaybe<MetadataCustomField15NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField15WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCustomField15.archive" relation */
  archive: MetadataCustomField15NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField15.photo" relation */
  photo: MetadataCustomField15NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField15WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCustomField15.archive" relation */
  archive?: InputMaybe<MetadataCustomField15NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField15.photo" relation */
  photo?: InputMaybe<MetadataCustomField15NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField15WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCustomField15.archive" relation */
  archive: MetadataCustomField15NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField15.metadata" relation */
  metadata: MetadataCustomField15NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField15WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCustomField15.archive" relation */
  archive?: InputMaybe<MetadataCustomField15NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField15.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField15NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCustomField16" resource's node */
export type MetadataCustomField16 = {
  __typename?: 'MetadataCustomField16';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCustomField16CreateInput = {
  /** Actions for the "MetadataCustomField16.archive" relation */
  archive: MetadataCustomField16NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField16.metadata" relation */
  metadata: MetadataCustomField16NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField16.photo" relation */
  photo: MetadataCustomField16NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField16NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCustomField16" node, through the "MetadataCustomField16.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCustomField16" node, through the "MetadataCustomField16.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCustomField16" node, through the "MetadataCustomField16.archive" relation. */
  update?: InputMaybe<MetadataCustomField16NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCustomField16" node, through the "MetadataCustomField16.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField16NestedUpsertArchiveCreateInput>;
};

export type MetadataCustomField16NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.archive" relation. */
  update?: InputMaybe<MetadataCustomField16NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField16NestedUpsertArchiveUpdateInput>;
};

export type MetadataCustomField16NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCustomField16" node, through the "MetadataCustomField16.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCustomField16" node, through the "MetadataCustomField16.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCustomField16" node, through the "MetadataCustomField16.metadata" relation. */
  update?: InputMaybe<MetadataCustomField16NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCustomField16" node, through the "MetadataCustomField16.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField16NestedUpsertMetadataCreateInput>;
};

export type MetadataCustomField16NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.metadata" relation. */
  update?: InputMaybe<MetadataCustomField16NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField16NestedUpsertMetadataUpdateInput>;
};

export type MetadataCustomField16NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCustomField16" node, through the "MetadataCustomField16.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCustomField16" node, through the "MetadataCustomField16.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCustomField16" node, through the "MetadataCustomField16.photo" relation. */
  update?: InputMaybe<MetadataCustomField16NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCustomField16" node, through the "MetadataCustomField16.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField16NestedUpsertPhotoCreateInput>;
};

export type MetadataCustomField16NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.photo" relation. */
  update?: InputMaybe<MetadataCustomField16NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCustomField16" node, through the "MetadataCustomField16.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField16NestedUpsertPhotoUpdateInput>;
};

export type MetadataCustomField16NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField16NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField16NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField16NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField16NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField16NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField16NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField16NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField16NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField16NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField16NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField16NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCustomField16OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCustomField16UpdateInput = {
  /** Actions for the "MetadataCustomField16.archive" relation */
  archive?: InputMaybe<MetadataCustomField16NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField16.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField16NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField16.photo" relation */
  photo?: InputMaybe<MetadataCustomField16NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCustomField16" nodes by specifying some conditions */
export type MetadataCustomField16WhereInput = {
  AND?: InputMaybe<Array<MetadataCustomField16WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCustomField16WhereInput>;
  OR?: InputMaybe<Array<MetadataCustomField16WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCustomField16" node. */
export type MetadataCustomField16WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCustomField16WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCustomField16.metadata" relation */
  metadata: MetadataCustomField16NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField16.photo" relation */
  photo: MetadataCustomField16NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField16WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCustomField16.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField16NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField16.photo" relation */
  photo?: InputMaybe<MetadataCustomField16NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField16WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCustomField16.archive" relation */
  archive: MetadataCustomField16NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField16.photo" relation */
  photo: MetadataCustomField16NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField16WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCustomField16.archive" relation */
  archive?: InputMaybe<MetadataCustomField16NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField16.photo" relation */
  photo?: InputMaybe<MetadataCustomField16NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField16WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCustomField16.archive" relation */
  archive: MetadataCustomField16NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField16.metadata" relation */
  metadata: MetadataCustomField16NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField16WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCustomField16.archive" relation */
  archive?: InputMaybe<MetadataCustomField16NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField16.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField16NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCustomField17" resource's node */
export type MetadataCustomField17 = {
  __typename?: 'MetadataCustomField17';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCustomField17CreateInput = {
  /** Actions for the "MetadataCustomField17.archive" relation */
  archive: MetadataCustomField17NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField17.metadata" relation */
  metadata: MetadataCustomField17NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField17.photo" relation */
  photo: MetadataCustomField17NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField17NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCustomField17" node, through the "MetadataCustomField17.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCustomField17" node, through the "MetadataCustomField17.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCustomField17" node, through the "MetadataCustomField17.archive" relation. */
  update?: InputMaybe<MetadataCustomField17NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCustomField17" node, through the "MetadataCustomField17.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField17NestedUpsertArchiveCreateInput>;
};

export type MetadataCustomField17NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.archive" relation. */
  update?: InputMaybe<MetadataCustomField17NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField17NestedUpsertArchiveUpdateInput>;
};

export type MetadataCustomField17NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCustomField17" node, through the "MetadataCustomField17.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCustomField17" node, through the "MetadataCustomField17.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCustomField17" node, through the "MetadataCustomField17.metadata" relation. */
  update?: InputMaybe<MetadataCustomField17NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCustomField17" node, through the "MetadataCustomField17.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField17NestedUpsertMetadataCreateInput>;
};

export type MetadataCustomField17NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.metadata" relation. */
  update?: InputMaybe<MetadataCustomField17NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField17NestedUpsertMetadataUpdateInput>;
};

export type MetadataCustomField17NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCustomField17" node, through the "MetadataCustomField17.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCustomField17" node, through the "MetadataCustomField17.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCustomField17" node, through the "MetadataCustomField17.photo" relation. */
  update?: InputMaybe<MetadataCustomField17NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCustomField17" node, through the "MetadataCustomField17.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField17NestedUpsertPhotoCreateInput>;
};

export type MetadataCustomField17NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.photo" relation. */
  update?: InputMaybe<MetadataCustomField17NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCustomField17" node, through the "MetadataCustomField17.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField17NestedUpsertPhotoUpdateInput>;
};

export type MetadataCustomField17NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField17NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField17NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField17NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField17NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField17NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField17NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField17NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField17NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField17NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField17NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField17NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCustomField17OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCustomField17UpdateInput = {
  /** Actions for the "MetadataCustomField17.archive" relation */
  archive?: InputMaybe<MetadataCustomField17NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField17.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField17NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField17.photo" relation */
  photo?: InputMaybe<MetadataCustomField17NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCustomField17" nodes by specifying some conditions */
export type MetadataCustomField17WhereInput = {
  AND?: InputMaybe<Array<MetadataCustomField17WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCustomField17WhereInput>;
  OR?: InputMaybe<Array<MetadataCustomField17WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCustomField17" node. */
export type MetadataCustomField17WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCustomField17WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCustomField17.metadata" relation */
  metadata: MetadataCustomField17NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField17.photo" relation */
  photo: MetadataCustomField17NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField17WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCustomField17.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField17NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField17.photo" relation */
  photo?: InputMaybe<MetadataCustomField17NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField17WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCustomField17.archive" relation */
  archive: MetadataCustomField17NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField17.photo" relation */
  photo: MetadataCustomField17NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField17WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCustomField17.archive" relation */
  archive?: InputMaybe<MetadataCustomField17NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField17.photo" relation */
  photo?: InputMaybe<MetadataCustomField17NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField17WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCustomField17.archive" relation */
  archive: MetadataCustomField17NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField17.metadata" relation */
  metadata: MetadataCustomField17NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField17WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCustomField17.archive" relation */
  archive?: InputMaybe<MetadataCustomField17NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField17.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField17NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCustomField20" resource's node */
export type MetadataCustomField20 = {
  __typename?: 'MetadataCustomField20';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCustomField20CreateInput = {
  /** Actions for the "MetadataCustomField20.archive" relation */
  archive: MetadataCustomField20NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField20.metadata" relation */
  metadata: MetadataCustomField20NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField20.photo" relation */
  photo: MetadataCustomField20NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField20NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCustomField20" node, through the "MetadataCustomField20.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCustomField20" node, through the "MetadataCustomField20.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCustomField20" node, through the "MetadataCustomField20.archive" relation. */
  update?: InputMaybe<MetadataCustomField20NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCustomField20" node, through the "MetadataCustomField20.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField20NestedUpsertArchiveCreateInput>;
};

export type MetadataCustomField20NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.archive" relation. */
  update?: InputMaybe<MetadataCustomField20NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField20NestedUpsertArchiveUpdateInput>;
};

export type MetadataCustomField20NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCustomField20" node, through the "MetadataCustomField20.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCustomField20" node, through the "MetadataCustomField20.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCustomField20" node, through the "MetadataCustomField20.metadata" relation. */
  update?: InputMaybe<MetadataCustomField20NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCustomField20" node, through the "MetadataCustomField20.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField20NestedUpsertMetadataCreateInput>;
};

export type MetadataCustomField20NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.metadata" relation. */
  update?: InputMaybe<MetadataCustomField20NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField20NestedUpsertMetadataUpdateInput>;
};

export type MetadataCustomField20NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCustomField20" node, through the "MetadataCustomField20.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCustomField20" node, through the "MetadataCustomField20.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCustomField20" node, through the "MetadataCustomField20.photo" relation. */
  update?: InputMaybe<MetadataCustomField20NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCustomField20" node, through the "MetadataCustomField20.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField20NestedUpsertPhotoCreateInput>;
};

export type MetadataCustomField20NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.photo" relation. */
  update?: InputMaybe<MetadataCustomField20NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCustomField20" node, through the "MetadataCustomField20.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField20NestedUpsertPhotoUpdateInput>;
};

export type MetadataCustomField20NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField20NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField20NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField20NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField20NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField20NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField20NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField20NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField20NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField20NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField20NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField20NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCustomField20OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCustomField20UpdateInput = {
  /** Actions for the "MetadataCustomField20.archive" relation */
  archive?: InputMaybe<MetadataCustomField20NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField20.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField20NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField20.photo" relation */
  photo?: InputMaybe<MetadataCustomField20NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCustomField20" nodes by specifying some conditions */
export type MetadataCustomField20WhereInput = {
  AND?: InputMaybe<Array<MetadataCustomField20WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCustomField20WhereInput>;
  OR?: InputMaybe<Array<MetadataCustomField20WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCustomField20" node. */
export type MetadataCustomField20WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCustomField20WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCustomField20.metadata" relation */
  metadata: MetadataCustomField20NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField20.photo" relation */
  photo: MetadataCustomField20NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField20WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCustomField20.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField20NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField20.photo" relation */
  photo?: InputMaybe<MetadataCustomField20NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField20WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCustomField20.archive" relation */
  archive: MetadataCustomField20NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField20.photo" relation */
  photo: MetadataCustomField20NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField20WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCustomField20.archive" relation */
  archive?: InputMaybe<MetadataCustomField20NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField20.photo" relation */
  photo?: InputMaybe<MetadataCustomField20NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField20WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCustomField20.archive" relation */
  archive: MetadataCustomField20NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField20.metadata" relation */
  metadata: MetadataCustomField20NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField20WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCustomField20.archive" relation */
  archive?: InputMaybe<MetadataCustomField20NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField20.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField20NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCustomField5" resource's node */
export type MetadataCustomField5 = {
  __typename?: 'MetadataCustomField5';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCustomField5CreateInput = {
  /** Actions for the "MetadataCustomField5.archive" relation */
  archive: MetadataCustomField5NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField5.metadata" relation */
  metadata: MetadataCustomField5NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField5.photo" relation */
  photo: MetadataCustomField5NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField5NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCustomField5" node, through the "MetadataCustomField5.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCustomField5" node, through the "MetadataCustomField5.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCustomField5" node, through the "MetadataCustomField5.archive" relation. */
  update?: InputMaybe<MetadataCustomField5NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCustomField5" node, through the "MetadataCustomField5.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField5NestedUpsertArchiveCreateInput>;
};

export type MetadataCustomField5NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.archive" relation. */
  update?: InputMaybe<MetadataCustomField5NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField5NestedUpsertArchiveUpdateInput>;
};

export type MetadataCustomField5NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCustomField5" node, through the "MetadataCustomField5.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCustomField5" node, through the "MetadataCustomField5.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCustomField5" node, through the "MetadataCustomField5.metadata" relation. */
  update?: InputMaybe<MetadataCustomField5NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCustomField5" node, through the "MetadataCustomField5.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField5NestedUpsertMetadataCreateInput>;
};

export type MetadataCustomField5NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.metadata" relation. */
  update?: InputMaybe<MetadataCustomField5NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField5NestedUpsertMetadataUpdateInput>;
};

export type MetadataCustomField5NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCustomField5" node, through the "MetadataCustomField5.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCustomField5" node, through the "MetadataCustomField5.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCustomField5" node, through the "MetadataCustomField5.photo" relation. */
  update?: InputMaybe<MetadataCustomField5NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCustomField5" node, through the "MetadataCustomField5.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField5NestedUpsertPhotoCreateInput>;
};

export type MetadataCustomField5NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.photo" relation. */
  update?: InputMaybe<MetadataCustomField5NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCustomField5" node, through the "MetadataCustomField5.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField5NestedUpsertPhotoUpdateInput>;
};

export type MetadataCustomField5NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField5NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField5NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField5NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField5NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField5NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField5NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField5NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField5NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField5NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField5NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField5NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCustomField5OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCustomField5UpdateInput = {
  /** Actions for the "MetadataCustomField5.archive" relation */
  archive?: InputMaybe<MetadataCustomField5NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField5.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField5NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField5.photo" relation */
  photo?: InputMaybe<MetadataCustomField5NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCustomField5" nodes by specifying some conditions */
export type MetadataCustomField5WhereInput = {
  AND?: InputMaybe<Array<MetadataCustomField5WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCustomField5WhereInput>;
  OR?: InputMaybe<Array<MetadataCustomField5WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCustomField5" node. */
export type MetadataCustomField5WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCustomField5WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCustomField5.metadata" relation */
  metadata: MetadataCustomField5NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField5.photo" relation */
  photo: MetadataCustomField5NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField5WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCustomField5.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField5NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField5.photo" relation */
  photo?: InputMaybe<MetadataCustomField5NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField5WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCustomField5.archive" relation */
  archive: MetadataCustomField5NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField5.photo" relation */
  photo: MetadataCustomField5NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField5WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCustomField5.archive" relation */
  archive?: InputMaybe<MetadataCustomField5NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField5.photo" relation */
  photo?: InputMaybe<MetadataCustomField5NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField5WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCustomField5.archive" relation */
  archive: MetadataCustomField5NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField5.metadata" relation */
  metadata: MetadataCustomField5NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField5WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCustomField5.archive" relation */
  archive?: InputMaybe<MetadataCustomField5NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField5.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField5NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCustomField6" resource's node */
export type MetadataCustomField6 = {
  __typename?: 'MetadataCustomField6';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCustomField6CreateInput = {
  /** Actions for the "MetadataCustomField6.archive" relation */
  archive: MetadataCustomField6NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField6.metadata" relation */
  metadata: MetadataCustomField6NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField6.photo" relation */
  photo: MetadataCustomField6NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField6NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCustomField6" node, through the "MetadataCustomField6.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCustomField6" node, through the "MetadataCustomField6.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCustomField6" node, through the "MetadataCustomField6.archive" relation. */
  update?: InputMaybe<MetadataCustomField6NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCustomField6" node, through the "MetadataCustomField6.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField6NestedUpsertArchiveCreateInput>;
};

export type MetadataCustomField6NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.archive" relation. */
  update?: InputMaybe<MetadataCustomField6NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField6NestedUpsertArchiveUpdateInput>;
};

export type MetadataCustomField6NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCustomField6" node, through the "MetadataCustomField6.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCustomField6" node, through the "MetadataCustomField6.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCustomField6" node, through the "MetadataCustomField6.metadata" relation. */
  update?: InputMaybe<MetadataCustomField6NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCustomField6" node, through the "MetadataCustomField6.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField6NestedUpsertMetadataCreateInput>;
};

export type MetadataCustomField6NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.metadata" relation. */
  update?: InputMaybe<MetadataCustomField6NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField6NestedUpsertMetadataUpdateInput>;
};

export type MetadataCustomField6NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCustomField6" node, through the "MetadataCustomField6.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCustomField6" node, through the "MetadataCustomField6.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCustomField6" node, through the "MetadataCustomField6.photo" relation. */
  update?: InputMaybe<MetadataCustomField6NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCustomField6" node, through the "MetadataCustomField6.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField6NestedUpsertPhotoCreateInput>;
};

export type MetadataCustomField6NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.photo" relation. */
  update?: InputMaybe<MetadataCustomField6NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCustomField6" node, through the "MetadataCustomField6.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField6NestedUpsertPhotoUpdateInput>;
};

export type MetadataCustomField6NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField6NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField6NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField6NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField6NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField6NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField6NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField6NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField6NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField6NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField6NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField6NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCustomField6OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCustomField6UpdateInput = {
  /** Actions for the "MetadataCustomField6.archive" relation */
  archive?: InputMaybe<MetadataCustomField6NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField6.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField6NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField6.photo" relation */
  photo?: InputMaybe<MetadataCustomField6NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCustomField6" nodes by specifying some conditions */
export type MetadataCustomField6WhereInput = {
  AND?: InputMaybe<Array<MetadataCustomField6WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCustomField6WhereInput>;
  OR?: InputMaybe<Array<MetadataCustomField6WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCustomField6" node. */
export type MetadataCustomField6WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCustomField6WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCustomField6.metadata" relation */
  metadata: MetadataCustomField6NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField6.photo" relation */
  photo: MetadataCustomField6NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField6WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCustomField6.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField6NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField6.photo" relation */
  photo?: InputMaybe<MetadataCustomField6NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField6WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCustomField6.archive" relation */
  archive: MetadataCustomField6NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField6.photo" relation */
  photo: MetadataCustomField6NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField6WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCustomField6.archive" relation */
  archive?: InputMaybe<MetadataCustomField6NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField6.photo" relation */
  photo?: InputMaybe<MetadataCustomField6NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField6WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCustomField6.archive" relation */
  archive: MetadataCustomField6NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField6.metadata" relation */
  metadata: MetadataCustomField6NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField6WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCustomField6.archive" relation */
  archive?: InputMaybe<MetadataCustomField6NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField6.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField6NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCustomField7" resource's node */
export type MetadataCustomField7 = {
  __typename?: 'MetadataCustomField7';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCustomField7CreateInput = {
  /** Actions for the "MetadataCustomField7.archive" relation */
  archive: MetadataCustomField7NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField7.metadata" relation */
  metadata: MetadataCustomField7NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField7.photo" relation */
  photo: MetadataCustomField7NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField7NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCustomField7" node, through the "MetadataCustomField7.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCustomField7" node, through the "MetadataCustomField7.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCustomField7" node, through the "MetadataCustomField7.archive" relation. */
  update?: InputMaybe<MetadataCustomField7NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCustomField7" node, through the "MetadataCustomField7.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField7NestedUpsertArchiveCreateInput>;
};

export type MetadataCustomField7NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.archive" relation. */
  update?: InputMaybe<MetadataCustomField7NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField7NestedUpsertArchiveUpdateInput>;
};

export type MetadataCustomField7NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCustomField7" node, through the "MetadataCustomField7.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCustomField7" node, through the "MetadataCustomField7.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCustomField7" node, through the "MetadataCustomField7.metadata" relation. */
  update?: InputMaybe<MetadataCustomField7NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCustomField7" node, through the "MetadataCustomField7.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField7NestedUpsertMetadataCreateInput>;
};

export type MetadataCustomField7NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.metadata" relation. */
  update?: InputMaybe<MetadataCustomField7NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField7NestedUpsertMetadataUpdateInput>;
};

export type MetadataCustomField7NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCustomField7" node, through the "MetadataCustomField7.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCustomField7" node, through the "MetadataCustomField7.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCustomField7" node, through the "MetadataCustomField7.photo" relation. */
  update?: InputMaybe<MetadataCustomField7NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCustomField7" node, through the "MetadataCustomField7.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField7NestedUpsertPhotoCreateInput>;
};

export type MetadataCustomField7NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.photo" relation. */
  update?: InputMaybe<MetadataCustomField7NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCustomField7" node, through the "MetadataCustomField7.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField7NestedUpsertPhotoUpdateInput>;
};

export type MetadataCustomField7NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField7NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField7NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField7NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField7NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField7NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField7NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField7NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField7NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField7NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField7NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField7NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCustomField7OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCustomField7UpdateInput = {
  /** Actions for the "MetadataCustomField7.archive" relation */
  archive?: InputMaybe<MetadataCustomField7NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField7.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField7NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField7.photo" relation */
  photo?: InputMaybe<MetadataCustomField7NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCustomField7" nodes by specifying some conditions */
export type MetadataCustomField7WhereInput = {
  AND?: InputMaybe<Array<MetadataCustomField7WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCustomField7WhereInput>;
  OR?: InputMaybe<Array<MetadataCustomField7WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCustomField7" node. */
export type MetadataCustomField7WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCustomField7WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCustomField7.metadata" relation */
  metadata: MetadataCustomField7NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField7.photo" relation */
  photo: MetadataCustomField7NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField7WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCustomField7.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField7NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField7.photo" relation */
  photo?: InputMaybe<MetadataCustomField7NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField7WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCustomField7.archive" relation */
  archive: MetadataCustomField7NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField7.photo" relation */
  photo: MetadataCustomField7NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField7WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCustomField7.archive" relation */
  archive?: InputMaybe<MetadataCustomField7NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField7.photo" relation */
  photo?: InputMaybe<MetadataCustomField7NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField7WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCustomField7.archive" relation */
  archive: MetadataCustomField7NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField7.metadata" relation */
  metadata: MetadataCustomField7NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField7WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCustomField7.archive" relation */
  archive?: InputMaybe<MetadataCustomField7NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField7.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField7NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataCustomField9" resource's node */
export type MetadataCustomField9 = {
  __typename?: 'MetadataCustomField9';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataCustomField9CreateInput = {
  /** Actions for the "MetadataCustomField9.archive" relation */
  archive: MetadataCustomField9NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField9.metadata" relation */
  metadata: MetadataCustomField9NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField9.photo" relation */
  photo: MetadataCustomField9NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField9NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataCustomField9" node, through the "MetadataCustomField9.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataCustomField9" node, through the "MetadataCustomField9.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataCustomField9" node, through the "MetadataCustomField9.archive" relation. */
  update?: InputMaybe<MetadataCustomField9NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataCustomField9" node, through the "MetadataCustomField9.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField9NestedUpsertArchiveCreateInput>;
};

export type MetadataCustomField9NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.archive" relation. */
  update?: InputMaybe<MetadataCustomField9NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.archive" relation. */
  upsert?: InputMaybe<MetadataCustomField9NestedUpsertArchiveUpdateInput>;
};

export type MetadataCustomField9NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataCustomField9" node, through the "MetadataCustomField9.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataCustomField9" node, through the "MetadataCustomField9.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataCustomField9" node, through the "MetadataCustomField9.metadata" relation. */
  update?: InputMaybe<MetadataCustomField9NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataCustomField9" node, through the "MetadataCustomField9.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField9NestedUpsertMetadataCreateInput>;
};

export type MetadataCustomField9NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.metadata" relation. */
  update?: InputMaybe<MetadataCustomField9NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.metadata" relation. */
  upsert?: InputMaybe<MetadataCustomField9NestedUpsertMetadataUpdateInput>;
};

export type MetadataCustomField9NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataCustomField9" node, through the "MetadataCustomField9.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataCustomField9" node, through the "MetadataCustomField9.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataCustomField9" node, through the "MetadataCustomField9.photo" relation. */
  update?: InputMaybe<MetadataCustomField9NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataCustomField9" node, through the "MetadataCustomField9.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField9NestedUpsertPhotoCreateInput>;
};

export type MetadataCustomField9NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.photo" relation. */
  update?: InputMaybe<MetadataCustomField9NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataCustomField9" node, through the "MetadataCustomField9.photo" relation. */
  upsert?: InputMaybe<MetadataCustomField9NestedUpsertPhotoUpdateInput>;
};

export type MetadataCustomField9NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField9NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField9NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField9NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField9NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField9NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField9NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField9NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataCustomField9NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField9NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataCustomField9NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataCustomField9NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataCustomField9OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataCustomField9UpdateInput = {
  /** Actions for the "MetadataCustomField9.archive" relation */
  archive?: InputMaybe<MetadataCustomField9NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField9.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField9NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField9.photo" relation */
  photo?: InputMaybe<MetadataCustomField9NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataCustomField9" nodes by specifying some conditions */
export type MetadataCustomField9WhereInput = {
  AND?: InputMaybe<Array<MetadataCustomField9WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataCustomField9WhereInput>;
  OR?: InputMaybe<Array<MetadataCustomField9WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataCustomField9" node. */
export type MetadataCustomField9WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataCustomField9WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataCustomField9.metadata" relation */
  metadata: MetadataCustomField9NestedMetadataCreateInput;
  /** Actions for the "MetadataCustomField9.photo" relation */
  photo: MetadataCustomField9NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField9WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataCustomField9.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField9NestedMetadataUpdateInput>;
  /** Actions for the "MetadataCustomField9.photo" relation */
  photo?: InputMaybe<MetadataCustomField9NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField9WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataCustomField9.archive" relation */
  archive: MetadataCustomField9NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField9.photo" relation */
  photo: MetadataCustomField9NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField9WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataCustomField9.archive" relation */
  archive?: InputMaybe<MetadataCustomField9NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField9.photo" relation */
  photo?: InputMaybe<MetadataCustomField9NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField9WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataCustomField9.archive" relation */
  archive: MetadataCustomField9NestedArchiveCreateInput;
  /** Actions for the "MetadataCustomField9.metadata" relation */
  metadata: MetadataCustomField9NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataCustomField9WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataCustomField9.archive" relation */
  archive?: InputMaybe<MetadataCustomField9NestedArchiveUpdateInput>;
  /** Actions for the "MetadataCustomField9.metadata" relation */
  metadata?: InputMaybe<MetadataCustomField9NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDateCreated" resource's node */
export type MetadataDateCreated = {
  __typename?: 'MetadataDateCreated';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDateCreatedCreateInput = {
  /** Actions for the "MetadataDateCreated.archive" relation */
  archive: MetadataDateCreatedNestedArchiveCreateInput;
  /** Actions for the "MetadataDateCreated.metadata" relation */
  metadata: MetadataDateCreatedNestedMetadataCreateInput;
  /** Actions for the "MetadataDateCreated.photo" relation */
  photo: MetadataDateCreatedNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateCreatedNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDateCreated" node, through the "MetadataDateCreated.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDateCreated" node, through the "MetadataDateCreated.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDateCreated" node, through the "MetadataDateCreated.archive" relation. */
  update?: InputMaybe<MetadataDateCreatedNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDateCreated" node, through the "MetadataDateCreated.archive" relation. */
  upsert?: InputMaybe<MetadataDateCreatedNestedUpsertArchiveCreateInput>;
};

export type MetadataDateCreatedNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.archive" relation. */
  update?: InputMaybe<MetadataDateCreatedNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.archive" relation. */
  upsert?: InputMaybe<MetadataDateCreatedNestedUpsertArchiveUpdateInput>;
};

export type MetadataDateCreatedNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDateCreated" node, through the "MetadataDateCreated.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDateCreated" node, through the "MetadataDateCreated.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDateCreated" node, through the "MetadataDateCreated.metadata" relation. */
  update?: InputMaybe<MetadataDateCreatedNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDateCreated" node, through the "MetadataDateCreated.metadata" relation. */
  upsert?: InputMaybe<MetadataDateCreatedNestedUpsertMetadataCreateInput>;
};

export type MetadataDateCreatedNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.metadata" relation. */
  update?: InputMaybe<MetadataDateCreatedNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.metadata" relation. */
  upsert?: InputMaybe<MetadataDateCreatedNestedUpsertMetadataUpdateInput>;
};

export type MetadataDateCreatedNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDateCreated" node, through the "MetadataDateCreated.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDateCreated" node, through the "MetadataDateCreated.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDateCreated" node, through the "MetadataDateCreated.photo" relation. */
  update?: InputMaybe<MetadataDateCreatedNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDateCreated" node, through the "MetadataDateCreated.photo" relation. */
  upsert?: InputMaybe<MetadataDateCreatedNestedUpsertPhotoCreateInput>;
};

export type MetadataDateCreatedNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.photo" relation. */
  update?: InputMaybe<MetadataDateCreatedNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDateCreated" node, through the "MetadataDateCreated.photo" relation. */
  upsert?: InputMaybe<MetadataDateCreatedNestedUpsertPhotoUpdateInput>;
};

export type MetadataDateCreatedNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateCreatedNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateCreatedNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateCreatedNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateCreatedNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDateCreatedNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDateCreatedNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateCreatedNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateCreatedNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateCreatedNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateCreatedNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDateCreatedNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDateCreatedOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDateCreatedUpdateInput = {
  /** Actions for the "MetadataDateCreated.archive" relation */
  archive?: InputMaybe<MetadataDateCreatedNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDateCreated.metadata" relation */
  metadata?: InputMaybe<MetadataDateCreatedNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDateCreated.photo" relation */
  photo?: InputMaybe<MetadataDateCreatedNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDateCreated" nodes by specifying some conditions */
export type MetadataDateCreatedWhereInput = {
  AND?: InputMaybe<Array<MetadataDateCreatedWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDateCreatedWhereInput>;
  OR?: InputMaybe<Array<MetadataDateCreatedWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDateCreated" node. */
export type MetadataDateCreatedWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDateCreatedWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDateCreated.metadata" relation */
  metadata: MetadataDateCreatedNestedMetadataCreateInput;
  /** Actions for the "MetadataDateCreated.photo" relation */
  photo: MetadataDateCreatedNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateCreatedWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDateCreated.metadata" relation */
  metadata?: InputMaybe<MetadataDateCreatedNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDateCreated.photo" relation */
  photo?: InputMaybe<MetadataDateCreatedNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateCreatedWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDateCreated.archive" relation */
  archive: MetadataDateCreatedNestedArchiveCreateInput;
  /** Actions for the "MetadataDateCreated.photo" relation */
  photo: MetadataDateCreatedNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateCreatedWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDateCreated.archive" relation */
  archive?: InputMaybe<MetadataDateCreatedNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDateCreated.photo" relation */
  photo?: InputMaybe<MetadataDateCreatedNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateCreatedWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDateCreated.archive" relation */
  archive: MetadataDateCreatedNestedArchiveCreateInput;
  /** Actions for the "MetadataDateCreated.metadata" relation */
  metadata: MetadataDateCreatedNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateCreatedWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDateCreated.archive" relation */
  archive?: InputMaybe<MetadataDateCreatedNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDateCreated.metadata" relation */
  metadata?: InputMaybe<MetadataDateCreatedNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDateTimeDigitized" resource's node */
export type MetadataDateTimeDigitized = {
  __typename?: 'MetadataDateTimeDigitized';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDateTimeDigitizedCreateInput = {
  /** Actions for the "MetadataDateTimeDigitized.archive" relation */
  archive: MetadataDateTimeDigitizedNestedArchiveCreateInput;
  /** Actions for the "MetadataDateTimeDigitized.metadata" relation */
  metadata: MetadataDateTimeDigitizedNestedMetadataCreateInput;
  /** Actions for the "MetadataDateTimeDigitized.photo" relation */
  photo: MetadataDateTimeDigitizedNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeDigitizedNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.archive" relation. */
  update?: InputMaybe<MetadataDateTimeDigitizedNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.archive" relation. */
  upsert?: InputMaybe<MetadataDateTimeDigitizedNestedUpsertArchiveCreateInput>;
};

export type MetadataDateTimeDigitizedNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.archive" relation. */
  update?: InputMaybe<MetadataDateTimeDigitizedNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.archive" relation. */
  upsert?: InputMaybe<MetadataDateTimeDigitizedNestedUpsertArchiveUpdateInput>;
};

export type MetadataDateTimeDigitizedNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.metadata" relation. */
  update?: InputMaybe<MetadataDateTimeDigitizedNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.metadata" relation. */
  upsert?: InputMaybe<MetadataDateTimeDigitizedNestedUpsertMetadataCreateInput>;
};

export type MetadataDateTimeDigitizedNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.metadata" relation. */
  update?: InputMaybe<MetadataDateTimeDigitizedNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.metadata" relation. */
  upsert?: InputMaybe<MetadataDateTimeDigitizedNestedUpsertMetadataUpdateInput>;
};

export type MetadataDateTimeDigitizedNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.photo" relation. */
  update?: InputMaybe<MetadataDateTimeDigitizedNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.photo" relation. */
  upsert?: InputMaybe<MetadataDateTimeDigitizedNestedUpsertPhotoCreateInput>;
};

export type MetadataDateTimeDigitizedNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.photo" relation. */
  update?: InputMaybe<MetadataDateTimeDigitizedNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDateTimeDigitized" node, through the "MetadataDateTimeDigitized.photo" relation. */
  upsert?: InputMaybe<MetadataDateTimeDigitizedNestedUpsertPhotoUpdateInput>;
};

export type MetadataDateTimeDigitizedNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateTimeDigitizedNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateTimeDigitizedNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateTimeDigitizedNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateTimeDigitizedNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDateTimeDigitizedNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDateTimeDigitizedNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateTimeDigitizedNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateTimeDigitizedNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateTimeDigitizedNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateTimeDigitizedNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDateTimeDigitizedNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDateTimeDigitizedOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDateTimeDigitizedUpdateInput = {
  /** Actions for the "MetadataDateTimeDigitized.archive" relation */
  archive?: InputMaybe<MetadataDateTimeDigitizedNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDateTimeDigitized.metadata" relation */
  metadata?: InputMaybe<MetadataDateTimeDigitizedNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDateTimeDigitized.photo" relation */
  photo?: InputMaybe<MetadataDateTimeDigitizedNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDateTimeDigitized" nodes by specifying some conditions */
export type MetadataDateTimeDigitizedWhereInput = {
  AND?: InputMaybe<Array<MetadataDateTimeDigitizedWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
  OR?: InputMaybe<Array<MetadataDateTimeDigitizedWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDateTimeDigitized" node. */
export type MetadataDateTimeDigitizedWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDateTimeDigitizedWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDateTimeDigitized.metadata" relation */
  metadata: MetadataDateTimeDigitizedNestedMetadataCreateInput;
  /** Actions for the "MetadataDateTimeDigitized.photo" relation */
  photo: MetadataDateTimeDigitizedNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeDigitizedWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDateTimeDigitized.metadata" relation */
  metadata?: InputMaybe<MetadataDateTimeDigitizedNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDateTimeDigitized.photo" relation */
  photo?: InputMaybe<MetadataDateTimeDigitizedNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeDigitizedWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDateTimeDigitized.archive" relation */
  archive: MetadataDateTimeDigitizedNestedArchiveCreateInput;
  /** Actions for the "MetadataDateTimeDigitized.photo" relation */
  photo: MetadataDateTimeDigitizedNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeDigitizedWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDateTimeDigitized.archive" relation */
  archive?: InputMaybe<MetadataDateTimeDigitizedNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDateTimeDigitized.photo" relation */
  photo?: InputMaybe<MetadataDateTimeDigitizedNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeDigitizedWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDateTimeDigitized.archive" relation */
  archive: MetadataDateTimeDigitizedNestedArchiveCreateInput;
  /** Actions for the "MetadataDateTimeDigitized.metadata" relation */
  metadata: MetadataDateTimeDigitizedNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeDigitizedWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDateTimeDigitized.archive" relation */
  archive?: InputMaybe<MetadataDateTimeDigitizedNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDateTimeDigitized.metadata" relation */
  metadata?: InputMaybe<MetadataDateTimeDigitizedNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDateTimeOriginal" resource's node */
export type MetadataDateTimeOriginal = {
  __typename?: 'MetadataDateTimeOriginal';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDateTimeOriginalCreateInput = {
  /** Actions for the "MetadataDateTimeOriginal.archive" relation */
  archive: MetadataDateTimeOriginalNestedArchiveCreateInput;
  /** Actions for the "MetadataDateTimeOriginal.metadata" relation */
  metadata: MetadataDateTimeOriginalNestedMetadataCreateInput;
  /** Actions for the "MetadataDateTimeOriginal.photo" relation */
  photo: MetadataDateTimeOriginalNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeOriginalNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.archive" relation. */
  update?: InputMaybe<MetadataDateTimeOriginalNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.archive" relation. */
  upsert?: InputMaybe<MetadataDateTimeOriginalNestedUpsertArchiveCreateInput>;
};

export type MetadataDateTimeOriginalNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.archive" relation. */
  update?: InputMaybe<MetadataDateTimeOriginalNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.archive" relation. */
  upsert?: InputMaybe<MetadataDateTimeOriginalNestedUpsertArchiveUpdateInput>;
};

export type MetadataDateTimeOriginalNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.metadata" relation. */
  update?: InputMaybe<MetadataDateTimeOriginalNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.metadata" relation. */
  upsert?: InputMaybe<MetadataDateTimeOriginalNestedUpsertMetadataCreateInput>;
};

export type MetadataDateTimeOriginalNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.metadata" relation. */
  update?: InputMaybe<MetadataDateTimeOriginalNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.metadata" relation. */
  upsert?: InputMaybe<MetadataDateTimeOriginalNestedUpsertMetadataUpdateInput>;
};

export type MetadataDateTimeOriginalNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.photo" relation. */
  update?: InputMaybe<MetadataDateTimeOriginalNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.photo" relation. */
  upsert?: InputMaybe<MetadataDateTimeOriginalNestedUpsertPhotoCreateInput>;
};

export type MetadataDateTimeOriginalNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.photo" relation. */
  update?: InputMaybe<MetadataDateTimeOriginalNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDateTimeOriginal" node, through the "MetadataDateTimeOriginal.photo" relation. */
  upsert?: InputMaybe<MetadataDateTimeOriginalNestedUpsertPhotoUpdateInput>;
};

export type MetadataDateTimeOriginalNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateTimeOriginalNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateTimeOriginalNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateTimeOriginalNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateTimeOriginalNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDateTimeOriginalNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDateTimeOriginalNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateTimeOriginalNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDateTimeOriginalNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateTimeOriginalNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDateTimeOriginalNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDateTimeOriginalNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDateTimeOriginalOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDateTimeOriginalUpdateInput = {
  /** Actions for the "MetadataDateTimeOriginal.archive" relation */
  archive?: InputMaybe<MetadataDateTimeOriginalNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDateTimeOriginal.metadata" relation */
  metadata?: InputMaybe<MetadataDateTimeOriginalNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDateTimeOriginal.photo" relation */
  photo?: InputMaybe<MetadataDateTimeOriginalNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDateTimeOriginal" nodes by specifying some conditions */
export type MetadataDateTimeOriginalWhereInput = {
  AND?: InputMaybe<Array<MetadataDateTimeOriginalWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
  OR?: InputMaybe<Array<MetadataDateTimeOriginalWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDateTimeOriginal" node. */
export type MetadataDateTimeOriginalWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDateTimeOriginalWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDateTimeOriginal.metadata" relation */
  metadata: MetadataDateTimeOriginalNestedMetadataCreateInput;
  /** Actions for the "MetadataDateTimeOriginal.photo" relation */
  photo: MetadataDateTimeOriginalNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeOriginalWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDateTimeOriginal.metadata" relation */
  metadata?: InputMaybe<MetadataDateTimeOriginalNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDateTimeOriginal.photo" relation */
  photo?: InputMaybe<MetadataDateTimeOriginalNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeOriginalWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDateTimeOriginal.archive" relation */
  archive: MetadataDateTimeOriginalNestedArchiveCreateInput;
  /** Actions for the "MetadataDateTimeOriginal.photo" relation */
  photo: MetadataDateTimeOriginalNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeOriginalWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDateTimeOriginal.archive" relation */
  archive?: InputMaybe<MetadataDateTimeOriginalNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDateTimeOriginal.photo" relation */
  photo?: InputMaybe<MetadataDateTimeOriginalNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeOriginalWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDateTimeOriginal.archive" relation */
  archive: MetadataDateTimeOriginalNestedArchiveCreateInput;
  /** Actions for the "MetadataDateTimeOriginal.metadata" relation */
  metadata: MetadataDateTimeOriginalNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDateTimeOriginalWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDateTimeOriginal.archive" relation */
  archive?: InputMaybe<MetadataDateTimeOriginalNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDateTimeOriginal.metadata" relation */
  metadata?: InputMaybe<MetadataDateTimeOriginalNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryAccount" resource's node */
export type MetadataDeliveryAccount = {
  __typename?: 'MetadataDeliveryAccount';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryAccountCreateInput = {
  /** Actions for the "MetadataDeliveryAccount.archive" relation */
  archive: MetadataDeliveryAccountNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryAccount.metadata" relation */
  metadata: MetadataDeliveryAccountNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryAccount.photo" relation */
  photo: MetadataDeliveryAccountNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryAccountNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.archive" relation. */
  update?: InputMaybe<MetadataDeliveryAccountNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryAccountNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryAccountNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.archive" relation. */
  update?: InputMaybe<MetadataDeliveryAccountNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryAccountNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryAccountNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryAccountNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryAccountNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryAccountNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryAccountNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryAccountNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryAccountNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.photo" relation. */
  update?: InputMaybe<MetadataDeliveryAccountNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryAccountNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryAccountNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.photo" relation. */
  update?: InputMaybe<MetadataDeliveryAccountNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryAccount" node, through the "MetadataDeliveryAccount.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryAccountNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryAccountNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryAccountNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryAccountNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryAccountNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryAccountNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryAccountNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryAccountNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryAccountNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryAccountNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryAccountNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryAccountNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryAccountNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryAccountOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryAccountUpdateInput = {
  /** Actions for the "MetadataDeliveryAccount.archive" relation */
  archive?: InputMaybe<MetadataDeliveryAccountNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryAccount.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryAccountNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryAccount.photo" relation */
  photo?: InputMaybe<MetadataDeliveryAccountNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryAccount" nodes by specifying some conditions */
export type MetadataDeliveryAccountWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryAccountWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryAccountWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryAccountWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryAccount" node. */
export type MetadataDeliveryAccountWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryAccountWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryAccount.metadata" relation */
  metadata: MetadataDeliveryAccountNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryAccount.photo" relation */
  photo: MetadataDeliveryAccountNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryAccountWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryAccount.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryAccountNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryAccount.photo" relation */
  photo?: InputMaybe<MetadataDeliveryAccountNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryAccountWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryAccount.archive" relation */
  archive: MetadataDeliveryAccountNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryAccount.photo" relation */
  photo: MetadataDeliveryAccountNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryAccountWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryAccount.archive" relation */
  archive?: InputMaybe<MetadataDeliveryAccountNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryAccount.photo" relation */
  photo?: InputMaybe<MetadataDeliveryAccountNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryAccountWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryAccount.archive" relation */
  archive: MetadataDeliveryAccountNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryAccount.metadata" relation */
  metadata: MetadataDeliveryAccountNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryAccountWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryAccount.archive" relation */
  archive?: InputMaybe<MetadataDeliveryAccountNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryAccount.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryAccountNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryCompany" resource's node */
export type MetadataDeliveryCompany = {
  __typename?: 'MetadataDeliveryCompany';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryCompanyCreateInput = {
  /** Actions for the "MetadataDeliveryCompany.archive" relation */
  archive: MetadataDeliveryCompanyNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryCompany.metadata" relation */
  metadata: MetadataDeliveryCompanyNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryCompany.photo" relation */
  photo: MetadataDeliveryCompanyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCompanyNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.archive" relation. */
  update?: InputMaybe<MetadataDeliveryCompanyNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryCompanyNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryCompanyNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.archive" relation. */
  update?: InputMaybe<MetadataDeliveryCompanyNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryCompanyNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryCompanyNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryCompanyNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryCompanyNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryCompanyNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryCompanyNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryCompanyNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryCompanyNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.photo" relation. */
  update?: InputMaybe<MetadataDeliveryCompanyNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryCompanyNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryCompanyNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.photo" relation. */
  update?: InputMaybe<MetadataDeliveryCompanyNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryCompany" node, through the "MetadataDeliveryCompany.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryCompanyNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryCompanyNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryCompanyNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryCompanyNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryCompanyNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryCompanyNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryCompanyNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryCompanyNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryCompanyNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryCompanyNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryCompanyNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryCompanyNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryCompanyNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryCompanyOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryCompanyUpdateInput = {
  /** Actions for the "MetadataDeliveryCompany.archive" relation */
  archive?: InputMaybe<MetadataDeliveryCompanyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryCompany.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryCompanyNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryCompany.photo" relation */
  photo?: InputMaybe<MetadataDeliveryCompanyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryCompany" nodes by specifying some conditions */
export type MetadataDeliveryCompanyWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryCompanyWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryCompanyWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryCompany" node. */
export type MetadataDeliveryCompanyWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryCompanyWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryCompany.metadata" relation */
  metadata: MetadataDeliveryCompanyNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryCompany.photo" relation */
  photo: MetadataDeliveryCompanyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCompanyWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryCompany.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryCompanyNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryCompany.photo" relation */
  photo?: InputMaybe<MetadataDeliveryCompanyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCompanyWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryCompany.archive" relation */
  archive: MetadataDeliveryCompanyNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryCompany.photo" relation */
  photo: MetadataDeliveryCompanyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCompanyWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryCompany.archive" relation */
  archive?: InputMaybe<MetadataDeliveryCompanyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryCompany.photo" relation */
  photo?: InputMaybe<MetadataDeliveryCompanyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCompanyWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryCompany.archive" relation */
  archive: MetadataDeliveryCompanyNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryCompany.metadata" relation */
  metadata: MetadataDeliveryCompanyNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCompanyWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryCompany.archive" relation */
  archive?: InputMaybe<MetadataDeliveryCompanyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryCompany.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryCompanyNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryCopyright" resource's node */
export type MetadataDeliveryCopyright = {
  __typename?: 'MetadataDeliveryCopyright';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryCopyrightCreateInput = {
  /** Actions for the "MetadataDeliveryCopyright.archive" relation */
  archive: MetadataDeliveryCopyrightNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryCopyright.metadata" relation */
  metadata: MetadataDeliveryCopyrightNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryCopyright.photo" relation */
  photo: MetadataDeliveryCopyrightNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCopyrightNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.archive" relation. */
  update?: InputMaybe<MetadataDeliveryCopyrightNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryCopyrightNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryCopyrightNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.archive" relation. */
  update?: InputMaybe<MetadataDeliveryCopyrightNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryCopyrightNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryCopyrightNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryCopyrightNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryCopyrightNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryCopyrightNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryCopyrightNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryCopyrightNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryCopyrightNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.photo" relation. */
  update?: InputMaybe<MetadataDeliveryCopyrightNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryCopyrightNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryCopyrightNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.photo" relation. */
  update?: InputMaybe<MetadataDeliveryCopyrightNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryCopyright" node, through the "MetadataDeliveryCopyright.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryCopyrightNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryCopyrightNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryCopyrightNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryCopyrightNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryCopyrightNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryCopyrightNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryCopyrightNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryCopyrightNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryCopyrightNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryCopyrightNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryCopyrightNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryCopyrightNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryCopyrightNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryCopyrightOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryCopyrightUpdateInput = {
  /** Actions for the "MetadataDeliveryCopyright.archive" relation */
  archive?: InputMaybe<MetadataDeliveryCopyrightNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryCopyright.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryCopyrightNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryCopyright.photo" relation */
  photo?: InputMaybe<MetadataDeliveryCopyrightNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryCopyright" nodes by specifying some conditions */
export type MetadataDeliveryCopyrightWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryCopyrightWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryCopyrightWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryCopyright" node. */
export type MetadataDeliveryCopyrightWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryCopyrightWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryCopyright.metadata" relation */
  metadata: MetadataDeliveryCopyrightNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryCopyright.photo" relation */
  photo: MetadataDeliveryCopyrightNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCopyrightWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryCopyright.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryCopyrightNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryCopyright.photo" relation */
  photo?: InputMaybe<MetadataDeliveryCopyrightNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCopyrightWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryCopyright.archive" relation */
  archive: MetadataDeliveryCopyrightNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryCopyright.photo" relation */
  photo: MetadataDeliveryCopyrightNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCopyrightWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryCopyright.archive" relation */
  archive?: InputMaybe<MetadataDeliveryCopyrightNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryCopyright.photo" relation */
  photo?: InputMaybe<MetadataDeliveryCopyrightNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCopyrightWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryCopyright.archive" relation */
  archive: MetadataDeliveryCopyrightNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryCopyright.metadata" relation */
  metadata: MetadataDeliveryCopyrightNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryCopyrightWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryCopyright.archive" relation */
  archive?: InputMaybe<MetadataDeliveryCopyrightNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryCopyright.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryCopyrightNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryDateFolder" resource's node */
export type MetadataDeliveryDateFolder = {
  __typename?: 'MetadataDeliveryDateFolder';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryDateFolderCreateInput = {
  /** Actions for the "MetadataDeliveryDateFolder.archive" relation */
  archive: MetadataDeliveryDateFolderNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDateFolder.metadata" relation */
  metadata: MetadataDeliveryDateFolderNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryDateFolder.photo" relation */
  photo: MetadataDeliveryDateFolderNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateFolderNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.archive" relation. */
  update?: InputMaybe<MetadataDeliveryDateFolderNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateFolderNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryDateFolderNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.archive" relation. */
  update?: InputMaybe<MetadataDeliveryDateFolderNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateFolderNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryDateFolderNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryDateFolderNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateFolderNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryDateFolderNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryDateFolderNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateFolderNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryDateFolderNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.photo" relation. */
  update?: InputMaybe<MetadataDeliveryDateFolderNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateFolderNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryDateFolderNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.photo" relation. */
  update?: InputMaybe<MetadataDeliveryDateFolderNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryDateFolder" node, through the "MetadataDeliveryDateFolder.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateFolderNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryDateFolderNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDateFolderNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDateFolderNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDateFolderNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDateFolderNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDateFolderNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDateFolderNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDateFolderNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDateFolderNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDateFolderNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDateFolderNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDateFolderNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryDateFolderOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryDateFolderUpdateInput = {
  /** Actions for the "MetadataDeliveryDateFolder.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDateFolderNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDateFolder.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDateFolderNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryDateFolder.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDateFolderNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryDateFolder" nodes by specifying some conditions */
export type MetadataDeliveryDateFolderWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryDateFolderWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryDateFolderWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryDateFolder" node. */
export type MetadataDeliveryDateFolderWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryDateFolderWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryDateFolder.metadata" relation */
  metadata: MetadataDeliveryDateFolderNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryDateFolder.photo" relation */
  photo: MetadataDeliveryDateFolderNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateFolderWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryDateFolder.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDateFolderNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryDateFolder.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDateFolderNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateFolderWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryDateFolder.archive" relation */
  archive: MetadataDeliveryDateFolderNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDateFolder.photo" relation */
  photo: MetadataDeliveryDateFolderNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateFolderWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryDateFolder.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDateFolderNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDateFolder.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDateFolderNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateFolderWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryDateFolder.archive" relation */
  archive: MetadataDeliveryDateFolderNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDateFolder.metadata" relation */
  metadata: MetadataDeliveryDateFolderNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateFolderWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryDateFolder.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDateFolderNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDateFolder.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDateFolderNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryDateTime" resource's node */
export type MetadataDeliveryDateTime = {
  __typename?: 'MetadataDeliveryDateTime';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryDateTimeCreateInput = {
  /** Actions for the "MetadataDeliveryDateTime.archive" relation */
  archive: MetadataDeliveryDateTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDateTime.metadata" relation */
  metadata: MetadataDeliveryDateTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryDateTime.photo" relation */
  photo: MetadataDeliveryDateTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateTimeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.archive" relation. */
  update?: InputMaybe<MetadataDeliveryDateTimeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateTimeNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryDateTimeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.archive" relation. */
  update?: InputMaybe<MetadataDeliveryDateTimeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateTimeNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryDateTimeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryDateTimeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateTimeNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryDateTimeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryDateTimeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateTimeNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryDateTimeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.photo" relation. */
  update?: InputMaybe<MetadataDeliveryDateTimeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateTimeNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryDateTimeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.photo" relation. */
  update?: InputMaybe<MetadataDeliveryDateTimeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryDateTime" node, through the "MetadataDeliveryDateTime.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryDateTimeNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryDateTimeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDateTimeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDateTimeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDateTimeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDateTimeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDateTimeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDateTimeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDateTimeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDateTimeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDateTimeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDateTimeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDateTimeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryDateTimeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryDateTimeUpdateInput = {
  /** Actions for the "MetadataDeliveryDateTime.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDateTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDateTime.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDateTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryDateTime.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDateTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryDateTime" nodes by specifying some conditions */
export type MetadataDeliveryDateTimeWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryDateTimeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryDateTimeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryDateTime" node. */
export type MetadataDeliveryDateTimeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryDateTimeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryDateTime.metadata" relation */
  metadata: MetadataDeliveryDateTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryDateTime.photo" relation */
  photo: MetadataDeliveryDateTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateTimeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryDateTime.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDateTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryDateTime.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDateTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateTimeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryDateTime.archive" relation */
  archive: MetadataDeliveryDateTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDateTime.photo" relation */
  photo: MetadataDeliveryDateTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateTimeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryDateTime.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDateTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDateTime.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDateTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateTimeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryDateTime.archive" relation */
  archive: MetadataDeliveryDateTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDateTime.metadata" relation */
  metadata: MetadataDeliveryDateTimeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDateTimeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryDateTime.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDateTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDateTime.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDateTimeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryDcCreator" resource's node */
export type MetadataDeliveryDcCreator = {
  __typename?: 'MetadataDeliveryDcCreator';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryDcCreatorCreateInput = {
  /** Actions for the "MetadataDeliveryDcCreator.archive" relation */
  archive: MetadataDeliveryDcCreatorNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDcCreator.metadata" relation */
  metadata: MetadataDeliveryDcCreatorNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryDcCreator.photo" relation */
  photo: MetadataDeliveryDcCreatorNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcCreatorNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.archive" relation. */
  update?: InputMaybe<MetadataDeliveryDcCreatorNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcCreatorNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryDcCreatorNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.archive" relation. */
  update?: InputMaybe<MetadataDeliveryDcCreatorNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcCreatorNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryDcCreatorNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryDcCreatorNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcCreatorNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryDcCreatorNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryDcCreatorNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcCreatorNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryDcCreatorNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.photo" relation. */
  update?: InputMaybe<MetadataDeliveryDcCreatorNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcCreatorNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryDcCreatorNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.photo" relation. */
  update?: InputMaybe<MetadataDeliveryDcCreatorNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryDcCreator" node, through the "MetadataDeliveryDcCreator.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcCreatorNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryDcCreatorNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDcCreatorNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDcCreatorNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDcCreatorNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDcCreatorNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDcCreatorNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDcCreatorNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDcCreatorNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDcCreatorNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDcCreatorNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDcCreatorNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDcCreatorNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryDcCreatorOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryDcCreatorUpdateInput = {
  /** Actions for the "MetadataDeliveryDcCreator.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDcCreatorNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDcCreator.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDcCreatorNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryDcCreator.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDcCreatorNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryDcCreator" nodes by specifying some conditions */
export type MetadataDeliveryDcCreatorWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryDcCreatorWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryDcCreatorWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryDcCreator" node. */
export type MetadataDeliveryDcCreatorWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryDcCreatorWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryDcCreator.metadata" relation */
  metadata: MetadataDeliveryDcCreatorNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryDcCreator.photo" relation */
  photo: MetadataDeliveryDcCreatorNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcCreatorWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryDcCreator.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDcCreatorNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryDcCreator.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDcCreatorNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcCreatorWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryDcCreator.archive" relation */
  archive: MetadataDeliveryDcCreatorNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDcCreator.photo" relation */
  photo: MetadataDeliveryDcCreatorNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcCreatorWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryDcCreator.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDcCreatorNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDcCreator.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDcCreatorNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcCreatorWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryDcCreator.archive" relation */
  archive: MetadataDeliveryDcCreatorNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDcCreator.metadata" relation */
  metadata: MetadataDeliveryDcCreatorNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcCreatorWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryDcCreator.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDcCreatorNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDcCreator.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDcCreatorNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryDcRights" resource's node */
export type MetadataDeliveryDcRights = {
  __typename?: 'MetadataDeliveryDcRights';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryDcRightsCreateInput = {
  /** Actions for the "MetadataDeliveryDcRights.archive" relation */
  archive: MetadataDeliveryDcRightsNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDcRights.metadata" relation */
  metadata: MetadataDeliveryDcRightsNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryDcRights.photo" relation */
  photo: MetadataDeliveryDcRightsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcRightsNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.archive" relation. */
  update?: InputMaybe<MetadataDeliveryDcRightsNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcRightsNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryDcRightsNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.archive" relation. */
  update?: InputMaybe<MetadataDeliveryDcRightsNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcRightsNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryDcRightsNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryDcRightsNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcRightsNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryDcRightsNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryDcRightsNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcRightsNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryDcRightsNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.photo" relation. */
  update?: InputMaybe<MetadataDeliveryDcRightsNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcRightsNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryDcRightsNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.photo" relation. */
  update?: InputMaybe<MetadataDeliveryDcRightsNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryDcRights" node, through the "MetadataDeliveryDcRights.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryDcRightsNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryDcRightsNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDcRightsNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDcRightsNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDcRightsNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDcRightsNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDcRightsNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDcRightsNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDcRightsNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryDcRightsNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDcRightsNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryDcRightsNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryDcRightsNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryDcRightsOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryDcRightsUpdateInput = {
  /** Actions for the "MetadataDeliveryDcRights.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDcRightsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDcRights.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDcRightsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryDcRights.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDcRightsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryDcRights" nodes by specifying some conditions */
export type MetadataDeliveryDcRightsWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryDcRightsWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryDcRightsWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryDcRights" node. */
export type MetadataDeliveryDcRightsWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryDcRightsWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryDcRights.metadata" relation */
  metadata: MetadataDeliveryDcRightsNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryDcRights.photo" relation */
  photo: MetadataDeliveryDcRightsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcRightsWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryDcRights.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDcRightsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryDcRights.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDcRightsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcRightsWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryDcRights.archive" relation */
  archive: MetadataDeliveryDcRightsNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDcRights.photo" relation */
  photo: MetadataDeliveryDcRightsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcRightsWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryDcRights.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDcRightsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDcRights.photo" relation */
  photo?: InputMaybe<MetadataDeliveryDcRightsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcRightsWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryDcRights.archive" relation */
  archive: MetadataDeliveryDcRightsNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryDcRights.metadata" relation */
  metadata: MetadataDeliveryDcRightsNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryDcRightsWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryDcRights.archive" relation */
  archive?: InputMaybe<MetadataDeliveryDcRightsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryDcRights.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryDcRightsNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryFileType" resource's node */
export type MetadataDeliveryFileType = {
  __typename?: 'MetadataDeliveryFileType';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryFileTypeCreateInput = {
  /** Actions for the "MetadataDeliveryFileType.archive" relation */
  archive: MetadataDeliveryFileTypeNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryFileType.metadata" relation */
  metadata: MetadataDeliveryFileTypeNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryFileType.photo" relation */
  photo: MetadataDeliveryFileTypeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFileTypeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.archive" relation. */
  update?: InputMaybe<MetadataDeliveryFileTypeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryFileTypeNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryFileTypeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.archive" relation. */
  update?: InputMaybe<MetadataDeliveryFileTypeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryFileTypeNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryFileTypeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryFileTypeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryFileTypeNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryFileTypeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryFileTypeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryFileTypeNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryFileTypeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.photo" relation. */
  update?: InputMaybe<MetadataDeliveryFileTypeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryFileTypeNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryFileTypeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.photo" relation. */
  update?: InputMaybe<MetadataDeliveryFileTypeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryFileType" node, through the "MetadataDeliveryFileType.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryFileTypeNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryFileTypeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryFileTypeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryFileTypeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryFileTypeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryFileTypeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryFileTypeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryFileTypeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryFileTypeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryFileTypeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryFileTypeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryFileTypeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryFileTypeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryFileTypeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryFileTypeUpdateInput = {
  /** Actions for the "MetadataDeliveryFileType.archive" relation */
  archive?: InputMaybe<MetadataDeliveryFileTypeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryFileType.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryFileTypeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryFileType.photo" relation */
  photo?: InputMaybe<MetadataDeliveryFileTypeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryFileType" nodes by specifying some conditions */
export type MetadataDeliveryFileTypeWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryFileTypeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryFileTypeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryFileType" node. */
export type MetadataDeliveryFileTypeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryFileTypeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryFileType.metadata" relation */
  metadata: MetadataDeliveryFileTypeNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryFileType.photo" relation */
  photo: MetadataDeliveryFileTypeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFileTypeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryFileType.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryFileTypeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryFileType.photo" relation */
  photo?: InputMaybe<MetadataDeliveryFileTypeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFileTypeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryFileType.archive" relation */
  archive: MetadataDeliveryFileTypeNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryFileType.photo" relation */
  photo: MetadataDeliveryFileTypeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFileTypeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryFileType.archive" relation */
  archive?: InputMaybe<MetadataDeliveryFileTypeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryFileType.photo" relation */
  photo?: InputMaybe<MetadataDeliveryFileTypeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFileTypeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryFileType.archive" relation */
  archive: MetadataDeliveryFileTypeNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryFileType.metadata" relation */
  metadata: MetadataDeliveryFileTypeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFileTypeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryFileType.archive" relation */
  archive?: InputMaybe<MetadataDeliveryFileTypeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryFileType.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryFileTypeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryFolder" resource's node */
export type MetadataDeliveryFolder = {
  __typename?: 'MetadataDeliveryFolder';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryFolderCreateInput = {
  /** Actions for the "MetadataDeliveryFolder.archive" relation */
  archive: MetadataDeliveryFolderNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryFolder.metadata" relation */
  metadata: MetadataDeliveryFolderNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryFolder.photo" relation */
  photo: MetadataDeliveryFolderNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFolderNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.archive" relation. */
  update?: InputMaybe<MetadataDeliveryFolderNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryFolderNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryFolderNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.archive" relation. */
  update?: InputMaybe<MetadataDeliveryFolderNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryFolderNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryFolderNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryFolderNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryFolderNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryFolderNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryFolderNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryFolderNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryFolderNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.photo" relation. */
  update?: InputMaybe<MetadataDeliveryFolderNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryFolderNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryFolderNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.photo" relation. */
  update?: InputMaybe<MetadataDeliveryFolderNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryFolder" node, through the "MetadataDeliveryFolder.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryFolderNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryFolderNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryFolderNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryFolderNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryFolderNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryFolderNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryFolderNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryFolderNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryFolderNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryFolderNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryFolderNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryFolderNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryFolderNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryFolderOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryFolderUpdateInput = {
  /** Actions for the "MetadataDeliveryFolder.archive" relation */
  archive?: InputMaybe<MetadataDeliveryFolderNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryFolder.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryFolderNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryFolder.photo" relation */
  photo?: InputMaybe<MetadataDeliveryFolderNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryFolder" nodes by specifying some conditions */
export type MetadataDeliveryFolderWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryFolderWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryFolderWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryFolderWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryFolder" node. */
export type MetadataDeliveryFolderWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryFolderWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryFolder.metadata" relation */
  metadata: MetadataDeliveryFolderNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryFolder.photo" relation */
  photo: MetadataDeliveryFolderNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFolderWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryFolder.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryFolderNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryFolder.photo" relation */
  photo?: InputMaybe<MetadataDeliveryFolderNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFolderWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryFolder.archive" relation */
  archive: MetadataDeliveryFolderNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryFolder.photo" relation */
  photo: MetadataDeliveryFolderNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFolderWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryFolder.archive" relation */
  archive?: InputMaybe<MetadataDeliveryFolderNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryFolder.photo" relation */
  photo?: InputMaybe<MetadataDeliveryFolderNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFolderWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryFolder.archive" relation */
  archive: MetadataDeliveryFolderNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryFolder.metadata" relation */
  metadata: MetadataDeliveryFolderNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryFolderWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryFolder.archive" relation */
  archive?: InputMaybe<MetadataDeliveryFolderNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryFolder.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryFolderNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryKind" resource's node */
export type MetadataDeliveryKind = {
  __typename?: 'MetadataDeliveryKind';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryKindCreateInput = {
  /** Actions for the "MetadataDeliveryKind.archive" relation */
  archive: MetadataDeliveryKindNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryKind.metadata" relation */
  metadata: MetadataDeliveryKindNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryKind.photo" relation */
  photo: MetadataDeliveryKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryKindNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.archive" relation. */
  update?: InputMaybe<MetadataDeliveryKindNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryKindNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryKindNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.archive" relation. */
  update?: InputMaybe<MetadataDeliveryKindNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryKindNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryKindNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryKindNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryKindNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryKindNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryKindNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryKindNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryKindNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.photo" relation. */
  update?: InputMaybe<MetadataDeliveryKindNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryKindNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryKindNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.photo" relation. */
  update?: InputMaybe<MetadataDeliveryKindNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryKind" node, through the "MetadataDeliveryKind.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryKindNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryKindNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryKindNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryKindNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryKindNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryKindNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryKindNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryKindNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryKindNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryKindNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryKindNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryKindNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryKindNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryKindOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryKindUpdateInput = {
  /** Actions for the "MetadataDeliveryKind.archive" relation */
  archive?: InputMaybe<MetadataDeliveryKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryKind.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryKindNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryKind.photo" relation */
  photo?: InputMaybe<MetadataDeliveryKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryKind" nodes by specifying some conditions */
export type MetadataDeliveryKindWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryKindWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryKindWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryKindWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryKind" node. */
export type MetadataDeliveryKindWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryKindWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryKind.metadata" relation */
  metadata: MetadataDeliveryKindNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryKind.photo" relation */
  photo: MetadataDeliveryKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryKindWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryKind.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryKindNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryKind.photo" relation */
  photo?: InputMaybe<MetadataDeliveryKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryKindWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryKind.archive" relation */
  archive: MetadataDeliveryKindNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryKind.photo" relation */
  photo: MetadataDeliveryKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryKindWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryKind.archive" relation */
  archive?: InputMaybe<MetadataDeliveryKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryKind.photo" relation */
  photo?: InputMaybe<MetadataDeliveryKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryKindWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryKind.archive" relation */
  archive: MetadataDeliveryKindNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryKind.metadata" relation */
  metadata: MetadataDeliveryKindNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryKindWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryKind.archive" relation */
  archive?: InputMaybe<MetadataDeliveryKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryKind.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryKindNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryPath" resource's node */
export type MetadataDeliveryPath = {
  __typename?: 'MetadataDeliveryPath';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryPathCreateInput = {
  /** Actions for the "MetadataDeliveryPath.archive" relation */
  archive: MetadataDeliveryPathNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPath.metadata" relation */
  metadata: MetadataDeliveryPathNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryPath.photo" relation */
  photo: MetadataDeliveryPathNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPathNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.archive" relation. */
  update?: InputMaybe<MetadataDeliveryPathNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryPathNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryPathNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.archive" relation. */
  update?: InputMaybe<MetadataDeliveryPathNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryPathNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryPathNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryPathNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryPathNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryPathNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryPathNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryPathNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryPathNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.photo" relation. */
  update?: InputMaybe<MetadataDeliveryPathNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryPathNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryPathNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.photo" relation. */
  update?: InputMaybe<MetadataDeliveryPathNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryPath" node, through the "MetadataDeliveryPath.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryPathNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryPathNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPathNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPathNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPathNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPathNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPathNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPathNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPathNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPathNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPathNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPathNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPathNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryPathOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryPathUpdateInput = {
  /** Actions for the "MetadataDeliveryPath.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPathNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPath.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPathNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryPath.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPathNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryPath" nodes by specifying some conditions */
export type MetadataDeliveryPathWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryPathWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryPathWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryPathWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryPath" node. */
export type MetadataDeliveryPathWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryPathWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryPath.metadata" relation */
  metadata: MetadataDeliveryPathNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryPath.photo" relation */
  photo: MetadataDeliveryPathNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPathWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryPath.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPathNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryPath.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPathNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPathWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryPath.archive" relation */
  archive: MetadataDeliveryPathNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPath.photo" relation */
  photo: MetadataDeliveryPathNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPathWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryPath.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPathNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPath.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPathNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPathWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryPath.archive" relation */
  archive: MetadataDeliveryPathNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPath.metadata" relation */
  metadata: MetadataDeliveryPathNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPathWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryPath.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPathNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPath.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPathNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryPersonShownintheImage" resource's node */
export type MetadataDeliveryPersonShownintheImage = {
  __typename?: 'MetadataDeliveryPersonShownintheImage';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryPersonShownintheImageCreateInput = {
  /** Actions for the "MetadataDeliveryPersonShownintheImage.archive" relation */
  archive: MetadataDeliveryPersonShownintheImageNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPersonShownintheImage.metadata" relation */
  metadata: MetadataDeliveryPersonShownintheImageNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryPersonShownintheImage.photo" relation */
  photo: MetadataDeliveryPersonShownintheImageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPersonShownintheImageNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.archive" relation. */
  update?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryPersonShownintheImageNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.archive" relation. */
  update?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryPersonShownintheImageNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryPersonShownintheImageNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryPersonShownintheImageNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.photo" relation. */
  update?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryPersonShownintheImageNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.photo" relation. */
  update?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryPersonShownintheImage" node, through the "MetadataDeliveryPersonShownintheImage.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryPersonShownintheImageNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPersonShownintheImageNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPersonShownintheImageNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPersonShownintheImageNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPersonShownintheImageNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPersonShownintheImageNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPersonShownintheImageNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPersonShownintheImageNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPersonShownintheImageNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPersonShownintheImageNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPersonShownintheImageNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPersonShownintheImageNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryPersonShownintheImageOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryPersonShownintheImageUpdateInput = {
  /** Actions for the "MetadataDeliveryPersonShownintheImage.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPersonShownintheImage.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryPersonShownintheImage.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryPersonShownintheImage" nodes by specifying some conditions */
export type MetadataDeliveryPersonShownintheImageWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryPersonShownintheImage" node. */
export type MetadataDeliveryPersonShownintheImageWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryPersonShownintheImageWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryPersonShownintheImage.metadata" relation */
  metadata: MetadataDeliveryPersonShownintheImageNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryPersonShownintheImage.photo" relation */
  photo: MetadataDeliveryPersonShownintheImageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPersonShownintheImageWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryPersonShownintheImage.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryPersonShownintheImage.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPersonShownintheImageWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryPersonShownintheImage.archive" relation */
  archive: MetadataDeliveryPersonShownintheImageNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPersonShownintheImage.photo" relation */
  photo: MetadataDeliveryPersonShownintheImageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPersonShownintheImageWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryPersonShownintheImage.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPersonShownintheImage.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPersonShownintheImageWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryPersonShownintheImage.archive" relation */
  archive: MetadataDeliveryPersonShownintheImageNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPersonShownintheImage.metadata" relation */
  metadata: MetadataDeliveryPersonShownintheImageNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPersonShownintheImageWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryPersonShownintheImage.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPersonShownintheImage.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPersonShownintheImageNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryPhotoshopCredit" resource's node */
export type MetadataDeliveryPhotoshopCredit = {
  __typename?: 'MetadataDeliveryPhotoshopCredit';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryPhotoshopCreditCreateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopCredit.archive" relation */
  archive: MetadataDeliveryPhotoshopCreditNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPhotoshopCredit.metadata" relation */
  metadata: MetadataDeliveryPhotoshopCreditNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryPhotoshopCredit.photo" relation */
  photo: MetadataDeliveryPhotoshopCreditNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopCreditNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.archive" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryPhotoshopCreditNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.archive" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryPhotoshopCreditNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryPhotoshopCreditNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryPhotoshopCreditNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.photo" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryPhotoshopCreditNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.photo" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryPhotoshopCredit" node, through the "MetadataDeliveryPhotoshopCredit.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryPhotoshopCreditNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopCreditNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopCreditNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopCreditNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopCreditNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopCreditNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopCreditNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopCreditNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopCreditNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopCreditNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopCreditNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopCreditNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryPhotoshopCreditOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryPhotoshopCreditUpdateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopCredit.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPhotoshopCredit.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryPhotoshopCredit.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryPhotoshopCredit" nodes by specifying some conditions */
export type MetadataDeliveryPhotoshopCreditWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryPhotoshopCredit" node. */
export type MetadataDeliveryPhotoshopCreditWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryPhotoshopCreditWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopCredit.metadata" relation */
  metadata: MetadataDeliveryPhotoshopCreditNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryPhotoshopCredit.photo" relation */
  photo: MetadataDeliveryPhotoshopCreditNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopCreditWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopCredit.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryPhotoshopCredit.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopCreditWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopCredit.archive" relation */
  archive: MetadataDeliveryPhotoshopCreditNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPhotoshopCredit.photo" relation */
  photo: MetadataDeliveryPhotoshopCreditNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopCreditWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopCredit.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPhotoshopCredit.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopCreditWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopCredit.archive" relation */
  archive: MetadataDeliveryPhotoshopCreditNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPhotoshopCredit.metadata" relation */
  metadata: MetadataDeliveryPhotoshopCreditNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopCreditWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopCredit.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPhotoshopCredit.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPhotoshopCreditNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryPhotoshopSource" resource's node */
export type MetadataDeliveryPhotoshopSource = {
  __typename?: 'MetadataDeliveryPhotoshopSource';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryPhotoshopSourceCreateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopSource.archive" relation */
  archive: MetadataDeliveryPhotoshopSourceNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPhotoshopSource.metadata" relation */
  metadata: MetadataDeliveryPhotoshopSourceNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryPhotoshopSource.photo" relation */
  photo: MetadataDeliveryPhotoshopSourceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopSourceNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.archive" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryPhotoshopSourceNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.archive" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryPhotoshopSourceNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryPhotoshopSourceNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryPhotoshopSourceNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.photo" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryPhotoshopSourceNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.photo" relation. */
  update?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryPhotoshopSource" node, through the "MetadataDeliveryPhotoshopSource.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryPhotoshopSourceNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopSourceNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopSourceNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopSourceNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopSourceNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopSourceNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopSourceNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopSourceNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopSourceNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopSourceNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopSourceNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryPhotoshopSourceNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryPhotoshopSourceOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryPhotoshopSourceUpdateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopSource.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPhotoshopSource.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryPhotoshopSource.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryPhotoshopSource" nodes by specifying some conditions */
export type MetadataDeliveryPhotoshopSourceWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryPhotoshopSource" node. */
export type MetadataDeliveryPhotoshopSourceWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryPhotoshopSourceWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopSource.metadata" relation */
  metadata: MetadataDeliveryPhotoshopSourceNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryPhotoshopSource.photo" relation */
  photo: MetadataDeliveryPhotoshopSourceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopSourceWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopSource.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryPhotoshopSource.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopSourceWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopSource.archive" relation */
  archive: MetadataDeliveryPhotoshopSourceNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPhotoshopSource.photo" relation */
  photo: MetadataDeliveryPhotoshopSourceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopSourceWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopSource.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPhotoshopSource.photo" relation */
  photo?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopSourceWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopSource.archive" relation */
  archive: MetadataDeliveryPhotoshopSourceNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryPhotoshopSource.metadata" relation */
  metadata: MetadataDeliveryPhotoshopSourceNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryPhotoshopSourceWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryPhotoshopSource.archive" relation */
  archive?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryPhotoshopSource.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryPhotoshopSourceNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryService" resource's node */
export type MetadataDeliveryService = {
  __typename?: 'MetadataDeliveryService';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryServiceCreateInput = {
  /** Actions for the "MetadataDeliveryService.archive" relation */
  archive: MetadataDeliveryServiceNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryService.metadata" relation */
  metadata: MetadataDeliveryServiceNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryService.photo" relation */
  photo: MetadataDeliveryServiceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryServiceNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.archive" relation. */
  update?: InputMaybe<MetadataDeliveryServiceNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryServiceNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryServiceNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.archive" relation. */
  update?: InputMaybe<MetadataDeliveryServiceNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryServiceNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryServiceNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryServiceNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryServiceNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryServiceNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryServiceNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryServiceNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryServiceNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.photo" relation. */
  update?: InputMaybe<MetadataDeliveryServiceNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryService" node, through the "MetadataDeliveryService.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryServiceNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryServiceNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.photo" relation. */
  update?: InputMaybe<MetadataDeliveryServiceNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryService" node, through the "MetadataDeliveryService.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryServiceNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryServiceNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryServiceNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryServiceNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryServiceNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryServiceNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryServiceNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryServiceNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryServiceNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryServiceNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryServiceNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryServiceNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryServiceNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryServiceOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryServiceUpdateInput = {
  /** Actions for the "MetadataDeliveryService.archive" relation */
  archive?: InputMaybe<MetadataDeliveryServiceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryService.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryServiceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryService.photo" relation */
  photo?: InputMaybe<MetadataDeliveryServiceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryService" nodes by specifying some conditions */
export type MetadataDeliveryServiceWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryServiceWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryServiceWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryServiceWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryService" node. */
export type MetadataDeliveryServiceWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryServiceWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryService.metadata" relation */
  metadata: MetadataDeliveryServiceNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryService.photo" relation */
  photo: MetadataDeliveryServiceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryServiceWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryService.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryServiceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryService.photo" relation */
  photo?: InputMaybe<MetadataDeliveryServiceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryServiceWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryService.archive" relation */
  archive: MetadataDeliveryServiceNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryService.photo" relation */
  photo: MetadataDeliveryServiceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryServiceWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryService.archive" relation */
  archive?: InputMaybe<MetadataDeliveryServiceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryService.photo" relation */
  photo?: InputMaybe<MetadataDeliveryServiceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryServiceWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryService.archive" relation */
  archive: MetadataDeliveryServiceNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryService.metadata" relation */
  metadata: MetadataDeliveryServiceNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryServiceWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryService.archive" relation */
  archive?: InputMaybe<MetadataDeliveryServiceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryService.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryServiceNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliverySubject" resource's node */
export type MetadataDeliverySubject = {
  __typename?: 'MetadataDeliverySubject';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliverySubjectCreateInput = {
  /** Actions for the "MetadataDeliverySubject.archive" relation */
  archive: MetadataDeliverySubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliverySubject.metadata" relation */
  metadata: MetadataDeliverySubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliverySubject.photo" relation */
  photo: MetadataDeliverySubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliverySubjectNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.archive" relation. */
  update?: InputMaybe<MetadataDeliverySubjectNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.archive" relation. */
  upsert?: InputMaybe<MetadataDeliverySubjectNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliverySubjectNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.archive" relation. */
  update?: InputMaybe<MetadataDeliverySubjectNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.archive" relation. */
  upsert?: InputMaybe<MetadataDeliverySubjectNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliverySubjectNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.metadata" relation. */
  update?: InputMaybe<MetadataDeliverySubjectNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliverySubjectNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliverySubjectNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.metadata" relation. */
  update?: InputMaybe<MetadataDeliverySubjectNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliverySubjectNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliverySubjectNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.photo" relation. */
  update?: InputMaybe<MetadataDeliverySubjectNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.photo" relation. */
  upsert?: InputMaybe<MetadataDeliverySubjectNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliverySubjectNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.photo" relation. */
  update?: InputMaybe<MetadataDeliverySubjectNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliverySubject" node, through the "MetadataDeliverySubject.photo" relation. */
  upsert?: InputMaybe<MetadataDeliverySubjectNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliverySubjectNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliverySubjectNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliverySubjectNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliverySubjectNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliverySubjectNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliverySubjectNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliverySubjectNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliverySubjectNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliverySubjectNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliverySubjectNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliverySubjectNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliverySubjectNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliverySubjectOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliverySubjectUpdateInput = {
  /** Actions for the "MetadataDeliverySubject.archive" relation */
  archive?: InputMaybe<MetadataDeliverySubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliverySubject.metadata" relation */
  metadata?: InputMaybe<MetadataDeliverySubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliverySubject.photo" relation */
  photo?: InputMaybe<MetadataDeliverySubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliverySubject" nodes by specifying some conditions */
export type MetadataDeliverySubjectWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliverySubjectWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliverySubjectWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliverySubjectWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliverySubject" node. */
export type MetadataDeliverySubjectWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliverySubjectWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliverySubject.metadata" relation */
  metadata: MetadataDeliverySubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliverySubject.photo" relation */
  photo: MetadataDeliverySubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliverySubjectWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliverySubject.metadata" relation */
  metadata?: InputMaybe<MetadataDeliverySubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliverySubject.photo" relation */
  photo?: InputMaybe<MetadataDeliverySubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliverySubjectWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliverySubject.archive" relation */
  archive: MetadataDeliverySubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliverySubject.photo" relation */
  photo: MetadataDeliverySubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliverySubjectWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliverySubject.archive" relation */
  archive?: InputMaybe<MetadataDeliverySubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliverySubject.photo" relation */
  photo?: InputMaybe<MetadataDeliverySubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliverySubjectWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliverySubject.archive" relation */
  archive: MetadataDeliverySubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliverySubject.metadata" relation */
  metadata: MetadataDeliverySubjectNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliverySubjectWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliverySubject.archive" relation */
  archive?: InputMaybe<MetadataDeliverySubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliverySubject.metadata" relation */
  metadata?: InputMaybe<MetadataDeliverySubjectNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDeliveryUnderSubject" resource's node */
export type MetadataDeliveryUnderSubject = {
  __typename?: 'MetadataDeliveryUnderSubject';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDeliveryUnderSubjectCreateInput = {
  /** Actions for the "MetadataDeliveryUnderSubject.archive" relation */
  archive: MetadataDeliveryUnderSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryUnderSubject.metadata" relation */
  metadata: MetadataDeliveryUnderSubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryUnderSubject.photo" relation */
  photo: MetadataDeliveryUnderSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryUnderSubjectNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.archive" relation. */
  update?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpsertArchiveCreateInput>;
};

export type MetadataDeliveryUnderSubjectNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.archive" relation. */
  update?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.archive" relation. */
  upsert?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpsertArchiveUpdateInput>;
};

export type MetadataDeliveryUnderSubjectNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpsertMetadataCreateInput>;
};

export type MetadataDeliveryUnderSubjectNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.metadata" relation. */
  update?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.metadata" relation. */
  upsert?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpsertMetadataUpdateInput>;
};

export type MetadataDeliveryUnderSubjectNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.photo" relation. */
  update?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpsertPhotoCreateInput>;
};

export type MetadataDeliveryUnderSubjectNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.photo" relation. */
  update?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDeliveryUnderSubject" node, through the "MetadataDeliveryUnderSubject.photo" relation. */
  upsert?: InputMaybe<MetadataDeliveryUnderSubjectNestedUpsertPhotoUpdateInput>;
};

export type MetadataDeliveryUnderSubjectNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryUnderSubjectNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryUnderSubjectNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryUnderSubjectNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryUnderSubjectNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryUnderSubjectNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryUnderSubjectNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryUnderSubjectNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDeliveryUnderSubjectNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryUnderSubjectNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDeliveryUnderSubjectNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDeliveryUnderSubjectNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDeliveryUnderSubjectOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDeliveryUnderSubjectUpdateInput = {
  /** Actions for the "MetadataDeliveryUnderSubject.archive" relation */
  archive?: InputMaybe<MetadataDeliveryUnderSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryUnderSubject.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryUnderSubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryUnderSubject.photo" relation */
  photo?: InputMaybe<MetadataDeliveryUnderSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDeliveryUnderSubject" nodes by specifying some conditions */
export type MetadataDeliveryUnderSubjectWhereInput = {
  AND?: InputMaybe<Array<MetadataDeliveryUnderSubjectWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
  OR?: InputMaybe<Array<MetadataDeliveryUnderSubjectWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDeliveryUnderSubject" node. */
export type MetadataDeliveryUnderSubjectWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDeliveryUnderSubjectWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDeliveryUnderSubject.metadata" relation */
  metadata: MetadataDeliveryUnderSubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataDeliveryUnderSubject.photo" relation */
  photo: MetadataDeliveryUnderSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryUnderSubjectWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDeliveryUnderSubject.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryUnderSubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDeliveryUnderSubject.photo" relation */
  photo?: InputMaybe<MetadataDeliveryUnderSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryUnderSubjectWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDeliveryUnderSubject.archive" relation */
  archive: MetadataDeliveryUnderSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryUnderSubject.photo" relation */
  photo: MetadataDeliveryUnderSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryUnderSubjectWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDeliveryUnderSubject.archive" relation */
  archive?: InputMaybe<MetadataDeliveryUnderSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryUnderSubject.photo" relation */
  photo?: InputMaybe<MetadataDeliveryUnderSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryUnderSubjectWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDeliveryUnderSubject.archive" relation */
  archive: MetadataDeliveryUnderSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataDeliveryUnderSubject.metadata" relation */
  metadata: MetadataDeliveryUnderSubjectNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDeliveryUnderSubjectWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDeliveryUnderSubject.archive" relation */
  archive?: InputMaybe<MetadataDeliveryUnderSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDeliveryUnderSubject.metadata" relation */
  metadata?: InputMaybe<MetadataDeliveryUnderSubjectNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDepartment" resource's node */
export type MetadataDepartment = {
  __typename?: 'MetadataDepartment';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDepartmentCreateInput = {
  /** Actions for the "MetadataDepartment.archive" relation */
  archive: MetadataDepartmentNestedArchiveCreateInput;
  /** Actions for the "MetadataDepartment.metadata" relation */
  metadata: MetadataDepartmentNestedMetadataCreateInput;
  /** Actions for the "MetadataDepartment.photo" relation */
  photo: MetadataDepartmentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDepartmentNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDepartment" node, through the "MetadataDepartment.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDepartment" node, through the "MetadataDepartment.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDepartment" node, through the "MetadataDepartment.archive" relation. */
  update?: InputMaybe<MetadataDepartmentNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDepartment" node, through the "MetadataDepartment.archive" relation. */
  upsert?: InputMaybe<MetadataDepartmentNestedUpsertArchiveCreateInput>;
};

export type MetadataDepartmentNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDepartment" node, through the "MetadataDepartment.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDepartment" node, through the "MetadataDepartment.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDepartment" node, through the "MetadataDepartment.archive" relation. */
  update?: InputMaybe<MetadataDepartmentNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDepartment" node, through the "MetadataDepartment.archive" relation. */
  upsert?: InputMaybe<MetadataDepartmentNestedUpsertArchiveUpdateInput>;
};

export type MetadataDepartmentNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDepartment" node, through the "MetadataDepartment.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDepartment" node, through the "MetadataDepartment.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDepartment" node, through the "MetadataDepartment.metadata" relation. */
  update?: InputMaybe<MetadataDepartmentNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDepartment" node, through the "MetadataDepartment.metadata" relation. */
  upsert?: InputMaybe<MetadataDepartmentNestedUpsertMetadataCreateInput>;
};

export type MetadataDepartmentNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDepartment" node, through the "MetadataDepartment.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDepartment" node, through the "MetadataDepartment.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDepartment" node, through the "MetadataDepartment.metadata" relation. */
  update?: InputMaybe<MetadataDepartmentNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDepartment" node, through the "MetadataDepartment.metadata" relation. */
  upsert?: InputMaybe<MetadataDepartmentNestedUpsertMetadataUpdateInput>;
};

export type MetadataDepartmentNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDepartment" node, through the "MetadataDepartment.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDepartment" node, through the "MetadataDepartment.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDepartment" node, through the "MetadataDepartment.photo" relation. */
  update?: InputMaybe<MetadataDepartmentNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDepartment" node, through the "MetadataDepartment.photo" relation. */
  upsert?: InputMaybe<MetadataDepartmentNestedUpsertPhotoCreateInput>;
};

export type MetadataDepartmentNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDepartment" node, through the "MetadataDepartment.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDepartment" node, through the "MetadataDepartment.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDepartment" node, through the "MetadataDepartment.photo" relation. */
  update?: InputMaybe<MetadataDepartmentNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDepartment" node, through the "MetadataDepartment.photo" relation. */
  upsert?: InputMaybe<MetadataDepartmentNestedUpsertPhotoUpdateInput>;
};

export type MetadataDepartmentNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDepartmentNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDepartmentNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDepartmentNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDepartmentNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDepartmentNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDepartmentNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDepartmentNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDepartmentNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDepartmentNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDepartmentNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDepartmentNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDepartmentOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDepartmentUpdateInput = {
  /** Actions for the "MetadataDepartment.archive" relation */
  archive?: InputMaybe<MetadataDepartmentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDepartment.metadata" relation */
  metadata?: InputMaybe<MetadataDepartmentNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDepartment.photo" relation */
  photo?: InputMaybe<MetadataDepartmentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDepartment" nodes by specifying some conditions */
export type MetadataDepartmentWhereInput = {
  AND?: InputMaybe<Array<MetadataDepartmentWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDepartmentWhereInput>;
  OR?: InputMaybe<Array<MetadataDepartmentWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDepartment" node. */
export type MetadataDepartmentWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDepartmentWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDepartment.metadata" relation */
  metadata: MetadataDepartmentNestedMetadataCreateInput;
  /** Actions for the "MetadataDepartment.photo" relation */
  photo: MetadataDepartmentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDepartmentWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDepartment.metadata" relation */
  metadata?: InputMaybe<MetadataDepartmentNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDepartment.photo" relation */
  photo?: InputMaybe<MetadataDepartmentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDepartmentWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDepartment.archive" relation */
  archive: MetadataDepartmentNestedArchiveCreateInput;
  /** Actions for the "MetadataDepartment.photo" relation */
  photo: MetadataDepartmentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDepartmentWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDepartment.archive" relation */
  archive?: InputMaybe<MetadataDepartmentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDepartment.photo" relation */
  photo?: InputMaybe<MetadataDepartmentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDepartmentWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDepartment.archive" relation */
  archive: MetadataDepartmentNestedArchiveCreateInput;
  /** Actions for the "MetadataDepartment.metadata" relation */
  metadata: MetadataDepartmentNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDepartmentWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDepartment.archive" relation */
  archive?: InputMaybe<MetadataDepartmentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDepartment.metadata" relation */
  metadata?: InputMaybe<MetadataDepartmentNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDescription" resource's node */
export type MetadataDescription = {
  __typename?: 'MetadataDescription';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDescriptionCreateInput = {
  /** Actions for the "MetadataDescription.archive" relation */
  archive: MetadataDescriptionNestedArchiveCreateInput;
  /** Actions for the "MetadataDescription.metadata" relation */
  metadata: MetadataDescriptionNestedMetadataCreateInput;
  /** Actions for the "MetadataDescription.photo" relation */
  photo: MetadataDescriptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDescriptionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDescription" node, through the "MetadataDescription.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDescription" node, through the "MetadataDescription.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDescription" node, through the "MetadataDescription.archive" relation. */
  update?: InputMaybe<MetadataDescriptionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDescription" node, through the "MetadataDescription.archive" relation. */
  upsert?: InputMaybe<MetadataDescriptionNestedUpsertArchiveCreateInput>;
};

export type MetadataDescriptionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDescription" node, through the "MetadataDescription.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDescription" node, through the "MetadataDescription.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDescription" node, through the "MetadataDescription.archive" relation. */
  update?: InputMaybe<MetadataDescriptionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDescription" node, through the "MetadataDescription.archive" relation. */
  upsert?: InputMaybe<MetadataDescriptionNestedUpsertArchiveUpdateInput>;
};

export type MetadataDescriptionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDescription" node, through the "MetadataDescription.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDescription" node, through the "MetadataDescription.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDescription" node, through the "MetadataDescription.metadata" relation. */
  update?: InputMaybe<MetadataDescriptionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDescription" node, through the "MetadataDescription.metadata" relation. */
  upsert?: InputMaybe<MetadataDescriptionNestedUpsertMetadataCreateInput>;
};

export type MetadataDescriptionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDescription" node, through the "MetadataDescription.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDescription" node, through the "MetadataDescription.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDescription" node, through the "MetadataDescription.metadata" relation. */
  update?: InputMaybe<MetadataDescriptionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDescription" node, through the "MetadataDescription.metadata" relation. */
  upsert?: InputMaybe<MetadataDescriptionNestedUpsertMetadataUpdateInput>;
};

export type MetadataDescriptionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDescription" node, through the "MetadataDescription.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDescription" node, through the "MetadataDescription.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDescription" node, through the "MetadataDescription.photo" relation. */
  update?: InputMaybe<MetadataDescriptionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDescription" node, through the "MetadataDescription.photo" relation. */
  upsert?: InputMaybe<MetadataDescriptionNestedUpsertPhotoCreateInput>;
};

export type MetadataDescriptionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDescription" node, through the "MetadataDescription.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDescription" node, through the "MetadataDescription.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDescription" node, through the "MetadataDescription.photo" relation. */
  update?: InputMaybe<MetadataDescriptionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDescription" node, through the "MetadataDescription.photo" relation. */
  upsert?: InputMaybe<MetadataDescriptionNestedUpsertPhotoUpdateInput>;
};

export type MetadataDescriptionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDescriptionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDescriptionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDescriptionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDescriptionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDescriptionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDescriptionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDescriptionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDescriptionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDescriptionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDescriptionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDescriptionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDescriptionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDescriptionUpdateInput = {
  /** Actions for the "MetadataDescription.archive" relation */
  archive?: InputMaybe<MetadataDescriptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDescription.metadata" relation */
  metadata?: InputMaybe<MetadataDescriptionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDescription.photo" relation */
  photo?: InputMaybe<MetadataDescriptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDescription" nodes by specifying some conditions */
export type MetadataDescriptionWhereInput = {
  AND?: InputMaybe<Array<MetadataDescriptionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDescriptionWhereInput>;
  OR?: InputMaybe<Array<MetadataDescriptionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDescription" node. */
export type MetadataDescriptionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDescriptionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDescription.metadata" relation */
  metadata: MetadataDescriptionNestedMetadataCreateInput;
  /** Actions for the "MetadataDescription.photo" relation */
  photo: MetadataDescriptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDescriptionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDescription.metadata" relation */
  metadata?: InputMaybe<MetadataDescriptionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDescription.photo" relation */
  photo?: InputMaybe<MetadataDescriptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDescriptionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDescription.archive" relation */
  archive: MetadataDescriptionNestedArchiveCreateInput;
  /** Actions for the "MetadataDescription.photo" relation */
  photo: MetadataDescriptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDescriptionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDescription.archive" relation */
  archive?: InputMaybe<MetadataDescriptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDescription.photo" relation */
  photo?: InputMaybe<MetadataDescriptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDescriptionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDescription.archive" relation */
  archive: MetadataDescriptionNestedArchiveCreateInput;
  /** Actions for the "MetadataDescription.metadata" relation */
  metadata: MetadataDescriptionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDescriptionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDescription.archive" relation */
  archive?: InputMaybe<MetadataDescriptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDescription.metadata" relation */
  metadata?: InputMaybe<MetadataDescriptionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDigitalAssetUrl" resource's node */
export type MetadataDigitalAssetUrl = {
  __typename?: 'MetadataDigitalAssetUrl';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDigitalAssetUrlCreateInput = {
  /** Actions for the "MetadataDigitalAssetUrl.archive" relation */
  archive: MetadataDigitalAssetUrlNestedArchiveCreateInput;
  /** Actions for the "MetadataDigitalAssetUrl.metadata" relation */
  metadata: MetadataDigitalAssetUrlNestedMetadataCreateInput;
  /** Actions for the "MetadataDigitalAssetUrl.photo" relation */
  photo: MetadataDigitalAssetUrlNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDigitalAssetUrlNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.archive" relation. */
  update?: InputMaybe<MetadataDigitalAssetUrlNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.archive" relation. */
  upsert?: InputMaybe<MetadataDigitalAssetUrlNestedUpsertArchiveCreateInput>;
};

export type MetadataDigitalAssetUrlNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.archive" relation. */
  update?: InputMaybe<MetadataDigitalAssetUrlNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.archive" relation. */
  upsert?: InputMaybe<MetadataDigitalAssetUrlNestedUpsertArchiveUpdateInput>;
};

export type MetadataDigitalAssetUrlNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.metadata" relation. */
  update?: InputMaybe<MetadataDigitalAssetUrlNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.metadata" relation. */
  upsert?: InputMaybe<MetadataDigitalAssetUrlNestedUpsertMetadataCreateInput>;
};

export type MetadataDigitalAssetUrlNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.metadata" relation. */
  update?: InputMaybe<MetadataDigitalAssetUrlNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.metadata" relation. */
  upsert?: InputMaybe<MetadataDigitalAssetUrlNestedUpsertMetadataUpdateInput>;
};

export type MetadataDigitalAssetUrlNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.photo" relation. */
  update?: InputMaybe<MetadataDigitalAssetUrlNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.photo" relation. */
  upsert?: InputMaybe<MetadataDigitalAssetUrlNestedUpsertPhotoCreateInput>;
};

export type MetadataDigitalAssetUrlNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.photo" relation. */
  update?: InputMaybe<MetadataDigitalAssetUrlNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDigitalAssetUrl" node, through the "MetadataDigitalAssetUrl.photo" relation. */
  upsert?: InputMaybe<MetadataDigitalAssetUrlNestedUpsertPhotoUpdateInput>;
};

export type MetadataDigitalAssetUrlNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDigitalAssetUrlNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDigitalAssetUrlNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDigitalAssetUrlNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDigitalAssetUrlNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDigitalAssetUrlNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDigitalAssetUrlNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDigitalAssetUrlNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDigitalAssetUrlNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDigitalAssetUrlNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDigitalAssetUrlNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDigitalAssetUrlNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDigitalAssetUrlOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDigitalAssetUrlUpdateInput = {
  /** Actions for the "MetadataDigitalAssetUrl.archive" relation */
  archive?: InputMaybe<MetadataDigitalAssetUrlNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDigitalAssetUrl.metadata" relation */
  metadata?: InputMaybe<MetadataDigitalAssetUrlNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDigitalAssetUrl.photo" relation */
  photo?: InputMaybe<MetadataDigitalAssetUrlNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDigitalAssetUrl" nodes by specifying some conditions */
export type MetadataDigitalAssetUrlWhereInput = {
  AND?: InputMaybe<Array<MetadataDigitalAssetUrlWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
  OR?: InputMaybe<Array<MetadataDigitalAssetUrlWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDigitalAssetUrl" node. */
export type MetadataDigitalAssetUrlWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDigitalAssetUrlWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDigitalAssetUrl.metadata" relation */
  metadata: MetadataDigitalAssetUrlNestedMetadataCreateInput;
  /** Actions for the "MetadataDigitalAssetUrl.photo" relation */
  photo: MetadataDigitalAssetUrlNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDigitalAssetUrlWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDigitalAssetUrl.metadata" relation */
  metadata?: InputMaybe<MetadataDigitalAssetUrlNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDigitalAssetUrl.photo" relation */
  photo?: InputMaybe<MetadataDigitalAssetUrlNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDigitalAssetUrlWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDigitalAssetUrl.archive" relation */
  archive: MetadataDigitalAssetUrlNestedArchiveCreateInput;
  /** Actions for the "MetadataDigitalAssetUrl.photo" relation */
  photo: MetadataDigitalAssetUrlNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDigitalAssetUrlWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDigitalAssetUrl.archive" relation */
  archive?: InputMaybe<MetadataDigitalAssetUrlNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDigitalAssetUrl.photo" relation */
  photo?: InputMaybe<MetadataDigitalAssetUrlNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDigitalAssetUrlWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDigitalAssetUrl.archive" relation */
  archive: MetadataDigitalAssetUrlNestedArchiveCreateInput;
  /** Actions for the "MetadataDigitalAssetUrl.metadata" relation */
  metadata: MetadataDigitalAssetUrlNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDigitalAssetUrlWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDigitalAssetUrl.archive" relation */
  archive?: InputMaybe<MetadataDigitalAssetUrlNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDigitalAssetUrl.metadata" relation */
  metadata?: InputMaybe<MetadataDigitalAssetUrlNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDivision" resource's node */
export type MetadataDivision = {
  __typename?: 'MetadataDivision';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDivisionCreateInput = {
  /** Actions for the "MetadataDivision.archive" relation */
  archive: MetadataDivisionNestedArchiveCreateInput;
  /** Actions for the "MetadataDivision.metadata" relation */
  metadata: MetadataDivisionNestedMetadataCreateInput;
  /** Actions for the "MetadataDivision.photo" relation */
  photo: MetadataDivisionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDivisionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDivision" node, through the "MetadataDivision.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDivision" node, through the "MetadataDivision.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDivision" node, through the "MetadataDivision.archive" relation. */
  update?: InputMaybe<MetadataDivisionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDivision" node, through the "MetadataDivision.archive" relation. */
  upsert?: InputMaybe<MetadataDivisionNestedUpsertArchiveCreateInput>;
};

export type MetadataDivisionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDivision" node, through the "MetadataDivision.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDivision" node, through the "MetadataDivision.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDivision" node, through the "MetadataDivision.archive" relation. */
  update?: InputMaybe<MetadataDivisionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDivision" node, through the "MetadataDivision.archive" relation. */
  upsert?: InputMaybe<MetadataDivisionNestedUpsertArchiveUpdateInput>;
};

export type MetadataDivisionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDivision" node, through the "MetadataDivision.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDivision" node, through the "MetadataDivision.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDivision" node, through the "MetadataDivision.metadata" relation. */
  update?: InputMaybe<MetadataDivisionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDivision" node, through the "MetadataDivision.metadata" relation. */
  upsert?: InputMaybe<MetadataDivisionNestedUpsertMetadataCreateInput>;
};

export type MetadataDivisionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDivision" node, through the "MetadataDivision.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDivision" node, through the "MetadataDivision.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDivision" node, through the "MetadataDivision.metadata" relation. */
  update?: InputMaybe<MetadataDivisionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDivision" node, through the "MetadataDivision.metadata" relation. */
  upsert?: InputMaybe<MetadataDivisionNestedUpsertMetadataUpdateInput>;
};

export type MetadataDivisionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDivision" node, through the "MetadataDivision.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDivision" node, through the "MetadataDivision.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDivision" node, through the "MetadataDivision.photo" relation. */
  update?: InputMaybe<MetadataDivisionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDivision" node, through the "MetadataDivision.photo" relation. */
  upsert?: InputMaybe<MetadataDivisionNestedUpsertPhotoCreateInput>;
};

export type MetadataDivisionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDivision" node, through the "MetadataDivision.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDivision" node, through the "MetadataDivision.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDivision" node, through the "MetadataDivision.photo" relation. */
  update?: InputMaybe<MetadataDivisionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDivision" node, through the "MetadataDivision.photo" relation. */
  upsert?: InputMaybe<MetadataDivisionNestedUpsertPhotoUpdateInput>;
};

export type MetadataDivisionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDivisionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDivisionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDivisionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDivisionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDivisionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDivisionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDivisionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDivisionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDivisionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDivisionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDivisionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDivisionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDivisionUpdateInput = {
  /** Actions for the "MetadataDivision.archive" relation */
  archive?: InputMaybe<MetadataDivisionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDivision.metadata" relation */
  metadata?: InputMaybe<MetadataDivisionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDivision.photo" relation */
  photo?: InputMaybe<MetadataDivisionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDivision" nodes by specifying some conditions */
export type MetadataDivisionWhereInput = {
  AND?: InputMaybe<Array<MetadataDivisionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDivisionWhereInput>;
  OR?: InputMaybe<Array<MetadataDivisionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDivision" node. */
export type MetadataDivisionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDivisionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDivision.metadata" relation */
  metadata: MetadataDivisionNestedMetadataCreateInput;
  /** Actions for the "MetadataDivision.photo" relation */
  photo: MetadataDivisionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDivisionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDivision.metadata" relation */
  metadata?: InputMaybe<MetadataDivisionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDivision.photo" relation */
  photo?: InputMaybe<MetadataDivisionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDivisionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDivision.archive" relation */
  archive: MetadataDivisionNestedArchiveCreateInput;
  /** Actions for the "MetadataDivision.photo" relation */
  photo: MetadataDivisionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDivisionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDivision.archive" relation */
  archive?: InputMaybe<MetadataDivisionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDivision.photo" relation */
  photo?: InputMaybe<MetadataDivisionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDivisionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDivision.archive" relation */
  archive: MetadataDivisionNestedArchiveCreateInput;
  /** Actions for the "MetadataDivision.metadata" relation */
  metadata: MetadataDivisionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDivisionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDivision.archive" relation */
  archive?: InputMaybe<MetadataDivisionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDivision.metadata" relation */
  metadata?: InputMaybe<MetadataDivisionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataDocumentText" resource's node */
export type MetadataDocumentText = {
  __typename?: 'MetadataDocumentText';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataDocumentTextCreateInput = {
  /** Actions for the "MetadataDocumentText.archive" relation */
  archive: MetadataDocumentTextNestedArchiveCreateInput;
  /** Actions for the "MetadataDocumentText.metadata" relation */
  metadata: MetadataDocumentTextNestedMetadataCreateInput;
  /** Actions for the "MetadataDocumentText.photo" relation */
  photo: MetadataDocumentTextNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDocumentTextNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataDocumentText" node, through the "MetadataDocumentText.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataDocumentText" node, through the "MetadataDocumentText.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataDocumentText" node, through the "MetadataDocumentText.archive" relation. */
  update?: InputMaybe<MetadataDocumentTextNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataDocumentText" node, through the "MetadataDocumentText.archive" relation. */
  upsert?: InputMaybe<MetadataDocumentTextNestedUpsertArchiveCreateInput>;
};

export type MetadataDocumentTextNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.archive" relation. */
  update?: InputMaybe<MetadataDocumentTextNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.archive" relation. */
  upsert?: InputMaybe<MetadataDocumentTextNestedUpsertArchiveUpdateInput>;
};

export type MetadataDocumentTextNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataDocumentText" node, through the "MetadataDocumentText.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataDocumentText" node, through the "MetadataDocumentText.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataDocumentText" node, through the "MetadataDocumentText.metadata" relation. */
  update?: InputMaybe<MetadataDocumentTextNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataDocumentText" node, through the "MetadataDocumentText.metadata" relation. */
  upsert?: InputMaybe<MetadataDocumentTextNestedUpsertMetadataCreateInput>;
};

export type MetadataDocumentTextNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.metadata" relation. */
  update?: InputMaybe<MetadataDocumentTextNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.metadata" relation. */
  upsert?: InputMaybe<MetadataDocumentTextNestedUpsertMetadataUpdateInput>;
};

export type MetadataDocumentTextNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataDocumentText" node, through the "MetadataDocumentText.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataDocumentText" node, through the "MetadataDocumentText.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataDocumentText" node, through the "MetadataDocumentText.photo" relation. */
  update?: InputMaybe<MetadataDocumentTextNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataDocumentText" node, through the "MetadataDocumentText.photo" relation. */
  upsert?: InputMaybe<MetadataDocumentTextNestedUpsertPhotoCreateInput>;
};

export type MetadataDocumentTextNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.photo" relation. */
  update?: InputMaybe<MetadataDocumentTextNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataDocumentText" node, through the "MetadataDocumentText.photo" relation. */
  upsert?: InputMaybe<MetadataDocumentTextNestedUpsertPhotoUpdateInput>;
};

export type MetadataDocumentTextNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDocumentTextNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDocumentTextNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDocumentTextNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDocumentTextNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDocumentTextNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDocumentTextNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDocumentTextNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataDocumentTextNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDocumentTextNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataDocumentTextNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataDocumentTextNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataDocumentTextOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataDocumentTextUpdateInput = {
  /** Actions for the "MetadataDocumentText.archive" relation */
  archive?: InputMaybe<MetadataDocumentTextNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDocumentText.metadata" relation */
  metadata?: InputMaybe<MetadataDocumentTextNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDocumentText.photo" relation */
  photo?: InputMaybe<MetadataDocumentTextNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataDocumentText" nodes by specifying some conditions */
export type MetadataDocumentTextWhereInput = {
  AND?: InputMaybe<Array<MetadataDocumentTextWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataDocumentTextWhereInput>;
  OR?: InputMaybe<Array<MetadataDocumentTextWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataDocumentText" node. */
export type MetadataDocumentTextWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataDocumentTextWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataDocumentText.metadata" relation */
  metadata: MetadataDocumentTextNestedMetadataCreateInput;
  /** Actions for the "MetadataDocumentText.photo" relation */
  photo: MetadataDocumentTextNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDocumentTextWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataDocumentText.metadata" relation */
  metadata?: InputMaybe<MetadataDocumentTextNestedMetadataUpdateInput>;
  /** Actions for the "MetadataDocumentText.photo" relation */
  photo?: InputMaybe<MetadataDocumentTextNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDocumentTextWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataDocumentText.archive" relation */
  archive: MetadataDocumentTextNestedArchiveCreateInput;
  /** Actions for the "MetadataDocumentText.photo" relation */
  photo: MetadataDocumentTextNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDocumentTextWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataDocumentText.archive" relation */
  archive?: InputMaybe<MetadataDocumentTextNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDocumentText.photo" relation */
  photo?: InputMaybe<MetadataDocumentTextNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDocumentTextWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataDocumentText.archive" relation */
  archive: MetadataDocumentTextNestedArchiveCreateInput;
  /** Actions for the "MetadataDocumentText.metadata" relation */
  metadata: MetadataDocumentTextNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataDocumentTextWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataDocumentText.archive" relation */
  archive?: InputMaybe<MetadataDocumentTextNestedArchiveUpdateInput>;
  /** Actions for the "MetadataDocumentText.metadata" relation */
  metadata?: InputMaybe<MetadataDocumentTextNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataEdition" resource's node */
export type MetadataEdition = {
  __typename?: 'MetadataEdition';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataEditionCreateInput = {
  /** Actions for the "MetadataEdition.archive" relation */
  archive: MetadataEditionNestedArchiveCreateInput;
  /** Actions for the "MetadataEdition.metadata" relation */
  metadata: MetadataEditionNestedMetadataCreateInput;
  /** Actions for the "MetadataEdition.photo" relation */
  photo: MetadataEditionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataEdition" node, through the "MetadataEdition.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataEdition" node, through the "MetadataEdition.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataEdition" node, through the "MetadataEdition.archive" relation. */
  update?: InputMaybe<MetadataEditionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataEdition" node, through the "MetadataEdition.archive" relation. */
  upsert?: InputMaybe<MetadataEditionNestedUpsertArchiveCreateInput>;
};

export type MetadataEditionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataEdition" node, through the "MetadataEdition.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataEdition" node, through the "MetadataEdition.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataEdition" node, through the "MetadataEdition.archive" relation. */
  update?: InputMaybe<MetadataEditionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataEdition" node, through the "MetadataEdition.archive" relation. */
  upsert?: InputMaybe<MetadataEditionNestedUpsertArchiveUpdateInput>;
};

export type MetadataEditionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataEdition" node, through the "MetadataEdition.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataEdition" node, through the "MetadataEdition.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataEdition" node, through the "MetadataEdition.metadata" relation. */
  update?: InputMaybe<MetadataEditionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataEdition" node, through the "MetadataEdition.metadata" relation. */
  upsert?: InputMaybe<MetadataEditionNestedUpsertMetadataCreateInput>;
};

export type MetadataEditionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataEdition" node, through the "MetadataEdition.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataEdition" node, through the "MetadataEdition.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataEdition" node, through the "MetadataEdition.metadata" relation. */
  update?: InputMaybe<MetadataEditionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataEdition" node, through the "MetadataEdition.metadata" relation. */
  upsert?: InputMaybe<MetadataEditionNestedUpsertMetadataUpdateInput>;
};

export type MetadataEditionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataEdition" node, through the "MetadataEdition.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataEdition" node, through the "MetadataEdition.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataEdition" node, through the "MetadataEdition.photo" relation. */
  update?: InputMaybe<MetadataEditionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataEdition" node, through the "MetadataEdition.photo" relation. */
  upsert?: InputMaybe<MetadataEditionNestedUpsertPhotoCreateInput>;
};

export type MetadataEditionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataEdition" node, through the "MetadataEdition.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataEdition" node, through the "MetadataEdition.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataEdition" node, through the "MetadataEdition.photo" relation. */
  update?: InputMaybe<MetadataEditionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataEdition" node, through the "MetadataEdition.photo" relation. */
  upsert?: InputMaybe<MetadataEditionNestedUpsertPhotoUpdateInput>;
};

export type MetadataEditionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEditionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEditionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEditionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataEditionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataEditionUpdateInput = {
  /** Actions for the "MetadataEdition.archive" relation */
  archive?: InputMaybe<MetadataEditionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEdition.metadata" relation */
  metadata?: InputMaybe<MetadataEditionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEdition.photo" relation */
  photo?: InputMaybe<MetadataEditionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataEdition" nodes by specifying some conditions */
export type MetadataEditionWhereInput = {
  AND?: InputMaybe<Array<MetadataEditionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataEditionWhereInput>;
  OR?: InputMaybe<Array<MetadataEditionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataEdition" node. */
export type MetadataEditionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataEditionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataEdition.metadata" relation */
  metadata: MetadataEditionNestedMetadataCreateInput;
  /** Actions for the "MetadataEdition.photo" relation */
  photo: MetadataEditionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataEdition.metadata" relation */
  metadata?: InputMaybe<MetadataEditionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEdition.photo" relation */
  photo?: InputMaybe<MetadataEditionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataEdition.archive" relation */
  archive: MetadataEditionNestedArchiveCreateInput;
  /** Actions for the "MetadataEdition.photo" relation */
  photo: MetadataEditionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataEdition.archive" relation */
  archive?: InputMaybe<MetadataEditionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEdition.photo" relation */
  photo?: InputMaybe<MetadataEditionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataEdition.archive" relation */
  archive: MetadataEditionNestedArchiveCreateInput;
  /** Actions for the "MetadataEdition.metadata" relation */
  metadata: MetadataEditionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataEdition.archive" relation */
  archive?: InputMaybe<MetadataEditionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEdition.metadata" relation */
  metadata?: InputMaybe<MetadataEditionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataEditorialVersion" resource's node */
export type MetadataEditorialVersion = {
  __typename?: 'MetadataEditorialVersion';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataEditorialVersionCreateInput = {
  /** Actions for the "MetadataEditorialVersion.archive" relation */
  archive: MetadataEditorialVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataEditorialVersion.metadata" relation */
  metadata: MetadataEditorialVersionNestedMetadataCreateInput;
  /** Actions for the "MetadataEditorialVersion.photo" relation */
  photo: MetadataEditorialVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditorialVersionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.archive" relation. */
  update?: InputMaybe<MetadataEditorialVersionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.archive" relation. */
  upsert?: InputMaybe<MetadataEditorialVersionNestedUpsertArchiveCreateInput>;
};

export type MetadataEditorialVersionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.archive" relation. */
  update?: InputMaybe<MetadataEditorialVersionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.archive" relation. */
  upsert?: InputMaybe<MetadataEditorialVersionNestedUpsertArchiveUpdateInput>;
};

export type MetadataEditorialVersionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.metadata" relation. */
  update?: InputMaybe<MetadataEditorialVersionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.metadata" relation. */
  upsert?: InputMaybe<MetadataEditorialVersionNestedUpsertMetadataCreateInput>;
};

export type MetadataEditorialVersionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.metadata" relation. */
  update?: InputMaybe<MetadataEditorialVersionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.metadata" relation. */
  upsert?: InputMaybe<MetadataEditorialVersionNestedUpsertMetadataUpdateInput>;
};

export type MetadataEditorialVersionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.photo" relation. */
  update?: InputMaybe<MetadataEditorialVersionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.photo" relation. */
  upsert?: InputMaybe<MetadataEditorialVersionNestedUpsertPhotoCreateInput>;
};

export type MetadataEditorialVersionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.photo" relation. */
  update?: InputMaybe<MetadataEditorialVersionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataEditorialVersion" node, through the "MetadataEditorialVersion.photo" relation. */
  upsert?: InputMaybe<MetadataEditorialVersionNestedUpsertPhotoUpdateInput>;
};

export type MetadataEditorialVersionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditorialVersionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditorialVersionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditorialVersionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditorialVersionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEditorialVersionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEditorialVersionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditorialVersionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditorialVersionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditorialVersionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditorialVersionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEditorialVersionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataEditorialVersionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataEditorialVersionUpdateInput = {
  /** Actions for the "MetadataEditorialVersion.archive" relation */
  archive?: InputMaybe<MetadataEditorialVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEditorialVersion.metadata" relation */
  metadata?: InputMaybe<MetadataEditorialVersionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEditorialVersion.photo" relation */
  photo?: InputMaybe<MetadataEditorialVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataEditorialVersion" nodes by specifying some conditions */
export type MetadataEditorialVersionWhereInput = {
  AND?: InputMaybe<Array<MetadataEditorialVersionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataEditorialVersionWhereInput>;
  OR?: InputMaybe<Array<MetadataEditorialVersionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataEditorialVersion" node. */
export type MetadataEditorialVersionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataEditorialVersionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataEditorialVersion.metadata" relation */
  metadata: MetadataEditorialVersionNestedMetadataCreateInput;
  /** Actions for the "MetadataEditorialVersion.photo" relation */
  photo: MetadataEditorialVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditorialVersionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataEditorialVersion.metadata" relation */
  metadata?: InputMaybe<MetadataEditorialVersionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEditorialVersion.photo" relation */
  photo?: InputMaybe<MetadataEditorialVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditorialVersionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataEditorialVersion.archive" relation */
  archive: MetadataEditorialVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataEditorialVersion.photo" relation */
  photo: MetadataEditorialVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditorialVersionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataEditorialVersion.archive" relation */
  archive?: InputMaybe<MetadataEditorialVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEditorialVersion.photo" relation */
  photo?: InputMaybe<MetadataEditorialVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditorialVersionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataEditorialVersion.archive" relation */
  archive: MetadataEditorialVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataEditorialVersion.metadata" relation */
  metadata: MetadataEditorialVersionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditorialVersionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataEditorialVersion.archive" relation */
  archive?: InputMaybe<MetadataEditorialVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEditorialVersion.metadata" relation */
  metadata?: InputMaybe<MetadataEditorialVersionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataEditStatus" resource's node */
export type MetadataEditStatus = {
  __typename?: 'MetadataEditStatus';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataEditStatusCreateInput = {
  /** Actions for the "MetadataEditStatus.archive" relation */
  archive: MetadataEditStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataEditStatus.metadata" relation */
  metadata: MetadataEditStatusNestedMetadataCreateInput;
  /** Actions for the "MetadataEditStatus.photo" relation */
  photo: MetadataEditStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditStatusNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataEditStatus" node, through the "MetadataEditStatus.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataEditStatus" node, through the "MetadataEditStatus.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataEditStatus" node, through the "MetadataEditStatus.archive" relation. */
  update?: InputMaybe<MetadataEditStatusNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataEditStatus" node, through the "MetadataEditStatus.archive" relation. */
  upsert?: InputMaybe<MetadataEditStatusNestedUpsertArchiveCreateInput>;
};

export type MetadataEditStatusNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.archive" relation. */
  update?: InputMaybe<MetadataEditStatusNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.archive" relation. */
  upsert?: InputMaybe<MetadataEditStatusNestedUpsertArchiveUpdateInput>;
};

export type MetadataEditStatusNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataEditStatus" node, through the "MetadataEditStatus.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataEditStatus" node, through the "MetadataEditStatus.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataEditStatus" node, through the "MetadataEditStatus.metadata" relation. */
  update?: InputMaybe<MetadataEditStatusNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataEditStatus" node, through the "MetadataEditStatus.metadata" relation. */
  upsert?: InputMaybe<MetadataEditStatusNestedUpsertMetadataCreateInput>;
};

export type MetadataEditStatusNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.metadata" relation. */
  update?: InputMaybe<MetadataEditStatusNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.metadata" relation. */
  upsert?: InputMaybe<MetadataEditStatusNestedUpsertMetadataUpdateInput>;
};

export type MetadataEditStatusNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataEditStatus" node, through the "MetadataEditStatus.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataEditStatus" node, through the "MetadataEditStatus.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataEditStatus" node, through the "MetadataEditStatus.photo" relation. */
  update?: InputMaybe<MetadataEditStatusNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataEditStatus" node, through the "MetadataEditStatus.photo" relation. */
  upsert?: InputMaybe<MetadataEditStatusNestedUpsertPhotoCreateInput>;
};

export type MetadataEditStatusNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.photo" relation. */
  update?: InputMaybe<MetadataEditStatusNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataEditStatus" node, through the "MetadataEditStatus.photo" relation. */
  upsert?: InputMaybe<MetadataEditStatusNestedUpsertPhotoUpdateInput>;
};

export type MetadataEditStatusNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditStatusNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditStatusNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditStatusNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditStatusNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEditStatusNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEditStatusNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditStatusNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEditStatusNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditStatusNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEditStatusNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEditStatusNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataEditStatusOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataEditStatusUpdateInput = {
  /** Actions for the "MetadataEditStatus.archive" relation */
  archive?: InputMaybe<MetadataEditStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEditStatus.metadata" relation */
  metadata?: InputMaybe<MetadataEditStatusNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEditStatus.photo" relation */
  photo?: InputMaybe<MetadataEditStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataEditStatus" nodes by specifying some conditions */
export type MetadataEditStatusWhereInput = {
  AND?: InputMaybe<Array<MetadataEditStatusWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataEditStatusWhereInput>;
  OR?: InputMaybe<Array<MetadataEditStatusWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataEditStatus" node. */
export type MetadataEditStatusWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataEditStatusWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataEditStatus.metadata" relation */
  metadata: MetadataEditStatusNestedMetadataCreateInput;
  /** Actions for the "MetadataEditStatus.photo" relation */
  photo: MetadataEditStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditStatusWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataEditStatus.metadata" relation */
  metadata?: InputMaybe<MetadataEditStatusNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEditStatus.photo" relation */
  photo?: InputMaybe<MetadataEditStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditStatusWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataEditStatus.archive" relation */
  archive: MetadataEditStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataEditStatus.photo" relation */
  photo: MetadataEditStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditStatusWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataEditStatus.archive" relation */
  archive?: InputMaybe<MetadataEditStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEditStatus.photo" relation */
  photo?: InputMaybe<MetadataEditStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditStatusWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataEditStatus.archive" relation */
  archive: MetadataEditStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataEditStatus.metadata" relation */
  metadata: MetadataEditStatusNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEditStatusWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataEditStatus.archive" relation */
  archive?: InputMaybe<MetadataEditStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEditStatus.metadata" relation */
  metadata?: InputMaybe<MetadataEditStatusNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataEnvironnementPhoto" resource's node */
export type MetadataEnvironnementPhoto = {
  __typename?: 'MetadataEnvironnementPhoto';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataEnvironnementPhotoCreateInput = {
  /** Actions for the "MetadataEnvironnementPhoto.archive" relation */
  archive: MetadataEnvironnementPhotoNestedArchiveCreateInput;
  /** Actions for the "MetadataEnvironnementPhoto.metadata" relation */
  metadata: MetadataEnvironnementPhotoNestedMetadataCreateInput;
  /** Actions for the "MetadataEnvironnementPhoto.photo" relation */
  photo: MetadataEnvironnementPhotoNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEnvironnementPhotoNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.archive" relation. */
  update?: InputMaybe<MetadataEnvironnementPhotoNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.archive" relation. */
  upsert?: InputMaybe<MetadataEnvironnementPhotoNestedUpsertArchiveCreateInput>;
};

export type MetadataEnvironnementPhotoNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.archive" relation. */
  update?: InputMaybe<MetadataEnvironnementPhotoNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.archive" relation. */
  upsert?: InputMaybe<MetadataEnvironnementPhotoNestedUpsertArchiveUpdateInput>;
};

export type MetadataEnvironnementPhotoNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.metadata" relation. */
  update?: InputMaybe<MetadataEnvironnementPhotoNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.metadata" relation. */
  upsert?: InputMaybe<MetadataEnvironnementPhotoNestedUpsertMetadataCreateInput>;
};

export type MetadataEnvironnementPhotoNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.metadata" relation. */
  update?: InputMaybe<MetadataEnvironnementPhotoNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.metadata" relation. */
  upsert?: InputMaybe<MetadataEnvironnementPhotoNestedUpsertMetadataUpdateInput>;
};

export type MetadataEnvironnementPhotoNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.photo" relation. */
  update?: InputMaybe<MetadataEnvironnementPhotoNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.photo" relation. */
  upsert?: InputMaybe<MetadataEnvironnementPhotoNestedUpsertPhotoCreateInput>;
};

export type MetadataEnvironnementPhotoNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.photo" relation. */
  update?: InputMaybe<MetadataEnvironnementPhotoNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataEnvironnementPhoto" node, through the "MetadataEnvironnementPhoto.photo" relation. */
  upsert?: InputMaybe<MetadataEnvironnementPhotoNestedUpsertPhotoUpdateInput>;
};

export type MetadataEnvironnementPhotoNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEnvironnementPhotoNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEnvironnementPhotoNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEnvironnementPhotoNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEnvironnementPhotoNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEnvironnementPhotoNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEnvironnementPhotoNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEnvironnementPhotoNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEnvironnementPhotoNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEnvironnementPhotoNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEnvironnementPhotoNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEnvironnementPhotoNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataEnvironnementPhotoOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataEnvironnementPhotoUpdateInput = {
  /** Actions for the "MetadataEnvironnementPhoto.archive" relation */
  archive?: InputMaybe<MetadataEnvironnementPhotoNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEnvironnementPhoto.metadata" relation */
  metadata?: InputMaybe<MetadataEnvironnementPhotoNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEnvironnementPhoto.photo" relation */
  photo?: InputMaybe<MetadataEnvironnementPhotoNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataEnvironnementPhoto" nodes by specifying some conditions */
export type MetadataEnvironnementPhotoWhereInput = {
  AND?: InputMaybe<Array<MetadataEnvironnementPhotoWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
  OR?: InputMaybe<Array<MetadataEnvironnementPhotoWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataEnvironnementPhoto" node. */
export type MetadataEnvironnementPhotoWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataEnvironnementPhotoWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataEnvironnementPhoto.metadata" relation */
  metadata: MetadataEnvironnementPhotoNestedMetadataCreateInput;
  /** Actions for the "MetadataEnvironnementPhoto.photo" relation */
  photo: MetadataEnvironnementPhotoNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEnvironnementPhotoWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataEnvironnementPhoto.metadata" relation */
  metadata?: InputMaybe<MetadataEnvironnementPhotoNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEnvironnementPhoto.photo" relation */
  photo?: InputMaybe<MetadataEnvironnementPhotoNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEnvironnementPhotoWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataEnvironnementPhoto.archive" relation */
  archive: MetadataEnvironnementPhotoNestedArchiveCreateInput;
  /** Actions for the "MetadataEnvironnementPhoto.photo" relation */
  photo: MetadataEnvironnementPhotoNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEnvironnementPhotoWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataEnvironnementPhoto.archive" relation */
  archive?: InputMaybe<MetadataEnvironnementPhotoNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEnvironnementPhoto.photo" relation */
  photo?: InputMaybe<MetadataEnvironnementPhotoNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEnvironnementPhotoWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataEnvironnementPhoto.archive" relation */
  archive: MetadataEnvironnementPhotoNestedArchiveCreateInput;
  /** Actions for the "MetadataEnvironnementPhoto.metadata" relation */
  metadata: MetadataEnvironnementPhotoNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEnvironnementPhotoWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataEnvironnementPhoto.archive" relation */
  archive?: InputMaybe<MetadataEnvironnementPhotoNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEnvironnementPhoto.metadata" relation */
  metadata?: InputMaybe<MetadataEnvironnementPhotoNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataEquipmentInstitution" resource's node */
export type MetadataEquipmentInstitution = {
  __typename?: 'MetadataEquipmentInstitution';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataEquipmentInstitutionCreateInput = {
  /** Actions for the "MetadataEquipmentInstitution.archive" relation */
  archive: MetadataEquipmentInstitutionNestedArchiveCreateInput;
  /** Actions for the "MetadataEquipmentInstitution.metadata" relation */
  metadata: MetadataEquipmentInstitutionNestedMetadataCreateInput;
  /** Actions for the "MetadataEquipmentInstitution.photo" relation */
  photo: MetadataEquipmentInstitutionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentInstitutionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.archive" relation. */
  update?: InputMaybe<MetadataEquipmentInstitutionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.archive" relation. */
  upsert?: InputMaybe<MetadataEquipmentInstitutionNestedUpsertArchiveCreateInput>;
};

export type MetadataEquipmentInstitutionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.archive" relation. */
  update?: InputMaybe<MetadataEquipmentInstitutionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.archive" relation. */
  upsert?: InputMaybe<MetadataEquipmentInstitutionNestedUpsertArchiveUpdateInput>;
};

export type MetadataEquipmentInstitutionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.metadata" relation. */
  update?: InputMaybe<MetadataEquipmentInstitutionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.metadata" relation. */
  upsert?: InputMaybe<MetadataEquipmentInstitutionNestedUpsertMetadataCreateInput>;
};

export type MetadataEquipmentInstitutionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.metadata" relation. */
  update?: InputMaybe<MetadataEquipmentInstitutionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.metadata" relation. */
  upsert?: InputMaybe<MetadataEquipmentInstitutionNestedUpsertMetadataUpdateInput>;
};

export type MetadataEquipmentInstitutionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.photo" relation. */
  update?: InputMaybe<MetadataEquipmentInstitutionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.photo" relation. */
  upsert?: InputMaybe<MetadataEquipmentInstitutionNestedUpsertPhotoCreateInput>;
};

export type MetadataEquipmentInstitutionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.photo" relation. */
  update?: InputMaybe<MetadataEquipmentInstitutionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataEquipmentInstitution" node, through the "MetadataEquipmentInstitution.photo" relation. */
  upsert?: InputMaybe<MetadataEquipmentInstitutionNestedUpsertPhotoUpdateInput>;
};

export type MetadataEquipmentInstitutionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEquipmentInstitutionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEquipmentInstitutionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEquipmentInstitutionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEquipmentInstitutionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEquipmentInstitutionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEquipmentInstitutionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEquipmentInstitutionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEquipmentInstitutionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEquipmentInstitutionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEquipmentInstitutionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEquipmentInstitutionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataEquipmentInstitutionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataEquipmentInstitutionUpdateInput = {
  /** Actions for the "MetadataEquipmentInstitution.archive" relation */
  archive?: InputMaybe<MetadataEquipmentInstitutionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEquipmentInstitution.metadata" relation */
  metadata?: InputMaybe<MetadataEquipmentInstitutionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEquipmentInstitution.photo" relation */
  photo?: InputMaybe<MetadataEquipmentInstitutionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataEquipmentInstitution" nodes by specifying some conditions */
export type MetadataEquipmentInstitutionWhereInput = {
  AND?: InputMaybe<Array<MetadataEquipmentInstitutionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
  OR?: InputMaybe<Array<MetadataEquipmentInstitutionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataEquipmentInstitution" node. */
export type MetadataEquipmentInstitutionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataEquipmentInstitutionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataEquipmentInstitution.metadata" relation */
  metadata: MetadataEquipmentInstitutionNestedMetadataCreateInput;
  /** Actions for the "MetadataEquipmentInstitution.photo" relation */
  photo: MetadataEquipmentInstitutionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentInstitutionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataEquipmentInstitution.metadata" relation */
  metadata?: InputMaybe<MetadataEquipmentInstitutionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEquipmentInstitution.photo" relation */
  photo?: InputMaybe<MetadataEquipmentInstitutionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentInstitutionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataEquipmentInstitution.archive" relation */
  archive: MetadataEquipmentInstitutionNestedArchiveCreateInput;
  /** Actions for the "MetadataEquipmentInstitution.photo" relation */
  photo: MetadataEquipmentInstitutionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentInstitutionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataEquipmentInstitution.archive" relation */
  archive?: InputMaybe<MetadataEquipmentInstitutionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEquipmentInstitution.photo" relation */
  photo?: InputMaybe<MetadataEquipmentInstitutionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentInstitutionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataEquipmentInstitution.archive" relation */
  archive: MetadataEquipmentInstitutionNestedArchiveCreateInput;
  /** Actions for the "MetadataEquipmentInstitution.metadata" relation */
  metadata: MetadataEquipmentInstitutionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentInstitutionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataEquipmentInstitution.archive" relation */
  archive?: InputMaybe<MetadataEquipmentInstitutionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEquipmentInstitution.metadata" relation */
  metadata?: InputMaybe<MetadataEquipmentInstitutionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataEquipmentManufacturer" resource's node */
export type MetadataEquipmentManufacturer = {
  __typename?: 'MetadataEquipmentManufacturer';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataEquipmentManufacturerCreateInput = {
  /** Actions for the "MetadataEquipmentManufacturer.archive" relation */
  archive: MetadataEquipmentManufacturerNestedArchiveCreateInput;
  /** Actions for the "MetadataEquipmentManufacturer.metadata" relation */
  metadata: MetadataEquipmentManufacturerNestedMetadataCreateInput;
  /** Actions for the "MetadataEquipmentManufacturer.photo" relation */
  photo: MetadataEquipmentManufacturerNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentManufacturerNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.archive" relation. */
  update?: InputMaybe<MetadataEquipmentManufacturerNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.archive" relation. */
  upsert?: InputMaybe<MetadataEquipmentManufacturerNestedUpsertArchiveCreateInput>;
};

export type MetadataEquipmentManufacturerNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.archive" relation. */
  update?: InputMaybe<MetadataEquipmentManufacturerNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.archive" relation. */
  upsert?: InputMaybe<MetadataEquipmentManufacturerNestedUpsertArchiveUpdateInput>;
};

export type MetadataEquipmentManufacturerNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.metadata" relation. */
  update?: InputMaybe<MetadataEquipmentManufacturerNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.metadata" relation. */
  upsert?: InputMaybe<MetadataEquipmentManufacturerNestedUpsertMetadataCreateInput>;
};

export type MetadataEquipmentManufacturerNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.metadata" relation. */
  update?: InputMaybe<MetadataEquipmentManufacturerNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.metadata" relation. */
  upsert?: InputMaybe<MetadataEquipmentManufacturerNestedUpsertMetadataUpdateInput>;
};

export type MetadataEquipmentManufacturerNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.photo" relation. */
  update?: InputMaybe<MetadataEquipmentManufacturerNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.photo" relation. */
  upsert?: InputMaybe<MetadataEquipmentManufacturerNestedUpsertPhotoCreateInput>;
};

export type MetadataEquipmentManufacturerNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.photo" relation. */
  update?: InputMaybe<MetadataEquipmentManufacturerNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataEquipmentManufacturer" node, through the "MetadataEquipmentManufacturer.photo" relation. */
  upsert?: InputMaybe<MetadataEquipmentManufacturerNestedUpsertPhotoUpdateInput>;
};

export type MetadataEquipmentManufacturerNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEquipmentManufacturerNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEquipmentManufacturerNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEquipmentManufacturerNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEquipmentManufacturerNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEquipmentManufacturerNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEquipmentManufacturerNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEquipmentManufacturerNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEquipmentManufacturerNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEquipmentManufacturerNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEquipmentManufacturerNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEquipmentManufacturerNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataEquipmentManufacturerOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataEquipmentManufacturerUpdateInput = {
  /** Actions for the "MetadataEquipmentManufacturer.archive" relation */
  archive?: InputMaybe<MetadataEquipmentManufacturerNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEquipmentManufacturer.metadata" relation */
  metadata?: InputMaybe<MetadataEquipmentManufacturerNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEquipmentManufacturer.photo" relation */
  photo?: InputMaybe<MetadataEquipmentManufacturerNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataEquipmentManufacturer" nodes by specifying some conditions */
export type MetadataEquipmentManufacturerWhereInput = {
  AND?: InputMaybe<Array<MetadataEquipmentManufacturerWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
  OR?: InputMaybe<Array<MetadataEquipmentManufacturerWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataEquipmentManufacturer" node. */
export type MetadataEquipmentManufacturerWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataEquipmentManufacturerWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataEquipmentManufacturer.metadata" relation */
  metadata: MetadataEquipmentManufacturerNestedMetadataCreateInput;
  /** Actions for the "MetadataEquipmentManufacturer.photo" relation */
  photo: MetadataEquipmentManufacturerNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentManufacturerWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataEquipmentManufacturer.metadata" relation */
  metadata?: InputMaybe<MetadataEquipmentManufacturerNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEquipmentManufacturer.photo" relation */
  photo?: InputMaybe<MetadataEquipmentManufacturerNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentManufacturerWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataEquipmentManufacturer.archive" relation */
  archive: MetadataEquipmentManufacturerNestedArchiveCreateInput;
  /** Actions for the "MetadataEquipmentManufacturer.photo" relation */
  photo: MetadataEquipmentManufacturerNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentManufacturerWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataEquipmentManufacturer.archive" relation */
  archive?: InputMaybe<MetadataEquipmentManufacturerNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEquipmentManufacturer.photo" relation */
  photo?: InputMaybe<MetadataEquipmentManufacturerNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentManufacturerWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataEquipmentManufacturer.archive" relation */
  archive: MetadataEquipmentManufacturerNestedArchiveCreateInput;
  /** Actions for the "MetadataEquipmentManufacturer.metadata" relation */
  metadata: MetadataEquipmentManufacturerNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEquipmentManufacturerWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataEquipmentManufacturer.archive" relation */
  archive?: InputMaybe<MetadataEquipmentManufacturerNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEquipmentManufacturer.metadata" relation */
  metadata?: InputMaybe<MetadataEquipmentManufacturerNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataEvent" resource's node */
export type MetadataEvent = {
  __typename?: 'MetadataEvent';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataEventCreateInput = {
  /** Actions for the "MetadataEvent.archive" relation */
  archive: MetadataEventNestedArchiveCreateInput;
  /** Actions for the "MetadataEvent.metadata" relation */
  metadata: MetadataEventNestedMetadataCreateInput;
  /** Actions for the "MetadataEvent.photo" relation */
  photo: MetadataEventNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEventNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataEvent" node, through the "MetadataEvent.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataEvent" node, through the "MetadataEvent.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataEvent" node, through the "MetadataEvent.archive" relation. */
  update?: InputMaybe<MetadataEventNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataEvent" node, through the "MetadataEvent.archive" relation. */
  upsert?: InputMaybe<MetadataEventNestedUpsertArchiveCreateInput>;
};

export type MetadataEventNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataEvent" node, through the "MetadataEvent.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataEvent" node, through the "MetadataEvent.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataEvent" node, through the "MetadataEvent.archive" relation. */
  update?: InputMaybe<MetadataEventNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataEvent" node, through the "MetadataEvent.archive" relation. */
  upsert?: InputMaybe<MetadataEventNestedUpsertArchiveUpdateInput>;
};

export type MetadataEventNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataEvent" node, through the "MetadataEvent.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataEvent" node, through the "MetadataEvent.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataEvent" node, through the "MetadataEvent.metadata" relation. */
  update?: InputMaybe<MetadataEventNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataEvent" node, through the "MetadataEvent.metadata" relation. */
  upsert?: InputMaybe<MetadataEventNestedUpsertMetadataCreateInput>;
};

export type MetadataEventNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataEvent" node, through the "MetadataEvent.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataEvent" node, through the "MetadataEvent.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataEvent" node, through the "MetadataEvent.metadata" relation. */
  update?: InputMaybe<MetadataEventNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataEvent" node, through the "MetadataEvent.metadata" relation. */
  upsert?: InputMaybe<MetadataEventNestedUpsertMetadataUpdateInput>;
};

export type MetadataEventNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataEvent" node, through the "MetadataEvent.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataEvent" node, through the "MetadataEvent.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataEvent" node, through the "MetadataEvent.photo" relation. */
  update?: InputMaybe<MetadataEventNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataEvent" node, through the "MetadataEvent.photo" relation. */
  upsert?: InputMaybe<MetadataEventNestedUpsertPhotoCreateInput>;
};

export type MetadataEventNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataEvent" node, through the "MetadataEvent.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataEvent" node, through the "MetadataEvent.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataEvent" node, through the "MetadataEvent.photo" relation. */
  update?: InputMaybe<MetadataEventNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataEvent" node, through the "MetadataEvent.photo" relation. */
  upsert?: InputMaybe<MetadataEventNestedUpsertPhotoUpdateInput>;
};

export type MetadataEventNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEventNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEventNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEventNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEventNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEventNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEventNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEventNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataEventNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEventNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataEventNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataEventNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataEventOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataEventUpdateInput = {
  /** Actions for the "MetadataEvent.archive" relation */
  archive?: InputMaybe<MetadataEventNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEvent.metadata" relation */
  metadata?: InputMaybe<MetadataEventNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEvent.photo" relation */
  photo?: InputMaybe<MetadataEventNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataEvent" nodes by specifying some conditions */
export type MetadataEventWhereInput = {
  AND?: InputMaybe<Array<MetadataEventWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataEventWhereInput>;
  OR?: InputMaybe<Array<MetadataEventWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataEvent" node. */
export type MetadataEventWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataEventWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataEvent.metadata" relation */
  metadata: MetadataEventNestedMetadataCreateInput;
  /** Actions for the "MetadataEvent.photo" relation */
  photo: MetadataEventNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEventWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataEvent.metadata" relation */
  metadata?: InputMaybe<MetadataEventNestedMetadataUpdateInput>;
  /** Actions for the "MetadataEvent.photo" relation */
  photo?: InputMaybe<MetadataEventNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEventWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataEvent.archive" relation */
  archive: MetadataEventNestedArchiveCreateInput;
  /** Actions for the "MetadataEvent.photo" relation */
  photo: MetadataEventNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEventWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataEvent.archive" relation */
  archive?: InputMaybe<MetadataEventNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEvent.photo" relation */
  photo?: InputMaybe<MetadataEventNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEventWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataEvent.archive" relation */
  archive: MetadataEventNestedArchiveCreateInput;
  /** Actions for the "MetadataEvent.metadata" relation */
  metadata: MetadataEventNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataEventWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataEvent.archive" relation */
  archive?: InputMaybe<MetadataEventNestedArchiveUpdateInput>;
  /** Actions for the "MetadataEvent.metadata" relation */
  metadata?: InputMaybe<MetadataEventNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataExif" resource's node */
export type MetadataExif = {
  __typename?: 'MetadataExif';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataExifCreateInput = {
  /** Actions for the "MetadataExif.archive" relation */
  archive: MetadataExifNestedArchiveCreateInput;
  /** Actions for the "MetadataExif.metadata" relation */
  metadata: MetadataExifNestedMetadataCreateInput;
  /** Actions for the "MetadataExif.photo" relation */
  photo: MetadataExifNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataExifNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataExif" node, through the "MetadataExif.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataExif" node, through the "MetadataExif.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataExif" node, through the "MetadataExif.archive" relation. */
  update?: InputMaybe<MetadataExifNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataExif" node, through the "MetadataExif.archive" relation. */
  upsert?: InputMaybe<MetadataExifNestedUpsertArchiveCreateInput>;
};

export type MetadataExifNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataExif" node, through the "MetadataExif.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataExif" node, through the "MetadataExif.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataExif" node, through the "MetadataExif.archive" relation. */
  update?: InputMaybe<MetadataExifNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataExif" node, through the "MetadataExif.archive" relation. */
  upsert?: InputMaybe<MetadataExifNestedUpsertArchiveUpdateInput>;
};

export type MetadataExifNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataExif" node, through the "MetadataExif.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataExif" node, through the "MetadataExif.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataExif" node, through the "MetadataExif.metadata" relation. */
  update?: InputMaybe<MetadataExifNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataExif" node, through the "MetadataExif.metadata" relation. */
  upsert?: InputMaybe<MetadataExifNestedUpsertMetadataCreateInput>;
};

export type MetadataExifNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataExif" node, through the "MetadataExif.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataExif" node, through the "MetadataExif.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataExif" node, through the "MetadataExif.metadata" relation. */
  update?: InputMaybe<MetadataExifNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataExif" node, through the "MetadataExif.metadata" relation. */
  upsert?: InputMaybe<MetadataExifNestedUpsertMetadataUpdateInput>;
};

export type MetadataExifNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataExif" node, through the "MetadataExif.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataExif" node, through the "MetadataExif.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataExif" node, through the "MetadataExif.photo" relation. */
  update?: InputMaybe<MetadataExifNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataExif" node, through the "MetadataExif.photo" relation. */
  upsert?: InputMaybe<MetadataExifNestedUpsertPhotoCreateInput>;
};

export type MetadataExifNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataExif" node, through the "MetadataExif.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataExif" node, through the "MetadataExif.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataExif" node, through the "MetadataExif.photo" relation. */
  update?: InputMaybe<MetadataExifNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataExif" node, through the "MetadataExif.photo" relation. */
  upsert?: InputMaybe<MetadataExifNestedUpsertPhotoUpdateInput>;
};

export type MetadataExifNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataExifNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataExifNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataExifNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataExifNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataExifNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataExifNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataExifNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataExifNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataExifNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataExifNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataExifNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataExifOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataExifUpdateInput = {
  /** Actions for the "MetadataExif.archive" relation */
  archive?: InputMaybe<MetadataExifNestedArchiveUpdateInput>;
  /** Actions for the "MetadataExif.metadata" relation */
  metadata?: InputMaybe<MetadataExifNestedMetadataUpdateInput>;
  /** Actions for the "MetadataExif.photo" relation */
  photo?: InputMaybe<MetadataExifNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataExif" nodes by specifying some conditions */
export type MetadataExifWhereInput = {
  AND?: InputMaybe<Array<MetadataExifWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataExifWhereInput>;
  OR?: InputMaybe<Array<MetadataExifWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataExif" node. */
export type MetadataExifWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataExifWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataExif.metadata" relation */
  metadata: MetadataExifNestedMetadataCreateInput;
  /** Actions for the "MetadataExif.photo" relation */
  photo: MetadataExifNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataExifWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataExif.metadata" relation */
  metadata?: InputMaybe<MetadataExifNestedMetadataUpdateInput>;
  /** Actions for the "MetadataExif.photo" relation */
  photo?: InputMaybe<MetadataExifNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataExifWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataExif.archive" relation */
  archive: MetadataExifNestedArchiveCreateInput;
  /** Actions for the "MetadataExif.photo" relation */
  photo: MetadataExifNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataExifWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataExif.archive" relation */
  archive?: InputMaybe<MetadataExifNestedArchiveUpdateInput>;
  /** Actions for the "MetadataExif.photo" relation */
  photo?: InputMaybe<MetadataExifNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataExifWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataExif.archive" relation */
  archive: MetadataExifNestedArchiveCreateInput;
  /** Actions for the "MetadataExif.metadata" relation */
  metadata: MetadataExifNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataExifWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataExif.archive" relation */
  archive?: InputMaybe<MetadataExifNestedArchiveUpdateInput>;
  /** Actions for the "MetadataExif.metadata" relation */
  metadata?: InputMaybe<MetadataExifNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataFabStorage" resource's node */
export type MetadataFabStorage = {
  __typename?: 'MetadataFabStorage';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataFabStorageCreateInput = {
  /** Actions for the "MetadataFabStorage.archive" relation */
  archive: MetadataFabStorageNestedArchiveCreateInput;
  /** Actions for the "MetadataFabStorage.metadata" relation */
  metadata: MetadataFabStorageNestedMetadataCreateInput;
  /** Actions for the "MetadataFabStorage.photo" relation */
  photo: MetadataFabStorageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFabStorageNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataFabStorage" node, through the "MetadataFabStorage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataFabStorage" node, through the "MetadataFabStorage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataFabStorage" node, through the "MetadataFabStorage.archive" relation. */
  update?: InputMaybe<MetadataFabStorageNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataFabStorage" node, through the "MetadataFabStorage.archive" relation. */
  upsert?: InputMaybe<MetadataFabStorageNestedUpsertArchiveCreateInput>;
};

export type MetadataFabStorageNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.archive" relation. */
  update?: InputMaybe<MetadataFabStorageNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.archive" relation. */
  upsert?: InputMaybe<MetadataFabStorageNestedUpsertArchiveUpdateInput>;
};

export type MetadataFabStorageNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataFabStorage" node, through the "MetadataFabStorage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataFabStorage" node, through the "MetadataFabStorage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataFabStorage" node, through the "MetadataFabStorage.metadata" relation. */
  update?: InputMaybe<MetadataFabStorageNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataFabStorage" node, through the "MetadataFabStorage.metadata" relation. */
  upsert?: InputMaybe<MetadataFabStorageNestedUpsertMetadataCreateInput>;
};

export type MetadataFabStorageNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.metadata" relation. */
  update?: InputMaybe<MetadataFabStorageNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.metadata" relation. */
  upsert?: InputMaybe<MetadataFabStorageNestedUpsertMetadataUpdateInput>;
};

export type MetadataFabStorageNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataFabStorage" node, through the "MetadataFabStorage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataFabStorage" node, through the "MetadataFabStorage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataFabStorage" node, through the "MetadataFabStorage.photo" relation. */
  update?: InputMaybe<MetadataFabStorageNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataFabStorage" node, through the "MetadataFabStorage.photo" relation. */
  upsert?: InputMaybe<MetadataFabStorageNestedUpsertPhotoCreateInput>;
};

export type MetadataFabStorageNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.photo" relation. */
  update?: InputMaybe<MetadataFabStorageNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataFabStorage" node, through the "MetadataFabStorage.photo" relation. */
  upsert?: InputMaybe<MetadataFabStorageNestedUpsertPhotoUpdateInput>;
};

export type MetadataFabStorageNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFabStorageNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFabStorageNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFabStorageNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFabStorageNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFabStorageNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFabStorageNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFabStorageNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFabStorageNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFabStorageNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFabStorageNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFabStorageNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataFabStorageOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataFabStorageUpdateInput = {
  /** Actions for the "MetadataFabStorage.archive" relation */
  archive?: InputMaybe<MetadataFabStorageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFabStorage.metadata" relation */
  metadata?: InputMaybe<MetadataFabStorageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFabStorage.photo" relation */
  photo?: InputMaybe<MetadataFabStorageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataFabStorage" nodes by specifying some conditions */
export type MetadataFabStorageWhereInput = {
  AND?: InputMaybe<Array<MetadataFabStorageWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataFabStorageWhereInput>;
  OR?: InputMaybe<Array<MetadataFabStorageWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataFabStorage" node. */
export type MetadataFabStorageWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataFabStorageWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataFabStorage.metadata" relation */
  metadata: MetadataFabStorageNestedMetadataCreateInput;
  /** Actions for the "MetadataFabStorage.photo" relation */
  photo: MetadataFabStorageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFabStorageWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataFabStorage.metadata" relation */
  metadata?: InputMaybe<MetadataFabStorageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFabStorage.photo" relation */
  photo?: InputMaybe<MetadataFabStorageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFabStorageWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataFabStorage.archive" relation */
  archive: MetadataFabStorageNestedArchiveCreateInput;
  /** Actions for the "MetadataFabStorage.photo" relation */
  photo: MetadataFabStorageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFabStorageWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataFabStorage.archive" relation */
  archive?: InputMaybe<MetadataFabStorageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFabStorage.photo" relation */
  photo?: InputMaybe<MetadataFabStorageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFabStorageWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataFabStorage.archive" relation */
  archive: MetadataFabStorageNestedArchiveCreateInput;
  /** Actions for the "MetadataFabStorage.metadata" relation */
  metadata: MetadataFabStorageNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFabStorageWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataFabStorage.archive" relation */
  archive?: InputMaybe<MetadataFabStorageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFabStorage.metadata" relation */
  metadata?: InputMaybe<MetadataFabStorageNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataFileCheckFor" resource's node */
export type MetadataFileCheckFor = {
  __typename?: 'MetadataFileCheckFor';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataFileCheckForCreateInput = {
  /** Actions for the "MetadataFileCheckFor.archive" relation */
  archive: MetadataFileCheckForNestedArchiveCreateInput;
  /** Actions for the "MetadataFileCheckFor.metadata" relation */
  metadata: MetadataFileCheckForNestedMetadataCreateInput;
  /** Actions for the "MetadataFileCheckFor.photo" relation */
  photo: MetadataFileCheckForNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileCheckForNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.archive" relation. */
  update?: InputMaybe<MetadataFileCheckForNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.archive" relation. */
  upsert?: InputMaybe<MetadataFileCheckForNestedUpsertArchiveCreateInput>;
};

export type MetadataFileCheckForNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.archive" relation. */
  update?: InputMaybe<MetadataFileCheckForNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.archive" relation. */
  upsert?: InputMaybe<MetadataFileCheckForNestedUpsertArchiveUpdateInput>;
};

export type MetadataFileCheckForNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.metadata" relation. */
  update?: InputMaybe<MetadataFileCheckForNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.metadata" relation. */
  upsert?: InputMaybe<MetadataFileCheckForNestedUpsertMetadataCreateInput>;
};

export type MetadataFileCheckForNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.metadata" relation. */
  update?: InputMaybe<MetadataFileCheckForNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.metadata" relation. */
  upsert?: InputMaybe<MetadataFileCheckForNestedUpsertMetadataUpdateInput>;
};

export type MetadataFileCheckForNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.photo" relation. */
  update?: InputMaybe<MetadataFileCheckForNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.photo" relation. */
  upsert?: InputMaybe<MetadataFileCheckForNestedUpsertPhotoCreateInput>;
};

export type MetadataFileCheckForNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.photo" relation. */
  update?: InputMaybe<MetadataFileCheckForNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataFileCheckFor" node, through the "MetadataFileCheckFor.photo" relation. */
  upsert?: InputMaybe<MetadataFileCheckForNestedUpsertPhotoUpdateInput>;
};

export type MetadataFileCheckForNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileCheckForNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileCheckForNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileCheckForNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileCheckForNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileCheckForNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileCheckForNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileCheckForNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileCheckForNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileCheckForNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileCheckForNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileCheckForNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataFileCheckForOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataFileCheckForUpdateInput = {
  /** Actions for the "MetadataFileCheckFor.archive" relation */
  archive?: InputMaybe<MetadataFileCheckForNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileCheckFor.metadata" relation */
  metadata?: InputMaybe<MetadataFileCheckForNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileCheckFor.photo" relation */
  photo?: InputMaybe<MetadataFileCheckForNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataFileCheckFor" nodes by specifying some conditions */
export type MetadataFileCheckForWhereInput = {
  AND?: InputMaybe<Array<MetadataFileCheckForWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataFileCheckForWhereInput>;
  OR?: InputMaybe<Array<MetadataFileCheckForWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataFileCheckFor" node. */
export type MetadataFileCheckForWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataFileCheckForWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataFileCheckFor.metadata" relation */
  metadata: MetadataFileCheckForNestedMetadataCreateInput;
  /** Actions for the "MetadataFileCheckFor.photo" relation */
  photo: MetadataFileCheckForNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileCheckForWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataFileCheckFor.metadata" relation */
  metadata?: InputMaybe<MetadataFileCheckForNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileCheckFor.photo" relation */
  photo?: InputMaybe<MetadataFileCheckForNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileCheckForWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataFileCheckFor.archive" relation */
  archive: MetadataFileCheckForNestedArchiveCreateInput;
  /** Actions for the "MetadataFileCheckFor.photo" relation */
  photo: MetadataFileCheckForNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileCheckForWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataFileCheckFor.archive" relation */
  archive?: InputMaybe<MetadataFileCheckForNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileCheckFor.photo" relation */
  photo?: InputMaybe<MetadataFileCheckForNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileCheckForWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataFileCheckFor.archive" relation */
  archive: MetadataFileCheckForNestedArchiveCreateInput;
  /** Actions for the "MetadataFileCheckFor.metadata" relation */
  metadata: MetadataFileCheckForNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileCheckForWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataFileCheckFor.archive" relation */
  archive?: InputMaybe<MetadataFileCheckForNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileCheckFor.metadata" relation */
  metadata?: InputMaybe<MetadataFileCheckForNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataFileInfoBitsPerPixel" resource's node */
export type MetadataFileInfoBitsPerPixel = {
  __typename?: 'MetadataFileInfoBitsPerPixel';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataFileInfoBitsPerPixelCreateInput = {
  /** Actions for the "MetadataFileInfoBitsPerPixel.archive" relation */
  archive: MetadataFileInfoBitsPerPixelNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoBitsPerPixel.metadata" relation */
  metadata: MetadataFileInfoBitsPerPixelNestedMetadataCreateInput;
  /** Actions for the "MetadataFileInfoBitsPerPixel.photo" relation */
  photo: MetadataFileInfoBitsPerPixelNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoBitsPerPixelNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.archive" relation. */
  update?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.archive" relation. */
  upsert?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpsertArchiveCreateInput>;
};

export type MetadataFileInfoBitsPerPixelNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.archive" relation. */
  update?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.archive" relation. */
  upsert?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpsertArchiveUpdateInput>;
};

export type MetadataFileInfoBitsPerPixelNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.metadata" relation. */
  update?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.metadata" relation. */
  upsert?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpsertMetadataCreateInput>;
};

export type MetadataFileInfoBitsPerPixelNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.metadata" relation. */
  update?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.metadata" relation. */
  upsert?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpsertMetadataUpdateInput>;
};

export type MetadataFileInfoBitsPerPixelNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.photo" relation. */
  update?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.photo" relation. */
  upsert?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpsertPhotoCreateInput>;
};

export type MetadataFileInfoBitsPerPixelNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.photo" relation. */
  update?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataFileInfoBitsPerPixel" node, through the "MetadataFileInfoBitsPerPixel.photo" relation. */
  upsert?: InputMaybe<MetadataFileInfoBitsPerPixelNestedUpsertPhotoUpdateInput>;
};

export type MetadataFileInfoBitsPerPixelNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoBitsPerPixelNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoBitsPerPixelNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoBitsPerPixelNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoBitsPerPixelNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoBitsPerPixelNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoBitsPerPixelNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoBitsPerPixelNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoBitsPerPixelNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoBitsPerPixelNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoBitsPerPixelNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoBitsPerPixelNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataFileInfoBitsPerPixelOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataFileInfoBitsPerPixelUpdateInput = {
  /** Actions for the "MetadataFileInfoBitsPerPixel.archive" relation */
  archive?: InputMaybe<MetadataFileInfoBitsPerPixelNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoBitsPerPixel.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoBitsPerPixelNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileInfoBitsPerPixel.photo" relation */
  photo?: InputMaybe<MetadataFileInfoBitsPerPixelNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataFileInfoBitsPerPixel" nodes by specifying some conditions */
export type MetadataFileInfoBitsPerPixelWhereInput = {
  AND?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
  OR?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataFileInfoBitsPerPixel" node. */
export type MetadataFileInfoBitsPerPixelWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataFileInfoBitsPerPixelWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataFileInfoBitsPerPixel.metadata" relation */
  metadata: MetadataFileInfoBitsPerPixelNestedMetadataCreateInput;
  /** Actions for the "MetadataFileInfoBitsPerPixel.photo" relation */
  photo: MetadataFileInfoBitsPerPixelNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoBitsPerPixelWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataFileInfoBitsPerPixel.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoBitsPerPixelNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileInfoBitsPerPixel.photo" relation */
  photo?: InputMaybe<MetadataFileInfoBitsPerPixelNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoBitsPerPixelWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataFileInfoBitsPerPixel.archive" relation */
  archive: MetadataFileInfoBitsPerPixelNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoBitsPerPixel.photo" relation */
  photo: MetadataFileInfoBitsPerPixelNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoBitsPerPixelWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataFileInfoBitsPerPixel.archive" relation */
  archive?: InputMaybe<MetadataFileInfoBitsPerPixelNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoBitsPerPixel.photo" relation */
  photo?: InputMaybe<MetadataFileInfoBitsPerPixelNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoBitsPerPixelWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataFileInfoBitsPerPixel.archive" relation */
  archive: MetadataFileInfoBitsPerPixelNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoBitsPerPixel.metadata" relation */
  metadata: MetadataFileInfoBitsPerPixelNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoBitsPerPixelWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataFileInfoBitsPerPixel.archive" relation */
  archive?: InputMaybe<MetadataFileInfoBitsPerPixelNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoBitsPerPixel.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoBitsPerPixelNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataFileInfoFrames" resource's node */
export type MetadataFileInfoFrames = {
  __typename?: 'MetadataFileInfoFrames';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataFileInfoFramesCreateInput = {
  /** Actions for the "MetadataFileInfoFrames.archive" relation */
  archive: MetadataFileInfoFramesNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoFrames.metadata" relation */
  metadata: MetadataFileInfoFramesNestedMetadataCreateInput;
  /** Actions for the "MetadataFileInfoFrames.photo" relation */
  photo: MetadataFileInfoFramesNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoFramesNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.archive" relation. */
  update?: InputMaybe<MetadataFileInfoFramesNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.archive" relation. */
  upsert?: InputMaybe<MetadataFileInfoFramesNestedUpsertArchiveCreateInput>;
};

export type MetadataFileInfoFramesNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.archive" relation. */
  update?: InputMaybe<MetadataFileInfoFramesNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.archive" relation. */
  upsert?: InputMaybe<MetadataFileInfoFramesNestedUpsertArchiveUpdateInput>;
};

export type MetadataFileInfoFramesNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.metadata" relation. */
  update?: InputMaybe<MetadataFileInfoFramesNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.metadata" relation. */
  upsert?: InputMaybe<MetadataFileInfoFramesNestedUpsertMetadataCreateInput>;
};

export type MetadataFileInfoFramesNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.metadata" relation. */
  update?: InputMaybe<MetadataFileInfoFramesNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.metadata" relation. */
  upsert?: InputMaybe<MetadataFileInfoFramesNestedUpsertMetadataUpdateInput>;
};

export type MetadataFileInfoFramesNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.photo" relation. */
  update?: InputMaybe<MetadataFileInfoFramesNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.photo" relation. */
  upsert?: InputMaybe<MetadataFileInfoFramesNestedUpsertPhotoCreateInput>;
};

export type MetadataFileInfoFramesNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.photo" relation. */
  update?: InputMaybe<MetadataFileInfoFramesNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataFileInfoFrames" node, through the "MetadataFileInfoFrames.photo" relation. */
  upsert?: InputMaybe<MetadataFileInfoFramesNestedUpsertPhotoUpdateInput>;
};

export type MetadataFileInfoFramesNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoFramesNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoFramesNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoFramesNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoFramesNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoFramesNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoFramesNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoFramesNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoFramesNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoFramesNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoFramesNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoFramesNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataFileInfoFramesOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataFileInfoFramesUpdateInput = {
  /** Actions for the "MetadataFileInfoFrames.archive" relation */
  archive?: InputMaybe<MetadataFileInfoFramesNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoFrames.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoFramesNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileInfoFrames.photo" relation */
  photo?: InputMaybe<MetadataFileInfoFramesNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataFileInfoFrames" nodes by specifying some conditions */
export type MetadataFileInfoFramesWhereInput = {
  AND?: InputMaybe<Array<MetadataFileInfoFramesWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataFileInfoFramesWhereInput>;
  OR?: InputMaybe<Array<MetadataFileInfoFramesWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataFileInfoFrames" node. */
export type MetadataFileInfoFramesWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataFileInfoFramesWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataFileInfoFrames.metadata" relation */
  metadata: MetadataFileInfoFramesNestedMetadataCreateInput;
  /** Actions for the "MetadataFileInfoFrames.photo" relation */
  photo: MetadataFileInfoFramesNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoFramesWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataFileInfoFrames.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoFramesNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileInfoFrames.photo" relation */
  photo?: InputMaybe<MetadataFileInfoFramesNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoFramesWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataFileInfoFrames.archive" relation */
  archive: MetadataFileInfoFramesNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoFrames.photo" relation */
  photo: MetadataFileInfoFramesNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoFramesWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataFileInfoFrames.archive" relation */
  archive?: InputMaybe<MetadataFileInfoFramesNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoFrames.photo" relation */
  photo?: InputMaybe<MetadataFileInfoFramesNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoFramesWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataFileInfoFrames.archive" relation */
  archive: MetadataFileInfoFramesNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoFrames.metadata" relation */
  metadata: MetadataFileInfoFramesNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoFramesWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataFileInfoFrames.archive" relation */
  archive?: InputMaybe<MetadataFileInfoFramesNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoFrames.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoFramesNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataFileInfoPhotometricInterpretation" resource's node */
export type MetadataFileInfoPhotometricInterpretation = {
  __typename?: 'MetadataFileInfoPhotometricInterpretation';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataFileInfoPhotometricInterpretationCreateInput = {
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.archive" relation */
  archive: MetadataFileInfoPhotometricInterpretationNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.metadata" relation */
  metadata: MetadataFileInfoPhotometricInterpretationNestedMetadataCreateInput;
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.photo" relation */
  photo: MetadataFileInfoPhotometricInterpretationNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoPhotometricInterpretationNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.archive" relation. */
  update?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.archive" relation. */
  upsert?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpsertArchiveCreateInput>;
};

export type MetadataFileInfoPhotometricInterpretationNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.archive" relation. */
  update?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.archive" relation. */
  upsert?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpsertArchiveUpdateInput>;
};

export type MetadataFileInfoPhotometricInterpretationNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.metadata" relation. */
  update?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.metadata" relation. */
  upsert?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpsertMetadataCreateInput>;
};

export type MetadataFileInfoPhotometricInterpretationNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.metadata" relation. */
  update?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.metadata" relation. */
  upsert?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpsertMetadataUpdateInput>;
};

export type MetadataFileInfoPhotometricInterpretationNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.photo" relation. */
  update?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.photo" relation. */
  upsert?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpsertPhotoCreateInput>;
};

export type MetadataFileInfoPhotometricInterpretationNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.photo" relation. */
  update?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataFileInfoPhotometricInterpretation" node, through the "MetadataFileInfoPhotometricInterpretation.photo" relation. */
  upsert?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedUpsertPhotoUpdateInput>;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoPhotometricInterpretationNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataFileInfoPhotometricInterpretationOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataFileInfoPhotometricInterpretationUpdateInput = {
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.archive" relation */
  archive?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.photo" relation */
  photo?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataFileInfoPhotometricInterpretation" nodes by specifying some conditions */
export type MetadataFileInfoPhotometricInterpretationWhereInput = {
  AND?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
  OR?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataFileInfoPhotometricInterpretation" node. */
export type MetadataFileInfoPhotometricInterpretationWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataFileInfoPhotometricInterpretationWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.metadata" relation */
  metadata: MetadataFileInfoPhotometricInterpretationNestedMetadataCreateInput;
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.photo" relation */
  photo: MetadataFileInfoPhotometricInterpretationNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoPhotometricInterpretationWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.photo" relation */
  photo?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoPhotometricInterpretationWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.archive" relation */
  archive: MetadataFileInfoPhotometricInterpretationNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.photo" relation */
  photo: MetadataFileInfoPhotometricInterpretationNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoPhotometricInterpretationWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.archive" relation */
  archive?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.photo" relation */
  photo?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoPhotometricInterpretationWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.archive" relation */
  archive: MetadataFileInfoPhotometricInterpretationNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.metadata" relation */
  metadata: MetadataFileInfoPhotometricInterpretationNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoPhotometricInterpretationWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.archive" relation */
  archive?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoPhotometricInterpretation.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoPhotometricInterpretationNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataFileInfoTransferSyntax" resource's node */
export type MetadataFileInfoTransferSyntax = {
  __typename?: 'MetadataFileInfoTransferSyntax';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataFileInfoTransferSyntaxCreateInput = {
  /** Actions for the "MetadataFileInfoTransferSyntax.archive" relation */
  archive: MetadataFileInfoTransferSyntaxNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoTransferSyntax.metadata" relation */
  metadata: MetadataFileInfoTransferSyntaxNestedMetadataCreateInput;
  /** Actions for the "MetadataFileInfoTransferSyntax.photo" relation */
  photo: MetadataFileInfoTransferSyntaxNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoTransferSyntaxNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.archive" relation. */
  update?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.archive" relation. */
  upsert?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpsertArchiveCreateInput>;
};

export type MetadataFileInfoTransferSyntaxNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.archive" relation. */
  update?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.archive" relation. */
  upsert?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpsertArchiveUpdateInput>;
};

export type MetadataFileInfoTransferSyntaxNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.metadata" relation. */
  update?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.metadata" relation. */
  upsert?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpsertMetadataCreateInput>;
};

export type MetadataFileInfoTransferSyntaxNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.metadata" relation. */
  update?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.metadata" relation. */
  upsert?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpsertMetadataUpdateInput>;
};

export type MetadataFileInfoTransferSyntaxNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.photo" relation. */
  update?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.photo" relation. */
  upsert?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpsertPhotoCreateInput>;
};

export type MetadataFileInfoTransferSyntaxNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.photo" relation. */
  update?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataFileInfoTransferSyntax" node, through the "MetadataFileInfoTransferSyntax.photo" relation. */
  upsert?: InputMaybe<MetadataFileInfoTransferSyntaxNestedUpsertPhotoUpdateInput>;
};

export type MetadataFileInfoTransferSyntaxNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoTransferSyntaxNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoTransferSyntaxNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoTransferSyntaxNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoTransferSyntaxNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoTransferSyntaxNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoTransferSyntaxNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoTransferSyntaxNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileInfoTransferSyntaxNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoTransferSyntaxNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileInfoTransferSyntaxNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileInfoTransferSyntaxNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataFileInfoTransferSyntaxOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataFileInfoTransferSyntaxUpdateInput = {
  /** Actions for the "MetadataFileInfoTransferSyntax.archive" relation */
  archive?: InputMaybe<MetadataFileInfoTransferSyntaxNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoTransferSyntax.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoTransferSyntaxNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileInfoTransferSyntax.photo" relation */
  photo?: InputMaybe<MetadataFileInfoTransferSyntaxNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataFileInfoTransferSyntax" nodes by specifying some conditions */
export type MetadataFileInfoTransferSyntaxWhereInput = {
  AND?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
  OR?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataFileInfoTransferSyntax" node. */
export type MetadataFileInfoTransferSyntaxWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataFileInfoTransferSyntaxWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataFileInfoTransferSyntax.metadata" relation */
  metadata: MetadataFileInfoTransferSyntaxNestedMetadataCreateInput;
  /** Actions for the "MetadataFileInfoTransferSyntax.photo" relation */
  photo: MetadataFileInfoTransferSyntaxNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoTransferSyntaxWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataFileInfoTransferSyntax.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoTransferSyntaxNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileInfoTransferSyntax.photo" relation */
  photo?: InputMaybe<MetadataFileInfoTransferSyntaxNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoTransferSyntaxWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataFileInfoTransferSyntax.archive" relation */
  archive: MetadataFileInfoTransferSyntaxNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoTransferSyntax.photo" relation */
  photo: MetadataFileInfoTransferSyntaxNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoTransferSyntaxWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataFileInfoTransferSyntax.archive" relation */
  archive?: InputMaybe<MetadataFileInfoTransferSyntaxNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoTransferSyntax.photo" relation */
  photo?: InputMaybe<MetadataFileInfoTransferSyntaxNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoTransferSyntaxWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataFileInfoTransferSyntax.archive" relation */
  archive: MetadataFileInfoTransferSyntaxNestedArchiveCreateInput;
  /** Actions for the "MetadataFileInfoTransferSyntax.metadata" relation */
  metadata: MetadataFileInfoTransferSyntaxNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileInfoTransferSyntaxWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataFileInfoTransferSyntax.archive" relation */
  archive?: InputMaybe<MetadataFileInfoTransferSyntaxNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileInfoTransferSyntax.metadata" relation */
  metadata?: InputMaybe<MetadataFileInfoTransferSyntaxNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataFileStage" resource's node */
export type MetadataFileStage = {
  __typename?: 'MetadataFileStage';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataFileStageCreateInput = {
  /** Actions for the "MetadataFileStage.archive" relation */
  archive: MetadataFileStageNestedArchiveCreateInput;
  /** Actions for the "MetadataFileStage.metadata" relation */
  metadata: MetadataFileStageNestedMetadataCreateInput;
  /** Actions for the "MetadataFileStage.photo" relation */
  photo: MetadataFileStageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStageNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataFileStage" node, through the "MetadataFileStage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataFileStage" node, through the "MetadataFileStage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataFileStage" node, through the "MetadataFileStage.archive" relation. */
  update?: InputMaybe<MetadataFileStageNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataFileStage" node, through the "MetadataFileStage.archive" relation. */
  upsert?: InputMaybe<MetadataFileStageNestedUpsertArchiveCreateInput>;
};

export type MetadataFileStageNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataFileStage" node, through the "MetadataFileStage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataFileStage" node, through the "MetadataFileStage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataFileStage" node, through the "MetadataFileStage.archive" relation. */
  update?: InputMaybe<MetadataFileStageNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataFileStage" node, through the "MetadataFileStage.archive" relation. */
  upsert?: InputMaybe<MetadataFileStageNestedUpsertArchiveUpdateInput>;
};

export type MetadataFileStageNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataFileStage" node, through the "MetadataFileStage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataFileStage" node, through the "MetadataFileStage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataFileStage" node, through the "MetadataFileStage.metadata" relation. */
  update?: InputMaybe<MetadataFileStageNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataFileStage" node, through the "MetadataFileStage.metadata" relation. */
  upsert?: InputMaybe<MetadataFileStageNestedUpsertMetadataCreateInput>;
};

export type MetadataFileStageNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataFileStage" node, through the "MetadataFileStage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataFileStage" node, through the "MetadataFileStage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataFileStage" node, through the "MetadataFileStage.metadata" relation. */
  update?: InputMaybe<MetadataFileStageNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataFileStage" node, through the "MetadataFileStage.metadata" relation. */
  upsert?: InputMaybe<MetadataFileStageNestedUpsertMetadataUpdateInput>;
};

export type MetadataFileStageNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataFileStage" node, through the "MetadataFileStage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataFileStage" node, through the "MetadataFileStage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataFileStage" node, through the "MetadataFileStage.photo" relation. */
  update?: InputMaybe<MetadataFileStageNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataFileStage" node, through the "MetadataFileStage.photo" relation. */
  upsert?: InputMaybe<MetadataFileStageNestedUpsertPhotoCreateInput>;
};

export type MetadataFileStageNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataFileStage" node, through the "MetadataFileStage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataFileStage" node, through the "MetadataFileStage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataFileStage" node, through the "MetadataFileStage.photo" relation. */
  update?: InputMaybe<MetadataFileStageNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataFileStage" node, through the "MetadataFileStage.photo" relation. */
  upsert?: InputMaybe<MetadataFileStageNestedUpsertPhotoUpdateInput>;
};

export type MetadataFileStageNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileStageNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileStageNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileStageNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileStageNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileStageNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileStageNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileStageNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileStageNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileStageNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileStageNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileStageNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataFileStageOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataFileStageUpdateInput = {
  /** Actions for the "MetadataFileStage.archive" relation */
  archive?: InputMaybe<MetadataFileStageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileStage.metadata" relation */
  metadata?: InputMaybe<MetadataFileStageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileStage.photo" relation */
  photo?: InputMaybe<MetadataFileStageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataFileStage" nodes by specifying some conditions */
export type MetadataFileStageWhereInput = {
  AND?: InputMaybe<Array<MetadataFileStageWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataFileStageWhereInput>;
  OR?: InputMaybe<Array<MetadataFileStageWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataFileStage" node. */
export type MetadataFileStageWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataFileStageWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataFileStage.metadata" relation */
  metadata: MetadataFileStageNestedMetadataCreateInput;
  /** Actions for the "MetadataFileStage.photo" relation */
  photo: MetadataFileStageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStageWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataFileStage.metadata" relation */
  metadata?: InputMaybe<MetadataFileStageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileStage.photo" relation */
  photo?: InputMaybe<MetadataFileStageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStageWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataFileStage.archive" relation */
  archive: MetadataFileStageNestedArchiveCreateInput;
  /** Actions for the "MetadataFileStage.photo" relation */
  photo: MetadataFileStageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStageWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataFileStage.archive" relation */
  archive?: InputMaybe<MetadataFileStageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileStage.photo" relation */
  photo?: InputMaybe<MetadataFileStageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStageWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataFileStage.archive" relation */
  archive: MetadataFileStageNestedArchiveCreateInput;
  /** Actions for the "MetadataFileStage.metadata" relation */
  metadata: MetadataFileStageNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStageWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataFileStage.archive" relation */
  archive?: InputMaybe<MetadataFileStageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileStage.metadata" relation */
  metadata?: InputMaybe<MetadataFileStageNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataFileStatus" resource's node */
export type MetadataFileStatus = {
  __typename?: 'MetadataFileStatus';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataFileStatusCreateInput = {
  /** Actions for the "MetadataFileStatus.archive" relation */
  archive: MetadataFileStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataFileStatus.metadata" relation */
  metadata: MetadataFileStatusNestedMetadataCreateInput;
  /** Actions for the "MetadataFileStatus.photo" relation */
  photo: MetadataFileStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStatusNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataFileStatus" node, through the "MetadataFileStatus.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataFileStatus" node, through the "MetadataFileStatus.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataFileStatus" node, through the "MetadataFileStatus.archive" relation. */
  update?: InputMaybe<MetadataFileStatusNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataFileStatus" node, through the "MetadataFileStatus.archive" relation. */
  upsert?: InputMaybe<MetadataFileStatusNestedUpsertArchiveCreateInput>;
};

export type MetadataFileStatusNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.archive" relation. */
  update?: InputMaybe<MetadataFileStatusNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.archive" relation. */
  upsert?: InputMaybe<MetadataFileStatusNestedUpsertArchiveUpdateInput>;
};

export type MetadataFileStatusNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataFileStatus" node, through the "MetadataFileStatus.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataFileStatus" node, through the "MetadataFileStatus.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataFileStatus" node, through the "MetadataFileStatus.metadata" relation. */
  update?: InputMaybe<MetadataFileStatusNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataFileStatus" node, through the "MetadataFileStatus.metadata" relation. */
  upsert?: InputMaybe<MetadataFileStatusNestedUpsertMetadataCreateInput>;
};

export type MetadataFileStatusNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.metadata" relation. */
  update?: InputMaybe<MetadataFileStatusNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.metadata" relation. */
  upsert?: InputMaybe<MetadataFileStatusNestedUpsertMetadataUpdateInput>;
};

export type MetadataFileStatusNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataFileStatus" node, through the "MetadataFileStatus.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataFileStatus" node, through the "MetadataFileStatus.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataFileStatus" node, through the "MetadataFileStatus.photo" relation. */
  update?: InputMaybe<MetadataFileStatusNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataFileStatus" node, through the "MetadataFileStatus.photo" relation. */
  upsert?: InputMaybe<MetadataFileStatusNestedUpsertPhotoCreateInput>;
};

export type MetadataFileStatusNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.photo" relation. */
  update?: InputMaybe<MetadataFileStatusNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataFileStatus" node, through the "MetadataFileStatus.photo" relation. */
  upsert?: InputMaybe<MetadataFileStatusNestedUpsertPhotoUpdateInput>;
};

export type MetadataFileStatusNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileStatusNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileStatusNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileStatusNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileStatusNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileStatusNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileStatusNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileStatusNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileStatusNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileStatusNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileStatusNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileStatusNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataFileStatusOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataFileStatusUpdateInput = {
  /** Actions for the "MetadataFileStatus.archive" relation */
  archive?: InputMaybe<MetadataFileStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileStatus.metadata" relation */
  metadata?: InputMaybe<MetadataFileStatusNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileStatus.photo" relation */
  photo?: InputMaybe<MetadataFileStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataFileStatus" nodes by specifying some conditions */
export type MetadataFileStatusWhereInput = {
  AND?: InputMaybe<Array<MetadataFileStatusWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataFileStatusWhereInput>;
  OR?: InputMaybe<Array<MetadataFileStatusWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataFileStatus" node. */
export type MetadataFileStatusWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataFileStatusWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataFileStatus.metadata" relation */
  metadata: MetadataFileStatusNestedMetadataCreateInput;
  /** Actions for the "MetadataFileStatus.photo" relation */
  photo: MetadataFileStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStatusWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataFileStatus.metadata" relation */
  metadata?: InputMaybe<MetadataFileStatusNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileStatus.photo" relation */
  photo?: InputMaybe<MetadataFileStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStatusWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataFileStatus.archive" relation */
  archive: MetadataFileStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataFileStatus.photo" relation */
  photo: MetadataFileStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStatusWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataFileStatus.archive" relation */
  archive?: InputMaybe<MetadataFileStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileStatus.photo" relation */
  photo?: InputMaybe<MetadataFileStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStatusWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataFileStatus.archive" relation */
  archive: MetadataFileStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataFileStatus.metadata" relation */
  metadata: MetadataFileStatusNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileStatusWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataFileStatus.archive" relation */
  archive?: InputMaybe<MetadataFileStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileStatus.metadata" relation */
  metadata?: InputMaybe<MetadataFileStatusNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataFileType" resource's node */
export type MetadataFileType = {
  __typename?: 'MetadataFileType';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataFileTypeCreateInput = {
  /** Actions for the "MetadataFileType.archive" relation */
  archive: MetadataFileTypeNestedArchiveCreateInput;
  /** Actions for the "MetadataFileType.metadata" relation */
  metadata: MetadataFileTypeNestedMetadataCreateInput;
  /** Actions for the "MetadataFileType.photo" relation */
  photo: MetadataFileTypeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileTypeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataFileType" node, through the "MetadataFileType.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataFileType" node, through the "MetadataFileType.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataFileType" node, through the "MetadataFileType.archive" relation. */
  update?: InputMaybe<MetadataFileTypeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataFileType" node, through the "MetadataFileType.archive" relation. */
  upsert?: InputMaybe<MetadataFileTypeNestedUpsertArchiveCreateInput>;
};

export type MetadataFileTypeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataFileType" node, through the "MetadataFileType.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataFileType" node, through the "MetadataFileType.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataFileType" node, through the "MetadataFileType.archive" relation. */
  update?: InputMaybe<MetadataFileTypeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataFileType" node, through the "MetadataFileType.archive" relation. */
  upsert?: InputMaybe<MetadataFileTypeNestedUpsertArchiveUpdateInput>;
};

export type MetadataFileTypeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataFileType" node, through the "MetadataFileType.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataFileType" node, through the "MetadataFileType.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataFileType" node, through the "MetadataFileType.metadata" relation. */
  update?: InputMaybe<MetadataFileTypeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataFileType" node, through the "MetadataFileType.metadata" relation. */
  upsert?: InputMaybe<MetadataFileTypeNestedUpsertMetadataCreateInput>;
};

export type MetadataFileTypeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataFileType" node, through the "MetadataFileType.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataFileType" node, through the "MetadataFileType.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataFileType" node, through the "MetadataFileType.metadata" relation. */
  update?: InputMaybe<MetadataFileTypeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataFileType" node, through the "MetadataFileType.metadata" relation. */
  upsert?: InputMaybe<MetadataFileTypeNestedUpsertMetadataUpdateInput>;
};

export type MetadataFileTypeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataFileType" node, through the "MetadataFileType.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataFileType" node, through the "MetadataFileType.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataFileType" node, through the "MetadataFileType.photo" relation. */
  update?: InputMaybe<MetadataFileTypeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataFileType" node, through the "MetadataFileType.photo" relation. */
  upsert?: InputMaybe<MetadataFileTypeNestedUpsertPhotoCreateInput>;
};

export type MetadataFileTypeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataFileType" node, through the "MetadataFileType.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataFileType" node, through the "MetadataFileType.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataFileType" node, through the "MetadataFileType.photo" relation. */
  update?: InputMaybe<MetadataFileTypeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataFileType" node, through the "MetadataFileType.photo" relation. */
  upsert?: InputMaybe<MetadataFileTypeNestedUpsertPhotoUpdateInput>;
};

export type MetadataFileTypeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileTypeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileTypeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileTypeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileTypeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileTypeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileTypeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileTypeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFileTypeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileTypeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFileTypeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFileTypeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataFileTypeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataFileTypeUpdateInput = {
  /** Actions for the "MetadataFileType.archive" relation */
  archive?: InputMaybe<MetadataFileTypeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileType.metadata" relation */
  metadata?: InputMaybe<MetadataFileTypeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileType.photo" relation */
  photo?: InputMaybe<MetadataFileTypeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataFileType" nodes by specifying some conditions */
export type MetadataFileTypeWhereInput = {
  AND?: InputMaybe<Array<MetadataFileTypeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataFileTypeWhereInput>;
  OR?: InputMaybe<Array<MetadataFileTypeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataFileType" node. */
export type MetadataFileTypeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataFileTypeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataFileType.metadata" relation */
  metadata: MetadataFileTypeNestedMetadataCreateInput;
  /** Actions for the "MetadataFileType.photo" relation */
  photo: MetadataFileTypeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileTypeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataFileType.metadata" relation */
  metadata?: InputMaybe<MetadataFileTypeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFileType.photo" relation */
  photo?: InputMaybe<MetadataFileTypeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileTypeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataFileType.archive" relation */
  archive: MetadataFileTypeNestedArchiveCreateInput;
  /** Actions for the "MetadataFileType.photo" relation */
  photo: MetadataFileTypeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileTypeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataFileType.archive" relation */
  archive?: InputMaybe<MetadataFileTypeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileType.photo" relation */
  photo?: InputMaybe<MetadataFileTypeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileTypeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataFileType.archive" relation */
  archive: MetadataFileTypeNestedArchiveCreateInput;
  /** Actions for the "MetadataFileType.metadata" relation */
  metadata: MetadataFileTypeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFileTypeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataFileType.archive" relation */
  archive?: InputMaybe<MetadataFileTypeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFileType.metadata" relation */
  metadata?: InputMaybe<MetadataFileTypeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataFixtureIdentifier" resource's node */
export type MetadataFixtureIdentifier = {
  __typename?: 'MetadataFixtureIdentifier';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataFixtureIdentifierCreateInput = {
  /** Actions for the "MetadataFixtureIdentifier.archive" relation */
  archive: MetadataFixtureIdentifierNestedArchiveCreateInput;
  /** Actions for the "MetadataFixtureIdentifier.metadata" relation */
  metadata: MetadataFixtureIdentifierNestedMetadataCreateInput;
  /** Actions for the "MetadataFixtureIdentifier.photo" relation */
  photo: MetadataFixtureIdentifierNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFixtureIdentifierNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.archive" relation. */
  update?: InputMaybe<MetadataFixtureIdentifierNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.archive" relation. */
  upsert?: InputMaybe<MetadataFixtureIdentifierNestedUpsertArchiveCreateInput>;
};

export type MetadataFixtureIdentifierNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.archive" relation. */
  update?: InputMaybe<MetadataFixtureIdentifierNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.archive" relation. */
  upsert?: InputMaybe<MetadataFixtureIdentifierNestedUpsertArchiveUpdateInput>;
};

export type MetadataFixtureIdentifierNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.metadata" relation. */
  update?: InputMaybe<MetadataFixtureIdentifierNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.metadata" relation. */
  upsert?: InputMaybe<MetadataFixtureIdentifierNestedUpsertMetadataCreateInput>;
};

export type MetadataFixtureIdentifierNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.metadata" relation. */
  update?: InputMaybe<MetadataFixtureIdentifierNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.metadata" relation. */
  upsert?: InputMaybe<MetadataFixtureIdentifierNestedUpsertMetadataUpdateInput>;
};

export type MetadataFixtureIdentifierNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.photo" relation. */
  update?: InputMaybe<MetadataFixtureIdentifierNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.photo" relation. */
  upsert?: InputMaybe<MetadataFixtureIdentifierNestedUpsertPhotoCreateInput>;
};

export type MetadataFixtureIdentifierNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.photo" relation. */
  update?: InputMaybe<MetadataFixtureIdentifierNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataFixtureIdentifier" node, through the "MetadataFixtureIdentifier.photo" relation. */
  upsert?: InputMaybe<MetadataFixtureIdentifierNestedUpsertPhotoUpdateInput>;
};

export type MetadataFixtureIdentifierNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFixtureIdentifierNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFixtureIdentifierNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFixtureIdentifierNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFixtureIdentifierNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFixtureIdentifierNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFixtureIdentifierNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFixtureIdentifierNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFixtureIdentifierNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFixtureIdentifierNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFixtureIdentifierNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFixtureIdentifierNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataFixtureIdentifierOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataFixtureIdentifierUpdateInput = {
  /** Actions for the "MetadataFixtureIdentifier.archive" relation */
  archive?: InputMaybe<MetadataFixtureIdentifierNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFixtureIdentifier.metadata" relation */
  metadata?: InputMaybe<MetadataFixtureIdentifierNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFixtureIdentifier.photo" relation */
  photo?: InputMaybe<MetadataFixtureIdentifierNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataFixtureIdentifier" nodes by specifying some conditions */
export type MetadataFixtureIdentifierWhereInput = {
  AND?: InputMaybe<Array<MetadataFixtureIdentifierWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
  OR?: InputMaybe<Array<MetadataFixtureIdentifierWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataFixtureIdentifier" node. */
export type MetadataFixtureIdentifierWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataFixtureIdentifierWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataFixtureIdentifier.metadata" relation */
  metadata: MetadataFixtureIdentifierNestedMetadataCreateInput;
  /** Actions for the "MetadataFixtureIdentifier.photo" relation */
  photo: MetadataFixtureIdentifierNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFixtureIdentifierWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataFixtureIdentifier.metadata" relation */
  metadata?: InputMaybe<MetadataFixtureIdentifierNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFixtureIdentifier.photo" relation */
  photo?: InputMaybe<MetadataFixtureIdentifierNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFixtureIdentifierWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataFixtureIdentifier.archive" relation */
  archive: MetadataFixtureIdentifierNestedArchiveCreateInput;
  /** Actions for the "MetadataFixtureIdentifier.photo" relation */
  photo: MetadataFixtureIdentifierNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFixtureIdentifierWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataFixtureIdentifier.archive" relation */
  archive?: InputMaybe<MetadataFixtureIdentifierNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFixtureIdentifier.photo" relation */
  photo?: InputMaybe<MetadataFixtureIdentifierNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFixtureIdentifierWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataFixtureIdentifier.archive" relation */
  archive: MetadataFixtureIdentifierNestedArchiveCreateInput;
  /** Actions for the "MetadataFixtureIdentifier.metadata" relation */
  metadata: MetadataFixtureIdentifierNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFixtureIdentifierWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataFixtureIdentifier.archive" relation */
  archive?: InputMaybe<MetadataFixtureIdentifierNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFixtureIdentifier.metadata" relation */
  metadata?: InputMaybe<MetadataFixtureIdentifierNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataFormat" resource's node */
export type MetadataFormat = {
  __typename?: 'MetadataFormat';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataFormatCreateInput = {
  /** Actions for the "MetadataFormat.archive" relation */
  archive: MetadataFormatNestedArchiveCreateInput;
  /** Actions for the "MetadataFormat.metadata" relation */
  metadata: MetadataFormatNestedMetadataCreateInput;
  /** Actions for the "MetadataFormat.photo" relation */
  photo: MetadataFormatNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFormatNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataFormat" node, through the "MetadataFormat.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataFormat" node, through the "MetadataFormat.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataFormat" node, through the "MetadataFormat.archive" relation. */
  update?: InputMaybe<MetadataFormatNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataFormat" node, through the "MetadataFormat.archive" relation. */
  upsert?: InputMaybe<MetadataFormatNestedUpsertArchiveCreateInput>;
};

export type MetadataFormatNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataFormat" node, through the "MetadataFormat.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataFormat" node, through the "MetadataFormat.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataFormat" node, through the "MetadataFormat.archive" relation. */
  update?: InputMaybe<MetadataFormatNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataFormat" node, through the "MetadataFormat.archive" relation. */
  upsert?: InputMaybe<MetadataFormatNestedUpsertArchiveUpdateInput>;
};

export type MetadataFormatNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataFormat" node, through the "MetadataFormat.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataFormat" node, through the "MetadataFormat.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataFormat" node, through the "MetadataFormat.metadata" relation. */
  update?: InputMaybe<MetadataFormatNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataFormat" node, through the "MetadataFormat.metadata" relation. */
  upsert?: InputMaybe<MetadataFormatNestedUpsertMetadataCreateInput>;
};

export type MetadataFormatNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataFormat" node, through the "MetadataFormat.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataFormat" node, through the "MetadataFormat.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataFormat" node, through the "MetadataFormat.metadata" relation. */
  update?: InputMaybe<MetadataFormatNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataFormat" node, through the "MetadataFormat.metadata" relation. */
  upsert?: InputMaybe<MetadataFormatNestedUpsertMetadataUpdateInput>;
};

export type MetadataFormatNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataFormat" node, through the "MetadataFormat.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataFormat" node, through the "MetadataFormat.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataFormat" node, through the "MetadataFormat.photo" relation. */
  update?: InputMaybe<MetadataFormatNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataFormat" node, through the "MetadataFormat.photo" relation. */
  upsert?: InputMaybe<MetadataFormatNestedUpsertPhotoCreateInput>;
};

export type MetadataFormatNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataFormat" node, through the "MetadataFormat.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataFormat" node, through the "MetadataFormat.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataFormat" node, through the "MetadataFormat.photo" relation. */
  update?: InputMaybe<MetadataFormatNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataFormat" node, through the "MetadataFormat.photo" relation. */
  upsert?: InputMaybe<MetadataFormatNestedUpsertPhotoUpdateInput>;
};

export type MetadataFormatNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFormatNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFormatNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFormatNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFormatNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFormatNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFormatNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFormatNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataFormatNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFormatNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataFormatNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataFormatNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataFormatOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataFormatUpdateInput = {
  /** Actions for the "MetadataFormat.archive" relation */
  archive?: InputMaybe<MetadataFormatNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFormat.metadata" relation */
  metadata?: InputMaybe<MetadataFormatNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFormat.photo" relation */
  photo?: InputMaybe<MetadataFormatNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataFormat" nodes by specifying some conditions */
export type MetadataFormatWhereInput = {
  AND?: InputMaybe<Array<MetadataFormatWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataFormatWhereInput>;
  OR?: InputMaybe<Array<MetadataFormatWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataFormat" node. */
export type MetadataFormatWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataFormatWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataFormat.metadata" relation */
  metadata: MetadataFormatNestedMetadataCreateInput;
  /** Actions for the "MetadataFormat.photo" relation */
  photo: MetadataFormatNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFormatWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataFormat.metadata" relation */
  metadata?: InputMaybe<MetadataFormatNestedMetadataUpdateInput>;
  /** Actions for the "MetadataFormat.photo" relation */
  photo?: InputMaybe<MetadataFormatNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFormatWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataFormat.archive" relation */
  archive: MetadataFormatNestedArchiveCreateInput;
  /** Actions for the "MetadataFormat.photo" relation */
  photo: MetadataFormatNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFormatWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataFormat.archive" relation */
  archive?: InputMaybe<MetadataFormatNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFormat.photo" relation */
  photo?: InputMaybe<MetadataFormatNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFormatWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataFormat.archive" relation */
  archive: MetadataFormatNestedArchiveCreateInput;
  /** Actions for the "MetadataFormat.metadata" relation */
  metadata: MetadataFormatNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataFormatWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataFormat.archive" relation */
  archive?: InputMaybe<MetadataFormatNestedArchiveUpdateInput>;
  /** Actions for the "MetadataFormat.metadata" relation */
  metadata?: InputMaybe<MetadataFormatNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataGpsLatitude" resource's node */
export type MetadataGpsLatitude = {
  __typename?: 'MetadataGpsLatitude';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataGpsLatitudeCreateInput = {
  /** Actions for the "MetadataGpsLatitude.archive" relation */
  archive: MetadataGpsLatitudeNestedArchiveCreateInput;
  /** Actions for the "MetadataGpsLatitude.metadata" relation */
  metadata: MetadataGpsLatitudeNestedMetadataCreateInput;
  /** Actions for the "MetadataGpsLatitude.photo" relation */
  photo: MetadataGpsLatitudeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLatitudeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.archive" relation. */
  update?: InputMaybe<MetadataGpsLatitudeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.archive" relation. */
  upsert?: InputMaybe<MetadataGpsLatitudeNestedUpsertArchiveCreateInput>;
};

export type MetadataGpsLatitudeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.archive" relation. */
  update?: InputMaybe<MetadataGpsLatitudeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.archive" relation. */
  upsert?: InputMaybe<MetadataGpsLatitudeNestedUpsertArchiveUpdateInput>;
};

export type MetadataGpsLatitudeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.metadata" relation. */
  update?: InputMaybe<MetadataGpsLatitudeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.metadata" relation. */
  upsert?: InputMaybe<MetadataGpsLatitudeNestedUpsertMetadataCreateInput>;
};

export type MetadataGpsLatitudeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.metadata" relation. */
  update?: InputMaybe<MetadataGpsLatitudeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.metadata" relation. */
  upsert?: InputMaybe<MetadataGpsLatitudeNestedUpsertMetadataUpdateInput>;
};

export type MetadataGpsLatitudeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.photo" relation. */
  update?: InputMaybe<MetadataGpsLatitudeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.photo" relation. */
  upsert?: InputMaybe<MetadataGpsLatitudeNestedUpsertPhotoCreateInput>;
};

export type MetadataGpsLatitudeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.photo" relation. */
  update?: InputMaybe<MetadataGpsLatitudeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataGpsLatitude" node, through the "MetadataGpsLatitude.photo" relation. */
  upsert?: InputMaybe<MetadataGpsLatitudeNestedUpsertPhotoUpdateInput>;
};

export type MetadataGpsLatitudeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsLatitudeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsLatitudeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsLatitudeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsLatitudeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataGpsLatitudeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataGpsLatitudeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsLatitudeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsLatitudeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsLatitudeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsLatitudeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataGpsLatitudeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataGpsLatitudeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataGpsLatitudeUpdateInput = {
  /** Actions for the "MetadataGpsLatitude.archive" relation */
  archive?: InputMaybe<MetadataGpsLatitudeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataGpsLatitude.metadata" relation */
  metadata?: InputMaybe<MetadataGpsLatitudeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataGpsLatitude.photo" relation */
  photo?: InputMaybe<MetadataGpsLatitudeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataGpsLatitude" nodes by specifying some conditions */
export type MetadataGpsLatitudeWhereInput = {
  AND?: InputMaybe<Array<MetadataGpsLatitudeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataGpsLatitudeWhereInput>;
  OR?: InputMaybe<Array<MetadataGpsLatitudeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataGpsLatitude" node. */
export type MetadataGpsLatitudeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataGpsLatitudeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataGpsLatitude.metadata" relation */
  metadata: MetadataGpsLatitudeNestedMetadataCreateInput;
  /** Actions for the "MetadataGpsLatitude.photo" relation */
  photo: MetadataGpsLatitudeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLatitudeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataGpsLatitude.metadata" relation */
  metadata?: InputMaybe<MetadataGpsLatitudeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataGpsLatitude.photo" relation */
  photo?: InputMaybe<MetadataGpsLatitudeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLatitudeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataGpsLatitude.archive" relation */
  archive: MetadataGpsLatitudeNestedArchiveCreateInput;
  /** Actions for the "MetadataGpsLatitude.photo" relation */
  photo: MetadataGpsLatitudeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLatitudeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataGpsLatitude.archive" relation */
  archive?: InputMaybe<MetadataGpsLatitudeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataGpsLatitude.photo" relation */
  photo?: InputMaybe<MetadataGpsLatitudeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLatitudeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataGpsLatitude.archive" relation */
  archive: MetadataGpsLatitudeNestedArchiveCreateInput;
  /** Actions for the "MetadataGpsLatitude.metadata" relation */
  metadata: MetadataGpsLatitudeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLatitudeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataGpsLatitude.archive" relation */
  archive?: InputMaybe<MetadataGpsLatitudeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataGpsLatitude.metadata" relation */
  metadata?: InputMaybe<MetadataGpsLatitudeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataGpsLongitude" resource's node */
export type MetadataGpsLongitude = {
  __typename?: 'MetadataGpsLongitude';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataGpsLongitudeCreateInput = {
  /** Actions for the "MetadataGpsLongitude.archive" relation */
  archive: MetadataGpsLongitudeNestedArchiveCreateInput;
  /** Actions for the "MetadataGpsLongitude.metadata" relation */
  metadata: MetadataGpsLongitudeNestedMetadataCreateInput;
  /** Actions for the "MetadataGpsLongitude.photo" relation */
  photo: MetadataGpsLongitudeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLongitudeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.archive" relation. */
  update?: InputMaybe<MetadataGpsLongitudeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.archive" relation. */
  upsert?: InputMaybe<MetadataGpsLongitudeNestedUpsertArchiveCreateInput>;
};

export type MetadataGpsLongitudeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.archive" relation. */
  update?: InputMaybe<MetadataGpsLongitudeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.archive" relation. */
  upsert?: InputMaybe<MetadataGpsLongitudeNestedUpsertArchiveUpdateInput>;
};

export type MetadataGpsLongitudeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.metadata" relation. */
  update?: InputMaybe<MetadataGpsLongitudeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.metadata" relation. */
  upsert?: InputMaybe<MetadataGpsLongitudeNestedUpsertMetadataCreateInput>;
};

export type MetadataGpsLongitudeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.metadata" relation. */
  update?: InputMaybe<MetadataGpsLongitudeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.metadata" relation. */
  upsert?: InputMaybe<MetadataGpsLongitudeNestedUpsertMetadataUpdateInput>;
};

export type MetadataGpsLongitudeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.photo" relation. */
  update?: InputMaybe<MetadataGpsLongitudeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.photo" relation. */
  upsert?: InputMaybe<MetadataGpsLongitudeNestedUpsertPhotoCreateInput>;
};

export type MetadataGpsLongitudeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.photo" relation. */
  update?: InputMaybe<MetadataGpsLongitudeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataGpsLongitude" node, through the "MetadataGpsLongitude.photo" relation. */
  upsert?: InputMaybe<MetadataGpsLongitudeNestedUpsertPhotoUpdateInput>;
};

export type MetadataGpsLongitudeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsLongitudeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsLongitudeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsLongitudeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsLongitudeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataGpsLongitudeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataGpsLongitudeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsLongitudeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsLongitudeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsLongitudeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsLongitudeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataGpsLongitudeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataGpsLongitudeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataGpsLongitudeUpdateInput = {
  /** Actions for the "MetadataGpsLongitude.archive" relation */
  archive?: InputMaybe<MetadataGpsLongitudeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataGpsLongitude.metadata" relation */
  metadata?: InputMaybe<MetadataGpsLongitudeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataGpsLongitude.photo" relation */
  photo?: InputMaybe<MetadataGpsLongitudeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataGpsLongitude" nodes by specifying some conditions */
export type MetadataGpsLongitudeWhereInput = {
  AND?: InputMaybe<Array<MetadataGpsLongitudeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataGpsLongitudeWhereInput>;
  OR?: InputMaybe<Array<MetadataGpsLongitudeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataGpsLongitude" node. */
export type MetadataGpsLongitudeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataGpsLongitudeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataGpsLongitude.metadata" relation */
  metadata: MetadataGpsLongitudeNestedMetadataCreateInput;
  /** Actions for the "MetadataGpsLongitude.photo" relation */
  photo: MetadataGpsLongitudeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLongitudeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataGpsLongitude.metadata" relation */
  metadata?: InputMaybe<MetadataGpsLongitudeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataGpsLongitude.photo" relation */
  photo?: InputMaybe<MetadataGpsLongitudeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLongitudeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataGpsLongitude.archive" relation */
  archive: MetadataGpsLongitudeNestedArchiveCreateInput;
  /** Actions for the "MetadataGpsLongitude.photo" relation */
  photo: MetadataGpsLongitudeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLongitudeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataGpsLongitude.archive" relation */
  archive?: InputMaybe<MetadataGpsLongitudeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataGpsLongitude.photo" relation */
  photo?: InputMaybe<MetadataGpsLongitudeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLongitudeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataGpsLongitude.archive" relation */
  archive: MetadataGpsLongitudeNestedArchiveCreateInput;
  /** Actions for the "MetadataGpsLongitude.metadata" relation */
  metadata: MetadataGpsLongitudeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsLongitudeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataGpsLongitude.archive" relation */
  archive?: InputMaybe<MetadataGpsLongitudeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataGpsLongitude.metadata" relation */
  metadata?: InputMaybe<MetadataGpsLongitudeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataGpsProcessingMethod" resource's node */
export type MetadataGpsProcessingMethod = {
  __typename?: 'MetadataGpsProcessingMethod';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataGpsProcessingMethodCreateInput = {
  /** Actions for the "MetadataGpsProcessingMethod.archive" relation */
  archive: MetadataGpsProcessingMethodNestedArchiveCreateInput;
  /** Actions for the "MetadataGpsProcessingMethod.metadata" relation */
  metadata: MetadataGpsProcessingMethodNestedMetadataCreateInput;
  /** Actions for the "MetadataGpsProcessingMethod.photo" relation */
  photo: MetadataGpsProcessingMethodNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsProcessingMethodNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.archive" relation. */
  update?: InputMaybe<MetadataGpsProcessingMethodNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.archive" relation. */
  upsert?: InputMaybe<MetadataGpsProcessingMethodNestedUpsertArchiveCreateInput>;
};

export type MetadataGpsProcessingMethodNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.archive" relation. */
  update?: InputMaybe<MetadataGpsProcessingMethodNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.archive" relation. */
  upsert?: InputMaybe<MetadataGpsProcessingMethodNestedUpsertArchiveUpdateInput>;
};

export type MetadataGpsProcessingMethodNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.metadata" relation. */
  update?: InputMaybe<MetadataGpsProcessingMethodNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.metadata" relation. */
  upsert?: InputMaybe<MetadataGpsProcessingMethodNestedUpsertMetadataCreateInput>;
};

export type MetadataGpsProcessingMethodNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.metadata" relation. */
  update?: InputMaybe<MetadataGpsProcessingMethodNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.metadata" relation. */
  upsert?: InputMaybe<MetadataGpsProcessingMethodNestedUpsertMetadataUpdateInput>;
};

export type MetadataGpsProcessingMethodNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.photo" relation. */
  update?: InputMaybe<MetadataGpsProcessingMethodNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.photo" relation. */
  upsert?: InputMaybe<MetadataGpsProcessingMethodNestedUpsertPhotoCreateInput>;
};

export type MetadataGpsProcessingMethodNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.photo" relation. */
  update?: InputMaybe<MetadataGpsProcessingMethodNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataGpsProcessingMethod" node, through the "MetadataGpsProcessingMethod.photo" relation. */
  upsert?: InputMaybe<MetadataGpsProcessingMethodNestedUpsertPhotoUpdateInput>;
};

export type MetadataGpsProcessingMethodNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsProcessingMethodNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsProcessingMethodNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsProcessingMethodNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsProcessingMethodNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataGpsProcessingMethodNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataGpsProcessingMethodNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsProcessingMethodNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataGpsProcessingMethodNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsProcessingMethodNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataGpsProcessingMethodNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataGpsProcessingMethodNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataGpsProcessingMethodOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataGpsProcessingMethodUpdateInput = {
  /** Actions for the "MetadataGpsProcessingMethod.archive" relation */
  archive?: InputMaybe<MetadataGpsProcessingMethodNestedArchiveUpdateInput>;
  /** Actions for the "MetadataGpsProcessingMethod.metadata" relation */
  metadata?: InputMaybe<MetadataGpsProcessingMethodNestedMetadataUpdateInput>;
  /** Actions for the "MetadataGpsProcessingMethod.photo" relation */
  photo?: InputMaybe<MetadataGpsProcessingMethodNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataGpsProcessingMethod" nodes by specifying some conditions */
export type MetadataGpsProcessingMethodWhereInput = {
  AND?: InputMaybe<Array<MetadataGpsProcessingMethodWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
  OR?: InputMaybe<Array<MetadataGpsProcessingMethodWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataGpsProcessingMethod" node. */
export type MetadataGpsProcessingMethodWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataGpsProcessingMethodWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataGpsProcessingMethod.metadata" relation */
  metadata: MetadataGpsProcessingMethodNestedMetadataCreateInput;
  /** Actions for the "MetadataGpsProcessingMethod.photo" relation */
  photo: MetadataGpsProcessingMethodNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsProcessingMethodWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataGpsProcessingMethod.metadata" relation */
  metadata?: InputMaybe<MetadataGpsProcessingMethodNestedMetadataUpdateInput>;
  /** Actions for the "MetadataGpsProcessingMethod.photo" relation */
  photo?: InputMaybe<MetadataGpsProcessingMethodNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsProcessingMethodWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataGpsProcessingMethod.archive" relation */
  archive: MetadataGpsProcessingMethodNestedArchiveCreateInput;
  /** Actions for the "MetadataGpsProcessingMethod.photo" relation */
  photo: MetadataGpsProcessingMethodNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsProcessingMethodWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataGpsProcessingMethod.archive" relation */
  archive?: InputMaybe<MetadataGpsProcessingMethodNestedArchiveUpdateInput>;
  /** Actions for the "MetadataGpsProcessingMethod.photo" relation */
  photo?: InputMaybe<MetadataGpsProcessingMethodNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsProcessingMethodWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataGpsProcessingMethod.archive" relation */
  archive: MetadataGpsProcessingMethodNestedArchiveCreateInput;
  /** Actions for the "MetadataGpsProcessingMethod.metadata" relation */
  metadata: MetadataGpsProcessingMethodNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataGpsProcessingMethodWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataGpsProcessingMethod.archive" relation */
  archive?: InputMaybe<MetadataGpsProcessingMethodNestedArchiveUpdateInput>;
  /** Actions for the "MetadataGpsProcessingMethod.metadata" relation */
  metadata?: InputMaybe<MetadataGpsProcessingMethodNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataHeader" resource's node */
export type MetadataHeader = {
  __typename?: 'MetadataHeader';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataHeaderCreateInput = {
  /** Actions for the "MetadataHeader.archive" relation */
  archive: MetadataHeaderNestedArchiveCreateInput;
  /** Actions for the "MetadataHeader.metadata" relation */
  metadata: MetadataHeaderNestedMetadataCreateInput;
  /** Actions for the "MetadataHeader.photo" relation */
  photo: MetadataHeaderNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataHeader" node, through the "MetadataHeader.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataHeader" node, through the "MetadataHeader.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataHeader" node, through the "MetadataHeader.archive" relation. */
  update?: InputMaybe<MetadataHeaderNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataHeader" node, through the "MetadataHeader.archive" relation. */
  upsert?: InputMaybe<MetadataHeaderNestedUpsertArchiveCreateInput>;
};

export type MetadataHeaderNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataHeader" node, through the "MetadataHeader.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataHeader" node, through the "MetadataHeader.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataHeader" node, through the "MetadataHeader.archive" relation. */
  update?: InputMaybe<MetadataHeaderNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataHeader" node, through the "MetadataHeader.archive" relation. */
  upsert?: InputMaybe<MetadataHeaderNestedUpsertArchiveUpdateInput>;
};

export type MetadataHeaderNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataHeader" node, through the "MetadataHeader.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataHeader" node, through the "MetadataHeader.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataHeader" node, through the "MetadataHeader.metadata" relation. */
  update?: InputMaybe<MetadataHeaderNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataHeader" node, through the "MetadataHeader.metadata" relation. */
  upsert?: InputMaybe<MetadataHeaderNestedUpsertMetadataCreateInput>;
};

export type MetadataHeaderNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataHeader" node, through the "MetadataHeader.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataHeader" node, through the "MetadataHeader.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataHeader" node, through the "MetadataHeader.metadata" relation. */
  update?: InputMaybe<MetadataHeaderNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataHeader" node, through the "MetadataHeader.metadata" relation. */
  upsert?: InputMaybe<MetadataHeaderNestedUpsertMetadataUpdateInput>;
};

export type MetadataHeaderNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataHeader" node, through the "MetadataHeader.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataHeader" node, through the "MetadataHeader.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataHeader" node, through the "MetadataHeader.photo" relation. */
  update?: InputMaybe<MetadataHeaderNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataHeader" node, through the "MetadataHeader.photo" relation. */
  upsert?: InputMaybe<MetadataHeaderNestedUpsertPhotoCreateInput>;
};

export type MetadataHeaderNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataHeader" node, through the "MetadataHeader.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataHeader" node, through the "MetadataHeader.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataHeader" node, through the "MetadataHeader.photo" relation. */
  update?: InputMaybe<MetadataHeaderNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataHeader" node, through the "MetadataHeader.photo" relation. */
  upsert?: InputMaybe<MetadataHeaderNestedUpsertPhotoUpdateInput>;
};

export type MetadataHeaderNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeaderNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeaderNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeaderNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeaderNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHeaderNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHeaderNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeaderNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeaderNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeaderNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeaderNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHeaderNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataHeaderOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

/** "MetadataHeaderPrisma" resource's node */
export type MetadataHeaderPrisma = {
  __typename?: 'MetadataHeaderPrisma';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataHeaderPrismaCreateInput = {
  /** Actions for the "MetadataHeaderPrisma.archive" relation */
  archive: MetadataHeaderPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataHeaderPrisma.metadata" relation */
  metadata: MetadataHeaderPrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataHeaderPrisma.photo" relation */
  photo: MetadataHeaderPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderPrismaNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.archive" relation. */
  update?: InputMaybe<MetadataHeaderPrismaNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.archive" relation. */
  upsert?: InputMaybe<MetadataHeaderPrismaNestedUpsertArchiveCreateInput>;
};

export type MetadataHeaderPrismaNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.archive" relation. */
  update?: InputMaybe<MetadataHeaderPrismaNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.archive" relation. */
  upsert?: InputMaybe<MetadataHeaderPrismaNestedUpsertArchiveUpdateInput>;
};

export type MetadataHeaderPrismaNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.metadata" relation. */
  update?: InputMaybe<MetadataHeaderPrismaNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataHeaderPrismaNestedUpsertMetadataCreateInput>;
};

export type MetadataHeaderPrismaNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.metadata" relation. */
  update?: InputMaybe<MetadataHeaderPrismaNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataHeaderPrismaNestedUpsertMetadataUpdateInput>;
};

export type MetadataHeaderPrismaNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.photo" relation. */
  update?: InputMaybe<MetadataHeaderPrismaNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.photo" relation. */
  upsert?: InputMaybe<MetadataHeaderPrismaNestedUpsertPhotoCreateInput>;
};

export type MetadataHeaderPrismaNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.photo" relation. */
  update?: InputMaybe<MetadataHeaderPrismaNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataHeaderPrisma" node, through the "MetadataHeaderPrisma.photo" relation. */
  upsert?: InputMaybe<MetadataHeaderPrismaNestedUpsertPhotoUpdateInput>;
};

export type MetadataHeaderPrismaNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeaderPrismaNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeaderPrismaNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeaderPrismaNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeaderPrismaNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHeaderPrismaNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHeaderPrismaNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeaderPrismaNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeaderPrismaNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeaderPrismaNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeaderPrismaNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHeaderPrismaNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataHeaderPrismaOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataHeaderPrismaUpdateInput = {
  /** Actions for the "MetadataHeaderPrisma.archive" relation */
  archive?: InputMaybe<MetadataHeaderPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHeaderPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataHeaderPrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataHeaderPrisma.photo" relation */
  photo?: InputMaybe<MetadataHeaderPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataHeaderPrisma" nodes by specifying some conditions */
export type MetadataHeaderPrismaWhereInput = {
  AND?: InputMaybe<Array<MetadataHeaderPrismaWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataHeaderPrismaWhereInput>;
  OR?: InputMaybe<Array<MetadataHeaderPrismaWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataHeaderPrisma" node. */
export type MetadataHeaderPrismaWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataHeaderPrismaWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataHeaderPrisma.metadata" relation */
  metadata: MetadataHeaderPrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataHeaderPrisma.photo" relation */
  photo: MetadataHeaderPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderPrismaWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataHeaderPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataHeaderPrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataHeaderPrisma.photo" relation */
  photo?: InputMaybe<MetadataHeaderPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderPrismaWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataHeaderPrisma.archive" relation */
  archive: MetadataHeaderPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataHeaderPrisma.photo" relation */
  photo: MetadataHeaderPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderPrismaWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataHeaderPrisma.archive" relation */
  archive?: InputMaybe<MetadataHeaderPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHeaderPrisma.photo" relation */
  photo?: InputMaybe<MetadataHeaderPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderPrismaWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataHeaderPrisma.archive" relation */
  archive: MetadataHeaderPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataHeaderPrisma.metadata" relation */
  metadata: MetadataHeaderPrismaNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderPrismaWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataHeaderPrisma.archive" relation */
  archive?: InputMaybe<MetadataHeaderPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHeaderPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataHeaderPrismaNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderUpdateInput = {
  /** Actions for the "MetadataHeader.archive" relation */
  archive?: InputMaybe<MetadataHeaderNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHeader.metadata" relation */
  metadata?: InputMaybe<MetadataHeaderNestedMetadataUpdateInput>;
  /** Actions for the "MetadataHeader.photo" relation */
  photo?: InputMaybe<MetadataHeaderNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataHeader" nodes by specifying some conditions */
export type MetadataHeaderWhereInput = {
  AND?: InputMaybe<Array<MetadataHeaderWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataHeaderWhereInput>;
  OR?: InputMaybe<Array<MetadataHeaderWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataHeader" node. */
export type MetadataHeaderWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataHeaderWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataHeader.metadata" relation */
  metadata: MetadataHeaderNestedMetadataCreateInput;
  /** Actions for the "MetadataHeader.photo" relation */
  photo: MetadataHeaderNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataHeader.metadata" relation */
  metadata?: InputMaybe<MetadataHeaderNestedMetadataUpdateInput>;
  /** Actions for the "MetadataHeader.photo" relation */
  photo?: InputMaybe<MetadataHeaderNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataHeader.archive" relation */
  archive: MetadataHeaderNestedArchiveCreateInput;
  /** Actions for the "MetadataHeader.photo" relation */
  photo: MetadataHeaderNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataHeader.archive" relation */
  archive?: InputMaybe<MetadataHeaderNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHeader.photo" relation */
  photo?: InputMaybe<MetadataHeaderNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataHeader.archive" relation */
  archive: MetadataHeaderNestedArchiveCreateInput;
  /** Actions for the "MetadataHeader.metadata" relation */
  metadata: MetadataHeaderNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeaderWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataHeader.archive" relation */
  archive?: InputMaybe<MetadataHeaderNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHeader.metadata" relation */
  metadata?: InputMaybe<MetadataHeaderNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataHeadline" resource's node */
export type MetadataHeadline = {
  __typename?: 'MetadataHeadline';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataHeadlineCreateInput = {
  /** Actions for the "MetadataHeadline.archive" relation */
  archive: MetadataHeadlineNestedArchiveCreateInput;
  /** Actions for the "MetadataHeadline.metadata" relation */
  metadata: MetadataHeadlineNestedMetadataCreateInput;
  /** Actions for the "MetadataHeadline.photo" relation */
  photo: MetadataHeadlineNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeadlineNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataHeadline" node, through the "MetadataHeadline.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataHeadline" node, through the "MetadataHeadline.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataHeadline" node, through the "MetadataHeadline.archive" relation. */
  update?: InputMaybe<MetadataHeadlineNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataHeadline" node, through the "MetadataHeadline.archive" relation. */
  upsert?: InputMaybe<MetadataHeadlineNestedUpsertArchiveCreateInput>;
};

export type MetadataHeadlineNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataHeadline" node, through the "MetadataHeadline.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataHeadline" node, through the "MetadataHeadline.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataHeadline" node, through the "MetadataHeadline.archive" relation. */
  update?: InputMaybe<MetadataHeadlineNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataHeadline" node, through the "MetadataHeadline.archive" relation. */
  upsert?: InputMaybe<MetadataHeadlineNestedUpsertArchiveUpdateInput>;
};

export type MetadataHeadlineNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataHeadline" node, through the "MetadataHeadline.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataHeadline" node, through the "MetadataHeadline.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataHeadline" node, through the "MetadataHeadline.metadata" relation. */
  update?: InputMaybe<MetadataHeadlineNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataHeadline" node, through the "MetadataHeadline.metadata" relation. */
  upsert?: InputMaybe<MetadataHeadlineNestedUpsertMetadataCreateInput>;
};

export type MetadataHeadlineNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataHeadline" node, through the "MetadataHeadline.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataHeadline" node, through the "MetadataHeadline.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataHeadline" node, through the "MetadataHeadline.metadata" relation. */
  update?: InputMaybe<MetadataHeadlineNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataHeadline" node, through the "MetadataHeadline.metadata" relation. */
  upsert?: InputMaybe<MetadataHeadlineNestedUpsertMetadataUpdateInput>;
};

export type MetadataHeadlineNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataHeadline" node, through the "MetadataHeadline.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataHeadline" node, through the "MetadataHeadline.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataHeadline" node, through the "MetadataHeadline.photo" relation. */
  update?: InputMaybe<MetadataHeadlineNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataHeadline" node, through the "MetadataHeadline.photo" relation. */
  upsert?: InputMaybe<MetadataHeadlineNestedUpsertPhotoCreateInput>;
};

export type MetadataHeadlineNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataHeadline" node, through the "MetadataHeadline.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataHeadline" node, through the "MetadataHeadline.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataHeadline" node, through the "MetadataHeadline.photo" relation. */
  update?: InputMaybe<MetadataHeadlineNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataHeadline" node, through the "MetadataHeadline.photo" relation. */
  upsert?: InputMaybe<MetadataHeadlineNestedUpsertPhotoUpdateInput>;
};

export type MetadataHeadlineNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeadlineNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeadlineNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeadlineNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeadlineNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHeadlineNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHeadlineNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeadlineNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHeadlineNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeadlineNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHeadlineNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHeadlineNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataHeadlineOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataHeadlineUpdateInput = {
  /** Actions for the "MetadataHeadline.archive" relation */
  archive?: InputMaybe<MetadataHeadlineNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHeadline.metadata" relation */
  metadata?: InputMaybe<MetadataHeadlineNestedMetadataUpdateInput>;
  /** Actions for the "MetadataHeadline.photo" relation */
  photo?: InputMaybe<MetadataHeadlineNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataHeadline" nodes by specifying some conditions */
export type MetadataHeadlineWhereInput = {
  AND?: InputMaybe<Array<MetadataHeadlineWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataHeadlineWhereInput>;
  OR?: InputMaybe<Array<MetadataHeadlineWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataHeadline" node. */
export type MetadataHeadlineWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataHeadlineWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataHeadline.metadata" relation */
  metadata: MetadataHeadlineNestedMetadataCreateInput;
  /** Actions for the "MetadataHeadline.photo" relation */
  photo: MetadataHeadlineNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeadlineWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataHeadline.metadata" relation */
  metadata?: InputMaybe<MetadataHeadlineNestedMetadataUpdateInput>;
  /** Actions for the "MetadataHeadline.photo" relation */
  photo?: InputMaybe<MetadataHeadlineNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeadlineWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataHeadline.archive" relation */
  archive: MetadataHeadlineNestedArchiveCreateInput;
  /** Actions for the "MetadataHeadline.photo" relation */
  photo: MetadataHeadlineNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeadlineWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataHeadline.archive" relation */
  archive?: InputMaybe<MetadataHeadlineNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHeadline.photo" relation */
  photo?: InputMaybe<MetadataHeadlineNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeadlineWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataHeadline.archive" relation */
  archive: MetadataHeadlineNestedArchiveCreateInput;
  /** Actions for the "MetadataHeadline.metadata" relation */
  metadata: MetadataHeadlineNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHeadlineWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataHeadline.archive" relation */
  archive?: InputMaybe<MetadataHeadlineNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHeadline.metadata" relation */
  metadata?: InputMaybe<MetadataHeadlineNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataHistory" resource's node */
export type MetadataHistory = {
  __typename?: 'MetadataHistory';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataHistoryCreateInput = {
  /** Actions for the "MetadataHistory.archive" relation */
  archive: MetadataHistoryNestedArchiveCreateInput;
  /** Actions for the "MetadataHistory.metadata" relation */
  metadata: MetadataHistoryNestedMetadataCreateInput;
  /** Actions for the "MetadataHistory.photo" relation */
  photo: MetadataHistoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHistoryNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataHistory" node, through the "MetadataHistory.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataHistory" node, through the "MetadataHistory.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataHistory" node, through the "MetadataHistory.archive" relation. */
  update?: InputMaybe<MetadataHistoryNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataHistory" node, through the "MetadataHistory.archive" relation. */
  upsert?: InputMaybe<MetadataHistoryNestedUpsertArchiveCreateInput>;
};

export type MetadataHistoryNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataHistory" node, through the "MetadataHistory.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataHistory" node, through the "MetadataHistory.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataHistory" node, through the "MetadataHistory.archive" relation. */
  update?: InputMaybe<MetadataHistoryNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataHistory" node, through the "MetadataHistory.archive" relation. */
  upsert?: InputMaybe<MetadataHistoryNestedUpsertArchiveUpdateInput>;
};

export type MetadataHistoryNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataHistory" node, through the "MetadataHistory.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataHistory" node, through the "MetadataHistory.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataHistory" node, through the "MetadataHistory.metadata" relation. */
  update?: InputMaybe<MetadataHistoryNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataHistory" node, through the "MetadataHistory.metadata" relation. */
  upsert?: InputMaybe<MetadataHistoryNestedUpsertMetadataCreateInput>;
};

export type MetadataHistoryNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataHistory" node, through the "MetadataHistory.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataHistory" node, through the "MetadataHistory.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataHistory" node, through the "MetadataHistory.metadata" relation. */
  update?: InputMaybe<MetadataHistoryNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataHistory" node, through the "MetadataHistory.metadata" relation. */
  upsert?: InputMaybe<MetadataHistoryNestedUpsertMetadataUpdateInput>;
};

export type MetadataHistoryNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataHistory" node, through the "MetadataHistory.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataHistory" node, through the "MetadataHistory.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataHistory" node, through the "MetadataHistory.photo" relation. */
  update?: InputMaybe<MetadataHistoryNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataHistory" node, through the "MetadataHistory.photo" relation. */
  upsert?: InputMaybe<MetadataHistoryNestedUpsertPhotoCreateInput>;
};

export type MetadataHistoryNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataHistory" node, through the "MetadataHistory.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataHistory" node, through the "MetadataHistory.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataHistory" node, through the "MetadataHistory.photo" relation. */
  update?: InputMaybe<MetadataHistoryNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataHistory" node, through the "MetadataHistory.photo" relation. */
  upsert?: InputMaybe<MetadataHistoryNestedUpsertPhotoUpdateInput>;
};

export type MetadataHistoryNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHistoryNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHistoryNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHistoryNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHistoryNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHistoryNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHistoryNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHistoryNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataHistoryNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHistoryNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataHistoryNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataHistoryNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataHistoryOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataHistoryUpdateInput = {
  /** Actions for the "MetadataHistory.archive" relation */
  archive?: InputMaybe<MetadataHistoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHistory.metadata" relation */
  metadata?: InputMaybe<MetadataHistoryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataHistory.photo" relation */
  photo?: InputMaybe<MetadataHistoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataHistory" nodes by specifying some conditions */
export type MetadataHistoryWhereInput = {
  AND?: InputMaybe<Array<MetadataHistoryWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataHistoryWhereInput>;
  OR?: InputMaybe<Array<MetadataHistoryWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataHistory" node. */
export type MetadataHistoryWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataHistoryWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataHistory.metadata" relation */
  metadata: MetadataHistoryNestedMetadataCreateInput;
  /** Actions for the "MetadataHistory.photo" relation */
  photo: MetadataHistoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHistoryWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataHistory.metadata" relation */
  metadata?: InputMaybe<MetadataHistoryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataHistory.photo" relation */
  photo?: InputMaybe<MetadataHistoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHistoryWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataHistory.archive" relation */
  archive: MetadataHistoryNestedArchiveCreateInput;
  /** Actions for the "MetadataHistory.photo" relation */
  photo: MetadataHistoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHistoryWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataHistory.archive" relation */
  archive?: InputMaybe<MetadataHistoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHistory.photo" relation */
  photo?: InputMaybe<MetadataHistoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHistoryWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataHistory.archive" relation */
  archive: MetadataHistoryNestedArchiveCreateInput;
  /** Actions for the "MetadataHistory.metadata" relation */
  metadata: MetadataHistoryNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataHistoryWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataHistory.archive" relation */
  archive?: InputMaybe<MetadataHistoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataHistory.metadata" relation */
  metadata?: InputMaybe<MetadataHistoryNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataIccProfile" resource's node */
export type MetadataIccProfile = {
  __typename?: 'MetadataIccProfile';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataIccProfileCreateInput = {
  /** Actions for the "MetadataIccProfile.archive" relation */
  archive: MetadataIccProfileNestedArchiveCreateInput;
  /** Actions for the "MetadataIccProfile.metadata" relation */
  metadata: MetadataIccProfileNestedMetadataCreateInput;
  /** Actions for the "MetadataIccProfile.photo" relation */
  photo: MetadataIccProfileNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIccProfileNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataIccProfile" node, through the "MetadataIccProfile.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataIccProfile" node, through the "MetadataIccProfile.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataIccProfile" node, through the "MetadataIccProfile.archive" relation. */
  update?: InputMaybe<MetadataIccProfileNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataIccProfile" node, through the "MetadataIccProfile.archive" relation. */
  upsert?: InputMaybe<MetadataIccProfileNestedUpsertArchiveCreateInput>;
};

export type MetadataIccProfileNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.archive" relation. */
  update?: InputMaybe<MetadataIccProfileNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.archive" relation. */
  upsert?: InputMaybe<MetadataIccProfileNestedUpsertArchiveUpdateInput>;
};

export type MetadataIccProfileNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataIccProfile" node, through the "MetadataIccProfile.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataIccProfile" node, through the "MetadataIccProfile.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataIccProfile" node, through the "MetadataIccProfile.metadata" relation. */
  update?: InputMaybe<MetadataIccProfileNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataIccProfile" node, through the "MetadataIccProfile.metadata" relation. */
  upsert?: InputMaybe<MetadataIccProfileNestedUpsertMetadataCreateInput>;
};

export type MetadataIccProfileNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.metadata" relation. */
  update?: InputMaybe<MetadataIccProfileNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.metadata" relation. */
  upsert?: InputMaybe<MetadataIccProfileNestedUpsertMetadataUpdateInput>;
};

export type MetadataIccProfileNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataIccProfile" node, through the "MetadataIccProfile.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataIccProfile" node, through the "MetadataIccProfile.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataIccProfile" node, through the "MetadataIccProfile.photo" relation. */
  update?: InputMaybe<MetadataIccProfileNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataIccProfile" node, through the "MetadataIccProfile.photo" relation. */
  upsert?: InputMaybe<MetadataIccProfileNestedUpsertPhotoCreateInput>;
};

export type MetadataIccProfileNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.photo" relation. */
  update?: InputMaybe<MetadataIccProfileNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataIccProfile" node, through the "MetadataIccProfile.photo" relation. */
  upsert?: InputMaybe<MetadataIccProfileNestedUpsertPhotoUpdateInput>;
};

export type MetadataIccProfileNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIccProfileNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIccProfileNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIccProfileNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIccProfileNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIccProfileNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIccProfileNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIccProfileNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIccProfileNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIccProfileNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIccProfileNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIccProfileNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataIccProfileOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataIccProfileUpdateInput = {
  /** Actions for the "MetadataIccProfile.archive" relation */
  archive?: InputMaybe<MetadataIccProfileNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIccProfile.metadata" relation */
  metadata?: InputMaybe<MetadataIccProfileNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIccProfile.photo" relation */
  photo?: InputMaybe<MetadataIccProfileNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataIccProfile" nodes by specifying some conditions */
export type MetadataIccProfileWhereInput = {
  AND?: InputMaybe<Array<MetadataIccProfileWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataIccProfileWhereInput>;
  OR?: InputMaybe<Array<MetadataIccProfileWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataIccProfile" node. */
export type MetadataIccProfileWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataIccProfileWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataIccProfile.metadata" relation */
  metadata: MetadataIccProfileNestedMetadataCreateInput;
  /** Actions for the "MetadataIccProfile.photo" relation */
  photo: MetadataIccProfileNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIccProfileWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataIccProfile.metadata" relation */
  metadata?: InputMaybe<MetadataIccProfileNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIccProfile.photo" relation */
  photo?: InputMaybe<MetadataIccProfileNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIccProfileWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataIccProfile.archive" relation */
  archive: MetadataIccProfileNestedArchiveCreateInput;
  /** Actions for the "MetadataIccProfile.photo" relation */
  photo: MetadataIccProfileNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIccProfileWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataIccProfile.archive" relation */
  archive?: InputMaybe<MetadataIccProfileNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIccProfile.photo" relation */
  photo?: InputMaybe<MetadataIccProfileNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIccProfileWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataIccProfile.archive" relation */
  archive: MetadataIccProfileNestedArchiveCreateInput;
  /** Actions for the "MetadataIccProfile.metadata" relation */
  metadata: MetadataIccProfileNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIccProfileWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataIccProfile.archive" relation */
  archive?: InputMaybe<MetadataIccProfileNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIccProfile.metadata" relation */
  metadata?: InputMaybe<MetadataIccProfileNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataIdAssignment" resource's node */
export type MetadataIdAssignment = {
  __typename?: 'MetadataIdAssignment';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataIdAssignmentCreateInput = {
  /** Actions for the "MetadataIdAssignment.archive" relation */
  archive: MetadataIdAssignmentNestedArchiveCreateInput;
  /** Actions for the "MetadataIdAssignment.metadata" relation */
  metadata: MetadataIdAssignmentNestedMetadataCreateInput;
  /** Actions for the "MetadataIdAssignment.photo" relation */
  photo: MetadataIdAssignmentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdAssignmentNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.archive" relation. */
  update?: InputMaybe<MetadataIdAssignmentNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.archive" relation. */
  upsert?: InputMaybe<MetadataIdAssignmentNestedUpsertArchiveCreateInput>;
};

export type MetadataIdAssignmentNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.archive" relation. */
  update?: InputMaybe<MetadataIdAssignmentNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.archive" relation. */
  upsert?: InputMaybe<MetadataIdAssignmentNestedUpsertArchiveUpdateInput>;
};

export type MetadataIdAssignmentNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.metadata" relation. */
  update?: InputMaybe<MetadataIdAssignmentNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.metadata" relation. */
  upsert?: InputMaybe<MetadataIdAssignmentNestedUpsertMetadataCreateInput>;
};

export type MetadataIdAssignmentNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.metadata" relation. */
  update?: InputMaybe<MetadataIdAssignmentNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.metadata" relation. */
  upsert?: InputMaybe<MetadataIdAssignmentNestedUpsertMetadataUpdateInput>;
};

export type MetadataIdAssignmentNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.photo" relation. */
  update?: InputMaybe<MetadataIdAssignmentNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataIdAssignment" node, through the "MetadataIdAssignment.photo" relation. */
  upsert?: InputMaybe<MetadataIdAssignmentNestedUpsertPhotoCreateInput>;
};

export type MetadataIdAssignmentNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.photo" relation. */
  update?: InputMaybe<MetadataIdAssignmentNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataIdAssignment" node, through the "MetadataIdAssignment.photo" relation. */
  upsert?: InputMaybe<MetadataIdAssignmentNestedUpsertPhotoUpdateInput>;
};

export type MetadataIdAssignmentNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdAssignmentNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdAssignmentNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdAssignmentNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdAssignmentNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdAssignmentNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdAssignmentNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdAssignmentNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdAssignmentNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdAssignmentNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdAssignmentNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdAssignmentNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataIdAssignmentOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataIdAssignmentUpdateInput = {
  /** Actions for the "MetadataIdAssignment.archive" relation */
  archive?: InputMaybe<MetadataIdAssignmentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdAssignment.metadata" relation */
  metadata?: InputMaybe<MetadataIdAssignmentNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIdAssignment.photo" relation */
  photo?: InputMaybe<MetadataIdAssignmentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataIdAssignment" nodes by specifying some conditions */
export type MetadataIdAssignmentWhereInput = {
  AND?: InputMaybe<Array<MetadataIdAssignmentWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataIdAssignmentWhereInput>;
  OR?: InputMaybe<Array<MetadataIdAssignmentWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataIdAssignment" node. */
export type MetadataIdAssignmentWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataIdAssignmentWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataIdAssignment.metadata" relation */
  metadata: MetadataIdAssignmentNestedMetadataCreateInput;
  /** Actions for the "MetadataIdAssignment.photo" relation */
  photo: MetadataIdAssignmentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdAssignmentWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataIdAssignment.metadata" relation */
  metadata?: InputMaybe<MetadataIdAssignmentNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIdAssignment.photo" relation */
  photo?: InputMaybe<MetadataIdAssignmentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdAssignmentWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataIdAssignment.archive" relation */
  archive: MetadataIdAssignmentNestedArchiveCreateInput;
  /** Actions for the "MetadataIdAssignment.photo" relation */
  photo: MetadataIdAssignmentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdAssignmentWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataIdAssignment.archive" relation */
  archive?: InputMaybe<MetadataIdAssignmentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdAssignment.photo" relation */
  photo?: InputMaybe<MetadataIdAssignmentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdAssignmentWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataIdAssignment.archive" relation */
  archive: MetadataIdAssignmentNestedArchiveCreateInput;
  /** Actions for the "MetadataIdAssignment.metadata" relation */
  metadata: MetadataIdAssignmentNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdAssignmentWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataIdAssignment.archive" relation */
  archive?: InputMaybe<MetadataIdAssignmentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdAssignment.metadata" relation */
  metadata?: InputMaybe<MetadataIdAssignmentNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataIdMediaContent" resource's node */
export type MetadataIdMediaContent = {
  __typename?: 'MetadataIdMediaContent';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataIdMediaContentCreateInput = {
  /** Actions for the "MetadataIdMediaContent.archive" relation */
  archive: MetadataIdMediaContentNestedArchiveCreateInput;
  /** Actions for the "MetadataIdMediaContent.metadata" relation */
  metadata: MetadataIdMediaContentNestedMetadataCreateInput;
  /** Actions for the "MetadataIdMediaContent.photo" relation */
  photo: MetadataIdMediaContentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdMediaContentNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.archive" relation. */
  update?: InputMaybe<MetadataIdMediaContentNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.archive" relation. */
  upsert?: InputMaybe<MetadataIdMediaContentNestedUpsertArchiveCreateInput>;
};

export type MetadataIdMediaContentNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.archive" relation. */
  update?: InputMaybe<MetadataIdMediaContentNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.archive" relation. */
  upsert?: InputMaybe<MetadataIdMediaContentNestedUpsertArchiveUpdateInput>;
};

export type MetadataIdMediaContentNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.metadata" relation. */
  update?: InputMaybe<MetadataIdMediaContentNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.metadata" relation. */
  upsert?: InputMaybe<MetadataIdMediaContentNestedUpsertMetadataCreateInput>;
};

export type MetadataIdMediaContentNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.metadata" relation. */
  update?: InputMaybe<MetadataIdMediaContentNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.metadata" relation. */
  upsert?: InputMaybe<MetadataIdMediaContentNestedUpsertMetadataUpdateInput>;
};

export type MetadataIdMediaContentNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.photo" relation. */
  update?: InputMaybe<MetadataIdMediaContentNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.photo" relation. */
  upsert?: InputMaybe<MetadataIdMediaContentNestedUpsertPhotoCreateInput>;
};

export type MetadataIdMediaContentNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.photo" relation. */
  update?: InputMaybe<MetadataIdMediaContentNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataIdMediaContent" node, through the "MetadataIdMediaContent.photo" relation. */
  upsert?: InputMaybe<MetadataIdMediaContentNestedUpsertPhotoUpdateInput>;
};

export type MetadataIdMediaContentNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdMediaContentNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdMediaContentNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdMediaContentNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdMediaContentNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdMediaContentNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdMediaContentNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdMediaContentNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdMediaContentNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdMediaContentNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdMediaContentNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdMediaContentNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataIdMediaContentOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataIdMediaContentUpdateInput = {
  /** Actions for the "MetadataIdMediaContent.archive" relation */
  archive?: InputMaybe<MetadataIdMediaContentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdMediaContent.metadata" relation */
  metadata?: InputMaybe<MetadataIdMediaContentNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIdMediaContent.photo" relation */
  photo?: InputMaybe<MetadataIdMediaContentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataIdMediaContent" nodes by specifying some conditions */
export type MetadataIdMediaContentWhereInput = {
  AND?: InputMaybe<Array<MetadataIdMediaContentWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataIdMediaContentWhereInput>;
  OR?: InputMaybe<Array<MetadataIdMediaContentWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataIdMediaContent" node. */
export type MetadataIdMediaContentWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataIdMediaContentWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataIdMediaContent.metadata" relation */
  metadata: MetadataIdMediaContentNestedMetadataCreateInput;
  /** Actions for the "MetadataIdMediaContent.photo" relation */
  photo: MetadataIdMediaContentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdMediaContentWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataIdMediaContent.metadata" relation */
  metadata?: InputMaybe<MetadataIdMediaContentNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIdMediaContent.photo" relation */
  photo?: InputMaybe<MetadataIdMediaContentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdMediaContentWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataIdMediaContent.archive" relation */
  archive: MetadataIdMediaContentNestedArchiveCreateInput;
  /** Actions for the "MetadataIdMediaContent.photo" relation */
  photo: MetadataIdMediaContentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdMediaContentWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataIdMediaContent.archive" relation */
  archive?: InputMaybe<MetadataIdMediaContentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdMediaContent.photo" relation */
  photo?: InputMaybe<MetadataIdMediaContentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdMediaContentWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataIdMediaContent.archive" relation */
  archive: MetadataIdMediaContentNestedArchiveCreateInput;
  /** Actions for the "MetadataIdMediaContent.metadata" relation */
  metadata: MetadataIdMediaContentNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdMediaContentWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataIdMediaContent.archive" relation */
  archive?: InputMaybe<MetadataIdMediaContentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdMediaContent.metadata" relation */
  metadata?: InputMaybe<MetadataIdMediaContentNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataIdPhotoPrisma" resource's node */
export type MetadataIdPhotoPrisma = {
  __typename?: 'MetadataIdPhotoPrisma';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataIdPhotoPrismaCreateInput = {
  /** Actions for the "MetadataIdPhotoPrisma.archive" relation */
  archive: MetadataIdPhotoPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataIdPhotoPrisma.metadata" relation */
  metadata: MetadataIdPhotoPrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataIdPhotoPrisma.photo" relation */
  photo: MetadataIdPhotoPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdPhotoPrismaNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.archive" relation. */
  update?: InputMaybe<MetadataIdPhotoPrismaNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.archive" relation. */
  upsert?: InputMaybe<MetadataIdPhotoPrismaNestedUpsertArchiveCreateInput>;
};

export type MetadataIdPhotoPrismaNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.archive" relation. */
  update?: InputMaybe<MetadataIdPhotoPrismaNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.archive" relation. */
  upsert?: InputMaybe<MetadataIdPhotoPrismaNestedUpsertArchiveUpdateInput>;
};

export type MetadataIdPhotoPrismaNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.metadata" relation. */
  update?: InputMaybe<MetadataIdPhotoPrismaNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataIdPhotoPrismaNestedUpsertMetadataCreateInput>;
};

export type MetadataIdPhotoPrismaNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.metadata" relation. */
  update?: InputMaybe<MetadataIdPhotoPrismaNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataIdPhotoPrismaNestedUpsertMetadataUpdateInput>;
};

export type MetadataIdPhotoPrismaNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.photo" relation. */
  update?: InputMaybe<MetadataIdPhotoPrismaNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.photo" relation. */
  upsert?: InputMaybe<MetadataIdPhotoPrismaNestedUpsertPhotoCreateInput>;
};

export type MetadataIdPhotoPrismaNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.photo" relation. */
  update?: InputMaybe<MetadataIdPhotoPrismaNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataIdPhotoPrisma" node, through the "MetadataIdPhotoPrisma.photo" relation. */
  upsert?: InputMaybe<MetadataIdPhotoPrismaNestedUpsertPhotoUpdateInput>;
};

export type MetadataIdPhotoPrismaNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdPhotoPrismaNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdPhotoPrismaNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdPhotoPrismaNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdPhotoPrismaNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdPhotoPrismaNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdPhotoPrismaNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdPhotoPrismaNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdPhotoPrismaNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdPhotoPrismaNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdPhotoPrismaNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdPhotoPrismaNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataIdPhotoPrismaOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataIdPhotoPrismaUpdateInput = {
  /** Actions for the "MetadataIdPhotoPrisma.archive" relation */
  archive?: InputMaybe<MetadataIdPhotoPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdPhotoPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataIdPhotoPrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIdPhotoPrisma.photo" relation */
  photo?: InputMaybe<MetadataIdPhotoPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataIdPhotoPrisma" nodes by specifying some conditions */
export type MetadataIdPhotoPrismaWhereInput = {
  AND?: InputMaybe<Array<MetadataIdPhotoPrismaWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
  OR?: InputMaybe<Array<MetadataIdPhotoPrismaWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataIdPhotoPrisma" node. */
export type MetadataIdPhotoPrismaWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataIdPhotoPrismaWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataIdPhotoPrisma.metadata" relation */
  metadata: MetadataIdPhotoPrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataIdPhotoPrisma.photo" relation */
  photo: MetadataIdPhotoPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdPhotoPrismaWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataIdPhotoPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataIdPhotoPrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIdPhotoPrisma.photo" relation */
  photo?: InputMaybe<MetadataIdPhotoPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdPhotoPrismaWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataIdPhotoPrisma.archive" relation */
  archive: MetadataIdPhotoPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataIdPhotoPrisma.photo" relation */
  photo: MetadataIdPhotoPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdPhotoPrismaWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataIdPhotoPrisma.archive" relation */
  archive?: InputMaybe<MetadataIdPhotoPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdPhotoPrisma.photo" relation */
  photo?: InputMaybe<MetadataIdPhotoPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdPhotoPrismaWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataIdPhotoPrisma.archive" relation */
  archive: MetadataIdPhotoPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataIdPhotoPrisma.metadata" relation */
  metadata: MetadataIdPhotoPrismaNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdPhotoPrismaWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataIdPhotoPrisma.archive" relation */
  archive?: InputMaybe<MetadataIdPhotoPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdPhotoPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataIdPhotoPrismaNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataIdSubject" resource's node */
export type MetadataIdSubject = {
  __typename?: 'MetadataIdSubject';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataIdSubjectCreateInput = {
  /** Actions for the "MetadataIdSubject.archive" relation */
  archive: MetadataIdSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataIdSubject.metadata" relation */
  metadata: MetadataIdSubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataIdSubject.photo" relation */
  photo: MetadataIdSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdSubjectNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataIdSubject" node, through the "MetadataIdSubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataIdSubject" node, through the "MetadataIdSubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataIdSubject" node, through the "MetadataIdSubject.archive" relation. */
  update?: InputMaybe<MetadataIdSubjectNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataIdSubject" node, through the "MetadataIdSubject.archive" relation. */
  upsert?: InputMaybe<MetadataIdSubjectNestedUpsertArchiveCreateInput>;
};

export type MetadataIdSubjectNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.archive" relation. */
  update?: InputMaybe<MetadataIdSubjectNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.archive" relation. */
  upsert?: InputMaybe<MetadataIdSubjectNestedUpsertArchiveUpdateInput>;
};

export type MetadataIdSubjectNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataIdSubject" node, through the "MetadataIdSubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataIdSubject" node, through the "MetadataIdSubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataIdSubject" node, through the "MetadataIdSubject.metadata" relation. */
  update?: InputMaybe<MetadataIdSubjectNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataIdSubject" node, through the "MetadataIdSubject.metadata" relation. */
  upsert?: InputMaybe<MetadataIdSubjectNestedUpsertMetadataCreateInput>;
};

export type MetadataIdSubjectNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.metadata" relation. */
  update?: InputMaybe<MetadataIdSubjectNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.metadata" relation. */
  upsert?: InputMaybe<MetadataIdSubjectNestedUpsertMetadataUpdateInput>;
};

export type MetadataIdSubjectNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataIdSubject" node, through the "MetadataIdSubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataIdSubject" node, through the "MetadataIdSubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataIdSubject" node, through the "MetadataIdSubject.photo" relation. */
  update?: InputMaybe<MetadataIdSubjectNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataIdSubject" node, through the "MetadataIdSubject.photo" relation. */
  upsert?: InputMaybe<MetadataIdSubjectNestedUpsertPhotoCreateInput>;
};

export type MetadataIdSubjectNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.photo" relation. */
  update?: InputMaybe<MetadataIdSubjectNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataIdSubject" node, through the "MetadataIdSubject.photo" relation. */
  upsert?: InputMaybe<MetadataIdSubjectNestedUpsertPhotoUpdateInput>;
};

export type MetadataIdSubjectNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdSubjectNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdSubjectNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdSubjectNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdSubjectNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdSubjectNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdSubjectNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdSubjectNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIdSubjectNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdSubjectNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIdSubjectNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIdSubjectNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataIdSubjectOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataIdSubjectUpdateInput = {
  /** Actions for the "MetadataIdSubject.archive" relation */
  archive?: InputMaybe<MetadataIdSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdSubject.metadata" relation */
  metadata?: InputMaybe<MetadataIdSubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIdSubject.photo" relation */
  photo?: InputMaybe<MetadataIdSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataIdSubject" nodes by specifying some conditions */
export type MetadataIdSubjectWhereInput = {
  AND?: InputMaybe<Array<MetadataIdSubjectWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataIdSubjectWhereInput>;
  OR?: InputMaybe<Array<MetadataIdSubjectWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataIdSubject" node. */
export type MetadataIdSubjectWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataIdSubjectWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataIdSubject.metadata" relation */
  metadata: MetadataIdSubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataIdSubject.photo" relation */
  photo: MetadataIdSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdSubjectWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataIdSubject.metadata" relation */
  metadata?: InputMaybe<MetadataIdSubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIdSubject.photo" relation */
  photo?: InputMaybe<MetadataIdSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdSubjectWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataIdSubject.archive" relation */
  archive: MetadataIdSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataIdSubject.photo" relation */
  photo: MetadataIdSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdSubjectWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataIdSubject.archive" relation */
  archive?: InputMaybe<MetadataIdSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdSubject.photo" relation */
  photo?: InputMaybe<MetadataIdSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdSubjectWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataIdSubject.archive" relation */
  archive: MetadataIdSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataIdSubject.metadata" relation */
  metadata: MetadataIdSubjectNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIdSubjectWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataIdSubject.archive" relation */
  archive?: InputMaybe<MetadataIdSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIdSubject.metadata" relation */
  metadata?: InputMaybe<MetadataIdSubjectNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataImageNotes" resource's node */
export type MetadataImageNotes = {
  __typename?: 'MetadataImageNotes';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataImageNotesCreateInput = {
  /** Actions for the "MetadataImageNotes.archive" relation */
  archive: MetadataImageNotesNestedArchiveCreateInput;
  /** Actions for the "MetadataImageNotes.metadata" relation */
  metadata: MetadataImageNotesNestedMetadataCreateInput;
  /** Actions for the "MetadataImageNotes.photo" relation */
  photo: MetadataImageNotesNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageNotesNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataImageNotes" node, through the "MetadataImageNotes.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataImageNotes" node, through the "MetadataImageNotes.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataImageNotes" node, through the "MetadataImageNotes.archive" relation. */
  update?: InputMaybe<MetadataImageNotesNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataImageNotes" node, through the "MetadataImageNotes.archive" relation. */
  upsert?: InputMaybe<MetadataImageNotesNestedUpsertArchiveCreateInput>;
};

export type MetadataImageNotesNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.archive" relation. */
  update?: InputMaybe<MetadataImageNotesNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.archive" relation. */
  upsert?: InputMaybe<MetadataImageNotesNestedUpsertArchiveUpdateInput>;
};

export type MetadataImageNotesNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataImageNotes" node, through the "MetadataImageNotes.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataImageNotes" node, through the "MetadataImageNotes.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataImageNotes" node, through the "MetadataImageNotes.metadata" relation. */
  update?: InputMaybe<MetadataImageNotesNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataImageNotes" node, through the "MetadataImageNotes.metadata" relation. */
  upsert?: InputMaybe<MetadataImageNotesNestedUpsertMetadataCreateInput>;
};

export type MetadataImageNotesNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.metadata" relation. */
  update?: InputMaybe<MetadataImageNotesNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.metadata" relation. */
  upsert?: InputMaybe<MetadataImageNotesNestedUpsertMetadataUpdateInput>;
};

export type MetadataImageNotesNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataImageNotes" node, through the "MetadataImageNotes.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataImageNotes" node, through the "MetadataImageNotes.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataImageNotes" node, through the "MetadataImageNotes.photo" relation. */
  update?: InputMaybe<MetadataImageNotesNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataImageNotes" node, through the "MetadataImageNotes.photo" relation. */
  upsert?: InputMaybe<MetadataImageNotesNestedUpsertPhotoCreateInput>;
};

export type MetadataImageNotesNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.photo" relation. */
  update?: InputMaybe<MetadataImageNotesNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataImageNotes" node, through the "MetadataImageNotes.photo" relation. */
  upsert?: InputMaybe<MetadataImageNotesNestedUpsertPhotoUpdateInput>;
};

export type MetadataImageNotesNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataImageNotesNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataImageNotesNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataImageNotesNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataImageNotesNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataImageNotesNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataImageNotesNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataImageNotesNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataImageNotesNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataImageNotesNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataImageNotesNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataImageNotesNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataImageNotesOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataImageNotesUpdateInput = {
  /** Actions for the "MetadataImageNotes.archive" relation */
  archive?: InputMaybe<MetadataImageNotesNestedArchiveUpdateInput>;
  /** Actions for the "MetadataImageNotes.metadata" relation */
  metadata?: InputMaybe<MetadataImageNotesNestedMetadataUpdateInput>;
  /** Actions for the "MetadataImageNotes.photo" relation */
  photo?: InputMaybe<MetadataImageNotesNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataImageNotes" nodes by specifying some conditions */
export type MetadataImageNotesWhereInput = {
  AND?: InputMaybe<Array<MetadataImageNotesWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataImageNotesWhereInput>;
  OR?: InputMaybe<Array<MetadataImageNotesWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataImageNotes" node. */
export type MetadataImageNotesWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataImageNotesWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataImageNotes.metadata" relation */
  metadata: MetadataImageNotesNestedMetadataCreateInput;
  /** Actions for the "MetadataImageNotes.photo" relation */
  photo: MetadataImageNotesNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageNotesWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataImageNotes.metadata" relation */
  metadata?: InputMaybe<MetadataImageNotesNestedMetadataUpdateInput>;
  /** Actions for the "MetadataImageNotes.photo" relation */
  photo?: InputMaybe<MetadataImageNotesNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageNotesWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataImageNotes.archive" relation */
  archive: MetadataImageNotesNestedArchiveCreateInput;
  /** Actions for the "MetadataImageNotes.photo" relation */
  photo: MetadataImageNotesNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageNotesWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataImageNotes.archive" relation */
  archive?: InputMaybe<MetadataImageNotesNestedArchiveUpdateInput>;
  /** Actions for the "MetadataImageNotes.photo" relation */
  photo?: InputMaybe<MetadataImageNotesNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageNotesWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataImageNotes.archive" relation */
  archive: MetadataImageNotesNestedArchiveCreateInput;
  /** Actions for the "MetadataImageNotes.metadata" relation */
  metadata: MetadataImageNotesNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageNotesWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataImageNotes.archive" relation */
  archive?: InputMaybe<MetadataImageNotesNestedArchiveUpdateInput>;
  /** Actions for the "MetadataImageNotes.metadata" relation */
  metadata?: InputMaybe<MetadataImageNotesNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataImageUniqueId" resource's node */
export type MetadataImageUniqueId = {
  __typename?: 'MetadataImageUniqueId';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataImageUniqueIdCreateInput = {
  /** Actions for the "MetadataImageUniqueId.archive" relation */
  archive: MetadataImageUniqueIdNestedArchiveCreateInput;
  /** Actions for the "MetadataImageUniqueId.metadata" relation */
  metadata: MetadataImageUniqueIdNestedMetadataCreateInput;
  /** Actions for the "MetadataImageUniqueId.photo" relation */
  photo: MetadataImageUniqueIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageUniqueIdNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.archive" relation. */
  update?: InputMaybe<MetadataImageUniqueIdNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.archive" relation. */
  upsert?: InputMaybe<MetadataImageUniqueIdNestedUpsertArchiveCreateInput>;
};

export type MetadataImageUniqueIdNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.archive" relation. */
  update?: InputMaybe<MetadataImageUniqueIdNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.archive" relation. */
  upsert?: InputMaybe<MetadataImageUniqueIdNestedUpsertArchiveUpdateInput>;
};

export type MetadataImageUniqueIdNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.metadata" relation. */
  update?: InputMaybe<MetadataImageUniqueIdNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.metadata" relation. */
  upsert?: InputMaybe<MetadataImageUniqueIdNestedUpsertMetadataCreateInput>;
};

export type MetadataImageUniqueIdNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.metadata" relation. */
  update?: InputMaybe<MetadataImageUniqueIdNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.metadata" relation. */
  upsert?: InputMaybe<MetadataImageUniqueIdNestedUpsertMetadataUpdateInput>;
};

export type MetadataImageUniqueIdNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.photo" relation. */
  update?: InputMaybe<MetadataImageUniqueIdNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.photo" relation. */
  upsert?: InputMaybe<MetadataImageUniqueIdNestedUpsertPhotoCreateInput>;
};

export type MetadataImageUniqueIdNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.photo" relation. */
  update?: InputMaybe<MetadataImageUniqueIdNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataImageUniqueId" node, through the "MetadataImageUniqueId.photo" relation. */
  upsert?: InputMaybe<MetadataImageUniqueIdNestedUpsertPhotoUpdateInput>;
};

export type MetadataImageUniqueIdNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataImageUniqueIdNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataImageUniqueIdNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataImageUniqueIdNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataImageUniqueIdNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataImageUniqueIdNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataImageUniqueIdNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataImageUniqueIdNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataImageUniqueIdNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataImageUniqueIdNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataImageUniqueIdNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataImageUniqueIdNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataImageUniqueIdOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataImageUniqueIdUpdateInput = {
  /** Actions for the "MetadataImageUniqueId.archive" relation */
  archive?: InputMaybe<MetadataImageUniqueIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataImageUniqueId.metadata" relation */
  metadata?: InputMaybe<MetadataImageUniqueIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataImageUniqueId.photo" relation */
  photo?: InputMaybe<MetadataImageUniqueIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataImageUniqueId" nodes by specifying some conditions */
export type MetadataImageUniqueIdWhereInput = {
  AND?: InputMaybe<Array<MetadataImageUniqueIdWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataImageUniqueIdWhereInput>;
  OR?: InputMaybe<Array<MetadataImageUniqueIdWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataImageUniqueId" node. */
export type MetadataImageUniqueIdWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataImageUniqueIdWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataImageUniqueId.metadata" relation */
  metadata: MetadataImageUniqueIdNestedMetadataCreateInput;
  /** Actions for the "MetadataImageUniqueId.photo" relation */
  photo: MetadataImageUniqueIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageUniqueIdWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataImageUniqueId.metadata" relation */
  metadata?: InputMaybe<MetadataImageUniqueIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataImageUniqueId.photo" relation */
  photo?: InputMaybe<MetadataImageUniqueIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageUniqueIdWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataImageUniqueId.archive" relation */
  archive: MetadataImageUniqueIdNestedArchiveCreateInput;
  /** Actions for the "MetadataImageUniqueId.photo" relation */
  photo: MetadataImageUniqueIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageUniqueIdWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataImageUniqueId.archive" relation */
  archive?: InputMaybe<MetadataImageUniqueIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataImageUniqueId.photo" relation */
  photo?: InputMaybe<MetadataImageUniqueIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageUniqueIdWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataImageUniqueId.archive" relation */
  archive: MetadataImageUniqueIdNestedArchiveCreateInput;
  /** Actions for the "MetadataImageUniqueId.metadata" relation */
  metadata: MetadataImageUniqueIdNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataImageUniqueIdWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataImageUniqueId.archive" relation */
  archive?: InputMaybe<MetadataImageUniqueIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataImageUniqueId.metadata" relation */
  metadata?: InputMaybe<MetadataImageUniqueIdNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataInstructions" resource's node */
export type MetadataInstructions = {
  __typename?: 'MetadataInstructions';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataInstructionsCreateInput = {
  /** Actions for the "MetadataInstructions.archive" relation */
  archive: MetadataInstructionsNestedArchiveCreateInput;
  /** Actions for the "MetadataInstructions.metadata" relation */
  metadata: MetadataInstructionsNestedMetadataCreateInput;
  /** Actions for the "MetadataInstructions.photo" relation */
  photo: MetadataInstructionsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataInstructionsNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataInstructions" node, through the "MetadataInstructions.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataInstructions" node, through the "MetadataInstructions.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataInstructions" node, through the "MetadataInstructions.archive" relation. */
  update?: InputMaybe<MetadataInstructionsNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataInstructions" node, through the "MetadataInstructions.archive" relation. */
  upsert?: InputMaybe<MetadataInstructionsNestedUpsertArchiveCreateInput>;
};

export type MetadataInstructionsNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataInstructions" node, through the "MetadataInstructions.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataInstructions" node, through the "MetadataInstructions.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataInstructions" node, through the "MetadataInstructions.archive" relation. */
  update?: InputMaybe<MetadataInstructionsNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataInstructions" node, through the "MetadataInstructions.archive" relation. */
  upsert?: InputMaybe<MetadataInstructionsNestedUpsertArchiveUpdateInput>;
};

export type MetadataInstructionsNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataInstructions" node, through the "MetadataInstructions.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataInstructions" node, through the "MetadataInstructions.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataInstructions" node, through the "MetadataInstructions.metadata" relation. */
  update?: InputMaybe<MetadataInstructionsNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataInstructions" node, through the "MetadataInstructions.metadata" relation. */
  upsert?: InputMaybe<MetadataInstructionsNestedUpsertMetadataCreateInput>;
};

export type MetadataInstructionsNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataInstructions" node, through the "MetadataInstructions.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataInstructions" node, through the "MetadataInstructions.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataInstructions" node, through the "MetadataInstructions.metadata" relation. */
  update?: InputMaybe<MetadataInstructionsNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataInstructions" node, through the "MetadataInstructions.metadata" relation. */
  upsert?: InputMaybe<MetadataInstructionsNestedUpsertMetadataUpdateInput>;
};

export type MetadataInstructionsNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataInstructions" node, through the "MetadataInstructions.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataInstructions" node, through the "MetadataInstructions.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataInstructions" node, through the "MetadataInstructions.photo" relation. */
  update?: InputMaybe<MetadataInstructionsNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataInstructions" node, through the "MetadataInstructions.photo" relation. */
  upsert?: InputMaybe<MetadataInstructionsNestedUpsertPhotoCreateInput>;
};

export type MetadataInstructionsNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataInstructions" node, through the "MetadataInstructions.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataInstructions" node, through the "MetadataInstructions.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataInstructions" node, through the "MetadataInstructions.photo" relation. */
  update?: InputMaybe<MetadataInstructionsNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataInstructions" node, through the "MetadataInstructions.photo" relation. */
  upsert?: InputMaybe<MetadataInstructionsNestedUpsertPhotoUpdateInput>;
};

export type MetadataInstructionsNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataInstructionsNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataInstructionsNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataInstructionsNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataInstructionsNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataInstructionsNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataInstructionsNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataInstructionsNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataInstructionsNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataInstructionsNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataInstructionsNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataInstructionsNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataInstructionsOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataInstructionsUpdateInput = {
  /** Actions for the "MetadataInstructions.archive" relation */
  archive?: InputMaybe<MetadataInstructionsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataInstructions.metadata" relation */
  metadata?: InputMaybe<MetadataInstructionsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataInstructions.photo" relation */
  photo?: InputMaybe<MetadataInstructionsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataInstructions" nodes by specifying some conditions */
export type MetadataInstructionsWhereInput = {
  AND?: InputMaybe<Array<MetadataInstructionsWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataInstructionsWhereInput>;
  OR?: InputMaybe<Array<MetadataInstructionsWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataInstructions" node. */
export type MetadataInstructionsWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataInstructionsWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataInstructions.metadata" relation */
  metadata: MetadataInstructionsNestedMetadataCreateInput;
  /** Actions for the "MetadataInstructions.photo" relation */
  photo: MetadataInstructionsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataInstructionsWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataInstructions.metadata" relation */
  metadata?: InputMaybe<MetadataInstructionsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataInstructions.photo" relation */
  photo?: InputMaybe<MetadataInstructionsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataInstructionsWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataInstructions.archive" relation */
  archive: MetadataInstructionsNestedArchiveCreateInput;
  /** Actions for the "MetadataInstructions.photo" relation */
  photo: MetadataInstructionsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataInstructionsWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataInstructions.archive" relation */
  archive?: InputMaybe<MetadataInstructionsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataInstructions.photo" relation */
  photo?: InputMaybe<MetadataInstructionsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataInstructionsWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataInstructions.archive" relation */
  archive: MetadataInstructionsNestedArchiveCreateInput;
  /** Actions for the "MetadataInstructions.metadata" relation */
  metadata: MetadataInstructionsNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataInstructionsWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataInstructions.archive" relation */
  archive?: InputMaybe<MetadataInstructionsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataInstructions.metadata" relation */
  metadata?: InputMaybe<MetadataInstructionsNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataIntellectualGenre" resource's node */
export type MetadataIntellectualGenre = {
  __typename?: 'MetadataIntellectualGenre';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataIntellectualGenreCreateInput = {
  /** Actions for the "MetadataIntellectualGenre.archive" relation */
  archive: MetadataIntellectualGenreNestedArchiveCreateInput;
  /** Actions for the "MetadataIntellectualGenre.metadata" relation */
  metadata: MetadataIntellectualGenreNestedMetadataCreateInput;
  /** Actions for the "MetadataIntellectualGenre.photo" relation */
  photo: MetadataIntellectualGenreNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIntellectualGenreNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.archive" relation. */
  update?: InputMaybe<MetadataIntellectualGenreNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.archive" relation. */
  upsert?: InputMaybe<MetadataIntellectualGenreNestedUpsertArchiveCreateInput>;
};

export type MetadataIntellectualGenreNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.archive" relation. */
  update?: InputMaybe<MetadataIntellectualGenreNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.archive" relation. */
  upsert?: InputMaybe<MetadataIntellectualGenreNestedUpsertArchiveUpdateInput>;
};

export type MetadataIntellectualGenreNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.metadata" relation. */
  update?: InputMaybe<MetadataIntellectualGenreNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.metadata" relation. */
  upsert?: InputMaybe<MetadataIntellectualGenreNestedUpsertMetadataCreateInput>;
};

export type MetadataIntellectualGenreNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.metadata" relation. */
  update?: InputMaybe<MetadataIntellectualGenreNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.metadata" relation. */
  upsert?: InputMaybe<MetadataIntellectualGenreNestedUpsertMetadataUpdateInput>;
};

export type MetadataIntellectualGenreNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.photo" relation. */
  update?: InputMaybe<MetadataIntellectualGenreNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.photo" relation. */
  upsert?: InputMaybe<MetadataIntellectualGenreNestedUpsertPhotoCreateInput>;
};

export type MetadataIntellectualGenreNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.photo" relation. */
  update?: InputMaybe<MetadataIntellectualGenreNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataIntellectualGenre" node, through the "MetadataIntellectualGenre.photo" relation. */
  upsert?: InputMaybe<MetadataIntellectualGenreNestedUpsertPhotoUpdateInput>;
};

export type MetadataIntellectualGenreNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIntellectualGenreNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIntellectualGenreNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIntellectualGenreNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIntellectualGenreNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIntellectualGenreNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIntellectualGenreNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIntellectualGenreNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIntellectualGenreNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIntellectualGenreNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIntellectualGenreNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIntellectualGenreNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataIntellectualGenreOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataIntellectualGenreUpdateInput = {
  /** Actions for the "MetadataIntellectualGenre.archive" relation */
  archive?: InputMaybe<MetadataIntellectualGenreNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIntellectualGenre.metadata" relation */
  metadata?: InputMaybe<MetadataIntellectualGenreNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIntellectualGenre.photo" relation */
  photo?: InputMaybe<MetadataIntellectualGenreNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataIntellectualGenre" nodes by specifying some conditions */
export type MetadataIntellectualGenreWhereInput = {
  AND?: InputMaybe<Array<MetadataIntellectualGenreWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataIntellectualGenreWhereInput>;
  OR?: InputMaybe<Array<MetadataIntellectualGenreWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataIntellectualGenre" node. */
export type MetadataIntellectualGenreWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataIntellectualGenreWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataIntellectualGenre.metadata" relation */
  metadata: MetadataIntellectualGenreNestedMetadataCreateInput;
  /** Actions for the "MetadataIntellectualGenre.photo" relation */
  photo: MetadataIntellectualGenreNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIntellectualGenreWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataIntellectualGenre.metadata" relation */
  metadata?: InputMaybe<MetadataIntellectualGenreNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIntellectualGenre.photo" relation */
  photo?: InputMaybe<MetadataIntellectualGenreNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIntellectualGenreWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataIntellectualGenre.archive" relation */
  archive: MetadataIntellectualGenreNestedArchiveCreateInput;
  /** Actions for the "MetadataIntellectualGenre.photo" relation */
  photo: MetadataIntellectualGenreNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIntellectualGenreWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataIntellectualGenre.archive" relation */
  archive?: InputMaybe<MetadataIntellectualGenreNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIntellectualGenre.photo" relation */
  photo?: InputMaybe<MetadataIntellectualGenreNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIntellectualGenreWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataIntellectualGenre.archive" relation */
  archive: MetadataIntellectualGenreNestedArchiveCreateInput;
  /** Actions for the "MetadataIntellectualGenre.metadata" relation */
  metadata: MetadataIntellectualGenreNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIntellectualGenreWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataIntellectualGenre.archive" relation */
  archive?: InputMaybe<MetadataIntellectualGenreNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIntellectualGenre.metadata" relation */
  metadata?: InputMaybe<MetadataIntellectualGenreNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataIsoSpeedRatings" resource's node */
export type MetadataIsoSpeedRatings = {
  __typename?: 'MetadataIsoSpeedRatings';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataIsoSpeedRatingsCreateInput = {
  /** Actions for the "MetadataIsoSpeedRatings.archive" relation */
  archive: MetadataIsoSpeedRatingsNestedArchiveCreateInput;
  /** Actions for the "MetadataIsoSpeedRatings.metadata" relation */
  metadata: MetadataIsoSpeedRatingsNestedMetadataCreateInput;
  /** Actions for the "MetadataIsoSpeedRatings.photo" relation */
  photo: MetadataIsoSpeedRatingsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIsoSpeedRatingsNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.archive" relation. */
  update?: InputMaybe<MetadataIsoSpeedRatingsNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.archive" relation. */
  upsert?: InputMaybe<MetadataIsoSpeedRatingsNestedUpsertArchiveCreateInput>;
};

export type MetadataIsoSpeedRatingsNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.archive" relation. */
  update?: InputMaybe<MetadataIsoSpeedRatingsNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.archive" relation. */
  upsert?: InputMaybe<MetadataIsoSpeedRatingsNestedUpsertArchiveUpdateInput>;
};

export type MetadataIsoSpeedRatingsNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.metadata" relation. */
  update?: InputMaybe<MetadataIsoSpeedRatingsNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.metadata" relation. */
  upsert?: InputMaybe<MetadataIsoSpeedRatingsNestedUpsertMetadataCreateInput>;
};

export type MetadataIsoSpeedRatingsNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.metadata" relation. */
  update?: InputMaybe<MetadataIsoSpeedRatingsNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.metadata" relation. */
  upsert?: InputMaybe<MetadataIsoSpeedRatingsNestedUpsertMetadataUpdateInput>;
};

export type MetadataIsoSpeedRatingsNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.photo" relation. */
  update?: InputMaybe<MetadataIsoSpeedRatingsNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.photo" relation. */
  upsert?: InputMaybe<MetadataIsoSpeedRatingsNestedUpsertPhotoCreateInput>;
};

export type MetadataIsoSpeedRatingsNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.photo" relation. */
  update?: InputMaybe<MetadataIsoSpeedRatingsNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataIsoSpeedRatings" node, through the "MetadataIsoSpeedRatings.photo" relation. */
  upsert?: InputMaybe<MetadataIsoSpeedRatingsNestedUpsertPhotoUpdateInput>;
};

export type MetadataIsoSpeedRatingsNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIsoSpeedRatingsNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIsoSpeedRatingsNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIsoSpeedRatingsNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIsoSpeedRatingsNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIsoSpeedRatingsNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIsoSpeedRatingsNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIsoSpeedRatingsNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIsoSpeedRatingsNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIsoSpeedRatingsNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIsoSpeedRatingsNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIsoSpeedRatingsNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataIsoSpeedRatingsOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataIsoSpeedRatingsUpdateInput = {
  /** Actions for the "MetadataIsoSpeedRatings.archive" relation */
  archive?: InputMaybe<MetadataIsoSpeedRatingsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIsoSpeedRatings.metadata" relation */
  metadata?: InputMaybe<MetadataIsoSpeedRatingsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIsoSpeedRatings.photo" relation */
  photo?: InputMaybe<MetadataIsoSpeedRatingsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataIsoSpeedRatings" nodes by specifying some conditions */
export type MetadataIsoSpeedRatingsWhereInput = {
  AND?: InputMaybe<Array<MetadataIsoSpeedRatingsWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
  OR?: InputMaybe<Array<MetadataIsoSpeedRatingsWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataIsoSpeedRatings" node. */
export type MetadataIsoSpeedRatingsWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataIsoSpeedRatingsWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataIsoSpeedRatings.metadata" relation */
  metadata: MetadataIsoSpeedRatingsNestedMetadataCreateInput;
  /** Actions for the "MetadataIsoSpeedRatings.photo" relation */
  photo: MetadataIsoSpeedRatingsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIsoSpeedRatingsWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataIsoSpeedRatings.metadata" relation */
  metadata?: InputMaybe<MetadataIsoSpeedRatingsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIsoSpeedRatings.photo" relation */
  photo?: InputMaybe<MetadataIsoSpeedRatingsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIsoSpeedRatingsWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataIsoSpeedRatings.archive" relation */
  archive: MetadataIsoSpeedRatingsNestedArchiveCreateInput;
  /** Actions for the "MetadataIsoSpeedRatings.photo" relation */
  photo: MetadataIsoSpeedRatingsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIsoSpeedRatingsWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataIsoSpeedRatings.archive" relation */
  archive?: InputMaybe<MetadataIsoSpeedRatingsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIsoSpeedRatings.photo" relation */
  photo?: InputMaybe<MetadataIsoSpeedRatingsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIsoSpeedRatingsWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataIsoSpeedRatings.archive" relation */
  archive: MetadataIsoSpeedRatingsNestedArchiveCreateInput;
  /** Actions for the "MetadataIsoSpeedRatings.metadata" relation */
  metadata: MetadataIsoSpeedRatingsNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIsoSpeedRatingsWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataIsoSpeedRatings.archive" relation */
  archive?: InputMaybe<MetadataIsoSpeedRatingsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIsoSpeedRatings.metadata" relation */
  metadata?: InputMaybe<MetadataIsoSpeedRatingsNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataIssue" resource's node */
export type MetadataIssue = {
  __typename?: 'MetadataIssue';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataIssueCreateInput = {
  /** Actions for the "MetadataIssue.archive" relation */
  archive: MetadataIssueNestedArchiveCreateInput;
  /** Actions for the "MetadataIssue.metadata" relation */
  metadata: MetadataIssueNestedMetadataCreateInput;
  /** Actions for the "MetadataIssue.photo" relation */
  photo: MetadataIssueNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIssueNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataIssue" node, through the "MetadataIssue.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataIssue" node, through the "MetadataIssue.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataIssue" node, through the "MetadataIssue.archive" relation. */
  update?: InputMaybe<MetadataIssueNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataIssue" node, through the "MetadataIssue.archive" relation. */
  upsert?: InputMaybe<MetadataIssueNestedUpsertArchiveCreateInput>;
};

export type MetadataIssueNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataIssue" node, through the "MetadataIssue.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataIssue" node, through the "MetadataIssue.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataIssue" node, through the "MetadataIssue.archive" relation. */
  update?: InputMaybe<MetadataIssueNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataIssue" node, through the "MetadataIssue.archive" relation. */
  upsert?: InputMaybe<MetadataIssueNestedUpsertArchiveUpdateInput>;
};

export type MetadataIssueNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataIssue" node, through the "MetadataIssue.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataIssue" node, through the "MetadataIssue.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataIssue" node, through the "MetadataIssue.metadata" relation. */
  update?: InputMaybe<MetadataIssueNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataIssue" node, through the "MetadataIssue.metadata" relation. */
  upsert?: InputMaybe<MetadataIssueNestedUpsertMetadataCreateInput>;
};

export type MetadataIssueNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataIssue" node, through the "MetadataIssue.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataIssue" node, through the "MetadataIssue.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataIssue" node, through the "MetadataIssue.metadata" relation. */
  update?: InputMaybe<MetadataIssueNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataIssue" node, through the "MetadataIssue.metadata" relation. */
  upsert?: InputMaybe<MetadataIssueNestedUpsertMetadataUpdateInput>;
};

export type MetadataIssueNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataIssue" node, through the "MetadataIssue.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataIssue" node, through the "MetadataIssue.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataIssue" node, through the "MetadataIssue.photo" relation. */
  update?: InputMaybe<MetadataIssueNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataIssue" node, through the "MetadataIssue.photo" relation. */
  upsert?: InputMaybe<MetadataIssueNestedUpsertPhotoCreateInput>;
};

export type MetadataIssueNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataIssue" node, through the "MetadataIssue.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataIssue" node, through the "MetadataIssue.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataIssue" node, through the "MetadataIssue.photo" relation. */
  update?: InputMaybe<MetadataIssueNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataIssue" node, through the "MetadataIssue.photo" relation. */
  upsert?: InputMaybe<MetadataIssueNestedUpsertPhotoUpdateInput>;
};

export type MetadataIssueNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIssueNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIssueNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIssueNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIssueNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIssueNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIssueNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIssueNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataIssueNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIssueNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataIssueNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataIssueNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataIssueOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataIssueUpdateInput = {
  /** Actions for the "MetadataIssue.archive" relation */
  archive?: InputMaybe<MetadataIssueNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIssue.metadata" relation */
  metadata?: InputMaybe<MetadataIssueNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIssue.photo" relation */
  photo?: InputMaybe<MetadataIssueNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataIssue" nodes by specifying some conditions */
export type MetadataIssueWhereInput = {
  AND?: InputMaybe<Array<MetadataIssueWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataIssueWhereInput>;
  OR?: InputMaybe<Array<MetadataIssueWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataIssue" node. */
export type MetadataIssueWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataIssueWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataIssue.metadata" relation */
  metadata: MetadataIssueNestedMetadataCreateInput;
  /** Actions for the "MetadataIssue.photo" relation */
  photo: MetadataIssueNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIssueWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataIssue.metadata" relation */
  metadata?: InputMaybe<MetadataIssueNestedMetadataUpdateInput>;
  /** Actions for the "MetadataIssue.photo" relation */
  photo?: InputMaybe<MetadataIssueNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIssueWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataIssue.archive" relation */
  archive: MetadataIssueNestedArchiveCreateInput;
  /** Actions for the "MetadataIssue.photo" relation */
  photo: MetadataIssueNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIssueWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataIssue.archive" relation */
  archive?: InputMaybe<MetadataIssueNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIssue.photo" relation */
  photo?: InputMaybe<MetadataIssueNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIssueWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataIssue.archive" relation */
  archive: MetadataIssueNestedArchiveCreateInput;
  /** Actions for the "MetadataIssue.metadata" relation */
  metadata: MetadataIssueNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataIssueWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataIssue.archive" relation */
  archive?: InputMaybe<MetadataIssueNestedArchiveUpdateInput>;
  /** Actions for the "MetadataIssue.metadata" relation */
  metadata?: InputMaybe<MetadataIssueNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataJobId" resource's node */
export type MetadataJobId = {
  __typename?: 'MetadataJobId';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataJobIdCreateInput = {
  /** Actions for the "MetadataJobId.archive" relation */
  archive: MetadataJobIdNestedArchiveCreateInput;
  /** Actions for the "MetadataJobId.metadata" relation */
  metadata: MetadataJobIdNestedMetadataCreateInput;
  /** Actions for the "MetadataJobId.photo" relation */
  photo: MetadataJobIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobIdNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataJobId" node, through the "MetadataJobId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataJobId" node, through the "MetadataJobId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataJobId" node, through the "MetadataJobId.archive" relation. */
  update?: InputMaybe<MetadataJobIdNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataJobId" node, through the "MetadataJobId.archive" relation. */
  upsert?: InputMaybe<MetadataJobIdNestedUpsertArchiveCreateInput>;
};

export type MetadataJobIdNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataJobId" node, through the "MetadataJobId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataJobId" node, through the "MetadataJobId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataJobId" node, through the "MetadataJobId.archive" relation. */
  update?: InputMaybe<MetadataJobIdNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataJobId" node, through the "MetadataJobId.archive" relation. */
  upsert?: InputMaybe<MetadataJobIdNestedUpsertArchiveUpdateInput>;
};

export type MetadataJobIdNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataJobId" node, through the "MetadataJobId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataJobId" node, through the "MetadataJobId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataJobId" node, through the "MetadataJobId.metadata" relation. */
  update?: InputMaybe<MetadataJobIdNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataJobId" node, through the "MetadataJobId.metadata" relation. */
  upsert?: InputMaybe<MetadataJobIdNestedUpsertMetadataCreateInput>;
};

export type MetadataJobIdNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataJobId" node, through the "MetadataJobId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataJobId" node, through the "MetadataJobId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataJobId" node, through the "MetadataJobId.metadata" relation. */
  update?: InputMaybe<MetadataJobIdNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataJobId" node, through the "MetadataJobId.metadata" relation. */
  upsert?: InputMaybe<MetadataJobIdNestedUpsertMetadataUpdateInput>;
};

export type MetadataJobIdNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataJobId" node, through the "MetadataJobId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataJobId" node, through the "MetadataJobId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataJobId" node, through the "MetadataJobId.photo" relation. */
  update?: InputMaybe<MetadataJobIdNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataJobId" node, through the "MetadataJobId.photo" relation. */
  upsert?: InputMaybe<MetadataJobIdNestedUpsertPhotoCreateInput>;
};

export type MetadataJobIdNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataJobId" node, through the "MetadataJobId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataJobId" node, through the "MetadataJobId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataJobId" node, through the "MetadataJobId.photo" relation. */
  update?: InputMaybe<MetadataJobIdNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataJobId" node, through the "MetadataJobId.photo" relation. */
  upsert?: InputMaybe<MetadataJobIdNestedUpsertPhotoUpdateInput>;
};

export type MetadataJobIdNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataJobIdNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataJobIdNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataJobIdNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataJobIdNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataJobIdNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataJobIdNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataJobIdNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataJobIdNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataJobIdNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataJobIdNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataJobIdNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataJobIdOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataJobIdUpdateInput = {
  /** Actions for the "MetadataJobId.archive" relation */
  archive?: InputMaybe<MetadataJobIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataJobId.metadata" relation */
  metadata?: InputMaybe<MetadataJobIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataJobId.photo" relation */
  photo?: InputMaybe<MetadataJobIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataJobId" nodes by specifying some conditions */
export type MetadataJobIdWhereInput = {
  AND?: InputMaybe<Array<MetadataJobIdWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataJobIdWhereInput>;
  OR?: InputMaybe<Array<MetadataJobIdWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataJobId" node. */
export type MetadataJobIdWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataJobIdWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataJobId.metadata" relation */
  metadata: MetadataJobIdNestedMetadataCreateInput;
  /** Actions for the "MetadataJobId.photo" relation */
  photo: MetadataJobIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobIdWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataJobId.metadata" relation */
  metadata?: InputMaybe<MetadataJobIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataJobId.photo" relation */
  photo?: InputMaybe<MetadataJobIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobIdWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataJobId.archive" relation */
  archive: MetadataJobIdNestedArchiveCreateInput;
  /** Actions for the "MetadataJobId.photo" relation */
  photo: MetadataJobIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobIdWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataJobId.archive" relation */
  archive?: InputMaybe<MetadataJobIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataJobId.photo" relation */
  photo?: InputMaybe<MetadataJobIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobIdWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataJobId.archive" relation */
  archive: MetadataJobIdNestedArchiveCreateInput;
  /** Actions for the "MetadataJobId.metadata" relation */
  metadata: MetadataJobIdNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobIdWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataJobId.archive" relation */
  archive?: InputMaybe<MetadataJobIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataJobId.metadata" relation */
  metadata?: InputMaybe<MetadataJobIdNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataJobProcessing" resource's node */
export type MetadataJobProcessing = {
  __typename?: 'MetadataJobProcessing';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataJobProcessingCreateInput = {
  /** Actions for the "MetadataJobProcessing.archive" relation */
  archive: MetadataJobProcessingNestedArchiveCreateInput;
  /** Actions for the "MetadataJobProcessing.metadata" relation */
  metadata: MetadataJobProcessingNestedMetadataCreateInput;
  /** Actions for the "MetadataJobProcessing.photo" relation */
  photo: MetadataJobProcessingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobProcessingNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.archive" relation. */
  update?: InputMaybe<MetadataJobProcessingNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.archive" relation. */
  upsert?: InputMaybe<MetadataJobProcessingNestedUpsertArchiveCreateInput>;
};

export type MetadataJobProcessingNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.archive" relation. */
  update?: InputMaybe<MetadataJobProcessingNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.archive" relation. */
  upsert?: InputMaybe<MetadataJobProcessingNestedUpsertArchiveUpdateInput>;
};

export type MetadataJobProcessingNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.metadata" relation. */
  update?: InputMaybe<MetadataJobProcessingNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.metadata" relation. */
  upsert?: InputMaybe<MetadataJobProcessingNestedUpsertMetadataCreateInput>;
};

export type MetadataJobProcessingNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.metadata" relation. */
  update?: InputMaybe<MetadataJobProcessingNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.metadata" relation. */
  upsert?: InputMaybe<MetadataJobProcessingNestedUpsertMetadataUpdateInput>;
};

export type MetadataJobProcessingNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.photo" relation. */
  update?: InputMaybe<MetadataJobProcessingNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataJobProcessing" node, through the "MetadataJobProcessing.photo" relation. */
  upsert?: InputMaybe<MetadataJobProcessingNestedUpsertPhotoCreateInput>;
};

export type MetadataJobProcessingNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.photo" relation. */
  update?: InputMaybe<MetadataJobProcessingNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataJobProcessing" node, through the "MetadataJobProcessing.photo" relation. */
  upsert?: InputMaybe<MetadataJobProcessingNestedUpsertPhotoUpdateInput>;
};

export type MetadataJobProcessingNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataJobProcessingNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataJobProcessingNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataJobProcessingNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataJobProcessingNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataJobProcessingNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataJobProcessingNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataJobProcessingNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataJobProcessingNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataJobProcessingNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataJobProcessingNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataJobProcessingNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataJobProcessingOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataJobProcessingUpdateInput = {
  /** Actions for the "MetadataJobProcessing.archive" relation */
  archive?: InputMaybe<MetadataJobProcessingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataJobProcessing.metadata" relation */
  metadata?: InputMaybe<MetadataJobProcessingNestedMetadataUpdateInput>;
  /** Actions for the "MetadataJobProcessing.photo" relation */
  photo?: InputMaybe<MetadataJobProcessingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataJobProcessing" nodes by specifying some conditions */
export type MetadataJobProcessingWhereInput = {
  AND?: InputMaybe<Array<MetadataJobProcessingWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataJobProcessingWhereInput>;
  OR?: InputMaybe<Array<MetadataJobProcessingWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataJobProcessing" node. */
export type MetadataJobProcessingWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataJobProcessingWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataJobProcessing.metadata" relation */
  metadata: MetadataJobProcessingNestedMetadataCreateInput;
  /** Actions for the "MetadataJobProcessing.photo" relation */
  photo: MetadataJobProcessingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobProcessingWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataJobProcessing.metadata" relation */
  metadata?: InputMaybe<MetadataJobProcessingNestedMetadataUpdateInput>;
  /** Actions for the "MetadataJobProcessing.photo" relation */
  photo?: InputMaybe<MetadataJobProcessingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobProcessingWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataJobProcessing.archive" relation */
  archive: MetadataJobProcessingNestedArchiveCreateInput;
  /** Actions for the "MetadataJobProcessing.photo" relation */
  photo: MetadataJobProcessingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobProcessingWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataJobProcessing.archive" relation */
  archive?: InputMaybe<MetadataJobProcessingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataJobProcessing.photo" relation */
  photo?: InputMaybe<MetadataJobProcessingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobProcessingWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataJobProcessing.archive" relation */
  archive: MetadataJobProcessingNestedArchiveCreateInput;
  /** Actions for the "MetadataJobProcessing.metadata" relation */
  metadata: MetadataJobProcessingNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataJobProcessingWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataJobProcessing.archive" relation */
  archive?: InputMaybe<MetadataJobProcessingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataJobProcessing.metadata" relation */
  metadata?: InputMaybe<MetadataJobProcessingNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataLayoutPictureEditing" resource's node */
export type MetadataLayoutPictureEditing = {
  __typename?: 'MetadataLayoutPictureEditing';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataLayoutPictureEditingCreateInput = {
  /** Actions for the "MetadataLayoutPictureEditing.archive" relation */
  archive: MetadataLayoutPictureEditingNestedArchiveCreateInput;
  /** Actions for the "MetadataLayoutPictureEditing.metadata" relation */
  metadata: MetadataLayoutPictureEditingNestedMetadataCreateInput;
  /** Actions for the "MetadataLayoutPictureEditing.photo" relation */
  photo: MetadataLayoutPictureEditingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutPictureEditingNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.archive" relation. */
  update?: InputMaybe<MetadataLayoutPictureEditingNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.archive" relation. */
  upsert?: InputMaybe<MetadataLayoutPictureEditingNestedUpsertArchiveCreateInput>;
};

export type MetadataLayoutPictureEditingNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.archive" relation. */
  update?: InputMaybe<MetadataLayoutPictureEditingNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.archive" relation. */
  upsert?: InputMaybe<MetadataLayoutPictureEditingNestedUpsertArchiveUpdateInput>;
};

export type MetadataLayoutPictureEditingNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.metadata" relation. */
  update?: InputMaybe<MetadataLayoutPictureEditingNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.metadata" relation. */
  upsert?: InputMaybe<MetadataLayoutPictureEditingNestedUpsertMetadataCreateInput>;
};

export type MetadataLayoutPictureEditingNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.metadata" relation. */
  update?: InputMaybe<MetadataLayoutPictureEditingNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.metadata" relation. */
  upsert?: InputMaybe<MetadataLayoutPictureEditingNestedUpsertMetadataUpdateInput>;
};

export type MetadataLayoutPictureEditingNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.photo" relation. */
  update?: InputMaybe<MetadataLayoutPictureEditingNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.photo" relation. */
  upsert?: InputMaybe<MetadataLayoutPictureEditingNestedUpsertPhotoCreateInput>;
};

export type MetadataLayoutPictureEditingNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.photo" relation. */
  update?: InputMaybe<MetadataLayoutPictureEditingNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataLayoutPictureEditing" node, through the "MetadataLayoutPictureEditing.photo" relation. */
  upsert?: InputMaybe<MetadataLayoutPictureEditingNestedUpsertPhotoUpdateInput>;
};

export type MetadataLayoutPictureEditingNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLayoutPictureEditingNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLayoutPictureEditingNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLayoutPictureEditingNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLayoutPictureEditingNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLayoutPictureEditingNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLayoutPictureEditingNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLayoutPictureEditingNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLayoutPictureEditingNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLayoutPictureEditingNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLayoutPictureEditingNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLayoutPictureEditingNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataLayoutPictureEditingOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataLayoutPictureEditingUpdateInput = {
  /** Actions for the "MetadataLayoutPictureEditing.archive" relation */
  archive?: InputMaybe<MetadataLayoutPictureEditingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLayoutPictureEditing.metadata" relation */
  metadata?: InputMaybe<MetadataLayoutPictureEditingNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLayoutPictureEditing.photo" relation */
  photo?: InputMaybe<MetadataLayoutPictureEditingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataLayoutPictureEditing" nodes by specifying some conditions */
export type MetadataLayoutPictureEditingWhereInput = {
  AND?: InputMaybe<Array<MetadataLayoutPictureEditingWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
  OR?: InputMaybe<Array<MetadataLayoutPictureEditingWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataLayoutPictureEditing" node. */
export type MetadataLayoutPictureEditingWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataLayoutPictureEditingWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataLayoutPictureEditing.metadata" relation */
  metadata: MetadataLayoutPictureEditingNestedMetadataCreateInput;
  /** Actions for the "MetadataLayoutPictureEditing.photo" relation */
  photo: MetadataLayoutPictureEditingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutPictureEditingWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataLayoutPictureEditing.metadata" relation */
  metadata?: InputMaybe<MetadataLayoutPictureEditingNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLayoutPictureEditing.photo" relation */
  photo?: InputMaybe<MetadataLayoutPictureEditingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutPictureEditingWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataLayoutPictureEditing.archive" relation */
  archive: MetadataLayoutPictureEditingNestedArchiveCreateInput;
  /** Actions for the "MetadataLayoutPictureEditing.photo" relation */
  photo: MetadataLayoutPictureEditingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutPictureEditingWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataLayoutPictureEditing.archive" relation */
  archive?: InputMaybe<MetadataLayoutPictureEditingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLayoutPictureEditing.photo" relation */
  photo?: InputMaybe<MetadataLayoutPictureEditingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutPictureEditingWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataLayoutPictureEditing.archive" relation */
  archive: MetadataLayoutPictureEditingNestedArchiveCreateInput;
  /** Actions for the "MetadataLayoutPictureEditing.metadata" relation */
  metadata: MetadataLayoutPictureEditingNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutPictureEditingWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataLayoutPictureEditing.archive" relation */
  archive?: InputMaybe<MetadataLayoutPictureEditingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLayoutPictureEditing.metadata" relation */
  metadata?: InputMaybe<MetadataLayoutPictureEditingNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataLayoutStorage" resource's node */
export type MetadataLayoutStorage = {
  __typename?: 'MetadataLayoutStorage';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataLayoutStorageCreateInput = {
  /** Actions for the "MetadataLayoutStorage.archive" relation */
  archive: MetadataLayoutStorageNestedArchiveCreateInput;
  /** Actions for the "MetadataLayoutStorage.metadata" relation */
  metadata: MetadataLayoutStorageNestedMetadataCreateInput;
  /** Actions for the "MetadataLayoutStorage.photo" relation */
  photo: MetadataLayoutStorageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutStorageNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.archive" relation. */
  update?: InputMaybe<MetadataLayoutStorageNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.archive" relation. */
  upsert?: InputMaybe<MetadataLayoutStorageNestedUpsertArchiveCreateInput>;
};

export type MetadataLayoutStorageNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.archive" relation. */
  update?: InputMaybe<MetadataLayoutStorageNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.archive" relation. */
  upsert?: InputMaybe<MetadataLayoutStorageNestedUpsertArchiveUpdateInput>;
};

export type MetadataLayoutStorageNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.metadata" relation. */
  update?: InputMaybe<MetadataLayoutStorageNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.metadata" relation. */
  upsert?: InputMaybe<MetadataLayoutStorageNestedUpsertMetadataCreateInput>;
};

export type MetadataLayoutStorageNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.metadata" relation. */
  update?: InputMaybe<MetadataLayoutStorageNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.metadata" relation. */
  upsert?: InputMaybe<MetadataLayoutStorageNestedUpsertMetadataUpdateInput>;
};

export type MetadataLayoutStorageNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.photo" relation. */
  update?: InputMaybe<MetadataLayoutStorageNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.photo" relation. */
  upsert?: InputMaybe<MetadataLayoutStorageNestedUpsertPhotoCreateInput>;
};

export type MetadataLayoutStorageNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.photo" relation. */
  update?: InputMaybe<MetadataLayoutStorageNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataLayoutStorage" node, through the "MetadataLayoutStorage.photo" relation. */
  upsert?: InputMaybe<MetadataLayoutStorageNestedUpsertPhotoUpdateInput>;
};

export type MetadataLayoutStorageNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLayoutStorageNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLayoutStorageNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLayoutStorageNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLayoutStorageNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLayoutStorageNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLayoutStorageNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLayoutStorageNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLayoutStorageNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLayoutStorageNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLayoutStorageNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLayoutStorageNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataLayoutStorageOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataLayoutStorageUpdateInput = {
  /** Actions for the "MetadataLayoutStorage.archive" relation */
  archive?: InputMaybe<MetadataLayoutStorageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLayoutStorage.metadata" relation */
  metadata?: InputMaybe<MetadataLayoutStorageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLayoutStorage.photo" relation */
  photo?: InputMaybe<MetadataLayoutStorageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataLayoutStorage" nodes by specifying some conditions */
export type MetadataLayoutStorageWhereInput = {
  AND?: InputMaybe<Array<MetadataLayoutStorageWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataLayoutStorageWhereInput>;
  OR?: InputMaybe<Array<MetadataLayoutStorageWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataLayoutStorage" node. */
export type MetadataLayoutStorageWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataLayoutStorageWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataLayoutStorage.metadata" relation */
  metadata: MetadataLayoutStorageNestedMetadataCreateInput;
  /** Actions for the "MetadataLayoutStorage.photo" relation */
  photo: MetadataLayoutStorageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutStorageWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataLayoutStorage.metadata" relation */
  metadata?: InputMaybe<MetadataLayoutStorageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLayoutStorage.photo" relation */
  photo?: InputMaybe<MetadataLayoutStorageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutStorageWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataLayoutStorage.archive" relation */
  archive: MetadataLayoutStorageNestedArchiveCreateInput;
  /** Actions for the "MetadataLayoutStorage.photo" relation */
  photo: MetadataLayoutStorageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutStorageWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataLayoutStorage.archive" relation */
  archive?: InputMaybe<MetadataLayoutStorageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLayoutStorage.photo" relation */
  photo?: InputMaybe<MetadataLayoutStorageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutStorageWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataLayoutStorage.archive" relation */
  archive: MetadataLayoutStorageNestedArchiveCreateInput;
  /** Actions for the "MetadataLayoutStorage.metadata" relation */
  metadata: MetadataLayoutStorageNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLayoutStorageWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataLayoutStorage.archive" relation */
  archive?: InputMaybe<MetadataLayoutStorageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLayoutStorage.metadata" relation */
  metadata?: InputMaybe<MetadataLayoutStorageNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataLicense" resource's node */
export type MetadataLicense = {
  __typename?: 'MetadataLicense';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataLicenseCreateInput = {
  /** Actions for the "MetadataLicense.archive" relation */
  archive: MetadataLicenseNestedArchiveCreateInput;
  /** Actions for the "MetadataLicense.metadata" relation */
  metadata: MetadataLicenseNestedMetadataCreateInput;
  /** Actions for the "MetadataLicense.photo" relation */
  photo: MetadataLicenseNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLicenseNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataLicense" node, through the "MetadataLicense.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataLicense" node, through the "MetadataLicense.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataLicense" node, through the "MetadataLicense.archive" relation. */
  update?: InputMaybe<MetadataLicenseNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataLicense" node, through the "MetadataLicense.archive" relation. */
  upsert?: InputMaybe<MetadataLicenseNestedUpsertArchiveCreateInput>;
};

export type MetadataLicenseNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataLicense" node, through the "MetadataLicense.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataLicense" node, through the "MetadataLicense.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataLicense" node, through the "MetadataLicense.archive" relation. */
  update?: InputMaybe<MetadataLicenseNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataLicense" node, through the "MetadataLicense.archive" relation. */
  upsert?: InputMaybe<MetadataLicenseNestedUpsertArchiveUpdateInput>;
};

export type MetadataLicenseNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataLicense" node, through the "MetadataLicense.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataLicense" node, through the "MetadataLicense.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataLicense" node, through the "MetadataLicense.metadata" relation. */
  update?: InputMaybe<MetadataLicenseNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataLicense" node, through the "MetadataLicense.metadata" relation. */
  upsert?: InputMaybe<MetadataLicenseNestedUpsertMetadataCreateInput>;
};

export type MetadataLicenseNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataLicense" node, through the "MetadataLicense.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataLicense" node, through the "MetadataLicense.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataLicense" node, through the "MetadataLicense.metadata" relation. */
  update?: InputMaybe<MetadataLicenseNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataLicense" node, through the "MetadataLicense.metadata" relation. */
  upsert?: InputMaybe<MetadataLicenseNestedUpsertMetadataUpdateInput>;
};

export type MetadataLicenseNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataLicense" node, through the "MetadataLicense.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataLicense" node, through the "MetadataLicense.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataLicense" node, through the "MetadataLicense.photo" relation. */
  update?: InputMaybe<MetadataLicenseNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataLicense" node, through the "MetadataLicense.photo" relation. */
  upsert?: InputMaybe<MetadataLicenseNestedUpsertPhotoCreateInput>;
};

export type MetadataLicenseNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataLicense" node, through the "MetadataLicense.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataLicense" node, through the "MetadataLicense.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataLicense" node, through the "MetadataLicense.photo" relation. */
  update?: InputMaybe<MetadataLicenseNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataLicense" node, through the "MetadataLicense.photo" relation. */
  upsert?: InputMaybe<MetadataLicenseNestedUpsertPhotoUpdateInput>;
};

export type MetadataLicenseNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLicenseNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLicenseNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLicenseNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLicenseNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLicenseNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLicenseNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLicenseNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLicenseNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLicenseNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLicenseNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLicenseNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataLicenseOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataLicenseUpdateInput = {
  /** Actions for the "MetadataLicense.archive" relation */
  archive?: InputMaybe<MetadataLicenseNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLicense.metadata" relation */
  metadata?: InputMaybe<MetadataLicenseNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLicense.photo" relation */
  photo?: InputMaybe<MetadataLicenseNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataLicense" nodes by specifying some conditions */
export type MetadataLicenseWhereInput = {
  AND?: InputMaybe<Array<MetadataLicenseWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataLicenseWhereInput>;
  OR?: InputMaybe<Array<MetadataLicenseWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataLicense" node. */
export type MetadataLicenseWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataLicenseWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataLicense.metadata" relation */
  metadata: MetadataLicenseNestedMetadataCreateInput;
  /** Actions for the "MetadataLicense.photo" relation */
  photo: MetadataLicenseNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLicenseWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataLicense.metadata" relation */
  metadata?: InputMaybe<MetadataLicenseNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLicense.photo" relation */
  photo?: InputMaybe<MetadataLicenseNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLicenseWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataLicense.archive" relation */
  archive: MetadataLicenseNestedArchiveCreateInput;
  /** Actions for the "MetadataLicense.photo" relation */
  photo: MetadataLicenseNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLicenseWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataLicense.archive" relation */
  archive?: InputMaybe<MetadataLicenseNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLicense.photo" relation */
  photo?: InputMaybe<MetadataLicenseNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLicenseWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataLicense.archive" relation */
  archive: MetadataLicenseNestedArchiveCreateInput;
  /** Actions for the "MetadataLicense.metadata" relation */
  metadata: MetadataLicenseNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLicenseWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataLicense.archive" relation */
  archive?: InputMaybe<MetadataLicenseNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLicense.metadata" relation */
  metadata?: InputMaybe<MetadataLicenseNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataLinks" resource's node */
export type MetadataLinks = {
  __typename?: 'MetadataLinks';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataLinksCreateInput = {
  /** Actions for the "MetadataLinks.archive" relation */
  archive: MetadataLinksNestedArchiveCreateInput;
  /** Actions for the "MetadataLinks.metadata" relation */
  metadata: MetadataLinksNestedMetadataCreateInput;
  /** Actions for the "MetadataLinks.photo" relation */
  photo: MetadataLinksNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLinksNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataLinks" node, through the "MetadataLinks.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataLinks" node, through the "MetadataLinks.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataLinks" node, through the "MetadataLinks.archive" relation. */
  update?: InputMaybe<MetadataLinksNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataLinks" node, through the "MetadataLinks.archive" relation. */
  upsert?: InputMaybe<MetadataLinksNestedUpsertArchiveCreateInput>;
};

export type MetadataLinksNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataLinks" node, through the "MetadataLinks.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataLinks" node, through the "MetadataLinks.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataLinks" node, through the "MetadataLinks.archive" relation. */
  update?: InputMaybe<MetadataLinksNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataLinks" node, through the "MetadataLinks.archive" relation. */
  upsert?: InputMaybe<MetadataLinksNestedUpsertArchiveUpdateInput>;
};

export type MetadataLinksNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataLinks" node, through the "MetadataLinks.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataLinks" node, through the "MetadataLinks.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataLinks" node, through the "MetadataLinks.metadata" relation. */
  update?: InputMaybe<MetadataLinksNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataLinks" node, through the "MetadataLinks.metadata" relation. */
  upsert?: InputMaybe<MetadataLinksNestedUpsertMetadataCreateInput>;
};

export type MetadataLinksNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataLinks" node, through the "MetadataLinks.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataLinks" node, through the "MetadataLinks.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataLinks" node, through the "MetadataLinks.metadata" relation. */
  update?: InputMaybe<MetadataLinksNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataLinks" node, through the "MetadataLinks.metadata" relation. */
  upsert?: InputMaybe<MetadataLinksNestedUpsertMetadataUpdateInput>;
};

export type MetadataLinksNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataLinks" node, through the "MetadataLinks.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataLinks" node, through the "MetadataLinks.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataLinks" node, through the "MetadataLinks.photo" relation. */
  update?: InputMaybe<MetadataLinksNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataLinks" node, through the "MetadataLinks.photo" relation. */
  upsert?: InputMaybe<MetadataLinksNestedUpsertPhotoCreateInput>;
};

export type MetadataLinksNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataLinks" node, through the "MetadataLinks.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataLinks" node, through the "MetadataLinks.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataLinks" node, through the "MetadataLinks.photo" relation. */
  update?: InputMaybe<MetadataLinksNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataLinks" node, through the "MetadataLinks.photo" relation. */
  upsert?: InputMaybe<MetadataLinksNestedUpsertPhotoUpdateInput>;
};

export type MetadataLinksNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLinksNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLinksNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLinksNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLinksNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLinksNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLinksNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLinksNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLinksNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLinksNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLinksNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLinksNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataLinksOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataLinksUpdateInput = {
  /** Actions for the "MetadataLinks.archive" relation */
  archive?: InputMaybe<MetadataLinksNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLinks.metadata" relation */
  metadata?: InputMaybe<MetadataLinksNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLinks.photo" relation */
  photo?: InputMaybe<MetadataLinksNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataLinks" nodes by specifying some conditions */
export type MetadataLinksWhereInput = {
  AND?: InputMaybe<Array<MetadataLinksWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataLinksWhereInput>;
  OR?: InputMaybe<Array<MetadataLinksWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataLinks" node. */
export type MetadataLinksWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataLinksWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataLinks.metadata" relation */
  metadata: MetadataLinksNestedMetadataCreateInput;
  /** Actions for the "MetadataLinks.photo" relation */
  photo: MetadataLinksNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLinksWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataLinks.metadata" relation */
  metadata?: InputMaybe<MetadataLinksNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLinks.photo" relation */
  photo?: InputMaybe<MetadataLinksNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLinksWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataLinks.archive" relation */
  archive: MetadataLinksNestedArchiveCreateInput;
  /** Actions for the "MetadataLinks.photo" relation */
  photo: MetadataLinksNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLinksWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataLinks.archive" relation */
  archive?: InputMaybe<MetadataLinksNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLinks.photo" relation */
  photo?: InputMaybe<MetadataLinksNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLinksWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataLinks.archive" relation */
  archive: MetadataLinksNestedArchiveCreateInput;
  /** Actions for the "MetadataLinks.metadata" relation */
  metadata: MetadataLinksNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLinksWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataLinks.archive" relation */
  archive?: InputMaybe<MetadataLinksNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLinks.metadata" relation */
  metadata?: InputMaybe<MetadataLinksNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataLocalCaption" resource's node */
export type MetadataLocalCaption = {
  __typename?: 'MetadataLocalCaption';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataLocalCaptionCreateInput = {
  /** Actions for the "MetadataLocalCaption.archive" relation */
  archive: MetadataLocalCaptionNestedArchiveCreateInput;
  /** Actions for the "MetadataLocalCaption.metadata" relation */
  metadata: MetadataLocalCaptionNestedMetadataCreateInput;
  /** Actions for the "MetadataLocalCaption.photo" relation */
  photo: MetadataLocalCaptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocalCaptionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.archive" relation. */
  update?: InputMaybe<MetadataLocalCaptionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.archive" relation. */
  upsert?: InputMaybe<MetadataLocalCaptionNestedUpsertArchiveCreateInput>;
};

export type MetadataLocalCaptionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.archive" relation. */
  update?: InputMaybe<MetadataLocalCaptionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.archive" relation. */
  upsert?: InputMaybe<MetadataLocalCaptionNestedUpsertArchiveUpdateInput>;
};

export type MetadataLocalCaptionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.metadata" relation. */
  update?: InputMaybe<MetadataLocalCaptionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.metadata" relation. */
  upsert?: InputMaybe<MetadataLocalCaptionNestedUpsertMetadataCreateInput>;
};

export type MetadataLocalCaptionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.metadata" relation. */
  update?: InputMaybe<MetadataLocalCaptionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.metadata" relation. */
  upsert?: InputMaybe<MetadataLocalCaptionNestedUpsertMetadataUpdateInput>;
};

export type MetadataLocalCaptionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.photo" relation. */
  update?: InputMaybe<MetadataLocalCaptionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataLocalCaption" node, through the "MetadataLocalCaption.photo" relation. */
  upsert?: InputMaybe<MetadataLocalCaptionNestedUpsertPhotoCreateInput>;
};

export type MetadataLocalCaptionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.photo" relation. */
  update?: InputMaybe<MetadataLocalCaptionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataLocalCaption" node, through the "MetadataLocalCaption.photo" relation. */
  upsert?: InputMaybe<MetadataLocalCaptionNestedUpsertPhotoUpdateInput>;
};

export type MetadataLocalCaptionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLocalCaptionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLocalCaptionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLocalCaptionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLocalCaptionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLocalCaptionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLocalCaptionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLocalCaptionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLocalCaptionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLocalCaptionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLocalCaptionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLocalCaptionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataLocalCaptionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataLocalCaptionUpdateInput = {
  /** Actions for the "MetadataLocalCaption.archive" relation */
  archive?: InputMaybe<MetadataLocalCaptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLocalCaption.metadata" relation */
  metadata?: InputMaybe<MetadataLocalCaptionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLocalCaption.photo" relation */
  photo?: InputMaybe<MetadataLocalCaptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataLocalCaption" nodes by specifying some conditions */
export type MetadataLocalCaptionWhereInput = {
  AND?: InputMaybe<Array<MetadataLocalCaptionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataLocalCaptionWhereInput>;
  OR?: InputMaybe<Array<MetadataLocalCaptionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataLocalCaption" node. */
export type MetadataLocalCaptionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataLocalCaptionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataLocalCaption.metadata" relation */
  metadata: MetadataLocalCaptionNestedMetadataCreateInput;
  /** Actions for the "MetadataLocalCaption.photo" relation */
  photo: MetadataLocalCaptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocalCaptionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataLocalCaption.metadata" relation */
  metadata?: InputMaybe<MetadataLocalCaptionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLocalCaption.photo" relation */
  photo?: InputMaybe<MetadataLocalCaptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocalCaptionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataLocalCaption.archive" relation */
  archive: MetadataLocalCaptionNestedArchiveCreateInput;
  /** Actions for the "MetadataLocalCaption.photo" relation */
  photo: MetadataLocalCaptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocalCaptionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataLocalCaption.archive" relation */
  archive?: InputMaybe<MetadataLocalCaptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLocalCaption.photo" relation */
  photo?: InputMaybe<MetadataLocalCaptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocalCaptionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataLocalCaption.archive" relation */
  archive: MetadataLocalCaptionNestedArchiveCreateInput;
  /** Actions for the "MetadataLocalCaption.metadata" relation */
  metadata: MetadataLocalCaptionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocalCaptionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataLocalCaption.archive" relation */
  archive?: InputMaybe<MetadataLocalCaptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLocalCaption.metadata" relation */
  metadata?: InputMaybe<MetadataLocalCaptionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataLocation" resource's node */
export type MetadataLocation = {
  __typename?: 'MetadataLocation';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataLocationCreateInput = {
  /** Actions for the "MetadataLocation.archive" relation */
  archive: MetadataLocationNestedArchiveCreateInput;
  /** Actions for the "MetadataLocation.metadata" relation */
  metadata: MetadataLocationNestedMetadataCreateInput;
  /** Actions for the "MetadataLocation.photo" relation */
  photo: MetadataLocationNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocationNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataLocation" node, through the "MetadataLocation.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataLocation" node, through the "MetadataLocation.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataLocation" node, through the "MetadataLocation.archive" relation. */
  update?: InputMaybe<MetadataLocationNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataLocation" node, through the "MetadataLocation.archive" relation. */
  upsert?: InputMaybe<MetadataLocationNestedUpsertArchiveCreateInput>;
};

export type MetadataLocationNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataLocation" node, through the "MetadataLocation.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataLocation" node, through the "MetadataLocation.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataLocation" node, through the "MetadataLocation.archive" relation. */
  update?: InputMaybe<MetadataLocationNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataLocation" node, through the "MetadataLocation.archive" relation. */
  upsert?: InputMaybe<MetadataLocationNestedUpsertArchiveUpdateInput>;
};

export type MetadataLocationNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataLocation" node, through the "MetadataLocation.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataLocation" node, through the "MetadataLocation.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataLocation" node, through the "MetadataLocation.metadata" relation. */
  update?: InputMaybe<MetadataLocationNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataLocation" node, through the "MetadataLocation.metadata" relation. */
  upsert?: InputMaybe<MetadataLocationNestedUpsertMetadataCreateInput>;
};

export type MetadataLocationNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataLocation" node, through the "MetadataLocation.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataLocation" node, through the "MetadataLocation.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataLocation" node, through the "MetadataLocation.metadata" relation. */
  update?: InputMaybe<MetadataLocationNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataLocation" node, through the "MetadataLocation.metadata" relation. */
  upsert?: InputMaybe<MetadataLocationNestedUpsertMetadataUpdateInput>;
};

export type MetadataLocationNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataLocation" node, through the "MetadataLocation.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataLocation" node, through the "MetadataLocation.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataLocation" node, through the "MetadataLocation.photo" relation. */
  update?: InputMaybe<MetadataLocationNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataLocation" node, through the "MetadataLocation.photo" relation. */
  upsert?: InputMaybe<MetadataLocationNestedUpsertPhotoCreateInput>;
};

export type MetadataLocationNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataLocation" node, through the "MetadataLocation.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataLocation" node, through the "MetadataLocation.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataLocation" node, through the "MetadataLocation.photo" relation. */
  update?: InputMaybe<MetadataLocationNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataLocation" node, through the "MetadataLocation.photo" relation. */
  upsert?: InputMaybe<MetadataLocationNestedUpsertPhotoUpdateInput>;
};

export type MetadataLocationNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLocationNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLocationNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLocationNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLocationNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLocationNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLocationNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLocationNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataLocationNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLocationNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataLocationNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataLocationNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataLocationOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataLocationUpdateInput = {
  /** Actions for the "MetadataLocation.archive" relation */
  archive?: InputMaybe<MetadataLocationNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLocation.metadata" relation */
  metadata?: InputMaybe<MetadataLocationNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLocation.photo" relation */
  photo?: InputMaybe<MetadataLocationNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataLocation" nodes by specifying some conditions */
export type MetadataLocationWhereInput = {
  AND?: InputMaybe<Array<MetadataLocationWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataLocationWhereInput>;
  OR?: InputMaybe<Array<MetadataLocationWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataLocation" node. */
export type MetadataLocationWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataLocationWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataLocation.metadata" relation */
  metadata: MetadataLocationNestedMetadataCreateInput;
  /** Actions for the "MetadataLocation.photo" relation */
  photo: MetadataLocationNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocationWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataLocation.metadata" relation */
  metadata?: InputMaybe<MetadataLocationNestedMetadataUpdateInput>;
  /** Actions for the "MetadataLocation.photo" relation */
  photo?: InputMaybe<MetadataLocationNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocationWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataLocation.archive" relation */
  archive: MetadataLocationNestedArchiveCreateInput;
  /** Actions for the "MetadataLocation.photo" relation */
  photo: MetadataLocationNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocationWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataLocation.archive" relation */
  archive?: InputMaybe<MetadataLocationNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLocation.photo" relation */
  photo?: InputMaybe<MetadataLocationNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocationWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataLocation.archive" relation */
  archive: MetadataLocationNestedArchiveCreateInput;
  /** Actions for the "MetadataLocation.metadata" relation */
  metadata: MetadataLocationNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataLocationWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataLocation.archive" relation */
  archive?: InputMaybe<MetadataLocationNestedArchiveUpdateInput>;
  /** Actions for the "MetadataLocation.metadata" relation */
  metadata?: InputMaybe<MetadataLocationNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataMake" resource's node */
export type MetadataMake = {
  __typename?: 'MetadataMake';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataMakeCreateInput = {
  /** Actions for the "MetadataMake.archive" relation */
  archive: MetadataMakeNestedArchiveCreateInput;
  /** Actions for the "MetadataMake.metadata" relation */
  metadata: MetadataMakeNestedMetadataCreateInput;
  /** Actions for the "MetadataMake.photo" relation */
  photo: MetadataMakeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMakeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataMake" node, through the "MetadataMake.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataMake" node, through the "MetadataMake.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataMake" node, through the "MetadataMake.archive" relation. */
  update?: InputMaybe<MetadataMakeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataMake" node, through the "MetadataMake.archive" relation. */
  upsert?: InputMaybe<MetadataMakeNestedUpsertArchiveCreateInput>;
};

export type MetadataMakeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataMake" node, through the "MetadataMake.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataMake" node, through the "MetadataMake.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataMake" node, through the "MetadataMake.archive" relation. */
  update?: InputMaybe<MetadataMakeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataMake" node, through the "MetadataMake.archive" relation. */
  upsert?: InputMaybe<MetadataMakeNestedUpsertArchiveUpdateInput>;
};

export type MetadataMakeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataMake" node, through the "MetadataMake.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataMake" node, through the "MetadataMake.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataMake" node, through the "MetadataMake.metadata" relation. */
  update?: InputMaybe<MetadataMakeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataMake" node, through the "MetadataMake.metadata" relation. */
  upsert?: InputMaybe<MetadataMakeNestedUpsertMetadataCreateInput>;
};

export type MetadataMakeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataMake" node, through the "MetadataMake.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataMake" node, through the "MetadataMake.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataMake" node, through the "MetadataMake.metadata" relation. */
  update?: InputMaybe<MetadataMakeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataMake" node, through the "MetadataMake.metadata" relation. */
  upsert?: InputMaybe<MetadataMakeNestedUpsertMetadataUpdateInput>;
};

export type MetadataMakeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataMake" node, through the "MetadataMake.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataMake" node, through the "MetadataMake.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataMake" node, through the "MetadataMake.photo" relation. */
  update?: InputMaybe<MetadataMakeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataMake" node, through the "MetadataMake.photo" relation. */
  upsert?: InputMaybe<MetadataMakeNestedUpsertPhotoCreateInput>;
};

export type MetadataMakeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataMake" node, through the "MetadataMake.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataMake" node, through the "MetadataMake.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataMake" node, through the "MetadataMake.photo" relation. */
  update?: InputMaybe<MetadataMakeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataMake" node, through the "MetadataMake.photo" relation. */
  upsert?: InputMaybe<MetadataMakeNestedUpsertPhotoUpdateInput>;
};

export type MetadataMakeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMakeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMakeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMakeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMakeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMakeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMakeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMakeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMakeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMakeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMakeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMakeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataMakeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataMakeUpdateInput = {
  /** Actions for the "MetadataMake.archive" relation */
  archive?: InputMaybe<MetadataMakeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMake.metadata" relation */
  metadata?: InputMaybe<MetadataMakeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMake.photo" relation */
  photo?: InputMaybe<MetadataMakeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataMake" nodes by specifying some conditions */
export type MetadataMakeWhereInput = {
  AND?: InputMaybe<Array<MetadataMakeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataMakeWhereInput>;
  OR?: InputMaybe<Array<MetadataMakeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataMake" node. */
export type MetadataMakeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataMakeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataMake.metadata" relation */
  metadata: MetadataMakeNestedMetadataCreateInput;
  /** Actions for the "MetadataMake.photo" relation */
  photo: MetadataMakeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMakeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataMake.metadata" relation */
  metadata?: InputMaybe<MetadataMakeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMake.photo" relation */
  photo?: InputMaybe<MetadataMakeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMakeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataMake.archive" relation */
  archive: MetadataMakeNestedArchiveCreateInput;
  /** Actions for the "MetadataMake.photo" relation */
  photo: MetadataMakeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMakeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataMake.archive" relation */
  archive?: InputMaybe<MetadataMakeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMake.photo" relation */
  photo?: InputMaybe<MetadataMakeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMakeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataMake.archive" relation */
  archive: MetadataMakeNestedArchiveCreateInput;
  /** Actions for the "MetadataMake.metadata" relation */
  metadata: MetadataMakeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMakeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataMake.archive" relation */
  archive?: InputMaybe<MetadataMakeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMake.metadata" relation */
  metadata?: InputMaybe<MetadataMakeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataManifest" resource's node */
export type MetadataManifest = {
  __typename?: 'MetadataManifest';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataManifestCreateInput = {
  /** Actions for the "MetadataManifest.archive" relation */
  archive: MetadataManifestNestedArchiveCreateInput;
  /** Actions for the "MetadataManifest.metadata" relation */
  metadata: MetadataManifestNestedMetadataCreateInput;
  /** Actions for the "MetadataManifest.photo" relation */
  photo: MetadataManifestNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataManifestNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataManifest" node, through the "MetadataManifest.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataManifest" node, through the "MetadataManifest.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataManifest" node, through the "MetadataManifest.archive" relation. */
  update?: InputMaybe<MetadataManifestNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataManifest" node, through the "MetadataManifest.archive" relation. */
  upsert?: InputMaybe<MetadataManifestNestedUpsertArchiveCreateInput>;
};

export type MetadataManifestNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataManifest" node, through the "MetadataManifest.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataManifest" node, through the "MetadataManifest.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataManifest" node, through the "MetadataManifest.archive" relation. */
  update?: InputMaybe<MetadataManifestNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataManifest" node, through the "MetadataManifest.archive" relation. */
  upsert?: InputMaybe<MetadataManifestNestedUpsertArchiveUpdateInput>;
};

export type MetadataManifestNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataManifest" node, through the "MetadataManifest.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataManifest" node, through the "MetadataManifest.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataManifest" node, through the "MetadataManifest.metadata" relation. */
  update?: InputMaybe<MetadataManifestNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataManifest" node, through the "MetadataManifest.metadata" relation. */
  upsert?: InputMaybe<MetadataManifestNestedUpsertMetadataCreateInput>;
};

export type MetadataManifestNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataManifest" node, through the "MetadataManifest.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataManifest" node, through the "MetadataManifest.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataManifest" node, through the "MetadataManifest.metadata" relation. */
  update?: InputMaybe<MetadataManifestNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataManifest" node, through the "MetadataManifest.metadata" relation. */
  upsert?: InputMaybe<MetadataManifestNestedUpsertMetadataUpdateInput>;
};

export type MetadataManifestNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataManifest" node, through the "MetadataManifest.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataManifest" node, through the "MetadataManifest.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataManifest" node, through the "MetadataManifest.photo" relation. */
  update?: InputMaybe<MetadataManifestNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataManifest" node, through the "MetadataManifest.photo" relation. */
  upsert?: InputMaybe<MetadataManifestNestedUpsertPhotoCreateInput>;
};

export type MetadataManifestNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataManifest" node, through the "MetadataManifest.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataManifest" node, through the "MetadataManifest.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataManifest" node, through the "MetadataManifest.photo" relation. */
  update?: InputMaybe<MetadataManifestNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataManifest" node, through the "MetadataManifest.photo" relation. */
  upsert?: InputMaybe<MetadataManifestNestedUpsertPhotoUpdateInput>;
};

export type MetadataManifestNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataManifestNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataManifestNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataManifestNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataManifestNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataManifestNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataManifestNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataManifestNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataManifestNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataManifestNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataManifestNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataManifestNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataManifestOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataManifestUpdateInput = {
  /** Actions for the "MetadataManifest.archive" relation */
  archive?: InputMaybe<MetadataManifestNestedArchiveUpdateInput>;
  /** Actions for the "MetadataManifest.metadata" relation */
  metadata?: InputMaybe<MetadataManifestNestedMetadataUpdateInput>;
  /** Actions for the "MetadataManifest.photo" relation */
  photo?: InputMaybe<MetadataManifestNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataManifest" nodes by specifying some conditions */
export type MetadataManifestWhereInput = {
  AND?: InputMaybe<Array<MetadataManifestWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataManifestWhereInput>;
  OR?: InputMaybe<Array<MetadataManifestWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataManifest" node. */
export type MetadataManifestWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataManifestWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataManifest.metadata" relation */
  metadata: MetadataManifestNestedMetadataCreateInput;
  /** Actions for the "MetadataManifest.photo" relation */
  photo: MetadataManifestNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataManifestWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataManifest.metadata" relation */
  metadata?: InputMaybe<MetadataManifestNestedMetadataUpdateInput>;
  /** Actions for the "MetadataManifest.photo" relation */
  photo?: InputMaybe<MetadataManifestNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataManifestWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataManifest.archive" relation */
  archive: MetadataManifestNestedArchiveCreateInput;
  /** Actions for the "MetadataManifest.photo" relation */
  photo: MetadataManifestNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataManifestWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataManifest.archive" relation */
  archive?: InputMaybe<MetadataManifestNestedArchiveUpdateInput>;
  /** Actions for the "MetadataManifest.photo" relation */
  photo?: InputMaybe<MetadataManifestNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataManifestWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataManifest.archive" relation */
  archive: MetadataManifestNestedArchiveCreateInput;
  /** Actions for the "MetadataManifest.metadata" relation */
  metadata: MetadataManifestNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataManifestWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataManifest.archive" relation */
  archive?: InputMaybe<MetadataManifestNestedArchiveUpdateInput>;
  /** Actions for the "MetadataManifest.metadata" relation */
  metadata?: InputMaybe<MetadataManifestNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataMarked" resource's node */
export type MetadataMarked = {
  __typename?: 'MetadataMarked';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataMarkedCreateInput = {
  /** Actions for the "MetadataMarked.archive" relation */
  archive: MetadataMarkedNestedArchiveCreateInput;
  /** Actions for the "MetadataMarked.metadata" relation */
  metadata: MetadataMarkedNestedMetadataCreateInput;
  /** Actions for the "MetadataMarked.photo" relation */
  photo: MetadataMarkedNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMarkedNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataMarked" node, through the "MetadataMarked.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataMarked" node, through the "MetadataMarked.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataMarked" node, through the "MetadataMarked.archive" relation. */
  update?: InputMaybe<MetadataMarkedNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataMarked" node, through the "MetadataMarked.archive" relation. */
  upsert?: InputMaybe<MetadataMarkedNestedUpsertArchiveCreateInput>;
};

export type MetadataMarkedNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataMarked" node, through the "MetadataMarked.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataMarked" node, through the "MetadataMarked.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataMarked" node, through the "MetadataMarked.archive" relation. */
  update?: InputMaybe<MetadataMarkedNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataMarked" node, through the "MetadataMarked.archive" relation. */
  upsert?: InputMaybe<MetadataMarkedNestedUpsertArchiveUpdateInput>;
};

export type MetadataMarkedNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataMarked" node, through the "MetadataMarked.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataMarked" node, through the "MetadataMarked.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataMarked" node, through the "MetadataMarked.metadata" relation. */
  update?: InputMaybe<MetadataMarkedNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataMarked" node, through the "MetadataMarked.metadata" relation. */
  upsert?: InputMaybe<MetadataMarkedNestedUpsertMetadataCreateInput>;
};

export type MetadataMarkedNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataMarked" node, through the "MetadataMarked.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataMarked" node, through the "MetadataMarked.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataMarked" node, through the "MetadataMarked.metadata" relation. */
  update?: InputMaybe<MetadataMarkedNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataMarked" node, through the "MetadataMarked.metadata" relation. */
  upsert?: InputMaybe<MetadataMarkedNestedUpsertMetadataUpdateInput>;
};

export type MetadataMarkedNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataMarked" node, through the "MetadataMarked.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataMarked" node, through the "MetadataMarked.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataMarked" node, through the "MetadataMarked.photo" relation. */
  update?: InputMaybe<MetadataMarkedNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataMarked" node, through the "MetadataMarked.photo" relation. */
  upsert?: InputMaybe<MetadataMarkedNestedUpsertPhotoCreateInput>;
};

export type MetadataMarkedNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataMarked" node, through the "MetadataMarked.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataMarked" node, through the "MetadataMarked.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataMarked" node, through the "MetadataMarked.photo" relation. */
  update?: InputMaybe<MetadataMarkedNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataMarked" node, through the "MetadataMarked.photo" relation. */
  upsert?: InputMaybe<MetadataMarkedNestedUpsertPhotoUpdateInput>;
};

export type MetadataMarkedNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMarkedNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMarkedNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMarkedNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMarkedNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMarkedNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMarkedNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMarkedNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMarkedNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMarkedNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMarkedNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMarkedNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataMarkedOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataMarkedUpdateInput = {
  /** Actions for the "MetadataMarked.archive" relation */
  archive?: InputMaybe<MetadataMarkedNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMarked.metadata" relation */
  metadata?: InputMaybe<MetadataMarkedNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMarked.photo" relation */
  photo?: InputMaybe<MetadataMarkedNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataMarked" nodes by specifying some conditions */
export type MetadataMarkedWhereInput = {
  AND?: InputMaybe<Array<MetadataMarkedWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataMarkedWhereInput>;
  OR?: InputMaybe<Array<MetadataMarkedWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataMarked" node. */
export type MetadataMarkedWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataMarkedWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataMarked.metadata" relation */
  metadata: MetadataMarkedNestedMetadataCreateInput;
  /** Actions for the "MetadataMarked.photo" relation */
  photo: MetadataMarkedNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMarkedWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataMarked.metadata" relation */
  metadata?: InputMaybe<MetadataMarkedNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMarked.photo" relation */
  photo?: InputMaybe<MetadataMarkedNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMarkedWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataMarked.archive" relation */
  archive: MetadataMarkedNestedArchiveCreateInput;
  /** Actions for the "MetadataMarked.photo" relation */
  photo: MetadataMarkedNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMarkedWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataMarked.archive" relation */
  archive?: InputMaybe<MetadataMarkedNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMarked.photo" relation */
  photo?: InputMaybe<MetadataMarkedNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMarkedWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataMarked.archive" relation */
  archive: MetadataMarkedNestedArchiveCreateInput;
  /** Actions for the "MetadataMarked.metadata" relation */
  metadata: MetadataMarkedNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMarkedWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataMarked.archive" relation */
  archive?: InputMaybe<MetadataMarkedNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMarked.metadata" relation */
  metadata?: InputMaybe<MetadataMarkedNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataMasterDocumentId" resource's node */
export type MetadataMasterDocumentId = {
  __typename?: 'MetadataMasterDocumentId';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataMasterDocumentIdCreateInput = {
  /** Actions for the "MetadataMasterDocumentId.archive" relation */
  archive: MetadataMasterDocumentIdNestedArchiveCreateInput;
  /** Actions for the "MetadataMasterDocumentId.metadata" relation */
  metadata: MetadataMasterDocumentIdNestedMetadataCreateInput;
  /** Actions for the "MetadataMasterDocumentId.photo" relation */
  photo: MetadataMasterDocumentIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMasterDocumentIdNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.archive" relation. */
  update?: InputMaybe<MetadataMasterDocumentIdNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.archive" relation. */
  upsert?: InputMaybe<MetadataMasterDocumentIdNestedUpsertArchiveCreateInput>;
};

export type MetadataMasterDocumentIdNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.archive" relation. */
  update?: InputMaybe<MetadataMasterDocumentIdNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.archive" relation. */
  upsert?: InputMaybe<MetadataMasterDocumentIdNestedUpsertArchiveUpdateInput>;
};

export type MetadataMasterDocumentIdNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.metadata" relation. */
  update?: InputMaybe<MetadataMasterDocumentIdNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.metadata" relation. */
  upsert?: InputMaybe<MetadataMasterDocumentIdNestedUpsertMetadataCreateInput>;
};

export type MetadataMasterDocumentIdNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.metadata" relation. */
  update?: InputMaybe<MetadataMasterDocumentIdNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.metadata" relation. */
  upsert?: InputMaybe<MetadataMasterDocumentIdNestedUpsertMetadataUpdateInput>;
};

export type MetadataMasterDocumentIdNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.photo" relation. */
  update?: InputMaybe<MetadataMasterDocumentIdNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.photo" relation. */
  upsert?: InputMaybe<MetadataMasterDocumentIdNestedUpsertPhotoCreateInput>;
};

export type MetadataMasterDocumentIdNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.photo" relation. */
  update?: InputMaybe<MetadataMasterDocumentIdNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataMasterDocumentId" node, through the "MetadataMasterDocumentId.photo" relation. */
  upsert?: InputMaybe<MetadataMasterDocumentIdNestedUpsertPhotoUpdateInput>;
};

export type MetadataMasterDocumentIdNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMasterDocumentIdNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMasterDocumentIdNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMasterDocumentIdNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMasterDocumentIdNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMasterDocumentIdNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMasterDocumentIdNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMasterDocumentIdNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMasterDocumentIdNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMasterDocumentIdNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMasterDocumentIdNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMasterDocumentIdNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataMasterDocumentIdOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataMasterDocumentIdUpdateInput = {
  /** Actions for the "MetadataMasterDocumentId.archive" relation */
  archive?: InputMaybe<MetadataMasterDocumentIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMasterDocumentId.metadata" relation */
  metadata?: InputMaybe<MetadataMasterDocumentIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMasterDocumentId.photo" relation */
  photo?: InputMaybe<MetadataMasterDocumentIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataMasterDocumentId" nodes by specifying some conditions */
export type MetadataMasterDocumentIdWhereInput = {
  AND?: InputMaybe<Array<MetadataMasterDocumentIdWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
  OR?: InputMaybe<Array<MetadataMasterDocumentIdWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataMasterDocumentId" node. */
export type MetadataMasterDocumentIdWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataMasterDocumentIdWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataMasterDocumentId.metadata" relation */
  metadata: MetadataMasterDocumentIdNestedMetadataCreateInput;
  /** Actions for the "MetadataMasterDocumentId.photo" relation */
  photo: MetadataMasterDocumentIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMasterDocumentIdWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataMasterDocumentId.metadata" relation */
  metadata?: InputMaybe<MetadataMasterDocumentIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMasterDocumentId.photo" relation */
  photo?: InputMaybe<MetadataMasterDocumentIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMasterDocumentIdWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataMasterDocumentId.archive" relation */
  archive: MetadataMasterDocumentIdNestedArchiveCreateInput;
  /** Actions for the "MetadataMasterDocumentId.photo" relation */
  photo: MetadataMasterDocumentIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMasterDocumentIdWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataMasterDocumentId.archive" relation */
  archive?: InputMaybe<MetadataMasterDocumentIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMasterDocumentId.photo" relation */
  photo?: InputMaybe<MetadataMasterDocumentIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMasterDocumentIdWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataMasterDocumentId.archive" relation */
  archive: MetadataMasterDocumentIdNestedArchiveCreateInput;
  /** Actions for the "MetadataMasterDocumentId.metadata" relation */
  metadata: MetadataMasterDocumentIdNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMasterDocumentIdWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataMasterDocumentId.archive" relation */
  archive?: InputMaybe<MetadataMasterDocumentIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMasterDocumentId.metadata" relation */
  metadata?: InputMaybe<MetadataMasterDocumentIdNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataMaxAvailHeight" resource's node */
export type MetadataMaxAvailHeight = {
  __typename?: 'MetadataMaxAvailHeight';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataMaxAvailHeightCreateInput = {
  /** Actions for the "MetadataMaxAvailHeight.archive" relation */
  archive: MetadataMaxAvailHeightNestedArchiveCreateInput;
  /** Actions for the "MetadataMaxAvailHeight.metadata" relation */
  metadata: MetadataMaxAvailHeightNestedMetadataCreateInput;
  /** Actions for the "MetadataMaxAvailHeight.photo" relation */
  photo: MetadataMaxAvailHeightNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailHeightNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.archive" relation. */
  update?: InputMaybe<MetadataMaxAvailHeightNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.archive" relation. */
  upsert?: InputMaybe<MetadataMaxAvailHeightNestedUpsertArchiveCreateInput>;
};

export type MetadataMaxAvailHeightNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.archive" relation. */
  update?: InputMaybe<MetadataMaxAvailHeightNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.archive" relation. */
  upsert?: InputMaybe<MetadataMaxAvailHeightNestedUpsertArchiveUpdateInput>;
};

export type MetadataMaxAvailHeightNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.metadata" relation. */
  update?: InputMaybe<MetadataMaxAvailHeightNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.metadata" relation. */
  upsert?: InputMaybe<MetadataMaxAvailHeightNestedUpsertMetadataCreateInput>;
};

export type MetadataMaxAvailHeightNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.metadata" relation. */
  update?: InputMaybe<MetadataMaxAvailHeightNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.metadata" relation. */
  upsert?: InputMaybe<MetadataMaxAvailHeightNestedUpsertMetadataUpdateInput>;
};

export type MetadataMaxAvailHeightNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.photo" relation. */
  update?: InputMaybe<MetadataMaxAvailHeightNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.photo" relation. */
  upsert?: InputMaybe<MetadataMaxAvailHeightNestedUpsertPhotoCreateInput>;
};

export type MetadataMaxAvailHeightNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.photo" relation. */
  update?: InputMaybe<MetadataMaxAvailHeightNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataMaxAvailHeight" node, through the "MetadataMaxAvailHeight.photo" relation. */
  upsert?: InputMaybe<MetadataMaxAvailHeightNestedUpsertPhotoUpdateInput>;
};

export type MetadataMaxAvailHeightNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMaxAvailHeightNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMaxAvailHeightNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMaxAvailHeightNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMaxAvailHeightNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMaxAvailHeightNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMaxAvailHeightNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMaxAvailHeightNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMaxAvailHeightNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMaxAvailHeightNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMaxAvailHeightNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMaxAvailHeightNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataMaxAvailHeightOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataMaxAvailHeightUpdateInput = {
  /** Actions for the "MetadataMaxAvailHeight.archive" relation */
  archive?: InputMaybe<MetadataMaxAvailHeightNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMaxAvailHeight.metadata" relation */
  metadata?: InputMaybe<MetadataMaxAvailHeightNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMaxAvailHeight.photo" relation */
  photo?: InputMaybe<MetadataMaxAvailHeightNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataMaxAvailHeight" nodes by specifying some conditions */
export type MetadataMaxAvailHeightWhereInput = {
  AND?: InputMaybe<Array<MetadataMaxAvailHeightWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
  OR?: InputMaybe<Array<MetadataMaxAvailHeightWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataMaxAvailHeight" node. */
export type MetadataMaxAvailHeightWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataMaxAvailHeightWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataMaxAvailHeight.metadata" relation */
  metadata: MetadataMaxAvailHeightNestedMetadataCreateInput;
  /** Actions for the "MetadataMaxAvailHeight.photo" relation */
  photo: MetadataMaxAvailHeightNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailHeightWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataMaxAvailHeight.metadata" relation */
  metadata?: InputMaybe<MetadataMaxAvailHeightNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMaxAvailHeight.photo" relation */
  photo?: InputMaybe<MetadataMaxAvailHeightNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailHeightWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataMaxAvailHeight.archive" relation */
  archive: MetadataMaxAvailHeightNestedArchiveCreateInput;
  /** Actions for the "MetadataMaxAvailHeight.photo" relation */
  photo: MetadataMaxAvailHeightNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailHeightWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataMaxAvailHeight.archive" relation */
  archive?: InputMaybe<MetadataMaxAvailHeightNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMaxAvailHeight.photo" relation */
  photo?: InputMaybe<MetadataMaxAvailHeightNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailHeightWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataMaxAvailHeight.archive" relation */
  archive: MetadataMaxAvailHeightNestedArchiveCreateInput;
  /** Actions for the "MetadataMaxAvailHeight.metadata" relation */
  metadata: MetadataMaxAvailHeightNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailHeightWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataMaxAvailHeight.archive" relation */
  archive?: InputMaybe<MetadataMaxAvailHeightNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMaxAvailHeight.metadata" relation */
  metadata?: InputMaybe<MetadataMaxAvailHeightNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataMaxAvailWidth" resource's node */
export type MetadataMaxAvailWidth = {
  __typename?: 'MetadataMaxAvailWidth';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataMaxAvailWidthCreateInput = {
  /** Actions for the "MetadataMaxAvailWidth.archive" relation */
  archive: MetadataMaxAvailWidthNestedArchiveCreateInput;
  /** Actions for the "MetadataMaxAvailWidth.metadata" relation */
  metadata: MetadataMaxAvailWidthNestedMetadataCreateInput;
  /** Actions for the "MetadataMaxAvailWidth.photo" relation */
  photo: MetadataMaxAvailWidthNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailWidthNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.archive" relation. */
  update?: InputMaybe<MetadataMaxAvailWidthNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.archive" relation. */
  upsert?: InputMaybe<MetadataMaxAvailWidthNestedUpsertArchiveCreateInput>;
};

export type MetadataMaxAvailWidthNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.archive" relation. */
  update?: InputMaybe<MetadataMaxAvailWidthNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.archive" relation. */
  upsert?: InputMaybe<MetadataMaxAvailWidthNestedUpsertArchiveUpdateInput>;
};

export type MetadataMaxAvailWidthNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.metadata" relation. */
  update?: InputMaybe<MetadataMaxAvailWidthNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.metadata" relation. */
  upsert?: InputMaybe<MetadataMaxAvailWidthNestedUpsertMetadataCreateInput>;
};

export type MetadataMaxAvailWidthNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.metadata" relation. */
  update?: InputMaybe<MetadataMaxAvailWidthNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.metadata" relation. */
  upsert?: InputMaybe<MetadataMaxAvailWidthNestedUpsertMetadataUpdateInput>;
};

export type MetadataMaxAvailWidthNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.photo" relation. */
  update?: InputMaybe<MetadataMaxAvailWidthNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.photo" relation. */
  upsert?: InputMaybe<MetadataMaxAvailWidthNestedUpsertPhotoCreateInput>;
};

export type MetadataMaxAvailWidthNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.photo" relation. */
  update?: InputMaybe<MetadataMaxAvailWidthNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataMaxAvailWidth" node, through the "MetadataMaxAvailWidth.photo" relation. */
  upsert?: InputMaybe<MetadataMaxAvailWidthNestedUpsertPhotoUpdateInput>;
};

export type MetadataMaxAvailWidthNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMaxAvailWidthNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMaxAvailWidthNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMaxAvailWidthNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMaxAvailWidthNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMaxAvailWidthNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMaxAvailWidthNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMaxAvailWidthNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMaxAvailWidthNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMaxAvailWidthNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMaxAvailWidthNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMaxAvailWidthNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataMaxAvailWidthOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataMaxAvailWidthUpdateInput = {
  /** Actions for the "MetadataMaxAvailWidth.archive" relation */
  archive?: InputMaybe<MetadataMaxAvailWidthNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMaxAvailWidth.metadata" relation */
  metadata?: InputMaybe<MetadataMaxAvailWidthNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMaxAvailWidth.photo" relation */
  photo?: InputMaybe<MetadataMaxAvailWidthNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataMaxAvailWidth" nodes by specifying some conditions */
export type MetadataMaxAvailWidthWhereInput = {
  AND?: InputMaybe<Array<MetadataMaxAvailWidthWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
  OR?: InputMaybe<Array<MetadataMaxAvailWidthWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataMaxAvailWidth" node. */
export type MetadataMaxAvailWidthWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataMaxAvailWidthWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataMaxAvailWidth.metadata" relation */
  metadata: MetadataMaxAvailWidthNestedMetadataCreateInput;
  /** Actions for the "MetadataMaxAvailWidth.photo" relation */
  photo: MetadataMaxAvailWidthNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailWidthWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataMaxAvailWidth.metadata" relation */
  metadata?: InputMaybe<MetadataMaxAvailWidthNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMaxAvailWidth.photo" relation */
  photo?: InputMaybe<MetadataMaxAvailWidthNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailWidthWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataMaxAvailWidth.archive" relation */
  archive: MetadataMaxAvailWidthNestedArchiveCreateInput;
  /** Actions for the "MetadataMaxAvailWidth.photo" relation */
  photo: MetadataMaxAvailWidthNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailWidthWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataMaxAvailWidth.archive" relation */
  archive?: InputMaybe<MetadataMaxAvailWidthNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMaxAvailWidth.photo" relation */
  photo?: InputMaybe<MetadataMaxAvailWidthNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailWidthWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataMaxAvailWidth.archive" relation */
  archive: MetadataMaxAvailWidthNestedArchiveCreateInput;
  /** Actions for the "MetadataMaxAvailWidth.metadata" relation */
  metadata: MetadataMaxAvailWidthNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMaxAvailWidthWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataMaxAvailWidth.archive" relation */
  archive?: InputMaybe<MetadataMaxAvailWidthNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMaxAvailWidth.metadata" relation */
  metadata?: InputMaybe<MetadataMaxAvailWidthNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataMinorModelAgeDisclosure" resource's node */
export type MetadataMinorModelAgeDisclosure = {
  __typename?: 'MetadataMinorModelAgeDisclosure';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataMinorModelAgeDisclosureCreateInput = {
  /** Actions for the "MetadataMinorModelAgeDisclosure.archive" relation */
  archive: MetadataMinorModelAgeDisclosureNestedArchiveCreateInput;
  /** Actions for the "MetadataMinorModelAgeDisclosure.metadata" relation */
  metadata: MetadataMinorModelAgeDisclosureNestedMetadataCreateInput;
  /** Actions for the "MetadataMinorModelAgeDisclosure.photo" relation */
  photo: MetadataMinorModelAgeDisclosureNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMinorModelAgeDisclosureNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.archive" relation. */
  update?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.archive" relation. */
  upsert?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpsertArchiveCreateInput>;
};

export type MetadataMinorModelAgeDisclosureNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.archive" relation. */
  update?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.archive" relation. */
  upsert?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpsertArchiveUpdateInput>;
};

export type MetadataMinorModelAgeDisclosureNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.metadata" relation. */
  update?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.metadata" relation. */
  upsert?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpsertMetadataCreateInput>;
};

export type MetadataMinorModelAgeDisclosureNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.metadata" relation. */
  update?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.metadata" relation. */
  upsert?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpsertMetadataUpdateInput>;
};

export type MetadataMinorModelAgeDisclosureNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.photo" relation. */
  update?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.photo" relation. */
  upsert?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpsertPhotoCreateInput>;
};

export type MetadataMinorModelAgeDisclosureNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.photo" relation. */
  update?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataMinorModelAgeDisclosure" node, through the "MetadataMinorModelAgeDisclosure.photo" relation. */
  upsert?: InputMaybe<MetadataMinorModelAgeDisclosureNestedUpsertPhotoUpdateInput>;
};

export type MetadataMinorModelAgeDisclosureNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMinorModelAgeDisclosureNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMinorModelAgeDisclosureNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMinorModelAgeDisclosureNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMinorModelAgeDisclosureNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMinorModelAgeDisclosureNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMinorModelAgeDisclosureNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMinorModelAgeDisclosureNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMinorModelAgeDisclosureNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMinorModelAgeDisclosureNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMinorModelAgeDisclosureNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMinorModelAgeDisclosureNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataMinorModelAgeDisclosureOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataMinorModelAgeDisclosureUpdateInput = {
  /** Actions for the "MetadataMinorModelAgeDisclosure.archive" relation */
  archive?: InputMaybe<MetadataMinorModelAgeDisclosureNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMinorModelAgeDisclosure.metadata" relation */
  metadata?: InputMaybe<MetadataMinorModelAgeDisclosureNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMinorModelAgeDisclosure.photo" relation */
  photo?: InputMaybe<MetadataMinorModelAgeDisclosureNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataMinorModelAgeDisclosure" nodes by specifying some conditions */
export type MetadataMinorModelAgeDisclosureWhereInput = {
  AND?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
  OR?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataMinorModelAgeDisclosure" node. */
export type MetadataMinorModelAgeDisclosureWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataMinorModelAgeDisclosureWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataMinorModelAgeDisclosure.metadata" relation */
  metadata: MetadataMinorModelAgeDisclosureNestedMetadataCreateInput;
  /** Actions for the "MetadataMinorModelAgeDisclosure.photo" relation */
  photo: MetadataMinorModelAgeDisclosureNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMinorModelAgeDisclosureWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataMinorModelAgeDisclosure.metadata" relation */
  metadata?: InputMaybe<MetadataMinorModelAgeDisclosureNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMinorModelAgeDisclosure.photo" relation */
  photo?: InputMaybe<MetadataMinorModelAgeDisclosureNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMinorModelAgeDisclosureWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataMinorModelAgeDisclosure.archive" relation */
  archive: MetadataMinorModelAgeDisclosureNestedArchiveCreateInput;
  /** Actions for the "MetadataMinorModelAgeDisclosure.photo" relation */
  photo: MetadataMinorModelAgeDisclosureNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMinorModelAgeDisclosureWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataMinorModelAgeDisclosure.archive" relation */
  archive?: InputMaybe<MetadataMinorModelAgeDisclosureNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMinorModelAgeDisclosure.photo" relation */
  photo?: InputMaybe<MetadataMinorModelAgeDisclosureNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMinorModelAgeDisclosureWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataMinorModelAgeDisclosure.archive" relation */
  archive: MetadataMinorModelAgeDisclosureNestedArchiveCreateInput;
  /** Actions for the "MetadataMinorModelAgeDisclosure.metadata" relation */
  metadata: MetadataMinorModelAgeDisclosureNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMinorModelAgeDisclosureWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataMinorModelAgeDisclosure.archive" relation */
  archive?: InputMaybe<MetadataMinorModelAgeDisclosureNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMinorModelAgeDisclosure.metadata" relation */
  metadata?: InputMaybe<MetadataMinorModelAgeDisclosureNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataModel" resource's node */
export type MetadataModel = {
  __typename?: 'MetadataModel';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** "MetadataModelAge" resource's node */
export type MetadataModelAge = {
  __typename?: 'MetadataModelAge';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataModelAgeCreateInput = {
  /** Actions for the "MetadataModelAge.archive" relation */
  archive: MetadataModelAgeNestedArchiveCreateInput;
  /** Actions for the "MetadataModelAge.metadata" relation */
  metadata: MetadataModelAgeNestedMetadataCreateInput;
  /** Actions for the "MetadataModelAge.photo" relation */
  photo: MetadataModelAgeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelAgeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataModelAge" node, through the "MetadataModelAge.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataModelAge" node, through the "MetadataModelAge.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataModelAge" node, through the "MetadataModelAge.archive" relation. */
  update?: InputMaybe<MetadataModelAgeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataModelAge" node, through the "MetadataModelAge.archive" relation. */
  upsert?: InputMaybe<MetadataModelAgeNestedUpsertArchiveCreateInput>;
};

export type MetadataModelAgeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataModelAge" node, through the "MetadataModelAge.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataModelAge" node, through the "MetadataModelAge.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataModelAge" node, through the "MetadataModelAge.archive" relation. */
  update?: InputMaybe<MetadataModelAgeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataModelAge" node, through the "MetadataModelAge.archive" relation. */
  upsert?: InputMaybe<MetadataModelAgeNestedUpsertArchiveUpdateInput>;
};

export type MetadataModelAgeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataModelAge" node, through the "MetadataModelAge.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataModelAge" node, through the "MetadataModelAge.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataModelAge" node, through the "MetadataModelAge.metadata" relation. */
  update?: InputMaybe<MetadataModelAgeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataModelAge" node, through the "MetadataModelAge.metadata" relation. */
  upsert?: InputMaybe<MetadataModelAgeNestedUpsertMetadataCreateInput>;
};

export type MetadataModelAgeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataModelAge" node, through the "MetadataModelAge.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataModelAge" node, through the "MetadataModelAge.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataModelAge" node, through the "MetadataModelAge.metadata" relation. */
  update?: InputMaybe<MetadataModelAgeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataModelAge" node, through the "MetadataModelAge.metadata" relation. */
  upsert?: InputMaybe<MetadataModelAgeNestedUpsertMetadataUpdateInput>;
};

export type MetadataModelAgeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataModelAge" node, through the "MetadataModelAge.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataModelAge" node, through the "MetadataModelAge.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataModelAge" node, through the "MetadataModelAge.photo" relation. */
  update?: InputMaybe<MetadataModelAgeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataModelAge" node, through the "MetadataModelAge.photo" relation. */
  upsert?: InputMaybe<MetadataModelAgeNestedUpsertPhotoCreateInput>;
};

export type MetadataModelAgeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataModelAge" node, through the "MetadataModelAge.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataModelAge" node, through the "MetadataModelAge.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataModelAge" node, through the "MetadataModelAge.photo" relation. */
  update?: InputMaybe<MetadataModelAgeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataModelAge" node, through the "MetadataModelAge.photo" relation. */
  upsert?: InputMaybe<MetadataModelAgeNestedUpsertPhotoUpdateInput>;
};

export type MetadataModelAgeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelAgeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelAgeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelAgeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelAgeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelAgeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelAgeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelAgeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelAgeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelAgeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelAgeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelAgeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataModelAgeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataModelAgeUpdateInput = {
  /** Actions for the "MetadataModelAge.archive" relation */
  archive?: InputMaybe<MetadataModelAgeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModelAge.metadata" relation */
  metadata?: InputMaybe<MetadataModelAgeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataModelAge.photo" relation */
  photo?: InputMaybe<MetadataModelAgeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataModelAge" nodes by specifying some conditions */
export type MetadataModelAgeWhereInput = {
  AND?: InputMaybe<Array<MetadataModelAgeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataModelAgeWhereInput>;
  OR?: InputMaybe<Array<MetadataModelAgeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataModelAge" node. */
export type MetadataModelAgeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataModelAgeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataModelAge.metadata" relation */
  metadata: MetadataModelAgeNestedMetadataCreateInput;
  /** Actions for the "MetadataModelAge.photo" relation */
  photo: MetadataModelAgeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelAgeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataModelAge.metadata" relation */
  metadata?: InputMaybe<MetadataModelAgeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataModelAge.photo" relation */
  photo?: InputMaybe<MetadataModelAgeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelAgeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataModelAge.archive" relation */
  archive: MetadataModelAgeNestedArchiveCreateInput;
  /** Actions for the "MetadataModelAge.photo" relation */
  photo: MetadataModelAgeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelAgeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataModelAge.archive" relation */
  archive?: InputMaybe<MetadataModelAgeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModelAge.photo" relation */
  photo?: InputMaybe<MetadataModelAgeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelAgeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataModelAge.archive" relation */
  archive: MetadataModelAgeNestedArchiveCreateInput;
  /** Actions for the "MetadataModelAge.metadata" relation */
  metadata: MetadataModelAgeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelAgeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataModelAge.archive" relation */
  archive?: InputMaybe<MetadataModelAgeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModelAge.metadata" relation */
  metadata?: InputMaybe<MetadataModelAgeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelCreateInput = {
  /** Actions for the "MetadataModel.archive" relation */
  archive: MetadataModelNestedArchiveCreateInput;
  /** Actions for the "MetadataModel.metadata" relation */
  metadata: MetadataModelNestedMetadataCreateInput;
  /** Actions for the "MetadataModel.photo" relation */
  photo: MetadataModelNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataModel" node, through the "MetadataModel.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataModel" node, through the "MetadataModel.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataModel" node, through the "MetadataModel.archive" relation. */
  update?: InputMaybe<MetadataModelNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataModel" node, through the "MetadataModel.archive" relation. */
  upsert?: InputMaybe<MetadataModelNestedUpsertArchiveCreateInput>;
};

export type MetadataModelNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataModel" node, through the "MetadataModel.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataModel" node, through the "MetadataModel.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataModel" node, through the "MetadataModel.archive" relation. */
  update?: InputMaybe<MetadataModelNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataModel" node, through the "MetadataModel.archive" relation. */
  upsert?: InputMaybe<MetadataModelNestedUpsertArchiveUpdateInput>;
};

export type MetadataModelNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataModel" node, through the "MetadataModel.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataModel" node, through the "MetadataModel.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataModel" node, through the "MetadataModel.metadata" relation. */
  update?: InputMaybe<MetadataModelNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataModel" node, through the "MetadataModel.metadata" relation. */
  upsert?: InputMaybe<MetadataModelNestedUpsertMetadataCreateInput>;
};

export type MetadataModelNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataModel" node, through the "MetadataModel.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataModel" node, through the "MetadataModel.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataModel" node, through the "MetadataModel.metadata" relation. */
  update?: InputMaybe<MetadataModelNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataModel" node, through the "MetadataModel.metadata" relation. */
  upsert?: InputMaybe<MetadataModelNestedUpsertMetadataUpdateInput>;
};

export type MetadataModelNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataModel" node, through the "MetadataModel.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataModel" node, through the "MetadataModel.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataModel" node, through the "MetadataModel.photo" relation. */
  update?: InputMaybe<MetadataModelNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataModel" node, through the "MetadataModel.photo" relation. */
  upsert?: InputMaybe<MetadataModelNestedUpsertPhotoCreateInput>;
};

export type MetadataModelNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataModel" node, through the "MetadataModel.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataModel" node, through the "MetadataModel.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataModel" node, through the "MetadataModel.photo" relation. */
  update?: InputMaybe<MetadataModelNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataModel" node, through the "MetadataModel.photo" relation. */
  upsert?: InputMaybe<MetadataModelNestedUpsertPhotoUpdateInput>;
};

export type MetadataModelNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataModelOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

/** "MetadataModelReleaseId" resource's node */
export type MetadataModelReleaseId = {
  __typename?: 'MetadataModelReleaseId';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataModelReleaseIdCreateInput = {
  /** Actions for the "MetadataModelReleaseId.archive" relation */
  archive: MetadataModelReleaseIdNestedArchiveCreateInput;
  /** Actions for the "MetadataModelReleaseId.metadata" relation */
  metadata: MetadataModelReleaseIdNestedMetadataCreateInput;
  /** Actions for the "MetadataModelReleaseId.photo" relation */
  photo: MetadataModelReleaseIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseIdNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.archive" relation. */
  update?: InputMaybe<MetadataModelReleaseIdNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.archive" relation. */
  upsert?: InputMaybe<MetadataModelReleaseIdNestedUpsertArchiveCreateInput>;
};

export type MetadataModelReleaseIdNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.archive" relation. */
  update?: InputMaybe<MetadataModelReleaseIdNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.archive" relation. */
  upsert?: InputMaybe<MetadataModelReleaseIdNestedUpsertArchiveUpdateInput>;
};

export type MetadataModelReleaseIdNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.metadata" relation. */
  update?: InputMaybe<MetadataModelReleaseIdNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.metadata" relation. */
  upsert?: InputMaybe<MetadataModelReleaseIdNestedUpsertMetadataCreateInput>;
};

export type MetadataModelReleaseIdNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.metadata" relation. */
  update?: InputMaybe<MetadataModelReleaseIdNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.metadata" relation. */
  upsert?: InputMaybe<MetadataModelReleaseIdNestedUpsertMetadataUpdateInput>;
};

export type MetadataModelReleaseIdNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.photo" relation. */
  update?: InputMaybe<MetadataModelReleaseIdNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.photo" relation. */
  upsert?: InputMaybe<MetadataModelReleaseIdNestedUpsertPhotoCreateInput>;
};

export type MetadataModelReleaseIdNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.photo" relation. */
  update?: InputMaybe<MetadataModelReleaseIdNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataModelReleaseId" node, through the "MetadataModelReleaseId.photo" relation. */
  upsert?: InputMaybe<MetadataModelReleaseIdNestedUpsertPhotoUpdateInput>;
};

export type MetadataModelReleaseIdNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelReleaseIdNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelReleaseIdNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelReleaseIdNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelReleaseIdNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelReleaseIdNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelReleaseIdNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelReleaseIdNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelReleaseIdNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelReleaseIdNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelReleaseIdNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelReleaseIdNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataModelReleaseIdOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataModelReleaseIdUpdateInput = {
  /** Actions for the "MetadataModelReleaseId.archive" relation */
  archive?: InputMaybe<MetadataModelReleaseIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModelReleaseId.metadata" relation */
  metadata?: InputMaybe<MetadataModelReleaseIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataModelReleaseId.photo" relation */
  photo?: InputMaybe<MetadataModelReleaseIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataModelReleaseId" nodes by specifying some conditions */
export type MetadataModelReleaseIdWhereInput = {
  AND?: InputMaybe<Array<MetadataModelReleaseIdWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataModelReleaseIdWhereInput>;
  OR?: InputMaybe<Array<MetadataModelReleaseIdWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataModelReleaseId" node. */
export type MetadataModelReleaseIdWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataModelReleaseIdWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataModelReleaseId.metadata" relation */
  metadata: MetadataModelReleaseIdNestedMetadataCreateInput;
  /** Actions for the "MetadataModelReleaseId.photo" relation */
  photo: MetadataModelReleaseIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseIdWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataModelReleaseId.metadata" relation */
  metadata?: InputMaybe<MetadataModelReleaseIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataModelReleaseId.photo" relation */
  photo?: InputMaybe<MetadataModelReleaseIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseIdWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataModelReleaseId.archive" relation */
  archive: MetadataModelReleaseIdNestedArchiveCreateInput;
  /** Actions for the "MetadataModelReleaseId.photo" relation */
  photo: MetadataModelReleaseIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseIdWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataModelReleaseId.archive" relation */
  archive?: InputMaybe<MetadataModelReleaseIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModelReleaseId.photo" relation */
  photo?: InputMaybe<MetadataModelReleaseIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseIdWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataModelReleaseId.archive" relation */
  archive: MetadataModelReleaseIdNestedArchiveCreateInput;
  /** Actions for the "MetadataModelReleaseId.metadata" relation */
  metadata: MetadataModelReleaseIdNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseIdWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataModelReleaseId.archive" relation */
  archive?: InputMaybe<MetadataModelReleaseIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModelReleaseId.metadata" relation */
  metadata?: InputMaybe<MetadataModelReleaseIdNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataModelReleaseStatus" resource's node */
export type MetadataModelReleaseStatus = {
  __typename?: 'MetadataModelReleaseStatus';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataModelReleaseStatusCreateInput = {
  /** Actions for the "MetadataModelReleaseStatus.archive" relation */
  archive: MetadataModelReleaseStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataModelReleaseStatus.metadata" relation */
  metadata: MetadataModelReleaseStatusNestedMetadataCreateInput;
  /** Actions for the "MetadataModelReleaseStatus.photo" relation */
  photo: MetadataModelReleaseStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseStatusNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.archive" relation. */
  update?: InputMaybe<MetadataModelReleaseStatusNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.archive" relation. */
  upsert?: InputMaybe<MetadataModelReleaseStatusNestedUpsertArchiveCreateInput>;
};

export type MetadataModelReleaseStatusNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.archive" relation. */
  update?: InputMaybe<MetadataModelReleaseStatusNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.archive" relation. */
  upsert?: InputMaybe<MetadataModelReleaseStatusNestedUpsertArchiveUpdateInput>;
};

export type MetadataModelReleaseStatusNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.metadata" relation. */
  update?: InputMaybe<MetadataModelReleaseStatusNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.metadata" relation. */
  upsert?: InputMaybe<MetadataModelReleaseStatusNestedUpsertMetadataCreateInput>;
};

export type MetadataModelReleaseStatusNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.metadata" relation. */
  update?: InputMaybe<MetadataModelReleaseStatusNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.metadata" relation. */
  upsert?: InputMaybe<MetadataModelReleaseStatusNestedUpsertMetadataUpdateInput>;
};

export type MetadataModelReleaseStatusNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.photo" relation. */
  update?: InputMaybe<MetadataModelReleaseStatusNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.photo" relation. */
  upsert?: InputMaybe<MetadataModelReleaseStatusNestedUpsertPhotoCreateInput>;
};

export type MetadataModelReleaseStatusNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.photo" relation. */
  update?: InputMaybe<MetadataModelReleaseStatusNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataModelReleaseStatus" node, through the "MetadataModelReleaseStatus.photo" relation. */
  upsert?: InputMaybe<MetadataModelReleaseStatusNestedUpsertPhotoUpdateInput>;
};

export type MetadataModelReleaseStatusNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelReleaseStatusNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelReleaseStatusNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelReleaseStatusNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelReleaseStatusNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelReleaseStatusNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelReleaseStatusNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelReleaseStatusNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataModelReleaseStatusNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelReleaseStatusNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataModelReleaseStatusNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataModelReleaseStatusNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataModelReleaseStatusOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataModelReleaseStatusUpdateInput = {
  /** Actions for the "MetadataModelReleaseStatus.archive" relation */
  archive?: InputMaybe<MetadataModelReleaseStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModelReleaseStatus.metadata" relation */
  metadata?: InputMaybe<MetadataModelReleaseStatusNestedMetadataUpdateInput>;
  /** Actions for the "MetadataModelReleaseStatus.photo" relation */
  photo?: InputMaybe<MetadataModelReleaseStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataModelReleaseStatus" nodes by specifying some conditions */
export type MetadataModelReleaseStatusWhereInput = {
  AND?: InputMaybe<Array<MetadataModelReleaseStatusWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
  OR?: InputMaybe<Array<MetadataModelReleaseStatusWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataModelReleaseStatus" node. */
export type MetadataModelReleaseStatusWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataModelReleaseStatusWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataModelReleaseStatus.metadata" relation */
  metadata: MetadataModelReleaseStatusNestedMetadataCreateInput;
  /** Actions for the "MetadataModelReleaseStatus.photo" relation */
  photo: MetadataModelReleaseStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseStatusWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataModelReleaseStatus.metadata" relation */
  metadata?: InputMaybe<MetadataModelReleaseStatusNestedMetadataUpdateInput>;
  /** Actions for the "MetadataModelReleaseStatus.photo" relation */
  photo?: InputMaybe<MetadataModelReleaseStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseStatusWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataModelReleaseStatus.archive" relation */
  archive: MetadataModelReleaseStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataModelReleaseStatus.photo" relation */
  photo: MetadataModelReleaseStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseStatusWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataModelReleaseStatus.archive" relation */
  archive?: InputMaybe<MetadataModelReleaseStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModelReleaseStatus.photo" relation */
  photo?: InputMaybe<MetadataModelReleaseStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseStatusWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataModelReleaseStatus.archive" relation */
  archive: MetadataModelReleaseStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataModelReleaseStatus.metadata" relation */
  metadata: MetadataModelReleaseStatusNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelReleaseStatusWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataModelReleaseStatus.archive" relation */
  archive?: InputMaybe<MetadataModelReleaseStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModelReleaseStatus.metadata" relation */
  metadata?: InputMaybe<MetadataModelReleaseStatusNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelUpdateInput = {
  /** Actions for the "MetadataModel.archive" relation */
  archive?: InputMaybe<MetadataModelNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModel.metadata" relation */
  metadata?: InputMaybe<MetadataModelNestedMetadataUpdateInput>;
  /** Actions for the "MetadataModel.photo" relation */
  photo?: InputMaybe<MetadataModelNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataModel" nodes by specifying some conditions */
export type MetadataModelWhereInput = {
  AND?: InputMaybe<Array<MetadataModelWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataModelWhereInput>;
  OR?: InputMaybe<Array<MetadataModelWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataModel" node. */
export type MetadataModelWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataModelWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataModel.metadata" relation */
  metadata: MetadataModelNestedMetadataCreateInput;
  /** Actions for the "MetadataModel.photo" relation */
  photo: MetadataModelNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataModel.metadata" relation */
  metadata?: InputMaybe<MetadataModelNestedMetadataUpdateInput>;
  /** Actions for the "MetadataModel.photo" relation */
  photo?: InputMaybe<MetadataModelNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataModel.archive" relation */
  archive: MetadataModelNestedArchiveCreateInput;
  /** Actions for the "MetadataModel.photo" relation */
  photo: MetadataModelNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataModel.archive" relation */
  archive?: InputMaybe<MetadataModelNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModel.photo" relation */
  photo?: InputMaybe<MetadataModelNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataModel.archive" relation */
  archive: MetadataModelNestedArchiveCreateInput;
  /** Actions for the "MetadataModel.metadata" relation */
  metadata: MetadataModelNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataModelWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataModel.archive" relation */
  archive?: InputMaybe<MetadataModelNestedArchiveUpdateInput>;
  /** Actions for the "MetadataModel.metadata" relation */
  metadata?: InputMaybe<MetadataModelNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataMorePermissions" resource's node */
export type MetadataMorePermissions = {
  __typename?: 'MetadataMorePermissions';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataMorePermissionsCreateInput = {
  /** Actions for the "MetadataMorePermissions.archive" relation */
  archive: MetadataMorePermissionsNestedArchiveCreateInput;
  /** Actions for the "MetadataMorePermissions.metadata" relation */
  metadata: MetadataMorePermissionsNestedMetadataCreateInput;
  /** Actions for the "MetadataMorePermissions.photo" relation */
  photo: MetadataMorePermissionsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMorePermissionsNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.archive" relation. */
  update?: InputMaybe<MetadataMorePermissionsNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.archive" relation. */
  upsert?: InputMaybe<MetadataMorePermissionsNestedUpsertArchiveCreateInput>;
};

export type MetadataMorePermissionsNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.archive" relation. */
  update?: InputMaybe<MetadataMorePermissionsNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.archive" relation. */
  upsert?: InputMaybe<MetadataMorePermissionsNestedUpsertArchiveUpdateInput>;
};

export type MetadataMorePermissionsNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.metadata" relation. */
  update?: InputMaybe<MetadataMorePermissionsNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.metadata" relation. */
  upsert?: InputMaybe<MetadataMorePermissionsNestedUpsertMetadataCreateInput>;
};

export type MetadataMorePermissionsNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.metadata" relation. */
  update?: InputMaybe<MetadataMorePermissionsNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.metadata" relation. */
  upsert?: InputMaybe<MetadataMorePermissionsNestedUpsertMetadataUpdateInput>;
};

export type MetadataMorePermissionsNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.photo" relation. */
  update?: InputMaybe<MetadataMorePermissionsNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataMorePermissions" node, through the "MetadataMorePermissions.photo" relation. */
  upsert?: InputMaybe<MetadataMorePermissionsNestedUpsertPhotoCreateInput>;
};

export type MetadataMorePermissionsNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.photo" relation. */
  update?: InputMaybe<MetadataMorePermissionsNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataMorePermissions" node, through the "MetadataMorePermissions.photo" relation. */
  upsert?: InputMaybe<MetadataMorePermissionsNestedUpsertPhotoUpdateInput>;
};

export type MetadataMorePermissionsNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMorePermissionsNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMorePermissionsNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMorePermissionsNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMorePermissionsNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMorePermissionsNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMorePermissionsNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMorePermissionsNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataMorePermissionsNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMorePermissionsNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataMorePermissionsNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataMorePermissionsNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataMorePermissionsOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataMorePermissionsUpdateInput = {
  /** Actions for the "MetadataMorePermissions.archive" relation */
  archive?: InputMaybe<MetadataMorePermissionsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMorePermissions.metadata" relation */
  metadata?: InputMaybe<MetadataMorePermissionsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMorePermissions.photo" relation */
  photo?: InputMaybe<MetadataMorePermissionsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataMorePermissions" nodes by specifying some conditions */
export type MetadataMorePermissionsWhereInput = {
  AND?: InputMaybe<Array<MetadataMorePermissionsWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataMorePermissionsWhereInput>;
  OR?: InputMaybe<Array<MetadataMorePermissionsWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataMorePermissions" node. */
export type MetadataMorePermissionsWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataMorePermissionsWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataMorePermissions.metadata" relation */
  metadata: MetadataMorePermissionsNestedMetadataCreateInput;
  /** Actions for the "MetadataMorePermissions.photo" relation */
  photo: MetadataMorePermissionsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMorePermissionsWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataMorePermissions.metadata" relation */
  metadata?: InputMaybe<MetadataMorePermissionsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataMorePermissions.photo" relation */
  photo?: InputMaybe<MetadataMorePermissionsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMorePermissionsWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataMorePermissions.archive" relation */
  archive: MetadataMorePermissionsNestedArchiveCreateInput;
  /** Actions for the "MetadataMorePermissions.photo" relation */
  photo: MetadataMorePermissionsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMorePermissionsWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataMorePermissions.archive" relation */
  archive?: InputMaybe<MetadataMorePermissionsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMorePermissions.photo" relation */
  photo?: InputMaybe<MetadataMorePermissionsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMorePermissionsWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataMorePermissions.archive" relation */
  archive: MetadataMorePermissionsNestedArchiveCreateInput;
  /** Actions for the "MetadataMorePermissions.metadata" relation */
  metadata: MetadataMorePermissionsNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataMorePermissionsWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataMorePermissions.archive" relation */
  archive?: InputMaybe<MetadataMorePermissionsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataMorePermissions.metadata" relation */
  metadata?: InputMaybe<MetadataMorePermissionsNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum MetadataNameEnumType {
  AccessRights = 'AccessRights',
  AddlModelInfo = 'AddlModelInfo',
  Archive_child = 'Archive_child',
  Archive_parent = 'Archive_parent',
  AssetState = 'AssetState',
  attributionName = 'attributionName',
  attributionURL = 'attributionURL',
  Audience = 'Audience',
  AuthorsPosition = 'AuthorsPosition',
  BackupName = 'BackupName',
  BitmapGrayscalePicture = 'BitmapGrayscalePicture',
  Brand = 'Brand',
  BrandPrisma = 'BrandPrisma',
  BrandShortName = 'BrandShortName',
  CaptionWriter = 'CaptionWriter',
  Category = 'Category',
  CategoryPrisma = 'CategoryPrisma',
  Certificate = 'Certificate',
  CiAdrCity = 'CiAdrCity',
  CiAdrCtry = 'CiAdrCtry',
  CiAdrExtadr = 'CiAdrExtadr',
  CiAdrPcode = 'CiAdrPcode',
  CiAdrRegion = 'CiAdrRegion',
  CiEmailWork = 'CiEmailWork',
  CiTelWork = 'CiTelWork',
  City = 'City',
  CiUrlWork = 'CiUrlWork',
  Classify = 'Classify',
  Company = 'Company',
  CompanyShortName = 'CompanyShortName',
  Confidentiality = 'Confidentiality',
  ContainedIn = 'ContainedIn',
  ContentMediaKind = 'ContentMediaKind',
  ContentValue = 'ContentValue',
  CopyrightLayer = 'CopyrightLayer',
  Country = 'Country',
  CountryCode = 'CountryCode',
  CreatedTime = 'CreatedTime',
  creator = 'creator',
  CreatorTool = 'CreatorTool',
  Credit = 'Credit',
  CustomField14 = 'CustomField14',
  CustomField15 = 'CustomField15',
  CustomField16 = 'CustomField16',
  CustomField17 = 'CustomField17',
  CustomField20 = 'CustomField20',
  CustomField5 = 'CustomField5',
  CustomField6 = 'CustomField6',
  CustomField7 = 'CustomField7',
  CustomField9 = 'CustomField9',
  DateCreated = 'DateCreated',
  DateTimeDigitized = 'DateTimeDigitized',
  DateTimeOriginal = 'DateTimeOriginal',
  DeliveryAccount = 'DeliveryAccount',
  DeliveryCompany = 'DeliveryCompany',
  DeliveryCopyright = 'DeliveryCopyright',
  DeliveryDateFolder = 'DeliveryDateFolder',
  DeliveryDateTime = 'DeliveryDateTime',
  DeliveryDcCreator = 'DeliveryDcCreator',
  DeliveryDcRights = 'DeliveryDcRights',
  DeliveryFileType = 'DeliveryFileType',
  DeliveryFolder = 'DeliveryFolder',
  DeliveryKind = 'DeliveryKind',
  DeliveryPath = 'DeliveryPath',
  DeliveryPersonShownintheImage = 'DeliveryPersonShownintheImage',
  DeliveryPhotoshopCredit = 'DeliveryPhotoshopCredit',
  DeliveryPhotoshopSource = 'DeliveryPhotoshopSource',
  DeliveryService = 'DeliveryService',
  DeliverySubject = 'DeliverySubject',
  DeliveryUnderSubject = 'DeliveryUnderSubject',
  Department = 'Department',
  description = 'description',
  DigitalAssetURL = 'DigitalAssetURL',
  Division = 'Division',
  DocumentText = 'DocumentText',
  Edition = 'Edition',
  EditorialVersion = 'EditorialVersion',
  EditStatus = 'EditStatus',
  EnvironnementPhoto = 'EnvironnementPhoto',
  EquipmentInstitution = 'EquipmentInstitution',
  EquipmentManufacturer = 'EquipmentManufacturer',
  Event = 'Event',
  Exif = 'Exif',
  FabStorage = 'FabStorage',
  FileCheckFor = 'FileCheckFor',
  FileInfoBitsPerPixel = 'FileInfoBitsPerPixel',
  FileInfoFrames = 'FileInfoFrames',
  FileInfoPhotometricInterpretation = 'FileInfoPhotometricInterpretation',
  FileInfoTransferSyntax = 'FileInfoTransferSyntax',
  FileStage = 'FileStage',
  FileStatus = 'FileStatus',
  FileType = 'FileType',
  FixtureIdentifier = 'FixtureIdentifier',
  Format = 'Format',
  GPSLatitude = 'GPSLatitude',
  GPSLongitude = 'GPSLongitude',
  GPSProcessingMethod = 'GPSProcessingMethod',
  Header = 'Header',
  HeaderPrisma = 'HeaderPrisma',
  Headline = 'Headline',
  History = 'History',
  ICCProfile = 'ICCProfile',
  ID_subject = 'ID_subject',
  IdAssignment = 'IdAssignment',
  IdMediaContent = 'IdMediaContent',
  IdPhotoPrisma = 'IdPhotoPrisma',
  ImageNotes = 'ImageNotes',
  ImageUniqueID = 'ImageUniqueID',
  Instructions = 'Instructions',
  IntellectualGenre = 'IntellectualGenre',
  ISOSpeedRatings = 'ISOSpeedRatings',
  Issue = 'Issue',
  JobId = 'JobId',
  JobProcessing = 'JobProcessing',
  LayoutPictureEditing = 'LayoutPictureEditing',
  LayoutStorage = 'LayoutStorage',
  license = 'license',
  Links = 'Links',
  LocalCaption = 'LocalCaption',
  Location = 'Location',
  Make = 'Make',
  Manifest = 'Manifest',
  Marked = 'Marked',
  MasterDocumentId = 'MasterDocumentId',
  MaxAvailHeight = 'MaxAvailHeight',
  MaxAvailWidth = 'MaxAvailWidth',
  MinorModelAgeDisclosure = 'MinorModelAgeDisclosure',
  Model = 'Model',
  ModelAge = 'ModelAge',
  ModelReleaseID = 'ModelReleaseID',
  ModelReleaseStatus = 'ModelReleaseStatus',
  morePermissions = 'morePermissions',
  ObjectCycle = 'ObjectCycle',
  OrganisationInImageName = 'OrganisationInImageName',
  OriginalColor = 'OriginalColor',
  OriginalICCProfile = 'OriginalICCProfile',
  OriginalName = 'OriginalName',
  OriginalWeigth = 'OriginalWeigth',
  Owner = 'Owner',
  OwnerId = 'OwnerId',
  Page = 'Page',
  PagePrisma = 'PagePrisma',
  PaintBasedCorrections = 'PaintBasedCorrections',
  PatientDOB = 'PatientDOB',
  PatientID = 'PatientID',
  PatientName = 'PatientName',
  PatientSex = 'PatientSex',
  PersonInImage = 'PersonInImage',
  PhotoStorage = 'PhotoStorage',
  PrepressPictureEditing = 'PrepressPictureEditing',
  PriceLevel = 'PriceLevel',
  PrintingProfile = 'PrintingProfile',
  PrismaProduction = 'PrismaProduction',
  ProcessHistory = 'ProcessHistory',
  ProcessParameter = 'ProcessParameter',
  ProcessStatus = 'ProcessStatus',
  Product = 'Product',
  Products = 'Products',
  ProductShortName = 'ProductShortName',
  Produits = 'Produits',
  ProgramVersion = 'ProgramVersion',
  PropertyReleaseID = 'PropertyReleaseID',
  PropertyReleaseStatus = 'PropertyReleaseStatus',
  Publisher = 'Publisher',
  publishingIssue = 'publishingIssue',
  publishingSubject = 'publishingSubject',
  QualifiedUsePrismaBy = 'QualifiedUsePrismaBy',
  QualifiedUsePrismaDate = 'QualifiedUsePrismaDate',
  QualifiedUsePrismaDuration = 'QualifiedUsePrismaDuration',
  QualifiedUsePrismaSupport = 'QualifiedUsePrismaSupport',
  Rating = 'Rating',
  ReferenceDate = 'ReferenceDate',
  ReferenceNumber = 'ReferenceNumber',
  ReferenceService = 'ReferenceService',
  ReleaseDate = 'ReleaseDate',
  ReleaseTime = 'ReleaseTime',
  RequiredPermission = 'RequiredPermission',
  ResolutionKind = 'ResolutionKind',
  rights = 'rights',
  Royaltyfree = 'Royaltyfree',
  Scene = 'Scene',
  Section = 'Section',
  SentTo = 'SentTo',
  SerialNumber = 'SerialNumber',
  SeriesDateTime = 'SeriesDateTime',
  SeriesDescription = 'SeriesDescription',
  SeriesModality = 'SeriesModality',
  SeriesNumber = 'SeriesNumber',
  ShortUniqueId = 'ShortUniqueId',
  Source = 'Source',
  State = 'State',
  StudyDateTime = 'StudyDateTime',
  StudyDescription = 'StudyDescription',
  StudyID = 'StudyID',
  StudyPhysician = 'StudyPhysician',
  subject = 'subject',
  SubjectCode = 'SubjectCode',
  SubjectPrisma = 'SubjectPrisma',
  SupplementalCategories = 'SupplementalCategories',
  TargetVersion = 'TargetVersion',
  title = 'title',
  ToneCurve = 'ToneCurve',
  TransferredBy = 'TransferredBy',
  TransferredByEmail = 'TransferredByEmail',
  TransferredByFullName = 'TransferredByFullName',
  TransmissionReference = 'TransmissionReference',
  Type = 'Type',
  Undersubject = 'Undersubject',
  UnderUnderSubject = 'UnderUnderSubject',
  UniqueId = 'UniqueId',
  Unit = 'Unit',
  UnitShortName = 'UnitShortName',
  UploadedBy = 'UploadedBy',
  UploadedByFullName = 'UploadedByFullName',
  UploadTime = 'UploadTime',
  Urgency = 'Urgency',
  UsageTerms = 'UsageTerms',
  UserComment = 'UserComment',
  UserDefined195 = 'UserDefined195',
  UserDefined237 = 'UserDefined237',
  UserDefined238 = 'UserDefined238',
  UserDefined239 = 'UserDefined239',
  UserDefined242 = 'UserDefined242',
  UserDefined62 = 'UserDefined62',
  Valid = 'Valid',
  Version = 'Version',
  WebStatement = 'WebStatement',
  WorkflowKind = 'WorkflowKind',
  XMPFileStamps = 'XMPFileStamps',
  XMPHistory = 'XMPHistory'
}

export type MetadataNestedMetadataAccessRightsListCreateInput = {
  /** Connect existing "MetadataAccessRights" nodes to the new "Metadata" node, through the "Metadata.metadataAccessRightsList" relation. */
  connect?: InputMaybe<Array<MetadataAccessRightsWhereUniqueInput>>;
  /** Create new "MetadataAccessRights" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataAccessRightsList" relation. */
  create?: InputMaybe<Array<MetadataAccessRightsWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataAccessRightsListUpdateInput = {
  /** Connect existing "MetadataAccessRights" nodes to the existing "Metadata" node, through the "Metadata.metadataAccessRightsList" relation. */
  connect?: InputMaybe<Array<MetadataAccessRightsWhereUniqueInput>>;
  /** Create new "MetadataAccessRights" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAccessRightsList" relation. */
  create?: InputMaybe<Array<MetadataAccessRightsWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataAccessRights" nodes from the "Metadata.metadataAccessRightsList" relation. */
  delete?: InputMaybe<Array<MetadataAccessRightsWhereUniqueInput>>;
  /** Update existing "MetadataAccessRights" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAccessRightsList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataAccessRightsListUpdateInput>>;
  /** Upsert existing "MetadataAccessRights" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAccessRightsList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataAccessRightsListUpdateInput>>;
};

export type MetadataNestedMetadataAddlModelInfoListCreateInput = {
  /** Connect existing "MetadataAddlModelInfo" nodes to the new "Metadata" node, through the "Metadata.metadataAddlModelInfoList" relation. */
  connect?: InputMaybe<Array<MetadataAddlModelInfoWhereUniqueInput>>;
  /** Create new "MetadataAddlModelInfo" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataAddlModelInfoList" relation. */
  create?: InputMaybe<Array<MetadataAddlModelInfoWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataAddlModelInfoListUpdateInput = {
  /** Connect existing "MetadataAddlModelInfo" nodes to the existing "Metadata" node, through the "Metadata.metadataAddlModelInfoList" relation. */
  connect?: InputMaybe<Array<MetadataAddlModelInfoWhereUniqueInput>>;
  /** Create new "MetadataAddlModelInfo" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAddlModelInfoList" relation. */
  create?: InputMaybe<Array<MetadataAddlModelInfoWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataAddlModelInfo" nodes from the "Metadata.metadataAddlModelInfoList" relation. */
  delete?: InputMaybe<Array<MetadataAddlModelInfoWhereUniqueInput>>;
  /** Update existing "MetadataAddlModelInfo" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAddlModelInfoList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataAddlModelInfoListUpdateInput>>;
  /** Upsert existing "MetadataAddlModelInfo" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAddlModelInfoList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataAddlModelInfoListUpdateInput>>;
};

export type MetadataNestedMetadataArchiveChildListCreateInput = {
  /** Connect existing "MetadataArchiveChild" nodes to the new "Metadata" node, through the "Metadata.metadataArchiveChildList" relation. */
  connect?: InputMaybe<Array<MetadataArchiveChildWhereUniqueInput>>;
  /** Create new "MetadataArchiveChild" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataArchiveChildList" relation. */
  create?: InputMaybe<Array<MetadataArchiveChildWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataArchiveChildListUpdateInput = {
  /** Connect existing "MetadataArchiveChild" nodes to the existing "Metadata" node, through the "Metadata.metadataArchiveChildList" relation. */
  connect?: InputMaybe<Array<MetadataArchiveChildWhereUniqueInput>>;
  /** Create new "MetadataArchiveChild" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataArchiveChildList" relation. */
  create?: InputMaybe<Array<MetadataArchiveChildWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataArchiveChild" nodes from the "Metadata.metadataArchiveChildList" relation. */
  delete?: InputMaybe<Array<MetadataArchiveChildWhereUniqueInput>>;
  /** Update existing "MetadataArchiveChild" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataArchiveChildList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataArchiveChildListUpdateInput>>;
  /** Upsert existing "MetadataArchiveChild" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataArchiveChildList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataArchiveChildListUpdateInput>>;
};

export type MetadataNestedMetadataArchiveParentListCreateInput = {
  /** Connect existing "MetadataArchiveParent" nodes to the new "Metadata" node, through the "Metadata.metadataArchiveParentList" relation. */
  connect?: InputMaybe<Array<MetadataArchiveParentWhereUniqueInput>>;
  /** Create new "MetadataArchiveParent" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataArchiveParentList" relation. */
  create?: InputMaybe<Array<MetadataArchiveParentWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataArchiveParentListUpdateInput = {
  /** Connect existing "MetadataArchiveParent" nodes to the existing "Metadata" node, through the "Metadata.metadataArchiveParentList" relation. */
  connect?: InputMaybe<Array<MetadataArchiveParentWhereUniqueInput>>;
  /** Create new "MetadataArchiveParent" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataArchiveParentList" relation. */
  create?: InputMaybe<Array<MetadataArchiveParentWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataArchiveParent" nodes from the "Metadata.metadataArchiveParentList" relation. */
  delete?: InputMaybe<Array<MetadataArchiveParentWhereUniqueInput>>;
  /** Update existing "MetadataArchiveParent" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataArchiveParentList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataArchiveParentListUpdateInput>>;
  /** Upsert existing "MetadataArchiveParent" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataArchiveParentList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataArchiveParentListUpdateInput>>;
};

export type MetadataNestedMetadataAssetStateListCreateInput = {
  /** Connect existing "MetadataAssetState" nodes to the new "Metadata" node, through the "Metadata.metadataAssetStateList" relation. */
  connect?: InputMaybe<Array<MetadataAssetStateWhereUniqueInput>>;
  /** Create new "MetadataAssetState" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataAssetStateList" relation. */
  create?: InputMaybe<Array<MetadataAssetStateWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataAssetStateListUpdateInput = {
  /** Connect existing "MetadataAssetState" nodes to the existing "Metadata" node, through the "Metadata.metadataAssetStateList" relation. */
  connect?: InputMaybe<Array<MetadataAssetStateWhereUniqueInput>>;
  /** Create new "MetadataAssetState" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAssetStateList" relation. */
  create?: InputMaybe<Array<MetadataAssetStateWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataAssetState" nodes from the "Metadata.metadataAssetStateList" relation. */
  delete?: InputMaybe<Array<MetadataAssetStateWhereUniqueInput>>;
  /** Update existing "MetadataAssetState" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAssetStateList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataAssetStateListUpdateInput>>;
  /** Upsert existing "MetadataAssetState" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAssetStateList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataAssetStateListUpdateInput>>;
};

export type MetadataNestedMetadataAttributionNameListCreateInput = {
  /** Connect existing "MetadataAttributionName" nodes to the new "Metadata" node, through the "Metadata.metadataAttributionNameList" relation. */
  connect?: InputMaybe<Array<MetadataAttributionNameWhereUniqueInput>>;
  /** Create new "MetadataAttributionName" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataAttributionNameList" relation. */
  create?: InputMaybe<Array<MetadataAttributionNameWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataAttributionNameListUpdateInput = {
  /** Connect existing "MetadataAttributionName" nodes to the existing "Metadata" node, through the "Metadata.metadataAttributionNameList" relation. */
  connect?: InputMaybe<Array<MetadataAttributionNameWhereUniqueInput>>;
  /** Create new "MetadataAttributionName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAttributionNameList" relation. */
  create?: InputMaybe<Array<MetadataAttributionNameWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataAttributionName" nodes from the "Metadata.metadataAttributionNameList" relation. */
  delete?: InputMaybe<Array<MetadataAttributionNameWhereUniqueInput>>;
  /** Update existing "MetadataAttributionName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAttributionNameList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataAttributionNameListUpdateInput>>;
  /** Upsert existing "MetadataAttributionName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAttributionNameList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataAttributionNameListUpdateInput>>;
};

export type MetadataNestedMetadataAttributionUrlListCreateInput = {
  /** Connect existing "MetadataAttributionUrl" nodes to the new "Metadata" node, through the "Metadata.metadataAttributionUrlList" relation. */
  connect?: InputMaybe<Array<MetadataAttributionUrlWhereUniqueInput>>;
  /** Create new "MetadataAttributionUrl" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataAttributionUrlList" relation. */
  create?: InputMaybe<Array<MetadataAttributionUrlWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataAttributionUrlListUpdateInput = {
  /** Connect existing "MetadataAttributionUrl" nodes to the existing "Metadata" node, through the "Metadata.metadataAttributionUrlList" relation. */
  connect?: InputMaybe<Array<MetadataAttributionUrlWhereUniqueInput>>;
  /** Create new "MetadataAttributionUrl" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAttributionUrlList" relation. */
  create?: InputMaybe<Array<MetadataAttributionUrlWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataAttributionUrl" nodes from the "Metadata.metadataAttributionUrlList" relation. */
  delete?: InputMaybe<Array<MetadataAttributionUrlWhereUniqueInput>>;
  /** Update existing "MetadataAttributionUrl" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAttributionUrlList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataAttributionUrlListUpdateInput>>;
  /** Upsert existing "MetadataAttributionUrl" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAttributionUrlList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataAttributionUrlListUpdateInput>>;
};

export type MetadataNestedMetadataAudienceListCreateInput = {
  /** Connect existing "MetadataAudience" nodes to the new "Metadata" node, through the "Metadata.metadataAudienceList" relation. */
  connect?: InputMaybe<Array<MetadataAudienceWhereUniqueInput>>;
  /** Create new "MetadataAudience" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataAudienceList" relation. */
  create?: InputMaybe<Array<MetadataAudienceWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataAudienceListUpdateInput = {
  /** Connect existing "MetadataAudience" nodes to the existing "Metadata" node, through the "Metadata.metadataAudienceList" relation. */
  connect?: InputMaybe<Array<MetadataAudienceWhereUniqueInput>>;
  /** Create new "MetadataAudience" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAudienceList" relation. */
  create?: InputMaybe<Array<MetadataAudienceWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataAudience" nodes from the "Metadata.metadataAudienceList" relation. */
  delete?: InputMaybe<Array<MetadataAudienceWhereUniqueInput>>;
  /** Update existing "MetadataAudience" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAudienceList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataAudienceListUpdateInput>>;
  /** Upsert existing "MetadataAudience" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAudienceList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataAudienceListUpdateInput>>;
};

export type MetadataNestedMetadataAuthorsPositionListCreateInput = {
  /** Connect existing "MetadataAuthorsPosition" nodes to the new "Metadata" node, through the "Metadata.metadataAuthorsPositionList" relation. */
  connect?: InputMaybe<Array<MetadataAuthorsPositionWhereUniqueInput>>;
  /** Create new "MetadataAuthorsPosition" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataAuthorsPositionList" relation. */
  create?: InputMaybe<Array<MetadataAuthorsPositionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataAuthorsPositionListUpdateInput = {
  /** Connect existing "MetadataAuthorsPosition" nodes to the existing "Metadata" node, through the "Metadata.metadataAuthorsPositionList" relation. */
  connect?: InputMaybe<Array<MetadataAuthorsPositionWhereUniqueInput>>;
  /** Create new "MetadataAuthorsPosition" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAuthorsPositionList" relation. */
  create?: InputMaybe<Array<MetadataAuthorsPositionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataAuthorsPosition" nodes from the "Metadata.metadataAuthorsPositionList" relation. */
  delete?: InputMaybe<Array<MetadataAuthorsPositionWhereUniqueInput>>;
  /** Update existing "MetadataAuthorsPosition" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAuthorsPositionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataAuthorsPositionListUpdateInput>>;
  /** Upsert existing "MetadataAuthorsPosition" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataAuthorsPositionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataAuthorsPositionListUpdateInput>>;
};

export type MetadataNestedMetadataBackupNameListCreateInput = {
  /** Connect existing "MetadataBackupName" nodes to the new "Metadata" node, through the "Metadata.metadataBackupNameList" relation. */
  connect?: InputMaybe<Array<MetadataBackupNameWhereUniqueInput>>;
  /** Create new "MetadataBackupName" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataBackupNameList" relation. */
  create?: InputMaybe<Array<MetadataBackupNameWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataBackupNameListUpdateInput = {
  /** Connect existing "MetadataBackupName" nodes to the existing "Metadata" node, through the "Metadata.metadataBackupNameList" relation. */
  connect?: InputMaybe<Array<MetadataBackupNameWhereUniqueInput>>;
  /** Create new "MetadataBackupName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBackupNameList" relation. */
  create?: InputMaybe<Array<MetadataBackupNameWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataBackupName" nodes from the "Metadata.metadataBackupNameList" relation. */
  delete?: InputMaybe<Array<MetadataBackupNameWhereUniqueInput>>;
  /** Update existing "MetadataBackupName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBackupNameList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataBackupNameListUpdateInput>>;
  /** Upsert existing "MetadataBackupName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBackupNameList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataBackupNameListUpdateInput>>;
};

export type MetadataNestedMetadataBitmapGrayscalePictureListCreateInput = {
  /** Connect existing "MetadataBitmapGrayscalePicture" nodes to the new "Metadata" node, through the "Metadata.metadataBitmapGrayscalePictureList" relation. */
  connect?: InputMaybe<Array<MetadataBitmapGrayscalePictureWhereUniqueInput>>;
  /** Create new "MetadataBitmapGrayscalePicture" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataBitmapGrayscalePictureList" relation. */
  create?: InputMaybe<Array<MetadataBitmapGrayscalePictureWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataBitmapGrayscalePictureListUpdateInput = {
  /** Connect existing "MetadataBitmapGrayscalePicture" nodes to the existing "Metadata" node, through the "Metadata.metadataBitmapGrayscalePictureList" relation. */
  connect?: InputMaybe<Array<MetadataBitmapGrayscalePictureWhereUniqueInput>>;
  /** Create new "MetadataBitmapGrayscalePicture" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBitmapGrayscalePictureList" relation. */
  create?: InputMaybe<Array<MetadataBitmapGrayscalePictureWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataBitmapGrayscalePicture" nodes from the "Metadata.metadataBitmapGrayscalePictureList" relation. */
  delete?: InputMaybe<Array<MetadataBitmapGrayscalePictureWhereUniqueInput>>;
  /** Update existing "MetadataBitmapGrayscalePicture" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBitmapGrayscalePictureList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataBitmapGrayscalePictureListUpdateInput>>;
  /** Upsert existing "MetadataBitmapGrayscalePicture" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBitmapGrayscalePictureList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataBitmapGrayscalePictureListUpdateInput>>;
};

export type MetadataNestedMetadataBrandListCreateInput = {
  /** Connect existing "MetadataBrand" nodes to the new "Metadata" node, through the "Metadata.metadataBrandList" relation. */
  connect?: InputMaybe<Array<MetadataBrandWhereUniqueInput>>;
  /** Create new "MetadataBrand" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataBrandList" relation. */
  create?: InputMaybe<Array<MetadataBrandWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataBrandListUpdateInput = {
  /** Connect existing "MetadataBrand" nodes to the existing "Metadata" node, through the "Metadata.metadataBrandList" relation. */
  connect?: InputMaybe<Array<MetadataBrandWhereUniqueInput>>;
  /** Create new "MetadataBrand" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBrandList" relation. */
  create?: InputMaybe<Array<MetadataBrandWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataBrand" nodes from the "Metadata.metadataBrandList" relation. */
  delete?: InputMaybe<Array<MetadataBrandWhereUniqueInput>>;
  /** Update existing "MetadataBrand" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBrandList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataBrandListUpdateInput>>;
  /** Upsert existing "MetadataBrand" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBrandList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataBrandListUpdateInput>>;
};

export type MetadataNestedMetadataBrandPrismaListCreateInput = {
  /** Connect existing "MetadataBrandPrisma" nodes to the new "Metadata" node, through the "Metadata.metadataBrandPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataBrandPrismaWhereUniqueInput>>;
  /** Create new "MetadataBrandPrisma" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataBrandPrismaList" relation. */
  create?: InputMaybe<Array<MetadataBrandPrismaWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataBrandPrismaListUpdateInput = {
  /** Connect existing "MetadataBrandPrisma" nodes to the existing "Metadata" node, through the "Metadata.metadataBrandPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataBrandPrismaWhereUniqueInput>>;
  /** Create new "MetadataBrandPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBrandPrismaList" relation. */
  create?: InputMaybe<Array<MetadataBrandPrismaWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataBrandPrisma" nodes from the "Metadata.metadataBrandPrismaList" relation. */
  delete?: InputMaybe<Array<MetadataBrandPrismaWhereUniqueInput>>;
  /** Update existing "MetadataBrandPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBrandPrismaList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataBrandPrismaListUpdateInput>>;
  /** Upsert existing "MetadataBrandPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBrandPrismaList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataBrandPrismaListUpdateInput>>;
};

export type MetadataNestedMetadataBrandShortNameListCreateInput = {
  /** Connect existing "MetadataBrandShortName" nodes to the new "Metadata" node, through the "Metadata.metadataBrandShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataBrandShortNameWhereUniqueInput>>;
  /** Create new "MetadataBrandShortName" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataBrandShortNameList" relation. */
  create?: InputMaybe<Array<MetadataBrandShortNameWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataBrandShortNameListUpdateInput = {
  /** Connect existing "MetadataBrandShortName" nodes to the existing "Metadata" node, through the "Metadata.metadataBrandShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataBrandShortNameWhereUniqueInput>>;
  /** Create new "MetadataBrandShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBrandShortNameList" relation. */
  create?: InputMaybe<Array<MetadataBrandShortNameWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataBrandShortName" nodes from the "Metadata.metadataBrandShortNameList" relation. */
  delete?: InputMaybe<Array<MetadataBrandShortNameWhereUniqueInput>>;
  /** Update existing "MetadataBrandShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBrandShortNameList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataBrandShortNameListUpdateInput>>;
  /** Upsert existing "MetadataBrandShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataBrandShortNameList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataBrandShortNameListUpdateInput>>;
};

export type MetadataNestedMetadataCaptionWriterListCreateInput = {
  /** Connect existing "MetadataCaptionWriter" nodes to the new "Metadata" node, through the "Metadata.metadataCaptionWriterList" relation. */
  connect?: InputMaybe<Array<MetadataCaptionWriterWhereUniqueInput>>;
  /** Create new "MetadataCaptionWriter" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCaptionWriterList" relation. */
  create?: InputMaybe<Array<MetadataCaptionWriterWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCaptionWriterListUpdateInput = {
  /** Connect existing "MetadataCaptionWriter" nodes to the existing "Metadata" node, through the "Metadata.metadataCaptionWriterList" relation. */
  connect?: InputMaybe<Array<MetadataCaptionWriterWhereUniqueInput>>;
  /** Create new "MetadataCaptionWriter" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCaptionWriterList" relation. */
  create?: InputMaybe<Array<MetadataCaptionWriterWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCaptionWriter" nodes from the "Metadata.metadataCaptionWriterList" relation. */
  delete?: InputMaybe<Array<MetadataCaptionWriterWhereUniqueInput>>;
  /** Update existing "MetadataCaptionWriter" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCaptionWriterList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCaptionWriterListUpdateInput>>;
  /** Upsert existing "MetadataCaptionWriter" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCaptionWriterList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCaptionWriterListUpdateInput>>;
};

export type MetadataNestedMetadataCategoryListCreateInput = {
  /** Connect existing "MetadataCategory" nodes to the new "Metadata" node, through the "Metadata.metadataCategoryList" relation. */
  connect?: InputMaybe<Array<MetadataCategoryWhereUniqueInput>>;
  /** Create new "MetadataCategory" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCategoryList" relation. */
  create?: InputMaybe<Array<MetadataCategoryWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCategoryListUpdateInput = {
  /** Connect existing "MetadataCategory" nodes to the existing "Metadata" node, through the "Metadata.metadataCategoryList" relation. */
  connect?: InputMaybe<Array<MetadataCategoryWhereUniqueInput>>;
  /** Create new "MetadataCategory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCategoryList" relation. */
  create?: InputMaybe<Array<MetadataCategoryWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCategory" nodes from the "Metadata.metadataCategoryList" relation. */
  delete?: InputMaybe<Array<MetadataCategoryWhereUniqueInput>>;
  /** Update existing "MetadataCategory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCategoryList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCategoryListUpdateInput>>;
  /** Upsert existing "MetadataCategory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCategoryList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCategoryListUpdateInput>>;
};

export type MetadataNestedMetadataCategoryPrismaListCreateInput = {
  /** Connect existing "MetadataCategoryPrisma" nodes to the new "Metadata" node, through the "Metadata.metadataCategoryPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataCategoryPrismaWhereUniqueInput>>;
  /** Create new "MetadataCategoryPrisma" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCategoryPrismaList" relation. */
  create?: InputMaybe<Array<MetadataCategoryPrismaWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCategoryPrismaListUpdateInput = {
  /** Connect existing "MetadataCategoryPrisma" nodes to the existing "Metadata" node, through the "Metadata.metadataCategoryPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataCategoryPrismaWhereUniqueInput>>;
  /** Create new "MetadataCategoryPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCategoryPrismaList" relation. */
  create?: InputMaybe<Array<MetadataCategoryPrismaWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCategoryPrisma" nodes from the "Metadata.metadataCategoryPrismaList" relation. */
  delete?: InputMaybe<Array<MetadataCategoryPrismaWhereUniqueInput>>;
  /** Update existing "MetadataCategoryPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCategoryPrismaList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCategoryPrismaListUpdateInput>>;
  /** Upsert existing "MetadataCategoryPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCategoryPrismaList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCategoryPrismaListUpdateInput>>;
};

export type MetadataNestedMetadataCertificateListCreateInput = {
  /** Connect existing "MetadataCertificate" nodes to the new "Metadata" node, through the "Metadata.metadataCertificateList" relation. */
  connect?: InputMaybe<Array<MetadataCertificateWhereUniqueInput>>;
  /** Create new "MetadataCertificate" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCertificateList" relation. */
  create?: InputMaybe<Array<MetadataCertificateWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCertificateListUpdateInput = {
  /** Connect existing "MetadataCertificate" nodes to the existing "Metadata" node, through the "Metadata.metadataCertificateList" relation. */
  connect?: InputMaybe<Array<MetadataCertificateWhereUniqueInput>>;
  /** Create new "MetadataCertificate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCertificateList" relation. */
  create?: InputMaybe<Array<MetadataCertificateWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCertificate" nodes from the "Metadata.metadataCertificateList" relation. */
  delete?: InputMaybe<Array<MetadataCertificateWhereUniqueInput>>;
  /** Update existing "MetadataCertificate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCertificateList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCertificateListUpdateInput>>;
  /** Upsert existing "MetadataCertificate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCertificateList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCertificateListUpdateInput>>;
};

export type MetadataNestedMetadataCiAdrCityListCreateInput = {
  /** Connect existing "MetadataCiAdrCity" nodes to the new "Metadata" node, through the "Metadata.metadataCiAdrCityList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCityWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCity" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCiAdrCityList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCityWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCiAdrCityListUpdateInput = {
  /** Connect existing "MetadataCiAdrCity" nodes to the existing "Metadata" node, through the "Metadata.metadataCiAdrCityList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCityWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCity" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrCityList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCityWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCiAdrCity" nodes from the "Metadata.metadataCiAdrCityList" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrCityWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrCity" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrCityList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCiAdrCityListUpdateInput>>;
  /** Upsert existing "MetadataCiAdrCity" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrCityList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCiAdrCityListUpdateInput>>;
};

export type MetadataNestedMetadataCiAdrCtryListCreateInput = {
  /** Connect existing "MetadataCiAdrCtry" nodes to the new "Metadata" node, through the "Metadata.metadataCiAdrCtryList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCtryWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCtry" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCiAdrCtryList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCtryWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCiAdrCtryListUpdateInput = {
  /** Connect existing "MetadataCiAdrCtry" nodes to the existing "Metadata" node, through the "Metadata.metadataCiAdrCtryList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCtryWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCtry" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrCtryList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCtryWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCiAdrCtry" nodes from the "Metadata.metadataCiAdrCtryList" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrCtryWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrCtry" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrCtryList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCiAdrCtryListUpdateInput>>;
  /** Upsert existing "MetadataCiAdrCtry" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrCtryList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCiAdrCtryListUpdateInput>>;
};

export type MetadataNestedMetadataCiAdrExtadrListCreateInput = {
  /** Connect existing "MetadataCiAdrExtadr" nodes to the new "Metadata" node, through the "Metadata.metadataCiAdrExtadrList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrExtadrWhereUniqueInput>>;
  /** Create new "MetadataCiAdrExtadr" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCiAdrExtadrList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrExtadrWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCiAdrExtadrListUpdateInput = {
  /** Connect existing "MetadataCiAdrExtadr" nodes to the existing "Metadata" node, through the "Metadata.metadataCiAdrExtadrList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrExtadrWhereUniqueInput>>;
  /** Create new "MetadataCiAdrExtadr" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrExtadrList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrExtadrWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCiAdrExtadr" nodes from the "Metadata.metadataCiAdrExtadrList" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrExtadrWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrExtadr" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrExtadrList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCiAdrExtadrListUpdateInput>>;
  /** Upsert existing "MetadataCiAdrExtadr" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrExtadrList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCiAdrExtadrListUpdateInput>>;
};

export type MetadataNestedMetadataCiAdrPcodeListCreateInput = {
  /** Connect existing "MetadataCiAdrPcode" nodes to the new "Metadata" node, through the "Metadata.metadataCiAdrPcodeList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrPcodeWhereUniqueInput>>;
  /** Create new "MetadataCiAdrPcode" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCiAdrPcodeList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrPcodeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCiAdrPcodeListUpdateInput = {
  /** Connect existing "MetadataCiAdrPcode" nodes to the existing "Metadata" node, through the "Metadata.metadataCiAdrPcodeList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrPcodeWhereUniqueInput>>;
  /** Create new "MetadataCiAdrPcode" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrPcodeList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrPcodeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCiAdrPcode" nodes from the "Metadata.metadataCiAdrPcodeList" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrPcodeWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrPcode" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrPcodeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCiAdrPcodeListUpdateInput>>;
  /** Upsert existing "MetadataCiAdrPcode" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrPcodeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCiAdrPcodeListUpdateInput>>;
};

export type MetadataNestedMetadataCiAdrRegionListCreateInput = {
  /** Connect existing "MetadataCiAdrRegion" nodes to the new "Metadata" node, through the "Metadata.metadataCiAdrRegionList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrRegionWhereUniqueInput>>;
  /** Create new "MetadataCiAdrRegion" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCiAdrRegionList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrRegionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCiAdrRegionListUpdateInput = {
  /** Connect existing "MetadataCiAdrRegion" nodes to the existing "Metadata" node, through the "Metadata.metadataCiAdrRegionList" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrRegionWhereUniqueInput>>;
  /** Create new "MetadataCiAdrRegion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrRegionList" relation. */
  create?: InputMaybe<Array<MetadataCiAdrRegionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCiAdrRegion" nodes from the "Metadata.metadataCiAdrRegionList" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrRegionWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrRegion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrRegionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCiAdrRegionListUpdateInput>>;
  /** Upsert existing "MetadataCiAdrRegion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiAdrRegionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCiAdrRegionListUpdateInput>>;
};

export type MetadataNestedMetadataCiEmailWorkListCreateInput = {
  /** Connect existing "MetadataCiEmailWork" nodes to the new "Metadata" node, through the "Metadata.metadataCiEmailWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiEmailWorkWhereUniqueInput>>;
  /** Create new "MetadataCiEmailWork" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCiEmailWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiEmailWorkWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCiEmailWorkListUpdateInput = {
  /** Connect existing "MetadataCiEmailWork" nodes to the existing "Metadata" node, through the "Metadata.metadataCiEmailWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiEmailWorkWhereUniqueInput>>;
  /** Create new "MetadataCiEmailWork" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiEmailWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiEmailWorkWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCiEmailWork" nodes from the "Metadata.metadataCiEmailWorkList" relation. */
  delete?: InputMaybe<Array<MetadataCiEmailWorkWhereUniqueInput>>;
  /** Update existing "MetadataCiEmailWork" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiEmailWorkList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCiEmailWorkListUpdateInput>>;
  /** Upsert existing "MetadataCiEmailWork" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiEmailWorkList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCiEmailWorkListUpdateInput>>;
};

export type MetadataNestedMetadataCiTelWorkListCreateInput = {
  /** Connect existing "MetadataCiTelWork" nodes to the new "Metadata" node, through the "Metadata.metadataCiTelWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiTelWorkWhereUniqueInput>>;
  /** Create new "MetadataCiTelWork" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCiTelWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiTelWorkWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCiTelWorkListUpdateInput = {
  /** Connect existing "MetadataCiTelWork" nodes to the existing "Metadata" node, through the "Metadata.metadataCiTelWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiTelWorkWhereUniqueInput>>;
  /** Create new "MetadataCiTelWork" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiTelWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiTelWorkWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCiTelWork" nodes from the "Metadata.metadataCiTelWorkList" relation. */
  delete?: InputMaybe<Array<MetadataCiTelWorkWhereUniqueInput>>;
  /** Update existing "MetadataCiTelWork" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiTelWorkList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCiTelWorkListUpdateInput>>;
  /** Upsert existing "MetadataCiTelWork" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiTelWorkList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCiTelWorkListUpdateInput>>;
};

export type MetadataNestedMetadataCityListCreateInput = {
  /** Connect existing "MetadataCity" nodes to the new "Metadata" node, through the "Metadata.metadataCityList" relation. */
  connect?: InputMaybe<Array<MetadataCityWhereUniqueInput>>;
  /** Create new "MetadataCity" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCityList" relation. */
  create?: InputMaybe<Array<MetadataCityWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCityListUpdateInput = {
  /** Connect existing "MetadataCity" nodes to the existing "Metadata" node, through the "Metadata.metadataCityList" relation. */
  connect?: InputMaybe<Array<MetadataCityWhereUniqueInput>>;
  /** Create new "MetadataCity" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCityList" relation. */
  create?: InputMaybe<Array<MetadataCityWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCity" nodes from the "Metadata.metadataCityList" relation. */
  delete?: InputMaybe<Array<MetadataCityWhereUniqueInput>>;
  /** Update existing "MetadataCity" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCityList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCityListUpdateInput>>;
  /** Upsert existing "MetadataCity" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCityList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCityListUpdateInput>>;
};

export type MetadataNestedMetadataCiUrlWorkListCreateInput = {
  /** Connect existing "MetadataCiUrlWork" nodes to the new "Metadata" node, through the "Metadata.metadataCiUrlWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiUrlWorkWhereUniqueInput>>;
  /** Create new "MetadataCiUrlWork" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCiUrlWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiUrlWorkWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCiUrlWorkListUpdateInput = {
  /** Connect existing "MetadataCiUrlWork" nodes to the existing "Metadata" node, through the "Metadata.metadataCiUrlWorkList" relation. */
  connect?: InputMaybe<Array<MetadataCiUrlWorkWhereUniqueInput>>;
  /** Create new "MetadataCiUrlWork" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiUrlWorkList" relation. */
  create?: InputMaybe<Array<MetadataCiUrlWorkWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCiUrlWork" nodes from the "Metadata.metadataCiUrlWorkList" relation. */
  delete?: InputMaybe<Array<MetadataCiUrlWorkWhereUniqueInput>>;
  /** Update existing "MetadataCiUrlWork" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiUrlWorkList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCiUrlWorkListUpdateInput>>;
  /** Upsert existing "MetadataCiUrlWork" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCiUrlWorkList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCiUrlWorkListUpdateInput>>;
};

export type MetadataNestedMetadataClassifyListCreateInput = {
  /** Connect existing "MetadataClassify" nodes to the new "Metadata" node, through the "Metadata.metadataClassifyList" relation. */
  connect?: InputMaybe<Array<MetadataClassifyWhereUniqueInput>>;
  /** Create new "MetadataClassify" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataClassifyList" relation. */
  create?: InputMaybe<Array<MetadataClassifyWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataClassifyListUpdateInput = {
  /** Connect existing "MetadataClassify" nodes to the existing "Metadata" node, through the "Metadata.metadataClassifyList" relation. */
  connect?: InputMaybe<Array<MetadataClassifyWhereUniqueInput>>;
  /** Create new "MetadataClassify" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataClassifyList" relation. */
  create?: InputMaybe<Array<MetadataClassifyWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataClassify" nodes from the "Metadata.metadataClassifyList" relation. */
  delete?: InputMaybe<Array<MetadataClassifyWhereUniqueInput>>;
  /** Update existing "MetadataClassify" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataClassifyList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataClassifyListUpdateInput>>;
  /** Upsert existing "MetadataClassify" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataClassifyList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataClassifyListUpdateInput>>;
};

export type MetadataNestedMetadataCompanyListCreateInput = {
  /** Connect existing "MetadataCompany" nodes to the new "Metadata" node, through the "Metadata.metadataCompanyList" relation. */
  connect?: InputMaybe<Array<MetadataCompanyWhereUniqueInput>>;
  /** Create new "MetadataCompany" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCompanyList" relation. */
  create?: InputMaybe<Array<MetadataCompanyWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCompanyListUpdateInput = {
  /** Connect existing "MetadataCompany" nodes to the existing "Metadata" node, through the "Metadata.metadataCompanyList" relation. */
  connect?: InputMaybe<Array<MetadataCompanyWhereUniqueInput>>;
  /** Create new "MetadataCompany" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCompanyList" relation. */
  create?: InputMaybe<Array<MetadataCompanyWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCompany" nodes from the "Metadata.metadataCompanyList" relation. */
  delete?: InputMaybe<Array<MetadataCompanyWhereUniqueInput>>;
  /** Update existing "MetadataCompany" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCompanyList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCompanyListUpdateInput>>;
  /** Upsert existing "MetadataCompany" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCompanyList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCompanyListUpdateInput>>;
};

export type MetadataNestedMetadataCompanyShortNameListCreateInput = {
  /** Connect existing "MetadataCompanyShortName" nodes to the new "Metadata" node, through the "Metadata.metadataCompanyShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataCompanyShortNameWhereUniqueInput>>;
  /** Create new "MetadataCompanyShortName" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCompanyShortNameList" relation. */
  create?: InputMaybe<Array<MetadataCompanyShortNameWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCompanyShortNameListUpdateInput = {
  /** Connect existing "MetadataCompanyShortName" nodes to the existing "Metadata" node, through the "Metadata.metadataCompanyShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataCompanyShortNameWhereUniqueInput>>;
  /** Create new "MetadataCompanyShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCompanyShortNameList" relation. */
  create?: InputMaybe<Array<MetadataCompanyShortNameWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCompanyShortName" nodes from the "Metadata.metadataCompanyShortNameList" relation. */
  delete?: InputMaybe<Array<MetadataCompanyShortNameWhereUniqueInput>>;
  /** Update existing "MetadataCompanyShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCompanyShortNameList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCompanyShortNameListUpdateInput>>;
  /** Upsert existing "MetadataCompanyShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCompanyShortNameList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCompanyShortNameListUpdateInput>>;
};

export type MetadataNestedMetadataConfidentialityListCreateInput = {
  /** Connect existing "MetadataConfidentiality" nodes to the new "Metadata" node, through the "Metadata.metadataConfidentialityList" relation. */
  connect?: InputMaybe<Array<MetadataConfidentialityWhereUniqueInput>>;
  /** Create new "MetadataConfidentiality" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataConfidentialityList" relation. */
  create?: InputMaybe<Array<MetadataConfidentialityWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataConfidentialityListUpdateInput = {
  /** Connect existing "MetadataConfidentiality" nodes to the existing "Metadata" node, through the "Metadata.metadataConfidentialityList" relation. */
  connect?: InputMaybe<Array<MetadataConfidentialityWhereUniqueInput>>;
  /** Create new "MetadataConfidentiality" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataConfidentialityList" relation. */
  create?: InputMaybe<Array<MetadataConfidentialityWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataConfidentiality" nodes from the "Metadata.metadataConfidentialityList" relation. */
  delete?: InputMaybe<Array<MetadataConfidentialityWhereUniqueInput>>;
  /** Update existing "MetadataConfidentiality" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataConfidentialityList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataConfidentialityListUpdateInput>>;
  /** Upsert existing "MetadataConfidentiality" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataConfidentialityList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataConfidentialityListUpdateInput>>;
};

export type MetadataNestedMetadataContainedInListCreateInput = {
  /** Connect existing "MetadataContainedIn" nodes to the new "Metadata" node, through the "Metadata.metadataContainedInList" relation. */
  connect?: InputMaybe<Array<MetadataContainedInWhereUniqueInput>>;
  /** Create new "MetadataContainedIn" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataContainedInList" relation. */
  create?: InputMaybe<Array<MetadataContainedInWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataContainedInListUpdateInput = {
  /** Connect existing "MetadataContainedIn" nodes to the existing "Metadata" node, through the "Metadata.metadataContainedInList" relation. */
  connect?: InputMaybe<Array<MetadataContainedInWhereUniqueInput>>;
  /** Create new "MetadataContainedIn" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataContainedInList" relation. */
  create?: InputMaybe<Array<MetadataContainedInWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataContainedIn" nodes from the "Metadata.metadataContainedInList" relation. */
  delete?: InputMaybe<Array<MetadataContainedInWhereUniqueInput>>;
  /** Update existing "MetadataContainedIn" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataContainedInList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataContainedInListUpdateInput>>;
  /** Upsert existing "MetadataContainedIn" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataContainedInList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataContainedInListUpdateInput>>;
};

export type MetadataNestedMetadataContentMediaKindListCreateInput = {
  /** Connect existing "MetadataContentMediaKind" nodes to the new "Metadata" node, through the "Metadata.metadataContentMediaKindList" relation. */
  connect?: InputMaybe<Array<MetadataContentMediaKindWhereUniqueInput>>;
  /** Create new "MetadataContentMediaKind" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataContentMediaKindList" relation. */
  create?: InputMaybe<Array<MetadataContentMediaKindWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataContentMediaKindListUpdateInput = {
  /** Connect existing "MetadataContentMediaKind" nodes to the existing "Metadata" node, through the "Metadata.metadataContentMediaKindList" relation. */
  connect?: InputMaybe<Array<MetadataContentMediaKindWhereUniqueInput>>;
  /** Create new "MetadataContentMediaKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataContentMediaKindList" relation. */
  create?: InputMaybe<Array<MetadataContentMediaKindWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataContentMediaKind" nodes from the "Metadata.metadataContentMediaKindList" relation. */
  delete?: InputMaybe<Array<MetadataContentMediaKindWhereUniqueInput>>;
  /** Update existing "MetadataContentMediaKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataContentMediaKindList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataContentMediaKindListUpdateInput>>;
  /** Upsert existing "MetadataContentMediaKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataContentMediaKindList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataContentMediaKindListUpdateInput>>;
};

export type MetadataNestedMetadataContentValueListCreateInput = {
  /** Connect existing "MetadataContentValue" nodes to the new "Metadata" node, through the "Metadata.metadataContentValueList" relation. */
  connect?: InputMaybe<Array<MetadataContentValueWhereUniqueInput>>;
  /** Create new "MetadataContentValue" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataContentValueList" relation. */
  create?: InputMaybe<Array<MetadataContentValueWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataContentValueListUpdateInput = {
  /** Connect existing "MetadataContentValue" nodes to the existing "Metadata" node, through the "Metadata.metadataContentValueList" relation. */
  connect?: InputMaybe<Array<MetadataContentValueWhereUniqueInput>>;
  /** Create new "MetadataContentValue" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataContentValueList" relation. */
  create?: InputMaybe<Array<MetadataContentValueWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataContentValue" nodes from the "Metadata.metadataContentValueList" relation. */
  delete?: InputMaybe<Array<MetadataContentValueWhereUniqueInput>>;
  /** Update existing "MetadataContentValue" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataContentValueList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataContentValueListUpdateInput>>;
  /** Upsert existing "MetadataContentValue" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataContentValueList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataContentValueListUpdateInput>>;
};

export type MetadataNestedMetadataCopyrightLayerListCreateInput = {
  /** Connect existing "MetadataCopyrightLayer" nodes to the new "Metadata" node, through the "Metadata.metadataCopyrightLayerList" relation. */
  connect?: InputMaybe<Array<MetadataCopyrightLayerWhereUniqueInput>>;
  /** Create new "MetadataCopyrightLayer" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCopyrightLayerList" relation. */
  create?: InputMaybe<Array<MetadataCopyrightLayerWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCopyrightLayerListUpdateInput = {
  /** Connect existing "MetadataCopyrightLayer" nodes to the existing "Metadata" node, through the "Metadata.metadataCopyrightLayerList" relation. */
  connect?: InputMaybe<Array<MetadataCopyrightLayerWhereUniqueInput>>;
  /** Create new "MetadataCopyrightLayer" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCopyrightLayerList" relation. */
  create?: InputMaybe<Array<MetadataCopyrightLayerWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCopyrightLayer" nodes from the "Metadata.metadataCopyrightLayerList" relation. */
  delete?: InputMaybe<Array<MetadataCopyrightLayerWhereUniqueInput>>;
  /** Update existing "MetadataCopyrightLayer" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCopyrightLayerList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCopyrightLayerListUpdateInput>>;
  /** Upsert existing "MetadataCopyrightLayer" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCopyrightLayerList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCopyrightLayerListUpdateInput>>;
};

export type MetadataNestedMetadataCountryCodeListCreateInput = {
  /** Connect existing "MetadataCountryCode" nodes to the new "Metadata" node, through the "Metadata.metadataCountryCodeList" relation. */
  connect?: InputMaybe<Array<MetadataCountryCodeWhereUniqueInput>>;
  /** Create new "MetadataCountryCode" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCountryCodeList" relation. */
  create?: InputMaybe<Array<MetadataCountryCodeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCountryCodeListUpdateInput = {
  /** Connect existing "MetadataCountryCode" nodes to the existing "Metadata" node, through the "Metadata.metadataCountryCodeList" relation. */
  connect?: InputMaybe<Array<MetadataCountryCodeWhereUniqueInput>>;
  /** Create new "MetadataCountryCode" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCountryCodeList" relation. */
  create?: InputMaybe<Array<MetadataCountryCodeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCountryCode" nodes from the "Metadata.metadataCountryCodeList" relation. */
  delete?: InputMaybe<Array<MetadataCountryCodeWhereUniqueInput>>;
  /** Update existing "MetadataCountryCode" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCountryCodeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCountryCodeListUpdateInput>>;
  /** Upsert existing "MetadataCountryCode" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCountryCodeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCountryCodeListUpdateInput>>;
};

export type MetadataNestedMetadataCountryListCreateInput = {
  /** Connect existing "MetadataCountry" nodes to the new "Metadata" node, through the "Metadata.metadataCountryList" relation. */
  connect?: InputMaybe<Array<MetadataCountryWhereUniqueInput>>;
  /** Create new "MetadataCountry" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCountryList" relation. */
  create?: InputMaybe<Array<MetadataCountryWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCountryListUpdateInput = {
  /** Connect existing "MetadataCountry" nodes to the existing "Metadata" node, through the "Metadata.metadataCountryList" relation. */
  connect?: InputMaybe<Array<MetadataCountryWhereUniqueInput>>;
  /** Create new "MetadataCountry" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCountryList" relation. */
  create?: InputMaybe<Array<MetadataCountryWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCountry" nodes from the "Metadata.metadataCountryList" relation. */
  delete?: InputMaybe<Array<MetadataCountryWhereUniqueInput>>;
  /** Update existing "MetadataCountry" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCountryList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCountryListUpdateInput>>;
  /** Upsert existing "MetadataCountry" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCountryList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCountryListUpdateInput>>;
};

export type MetadataNestedMetadataCreatedTimeListCreateInput = {
  /** Connect existing "MetadataCreatedTime" nodes to the new "Metadata" node, through the "Metadata.metadataCreatedTimeList" relation. */
  connect?: InputMaybe<Array<MetadataCreatedTimeWhereUniqueInput>>;
  /** Create new "MetadataCreatedTime" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCreatedTimeList" relation. */
  create?: InputMaybe<Array<MetadataCreatedTimeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCreatedTimeListUpdateInput = {
  /** Connect existing "MetadataCreatedTime" nodes to the existing "Metadata" node, through the "Metadata.metadataCreatedTimeList" relation. */
  connect?: InputMaybe<Array<MetadataCreatedTimeWhereUniqueInput>>;
  /** Create new "MetadataCreatedTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreatedTimeList" relation. */
  create?: InputMaybe<Array<MetadataCreatedTimeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCreatedTime" nodes from the "Metadata.metadataCreatedTimeList" relation. */
  delete?: InputMaybe<Array<MetadataCreatedTimeWhereUniqueInput>>;
  /** Update existing "MetadataCreatedTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreatedTimeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCreatedTimeListUpdateInput>>;
  /** Upsert existing "MetadataCreatedTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreatedTimeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCreatedTimeListUpdateInput>>;
};

export type MetadataNestedMetadataCreatorListCreateInput = {
  /** Connect existing "MetadataCreator" nodes to the new "Metadata" node, through the "Metadata.metadataCreatorList" relation. */
  connect?: InputMaybe<Array<MetadataCreatorWhereUniqueInput>>;
  /** Create new "MetadataCreator" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCreatorList" relation. */
  create?: InputMaybe<Array<MetadataCreatorWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCreatorListUpdateInput = {
  /** Connect existing "MetadataCreator" nodes to the existing "Metadata" node, through the "Metadata.metadataCreatorList" relation. */
  connect?: InputMaybe<Array<MetadataCreatorWhereUniqueInput>>;
  /** Create new "MetadataCreator" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreatorList" relation. */
  create?: InputMaybe<Array<MetadataCreatorWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCreator" nodes from the "Metadata.metadataCreatorList" relation. */
  delete?: InputMaybe<Array<MetadataCreatorWhereUniqueInput>>;
  /** Update existing "MetadataCreator" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreatorList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCreatorListUpdateInput>>;
  /** Upsert existing "MetadataCreator" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreatorList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCreatorListUpdateInput>>;
};

export type MetadataNestedMetadataCreatorToolListCreateInput = {
  /** Connect existing "MetadataCreatorTool" nodes to the new "Metadata" node, through the "Metadata.metadataCreatorToolList" relation. */
  connect?: InputMaybe<Array<MetadataCreatorToolWhereUniqueInput>>;
  /** Create new "MetadataCreatorTool" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCreatorToolList" relation. */
  create?: InputMaybe<Array<MetadataCreatorToolWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCreatorToolListUpdateInput = {
  /** Connect existing "MetadataCreatorTool" nodes to the existing "Metadata" node, through the "Metadata.metadataCreatorToolList" relation. */
  connect?: InputMaybe<Array<MetadataCreatorToolWhereUniqueInput>>;
  /** Create new "MetadataCreatorTool" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreatorToolList" relation. */
  create?: InputMaybe<Array<MetadataCreatorToolWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCreatorTool" nodes from the "Metadata.metadataCreatorToolList" relation. */
  delete?: InputMaybe<Array<MetadataCreatorToolWhereUniqueInput>>;
  /** Update existing "MetadataCreatorTool" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreatorToolList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCreatorToolListUpdateInput>>;
  /** Upsert existing "MetadataCreatorTool" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreatorToolList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCreatorToolListUpdateInput>>;
};

export type MetadataNestedMetadataCreditListCreateInput = {
  /** Connect existing "MetadataCredit" nodes to the new "Metadata" node, through the "Metadata.metadataCreditList" relation. */
  connect?: InputMaybe<Array<MetadataCreditWhereUniqueInput>>;
  /** Create new "MetadataCredit" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCreditList" relation. */
  create?: InputMaybe<Array<MetadataCreditWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCreditListUpdateInput = {
  /** Connect existing "MetadataCredit" nodes to the existing "Metadata" node, through the "Metadata.metadataCreditList" relation. */
  connect?: InputMaybe<Array<MetadataCreditWhereUniqueInput>>;
  /** Create new "MetadataCredit" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreditList" relation. */
  create?: InputMaybe<Array<MetadataCreditWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCredit" nodes from the "Metadata.metadataCreditList" relation. */
  delete?: InputMaybe<Array<MetadataCreditWhereUniqueInput>>;
  /** Update existing "MetadataCredit" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreditList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCreditListUpdateInput>>;
  /** Upsert existing "MetadataCredit" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCreditList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCreditListUpdateInput>>;
};

export type MetadataNestedMetadataCustomField14ListCreateInput = {
  /** Connect existing "MetadataCustomField14" nodes to the new "Metadata" node, through the "Metadata.metadataCustomField14List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField14WhereUniqueInput>>;
  /** Create new "MetadataCustomField14" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCustomField14List" relation. */
  create?: InputMaybe<Array<MetadataCustomField14WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCustomField14ListUpdateInput = {
  /** Connect existing "MetadataCustomField14" nodes to the existing "Metadata" node, through the "Metadata.metadataCustomField14List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField14WhereUniqueInput>>;
  /** Create new "MetadataCustomField14" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField14List" relation. */
  create?: InputMaybe<Array<MetadataCustomField14WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCustomField14" nodes from the "Metadata.metadataCustomField14List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField14WhereUniqueInput>>;
  /** Update existing "MetadataCustomField14" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField14List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCustomField14ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField14" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField14List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCustomField14ListUpdateInput>>;
};

export type MetadataNestedMetadataCustomField15ListCreateInput = {
  /** Connect existing "MetadataCustomField15" nodes to the new "Metadata" node, through the "Metadata.metadataCustomField15List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField15WhereUniqueInput>>;
  /** Create new "MetadataCustomField15" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCustomField15List" relation. */
  create?: InputMaybe<Array<MetadataCustomField15WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCustomField15ListUpdateInput = {
  /** Connect existing "MetadataCustomField15" nodes to the existing "Metadata" node, through the "Metadata.metadataCustomField15List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField15WhereUniqueInput>>;
  /** Create new "MetadataCustomField15" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField15List" relation. */
  create?: InputMaybe<Array<MetadataCustomField15WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCustomField15" nodes from the "Metadata.metadataCustomField15List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField15WhereUniqueInput>>;
  /** Update existing "MetadataCustomField15" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField15List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCustomField15ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField15" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField15List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCustomField15ListUpdateInput>>;
};

export type MetadataNestedMetadataCustomField16ListCreateInput = {
  /** Connect existing "MetadataCustomField16" nodes to the new "Metadata" node, through the "Metadata.metadataCustomField16List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField16WhereUniqueInput>>;
  /** Create new "MetadataCustomField16" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCustomField16List" relation. */
  create?: InputMaybe<Array<MetadataCustomField16WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCustomField16ListUpdateInput = {
  /** Connect existing "MetadataCustomField16" nodes to the existing "Metadata" node, through the "Metadata.metadataCustomField16List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField16WhereUniqueInput>>;
  /** Create new "MetadataCustomField16" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField16List" relation. */
  create?: InputMaybe<Array<MetadataCustomField16WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCustomField16" nodes from the "Metadata.metadataCustomField16List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField16WhereUniqueInput>>;
  /** Update existing "MetadataCustomField16" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField16List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCustomField16ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField16" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField16List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCustomField16ListUpdateInput>>;
};

export type MetadataNestedMetadataCustomField17ListCreateInput = {
  /** Connect existing "MetadataCustomField17" nodes to the new "Metadata" node, through the "Metadata.metadataCustomField17List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField17WhereUniqueInput>>;
  /** Create new "MetadataCustomField17" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCustomField17List" relation. */
  create?: InputMaybe<Array<MetadataCustomField17WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCustomField17ListUpdateInput = {
  /** Connect existing "MetadataCustomField17" nodes to the existing "Metadata" node, through the "Metadata.metadataCustomField17List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField17WhereUniqueInput>>;
  /** Create new "MetadataCustomField17" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField17List" relation. */
  create?: InputMaybe<Array<MetadataCustomField17WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCustomField17" nodes from the "Metadata.metadataCustomField17List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField17WhereUniqueInput>>;
  /** Update existing "MetadataCustomField17" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField17List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCustomField17ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField17" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField17List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCustomField17ListUpdateInput>>;
};

export type MetadataNestedMetadataCustomField20ListCreateInput = {
  /** Connect existing "MetadataCustomField20" nodes to the new "Metadata" node, through the "Metadata.metadataCustomField20List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField20WhereUniqueInput>>;
  /** Create new "MetadataCustomField20" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCustomField20List" relation. */
  create?: InputMaybe<Array<MetadataCustomField20WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCustomField20ListUpdateInput = {
  /** Connect existing "MetadataCustomField20" nodes to the existing "Metadata" node, through the "Metadata.metadataCustomField20List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField20WhereUniqueInput>>;
  /** Create new "MetadataCustomField20" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField20List" relation. */
  create?: InputMaybe<Array<MetadataCustomField20WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCustomField20" nodes from the "Metadata.metadataCustomField20List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField20WhereUniqueInput>>;
  /** Update existing "MetadataCustomField20" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField20List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCustomField20ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField20" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField20List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCustomField20ListUpdateInput>>;
};

export type MetadataNestedMetadataCustomField5ListCreateInput = {
  /** Connect existing "MetadataCustomField5" nodes to the new "Metadata" node, through the "Metadata.metadataCustomField5List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField5WhereUniqueInput>>;
  /** Create new "MetadataCustomField5" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCustomField5List" relation. */
  create?: InputMaybe<Array<MetadataCustomField5WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCustomField5ListUpdateInput = {
  /** Connect existing "MetadataCustomField5" nodes to the existing "Metadata" node, through the "Metadata.metadataCustomField5List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField5WhereUniqueInput>>;
  /** Create new "MetadataCustomField5" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField5List" relation. */
  create?: InputMaybe<Array<MetadataCustomField5WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCustomField5" nodes from the "Metadata.metadataCustomField5List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField5WhereUniqueInput>>;
  /** Update existing "MetadataCustomField5" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField5List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCustomField5ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField5" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField5List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCustomField5ListUpdateInput>>;
};

export type MetadataNestedMetadataCustomField6ListCreateInput = {
  /** Connect existing "MetadataCustomField6" nodes to the new "Metadata" node, through the "Metadata.metadataCustomField6List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField6WhereUniqueInput>>;
  /** Create new "MetadataCustomField6" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCustomField6List" relation. */
  create?: InputMaybe<Array<MetadataCustomField6WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCustomField6ListUpdateInput = {
  /** Connect existing "MetadataCustomField6" nodes to the existing "Metadata" node, through the "Metadata.metadataCustomField6List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField6WhereUniqueInput>>;
  /** Create new "MetadataCustomField6" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField6List" relation. */
  create?: InputMaybe<Array<MetadataCustomField6WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCustomField6" nodes from the "Metadata.metadataCustomField6List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField6WhereUniqueInput>>;
  /** Update existing "MetadataCustomField6" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField6List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCustomField6ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField6" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField6List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCustomField6ListUpdateInput>>;
};

export type MetadataNestedMetadataCustomField7ListCreateInput = {
  /** Connect existing "MetadataCustomField7" nodes to the new "Metadata" node, through the "Metadata.metadataCustomField7List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField7WhereUniqueInput>>;
  /** Create new "MetadataCustomField7" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCustomField7List" relation. */
  create?: InputMaybe<Array<MetadataCustomField7WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCustomField7ListUpdateInput = {
  /** Connect existing "MetadataCustomField7" nodes to the existing "Metadata" node, through the "Metadata.metadataCustomField7List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField7WhereUniqueInput>>;
  /** Create new "MetadataCustomField7" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField7List" relation. */
  create?: InputMaybe<Array<MetadataCustomField7WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCustomField7" nodes from the "Metadata.metadataCustomField7List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField7WhereUniqueInput>>;
  /** Update existing "MetadataCustomField7" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField7List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCustomField7ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField7" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField7List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCustomField7ListUpdateInput>>;
};

export type MetadataNestedMetadataCustomField9ListCreateInput = {
  /** Connect existing "MetadataCustomField9" nodes to the new "Metadata" node, through the "Metadata.metadataCustomField9List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField9WhereUniqueInput>>;
  /** Create new "MetadataCustomField9" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataCustomField9List" relation. */
  create?: InputMaybe<Array<MetadataCustomField9WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataCustomField9ListUpdateInput = {
  /** Connect existing "MetadataCustomField9" nodes to the existing "Metadata" node, through the "Metadata.metadataCustomField9List" relation. */
  connect?: InputMaybe<Array<MetadataCustomField9WhereUniqueInput>>;
  /** Create new "MetadataCustomField9" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField9List" relation. */
  create?: InputMaybe<Array<MetadataCustomField9WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataCustomField9" nodes from the "Metadata.metadataCustomField9List" relation. */
  delete?: InputMaybe<Array<MetadataCustomField9WhereUniqueInput>>;
  /** Update existing "MetadataCustomField9" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField9List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataCustomField9ListUpdateInput>>;
  /** Upsert existing "MetadataCustomField9" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataCustomField9List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataCustomField9ListUpdateInput>>;
};

export type MetadataNestedMetadataDateCreatedListCreateInput = {
  /** Connect existing "MetadataDateCreated" nodes to the new "Metadata" node, through the "Metadata.metadataDateCreatedList" relation. */
  connect?: InputMaybe<Array<MetadataDateCreatedWhereUniqueInput>>;
  /** Create new "MetadataDateCreated" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDateCreatedList" relation. */
  create?: InputMaybe<Array<MetadataDateCreatedWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDateCreatedListUpdateInput = {
  /** Connect existing "MetadataDateCreated" nodes to the existing "Metadata" node, through the "Metadata.metadataDateCreatedList" relation. */
  connect?: InputMaybe<Array<MetadataDateCreatedWhereUniqueInput>>;
  /** Create new "MetadataDateCreated" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDateCreatedList" relation. */
  create?: InputMaybe<Array<MetadataDateCreatedWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDateCreated" nodes from the "Metadata.metadataDateCreatedList" relation. */
  delete?: InputMaybe<Array<MetadataDateCreatedWhereUniqueInput>>;
  /** Update existing "MetadataDateCreated" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDateCreatedList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDateCreatedListUpdateInput>>;
  /** Upsert existing "MetadataDateCreated" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDateCreatedList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDateCreatedListUpdateInput>>;
};

export type MetadataNestedMetadataDateTimeDigitizedListCreateInput = {
  /** Connect existing "MetadataDateTimeDigitized" nodes to the new "Metadata" node, through the "Metadata.metadataDateTimeDigitizedList" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeDigitizedWhereUniqueInput>>;
  /** Create new "MetadataDateTimeDigitized" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDateTimeDigitizedList" relation. */
  create?: InputMaybe<Array<MetadataDateTimeDigitizedWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDateTimeDigitizedListUpdateInput = {
  /** Connect existing "MetadataDateTimeDigitized" nodes to the existing "Metadata" node, through the "Metadata.metadataDateTimeDigitizedList" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeDigitizedWhereUniqueInput>>;
  /** Create new "MetadataDateTimeDigitized" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDateTimeDigitizedList" relation. */
  create?: InputMaybe<Array<MetadataDateTimeDigitizedWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDateTimeDigitized" nodes from the "Metadata.metadataDateTimeDigitizedList" relation. */
  delete?: InputMaybe<Array<MetadataDateTimeDigitizedWhereUniqueInput>>;
  /** Update existing "MetadataDateTimeDigitized" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDateTimeDigitizedList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDateTimeDigitizedListUpdateInput>>;
  /** Upsert existing "MetadataDateTimeDigitized" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDateTimeDigitizedList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDateTimeDigitizedListUpdateInput>>;
};

export type MetadataNestedMetadataDateTimeOriginalListCreateInput = {
  /** Connect existing "MetadataDateTimeOriginal" nodes to the new "Metadata" node, through the "Metadata.metadataDateTimeOriginalList" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeOriginalWhereUniqueInput>>;
  /** Create new "MetadataDateTimeOriginal" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDateTimeOriginalList" relation. */
  create?: InputMaybe<Array<MetadataDateTimeOriginalWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDateTimeOriginalListUpdateInput = {
  /** Connect existing "MetadataDateTimeOriginal" nodes to the existing "Metadata" node, through the "Metadata.metadataDateTimeOriginalList" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeOriginalWhereUniqueInput>>;
  /** Create new "MetadataDateTimeOriginal" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDateTimeOriginalList" relation. */
  create?: InputMaybe<Array<MetadataDateTimeOriginalWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDateTimeOriginal" nodes from the "Metadata.metadataDateTimeOriginalList" relation. */
  delete?: InputMaybe<Array<MetadataDateTimeOriginalWhereUniqueInput>>;
  /** Update existing "MetadataDateTimeOriginal" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDateTimeOriginalList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDateTimeOriginalListUpdateInput>>;
  /** Upsert existing "MetadataDateTimeOriginal" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDateTimeOriginalList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDateTimeOriginalListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryAccountListCreateInput = {
  /** Connect existing "MetadataDeliveryAccount" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryAccountList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryAccountWhereUniqueInput>>;
  /** Create new "MetadataDeliveryAccount" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryAccountList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryAccountWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryAccountListUpdateInput = {
  /** Connect existing "MetadataDeliveryAccount" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryAccountList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryAccountWhereUniqueInput>>;
  /** Create new "MetadataDeliveryAccount" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryAccountList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryAccountWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryAccount" nodes from the "Metadata.metadataDeliveryAccountList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryAccountWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryAccount" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryAccountList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryAccountListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryAccount" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryAccountList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryAccountListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryCompanyListCreateInput = {
  /** Connect existing "MetadataDeliveryCompany" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryCompanyList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCompanyWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCompany" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryCompanyList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCompanyWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryCompanyListUpdateInput = {
  /** Connect existing "MetadataDeliveryCompany" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryCompanyList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCompanyWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCompany" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryCompanyList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCompanyWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryCompany" nodes from the "Metadata.metadataDeliveryCompanyList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryCompanyWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryCompany" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryCompanyList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryCompanyListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryCompany" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryCompanyList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryCompanyListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryCopyrightListCreateInput = {
  /** Connect existing "MetadataDeliveryCopyright" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryCopyrightList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCopyrightWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCopyright" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryCopyrightList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCopyrightWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryCopyrightListUpdateInput = {
  /** Connect existing "MetadataDeliveryCopyright" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryCopyrightList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCopyrightWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCopyright" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryCopyrightList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCopyrightWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryCopyright" nodes from the "Metadata.metadataDeliveryCopyrightList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryCopyrightWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryCopyright" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryCopyrightList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryCopyrightListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryCopyright" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryCopyrightList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryCopyrightListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryDateFolderListCreateInput = {
  /** Connect existing "MetadataDeliveryDateFolder" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryDateFolderList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateFolder" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryDateFolderList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateFolderWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryDateFolderListUpdateInput = {
  /** Connect existing "MetadataDeliveryDateFolder" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryDateFolderList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateFolder" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDateFolderList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateFolderWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryDateFolder" nodes from the "Metadata.metadataDeliveryDateFolderList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDateFolderWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDateFolder" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDateFolderList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryDateFolderListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDateFolder" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDateFolderList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryDateFolderListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryDateTimeListCreateInput = {
  /** Connect existing "MetadataDeliveryDateTime" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateTimeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateTime" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateTimeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryDateTimeListUpdateInput = {
  /** Connect existing "MetadataDeliveryDateTime" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateTimeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateTimeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryDateTime" nodes from the "Metadata.metadataDeliveryDateTimeList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDateTimeWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDateTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDateTimeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryDateTimeListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDateTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDateTimeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryDateTimeListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryDcCreatorListCreateInput = {
  /** Connect existing "MetadataDeliveryDcCreator" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryDcCreatorList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcCreatorWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcCreator" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryDcCreatorList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcCreatorWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryDcCreatorListUpdateInput = {
  /** Connect existing "MetadataDeliveryDcCreator" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryDcCreatorList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcCreatorWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcCreator" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDcCreatorList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcCreatorWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryDcCreator" nodes from the "Metadata.metadataDeliveryDcCreatorList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDcCreatorWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDcCreator" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDcCreatorList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryDcCreatorListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDcCreator" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDcCreatorList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryDcCreatorListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryDcRightsListCreateInput = {
  /** Connect existing "MetadataDeliveryDcRights" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryDcRightsList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcRightsWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcRights" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryDcRightsList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcRightsWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryDcRightsListUpdateInput = {
  /** Connect existing "MetadataDeliveryDcRights" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryDcRightsList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcRightsWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcRights" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDcRightsList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcRightsWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryDcRights" nodes from the "Metadata.metadataDeliveryDcRightsList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDcRightsWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDcRights" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDcRightsList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryDcRightsListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDcRights" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryDcRightsList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryDcRightsListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryFileTypeListCreateInput = {
  /** Connect existing "MetadataDeliveryFileType" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryFileTypeList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFileTypeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFileType" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryFileTypeList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFileTypeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryFileTypeListUpdateInput = {
  /** Connect existing "MetadataDeliveryFileType" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryFileTypeList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFileTypeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFileType" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryFileTypeList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFileTypeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryFileType" nodes from the "Metadata.metadataDeliveryFileTypeList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryFileTypeWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryFileType" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryFileTypeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryFileTypeListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryFileType" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryFileTypeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryFileTypeListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryFolderListCreateInput = {
  /** Connect existing "MetadataDeliveryFolder" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryFolderList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFolder" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryFolderList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFolderWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryFolderListUpdateInput = {
  /** Connect existing "MetadataDeliveryFolder" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryFolderList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFolder" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryFolderList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFolderWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryFolder" nodes from the "Metadata.metadataDeliveryFolderList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryFolderWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryFolder" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryFolderList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryFolderListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryFolder" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryFolderList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryFolderListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryKindListCreateInput = {
  /** Connect existing "MetadataDeliveryKind" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryKindList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryKindWhereUniqueInput>>;
  /** Create new "MetadataDeliveryKind" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryKindList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryKindWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryKindListUpdateInput = {
  /** Connect existing "MetadataDeliveryKind" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryKindList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryKindWhereUniqueInput>>;
  /** Create new "MetadataDeliveryKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryKindList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryKindWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryKind" nodes from the "Metadata.metadataDeliveryKindList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryKindWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryKindList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryKindListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryKindList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryKindListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryPathListCreateInput = {
  /** Connect existing "MetadataDeliveryPath" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryPathList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPathWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPath" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryPathList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPathWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryPathListUpdateInput = {
  /** Connect existing "MetadataDeliveryPath" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryPathList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPathWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPath" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPathList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPathWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryPath" nodes from the "Metadata.metadataDeliveryPathList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPathWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPath" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPathList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryPathListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPath" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPathList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryPathListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryPersonShownintheImageListCreateInput = {
  /** Connect existing "MetadataDeliveryPersonShownintheImage" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryPersonShownintheImageList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPersonShownintheImage" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryPersonShownintheImageList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryPersonShownintheImageListUpdateInput = {
  /** Connect existing "MetadataDeliveryPersonShownintheImage" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryPersonShownintheImageList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPersonShownintheImage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPersonShownintheImageList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryPersonShownintheImage" nodes from the "Metadata.metadataDeliveryPersonShownintheImageList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPersonShownintheImage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPersonShownintheImageList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryPersonShownintheImageListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPersonShownintheImage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPersonShownintheImageList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryPersonShownintheImageListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryPhotoshopCreditListCreateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopCredit" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopCreditList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopCredit" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopCreditList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryPhotoshopCreditListUpdateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopCredit" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopCreditList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopCredit" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopCreditList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryPhotoshopCredit" nodes from the "Metadata.metadataDeliveryPhotoshopCreditList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPhotoshopCredit" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopCreditList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryPhotoshopCreditListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPhotoshopCredit" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopCreditList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryPhotoshopCreditListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryPhotoshopSourceListCreateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopSource" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopSourceList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopSource" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopSourceList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryPhotoshopSourceListUpdateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopSource" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopSourceList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopSource" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopSourceList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryPhotoshopSource" nodes from the "Metadata.metadataDeliveryPhotoshopSourceList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPhotoshopSource" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopSourceList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryPhotoshopSourceListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPhotoshopSource" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryPhotoshopSourceList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryPhotoshopSourceListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryServiceListCreateInput = {
  /** Connect existing "MetadataDeliveryService" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryServiceList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryServiceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryService" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryServiceList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryServiceWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryServiceListUpdateInput = {
  /** Connect existing "MetadataDeliveryService" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryServiceList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryServiceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryService" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryServiceList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryServiceWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryService" nodes from the "Metadata.metadataDeliveryServiceList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryServiceWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryService" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryServiceList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryServiceListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryService" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryServiceList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryServiceListUpdateInput>>;
};

export type MetadataNestedMetadataDeliverySubjectListCreateInput = {
  /** Connect existing "MetadataDeliverySubject" nodes to the new "Metadata" node, through the "Metadata.metadataDeliverySubjectList" relation. */
  connect?: InputMaybe<Array<MetadataDeliverySubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliverySubject" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliverySubjectList" relation. */
  create?: InputMaybe<Array<MetadataDeliverySubjectWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliverySubjectListUpdateInput = {
  /** Connect existing "MetadataDeliverySubject" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliverySubjectList" relation. */
  connect?: InputMaybe<Array<MetadataDeliverySubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliverySubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliverySubjectList" relation. */
  create?: InputMaybe<Array<MetadataDeliverySubjectWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliverySubject" nodes from the "Metadata.metadataDeliverySubjectList" relation. */
  delete?: InputMaybe<Array<MetadataDeliverySubjectWhereUniqueInput>>;
  /** Update existing "MetadataDeliverySubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliverySubjectList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliverySubjectListUpdateInput>>;
  /** Upsert existing "MetadataDeliverySubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliverySubjectList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliverySubjectListUpdateInput>>;
};

export type MetadataNestedMetadataDeliveryUnderSubjectListCreateInput = {
  /** Connect existing "MetadataDeliveryUnderSubject" nodes to the new "Metadata" node, through the "Metadata.metadataDeliveryUnderSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliveryUnderSubject" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDeliveryUnderSubjectList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryUnderSubjectWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDeliveryUnderSubjectListUpdateInput = {
  /** Connect existing "MetadataDeliveryUnderSubject" nodes to the existing "Metadata" node, through the "Metadata.metadataDeliveryUnderSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliveryUnderSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryUnderSubjectList" relation. */
  create?: InputMaybe<Array<MetadataDeliveryUnderSubjectWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDeliveryUnderSubject" nodes from the "Metadata.metadataDeliveryUnderSubjectList" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryUnderSubjectWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryUnderSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryUnderSubjectList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDeliveryUnderSubjectListUpdateInput>>;
  /** Upsert existing "MetadataDeliveryUnderSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDeliveryUnderSubjectList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDeliveryUnderSubjectListUpdateInput>>;
};

export type MetadataNestedMetadataDepartmentListCreateInput = {
  /** Connect existing "MetadataDepartment" nodes to the new "Metadata" node, through the "Metadata.metadataDepartmentList" relation. */
  connect?: InputMaybe<Array<MetadataDepartmentWhereUniqueInput>>;
  /** Create new "MetadataDepartment" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDepartmentList" relation. */
  create?: InputMaybe<Array<MetadataDepartmentWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDepartmentListUpdateInput = {
  /** Connect existing "MetadataDepartment" nodes to the existing "Metadata" node, through the "Metadata.metadataDepartmentList" relation. */
  connect?: InputMaybe<Array<MetadataDepartmentWhereUniqueInput>>;
  /** Create new "MetadataDepartment" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDepartmentList" relation. */
  create?: InputMaybe<Array<MetadataDepartmentWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDepartment" nodes from the "Metadata.metadataDepartmentList" relation. */
  delete?: InputMaybe<Array<MetadataDepartmentWhereUniqueInput>>;
  /** Update existing "MetadataDepartment" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDepartmentList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDepartmentListUpdateInput>>;
  /** Upsert existing "MetadataDepartment" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDepartmentList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDepartmentListUpdateInput>>;
};

export type MetadataNestedMetadataDescriptionListCreateInput = {
  /** Connect existing "MetadataDescription" nodes to the new "Metadata" node, through the "Metadata.metadataDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataDescriptionWhereUniqueInput>>;
  /** Create new "MetadataDescription" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataDescriptionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDescriptionListUpdateInput = {
  /** Connect existing "MetadataDescription" nodes to the existing "Metadata" node, through the "Metadata.metadataDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataDescriptionWhereUniqueInput>>;
  /** Create new "MetadataDescription" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataDescriptionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDescription" nodes from the "Metadata.metadataDescriptionList" relation. */
  delete?: InputMaybe<Array<MetadataDescriptionWhereUniqueInput>>;
  /** Update existing "MetadataDescription" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDescriptionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDescriptionListUpdateInput>>;
  /** Upsert existing "MetadataDescription" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDescriptionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDescriptionListUpdateInput>>;
};

export type MetadataNestedMetadataDigitalAssetUrlListCreateInput = {
  /** Connect existing "MetadataDigitalAssetUrl" nodes to the new "Metadata" node, through the "Metadata.metadataDigitalAssetUrlList" relation. */
  connect?: InputMaybe<Array<MetadataDigitalAssetUrlWhereUniqueInput>>;
  /** Create new "MetadataDigitalAssetUrl" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDigitalAssetUrlList" relation. */
  create?: InputMaybe<Array<MetadataDigitalAssetUrlWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDigitalAssetUrlListUpdateInput = {
  /** Connect existing "MetadataDigitalAssetUrl" nodes to the existing "Metadata" node, through the "Metadata.metadataDigitalAssetUrlList" relation. */
  connect?: InputMaybe<Array<MetadataDigitalAssetUrlWhereUniqueInput>>;
  /** Create new "MetadataDigitalAssetUrl" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDigitalAssetUrlList" relation. */
  create?: InputMaybe<Array<MetadataDigitalAssetUrlWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDigitalAssetUrl" nodes from the "Metadata.metadataDigitalAssetUrlList" relation. */
  delete?: InputMaybe<Array<MetadataDigitalAssetUrlWhereUniqueInput>>;
  /** Update existing "MetadataDigitalAssetUrl" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDigitalAssetUrlList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDigitalAssetUrlListUpdateInput>>;
  /** Upsert existing "MetadataDigitalAssetUrl" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDigitalAssetUrlList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDigitalAssetUrlListUpdateInput>>;
};

export type MetadataNestedMetadataDivisionListCreateInput = {
  /** Connect existing "MetadataDivision" nodes to the new "Metadata" node, through the "Metadata.metadataDivisionList" relation. */
  connect?: InputMaybe<Array<MetadataDivisionWhereUniqueInput>>;
  /** Create new "MetadataDivision" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDivisionList" relation. */
  create?: InputMaybe<Array<MetadataDivisionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDivisionListUpdateInput = {
  /** Connect existing "MetadataDivision" nodes to the existing "Metadata" node, through the "Metadata.metadataDivisionList" relation. */
  connect?: InputMaybe<Array<MetadataDivisionWhereUniqueInput>>;
  /** Create new "MetadataDivision" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDivisionList" relation. */
  create?: InputMaybe<Array<MetadataDivisionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDivision" nodes from the "Metadata.metadataDivisionList" relation. */
  delete?: InputMaybe<Array<MetadataDivisionWhereUniqueInput>>;
  /** Update existing "MetadataDivision" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDivisionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDivisionListUpdateInput>>;
  /** Upsert existing "MetadataDivision" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDivisionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDivisionListUpdateInput>>;
};

export type MetadataNestedMetadataDocumentTextListCreateInput = {
  /** Connect existing "MetadataDocumentText" nodes to the new "Metadata" node, through the "Metadata.metadataDocumentTextList" relation. */
  connect?: InputMaybe<Array<MetadataDocumentTextWhereUniqueInput>>;
  /** Create new "MetadataDocumentText" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataDocumentTextList" relation. */
  create?: InputMaybe<Array<MetadataDocumentTextWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataDocumentTextListUpdateInput = {
  /** Connect existing "MetadataDocumentText" nodes to the existing "Metadata" node, through the "Metadata.metadataDocumentTextList" relation. */
  connect?: InputMaybe<Array<MetadataDocumentTextWhereUniqueInput>>;
  /** Create new "MetadataDocumentText" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDocumentTextList" relation. */
  create?: InputMaybe<Array<MetadataDocumentTextWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataDocumentText" nodes from the "Metadata.metadataDocumentTextList" relation. */
  delete?: InputMaybe<Array<MetadataDocumentTextWhereUniqueInput>>;
  /** Update existing "MetadataDocumentText" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDocumentTextList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataDocumentTextListUpdateInput>>;
  /** Upsert existing "MetadataDocumentText" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataDocumentTextList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataDocumentTextListUpdateInput>>;
};

export type MetadataNestedMetadataEditionListCreateInput = {
  /** Connect existing "MetadataEdition" nodes to the new "Metadata" node, through the "Metadata.metadataEditionList" relation. */
  connect?: InputMaybe<Array<MetadataEditionWhereUniqueInput>>;
  /** Create new "MetadataEdition" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataEditionList" relation. */
  create?: InputMaybe<Array<MetadataEditionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataEditionListUpdateInput = {
  /** Connect existing "MetadataEdition" nodes to the existing "Metadata" node, through the "Metadata.metadataEditionList" relation. */
  connect?: InputMaybe<Array<MetadataEditionWhereUniqueInput>>;
  /** Create new "MetadataEdition" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEditionList" relation. */
  create?: InputMaybe<Array<MetadataEditionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataEdition" nodes from the "Metadata.metadataEditionList" relation. */
  delete?: InputMaybe<Array<MetadataEditionWhereUniqueInput>>;
  /** Update existing "MetadataEdition" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEditionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataEditionListUpdateInput>>;
  /** Upsert existing "MetadataEdition" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEditionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataEditionListUpdateInput>>;
};

export type MetadataNestedMetadataEditorialVersionListCreateInput = {
  /** Connect existing "MetadataEditorialVersion" nodes to the new "Metadata" node, through the "Metadata.metadataEditorialVersionList" relation. */
  connect?: InputMaybe<Array<MetadataEditorialVersionWhereUniqueInput>>;
  /** Create new "MetadataEditorialVersion" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataEditorialVersionList" relation. */
  create?: InputMaybe<Array<MetadataEditorialVersionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataEditorialVersionListUpdateInput = {
  /** Connect existing "MetadataEditorialVersion" nodes to the existing "Metadata" node, through the "Metadata.metadataEditorialVersionList" relation. */
  connect?: InputMaybe<Array<MetadataEditorialVersionWhereUniqueInput>>;
  /** Create new "MetadataEditorialVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEditorialVersionList" relation. */
  create?: InputMaybe<Array<MetadataEditorialVersionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataEditorialVersion" nodes from the "Metadata.metadataEditorialVersionList" relation. */
  delete?: InputMaybe<Array<MetadataEditorialVersionWhereUniqueInput>>;
  /** Update existing "MetadataEditorialVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEditorialVersionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataEditorialVersionListUpdateInput>>;
  /** Upsert existing "MetadataEditorialVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEditorialVersionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataEditorialVersionListUpdateInput>>;
};

export type MetadataNestedMetadataEditStatusListCreateInput = {
  /** Connect existing "MetadataEditStatus" nodes to the new "Metadata" node, through the "Metadata.metadataEditStatusList" relation. */
  connect?: InputMaybe<Array<MetadataEditStatusWhereUniqueInput>>;
  /** Create new "MetadataEditStatus" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataEditStatusList" relation. */
  create?: InputMaybe<Array<MetadataEditStatusWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataEditStatusListUpdateInput = {
  /** Connect existing "MetadataEditStatus" nodes to the existing "Metadata" node, through the "Metadata.metadataEditStatusList" relation. */
  connect?: InputMaybe<Array<MetadataEditStatusWhereUniqueInput>>;
  /** Create new "MetadataEditStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEditStatusList" relation. */
  create?: InputMaybe<Array<MetadataEditStatusWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataEditStatus" nodes from the "Metadata.metadataEditStatusList" relation. */
  delete?: InputMaybe<Array<MetadataEditStatusWhereUniqueInput>>;
  /** Update existing "MetadataEditStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEditStatusList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataEditStatusListUpdateInput>>;
  /** Upsert existing "MetadataEditStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEditStatusList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataEditStatusListUpdateInput>>;
};

export type MetadataNestedMetadataEnvironnementPhotoListCreateInput = {
  /** Connect existing "MetadataEnvironnementPhoto" nodes to the new "Metadata" node, through the "Metadata.metadataEnvironnementPhotoList" relation. */
  connect?: InputMaybe<Array<MetadataEnvironnementPhotoWhereUniqueInput>>;
  /** Create new "MetadataEnvironnementPhoto" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataEnvironnementPhotoList" relation. */
  create?: InputMaybe<Array<MetadataEnvironnementPhotoWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataEnvironnementPhotoListUpdateInput = {
  /** Connect existing "MetadataEnvironnementPhoto" nodes to the existing "Metadata" node, through the "Metadata.metadataEnvironnementPhotoList" relation. */
  connect?: InputMaybe<Array<MetadataEnvironnementPhotoWhereUniqueInput>>;
  /** Create new "MetadataEnvironnementPhoto" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEnvironnementPhotoList" relation. */
  create?: InputMaybe<Array<MetadataEnvironnementPhotoWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataEnvironnementPhoto" nodes from the "Metadata.metadataEnvironnementPhotoList" relation. */
  delete?: InputMaybe<Array<MetadataEnvironnementPhotoWhereUniqueInput>>;
  /** Update existing "MetadataEnvironnementPhoto" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEnvironnementPhotoList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataEnvironnementPhotoListUpdateInput>>;
  /** Upsert existing "MetadataEnvironnementPhoto" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEnvironnementPhotoList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataEnvironnementPhotoListUpdateInput>>;
};

export type MetadataNestedMetadataEquipmentInstitutionListCreateInput = {
  /** Connect existing "MetadataEquipmentInstitution" nodes to the new "Metadata" node, through the "Metadata.metadataEquipmentInstitutionList" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentInstitutionWhereUniqueInput>>;
  /** Create new "MetadataEquipmentInstitution" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataEquipmentInstitutionList" relation. */
  create?: InputMaybe<Array<MetadataEquipmentInstitutionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataEquipmentInstitutionListUpdateInput = {
  /** Connect existing "MetadataEquipmentInstitution" nodes to the existing "Metadata" node, through the "Metadata.metadataEquipmentInstitutionList" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentInstitutionWhereUniqueInput>>;
  /** Create new "MetadataEquipmentInstitution" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEquipmentInstitutionList" relation. */
  create?: InputMaybe<Array<MetadataEquipmentInstitutionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataEquipmentInstitution" nodes from the "Metadata.metadataEquipmentInstitutionList" relation. */
  delete?: InputMaybe<Array<MetadataEquipmentInstitutionWhereUniqueInput>>;
  /** Update existing "MetadataEquipmentInstitution" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEquipmentInstitutionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataEquipmentInstitutionListUpdateInput>>;
  /** Upsert existing "MetadataEquipmentInstitution" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEquipmentInstitutionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataEquipmentInstitutionListUpdateInput>>;
};

export type MetadataNestedMetadataEquipmentManufacturerListCreateInput = {
  /** Connect existing "MetadataEquipmentManufacturer" nodes to the new "Metadata" node, through the "Metadata.metadataEquipmentManufacturerList" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentManufacturerWhereUniqueInput>>;
  /** Create new "MetadataEquipmentManufacturer" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataEquipmentManufacturerList" relation. */
  create?: InputMaybe<Array<MetadataEquipmentManufacturerWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataEquipmentManufacturerListUpdateInput = {
  /** Connect existing "MetadataEquipmentManufacturer" nodes to the existing "Metadata" node, through the "Metadata.metadataEquipmentManufacturerList" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentManufacturerWhereUniqueInput>>;
  /** Create new "MetadataEquipmentManufacturer" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEquipmentManufacturerList" relation. */
  create?: InputMaybe<Array<MetadataEquipmentManufacturerWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataEquipmentManufacturer" nodes from the "Metadata.metadataEquipmentManufacturerList" relation. */
  delete?: InputMaybe<Array<MetadataEquipmentManufacturerWhereUniqueInput>>;
  /** Update existing "MetadataEquipmentManufacturer" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEquipmentManufacturerList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataEquipmentManufacturerListUpdateInput>>;
  /** Upsert existing "MetadataEquipmentManufacturer" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEquipmentManufacturerList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataEquipmentManufacturerListUpdateInput>>;
};

export type MetadataNestedMetadataEventListCreateInput = {
  /** Connect existing "MetadataEvent" nodes to the new "Metadata" node, through the "Metadata.metadataEventList" relation. */
  connect?: InputMaybe<Array<MetadataEventWhereUniqueInput>>;
  /** Create new "MetadataEvent" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataEventList" relation. */
  create?: InputMaybe<Array<MetadataEventWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataEventListUpdateInput = {
  /** Connect existing "MetadataEvent" nodes to the existing "Metadata" node, through the "Metadata.metadataEventList" relation. */
  connect?: InputMaybe<Array<MetadataEventWhereUniqueInput>>;
  /** Create new "MetadataEvent" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEventList" relation. */
  create?: InputMaybe<Array<MetadataEventWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataEvent" nodes from the "Metadata.metadataEventList" relation. */
  delete?: InputMaybe<Array<MetadataEventWhereUniqueInput>>;
  /** Update existing "MetadataEvent" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEventList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataEventListUpdateInput>>;
  /** Upsert existing "MetadataEvent" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataEventList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataEventListUpdateInput>>;
};

export type MetadataNestedMetadataExifListCreateInput = {
  /** Connect existing "MetadataExif" nodes to the new "Metadata" node, through the "Metadata.metadataExifList" relation. */
  connect?: InputMaybe<Array<MetadataExifWhereUniqueInput>>;
  /** Create new "MetadataExif" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataExifList" relation. */
  create?: InputMaybe<Array<MetadataExifWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataExifListUpdateInput = {
  /** Connect existing "MetadataExif" nodes to the existing "Metadata" node, through the "Metadata.metadataExifList" relation. */
  connect?: InputMaybe<Array<MetadataExifWhereUniqueInput>>;
  /** Create new "MetadataExif" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataExifList" relation. */
  create?: InputMaybe<Array<MetadataExifWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataExif" nodes from the "Metadata.metadataExifList" relation. */
  delete?: InputMaybe<Array<MetadataExifWhereUniqueInput>>;
  /** Update existing "MetadataExif" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataExifList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataExifListUpdateInput>>;
  /** Upsert existing "MetadataExif" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataExifList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataExifListUpdateInput>>;
};

export type MetadataNestedMetadataFabStorageListCreateInput = {
  /** Connect existing "MetadataFabStorage" nodes to the new "Metadata" node, through the "Metadata.metadataFabStorageList" relation. */
  connect?: InputMaybe<Array<MetadataFabStorageWhereUniqueInput>>;
  /** Create new "MetadataFabStorage" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataFabStorageList" relation. */
  create?: InputMaybe<Array<MetadataFabStorageWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataFabStorageListUpdateInput = {
  /** Connect existing "MetadataFabStorage" nodes to the existing "Metadata" node, through the "Metadata.metadataFabStorageList" relation. */
  connect?: InputMaybe<Array<MetadataFabStorageWhereUniqueInput>>;
  /** Create new "MetadataFabStorage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFabStorageList" relation. */
  create?: InputMaybe<Array<MetadataFabStorageWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataFabStorage" nodes from the "Metadata.metadataFabStorageList" relation. */
  delete?: InputMaybe<Array<MetadataFabStorageWhereUniqueInput>>;
  /** Update existing "MetadataFabStorage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFabStorageList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataFabStorageListUpdateInput>>;
  /** Upsert existing "MetadataFabStorage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFabStorageList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataFabStorageListUpdateInput>>;
};

export type MetadataNestedMetadataFileCheckForListCreateInput = {
  /** Connect existing "MetadataFileCheckFor" nodes to the new "Metadata" node, through the "Metadata.metadataFileCheckForList" relation. */
  connect?: InputMaybe<Array<MetadataFileCheckForWhereUniqueInput>>;
  /** Create new "MetadataFileCheckFor" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataFileCheckForList" relation. */
  create?: InputMaybe<Array<MetadataFileCheckForWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataFileCheckForListUpdateInput = {
  /** Connect existing "MetadataFileCheckFor" nodes to the existing "Metadata" node, through the "Metadata.metadataFileCheckForList" relation. */
  connect?: InputMaybe<Array<MetadataFileCheckForWhereUniqueInput>>;
  /** Create new "MetadataFileCheckFor" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileCheckForList" relation. */
  create?: InputMaybe<Array<MetadataFileCheckForWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataFileCheckFor" nodes from the "Metadata.metadataFileCheckForList" relation. */
  delete?: InputMaybe<Array<MetadataFileCheckForWhereUniqueInput>>;
  /** Update existing "MetadataFileCheckFor" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileCheckForList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataFileCheckForListUpdateInput>>;
  /** Upsert existing "MetadataFileCheckFor" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileCheckForList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataFileCheckForListUpdateInput>>;
};

export type MetadataNestedMetadataFileInfoBitsPerPixelListCreateInput = {
  /** Connect existing "MetadataFileInfoBitsPerPixel" nodes to the new "Metadata" node, through the "Metadata.metadataFileInfoBitsPerPixelList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWhereUniqueInput>>;
  /** Create new "MetadataFileInfoBitsPerPixel" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataFileInfoBitsPerPixelList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataFileInfoBitsPerPixelListUpdateInput = {
  /** Connect existing "MetadataFileInfoBitsPerPixel" nodes to the existing "Metadata" node, through the "Metadata.metadataFileInfoBitsPerPixelList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWhereUniqueInput>>;
  /** Create new "MetadataFileInfoBitsPerPixel" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoBitsPerPixelList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataFileInfoBitsPerPixel" nodes from the "Metadata.metadataFileInfoBitsPerPixelList" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoBitsPerPixel" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoBitsPerPixelList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataFileInfoBitsPerPixelListUpdateInput>>;
  /** Upsert existing "MetadataFileInfoBitsPerPixel" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoBitsPerPixelList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataFileInfoBitsPerPixelListUpdateInput>>;
};

export type MetadataNestedMetadataFileInfoFramesListCreateInput = {
  /** Connect existing "MetadataFileInfoFrames" nodes to the new "Metadata" node, through the "Metadata.metadataFileInfoFramesList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoFramesWhereUniqueInput>>;
  /** Create new "MetadataFileInfoFrames" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataFileInfoFramesList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoFramesWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataFileInfoFramesListUpdateInput = {
  /** Connect existing "MetadataFileInfoFrames" nodes to the existing "Metadata" node, through the "Metadata.metadataFileInfoFramesList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoFramesWhereUniqueInput>>;
  /** Create new "MetadataFileInfoFrames" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoFramesList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoFramesWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataFileInfoFrames" nodes from the "Metadata.metadataFileInfoFramesList" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoFramesWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoFrames" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoFramesList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataFileInfoFramesListUpdateInput>>;
  /** Upsert existing "MetadataFileInfoFrames" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoFramesList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataFileInfoFramesListUpdateInput>>;
};

export type MetadataNestedMetadataFileInfoPhotometricInterpretationListCreateInput = {
  /** Connect existing "MetadataFileInfoPhotometricInterpretation" nodes to the new "Metadata" node, through the "Metadata.metadataFileInfoPhotometricInterpretationList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWhereUniqueInput>>;
  /** Create new "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataFileInfoPhotometricInterpretationList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataFileInfoPhotometricInterpretationListUpdateInput = {
  /** Connect existing "MetadataFileInfoPhotometricInterpretation" nodes to the existing "Metadata" node, through the "Metadata.metadataFileInfoPhotometricInterpretationList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWhereUniqueInput>>;
  /** Create new "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoPhotometricInterpretationList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataFileInfoPhotometricInterpretation" nodes from the "Metadata.metadataFileInfoPhotometricInterpretationList" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoPhotometricInterpretationList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataFileInfoPhotometricInterpretationListUpdateInput>>;
  /** Upsert existing "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoPhotometricInterpretationList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataFileInfoPhotometricInterpretationListUpdateInput>>;
};

export type MetadataNestedMetadataFileInfoTransferSyntaxListCreateInput = {
  /** Connect existing "MetadataFileInfoTransferSyntax" nodes to the new "Metadata" node, through the "Metadata.metadataFileInfoTransferSyntaxList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWhereUniqueInput>>;
  /** Create new "MetadataFileInfoTransferSyntax" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataFileInfoTransferSyntaxList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataFileInfoTransferSyntaxListUpdateInput = {
  /** Connect existing "MetadataFileInfoTransferSyntax" nodes to the existing "Metadata" node, through the "Metadata.metadataFileInfoTransferSyntaxList" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWhereUniqueInput>>;
  /** Create new "MetadataFileInfoTransferSyntax" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoTransferSyntaxList" relation. */
  create?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataFileInfoTransferSyntax" nodes from the "Metadata.metadataFileInfoTransferSyntaxList" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoTransferSyntax" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoTransferSyntaxList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataFileInfoTransferSyntaxListUpdateInput>>;
  /** Upsert existing "MetadataFileInfoTransferSyntax" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileInfoTransferSyntaxList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataFileInfoTransferSyntaxListUpdateInput>>;
};

export type MetadataNestedMetadataFileStageListCreateInput = {
  /** Connect existing "MetadataFileStage" nodes to the new "Metadata" node, through the "Metadata.metadataFileStageList" relation. */
  connect?: InputMaybe<Array<MetadataFileStageWhereUniqueInput>>;
  /** Create new "MetadataFileStage" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataFileStageList" relation. */
  create?: InputMaybe<Array<MetadataFileStageWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataFileStageListUpdateInput = {
  /** Connect existing "MetadataFileStage" nodes to the existing "Metadata" node, through the "Metadata.metadataFileStageList" relation. */
  connect?: InputMaybe<Array<MetadataFileStageWhereUniqueInput>>;
  /** Create new "MetadataFileStage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileStageList" relation. */
  create?: InputMaybe<Array<MetadataFileStageWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataFileStage" nodes from the "Metadata.metadataFileStageList" relation. */
  delete?: InputMaybe<Array<MetadataFileStageWhereUniqueInput>>;
  /** Update existing "MetadataFileStage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileStageList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataFileStageListUpdateInput>>;
  /** Upsert existing "MetadataFileStage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileStageList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataFileStageListUpdateInput>>;
};

export type MetadataNestedMetadataFileStatusListCreateInput = {
  /** Connect existing "MetadataFileStatus" nodes to the new "Metadata" node, through the "Metadata.metadataFileStatusList" relation. */
  connect?: InputMaybe<Array<MetadataFileStatusWhereUniqueInput>>;
  /** Create new "MetadataFileStatus" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataFileStatusList" relation. */
  create?: InputMaybe<Array<MetadataFileStatusWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataFileStatusListUpdateInput = {
  /** Connect existing "MetadataFileStatus" nodes to the existing "Metadata" node, through the "Metadata.metadataFileStatusList" relation. */
  connect?: InputMaybe<Array<MetadataFileStatusWhereUniqueInput>>;
  /** Create new "MetadataFileStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileStatusList" relation. */
  create?: InputMaybe<Array<MetadataFileStatusWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataFileStatus" nodes from the "Metadata.metadataFileStatusList" relation. */
  delete?: InputMaybe<Array<MetadataFileStatusWhereUniqueInput>>;
  /** Update existing "MetadataFileStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileStatusList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataFileStatusListUpdateInput>>;
  /** Upsert existing "MetadataFileStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileStatusList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataFileStatusListUpdateInput>>;
};

export type MetadataNestedMetadataFileTypeListCreateInput = {
  /** Connect existing "MetadataFileType" nodes to the new "Metadata" node, through the "Metadata.metadataFileTypeList" relation. */
  connect?: InputMaybe<Array<MetadataFileTypeWhereUniqueInput>>;
  /** Create new "MetadataFileType" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataFileTypeList" relation. */
  create?: InputMaybe<Array<MetadataFileTypeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataFileTypeListUpdateInput = {
  /** Connect existing "MetadataFileType" nodes to the existing "Metadata" node, through the "Metadata.metadataFileTypeList" relation. */
  connect?: InputMaybe<Array<MetadataFileTypeWhereUniqueInput>>;
  /** Create new "MetadataFileType" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileTypeList" relation. */
  create?: InputMaybe<Array<MetadataFileTypeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataFileType" nodes from the "Metadata.metadataFileTypeList" relation. */
  delete?: InputMaybe<Array<MetadataFileTypeWhereUniqueInput>>;
  /** Update existing "MetadataFileType" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileTypeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataFileTypeListUpdateInput>>;
  /** Upsert existing "MetadataFileType" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFileTypeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataFileTypeListUpdateInput>>;
};

export type MetadataNestedMetadataFixtureIdentifierListCreateInput = {
  /** Connect existing "MetadataFixtureIdentifier" nodes to the new "Metadata" node, through the "Metadata.metadataFixtureIdentifierList" relation. */
  connect?: InputMaybe<Array<MetadataFixtureIdentifierWhereUniqueInput>>;
  /** Create new "MetadataFixtureIdentifier" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataFixtureIdentifierList" relation. */
  create?: InputMaybe<Array<MetadataFixtureIdentifierWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataFixtureIdentifierListUpdateInput = {
  /** Connect existing "MetadataFixtureIdentifier" nodes to the existing "Metadata" node, through the "Metadata.metadataFixtureIdentifierList" relation. */
  connect?: InputMaybe<Array<MetadataFixtureIdentifierWhereUniqueInput>>;
  /** Create new "MetadataFixtureIdentifier" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFixtureIdentifierList" relation. */
  create?: InputMaybe<Array<MetadataFixtureIdentifierWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataFixtureIdentifier" nodes from the "Metadata.metadataFixtureIdentifierList" relation. */
  delete?: InputMaybe<Array<MetadataFixtureIdentifierWhereUniqueInput>>;
  /** Update existing "MetadataFixtureIdentifier" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFixtureIdentifierList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataFixtureIdentifierListUpdateInput>>;
  /** Upsert existing "MetadataFixtureIdentifier" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFixtureIdentifierList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataFixtureIdentifierListUpdateInput>>;
};

export type MetadataNestedMetadataFormatListCreateInput = {
  /** Connect existing "MetadataFormat" nodes to the new "Metadata" node, through the "Metadata.metadataFormatList" relation. */
  connect?: InputMaybe<Array<MetadataFormatWhereUniqueInput>>;
  /** Create new "MetadataFormat" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataFormatList" relation. */
  create?: InputMaybe<Array<MetadataFormatWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataFormatListUpdateInput = {
  /** Connect existing "MetadataFormat" nodes to the existing "Metadata" node, through the "Metadata.metadataFormatList" relation. */
  connect?: InputMaybe<Array<MetadataFormatWhereUniqueInput>>;
  /** Create new "MetadataFormat" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFormatList" relation. */
  create?: InputMaybe<Array<MetadataFormatWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataFormat" nodes from the "Metadata.metadataFormatList" relation. */
  delete?: InputMaybe<Array<MetadataFormatWhereUniqueInput>>;
  /** Update existing "MetadataFormat" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFormatList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataFormatListUpdateInput>>;
  /** Upsert existing "MetadataFormat" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataFormatList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataFormatListUpdateInput>>;
};

export type MetadataNestedMetadataGpsLatitudeListCreateInput = {
  /** Connect existing "MetadataGpsLatitude" nodes to the new "Metadata" node, through the "Metadata.metadataGpsLatitudeList" relation. */
  connect?: InputMaybe<Array<MetadataGpsLatitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLatitude" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataGpsLatitudeList" relation. */
  create?: InputMaybe<Array<MetadataGpsLatitudeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataGpsLatitudeListUpdateInput = {
  /** Connect existing "MetadataGpsLatitude" nodes to the existing "Metadata" node, through the "Metadata.metadataGpsLatitudeList" relation. */
  connect?: InputMaybe<Array<MetadataGpsLatitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLatitude" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataGpsLatitudeList" relation. */
  create?: InputMaybe<Array<MetadataGpsLatitudeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataGpsLatitude" nodes from the "Metadata.metadataGpsLatitudeList" relation. */
  delete?: InputMaybe<Array<MetadataGpsLatitudeWhereUniqueInput>>;
  /** Update existing "MetadataGpsLatitude" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataGpsLatitudeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataGpsLatitudeListUpdateInput>>;
  /** Upsert existing "MetadataGpsLatitude" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataGpsLatitudeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataGpsLatitudeListUpdateInput>>;
};

export type MetadataNestedMetadataGpsLongitudeListCreateInput = {
  /** Connect existing "MetadataGpsLongitude" nodes to the new "Metadata" node, through the "Metadata.metadataGpsLongitudeList" relation. */
  connect?: InputMaybe<Array<MetadataGpsLongitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLongitude" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataGpsLongitudeList" relation. */
  create?: InputMaybe<Array<MetadataGpsLongitudeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataGpsLongitudeListUpdateInput = {
  /** Connect existing "MetadataGpsLongitude" nodes to the existing "Metadata" node, through the "Metadata.metadataGpsLongitudeList" relation. */
  connect?: InputMaybe<Array<MetadataGpsLongitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLongitude" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataGpsLongitudeList" relation. */
  create?: InputMaybe<Array<MetadataGpsLongitudeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataGpsLongitude" nodes from the "Metadata.metadataGpsLongitudeList" relation. */
  delete?: InputMaybe<Array<MetadataGpsLongitudeWhereUniqueInput>>;
  /** Update existing "MetadataGpsLongitude" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataGpsLongitudeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataGpsLongitudeListUpdateInput>>;
  /** Upsert existing "MetadataGpsLongitude" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataGpsLongitudeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataGpsLongitudeListUpdateInput>>;
};

export type MetadataNestedMetadataGpsProcessingMethodListCreateInput = {
  /** Connect existing "MetadataGpsProcessingMethod" nodes to the new "Metadata" node, through the "Metadata.metadataGpsProcessingMethodList" relation. */
  connect?: InputMaybe<Array<MetadataGpsProcessingMethodWhereUniqueInput>>;
  /** Create new "MetadataGpsProcessingMethod" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataGpsProcessingMethodList" relation. */
  create?: InputMaybe<Array<MetadataGpsProcessingMethodWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataGpsProcessingMethodListUpdateInput = {
  /** Connect existing "MetadataGpsProcessingMethod" nodes to the existing "Metadata" node, through the "Metadata.metadataGpsProcessingMethodList" relation. */
  connect?: InputMaybe<Array<MetadataGpsProcessingMethodWhereUniqueInput>>;
  /** Create new "MetadataGpsProcessingMethod" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataGpsProcessingMethodList" relation. */
  create?: InputMaybe<Array<MetadataGpsProcessingMethodWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataGpsProcessingMethod" nodes from the "Metadata.metadataGpsProcessingMethodList" relation. */
  delete?: InputMaybe<Array<MetadataGpsProcessingMethodWhereUniqueInput>>;
  /** Update existing "MetadataGpsProcessingMethod" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataGpsProcessingMethodList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataGpsProcessingMethodListUpdateInput>>;
  /** Upsert existing "MetadataGpsProcessingMethod" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataGpsProcessingMethodList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataGpsProcessingMethodListUpdateInput>>;
};

export type MetadataNestedMetadataHeaderListCreateInput = {
  /** Connect existing "MetadataHeader" nodes to the new "Metadata" node, through the "Metadata.metadataHeaderList" relation. */
  connect?: InputMaybe<Array<MetadataHeaderWhereUniqueInput>>;
  /** Create new "MetadataHeader" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataHeaderList" relation. */
  create?: InputMaybe<Array<MetadataHeaderWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataHeaderListUpdateInput = {
  /** Connect existing "MetadataHeader" nodes to the existing "Metadata" node, through the "Metadata.metadataHeaderList" relation. */
  connect?: InputMaybe<Array<MetadataHeaderWhereUniqueInput>>;
  /** Create new "MetadataHeader" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHeaderList" relation. */
  create?: InputMaybe<Array<MetadataHeaderWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataHeader" nodes from the "Metadata.metadataHeaderList" relation. */
  delete?: InputMaybe<Array<MetadataHeaderWhereUniqueInput>>;
  /** Update existing "MetadataHeader" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHeaderList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataHeaderListUpdateInput>>;
  /** Upsert existing "MetadataHeader" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHeaderList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataHeaderListUpdateInput>>;
};

export type MetadataNestedMetadataHeaderPrismaListCreateInput = {
  /** Connect existing "MetadataHeaderPrisma" nodes to the new "Metadata" node, through the "Metadata.metadataHeaderPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataHeaderPrismaWhereUniqueInput>>;
  /** Create new "MetadataHeaderPrisma" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataHeaderPrismaList" relation. */
  create?: InputMaybe<Array<MetadataHeaderPrismaWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataHeaderPrismaListUpdateInput = {
  /** Connect existing "MetadataHeaderPrisma" nodes to the existing "Metadata" node, through the "Metadata.metadataHeaderPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataHeaderPrismaWhereUniqueInput>>;
  /** Create new "MetadataHeaderPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHeaderPrismaList" relation. */
  create?: InputMaybe<Array<MetadataHeaderPrismaWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataHeaderPrisma" nodes from the "Metadata.metadataHeaderPrismaList" relation. */
  delete?: InputMaybe<Array<MetadataHeaderPrismaWhereUniqueInput>>;
  /** Update existing "MetadataHeaderPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHeaderPrismaList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataHeaderPrismaListUpdateInput>>;
  /** Upsert existing "MetadataHeaderPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHeaderPrismaList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataHeaderPrismaListUpdateInput>>;
};

export type MetadataNestedMetadataHeadlineListCreateInput = {
  /** Connect existing "MetadataHeadline" nodes to the new "Metadata" node, through the "Metadata.metadataHeadlineList" relation. */
  connect?: InputMaybe<Array<MetadataHeadlineWhereUniqueInput>>;
  /** Create new "MetadataHeadline" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataHeadlineList" relation. */
  create?: InputMaybe<Array<MetadataHeadlineWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataHeadlineListUpdateInput = {
  /** Connect existing "MetadataHeadline" nodes to the existing "Metadata" node, through the "Metadata.metadataHeadlineList" relation. */
  connect?: InputMaybe<Array<MetadataHeadlineWhereUniqueInput>>;
  /** Create new "MetadataHeadline" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHeadlineList" relation. */
  create?: InputMaybe<Array<MetadataHeadlineWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataHeadline" nodes from the "Metadata.metadataHeadlineList" relation. */
  delete?: InputMaybe<Array<MetadataHeadlineWhereUniqueInput>>;
  /** Update existing "MetadataHeadline" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHeadlineList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataHeadlineListUpdateInput>>;
  /** Upsert existing "MetadataHeadline" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHeadlineList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataHeadlineListUpdateInput>>;
};

export type MetadataNestedMetadataHistoryListCreateInput = {
  /** Connect existing "MetadataHistory" nodes to the new "Metadata" node, through the "Metadata.metadataHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataHistoryWhereUniqueInput>>;
  /** Create new "MetadataHistory" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataHistoryList" relation. */
  create?: InputMaybe<Array<MetadataHistoryWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataHistoryListUpdateInput = {
  /** Connect existing "MetadataHistory" nodes to the existing "Metadata" node, through the "Metadata.metadataHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataHistoryWhereUniqueInput>>;
  /** Create new "MetadataHistory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHistoryList" relation. */
  create?: InputMaybe<Array<MetadataHistoryWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataHistory" nodes from the "Metadata.metadataHistoryList" relation. */
  delete?: InputMaybe<Array<MetadataHistoryWhereUniqueInput>>;
  /** Update existing "MetadataHistory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHistoryList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataHistoryListUpdateInput>>;
  /** Upsert existing "MetadataHistory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataHistoryList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataHistoryListUpdateInput>>;
};

export type MetadataNestedMetadataIccProfileListCreateInput = {
  /** Connect existing "MetadataIccProfile" nodes to the new "Metadata" node, through the "Metadata.metadataIccProfileList" relation. */
  connect?: InputMaybe<Array<MetadataIccProfileWhereUniqueInput>>;
  /** Create new "MetadataIccProfile" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataIccProfileList" relation. */
  create?: InputMaybe<Array<MetadataIccProfileWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataIccProfileListUpdateInput = {
  /** Connect existing "MetadataIccProfile" nodes to the existing "Metadata" node, through the "Metadata.metadataIccProfileList" relation. */
  connect?: InputMaybe<Array<MetadataIccProfileWhereUniqueInput>>;
  /** Create new "MetadataIccProfile" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIccProfileList" relation. */
  create?: InputMaybe<Array<MetadataIccProfileWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataIccProfile" nodes from the "Metadata.metadataIccProfileList" relation. */
  delete?: InputMaybe<Array<MetadataIccProfileWhereUniqueInput>>;
  /** Update existing "MetadataIccProfile" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIccProfileList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataIccProfileListUpdateInput>>;
  /** Upsert existing "MetadataIccProfile" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIccProfileList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataIccProfileListUpdateInput>>;
};

export type MetadataNestedMetadataIdAssignmentListCreateInput = {
  /** Connect existing "MetadataIdAssignment" nodes to the new "Metadata" node, through the "Metadata.metadataIdAssignmentList" relation. */
  connect?: InputMaybe<Array<MetadataIdAssignmentWhereUniqueInput>>;
  /** Create new "MetadataIdAssignment" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataIdAssignmentList" relation. */
  create?: InputMaybe<Array<MetadataIdAssignmentWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataIdAssignmentListUpdateInput = {
  /** Connect existing "MetadataIdAssignment" nodes to the existing "Metadata" node, through the "Metadata.metadataIdAssignmentList" relation. */
  connect?: InputMaybe<Array<MetadataIdAssignmentWhereUniqueInput>>;
  /** Create new "MetadataIdAssignment" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdAssignmentList" relation. */
  create?: InputMaybe<Array<MetadataIdAssignmentWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataIdAssignment" nodes from the "Metadata.metadataIdAssignmentList" relation. */
  delete?: InputMaybe<Array<MetadataIdAssignmentWhereUniqueInput>>;
  /** Update existing "MetadataIdAssignment" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdAssignmentList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataIdAssignmentListUpdateInput>>;
  /** Upsert existing "MetadataIdAssignment" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdAssignmentList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataIdAssignmentListUpdateInput>>;
};

export type MetadataNestedMetadataIdMediaContentListCreateInput = {
  /** Connect existing "MetadataIdMediaContent" nodes to the new "Metadata" node, through the "Metadata.metadataIdMediaContentList" relation. */
  connect?: InputMaybe<Array<MetadataIdMediaContentWhereUniqueInput>>;
  /** Create new "MetadataIdMediaContent" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataIdMediaContentList" relation. */
  create?: InputMaybe<Array<MetadataIdMediaContentWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataIdMediaContentListUpdateInput = {
  /** Connect existing "MetadataIdMediaContent" nodes to the existing "Metadata" node, through the "Metadata.metadataIdMediaContentList" relation. */
  connect?: InputMaybe<Array<MetadataIdMediaContentWhereUniqueInput>>;
  /** Create new "MetadataIdMediaContent" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdMediaContentList" relation. */
  create?: InputMaybe<Array<MetadataIdMediaContentWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataIdMediaContent" nodes from the "Metadata.metadataIdMediaContentList" relation. */
  delete?: InputMaybe<Array<MetadataIdMediaContentWhereUniqueInput>>;
  /** Update existing "MetadataIdMediaContent" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdMediaContentList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataIdMediaContentListUpdateInput>>;
  /** Upsert existing "MetadataIdMediaContent" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdMediaContentList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataIdMediaContentListUpdateInput>>;
};

export type MetadataNestedMetadataIdPhotoPrismaListCreateInput = {
  /** Connect existing "MetadataIdPhotoPrisma" nodes to the new "Metadata" node, through the "Metadata.metadataIdPhotoPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataIdPhotoPrismaWhereUniqueInput>>;
  /** Create new "MetadataIdPhotoPrisma" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataIdPhotoPrismaList" relation. */
  create?: InputMaybe<Array<MetadataIdPhotoPrismaWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataIdPhotoPrismaListUpdateInput = {
  /** Connect existing "MetadataIdPhotoPrisma" nodes to the existing "Metadata" node, through the "Metadata.metadataIdPhotoPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataIdPhotoPrismaWhereUniqueInput>>;
  /** Create new "MetadataIdPhotoPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdPhotoPrismaList" relation. */
  create?: InputMaybe<Array<MetadataIdPhotoPrismaWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataIdPhotoPrisma" nodes from the "Metadata.metadataIdPhotoPrismaList" relation. */
  delete?: InputMaybe<Array<MetadataIdPhotoPrismaWhereUniqueInput>>;
  /** Update existing "MetadataIdPhotoPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdPhotoPrismaList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataIdPhotoPrismaListUpdateInput>>;
  /** Upsert existing "MetadataIdPhotoPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdPhotoPrismaList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataIdPhotoPrismaListUpdateInput>>;
};

export type MetadataNestedMetadataIdSubjectListCreateInput = {
  /** Connect existing "MetadataIdSubject" nodes to the new "Metadata" node, through the "Metadata.metadataIdSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataIdSubjectWhereUniqueInput>>;
  /** Create new "MetadataIdSubject" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataIdSubjectList" relation. */
  create?: InputMaybe<Array<MetadataIdSubjectWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataIdSubjectListUpdateInput = {
  /** Connect existing "MetadataIdSubject" nodes to the existing "Metadata" node, through the "Metadata.metadataIdSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataIdSubjectWhereUniqueInput>>;
  /** Create new "MetadataIdSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdSubjectList" relation. */
  create?: InputMaybe<Array<MetadataIdSubjectWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataIdSubject" nodes from the "Metadata.metadataIdSubjectList" relation. */
  delete?: InputMaybe<Array<MetadataIdSubjectWhereUniqueInput>>;
  /** Update existing "MetadataIdSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdSubjectList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataIdSubjectListUpdateInput>>;
  /** Upsert existing "MetadataIdSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIdSubjectList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataIdSubjectListUpdateInput>>;
};

export type MetadataNestedMetadataImageNotesListCreateInput = {
  /** Connect existing "MetadataImageNotes" nodes to the new "Metadata" node, through the "Metadata.metadataImageNotesList" relation. */
  connect?: InputMaybe<Array<MetadataImageNotesWhereUniqueInput>>;
  /** Create new "MetadataImageNotes" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataImageNotesList" relation. */
  create?: InputMaybe<Array<MetadataImageNotesWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataImageNotesListUpdateInput = {
  /** Connect existing "MetadataImageNotes" nodes to the existing "Metadata" node, through the "Metadata.metadataImageNotesList" relation. */
  connect?: InputMaybe<Array<MetadataImageNotesWhereUniqueInput>>;
  /** Create new "MetadataImageNotes" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataImageNotesList" relation. */
  create?: InputMaybe<Array<MetadataImageNotesWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataImageNotes" nodes from the "Metadata.metadataImageNotesList" relation. */
  delete?: InputMaybe<Array<MetadataImageNotesWhereUniqueInput>>;
  /** Update existing "MetadataImageNotes" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataImageNotesList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataImageNotesListUpdateInput>>;
  /** Upsert existing "MetadataImageNotes" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataImageNotesList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataImageNotesListUpdateInput>>;
};

export type MetadataNestedMetadataImageUniqueIdListCreateInput = {
  /** Connect existing "MetadataImageUniqueId" nodes to the new "Metadata" node, through the "Metadata.metadataImageUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataImageUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataImageUniqueId" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataImageUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataImageUniqueIdWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataImageUniqueIdListUpdateInput = {
  /** Connect existing "MetadataImageUniqueId" nodes to the existing "Metadata" node, through the "Metadata.metadataImageUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataImageUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataImageUniqueId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataImageUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataImageUniqueIdWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataImageUniqueId" nodes from the "Metadata.metadataImageUniqueIdList" relation. */
  delete?: InputMaybe<Array<MetadataImageUniqueIdWhereUniqueInput>>;
  /** Update existing "MetadataImageUniqueId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataImageUniqueIdList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataImageUniqueIdListUpdateInput>>;
  /** Upsert existing "MetadataImageUniqueId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataImageUniqueIdList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataImageUniqueIdListUpdateInput>>;
};

export type MetadataNestedMetadataInstructionsListCreateInput = {
  /** Connect existing "MetadataInstructions" nodes to the new "Metadata" node, through the "Metadata.metadataInstructionsList" relation. */
  connect?: InputMaybe<Array<MetadataInstructionsWhereUniqueInput>>;
  /** Create new "MetadataInstructions" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataInstructionsList" relation. */
  create?: InputMaybe<Array<MetadataInstructionsWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataInstructionsListUpdateInput = {
  /** Connect existing "MetadataInstructions" nodes to the existing "Metadata" node, through the "Metadata.metadataInstructionsList" relation. */
  connect?: InputMaybe<Array<MetadataInstructionsWhereUniqueInput>>;
  /** Create new "MetadataInstructions" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataInstructionsList" relation. */
  create?: InputMaybe<Array<MetadataInstructionsWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataInstructions" nodes from the "Metadata.metadataInstructionsList" relation. */
  delete?: InputMaybe<Array<MetadataInstructionsWhereUniqueInput>>;
  /** Update existing "MetadataInstructions" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataInstructionsList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataInstructionsListUpdateInput>>;
  /** Upsert existing "MetadataInstructions" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataInstructionsList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataInstructionsListUpdateInput>>;
};

export type MetadataNestedMetadataIntellectualGenreListCreateInput = {
  /** Connect existing "MetadataIntellectualGenre" nodes to the new "Metadata" node, through the "Metadata.metadataIntellectualGenreList" relation. */
  connect?: InputMaybe<Array<MetadataIntellectualGenreWhereUniqueInput>>;
  /** Create new "MetadataIntellectualGenre" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataIntellectualGenreList" relation. */
  create?: InputMaybe<Array<MetadataIntellectualGenreWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataIntellectualGenreListUpdateInput = {
  /** Connect existing "MetadataIntellectualGenre" nodes to the existing "Metadata" node, through the "Metadata.metadataIntellectualGenreList" relation. */
  connect?: InputMaybe<Array<MetadataIntellectualGenreWhereUniqueInput>>;
  /** Create new "MetadataIntellectualGenre" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIntellectualGenreList" relation. */
  create?: InputMaybe<Array<MetadataIntellectualGenreWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataIntellectualGenre" nodes from the "Metadata.metadataIntellectualGenreList" relation. */
  delete?: InputMaybe<Array<MetadataIntellectualGenreWhereUniqueInput>>;
  /** Update existing "MetadataIntellectualGenre" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIntellectualGenreList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataIntellectualGenreListUpdateInput>>;
  /** Upsert existing "MetadataIntellectualGenre" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIntellectualGenreList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataIntellectualGenreListUpdateInput>>;
};

export type MetadataNestedMetadataIsoSpeedRatingsListCreateInput = {
  /** Connect existing "MetadataIsoSpeedRatings" nodes to the new "Metadata" node, through the "Metadata.metadataIsoSpeedRatingsList" relation. */
  connect?: InputMaybe<Array<MetadataIsoSpeedRatingsWhereUniqueInput>>;
  /** Create new "MetadataIsoSpeedRatings" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataIsoSpeedRatingsList" relation. */
  create?: InputMaybe<Array<MetadataIsoSpeedRatingsWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataIsoSpeedRatingsListUpdateInput = {
  /** Connect existing "MetadataIsoSpeedRatings" nodes to the existing "Metadata" node, through the "Metadata.metadataIsoSpeedRatingsList" relation. */
  connect?: InputMaybe<Array<MetadataIsoSpeedRatingsWhereUniqueInput>>;
  /** Create new "MetadataIsoSpeedRatings" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIsoSpeedRatingsList" relation. */
  create?: InputMaybe<Array<MetadataIsoSpeedRatingsWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataIsoSpeedRatings" nodes from the "Metadata.metadataIsoSpeedRatingsList" relation. */
  delete?: InputMaybe<Array<MetadataIsoSpeedRatingsWhereUniqueInput>>;
  /** Update existing "MetadataIsoSpeedRatings" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIsoSpeedRatingsList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataIsoSpeedRatingsListUpdateInput>>;
  /** Upsert existing "MetadataIsoSpeedRatings" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIsoSpeedRatingsList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataIsoSpeedRatingsListUpdateInput>>;
};

export type MetadataNestedMetadataIssueListCreateInput = {
  /** Connect existing "MetadataIssue" nodes to the new "Metadata" node, through the "Metadata.metadataIssueList" relation. */
  connect?: InputMaybe<Array<MetadataIssueWhereUniqueInput>>;
  /** Create new "MetadataIssue" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataIssueList" relation. */
  create?: InputMaybe<Array<MetadataIssueWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataIssueListUpdateInput = {
  /** Connect existing "MetadataIssue" nodes to the existing "Metadata" node, through the "Metadata.metadataIssueList" relation. */
  connect?: InputMaybe<Array<MetadataIssueWhereUniqueInput>>;
  /** Create new "MetadataIssue" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIssueList" relation. */
  create?: InputMaybe<Array<MetadataIssueWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataIssue" nodes from the "Metadata.metadataIssueList" relation. */
  delete?: InputMaybe<Array<MetadataIssueWhereUniqueInput>>;
  /** Update existing "MetadataIssue" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIssueList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataIssueListUpdateInput>>;
  /** Upsert existing "MetadataIssue" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataIssueList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataIssueListUpdateInput>>;
};

export type MetadataNestedMetadataJobIdListCreateInput = {
  /** Connect existing "MetadataJobId" nodes to the new "Metadata" node, through the "Metadata.metadataJobIdList" relation. */
  connect?: InputMaybe<Array<MetadataJobIdWhereUniqueInput>>;
  /** Create new "MetadataJobId" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataJobIdList" relation. */
  create?: InputMaybe<Array<MetadataJobIdWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataJobIdListUpdateInput = {
  /** Connect existing "MetadataJobId" nodes to the existing "Metadata" node, through the "Metadata.metadataJobIdList" relation. */
  connect?: InputMaybe<Array<MetadataJobIdWhereUniqueInput>>;
  /** Create new "MetadataJobId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataJobIdList" relation. */
  create?: InputMaybe<Array<MetadataJobIdWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataJobId" nodes from the "Metadata.metadataJobIdList" relation. */
  delete?: InputMaybe<Array<MetadataJobIdWhereUniqueInput>>;
  /** Update existing "MetadataJobId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataJobIdList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataJobIdListUpdateInput>>;
  /** Upsert existing "MetadataJobId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataJobIdList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataJobIdListUpdateInput>>;
};

export type MetadataNestedMetadataJobProcessingListCreateInput = {
  /** Connect existing "MetadataJobProcessing" nodes to the new "Metadata" node, through the "Metadata.metadataJobProcessingList" relation. */
  connect?: InputMaybe<Array<MetadataJobProcessingWhereUniqueInput>>;
  /** Create new "MetadataJobProcessing" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataJobProcessingList" relation. */
  create?: InputMaybe<Array<MetadataJobProcessingWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataJobProcessingListUpdateInput = {
  /** Connect existing "MetadataJobProcessing" nodes to the existing "Metadata" node, through the "Metadata.metadataJobProcessingList" relation. */
  connect?: InputMaybe<Array<MetadataJobProcessingWhereUniqueInput>>;
  /** Create new "MetadataJobProcessing" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataJobProcessingList" relation. */
  create?: InputMaybe<Array<MetadataJobProcessingWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataJobProcessing" nodes from the "Metadata.metadataJobProcessingList" relation. */
  delete?: InputMaybe<Array<MetadataJobProcessingWhereUniqueInput>>;
  /** Update existing "MetadataJobProcessing" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataJobProcessingList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataJobProcessingListUpdateInput>>;
  /** Upsert existing "MetadataJobProcessing" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataJobProcessingList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataJobProcessingListUpdateInput>>;
};

export type MetadataNestedMetadataLayoutPictureEditingListCreateInput = {
  /** Connect existing "MetadataLayoutPictureEditing" nodes to the new "Metadata" node, through the "Metadata.metadataLayoutPictureEditingList" relation. */
  connect?: InputMaybe<Array<MetadataLayoutPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataLayoutPictureEditing" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataLayoutPictureEditingList" relation. */
  create?: InputMaybe<Array<MetadataLayoutPictureEditingWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataLayoutPictureEditingListUpdateInput = {
  /** Connect existing "MetadataLayoutPictureEditing" nodes to the existing "Metadata" node, through the "Metadata.metadataLayoutPictureEditingList" relation. */
  connect?: InputMaybe<Array<MetadataLayoutPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataLayoutPictureEditing" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLayoutPictureEditingList" relation. */
  create?: InputMaybe<Array<MetadataLayoutPictureEditingWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataLayoutPictureEditing" nodes from the "Metadata.metadataLayoutPictureEditingList" relation. */
  delete?: InputMaybe<Array<MetadataLayoutPictureEditingWhereUniqueInput>>;
  /** Update existing "MetadataLayoutPictureEditing" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLayoutPictureEditingList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataLayoutPictureEditingListUpdateInput>>;
  /** Upsert existing "MetadataLayoutPictureEditing" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLayoutPictureEditingList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataLayoutPictureEditingListUpdateInput>>;
};

export type MetadataNestedMetadataLayoutStorageListCreateInput = {
  /** Connect existing "MetadataLayoutStorage" nodes to the new "Metadata" node, through the "Metadata.metadataLayoutStorageList" relation. */
  connect?: InputMaybe<Array<MetadataLayoutStorageWhereUniqueInput>>;
  /** Create new "MetadataLayoutStorage" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataLayoutStorageList" relation. */
  create?: InputMaybe<Array<MetadataLayoutStorageWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataLayoutStorageListUpdateInput = {
  /** Connect existing "MetadataLayoutStorage" nodes to the existing "Metadata" node, through the "Metadata.metadataLayoutStorageList" relation. */
  connect?: InputMaybe<Array<MetadataLayoutStorageWhereUniqueInput>>;
  /** Create new "MetadataLayoutStorage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLayoutStorageList" relation. */
  create?: InputMaybe<Array<MetadataLayoutStorageWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataLayoutStorage" nodes from the "Metadata.metadataLayoutStorageList" relation. */
  delete?: InputMaybe<Array<MetadataLayoutStorageWhereUniqueInput>>;
  /** Update existing "MetadataLayoutStorage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLayoutStorageList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataLayoutStorageListUpdateInput>>;
  /** Upsert existing "MetadataLayoutStorage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLayoutStorageList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataLayoutStorageListUpdateInput>>;
};

export type MetadataNestedMetadataLicenseListCreateInput = {
  /** Connect existing "MetadataLicense" nodes to the new "Metadata" node, through the "Metadata.metadataLicenseList" relation. */
  connect?: InputMaybe<Array<MetadataLicenseWhereUniqueInput>>;
  /** Create new "MetadataLicense" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataLicenseList" relation. */
  create?: InputMaybe<Array<MetadataLicenseWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataLicenseListUpdateInput = {
  /** Connect existing "MetadataLicense" nodes to the existing "Metadata" node, through the "Metadata.metadataLicenseList" relation. */
  connect?: InputMaybe<Array<MetadataLicenseWhereUniqueInput>>;
  /** Create new "MetadataLicense" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLicenseList" relation. */
  create?: InputMaybe<Array<MetadataLicenseWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataLicense" nodes from the "Metadata.metadataLicenseList" relation. */
  delete?: InputMaybe<Array<MetadataLicenseWhereUniqueInput>>;
  /** Update existing "MetadataLicense" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLicenseList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataLicenseListUpdateInput>>;
  /** Upsert existing "MetadataLicense" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLicenseList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataLicenseListUpdateInput>>;
};

export type MetadataNestedMetadataLinksListCreateInput = {
  /** Connect existing "MetadataLinks" nodes to the new "Metadata" node, through the "Metadata.metadataLinksList" relation. */
  connect?: InputMaybe<Array<MetadataLinksWhereUniqueInput>>;
  /** Create new "MetadataLinks" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataLinksList" relation. */
  create?: InputMaybe<Array<MetadataLinksWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataLinksListUpdateInput = {
  /** Connect existing "MetadataLinks" nodes to the existing "Metadata" node, through the "Metadata.metadataLinksList" relation. */
  connect?: InputMaybe<Array<MetadataLinksWhereUniqueInput>>;
  /** Create new "MetadataLinks" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLinksList" relation. */
  create?: InputMaybe<Array<MetadataLinksWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataLinks" nodes from the "Metadata.metadataLinksList" relation. */
  delete?: InputMaybe<Array<MetadataLinksWhereUniqueInput>>;
  /** Update existing "MetadataLinks" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLinksList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataLinksListUpdateInput>>;
  /** Upsert existing "MetadataLinks" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLinksList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataLinksListUpdateInput>>;
};

export type MetadataNestedMetadataLocalCaptionListCreateInput = {
  /** Connect existing "MetadataLocalCaption" nodes to the new "Metadata" node, through the "Metadata.metadataLocalCaptionList" relation. */
  connect?: InputMaybe<Array<MetadataLocalCaptionWhereUniqueInput>>;
  /** Create new "MetadataLocalCaption" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataLocalCaptionList" relation. */
  create?: InputMaybe<Array<MetadataLocalCaptionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataLocalCaptionListUpdateInput = {
  /** Connect existing "MetadataLocalCaption" nodes to the existing "Metadata" node, through the "Metadata.metadataLocalCaptionList" relation. */
  connect?: InputMaybe<Array<MetadataLocalCaptionWhereUniqueInput>>;
  /** Create new "MetadataLocalCaption" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLocalCaptionList" relation. */
  create?: InputMaybe<Array<MetadataLocalCaptionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataLocalCaption" nodes from the "Metadata.metadataLocalCaptionList" relation. */
  delete?: InputMaybe<Array<MetadataLocalCaptionWhereUniqueInput>>;
  /** Update existing "MetadataLocalCaption" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLocalCaptionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataLocalCaptionListUpdateInput>>;
  /** Upsert existing "MetadataLocalCaption" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLocalCaptionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataLocalCaptionListUpdateInput>>;
};

export type MetadataNestedMetadataLocationListCreateInput = {
  /** Connect existing "MetadataLocation" nodes to the new "Metadata" node, through the "Metadata.metadataLocationList" relation. */
  connect?: InputMaybe<Array<MetadataLocationWhereUniqueInput>>;
  /** Create new "MetadataLocation" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataLocationList" relation. */
  create?: InputMaybe<Array<MetadataLocationWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataLocationListUpdateInput = {
  /** Connect existing "MetadataLocation" nodes to the existing "Metadata" node, through the "Metadata.metadataLocationList" relation. */
  connect?: InputMaybe<Array<MetadataLocationWhereUniqueInput>>;
  /** Create new "MetadataLocation" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLocationList" relation. */
  create?: InputMaybe<Array<MetadataLocationWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataLocation" nodes from the "Metadata.metadataLocationList" relation. */
  delete?: InputMaybe<Array<MetadataLocationWhereUniqueInput>>;
  /** Update existing "MetadataLocation" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLocationList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataLocationListUpdateInput>>;
  /** Upsert existing "MetadataLocation" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataLocationList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataLocationListUpdateInput>>;
};

export type MetadataNestedMetadataMakeListCreateInput = {
  /** Connect existing "MetadataMake" nodes to the new "Metadata" node, through the "Metadata.metadataMakeList" relation. */
  connect?: InputMaybe<Array<MetadataMakeWhereUniqueInput>>;
  /** Create new "MetadataMake" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataMakeList" relation. */
  create?: InputMaybe<Array<MetadataMakeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataMakeListUpdateInput = {
  /** Connect existing "MetadataMake" nodes to the existing "Metadata" node, through the "Metadata.metadataMakeList" relation. */
  connect?: InputMaybe<Array<MetadataMakeWhereUniqueInput>>;
  /** Create new "MetadataMake" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMakeList" relation. */
  create?: InputMaybe<Array<MetadataMakeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataMake" nodes from the "Metadata.metadataMakeList" relation. */
  delete?: InputMaybe<Array<MetadataMakeWhereUniqueInput>>;
  /** Update existing "MetadataMake" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMakeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataMakeListUpdateInput>>;
  /** Upsert existing "MetadataMake" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMakeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataMakeListUpdateInput>>;
};

export type MetadataNestedMetadataManifestListCreateInput = {
  /** Connect existing "MetadataManifest" nodes to the new "Metadata" node, through the "Metadata.metadataManifestList" relation. */
  connect?: InputMaybe<Array<MetadataManifestWhereUniqueInput>>;
  /** Create new "MetadataManifest" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataManifestList" relation. */
  create?: InputMaybe<Array<MetadataManifestWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataManifestListUpdateInput = {
  /** Connect existing "MetadataManifest" nodes to the existing "Metadata" node, through the "Metadata.metadataManifestList" relation. */
  connect?: InputMaybe<Array<MetadataManifestWhereUniqueInput>>;
  /** Create new "MetadataManifest" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataManifestList" relation. */
  create?: InputMaybe<Array<MetadataManifestWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataManifest" nodes from the "Metadata.metadataManifestList" relation. */
  delete?: InputMaybe<Array<MetadataManifestWhereUniqueInput>>;
  /** Update existing "MetadataManifest" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataManifestList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataManifestListUpdateInput>>;
  /** Upsert existing "MetadataManifest" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataManifestList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataManifestListUpdateInput>>;
};

export type MetadataNestedMetadataMarkedListCreateInput = {
  /** Connect existing "MetadataMarked" nodes to the new "Metadata" node, through the "Metadata.metadataMarkedList" relation. */
  connect?: InputMaybe<Array<MetadataMarkedWhereUniqueInput>>;
  /** Create new "MetadataMarked" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataMarkedList" relation. */
  create?: InputMaybe<Array<MetadataMarkedWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataMarkedListUpdateInput = {
  /** Connect existing "MetadataMarked" nodes to the existing "Metadata" node, through the "Metadata.metadataMarkedList" relation. */
  connect?: InputMaybe<Array<MetadataMarkedWhereUniqueInput>>;
  /** Create new "MetadataMarked" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMarkedList" relation. */
  create?: InputMaybe<Array<MetadataMarkedWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataMarked" nodes from the "Metadata.metadataMarkedList" relation. */
  delete?: InputMaybe<Array<MetadataMarkedWhereUniqueInput>>;
  /** Update existing "MetadataMarked" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMarkedList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataMarkedListUpdateInput>>;
  /** Upsert existing "MetadataMarked" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMarkedList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataMarkedListUpdateInput>>;
};

export type MetadataNestedMetadataMasterDocumentIdListCreateInput = {
  /** Connect existing "MetadataMasterDocumentId" nodes to the new "Metadata" node, through the "Metadata.metadataMasterDocumentIdList" relation. */
  connect?: InputMaybe<Array<MetadataMasterDocumentIdWhereUniqueInput>>;
  /** Create new "MetadataMasterDocumentId" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataMasterDocumentIdList" relation. */
  create?: InputMaybe<Array<MetadataMasterDocumentIdWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataMasterDocumentIdListUpdateInput = {
  /** Connect existing "MetadataMasterDocumentId" nodes to the existing "Metadata" node, through the "Metadata.metadataMasterDocumentIdList" relation. */
  connect?: InputMaybe<Array<MetadataMasterDocumentIdWhereUniqueInput>>;
  /** Create new "MetadataMasterDocumentId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMasterDocumentIdList" relation. */
  create?: InputMaybe<Array<MetadataMasterDocumentIdWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataMasterDocumentId" nodes from the "Metadata.metadataMasterDocumentIdList" relation. */
  delete?: InputMaybe<Array<MetadataMasterDocumentIdWhereUniqueInput>>;
  /** Update existing "MetadataMasterDocumentId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMasterDocumentIdList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataMasterDocumentIdListUpdateInput>>;
  /** Upsert existing "MetadataMasterDocumentId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMasterDocumentIdList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataMasterDocumentIdListUpdateInput>>;
};

export type MetadataNestedMetadataMaxAvailHeightListCreateInput = {
  /** Connect existing "MetadataMaxAvailHeight" nodes to the new "Metadata" node, through the "Metadata.metadataMaxAvailHeightList" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailHeightWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailHeight" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataMaxAvailHeightList" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailHeightWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataMaxAvailHeightListUpdateInput = {
  /** Connect existing "MetadataMaxAvailHeight" nodes to the existing "Metadata" node, through the "Metadata.metadataMaxAvailHeightList" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailHeightWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailHeight" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMaxAvailHeightList" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailHeightWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataMaxAvailHeight" nodes from the "Metadata.metadataMaxAvailHeightList" relation. */
  delete?: InputMaybe<Array<MetadataMaxAvailHeightWhereUniqueInput>>;
  /** Update existing "MetadataMaxAvailHeight" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMaxAvailHeightList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataMaxAvailHeightListUpdateInput>>;
  /** Upsert existing "MetadataMaxAvailHeight" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMaxAvailHeightList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataMaxAvailHeightListUpdateInput>>;
};

export type MetadataNestedMetadataMaxAvailWidthListCreateInput = {
  /** Connect existing "MetadataMaxAvailWidth" nodes to the new "Metadata" node, through the "Metadata.metadataMaxAvailWidthList" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailWidthWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailWidth" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataMaxAvailWidthList" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailWidthWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataMaxAvailWidthListUpdateInput = {
  /** Connect existing "MetadataMaxAvailWidth" nodes to the existing "Metadata" node, through the "Metadata.metadataMaxAvailWidthList" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailWidthWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailWidth" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMaxAvailWidthList" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailWidthWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataMaxAvailWidth" nodes from the "Metadata.metadataMaxAvailWidthList" relation. */
  delete?: InputMaybe<Array<MetadataMaxAvailWidthWhereUniqueInput>>;
  /** Update existing "MetadataMaxAvailWidth" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMaxAvailWidthList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataMaxAvailWidthListUpdateInput>>;
  /** Upsert existing "MetadataMaxAvailWidth" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMaxAvailWidthList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataMaxAvailWidthListUpdateInput>>;
};

export type MetadataNestedMetadataMinorModelAgeDisclosureListCreateInput = {
  /** Connect existing "MetadataMinorModelAgeDisclosure" nodes to the new "Metadata" node, through the "Metadata.metadataMinorModelAgeDisclosureList" relation. */
  connect?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWhereUniqueInput>>;
  /** Create new "MetadataMinorModelAgeDisclosure" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataMinorModelAgeDisclosureList" relation. */
  create?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataMinorModelAgeDisclosureListUpdateInput = {
  /** Connect existing "MetadataMinorModelAgeDisclosure" nodes to the existing "Metadata" node, through the "Metadata.metadataMinorModelAgeDisclosureList" relation. */
  connect?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWhereUniqueInput>>;
  /** Create new "MetadataMinorModelAgeDisclosure" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMinorModelAgeDisclosureList" relation. */
  create?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataMinorModelAgeDisclosure" nodes from the "Metadata.metadataMinorModelAgeDisclosureList" relation. */
  delete?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWhereUniqueInput>>;
  /** Update existing "MetadataMinorModelAgeDisclosure" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMinorModelAgeDisclosureList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataMinorModelAgeDisclosureListUpdateInput>>;
  /** Upsert existing "MetadataMinorModelAgeDisclosure" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMinorModelAgeDisclosureList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataMinorModelAgeDisclosureListUpdateInput>>;
};

export type MetadataNestedMetadataModelAgeListCreateInput = {
  /** Connect existing "MetadataModelAge" nodes to the new "Metadata" node, through the "Metadata.metadataModelAgeList" relation. */
  connect?: InputMaybe<Array<MetadataModelAgeWhereUniqueInput>>;
  /** Create new "MetadataModelAge" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataModelAgeList" relation. */
  create?: InputMaybe<Array<MetadataModelAgeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataModelAgeListUpdateInput = {
  /** Connect existing "MetadataModelAge" nodes to the existing "Metadata" node, through the "Metadata.metadataModelAgeList" relation. */
  connect?: InputMaybe<Array<MetadataModelAgeWhereUniqueInput>>;
  /** Create new "MetadataModelAge" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelAgeList" relation. */
  create?: InputMaybe<Array<MetadataModelAgeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataModelAge" nodes from the "Metadata.metadataModelAgeList" relation. */
  delete?: InputMaybe<Array<MetadataModelAgeWhereUniqueInput>>;
  /** Update existing "MetadataModelAge" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelAgeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataModelAgeListUpdateInput>>;
  /** Upsert existing "MetadataModelAge" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelAgeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataModelAgeListUpdateInput>>;
};

export type MetadataNestedMetadataModelListCreateInput = {
  /** Connect existing "MetadataModel" nodes to the new "Metadata" node, through the "Metadata.metadataModelList" relation. */
  connect?: InputMaybe<Array<MetadataModelWhereUniqueInput>>;
  /** Create new "MetadataModel" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataModelList" relation. */
  create?: InputMaybe<Array<MetadataModelWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataModelListUpdateInput = {
  /** Connect existing "MetadataModel" nodes to the existing "Metadata" node, through the "Metadata.metadataModelList" relation. */
  connect?: InputMaybe<Array<MetadataModelWhereUniqueInput>>;
  /** Create new "MetadataModel" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelList" relation. */
  create?: InputMaybe<Array<MetadataModelWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataModel" nodes from the "Metadata.metadataModelList" relation. */
  delete?: InputMaybe<Array<MetadataModelWhereUniqueInput>>;
  /** Update existing "MetadataModel" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataModelListUpdateInput>>;
  /** Upsert existing "MetadataModel" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataModelListUpdateInput>>;
};

export type MetadataNestedMetadataModelReleaseIdListCreateInput = {
  /** Connect existing "MetadataModelReleaseId" nodes to the new "Metadata" node, through the "Metadata.metadataModelReleaseIdList" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseId" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataModelReleaseIdList" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseIdWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataModelReleaseIdListUpdateInput = {
  /** Connect existing "MetadataModelReleaseId" nodes to the existing "Metadata" node, through the "Metadata.metadataModelReleaseIdList" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelReleaseIdList" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseIdWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataModelReleaseId" nodes from the "Metadata.metadataModelReleaseIdList" relation. */
  delete?: InputMaybe<Array<MetadataModelReleaseIdWhereUniqueInput>>;
  /** Update existing "MetadataModelReleaseId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelReleaseIdList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataModelReleaseIdListUpdateInput>>;
  /** Upsert existing "MetadataModelReleaseId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelReleaseIdList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataModelReleaseIdListUpdateInput>>;
};

export type MetadataNestedMetadataModelReleaseStatusListCreateInput = {
  /** Connect existing "MetadataModelReleaseStatus" nodes to the new "Metadata" node, through the "Metadata.metadataModelReleaseStatusList" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseStatus" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataModelReleaseStatusList" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseStatusWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataModelReleaseStatusListUpdateInput = {
  /** Connect existing "MetadataModelReleaseStatus" nodes to the existing "Metadata" node, through the "Metadata.metadataModelReleaseStatusList" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelReleaseStatusList" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseStatusWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataModelReleaseStatus" nodes from the "Metadata.metadataModelReleaseStatusList" relation. */
  delete?: InputMaybe<Array<MetadataModelReleaseStatusWhereUniqueInput>>;
  /** Update existing "MetadataModelReleaseStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelReleaseStatusList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataModelReleaseStatusListUpdateInput>>;
  /** Upsert existing "MetadataModelReleaseStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataModelReleaseStatusList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataModelReleaseStatusListUpdateInput>>;
};

export type MetadataNestedMetadataMorePermissionsListCreateInput = {
  /** Connect existing "MetadataMorePermissions" nodes to the new "Metadata" node, through the "Metadata.metadataMorePermissionsList" relation. */
  connect?: InputMaybe<Array<MetadataMorePermissionsWhereUniqueInput>>;
  /** Create new "MetadataMorePermissions" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataMorePermissionsList" relation. */
  create?: InputMaybe<Array<MetadataMorePermissionsWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataMorePermissionsListUpdateInput = {
  /** Connect existing "MetadataMorePermissions" nodes to the existing "Metadata" node, through the "Metadata.metadataMorePermissionsList" relation. */
  connect?: InputMaybe<Array<MetadataMorePermissionsWhereUniqueInput>>;
  /** Create new "MetadataMorePermissions" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMorePermissionsList" relation. */
  create?: InputMaybe<Array<MetadataMorePermissionsWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataMorePermissions" nodes from the "Metadata.metadataMorePermissionsList" relation. */
  delete?: InputMaybe<Array<MetadataMorePermissionsWhereUniqueInput>>;
  /** Update existing "MetadataMorePermissions" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMorePermissionsList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataMorePermissionsListUpdateInput>>;
  /** Upsert existing "MetadataMorePermissions" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataMorePermissionsList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataMorePermissionsListUpdateInput>>;
};

export type MetadataNestedMetadataObjectCycleListCreateInput = {
  /** Connect existing "MetadataObjectCycle" nodes to the new "Metadata" node, through the "Metadata.metadataObjectCycleList" relation. */
  connect?: InputMaybe<Array<MetadataObjectCycleWhereUniqueInput>>;
  /** Create new "MetadataObjectCycle" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataObjectCycleList" relation. */
  create?: InputMaybe<Array<MetadataObjectCycleWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataObjectCycleListUpdateInput = {
  /** Connect existing "MetadataObjectCycle" nodes to the existing "Metadata" node, through the "Metadata.metadataObjectCycleList" relation. */
  connect?: InputMaybe<Array<MetadataObjectCycleWhereUniqueInput>>;
  /** Create new "MetadataObjectCycle" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataObjectCycleList" relation. */
  create?: InputMaybe<Array<MetadataObjectCycleWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataObjectCycle" nodes from the "Metadata.metadataObjectCycleList" relation. */
  delete?: InputMaybe<Array<MetadataObjectCycleWhereUniqueInput>>;
  /** Update existing "MetadataObjectCycle" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataObjectCycleList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataObjectCycleListUpdateInput>>;
  /** Upsert existing "MetadataObjectCycle" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataObjectCycleList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataObjectCycleListUpdateInput>>;
};

export type MetadataNestedMetadataOrganisationInImageNameListCreateInput = {
  /** Connect existing "MetadataOrganisationInImageName" nodes to the new "Metadata" node, through the "Metadata.metadataOrganisationInImageNameList" relation. */
  connect?: InputMaybe<Array<MetadataOrganisationInImageNameWhereUniqueInput>>;
  /** Create new "MetadataOrganisationInImageName" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataOrganisationInImageNameList" relation. */
  create?: InputMaybe<Array<MetadataOrganisationInImageNameWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataOrganisationInImageNameListUpdateInput = {
  /** Connect existing "MetadataOrganisationInImageName" nodes to the existing "Metadata" node, through the "Metadata.metadataOrganisationInImageNameList" relation. */
  connect?: InputMaybe<Array<MetadataOrganisationInImageNameWhereUniqueInput>>;
  /** Create new "MetadataOrganisationInImageName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOrganisationInImageNameList" relation. */
  create?: InputMaybe<Array<MetadataOrganisationInImageNameWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataOrganisationInImageName" nodes from the "Metadata.metadataOrganisationInImageNameList" relation. */
  delete?: InputMaybe<Array<MetadataOrganisationInImageNameWhereUniqueInput>>;
  /** Update existing "MetadataOrganisationInImageName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOrganisationInImageNameList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataOrganisationInImageNameListUpdateInput>>;
  /** Upsert existing "MetadataOrganisationInImageName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOrganisationInImageNameList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataOrganisationInImageNameListUpdateInput>>;
};

export type MetadataNestedMetadataOriginalColorListCreateInput = {
  /** Connect existing "MetadataOriginalColor" nodes to the new "Metadata" node, through the "Metadata.metadataOriginalColorList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalColorWhereUniqueInput>>;
  /** Create new "MetadataOriginalColor" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataOriginalColorList" relation. */
  create?: InputMaybe<Array<MetadataOriginalColorWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataOriginalColorListUpdateInput = {
  /** Connect existing "MetadataOriginalColor" nodes to the existing "Metadata" node, through the "Metadata.metadataOriginalColorList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalColorWhereUniqueInput>>;
  /** Create new "MetadataOriginalColor" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalColorList" relation. */
  create?: InputMaybe<Array<MetadataOriginalColorWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataOriginalColor" nodes from the "Metadata.metadataOriginalColorList" relation. */
  delete?: InputMaybe<Array<MetadataOriginalColorWhereUniqueInput>>;
  /** Update existing "MetadataOriginalColor" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalColorList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataOriginalColorListUpdateInput>>;
  /** Upsert existing "MetadataOriginalColor" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalColorList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataOriginalColorListUpdateInput>>;
};

export type MetadataNestedMetadataOriginalIccProfileListCreateInput = {
  /** Connect existing "MetadataOriginalIccProfile" nodes to the new "Metadata" node, through the "Metadata.metadataOriginalIccProfileList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalIccProfileWhereUniqueInput>>;
  /** Create new "MetadataOriginalIccProfile" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataOriginalIccProfileList" relation. */
  create?: InputMaybe<Array<MetadataOriginalIccProfileWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataOriginalIccProfileListUpdateInput = {
  /** Connect existing "MetadataOriginalIccProfile" nodes to the existing "Metadata" node, through the "Metadata.metadataOriginalIccProfileList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalIccProfileWhereUniqueInput>>;
  /** Create new "MetadataOriginalIccProfile" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalIccProfileList" relation. */
  create?: InputMaybe<Array<MetadataOriginalIccProfileWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataOriginalIccProfile" nodes from the "Metadata.metadataOriginalIccProfileList" relation. */
  delete?: InputMaybe<Array<MetadataOriginalIccProfileWhereUniqueInput>>;
  /** Update existing "MetadataOriginalIccProfile" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalIccProfileList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataOriginalIccProfileListUpdateInput>>;
  /** Upsert existing "MetadataOriginalIccProfile" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalIccProfileList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataOriginalIccProfileListUpdateInput>>;
};

export type MetadataNestedMetadataOriginalNameListCreateInput = {
  /** Connect existing "MetadataOriginalName" nodes to the new "Metadata" node, through the "Metadata.metadataOriginalNameList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalNameWhereUniqueInput>>;
  /** Create new "MetadataOriginalName" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataOriginalNameList" relation. */
  create?: InputMaybe<Array<MetadataOriginalNameWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataOriginalNameListUpdateInput = {
  /** Connect existing "MetadataOriginalName" nodes to the existing "Metadata" node, through the "Metadata.metadataOriginalNameList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalNameWhereUniqueInput>>;
  /** Create new "MetadataOriginalName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalNameList" relation. */
  create?: InputMaybe<Array<MetadataOriginalNameWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataOriginalName" nodes from the "Metadata.metadataOriginalNameList" relation. */
  delete?: InputMaybe<Array<MetadataOriginalNameWhereUniqueInput>>;
  /** Update existing "MetadataOriginalName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalNameList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataOriginalNameListUpdateInput>>;
  /** Upsert existing "MetadataOriginalName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalNameList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataOriginalNameListUpdateInput>>;
};

export type MetadataNestedMetadataOriginalWeigthListCreateInput = {
  /** Connect existing "MetadataOriginalWeigth" nodes to the new "Metadata" node, through the "Metadata.metadataOriginalWeigthList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalWeigthWhereUniqueInput>>;
  /** Create new "MetadataOriginalWeigth" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataOriginalWeigthList" relation. */
  create?: InputMaybe<Array<MetadataOriginalWeigthWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataOriginalWeigthListUpdateInput = {
  /** Connect existing "MetadataOriginalWeigth" nodes to the existing "Metadata" node, through the "Metadata.metadataOriginalWeigthList" relation. */
  connect?: InputMaybe<Array<MetadataOriginalWeigthWhereUniqueInput>>;
  /** Create new "MetadataOriginalWeigth" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalWeigthList" relation. */
  create?: InputMaybe<Array<MetadataOriginalWeigthWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataOriginalWeigth" nodes from the "Metadata.metadataOriginalWeigthList" relation. */
  delete?: InputMaybe<Array<MetadataOriginalWeigthWhereUniqueInput>>;
  /** Update existing "MetadataOriginalWeigth" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalWeigthList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataOriginalWeigthListUpdateInput>>;
  /** Upsert existing "MetadataOriginalWeigth" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOriginalWeigthList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataOriginalWeigthListUpdateInput>>;
};

export type MetadataNestedMetadataOwnerIdListCreateInput = {
  /** Connect existing "MetadataOwnerId" nodes to the new "Metadata" node, through the "Metadata.metadataOwnerIdList" relation. */
  connect?: InputMaybe<Array<MetadataOwnerIdWhereUniqueInput>>;
  /** Create new "MetadataOwnerId" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataOwnerIdList" relation. */
  create?: InputMaybe<Array<MetadataOwnerIdWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataOwnerIdListUpdateInput = {
  /** Connect existing "MetadataOwnerId" nodes to the existing "Metadata" node, through the "Metadata.metadataOwnerIdList" relation. */
  connect?: InputMaybe<Array<MetadataOwnerIdWhereUniqueInput>>;
  /** Create new "MetadataOwnerId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOwnerIdList" relation. */
  create?: InputMaybe<Array<MetadataOwnerIdWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataOwnerId" nodes from the "Metadata.metadataOwnerIdList" relation. */
  delete?: InputMaybe<Array<MetadataOwnerIdWhereUniqueInput>>;
  /** Update existing "MetadataOwnerId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOwnerIdList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataOwnerIdListUpdateInput>>;
  /** Upsert existing "MetadataOwnerId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOwnerIdList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataOwnerIdListUpdateInput>>;
};

export type MetadataNestedMetadataOwnerListCreateInput = {
  /** Connect existing "MetadataOwner" nodes to the new "Metadata" node, through the "Metadata.metadataOwnerList" relation. */
  connect?: InputMaybe<Array<MetadataOwnerWhereUniqueInput>>;
  /** Create new "MetadataOwner" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataOwnerList" relation. */
  create?: InputMaybe<Array<MetadataOwnerWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataOwnerListUpdateInput = {
  /** Connect existing "MetadataOwner" nodes to the existing "Metadata" node, through the "Metadata.metadataOwnerList" relation. */
  connect?: InputMaybe<Array<MetadataOwnerWhereUniqueInput>>;
  /** Create new "MetadataOwner" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOwnerList" relation. */
  create?: InputMaybe<Array<MetadataOwnerWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataOwner" nodes from the "Metadata.metadataOwnerList" relation. */
  delete?: InputMaybe<Array<MetadataOwnerWhereUniqueInput>>;
  /** Update existing "MetadataOwner" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOwnerList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataOwnerListUpdateInput>>;
  /** Upsert existing "MetadataOwner" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataOwnerList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataOwnerListUpdateInput>>;
};

export type MetadataNestedMetadataPageListCreateInput = {
  /** Connect existing "MetadataPage" nodes to the new "Metadata" node, through the "Metadata.metadataPageList" relation. */
  connect?: InputMaybe<Array<MetadataPageWhereUniqueInput>>;
  /** Create new "MetadataPage" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPageList" relation. */
  create?: InputMaybe<Array<MetadataPageWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPageListUpdateInput = {
  /** Connect existing "MetadataPage" nodes to the existing "Metadata" node, through the "Metadata.metadataPageList" relation. */
  connect?: InputMaybe<Array<MetadataPageWhereUniqueInput>>;
  /** Create new "MetadataPage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPageList" relation. */
  create?: InputMaybe<Array<MetadataPageWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPage" nodes from the "Metadata.metadataPageList" relation. */
  delete?: InputMaybe<Array<MetadataPageWhereUniqueInput>>;
  /** Update existing "MetadataPage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPageList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPageListUpdateInput>>;
  /** Upsert existing "MetadataPage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPageList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPageListUpdateInput>>;
};

export type MetadataNestedMetadataPagePrismaListCreateInput = {
  /** Connect existing "MetadataPagePrisma" nodes to the new "Metadata" node, through the "Metadata.metadataPagePrismaList" relation. */
  connect?: InputMaybe<Array<MetadataPagePrismaWhereUniqueInput>>;
  /** Create new "MetadataPagePrisma" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPagePrismaList" relation. */
  create?: InputMaybe<Array<MetadataPagePrismaWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPagePrismaListUpdateInput = {
  /** Connect existing "MetadataPagePrisma" nodes to the existing "Metadata" node, through the "Metadata.metadataPagePrismaList" relation. */
  connect?: InputMaybe<Array<MetadataPagePrismaWhereUniqueInput>>;
  /** Create new "MetadataPagePrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPagePrismaList" relation. */
  create?: InputMaybe<Array<MetadataPagePrismaWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPagePrisma" nodes from the "Metadata.metadataPagePrismaList" relation. */
  delete?: InputMaybe<Array<MetadataPagePrismaWhereUniqueInput>>;
  /** Update existing "MetadataPagePrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPagePrismaList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPagePrismaListUpdateInput>>;
  /** Upsert existing "MetadataPagePrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPagePrismaList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPagePrismaListUpdateInput>>;
};

export type MetadataNestedMetadataPaintBasedCorrectionsListCreateInput = {
  /** Connect existing "MetadataPaintBasedCorrections" nodes to the new "Metadata" node, through the "Metadata.metadataPaintBasedCorrectionsList" relation. */
  connect?: InputMaybe<Array<MetadataPaintBasedCorrectionsWhereUniqueInput>>;
  /** Create new "MetadataPaintBasedCorrections" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPaintBasedCorrectionsList" relation. */
  create?: InputMaybe<Array<MetadataPaintBasedCorrectionsWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPaintBasedCorrectionsListUpdateInput = {
  /** Connect existing "MetadataPaintBasedCorrections" nodes to the existing "Metadata" node, through the "Metadata.metadataPaintBasedCorrectionsList" relation. */
  connect?: InputMaybe<Array<MetadataPaintBasedCorrectionsWhereUniqueInput>>;
  /** Create new "MetadataPaintBasedCorrections" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPaintBasedCorrectionsList" relation. */
  create?: InputMaybe<Array<MetadataPaintBasedCorrectionsWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPaintBasedCorrections" nodes from the "Metadata.metadataPaintBasedCorrectionsList" relation. */
  delete?: InputMaybe<Array<MetadataPaintBasedCorrectionsWhereUniqueInput>>;
  /** Update existing "MetadataPaintBasedCorrections" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPaintBasedCorrectionsList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPaintBasedCorrectionsListUpdateInput>>;
  /** Upsert existing "MetadataPaintBasedCorrections" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPaintBasedCorrectionsList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPaintBasedCorrectionsListUpdateInput>>;
};

export type MetadataNestedMetadataPatientDobListCreateInput = {
  /** Connect existing "MetadataPatientDob" nodes to the new "Metadata" node, through the "Metadata.metadataPatientDobList" relation. */
  connect?: InputMaybe<Array<MetadataPatientDobWhereUniqueInput>>;
  /** Create new "MetadataPatientDob" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPatientDobList" relation. */
  create?: InputMaybe<Array<MetadataPatientDobWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPatientDobListUpdateInput = {
  /** Connect existing "MetadataPatientDob" nodes to the existing "Metadata" node, through the "Metadata.metadataPatientDobList" relation. */
  connect?: InputMaybe<Array<MetadataPatientDobWhereUniqueInput>>;
  /** Create new "MetadataPatientDob" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientDobList" relation. */
  create?: InputMaybe<Array<MetadataPatientDobWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPatientDob" nodes from the "Metadata.metadataPatientDobList" relation. */
  delete?: InputMaybe<Array<MetadataPatientDobWhereUniqueInput>>;
  /** Update existing "MetadataPatientDob" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientDobList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPatientDobListUpdateInput>>;
  /** Upsert existing "MetadataPatientDob" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientDobList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPatientDobListUpdateInput>>;
};

export type MetadataNestedMetadataPatientIdListCreateInput = {
  /** Connect existing "MetadataPatientId" nodes to the new "Metadata" node, through the "Metadata.metadataPatientIdList" relation. */
  connect?: InputMaybe<Array<MetadataPatientIdWhereUniqueInput>>;
  /** Create new "MetadataPatientId" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPatientIdList" relation. */
  create?: InputMaybe<Array<MetadataPatientIdWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPatientIdListUpdateInput = {
  /** Connect existing "MetadataPatientId" nodes to the existing "Metadata" node, through the "Metadata.metadataPatientIdList" relation. */
  connect?: InputMaybe<Array<MetadataPatientIdWhereUniqueInput>>;
  /** Create new "MetadataPatientId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientIdList" relation. */
  create?: InputMaybe<Array<MetadataPatientIdWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPatientId" nodes from the "Metadata.metadataPatientIdList" relation. */
  delete?: InputMaybe<Array<MetadataPatientIdWhereUniqueInput>>;
  /** Update existing "MetadataPatientId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientIdList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPatientIdListUpdateInput>>;
  /** Upsert existing "MetadataPatientId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientIdList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPatientIdListUpdateInput>>;
};

export type MetadataNestedMetadataPatientNameListCreateInput = {
  /** Connect existing "MetadataPatientName" nodes to the new "Metadata" node, through the "Metadata.metadataPatientNameList" relation. */
  connect?: InputMaybe<Array<MetadataPatientNameWhereUniqueInput>>;
  /** Create new "MetadataPatientName" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPatientNameList" relation. */
  create?: InputMaybe<Array<MetadataPatientNameWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPatientNameListUpdateInput = {
  /** Connect existing "MetadataPatientName" nodes to the existing "Metadata" node, through the "Metadata.metadataPatientNameList" relation. */
  connect?: InputMaybe<Array<MetadataPatientNameWhereUniqueInput>>;
  /** Create new "MetadataPatientName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientNameList" relation. */
  create?: InputMaybe<Array<MetadataPatientNameWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPatientName" nodes from the "Metadata.metadataPatientNameList" relation. */
  delete?: InputMaybe<Array<MetadataPatientNameWhereUniqueInput>>;
  /** Update existing "MetadataPatientName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientNameList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPatientNameListUpdateInput>>;
  /** Upsert existing "MetadataPatientName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientNameList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPatientNameListUpdateInput>>;
};

export type MetadataNestedMetadataPatientSexListCreateInput = {
  /** Connect existing "MetadataPatientSex" nodes to the new "Metadata" node, through the "Metadata.metadataPatientSexList" relation. */
  connect?: InputMaybe<Array<MetadataPatientSexWhereUniqueInput>>;
  /** Create new "MetadataPatientSex" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPatientSexList" relation. */
  create?: InputMaybe<Array<MetadataPatientSexWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPatientSexListUpdateInput = {
  /** Connect existing "MetadataPatientSex" nodes to the existing "Metadata" node, through the "Metadata.metadataPatientSexList" relation. */
  connect?: InputMaybe<Array<MetadataPatientSexWhereUniqueInput>>;
  /** Create new "MetadataPatientSex" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientSexList" relation. */
  create?: InputMaybe<Array<MetadataPatientSexWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPatientSex" nodes from the "Metadata.metadataPatientSexList" relation. */
  delete?: InputMaybe<Array<MetadataPatientSexWhereUniqueInput>>;
  /** Update existing "MetadataPatientSex" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientSexList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPatientSexListUpdateInput>>;
  /** Upsert existing "MetadataPatientSex" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPatientSexList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPatientSexListUpdateInput>>;
};

export type MetadataNestedMetadataPersonInImageListCreateInput = {
  /** Connect existing "MetadataPersonInImage" nodes to the new "Metadata" node, through the "Metadata.metadataPersonInImageList" relation. */
  connect?: InputMaybe<Array<MetadataPersonInImageWhereUniqueInput>>;
  /** Create new "MetadataPersonInImage" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPersonInImageList" relation. */
  create?: InputMaybe<Array<MetadataPersonInImageWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPersonInImageListUpdateInput = {
  /** Connect existing "MetadataPersonInImage" nodes to the existing "Metadata" node, through the "Metadata.metadataPersonInImageList" relation. */
  connect?: InputMaybe<Array<MetadataPersonInImageWhereUniqueInput>>;
  /** Create new "MetadataPersonInImage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPersonInImageList" relation. */
  create?: InputMaybe<Array<MetadataPersonInImageWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPersonInImage" nodes from the "Metadata.metadataPersonInImageList" relation. */
  delete?: InputMaybe<Array<MetadataPersonInImageWhereUniqueInput>>;
  /** Update existing "MetadataPersonInImage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPersonInImageList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPersonInImageListUpdateInput>>;
  /** Upsert existing "MetadataPersonInImage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPersonInImageList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPersonInImageListUpdateInput>>;
};

export type MetadataNestedMetadataPhotoStorageListCreateInput = {
  /** Connect existing "MetadataPhotoStorage" nodes to the new "Metadata" node, through the "Metadata.metadataPhotoStorageList" relation. */
  connect?: InputMaybe<Array<MetadataPhotoStorageWhereUniqueInput>>;
  /** Create new "MetadataPhotoStorage" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPhotoStorageList" relation. */
  create?: InputMaybe<Array<MetadataPhotoStorageWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPhotoStorageListUpdateInput = {
  /** Connect existing "MetadataPhotoStorage" nodes to the existing "Metadata" node, through the "Metadata.metadataPhotoStorageList" relation. */
  connect?: InputMaybe<Array<MetadataPhotoStorageWhereUniqueInput>>;
  /** Create new "MetadataPhotoStorage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPhotoStorageList" relation. */
  create?: InputMaybe<Array<MetadataPhotoStorageWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPhotoStorage" nodes from the "Metadata.metadataPhotoStorageList" relation. */
  delete?: InputMaybe<Array<MetadataPhotoStorageWhereUniqueInput>>;
  /** Update existing "MetadataPhotoStorage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPhotoStorageList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPhotoStorageListUpdateInput>>;
  /** Upsert existing "MetadataPhotoStorage" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPhotoStorageList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPhotoStorageListUpdateInput>>;
};

export type MetadataNestedMetadataPrepressPictureEditingListCreateInput = {
  /** Connect existing "MetadataPrepressPictureEditing" nodes to the new "Metadata" node, through the "Metadata.metadataPrepressPictureEditingList" relation. */
  connect?: InputMaybe<Array<MetadataPrepressPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataPrepressPictureEditing" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPrepressPictureEditingList" relation. */
  create?: InputMaybe<Array<MetadataPrepressPictureEditingWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPrepressPictureEditingListUpdateInput = {
  /** Connect existing "MetadataPrepressPictureEditing" nodes to the existing "Metadata" node, through the "Metadata.metadataPrepressPictureEditingList" relation. */
  connect?: InputMaybe<Array<MetadataPrepressPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataPrepressPictureEditing" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPrepressPictureEditingList" relation. */
  create?: InputMaybe<Array<MetadataPrepressPictureEditingWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPrepressPictureEditing" nodes from the "Metadata.metadataPrepressPictureEditingList" relation. */
  delete?: InputMaybe<Array<MetadataPrepressPictureEditingWhereUniqueInput>>;
  /** Update existing "MetadataPrepressPictureEditing" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPrepressPictureEditingList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPrepressPictureEditingListUpdateInput>>;
  /** Upsert existing "MetadataPrepressPictureEditing" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPrepressPictureEditingList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPrepressPictureEditingListUpdateInput>>;
};

export type MetadataNestedMetadataPriceLevelListCreateInput = {
  /** Connect existing "MetadataPriceLevel" nodes to the new "Metadata" node, through the "Metadata.metadataPriceLevelList" relation. */
  connect?: InputMaybe<Array<MetadataPriceLevelWhereUniqueInput>>;
  /** Create new "MetadataPriceLevel" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPriceLevelList" relation. */
  create?: InputMaybe<Array<MetadataPriceLevelWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPriceLevelListUpdateInput = {
  /** Connect existing "MetadataPriceLevel" nodes to the existing "Metadata" node, through the "Metadata.metadataPriceLevelList" relation. */
  connect?: InputMaybe<Array<MetadataPriceLevelWhereUniqueInput>>;
  /** Create new "MetadataPriceLevel" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPriceLevelList" relation. */
  create?: InputMaybe<Array<MetadataPriceLevelWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPriceLevel" nodes from the "Metadata.metadataPriceLevelList" relation. */
  delete?: InputMaybe<Array<MetadataPriceLevelWhereUniqueInput>>;
  /** Update existing "MetadataPriceLevel" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPriceLevelList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPriceLevelListUpdateInput>>;
  /** Upsert existing "MetadataPriceLevel" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPriceLevelList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPriceLevelListUpdateInput>>;
};

export type MetadataNestedMetadataPrintingProfileListCreateInput = {
  /** Connect existing "MetadataPrintingProfile" nodes to the new "Metadata" node, through the "Metadata.metadataPrintingProfileList" relation. */
  connect?: InputMaybe<Array<MetadataPrintingProfileWhereUniqueInput>>;
  /** Create new "MetadataPrintingProfile" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPrintingProfileList" relation. */
  create?: InputMaybe<Array<MetadataPrintingProfileWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPrintingProfileListUpdateInput = {
  /** Connect existing "MetadataPrintingProfile" nodes to the existing "Metadata" node, through the "Metadata.metadataPrintingProfileList" relation. */
  connect?: InputMaybe<Array<MetadataPrintingProfileWhereUniqueInput>>;
  /** Create new "MetadataPrintingProfile" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPrintingProfileList" relation. */
  create?: InputMaybe<Array<MetadataPrintingProfileWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPrintingProfile" nodes from the "Metadata.metadataPrintingProfileList" relation. */
  delete?: InputMaybe<Array<MetadataPrintingProfileWhereUniqueInput>>;
  /** Update existing "MetadataPrintingProfile" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPrintingProfileList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPrintingProfileListUpdateInput>>;
  /** Upsert existing "MetadataPrintingProfile" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPrintingProfileList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPrintingProfileListUpdateInput>>;
};

export type MetadataNestedMetadataPrismaProductionListCreateInput = {
  /** Connect existing "MetadataPrismaProduction" nodes to the new "Metadata" node, through the "Metadata.metadataPrismaProductionList" relation. */
  connect?: InputMaybe<Array<MetadataPrismaProductionWhereUniqueInput>>;
  /** Create new "MetadataPrismaProduction" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPrismaProductionList" relation. */
  create?: InputMaybe<Array<MetadataPrismaProductionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPrismaProductionListUpdateInput = {
  /** Connect existing "MetadataPrismaProduction" nodes to the existing "Metadata" node, through the "Metadata.metadataPrismaProductionList" relation. */
  connect?: InputMaybe<Array<MetadataPrismaProductionWhereUniqueInput>>;
  /** Create new "MetadataPrismaProduction" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPrismaProductionList" relation. */
  create?: InputMaybe<Array<MetadataPrismaProductionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPrismaProduction" nodes from the "Metadata.metadataPrismaProductionList" relation. */
  delete?: InputMaybe<Array<MetadataPrismaProductionWhereUniqueInput>>;
  /** Update existing "MetadataPrismaProduction" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPrismaProductionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPrismaProductionListUpdateInput>>;
  /** Upsert existing "MetadataPrismaProduction" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPrismaProductionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPrismaProductionListUpdateInput>>;
};

export type MetadataNestedMetadataProcessHistoryListCreateInput = {
  /** Connect existing "MetadataProcessHistory" nodes to the new "Metadata" node, through the "Metadata.metadataProcessHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataProcessHistoryWhereUniqueInput>>;
  /** Create new "MetadataProcessHistory" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataProcessHistoryList" relation. */
  create?: InputMaybe<Array<MetadataProcessHistoryWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataProcessHistoryListUpdateInput = {
  /** Connect existing "MetadataProcessHistory" nodes to the existing "Metadata" node, through the "Metadata.metadataProcessHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataProcessHistoryWhereUniqueInput>>;
  /** Create new "MetadataProcessHistory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProcessHistoryList" relation. */
  create?: InputMaybe<Array<MetadataProcessHistoryWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataProcessHistory" nodes from the "Metadata.metadataProcessHistoryList" relation. */
  delete?: InputMaybe<Array<MetadataProcessHistoryWhereUniqueInput>>;
  /** Update existing "MetadataProcessHistory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProcessHistoryList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataProcessHistoryListUpdateInput>>;
  /** Upsert existing "MetadataProcessHistory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProcessHistoryList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataProcessHistoryListUpdateInput>>;
};

export type MetadataNestedMetadataProcessParameterListCreateInput = {
  /** Connect existing "MetadataProcessParameter" nodes to the new "Metadata" node, through the "Metadata.metadataProcessParameterList" relation. */
  connect?: InputMaybe<Array<MetadataProcessParameterWhereUniqueInput>>;
  /** Create new "MetadataProcessParameter" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataProcessParameterList" relation. */
  create?: InputMaybe<Array<MetadataProcessParameterWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataProcessParameterListUpdateInput = {
  /** Connect existing "MetadataProcessParameter" nodes to the existing "Metadata" node, through the "Metadata.metadataProcessParameterList" relation. */
  connect?: InputMaybe<Array<MetadataProcessParameterWhereUniqueInput>>;
  /** Create new "MetadataProcessParameter" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProcessParameterList" relation. */
  create?: InputMaybe<Array<MetadataProcessParameterWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataProcessParameter" nodes from the "Metadata.metadataProcessParameterList" relation. */
  delete?: InputMaybe<Array<MetadataProcessParameterWhereUniqueInput>>;
  /** Update existing "MetadataProcessParameter" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProcessParameterList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataProcessParameterListUpdateInput>>;
  /** Upsert existing "MetadataProcessParameter" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProcessParameterList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataProcessParameterListUpdateInput>>;
};

export type MetadataNestedMetadataProcessStatusListCreateInput = {
  /** Connect existing "MetadataProcessStatus" nodes to the new "Metadata" node, through the "Metadata.metadataProcessStatusList" relation. */
  connect?: InputMaybe<Array<MetadataProcessStatusWhereUniqueInput>>;
  /** Create new "MetadataProcessStatus" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataProcessStatusList" relation. */
  create?: InputMaybe<Array<MetadataProcessStatusWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataProcessStatusListUpdateInput = {
  /** Connect existing "MetadataProcessStatus" nodes to the existing "Metadata" node, through the "Metadata.metadataProcessStatusList" relation. */
  connect?: InputMaybe<Array<MetadataProcessStatusWhereUniqueInput>>;
  /** Create new "MetadataProcessStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProcessStatusList" relation. */
  create?: InputMaybe<Array<MetadataProcessStatusWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataProcessStatus" nodes from the "Metadata.metadataProcessStatusList" relation. */
  delete?: InputMaybe<Array<MetadataProcessStatusWhereUniqueInput>>;
  /** Update existing "MetadataProcessStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProcessStatusList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataProcessStatusListUpdateInput>>;
  /** Upsert existing "MetadataProcessStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProcessStatusList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataProcessStatusListUpdateInput>>;
};

export type MetadataNestedMetadataProductListCreateInput = {
  /** Connect existing "MetadataProduct" nodes to the new "Metadata" node, through the "Metadata.metadataProductList" relation. */
  connect?: InputMaybe<Array<MetadataProductWhereUniqueInput>>;
  /** Create new "MetadataProduct" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataProductList" relation. */
  create?: InputMaybe<Array<MetadataProductWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataProductListUpdateInput = {
  /** Connect existing "MetadataProduct" nodes to the existing "Metadata" node, through the "Metadata.metadataProductList" relation. */
  connect?: InputMaybe<Array<MetadataProductWhereUniqueInput>>;
  /** Create new "MetadataProduct" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProductList" relation. */
  create?: InputMaybe<Array<MetadataProductWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataProduct" nodes from the "Metadata.metadataProductList" relation. */
  delete?: InputMaybe<Array<MetadataProductWhereUniqueInput>>;
  /** Update existing "MetadataProduct" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProductList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataProductListUpdateInput>>;
  /** Upsert existing "MetadataProduct" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProductList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataProductListUpdateInput>>;
};

export type MetadataNestedMetadataProductShortNameListCreateInput = {
  /** Connect existing "MetadataProductShortName" nodes to the new "Metadata" node, through the "Metadata.metadataProductShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataProductShortNameWhereUniqueInput>>;
  /** Create new "MetadataProductShortName" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataProductShortNameList" relation. */
  create?: InputMaybe<Array<MetadataProductShortNameWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataProductShortNameListUpdateInput = {
  /** Connect existing "MetadataProductShortName" nodes to the existing "Metadata" node, through the "Metadata.metadataProductShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataProductShortNameWhereUniqueInput>>;
  /** Create new "MetadataProductShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProductShortNameList" relation. */
  create?: InputMaybe<Array<MetadataProductShortNameWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataProductShortName" nodes from the "Metadata.metadataProductShortNameList" relation. */
  delete?: InputMaybe<Array<MetadataProductShortNameWhereUniqueInput>>;
  /** Update existing "MetadataProductShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProductShortNameList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataProductShortNameListUpdateInput>>;
  /** Upsert existing "MetadataProductShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProductShortNameList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataProductShortNameListUpdateInput>>;
};

export type MetadataNestedMetadataProductsListCreateInput = {
  /** Connect existing "MetadataProducts" nodes to the new "Metadata" node, through the "Metadata.metadataProductsList" relation. */
  connect?: InputMaybe<Array<MetadataProductsWhereUniqueInput>>;
  /** Create new "MetadataProducts" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataProductsList" relation. */
  create?: InputMaybe<Array<MetadataProductsWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataProductsListUpdateInput = {
  /** Connect existing "MetadataProducts" nodes to the existing "Metadata" node, through the "Metadata.metadataProductsList" relation. */
  connect?: InputMaybe<Array<MetadataProductsWhereUniqueInput>>;
  /** Create new "MetadataProducts" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProductsList" relation. */
  create?: InputMaybe<Array<MetadataProductsWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataProducts" nodes from the "Metadata.metadataProductsList" relation. */
  delete?: InputMaybe<Array<MetadataProductsWhereUniqueInput>>;
  /** Update existing "MetadataProducts" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProductsList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataProductsListUpdateInput>>;
  /** Upsert existing "MetadataProducts" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProductsList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataProductsListUpdateInput>>;
};

export type MetadataNestedMetadataProduitsListCreateInput = {
  /** Connect existing "MetadataProduits" nodes to the new "Metadata" node, through the "Metadata.metadataProduitsList" relation. */
  connect?: InputMaybe<Array<MetadataProduitsWhereUniqueInput>>;
  /** Create new "MetadataProduits" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataProduitsList" relation. */
  create?: InputMaybe<Array<MetadataProduitsWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataProduitsListUpdateInput = {
  /** Connect existing "MetadataProduits" nodes to the existing "Metadata" node, through the "Metadata.metadataProduitsList" relation. */
  connect?: InputMaybe<Array<MetadataProduitsWhereUniqueInput>>;
  /** Create new "MetadataProduits" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProduitsList" relation. */
  create?: InputMaybe<Array<MetadataProduitsWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataProduits" nodes from the "Metadata.metadataProduitsList" relation. */
  delete?: InputMaybe<Array<MetadataProduitsWhereUniqueInput>>;
  /** Update existing "MetadataProduits" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProduitsList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataProduitsListUpdateInput>>;
  /** Upsert existing "MetadataProduits" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProduitsList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataProduitsListUpdateInput>>;
};

export type MetadataNestedMetadataProgramVersionListCreateInput = {
  /** Connect existing "MetadataProgramVersion" nodes to the new "Metadata" node, through the "Metadata.metadataProgramVersionList" relation. */
  connect?: InputMaybe<Array<MetadataProgramVersionWhereUniqueInput>>;
  /** Create new "MetadataProgramVersion" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataProgramVersionList" relation. */
  create?: InputMaybe<Array<MetadataProgramVersionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataProgramVersionListUpdateInput = {
  /** Connect existing "MetadataProgramVersion" nodes to the existing "Metadata" node, through the "Metadata.metadataProgramVersionList" relation. */
  connect?: InputMaybe<Array<MetadataProgramVersionWhereUniqueInput>>;
  /** Create new "MetadataProgramVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProgramVersionList" relation. */
  create?: InputMaybe<Array<MetadataProgramVersionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataProgramVersion" nodes from the "Metadata.metadataProgramVersionList" relation. */
  delete?: InputMaybe<Array<MetadataProgramVersionWhereUniqueInput>>;
  /** Update existing "MetadataProgramVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProgramVersionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataProgramVersionListUpdateInput>>;
  /** Upsert existing "MetadataProgramVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataProgramVersionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataProgramVersionListUpdateInput>>;
};

export type MetadataNestedMetadataPropertyReleaseIdListCreateInput = {
  /** Connect existing "MetadataPropertyReleaseId" nodes to the new "Metadata" node, through the "Metadata.metadataPropertyReleaseIdList" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseId" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPropertyReleaseIdList" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseIdWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPropertyReleaseIdListUpdateInput = {
  /** Connect existing "MetadataPropertyReleaseId" nodes to the existing "Metadata" node, through the "Metadata.metadataPropertyReleaseIdList" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPropertyReleaseIdList" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseIdWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPropertyReleaseId" nodes from the "Metadata.metadataPropertyReleaseIdList" relation. */
  delete?: InputMaybe<Array<MetadataPropertyReleaseIdWhereUniqueInput>>;
  /** Update existing "MetadataPropertyReleaseId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPropertyReleaseIdList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPropertyReleaseIdListUpdateInput>>;
  /** Upsert existing "MetadataPropertyReleaseId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPropertyReleaseIdList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPropertyReleaseIdListUpdateInput>>;
};

export type MetadataNestedMetadataPropertyReleaseStatusListCreateInput = {
  /** Connect existing "MetadataPropertyReleaseStatus" nodes to the new "Metadata" node, through the "Metadata.metadataPropertyReleaseStatusList" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseStatus" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPropertyReleaseStatusList" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseStatusWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPropertyReleaseStatusListUpdateInput = {
  /** Connect existing "MetadataPropertyReleaseStatus" nodes to the existing "Metadata" node, through the "Metadata.metadataPropertyReleaseStatusList" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPropertyReleaseStatusList" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseStatusWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPropertyReleaseStatus" nodes from the "Metadata.metadataPropertyReleaseStatusList" relation. */
  delete?: InputMaybe<Array<MetadataPropertyReleaseStatusWhereUniqueInput>>;
  /** Update existing "MetadataPropertyReleaseStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPropertyReleaseStatusList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPropertyReleaseStatusListUpdateInput>>;
  /** Upsert existing "MetadataPropertyReleaseStatus" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPropertyReleaseStatusList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPropertyReleaseStatusListUpdateInput>>;
};

export type MetadataNestedMetadataPublisherListCreateInput = {
  /** Connect existing "MetadataPublisher" nodes to the new "Metadata" node, through the "Metadata.metadataPublisherList" relation. */
  connect?: InputMaybe<Array<MetadataPublisherWhereUniqueInput>>;
  /** Create new "MetadataPublisher" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPublisherList" relation. */
  create?: InputMaybe<Array<MetadataPublisherWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPublisherListUpdateInput = {
  /** Connect existing "MetadataPublisher" nodes to the existing "Metadata" node, through the "Metadata.metadataPublisherList" relation. */
  connect?: InputMaybe<Array<MetadataPublisherWhereUniqueInput>>;
  /** Create new "MetadataPublisher" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPublisherList" relation. */
  create?: InputMaybe<Array<MetadataPublisherWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPublisher" nodes from the "Metadata.metadataPublisherList" relation. */
  delete?: InputMaybe<Array<MetadataPublisherWhereUniqueInput>>;
  /** Update existing "MetadataPublisher" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPublisherList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPublisherListUpdateInput>>;
  /** Upsert existing "MetadataPublisher" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPublisherList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPublisherListUpdateInput>>;
};

export type MetadataNestedMetadataPublishingIssueListCreateInput = {
  /** Connect existing "MetadataPublishingIssue" nodes to the new "Metadata" node, through the "Metadata.metadataPublishingIssueList" relation. */
  connect?: InputMaybe<Array<MetadataPublishingIssueWhereUniqueInput>>;
  /** Create new "MetadataPublishingIssue" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPublishingIssueList" relation. */
  create?: InputMaybe<Array<MetadataPublishingIssueWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPublishingIssueListUpdateInput = {
  /** Connect existing "MetadataPublishingIssue" nodes to the existing "Metadata" node, through the "Metadata.metadataPublishingIssueList" relation. */
  connect?: InputMaybe<Array<MetadataPublishingIssueWhereUniqueInput>>;
  /** Create new "MetadataPublishingIssue" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPublishingIssueList" relation. */
  create?: InputMaybe<Array<MetadataPublishingIssueWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPublishingIssue" nodes from the "Metadata.metadataPublishingIssueList" relation. */
  delete?: InputMaybe<Array<MetadataPublishingIssueWhereUniqueInput>>;
  /** Update existing "MetadataPublishingIssue" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPublishingIssueList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPublishingIssueListUpdateInput>>;
  /** Upsert existing "MetadataPublishingIssue" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPublishingIssueList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPublishingIssueListUpdateInput>>;
};

export type MetadataNestedMetadataPublishingSubjectListCreateInput = {
  /** Connect existing "MetadataPublishingSubject" nodes to the new "Metadata" node, through the "Metadata.metadataPublishingSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataPublishingSubjectWhereUniqueInput>>;
  /** Create new "MetadataPublishingSubject" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataPublishingSubjectList" relation. */
  create?: InputMaybe<Array<MetadataPublishingSubjectWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataPublishingSubjectListUpdateInput = {
  /** Connect existing "MetadataPublishingSubject" nodes to the existing "Metadata" node, through the "Metadata.metadataPublishingSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataPublishingSubjectWhereUniqueInput>>;
  /** Create new "MetadataPublishingSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPublishingSubjectList" relation. */
  create?: InputMaybe<Array<MetadataPublishingSubjectWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataPublishingSubject" nodes from the "Metadata.metadataPublishingSubjectList" relation. */
  delete?: InputMaybe<Array<MetadataPublishingSubjectWhereUniqueInput>>;
  /** Update existing "MetadataPublishingSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPublishingSubjectList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataPublishingSubjectListUpdateInput>>;
  /** Upsert existing "MetadataPublishingSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataPublishingSubjectList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataPublishingSubjectListUpdateInput>>;
};

export type MetadataNestedMetadataQualifiedUsePrismaByListCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaBy" nodes to the new "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaByList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaByWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaBy" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaByList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaByWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataQualifiedUsePrismaByListUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaBy" nodes to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaByList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaByWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaBy" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaByList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaByWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaBy" nodes from the "Metadata.metadataQualifiedUsePrismaByList" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaByWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaBy" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaByList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataQualifiedUsePrismaByListUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaBy" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaByList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataQualifiedUsePrismaByListUpdateInput>>;
};

export type MetadataNestedMetadataQualifiedUsePrismaDateListCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDate" nodes to the new "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDateList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDate" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDateList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataQualifiedUsePrismaDateListUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDate" nodes to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDateList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDateList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaDate" nodes from the "Metadata.metadataQualifiedUsePrismaDateList" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaDate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDateList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataQualifiedUsePrismaDateListUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaDate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDateList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataQualifiedUsePrismaDateListUpdateInput>>;
};

export type MetadataNestedMetadataQualifiedUsePrismaDurationListCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDuration" nodes to the new "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDurationList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDuration" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDurationList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataQualifiedUsePrismaDurationListUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDuration" nodes to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDurationList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDuration" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDurationList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaDuration" nodes from the "Metadata.metadataQualifiedUsePrismaDurationList" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaDuration" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDurationList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataQualifiedUsePrismaDurationListUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaDuration" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaDurationList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataQualifiedUsePrismaDurationListUpdateInput>>;
};

export type MetadataNestedMetadataQualifiedUsePrismaSupportListCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaSupport" nodes to the new "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaSupportList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaSupport" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaSupportList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataQualifiedUsePrismaSupportListUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaSupport" nodes to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaSupportList" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaSupport" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaSupportList" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaSupport" nodes from the "Metadata.metadataQualifiedUsePrismaSupportList" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaSupport" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaSupportList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataQualifiedUsePrismaSupportListUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaSupport" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataQualifiedUsePrismaSupportList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataQualifiedUsePrismaSupportListUpdateInput>>;
};

export type MetadataNestedMetadataRatingListCreateInput = {
  /** Connect existing "MetadataRating" nodes to the new "Metadata" node, through the "Metadata.metadataRatingList" relation. */
  connect?: InputMaybe<Array<MetadataRatingWhereUniqueInput>>;
  /** Create new "MetadataRating" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataRatingList" relation. */
  create?: InputMaybe<Array<MetadataRatingWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataRatingListUpdateInput = {
  /** Connect existing "MetadataRating" nodes to the existing "Metadata" node, through the "Metadata.metadataRatingList" relation. */
  connect?: InputMaybe<Array<MetadataRatingWhereUniqueInput>>;
  /** Create new "MetadataRating" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRatingList" relation. */
  create?: InputMaybe<Array<MetadataRatingWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataRating" nodes from the "Metadata.metadataRatingList" relation. */
  delete?: InputMaybe<Array<MetadataRatingWhereUniqueInput>>;
  /** Update existing "MetadataRating" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRatingList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataRatingListUpdateInput>>;
  /** Upsert existing "MetadataRating" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRatingList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataRatingListUpdateInput>>;
};

export type MetadataNestedMetadataReferenceDateListCreateInput = {
  /** Connect existing "MetadataReferenceDate" nodes to the new "Metadata" node, through the "Metadata.metadataReferenceDateList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceDateWhereUniqueInput>>;
  /** Create new "MetadataReferenceDate" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataReferenceDateList" relation. */
  create?: InputMaybe<Array<MetadataReferenceDateWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataReferenceDateListUpdateInput = {
  /** Connect existing "MetadataReferenceDate" nodes to the existing "Metadata" node, through the "Metadata.metadataReferenceDateList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceDateWhereUniqueInput>>;
  /** Create new "MetadataReferenceDate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReferenceDateList" relation. */
  create?: InputMaybe<Array<MetadataReferenceDateWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataReferenceDate" nodes from the "Metadata.metadataReferenceDateList" relation. */
  delete?: InputMaybe<Array<MetadataReferenceDateWhereUniqueInput>>;
  /** Update existing "MetadataReferenceDate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReferenceDateList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataReferenceDateListUpdateInput>>;
  /** Upsert existing "MetadataReferenceDate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReferenceDateList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataReferenceDateListUpdateInput>>;
};

export type MetadataNestedMetadataReferenceNumberListCreateInput = {
  /** Connect existing "MetadataReferenceNumber" nodes to the new "Metadata" node, through the "Metadata.metadataReferenceNumberList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceNumberWhereUniqueInput>>;
  /** Create new "MetadataReferenceNumber" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataReferenceNumberList" relation. */
  create?: InputMaybe<Array<MetadataReferenceNumberWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataReferenceNumberListUpdateInput = {
  /** Connect existing "MetadataReferenceNumber" nodes to the existing "Metadata" node, through the "Metadata.metadataReferenceNumberList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceNumberWhereUniqueInput>>;
  /** Create new "MetadataReferenceNumber" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReferenceNumberList" relation. */
  create?: InputMaybe<Array<MetadataReferenceNumberWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataReferenceNumber" nodes from the "Metadata.metadataReferenceNumberList" relation. */
  delete?: InputMaybe<Array<MetadataReferenceNumberWhereUniqueInput>>;
  /** Update existing "MetadataReferenceNumber" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReferenceNumberList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataReferenceNumberListUpdateInput>>;
  /** Upsert existing "MetadataReferenceNumber" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReferenceNumberList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataReferenceNumberListUpdateInput>>;
};

export type MetadataNestedMetadataReferenceServiceListCreateInput = {
  /** Connect existing "MetadataReferenceService" nodes to the new "Metadata" node, through the "Metadata.metadataReferenceServiceList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceServiceWhereUniqueInput>>;
  /** Create new "MetadataReferenceService" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataReferenceServiceList" relation. */
  create?: InputMaybe<Array<MetadataReferenceServiceWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataReferenceServiceListUpdateInput = {
  /** Connect existing "MetadataReferenceService" nodes to the existing "Metadata" node, through the "Metadata.metadataReferenceServiceList" relation. */
  connect?: InputMaybe<Array<MetadataReferenceServiceWhereUniqueInput>>;
  /** Create new "MetadataReferenceService" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReferenceServiceList" relation. */
  create?: InputMaybe<Array<MetadataReferenceServiceWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataReferenceService" nodes from the "Metadata.metadataReferenceServiceList" relation. */
  delete?: InputMaybe<Array<MetadataReferenceServiceWhereUniqueInput>>;
  /** Update existing "MetadataReferenceService" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReferenceServiceList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataReferenceServiceListUpdateInput>>;
  /** Upsert existing "MetadataReferenceService" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReferenceServiceList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataReferenceServiceListUpdateInput>>;
};

export type MetadataNestedMetadataReleaseDateListCreateInput = {
  /** Connect existing "MetadataReleaseDate" nodes to the new "Metadata" node, through the "Metadata.metadataReleaseDateList" relation. */
  connect?: InputMaybe<Array<MetadataReleaseDateWhereUniqueInput>>;
  /** Create new "MetadataReleaseDate" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataReleaseDateList" relation. */
  create?: InputMaybe<Array<MetadataReleaseDateWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataReleaseDateListUpdateInput = {
  /** Connect existing "MetadataReleaseDate" nodes to the existing "Metadata" node, through the "Metadata.metadataReleaseDateList" relation. */
  connect?: InputMaybe<Array<MetadataReleaseDateWhereUniqueInput>>;
  /** Create new "MetadataReleaseDate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReleaseDateList" relation. */
  create?: InputMaybe<Array<MetadataReleaseDateWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataReleaseDate" nodes from the "Metadata.metadataReleaseDateList" relation. */
  delete?: InputMaybe<Array<MetadataReleaseDateWhereUniqueInput>>;
  /** Update existing "MetadataReleaseDate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReleaseDateList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataReleaseDateListUpdateInput>>;
  /** Upsert existing "MetadataReleaseDate" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReleaseDateList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataReleaseDateListUpdateInput>>;
};

export type MetadataNestedMetadataReleaseTimeListCreateInput = {
  /** Connect existing "MetadataReleaseTime" nodes to the new "Metadata" node, through the "Metadata.metadataReleaseTimeList" relation. */
  connect?: InputMaybe<Array<MetadataReleaseTimeWhereUniqueInput>>;
  /** Create new "MetadataReleaseTime" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataReleaseTimeList" relation. */
  create?: InputMaybe<Array<MetadataReleaseTimeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataReleaseTimeListUpdateInput = {
  /** Connect existing "MetadataReleaseTime" nodes to the existing "Metadata" node, through the "Metadata.metadataReleaseTimeList" relation. */
  connect?: InputMaybe<Array<MetadataReleaseTimeWhereUniqueInput>>;
  /** Create new "MetadataReleaseTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReleaseTimeList" relation. */
  create?: InputMaybe<Array<MetadataReleaseTimeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataReleaseTime" nodes from the "Metadata.metadataReleaseTimeList" relation. */
  delete?: InputMaybe<Array<MetadataReleaseTimeWhereUniqueInput>>;
  /** Update existing "MetadataReleaseTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReleaseTimeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataReleaseTimeListUpdateInput>>;
  /** Upsert existing "MetadataReleaseTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataReleaseTimeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataReleaseTimeListUpdateInput>>;
};

export type MetadataNestedMetadataRequiredPermissionListCreateInput = {
  /** Connect existing "MetadataRequiredPermission" nodes to the new "Metadata" node, through the "Metadata.metadataRequiredPermissionList" relation. */
  connect?: InputMaybe<Array<MetadataRequiredPermissionWhereUniqueInput>>;
  /** Create new "MetadataRequiredPermission" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataRequiredPermissionList" relation. */
  create?: InputMaybe<Array<MetadataRequiredPermissionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataRequiredPermissionListUpdateInput = {
  /** Connect existing "MetadataRequiredPermission" nodes to the existing "Metadata" node, through the "Metadata.metadataRequiredPermissionList" relation. */
  connect?: InputMaybe<Array<MetadataRequiredPermissionWhereUniqueInput>>;
  /** Create new "MetadataRequiredPermission" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRequiredPermissionList" relation. */
  create?: InputMaybe<Array<MetadataRequiredPermissionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataRequiredPermission" nodes from the "Metadata.metadataRequiredPermissionList" relation. */
  delete?: InputMaybe<Array<MetadataRequiredPermissionWhereUniqueInput>>;
  /** Update existing "MetadataRequiredPermission" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRequiredPermissionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataRequiredPermissionListUpdateInput>>;
  /** Upsert existing "MetadataRequiredPermission" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRequiredPermissionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataRequiredPermissionListUpdateInput>>;
};

export type MetadataNestedMetadataResolutionKindListCreateInput = {
  /** Connect existing "MetadataResolutionKind" nodes to the new "Metadata" node, through the "Metadata.metadataResolutionKindList" relation. */
  connect?: InputMaybe<Array<MetadataResolutionKindWhereUniqueInput>>;
  /** Create new "MetadataResolutionKind" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataResolutionKindList" relation. */
  create?: InputMaybe<Array<MetadataResolutionKindWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataResolutionKindListUpdateInput = {
  /** Connect existing "MetadataResolutionKind" nodes to the existing "Metadata" node, through the "Metadata.metadataResolutionKindList" relation. */
  connect?: InputMaybe<Array<MetadataResolutionKindWhereUniqueInput>>;
  /** Create new "MetadataResolutionKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataResolutionKindList" relation. */
  create?: InputMaybe<Array<MetadataResolutionKindWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataResolutionKind" nodes from the "Metadata.metadataResolutionKindList" relation. */
  delete?: InputMaybe<Array<MetadataResolutionKindWhereUniqueInput>>;
  /** Update existing "MetadataResolutionKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataResolutionKindList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataResolutionKindListUpdateInput>>;
  /** Upsert existing "MetadataResolutionKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataResolutionKindList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataResolutionKindListUpdateInput>>;
};

export type MetadataNestedMetadataRightsListCreateInput = {
  /** Connect existing "MetadataRights" nodes to the new "Metadata" node, through the "Metadata.metadataRightsList" relation. */
  connect?: InputMaybe<Array<MetadataRightsWhereUniqueInput>>;
  /** Create new "MetadataRights" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataRightsList" relation. */
  create?: InputMaybe<Array<MetadataRightsWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataRightsListUpdateInput = {
  /** Connect existing "MetadataRights" nodes to the existing "Metadata" node, through the "Metadata.metadataRightsList" relation. */
  connect?: InputMaybe<Array<MetadataRightsWhereUniqueInput>>;
  /** Create new "MetadataRights" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRightsList" relation. */
  create?: InputMaybe<Array<MetadataRightsWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataRights" nodes from the "Metadata.metadataRightsList" relation. */
  delete?: InputMaybe<Array<MetadataRightsWhereUniqueInput>>;
  /** Update existing "MetadataRights" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRightsList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataRightsListUpdateInput>>;
  /** Upsert existing "MetadataRights" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRightsList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataRightsListUpdateInput>>;
};

export type MetadataNestedMetadataRoyaltyfreeListCreateInput = {
  /** Connect existing "MetadataRoyaltyfree" nodes to the new "Metadata" node, through the "Metadata.metadataRoyaltyfreeList" relation. */
  connect?: InputMaybe<Array<MetadataRoyaltyfreeWhereUniqueInput>>;
  /** Create new "MetadataRoyaltyfree" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataRoyaltyfreeList" relation. */
  create?: InputMaybe<Array<MetadataRoyaltyfreeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataRoyaltyfreeListUpdateInput = {
  /** Connect existing "MetadataRoyaltyfree" nodes to the existing "Metadata" node, through the "Metadata.metadataRoyaltyfreeList" relation. */
  connect?: InputMaybe<Array<MetadataRoyaltyfreeWhereUniqueInput>>;
  /** Create new "MetadataRoyaltyfree" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRoyaltyfreeList" relation. */
  create?: InputMaybe<Array<MetadataRoyaltyfreeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataRoyaltyfree" nodes from the "Metadata.metadataRoyaltyfreeList" relation. */
  delete?: InputMaybe<Array<MetadataRoyaltyfreeWhereUniqueInput>>;
  /** Update existing "MetadataRoyaltyfree" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRoyaltyfreeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataRoyaltyfreeListUpdateInput>>;
  /** Upsert existing "MetadataRoyaltyfree" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataRoyaltyfreeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataRoyaltyfreeListUpdateInput>>;
};

export type MetadataNestedMetadataSceneListCreateInput = {
  /** Connect existing "MetadataScene" nodes to the new "Metadata" node, through the "Metadata.metadataSceneList" relation. */
  connect?: InputMaybe<Array<MetadataSceneWhereUniqueInput>>;
  /** Create new "MetadataScene" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSceneList" relation. */
  create?: InputMaybe<Array<MetadataSceneWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSceneListUpdateInput = {
  /** Connect existing "MetadataScene" nodes to the existing "Metadata" node, through the "Metadata.metadataSceneList" relation. */
  connect?: InputMaybe<Array<MetadataSceneWhereUniqueInput>>;
  /** Create new "MetadataScene" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSceneList" relation. */
  create?: InputMaybe<Array<MetadataSceneWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataScene" nodes from the "Metadata.metadataSceneList" relation. */
  delete?: InputMaybe<Array<MetadataSceneWhereUniqueInput>>;
  /** Update existing "MetadataScene" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSceneList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSceneListUpdateInput>>;
  /** Upsert existing "MetadataScene" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSceneList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSceneListUpdateInput>>;
};

export type MetadataNestedMetadataSectionListCreateInput = {
  /** Connect existing "MetadataSection" nodes to the new "Metadata" node, through the "Metadata.metadataSectionList" relation. */
  connect?: InputMaybe<Array<MetadataSectionWhereUniqueInput>>;
  /** Create new "MetadataSection" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSectionList" relation. */
  create?: InputMaybe<Array<MetadataSectionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSectionListUpdateInput = {
  /** Connect existing "MetadataSection" nodes to the existing "Metadata" node, through the "Metadata.metadataSectionList" relation. */
  connect?: InputMaybe<Array<MetadataSectionWhereUniqueInput>>;
  /** Create new "MetadataSection" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSectionList" relation. */
  create?: InputMaybe<Array<MetadataSectionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSection" nodes from the "Metadata.metadataSectionList" relation. */
  delete?: InputMaybe<Array<MetadataSectionWhereUniqueInput>>;
  /** Update existing "MetadataSection" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSectionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSectionListUpdateInput>>;
  /** Upsert existing "MetadataSection" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSectionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSectionListUpdateInput>>;
};

export type MetadataNestedMetadataSentToListCreateInput = {
  /** Connect existing "MetadataSentTo" nodes to the new "Metadata" node, through the "Metadata.metadataSentToList" relation. */
  connect?: InputMaybe<Array<MetadataSentToWhereUniqueInput>>;
  /** Create new "MetadataSentTo" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSentToList" relation. */
  create?: InputMaybe<Array<MetadataSentToWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSentToListUpdateInput = {
  /** Connect existing "MetadataSentTo" nodes to the existing "Metadata" node, through the "Metadata.metadataSentToList" relation. */
  connect?: InputMaybe<Array<MetadataSentToWhereUniqueInput>>;
  /** Create new "MetadataSentTo" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSentToList" relation. */
  create?: InputMaybe<Array<MetadataSentToWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSentTo" nodes from the "Metadata.metadataSentToList" relation. */
  delete?: InputMaybe<Array<MetadataSentToWhereUniqueInput>>;
  /** Update existing "MetadataSentTo" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSentToList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSentToListUpdateInput>>;
  /** Upsert existing "MetadataSentTo" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSentToList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSentToListUpdateInput>>;
};

export type MetadataNestedMetadataSerialNumberListCreateInput = {
  /** Connect existing "MetadataSerialNumber" nodes to the new "Metadata" node, through the "Metadata.metadataSerialNumberList" relation. */
  connect?: InputMaybe<Array<MetadataSerialNumberWhereUniqueInput>>;
  /** Create new "MetadataSerialNumber" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSerialNumberList" relation. */
  create?: InputMaybe<Array<MetadataSerialNumberWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSerialNumberListUpdateInput = {
  /** Connect existing "MetadataSerialNumber" nodes to the existing "Metadata" node, through the "Metadata.metadataSerialNumberList" relation. */
  connect?: InputMaybe<Array<MetadataSerialNumberWhereUniqueInput>>;
  /** Create new "MetadataSerialNumber" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSerialNumberList" relation. */
  create?: InputMaybe<Array<MetadataSerialNumberWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSerialNumber" nodes from the "Metadata.metadataSerialNumberList" relation. */
  delete?: InputMaybe<Array<MetadataSerialNumberWhereUniqueInput>>;
  /** Update existing "MetadataSerialNumber" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSerialNumberList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSerialNumberListUpdateInput>>;
  /** Upsert existing "MetadataSerialNumber" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSerialNumberList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSerialNumberListUpdateInput>>;
};

export type MetadataNestedMetadataSeriesDateTimeListCreateInput = {
  /** Connect existing "MetadataSeriesDateTime" nodes to the new "Metadata" node, through the "Metadata.metadataSeriesDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDateTimeWhereUniqueInput>>;
  /** Create new "MetadataSeriesDateTime" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSeriesDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataSeriesDateTimeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSeriesDateTimeListUpdateInput = {
  /** Connect existing "MetadataSeriesDateTime" nodes to the existing "Metadata" node, through the "Metadata.metadataSeriesDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDateTimeWhereUniqueInput>>;
  /** Create new "MetadataSeriesDateTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataSeriesDateTimeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSeriesDateTime" nodes from the "Metadata.metadataSeriesDateTimeList" relation. */
  delete?: InputMaybe<Array<MetadataSeriesDateTimeWhereUniqueInput>>;
  /** Update existing "MetadataSeriesDateTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesDateTimeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSeriesDateTimeListUpdateInput>>;
  /** Upsert existing "MetadataSeriesDateTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesDateTimeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSeriesDateTimeListUpdateInput>>;
};

export type MetadataNestedMetadataSeriesDescriptionListCreateInput = {
  /** Connect existing "MetadataSeriesDescription" nodes to the new "Metadata" node, through the "Metadata.metadataSeriesDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDescriptionWhereUniqueInput>>;
  /** Create new "MetadataSeriesDescription" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSeriesDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataSeriesDescriptionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSeriesDescriptionListUpdateInput = {
  /** Connect existing "MetadataSeriesDescription" nodes to the existing "Metadata" node, through the "Metadata.metadataSeriesDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDescriptionWhereUniqueInput>>;
  /** Create new "MetadataSeriesDescription" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataSeriesDescriptionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSeriesDescription" nodes from the "Metadata.metadataSeriesDescriptionList" relation. */
  delete?: InputMaybe<Array<MetadataSeriesDescriptionWhereUniqueInput>>;
  /** Update existing "MetadataSeriesDescription" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesDescriptionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSeriesDescriptionListUpdateInput>>;
  /** Upsert existing "MetadataSeriesDescription" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesDescriptionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSeriesDescriptionListUpdateInput>>;
};

export type MetadataNestedMetadataSeriesModalityListCreateInput = {
  /** Connect existing "MetadataSeriesModality" nodes to the new "Metadata" node, through the "Metadata.metadataSeriesModalityList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesModalityWhereUniqueInput>>;
  /** Create new "MetadataSeriesModality" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSeriesModalityList" relation. */
  create?: InputMaybe<Array<MetadataSeriesModalityWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSeriesModalityListUpdateInput = {
  /** Connect existing "MetadataSeriesModality" nodes to the existing "Metadata" node, through the "Metadata.metadataSeriesModalityList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesModalityWhereUniqueInput>>;
  /** Create new "MetadataSeriesModality" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesModalityList" relation. */
  create?: InputMaybe<Array<MetadataSeriesModalityWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSeriesModality" nodes from the "Metadata.metadataSeriesModalityList" relation. */
  delete?: InputMaybe<Array<MetadataSeriesModalityWhereUniqueInput>>;
  /** Update existing "MetadataSeriesModality" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesModalityList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSeriesModalityListUpdateInput>>;
  /** Upsert existing "MetadataSeriesModality" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesModalityList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSeriesModalityListUpdateInput>>;
};

export type MetadataNestedMetadataSeriesNumberListCreateInput = {
  /** Connect existing "MetadataSeriesNumber" nodes to the new "Metadata" node, through the "Metadata.metadataSeriesNumberList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesNumberWhereUniqueInput>>;
  /** Create new "MetadataSeriesNumber" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSeriesNumberList" relation. */
  create?: InputMaybe<Array<MetadataSeriesNumberWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSeriesNumberListUpdateInput = {
  /** Connect existing "MetadataSeriesNumber" nodes to the existing "Metadata" node, through the "Metadata.metadataSeriesNumberList" relation. */
  connect?: InputMaybe<Array<MetadataSeriesNumberWhereUniqueInput>>;
  /** Create new "MetadataSeriesNumber" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesNumberList" relation. */
  create?: InputMaybe<Array<MetadataSeriesNumberWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSeriesNumber" nodes from the "Metadata.metadataSeriesNumberList" relation. */
  delete?: InputMaybe<Array<MetadataSeriesNumberWhereUniqueInput>>;
  /** Update existing "MetadataSeriesNumber" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesNumberList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSeriesNumberListUpdateInput>>;
  /** Upsert existing "MetadataSeriesNumber" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSeriesNumberList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSeriesNumberListUpdateInput>>;
};

export type MetadataNestedMetadataShortUniqueIdListCreateInput = {
  /** Connect existing "MetadataShortUniqueId" nodes to the new "Metadata" node, through the "Metadata.metadataShortUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataShortUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataShortUniqueId" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataShortUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataShortUniqueIdWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataShortUniqueIdListUpdateInput = {
  /** Connect existing "MetadataShortUniqueId" nodes to the existing "Metadata" node, through the "Metadata.metadataShortUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataShortUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataShortUniqueId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataShortUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataShortUniqueIdWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataShortUniqueId" nodes from the "Metadata.metadataShortUniqueIdList" relation. */
  delete?: InputMaybe<Array<MetadataShortUniqueIdWhereUniqueInput>>;
  /** Update existing "MetadataShortUniqueId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataShortUniqueIdList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataShortUniqueIdListUpdateInput>>;
  /** Upsert existing "MetadataShortUniqueId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataShortUniqueIdList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataShortUniqueIdListUpdateInput>>;
};

export type MetadataNestedMetadataSourceListCreateInput = {
  /** Connect existing "MetadataSource" nodes to the new "Metadata" node, through the "Metadata.metadataSourceList" relation. */
  connect?: InputMaybe<Array<MetadataSourceWhereUniqueInput>>;
  /** Create new "MetadataSource" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSourceList" relation. */
  create?: InputMaybe<Array<MetadataSourceWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSourceListUpdateInput = {
  /** Connect existing "MetadataSource" nodes to the existing "Metadata" node, through the "Metadata.metadataSourceList" relation. */
  connect?: InputMaybe<Array<MetadataSourceWhereUniqueInput>>;
  /** Create new "MetadataSource" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSourceList" relation. */
  create?: InputMaybe<Array<MetadataSourceWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSource" nodes from the "Metadata.metadataSourceList" relation. */
  delete?: InputMaybe<Array<MetadataSourceWhereUniqueInput>>;
  /** Update existing "MetadataSource" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSourceList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSourceListUpdateInput>>;
  /** Upsert existing "MetadataSource" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSourceList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSourceListUpdateInput>>;
};

export type MetadataNestedMetadataStateListCreateInput = {
  /** Connect existing "MetadataState" nodes to the new "Metadata" node, through the "Metadata.metadataStateList" relation. */
  connect?: InputMaybe<Array<MetadataStateWhereUniqueInput>>;
  /** Create new "MetadataState" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataStateList" relation. */
  create?: InputMaybe<Array<MetadataStateWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataStateListUpdateInput = {
  /** Connect existing "MetadataState" nodes to the existing "Metadata" node, through the "Metadata.metadataStateList" relation. */
  connect?: InputMaybe<Array<MetadataStateWhereUniqueInput>>;
  /** Create new "MetadataState" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStateList" relation. */
  create?: InputMaybe<Array<MetadataStateWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataState" nodes from the "Metadata.metadataStateList" relation. */
  delete?: InputMaybe<Array<MetadataStateWhereUniqueInput>>;
  /** Update existing "MetadataState" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStateList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataStateListUpdateInput>>;
  /** Upsert existing "MetadataState" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStateList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataStateListUpdateInput>>;
};

export type MetadataNestedMetadataStudyDateTimeListCreateInput = {
  /** Connect existing "MetadataStudyDateTime" nodes to the new "Metadata" node, through the "Metadata.metadataStudyDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataStudyDateTimeWhereUniqueInput>>;
  /** Create new "MetadataStudyDateTime" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataStudyDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataStudyDateTimeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataStudyDateTimeListUpdateInput = {
  /** Connect existing "MetadataStudyDateTime" nodes to the existing "Metadata" node, through the "Metadata.metadataStudyDateTimeList" relation. */
  connect?: InputMaybe<Array<MetadataStudyDateTimeWhereUniqueInput>>;
  /** Create new "MetadataStudyDateTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyDateTimeList" relation. */
  create?: InputMaybe<Array<MetadataStudyDateTimeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataStudyDateTime" nodes from the "Metadata.metadataStudyDateTimeList" relation. */
  delete?: InputMaybe<Array<MetadataStudyDateTimeWhereUniqueInput>>;
  /** Update existing "MetadataStudyDateTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyDateTimeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataStudyDateTimeListUpdateInput>>;
  /** Upsert existing "MetadataStudyDateTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyDateTimeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataStudyDateTimeListUpdateInput>>;
};

export type MetadataNestedMetadataStudyDescriptionListCreateInput = {
  /** Connect existing "MetadataStudyDescription" nodes to the new "Metadata" node, through the "Metadata.metadataStudyDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataStudyDescriptionWhereUniqueInput>>;
  /** Create new "MetadataStudyDescription" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataStudyDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataStudyDescriptionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataStudyDescriptionListUpdateInput = {
  /** Connect existing "MetadataStudyDescription" nodes to the existing "Metadata" node, through the "Metadata.metadataStudyDescriptionList" relation. */
  connect?: InputMaybe<Array<MetadataStudyDescriptionWhereUniqueInput>>;
  /** Create new "MetadataStudyDescription" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyDescriptionList" relation. */
  create?: InputMaybe<Array<MetadataStudyDescriptionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataStudyDescription" nodes from the "Metadata.metadataStudyDescriptionList" relation. */
  delete?: InputMaybe<Array<MetadataStudyDescriptionWhereUniqueInput>>;
  /** Update existing "MetadataStudyDescription" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyDescriptionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataStudyDescriptionListUpdateInput>>;
  /** Upsert existing "MetadataStudyDescription" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyDescriptionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataStudyDescriptionListUpdateInput>>;
};

export type MetadataNestedMetadataStudyIdListCreateInput = {
  /** Connect existing "MetadataStudyId" nodes to the new "Metadata" node, through the "Metadata.metadataStudyIdList" relation. */
  connect?: InputMaybe<Array<MetadataStudyIdWhereUniqueInput>>;
  /** Create new "MetadataStudyId" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataStudyIdList" relation. */
  create?: InputMaybe<Array<MetadataStudyIdWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataStudyIdListUpdateInput = {
  /** Connect existing "MetadataStudyId" nodes to the existing "Metadata" node, through the "Metadata.metadataStudyIdList" relation. */
  connect?: InputMaybe<Array<MetadataStudyIdWhereUniqueInput>>;
  /** Create new "MetadataStudyId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyIdList" relation. */
  create?: InputMaybe<Array<MetadataStudyIdWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataStudyId" nodes from the "Metadata.metadataStudyIdList" relation. */
  delete?: InputMaybe<Array<MetadataStudyIdWhereUniqueInput>>;
  /** Update existing "MetadataStudyId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyIdList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataStudyIdListUpdateInput>>;
  /** Upsert existing "MetadataStudyId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyIdList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataStudyIdListUpdateInput>>;
};

export type MetadataNestedMetadataStudyPhysicianListCreateInput = {
  /** Connect existing "MetadataStudyPhysician" nodes to the new "Metadata" node, through the "Metadata.metadataStudyPhysicianList" relation. */
  connect?: InputMaybe<Array<MetadataStudyPhysicianWhereUniqueInput>>;
  /** Create new "MetadataStudyPhysician" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataStudyPhysicianList" relation. */
  create?: InputMaybe<Array<MetadataStudyPhysicianWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataStudyPhysicianListUpdateInput = {
  /** Connect existing "MetadataStudyPhysician" nodes to the existing "Metadata" node, through the "Metadata.metadataStudyPhysicianList" relation. */
  connect?: InputMaybe<Array<MetadataStudyPhysicianWhereUniqueInput>>;
  /** Create new "MetadataStudyPhysician" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyPhysicianList" relation. */
  create?: InputMaybe<Array<MetadataStudyPhysicianWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataStudyPhysician" nodes from the "Metadata.metadataStudyPhysicianList" relation. */
  delete?: InputMaybe<Array<MetadataStudyPhysicianWhereUniqueInput>>;
  /** Update existing "MetadataStudyPhysician" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyPhysicianList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataStudyPhysicianListUpdateInput>>;
  /** Upsert existing "MetadataStudyPhysician" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataStudyPhysicianList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataStudyPhysicianListUpdateInput>>;
};

export type MetadataNestedMetadataSubjectCodeListCreateInput = {
  /** Connect existing "MetadataSubjectCode" nodes to the new "Metadata" node, through the "Metadata.metadataSubjectCodeList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectCodeWhereUniqueInput>>;
  /** Create new "MetadataSubjectCode" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSubjectCodeList" relation. */
  create?: InputMaybe<Array<MetadataSubjectCodeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSubjectCodeListUpdateInput = {
  /** Connect existing "MetadataSubjectCode" nodes to the existing "Metadata" node, through the "Metadata.metadataSubjectCodeList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectCodeWhereUniqueInput>>;
  /** Create new "MetadataSubjectCode" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSubjectCodeList" relation. */
  create?: InputMaybe<Array<MetadataSubjectCodeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSubjectCode" nodes from the "Metadata.metadataSubjectCodeList" relation. */
  delete?: InputMaybe<Array<MetadataSubjectCodeWhereUniqueInput>>;
  /** Update existing "MetadataSubjectCode" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSubjectCodeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSubjectCodeListUpdateInput>>;
  /** Upsert existing "MetadataSubjectCode" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSubjectCodeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSubjectCodeListUpdateInput>>;
};

export type MetadataNestedMetadataSubjectListCreateInput = {
  /** Connect existing "MetadataSubject" nodes to the new "Metadata" node, through the "Metadata.metadataSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectWhereUniqueInput>>;
  /** Create new "MetadataSubject" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSubjectList" relation. */
  create?: InputMaybe<Array<MetadataSubjectWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSubjectListUpdateInput = {
  /** Connect existing "MetadataSubject" nodes to the existing "Metadata" node, through the "Metadata.metadataSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectWhereUniqueInput>>;
  /** Create new "MetadataSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSubjectList" relation. */
  create?: InputMaybe<Array<MetadataSubjectWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSubject" nodes from the "Metadata.metadataSubjectList" relation. */
  delete?: InputMaybe<Array<MetadataSubjectWhereUniqueInput>>;
  /** Update existing "MetadataSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSubjectList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSubjectListUpdateInput>>;
  /** Upsert existing "MetadataSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSubjectList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSubjectListUpdateInput>>;
};

export type MetadataNestedMetadataSubjectPrismaListCreateInput = {
  /** Connect existing "MetadataSubjectPrisma" nodes to the new "Metadata" node, through the "Metadata.metadataSubjectPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectPrismaWhereUniqueInput>>;
  /** Create new "MetadataSubjectPrisma" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSubjectPrismaList" relation. */
  create?: InputMaybe<Array<MetadataSubjectPrismaWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSubjectPrismaListUpdateInput = {
  /** Connect existing "MetadataSubjectPrisma" nodes to the existing "Metadata" node, through the "Metadata.metadataSubjectPrismaList" relation. */
  connect?: InputMaybe<Array<MetadataSubjectPrismaWhereUniqueInput>>;
  /** Create new "MetadataSubjectPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSubjectPrismaList" relation. */
  create?: InputMaybe<Array<MetadataSubjectPrismaWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSubjectPrisma" nodes from the "Metadata.metadataSubjectPrismaList" relation. */
  delete?: InputMaybe<Array<MetadataSubjectPrismaWhereUniqueInput>>;
  /** Update existing "MetadataSubjectPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSubjectPrismaList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSubjectPrismaListUpdateInput>>;
  /** Upsert existing "MetadataSubjectPrisma" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSubjectPrismaList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSubjectPrismaListUpdateInput>>;
};

export type MetadataNestedMetadataSupplementalCategoriesListCreateInput = {
  /** Connect existing "MetadataSupplementalCategories" nodes to the new "Metadata" node, through the "Metadata.metadataSupplementalCategoriesList" relation. */
  connect?: InputMaybe<Array<MetadataSupplementalCategoriesWhereUniqueInput>>;
  /** Create new "MetadataSupplementalCategories" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataSupplementalCategoriesList" relation. */
  create?: InputMaybe<Array<MetadataSupplementalCategoriesWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataSupplementalCategoriesListUpdateInput = {
  /** Connect existing "MetadataSupplementalCategories" nodes to the existing "Metadata" node, through the "Metadata.metadataSupplementalCategoriesList" relation. */
  connect?: InputMaybe<Array<MetadataSupplementalCategoriesWhereUniqueInput>>;
  /** Create new "MetadataSupplementalCategories" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSupplementalCategoriesList" relation. */
  create?: InputMaybe<Array<MetadataSupplementalCategoriesWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataSupplementalCategories" nodes from the "Metadata.metadataSupplementalCategoriesList" relation. */
  delete?: InputMaybe<Array<MetadataSupplementalCategoriesWhereUniqueInput>>;
  /** Update existing "MetadataSupplementalCategories" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSupplementalCategoriesList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataSupplementalCategoriesListUpdateInput>>;
  /** Upsert existing "MetadataSupplementalCategories" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataSupplementalCategoriesList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataSupplementalCategoriesListUpdateInput>>;
};

export type MetadataNestedMetadataTargetVersionListCreateInput = {
  /** Connect existing "MetadataTargetVersion" nodes to the new "Metadata" node, through the "Metadata.metadataTargetVersionList" relation. */
  connect?: InputMaybe<Array<MetadataTargetVersionWhereUniqueInput>>;
  /** Create new "MetadataTargetVersion" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataTargetVersionList" relation. */
  create?: InputMaybe<Array<MetadataTargetVersionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataTargetVersionListUpdateInput = {
  /** Connect existing "MetadataTargetVersion" nodes to the existing "Metadata" node, through the "Metadata.metadataTargetVersionList" relation. */
  connect?: InputMaybe<Array<MetadataTargetVersionWhereUniqueInput>>;
  /** Create new "MetadataTargetVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTargetVersionList" relation. */
  create?: InputMaybe<Array<MetadataTargetVersionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataTargetVersion" nodes from the "Metadata.metadataTargetVersionList" relation. */
  delete?: InputMaybe<Array<MetadataTargetVersionWhereUniqueInput>>;
  /** Update existing "MetadataTargetVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTargetVersionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataTargetVersionListUpdateInput>>;
  /** Upsert existing "MetadataTargetVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTargetVersionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataTargetVersionListUpdateInput>>;
};

export type MetadataNestedMetadataTitleListCreateInput = {
  /** Connect existing "MetadataTitle" nodes to the new "Metadata" node, through the "Metadata.metadataTitleList" relation. */
  connect?: InputMaybe<Array<MetadataTitleWhereUniqueInput>>;
  /** Create new "MetadataTitle" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataTitleList" relation. */
  create?: InputMaybe<Array<MetadataTitleWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataTitleListUpdateInput = {
  /** Connect existing "MetadataTitle" nodes to the existing "Metadata" node, through the "Metadata.metadataTitleList" relation. */
  connect?: InputMaybe<Array<MetadataTitleWhereUniqueInput>>;
  /** Create new "MetadataTitle" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTitleList" relation. */
  create?: InputMaybe<Array<MetadataTitleWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataTitle" nodes from the "Metadata.metadataTitleList" relation. */
  delete?: InputMaybe<Array<MetadataTitleWhereUniqueInput>>;
  /** Update existing "MetadataTitle" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTitleList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataTitleListUpdateInput>>;
  /** Upsert existing "MetadataTitle" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTitleList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataTitleListUpdateInput>>;
};

export type MetadataNestedMetadataToneCurveListCreateInput = {
  /** Connect existing "MetadataToneCurve" nodes to the new "Metadata" node, through the "Metadata.metadataToneCurveList" relation. */
  connect?: InputMaybe<Array<MetadataToneCurveWhereUniqueInput>>;
  /** Create new "MetadataToneCurve" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataToneCurveList" relation. */
  create?: InputMaybe<Array<MetadataToneCurveWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataToneCurveListUpdateInput = {
  /** Connect existing "MetadataToneCurve" nodes to the existing "Metadata" node, through the "Metadata.metadataToneCurveList" relation. */
  connect?: InputMaybe<Array<MetadataToneCurveWhereUniqueInput>>;
  /** Create new "MetadataToneCurve" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataToneCurveList" relation. */
  create?: InputMaybe<Array<MetadataToneCurveWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataToneCurve" nodes from the "Metadata.metadataToneCurveList" relation. */
  delete?: InputMaybe<Array<MetadataToneCurveWhereUniqueInput>>;
  /** Update existing "MetadataToneCurve" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataToneCurveList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataToneCurveListUpdateInput>>;
  /** Upsert existing "MetadataToneCurve" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataToneCurveList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataToneCurveListUpdateInput>>;
};

export type MetadataNestedMetadataTransferredByEmailListCreateInput = {
  /** Connect existing "MetadataTransferredByEmail" nodes to the new "Metadata" node, through the "Metadata.metadataTransferredByEmailList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByEmailWhereUniqueInput>>;
  /** Create new "MetadataTransferredByEmail" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataTransferredByEmailList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByEmailWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataTransferredByEmailListUpdateInput = {
  /** Connect existing "MetadataTransferredByEmail" nodes to the existing "Metadata" node, through the "Metadata.metadataTransferredByEmailList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByEmailWhereUniqueInput>>;
  /** Create new "MetadataTransferredByEmail" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransferredByEmailList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByEmailWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataTransferredByEmail" nodes from the "Metadata.metadataTransferredByEmailList" relation. */
  delete?: InputMaybe<Array<MetadataTransferredByEmailWhereUniqueInput>>;
  /** Update existing "MetadataTransferredByEmail" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransferredByEmailList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataTransferredByEmailListUpdateInput>>;
  /** Upsert existing "MetadataTransferredByEmail" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransferredByEmailList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataTransferredByEmailListUpdateInput>>;
};

export type MetadataNestedMetadataTransferredByFullNameListCreateInput = {
  /** Connect existing "MetadataTransferredByFullName" nodes to the new "Metadata" node, through the "Metadata.metadataTransferredByFullNameList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByFullNameWhereUniqueInput>>;
  /** Create new "MetadataTransferredByFullName" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataTransferredByFullNameList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByFullNameWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataTransferredByFullNameListUpdateInput = {
  /** Connect existing "MetadataTransferredByFullName" nodes to the existing "Metadata" node, through the "Metadata.metadataTransferredByFullNameList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByFullNameWhereUniqueInput>>;
  /** Create new "MetadataTransferredByFullName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransferredByFullNameList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByFullNameWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataTransferredByFullName" nodes from the "Metadata.metadataTransferredByFullNameList" relation. */
  delete?: InputMaybe<Array<MetadataTransferredByFullNameWhereUniqueInput>>;
  /** Update existing "MetadataTransferredByFullName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransferredByFullNameList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataTransferredByFullNameListUpdateInput>>;
  /** Upsert existing "MetadataTransferredByFullName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransferredByFullNameList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataTransferredByFullNameListUpdateInput>>;
};

export type MetadataNestedMetadataTransferredByListCreateInput = {
  /** Connect existing "MetadataTransferredBy" nodes to the new "Metadata" node, through the "Metadata.metadataTransferredByList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByWhereUniqueInput>>;
  /** Create new "MetadataTransferredBy" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataTransferredByList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataTransferredByListUpdateInput = {
  /** Connect existing "MetadataTransferredBy" nodes to the existing "Metadata" node, through the "Metadata.metadataTransferredByList" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByWhereUniqueInput>>;
  /** Create new "MetadataTransferredBy" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransferredByList" relation. */
  create?: InputMaybe<Array<MetadataTransferredByWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataTransferredBy" nodes from the "Metadata.metadataTransferredByList" relation. */
  delete?: InputMaybe<Array<MetadataTransferredByWhereUniqueInput>>;
  /** Update existing "MetadataTransferredBy" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransferredByList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataTransferredByListUpdateInput>>;
  /** Upsert existing "MetadataTransferredBy" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransferredByList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataTransferredByListUpdateInput>>;
};

export type MetadataNestedMetadataTransmissionReferenceListCreateInput = {
  /** Connect existing "MetadataTransmissionReference" nodes to the new "Metadata" node, through the "Metadata.metadataTransmissionReferenceList" relation. */
  connect?: InputMaybe<Array<MetadataTransmissionReferenceWhereUniqueInput>>;
  /** Create new "MetadataTransmissionReference" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataTransmissionReferenceList" relation. */
  create?: InputMaybe<Array<MetadataTransmissionReferenceWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataTransmissionReferenceListUpdateInput = {
  /** Connect existing "MetadataTransmissionReference" nodes to the existing "Metadata" node, through the "Metadata.metadataTransmissionReferenceList" relation. */
  connect?: InputMaybe<Array<MetadataTransmissionReferenceWhereUniqueInput>>;
  /** Create new "MetadataTransmissionReference" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransmissionReferenceList" relation. */
  create?: InputMaybe<Array<MetadataTransmissionReferenceWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataTransmissionReference" nodes from the "Metadata.metadataTransmissionReferenceList" relation. */
  delete?: InputMaybe<Array<MetadataTransmissionReferenceWhereUniqueInput>>;
  /** Update existing "MetadataTransmissionReference" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransmissionReferenceList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataTransmissionReferenceListUpdateInput>>;
  /** Upsert existing "MetadataTransmissionReference" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTransmissionReferenceList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataTransmissionReferenceListUpdateInput>>;
};

export type MetadataNestedMetadataTypeListCreateInput = {
  /** Connect existing "MetadataType" nodes to the new "Metadata" node, through the "Metadata.metadataTypeList" relation. */
  connect?: InputMaybe<Array<MetadataTypeWhereUniqueInput>>;
  /** Create new "MetadataType" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataTypeList" relation. */
  create?: InputMaybe<Array<MetadataTypeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataTypeListUpdateInput = {
  /** Connect existing "MetadataType" nodes to the existing "Metadata" node, through the "Metadata.metadataTypeList" relation. */
  connect?: InputMaybe<Array<MetadataTypeWhereUniqueInput>>;
  /** Create new "MetadataType" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTypeList" relation. */
  create?: InputMaybe<Array<MetadataTypeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataType" nodes from the "Metadata.metadataTypeList" relation. */
  delete?: InputMaybe<Array<MetadataTypeWhereUniqueInput>>;
  /** Update existing "MetadataType" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTypeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataTypeListUpdateInput>>;
  /** Upsert existing "MetadataType" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataTypeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataTypeListUpdateInput>>;
};

export type MetadataNestedMetadataUndersubjectListCreateInput = {
  /** Connect existing "MetadataUndersubject" nodes to the new "Metadata" node, through the "Metadata.metadataUndersubjectList" relation. */
  connect?: InputMaybe<Array<MetadataUndersubjectWhereUniqueInput>>;
  /** Create new "MetadataUndersubject" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUndersubjectList" relation. */
  create?: InputMaybe<Array<MetadataUndersubjectWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUndersubjectListUpdateInput = {
  /** Connect existing "MetadataUndersubject" nodes to the existing "Metadata" node, through the "Metadata.metadataUndersubjectList" relation. */
  connect?: InputMaybe<Array<MetadataUndersubjectWhereUniqueInput>>;
  /** Create new "MetadataUndersubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUndersubjectList" relation. */
  create?: InputMaybe<Array<MetadataUndersubjectWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUndersubject" nodes from the "Metadata.metadataUndersubjectList" relation. */
  delete?: InputMaybe<Array<MetadataUndersubjectWhereUniqueInput>>;
  /** Update existing "MetadataUndersubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUndersubjectList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUndersubjectListUpdateInput>>;
  /** Upsert existing "MetadataUndersubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUndersubjectList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUndersubjectListUpdateInput>>;
};

export type MetadataNestedMetadataUnderUnderSubjectListCreateInput = {
  /** Connect existing "MetadataUnderUnderSubject" nodes to the new "Metadata" node, through the "Metadata.metadataUnderUnderSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataUnderUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataUnderUnderSubject" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUnderUnderSubjectList" relation. */
  create?: InputMaybe<Array<MetadataUnderUnderSubjectWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUnderUnderSubjectListUpdateInput = {
  /** Connect existing "MetadataUnderUnderSubject" nodes to the existing "Metadata" node, through the "Metadata.metadataUnderUnderSubjectList" relation. */
  connect?: InputMaybe<Array<MetadataUnderUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataUnderUnderSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUnderUnderSubjectList" relation. */
  create?: InputMaybe<Array<MetadataUnderUnderSubjectWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUnderUnderSubject" nodes from the "Metadata.metadataUnderUnderSubjectList" relation. */
  delete?: InputMaybe<Array<MetadataUnderUnderSubjectWhereUniqueInput>>;
  /** Update existing "MetadataUnderUnderSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUnderUnderSubjectList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUnderUnderSubjectListUpdateInput>>;
  /** Upsert existing "MetadataUnderUnderSubject" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUnderUnderSubjectList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUnderUnderSubjectListUpdateInput>>;
};

export type MetadataNestedMetadataUniqueIdListCreateInput = {
  /** Connect existing "MetadataUniqueId" nodes to the new "Metadata" node, through the "Metadata.metadataUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataUniqueId" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataUniqueIdWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUniqueIdListUpdateInput = {
  /** Connect existing "MetadataUniqueId" nodes to the existing "Metadata" node, through the "Metadata.metadataUniqueIdList" relation. */
  connect?: InputMaybe<Array<MetadataUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataUniqueId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUniqueIdList" relation. */
  create?: InputMaybe<Array<MetadataUniqueIdWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUniqueId" nodes from the "Metadata.metadataUniqueIdList" relation. */
  delete?: InputMaybe<Array<MetadataUniqueIdWhereUniqueInput>>;
  /** Update existing "MetadataUniqueId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUniqueIdList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUniqueIdListUpdateInput>>;
  /** Upsert existing "MetadataUniqueId" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUniqueIdList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUniqueIdListUpdateInput>>;
};

export type MetadataNestedMetadataUnitListCreateInput = {
  /** Connect existing "MetadataUnit" nodes to the new "Metadata" node, through the "Metadata.metadataUnitList" relation. */
  connect?: InputMaybe<Array<MetadataUnitWhereUniqueInput>>;
  /** Create new "MetadataUnit" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUnitList" relation. */
  create?: InputMaybe<Array<MetadataUnitWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUnitListUpdateInput = {
  /** Connect existing "MetadataUnit" nodes to the existing "Metadata" node, through the "Metadata.metadataUnitList" relation. */
  connect?: InputMaybe<Array<MetadataUnitWhereUniqueInput>>;
  /** Create new "MetadataUnit" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUnitList" relation. */
  create?: InputMaybe<Array<MetadataUnitWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUnit" nodes from the "Metadata.metadataUnitList" relation. */
  delete?: InputMaybe<Array<MetadataUnitWhereUniqueInput>>;
  /** Update existing "MetadataUnit" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUnitList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUnitListUpdateInput>>;
  /** Upsert existing "MetadataUnit" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUnitList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUnitListUpdateInput>>;
};

export type MetadataNestedMetadataUnitShortNameListCreateInput = {
  /** Connect existing "MetadataUnitShortName" nodes to the new "Metadata" node, through the "Metadata.metadataUnitShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataUnitShortNameWhereUniqueInput>>;
  /** Create new "MetadataUnitShortName" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUnitShortNameList" relation. */
  create?: InputMaybe<Array<MetadataUnitShortNameWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUnitShortNameListUpdateInput = {
  /** Connect existing "MetadataUnitShortName" nodes to the existing "Metadata" node, through the "Metadata.metadataUnitShortNameList" relation. */
  connect?: InputMaybe<Array<MetadataUnitShortNameWhereUniqueInput>>;
  /** Create new "MetadataUnitShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUnitShortNameList" relation. */
  create?: InputMaybe<Array<MetadataUnitShortNameWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUnitShortName" nodes from the "Metadata.metadataUnitShortNameList" relation. */
  delete?: InputMaybe<Array<MetadataUnitShortNameWhereUniqueInput>>;
  /** Update existing "MetadataUnitShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUnitShortNameList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUnitShortNameListUpdateInput>>;
  /** Upsert existing "MetadataUnitShortName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUnitShortNameList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUnitShortNameListUpdateInput>>;
};

export type MetadataNestedMetadataUploadedByFullNameListCreateInput = {
  /** Connect existing "MetadataUploadedByFullName" nodes to the new "Metadata" node, through the "Metadata.metadataUploadedByFullNameList" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByFullNameWhereUniqueInput>>;
  /** Create new "MetadataUploadedByFullName" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUploadedByFullNameList" relation. */
  create?: InputMaybe<Array<MetadataUploadedByFullNameWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUploadedByFullNameListUpdateInput = {
  /** Connect existing "MetadataUploadedByFullName" nodes to the existing "Metadata" node, through the "Metadata.metadataUploadedByFullNameList" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByFullNameWhereUniqueInput>>;
  /** Create new "MetadataUploadedByFullName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUploadedByFullNameList" relation. */
  create?: InputMaybe<Array<MetadataUploadedByFullNameWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUploadedByFullName" nodes from the "Metadata.metadataUploadedByFullNameList" relation. */
  delete?: InputMaybe<Array<MetadataUploadedByFullNameWhereUniqueInput>>;
  /** Update existing "MetadataUploadedByFullName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUploadedByFullNameList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUploadedByFullNameListUpdateInput>>;
  /** Upsert existing "MetadataUploadedByFullName" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUploadedByFullNameList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUploadedByFullNameListUpdateInput>>;
};

export type MetadataNestedMetadataUploadedByListCreateInput = {
  /** Connect existing "MetadataUploadedBy" nodes to the new "Metadata" node, through the "Metadata.metadataUploadedByList" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByWhereUniqueInput>>;
  /** Create new "MetadataUploadedBy" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUploadedByList" relation. */
  create?: InputMaybe<Array<MetadataUploadedByWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUploadedByListUpdateInput = {
  /** Connect existing "MetadataUploadedBy" nodes to the existing "Metadata" node, through the "Metadata.metadataUploadedByList" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByWhereUniqueInput>>;
  /** Create new "MetadataUploadedBy" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUploadedByList" relation. */
  create?: InputMaybe<Array<MetadataUploadedByWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUploadedBy" nodes from the "Metadata.metadataUploadedByList" relation. */
  delete?: InputMaybe<Array<MetadataUploadedByWhereUniqueInput>>;
  /** Update existing "MetadataUploadedBy" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUploadedByList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUploadedByListUpdateInput>>;
  /** Upsert existing "MetadataUploadedBy" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUploadedByList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUploadedByListUpdateInput>>;
};

export type MetadataNestedMetadataUploadTimeListCreateInput = {
  /** Connect existing "MetadataUploadTime" nodes to the new "Metadata" node, through the "Metadata.metadataUploadTimeList" relation. */
  connect?: InputMaybe<Array<MetadataUploadTimeWhereUniqueInput>>;
  /** Create new "MetadataUploadTime" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUploadTimeList" relation. */
  create?: InputMaybe<Array<MetadataUploadTimeWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUploadTimeListUpdateInput = {
  /** Connect existing "MetadataUploadTime" nodes to the existing "Metadata" node, through the "Metadata.metadataUploadTimeList" relation. */
  connect?: InputMaybe<Array<MetadataUploadTimeWhereUniqueInput>>;
  /** Create new "MetadataUploadTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUploadTimeList" relation. */
  create?: InputMaybe<Array<MetadataUploadTimeWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUploadTime" nodes from the "Metadata.metadataUploadTimeList" relation. */
  delete?: InputMaybe<Array<MetadataUploadTimeWhereUniqueInput>>;
  /** Update existing "MetadataUploadTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUploadTimeList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUploadTimeListUpdateInput>>;
  /** Upsert existing "MetadataUploadTime" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUploadTimeList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUploadTimeListUpdateInput>>;
};

export type MetadataNestedMetadataUrgencyListCreateInput = {
  /** Connect existing "MetadataUrgency" nodes to the new "Metadata" node, through the "Metadata.metadataUrgencyList" relation. */
  connect?: InputMaybe<Array<MetadataUrgencyWhereUniqueInput>>;
  /** Create new "MetadataUrgency" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUrgencyList" relation. */
  create?: InputMaybe<Array<MetadataUrgencyWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUrgencyListUpdateInput = {
  /** Connect existing "MetadataUrgency" nodes to the existing "Metadata" node, through the "Metadata.metadataUrgencyList" relation. */
  connect?: InputMaybe<Array<MetadataUrgencyWhereUniqueInput>>;
  /** Create new "MetadataUrgency" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUrgencyList" relation. */
  create?: InputMaybe<Array<MetadataUrgencyWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUrgency" nodes from the "Metadata.metadataUrgencyList" relation. */
  delete?: InputMaybe<Array<MetadataUrgencyWhereUniqueInput>>;
  /** Update existing "MetadataUrgency" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUrgencyList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUrgencyListUpdateInput>>;
  /** Upsert existing "MetadataUrgency" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUrgencyList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUrgencyListUpdateInput>>;
};

export type MetadataNestedMetadataUsageTermsListCreateInput = {
  /** Connect existing "MetadataUsageTerms" nodes to the new "Metadata" node, through the "Metadata.metadataUsageTermsList" relation. */
  connect?: InputMaybe<Array<MetadataUsageTermsWhereUniqueInput>>;
  /** Create new "MetadataUsageTerms" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUsageTermsList" relation. */
  create?: InputMaybe<Array<MetadataUsageTermsWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUsageTermsListUpdateInput = {
  /** Connect existing "MetadataUsageTerms" nodes to the existing "Metadata" node, through the "Metadata.metadataUsageTermsList" relation. */
  connect?: InputMaybe<Array<MetadataUsageTermsWhereUniqueInput>>;
  /** Create new "MetadataUsageTerms" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUsageTermsList" relation. */
  create?: InputMaybe<Array<MetadataUsageTermsWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUsageTerms" nodes from the "Metadata.metadataUsageTermsList" relation. */
  delete?: InputMaybe<Array<MetadataUsageTermsWhereUniqueInput>>;
  /** Update existing "MetadataUsageTerms" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUsageTermsList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUsageTermsListUpdateInput>>;
  /** Upsert existing "MetadataUsageTerms" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUsageTermsList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUsageTermsListUpdateInput>>;
};

export type MetadataNestedMetadataUserCommentListCreateInput = {
  /** Connect existing "MetadataUserComment" nodes to the new "Metadata" node, through the "Metadata.metadataUserCommentList" relation. */
  connect?: InputMaybe<Array<MetadataUserCommentWhereUniqueInput>>;
  /** Create new "MetadataUserComment" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUserCommentList" relation. */
  create?: InputMaybe<Array<MetadataUserCommentWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUserCommentListUpdateInput = {
  /** Connect existing "MetadataUserComment" nodes to the existing "Metadata" node, through the "Metadata.metadataUserCommentList" relation. */
  connect?: InputMaybe<Array<MetadataUserCommentWhereUniqueInput>>;
  /** Create new "MetadataUserComment" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserCommentList" relation. */
  create?: InputMaybe<Array<MetadataUserCommentWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUserComment" nodes from the "Metadata.metadataUserCommentList" relation. */
  delete?: InputMaybe<Array<MetadataUserCommentWhereUniqueInput>>;
  /** Update existing "MetadataUserComment" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserCommentList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUserCommentListUpdateInput>>;
  /** Upsert existing "MetadataUserComment" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserCommentList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUserCommentListUpdateInput>>;
};

export type MetadataNestedMetadataUserDefined195ListCreateInput = {
  /** Connect existing "MetadataUserDefined195" nodes to the new "Metadata" node, through the "Metadata.metadataUserDefined195List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined195WhereUniqueInput>>;
  /** Create new "MetadataUserDefined195" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUserDefined195List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined195WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUserDefined195ListUpdateInput = {
  /** Connect existing "MetadataUserDefined195" nodes to the existing "Metadata" node, through the "Metadata.metadataUserDefined195List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined195WhereUniqueInput>>;
  /** Create new "MetadataUserDefined195" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined195List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined195WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUserDefined195" nodes from the "Metadata.metadataUserDefined195List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined195WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined195" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined195List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUserDefined195ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined195" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined195List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUserDefined195ListUpdateInput>>;
};

export type MetadataNestedMetadataUserDefined237ListCreateInput = {
  /** Connect existing "MetadataUserDefined237" nodes to the new "Metadata" node, through the "Metadata.metadataUserDefined237List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined237WhereUniqueInput>>;
  /** Create new "MetadataUserDefined237" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUserDefined237List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined237WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUserDefined237ListUpdateInput = {
  /** Connect existing "MetadataUserDefined237" nodes to the existing "Metadata" node, through the "Metadata.metadataUserDefined237List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined237WhereUniqueInput>>;
  /** Create new "MetadataUserDefined237" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined237List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined237WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUserDefined237" nodes from the "Metadata.metadataUserDefined237List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined237WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined237" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined237List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUserDefined237ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined237" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined237List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUserDefined237ListUpdateInput>>;
};

export type MetadataNestedMetadataUserDefined238ListCreateInput = {
  /** Connect existing "MetadataUserDefined238" nodes to the new "Metadata" node, through the "Metadata.metadataUserDefined238List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined238WhereUniqueInput>>;
  /** Create new "MetadataUserDefined238" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUserDefined238List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined238WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUserDefined238ListUpdateInput = {
  /** Connect existing "MetadataUserDefined238" nodes to the existing "Metadata" node, through the "Metadata.metadataUserDefined238List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined238WhereUniqueInput>>;
  /** Create new "MetadataUserDefined238" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined238List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined238WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUserDefined238" nodes from the "Metadata.metadataUserDefined238List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined238WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined238" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined238List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUserDefined238ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined238" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined238List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUserDefined238ListUpdateInput>>;
};

export type MetadataNestedMetadataUserDefined239ListCreateInput = {
  /** Connect existing "MetadataUserDefined239" nodes to the new "Metadata" node, through the "Metadata.metadataUserDefined239List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined239WhereUniqueInput>>;
  /** Create new "MetadataUserDefined239" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUserDefined239List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined239WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUserDefined239ListUpdateInput = {
  /** Connect existing "MetadataUserDefined239" nodes to the existing "Metadata" node, through the "Metadata.metadataUserDefined239List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined239WhereUniqueInput>>;
  /** Create new "MetadataUserDefined239" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined239List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined239WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUserDefined239" nodes from the "Metadata.metadataUserDefined239List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined239WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined239" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined239List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUserDefined239ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined239" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined239List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUserDefined239ListUpdateInput>>;
};

export type MetadataNestedMetadataUserDefined242ListCreateInput = {
  /** Connect existing "MetadataUserDefined242" nodes to the new "Metadata" node, through the "Metadata.metadataUserDefined242List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined242WhereUniqueInput>>;
  /** Create new "MetadataUserDefined242" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUserDefined242List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined242WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUserDefined242ListUpdateInput = {
  /** Connect existing "MetadataUserDefined242" nodes to the existing "Metadata" node, through the "Metadata.metadataUserDefined242List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined242WhereUniqueInput>>;
  /** Create new "MetadataUserDefined242" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined242List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined242WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUserDefined242" nodes from the "Metadata.metadataUserDefined242List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined242WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined242" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined242List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUserDefined242ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined242" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined242List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUserDefined242ListUpdateInput>>;
};

export type MetadataNestedMetadataUserDefined62ListCreateInput = {
  /** Connect existing "MetadataUserDefined62" nodes to the new "Metadata" node, through the "Metadata.metadataUserDefined62List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined62WhereUniqueInput>>;
  /** Create new "MetadataUserDefined62" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataUserDefined62List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined62WithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataUserDefined62ListUpdateInput = {
  /** Connect existing "MetadataUserDefined62" nodes to the existing "Metadata" node, through the "Metadata.metadataUserDefined62List" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined62WhereUniqueInput>>;
  /** Create new "MetadataUserDefined62" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined62List" relation. */
  create?: InputMaybe<Array<MetadataUserDefined62WithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataUserDefined62" nodes from the "Metadata.metadataUserDefined62List" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined62WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined62" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined62List" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataUserDefined62ListUpdateInput>>;
  /** Upsert existing "MetadataUserDefined62" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataUserDefined62List" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataUserDefined62ListUpdateInput>>;
};

export type MetadataNestedMetadataValidListCreateInput = {
  /** Connect existing "MetadataValid" nodes to the new "Metadata" node, through the "Metadata.metadataValidList" relation. */
  connect?: InputMaybe<Array<MetadataValidWhereUniqueInput>>;
  /** Create new "MetadataValid" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataValidList" relation. */
  create?: InputMaybe<Array<MetadataValidWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataValidListUpdateInput = {
  /** Connect existing "MetadataValid" nodes to the existing "Metadata" node, through the "Metadata.metadataValidList" relation. */
  connect?: InputMaybe<Array<MetadataValidWhereUniqueInput>>;
  /** Create new "MetadataValid" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataValidList" relation. */
  create?: InputMaybe<Array<MetadataValidWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataValid" nodes from the "Metadata.metadataValidList" relation. */
  delete?: InputMaybe<Array<MetadataValidWhereUniqueInput>>;
  /** Update existing "MetadataValid" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataValidList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataValidListUpdateInput>>;
  /** Upsert existing "MetadataValid" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataValidList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataValidListUpdateInput>>;
};

export type MetadataNestedMetadataVersionListCreateInput = {
  /** Connect existing "MetadataVersion" nodes to the new "Metadata" node, through the "Metadata.metadataVersionList" relation. */
  connect?: InputMaybe<Array<MetadataVersionWhereUniqueInput>>;
  /** Create new "MetadataVersion" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataVersionList" relation. */
  create?: InputMaybe<Array<MetadataVersionWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataVersionListUpdateInput = {
  /** Connect existing "MetadataVersion" nodes to the existing "Metadata" node, through the "Metadata.metadataVersionList" relation. */
  connect?: InputMaybe<Array<MetadataVersionWhereUniqueInput>>;
  /** Create new "MetadataVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataVersionList" relation. */
  create?: InputMaybe<Array<MetadataVersionWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataVersion" nodes from the "Metadata.metadataVersionList" relation. */
  delete?: InputMaybe<Array<MetadataVersionWhereUniqueInput>>;
  /** Update existing "MetadataVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataVersionList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataVersionListUpdateInput>>;
  /** Upsert existing "MetadataVersion" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataVersionList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataVersionListUpdateInput>>;
};

export type MetadataNestedMetadataWebStatementListCreateInput = {
  /** Connect existing "MetadataWebStatement" nodes to the new "Metadata" node, through the "Metadata.metadataWebStatementList" relation. */
  connect?: InputMaybe<Array<MetadataWebStatementWhereUniqueInput>>;
  /** Create new "MetadataWebStatement" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataWebStatementList" relation. */
  create?: InputMaybe<Array<MetadataWebStatementWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataWebStatementListUpdateInput = {
  /** Connect existing "MetadataWebStatement" nodes to the existing "Metadata" node, through the "Metadata.metadataWebStatementList" relation. */
  connect?: InputMaybe<Array<MetadataWebStatementWhereUniqueInput>>;
  /** Create new "MetadataWebStatement" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataWebStatementList" relation. */
  create?: InputMaybe<Array<MetadataWebStatementWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataWebStatement" nodes from the "Metadata.metadataWebStatementList" relation. */
  delete?: InputMaybe<Array<MetadataWebStatementWhereUniqueInput>>;
  /** Update existing "MetadataWebStatement" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataWebStatementList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataWebStatementListUpdateInput>>;
  /** Upsert existing "MetadataWebStatement" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataWebStatementList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataWebStatementListUpdateInput>>;
};

export type MetadataNestedMetadataWorkflowKindListCreateInput = {
  /** Connect existing "MetadataWorkflowKind" nodes to the new "Metadata" node, through the "Metadata.metadataWorkflowKindList" relation. */
  connect?: InputMaybe<Array<MetadataWorkflowKindWhereUniqueInput>>;
  /** Create new "MetadataWorkflowKind" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataWorkflowKindList" relation. */
  create?: InputMaybe<Array<MetadataWorkflowKindWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataWorkflowKindListUpdateInput = {
  /** Connect existing "MetadataWorkflowKind" nodes to the existing "Metadata" node, through the "Metadata.metadataWorkflowKindList" relation. */
  connect?: InputMaybe<Array<MetadataWorkflowKindWhereUniqueInput>>;
  /** Create new "MetadataWorkflowKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataWorkflowKindList" relation. */
  create?: InputMaybe<Array<MetadataWorkflowKindWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataWorkflowKind" nodes from the "Metadata.metadataWorkflowKindList" relation. */
  delete?: InputMaybe<Array<MetadataWorkflowKindWhereUniqueInput>>;
  /** Update existing "MetadataWorkflowKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataWorkflowKindList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataWorkflowKindListUpdateInput>>;
  /** Upsert existing "MetadataWorkflowKind" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataWorkflowKindList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataWorkflowKindListUpdateInput>>;
};

export type MetadataNestedMetadataXmpFileStampsListCreateInput = {
  /** Connect existing "MetadataXmpFileStamps" nodes to the new "Metadata" node, through the "Metadata.metadataXmpFileStampsList" relation. */
  connect?: InputMaybe<Array<MetadataXmpFileStampsWhereUniqueInput>>;
  /** Create new "MetadataXmpFileStamps" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataXmpFileStampsList" relation. */
  create?: InputMaybe<Array<MetadataXmpFileStampsWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataXmpFileStampsListUpdateInput = {
  /** Connect existing "MetadataXmpFileStamps" nodes to the existing "Metadata" node, through the "Metadata.metadataXmpFileStampsList" relation. */
  connect?: InputMaybe<Array<MetadataXmpFileStampsWhereUniqueInput>>;
  /** Create new "MetadataXmpFileStamps" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataXmpFileStampsList" relation. */
  create?: InputMaybe<Array<MetadataXmpFileStampsWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataXmpFileStamps" nodes from the "Metadata.metadataXmpFileStampsList" relation. */
  delete?: InputMaybe<Array<MetadataXmpFileStampsWhereUniqueInput>>;
  /** Update existing "MetadataXmpFileStamps" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataXmpFileStampsList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataXmpFileStampsListUpdateInput>>;
  /** Upsert existing "MetadataXmpFileStamps" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataXmpFileStampsList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataXmpFileStampsListUpdateInput>>;
};

export type MetadataNestedMetadataXmpHistoryListCreateInput = {
  /** Connect existing "MetadataXmpHistory" nodes to the new "Metadata" node, through the "Metadata.metadataXmpHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataXmpHistoryWhereUniqueInput>>;
  /** Create new "MetadataXmpHistory" nodes and connect them to the new "Metadata" node, through the "Metadata.metadataXmpHistoryList" relation. */
  create?: InputMaybe<Array<MetadataXmpHistoryWithForcedMetadataCreateInput>>;
};

export type MetadataNestedMetadataXmpHistoryListUpdateInput = {
  /** Connect existing "MetadataXmpHistory" nodes to the existing "Metadata" node, through the "Metadata.metadataXmpHistoryList" relation. */
  connect?: InputMaybe<Array<MetadataXmpHistoryWhereUniqueInput>>;
  /** Create new "MetadataXmpHistory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataXmpHistoryList" relation. */
  create?: InputMaybe<Array<MetadataXmpHistoryWithForcedMetadataCreateInput>>;
  /** Delete existing "MetadataXmpHistory" nodes from the "Metadata.metadataXmpHistoryList" relation. */
  delete?: InputMaybe<Array<MetadataXmpHistoryWhereUniqueInput>>;
  /** Update existing "MetadataXmpHistory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataXmpHistoryList" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdateMetadataXmpHistoryListUpdateInput>>;
  /** Upsert existing "MetadataXmpHistory" nodes and connect them to the existing "Metadata" node, through the "Metadata.metadataXmpHistoryList" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertMetadataXmpHistoryListUpdateInput>>;
};

export type MetadataNestedPhotoMetadatasCreateInput = {
  /** Connect existing "PhotoMetadata" nodes to the new "Metadata" node, through the "Metadata.photoMetadatas" relation. */
  connect?: InputMaybe<Array<PhotoMetadataWhereUniqueInput>>;
  /** Create new "PhotoMetadata" nodes and connect them to the new "Metadata" node, through the "Metadata.photoMetadatas" relation. */
  create?: InputMaybe<Array<PhotoMetadataWithForcedMetadataCreateInput>>;
};

export type MetadataNestedPhotoMetadatasUpdateInput = {
  /** Connect existing "PhotoMetadata" nodes to the existing "Metadata" node, through the "Metadata.photoMetadatas" relation. */
  connect?: InputMaybe<Array<PhotoMetadataWhereUniqueInput>>;
  /** Create new "PhotoMetadata" nodes and connect them to the existing "Metadata" node, through the "Metadata.photoMetadatas" relation. */
  create?: InputMaybe<Array<PhotoMetadataWithForcedMetadataCreateInput>>;
  /** Delete existing "PhotoMetadata" nodes from the "Metadata.photoMetadatas" relation. */
  delete?: InputMaybe<Array<PhotoMetadataWhereUniqueInput>>;
  /** Update existing "PhotoMetadata" nodes and connect them to the existing "Metadata" node, through the "Metadata.photoMetadatas" relation. */
  update?: InputMaybe<Array<MetadataNestedUpdatePhotoMetadatasUpdateInput>>;
  /** Upsert existing "PhotoMetadata" nodes and connect them to the existing "Metadata" node, through the "Metadata.photoMetadatas" relation. */
  upsert?: InputMaybe<Array<MetadataNestedUpsertPhotoMetadatasUpdateInput>>;
};

export type MetadataNestedUpdateMetadataAccessRightsListUpdateInput = {
  data: MetadataAccessRightsWithForcedMetadataUpdateInput;
  where: MetadataAccessRightsWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataAddlModelInfoListUpdateInput = {
  data: MetadataAddlModelInfoWithForcedMetadataUpdateInput;
  where: MetadataAddlModelInfoWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataArchiveChildListUpdateInput = {
  data: MetadataArchiveChildWithForcedMetadataUpdateInput;
  where: MetadataArchiveChildWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataArchiveParentListUpdateInput = {
  data: MetadataArchiveParentWithForcedMetadataUpdateInput;
  where: MetadataArchiveParentWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataAssetStateListUpdateInput = {
  data: MetadataAssetStateWithForcedMetadataUpdateInput;
  where: MetadataAssetStateWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataAttributionNameListUpdateInput = {
  data: MetadataAttributionNameWithForcedMetadataUpdateInput;
  where: MetadataAttributionNameWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataAttributionUrlListUpdateInput = {
  data: MetadataAttributionUrlWithForcedMetadataUpdateInput;
  where: MetadataAttributionUrlWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataAudienceListUpdateInput = {
  data: MetadataAudienceWithForcedMetadataUpdateInput;
  where: MetadataAudienceWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataAuthorsPositionListUpdateInput = {
  data: MetadataAuthorsPositionWithForcedMetadataUpdateInput;
  where: MetadataAuthorsPositionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataBackupNameListUpdateInput = {
  data: MetadataBackupNameWithForcedMetadataUpdateInput;
  where: MetadataBackupNameWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataBitmapGrayscalePictureListUpdateInput = {
  data: MetadataBitmapGrayscalePictureWithForcedMetadataUpdateInput;
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataBrandListUpdateInput = {
  data: MetadataBrandWithForcedMetadataUpdateInput;
  where: MetadataBrandWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataBrandPrismaListUpdateInput = {
  data: MetadataBrandPrismaWithForcedMetadataUpdateInput;
  where: MetadataBrandPrismaWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataBrandShortNameListUpdateInput = {
  data: MetadataBrandShortNameWithForcedMetadataUpdateInput;
  where: MetadataBrandShortNameWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCaptionWriterListUpdateInput = {
  data: MetadataCaptionWriterWithForcedMetadataUpdateInput;
  where: MetadataCaptionWriterWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCategoryListUpdateInput = {
  data: MetadataCategoryWithForcedMetadataUpdateInput;
  where: MetadataCategoryWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCategoryPrismaListUpdateInput = {
  data: MetadataCategoryPrismaWithForcedMetadataUpdateInput;
  where: MetadataCategoryPrismaWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCertificateListUpdateInput = {
  data: MetadataCertificateWithForcedMetadataUpdateInput;
  where: MetadataCertificateWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCiAdrCityListUpdateInput = {
  data: MetadataCiAdrCityWithForcedMetadataUpdateInput;
  where: MetadataCiAdrCityWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCiAdrCtryListUpdateInput = {
  data: MetadataCiAdrCtryWithForcedMetadataUpdateInput;
  where: MetadataCiAdrCtryWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCiAdrExtadrListUpdateInput = {
  data: MetadataCiAdrExtadrWithForcedMetadataUpdateInput;
  where: MetadataCiAdrExtadrWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCiAdrPcodeListUpdateInput = {
  data: MetadataCiAdrPcodeWithForcedMetadataUpdateInput;
  where: MetadataCiAdrPcodeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCiAdrRegionListUpdateInput = {
  data: MetadataCiAdrRegionWithForcedMetadataUpdateInput;
  where: MetadataCiAdrRegionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCiEmailWorkListUpdateInput = {
  data: MetadataCiEmailWorkWithForcedMetadataUpdateInput;
  where: MetadataCiEmailWorkWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCiTelWorkListUpdateInput = {
  data: MetadataCiTelWorkWithForcedMetadataUpdateInput;
  where: MetadataCiTelWorkWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCityListUpdateInput = {
  data: MetadataCityWithForcedMetadataUpdateInput;
  where: MetadataCityWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCiUrlWorkListUpdateInput = {
  data: MetadataCiUrlWorkWithForcedMetadataUpdateInput;
  where: MetadataCiUrlWorkWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataClassifyListUpdateInput = {
  data: MetadataClassifyWithForcedMetadataUpdateInput;
  where: MetadataClassifyWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCompanyListUpdateInput = {
  data: MetadataCompanyWithForcedMetadataUpdateInput;
  where: MetadataCompanyWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCompanyShortNameListUpdateInput = {
  data: MetadataCompanyShortNameWithForcedMetadataUpdateInput;
  where: MetadataCompanyShortNameWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataConfidentialityListUpdateInput = {
  data: MetadataConfidentialityWithForcedMetadataUpdateInput;
  where: MetadataConfidentialityWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataContainedInListUpdateInput = {
  data: MetadataContainedInWithForcedMetadataUpdateInput;
  where: MetadataContainedInWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataContentMediaKindListUpdateInput = {
  data: MetadataContentMediaKindWithForcedMetadataUpdateInput;
  where: MetadataContentMediaKindWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataContentValueListUpdateInput = {
  data: MetadataContentValueWithForcedMetadataUpdateInput;
  where: MetadataContentValueWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCopyrightLayerListUpdateInput = {
  data: MetadataCopyrightLayerWithForcedMetadataUpdateInput;
  where: MetadataCopyrightLayerWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCountryCodeListUpdateInput = {
  data: MetadataCountryCodeWithForcedMetadataUpdateInput;
  where: MetadataCountryCodeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCountryListUpdateInput = {
  data: MetadataCountryWithForcedMetadataUpdateInput;
  where: MetadataCountryWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCreatedTimeListUpdateInput = {
  data: MetadataCreatedTimeWithForcedMetadataUpdateInput;
  where: MetadataCreatedTimeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCreatorListUpdateInput = {
  data: MetadataCreatorWithForcedMetadataUpdateInput;
  where: MetadataCreatorWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCreatorToolListUpdateInput = {
  data: MetadataCreatorToolWithForcedMetadataUpdateInput;
  where: MetadataCreatorToolWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCreditListUpdateInput = {
  data: MetadataCreditWithForcedMetadataUpdateInput;
  where: MetadataCreditWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCustomField14ListUpdateInput = {
  data: MetadataCustomField14WithForcedMetadataUpdateInput;
  where: MetadataCustomField14WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCustomField15ListUpdateInput = {
  data: MetadataCustomField15WithForcedMetadataUpdateInput;
  where: MetadataCustomField15WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCustomField16ListUpdateInput = {
  data: MetadataCustomField16WithForcedMetadataUpdateInput;
  where: MetadataCustomField16WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCustomField17ListUpdateInput = {
  data: MetadataCustomField17WithForcedMetadataUpdateInput;
  where: MetadataCustomField17WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCustomField20ListUpdateInput = {
  data: MetadataCustomField20WithForcedMetadataUpdateInput;
  where: MetadataCustomField20WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCustomField5ListUpdateInput = {
  data: MetadataCustomField5WithForcedMetadataUpdateInput;
  where: MetadataCustomField5WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCustomField6ListUpdateInput = {
  data: MetadataCustomField6WithForcedMetadataUpdateInput;
  where: MetadataCustomField6WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCustomField7ListUpdateInput = {
  data: MetadataCustomField7WithForcedMetadataUpdateInput;
  where: MetadataCustomField7WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataCustomField9ListUpdateInput = {
  data: MetadataCustomField9WithForcedMetadataUpdateInput;
  where: MetadataCustomField9WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDateCreatedListUpdateInput = {
  data: MetadataDateCreatedWithForcedMetadataUpdateInput;
  where: MetadataDateCreatedWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDateTimeDigitizedListUpdateInput = {
  data: MetadataDateTimeDigitizedWithForcedMetadataUpdateInput;
  where: MetadataDateTimeDigitizedWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDateTimeOriginalListUpdateInput = {
  data: MetadataDateTimeOriginalWithForcedMetadataUpdateInput;
  where: MetadataDateTimeOriginalWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryAccountListUpdateInput = {
  data: MetadataDeliveryAccountWithForcedMetadataUpdateInput;
  where: MetadataDeliveryAccountWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryCompanyListUpdateInput = {
  data: MetadataDeliveryCompanyWithForcedMetadataUpdateInput;
  where: MetadataDeliveryCompanyWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryCopyrightListUpdateInput = {
  data: MetadataDeliveryCopyrightWithForcedMetadataUpdateInput;
  where: MetadataDeliveryCopyrightWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryDateFolderListUpdateInput = {
  data: MetadataDeliveryDateFolderWithForcedMetadataUpdateInput;
  where: MetadataDeliveryDateFolderWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryDateTimeListUpdateInput = {
  data: MetadataDeliveryDateTimeWithForcedMetadataUpdateInput;
  where: MetadataDeliveryDateTimeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryDcCreatorListUpdateInput = {
  data: MetadataDeliveryDcCreatorWithForcedMetadataUpdateInput;
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryDcRightsListUpdateInput = {
  data: MetadataDeliveryDcRightsWithForcedMetadataUpdateInput;
  where: MetadataDeliveryDcRightsWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryFileTypeListUpdateInput = {
  data: MetadataDeliveryFileTypeWithForcedMetadataUpdateInput;
  where: MetadataDeliveryFileTypeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryFolderListUpdateInput = {
  data: MetadataDeliveryFolderWithForcedMetadataUpdateInput;
  where: MetadataDeliveryFolderWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryKindListUpdateInput = {
  data: MetadataDeliveryKindWithForcedMetadataUpdateInput;
  where: MetadataDeliveryKindWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryPathListUpdateInput = {
  data: MetadataDeliveryPathWithForcedMetadataUpdateInput;
  where: MetadataDeliveryPathWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryPersonShownintheImageListUpdateInput = {
  data: MetadataDeliveryPersonShownintheImageWithForcedMetadataUpdateInput;
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryPhotoshopCreditListUpdateInput = {
  data: MetadataDeliveryPhotoshopCreditWithForcedMetadataUpdateInput;
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryPhotoshopSourceListUpdateInput = {
  data: MetadataDeliveryPhotoshopSourceWithForcedMetadataUpdateInput;
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryServiceListUpdateInput = {
  data: MetadataDeliveryServiceWithForcedMetadataUpdateInput;
  where: MetadataDeliveryServiceWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliverySubjectListUpdateInput = {
  data: MetadataDeliverySubjectWithForcedMetadataUpdateInput;
  where: MetadataDeliverySubjectWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDeliveryUnderSubjectListUpdateInput = {
  data: MetadataDeliveryUnderSubjectWithForcedMetadataUpdateInput;
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDepartmentListUpdateInput = {
  data: MetadataDepartmentWithForcedMetadataUpdateInput;
  where: MetadataDepartmentWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDescriptionListUpdateInput = {
  data: MetadataDescriptionWithForcedMetadataUpdateInput;
  where: MetadataDescriptionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDigitalAssetUrlListUpdateInput = {
  data: MetadataDigitalAssetUrlWithForcedMetadataUpdateInput;
  where: MetadataDigitalAssetUrlWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDivisionListUpdateInput = {
  data: MetadataDivisionWithForcedMetadataUpdateInput;
  where: MetadataDivisionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataDocumentTextListUpdateInput = {
  data: MetadataDocumentTextWithForcedMetadataUpdateInput;
  where: MetadataDocumentTextWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataEditionListUpdateInput = {
  data: MetadataEditionWithForcedMetadataUpdateInput;
  where: MetadataEditionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataEditorialVersionListUpdateInput = {
  data: MetadataEditorialVersionWithForcedMetadataUpdateInput;
  where: MetadataEditorialVersionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataEditStatusListUpdateInput = {
  data: MetadataEditStatusWithForcedMetadataUpdateInput;
  where: MetadataEditStatusWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataEnvironnementPhotoListUpdateInput = {
  data: MetadataEnvironnementPhotoWithForcedMetadataUpdateInput;
  where: MetadataEnvironnementPhotoWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataEquipmentInstitutionListUpdateInput = {
  data: MetadataEquipmentInstitutionWithForcedMetadataUpdateInput;
  where: MetadataEquipmentInstitutionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataEquipmentManufacturerListUpdateInput = {
  data: MetadataEquipmentManufacturerWithForcedMetadataUpdateInput;
  where: MetadataEquipmentManufacturerWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataEventListUpdateInput = {
  data: MetadataEventWithForcedMetadataUpdateInput;
  where: MetadataEventWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataExifListUpdateInput = {
  data: MetadataExifWithForcedMetadataUpdateInput;
  where: MetadataExifWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataFabStorageListUpdateInput = {
  data: MetadataFabStorageWithForcedMetadataUpdateInput;
  where: MetadataFabStorageWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataFileCheckForListUpdateInput = {
  data: MetadataFileCheckForWithForcedMetadataUpdateInput;
  where: MetadataFileCheckForWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataFileInfoBitsPerPixelListUpdateInput = {
  data: MetadataFileInfoBitsPerPixelWithForcedMetadataUpdateInput;
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataFileInfoFramesListUpdateInput = {
  data: MetadataFileInfoFramesWithForcedMetadataUpdateInput;
  where: MetadataFileInfoFramesWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataFileInfoPhotometricInterpretationListUpdateInput = {
  data: MetadataFileInfoPhotometricInterpretationWithForcedMetadataUpdateInput;
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataFileInfoTransferSyntaxListUpdateInput = {
  data: MetadataFileInfoTransferSyntaxWithForcedMetadataUpdateInput;
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataFileStageListUpdateInput = {
  data: MetadataFileStageWithForcedMetadataUpdateInput;
  where: MetadataFileStageWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataFileStatusListUpdateInput = {
  data: MetadataFileStatusWithForcedMetadataUpdateInput;
  where: MetadataFileStatusWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataFileTypeListUpdateInput = {
  data: MetadataFileTypeWithForcedMetadataUpdateInput;
  where: MetadataFileTypeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataFixtureIdentifierListUpdateInput = {
  data: MetadataFixtureIdentifierWithForcedMetadataUpdateInput;
  where: MetadataFixtureIdentifierWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataFormatListUpdateInput = {
  data: MetadataFormatWithForcedMetadataUpdateInput;
  where: MetadataFormatWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataGpsLatitudeListUpdateInput = {
  data: MetadataGpsLatitudeWithForcedMetadataUpdateInput;
  where: MetadataGpsLatitudeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataGpsLongitudeListUpdateInput = {
  data: MetadataGpsLongitudeWithForcedMetadataUpdateInput;
  where: MetadataGpsLongitudeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataGpsProcessingMethodListUpdateInput = {
  data: MetadataGpsProcessingMethodWithForcedMetadataUpdateInput;
  where: MetadataGpsProcessingMethodWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataHeaderListUpdateInput = {
  data: MetadataHeaderWithForcedMetadataUpdateInput;
  where: MetadataHeaderWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataHeaderPrismaListUpdateInput = {
  data: MetadataHeaderPrismaWithForcedMetadataUpdateInput;
  where: MetadataHeaderPrismaWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataHeadlineListUpdateInput = {
  data: MetadataHeadlineWithForcedMetadataUpdateInput;
  where: MetadataHeadlineWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataHistoryListUpdateInput = {
  data: MetadataHistoryWithForcedMetadataUpdateInput;
  where: MetadataHistoryWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataIccProfileListUpdateInput = {
  data: MetadataIccProfileWithForcedMetadataUpdateInput;
  where: MetadataIccProfileWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataIdAssignmentListUpdateInput = {
  data: MetadataIdAssignmentWithForcedMetadataUpdateInput;
  where: MetadataIdAssignmentWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataIdMediaContentListUpdateInput = {
  data: MetadataIdMediaContentWithForcedMetadataUpdateInput;
  where: MetadataIdMediaContentWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataIdPhotoPrismaListUpdateInput = {
  data: MetadataIdPhotoPrismaWithForcedMetadataUpdateInput;
  where: MetadataIdPhotoPrismaWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataIdSubjectListUpdateInput = {
  data: MetadataIdSubjectWithForcedMetadataUpdateInput;
  where: MetadataIdSubjectWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataImageNotesListUpdateInput = {
  data: MetadataImageNotesWithForcedMetadataUpdateInput;
  where: MetadataImageNotesWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataImageUniqueIdListUpdateInput = {
  data: MetadataImageUniqueIdWithForcedMetadataUpdateInput;
  where: MetadataImageUniqueIdWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataInstructionsListUpdateInput = {
  data: MetadataInstructionsWithForcedMetadataUpdateInput;
  where: MetadataInstructionsWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataIntellectualGenreListUpdateInput = {
  data: MetadataIntellectualGenreWithForcedMetadataUpdateInput;
  where: MetadataIntellectualGenreWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataIsoSpeedRatingsListUpdateInput = {
  data: MetadataIsoSpeedRatingsWithForcedMetadataUpdateInput;
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataIssueListUpdateInput = {
  data: MetadataIssueWithForcedMetadataUpdateInput;
  where: MetadataIssueWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataJobIdListUpdateInput = {
  data: MetadataJobIdWithForcedMetadataUpdateInput;
  where: MetadataJobIdWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataJobProcessingListUpdateInput = {
  data: MetadataJobProcessingWithForcedMetadataUpdateInput;
  where: MetadataJobProcessingWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataLayoutPictureEditingListUpdateInput = {
  data: MetadataLayoutPictureEditingWithForcedMetadataUpdateInput;
  where: MetadataLayoutPictureEditingWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataLayoutStorageListUpdateInput = {
  data: MetadataLayoutStorageWithForcedMetadataUpdateInput;
  where: MetadataLayoutStorageWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataLicenseListUpdateInput = {
  data: MetadataLicenseWithForcedMetadataUpdateInput;
  where: MetadataLicenseWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataLinksListUpdateInput = {
  data: MetadataLinksWithForcedMetadataUpdateInput;
  where: MetadataLinksWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataLocalCaptionListUpdateInput = {
  data: MetadataLocalCaptionWithForcedMetadataUpdateInput;
  where: MetadataLocalCaptionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataLocationListUpdateInput = {
  data: MetadataLocationWithForcedMetadataUpdateInput;
  where: MetadataLocationWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataMakeListUpdateInput = {
  data: MetadataMakeWithForcedMetadataUpdateInput;
  where: MetadataMakeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataManifestListUpdateInput = {
  data: MetadataManifestWithForcedMetadataUpdateInput;
  where: MetadataManifestWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataMarkedListUpdateInput = {
  data: MetadataMarkedWithForcedMetadataUpdateInput;
  where: MetadataMarkedWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataMasterDocumentIdListUpdateInput = {
  data: MetadataMasterDocumentIdWithForcedMetadataUpdateInput;
  where: MetadataMasterDocumentIdWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataMaxAvailHeightListUpdateInput = {
  data: MetadataMaxAvailHeightWithForcedMetadataUpdateInput;
  where: MetadataMaxAvailHeightWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataMaxAvailWidthListUpdateInput = {
  data: MetadataMaxAvailWidthWithForcedMetadataUpdateInput;
  where: MetadataMaxAvailWidthWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataMinorModelAgeDisclosureListUpdateInput = {
  data: MetadataMinorModelAgeDisclosureWithForcedMetadataUpdateInput;
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataModelAgeListUpdateInput = {
  data: MetadataModelAgeWithForcedMetadataUpdateInput;
  where: MetadataModelAgeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataModelListUpdateInput = {
  data: MetadataModelWithForcedMetadataUpdateInput;
  where: MetadataModelWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataModelReleaseIdListUpdateInput = {
  data: MetadataModelReleaseIdWithForcedMetadataUpdateInput;
  where: MetadataModelReleaseIdWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataModelReleaseStatusListUpdateInput = {
  data: MetadataModelReleaseStatusWithForcedMetadataUpdateInput;
  where: MetadataModelReleaseStatusWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataMorePermissionsListUpdateInput = {
  data: MetadataMorePermissionsWithForcedMetadataUpdateInput;
  where: MetadataMorePermissionsWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataObjectCycleListUpdateInput = {
  data: MetadataObjectCycleWithForcedMetadataUpdateInput;
  where: MetadataObjectCycleWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataOrganisationInImageNameListUpdateInput = {
  data: MetadataOrganisationInImageNameWithForcedMetadataUpdateInput;
  where: MetadataOrganisationInImageNameWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataOriginalColorListUpdateInput = {
  data: MetadataOriginalColorWithForcedMetadataUpdateInput;
  where: MetadataOriginalColorWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataOriginalIccProfileListUpdateInput = {
  data: MetadataOriginalIccProfileWithForcedMetadataUpdateInput;
  where: MetadataOriginalIccProfileWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataOriginalNameListUpdateInput = {
  data: MetadataOriginalNameWithForcedMetadataUpdateInput;
  where: MetadataOriginalNameWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataOriginalWeigthListUpdateInput = {
  data: MetadataOriginalWeigthWithForcedMetadataUpdateInput;
  where: MetadataOriginalWeigthWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataOwnerIdListUpdateInput = {
  data: MetadataOwnerIdWithForcedMetadataUpdateInput;
  where: MetadataOwnerIdWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataOwnerListUpdateInput = {
  data: MetadataOwnerWithForcedMetadataUpdateInput;
  where: MetadataOwnerWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPageListUpdateInput = {
  data: MetadataPageWithForcedMetadataUpdateInput;
  where: MetadataPageWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPagePrismaListUpdateInput = {
  data: MetadataPagePrismaWithForcedMetadataUpdateInput;
  where: MetadataPagePrismaWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPaintBasedCorrectionsListUpdateInput = {
  data: MetadataPaintBasedCorrectionsWithForcedMetadataUpdateInput;
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPatientDobListUpdateInput = {
  data: MetadataPatientDobWithForcedMetadataUpdateInput;
  where: MetadataPatientDobWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPatientIdListUpdateInput = {
  data: MetadataPatientIdWithForcedMetadataUpdateInput;
  where: MetadataPatientIdWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPatientNameListUpdateInput = {
  data: MetadataPatientNameWithForcedMetadataUpdateInput;
  where: MetadataPatientNameWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPatientSexListUpdateInput = {
  data: MetadataPatientSexWithForcedMetadataUpdateInput;
  where: MetadataPatientSexWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPersonInImageListUpdateInput = {
  data: MetadataPersonInImageWithForcedMetadataUpdateInput;
  where: MetadataPersonInImageWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPhotoStorageListUpdateInput = {
  data: MetadataPhotoStorageWithForcedMetadataUpdateInput;
  where: MetadataPhotoStorageWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPrepressPictureEditingListUpdateInput = {
  data: MetadataPrepressPictureEditingWithForcedMetadataUpdateInput;
  where: MetadataPrepressPictureEditingWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPriceLevelListUpdateInput = {
  data: MetadataPriceLevelWithForcedMetadataUpdateInput;
  where: MetadataPriceLevelWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPrintingProfileListUpdateInput = {
  data: MetadataPrintingProfileWithForcedMetadataUpdateInput;
  where: MetadataPrintingProfileWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPrismaProductionListUpdateInput = {
  data: MetadataPrismaProductionWithForcedMetadataUpdateInput;
  where: MetadataPrismaProductionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataProcessHistoryListUpdateInput = {
  data: MetadataProcessHistoryWithForcedMetadataUpdateInput;
  where: MetadataProcessHistoryWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataProcessParameterListUpdateInput = {
  data: MetadataProcessParameterWithForcedMetadataUpdateInput;
  where: MetadataProcessParameterWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataProcessStatusListUpdateInput = {
  data: MetadataProcessStatusWithForcedMetadataUpdateInput;
  where: MetadataProcessStatusWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataProductListUpdateInput = {
  data: MetadataProductWithForcedMetadataUpdateInput;
  where: MetadataProductWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataProductShortNameListUpdateInput = {
  data: MetadataProductShortNameWithForcedMetadataUpdateInput;
  where: MetadataProductShortNameWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataProductsListUpdateInput = {
  data: MetadataProductsWithForcedMetadataUpdateInput;
  where: MetadataProductsWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataProduitsListUpdateInput = {
  data: MetadataProduitsWithForcedMetadataUpdateInput;
  where: MetadataProduitsWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataProgramVersionListUpdateInput = {
  data: MetadataProgramVersionWithForcedMetadataUpdateInput;
  where: MetadataProgramVersionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPropertyReleaseIdListUpdateInput = {
  data: MetadataPropertyReleaseIdWithForcedMetadataUpdateInput;
  where: MetadataPropertyReleaseIdWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPropertyReleaseStatusListUpdateInput = {
  data: MetadataPropertyReleaseStatusWithForcedMetadataUpdateInput;
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPublisherListUpdateInput = {
  data: MetadataPublisherWithForcedMetadataUpdateInput;
  where: MetadataPublisherWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPublishingIssueListUpdateInput = {
  data: MetadataPublishingIssueWithForcedMetadataUpdateInput;
  where: MetadataPublishingIssueWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataPublishingSubjectListUpdateInput = {
  data: MetadataPublishingSubjectWithForcedMetadataUpdateInput;
  where: MetadataPublishingSubjectWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataQualifiedUsePrismaByListUpdateInput = {
  data: MetadataQualifiedUsePrismaByWithForcedMetadataUpdateInput;
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataQualifiedUsePrismaDateListUpdateInput = {
  data: MetadataQualifiedUsePrismaDateWithForcedMetadataUpdateInput;
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataQualifiedUsePrismaDurationListUpdateInput = {
  data: MetadataQualifiedUsePrismaDurationWithForcedMetadataUpdateInput;
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataQualifiedUsePrismaSupportListUpdateInput = {
  data: MetadataQualifiedUsePrismaSupportWithForcedMetadataUpdateInput;
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataRatingListUpdateInput = {
  data: MetadataRatingWithForcedMetadataUpdateInput;
  where: MetadataRatingWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataReferenceDateListUpdateInput = {
  data: MetadataReferenceDateWithForcedMetadataUpdateInput;
  where: MetadataReferenceDateWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataReferenceNumberListUpdateInput = {
  data: MetadataReferenceNumberWithForcedMetadataUpdateInput;
  where: MetadataReferenceNumberWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataReferenceServiceListUpdateInput = {
  data: MetadataReferenceServiceWithForcedMetadataUpdateInput;
  where: MetadataReferenceServiceWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataReleaseDateListUpdateInput = {
  data: MetadataReleaseDateWithForcedMetadataUpdateInput;
  where: MetadataReleaseDateWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataReleaseTimeListUpdateInput = {
  data: MetadataReleaseTimeWithForcedMetadataUpdateInput;
  where: MetadataReleaseTimeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataRequiredPermissionListUpdateInput = {
  data: MetadataRequiredPermissionWithForcedMetadataUpdateInput;
  where: MetadataRequiredPermissionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataResolutionKindListUpdateInput = {
  data: MetadataResolutionKindWithForcedMetadataUpdateInput;
  where: MetadataResolutionKindWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataRightsListUpdateInput = {
  data: MetadataRightsWithForcedMetadataUpdateInput;
  where: MetadataRightsWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataRoyaltyfreeListUpdateInput = {
  data: MetadataRoyaltyfreeWithForcedMetadataUpdateInput;
  where: MetadataRoyaltyfreeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSceneListUpdateInput = {
  data: MetadataSceneWithForcedMetadataUpdateInput;
  where: MetadataSceneWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSectionListUpdateInput = {
  data: MetadataSectionWithForcedMetadataUpdateInput;
  where: MetadataSectionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSentToListUpdateInput = {
  data: MetadataSentToWithForcedMetadataUpdateInput;
  where: MetadataSentToWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSerialNumberListUpdateInput = {
  data: MetadataSerialNumberWithForcedMetadataUpdateInput;
  where: MetadataSerialNumberWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSeriesDateTimeListUpdateInput = {
  data: MetadataSeriesDateTimeWithForcedMetadataUpdateInput;
  where: MetadataSeriesDateTimeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSeriesDescriptionListUpdateInput = {
  data: MetadataSeriesDescriptionWithForcedMetadataUpdateInput;
  where: MetadataSeriesDescriptionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSeriesModalityListUpdateInput = {
  data: MetadataSeriesModalityWithForcedMetadataUpdateInput;
  where: MetadataSeriesModalityWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSeriesNumberListUpdateInput = {
  data: MetadataSeriesNumberWithForcedMetadataUpdateInput;
  where: MetadataSeriesNumberWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataShortUniqueIdListUpdateInput = {
  data: MetadataShortUniqueIdWithForcedMetadataUpdateInput;
  where: MetadataShortUniqueIdWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSourceListUpdateInput = {
  data: MetadataSourceWithForcedMetadataUpdateInput;
  where: MetadataSourceWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataStateListUpdateInput = {
  data: MetadataStateWithForcedMetadataUpdateInput;
  where: MetadataStateWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataStudyDateTimeListUpdateInput = {
  data: MetadataStudyDateTimeWithForcedMetadataUpdateInput;
  where: MetadataStudyDateTimeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataStudyDescriptionListUpdateInput = {
  data: MetadataStudyDescriptionWithForcedMetadataUpdateInput;
  where: MetadataStudyDescriptionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataStudyIdListUpdateInput = {
  data: MetadataStudyIdWithForcedMetadataUpdateInput;
  where: MetadataStudyIdWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataStudyPhysicianListUpdateInput = {
  data: MetadataStudyPhysicianWithForcedMetadataUpdateInput;
  where: MetadataStudyPhysicianWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSubjectCodeListUpdateInput = {
  data: MetadataSubjectCodeWithForcedMetadataUpdateInput;
  where: MetadataSubjectCodeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSubjectListUpdateInput = {
  data: MetadataSubjectWithForcedMetadataUpdateInput;
  where: MetadataSubjectWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSubjectPrismaListUpdateInput = {
  data: MetadataSubjectPrismaWithForcedMetadataUpdateInput;
  where: MetadataSubjectPrismaWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataSupplementalCategoriesListUpdateInput = {
  data: MetadataSupplementalCategoriesWithForcedMetadataUpdateInput;
  where: MetadataSupplementalCategoriesWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataTargetVersionListUpdateInput = {
  data: MetadataTargetVersionWithForcedMetadataUpdateInput;
  where: MetadataTargetVersionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataTitleListUpdateInput = {
  data: MetadataTitleWithForcedMetadataUpdateInput;
  where: MetadataTitleWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataToneCurveListUpdateInput = {
  data: MetadataToneCurveWithForcedMetadataUpdateInput;
  where: MetadataToneCurveWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataTransferredByEmailListUpdateInput = {
  data: MetadataTransferredByEmailWithForcedMetadataUpdateInput;
  where: MetadataTransferredByEmailWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataTransferredByFullNameListUpdateInput = {
  data: MetadataTransferredByFullNameWithForcedMetadataUpdateInput;
  where: MetadataTransferredByFullNameWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataTransferredByListUpdateInput = {
  data: MetadataTransferredByWithForcedMetadataUpdateInput;
  where: MetadataTransferredByWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataTransmissionReferenceListUpdateInput = {
  data: MetadataTransmissionReferenceWithForcedMetadataUpdateInput;
  where: MetadataTransmissionReferenceWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataTypeListUpdateInput = {
  data: MetadataTypeWithForcedMetadataUpdateInput;
  where: MetadataTypeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUndersubjectListUpdateInput = {
  data: MetadataUndersubjectWithForcedMetadataUpdateInput;
  where: MetadataUndersubjectWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUnderUnderSubjectListUpdateInput = {
  data: MetadataUnderUnderSubjectWithForcedMetadataUpdateInput;
  where: MetadataUnderUnderSubjectWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUniqueIdListUpdateInput = {
  data: MetadataUniqueIdWithForcedMetadataUpdateInput;
  where: MetadataUniqueIdWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUnitListUpdateInput = {
  data: MetadataUnitWithForcedMetadataUpdateInput;
  where: MetadataUnitWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUnitShortNameListUpdateInput = {
  data: MetadataUnitShortNameWithForcedMetadataUpdateInput;
  where: MetadataUnitShortNameWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUploadedByFullNameListUpdateInput = {
  data: MetadataUploadedByFullNameWithForcedMetadataUpdateInput;
  where: MetadataUploadedByFullNameWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUploadedByListUpdateInput = {
  data: MetadataUploadedByWithForcedMetadataUpdateInput;
  where: MetadataUploadedByWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUploadTimeListUpdateInput = {
  data: MetadataUploadTimeWithForcedMetadataUpdateInput;
  where: MetadataUploadTimeWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUrgencyListUpdateInput = {
  data: MetadataUrgencyWithForcedMetadataUpdateInput;
  where: MetadataUrgencyWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUsageTermsListUpdateInput = {
  data: MetadataUsageTermsWithForcedMetadataUpdateInput;
  where: MetadataUsageTermsWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUserCommentListUpdateInput = {
  data: MetadataUserCommentWithForcedMetadataUpdateInput;
  where: MetadataUserCommentWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUserDefined195ListUpdateInput = {
  data: MetadataUserDefined195WithForcedMetadataUpdateInput;
  where: MetadataUserDefined195WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUserDefined237ListUpdateInput = {
  data: MetadataUserDefined237WithForcedMetadataUpdateInput;
  where: MetadataUserDefined237WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUserDefined238ListUpdateInput = {
  data: MetadataUserDefined238WithForcedMetadataUpdateInput;
  where: MetadataUserDefined238WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUserDefined239ListUpdateInput = {
  data: MetadataUserDefined239WithForcedMetadataUpdateInput;
  where: MetadataUserDefined239WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUserDefined242ListUpdateInput = {
  data: MetadataUserDefined242WithForcedMetadataUpdateInput;
  where: MetadataUserDefined242WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataUserDefined62ListUpdateInput = {
  data: MetadataUserDefined62WithForcedMetadataUpdateInput;
  where: MetadataUserDefined62WhereUniqueInput;
};

export type MetadataNestedUpdateMetadataValidListUpdateInput = {
  data: MetadataValidWithForcedMetadataUpdateInput;
  where: MetadataValidWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataVersionListUpdateInput = {
  data: MetadataVersionWithForcedMetadataUpdateInput;
  where: MetadataVersionWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataWebStatementListUpdateInput = {
  data: MetadataWebStatementWithForcedMetadataUpdateInput;
  where: MetadataWebStatementWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataWorkflowKindListUpdateInput = {
  data: MetadataWorkflowKindWithForcedMetadataUpdateInput;
  where: MetadataWorkflowKindWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataXmpFileStampsListUpdateInput = {
  data: MetadataXmpFileStampsWithForcedMetadataUpdateInput;
  where: MetadataXmpFileStampsWhereUniqueInput;
};

export type MetadataNestedUpdateMetadataXmpHistoryListUpdateInput = {
  data: MetadataXmpHistoryWithForcedMetadataUpdateInput;
  where: MetadataXmpHistoryWhereUniqueInput;
};

export type MetadataNestedUpdatePhotoMetadatasUpdateInput = {
  data: PhotoMetadataWithForcedMetadataUpdateInput;
  where: PhotoMetadataWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataAccessRightsListUpdateInput = {
  create: MetadataAccessRightsWithForcedMetadataCreateInput;
  update: MetadataAccessRightsWithForcedMetadataUpdateInput;
  where: MetadataAccessRightsWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataAddlModelInfoListUpdateInput = {
  create: MetadataAddlModelInfoWithForcedMetadataCreateInput;
  update: MetadataAddlModelInfoWithForcedMetadataUpdateInput;
  where: MetadataAddlModelInfoWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataArchiveChildListUpdateInput = {
  create: MetadataArchiveChildWithForcedMetadataCreateInput;
  update: MetadataArchiveChildWithForcedMetadataUpdateInput;
  where: MetadataArchiveChildWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataArchiveParentListUpdateInput = {
  create: MetadataArchiveParentWithForcedMetadataCreateInput;
  update: MetadataArchiveParentWithForcedMetadataUpdateInput;
  where: MetadataArchiveParentWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataAssetStateListUpdateInput = {
  create: MetadataAssetStateWithForcedMetadataCreateInput;
  update: MetadataAssetStateWithForcedMetadataUpdateInput;
  where: MetadataAssetStateWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataAttributionNameListUpdateInput = {
  create: MetadataAttributionNameWithForcedMetadataCreateInput;
  update: MetadataAttributionNameWithForcedMetadataUpdateInput;
  where: MetadataAttributionNameWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataAttributionUrlListUpdateInput = {
  create: MetadataAttributionUrlWithForcedMetadataCreateInput;
  update: MetadataAttributionUrlWithForcedMetadataUpdateInput;
  where: MetadataAttributionUrlWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataAudienceListUpdateInput = {
  create: MetadataAudienceWithForcedMetadataCreateInput;
  update: MetadataAudienceWithForcedMetadataUpdateInput;
  where: MetadataAudienceWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataAuthorsPositionListUpdateInput = {
  create: MetadataAuthorsPositionWithForcedMetadataCreateInput;
  update: MetadataAuthorsPositionWithForcedMetadataUpdateInput;
  where: MetadataAuthorsPositionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataBackupNameListUpdateInput = {
  create: MetadataBackupNameWithForcedMetadataCreateInput;
  update: MetadataBackupNameWithForcedMetadataUpdateInput;
  where: MetadataBackupNameWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataBitmapGrayscalePictureListUpdateInput = {
  create: MetadataBitmapGrayscalePictureWithForcedMetadataCreateInput;
  update: MetadataBitmapGrayscalePictureWithForcedMetadataUpdateInput;
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataBrandListUpdateInput = {
  create: MetadataBrandWithForcedMetadataCreateInput;
  update: MetadataBrandWithForcedMetadataUpdateInput;
  where: MetadataBrandWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataBrandPrismaListUpdateInput = {
  create: MetadataBrandPrismaWithForcedMetadataCreateInput;
  update: MetadataBrandPrismaWithForcedMetadataUpdateInput;
  where: MetadataBrandPrismaWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataBrandShortNameListUpdateInput = {
  create: MetadataBrandShortNameWithForcedMetadataCreateInput;
  update: MetadataBrandShortNameWithForcedMetadataUpdateInput;
  where: MetadataBrandShortNameWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCaptionWriterListUpdateInput = {
  create: MetadataCaptionWriterWithForcedMetadataCreateInput;
  update: MetadataCaptionWriterWithForcedMetadataUpdateInput;
  where: MetadataCaptionWriterWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCategoryListUpdateInput = {
  create: MetadataCategoryWithForcedMetadataCreateInput;
  update: MetadataCategoryWithForcedMetadataUpdateInput;
  where: MetadataCategoryWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCategoryPrismaListUpdateInput = {
  create: MetadataCategoryPrismaWithForcedMetadataCreateInput;
  update: MetadataCategoryPrismaWithForcedMetadataUpdateInput;
  where: MetadataCategoryPrismaWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCertificateListUpdateInput = {
  create: MetadataCertificateWithForcedMetadataCreateInput;
  update: MetadataCertificateWithForcedMetadataUpdateInput;
  where: MetadataCertificateWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCiAdrCityListUpdateInput = {
  create: MetadataCiAdrCityWithForcedMetadataCreateInput;
  update: MetadataCiAdrCityWithForcedMetadataUpdateInput;
  where: MetadataCiAdrCityWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCiAdrCtryListUpdateInput = {
  create: MetadataCiAdrCtryWithForcedMetadataCreateInput;
  update: MetadataCiAdrCtryWithForcedMetadataUpdateInput;
  where: MetadataCiAdrCtryWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCiAdrExtadrListUpdateInput = {
  create: MetadataCiAdrExtadrWithForcedMetadataCreateInput;
  update: MetadataCiAdrExtadrWithForcedMetadataUpdateInput;
  where: MetadataCiAdrExtadrWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCiAdrPcodeListUpdateInput = {
  create: MetadataCiAdrPcodeWithForcedMetadataCreateInput;
  update: MetadataCiAdrPcodeWithForcedMetadataUpdateInput;
  where: MetadataCiAdrPcodeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCiAdrRegionListUpdateInput = {
  create: MetadataCiAdrRegionWithForcedMetadataCreateInput;
  update: MetadataCiAdrRegionWithForcedMetadataUpdateInput;
  where: MetadataCiAdrRegionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCiEmailWorkListUpdateInput = {
  create: MetadataCiEmailWorkWithForcedMetadataCreateInput;
  update: MetadataCiEmailWorkWithForcedMetadataUpdateInput;
  where: MetadataCiEmailWorkWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCiTelWorkListUpdateInput = {
  create: MetadataCiTelWorkWithForcedMetadataCreateInput;
  update: MetadataCiTelWorkWithForcedMetadataUpdateInput;
  where: MetadataCiTelWorkWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCityListUpdateInput = {
  create: MetadataCityWithForcedMetadataCreateInput;
  update: MetadataCityWithForcedMetadataUpdateInput;
  where: MetadataCityWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCiUrlWorkListUpdateInput = {
  create: MetadataCiUrlWorkWithForcedMetadataCreateInput;
  update: MetadataCiUrlWorkWithForcedMetadataUpdateInput;
  where: MetadataCiUrlWorkWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataClassifyListUpdateInput = {
  create: MetadataClassifyWithForcedMetadataCreateInput;
  update: MetadataClassifyWithForcedMetadataUpdateInput;
  where: MetadataClassifyWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCompanyListUpdateInput = {
  create: MetadataCompanyWithForcedMetadataCreateInput;
  update: MetadataCompanyWithForcedMetadataUpdateInput;
  where: MetadataCompanyWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCompanyShortNameListUpdateInput = {
  create: MetadataCompanyShortNameWithForcedMetadataCreateInput;
  update: MetadataCompanyShortNameWithForcedMetadataUpdateInput;
  where: MetadataCompanyShortNameWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataConfidentialityListUpdateInput = {
  create: MetadataConfidentialityWithForcedMetadataCreateInput;
  update: MetadataConfidentialityWithForcedMetadataUpdateInput;
  where: MetadataConfidentialityWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataContainedInListUpdateInput = {
  create: MetadataContainedInWithForcedMetadataCreateInput;
  update: MetadataContainedInWithForcedMetadataUpdateInput;
  where: MetadataContainedInWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataContentMediaKindListUpdateInput = {
  create: MetadataContentMediaKindWithForcedMetadataCreateInput;
  update: MetadataContentMediaKindWithForcedMetadataUpdateInput;
  where: MetadataContentMediaKindWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataContentValueListUpdateInput = {
  create: MetadataContentValueWithForcedMetadataCreateInput;
  update: MetadataContentValueWithForcedMetadataUpdateInput;
  where: MetadataContentValueWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCopyrightLayerListUpdateInput = {
  create: MetadataCopyrightLayerWithForcedMetadataCreateInput;
  update: MetadataCopyrightLayerWithForcedMetadataUpdateInput;
  where: MetadataCopyrightLayerWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCountryCodeListUpdateInput = {
  create: MetadataCountryCodeWithForcedMetadataCreateInput;
  update: MetadataCountryCodeWithForcedMetadataUpdateInput;
  where: MetadataCountryCodeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCountryListUpdateInput = {
  create: MetadataCountryWithForcedMetadataCreateInput;
  update: MetadataCountryWithForcedMetadataUpdateInput;
  where: MetadataCountryWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCreatedTimeListUpdateInput = {
  create: MetadataCreatedTimeWithForcedMetadataCreateInput;
  update: MetadataCreatedTimeWithForcedMetadataUpdateInput;
  where: MetadataCreatedTimeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCreatorListUpdateInput = {
  create: MetadataCreatorWithForcedMetadataCreateInput;
  update: MetadataCreatorWithForcedMetadataUpdateInput;
  where: MetadataCreatorWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCreatorToolListUpdateInput = {
  create: MetadataCreatorToolWithForcedMetadataCreateInput;
  update: MetadataCreatorToolWithForcedMetadataUpdateInput;
  where: MetadataCreatorToolWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCreditListUpdateInput = {
  create: MetadataCreditWithForcedMetadataCreateInput;
  update: MetadataCreditWithForcedMetadataUpdateInput;
  where: MetadataCreditWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCustomField14ListUpdateInput = {
  create: MetadataCustomField14WithForcedMetadataCreateInput;
  update: MetadataCustomField14WithForcedMetadataUpdateInput;
  where: MetadataCustomField14WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCustomField15ListUpdateInput = {
  create: MetadataCustomField15WithForcedMetadataCreateInput;
  update: MetadataCustomField15WithForcedMetadataUpdateInput;
  where: MetadataCustomField15WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCustomField16ListUpdateInput = {
  create: MetadataCustomField16WithForcedMetadataCreateInput;
  update: MetadataCustomField16WithForcedMetadataUpdateInput;
  where: MetadataCustomField16WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCustomField17ListUpdateInput = {
  create: MetadataCustomField17WithForcedMetadataCreateInput;
  update: MetadataCustomField17WithForcedMetadataUpdateInput;
  where: MetadataCustomField17WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCustomField20ListUpdateInput = {
  create: MetadataCustomField20WithForcedMetadataCreateInput;
  update: MetadataCustomField20WithForcedMetadataUpdateInput;
  where: MetadataCustomField20WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCustomField5ListUpdateInput = {
  create: MetadataCustomField5WithForcedMetadataCreateInput;
  update: MetadataCustomField5WithForcedMetadataUpdateInput;
  where: MetadataCustomField5WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCustomField6ListUpdateInput = {
  create: MetadataCustomField6WithForcedMetadataCreateInput;
  update: MetadataCustomField6WithForcedMetadataUpdateInput;
  where: MetadataCustomField6WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCustomField7ListUpdateInput = {
  create: MetadataCustomField7WithForcedMetadataCreateInput;
  update: MetadataCustomField7WithForcedMetadataUpdateInput;
  where: MetadataCustomField7WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataCustomField9ListUpdateInput = {
  create: MetadataCustomField9WithForcedMetadataCreateInput;
  update: MetadataCustomField9WithForcedMetadataUpdateInput;
  where: MetadataCustomField9WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDateCreatedListUpdateInput = {
  create: MetadataDateCreatedWithForcedMetadataCreateInput;
  update: MetadataDateCreatedWithForcedMetadataUpdateInput;
  where: MetadataDateCreatedWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDateTimeDigitizedListUpdateInput = {
  create: MetadataDateTimeDigitizedWithForcedMetadataCreateInput;
  update: MetadataDateTimeDigitizedWithForcedMetadataUpdateInput;
  where: MetadataDateTimeDigitizedWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDateTimeOriginalListUpdateInput = {
  create: MetadataDateTimeOriginalWithForcedMetadataCreateInput;
  update: MetadataDateTimeOriginalWithForcedMetadataUpdateInput;
  where: MetadataDateTimeOriginalWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryAccountListUpdateInput = {
  create: MetadataDeliveryAccountWithForcedMetadataCreateInput;
  update: MetadataDeliveryAccountWithForcedMetadataUpdateInput;
  where: MetadataDeliveryAccountWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryCompanyListUpdateInput = {
  create: MetadataDeliveryCompanyWithForcedMetadataCreateInput;
  update: MetadataDeliveryCompanyWithForcedMetadataUpdateInput;
  where: MetadataDeliveryCompanyWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryCopyrightListUpdateInput = {
  create: MetadataDeliveryCopyrightWithForcedMetadataCreateInput;
  update: MetadataDeliveryCopyrightWithForcedMetadataUpdateInput;
  where: MetadataDeliveryCopyrightWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryDateFolderListUpdateInput = {
  create: MetadataDeliveryDateFolderWithForcedMetadataCreateInput;
  update: MetadataDeliveryDateFolderWithForcedMetadataUpdateInput;
  where: MetadataDeliveryDateFolderWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryDateTimeListUpdateInput = {
  create: MetadataDeliveryDateTimeWithForcedMetadataCreateInput;
  update: MetadataDeliveryDateTimeWithForcedMetadataUpdateInput;
  where: MetadataDeliveryDateTimeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryDcCreatorListUpdateInput = {
  create: MetadataDeliveryDcCreatorWithForcedMetadataCreateInput;
  update: MetadataDeliveryDcCreatorWithForcedMetadataUpdateInput;
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryDcRightsListUpdateInput = {
  create: MetadataDeliveryDcRightsWithForcedMetadataCreateInput;
  update: MetadataDeliveryDcRightsWithForcedMetadataUpdateInput;
  where: MetadataDeliveryDcRightsWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryFileTypeListUpdateInput = {
  create: MetadataDeliveryFileTypeWithForcedMetadataCreateInput;
  update: MetadataDeliveryFileTypeWithForcedMetadataUpdateInput;
  where: MetadataDeliveryFileTypeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryFolderListUpdateInput = {
  create: MetadataDeliveryFolderWithForcedMetadataCreateInput;
  update: MetadataDeliveryFolderWithForcedMetadataUpdateInput;
  where: MetadataDeliveryFolderWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryKindListUpdateInput = {
  create: MetadataDeliveryKindWithForcedMetadataCreateInput;
  update: MetadataDeliveryKindWithForcedMetadataUpdateInput;
  where: MetadataDeliveryKindWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryPathListUpdateInput = {
  create: MetadataDeliveryPathWithForcedMetadataCreateInput;
  update: MetadataDeliveryPathWithForcedMetadataUpdateInput;
  where: MetadataDeliveryPathWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryPersonShownintheImageListUpdateInput = {
  create: MetadataDeliveryPersonShownintheImageWithForcedMetadataCreateInput;
  update: MetadataDeliveryPersonShownintheImageWithForcedMetadataUpdateInput;
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryPhotoshopCreditListUpdateInput = {
  create: MetadataDeliveryPhotoshopCreditWithForcedMetadataCreateInput;
  update: MetadataDeliveryPhotoshopCreditWithForcedMetadataUpdateInput;
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryPhotoshopSourceListUpdateInput = {
  create: MetadataDeliveryPhotoshopSourceWithForcedMetadataCreateInput;
  update: MetadataDeliveryPhotoshopSourceWithForcedMetadataUpdateInput;
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryServiceListUpdateInput = {
  create: MetadataDeliveryServiceWithForcedMetadataCreateInput;
  update: MetadataDeliveryServiceWithForcedMetadataUpdateInput;
  where: MetadataDeliveryServiceWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliverySubjectListUpdateInput = {
  create: MetadataDeliverySubjectWithForcedMetadataCreateInput;
  update: MetadataDeliverySubjectWithForcedMetadataUpdateInput;
  where: MetadataDeliverySubjectWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDeliveryUnderSubjectListUpdateInput = {
  create: MetadataDeliveryUnderSubjectWithForcedMetadataCreateInput;
  update: MetadataDeliveryUnderSubjectWithForcedMetadataUpdateInput;
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDepartmentListUpdateInput = {
  create: MetadataDepartmentWithForcedMetadataCreateInput;
  update: MetadataDepartmentWithForcedMetadataUpdateInput;
  where: MetadataDepartmentWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDescriptionListUpdateInput = {
  create: MetadataDescriptionWithForcedMetadataCreateInput;
  update: MetadataDescriptionWithForcedMetadataUpdateInput;
  where: MetadataDescriptionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDigitalAssetUrlListUpdateInput = {
  create: MetadataDigitalAssetUrlWithForcedMetadataCreateInput;
  update: MetadataDigitalAssetUrlWithForcedMetadataUpdateInput;
  where: MetadataDigitalAssetUrlWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDivisionListUpdateInput = {
  create: MetadataDivisionWithForcedMetadataCreateInput;
  update: MetadataDivisionWithForcedMetadataUpdateInput;
  where: MetadataDivisionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataDocumentTextListUpdateInput = {
  create: MetadataDocumentTextWithForcedMetadataCreateInput;
  update: MetadataDocumentTextWithForcedMetadataUpdateInput;
  where: MetadataDocumentTextWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataEditionListUpdateInput = {
  create: MetadataEditionWithForcedMetadataCreateInput;
  update: MetadataEditionWithForcedMetadataUpdateInput;
  where: MetadataEditionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataEditorialVersionListUpdateInput = {
  create: MetadataEditorialVersionWithForcedMetadataCreateInput;
  update: MetadataEditorialVersionWithForcedMetadataUpdateInput;
  where: MetadataEditorialVersionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataEditStatusListUpdateInput = {
  create: MetadataEditStatusWithForcedMetadataCreateInput;
  update: MetadataEditStatusWithForcedMetadataUpdateInput;
  where: MetadataEditStatusWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataEnvironnementPhotoListUpdateInput = {
  create: MetadataEnvironnementPhotoWithForcedMetadataCreateInput;
  update: MetadataEnvironnementPhotoWithForcedMetadataUpdateInput;
  where: MetadataEnvironnementPhotoWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataEquipmentInstitutionListUpdateInput = {
  create: MetadataEquipmentInstitutionWithForcedMetadataCreateInput;
  update: MetadataEquipmentInstitutionWithForcedMetadataUpdateInput;
  where: MetadataEquipmentInstitutionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataEquipmentManufacturerListUpdateInput = {
  create: MetadataEquipmentManufacturerWithForcedMetadataCreateInput;
  update: MetadataEquipmentManufacturerWithForcedMetadataUpdateInput;
  where: MetadataEquipmentManufacturerWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataEventListUpdateInput = {
  create: MetadataEventWithForcedMetadataCreateInput;
  update: MetadataEventWithForcedMetadataUpdateInput;
  where: MetadataEventWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataExifListUpdateInput = {
  create: MetadataExifWithForcedMetadataCreateInput;
  update: MetadataExifWithForcedMetadataUpdateInput;
  where: MetadataExifWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataFabStorageListUpdateInput = {
  create: MetadataFabStorageWithForcedMetadataCreateInput;
  update: MetadataFabStorageWithForcedMetadataUpdateInput;
  where: MetadataFabStorageWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataFileCheckForListUpdateInput = {
  create: MetadataFileCheckForWithForcedMetadataCreateInput;
  update: MetadataFileCheckForWithForcedMetadataUpdateInput;
  where: MetadataFileCheckForWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataFileInfoBitsPerPixelListUpdateInput = {
  create: MetadataFileInfoBitsPerPixelWithForcedMetadataCreateInput;
  update: MetadataFileInfoBitsPerPixelWithForcedMetadataUpdateInput;
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataFileInfoFramesListUpdateInput = {
  create: MetadataFileInfoFramesWithForcedMetadataCreateInput;
  update: MetadataFileInfoFramesWithForcedMetadataUpdateInput;
  where: MetadataFileInfoFramesWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataFileInfoPhotometricInterpretationListUpdateInput = {
  create: MetadataFileInfoPhotometricInterpretationWithForcedMetadataCreateInput;
  update: MetadataFileInfoPhotometricInterpretationWithForcedMetadataUpdateInput;
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataFileInfoTransferSyntaxListUpdateInput = {
  create: MetadataFileInfoTransferSyntaxWithForcedMetadataCreateInput;
  update: MetadataFileInfoTransferSyntaxWithForcedMetadataUpdateInput;
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataFileStageListUpdateInput = {
  create: MetadataFileStageWithForcedMetadataCreateInput;
  update: MetadataFileStageWithForcedMetadataUpdateInput;
  where: MetadataFileStageWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataFileStatusListUpdateInput = {
  create: MetadataFileStatusWithForcedMetadataCreateInput;
  update: MetadataFileStatusWithForcedMetadataUpdateInput;
  where: MetadataFileStatusWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataFileTypeListUpdateInput = {
  create: MetadataFileTypeWithForcedMetadataCreateInput;
  update: MetadataFileTypeWithForcedMetadataUpdateInput;
  where: MetadataFileTypeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataFixtureIdentifierListUpdateInput = {
  create: MetadataFixtureIdentifierWithForcedMetadataCreateInput;
  update: MetadataFixtureIdentifierWithForcedMetadataUpdateInput;
  where: MetadataFixtureIdentifierWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataFormatListUpdateInput = {
  create: MetadataFormatWithForcedMetadataCreateInput;
  update: MetadataFormatWithForcedMetadataUpdateInput;
  where: MetadataFormatWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataGpsLatitudeListUpdateInput = {
  create: MetadataGpsLatitudeWithForcedMetadataCreateInput;
  update: MetadataGpsLatitudeWithForcedMetadataUpdateInput;
  where: MetadataGpsLatitudeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataGpsLongitudeListUpdateInput = {
  create: MetadataGpsLongitudeWithForcedMetadataCreateInput;
  update: MetadataGpsLongitudeWithForcedMetadataUpdateInput;
  where: MetadataGpsLongitudeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataGpsProcessingMethodListUpdateInput = {
  create: MetadataGpsProcessingMethodWithForcedMetadataCreateInput;
  update: MetadataGpsProcessingMethodWithForcedMetadataUpdateInput;
  where: MetadataGpsProcessingMethodWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataHeaderListUpdateInput = {
  create: MetadataHeaderWithForcedMetadataCreateInput;
  update: MetadataHeaderWithForcedMetadataUpdateInput;
  where: MetadataHeaderWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataHeaderPrismaListUpdateInput = {
  create: MetadataHeaderPrismaWithForcedMetadataCreateInput;
  update: MetadataHeaderPrismaWithForcedMetadataUpdateInput;
  where: MetadataHeaderPrismaWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataHeadlineListUpdateInput = {
  create: MetadataHeadlineWithForcedMetadataCreateInput;
  update: MetadataHeadlineWithForcedMetadataUpdateInput;
  where: MetadataHeadlineWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataHistoryListUpdateInput = {
  create: MetadataHistoryWithForcedMetadataCreateInput;
  update: MetadataHistoryWithForcedMetadataUpdateInput;
  where: MetadataHistoryWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataIccProfileListUpdateInput = {
  create: MetadataIccProfileWithForcedMetadataCreateInput;
  update: MetadataIccProfileWithForcedMetadataUpdateInput;
  where: MetadataIccProfileWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataIdAssignmentListUpdateInput = {
  create: MetadataIdAssignmentWithForcedMetadataCreateInput;
  update: MetadataIdAssignmentWithForcedMetadataUpdateInput;
  where: MetadataIdAssignmentWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataIdMediaContentListUpdateInput = {
  create: MetadataIdMediaContentWithForcedMetadataCreateInput;
  update: MetadataIdMediaContentWithForcedMetadataUpdateInput;
  where: MetadataIdMediaContentWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataIdPhotoPrismaListUpdateInput = {
  create: MetadataIdPhotoPrismaWithForcedMetadataCreateInput;
  update: MetadataIdPhotoPrismaWithForcedMetadataUpdateInput;
  where: MetadataIdPhotoPrismaWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataIdSubjectListUpdateInput = {
  create: MetadataIdSubjectWithForcedMetadataCreateInput;
  update: MetadataIdSubjectWithForcedMetadataUpdateInput;
  where: MetadataIdSubjectWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataImageNotesListUpdateInput = {
  create: MetadataImageNotesWithForcedMetadataCreateInput;
  update: MetadataImageNotesWithForcedMetadataUpdateInput;
  where: MetadataImageNotesWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataImageUniqueIdListUpdateInput = {
  create: MetadataImageUniqueIdWithForcedMetadataCreateInput;
  update: MetadataImageUniqueIdWithForcedMetadataUpdateInput;
  where: MetadataImageUniqueIdWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataInstructionsListUpdateInput = {
  create: MetadataInstructionsWithForcedMetadataCreateInput;
  update: MetadataInstructionsWithForcedMetadataUpdateInput;
  where: MetadataInstructionsWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataIntellectualGenreListUpdateInput = {
  create: MetadataIntellectualGenreWithForcedMetadataCreateInput;
  update: MetadataIntellectualGenreWithForcedMetadataUpdateInput;
  where: MetadataIntellectualGenreWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataIsoSpeedRatingsListUpdateInput = {
  create: MetadataIsoSpeedRatingsWithForcedMetadataCreateInput;
  update: MetadataIsoSpeedRatingsWithForcedMetadataUpdateInput;
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataIssueListUpdateInput = {
  create: MetadataIssueWithForcedMetadataCreateInput;
  update: MetadataIssueWithForcedMetadataUpdateInput;
  where: MetadataIssueWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataJobIdListUpdateInput = {
  create: MetadataJobIdWithForcedMetadataCreateInput;
  update: MetadataJobIdWithForcedMetadataUpdateInput;
  where: MetadataJobIdWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataJobProcessingListUpdateInput = {
  create: MetadataJobProcessingWithForcedMetadataCreateInput;
  update: MetadataJobProcessingWithForcedMetadataUpdateInput;
  where: MetadataJobProcessingWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataLayoutPictureEditingListUpdateInput = {
  create: MetadataLayoutPictureEditingWithForcedMetadataCreateInput;
  update: MetadataLayoutPictureEditingWithForcedMetadataUpdateInput;
  where: MetadataLayoutPictureEditingWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataLayoutStorageListUpdateInput = {
  create: MetadataLayoutStorageWithForcedMetadataCreateInput;
  update: MetadataLayoutStorageWithForcedMetadataUpdateInput;
  where: MetadataLayoutStorageWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataLicenseListUpdateInput = {
  create: MetadataLicenseWithForcedMetadataCreateInput;
  update: MetadataLicenseWithForcedMetadataUpdateInput;
  where: MetadataLicenseWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataLinksListUpdateInput = {
  create: MetadataLinksWithForcedMetadataCreateInput;
  update: MetadataLinksWithForcedMetadataUpdateInput;
  where: MetadataLinksWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataLocalCaptionListUpdateInput = {
  create: MetadataLocalCaptionWithForcedMetadataCreateInput;
  update: MetadataLocalCaptionWithForcedMetadataUpdateInput;
  where: MetadataLocalCaptionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataLocationListUpdateInput = {
  create: MetadataLocationWithForcedMetadataCreateInput;
  update: MetadataLocationWithForcedMetadataUpdateInput;
  where: MetadataLocationWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataMakeListUpdateInput = {
  create: MetadataMakeWithForcedMetadataCreateInput;
  update: MetadataMakeWithForcedMetadataUpdateInput;
  where: MetadataMakeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataManifestListUpdateInput = {
  create: MetadataManifestWithForcedMetadataCreateInput;
  update: MetadataManifestWithForcedMetadataUpdateInput;
  where: MetadataManifestWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataMarkedListUpdateInput = {
  create: MetadataMarkedWithForcedMetadataCreateInput;
  update: MetadataMarkedWithForcedMetadataUpdateInput;
  where: MetadataMarkedWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataMasterDocumentIdListUpdateInput = {
  create: MetadataMasterDocumentIdWithForcedMetadataCreateInput;
  update: MetadataMasterDocumentIdWithForcedMetadataUpdateInput;
  where: MetadataMasterDocumentIdWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataMaxAvailHeightListUpdateInput = {
  create: MetadataMaxAvailHeightWithForcedMetadataCreateInput;
  update: MetadataMaxAvailHeightWithForcedMetadataUpdateInput;
  where: MetadataMaxAvailHeightWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataMaxAvailWidthListUpdateInput = {
  create: MetadataMaxAvailWidthWithForcedMetadataCreateInput;
  update: MetadataMaxAvailWidthWithForcedMetadataUpdateInput;
  where: MetadataMaxAvailWidthWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataMinorModelAgeDisclosureListUpdateInput = {
  create: MetadataMinorModelAgeDisclosureWithForcedMetadataCreateInput;
  update: MetadataMinorModelAgeDisclosureWithForcedMetadataUpdateInput;
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataModelAgeListUpdateInput = {
  create: MetadataModelAgeWithForcedMetadataCreateInput;
  update: MetadataModelAgeWithForcedMetadataUpdateInput;
  where: MetadataModelAgeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataModelListUpdateInput = {
  create: MetadataModelWithForcedMetadataCreateInput;
  update: MetadataModelWithForcedMetadataUpdateInput;
  where: MetadataModelWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataModelReleaseIdListUpdateInput = {
  create: MetadataModelReleaseIdWithForcedMetadataCreateInput;
  update: MetadataModelReleaseIdWithForcedMetadataUpdateInput;
  where: MetadataModelReleaseIdWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataModelReleaseStatusListUpdateInput = {
  create: MetadataModelReleaseStatusWithForcedMetadataCreateInput;
  update: MetadataModelReleaseStatusWithForcedMetadataUpdateInput;
  where: MetadataModelReleaseStatusWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataMorePermissionsListUpdateInput = {
  create: MetadataMorePermissionsWithForcedMetadataCreateInput;
  update: MetadataMorePermissionsWithForcedMetadataUpdateInput;
  where: MetadataMorePermissionsWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataObjectCycleListUpdateInput = {
  create: MetadataObjectCycleWithForcedMetadataCreateInput;
  update: MetadataObjectCycleWithForcedMetadataUpdateInput;
  where: MetadataObjectCycleWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataOrganisationInImageNameListUpdateInput = {
  create: MetadataOrganisationInImageNameWithForcedMetadataCreateInput;
  update: MetadataOrganisationInImageNameWithForcedMetadataUpdateInput;
  where: MetadataOrganisationInImageNameWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataOriginalColorListUpdateInput = {
  create: MetadataOriginalColorWithForcedMetadataCreateInput;
  update: MetadataOriginalColorWithForcedMetadataUpdateInput;
  where: MetadataOriginalColorWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataOriginalIccProfileListUpdateInput = {
  create: MetadataOriginalIccProfileWithForcedMetadataCreateInput;
  update: MetadataOriginalIccProfileWithForcedMetadataUpdateInput;
  where: MetadataOriginalIccProfileWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataOriginalNameListUpdateInput = {
  create: MetadataOriginalNameWithForcedMetadataCreateInput;
  update: MetadataOriginalNameWithForcedMetadataUpdateInput;
  where: MetadataOriginalNameWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataOriginalWeigthListUpdateInput = {
  create: MetadataOriginalWeigthWithForcedMetadataCreateInput;
  update: MetadataOriginalWeigthWithForcedMetadataUpdateInput;
  where: MetadataOriginalWeigthWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataOwnerIdListUpdateInput = {
  create: MetadataOwnerIdWithForcedMetadataCreateInput;
  update: MetadataOwnerIdWithForcedMetadataUpdateInput;
  where: MetadataOwnerIdWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataOwnerListUpdateInput = {
  create: MetadataOwnerWithForcedMetadataCreateInput;
  update: MetadataOwnerWithForcedMetadataUpdateInput;
  where: MetadataOwnerWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPageListUpdateInput = {
  create: MetadataPageWithForcedMetadataCreateInput;
  update: MetadataPageWithForcedMetadataUpdateInput;
  where: MetadataPageWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPagePrismaListUpdateInput = {
  create: MetadataPagePrismaWithForcedMetadataCreateInput;
  update: MetadataPagePrismaWithForcedMetadataUpdateInput;
  where: MetadataPagePrismaWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPaintBasedCorrectionsListUpdateInput = {
  create: MetadataPaintBasedCorrectionsWithForcedMetadataCreateInput;
  update: MetadataPaintBasedCorrectionsWithForcedMetadataUpdateInput;
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPatientDobListUpdateInput = {
  create: MetadataPatientDobWithForcedMetadataCreateInput;
  update: MetadataPatientDobWithForcedMetadataUpdateInput;
  where: MetadataPatientDobWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPatientIdListUpdateInput = {
  create: MetadataPatientIdWithForcedMetadataCreateInput;
  update: MetadataPatientIdWithForcedMetadataUpdateInput;
  where: MetadataPatientIdWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPatientNameListUpdateInput = {
  create: MetadataPatientNameWithForcedMetadataCreateInput;
  update: MetadataPatientNameWithForcedMetadataUpdateInput;
  where: MetadataPatientNameWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPatientSexListUpdateInput = {
  create: MetadataPatientSexWithForcedMetadataCreateInput;
  update: MetadataPatientSexWithForcedMetadataUpdateInput;
  where: MetadataPatientSexWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPersonInImageListUpdateInput = {
  create: MetadataPersonInImageWithForcedMetadataCreateInput;
  update: MetadataPersonInImageWithForcedMetadataUpdateInput;
  where: MetadataPersonInImageWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPhotoStorageListUpdateInput = {
  create: MetadataPhotoStorageWithForcedMetadataCreateInput;
  update: MetadataPhotoStorageWithForcedMetadataUpdateInput;
  where: MetadataPhotoStorageWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPrepressPictureEditingListUpdateInput = {
  create: MetadataPrepressPictureEditingWithForcedMetadataCreateInput;
  update: MetadataPrepressPictureEditingWithForcedMetadataUpdateInput;
  where: MetadataPrepressPictureEditingWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPriceLevelListUpdateInput = {
  create: MetadataPriceLevelWithForcedMetadataCreateInput;
  update: MetadataPriceLevelWithForcedMetadataUpdateInput;
  where: MetadataPriceLevelWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPrintingProfileListUpdateInput = {
  create: MetadataPrintingProfileWithForcedMetadataCreateInput;
  update: MetadataPrintingProfileWithForcedMetadataUpdateInput;
  where: MetadataPrintingProfileWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPrismaProductionListUpdateInput = {
  create: MetadataPrismaProductionWithForcedMetadataCreateInput;
  update: MetadataPrismaProductionWithForcedMetadataUpdateInput;
  where: MetadataPrismaProductionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataProcessHistoryListUpdateInput = {
  create: MetadataProcessHistoryWithForcedMetadataCreateInput;
  update: MetadataProcessHistoryWithForcedMetadataUpdateInput;
  where: MetadataProcessHistoryWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataProcessParameterListUpdateInput = {
  create: MetadataProcessParameterWithForcedMetadataCreateInput;
  update: MetadataProcessParameterWithForcedMetadataUpdateInput;
  where: MetadataProcessParameterWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataProcessStatusListUpdateInput = {
  create: MetadataProcessStatusWithForcedMetadataCreateInput;
  update: MetadataProcessStatusWithForcedMetadataUpdateInput;
  where: MetadataProcessStatusWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataProductListUpdateInput = {
  create: MetadataProductWithForcedMetadataCreateInput;
  update: MetadataProductWithForcedMetadataUpdateInput;
  where: MetadataProductWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataProductShortNameListUpdateInput = {
  create: MetadataProductShortNameWithForcedMetadataCreateInput;
  update: MetadataProductShortNameWithForcedMetadataUpdateInput;
  where: MetadataProductShortNameWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataProductsListUpdateInput = {
  create: MetadataProductsWithForcedMetadataCreateInput;
  update: MetadataProductsWithForcedMetadataUpdateInput;
  where: MetadataProductsWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataProduitsListUpdateInput = {
  create: MetadataProduitsWithForcedMetadataCreateInput;
  update: MetadataProduitsWithForcedMetadataUpdateInput;
  where: MetadataProduitsWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataProgramVersionListUpdateInput = {
  create: MetadataProgramVersionWithForcedMetadataCreateInput;
  update: MetadataProgramVersionWithForcedMetadataUpdateInput;
  where: MetadataProgramVersionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPropertyReleaseIdListUpdateInput = {
  create: MetadataPropertyReleaseIdWithForcedMetadataCreateInput;
  update: MetadataPropertyReleaseIdWithForcedMetadataUpdateInput;
  where: MetadataPropertyReleaseIdWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPropertyReleaseStatusListUpdateInput = {
  create: MetadataPropertyReleaseStatusWithForcedMetadataCreateInput;
  update: MetadataPropertyReleaseStatusWithForcedMetadataUpdateInput;
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPublisherListUpdateInput = {
  create: MetadataPublisherWithForcedMetadataCreateInput;
  update: MetadataPublisherWithForcedMetadataUpdateInput;
  where: MetadataPublisherWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPublishingIssueListUpdateInput = {
  create: MetadataPublishingIssueWithForcedMetadataCreateInput;
  update: MetadataPublishingIssueWithForcedMetadataUpdateInput;
  where: MetadataPublishingIssueWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataPublishingSubjectListUpdateInput = {
  create: MetadataPublishingSubjectWithForcedMetadataCreateInput;
  update: MetadataPublishingSubjectWithForcedMetadataUpdateInput;
  where: MetadataPublishingSubjectWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataQualifiedUsePrismaByListUpdateInput = {
  create: MetadataQualifiedUsePrismaByWithForcedMetadataCreateInput;
  update: MetadataQualifiedUsePrismaByWithForcedMetadataUpdateInput;
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataQualifiedUsePrismaDateListUpdateInput = {
  create: MetadataQualifiedUsePrismaDateWithForcedMetadataCreateInput;
  update: MetadataQualifiedUsePrismaDateWithForcedMetadataUpdateInput;
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataQualifiedUsePrismaDurationListUpdateInput = {
  create: MetadataQualifiedUsePrismaDurationWithForcedMetadataCreateInput;
  update: MetadataQualifiedUsePrismaDurationWithForcedMetadataUpdateInput;
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataQualifiedUsePrismaSupportListUpdateInput = {
  create: MetadataQualifiedUsePrismaSupportWithForcedMetadataCreateInput;
  update: MetadataQualifiedUsePrismaSupportWithForcedMetadataUpdateInput;
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataRatingListUpdateInput = {
  create: MetadataRatingWithForcedMetadataCreateInput;
  update: MetadataRatingWithForcedMetadataUpdateInput;
  where: MetadataRatingWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataReferenceDateListUpdateInput = {
  create: MetadataReferenceDateWithForcedMetadataCreateInput;
  update: MetadataReferenceDateWithForcedMetadataUpdateInput;
  where: MetadataReferenceDateWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataReferenceNumberListUpdateInput = {
  create: MetadataReferenceNumberWithForcedMetadataCreateInput;
  update: MetadataReferenceNumberWithForcedMetadataUpdateInput;
  where: MetadataReferenceNumberWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataReferenceServiceListUpdateInput = {
  create: MetadataReferenceServiceWithForcedMetadataCreateInput;
  update: MetadataReferenceServiceWithForcedMetadataUpdateInput;
  where: MetadataReferenceServiceWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataReleaseDateListUpdateInput = {
  create: MetadataReleaseDateWithForcedMetadataCreateInput;
  update: MetadataReleaseDateWithForcedMetadataUpdateInput;
  where: MetadataReleaseDateWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataReleaseTimeListUpdateInput = {
  create: MetadataReleaseTimeWithForcedMetadataCreateInput;
  update: MetadataReleaseTimeWithForcedMetadataUpdateInput;
  where: MetadataReleaseTimeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataRequiredPermissionListUpdateInput = {
  create: MetadataRequiredPermissionWithForcedMetadataCreateInput;
  update: MetadataRequiredPermissionWithForcedMetadataUpdateInput;
  where: MetadataRequiredPermissionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataResolutionKindListUpdateInput = {
  create: MetadataResolutionKindWithForcedMetadataCreateInput;
  update: MetadataResolutionKindWithForcedMetadataUpdateInput;
  where: MetadataResolutionKindWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataRightsListUpdateInput = {
  create: MetadataRightsWithForcedMetadataCreateInput;
  update: MetadataRightsWithForcedMetadataUpdateInput;
  where: MetadataRightsWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataRoyaltyfreeListUpdateInput = {
  create: MetadataRoyaltyfreeWithForcedMetadataCreateInput;
  update: MetadataRoyaltyfreeWithForcedMetadataUpdateInput;
  where: MetadataRoyaltyfreeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSceneListUpdateInput = {
  create: MetadataSceneWithForcedMetadataCreateInput;
  update: MetadataSceneWithForcedMetadataUpdateInput;
  where: MetadataSceneWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSectionListUpdateInput = {
  create: MetadataSectionWithForcedMetadataCreateInput;
  update: MetadataSectionWithForcedMetadataUpdateInput;
  where: MetadataSectionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSentToListUpdateInput = {
  create: MetadataSentToWithForcedMetadataCreateInput;
  update: MetadataSentToWithForcedMetadataUpdateInput;
  where: MetadataSentToWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSerialNumberListUpdateInput = {
  create: MetadataSerialNumberWithForcedMetadataCreateInput;
  update: MetadataSerialNumberWithForcedMetadataUpdateInput;
  where: MetadataSerialNumberWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSeriesDateTimeListUpdateInput = {
  create: MetadataSeriesDateTimeWithForcedMetadataCreateInput;
  update: MetadataSeriesDateTimeWithForcedMetadataUpdateInput;
  where: MetadataSeriesDateTimeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSeriesDescriptionListUpdateInput = {
  create: MetadataSeriesDescriptionWithForcedMetadataCreateInput;
  update: MetadataSeriesDescriptionWithForcedMetadataUpdateInput;
  where: MetadataSeriesDescriptionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSeriesModalityListUpdateInput = {
  create: MetadataSeriesModalityWithForcedMetadataCreateInput;
  update: MetadataSeriesModalityWithForcedMetadataUpdateInput;
  where: MetadataSeriesModalityWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSeriesNumberListUpdateInput = {
  create: MetadataSeriesNumberWithForcedMetadataCreateInput;
  update: MetadataSeriesNumberWithForcedMetadataUpdateInput;
  where: MetadataSeriesNumberWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataShortUniqueIdListUpdateInput = {
  create: MetadataShortUniqueIdWithForcedMetadataCreateInput;
  update: MetadataShortUniqueIdWithForcedMetadataUpdateInput;
  where: MetadataShortUniqueIdWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSourceListUpdateInput = {
  create: MetadataSourceWithForcedMetadataCreateInput;
  update: MetadataSourceWithForcedMetadataUpdateInput;
  where: MetadataSourceWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataStateListUpdateInput = {
  create: MetadataStateWithForcedMetadataCreateInput;
  update: MetadataStateWithForcedMetadataUpdateInput;
  where: MetadataStateWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataStudyDateTimeListUpdateInput = {
  create: MetadataStudyDateTimeWithForcedMetadataCreateInput;
  update: MetadataStudyDateTimeWithForcedMetadataUpdateInput;
  where: MetadataStudyDateTimeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataStudyDescriptionListUpdateInput = {
  create: MetadataStudyDescriptionWithForcedMetadataCreateInput;
  update: MetadataStudyDescriptionWithForcedMetadataUpdateInput;
  where: MetadataStudyDescriptionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataStudyIdListUpdateInput = {
  create: MetadataStudyIdWithForcedMetadataCreateInput;
  update: MetadataStudyIdWithForcedMetadataUpdateInput;
  where: MetadataStudyIdWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataStudyPhysicianListUpdateInput = {
  create: MetadataStudyPhysicianWithForcedMetadataCreateInput;
  update: MetadataStudyPhysicianWithForcedMetadataUpdateInput;
  where: MetadataStudyPhysicianWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSubjectCodeListUpdateInput = {
  create: MetadataSubjectCodeWithForcedMetadataCreateInput;
  update: MetadataSubjectCodeWithForcedMetadataUpdateInput;
  where: MetadataSubjectCodeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSubjectListUpdateInput = {
  create: MetadataSubjectWithForcedMetadataCreateInput;
  update: MetadataSubjectWithForcedMetadataUpdateInput;
  where: MetadataSubjectWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSubjectPrismaListUpdateInput = {
  create: MetadataSubjectPrismaWithForcedMetadataCreateInput;
  update: MetadataSubjectPrismaWithForcedMetadataUpdateInput;
  where: MetadataSubjectPrismaWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataSupplementalCategoriesListUpdateInput = {
  create: MetadataSupplementalCategoriesWithForcedMetadataCreateInput;
  update: MetadataSupplementalCategoriesWithForcedMetadataUpdateInput;
  where: MetadataSupplementalCategoriesWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataTargetVersionListUpdateInput = {
  create: MetadataTargetVersionWithForcedMetadataCreateInput;
  update: MetadataTargetVersionWithForcedMetadataUpdateInput;
  where: MetadataTargetVersionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataTitleListUpdateInput = {
  create: MetadataTitleWithForcedMetadataCreateInput;
  update: MetadataTitleWithForcedMetadataUpdateInput;
  where: MetadataTitleWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataToneCurveListUpdateInput = {
  create: MetadataToneCurveWithForcedMetadataCreateInput;
  update: MetadataToneCurveWithForcedMetadataUpdateInput;
  where: MetadataToneCurveWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataTransferredByEmailListUpdateInput = {
  create: MetadataTransferredByEmailWithForcedMetadataCreateInput;
  update: MetadataTransferredByEmailWithForcedMetadataUpdateInput;
  where: MetadataTransferredByEmailWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataTransferredByFullNameListUpdateInput = {
  create: MetadataTransferredByFullNameWithForcedMetadataCreateInput;
  update: MetadataTransferredByFullNameWithForcedMetadataUpdateInput;
  where: MetadataTransferredByFullNameWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataTransferredByListUpdateInput = {
  create: MetadataTransferredByWithForcedMetadataCreateInput;
  update: MetadataTransferredByWithForcedMetadataUpdateInput;
  where: MetadataTransferredByWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataTransmissionReferenceListUpdateInput = {
  create: MetadataTransmissionReferenceWithForcedMetadataCreateInput;
  update: MetadataTransmissionReferenceWithForcedMetadataUpdateInput;
  where: MetadataTransmissionReferenceWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataTypeListUpdateInput = {
  create: MetadataTypeWithForcedMetadataCreateInput;
  update: MetadataTypeWithForcedMetadataUpdateInput;
  where: MetadataTypeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUndersubjectListUpdateInput = {
  create: MetadataUndersubjectWithForcedMetadataCreateInput;
  update: MetadataUndersubjectWithForcedMetadataUpdateInput;
  where: MetadataUndersubjectWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUnderUnderSubjectListUpdateInput = {
  create: MetadataUnderUnderSubjectWithForcedMetadataCreateInput;
  update: MetadataUnderUnderSubjectWithForcedMetadataUpdateInput;
  where: MetadataUnderUnderSubjectWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUniqueIdListUpdateInput = {
  create: MetadataUniqueIdWithForcedMetadataCreateInput;
  update: MetadataUniqueIdWithForcedMetadataUpdateInput;
  where: MetadataUniqueIdWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUnitListUpdateInput = {
  create: MetadataUnitWithForcedMetadataCreateInput;
  update: MetadataUnitWithForcedMetadataUpdateInput;
  where: MetadataUnitWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUnitShortNameListUpdateInput = {
  create: MetadataUnitShortNameWithForcedMetadataCreateInput;
  update: MetadataUnitShortNameWithForcedMetadataUpdateInput;
  where: MetadataUnitShortNameWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUploadedByFullNameListUpdateInput = {
  create: MetadataUploadedByFullNameWithForcedMetadataCreateInput;
  update: MetadataUploadedByFullNameWithForcedMetadataUpdateInput;
  where: MetadataUploadedByFullNameWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUploadedByListUpdateInput = {
  create: MetadataUploadedByWithForcedMetadataCreateInput;
  update: MetadataUploadedByWithForcedMetadataUpdateInput;
  where: MetadataUploadedByWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUploadTimeListUpdateInput = {
  create: MetadataUploadTimeWithForcedMetadataCreateInput;
  update: MetadataUploadTimeWithForcedMetadataUpdateInput;
  where: MetadataUploadTimeWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUrgencyListUpdateInput = {
  create: MetadataUrgencyWithForcedMetadataCreateInput;
  update: MetadataUrgencyWithForcedMetadataUpdateInput;
  where: MetadataUrgencyWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUsageTermsListUpdateInput = {
  create: MetadataUsageTermsWithForcedMetadataCreateInput;
  update: MetadataUsageTermsWithForcedMetadataUpdateInput;
  where: MetadataUsageTermsWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUserCommentListUpdateInput = {
  create: MetadataUserCommentWithForcedMetadataCreateInput;
  update: MetadataUserCommentWithForcedMetadataUpdateInput;
  where: MetadataUserCommentWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUserDefined195ListUpdateInput = {
  create: MetadataUserDefined195WithForcedMetadataCreateInput;
  update: MetadataUserDefined195WithForcedMetadataUpdateInput;
  where: MetadataUserDefined195WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUserDefined237ListUpdateInput = {
  create: MetadataUserDefined237WithForcedMetadataCreateInput;
  update: MetadataUserDefined237WithForcedMetadataUpdateInput;
  where: MetadataUserDefined237WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUserDefined238ListUpdateInput = {
  create: MetadataUserDefined238WithForcedMetadataCreateInput;
  update: MetadataUserDefined238WithForcedMetadataUpdateInput;
  where: MetadataUserDefined238WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUserDefined239ListUpdateInput = {
  create: MetadataUserDefined239WithForcedMetadataCreateInput;
  update: MetadataUserDefined239WithForcedMetadataUpdateInput;
  where: MetadataUserDefined239WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUserDefined242ListUpdateInput = {
  create: MetadataUserDefined242WithForcedMetadataCreateInput;
  update: MetadataUserDefined242WithForcedMetadataUpdateInput;
  where: MetadataUserDefined242WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataUserDefined62ListUpdateInput = {
  create: MetadataUserDefined62WithForcedMetadataCreateInput;
  update: MetadataUserDefined62WithForcedMetadataUpdateInput;
  where: MetadataUserDefined62WhereUniqueInput;
};

export type MetadataNestedUpsertMetadataValidListUpdateInput = {
  create: MetadataValidWithForcedMetadataCreateInput;
  update: MetadataValidWithForcedMetadataUpdateInput;
  where: MetadataValidWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataVersionListUpdateInput = {
  create: MetadataVersionWithForcedMetadataCreateInput;
  update: MetadataVersionWithForcedMetadataUpdateInput;
  where: MetadataVersionWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataWebStatementListUpdateInput = {
  create: MetadataWebStatementWithForcedMetadataCreateInput;
  update: MetadataWebStatementWithForcedMetadataUpdateInput;
  where: MetadataWebStatementWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataWorkflowKindListUpdateInput = {
  create: MetadataWorkflowKindWithForcedMetadataCreateInput;
  update: MetadataWorkflowKindWithForcedMetadataUpdateInput;
  where: MetadataWorkflowKindWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataXmpFileStampsListUpdateInput = {
  create: MetadataXmpFileStampsWithForcedMetadataCreateInput;
  update: MetadataXmpFileStampsWithForcedMetadataUpdateInput;
  where: MetadataXmpFileStampsWhereUniqueInput;
};

export type MetadataNestedUpsertMetadataXmpHistoryListUpdateInput = {
  create: MetadataXmpHistoryWithForcedMetadataCreateInput;
  update: MetadataXmpHistoryWithForcedMetadataUpdateInput;
  where: MetadataXmpHistoryWhereUniqueInput;
};

export type MetadataNestedUpsertPhotoMetadatasUpdateInput = {
  create: PhotoMetadataWithForcedMetadataCreateInput;
  update: PhotoMetadataWithForcedMetadataUpdateInput;
  where: PhotoMetadataWhereUniqueInput;
};

/** "MetadataObjectCycle" resource's node */
export type MetadataObjectCycle = {
  __typename?: 'MetadataObjectCycle';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataObjectCycleCreateInput = {
  /** Actions for the "MetadataObjectCycle.archive" relation */
  archive: MetadataObjectCycleNestedArchiveCreateInput;
  /** Actions for the "MetadataObjectCycle.metadata" relation */
  metadata: MetadataObjectCycleNestedMetadataCreateInput;
  /** Actions for the "MetadataObjectCycle.photo" relation */
  photo: MetadataObjectCycleNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataObjectCycleNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.archive" relation. */
  update?: InputMaybe<MetadataObjectCycleNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.archive" relation. */
  upsert?: InputMaybe<MetadataObjectCycleNestedUpsertArchiveCreateInput>;
};

export type MetadataObjectCycleNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.archive" relation. */
  update?: InputMaybe<MetadataObjectCycleNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.archive" relation. */
  upsert?: InputMaybe<MetadataObjectCycleNestedUpsertArchiveUpdateInput>;
};

export type MetadataObjectCycleNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.metadata" relation. */
  update?: InputMaybe<MetadataObjectCycleNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.metadata" relation. */
  upsert?: InputMaybe<MetadataObjectCycleNestedUpsertMetadataCreateInput>;
};

export type MetadataObjectCycleNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.metadata" relation. */
  update?: InputMaybe<MetadataObjectCycleNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.metadata" relation. */
  upsert?: InputMaybe<MetadataObjectCycleNestedUpsertMetadataUpdateInput>;
};

export type MetadataObjectCycleNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.photo" relation. */
  update?: InputMaybe<MetadataObjectCycleNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataObjectCycle" node, through the "MetadataObjectCycle.photo" relation. */
  upsert?: InputMaybe<MetadataObjectCycleNestedUpsertPhotoCreateInput>;
};

export type MetadataObjectCycleNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.photo" relation. */
  update?: InputMaybe<MetadataObjectCycleNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataObjectCycle" node, through the "MetadataObjectCycle.photo" relation. */
  upsert?: InputMaybe<MetadataObjectCycleNestedUpsertPhotoUpdateInput>;
};

export type MetadataObjectCycleNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataObjectCycleNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataObjectCycleNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataObjectCycleNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataObjectCycleNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataObjectCycleNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataObjectCycleNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataObjectCycleNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataObjectCycleNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataObjectCycleNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataObjectCycleNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataObjectCycleNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataObjectCycleOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataObjectCycleUpdateInput = {
  /** Actions for the "MetadataObjectCycle.archive" relation */
  archive?: InputMaybe<MetadataObjectCycleNestedArchiveUpdateInput>;
  /** Actions for the "MetadataObjectCycle.metadata" relation */
  metadata?: InputMaybe<MetadataObjectCycleNestedMetadataUpdateInput>;
  /** Actions for the "MetadataObjectCycle.photo" relation */
  photo?: InputMaybe<MetadataObjectCycleNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataObjectCycle" nodes by specifying some conditions */
export type MetadataObjectCycleWhereInput = {
  AND?: InputMaybe<Array<MetadataObjectCycleWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataObjectCycleWhereInput>;
  OR?: InputMaybe<Array<MetadataObjectCycleWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataObjectCycle" node. */
export type MetadataObjectCycleWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataObjectCycleWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataObjectCycle.metadata" relation */
  metadata: MetadataObjectCycleNestedMetadataCreateInput;
  /** Actions for the "MetadataObjectCycle.photo" relation */
  photo: MetadataObjectCycleNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataObjectCycleWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataObjectCycle.metadata" relation */
  metadata?: InputMaybe<MetadataObjectCycleNestedMetadataUpdateInput>;
  /** Actions for the "MetadataObjectCycle.photo" relation */
  photo?: InputMaybe<MetadataObjectCycleNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataObjectCycleWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataObjectCycle.archive" relation */
  archive: MetadataObjectCycleNestedArchiveCreateInput;
  /** Actions for the "MetadataObjectCycle.photo" relation */
  photo: MetadataObjectCycleNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataObjectCycleWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataObjectCycle.archive" relation */
  archive?: InputMaybe<MetadataObjectCycleNestedArchiveUpdateInput>;
  /** Actions for the "MetadataObjectCycle.photo" relation */
  photo?: InputMaybe<MetadataObjectCycleNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataObjectCycleWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataObjectCycle.archive" relation */
  archive: MetadataObjectCycleNestedArchiveCreateInput;
  /** Actions for the "MetadataObjectCycle.metadata" relation */
  metadata: MetadataObjectCycleNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataObjectCycleWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataObjectCycle.archive" relation */
  archive?: InputMaybe<MetadataObjectCycleNestedArchiveUpdateInput>;
  /** Actions for the "MetadataObjectCycle.metadata" relation */
  metadata?: InputMaybe<MetadataObjectCycleNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOccurrenceQlType = {
  __typename?: 'MetadataOccurrenceQLType';
  occurrence: Scalars['Int']['output'];
  value: Scalars['String']['output'];
};

export enum MetadataOrderByInput {
  _id_ASC = '_id_ASC',
  _id_DESC = '_id_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  label_ASC = 'label_ASC',
  label_DESC = 'label_DESC',
  legacyName_ASC = 'legacyName_ASC',
  legacyName_DESC = 'legacyName_DESC',
  name_ASC = 'name_ASC',
  name_DESC = 'name_DESC',
  namespace_ASC = 'namespace_ASC',
  namespace_DESC = 'namespace_DESC',
  structName_ASC = 'structName_ASC',
  structName_DESC = 'structName_DESC',
  type_ASC = 'type_ASC',
  type_DESC = 'type_DESC'
}

/** "MetadataOrganisationInImageName" resource's node */
export type MetadataOrganisationInImageName = {
  __typename?: 'MetadataOrganisationInImageName';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataOrganisationInImageNameCreateInput = {
  /** Actions for the "MetadataOrganisationInImageName.archive" relation */
  archive: MetadataOrganisationInImageNameNestedArchiveCreateInput;
  /** Actions for the "MetadataOrganisationInImageName.metadata" relation */
  metadata: MetadataOrganisationInImageNameNestedMetadataCreateInput;
  /** Actions for the "MetadataOrganisationInImageName.photo" relation */
  photo: MetadataOrganisationInImageNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOrganisationInImageNameNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.archive" relation. */
  update?: InputMaybe<MetadataOrganisationInImageNameNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.archive" relation. */
  upsert?: InputMaybe<MetadataOrganisationInImageNameNestedUpsertArchiveCreateInput>;
};

export type MetadataOrganisationInImageNameNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.archive" relation. */
  update?: InputMaybe<MetadataOrganisationInImageNameNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.archive" relation. */
  upsert?: InputMaybe<MetadataOrganisationInImageNameNestedUpsertArchiveUpdateInput>;
};

export type MetadataOrganisationInImageNameNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.metadata" relation. */
  update?: InputMaybe<MetadataOrganisationInImageNameNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.metadata" relation. */
  upsert?: InputMaybe<MetadataOrganisationInImageNameNestedUpsertMetadataCreateInput>;
};

export type MetadataOrganisationInImageNameNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.metadata" relation. */
  update?: InputMaybe<MetadataOrganisationInImageNameNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.metadata" relation. */
  upsert?: InputMaybe<MetadataOrganisationInImageNameNestedUpsertMetadataUpdateInput>;
};

export type MetadataOrganisationInImageNameNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.photo" relation. */
  update?: InputMaybe<MetadataOrganisationInImageNameNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.photo" relation. */
  upsert?: InputMaybe<MetadataOrganisationInImageNameNestedUpsertPhotoCreateInput>;
};

export type MetadataOrganisationInImageNameNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.photo" relation. */
  update?: InputMaybe<MetadataOrganisationInImageNameNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataOrganisationInImageName" node, through the "MetadataOrganisationInImageName.photo" relation. */
  upsert?: InputMaybe<MetadataOrganisationInImageNameNestedUpsertPhotoUpdateInput>;
};

export type MetadataOrganisationInImageNameNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOrganisationInImageNameNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOrganisationInImageNameNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOrganisationInImageNameNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOrganisationInImageNameNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOrganisationInImageNameNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOrganisationInImageNameNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOrganisationInImageNameNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOrganisationInImageNameNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOrganisationInImageNameNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOrganisationInImageNameNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOrganisationInImageNameNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataOrganisationInImageNameOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataOrganisationInImageNameUpdateInput = {
  /** Actions for the "MetadataOrganisationInImageName.archive" relation */
  archive?: InputMaybe<MetadataOrganisationInImageNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOrganisationInImageName.metadata" relation */
  metadata?: InputMaybe<MetadataOrganisationInImageNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOrganisationInImageName.photo" relation */
  photo?: InputMaybe<MetadataOrganisationInImageNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataOrganisationInImageName" nodes by specifying some conditions */
export type MetadataOrganisationInImageNameWhereInput = {
  AND?: InputMaybe<Array<MetadataOrganisationInImageNameWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
  OR?: InputMaybe<Array<MetadataOrganisationInImageNameWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataOrganisationInImageName" node. */
export type MetadataOrganisationInImageNameWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataOrganisationInImageNameWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataOrganisationInImageName.metadata" relation */
  metadata: MetadataOrganisationInImageNameNestedMetadataCreateInput;
  /** Actions for the "MetadataOrganisationInImageName.photo" relation */
  photo: MetadataOrganisationInImageNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOrganisationInImageNameWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataOrganisationInImageName.metadata" relation */
  metadata?: InputMaybe<MetadataOrganisationInImageNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOrganisationInImageName.photo" relation */
  photo?: InputMaybe<MetadataOrganisationInImageNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOrganisationInImageNameWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataOrganisationInImageName.archive" relation */
  archive: MetadataOrganisationInImageNameNestedArchiveCreateInput;
  /** Actions for the "MetadataOrganisationInImageName.photo" relation */
  photo: MetadataOrganisationInImageNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOrganisationInImageNameWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataOrganisationInImageName.archive" relation */
  archive?: InputMaybe<MetadataOrganisationInImageNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOrganisationInImageName.photo" relation */
  photo?: InputMaybe<MetadataOrganisationInImageNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOrganisationInImageNameWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataOrganisationInImageName.archive" relation */
  archive: MetadataOrganisationInImageNameNestedArchiveCreateInput;
  /** Actions for the "MetadataOrganisationInImageName.metadata" relation */
  metadata: MetadataOrganisationInImageNameNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOrganisationInImageNameWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataOrganisationInImageName.archive" relation */
  archive?: InputMaybe<MetadataOrganisationInImageNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOrganisationInImageName.metadata" relation */
  metadata?: InputMaybe<MetadataOrganisationInImageNameNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataOriginalColor" resource's node */
export type MetadataOriginalColor = {
  __typename?: 'MetadataOriginalColor';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataOriginalColorCreateInput = {
  /** Actions for the "MetadataOriginalColor.archive" relation */
  archive: MetadataOriginalColorNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalColor.metadata" relation */
  metadata: MetadataOriginalColorNestedMetadataCreateInput;
  /** Actions for the "MetadataOriginalColor.photo" relation */
  photo: MetadataOriginalColorNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalColorNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.archive" relation. */
  update?: InputMaybe<MetadataOriginalColorNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.archive" relation. */
  upsert?: InputMaybe<MetadataOriginalColorNestedUpsertArchiveCreateInput>;
};

export type MetadataOriginalColorNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.archive" relation. */
  update?: InputMaybe<MetadataOriginalColorNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.archive" relation. */
  upsert?: InputMaybe<MetadataOriginalColorNestedUpsertArchiveUpdateInput>;
};

export type MetadataOriginalColorNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.metadata" relation. */
  update?: InputMaybe<MetadataOriginalColorNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.metadata" relation. */
  upsert?: InputMaybe<MetadataOriginalColorNestedUpsertMetadataCreateInput>;
};

export type MetadataOriginalColorNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.metadata" relation. */
  update?: InputMaybe<MetadataOriginalColorNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.metadata" relation. */
  upsert?: InputMaybe<MetadataOriginalColorNestedUpsertMetadataUpdateInput>;
};

export type MetadataOriginalColorNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.photo" relation. */
  update?: InputMaybe<MetadataOriginalColorNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataOriginalColor" node, through the "MetadataOriginalColor.photo" relation. */
  upsert?: InputMaybe<MetadataOriginalColorNestedUpsertPhotoCreateInput>;
};

export type MetadataOriginalColorNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.photo" relation. */
  update?: InputMaybe<MetadataOriginalColorNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataOriginalColor" node, through the "MetadataOriginalColor.photo" relation. */
  upsert?: InputMaybe<MetadataOriginalColorNestedUpsertPhotoUpdateInput>;
};

export type MetadataOriginalColorNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalColorNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalColorNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalColorNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalColorNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalColorNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalColorNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalColorNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalColorNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalColorNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalColorNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalColorNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataOriginalColorOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataOriginalColorUpdateInput = {
  /** Actions for the "MetadataOriginalColor.archive" relation */
  archive?: InputMaybe<MetadataOriginalColorNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalColor.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalColorNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOriginalColor.photo" relation */
  photo?: InputMaybe<MetadataOriginalColorNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataOriginalColor" nodes by specifying some conditions */
export type MetadataOriginalColorWhereInput = {
  AND?: InputMaybe<Array<MetadataOriginalColorWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataOriginalColorWhereInput>;
  OR?: InputMaybe<Array<MetadataOriginalColorWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataOriginalColor" node. */
export type MetadataOriginalColorWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataOriginalColorWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataOriginalColor.metadata" relation */
  metadata: MetadataOriginalColorNestedMetadataCreateInput;
  /** Actions for the "MetadataOriginalColor.photo" relation */
  photo: MetadataOriginalColorNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalColorWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataOriginalColor.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalColorNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOriginalColor.photo" relation */
  photo?: InputMaybe<MetadataOriginalColorNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalColorWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataOriginalColor.archive" relation */
  archive: MetadataOriginalColorNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalColor.photo" relation */
  photo: MetadataOriginalColorNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalColorWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataOriginalColor.archive" relation */
  archive?: InputMaybe<MetadataOriginalColorNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalColor.photo" relation */
  photo?: InputMaybe<MetadataOriginalColorNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalColorWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataOriginalColor.archive" relation */
  archive: MetadataOriginalColorNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalColor.metadata" relation */
  metadata: MetadataOriginalColorNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalColorWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataOriginalColor.archive" relation */
  archive?: InputMaybe<MetadataOriginalColorNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalColor.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalColorNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataOriginalIccProfile" resource's node */
export type MetadataOriginalIccProfile = {
  __typename?: 'MetadataOriginalIccProfile';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataOriginalIccProfileCreateInput = {
  /** Actions for the "MetadataOriginalIccProfile.archive" relation */
  archive: MetadataOriginalIccProfileNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalIccProfile.metadata" relation */
  metadata: MetadataOriginalIccProfileNestedMetadataCreateInput;
  /** Actions for the "MetadataOriginalIccProfile.photo" relation */
  photo: MetadataOriginalIccProfileNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalIccProfileNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.archive" relation. */
  update?: InputMaybe<MetadataOriginalIccProfileNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.archive" relation. */
  upsert?: InputMaybe<MetadataOriginalIccProfileNestedUpsertArchiveCreateInput>;
};

export type MetadataOriginalIccProfileNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.archive" relation. */
  update?: InputMaybe<MetadataOriginalIccProfileNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.archive" relation. */
  upsert?: InputMaybe<MetadataOriginalIccProfileNestedUpsertArchiveUpdateInput>;
};

export type MetadataOriginalIccProfileNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.metadata" relation. */
  update?: InputMaybe<MetadataOriginalIccProfileNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.metadata" relation. */
  upsert?: InputMaybe<MetadataOriginalIccProfileNestedUpsertMetadataCreateInput>;
};

export type MetadataOriginalIccProfileNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.metadata" relation. */
  update?: InputMaybe<MetadataOriginalIccProfileNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.metadata" relation. */
  upsert?: InputMaybe<MetadataOriginalIccProfileNestedUpsertMetadataUpdateInput>;
};

export type MetadataOriginalIccProfileNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.photo" relation. */
  update?: InputMaybe<MetadataOriginalIccProfileNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.photo" relation. */
  upsert?: InputMaybe<MetadataOriginalIccProfileNestedUpsertPhotoCreateInput>;
};

export type MetadataOriginalIccProfileNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.photo" relation. */
  update?: InputMaybe<MetadataOriginalIccProfileNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataOriginalIccProfile" node, through the "MetadataOriginalIccProfile.photo" relation. */
  upsert?: InputMaybe<MetadataOriginalIccProfileNestedUpsertPhotoUpdateInput>;
};

export type MetadataOriginalIccProfileNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalIccProfileNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalIccProfileNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalIccProfileNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalIccProfileNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalIccProfileNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalIccProfileNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalIccProfileNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalIccProfileNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalIccProfileNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalIccProfileNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalIccProfileNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataOriginalIccProfileOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataOriginalIccProfileUpdateInput = {
  /** Actions for the "MetadataOriginalIccProfile.archive" relation */
  archive?: InputMaybe<MetadataOriginalIccProfileNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalIccProfile.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalIccProfileNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOriginalIccProfile.photo" relation */
  photo?: InputMaybe<MetadataOriginalIccProfileNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataOriginalIccProfile" nodes by specifying some conditions */
export type MetadataOriginalIccProfileWhereInput = {
  AND?: InputMaybe<Array<MetadataOriginalIccProfileWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
  OR?: InputMaybe<Array<MetadataOriginalIccProfileWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataOriginalIccProfile" node. */
export type MetadataOriginalIccProfileWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataOriginalIccProfileWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataOriginalIccProfile.metadata" relation */
  metadata: MetadataOriginalIccProfileNestedMetadataCreateInput;
  /** Actions for the "MetadataOriginalIccProfile.photo" relation */
  photo: MetadataOriginalIccProfileNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalIccProfileWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataOriginalIccProfile.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalIccProfileNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOriginalIccProfile.photo" relation */
  photo?: InputMaybe<MetadataOriginalIccProfileNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalIccProfileWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataOriginalIccProfile.archive" relation */
  archive: MetadataOriginalIccProfileNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalIccProfile.photo" relation */
  photo: MetadataOriginalIccProfileNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalIccProfileWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataOriginalIccProfile.archive" relation */
  archive?: InputMaybe<MetadataOriginalIccProfileNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalIccProfile.photo" relation */
  photo?: InputMaybe<MetadataOriginalIccProfileNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalIccProfileWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataOriginalIccProfile.archive" relation */
  archive: MetadataOriginalIccProfileNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalIccProfile.metadata" relation */
  metadata: MetadataOriginalIccProfileNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalIccProfileWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataOriginalIccProfile.archive" relation */
  archive?: InputMaybe<MetadataOriginalIccProfileNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalIccProfile.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalIccProfileNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataOriginalName" resource's node */
export type MetadataOriginalName = {
  __typename?: 'MetadataOriginalName';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataOriginalNameCreateInput = {
  /** Actions for the "MetadataOriginalName.archive" relation */
  archive: MetadataOriginalNameNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalName.metadata" relation */
  metadata: MetadataOriginalNameNestedMetadataCreateInput;
  /** Actions for the "MetadataOriginalName.photo" relation */
  photo: MetadataOriginalNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalNameNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataOriginalName" node, through the "MetadataOriginalName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataOriginalName" node, through the "MetadataOriginalName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataOriginalName" node, through the "MetadataOriginalName.archive" relation. */
  update?: InputMaybe<MetadataOriginalNameNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataOriginalName" node, through the "MetadataOriginalName.archive" relation. */
  upsert?: InputMaybe<MetadataOriginalNameNestedUpsertArchiveCreateInput>;
};

export type MetadataOriginalNameNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.archive" relation. */
  update?: InputMaybe<MetadataOriginalNameNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.archive" relation. */
  upsert?: InputMaybe<MetadataOriginalNameNestedUpsertArchiveUpdateInput>;
};

export type MetadataOriginalNameNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataOriginalName" node, through the "MetadataOriginalName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataOriginalName" node, through the "MetadataOriginalName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataOriginalName" node, through the "MetadataOriginalName.metadata" relation. */
  update?: InputMaybe<MetadataOriginalNameNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataOriginalName" node, through the "MetadataOriginalName.metadata" relation. */
  upsert?: InputMaybe<MetadataOriginalNameNestedUpsertMetadataCreateInput>;
};

export type MetadataOriginalNameNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.metadata" relation. */
  update?: InputMaybe<MetadataOriginalNameNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.metadata" relation. */
  upsert?: InputMaybe<MetadataOriginalNameNestedUpsertMetadataUpdateInput>;
};

export type MetadataOriginalNameNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataOriginalName" node, through the "MetadataOriginalName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataOriginalName" node, through the "MetadataOriginalName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataOriginalName" node, through the "MetadataOriginalName.photo" relation. */
  update?: InputMaybe<MetadataOriginalNameNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataOriginalName" node, through the "MetadataOriginalName.photo" relation. */
  upsert?: InputMaybe<MetadataOriginalNameNestedUpsertPhotoCreateInput>;
};

export type MetadataOriginalNameNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.photo" relation. */
  update?: InputMaybe<MetadataOriginalNameNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataOriginalName" node, through the "MetadataOriginalName.photo" relation. */
  upsert?: InputMaybe<MetadataOriginalNameNestedUpsertPhotoUpdateInput>;
};

export type MetadataOriginalNameNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalNameNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalNameNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalNameNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalNameNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalNameNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalNameNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalNameNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalNameNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalNameNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalNameNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalNameNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataOriginalNameOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataOriginalNameUpdateInput = {
  /** Actions for the "MetadataOriginalName.archive" relation */
  archive?: InputMaybe<MetadataOriginalNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalName.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOriginalName.photo" relation */
  photo?: InputMaybe<MetadataOriginalNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataOriginalName" nodes by specifying some conditions */
export type MetadataOriginalNameWhereInput = {
  AND?: InputMaybe<Array<MetadataOriginalNameWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataOriginalNameWhereInput>;
  OR?: InputMaybe<Array<MetadataOriginalNameWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataOriginalName" node. */
export type MetadataOriginalNameWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataOriginalNameWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataOriginalName.metadata" relation */
  metadata: MetadataOriginalNameNestedMetadataCreateInput;
  /** Actions for the "MetadataOriginalName.photo" relation */
  photo: MetadataOriginalNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalNameWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataOriginalName.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOriginalName.photo" relation */
  photo?: InputMaybe<MetadataOriginalNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalNameWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataOriginalName.archive" relation */
  archive: MetadataOriginalNameNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalName.photo" relation */
  photo: MetadataOriginalNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalNameWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataOriginalName.archive" relation */
  archive?: InputMaybe<MetadataOriginalNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalName.photo" relation */
  photo?: InputMaybe<MetadataOriginalNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalNameWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataOriginalName.archive" relation */
  archive: MetadataOriginalNameNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalName.metadata" relation */
  metadata: MetadataOriginalNameNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalNameWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataOriginalName.archive" relation */
  archive?: InputMaybe<MetadataOriginalNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalName.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalNameNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataOriginalWeigth" resource's node */
export type MetadataOriginalWeigth = {
  __typename?: 'MetadataOriginalWeigth';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataOriginalWeigthCreateInput = {
  /** Actions for the "MetadataOriginalWeigth.archive" relation */
  archive: MetadataOriginalWeigthNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalWeigth.metadata" relation */
  metadata: MetadataOriginalWeigthNestedMetadataCreateInput;
  /** Actions for the "MetadataOriginalWeigth.photo" relation */
  photo: MetadataOriginalWeigthNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalWeigthNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.archive" relation. */
  update?: InputMaybe<MetadataOriginalWeigthNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.archive" relation. */
  upsert?: InputMaybe<MetadataOriginalWeigthNestedUpsertArchiveCreateInput>;
};

export type MetadataOriginalWeigthNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.archive" relation. */
  update?: InputMaybe<MetadataOriginalWeigthNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.archive" relation. */
  upsert?: InputMaybe<MetadataOriginalWeigthNestedUpsertArchiveUpdateInput>;
};

export type MetadataOriginalWeigthNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.metadata" relation. */
  update?: InputMaybe<MetadataOriginalWeigthNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.metadata" relation. */
  upsert?: InputMaybe<MetadataOriginalWeigthNestedUpsertMetadataCreateInput>;
};

export type MetadataOriginalWeigthNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.metadata" relation. */
  update?: InputMaybe<MetadataOriginalWeigthNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.metadata" relation. */
  upsert?: InputMaybe<MetadataOriginalWeigthNestedUpsertMetadataUpdateInput>;
};

export type MetadataOriginalWeigthNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.photo" relation. */
  update?: InputMaybe<MetadataOriginalWeigthNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.photo" relation. */
  upsert?: InputMaybe<MetadataOriginalWeigthNestedUpsertPhotoCreateInput>;
};

export type MetadataOriginalWeigthNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.photo" relation. */
  update?: InputMaybe<MetadataOriginalWeigthNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataOriginalWeigth" node, through the "MetadataOriginalWeigth.photo" relation. */
  upsert?: InputMaybe<MetadataOriginalWeigthNestedUpsertPhotoUpdateInput>;
};

export type MetadataOriginalWeigthNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalWeigthNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalWeigthNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalWeigthNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalWeigthNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalWeigthNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalWeigthNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalWeigthNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOriginalWeigthNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalWeigthNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOriginalWeigthNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOriginalWeigthNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataOriginalWeigthOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataOriginalWeigthUpdateInput = {
  /** Actions for the "MetadataOriginalWeigth.archive" relation */
  archive?: InputMaybe<MetadataOriginalWeigthNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalWeigth.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalWeigthNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOriginalWeigth.photo" relation */
  photo?: InputMaybe<MetadataOriginalWeigthNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataOriginalWeigth" nodes by specifying some conditions */
export type MetadataOriginalWeigthWhereInput = {
  AND?: InputMaybe<Array<MetadataOriginalWeigthWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataOriginalWeigthWhereInput>;
  OR?: InputMaybe<Array<MetadataOriginalWeigthWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataOriginalWeigth" node. */
export type MetadataOriginalWeigthWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataOriginalWeigthWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataOriginalWeigth.metadata" relation */
  metadata: MetadataOriginalWeigthNestedMetadataCreateInput;
  /** Actions for the "MetadataOriginalWeigth.photo" relation */
  photo: MetadataOriginalWeigthNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalWeigthWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataOriginalWeigth.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalWeigthNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOriginalWeigth.photo" relation */
  photo?: InputMaybe<MetadataOriginalWeigthNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalWeigthWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataOriginalWeigth.archive" relation */
  archive: MetadataOriginalWeigthNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalWeigth.photo" relation */
  photo: MetadataOriginalWeigthNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalWeigthWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataOriginalWeigth.archive" relation */
  archive?: InputMaybe<MetadataOriginalWeigthNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalWeigth.photo" relation */
  photo?: InputMaybe<MetadataOriginalWeigthNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalWeigthWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataOriginalWeigth.archive" relation */
  archive: MetadataOriginalWeigthNestedArchiveCreateInput;
  /** Actions for the "MetadataOriginalWeigth.metadata" relation */
  metadata: MetadataOriginalWeigthNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOriginalWeigthWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataOriginalWeigth.archive" relation */
  archive?: InputMaybe<MetadataOriginalWeigthNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOriginalWeigth.metadata" relation */
  metadata?: InputMaybe<MetadataOriginalWeigthNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataOwner" resource's node */
export type MetadataOwner = {
  __typename?: 'MetadataOwner';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataOwnerCreateInput = {
  /** Actions for the "MetadataOwner.archive" relation */
  archive: MetadataOwnerNestedArchiveCreateInput;
  /** Actions for the "MetadataOwner.metadata" relation */
  metadata: MetadataOwnerNestedMetadataCreateInput;
  /** Actions for the "MetadataOwner.photo" relation */
  photo: MetadataOwnerNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataOwnerId" resource's node */
export type MetadataOwnerId = {
  __typename?: 'MetadataOwnerId';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataOwnerIdCreateInput = {
  /** Actions for the "MetadataOwnerId.archive" relation */
  archive: MetadataOwnerIdNestedArchiveCreateInput;
  /** Actions for the "MetadataOwnerId.metadata" relation */
  metadata: MetadataOwnerIdNestedMetadataCreateInput;
  /** Actions for the "MetadataOwnerId.photo" relation */
  photo: MetadataOwnerIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerIdNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataOwnerId" node, through the "MetadataOwnerId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataOwnerId" node, through the "MetadataOwnerId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataOwnerId" node, through the "MetadataOwnerId.archive" relation. */
  update?: InputMaybe<MetadataOwnerIdNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataOwnerId" node, through the "MetadataOwnerId.archive" relation. */
  upsert?: InputMaybe<MetadataOwnerIdNestedUpsertArchiveCreateInput>;
};

export type MetadataOwnerIdNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.archive" relation. */
  update?: InputMaybe<MetadataOwnerIdNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.archive" relation. */
  upsert?: InputMaybe<MetadataOwnerIdNestedUpsertArchiveUpdateInput>;
};

export type MetadataOwnerIdNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataOwnerId" node, through the "MetadataOwnerId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataOwnerId" node, through the "MetadataOwnerId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataOwnerId" node, through the "MetadataOwnerId.metadata" relation. */
  update?: InputMaybe<MetadataOwnerIdNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataOwnerId" node, through the "MetadataOwnerId.metadata" relation. */
  upsert?: InputMaybe<MetadataOwnerIdNestedUpsertMetadataCreateInput>;
};

export type MetadataOwnerIdNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.metadata" relation. */
  update?: InputMaybe<MetadataOwnerIdNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.metadata" relation. */
  upsert?: InputMaybe<MetadataOwnerIdNestedUpsertMetadataUpdateInput>;
};

export type MetadataOwnerIdNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataOwnerId" node, through the "MetadataOwnerId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataOwnerId" node, through the "MetadataOwnerId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataOwnerId" node, through the "MetadataOwnerId.photo" relation. */
  update?: InputMaybe<MetadataOwnerIdNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataOwnerId" node, through the "MetadataOwnerId.photo" relation. */
  upsert?: InputMaybe<MetadataOwnerIdNestedUpsertPhotoCreateInput>;
};

export type MetadataOwnerIdNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.photo" relation. */
  update?: InputMaybe<MetadataOwnerIdNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataOwnerId" node, through the "MetadataOwnerId.photo" relation. */
  upsert?: InputMaybe<MetadataOwnerIdNestedUpsertPhotoUpdateInput>;
};

export type MetadataOwnerIdNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOwnerIdNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOwnerIdNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOwnerIdNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOwnerIdNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOwnerIdNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOwnerIdNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOwnerIdNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOwnerIdNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOwnerIdNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOwnerIdNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOwnerIdNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataOwnerIdOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataOwnerIdUpdateInput = {
  /** Actions for the "MetadataOwnerId.archive" relation */
  archive?: InputMaybe<MetadataOwnerIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOwnerId.metadata" relation */
  metadata?: InputMaybe<MetadataOwnerIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOwnerId.photo" relation */
  photo?: InputMaybe<MetadataOwnerIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataOwnerId" nodes by specifying some conditions */
export type MetadataOwnerIdWhereInput = {
  AND?: InputMaybe<Array<MetadataOwnerIdWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataOwnerIdWhereInput>;
  OR?: InputMaybe<Array<MetadataOwnerIdWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataOwnerId" node. */
export type MetadataOwnerIdWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataOwnerIdWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataOwnerId.metadata" relation */
  metadata: MetadataOwnerIdNestedMetadataCreateInput;
  /** Actions for the "MetadataOwnerId.photo" relation */
  photo: MetadataOwnerIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerIdWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataOwnerId.metadata" relation */
  metadata?: InputMaybe<MetadataOwnerIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOwnerId.photo" relation */
  photo?: InputMaybe<MetadataOwnerIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerIdWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataOwnerId.archive" relation */
  archive: MetadataOwnerIdNestedArchiveCreateInput;
  /** Actions for the "MetadataOwnerId.photo" relation */
  photo: MetadataOwnerIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerIdWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataOwnerId.archive" relation */
  archive?: InputMaybe<MetadataOwnerIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOwnerId.photo" relation */
  photo?: InputMaybe<MetadataOwnerIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerIdWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataOwnerId.archive" relation */
  archive: MetadataOwnerIdNestedArchiveCreateInput;
  /** Actions for the "MetadataOwnerId.metadata" relation */
  metadata: MetadataOwnerIdNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerIdWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataOwnerId.archive" relation */
  archive?: InputMaybe<MetadataOwnerIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOwnerId.metadata" relation */
  metadata?: InputMaybe<MetadataOwnerIdNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataOwner" node, through the "MetadataOwner.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataOwner" node, through the "MetadataOwner.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataOwner" node, through the "MetadataOwner.archive" relation. */
  update?: InputMaybe<MetadataOwnerNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataOwner" node, through the "MetadataOwner.archive" relation. */
  upsert?: InputMaybe<MetadataOwnerNestedUpsertArchiveCreateInput>;
};

export type MetadataOwnerNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataOwner" node, through the "MetadataOwner.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataOwner" node, through the "MetadataOwner.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataOwner" node, through the "MetadataOwner.archive" relation. */
  update?: InputMaybe<MetadataOwnerNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataOwner" node, through the "MetadataOwner.archive" relation. */
  upsert?: InputMaybe<MetadataOwnerNestedUpsertArchiveUpdateInput>;
};

export type MetadataOwnerNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataOwner" node, through the "MetadataOwner.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataOwner" node, through the "MetadataOwner.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataOwner" node, through the "MetadataOwner.metadata" relation. */
  update?: InputMaybe<MetadataOwnerNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataOwner" node, through the "MetadataOwner.metadata" relation. */
  upsert?: InputMaybe<MetadataOwnerNestedUpsertMetadataCreateInput>;
};

export type MetadataOwnerNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataOwner" node, through the "MetadataOwner.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataOwner" node, through the "MetadataOwner.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataOwner" node, through the "MetadataOwner.metadata" relation. */
  update?: InputMaybe<MetadataOwnerNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataOwner" node, through the "MetadataOwner.metadata" relation. */
  upsert?: InputMaybe<MetadataOwnerNestedUpsertMetadataUpdateInput>;
};

export type MetadataOwnerNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataOwner" node, through the "MetadataOwner.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataOwner" node, through the "MetadataOwner.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataOwner" node, through the "MetadataOwner.photo" relation. */
  update?: InputMaybe<MetadataOwnerNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataOwner" node, through the "MetadataOwner.photo" relation. */
  upsert?: InputMaybe<MetadataOwnerNestedUpsertPhotoCreateInput>;
};

export type MetadataOwnerNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataOwner" node, through the "MetadataOwner.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataOwner" node, through the "MetadataOwner.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataOwner" node, through the "MetadataOwner.photo" relation. */
  update?: InputMaybe<MetadataOwnerNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataOwner" node, through the "MetadataOwner.photo" relation. */
  upsert?: InputMaybe<MetadataOwnerNestedUpsertPhotoUpdateInput>;
};

export type MetadataOwnerNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOwnerNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOwnerNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOwnerNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOwnerNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOwnerNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOwnerNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOwnerNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataOwnerNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOwnerNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataOwnerNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataOwnerNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataOwnerOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataOwnerUpdateInput = {
  /** Actions for the "MetadataOwner.archive" relation */
  archive?: InputMaybe<MetadataOwnerNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOwner.metadata" relation */
  metadata?: InputMaybe<MetadataOwnerNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOwner.photo" relation */
  photo?: InputMaybe<MetadataOwnerNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataOwner" nodes by specifying some conditions */
export type MetadataOwnerWhereInput = {
  AND?: InputMaybe<Array<MetadataOwnerWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataOwnerWhereInput>;
  OR?: InputMaybe<Array<MetadataOwnerWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataOwner" node. */
export type MetadataOwnerWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataOwnerWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataOwner.metadata" relation */
  metadata: MetadataOwnerNestedMetadataCreateInput;
  /** Actions for the "MetadataOwner.photo" relation */
  photo: MetadataOwnerNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataOwner.metadata" relation */
  metadata?: InputMaybe<MetadataOwnerNestedMetadataUpdateInput>;
  /** Actions for the "MetadataOwner.photo" relation */
  photo?: InputMaybe<MetadataOwnerNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataOwner.archive" relation */
  archive: MetadataOwnerNestedArchiveCreateInput;
  /** Actions for the "MetadataOwner.photo" relation */
  photo: MetadataOwnerNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataOwner.archive" relation */
  archive?: InputMaybe<MetadataOwnerNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOwner.photo" relation */
  photo?: InputMaybe<MetadataOwnerNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataOwner.archive" relation */
  archive: MetadataOwnerNestedArchiveCreateInput;
  /** Actions for the "MetadataOwner.metadata" relation */
  metadata: MetadataOwnerNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataOwnerWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataOwner.archive" relation */
  archive?: InputMaybe<MetadataOwnerNestedArchiveUpdateInput>;
  /** Actions for the "MetadataOwner.metadata" relation */
  metadata?: InputMaybe<MetadataOwnerNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPage" resource's node */
export type MetadataPage = {
  __typename?: 'MetadataPage';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPageCreateInput = {
  /** Actions for the "MetadataPage.archive" relation */
  archive: MetadataPageNestedArchiveCreateInput;
  /** Actions for the "MetadataPage.metadata" relation */
  metadata: MetadataPageNestedMetadataCreateInput;
  /** Actions for the "MetadataPage.photo" relation */
  photo: MetadataPageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPageNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPage" node, through the "MetadataPage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPage" node, through the "MetadataPage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPage" node, through the "MetadataPage.archive" relation. */
  update?: InputMaybe<MetadataPageNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPage" node, through the "MetadataPage.archive" relation. */
  upsert?: InputMaybe<MetadataPageNestedUpsertArchiveCreateInput>;
};

export type MetadataPageNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPage" node, through the "MetadataPage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPage" node, through the "MetadataPage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPage" node, through the "MetadataPage.archive" relation. */
  update?: InputMaybe<MetadataPageNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPage" node, through the "MetadataPage.archive" relation. */
  upsert?: InputMaybe<MetadataPageNestedUpsertArchiveUpdateInput>;
};

export type MetadataPageNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPage" node, through the "MetadataPage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPage" node, through the "MetadataPage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPage" node, through the "MetadataPage.metadata" relation. */
  update?: InputMaybe<MetadataPageNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPage" node, through the "MetadataPage.metadata" relation. */
  upsert?: InputMaybe<MetadataPageNestedUpsertMetadataCreateInput>;
};

export type MetadataPageNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPage" node, through the "MetadataPage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPage" node, through the "MetadataPage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPage" node, through the "MetadataPage.metadata" relation. */
  update?: InputMaybe<MetadataPageNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPage" node, through the "MetadataPage.metadata" relation. */
  upsert?: InputMaybe<MetadataPageNestedUpsertMetadataUpdateInput>;
};

export type MetadataPageNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPage" node, through the "MetadataPage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPage" node, through the "MetadataPage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPage" node, through the "MetadataPage.photo" relation. */
  update?: InputMaybe<MetadataPageNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPage" node, through the "MetadataPage.photo" relation. */
  upsert?: InputMaybe<MetadataPageNestedUpsertPhotoCreateInput>;
};

export type MetadataPageNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPage" node, through the "MetadataPage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPage" node, through the "MetadataPage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPage" node, through the "MetadataPage.photo" relation. */
  update?: InputMaybe<MetadataPageNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPage" node, through the "MetadataPage.photo" relation. */
  upsert?: InputMaybe<MetadataPageNestedUpsertPhotoUpdateInput>;
};

export type MetadataPageNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPageNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPageNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPageNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPageNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPageNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPageNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPageNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPageNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPageNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPageNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPageNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPageOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

/** "MetadataPagePrisma" resource's node */
export type MetadataPagePrisma = {
  __typename?: 'MetadataPagePrisma';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPagePrismaCreateInput = {
  /** Actions for the "MetadataPagePrisma.archive" relation */
  archive: MetadataPagePrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataPagePrisma.metadata" relation */
  metadata: MetadataPagePrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataPagePrisma.photo" relation */
  photo: MetadataPagePrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPagePrismaNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.archive" relation. */
  update?: InputMaybe<MetadataPagePrismaNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.archive" relation. */
  upsert?: InputMaybe<MetadataPagePrismaNestedUpsertArchiveCreateInput>;
};

export type MetadataPagePrismaNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.archive" relation. */
  update?: InputMaybe<MetadataPagePrismaNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.archive" relation. */
  upsert?: InputMaybe<MetadataPagePrismaNestedUpsertArchiveUpdateInput>;
};

export type MetadataPagePrismaNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.metadata" relation. */
  update?: InputMaybe<MetadataPagePrismaNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataPagePrismaNestedUpsertMetadataCreateInput>;
};

export type MetadataPagePrismaNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.metadata" relation. */
  update?: InputMaybe<MetadataPagePrismaNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataPagePrismaNestedUpsertMetadataUpdateInput>;
};

export type MetadataPagePrismaNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.photo" relation. */
  update?: InputMaybe<MetadataPagePrismaNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPagePrisma" node, through the "MetadataPagePrisma.photo" relation. */
  upsert?: InputMaybe<MetadataPagePrismaNestedUpsertPhotoCreateInput>;
};

export type MetadataPagePrismaNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.photo" relation. */
  update?: InputMaybe<MetadataPagePrismaNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPagePrisma" node, through the "MetadataPagePrisma.photo" relation. */
  upsert?: InputMaybe<MetadataPagePrismaNestedUpsertPhotoUpdateInput>;
};

export type MetadataPagePrismaNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPagePrismaNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPagePrismaNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPagePrismaNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPagePrismaNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPagePrismaNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPagePrismaNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPagePrismaNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPagePrismaNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPagePrismaNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPagePrismaNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPagePrismaNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPagePrismaOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPagePrismaUpdateInput = {
  /** Actions for the "MetadataPagePrisma.archive" relation */
  archive?: InputMaybe<MetadataPagePrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPagePrisma.metadata" relation */
  metadata?: InputMaybe<MetadataPagePrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPagePrisma.photo" relation */
  photo?: InputMaybe<MetadataPagePrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPagePrisma" nodes by specifying some conditions */
export type MetadataPagePrismaWhereInput = {
  AND?: InputMaybe<Array<MetadataPagePrismaWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPagePrismaWhereInput>;
  OR?: InputMaybe<Array<MetadataPagePrismaWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPagePrisma" node. */
export type MetadataPagePrismaWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPagePrismaWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPagePrisma.metadata" relation */
  metadata: MetadataPagePrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataPagePrisma.photo" relation */
  photo: MetadataPagePrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPagePrismaWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPagePrisma.metadata" relation */
  metadata?: InputMaybe<MetadataPagePrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPagePrisma.photo" relation */
  photo?: InputMaybe<MetadataPagePrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPagePrismaWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPagePrisma.archive" relation */
  archive: MetadataPagePrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataPagePrisma.photo" relation */
  photo: MetadataPagePrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPagePrismaWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPagePrisma.archive" relation */
  archive?: InputMaybe<MetadataPagePrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPagePrisma.photo" relation */
  photo?: InputMaybe<MetadataPagePrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPagePrismaWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPagePrisma.archive" relation */
  archive: MetadataPagePrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataPagePrisma.metadata" relation */
  metadata: MetadataPagePrismaNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPagePrismaWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPagePrisma.archive" relation */
  archive?: InputMaybe<MetadataPagePrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPagePrisma.metadata" relation */
  metadata?: InputMaybe<MetadataPagePrismaNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPageUpdateInput = {
  /** Actions for the "MetadataPage.archive" relation */
  archive?: InputMaybe<MetadataPageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPage.metadata" relation */
  metadata?: InputMaybe<MetadataPageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPage.photo" relation */
  photo?: InputMaybe<MetadataPageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPage" nodes by specifying some conditions */
export type MetadataPageWhereInput = {
  AND?: InputMaybe<Array<MetadataPageWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPageWhereInput>;
  OR?: InputMaybe<Array<MetadataPageWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPage" node. */
export type MetadataPageWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPageWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPage.metadata" relation */
  metadata: MetadataPageNestedMetadataCreateInput;
  /** Actions for the "MetadataPage.photo" relation */
  photo: MetadataPageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPageWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPage.metadata" relation */
  metadata?: InputMaybe<MetadataPageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPage.photo" relation */
  photo?: InputMaybe<MetadataPageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPageWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPage.archive" relation */
  archive: MetadataPageNestedArchiveCreateInput;
  /** Actions for the "MetadataPage.photo" relation */
  photo: MetadataPageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPageWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPage.archive" relation */
  archive?: InputMaybe<MetadataPageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPage.photo" relation */
  photo?: InputMaybe<MetadataPageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPageWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPage.archive" relation */
  archive: MetadataPageNestedArchiveCreateInput;
  /** Actions for the "MetadataPage.metadata" relation */
  metadata: MetadataPageNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPageWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPage.archive" relation */
  archive?: InputMaybe<MetadataPageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPage.metadata" relation */
  metadata?: InputMaybe<MetadataPageNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPaintBasedCorrections" resource's node */
export type MetadataPaintBasedCorrections = {
  __typename?: 'MetadataPaintBasedCorrections';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPaintBasedCorrectionsCreateInput = {
  /** Actions for the "MetadataPaintBasedCorrections.archive" relation */
  archive: MetadataPaintBasedCorrectionsNestedArchiveCreateInput;
  /** Actions for the "MetadataPaintBasedCorrections.metadata" relation */
  metadata: MetadataPaintBasedCorrectionsNestedMetadataCreateInput;
  /** Actions for the "MetadataPaintBasedCorrections.photo" relation */
  photo: MetadataPaintBasedCorrectionsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPaintBasedCorrectionsNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.archive" relation. */
  update?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.archive" relation. */
  upsert?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpsertArchiveCreateInput>;
};

export type MetadataPaintBasedCorrectionsNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.archive" relation. */
  update?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.archive" relation. */
  upsert?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpsertArchiveUpdateInput>;
};

export type MetadataPaintBasedCorrectionsNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.metadata" relation. */
  update?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.metadata" relation. */
  upsert?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpsertMetadataCreateInput>;
};

export type MetadataPaintBasedCorrectionsNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.metadata" relation. */
  update?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.metadata" relation. */
  upsert?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpsertMetadataUpdateInput>;
};

export type MetadataPaintBasedCorrectionsNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.photo" relation. */
  update?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.photo" relation. */
  upsert?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpsertPhotoCreateInput>;
};

export type MetadataPaintBasedCorrectionsNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.photo" relation. */
  update?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPaintBasedCorrections" node, through the "MetadataPaintBasedCorrections.photo" relation. */
  upsert?: InputMaybe<MetadataPaintBasedCorrectionsNestedUpsertPhotoUpdateInput>;
};

export type MetadataPaintBasedCorrectionsNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPaintBasedCorrectionsNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPaintBasedCorrectionsNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPaintBasedCorrectionsNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPaintBasedCorrectionsNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPaintBasedCorrectionsNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPaintBasedCorrectionsNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPaintBasedCorrectionsNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPaintBasedCorrectionsNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPaintBasedCorrectionsNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPaintBasedCorrectionsNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPaintBasedCorrectionsNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPaintBasedCorrectionsOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPaintBasedCorrectionsUpdateInput = {
  /** Actions for the "MetadataPaintBasedCorrections.archive" relation */
  archive?: InputMaybe<MetadataPaintBasedCorrectionsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPaintBasedCorrections.metadata" relation */
  metadata?: InputMaybe<MetadataPaintBasedCorrectionsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPaintBasedCorrections.photo" relation */
  photo?: InputMaybe<MetadataPaintBasedCorrectionsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPaintBasedCorrections" nodes by specifying some conditions */
export type MetadataPaintBasedCorrectionsWhereInput = {
  AND?: InputMaybe<Array<MetadataPaintBasedCorrectionsWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
  OR?: InputMaybe<Array<MetadataPaintBasedCorrectionsWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPaintBasedCorrections" node. */
export type MetadataPaintBasedCorrectionsWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPaintBasedCorrectionsWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPaintBasedCorrections.metadata" relation */
  metadata: MetadataPaintBasedCorrectionsNestedMetadataCreateInput;
  /** Actions for the "MetadataPaintBasedCorrections.photo" relation */
  photo: MetadataPaintBasedCorrectionsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPaintBasedCorrectionsWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPaintBasedCorrections.metadata" relation */
  metadata?: InputMaybe<MetadataPaintBasedCorrectionsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPaintBasedCorrections.photo" relation */
  photo?: InputMaybe<MetadataPaintBasedCorrectionsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPaintBasedCorrectionsWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPaintBasedCorrections.archive" relation */
  archive: MetadataPaintBasedCorrectionsNestedArchiveCreateInput;
  /** Actions for the "MetadataPaintBasedCorrections.photo" relation */
  photo: MetadataPaintBasedCorrectionsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPaintBasedCorrectionsWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPaintBasedCorrections.archive" relation */
  archive?: InputMaybe<MetadataPaintBasedCorrectionsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPaintBasedCorrections.photo" relation */
  photo?: InputMaybe<MetadataPaintBasedCorrectionsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPaintBasedCorrectionsWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPaintBasedCorrections.archive" relation */
  archive: MetadataPaintBasedCorrectionsNestedArchiveCreateInput;
  /** Actions for the "MetadataPaintBasedCorrections.metadata" relation */
  metadata: MetadataPaintBasedCorrectionsNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPaintBasedCorrectionsWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPaintBasedCorrections.archive" relation */
  archive?: InputMaybe<MetadataPaintBasedCorrectionsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPaintBasedCorrections.metadata" relation */
  metadata?: InputMaybe<MetadataPaintBasedCorrectionsNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPatientDob" resource's node */
export type MetadataPatientDob = {
  __typename?: 'MetadataPatientDob';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPatientDobCreateInput = {
  /** Actions for the "MetadataPatientDob.archive" relation */
  archive: MetadataPatientDobNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientDob.metadata" relation */
  metadata: MetadataPatientDobNestedMetadataCreateInput;
  /** Actions for the "MetadataPatientDob.photo" relation */
  photo: MetadataPatientDobNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientDobNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPatientDob" node, through the "MetadataPatientDob.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPatientDob" node, through the "MetadataPatientDob.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPatientDob" node, through the "MetadataPatientDob.archive" relation. */
  update?: InputMaybe<MetadataPatientDobNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPatientDob" node, through the "MetadataPatientDob.archive" relation. */
  upsert?: InputMaybe<MetadataPatientDobNestedUpsertArchiveCreateInput>;
};

export type MetadataPatientDobNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.archive" relation. */
  update?: InputMaybe<MetadataPatientDobNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.archive" relation. */
  upsert?: InputMaybe<MetadataPatientDobNestedUpsertArchiveUpdateInput>;
};

export type MetadataPatientDobNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPatientDob" node, through the "MetadataPatientDob.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPatientDob" node, through the "MetadataPatientDob.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPatientDob" node, through the "MetadataPatientDob.metadata" relation. */
  update?: InputMaybe<MetadataPatientDobNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPatientDob" node, through the "MetadataPatientDob.metadata" relation. */
  upsert?: InputMaybe<MetadataPatientDobNestedUpsertMetadataCreateInput>;
};

export type MetadataPatientDobNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.metadata" relation. */
  update?: InputMaybe<MetadataPatientDobNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.metadata" relation. */
  upsert?: InputMaybe<MetadataPatientDobNestedUpsertMetadataUpdateInput>;
};

export type MetadataPatientDobNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPatientDob" node, through the "MetadataPatientDob.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPatientDob" node, through the "MetadataPatientDob.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPatientDob" node, through the "MetadataPatientDob.photo" relation. */
  update?: InputMaybe<MetadataPatientDobNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPatientDob" node, through the "MetadataPatientDob.photo" relation. */
  upsert?: InputMaybe<MetadataPatientDobNestedUpsertPhotoCreateInput>;
};

export type MetadataPatientDobNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.photo" relation. */
  update?: InputMaybe<MetadataPatientDobNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPatientDob" node, through the "MetadataPatientDob.photo" relation. */
  upsert?: InputMaybe<MetadataPatientDobNestedUpsertPhotoUpdateInput>;
};

export type MetadataPatientDobNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientDobNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientDobNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientDobNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientDobNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientDobNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientDobNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientDobNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientDobNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientDobNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientDobNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientDobNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPatientDobOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPatientDobUpdateInput = {
  /** Actions for the "MetadataPatientDob.archive" relation */
  archive?: InputMaybe<MetadataPatientDobNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientDob.metadata" relation */
  metadata?: InputMaybe<MetadataPatientDobNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPatientDob.photo" relation */
  photo?: InputMaybe<MetadataPatientDobNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPatientDob" nodes by specifying some conditions */
export type MetadataPatientDobWhereInput = {
  AND?: InputMaybe<Array<MetadataPatientDobWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPatientDobWhereInput>;
  OR?: InputMaybe<Array<MetadataPatientDobWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPatientDob" node. */
export type MetadataPatientDobWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPatientDobWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPatientDob.metadata" relation */
  metadata: MetadataPatientDobNestedMetadataCreateInput;
  /** Actions for the "MetadataPatientDob.photo" relation */
  photo: MetadataPatientDobNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientDobWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPatientDob.metadata" relation */
  metadata?: InputMaybe<MetadataPatientDobNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPatientDob.photo" relation */
  photo?: InputMaybe<MetadataPatientDobNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientDobWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPatientDob.archive" relation */
  archive: MetadataPatientDobNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientDob.photo" relation */
  photo: MetadataPatientDobNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientDobWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPatientDob.archive" relation */
  archive?: InputMaybe<MetadataPatientDobNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientDob.photo" relation */
  photo?: InputMaybe<MetadataPatientDobNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientDobWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPatientDob.archive" relation */
  archive: MetadataPatientDobNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientDob.metadata" relation */
  metadata: MetadataPatientDobNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientDobWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPatientDob.archive" relation */
  archive?: InputMaybe<MetadataPatientDobNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientDob.metadata" relation */
  metadata?: InputMaybe<MetadataPatientDobNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPatientId" resource's node */
export type MetadataPatientId = {
  __typename?: 'MetadataPatientId';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPatientIdCreateInput = {
  /** Actions for the "MetadataPatientId.archive" relation */
  archive: MetadataPatientIdNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientId.metadata" relation */
  metadata: MetadataPatientIdNestedMetadataCreateInput;
  /** Actions for the "MetadataPatientId.photo" relation */
  photo: MetadataPatientIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientIdNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPatientId" node, through the "MetadataPatientId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPatientId" node, through the "MetadataPatientId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPatientId" node, through the "MetadataPatientId.archive" relation. */
  update?: InputMaybe<MetadataPatientIdNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPatientId" node, through the "MetadataPatientId.archive" relation. */
  upsert?: InputMaybe<MetadataPatientIdNestedUpsertArchiveCreateInput>;
};

export type MetadataPatientIdNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPatientId" node, through the "MetadataPatientId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPatientId" node, through the "MetadataPatientId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPatientId" node, through the "MetadataPatientId.archive" relation. */
  update?: InputMaybe<MetadataPatientIdNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPatientId" node, through the "MetadataPatientId.archive" relation. */
  upsert?: InputMaybe<MetadataPatientIdNestedUpsertArchiveUpdateInput>;
};

export type MetadataPatientIdNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPatientId" node, through the "MetadataPatientId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPatientId" node, through the "MetadataPatientId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPatientId" node, through the "MetadataPatientId.metadata" relation. */
  update?: InputMaybe<MetadataPatientIdNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPatientId" node, through the "MetadataPatientId.metadata" relation. */
  upsert?: InputMaybe<MetadataPatientIdNestedUpsertMetadataCreateInput>;
};

export type MetadataPatientIdNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPatientId" node, through the "MetadataPatientId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPatientId" node, through the "MetadataPatientId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPatientId" node, through the "MetadataPatientId.metadata" relation. */
  update?: InputMaybe<MetadataPatientIdNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPatientId" node, through the "MetadataPatientId.metadata" relation. */
  upsert?: InputMaybe<MetadataPatientIdNestedUpsertMetadataUpdateInput>;
};

export type MetadataPatientIdNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPatientId" node, through the "MetadataPatientId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPatientId" node, through the "MetadataPatientId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPatientId" node, through the "MetadataPatientId.photo" relation. */
  update?: InputMaybe<MetadataPatientIdNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPatientId" node, through the "MetadataPatientId.photo" relation. */
  upsert?: InputMaybe<MetadataPatientIdNestedUpsertPhotoCreateInput>;
};

export type MetadataPatientIdNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPatientId" node, through the "MetadataPatientId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPatientId" node, through the "MetadataPatientId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPatientId" node, through the "MetadataPatientId.photo" relation. */
  update?: InputMaybe<MetadataPatientIdNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPatientId" node, through the "MetadataPatientId.photo" relation. */
  upsert?: InputMaybe<MetadataPatientIdNestedUpsertPhotoUpdateInput>;
};

export type MetadataPatientIdNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientIdNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientIdNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientIdNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientIdNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientIdNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientIdNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientIdNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientIdNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientIdNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientIdNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientIdNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPatientIdOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPatientIdUpdateInput = {
  /** Actions for the "MetadataPatientId.archive" relation */
  archive?: InputMaybe<MetadataPatientIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientId.metadata" relation */
  metadata?: InputMaybe<MetadataPatientIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPatientId.photo" relation */
  photo?: InputMaybe<MetadataPatientIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPatientId" nodes by specifying some conditions */
export type MetadataPatientIdWhereInput = {
  AND?: InputMaybe<Array<MetadataPatientIdWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPatientIdWhereInput>;
  OR?: InputMaybe<Array<MetadataPatientIdWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPatientId" node. */
export type MetadataPatientIdWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPatientIdWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPatientId.metadata" relation */
  metadata: MetadataPatientIdNestedMetadataCreateInput;
  /** Actions for the "MetadataPatientId.photo" relation */
  photo: MetadataPatientIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientIdWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPatientId.metadata" relation */
  metadata?: InputMaybe<MetadataPatientIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPatientId.photo" relation */
  photo?: InputMaybe<MetadataPatientIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientIdWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPatientId.archive" relation */
  archive: MetadataPatientIdNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientId.photo" relation */
  photo: MetadataPatientIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientIdWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPatientId.archive" relation */
  archive?: InputMaybe<MetadataPatientIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientId.photo" relation */
  photo?: InputMaybe<MetadataPatientIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientIdWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPatientId.archive" relation */
  archive: MetadataPatientIdNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientId.metadata" relation */
  metadata: MetadataPatientIdNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientIdWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPatientId.archive" relation */
  archive?: InputMaybe<MetadataPatientIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientId.metadata" relation */
  metadata?: InputMaybe<MetadataPatientIdNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPatientName" resource's node */
export type MetadataPatientName = {
  __typename?: 'MetadataPatientName';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPatientNameCreateInput = {
  /** Actions for the "MetadataPatientName.archive" relation */
  archive: MetadataPatientNameNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientName.metadata" relation */
  metadata: MetadataPatientNameNestedMetadataCreateInput;
  /** Actions for the "MetadataPatientName.photo" relation */
  photo: MetadataPatientNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientNameNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPatientName" node, through the "MetadataPatientName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPatientName" node, through the "MetadataPatientName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPatientName" node, through the "MetadataPatientName.archive" relation. */
  update?: InputMaybe<MetadataPatientNameNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPatientName" node, through the "MetadataPatientName.archive" relation. */
  upsert?: InputMaybe<MetadataPatientNameNestedUpsertArchiveCreateInput>;
};

export type MetadataPatientNameNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPatientName" node, through the "MetadataPatientName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPatientName" node, through the "MetadataPatientName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPatientName" node, through the "MetadataPatientName.archive" relation. */
  update?: InputMaybe<MetadataPatientNameNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPatientName" node, through the "MetadataPatientName.archive" relation. */
  upsert?: InputMaybe<MetadataPatientNameNestedUpsertArchiveUpdateInput>;
};

export type MetadataPatientNameNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPatientName" node, through the "MetadataPatientName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPatientName" node, through the "MetadataPatientName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPatientName" node, through the "MetadataPatientName.metadata" relation. */
  update?: InputMaybe<MetadataPatientNameNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPatientName" node, through the "MetadataPatientName.metadata" relation. */
  upsert?: InputMaybe<MetadataPatientNameNestedUpsertMetadataCreateInput>;
};

export type MetadataPatientNameNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPatientName" node, through the "MetadataPatientName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPatientName" node, through the "MetadataPatientName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPatientName" node, through the "MetadataPatientName.metadata" relation. */
  update?: InputMaybe<MetadataPatientNameNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPatientName" node, through the "MetadataPatientName.metadata" relation. */
  upsert?: InputMaybe<MetadataPatientNameNestedUpsertMetadataUpdateInput>;
};

export type MetadataPatientNameNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPatientName" node, through the "MetadataPatientName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPatientName" node, through the "MetadataPatientName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPatientName" node, through the "MetadataPatientName.photo" relation. */
  update?: InputMaybe<MetadataPatientNameNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPatientName" node, through the "MetadataPatientName.photo" relation. */
  upsert?: InputMaybe<MetadataPatientNameNestedUpsertPhotoCreateInput>;
};

export type MetadataPatientNameNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPatientName" node, through the "MetadataPatientName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPatientName" node, through the "MetadataPatientName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPatientName" node, through the "MetadataPatientName.photo" relation. */
  update?: InputMaybe<MetadataPatientNameNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPatientName" node, through the "MetadataPatientName.photo" relation. */
  upsert?: InputMaybe<MetadataPatientNameNestedUpsertPhotoUpdateInput>;
};

export type MetadataPatientNameNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientNameNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientNameNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientNameNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientNameNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientNameNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientNameNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientNameNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientNameNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientNameNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientNameNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientNameNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPatientNameOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPatientNameUpdateInput = {
  /** Actions for the "MetadataPatientName.archive" relation */
  archive?: InputMaybe<MetadataPatientNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientName.metadata" relation */
  metadata?: InputMaybe<MetadataPatientNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPatientName.photo" relation */
  photo?: InputMaybe<MetadataPatientNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPatientName" nodes by specifying some conditions */
export type MetadataPatientNameWhereInput = {
  AND?: InputMaybe<Array<MetadataPatientNameWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPatientNameWhereInput>;
  OR?: InputMaybe<Array<MetadataPatientNameWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPatientName" node. */
export type MetadataPatientNameWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPatientNameWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPatientName.metadata" relation */
  metadata: MetadataPatientNameNestedMetadataCreateInput;
  /** Actions for the "MetadataPatientName.photo" relation */
  photo: MetadataPatientNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientNameWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPatientName.metadata" relation */
  metadata?: InputMaybe<MetadataPatientNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPatientName.photo" relation */
  photo?: InputMaybe<MetadataPatientNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientNameWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPatientName.archive" relation */
  archive: MetadataPatientNameNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientName.photo" relation */
  photo: MetadataPatientNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientNameWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPatientName.archive" relation */
  archive?: InputMaybe<MetadataPatientNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientName.photo" relation */
  photo?: InputMaybe<MetadataPatientNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientNameWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPatientName.archive" relation */
  archive: MetadataPatientNameNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientName.metadata" relation */
  metadata: MetadataPatientNameNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientNameWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPatientName.archive" relation */
  archive?: InputMaybe<MetadataPatientNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientName.metadata" relation */
  metadata?: InputMaybe<MetadataPatientNameNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPatientSex" resource's node */
export type MetadataPatientSex = {
  __typename?: 'MetadataPatientSex';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPatientSexCreateInput = {
  /** Actions for the "MetadataPatientSex.archive" relation */
  archive: MetadataPatientSexNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientSex.metadata" relation */
  metadata: MetadataPatientSexNestedMetadataCreateInput;
  /** Actions for the "MetadataPatientSex.photo" relation */
  photo: MetadataPatientSexNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientSexNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPatientSex" node, through the "MetadataPatientSex.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPatientSex" node, through the "MetadataPatientSex.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPatientSex" node, through the "MetadataPatientSex.archive" relation. */
  update?: InputMaybe<MetadataPatientSexNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPatientSex" node, through the "MetadataPatientSex.archive" relation. */
  upsert?: InputMaybe<MetadataPatientSexNestedUpsertArchiveCreateInput>;
};

export type MetadataPatientSexNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.archive" relation. */
  update?: InputMaybe<MetadataPatientSexNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.archive" relation. */
  upsert?: InputMaybe<MetadataPatientSexNestedUpsertArchiveUpdateInput>;
};

export type MetadataPatientSexNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPatientSex" node, through the "MetadataPatientSex.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPatientSex" node, through the "MetadataPatientSex.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPatientSex" node, through the "MetadataPatientSex.metadata" relation. */
  update?: InputMaybe<MetadataPatientSexNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPatientSex" node, through the "MetadataPatientSex.metadata" relation. */
  upsert?: InputMaybe<MetadataPatientSexNestedUpsertMetadataCreateInput>;
};

export type MetadataPatientSexNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.metadata" relation. */
  update?: InputMaybe<MetadataPatientSexNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.metadata" relation. */
  upsert?: InputMaybe<MetadataPatientSexNestedUpsertMetadataUpdateInput>;
};

export type MetadataPatientSexNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPatientSex" node, through the "MetadataPatientSex.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPatientSex" node, through the "MetadataPatientSex.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPatientSex" node, through the "MetadataPatientSex.photo" relation. */
  update?: InputMaybe<MetadataPatientSexNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPatientSex" node, through the "MetadataPatientSex.photo" relation. */
  upsert?: InputMaybe<MetadataPatientSexNestedUpsertPhotoCreateInput>;
};

export type MetadataPatientSexNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.photo" relation. */
  update?: InputMaybe<MetadataPatientSexNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPatientSex" node, through the "MetadataPatientSex.photo" relation. */
  upsert?: InputMaybe<MetadataPatientSexNestedUpsertPhotoUpdateInput>;
};

export type MetadataPatientSexNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientSexNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientSexNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientSexNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientSexNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientSexNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientSexNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientSexNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPatientSexNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientSexNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPatientSexNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPatientSexNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPatientSexOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPatientSexUpdateInput = {
  /** Actions for the "MetadataPatientSex.archive" relation */
  archive?: InputMaybe<MetadataPatientSexNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientSex.metadata" relation */
  metadata?: InputMaybe<MetadataPatientSexNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPatientSex.photo" relation */
  photo?: InputMaybe<MetadataPatientSexNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPatientSex" nodes by specifying some conditions */
export type MetadataPatientSexWhereInput = {
  AND?: InputMaybe<Array<MetadataPatientSexWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPatientSexWhereInput>;
  OR?: InputMaybe<Array<MetadataPatientSexWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPatientSex" node. */
export type MetadataPatientSexWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPatientSexWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPatientSex.metadata" relation */
  metadata: MetadataPatientSexNestedMetadataCreateInput;
  /** Actions for the "MetadataPatientSex.photo" relation */
  photo: MetadataPatientSexNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientSexWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPatientSex.metadata" relation */
  metadata?: InputMaybe<MetadataPatientSexNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPatientSex.photo" relation */
  photo?: InputMaybe<MetadataPatientSexNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientSexWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPatientSex.archive" relation */
  archive: MetadataPatientSexNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientSex.photo" relation */
  photo: MetadataPatientSexNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientSexWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPatientSex.archive" relation */
  archive?: InputMaybe<MetadataPatientSexNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientSex.photo" relation */
  photo?: InputMaybe<MetadataPatientSexNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientSexWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPatientSex.archive" relation */
  archive: MetadataPatientSexNestedArchiveCreateInput;
  /** Actions for the "MetadataPatientSex.metadata" relation */
  metadata: MetadataPatientSexNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPatientSexWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPatientSex.archive" relation */
  archive?: InputMaybe<MetadataPatientSexNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPatientSex.metadata" relation */
  metadata?: InputMaybe<MetadataPatientSexNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPersonInImage" resource's node */
export type MetadataPersonInImage = {
  __typename?: 'MetadataPersonInImage';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPersonInImageCreateInput = {
  /** Actions for the "MetadataPersonInImage.archive" relation */
  archive: MetadataPersonInImageNestedArchiveCreateInput;
  /** Actions for the "MetadataPersonInImage.metadata" relation */
  metadata: MetadataPersonInImageNestedMetadataCreateInput;
  /** Actions for the "MetadataPersonInImage.photo" relation */
  photo: MetadataPersonInImageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPersonInImageNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.archive" relation. */
  update?: InputMaybe<MetadataPersonInImageNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.archive" relation. */
  upsert?: InputMaybe<MetadataPersonInImageNestedUpsertArchiveCreateInput>;
};

export type MetadataPersonInImageNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.archive" relation. */
  update?: InputMaybe<MetadataPersonInImageNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.archive" relation. */
  upsert?: InputMaybe<MetadataPersonInImageNestedUpsertArchiveUpdateInput>;
};

export type MetadataPersonInImageNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.metadata" relation. */
  update?: InputMaybe<MetadataPersonInImageNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.metadata" relation. */
  upsert?: InputMaybe<MetadataPersonInImageNestedUpsertMetadataCreateInput>;
};

export type MetadataPersonInImageNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.metadata" relation. */
  update?: InputMaybe<MetadataPersonInImageNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.metadata" relation. */
  upsert?: InputMaybe<MetadataPersonInImageNestedUpsertMetadataUpdateInput>;
};

export type MetadataPersonInImageNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.photo" relation. */
  update?: InputMaybe<MetadataPersonInImageNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPersonInImage" node, through the "MetadataPersonInImage.photo" relation. */
  upsert?: InputMaybe<MetadataPersonInImageNestedUpsertPhotoCreateInput>;
};

export type MetadataPersonInImageNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.photo" relation. */
  update?: InputMaybe<MetadataPersonInImageNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPersonInImage" node, through the "MetadataPersonInImage.photo" relation. */
  upsert?: InputMaybe<MetadataPersonInImageNestedUpsertPhotoUpdateInput>;
};

export type MetadataPersonInImageNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPersonInImageNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPersonInImageNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPersonInImageNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPersonInImageNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPersonInImageNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPersonInImageNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPersonInImageNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPersonInImageNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPersonInImageNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPersonInImageNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPersonInImageNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPersonInImageOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPersonInImageUpdateInput = {
  /** Actions for the "MetadataPersonInImage.archive" relation */
  archive?: InputMaybe<MetadataPersonInImageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPersonInImage.metadata" relation */
  metadata?: InputMaybe<MetadataPersonInImageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPersonInImage.photo" relation */
  photo?: InputMaybe<MetadataPersonInImageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPersonInImage" nodes by specifying some conditions */
export type MetadataPersonInImageWhereInput = {
  AND?: InputMaybe<Array<MetadataPersonInImageWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPersonInImageWhereInput>;
  OR?: InputMaybe<Array<MetadataPersonInImageWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPersonInImage" node. */
export type MetadataPersonInImageWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPersonInImageWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPersonInImage.metadata" relation */
  metadata: MetadataPersonInImageNestedMetadataCreateInput;
  /** Actions for the "MetadataPersonInImage.photo" relation */
  photo: MetadataPersonInImageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPersonInImageWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPersonInImage.metadata" relation */
  metadata?: InputMaybe<MetadataPersonInImageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPersonInImage.photo" relation */
  photo?: InputMaybe<MetadataPersonInImageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPersonInImageWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPersonInImage.archive" relation */
  archive: MetadataPersonInImageNestedArchiveCreateInput;
  /** Actions for the "MetadataPersonInImage.photo" relation */
  photo: MetadataPersonInImageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPersonInImageWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPersonInImage.archive" relation */
  archive?: InputMaybe<MetadataPersonInImageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPersonInImage.photo" relation */
  photo?: InputMaybe<MetadataPersonInImageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPersonInImageWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPersonInImage.archive" relation */
  archive: MetadataPersonInImageNestedArchiveCreateInput;
  /** Actions for the "MetadataPersonInImage.metadata" relation */
  metadata: MetadataPersonInImageNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPersonInImageWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPersonInImage.archive" relation */
  archive?: InputMaybe<MetadataPersonInImageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPersonInImage.metadata" relation */
  metadata?: InputMaybe<MetadataPersonInImageNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPhotoStorage" resource's node */
export type MetadataPhotoStorage = {
  __typename?: 'MetadataPhotoStorage';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPhotoStorageCreateInput = {
  /** Actions for the "MetadataPhotoStorage.archive" relation */
  archive: MetadataPhotoStorageNestedArchiveCreateInput;
  /** Actions for the "MetadataPhotoStorage.metadata" relation */
  metadata: MetadataPhotoStorageNestedMetadataCreateInput;
  /** Actions for the "MetadataPhotoStorage.photo" relation */
  photo: MetadataPhotoStorageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPhotoStorageNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.archive" relation. */
  update?: InputMaybe<MetadataPhotoStorageNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.archive" relation. */
  upsert?: InputMaybe<MetadataPhotoStorageNestedUpsertArchiveCreateInput>;
};

export type MetadataPhotoStorageNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.archive" relation. */
  update?: InputMaybe<MetadataPhotoStorageNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.archive" relation. */
  upsert?: InputMaybe<MetadataPhotoStorageNestedUpsertArchiveUpdateInput>;
};

export type MetadataPhotoStorageNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.metadata" relation. */
  update?: InputMaybe<MetadataPhotoStorageNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.metadata" relation. */
  upsert?: InputMaybe<MetadataPhotoStorageNestedUpsertMetadataCreateInput>;
};

export type MetadataPhotoStorageNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.metadata" relation. */
  update?: InputMaybe<MetadataPhotoStorageNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.metadata" relation. */
  upsert?: InputMaybe<MetadataPhotoStorageNestedUpsertMetadataUpdateInput>;
};

export type MetadataPhotoStorageNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.photo" relation. */
  update?: InputMaybe<MetadataPhotoStorageNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.photo" relation. */
  upsert?: InputMaybe<MetadataPhotoStorageNestedUpsertPhotoCreateInput>;
};

export type MetadataPhotoStorageNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.photo" relation. */
  update?: InputMaybe<MetadataPhotoStorageNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPhotoStorage" node, through the "MetadataPhotoStorage.photo" relation. */
  upsert?: InputMaybe<MetadataPhotoStorageNestedUpsertPhotoUpdateInput>;
};

export type MetadataPhotoStorageNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPhotoStorageNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPhotoStorageNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPhotoStorageNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPhotoStorageNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPhotoStorageNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPhotoStorageNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPhotoStorageNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPhotoStorageNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPhotoStorageNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPhotoStorageNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPhotoStorageNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPhotoStorageOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPhotoStorageUpdateInput = {
  /** Actions for the "MetadataPhotoStorage.archive" relation */
  archive?: InputMaybe<MetadataPhotoStorageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPhotoStorage.metadata" relation */
  metadata?: InputMaybe<MetadataPhotoStorageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPhotoStorage.photo" relation */
  photo?: InputMaybe<MetadataPhotoStorageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPhotoStorage" nodes by specifying some conditions */
export type MetadataPhotoStorageWhereInput = {
  AND?: InputMaybe<Array<MetadataPhotoStorageWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPhotoStorageWhereInput>;
  OR?: InputMaybe<Array<MetadataPhotoStorageWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPhotoStorage" node. */
export type MetadataPhotoStorageWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPhotoStorageWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPhotoStorage.metadata" relation */
  metadata: MetadataPhotoStorageNestedMetadataCreateInput;
  /** Actions for the "MetadataPhotoStorage.photo" relation */
  photo: MetadataPhotoStorageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPhotoStorageWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPhotoStorage.metadata" relation */
  metadata?: InputMaybe<MetadataPhotoStorageNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPhotoStorage.photo" relation */
  photo?: InputMaybe<MetadataPhotoStorageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPhotoStorageWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPhotoStorage.archive" relation */
  archive: MetadataPhotoStorageNestedArchiveCreateInput;
  /** Actions for the "MetadataPhotoStorage.photo" relation */
  photo: MetadataPhotoStorageNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPhotoStorageWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPhotoStorage.archive" relation */
  archive?: InputMaybe<MetadataPhotoStorageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPhotoStorage.photo" relation */
  photo?: InputMaybe<MetadataPhotoStorageNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPhotoStorageWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPhotoStorage.archive" relation */
  archive: MetadataPhotoStorageNestedArchiveCreateInput;
  /** Actions for the "MetadataPhotoStorage.metadata" relation */
  metadata: MetadataPhotoStorageNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPhotoStorageWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPhotoStorage.archive" relation */
  archive?: InputMaybe<MetadataPhotoStorageNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPhotoStorage.metadata" relation */
  metadata?: InputMaybe<MetadataPhotoStorageNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPrepressPictureEditing" resource's node */
export type MetadataPrepressPictureEditing = {
  __typename?: 'MetadataPrepressPictureEditing';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPrepressPictureEditingCreateInput = {
  /** Actions for the "MetadataPrepressPictureEditing.archive" relation */
  archive: MetadataPrepressPictureEditingNestedArchiveCreateInput;
  /** Actions for the "MetadataPrepressPictureEditing.metadata" relation */
  metadata: MetadataPrepressPictureEditingNestedMetadataCreateInput;
  /** Actions for the "MetadataPrepressPictureEditing.photo" relation */
  photo: MetadataPrepressPictureEditingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrepressPictureEditingNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.archive" relation. */
  update?: InputMaybe<MetadataPrepressPictureEditingNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.archive" relation. */
  upsert?: InputMaybe<MetadataPrepressPictureEditingNestedUpsertArchiveCreateInput>;
};

export type MetadataPrepressPictureEditingNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.archive" relation. */
  update?: InputMaybe<MetadataPrepressPictureEditingNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.archive" relation. */
  upsert?: InputMaybe<MetadataPrepressPictureEditingNestedUpsertArchiveUpdateInput>;
};

export type MetadataPrepressPictureEditingNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.metadata" relation. */
  update?: InputMaybe<MetadataPrepressPictureEditingNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.metadata" relation. */
  upsert?: InputMaybe<MetadataPrepressPictureEditingNestedUpsertMetadataCreateInput>;
};

export type MetadataPrepressPictureEditingNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.metadata" relation. */
  update?: InputMaybe<MetadataPrepressPictureEditingNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.metadata" relation. */
  upsert?: InputMaybe<MetadataPrepressPictureEditingNestedUpsertMetadataUpdateInput>;
};

export type MetadataPrepressPictureEditingNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.photo" relation. */
  update?: InputMaybe<MetadataPrepressPictureEditingNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.photo" relation. */
  upsert?: InputMaybe<MetadataPrepressPictureEditingNestedUpsertPhotoCreateInput>;
};

export type MetadataPrepressPictureEditingNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.photo" relation. */
  update?: InputMaybe<MetadataPrepressPictureEditingNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPrepressPictureEditing" node, through the "MetadataPrepressPictureEditing.photo" relation. */
  upsert?: InputMaybe<MetadataPrepressPictureEditingNestedUpsertPhotoUpdateInput>;
};

export type MetadataPrepressPictureEditingNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrepressPictureEditingNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrepressPictureEditingNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrepressPictureEditingNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrepressPictureEditingNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPrepressPictureEditingNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPrepressPictureEditingNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrepressPictureEditingNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrepressPictureEditingNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrepressPictureEditingNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrepressPictureEditingNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPrepressPictureEditingNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPrepressPictureEditingOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPrepressPictureEditingUpdateInput = {
  /** Actions for the "MetadataPrepressPictureEditing.archive" relation */
  archive?: InputMaybe<MetadataPrepressPictureEditingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPrepressPictureEditing.metadata" relation */
  metadata?: InputMaybe<MetadataPrepressPictureEditingNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPrepressPictureEditing.photo" relation */
  photo?: InputMaybe<MetadataPrepressPictureEditingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPrepressPictureEditing" nodes by specifying some conditions */
export type MetadataPrepressPictureEditingWhereInput = {
  AND?: InputMaybe<Array<MetadataPrepressPictureEditingWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
  OR?: InputMaybe<Array<MetadataPrepressPictureEditingWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPrepressPictureEditing" node. */
export type MetadataPrepressPictureEditingWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPrepressPictureEditingWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPrepressPictureEditing.metadata" relation */
  metadata: MetadataPrepressPictureEditingNestedMetadataCreateInput;
  /** Actions for the "MetadataPrepressPictureEditing.photo" relation */
  photo: MetadataPrepressPictureEditingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrepressPictureEditingWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPrepressPictureEditing.metadata" relation */
  metadata?: InputMaybe<MetadataPrepressPictureEditingNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPrepressPictureEditing.photo" relation */
  photo?: InputMaybe<MetadataPrepressPictureEditingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrepressPictureEditingWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPrepressPictureEditing.archive" relation */
  archive: MetadataPrepressPictureEditingNestedArchiveCreateInput;
  /** Actions for the "MetadataPrepressPictureEditing.photo" relation */
  photo: MetadataPrepressPictureEditingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrepressPictureEditingWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPrepressPictureEditing.archive" relation */
  archive?: InputMaybe<MetadataPrepressPictureEditingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPrepressPictureEditing.photo" relation */
  photo?: InputMaybe<MetadataPrepressPictureEditingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrepressPictureEditingWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPrepressPictureEditing.archive" relation */
  archive: MetadataPrepressPictureEditingNestedArchiveCreateInput;
  /** Actions for the "MetadataPrepressPictureEditing.metadata" relation */
  metadata: MetadataPrepressPictureEditingNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrepressPictureEditingWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPrepressPictureEditing.archive" relation */
  archive?: InputMaybe<MetadataPrepressPictureEditingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPrepressPictureEditing.metadata" relation */
  metadata?: InputMaybe<MetadataPrepressPictureEditingNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPriceLevel" resource's node */
export type MetadataPriceLevel = {
  __typename?: 'MetadataPriceLevel';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPriceLevelCreateInput = {
  /** Actions for the "MetadataPriceLevel.archive" relation */
  archive: MetadataPriceLevelNestedArchiveCreateInput;
  /** Actions for the "MetadataPriceLevel.metadata" relation */
  metadata: MetadataPriceLevelNestedMetadataCreateInput;
  /** Actions for the "MetadataPriceLevel.photo" relation */
  photo: MetadataPriceLevelNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPriceLevelNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.archive" relation. */
  update?: InputMaybe<MetadataPriceLevelNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.archive" relation. */
  upsert?: InputMaybe<MetadataPriceLevelNestedUpsertArchiveCreateInput>;
};

export type MetadataPriceLevelNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.archive" relation. */
  update?: InputMaybe<MetadataPriceLevelNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.archive" relation. */
  upsert?: InputMaybe<MetadataPriceLevelNestedUpsertArchiveUpdateInput>;
};

export type MetadataPriceLevelNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.metadata" relation. */
  update?: InputMaybe<MetadataPriceLevelNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.metadata" relation. */
  upsert?: InputMaybe<MetadataPriceLevelNestedUpsertMetadataCreateInput>;
};

export type MetadataPriceLevelNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.metadata" relation. */
  update?: InputMaybe<MetadataPriceLevelNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.metadata" relation. */
  upsert?: InputMaybe<MetadataPriceLevelNestedUpsertMetadataUpdateInput>;
};

export type MetadataPriceLevelNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.photo" relation. */
  update?: InputMaybe<MetadataPriceLevelNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPriceLevel" node, through the "MetadataPriceLevel.photo" relation. */
  upsert?: InputMaybe<MetadataPriceLevelNestedUpsertPhotoCreateInput>;
};

export type MetadataPriceLevelNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.photo" relation. */
  update?: InputMaybe<MetadataPriceLevelNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPriceLevel" node, through the "MetadataPriceLevel.photo" relation. */
  upsert?: InputMaybe<MetadataPriceLevelNestedUpsertPhotoUpdateInput>;
};

export type MetadataPriceLevelNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPriceLevelNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPriceLevelNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPriceLevelNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPriceLevelNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPriceLevelNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPriceLevelNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPriceLevelNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPriceLevelNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPriceLevelNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPriceLevelNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPriceLevelNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPriceLevelOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPriceLevelUpdateInput = {
  /** Actions for the "MetadataPriceLevel.archive" relation */
  archive?: InputMaybe<MetadataPriceLevelNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPriceLevel.metadata" relation */
  metadata?: InputMaybe<MetadataPriceLevelNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPriceLevel.photo" relation */
  photo?: InputMaybe<MetadataPriceLevelNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPriceLevel" nodes by specifying some conditions */
export type MetadataPriceLevelWhereInput = {
  AND?: InputMaybe<Array<MetadataPriceLevelWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPriceLevelWhereInput>;
  OR?: InputMaybe<Array<MetadataPriceLevelWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPriceLevel" node. */
export type MetadataPriceLevelWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPriceLevelWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPriceLevel.metadata" relation */
  metadata: MetadataPriceLevelNestedMetadataCreateInput;
  /** Actions for the "MetadataPriceLevel.photo" relation */
  photo: MetadataPriceLevelNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPriceLevelWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPriceLevel.metadata" relation */
  metadata?: InputMaybe<MetadataPriceLevelNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPriceLevel.photo" relation */
  photo?: InputMaybe<MetadataPriceLevelNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPriceLevelWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPriceLevel.archive" relation */
  archive: MetadataPriceLevelNestedArchiveCreateInput;
  /** Actions for the "MetadataPriceLevel.photo" relation */
  photo: MetadataPriceLevelNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPriceLevelWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPriceLevel.archive" relation */
  archive?: InputMaybe<MetadataPriceLevelNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPriceLevel.photo" relation */
  photo?: InputMaybe<MetadataPriceLevelNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPriceLevelWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPriceLevel.archive" relation */
  archive: MetadataPriceLevelNestedArchiveCreateInput;
  /** Actions for the "MetadataPriceLevel.metadata" relation */
  metadata: MetadataPriceLevelNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPriceLevelWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPriceLevel.archive" relation */
  archive?: InputMaybe<MetadataPriceLevelNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPriceLevel.metadata" relation */
  metadata?: InputMaybe<MetadataPriceLevelNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPrintingProfile" resource's node */
export type MetadataPrintingProfile = {
  __typename?: 'MetadataPrintingProfile';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPrintingProfileCreateInput = {
  /** Actions for the "MetadataPrintingProfile.archive" relation */
  archive: MetadataPrintingProfileNestedArchiveCreateInput;
  /** Actions for the "MetadataPrintingProfile.metadata" relation */
  metadata: MetadataPrintingProfileNestedMetadataCreateInput;
  /** Actions for the "MetadataPrintingProfile.photo" relation */
  photo: MetadataPrintingProfileNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrintingProfileNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.archive" relation. */
  update?: InputMaybe<MetadataPrintingProfileNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.archive" relation. */
  upsert?: InputMaybe<MetadataPrintingProfileNestedUpsertArchiveCreateInput>;
};

export type MetadataPrintingProfileNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.archive" relation. */
  update?: InputMaybe<MetadataPrintingProfileNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.archive" relation. */
  upsert?: InputMaybe<MetadataPrintingProfileNestedUpsertArchiveUpdateInput>;
};

export type MetadataPrintingProfileNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.metadata" relation. */
  update?: InputMaybe<MetadataPrintingProfileNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.metadata" relation. */
  upsert?: InputMaybe<MetadataPrintingProfileNestedUpsertMetadataCreateInput>;
};

export type MetadataPrintingProfileNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.metadata" relation. */
  update?: InputMaybe<MetadataPrintingProfileNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.metadata" relation. */
  upsert?: InputMaybe<MetadataPrintingProfileNestedUpsertMetadataUpdateInput>;
};

export type MetadataPrintingProfileNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.photo" relation. */
  update?: InputMaybe<MetadataPrintingProfileNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.photo" relation. */
  upsert?: InputMaybe<MetadataPrintingProfileNestedUpsertPhotoCreateInput>;
};

export type MetadataPrintingProfileNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.photo" relation. */
  update?: InputMaybe<MetadataPrintingProfileNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPrintingProfile" node, through the "MetadataPrintingProfile.photo" relation. */
  upsert?: InputMaybe<MetadataPrintingProfileNestedUpsertPhotoUpdateInput>;
};

export type MetadataPrintingProfileNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrintingProfileNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrintingProfileNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrintingProfileNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrintingProfileNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPrintingProfileNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPrintingProfileNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrintingProfileNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrintingProfileNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrintingProfileNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrintingProfileNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPrintingProfileNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPrintingProfileOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPrintingProfileUpdateInput = {
  /** Actions for the "MetadataPrintingProfile.archive" relation */
  archive?: InputMaybe<MetadataPrintingProfileNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPrintingProfile.metadata" relation */
  metadata?: InputMaybe<MetadataPrintingProfileNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPrintingProfile.photo" relation */
  photo?: InputMaybe<MetadataPrintingProfileNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPrintingProfile" nodes by specifying some conditions */
export type MetadataPrintingProfileWhereInput = {
  AND?: InputMaybe<Array<MetadataPrintingProfileWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPrintingProfileWhereInput>;
  OR?: InputMaybe<Array<MetadataPrintingProfileWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPrintingProfile" node. */
export type MetadataPrintingProfileWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPrintingProfileWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPrintingProfile.metadata" relation */
  metadata: MetadataPrintingProfileNestedMetadataCreateInput;
  /** Actions for the "MetadataPrintingProfile.photo" relation */
  photo: MetadataPrintingProfileNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrintingProfileWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPrintingProfile.metadata" relation */
  metadata?: InputMaybe<MetadataPrintingProfileNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPrintingProfile.photo" relation */
  photo?: InputMaybe<MetadataPrintingProfileNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrintingProfileWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPrintingProfile.archive" relation */
  archive: MetadataPrintingProfileNestedArchiveCreateInput;
  /** Actions for the "MetadataPrintingProfile.photo" relation */
  photo: MetadataPrintingProfileNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrintingProfileWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPrintingProfile.archive" relation */
  archive?: InputMaybe<MetadataPrintingProfileNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPrintingProfile.photo" relation */
  photo?: InputMaybe<MetadataPrintingProfileNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrintingProfileWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPrintingProfile.archive" relation */
  archive: MetadataPrintingProfileNestedArchiveCreateInput;
  /** Actions for the "MetadataPrintingProfile.metadata" relation */
  metadata: MetadataPrintingProfileNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrintingProfileWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPrintingProfile.archive" relation */
  archive?: InputMaybe<MetadataPrintingProfileNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPrintingProfile.metadata" relation */
  metadata?: InputMaybe<MetadataPrintingProfileNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPrismaProduction" resource's node */
export type MetadataPrismaProduction = {
  __typename?: 'MetadataPrismaProduction';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPrismaProductionCreateInput = {
  /** Actions for the "MetadataPrismaProduction.archive" relation */
  archive: MetadataPrismaProductionNestedArchiveCreateInput;
  /** Actions for the "MetadataPrismaProduction.metadata" relation */
  metadata: MetadataPrismaProductionNestedMetadataCreateInput;
  /** Actions for the "MetadataPrismaProduction.photo" relation */
  photo: MetadataPrismaProductionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrismaProductionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.archive" relation. */
  update?: InputMaybe<MetadataPrismaProductionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.archive" relation. */
  upsert?: InputMaybe<MetadataPrismaProductionNestedUpsertArchiveCreateInput>;
};

export type MetadataPrismaProductionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.archive" relation. */
  update?: InputMaybe<MetadataPrismaProductionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.archive" relation. */
  upsert?: InputMaybe<MetadataPrismaProductionNestedUpsertArchiveUpdateInput>;
};

export type MetadataPrismaProductionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.metadata" relation. */
  update?: InputMaybe<MetadataPrismaProductionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.metadata" relation. */
  upsert?: InputMaybe<MetadataPrismaProductionNestedUpsertMetadataCreateInput>;
};

export type MetadataPrismaProductionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.metadata" relation. */
  update?: InputMaybe<MetadataPrismaProductionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.metadata" relation. */
  upsert?: InputMaybe<MetadataPrismaProductionNestedUpsertMetadataUpdateInput>;
};

export type MetadataPrismaProductionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.photo" relation. */
  update?: InputMaybe<MetadataPrismaProductionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.photo" relation. */
  upsert?: InputMaybe<MetadataPrismaProductionNestedUpsertPhotoCreateInput>;
};

export type MetadataPrismaProductionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.photo" relation. */
  update?: InputMaybe<MetadataPrismaProductionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPrismaProduction" node, through the "MetadataPrismaProduction.photo" relation. */
  upsert?: InputMaybe<MetadataPrismaProductionNestedUpsertPhotoUpdateInput>;
};

export type MetadataPrismaProductionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrismaProductionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrismaProductionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrismaProductionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrismaProductionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPrismaProductionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPrismaProductionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrismaProductionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPrismaProductionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrismaProductionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPrismaProductionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPrismaProductionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPrismaProductionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPrismaProductionUpdateInput = {
  /** Actions for the "MetadataPrismaProduction.archive" relation */
  archive?: InputMaybe<MetadataPrismaProductionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPrismaProduction.metadata" relation */
  metadata?: InputMaybe<MetadataPrismaProductionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPrismaProduction.photo" relation */
  photo?: InputMaybe<MetadataPrismaProductionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPrismaProduction" nodes by specifying some conditions */
export type MetadataPrismaProductionWhereInput = {
  AND?: InputMaybe<Array<MetadataPrismaProductionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPrismaProductionWhereInput>;
  OR?: InputMaybe<Array<MetadataPrismaProductionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPrismaProduction" node. */
export type MetadataPrismaProductionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPrismaProductionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPrismaProduction.metadata" relation */
  metadata: MetadataPrismaProductionNestedMetadataCreateInput;
  /** Actions for the "MetadataPrismaProduction.photo" relation */
  photo: MetadataPrismaProductionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrismaProductionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPrismaProduction.metadata" relation */
  metadata?: InputMaybe<MetadataPrismaProductionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPrismaProduction.photo" relation */
  photo?: InputMaybe<MetadataPrismaProductionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrismaProductionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPrismaProduction.archive" relation */
  archive: MetadataPrismaProductionNestedArchiveCreateInput;
  /** Actions for the "MetadataPrismaProduction.photo" relation */
  photo: MetadataPrismaProductionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrismaProductionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPrismaProduction.archive" relation */
  archive?: InputMaybe<MetadataPrismaProductionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPrismaProduction.photo" relation */
  photo?: InputMaybe<MetadataPrismaProductionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrismaProductionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPrismaProduction.archive" relation */
  archive: MetadataPrismaProductionNestedArchiveCreateInput;
  /** Actions for the "MetadataPrismaProduction.metadata" relation */
  metadata: MetadataPrismaProductionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPrismaProductionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPrismaProduction.archive" relation */
  archive?: InputMaybe<MetadataPrismaProductionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPrismaProduction.metadata" relation */
  metadata?: InputMaybe<MetadataPrismaProductionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataProcessHistory" resource's node */
export type MetadataProcessHistory = {
  __typename?: 'MetadataProcessHistory';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataProcessHistoryCreateInput = {
  /** Actions for the "MetadataProcessHistory.archive" relation */
  archive: MetadataProcessHistoryNestedArchiveCreateInput;
  /** Actions for the "MetadataProcessHistory.metadata" relation */
  metadata: MetadataProcessHistoryNestedMetadataCreateInput;
  /** Actions for the "MetadataProcessHistory.photo" relation */
  photo: MetadataProcessHistoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessHistoryNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.archive" relation. */
  update?: InputMaybe<MetadataProcessHistoryNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.archive" relation. */
  upsert?: InputMaybe<MetadataProcessHistoryNestedUpsertArchiveCreateInput>;
};

export type MetadataProcessHistoryNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.archive" relation. */
  update?: InputMaybe<MetadataProcessHistoryNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.archive" relation. */
  upsert?: InputMaybe<MetadataProcessHistoryNestedUpsertArchiveUpdateInput>;
};

export type MetadataProcessHistoryNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.metadata" relation. */
  update?: InputMaybe<MetadataProcessHistoryNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.metadata" relation. */
  upsert?: InputMaybe<MetadataProcessHistoryNestedUpsertMetadataCreateInput>;
};

export type MetadataProcessHistoryNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.metadata" relation. */
  update?: InputMaybe<MetadataProcessHistoryNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.metadata" relation. */
  upsert?: InputMaybe<MetadataProcessHistoryNestedUpsertMetadataUpdateInput>;
};

export type MetadataProcessHistoryNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.photo" relation. */
  update?: InputMaybe<MetadataProcessHistoryNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataProcessHistory" node, through the "MetadataProcessHistory.photo" relation. */
  upsert?: InputMaybe<MetadataProcessHistoryNestedUpsertPhotoCreateInput>;
};

export type MetadataProcessHistoryNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.photo" relation. */
  update?: InputMaybe<MetadataProcessHistoryNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataProcessHistory" node, through the "MetadataProcessHistory.photo" relation. */
  upsert?: InputMaybe<MetadataProcessHistoryNestedUpsertPhotoUpdateInput>;
};

export type MetadataProcessHistoryNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessHistoryNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessHistoryNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessHistoryNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessHistoryNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProcessHistoryNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProcessHistoryNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessHistoryNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessHistoryNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessHistoryNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessHistoryNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProcessHistoryNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataProcessHistoryOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataProcessHistoryUpdateInput = {
  /** Actions for the "MetadataProcessHistory.archive" relation */
  archive?: InputMaybe<MetadataProcessHistoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProcessHistory.metadata" relation */
  metadata?: InputMaybe<MetadataProcessHistoryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProcessHistory.photo" relation */
  photo?: InputMaybe<MetadataProcessHistoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataProcessHistory" nodes by specifying some conditions */
export type MetadataProcessHistoryWhereInput = {
  AND?: InputMaybe<Array<MetadataProcessHistoryWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataProcessHistoryWhereInput>;
  OR?: InputMaybe<Array<MetadataProcessHistoryWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataProcessHistory" node. */
export type MetadataProcessHistoryWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataProcessHistoryWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataProcessHistory.metadata" relation */
  metadata: MetadataProcessHistoryNestedMetadataCreateInput;
  /** Actions for the "MetadataProcessHistory.photo" relation */
  photo: MetadataProcessHistoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessHistoryWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataProcessHistory.metadata" relation */
  metadata?: InputMaybe<MetadataProcessHistoryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProcessHistory.photo" relation */
  photo?: InputMaybe<MetadataProcessHistoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessHistoryWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataProcessHistory.archive" relation */
  archive: MetadataProcessHistoryNestedArchiveCreateInput;
  /** Actions for the "MetadataProcessHistory.photo" relation */
  photo: MetadataProcessHistoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessHistoryWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataProcessHistory.archive" relation */
  archive?: InputMaybe<MetadataProcessHistoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProcessHistory.photo" relation */
  photo?: InputMaybe<MetadataProcessHistoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessHistoryWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataProcessHistory.archive" relation */
  archive: MetadataProcessHistoryNestedArchiveCreateInput;
  /** Actions for the "MetadataProcessHistory.metadata" relation */
  metadata: MetadataProcessHistoryNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessHistoryWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataProcessHistory.archive" relation */
  archive?: InputMaybe<MetadataProcessHistoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProcessHistory.metadata" relation */
  metadata?: InputMaybe<MetadataProcessHistoryNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataProcessParameter" resource's node */
export type MetadataProcessParameter = {
  __typename?: 'MetadataProcessParameter';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataProcessParameterCreateInput = {
  /** Actions for the "MetadataProcessParameter.archive" relation */
  archive: MetadataProcessParameterNestedArchiveCreateInput;
  /** Actions for the "MetadataProcessParameter.metadata" relation */
  metadata: MetadataProcessParameterNestedMetadataCreateInput;
  /** Actions for the "MetadataProcessParameter.photo" relation */
  photo: MetadataProcessParameterNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessParameterNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.archive" relation. */
  update?: InputMaybe<MetadataProcessParameterNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.archive" relation. */
  upsert?: InputMaybe<MetadataProcessParameterNestedUpsertArchiveCreateInput>;
};

export type MetadataProcessParameterNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.archive" relation. */
  update?: InputMaybe<MetadataProcessParameterNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.archive" relation. */
  upsert?: InputMaybe<MetadataProcessParameterNestedUpsertArchiveUpdateInput>;
};

export type MetadataProcessParameterNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.metadata" relation. */
  update?: InputMaybe<MetadataProcessParameterNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.metadata" relation. */
  upsert?: InputMaybe<MetadataProcessParameterNestedUpsertMetadataCreateInput>;
};

export type MetadataProcessParameterNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.metadata" relation. */
  update?: InputMaybe<MetadataProcessParameterNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.metadata" relation. */
  upsert?: InputMaybe<MetadataProcessParameterNestedUpsertMetadataUpdateInput>;
};

export type MetadataProcessParameterNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.photo" relation. */
  update?: InputMaybe<MetadataProcessParameterNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataProcessParameter" node, through the "MetadataProcessParameter.photo" relation. */
  upsert?: InputMaybe<MetadataProcessParameterNestedUpsertPhotoCreateInput>;
};

export type MetadataProcessParameterNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.photo" relation. */
  update?: InputMaybe<MetadataProcessParameterNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataProcessParameter" node, through the "MetadataProcessParameter.photo" relation. */
  upsert?: InputMaybe<MetadataProcessParameterNestedUpsertPhotoUpdateInput>;
};

export type MetadataProcessParameterNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessParameterNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessParameterNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessParameterNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessParameterNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProcessParameterNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProcessParameterNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessParameterNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessParameterNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessParameterNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessParameterNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProcessParameterNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataProcessParameterOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataProcessParameterUpdateInput = {
  /** Actions for the "MetadataProcessParameter.archive" relation */
  archive?: InputMaybe<MetadataProcessParameterNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProcessParameter.metadata" relation */
  metadata?: InputMaybe<MetadataProcessParameterNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProcessParameter.photo" relation */
  photo?: InputMaybe<MetadataProcessParameterNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataProcessParameter" nodes by specifying some conditions */
export type MetadataProcessParameterWhereInput = {
  AND?: InputMaybe<Array<MetadataProcessParameterWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataProcessParameterWhereInput>;
  OR?: InputMaybe<Array<MetadataProcessParameterWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataProcessParameter" node. */
export type MetadataProcessParameterWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataProcessParameterWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataProcessParameter.metadata" relation */
  metadata: MetadataProcessParameterNestedMetadataCreateInput;
  /** Actions for the "MetadataProcessParameter.photo" relation */
  photo: MetadataProcessParameterNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessParameterWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataProcessParameter.metadata" relation */
  metadata?: InputMaybe<MetadataProcessParameterNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProcessParameter.photo" relation */
  photo?: InputMaybe<MetadataProcessParameterNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessParameterWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataProcessParameter.archive" relation */
  archive: MetadataProcessParameterNestedArchiveCreateInput;
  /** Actions for the "MetadataProcessParameter.photo" relation */
  photo: MetadataProcessParameterNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessParameterWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataProcessParameter.archive" relation */
  archive?: InputMaybe<MetadataProcessParameterNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProcessParameter.photo" relation */
  photo?: InputMaybe<MetadataProcessParameterNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessParameterWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataProcessParameter.archive" relation */
  archive: MetadataProcessParameterNestedArchiveCreateInput;
  /** Actions for the "MetadataProcessParameter.metadata" relation */
  metadata: MetadataProcessParameterNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessParameterWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataProcessParameter.archive" relation */
  archive?: InputMaybe<MetadataProcessParameterNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProcessParameter.metadata" relation */
  metadata?: InputMaybe<MetadataProcessParameterNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataProcessStatus" resource's node */
export type MetadataProcessStatus = {
  __typename?: 'MetadataProcessStatus';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataProcessStatusCreateInput = {
  /** Actions for the "MetadataProcessStatus.archive" relation */
  archive: MetadataProcessStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataProcessStatus.metadata" relation */
  metadata: MetadataProcessStatusNestedMetadataCreateInput;
  /** Actions for the "MetadataProcessStatus.photo" relation */
  photo: MetadataProcessStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessStatusNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.archive" relation. */
  update?: InputMaybe<MetadataProcessStatusNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.archive" relation. */
  upsert?: InputMaybe<MetadataProcessStatusNestedUpsertArchiveCreateInput>;
};

export type MetadataProcessStatusNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.archive" relation. */
  update?: InputMaybe<MetadataProcessStatusNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.archive" relation. */
  upsert?: InputMaybe<MetadataProcessStatusNestedUpsertArchiveUpdateInput>;
};

export type MetadataProcessStatusNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.metadata" relation. */
  update?: InputMaybe<MetadataProcessStatusNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.metadata" relation. */
  upsert?: InputMaybe<MetadataProcessStatusNestedUpsertMetadataCreateInput>;
};

export type MetadataProcessStatusNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.metadata" relation. */
  update?: InputMaybe<MetadataProcessStatusNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.metadata" relation. */
  upsert?: InputMaybe<MetadataProcessStatusNestedUpsertMetadataUpdateInput>;
};

export type MetadataProcessStatusNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.photo" relation. */
  update?: InputMaybe<MetadataProcessStatusNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataProcessStatus" node, through the "MetadataProcessStatus.photo" relation. */
  upsert?: InputMaybe<MetadataProcessStatusNestedUpsertPhotoCreateInput>;
};

export type MetadataProcessStatusNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.photo" relation. */
  update?: InputMaybe<MetadataProcessStatusNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataProcessStatus" node, through the "MetadataProcessStatus.photo" relation. */
  upsert?: InputMaybe<MetadataProcessStatusNestedUpsertPhotoUpdateInput>;
};

export type MetadataProcessStatusNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessStatusNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessStatusNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessStatusNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessStatusNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProcessStatusNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProcessStatusNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessStatusNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProcessStatusNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessStatusNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProcessStatusNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProcessStatusNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataProcessStatusOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataProcessStatusUpdateInput = {
  /** Actions for the "MetadataProcessStatus.archive" relation */
  archive?: InputMaybe<MetadataProcessStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProcessStatus.metadata" relation */
  metadata?: InputMaybe<MetadataProcessStatusNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProcessStatus.photo" relation */
  photo?: InputMaybe<MetadataProcessStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataProcessStatus" nodes by specifying some conditions */
export type MetadataProcessStatusWhereInput = {
  AND?: InputMaybe<Array<MetadataProcessStatusWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataProcessStatusWhereInput>;
  OR?: InputMaybe<Array<MetadataProcessStatusWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataProcessStatus" node. */
export type MetadataProcessStatusWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataProcessStatusWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataProcessStatus.metadata" relation */
  metadata: MetadataProcessStatusNestedMetadataCreateInput;
  /** Actions for the "MetadataProcessStatus.photo" relation */
  photo: MetadataProcessStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessStatusWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataProcessStatus.metadata" relation */
  metadata?: InputMaybe<MetadataProcessStatusNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProcessStatus.photo" relation */
  photo?: InputMaybe<MetadataProcessStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessStatusWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataProcessStatus.archive" relation */
  archive: MetadataProcessStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataProcessStatus.photo" relation */
  photo: MetadataProcessStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessStatusWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataProcessStatus.archive" relation */
  archive?: InputMaybe<MetadataProcessStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProcessStatus.photo" relation */
  photo?: InputMaybe<MetadataProcessStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessStatusWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataProcessStatus.archive" relation */
  archive: MetadataProcessStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataProcessStatus.metadata" relation */
  metadata: MetadataProcessStatusNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProcessStatusWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataProcessStatus.archive" relation */
  archive?: InputMaybe<MetadataProcessStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProcessStatus.metadata" relation */
  metadata?: InputMaybe<MetadataProcessStatusNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataProduct" resource's node */
export type MetadataProduct = {
  __typename?: 'MetadataProduct';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataProductCreateInput = {
  /** Actions for the "MetadataProduct.archive" relation */
  archive: MetadataProductNestedArchiveCreateInput;
  /** Actions for the "MetadataProduct.metadata" relation */
  metadata: MetadataProductNestedMetadataCreateInput;
  /** Actions for the "MetadataProduct.photo" relation */
  photo: MetadataProductNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataProduct" node, through the "MetadataProduct.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataProduct" node, through the "MetadataProduct.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataProduct" node, through the "MetadataProduct.archive" relation. */
  update?: InputMaybe<MetadataProductNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataProduct" node, through the "MetadataProduct.archive" relation. */
  upsert?: InputMaybe<MetadataProductNestedUpsertArchiveCreateInput>;
};

export type MetadataProductNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataProduct" node, through the "MetadataProduct.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataProduct" node, through the "MetadataProduct.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataProduct" node, through the "MetadataProduct.archive" relation. */
  update?: InputMaybe<MetadataProductNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataProduct" node, through the "MetadataProduct.archive" relation. */
  upsert?: InputMaybe<MetadataProductNestedUpsertArchiveUpdateInput>;
};

export type MetadataProductNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataProduct" node, through the "MetadataProduct.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataProduct" node, through the "MetadataProduct.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataProduct" node, through the "MetadataProduct.metadata" relation. */
  update?: InputMaybe<MetadataProductNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataProduct" node, through the "MetadataProduct.metadata" relation. */
  upsert?: InputMaybe<MetadataProductNestedUpsertMetadataCreateInput>;
};

export type MetadataProductNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataProduct" node, through the "MetadataProduct.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataProduct" node, through the "MetadataProduct.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataProduct" node, through the "MetadataProduct.metadata" relation. */
  update?: InputMaybe<MetadataProductNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataProduct" node, through the "MetadataProduct.metadata" relation. */
  upsert?: InputMaybe<MetadataProductNestedUpsertMetadataUpdateInput>;
};

export type MetadataProductNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataProduct" node, through the "MetadataProduct.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataProduct" node, through the "MetadataProduct.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataProduct" node, through the "MetadataProduct.photo" relation. */
  update?: InputMaybe<MetadataProductNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataProduct" node, through the "MetadataProduct.photo" relation. */
  upsert?: InputMaybe<MetadataProductNestedUpsertPhotoCreateInput>;
};

export type MetadataProductNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataProduct" node, through the "MetadataProduct.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataProduct" node, through the "MetadataProduct.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataProduct" node, through the "MetadataProduct.photo" relation. */
  update?: InputMaybe<MetadataProductNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataProduct" node, through the "MetadataProduct.photo" relation. */
  upsert?: InputMaybe<MetadataProductNestedUpsertPhotoUpdateInput>;
};

export type MetadataProductNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProductNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProductNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProductNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataProductOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

/** "MetadataProducts" resource's node */
export type MetadataProducts = {
  __typename?: 'MetadataProducts';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataProductsCreateInput = {
  /** Actions for the "MetadataProducts.archive" relation */
  archive: MetadataProductsNestedArchiveCreateInput;
  /** Actions for the "MetadataProducts.metadata" relation */
  metadata: MetadataProductsNestedMetadataCreateInput;
  /** Actions for the "MetadataProducts.photo" relation */
  photo: MetadataProductsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataProductShortName" resource's node */
export type MetadataProductShortName = {
  __typename?: 'MetadataProductShortName';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataProductShortNameCreateInput = {
  /** Actions for the "MetadataProductShortName.archive" relation */
  archive: MetadataProductShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataProductShortName.metadata" relation */
  metadata: MetadataProductShortNameNestedMetadataCreateInput;
  /** Actions for the "MetadataProductShortName.photo" relation */
  photo: MetadataProductShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductShortNameNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataProductShortName" node, through the "MetadataProductShortName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataProductShortName" node, through the "MetadataProductShortName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataProductShortName" node, through the "MetadataProductShortName.archive" relation. */
  update?: InputMaybe<MetadataProductShortNameNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataProductShortName" node, through the "MetadataProductShortName.archive" relation. */
  upsert?: InputMaybe<MetadataProductShortNameNestedUpsertArchiveCreateInput>;
};

export type MetadataProductShortNameNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.archive" relation. */
  update?: InputMaybe<MetadataProductShortNameNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.archive" relation. */
  upsert?: InputMaybe<MetadataProductShortNameNestedUpsertArchiveUpdateInput>;
};

export type MetadataProductShortNameNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataProductShortName" node, through the "MetadataProductShortName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataProductShortName" node, through the "MetadataProductShortName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataProductShortName" node, through the "MetadataProductShortName.metadata" relation. */
  update?: InputMaybe<MetadataProductShortNameNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataProductShortName" node, through the "MetadataProductShortName.metadata" relation. */
  upsert?: InputMaybe<MetadataProductShortNameNestedUpsertMetadataCreateInput>;
};

export type MetadataProductShortNameNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.metadata" relation. */
  update?: InputMaybe<MetadataProductShortNameNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.metadata" relation. */
  upsert?: InputMaybe<MetadataProductShortNameNestedUpsertMetadataUpdateInput>;
};

export type MetadataProductShortNameNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataProductShortName" node, through the "MetadataProductShortName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataProductShortName" node, through the "MetadataProductShortName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataProductShortName" node, through the "MetadataProductShortName.photo" relation. */
  update?: InputMaybe<MetadataProductShortNameNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataProductShortName" node, through the "MetadataProductShortName.photo" relation. */
  upsert?: InputMaybe<MetadataProductShortNameNestedUpsertPhotoCreateInput>;
};

export type MetadataProductShortNameNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.photo" relation. */
  update?: InputMaybe<MetadataProductShortNameNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataProductShortName" node, through the "MetadataProductShortName.photo" relation. */
  upsert?: InputMaybe<MetadataProductShortNameNestedUpsertPhotoUpdateInput>;
};

export type MetadataProductShortNameNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductShortNameNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductShortNameNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductShortNameNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductShortNameNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProductShortNameNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProductShortNameNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductShortNameNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductShortNameNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductShortNameNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductShortNameNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProductShortNameNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataProductShortNameOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataProductShortNameUpdateInput = {
  /** Actions for the "MetadataProductShortName.archive" relation */
  archive?: InputMaybe<MetadataProductShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProductShortName.metadata" relation */
  metadata?: InputMaybe<MetadataProductShortNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProductShortName.photo" relation */
  photo?: InputMaybe<MetadataProductShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataProductShortName" nodes by specifying some conditions */
export type MetadataProductShortNameWhereInput = {
  AND?: InputMaybe<Array<MetadataProductShortNameWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataProductShortNameWhereInput>;
  OR?: InputMaybe<Array<MetadataProductShortNameWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataProductShortName" node. */
export type MetadataProductShortNameWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataProductShortNameWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataProductShortName.metadata" relation */
  metadata: MetadataProductShortNameNestedMetadataCreateInput;
  /** Actions for the "MetadataProductShortName.photo" relation */
  photo: MetadataProductShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductShortNameWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataProductShortName.metadata" relation */
  metadata?: InputMaybe<MetadataProductShortNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProductShortName.photo" relation */
  photo?: InputMaybe<MetadataProductShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductShortNameWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataProductShortName.archive" relation */
  archive: MetadataProductShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataProductShortName.photo" relation */
  photo: MetadataProductShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductShortNameWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataProductShortName.archive" relation */
  archive?: InputMaybe<MetadataProductShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProductShortName.photo" relation */
  photo?: InputMaybe<MetadataProductShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductShortNameWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataProductShortName.archive" relation */
  archive: MetadataProductShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataProductShortName.metadata" relation */
  metadata: MetadataProductShortNameNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductShortNameWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataProductShortName.archive" relation */
  archive?: InputMaybe<MetadataProductShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProductShortName.metadata" relation */
  metadata?: InputMaybe<MetadataProductShortNameNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductsNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataProducts" node, through the "MetadataProducts.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataProducts" node, through the "MetadataProducts.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataProducts" node, through the "MetadataProducts.archive" relation. */
  update?: InputMaybe<MetadataProductsNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataProducts" node, through the "MetadataProducts.archive" relation. */
  upsert?: InputMaybe<MetadataProductsNestedUpsertArchiveCreateInput>;
};

export type MetadataProductsNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataProducts" node, through the "MetadataProducts.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataProducts" node, through the "MetadataProducts.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataProducts" node, through the "MetadataProducts.archive" relation. */
  update?: InputMaybe<MetadataProductsNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataProducts" node, through the "MetadataProducts.archive" relation. */
  upsert?: InputMaybe<MetadataProductsNestedUpsertArchiveUpdateInput>;
};

export type MetadataProductsNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataProducts" node, through the "MetadataProducts.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataProducts" node, through the "MetadataProducts.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataProducts" node, through the "MetadataProducts.metadata" relation. */
  update?: InputMaybe<MetadataProductsNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataProducts" node, through the "MetadataProducts.metadata" relation. */
  upsert?: InputMaybe<MetadataProductsNestedUpsertMetadataCreateInput>;
};

export type MetadataProductsNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataProducts" node, through the "MetadataProducts.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataProducts" node, through the "MetadataProducts.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataProducts" node, through the "MetadataProducts.metadata" relation. */
  update?: InputMaybe<MetadataProductsNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataProducts" node, through the "MetadataProducts.metadata" relation. */
  upsert?: InputMaybe<MetadataProductsNestedUpsertMetadataUpdateInput>;
};

export type MetadataProductsNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataProducts" node, through the "MetadataProducts.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataProducts" node, through the "MetadataProducts.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataProducts" node, through the "MetadataProducts.photo" relation. */
  update?: InputMaybe<MetadataProductsNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataProducts" node, through the "MetadataProducts.photo" relation. */
  upsert?: InputMaybe<MetadataProductsNestedUpsertPhotoCreateInput>;
};

export type MetadataProductsNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataProducts" node, through the "MetadataProducts.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataProducts" node, through the "MetadataProducts.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataProducts" node, through the "MetadataProducts.photo" relation. */
  update?: InputMaybe<MetadataProductsNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataProducts" node, through the "MetadataProducts.photo" relation. */
  upsert?: InputMaybe<MetadataProductsNestedUpsertPhotoUpdateInput>;
};

export type MetadataProductsNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductsNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductsNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductsNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductsNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProductsNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProductsNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductsNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProductsNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductsNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProductsNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProductsNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataProductsOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataProductsUpdateInput = {
  /** Actions for the "MetadataProducts.archive" relation */
  archive?: InputMaybe<MetadataProductsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProducts.metadata" relation */
  metadata?: InputMaybe<MetadataProductsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProducts.photo" relation */
  photo?: InputMaybe<MetadataProductsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataProducts" nodes by specifying some conditions */
export type MetadataProductsWhereInput = {
  AND?: InputMaybe<Array<MetadataProductsWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataProductsWhereInput>;
  OR?: InputMaybe<Array<MetadataProductsWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataProducts" node. */
export type MetadataProductsWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataProductsWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataProducts.metadata" relation */
  metadata: MetadataProductsNestedMetadataCreateInput;
  /** Actions for the "MetadataProducts.photo" relation */
  photo: MetadataProductsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductsWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataProducts.metadata" relation */
  metadata?: InputMaybe<MetadataProductsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProducts.photo" relation */
  photo?: InputMaybe<MetadataProductsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductsWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataProducts.archive" relation */
  archive: MetadataProductsNestedArchiveCreateInput;
  /** Actions for the "MetadataProducts.photo" relation */
  photo: MetadataProductsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductsWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataProducts.archive" relation */
  archive?: InputMaybe<MetadataProductsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProducts.photo" relation */
  photo?: InputMaybe<MetadataProductsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductsWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataProducts.archive" relation */
  archive: MetadataProductsNestedArchiveCreateInput;
  /** Actions for the "MetadataProducts.metadata" relation */
  metadata: MetadataProductsNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductsWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataProducts.archive" relation */
  archive?: InputMaybe<MetadataProductsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProducts.metadata" relation */
  metadata?: InputMaybe<MetadataProductsNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductUpdateInput = {
  /** Actions for the "MetadataProduct.archive" relation */
  archive?: InputMaybe<MetadataProductNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProduct.metadata" relation */
  metadata?: InputMaybe<MetadataProductNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProduct.photo" relation */
  photo?: InputMaybe<MetadataProductNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataProduct" nodes by specifying some conditions */
export type MetadataProductWhereInput = {
  AND?: InputMaybe<Array<MetadataProductWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataProductWhereInput>;
  OR?: InputMaybe<Array<MetadataProductWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataProduct" node. */
export type MetadataProductWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataProductWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataProduct.metadata" relation */
  metadata: MetadataProductNestedMetadataCreateInput;
  /** Actions for the "MetadataProduct.photo" relation */
  photo: MetadataProductNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataProduct.metadata" relation */
  metadata?: InputMaybe<MetadataProductNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProduct.photo" relation */
  photo?: InputMaybe<MetadataProductNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataProduct.archive" relation */
  archive: MetadataProductNestedArchiveCreateInput;
  /** Actions for the "MetadataProduct.photo" relation */
  photo: MetadataProductNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataProduct.archive" relation */
  archive?: InputMaybe<MetadataProductNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProduct.photo" relation */
  photo?: InputMaybe<MetadataProductNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataProduct.archive" relation */
  archive: MetadataProductNestedArchiveCreateInput;
  /** Actions for the "MetadataProduct.metadata" relation */
  metadata: MetadataProductNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProductWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataProduct.archive" relation */
  archive?: InputMaybe<MetadataProductNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProduct.metadata" relation */
  metadata?: InputMaybe<MetadataProductNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataProduits" resource's node */
export type MetadataProduits = {
  __typename?: 'MetadataProduits';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataProduitsCreateInput = {
  /** Actions for the "MetadataProduits.archive" relation */
  archive: MetadataProduitsNestedArchiveCreateInput;
  /** Actions for the "MetadataProduits.metadata" relation */
  metadata: MetadataProduitsNestedMetadataCreateInput;
  /** Actions for the "MetadataProduits.photo" relation */
  photo: MetadataProduitsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProduitsNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataProduits" node, through the "MetadataProduits.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataProduits" node, through the "MetadataProduits.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataProduits" node, through the "MetadataProduits.archive" relation. */
  update?: InputMaybe<MetadataProduitsNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataProduits" node, through the "MetadataProduits.archive" relation. */
  upsert?: InputMaybe<MetadataProduitsNestedUpsertArchiveCreateInput>;
};

export type MetadataProduitsNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataProduits" node, through the "MetadataProduits.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataProduits" node, through the "MetadataProduits.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataProduits" node, through the "MetadataProduits.archive" relation. */
  update?: InputMaybe<MetadataProduitsNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataProduits" node, through the "MetadataProduits.archive" relation. */
  upsert?: InputMaybe<MetadataProduitsNestedUpsertArchiveUpdateInput>;
};

export type MetadataProduitsNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataProduits" node, through the "MetadataProduits.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataProduits" node, through the "MetadataProduits.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataProduits" node, through the "MetadataProduits.metadata" relation. */
  update?: InputMaybe<MetadataProduitsNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataProduits" node, through the "MetadataProduits.metadata" relation. */
  upsert?: InputMaybe<MetadataProduitsNestedUpsertMetadataCreateInput>;
};

export type MetadataProduitsNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataProduits" node, through the "MetadataProduits.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataProduits" node, through the "MetadataProduits.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataProduits" node, through the "MetadataProduits.metadata" relation. */
  update?: InputMaybe<MetadataProduitsNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataProduits" node, through the "MetadataProduits.metadata" relation. */
  upsert?: InputMaybe<MetadataProduitsNestedUpsertMetadataUpdateInput>;
};

export type MetadataProduitsNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataProduits" node, through the "MetadataProduits.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataProduits" node, through the "MetadataProduits.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataProduits" node, through the "MetadataProduits.photo" relation. */
  update?: InputMaybe<MetadataProduitsNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataProduits" node, through the "MetadataProduits.photo" relation. */
  upsert?: InputMaybe<MetadataProduitsNestedUpsertPhotoCreateInput>;
};

export type MetadataProduitsNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataProduits" node, through the "MetadataProduits.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataProduits" node, through the "MetadataProduits.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataProduits" node, through the "MetadataProduits.photo" relation. */
  update?: InputMaybe<MetadataProduitsNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataProduits" node, through the "MetadataProduits.photo" relation. */
  upsert?: InputMaybe<MetadataProduitsNestedUpsertPhotoUpdateInput>;
};

export type MetadataProduitsNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProduitsNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProduitsNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProduitsNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProduitsNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProduitsNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProduitsNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProduitsNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProduitsNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProduitsNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProduitsNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProduitsNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataProduitsOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataProduitsUpdateInput = {
  /** Actions for the "MetadataProduits.archive" relation */
  archive?: InputMaybe<MetadataProduitsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProduits.metadata" relation */
  metadata?: InputMaybe<MetadataProduitsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProduits.photo" relation */
  photo?: InputMaybe<MetadataProduitsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataProduits" nodes by specifying some conditions */
export type MetadataProduitsWhereInput = {
  AND?: InputMaybe<Array<MetadataProduitsWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataProduitsWhereInput>;
  OR?: InputMaybe<Array<MetadataProduitsWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataProduits" node. */
export type MetadataProduitsWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataProduitsWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataProduits.metadata" relation */
  metadata: MetadataProduitsNestedMetadataCreateInput;
  /** Actions for the "MetadataProduits.photo" relation */
  photo: MetadataProduitsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProduitsWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataProduits.metadata" relation */
  metadata?: InputMaybe<MetadataProduitsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProduits.photo" relation */
  photo?: InputMaybe<MetadataProduitsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProduitsWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataProduits.archive" relation */
  archive: MetadataProduitsNestedArchiveCreateInput;
  /** Actions for the "MetadataProduits.photo" relation */
  photo: MetadataProduitsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProduitsWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataProduits.archive" relation */
  archive?: InputMaybe<MetadataProduitsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProduits.photo" relation */
  photo?: InputMaybe<MetadataProduitsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProduitsWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataProduits.archive" relation */
  archive: MetadataProduitsNestedArchiveCreateInput;
  /** Actions for the "MetadataProduits.metadata" relation */
  metadata: MetadataProduitsNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProduitsWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataProduits.archive" relation */
  archive?: InputMaybe<MetadataProduitsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProduits.metadata" relation */
  metadata?: InputMaybe<MetadataProduitsNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataProgramVersion" resource's node */
export type MetadataProgramVersion = {
  __typename?: 'MetadataProgramVersion';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataProgramVersionCreateInput = {
  /** Actions for the "MetadataProgramVersion.archive" relation */
  archive: MetadataProgramVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataProgramVersion.metadata" relation */
  metadata: MetadataProgramVersionNestedMetadataCreateInput;
  /** Actions for the "MetadataProgramVersion.photo" relation */
  photo: MetadataProgramVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProgramVersionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.archive" relation. */
  update?: InputMaybe<MetadataProgramVersionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.archive" relation. */
  upsert?: InputMaybe<MetadataProgramVersionNestedUpsertArchiveCreateInput>;
};

export type MetadataProgramVersionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.archive" relation. */
  update?: InputMaybe<MetadataProgramVersionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.archive" relation. */
  upsert?: InputMaybe<MetadataProgramVersionNestedUpsertArchiveUpdateInput>;
};

export type MetadataProgramVersionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.metadata" relation. */
  update?: InputMaybe<MetadataProgramVersionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.metadata" relation. */
  upsert?: InputMaybe<MetadataProgramVersionNestedUpsertMetadataCreateInput>;
};

export type MetadataProgramVersionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.metadata" relation. */
  update?: InputMaybe<MetadataProgramVersionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.metadata" relation. */
  upsert?: InputMaybe<MetadataProgramVersionNestedUpsertMetadataUpdateInput>;
};

export type MetadataProgramVersionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.photo" relation. */
  update?: InputMaybe<MetadataProgramVersionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataProgramVersion" node, through the "MetadataProgramVersion.photo" relation. */
  upsert?: InputMaybe<MetadataProgramVersionNestedUpsertPhotoCreateInput>;
};

export type MetadataProgramVersionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.photo" relation. */
  update?: InputMaybe<MetadataProgramVersionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataProgramVersion" node, through the "MetadataProgramVersion.photo" relation. */
  upsert?: InputMaybe<MetadataProgramVersionNestedUpsertPhotoUpdateInput>;
};

export type MetadataProgramVersionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProgramVersionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProgramVersionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProgramVersionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProgramVersionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProgramVersionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProgramVersionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProgramVersionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataProgramVersionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProgramVersionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataProgramVersionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataProgramVersionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataProgramVersionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataProgramVersionUpdateInput = {
  /** Actions for the "MetadataProgramVersion.archive" relation */
  archive?: InputMaybe<MetadataProgramVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProgramVersion.metadata" relation */
  metadata?: InputMaybe<MetadataProgramVersionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProgramVersion.photo" relation */
  photo?: InputMaybe<MetadataProgramVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataProgramVersion" nodes by specifying some conditions */
export type MetadataProgramVersionWhereInput = {
  AND?: InputMaybe<Array<MetadataProgramVersionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataProgramVersionWhereInput>;
  OR?: InputMaybe<Array<MetadataProgramVersionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataProgramVersion" node. */
export type MetadataProgramVersionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataProgramVersionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataProgramVersion.metadata" relation */
  metadata: MetadataProgramVersionNestedMetadataCreateInput;
  /** Actions for the "MetadataProgramVersion.photo" relation */
  photo: MetadataProgramVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProgramVersionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataProgramVersion.metadata" relation */
  metadata?: InputMaybe<MetadataProgramVersionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataProgramVersion.photo" relation */
  photo?: InputMaybe<MetadataProgramVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProgramVersionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataProgramVersion.archive" relation */
  archive: MetadataProgramVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataProgramVersion.photo" relation */
  photo: MetadataProgramVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProgramVersionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataProgramVersion.archive" relation */
  archive?: InputMaybe<MetadataProgramVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProgramVersion.photo" relation */
  photo?: InputMaybe<MetadataProgramVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProgramVersionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataProgramVersion.archive" relation */
  archive: MetadataProgramVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataProgramVersion.metadata" relation */
  metadata: MetadataProgramVersionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataProgramVersionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataProgramVersion.archive" relation */
  archive?: InputMaybe<MetadataProgramVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataProgramVersion.metadata" relation */
  metadata?: InputMaybe<MetadataProgramVersionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPropertyReleaseId" resource's node */
export type MetadataPropertyReleaseId = {
  __typename?: 'MetadataPropertyReleaseId';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPropertyReleaseIdCreateInput = {
  /** Actions for the "MetadataPropertyReleaseId.archive" relation */
  archive: MetadataPropertyReleaseIdNestedArchiveCreateInput;
  /** Actions for the "MetadataPropertyReleaseId.metadata" relation */
  metadata: MetadataPropertyReleaseIdNestedMetadataCreateInput;
  /** Actions for the "MetadataPropertyReleaseId.photo" relation */
  photo: MetadataPropertyReleaseIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseIdNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.archive" relation. */
  update?: InputMaybe<MetadataPropertyReleaseIdNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.archive" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseIdNestedUpsertArchiveCreateInput>;
};

export type MetadataPropertyReleaseIdNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.archive" relation. */
  update?: InputMaybe<MetadataPropertyReleaseIdNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.archive" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseIdNestedUpsertArchiveUpdateInput>;
};

export type MetadataPropertyReleaseIdNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.metadata" relation. */
  update?: InputMaybe<MetadataPropertyReleaseIdNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.metadata" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseIdNestedUpsertMetadataCreateInput>;
};

export type MetadataPropertyReleaseIdNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.metadata" relation. */
  update?: InputMaybe<MetadataPropertyReleaseIdNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.metadata" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseIdNestedUpsertMetadataUpdateInput>;
};

export type MetadataPropertyReleaseIdNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.photo" relation. */
  update?: InputMaybe<MetadataPropertyReleaseIdNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.photo" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseIdNestedUpsertPhotoCreateInput>;
};

export type MetadataPropertyReleaseIdNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.photo" relation. */
  update?: InputMaybe<MetadataPropertyReleaseIdNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPropertyReleaseId" node, through the "MetadataPropertyReleaseId.photo" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseIdNestedUpsertPhotoUpdateInput>;
};

export type MetadataPropertyReleaseIdNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPropertyReleaseIdNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPropertyReleaseIdNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPropertyReleaseIdNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPropertyReleaseIdNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPropertyReleaseIdNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPropertyReleaseIdNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPropertyReleaseIdNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPropertyReleaseIdNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPropertyReleaseIdNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPropertyReleaseIdNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPropertyReleaseIdNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPropertyReleaseIdOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPropertyReleaseIdUpdateInput = {
  /** Actions for the "MetadataPropertyReleaseId.archive" relation */
  archive?: InputMaybe<MetadataPropertyReleaseIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPropertyReleaseId.metadata" relation */
  metadata?: InputMaybe<MetadataPropertyReleaseIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPropertyReleaseId.photo" relation */
  photo?: InputMaybe<MetadataPropertyReleaseIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPropertyReleaseId" nodes by specifying some conditions */
export type MetadataPropertyReleaseIdWhereInput = {
  AND?: InputMaybe<Array<MetadataPropertyReleaseIdWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
  OR?: InputMaybe<Array<MetadataPropertyReleaseIdWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPropertyReleaseId" node. */
export type MetadataPropertyReleaseIdWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPropertyReleaseIdWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPropertyReleaseId.metadata" relation */
  metadata: MetadataPropertyReleaseIdNestedMetadataCreateInput;
  /** Actions for the "MetadataPropertyReleaseId.photo" relation */
  photo: MetadataPropertyReleaseIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseIdWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPropertyReleaseId.metadata" relation */
  metadata?: InputMaybe<MetadataPropertyReleaseIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPropertyReleaseId.photo" relation */
  photo?: InputMaybe<MetadataPropertyReleaseIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseIdWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPropertyReleaseId.archive" relation */
  archive: MetadataPropertyReleaseIdNestedArchiveCreateInput;
  /** Actions for the "MetadataPropertyReleaseId.photo" relation */
  photo: MetadataPropertyReleaseIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseIdWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPropertyReleaseId.archive" relation */
  archive?: InputMaybe<MetadataPropertyReleaseIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPropertyReleaseId.photo" relation */
  photo?: InputMaybe<MetadataPropertyReleaseIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseIdWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPropertyReleaseId.archive" relation */
  archive: MetadataPropertyReleaseIdNestedArchiveCreateInput;
  /** Actions for the "MetadataPropertyReleaseId.metadata" relation */
  metadata: MetadataPropertyReleaseIdNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseIdWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPropertyReleaseId.archive" relation */
  archive?: InputMaybe<MetadataPropertyReleaseIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPropertyReleaseId.metadata" relation */
  metadata?: InputMaybe<MetadataPropertyReleaseIdNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPropertyReleaseStatus" resource's node */
export type MetadataPropertyReleaseStatus = {
  __typename?: 'MetadataPropertyReleaseStatus';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPropertyReleaseStatusCreateInput = {
  /** Actions for the "MetadataPropertyReleaseStatus.archive" relation */
  archive: MetadataPropertyReleaseStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataPropertyReleaseStatus.metadata" relation */
  metadata: MetadataPropertyReleaseStatusNestedMetadataCreateInput;
  /** Actions for the "MetadataPropertyReleaseStatus.photo" relation */
  photo: MetadataPropertyReleaseStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseStatusNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.archive" relation. */
  update?: InputMaybe<MetadataPropertyReleaseStatusNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.archive" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseStatusNestedUpsertArchiveCreateInput>;
};

export type MetadataPropertyReleaseStatusNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.archive" relation. */
  update?: InputMaybe<MetadataPropertyReleaseStatusNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.archive" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseStatusNestedUpsertArchiveUpdateInput>;
};

export type MetadataPropertyReleaseStatusNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.metadata" relation. */
  update?: InputMaybe<MetadataPropertyReleaseStatusNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.metadata" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseStatusNestedUpsertMetadataCreateInput>;
};

export type MetadataPropertyReleaseStatusNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.metadata" relation. */
  update?: InputMaybe<MetadataPropertyReleaseStatusNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.metadata" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseStatusNestedUpsertMetadataUpdateInput>;
};

export type MetadataPropertyReleaseStatusNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.photo" relation. */
  update?: InputMaybe<MetadataPropertyReleaseStatusNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.photo" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseStatusNestedUpsertPhotoCreateInput>;
};

export type MetadataPropertyReleaseStatusNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.photo" relation. */
  update?: InputMaybe<MetadataPropertyReleaseStatusNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPropertyReleaseStatus" node, through the "MetadataPropertyReleaseStatus.photo" relation. */
  upsert?: InputMaybe<MetadataPropertyReleaseStatusNestedUpsertPhotoUpdateInput>;
};

export type MetadataPropertyReleaseStatusNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPropertyReleaseStatusNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPropertyReleaseStatusNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPropertyReleaseStatusNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPropertyReleaseStatusNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPropertyReleaseStatusNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPropertyReleaseStatusNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPropertyReleaseStatusNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPropertyReleaseStatusNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPropertyReleaseStatusNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPropertyReleaseStatusNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPropertyReleaseStatusNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPropertyReleaseStatusOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPropertyReleaseStatusUpdateInput = {
  /** Actions for the "MetadataPropertyReleaseStatus.archive" relation */
  archive?: InputMaybe<MetadataPropertyReleaseStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPropertyReleaseStatus.metadata" relation */
  metadata?: InputMaybe<MetadataPropertyReleaseStatusNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPropertyReleaseStatus.photo" relation */
  photo?: InputMaybe<MetadataPropertyReleaseStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPropertyReleaseStatus" nodes by specifying some conditions */
export type MetadataPropertyReleaseStatusWhereInput = {
  AND?: InputMaybe<Array<MetadataPropertyReleaseStatusWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
  OR?: InputMaybe<Array<MetadataPropertyReleaseStatusWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPropertyReleaseStatus" node. */
export type MetadataPropertyReleaseStatusWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPropertyReleaseStatusWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPropertyReleaseStatus.metadata" relation */
  metadata: MetadataPropertyReleaseStatusNestedMetadataCreateInput;
  /** Actions for the "MetadataPropertyReleaseStatus.photo" relation */
  photo: MetadataPropertyReleaseStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseStatusWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPropertyReleaseStatus.metadata" relation */
  metadata?: InputMaybe<MetadataPropertyReleaseStatusNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPropertyReleaseStatus.photo" relation */
  photo?: InputMaybe<MetadataPropertyReleaseStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseStatusWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPropertyReleaseStatus.archive" relation */
  archive: MetadataPropertyReleaseStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataPropertyReleaseStatus.photo" relation */
  photo: MetadataPropertyReleaseStatusNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseStatusWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPropertyReleaseStatus.archive" relation */
  archive?: InputMaybe<MetadataPropertyReleaseStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPropertyReleaseStatus.photo" relation */
  photo?: InputMaybe<MetadataPropertyReleaseStatusNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseStatusWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPropertyReleaseStatus.archive" relation */
  archive: MetadataPropertyReleaseStatusNestedArchiveCreateInput;
  /** Actions for the "MetadataPropertyReleaseStatus.metadata" relation */
  metadata: MetadataPropertyReleaseStatusNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPropertyReleaseStatusWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPropertyReleaseStatus.archive" relation */
  archive?: InputMaybe<MetadataPropertyReleaseStatusNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPropertyReleaseStatus.metadata" relation */
  metadata?: InputMaybe<MetadataPropertyReleaseStatusNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPublisher" resource's node */
export type MetadataPublisher = {
  __typename?: 'MetadataPublisher';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPublisherCreateInput = {
  /** Actions for the "MetadataPublisher.archive" relation */
  archive: MetadataPublisherNestedArchiveCreateInput;
  /** Actions for the "MetadataPublisher.metadata" relation */
  metadata: MetadataPublisherNestedMetadataCreateInput;
  /** Actions for the "MetadataPublisher.photo" relation */
  photo: MetadataPublisherNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublisherNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPublisher" node, through the "MetadataPublisher.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPublisher" node, through the "MetadataPublisher.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPublisher" node, through the "MetadataPublisher.archive" relation. */
  update?: InputMaybe<MetadataPublisherNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPublisher" node, through the "MetadataPublisher.archive" relation. */
  upsert?: InputMaybe<MetadataPublisherNestedUpsertArchiveCreateInput>;
};

export type MetadataPublisherNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPublisher" node, through the "MetadataPublisher.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPublisher" node, through the "MetadataPublisher.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPublisher" node, through the "MetadataPublisher.archive" relation. */
  update?: InputMaybe<MetadataPublisherNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPublisher" node, through the "MetadataPublisher.archive" relation. */
  upsert?: InputMaybe<MetadataPublisherNestedUpsertArchiveUpdateInput>;
};

export type MetadataPublisherNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPublisher" node, through the "MetadataPublisher.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPublisher" node, through the "MetadataPublisher.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPublisher" node, through the "MetadataPublisher.metadata" relation. */
  update?: InputMaybe<MetadataPublisherNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPublisher" node, through the "MetadataPublisher.metadata" relation. */
  upsert?: InputMaybe<MetadataPublisherNestedUpsertMetadataCreateInput>;
};

export type MetadataPublisherNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPublisher" node, through the "MetadataPublisher.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPublisher" node, through the "MetadataPublisher.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPublisher" node, through the "MetadataPublisher.metadata" relation. */
  update?: InputMaybe<MetadataPublisherNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPublisher" node, through the "MetadataPublisher.metadata" relation. */
  upsert?: InputMaybe<MetadataPublisherNestedUpsertMetadataUpdateInput>;
};

export type MetadataPublisherNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPublisher" node, through the "MetadataPublisher.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPublisher" node, through the "MetadataPublisher.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPublisher" node, through the "MetadataPublisher.photo" relation. */
  update?: InputMaybe<MetadataPublisherNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPublisher" node, through the "MetadataPublisher.photo" relation. */
  upsert?: InputMaybe<MetadataPublisherNestedUpsertPhotoCreateInput>;
};

export type MetadataPublisherNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPublisher" node, through the "MetadataPublisher.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPublisher" node, through the "MetadataPublisher.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPublisher" node, through the "MetadataPublisher.photo" relation. */
  update?: InputMaybe<MetadataPublisherNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPublisher" node, through the "MetadataPublisher.photo" relation. */
  upsert?: InputMaybe<MetadataPublisherNestedUpsertPhotoUpdateInput>;
};

export type MetadataPublisherNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublisherNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublisherNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublisherNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublisherNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPublisherNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPublisherNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublisherNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublisherNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublisherNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublisherNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPublisherNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPublisherOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPublisherUpdateInput = {
  /** Actions for the "MetadataPublisher.archive" relation */
  archive?: InputMaybe<MetadataPublisherNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPublisher.metadata" relation */
  metadata?: InputMaybe<MetadataPublisherNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPublisher.photo" relation */
  photo?: InputMaybe<MetadataPublisherNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPublisher" nodes by specifying some conditions */
export type MetadataPublisherWhereInput = {
  AND?: InputMaybe<Array<MetadataPublisherWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPublisherWhereInput>;
  OR?: InputMaybe<Array<MetadataPublisherWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPublisher" node. */
export type MetadataPublisherWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPublisherWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPublisher.metadata" relation */
  metadata: MetadataPublisherNestedMetadataCreateInput;
  /** Actions for the "MetadataPublisher.photo" relation */
  photo: MetadataPublisherNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublisherWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPublisher.metadata" relation */
  metadata?: InputMaybe<MetadataPublisherNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPublisher.photo" relation */
  photo?: InputMaybe<MetadataPublisherNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublisherWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPublisher.archive" relation */
  archive: MetadataPublisherNestedArchiveCreateInput;
  /** Actions for the "MetadataPublisher.photo" relation */
  photo: MetadataPublisherNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublisherWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPublisher.archive" relation */
  archive?: InputMaybe<MetadataPublisherNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPublisher.photo" relation */
  photo?: InputMaybe<MetadataPublisherNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublisherWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPublisher.archive" relation */
  archive: MetadataPublisherNestedArchiveCreateInput;
  /** Actions for the "MetadataPublisher.metadata" relation */
  metadata: MetadataPublisherNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublisherWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPublisher.archive" relation */
  archive?: InputMaybe<MetadataPublisherNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPublisher.metadata" relation */
  metadata?: InputMaybe<MetadataPublisherNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPublishingIssue" resource's node */
export type MetadataPublishingIssue = {
  __typename?: 'MetadataPublishingIssue';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPublishingIssueCreateInput = {
  /** Actions for the "MetadataPublishingIssue.archive" relation */
  archive: MetadataPublishingIssueNestedArchiveCreateInput;
  /** Actions for the "MetadataPublishingIssue.metadata" relation */
  metadata: MetadataPublishingIssueNestedMetadataCreateInput;
  /** Actions for the "MetadataPublishingIssue.photo" relation */
  photo: MetadataPublishingIssueNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingIssueNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.archive" relation. */
  update?: InputMaybe<MetadataPublishingIssueNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.archive" relation. */
  upsert?: InputMaybe<MetadataPublishingIssueNestedUpsertArchiveCreateInput>;
};

export type MetadataPublishingIssueNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.archive" relation. */
  update?: InputMaybe<MetadataPublishingIssueNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.archive" relation. */
  upsert?: InputMaybe<MetadataPublishingIssueNestedUpsertArchiveUpdateInput>;
};

export type MetadataPublishingIssueNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.metadata" relation. */
  update?: InputMaybe<MetadataPublishingIssueNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.metadata" relation. */
  upsert?: InputMaybe<MetadataPublishingIssueNestedUpsertMetadataCreateInput>;
};

export type MetadataPublishingIssueNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.metadata" relation. */
  update?: InputMaybe<MetadataPublishingIssueNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.metadata" relation. */
  upsert?: InputMaybe<MetadataPublishingIssueNestedUpsertMetadataUpdateInput>;
};

export type MetadataPublishingIssueNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.photo" relation. */
  update?: InputMaybe<MetadataPublishingIssueNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.photo" relation. */
  upsert?: InputMaybe<MetadataPublishingIssueNestedUpsertPhotoCreateInput>;
};

export type MetadataPublishingIssueNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.photo" relation. */
  update?: InputMaybe<MetadataPublishingIssueNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPublishingIssue" node, through the "MetadataPublishingIssue.photo" relation. */
  upsert?: InputMaybe<MetadataPublishingIssueNestedUpsertPhotoUpdateInput>;
};

export type MetadataPublishingIssueNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublishingIssueNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublishingIssueNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublishingIssueNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublishingIssueNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPublishingIssueNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPublishingIssueNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublishingIssueNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublishingIssueNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublishingIssueNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublishingIssueNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPublishingIssueNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPublishingIssueOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPublishingIssueUpdateInput = {
  /** Actions for the "MetadataPublishingIssue.archive" relation */
  archive?: InputMaybe<MetadataPublishingIssueNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPublishingIssue.metadata" relation */
  metadata?: InputMaybe<MetadataPublishingIssueNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPublishingIssue.photo" relation */
  photo?: InputMaybe<MetadataPublishingIssueNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPublishingIssue" nodes by specifying some conditions */
export type MetadataPublishingIssueWhereInput = {
  AND?: InputMaybe<Array<MetadataPublishingIssueWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPublishingIssueWhereInput>;
  OR?: InputMaybe<Array<MetadataPublishingIssueWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPublishingIssue" node. */
export type MetadataPublishingIssueWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPublishingIssueWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPublishingIssue.metadata" relation */
  metadata: MetadataPublishingIssueNestedMetadataCreateInput;
  /** Actions for the "MetadataPublishingIssue.photo" relation */
  photo: MetadataPublishingIssueNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingIssueWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPublishingIssue.metadata" relation */
  metadata?: InputMaybe<MetadataPublishingIssueNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPublishingIssue.photo" relation */
  photo?: InputMaybe<MetadataPublishingIssueNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingIssueWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPublishingIssue.archive" relation */
  archive: MetadataPublishingIssueNestedArchiveCreateInput;
  /** Actions for the "MetadataPublishingIssue.photo" relation */
  photo: MetadataPublishingIssueNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingIssueWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPublishingIssue.archive" relation */
  archive?: InputMaybe<MetadataPublishingIssueNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPublishingIssue.photo" relation */
  photo?: InputMaybe<MetadataPublishingIssueNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingIssueWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPublishingIssue.archive" relation */
  archive: MetadataPublishingIssueNestedArchiveCreateInput;
  /** Actions for the "MetadataPublishingIssue.metadata" relation */
  metadata: MetadataPublishingIssueNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingIssueWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPublishingIssue.archive" relation */
  archive?: InputMaybe<MetadataPublishingIssueNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPublishingIssue.metadata" relation */
  metadata?: InputMaybe<MetadataPublishingIssueNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataPublishingSubject" resource's node */
export type MetadataPublishingSubject = {
  __typename?: 'MetadataPublishingSubject';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataPublishingSubjectCreateInput = {
  /** Actions for the "MetadataPublishingSubject.archive" relation */
  archive: MetadataPublishingSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataPublishingSubject.metadata" relation */
  metadata: MetadataPublishingSubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataPublishingSubject.photo" relation */
  photo: MetadataPublishingSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingSubjectNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.archive" relation. */
  update?: InputMaybe<MetadataPublishingSubjectNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.archive" relation. */
  upsert?: InputMaybe<MetadataPublishingSubjectNestedUpsertArchiveCreateInput>;
};

export type MetadataPublishingSubjectNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.archive" relation. */
  update?: InputMaybe<MetadataPublishingSubjectNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.archive" relation. */
  upsert?: InputMaybe<MetadataPublishingSubjectNestedUpsertArchiveUpdateInput>;
};

export type MetadataPublishingSubjectNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.metadata" relation. */
  update?: InputMaybe<MetadataPublishingSubjectNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.metadata" relation. */
  upsert?: InputMaybe<MetadataPublishingSubjectNestedUpsertMetadataCreateInput>;
};

export type MetadataPublishingSubjectNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.metadata" relation. */
  update?: InputMaybe<MetadataPublishingSubjectNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.metadata" relation. */
  upsert?: InputMaybe<MetadataPublishingSubjectNestedUpsertMetadataUpdateInput>;
};

export type MetadataPublishingSubjectNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.photo" relation. */
  update?: InputMaybe<MetadataPublishingSubjectNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.photo" relation. */
  upsert?: InputMaybe<MetadataPublishingSubjectNestedUpsertPhotoCreateInput>;
};

export type MetadataPublishingSubjectNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.photo" relation. */
  update?: InputMaybe<MetadataPublishingSubjectNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataPublishingSubject" node, through the "MetadataPublishingSubject.photo" relation. */
  upsert?: InputMaybe<MetadataPublishingSubjectNestedUpsertPhotoUpdateInput>;
};

export type MetadataPublishingSubjectNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublishingSubjectNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublishingSubjectNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublishingSubjectNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublishingSubjectNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPublishingSubjectNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPublishingSubjectNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublishingSubjectNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataPublishingSubjectNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublishingSubjectNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataPublishingSubjectNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataPublishingSubjectNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataPublishingSubjectOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataPublishingSubjectUpdateInput = {
  /** Actions for the "MetadataPublishingSubject.archive" relation */
  archive?: InputMaybe<MetadataPublishingSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPublishingSubject.metadata" relation */
  metadata?: InputMaybe<MetadataPublishingSubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPublishingSubject.photo" relation */
  photo?: InputMaybe<MetadataPublishingSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataPublishingSubject" nodes by specifying some conditions */
export type MetadataPublishingSubjectWhereInput = {
  AND?: InputMaybe<Array<MetadataPublishingSubjectWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataPublishingSubjectWhereInput>;
  OR?: InputMaybe<Array<MetadataPublishingSubjectWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataPublishingSubject" node. */
export type MetadataPublishingSubjectWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataPublishingSubjectWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataPublishingSubject.metadata" relation */
  metadata: MetadataPublishingSubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataPublishingSubject.photo" relation */
  photo: MetadataPublishingSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingSubjectWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataPublishingSubject.metadata" relation */
  metadata?: InputMaybe<MetadataPublishingSubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataPublishingSubject.photo" relation */
  photo?: InputMaybe<MetadataPublishingSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingSubjectWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataPublishingSubject.archive" relation */
  archive: MetadataPublishingSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataPublishingSubject.photo" relation */
  photo: MetadataPublishingSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingSubjectWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataPublishingSubject.archive" relation */
  archive?: InputMaybe<MetadataPublishingSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPublishingSubject.photo" relation */
  photo?: InputMaybe<MetadataPublishingSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingSubjectWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataPublishingSubject.archive" relation */
  archive: MetadataPublishingSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataPublishingSubject.metadata" relation */
  metadata: MetadataPublishingSubjectNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataPublishingSubjectWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataPublishingSubject.archive" relation */
  archive?: InputMaybe<MetadataPublishingSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataPublishingSubject.metadata" relation */
  metadata?: InputMaybe<MetadataPublishingSubjectNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataQualifiedUsePrismaBy" resource's node */
export type MetadataQualifiedUsePrismaBy = {
  __typename?: 'MetadataQualifiedUsePrismaBy';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataQualifiedUsePrismaByCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaBy.archive" relation */
  archive: MetadataQualifiedUsePrismaByNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaBy.metadata" relation */
  metadata: MetadataQualifiedUsePrismaByNestedMetadataCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaBy.photo" relation */
  photo: MetadataQualifiedUsePrismaByNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaByNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.archive" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.archive" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpsertArchiveCreateInput>;
};

export type MetadataQualifiedUsePrismaByNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.archive" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.archive" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpsertArchiveUpdateInput>;
};

export type MetadataQualifiedUsePrismaByNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.metadata" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.metadata" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpsertMetadataCreateInput>;
};

export type MetadataQualifiedUsePrismaByNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.metadata" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.metadata" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpsertMetadataUpdateInput>;
};

export type MetadataQualifiedUsePrismaByNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.photo" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.photo" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpsertPhotoCreateInput>;
};

export type MetadataQualifiedUsePrismaByNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.photo" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaBy" node, through the "MetadataQualifiedUsePrismaBy.photo" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaByNestedUpsertPhotoUpdateInput>;
};

export type MetadataQualifiedUsePrismaByNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaByNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaByNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaByNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaByNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaByNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaByNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaByNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaByNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaByNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaByNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaByNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataQualifiedUsePrismaByOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataQualifiedUsePrismaByUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaBy.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaByNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaBy.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaByNestedMetadataUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaBy.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaByNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataQualifiedUsePrismaBy" nodes by specifying some conditions */
export type MetadataQualifiedUsePrismaByWhereInput = {
  AND?: InputMaybe<Array<MetadataQualifiedUsePrismaByWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
  OR?: InputMaybe<Array<MetadataQualifiedUsePrismaByWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataQualifiedUsePrismaBy" node. */
export type MetadataQualifiedUsePrismaByWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataQualifiedUsePrismaByWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaBy.metadata" relation */
  metadata: MetadataQualifiedUsePrismaByNestedMetadataCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaBy.photo" relation */
  photo: MetadataQualifiedUsePrismaByNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaByWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaBy.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaByNestedMetadataUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaBy.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaByNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaByWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaBy.archive" relation */
  archive: MetadataQualifiedUsePrismaByNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaBy.photo" relation */
  photo: MetadataQualifiedUsePrismaByNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaByWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaBy.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaByNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaBy.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaByNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaByWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaBy.archive" relation */
  archive: MetadataQualifiedUsePrismaByNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaBy.metadata" relation */
  metadata: MetadataQualifiedUsePrismaByNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaByWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaBy.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaByNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaBy.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaByNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataQualifiedUsePrismaDate" resource's node */
export type MetadataQualifiedUsePrismaDate = {
  __typename?: 'MetadataQualifiedUsePrismaDate';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataQualifiedUsePrismaDateCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDate.archive" relation */
  archive: MetadataQualifiedUsePrismaDateNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaDate.metadata" relation */
  metadata: MetadataQualifiedUsePrismaDateNestedMetadataCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaDate.photo" relation */
  photo: MetadataQualifiedUsePrismaDateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDateNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.archive" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.archive" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpsertArchiveCreateInput>;
};

export type MetadataQualifiedUsePrismaDateNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.archive" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.archive" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpsertArchiveUpdateInput>;
};

export type MetadataQualifiedUsePrismaDateNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.metadata" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.metadata" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpsertMetadataCreateInput>;
};

export type MetadataQualifiedUsePrismaDateNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.metadata" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.metadata" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpsertMetadataUpdateInput>;
};

export type MetadataQualifiedUsePrismaDateNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.photo" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.photo" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpsertPhotoCreateInput>;
};

export type MetadataQualifiedUsePrismaDateNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.photo" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaDate" node, through the "MetadataQualifiedUsePrismaDate.photo" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDateNestedUpsertPhotoUpdateInput>;
};

export type MetadataQualifiedUsePrismaDateNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDateNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDateNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDateNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDateNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDateNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDateNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDateNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDateNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDateNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDateNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDateNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataQualifiedUsePrismaDateOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataQualifiedUsePrismaDateUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDate.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaDateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaDate.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaDateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaDate.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaDateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataQualifiedUsePrismaDate" nodes by specifying some conditions */
export type MetadataQualifiedUsePrismaDateWhereInput = {
  AND?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
  OR?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataQualifiedUsePrismaDate" node. */
export type MetadataQualifiedUsePrismaDateWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataQualifiedUsePrismaDateWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDate.metadata" relation */
  metadata: MetadataQualifiedUsePrismaDateNestedMetadataCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaDate.photo" relation */
  photo: MetadataQualifiedUsePrismaDateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDateWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDate.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaDateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaDate.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaDateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDateWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDate.archive" relation */
  archive: MetadataQualifiedUsePrismaDateNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaDate.photo" relation */
  photo: MetadataQualifiedUsePrismaDateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDateWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDate.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaDateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaDate.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaDateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDateWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDate.archive" relation */
  archive: MetadataQualifiedUsePrismaDateNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaDate.metadata" relation */
  metadata: MetadataQualifiedUsePrismaDateNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDateWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDate.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaDateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaDate.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaDateNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataQualifiedUsePrismaDuration" resource's node */
export type MetadataQualifiedUsePrismaDuration = {
  __typename?: 'MetadataQualifiedUsePrismaDuration';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataQualifiedUsePrismaDurationCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDuration.archive" relation */
  archive: MetadataQualifiedUsePrismaDurationNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaDuration.metadata" relation */
  metadata: MetadataQualifiedUsePrismaDurationNestedMetadataCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaDuration.photo" relation */
  photo: MetadataQualifiedUsePrismaDurationNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDurationNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.archive" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.archive" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpsertArchiveCreateInput>;
};

export type MetadataQualifiedUsePrismaDurationNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.archive" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.archive" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpsertArchiveUpdateInput>;
};

export type MetadataQualifiedUsePrismaDurationNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.metadata" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.metadata" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpsertMetadataCreateInput>;
};

export type MetadataQualifiedUsePrismaDurationNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.metadata" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.metadata" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpsertMetadataUpdateInput>;
};

export type MetadataQualifiedUsePrismaDurationNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.photo" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.photo" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpsertPhotoCreateInput>;
};

export type MetadataQualifiedUsePrismaDurationNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.photo" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaDuration" node, through the "MetadataQualifiedUsePrismaDuration.photo" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedUpsertPhotoUpdateInput>;
};

export type MetadataQualifiedUsePrismaDurationNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDurationNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDurationNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDurationNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDurationNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDurationNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDurationNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDurationNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDurationNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDurationNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDurationNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaDurationNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataQualifiedUsePrismaDurationOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataQualifiedUsePrismaDurationUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDuration.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaDuration.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedMetadataUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaDuration.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataQualifiedUsePrismaDuration" nodes by specifying some conditions */
export type MetadataQualifiedUsePrismaDurationWhereInput = {
  AND?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
  OR?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataQualifiedUsePrismaDuration" node. */
export type MetadataQualifiedUsePrismaDurationWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataQualifiedUsePrismaDurationWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDuration.metadata" relation */
  metadata: MetadataQualifiedUsePrismaDurationNestedMetadataCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaDuration.photo" relation */
  photo: MetadataQualifiedUsePrismaDurationNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDurationWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDuration.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedMetadataUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaDuration.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDurationWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDuration.archive" relation */
  archive: MetadataQualifiedUsePrismaDurationNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaDuration.photo" relation */
  photo: MetadataQualifiedUsePrismaDurationNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDurationWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDuration.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaDuration.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDurationWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDuration.archive" relation */
  archive: MetadataQualifiedUsePrismaDurationNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaDuration.metadata" relation */
  metadata: MetadataQualifiedUsePrismaDurationNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaDurationWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaDuration.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaDuration.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaDurationNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataQualifiedUsePrismaSupport" resource's node */
export type MetadataQualifiedUsePrismaSupport = {
  __typename?: 'MetadataQualifiedUsePrismaSupport';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataQualifiedUsePrismaSupportCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaSupport.archive" relation */
  archive: MetadataQualifiedUsePrismaSupportNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaSupport.metadata" relation */
  metadata: MetadataQualifiedUsePrismaSupportNestedMetadataCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaSupport.photo" relation */
  photo: MetadataQualifiedUsePrismaSupportNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaSupportNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.archive" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.archive" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpsertArchiveCreateInput>;
};

export type MetadataQualifiedUsePrismaSupportNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.archive" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.archive" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpsertArchiveUpdateInput>;
};

export type MetadataQualifiedUsePrismaSupportNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.metadata" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.metadata" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpsertMetadataCreateInput>;
};

export type MetadataQualifiedUsePrismaSupportNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.metadata" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.metadata" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpsertMetadataUpdateInput>;
};

export type MetadataQualifiedUsePrismaSupportNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.photo" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.photo" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpsertPhotoCreateInput>;
};

export type MetadataQualifiedUsePrismaSupportNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.photo" relation. */
  update?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataQualifiedUsePrismaSupport" node, through the "MetadataQualifiedUsePrismaSupport.photo" relation. */
  upsert?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedUpsertPhotoUpdateInput>;
};

export type MetadataQualifiedUsePrismaSupportNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaSupportNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaSupportNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaSupportNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaSupportNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaSupportNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaSupportNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaSupportNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaSupportNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaSupportNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaSupportNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataQualifiedUsePrismaSupportNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataQualifiedUsePrismaSupportOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataQualifiedUsePrismaSupportUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaSupport.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaSupport.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedMetadataUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaSupport.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataQualifiedUsePrismaSupport" nodes by specifying some conditions */
export type MetadataQualifiedUsePrismaSupportWhereInput = {
  AND?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
  OR?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataQualifiedUsePrismaSupport" node. */
export type MetadataQualifiedUsePrismaSupportWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataQualifiedUsePrismaSupportWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaSupport.metadata" relation */
  metadata: MetadataQualifiedUsePrismaSupportNestedMetadataCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaSupport.photo" relation */
  photo: MetadataQualifiedUsePrismaSupportNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaSupportWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaSupport.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedMetadataUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaSupport.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaSupportWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaSupport.archive" relation */
  archive: MetadataQualifiedUsePrismaSupportNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaSupport.photo" relation */
  photo: MetadataQualifiedUsePrismaSupportNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaSupportWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaSupport.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaSupport.photo" relation */
  photo?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaSupportWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaSupport.archive" relation */
  archive: MetadataQualifiedUsePrismaSupportNestedArchiveCreateInput;
  /** Actions for the "MetadataQualifiedUsePrismaSupport.metadata" relation */
  metadata: MetadataQualifiedUsePrismaSupportNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataQualifiedUsePrismaSupportWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataQualifiedUsePrismaSupport.archive" relation */
  archive?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedArchiveUpdateInput>;
  /** Actions for the "MetadataQualifiedUsePrismaSupport.metadata" relation */
  metadata?: InputMaybe<MetadataQualifiedUsePrismaSupportNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataRating" resource's node */
export type MetadataRating = {
  __typename?: 'MetadataRating';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataRatingCreateInput = {
  /** Actions for the "MetadataRating.archive" relation */
  archive: MetadataRatingNestedArchiveCreateInput;
  /** Actions for the "MetadataRating.metadata" relation */
  metadata: MetadataRatingNestedMetadataCreateInput;
  /** Actions for the "MetadataRating.photo" relation */
  photo: MetadataRatingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRatingNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataRating" node, through the "MetadataRating.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataRating" node, through the "MetadataRating.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataRating" node, through the "MetadataRating.archive" relation. */
  update?: InputMaybe<MetadataRatingNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataRating" node, through the "MetadataRating.archive" relation. */
  upsert?: InputMaybe<MetadataRatingNestedUpsertArchiveCreateInput>;
};

export type MetadataRatingNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataRating" node, through the "MetadataRating.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataRating" node, through the "MetadataRating.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataRating" node, through the "MetadataRating.archive" relation. */
  update?: InputMaybe<MetadataRatingNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataRating" node, through the "MetadataRating.archive" relation. */
  upsert?: InputMaybe<MetadataRatingNestedUpsertArchiveUpdateInput>;
};

export type MetadataRatingNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataRating" node, through the "MetadataRating.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataRating" node, through the "MetadataRating.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataRating" node, through the "MetadataRating.metadata" relation. */
  update?: InputMaybe<MetadataRatingNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataRating" node, through the "MetadataRating.metadata" relation. */
  upsert?: InputMaybe<MetadataRatingNestedUpsertMetadataCreateInput>;
};

export type MetadataRatingNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataRating" node, through the "MetadataRating.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataRating" node, through the "MetadataRating.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataRating" node, through the "MetadataRating.metadata" relation. */
  update?: InputMaybe<MetadataRatingNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataRating" node, through the "MetadataRating.metadata" relation. */
  upsert?: InputMaybe<MetadataRatingNestedUpsertMetadataUpdateInput>;
};

export type MetadataRatingNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataRating" node, through the "MetadataRating.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataRating" node, through the "MetadataRating.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataRating" node, through the "MetadataRating.photo" relation. */
  update?: InputMaybe<MetadataRatingNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataRating" node, through the "MetadataRating.photo" relation. */
  upsert?: InputMaybe<MetadataRatingNestedUpsertPhotoCreateInput>;
};

export type MetadataRatingNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataRating" node, through the "MetadataRating.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataRating" node, through the "MetadataRating.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataRating" node, through the "MetadataRating.photo" relation. */
  update?: InputMaybe<MetadataRatingNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataRating" node, through the "MetadataRating.photo" relation. */
  upsert?: InputMaybe<MetadataRatingNestedUpsertPhotoUpdateInput>;
};

export type MetadataRatingNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRatingNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRatingNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRatingNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRatingNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRatingNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRatingNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRatingNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRatingNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRatingNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRatingNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRatingNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataRatingOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataRatingUpdateInput = {
  /** Actions for the "MetadataRating.archive" relation */
  archive?: InputMaybe<MetadataRatingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRating.metadata" relation */
  metadata?: InputMaybe<MetadataRatingNestedMetadataUpdateInput>;
  /** Actions for the "MetadataRating.photo" relation */
  photo?: InputMaybe<MetadataRatingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataRating" nodes by specifying some conditions */
export type MetadataRatingWhereInput = {
  AND?: InputMaybe<Array<MetadataRatingWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataRatingWhereInput>;
  OR?: InputMaybe<Array<MetadataRatingWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataRating" node. */
export type MetadataRatingWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataRatingWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataRating.metadata" relation */
  metadata: MetadataRatingNestedMetadataCreateInput;
  /** Actions for the "MetadataRating.photo" relation */
  photo: MetadataRatingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRatingWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataRating.metadata" relation */
  metadata?: InputMaybe<MetadataRatingNestedMetadataUpdateInput>;
  /** Actions for the "MetadataRating.photo" relation */
  photo?: InputMaybe<MetadataRatingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRatingWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataRating.archive" relation */
  archive: MetadataRatingNestedArchiveCreateInput;
  /** Actions for the "MetadataRating.photo" relation */
  photo: MetadataRatingNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRatingWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataRating.archive" relation */
  archive?: InputMaybe<MetadataRatingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRating.photo" relation */
  photo?: InputMaybe<MetadataRatingNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRatingWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataRating.archive" relation */
  archive: MetadataRatingNestedArchiveCreateInput;
  /** Actions for the "MetadataRating.metadata" relation */
  metadata: MetadataRatingNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRatingWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataRating.archive" relation */
  archive?: InputMaybe<MetadataRatingNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRating.metadata" relation */
  metadata?: InputMaybe<MetadataRatingNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataReferenceDate" resource's node */
export type MetadataReferenceDate = {
  __typename?: 'MetadataReferenceDate';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataReferenceDateCreateInput = {
  /** Actions for the "MetadataReferenceDate.archive" relation */
  archive: MetadataReferenceDateNestedArchiveCreateInput;
  /** Actions for the "MetadataReferenceDate.metadata" relation */
  metadata: MetadataReferenceDateNestedMetadataCreateInput;
  /** Actions for the "MetadataReferenceDate.photo" relation */
  photo: MetadataReferenceDateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceDateNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.archive" relation. */
  update?: InputMaybe<MetadataReferenceDateNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.archive" relation. */
  upsert?: InputMaybe<MetadataReferenceDateNestedUpsertArchiveCreateInput>;
};

export type MetadataReferenceDateNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.archive" relation. */
  update?: InputMaybe<MetadataReferenceDateNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.archive" relation. */
  upsert?: InputMaybe<MetadataReferenceDateNestedUpsertArchiveUpdateInput>;
};

export type MetadataReferenceDateNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.metadata" relation. */
  update?: InputMaybe<MetadataReferenceDateNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.metadata" relation. */
  upsert?: InputMaybe<MetadataReferenceDateNestedUpsertMetadataCreateInput>;
};

export type MetadataReferenceDateNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.metadata" relation. */
  update?: InputMaybe<MetadataReferenceDateNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.metadata" relation. */
  upsert?: InputMaybe<MetadataReferenceDateNestedUpsertMetadataUpdateInput>;
};

export type MetadataReferenceDateNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.photo" relation. */
  update?: InputMaybe<MetadataReferenceDateNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataReferenceDate" node, through the "MetadataReferenceDate.photo" relation. */
  upsert?: InputMaybe<MetadataReferenceDateNestedUpsertPhotoCreateInput>;
};

export type MetadataReferenceDateNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.photo" relation. */
  update?: InputMaybe<MetadataReferenceDateNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataReferenceDate" node, through the "MetadataReferenceDate.photo" relation. */
  upsert?: InputMaybe<MetadataReferenceDateNestedUpsertPhotoUpdateInput>;
};

export type MetadataReferenceDateNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceDateNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceDateNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceDateNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceDateNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReferenceDateNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReferenceDateNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceDateNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceDateNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceDateNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceDateNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReferenceDateNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataReferenceDateOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataReferenceDateUpdateInput = {
  /** Actions for the "MetadataReferenceDate.archive" relation */
  archive?: InputMaybe<MetadataReferenceDateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReferenceDate.metadata" relation */
  metadata?: InputMaybe<MetadataReferenceDateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataReferenceDate.photo" relation */
  photo?: InputMaybe<MetadataReferenceDateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataReferenceDate" nodes by specifying some conditions */
export type MetadataReferenceDateWhereInput = {
  AND?: InputMaybe<Array<MetadataReferenceDateWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataReferenceDateWhereInput>;
  OR?: InputMaybe<Array<MetadataReferenceDateWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataReferenceDate" node. */
export type MetadataReferenceDateWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataReferenceDateWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataReferenceDate.metadata" relation */
  metadata: MetadataReferenceDateNestedMetadataCreateInput;
  /** Actions for the "MetadataReferenceDate.photo" relation */
  photo: MetadataReferenceDateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceDateWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataReferenceDate.metadata" relation */
  metadata?: InputMaybe<MetadataReferenceDateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataReferenceDate.photo" relation */
  photo?: InputMaybe<MetadataReferenceDateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceDateWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataReferenceDate.archive" relation */
  archive: MetadataReferenceDateNestedArchiveCreateInput;
  /** Actions for the "MetadataReferenceDate.photo" relation */
  photo: MetadataReferenceDateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceDateWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataReferenceDate.archive" relation */
  archive?: InputMaybe<MetadataReferenceDateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReferenceDate.photo" relation */
  photo?: InputMaybe<MetadataReferenceDateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceDateWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataReferenceDate.archive" relation */
  archive: MetadataReferenceDateNestedArchiveCreateInput;
  /** Actions for the "MetadataReferenceDate.metadata" relation */
  metadata: MetadataReferenceDateNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceDateWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataReferenceDate.archive" relation */
  archive?: InputMaybe<MetadataReferenceDateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReferenceDate.metadata" relation */
  metadata?: InputMaybe<MetadataReferenceDateNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataReferenceNumber" resource's node */
export type MetadataReferenceNumber = {
  __typename?: 'MetadataReferenceNumber';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataReferenceNumberCreateInput = {
  /** Actions for the "MetadataReferenceNumber.archive" relation */
  archive: MetadataReferenceNumberNestedArchiveCreateInput;
  /** Actions for the "MetadataReferenceNumber.metadata" relation */
  metadata: MetadataReferenceNumberNestedMetadataCreateInput;
  /** Actions for the "MetadataReferenceNumber.photo" relation */
  photo: MetadataReferenceNumberNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceNumberNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.archive" relation. */
  update?: InputMaybe<MetadataReferenceNumberNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.archive" relation. */
  upsert?: InputMaybe<MetadataReferenceNumberNestedUpsertArchiveCreateInput>;
};

export type MetadataReferenceNumberNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.archive" relation. */
  update?: InputMaybe<MetadataReferenceNumberNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.archive" relation. */
  upsert?: InputMaybe<MetadataReferenceNumberNestedUpsertArchiveUpdateInput>;
};

export type MetadataReferenceNumberNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.metadata" relation. */
  update?: InputMaybe<MetadataReferenceNumberNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.metadata" relation. */
  upsert?: InputMaybe<MetadataReferenceNumberNestedUpsertMetadataCreateInput>;
};

export type MetadataReferenceNumberNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.metadata" relation. */
  update?: InputMaybe<MetadataReferenceNumberNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.metadata" relation. */
  upsert?: InputMaybe<MetadataReferenceNumberNestedUpsertMetadataUpdateInput>;
};

export type MetadataReferenceNumberNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.photo" relation. */
  update?: InputMaybe<MetadataReferenceNumberNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.photo" relation. */
  upsert?: InputMaybe<MetadataReferenceNumberNestedUpsertPhotoCreateInput>;
};

export type MetadataReferenceNumberNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.photo" relation. */
  update?: InputMaybe<MetadataReferenceNumberNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataReferenceNumber" node, through the "MetadataReferenceNumber.photo" relation. */
  upsert?: InputMaybe<MetadataReferenceNumberNestedUpsertPhotoUpdateInput>;
};

export type MetadataReferenceNumberNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceNumberNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceNumberNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceNumberNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceNumberNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReferenceNumberNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReferenceNumberNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceNumberNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceNumberNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceNumberNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceNumberNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReferenceNumberNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataReferenceNumberOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataReferenceNumberUpdateInput = {
  /** Actions for the "MetadataReferenceNumber.archive" relation */
  archive?: InputMaybe<MetadataReferenceNumberNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReferenceNumber.metadata" relation */
  metadata?: InputMaybe<MetadataReferenceNumberNestedMetadataUpdateInput>;
  /** Actions for the "MetadataReferenceNumber.photo" relation */
  photo?: InputMaybe<MetadataReferenceNumberNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataReferenceNumber" nodes by specifying some conditions */
export type MetadataReferenceNumberWhereInput = {
  AND?: InputMaybe<Array<MetadataReferenceNumberWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataReferenceNumberWhereInput>;
  OR?: InputMaybe<Array<MetadataReferenceNumberWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataReferenceNumber" node. */
export type MetadataReferenceNumberWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataReferenceNumberWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataReferenceNumber.metadata" relation */
  metadata: MetadataReferenceNumberNestedMetadataCreateInput;
  /** Actions for the "MetadataReferenceNumber.photo" relation */
  photo: MetadataReferenceNumberNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceNumberWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataReferenceNumber.metadata" relation */
  metadata?: InputMaybe<MetadataReferenceNumberNestedMetadataUpdateInput>;
  /** Actions for the "MetadataReferenceNumber.photo" relation */
  photo?: InputMaybe<MetadataReferenceNumberNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceNumberWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataReferenceNumber.archive" relation */
  archive: MetadataReferenceNumberNestedArchiveCreateInput;
  /** Actions for the "MetadataReferenceNumber.photo" relation */
  photo: MetadataReferenceNumberNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceNumberWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataReferenceNumber.archive" relation */
  archive?: InputMaybe<MetadataReferenceNumberNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReferenceNumber.photo" relation */
  photo?: InputMaybe<MetadataReferenceNumberNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceNumberWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataReferenceNumber.archive" relation */
  archive: MetadataReferenceNumberNestedArchiveCreateInput;
  /** Actions for the "MetadataReferenceNumber.metadata" relation */
  metadata: MetadataReferenceNumberNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceNumberWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataReferenceNumber.archive" relation */
  archive?: InputMaybe<MetadataReferenceNumberNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReferenceNumber.metadata" relation */
  metadata?: InputMaybe<MetadataReferenceNumberNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataReferenceService" resource's node */
export type MetadataReferenceService = {
  __typename?: 'MetadataReferenceService';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataReferenceServiceCreateInput = {
  /** Actions for the "MetadataReferenceService.archive" relation */
  archive: MetadataReferenceServiceNestedArchiveCreateInput;
  /** Actions for the "MetadataReferenceService.metadata" relation */
  metadata: MetadataReferenceServiceNestedMetadataCreateInput;
  /** Actions for the "MetadataReferenceService.photo" relation */
  photo: MetadataReferenceServiceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceServiceNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataReferenceService" node, through the "MetadataReferenceService.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataReferenceService" node, through the "MetadataReferenceService.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataReferenceService" node, through the "MetadataReferenceService.archive" relation. */
  update?: InputMaybe<MetadataReferenceServiceNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataReferenceService" node, through the "MetadataReferenceService.archive" relation. */
  upsert?: InputMaybe<MetadataReferenceServiceNestedUpsertArchiveCreateInput>;
};

export type MetadataReferenceServiceNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.archive" relation. */
  update?: InputMaybe<MetadataReferenceServiceNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.archive" relation. */
  upsert?: InputMaybe<MetadataReferenceServiceNestedUpsertArchiveUpdateInput>;
};

export type MetadataReferenceServiceNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataReferenceService" node, through the "MetadataReferenceService.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataReferenceService" node, through the "MetadataReferenceService.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataReferenceService" node, through the "MetadataReferenceService.metadata" relation. */
  update?: InputMaybe<MetadataReferenceServiceNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataReferenceService" node, through the "MetadataReferenceService.metadata" relation. */
  upsert?: InputMaybe<MetadataReferenceServiceNestedUpsertMetadataCreateInput>;
};

export type MetadataReferenceServiceNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.metadata" relation. */
  update?: InputMaybe<MetadataReferenceServiceNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.metadata" relation. */
  upsert?: InputMaybe<MetadataReferenceServiceNestedUpsertMetadataUpdateInput>;
};

export type MetadataReferenceServiceNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataReferenceService" node, through the "MetadataReferenceService.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataReferenceService" node, through the "MetadataReferenceService.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataReferenceService" node, through the "MetadataReferenceService.photo" relation. */
  update?: InputMaybe<MetadataReferenceServiceNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataReferenceService" node, through the "MetadataReferenceService.photo" relation. */
  upsert?: InputMaybe<MetadataReferenceServiceNestedUpsertPhotoCreateInput>;
};

export type MetadataReferenceServiceNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.photo" relation. */
  update?: InputMaybe<MetadataReferenceServiceNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataReferenceService" node, through the "MetadataReferenceService.photo" relation. */
  upsert?: InputMaybe<MetadataReferenceServiceNestedUpsertPhotoUpdateInput>;
};

export type MetadataReferenceServiceNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceServiceNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceServiceNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceServiceNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceServiceNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReferenceServiceNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReferenceServiceNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceServiceNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReferenceServiceNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceServiceNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReferenceServiceNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReferenceServiceNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataReferenceServiceOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataReferenceServiceUpdateInput = {
  /** Actions for the "MetadataReferenceService.archive" relation */
  archive?: InputMaybe<MetadataReferenceServiceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReferenceService.metadata" relation */
  metadata?: InputMaybe<MetadataReferenceServiceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataReferenceService.photo" relation */
  photo?: InputMaybe<MetadataReferenceServiceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataReferenceService" nodes by specifying some conditions */
export type MetadataReferenceServiceWhereInput = {
  AND?: InputMaybe<Array<MetadataReferenceServiceWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataReferenceServiceWhereInput>;
  OR?: InputMaybe<Array<MetadataReferenceServiceWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataReferenceService" node. */
export type MetadataReferenceServiceWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataReferenceServiceWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataReferenceService.metadata" relation */
  metadata: MetadataReferenceServiceNestedMetadataCreateInput;
  /** Actions for the "MetadataReferenceService.photo" relation */
  photo: MetadataReferenceServiceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceServiceWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataReferenceService.metadata" relation */
  metadata?: InputMaybe<MetadataReferenceServiceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataReferenceService.photo" relation */
  photo?: InputMaybe<MetadataReferenceServiceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceServiceWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataReferenceService.archive" relation */
  archive: MetadataReferenceServiceNestedArchiveCreateInput;
  /** Actions for the "MetadataReferenceService.photo" relation */
  photo: MetadataReferenceServiceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceServiceWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataReferenceService.archive" relation */
  archive?: InputMaybe<MetadataReferenceServiceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReferenceService.photo" relation */
  photo?: InputMaybe<MetadataReferenceServiceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceServiceWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataReferenceService.archive" relation */
  archive: MetadataReferenceServiceNestedArchiveCreateInput;
  /** Actions for the "MetadataReferenceService.metadata" relation */
  metadata: MetadataReferenceServiceNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReferenceServiceWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataReferenceService.archive" relation */
  archive?: InputMaybe<MetadataReferenceServiceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReferenceService.metadata" relation */
  metadata?: InputMaybe<MetadataReferenceServiceNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataReleaseDate" resource's node */
export type MetadataReleaseDate = {
  __typename?: 'MetadataReleaseDate';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataReleaseDateCreateInput = {
  /** Actions for the "MetadataReleaseDate.archive" relation */
  archive: MetadataReleaseDateNestedArchiveCreateInput;
  /** Actions for the "MetadataReleaseDate.metadata" relation */
  metadata: MetadataReleaseDateNestedMetadataCreateInput;
  /** Actions for the "MetadataReleaseDate.photo" relation */
  photo: MetadataReleaseDateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseDateNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.archive" relation. */
  update?: InputMaybe<MetadataReleaseDateNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.archive" relation. */
  upsert?: InputMaybe<MetadataReleaseDateNestedUpsertArchiveCreateInput>;
};

export type MetadataReleaseDateNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.archive" relation. */
  update?: InputMaybe<MetadataReleaseDateNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.archive" relation. */
  upsert?: InputMaybe<MetadataReleaseDateNestedUpsertArchiveUpdateInput>;
};

export type MetadataReleaseDateNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.metadata" relation. */
  update?: InputMaybe<MetadataReleaseDateNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.metadata" relation. */
  upsert?: InputMaybe<MetadataReleaseDateNestedUpsertMetadataCreateInput>;
};

export type MetadataReleaseDateNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.metadata" relation. */
  update?: InputMaybe<MetadataReleaseDateNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.metadata" relation. */
  upsert?: InputMaybe<MetadataReleaseDateNestedUpsertMetadataUpdateInput>;
};

export type MetadataReleaseDateNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.photo" relation. */
  update?: InputMaybe<MetadataReleaseDateNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataReleaseDate" node, through the "MetadataReleaseDate.photo" relation. */
  upsert?: InputMaybe<MetadataReleaseDateNestedUpsertPhotoCreateInput>;
};

export type MetadataReleaseDateNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.photo" relation. */
  update?: InputMaybe<MetadataReleaseDateNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataReleaseDate" node, through the "MetadataReleaseDate.photo" relation. */
  upsert?: InputMaybe<MetadataReleaseDateNestedUpsertPhotoUpdateInput>;
};

export type MetadataReleaseDateNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReleaseDateNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReleaseDateNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReleaseDateNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReleaseDateNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReleaseDateNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReleaseDateNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReleaseDateNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReleaseDateNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReleaseDateNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReleaseDateNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReleaseDateNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataReleaseDateOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataReleaseDateUpdateInput = {
  /** Actions for the "MetadataReleaseDate.archive" relation */
  archive?: InputMaybe<MetadataReleaseDateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReleaseDate.metadata" relation */
  metadata?: InputMaybe<MetadataReleaseDateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataReleaseDate.photo" relation */
  photo?: InputMaybe<MetadataReleaseDateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataReleaseDate" nodes by specifying some conditions */
export type MetadataReleaseDateWhereInput = {
  AND?: InputMaybe<Array<MetadataReleaseDateWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataReleaseDateWhereInput>;
  OR?: InputMaybe<Array<MetadataReleaseDateWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataReleaseDate" node. */
export type MetadataReleaseDateWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataReleaseDateWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataReleaseDate.metadata" relation */
  metadata: MetadataReleaseDateNestedMetadataCreateInput;
  /** Actions for the "MetadataReleaseDate.photo" relation */
  photo: MetadataReleaseDateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseDateWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataReleaseDate.metadata" relation */
  metadata?: InputMaybe<MetadataReleaseDateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataReleaseDate.photo" relation */
  photo?: InputMaybe<MetadataReleaseDateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseDateWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataReleaseDate.archive" relation */
  archive: MetadataReleaseDateNestedArchiveCreateInput;
  /** Actions for the "MetadataReleaseDate.photo" relation */
  photo: MetadataReleaseDateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseDateWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataReleaseDate.archive" relation */
  archive?: InputMaybe<MetadataReleaseDateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReleaseDate.photo" relation */
  photo?: InputMaybe<MetadataReleaseDateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseDateWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataReleaseDate.archive" relation */
  archive: MetadataReleaseDateNestedArchiveCreateInput;
  /** Actions for the "MetadataReleaseDate.metadata" relation */
  metadata: MetadataReleaseDateNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseDateWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataReleaseDate.archive" relation */
  archive?: InputMaybe<MetadataReleaseDateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReleaseDate.metadata" relation */
  metadata?: InputMaybe<MetadataReleaseDateNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataReleaseTime" resource's node */
export type MetadataReleaseTime = {
  __typename?: 'MetadataReleaseTime';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataReleaseTimeCreateInput = {
  /** Actions for the "MetadataReleaseTime.archive" relation */
  archive: MetadataReleaseTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataReleaseTime.metadata" relation */
  metadata: MetadataReleaseTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataReleaseTime.photo" relation */
  photo: MetadataReleaseTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseTimeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.archive" relation. */
  update?: InputMaybe<MetadataReleaseTimeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.archive" relation. */
  upsert?: InputMaybe<MetadataReleaseTimeNestedUpsertArchiveCreateInput>;
};

export type MetadataReleaseTimeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.archive" relation. */
  update?: InputMaybe<MetadataReleaseTimeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.archive" relation. */
  upsert?: InputMaybe<MetadataReleaseTimeNestedUpsertArchiveUpdateInput>;
};

export type MetadataReleaseTimeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.metadata" relation. */
  update?: InputMaybe<MetadataReleaseTimeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.metadata" relation. */
  upsert?: InputMaybe<MetadataReleaseTimeNestedUpsertMetadataCreateInput>;
};

export type MetadataReleaseTimeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.metadata" relation. */
  update?: InputMaybe<MetadataReleaseTimeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.metadata" relation. */
  upsert?: InputMaybe<MetadataReleaseTimeNestedUpsertMetadataUpdateInput>;
};

export type MetadataReleaseTimeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.photo" relation. */
  update?: InputMaybe<MetadataReleaseTimeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataReleaseTime" node, through the "MetadataReleaseTime.photo" relation. */
  upsert?: InputMaybe<MetadataReleaseTimeNestedUpsertPhotoCreateInput>;
};

export type MetadataReleaseTimeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.photo" relation. */
  update?: InputMaybe<MetadataReleaseTimeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataReleaseTime" node, through the "MetadataReleaseTime.photo" relation. */
  upsert?: InputMaybe<MetadataReleaseTimeNestedUpsertPhotoUpdateInput>;
};

export type MetadataReleaseTimeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReleaseTimeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReleaseTimeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReleaseTimeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReleaseTimeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReleaseTimeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReleaseTimeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReleaseTimeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataReleaseTimeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReleaseTimeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataReleaseTimeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataReleaseTimeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataReleaseTimeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataReleaseTimeUpdateInput = {
  /** Actions for the "MetadataReleaseTime.archive" relation */
  archive?: InputMaybe<MetadataReleaseTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReleaseTime.metadata" relation */
  metadata?: InputMaybe<MetadataReleaseTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataReleaseTime.photo" relation */
  photo?: InputMaybe<MetadataReleaseTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataReleaseTime" nodes by specifying some conditions */
export type MetadataReleaseTimeWhereInput = {
  AND?: InputMaybe<Array<MetadataReleaseTimeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataReleaseTimeWhereInput>;
  OR?: InputMaybe<Array<MetadataReleaseTimeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataReleaseTime" node. */
export type MetadataReleaseTimeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataReleaseTimeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataReleaseTime.metadata" relation */
  metadata: MetadataReleaseTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataReleaseTime.photo" relation */
  photo: MetadataReleaseTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseTimeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataReleaseTime.metadata" relation */
  metadata?: InputMaybe<MetadataReleaseTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataReleaseTime.photo" relation */
  photo?: InputMaybe<MetadataReleaseTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseTimeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataReleaseTime.archive" relation */
  archive: MetadataReleaseTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataReleaseTime.photo" relation */
  photo: MetadataReleaseTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseTimeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataReleaseTime.archive" relation */
  archive?: InputMaybe<MetadataReleaseTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReleaseTime.photo" relation */
  photo?: InputMaybe<MetadataReleaseTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseTimeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataReleaseTime.archive" relation */
  archive: MetadataReleaseTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataReleaseTime.metadata" relation */
  metadata: MetadataReleaseTimeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataReleaseTimeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataReleaseTime.archive" relation */
  archive?: InputMaybe<MetadataReleaseTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataReleaseTime.metadata" relation */
  metadata?: InputMaybe<MetadataReleaseTimeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataRequiredPermission" resource's node */
export type MetadataRequiredPermission = {
  __typename?: 'MetadataRequiredPermission';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataRequiredPermissionCreateInput = {
  /** Actions for the "MetadataRequiredPermission.archive" relation */
  archive: MetadataRequiredPermissionNestedArchiveCreateInput;
  /** Actions for the "MetadataRequiredPermission.metadata" relation */
  metadata: MetadataRequiredPermissionNestedMetadataCreateInput;
  /** Actions for the "MetadataRequiredPermission.photo" relation */
  photo: MetadataRequiredPermissionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRequiredPermissionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.archive" relation. */
  update?: InputMaybe<MetadataRequiredPermissionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.archive" relation. */
  upsert?: InputMaybe<MetadataRequiredPermissionNestedUpsertArchiveCreateInput>;
};

export type MetadataRequiredPermissionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.archive" relation. */
  update?: InputMaybe<MetadataRequiredPermissionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.archive" relation. */
  upsert?: InputMaybe<MetadataRequiredPermissionNestedUpsertArchiveUpdateInput>;
};

export type MetadataRequiredPermissionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.metadata" relation. */
  update?: InputMaybe<MetadataRequiredPermissionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.metadata" relation. */
  upsert?: InputMaybe<MetadataRequiredPermissionNestedUpsertMetadataCreateInput>;
};

export type MetadataRequiredPermissionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.metadata" relation. */
  update?: InputMaybe<MetadataRequiredPermissionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.metadata" relation. */
  upsert?: InputMaybe<MetadataRequiredPermissionNestedUpsertMetadataUpdateInput>;
};

export type MetadataRequiredPermissionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.photo" relation. */
  update?: InputMaybe<MetadataRequiredPermissionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.photo" relation. */
  upsert?: InputMaybe<MetadataRequiredPermissionNestedUpsertPhotoCreateInput>;
};

export type MetadataRequiredPermissionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.photo" relation. */
  update?: InputMaybe<MetadataRequiredPermissionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataRequiredPermission" node, through the "MetadataRequiredPermission.photo" relation. */
  upsert?: InputMaybe<MetadataRequiredPermissionNestedUpsertPhotoUpdateInput>;
};

export type MetadataRequiredPermissionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRequiredPermissionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRequiredPermissionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRequiredPermissionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRequiredPermissionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRequiredPermissionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRequiredPermissionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRequiredPermissionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRequiredPermissionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRequiredPermissionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRequiredPermissionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRequiredPermissionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataRequiredPermissionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataRequiredPermissionUpdateInput = {
  /** Actions for the "MetadataRequiredPermission.archive" relation */
  archive?: InputMaybe<MetadataRequiredPermissionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRequiredPermission.metadata" relation */
  metadata?: InputMaybe<MetadataRequiredPermissionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataRequiredPermission.photo" relation */
  photo?: InputMaybe<MetadataRequiredPermissionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataRequiredPermission" nodes by specifying some conditions */
export type MetadataRequiredPermissionWhereInput = {
  AND?: InputMaybe<Array<MetadataRequiredPermissionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataRequiredPermissionWhereInput>;
  OR?: InputMaybe<Array<MetadataRequiredPermissionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataRequiredPermission" node. */
export type MetadataRequiredPermissionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataRequiredPermissionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataRequiredPermission.metadata" relation */
  metadata: MetadataRequiredPermissionNestedMetadataCreateInput;
  /** Actions for the "MetadataRequiredPermission.photo" relation */
  photo: MetadataRequiredPermissionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRequiredPermissionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataRequiredPermission.metadata" relation */
  metadata?: InputMaybe<MetadataRequiredPermissionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataRequiredPermission.photo" relation */
  photo?: InputMaybe<MetadataRequiredPermissionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRequiredPermissionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataRequiredPermission.archive" relation */
  archive: MetadataRequiredPermissionNestedArchiveCreateInput;
  /** Actions for the "MetadataRequiredPermission.photo" relation */
  photo: MetadataRequiredPermissionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRequiredPermissionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataRequiredPermission.archive" relation */
  archive?: InputMaybe<MetadataRequiredPermissionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRequiredPermission.photo" relation */
  photo?: InputMaybe<MetadataRequiredPermissionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRequiredPermissionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataRequiredPermission.archive" relation */
  archive: MetadataRequiredPermissionNestedArchiveCreateInput;
  /** Actions for the "MetadataRequiredPermission.metadata" relation */
  metadata: MetadataRequiredPermissionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRequiredPermissionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataRequiredPermission.archive" relation */
  archive?: InputMaybe<MetadataRequiredPermissionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRequiredPermission.metadata" relation */
  metadata?: InputMaybe<MetadataRequiredPermissionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataResolutionKind" resource's node */
export type MetadataResolutionKind = {
  __typename?: 'MetadataResolutionKind';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataResolutionKindCreateInput = {
  /** Actions for the "MetadataResolutionKind.archive" relation */
  archive: MetadataResolutionKindNestedArchiveCreateInput;
  /** Actions for the "MetadataResolutionKind.metadata" relation */
  metadata: MetadataResolutionKindNestedMetadataCreateInput;
  /** Actions for the "MetadataResolutionKind.photo" relation */
  photo: MetadataResolutionKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataResolutionKindNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.archive" relation. */
  update?: InputMaybe<MetadataResolutionKindNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.archive" relation. */
  upsert?: InputMaybe<MetadataResolutionKindNestedUpsertArchiveCreateInput>;
};

export type MetadataResolutionKindNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.archive" relation. */
  update?: InputMaybe<MetadataResolutionKindNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.archive" relation. */
  upsert?: InputMaybe<MetadataResolutionKindNestedUpsertArchiveUpdateInput>;
};

export type MetadataResolutionKindNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.metadata" relation. */
  update?: InputMaybe<MetadataResolutionKindNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.metadata" relation. */
  upsert?: InputMaybe<MetadataResolutionKindNestedUpsertMetadataCreateInput>;
};

export type MetadataResolutionKindNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.metadata" relation. */
  update?: InputMaybe<MetadataResolutionKindNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.metadata" relation. */
  upsert?: InputMaybe<MetadataResolutionKindNestedUpsertMetadataUpdateInput>;
};

export type MetadataResolutionKindNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.photo" relation. */
  update?: InputMaybe<MetadataResolutionKindNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataResolutionKind" node, through the "MetadataResolutionKind.photo" relation. */
  upsert?: InputMaybe<MetadataResolutionKindNestedUpsertPhotoCreateInput>;
};

export type MetadataResolutionKindNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.photo" relation. */
  update?: InputMaybe<MetadataResolutionKindNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataResolutionKind" node, through the "MetadataResolutionKind.photo" relation. */
  upsert?: InputMaybe<MetadataResolutionKindNestedUpsertPhotoUpdateInput>;
};

export type MetadataResolutionKindNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataResolutionKindNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataResolutionKindNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataResolutionKindNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataResolutionKindNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataResolutionKindNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataResolutionKindNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataResolutionKindNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataResolutionKindNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataResolutionKindNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataResolutionKindNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataResolutionKindNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataResolutionKindOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataResolutionKindUpdateInput = {
  /** Actions for the "MetadataResolutionKind.archive" relation */
  archive?: InputMaybe<MetadataResolutionKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataResolutionKind.metadata" relation */
  metadata?: InputMaybe<MetadataResolutionKindNestedMetadataUpdateInput>;
  /** Actions for the "MetadataResolutionKind.photo" relation */
  photo?: InputMaybe<MetadataResolutionKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataResolutionKind" nodes by specifying some conditions */
export type MetadataResolutionKindWhereInput = {
  AND?: InputMaybe<Array<MetadataResolutionKindWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataResolutionKindWhereInput>;
  OR?: InputMaybe<Array<MetadataResolutionKindWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataResolutionKind" node. */
export type MetadataResolutionKindWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataResolutionKindWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataResolutionKind.metadata" relation */
  metadata: MetadataResolutionKindNestedMetadataCreateInput;
  /** Actions for the "MetadataResolutionKind.photo" relation */
  photo: MetadataResolutionKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataResolutionKindWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataResolutionKind.metadata" relation */
  metadata?: InputMaybe<MetadataResolutionKindNestedMetadataUpdateInput>;
  /** Actions for the "MetadataResolutionKind.photo" relation */
  photo?: InputMaybe<MetadataResolutionKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataResolutionKindWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataResolutionKind.archive" relation */
  archive: MetadataResolutionKindNestedArchiveCreateInput;
  /** Actions for the "MetadataResolutionKind.photo" relation */
  photo: MetadataResolutionKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataResolutionKindWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataResolutionKind.archive" relation */
  archive?: InputMaybe<MetadataResolutionKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataResolutionKind.photo" relation */
  photo?: InputMaybe<MetadataResolutionKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataResolutionKindWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataResolutionKind.archive" relation */
  archive: MetadataResolutionKindNestedArchiveCreateInput;
  /** Actions for the "MetadataResolutionKind.metadata" relation */
  metadata: MetadataResolutionKindNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataResolutionKindWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataResolutionKind.archive" relation */
  archive?: InputMaybe<MetadataResolutionKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataResolutionKind.metadata" relation */
  metadata?: InputMaybe<MetadataResolutionKindNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataRights" resource's node */
export type MetadataRights = {
  __typename?: 'MetadataRights';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataRightsCreateInput = {
  /** Actions for the "MetadataRights.archive" relation */
  archive: MetadataRightsNestedArchiveCreateInput;
  /** Actions for the "MetadataRights.metadata" relation */
  metadata: MetadataRightsNestedMetadataCreateInput;
  /** Actions for the "MetadataRights.photo" relation */
  photo: MetadataRightsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRightsNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataRights" node, through the "MetadataRights.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataRights" node, through the "MetadataRights.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataRights" node, through the "MetadataRights.archive" relation. */
  update?: InputMaybe<MetadataRightsNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataRights" node, through the "MetadataRights.archive" relation. */
  upsert?: InputMaybe<MetadataRightsNestedUpsertArchiveCreateInput>;
};

export type MetadataRightsNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataRights" node, through the "MetadataRights.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataRights" node, through the "MetadataRights.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataRights" node, through the "MetadataRights.archive" relation. */
  update?: InputMaybe<MetadataRightsNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataRights" node, through the "MetadataRights.archive" relation. */
  upsert?: InputMaybe<MetadataRightsNestedUpsertArchiveUpdateInput>;
};

export type MetadataRightsNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataRights" node, through the "MetadataRights.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataRights" node, through the "MetadataRights.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataRights" node, through the "MetadataRights.metadata" relation. */
  update?: InputMaybe<MetadataRightsNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataRights" node, through the "MetadataRights.metadata" relation. */
  upsert?: InputMaybe<MetadataRightsNestedUpsertMetadataCreateInput>;
};

export type MetadataRightsNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataRights" node, through the "MetadataRights.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataRights" node, through the "MetadataRights.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataRights" node, through the "MetadataRights.metadata" relation. */
  update?: InputMaybe<MetadataRightsNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataRights" node, through the "MetadataRights.metadata" relation. */
  upsert?: InputMaybe<MetadataRightsNestedUpsertMetadataUpdateInput>;
};

export type MetadataRightsNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataRights" node, through the "MetadataRights.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataRights" node, through the "MetadataRights.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataRights" node, through the "MetadataRights.photo" relation. */
  update?: InputMaybe<MetadataRightsNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataRights" node, through the "MetadataRights.photo" relation. */
  upsert?: InputMaybe<MetadataRightsNestedUpsertPhotoCreateInput>;
};

export type MetadataRightsNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataRights" node, through the "MetadataRights.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataRights" node, through the "MetadataRights.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataRights" node, through the "MetadataRights.photo" relation. */
  update?: InputMaybe<MetadataRightsNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataRights" node, through the "MetadataRights.photo" relation. */
  upsert?: InputMaybe<MetadataRightsNestedUpsertPhotoUpdateInput>;
};

export type MetadataRightsNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRightsNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRightsNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRightsNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRightsNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRightsNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRightsNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRightsNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRightsNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRightsNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRightsNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRightsNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataRightsOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataRightsUpdateInput = {
  /** Actions for the "MetadataRights.archive" relation */
  archive?: InputMaybe<MetadataRightsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRights.metadata" relation */
  metadata?: InputMaybe<MetadataRightsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataRights.photo" relation */
  photo?: InputMaybe<MetadataRightsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataRights" nodes by specifying some conditions */
export type MetadataRightsWhereInput = {
  AND?: InputMaybe<Array<MetadataRightsWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataRightsWhereInput>;
  OR?: InputMaybe<Array<MetadataRightsWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataRights" node. */
export type MetadataRightsWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataRightsWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataRights.metadata" relation */
  metadata: MetadataRightsNestedMetadataCreateInput;
  /** Actions for the "MetadataRights.photo" relation */
  photo: MetadataRightsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRightsWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataRights.metadata" relation */
  metadata?: InputMaybe<MetadataRightsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataRights.photo" relation */
  photo?: InputMaybe<MetadataRightsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRightsWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataRights.archive" relation */
  archive: MetadataRightsNestedArchiveCreateInput;
  /** Actions for the "MetadataRights.photo" relation */
  photo: MetadataRightsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRightsWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataRights.archive" relation */
  archive?: InputMaybe<MetadataRightsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRights.photo" relation */
  photo?: InputMaybe<MetadataRightsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRightsWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataRights.archive" relation */
  archive: MetadataRightsNestedArchiveCreateInput;
  /** Actions for the "MetadataRights.metadata" relation */
  metadata: MetadataRightsNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRightsWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataRights.archive" relation */
  archive?: InputMaybe<MetadataRightsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRights.metadata" relation */
  metadata?: InputMaybe<MetadataRightsNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataRoyaltyfree" resource's node */
export type MetadataRoyaltyfree = {
  __typename?: 'MetadataRoyaltyfree';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataRoyaltyfreeCreateInput = {
  /** Actions for the "MetadataRoyaltyfree.archive" relation */
  archive: MetadataRoyaltyfreeNestedArchiveCreateInput;
  /** Actions for the "MetadataRoyaltyfree.metadata" relation */
  metadata: MetadataRoyaltyfreeNestedMetadataCreateInput;
  /** Actions for the "MetadataRoyaltyfree.photo" relation */
  photo: MetadataRoyaltyfreeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRoyaltyfreeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.archive" relation. */
  update?: InputMaybe<MetadataRoyaltyfreeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.archive" relation. */
  upsert?: InputMaybe<MetadataRoyaltyfreeNestedUpsertArchiveCreateInput>;
};

export type MetadataRoyaltyfreeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.archive" relation. */
  update?: InputMaybe<MetadataRoyaltyfreeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.archive" relation. */
  upsert?: InputMaybe<MetadataRoyaltyfreeNestedUpsertArchiveUpdateInput>;
};

export type MetadataRoyaltyfreeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.metadata" relation. */
  update?: InputMaybe<MetadataRoyaltyfreeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.metadata" relation. */
  upsert?: InputMaybe<MetadataRoyaltyfreeNestedUpsertMetadataCreateInput>;
};

export type MetadataRoyaltyfreeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.metadata" relation. */
  update?: InputMaybe<MetadataRoyaltyfreeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.metadata" relation. */
  upsert?: InputMaybe<MetadataRoyaltyfreeNestedUpsertMetadataUpdateInput>;
};

export type MetadataRoyaltyfreeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.photo" relation. */
  update?: InputMaybe<MetadataRoyaltyfreeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.photo" relation. */
  upsert?: InputMaybe<MetadataRoyaltyfreeNestedUpsertPhotoCreateInput>;
};

export type MetadataRoyaltyfreeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.photo" relation. */
  update?: InputMaybe<MetadataRoyaltyfreeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataRoyaltyfree" node, through the "MetadataRoyaltyfree.photo" relation. */
  upsert?: InputMaybe<MetadataRoyaltyfreeNestedUpsertPhotoUpdateInput>;
};

export type MetadataRoyaltyfreeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRoyaltyfreeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRoyaltyfreeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRoyaltyfreeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRoyaltyfreeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRoyaltyfreeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRoyaltyfreeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRoyaltyfreeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataRoyaltyfreeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRoyaltyfreeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataRoyaltyfreeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataRoyaltyfreeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataRoyaltyfreeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataRoyaltyfreeUpdateInput = {
  /** Actions for the "MetadataRoyaltyfree.archive" relation */
  archive?: InputMaybe<MetadataRoyaltyfreeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRoyaltyfree.metadata" relation */
  metadata?: InputMaybe<MetadataRoyaltyfreeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataRoyaltyfree.photo" relation */
  photo?: InputMaybe<MetadataRoyaltyfreeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataRoyaltyfree" nodes by specifying some conditions */
export type MetadataRoyaltyfreeWhereInput = {
  AND?: InputMaybe<Array<MetadataRoyaltyfreeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
  OR?: InputMaybe<Array<MetadataRoyaltyfreeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataRoyaltyfree" node. */
export type MetadataRoyaltyfreeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataRoyaltyfreeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataRoyaltyfree.metadata" relation */
  metadata: MetadataRoyaltyfreeNestedMetadataCreateInput;
  /** Actions for the "MetadataRoyaltyfree.photo" relation */
  photo: MetadataRoyaltyfreeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRoyaltyfreeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataRoyaltyfree.metadata" relation */
  metadata?: InputMaybe<MetadataRoyaltyfreeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataRoyaltyfree.photo" relation */
  photo?: InputMaybe<MetadataRoyaltyfreeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRoyaltyfreeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataRoyaltyfree.archive" relation */
  archive: MetadataRoyaltyfreeNestedArchiveCreateInput;
  /** Actions for the "MetadataRoyaltyfree.photo" relation */
  photo: MetadataRoyaltyfreeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRoyaltyfreeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataRoyaltyfree.archive" relation */
  archive?: InputMaybe<MetadataRoyaltyfreeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRoyaltyfree.photo" relation */
  photo?: InputMaybe<MetadataRoyaltyfreeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRoyaltyfreeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataRoyaltyfree.archive" relation */
  archive: MetadataRoyaltyfreeNestedArchiveCreateInput;
  /** Actions for the "MetadataRoyaltyfree.metadata" relation */
  metadata: MetadataRoyaltyfreeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataRoyaltyfreeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataRoyaltyfree.archive" relation */
  archive?: InputMaybe<MetadataRoyaltyfreeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataRoyaltyfree.metadata" relation */
  metadata?: InputMaybe<MetadataRoyaltyfreeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataScene" resource's node */
export type MetadataScene = {
  __typename?: 'MetadataScene';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSceneCreateInput = {
  /** Actions for the "MetadataScene.archive" relation */
  archive: MetadataSceneNestedArchiveCreateInput;
  /** Actions for the "MetadataScene.metadata" relation */
  metadata: MetadataSceneNestedMetadataCreateInput;
  /** Actions for the "MetadataScene.photo" relation */
  photo: MetadataSceneNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSceneNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataScene" node, through the "MetadataScene.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataScene" node, through the "MetadataScene.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataScene" node, through the "MetadataScene.archive" relation. */
  update?: InputMaybe<MetadataSceneNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataScene" node, through the "MetadataScene.archive" relation. */
  upsert?: InputMaybe<MetadataSceneNestedUpsertArchiveCreateInput>;
};

export type MetadataSceneNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataScene" node, through the "MetadataScene.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataScene" node, through the "MetadataScene.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataScene" node, through the "MetadataScene.archive" relation. */
  update?: InputMaybe<MetadataSceneNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataScene" node, through the "MetadataScene.archive" relation. */
  upsert?: InputMaybe<MetadataSceneNestedUpsertArchiveUpdateInput>;
};

export type MetadataSceneNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataScene" node, through the "MetadataScene.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataScene" node, through the "MetadataScene.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataScene" node, through the "MetadataScene.metadata" relation. */
  update?: InputMaybe<MetadataSceneNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataScene" node, through the "MetadataScene.metadata" relation. */
  upsert?: InputMaybe<MetadataSceneNestedUpsertMetadataCreateInput>;
};

export type MetadataSceneNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataScene" node, through the "MetadataScene.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataScene" node, through the "MetadataScene.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataScene" node, through the "MetadataScene.metadata" relation. */
  update?: InputMaybe<MetadataSceneNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataScene" node, through the "MetadataScene.metadata" relation. */
  upsert?: InputMaybe<MetadataSceneNestedUpsertMetadataUpdateInput>;
};

export type MetadataSceneNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataScene" node, through the "MetadataScene.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataScene" node, through the "MetadataScene.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataScene" node, through the "MetadataScene.photo" relation. */
  update?: InputMaybe<MetadataSceneNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataScene" node, through the "MetadataScene.photo" relation. */
  upsert?: InputMaybe<MetadataSceneNestedUpsertPhotoCreateInput>;
};

export type MetadataSceneNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataScene" node, through the "MetadataScene.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataScene" node, through the "MetadataScene.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataScene" node, through the "MetadataScene.photo" relation. */
  update?: InputMaybe<MetadataSceneNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataScene" node, through the "MetadataScene.photo" relation. */
  upsert?: InputMaybe<MetadataSceneNestedUpsertPhotoUpdateInput>;
};

export type MetadataSceneNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSceneNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSceneNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSceneNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSceneNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSceneNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSceneNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSceneNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSceneNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSceneNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSceneNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSceneNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSceneOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSceneUpdateInput = {
  /** Actions for the "MetadataScene.archive" relation */
  archive?: InputMaybe<MetadataSceneNestedArchiveUpdateInput>;
  /** Actions for the "MetadataScene.metadata" relation */
  metadata?: InputMaybe<MetadataSceneNestedMetadataUpdateInput>;
  /** Actions for the "MetadataScene.photo" relation */
  photo?: InputMaybe<MetadataSceneNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataScene" nodes by specifying some conditions */
export type MetadataSceneWhereInput = {
  AND?: InputMaybe<Array<MetadataSceneWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSceneWhereInput>;
  OR?: InputMaybe<Array<MetadataSceneWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataScene" node. */
export type MetadataSceneWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSceneWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataScene.metadata" relation */
  metadata: MetadataSceneNestedMetadataCreateInput;
  /** Actions for the "MetadataScene.photo" relation */
  photo: MetadataSceneNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSceneWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataScene.metadata" relation */
  metadata?: InputMaybe<MetadataSceneNestedMetadataUpdateInput>;
  /** Actions for the "MetadataScene.photo" relation */
  photo?: InputMaybe<MetadataSceneNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSceneWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataScene.archive" relation */
  archive: MetadataSceneNestedArchiveCreateInput;
  /** Actions for the "MetadataScene.photo" relation */
  photo: MetadataSceneNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSceneWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataScene.archive" relation */
  archive?: InputMaybe<MetadataSceneNestedArchiveUpdateInput>;
  /** Actions for the "MetadataScene.photo" relation */
  photo?: InputMaybe<MetadataSceneNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSceneWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataScene.archive" relation */
  archive: MetadataSceneNestedArchiveCreateInput;
  /** Actions for the "MetadataScene.metadata" relation */
  metadata: MetadataSceneNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSceneWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataScene.archive" relation */
  archive?: InputMaybe<MetadataSceneNestedArchiveUpdateInput>;
  /** Actions for the "MetadataScene.metadata" relation */
  metadata?: InputMaybe<MetadataSceneNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataSection" resource's node */
export type MetadataSection = {
  __typename?: 'MetadataSection';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSectionCreateInput = {
  /** Actions for the "MetadataSection.archive" relation */
  archive: MetadataSectionNestedArchiveCreateInput;
  /** Actions for the "MetadataSection.metadata" relation */
  metadata: MetadataSectionNestedMetadataCreateInput;
  /** Actions for the "MetadataSection.photo" relation */
  photo: MetadataSectionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSectionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSection" node, through the "MetadataSection.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSection" node, through the "MetadataSection.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSection" node, through the "MetadataSection.archive" relation. */
  update?: InputMaybe<MetadataSectionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSection" node, through the "MetadataSection.archive" relation. */
  upsert?: InputMaybe<MetadataSectionNestedUpsertArchiveCreateInput>;
};

export type MetadataSectionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSection" node, through the "MetadataSection.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSection" node, through the "MetadataSection.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSection" node, through the "MetadataSection.archive" relation. */
  update?: InputMaybe<MetadataSectionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSection" node, through the "MetadataSection.archive" relation. */
  upsert?: InputMaybe<MetadataSectionNestedUpsertArchiveUpdateInput>;
};

export type MetadataSectionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSection" node, through the "MetadataSection.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSection" node, through the "MetadataSection.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSection" node, through the "MetadataSection.metadata" relation. */
  update?: InputMaybe<MetadataSectionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSection" node, through the "MetadataSection.metadata" relation. */
  upsert?: InputMaybe<MetadataSectionNestedUpsertMetadataCreateInput>;
};

export type MetadataSectionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSection" node, through the "MetadataSection.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSection" node, through the "MetadataSection.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSection" node, through the "MetadataSection.metadata" relation. */
  update?: InputMaybe<MetadataSectionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSection" node, through the "MetadataSection.metadata" relation. */
  upsert?: InputMaybe<MetadataSectionNestedUpsertMetadataUpdateInput>;
};

export type MetadataSectionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSection" node, through the "MetadataSection.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSection" node, through the "MetadataSection.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSection" node, through the "MetadataSection.photo" relation. */
  update?: InputMaybe<MetadataSectionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSection" node, through the "MetadataSection.photo" relation. */
  upsert?: InputMaybe<MetadataSectionNestedUpsertPhotoCreateInput>;
};

export type MetadataSectionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSection" node, through the "MetadataSection.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSection" node, through the "MetadataSection.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSection" node, through the "MetadataSection.photo" relation. */
  update?: InputMaybe<MetadataSectionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSection" node, through the "MetadataSection.photo" relation. */
  upsert?: InputMaybe<MetadataSectionNestedUpsertPhotoUpdateInput>;
};

export type MetadataSectionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSectionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSectionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSectionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSectionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSectionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSectionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSectionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSectionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSectionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSectionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSectionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSectionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSectionUpdateInput = {
  /** Actions for the "MetadataSection.archive" relation */
  archive?: InputMaybe<MetadataSectionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSection.metadata" relation */
  metadata?: InputMaybe<MetadataSectionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSection.photo" relation */
  photo?: InputMaybe<MetadataSectionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSection" nodes by specifying some conditions */
export type MetadataSectionWhereInput = {
  AND?: InputMaybe<Array<MetadataSectionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSectionWhereInput>;
  OR?: InputMaybe<Array<MetadataSectionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSection" node. */
export type MetadataSectionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSectionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSection.metadata" relation */
  metadata: MetadataSectionNestedMetadataCreateInput;
  /** Actions for the "MetadataSection.photo" relation */
  photo: MetadataSectionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSectionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSection.metadata" relation */
  metadata?: InputMaybe<MetadataSectionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSection.photo" relation */
  photo?: InputMaybe<MetadataSectionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSectionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSection.archive" relation */
  archive: MetadataSectionNestedArchiveCreateInput;
  /** Actions for the "MetadataSection.photo" relation */
  photo: MetadataSectionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSectionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSection.archive" relation */
  archive?: InputMaybe<MetadataSectionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSection.photo" relation */
  photo?: InputMaybe<MetadataSectionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSectionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSection.archive" relation */
  archive: MetadataSectionNestedArchiveCreateInput;
  /** Actions for the "MetadataSection.metadata" relation */
  metadata: MetadataSectionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSectionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSection.archive" relation */
  archive?: InputMaybe<MetadataSectionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSection.metadata" relation */
  metadata?: InputMaybe<MetadataSectionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataSentTo" resource's node */
export type MetadataSentTo = {
  __typename?: 'MetadataSentTo';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSentToCreateInput = {
  /** Actions for the "MetadataSentTo.archive" relation */
  archive: MetadataSentToNestedArchiveCreateInput;
  /** Actions for the "MetadataSentTo.metadata" relation */
  metadata: MetadataSentToNestedMetadataCreateInput;
  /** Actions for the "MetadataSentTo.photo" relation */
  photo: MetadataSentToNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSentToNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSentTo" node, through the "MetadataSentTo.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSentTo" node, through the "MetadataSentTo.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSentTo" node, through the "MetadataSentTo.archive" relation. */
  update?: InputMaybe<MetadataSentToNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSentTo" node, through the "MetadataSentTo.archive" relation. */
  upsert?: InputMaybe<MetadataSentToNestedUpsertArchiveCreateInput>;
};

export type MetadataSentToNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSentTo" node, through the "MetadataSentTo.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSentTo" node, through the "MetadataSentTo.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSentTo" node, through the "MetadataSentTo.archive" relation. */
  update?: InputMaybe<MetadataSentToNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSentTo" node, through the "MetadataSentTo.archive" relation. */
  upsert?: InputMaybe<MetadataSentToNestedUpsertArchiveUpdateInput>;
};

export type MetadataSentToNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSentTo" node, through the "MetadataSentTo.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSentTo" node, through the "MetadataSentTo.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSentTo" node, through the "MetadataSentTo.metadata" relation. */
  update?: InputMaybe<MetadataSentToNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSentTo" node, through the "MetadataSentTo.metadata" relation. */
  upsert?: InputMaybe<MetadataSentToNestedUpsertMetadataCreateInput>;
};

export type MetadataSentToNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSentTo" node, through the "MetadataSentTo.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSentTo" node, through the "MetadataSentTo.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSentTo" node, through the "MetadataSentTo.metadata" relation. */
  update?: InputMaybe<MetadataSentToNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSentTo" node, through the "MetadataSentTo.metadata" relation. */
  upsert?: InputMaybe<MetadataSentToNestedUpsertMetadataUpdateInput>;
};

export type MetadataSentToNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSentTo" node, through the "MetadataSentTo.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSentTo" node, through the "MetadataSentTo.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSentTo" node, through the "MetadataSentTo.photo" relation. */
  update?: InputMaybe<MetadataSentToNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSentTo" node, through the "MetadataSentTo.photo" relation. */
  upsert?: InputMaybe<MetadataSentToNestedUpsertPhotoCreateInput>;
};

export type MetadataSentToNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSentTo" node, through the "MetadataSentTo.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSentTo" node, through the "MetadataSentTo.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSentTo" node, through the "MetadataSentTo.photo" relation. */
  update?: InputMaybe<MetadataSentToNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSentTo" node, through the "MetadataSentTo.photo" relation. */
  upsert?: InputMaybe<MetadataSentToNestedUpsertPhotoUpdateInput>;
};

export type MetadataSentToNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSentToNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSentToNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSentToNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSentToNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSentToNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSentToNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSentToNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSentToNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSentToNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSentToNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSentToNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSentToOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSentToUpdateInput = {
  /** Actions for the "MetadataSentTo.archive" relation */
  archive?: InputMaybe<MetadataSentToNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSentTo.metadata" relation */
  metadata?: InputMaybe<MetadataSentToNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSentTo.photo" relation */
  photo?: InputMaybe<MetadataSentToNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSentTo" nodes by specifying some conditions */
export type MetadataSentToWhereInput = {
  AND?: InputMaybe<Array<MetadataSentToWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSentToWhereInput>;
  OR?: InputMaybe<Array<MetadataSentToWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSentTo" node. */
export type MetadataSentToWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSentToWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSentTo.metadata" relation */
  metadata: MetadataSentToNestedMetadataCreateInput;
  /** Actions for the "MetadataSentTo.photo" relation */
  photo: MetadataSentToNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSentToWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSentTo.metadata" relation */
  metadata?: InputMaybe<MetadataSentToNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSentTo.photo" relation */
  photo?: InputMaybe<MetadataSentToNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSentToWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSentTo.archive" relation */
  archive: MetadataSentToNestedArchiveCreateInput;
  /** Actions for the "MetadataSentTo.photo" relation */
  photo: MetadataSentToNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSentToWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSentTo.archive" relation */
  archive?: InputMaybe<MetadataSentToNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSentTo.photo" relation */
  photo?: InputMaybe<MetadataSentToNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSentToWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSentTo.archive" relation */
  archive: MetadataSentToNestedArchiveCreateInput;
  /** Actions for the "MetadataSentTo.metadata" relation */
  metadata: MetadataSentToNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSentToWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSentTo.archive" relation */
  archive?: InputMaybe<MetadataSentToNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSentTo.metadata" relation */
  metadata?: InputMaybe<MetadataSentToNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataSerialNumber" resource's node */
export type MetadataSerialNumber = {
  __typename?: 'MetadataSerialNumber';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSerialNumberCreateInput = {
  /** Actions for the "MetadataSerialNumber.archive" relation */
  archive: MetadataSerialNumberNestedArchiveCreateInput;
  /** Actions for the "MetadataSerialNumber.metadata" relation */
  metadata: MetadataSerialNumberNestedMetadataCreateInput;
  /** Actions for the "MetadataSerialNumber.photo" relation */
  photo: MetadataSerialNumberNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSerialNumberNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.archive" relation. */
  update?: InputMaybe<MetadataSerialNumberNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.archive" relation. */
  upsert?: InputMaybe<MetadataSerialNumberNestedUpsertArchiveCreateInput>;
};

export type MetadataSerialNumberNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.archive" relation. */
  update?: InputMaybe<MetadataSerialNumberNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.archive" relation. */
  upsert?: InputMaybe<MetadataSerialNumberNestedUpsertArchiveUpdateInput>;
};

export type MetadataSerialNumberNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.metadata" relation. */
  update?: InputMaybe<MetadataSerialNumberNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.metadata" relation. */
  upsert?: InputMaybe<MetadataSerialNumberNestedUpsertMetadataCreateInput>;
};

export type MetadataSerialNumberNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.metadata" relation. */
  update?: InputMaybe<MetadataSerialNumberNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.metadata" relation. */
  upsert?: InputMaybe<MetadataSerialNumberNestedUpsertMetadataUpdateInput>;
};

export type MetadataSerialNumberNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.photo" relation. */
  update?: InputMaybe<MetadataSerialNumberNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSerialNumber" node, through the "MetadataSerialNumber.photo" relation. */
  upsert?: InputMaybe<MetadataSerialNumberNestedUpsertPhotoCreateInput>;
};

export type MetadataSerialNumberNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.photo" relation. */
  update?: InputMaybe<MetadataSerialNumberNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSerialNumber" node, through the "MetadataSerialNumber.photo" relation. */
  upsert?: InputMaybe<MetadataSerialNumberNestedUpsertPhotoUpdateInput>;
};

export type MetadataSerialNumberNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSerialNumberNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSerialNumberNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSerialNumberNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSerialNumberNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSerialNumberNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSerialNumberNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSerialNumberNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSerialNumberNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSerialNumberNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSerialNumberNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSerialNumberNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSerialNumberOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSerialNumberUpdateInput = {
  /** Actions for the "MetadataSerialNumber.archive" relation */
  archive?: InputMaybe<MetadataSerialNumberNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSerialNumber.metadata" relation */
  metadata?: InputMaybe<MetadataSerialNumberNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSerialNumber.photo" relation */
  photo?: InputMaybe<MetadataSerialNumberNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSerialNumber" nodes by specifying some conditions */
export type MetadataSerialNumberWhereInput = {
  AND?: InputMaybe<Array<MetadataSerialNumberWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSerialNumberWhereInput>;
  OR?: InputMaybe<Array<MetadataSerialNumberWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSerialNumber" node. */
export type MetadataSerialNumberWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSerialNumberWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSerialNumber.metadata" relation */
  metadata: MetadataSerialNumberNestedMetadataCreateInput;
  /** Actions for the "MetadataSerialNumber.photo" relation */
  photo: MetadataSerialNumberNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSerialNumberWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSerialNumber.metadata" relation */
  metadata?: InputMaybe<MetadataSerialNumberNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSerialNumber.photo" relation */
  photo?: InputMaybe<MetadataSerialNumberNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSerialNumberWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSerialNumber.archive" relation */
  archive: MetadataSerialNumberNestedArchiveCreateInput;
  /** Actions for the "MetadataSerialNumber.photo" relation */
  photo: MetadataSerialNumberNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSerialNumberWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSerialNumber.archive" relation */
  archive?: InputMaybe<MetadataSerialNumberNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSerialNumber.photo" relation */
  photo?: InputMaybe<MetadataSerialNumberNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSerialNumberWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSerialNumber.archive" relation */
  archive: MetadataSerialNumberNestedArchiveCreateInput;
  /** Actions for the "MetadataSerialNumber.metadata" relation */
  metadata: MetadataSerialNumberNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSerialNumberWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSerialNumber.archive" relation */
  archive?: InputMaybe<MetadataSerialNumberNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSerialNumber.metadata" relation */
  metadata?: InputMaybe<MetadataSerialNumberNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataSeriesDateTime" resource's node */
export type MetadataSeriesDateTime = {
  __typename?: 'MetadataSeriesDateTime';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSeriesDateTimeCreateInput = {
  /** Actions for the "MetadataSeriesDateTime.archive" relation */
  archive: MetadataSeriesDateTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesDateTime.metadata" relation */
  metadata: MetadataSeriesDateTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataSeriesDateTime.photo" relation */
  photo: MetadataSeriesDateTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDateTimeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.archive" relation. */
  update?: InputMaybe<MetadataSeriesDateTimeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.archive" relation. */
  upsert?: InputMaybe<MetadataSeriesDateTimeNestedUpsertArchiveCreateInput>;
};

export type MetadataSeriesDateTimeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.archive" relation. */
  update?: InputMaybe<MetadataSeriesDateTimeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.archive" relation. */
  upsert?: InputMaybe<MetadataSeriesDateTimeNestedUpsertArchiveUpdateInput>;
};

export type MetadataSeriesDateTimeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.metadata" relation. */
  update?: InputMaybe<MetadataSeriesDateTimeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.metadata" relation. */
  upsert?: InputMaybe<MetadataSeriesDateTimeNestedUpsertMetadataCreateInput>;
};

export type MetadataSeriesDateTimeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.metadata" relation. */
  update?: InputMaybe<MetadataSeriesDateTimeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.metadata" relation. */
  upsert?: InputMaybe<MetadataSeriesDateTimeNestedUpsertMetadataUpdateInput>;
};

export type MetadataSeriesDateTimeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.photo" relation. */
  update?: InputMaybe<MetadataSeriesDateTimeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.photo" relation. */
  upsert?: InputMaybe<MetadataSeriesDateTimeNestedUpsertPhotoCreateInput>;
};

export type MetadataSeriesDateTimeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.photo" relation. */
  update?: InputMaybe<MetadataSeriesDateTimeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSeriesDateTime" node, through the "MetadataSeriesDateTime.photo" relation. */
  upsert?: InputMaybe<MetadataSeriesDateTimeNestedUpsertPhotoUpdateInput>;
};

export type MetadataSeriesDateTimeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesDateTimeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesDateTimeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesDateTimeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesDateTimeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesDateTimeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesDateTimeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesDateTimeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesDateTimeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesDateTimeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesDateTimeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesDateTimeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSeriesDateTimeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSeriesDateTimeUpdateInput = {
  /** Actions for the "MetadataSeriesDateTime.archive" relation */
  archive?: InputMaybe<MetadataSeriesDateTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesDateTime.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesDateTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSeriesDateTime.photo" relation */
  photo?: InputMaybe<MetadataSeriesDateTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSeriesDateTime" nodes by specifying some conditions */
export type MetadataSeriesDateTimeWhereInput = {
  AND?: InputMaybe<Array<MetadataSeriesDateTimeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
  OR?: InputMaybe<Array<MetadataSeriesDateTimeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSeriesDateTime" node. */
export type MetadataSeriesDateTimeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSeriesDateTimeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSeriesDateTime.metadata" relation */
  metadata: MetadataSeriesDateTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataSeriesDateTime.photo" relation */
  photo: MetadataSeriesDateTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDateTimeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSeriesDateTime.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesDateTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSeriesDateTime.photo" relation */
  photo?: InputMaybe<MetadataSeriesDateTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDateTimeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSeriesDateTime.archive" relation */
  archive: MetadataSeriesDateTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesDateTime.photo" relation */
  photo: MetadataSeriesDateTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDateTimeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSeriesDateTime.archive" relation */
  archive?: InputMaybe<MetadataSeriesDateTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesDateTime.photo" relation */
  photo?: InputMaybe<MetadataSeriesDateTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDateTimeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSeriesDateTime.archive" relation */
  archive: MetadataSeriesDateTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesDateTime.metadata" relation */
  metadata: MetadataSeriesDateTimeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDateTimeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSeriesDateTime.archive" relation */
  archive?: InputMaybe<MetadataSeriesDateTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesDateTime.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesDateTimeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataSeriesDescription" resource's node */
export type MetadataSeriesDescription = {
  __typename?: 'MetadataSeriesDescription';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSeriesDescriptionCreateInput = {
  /** Actions for the "MetadataSeriesDescription.archive" relation */
  archive: MetadataSeriesDescriptionNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesDescription.metadata" relation */
  metadata: MetadataSeriesDescriptionNestedMetadataCreateInput;
  /** Actions for the "MetadataSeriesDescription.photo" relation */
  photo: MetadataSeriesDescriptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDescriptionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.archive" relation. */
  update?: InputMaybe<MetadataSeriesDescriptionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.archive" relation. */
  upsert?: InputMaybe<MetadataSeriesDescriptionNestedUpsertArchiveCreateInput>;
};

export type MetadataSeriesDescriptionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.archive" relation. */
  update?: InputMaybe<MetadataSeriesDescriptionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.archive" relation. */
  upsert?: InputMaybe<MetadataSeriesDescriptionNestedUpsertArchiveUpdateInput>;
};

export type MetadataSeriesDescriptionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.metadata" relation. */
  update?: InputMaybe<MetadataSeriesDescriptionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.metadata" relation. */
  upsert?: InputMaybe<MetadataSeriesDescriptionNestedUpsertMetadataCreateInput>;
};

export type MetadataSeriesDescriptionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.metadata" relation. */
  update?: InputMaybe<MetadataSeriesDescriptionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.metadata" relation. */
  upsert?: InputMaybe<MetadataSeriesDescriptionNestedUpsertMetadataUpdateInput>;
};

export type MetadataSeriesDescriptionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.photo" relation. */
  update?: InputMaybe<MetadataSeriesDescriptionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.photo" relation. */
  upsert?: InputMaybe<MetadataSeriesDescriptionNestedUpsertPhotoCreateInput>;
};

export type MetadataSeriesDescriptionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.photo" relation. */
  update?: InputMaybe<MetadataSeriesDescriptionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSeriesDescription" node, through the "MetadataSeriesDescription.photo" relation. */
  upsert?: InputMaybe<MetadataSeriesDescriptionNestedUpsertPhotoUpdateInput>;
};

export type MetadataSeriesDescriptionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesDescriptionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesDescriptionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesDescriptionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesDescriptionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesDescriptionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesDescriptionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesDescriptionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesDescriptionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesDescriptionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesDescriptionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesDescriptionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSeriesDescriptionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSeriesDescriptionUpdateInput = {
  /** Actions for the "MetadataSeriesDescription.archive" relation */
  archive?: InputMaybe<MetadataSeriesDescriptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesDescription.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesDescriptionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSeriesDescription.photo" relation */
  photo?: InputMaybe<MetadataSeriesDescriptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSeriesDescription" nodes by specifying some conditions */
export type MetadataSeriesDescriptionWhereInput = {
  AND?: InputMaybe<Array<MetadataSeriesDescriptionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
  OR?: InputMaybe<Array<MetadataSeriesDescriptionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSeriesDescription" node. */
export type MetadataSeriesDescriptionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSeriesDescriptionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSeriesDescription.metadata" relation */
  metadata: MetadataSeriesDescriptionNestedMetadataCreateInput;
  /** Actions for the "MetadataSeriesDescription.photo" relation */
  photo: MetadataSeriesDescriptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDescriptionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSeriesDescription.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesDescriptionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSeriesDescription.photo" relation */
  photo?: InputMaybe<MetadataSeriesDescriptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDescriptionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSeriesDescription.archive" relation */
  archive: MetadataSeriesDescriptionNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesDescription.photo" relation */
  photo: MetadataSeriesDescriptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDescriptionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSeriesDescription.archive" relation */
  archive?: InputMaybe<MetadataSeriesDescriptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesDescription.photo" relation */
  photo?: InputMaybe<MetadataSeriesDescriptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDescriptionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSeriesDescription.archive" relation */
  archive: MetadataSeriesDescriptionNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesDescription.metadata" relation */
  metadata: MetadataSeriesDescriptionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesDescriptionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSeriesDescription.archive" relation */
  archive?: InputMaybe<MetadataSeriesDescriptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesDescription.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesDescriptionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataSeriesModality" resource's node */
export type MetadataSeriesModality = {
  __typename?: 'MetadataSeriesModality';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSeriesModalityCreateInput = {
  /** Actions for the "MetadataSeriesModality.archive" relation */
  archive: MetadataSeriesModalityNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesModality.metadata" relation */
  metadata: MetadataSeriesModalityNestedMetadataCreateInput;
  /** Actions for the "MetadataSeriesModality.photo" relation */
  photo: MetadataSeriesModalityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesModalityNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.archive" relation. */
  update?: InputMaybe<MetadataSeriesModalityNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.archive" relation. */
  upsert?: InputMaybe<MetadataSeriesModalityNestedUpsertArchiveCreateInput>;
};

export type MetadataSeriesModalityNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.archive" relation. */
  update?: InputMaybe<MetadataSeriesModalityNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.archive" relation. */
  upsert?: InputMaybe<MetadataSeriesModalityNestedUpsertArchiveUpdateInput>;
};

export type MetadataSeriesModalityNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.metadata" relation. */
  update?: InputMaybe<MetadataSeriesModalityNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.metadata" relation. */
  upsert?: InputMaybe<MetadataSeriesModalityNestedUpsertMetadataCreateInput>;
};

export type MetadataSeriesModalityNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.metadata" relation. */
  update?: InputMaybe<MetadataSeriesModalityNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.metadata" relation. */
  upsert?: InputMaybe<MetadataSeriesModalityNestedUpsertMetadataUpdateInput>;
};

export type MetadataSeriesModalityNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.photo" relation. */
  update?: InputMaybe<MetadataSeriesModalityNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSeriesModality" node, through the "MetadataSeriesModality.photo" relation. */
  upsert?: InputMaybe<MetadataSeriesModalityNestedUpsertPhotoCreateInput>;
};

export type MetadataSeriesModalityNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.photo" relation. */
  update?: InputMaybe<MetadataSeriesModalityNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSeriesModality" node, through the "MetadataSeriesModality.photo" relation. */
  upsert?: InputMaybe<MetadataSeriesModalityNestedUpsertPhotoUpdateInput>;
};

export type MetadataSeriesModalityNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesModalityNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesModalityNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesModalityNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesModalityNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesModalityNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesModalityNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesModalityNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesModalityNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesModalityNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesModalityNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesModalityNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSeriesModalityOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSeriesModalityUpdateInput = {
  /** Actions for the "MetadataSeriesModality.archive" relation */
  archive?: InputMaybe<MetadataSeriesModalityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesModality.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesModalityNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSeriesModality.photo" relation */
  photo?: InputMaybe<MetadataSeriesModalityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSeriesModality" nodes by specifying some conditions */
export type MetadataSeriesModalityWhereInput = {
  AND?: InputMaybe<Array<MetadataSeriesModalityWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSeriesModalityWhereInput>;
  OR?: InputMaybe<Array<MetadataSeriesModalityWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSeriesModality" node. */
export type MetadataSeriesModalityWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSeriesModalityWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSeriesModality.metadata" relation */
  metadata: MetadataSeriesModalityNestedMetadataCreateInput;
  /** Actions for the "MetadataSeriesModality.photo" relation */
  photo: MetadataSeriesModalityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesModalityWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSeriesModality.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesModalityNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSeriesModality.photo" relation */
  photo?: InputMaybe<MetadataSeriesModalityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesModalityWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSeriesModality.archive" relation */
  archive: MetadataSeriesModalityNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesModality.photo" relation */
  photo: MetadataSeriesModalityNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesModalityWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSeriesModality.archive" relation */
  archive?: InputMaybe<MetadataSeriesModalityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesModality.photo" relation */
  photo?: InputMaybe<MetadataSeriesModalityNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesModalityWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSeriesModality.archive" relation */
  archive: MetadataSeriesModalityNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesModality.metadata" relation */
  metadata: MetadataSeriesModalityNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesModalityWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSeriesModality.archive" relation */
  archive?: InputMaybe<MetadataSeriesModalityNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesModality.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesModalityNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataSeriesNumber" resource's node */
export type MetadataSeriesNumber = {
  __typename?: 'MetadataSeriesNumber';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSeriesNumberCreateInput = {
  /** Actions for the "MetadataSeriesNumber.archive" relation */
  archive: MetadataSeriesNumberNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesNumber.metadata" relation */
  metadata: MetadataSeriesNumberNestedMetadataCreateInput;
  /** Actions for the "MetadataSeriesNumber.photo" relation */
  photo: MetadataSeriesNumberNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesNumberNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.archive" relation. */
  update?: InputMaybe<MetadataSeriesNumberNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.archive" relation. */
  upsert?: InputMaybe<MetadataSeriesNumberNestedUpsertArchiveCreateInput>;
};

export type MetadataSeriesNumberNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.archive" relation. */
  update?: InputMaybe<MetadataSeriesNumberNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.archive" relation. */
  upsert?: InputMaybe<MetadataSeriesNumberNestedUpsertArchiveUpdateInput>;
};

export type MetadataSeriesNumberNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.metadata" relation. */
  update?: InputMaybe<MetadataSeriesNumberNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.metadata" relation. */
  upsert?: InputMaybe<MetadataSeriesNumberNestedUpsertMetadataCreateInput>;
};

export type MetadataSeriesNumberNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.metadata" relation. */
  update?: InputMaybe<MetadataSeriesNumberNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.metadata" relation. */
  upsert?: InputMaybe<MetadataSeriesNumberNestedUpsertMetadataUpdateInput>;
};

export type MetadataSeriesNumberNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.photo" relation. */
  update?: InputMaybe<MetadataSeriesNumberNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.photo" relation. */
  upsert?: InputMaybe<MetadataSeriesNumberNestedUpsertPhotoCreateInput>;
};

export type MetadataSeriesNumberNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.photo" relation. */
  update?: InputMaybe<MetadataSeriesNumberNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSeriesNumber" node, through the "MetadataSeriesNumber.photo" relation. */
  upsert?: InputMaybe<MetadataSeriesNumberNestedUpsertPhotoUpdateInput>;
};

export type MetadataSeriesNumberNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesNumberNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesNumberNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesNumberNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesNumberNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesNumberNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesNumberNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesNumberNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSeriesNumberNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesNumberNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSeriesNumberNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSeriesNumberNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSeriesNumberOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSeriesNumberUpdateInput = {
  /** Actions for the "MetadataSeriesNumber.archive" relation */
  archive?: InputMaybe<MetadataSeriesNumberNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesNumber.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesNumberNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSeriesNumber.photo" relation */
  photo?: InputMaybe<MetadataSeriesNumberNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSeriesNumber" nodes by specifying some conditions */
export type MetadataSeriesNumberWhereInput = {
  AND?: InputMaybe<Array<MetadataSeriesNumberWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSeriesNumberWhereInput>;
  OR?: InputMaybe<Array<MetadataSeriesNumberWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSeriesNumber" node. */
export type MetadataSeriesNumberWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSeriesNumberWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSeriesNumber.metadata" relation */
  metadata: MetadataSeriesNumberNestedMetadataCreateInput;
  /** Actions for the "MetadataSeriesNumber.photo" relation */
  photo: MetadataSeriesNumberNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesNumberWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSeriesNumber.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesNumberNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSeriesNumber.photo" relation */
  photo?: InputMaybe<MetadataSeriesNumberNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesNumberWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSeriesNumber.archive" relation */
  archive: MetadataSeriesNumberNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesNumber.photo" relation */
  photo: MetadataSeriesNumberNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesNumberWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSeriesNumber.archive" relation */
  archive?: InputMaybe<MetadataSeriesNumberNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesNumber.photo" relation */
  photo?: InputMaybe<MetadataSeriesNumberNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesNumberWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSeriesNumber.archive" relation */
  archive: MetadataSeriesNumberNestedArchiveCreateInput;
  /** Actions for the "MetadataSeriesNumber.metadata" relation */
  metadata: MetadataSeriesNumberNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSeriesNumberWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSeriesNumber.archive" relation */
  archive?: InputMaybe<MetadataSeriesNumberNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSeriesNumber.metadata" relation */
  metadata?: InputMaybe<MetadataSeriesNumberNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataShortUniqueId" resource's node */
export type MetadataShortUniqueId = {
  __typename?: 'MetadataShortUniqueId';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataShortUniqueIdCreateInput = {
  /** Actions for the "MetadataShortUniqueId.archive" relation */
  archive: MetadataShortUniqueIdNestedArchiveCreateInput;
  /** Actions for the "MetadataShortUniqueId.metadata" relation */
  metadata: MetadataShortUniqueIdNestedMetadataCreateInput;
  /** Actions for the "MetadataShortUniqueId.photo" relation */
  photo: MetadataShortUniqueIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataShortUniqueIdNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.archive" relation. */
  update?: InputMaybe<MetadataShortUniqueIdNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.archive" relation. */
  upsert?: InputMaybe<MetadataShortUniqueIdNestedUpsertArchiveCreateInput>;
};

export type MetadataShortUniqueIdNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.archive" relation. */
  update?: InputMaybe<MetadataShortUniqueIdNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.archive" relation. */
  upsert?: InputMaybe<MetadataShortUniqueIdNestedUpsertArchiveUpdateInput>;
};

export type MetadataShortUniqueIdNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.metadata" relation. */
  update?: InputMaybe<MetadataShortUniqueIdNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.metadata" relation. */
  upsert?: InputMaybe<MetadataShortUniqueIdNestedUpsertMetadataCreateInput>;
};

export type MetadataShortUniqueIdNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.metadata" relation. */
  update?: InputMaybe<MetadataShortUniqueIdNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.metadata" relation. */
  upsert?: InputMaybe<MetadataShortUniqueIdNestedUpsertMetadataUpdateInput>;
};

export type MetadataShortUniqueIdNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.photo" relation. */
  update?: InputMaybe<MetadataShortUniqueIdNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.photo" relation. */
  upsert?: InputMaybe<MetadataShortUniqueIdNestedUpsertPhotoCreateInput>;
};

export type MetadataShortUniqueIdNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.photo" relation. */
  update?: InputMaybe<MetadataShortUniqueIdNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataShortUniqueId" node, through the "MetadataShortUniqueId.photo" relation. */
  upsert?: InputMaybe<MetadataShortUniqueIdNestedUpsertPhotoUpdateInput>;
};

export type MetadataShortUniqueIdNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataShortUniqueIdNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataShortUniqueIdNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataShortUniqueIdNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataShortUniqueIdNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataShortUniqueIdNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataShortUniqueIdNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataShortUniqueIdNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataShortUniqueIdNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataShortUniqueIdNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataShortUniqueIdNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataShortUniqueIdNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataShortUniqueIdOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataShortUniqueIdUpdateInput = {
  /** Actions for the "MetadataShortUniqueId.archive" relation */
  archive?: InputMaybe<MetadataShortUniqueIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataShortUniqueId.metadata" relation */
  metadata?: InputMaybe<MetadataShortUniqueIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataShortUniqueId.photo" relation */
  photo?: InputMaybe<MetadataShortUniqueIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataShortUniqueId" nodes by specifying some conditions */
export type MetadataShortUniqueIdWhereInput = {
  AND?: InputMaybe<Array<MetadataShortUniqueIdWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataShortUniqueIdWhereInput>;
  OR?: InputMaybe<Array<MetadataShortUniqueIdWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataShortUniqueId" node. */
export type MetadataShortUniqueIdWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataShortUniqueIdWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataShortUniqueId.metadata" relation */
  metadata: MetadataShortUniqueIdNestedMetadataCreateInput;
  /** Actions for the "MetadataShortUniqueId.photo" relation */
  photo: MetadataShortUniqueIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataShortUniqueIdWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataShortUniqueId.metadata" relation */
  metadata?: InputMaybe<MetadataShortUniqueIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataShortUniqueId.photo" relation */
  photo?: InputMaybe<MetadataShortUniqueIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataShortUniqueIdWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataShortUniqueId.archive" relation */
  archive: MetadataShortUniqueIdNestedArchiveCreateInput;
  /** Actions for the "MetadataShortUniqueId.photo" relation */
  photo: MetadataShortUniqueIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataShortUniqueIdWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataShortUniqueId.archive" relation */
  archive?: InputMaybe<MetadataShortUniqueIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataShortUniqueId.photo" relation */
  photo?: InputMaybe<MetadataShortUniqueIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataShortUniqueIdWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataShortUniqueId.archive" relation */
  archive: MetadataShortUniqueIdNestedArchiveCreateInput;
  /** Actions for the "MetadataShortUniqueId.metadata" relation */
  metadata: MetadataShortUniqueIdNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataShortUniqueIdWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataShortUniqueId.archive" relation */
  archive?: InputMaybe<MetadataShortUniqueIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataShortUniqueId.metadata" relation */
  metadata?: InputMaybe<MetadataShortUniqueIdNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataSource" resource's node */
export type MetadataSource = {
  __typename?: 'MetadataSource';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSourceCreateInput = {
  /** Actions for the "MetadataSource.archive" relation */
  archive: MetadataSourceNestedArchiveCreateInput;
  /** Actions for the "MetadataSource.metadata" relation */
  metadata: MetadataSourceNestedMetadataCreateInput;
  /** Actions for the "MetadataSource.photo" relation */
  photo: MetadataSourceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSourceNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSource" node, through the "MetadataSource.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSource" node, through the "MetadataSource.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSource" node, through the "MetadataSource.archive" relation. */
  update?: InputMaybe<MetadataSourceNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSource" node, through the "MetadataSource.archive" relation. */
  upsert?: InputMaybe<MetadataSourceNestedUpsertArchiveCreateInput>;
};

export type MetadataSourceNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSource" node, through the "MetadataSource.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSource" node, through the "MetadataSource.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSource" node, through the "MetadataSource.archive" relation. */
  update?: InputMaybe<MetadataSourceNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSource" node, through the "MetadataSource.archive" relation. */
  upsert?: InputMaybe<MetadataSourceNestedUpsertArchiveUpdateInput>;
};

export type MetadataSourceNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSource" node, through the "MetadataSource.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSource" node, through the "MetadataSource.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSource" node, through the "MetadataSource.metadata" relation. */
  update?: InputMaybe<MetadataSourceNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSource" node, through the "MetadataSource.metadata" relation. */
  upsert?: InputMaybe<MetadataSourceNestedUpsertMetadataCreateInput>;
};

export type MetadataSourceNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSource" node, through the "MetadataSource.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSource" node, through the "MetadataSource.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSource" node, through the "MetadataSource.metadata" relation. */
  update?: InputMaybe<MetadataSourceNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSource" node, through the "MetadataSource.metadata" relation. */
  upsert?: InputMaybe<MetadataSourceNestedUpsertMetadataUpdateInput>;
};

export type MetadataSourceNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSource" node, through the "MetadataSource.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSource" node, through the "MetadataSource.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSource" node, through the "MetadataSource.photo" relation. */
  update?: InputMaybe<MetadataSourceNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSource" node, through the "MetadataSource.photo" relation. */
  upsert?: InputMaybe<MetadataSourceNestedUpsertPhotoCreateInput>;
};

export type MetadataSourceNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSource" node, through the "MetadataSource.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSource" node, through the "MetadataSource.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSource" node, through the "MetadataSource.photo" relation. */
  update?: InputMaybe<MetadataSourceNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSource" node, through the "MetadataSource.photo" relation. */
  upsert?: InputMaybe<MetadataSourceNestedUpsertPhotoUpdateInput>;
};

export type MetadataSourceNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSourceNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSourceNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSourceNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSourceNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSourceNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSourceNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSourceNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSourceNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSourceNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSourceNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSourceNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSourceOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSourceUpdateInput = {
  /** Actions for the "MetadataSource.archive" relation */
  archive?: InputMaybe<MetadataSourceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSource.metadata" relation */
  metadata?: InputMaybe<MetadataSourceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSource.photo" relation */
  photo?: InputMaybe<MetadataSourceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSource" nodes by specifying some conditions */
export type MetadataSourceWhereInput = {
  AND?: InputMaybe<Array<MetadataSourceWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSourceWhereInput>;
  OR?: InputMaybe<Array<MetadataSourceWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSource" node. */
export type MetadataSourceWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSourceWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSource.metadata" relation */
  metadata: MetadataSourceNestedMetadataCreateInput;
  /** Actions for the "MetadataSource.photo" relation */
  photo: MetadataSourceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSourceWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSource.metadata" relation */
  metadata?: InputMaybe<MetadataSourceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSource.photo" relation */
  photo?: InputMaybe<MetadataSourceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSourceWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSource.archive" relation */
  archive: MetadataSourceNestedArchiveCreateInput;
  /** Actions for the "MetadataSource.photo" relation */
  photo: MetadataSourceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSourceWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSource.archive" relation */
  archive?: InputMaybe<MetadataSourceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSource.photo" relation */
  photo?: InputMaybe<MetadataSourceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSourceWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSource.archive" relation */
  archive: MetadataSourceNestedArchiveCreateInput;
  /** Actions for the "MetadataSource.metadata" relation */
  metadata: MetadataSourceNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSourceWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSource.archive" relation */
  archive?: InputMaybe<MetadataSourceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSource.metadata" relation */
  metadata?: InputMaybe<MetadataSourceNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataState" resource's node */
export type MetadataState = {
  __typename?: 'MetadataState';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataStateCreateInput = {
  /** Actions for the "MetadataState.archive" relation */
  archive: MetadataStateNestedArchiveCreateInput;
  /** Actions for the "MetadataState.metadata" relation */
  metadata: MetadataStateNestedMetadataCreateInput;
  /** Actions for the "MetadataState.photo" relation */
  photo: MetadataStateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStateNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataState" node, through the "MetadataState.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataState" node, through the "MetadataState.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataState" node, through the "MetadataState.archive" relation. */
  update?: InputMaybe<MetadataStateNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataState" node, through the "MetadataState.archive" relation. */
  upsert?: InputMaybe<MetadataStateNestedUpsertArchiveCreateInput>;
};

export type MetadataStateNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataState" node, through the "MetadataState.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataState" node, through the "MetadataState.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataState" node, through the "MetadataState.archive" relation. */
  update?: InputMaybe<MetadataStateNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataState" node, through the "MetadataState.archive" relation. */
  upsert?: InputMaybe<MetadataStateNestedUpsertArchiveUpdateInput>;
};

export type MetadataStateNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataState" node, through the "MetadataState.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataState" node, through the "MetadataState.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataState" node, through the "MetadataState.metadata" relation. */
  update?: InputMaybe<MetadataStateNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataState" node, through the "MetadataState.metadata" relation. */
  upsert?: InputMaybe<MetadataStateNestedUpsertMetadataCreateInput>;
};

export type MetadataStateNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataState" node, through the "MetadataState.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataState" node, through the "MetadataState.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataState" node, through the "MetadataState.metadata" relation. */
  update?: InputMaybe<MetadataStateNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataState" node, through the "MetadataState.metadata" relation. */
  upsert?: InputMaybe<MetadataStateNestedUpsertMetadataUpdateInput>;
};

export type MetadataStateNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataState" node, through the "MetadataState.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataState" node, through the "MetadataState.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataState" node, through the "MetadataState.photo" relation. */
  update?: InputMaybe<MetadataStateNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataState" node, through the "MetadataState.photo" relation. */
  upsert?: InputMaybe<MetadataStateNestedUpsertPhotoCreateInput>;
};

export type MetadataStateNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataState" node, through the "MetadataState.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataState" node, through the "MetadataState.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataState" node, through the "MetadataState.photo" relation. */
  update?: InputMaybe<MetadataStateNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataState" node, through the "MetadataState.photo" relation. */
  upsert?: InputMaybe<MetadataStateNestedUpsertPhotoUpdateInput>;
};

export type MetadataStateNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStateNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStateNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStateNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStateNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStateNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStateNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStateNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStateNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStateNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStateNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStateNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataStateOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataStateUpdateInput = {
  /** Actions for the "MetadataState.archive" relation */
  archive?: InputMaybe<MetadataStateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataState.metadata" relation */
  metadata?: InputMaybe<MetadataStateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataState.photo" relation */
  photo?: InputMaybe<MetadataStateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataState" nodes by specifying some conditions */
export type MetadataStateWhereInput = {
  AND?: InputMaybe<Array<MetadataStateWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataStateWhereInput>;
  OR?: InputMaybe<Array<MetadataStateWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataState" node. */
export type MetadataStateWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataStateWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataState.metadata" relation */
  metadata: MetadataStateNestedMetadataCreateInput;
  /** Actions for the "MetadataState.photo" relation */
  photo: MetadataStateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStateWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataState.metadata" relation */
  metadata?: InputMaybe<MetadataStateNestedMetadataUpdateInput>;
  /** Actions for the "MetadataState.photo" relation */
  photo?: InputMaybe<MetadataStateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStateWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataState.archive" relation */
  archive: MetadataStateNestedArchiveCreateInput;
  /** Actions for the "MetadataState.photo" relation */
  photo: MetadataStateNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStateWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataState.archive" relation */
  archive?: InputMaybe<MetadataStateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataState.photo" relation */
  photo?: InputMaybe<MetadataStateNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStateWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataState.archive" relation */
  archive: MetadataStateNestedArchiveCreateInput;
  /** Actions for the "MetadataState.metadata" relation */
  metadata: MetadataStateNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStateWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataState.archive" relation */
  archive?: InputMaybe<MetadataStateNestedArchiveUpdateInput>;
  /** Actions for the "MetadataState.metadata" relation */
  metadata?: InputMaybe<MetadataStateNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataStudyDateTime" resource's node */
export type MetadataStudyDateTime = {
  __typename?: 'MetadataStudyDateTime';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataStudyDateTimeCreateInput = {
  /** Actions for the "MetadataStudyDateTime.archive" relation */
  archive: MetadataStudyDateTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyDateTime.metadata" relation */
  metadata: MetadataStudyDateTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataStudyDateTime.photo" relation */
  photo: MetadataStudyDateTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDateTimeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.archive" relation. */
  update?: InputMaybe<MetadataStudyDateTimeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.archive" relation. */
  upsert?: InputMaybe<MetadataStudyDateTimeNestedUpsertArchiveCreateInput>;
};

export type MetadataStudyDateTimeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.archive" relation. */
  update?: InputMaybe<MetadataStudyDateTimeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.archive" relation. */
  upsert?: InputMaybe<MetadataStudyDateTimeNestedUpsertArchiveUpdateInput>;
};

export type MetadataStudyDateTimeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.metadata" relation. */
  update?: InputMaybe<MetadataStudyDateTimeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.metadata" relation. */
  upsert?: InputMaybe<MetadataStudyDateTimeNestedUpsertMetadataCreateInput>;
};

export type MetadataStudyDateTimeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.metadata" relation. */
  update?: InputMaybe<MetadataStudyDateTimeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.metadata" relation. */
  upsert?: InputMaybe<MetadataStudyDateTimeNestedUpsertMetadataUpdateInput>;
};

export type MetadataStudyDateTimeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.photo" relation. */
  update?: InputMaybe<MetadataStudyDateTimeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.photo" relation. */
  upsert?: InputMaybe<MetadataStudyDateTimeNestedUpsertPhotoCreateInput>;
};

export type MetadataStudyDateTimeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.photo" relation. */
  update?: InputMaybe<MetadataStudyDateTimeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataStudyDateTime" node, through the "MetadataStudyDateTime.photo" relation. */
  upsert?: InputMaybe<MetadataStudyDateTimeNestedUpsertPhotoUpdateInput>;
};

export type MetadataStudyDateTimeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyDateTimeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyDateTimeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyDateTimeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyDateTimeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyDateTimeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyDateTimeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyDateTimeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyDateTimeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyDateTimeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyDateTimeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyDateTimeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataStudyDateTimeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataStudyDateTimeUpdateInput = {
  /** Actions for the "MetadataStudyDateTime.archive" relation */
  archive?: InputMaybe<MetadataStudyDateTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyDateTime.metadata" relation */
  metadata?: InputMaybe<MetadataStudyDateTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataStudyDateTime.photo" relation */
  photo?: InputMaybe<MetadataStudyDateTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataStudyDateTime" nodes by specifying some conditions */
export type MetadataStudyDateTimeWhereInput = {
  AND?: InputMaybe<Array<MetadataStudyDateTimeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataStudyDateTimeWhereInput>;
  OR?: InputMaybe<Array<MetadataStudyDateTimeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataStudyDateTime" node. */
export type MetadataStudyDateTimeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataStudyDateTimeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataStudyDateTime.metadata" relation */
  metadata: MetadataStudyDateTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataStudyDateTime.photo" relation */
  photo: MetadataStudyDateTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDateTimeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataStudyDateTime.metadata" relation */
  metadata?: InputMaybe<MetadataStudyDateTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataStudyDateTime.photo" relation */
  photo?: InputMaybe<MetadataStudyDateTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDateTimeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataStudyDateTime.archive" relation */
  archive: MetadataStudyDateTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyDateTime.photo" relation */
  photo: MetadataStudyDateTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDateTimeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataStudyDateTime.archive" relation */
  archive?: InputMaybe<MetadataStudyDateTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyDateTime.photo" relation */
  photo?: InputMaybe<MetadataStudyDateTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDateTimeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataStudyDateTime.archive" relation */
  archive: MetadataStudyDateTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyDateTime.metadata" relation */
  metadata: MetadataStudyDateTimeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDateTimeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataStudyDateTime.archive" relation */
  archive?: InputMaybe<MetadataStudyDateTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyDateTime.metadata" relation */
  metadata?: InputMaybe<MetadataStudyDateTimeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataStudyDescription" resource's node */
export type MetadataStudyDescription = {
  __typename?: 'MetadataStudyDescription';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataStudyDescriptionCreateInput = {
  /** Actions for the "MetadataStudyDescription.archive" relation */
  archive: MetadataStudyDescriptionNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyDescription.metadata" relation */
  metadata: MetadataStudyDescriptionNestedMetadataCreateInput;
  /** Actions for the "MetadataStudyDescription.photo" relation */
  photo: MetadataStudyDescriptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDescriptionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.archive" relation. */
  update?: InputMaybe<MetadataStudyDescriptionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.archive" relation. */
  upsert?: InputMaybe<MetadataStudyDescriptionNestedUpsertArchiveCreateInput>;
};

export type MetadataStudyDescriptionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.archive" relation. */
  update?: InputMaybe<MetadataStudyDescriptionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.archive" relation. */
  upsert?: InputMaybe<MetadataStudyDescriptionNestedUpsertArchiveUpdateInput>;
};

export type MetadataStudyDescriptionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.metadata" relation. */
  update?: InputMaybe<MetadataStudyDescriptionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.metadata" relation. */
  upsert?: InputMaybe<MetadataStudyDescriptionNestedUpsertMetadataCreateInput>;
};

export type MetadataStudyDescriptionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.metadata" relation. */
  update?: InputMaybe<MetadataStudyDescriptionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.metadata" relation. */
  upsert?: InputMaybe<MetadataStudyDescriptionNestedUpsertMetadataUpdateInput>;
};

export type MetadataStudyDescriptionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.photo" relation. */
  update?: InputMaybe<MetadataStudyDescriptionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataStudyDescription" node, through the "MetadataStudyDescription.photo" relation. */
  upsert?: InputMaybe<MetadataStudyDescriptionNestedUpsertPhotoCreateInput>;
};

export type MetadataStudyDescriptionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.photo" relation. */
  update?: InputMaybe<MetadataStudyDescriptionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataStudyDescription" node, through the "MetadataStudyDescription.photo" relation. */
  upsert?: InputMaybe<MetadataStudyDescriptionNestedUpsertPhotoUpdateInput>;
};

export type MetadataStudyDescriptionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyDescriptionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyDescriptionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyDescriptionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyDescriptionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyDescriptionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyDescriptionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyDescriptionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyDescriptionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyDescriptionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyDescriptionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyDescriptionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataStudyDescriptionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataStudyDescriptionUpdateInput = {
  /** Actions for the "MetadataStudyDescription.archive" relation */
  archive?: InputMaybe<MetadataStudyDescriptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyDescription.metadata" relation */
  metadata?: InputMaybe<MetadataStudyDescriptionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataStudyDescription.photo" relation */
  photo?: InputMaybe<MetadataStudyDescriptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataStudyDescription" nodes by specifying some conditions */
export type MetadataStudyDescriptionWhereInput = {
  AND?: InputMaybe<Array<MetadataStudyDescriptionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataStudyDescriptionWhereInput>;
  OR?: InputMaybe<Array<MetadataStudyDescriptionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataStudyDescription" node. */
export type MetadataStudyDescriptionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataStudyDescriptionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataStudyDescription.metadata" relation */
  metadata: MetadataStudyDescriptionNestedMetadataCreateInput;
  /** Actions for the "MetadataStudyDescription.photo" relation */
  photo: MetadataStudyDescriptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDescriptionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataStudyDescription.metadata" relation */
  metadata?: InputMaybe<MetadataStudyDescriptionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataStudyDescription.photo" relation */
  photo?: InputMaybe<MetadataStudyDescriptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDescriptionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataStudyDescription.archive" relation */
  archive: MetadataStudyDescriptionNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyDescription.photo" relation */
  photo: MetadataStudyDescriptionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDescriptionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataStudyDescription.archive" relation */
  archive?: InputMaybe<MetadataStudyDescriptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyDescription.photo" relation */
  photo?: InputMaybe<MetadataStudyDescriptionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDescriptionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataStudyDescription.archive" relation */
  archive: MetadataStudyDescriptionNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyDescription.metadata" relation */
  metadata: MetadataStudyDescriptionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyDescriptionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataStudyDescription.archive" relation */
  archive?: InputMaybe<MetadataStudyDescriptionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyDescription.metadata" relation */
  metadata?: InputMaybe<MetadataStudyDescriptionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataStudyId" resource's node */
export type MetadataStudyId = {
  __typename?: 'MetadataStudyId';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataStudyIdCreateInput = {
  /** Actions for the "MetadataStudyId.archive" relation */
  archive: MetadataStudyIdNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyId.metadata" relation */
  metadata: MetadataStudyIdNestedMetadataCreateInput;
  /** Actions for the "MetadataStudyId.photo" relation */
  photo: MetadataStudyIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyIdNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataStudyId" node, through the "MetadataStudyId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataStudyId" node, through the "MetadataStudyId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataStudyId" node, through the "MetadataStudyId.archive" relation. */
  update?: InputMaybe<MetadataStudyIdNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataStudyId" node, through the "MetadataStudyId.archive" relation. */
  upsert?: InputMaybe<MetadataStudyIdNestedUpsertArchiveCreateInput>;
};

export type MetadataStudyIdNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataStudyId" node, through the "MetadataStudyId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataStudyId" node, through the "MetadataStudyId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataStudyId" node, through the "MetadataStudyId.archive" relation. */
  update?: InputMaybe<MetadataStudyIdNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataStudyId" node, through the "MetadataStudyId.archive" relation. */
  upsert?: InputMaybe<MetadataStudyIdNestedUpsertArchiveUpdateInput>;
};

export type MetadataStudyIdNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataStudyId" node, through the "MetadataStudyId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataStudyId" node, through the "MetadataStudyId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataStudyId" node, through the "MetadataStudyId.metadata" relation. */
  update?: InputMaybe<MetadataStudyIdNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataStudyId" node, through the "MetadataStudyId.metadata" relation. */
  upsert?: InputMaybe<MetadataStudyIdNestedUpsertMetadataCreateInput>;
};

export type MetadataStudyIdNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataStudyId" node, through the "MetadataStudyId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataStudyId" node, through the "MetadataStudyId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataStudyId" node, through the "MetadataStudyId.metadata" relation. */
  update?: InputMaybe<MetadataStudyIdNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataStudyId" node, through the "MetadataStudyId.metadata" relation. */
  upsert?: InputMaybe<MetadataStudyIdNestedUpsertMetadataUpdateInput>;
};

export type MetadataStudyIdNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataStudyId" node, through the "MetadataStudyId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataStudyId" node, through the "MetadataStudyId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataStudyId" node, through the "MetadataStudyId.photo" relation. */
  update?: InputMaybe<MetadataStudyIdNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataStudyId" node, through the "MetadataStudyId.photo" relation. */
  upsert?: InputMaybe<MetadataStudyIdNestedUpsertPhotoCreateInput>;
};

export type MetadataStudyIdNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataStudyId" node, through the "MetadataStudyId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataStudyId" node, through the "MetadataStudyId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataStudyId" node, through the "MetadataStudyId.photo" relation. */
  update?: InputMaybe<MetadataStudyIdNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataStudyId" node, through the "MetadataStudyId.photo" relation. */
  upsert?: InputMaybe<MetadataStudyIdNestedUpsertPhotoUpdateInput>;
};

export type MetadataStudyIdNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyIdNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyIdNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyIdNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyIdNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyIdNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyIdNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyIdNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyIdNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyIdNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyIdNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyIdNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataStudyIdOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataStudyIdUpdateInput = {
  /** Actions for the "MetadataStudyId.archive" relation */
  archive?: InputMaybe<MetadataStudyIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyId.metadata" relation */
  metadata?: InputMaybe<MetadataStudyIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataStudyId.photo" relation */
  photo?: InputMaybe<MetadataStudyIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataStudyId" nodes by specifying some conditions */
export type MetadataStudyIdWhereInput = {
  AND?: InputMaybe<Array<MetadataStudyIdWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataStudyIdWhereInput>;
  OR?: InputMaybe<Array<MetadataStudyIdWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataStudyId" node. */
export type MetadataStudyIdWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataStudyIdWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataStudyId.metadata" relation */
  metadata: MetadataStudyIdNestedMetadataCreateInput;
  /** Actions for the "MetadataStudyId.photo" relation */
  photo: MetadataStudyIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyIdWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataStudyId.metadata" relation */
  metadata?: InputMaybe<MetadataStudyIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataStudyId.photo" relation */
  photo?: InputMaybe<MetadataStudyIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyIdWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataStudyId.archive" relation */
  archive: MetadataStudyIdNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyId.photo" relation */
  photo: MetadataStudyIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyIdWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataStudyId.archive" relation */
  archive?: InputMaybe<MetadataStudyIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyId.photo" relation */
  photo?: InputMaybe<MetadataStudyIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyIdWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataStudyId.archive" relation */
  archive: MetadataStudyIdNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyId.metadata" relation */
  metadata: MetadataStudyIdNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyIdWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataStudyId.archive" relation */
  archive?: InputMaybe<MetadataStudyIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyId.metadata" relation */
  metadata?: InputMaybe<MetadataStudyIdNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataStudyPhysician" resource's node */
export type MetadataStudyPhysician = {
  __typename?: 'MetadataStudyPhysician';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataStudyPhysicianCreateInput = {
  /** Actions for the "MetadataStudyPhysician.archive" relation */
  archive: MetadataStudyPhysicianNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyPhysician.metadata" relation */
  metadata: MetadataStudyPhysicianNestedMetadataCreateInput;
  /** Actions for the "MetadataStudyPhysician.photo" relation */
  photo: MetadataStudyPhysicianNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyPhysicianNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.archive" relation. */
  update?: InputMaybe<MetadataStudyPhysicianNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.archive" relation. */
  upsert?: InputMaybe<MetadataStudyPhysicianNestedUpsertArchiveCreateInput>;
};

export type MetadataStudyPhysicianNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.archive" relation. */
  update?: InputMaybe<MetadataStudyPhysicianNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.archive" relation. */
  upsert?: InputMaybe<MetadataStudyPhysicianNestedUpsertArchiveUpdateInput>;
};

export type MetadataStudyPhysicianNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.metadata" relation. */
  update?: InputMaybe<MetadataStudyPhysicianNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.metadata" relation. */
  upsert?: InputMaybe<MetadataStudyPhysicianNestedUpsertMetadataCreateInput>;
};

export type MetadataStudyPhysicianNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.metadata" relation. */
  update?: InputMaybe<MetadataStudyPhysicianNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.metadata" relation. */
  upsert?: InputMaybe<MetadataStudyPhysicianNestedUpsertMetadataUpdateInput>;
};

export type MetadataStudyPhysicianNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.photo" relation. */
  update?: InputMaybe<MetadataStudyPhysicianNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.photo" relation. */
  upsert?: InputMaybe<MetadataStudyPhysicianNestedUpsertPhotoCreateInput>;
};

export type MetadataStudyPhysicianNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.photo" relation. */
  update?: InputMaybe<MetadataStudyPhysicianNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataStudyPhysician" node, through the "MetadataStudyPhysician.photo" relation. */
  upsert?: InputMaybe<MetadataStudyPhysicianNestedUpsertPhotoUpdateInput>;
};

export type MetadataStudyPhysicianNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyPhysicianNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyPhysicianNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyPhysicianNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyPhysicianNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyPhysicianNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyPhysicianNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyPhysicianNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataStudyPhysicianNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyPhysicianNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataStudyPhysicianNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataStudyPhysicianNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataStudyPhysicianOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataStudyPhysicianUpdateInput = {
  /** Actions for the "MetadataStudyPhysician.archive" relation */
  archive?: InputMaybe<MetadataStudyPhysicianNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyPhysician.metadata" relation */
  metadata?: InputMaybe<MetadataStudyPhysicianNestedMetadataUpdateInput>;
  /** Actions for the "MetadataStudyPhysician.photo" relation */
  photo?: InputMaybe<MetadataStudyPhysicianNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataStudyPhysician" nodes by specifying some conditions */
export type MetadataStudyPhysicianWhereInput = {
  AND?: InputMaybe<Array<MetadataStudyPhysicianWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataStudyPhysicianWhereInput>;
  OR?: InputMaybe<Array<MetadataStudyPhysicianWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataStudyPhysician" node. */
export type MetadataStudyPhysicianWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataStudyPhysicianWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataStudyPhysician.metadata" relation */
  metadata: MetadataStudyPhysicianNestedMetadataCreateInput;
  /** Actions for the "MetadataStudyPhysician.photo" relation */
  photo: MetadataStudyPhysicianNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyPhysicianWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataStudyPhysician.metadata" relation */
  metadata?: InputMaybe<MetadataStudyPhysicianNestedMetadataUpdateInput>;
  /** Actions for the "MetadataStudyPhysician.photo" relation */
  photo?: InputMaybe<MetadataStudyPhysicianNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyPhysicianWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataStudyPhysician.archive" relation */
  archive: MetadataStudyPhysicianNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyPhysician.photo" relation */
  photo: MetadataStudyPhysicianNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyPhysicianWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataStudyPhysician.archive" relation */
  archive?: InputMaybe<MetadataStudyPhysicianNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyPhysician.photo" relation */
  photo?: InputMaybe<MetadataStudyPhysicianNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyPhysicianWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataStudyPhysician.archive" relation */
  archive: MetadataStudyPhysicianNestedArchiveCreateInput;
  /** Actions for the "MetadataStudyPhysician.metadata" relation */
  metadata: MetadataStudyPhysicianNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataStudyPhysicianWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataStudyPhysician.archive" relation */
  archive?: InputMaybe<MetadataStudyPhysicianNestedArchiveUpdateInput>;
  /** Actions for the "MetadataStudyPhysician.metadata" relation */
  metadata?: InputMaybe<MetadataStudyPhysicianNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataSubject" resource's node */
export type MetadataSubject = {
  __typename?: 'MetadataSubject';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** "MetadataSubjectCode" resource's node */
export type MetadataSubjectCode = {
  __typename?: 'MetadataSubjectCode';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSubjectCodeCreateInput = {
  /** Actions for the "MetadataSubjectCode.archive" relation */
  archive: MetadataSubjectCodeNestedArchiveCreateInput;
  /** Actions for the "MetadataSubjectCode.metadata" relation */
  metadata: MetadataSubjectCodeNestedMetadataCreateInput;
  /** Actions for the "MetadataSubjectCode.photo" relation */
  photo: MetadataSubjectCodeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectCodeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.archive" relation. */
  update?: InputMaybe<MetadataSubjectCodeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.archive" relation. */
  upsert?: InputMaybe<MetadataSubjectCodeNestedUpsertArchiveCreateInput>;
};

export type MetadataSubjectCodeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.archive" relation. */
  update?: InputMaybe<MetadataSubjectCodeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.archive" relation. */
  upsert?: InputMaybe<MetadataSubjectCodeNestedUpsertArchiveUpdateInput>;
};

export type MetadataSubjectCodeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.metadata" relation. */
  update?: InputMaybe<MetadataSubjectCodeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.metadata" relation. */
  upsert?: InputMaybe<MetadataSubjectCodeNestedUpsertMetadataCreateInput>;
};

export type MetadataSubjectCodeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.metadata" relation. */
  update?: InputMaybe<MetadataSubjectCodeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.metadata" relation. */
  upsert?: InputMaybe<MetadataSubjectCodeNestedUpsertMetadataUpdateInput>;
};

export type MetadataSubjectCodeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.photo" relation. */
  update?: InputMaybe<MetadataSubjectCodeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSubjectCode" node, through the "MetadataSubjectCode.photo" relation. */
  upsert?: InputMaybe<MetadataSubjectCodeNestedUpsertPhotoCreateInput>;
};

export type MetadataSubjectCodeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.photo" relation. */
  update?: InputMaybe<MetadataSubjectCodeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSubjectCode" node, through the "MetadataSubjectCode.photo" relation. */
  upsert?: InputMaybe<MetadataSubjectCodeNestedUpsertPhotoUpdateInput>;
};

export type MetadataSubjectCodeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectCodeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectCodeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectCodeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectCodeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSubjectCodeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSubjectCodeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectCodeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectCodeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectCodeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectCodeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSubjectCodeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSubjectCodeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSubjectCodeUpdateInput = {
  /** Actions for the "MetadataSubjectCode.archive" relation */
  archive?: InputMaybe<MetadataSubjectCodeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSubjectCode.metadata" relation */
  metadata?: InputMaybe<MetadataSubjectCodeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSubjectCode.photo" relation */
  photo?: InputMaybe<MetadataSubjectCodeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSubjectCode" nodes by specifying some conditions */
export type MetadataSubjectCodeWhereInput = {
  AND?: InputMaybe<Array<MetadataSubjectCodeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSubjectCodeWhereInput>;
  OR?: InputMaybe<Array<MetadataSubjectCodeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSubjectCode" node. */
export type MetadataSubjectCodeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSubjectCodeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSubjectCode.metadata" relation */
  metadata: MetadataSubjectCodeNestedMetadataCreateInput;
  /** Actions for the "MetadataSubjectCode.photo" relation */
  photo: MetadataSubjectCodeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectCodeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSubjectCode.metadata" relation */
  metadata?: InputMaybe<MetadataSubjectCodeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSubjectCode.photo" relation */
  photo?: InputMaybe<MetadataSubjectCodeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectCodeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSubjectCode.archive" relation */
  archive: MetadataSubjectCodeNestedArchiveCreateInput;
  /** Actions for the "MetadataSubjectCode.photo" relation */
  photo: MetadataSubjectCodeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectCodeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSubjectCode.archive" relation */
  archive?: InputMaybe<MetadataSubjectCodeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSubjectCode.photo" relation */
  photo?: InputMaybe<MetadataSubjectCodeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectCodeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSubjectCode.archive" relation */
  archive: MetadataSubjectCodeNestedArchiveCreateInput;
  /** Actions for the "MetadataSubjectCode.metadata" relation */
  metadata: MetadataSubjectCodeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectCodeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSubjectCode.archive" relation */
  archive?: InputMaybe<MetadataSubjectCodeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSubjectCode.metadata" relation */
  metadata?: InputMaybe<MetadataSubjectCodeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectCreateInput = {
  /** Actions for the "MetadataSubject.archive" relation */
  archive: MetadataSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataSubject.metadata" relation */
  metadata: MetadataSubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataSubject.photo" relation */
  photo: MetadataSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSubject" node, through the "MetadataSubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSubject" node, through the "MetadataSubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSubject" node, through the "MetadataSubject.archive" relation. */
  update?: InputMaybe<MetadataSubjectNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSubject" node, through the "MetadataSubject.archive" relation. */
  upsert?: InputMaybe<MetadataSubjectNestedUpsertArchiveCreateInput>;
};

export type MetadataSubjectNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSubject" node, through the "MetadataSubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSubject" node, through the "MetadataSubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSubject" node, through the "MetadataSubject.archive" relation. */
  update?: InputMaybe<MetadataSubjectNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSubject" node, through the "MetadataSubject.archive" relation. */
  upsert?: InputMaybe<MetadataSubjectNestedUpsertArchiveUpdateInput>;
};

export type MetadataSubjectNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSubject" node, through the "MetadataSubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSubject" node, through the "MetadataSubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSubject" node, through the "MetadataSubject.metadata" relation. */
  update?: InputMaybe<MetadataSubjectNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSubject" node, through the "MetadataSubject.metadata" relation. */
  upsert?: InputMaybe<MetadataSubjectNestedUpsertMetadataCreateInput>;
};

export type MetadataSubjectNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSubject" node, through the "MetadataSubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSubject" node, through the "MetadataSubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSubject" node, through the "MetadataSubject.metadata" relation. */
  update?: InputMaybe<MetadataSubjectNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSubject" node, through the "MetadataSubject.metadata" relation. */
  upsert?: InputMaybe<MetadataSubjectNestedUpsertMetadataUpdateInput>;
};

export type MetadataSubjectNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSubject" node, through the "MetadataSubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSubject" node, through the "MetadataSubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSubject" node, through the "MetadataSubject.photo" relation. */
  update?: InputMaybe<MetadataSubjectNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSubject" node, through the "MetadataSubject.photo" relation. */
  upsert?: InputMaybe<MetadataSubjectNestedUpsertPhotoCreateInput>;
};

export type MetadataSubjectNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSubject" node, through the "MetadataSubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSubject" node, through the "MetadataSubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSubject" node, through the "MetadataSubject.photo" relation. */
  update?: InputMaybe<MetadataSubjectNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSubject" node, through the "MetadataSubject.photo" relation. */
  upsert?: InputMaybe<MetadataSubjectNestedUpsertPhotoUpdateInput>;
};

export type MetadataSubjectNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSubjectNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSubjectNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSubjectNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSubjectOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

/** "MetadataSubjectPrisma" resource's node */
export type MetadataSubjectPrisma = {
  __typename?: 'MetadataSubjectPrisma';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSubjectPrismaCreateInput = {
  /** Actions for the "MetadataSubjectPrisma.archive" relation */
  archive: MetadataSubjectPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataSubjectPrisma.metadata" relation */
  metadata: MetadataSubjectPrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataSubjectPrisma.photo" relation */
  photo: MetadataSubjectPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectPrismaNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.archive" relation. */
  update?: InputMaybe<MetadataSubjectPrismaNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.archive" relation. */
  upsert?: InputMaybe<MetadataSubjectPrismaNestedUpsertArchiveCreateInput>;
};

export type MetadataSubjectPrismaNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.archive" relation. */
  update?: InputMaybe<MetadataSubjectPrismaNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.archive" relation. */
  upsert?: InputMaybe<MetadataSubjectPrismaNestedUpsertArchiveUpdateInput>;
};

export type MetadataSubjectPrismaNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.metadata" relation. */
  update?: InputMaybe<MetadataSubjectPrismaNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataSubjectPrismaNestedUpsertMetadataCreateInput>;
};

export type MetadataSubjectPrismaNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.metadata" relation. */
  update?: InputMaybe<MetadataSubjectPrismaNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.metadata" relation. */
  upsert?: InputMaybe<MetadataSubjectPrismaNestedUpsertMetadataUpdateInput>;
};

export type MetadataSubjectPrismaNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.photo" relation. */
  update?: InputMaybe<MetadataSubjectPrismaNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.photo" relation. */
  upsert?: InputMaybe<MetadataSubjectPrismaNestedUpsertPhotoCreateInput>;
};

export type MetadataSubjectPrismaNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.photo" relation. */
  update?: InputMaybe<MetadataSubjectPrismaNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSubjectPrisma" node, through the "MetadataSubjectPrisma.photo" relation. */
  upsert?: InputMaybe<MetadataSubjectPrismaNestedUpsertPhotoUpdateInput>;
};

export type MetadataSubjectPrismaNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectPrismaNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectPrismaNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectPrismaNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectPrismaNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSubjectPrismaNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSubjectPrismaNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectPrismaNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSubjectPrismaNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectPrismaNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSubjectPrismaNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSubjectPrismaNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSubjectPrismaOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSubjectPrismaUpdateInput = {
  /** Actions for the "MetadataSubjectPrisma.archive" relation */
  archive?: InputMaybe<MetadataSubjectPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSubjectPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataSubjectPrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSubjectPrisma.photo" relation */
  photo?: InputMaybe<MetadataSubjectPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSubjectPrisma" nodes by specifying some conditions */
export type MetadataSubjectPrismaWhereInput = {
  AND?: InputMaybe<Array<MetadataSubjectPrismaWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSubjectPrismaWhereInput>;
  OR?: InputMaybe<Array<MetadataSubjectPrismaWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSubjectPrisma" node. */
export type MetadataSubjectPrismaWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSubjectPrismaWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSubjectPrisma.metadata" relation */
  metadata: MetadataSubjectPrismaNestedMetadataCreateInput;
  /** Actions for the "MetadataSubjectPrisma.photo" relation */
  photo: MetadataSubjectPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectPrismaWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSubjectPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataSubjectPrismaNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSubjectPrisma.photo" relation */
  photo?: InputMaybe<MetadataSubjectPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectPrismaWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSubjectPrisma.archive" relation */
  archive: MetadataSubjectPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataSubjectPrisma.photo" relation */
  photo: MetadataSubjectPrismaNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectPrismaWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSubjectPrisma.archive" relation */
  archive?: InputMaybe<MetadataSubjectPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSubjectPrisma.photo" relation */
  photo?: InputMaybe<MetadataSubjectPrismaNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectPrismaWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSubjectPrisma.archive" relation */
  archive: MetadataSubjectPrismaNestedArchiveCreateInput;
  /** Actions for the "MetadataSubjectPrisma.metadata" relation */
  metadata: MetadataSubjectPrismaNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectPrismaWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSubjectPrisma.archive" relation */
  archive?: InputMaybe<MetadataSubjectPrismaNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSubjectPrisma.metadata" relation */
  metadata?: InputMaybe<MetadataSubjectPrismaNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectUpdateInput = {
  /** Actions for the "MetadataSubject.archive" relation */
  archive?: InputMaybe<MetadataSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSubject.metadata" relation */
  metadata?: InputMaybe<MetadataSubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSubject.photo" relation */
  photo?: InputMaybe<MetadataSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSubject" nodes by specifying some conditions */
export type MetadataSubjectWhereInput = {
  AND?: InputMaybe<Array<MetadataSubjectWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSubjectWhereInput>;
  OR?: InputMaybe<Array<MetadataSubjectWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSubject" node. */
export type MetadataSubjectWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSubjectWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSubject.metadata" relation */
  metadata: MetadataSubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataSubject.photo" relation */
  photo: MetadataSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSubject.metadata" relation */
  metadata?: InputMaybe<MetadataSubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSubject.photo" relation */
  photo?: InputMaybe<MetadataSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSubject.archive" relation */
  archive: MetadataSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataSubject.photo" relation */
  photo: MetadataSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSubject.archive" relation */
  archive?: InputMaybe<MetadataSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSubject.photo" relation */
  photo?: InputMaybe<MetadataSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSubject.archive" relation */
  archive: MetadataSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataSubject.metadata" relation */
  metadata: MetadataSubjectNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSubjectWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSubject.archive" relation */
  archive?: InputMaybe<MetadataSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSubject.metadata" relation */
  metadata?: InputMaybe<MetadataSubjectNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataSupplementalCategories" resource's node */
export type MetadataSupplementalCategories = {
  __typename?: 'MetadataSupplementalCategories';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataSupplementalCategoriesCreateInput = {
  /** Actions for the "MetadataSupplementalCategories.archive" relation */
  archive: MetadataSupplementalCategoriesNestedArchiveCreateInput;
  /** Actions for the "MetadataSupplementalCategories.metadata" relation */
  metadata: MetadataSupplementalCategoriesNestedMetadataCreateInput;
  /** Actions for the "MetadataSupplementalCategories.photo" relation */
  photo: MetadataSupplementalCategoriesNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSupplementalCategoriesNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.archive" relation. */
  update?: InputMaybe<MetadataSupplementalCategoriesNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.archive" relation. */
  upsert?: InputMaybe<MetadataSupplementalCategoriesNestedUpsertArchiveCreateInput>;
};

export type MetadataSupplementalCategoriesNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.archive" relation. */
  update?: InputMaybe<MetadataSupplementalCategoriesNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.archive" relation. */
  upsert?: InputMaybe<MetadataSupplementalCategoriesNestedUpsertArchiveUpdateInput>;
};

export type MetadataSupplementalCategoriesNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.metadata" relation. */
  update?: InputMaybe<MetadataSupplementalCategoriesNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.metadata" relation. */
  upsert?: InputMaybe<MetadataSupplementalCategoriesNestedUpsertMetadataCreateInput>;
};

export type MetadataSupplementalCategoriesNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.metadata" relation. */
  update?: InputMaybe<MetadataSupplementalCategoriesNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.metadata" relation. */
  upsert?: InputMaybe<MetadataSupplementalCategoriesNestedUpsertMetadataUpdateInput>;
};

export type MetadataSupplementalCategoriesNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.photo" relation. */
  update?: InputMaybe<MetadataSupplementalCategoriesNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.photo" relation. */
  upsert?: InputMaybe<MetadataSupplementalCategoriesNestedUpsertPhotoCreateInput>;
};

export type MetadataSupplementalCategoriesNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.photo" relation. */
  update?: InputMaybe<MetadataSupplementalCategoriesNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataSupplementalCategories" node, through the "MetadataSupplementalCategories.photo" relation. */
  upsert?: InputMaybe<MetadataSupplementalCategoriesNestedUpsertPhotoUpdateInput>;
};

export type MetadataSupplementalCategoriesNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSupplementalCategoriesNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSupplementalCategoriesNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSupplementalCategoriesNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSupplementalCategoriesNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSupplementalCategoriesNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSupplementalCategoriesNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSupplementalCategoriesNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataSupplementalCategoriesNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSupplementalCategoriesNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataSupplementalCategoriesNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataSupplementalCategoriesNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataSupplementalCategoriesOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataSupplementalCategoriesUpdateInput = {
  /** Actions for the "MetadataSupplementalCategories.archive" relation */
  archive?: InputMaybe<MetadataSupplementalCategoriesNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSupplementalCategories.metadata" relation */
  metadata?: InputMaybe<MetadataSupplementalCategoriesNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSupplementalCategories.photo" relation */
  photo?: InputMaybe<MetadataSupplementalCategoriesNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataSupplementalCategories" nodes by specifying some conditions */
export type MetadataSupplementalCategoriesWhereInput = {
  AND?: InputMaybe<Array<MetadataSupplementalCategoriesWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
  OR?: InputMaybe<Array<MetadataSupplementalCategoriesWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataSupplementalCategories" node. */
export type MetadataSupplementalCategoriesWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataSupplementalCategoriesWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataSupplementalCategories.metadata" relation */
  metadata: MetadataSupplementalCategoriesNestedMetadataCreateInput;
  /** Actions for the "MetadataSupplementalCategories.photo" relation */
  photo: MetadataSupplementalCategoriesNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSupplementalCategoriesWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataSupplementalCategories.metadata" relation */
  metadata?: InputMaybe<MetadataSupplementalCategoriesNestedMetadataUpdateInput>;
  /** Actions for the "MetadataSupplementalCategories.photo" relation */
  photo?: InputMaybe<MetadataSupplementalCategoriesNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSupplementalCategoriesWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataSupplementalCategories.archive" relation */
  archive: MetadataSupplementalCategoriesNestedArchiveCreateInput;
  /** Actions for the "MetadataSupplementalCategories.photo" relation */
  photo: MetadataSupplementalCategoriesNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSupplementalCategoriesWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataSupplementalCategories.archive" relation */
  archive?: InputMaybe<MetadataSupplementalCategoriesNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSupplementalCategories.photo" relation */
  photo?: InputMaybe<MetadataSupplementalCategoriesNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSupplementalCategoriesWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataSupplementalCategories.archive" relation */
  archive: MetadataSupplementalCategoriesNestedArchiveCreateInput;
  /** Actions for the "MetadataSupplementalCategories.metadata" relation */
  metadata: MetadataSupplementalCategoriesNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataSupplementalCategoriesWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataSupplementalCategories.archive" relation */
  archive?: InputMaybe<MetadataSupplementalCategoriesNestedArchiveUpdateInput>;
  /** Actions for the "MetadataSupplementalCategories.metadata" relation */
  metadata?: InputMaybe<MetadataSupplementalCategoriesNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataTargetVersion" resource's node */
export type MetadataTargetVersion = {
  __typename?: 'MetadataTargetVersion';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataTargetVersionCreateInput = {
  /** Actions for the "MetadataTargetVersion.archive" relation */
  archive: MetadataTargetVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataTargetVersion.metadata" relation */
  metadata: MetadataTargetVersionNestedMetadataCreateInput;
  /** Actions for the "MetadataTargetVersion.photo" relation */
  photo: MetadataTargetVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTargetVersionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.archive" relation. */
  update?: InputMaybe<MetadataTargetVersionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.archive" relation. */
  upsert?: InputMaybe<MetadataTargetVersionNestedUpsertArchiveCreateInput>;
};

export type MetadataTargetVersionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.archive" relation. */
  update?: InputMaybe<MetadataTargetVersionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.archive" relation. */
  upsert?: InputMaybe<MetadataTargetVersionNestedUpsertArchiveUpdateInput>;
};

export type MetadataTargetVersionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.metadata" relation. */
  update?: InputMaybe<MetadataTargetVersionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.metadata" relation. */
  upsert?: InputMaybe<MetadataTargetVersionNestedUpsertMetadataCreateInput>;
};

export type MetadataTargetVersionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.metadata" relation. */
  update?: InputMaybe<MetadataTargetVersionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.metadata" relation. */
  upsert?: InputMaybe<MetadataTargetVersionNestedUpsertMetadataUpdateInput>;
};

export type MetadataTargetVersionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.photo" relation. */
  update?: InputMaybe<MetadataTargetVersionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataTargetVersion" node, through the "MetadataTargetVersion.photo" relation. */
  upsert?: InputMaybe<MetadataTargetVersionNestedUpsertPhotoCreateInput>;
};

export type MetadataTargetVersionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.photo" relation. */
  update?: InputMaybe<MetadataTargetVersionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataTargetVersion" node, through the "MetadataTargetVersion.photo" relation. */
  upsert?: InputMaybe<MetadataTargetVersionNestedUpsertPhotoUpdateInput>;
};

export type MetadataTargetVersionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTargetVersionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTargetVersionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTargetVersionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTargetVersionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTargetVersionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTargetVersionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTargetVersionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTargetVersionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTargetVersionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTargetVersionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTargetVersionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataTargetVersionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataTargetVersionUpdateInput = {
  /** Actions for the "MetadataTargetVersion.archive" relation */
  archive?: InputMaybe<MetadataTargetVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTargetVersion.metadata" relation */
  metadata?: InputMaybe<MetadataTargetVersionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTargetVersion.photo" relation */
  photo?: InputMaybe<MetadataTargetVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataTargetVersion" nodes by specifying some conditions */
export type MetadataTargetVersionWhereInput = {
  AND?: InputMaybe<Array<MetadataTargetVersionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataTargetVersionWhereInput>;
  OR?: InputMaybe<Array<MetadataTargetVersionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataTargetVersion" node. */
export type MetadataTargetVersionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataTargetVersionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataTargetVersion.metadata" relation */
  metadata: MetadataTargetVersionNestedMetadataCreateInput;
  /** Actions for the "MetadataTargetVersion.photo" relation */
  photo: MetadataTargetVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTargetVersionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataTargetVersion.metadata" relation */
  metadata?: InputMaybe<MetadataTargetVersionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTargetVersion.photo" relation */
  photo?: InputMaybe<MetadataTargetVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTargetVersionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataTargetVersion.archive" relation */
  archive: MetadataTargetVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataTargetVersion.photo" relation */
  photo: MetadataTargetVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTargetVersionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataTargetVersion.archive" relation */
  archive?: InputMaybe<MetadataTargetVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTargetVersion.photo" relation */
  photo?: InputMaybe<MetadataTargetVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTargetVersionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataTargetVersion.archive" relation */
  archive: MetadataTargetVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataTargetVersion.metadata" relation */
  metadata: MetadataTargetVersionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTargetVersionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataTargetVersion.archive" relation */
  archive?: InputMaybe<MetadataTargetVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTargetVersion.metadata" relation */
  metadata?: InputMaybe<MetadataTargetVersionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataTitle" resource's node */
export type MetadataTitle = {
  __typename?: 'MetadataTitle';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataTitleCreateInput = {
  /** Actions for the "MetadataTitle.archive" relation */
  archive: MetadataTitleNestedArchiveCreateInput;
  /** Actions for the "MetadataTitle.metadata" relation */
  metadata: MetadataTitleNestedMetadataCreateInput;
  /** Actions for the "MetadataTitle.photo" relation */
  photo: MetadataTitleNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTitleNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataTitle" node, through the "MetadataTitle.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataTitle" node, through the "MetadataTitle.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataTitle" node, through the "MetadataTitle.archive" relation. */
  update?: InputMaybe<MetadataTitleNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataTitle" node, through the "MetadataTitle.archive" relation. */
  upsert?: InputMaybe<MetadataTitleNestedUpsertArchiveCreateInput>;
};

export type MetadataTitleNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataTitle" node, through the "MetadataTitle.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataTitle" node, through the "MetadataTitle.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataTitle" node, through the "MetadataTitle.archive" relation. */
  update?: InputMaybe<MetadataTitleNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataTitle" node, through the "MetadataTitle.archive" relation. */
  upsert?: InputMaybe<MetadataTitleNestedUpsertArchiveUpdateInput>;
};

export type MetadataTitleNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataTitle" node, through the "MetadataTitle.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataTitle" node, through the "MetadataTitle.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataTitle" node, through the "MetadataTitle.metadata" relation. */
  update?: InputMaybe<MetadataTitleNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataTitle" node, through the "MetadataTitle.metadata" relation. */
  upsert?: InputMaybe<MetadataTitleNestedUpsertMetadataCreateInput>;
};

export type MetadataTitleNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataTitle" node, through the "MetadataTitle.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataTitle" node, through the "MetadataTitle.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataTitle" node, through the "MetadataTitle.metadata" relation. */
  update?: InputMaybe<MetadataTitleNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataTitle" node, through the "MetadataTitle.metadata" relation. */
  upsert?: InputMaybe<MetadataTitleNestedUpsertMetadataUpdateInput>;
};

export type MetadataTitleNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataTitle" node, through the "MetadataTitle.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataTitle" node, through the "MetadataTitle.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataTitle" node, through the "MetadataTitle.photo" relation. */
  update?: InputMaybe<MetadataTitleNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataTitle" node, through the "MetadataTitle.photo" relation. */
  upsert?: InputMaybe<MetadataTitleNestedUpsertPhotoCreateInput>;
};

export type MetadataTitleNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataTitle" node, through the "MetadataTitle.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataTitle" node, through the "MetadataTitle.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataTitle" node, through the "MetadataTitle.photo" relation. */
  update?: InputMaybe<MetadataTitleNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataTitle" node, through the "MetadataTitle.photo" relation. */
  upsert?: InputMaybe<MetadataTitleNestedUpsertPhotoUpdateInput>;
};

export type MetadataTitleNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTitleNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTitleNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTitleNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTitleNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTitleNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTitleNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTitleNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTitleNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTitleNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTitleNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTitleNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataTitleOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataTitleUpdateInput = {
  /** Actions for the "MetadataTitle.archive" relation */
  archive?: InputMaybe<MetadataTitleNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTitle.metadata" relation */
  metadata?: InputMaybe<MetadataTitleNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTitle.photo" relation */
  photo?: InputMaybe<MetadataTitleNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataTitle" nodes by specifying some conditions */
export type MetadataTitleWhereInput = {
  AND?: InputMaybe<Array<MetadataTitleWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataTitleWhereInput>;
  OR?: InputMaybe<Array<MetadataTitleWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataTitle" node. */
export type MetadataTitleWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataTitleWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataTitle.metadata" relation */
  metadata: MetadataTitleNestedMetadataCreateInput;
  /** Actions for the "MetadataTitle.photo" relation */
  photo: MetadataTitleNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTitleWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataTitle.metadata" relation */
  metadata?: InputMaybe<MetadataTitleNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTitle.photo" relation */
  photo?: InputMaybe<MetadataTitleNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTitleWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataTitle.archive" relation */
  archive: MetadataTitleNestedArchiveCreateInput;
  /** Actions for the "MetadataTitle.photo" relation */
  photo: MetadataTitleNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTitleWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataTitle.archive" relation */
  archive?: InputMaybe<MetadataTitleNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTitle.photo" relation */
  photo?: InputMaybe<MetadataTitleNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTitleWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataTitle.archive" relation */
  archive: MetadataTitleNestedArchiveCreateInput;
  /** Actions for the "MetadataTitle.metadata" relation */
  metadata: MetadataTitleNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTitleWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataTitle.archive" relation */
  archive?: InputMaybe<MetadataTitleNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTitle.metadata" relation */
  metadata?: InputMaybe<MetadataTitleNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataToneCurve" resource's node */
export type MetadataToneCurve = {
  __typename?: 'MetadataToneCurve';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataToneCurveCreateInput = {
  /** Actions for the "MetadataToneCurve.archive" relation */
  archive: MetadataToneCurveNestedArchiveCreateInput;
  /** Actions for the "MetadataToneCurve.metadata" relation */
  metadata: MetadataToneCurveNestedMetadataCreateInput;
  /** Actions for the "MetadataToneCurve.photo" relation */
  photo: MetadataToneCurveNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataToneCurveNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataToneCurve" node, through the "MetadataToneCurve.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataToneCurve" node, through the "MetadataToneCurve.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataToneCurve" node, through the "MetadataToneCurve.archive" relation. */
  update?: InputMaybe<MetadataToneCurveNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataToneCurve" node, through the "MetadataToneCurve.archive" relation. */
  upsert?: InputMaybe<MetadataToneCurveNestedUpsertArchiveCreateInput>;
};

export type MetadataToneCurveNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.archive" relation. */
  update?: InputMaybe<MetadataToneCurveNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.archive" relation. */
  upsert?: InputMaybe<MetadataToneCurveNestedUpsertArchiveUpdateInput>;
};

export type MetadataToneCurveNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataToneCurve" node, through the "MetadataToneCurve.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataToneCurve" node, through the "MetadataToneCurve.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataToneCurve" node, through the "MetadataToneCurve.metadata" relation. */
  update?: InputMaybe<MetadataToneCurveNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataToneCurve" node, through the "MetadataToneCurve.metadata" relation. */
  upsert?: InputMaybe<MetadataToneCurveNestedUpsertMetadataCreateInput>;
};

export type MetadataToneCurveNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.metadata" relation. */
  update?: InputMaybe<MetadataToneCurveNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.metadata" relation. */
  upsert?: InputMaybe<MetadataToneCurveNestedUpsertMetadataUpdateInput>;
};

export type MetadataToneCurveNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataToneCurve" node, through the "MetadataToneCurve.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataToneCurve" node, through the "MetadataToneCurve.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataToneCurve" node, through the "MetadataToneCurve.photo" relation. */
  update?: InputMaybe<MetadataToneCurveNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataToneCurve" node, through the "MetadataToneCurve.photo" relation. */
  upsert?: InputMaybe<MetadataToneCurveNestedUpsertPhotoCreateInput>;
};

export type MetadataToneCurveNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.photo" relation. */
  update?: InputMaybe<MetadataToneCurveNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataToneCurve" node, through the "MetadataToneCurve.photo" relation. */
  upsert?: InputMaybe<MetadataToneCurveNestedUpsertPhotoUpdateInput>;
};

export type MetadataToneCurveNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataToneCurveNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataToneCurveNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataToneCurveNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataToneCurveNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataToneCurveNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataToneCurveNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataToneCurveNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataToneCurveNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataToneCurveNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataToneCurveNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataToneCurveNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataToneCurveOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataToneCurveUpdateInput = {
  /** Actions for the "MetadataToneCurve.archive" relation */
  archive?: InputMaybe<MetadataToneCurveNestedArchiveUpdateInput>;
  /** Actions for the "MetadataToneCurve.metadata" relation */
  metadata?: InputMaybe<MetadataToneCurveNestedMetadataUpdateInput>;
  /** Actions for the "MetadataToneCurve.photo" relation */
  photo?: InputMaybe<MetadataToneCurveNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataToneCurve" nodes by specifying some conditions */
export type MetadataToneCurveWhereInput = {
  AND?: InputMaybe<Array<MetadataToneCurveWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataToneCurveWhereInput>;
  OR?: InputMaybe<Array<MetadataToneCurveWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataToneCurve" node. */
export type MetadataToneCurveWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataToneCurveWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataToneCurve.metadata" relation */
  metadata: MetadataToneCurveNestedMetadataCreateInput;
  /** Actions for the "MetadataToneCurve.photo" relation */
  photo: MetadataToneCurveNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataToneCurveWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataToneCurve.metadata" relation */
  metadata?: InputMaybe<MetadataToneCurveNestedMetadataUpdateInput>;
  /** Actions for the "MetadataToneCurve.photo" relation */
  photo?: InputMaybe<MetadataToneCurveNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataToneCurveWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataToneCurve.archive" relation */
  archive: MetadataToneCurveNestedArchiveCreateInput;
  /** Actions for the "MetadataToneCurve.photo" relation */
  photo: MetadataToneCurveNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataToneCurveWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataToneCurve.archive" relation */
  archive?: InputMaybe<MetadataToneCurveNestedArchiveUpdateInput>;
  /** Actions for the "MetadataToneCurve.photo" relation */
  photo?: InputMaybe<MetadataToneCurveNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataToneCurveWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataToneCurve.archive" relation */
  archive: MetadataToneCurveNestedArchiveCreateInput;
  /** Actions for the "MetadataToneCurve.metadata" relation */
  metadata: MetadataToneCurveNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataToneCurveWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataToneCurve.archive" relation */
  archive?: InputMaybe<MetadataToneCurveNestedArchiveUpdateInput>;
  /** Actions for the "MetadataToneCurve.metadata" relation */
  metadata?: InputMaybe<MetadataToneCurveNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataTransferredBy" resource's node */
export type MetadataTransferredBy = {
  __typename?: 'MetadataTransferredBy';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataTransferredByCreateInput = {
  /** Actions for the "MetadataTransferredBy.archive" relation */
  archive: MetadataTransferredByNestedArchiveCreateInput;
  /** Actions for the "MetadataTransferredBy.metadata" relation */
  metadata: MetadataTransferredByNestedMetadataCreateInput;
  /** Actions for the "MetadataTransferredBy.photo" relation */
  photo: MetadataTransferredByNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataTransferredByEmail" resource's node */
export type MetadataTransferredByEmail = {
  __typename?: 'MetadataTransferredByEmail';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataTransferredByEmailCreateInput = {
  /** Actions for the "MetadataTransferredByEmail.archive" relation */
  archive: MetadataTransferredByEmailNestedArchiveCreateInput;
  /** Actions for the "MetadataTransferredByEmail.metadata" relation */
  metadata: MetadataTransferredByEmailNestedMetadataCreateInput;
  /** Actions for the "MetadataTransferredByEmail.photo" relation */
  photo: MetadataTransferredByEmailNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByEmailNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.archive" relation. */
  update?: InputMaybe<MetadataTransferredByEmailNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.archive" relation. */
  upsert?: InputMaybe<MetadataTransferredByEmailNestedUpsertArchiveCreateInput>;
};

export type MetadataTransferredByEmailNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.archive" relation. */
  update?: InputMaybe<MetadataTransferredByEmailNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.archive" relation. */
  upsert?: InputMaybe<MetadataTransferredByEmailNestedUpsertArchiveUpdateInput>;
};

export type MetadataTransferredByEmailNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.metadata" relation. */
  update?: InputMaybe<MetadataTransferredByEmailNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.metadata" relation. */
  upsert?: InputMaybe<MetadataTransferredByEmailNestedUpsertMetadataCreateInput>;
};

export type MetadataTransferredByEmailNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.metadata" relation. */
  update?: InputMaybe<MetadataTransferredByEmailNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.metadata" relation. */
  upsert?: InputMaybe<MetadataTransferredByEmailNestedUpsertMetadataUpdateInput>;
};

export type MetadataTransferredByEmailNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.photo" relation. */
  update?: InputMaybe<MetadataTransferredByEmailNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.photo" relation. */
  upsert?: InputMaybe<MetadataTransferredByEmailNestedUpsertPhotoCreateInput>;
};

export type MetadataTransferredByEmailNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.photo" relation. */
  update?: InputMaybe<MetadataTransferredByEmailNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataTransferredByEmail" node, through the "MetadataTransferredByEmail.photo" relation. */
  upsert?: InputMaybe<MetadataTransferredByEmailNestedUpsertPhotoUpdateInput>;
};

export type MetadataTransferredByEmailNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByEmailNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByEmailNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByEmailNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByEmailNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransferredByEmailNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransferredByEmailNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByEmailNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByEmailNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByEmailNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByEmailNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransferredByEmailNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataTransferredByEmailOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataTransferredByEmailUpdateInput = {
  /** Actions for the "MetadataTransferredByEmail.archive" relation */
  archive?: InputMaybe<MetadataTransferredByEmailNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransferredByEmail.metadata" relation */
  metadata?: InputMaybe<MetadataTransferredByEmailNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTransferredByEmail.photo" relation */
  photo?: InputMaybe<MetadataTransferredByEmailNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataTransferredByEmail" nodes by specifying some conditions */
export type MetadataTransferredByEmailWhereInput = {
  AND?: InputMaybe<Array<MetadataTransferredByEmailWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataTransferredByEmailWhereInput>;
  OR?: InputMaybe<Array<MetadataTransferredByEmailWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataTransferredByEmail" node. */
export type MetadataTransferredByEmailWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataTransferredByEmailWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataTransferredByEmail.metadata" relation */
  metadata: MetadataTransferredByEmailNestedMetadataCreateInput;
  /** Actions for the "MetadataTransferredByEmail.photo" relation */
  photo: MetadataTransferredByEmailNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByEmailWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataTransferredByEmail.metadata" relation */
  metadata?: InputMaybe<MetadataTransferredByEmailNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTransferredByEmail.photo" relation */
  photo?: InputMaybe<MetadataTransferredByEmailNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByEmailWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataTransferredByEmail.archive" relation */
  archive: MetadataTransferredByEmailNestedArchiveCreateInput;
  /** Actions for the "MetadataTransferredByEmail.photo" relation */
  photo: MetadataTransferredByEmailNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByEmailWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataTransferredByEmail.archive" relation */
  archive?: InputMaybe<MetadataTransferredByEmailNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransferredByEmail.photo" relation */
  photo?: InputMaybe<MetadataTransferredByEmailNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByEmailWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataTransferredByEmail.archive" relation */
  archive: MetadataTransferredByEmailNestedArchiveCreateInput;
  /** Actions for the "MetadataTransferredByEmail.metadata" relation */
  metadata: MetadataTransferredByEmailNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByEmailWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataTransferredByEmail.archive" relation */
  archive?: InputMaybe<MetadataTransferredByEmailNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransferredByEmail.metadata" relation */
  metadata?: InputMaybe<MetadataTransferredByEmailNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataTransferredByFullName" resource's node */
export type MetadataTransferredByFullName = {
  __typename?: 'MetadataTransferredByFullName';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataTransferredByFullNameCreateInput = {
  /** Actions for the "MetadataTransferredByFullName.archive" relation */
  archive: MetadataTransferredByFullNameNestedArchiveCreateInput;
  /** Actions for the "MetadataTransferredByFullName.metadata" relation */
  metadata: MetadataTransferredByFullNameNestedMetadataCreateInput;
  /** Actions for the "MetadataTransferredByFullName.photo" relation */
  photo: MetadataTransferredByFullNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByFullNameNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.archive" relation. */
  update?: InputMaybe<MetadataTransferredByFullNameNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.archive" relation. */
  upsert?: InputMaybe<MetadataTransferredByFullNameNestedUpsertArchiveCreateInput>;
};

export type MetadataTransferredByFullNameNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.archive" relation. */
  update?: InputMaybe<MetadataTransferredByFullNameNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.archive" relation. */
  upsert?: InputMaybe<MetadataTransferredByFullNameNestedUpsertArchiveUpdateInput>;
};

export type MetadataTransferredByFullNameNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.metadata" relation. */
  update?: InputMaybe<MetadataTransferredByFullNameNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.metadata" relation. */
  upsert?: InputMaybe<MetadataTransferredByFullNameNestedUpsertMetadataCreateInput>;
};

export type MetadataTransferredByFullNameNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.metadata" relation. */
  update?: InputMaybe<MetadataTransferredByFullNameNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.metadata" relation. */
  upsert?: InputMaybe<MetadataTransferredByFullNameNestedUpsertMetadataUpdateInput>;
};

export type MetadataTransferredByFullNameNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.photo" relation. */
  update?: InputMaybe<MetadataTransferredByFullNameNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.photo" relation. */
  upsert?: InputMaybe<MetadataTransferredByFullNameNestedUpsertPhotoCreateInput>;
};

export type MetadataTransferredByFullNameNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.photo" relation. */
  update?: InputMaybe<MetadataTransferredByFullNameNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataTransferredByFullName" node, through the "MetadataTransferredByFullName.photo" relation. */
  upsert?: InputMaybe<MetadataTransferredByFullNameNestedUpsertPhotoUpdateInput>;
};

export type MetadataTransferredByFullNameNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByFullNameNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByFullNameNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByFullNameNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByFullNameNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransferredByFullNameNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransferredByFullNameNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByFullNameNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByFullNameNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByFullNameNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByFullNameNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransferredByFullNameNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataTransferredByFullNameOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataTransferredByFullNameUpdateInput = {
  /** Actions for the "MetadataTransferredByFullName.archive" relation */
  archive?: InputMaybe<MetadataTransferredByFullNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransferredByFullName.metadata" relation */
  metadata?: InputMaybe<MetadataTransferredByFullNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTransferredByFullName.photo" relation */
  photo?: InputMaybe<MetadataTransferredByFullNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataTransferredByFullName" nodes by specifying some conditions */
export type MetadataTransferredByFullNameWhereInput = {
  AND?: InputMaybe<Array<MetadataTransferredByFullNameWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
  OR?: InputMaybe<Array<MetadataTransferredByFullNameWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataTransferredByFullName" node. */
export type MetadataTransferredByFullNameWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataTransferredByFullNameWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataTransferredByFullName.metadata" relation */
  metadata: MetadataTransferredByFullNameNestedMetadataCreateInput;
  /** Actions for the "MetadataTransferredByFullName.photo" relation */
  photo: MetadataTransferredByFullNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByFullNameWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataTransferredByFullName.metadata" relation */
  metadata?: InputMaybe<MetadataTransferredByFullNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTransferredByFullName.photo" relation */
  photo?: InputMaybe<MetadataTransferredByFullNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByFullNameWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataTransferredByFullName.archive" relation */
  archive: MetadataTransferredByFullNameNestedArchiveCreateInput;
  /** Actions for the "MetadataTransferredByFullName.photo" relation */
  photo: MetadataTransferredByFullNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByFullNameWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataTransferredByFullName.archive" relation */
  archive?: InputMaybe<MetadataTransferredByFullNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransferredByFullName.photo" relation */
  photo?: InputMaybe<MetadataTransferredByFullNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByFullNameWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataTransferredByFullName.archive" relation */
  archive: MetadataTransferredByFullNameNestedArchiveCreateInput;
  /** Actions for the "MetadataTransferredByFullName.metadata" relation */
  metadata: MetadataTransferredByFullNameNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByFullNameWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataTransferredByFullName.archive" relation */
  archive?: InputMaybe<MetadataTransferredByFullNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransferredByFullName.metadata" relation */
  metadata?: InputMaybe<MetadataTransferredByFullNameNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.archive" relation. */
  update?: InputMaybe<MetadataTransferredByNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.archive" relation. */
  upsert?: InputMaybe<MetadataTransferredByNestedUpsertArchiveCreateInput>;
};

export type MetadataTransferredByNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.archive" relation. */
  update?: InputMaybe<MetadataTransferredByNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.archive" relation. */
  upsert?: InputMaybe<MetadataTransferredByNestedUpsertArchiveUpdateInput>;
};

export type MetadataTransferredByNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.metadata" relation. */
  update?: InputMaybe<MetadataTransferredByNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.metadata" relation. */
  upsert?: InputMaybe<MetadataTransferredByNestedUpsertMetadataCreateInput>;
};

export type MetadataTransferredByNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.metadata" relation. */
  update?: InputMaybe<MetadataTransferredByNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.metadata" relation. */
  upsert?: InputMaybe<MetadataTransferredByNestedUpsertMetadataUpdateInput>;
};

export type MetadataTransferredByNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.photo" relation. */
  update?: InputMaybe<MetadataTransferredByNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataTransferredBy" node, through the "MetadataTransferredBy.photo" relation. */
  upsert?: InputMaybe<MetadataTransferredByNestedUpsertPhotoCreateInput>;
};

export type MetadataTransferredByNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.photo" relation. */
  update?: InputMaybe<MetadataTransferredByNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataTransferredBy" node, through the "MetadataTransferredBy.photo" relation. */
  upsert?: InputMaybe<MetadataTransferredByNestedUpsertPhotoUpdateInput>;
};

export type MetadataTransferredByNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransferredByNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransferredByNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransferredByNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransferredByNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransferredByNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataTransferredByOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataTransferredByUpdateInput = {
  /** Actions for the "MetadataTransferredBy.archive" relation */
  archive?: InputMaybe<MetadataTransferredByNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransferredBy.metadata" relation */
  metadata?: InputMaybe<MetadataTransferredByNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTransferredBy.photo" relation */
  photo?: InputMaybe<MetadataTransferredByNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataTransferredBy" nodes by specifying some conditions */
export type MetadataTransferredByWhereInput = {
  AND?: InputMaybe<Array<MetadataTransferredByWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataTransferredByWhereInput>;
  OR?: InputMaybe<Array<MetadataTransferredByWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataTransferredBy" node. */
export type MetadataTransferredByWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataTransferredByWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataTransferredBy.metadata" relation */
  metadata: MetadataTransferredByNestedMetadataCreateInput;
  /** Actions for the "MetadataTransferredBy.photo" relation */
  photo: MetadataTransferredByNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataTransferredBy.metadata" relation */
  metadata?: InputMaybe<MetadataTransferredByNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTransferredBy.photo" relation */
  photo?: InputMaybe<MetadataTransferredByNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataTransferredBy.archive" relation */
  archive: MetadataTransferredByNestedArchiveCreateInput;
  /** Actions for the "MetadataTransferredBy.photo" relation */
  photo: MetadataTransferredByNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataTransferredBy.archive" relation */
  archive?: InputMaybe<MetadataTransferredByNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransferredBy.photo" relation */
  photo?: InputMaybe<MetadataTransferredByNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataTransferredBy.archive" relation */
  archive: MetadataTransferredByNestedArchiveCreateInput;
  /** Actions for the "MetadataTransferredBy.metadata" relation */
  metadata: MetadataTransferredByNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransferredByWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataTransferredBy.archive" relation */
  archive?: InputMaybe<MetadataTransferredByNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransferredBy.metadata" relation */
  metadata?: InputMaybe<MetadataTransferredByNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataTransmissionReference" resource's node */
export type MetadataTransmissionReference = {
  __typename?: 'MetadataTransmissionReference';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataTransmissionReferenceCreateInput = {
  /** Actions for the "MetadataTransmissionReference.archive" relation */
  archive: MetadataTransmissionReferenceNestedArchiveCreateInput;
  /** Actions for the "MetadataTransmissionReference.metadata" relation */
  metadata: MetadataTransmissionReferenceNestedMetadataCreateInput;
  /** Actions for the "MetadataTransmissionReference.photo" relation */
  photo: MetadataTransmissionReferenceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransmissionReferenceNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.archive" relation. */
  update?: InputMaybe<MetadataTransmissionReferenceNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.archive" relation. */
  upsert?: InputMaybe<MetadataTransmissionReferenceNestedUpsertArchiveCreateInput>;
};

export type MetadataTransmissionReferenceNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.archive" relation. */
  update?: InputMaybe<MetadataTransmissionReferenceNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.archive" relation. */
  upsert?: InputMaybe<MetadataTransmissionReferenceNestedUpsertArchiveUpdateInput>;
};

export type MetadataTransmissionReferenceNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.metadata" relation. */
  update?: InputMaybe<MetadataTransmissionReferenceNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.metadata" relation. */
  upsert?: InputMaybe<MetadataTransmissionReferenceNestedUpsertMetadataCreateInput>;
};

export type MetadataTransmissionReferenceNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.metadata" relation. */
  update?: InputMaybe<MetadataTransmissionReferenceNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.metadata" relation. */
  upsert?: InputMaybe<MetadataTransmissionReferenceNestedUpsertMetadataUpdateInput>;
};

export type MetadataTransmissionReferenceNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.photo" relation. */
  update?: InputMaybe<MetadataTransmissionReferenceNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.photo" relation. */
  upsert?: InputMaybe<MetadataTransmissionReferenceNestedUpsertPhotoCreateInput>;
};

export type MetadataTransmissionReferenceNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.photo" relation. */
  update?: InputMaybe<MetadataTransmissionReferenceNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataTransmissionReference" node, through the "MetadataTransmissionReference.photo" relation. */
  upsert?: InputMaybe<MetadataTransmissionReferenceNestedUpsertPhotoUpdateInput>;
};

export type MetadataTransmissionReferenceNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransmissionReferenceNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransmissionReferenceNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransmissionReferenceNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransmissionReferenceNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransmissionReferenceNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransmissionReferenceNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransmissionReferenceNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTransmissionReferenceNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransmissionReferenceNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTransmissionReferenceNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTransmissionReferenceNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataTransmissionReferenceOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataTransmissionReferenceUpdateInput = {
  /** Actions for the "MetadataTransmissionReference.archive" relation */
  archive?: InputMaybe<MetadataTransmissionReferenceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransmissionReference.metadata" relation */
  metadata?: InputMaybe<MetadataTransmissionReferenceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTransmissionReference.photo" relation */
  photo?: InputMaybe<MetadataTransmissionReferenceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataTransmissionReference" nodes by specifying some conditions */
export type MetadataTransmissionReferenceWhereInput = {
  AND?: InputMaybe<Array<MetadataTransmissionReferenceWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
  OR?: InputMaybe<Array<MetadataTransmissionReferenceWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataTransmissionReference" node. */
export type MetadataTransmissionReferenceWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataTransmissionReferenceWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataTransmissionReference.metadata" relation */
  metadata: MetadataTransmissionReferenceNestedMetadataCreateInput;
  /** Actions for the "MetadataTransmissionReference.photo" relation */
  photo: MetadataTransmissionReferenceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransmissionReferenceWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataTransmissionReference.metadata" relation */
  metadata?: InputMaybe<MetadataTransmissionReferenceNestedMetadataUpdateInput>;
  /** Actions for the "MetadataTransmissionReference.photo" relation */
  photo?: InputMaybe<MetadataTransmissionReferenceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransmissionReferenceWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataTransmissionReference.archive" relation */
  archive: MetadataTransmissionReferenceNestedArchiveCreateInput;
  /** Actions for the "MetadataTransmissionReference.photo" relation */
  photo: MetadataTransmissionReferenceNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransmissionReferenceWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataTransmissionReference.archive" relation */
  archive?: InputMaybe<MetadataTransmissionReferenceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransmissionReference.photo" relation */
  photo?: InputMaybe<MetadataTransmissionReferenceNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransmissionReferenceWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataTransmissionReference.archive" relation */
  archive: MetadataTransmissionReferenceNestedArchiveCreateInput;
  /** Actions for the "MetadataTransmissionReference.metadata" relation */
  metadata: MetadataTransmissionReferenceNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTransmissionReferenceWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataTransmissionReference.archive" relation */
  archive?: InputMaybe<MetadataTransmissionReferenceNestedArchiveUpdateInput>;
  /** Actions for the "MetadataTransmissionReference.metadata" relation */
  metadata?: InputMaybe<MetadataTransmissionReferenceNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataType" resource's node */
export type MetadataType = {
  __typename?: 'MetadataType';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataTypeCreateInput = {
  /** Actions for the "MetadataType.archive" relation */
  archive: MetadataTypeNestedArchiveCreateInput;
  /** Actions for the "MetadataType.metadata" relation */
  metadata: MetadataTypeNestedMetadataCreateInput;
  /** Actions for the "MetadataType.photo" relation */
  photo: MetadataTypeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTypeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataType" node, through the "MetadataType.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataType" node, through the "MetadataType.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataType" node, through the "MetadataType.archive" relation. */
  update?: InputMaybe<MetadataTypeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataType" node, through the "MetadataType.archive" relation. */
  upsert?: InputMaybe<MetadataTypeNestedUpsertArchiveCreateInput>;
};

export type MetadataTypeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataType" node, through the "MetadataType.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataType" node, through the "MetadataType.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataType" node, through the "MetadataType.archive" relation. */
  update?: InputMaybe<MetadataTypeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataType" node, through the "MetadataType.archive" relation. */
  upsert?: InputMaybe<MetadataTypeNestedUpsertArchiveUpdateInput>;
};

export type MetadataTypeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataType" node, through the "MetadataType.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataType" node, through the "MetadataType.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataType" node, through the "MetadataType.metadata" relation. */
  update?: InputMaybe<MetadataTypeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataType" node, through the "MetadataType.metadata" relation. */
  upsert?: InputMaybe<MetadataTypeNestedUpsertMetadataCreateInput>;
};

export type MetadataTypeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataType" node, through the "MetadataType.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataType" node, through the "MetadataType.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataType" node, through the "MetadataType.metadata" relation. */
  update?: InputMaybe<MetadataTypeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataType" node, through the "MetadataType.metadata" relation. */
  upsert?: InputMaybe<MetadataTypeNestedUpsertMetadataUpdateInput>;
};

export type MetadataTypeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataType" node, through the "MetadataType.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataType" node, through the "MetadataType.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataType" node, through the "MetadataType.photo" relation. */
  update?: InputMaybe<MetadataTypeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataType" node, through the "MetadataType.photo" relation. */
  upsert?: InputMaybe<MetadataTypeNestedUpsertPhotoCreateInput>;
};

export type MetadataTypeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataType" node, through the "MetadataType.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataType" node, through the "MetadataType.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataType" node, through the "MetadataType.photo" relation. */
  update?: InputMaybe<MetadataTypeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataType" node, through the "MetadataType.photo" relation. */
  upsert?: InputMaybe<MetadataTypeNestedUpsertPhotoUpdateInput>;
};

export type MetadataTypeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTypeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTypeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTypeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTypeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTypeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTypeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTypeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataTypeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTypeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataTypeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataTypeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataTypeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataTypeUpdateInput = {
  /** Actions for the "MetadataType.archive" relation */
  archive?: InputMaybe<MetadataTypeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataType.metadata" relation */
  metadata?: InputMaybe<MetadataTypeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataType.photo" relation */
  photo?: InputMaybe<MetadataTypeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataType" nodes by specifying some conditions */
export type MetadataTypeWhereInput = {
  AND?: InputMaybe<Array<MetadataTypeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataTypeWhereInput>;
  OR?: InputMaybe<Array<MetadataTypeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataType" node. */
export type MetadataTypeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataTypeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataType.metadata" relation */
  metadata: MetadataTypeNestedMetadataCreateInput;
  /** Actions for the "MetadataType.photo" relation */
  photo: MetadataTypeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTypeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataType.metadata" relation */
  metadata?: InputMaybe<MetadataTypeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataType.photo" relation */
  photo?: InputMaybe<MetadataTypeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTypeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataType.archive" relation */
  archive: MetadataTypeNestedArchiveCreateInput;
  /** Actions for the "MetadataType.photo" relation */
  photo: MetadataTypeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTypeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataType.archive" relation */
  archive?: InputMaybe<MetadataTypeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataType.photo" relation */
  photo?: InputMaybe<MetadataTypeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTypeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataType.archive" relation */
  archive: MetadataTypeNestedArchiveCreateInput;
  /** Actions for the "MetadataType.metadata" relation */
  metadata: MetadataTypeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataTypeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataType.archive" relation */
  archive?: InputMaybe<MetadataTypeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataType.metadata" relation */
  metadata?: InputMaybe<MetadataTypeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUndersubject" resource's node */
export type MetadataUndersubject = {
  __typename?: 'MetadataUndersubject';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUndersubjectCreateInput = {
  /** Actions for the "MetadataUndersubject.archive" relation */
  archive: MetadataUndersubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataUndersubject.metadata" relation */
  metadata: MetadataUndersubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataUndersubject.photo" relation */
  photo: MetadataUndersubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUndersubjectNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUndersubject" node, through the "MetadataUndersubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUndersubject" node, through the "MetadataUndersubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUndersubject" node, through the "MetadataUndersubject.archive" relation. */
  update?: InputMaybe<MetadataUndersubjectNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUndersubject" node, through the "MetadataUndersubject.archive" relation. */
  upsert?: InputMaybe<MetadataUndersubjectNestedUpsertArchiveCreateInput>;
};

export type MetadataUndersubjectNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.archive" relation. */
  update?: InputMaybe<MetadataUndersubjectNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.archive" relation. */
  upsert?: InputMaybe<MetadataUndersubjectNestedUpsertArchiveUpdateInput>;
};

export type MetadataUndersubjectNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUndersubject" node, through the "MetadataUndersubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUndersubject" node, through the "MetadataUndersubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUndersubject" node, through the "MetadataUndersubject.metadata" relation. */
  update?: InputMaybe<MetadataUndersubjectNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUndersubject" node, through the "MetadataUndersubject.metadata" relation. */
  upsert?: InputMaybe<MetadataUndersubjectNestedUpsertMetadataCreateInput>;
};

export type MetadataUndersubjectNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.metadata" relation. */
  update?: InputMaybe<MetadataUndersubjectNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.metadata" relation. */
  upsert?: InputMaybe<MetadataUndersubjectNestedUpsertMetadataUpdateInput>;
};

export type MetadataUndersubjectNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUndersubject" node, through the "MetadataUndersubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUndersubject" node, through the "MetadataUndersubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUndersubject" node, through the "MetadataUndersubject.photo" relation. */
  update?: InputMaybe<MetadataUndersubjectNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUndersubject" node, through the "MetadataUndersubject.photo" relation. */
  upsert?: InputMaybe<MetadataUndersubjectNestedUpsertPhotoCreateInput>;
};

export type MetadataUndersubjectNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.photo" relation. */
  update?: InputMaybe<MetadataUndersubjectNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUndersubject" node, through the "MetadataUndersubject.photo" relation. */
  upsert?: InputMaybe<MetadataUndersubjectNestedUpsertPhotoUpdateInput>;
};

export type MetadataUndersubjectNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUndersubjectNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUndersubjectNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUndersubjectNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUndersubjectNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUndersubjectNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUndersubjectNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUndersubjectNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUndersubjectNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUndersubjectNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUndersubjectNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUndersubjectNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUndersubjectOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUndersubjectUpdateInput = {
  /** Actions for the "MetadataUndersubject.archive" relation */
  archive?: InputMaybe<MetadataUndersubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUndersubject.metadata" relation */
  metadata?: InputMaybe<MetadataUndersubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUndersubject.photo" relation */
  photo?: InputMaybe<MetadataUndersubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUndersubject" nodes by specifying some conditions */
export type MetadataUndersubjectWhereInput = {
  AND?: InputMaybe<Array<MetadataUndersubjectWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUndersubjectWhereInput>;
  OR?: InputMaybe<Array<MetadataUndersubjectWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUndersubject" node. */
export type MetadataUndersubjectWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUndersubjectWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUndersubject.metadata" relation */
  metadata: MetadataUndersubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataUndersubject.photo" relation */
  photo: MetadataUndersubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUndersubjectWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUndersubject.metadata" relation */
  metadata?: InputMaybe<MetadataUndersubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUndersubject.photo" relation */
  photo?: InputMaybe<MetadataUndersubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUndersubjectWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUndersubject.archive" relation */
  archive: MetadataUndersubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataUndersubject.photo" relation */
  photo: MetadataUndersubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUndersubjectWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUndersubject.archive" relation */
  archive?: InputMaybe<MetadataUndersubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUndersubject.photo" relation */
  photo?: InputMaybe<MetadataUndersubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUndersubjectWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUndersubject.archive" relation */
  archive: MetadataUndersubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataUndersubject.metadata" relation */
  metadata: MetadataUndersubjectNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUndersubjectWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUndersubject.archive" relation */
  archive?: InputMaybe<MetadataUndersubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUndersubject.metadata" relation */
  metadata?: InputMaybe<MetadataUndersubjectNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUnderUnderSubject" resource's node */
export type MetadataUnderUnderSubject = {
  __typename?: 'MetadataUnderUnderSubject';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUnderUnderSubjectCreateInput = {
  /** Actions for the "MetadataUnderUnderSubject.archive" relation */
  archive: MetadataUnderUnderSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataUnderUnderSubject.metadata" relation */
  metadata: MetadataUnderUnderSubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataUnderUnderSubject.photo" relation */
  photo: MetadataUnderUnderSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnderUnderSubjectNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.archive" relation. */
  update?: InputMaybe<MetadataUnderUnderSubjectNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.archive" relation. */
  upsert?: InputMaybe<MetadataUnderUnderSubjectNestedUpsertArchiveCreateInput>;
};

export type MetadataUnderUnderSubjectNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.archive" relation. */
  update?: InputMaybe<MetadataUnderUnderSubjectNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.archive" relation. */
  upsert?: InputMaybe<MetadataUnderUnderSubjectNestedUpsertArchiveUpdateInput>;
};

export type MetadataUnderUnderSubjectNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.metadata" relation. */
  update?: InputMaybe<MetadataUnderUnderSubjectNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.metadata" relation. */
  upsert?: InputMaybe<MetadataUnderUnderSubjectNestedUpsertMetadataCreateInput>;
};

export type MetadataUnderUnderSubjectNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.metadata" relation. */
  update?: InputMaybe<MetadataUnderUnderSubjectNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.metadata" relation. */
  upsert?: InputMaybe<MetadataUnderUnderSubjectNestedUpsertMetadataUpdateInput>;
};

export type MetadataUnderUnderSubjectNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.photo" relation. */
  update?: InputMaybe<MetadataUnderUnderSubjectNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.photo" relation. */
  upsert?: InputMaybe<MetadataUnderUnderSubjectNestedUpsertPhotoCreateInput>;
};

export type MetadataUnderUnderSubjectNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.photo" relation. */
  update?: InputMaybe<MetadataUnderUnderSubjectNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUnderUnderSubject" node, through the "MetadataUnderUnderSubject.photo" relation. */
  upsert?: InputMaybe<MetadataUnderUnderSubjectNestedUpsertPhotoUpdateInput>;
};

export type MetadataUnderUnderSubjectNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnderUnderSubjectNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnderUnderSubjectNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnderUnderSubjectNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnderUnderSubjectNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUnderUnderSubjectNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUnderUnderSubjectNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnderUnderSubjectNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnderUnderSubjectNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnderUnderSubjectNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnderUnderSubjectNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUnderUnderSubjectNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUnderUnderSubjectOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUnderUnderSubjectUpdateInput = {
  /** Actions for the "MetadataUnderUnderSubject.archive" relation */
  archive?: InputMaybe<MetadataUnderUnderSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUnderUnderSubject.metadata" relation */
  metadata?: InputMaybe<MetadataUnderUnderSubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUnderUnderSubject.photo" relation */
  photo?: InputMaybe<MetadataUnderUnderSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUnderUnderSubject" nodes by specifying some conditions */
export type MetadataUnderUnderSubjectWhereInput = {
  AND?: InputMaybe<Array<MetadataUnderUnderSubjectWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
  OR?: InputMaybe<Array<MetadataUnderUnderSubjectWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUnderUnderSubject" node. */
export type MetadataUnderUnderSubjectWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUnderUnderSubjectWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUnderUnderSubject.metadata" relation */
  metadata: MetadataUnderUnderSubjectNestedMetadataCreateInput;
  /** Actions for the "MetadataUnderUnderSubject.photo" relation */
  photo: MetadataUnderUnderSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnderUnderSubjectWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUnderUnderSubject.metadata" relation */
  metadata?: InputMaybe<MetadataUnderUnderSubjectNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUnderUnderSubject.photo" relation */
  photo?: InputMaybe<MetadataUnderUnderSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnderUnderSubjectWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUnderUnderSubject.archive" relation */
  archive: MetadataUnderUnderSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataUnderUnderSubject.photo" relation */
  photo: MetadataUnderUnderSubjectNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnderUnderSubjectWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUnderUnderSubject.archive" relation */
  archive?: InputMaybe<MetadataUnderUnderSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUnderUnderSubject.photo" relation */
  photo?: InputMaybe<MetadataUnderUnderSubjectNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnderUnderSubjectWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUnderUnderSubject.archive" relation */
  archive: MetadataUnderUnderSubjectNestedArchiveCreateInput;
  /** Actions for the "MetadataUnderUnderSubject.metadata" relation */
  metadata: MetadataUnderUnderSubjectNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnderUnderSubjectWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUnderUnderSubject.archive" relation */
  archive?: InputMaybe<MetadataUnderUnderSubjectNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUnderUnderSubject.metadata" relation */
  metadata?: InputMaybe<MetadataUnderUnderSubjectNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUniqueId" resource's node */
export type MetadataUniqueId = {
  __typename?: 'MetadataUniqueId';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUniqueIdCreateInput = {
  /** Actions for the "MetadataUniqueId.archive" relation */
  archive: MetadataUniqueIdNestedArchiveCreateInput;
  /** Actions for the "MetadataUniqueId.metadata" relation */
  metadata: MetadataUniqueIdNestedMetadataCreateInput;
  /** Actions for the "MetadataUniqueId.photo" relation */
  photo: MetadataUniqueIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUniqueIdNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUniqueId" node, through the "MetadataUniqueId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUniqueId" node, through the "MetadataUniqueId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUniqueId" node, through the "MetadataUniqueId.archive" relation. */
  update?: InputMaybe<MetadataUniqueIdNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUniqueId" node, through the "MetadataUniqueId.archive" relation. */
  upsert?: InputMaybe<MetadataUniqueIdNestedUpsertArchiveCreateInput>;
};

export type MetadataUniqueIdNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.archive" relation. */
  update?: InputMaybe<MetadataUniqueIdNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.archive" relation. */
  upsert?: InputMaybe<MetadataUniqueIdNestedUpsertArchiveUpdateInput>;
};

export type MetadataUniqueIdNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUniqueId" node, through the "MetadataUniqueId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUniqueId" node, through the "MetadataUniqueId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUniqueId" node, through the "MetadataUniqueId.metadata" relation. */
  update?: InputMaybe<MetadataUniqueIdNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUniqueId" node, through the "MetadataUniqueId.metadata" relation. */
  upsert?: InputMaybe<MetadataUniqueIdNestedUpsertMetadataCreateInput>;
};

export type MetadataUniqueIdNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.metadata" relation. */
  update?: InputMaybe<MetadataUniqueIdNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.metadata" relation. */
  upsert?: InputMaybe<MetadataUniqueIdNestedUpsertMetadataUpdateInput>;
};

export type MetadataUniqueIdNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUniqueId" node, through the "MetadataUniqueId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUniqueId" node, through the "MetadataUniqueId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUniqueId" node, through the "MetadataUniqueId.photo" relation. */
  update?: InputMaybe<MetadataUniqueIdNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUniqueId" node, through the "MetadataUniqueId.photo" relation. */
  upsert?: InputMaybe<MetadataUniqueIdNestedUpsertPhotoCreateInput>;
};

export type MetadataUniqueIdNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.photo" relation. */
  update?: InputMaybe<MetadataUniqueIdNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUniqueId" node, through the "MetadataUniqueId.photo" relation. */
  upsert?: InputMaybe<MetadataUniqueIdNestedUpsertPhotoUpdateInput>;
};

export type MetadataUniqueIdNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUniqueIdNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUniqueIdNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUniqueIdNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUniqueIdNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUniqueIdNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUniqueIdNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUniqueIdNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUniqueIdNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUniqueIdNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUniqueIdNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUniqueIdNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUniqueIdOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUniqueIdUpdateInput = {
  /** Actions for the "MetadataUniqueId.archive" relation */
  archive?: InputMaybe<MetadataUniqueIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUniqueId.metadata" relation */
  metadata?: InputMaybe<MetadataUniqueIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUniqueId.photo" relation */
  photo?: InputMaybe<MetadataUniqueIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUniqueId" nodes by specifying some conditions */
export type MetadataUniqueIdWhereInput = {
  AND?: InputMaybe<Array<MetadataUniqueIdWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUniqueIdWhereInput>;
  OR?: InputMaybe<Array<MetadataUniqueIdWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUniqueId" node. */
export type MetadataUniqueIdWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUniqueIdWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUniqueId.metadata" relation */
  metadata: MetadataUniqueIdNestedMetadataCreateInput;
  /** Actions for the "MetadataUniqueId.photo" relation */
  photo: MetadataUniqueIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUniqueIdWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUniqueId.metadata" relation */
  metadata?: InputMaybe<MetadataUniqueIdNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUniqueId.photo" relation */
  photo?: InputMaybe<MetadataUniqueIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUniqueIdWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUniqueId.archive" relation */
  archive: MetadataUniqueIdNestedArchiveCreateInput;
  /** Actions for the "MetadataUniqueId.photo" relation */
  photo: MetadataUniqueIdNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUniqueIdWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUniqueId.archive" relation */
  archive?: InputMaybe<MetadataUniqueIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUniqueId.photo" relation */
  photo?: InputMaybe<MetadataUniqueIdNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUniqueIdWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUniqueId.archive" relation */
  archive: MetadataUniqueIdNestedArchiveCreateInput;
  /** Actions for the "MetadataUniqueId.metadata" relation */
  metadata: MetadataUniqueIdNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUniqueIdWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUniqueId.archive" relation */
  archive?: InputMaybe<MetadataUniqueIdNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUniqueId.metadata" relation */
  metadata?: InputMaybe<MetadataUniqueIdNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUnit" resource's node */
export type MetadataUnit = {
  __typename?: 'MetadataUnit';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUnitCreateInput = {
  /** Actions for the "MetadataUnit.archive" relation */
  archive: MetadataUnitNestedArchiveCreateInput;
  /** Actions for the "MetadataUnit.metadata" relation */
  metadata: MetadataUnitNestedMetadataCreateInput;
  /** Actions for the "MetadataUnit.photo" relation */
  photo: MetadataUnitNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUnit" node, through the "MetadataUnit.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUnit" node, through the "MetadataUnit.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUnit" node, through the "MetadataUnit.archive" relation. */
  update?: InputMaybe<MetadataUnitNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUnit" node, through the "MetadataUnit.archive" relation. */
  upsert?: InputMaybe<MetadataUnitNestedUpsertArchiveCreateInput>;
};

export type MetadataUnitNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUnit" node, through the "MetadataUnit.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUnit" node, through the "MetadataUnit.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUnit" node, through the "MetadataUnit.archive" relation. */
  update?: InputMaybe<MetadataUnitNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUnit" node, through the "MetadataUnit.archive" relation. */
  upsert?: InputMaybe<MetadataUnitNestedUpsertArchiveUpdateInput>;
};

export type MetadataUnitNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUnit" node, through the "MetadataUnit.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUnit" node, through the "MetadataUnit.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUnit" node, through the "MetadataUnit.metadata" relation. */
  update?: InputMaybe<MetadataUnitNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUnit" node, through the "MetadataUnit.metadata" relation. */
  upsert?: InputMaybe<MetadataUnitNestedUpsertMetadataCreateInput>;
};

export type MetadataUnitNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUnit" node, through the "MetadataUnit.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUnit" node, through the "MetadataUnit.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUnit" node, through the "MetadataUnit.metadata" relation. */
  update?: InputMaybe<MetadataUnitNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUnit" node, through the "MetadataUnit.metadata" relation. */
  upsert?: InputMaybe<MetadataUnitNestedUpsertMetadataUpdateInput>;
};

export type MetadataUnitNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUnit" node, through the "MetadataUnit.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUnit" node, through the "MetadataUnit.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUnit" node, through the "MetadataUnit.photo" relation. */
  update?: InputMaybe<MetadataUnitNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUnit" node, through the "MetadataUnit.photo" relation. */
  upsert?: InputMaybe<MetadataUnitNestedUpsertPhotoCreateInput>;
};

export type MetadataUnitNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUnit" node, through the "MetadataUnit.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUnit" node, through the "MetadataUnit.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUnit" node, through the "MetadataUnit.photo" relation. */
  update?: InputMaybe<MetadataUnitNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUnit" node, through the "MetadataUnit.photo" relation. */
  upsert?: InputMaybe<MetadataUnitNestedUpsertPhotoUpdateInput>;
};

export type MetadataUnitNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnitNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnitNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnitNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnitNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUnitNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUnitNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnitNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnitNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnitNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnitNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUnitNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUnitOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

/** "MetadataUnitShortName" resource's node */
export type MetadataUnitShortName = {
  __typename?: 'MetadataUnitShortName';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUnitShortNameCreateInput = {
  /** Actions for the "MetadataUnitShortName.archive" relation */
  archive: MetadataUnitShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataUnitShortName.metadata" relation */
  metadata: MetadataUnitShortNameNestedMetadataCreateInput;
  /** Actions for the "MetadataUnitShortName.photo" relation */
  photo: MetadataUnitShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitShortNameNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.archive" relation. */
  update?: InputMaybe<MetadataUnitShortNameNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.archive" relation. */
  upsert?: InputMaybe<MetadataUnitShortNameNestedUpsertArchiveCreateInput>;
};

export type MetadataUnitShortNameNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.archive" relation. */
  update?: InputMaybe<MetadataUnitShortNameNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.archive" relation. */
  upsert?: InputMaybe<MetadataUnitShortNameNestedUpsertArchiveUpdateInput>;
};

export type MetadataUnitShortNameNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.metadata" relation. */
  update?: InputMaybe<MetadataUnitShortNameNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.metadata" relation. */
  upsert?: InputMaybe<MetadataUnitShortNameNestedUpsertMetadataCreateInput>;
};

export type MetadataUnitShortNameNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.metadata" relation. */
  update?: InputMaybe<MetadataUnitShortNameNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.metadata" relation. */
  upsert?: InputMaybe<MetadataUnitShortNameNestedUpsertMetadataUpdateInput>;
};

export type MetadataUnitShortNameNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.photo" relation. */
  update?: InputMaybe<MetadataUnitShortNameNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUnitShortName" node, through the "MetadataUnitShortName.photo" relation. */
  upsert?: InputMaybe<MetadataUnitShortNameNestedUpsertPhotoCreateInput>;
};

export type MetadataUnitShortNameNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.photo" relation. */
  update?: InputMaybe<MetadataUnitShortNameNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUnitShortName" node, through the "MetadataUnitShortName.photo" relation. */
  upsert?: InputMaybe<MetadataUnitShortNameNestedUpsertPhotoUpdateInput>;
};

export type MetadataUnitShortNameNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnitShortNameNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnitShortNameNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnitShortNameNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnitShortNameNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUnitShortNameNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUnitShortNameNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnitShortNameNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUnitShortNameNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnitShortNameNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUnitShortNameNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUnitShortNameNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUnitShortNameOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUnitShortNameUpdateInput = {
  /** Actions for the "MetadataUnitShortName.archive" relation */
  archive?: InputMaybe<MetadataUnitShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUnitShortName.metadata" relation */
  metadata?: InputMaybe<MetadataUnitShortNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUnitShortName.photo" relation */
  photo?: InputMaybe<MetadataUnitShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUnitShortName" nodes by specifying some conditions */
export type MetadataUnitShortNameWhereInput = {
  AND?: InputMaybe<Array<MetadataUnitShortNameWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUnitShortNameWhereInput>;
  OR?: InputMaybe<Array<MetadataUnitShortNameWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUnitShortName" node. */
export type MetadataUnitShortNameWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUnitShortNameWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUnitShortName.metadata" relation */
  metadata: MetadataUnitShortNameNestedMetadataCreateInput;
  /** Actions for the "MetadataUnitShortName.photo" relation */
  photo: MetadataUnitShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitShortNameWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUnitShortName.metadata" relation */
  metadata?: InputMaybe<MetadataUnitShortNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUnitShortName.photo" relation */
  photo?: InputMaybe<MetadataUnitShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitShortNameWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUnitShortName.archive" relation */
  archive: MetadataUnitShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataUnitShortName.photo" relation */
  photo: MetadataUnitShortNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitShortNameWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUnitShortName.archive" relation */
  archive?: InputMaybe<MetadataUnitShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUnitShortName.photo" relation */
  photo?: InputMaybe<MetadataUnitShortNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitShortNameWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUnitShortName.archive" relation */
  archive: MetadataUnitShortNameNestedArchiveCreateInput;
  /** Actions for the "MetadataUnitShortName.metadata" relation */
  metadata: MetadataUnitShortNameNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitShortNameWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUnitShortName.archive" relation */
  archive?: InputMaybe<MetadataUnitShortNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUnitShortName.metadata" relation */
  metadata?: InputMaybe<MetadataUnitShortNameNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitUpdateInput = {
  /** Actions for the "MetadataUnit.archive" relation */
  archive?: InputMaybe<MetadataUnitNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUnit.metadata" relation */
  metadata?: InputMaybe<MetadataUnitNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUnit.photo" relation */
  photo?: InputMaybe<MetadataUnitNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUnit" nodes by specifying some conditions */
export type MetadataUnitWhereInput = {
  AND?: InputMaybe<Array<MetadataUnitWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUnitWhereInput>;
  OR?: InputMaybe<Array<MetadataUnitWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUnit" node. */
export type MetadataUnitWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUnitWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUnit.metadata" relation */
  metadata: MetadataUnitNestedMetadataCreateInput;
  /** Actions for the "MetadataUnit.photo" relation */
  photo: MetadataUnitNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUnit.metadata" relation */
  metadata?: InputMaybe<MetadataUnitNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUnit.photo" relation */
  photo?: InputMaybe<MetadataUnitNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUnit.archive" relation */
  archive: MetadataUnitNestedArchiveCreateInput;
  /** Actions for the "MetadataUnit.photo" relation */
  photo: MetadataUnitNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUnit.archive" relation */
  archive?: InputMaybe<MetadataUnitNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUnit.photo" relation */
  photo?: InputMaybe<MetadataUnitNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUnit.archive" relation */
  archive: MetadataUnitNestedArchiveCreateInput;
  /** Actions for the "MetadataUnit.metadata" relation */
  metadata: MetadataUnitNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUnitWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUnit.archive" relation */
  archive?: InputMaybe<MetadataUnitNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUnit.metadata" relation */
  metadata?: InputMaybe<MetadataUnitNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUpdateInput = {
  label?: InputMaybe<Scalars['String']['input']>;
  legacyName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Metadata.metadataAccessRightsList" relation: "MetadataAccessRights.metadata"'s inverse relation */
  metadataAccessRightsList?: InputMaybe<MetadataNestedMetadataAccessRightsListUpdateInput>;
  /** Actions for the "Metadata.metadataAddlModelInfoList" relation: "MetadataAddlModelInfo.metadata"'s inverse relation */
  metadataAddlModelInfoList?: InputMaybe<MetadataNestedMetadataAddlModelInfoListUpdateInput>;
  /** Actions for the "Metadata.metadataArchiveChildList" relation: "MetadataArchiveChild.metadata"'s inverse relation */
  metadataArchiveChildList?: InputMaybe<MetadataNestedMetadataArchiveChildListUpdateInput>;
  /** Actions for the "Metadata.metadataArchiveParentList" relation: "MetadataArchiveParent.metadata"'s inverse relation */
  metadataArchiveParentList?: InputMaybe<MetadataNestedMetadataArchiveParentListUpdateInput>;
  /** Actions for the "Metadata.metadataAssetStateList" relation: "MetadataAssetState.metadata"'s inverse relation */
  metadataAssetStateList?: InputMaybe<MetadataNestedMetadataAssetStateListUpdateInput>;
  /** Actions for the "Metadata.metadataAttributionNameList" relation: "MetadataAttributionName.metadata"'s inverse relation */
  metadataAttributionNameList?: InputMaybe<MetadataNestedMetadataAttributionNameListUpdateInput>;
  /** Actions for the "Metadata.metadataAttributionUrlList" relation: "MetadataAttributionUrl.metadata"'s inverse relation */
  metadataAttributionUrlList?: InputMaybe<MetadataNestedMetadataAttributionUrlListUpdateInput>;
  /** Actions for the "Metadata.metadataAudienceList" relation: "MetadataAudience.metadata"'s inverse relation */
  metadataAudienceList?: InputMaybe<MetadataNestedMetadataAudienceListUpdateInput>;
  /** Actions for the "Metadata.metadataAuthorsPositionList" relation: "MetadataAuthorsPosition.metadata"'s inverse relation */
  metadataAuthorsPositionList?: InputMaybe<MetadataNestedMetadataAuthorsPositionListUpdateInput>;
  /** Actions for the "Metadata.metadataBackupNameList" relation: "MetadataBackupName.metadata"'s inverse relation */
  metadataBackupNameList?: InputMaybe<MetadataNestedMetadataBackupNameListUpdateInput>;
  /** Actions for the "Metadata.metadataBitmapGrayscalePictureList" relation: "MetadataBitmapGrayscalePicture.metadata"'s inverse relation */
  metadataBitmapGrayscalePictureList?: InputMaybe<MetadataNestedMetadataBitmapGrayscalePictureListUpdateInput>;
  /** Actions for the "Metadata.metadataBrandList" relation: "MetadataBrand.metadata"'s inverse relation */
  metadataBrandList?: InputMaybe<MetadataNestedMetadataBrandListUpdateInput>;
  /** Actions for the "Metadata.metadataBrandPrismaList" relation: "MetadataBrandPrisma.metadata"'s inverse relation */
  metadataBrandPrismaList?: InputMaybe<MetadataNestedMetadataBrandPrismaListUpdateInput>;
  /** Actions for the "Metadata.metadataBrandShortNameList" relation: "MetadataBrandShortName.metadata"'s inverse relation */
  metadataBrandShortNameList?: InputMaybe<MetadataNestedMetadataBrandShortNameListUpdateInput>;
  /** Actions for the "Metadata.metadataCaptionWriterList" relation: "MetadataCaptionWriter.metadata"'s inverse relation */
  metadataCaptionWriterList?: InputMaybe<MetadataNestedMetadataCaptionWriterListUpdateInput>;
  /** Actions for the "Metadata.metadataCategoryList" relation: "MetadataCategory.metadata"'s inverse relation */
  metadataCategoryList?: InputMaybe<MetadataNestedMetadataCategoryListUpdateInput>;
  /** Actions for the "Metadata.metadataCategoryPrismaList" relation: "MetadataCategoryPrisma.metadata"'s inverse relation */
  metadataCategoryPrismaList?: InputMaybe<MetadataNestedMetadataCategoryPrismaListUpdateInput>;
  /** Actions for the "Metadata.metadataCertificateList" relation: "MetadataCertificate.metadata"'s inverse relation */
  metadataCertificateList?: InputMaybe<MetadataNestedMetadataCertificateListUpdateInput>;
  /** Actions for the "Metadata.metadataCiAdrCityList" relation: "MetadataCiAdrCity.metadata"'s inverse relation */
  metadataCiAdrCityList?: InputMaybe<MetadataNestedMetadataCiAdrCityListUpdateInput>;
  /** Actions for the "Metadata.metadataCiAdrCtryList" relation: "MetadataCiAdrCtry.metadata"'s inverse relation */
  metadataCiAdrCtryList?: InputMaybe<MetadataNestedMetadataCiAdrCtryListUpdateInput>;
  /** Actions for the "Metadata.metadataCiAdrExtadrList" relation: "MetadataCiAdrExtadr.metadata"'s inverse relation */
  metadataCiAdrExtadrList?: InputMaybe<MetadataNestedMetadataCiAdrExtadrListUpdateInput>;
  /** Actions for the "Metadata.metadataCiAdrPcodeList" relation: "MetadataCiAdrPcode.metadata"'s inverse relation */
  metadataCiAdrPcodeList?: InputMaybe<MetadataNestedMetadataCiAdrPcodeListUpdateInput>;
  /** Actions for the "Metadata.metadataCiAdrRegionList" relation: "MetadataCiAdrRegion.metadata"'s inverse relation */
  metadataCiAdrRegionList?: InputMaybe<MetadataNestedMetadataCiAdrRegionListUpdateInput>;
  /** Actions for the "Metadata.metadataCiEmailWorkList" relation: "MetadataCiEmailWork.metadata"'s inverse relation */
  metadataCiEmailWorkList?: InputMaybe<MetadataNestedMetadataCiEmailWorkListUpdateInput>;
  /** Actions for the "Metadata.metadataCiTelWorkList" relation: "MetadataCiTelWork.metadata"'s inverse relation */
  metadataCiTelWorkList?: InputMaybe<MetadataNestedMetadataCiTelWorkListUpdateInput>;
  /** Actions for the "Metadata.metadataCityList" relation: "MetadataCity.metadata"'s inverse relation */
  metadataCityList?: InputMaybe<MetadataNestedMetadataCityListUpdateInput>;
  /** Actions for the "Metadata.metadataCiUrlWorkList" relation: "MetadataCiUrlWork.metadata"'s inverse relation */
  metadataCiUrlWorkList?: InputMaybe<MetadataNestedMetadataCiUrlWorkListUpdateInput>;
  /** Actions for the "Metadata.metadataClassifyList" relation: "MetadataClassify.metadata"'s inverse relation */
  metadataClassifyList?: InputMaybe<MetadataNestedMetadataClassifyListUpdateInput>;
  /** Actions for the "Metadata.metadataCompanyList" relation: "MetadataCompany.metadata"'s inverse relation */
  metadataCompanyList?: InputMaybe<MetadataNestedMetadataCompanyListUpdateInput>;
  /** Actions for the "Metadata.metadataCompanyShortNameList" relation: "MetadataCompanyShortName.metadata"'s inverse relation */
  metadataCompanyShortNameList?: InputMaybe<MetadataNestedMetadataCompanyShortNameListUpdateInput>;
  /** Actions for the "Metadata.metadataConfidentialityList" relation: "MetadataConfidentiality.metadata"'s inverse relation */
  metadataConfidentialityList?: InputMaybe<MetadataNestedMetadataConfidentialityListUpdateInput>;
  /** Actions for the "Metadata.metadataContainedInList" relation: "MetadataContainedIn.metadata"'s inverse relation */
  metadataContainedInList?: InputMaybe<MetadataNestedMetadataContainedInListUpdateInput>;
  /** Actions for the "Metadata.metadataContentMediaKindList" relation: "MetadataContentMediaKind.metadata"'s inverse relation */
  metadataContentMediaKindList?: InputMaybe<MetadataNestedMetadataContentMediaKindListUpdateInput>;
  /** Actions for the "Metadata.metadataContentValueList" relation: "MetadataContentValue.metadata"'s inverse relation */
  metadataContentValueList?: InputMaybe<MetadataNestedMetadataContentValueListUpdateInput>;
  /** Actions for the "Metadata.metadataCopyrightLayerList" relation: "MetadataCopyrightLayer.metadata"'s inverse relation */
  metadataCopyrightLayerList?: InputMaybe<MetadataNestedMetadataCopyrightLayerListUpdateInput>;
  /** Actions for the "Metadata.metadataCountryCodeList" relation: "MetadataCountryCode.metadata"'s inverse relation */
  metadataCountryCodeList?: InputMaybe<MetadataNestedMetadataCountryCodeListUpdateInput>;
  /** Actions for the "Metadata.metadataCountryList" relation: "MetadataCountry.metadata"'s inverse relation */
  metadataCountryList?: InputMaybe<MetadataNestedMetadataCountryListUpdateInput>;
  /** Actions for the "Metadata.metadataCreatedTimeList" relation: "MetadataCreatedTime.metadata"'s inverse relation */
  metadataCreatedTimeList?: InputMaybe<MetadataNestedMetadataCreatedTimeListUpdateInput>;
  /** Actions for the "Metadata.metadataCreatorList" relation: "MetadataCreator.metadata"'s inverse relation */
  metadataCreatorList?: InputMaybe<MetadataNestedMetadataCreatorListUpdateInput>;
  /** Actions for the "Metadata.metadataCreatorToolList" relation: "MetadataCreatorTool.metadata"'s inverse relation */
  metadataCreatorToolList?: InputMaybe<MetadataNestedMetadataCreatorToolListUpdateInput>;
  /** Actions for the "Metadata.metadataCreditList" relation: "MetadataCredit.metadata"'s inverse relation */
  metadataCreditList?: InputMaybe<MetadataNestedMetadataCreditListUpdateInput>;
  /** Actions for the "Metadata.metadataCustomField14List" relation: "MetadataCustomField14.metadata"'s inverse relation */
  metadataCustomField14List?: InputMaybe<MetadataNestedMetadataCustomField14ListUpdateInput>;
  /** Actions for the "Metadata.metadataCustomField15List" relation: "MetadataCustomField15.metadata"'s inverse relation */
  metadataCustomField15List?: InputMaybe<MetadataNestedMetadataCustomField15ListUpdateInput>;
  /** Actions for the "Metadata.metadataCustomField16List" relation: "MetadataCustomField16.metadata"'s inverse relation */
  metadataCustomField16List?: InputMaybe<MetadataNestedMetadataCustomField16ListUpdateInput>;
  /** Actions for the "Metadata.metadataCustomField17List" relation: "MetadataCustomField17.metadata"'s inverse relation */
  metadataCustomField17List?: InputMaybe<MetadataNestedMetadataCustomField17ListUpdateInput>;
  /** Actions for the "Metadata.metadataCustomField20List" relation: "MetadataCustomField20.metadata"'s inverse relation */
  metadataCustomField20List?: InputMaybe<MetadataNestedMetadataCustomField20ListUpdateInput>;
  /** Actions for the "Metadata.metadataCustomField5List" relation: "MetadataCustomField5.metadata"'s inverse relation */
  metadataCustomField5List?: InputMaybe<MetadataNestedMetadataCustomField5ListUpdateInput>;
  /** Actions for the "Metadata.metadataCustomField6List" relation: "MetadataCustomField6.metadata"'s inverse relation */
  metadataCustomField6List?: InputMaybe<MetadataNestedMetadataCustomField6ListUpdateInput>;
  /** Actions for the "Metadata.metadataCustomField7List" relation: "MetadataCustomField7.metadata"'s inverse relation */
  metadataCustomField7List?: InputMaybe<MetadataNestedMetadataCustomField7ListUpdateInput>;
  /** Actions for the "Metadata.metadataCustomField9List" relation: "MetadataCustomField9.metadata"'s inverse relation */
  metadataCustomField9List?: InputMaybe<MetadataNestedMetadataCustomField9ListUpdateInput>;
  /** Actions for the "Metadata.metadataDateCreatedList" relation: "MetadataDateCreated.metadata"'s inverse relation */
  metadataDateCreatedList?: InputMaybe<MetadataNestedMetadataDateCreatedListUpdateInput>;
  /** Actions for the "Metadata.metadataDateTimeDigitizedList" relation: "MetadataDateTimeDigitized.metadata"'s inverse relation */
  metadataDateTimeDigitizedList?: InputMaybe<MetadataNestedMetadataDateTimeDigitizedListUpdateInput>;
  /** Actions for the "Metadata.metadataDateTimeOriginalList" relation: "MetadataDateTimeOriginal.metadata"'s inverse relation */
  metadataDateTimeOriginalList?: InputMaybe<MetadataNestedMetadataDateTimeOriginalListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryAccountList" relation: "MetadataDeliveryAccount.metadata"'s inverse relation */
  metadataDeliveryAccountList?: InputMaybe<MetadataNestedMetadataDeliveryAccountListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryCompanyList" relation: "MetadataDeliveryCompany.metadata"'s inverse relation */
  metadataDeliveryCompanyList?: InputMaybe<MetadataNestedMetadataDeliveryCompanyListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryCopyrightList" relation: "MetadataDeliveryCopyright.metadata"'s inverse relation */
  metadataDeliveryCopyrightList?: InputMaybe<MetadataNestedMetadataDeliveryCopyrightListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryDateFolderList" relation: "MetadataDeliveryDateFolder.metadata"'s inverse relation */
  metadataDeliveryDateFolderList?: InputMaybe<MetadataNestedMetadataDeliveryDateFolderListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryDateTimeList" relation: "MetadataDeliveryDateTime.metadata"'s inverse relation */
  metadataDeliveryDateTimeList?: InputMaybe<MetadataNestedMetadataDeliveryDateTimeListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryDcCreatorList" relation: "MetadataDeliveryDcCreator.metadata"'s inverse relation */
  metadataDeliveryDcCreatorList?: InputMaybe<MetadataNestedMetadataDeliveryDcCreatorListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryDcRightsList" relation: "MetadataDeliveryDcRights.metadata"'s inverse relation */
  metadataDeliveryDcRightsList?: InputMaybe<MetadataNestedMetadataDeliveryDcRightsListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryFileTypeList" relation: "MetadataDeliveryFileType.metadata"'s inverse relation */
  metadataDeliveryFileTypeList?: InputMaybe<MetadataNestedMetadataDeliveryFileTypeListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryFolderList" relation: "MetadataDeliveryFolder.metadata"'s inverse relation */
  metadataDeliveryFolderList?: InputMaybe<MetadataNestedMetadataDeliveryFolderListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryKindList" relation: "MetadataDeliveryKind.metadata"'s inverse relation */
  metadataDeliveryKindList?: InputMaybe<MetadataNestedMetadataDeliveryKindListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryPathList" relation: "MetadataDeliveryPath.metadata"'s inverse relation */
  metadataDeliveryPathList?: InputMaybe<MetadataNestedMetadataDeliveryPathListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryPersonShownintheImageList" relation: "MetadataDeliveryPersonShownintheImage.metadata"'s inverse relation */
  metadataDeliveryPersonShownintheImageList?: InputMaybe<MetadataNestedMetadataDeliveryPersonShownintheImageListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryPhotoshopCreditList" relation: "MetadataDeliveryPhotoshopCredit.metadata"'s inverse relation */
  metadataDeliveryPhotoshopCreditList?: InputMaybe<MetadataNestedMetadataDeliveryPhotoshopCreditListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryPhotoshopSourceList" relation: "MetadataDeliveryPhotoshopSource.metadata"'s inverse relation */
  metadataDeliveryPhotoshopSourceList?: InputMaybe<MetadataNestedMetadataDeliveryPhotoshopSourceListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryServiceList" relation: "MetadataDeliveryService.metadata"'s inverse relation */
  metadataDeliveryServiceList?: InputMaybe<MetadataNestedMetadataDeliveryServiceListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliverySubjectList" relation: "MetadataDeliverySubject.metadata"'s inverse relation */
  metadataDeliverySubjectList?: InputMaybe<MetadataNestedMetadataDeliverySubjectListUpdateInput>;
  /** Actions for the "Metadata.metadataDeliveryUnderSubjectList" relation: "MetadataDeliveryUnderSubject.metadata"'s inverse relation */
  metadataDeliveryUnderSubjectList?: InputMaybe<MetadataNestedMetadataDeliveryUnderSubjectListUpdateInput>;
  /** Actions for the "Metadata.metadataDepartmentList" relation: "MetadataDepartment.metadata"'s inverse relation */
  metadataDepartmentList?: InputMaybe<MetadataNestedMetadataDepartmentListUpdateInput>;
  /** Actions for the "Metadata.metadataDescriptionList" relation: "MetadataDescription.metadata"'s inverse relation */
  metadataDescriptionList?: InputMaybe<MetadataNestedMetadataDescriptionListUpdateInput>;
  /** Actions for the "Metadata.metadataDigitalAssetUrlList" relation: "MetadataDigitalAssetUrl.metadata"'s inverse relation */
  metadataDigitalAssetUrlList?: InputMaybe<MetadataNestedMetadataDigitalAssetUrlListUpdateInput>;
  /** Actions for the "Metadata.metadataDivisionList" relation: "MetadataDivision.metadata"'s inverse relation */
  metadataDivisionList?: InputMaybe<MetadataNestedMetadataDivisionListUpdateInput>;
  /** Actions for the "Metadata.metadataDocumentTextList" relation: "MetadataDocumentText.metadata"'s inverse relation */
  metadataDocumentTextList?: InputMaybe<MetadataNestedMetadataDocumentTextListUpdateInput>;
  /** Actions for the "Metadata.metadataEditionList" relation: "MetadataEdition.metadata"'s inverse relation */
  metadataEditionList?: InputMaybe<MetadataNestedMetadataEditionListUpdateInput>;
  /** Actions for the "Metadata.metadataEditorialVersionList" relation: "MetadataEditorialVersion.metadata"'s inverse relation */
  metadataEditorialVersionList?: InputMaybe<MetadataNestedMetadataEditorialVersionListUpdateInput>;
  /** Actions for the "Metadata.metadataEditStatusList" relation: "MetadataEditStatus.metadata"'s inverse relation */
  metadataEditStatusList?: InputMaybe<MetadataNestedMetadataEditStatusListUpdateInput>;
  /** Actions for the "Metadata.metadataEnvironnementPhotoList" relation: "MetadataEnvironnementPhoto.metadata"'s inverse relation */
  metadataEnvironnementPhotoList?: InputMaybe<MetadataNestedMetadataEnvironnementPhotoListUpdateInput>;
  /** Actions for the "Metadata.metadataEquipmentInstitutionList" relation: "MetadataEquipmentInstitution.metadata"'s inverse relation */
  metadataEquipmentInstitutionList?: InputMaybe<MetadataNestedMetadataEquipmentInstitutionListUpdateInput>;
  /** Actions for the "Metadata.metadataEquipmentManufacturerList" relation: "MetadataEquipmentManufacturer.metadata"'s inverse relation */
  metadataEquipmentManufacturerList?: InputMaybe<MetadataNestedMetadataEquipmentManufacturerListUpdateInput>;
  /** Actions for the "Metadata.metadataEventList" relation: "MetadataEvent.metadata"'s inverse relation */
  metadataEventList?: InputMaybe<MetadataNestedMetadataEventListUpdateInput>;
  /** Actions for the "Metadata.metadataExifList" relation: "MetadataExif.metadata"'s inverse relation */
  metadataExifList?: InputMaybe<MetadataNestedMetadataExifListUpdateInput>;
  /** Actions for the "Metadata.metadataFabStorageList" relation: "MetadataFabStorage.metadata"'s inverse relation */
  metadataFabStorageList?: InputMaybe<MetadataNestedMetadataFabStorageListUpdateInput>;
  /** Actions for the "Metadata.metadataFileCheckForList" relation: "MetadataFileCheckFor.metadata"'s inverse relation */
  metadataFileCheckForList?: InputMaybe<MetadataNestedMetadataFileCheckForListUpdateInput>;
  /** Actions for the "Metadata.metadataFileInfoBitsPerPixelList" relation: "MetadataFileInfoBitsPerPixel.metadata"'s inverse relation */
  metadataFileInfoBitsPerPixelList?: InputMaybe<MetadataNestedMetadataFileInfoBitsPerPixelListUpdateInput>;
  /** Actions for the "Metadata.metadataFileInfoFramesList" relation: "MetadataFileInfoFrames.metadata"'s inverse relation */
  metadataFileInfoFramesList?: InputMaybe<MetadataNestedMetadataFileInfoFramesListUpdateInput>;
  /** Actions for the "Metadata.metadataFileInfoPhotometricInterpretationList" relation: "MetadataFileInfoPhotometricInterpretation.metadata"'s inverse relation */
  metadataFileInfoPhotometricInterpretationList?: InputMaybe<MetadataNestedMetadataFileInfoPhotometricInterpretationListUpdateInput>;
  /** Actions for the "Metadata.metadataFileInfoTransferSyntaxList" relation: "MetadataFileInfoTransferSyntax.metadata"'s inverse relation */
  metadataFileInfoTransferSyntaxList?: InputMaybe<MetadataNestedMetadataFileInfoTransferSyntaxListUpdateInput>;
  /** Actions for the "Metadata.metadataFileStageList" relation: "MetadataFileStage.metadata"'s inverse relation */
  metadataFileStageList?: InputMaybe<MetadataNestedMetadataFileStageListUpdateInput>;
  /** Actions for the "Metadata.metadataFileStatusList" relation: "MetadataFileStatus.metadata"'s inverse relation */
  metadataFileStatusList?: InputMaybe<MetadataNestedMetadataFileStatusListUpdateInput>;
  /** Actions for the "Metadata.metadataFileTypeList" relation: "MetadataFileType.metadata"'s inverse relation */
  metadataFileTypeList?: InputMaybe<MetadataNestedMetadataFileTypeListUpdateInput>;
  /** Actions for the "Metadata.metadataFixtureIdentifierList" relation: "MetadataFixtureIdentifier.metadata"'s inverse relation */
  metadataFixtureIdentifierList?: InputMaybe<MetadataNestedMetadataFixtureIdentifierListUpdateInput>;
  /** Actions for the "Metadata.metadataFormatList" relation: "MetadataFormat.metadata"'s inverse relation */
  metadataFormatList?: InputMaybe<MetadataNestedMetadataFormatListUpdateInput>;
  /** Actions for the "Metadata.metadataGpsLatitudeList" relation: "MetadataGpsLatitude.metadata"'s inverse relation */
  metadataGpsLatitudeList?: InputMaybe<MetadataNestedMetadataGpsLatitudeListUpdateInput>;
  /** Actions for the "Metadata.metadataGpsLongitudeList" relation: "MetadataGpsLongitude.metadata"'s inverse relation */
  metadataGpsLongitudeList?: InputMaybe<MetadataNestedMetadataGpsLongitudeListUpdateInput>;
  /** Actions for the "Metadata.metadataGpsProcessingMethodList" relation: "MetadataGpsProcessingMethod.metadata"'s inverse relation */
  metadataGpsProcessingMethodList?: InputMaybe<MetadataNestedMetadataGpsProcessingMethodListUpdateInput>;
  /** Actions for the "Metadata.metadataHeaderList" relation: "MetadataHeader.metadata"'s inverse relation */
  metadataHeaderList?: InputMaybe<MetadataNestedMetadataHeaderListUpdateInput>;
  /** Actions for the "Metadata.metadataHeaderPrismaList" relation: "MetadataHeaderPrisma.metadata"'s inverse relation */
  metadataHeaderPrismaList?: InputMaybe<MetadataNestedMetadataHeaderPrismaListUpdateInput>;
  /** Actions for the "Metadata.metadataHeadlineList" relation: "MetadataHeadline.metadata"'s inverse relation */
  metadataHeadlineList?: InputMaybe<MetadataNestedMetadataHeadlineListUpdateInput>;
  /** Actions for the "Metadata.metadataHistoryList" relation: "MetadataHistory.metadata"'s inverse relation */
  metadataHistoryList?: InputMaybe<MetadataNestedMetadataHistoryListUpdateInput>;
  /** Actions for the "Metadata.metadataIccProfileList" relation: "MetadataIccProfile.metadata"'s inverse relation */
  metadataIccProfileList?: InputMaybe<MetadataNestedMetadataIccProfileListUpdateInput>;
  /** Actions for the "Metadata.metadataIdAssignmentList" relation: "MetadataIdAssignment.metadata"'s inverse relation */
  metadataIdAssignmentList?: InputMaybe<MetadataNestedMetadataIdAssignmentListUpdateInput>;
  /** Actions for the "Metadata.metadataIdMediaContentList" relation: "MetadataIdMediaContent.metadata"'s inverse relation */
  metadataIdMediaContentList?: InputMaybe<MetadataNestedMetadataIdMediaContentListUpdateInput>;
  /** Actions for the "Metadata.metadataIdPhotoPrismaList" relation: "MetadataIdPhotoPrisma.metadata"'s inverse relation */
  metadataIdPhotoPrismaList?: InputMaybe<MetadataNestedMetadataIdPhotoPrismaListUpdateInput>;
  /** Actions for the "Metadata.metadataIdSubjectList" relation: "MetadataIdSubject.metadata"'s inverse relation */
  metadataIdSubjectList?: InputMaybe<MetadataNestedMetadataIdSubjectListUpdateInput>;
  /** Actions for the "Metadata.metadataImageNotesList" relation: "MetadataImageNotes.metadata"'s inverse relation */
  metadataImageNotesList?: InputMaybe<MetadataNestedMetadataImageNotesListUpdateInput>;
  /** Actions for the "Metadata.metadataImageUniqueIdList" relation: "MetadataImageUniqueId.metadata"'s inverse relation */
  metadataImageUniqueIdList?: InputMaybe<MetadataNestedMetadataImageUniqueIdListUpdateInput>;
  /** Actions for the "Metadata.metadataInstructionsList" relation: "MetadataInstructions.metadata"'s inverse relation */
  metadataInstructionsList?: InputMaybe<MetadataNestedMetadataInstructionsListUpdateInput>;
  /** Actions for the "Metadata.metadataIntellectualGenreList" relation: "MetadataIntellectualGenre.metadata"'s inverse relation */
  metadataIntellectualGenreList?: InputMaybe<MetadataNestedMetadataIntellectualGenreListUpdateInput>;
  /** Actions for the "Metadata.metadataIsoSpeedRatingsList" relation: "MetadataIsoSpeedRatings.metadata"'s inverse relation */
  metadataIsoSpeedRatingsList?: InputMaybe<MetadataNestedMetadataIsoSpeedRatingsListUpdateInput>;
  /** Actions for the "Metadata.metadataIssueList" relation: "MetadataIssue.metadata"'s inverse relation */
  metadataIssueList?: InputMaybe<MetadataNestedMetadataIssueListUpdateInput>;
  /** Actions for the "Metadata.metadataJobIdList" relation: "MetadataJobId.metadata"'s inverse relation */
  metadataJobIdList?: InputMaybe<MetadataNestedMetadataJobIdListUpdateInput>;
  /** Actions for the "Metadata.metadataJobProcessingList" relation: "MetadataJobProcessing.metadata"'s inverse relation */
  metadataJobProcessingList?: InputMaybe<MetadataNestedMetadataJobProcessingListUpdateInput>;
  /** Actions for the "Metadata.metadataLayoutPictureEditingList" relation: "MetadataLayoutPictureEditing.metadata"'s inverse relation */
  metadataLayoutPictureEditingList?: InputMaybe<MetadataNestedMetadataLayoutPictureEditingListUpdateInput>;
  /** Actions for the "Metadata.metadataLayoutStorageList" relation: "MetadataLayoutStorage.metadata"'s inverse relation */
  metadataLayoutStorageList?: InputMaybe<MetadataNestedMetadataLayoutStorageListUpdateInput>;
  /** Actions for the "Metadata.metadataLicenseList" relation: "MetadataLicense.metadata"'s inverse relation */
  metadataLicenseList?: InputMaybe<MetadataNestedMetadataLicenseListUpdateInput>;
  /** Actions for the "Metadata.metadataLinksList" relation: "MetadataLinks.metadata"'s inverse relation */
  metadataLinksList?: InputMaybe<MetadataNestedMetadataLinksListUpdateInput>;
  /** Actions for the "Metadata.metadataLocalCaptionList" relation: "MetadataLocalCaption.metadata"'s inverse relation */
  metadataLocalCaptionList?: InputMaybe<MetadataNestedMetadataLocalCaptionListUpdateInput>;
  /** Actions for the "Metadata.metadataLocationList" relation: "MetadataLocation.metadata"'s inverse relation */
  metadataLocationList?: InputMaybe<MetadataNestedMetadataLocationListUpdateInput>;
  /** Actions for the "Metadata.metadataMakeList" relation: "MetadataMake.metadata"'s inverse relation */
  metadataMakeList?: InputMaybe<MetadataNestedMetadataMakeListUpdateInput>;
  /** Actions for the "Metadata.metadataManifestList" relation: "MetadataManifest.metadata"'s inverse relation */
  metadataManifestList?: InputMaybe<MetadataNestedMetadataManifestListUpdateInput>;
  /** Actions for the "Metadata.metadataMarkedList" relation: "MetadataMarked.metadata"'s inverse relation */
  metadataMarkedList?: InputMaybe<MetadataNestedMetadataMarkedListUpdateInput>;
  /** Actions for the "Metadata.metadataMasterDocumentIdList" relation: "MetadataMasterDocumentId.metadata"'s inverse relation */
  metadataMasterDocumentIdList?: InputMaybe<MetadataNestedMetadataMasterDocumentIdListUpdateInput>;
  /** Actions for the "Metadata.metadataMaxAvailHeightList" relation: "MetadataMaxAvailHeight.metadata"'s inverse relation */
  metadataMaxAvailHeightList?: InputMaybe<MetadataNestedMetadataMaxAvailHeightListUpdateInput>;
  /** Actions for the "Metadata.metadataMaxAvailWidthList" relation: "MetadataMaxAvailWidth.metadata"'s inverse relation */
  metadataMaxAvailWidthList?: InputMaybe<MetadataNestedMetadataMaxAvailWidthListUpdateInput>;
  /** Actions for the "Metadata.metadataMinorModelAgeDisclosureList" relation: "MetadataMinorModelAgeDisclosure.metadata"'s inverse relation */
  metadataMinorModelAgeDisclosureList?: InputMaybe<MetadataNestedMetadataMinorModelAgeDisclosureListUpdateInput>;
  /** Actions for the "Metadata.metadataModelAgeList" relation: "MetadataModelAge.metadata"'s inverse relation */
  metadataModelAgeList?: InputMaybe<MetadataNestedMetadataModelAgeListUpdateInput>;
  /** Actions for the "Metadata.metadataModelList" relation: "MetadataModel.metadata"'s inverse relation */
  metadataModelList?: InputMaybe<MetadataNestedMetadataModelListUpdateInput>;
  /** Actions for the "Metadata.metadataModelReleaseIdList" relation: "MetadataModelReleaseId.metadata"'s inverse relation */
  metadataModelReleaseIdList?: InputMaybe<MetadataNestedMetadataModelReleaseIdListUpdateInput>;
  /** Actions for the "Metadata.metadataModelReleaseStatusList" relation: "MetadataModelReleaseStatus.metadata"'s inverse relation */
  metadataModelReleaseStatusList?: InputMaybe<MetadataNestedMetadataModelReleaseStatusListUpdateInput>;
  /** Actions for the "Metadata.metadataMorePermissionsList" relation: "MetadataMorePermissions.metadata"'s inverse relation */
  metadataMorePermissionsList?: InputMaybe<MetadataNestedMetadataMorePermissionsListUpdateInput>;
  /** Actions for the "Metadata.metadataObjectCycleList" relation: "MetadataObjectCycle.metadata"'s inverse relation */
  metadataObjectCycleList?: InputMaybe<MetadataNestedMetadataObjectCycleListUpdateInput>;
  /** Actions for the "Metadata.metadataOrganisationInImageNameList" relation: "MetadataOrganisationInImageName.metadata"'s inverse relation */
  metadataOrganisationInImageNameList?: InputMaybe<MetadataNestedMetadataOrganisationInImageNameListUpdateInput>;
  /** Actions for the "Metadata.metadataOriginalColorList" relation: "MetadataOriginalColor.metadata"'s inverse relation */
  metadataOriginalColorList?: InputMaybe<MetadataNestedMetadataOriginalColorListUpdateInput>;
  /** Actions for the "Metadata.metadataOriginalIccProfileList" relation: "MetadataOriginalIccProfile.metadata"'s inverse relation */
  metadataOriginalIccProfileList?: InputMaybe<MetadataNestedMetadataOriginalIccProfileListUpdateInput>;
  /** Actions for the "Metadata.metadataOriginalNameList" relation: "MetadataOriginalName.metadata"'s inverse relation */
  metadataOriginalNameList?: InputMaybe<MetadataNestedMetadataOriginalNameListUpdateInput>;
  /** Actions for the "Metadata.metadataOriginalWeigthList" relation: "MetadataOriginalWeigth.metadata"'s inverse relation */
  metadataOriginalWeigthList?: InputMaybe<MetadataNestedMetadataOriginalWeigthListUpdateInput>;
  /** Actions for the "Metadata.metadataOwnerIdList" relation: "MetadataOwnerId.metadata"'s inverse relation */
  metadataOwnerIdList?: InputMaybe<MetadataNestedMetadataOwnerIdListUpdateInput>;
  /** Actions for the "Metadata.metadataOwnerList" relation: "MetadataOwner.metadata"'s inverse relation */
  metadataOwnerList?: InputMaybe<MetadataNestedMetadataOwnerListUpdateInput>;
  /** Actions for the "Metadata.metadataPageList" relation: "MetadataPage.metadata"'s inverse relation */
  metadataPageList?: InputMaybe<MetadataNestedMetadataPageListUpdateInput>;
  /** Actions for the "Metadata.metadataPagePrismaList" relation: "MetadataPagePrisma.metadata"'s inverse relation */
  metadataPagePrismaList?: InputMaybe<MetadataNestedMetadataPagePrismaListUpdateInput>;
  /** Actions for the "Metadata.metadataPaintBasedCorrectionsList" relation: "MetadataPaintBasedCorrections.metadata"'s inverse relation */
  metadataPaintBasedCorrectionsList?: InputMaybe<MetadataNestedMetadataPaintBasedCorrectionsListUpdateInput>;
  /** Actions for the "Metadata.metadataPatientDobList" relation: "MetadataPatientDob.metadata"'s inverse relation */
  metadataPatientDobList?: InputMaybe<MetadataNestedMetadataPatientDobListUpdateInput>;
  /** Actions for the "Metadata.metadataPatientIdList" relation: "MetadataPatientId.metadata"'s inverse relation */
  metadataPatientIdList?: InputMaybe<MetadataNestedMetadataPatientIdListUpdateInput>;
  /** Actions for the "Metadata.metadataPatientNameList" relation: "MetadataPatientName.metadata"'s inverse relation */
  metadataPatientNameList?: InputMaybe<MetadataNestedMetadataPatientNameListUpdateInput>;
  /** Actions for the "Metadata.metadataPatientSexList" relation: "MetadataPatientSex.metadata"'s inverse relation */
  metadataPatientSexList?: InputMaybe<MetadataNestedMetadataPatientSexListUpdateInput>;
  /** Actions for the "Metadata.metadataPersonInImageList" relation: "MetadataPersonInImage.metadata"'s inverse relation */
  metadataPersonInImageList?: InputMaybe<MetadataNestedMetadataPersonInImageListUpdateInput>;
  /** Actions for the "Metadata.metadataPhotoStorageList" relation: "MetadataPhotoStorage.metadata"'s inverse relation */
  metadataPhotoStorageList?: InputMaybe<MetadataNestedMetadataPhotoStorageListUpdateInput>;
  /** Actions for the "Metadata.metadataPrepressPictureEditingList" relation: "MetadataPrepressPictureEditing.metadata"'s inverse relation */
  metadataPrepressPictureEditingList?: InputMaybe<MetadataNestedMetadataPrepressPictureEditingListUpdateInput>;
  /** Actions for the "Metadata.metadataPriceLevelList" relation: "MetadataPriceLevel.metadata"'s inverse relation */
  metadataPriceLevelList?: InputMaybe<MetadataNestedMetadataPriceLevelListUpdateInput>;
  /** Actions for the "Metadata.metadataPrintingProfileList" relation: "MetadataPrintingProfile.metadata"'s inverse relation */
  metadataPrintingProfileList?: InputMaybe<MetadataNestedMetadataPrintingProfileListUpdateInput>;
  /** Actions for the "Metadata.metadataPrismaProductionList" relation: "MetadataPrismaProduction.metadata"'s inverse relation */
  metadataPrismaProductionList?: InputMaybe<MetadataNestedMetadataPrismaProductionListUpdateInput>;
  /** Actions for the "Metadata.metadataProcessHistoryList" relation: "MetadataProcessHistory.metadata"'s inverse relation */
  metadataProcessHistoryList?: InputMaybe<MetadataNestedMetadataProcessHistoryListUpdateInput>;
  /** Actions for the "Metadata.metadataProcessParameterList" relation: "MetadataProcessParameter.metadata"'s inverse relation */
  metadataProcessParameterList?: InputMaybe<MetadataNestedMetadataProcessParameterListUpdateInput>;
  /** Actions for the "Metadata.metadataProcessStatusList" relation: "MetadataProcessStatus.metadata"'s inverse relation */
  metadataProcessStatusList?: InputMaybe<MetadataNestedMetadataProcessStatusListUpdateInput>;
  /** Actions for the "Metadata.metadataProductList" relation: "MetadataProduct.metadata"'s inverse relation */
  metadataProductList?: InputMaybe<MetadataNestedMetadataProductListUpdateInput>;
  /** Actions for the "Metadata.metadataProductShortNameList" relation: "MetadataProductShortName.metadata"'s inverse relation */
  metadataProductShortNameList?: InputMaybe<MetadataNestedMetadataProductShortNameListUpdateInput>;
  /** Actions for the "Metadata.metadataProductsList" relation: "MetadataProducts.metadata"'s inverse relation */
  metadataProductsList?: InputMaybe<MetadataNestedMetadataProductsListUpdateInput>;
  /** Actions for the "Metadata.metadataProduitsList" relation: "MetadataProduits.metadata"'s inverse relation */
  metadataProduitsList?: InputMaybe<MetadataNestedMetadataProduitsListUpdateInput>;
  /** Actions for the "Metadata.metadataProgramVersionList" relation: "MetadataProgramVersion.metadata"'s inverse relation */
  metadataProgramVersionList?: InputMaybe<MetadataNestedMetadataProgramVersionListUpdateInput>;
  /** Actions for the "Metadata.metadataPropertyReleaseIdList" relation: "MetadataPropertyReleaseId.metadata"'s inverse relation */
  metadataPropertyReleaseIdList?: InputMaybe<MetadataNestedMetadataPropertyReleaseIdListUpdateInput>;
  /** Actions for the "Metadata.metadataPropertyReleaseStatusList" relation: "MetadataPropertyReleaseStatus.metadata"'s inverse relation */
  metadataPropertyReleaseStatusList?: InputMaybe<MetadataNestedMetadataPropertyReleaseStatusListUpdateInput>;
  /** Actions for the "Metadata.metadataPublisherList" relation: "MetadataPublisher.metadata"'s inverse relation */
  metadataPublisherList?: InputMaybe<MetadataNestedMetadataPublisherListUpdateInput>;
  /** Actions for the "Metadata.metadataPublishingIssueList" relation: "MetadataPublishingIssue.metadata"'s inverse relation */
  metadataPublishingIssueList?: InputMaybe<MetadataNestedMetadataPublishingIssueListUpdateInput>;
  /** Actions for the "Metadata.metadataPublishingSubjectList" relation: "MetadataPublishingSubject.metadata"'s inverse relation */
  metadataPublishingSubjectList?: InputMaybe<MetadataNestedMetadataPublishingSubjectListUpdateInput>;
  /** Actions for the "Metadata.metadataQualifiedUsePrismaByList" relation: "MetadataQualifiedUsePrismaBy.metadata"'s inverse relation */
  metadataQualifiedUsePrismaByList?: InputMaybe<MetadataNestedMetadataQualifiedUsePrismaByListUpdateInput>;
  /** Actions for the "Metadata.metadataQualifiedUsePrismaDateList" relation: "MetadataQualifiedUsePrismaDate.metadata"'s inverse relation */
  metadataQualifiedUsePrismaDateList?: InputMaybe<MetadataNestedMetadataQualifiedUsePrismaDateListUpdateInput>;
  /** Actions for the "Metadata.metadataQualifiedUsePrismaDurationList" relation: "MetadataQualifiedUsePrismaDuration.metadata"'s inverse relation */
  metadataQualifiedUsePrismaDurationList?: InputMaybe<MetadataNestedMetadataQualifiedUsePrismaDurationListUpdateInput>;
  /** Actions for the "Metadata.metadataQualifiedUsePrismaSupportList" relation: "MetadataQualifiedUsePrismaSupport.metadata"'s inverse relation */
  metadataQualifiedUsePrismaSupportList?: InputMaybe<MetadataNestedMetadataQualifiedUsePrismaSupportListUpdateInput>;
  /** Actions for the "Metadata.metadataRatingList" relation: "MetadataRating.metadata"'s inverse relation */
  metadataRatingList?: InputMaybe<MetadataNestedMetadataRatingListUpdateInput>;
  /** Actions for the "Metadata.metadataReferenceDateList" relation: "MetadataReferenceDate.metadata"'s inverse relation */
  metadataReferenceDateList?: InputMaybe<MetadataNestedMetadataReferenceDateListUpdateInput>;
  /** Actions for the "Metadata.metadataReferenceNumberList" relation: "MetadataReferenceNumber.metadata"'s inverse relation */
  metadataReferenceNumberList?: InputMaybe<MetadataNestedMetadataReferenceNumberListUpdateInput>;
  /** Actions for the "Metadata.metadataReferenceServiceList" relation: "MetadataReferenceService.metadata"'s inverse relation */
  metadataReferenceServiceList?: InputMaybe<MetadataNestedMetadataReferenceServiceListUpdateInput>;
  /** Actions for the "Metadata.metadataReleaseDateList" relation: "MetadataReleaseDate.metadata"'s inverse relation */
  metadataReleaseDateList?: InputMaybe<MetadataNestedMetadataReleaseDateListUpdateInput>;
  /** Actions for the "Metadata.metadataReleaseTimeList" relation: "MetadataReleaseTime.metadata"'s inverse relation */
  metadataReleaseTimeList?: InputMaybe<MetadataNestedMetadataReleaseTimeListUpdateInput>;
  /** Actions for the "Metadata.metadataRequiredPermissionList" relation: "MetadataRequiredPermission.metadata"'s inverse relation */
  metadataRequiredPermissionList?: InputMaybe<MetadataNestedMetadataRequiredPermissionListUpdateInput>;
  /** Actions for the "Metadata.metadataResolutionKindList" relation: "MetadataResolutionKind.metadata"'s inverse relation */
  metadataResolutionKindList?: InputMaybe<MetadataNestedMetadataResolutionKindListUpdateInput>;
  /** Actions for the "Metadata.metadataRightsList" relation: "MetadataRights.metadata"'s inverse relation */
  metadataRightsList?: InputMaybe<MetadataNestedMetadataRightsListUpdateInput>;
  /** Actions for the "Metadata.metadataRoyaltyfreeList" relation: "MetadataRoyaltyfree.metadata"'s inverse relation */
  metadataRoyaltyfreeList?: InputMaybe<MetadataNestedMetadataRoyaltyfreeListUpdateInput>;
  /** Actions for the "Metadata.metadataSceneList" relation: "MetadataScene.metadata"'s inverse relation */
  metadataSceneList?: InputMaybe<MetadataNestedMetadataSceneListUpdateInput>;
  /** Actions for the "Metadata.metadataSectionList" relation: "MetadataSection.metadata"'s inverse relation */
  metadataSectionList?: InputMaybe<MetadataNestedMetadataSectionListUpdateInput>;
  /** Actions for the "Metadata.metadataSentToList" relation: "MetadataSentTo.metadata"'s inverse relation */
  metadataSentToList?: InputMaybe<MetadataNestedMetadataSentToListUpdateInput>;
  /** Actions for the "Metadata.metadataSerialNumberList" relation: "MetadataSerialNumber.metadata"'s inverse relation */
  metadataSerialNumberList?: InputMaybe<MetadataNestedMetadataSerialNumberListUpdateInput>;
  /** Actions for the "Metadata.metadataSeriesDateTimeList" relation: "MetadataSeriesDateTime.metadata"'s inverse relation */
  metadataSeriesDateTimeList?: InputMaybe<MetadataNestedMetadataSeriesDateTimeListUpdateInput>;
  /** Actions for the "Metadata.metadataSeriesDescriptionList" relation: "MetadataSeriesDescription.metadata"'s inverse relation */
  metadataSeriesDescriptionList?: InputMaybe<MetadataNestedMetadataSeriesDescriptionListUpdateInput>;
  /** Actions for the "Metadata.metadataSeriesModalityList" relation: "MetadataSeriesModality.metadata"'s inverse relation */
  metadataSeriesModalityList?: InputMaybe<MetadataNestedMetadataSeriesModalityListUpdateInput>;
  /** Actions for the "Metadata.metadataSeriesNumberList" relation: "MetadataSeriesNumber.metadata"'s inverse relation */
  metadataSeriesNumberList?: InputMaybe<MetadataNestedMetadataSeriesNumberListUpdateInput>;
  /** Actions for the "Metadata.metadataShortUniqueIdList" relation: "MetadataShortUniqueId.metadata"'s inverse relation */
  metadataShortUniqueIdList?: InputMaybe<MetadataNestedMetadataShortUniqueIdListUpdateInput>;
  /** Actions for the "Metadata.metadataSourceList" relation: "MetadataSource.metadata"'s inverse relation */
  metadataSourceList?: InputMaybe<MetadataNestedMetadataSourceListUpdateInput>;
  /** Actions for the "Metadata.metadataStateList" relation: "MetadataState.metadata"'s inverse relation */
  metadataStateList?: InputMaybe<MetadataNestedMetadataStateListUpdateInput>;
  /** Actions for the "Metadata.metadataStudyDateTimeList" relation: "MetadataStudyDateTime.metadata"'s inverse relation */
  metadataStudyDateTimeList?: InputMaybe<MetadataNestedMetadataStudyDateTimeListUpdateInput>;
  /** Actions for the "Metadata.metadataStudyDescriptionList" relation: "MetadataStudyDescription.metadata"'s inverse relation */
  metadataStudyDescriptionList?: InputMaybe<MetadataNestedMetadataStudyDescriptionListUpdateInput>;
  /** Actions for the "Metadata.metadataStudyIdList" relation: "MetadataStudyId.metadata"'s inverse relation */
  metadataStudyIdList?: InputMaybe<MetadataNestedMetadataStudyIdListUpdateInput>;
  /** Actions for the "Metadata.metadataStudyPhysicianList" relation: "MetadataStudyPhysician.metadata"'s inverse relation */
  metadataStudyPhysicianList?: InputMaybe<MetadataNestedMetadataStudyPhysicianListUpdateInput>;
  /** Actions for the "Metadata.metadataSubjectCodeList" relation: "MetadataSubjectCode.metadata"'s inverse relation */
  metadataSubjectCodeList?: InputMaybe<MetadataNestedMetadataSubjectCodeListUpdateInput>;
  /** Actions for the "Metadata.metadataSubjectList" relation: "MetadataSubject.metadata"'s inverse relation */
  metadataSubjectList?: InputMaybe<MetadataNestedMetadataSubjectListUpdateInput>;
  /** Actions for the "Metadata.metadataSubjectPrismaList" relation: "MetadataSubjectPrisma.metadata"'s inverse relation */
  metadataSubjectPrismaList?: InputMaybe<MetadataNestedMetadataSubjectPrismaListUpdateInput>;
  /** Actions for the "Metadata.metadataSupplementalCategoriesList" relation: "MetadataSupplementalCategories.metadata"'s inverse relation */
  metadataSupplementalCategoriesList?: InputMaybe<MetadataNestedMetadataSupplementalCategoriesListUpdateInput>;
  /** Actions for the "Metadata.metadataTargetVersionList" relation: "MetadataTargetVersion.metadata"'s inverse relation */
  metadataTargetVersionList?: InputMaybe<MetadataNestedMetadataTargetVersionListUpdateInput>;
  /** Actions for the "Metadata.metadataTitleList" relation: "MetadataTitle.metadata"'s inverse relation */
  metadataTitleList?: InputMaybe<MetadataNestedMetadataTitleListUpdateInput>;
  /** Actions for the "Metadata.metadataToneCurveList" relation: "MetadataToneCurve.metadata"'s inverse relation */
  metadataToneCurveList?: InputMaybe<MetadataNestedMetadataToneCurveListUpdateInput>;
  /** Actions for the "Metadata.metadataTransferredByEmailList" relation: "MetadataTransferredByEmail.metadata"'s inverse relation */
  metadataTransferredByEmailList?: InputMaybe<MetadataNestedMetadataTransferredByEmailListUpdateInput>;
  /** Actions for the "Metadata.metadataTransferredByFullNameList" relation: "MetadataTransferredByFullName.metadata"'s inverse relation */
  metadataTransferredByFullNameList?: InputMaybe<MetadataNestedMetadataTransferredByFullNameListUpdateInput>;
  /** Actions for the "Metadata.metadataTransferredByList" relation: "MetadataTransferredBy.metadata"'s inverse relation */
  metadataTransferredByList?: InputMaybe<MetadataNestedMetadataTransferredByListUpdateInput>;
  /** Actions for the "Metadata.metadataTransmissionReferenceList" relation: "MetadataTransmissionReference.metadata"'s inverse relation */
  metadataTransmissionReferenceList?: InputMaybe<MetadataNestedMetadataTransmissionReferenceListUpdateInput>;
  /** Actions for the "Metadata.metadataTypeList" relation: "MetadataType.metadata"'s inverse relation */
  metadataTypeList?: InputMaybe<MetadataNestedMetadataTypeListUpdateInput>;
  /** Actions for the "Metadata.metadataUndersubjectList" relation: "MetadataUndersubject.metadata"'s inverse relation */
  metadataUndersubjectList?: InputMaybe<MetadataNestedMetadataUndersubjectListUpdateInput>;
  /** Actions for the "Metadata.metadataUnderUnderSubjectList" relation: "MetadataUnderUnderSubject.metadata"'s inverse relation */
  metadataUnderUnderSubjectList?: InputMaybe<MetadataNestedMetadataUnderUnderSubjectListUpdateInput>;
  /** Actions for the "Metadata.metadataUniqueIdList" relation: "MetadataUniqueId.metadata"'s inverse relation */
  metadataUniqueIdList?: InputMaybe<MetadataNestedMetadataUniqueIdListUpdateInput>;
  /** Actions for the "Metadata.metadataUnitList" relation: "MetadataUnit.metadata"'s inverse relation */
  metadataUnitList?: InputMaybe<MetadataNestedMetadataUnitListUpdateInput>;
  /** Actions for the "Metadata.metadataUnitShortNameList" relation: "MetadataUnitShortName.metadata"'s inverse relation */
  metadataUnitShortNameList?: InputMaybe<MetadataNestedMetadataUnitShortNameListUpdateInput>;
  /** Actions for the "Metadata.metadataUploadedByFullNameList" relation: "MetadataUploadedByFullName.metadata"'s inverse relation */
  metadataUploadedByFullNameList?: InputMaybe<MetadataNestedMetadataUploadedByFullNameListUpdateInput>;
  /** Actions for the "Metadata.metadataUploadedByList" relation: "MetadataUploadedBy.metadata"'s inverse relation */
  metadataUploadedByList?: InputMaybe<MetadataNestedMetadataUploadedByListUpdateInput>;
  /** Actions for the "Metadata.metadataUploadTimeList" relation: "MetadataUploadTime.metadata"'s inverse relation */
  metadataUploadTimeList?: InputMaybe<MetadataNestedMetadataUploadTimeListUpdateInput>;
  /** Actions for the "Metadata.metadataUrgencyList" relation: "MetadataUrgency.metadata"'s inverse relation */
  metadataUrgencyList?: InputMaybe<MetadataNestedMetadataUrgencyListUpdateInput>;
  /** Actions for the "Metadata.metadataUsageTermsList" relation: "MetadataUsageTerms.metadata"'s inverse relation */
  metadataUsageTermsList?: InputMaybe<MetadataNestedMetadataUsageTermsListUpdateInput>;
  /** Actions for the "Metadata.metadataUserCommentList" relation: "MetadataUserComment.metadata"'s inverse relation */
  metadataUserCommentList?: InputMaybe<MetadataNestedMetadataUserCommentListUpdateInput>;
  /** Actions for the "Metadata.metadataUserDefined195List" relation: "MetadataUserDefined195.metadata"'s inverse relation */
  metadataUserDefined195List?: InputMaybe<MetadataNestedMetadataUserDefined195ListUpdateInput>;
  /** Actions for the "Metadata.metadataUserDefined237List" relation: "MetadataUserDefined237.metadata"'s inverse relation */
  metadataUserDefined237List?: InputMaybe<MetadataNestedMetadataUserDefined237ListUpdateInput>;
  /** Actions for the "Metadata.metadataUserDefined238List" relation: "MetadataUserDefined238.metadata"'s inverse relation */
  metadataUserDefined238List?: InputMaybe<MetadataNestedMetadataUserDefined238ListUpdateInput>;
  /** Actions for the "Metadata.metadataUserDefined239List" relation: "MetadataUserDefined239.metadata"'s inverse relation */
  metadataUserDefined239List?: InputMaybe<MetadataNestedMetadataUserDefined239ListUpdateInput>;
  /** Actions for the "Metadata.metadataUserDefined242List" relation: "MetadataUserDefined242.metadata"'s inverse relation */
  metadataUserDefined242List?: InputMaybe<MetadataNestedMetadataUserDefined242ListUpdateInput>;
  /** Actions for the "Metadata.metadataUserDefined62List" relation: "MetadataUserDefined62.metadata"'s inverse relation */
  metadataUserDefined62List?: InputMaybe<MetadataNestedMetadataUserDefined62ListUpdateInput>;
  /** Actions for the "Metadata.metadataValidList" relation: "MetadataValid.metadata"'s inverse relation */
  metadataValidList?: InputMaybe<MetadataNestedMetadataValidListUpdateInput>;
  /** Actions for the "Metadata.metadataVersionList" relation: "MetadataVersion.metadata"'s inverse relation */
  metadataVersionList?: InputMaybe<MetadataNestedMetadataVersionListUpdateInput>;
  /** Actions for the "Metadata.metadataWebStatementList" relation: "MetadataWebStatement.metadata"'s inverse relation */
  metadataWebStatementList?: InputMaybe<MetadataNestedMetadataWebStatementListUpdateInput>;
  /** Actions for the "Metadata.metadataWorkflowKindList" relation: "MetadataWorkflowKind.metadata"'s inverse relation */
  metadataWorkflowKindList?: InputMaybe<MetadataNestedMetadataWorkflowKindListUpdateInput>;
  /** Actions for the "Metadata.metadataXmpFileStampsList" relation: "MetadataXmpFileStamps.metadata"'s inverse relation */
  metadataXmpFileStampsList?: InputMaybe<MetadataNestedMetadataXmpFileStampsListUpdateInput>;
  /** Actions for the "Metadata.metadataXmpHistoryList" relation: "MetadataXmpHistory.metadata"'s inverse relation */
  metadataXmpHistoryList?: InputMaybe<MetadataNestedMetadataXmpHistoryListUpdateInput>;
  name?: InputMaybe<MetadataNameEnumType>;
  namespace?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Metadata.photoMetadatas" relation: "PhotoMetadata.metadata"'s inverse relation */
  photoMetadatas?: InputMaybe<MetadataNestedPhotoMetadatasUpdateInput>;
  structName?: InputMaybe<Scalars['String']['input']>;
  /** whether is this metadata value is a list or not, for display usage */
  type?: InputMaybe<MetadataValueTypeEnum>;
};

/** "MetadataUploadedBy" resource's node */
export type MetadataUploadedBy = {
  __typename?: 'MetadataUploadedBy';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUploadedByCreateInput = {
  /** Actions for the "MetadataUploadedBy.archive" relation */
  archive: MetadataUploadedByNestedArchiveCreateInput;
  /** Actions for the "MetadataUploadedBy.metadata" relation */
  metadata: MetadataUploadedByNestedMetadataCreateInput;
  /** Actions for the "MetadataUploadedBy.photo" relation */
  photo: MetadataUploadedByNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUploadedByFullName" resource's node */
export type MetadataUploadedByFullName = {
  __typename?: 'MetadataUploadedByFullName';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUploadedByFullNameCreateInput = {
  /** Actions for the "MetadataUploadedByFullName.archive" relation */
  archive: MetadataUploadedByFullNameNestedArchiveCreateInput;
  /** Actions for the "MetadataUploadedByFullName.metadata" relation */
  metadata: MetadataUploadedByFullNameNestedMetadataCreateInput;
  /** Actions for the "MetadataUploadedByFullName.photo" relation */
  photo: MetadataUploadedByFullNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByFullNameNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.archive" relation. */
  update?: InputMaybe<MetadataUploadedByFullNameNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.archive" relation. */
  upsert?: InputMaybe<MetadataUploadedByFullNameNestedUpsertArchiveCreateInput>;
};

export type MetadataUploadedByFullNameNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.archive" relation. */
  update?: InputMaybe<MetadataUploadedByFullNameNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.archive" relation. */
  upsert?: InputMaybe<MetadataUploadedByFullNameNestedUpsertArchiveUpdateInput>;
};

export type MetadataUploadedByFullNameNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.metadata" relation. */
  update?: InputMaybe<MetadataUploadedByFullNameNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.metadata" relation. */
  upsert?: InputMaybe<MetadataUploadedByFullNameNestedUpsertMetadataCreateInput>;
};

export type MetadataUploadedByFullNameNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.metadata" relation. */
  update?: InputMaybe<MetadataUploadedByFullNameNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.metadata" relation. */
  upsert?: InputMaybe<MetadataUploadedByFullNameNestedUpsertMetadataUpdateInput>;
};

export type MetadataUploadedByFullNameNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.photo" relation. */
  update?: InputMaybe<MetadataUploadedByFullNameNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.photo" relation. */
  upsert?: InputMaybe<MetadataUploadedByFullNameNestedUpsertPhotoCreateInput>;
};

export type MetadataUploadedByFullNameNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.photo" relation. */
  update?: InputMaybe<MetadataUploadedByFullNameNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUploadedByFullName" node, through the "MetadataUploadedByFullName.photo" relation. */
  upsert?: InputMaybe<MetadataUploadedByFullNameNestedUpsertPhotoUpdateInput>;
};

export type MetadataUploadedByFullNameNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadedByFullNameNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadedByFullNameNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadedByFullNameNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadedByFullNameNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUploadedByFullNameNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUploadedByFullNameNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadedByFullNameNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadedByFullNameNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadedByFullNameNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadedByFullNameNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUploadedByFullNameNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUploadedByFullNameOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUploadedByFullNameUpdateInput = {
  /** Actions for the "MetadataUploadedByFullName.archive" relation */
  archive?: InputMaybe<MetadataUploadedByFullNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUploadedByFullName.metadata" relation */
  metadata?: InputMaybe<MetadataUploadedByFullNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUploadedByFullName.photo" relation */
  photo?: InputMaybe<MetadataUploadedByFullNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUploadedByFullName" nodes by specifying some conditions */
export type MetadataUploadedByFullNameWhereInput = {
  AND?: InputMaybe<Array<MetadataUploadedByFullNameWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
  OR?: InputMaybe<Array<MetadataUploadedByFullNameWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUploadedByFullName" node. */
export type MetadataUploadedByFullNameWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUploadedByFullNameWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUploadedByFullName.metadata" relation */
  metadata: MetadataUploadedByFullNameNestedMetadataCreateInput;
  /** Actions for the "MetadataUploadedByFullName.photo" relation */
  photo: MetadataUploadedByFullNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByFullNameWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUploadedByFullName.metadata" relation */
  metadata?: InputMaybe<MetadataUploadedByFullNameNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUploadedByFullName.photo" relation */
  photo?: InputMaybe<MetadataUploadedByFullNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByFullNameWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUploadedByFullName.archive" relation */
  archive: MetadataUploadedByFullNameNestedArchiveCreateInput;
  /** Actions for the "MetadataUploadedByFullName.photo" relation */
  photo: MetadataUploadedByFullNameNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByFullNameWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUploadedByFullName.archive" relation */
  archive?: InputMaybe<MetadataUploadedByFullNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUploadedByFullName.photo" relation */
  photo?: InputMaybe<MetadataUploadedByFullNameNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByFullNameWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUploadedByFullName.archive" relation */
  archive: MetadataUploadedByFullNameNestedArchiveCreateInput;
  /** Actions for the "MetadataUploadedByFullName.metadata" relation */
  metadata: MetadataUploadedByFullNameNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByFullNameWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUploadedByFullName.archive" relation */
  archive?: InputMaybe<MetadataUploadedByFullNameNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUploadedByFullName.metadata" relation */
  metadata?: InputMaybe<MetadataUploadedByFullNameNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.archive" relation. */
  update?: InputMaybe<MetadataUploadedByNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.archive" relation. */
  upsert?: InputMaybe<MetadataUploadedByNestedUpsertArchiveCreateInput>;
};

export type MetadataUploadedByNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.archive" relation. */
  update?: InputMaybe<MetadataUploadedByNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.archive" relation. */
  upsert?: InputMaybe<MetadataUploadedByNestedUpsertArchiveUpdateInput>;
};

export type MetadataUploadedByNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.metadata" relation. */
  update?: InputMaybe<MetadataUploadedByNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.metadata" relation. */
  upsert?: InputMaybe<MetadataUploadedByNestedUpsertMetadataCreateInput>;
};

export type MetadataUploadedByNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.metadata" relation. */
  update?: InputMaybe<MetadataUploadedByNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.metadata" relation. */
  upsert?: InputMaybe<MetadataUploadedByNestedUpsertMetadataUpdateInput>;
};

export type MetadataUploadedByNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.photo" relation. */
  update?: InputMaybe<MetadataUploadedByNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUploadedBy" node, through the "MetadataUploadedBy.photo" relation. */
  upsert?: InputMaybe<MetadataUploadedByNestedUpsertPhotoCreateInput>;
};

export type MetadataUploadedByNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.photo" relation. */
  update?: InputMaybe<MetadataUploadedByNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUploadedBy" node, through the "MetadataUploadedBy.photo" relation. */
  upsert?: InputMaybe<MetadataUploadedByNestedUpsertPhotoUpdateInput>;
};

export type MetadataUploadedByNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadedByNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadedByNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadedByNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadedByNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUploadedByNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUploadedByNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadedByNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadedByNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadedByNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadedByNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUploadedByNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUploadedByOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUploadedByUpdateInput = {
  /** Actions for the "MetadataUploadedBy.archive" relation */
  archive?: InputMaybe<MetadataUploadedByNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUploadedBy.metadata" relation */
  metadata?: InputMaybe<MetadataUploadedByNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUploadedBy.photo" relation */
  photo?: InputMaybe<MetadataUploadedByNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUploadedBy" nodes by specifying some conditions */
export type MetadataUploadedByWhereInput = {
  AND?: InputMaybe<Array<MetadataUploadedByWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUploadedByWhereInput>;
  OR?: InputMaybe<Array<MetadataUploadedByWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUploadedBy" node. */
export type MetadataUploadedByWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUploadedByWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUploadedBy.metadata" relation */
  metadata: MetadataUploadedByNestedMetadataCreateInput;
  /** Actions for the "MetadataUploadedBy.photo" relation */
  photo: MetadataUploadedByNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUploadedBy.metadata" relation */
  metadata?: InputMaybe<MetadataUploadedByNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUploadedBy.photo" relation */
  photo?: InputMaybe<MetadataUploadedByNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUploadedBy.archive" relation */
  archive: MetadataUploadedByNestedArchiveCreateInput;
  /** Actions for the "MetadataUploadedBy.photo" relation */
  photo: MetadataUploadedByNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUploadedBy.archive" relation */
  archive?: InputMaybe<MetadataUploadedByNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUploadedBy.photo" relation */
  photo?: InputMaybe<MetadataUploadedByNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUploadedBy.archive" relation */
  archive: MetadataUploadedByNestedArchiveCreateInput;
  /** Actions for the "MetadataUploadedBy.metadata" relation */
  metadata: MetadataUploadedByNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadedByWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUploadedBy.archive" relation */
  archive?: InputMaybe<MetadataUploadedByNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUploadedBy.metadata" relation */
  metadata?: InputMaybe<MetadataUploadedByNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUploadTime" resource's node */
export type MetadataUploadTime = {
  __typename?: 'MetadataUploadTime';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUploadTimeCreateInput = {
  /** Actions for the "MetadataUploadTime.archive" relation */
  archive: MetadataUploadTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataUploadTime.metadata" relation */
  metadata: MetadataUploadTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataUploadTime.photo" relation */
  photo: MetadataUploadTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadTimeNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUploadTime" node, through the "MetadataUploadTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUploadTime" node, through the "MetadataUploadTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUploadTime" node, through the "MetadataUploadTime.archive" relation. */
  update?: InputMaybe<MetadataUploadTimeNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUploadTime" node, through the "MetadataUploadTime.archive" relation. */
  upsert?: InputMaybe<MetadataUploadTimeNestedUpsertArchiveCreateInput>;
};

export type MetadataUploadTimeNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.archive" relation. */
  update?: InputMaybe<MetadataUploadTimeNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.archive" relation. */
  upsert?: InputMaybe<MetadataUploadTimeNestedUpsertArchiveUpdateInput>;
};

export type MetadataUploadTimeNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUploadTime" node, through the "MetadataUploadTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUploadTime" node, through the "MetadataUploadTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUploadTime" node, through the "MetadataUploadTime.metadata" relation. */
  update?: InputMaybe<MetadataUploadTimeNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUploadTime" node, through the "MetadataUploadTime.metadata" relation. */
  upsert?: InputMaybe<MetadataUploadTimeNestedUpsertMetadataCreateInput>;
};

export type MetadataUploadTimeNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.metadata" relation. */
  update?: InputMaybe<MetadataUploadTimeNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.metadata" relation. */
  upsert?: InputMaybe<MetadataUploadTimeNestedUpsertMetadataUpdateInput>;
};

export type MetadataUploadTimeNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUploadTime" node, through the "MetadataUploadTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUploadTime" node, through the "MetadataUploadTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUploadTime" node, through the "MetadataUploadTime.photo" relation. */
  update?: InputMaybe<MetadataUploadTimeNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUploadTime" node, through the "MetadataUploadTime.photo" relation. */
  upsert?: InputMaybe<MetadataUploadTimeNestedUpsertPhotoCreateInput>;
};

export type MetadataUploadTimeNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.photo" relation. */
  update?: InputMaybe<MetadataUploadTimeNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUploadTime" node, through the "MetadataUploadTime.photo" relation. */
  upsert?: InputMaybe<MetadataUploadTimeNestedUpsertPhotoUpdateInput>;
};

export type MetadataUploadTimeNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadTimeNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadTimeNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadTimeNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadTimeNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUploadTimeNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUploadTimeNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadTimeNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUploadTimeNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadTimeNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUploadTimeNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUploadTimeNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUploadTimeOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUploadTimeUpdateInput = {
  /** Actions for the "MetadataUploadTime.archive" relation */
  archive?: InputMaybe<MetadataUploadTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUploadTime.metadata" relation */
  metadata?: InputMaybe<MetadataUploadTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUploadTime.photo" relation */
  photo?: InputMaybe<MetadataUploadTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUploadTime" nodes by specifying some conditions */
export type MetadataUploadTimeWhereInput = {
  AND?: InputMaybe<Array<MetadataUploadTimeWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUploadTimeWhereInput>;
  OR?: InputMaybe<Array<MetadataUploadTimeWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUploadTime" node. */
export type MetadataUploadTimeWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUploadTimeWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUploadTime.metadata" relation */
  metadata: MetadataUploadTimeNestedMetadataCreateInput;
  /** Actions for the "MetadataUploadTime.photo" relation */
  photo: MetadataUploadTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadTimeWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUploadTime.metadata" relation */
  metadata?: InputMaybe<MetadataUploadTimeNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUploadTime.photo" relation */
  photo?: InputMaybe<MetadataUploadTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadTimeWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUploadTime.archive" relation */
  archive: MetadataUploadTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataUploadTime.photo" relation */
  photo: MetadataUploadTimeNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadTimeWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUploadTime.archive" relation */
  archive?: InputMaybe<MetadataUploadTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUploadTime.photo" relation */
  photo?: InputMaybe<MetadataUploadTimeNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadTimeWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUploadTime.archive" relation */
  archive: MetadataUploadTimeNestedArchiveCreateInput;
  /** Actions for the "MetadataUploadTime.metadata" relation */
  metadata: MetadataUploadTimeNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUploadTimeWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUploadTime.archive" relation */
  archive?: InputMaybe<MetadataUploadTimeNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUploadTime.metadata" relation */
  metadata?: InputMaybe<MetadataUploadTimeNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUrgency" resource's node */
export type MetadataUrgency = {
  __typename?: 'MetadataUrgency';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUrgencyCreateInput = {
  /** Actions for the "MetadataUrgency.archive" relation */
  archive: MetadataUrgencyNestedArchiveCreateInput;
  /** Actions for the "MetadataUrgency.metadata" relation */
  metadata: MetadataUrgencyNestedMetadataCreateInput;
  /** Actions for the "MetadataUrgency.photo" relation */
  photo: MetadataUrgencyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUrgencyNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUrgency" node, through the "MetadataUrgency.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUrgency" node, through the "MetadataUrgency.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUrgency" node, through the "MetadataUrgency.archive" relation. */
  update?: InputMaybe<MetadataUrgencyNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUrgency" node, through the "MetadataUrgency.archive" relation. */
  upsert?: InputMaybe<MetadataUrgencyNestedUpsertArchiveCreateInput>;
};

export type MetadataUrgencyNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUrgency" node, through the "MetadataUrgency.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUrgency" node, through the "MetadataUrgency.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUrgency" node, through the "MetadataUrgency.archive" relation. */
  update?: InputMaybe<MetadataUrgencyNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUrgency" node, through the "MetadataUrgency.archive" relation. */
  upsert?: InputMaybe<MetadataUrgencyNestedUpsertArchiveUpdateInput>;
};

export type MetadataUrgencyNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUrgency" node, through the "MetadataUrgency.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUrgency" node, through the "MetadataUrgency.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUrgency" node, through the "MetadataUrgency.metadata" relation. */
  update?: InputMaybe<MetadataUrgencyNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUrgency" node, through the "MetadataUrgency.metadata" relation. */
  upsert?: InputMaybe<MetadataUrgencyNestedUpsertMetadataCreateInput>;
};

export type MetadataUrgencyNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUrgency" node, through the "MetadataUrgency.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUrgency" node, through the "MetadataUrgency.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUrgency" node, through the "MetadataUrgency.metadata" relation. */
  update?: InputMaybe<MetadataUrgencyNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUrgency" node, through the "MetadataUrgency.metadata" relation. */
  upsert?: InputMaybe<MetadataUrgencyNestedUpsertMetadataUpdateInput>;
};

export type MetadataUrgencyNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUrgency" node, through the "MetadataUrgency.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUrgency" node, through the "MetadataUrgency.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUrgency" node, through the "MetadataUrgency.photo" relation. */
  update?: InputMaybe<MetadataUrgencyNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUrgency" node, through the "MetadataUrgency.photo" relation. */
  upsert?: InputMaybe<MetadataUrgencyNestedUpsertPhotoCreateInput>;
};

export type MetadataUrgencyNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUrgency" node, through the "MetadataUrgency.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUrgency" node, through the "MetadataUrgency.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUrgency" node, through the "MetadataUrgency.photo" relation. */
  update?: InputMaybe<MetadataUrgencyNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUrgency" node, through the "MetadataUrgency.photo" relation. */
  upsert?: InputMaybe<MetadataUrgencyNestedUpsertPhotoUpdateInput>;
};

export type MetadataUrgencyNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUrgencyNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUrgencyNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUrgencyNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUrgencyNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUrgencyNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUrgencyNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUrgencyNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUrgencyNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUrgencyNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUrgencyNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUrgencyNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUrgencyOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUrgencyUpdateInput = {
  /** Actions for the "MetadataUrgency.archive" relation */
  archive?: InputMaybe<MetadataUrgencyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUrgency.metadata" relation */
  metadata?: InputMaybe<MetadataUrgencyNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUrgency.photo" relation */
  photo?: InputMaybe<MetadataUrgencyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUrgency" nodes by specifying some conditions */
export type MetadataUrgencyWhereInput = {
  AND?: InputMaybe<Array<MetadataUrgencyWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUrgencyWhereInput>;
  OR?: InputMaybe<Array<MetadataUrgencyWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUrgency" node. */
export type MetadataUrgencyWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUrgencyWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUrgency.metadata" relation */
  metadata: MetadataUrgencyNestedMetadataCreateInput;
  /** Actions for the "MetadataUrgency.photo" relation */
  photo: MetadataUrgencyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUrgencyWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUrgency.metadata" relation */
  metadata?: InputMaybe<MetadataUrgencyNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUrgency.photo" relation */
  photo?: InputMaybe<MetadataUrgencyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUrgencyWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUrgency.archive" relation */
  archive: MetadataUrgencyNestedArchiveCreateInput;
  /** Actions for the "MetadataUrgency.photo" relation */
  photo: MetadataUrgencyNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUrgencyWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUrgency.archive" relation */
  archive?: InputMaybe<MetadataUrgencyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUrgency.photo" relation */
  photo?: InputMaybe<MetadataUrgencyNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUrgencyWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUrgency.archive" relation */
  archive: MetadataUrgencyNestedArchiveCreateInput;
  /** Actions for the "MetadataUrgency.metadata" relation */
  metadata: MetadataUrgencyNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUrgencyWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUrgency.archive" relation */
  archive?: InputMaybe<MetadataUrgencyNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUrgency.metadata" relation */
  metadata?: InputMaybe<MetadataUrgencyNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUsageTerms" resource's node */
export type MetadataUsageTerms = {
  __typename?: 'MetadataUsageTerms';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUsageTermsCreateInput = {
  /** Actions for the "MetadataUsageTerms.archive" relation */
  archive: MetadataUsageTermsNestedArchiveCreateInput;
  /** Actions for the "MetadataUsageTerms.metadata" relation */
  metadata: MetadataUsageTermsNestedMetadataCreateInput;
  /** Actions for the "MetadataUsageTerms.photo" relation */
  photo: MetadataUsageTermsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUsageTermsNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.archive" relation. */
  update?: InputMaybe<MetadataUsageTermsNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.archive" relation. */
  upsert?: InputMaybe<MetadataUsageTermsNestedUpsertArchiveCreateInput>;
};

export type MetadataUsageTermsNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.archive" relation. */
  update?: InputMaybe<MetadataUsageTermsNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.archive" relation. */
  upsert?: InputMaybe<MetadataUsageTermsNestedUpsertArchiveUpdateInput>;
};

export type MetadataUsageTermsNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.metadata" relation. */
  update?: InputMaybe<MetadataUsageTermsNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.metadata" relation. */
  upsert?: InputMaybe<MetadataUsageTermsNestedUpsertMetadataCreateInput>;
};

export type MetadataUsageTermsNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.metadata" relation. */
  update?: InputMaybe<MetadataUsageTermsNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.metadata" relation. */
  upsert?: InputMaybe<MetadataUsageTermsNestedUpsertMetadataUpdateInput>;
};

export type MetadataUsageTermsNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.photo" relation. */
  update?: InputMaybe<MetadataUsageTermsNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUsageTerms" node, through the "MetadataUsageTerms.photo" relation. */
  upsert?: InputMaybe<MetadataUsageTermsNestedUpsertPhotoCreateInput>;
};

export type MetadataUsageTermsNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.photo" relation. */
  update?: InputMaybe<MetadataUsageTermsNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUsageTerms" node, through the "MetadataUsageTerms.photo" relation. */
  upsert?: InputMaybe<MetadataUsageTermsNestedUpsertPhotoUpdateInput>;
};

export type MetadataUsageTermsNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUsageTermsNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUsageTermsNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUsageTermsNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUsageTermsNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUsageTermsNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUsageTermsNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUsageTermsNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUsageTermsNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUsageTermsNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUsageTermsNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUsageTermsNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUsageTermsOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUsageTermsUpdateInput = {
  /** Actions for the "MetadataUsageTerms.archive" relation */
  archive?: InputMaybe<MetadataUsageTermsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUsageTerms.metadata" relation */
  metadata?: InputMaybe<MetadataUsageTermsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUsageTerms.photo" relation */
  photo?: InputMaybe<MetadataUsageTermsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUsageTerms" nodes by specifying some conditions */
export type MetadataUsageTermsWhereInput = {
  AND?: InputMaybe<Array<MetadataUsageTermsWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUsageTermsWhereInput>;
  OR?: InputMaybe<Array<MetadataUsageTermsWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUsageTerms" node. */
export type MetadataUsageTermsWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUsageTermsWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUsageTerms.metadata" relation */
  metadata: MetadataUsageTermsNestedMetadataCreateInput;
  /** Actions for the "MetadataUsageTerms.photo" relation */
  photo: MetadataUsageTermsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUsageTermsWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUsageTerms.metadata" relation */
  metadata?: InputMaybe<MetadataUsageTermsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUsageTerms.photo" relation */
  photo?: InputMaybe<MetadataUsageTermsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUsageTermsWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUsageTerms.archive" relation */
  archive: MetadataUsageTermsNestedArchiveCreateInput;
  /** Actions for the "MetadataUsageTerms.photo" relation */
  photo: MetadataUsageTermsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUsageTermsWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUsageTerms.archive" relation */
  archive?: InputMaybe<MetadataUsageTermsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUsageTerms.photo" relation */
  photo?: InputMaybe<MetadataUsageTermsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUsageTermsWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUsageTerms.archive" relation */
  archive: MetadataUsageTermsNestedArchiveCreateInput;
  /** Actions for the "MetadataUsageTerms.metadata" relation */
  metadata: MetadataUsageTermsNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUsageTermsWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUsageTerms.archive" relation */
  archive?: InputMaybe<MetadataUsageTermsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUsageTerms.metadata" relation */
  metadata?: InputMaybe<MetadataUsageTermsNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUserComment" resource's node */
export type MetadataUserComment = {
  __typename?: 'MetadataUserComment';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUserCommentCreateInput = {
  /** Actions for the "MetadataUserComment.archive" relation */
  archive: MetadataUserCommentNestedArchiveCreateInput;
  /** Actions for the "MetadataUserComment.metadata" relation */
  metadata: MetadataUserCommentNestedMetadataCreateInput;
  /** Actions for the "MetadataUserComment.photo" relation */
  photo: MetadataUserCommentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserCommentNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUserComment" node, through the "MetadataUserComment.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUserComment" node, through the "MetadataUserComment.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUserComment" node, through the "MetadataUserComment.archive" relation. */
  update?: InputMaybe<MetadataUserCommentNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUserComment" node, through the "MetadataUserComment.archive" relation. */
  upsert?: InputMaybe<MetadataUserCommentNestedUpsertArchiveCreateInput>;
};

export type MetadataUserCommentNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUserComment" node, through the "MetadataUserComment.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUserComment" node, through the "MetadataUserComment.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUserComment" node, through the "MetadataUserComment.archive" relation. */
  update?: InputMaybe<MetadataUserCommentNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUserComment" node, through the "MetadataUserComment.archive" relation. */
  upsert?: InputMaybe<MetadataUserCommentNestedUpsertArchiveUpdateInput>;
};

export type MetadataUserCommentNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUserComment" node, through the "MetadataUserComment.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUserComment" node, through the "MetadataUserComment.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUserComment" node, through the "MetadataUserComment.metadata" relation. */
  update?: InputMaybe<MetadataUserCommentNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUserComment" node, through the "MetadataUserComment.metadata" relation. */
  upsert?: InputMaybe<MetadataUserCommentNestedUpsertMetadataCreateInput>;
};

export type MetadataUserCommentNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUserComment" node, through the "MetadataUserComment.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUserComment" node, through the "MetadataUserComment.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUserComment" node, through the "MetadataUserComment.metadata" relation. */
  update?: InputMaybe<MetadataUserCommentNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUserComment" node, through the "MetadataUserComment.metadata" relation. */
  upsert?: InputMaybe<MetadataUserCommentNestedUpsertMetadataUpdateInput>;
};

export type MetadataUserCommentNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUserComment" node, through the "MetadataUserComment.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUserComment" node, through the "MetadataUserComment.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUserComment" node, through the "MetadataUserComment.photo" relation. */
  update?: InputMaybe<MetadataUserCommentNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUserComment" node, through the "MetadataUserComment.photo" relation. */
  upsert?: InputMaybe<MetadataUserCommentNestedUpsertPhotoCreateInput>;
};

export type MetadataUserCommentNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUserComment" node, through the "MetadataUserComment.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUserComment" node, through the "MetadataUserComment.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUserComment" node, through the "MetadataUserComment.photo" relation. */
  update?: InputMaybe<MetadataUserCommentNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUserComment" node, through the "MetadataUserComment.photo" relation. */
  upsert?: InputMaybe<MetadataUserCommentNestedUpsertPhotoUpdateInput>;
};

export type MetadataUserCommentNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserCommentNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserCommentNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserCommentNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserCommentNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserCommentNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserCommentNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserCommentNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserCommentNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserCommentNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserCommentNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserCommentNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUserCommentOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUserCommentUpdateInput = {
  /** Actions for the "MetadataUserComment.archive" relation */
  archive?: InputMaybe<MetadataUserCommentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserComment.metadata" relation */
  metadata?: InputMaybe<MetadataUserCommentNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserComment.photo" relation */
  photo?: InputMaybe<MetadataUserCommentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUserComment" nodes by specifying some conditions */
export type MetadataUserCommentWhereInput = {
  AND?: InputMaybe<Array<MetadataUserCommentWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUserCommentWhereInput>;
  OR?: InputMaybe<Array<MetadataUserCommentWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUserComment" node. */
export type MetadataUserCommentWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUserCommentWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUserComment.metadata" relation */
  metadata: MetadataUserCommentNestedMetadataCreateInput;
  /** Actions for the "MetadataUserComment.photo" relation */
  photo: MetadataUserCommentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserCommentWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUserComment.metadata" relation */
  metadata?: InputMaybe<MetadataUserCommentNestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserComment.photo" relation */
  photo?: InputMaybe<MetadataUserCommentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserCommentWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUserComment.archive" relation */
  archive: MetadataUserCommentNestedArchiveCreateInput;
  /** Actions for the "MetadataUserComment.photo" relation */
  photo: MetadataUserCommentNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserCommentWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUserComment.archive" relation */
  archive?: InputMaybe<MetadataUserCommentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserComment.photo" relation */
  photo?: InputMaybe<MetadataUserCommentNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserCommentWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUserComment.archive" relation */
  archive: MetadataUserCommentNestedArchiveCreateInput;
  /** Actions for the "MetadataUserComment.metadata" relation */
  metadata: MetadataUserCommentNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserCommentWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUserComment.archive" relation */
  archive?: InputMaybe<MetadataUserCommentNestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserComment.metadata" relation */
  metadata?: InputMaybe<MetadataUserCommentNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUserDefined195" resource's node */
export type MetadataUserDefined195 = {
  __typename?: 'MetadataUserDefined195';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUserDefined195CreateInput = {
  /** Actions for the "MetadataUserDefined195.archive" relation */
  archive: MetadataUserDefined195NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined195.metadata" relation */
  metadata: MetadataUserDefined195NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined195.photo" relation */
  photo: MetadataUserDefined195NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined195NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.archive" relation. */
  update?: InputMaybe<MetadataUserDefined195NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined195NestedUpsertArchiveCreateInput>;
};

export type MetadataUserDefined195NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.archive" relation. */
  update?: InputMaybe<MetadataUserDefined195NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined195NestedUpsertArchiveUpdateInput>;
};

export type MetadataUserDefined195NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined195NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined195NestedUpsertMetadataCreateInput>;
};

export type MetadataUserDefined195NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined195NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined195NestedUpsertMetadataUpdateInput>;
};

export type MetadataUserDefined195NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.photo" relation. */
  update?: InputMaybe<MetadataUserDefined195NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUserDefined195" node, through the "MetadataUserDefined195.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined195NestedUpsertPhotoCreateInput>;
};

export type MetadataUserDefined195NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.photo" relation. */
  update?: InputMaybe<MetadataUserDefined195NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUserDefined195" node, through the "MetadataUserDefined195.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined195NestedUpsertPhotoUpdateInput>;
};

export type MetadataUserDefined195NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined195NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined195NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined195NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined195NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined195NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined195NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined195NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined195NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined195NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined195NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined195NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUserDefined195OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUserDefined195UpdateInput = {
  /** Actions for the "MetadataUserDefined195.archive" relation */
  archive?: InputMaybe<MetadataUserDefined195NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined195.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined195NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined195.photo" relation */
  photo?: InputMaybe<MetadataUserDefined195NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUserDefined195" nodes by specifying some conditions */
export type MetadataUserDefined195WhereInput = {
  AND?: InputMaybe<Array<MetadataUserDefined195WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUserDefined195WhereInput>;
  OR?: InputMaybe<Array<MetadataUserDefined195WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUserDefined195" node. */
export type MetadataUserDefined195WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUserDefined195WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUserDefined195.metadata" relation */
  metadata: MetadataUserDefined195NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined195.photo" relation */
  photo: MetadataUserDefined195NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined195WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUserDefined195.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined195NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined195.photo" relation */
  photo?: InputMaybe<MetadataUserDefined195NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined195WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUserDefined195.archive" relation */
  archive: MetadataUserDefined195NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined195.photo" relation */
  photo: MetadataUserDefined195NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined195WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUserDefined195.archive" relation */
  archive?: InputMaybe<MetadataUserDefined195NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined195.photo" relation */
  photo?: InputMaybe<MetadataUserDefined195NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined195WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUserDefined195.archive" relation */
  archive: MetadataUserDefined195NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined195.metadata" relation */
  metadata: MetadataUserDefined195NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined195WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUserDefined195.archive" relation */
  archive?: InputMaybe<MetadataUserDefined195NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined195.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined195NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUserDefined237" resource's node */
export type MetadataUserDefined237 = {
  __typename?: 'MetadataUserDefined237';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUserDefined237CreateInput = {
  /** Actions for the "MetadataUserDefined237.archive" relation */
  archive: MetadataUserDefined237NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined237.metadata" relation */
  metadata: MetadataUserDefined237NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined237.photo" relation */
  photo: MetadataUserDefined237NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined237NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.archive" relation. */
  update?: InputMaybe<MetadataUserDefined237NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined237NestedUpsertArchiveCreateInput>;
};

export type MetadataUserDefined237NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.archive" relation. */
  update?: InputMaybe<MetadataUserDefined237NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined237NestedUpsertArchiveUpdateInput>;
};

export type MetadataUserDefined237NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined237NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined237NestedUpsertMetadataCreateInput>;
};

export type MetadataUserDefined237NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined237NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined237NestedUpsertMetadataUpdateInput>;
};

export type MetadataUserDefined237NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.photo" relation. */
  update?: InputMaybe<MetadataUserDefined237NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUserDefined237" node, through the "MetadataUserDefined237.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined237NestedUpsertPhotoCreateInput>;
};

export type MetadataUserDefined237NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.photo" relation. */
  update?: InputMaybe<MetadataUserDefined237NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUserDefined237" node, through the "MetadataUserDefined237.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined237NestedUpsertPhotoUpdateInput>;
};

export type MetadataUserDefined237NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined237NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined237NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined237NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined237NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined237NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined237NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined237NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined237NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined237NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined237NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined237NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUserDefined237OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUserDefined237UpdateInput = {
  /** Actions for the "MetadataUserDefined237.archive" relation */
  archive?: InputMaybe<MetadataUserDefined237NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined237.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined237NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined237.photo" relation */
  photo?: InputMaybe<MetadataUserDefined237NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUserDefined237" nodes by specifying some conditions */
export type MetadataUserDefined237WhereInput = {
  AND?: InputMaybe<Array<MetadataUserDefined237WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUserDefined237WhereInput>;
  OR?: InputMaybe<Array<MetadataUserDefined237WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUserDefined237" node. */
export type MetadataUserDefined237WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUserDefined237WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUserDefined237.metadata" relation */
  metadata: MetadataUserDefined237NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined237.photo" relation */
  photo: MetadataUserDefined237NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined237WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUserDefined237.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined237NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined237.photo" relation */
  photo?: InputMaybe<MetadataUserDefined237NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined237WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUserDefined237.archive" relation */
  archive: MetadataUserDefined237NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined237.photo" relation */
  photo: MetadataUserDefined237NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined237WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUserDefined237.archive" relation */
  archive?: InputMaybe<MetadataUserDefined237NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined237.photo" relation */
  photo?: InputMaybe<MetadataUserDefined237NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined237WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUserDefined237.archive" relation */
  archive: MetadataUserDefined237NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined237.metadata" relation */
  metadata: MetadataUserDefined237NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined237WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUserDefined237.archive" relation */
  archive?: InputMaybe<MetadataUserDefined237NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined237.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined237NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUserDefined238" resource's node */
export type MetadataUserDefined238 = {
  __typename?: 'MetadataUserDefined238';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUserDefined238CreateInput = {
  /** Actions for the "MetadataUserDefined238.archive" relation */
  archive: MetadataUserDefined238NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined238.metadata" relation */
  metadata: MetadataUserDefined238NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined238.photo" relation */
  photo: MetadataUserDefined238NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined238NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.archive" relation. */
  update?: InputMaybe<MetadataUserDefined238NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined238NestedUpsertArchiveCreateInput>;
};

export type MetadataUserDefined238NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.archive" relation. */
  update?: InputMaybe<MetadataUserDefined238NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined238NestedUpsertArchiveUpdateInput>;
};

export type MetadataUserDefined238NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined238NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined238NestedUpsertMetadataCreateInput>;
};

export type MetadataUserDefined238NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined238NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined238NestedUpsertMetadataUpdateInput>;
};

export type MetadataUserDefined238NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.photo" relation. */
  update?: InputMaybe<MetadataUserDefined238NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUserDefined238" node, through the "MetadataUserDefined238.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined238NestedUpsertPhotoCreateInput>;
};

export type MetadataUserDefined238NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.photo" relation. */
  update?: InputMaybe<MetadataUserDefined238NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUserDefined238" node, through the "MetadataUserDefined238.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined238NestedUpsertPhotoUpdateInput>;
};

export type MetadataUserDefined238NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined238NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined238NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined238NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined238NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined238NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined238NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined238NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined238NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined238NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined238NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined238NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUserDefined238OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUserDefined238UpdateInput = {
  /** Actions for the "MetadataUserDefined238.archive" relation */
  archive?: InputMaybe<MetadataUserDefined238NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined238.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined238NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined238.photo" relation */
  photo?: InputMaybe<MetadataUserDefined238NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUserDefined238" nodes by specifying some conditions */
export type MetadataUserDefined238WhereInput = {
  AND?: InputMaybe<Array<MetadataUserDefined238WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUserDefined238WhereInput>;
  OR?: InputMaybe<Array<MetadataUserDefined238WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUserDefined238" node. */
export type MetadataUserDefined238WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUserDefined238WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUserDefined238.metadata" relation */
  metadata: MetadataUserDefined238NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined238.photo" relation */
  photo: MetadataUserDefined238NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined238WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUserDefined238.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined238NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined238.photo" relation */
  photo?: InputMaybe<MetadataUserDefined238NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined238WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUserDefined238.archive" relation */
  archive: MetadataUserDefined238NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined238.photo" relation */
  photo: MetadataUserDefined238NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined238WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUserDefined238.archive" relation */
  archive?: InputMaybe<MetadataUserDefined238NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined238.photo" relation */
  photo?: InputMaybe<MetadataUserDefined238NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined238WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUserDefined238.archive" relation */
  archive: MetadataUserDefined238NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined238.metadata" relation */
  metadata: MetadataUserDefined238NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined238WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUserDefined238.archive" relation */
  archive?: InputMaybe<MetadataUserDefined238NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined238.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined238NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUserDefined239" resource's node */
export type MetadataUserDefined239 = {
  __typename?: 'MetadataUserDefined239';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUserDefined239CreateInput = {
  /** Actions for the "MetadataUserDefined239.archive" relation */
  archive: MetadataUserDefined239NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined239.metadata" relation */
  metadata: MetadataUserDefined239NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined239.photo" relation */
  photo: MetadataUserDefined239NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined239NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.archive" relation. */
  update?: InputMaybe<MetadataUserDefined239NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined239NestedUpsertArchiveCreateInput>;
};

export type MetadataUserDefined239NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.archive" relation. */
  update?: InputMaybe<MetadataUserDefined239NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined239NestedUpsertArchiveUpdateInput>;
};

export type MetadataUserDefined239NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined239NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined239NestedUpsertMetadataCreateInput>;
};

export type MetadataUserDefined239NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined239NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined239NestedUpsertMetadataUpdateInput>;
};

export type MetadataUserDefined239NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.photo" relation. */
  update?: InputMaybe<MetadataUserDefined239NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUserDefined239" node, through the "MetadataUserDefined239.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined239NestedUpsertPhotoCreateInput>;
};

export type MetadataUserDefined239NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.photo" relation. */
  update?: InputMaybe<MetadataUserDefined239NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUserDefined239" node, through the "MetadataUserDefined239.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined239NestedUpsertPhotoUpdateInput>;
};

export type MetadataUserDefined239NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined239NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined239NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined239NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined239NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined239NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined239NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined239NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined239NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined239NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined239NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined239NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUserDefined239OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUserDefined239UpdateInput = {
  /** Actions for the "MetadataUserDefined239.archive" relation */
  archive?: InputMaybe<MetadataUserDefined239NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined239.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined239NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined239.photo" relation */
  photo?: InputMaybe<MetadataUserDefined239NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUserDefined239" nodes by specifying some conditions */
export type MetadataUserDefined239WhereInput = {
  AND?: InputMaybe<Array<MetadataUserDefined239WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUserDefined239WhereInput>;
  OR?: InputMaybe<Array<MetadataUserDefined239WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUserDefined239" node. */
export type MetadataUserDefined239WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUserDefined239WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUserDefined239.metadata" relation */
  metadata: MetadataUserDefined239NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined239.photo" relation */
  photo: MetadataUserDefined239NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined239WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUserDefined239.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined239NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined239.photo" relation */
  photo?: InputMaybe<MetadataUserDefined239NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined239WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUserDefined239.archive" relation */
  archive: MetadataUserDefined239NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined239.photo" relation */
  photo: MetadataUserDefined239NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined239WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUserDefined239.archive" relation */
  archive?: InputMaybe<MetadataUserDefined239NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined239.photo" relation */
  photo?: InputMaybe<MetadataUserDefined239NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined239WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUserDefined239.archive" relation */
  archive: MetadataUserDefined239NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined239.metadata" relation */
  metadata: MetadataUserDefined239NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined239WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUserDefined239.archive" relation */
  archive?: InputMaybe<MetadataUserDefined239NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined239.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined239NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUserDefined242" resource's node */
export type MetadataUserDefined242 = {
  __typename?: 'MetadataUserDefined242';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUserDefined242CreateInput = {
  /** Actions for the "MetadataUserDefined242.archive" relation */
  archive: MetadataUserDefined242NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined242.metadata" relation */
  metadata: MetadataUserDefined242NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined242.photo" relation */
  photo: MetadataUserDefined242NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined242NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.archive" relation. */
  update?: InputMaybe<MetadataUserDefined242NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined242NestedUpsertArchiveCreateInput>;
};

export type MetadataUserDefined242NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.archive" relation. */
  update?: InputMaybe<MetadataUserDefined242NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined242NestedUpsertArchiveUpdateInput>;
};

export type MetadataUserDefined242NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined242NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined242NestedUpsertMetadataCreateInput>;
};

export type MetadataUserDefined242NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined242NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined242NestedUpsertMetadataUpdateInput>;
};

export type MetadataUserDefined242NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.photo" relation. */
  update?: InputMaybe<MetadataUserDefined242NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUserDefined242" node, through the "MetadataUserDefined242.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined242NestedUpsertPhotoCreateInput>;
};

export type MetadataUserDefined242NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.photo" relation. */
  update?: InputMaybe<MetadataUserDefined242NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUserDefined242" node, through the "MetadataUserDefined242.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined242NestedUpsertPhotoUpdateInput>;
};

export type MetadataUserDefined242NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined242NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined242NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined242NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined242NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined242NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined242NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined242NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined242NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined242NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined242NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined242NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUserDefined242OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUserDefined242UpdateInput = {
  /** Actions for the "MetadataUserDefined242.archive" relation */
  archive?: InputMaybe<MetadataUserDefined242NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined242.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined242NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined242.photo" relation */
  photo?: InputMaybe<MetadataUserDefined242NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUserDefined242" nodes by specifying some conditions */
export type MetadataUserDefined242WhereInput = {
  AND?: InputMaybe<Array<MetadataUserDefined242WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUserDefined242WhereInput>;
  OR?: InputMaybe<Array<MetadataUserDefined242WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUserDefined242" node. */
export type MetadataUserDefined242WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUserDefined242WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUserDefined242.metadata" relation */
  metadata: MetadataUserDefined242NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined242.photo" relation */
  photo: MetadataUserDefined242NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined242WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUserDefined242.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined242NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined242.photo" relation */
  photo?: InputMaybe<MetadataUserDefined242NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined242WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUserDefined242.archive" relation */
  archive: MetadataUserDefined242NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined242.photo" relation */
  photo: MetadataUserDefined242NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined242WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUserDefined242.archive" relation */
  archive?: InputMaybe<MetadataUserDefined242NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined242.photo" relation */
  photo?: InputMaybe<MetadataUserDefined242NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined242WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUserDefined242.archive" relation */
  archive: MetadataUserDefined242NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined242.metadata" relation */
  metadata: MetadataUserDefined242NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined242WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUserDefined242.archive" relation */
  archive?: InputMaybe<MetadataUserDefined242NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined242.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined242NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataUserDefined62" resource's node */
export type MetadataUserDefined62 = {
  __typename?: 'MetadataUserDefined62';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataUserDefined62CreateInput = {
  /** Actions for the "MetadataUserDefined62.archive" relation */
  archive: MetadataUserDefined62NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined62.metadata" relation */
  metadata: MetadataUserDefined62NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined62.photo" relation */
  photo: MetadataUserDefined62NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined62NestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.archive" relation. */
  update?: InputMaybe<MetadataUserDefined62NestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined62NestedUpsertArchiveCreateInput>;
};

export type MetadataUserDefined62NestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.archive" relation. */
  update?: InputMaybe<MetadataUserDefined62NestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.archive" relation. */
  upsert?: InputMaybe<MetadataUserDefined62NestedUpsertArchiveUpdateInput>;
};

export type MetadataUserDefined62NestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined62NestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined62NestedUpsertMetadataCreateInput>;
};

export type MetadataUserDefined62NestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.metadata" relation. */
  update?: InputMaybe<MetadataUserDefined62NestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.metadata" relation. */
  upsert?: InputMaybe<MetadataUserDefined62NestedUpsertMetadataUpdateInput>;
};

export type MetadataUserDefined62NestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.photo" relation. */
  update?: InputMaybe<MetadataUserDefined62NestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataUserDefined62" node, through the "MetadataUserDefined62.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined62NestedUpsertPhotoCreateInput>;
};

export type MetadataUserDefined62NestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.photo" relation. */
  update?: InputMaybe<MetadataUserDefined62NestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataUserDefined62" node, through the "MetadataUserDefined62.photo" relation. */
  upsert?: InputMaybe<MetadataUserDefined62NestedUpsertPhotoUpdateInput>;
};

export type MetadataUserDefined62NestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined62NestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined62NestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined62NestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined62NestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined62NestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined62NestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined62NestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataUserDefined62NestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined62NestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataUserDefined62NestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataUserDefined62NestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataUserDefined62OrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataUserDefined62UpdateInput = {
  /** Actions for the "MetadataUserDefined62.archive" relation */
  archive?: InputMaybe<MetadataUserDefined62NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined62.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined62NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined62.photo" relation */
  photo?: InputMaybe<MetadataUserDefined62NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataUserDefined62" nodes by specifying some conditions */
export type MetadataUserDefined62WhereInput = {
  AND?: InputMaybe<Array<MetadataUserDefined62WhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataUserDefined62WhereInput>;
  OR?: InputMaybe<Array<MetadataUserDefined62WhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataUserDefined62" node. */
export type MetadataUserDefined62WhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataUserDefined62WithForcedArchiveCreateInput = {
  /** Actions for the "MetadataUserDefined62.metadata" relation */
  metadata: MetadataUserDefined62NestedMetadataCreateInput;
  /** Actions for the "MetadataUserDefined62.photo" relation */
  photo: MetadataUserDefined62NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined62WithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataUserDefined62.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined62NestedMetadataUpdateInput>;
  /** Actions for the "MetadataUserDefined62.photo" relation */
  photo?: InputMaybe<MetadataUserDefined62NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined62WithForcedMetadataCreateInput = {
  /** Actions for the "MetadataUserDefined62.archive" relation */
  archive: MetadataUserDefined62NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined62.photo" relation */
  photo: MetadataUserDefined62NestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined62WithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataUserDefined62.archive" relation */
  archive?: InputMaybe<MetadataUserDefined62NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined62.photo" relation */
  photo?: InputMaybe<MetadataUserDefined62NestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined62WithForcedPhotoCreateInput = {
  /** Actions for the "MetadataUserDefined62.archive" relation */
  archive: MetadataUserDefined62NestedArchiveCreateInput;
  /** Actions for the "MetadataUserDefined62.metadata" relation */
  metadata: MetadataUserDefined62NestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataUserDefined62WithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataUserDefined62.archive" relation */
  archive?: InputMaybe<MetadataUserDefined62NestedArchiveUpdateInput>;
  /** Actions for the "MetadataUserDefined62.metadata" relation */
  metadata?: InputMaybe<MetadataUserDefined62NestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataValid" resource's node */
export type MetadataValid = {
  __typename?: 'MetadataValid';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataValidCreateInput = {
  /** Actions for the "MetadataValid.archive" relation */
  archive: MetadataValidNestedArchiveCreateInput;
  /** Actions for the "MetadataValid.metadata" relation */
  metadata: MetadataValidNestedMetadataCreateInput;
  /** Actions for the "MetadataValid.photo" relation */
  photo: MetadataValidNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataValidNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataValid" node, through the "MetadataValid.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataValid" node, through the "MetadataValid.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataValid" node, through the "MetadataValid.archive" relation. */
  update?: InputMaybe<MetadataValidNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataValid" node, through the "MetadataValid.archive" relation. */
  upsert?: InputMaybe<MetadataValidNestedUpsertArchiveCreateInput>;
};

export type MetadataValidNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataValid" node, through the "MetadataValid.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataValid" node, through the "MetadataValid.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataValid" node, through the "MetadataValid.archive" relation. */
  update?: InputMaybe<MetadataValidNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataValid" node, through the "MetadataValid.archive" relation. */
  upsert?: InputMaybe<MetadataValidNestedUpsertArchiveUpdateInput>;
};

export type MetadataValidNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataValid" node, through the "MetadataValid.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataValid" node, through the "MetadataValid.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataValid" node, through the "MetadataValid.metadata" relation. */
  update?: InputMaybe<MetadataValidNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataValid" node, through the "MetadataValid.metadata" relation. */
  upsert?: InputMaybe<MetadataValidNestedUpsertMetadataCreateInput>;
};

export type MetadataValidNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataValid" node, through the "MetadataValid.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataValid" node, through the "MetadataValid.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataValid" node, through the "MetadataValid.metadata" relation. */
  update?: InputMaybe<MetadataValidNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataValid" node, through the "MetadataValid.metadata" relation. */
  upsert?: InputMaybe<MetadataValidNestedUpsertMetadataUpdateInput>;
};

export type MetadataValidNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataValid" node, through the "MetadataValid.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataValid" node, through the "MetadataValid.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataValid" node, through the "MetadataValid.photo" relation. */
  update?: InputMaybe<MetadataValidNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataValid" node, through the "MetadataValid.photo" relation. */
  upsert?: InputMaybe<MetadataValidNestedUpsertPhotoCreateInput>;
};

export type MetadataValidNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataValid" node, through the "MetadataValid.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataValid" node, through the "MetadataValid.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataValid" node, through the "MetadataValid.photo" relation. */
  update?: InputMaybe<MetadataValidNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataValid" node, through the "MetadataValid.photo" relation. */
  upsert?: InputMaybe<MetadataValidNestedUpsertPhotoUpdateInput>;
};

export type MetadataValidNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataValidNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataValidNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataValidNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataValidNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataValidNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataValidNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataValidNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataValidNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataValidNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataValidNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataValidNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataValidOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataValidUpdateInput = {
  /** Actions for the "MetadataValid.archive" relation */
  archive?: InputMaybe<MetadataValidNestedArchiveUpdateInput>;
  /** Actions for the "MetadataValid.metadata" relation */
  metadata?: InputMaybe<MetadataValidNestedMetadataUpdateInput>;
  /** Actions for the "MetadataValid.photo" relation */
  photo?: InputMaybe<MetadataValidNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataValid" nodes by specifying some conditions */
export type MetadataValidWhereInput = {
  AND?: InputMaybe<Array<MetadataValidWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataValidWhereInput>;
  OR?: InputMaybe<Array<MetadataValidWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataValid" node. */
export type MetadataValidWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataValidWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataValid.metadata" relation */
  metadata: MetadataValidNestedMetadataCreateInput;
  /** Actions for the "MetadataValid.photo" relation */
  photo: MetadataValidNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataValidWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataValid.metadata" relation */
  metadata?: InputMaybe<MetadataValidNestedMetadataUpdateInput>;
  /** Actions for the "MetadataValid.photo" relation */
  photo?: InputMaybe<MetadataValidNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataValidWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataValid.archive" relation */
  archive: MetadataValidNestedArchiveCreateInput;
  /** Actions for the "MetadataValid.photo" relation */
  photo: MetadataValidNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataValidWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataValid.archive" relation */
  archive?: InputMaybe<MetadataValidNestedArchiveUpdateInput>;
  /** Actions for the "MetadataValid.photo" relation */
  photo?: InputMaybe<MetadataValidNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataValidWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataValid.archive" relation */
  archive: MetadataValidNestedArchiveCreateInput;
  /** Actions for the "MetadataValid.metadata" relation */
  metadata: MetadataValidNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataValidWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataValid.archive" relation */
  archive?: InputMaybe<MetadataValidNestedArchiveUpdateInput>;
  /** Actions for the "MetadataValid.metadata" relation */
  metadata?: InputMaybe<MetadataValidNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export enum MetadataValueTypeEnum {
  Date = 'Date',
  List = 'List',
  Text = 'Text'
}

/** "MetadataVersion" resource's node */
export type MetadataVersion = {
  __typename?: 'MetadataVersion';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataVersionCreateInput = {
  /** Actions for the "MetadataVersion.archive" relation */
  archive: MetadataVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataVersion.metadata" relation */
  metadata: MetadataVersionNestedMetadataCreateInput;
  /** Actions for the "MetadataVersion.photo" relation */
  photo: MetadataVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataVersionNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataVersion" node, through the "MetadataVersion.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataVersion" node, through the "MetadataVersion.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataVersion" node, through the "MetadataVersion.archive" relation. */
  update?: InputMaybe<MetadataVersionNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataVersion" node, through the "MetadataVersion.archive" relation. */
  upsert?: InputMaybe<MetadataVersionNestedUpsertArchiveCreateInput>;
};

export type MetadataVersionNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataVersion" node, through the "MetadataVersion.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataVersion" node, through the "MetadataVersion.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataVersion" node, through the "MetadataVersion.archive" relation. */
  update?: InputMaybe<MetadataVersionNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataVersion" node, through the "MetadataVersion.archive" relation. */
  upsert?: InputMaybe<MetadataVersionNestedUpsertArchiveUpdateInput>;
};

export type MetadataVersionNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataVersion" node, through the "MetadataVersion.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataVersion" node, through the "MetadataVersion.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataVersion" node, through the "MetadataVersion.metadata" relation. */
  update?: InputMaybe<MetadataVersionNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataVersion" node, through the "MetadataVersion.metadata" relation. */
  upsert?: InputMaybe<MetadataVersionNestedUpsertMetadataCreateInput>;
};

export type MetadataVersionNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataVersion" node, through the "MetadataVersion.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataVersion" node, through the "MetadataVersion.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataVersion" node, through the "MetadataVersion.metadata" relation. */
  update?: InputMaybe<MetadataVersionNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataVersion" node, through the "MetadataVersion.metadata" relation. */
  upsert?: InputMaybe<MetadataVersionNestedUpsertMetadataUpdateInput>;
};

export type MetadataVersionNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataVersion" node, through the "MetadataVersion.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataVersion" node, through the "MetadataVersion.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataVersion" node, through the "MetadataVersion.photo" relation. */
  update?: InputMaybe<MetadataVersionNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataVersion" node, through the "MetadataVersion.photo" relation. */
  upsert?: InputMaybe<MetadataVersionNestedUpsertPhotoCreateInput>;
};

export type MetadataVersionNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataVersion" node, through the "MetadataVersion.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataVersion" node, through the "MetadataVersion.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataVersion" node, through the "MetadataVersion.photo" relation. */
  update?: InputMaybe<MetadataVersionNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataVersion" node, through the "MetadataVersion.photo" relation. */
  upsert?: InputMaybe<MetadataVersionNestedUpsertPhotoUpdateInput>;
};

export type MetadataVersionNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataVersionNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataVersionNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataVersionNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataVersionNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataVersionNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataVersionNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataVersionNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataVersionNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataVersionNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataVersionNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataVersionNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataVersionOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataVersionUpdateInput = {
  /** Actions for the "MetadataVersion.archive" relation */
  archive?: InputMaybe<MetadataVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataVersion.metadata" relation */
  metadata?: InputMaybe<MetadataVersionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataVersion.photo" relation */
  photo?: InputMaybe<MetadataVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataVersion" nodes by specifying some conditions */
export type MetadataVersionWhereInput = {
  AND?: InputMaybe<Array<MetadataVersionWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataVersionWhereInput>;
  OR?: InputMaybe<Array<MetadataVersionWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataVersion" node. */
export type MetadataVersionWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataVersionWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataVersion.metadata" relation */
  metadata: MetadataVersionNestedMetadataCreateInput;
  /** Actions for the "MetadataVersion.photo" relation */
  photo: MetadataVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataVersionWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataVersion.metadata" relation */
  metadata?: InputMaybe<MetadataVersionNestedMetadataUpdateInput>;
  /** Actions for the "MetadataVersion.photo" relation */
  photo?: InputMaybe<MetadataVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataVersionWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataVersion.archive" relation */
  archive: MetadataVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataVersion.photo" relation */
  photo: MetadataVersionNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataVersionWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataVersion.archive" relation */
  archive?: InputMaybe<MetadataVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataVersion.photo" relation */
  photo?: InputMaybe<MetadataVersionNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataVersionWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataVersion.archive" relation */
  archive: MetadataVersionNestedArchiveCreateInput;
  /** Actions for the "MetadataVersion.metadata" relation */
  metadata: MetadataVersionNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataVersionWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataVersion.archive" relation */
  archive?: InputMaybe<MetadataVersionNestedArchiveUpdateInput>;
  /** Actions for the "MetadataVersion.metadata" relation */
  metadata?: InputMaybe<MetadataVersionNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataWebStatement" resource's node */
export type MetadataWebStatement = {
  __typename?: 'MetadataWebStatement';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataWebStatementCreateInput = {
  /** Actions for the "MetadataWebStatement.archive" relation */
  archive: MetadataWebStatementNestedArchiveCreateInput;
  /** Actions for the "MetadataWebStatement.metadata" relation */
  metadata: MetadataWebStatementNestedMetadataCreateInput;
  /** Actions for the "MetadataWebStatement.photo" relation */
  photo: MetadataWebStatementNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWebStatementNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataWebStatement" node, through the "MetadataWebStatement.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataWebStatement" node, through the "MetadataWebStatement.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataWebStatement" node, through the "MetadataWebStatement.archive" relation. */
  update?: InputMaybe<MetadataWebStatementNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataWebStatement" node, through the "MetadataWebStatement.archive" relation. */
  upsert?: InputMaybe<MetadataWebStatementNestedUpsertArchiveCreateInput>;
};

export type MetadataWebStatementNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.archive" relation. */
  update?: InputMaybe<MetadataWebStatementNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.archive" relation. */
  upsert?: InputMaybe<MetadataWebStatementNestedUpsertArchiveUpdateInput>;
};

export type MetadataWebStatementNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataWebStatement" node, through the "MetadataWebStatement.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataWebStatement" node, through the "MetadataWebStatement.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataWebStatement" node, through the "MetadataWebStatement.metadata" relation. */
  update?: InputMaybe<MetadataWebStatementNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataWebStatement" node, through the "MetadataWebStatement.metadata" relation. */
  upsert?: InputMaybe<MetadataWebStatementNestedUpsertMetadataCreateInput>;
};

export type MetadataWebStatementNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.metadata" relation. */
  update?: InputMaybe<MetadataWebStatementNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.metadata" relation. */
  upsert?: InputMaybe<MetadataWebStatementNestedUpsertMetadataUpdateInput>;
};

export type MetadataWebStatementNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataWebStatement" node, through the "MetadataWebStatement.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataWebStatement" node, through the "MetadataWebStatement.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataWebStatement" node, through the "MetadataWebStatement.photo" relation. */
  update?: InputMaybe<MetadataWebStatementNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataWebStatement" node, through the "MetadataWebStatement.photo" relation. */
  upsert?: InputMaybe<MetadataWebStatementNestedUpsertPhotoCreateInput>;
};

export type MetadataWebStatementNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.photo" relation. */
  update?: InputMaybe<MetadataWebStatementNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataWebStatement" node, through the "MetadataWebStatement.photo" relation. */
  upsert?: InputMaybe<MetadataWebStatementNestedUpsertPhotoUpdateInput>;
};

export type MetadataWebStatementNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataWebStatementNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataWebStatementNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataWebStatementNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataWebStatementNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataWebStatementNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataWebStatementNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataWebStatementNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataWebStatementNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataWebStatementNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataWebStatementNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataWebStatementNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataWebStatementOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataWebStatementUpdateInput = {
  /** Actions for the "MetadataWebStatement.archive" relation */
  archive?: InputMaybe<MetadataWebStatementNestedArchiveUpdateInput>;
  /** Actions for the "MetadataWebStatement.metadata" relation */
  metadata?: InputMaybe<MetadataWebStatementNestedMetadataUpdateInput>;
  /** Actions for the "MetadataWebStatement.photo" relation */
  photo?: InputMaybe<MetadataWebStatementNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataWebStatement" nodes by specifying some conditions */
export type MetadataWebStatementWhereInput = {
  AND?: InputMaybe<Array<MetadataWebStatementWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataWebStatementWhereInput>;
  OR?: InputMaybe<Array<MetadataWebStatementWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataWebStatement" node. */
export type MetadataWebStatementWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataWebStatementWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataWebStatement.metadata" relation */
  metadata: MetadataWebStatementNestedMetadataCreateInput;
  /** Actions for the "MetadataWebStatement.photo" relation */
  photo: MetadataWebStatementNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWebStatementWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataWebStatement.metadata" relation */
  metadata?: InputMaybe<MetadataWebStatementNestedMetadataUpdateInput>;
  /** Actions for the "MetadataWebStatement.photo" relation */
  photo?: InputMaybe<MetadataWebStatementNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWebStatementWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataWebStatement.archive" relation */
  archive: MetadataWebStatementNestedArchiveCreateInput;
  /** Actions for the "MetadataWebStatement.photo" relation */
  photo: MetadataWebStatementNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWebStatementWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataWebStatement.archive" relation */
  archive?: InputMaybe<MetadataWebStatementNestedArchiveUpdateInput>;
  /** Actions for the "MetadataWebStatement.photo" relation */
  photo?: InputMaybe<MetadataWebStatementNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWebStatementWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataWebStatement.archive" relation */
  archive: MetadataWebStatementNestedArchiveCreateInput;
  /** Actions for the "MetadataWebStatement.metadata" relation */
  metadata: MetadataWebStatementNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWebStatementWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataWebStatement.archive" relation */
  archive?: InputMaybe<MetadataWebStatementNestedArchiveUpdateInput>;
  /** Actions for the "MetadataWebStatement.metadata" relation */
  metadata?: InputMaybe<MetadataWebStatementNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "Metadata" nodes by specifying some conditions */
export type MetadataWhereInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  _id_gt?: InputMaybe<Scalars['Int']['input']>;
  _id_gte?: InputMaybe<Scalars['Int']['input']>;
  _id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _id_lt?: InputMaybe<Scalars['Int']['input']>;
  _id_lte?: InputMaybe<Scalars['Int']['input']>;
  _id_not?: InputMaybe<Scalars['Int']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  AND?: InputMaybe<Array<MetadataWhereInput>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  label?: InputMaybe<Scalars['String']['input']>;
  label_contains?: InputMaybe<Scalars['String']['input']>;
  label_ends_with?: InputMaybe<Scalars['String']['input']>;
  label_gt?: InputMaybe<Scalars['String']['input']>;
  label_gte?: InputMaybe<Scalars['String']['input']>;
  label_in?: InputMaybe<Array<Scalars['String']['input']>>;
  label_lt?: InputMaybe<Scalars['String']['input']>;
  label_lte?: InputMaybe<Scalars['String']['input']>;
  label_not?: InputMaybe<Scalars['String']['input']>;
  label_not_contains?: InputMaybe<Scalars['String']['input']>;
  label_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  label_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  label_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  label_starts_with?: InputMaybe<Scalars['String']['input']>;
  legacyName?: InputMaybe<Scalars['String']['input']>;
  legacyName_contains?: InputMaybe<Scalars['String']['input']>;
  legacyName_ends_with?: InputMaybe<Scalars['String']['input']>;
  legacyName_gt?: InputMaybe<Scalars['String']['input']>;
  legacyName_gte?: InputMaybe<Scalars['String']['input']>;
  legacyName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  legacyName_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  legacyName_lt?: InputMaybe<Scalars['String']['input']>;
  legacyName_lte?: InputMaybe<Scalars['String']['input']>;
  legacyName_not?: InputMaybe<Scalars['String']['input']>;
  legacyName_not_contains?: InputMaybe<Scalars['String']['input']>;
  legacyName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  legacyName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  legacyName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  legacyName_starts_with?: InputMaybe<Scalars['String']['input']>;
  metadataAccessRightsList_some?: InputMaybe<MetadataAccessRightsWhereInput>;
  metadataAddlModelInfoList_some?: InputMaybe<MetadataAddlModelInfoWhereInput>;
  metadataArchiveChildList_some?: InputMaybe<MetadataArchiveChildWhereInput>;
  metadataArchiveParentList_some?: InputMaybe<MetadataArchiveParentWhereInput>;
  metadataAssetStateList_some?: InputMaybe<MetadataAssetStateWhereInput>;
  metadataAttributionNameList_some?: InputMaybe<MetadataAttributionNameWhereInput>;
  metadataAttributionUrlList_some?: InputMaybe<MetadataAttributionUrlWhereInput>;
  metadataAudienceList_some?: InputMaybe<MetadataAudienceWhereInput>;
  metadataAuthorsPositionList_some?: InputMaybe<MetadataAuthorsPositionWhereInput>;
  metadataBackupNameList_some?: InputMaybe<MetadataBackupNameWhereInput>;
  metadataBitmapGrayscalePictureList_some?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
  metadataBrandList_some?: InputMaybe<MetadataBrandWhereInput>;
  metadataBrandPrismaList_some?: InputMaybe<MetadataBrandPrismaWhereInput>;
  metadataBrandShortNameList_some?: InputMaybe<MetadataBrandShortNameWhereInput>;
  metadataCaptionWriterList_some?: InputMaybe<MetadataCaptionWriterWhereInput>;
  metadataCategoryList_some?: InputMaybe<MetadataCategoryWhereInput>;
  metadataCategoryPrismaList_some?: InputMaybe<MetadataCategoryPrismaWhereInput>;
  metadataCertificateList_some?: InputMaybe<MetadataCertificateWhereInput>;
  metadataCiAdrCityList_some?: InputMaybe<MetadataCiAdrCityWhereInput>;
  metadataCiAdrCtryList_some?: InputMaybe<MetadataCiAdrCtryWhereInput>;
  metadataCiAdrExtadrList_some?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
  metadataCiAdrPcodeList_some?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
  metadataCiAdrRegionList_some?: InputMaybe<MetadataCiAdrRegionWhereInput>;
  metadataCiEmailWorkList_some?: InputMaybe<MetadataCiEmailWorkWhereInput>;
  metadataCiTelWorkList_some?: InputMaybe<MetadataCiTelWorkWhereInput>;
  metadataCityList_some?: InputMaybe<MetadataCityWhereInput>;
  metadataCiUrlWorkList_some?: InputMaybe<MetadataCiUrlWorkWhereInput>;
  metadataClassifyList_some?: InputMaybe<MetadataClassifyWhereInput>;
  metadataCompanyList_some?: InputMaybe<MetadataCompanyWhereInput>;
  metadataCompanyShortNameList_some?: InputMaybe<MetadataCompanyShortNameWhereInput>;
  metadataConfidentialityList_some?: InputMaybe<MetadataConfidentialityWhereInput>;
  metadataContainedInList_some?: InputMaybe<MetadataContainedInWhereInput>;
  metadataContentMediaKindList_some?: InputMaybe<MetadataContentMediaKindWhereInput>;
  metadataContentValueList_some?: InputMaybe<MetadataContentValueWhereInput>;
  metadataCopyrightLayerList_some?: InputMaybe<MetadataCopyrightLayerWhereInput>;
  metadataCountryCodeList_some?: InputMaybe<MetadataCountryCodeWhereInput>;
  metadataCountryList_some?: InputMaybe<MetadataCountryWhereInput>;
  metadataCreatedTimeList_some?: InputMaybe<MetadataCreatedTimeWhereInput>;
  metadataCreatorList_some?: InputMaybe<MetadataCreatorWhereInput>;
  metadataCreatorToolList_some?: InputMaybe<MetadataCreatorToolWhereInput>;
  metadataCreditList_some?: InputMaybe<MetadataCreditWhereInput>;
  metadataCustomField14List_some?: InputMaybe<MetadataCustomField14WhereInput>;
  metadataCustomField15List_some?: InputMaybe<MetadataCustomField15WhereInput>;
  metadataCustomField16List_some?: InputMaybe<MetadataCustomField16WhereInput>;
  metadataCustomField17List_some?: InputMaybe<MetadataCustomField17WhereInput>;
  metadataCustomField20List_some?: InputMaybe<MetadataCustomField20WhereInput>;
  metadataCustomField5List_some?: InputMaybe<MetadataCustomField5WhereInput>;
  metadataCustomField6List_some?: InputMaybe<MetadataCustomField6WhereInput>;
  metadataCustomField7List_some?: InputMaybe<MetadataCustomField7WhereInput>;
  metadataCustomField9List_some?: InputMaybe<MetadataCustomField9WhereInput>;
  metadataDateCreatedList_some?: InputMaybe<MetadataDateCreatedWhereInput>;
  metadataDateTimeDigitizedList_some?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
  metadataDateTimeOriginalList_some?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
  metadataDeliveryAccountList_some?: InputMaybe<MetadataDeliveryAccountWhereInput>;
  metadataDeliveryCompanyList_some?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
  metadataDeliveryCopyrightList_some?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
  metadataDeliveryDateFolderList_some?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
  metadataDeliveryDateTimeList_some?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
  metadataDeliveryDcCreatorList_some?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
  metadataDeliveryDcRightsList_some?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
  metadataDeliveryFileTypeList_some?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
  metadataDeliveryFolderList_some?: InputMaybe<MetadataDeliveryFolderWhereInput>;
  metadataDeliveryKindList_some?: InputMaybe<MetadataDeliveryKindWhereInput>;
  metadataDeliveryPathList_some?: InputMaybe<MetadataDeliveryPathWhereInput>;
  metadataDeliveryPersonShownintheImageList_some?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
  metadataDeliveryPhotoshopCreditList_some?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
  metadataDeliveryPhotoshopSourceList_some?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
  metadataDeliveryServiceList_some?: InputMaybe<MetadataDeliveryServiceWhereInput>;
  metadataDeliverySubjectList_some?: InputMaybe<MetadataDeliverySubjectWhereInput>;
  metadataDeliveryUnderSubjectList_some?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
  metadataDepartmentList_some?: InputMaybe<MetadataDepartmentWhereInput>;
  metadataDescriptionList_some?: InputMaybe<MetadataDescriptionWhereInput>;
  metadataDigitalAssetUrlList_some?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
  metadataDivisionList_some?: InputMaybe<MetadataDivisionWhereInput>;
  metadataDocumentTextList_some?: InputMaybe<MetadataDocumentTextWhereInput>;
  metadataEditionList_some?: InputMaybe<MetadataEditionWhereInput>;
  metadataEditorialVersionList_some?: InputMaybe<MetadataEditorialVersionWhereInput>;
  metadataEditStatusList_some?: InputMaybe<MetadataEditStatusWhereInput>;
  metadataEnvironnementPhotoList_some?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
  metadataEquipmentInstitutionList_some?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
  metadataEquipmentManufacturerList_some?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
  metadataEventList_some?: InputMaybe<MetadataEventWhereInput>;
  metadataExifList_some?: InputMaybe<MetadataExifWhereInput>;
  metadataFabStorageList_some?: InputMaybe<MetadataFabStorageWhereInput>;
  metadataFileCheckForList_some?: InputMaybe<MetadataFileCheckForWhereInput>;
  metadataFileInfoBitsPerPixelList_some?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
  metadataFileInfoFramesList_some?: InputMaybe<MetadataFileInfoFramesWhereInput>;
  metadataFileInfoPhotometricInterpretationList_some?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
  metadataFileInfoTransferSyntaxList_some?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
  metadataFileStageList_some?: InputMaybe<MetadataFileStageWhereInput>;
  metadataFileStatusList_some?: InputMaybe<MetadataFileStatusWhereInput>;
  metadataFileTypeList_some?: InputMaybe<MetadataFileTypeWhereInput>;
  metadataFixtureIdentifierList_some?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
  metadataFormatList_some?: InputMaybe<MetadataFormatWhereInput>;
  metadataGpsLatitudeList_some?: InputMaybe<MetadataGpsLatitudeWhereInput>;
  metadataGpsLongitudeList_some?: InputMaybe<MetadataGpsLongitudeWhereInput>;
  metadataGpsProcessingMethodList_some?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
  metadataHeaderList_some?: InputMaybe<MetadataHeaderWhereInput>;
  metadataHeaderPrismaList_some?: InputMaybe<MetadataHeaderPrismaWhereInput>;
  metadataHeadlineList_some?: InputMaybe<MetadataHeadlineWhereInput>;
  metadataHistoryList_some?: InputMaybe<MetadataHistoryWhereInput>;
  metadataIccProfileList_some?: InputMaybe<MetadataIccProfileWhereInput>;
  metadataIdAssignmentList_some?: InputMaybe<MetadataIdAssignmentWhereInput>;
  metadataIdMediaContentList_some?: InputMaybe<MetadataIdMediaContentWhereInput>;
  metadataIdPhotoPrismaList_some?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
  metadataIdSubjectList_some?: InputMaybe<MetadataIdSubjectWhereInput>;
  metadataImageNotesList_some?: InputMaybe<MetadataImageNotesWhereInput>;
  metadataImageUniqueIdList_some?: InputMaybe<MetadataImageUniqueIdWhereInput>;
  metadataInstructionsList_some?: InputMaybe<MetadataInstructionsWhereInput>;
  metadataIntellectualGenreList_some?: InputMaybe<MetadataIntellectualGenreWhereInput>;
  metadataIsoSpeedRatingsList_some?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
  metadataIssueList_some?: InputMaybe<MetadataIssueWhereInput>;
  metadataJobIdList_some?: InputMaybe<MetadataJobIdWhereInput>;
  metadataJobProcessingList_some?: InputMaybe<MetadataJobProcessingWhereInput>;
  metadataLayoutPictureEditingList_some?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
  metadataLayoutStorageList_some?: InputMaybe<MetadataLayoutStorageWhereInput>;
  metadataLicenseList_some?: InputMaybe<MetadataLicenseWhereInput>;
  metadataLinksList_some?: InputMaybe<MetadataLinksWhereInput>;
  metadataLocalCaptionList_some?: InputMaybe<MetadataLocalCaptionWhereInput>;
  metadataLocationList_some?: InputMaybe<MetadataLocationWhereInput>;
  metadataMakeList_some?: InputMaybe<MetadataMakeWhereInput>;
  metadataManifestList_some?: InputMaybe<MetadataManifestWhereInput>;
  metadataMarkedList_some?: InputMaybe<MetadataMarkedWhereInput>;
  metadataMasterDocumentIdList_some?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
  metadataMaxAvailHeightList_some?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
  metadataMaxAvailWidthList_some?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
  metadataMinorModelAgeDisclosureList_some?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
  metadataModelAgeList_some?: InputMaybe<MetadataModelAgeWhereInput>;
  metadataModelList_some?: InputMaybe<MetadataModelWhereInput>;
  metadataModelReleaseIdList_some?: InputMaybe<MetadataModelReleaseIdWhereInput>;
  metadataModelReleaseStatusList_some?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
  metadataMorePermissionsList_some?: InputMaybe<MetadataMorePermissionsWhereInput>;
  metadataObjectCycleList_some?: InputMaybe<MetadataObjectCycleWhereInput>;
  metadataOrganisationInImageNameList_some?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
  metadataOriginalColorList_some?: InputMaybe<MetadataOriginalColorWhereInput>;
  metadataOriginalIccProfileList_some?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
  metadataOriginalNameList_some?: InputMaybe<MetadataOriginalNameWhereInput>;
  metadataOriginalWeigthList_some?: InputMaybe<MetadataOriginalWeigthWhereInput>;
  metadataOwnerIdList_some?: InputMaybe<MetadataOwnerIdWhereInput>;
  metadataOwnerList_some?: InputMaybe<MetadataOwnerWhereInput>;
  metadataPageList_some?: InputMaybe<MetadataPageWhereInput>;
  metadataPagePrismaList_some?: InputMaybe<MetadataPagePrismaWhereInput>;
  metadataPaintBasedCorrectionsList_some?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
  metadataPatientDobList_some?: InputMaybe<MetadataPatientDobWhereInput>;
  metadataPatientIdList_some?: InputMaybe<MetadataPatientIdWhereInput>;
  metadataPatientNameList_some?: InputMaybe<MetadataPatientNameWhereInput>;
  metadataPatientSexList_some?: InputMaybe<MetadataPatientSexWhereInput>;
  metadataPersonInImageList_some?: InputMaybe<MetadataPersonInImageWhereInput>;
  metadataPhotoStorageList_some?: InputMaybe<MetadataPhotoStorageWhereInput>;
  metadataPrepressPictureEditingList_some?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
  metadataPriceLevelList_some?: InputMaybe<MetadataPriceLevelWhereInput>;
  metadataPrintingProfileList_some?: InputMaybe<MetadataPrintingProfileWhereInput>;
  metadataPrismaProductionList_some?: InputMaybe<MetadataPrismaProductionWhereInput>;
  metadataProcessHistoryList_some?: InputMaybe<MetadataProcessHistoryWhereInput>;
  metadataProcessParameterList_some?: InputMaybe<MetadataProcessParameterWhereInput>;
  metadataProcessStatusList_some?: InputMaybe<MetadataProcessStatusWhereInput>;
  metadataProductList_some?: InputMaybe<MetadataProductWhereInput>;
  metadataProductShortNameList_some?: InputMaybe<MetadataProductShortNameWhereInput>;
  metadataProductsList_some?: InputMaybe<MetadataProductsWhereInput>;
  metadataProduitsList_some?: InputMaybe<MetadataProduitsWhereInput>;
  metadataProgramVersionList_some?: InputMaybe<MetadataProgramVersionWhereInput>;
  metadataPropertyReleaseIdList_some?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
  metadataPropertyReleaseStatusList_some?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
  metadataPublisherList_some?: InputMaybe<MetadataPublisherWhereInput>;
  metadataPublishingIssueList_some?: InputMaybe<MetadataPublishingIssueWhereInput>;
  metadataPublishingSubjectList_some?: InputMaybe<MetadataPublishingSubjectWhereInput>;
  metadataQualifiedUsePrismaByList_some?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
  metadataQualifiedUsePrismaDateList_some?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
  metadataQualifiedUsePrismaDurationList_some?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
  metadataQualifiedUsePrismaSupportList_some?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
  metadataRatingList_some?: InputMaybe<MetadataRatingWhereInput>;
  metadataReferenceDateList_some?: InputMaybe<MetadataReferenceDateWhereInput>;
  metadataReferenceNumberList_some?: InputMaybe<MetadataReferenceNumberWhereInput>;
  metadataReferenceServiceList_some?: InputMaybe<MetadataReferenceServiceWhereInput>;
  metadataReleaseDateList_some?: InputMaybe<MetadataReleaseDateWhereInput>;
  metadataReleaseTimeList_some?: InputMaybe<MetadataReleaseTimeWhereInput>;
  metadataRequiredPermissionList_some?: InputMaybe<MetadataRequiredPermissionWhereInput>;
  metadataResolutionKindList_some?: InputMaybe<MetadataResolutionKindWhereInput>;
  metadataRightsList_some?: InputMaybe<MetadataRightsWhereInput>;
  metadataRoyaltyfreeList_some?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
  metadataSceneList_some?: InputMaybe<MetadataSceneWhereInput>;
  metadataSectionList_some?: InputMaybe<MetadataSectionWhereInput>;
  metadataSentToList_some?: InputMaybe<MetadataSentToWhereInput>;
  metadataSerialNumberList_some?: InputMaybe<MetadataSerialNumberWhereInput>;
  metadataSeriesDateTimeList_some?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
  metadataSeriesDescriptionList_some?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
  metadataSeriesModalityList_some?: InputMaybe<MetadataSeriesModalityWhereInput>;
  metadataSeriesNumberList_some?: InputMaybe<MetadataSeriesNumberWhereInput>;
  metadataShortUniqueIdList_some?: InputMaybe<MetadataShortUniqueIdWhereInput>;
  metadataSourceList_some?: InputMaybe<MetadataSourceWhereInput>;
  metadataStateList_some?: InputMaybe<MetadataStateWhereInput>;
  metadataStudyDateTimeList_some?: InputMaybe<MetadataStudyDateTimeWhereInput>;
  metadataStudyDescriptionList_some?: InputMaybe<MetadataStudyDescriptionWhereInput>;
  metadataStudyIdList_some?: InputMaybe<MetadataStudyIdWhereInput>;
  metadataStudyPhysicianList_some?: InputMaybe<MetadataStudyPhysicianWhereInput>;
  metadataSubjectCodeList_some?: InputMaybe<MetadataSubjectCodeWhereInput>;
  metadataSubjectList_some?: InputMaybe<MetadataSubjectWhereInput>;
  metadataSubjectPrismaList_some?: InputMaybe<MetadataSubjectPrismaWhereInput>;
  metadataSupplementalCategoriesList_some?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
  metadataTargetVersionList_some?: InputMaybe<MetadataTargetVersionWhereInput>;
  metadataTitleList_some?: InputMaybe<MetadataTitleWhereInput>;
  metadataToneCurveList_some?: InputMaybe<MetadataToneCurveWhereInput>;
  metadataTransferredByEmailList_some?: InputMaybe<MetadataTransferredByEmailWhereInput>;
  metadataTransferredByFullNameList_some?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
  metadataTransferredByList_some?: InputMaybe<MetadataTransferredByWhereInput>;
  metadataTransmissionReferenceList_some?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
  metadataTypeList_some?: InputMaybe<MetadataTypeWhereInput>;
  metadataUndersubjectList_some?: InputMaybe<MetadataUndersubjectWhereInput>;
  metadataUnderUnderSubjectList_some?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
  metadataUniqueIdList_some?: InputMaybe<MetadataUniqueIdWhereInput>;
  metadataUnitList_some?: InputMaybe<MetadataUnitWhereInput>;
  metadataUnitShortNameList_some?: InputMaybe<MetadataUnitShortNameWhereInput>;
  metadataUploadedByFullNameList_some?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
  metadataUploadedByList_some?: InputMaybe<MetadataUploadedByWhereInput>;
  metadataUploadTimeList_some?: InputMaybe<MetadataUploadTimeWhereInput>;
  metadataUrgencyList_some?: InputMaybe<MetadataUrgencyWhereInput>;
  metadataUsageTermsList_some?: InputMaybe<MetadataUsageTermsWhereInput>;
  metadataUserCommentList_some?: InputMaybe<MetadataUserCommentWhereInput>;
  metadataUserDefined195List_some?: InputMaybe<MetadataUserDefined195WhereInput>;
  metadataUserDefined237List_some?: InputMaybe<MetadataUserDefined237WhereInput>;
  metadataUserDefined238List_some?: InputMaybe<MetadataUserDefined238WhereInput>;
  metadataUserDefined239List_some?: InputMaybe<MetadataUserDefined239WhereInput>;
  metadataUserDefined242List_some?: InputMaybe<MetadataUserDefined242WhereInput>;
  metadataUserDefined62List_some?: InputMaybe<MetadataUserDefined62WhereInput>;
  metadataValidList_some?: InputMaybe<MetadataValidWhereInput>;
  metadataVersionList_some?: InputMaybe<MetadataVersionWhereInput>;
  metadataWebStatementList_some?: InputMaybe<MetadataWebStatementWhereInput>;
  metadataWorkflowKindList_some?: InputMaybe<MetadataWorkflowKindWhereInput>;
  metadataXmpFileStampsList_some?: InputMaybe<MetadataXmpFileStampsWhereInput>;
  metadataXmpHistoryList_some?: InputMaybe<MetadataXmpHistoryWhereInput>;
  name?: InputMaybe<MetadataNameEnumType>;
  name_in?: InputMaybe<Array<MetadataNameEnumType>>;
  name_not?: InputMaybe<MetadataNameEnumType>;
  name_not_in?: InputMaybe<Array<MetadataNameEnumType>>;
  namespace?: InputMaybe<Scalars['String']['input']>;
  namespace_contains?: InputMaybe<Scalars['String']['input']>;
  namespace_ends_with?: InputMaybe<Scalars['String']['input']>;
  namespace_gt?: InputMaybe<Scalars['String']['input']>;
  namespace_gte?: InputMaybe<Scalars['String']['input']>;
  namespace_in?: InputMaybe<Array<Scalars['String']['input']>>;
  namespace_lt?: InputMaybe<Scalars['String']['input']>;
  namespace_lte?: InputMaybe<Scalars['String']['input']>;
  namespace_not?: InputMaybe<Scalars['String']['input']>;
  namespace_not_contains?: InputMaybe<Scalars['String']['input']>;
  namespace_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  namespace_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  namespace_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  namespace_starts_with?: InputMaybe<Scalars['String']['input']>;
  NOT?: InputMaybe<MetadataWhereInput>;
  OR?: InputMaybe<Array<MetadataWhereInput>>;
  photoMetadatas_some?: InputMaybe<PhotoMetadataWhereInput>;
  structName?: InputMaybe<Scalars['String']['input']>;
  structName_contains?: InputMaybe<Scalars['String']['input']>;
  structName_ends_with?: InputMaybe<Scalars['String']['input']>;
  structName_gt?: InputMaybe<Scalars['String']['input']>;
  structName_gte?: InputMaybe<Scalars['String']['input']>;
  structName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structName_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  structName_lt?: InputMaybe<Scalars['String']['input']>;
  structName_lte?: InputMaybe<Scalars['String']['input']>;
  structName_not?: InputMaybe<Scalars['String']['input']>;
  structName_not_contains?: InputMaybe<Scalars['String']['input']>;
  structName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  structName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  structName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  structName_starts_with?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MetadataValueTypeEnum>;
  type_in?: InputMaybe<Array<MetadataValueTypeEnum>>;
  type_not?: InputMaybe<MetadataValueTypeEnum>;
  type_not_in?: InputMaybe<Array<MetadataValueTypeEnum>>;
};

/**
 * Identifies exactly one "Metadata" node with one of these unique values:
 * - _id
 * - id
 * - name
 */
export type MetadataWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<MetadataNameEnumType>;
};

/** "MetadataWorkflowKind" resource's node */
export type MetadataWorkflowKind = {
  __typename?: 'MetadataWorkflowKind';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataWorkflowKindCreateInput = {
  /** Actions for the "MetadataWorkflowKind.archive" relation */
  archive: MetadataWorkflowKindNestedArchiveCreateInput;
  /** Actions for the "MetadataWorkflowKind.metadata" relation */
  metadata: MetadataWorkflowKindNestedMetadataCreateInput;
  /** Actions for the "MetadataWorkflowKind.photo" relation */
  photo: MetadataWorkflowKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWorkflowKindNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.archive" relation. */
  update?: InputMaybe<MetadataWorkflowKindNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.archive" relation. */
  upsert?: InputMaybe<MetadataWorkflowKindNestedUpsertArchiveCreateInput>;
};

export type MetadataWorkflowKindNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.archive" relation. */
  update?: InputMaybe<MetadataWorkflowKindNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.archive" relation. */
  upsert?: InputMaybe<MetadataWorkflowKindNestedUpsertArchiveUpdateInput>;
};

export type MetadataWorkflowKindNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.metadata" relation. */
  update?: InputMaybe<MetadataWorkflowKindNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.metadata" relation. */
  upsert?: InputMaybe<MetadataWorkflowKindNestedUpsertMetadataCreateInput>;
};

export type MetadataWorkflowKindNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.metadata" relation. */
  update?: InputMaybe<MetadataWorkflowKindNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.metadata" relation. */
  upsert?: InputMaybe<MetadataWorkflowKindNestedUpsertMetadataUpdateInput>;
};

export type MetadataWorkflowKindNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.photo" relation. */
  update?: InputMaybe<MetadataWorkflowKindNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.photo" relation. */
  upsert?: InputMaybe<MetadataWorkflowKindNestedUpsertPhotoCreateInput>;
};

export type MetadataWorkflowKindNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.photo" relation. */
  update?: InputMaybe<MetadataWorkflowKindNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataWorkflowKind" node, through the "MetadataWorkflowKind.photo" relation. */
  upsert?: InputMaybe<MetadataWorkflowKindNestedUpsertPhotoUpdateInput>;
};

export type MetadataWorkflowKindNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataWorkflowKindNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataWorkflowKindNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataWorkflowKindNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataWorkflowKindNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataWorkflowKindNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataWorkflowKindNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataWorkflowKindNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataWorkflowKindNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataWorkflowKindNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataWorkflowKindNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataWorkflowKindNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataWorkflowKindOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataWorkflowKindUpdateInput = {
  /** Actions for the "MetadataWorkflowKind.archive" relation */
  archive?: InputMaybe<MetadataWorkflowKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataWorkflowKind.metadata" relation */
  metadata?: InputMaybe<MetadataWorkflowKindNestedMetadataUpdateInput>;
  /** Actions for the "MetadataWorkflowKind.photo" relation */
  photo?: InputMaybe<MetadataWorkflowKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataWorkflowKind" nodes by specifying some conditions */
export type MetadataWorkflowKindWhereInput = {
  AND?: InputMaybe<Array<MetadataWorkflowKindWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataWorkflowKindWhereInput>;
  OR?: InputMaybe<Array<MetadataWorkflowKindWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataWorkflowKind" node. */
export type MetadataWorkflowKindWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataWorkflowKindWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataWorkflowKind.metadata" relation */
  metadata: MetadataWorkflowKindNestedMetadataCreateInput;
  /** Actions for the "MetadataWorkflowKind.photo" relation */
  photo: MetadataWorkflowKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWorkflowKindWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataWorkflowKind.metadata" relation */
  metadata?: InputMaybe<MetadataWorkflowKindNestedMetadataUpdateInput>;
  /** Actions for the "MetadataWorkflowKind.photo" relation */
  photo?: InputMaybe<MetadataWorkflowKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWorkflowKindWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataWorkflowKind.archive" relation */
  archive: MetadataWorkflowKindNestedArchiveCreateInput;
  /** Actions for the "MetadataWorkflowKind.photo" relation */
  photo: MetadataWorkflowKindNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWorkflowKindWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataWorkflowKind.archive" relation */
  archive?: InputMaybe<MetadataWorkflowKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataWorkflowKind.photo" relation */
  photo?: InputMaybe<MetadataWorkflowKindNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWorkflowKindWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataWorkflowKind.archive" relation */
  archive: MetadataWorkflowKindNestedArchiveCreateInput;
  /** Actions for the "MetadataWorkflowKind.metadata" relation */
  metadata: MetadataWorkflowKindNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataWorkflowKindWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataWorkflowKind.archive" relation */
  archive?: InputMaybe<MetadataWorkflowKindNestedArchiveUpdateInput>;
  /** Actions for the "MetadataWorkflowKind.metadata" relation */
  metadata?: InputMaybe<MetadataWorkflowKindNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataXmpFileStamps" resource's node */
export type MetadataXmpFileStamps = {
  __typename?: 'MetadataXmpFileStamps';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataXmpFileStampsCreateInput = {
  /** Actions for the "MetadataXmpFileStamps.archive" relation */
  archive: MetadataXmpFileStampsNestedArchiveCreateInput;
  /** Actions for the "MetadataXmpFileStamps.metadata" relation */
  metadata: MetadataXmpFileStampsNestedMetadataCreateInput;
  /** Actions for the "MetadataXmpFileStamps.photo" relation */
  photo: MetadataXmpFileStampsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpFileStampsNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.archive" relation. */
  update?: InputMaybe<MetadataXmpFileStampsNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.archive" relation. */
  upsert?: InputMaybe<MetadataXmpFileStampsNestedUpsertArchiveCreateInput>;
};

export type MetadataXmpFileStampsNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.archive" relation. */
  update?: InputMaybe<MetadataXmpFileStampsNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.archive" relation. */
  upsert?: InputMaybe<MetadataXmpFileStampsNestedUpsertArchiveUpdateInput>;
};

export type MetadataXmpFileStampsNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.metadata" relation. */
  update?: InputMaybe<MetadataXmpFileStampsNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.metadata" relation. */
  upsert?: InputMaybe<MetadataXmpFileStampsNestedUpsertMetadataCreateInput>;
};

export type MetadataXmpFileStampsNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.metadata" relation. */
  update?: InputMaybe<MetadataXmpFileStampsNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.metadata" relation. */
  upsert?: InputMaybe<MetadataXmpFileStampsNestedUpsertMetadataUpdateInput>;
};

export type MetadataXmpFileStampsNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.photo" relation. */
  update?: InputMaybe<MetadataXmpFileStampsNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.photo" relation. */
  upsert?: InputMaybe<MetadataXmpFileStampsNestedUpsertPhotoCreateInput>;
};

export type MetadataXmpFileStampsNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.photo" relation. */
  update?: InputMaybe<MetadataXmpFileStampsNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataXmpFileStamps" node, through the "MetadataXmpFileStamps.photo" relation. */
  upsert?: InputMaybe<MetadataXmpFileStampsNestedUpsertPhotoUpdateInput>;
};

export type MetadataXmpFileStampsNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataXmpFileStampsNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataXmpFileStampsNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataXmpFileStampsNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataXmpFileStampsNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataXmpFileStampsNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataXmpFileStampsNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataXmpFileStampsNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataXmpFileStampsNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataXmpFileStampsNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataXmpFileStampsNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataXmpFileStampsNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataXmpFileStampsOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataXmpFileStampsUpdateInput = {
  /** Actions for the "MetadataXmpFileStamps.archive" relation */
  archive?: InputMaybe<MetadataXmpFileStampsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataXmpFileStamps.metadata" relation */
  metadata?: InputMaybe<MetadataXmpFileStampsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataXmpFileStamps.photo" relation */
  photo?: InputMaybe<MetadataXmpFileStampsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataXmpFileStamps" nodes by specifying some conditions */
export type MetadataXmpFileStampsWhereInput = {
  AND?: InputMaybe<Array<MetadataXmpFileStampsWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataXmpFileStampsWhereInput>;
  OR?: InputMaybe<Array<MetadataXmpFileStampsWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataXmpFileStamps" node. */
export type MetadataXmpFileStampsWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataXmpFileStampsWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataXmpFileStamps.metadata" relation */
  metadata: MetadataXmpFileStampsNestedMetadataCreateInput;
  /** Actions for the "MetadataXmpFileStamps.photo" relation */
  photo: MetadataXmpFileStampsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpFileStampsWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataXmpFileStamps.metadata" relation */
  metadata?: InputMaybe<MetadataXmpFileStampsNestedMetadataUpdateInput>;
  /** Actions for the "MetadataXmpFileStamps.photo" relation */
  photo?: InputMaybe<MetadataXmpFileStampsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpFileStampsWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataXmpFileStamps.archive" relation */
  archive: MetadataXmpFileStampsNestedArchiveCreateInput;
  /** Actions for the "MetadataXmpFileStamps.photo" relation */
  photo: MetadataXmpFileStampsNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpFileStampsWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataXmpFileStamps.archive" relation */
  archive?: InputMaybe<MetadataXmpFileStampsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataXmpFileStamps.photo" relation */
  photo?: InputMaybe<MetadataXmpFileStampsNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpFileStampsWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataXmpFileStamps.archive" relation */
  archive: MetadataXmpFileStampsNestedArchiveCreateInput;
  /** Actions for the "MetadataXmpFileStamps.metadata" relation */
  metadata: MetadataXmpFileStampsNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpFileStampsWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataXmpFileStamps.archive" relation */
  archive?: InputMaybe<MetadataXmpFileStampsNestedArchiveUpdateInput>;
  /** Actions for the "MetadataXmpFileStamps.metadata" relation */
  metadata?: InputMaybe<MetadataXmpFileStampsNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** "MetadataXmpHistory" resource's node */
export type MetadataXmpHistory = {
  __typename?: 'MetadataXmpHistory';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type MetadataXmpHistoryCreateInput = {
  /** Actions for the "MetadataXmpHistory.archive" relation */
  archive: MetadataXmpHistoryNestedArchiveCreateInput;
  /** Actions for the "MetadataXmpHistory.metadata" relation */
  metadata: MetadataXmpHistoryNestedMetadataCreateInput;
  /** Actions for the "MetadataXmpHistory.photo" relation */
  photo: MetadataXmpHistoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpHistoryNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.archive" relation. */
  update?: InputMaybe<MetadataXmpHistoryNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.archive" relation. */
  upsert?: InputMaybe<MetadataXmpHistoryNestedUpsertArchiveCreateInput>;
};

export type MetadataXmpHistoryNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.archive" relation. */
  update?: InputMaybe<MetadataXmpHistoryNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.archive" relation. */
  upsert?: InputMaybe<MetadataXmpHistoryNestedUpsertArchiveUpdateInput>;
};

export type MetadataXmpHistoryNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.metadata" relation. */
  update?: InputMaybe<MetadataXmpHistoryNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.metadata" relation. */
  upsert?: InputMaybe<MetadataXmpHistoryNestedUpsertMetadataCreateInput>;
};

export type MetadataXmpHistoryNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.metadata" relation. */
  update?: InputMaybe<MetadataXmpHistoryNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.metadata" relation. */
  upsert?: InputMaybe<MetadataXmpHistoryNestedUpsertMetadataUpdateInput>;
};

export type MetadataXmpHistoryNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.photo" relation. */
  update?: InputMaybe<MetadataXmpHistoryNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "MetadataXmpHistory" node, through the "MetadataXmpHistory.photo" relation. */
  upsert?: InputMaybe<MetadataXmpHistoryNestedUpsertPhotoCreateInput>;
};

export type MetadataXmpHistoryNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.photo" relation. */
  update?: InputMaybe<MetadataXmpHistoryNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "MetadataXmpHistory" node, through the "MetadataXmpHistory.photo" relation. */
  upsert?: InputMaybe<MetadataXmpHistoryNestedUpsertPhotoUpdateInput>;
};

export type MetadataXmpHistoryNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataXmpHistoryNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataXmpHistoryNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataXmpHistoryNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataXmpHistoryNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataXmpHistoryNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataXmpHistoryNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataXmpHistoryNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type MetadataXmpHistoryNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataXmpHistoryNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type MetadataXmpHistoryNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type MetadataXmpHistoryNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum MetadataXmpHistoryOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type MetadataXmpHistoryUpdateInput = {
  /** Actions for the "MetadataXmpHistory.archive" relation */
  archive?: InputMaybe<MetadataXmpHistoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataXmpHistory.metadata" relation */
  metadata?: InputMaybe<MetadataXmpHistoryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataXmpHistory.photo" relation */
  photo?: InputMaybe<MetadataXmpHistoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "MetadataXmpHistory" nodes by specifying some conditions */
export type MetadataXmpHistoryWhereInput = {
  AND?: InputMaybe<Array<MetadataXmpHistoryWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<MetadataXmpHistoryWhereInput>;
  OR?: InputMaybe<Array<MetadataXmpHistoryWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "MetadataXmpHistory" node. */
export type MetadataXmpHistoryWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type MetadataXmpHistoryWithForcedArchiveCreateInput = {
  /** Actions for the "MetadataXmpHistory.metadata" relation */
  metadata: MetadataXmpHistoryNestedMetadataCreateInput;
  /** Actions for the "MetadataXmpHistory.photo" relation */
  photo: MetadataXmpHistoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpHistoryWithForcedArchiveUpdateInput = {
  /** Actions for the "MetadataXmpHistory.metadata" relation */
  metadata?: InputMaybe<MetadataXmpHistoryNestedMetadataUpdateInput>;
  /** Actions for the "MetadataXmpHistory.photo" relation */
  photo?: InputMaybe<MetadataXmpHistoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpHistoryWithForcedMetadataCreateInput = {
  /** Actions for the "MetadataXmpHistory.archive" relation */
  archive: MetadataXmpHistoryNestedArchiveCreateInput;
  /** Actions for the "MetadataXmpHistory.photo" relation */
  photo: MetadataXmpHistoryNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpHistoryWithForcedMetadataUpdateInput = {
  /** Actions for the "MetadataXmpHistory.archive" relation */
  archive?: InputMaybe<MetadataXmpHistoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataXmpHistory.photo" relation */
  photo?: InputMaybe<MetadataXmpHistoryNestedPhotoUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpHistoryWithForcedPhotoCreateInput = {
  /** Actions for the "MetadataXmpHistory.archive" relation */
  archive: MetadataXmpHistoryNestedArchiveCreateInput;
  /** Actions for the "MetadataXmpHistory.metadata" relation */
  metadata: MetadataXmpHistoryNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type MetadataXmpHistoryWithForcedPhotoUpdateInput = {
  /** Actions for the "MetadataXmpHistory.archive" relation */
  archive?: InputMaybe<MetadataXmpHistoryNestedArchiveUpdateInput>;
  /** Actions for the "MetadataXmpHistory.metadata" relation */
  metadata?: InputMaybe<MetadataXmpHistoryNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** clone a photo */
  clonePhoto: Photo;
  /** Create a single "Agency" node. */
  createAgency: Agency;
  /** Create a single "Archive" node. */
  createArchive: Archive;
  /** Create a single "ArchiveAgency" node. */
  createArchiveAgency: ArchiveAgency;
  /** Create a single "Category" node. */
  createCategory: Category;
  /** Create a single "DeliverySubject" node. */
  createDeliverySubject: DeliverySubject;
  /** Create a single "GcImportSetting" node. */
  createGcImportSetting: GcImportSetting;
  /** Create a single "ImportQueue" node. */
  createImportQueue: ImportQueue;
  /** Create a single "Metadata" node. */
  createMetadata: Metadata;
  /** Create a single "MetadataAccessRights" node. */
  createMetadataAccessRights: MetadataAccessRights;
  /** Create a single "MetadataAddlModelInfo" node. */
  createMetadataAddlModelInfo: MetadataAddlModelInfo;
  /** Create a single "MetadataArchiveChild" node. */
  createMetadataArchiveChild: MetadataArchiveChild;
  /** Create a single "MetadataArchiveParent" node. */
  createMetadataArchiveParent: MetadataArchiveParent;
  /** Create a single "MetadataAssetState" node. */
  createMetadataAssetState: MetadataAssetState;
  /** Create a single "MetadataAttributionName" node. */
  createMetadataAttributionName: MetadataAttributionName;
  /** Create a single "MetadataAttributionUrl" node. */
  createMetadataAttributionUrl: MetadataAttributionUrl;
  /** Create a single "MetadataAudience" node. */
  createMetadataAudience: MetadataAudience;
  /** Create a single "MetadataAuthorsPosition" node. */
  createMetadataAuthorsPosition: MetadataAuthorsPosition;
  /** Create a single "MetadataBackupName" node. */
  createMetadataBackupName: MetadataBackupName;
  /** Create a single "MetadataBitmapGrayscalePicture" node. */
  createMetadataBitmapGrayscalePicture: MetadataBitmapGrayscalePicture;
  /** Create a single "MetadataBrand" node. */
  createMetadataBrand: MetadataBrand;
  /** Create a single "MetadataBrandPrisma" node. */
  createMetadataBrandPrisma: MetadataBrandPrisma;
  /** Create a single "MetadataBrandShortName" node. */
  createMetadataBrandShortName: MetadataBrandShortName;
  /** Create a single "MetadataCaptionWriter" node. */
  createMetadataCaptionWriter: MetadataCaptionWriter;
  /** Create a single "MetadataCategory" node. */
  createMetadataCategory: MetadataCategory;
  /** Create a single "MetadataCategoryPrisma" node. */
  createMetadataCategoryPrisma: MetadataCategoryPrisma;
  /** Create a single "MetadataCertificate" node. */
  createMetadataCertificate: MetadataCertificate;
  /** Create a single "MetadataCiAdrCity" node. */
  createMetadataCiAdrCity: MetadataCiAdrCity;
  /** Create a single "MetadataCiAdrCtry" node. */
  createMetadataCiAdrCtry: MetadataCiAdrCtry;
  /** Create a single "MetadataCiAdrExtadr" node. */
  createMetadataCiAdrExtadr: MetadataCiAdrExtadr;
  /** Create a single "MetadataCiAdrPcode" node. */
  createMetadataCiAdrPcode: MetadataCiAdrPcode;
  /** Create a single "MetadataCiAdrRegion" node. */
  createMetadataCiAdrRegion: MetadataCiAdrRegion;
  /** Create a single "MetadataCiEmailWork" node. */
  createMetadataCiEmailWork: MetadataCiEmailWork;
  /** Create a single "MetadataCiTelWork" node. */
  createMetadataCiTelWork: MetadataCiTelWork;
  /** Create a single "MetadataCity" node. */
  createMetadataCity: MetadataCity;
  /** Create a single "MetadataCiUrlWork" node. */
  createMetadataCiUrlWork: MetadataCiUrlWork;
  /** Create a single "MetadataClassify" node. */
  createMetadataClassify: MetadataClassify;
  /** Create a single "MetadataCompany" node. */
  createMetadataCompany: MetadataCompany;
  /** Create a single "MetadataCompanyShortName" node. */
  createMetadataCompanyShortName: MetadataCompanyShortName;
  /** Create a single "MetadataConfidentiality" node. */
  createMetadataConfidentiality: MetadataConfidentiality;
  /** Create a single "MetadataContainedIn" node. */
  createMetadataContainedIn: MetadataContainedIn;
  /** Create a single "MetadataContentMediaKind" node. */
  createMetadataContentMediaKind: MetadataContentMediaKind;
  /** Create a single "MetadataContentValue" node. */
  createMetadataContentValue: MetadataContentValue;
  /** Create a single "MetadataCopyrightLayer" node. */
  createMetadataCopyrightLayer: MetadataCopyrightLayer;
  /** Create a single "MetadataCountry" node. */
  createMetadataCountry: MetadataCountry;
  /** Create a single "MetadataCountryCode" node. */
  createMetadataCountryCode: MetadataCountryCode;
  /** Create a single "MetadataCreatedTime" node. */
  createMetadataCreatedTime: MetadataCreatedTime;
  /** Create a single "MetadataCreator" node. */
  createMetadataCreator: MetadataCreator;
  /** Create a single "MetadataCreatorTool" node. */
  createMetadataCreatorTool: MetadataCreatorTool;
  /** Create a single "MetadataCredit" node. */
  createMetadataCredit: MetadataCredit;
  /** Create a single "MetadataCustomField14" node. */
  createMetadataCustomField14: MetadataCustomField14;
  /** Create a single "MetadataCustomField15" node. */
  createMetadataCustomField15: MetadataCustomField15;
  /** Create a single "MetadataCustomField16" node. */
  createMetadataCustomField16: MetadataCustomField16;
  /** Create a single "MetadataCustomField17" node. */
  createMetadataCustomField17: MetadataCustomField17;
  /** Create a single "MetadataCustomField20" node. */
  createMetadataCustomField20: MetadataCustomField20;
  /** Create a single "MetadataCustomField5" node. */
  createMetadataCustomField5: MetadataCustomField5;
  /** Create a single "MetadataCustomField6" node. */
  createMetadataCustomField6: MetadataCustomField6;
  /** Create a single "MetadataCustomField7" node. */
  createMetadataCustomField7: MetadataCustomField7;
  /** Create a single "MetadataCustomField9" node. */
  createMetadataCustomField9: MetadataCustomField9;
  /** Create a single "MetadataDateCreated" node. */
  createMetadataDateCreated: MetadataDateCreated;
  /** Create a single "MetadataDateTimeDigitized" node. */
  createMetadataDateTimeDigitized: MetadataDateTimeDigitized;
  /** Create a single "MetadataDateTimeOriginal" node. */
  createMetadataDateTimeOriginal: MetadataDateTimeOriginal;
  /** Create a single "MetadataDeliveryAccount" node. */
  createMetadataDeliveryAccount: MetadataDeliveryAccount;
  /** Create a single "MetadataDeliveryCompany" node. */
  createMetadataDeliveryCompany: MetadataDeliveryCompany;
  /** Create a single "MetadataDeliveryCopyright" node. */
  createMetadataDeliveryCopyright: MetadataDeliveryCopyright;
  /** Create a single "MetadataDeliveryDateFolder" node. */
  createMetadataDeliveryDateFolder: MetadataDeliveryDateFolder;
  /** Create a single "MetadataDeliveryDateTime" node. */
  createMetadataDeliveryDateTime: MetadataDeliveryDateTime;
  /** Create a single "MetadataDeliveryDcCreator" node. */
  createMetadataDeliveryDcCreator: MetadataDeliveryDcCreator;
  /** Create a single "MetadataDeliveryDcRights" node. */
  createMetadataDeliveryDcRights: MetadataDeliveryDcRights;
  /** Create a single "MetadataDeliveryFileType" node. */
  createMetadataDeliveryFileType: MetadataDeliveryFileType;
  /** Create a single "MetadataDeliveryFolder" node. */
  createMetadataDeliveryFolder: MetadataDeliveryFolder;
  /** Create a single "MetadataDeliveryKind" node. */
  createMetadataDeliveryKind: MetadataDeliveryKind;
  /** Create a single "MetadataDeliveryPath" node. */
  createMetadataDeliveryPath: MetadataDeliveryPath;
  /** Create a single "MetadataDeliveryPersonShownintheImage" node. */
  createMetadataDeliveryPersonShownintheImage: MetadataDeliveryPersonShownintheImage;
  /** Create a single "MetadataDeliveryPhotoshopCredit" node. */
  createMetadataDeliveryPhotoshopCredit: MetadataDeliveryPhotoshopCredit;
  /** Create a single "MetadataDeliveryPhotoshopSource" node. */
  createMetadataDeliveryPhotoshopSource: MetadataDeliveryPhotoshopSource;
  /** Create a single "MetadataDeliveryService" node. */
  createMetadataDeliveryService: MetadataDeliveryService;
  /** Create a single "MetadataDeliverySubject" node. */
  createMetadataDeliverySubject: MetadataDeliverySubject;
  /** Create a single "MetadataDeliveryUnderSubject" node. */
  createMetadataDeliveryUnderSubject: MetadataDeliveryUnderSubject;
  /** Create a single "MetadataDepartment" node. */
  createMetadataDepartment: MetadataDepartment;
  /** Create a single "MetadataDescription" node. */
  createMetadataDescription: MetadataDescription;
  /** Create a single "MetadataDigitalAssetUrl" node. */
  createMetadataDigitalAssetUrl: MetadataDigitalAssetUrl;
  /** Create a single "MetadataDivision" node. */
  createMetadataDivision: MetadataDivision;
  /** Create a single "MetadataDocumentText" node. */
  createMetadataDocumentText: MetadataDocumentText;
  /** Create a single "MetadataEdition" node. */
  createMetadataEdition: MetadataEdition;
  /** Create a single "MetadataEditorialVersion" node. */
  createMetadataEditorialVersion: MetadataEditorialVersion;
  /** Create a single "MetadataEditStatus" node. */
  createMetadataEditStatus: MetadataEditStatus;
  /** Create a single "MetadataEnvironnementPhoto" node. */
  createMetadataEnvironnementPhoto: MetadataEnvironnementPhoto;
  /** Create a single "MetadataEquipmentInstitution" node. */
  createMetadataEquipmentInstitution: MetadataEquipmentInstitution;
  /** Create a single "MetadataEquipmentManufacturer" node. */
  createMetadataEquipmentManufacturer: MetadataEquipmentManufacturer;
  /** Create a single "MetadataEvent" node. */
  createMetadataEvent: MetadataEvent;
  /** Create a single "MetadataExif" node. */
  createMetadataExif: MetadataExif;
  /** Create a single "MetadataFabStorage" node. */
  createMetadataFabStorage: MetadataFabStorage;
  /** Create a single "MetadataFileCheckFor" node. */
  createMetadataFileCheckFor: MetadataFileCheckFor;
  /** Create a single "MetadataFileInfoBitsPerPixel" node. */
  createMetadataFileInfoBitsPerPixel: MetadataFileInfoBitsPerPixel;
  /** Create a single "MetadataFileInfoFrames" node. */
  createMetadataFileInfoFrames: MetadataFileInfoFrames;
  /** Create a single "MetadataFileInfoPhotometricInterpretation" node. */
  createMetadataFileInfoPhotometricInterpretation: MetadataFileInfoPhotometricInterpretation;
  /** Create a single "MetadataFileInfoTransferSyntax" node. */
  createMetadataFileInfoTransferSyntax: MetadataFileInfoTransferSyntax;
  /** Create a single "MetadataFileStage" node. */
  createMetadataFileStage: MetadataFileStage;
  /** Create a single "MetadataFileStatus" node. */
  createMetadataFileStatus: MetadataFileStatus;
  /** Create a single "MetadataFileType" node. */
  createMetadataFileType: MetadataFileType;
  /** Create a single "MetadataFixtureIdentifier" node. */
  createMetadataFixtureIdentifier: MetadataFixtureIdentifier;
  /** Create a single "MetadataFormat" node. */
  createMetadataFormat: MetadataFormat;
  /** Create a single "MetadataGpsLatitude" node. */
  createMetadataGpsLatitude: MetadataGpsLatitude;
  /** Create a single "MetadataGpsLongitude" node. */
  createMetadataGpsLongitude: MetadataGpsLongitude;
  /** Create a single "MetadataGpsProcessingMethod" node. */
  createMetadataGpsProcessingMethod: MetadataGpsProcessingMethod;
  /** Create a single "MetadataHeader" node. */
  createMetadataHeader: MetadataHeader;
  /** Create a single "MetadataHeaderPrisma" node. */
  createMetadataHeaderPrisma: MetadataHeaderPrisma;
  /** Create a single "MetadataHeadline" node. */
  createMetadataHeadline: MetadataHeadline;
  /** Create a single "MetadataHistory" node. */
  createMetadataHistory: MetadataHistory;
  /** Create a single "MetadataIccProfile" node. */
  createMetadataIccProfile: MetadataIccProfile;
  /** Create a single "MetadataIdAssignment" node. */
  createMetadataIdAssignment: MetadataIdAssignment;
  /** Create a single "MetadataIdMediaContent" node. */
  createMetadataIdMediaContent: MetadataIdMediaContent;
  /** Create a single "MetadataIdPhotoPrisma" node. */
  createMetadataIdPhotoPrisma: MetadataIdPhotoPrisma;
  /** Create a single "MetadataIdSubject" node. */
  createMetadataIdSubject: MetadataIdSubject;
  /** Create a single "MetadataImageNotes" node. */
  createMetadataImageNotes: MetadataImageNotes;
  /** Create a single "MetadataImageUniqueId" node. */
  createMetadataImageUniqueId: MetadataImageUniqueId;
  /** Create a single "MetadataInstructions" node. */
  createMetadataInstructions: MetadataInstructions;
  /** Create a single "MetadataIntellectualGenre" node. */
  createMetadataIntellectualGenre: MetadataIntellectualGenre;
  /** Create a single "MetadataIsoSpeedRatings" node. */
  createMetadataIsoSpeedRatings: MetadataIsoSpeedRatings;
  /** Create a single "MetadataIssue" node. */
  createMetadataIssue: MetadataIssue;
  /** Create a single "MetadataJobId" node. */
  createMetadataJobId: MetadataJobId;
  /** Create a single "MetadataJobProcessing" node. */
  createMetadataJobProcessing: MetadataJobProcessing;
  /** Create a single "MetadataLayoutPictureEditing" node. */
  createMetadataLayoutPictureEditing: MetadataLayoutPictureEditing;
  /** Create a single "MetadataLayoutStorage" node. */
  createMetadataLayoutStorage: MetadataLayoutStorage;
  /** Create a single "MetadataLicense" node. */
  createMetadataLicense: MetadataLicense;
  /** Create a single "MetadataLinks" node. */
  createMetadataLinks: MetadataLinks;
  /** Create a single "MetadataLocalCaption" node. */
  createMetadataLocalCaption: MetadataLocalCaption;
  /** Create a single "MetadataLocation" node. */
  createMetadataLocation: MetadataLocation;
  /** Create a single "MetadataMake" node. */
  createMetadataMake: MetadataMake;
  /** Create a single "MetadataManifest" node. */
  createMetadataManifest: MetadataManifest;
  /** Create a single "MetadataMarked" node. */
  createMetadataMarked: MetadataMarked;
  /** Create a single "MetadataMasterDocumentId" node. */
  createMetadataMasterDocumentId: MetadataMasterDocumentId;
  /** Create a single "MetadataMaxAvailHeight" node. */
  createMetadataMaxAvailHeight: MetadataMaxAvailHeight;
  /** Create a single "MetadataMaxAvailWidth" node. */
  createMetadataMaxAvailWidth: MetadataMaxAvailWidth;
  /** Create a single "MetadataMinorModelAgeDisclosure" node. */
  createMetadataMinorModelAgeDisclosure: MetadataMinorModelAgeDisclosure;
  /** Create a single "MetadataModel" node. */
  createMetadataModel: MetadataModel;
  /** Create a single "MetadataModelAge" node. */
  createMetadataModelAge: MetadataModelAge;
  /** Create a single "MetadataModelReleaseId" node. */
  createMetadataModelReleaseId: MetadataModelReleaseId;
  /** Create a single "MetadataModelReleaseStatus" node. */
  createMetadataModelReleaseStatus: MetadataModelReleaseStatus;
  /** Create a single "MetadataMorePermissions" node. */
  createMetadataMorePermissions: MetadataMorePermissions;
  /** Create a single "MetadataObjectCycle" node. */
  createMetadataObjectCycle: MetadataObjectCycle;
  /** Create a single "MetadataOrganisationInImageName" node. */
  createMetadataOrganisationInImageName: MetadataOrganisationInImageName;
  /** Create a single "MetadataOriginalColor" node. */
  createMetadataOriginalColor: MetadataOriginalColor;
  /** Create a single "MetadataOriginalIccProfile" node. */
  createMetadataOriginalIccProfile: MetadataOriginalIccProfile;
  /** Create a single "MetadataOriginalName" node. */
  createMetadataOriginalName: MetadataOriginalName;
  /** Create a single "MetadataOriginalWeigth" node. */
  createMetadataOriginalWeigth: MetadataOriginalWeigth;
  /** Create a single "MetadataOwner" node. */
  createMetadataOwner: MetadataOwner;
  /** Create a single "MetadataOwnerId" node. */
  createMetadataOwnerId: MetadataOwnerId;
  /** Create a single "MetadataPage" node. */
  createMetadataPage: MetadataPage;
  /** Create a single "MetadataPagePrisma" node. */
  createMetadataPagePrisma: MetadataPagePrisma;
  /** Create a single "MetadataPaintBasedCorrections" node. */
  createMetadataPaintBasedCorrections: MetadataPaintBasedCorrections;
  /** Create a single "MetadataPatientDob" node. */
  createMetadataPatientDob: MetadataPatientDob;
  /** Create a single "MetadataPatientId" node. */
  createMetadataPatientId: MetadataPatientId;
  /** Create a single "MetadataPatientName" node. */
  createMetadataPatientName: MetadataPatientName;
  /** Create a single "MetadataPatientSex" node. */
  createMetadataPatientSex: MetadataPatientSex;
  /** Create a single "MetadataPersonInImage" node. */
  createMetadataPersonInImage: MetadataPersonInImage;
  /** Create a single "MetadataPhotoStorage" node. */
  createMetadataPhotoStorage: MetadataPhotoStorage;
  /** Create a single "MetadataPrepressPictureEditing" node. */
  createMetadataPrepressPictureEditing: MetadataPrepressPictureEditing;
  /** Create a single "MetadataPriceLevel" node. */
  createMetadataPriceLevel: MetadataPriceLevel;
  /** Create a single "MetadataPrintingProfile" node. */
  createMetadataPrintingProfile: MetadataPrintingProfile;
  /** Create a single "MetadataPrismaProduction" node. */
  createMetadataPrismaProduction: MetadataPrismaProduction;
  /** Create a single "MetadataProcessHistory" node. */
  createMetadataProcessHistory: MetadataProcessHistory;
  /** Create a single "MetadataProcessParameter" node. */
  createMetadataProcessParameter: MetadataProcessParameter;
  /** Create a single "MetadataProcessStatus" node. */
  createMetadataProcessStatus: MetadataProcessStatus;
  /** Create a single "MetadataProduct" node. */
  createMetadataProduct: MetadataProduct;
  /** Create a single "MetadataProducts" node. */
  createMetadataProducts: MetadataProducts;
  /** Create a single "MetadataProductShortName" node. */
  createMetadataProductShortName: MetadataProductShortName;
  /** Create a single "MetadataProduits" node. */
  createMetadataProduits: MetadataProduits;
  /** Create a single "MetadataProgramVersion" node. */
  createMetadataProgramVersion: MetadataProgramVersion;
  /** Create a single "MetadataPropertyReleaseId" node. */
  createMetadataPropertyReleaseId: MetadataPropertyReleaseId;
  /** Create a single "MetadataPropertyReleaseStatus" node. */
  createMetadataPropertyReleaseStatus: MetadataPropertyReleaseStatus;
  /** Create a single "MetadataPublisher" node. */
  createMetadataPublisher: MetadataPublisher;
  /** Create a single "MetadataPublishingIssue" node. */
  createMetadataPublishingIssue: MetadataPublishingIssue;
  /** Create a single "MetadataPublishingSubject" node. */
  createMetadataPublishingSubject: MetadataPublishingSubject;
  /** Create a single "MetadataQualifiedUsePrismaBy" node. */
  createMetadataQualifiedUsePrismaBy: MetadataQualifiedUsePrismaBy;
  /** Create a single "MetadataQualifiedUsePrismaDate" node. */
  createMetadataQualifiedUsePrismaDate: MetadataQualifiedUsePrismaDate;
  /** Create a single "MetadataQualifiedUsePrismaDuration" node. */
  createMetadataQualifiedUsePrismaDuration: MetadataQualifiedUsePrismaDuration;
  /** Create a single "MetadataQualifiedUsePrismaSupport" node. */
  createMetadataQualifiedUsePrismaSupport: MetadataQualifiedUsePrismaSupport;
  /** Create a single "MetadataRating" node. */
  createMetadataRating: MetadataRating;
  /** Create a single "MetadataReferenceDate" node. */
  createMetadataReferenceDate: MetadataReferenceDate;
  /** Create a single "MetadataReferenceNumber" node. */
  createMetadataReferenceNumber: MetadataReferenceNumber;
  /** Create a single "MetadataReferenceService" node. */
  createMetadataReferenceService: MetadataReferenceService;
  /** Create a single "MetadataReleaseDate" node. */
  createMetadataReleaseDate: MetadataReleaseDate;
  /** Create a single "MetadataReleaseTime" node. */
  createMetadataReleaseTime: MetadataReleaseTime;
  /** Create a single "MetadataRequiredPermission" node. */
  createMetadataRequiredPermission: MetadataRequiredPermission;
  /** Create a single "MetadataResolutionKind" node. */
  createMetadataResolutionKind: MetadataResolutionKind;
  /** Create a single "MetadataRights" node. */
  createMetadataRights: MetadataRights;
  /** Create a single "MetadataRoyaltyfree" node. */
  createMetadataRoyaltyfree: MetadataRoyaltyfree;
  /** Create a single "MetadataScene" node. */
  createMetadataScene: MetadataScene;
  /** Create a single "MetadataSection" node. */
  createMetadataSection: MetadataSection;
  /** Create a single "MetadataSentTo" node. */
  createMetadataSentTo: MetadataSentTo;
  /** Create a single "MetadataSerialNumber" node. */
  createMetadataSerialNumber: MetadataSerialNumber;
  /** Create a single "MetadataSeriesDateTime" node. */
  createMetadataSeriesDateTime: MetadataSeriesDateTime;
  /** Create a single "MetadataSeriesDescription" node. */
  createMetadataSeriesDescription: MetadataSeriesDescription;
  /** Create a single "MetadataSeriesModality" node. */
  createMetadataSeriesModality: MetadataSeriesModality;
  /** Create a single "MetadataSeriesNumber" node. */
  createMetadataSeriesNumber: MetadataSeriesNumber;
  /** Create a single "MetadataShortUniqueId" node. */
  createMetadataShortUniqueId: MetadataShortUniqueId;
  /** Create a single "MetadataSource" node. */
  createMetadataSource: MetadataSource;
  /** Create a single "MetadataState" node. */
  createMetadataState: MetadataState;
  /** Create a single "MetadataStudyDateTime" node. */
  createMetadataStudyDateTime: MetadataStudyDateTime;
  /** Create a single "MetadataStudyDescription" node. */
  createMetadataStudyDescription: MetadataStudyDescription;
  /** Create a single "MetadataStudyId" node. */
  createMetadataStudyId: MetadataStudyId;
  /** Create a single "MetadataStudyPhysician" node. */
  createMetadataStudyPhysician: MetadataStudyPhysician;
  /** Create a single "MetadataSubject" node. */
  createMetadataSubject: MetadataSubject;
  /** Create a single "MetadataSubjectCode" node. */
  createMetadataSubjectCode: MetadataSubjectCode;
  /** Create a single "MetadataSubjectPrisma" node. */
  createMetadataSubjectPrisma: MetadataSubjectPrisma;
  /** Create a single "MetadataSupplementalCategories" node. */
  createMetadataSupplementalCategories: MetadataSupplementalCategories;
  /** Create a single "MetadataTargetVersion" node. */
  createMetadataTargetVersion: MetadataTargetVersion;
  /** Create a single "MetadataTitle" node. */
  createMetadataTitle: MetadataTitle;
  /** Create a single "MetadataToneCurve" node. */
  createMetadataToneCurve: MetadataToneCurve;
  /** Create a single "MetadataTransferredBy" node. */
  createMetadataTransferredBy: MetadataTransferredBy;
  /** Create a single "MetadataTransferredByEmail" node. */
  createMetadataTransferredByEmail: MetadataTransferredByEmail;
  /** Create a single "MetadataTransferredByFullName" node. */
  createMetadataTransferredByFullName: MetadataTransferredByFullName;
  /** Create a single "MetadataTransmissionReference" node. */
  createMetadataTransmissionReference: MetadataTransmissionReference;
  /** Create a single "MetadataType" node. */
  createMetadataType: MetadataType;
  /** Create a single "MetadataUndersubject" node. */
  createMetadataUndersubject: MetadataUndersubject;
  /** Create a single "MetadataUnderUnderSubject" node. */
  createMetadataUnderUnderSubject: MetadataUnderUnderSubject;
  /** Create a single "MetadataUniqueId" node. */
  createMetadataUniqueId: MetadataUniqueId;
  /** Create a single "MetadataUnit" node. */
  createMetadataUnit: MetadataUnit;
  /** Create a single "MetadataUnitShortName" node. */
  createMetadataUnitShortName: MetadataUnitShortName;
  /** Create a single "MetadataUploadedBy" node. */
  createMetadataUploadedBy: MetadataUploadedBy;
  /** Create a single "MetadataUploadedByFullName" node. */
  createMetadataUploadedByFullName: MetadataUploadedByFullName;
  /** Create a single "MetadataUploadTime" node. */
  createMetadataUploadTime: MetadataUploadTime;
  /** Create a single "MetadataUrgency" node. */
  createMetadataUrgency: MetadataUrgency;
  /** Create a single "MetadataUsageTerms" node. */
  createMetadataUsageTerms: MetadataUsageTerms;
  /** Create a single "MetadataUserComment" node. */
  createMetadataUserComment: MetadataUserComment;
  /** Create a single "MetadataUserDefined195" node. */
  createMetadataUserDefined195: MetadataUserDefined195;
  /** Create a single "MetadataUserDefined237" node. */
  createMetadataUserDefined237: MetadataUserDefined237;
  /** Create a single "MetadataUserDefined238" node. */
  createMetadataUserDefined238: MetadataUserDefined238;
  /** Create a single "MetadataUserDefined239" node. */
  createMetadataUserDefined239: MetadataUserDefined239;
  /** Create a single "MetadataUserDefined242" node. */
  createMetadataUserDefined242: MetadataUserDefined242;
  /** Create a single "MetadataUserDefined62" node. */
  createMetadataUserDefined62: MetadataUserDefined62;
  /** Create a single "MetadataValid" node. */
  createMetadataValid: MetadataValid;
  /** Create a single "MetadataVersion" node. */
  createMetadataVersion: MetadataVersion;
  /** Create a single "MetadataWebStatement" node. */
  createMetadataWebStatement: MetadataWebStatement;
  /** Create a single "MetadataWorkflowKind" node. */
  createMetadataWorkflowKind: MetadataWorkflowKind;
  /** Create a single "MetadataXmpFileStamps" node. */
  createMetadataXmpFileStamps: MetadataXmpFileStamps;
  /** Create a single "MetadataXmpHistory" node. */
  createMetadataXmpHistory: MetadataXmpHistory;
  /** Create a single "Photo" node. */
  createPhoto: Photo;
  /** Create a single "PhotoMetadata" node. */
  createPhotoMetadata: PhotoMetadata;
  /** Create a single "PhotoPublication" node. */
  createPhotoPublication: PhotoPublication;
  /** Delete a single "Agency" node. */
  deleteAgency?: Maybe<Agency>;
  /** Delete a single "Archive" node. */
  deleteArchive?: Maybe<Archive>;
  /** Delete a single "ArchiveAgency" node. */
  deleteArchiveAgency?: Maybe<ArchiveAgency>;
  /** Delete a single "Category" node. */
  deleteCategory?: Maybe<Category>;
  /** Delete a single "DeliverySubject" node. */
  deleteDeliverySubject?: Maybe<DeliverySubject>;
  /** Delete a single "GcImportSetting" node. */
  deleteGcImportSetting?: Maybe<GcImportSetting>;
  /** Delete a single "ImportQueue" node. */
  deleteImportQueue?: Maybe<ImportQueue>;
  /** Delete a single "Metadata" node. */
  deleteMetadata?: Maybe<Metadata>;
  /** Delete a single "MetadataAccessRights" node. */
  deleteMetadataAccessRights?: Maybe<MetadataAccessRights>;
  /** Delete a single "MetadataAddlModelInfo" node. */
  deleteMetadataAddlModelInfo?: Maybe<MetadataAddlModelInfo>;
  /** Delete a single "MetadataArchiveChild" node. */
  deleteMetadataArchiveChild?: Maybe<MetadataArchiveChild>;
  /** Delete a single "MetadataArchiveParent" node. */
  deleteMetadataArchiveParent?: Maybe<MetadataArchiveParent>;
  /** Delete a single "MetadataAssetState" node. */
  deleteMetadataAssetState?: Maybe<MetadataAssetState>;
  /** Delete a single "MetadataAttributionName" node. */
  deleteMetadataAttributionName?: Maybe<MetadataAttributionName>;
  /** Delete a single "MetadataAttributionUrl" node. */
  deleteMetadataAttributionUrl?: Maybe<MetadataAttributionUrl>;
  /** Delete a single "MetadataAudience" node. */
  deleteMetadataAudience?: Maybe<MetadataAudience>;
  /** Delete a single "MetadataAuthorsPosition" node. */
  deleteMetadataAuthorsPosition?: Maybe<MetadataAuthorsPosition>;
  /** Delete a single "MetadataBackupName" node. */
  deleteMetadataBackupName?: Maybe<MetadataBackupName>;
  /** Delete a single "MetadataBitmapGrayscalePicture" node. */
  deleteMetadataBitmapGrayscalePicture?: Maybe<MetadataBitmapGrayscalePicture>;
  /** Delete a single "MetadataBrand" node. */
  deleteMetadataBrand?: Maybe<MetadataBrand>;
  /** Delete a single "MetadataBrandPrisma" node. */
  deleteMetadataBrandPrisma?: Maybe<MetadataBrandPrisma>;
  /** Delete a single "MetadataBrandShortName" node. */
  deleteMetadataBrandShortName?: Maybe<MetadataBrandShortName>;
  /** Delete a single "MetadataCaptionWriter" node. */
  deleteMetadataCaptionWriter?: Maybe<MetadataCaptionWriter>;
  /** Delete a single "MetadataCategory" node. */
  deleteMetadataCategory?: Maybe<MetadataCategory>;
  /** Delete a single "MetadataCategoryPrisma" node. */
  deleteMetadataCategoryPrisma?: Maybe<MetadataCategoryPrisma>;
  /** Delete a single "MetadataCertificate" node. */
  deleteMetadataCertificate?: Maybe<MetadataCertificate>;
  /** Delete a single "MetadataCiAdrCity" node. */
  deleteMetadataCiAdrCity?: Maybe<MetadataCiAdrCity>;
  /** Delete a single "MetadataCiAdrCtry" node. */
  deleteMetadataCiAdrCtry?: Maybe<MetadataCiAdrCtry>;
  /** Delete a single "MetadataCiAdrExtadr" node. */
  deleteMetadataCiAdrExtadr?: Maybe<MetadataCiAdrExtadr>;
  /** Delete a single "MetadataCiAdrPcode" node. */
  deleteMetadataCiAdrPcode?: Maybe<MetadataCiAdrPcode>;
  /** Delete a single "MetadataCiAdrRegion" node. */
  deleteMetadataCiAdrRegion?: Maybe<MetadataCiAdrRegion>;
  /** Delete a single "MetadataCiEmailWork" node. */
  deleteMetadataCiEmailWork?: Maybe<MetadataCiEmailWork>;
  /** Delete a single "MetadataCiTelWork" node. */
  deleteMetadataCiTelWork?: Maybe<MetadataCiTelWork>;
  /** Delete a single "MetadataCity" node. */
  deleteMetadataCity?: Maybe<MetadataCity>;
  /** Delete a single "MetadataCiUrlWork" node. */
  deleteMetadataCiUrlWork?: Maybe<MetadataCiUrlWork>;
  /** Delete a single "MetadataClassify" node. */
  deleteMetadataClassify?: Maybe<MetadataClassify>;
  /** Delete a single "MetadataCompany" node. */
  deleteMetadataCompany?: Maybe<MetadataCompany>;
  /** Delete a single "MetadataCompanyShortName" node. */
  deleteMetadataCompanyShortName?: Maybe<MetadataCompanyShortName>;
  /** Delete a single "MetadataConfidentiality" node. */
  deleteMetadataConfidentiality?: Maybe<MetadataConfidentiality>;
  /** Delete a single "MetadataContainedIn" node. */
  deleteMetadataContainedIn?: Maybe<MetadataContainedIn>;
  /** Delete a single "MetadataContentMediaKind" node. */
  deleteMetadataContentMediaKind?: Maybe<MetadataContentMediaKind>;
  /** Delete a single "MetadataContentValue" node. */
  deleteMetadataContentValue?: Maybe<MetadataContentValue>;
  /** Delete a single "MetadataCopyrightLayer" node. */
  deleteMetadataCopyrightLayer?: Maybe<MetadataCopyrightLayer>;
  /** Delete a single "MetadataCountry" node. */
  deleteMetadataCountry?: Maybe<MetadataCountry>;
  /** Delete a single "MetadataCountryCode" node. */
  deleteMetadataCountryCode?: Maybe<MetadataCountryCode>;
  /** Delete a single "MetadataCreatedTime" node. */
  deleteMetadataCreatedTime?: Maybe<MetadataCreatedTime>;
  /** Delete a single "MetadataCreator" node. */
  deleteMetadataCreator?: Maybe<MetadataCreator>;
  /** Delete a single "MetadataCreatorTool" node. */
  deleteMetadataCreatorTool?: Maybe<MetadataCreatorTool>;
  /** Delete a single "MetadataCredit" node. */
  deleteMetadataCredit?: Maybe<MetadataCredit>;
  /** Delete a single "MetadataCustomField14" node. */
  deleteMetadataCustomField14?: Maybe<MetadataCustomField14>;
  /** Delete a single "MetadataCustomField15" node. */
  deleteMetadataCustomField15?: Maybe<MetadataCustomField15>;
  /** Delete a single "MetadataCustomField16" node. */
  deleteMetadataCustomField16?: Maybe<MetadataCustomField16>;
  /** Delete a single "MetadataCustomField17" node. */
  deleteMetadataCustomField17?: Maybe<MetadataCustomField17>;
  /** Delete a single "MetadataCustomField20" node. */
  deleteMetadataCustomField20?: Maybe<MetadataCustomField20>;
  /** Delete a single "MetadataCustomField5" node. */
  deleteMetadataCustomField5?: Maybe<MetadataCustomField5>;
  /** Delete a single "MetadataCustomField6" node. */
  deleteMetadataCustomField6?: Maybe<MetadataCustomField6>;
  /** Delete a single "MetadataCustomField7" node. */
  deleteMetadataCustomField7?: Maybe<MetadataCustomField7>;
  /** Delete a single "MetadataCustomField9" node. */
  deleteMetadataCustomField9?: Maybe<MetadataCustomField9>;
  /** Delete a single "MetadataDateCreated" node. */
  deleteMetadataDateCreated?: Maybe<MetadataDateCreated>;
  /** Delete a single "MetadataDateTimeDigitized" node. */
  deleteMetadataDateTimeDigitized?: Maybe<MetadataDateTimeDigitized>;
  /** Delete a single "MetadataDateTimeOriginal" node. */
  deleteMetadataDateTimeOriginal?: Maybe<MetadataDateTimeOriginal>;
  /** Delete a single "MetadataDeliveryAccount" node. */
  deleteMetadataDeliveryAccount?: Maybe<MetadataDeliveryAccount>;
  /** Delete a single "MetadataDeliveryCompany" node. */
  deleteMetadataDeliveryCompany?: Maybe<MetadataDeliveryCompany>;
  /** Delete a single "MetadataDeliveryCopyright" node. */
  deleteMetadataDeliveryCopyright?: Maybe<MetadataDeliveryCopyright>;
  /** Delete a single "MetadataDeliveryDateFolder" node. */
  deleteMetadataDeliveryDateFolder?: Maybe<MetadataDeliveryDateFolder>;
  /** Delete a single "MetadataDeliveryDateTime" node. */
  deleteMetadataDeliveryDateTime?: Maybe<MetadataDeliveryDateTime>;
  /** Delete a single "MetadataDeliveryDcCreator" node. */
  deleteMetadataDeliveryDcCreator?: Maybe<MetadataDeliveryDcCreator>;
  /** Delete a single "MetadataDeliveryDcRights" node. */
  deleteMetadataDeliveryDcRights?: Maybe<MetadataDeliveryDcRights>;
  /** Delete a single "MetadataDeliveryFileType" node. */
  deleteMetadataDeliveryFileType?: Maybe<MetadataDeliveryFileType>;
  /** Delete a single "MetadataDeliveryFolder" node. */
  deleteMetadataDeliveryFolder?: Maybe<MetadataDeliveryFolder>;
  /** Delete a single "MetadataDeliveryKind" node. */
  deleteMetadataDeliveryKind?: Maybe<MetadataDeliveryKind>;
  /** Delete a single "MetadataDeliveryPath" node. */
  deleteMetadataDeliveryPath?: Maybe<MetadataDeliveryPath>;
  /** Delete a single "MetadataDeliveryPersonShownintheImage" node. */
  deleteMetadataDeliveryPersonShownintheImage?: Maybe<MetadataDeliveryPersonShownintheImage>;
  /** Delete a single "MetadataDeliveryPhotoshopCredit" node. */
  deleteMetadataDeliveryPhotoshopCredit?: Maybe<MetadataDeliveryPhotoshopCredit>;
  /** Delete a single "MetadataDeliveryPhotoshopSource" node. */
  deleteMetadataDeliveryPhotoshopSource?: Maybe<MetadataDeliveryPhotoshopSource>;
  /** Delete a single "MetadataDeliveryService" node. */
  deleteMetadataDeliveryService?: Maybe<MetadataDeliveryService>;
  /** Delete a single "MetadataDeliverySubject" node. */
  deleteMetadataDeliverySubject?: Maybe<MetadataDeliverySubject>;
  /** Delete a single "MetadataDeliveryUnderSubject" node. */
  deleteMetadataDeliveryUnderSubject?: Maybe<MetadataDeliveryUnderSubject>;
  /** Delete a single "MetadataDepartment" node. */
  deleteMetadataDepartment?: Maybe<MetadataDepartment>;
  /** Delete a single "MetadataDescription" node. */
  deleteMetadataDescription?: Maybe<MetadataDescription>;
  /** Delete a single "MetadataDigitalAssetUrl" node. */
  deleteMetadataDigitalAssetUrl?: Maybe<MetadataDigitalAssetUrl>;
  /** Delete a single "MetadataDivision" node. */
  deleteMetadataDivision?: Maybe<MetadataDivision>;
  /** Delete a single "MetadataDocumentText" node. */
  deleteMetadataDocumentText?: Maybe<MetadataDocumentText>;
  /** Delete a single "MetadataEdition" node. */
  deleteMetadataEdition?: Maybe<MetadataEdition>;
  /** Delete a single "MetadataEditorialVersion" node. */
  deleteMetadataEditorialVersion?: Maybe<MetadataEditorialVersion>;
  /** Delete a single "MetadataEditStatus" node. */
  deleteMetadataEditStatus?: Maybe<MetadataEditStatus>;
  /** Delete a single "MetadataEnvironnementPhoto" node. */
  deleteMetadataEnvironnementPhoto?: Maybe<MetadataEnvironnementPhoto>;
  /** Delete a single "MetadataEquipmentInstitution" node. */
  deleteMetadataEquipmentInstitution?: Maybe<MetadataEquipmentInstitution>;
  /** Delete a single "MetadataEquipmentManufacturer" node. */
  deleteMetadataEquipmentManufacturer?: Maybe<MetadataEquipmentManufacturer>;
  /** Delete a single "MetadataEvent" node. */
  deleteMetadataEvent?: Maybe<MetadataEvent>;
  /** Delete a single "MetadataExif" node. */
  deleteMetadataExif?: Maybe<MetadataExif>;
  /** Delete a single "MetadataFabStorage" node. */
  deleteMetadataFabStorage?: Maybe<MetadataFabStorage>;
  /** Delete a single "MetadataFileCheckFor" node. */
  deleteMetadataFileCheckFor?: Maybe<MetadataFileCheckFor>;
  /** Delete a single "MetadataFileInfoBitsPerPixel" node. */
  deleteMetadataFileInfoBitsPerPixel?: Maybe<MetadataFileInfoBitsPerPixel>;
  /** Delete a single "MetadataFileInfoFrames" node. */
  deleteMetadataFileInfoFrames?: Maybe<MetadataFileInfoFrames>;
  /** Delete a single "MetadataFileInfoPhotometricInterpretation" node. */
  deleteMetadataFileInfoPhotometricInterpretation?: Maybe<MetadataFileInfoPhotometricInterpretation>;
  /** Delete a single "MetadataFileInfoTransferSyntax" node. */
  deleteMetadataFileInfoTransferSyntax?: Maybe<MetadataFileInfoTransferSyntax>;
  /** Delete a single "MetadataFileStage" node. */
  deleteMetadataFileStage?: Maybe<MetadataFileStage>;
  /** Delete a single "MetadataFileStatus" node. */
  deleteMetadataFileStatus?: Maybe<MetadataFileStatus>;
  /** Delete a single "MetadataFileType" node. */
  deleteMetadataFileType?: Maybe<MetadataFileType>;
  /** Delete a single "MetadataFixtureIdentifier" node. */
  deleteMetadataFixtureIdentifier?: Maybe<MetadataFixtureIdentifier>;
  /** Delete a single "MetadataFormat" node. */
  deleteMetadataFormat?: Maybe<MetadataFormat>;
  /** Delete a single "MetadataGpsLatitude" node. */
  deleteMetadataGpsLatitude?: Maybe<MetadataGpsLatitude>;
  /** Delete a single "MetadataGpsLongitude" node. */
  deleteMetadataGpsLongitude?: Maybe<MetadataGpsLongitude>;
  /** Delete a single "MetadataGpsProcessingMethod" node. */
  deleteMetadataGpsProcessingMethod?: Maybe<MetadataGpsProcessingMethod>;
  /** Delete a single "MetadataHeader" node. */
  deleteMetadataHeader?: Maybe<MetadataHeader>;
  /** Delete a single "MetadataHeaderPrisma" node. */
  deleteMetadataHeaderPrisma?: Maybe<MetadataHeaderPrisma>;
  /** Delete a single "MetadataHeadline" node. */
  deleteMetadataHeadline?: Maybe<MetadataHeadline>;
  /** Delete a single "MetadataHistory" node. */
  deleteMetadataHistory?: Maybe<MetadataHistory>;
  /** Delete a single "MetadataIccProfile" node. */
  deleteMetadataIccProfile?: Maybe<MetadataIccProfile>;
  /** Delete a single "MetadataIdAssignment" node. */
  deleteMetadataIdAssignment?: Maybe<MetadataIdAssignment>;
  /** Delete a single "MetadataIdMediaContent" node. */
  deleteMetadataIdMediaContent?: Maybe<MetadataIdMediaContent>;
  /** Delete a single "MetadataIdPhotoPrisma" node. */
  deleteMetadataIdPhotoPrisma?: Maybe<MetadataIdPhotoPrisma>;
  /** Delete a single "MetadataIdSubject" node. */
  deleteMetadataIdSubject?: Maybe<MetadataIdSubject>;
  /** Delete a single "MetadataImageNotes" node. */
  deleteMetadataImageNotes?: Maybe<MetadataImageNotes>;
  /** Delete a single "MetadataImageUniqueId" node. */
  deleteMetadataImageUniqueId?: Maybe<MetadataImageUniqueId>;
  /** Delete a single "MetadataInstructions" node. */
  deleteMetadataInstructions?: Maybe<MetadataInstructions>;
  /** Delete a single "MetadataIntellectualGenre" node. */
  deleteMetadataIntellectualGenre?: Maybe<MetadataIntellectualGenre>;
  /** Delete a single "MetadataIsoSpeedRatings" node. */
  deleteMetadataIsoSpeedRatings?: Maybe<MetadataIsoSpeedRatings>;
  /** Delete a single "MetadataIssue" node. */
  deleteMetadataIssue?: Maybe<MetadataIssue>;
  /** Delete a single "MetadataJobId" node. */
  deleteMetadataJobId?: Maybe<MetadataJobId>;
  /** Delete a single "MetadataJobProcessing" node. */
  deleteMetadataJobProcessing?: Maybe<MetadataJobProcessing>;
  /** Delete a single "MetadataLayoutPictureEditing" node. */
  deleteMetadataLayoutPictureEditing?: Maybe<MetadataLayoutPictureEditing>;
  /** Delete a single "MetadataLayoutStorage" node. */
  deleteMetadataLayoutStorage?: Maybe<MetadataLayoutStorage>;
  /** Delete a single "MetadataLicense" node. */
  deleteMetadataLicense?: Maybe<MetadataLicense>;
  /** Delete a single "MetadataLinks" node. */
  deleteMetadataLinks?: Maybe<MetadataLinks>;
  /** Delete a single "MetadataLocalCaption" node. */
  deleteMetadataLocalCaption?: Maybe<MetadataLocalCaption>;
  /** Delete a single "MetadataLocation" node. */
  deleteMetadataLocation?: Maybe<MetadataLocation>;
  /** Delete a single "MetadataMake" node. */
  deleteMetadataMake?: Maybe<MetadataMake>;
  /** Delete a single "MetadataManifest" node. */
  deleteMetadataManifest?: Maybe<MetadataManifest>;
  /** Delete a single "MetadataMarked" node. */
  deleteMetadataMarked?: Maybe<MetadataMarked>;
  /** Delete a single "MetadataMasterDocumentId" node. */
  deleteMetadataMasterDocumentId?: Maybe<MetadataMasterDocumentId>;
  /** Delete a single "MetadataMaxAvailHeight" node. */
  deleteMetadataMaxAvailHeight?: Maybe<MetadataMaxAvailHeight>;
  /** Delete a single "MetadataMaxAvailWidth" node. */
  deleteMetadataMaxAvailWidth?: Maybe<MetadataMaxAvailWidth>;
  /** Delete a single "MetadataMinorModelAgeDisclosure" node. */
  deleteMetadataMinorModelAgeDisclosure?: Maybe<MetadataMinorModelAgeDisclosure>;
  /** Delete a single "MetadataModel" node. */
  deleteMetadataModel?: Maybe<MetadataModel>;
  /** Delete a single "MetadataModelAge" node. */
  deleteMetadataModelAge?: Maybe<MetadataModelAge>;
  /** Delete a single "MetadataModelReleaseId" node. */
  deleteMetadataModelReleaseId?: Maybe<MetadataModelReleaseId>;
  /** Delete a single "MetadataModelReleaseStatus" node. */
  deleteMetadataModelReleaseStatus?: Maybe<MetadataModelReleaseStatus>;
  /** Delete a single "MetadataMorePermissions" node. */
  deleteMetadataMorePermissions?: Maybe<MetadataMorePermissions>;
  /** Delete a single "MetadataObjectCycle" node. */
  deleteMetadataObjectCycle?: Maybe<MetadataObjectCycle>;
  /** Delete a single "MetadataOrganisationInImageName" node. */
  deleteMetadataOrganisationInImageName?: Maybe<MetadataOrganisationInImageName>;
  /** Delete a single "MetadataOriginalColor" node. */
  deleteMetadataOriginalColor?: Maybe<MetadataOriginalColor>;
  /** Delete a single "MetadataOriginalIccProfile" node. */
  deleteMetadataOriginalIccProfile?: Maybe<MetadataOriginalIccProfile>;
  /** Delete a single "MetadataOriginalName" node. */
  deleteMetadataOriginalName?: Maybe<MetadataOriginalName>;
  /** Delete a single "MetadataOriginalWeigth" node. */
  deleteMetadataOriginalWeigth?: Maybe<MetadataOriginalWeigth>;
  /** Delete a single "MetadataOwner" node. */
  deleteMetadataOwner?: Maybe<MetadataOwner>;
  /** Delete a single "MetadataOwnerId" node. */
  deleteMetadataOwnerId?: Maybe<MetadataOwnerId>;
  /** Delete a single "MetadataPage" node. */
  deleteMetadataPage?: Maybe<MetadataPage>;
  /** Delete a single "MetadataPagePrisma" node. */
  deleteMetadataPagePrisma?: Maybe<MetadataPagePrisma>;
  /** Delete a single "MetadataPaintBasedCorrections" node. */
  deleteMetadataPaintBasedCorrections?: Maybe<MetadataPaintBasedCorrections>;
  /** Delete a single "MetadataPatientDob" node. */
  deleteMetadataPatientDob?: Maybe<MetadataPatientDob>;
  /** Delete a single "MetadataPatientId" node. */
  deleteMetadataPatientId?: Maybe<MetadataPatientId>;
  /** Delete a single "MetadataPatientName" node. */
  deleteMetadataPatientName?: Maybe<MetadataPatientName>;
  /** Delete a single "MetadataPatientSex" node. */
  deleteMetadataPatientSex?: Maybe<MetadataPatientSex>;
  /** Delete a single "MetadataPersonInImage" node. */
  deleteMetadataPersonInImage?: Maybe<MetadataPersonInImage>;
  /** Delete a single "MetadataPhotoStorage" node. */
  deleteMetadataPhotoStorage?: Maybe<MetadataPhotoStorage>;
  /** Delete a single "MetadataPrepressPictureEditing" node. */
  deleteMetadataPrepressPictureEditing?: Maybe<MetadataPrepressPictureEditing>;
  /** Delete a single "MetadataPriceLevel" node. */
  deleteMetadataPriceLevel?: Maybe<MetadataPriceLevel>;
  /** Delete a single "MetadataPrintingProfile" node. */
  deleteMetadataPrintingProfile?: Maybe<MetadataPrintingProfile>;
  /** Delete a single "MetadataPrismaProduction" node. */
  deleteMetadataPrismaProduction?: Maybe<MetadataPrismaProduction>;
  /** Delete a single "MetadataProcessHistory" node. */
  deleteMetadataProcessHistory?: Maybe<MetadataProcessHistory>;
  /** Delete a single "MetadataProcessParameter" node. */
  deleteMetadataProcessParameter?: Maybe<MetadataProcessParameter>;
  /** Delete a single "MetadataProcessStatus" node. */
  deleteMetadataProcessStatus?: Maybe<MetadataProcessStatus>;
  /** Delete a single "MetadataProduct" node. */
  deleteMetadataProduct?: Maybe<MetadataProduct>;
  /** Delete a single "MetadataProducts" node. */
  deleteMetadataProducts?: Maybe<MetadataProducts>;
  /** Delete a single "MetadataProductShortName" node. */
  deleteMetadataProductShortName?: Maybe<MetadataProductShortName>;
  /** Delete a single "MetadataProduits" node. */
  deleteMetadataProduits?: Maybe<MetadataProduits>;
  /** Delete a single "MetadataProgramVersion" node. */
  deleteMetadataProgramVersion?: Maybe<MetadataProgramVersion>;
  /** Delete a single "MetadataPropertyReleaseId" node. */
  deleteMetadataPropertyReleaseId?: Maybe<MetadataPropertyReleaseId>;
  /** Delete a single "MetadataPropertyReleaseStatus" node. */
  deleteMetadataPropertyReleaseStatus?: Maybe<MetadataPropertyReleaseStatus>;
  /** Delete a single "MetadataPublisher" node. */
  deleteMetadataPublisher?: Maybe<MetadataPublisher>;
  /** Delete a single "MetadataPublishingIssue" node. */
  deleteMetadataPublishingIssue?: Maybe<MetadataPublishingIssue>;
  /** Delete a single "MetadataPublishingSubject" node. */
  deleteMetadataPublishingSubject?: Maybe<MetadataPublishingSubject>;
  /** Delete a single "MetadataQualifiedUsePrismaBy" node. */
  deleteMetadataQualifiedUsePrismaBy?: Maybe<MetadataQualifiedUsePrismaBy>;
  /** Delete a single "MetadataQualifiedUsePrismaDate" node. */
  deleteMetadataQualifiedUsePrismaDate?: Maybe<MetadataQualifiedUsePrismaDate>;
  /** Delete a single "MetadataQualifiedUsePrismaDuration" node. */
  deleteMetadataQualifiedUsePrismaDuration?: Maybe<MetadataQualifiedUsePrismaDuration>;
  /** Delete a single "MetadataQualifiedUsePrismaSupport" node. */
  deleteMetadataQualifiedUsePrismaSupport?: Maybe<MetadataQualifiedUsePrismaSupport>;
  /** Delete a single "MetadataRating" node. */
  deleteMetadataRating?: Maybe<MetadataRating>;
  /** Delete a single "MetadataReferenceDate" node. */
  deleteMetadataReferenceDate?: Maybe<MetadataReferenceDate>;
  /** Delete a single "MetadataReferenceNumber" node. */
  deleteMetadataReferenceNumber?: Maybe<MetadataReferenceNumber>;
  /** Delete a single "MetadataReferenceService" node. */
  deleteMetadataReferenceService?: Maybe<MetadataReferenceService>;
  /** Delete a single "MetadataReleaseDate" node. */
  deleteMetadataReleaseDate?: Maybe<MetadataReleaseDate>;
  /** Delete a single "MetadataReleaseTime" node. */
  deleteMetadataReleaseTime?: Maybe<MetadataReleaseTime>;
  /** Delete a single "MetadataRequiredPermission" node. */
  deleteMetadataRequiredPermission?: Maybe<MetadataRequiredPermission>;
  /** Delete a single "MetadataResolutionKind" node. */
  deleteMetadataResolutionKind?: Maybe<MetadataResolutionKind>;
  /** Delete a single "MetadataRights" node. */
  deleteMetadataRights?: Maybe<MetadataRights>;
  /** Delete a single "MetadataRoyaltyfree" node. */
  deleteMetadataRoyaltyfree?: Maybe<MetadataRoyaltyfree>;
  /** Delete a single "MetadataScene" node. */
  deleteMetadataScene?: Maybe<MetadataScene>;
  /** Delete a single "MetadataSection" node. */
  deleteMetadataSection?: Maybe<MetadataSection>;
  /** Delete a single "MetadataSentTo" node. */
  deleteMetadataSentTo?: Maybe<MetadataSentTo>;
  /** Delete a single "MetadataSerialNumber" node. */
  deleteMetadataSerialNumber?: Maybe<MetadataSerialNumber>;
  /** Delete a single "MetadataSeriesDateTime" node. */
  deleteMetadataSeriesDateTime?: Maybe<MetadataSeriesDateTime>;
  /** Delete a single "MetadataSeriesDescription" node. */
  deleteMetadataSeriesDescription?: Maybe<MetadataSeriesDescription>;
  /** Delete a single "MetadataSeriesModality" node. */
  deleteMetadataSeriesModality?: Maybe<MetadataSeriesModality>;
  /** Delete a single "MetadataSeriesNumber" node. */
  deleteMetadataSeriesNumber?: Maybe<MetadataSeriesNumber>;
  /** Delete a single "MetadataShortUniqueId" node. */
  deleteMetadataShortUniqueId?: Maybe<MetadataShortUniqueId>;
  /** Delete a single "MetadataSource" node. */
  deleteMetadataSource?: Maybe<MetadataSource>;
  /** Delete a single "MetadataState" node. */
  deleteMetadataState?: Maybe<MetadataState>;
  /** Delete a single "MetadataStudyDateTime" node. */
  deleteMetadataStudyDateTime?: Maybe<MetadataStudyDateTime>;
  /** Delete a single "MetadataStudyDescription" node. */
  deleteMetadataStudyDescription?: Maybe<MetadataStudyDescription>;
  /** Delete a single "MetadataStudyId" node. */
  deleteMetadataStudyId?: Maybe<MetadataStudyId>;
  /** Delete a single "MetadataStudyPhysician" node. */
  deleteMetadataStudyPhysician?: Maybe<MetadataStudyPhysician>;
  /** Delete a single "MetadataSubject" node. */
  deleteMetadataSubject?: Maybe<MetadataSubject>;
  /** Delete a single "MetadataSubjectCode" node. */
  deleteMetadataSubjectCode?: Maybe<MetadataSubjectCode>;
  /** Delete a single "MetadataSubjectPrisma" node. */
  deleteMetadataSubjectPrisma?: Maybe<MetadataSubjectPrisma>;
  /** Delete a single "MetadataSupplementalCategories" node. */
  deleteMetadataSupplementalCategories?: Maybe<MetadataSupplementalCategories>;
  /** Delete a single "MetadataTargetVersion" node. */
  deleteMetadataTargetVersion?: Maybe<MetadataTargetVersion>;
  /** Delete a single "MetadataTitle" node. */
  deleteMetadataTitle?: Maybe<MetadataTitle>;
  /** Delete a single "MetadataToneCurve" node. */
  deleteMetadataToneCurve?: Maybe<MetadataToneCurve>;
  /** Delete a single "MetadataTransferredBy" node. */
  deleteMetadataTransferredBy?: Maybe<MetadataTransferredBy>;
  /** Delete a single "MetadataTransferredByEmail" node. */
  deleteMetadataTransferredByEmail?: Maybe<MetadataTransferredByEmail>;
  /** Delete a single "MetadataTransferredByFullName" node. */
  deleteMetadataTransferredByFullName?: Maybe<MetadataTransferredByFullName>;
  /** Delete a single "MetadataTransmissionReference" node. */
  deleteMetadataTransmissionReference?: Maybe<MetadataTransmissionReference>;
  /** Delete a single "MetadataType" node. */
  deleteMetadataType?: Maybe<MetadataType>;
  /** Delete a single "MetadataUndersubject" node. */
  deleteMetadataUndersubject?: Maybe<MetadataUndersubject>;
  /** Delete a single "MetadataUnderUnderSubject" node. */
  deleteMetadataUnderUnderSubject?: Maybe<MetadataUnderUnderSubject>;
  /** Delete a single "MetadataUniqueId" node. */
  deleteMetadataUniqueId?: Maybe<MetadataUniqueId>;
  /** Delete a single "MetadataUnit" node. */
  deleteMetadataUnit?: Maybe<MetadataUnit>;
  /** Delete a single "MetadataUnitShortName" node. */
  deleteMetadataUnitShortName?: Maybe<MetadataUnitShortName>;
  /** Delete a single "MetadataUploadedBy" node. */
  deleteMetadataUploadedBy?: Maybe<MetadataUploadedBy>;
  /** Delete a single "MetadataUploadedByFullName" node. */
  deleteMetadataUploadedByFullName?: Maybe<MetadataUploadedByFullName>;
  /** Delete a single "MetadataUploadTime" node. */
  deleteMetadataUploadTime?: Maybe<MetadataUploadTime>;
  /** Delete a single "MetadataUrgency" node. */
  deleteMetadataUrgency?: Maybe<MetadataUrgency>;
  /** Delete a single "MetadataUsageTerms" node. */
  deleteMetadataUsageTerms?: Maybe<MetadataUsageTerms>;
  /** Delete a single "MetadataUserComment" node. */
  deleteMetadataUserComment?: Maybe<MetadataUserComment>;
  /** Delete a single "MetadataUserDefined195" node. */
  deleteMetadataUserDefined195?: Maybe<MetadataUserDefined195>;
  /** Delete a single "MetadataUserDefined237" node. */
  deleteMetadataUserDefined237?: Maybe<MetadataUserDefined237>;
  /** Delete a single "MetadataUserDefined238" node. */
  deleteMetadataUserDefined238?: Maybe<MetadataUserDefined238>;
  /** Delete a single "MetadataUserDefined239" node. */
  deleteMetadataUserDefined239?: Maybe<MetadataUserDefined239>;
  /** Delete a single "MetadataUserDefined242" node. */
  deleteMetadataUserDefined242?: Maybe<MetadataUserDefined242>;
  /** Delete a single "MetadataUserDefined62" node. */
  deleteMetadataUserDefined62?: Maybe<MetadataUserDefined62>;
  /** Delete a single "MetadataValid" node. */
  deleteMetadataValid?: Maybe<MetadataValid>;
  /** Delete a single "MetadataVersion" node. */
  deleteMetadataVersion?: Maybe<MetadataVersion>;
  /** Delete a single "MetadataWebStatement" node. */
  deleteMetadataWebStatement?: Maybe<MetadataWebStatement>;
  /** Delete a single "MetadataWorkflowKind" node. */
  deleteMetadataWorkflowKind?: Maybe<MetadataWorkflowKind>;
  /** Delete a single "MetadataXmpFileStamps" node. */
  deleteMetadataXmpFileStamps?: Maybe<MetadataXmpFileStamps>;
  /** Delete a single "MetadataXmpHistory" node. */
  deleteMetadataXmpHistory?: Maybe<MetadataXmpHistory>;
  /** Delete a single "Photo" node. */
  deletePhoto?: Maybe<Photo>;
  /** Delete a single "PhotoMetadata" node. */
  deletePhotoMetadata?: Maybe<PhotoMetadata>;
  /** Delete a single "PhotoPublication" node. */
  deletePhotoPublication?: Maybe<PhotoPublication>;
  /** Update a single "Agency" node. */
  updateAgency?: Maybe<Agency>;
  /** Update a single "Archive" node. */
  updateArchive?: Maybe<Archive>;
  /** Update a single "ArchiveAgency" node. */
  updateArchiveAgency?: Maybe<ArchiveAgency>;
  /** Update a single "Category" node. */
  updateCategory?: Maybe<Category>;
  /** Update a single "DeliverySubject" node. */
  updateDeliverySubject?: Maybe<DeliverySubject>;
  /** Update a single "GcImportSetting" node. */
  updateGcImportSetting?: Maybe<GcImportSetting>;
  /** Update a single "ImportQueue" node. */
  updateImportQueue?: Maybe<ImportQueue>;
  /** Update a single "Metadata" node. */
  updateMetadata?: Maybe<Metadata>;
  /** Update a single "MetadataAccessRights" node. */
  updateMetadataAccessRights?: Maybe<MetadataAccessRights>;
  /** Update a single "MetadataAddlModelInfo" node. */
  updateMetadataAddlModelInfo?: Maybe<MetadataAddlModelInfo>;
  /** Update a single "MetadataArchiveChild" node. */
  updateMetadataArchiveChild?: Maybe<MetadataArchiveChild>;
  /** Update a single "MetadataArchiveParent" node. */
  updateMetadataArchiveParent?: Maybe<MetadataArchiveParent>;
  /** Update a single "MetadataAssetState" node. */
  updateMetadataAssetState?: Maybe<MetadataAssetState>;
  /** Update a single "MetadataAttributionName" node. */
  updateMetadataAttributionName?: Maybe<MetadataAttributionName>;
  /** Update a single "MetadataAttributionUrl" node. */
  updateMetadataAttributionUrl?: Maybe<MetadataAttributionUrl>;
  /** Update a single "MetadataAudience" node. */
  updateMetadataAudience?: Maybe<MetadataAudience>;
  /** Update a single "MetadataAuthorsPosition" node. */
  updateMetadataAuthorsPosition?: Maybe<MetadataAuthorsPosition>;
  /** Update a single "MetadataBackupName" node. */
  updateMetadataBackupName?: Maybe<MetadataBackupName>;
  /** Update a single "MetadataBitmapGrayscalePicture" node. */
  updateMetadataBitmapGrayscalePicture?: Maybe<MetadataBitmapGrayscalePicture>;
  /** Update a single "MetadataBrand" node. */
  updateMetadataBrand?: Maybe<MetadataBrand>;
  /** Update a single "MetadataBrandPrisma" node. */
  updateMetadataBrandPrisma?: Maybe<MetadataBrandPrisma>;
  /** Update a single "MetadataBrandShortName" node. */
  updateMetadataBrandShortName?: Maybe<MetadataBrandShortName>;
  /** Update a single "MetadataCaptionWriter" node. */
  updateMetadataCaptionWriter?: Maybe<MetadataCaptionWriter>;
  /** Update a single "MetadataCategory" node. */
  updateMetadataCategory?: Maybe<MetadataCategory>;
  /** Update a single "MetadataCategoryPrisma" node. */
  updateMetadataCategoryPrisma?: Maybe<MetadataCategoryPrisma>;
  /** Update a single "MetadataCertificate" node. */
  updateMetadataCertificate?: Maybe<MetadataCertificate>;
  /** Update a single "MetadataCiAdrCity" node. */
  updateMetadataCiAdrCity?: Maybe<MetadataCiAdrCity>;
  /** Update a single "MetadataCiAdrCtry" node. */
  updateMetadataCiAdrCtry?: Maybe<MetadataCiAdrCtry>;
  /** Update a single "MetadataCiAdrExtadr" node. */
  updateMetadataCiAdrExtadr?: Maybe<MetadataCiAdrExtadr>;
  /** Update a single "MetadataCiAdrPcode" node. */
  updateMetadataCiAdrPcode?: Maybe<MetadataCiAdrPcode>;
  /** Update a single "MetadataCiAdrRegion" node. */
  updateMetadataCiAdrRegion?: Maybe<MetadataCiAdrRegion>;
  /** Update a single "MetadataCiEmailWork" node. */
  updateMetadataCiEmailWork?: Maybe<MetadataCiEmailWork>;
  /** Update a single "MetadataCiTelWork" node. */
  updateMetadataCiTelWork?: Maybe<MetadataCiTelWork>;
  /** Update a single "MetadataCity" node. */
  updateMetadataCity?: Maybe<MetadataCity>;
  /** Update a single "MetadataCiUrlWork" node. */
  updateMetadataCiUrlWork?: Maybe<MetadataCiUrlWork>;
  /** Update a single "MetadataClassify" node. */
  updateMetadataClassify?: Maybe<MetadataClassify>;
  /** Update a single "MetadataCompany" node. */
  updateMetadataCompany?: Maybe<MetadataCompany>;
  /** Update a single "MetadataCompanyShortName" node. */
  updateMetadataCompanyShortName?: Maybe<MetadataCompanyShortName>;
  /** Update a single "MetadataConfidentiality" node. */
  updateMetadataConfidentiality?: Maybe<MetadataConfidentiality>;
  /** Update a single "MetadataContainedIn" node. */
  updateMetadataContainedIn?: Maybe<MetadataContainedIn>;
  /** Update a single "MetadataContentMediaKind" node. */
  updateMetadataContentMediaKind?: Maybe<MetadataContentMediaKind>;
  /** Update a single "MetadataContentValue" node. */
  updateMetadataContentValue?: Maybe<MetadataContentValue>;
  /** Update a single "MetadataCopyrightLayer" node. */
  updateMetadataCopyrightLayer?: Maybe<MetadataCopyrightLayer>;
  /** Update a single "MetadataCountry" node. */
  updateMetadataCountry?: Maybe<MetadataCountry>;
  /** Update a single "MetadataCountryCode" node. */
  updateMetadataCountryCode?: Maybe<MetadataCountryCode>;
  /** Update a single "MetadataCreatedTime" node. */
  updateMetadataCreatedTime?: Maybe<MetadataCreatedTime>;
  /** Update a single "MetadataCreator" node. */
  updateMetadataCreator?: Maybe<MetadataCreator>;
  /** Update a single "MetadataCreatorTool" node. */
  updateMetadataCreatorTool?: Maybe<MetadataCreatorTool>;
  /** Update a single "MetadataCredit" node. */
  updateMetadataCredit?: Maybe<MetadataCredit>;
  /** Update a single "MetadataCustomField14" node. */
  updateMetadataCustomField14?: Maybe<MetadataCustomField14>;
  /** Update a single "MetadataCustomField15" node. */
  updateMetadataCustomField15?: Maybe<MetadataCustomField15>;
  /** Update a single "MetadataCustomField16" node. */
  updateMetadataCustomField16?: Maybe<MetadataCustomField16>;
  /** Update a single "MetadataCustomField17" node. */
  updateMetadataCustomField17?: Maybe<MetadataCustomField17>;
  /** Update a single "MetadataCustomField20" node. */
  updateMetadataCustomField20?: Maybe<MetadataCustomField20>;
  /** Update a single "MetadataCustomField5" node. */
  updateMetadataCustomField5?: Maybe<MetadataCustomField5>;
  /** Update a single "MetadataCustomField6" node. */
  updateMetadataCustomField6?: Maybe<MetadataCustomField6>;
  /** Update a single "MetadataCustomField7" node. */
  updateMetadataCustomField7?: Maybe<MetadataCustomField7>;
  /** Update a single "MetadataCustomField9" node. */
  updateMetadataCustomField9?: Maybe<MetadataCustomField9>;
  /** Update a single "MetadataDateCreated" node. */
  updateMetadataDateCreated?: Maybe<MetadataDateCreated>;
  /** Update a single "MetadataDateTimeDigitized" node. */
  updateMetadataDateTimeDigitized?: Maybe<MetadataDateTimeDigitized>;
  /** Update a single "MetadataDateTimeOriginal" node. */
  updateMetadataDateTimeOriginal?: Maybe<MetadataDateTimeOriginal>;
  /** Update a single "MetadataDeliveryAccount" node. */
  updateMetadataDeliveryAccount?: Maybe<MetadataDeliveryAccount>;
  /** Update a single "MetadataDeliveryCompany" node. */
  updateMetadataDeliveryCompany?: Maybe<MetadataDeliveryCompany>;
  /** Update a single "MetadataDeliveryCopyright" node. */
  updateMetadataDeliveryCopyright?: Maybe<MetadataDeliveryCopyright>;
  /** Update a single "MetadataDeliveryDateFolder" node. */
  updateMetadataDeliveryDateFolder?: Maybe<MetadataDeliveryDateFolder>;
  /** Update a single "MetadataDeliveryDateTime" node. */
  updateMetadataDeliveryDateTime?: Maybe<MetadataDeliveryDateTime>;
  /** Update a single "MetadataDeliveryDcCreator" node. */
  updateMetadataDeliveryDcCreator?: Maybe<MetadataDeliveryDcCreator>;
  /** Update a single "MetadataDeliveryDcRights" node. */
  updateMetadataDeliveryDcRights?: Maybe<MetadataDeliveryDcRights>;
  /** Update a single "MetadataDeliveryFileType" node. */
  updateMetadataDeliveryFileType?: Maybe<MetadataDeliveryFileType>;
  /** Update a single "MetadataDeliveryFolder" node. */
  updateMetadataDeliveryFolder?: Maybe<MetadataDeliveryFolder>;
  /** Update a single "MetadataDeliveryKind" node. */
  updateMetadataDeliveryKind?: Maybe<MetadataDeliveryKind>;
  /** Update a single "MetadataDeliveryPath" node. */
  updateMetadataDeliveryPath?: Maybe<MetadataDeliveryPath>;
  /** Update a single "MetadataDeliveryPersonShownintheImage" node. */
  updateMetadataDeliveryPersonShownintheImage?: Maybe<MetadataDeliveryPersonShownintheImage>;
  /** Update a single "MetadataDeliveryPhotoshopCredit" node. */
  updateMetadataDeliveryPhotoshopCredit?: Maybe<MetadataDeliveryPhotoshopCredit>;
  /** Update a single "MetadataDeliveryPhotoshopSource" node. */
  updateMetadataDeliveryPhotoshopSource?: Maybe<MetadataDeliveryPhotoshopSource>;
  /** Update a single "MetadataDeliveryService" node. */
  updateMetadataDeliveryService?: Maybe<MetadataDeliveryService>;
  /** Update a single "MetadataDeliverySubject" node. */
  updateMetadataDeliverySubject?: Maybe<MetadataDeliverySubject>;
  /** Update a single "MetadataDeliveryUnderSubject" node. */
  updateMetadataDeliveryUnderSubject?: Maybe<MetadataDeliveryUnderSubject>;
  /** Update a single "MetadataDepartment" node. */
  updateMetadataDepartment?: Maybe<MetadataDepartment>;
  /** Update a single "MetadataDescription" node. */
  updateMetadataDescription?: Maybe<MetadataDescription>;
  /** Update a single "MetadataDigitalAssetUrl" node. */
  updateMetadataDigitalAssetUrl?: Maybe<MetadataDigitalAssetUrl>;
  /** Update a single "MetadataDivision" node. */
  updateMetadataDivision?: Maybe<MetadataDivision>;
  /** Update a single "MetadataDocumentText" node. */
  updateMetadataDocumentText?: Maybe<MetadataDocumentText>;
  /** Update a single "MetadataEdition" node. */
  updateMetadataEdition?: Maybe<MetadataEdition>;
  /** Update a single "MetadataEditorialVersion" node. */
  updateMetadataEditorialVersion?: Maybe<MetadataEditorialVersion>;
  /** Update a single "MetadataEditStatus" node. */
  updateMetadataEditStatus?: Maybe<MetadataEditStatus>;
  /** Update a single "MetadataEnvironnementPhoto" node. */
  updateMetadataEnvironnementPhoto?: Maybe<MetadataEnvironnementPhoto>;
  /** Update a single "MetadataEquipmentInstitution" node. */
  updateMetadataEquipmentInstitution?: Maybe<MetadataEquipmentInstitution>;
  /** Update a single "MetadataEquipmentManufacturer" node. */
  updateMetadataEquipmentManufacturer?: Maybe<MetadataEquipmentManufacturer>;
  /** Update a single "MetadataEvent" node. */
  updateMetadataEvent?: Maybe<MetadataEvent>;
  /** Update a single "MetadataExif" node. */
  updateMetadataExif?: Maybe<MetadataExif>;
  /** Update a single "MetadataFabStorage" node. */
  updateMetadataFabStorage?: Maybe<MetadataFabStorage>;
  /** Update a single "MetadataFileCheckFor" node. */
  updateMetadataFileCheckFor?: Maybe<MetadataFileCheckFor>;
  /** Update a single "MetadataFileInfoBitsPerPixel" node. */
  updateMetadataFileInfoBitsPerPixel?: Maybe<MetadataFileInfoBitsPerPixel>;
  /** Update a single "MetadataFileInfoFrames" node. */
  updateMetadataFileInfoFrames?: Maybe<MetadataFileInfoFrames>;
  /** Update a single "MetadataFileInfoPhotometricInterpretation" node. */
  updateMetadataFileInfoPhotometricInterpretation?: Maybe<MetadataFileInfoPhotometricInterpretation>;
  /** Update a single "MetadataFileInfoTransferSyntax" node. */
  updateMetadataFileInfoTransferSyntax?: Maybe<MetadataFileInfoTransferSyntax>;
  /** Update a single "MetadataFileStage" node. */
  updateMetadataFileStage?: Maybe<MetadataFileStage>;
  /** Update a single "MetadataFileStatus" node. */
  updateMetadataFileStatus?: Maybe<MetadataFileStatus>;
  /** Update a single "MetadataFileType" node. */
  updateMetadataFileType?: Maybe<MetadataFileType>;
  /** Update a single "MetadataFixtureIdentifier" node. */
  updateMetadataFixtureIdentifier?: Maybe<MetadataFixtureIdentifier>;
  /** Update a single "MetadataFormat" node. */
  updateMetadataFormat?: Maybe<MetadataFormat>;
  /** Update a single "MetadataGpsLatitude" node. */
  updateMetadataGpsLatitude?: Maybe<MetadataGpsLatitude>;
  /** Update a single "MetadataGpsLongitude" node. */
  updateMetadataGpsLongitude?: Maybe<MetadataGpsLongitude>;
  /** Update a single "MetadataGpsProcessingMethod" node. */
  updateMetadataGpsProcessingMethod?: Maybe<MetadataGpsProcessingMethod>;
  /** Update a single "MetadataHeader" node. */
  updateMetadataHeader?: Maybe<MetadataHeader>;
  /** Update a single "MetadataHeaderPrisma" node. */
  updateMetadataHeaderPrisma?: Maybe<MetadataHeaderPrisma>;
  /** Update a single "MetadataHeadline" node. */
  updateMetadataHeadline?: Maybe<MetadataHeadline>;
  /** Update a single "MetadataHistory" node. */
  updateMetadataHistory?: Maybe<MetadataHistory>;
  /** Update a single "MetadataIccProfile" node. */
  updateMetadataIccProfile?: Maybe<MetadataIccProfile>;
  /** Update a single "MetadataIdAssignment" node. */
  updateMetadataIdAssignment?: Maybe<MetadataIdAssignment>;
  /** Update a single "MetadataIdMediaContent" node. */
  updateMetadataIdMediaContent?: Maybe<MetadataIdMediaContent>;
  /** Update a single "MetadataIdPhotoPrisma" node. */
  updateMetadataIdPhotoPrisma?: Maybe<MetadataIdPhotoPrisma>;
  /** Update a single "MetadataIdSubject" node. */
  updateMetadataIdSubject?: Maybe<MetadataIdSubject>;
  /** Update a single "MetadataImageNotes" node. */
  updateMetadataImageNotes?: Maybe<MetadataImageNotes>;
  /** Update a single "MetadataImageUniqueId" node. */
  updateMetadataImageUniqueId?: Maybe<MetadataImageUniqueId>;
  /** Update a single "MetadataInstructions" node. */
  updateMetadataInstructions?: Maybe<MetadataInstructions>;
  /** Update a single "MetadataIntellectualGenre" node. */
  updateMetadataIntellectualGenre?: Maybe<MetadataIntellectualGenre>;
  /** Update a single "MetadataIsoSpeedRatings" node. */
  updateMetadataIsoSpeedRatings?: Maybe<MetadataIsoSpeedRatings>;
  /** Update a single "MetadataIssue" node. */
  updateMetadataIssue?: Maybe<MetadataIssue>;
  /** Update a single "MetadataJobId" node. */
  updateMetadataJobId?: Maybe<MetadataJobId>;
  /** Update a single "MetadataJobProcessing" node. */
  updateMetadataJobProcessing?: Maybe<MetadataJobProcessing>;
  /** Update a single "MetadataLayoutPictureEditing" node. */
  updateMetadataLayoutPictureEditing?: Maybe<MetadataLayoutPictureEditing>;
  /** Update a single "MetadataLayoutStorage" node. */
  updateMetadataLayoutStorage?: Maybe<MetadataLayoutStorage>;
  /** Update a single "MetadataLicense" node. */
  updateMetadataLicense?: Maybe<MetadataLicense>;
  /** Update a single "MetadataLinks" node. */
  updateMetadataLinks?: Maybe<MetadataLinks>;
  /** Update a single "MetadataLocalCaption" node. */
  updateMetadataLocalCaption?: Maybe<MetadataLocalCaption>;
  /** Update a single "MetadataLocation" node. */
  updateMetadataLocation?: Maybe<MetadataLocation>;
  /** Update a single "MetadataMake" node. */
  updateMetadataMake?: Maybe<MetadataMake>;
  /** Update a single "MetadataManifest" node. */
  updateMetadataManifest?: Maybe<MetadataManifest>;
  /** Update a single "MetadataMarked" node. */
  updateMetadataMarked?: Maybe<MetadataMarked>;
  /** Update a single "MetadataMasterDocumentId" node. */
  updateMetadataMasterDocumentId?: Maybe<MetadataMasterDocumentId>;
  /** Update a single "MetadataMaxAvailHeight" node. */
  updateMetadataMaxAvailHeight?: Maybe<MetadataMaxAvailHeight>;
  /** Update a single "MetadataMaxAvailWidth" node. */
  updateMetadataMaxAvailWidth?: Maybe<MetadataMaxAvailWidth>;
  /** Update a single "MetadataMinorModelAgeDisclosure" node. */
  updateMetadataMinorModelAgeDisclosure?: Maybe<MetadataMinorModelAgeDisclosure>;
  /** Update a single "MetadataModel" node. */
  updateMetadataModel?: Maybe<MetadataModel>;
  /** Update a single "MetadataModelAge" node. */
  updateMetadataModelAge?: Maybe<MetadataModelAge>;
  /** Update a single "MetadataModelReleaseId" node. */
  updateMetadataModelReleaseId?: Maybe<MetadataModelReleaseId>;
  /** Update a single "MetadataModelReleaseStatus" node. */
  updateMetadataModelReleaseStatus?: Maybe<MetadataModelReleaseStatus>;
  /** Update a single "MetadataMorePermissions" node. */
  updateMetadataMorePermissions?: Maybe<MetadataMorePermissions>;
  /** Update a single "MetadataObjectCycle" node. */
  updateMetadataObjectCycle?: Maybe<MetadataObjectCycle>;
  /** Update a single "MetadataOrganisationInImageName" node. */
  updateMetadataOrganisationInImageName?: Maybe<MetadataOrganisationInImageName>;
  /** Update a single "MetadataOriginalColor" node. */
  updateMetadataOriginalColor?: Maybe<MetadataOriginalColor>;
  /** Update a single "MetadataOriginalIccProfile" node. */
  updateMetadataOriginalIccProfile?: Maybe<MetadataOriginalIccProfile>;
  /** Update a single "MetadataOriginalName" node. */
  updateMetadataOriginalName?: Maybe<MetadataOriginalName>;
  /** Update a single "MetadataOriginalWeigth" node. */
  updateMetadataOriginalWeigth?: Maybe<MetadataOriginalWeigth>;
  /** Update a single "MetadataOwner" node. */
  updateMetadataOwner?: Maybe<MetadataOwner>;
  /** Update a single "MetadataOwnerId" node. */
  updateMetadataOwnerId?: Maybe<MetadataOwnerId>;
  /** Update a single "MetadataPage" node. */
  updateMetadataPage?: Maybe<MetadataPage>;
  /** Update a single "MetadataPagePrisma" node. */
  updateMetadataPagePrisma?: Maybe<MetadataPagePrisma>;
  /** Update a single "MetadataPaintBasedCorrections" node. */
  updateMetadataPaintBasedCorrections?: Maybe<MetadataPaintBasedCorrections>;
  /** Update a single "MetadataPatientDob" node. */
  updateMetadataPatientDob?: Maybe<MetadataPatientDob>;
  /** Update a single "MetadataPatientId" node. */
  updateMetadataPatientId?: Maybe<MetadataPatientId>;
  /** Update a single "MetadataPatientName" node. */
  updateMetadataPatientName?: Maybe<MetadataPatientName>;
  /** Update a single "MetadataPatientSex" node. */
  updateMetadataPatientSex?: Maybe<MetadataPatientSex>;
  /** Update a single "MetadataPersonInImage" node. */
  updateMetadataPersonInImage?: Maybe<MetadataPersonInImage>;
  /** Update a single "MetadataPhotoStorage" node. */
  updateMetadataPhotoStorage?: Maybe<MetadataPhotoStorage>;
  /** Update a single "MetadataPrepressPictureEditing" node. */
  updateMetadataPrepressPictureEditing?: Maybe<MetadataPrepressPictureEditing>;
  /** Update a single "MetadataPriceLevel" node. */
  updateMetadataPriceLevel?: Maybe<MetadataPriceLevel>;
  /** Update a single "MetadataPrintingProfile" node. */
  updateMetadataPrintingProfile?: Maybe<MetadataPrintingProfile>;
  /** Update a single "MetadataPrismaProduction" node. */
  updateMetadataPrismaProduction?: Maybe<MetadataPrismaProduction>;
  /** Update a single "MetadataProcessHistory" node. */
  updateMetadataProcessHistory?: Maybe<MetadataProcessHistory>;
  /** Update a single "MetadataProcessParameter" node. */
  updateMetadataProcessParameter?: Maybe<MetadataProcessParameter>;
  /** Update a single "MetadataProcessStatus" node. */
  updateMetadataProcessStatus?: Maybe<MetadataProcessStatus>;
  /** Update a single "MetadataProduct" node. */
  updateMetadataProduct?: Maybe<MetadataProduct>;
  /** Update a single "MetadataProducts" node. */
  updateMetadataProducts?: Maybe<MetadataProducts>;
  /** Update a single "MetadataProductShortName" node. */
  updateMetadataProductShortName?: Maybe<MetadataProductShortName>;
  /** Update a single "MetadataProduits" node. */
  updateMetadataProduits?: Maybe<MetadataProduits>;
  /** Update a single "MetadataProgramVersion" node. */
  updateMetadataProgramVersion?: Maybe<MetadataProgramVersion>;
  /** Update a single "MetadataPropertyReleaseId" node. */
  updateMetadataPropertyReleaseId?: Maybe<MetadataPropertyReleaseId>;
  /** Update a single "MetadataPropertyReleaseStatus" node. */
  updateMetadataPropertyReleaseStatus?: Maybe<MetadataPropertyReleaseStatus>;
  /** Update a single "MetadataPublisher" node. */
  updateMetadataPublisher?: Maybe<MetadataPublisher>;
  /** Update a single "MetadataPublishingIssue" node. */
  updateMetadataPublishingIssue?: Maybe<MetadataPublishingIssue>;
  /** Update a single "MetadataPublishingSubject" node. */
  updateMetadataPublishingSubject?: Maybe<MetadataPublishingSubject>;
  /** Update a single "MetadataQualifiedUsePrismaBy" node. */
  updateMetadataQualifiedUsePrismaBy?: Maybe<MetadataQualifiedUsePrismaBy>;
  /** Update a single "MetadataQualifiedUsePrismaDate" node. */
  updateMetadataQualifiedUsePrismaDate?: Maybe<MetadataQualifiedUsePrismaDate>;
  /** Update a single "MetadataQualifiedUsePrismaDuration" node. */
  updateMetadataQualifiedUsePrismaDuration?: Maybe<MetadataQualifiedUsePrismaDuration>;
  /** Update a single "MetadataQualifiedUsePrismaSupport" node. */
  updateMetadataQualifiedUsePrismaSupport?: Maybe<MetadataQualifiedUsePrismaSupport>;
  /** Update a single "MetadataRating" node. */
  updateMetadataRating?: Maybe<MetadataRating>;
  /** Update a single "MetadataReferenceDate" node. */
  updateMetadataReferenceDate?: Maybe<MetadataReferenceDate>;
  /** Update a single "MetadataReferenceNumber" node. */
  updateMetadataReferenceNumber?: Maybe<MetadataReferenceNumber>;
  /** Update a single "MetadataReferenceService" node. */
  updateMetadataReferenceService?: Maybe<MetadataReferenceService>;
  /** Update a single "MetadataReleaseDate" node. */
  updateMetadataReleaseDate?: Maybe<MetadataReleaseDate>;
  /** Update a single "MetadataReleaseTime" node. */
  updateMetadataReleaseTime?: Maybe<MetadataReleaseTime>;
  /** Update a single "MetadataRequiredPermission" node. */
  updateMetadataRequiredPermission?: Maybe<MetadataRequiredPermission>;
  /** Update a single "MetadataResolutionKind" node. */
  updateMetadataResolutionKind?: Maybe<MetadataResolutionKind>;
  /** Update a single "MetadataRights" node. */
  updateMetadataRights?: Maybe<MetadataRights>;
  /** Update a single "MetadataRoyaltyfree" node. */
  updateMetadataRoyaltyfree?: Maybe<MetadataRoyaltyfree>;
  /** Update a single "MetadataScene" node. */
  updateMetadataScene?: Maybe<MetadataScene>;
  /** Update a single "MetadataSection" node. */
  updateMetadataSection?: Maybe<MetadataSection>;
  /** Update a single "MetadataSentTo" node. */
  updateMetadataSentTo?: Maybe<MetadataSentTo>;
  /** Update a single "MetadataSerialNumber" node. */
  updateMetadataSerialNumber?: Maybe<MetadataSerialNumber>;
  /** Update a single "MetadataSeriesDateTime" node. */
  updateMetadataSeriesDateTime?: Maybe<MetadataSeriesDateTime>;
  /** Update a single "MetadataSeriesDescription" node. */
  updateMetadataSeriesDescription?: Maybe<MetadataSeriesDescription>;
  /** Update a single "MetadataSeriesModality" node. */
  updateMetadataSeriesModality?: Maybe<MetadataSeriesModality>;
  /** Update a single "MetadataSeriesNumber" node. */
  updateMetadataSeriesNumber?: Maybe<MetadataSeriesNumber>;
  /** Update a single "MetadataShortUniqueId" node. */
  updateMetadataShortUniqueId?: Maybe<MetadataShortUniqueId>;
  /** Update a single "MetadataSource" node. */
  updateMetadataSource?: Maybe<MetadataSource>;
  /** Update a single "MetadataState" node. */
  updateMetadataState?: Maybe<MetadataState>;
  /** Update a single "MetadataStudyDateTime" node. */
  updateMetadataStudyDateTime?: Maybe<MetadataStudyDateTime>;
  /** Update a single "MetadataStudyDescription" node. */
  updateMetadataStudyDescription?: Maybe<MetadataStudyDescription>;
  /** Update a single "MetadataStudyId" node. */
  updateMetadataStudyId?: Maybe<MetadataStudyId>;
  /** Update a single "MetadataStudyPhysician" node. */
  updateMetadataStudyPhysician?: Maybe<MetadataStudyPhysician>;
  /** Update a single "MetadataSubject" node. */
  updateMetadataSubject?: Maybe<MetadataSubject>;
  /** Update a single "MetadataSubjectCode" node. */
  updateMetadataSubjectCode?: Maybe<MetadataSubjectCode>;
  /** Update a single "MetadataSubjectPrisma" node. */
  updateMetadataSubjectPrisma?: Maybe<MetadataSubjectPrisma>;
  /** Update a single "MetadataSupplementalCategories" node. */
  updateMetadataSupplementalCategories?: Maybe<MetadataSupplementalCategories>;
  /** Update a single "MetadataTargetVersion" node. */
  updateMetadataTargetVersion?: Maybe<MetadataTargetVersion>;
  /** Update a single "MetadataTitle" node. */
  updateMetadataTitle?: Maybe<MetadataTitle>;
  /** Update a single "MetadataToneCurve" node. */
  updateMetadataToneCurve?: Maybe<MetadataToneCurve>;
  /** Update a single "MetadataTransferredBy" node. */
  updateMetadataTransferredBy?: Maybe<MetadataTransferredBy>;
  /** Update a single "MetadataTransferredByEmail" node. */
  updateMetadataTransferredByEmail?: Maybe<MetadataTransferredByEmail>;
  /** Update a single "MetadataTransferredByFullName" node. */
  updateMetadataTransferredByFullName?: Maybe<MetadataTransferredByFullName>;
  /** Update a single "MetadataTransmissionReference" node. */
  updateMetadataTransmissionReference?: Maybe<MetadataTransmissionReference>;
  /** Update a single "MetadataType" node. */
  updateMetadataType?: Maybe<MetadataType>;
  /** Update a single "MetadataUndersubject" node. */
  updateMetadataUndersubject?: Maybe<MetadataUndersubject>;
  /** Update a single "MetadataUnderUnderSubject" node. */
  updateMetadataUnderUnderSubject?: Maybe<MetadataUnderUnderSubject>;
  /** Update a single "MetadataUniqueId" node. */
  updateMetadataUniqueId?: Maybe<MetadataUniqueId>;
  /** Update a single "MetadataUnit" node. */
  updateMetadataUnit?: Maybe<MetadataUnit>;
  /** Update a single "MetadataUnitShortName" node. */
  updateMetadataUnitShortName?: Maybe<MetadataUnitShortName>;
  /** Update a single "MetadataUploadedBy" node. */
  updateMetadataUploadedBy?: Maybe<MetadataUploadedBy>;
  /** Update a single "MetadataUploadedByFullName" node. */
  updateMetadataUploadedByFullName?: Maybe<MetadataUploadedByFullName>;
  /** Update a single "MetadataUploadTime" node. */
  updateMetadataUploadTime?: Maybe<MetadataUploadTime>;
  /** Update a single "MetadataUrgency" node. */
  updateMetadataUrgency?: Maybe<MetadataUrgency>;
  /** Update a single "MetadataUsageTerms" node. */
  updateMetadataUsageTerms?: Maybe<MetadataUsageTerms>;
  /** Update a single "MetadataUserComment" node. */
  updateMetadataUserComment?: Maybe<MetadataUserComment>;
  /** Update a single "MetadataUserDefined195" node. */
  updateMetadataUserDefined195?: Maybe<MetadataUserDefined195>;
  /** Update a single "MetadataUserDefined237" node. */
  updateMetadataUserDefined237?: Maybe<MetadataUserDefined237>;
  /** Update a single "MetadataUserDefined238" node. */
  updateMetadataUserDefined238?: Maybe<MetadataUserDefined238>;
  /** Update a single "MetadataUserDefined239" node. */
  updateMetadataUserDefined239?: Maybe<MetadataUserDefined239>;
  /** Update a single "MetadataUserDefined242" node. */
  updateMetadataUserDefined242?: Maybe<MetadataUserDefined242>;
  /** Update a single "MetadataUserDefined62" node. */
  updateMetadataUserDefined62?: Maybe<MetadataUserDefined62>;
  /** Update a single "MetadataValid" node. */
  updateMetadataValid?: Maybe<MetadataValid>;
  /** Update a single "MetadataVersion" node. */
  updateMetadataVersion?: Maybe<MetadataVersion>;
  /** Update a single "MetadataWebStatement" node. */
  updateMetadataWebStatement?: Maybe<MetadataWebStatement>;
  /** Update a single "MetadataWorkflowKind" node. */
  updateMetadataWorkflowKind?: Maybe<MetadataWorkflowKind>;
  /** Update a single "MetadataXmpFileStamps" node. */
  updateMetadataXmpFileStamps?: Maybe<MetadataXmpFileStamps>;
  /** Update a single "MetadataXmpHistory" node. */
  updateMetadataXmpHistory?: Maybe<MetadataXmpHistory>;
  /** Update a single "Photo" node. */
  updatePhoto?: Maybe<Photo>;
  /** Update files metadatas */
  updatePhotoFile?: Maybe<Photo>;
  /** Update a single "PhotoMetadata" node. */
  updatePhotoMetadata?: Maybe<PhotoMetadata>;
  /** Update a single "PhotoPublication" node. */
  updatePhotoPublication?: Maybe<PhotoPublication>;
  /** Update or create a single "Agency" node. */
  upsertAgency: Agency;
  /** Update or create a single "Archive" node. */
  upsertArchive: Archive;
  /** Update or create a single "ArchiveAgency" node. */
  upsertArchiveAgency: ArchiveAgency;
  /** Update or create a single "Category" node. */
  upsertCategory: Category;
  /** Update or create a single "DeliverySubject" node. */
  upsertDeliverySubject: DeliverySubject;
  /** Update or create a single "GcImportSetting" node. */
  upsertGcImportSetting: GcImportSetting;
  /** Update or create a single "ImportQueue" node. */
  upsertImportQueue: ImportQueue;
  /** Update or create a single "Metadata" node. */
  upsertMetadata: Metadata;
  /** Update or create a single "MetadataAccessRights" node. */
  upsertMetadataAccessRights: MetadataAccessRights;
  /** Update or create a single "MetadataAddlModelInfo" node. */
  upsertMetadataAddlModelInfo: MetadataAddlModelInfo;
  /** Update or create a single "MetadataArchiveChild" node. */
  upsertMetadataArchiveChild: MetadataArchiveChild;
  /** Update or create a single "MetadataArchiveParent" node. */
  upsertMetadataArchiveParent: MetadataArchiveParent;
  /** Update or create a single "MetadataAssetState" node. */
  upsertMetadataAssetState: MetadataAssetState;
  /** Update or create a single "MetadataAttributionName" node. */
  upsertMetadataAttributionName: MetadataAttributionName;
  /** Update or create a single "MetadataAttributionUrl" node. */
  upsertMetadataAttributionUrl: MetadataAttributionUrl;
  /** Update or create a single "MetadataAudience" node. */
  upsertMetadataAudience: MetadataAudience;
  /** Update or create a single "MetadataAuthorsPosition" node. */
  upsertMetadataAuthorsPosition: MetadataAuthorsPosition;
  /** Update or create a single "MetadataBackupName" node. */
  upsertMetadataBackupName: MetadataBackupName;
  /** Update or create a single "MetadataBitmapGrayscalePicture" node. */
  upsertMetadataBitmapGrayscalePicture: MetadataBitmapGrayscalePicture;
  /** Update or create a single "MetadataBrand" node. */
  upsertMetadataBrand: MetadataBrand;
  /** Update or create a single "MetadataBrandPrisma" node. */
  upsertMetadataBrandPrisma: MetadataBrandPrisma;
  /** Update or create a single "MetadataBrandShortName" node. */
  upsertMetadataBrandShortName: MetadataBrandShortName;
  /** Update or create a single "MetadataCaptionWriter" node. */
  upsertMetadataCaptionWriter: MetadataCaptionWriter;
  /** Update or create a single "MetadataCategory" node. */
  upsertMetadataCategory: MetadataCategory;
  /** Update or create a single "MetadataCategoryPrisma" node. */
  upsertMetadataCategoryPrisma: MetadataCategoryPrisma;
  /** Update or create a single "MetadataCertificate" node. */
  upsertMetadataCertificate: MetadataCertificate;
  /** Update or create a single "MetadataCiAdrCity" node. */
  upsertMetadataCiAdrCity: MetadataCiAdrCity;
  /** Update or create a single "MetadataCiAdrCtry" node. */
  upsertMetadataCiAdrCtry: MetadataCiAdrCtry;
  /** Update or create a single "MetadataCiAdrExtadr" node. */
  upsertMetadataCiAdrExtadr: MetadataCiAdrExtadr;
  /** Update or create a single "MetadataCiAdrPcode" node. */
  upsertMetadataCiAdrPcode: MetadataCiAdrPcode;
  /** Update or create a single "MetadataCiAdrRegion" node. */
  upsertMetadataCiAdrRegion: MetadataCiAdrRegion;
  /** Update or create a single "MetadataCiEmailWork" node. */
  upsertMetadataCiEmailWork: MetadataCiEmailWork;
  /** Update or create a single "MetadataCiTelWork" node. */
  upsertMetadataCiTelWork: MetadataCiTelWork;
  /** Update or create a single "MetadataCity" node. */
  upsertMetadataCity: MetadataCity;
  /** Update or create a single "MetadataCiUrlWork" node. */
  upsertMetadataCiUrlWork: MetadataCiUrlWork;
  /** Update or create a single "MetadataClassify" node. */
  upsertMetadataClassify: MetadataClassify;
  /** Update or create a single "MetadataCompany" node. */
  upsertMetadataCompany: MetadataCompany;
  /** Update or create a single "MetadataCompanyShortName" node. */
  upsertMetadataCompanyShortName: MetadataCompanyShortName;
  /** Update or create a single "MetadataConfidentiality" node. */
  upsertMetadataConfidentiality: MetadataConfidentiality;
  /** Update or create a single "MetadataContainedIn" node. */
  upsertMetadataContainedIn: MetadataContainedIn;
  /** Update or create a single "MetadataContentMediaKind" node. */
  upsertMetadataContentMediaKind: MetadataContentMediaKind;
  /** Update or create a single "MetadataContentValue" node. */
  upsertMetadataContentValue: MetadataContentValue;
  /** Update or create a single "MetadataCopyrightLayer" node. */
  upsertMetadataCopyrightLayer: MetadataCopyrightLayer;
  /** Update or create a single "MetadataCountry" node. */
  upsertMetadataCountry: MetadataCountry;
  /** Update or create a single "MetadataCountryCode" node. */
  upsertMetadataCountryCode: MetadataCountryCode;
  /** Update or create a single "MetadataCreatedTime" node. */
  upsertMetadataCreatedTime: MetadataCreatedTime;
  /** Update or create a single "MetadataCreator" node. */
  upsertMetadataCreator: MetadataCreator;
  /** Update or create a single "MetadataCreatorTool" node. */
  upsertMetadataCreatorTool: MetadataCreatorTool;
  /** Update or create a single "MetadataCredit" node. */
  upsertMetadataCredit: MetadataCredit;
  /** Update or create a single "MetadataCustomField14" node. */
  upsertMetadataCustomField14: MetadataCustomField14;
  /** Update or create a single "MetadataCustomField15" node. */
  upsertMetadataCustomField15: MetadataCustomField15;
  /** Update or create a single "MetadataCustomField16" node. */
  upsertMetadataCustomField16: MetadataCustomField16;
  /** Update or create a single "MetadataCustomField17" node. */
  upsertMetadataCustomField17: MetadataCustomField17;
  /** Update or create a single "MetadataCustomField20" node. */
  upsertMetadataCustomField20: MetadataCustomField20;
  /** Update or create a single "MetadataCustomField5" node. */
  upsertMetadataCustomField5: MetadataCustomField5;
  /** Update or create a single "MetadataCustomField6" node. */
  upsertMetadataCustomField6: MetadataCustomField6;
  /** Update or create a single "MetadataCustomField7" node. */
  upsertMetadataCustomField7: MetadataCustomField7;
  /** Update or create a single "MetadataCustomField9" node. */
  upsertMetadataCustomField9: MetadataCustomField9;
  /** Update or create a single "MetadataDateCreated" node. */
  upsertMetadataDateCreated: MetadataDateCreated;
  /** Update or create a single "MetadataDateTimeDigitized" node. */
  upsertMetadataDateTimeDigitized: MetadataDateTimeDigitized;
  /** Update or create a single "MetadataDateTimeOriginal" node. */
  upsertMetadataDateTimeOriginal: MetadataDateTimeOriginal;
  /** Update or create a single "MetadataDeliveryAccount" node. */
  upsertMetadataDeliveryAccount: MetadataDeliveryAccount;
  /** Update or create a single "MetadataDeliveryCompany" node. */
  upsertMetadataDeliveryCompany: MetadataDeliveryCompany;
  /** Update or create a single "MetadataDeliveryCopyright" node. */
  upsertMetadataDeliveryCopyright: MetadataDeliveryCopyright;
  /** Update or create a single "MetadataDeliveryDateFolder" node. */
  upsertMetadataDeliveryDateFolder: MetadataDeliveryDateFolder;
  /** Update or create a single "MetadataDeliveryDateTime" node. */
  upsertMetadataDeliveryDateTime: MetadataDeliveryDateTime;
  /** Update or create a single "MetadataDeliveryDcCreator" node. */
  upsertMetadataDeliveryDcCreator: MetadataDeliveryDcCreator;
  /** Update or create a single "MetadataDeliveryDcRights" node. */
  upsertMetadataDeliveryDcRights: MetadataDeliveryDcRights;
  /** Update or create a single "MetadataDeliveryFileType" node. */
  upsertMetadataDeliveryFileType: MetadataDeliveryFileType;
  /** Update or create a single "MetadataDeliveryFolder" node. */
  upsertMetadataDeliveryFolder: MetadataDeliveryFolder;
  /** Update or create a single "MetadataDeliveryKind" node. */
  upsertMetadataDeliveryKind: MetadataDeliveryKind;
  /** Update or create a single "MetadataDeliveryPath" node. */
  upsertMetadataDeliveryPath: MetadataDeliveryPath;
  /** Update or create a single "MetadataDeliveryPersonShownintheImage" node. */
  upsertMetadataDeliveryPersonShownintheImage: MetadataDeliveryPersonShownintheImage;
  /** Update or create a single "MetadataDeliveryPhotoshopCredit" node. */
  upsertMetadataDeliveryPhotoshopCredit: MetadataDeliveryPhotoshopCredit;
  /** Update or create a single "MetadataDeliveryPhotoshopSource" node. */
  upsertMetadataDeliveryPhotoshopSource: MetadataDeliveryPhotoshopSource;
  /** Update or create a single "MetadataDeliveryService" node. */
  upsertMetadataDeliveryService: MetadataDeliveryService;
  /** Update or create a single "MetadataDeliverySubject" node. */
  upsertMetadataDeliverySubject: MetadataDeliverySubject;
  /** Update or create a single "MetadataDeliveryUnderSubject" node. */
  upsertMetadataDeliveryUnderSubject: MetadataDeliveryUnderSubject;
  /** Update or create a single "MetadataDepartment" node. */
  upsertMetadataDepartment: MetadataDepartment;
  /** Update or create a single "MetadataDescription" node. */
  upsertMetadataDescription: MetadataDescription;
  /** Update or create a single "MetadataDigitalAssetUrl" node. */
  upsertMetadataDigitalAssetUrl: MetadataDigitalAssetUrl;
  /** Update or create a single "MetadataDivision" node. */
  upsertMetadataDivision: MetadataDivision;
  /** Update or create a single "MetadataDocumentText" node. */
  upsertMetadataDocumentText: MetadataDocumentText;
  /** Update or create a single "MetadataEdition" node. */
  upsertMetadataEdition: MetadataEdition;
  /** Update or create a single "MetadataEditorialVersion" node. */
  upsertMetadataEditorialVersion: MetadataEditorialVersion;
  /** Update or create a single "MetadataEditStatus" node. */
  upsertMetadataEditStatus: MetadataEditStatus;
  /** Update or create a single "MetadataEnvironnementPhoto" node. */
  upsertMetadataEnvironnementPhoto: MetadataEnvironnementPhoto;
  /** Update or create a single "MetadataEquipmentInstitution" node. */
  upsertMetadataEquipmentInstitution: MetadataEquipmentInstitution;
  /** Update or create a single "MetadataEquipmentManufacturer" node. */
  upsertMetadataEquipmentManufacturer: MetadataEquipmentManufacturer;
  /** Update or create a single "MetadataEvent" node. */
  upsertMetadataEvent: MetadataEvent;
  /** Update or create a single "MetadataExif" node. */
  upsertMetadataExif: MetadataExif;
  /** Update or create a single "MetadataFabStorage" node. */
  upsertMetadataFabStorage: MetadataFabStorage;
  /** Update or create a single "MetadataFileCheckFor" node. */
  upsertMetadataFileCheckFor: MetadataFileCheckFor;
  /** Update or create a single "MetadataFileInfoBitsPerPixel" node. */
  upsertMetadataFileInfoBitsPerPixel: MetadataFileInfoBitsPerPixel;
  /** Update or create a single "MetadataFileInfoFrames" node. */
  upsertMetadataFileInfoFrames: MetadataFileInfoFrames;
  /** Update or create a single "MetadataFileInfoPhotometricInterpretation" node. */
  upsertMetadataFileInfoPhotometricInterpretation: MetadataFileInfoPhotometricInterpretation;
  /** Update or create a single "MetadataFileInfoTransferSyntax" node. */
  upsertMetadataFileInfoTransferSyntax: MetadataFileInfoTransferSyntax;
  /** Update or create a single "MetadataFileStage" node. */
  upsertMetadataFileStage: MetadataFileStage;
  /** Update or create a single "MetadataFileStatus" node. */
  upsertMetadataFileStatus: MetadataFileStatus;
  /** Update or create a single "MetadataFileType" node. */
  upsertMetadataFileType: MetadataFileType;
  /** Update or create a single "MetadataFixtureIdentifier" node. */
  upsertMetadataFixtureIdentifier: MetadataFixtureIdentifier;
  /** Update or create a single "MetadataFormat" node. */
  upsertMetadataFormat: MetadataFormat;
  /** Update or create a single "MetadataGpsLatitude" node. */
  upsertMetadataGpsLatitude: MetadataGpsLatitude;
  /** Update or create a single "MetadataGpsLongitude" node. */
  upsertMetadataGpsLongitude: MetadataGpsLongitude;
  /** Update or create a single "MetadataGpsProcessingMethod" node. */
  upsertMetadataGpsProcessingMethod: MetadataGpsProcessingMethod;
  /** Update or create a single "MetadataHeader" node. */
  upsertMetadataHeader: MetadataHeader;
  /** Update or create a single "MetadataHeaderPrisma" node. */
  upsertMetadataHeaderPrisma: MetadataHeaderPrisma;
  /** Update or create a single "MetadataHeadline" node. */
  upsertMetadataHeadline: MetadataHeadline;
  /** Update or create a single "MetadataHistory" node. */
  upsertMetadataHistory: MetadataHistory;
  /** Update or create a single "MetadataIccProfile" node. */
  upsertMetadataIccProfile: MetadataIccProfile;
  /** Update or create a single "MetadataIdAssignment" node. */
  upsertMetadataIdAssignment: MetadataIdAssignment;
  /** Update or create a single "MetadataIdMediaContent" node. */
  upsertMetadataIdMediaContent: MetadataIdMediaContent;
  /** Update or create a single "MetadataIdPhotoPrisma" node. */
  upsertMetadataIdPhotoPrisma: MetadataIdPhotoPrisma;
  /** Update or create a single "MetadataIdSubject" node. */
  upsertMetadataIdSubject: MetadataIdSubject;
  /** Update or create a single "MetadataImageNotes" node. */
  upsertMetadataImageNotes: MetadataImageNotes;
  /** Update or create a single "MetadataImageUniqueId" node. */
  upsertMetadataImageUniqueId: MetadataImageUniqueId;
  /** Update or create a single "MetadataInstructions" node. */
  upsertMetadataInstructions: MetadataInstructions;
  /** Update or create a single "MetadataIntellectualGenre" node. */
  upsertMetadataIntellectualGenre: MetadataIntellectualGenre;
  /** Update or create a single "MetadataIsoSpeedRatings" node. */
  upsertMetadataIsoSpeedRatings: MetadataIsoSpeedRatings;
  /** Update or create a single "MetadataIssue" node. */
  upsertMetadataIssue: MetadataIssue;
  /** Update or create a single "MetadataJobId" node. */
  upsertMetadataJobId: MetadataJobId;
  /** Update or create a single "MetadataJobProcessing" node. */
  upsertMetadataJobProcessing: MetadataJobProcessing;
  /** Update or create a single "MetadataLayoutPictureEditing" node. */
  upsertMetadataLayoutPictureEditing: MetadataLayoutPictureEditing;
  /** Update or create a single "MetadataLayoutStorage" node. */
  upsertMetadataLayoutStorage: MetadataLayoutStorage;
  /** Update or create a single "MetadataLicense" node. */
  upsertMetadataLicense: MetadataLicense;
  /** Update or create a single "MetadataLinks" node. */
  upsertMetadataLinks: MetadataLinks;
  /** Update or create a single "MetadataLocalCaption" node. */
  upsertMetadataLocalCaption: MetadataLocalCaption;
  /** Update or create a single "MetadataLocation" node. */
  upsertMetadataLocation: MetadataLocation;
  /** Update or create a single "MetadataMake" node. */
  upsertMetadataMake: MetadataMake;
  /** Update or create a single "MetadataManifest" node. */
  upsertMetadataManifest: MetadataManifest;
  /** Update or create a single "MetadataMarked" node. */
  upsertMetadataMarked: MetadataMarked;
  /** Update or create a single "MetadataMasterDocumentId" node. */
  upsertMetadataMasterDocumentId: MetadataMasterDocumentId;
  /** Update or create a single "MetadataMaxAvailHeight" node. */
  upsertMetadataMaxAvailHeight: MetadataMaxAvailHeight;
  /** Update or create a single "MetadataMaxAvailWidth" node. */
  upsertMetadataMaxAvailWidth: MetadataMaxAvailWidth;
  /** Update or create a single "MetadataMinorModelAgeDisclosure" node. */
  upsertMetadataMinorModelAgeDisclosure: MetadataMinorModelAgeDisclosure;
  /** Update or create a single "MetadataModel" node. */
  upsertMetadataModel: MetadataModel;
  /** Update or create a single "MetadataModelAge" node. */
  upsertMetadataModelAge: MetadataModelAge;
  /** Update or create a single "MetadataModelReleaseId" node. */
  upsertMetadataModelReleaseId: MetadataModelReleaseId;
  /** Update or create a single "MetadataModelReleaseStatus" node. */
  upsertMetadataModelReleaseStatus: MetadataModelReleaseStatus;
  /** Update or create a single "MetadataMorePermissions" node. */
  upsertMetadataMorePermissions: MetadataMorePermissions;
  /** Update or create a single "MetadataObjectCycle" node. */
  upsertMetadataObjectCycle: MetadataObjectCycle;
  /** Update or create a single "MetadataOrganisationInImageName" node. */
  upsertMetadataOrganisationInImageName: MetadataOrganisationInImageName;
  /** Update or create a single "MetadataOriginalColor" node. */
  upsertMetadataOriginalColor: MetadataOriginalColor;
  /** Update or create a single "MetadataOriginalIccProfile" node. */
  upsertMetadataOriginalIccProfile: MetadataOriginalIccProfile;
  /** Update or create a single "MetadataOriginalName" node. */
  upsertMetadataOriginalName: MetadataOriginalName;
  /** Update or create a single "MetadataOriginalWeigth" node. */
  upsertMetadataOriginalWeigth: MetadataOriginalWeigth;
  /** Update or create a single "MetadataOwner" node. */
  upsertMetadataOwner: MetadataOwner;
  /** Update or create a single "MetadataOwnerId" node. */
  upsertMetadataOwnerId: MetadataOwnerId;
  /** Update or create a single "MetadataPage" node. */
  upsertMetadataPage: MetadataPage;
  /** Update or create a single "MetadataPagePrisma" node. */
  upsertMetadataPagePrisma: MetadataPagePrisma;
  /** Update or create a single "MetadataPaintBasedCorrections" node. */
  upsertMetadataPaintBasedCorrections: MetadataPaintBasedCorrections;
  /** Update or create a single "MetadataPatientDob" node. */
  upsertMetadataPatientDob: MetadataPatientDob;
  /** Update or create a single "MetadataPatientId" node. */
  upsertMetadataPatientId: MetadataPatientId;
  /** Update or create a single "MetadataPatientName" node. */
  upsertMetadataPatientName: MetadataPatientName;
  /** Update or create a single "MetadataPatientSex" node. */
  upsertMetadataPatientSex: MetadataPatientSex;
  /** Update or create a single "MetadataPersonInImage" node. */
  upsertMetadataPersonInImage: MetadataPersonInImage;
  /** Update or create a single "MetadataPhotoStorage" node. */
  upsertMetadataPhotoStorage: MetadataPhotoStorage;
  /** Update or create a single "MetadataPrepressPictureEditing" node. */
  upsertMetadataPrepressPictureEditing: MetadataPrepressPictureEditing;
  /** Update or create a single "MetadataPriceLevel" node. */
  upsertMetadataPriceLevel: MetadataPriceLevel;
  /** Update or create a single "MetadataPrintingProfile" node. */
  upsertMetadataPrintingProfile: MetadataPrintingProfile;
  /** Update or create a single "MetadataPrismaProduction" node. */
  upsertMetadataPrismaProduction: MetadataPrismaProduction;
  /** Update or create a single "MetadataProcessHistory" node. */
  upsertMetadataProcessHistory: MetadataProcessHistory;
  /** Update or create a single "MetadataProcessParameter" node. */
  upsertMetadataProcessParameter: MetadataProcessParameter;
  /** Update or create a single "MetadataProcessStatus" node. */
  upsertMetadataProcessStatus: MetadataProcessStatus;
  /** Update or create a single "MetadataProduct" node. */
  upsertMetadataProduct: MetadataProduct;
  /** Update or create a single "MetadataProducts" node. */
  upsertMetadataProducts: MetadataProducts;
  /** Update or create a single "MetadataProductShortName" node. */
  upsertMetadataProductShortName: MetadataProductShortName;
  /** Update or create a single "MetadataProduits" node. */
  upsertMetadataProduits: MetadataProduits;
  /** Update or create a single "MetadataProgramVersion" node. */
  upsertMetadataProgramVersion: MetadataProgramVersion;
  /** Update or create a single "MetadataPropertyReleaseId" node. */
  upsertMetadataPropertyReleaseId: MetadataPropertyReleaseId;
  /** Update or create a single "MetadataPropertyReleaseStatus" node. */
  upsertMetadataPropertyReleaseStatus: MetadataPropertyReleaseStatus;
  /** Update or create a single "MetadataPublisher" node. */
  upsertMetadataPublisher: MetadataPublisher;
  /** Update or create a single "MetadataPublishingIssue" node. */
  upsertMetadataPublishingIssue: MetadataPublishingIssue;
  /** Update or create a single "MetadataPublishingSubject" node. */
  upsertMetadataPublishingSubject: MetadataPublishingSubject;
  /** Update or create a single "MetadataQualifiedUsePrismaBy" node. */
  upsertMetadataQualifiedUsePrismaBy: MetadataQualifiedUsePrismaBy;
  /** Update or create a single "MetadataQualifiedUsePrismaDate" node. */
  upsertMetadataQualifiedUsePrismaDate: MetadataQualifiedUsePrismaDate;
  /** Update or create a single "MetadataQualifiedUsePrismaDuration" node. */
  upsertMetadataQualifiedUsePrismaDuration: MetadataQualifiedUsePrismaDuration;
  /** Update or create a single "MetadataQualifiedUsePrismaSupport" node. */
  upsertMetadataQualifiedUsePrismaSupport: MetadataQualifiedUsePrismaSupport;
  /** Update or create a single "MetadataRating" node. */
  upsertMetadataRating: MetadataRating;
  /** Update or create a single "MetadataReferenceDate" node. */
  upsertMetadataReferenceDate: MetadataReferenceDate;
  /** Update or create a single "MetadataReferenceNumber" node. */
  upsertMetadataReferenceNumber: MetadataReferenceNumber;
  /** Update or create a single "MetadataReferenceService" node. */
  upsertMetadataReferenceService: MetadataReferenceService;
  /** Update or create a single "MetadataReleaseDate" node. */
  upsertMetadataReleaseDate: MetadataReleaseDate;
  /** Update or create a single "MetadataReleaseTime" node. */
  upsertMetadataReleaseTime: MetadataReleaseTime;
  /** Update or create a single "MetadataRequiredPermission" node. */
  upsertMetadataRequiredPermission: MetadataRequiredPermission;
  /** Update or create a single "MetadataResolutionKind" node. */
  upsertMetadataResolutionKind: MetadataResolutionKind;
  /** Update or create a single "MetadataRights" node. */
  upsertMetadataRights: MetadataRights;
  /** Update or create a single "MetadataRoyaltyfree" node. */
  upsertMetadataRoyaltyfree: MetadataRoyaltyfree;
  /** Update or create a single "MetadataScene" node. */
  upsertMetadataScene: MetadataScene;
  /** Update or create a single "MetadataSection" node. */
  upsertMetadataSection: MetadataSection;
  /** Update or create a single "MetadataSentTo" node. */
  upsertMetadataSentTo: MetadataSentTo;
  /** Update or create a single "MetadataSerialNumber" node. */
  upsertMetadataSerialNumber: MetadataSerialNumber;
  /** Update or create a single "MetadataSeriesDateTime" node. */
  upsertMetadataSeriesDateTime: MetadataSeriesDateTime;
  /** Update or create a single "MetadataSeriesDescription" node. */
  upsertMetadataSeriesDescription: MetadataSeriesDescription;
  /** Update or create a single "MetadataSeriesModality" node. */
  upsertMetadataSeriesModality: MetadataSeriesModality;
  /** Update or create a single "MetadataSeriesNumber" node. */
  upsertMetadataSeriesNumber: MetadataSeriesNumber;
  /** Update or create a single "MetadataShortUniqueId" node. */
  upsertMetadataShortUniqueId: MetadataShortUniqueId;
  /** Update or create a single "MetadataSource" node. */
  upsertMetadataSource: MetadataSource;
  /** Update or create a single "MetadataState" node. */
  upsertMetadataState: MetadataState;
  /** Update or create a single "MetadataStudyDateTime" node. */
  upsertMetadataStudyDateTime: MetadataStudyDateTime;
  /** Update or create a single "MetadataStudyDescription" node. */
  upsertMetadataStudyDescription: MetadataStudyDescription;
  /** Update or create a single "MetadataStudyId" node. */
  upsertMetadataStudyId: MetadataStudyId;
  /** Update or create a single "MetadataStudyPhysician" node. */
  upsertMetadataStudyPhysician: MetadataStudyPhysician;
  /** Update or create a single "MetadataSubject" node. */
  upsertMetadataSubject: MetadataSubject;
  /** Update or create a single "MetadataSubjectCode" node. */
  upsertMetadataSubjectCode: MetadataSubjectCode;
  /** Update or create a single "MetadataSubjectPrisma" node. */
  upsertMetadataSubjectPrisma: MetadataSubjectPrisma;
  /** Update or create a single "MetadataSupplementalCategories" node. */
  upsertMetadataSupplementalCategories: MetadataSupplementalCategories;
  /** Update or create a single "MetadataTargetVersion" node. */
  upsertMetadataTargetVersion: MetadataTargetVersion;
  /** Update or create a single "MetadataTitle" node. */
  upsertMetadataTitle: MetadataTitle;
  /** Update or create a single "MetadataToneCurve" node. */
  upsertMetadataToneCurve: MetadataToneCurve;
  /** Update or create a single "MetadataTransferredBy" node. */
  upsertMetadataTransferredBy: MetadataTransferredBy;
  /** Update or create a single "MetadataTransferredByEmail" node. */
  upsertMetadataTransferredByEmail: MetadataTransferredByEmail;
  /** Update or create a single "MetadataTransferredByFullName" node. */
  upsertMetadataTransferredByFullName: MetadataTransferredByFullName;
  /** Update or create a single "MetadataTransmissionReference" node. */
  upsertMetadataTransmissionReference: MetadataTransmissionReference;
  /** Update or create a single "MetadataType" node. */
  upsertMetadataType: MetadataType;
  /** Update or create a single "MetadataUndersubject" node. */
  upsertMetadataUndersubject: MetadataUndersubject;
  /** Update or create a single "MetadataUnderUnderSubject" node. */
  upsertMetadataUnderUnderSubject: MetadataUnderUnderSubject;
  /** Update or create a single "MetadataUniqueId" node. */
  upsertMetadataUniqueId: MetadataUniqueId;
  /** Update or create a single "MetadataUnit" node. */
  upsertMetadataUnit: MetadataUnit;
  /** Update or create a single "MetadataUnitShortName" node. */
  upsertMetadataUnitShortName: MetadataUnitShortName;
  /** Update or create a single "MetadataUploadedBy" node. */
  upsertMetadataUploadedBy: MetadataUploadedBy;
  /** Update or create a single "MetadataUploadedByFullName" node. */
  upsertMetadataUploadedByFullName: MetadataUploadedByFullName;
  /** Update or create a single "MetadataUploadTime" node. */
  upsertMetadataUploadTime: MetadataUploadTime;
  /** Update or create a single "MetadataUrgency" node. */
  upsertMetadataUrgency: MetadataUrgency;
  /** Update or create a single "MetadataUsageTerms" node. */
  upsertMetadataUsageTerms: MetadataUsageTerms;
  /** Update or create a single "MetadataUserComment" node. */
  upsertMetadataUserComment: MetadataUserComment;
  /** Update or create a single "MetadataUserDefined195" node. */
  upsertMetadataUserDefined195: MetadataUserDefined195;
  /** Update or create a single "MetadataUserDefined237" node. */
  upsertMetadataUserDefined237: MetadataUserDefined237;
  /** Update or create a single "MetadataUserDefined238" node. */
  upsertMetadataUserDefined238: MetadataUserDefined238;
  /** Update or create a single "MetadataUserDefined239" node. */
  upsertMetadataUserDefined239: MetadataUserDefined239;
  /** Update or create a single "MetadataUserDefined242" node. */
  upsertMetadataUserDefined242: MetadataUserDefined242;
  /** Update or create a single "MetadataUserDefined62" node. */
  upsertMetadataUserDefined62: MetadataUserDefined62;
  /** Update or create a single "MetadataValid" node. */
  upsertMetadataValid: MetadataValid;
  /** Update or create a single "MetadataVersion" node. */
  upsertMetadataVersion: MetadataVersion;
  /** Update or create a single "MetadataWebStatement" node. */
  upsertMetadataWebStatement: MetadataWebStatement;
  /** Update or create a single "MetadataWorkflowKind" node. */
  upsertMetadataWorkflowKind: MetadataWorkflowKind;
  /** Update or create a single "MetadataXmpFileStamps" node. */
  upsertMetadataXmpFileStamps: MetadataXmpFileStamps;
  /** Update or create a single "MetadataXmpHistory" node. */
  upsertMetadataXmpHistory: MetadataXmpHistory;
  /** Update or create a single "Photo" node. */
  upsertPhoto: Photo;
  /** Update or create a single "PhotoMetadata" node. */
  upsertPhotoMetadata: PhotoMetadata;
  /** Update or create a single "PhotoPublication" node. */
  upsertPhotoPublication: PhotoPublication;
};


export type MutationClonePhotoArgs = {
  create: PhotoCreateInput;
  sourceId: Scalars['String']['input'];
};


export type MutationCreateAgencyArgs = {
  data: AgencyCreateInput;
};


export type MutationCreateArchiveArgs = {
  data: ArchiveCreateInput;
};


export type MutationCreateArchiveAgencyArgs = {
  data: ArchiveAgencyCreateInput;
};


export type MutationCreateCategoryArgs = {
  data: CategoryCreateInput;
};


export type MutationCreateDeliverySubjectArgs = {
  data: DeliverySubjectCreateInput;
};


export type MutationCreateGcImportSettingArgs = {
  data: GcImportSettingCreateInput;
};


export type MutationCreateImportQueueArgs = {
  data: ImportQueueCreateInput;
};


export type MutationCreateMetadataArgs = {
  data: MetadataCreateInput;
};


export type MutationCreateMetadataAccessRightsArgs = {
  data: MetadataAccessRightsCreateInput;
};


export type MutationCreateMetadataAddlModelInfoArgs = {
  data: MetadataAddlModelInfoCreateInput;
};


export type MutationCreateMetadataArchiveChildArgs = {
  data: MetadataArchiveChildCreateInput;
};


export type MutationCreateMetadataArchiveParentArgs = {
  data: MetadataArchiveParentCreateInput;
};


export type MutationCreateMetadataAssetStateArgs = {
  data: MetadataAssetStateCreateInput;
};


export type MutationCreateMetadataAttributionNameArgs = {
  data: MetadataAttributionNameCreateInput;
};


export type MutationCreateMetadataAttributionUrlArgs = {
  data: MetadataAttributionUrlCreateInput;
};


export type MutationCreateMetadataAudienceArgs = {
  data: MetadataAudienceCreateInput;
};


export type MutationCreateMetadataAuthorsPositionArgs = {
  data: MetadataAuthorsPositionCreateInput;
};


export type MutationCreateMetadataBackupNameArgs = {
  data: MetadataBackupNameCreateInput;
};


export type MutationCreateMetadataBitmapGrayscalePictureArgs = {
  data: MetadataBitmapGrayscalePictureCreateInput;
};


export type MutationCreateMetadataBrandArgs = {
  data: MetadataBrandCreateInput;
};


export type MutationCreateMetadataBrandPrismaArgs = {
  data: MetadataBrandPrismaCreateInput;
};


export type MutationCreateMetadataBrandShortNameArgs = {
  data: MetadataBrandShortNameCreateInput;
};


export type MutationCreateMetadataCaptionWriterArgs = {
  data: MetadataCaptionWriterCreateInput;
};


export type MutationCreateMetadataCategoryArgs = {
  data: MetadataCategoryCreateInput;
};


export type MutationCreateMetadataCategoryPrismaArgs = {
  data: MetadataCategoryPrismaCreateInput;
};


export type MutationCreateMetadataCertificateArgs = {
  data: MetadataCertificateCreateInput;
};


export type MutationCreateMetadataCiAdrCityArgs = {
  data: MetadataCiAdrCityCreateInput;
};


export type MutationCreateMetadataCiAdrCtryArgs = {
  data: MetadataCiAdrCtryCreateInput;
};


export type MutationCreateMetadataCiAdrExtadrArgs = {
  data: MetadataCiAdrExtadrCreateInput;
};


export type MutationCreateMetadataCiAdrPcodeArgs = {
  data: MetadataCiAdrPcodeCreateInput;
};


export type MutationCreateMetadataCiAdrRegionArgs = {
  data: MetadataCiAdrRegionCreateInput;
};


export type MutationCreateMetadataCiEmailWorkArgs = {
  data: MetadataCiEmailWorkCreateInput;
};


export type MutationCreateMetadataCiTelWorkArgs = {
  data: MetadataCiTelWorkCreateInput;
};


export type MutationCreateMetadataCityArgs = {
  data: MetadataCityCreateInput;
};


export type MutationCreateMetadataCiUrlWorkArgs = {
  data: MetadataCiUrlWorkCreateInput;
};


export type MutationCreateMetadataClassifyArgs = {
  data: MetadataClassifyCreateInput;
};


export type MutationCreateMetadataCompanyArgs = {
  data: MetadataCompanyCreateInput;
};


export type MutationCreateMetadataCompanyShortNameArgs = {
  data: MetadataCompanyShortNameCreateInput;
};


export type MutationCreateMetadataConfidentialityArgs = {
  data: MetadataConfidentialityCreateInput;
};


export type MutationCreateMetadataContainedInArgs = {
  data: MetadataContainedInCreateInput;
};


export type MutationCreateMetadataContentMediaKindArgs = {
  data: MetadataContentMediaKindCreateInput;
};


export type MutationCreateMetadataContentValueArgs = {
  data: MetadataContentValueCreateInput;
};


export type MutationCreateMetadataCopyrightLayerArgs = {
  data: MetadataCopyrightLayerCreateInput;
};


export type MutationCreateMetadataCountryArgs = {
  data: MetadataCountryCreateInput;
};


export type MutationCreateMetadataCountryCodeArgs = {
  data: MetadataCountryCodeCreateInput;
};


export type MutationCreateMetadataCreatedTimeArgs = {
  data: MetadataCreatedTimeCreateInput;
};


export type MutationCreateMetadataCreatorArgs = {
  data: MetadataCreatorCreateInput;
};


export type MutationCreateMetadataCreatorToolArgs = {
  data: MetadataCreatorToolCreateInput;
};


export type MutationCreateMetadataCreditArgs = {
  data: MetadataCreditCreateInput;
};


export type MutationCreateMetadataCustomField14Args = {
  data: MetadataCustomField14CreateInput;
};


export type MutationCreateMetadataCustomField15Args = {
  data: MetadataCustomField15CreateInput;
};


export type MutationCreateMetadataCustomField16Args = {
  data: MetadataCustomField16CreateInput;
};


export type MutationCreateMetadataCustomField17Args = {
  data: MetadataCustomField17CreateInput;
};


export type MutationCreateMetadataCustomField20Args = {
  data: MetadataCustomField20CreateInput;
};


export type MutationCreateMetadataCustomField5Args = {
  data: MetadataCustomField5CreateInput;
};


export type MutationCreateMetadataCustomField6Args = {
  data: MetadataCustomField6CreateInput;
};


export type MutationCreateMetadataCustomField7Args = {
  data: MetadataCustomField7CreateInput;
};


export type MutationCreateMetadataCustomField9Args = {
  data: MetadataCustomField9CreateInput;
};


export type MutationCreateMetadataDateCreatedArgs = {
  data: MetadataDateCreatedCreateInput;
};


export type MutationCreateMetadataDateTimeDigitizedArgs = {
  data: MetadataDateTimeDigitizedCreateInput;
};


export type MutationCreateMetadataDateTimeOriginalArgs = {
  data: MetadataDateTimeOriginalCreateInput;
};


export type MutationCreateMetadataDeliveryAccountArgs = {
  data: MetadataDeliveryAccountCreateInput;
};


export type MutationCreateMetadataDeliveryCompanyArgs = {
  data: MetadataDeliveryCompanyCreateInput;
};


export type MutationCreateMetadataDeliveryCopyrightArgs = {
  data: MetadataDeliveryCopyrightCreateInput;
};


export type MutationCreateMetadataDeliveryDateFolderArgs = {
  data: MetadataDeliveryDateFolderCreateInput;
};


export type MutationCreateMetadataDeliveryDateTimeArgs = {
  data: MetadataDeliveryDateTimeCreateInput;
};


export type MutationCreateMetadataDeliveryDcCreatorArgs = {
  data: MetadataDeliveryDcCreatorCreateInput;
};


export type MutationCreateMetadataDeliveryDcRightsArgs = {
  data: MetadataDeliveryDcRightsCreateInput;
};


export type MutationCreateMetadataDeliveryFileTypeArgs = {
  data: MetadataDeliveryFileTypeCreateInput;
};


export type MutationCreateMetadataDeliveryFolderArgs = {
  data: MetadataDeliveryFolderCreateInput;
};


export type MutationCreateMetadataDeliveryKindArgs = {
  data: MetadataDeliveryKindCreateInput;
};


export type MutationCreateMetadataDeliveryPathArgs = {
  data: MetadataDeliveryPathCreateInput;
};


export type MutationCreateMetadataDeliveryPersonShownintheImageArgs = {
  data: MetadataDeliveryPersonShownintheImageCreateInput;
};


export type MutationCreateMetadataDeliveryPhotoshopCreditArgs = {
  data: MetadataDeliveryPhotoshopCreditCreateInput;
};


export type MutationCreateMetadataDeliveryPhotoshopSourceArgs = {
  data: MetadataDeliveryPhotoshopSourceCreateInput;
};


export type MutationCreateMetadataDeliveryServiceArgs = {
  data: MetadataDeliveryServiceCreateInput;
};


export type MutationCreateMetadataDeliverySubjectArgs = {
  data: MetadataDeliverySubjectCreateInput;
};


export type MutationCreateMetadataDeliveryUnderSubjectArgs = {
  data: MetadataDeliveryUnderSubjectCreateInput;
};


export type MutationCreateMetadataDepartmentArgs = {
  data: MetadataDepartmentCreateInput;
};


export type MutationCreateMetadataDescriptionArgs = {
  data: MetadataDescriptionCreateInput;
};


export type MutationCreateMetadataDigitalAssetUrlArgs = {
  data: MetadataDigitalAssetUrlCreateInput;
};


export type MutationCreateMetadataDivisionArgs = {
  data: MetadataDivisionCreateInput;
};


export type MutationCreateMetadataDocumentTextArgs = {
  data: MetadataDocumentTextCreateInput;
};


export type MutationCreateMetadataEditionArgs = {
  data: MetadataEditionCreateInput;
};


export type MutationCreateMetadataEditorialVersionArgs = {
  data: MetadataEditorialVersionCreateInput;
};


export type MutationCreateMetadataEditStatusArgs = {
  data: MetadataEditStatusCreateInput;
};


export type MutationCreateMetadataEnvironnementPhotoArgs = {
  data: MetadataEnvironnementPhotoCreateInput;
};


export type MutationCreateMetadataEquipmentInstitutionArgs = {
  data: MetadataEquipmentInstitutionCreateInput;
};


export type MutationCreateMetadataEquipmentManufacturerArgs = {
  data: MetadataEquipmentManufacturerCreateInput;
};


export type MutationCreateMetadataEventArgs = {
  data: MetadataEventCreateInput;
};


export type MutationCreateMetadataExifArgs = {
  data: MetadataExifCreateInput;
};


export type MutationCreateMetadataFabStorageArgs = {
  data: MetadataFabStorageCreateInput;
};


export type MutationCreateMetadataFileCheckForArgs = {
  data: MetadataFileCheckForCreateInput;
};


export type MutationCreateMetadataFileInfoBitsPerPixelArgs = {
  data: MetadataFileInfoBitsPerPixelCreateInput;
};


export type MutationCreateMetadataFileInfoFramesArgs = {
  data: MetadataFileInfoFramesCreateInput;
};


export type MutationCreateMetadataFileInfoPhotometricInterpretationArgs = {
  data: MetadataFileInfoPhotometricInterpretationCreateInput;
};


export type MutationCreateMetadataFileInfoTransferSyntaxArgs = {
  data: MetadataFileInfoTransferSyntaxCreateInput;
};


export type MutationCreateMetadataFileStageArgs = {
  data: MetadataFileStageCreateInput;
};


export type MutationCreateMetadataFileStatusArgs = {
  data: MetadataFileStatusCreateInput;
};


export type MutationCreateMetadataFileTypeArgs = {
  data: MetadataFileTypeCreateInput;
};


export type MutationCreateMetadataFixtureIdentifierArgs = {
  data: MetadataFixtureIdentifierCreateInput;
};


export type MutationCreateMetadataFormatArgs = {
  data: MetadataFormatCreateInput;
};


export type MutationCreateMetadataGpsLatitudeArgs = {
  data: MetadataGpsLatitudeCreateInput;
};


export type MutationCreateMetadataGpsLongitudeArgs = {
  data: MetadataGpsLongitudeCreateInput;
};


export type MutationCreateMetadataGpsProcessingMethodArgs = {
  data: MetadataGpsProcessingMethodCreateInput;
};


export type MutationCreateMetadataHeaderArgs = {
  data: MetadataHeaderCreateInput;
};


export type MutationCreateMetadataHeaderPrismaArgs = {
  data: MetadataHeaderPrismaCreateInput;
};


export type MutationCreateMetadataHeadlineArgs = {
  data: MetadataHeadlineCreateInput;
};


export type MutationCreateMetadataHistoryArgs = {
  data: MetadataHistoryCreateInput;
};


export type MutationCreateMetadataIccProfileArgs = {
  data: MetadataIccProfileCreateInput;
};


export type MutationCreateMetadataIdAssignmentArgs = {
  data: MetadataIdAssignmentCreateInput;
};


export type MutationCreateMetadataIdMediaContentArgs = {
  data: MetadataIdMediaContentCreateInput;
};


export type MutationCreateMetadataIdPhotoPrismaArgs = {
  data: MetadataIdPhotoPrismaCreateInput;
};


export type MutationCreateMetadataIdSubjectArgs = {
  data: MetadataIdSubjectCreateInput;
};


export type MutationCreateMetadataImageNotesArgs = {
  data: MetadataImageNotesCreateInput;
};


export type MutationCreateMetadataImageUniqueIdArgs = {
  data: MetadataImageUniqueIdCreateInput;
};


export type MutationCreateMetadataInstructionsArgs = {
  data: MetadataInstructionsCreateInput;
};


export type MutationCreateMetadataIntellectualGenreArgs = {
  data: MetadataIntellectualGenreCreateInput;
};


export type MutationCreateMetadataIsoSpeedRatingsArgs = {
  data: MetadataIsoSpeedRatingsCreateInput;
};


export type MutationCreateMetadataIssueArgs = {
  data: MetadataIssueCreateInput;
};


export type MutationCreateMetadataJobIdArgs = {
  data: MetadataJobIdCreateInput;
};


export type MutationCreateMetadataJobProcessingArgs = {
  data: MetadataJobProcessingCreateInput;
};


export type MutationCreateMetadataLayoutPictureEditingArgs = {
  data: MetadataLayoutPictureEditingCreateInput;
};


export type MutationCreateMetadataLayoutStorageArgs = {
  data: MetadataLayoutStorageCreateInput;
};


export type MutationCreateMetadataLicenseArgs = {
  data: MetadataLicenseCreateInput;
};


export type MutationCreateMetadataLinksArgs = {
  data: MetadataLinksCreateInput;
};


export type MutationCreateMetadataLocalCaptionArgs = {
  data: MetadataLocalCaptionCreateInput;
};


export type MutationCreateMetadataLocationArgs = {
  data: MetadataLocationCreateInput;
};


export type MutationCreateMetadataMakeArgs = {
  data: MetadataMakeCreateInput;
};


export type MutationCreateMetadataManifestArgs = {
  data: MetadataManifestCreateInput;
};


export type MutationCreateMetadataMarkedArgs = {
  data: MetadataMarkedCreateInput;
};


export type MutationCreateMetadataMasterDocumentIdArgs = {
  data: MetadataMasterDocumentIdCreateInput;
};


export type MutationCreateMetadataMaxAvailHeightArgs = {
  data: MetadataMaxAvailHeightCreateInput;
};


export type MutationCreateMetadataMaxAvailWidthArgs = {
  data: MetadataMaxAvailWidthCreateInput;
};


export type MutationCreateMetadataMinorModelAgeDisclosureArgs = {
  data: MetadataMinorModelAgeDisclosureCreateInput;
};


export type MutationCreateMetadataModelArgs = {
  data: MetadataModelCreateInput;
};


export type MutationCreateMetadataModelAgeArgs = {
  data: MetadataModelAgeCreateInput;
};


export type MutationCreateMetadataModelReleaseIdArgs = {
  data: MetadataModelReleaseIdCreateInput;
};


export type MutationCreateMetadataModelReleaseStatusArgs = {
  data: MetadataModelReleaseStatusCreateInput;
};


export type MutationCreateMetadataMorePermissionsArgs = {
  data: MetadataMorePermissionsCreateInput;
};


export type MutationCreateMetadataObjectCycleArgs = {
  data: MetadataObjectCycleCreateInput;
};


export type MutationCreateMetadataOrganisationInImageNameArgs = {
  data: MetadataOrganisationInImageNameCreateInput;
};


export type MutationCreateMetadataOriginalColorArgs = {
  data: MetadataOriginalColorCreateInput;
};


export type MutationCreateMetadataOriginalIccProfileArgs = {
  data: MetadataOriginalIccProfileCreateInput;
};


export type MutationCreateMetadataOriginalNameArgs = {
  data: MetadataOriginalNameCreateInput;
};


export type MutationCreateMetadataOriginalWeigthArgs = {
  data: MetadataOriginalWeigthCreateInput;
};


export type MutationCreateMetadataOwnerArgs = {
  data: MetadataOwnerCreateInput;
};


export type MutationCreateMetadataOwnerIdArgs = {
  data: MetadataOwnerIdCreateInput;
};


export type MutationCreateMetadataPageArgs = {
  data: MetadataPageCreateInput;
};


export type MutationCreateMetadataPagePrismaArgs = {
  data: MetadataPagePrismaCreateInput;
};


export type MutationCreateMetadataPaintBasedCorrectionsArgs = {
  data: MetadataPaintBasedCorrectionsCreateInput;
};


export type MutationCreateMetadataPatientDobArgs = {
  data: MetadataPatientDobCreateInput;
};


export type MutationCreateMetadataPatientIdArgs = {
  data: MetadataPatientIdCreateInput;
};


export type MutationCreateMetadataPatientNameArgs = {
  data: MetadataPatientNameCreateInput;
};


export type MutationCreateMetadataPatientSexArgs = {
  data: MetadataPatientSexCreateInput;
};


export type MutationCreateMetadataPersonInImageArgs = {
  data: MetadataPersonInImageCreateInput;
};


export type MutationCreateMetadataPhotoStorageArgs = {
  data: MetadataPhotoStorageCreateInput;
};


export type MutationCreateMetadataPrepressPictureEditingArgs = {
  data: MetadataPrepressPictureEditingCreateInput;
};


export type MutationCreateMetadataPriceLevelArgs = {
  data: MetadataPriceLevelCreateInput;
};


export type MutationCreateMetadataPrintingProfileArgs = {
  data: MetadataPrintingProfileCreateInput;
};


export type MutationCreateMetadataPrismaProductionArgs = {
  data: MetadataPrismaProductionCreateInput;
};


export type MutationCreateMetadataProcessHistoryArgs = {
  data: MetadataProcessHistoryCreateInput;
};


export type MutationCreateMetadataProcessParameterArgs = {
  data: MetadataProcessParameterCreateInput;
};


export type MutationCreateMetadataProcessStatusArgs = {
  data: MetadataProcessStatusCreateInput;
};


export type MutationCreateMetadataProductArgs = {
  data: MetadataProductCreateInput;
};


export type MutationCreateMetadataProductsArgs = {
  data: MetadataProductsCreateInput;
};


export type MutationCreateMetadataProductShortNameArgs = {
  data: MetadataProductShortNameCreateInput;
};


export type MutationCreateMetadataProduitsArgs = {
  data: MetadataProduitsCreateInput;
};


export type MutationCreateMetadataProgramVersionArgs = {
  data: MetadataProgramVersionCreateInput;
};


export type MutationCreateMetadataPropertyReleaseIdArgs = {
  data: MetadataPropertyReleaseIdCreateInput;
};


export type MutationCreateMetadataPropertyReleaseStatusArgs = {
  data: MetadataPropertyReleaseStatusCreateInput;
};


export type MutationCreateMetadataPublisherArgs = {
  data: MetadataPublisherCreateInput;
};


export type MutationCreateMetadataPublishingIssueArgs = {
  data: MetadataPublishingIssueCreateInput;
};


export type MutationCreateMetadataPublishingSubjectArgs = {
  data: MetadataPublishingSubjectCreateInput;
};


export type MutationCreateMetadataQualifiedUsePrismaByArgs = {
  data: MetadataQualifiedUsePrismaByCreateInput;
};


export type MutationCreateMetadataQualifiedUsePrismaDateArgs = {
  data: MetadataQualifiedUsePrismaDateCreateInput;
};


export type MutationCreateMetadataQualifiedUsePrismaDurationArgs = {
  data: MetadataQualifiedUsePrismaDurationCreateInput;
};


export type MutationCreateMetadataQualifiedUsePrismaSupportArgs = {
  data: MetadataQualifiedUsePrismaSupportCreateInput;
};


export type MutationCreateMetadataRatingArgs = {
  data: MetadataRatingCreateInput;
};


export type MutationCreateMetadataReferenceDateArgs = {
  data: MetadataReferenceDateCreateInput;
};


export type MutationCreateMetadataReferenceNumberArgs = {
  data: MetadataReferenceNumberCreateInput;
};


export type MutationCreateMetadataReferenceServiceArgs = {
  data: MetadataReferenceServiceCreateInput;
};


export type MutationCreateMetadataReleaseDateArgs = {
  data: MetadataReleaseDateCreateInput;
};


export type MutationCreateMetadataReleaseTimeArgs = {
  data: MetadataReleaseTimeCreateInput;
};


export type MutationCreateMetadataRequiredPermissionArgs = {
  data: MetadataRequiredPermissionCreateInput;
};


export type MutationCreateMetadataResolutionKindArgs = {
  data: MetadataResolutionKindCreateInput;
};


export type MutationCreateMetadataRightsArgs = {
  data: MetadataRightsCreateInput;
};


export type MutationCreateMetadataRoyaltyfreeArgs = {
  data: MetadataRoyaltyfreeCreateInput;
};


export type MutationCreateMetadataSceneArgs = {
  data: MetadataSceneCreateInput;
};


export type MutationCreateMetadataSectionArgs = {
  data: MetadataSectionCreateInput;
};


export type MutationCreateMetadataSentToArgs = {
  data: MetadataSentToCreateInput;
};


export type MutationCreateMetadataSerialNumberArgs = {
  data: MetadataSerialNumberCreateInput;
};


export type MutationCreateMetadataSeriesDateTimeArgs = {
  data: MetadataSeriesDateTimeCreateInput;
};


export type MutationCreateMetadataSeriesDescriptionArgs = {
  data: MetadataSeriesDescriptionCreateInput;
};


export type MutationCreateMetadataSeriesModalityArgs = {
  data: MetadataSeriesModalityCreateInput;
};


export type MutationCreateMetadataSeriesNumberArgs = {
  data: MetadataSeriesNumberCreateInput;
};


export type MutationCreateMetadataShortUniqueIdArgs = {
  data: MetadataShortUniqueIdCreateInput;
};


export type MutationCreateMetadataSourceArgs = {
  data: MetadataSourceCreateInput;
};


export type MutationCreateMetadataStateArgs = {
  data: MetadataStateCreateInput;
};


export type MutationCreateMetadataStudyDateTimeArgs = {
  data: MetadataStudyDateTimeCreateInput;
};


export type MutationCreateMetadataStudyDescriptionArgs = {
  data: MetadataStudyDescriptionCreateInput;
};


export type MutationCreateMetadataStudyIdArgs = {
  data: MetadataStudyIdCreateInput;
};


export type MutationCreateMetadataStudyPhysicianArgs = {
  data: MetadataStudyPhysicianCreateInput;
};


export type MutationCreateMetadataSubjectArgs = {
  data: MetadataSubjectCreateInput;
};


export type MutationCreateMetadataSubjectCodeArgs = {
  data: MetadataSubjectCodeCreateInput;
};


export type MutationCreateMetadataSubjectPrismaArgs = {
  data: MetadataSubjectPrismaCreateInput;
};


export type MutationCreateMetadataSupplementalCategoriesArgs = {
  data: MetadataSupplementalCategoriesCreateInput;
};


export type MutationCreateMetadataTargetVersionArgs = {
  data: MetadataTargetVersionCreateInput;
};


export type MutationCreateMetadataTitleArgs = {
  data: MetadataTitleCreateInput;
};


export type MutationCreateMetadataToneCurveArgs = {
  data: MetadataToneCurveCreateInput;
};


export type MutationCreateMetadataTransferredByArgs = {
  data: MetadataTransferredByCreateInput;
};


export type MutationCreateMetadataTransferredByEmailArgs = {
  data: MetadataTransferredByEmailCreateInput;
};


export type MutationCreateMetadataTransferredByFullNameArgs = {
  data: MetadataTransferredByFullNameCreateInput;
};


export type MutationCreateMetadataTransmissionReferenceArgs = {
  data: MetadataTransmissionReferenceCreateInput;
};


export type MutationCreateMetadataTypeArgs = {
  data: MetadataTypeCreateInput;
};


export type MutationCreateMetadataUndersubjectArgs = {
  data: MetadataUndersubjectCreateInput;
};


export type MutationCreateMetadataUnderUnderSubjectArgs = {
  data: MetadataUnderUnderSubjectCreateInput;
};


export type MutationCreateMetadataUniqueIdArgs = {
  data: MetadataUniqueIdCreateInput;
};


export type MutationCreateMetadataUnitArgs = {
  data: MetadataUnitCreateInput;
};


export type MutationCreateMetadataUnitShortNameArgs = {
  data: MetadataUnitShortNameCreateInput;
};


export type MutationCreateMetadataUploadedByArgs = {
  data: MetadataUploadedByCreateInput;
};


export type MutationCreateMetadataUploadedByFullNameArgs = {
  data: MetadataUploadedByFullNameCreateInput;
};


export type MutationCreateMetadataUploadTimeArgs = {
  data: MetadataUploadTimeCreateInput;
};


export type MutationCreateMetadataUrgencyArgs = {
  data: MetadataUrgencyCreateInput;
};


export type MutationCreateMetadataUsageTermsArgs = {
  data: MetadataUsageTermsCreateInput;
};


export type MutationCreateMetadataUserCommentArgs = {
  data: MetadataUserCommentCreateInput;
};


export type MutationCreateMetadataUserDefined195Args = {
  data: MetadataUserDefined195CreateInput;
};


export type MutationCreateMetadataUserDefined237Args = {
  data: MetadataUserDefined237CreateInput;
};


export type MutationCreateMetadataUserDefined238Args = {
  data: MetadataUserDefined238CreateInput;
};


export type MutationCreateMetadataUserDefined239Args = {
  data: MetadataUserDefined239CreateInput;
};


export type MutationCreateMetadataUserDefined242Args = {
  data: MetadataUserDefined242CreateInput;
};


export type MutationCreateMetadataUserDefined62Args = {
  data: MetadataUserDefined62CreateInput;
};


export type MutationCreateMetadataValidArgs = {
  data: MetadataValidCreateInput;
};


export type MutationCreateMetadataVersionArgs = {
  data: MetadataVersionCreateInput;
};


export type MutationCreateMetadataWebStatementArgs = {
  data: MetadataWebStatementCreateInput;
};


export type MutationCreateMetadataWorkflowKindArgs = {
  data: MetadataWorkflowKindCreateInput;
};


export type MutationCreateMetadataXmpFileStampsArgs = {
  data: MetadataXmpFileStampsCreateInput;
};


export type MutationCreateMetadataXmpHistoryArgs = {
  data: MetadataXmpHistoryCreateInput;
};


export type MutationCreatePhotoArgs = {
  data: PhotoCreateInput;
};


export type MutationCreatePhotoMetadataArgs = {
  data: PhotoMetadataCreateInput;
};


export type MutationCreatePhotoPublicationArgs = {
  data: PhotoPublicationCreateInput;
};


export type MutationDeleteAgencyArgs = {
  where: AgencyWhereUniqueInput;
};


export type MutationDeleteArchiveArgs = {
  where: ArchiveWhereUniqueInput;
};


export type MutationDeleteArchiveAgencyArgs = {
  where: ArchiveAgencyWhereUniqueInput;
};


export type MutationDeleteCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type MutationDeleteDeliverySubjectArgs = {
  where: DeliverySubjectWhereUniqueInput;
};


export type MutationDeleteGcImportSettingArgs = {
  where: GcImportSettingWhereUniqueInput;
};


export type MutationDeleteImportQueueArgs = {
  where: ImportQueueWhereUniqueInput;
};


export type MutationDeleteMetadataArgs = {
  where: MetadataWhereUniqueInput;
};


export type MutationDeleteMetadataAccessRightsArgs = {
  where: MetadataAccessRightsWhereUniqueInput;
};


export type MutationDeleteMetadataAddlModelInfoArgs = {
  where: MetadataAddlModelInfoWhereUniqueInput;
};


export type MutationDeleteMetadataArchiveChildArgs = {
  where: MetadataArchiveChildWhereUniqueInput;
};


export type MutationDeleteMetadataArchiveParentArgs = {
  where: MetadataArchiveParentWhereUniqueInput;
};


export type MutationDeleteMetadataAssetStateArgs = {
  where: MetadataAssetStateWhereUniqueInput;
};


export type MutationDeleteMetadataAttributionNameArgs = {
  where: MetadataAttributionNameWhereUniqueInput;
};


export type MutationDeleteMetadataAttributionUrlArgs = {
  where: MetadataAttributionUrlWhereUniqueInput;
};


export type MutationDeleteMetadataAudienceArgs = {
  where: MetadataAudienceWhereUniqueInput;
};


export type MutationDeleteMetadataAuthorsPositionArgs = {
  where: MetadataAuthorsPositionWhereUniqueInput;
};


export type MutationDeleteMetadataBackupNameArgs = {
  where: MetadataBackupNameWhereUniqueInput;
};


export type MutationDeleteMetadataBitmapGrayscalePictureArgs = {
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
};


export type MutationDeleteMetadataBrandArgs = {
  where: MetadataBrandWhereUniqueInput;
};


export type MutationDeleteMetadataBrandPrismaArgs = {
  where: MetadataBrandPrismaWhereUniqueInput;
};


export type MutationDeleteMetadataBrandShortNameArgs = {
  where: MetadataBrandShortNameWhereUniqueInput;
};


export type MutationDeleteMetadataCaptionWriterArgs = {
  where: MetadataCaptionWriterWhereUniqueInput;
};


export type MutationDeleteMetadataCategoryArgs = {
  where: MetadataCategoryWhereUniqueInput;
};


export type MutationDeleteMetadataCategoryPrismaArgs = {
  where: MetadataCategoryPrismaWhereUniqueInput;
};


export type MutationDeleteMetadataCertificateArgs = {
  where: MetadataCertificateWhereUniqueInput;
};


export type MutationDeleteMetadataCiAdrCityArgs = {
  where: MetadataCiAdrCityWhereUniqueInput;
};


export type MutationDeleteMetadataCiAdrCtryArgs = {
  where: MetadataCiAdrCtryWhereUniqueInput;
};


export type MutationDeleteMetadataCiAdrExtadrArgs = {
  where: MetadataCiAdrExtadrWhereUniqueInput;
};


export type MutationDeleteMetadataCiAdrPcodeArgs = {
  where: MetadataCiAdrPcodeWhereUniqueInput;
};


export type MutationDeleteMetadataCiAdrRegionArgs = {
  where: MetadataCiAdrRegionWhereUniqueInput;
};


export type MutationDeleteMetadataCiEmailWorkArgs = {
  where: MetadataCiEmailWorkWhereUniqueInput;
};


export type MutationDeleteMetadataCiTelWorkArgs = {
  where: MetadataCiTelWorkWhereUniqueInput;
};


export type MutationDeleteMetadataCityArgs = {
  where: MetadataCityWhereUniqueInput;
};


export type MutationDeleteMetadataCiUrlWorkArgs = {
  where: MetadataCiUrlWorkWhereUniqueInput;
};


export type MutationDeleteMetadataClassifyArgs = {
  where: MetadataClassifyWhereUniqueInput;
};


export type MutationDeleteMetadataCompanyArgs = {
  where: MetadataCompanyWhereUniqueInput;
};


export type MutationDeleteMetadataCompanyShortNameArgs = {
  where: MetadataCompanyShortNameWhereUniqueInput;
};


export type MutationDeleteMetadataConfidentialityArgs = {
  where: MetadataConfidentialityWhereUniqueInput;
};


export type MutationDeleteMetadataContainedInArgs = {
  where: MetadataContainedInWhereUniqueInput;
};


export type MutationDeleteMetadataContentMediaKindArgs = {
  where: MetadataContentMediaKindWhereUniqueInput;
};


export type MutationDeleteMetadataContentValueArgs = {
  where: MetadataContentValueWhereUniqueInput;
};


export type MutationDeleteMetadataCopyrightLayerArgs = {
  where: MetadataCopyrightLayerWhereUniqueInput;
};


export type MutationDeleteMetadataCountryArgs = {
  where: MetadataCountryWhereUniqueInput;
};


export type MutationDeleteMetadataCountryCodeArgs = {
  where: MetadataCountryCodeWhereUniqueInput;
};


export type MutationDeleteMetadataCreatedTimeArgs = {
  where: MetadataCreatedTimeWhereUniqueInput;
};


export type MutationDeleteMetadataCreatorArgs = {
  where: MetadataCreatorWhereUniqueInput;
};


export type MutationDeleteMetadataCreatorToolArgs = {
  where: MetadataCreatorToolWhereUniqueInput;
};


export type MutationDeleteMetadataCreditArgs = {
  where: MetadataCreditWhereUniqueInput;
};


export type MutationDeleteMetadataCustomField14Args = {
  where: MetadataCustomField14WhereUniqueInput;
};


export type MutationDeleteMetadataCustomField15Args = {
  where: MetadataCustomField15WhereUniqueInput;
};


export type MutationDeleteMetadataCustomField16Args = {
  where: MetadataCustomField16WhereUniqueInput;
};


export type MutationDeleteMetadataCustomField17Args = {
  where: MetadataCustomField17WhereUniqueInput;
};


export type MutationDeleteMetadataCustomField20Args = {
  where: MetadataCustomField20WhereUniqueInput;
};


export type MutationDeleteMetadataCustomField5Args = {
  where: MetadataCustomField5WhereUniqueInput;
};


export type MutationDeleteMetadataCustomField6Args = {
  where: MetadataCustomField6WhereUniqueInput;
};


export type MutationDeleteMetadataCustomField7Args = {
  where: MetadataCustomField7WhereUniqueInput;
};


export type MutationDeleteMetadataCustomField9Args = {
  where: MetadataCustomField9WhereUniqueInput;
};


export type MutationDeleteMetadataDateCreatedArgs = {
  where: MetadataDateCreatedWhereUniqueInput;
};


export type MutationDeleteMetadataDateTimeDigitizedArgs = {
  where: MetadataDateTimeDigitizedWhereUniqueInput;
};


export type MutationDeleteMetadataDateTimeOriginalArgs = {
  where: MetadataDateTimeOriginalWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryAccountArgs = {
  where: MetadataDeliveryAccountWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryCompanyArgs = {
  where: MetadataDeliveryCompanyWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryCopyrightArgs = {
  where: MetadataDeliveryCopyrightWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryDateFolderArgs = {
  where: MetadataDeliveryDateFolderWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryDateTimeArgs = {
  where: MetadataDeliveryDateTimeWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryDcCreatorArgs = {
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryDcRightsArgs = {
  where: MetadataDeliveryDcRightsWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryFileTypeArgs = {
  where: MetadataDeliveryFileTypeWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryFolderArgs = {
  where: MetadataDeliveryFolderWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryKindArgs = {
  where: MetadataDeliveryKindWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryPathArgs = {
  where: MetadataDeliveryPathWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryPersonShownintheImageArgs = {
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryPhotoshopCreditArgs = {
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryPhotoshopSourceArgs = {
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryServiceArgs = {
  where: MetadataDeliveryServiceWhereUniqueInput;
};


export type MutationDeleteMetadataDeliverySubjectArgs = {
  where: MetadataDeliverySubjectWhereUniqueInput;
};


export type MutationDeleteMetadataDeliveryUnderSubjectArgs = {
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
};


export type MutationDeleteMetadataDepartmentArgs = {
  where: MetadataDepartmentWhereUniqueInput;
};


export type MutationDeleteMetadataDescriptionArgs = {
  where: MetadataDescriptionWhereUniqueInput;
};


export type MutationDeleteMetadataDigitalAssetUrlArgs = {
  where: MetadataDigitalAssetUrlWhereUniqueInput;
};


export type MutationDeleteMetadataDivisionArgs = {
  where: MetadataDivisionWhereUniqueInput;
};


export type MutationDeleteMetadataDocumentTextArgs = {
  where: MetadataDocumentTextWhereUniqueInput;
};


export type MutationDeleteMetadataEditionArgs = {
  where: MetadataEditionWhereUniqueInput;
};


export type MutationDeleteMetadataEditorialVersionArgs = {
  where: MetadataEditorialVersionWhereUniqueInput;
};


export type MutationDeleteMetadataEditStatusArgs = {
  where: MetadataEditStatusWhereUniqueInput;
};


export type MutationDeleteMetadataEnvironnementPhotoArgs = {
  where: MetadataEnvironnementPhotoWhereUniqueInput;
};


export type MutationDeleteMetadataEquipmentInstitutionArgs = {
  where: MetadataEquipmentInstitutionWhereUniqueInput;
};


export type MutationDeleteMetadataEquipmentManufacturerArgs = {
  where: MetadataEquipmentManufacturerWhereUniqueInput;
};


export type MutationDeleteMetadataEventArgs = {
  where: MetadataEventWhereUniqueInput;
};


export type MutationDeleteMetadataExifArgs = {
  where: MetadataExifWhereUniqueInput;
};


export type MutationDeleteMetadataFabStorageArgs = {
  where: MetadataFabStorageWhereUniqueInput;
};


export type MutationDeleteMetadataFileCheckForArgs = {
  where: MetadataFileCheckForWhereUniqueInput;
};


export type MutationDeleteMetadataFileInfoBitsPerPixelArgs = {
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
};


export type MutationDeleteMetadataFileInfoFramesArgs = {
  where: MetadataFileInfoFramesWhereUniqueInput;
};


export type MutationDeleteMetadataFileInfoPhotometricInterpretationArgs = {
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
};


export type MutationDeleteMetadataFileInfoTransferSyntaxArgs = {
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
};


export type MutationDeleteMetadataFileStageArgs = {
  where: MetadataFileStageWhereUniqueInput;
};


export type MutationDeleteMetadataFileStatusArgs = {
  where: MetadataFileStatusWhereUniqueInput;
};


export type MutationDeleteMetadataFileTypeArgs = {
  where: MetadataFileTypeWhereUniqueInput;
};


export type MutationDeleteMetadataFixtureIdentifierArgs = {
  where: MetadataFixtureIdentifierWhereUniqueInput;
};


export type MutationDeleteMetadataFormatArgs = {
  where: MetadataFormatWhereUniqueInput;
};


export type MutationDeleteMetadataGpsLatitudeArgs = {
  where: MetadataGpsLatitudeWhereUniqueInput;
};


export type MutationDeleteMetadataGpsLongitudeArgs = {
  where: MetadataGpsLongitudeWhereUniqueInput;
};


export type MutationDeleteMetadataGpsProcessingMethodArgs = {
  where: MetadataGpsProcessingMethodWhereUniqueInput;
};


export type MutationDeleteMetadataHeaderArgs = {
  where: MetadataHeaderWhereUniqueInput;
};


export type MutationDeleteMetadataHeaderPrismaArgs = {
  where: MetadataHeaderPrismaWhereUniqueInput;
};


export type MutationDeleteMetadataHeadlineArgs = {
  where: MetadataHeadlineWhereUniqueInput;
};


export type MutationDeleteMetadataHistoryArgs = {
  where: MetadataHistoryWhereUniqueInput;
};


export type MutationDeleteMetadataIccProfileArgs = {
  where: MetadataIccProfileWhereUniqueInput;
};


export type MutationDeleteMetadataIdAssignmentArgs = {
  where: MetadataIdAssignmentWhereUniqueInput;
};


export type MutationDeleteMetadataIdMediaContentArgs = {
  where: MetadataIdMediaContentWhereUniqueInput;
};


export type MutationDeleteMetadataIdPhotoPrismaArgs = {
  where: MetadataIdPhotoPrismaWhereUniqueInput;
};


export type MutationDeleteMetadataIdSubjectArgs = {
  where: MetadataIdSubjectWhereUniqueInput;
};


export type MutationDeleteMetadataImageNotesArgs = {
  where: MetadataImageNotesWhereUniqueInput;
};


export type MutationDeleteMetadataImageUniqueIdArgs = {
  where: MetadataImageUniqueIdWhereUniqueInput;
};


export type MutationDeleteMetadataInstructionsArgs = {
  where: MetadataInstructionsWhereUniqueInput;
};


export type MutationDeleteMetadataIntellectualGenreArgs = {
  where: MetadataIntellectualGenreWhereUniqueInput;
};


export type MutationDeleteMetadataIsoSpeedRatingsArgs = {
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
};


export type MutationDeleteMetadataIssueArgs = {
  where: MetadataIssueWhereUniqueInput;
};


export type MutationDeleteMetadataJobIdArgs = {
  where: MetadataJobIdWhereUniqueInput;
};


export type MutationDeleteMetadataJobProcessingArgs = {
  where: MetadataJobProcessingWhereUniqueInput;
};


export type MutationDeleteMetadataLayoutPictureEditingArgs = {
  where: MetadataLayoutPictureEditingWhereUniqueInput;
};


export type MutationDeleteMetadataLayoutStorageArgs = {
  where: MetadataLayoutStorageWhereUniqueInput;
};


export type MutationDeleteMetadataLicenseArgs = {
  where: MetadataLicenseWhereUniqueInput;
};


export type MutationDeleteMetadataLinksArgs = {
  where: MetadataLinksWhereUniqueInput;
};


export type MutationDeleteMetadataLocalCaptionArgs = {
  where: MetadataLocalCaptionWhereUniqueInput;
};


export type MutationDeleteMetadataLocationArgs = {
  where: MetadataLocationWhereUniqueInput;
};


export type MutationDeleteMetadataMakeArgs = {
  where: MetadataMakeWhereUniqueInput;
};


export type MutationDeleteMetadataManifestArgs = {
  where: MetadataManifestWhereUniqueInput;
};


export type MutationDeleteMetadataMarkedArgs = {
  where: MetadataMarkedWhereUniqueInput;
};


export type MutationDeleteMetadataMasterDocumentIdArgs = {
  where: MetadataMasterDocumentIdWhereUniqueInput;
};


export type MutationDeleteMetadataMaxAvailHeightArgs = {
  where: MetadataMaxAvailHeightWhereUniqueInput;
};


export type MutationDeleteMetadataMaxAvailWidthArgs = {
  where: MetadataMaxAvailWidthWhereUniqueInput;
};


export type MutationDeleteMetadataMinorModelAgeDisclosureArgs = {
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
};


export type MutationDeleteMetadataModelArgs = {
  where: MetadataModelWhereUniqueInput;
};


export type MutationDeleteMetadataModelAgeArgs = {
  where: MetadataModelAgeWhereUniqueInput;
};


export type MutationDeleteMetadataModelReleaseIdArgs = {
  where: MetadataModelReleaseIdWhereUniqueInput;
};


export type MutationDeleteMetadataModelReleaseStatusArgs = {
  where: MetadataModelReleaseStatusWhereUniqueInput;
};


export type MutationDeleteMetadataMorePermissionsArgs = {
  where: MetadataMorePermissionsWhereUniqueInput;
};


export type MutationDeleteMetadataObjectCycleArgs = {
  where: MetadataObjectCycleWhereUniqueInput;
};


export type MutationDeleteMetadataOrganisationInImageNameArgs = {
  where: MetadataOrganisationInImageNameWhereUniqueInput;
};


export type MutationDeleteMetadataOriginalColorArgs = {
  where: MetadataOriginalColorWhereUniqueInput;
};


export type MutationDeleteMetadataOriginalIccProfileArgs = {
  where: MetadataOriginalIccProfileWhereUniqueInput;
};


export type MutationDeleteMetadataOriginalNameArgs = {
  where: MetadataOriginalNameWhereUniqueInput;
};


export type MutationDeleteMetadataOriginalWeigthArgs = {
  where: MetadataOriginalWeigthWhereUniqueInput;
};


export type MutationDeleteMetadataOwnerArgs = {
  where: MetadataOwnerWhereUniqueInput;
};


export type MutationDeleteMetadataOwnerIdArgs = {
  where: MetadataOwnerIdWhereUniqueInput;
};


export type MutationDeleteMetadataPageArgs = {
  where: MetadataPageWhereUniqueInput;
};


export type MutationDeleteMetadataPagePrismaArgs = {
  where: MetadataPagePrismaWhereUniqueInput;
};


export type MutationDeleteMetadataPaintBasedCorrectionsArgs = {
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
};


export type MutationDeleteMetadataPatientDobArgs = {
  where: MetadataPatientDobWhereUniqueInput;
};


export type MutationDeleteMetadataPatientIdArgs = {
  where: MetadataPatientIdWhereUniqueInput;
};


export type MutationDeleteMetadataPatientNameArgs = {
  where: MetadataPatientNameWhereUniqueInput;
};


export type MutationDeleteMetadataPatientSexArgs = {
  where: MetadataPatientSexWhereUniqueInput;
};


export type MutationDeleteMetadataPersonInImageArgs = {
  where: MetadataPersonInImageWhereUniqueInput;
};


export type MutationDeleteMetadataPhotoStorageArgs = {
  where: MetadataPhotoStorageWhereUniqueInput;
};


export type MutationDeleteMetadataPrepressPictureEditingArgs = {
  where: MetadataPrepressPictureEditingWhereUniqueInput;
};


export type MutationDeleteMetadataPriceLevelArgs = {
  where: MetadataPriceLevelWhereUniqueInput;
};


export type MutationDeleteMetadataPrintingProfileArgs = {
  where: MetadataPrintingProfileWhereUniqueInput;
};


export type MutationDeleteMetadataPrismaProductionArgs = {
  where: MetadataPrismaProductionWhereUniqueInput;
};


export type MutationDeleteMetadataProcessHistoryArgs = {
  where: MetadataProcessHistoryWhereUniqueInput;
};


export type MutationDeleteMetadataProcessParameterArgs = {
  where: MetadataProcessParameterWhereUniqueInput;
};


export type MutationDeleteMetadataProcessStatusArgs = {
  where: MetadataProcessStatusWhereUniqueInput;
};


export type MutationDeleteMetadataProductArgs = {
  where: MetadataProductWhereUniqueInput;
};


export type MutationDeleteMetadataProductsArgs = {
  where: MetadataProductsWhereUniqueInput;
};


export type MutationDeleteMetadataProductShortNameArgs = {
  where: MetadataProductShortNameWhereUniqueInput;
};


export type MutationDeleteMetadataProduitsArgs = {
  where: MetadataProduitsWhereUniqueInput;
};


export type MutationDeleteMetadataProgramVersionArgs = {
  where: MetadataProgramVersionWhereUniqueInput;
};


export type MutationDeleteMetadataPropertyReleaseIdArgs = {
  where: MetadataPropertyReleaseIdWhereUniqueInput;
};


export type MutationDeleteMetadataPropertyReleaseStatusArgs = {
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
};


export type MutationDeleteMetadataPublisherArgs = {
  where: MetadataPublisherWhereUniqueInput;
};


export type MutationDeleteMetadataPublishingIssueArgs = {
  where: MetadataPublishingIssueWhereUniqueInput;
};


export type MutationDeleteMetadataPublishingSubjectArgs = {
  where: MetadataPublishingSubjectWhereUniqueInput;
};


export type MutationDeleteMetadataQualifiedUsePrismaByArgs = {
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
};


export type MutationDeleteMetadataQualifiedUsePrismaDateArgs = {
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
};


export type MutationDeleteMetadataQualifiedUsePrismaDurationArgs = {
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
};


export type MutationDeleteMetadataQualifiedUsePrismaSupportArgs = {
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
};


export type MutationDeleteMetadataRatingArgs = {
  where: MetadataRatingWhereUniqueInput;
};


export type MutationDeleteMetadataReferenceDateArgs = {
  where: MetadataReferenceDateWhereUniqueInput;
};


export type MutationDeleteMetadataReferenceNumberArgs = {
  where: MetadataReferenceNumberWhereUniqueInput;
};


export type MutationDeleteMetadataReferenceServiceArgs = {
  where: MetadataReferenceServiceWhereUniqueInput;
};


export type MutationDeleteMetadataReleaseDateArgs = {
  where: MetadataReleaseDateWhereUniqueInput;
};


export type MutationDeleteMetadataReleaseTimeArgs = {
  where: MetadataReleaseTimeWhereUniqueInput;
};


export type MutationDeleteMetadataRequiredPermissionArgs = {
  where: MetadataRequiredPermissionWhereUniqueInput;
};


export type MutationDeleteMetadataResolutionKindArgs = {
  where: MetadataResolutionKindWhereUniqueInput;
};


export type MutationDeleteMetadataRightsArgs = {
  where: MetadataRightsWhereUniqueInput;
};


export type MutationDeleteMetadataRoyaltyfreeArgs = {
  where: MetadataRoyaltyfreeWhereUniqueInput;
};


export type MutationDeleteMetadataSceneArgs = {
  where: MetadataSceneWhereUniqueInput;
};


export type MutationDeleteMetadataSectionArgs = {
  where: MetadataSectionWhereUniqueInput;
};


export type MutationDeleteMetadataSentToArgs = {
  where: MetadataSentToWhereUniqueInput;
};


export type MutationDeleteMetadataSerialNumberArgs = {
  where: MetadataSerialNumberWhereUniqueInput;
};


export type MutationDeleteMetadataSeriesDateTimeArgs = {
  where: MetadataSeriesDateTimeWhereUniqueInput;
};


export type MutationDeleteMetadataSeriesDescriptionArgs = {
  where: MetadataSeriesDescriptionWhereUniqueInput;
};


export type MutationDeleteMetadataSeriesModalityArgs = {
  where: MetadataSeriesModalityWhereUniqueInput;
};


export type MutationDeleteMetadataSeriesNumberArgs = {
  where: MetadataSeriesNumberWhereUniqueInput;
};


export type MutationDeleteMetadataShortUniqueIdArgs = {
  where: MetadataShortUniqueIdWhereUniqueInput;
};


export type MutationDeleteMetadataSourceArgs = {
  where: MetadataSourceWhereUniqueInput;
};


export type MutationDeleteMetadataStateArgs = {
  where: MetadataStateWhereUniqueInput;
};


export type MutationDeleteMetadataStudyDateTimeArgs = {
  where: MetadataStudyDateTimeWhereUniqueInput;
};


export type MutationDeleteMetadataStudyDescriptionArgs = {
  where: MetadataStudyDescriptionWhereUniqueInput;
};


export type MutationDeleteMetadataStudyIdArgs = {
  where: MetadataStudyIdWhereUniqueInput;
};


export type MutationDeleteMetadataStudyPhysicianArgs = {
  where: MetadataStudyPhysicianWhereUniqueInput;
};


export type MutationDeleteMetadataSubjectArgs = {
  where: MetadataSubjectWhereUniqueInput;
};


export type MutationDeleteMetadataSubjectCodeArgs = {
  where: MetadataSubjectCodeWhereUniqueInput;
};


export type MutationDeleteMetadataSubjectPrismaArgs = {
  where: MetadataSubjectPrismaWhereUniqueInput;
};


export type MutationDeleteMetadataSupplementalCategoriesArgs = {
  where: MetadataSupplementalCategoriesWhereUniqueInput;
};


export type MutationDeleteMetadataTargetVersionArgs = {
  where: MetadataTargetVersionWhereUniqueInput;
};


export type MutationDeleteMetadataTitleArgs = {
  where: MetadataTitleWhereUniqueInput;
};


export type MutationDeleteMetadataToneCurveArgs = {
  where: MetadataToneCurveWhereUniqueInput;
};


export type MutationDeleteMetadataTransferredByArgs = {
  where: MetadataTransferredByWhereUniqueInput;
};


export type MutationDeleteMetadataTransferredByEmailArgs = {
  where: MetadataTransferredByEmailWhereUniqueInput;
};


export type MutationDeleteMetadataTransferredByFullNameArgs = {
  where: MetadataTransferredByFullNameWhereUniqueInput;
};


export type MutationDeleteMetadataTransmissionReferenceArgs = {
  where: MetadataTransmissionReferenceWhereUniqueInput;
};


export type MutationDeleteMetadataTypeArgs = {
  where: MetadataTypeWhereUniqueInput;
};


export type MutationDeleteMetadataUndersubjectArgs = {
  where: MetadataUndersubjectWhereUniqueInput;
};


export type MutationDeleteMetadataUnderUnderSubjectArgs = {
  where: MetadataUnderUnderSubjectWhereUniqueInput;
};


export type MutationDeleteMetadataUniqueIdArgs = {
  where: MetadataUniqueIdWhereUniqueInput;
};


export type MutationDeleteMetadataUnitArgs = {
  where: MetadataUnitWhereUniqueInput;
};


export type MutationDeleteMetadataUnitShortNameArgs = {
  where: MetadataUnitShortNameWhereUniqueInput;
};


export type MutationDeleteMetadataUploadedByArgs = {
  where: MetadataUploadedByWhereUniqueInput;
};


export type MutationDeleteMetadataUploadedByFullNameArgs = {
  where: MetadataUploadedByFullNameWhereUniqueInput;
};


export type MutationDeleteMetadataUploadTimeArgs = {
  where: MetadataUploadTimeWhereUniqueInput;
};


export type MutationDeleteMetadataUrgencyArgs = {
  where: MetadataUrgencyWhereUniqueInput;
};


export type MutationDeleteMetadataUsageTermsArgs = {
  where: MetadataUsageTermsWhereUniqueInput;
};


export type MutationDeleteMetadataUserCommentArgs = {
  where: MetadataUserCommentWhereUniqueInput;
};


export type MutationDeleteMetadataUserDefined195Args = {
  where: MetadataUserDefined195WhereUniqueInput;
};


export type MutationDeleteMetadataUserDefined237Args = {
  where: MetadataUserDefined237WhereUniqueInput;
};


export type MutationDeleteMetadataUserDefined238Args = {
  where: MetadataUserDefined238WhereUniqueInput;
};


export type MutationDeleteMetadataUserDefined239Args = {
  where: MetadataUserDefined239WhereUniqueInput;
};


export type MutationDeleteMetadataUserDefined242Args = {
  where: MetadataUserDefined242WhereUniqueInput;
};


export type MutationDeleteMetadataUserDefined62Args = {
  where: MetadataUserDefined62WhereUniqueInput;
};


export type MutationDeleteMetadataValidArgs = {
  where: MetadataValidWhereUniqueInput;
};


export type MutationDeleteMetadataVersionArgs = {
  where: MetadataVersionWhereUniqueInput;
};


export type MutationDeleteMetadataWebStatementArgs = {
  where: MetadataWebStatementWhereUniqueInput;
};


export type MutationDeleteMetadataWorkflowKindArgs = {
  where: MetadataWorkflowKindWhereUniqueInput;
};


export type MutationDeleteMetadataXmpFileStampsArgs = {
  where: MetadataXmpFileStampsWhereUniqueInput;
};


export type MutationDeleteMetadataXmpHistoryArgs = {
  where: MetadataXmpHistoryWhereUniqueInput;
};


export type MutationDeletePhotoArgs = {
  where: PhotoWhereUniqueInput;
};


export type MutationDeletePhotoMetadataArgs = {
  where: PhotoMetadataWhereUniqueInput;
};


export type MutationDeletePhotoPublicationArgs = {
  where: PhotoPublicationWhereUniqueInput;
};


export type MutationUpdateAgencyArgs = {
  data: AgencyUpdateInput;
  where: AgencyWhereUniqueInput;
};


export type MutationUpdateArchiveArgs = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};


export type MutationUpdateArchiveAgencyArgs = {
  data: ArchiveAgencyUpdateInput;
  where: ArchiveAgencyWhereUniqueInput;
};


export type MutationUpdateCategoryArgs = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationUpdateDeliverySubjectArgs = {
  data: DeliverySubjectUpdateInput;
  where: DeliverySubjectWhereUniqueInput;
};


export type MutationUpdateGcImportSettingArgs = {
  data: GcImportSettingUpdateInput;
  where: GcImportSettingWhereUniqueInput;
};


export type MutationUpdateImportQueueArgs = {
  data: ImportQueueUpdateInput;
  where: ImportQueueWhereUniqueInput;
};


export type MutationUpdateMetadataArgs = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};


export type MutationUpdateMetadataAccessRightsArgs = {
  data: MetadataAccessRightsUpdateInput;
  where: MetadataAccessRightsWhereUniqueInput;
};


export type MutationUpdateMetadataAddlModelInfoArgs = {
  data: MetadataAddlModelInfoUpdateInput;
  where: MetadataAddlModelInfoWhereUniqueInput;
};


export type MutationUpdateMetadataArchiveChildArgs = {
  data: MetadataArchiveChildUpdateInput;
  where: MetadataArchiveChildWhereUniqueInput;
};


export type MutationUpdateMetadataArchiveParentArgs = {
  data: MetadataArchiveParentUpdateInput;
  where: MetadataArchiveParentWhereUniqueInput;
};


export type MutationUpdateMetadataAssetStateArgs = {
  data: MetadataAssetStateUpdateInput;
  where: MetadataAssetStateWhereUniqueInput;
};


export type MutationUpdateMetadataAttributionNameArgs = {
  data: MetadataAttributionNameUpdateInput;
  where: MetadataAttributionNameWhereUniqueInput;
};


export type MutationUpdateMetadataAttributionUrlArgs = {
  data: MetadataAttributionUrlUpdateInput;
  where: MetadataAttributionUrlWhereUniqueInput;
};


export type MutationUpdateMetadataAudienceArgs = {
  data: MetadataAudienceUpdateInput;
  where: MetadataAudienceWhereUniqueInput;
};


export type MutationUpdateMetadataAuthorsPositionArgs = {
  data: MetadataAuthorsPositionUpdateInput;
  where: MetadataAuthorsPositionWhereUniqueInput;
};


export type MutationUpdateMetadataBackupNameArgs = {
  data: MetadataBackupNameUpdateInput;
  where: MetadataBackupNameWhereUniqueInput;
};


export type MutationUpdateMetadataBitmapGrayscalePictureArgs = {
  data: MetadataBitmapGrayscalePictureUpdateInput;
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
};


export type MutationUpdateMetadataBrandArgs = {
  data: MetadataBrandUpdateInput;
  where: MetadataBrandWhereUniqueInput;
};


export type MutationUpdateMetadataBrandPrismaArgs = {
  data: MetadataBrandPrismaUpdateInput;
  where: MetadataBrandPrismaWhereUniqueInput;
};


export type MutationUpdateMetadataBrandShortNameArgs = {
  data: MetadataBrandShortNameUpdateInput;
  where: MetadataBrandShortNameWhereUniqueInput;
};


export type MutationUpdateMetadataCaptionWriterArgs = {
  data: MetadataCaptionWriterUpdateInput;
  where: MetadataCaptionWriterWhereUniqueInput;
};


export type MutationUpdateMetadataCategoryArgs = {
  data: MetadataCategoryUpdateInput;
  where: MetadataCategoryWhereUniqueInput;
};


export type MutationUpdateMetadataCategoryPrismaArgs = {
  data: MetadataCategoryPrismaUpdateInput;
  where: MetadataCategoryPrismaWhereUniqueInput;
};


export type MutationUpdateMetadataCertificateArgs = {
  data: MetadataCertificateUpdateInput;
  where: MetadataCertificateWhereUniqueInput;
};


export type MutationUpdateMetadataCiAdrCityArgs = {
  data: MetadataCiAdrCityUpdateInput;
  where: MetadataCiAdrCityWhereUniqueInput;
};


export type MutationUpdateMetadataCiAdrCtryArgs = {
  data: MetadataCiAdrCtryUpdateInput;
  where: MetadataCiAdrCtryWhereUniqueInput;
};


export type MutationUpdateMetadataCiAdrExtadrArgs = {
  data: MetadataCiAdrExtadrUpdateInput;
  where: MetadataCiAdrExtadrWhereUniqueInput;
};


export type MutationUpdateMetadataCiAdrPcodeArgs = {
  data: MetadataCiAdrPcodeUpdateInput;
  where: MetadataCiAdrPcodeWhereUniqueInput;
};


export type MutationUpdateMetadataCiAdrRegionArgs = {
  data: MetadataCiAdrRegionUpdateInput;
  where: MetadataCiAdrRegionWhereUniqueInput;
};


export type MutationUpdateMetadataCiEmailWorkArgs = {
  data: MetadataCiEmailWorkUpdateInput;
  where: MetadataCiEmailWorkWhereUniqueInput;
};


export type MutationUpdateMetadataCiTelWorkArgs = {
  data: MetadataCiTelWorkUpdateInput;
  where: MetadataCiTelWorkWhereUniqueInput;
};


export type MutationUpdateMetadataCityArgs = {
  data: MetadataCityUpdateInput;
  where: MetadataCityWhereUniqueInput;
};


export type MutationUpdateMetadataCiUrlWorkArgs = {
  data: MetadataCiUrlWorkUpdateInput;
  where: MetadataCiUrlWorkWhereUniqueInput;
};


export type MutationUpdateMetadataClassifyArgs = {
  data: MetadataClassifyUpdateInput;
  where: MetadataClassifyWhereUniqueInput;
};


export type MutationUpdateMetadataCompanyArgs = {
  data: MetadataCompanyUpdateInput;
  where: MetadataCompanyWhereUniqueInput;
};


export type MutationUpdateMetadataCompanyShortNameArgs = {
  data: MetadataCompanyShortNameUpdateInput;
  where: MetadataCompanyShortNameWhereUniqueInput;
};


export type MutationUpdateMetadataConfidentialityArgs = {
  data: MetadataConfidentialityUpdateInput;
  where: MetadataConfidentialityWhereUniqueInput;
};


export type MutationUpdateMetadataContainedInArgs = {
  data: MetadataContainedInUpdateInput;
  where: MetadataContainedInWhereUniqueInput;
};


export type MutationUpdateMetadataContentMediaKindArgs = {
  data: MetadataContentMediaKindUpdateInput;
  where: MetadataContentMediaKindWhereUniqueInput;
};


export type MutationUpdateMetadataContentValueArgs = {
  data: MetadataContentValueUpdateInput;
  where: MetadataContentValueWhereUniqueInput;
};


export type MutationUpdateMetadataCopyrightLayerArgs = {
  data: MetadataCopyrightLayerUpdateInput;
  where: MetadataCopyrightLayerWhereUniqueInput;
};


export type MutationUpdateMetadataCountryArgs = {
  data: MetadataCountryUpdateInput;
  where: MetadataCountryWhereUniqueInput;
};


export type MutationUpdateMetadataCountryCodeArgs = {
  data: MetadataCountryCodeUpdateInput;
  where: MetadataCountryCodeWhereUniqueInput;
};


export type MutationUpdateMetadataCreatedTimeArgs = {
  data: MetadataCreatedTimeUpdateInput;
  where: MetadataCreatedTimeWhereUniqueInput;
};


export type MutationUpdateMetadataCreatorArgs = {
  data: MetadataCreatorUpdateInput;
  where: MetadataCreatorWhereUniqueInput;
};


export type MutationUpdateMetadataCreatorToolArgs = {
  data: MetadataCreatorToolUpdateInput;
  where: MetadataCreatorToolWhereUniqueInput;
};


export type MutationUpdateMetadataCreditArgs = {
  data: MetadataCreditUpdateInput;
  where: MetadataCreditWhereUniqueInput;
};


export type MutationUpdateMetadataCustomField14Args = {
  data: MetadataCustomField14UpdateInput;
  where: MetadataCustomField14WhereUniqueInput;
};


export type MutationUpdateMetadataCustomField15Args = {
  data: MetadataCustomField15UpdateInput;
  where: MetadataCustomField15WhereUniqueInput;
};


export type MutationUpdateMetadataCustomField16Args = {
  data: MetadataCustomField16UpdateInput;
  where: MetadataCustomField16WhereUniqueInput;
};


export type MutationUpdateMetadataCustomField17Args = {
  data: MetadataCustomField17UpdateInput;
  where: MetadataCustomField17WhereUniqueInput;
};


export type MutationUpdateMetadataCustomField20Args = {
  data: MetadataCustomField20UpdateInput;
  where: MetadataCustomField20WhereUniqueInput;
};


export type MutationUpdateMetadataCustomField5Args = {
  data: MetadataCustomField5UpdateInput;
  where: MetadataCustomField5WhereUniqueInput;
};


export type MutationUpdateMetadataCustomField6Args = {
  data: MetadataCustomField6UpdateInput;
  where: MetadataCustomField6WhereUniqueInput;
};


export type MutationUpdateMetadataCustomField7Args = {
  data: MetadataCustomField7UpdateInput;
  where: MetadataCustomField7WhereUniqueInput;
};


export type MutationUpdateMetadataCustomField9Args = {
  data: MetadataCustomField9UpdateInput;
  where: MetadataCustomField9WhereUniqueInput;
};


export type MutationUpdateMetadataDateCreatedArgs = {
  data: MetadataDateCreatedUpdateInput;
  where: MetadataDateCreatedWhereUniqueInput;
};


export type MutationUpdateMetadataDateTimeDigitizedArgs = {
  data: MetadataDateTimeDigitizedUpdateInput;
  where: MetadataDateTimeDigitizedWhereUniqueInput;
};


export type MutationUpdateMetadataDateTimeOriginalArgs = {
  data: MetadataDateTimeOriginalUpdateInput;
  where: MetadataDateTimeOriginalWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryAccountArgs = {
  data: MetadataDeliveryAccountUpdateInput;
  where: MetadataDeliveryAccountWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryCompanyArgs = {
  data: MetadataDeliveryCompanyUpdateInput;
  where: MetadataDeliveryCompanyWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryCopyrightArgs = {
  data: MetadataDeliveryCopyrightUpdateInput;
  where: MetadataDeliveryCopyrightWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryDateFolderArgs = {
  data: MetadataDeliveryDateFolderUpdateInput;
  where: MetadataDeliveryDateFolderWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryDateTimeArgs = {
  data: MetadataDeliveryDateTimeUpdateInput;
  where: MetadataDeliveryDateTimeWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryDcCreatorArgs = {
  data: MetadataDeliveryDcCreatorUpdateInput;
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryDcRightsArgs = {
  data: MetadataDeliveryDcRightsUpdateInput;
  where: MetadataDeliveryDcRightsWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryFileTypeArgs = {
  data: MetadataDeliveryFileTypeUpdateInput;
  where: MetadataDeliveryFileTypeWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryFolderArgs = {
  data: MetadataDeliveryFolderUpdateInput;
  where: MetadataDeliveryFolderWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryKindArgs = {
  data: MetadataDeliveryKindUpdateInput;
  where: MetadataDeliveryKindWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryPathArgs = {
  data: MetadataDeliveryPathUpdateInput;
  where: MetadataDeliveryPathWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryPersonShownintheImageArgs = {
  data: MetadataDeliveryPersonShownintheImageUpdateInput;
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryPhotoshopCreditArgs = {
  data: MetadataDeliveryPhotoshopCreditUpdateInput;
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryPhotoshopSourceArgs = {
  data: MetadataDeliveryPhotoshopSourceUpdateInput;
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryServiceArgs = {
  data: MetadataDeliveryServiceUpdateInput;
  where: MetadataDeliveryServiceWhereUniqueInput;
};


export type MutationUpdateMetadataDeliverySubjectArgs = {
  data: MetadataDeliverySubjectUpdateInput;
  where: MetadataDeliverySubjectWhereUniqueInput;
};


export type MutationUpdateMetadataDeliveryUnderSubjectArgs = {
  data: MetadataDeliveryUnderSubjectUpdateInput;
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
};


export type MutationUpdateMetadataDepartmentArgs = {
  data: MetadataDepartmentUpdateInput;
  where: MetadataDepartmentWhereUniqueInput;
};


export type MutationUpdateMetadataDescriptionArgs = {
  data: MetadataDescriptionUpdateInput;
  where: MetadataDescriptionWhereUniqueInput;
};


export type MutationUpdateMetadataDigitalAssetUrlArgs = {
  data: MetadataDigitalAssetUrlUpdateInput;
  where: MetadataDigitalAssetUrlWhereUniqueInput;
};


export type MutationUpdateMetadataDivisionArgs = {
  data: MetadataDivisionUpdateInput;
  where: MetadataDivisionWhereUniqueInput;
};


export type MutationUpdateMetadataDocumentTextArgs = {
  data: MetadataDocumentTextUpdateInput;
  where: MetadataDocumentTextWhereUniqueInput;
};


export type MutationUpdateMetadataEditionArgs = {
  data: MetadataEditionUpdateInput;
  where: MetadataEditionWhereUniqueInput;
};


export type MutationUpdateMetadataEditorialVersionArgs = {
  data: MetadataEditorialVersionUpdateInput;
  where: MetadataEditorialVersionWhereUniqueInput;
};


export type MutationUpdateMetadataEditStatusArgs = {
  data: MetadataEditStatusUpdateInput;
  where: MetadataEditStatusWhereUniqueInput;
};


export type MutationUpdateMetadataEnvironnementPhotoArgs = {
  data: MetadataEnvironnementPhotoUpdateInput;
  where: MetadataEnvironnementPhotoWhereUniqueInput;
};


export type MutationUpdateMetadataEquipmentInstitutionArgs = {
  data: MetadataEquipmentInstitutionUpdateInput;
  where: MetadataEquipmentInstitutionWhereUniqueInput;
};


export type MutationUpdateMetadataEquipmentManufacturerArgs = {
  data: MetadataEquipmentManufacturerUpdateInput;
  where: MetadataEquipmentManufacturerWhereUniqueInput;
};


export type MutationUpdateMetadataEventArgs = {
  data: MetadataEventUpdateInput;
  where: MetadataEventWhereUniqueInput;
};


export type MutationUpdateMetadataExifArgs = {
  data: MetadataExifUpdateInput;
  where: MetadataExifWhereUniqueInput;
};


export type MutationUpdateMetadataFabStorageArgs = {
  data: MetadataFabStorageUpdateInput;
  where: MetadataFabStorageWhereUniqueInput;
};


export type MutationUpdateMetadataFileCheckForArgs = {
  data: MetadataFileCheckForUpdateInput;
  where: MetadataFileCheckForWhereUniqueInput;
};


export type MutationUpdateMetadataFileInfoBitsPerPixelArgs = {
  data: MetadataFileInfoBitsPerPixelUpdateInput;
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
};


export type MutationUpdateMetadataFileInfoFramesArgs = {
  data: MetadataFileInfoFramesUpdateInput;
  where: MetadataFileInfoFramesWhereUniqueInput;
};


export type MutationUpdateMetadataFileInfoPhotometricInterpretationArgs = {
  data: MetadataFileInfoPhotometricInterpretationUpdateInput;
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
};


export type MutationUpdateMetadataFileInfoTransferSyntaxArgs = {
  data: MetadataFileInfoTransferSyntaxUpdateInput;
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
};


export type MutationUpdateMetadataFileStageArgs = {
  data: MetadataFileStageUpdateInput;
  where: MetadataFileStageWhereUniqueInput;
};


export type MutationUpdateMetadataFileStatusArgs = {
  data: MetadataFileStatusUpdateInput;
  where: MetadataFileStatusWhereUniqueInput;
};


export type MutationUpdateMetadataFileTypeArgs = {
  data: MetadataFileTypeUpdateInput;
  where: MetadataFileTypeWhereUniqueInput;
};


export type MutationUpdateMetadataFixtureIdentifierArgs = {
  data: MetadataFixtureIdentifierUpdateInput;
  where: MetadataFixtureIdentifierWhereUniqueInput;
};


export type MutationUpdateMetadataFormatArgs = {
  data: MetadataFormatUpdateInput;
  where: MetadataFormatWhereUniqueInput;
};


export type MutationUpdateMetadataGpsLatitudeArgs = {
  data: MetadataGpsLatitudeUpdateInput;
  where: MetadataGpsLatitudeWhereUniqueInput;
};


export type MutationUpdateMetadataGpsLongitudeArgs = {
  data: MetadataGpsLongitudeUpdateInput;
  where: MetadataGpsLongitudeWhereUniqueInput;
};


export type MutationUpdateMetadataGpsProcessingMethodArgs = {
  data: MetadataGpsProcessingMethodUpdateInput;
  where: MetadataGpsProcessingMethodWhereUniqueInput;
};


export type MutationUpdateMetadataHeaderArgs = {
  data: MetadataHeaderUpdateInput;
  where: MetadataHeaderWhereUniqueInput;
};


export type MutationUpdateMetadataHeaderPrismaArgs = {
  data: MetadataHeaderPrismaUpdateInput;
  where: MetadataHeaderPrismaWhereUniqueInput;
};


export type MutationUpdateMetadataHeadlineArgs = {
  data: MetadataHeadlineUpdateInput;
  where: MetadataHeadlineWhereUniqueInput;
};


export type MutationUpdateMetadataHistoryArgs = {
  data: MetadataHistoryUpdateInput;
  where: MetadataHistoryWhereUniqueInput;
};


export type MutationUpdateMetadataIccProfileArgs = {
  data: MetadataIccProfileUpdateInput;
  where: MetadataIccProfileWhereUniqueInput;
};


export type MutationUpdateMetadataIdAssignmentArgs = {
  data: MetadataIdAssignmentUpdateInput;
  where: MetadataIdAssignmentWhereUniqueInput;
};


export type MutationUpdateMetadataIdMediaContentArgs = {
  data: MetadataIdMediaContentUpdateInput;
  where: MetadataIdMediaContentWhereUniqueInput;
};


export type MutationUpdateMetadataIdPhotoPrismaArgs = {
  data: MetadataIdPhotoPrismaUpdateInput;
  where: MetadataIdPhotoPrismaWhereUniqueInput;
};


export type MutationUpdateMetadataIdSubjectArgs = {
  data: MetadataIdSubjectUpdateInput;
  where: MetadataIdSubjectWhereUniqueInput;
};


export type MutationUpdateMetadataImageNotesArgs = {
  data: MetadataImageNotesUpdateInput;
  where: MetadataImageNotesWhereUniqueInput;
};


export type MutationUpdateMetadataImageUniqueIdArgs = {
  data: MetadataImageUniqueIdUpdateInput;
  where: MetadataImageUniqueIdWhereUniqueInput;
};


export type MutationUpdateMetadataInstructionsArgs = {
  data: MetadataInstructionsUpdateInput;
  where: MetadataInstructionsWhereUniqueInput;
};


export type MutationUpdateMetadataIntellectualGenreArgs = {
  data: MetadataIntellectualGenreUpdateInput;
  where: MetadataIntellectualGenreWhereUniqueInput;
};


export type MutationUpdateMetadataIsoSpeedRatingsArgs = {
  data: MetadataIsoSpeedRatingsUpdateInput;
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
};


export type MutationUpdateMetadataIssueArgs = {
  data: MetadataIssueUpdateInput;
  where: MetadataIssueWhereUniqueInput;
};


export type MutationUpdateMetadataJobIdArgs = {
  data: MetadataJobIdUpdateInput;
  where: MetadataJobIdWhereUniqueInput;
};


export type MutationUpdateMetadataJobProcessingArgs = {
  data: MetadataJobProcessingUpdateInput;
  where: MetadataJobProcessingWhereUniqueInput;
};


export type MutationUpdateMetadataLayoutPictureEditingArgs = {
  data: MetadataLayoutPictureEditingUpdateInput;
  where: MetadataLayoutPictureEditingWhereUniqueInput;
};


export type MutationUpdateMetadataLayoutStorageArgs = {
  data: MetadataLayoutStorageUpdateInput;
  where: MetadataLayoutStorageWhereUniqueInput;
};


export type MutationUpdateMetadataLicenseArgs = {
  data: MetadataLicenseUpdateInput;
  where: MetadataLicenseWhereUniqueInput;
};


export type MutationUpdateMetadataLinksArgs = {
  data: MetadataLinksUpdateInput;
  where: MetadataLinksWhereUniqueInput;
};


export type MutationUpdateMetadataLocalCaptionArgs = {
  data: MetadataLocalCaptionUpdateInput;
  where: MetadataLocalCaptionWhereUniqueInput;
};


export type MutationUpdateMetadataLocationArgs = {
  data: MetadataLocationUpdateInput;
  where: MetadataLocationWhereUniqueInput;
};


export type MutationUpdateMetadataMakeArgs = {
  data: MetadataMakeUpdateInput;
  where: MetadataMakeWhereUniqueInput;
};


export type MutationUpdateMetadataManifestArgs = {
  data: MetadataManifestUpdateInput;
  where: MetadataManifestWhereUniqueInput;
};


export type MutationUpdateMetadataMarkedArgs = {
  data: MetadataMarkedUpdateInput;
  where: MetadataMarkedWhereUniqueInput;
};


export type MutationUpdateMetadataMasterDocumentIdArgs = {
  data: MetadataMasterDocumentIdUpdateInput;
  where: MetadataMasterDocumentIdWhereUniqueInput;
};


export type MutationUpdateMetadataMaxAvailHeightArgs = {
  data: MetadataMaxAvailHeightUpdateInput;
  where: MetadataMaxAvailHeightWhereUniqueInput;
};


export type MutationUpdateMetadataMaxAvailWidthArgs = {
  data: MetadataMaxAvailWidthUpdateInput;
  where: MetadataMaxAvailWidthWhereUniqueInput;
};


export type MutationUpdateMetadataMinorModelAgeDisclosureArgs = {
  data: MetadataMinorModelAgeDisclosureUpdateInput;
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
};


export type MutationUpdateMetadataModelArgs = {
  data: MetadataModelUpdateInput;
  where: MetadataModelWhereUniqueInput;
};


export type MutationUpdateMetadataModelAgeArgs = {
  data: MetadataModelAgeUpdateInput;
  where: MetadataModelAgeWhereUniqueInput;
};


export type MutationUpdateMetadataModelReleaseIdArgs = {
  data: MetadataModelReleaseIdUpdateInput;
  where: MetadataModelReleaseIdWhereUniqueInput;
};


export type MutationUpdateMetadataModelReleaseStatusArgs = {
  data: MetadataModelReleaseStatusUpdateInput;
  where: MetadataModelReleaseStatusWhereUniqueInput;
};


export type MutationUpdateMetadataMorePermissionsArgs = {
  data: MetadataMorePermissionsUpdateInput;
  where: MetadataMorePermissionsWhereUniqueInput;
};


export type MutationUpdateMetadataObjectCycleArgs = {
  data: MetadataObjectCycleUpdateInput;
  where: MetadataObjectCycleWhereUniqueInput;
};


export type MutationUpdateMetadataOrganisationInImageNameArgs = {
  data: MetadataOrganisationInImageNameUpdateInput;
  where: MetadataOrganisationInImageNameWhereUniqueInput;
};


export type MutationUpdateMetadataOriginalColorArgs = {
  data: MetadataOriginalColorUpdateInput;
  where: MetadataOriginalColorWhereUniqueInput;
};


export type MutationUpdateMetadataOriginalIccProfileArgs = {
  data: MetadataOriginalIccProfileUpdateInput;
  where: MetadataOriginalIccProfileWhereUniqueInput;
};


export type MutationUpdateMetadataOriginalNameArgs = {
  data: MetadataOriginalNameUpdateInput;
  where: MetadataOriginalNameWhereUniqueInput;
};


export type MutationUpdateMetadataOriginalWeigthArgs = {
  data: MetadataOriginalWeigthUpdateInput;
  where: MetadataOriginalWeigthWhereUniqueInput;
};


export type MutationUpdateMetadataOwnerArgs = {
  data: MetadataOwnerUpdateInput;
  where: MetadataOwnerWhereUniqueInput;
};


export type MutationUpdateMetadataOwnerIdArgs = {
  data: MetadataOwnerIdUpdateInput;
  where: MetadataOwnerIdWhereUniqueInput;
};


export type MutationUpdateMetadataPageArgs = {
  data: MetadataPageUpdateInput;
  where: MetadataPageWhereUniqueInput;
};


export type MutationUpdateMetadataPagePrismaArgs = {
  data: MetadataPagePrismaUpdateInput;
  where: MetadataPagePrismaWhereUniqueInput;
};


export type MutationUpdateMetadataPaintBasedCorrectionsArgs = {
  data: MetadataPaintBasedCorrectionsUpdateInput;
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
};


export type MutationUpdateMetadataPatientDobArgs = {
  data: MetadataPatientDobUpdateInput;
  where: MetadataPatientDobWhereUniqueInput;
};


export type MutationUpdateMetadataPatientIdArgs = {
  data: MetadataPatientIdUpdateInput;
  where: MetadataPatientIdWhereUniqueInput;
};


export type MutationUpdateMetadataPatientNameArgs = {
  data: MetadataPatientNameUpdateInput;
  where: MetadataPatientNameWhereUniqueInput;
};


export type MutationUpdateMetadataPatientSexArgs = {
  data: MetadataPatientSexUpdateInput;
  where: MetadataPatientSexWhereUniqueInput;
};


export type MutationUpdateMetadataPersonInImageArgs = {
  data: MetadataPersonInImageUpdateInput;
  where: MetadataPersonInImageWhereUniqueInput;
};


export type MutationUpdateMetadataPhotoStorageArgs = {
  data: MetadataPhotoStorageUpdateInput;
  where: MetadataPhotoStorageWhereUniqueInput;
};


export type MutationUpdateMetadataPrepressPictureEditingArgs = {
  data: MetadataPrepressPictureEditingUpdateInput;
  where: MetadataPrepressPictureEditingWhereUniqueInput;
};


export type MutationUpdateMetadataPriceLevelArgs = {
  data: MetadataPriceLevelUpdateInput;
  where: MetadataPriceLevelWhereUniqueInput;
};


export type MutationUpdateMetadataPrintingProfileArgs = {
  data: MetadataPrintingProfileUpdateInput;
  where: MetadataPrintingProfileWhereUniqueInput;
};


export type MutationUpdateMetadataPrismaProductionArgs = {
  data: MetadataPrismaProductionUpdateInput;
  where: MetadataPrismaProductionWhereUniqueInput;
};


export type MutationUpdateMetadataProcessHistoryArgs = {
  data: MetadataProcessHistoryUpdateInput;
  where: MetadataProcessHistoryWhereUniqueInput;
};


export type MutationUpdateMetadataProcessParameterArgs = {
  data: MetadataProcessParameterUpdateInput;
  where: MetadataProcessParameterWhereUniqueInput;
};


export type MutationUpdateMetadataProcessStatusArgs = {
  data: MetadataProcessStatusUpdateInput;
  where: MetadataProcessStatusWhereUniqueInput;
};


export type MutationUpdateMetadataProductArgs = {
  data: MetadataProductUpdateInput;
  where: MetadataProductWhereUniqueInput;
};


export type MutationUpdateMetadataProductsArgs = {
  data: MetadataProductsUpdateInput;
  where: MetadataProductsWhereUniqueInput;
};


export type MutationUpdateMetadataProductShortNameArgs = {
  data: MetadataProductShortNameUpdateInput;
  where: MetadataProductShortNameWhereUniqueInput;
};


export type MutationUpdateMetadataProduitsArgs = {
  data: MetadataProduitsUpdateInput;
  where: MetadataProduitsWhereUniqueInput;
};


export type MutationUpdateMetadataProgramVersionArgs = {
  data: MetadataProgramVersionUpdateInput;
  where: MetadataProgramVersionWhereUniqueInput;
};


export type MutationUpdateMetadataPropertyReleaseIdArgs = {
  data: MetadataPropertyReleaseIdUpdateInput;
  where: MetadataPropertyReleaseIdWhereUniqueInput;
};


export type MutationUpdateMetadataPropertyReleaseStatusArgs = {
  data: MetadataPropertyReleaseStatusUpdateInput;
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
};


export type MutationUpdateMetadataPublisherArgs = {
  data: MetadataPublisherUpdateInput;
  where: MetadataPublisherWhereUniqueInput;
};


export type MutationUpdateMetadataPublishingIssueArgs = {
  data: MetadataPublishingIssueUpdateInput;
  where: MetadataPublishingIssueWhereUniqueInput;
};


export type MutationUpdateMetadataPublishingSubjectArgs = {
  data: MetadataPublishingSubjectUpdateInput;
  where: MetadataPublishingSubjectWhereUniqueInput;
};


export type MutationUpdateMetadataQualifiedUsePrismaByArgs = {
  data: MetadataQualifiedUsePrismaByUpdateInput;
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
};


export type MutationUpdateMetadataQualifiedUsePrismaDateArgs = {
  data: MetadataQualifiedUsePrismaDateUpdateInput;
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
};


export type MutationUpdateMetadataQualifiedUsePrismaDurationArgs = {
  data: MetadataQualifiedUsePrismaDurationUpdateInput;
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
};


export type MutationUpdateMetadataQualifiedUsePrismaSupportArgs = {
  data: MetadataQualifiedUsePrismaSupportUpdateInput;
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
};


export type MutationUpdateMetadataRatingArgs = {
  data: MetadataRatingUpdateInput;
  where: MetadataRatingWhereUniqueInput;
};


export type MutationUpdateMetadataReferenceDateArgs = {
  data: MetadataReferenceDateUpdateInput;
  where: MetadataReferenceDateWhereUniqueInput;
};


export type MutationUpdateMetadataReferenceNumberArgs = {
  data: MetadataReferenceNumberUpdateInput;
  where: MetadataReferenceNumberWhereUniqueInput;
};


export type MutationUpdateMetadataReferenceServiceArgs = {
  data: MetadataReferenceServiceUpdateInput;
  where: MetadataReferenceServiceWhereUniqueInput;
};


export type MutationUpdateMetadataReleaseDateArgs = {
  data: MetadataReleaseDateUpdateInput;
  where: MetadataReleaseDateWhereUniqueInput;
};


export type MutationUpdateMetadataReleaseTimeArgs = {
  data: MetadataReleaseTimeUpdateInput;
  where: MetadataReleaseTimeWhereUniqueInput;
};


export type MutationUpdateMetadataRequiredPermissionArgs = {
  data: MetadataRequiredPermissionUpdateInput;
  where: MetadataRequiredPermissionWhereUniqueInput;
};


export type MutationUpdateMetadataResolutionKindArgs = {
  data: MetadataResolutionKindUpdateInput;
  where: MetadataResolutionKindWhereUniqueInput;
};


export type MutationUpdateMetadataRightsArgs = {
  data: MetadataRightsUpdateInput;
  where: MetadataRightsWhereUniqueInput;
};


export type MutationUpdateMetadataRoyaltyfreeArgs = {
  data: MetadataRoyaltyfreeUpdateInput;
  where: MetadataRoyaltyfreeWhereUniqueInput;
};


export type MutationUpdateMetadataSceneArgs = {
  data: MetadataSceneUpdateInput;
  where: MetadataSceneWhereUniqueInput;
};


export type MutationUpdateMetadataSectionArgs = {
  data: MetadataSectionUpdateInput;
  where: MetadataSectionWhereUniqueInput;
};


export type MutationUpdateMetadataSentToArgs = {
  data: MetadataSentToUpdateInput;
  where: MetadataSentToWhereUniqueInput;
};


export type MutationUpdateMetadataSerialNumberArgs = {
  data: MetadataSerialNumberUpdateInput;
  where: MetadataSerialNumberWhereUniqueInput;
};


export type MutationUpdateMetadataSeriesDateTimeArgs = {
  data: MetadataSeriesDateTimeUpdateInput;
  where: MetadataSeriesDateTimeWhereUniqueInput;
};


export type MutationUpdateMetadataSeriesDescriptionArgs = {
  data: MetadataSeriesDescriptionUpdateInput;
  where: MetadataSeriesDescriptionWhereUniqueInput;
};


export type MutationUpdateMetadataSeriesModalityArgs = {
  data: MetadataSeriesModalityUpdateInput;
  where: MetadataSeriesModalityWhereUniqueInput;
};


export type MutationUpdateMetadataSeriesNumberArgs = {
  data: MetadataSeriesNumberUpdateInput;
  where: MetadataSeriesNumberWhereUniqueInput;
};


export type MutationUpdateMetadataShortUniqueIdArgs = {
  data: MetadataShortUniqueIdUpdateInput;
  where: MetadataShortUniqueIdWhereUniqueInput;
};


export type MutationUpdateMetadataSourceArgs = {
  data: MetadataSourceUpdateInput;
  where: MetadataSourceWhereUniqueInput;
};


export type MutationUpdateMetadataStateArgs = {
  data: MetadataStateUpdateInput;
  where: MetadataStateWhereUniqueInput;
};


export type MutationUpdateMetadataStudyDateTimeArgs = {
  data: MetadataStudyDateTimeUpdateInput;
  where: MetadataStudyDateTimeWhereUniqueInput;
};


export type MutationUpdateMetadataStudyDescriptionArgs = {
  data: MetadataStudyDescriptionUpdateInput;
  where: MetadataStudyDescriptionWhereUniqueInput;
};


export type MutationUpdateMetadataStudyIdArgs = {
  data: MetadataStudyIdUpdateInput;
  where: MetadataStudyIdWhereUniqueInput;
};


export type MutationUpdateMetadataStudyPhysicianArgs = {
  data: MetadataStudyPhysicianUpdateInput;
  where: MetadataStudyPhysicianWhereUniqueInput;
};


export type MutationUpdateMetadataSubjectArgs = {
  data: MetadataSubjectUpdateInput;
  where: MetadataSubjectWhereUniqueInput;
};


export type MutationUpdateMetadataSubjectCodeArgs = {
  data: MetadataSubjectCodeUpdateInput;
  where: MetadataSubjectCodeWhereUniqueInput;
};


export type MutationUpdateMetadataSubjectPrismaArgs = {
  data: MetadataSubjectPrismaUpdateInput;
  where: MetadataSubjectPrismaWhereUniqueInput;
};


export type MutationUpdateMetadataSupplementalCategoriesArgs = {
  data: MetadataSupplementalCategoriesUpdateInput;
  where: MetadataSupplementalCategoriesWhereUniqueInput;
};


export type MutationUpdateMetadataTargetVersionArgs = {
  data: MetadataTargetVersionUpdateInput;
  where: MetadataTargetVersionWhereUniqueInput;
};


export type MutationUpdateMetadataTitleArgs = {
  data: MetadataTitleUpdateInput;
  where: MetadataTitleWhereUniqueInput;
};


export type MutationUpdateMetadataToneCurveArgs = {
  data: MetadataToneCurveUpdateInput;
  where: MetadataToneCurveWhereUniqueInput;
};


export type MutationUpdateMetadataTransferredByArgs = {
  data: MetadataTransferredByUpdateInput;
  where: MetadataTransferredByWhereUniqueInput;
};


export type MutationUpdateMetadataTransferredByEmailArgs = {
  data: MetadataTransferredByEmailUpdateInput;
  where: MetadataTransferredByEmailWhereUniqueInput;
};


export type MutationUpdateMetadataTransferredByFullNameArgs = {
  data: MetadataTransferredByFullNameUpdateInput;
  where: MetadataTransferredByFullNameWhereUniqueInput;
};


export type MutationUpdateMetadataTransmissionReferenceArgs = {
  data: MetadataTransmissionReferenceUpdateInput;
  where: MetadataTransmissionReferenceWhereUniqueInput;
};


export type MutationUpdateMetadataTypeArgs = {
  data: MetadataTypeUpdateInput;
  where: MetadataTypeWhereUniqueInput;
};


export type MutationUpdateMetadataUndersubjectArgs = {
  data: MetadataUndersubjectUpdateInput;
  where: MetadataUndersubjectWhereUniqueInput;
};


export type MutationUpdateMetadataUnderUnderSubjectArgs = {
  data: MetadataUnderUnderSubjectUpdateInput;
  where: MetadataUnderUnderSubjectWhereUniqueInput;
};


export type MutationUpdateMetadataUniqueIdArgs = {
  data: MetadataUniqueIdUpdateInput;
  where: MetadataUniqueIdWhereUniqueInput;
};


export type MutationUpdateMetadataUnitArgs = {
  data: MetadataUnitUpdateInput;
  where: MetadataUnitWhereUniqueInput;
};


export type MutationUpdateMetadataUnitShortNameArgs = {
  data: MetadataUnitShortNameUpdateInput;
  where: MetadataUnitShortNameWhereUniqueInput;
};


export type MutationUpdateMetadataUploadedByArgs = {
  data: MetadataUploadedByUpdateInput;
  where: MetadataUploadedByWhereUniqueInput;
};


export type MutationUpdateMetadataUploadedByFullNameArgs = {
  data: MetadataUploadedByFullNameUpdateInput;
  where: MetadataUploadedByFullNameWhereUniqueInput;
};


export type MutationUpdateMetadataUploadTimeArgs = {
  data: MetadataUploadTimeUpdateInput;
  where: MetadataUploadTimeWhereUniqueInput;
};


export type MutationUpdateMetadataUrgencyArgs = {
  data: MetadataUrgencyUpdateInput;
  where: MetadataUrgencyWhereUniqueInput;
};


export type MutationUpdateMetadataUsageTermsArgs = {
  data: MetadataUsageTermsUpdateInput;
  where: MetadataUsageTermsWhereUniqueInput;
};


export type MutationUpdateMetadataUserCommentArgs = {
  data: MetadataUserCommentUpdateInput;
  where: MetadataUserCommentWhereUniqueInput;
};


export type MutationUpdateMetadataUserDefined195Args = {
  data: MetadataUserDefined195UpdateInput;
  where: MetadataUserDefined195WhereUniqueInput;
};


export type MutationUpdateMetadataUserDefined237Args = {
  data: MetadataUserDefined237UpdateInput;
  where: MetadataUserDefined237WhereUniqueInput;
};


export type MutationUpdateMetadataUserDefined238Args = {
  data: MetadataUserDefined238UpdateInput;
  where: MetadataUserDefined238WhereUniqueInput;
};


export type MutationUpdateMetadataUserDefined239Args = {
  data: MetadataUserDefined239UpdateInput;
  where: MetadataUserDefined239WhereUniqueInput;
};


export type MutationUpdateMetadataUserDefined242Args = {
  data: MetadataUserDefined242UpdateInput;
  where: MetadataUserDefined242WhereUniqueInput;
};


export type MutationUpdateMetadataUserDefined62Args = {
  data: MetadataUserDefined62UpdateInput;
  where: MetadataUserDefined62WhereUniqueInput;
};


export type MutationUpdateMetadataValidArgs = {
  data: MetadataValidUpdateInput;
  where: MetadataValidWhereUniqueInput;
};


export type MutationUpdateMetadataVersionArgs = {
  data: MetadataVersionUpdateInput;
  where: MetadataVersionWhereUniqueInput;
};


export type MutationUpdateMetadataWebStatementArgs = {
  data: MetadataWebStatementUpdateInput;
  where: MetadataWebStatementWhereUniqueInput;
};


export type MutationUpdateMetadataWorkflowKindArgs = {
  data: MetadataWorkflowKindUpdateInput;
  where: MetadataWorkflowKindWhereUniqueInput;
};


export type MutationUpdateMetadataXmpFileStampsArgs = {
  data: MetadataXmpFileStampsUpdateInput;
  where: MetadataXmpFileStampsWhereUniqueInput;
};


export type MutationUpdateMetadataXmpHistoryArgs = {
  data: MetadataXmpHistoryUpdateInput;
  where: MetadataXmpHistoryWhereUniqueInput;
};


export type MutationUpdatePhotoArgs = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};


export type MutationUpdatePhotoFileArgs = {
  photoId: Scalars['String']['input'];
};


export type MutationUpdatePhotoMetadataArgs = {
  data: PhotoMetadataUpdateInput;
  where: PhotoMetadataWhereUniqueInput;
};


export type MutationUpdatePhotoPublicationArgs = {
  data: PhotoPublicationUpdateInput;
  where: PhotoPublicationWhereUniqueInput;
};


export type MutationUpsertAgencyArgs = {
  create: AgencyCreateInput;
  update: AgencyUpdateInput;
  where: AgencyWhereUniqueInput;
};


export type MutationUpsertArchiveArgs = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};


export type MutationUpsertArchiveAgencyArgs = {
  create: ArchiveAgencyCreateInput;
  update: ArchiveAgencyUpdateInput;
  where: ArchiveAgencyWhereUniqueInput;
};


export type MutationUpsertCategoryArgs = {
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};


export type MutationUpsertDeliverySubjectArgs = {
  create: DeliverySubjectCreateInput;
  update: DeliverySubjectUpdateInput;
  where: DeliverySubjectWhereUniqueInput;
};


export type MutationUpsertGcImportSettingArgs = {
  create: GcImportSettingCreateInput;
  update: GcImportSettingUpdateInput;
  where: GcImportSettingWhereUniqueInput;
};


export type MutationUpsertImportQueueArgs = {
  create: ImportQueueCreateInput;
  update: ImportQueueUpdateInput;
  where: ImportQueueWhereUniqueInput;
};


export type MutationUpsertMetadataArgs = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};


export type MutationUpsertMetadataAccessRightsArgs = {
  create: MetadataAccessRightsCreateInput;
  update: MetadataAccessRightsUpdateInput;
  where: MetadataAccessRightsWhereUniqueInput;
};


export type MutationUpsertMetadataAddlModelInfoArgs = {
  create: MetadataAddlModelInfoCreateInput;
  update: MetadataAddlModelInfoUpdateInput;
  where: MetadataAddlModelInfoWhereUniqueInput;
};


export type MutationUpsertMetadataArchiveChildArgs = {
  create: MetadataArchiveChildCreateInput;
  update: MetadataArchiveChildUpdateInput;
  where: MetadataArchiveChildWhereUniqueInput;
};


export type MutationUpsertMetadataArchiveParentArgs = {
  create: MetadataArchiveParentCreateInput;
  update: MetadataArchiveParentUpdateInput;
  where: MetadataArchiveParentWhereUniqueInput;
};


export type MutationUpsertMetadataAssetStateArgs = {
  create: MetadataAssetStateCreateInput;
  update: MetadataAssetStateUpdateInput;
  where: MetadataAssetStateWhereUniqueInput;
};


export type MutationUpsertMetadataAttributionNameArgs = {
  create: MetadataAttributionNameCreateInput;
  update: MetadataAttributionNameUpdateInput;
  where: MetadataAttributionNameWhereUniqueInput;
};


export type MutationUpsertMetadataAttributionUrlArgs = {
  create: MetadataAttributionUrlCreateInput;
  update: MetadataAttributionUrlUpdateInput;
  where: MetadataAttributionUrlWhereUniqueInput;
};


export type MutationUpsertMetadataAudienceArgs = {
  create: MetadataAudienceCreateInput;
  update: MetadataAudienceUpdateInput;
  where: MetadataAudienceWhereUniqueInput;
};


export type MutationUpsertMetadataAuthorsPositionArgs = {
  create: MetadataAuthorsPositionCreateInput;
  update: MetadataAuthorsPositionUpdateInput;
  where: MetadataAuthorsPositionWhereUniqueInput;
};


export type MutationUpsertMetadataBackupNameArgs = {
  create: MetadataBackupNameCreateInput;
  update: MetadataBackupNameUpdateInput;
  where: MetadataBackupNameWhereUniqueInput;
};


export type MutationUpsertMetadataBitmapGrayscalePictureArgs = {
  create: MetadataBitmapGrayscalePictureCreateInput;
  update: MetadataBitmapGrayscalePictureUpdateInput;
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
};


export type MutationUpsertMetadataBrandArgs = {
  create: MetadataBrandCreateInput;
  update: MetadataBrandUpdateInput;
  where: MetadataBrandWhereUniqueInput;
};


export type MutationUpsertMetadataBrandPrismaArgs = {
  create: MetadataBrandPrismaCreateInput;
  update: MetadataBrandPrismaUpdateInput;
  where: MetadataBrandPrismaWhereUniqueInput;
};


export type MutationUpsertMetadataBrandShortNameArgs = {
  create: MetadataBrandShortNameCreateInput;
  update: MetadataBrandShortNameUpdateInput;
  where: MetadataBrandShortNameWhereUniqueInput;
};


export type MutationUpsertMetadataCaptionWriterArgs = {
  create: MetadataCaptionWriterCreateInput;
  update: MetadataCaptionWriterUpdateInput;
  where: MetadataCaptionWriterWhereUniqueInput;
};


export type MutationUpsertMetadataCategoryArgs = {
  create: MetadataCategoryCreateInput;
  update: MetadataCategoryUpdateInput;
  where: MetadataCategoryWhereUniqueInput;
};


export type MutationUpsertMetadataCategoryPrismaArgs = {
  create: MetadataCategoryPrismaCreateInput;
  update: MetadataCategoryPrismaUpdateInput;
  where: MetadataCategoryPrismaWhereUniqueInput;
};


export type MutationUpsertMetadataCertificateArgs = {
  create: MetadataCertificateCreateInput;
  update: MetadataCertificateUpdateInput;
  where: MetadataCertificateWhereUniqueInput;
};


export type MutationUpsertMetadataCiAdrCityArgs = {
  create: MetadataCiAdrCityCreateInput;
  update: MetadataCiAdrCityUpdateInput;
  where: MetadataCiAdrCityWhereUniqueInput;
};


export type MutationUpsertMetadataCiAdrCtryArgs = {
  create: MetadataCiAdrCtryCreateInput;
  update: MetadataCiAdrCtryUpdateInput;
  where: MetadataCiAdrCtryWhereUniqueInput;
};


export type MutationUpsertMetadataCiAdrExtadrArgs = {
  create: MetadataCiAdrExtadrCreateInput;
  update: MetadataCiAdrExtadrUpdateInput;
  where: MetadataCiAdrExtadrWhereUniqueInput;
};


export type MutationUpsertMetadataCiAdrPcodeArgs = {
  create: MetadataCiAdrPcodeCreateInput;
  update: MetadataCiAdrPcodeUpdateInput;
  where: MetadataCiAdrPcodeWhereUniqueInput;
};


export type MutationUpsertMetadataCiAdrRegionArgs = {
  create: MetadataCiAdrRegionCreateInput;
  update: MetadataCiAdrRegionUpdateInput;
  where: MetadataCiAdrRegionWhereUniqueInput;
};


export type MutationUpsertMetadataCiEmailWorkArgs = {
  create: MetadataCiEmailWorkCreateInput;
  update: MetadataCiEmailWorkUpdateInput;
  where: MetadataCiEmailWorkWhereUniqueInput;
};


export type MutationUpsertMetadataCiTelWorkArgs = {
  create: MetadataCiTelWorkCreateInput;
  update: MetadataCiTelWorkUpdateInput;
  where: MetadataCiTelWorkWhereUniqueInput;
};


export type MutationUpsertMetadataCityArgs = {
  create: MetadataCityCreateInput;
  update: MetadataCityUpdateInput;
  where: MetadataCityWhereUniqueInput;
};


export type MutationUpsertMetadataCiUrlWorkArgs = {
  create: MetadataCiUrlWorkCreateInput;
  update: MetadataCiUrlWorkUpdateInput;
  where: MetadataCiUrlWorkWhereUniqueInput;
};


export type MutationUpsertMetadataClassifyArgs = {
  create: MetadataClassifyCreateInput;
  update: MetadataClassifyUpdateInput;
  where: MetadataClassifyWhereUniqueInput;
};


export type MutationUpsertMetadataCompanyArgs = {
  create: MetadataCompanyCreateInput;
  update: MetadataCompanyUpdateInput;
  where: MetadataCompanyWhereUniqueInput;
};


export type MutationUpsertMetadataCompanyShortNameArgs = {
  create: MetadataCompanyShortNameCreateInput;
  update: MetadataCompanyShortNameUpdateInput;
  where: MetadataCompanyShortNameWhereUniqueInput;
};


export type MutationUpsertMetadataConfidentialityArgs = {
  create: MetadataConfidentialityCreateInput;
  update: MetadataConfidentialityUpdateInput;
  where: MetadataConfidentialityWhereUniqueInput;
};


export type MutationUpsertMetadataContainedInArgs = {
  create: MetadataContainedInCreateInput;
  update: MetadataContainedInUpdateInput;
  where: MetadataContainedInWhereUniqueInput;
};


export type MutationUpsertMetadataContentMediaKindArgs = {
  create: MetadataContentMediaKindCreateInput;
  update: MetadataContentMediaKindUpdateInput;
  where: MetadataContentMediaKindWhereUniqueInput;
};


export type MutationUpsertMetadataContentValueArgs = {
  create: MetadataContentValueCreateInput;
  update: MetadataContentValueUpdateInput;
  where: MetadataContentValueWhereUniqueInput;
};


export type MutationUpsertMetadataCopyrightLayerArgs = {
  create: MetadataCopyrightLayerCreateInput;
  update: MetadataCopyrightLayerUpdateInput;
  where: MetadataCopyrightLayerWhereUniqueInput;
};


export type MutationUpsertMetadataCountryArgs = {
  create: MetadataCountryCreateInput;
  update: MetadataCountryUpdateInput;
  where: MetadataCountryWhereUniqueInput;
};


export type MutationUpsertMetadataCountryCodeArgs = {
  create: MetadataCountryCodeCreateInput;
  update: MetadataCountryCodeUpdateInput;
  where: MetadataCountryCodeWhereUniqueInput;
};


export type MutationUpsertMetadataCreatedTimeArgs = {
  create: MetadataCreatedTimeCreateInput;
  update: MetadataCreatedTimeUpdateInput;
  where: MetadataCreatedTimeWhereUniqueInput;
};


export type MutationUpsertMetadataCreatorArgs = {
  create: MetadataCreatorCreateInput;
  update: MetadataCreatorUpdateInput;
  where: MetadataCreatorWhereUniqueInput;
};


export type MutationUpsertMetadataCreatorToolArgs = {
  create: MetadataCreatorToolCreateInput;
  update: MetadataCreatorToolUpdateInput;
  where: MetadataCreatorToolWhereUniqueInput;
};


export type MutationUpsertMetadataCreditArgs = {
  create: MetadataCreditCreateInput;
  update: MetadataCreditUpdateInput;
  where: MetadataCreditWhereUniqueInput;
};


export type MutationUpsertMetadataCustomField14Args = {
  create: MetadataCustomField14CreateInput;
  update: MetadataCustomField14UpdateInput;
  where: MetadataCustomField14WhereUniqueInput;
};


export type MutationUpsertMetadataCustomField15Args = {
  create: MetadataCustomField15CreateInput;
  update: MetadataCustomField15UpdateInput;
  where: MetadataCustomField15WhereUniqueInput;
};


export type MutationUpsertMetadataCustomField16Args = {
  create: MetadataCustomField16CreateInput;
  update: MetadataCustomField16UpdateInput;
  where: MetadataCustomField16WhereUniqueInput;
};


export type MutationUpsertMetadataCustomField17Args = {
  create: MetadataCustomField17CreateInput;
  update: MetadataCustomField17UpdateInput;
  where: MetadataCustomField17WhereUniqueInput;
};


export type MutationUpsertMetadataCustomField20Args = {
  create: MetadataCustomField20CreateInput;
  update: MetadataCustomField20UpdateInput;
  where: MetadataCustomField20WhereUniqueInput;
};


export type MutationUpsertMetadataCustomField5Args = {
  create: MetadataCustomField5CreateInput;
  update: MetadataCustomField5UpdateInput;
  where: MetadataCustomField5WhereUniqueInput;
};


export type MutationUpsertMetadataCustomField6Args = {
  create: MetadataCustomField6CreateInput;
  update: MetadataCustomField6UpdateInput;
  where: MetadataCustomField6WhereUniqueInput;
};


export type MutationUpsertMetadataCustomField7Args = {
  create: MetadataCustomField7CreateInput;
  update: MetadataCustomField7UpdateInput;
  where: MetadataCustomField7WhereUniqueInput;
};


export type MutationUpsertMetadataCustomField9Args = {
  create: MetadataCustomField9CreateInput;
  update: MetadataCustomField9UpdateInput;
  where: MetadataCustomField9WhereUniqueInput;
};


export type MutationUpsertMetadataDateCreatedArgs = {
  create: MetadataDateCreatedCreateInput;
  update: MetadataDateCreatedUpdateInput;
  where: MetadataDateCreatedWhereUniqueInput;
};


export type MutationUpsertMetadataDateTimeDigitizedArgs = {
  create: MetadataDateTimeDigitizedCreateInput;
  update: MetadataDateTimeDigitizedUpdateInput;
  where: MetadataDateTimeDigitizedWhereUniqueInput;
};


export type MutationUpsertMetadataDateTimeOriginalArgs = {
  create: MetadataDateTimeOriginalCreateInput;
  update: MetadataDateTimeOriginalUpdateInput;
  where: MetadataDateTimeOriginalWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryAccountArgs = {
  create: MetadataDeliveryAccountCreateInput;
  update: MetadataDeliveryAccountUpdateInput;
  where: MetadataDeliveryAccountWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryCompanyArgs = {
  create: MetadataDeliveryCompanyCreateInput;
  update: MetadataDeliveryCompanyUpdateInput;
  where: MetadataDeliveryCompanyWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryCopyrightArgs = {
  create: MetadataDeliveryCopyrightCreateInput;
  update: MetadataDeliveryCopyrightUpdateInput;
  where: MetadataDeliveryCopyrightWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryDateFolderArgs = {
  create: MetadataDeliveryDateFolderCreateInput;
  update: MetadataDeliveryDateFolderUpdateInput;
  where: MetadataDeliveryDateFolderWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryDateTimeArgs = {
  create: MetadataDeliveryDateTimeCreateInput;
  update: MetadataDeliveryDateTimeUpdateInput;
  where: MetadataDeliveryDateTimeWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryDcCreatorArgs = {
  create: MetadataDeliveryDcCreatorCreateInput;
  update: MetadataDeliveryDcCreatorUpdateInput;
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryDcRightsArgs = {
  create: MetadataDeliveryDcRightsCreateInput;
  update: MetadataDeliveryDcRightsUpdateInput;
  where: MetadataDeliveryDcRightsWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryFileTypeArgs = {
  create: MetadataDeliveryFileTypeCreateInput;
  update: MetadataDeliveryFileTypeUpdateInput;
  where: MetadataDeliveryFileTypeWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryFolderArgs = {
  create: MetadataDeliveryFolderCreateInput;
  update: MetadataDeliveryFolderUpdateInput;
  where: MetadataDeliveryFolderWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryKindArgs = {
  create: MetadataDeliveryKindCreateInput;
  update: MetadataDeliveryKindUpdateInput;
  where: MetadataDeliveryKindWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryPathArgs = {
  create: MetadataDeliveryPathCreateInput;
  update: MetadataDeliveryPathUpdateInput;
  where: MetadataDeliveryPathWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryPersonShownintheImageArgs = {
  create: MetadataDeliveryPersonShownintheImageCreateInput;
  update: MetadataDeliveryPersonShownintheImageUpdateInput;
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryPhotoshopCreditArgs = {
  create: MetadataDeliveryPhotoshopCreditCreateInput;
  update: MetadataDeliveryPhotoshopCreditUpdateInput;
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryPhotoshopSourceArgs = {
  create: MetadataDeliveryPhotoshopSourceCreateInput;
  update: MetadataDeliveryPhotoshopSourceUpdateInput;
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryServiceArgs = {
  create: MetadataDeliveryServiceCreateInput;
  update: MetadataDeliveryServiceUpdateInput;
  where: MetadataDeliveryServiceWhereUniqueInput;
};


export type MutationUpsertMetadataDeliverySubjectArgs = {
  create: MetadataDeliverySubjectCreateInput;
  update: MetadataDeliverySubjectUpdateInput;
  where: MetadataDeliverySubjectWhereUniqueInput;
};


export type MutationUpsertMetadataDeliveryUnderSubjectArgs = {
  create: MetadataDeliveryUnderSubjectCreateInput;
  update: MetadataDeliveryUnderSubjectUpdateInput;
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
};


export type MutationUpsertMetadataDepartmentArgs = {
  create: MetadataDepartmentCreateInput;
  update: MetadataDepartmentUpdateInput;
  where: MetadataDepartmentWhereUniqueInput;
};


export type MutationUpsertMetadataDescriptionArgs = {
  create: MetadataDescriptionCreateInput;
  update: MetadataDescriptionUpdateInput;
  where: MetadataDescriptionWhereUniqueInput;
};


export type MutationUpsertMetadataDigitalAssetUrlArgs = {
  create: MetadataDigitalAssetUrlCreateInput;
  update: MetadataDigitalAssetUrlUpdateInput;
  where: MetadataDigitalAssetUrlWhereUniqueInput;
};


export type MutationUpsertMetadataDivisionArgs = {
  create: MetadataDivisionCreateInput;
  update: MetadataDivisionUpdateInput;
  where: MetadataDivisionWhereUniqueInput;
};


export type MutationUpsertMetadataDocumentTextArgs = {
  create: MetadataDocumentTextCreateInput;
  update: MetadataDocumentTextUpdateInput;
  where: MetadataDocumentTextWhereUniqueInput;
};


export type MutationUpsertMetadataEditionArgs = {
  create: MetadataEditionCreateInput;
  update: MetadataEditionUpdateInput;
  where: MetadataEditionWhereUniqueInput;
};


export type MutationUpsertMetadataEditorialVersionArgs = {
  create: MetadataEditorialVersionCreateInput;
  update: MetadataEditorialVersionUpdateInput;
  where: MetadataEditorialVersionWhereUniqueInput;
};


export type MutationUpsertMetadataEditStatusArgs = {
  create: MetadataEditStatusCreateInput;
  update: MetadataEditStatusUpdateInput;
  where: MetadataEditStatusWhereUniqueInput;
};


export type MutationUpsertMetadataEnvironnementPhotoArgs = {
  create: MetadataEnvironnementPhotoCreateInput;
  update: MetadataEnvironnementPhotoUpdateInput;
  where: MetadataEnvironnementPhotoWhereUniqueInput;
};


export type MutationUpsertMetadataEquipmentInstitutionArgs = {
  create: MetadataEquipmentInstitutionCreateInput;
  update: MetadataEquipmentInstitutionUpdateInput;
  where: MetadataEquipmentInstitutionWhereUniqueInput;
};


export type MutationUpsertMetadataEquipmentManufacturerArgs = {
  create: MetadataEquipmentManufacturerCreateInput;
  update: MetadataEquipmentManufacturerUpdateInput;
  where: MetadataEquipmentManufacturerWhereUniqueInput;
};


export type MutationUpsertMetadataEventArgs = {
  create: MetadataEventCreateInput;
  update: MetadataEventUpdateInput;
  where: MetadataEventWhereUniqueInput;
};


export type MutationUpsertMetadataExifArgs = {
  create: MetadataExifCreateInput;
  update: MetadataExifUpdateInput;
  where: MetadataExifWhereUniqueInput;
};


export type MutationUpsertMetadataFabStorageArgs = {
  create: MetadataFabStorageCreateInput;
  update: MetadataFabStorageUpdateInput;
  where: MetadataFabStorageWhereUniqueInput;
};


export type MutationUpsertMetadataFileCheckForArgs = {
  create: MetadataFileCheckForCreateInput;
  update: MetadataFileCheckForUpdateInput;
  where: MetadataFileCheckForWhereUniqueInput;
};


export type MutationUpsertMetadataFileInfoBitsPerPixelArgs = {
  create: MetadataFileInfoBitsPerPixelCreateInput;
  update: MetadataFileInfoBitsPerPixelUpdateInput;
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
};


export type MutationUpsertMetadataFileInfoFramesArgs = {
  create: MetadataFileInfoFramesCreateInput;
  update: MetadataFileInfoFramesUpdateInput;
  where: MetadataFileInfoFramesWhereUniqueInput;
};


export type MutationUpsertMetadataFileInfoPhotometricInterpretationArgs = {
  create: MetadataFileInfoPhotometricInterpretationCreateInput;
  update: MetadataFileInfoPhotometricInterpretationUpdateInput;
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
};


export type MutationUpsertMetadataFileInfoTransferSyntaxArgs = {
  create: MetadataFileInfoTransferSyntaxCreateInput;
  update: MetadataFileInfoTransferSyntaxUpdateInput;
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
};


export type MutationUpsertMetadataFileStageArgs = {
  create: MetadataFileStageCreateInput;
  update: MetadataFileStageUpdateInput;
  where: MetadataFileStageWhereUniqueInput;
};


export type MutationUpsertMetadataFileStatusArgs = {
  create: MetadataFileStatusCreateInput;
  update: MetadataFileStatusUpdateInput;
  where: MetadataFileStatusWhereUniqueInput;
};


export type MutationUpsertMetadataFileTypeArgs = {
  create: MetadataFileTypeCreateInput;
  update: MetadataFileTypeUpdateInput;
  where: MetadataFileTypeWhereUniqueInput;
};


export type MutationUpsertMetadataFixtureIdentifierArgs = {
  create: MetadataFixtureIdentifierCreateInput;
  update: MetadataFixtureIdentifierUpdateInput;
  where: MetadataFixtureIdentifierWhereUniqueInput;
};


export type MutationUpsertMetadataFormatArgs = {
  create: MetadataFormatCreateInput;
  update: MetadataFormatUpdateInput;
  where: MetadataFormatWhereUniqueInput;
};


export type MutationUpsertMetadataGpsLatitudeArgs = {
  create: MetadataGpsLatitudeCreateInput;
  update: MetadataGpsLatitudeUpdateInput;
  where: MetadataGpsLatitudeWhereUniqueInput;
};


export type MutationUpsertMetadataGpsLongitudeArgs = {
  create: MetadataGpsLongitudeCreateInput;
  update: MetadataGpsLongitudeUpdateInput;
  where: MetadataGpsLongitudeWhereUniqueInput;
};


export type MutationUpsertMetadataGpsProcessingMethodArgs = {
  create: MetadataGpsProcessingMethodCreateInput;
  update: MetadataGpsProcessingMethodUpdateInput;
  where: MetadataGpsProcessingMethodWhereUniqueInput;
};


export type MutationUpsertMetadataHeaderArgs = {
  create: MetadataHeaderCreateInput;
  update: MetadataHeaderUpdateInput;
  where: MetadataHeaderWhereUniqueInput;
};


export type MutationUpsertMetadataHeaderPrismaArgs = {
  create: MetadataHeaderPrismaCreateInput;
  update: MetadataHeaderPrismaUpdateInput;
  where: MetadataHeaderPrismaWhereUniqueInput;
};


export type MutationUpsertMetadataHeadlineArgs = {
  create: MetadataHeadlineCreateInput;
  update: MetadataHeadlineUpdateInput;
  where: MetadataHeadlineWhereUniqueInput;
};


export type MutationUpsertMetadataHistoryArgs = {
  create: MetadataHistoryCreateInput;
  update: MetadataHistoryUpdateInput;
  where: MetadataHistoryWhereUniqueInput;
};


export type MutationUpsertMetadataIccProfileArgs = {
  create: MetadataIccProfileCreateInput;
  update: MetadataIccProfileUpdateInput;
  where: MetadataIccProfileWhereUniqueInput;
};


export type MutationUpsertMetadataIdAssignmentArgs = {
  create: MetadataIdAssignmentCreateInput;
  update: MetadataIdAssignmentUpdateInput;
  where: MetadataIdAssignmentWhereUniqueInput;
};


export type MutationUpsertMetadataIdMediaContentArgs = {
  create: MetadataIdMediaContentCreateInput;
  update: MetadataIdMediaContentUpdateInput;
  where: MetadataIdMediaContentWhereUniqueInput;
};


export type MutationUpsertMetadataIdPhotoPrismaArgs = {
  create: MetadataIdPhotoPrismaCreateInput;
  update: MetadataIdPhotoPrismaUpdateInput;
  where: MetadataIdPhotoPrismaWhereUniqueInput;
};


export type MutationUpsertMetadataIdSubjectArgs = {
  create: MetadataIdSubjectCreateInput;
  update: MetadataIdSubjectUpdateInput;
  where: MetadataIdSubjectWhereUniqueInput;
};


export type MutationUpsertMetadataImageNotesArgs = {
  create: MetadataImageNotesCreateInput;
  update: MetadataImageNotesUpdateInput;
  where: MetadataImageNotesWhereUniqueInput;
};


export type MutationUpsertMetadataImageUniqueIdArgs = {
  create: MetadataImageUniqueIdCreateInput;
  update: MetadataImageUniqueIdUpdateInput;
  where: MetadataImageUniqueIdWhereUniqueInput;
};


export type MutationUpsertMetadataInstructionsArgs = {
  create: MetadataInstructionsCreateInput;
  update: MetadataInstructionsUpdateInput;
  where: MetadataInstructionsWhereUniqueInput;
};


export type MutationUpsertMetadataIntellectualGenreArgs = {
  create: MetadataIntellectualGenreCreateInput;
  update: MetadataIntellectualGenreUpdateInput;
  where: MetadataIntellectualGenreWhereUniqueInput;
};


export type MutationUpsertMetadataIsoSpeedRatingsArgs = {
  create: MetadataIsoSpeedRatingsCreateInput;
  update: MetadataIsoSpeedRatingsUpdateInput;
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
};


export type MutationUpsertMetadataIssueArgs = {
  create: MetadataIssueCreateInput;
  update: MetadataIssueUpdateInput;
  where: MetadataIssueWhereUniqueInput;
};


export type MutationUpsertMetadataJobIdArgs = {
  create: MetadataJobIdCreateInput;
  update: MetadataJobIdUpdateInput;
  where: MetadataJobIdWhereUniqueInput;
};


export type MutationUpsertMetadataJobProcessingArgs = {
  create: MetadataJobProcessingCreateInput;
  update: MetadataJobProcessingUpdateInput;
  where: MetadataJobProcessingWhereUniqueInput;
};


export type MutationUpsertMetadataLayoutPictureEditingArgs = {
  create: MetadataLayoutPictureEditingCreateInput;
  update: MetadataLayoutPictureEditingUpdateInput;
  where: MetadataLayoutPictureEditingWhereUniqueInput;
};


export type MutationUpsertMetadataLayoutStorageArgs = {
  create: MetadataLayoutStorageCreateInput;
  update: MetadataLayoutStorageUpdateInput;
  where: MetadataLayoutStorageWhereUniqueInput;
};


export type MutationUpsertMetadataLicenseArgs = {
  create: MetadataLicenseCreateInput;
  update: MetadataLicenseUpdateInput;
  where: MetadataLicenseWhereUniqueInput;
};


export type MutationUpsertMetadataLinksArgs = {
  create: MetadataLinksCreateInput;
  update: MetadataLinksUpdateInput;
  where: MetadataLinksWhereUniqueInput;
};


export type MutationUpsertMetadataLocalCaptionArgs = {
  create: MetadataLocalCaptionCreateInput;
  update: MetadataLocalCaptionUpdateInput;
  where: MetadataLocalCaptionWhereUniqueInput;
};


export type MutationUpsertMetadataLocationArgs = {
  create: MetadataLocationCreateInput;
  update: MetadataLocationUpdateInput;
  where: MetadataLocationWhereUniqueInput;
};


export type MutationUpsertMetadataMakeArgs = {
  create: MetadataMakeCreateInput;
  update: MetadataMakeUpdateInput;
  where: MetadataMakeWhereUniqueInput;
};


export type MutationUpsertMetadataManifestArgs = {
  create: MetadataManifestCreateInput;
  update: MetadataManifestUpdateInput;
  where: MetadataManifestWhereUniqueInput;
};


export type MutationUpsertMetadataMarkedArgs = {
  create: MetadataMarkedCreateInput;
  update: MetadataMarkedUpdateInput;
  where: MetadataMarkedWhereUniqueInput;
};


export type MutationUpsertMetadataMasterDocumentIdArgs = {
  create: MetadataMasterDocumentIdCreateInput;
  update: MetadataMasterDocumentIdUpdateInput;
  where: MetadataMasterDocumentIdWhereUniqueInput;
};


export type MutationUpsertMetadataMaxAvailHeightArgs = {
  create: MetadataMaxAvailHeightCreateInput;
  update: MetadataMaxAvailHeightUpdateInput;
  where: MetadataMaxAvailHeightWhereUniqueInput;
};


export type MutationUpsertMetadataMaxAvailWidthArgs = {
  create: MetadataMaxAvailWidthCreateInput;
  update: MetadataMaxAvailWidthUpdateInput;
  where: MetadataMaxAvailWidthWhereUniqueInput;
};


export type MutationUpsertMetadataMinorModelAgeDisclosureArgs = {
  create: MetadataMinorModelAgeDisclosureCreateInput;
  update: MetadataMinorModelAgeDisclosureUpdateInput;
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
};


export type MutationUpsertMetadataModelArgs = {
  create: MetadataModelCreateInput;
  update: MetadataModelUpdateInput;
  where: MetadataModelWhereUniqueInput;
};


export type MutationUpsertMetadataModelAgeArgs = {
  create: MetadataModelAgeCreateInput;
  update: MetadataModelAgeUpdateInput;
  where: MetadataModelAgeWhereUniqueInput;
};


export type MutationUpsertMetadataModelReleaseIdArgs = {
  create: MetadataModelReleaseIdCreateInput;
  update: MetadataModelReleaseIdUpdateInput;
  where: MetadataModelReleaseIdWhereUniqueInput;
};


export type MutationUpsertMetadataModelReleaseStatusArgs = {
  create: MetadataModelReleaseStatusCreateInput;
  update: MetadataModelReleaseStatusUpdateInput;
  where: MetadataModelReleaseStatusWhereUniqueInput;
};


export type MutationUpsertMetadataMorePermissionsArgs = {
  create: MetadataMorePermissionsCreateInput;
  update: MetadataMorePermissionsUpdateInput;
  where: MetadataMorePermissionsWhereUniqueInput;
};


export type MutationUpsertMetadataObjectCycleArgs = {
  create: MetadataObjectCycleCreateInput;
  update: MetadataObjectCycleUpdateInput;
  where: MetadataObjectCycleWhereUniqueInput;
};


export type MutationUpsertMetadataOrganisationInImageNameArgs = {
  create: MetadataOrganisationInImageNameCreateInput;
  update: MetadataOrganisationInImageNameUpdateInput;
  where: MetadataOrganisationInImageNameWhereUniqueInput;
};


export type MutationUpsertMetadataOriginalColorArgs = {
  create: MetadataOriginalColorCreateInput;
  update: MetadataOriginalColorUpdateInput;
  where: MetadataOriginalColorWhereUniqueInput;
};


export type MutationUpsertMetadataOriginalIccProfileArgs = {
  create: MetadataOriginalIccProfileCreateInput;
  update: MetadataOriginalIccProfileUpdateInput;
  where: MetadataOriginalIccProfileWhereUniqueInput;
};


export type MutationUpsertMetadataOriginalNameArgs = {
  create: MetadataOriginalNameCreateInput;
  update: MetadataOriginalNameUpdateInput;
  where: MetadataOriginalNameWhereUniqueInput;
};


export type MutationUpsertMetadataOriginalWeigthArgs = {
  create: MetadataOriginalWeigthCreateInput;
  update: MetadataOriginalWeigthUpdateInput;
  where: MetadataOriginalWeigthWhereUniqueInput;
};


export type MutationUpsertMetadataOwnerArgs = {
  create: MetadataOwnerCreateInput;
  update: MetadataOwnerUpdateInput;
  where: MetadataOwnerWhereUniqueInput;
};


export type MutationUpsertMetadataOwnerIdArgs = {
  create: MetadataOwnerIdCreateInput;
  update: MetadataOwnerIdUpdateInput;
  where: MetadataOwnerIdWhereUniqueInput;
};


export type MutationUpsertMetadataPageArgs = {
  create: MetadataPageCreateInput;
  update: MetadataPageUpdateInput;
  where: MetadataPageWhereUniqueInput;
};


export type MutationUpsertMetadataPagePrismaArgs = {
  create: MetadataPagePrismaCreateInput;
  update: MetadataPagePrismaUpdateInput;
  where: MetadataPagePrismaWhereUniqueInput;
};


export type MutationUpsertMetadataPaintBasedCorrectionsArgs = {
  create: MetadataPaintBasedCorrectionsCreateInput;
  update: MetadataPaintBasedCorrectionsUpdateInput;
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
};


export type MutationUpsertMetadataPatientDobArgs = {
  create: MetadataPatientDobCreateInput;
  update: MetadataPatientDobUpdateInput;
  where: MetadataPatientDobWhereUniqueInput;
};


export type MutationUpsertMetadataPatientIdArgs = {
  create: MetadataPatientIdCreateInput;
  update: MetadataPatientIdUpdateInput;
  where: MetadataPatientIdWhereUniqueInput;
};


export type MutationUpsertMetadataPatientNameArgs = {
  create: MetadataPatientNameCreateInput;
  update: MetadataPatientNameUpdateInput;
  where: MetadataPatientNameWhereUniqueInput;
};


export type MutationUpsertMetadataPatientSexArgs = {
  create: MetadataPatientSexCreateInput;
  update: MetadataPatientSexUpdateInput;
  where: MetadataPatientSexWhereUniqueInput;
};


export type MutationUpsertMetadataPersonInImageArgs = {
  create: MetadataPersonInImageCreateInput;
  update: MetadataPersonInImageUpdateInput;
  where: MetadataPersonInImageWhereUniqueInput;
};


export type MutationUpsertMetadataPhotoStorageArgs = {
  create: MetadataPhotoStorageCreateInput;
  update: MetadataPhotoStorageUpdateInput;
  where: MetadataPhotoStorageWhereUniqueInput;
};


export type MutationUpsertMetadataPrepressPictureEditingArgs = {
  create: MetadataPrepressPictureEditingCreateInput;
  update: MetadataPrepressPictureEditingUpdateInput;
  where: MetadataPrepressPictureEditingWhereUniqueInput;
};


export type MutationUpsertMetadataPriceLevelArgs = {
  create: MetadataPriceLevelCreateInput;
  update: MetadataPriceLevelUpdateInput;
  where: MetadataPriceLevelWhereUniqueInput;
};


export type MutationUpsertMetadataPrintingProfileArgs = {
  create: MetadataPrintingProfileCreateInput;
  update: MetadataPrintingProfileUpdateInput;
  where: MetadataPrintingProfileWhereUniqueInput;
};


export type MutationUpsertMetadataPrismaProductionArgs = {
  create: MetadataPrismaProductionCreateInput;
  update: MetadataPrismaProductionUpdateInput;
  where: MetadataPrismaProductionWhereUniqueInput;
};


export type MutationUpsertMetadataProcessHistoryArgs = {
  create: MetadataProcessHistoryCreateInput;
  update: MetadataProcessHistoryUpdateInput;
  where: MetadataProcessHistoryWhereUniqueInput;
};


export type MutationUpsertMetadataProcessParameterArgs = {
  create: MetadataProcessParameterCreateInput;
  update: MetadataProcessParameterUpdateInput;
  where: MetadataProcessParameterWhereUniqueInput;
};


export type MutationUpsertMetadataProcessStatusArgs = {
  create: MetadataProcessStatusCreateInput;
  update: MetadataProcessStatusUpdateInput;
  where: MetadataProcessStatusWhereUniqueInput;
};


export type MutationUpsertMetadataProductArgs = {
  create: MetadataProductCreateInput;
  update: MetadataProductUpdateInput;
  where: MetadataProductWhereUniqueInput;
};


export type MutationUpsertMetadataProductsArgs = {
  create: MetadataProductsCreateInput;
  update: MetadataProductsUpdateInput;
  where: MetadataProductsWhereUniqueInput;
};


export type MutationUpsertMetadataProductShortNameArgs = {
  create: MetadataProductShortNameCreateInput;
  update: MetadataProductShortNameUpdateInput;
  where: MetadataProductShortNameWhereUniqueInput;
};


export type MutationUpsertMetadataProduitsArgs = {
  create: MetadataProduitsCreateInput;
  update: MetadataProduitsUpdateInput;
  where: MetadataProduitsWhereUniqueInput;
};


export type MutationUpsertMetadataProgramVersionArgs = {
  create: MetadataProgramVersionCreateInput;
  update: MetadataProgramVersionUpdateInput;
  where: MetadataProgramVersionWhereUniqueInput;
};


export type MutationUpsertMetadataPropertyReleaseIdArgs = {
  create: MetadataPropertyReleaseIdCreateInput;
  update: MetadataPropertyReleaseIdUpdateInput;
  where: MetadataPropertyReleaseIdWhereUniqueInput;
};


export type MutationUpsertMetadataPropertyReleaseStatusArgs = {
  create: MetadataPropertyReleaseStatusCreateInput;
  update: MetadataPropertyReleaseStatusUpdateInput;
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
};


export type MutationUpsertMetadataPublisherArgs = {
  create: MetadataPublisherCreateInput;
  update: MetadataPublisherUpdateInput;
  where: MetadataPublisherWhereUniqueInput;
};


export type MutationUpsertMetadataPublishingIssueArgs = {
  create: MetadataPublishingIssueCreateInput;
  update: MetadataPublishingIssueUpdateInput;
  where: MetadataPublishingIssueWhereUniqueInput;
};


export type MutationUpsertMetadataPublishingSubjectArgs = {
  create: MetadataPublishingSubjectCreateInput;
  update: MetadataPublishingSubjectUpdateInput;
  where: MetadataPublishingSubjectWhereUniqueInput;
};


export type MutationUpsertMetadataQualifiedUsePrismaByArgs = {
  create: MetadataQualifiedUsePrismaByCreateInput;
  update: MetadataQualifiedUsePrismaByUpdateInput;
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
};


export type MutationUpsertMetadataQualifiedUsePrismaDateArgs = {
  create: MetadataQualifiedUsePrismaDateCreateInput;
  update: MetadataQualifiedUsePrismaDateUpdateInput;
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
};


export type MutationUpsertMetadataQualifiedUsePrismaDurationArgs = {
  create: MetadataQualifiedUsePrismaDurationCreateInput;
  update: MetadataQualifiedUsePrismaDurationUpdateInput;
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
};


export type MutationUpsertMetadataQualifiedUsePrismaSupportArgs = {
  create: MetadataQualifiedUsePrismaSupportCreateInput;
  update: MetadataQualifiedUsePrismaSupportUpdateInput;
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
};


export type MutationUpsertMetadataRatingArgs = {
  create: MetadataRatingCreateInput;
  update: MetadataRatingUpdateInput;
  where: MetadataRatingWhereUniqueInput;
};


export type MutationUpsertMetadataReferenceDateArgs = {
  create: MetadataReferenceDateCreateInput;
  update: MetadataReferenceDateUpdateInput;
  where: MetadataReferenceDateWhereUniqueInput;
};


export type MutationUpsertMetadataReferenceNumberArgs = {
  create: MetadataReferenceNumberCreateInput;
  update: MetadataReferenceNumberUpdateInput;
  where: MetadataReferenceNumberWhereUniqueInput;
};


export type MutationUpsertMetadataReferenceServiceArgs = {
  create: MetadataReferenceServiceCreateInput;
  update: MetadataReferenceServiceUpdateInput;
  where: MetadataReferenceServiceWhereUniqueInput;
};


export type MutationUpsertMetadataReleaseDateArgs = {
  create: MetadataReleaseDateCreateInput;
  update: MetadataReleaseDateUpdateInput;
  where: MetadataReleaseDateWhereUniqueInput;
};


export type MutationUpsertMetadataReleaseTimeArgs = {
  create: MetadataReleaseTimeCreateInput;
  update: MetadataReleaseTimeUpdateInput;
  where: MetadataReleaseTimeWhereUniqueInput;
};


export type MutationUpsertMetadataRequiredPermissionArgs = {
  create: MetadataRequiredPermissionCreateInput;
  update: MetadataRequiredPermissionUpdateInput;
  where: MetadataRequiredPermissionWhereUniqueInput;
};


export type MutationUpsertMetadataResolutionKindArgs = {
  create: MetadataResolutionKindCreateInput;
  update: MetadataResolutionKindUpdateInput;
  where: MetadataResolutionKindWhereUniqueInput;
};


export type MutationUpsertMetadataRightsArgs = {
  create: MetadataRightsCreateInput;
  update: MetadataRightsUpdateInput;
  where: MetadataRightsWhereUniqueInput;
};


export type MutationUpsertMetadataRoyaltyfreeArgs = {
  create: MetadataRoyaltyfreeCreateInput;
  update: MetadataRoyaltyfreeUpdateInput;
  where: MetadataRoyaltyfreeWhereUniqueInput;
};


export type MutationUpsertMetadataSceneArgs = {
  create: MetadataSceneCreateInput;
  update: MetadataSceneUpdateInput;
  where: MetadataSceneWhereUniqueInput;
};


export type MutationUpsertMetadataSectionArgs = {
  create: MetadataSectionCreateInput;
  update: MetadataSectionUpdateInput;
  where: MetadataSectionWhereUniqueInput;
};


export type MutationUpsertMetadataSentToArgs = {
  create: MetadataSentToCreateInput;
  update: MetadataSentToUpdateInput;
  where: MetadataSentToWhereUniqueInput;
};


export type MutationUpsertMetadataSerialNumberArgs = {
  create: MetadataSerialNumberCreateInput;
  update: MetadataSerialNumberUpdateInput;
  where: MetadataSerialNumberWhereUniqueInput;
};


export type MutationUpsertMetadataSeriesDateTimeArgs = {
  create: MetadataSeriesDateTimeCreateInput;
  update: MetadataSeriesDateTimeUpdateInput;
  where: MetadataSeriesDateTimeWhereUniqueInput;
};


export type MutationUpsertMetadataSeriesDescriptionArgs = {
  create: MetadataSeriesDescriptionCreateInput;
  update: MetadataSeriesDescriptionUpdateInput;
  where: MetadataSeriesDescriptionWhereUniqueInput;
};


export type MutationUpsertMetadataSeriesModalityArgs = {
  create: MetadataSeriesModalityCreateInput;
  update: MetadataSeriesModalityUpdateInput;
  where: MetadataSeriesModalityWhereUniqueInput;
};


export type MutationUpsertMetadataSeriesNumberArgs = {
  create: MetadataSeriesNumberCreateInput;
  update: MetadataSeriesNumberUpdateInput;
  where: MetadataSeriesNumberWhereUniqueInput;
};


export type MutationUpsertMetadataShortUniqueIdArgs = {
  create: MetadataShortUniqueIdCreateInput;
  update: MetadataShortUniqueIdUpdateInput;
  where: MetadataShortUniqueIdWhereUniqueInput;
};


export type MutationUpsertMetadataSourceArgs = {
  create: MetadataSourceCreateInput;
  update: MetadataSourceUpdateInput;
  where: MetadataSourceWhereUniqueInput;
};


export type MutationUpsertMetadataStateArgs = {
  create: MetadataStateCreateInput;
  update: MetadataStateUpdateInput;
  where: MetadataStateWhereUniqueInput;
};


export type MutationUpsertMetadataStudyDateTimeArgs = {
  create: MetadataStudyDateTimeCreateInput;
  update: MetadataStudyDateTimeUpdateInput;
  where: MetadataStudyDateTimeWhereUniqueInput;
};


export type MutationUpsertMetadataStudyDescriptionArgs = {
  create: MetadataStudyDescriptionCreateInput;
  update: MetadataStudyDescriptionUpdateInput;
  where: MetadataStudyDescriptionWhereUniqueInput;
};


export type MutationUpsertMetadataStudyIdArgs = {
  create: MetadataStudyIdCreateInput;
  update: MetadataStudyIdUpdateInput;
  where: MetadataStudyIdWhereUniqueInput;
};


export type MutationUpsertMetadataStudyPhysicianArgs = {
  create: MetadataStudyPhysicianCreateInput;
  update: MetadataStudyPhysicianUpdateInput;
  where: MetadataStudyPhysicianWhereUniqueInput;
};


export type MutationUpsertMetadataSubjectArgs = {
  create: MetadataSubjectCreateInput;
  update: MetadataSubjectUpdateInput;
  where: MetadataSubjectWhereUniqueInput;
};


export type MutationUpsertMetadataSubjectCodeArgs = {
  create: MetadataSubjectCodeCreateInput;
  update: MetadataSubjectCodeUpdateInput;
  where: MetadataSubjectCodeWhereUniqueInput;
};


export type MutationUpsertMetadataSubjectPrismaArgs = {
  create: MetadataSubjectPrismaCreateInput;
  update: MetadataSubjectPrismaUpdateInput;
  where: MetadataSubjectPrismaWhereUniqueInput;
};


export type MutationUpsertMetadataSupplementalCategoriesArgs = {
  create: MetadataSupplementalCategoriesCreateInput;
  update: MetadataSupplementalCategoriesUpdateInput;
  where: MetadataSupplementalCategoriesWhereUniqueInput;
};


export type MutationUpsertMetadataTargetVersionArgs = {
  create: MetadataTargetVersionCreateInput;
  update: MetadataTargetVersionUpdateInput;
  where: MetadataTargetVersionWhereUniqueInput;
};


export type MutationUpsertMetadataTitleArgs = {
  create: MetadataTitleCreateInput;
  update: MetadataTitleUpdateInput;
  where: MetadataTitleWhereUniqueInput;
};


export type MutationUpsertMetadataToneCurveArgs = {
  create: MetadataToneCurveCreateInput;
  update: MetadataToneCurveUpdateInput;
  where: MetadataToneCurveWhereUniqueInput;
};


export type MutationUpsertMetadataTransferredByArgs = {
  create: MetadataTransferredByCreateInput;
  update: MetadataTransferredByUpdateInput;
  where: MetadataTransferredByWhereUniqueInput;
};


export type MutationUpsertMetadataTransferredByEmailArgs = {
  create: MetadataTransferredByEmailCreateInput;
  update: MetadataTransferredByEmailUpdateInput;
  where: MetadataTransferredByEmailWhereUniqueInput;
};


export type MutationUpsertMetadataTransferredByFullNameArgs = {
  create: MetadataTransferredByFullNameCreateInput;
  update: MetadataTransferredByFullNameUpdateInput;
  where: MetadataTransferredByFullNameWhereUniqueInput;
};


export type MutationUpsertMetadataTransmissionReferenceArgs = {
  create: MetadataTransmissionReferenceCreateInput;
  update: MetadataTransmissionReferenceUpdateInput;
  where: MetadataTransmissionReferenceWhereUniqueInput;
};


export type MutationUpsertMetadataTypeArgs = {
  create: MetadataTypeCreateInput;
  update: MetadataTypeUpdateInput;
  where: MetadataTypeWhereUniqueInput;
};


export type MutationUpsertMetadataUndersubjectArgs = {
  create: MetadataUndersubjectCreateInput;
  update: MetadataUndersubjectUpdateInput;
  where: MetadataUndersubjectWhereUniqueInput;
};


export type MutationUpsertMetadataUnderUnderSubjectArgs = {
  create: MetadataUnderUnderSubjectCreateInput;
  update: MetadataUnderUnderSubjectUpdateInput;
  where: MetadataUnderUnderSubjectWhereUniqueInput;
};


export type MutationUpsertMetadataUniqueIdArgs = {
  create: MetadataUniqueIdCreateInput;
  update: MetadataUniqueIdUpdateInput;
  where: MetadataUniqueIdWhereUniqueInput;
};


export type MutationUpsertMetadataUnitArgs = {
  create: MetadataUnitCreateInput;
  update: MetadataUnitUpdateInput;
  where: MetadataUnitWhereUniqueInput;
};


export type MutationUpsertMetadataUnitShortNameArgs = {
  create: MetadataUnitShortNameCreateInput;
  update: MetadataUnitShortNameUpdateInput;
  where: MetadataUnitShortNameWhereUniqueInput;
};


export type MutationUpsertMetadataUploadedByArgs = {
  create: MetadataUploadedByCreateInput;
  update: MetadataUploadedByUpdateInput;
  where: MetadataUploadedByWhereUniqueInput;
};


export type MutationUpsertMetadataUploadedByFullNameArgs = {
  create: MetadataUploadedByFullNameCreateInput;
  update: MetadataUploadedByFullNameUpdateInput;
  where: MetadataUploadedByFullNameWhereUniqueInput;
};


export type MutationUpsertMetadataUploadTimeArgs = {
  create: MetadataUploadTimeCreateInput;
  update: MetadataUploadTimeUpdateInput;
  where: MetadataUploadTimeWhereUniqueInput;
};


export type MutationUpsertMetadataUrgencyArgs = {
  create: MetadataUrgencyCreateInput;
  update: MetadataUrgencyUpdateInput;
  where: MetadataUrgencyWhereUniqueInput;
};


export type MutationUpsertMetadataUsageTermsArgs = {
  create: MetadataUsageTermsCreateInput;
  update: MetadataUsageTermsUpdateInput;
  where: MetadataUsageTermsWhereUniqueInput;
};


export type MutationUpsertMetadataUserCommentArgs = {
  create: MetadataUserCommentCreateInput;
  update: MetadataUserCommentUpdateInput;
  where: MetadataUserCommentWhereUniqueInput;
};


export type MutationUpsertMetadataUserDefined195Args = {
  create: MetadataUserDefined195CreateInput;
  update: MetadataUserDefined195UpdateInput;
  where: MetadataUserDefined195WhereUniqueInput;
};


export type MutationUpsertMetadataUserDefined237Args = {
  create: MetadataUserDefined237CreateInput;
  update: MetadataUserDefined237UpdateInput;
  where: MetadataUserDefined237WhereUniqueInput;
};


export type MutationUpsertMetadataUserDefined238Args = {
  create: MetadataUserDefined238CreateInput;
  update: MetadataUserDefined238UpdateInput;
  where: MetadataUserDefined238WhereUniqueInput;
};


export type MutationUpsertMetadataUserDefined239Args = {
  create: MetadataUserDefined239CreateInput;
  update: MetadataUserDefined239UpdateInput;
  where: MetadataUserDefined239WhereUniqueInput;
};


export type MutationUpsertMetadataUserDefined242Args = {
  create: MetadataUserDefined242CreateInput;
  update: MetadataUserDefined242UpdateInput;
  where: MetadataUserDefined242WhereUniqueInput;
};


export type MutationUpsertMetadataUserDefined62Args = {
  create: MetadataUserDefined62CreateInput;
  update: MetadataUserDefined62UpdateInput;
  where: MetadataUserDefined62WhereUniqueInput;
};


export type MutationUpsertMetadataValidArgs = {
  create: MetadataValidCreateInput;
  update: MetadataValidUpdateInput;
  where: MetadataValidWhereUniqueInput;
};


export type MutationUpsertMetadataVersionArgs = {
  create: MetadataVersionCreateInput;
  update: MetadataVersionUpdateInput;
  where: MetadataVersionWhereUniqueInput;
};


export type MutationUpsertMetadataWebStatementArgs = {
  create: MetadataWebStatementCreateInput;
  update: MetadataWebStatementUpdateInput;
  where: MetadataWebStatementWhereUniqueInput;
};


export type MutationUpsertMetadataWorkflowKindArgs = {
  create: MetadataWorkflowKindCreateInput;
  update: MetadataWorkflowKindUpdateInput;
  where: MetadataWorkflowKindWhereUniqueInput;
};


export type MutationUpsertMetadataXmpFileStampsArgs = {
  create: MetadataXmpFileStampsCreateInput;
  update: MetadataXmpFileStampsUpdateInput;
  where: MetadataXmpFileStampsWhereUniqueInput;
};


export type MutationUpsertMetadataXmpHistoryArgs = {
  create: MetadataXmpHistoryCreateInput;
  update: MetadataXmpHistoryUpdateInput;
  where: MetadataXmpHistoryWhereUniqueInput;
};


export type MutationUpsertPhotoArgs = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};


export type MutationUpsertPhotoMetadataArgs = {
  create: PhotoMetadataCreateInput;
  update: PhotoMetadataUpdateInput;
  where: PhotoMetadataWhereUniqueInput;
};


export type MutationUpsertPhotoPublicationArgs = {
  create: PhotoPublicationCreateInput;
  update: PhotoPublicationUpdateInput;
  where: PhotoPublicationWhereUniqueInput;
};

/** "Photo" resource's node */
export type Photo = {
  __typename?: 'Photo';
  /** The internal ID */
  _id: Scalars['Int']['output'];
  agency?: Maybe<Agency>;
  archive: Archive;
  category?: Maybe<Category>;
  /** asset content type */
  contentType?: Maybe<Scalars['String']['output']>;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  deliverySubject?: Maybe<DeliverySubject>;
  /** get private download URL */
  downloadUrl?: Maybe<Scalars['String']['output']>;
  filesize?: Maybe<Scalars['Int']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  /** The public ID, an UUID v4 */
  id: Scalars['ID']['output'];
  /** The date of the photo's importation */
  importedAt?: Maybe<Scalars['DateTime']['output']>;
  /** "ImportQueue.photo"'s inverse relation */
  importQueue?: Maybe<ImportQueue>;
  isLegacy?: Maybe<Scalars['Boolean']['output']>;
  isUploaded?: Maybe<Scalars['Boolean']['output']>;
  /** "MetadataAccessRights.photo"'s inverse relation count */
  Metadata_AccessRightCount: Scalars['Int']['output'];
  /** "MetadataAccessRights.photo"'s inverse relation */
  Metadata_AccessRights: Array<MetadataAccessRights>;
  /** "MetadataAddlModelInfo.photo"'s inverse relation */
  Metadata_AddlModelInfo: Array<MetadataAddlModelInfo>;
  /** "MetadataAddlModelInfo.photo"'s inverse relation count */
  Metadata_AddlModelInfoCount: Scalars['Int']['output'];
  /** "MetadataArchiveChild.photo"'s inverse relation */
  Metadata_Archive_child: Array<MetadataArchiveChild>;
  /** "MetadataArchiveChild.photo"'s inverse relation count */
  Metadata_Archive_childCount: Scalars['Int']['output'];
  /** "MetadataArchiveParent.photo"'s inverse relation */
  Metadata_Archive_parent: Array<MetadataArchiveParent>;
  /** "MetadataArchiveParent.photo"'s inverse relation count */
  Metadata_Archive_parentCount: Scalars['Int']['output'];
  /** "MetadataAssetState.photo"'s inverse relation */
  Metadata_AssetState: Array<MetadataAssetState>;
  /** "MetadataAssetState.photo"'s inverse relation count */
  Metadata_AssetStateCount: Scalars['Int']['output'];
  /** "MetadataAttributionName.photo"'s inverse relation */
  Metadata_attributionName: Array<MetadataAttributionName>;
  /** "MetadataAttributionName.photo"'s inverse relation count */
  Metadata_attributionNameCount: Scalars['Int']['output'];
  /** "MetadataAttributionUrl.photo"'s inverse relation */
  Metadata_attributionURL: Array<MetadataAttributionUrl>;
  /** "MetadataAttributionUrl.photo"'s inverse relation count */
  Metadata_attributionURLCount: Scalars['Int']['output'];
  /** "MetadataAudience.photo"'s inverse relation */
  Metadata_Audience: Array<MetadataAudience>;
  /** "MetadataAudience.photo"'s inverse relation count */
  Metadata_AudienceCount: Scalars['Int']['output'];
  /** "MetadataAuthorsPosition.photo"'s inverse relation */
  Metadata_AuthorsPosition: Array<MetadataAuthorsPosition>;
  /** "MetadataAuthorsPosition.photo"'s inverse relation count */
  Metadata_AuthorsPositionCount: Scalars['Int']['output'];
  /** "MetadataBackupName.photo"'s inverse relation */
  Metadata_BackupName: Array<MetadataBackupName>;
  /** "MetadataBackupName.photo"'s inverse relation count */
  Metadata_BackupNameCount: Scalars['Int']['output'];
  /** "MetadataBitmapGrayscalePicture.photo"'s inverse relation */
  Metadata_BitmapGrayscalePicture: Array<MetadataBitmapGrayscalePicture>;
  /** "MetadataBitmapGrayscalePicture.photo"'s inverse relation count */
  Metadata_BitmapGrayscalePictureCount: Scalars['Int']['output'];
  /** "MetadataBrand.photo"'s inverse relation */
  Metadata_Brand: Array<MetadataBrand>;
  /** "MetadataBrand.photo"'s inverse relation count */
  Metadata_BrandCount: Scalars['Int']['output'];
  /** "MetadataBrandPrisma.photo"'s inverse relation */
  Metadata_BrandPrisma: Array<MetadataBrandPrisma>;
  /** "MetadataBrandPrisma.photo"'s inverse relation count */
  Metadata_BrandPrismaCount: Scalars['Int']['output'];
  /** "MetadataBrandShortName.photo"'s inverse relation */
  Metadata_BrandShortName: Array<MetadataBrandShortName>;
  /** "MetadataBrandShortName.photo"'s inverse relation count */
  Metadata_BrandShortNameCount: Scalars['Int']['output'];
  /** "MetadataCaptionWriter.photo"'s inverse relation */
  Metadata_CaptionWriter: Array<MetadataCaptionWriter>;
  /** "MetadataCaptionWriter.photo"'s inverse relation count */
  Metadata_CaptionWriterCount: Scalars['Int']['output'];
  /** "MetadataCategory.photo"'s inverse relation */
  Metadata_Category: Array<MetadataCategory>;
  /** "MetadataCategory.photo"'s inverse relation count */
  Metadata_CategoryCount: Scalars['Int']['output'];
  /** "MetadataCategoryPrisma.photo"'s inverse relation */
  Metadata_CategoryPrisma: Array<MetadataCategoryPrisma>;
  /** "MetadataCategoryPrisma.photo"'s inverse relation count */
  Metadata_CategoryPrismaCount: Scalars['Int']['output'];
  /** "MetadataCertificate.photo"'s inverse relation */
  Metadata_Certificate: Array<MetadataCertificate>;
  /** "MetadataCertificate.photo"'s inverse relation count */
  Metadata_CertificateCount: Scalars['Int']['output'];
  /** "MetadataCiAdrCity.photo"'s inverse relation */
  Metadata_CiAdrCity: Array<MetadataCiAdrCity>;
  /** "MetadataCiAdrCity.photo"'s inverse relation count */
  Metadata_CiAdrCityCount: Scalars['Int']['output'];
  /** "MetadataCiAdrCtry.photo"'s inverse relation */
  Metadata_CiAdrCtry: Array<MetadataCiAdrCtry>;
  /** "MetadataCiAdrCtry.photo"'s inverse relation count */
  Metadata_CiAdrCtryCount: Scalars['Int']['output'];
  /** "MetadataCiAdrExtadr.photo"'s inverse relation */
  Metadata_CiAdrExtadr: Array<MetadataCiAdrExtadr>;
  /** "MetadataCiAdrExtadr.photo"'s inverse relation count */
  Metadata_CiAdrExtadrCount: Scalars['Int']['output'];
  /** "MetadataCiAdrPcode.photo"'s inverse relation */
  Metadata_CiAdrPcode: Array<MetadataCiAdrPcode>;
  /** "MetadataCiAdrPcode.photo"'s inverse relation count */
  Metadata_CiAdrPcodeCount: Scalars['Int']['output'];
  /** "MetadataCiAdrRegion.photo"'s inverse relation */
  Metadata_CiAdrRegion: Array<MetadataCiAdrRegion>;
  /** "MetadataCiAdrRegion.photo"'s inverse relation count */
  Metadata_CiAdrRegionCount: Scalars['Int']['output'];
  /** "MetadataCiEmailWork.photo"'s inverse relation */
  Metadata_CiEmailWork: Array<MetadataCiEmailWork>;
  /** "MetadataCiEmailWork.photo"'s inverse relation count */
  Metadata_CiEmailWorkCount: Scalars['Int']['output'];
  /** "MetadataCiTelWork.photo"'s inverse relation */
  Metadata_CiTelWork: Array<MetadataCiTelWork>;
  /** "MetadataCiTelWork.photo"'s inverse relation count */
  Metadata_CiTelWorkCount: Scalars['Int']['output'];
  /** "MetadataCity.photo"'s inverse relation */
  Metadata_City: Array<MetadataCity>;
  /** "MetadataCity.photo"'s inverse relation count */
  Metadata_CityCount: Scalars['Int']['output'];
  /** "MetadataCiUrlWork.photo"'s inverse relation */
  Metadata_CiUrlWork: Array<MetadataCiUrlWork>;
  /** "MetadataCiUrlWork.photo"'s inverse relation count */
  Metadata_CiUrlWorkCount: Scalars['Int']['output'];
  /** "MetadataClassify.photo"'s inverse relation */
  Metadata_Classify: Array<MetadataClassify>;
  /** "MetadataClassify.photo"'s inverse relation count */
  Metadata_ClassifyCount: Scalars['Int']['output'];
  /** "MetadataCompany.photo"'s inverse relation */
  Metadata_Company: Array<MetadataCompany>;
  /** "MetadataCompany.photo"'s inverse relation count */
  Metadata_CompanyCount: Scalars['Int']['output'];
  /** "MetadataCompanyShortName.photo"'s inverse relation */
  Metadata_CompanyShortName: Array<MetadataCompanyShortName>;
  /** "MetadataCompanyShortName.photo"'s inverse relation count */
  Metadata_CompanyShortNameCount: Scalars['Int']['output'];
  /** "MetadataConfidentiality.photo"'s inverse relation */
  Metadata_Confidentiality: Array<MetadataConfidentiality>;
  /** "MetadataConfidentiality.photo"'s inverse relation count */
  Metadata_ConfidentialityCount: Scalars['Int']['output'];
  /** "MetadataContainedIn.photo"'s inverse relation */
  Metadata_ContainedIn: Array<MetadataContainedIn>;
  /** "MetadataContainedIn.photo"'s inverse relation count */
  Metadata_ContainedInCount: Scalars['Int']['output'];
  /** "MetadataContentMediaKind.photo"'s inverse relation */
  Metadata_ContentMediaKind: Array<MetadataContentMediaKind>;
  /** "MetadataContentMediaKind.photo"'s inverse relation count */
  Metadata_ContentMediaKindCount: Scalars['Int']['output'];
  /** "MetadataContentValue.photo"'s inverse relation */
  Metadata_ContentValue: Array<MetadataContentValue>;
  /** "MetadataContentValue.photo"'s inverse relation count */
  Metadata_ContentValueCount: Scalars['Int']['output'];
  /** "MetadataCopyrightLayer.photo"'s inverse relation */
  Metadata_CopyrightLayer: Array<MetadataCopyrightLayer>;
  /** "MetadataCopyrightLayer.photo"'s inverse relation count */
  Metadata_CopyrightLayerCount: Scalars['Int']['output'];
  /** "MetadataCountry.photo"'s inverse relation */
  Metadata_Country: Array<MetadataCountry>;
  /** "MetadataCountryCode.photo"'s inverse relation */
  Metadata_CountryCode: Array<MetadataCountryCode>;
  /** "MetadataCountryCode.photo"'s inverse relation count */
  Metadata_CountryCodeCount: Scalars['Int']['output'];
  /** "MetadataCountry.photo"'s inverse relation count */
  Metadata_CountryCount: Scalars['Int']['output'];
  /** "MetadataCreatedTime.photo"'s inverse relation */
  Metadata_CreatedTime: Array<MetadataCreatedTime>;
  /** "MetadataCreatedTime.photo"'s inverse relation count */
  Metadata_CreatedTimeCount: Scalars['Int']['output'];
  /** "MetadataCreator.photo"'s inverse relation */
  Metadata_creator: Array<MetadataCreator>;
  /** "MetadataCreator.photo"'s inverse relation count */
  Metadata_creatorCount: Scalars['Int']['output'];
  /** "MetadataCreatorTool.photo"'s inverse relation */
  Metadata_CreatorTool: Array<MetadataCreatorTool>;
  /** "MetadataCreatorTool.photo"'s inverse relation count */
  Metadata_CreatorToolCount: Scalars['Int']['output'];
  /** "MetadataCredit.photo"'s inverse relation */
  Metadata_Credit: Array<MetadataCredit>;
  /** "MetadataCredit.photo"'s inverse relation count */
  Metadata_CreditCount: Scalars['Int']['output'];
  /** "MetadataCustomField14.photo"'s inverse relation */
  Metadata_CustomField14: Array<MetadataCustomField14>;
  /** "MetadataCustomField14.photo"'s inverse relation count */
  Metadata_CustomField14Count: Scalars['Int']['output'];
  /** "MetadataCustomField15.photo"'s inverse relation */
  Metadata_CustomField15: Array<MetadataCustomField15>;
  /** "MetadataCustomField15.photo"'s inverse relation count */
  Metadata_CustomField15Count: Scalars['Int']['output'];
  /** "MetadataCustomField16.photo"'s inverse relation */
  Metadata_CustomField16: Array<MetadataCustomField16>;
  /** "MetadataCustomField16.photo"'s inverse relation count */
  Metadata_CustomField16Count: Scalars['Int']['output'];
  /** "MetadataCustomField17.photo"'s inverse relation */
  Metadata_CustomField17: Array<MetadataCustomField17>;
  /** "MetadataCustomField17.photo"'s inverse relation count */
  Metadata_CustomField17Count: Scalars['Int']['output'];
  /** "MetadataCustomField20.photo"'s inverse relation */
  Metadata_CustomField20: Array<MetadataCustomField20>;
  /** "MetadataCustomField20.photo"'s inverse relation count */
  Metadata_CustomField20Count: Scalars['Int']['output'];
  /** "MetadataCustomField5.photo"'s inverse relation */
  Metadata_CustomField5: Array<MetadataCustomField5>;
  /** "MetadataCustomField5.photo"'s inverse relation count */
  Metadata_CustomField5Count: Scalars['Int']['output'];
  /** "MetadataCustomField6.photo"'s inverse relation */
  Metadata_CustomField6: Array<MetadataCustomField6>;
  /** "MetadataCustomField6.photo"'s inverse relation count */
  Metadata_CustomField6Count: Scalars['Int']['output'];
  /** "MetadataCustomField7.photo"'s inverse relation */
  Metadata_CustomField7: Array<MetadataCustomField7>;
  /** "MetadataCustomField7.photo"'s inverse relation count */
  Metadata_CustomField7Count: Scalars['Int']['output'];
  /** "MetadataCustomField9.photo"'s inverse relation */
  Metadata_CustomField9: Array<MetadataCustomField9>;
  /** "MetadataCustomField9.photo"'s inverse relation count */
  Metadata_CustomField9Count: Scalars['Int']['output'];
  /** "MetadataDateCreated.photo"'s inverse relation */
  Metadata_DateCreated: Array<MetadataDateCreated>;
  /** "MetadataDateCreated.photo"'s inverse relation count */
  Metadata_DateCreatedCount: Scalars['Int']['output'];
  /** "MetadataDateTimeDigitized.photo"'s inverse relation */
  Metadata_DateTimeDigitized: Array<MetadataDateTimeDigitized>;
  /** "MetadataDateTimeDigitized.photo"'s inverse relation count */
  Metadata_DateTimeDigitizedCount: Scalars['Int']['output'];
  /** "MetadataDateTimeOriginal.photo"'s inverse relation */
  Metadata_DateTimeOriginal: Array<MetadataDateTimeOriginal>;
  /** "MetadataDateTimeOriginal.photo"'s inverse relation count */
  Metadata_DateTimeOriginalCount: Scalars['Int']['output'];
  /** "MetadataDeliveryAccount.photo"'s inverse relation */
  Metadata_DeliveryAccount: Array<MetadataDeliveryAccount>;
  /** "MetadataDeliveryAccount.photo"'s inverse relation count */
  Metadata_DeliveryAccountCount: Scalars['Int']['output'];
  /** "MetadataDeliveryCompany.photo"'s inverse relation */
  Metadata_DeliveryCompany: Array<MetadataDeliveryCompany>;
  /** "MetadataDeliveryCompany.photo"'s inverse relation count */
  Metadata_DeliveryCompanyCount: Scalars['Int']['output'];
  /** "MetadataDeliveryCopyright.photo"'s inverse relation */
  Metadata_DeliveryCopyright: Array<MetadataDeliveryCopyright>;
  /** "MetadataDeliveryCopyright.photo"'s inverse relation count */
  Metadata_DeliveryCopyrightCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDateFolder.photo"'s inverse relation */
  Metadata_DeliveryDateFolder: Array<MetadataDeliveryDateFolder>;
  /** "MetadataDeliveryDateFolder.photo"'s inverse relation count */
  Metadata_DeliveryDateFolderCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDateTime.photo"'s inverse relation */
  Metadata_DeliveryDateTime: Array<MetadataDeliveryDateTime>;
  /** "MetadataDeliveryDateTime.photo"'s inverse relation count */
  Metadata_DeliveryDateTimeCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDcCreator.photo"'s inverse relation */
  Metadata_DeliveryDcCreator: Array<MetadataDeliveryDcCreator>;
  /** "MetadataDeliveryDcCreator.photo"'s inverse relation count */
  Metadata_DeliveryDcCreatorCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDcRights.photo"'s inverse relation count */
  Metadata_DeliveryDcRightCount: Scalars['Int']['output'];
  /** "MetadataDeliveryDcRights.photo"'s inverse relation */
  Metadata_DeliveryDcRights: Array<MetadataDeliveryDcRights>;
  /** "MetadataDeliveryFileType.photo"'s inverse relation */
  Metadata_DeliveryFileType: Array<MetadataDeliveryFileType>;
  /** "MetadataDeliveryFileType.photo"'s inverse relation count */
  Metadata_DeliveryFileTypeCount: Scalars['Int']['output'];
  /** "MetadataDeliveryFolder.photo"'s inverse relation */
  Metadata_DeliveryFolder: Array<MetadataDeliveryFolder>;
  /** "MetadataDeliveryFolder.photo"'s inverse relation count */
  Metadata_DeliveryFolderCount: Scalars['Int']['output'];
  /** "MetadataDeliveryKind.photo"'s inverse relation */
  Metadata_DeliveryKind: Array<MetadataDeliveryKind>;
  /** "MetadataDeliveryKind.photo"'s inverse relation count */
  Metadata_DeliveryKindCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPath.photo"'s inverse relation */
  Metadata_DeliveryPath: Array<MetadataDeliveryPath>;
  /** "MetadataDeliveryPath.photo"'s inverse relation count */
  Metadata_DeliveryPathCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation */
  Metadata_DeliveryPersonShownintheImage: Array<MetadataDeliveryPersonShownintheImage>;
  /** "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation count */
  Metadata_DeliveryPersonShownintheImageCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopCredit: Array<MetadataDeliveryPhotoshopCredit>;
  /** "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation count */
  Metadata_DeliveryPhotoshopCreditCount: Scalars['Int']['output'];
  /** "MetadataDeliveryPhotoshopSource.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopSource: Array<MetadataDeliveryPhotoshopSource>;
  /** "MetadataDeliveryPhotoshopSource.photo"'s inverse relation count */
  Metadata_DeliveryPhotoshopSourceCount: Scalars['Int']['output'];
  /** "MetadataDeliveryService.photo"'s inverse relation */
  Metadata_DeliveryService: Array<MetadataDeliveryService>;
  /** "MetadataDeliveryService.photo"'s inverse relation count */
  Metadata_DeliveryServiceCount: Scalars['Int']['output'];
  /** "MetadataDeliverySubject.photo"'s inverse relation */
  Metadata_DeliverySubject: Array<MetadataDeliverySubject>;
  /** "MetadataDeliverySubject.photo"'s inverse relation count */
  Metadata_DeliverySubjectCount: Scalars['Int']['output'];
  /** "MetadataDeliveryUnderSubject.photo"'s inverse relation */
  Metadata_DeliveryUnderSubject: Array<MetadataDeliveryUnderSubject>;
  /** "MetadataDeliveryUnderSubject.photo"'s inverse relation count */
  Metadata_DeliveryUnderSubjectCount: Scalars['Int']['output'];
  /** "MetadataDepartment.photo"'s inverse relation */
  Metadata_Department: Array<MetadataDepartment>;
  /** "MetadataDepartment.photo"'s inverse relation count */
  Metadata_DepartmentCount: Scalars['Int']['output'];
  /** "MetadataDescription.photo"'s inverse relation */
  Metadata_description: Array<MetadataDescription>;
  /** "MetadataDescription.photo"'s inverse relation count */
  Metadata_descriptionCount: Scalars['Int']['output'];
  /** "MetadataDigitalAssetUrl.photo"'s inverse relation */
  Metadata_DigitalAssetURL: Array<MetadataDigitalAssetUrl>;
  /** "MetadataDigitalAssetUrl.photo"'s inverse relation count */
  Metadata_DigitalAssetURLCount: Scalars['Int']['output'];
  /** "MetadataDivision.photo"'s inverse relation */
  Metadata_Division: Array<MetadataDivision>;
  /** "MetadataDivision.photo"'s inverse relation count */
  Metadata_DivisionCount: Scalars['Int']['output'];
  /** "MetadataDocumentText.photo"'s inverse relation */
  Metadata_DocumentText: Array<MetadataDocumentText>;
  /** "MetadataDocumentText.photo"'s inverse relation count */
  Metadata_DocumentTextCount: Scalars['Int']['output'];
  /** "MetadataEdition.photo"'s inverse relation */
  Metadata_Edition: Array<MetadataEdition>;
  /** "MetadataEdition.photo"'s inverse relation count */
  Metadata_EditionCount: Scalars['Int']['output'];
  /** "MetadataEditorialVersion.photo"'s inverse relation */
  Metadata_EditorialVersion: Array<MetadataEditorialVersion>;
  /** "MetadataEditorialVersion.photo"'s inverse relation count */
  Metadata_EditorialVersionCount: Scalars['Int']['output'];
  /** "MetadataEditStatus.photo"'s inverse relation */
  Metadata_EditStatus: Array<MetadataEditStatus>;
  /** "MetadataEditStatus.photo"'s inverse relation count */
  Metadata_EditStatusCount: Scalars['Int']['output'];
  /** "MetadataEnvironnementPhoto.photo"'s inverse relation */
  Metadata_EnvironnementPhoto: Array<MetadataEnvironnementPhoto>;
  /** "MetadataEnvironnementPhoto.photo"'s inverse relation count */
  Metadata_EnvironnementPhotoCount: Scalars['Int']['output'];
  /** "MetadataEquipmentInstitution.photo"'s inverse relation */
  Metadata_EquipmentInstitution: Array<MetadataEquipmentInstitution>;
  /** "MetadataEquipmentInstitution.photo"'s inverse relation count */
  Metadata_EquipmentInstitutionCount: Scalars['Int']['output'];
  /** "MetadataEquipmentManufacturer.photo"'s inverse relation */
  Metadata_EquipmentManufacturer: Array<MetadataEquipmentManufacturer>;
  /** "MetadataEquipmentManufacturer.photo"'s inverse relation count */
  Metadata_EquipmentManufacturerCount: Scalars['Int']['output'];
  /** "MetadataEvent.photo"'s inverse relation */
  Metadata_Event: Array<MetadataEvent>;
  /** "MetadataEvent.photo"'s inverse relation count */
  Metadata_EventCount: Scalars['Int']['output'];
  /** "MetadataExif.photo"'s inverse relation */
  Metadata_Exif: Array<MetadataExif>;
  /** "MetadataExif.photo"'s inverse relation count */
  Metadata_ExifCount: Scalars['Int']['output'];
  /** "MetadataFabStorage.photo"'s inverse relation */
  Metadata_FabStorage: Array<MetadataFabStorage>;
  /** "MetadataFabStorage.photo"'s inverse relation count */
  Metadata_FabStorageCount: Scalars['Int']['output'];
  /** "MetadataFileCheckFor.photo"'s inverse relation */
  Metadata_FileCheckFor: Array<MetadataFileCheckFor>;
  /** "MetadataFileCheckFor.photo"'s inverse relation count */
  Metadata_FileCheckForCount: Scalars['Int']['output'];
  /** "MetadataFileInfoBitsPerPixel.photo"'s inverse relation */
  Metadata_FileInfoBitsPerPixel: Array<MetadataFileInfoBitsPerPixel>;
  /** "MetadataFileInfoBitsPerPixel.photo"'s inverse relation count */
  Metadata_FileInfoBitsPerPixelCount: Scalars['Int']['output'];
  /** "MetadataFileInfoFrames.photo"'s inverse relation count */
  Metadata_FileInfoFrameCount: Scalars['Int']['output'];
  /** "MetadataFileInfoFrames.photo"'s inverse relation */
  Metadata_FileInfoFrames: Array<MetadataFileInfoFrames>;
  /** "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation */
  Metadata_FileInfoPhotometricInterpretation: Array<MetadataFileInfoPhotometricInterpretation>;
  /** "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation count */
  Metadata_FileInfoPhotometricInterpretationCount: Scalars['Int']['output'];
  /** "MetadataFileInfoTransferSyntax.photo"'s inverse relation */
  Metadata_FileInfoTransferSyntax: Array<MetadataFileInfoTransferSyntax>;
  /** "MetadataFileInfoTransferSyntax.photo"'s inverse relation count */
  Metadata_FileInfoTransferSyntaxCount: Scalars['Int']['output'];
  /** "MetadataFileStage.photo"'s inverse relation */
  Metadata_FileStage: Array<MetadataFileStage>;
  /** "MetadataFileStage.photo"'s inverse relation count */
  Metadata_FileStageCount: Scalars['Int']['output'];
  /** "MetadataFileStatus.photo"'s inverse relation */
  Metadata_FileStatus: Array<MetadataFileStatus>;
  /** "MetadataFileStatus.photo"'s inverse relation count */
  Metadata_FileStatusCount: Scalars['Int']['output'];
  /** "MetadataFileType.photo"'s inverse relation */
  Metadata_FileType: Array<MetadataFileType>;
  /** "MetadataFileType.photo"'s inverse relation count */
  Metadata_FileTypeCount: Scalars['Int']['output'];
  /** "MetadataFixtureIdentifier.photo"'s inverse relation */
  Metadata_FixtureIdentifier: Array<MetadataFixtureIdentifier>;
  /** "MetadataFixtureIdentifier.photo"'s inverse relation count */
  Metadata_FixtureIdentifierCount: Scalars['Int']['output'];
  /** "MetadataFormat.photo"'s inverse relation */
  Metadata_Format: Array<MetadataFormat>;
  /** "MetadataFormat.photo"'s inverse relation count */
  Metadata_FormatCount: Scalars['Int']['output'];
  /** "MetadataGpsLatitude.photo"'s inverse relation */
  Metadata_GPSLatitude: Array<MetadataGpsLatitude>;
  /** "MetadataGpsLatitude.photo"'s inverse relation count */
  Metadata_GPSLatitudeCount: Scalars['Int']['output'];
  /** "MetadataGpsLongitude.photo"'s inverse relation */
  Metadata_GPSLongitude: Array<MetadataGpsLongitude>;
  /** "MetadataGpsLongitude.photo"'s inverse relation count */
  Metadata_GPSLongitudeCount: Scalars['Int']['output'];
  /** "MetadataGpsProcessingMethod.photo"'s inverse relation */
  Metadata_GPSProcessingMethod: Array<MetadataGpsProcessingMethod>;
  /** "MetadataGpsProcessingMethod.photo"'s inverse relation count */
  Metadata_GPSProcessingMethodCount: Scalars['Int']['output'];
  /** "MetadataHeader.photo"'s inverse relation */
  Metadata_Header: Array<MetadataHeader>;
  /** "MetadataHeader.photo"'s inverse relation count */
  Metadata_HeaderCount: Scalars['Int']['output'];
  /** "MetadataHeaderPrisma.photo"'s inverse relation */
  Metadata_HeaderPrisma: Array<MetadataHeaderPrisma>;
  /** "MetadataHeaderPrisma.photo"'s inverse relation count */
  Metadata_HeaderPrismaCount: Scalars['Int']['output'];
  /** "MetadataHeadline.photo"'s inverse relation */
  Metadata_Headline: Array<MetadataHeadline>;
  /** "MetadataHeadline.photo"'s inverse relation count */
  Metadata_HeadlineCount: Scalars['Int']['output'];
  /** "MetadataHistory.photo"'s inverse relation */
  Metadata_History: Array<MetadataHistory>;
  /** "MetadataHistory.photo"'s inverse relation count */
  Metadata_HistoryCount: Scalars['Int']['output'];
  /** "MetadataIccProfile.photo"'s inverse relation */
  Metadata_ICCProfile: Array<MetadataIccProfile>;
  /** "MetadataIccProfile.photo"'s inverse relation count */
  Metadata_ICCProfileCount: Scalars['Int']['output'];
  /** "MetadataIdSubject.photo"'s inverse relation */
  Metadata_ID_subject: Array<MetadataIdSubject>;
  /** "MetadataIdSubject.photo"'s inverse relation count */
  Metadata_ID_subjectCount: Scalars['Int']['output'];
  /** "MetadataIdAssignment.photo"'s inverse relation */
  Metadata_IdAssignment: Array<MetadataIdAssignment>;
  /** "MetadataIdAssignment.photo"'s inverse relation count */
  Metadata_IdAssignmentCount: Scalars['Int']['output'];
  /** "MetadataIdMediaContent.photo"'s inverse relation */
  Metadata_IdMediaContent: Array<MetadataIdMediaContent>;
  /** "MetadataIdMediaContent.photo"'s inverse relation count */
  Metadata_IdMediaContentCount: Scalars['Int']['output'];
  /** "MetadataIdPhotoPrisma.photo"'s inverse relation */
  Metadata_IdPhotoPrisma: Array<MetadataIdPhotoPrisma>;
  /** "MetadataIdPhotoPrisma.photo"'s inverse relation count */
  Metadata_IdPhotoPrismaCount: Scalars['Int']['output'];
  /** "MetadataImageNotes.photo"'s inverse relation count */
  Metadata_ImageNoteCount: Scalars['Int']['output'];
  /** "MetadataImageNotes.photo"'s inverse relation */
  Metadata_ImageNotes: Array<MetadataImageNotes>;
  /** "MetadataImageUniqueId.photo"'s inverse relation */
  Metadata_ImageUniqueID: Array<MetadataImageUniqueId>;
  /** "MetadataImageUniqueId.photo"'s inverse relation count */
  Metadata_ImageUniqueIDCount: Scalars['Int']['output'];
  /** "MetadataInstructions.photo"'s inverse relation count */
  Metadata_InstructionCount: Scalars['Int']['output'];
  /** "MetadataInstructions.photo"'s inverse relation */
  Metadata_Instructions: Array<MetadataInstructions>;
  /** "MetadataIntellectualGenre.photo"'s inverse relation */
  Metadata_IntellectualGenre: Array<MetadataIntellectualGenre>;
  /** "MetadataIntellectualGenre.photo"'s inverse relation count */
  Metadata_IntellectualGenreCount: Scalars['Int']['output'];
  /** "MetadataIsoSpeedRatings.photo"'s inverse relation count */
  Metadata_ISOSpeedRatingCount: Scalars['Int']['output'];
  /** "MetadataIsoSpeedRatings.photo"'s inverse relation */
  Metadata_ISOSpeedRatings: Array<MetadataIsoSpeedRatings>;
  /** "MetadataIssue.photo"'s inverse relation */
  Metadata_Issue: Array<MetadataIssue>;
  /** "MetadataIssue.photo"'s inverse relation count */
  Metadata_IssueCount: Scalars['Int']['output'];
  /** "MetadataJobId.photo"'s inverse relation */
  Metadata_JobId: Array<MetadataJobId>;
  /** "MetadataJobId.photo"'s inverse relation count */
  Metadata_JobIdCount: Scalars['Int']['output'];
  /** "MetadataJobProcessing.photo"'s inverse relation */
  Metadata_JobProcessing: Array<MetadataJobProcessing>;
  /** "MetadataJobProcessing.photo"'s inverse relation count */
  Metadata_JobProcessingCount: Scalars['Int']['output'];
  /** "MetadataLayoutPictureEditing.photo"'s inverse relation */
  Metadata_LayoutPictureEditing: Array<MetadataLayoutPictureEditing>;
  /** "MetadataLayoutPictureEditing.photo"'s inverse relation count */
  Metadata_LayoutPictureEditingCount: Scalars['Int']['output'];
  /** "MetadataLayoutStorage.photo"'s inverse relation */
  Metadata_LayoutStorage: Array<MetadataLayoutStorage>;
  /** "MetadataLayoutStorage.photo"'s inverse relation count */
  Metadata_LayoutStorageCount: Scalars['Int']['output'];
  /** "MetadataLicense.photo"'s inverse relation */
  Metadata_license: Array<MetadataLicense>;
  /** "MetadataLicense.photo"'s inverse relation count */
  Metadata_licenseCount: Scalars['Int']['output'];
  /** "MetadataLinks.photo"'s inverse relation count */
  Metadata_LinkCount: Scalars['Int']['output'];
  /** "MetadataLinks.photo"'s inverse relation */
  Metadata_Links: Array<MetadataLinks>;
  /** "MetadataLocalCaption.photo"'s inverse relation */
  Metadata_LocalCaption: Array<MetadataLocalCaption>;
  /** "MetadataLocalCaption.photo"'s inverse relation count */
  Metadata_LocalCaptionCount: Scalars['Int']['output'];
  /** "MetadataLocation.photo"'s inverse relation */
  Metadata_Location: Array<MetadataLocation>;
  /** "MetadataLocation.photo"'s inverse relation count */
  Metadata_LocationCount: Scalars['Int']['output'];
  /** "MetadataMake.photo"'s inverse relation */
  Metadata_Make: Array<MetadataMake>;
  /** "MetadataMake.photo"'s inverse relation count */
  Metadata_MakeCount: Scalars['Int']['output'];
  /** "MetadataManifest.photo"'s inverse relation */
  Metadata_Manifest: Array<MetadataManifest>;
  /** "MetadataManifest.photo"'s inverse relation count */
  Metadata_ManifestCount: Scalars['Int']['output'];
  /** "MetadataMarked.photo"'s inverse relation */
  Metadata_Marked: Array<MetadataMarked>;
  /** "MetadataMarked.photo"'s inverse relation count */
  Metadata_MarkedCount: Scalars['Int']['output'];
  /** "MetadataMasterDocumentId.photo"'s inverse relation */
  Metadata_MasterDocumentId: Array<MetadataMasterDocumentId>;
  /** "MetadataMasterDocumentId.photo"'s inverse relation count */
  Metadata_MasterDocumentIdCount: Scalars['Int']['output'];
  /** "MetadataMaxAvailHeight.photo"'s inverse relation */
  Metadata_MaxAvailHeight: Array<MetadataMaxAvailHeight>;
  /** "MetadataMaxAvailHeight.photo"'s inverse relation count */
  Metadata_MaxAvailHeightCount: Scalars['Int']['output'];
  /** "MetadataMaxAvailWidth.photo"'s inverse relation */
  Metadata_MaxAvailWidth: Array<MetadataMaxAvailWidth>;
  /** "MetadataMaxAvailWidth.photo"'s inverse relation count */
  Metadata_MaxAvailWidthCount: Scalars['Int']['output'];
  /** "MetadataMinorModelAgeDisclosure.photo"'s inverse relation */
  Metadata_MinorModelAgeDisclosure: Array<MetadataMinorModelAgeDisclosure>;
  /** "MetadataMinorModelAgeDisclosure.photo"'s inverse relation count */
  Metadata_MinorModelAgeDisclosureCount: Scalars['Int']['output'];
  /** "MetadataModel.photo"'s inverse relation */
  Metadata_Model: Array<MetadataModel>;
  /** "MetadataModelAge.photo"'s inverse relation */
  Metadata_ModelAge: Array<MetadataModelAge>;
  /** "MetadataModelAge.photo"'s inverse relation count */
  Metadata_ModelAgeCount: Scalars['Int']['output'];
  /** "MetadataModel.photo"'s inverse relation count */
  Metadata_ModelCount: Scalars['Int']['output'];
  /** "MetadataModelReleaseId.photo"'s inverse relation */
  Metadata_ModelReleaseID: Array<MetadataModelReleaseId>;
  /** "MetadataModelReleaseId.photo"'s inverse relation count */
  Metadata_ModelReleaseIDCount: Scalars['Int']['output'];
  /** "MetadataModelReleaseStatus.photo"'s inverse relation */
  Metadata_ModelReleaseStatus: Array<MetadataModelReleaseStatus>;
  /** "MetadataModelReleaseStatus.photo"'s inverse relation count */
  Metadata_ModelReleaseStatusCount: Scalars['Int']['output'];
  /** "MetadataMorePermissions.photo"'s inverse relation count */
  Metadata_morePermissionCount: Scalars['Int']['output'];
  /** "MetadataMorePermissions.photo"'s inverse relation */
  Metadata_morePermissions: Array<MetadataMorePermissions>;
  /** "MetadataObjectCycle.photo"'s inverse relation */
  Metadata_ObjectCycle: Array<MetadataObjectCycle>;
  /** "MetadataObjectCycle.photo"'s inverse relation count */
  Metadata_ObjectCycleCount: Scalars['Int']['output'];
  /** "MetadataOrganisationInImageName.photo"'s inverse relation */
  Metadata_OrganisationInImageName: Array<MetadataOrganisationInImageName>;
  /** "MetadataOrganisationInImageName.photo"'s inverse relation count */
  Metadata_OrganisationInImageNameCount: Scalars['Int']['output'];
  /** "MetadataOriginalColor.photo"'s inverse relation */
  Metadata_OriginalColor: Array<MetadataOriginalColor>;
  /** "MetadataOriginalColor.photo"'s inverse relation count */
  Metadata_OriginalColorCount: Scalars['Int']['output'];
  /** "MetadataOriginalIccProfile.photo"'s inverse relation */
  Metadata_OriginalICCProfile: Array<MetadataOriginalIccProfile>;
  /** "MetadataOriginalIccProfile.photo"'s inverse relation count */
  Metadata_OriginalICCProfileCount: Scalars['Int']['output'];
  /** "MetadataOriginalName.photo"'s inverse relation */
  Metadata_OriginalName: Array<MetadataOriginalName>;
  /** "MetadataOriginalName.photo"'s inverse relation count */
  Metadata_OriginalNameCount: Scalars['Int']['output'];
  /** "MetadataOriginalWeigth.photo"'s inverse relation */
  Metadata_OriginalWeigth: Array<MetadataOriginalWeigth>;
  /** "MetadataOriginalWeigth.photo"'s inverse relation count */
  Metadata_OriginalWeigthCount: Scalars['Int']['output'];
  /** "MetadataOwner.photo"'s inverse relation */
  Metadata_Owner: Array<MetadataOwner>;
  /** "MetadataOwner.photo"'s inverse relation count */
  Metadata_OwnerCount: Scalars['Int']['output'];
  /** "MetadataOwnerId.photo"'s inverse relation */
  Metadata_OwnerId: Array<MetadataOwnerId>;
  /** "MetadataOwnerId.photo"'s inverse relation count */
  Metadata_OwnerIdCount: Scalars['Int']['output'];
  /** "MetadataPage.photo"'s inverse relation */
  Metadata_Page: Array<MetadataPage>;
  /** "MetadataPage.photo"'s inverse relation count */
  Metadata_PageCount: Scalars['Int']['output'];
  /** "MetadataPagePrisma.photo"'s inverse relation */
  Metadata_PagePrisma: Array<MetadataPagePrisma>;
  /** "MetadataPagePrisma.photo"'s inverse relation count */
  Metadata_PagePrismaCount: Scalars['Int']['output'];
  /** "MetadataPaintBasedCorrections.photo"'s inverse relation count */
  Metadata_PaintBasedCorrectionCount: Scalars['Int']['output'];
  /** "MetadataPaintBasedCorrections.photo"'s inverse relation */
  Metadata_PaintBasedCorrections: Array<MetadataPaintBasedCorrections>;
  /** "MetadataPatientDob.photo"'s inverse relation */
  Metadata_PatientDOB: Array<MetadataPatientDob>;
  /** "MetadataPatientDob.photo"'s inverse relation count */
  Metadata_PatientDOBCount: Scalars['Int']['output'];
  /** "MetadataPatientId.photo"'s inverse relation */
  Metadata_PatientID: Array<MetadataPatientId>;
  /** "MetadataPatientId.photo"'s inverse relation count */
  Metadata_PatientIDCount: Scalars['Int']['output'];
  /** "MetadataPatientName.photo"'s inverse relation */
  Metadata_PatientName: Array<MetadataPatientName>;
  /** "MetadataPatientName.photo"'s inverse relation count */
  Metadata_PatientNameCount: Scalars['Int']['output'];
  /** "MetadataPatientSex.photo"'s inverse relation */
  Metadata_PatientSex: Array<MetadataPatientSex>;
  /** "MetadataPatientSex.photo"'s inverse relation count */
  Metadata_PatientSexCount: Scalars['Int']['output'];
  /** "MetadataPersonInImage.photo"'s inverse relation */
  Metadata_PersonInImage: Array<MetadataPersonInImage>;
  /** "MetadataPersonInImage.photo"'s inverse relation count */
  Metadata_PersonInImageCount: Scalars['Int']['output'];
  /** "MetadataPhotoStorage.photo"'s inverse relation */
  Metadata_PhotoStorage: Array<MetadataPhotoStorage>;
  /** "MetadataPhotoStorage.photo"'s inverse relation count */
  Metadata_PhotoStorageCount: Scalars['Int']['output'];
  /** "MetadataPrepressPictureEditing.photo"'s inverse relation */
  Metadata_PrepressPictureEditing: Array<MetadataPrepressPictureEditing>;
  /** "MetadataPrepressPictureEditing.photo"'s inverse relation count */
  Metadata_PrepressPictureEditingCount: Scalars['Int']['output'];
  /** "MetadataPriceLevel.photo"'s inverse relation */
  Metadata_PriceLevel: Array<MetadataPriceLevel>;
  /** "MetadataPriceLevel.photo"'s inverse relation count */
  Metadata_PriceLevelCount: Scalars['Int']['output'];
  /** "MetadataPrintingProfile.photo"'s inverse relation */
  Metadata_PrintingProfile: Array<MetadataPrintingProfile>;
  /** "MetadataPrintingProfile.photo"'s inverse relation count */
  Metadata_PrintingProfileCount: Scalars['Int']['output'];
  /** "MetadataPrismaProduction.photo"'s inverse relation */
  Metadata_PrismaProduction: Array<MetadataPrismaProduction>;
  /** "MetadataPrismaProduction.photo"'s inverse relation count */
  Metadata_PrismaProductionCount: Scalars['Int']['output'];
  /** "MetadataProcessHistory.photo"'s inverse relation */
  Metadata_ProcessHistory: Array<MetadataProcessHistory>;
  /** "MetadataProcessHistory.photo"'s inverse relation count */
  Metadata_ProcessHistoryCount: Scalars['Int']['output'];
  /** "MetadataProcessParameter.photo"'s inverse relation */
  Metadata_ProcessParameter: Array<MetadataProcessParameter>;
  /** "MetadataProcessParameter.photo"'s inverse relation count */
  Metadata_ProcessParameterCount: Scalars['Int']['output'];
  /** "MetadataProcessStatus.photo"'s inverse relation */
  Metadata_ProcessStatus: Array<MetadataProcessStatus>;
  /** "MetadataProcessStatus.photo"'s inverse relation count */
  Metadata_ProcessStatusCount: Scalars['Int']['output'];
  /** "MetadataProduct.photo"'s inverse relation */
  Metadata_Product: Array<MetadataProduct>;
  /** "MetadataProduct.photo"'s inverse relation count */
  Metadata_ProductCount: Scalars['Int']['output'];
  /** "MetadataProducts.photo"'s inverse relation */
  Metadata_Products: Array<MetadataProducts>;
  /** "MetadataProductShortName.photo"'s inverse relation */
  Metadata_ProductShortName: Array<MetadataProductShortName>;
  /** "MetadataProductShortName.photo"'s inverse relation count */
  Metadata_ProductShortNameCount: Scalars['Int']['output'];
  /** "MetadataProduits.photo"'s inverse relation count */
  Metadata_ProduitCount: Scalars['Int']['output'];
  /** "MetadataProduits.photo"'s inverse relation */
  Metadata_Produits: Array<MetadataProduits>;
  /** "MetadataProgramVersion.photo"'s inverse relation */
  Metadata_ProgramVersion: Array<MetadataProgramVersion>;
  /** "MetadataProgramVersion.photo"'s inverse relation count */
  Metadata_ProgramVersionCount: Scalars['Int']['output'];
  /** "MetadataPropertyReleaseId.photo"'s inverse relation */
  Metadata_PropertyReleaseID: Array<MetadataPropertyReleaseId>;
  /** "MetadataPropertyReleaseId.photo"'s inverse relation count */
  Metadata_PropertyReleaseIDCount: Scalars['Int']['output'];
  /** "MetadataPropertyReleaseStatus.photo"'s inverse relation */
  Metadata_PropertyReleaseStatus: Array<MetadataPropertyReleaseStatus>;
  /** "MetadataPropertyReleaseStatus.photo"'s inverse relation count */
  Metadata_PropertyReleaseStatusCount: Scalars['Int']['output'];
  /** "MetadataPublisher.photo"'s inverse relation */
  Metadata_Publisher: Array<MetadataPublisher>;
  /** "MetadataPublisher.photo"'s inverse relation count */
  Metadata_PublisherCount: Scalars['Int']['output'];
  /** "MetadataPublishingIssue.photo"'s inverse relation */
  Metadata_publishingIssue: Array<MetadataPublishingIssue>;
  /** "MetadataPublishingIssue.photo"'s inverse relation count */
  Metadata_publishingIssueCount: Scalars['Int']['output'];
  /** "MetadataPublishingSubject.photo"'s inverse relation */
  Metadata_publishingSubject: Array<MetadataPublishingSubject>;
  /** "MetadataPublishingSubject.photo"'s inverse relation count */
  Metadata_publishingSubjectCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaBy.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaBy: Array<MetadataQualifiedUsePrismaBy>;
  /** "MetadataQualifiedUsePrismaBy.photo"'s inverse relation count */
  Metadata_QualifiedUsePrismaByCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaDate.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDate: Array<MetadataQualifiedUsePrismaDate>;
  /** "MetadataQualifiedUsePrismaDate.photo"'s inverse relation count */
  Metadata_QualifiedUsePrismaDateCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDuration: Array<MetadataQualifiedUsePrismaDuration>;
  /** "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation count */
  Metadata_QualifiedUsePrismaDurationCount: Scalars['Int']['output'];
  /** "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaSupport: Array<MetadataQualifiedUsePrismaSupport>;
  /** "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation count */
  Metadata_QualifiedUsePrismaSupportCount: Scalars['Int']['output'];
  /** "MetadataRating.photo"'s inverse relation */
  Metadata_Rating: Array<MetadataRating>;
  /** "MetadataRating.photo"'s inverse relation count */
  Metadata_RatingCount: Scalars['Int']['output'];
  /** "MetadataReferenceDate.photo"'s inverse relation */
  Metadata_ReferenceDate: Array<MetadataReferenceDate>;
  /** "MetadataReferenceDate.photo"'s inverse relation count */
  Metadata_ReferenceDateCount: Scalars['Int']['output'];
  /** "MetadataReferenceNumber.photo"'s inverse relation */
  Metadata_ReferenceNumber: Array<MetadataReferenceNumber>;
  /** "MetadataReferenceNumber.photo"'s inverse relation count */
  Metadata_ReferenceNumberCount: Scalars['Int']['output'];
  /** "MetadataReferenceService.photo"'s inverse relation */
  Metadata_ReferenceService: Array<MetadataReferenceService>;
  /** "MetadataReferenceService.photo"'s inverse relation count */
  Metadata_ReferenceServiceCount: Scalars['Int']['output'];
  /** "MetadataReleaseDate.photo"'s inverse relation */
  Metadata_ReleaseDate: Array<MetadataReleaseDate>;
  /** "MetadataReleaseDate.photo"'s inverse relation count */
  Metadata_ReleaseDateCount: Scalars['Int']['output'];
  /** "MetadataReleaseTime.photo"'s inverse relation */
  Metadata_ReleaseTime: Array<MetadataReleaseTime>;
  /** "MetadataReleaseTime.photo"'s inverse relation count */
  Metadata_ReleaseTimeCount: Scalars['Int']['output'];
  /** "MetadataRequiredPermission.photo"'s inverse relation */
  Metadata_RequiredPermission: Array<MetadataRequiredPermission>;
  /** "MetadataRequiredPermission.photo"'s inverse relation count */
  Metadata_RequiredPermissionCount: Scalars['Int']['output'];
  /** "MetadataResolutionKind.photo"'s inverse relation */
  Metadata_ResolutionKind: Array<MetadataResolutionKind>;
  /** "MetadataResolutionKind.photo"'s inverse relation count */
  Metadata_ResolutionKindCount: Scalars['Int']['output'];
  /** "MetadataRights.photo"'s inverse relation count */
  Metadata_rightCount: Scalars['Int']['output'];
  /** "MetadataRights.photo"'s inverse relation */
  Metadata_rights: Array<MetadataRights>;
  /** "MetadataRoyaltyfree.photo"'s inverse relation */
  Metadata_Royaltyfree: Array<MetadataRoyaltyfree>;
  /** "MetadataRoyaltyfree.photo"'s inverse relation count */
  Metadata_RoyaltyfreeCount: Scalars['Int']['output'];
  /** "MetadataScene.photo"'s inverse relation */
  Metadata_Scene: Array<MetadataScene>;
  /** "MetadataScene.photo"'s inverse relation count */
  Metadata_SceneCount: Scalars['Int']['output'];
  /** "MetadataSection.photo"'s inverse relation */
  Metadata_Section: Array<MetadataSection>;
  /** "MetadataSection.photo"'s inverse relation count */
  Metadata_SectionCount: Scalars['Int']['output'];
  /** "MetadataSentTo.photo"'s inverse relation */
  Metadata_SentTo: Array<MetadataSentTo>;
  /** "MetadataSentTo.photo"'s inverse relation count */
  Metadata_SentToCount: Scalars['Int']['output'];
  /** "MetadataSerialNumber.photo"'s inverse relation */
  Metadata_SerialNumber: Array<MetadataSerialNumber>;
  /** "MetadataSerialNumber.photo"'s inverse relation count */
  Metadata_SerialNumberCount: Scalars['Int']['output'];
  /** "MetadataSeriesDateTime.photo"'s inverse relation */
  Metadata_SeriesDateTime: Array<MetadataSeriesDateTime>;
  /** "MetadataSeriesDateTime.photo"'s inverse relation count */
  Metadata_SeriesDateTimeCount: Scalars['Int']['output'];
  /** "MetadataSeriesDescription.photo"'s inverse relation */
  Metadata_SeriesDescription: Array<MetadataSeriesDescription>;
  /** "MetadataSeriesDescription.photo"'s inverse relation count */
  Metadata_SeriesDescriptionCount: Scalars['Int']['output'];
  /** "MetadataSeriesModality.photo"'s inverse relation */
  Metadata_SeriesModality: Array<MetadataSeriesModality>;
  /** "MetadataSeriesModality.photo"'s inverse relation count */
  Metadata_SeriesModalityCount: Scalars['Int']['output'];
  /** "MetadataSeriesNumber.photo"'s inverse relation */
  Metadata_SeriesNumber: Array<MetadataSeriesNumber>;
  /** "MetadataSeriesNumber.photo"'s inverse relation count */
  Metadata_SeriesNumberCount: Scalars['Int']['output'];
  /** "MetadataShortUniqueId.photo"'s inverse relation */
  Metadata_ShortUniqueId: Array<MetadataShortUniqueId>;
  /** "MetadataShortUniqueId.photo"'s inverse relation count */
  Metadata_ShortUniqueIdCount: Scalars['Int']['output'];
  /** "MetadataSource.photo"'s inverse relation */
  Metadata_Source: Array<MetadataSource>;
  /** "MetadataSource.photo"'s inverse relation count */
  Metadata_SourceCount: Scalars['Int']['output'];
  /** "MetadataState.photo"'s inverse relation */
  Metadata_State: Array<MetadataState>;
  /** "MetadataState.photo"'s inverse relation count */
  Metadata_StateCount: Scalars['Int']['output'];
  /** "MetadataStudyDateTime.photo"'s inverse relation */
  Metadata_StudyDateTime: Array<MetadataStudyDateTime>;
  /** "MetadataStudyDateTime.photo"'s inverse relation count */
  Metadata_StudyDateTimeCount: Scalars['Int']['output'];
  /** "MetadataStudyDescription.photo"'s inverse relation */
  Metadata_StudyDescription: Array<MetadataStudyDescription>;
  /** "MetadataStudyDescription.photo"'s inverse relation count */
  Metadata_StudyDescriptionCount: Scalars['Int']['output'];
  /** "MetadataStudyId.photo"'s inverse relation */
  Metadata_StudyID: Array<MetadataStudyId>;
  /** "MetadataStudyId.photo"'s inverse relation count */
  Metadata_StudyIDCount: Scalars['Int']['output'];
  /** "MetadataStudyPhysician.photo"'s inverse relation */
  Metadata_StudyPhysician: Array<MetadataStudyPhysician>;
  /** "MetadataStudyPhysician.photo"'s inverse relation count */
  Metadata_StudyPhysicianCount: Scalars['Int']['output'];
  /** "MetadataSubject.photo"'s inverse relation */
  Metadata_subject: Array<MetadataSubject>;
  /** "MetadataSubjectCode.photo"'s inverse relation */
  Metadata_SubjectCode: Array<MetadataSubjectCode>;
  /** "MetadataSubjectCode.photo"'s inverse relation count */
  Metadata_SubjectCodeCount: Scalars['Int']['output'];
  /** "MetadataSubject.photo"'s inverse relation count */
  Metadata_subjectCount: Scalars['Int']['output'];
  /** "MetadataSubjectPrisma.photo"'s inverse relation */
  Metadata_SubjectPrisma: Array<MetadataSubjectPrisma>;
  /** "MetadataSubjectPrisma.photo"'s inverse relation count */
  Metadata_SubjectPrismaCount: Scalars['Int']['output'];
  /** "MetadataSupplementalCategories.photo"'s inverse relation */
  Metadata_SupplementalCategories: Array<MetadataSupplementalCategories>;
  /** "MetadataSupplementalCategories.photo"'s inverse relation count */
  Metadata_SupplementalCategoryCount: Scalars['Int']['output'];
  /** "MetadataTargetVersion.photo"'s inverse relation */
  Metadata_TargetVersion: Array<MetadataTargetVersion>;
  /** "MetadataTargetVersion.photo"'s inverse relation count */
  Metadata_TargetVersionCount: Scalars['Int']['output'];
  /** "MetadataTitle.photo"'s inverse relation */
  Metadata_title: Array<MetadataTitle>;
  /** "MetadataTitle.photo"'s inverse relation count */
  Metadata_titleCount: Scalars['Int']['output'];
  /** "MetadataToneCurve.photo"'s inverse relation */
  Metadata_ToneCurve: Array<MetadataToneCurve>;
  /** "MetadataToneCurve.photo"'s inverse relation count */
  Metadata_ToneCurveCount: Scalars['Int']['output'];
  /** "MetadataTransferredBy.photo"'s inverse relation */
  Metadata_TransferredBy: Array<MetadataTransferredBy>;
  /** "MetadataTransferredBy.photo"'s inverse relation count */
  Metadata_TransferredByCount: Scalars['Int']['output'];
  /** "MetadataTransferredByEmail.photo"'s inverse relation */
  Metadata_TransferredByEmail: Array<MetadataTransferredByEmail>;
  /** "MetadataTransferredByEmail.photo"'s inverse relation count */
  Metadata_TransferredByEmailCount: Scalars['Int']['output'];
  /** "MetadataTransferredByFullName.photo"'s inverse relation */
  Metadata_TransferredByFullName: Array<MetadataTransferredByFullName>;
  /** "MetadataTransferredByFullName.photo"'s inverse relation count */
  Metadata_TransferredByFullNameCount: Scalars['Int']['output'];
  /** "MetadataTransmissionReference.photo"'s inverse relation */
  Metadata_TransmissionReference: Array<MetadataTransmissionReference>;
  /** "MetadataTransmissionReference.photo"'s inverse relation count */
  Metadata_TransmissionReferenceCount: Scalars['Int']['output'];
  /** "MetadataType.photo"'s inverse relation */
  Metadata_Type: Array<MetadataType>;
  /** "MetadataType.photo"'s inverse relation count */
  Metadata_TypeCount: Scalars['Int']['output'];
  /** "MetadataUndersubject.photo"'s inverse relation */
  Metadata_Undersubject: Array<MetadataUndersubject>;
  /** "MetadataUndersubject.photo"'s inverse relation count */
  Metadata_UndersubjectCount: Scalars['Int']['output'];
  /** "MetadataUnderUnderSubject.photo"'s inverse relation */
  Metadata_UnderUnderSubject: Array<MetadataUnderUnderSubject>;
  /** "MetadataUnderUnderSubject.photo"'s inverse relation count */
  Metadata_UnderUnderSubjectCount: Scalars['Int']['output'];
  /** "MetadataUniqueId.photo"'s inverse relation */
  Metadata_UniqueId: Array<MetadataUniqueId>;
  /** "MetadataUniqueId.photo"'s inverse relation count */
  Metadata_UniqueIdCount: Scalars['Int']['output'];
  /** "MetadataUnit.photo"'s inverse relation */
  Metadata_Unit: Array<MetadataUnit>;
  /** "MetadataUnit.photo"'s inverse relation count */
  Metadata_UnitCount: Scalars['Int']['output'];
  /** "MetadataUnitShortName.photo"'s inverse relation */
  Metadata_UnitShortName: Array<MetadataUnitShortName>;
  /** "MetadataUnitShortName.photo"'s inverse relation count */
  Metadata_UnitShortNameCount: Scalars['Int']['output'];
  /** "MetadataUploadedBy.photo"'s inverse relation */
  Metadata_UploadedBy: Array<MetadataUploadedBy>;
  /** "MetadataUploadedBy.photo"'s inverse relation count */
  Metadata_UploadedByCount: Scalars['Int']['output'];
  /** "MetadataUploadedByFullName.photo"'s inverse relation */
  Metadata_UploadedByFullName: Array<MetadataUploadedByFullName>;
  /** "MetadataUploadedByFullName.photo"'s inverse relation count */
  Metadata_UploadedByFullNameCount: Scalars['Int']['output'];
  /** "MetadataUploadTime.photo"'s inverse relation */
  Metadata_UploadTime: Array<MetadataUploadTime>;
  /** "MetadataUploadTime.photo"'s inverse relation count */
  Metadata_UploadTimeCount: Scalars['Int']['output'];
  /** "MetadataUrgency.photo"'s inverse relation */
  Metadata_Urgency: Array<MetadataUrgency>;
  /** "MetadataUrgency.photo"'s inverse relation count */
  Metadata_UrgencyCount: Scalars['Int']['output'];
  /** "MetadataUsageTerms.photo"'s inverse relation count */
  Metadata_UsageTermCount: Scalars['Int']['output'];
  /** "MetadataUsageTerms.photo"'s inverse relation */
  Metadata_UsageTerms: Array<MetadataUsageTerms>;
  /** "MetadataUserComment.photo"'s inverse relation */
  Metadata_UserComment: Array<MetadataUserComment>;
  /** "MetadataUserComment.photo"'s inverse relation count */
  Metadata_UserCommentCount: Scalars['Int']['output'];
  /** "MetadataUserDefined195.photo"'s inverse relation */
  Metadata_UserDefined195: Array<MetadataUserDefined195>;
  /** "MetadataUserDefined195.photo"'s inverse relation count */
  Metadata_UserDefined195Count: Scalars['Int']['output'];
  /** "MetadataUserDefined237.photo"'s inverse relation */
  Metadata_UserDefined237: Array<MetadataUserDefined237>;
  /** "MetadataUserDefined237.photo"'s inverse relation count */
  Metadata_UserDefined237Count: Scalars['Int']['output'];
  /** "MetadataUserDefined238.photo"'s inverse relation */
  Metadata_UserDefined238: Array<MetadataUserDefined238>;
  /** "MetadataUserDefined238.photo"'s inverse relation count */
  Metadata_UserDefined238Count: Scalars['Int']['output'];
  /** "MetadataUserDefined239.photo"'s inverse relation */
  Metadata_UserDefined239: Array<MetadataUserDefined239>;
  /** "MetadataUserDefined239.photo"'s inverse relation count */
  Metadata_UserDefined239Count: Scalars['Int']['output'];
  /** "MetadataUserDefined242.photo"'s inverse relation */
  Metadata_UserDefined242: Array<MetadataUserDefined242>;
  /** "MetadataUserDefined242.photo"'s inverse relation count */
  Metadata_UserDefined242Count: Scalars['Int']['output'];
  /** "MetadataUserDefined62.photo"'s inverse relation */
  Metadata_UserDefined62: Array<MetadataUserDefined62>;
  /** "MetadataUserDefined62.photo"'s inverse relation count */
  Metadata_UserDefined62Count: Scalars['Int']['output'];
  /** "MetadataValid.photo"'s inverse relation */
  Metadata_Valid: Array<MetadataValid>;
  /** "MetadataValid.photo"'s inverse relation count */
  Metadata_ValidCount: Scalars['Int']['output'];
  /** "MetadataVersion.photo"'s inverse relation */
  Metadata_Version: Array<MetadataVersion>;
  /** "MetadataVersion.photo"'s inverse relation count */
  Metadata_VersionCount: Scalars['Int']['output'];
  /** "MetadataWebStatement.photo"'s inverse relation */
  Metadata_WebStatement: Array<MetadataWebStatement>;
  /** "MetadataWebStatement.photo"'s inverse relation count */
  Metadata_WebStatementCount: Scalars['Int']['output'];
  /** "MetadataWorkflowKind.photo"'s inverse relation */
  Metadata_WorkflowKind: Array<MetadataWorkflowKind>;
  /** "MetadataWorkflowKind.photo"'s inverse relation count */
  Metadata_WorkflowKindCount: Scalars['Int']['output'];
  /** "MetadataXmpFileStamps.photo"'s inverse relation count */
  Metadata_XMPFileStampCount: Scalars['Int']['output'];
  /** "MetadataXmpFileStamps.photo"'s inverse relation */
  Metadata_XMPFileStamps: Array<MetadataXmpFileStamps>;
  /** "MetadataXmpHistory.photo"'s inverse relation */
  Metadata_XMPHistory: Array<MetadataXmpHistory>;
  /** "MetadataXmpHistory.photo"'s inverse relation count */
  Metadata_XMPHistoryCount: Scalars['Int']['output'];
  /** optimized photo metadata listing by name for convenience */
  metadataByName?: Maybe<Scalars['JSONObject']['output']>;
  originalName?: Maybe<Scalars['String']['output']>;
  /** "PhotoMetadata.photo"'s inverse relation count */
  photoMetadataCount: Scalars['Int']['output'];
  /** "PhotoMetadata.photo"'s inverse relation */
  photoMetadatas: Array<PhotoMetadata>;
  /** "PhotoPublication.photo"'s inverse relation count */
  photoPublicationCount: Scalars['Int']['output'];
  /** "PhotoPublication.photo"'s inverse relation */
  photoPublications: Array<PhotoPublication>;
  /** get all supported resized previews */
  previews?: Maybe<Array<Preview>>;
  /** get an expirable public download URL */
  publicUrl?: Maybe<Scalars['String']['output']>;
  synced?: Maybe<Scalars['Boolean']['output']>;
  /** The date of the photo's capture */
  takenAt?: Maybe<Scalars['DateTime']['output']>;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  /** get presigned url from S3 */
  uploadUrl?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AccessRightCountArgs = {
  where?: InputMaybe<MetadataAccessRightsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AccessRightsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAccessRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAccessRightsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AddlModelInfoArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAddlModelInfoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAddlModelInfoWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AddlModelInfoCountArgs = {
  where?: InputMaybe<MetadataAddlModelInfoWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_Archive_ChildArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataArchiveChildOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataArchiveChildWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_Archive_ChildCountArgs = {
  where?: InputMaybe<MetadataArchiveChildWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_Archive_ParentArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataArchiveParentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataArchiveParentWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_Archive_ParentCountArgs = {
  where?: InputMaybe<MetadataArchiveParentWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AssetStateArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAssetStateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAssetStateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AssetStateCountArgs = {
  where?: InputMaybe<MetadataAssetStateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AttributionNameArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAttributionNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAttributionNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AttributionNameCountArgs = {
  where?: InputMaybe<MetadataAttributionNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AttributionUrlArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAttributionUrlOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAttributionUrlWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AttributionUrlCountArgs = {
  where?: InputMaybe<MetadataAttributionUrlWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AudienceArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAudienceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAudienceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AudienceCountArgs = {
  where?: InputMaybe<MetadataAudienceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AuthorsPositionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAuthorsPositionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAuthorsPositionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_AuthorsPositionCountArgs = {
  where?: InputMaybe<MetadataAuthorsPositionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_BackupNameArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBackupNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBackupNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_BackupNameCountArgs = {
  where?: InputMaybe<MetadataBackupNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_BitmapGrayscalePictureArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBitmapGrayscalePictureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_BitmapGrayscalePictureCountArgs = {
  where?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_BrandArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_BrandCountArgs = {
  where?: InputMaybe<MetadataBrandWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_BrandPrismaArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandPrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_BrandPrismaCountArgs = {
  where?: InputMaybe<MetadataBrandPrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_BrandShortNameArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandShortNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_BrandShortNameCountArgs = {
  where?: InputMaybe<MetadataBrandShortNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CaptionWriterArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCaptionWriterOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCaptionWriterWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CaptionWriterCountArgs = {
  where?: InputMaybe<MetadataCaptionWriterWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CategoryArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCategoryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CategoryCountArgs = {
  where?: InputMaybe<MetadataCategoryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CategoryPrismaArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCategoryPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCategoryPrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CategoryPrismaCountArgs = {
  where?: InputMaybe<MetadataCategoryPrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CertificateArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCertificateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCertificateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CertificateCountArgs = {
  where?: InputMaybe<MetadataCertificateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiAdrCityArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrCityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrCityWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiAdrCityCountArgs = {
  where?: InputMaybe<MetadataCiAdrCityWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiAdrCtryArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrCtryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrCtryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiAdrCtryCountArgs = {
  where?: InputMaybe<MetadataCiAdrCtryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiAdrExtadrArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrExtadrOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiAdrExtadrCountArgs = {
  where?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiAdrPcodeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrPcodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiAdrPcodeCountArgs = {
  where?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiAdrRegionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrRegionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrRegionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiAdrRegionCountArgs = {
  where?: InputMaybe<MetadataCiAdrRegionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiEmailWorkArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiEmailWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiEmailWorkWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiEmailWorkCountArgs = {
  where?: InputMaybe<MetadataCiEmailWorkWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiTelWorkArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiTelWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiTelWorkWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiTelWorkCountArgs = {
  where?: InputMaybe<MetadataCiTelWorkWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CityArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCityWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CityCountArgs = {
  where?: InputMaybe<MetadataCityWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiUrlWorkArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiUrlWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiUrlWorkWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CiUrlWorkCountArgs = {
  where?: InputMaybe<MetadataCiUrlWorkWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ClassifyArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataClassifyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataClassifyWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ClassifyCountArgs = {
  where?: InputMaybe<MetadataClassifyWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CompanyArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCompanyWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CompanyCountArgs = {
  where?: InputMaybe<MetadataCompanyWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CompanyShortNameArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCompanyShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCompanyShortNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CompanyShortNameCountArgs = {
  where?: InputMaybe<MetadataCompanyShortNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ConfidentialityArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataConfidentialityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataConfidentialityWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ConfidentialityCountArgs = {
  where?: InputMaybe<MetadataConfidentialityWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ContainedInArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContainedInOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContainedInWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ContainedInCountArgs = {
  where?: InputMaybe<MetadataContainedInWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ContentMediaKindArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContentMediaKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContentMediaKindWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ContentMediaKindCountArgs = {
  where?: InputMaybe<MetadataContentMediaKindWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ContentValueArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContentValueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContentValueWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ContentValueCountArgs = {
  where?: InputMaybe<MetadataContentValueWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CopyrightLayerArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCopyrightLayerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCopyrightLayerWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CopyrightLayerCountArgs = {
  where?: InputMaybe<MetadataCopyrightLayerWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CountryArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCountryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCountryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CountryCodeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCountryCodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCountryCodeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CountryCodeCountArgs = {
  where?: InputMaybe<MetadataCountryCodeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CountryCountArgs = {
  where?: InputMaybe<MetadataCountryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CreatedTimeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatedTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatedTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CreatedTimeCountArgs = {
  where?: InputMaybe<MetadataCreatedTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CreatorArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatorWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CreatorCountArgs = {
  where?: InputMaybe<MetadataCreatorWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CreatorToolArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatorToolOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatorToolWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CreatorToolCountArgs = {
  where?: InputMaybe<MetadataCreatorToolWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CreditArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreditOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreditWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CreditCountArgs = {
  where?: InputMaybe<MetadataCreditWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField14Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField14OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField14WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField14CountArgs = {
  where?: InputMaybe<MetadataCustomField14WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField15Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField15OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField15WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField15CountArgs = {
  where?: InputMaybe<MetadataCustomField15WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField16Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField16OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField16WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField16CountArgs = {
  where?: InputMaybe<MetadataCustomField16WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField17Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField17OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField17WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField17CountArgs = {
  where?: InputMaybe<MetadataCustomField17WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField20Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField20OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField20WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField20CountArgs = {
  where?: InputMaybe<MetadataCustomField20WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField5Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField5OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField5WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField5CountArgs = {
  where?: InputMaybe<MetadataCustomField5WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField6Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField6OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField6WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField6CountArgs = {
  where?: InputMaybe<MetadataCustomField6WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField7Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField7OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField7WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField7CountArgs = {
  where?: InputMaybe<MetadataCustomField7WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField9Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField9OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField9WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_CustomField9CountArgs = {
  where?: InputMaybe<MetadataCustomField9WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DateCreatedArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateCreatedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateCreatedWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DateCreatedCountArgs = {
  where?: InputMaybe<MetadataDateCreatedWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DateTimeDigitizedArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateTimeDigitizedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DateTimeDigitizedCountArgs = {
  where?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DateTimeOriginalArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateTimeOriginalOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DateTimeOriginalCountArgs = {
  where?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryAccountArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryAccountOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryAccountWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryAccountCountArgs = {
  where?: InputMaybe<MetadataDeliveryAccountWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryCompanyArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryCompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryCompanyCountArgs = {
  where?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryCopyrightArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryCopyrightOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryCopyrightCountArgs = {
  where?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryDateFolderArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDateFolderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryDateFolderCountArgs = {
  where?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryDateTimeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryDateTimeCountArgs = {
  where?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryDcCreatorArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDcCreatorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryDcCreatorCountArgs = {
  where?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryDcRightCountArgs = {
  where?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryDcRightsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDcRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryFileTypeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryFileTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryFileTypeCountArgs = {
  where?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryFolderArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryFolderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryFolderWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryFolderCountArgs = {
  where?: InputMaybe<MetadataDeliveryFolderWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryKindArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryKindWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryKindCountArgs = {
  where?: InputMaybe<MetadataDeliveryKindWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryPathArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPathOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPathWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryPathCountArgs = {
  where?: InputMaybe<MetadataDeliveryPathWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryPersonShownintheImageArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryPersonShownintheImageCountArgs = {
  where?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryPhotoshopCreditArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryPhotoshopCreditCountArgs = {
  where?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryPhotoshopSourceArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryPhotoshopSourceCountArgs = {
  where?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryServiceArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryServiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryServiceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryServiceCountArgs = {
  where?: InputMaybe<MetadataDeliveryServiceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliverySubjectArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliverySubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliverySubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliverySubjectCountArgs = {
  where?: InputMaybe<MetadataDeliverySubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryUnderSubjectArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryUnderSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DeliveryUnderSubjectCountArgs = {
  where?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DepartmentArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDepartmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDepartmentWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DepartmentCountArgs = {
  where?: InputMaybe<MetadataDepartmentWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DescriptionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDescriptionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DescriptionCountArgs = {
  where?: InputMaybe<MetadataDescriptionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DigitalAssetUrlArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDigitalAssetUrlOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DigitalAssetUrlCountArgs = {
  where?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DivisionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDivisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDivisionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DivisionCountArgs = {
  where?: InputMaybe<MetadataDivisionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DocumentTextArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDocumentTextOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDocumentTextWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_DocumentTextCountArgs = {
  where?: InputMaybe<MetadataDocumentTextWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EditionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EditionCountArgs = {
  where?: InputMaybe<MetadataEditionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EditorialVersionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditorialVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditorialVersionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EditorialVersionCountArgs = {
  where?: InputMaybe<MetadataEditorialVersionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EditStatusArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditStatusWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EditStatusCountArgs = {
  where?: InputMaybe<MetadataEditStatusWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EnvironnementPhotoArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEnvironnementPhotoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EnvironnementPhotoCountArgs = {
  where?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EquipmentInstitutionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEquipmentInstitutionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EquipmentInstitutionCountArgs = {
  where?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EquipmentManufacturerArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEquipmentManufacturerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EquipmentManufacturerCountArgs = {
  where?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EventArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEventOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEventWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_EventCountArgs = {
  where?: InputMaybe<MetadataEventWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ExifArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataExifOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataExifWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ExifCountArgs = {
  where?: InputMaybe<MetadataExifWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FabStorageArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFabStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFabStorageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FabStorageCountArgs = {
  where?: InputMaybe<MetadataFabStorageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileCheckForArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileCheckForOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileCheckForWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileCheckForCountArgs = {
  where?: InputMaybe<MetadataFileCheckForWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileInfoBitsPerPixelArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoBitsPerPixelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileInfoBitsPerPixelCountArgs = {
  where?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileInfoFrameCountArgs = {
  where?: InputMaybe<MetadataFileInfoFramesWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileInfoFramesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoFramesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoFramesWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileInfoPhotometricInterpretationArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileInfoPhotometricInterpretationCountArgs = {
  where?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileInfoTransferSyntaxArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoTransferSyntaxOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileInfoTransferSyntaxCountArgs = {
  where?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileStageArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileStageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileStageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileStageCountArgs = {
  where?: InputMaybe<MetadataFileStageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileStatusArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileStatusWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileStatusCountArgs = {
  where?: InputMaybe<MetadataFileStatusWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileTypeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileTypeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FileTypeCountArgs = {
  where?: InputMaybe<MetadataFileTypeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FixtureIdentifierArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFixtureIdentifierOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FixtureIdentifierCountArgs = {
  where?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FormatArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFormatOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFormatWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_FormatCountArgs = {
  where?: InputMaybe<MetadataFormatWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_GpsLatitudeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsLatitudeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsLatitudeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_GpsLatitudeCountArgs = {
  where?: InputMaybe<MetadataGpsLatitudeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_GpsLongitudeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsLongitudeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsLongitudeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_GpsLongitudeCountArgs = {
  where?: InputMaybe<MetadataGpsLongitudeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_GpsProcessingMethodArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsProcessingMethodOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_GpsProcessingMethodCountArgs = {
  where?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_HeaderArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeaderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeaderWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_HeaderCountArgs = {
  where?: InputMaybe<MetadataHeaderWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_HeaderPrismaArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeaderPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeaderPrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_HeaderPrismaCountArgs = {
  where?: InputMaybe<MetadataHeaderPrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_HeadlineArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeadlineOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeadlineWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_HeadlineCountArgs = {
  where?: InputMaybe<MetadataHeadlineWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_HistoryArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHistoryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_HistoryCountArgs = {
  where?: InputMaybe<MetadataHistoryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IccProfileArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIccProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIccProfileWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IccProfileCountArgs = {
  where?: InputMaybe<MetadataIccProfileWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_Id_SubjectArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdSubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_Id_SubjectCountArgs = {
  where?: InputMaybe<MetadataIdSubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IdAssignmentArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdAssignmentWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IdAssignmentCountArgs = {
  where?: InputMaybe<MetadataIdAssignmentWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IdMediaContentArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdMediaContentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdMediaContentWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IdMediaContentCountArgs = {
  where?: InputMaybe<MetadataIdMediaContentWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IdPhotoPrismaArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdPhotoPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IdPhotoPrismaCountArgs = {
  where?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ImageNoteCountArgs = {
  where?: InputMaybe<MetadataImageNotesWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ImageNotesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataImageNotesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataImageNotesWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ImageUniqueIdArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataImageUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataImageUniqueIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ImageUniqueIdCountArgs = {
  where?: InputMaybe<MetadataImageUniqueIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_InstructionCountArgs = {
  where?: InputMaybe<MetadataInstructionsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_InstructionsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataInstructionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataInstructionsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IntellectualGenreArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIntellectualGenreOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIntellectualGenreWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IntellectualGenreCountArgs = {
  where?: InputMaybe<MetadataIntellectualGenreWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IsoSpeedRatingCountArgs = {
  where?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IsoSpeedRatingsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIsoSpeedRatingsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IssueArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIssueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIssueWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_IssueCountArgs = {
  where?: InputMaybe<MetadataIssueWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_JobIdArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataJobIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataJobIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_JobIdCountArgs = {
  where?: InputMaybe<MetadataJobIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_JobProcessingArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataJobProcessingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataJobProcessingWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_JobProcessingCountArgs = {
  where?: InputMaybe<MetadataJobProcessingWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LayoutPictureEditingArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLayoutPictureEditingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LayoutPictureEditingCountArgs = {
  where?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LayoutStorageArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLayoutStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLayoutStorageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LayoutStorageCountArgs = {
  where?: InputMaybe<MetadataLayoutStorageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LicenseArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLicenseOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLicenseWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LicenseCountArgs = {
  where?: InputMaybe<MetadataLicenseWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LinkCountArgs = {
  where?: InputMaybe<MetadataLinksWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LinksArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLinksOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLinksWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LocalCaptionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLocalCaptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLocalCaptionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LocalCaptionCountArgs = {
  where?: InputMaybe<MetadataLocalCaptionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LocationArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLocationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLocationWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_LocationCountArgs = {
  where?: InputMaybe<MetadataLocationWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MakeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMakeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMakeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MakeCountArgs = {
  where?: InputMaybe<MetadataMakeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ManifestArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataManifestOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataManifestWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ManifestCountArgs = {
  where?: InputMaybe<MetadataManifestWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MarkedArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMarkedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMarkedWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MarkedCountArgs = {
  where?: InputMaybe<MetadataMarkedWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MasterDocumentIdArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMasterDocumentIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MasterDocumentIdCountArgs = {
  where?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MaxAvailHeightArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMaxAvailHeightOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MaxAvailHeightCountArgs = {
  where?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MaxAvailWidthArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMaxAvailWidthOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MaxAvailWidthCountArgs = {
  where?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MinorModelAgeDisclosureArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMinorModelAgeDisclosureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MinorModelAgeDisclosureCountArgs = {
  where?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ModelArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ModelAgeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelAgeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelAgeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ModelAgeCountArgs = {
  where?: InputMaybe<MetadataModelAgeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ModelCountArgs = {
  where?: InputMaybe<MetadataModelWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ModelReleaseIdArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelReleaseIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelReleaseIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ModelReleaseIdCountArgs = {
  where?: InputMaybe<MetadataModelReleaseIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ModelReleaseStatusArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelReleaseStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ModelReleaseStatusCountArgs = {
  where?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MorePermissionCountArgs = {
  where?: InputMaybe<MetadataMorePermissionsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_MorePermissionsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMorePermissionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMorePermissionsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ObjectCycleArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataObjectCycleOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataObjectCycleWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ObjectCycleCountArgs = {
  where?: InputMaybe<MetadataObjectCycleWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OrganisationInImageNameArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOrganisationInImageNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OrganisationInImageNameCountArgs = {
  where?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OriginalColorArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalColorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalColorWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OriginalColorCountArgs = {
  where?: InputMaybe<MetadataOriginalColorWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OriginalIccProfileArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalIccProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OriginalIccProfileCountArgs = {
  where?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OriginalNameArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OriginalNameCountArgs = {
  where?: InputMaybe<MetadataOriginalNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OriginalWeigthArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalWeigthOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalWeigthWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OriginalWeigthCountArgs = {
  where?: InputMaybe<MetadataOriginalWeigthWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OwnerArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOwnerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOwnerWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OwnerCountArgs = {
  where?: InputMaybe<MetadataOwnerWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OwnerIdArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOwnerIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOwnerIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_OwnerIdCountArgs = {
  where?: InputMaybe<MetadataOwnerIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PageArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PageCountArgs = {
  where?: InputMaybe<MetadataPageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PagePrismaArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPagePrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPagePrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PagePrismaCountArgs = {
  where?: InputMaybe<MetadataPagePrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PaintBasedCorrectionCountArgs = {
  where?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PaintBasedCorrectionsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPaintBasedCorrectionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PatientDobArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientDobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientDobWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PatientDobCountArgs = {
  where?: InputMaybe<MetadataPatientDobWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PatientIdArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PatientIdCountArgs = {
  where?: InputMaybe<MetadataPatientIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PatientNameArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PatientNameCountArgs = {
  where?: InputMaybe<MetadataPatientNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PatientSexArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientSexOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientSexWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PatientSexCountArgs = {
  where?: InputMaybe<MetadataPatientSexWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PersonInImageArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPersonInImageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPersonInImageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PersonInImageCountArgs = {
  where?: InputMaybe<MetadataPersonInImageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PhotoStorageArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPhotoStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPhotoStorageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PhotoStorageCountArgs = {
  where?: InputMaybe<MetadataPhotoStorageWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PrepressPictureEditingArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrepressPictureEditingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PrepressPictureEditingCountArgs = {
  where?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PriceLevelArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPriceLevelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPriceLevelWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PriceLevelCountArgs = {
  where?: InputMaybe<MetadataPriceLevelWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PrintingProfileArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrintingProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrintingProfileWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PrintingProfileCountArgs = {
  where?: InputMaybe<MetadataPrintingProfileWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PrismaProductionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrismaProductionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrismaProductionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PrismaProductionCountArgs = {
  where?: InputMaybe<MetadataPrismaProductionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProcessHistoryArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessHistoryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProcessHistoryCountArgs = {
  where?: InputMaybe<MetadataProcessHistoryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProcessParameterArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessParameterOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessParameterWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProcessParameterCountArgs = {
  where?: InputMaybe<MetadataProcessParameterWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProcessStatusArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessStatusWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProcessStatusCountArgs = {
  where?: InputMaybe<MetadataProcessStatusWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProductArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProductCountArgs = {
  where?: InputMaybe<MetadataProductWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProductsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProductShortNameArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductShortNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProductShortNameCountArgs = {
  where?: InputMaybe<MetadataProductShortNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProduitCountArgs = {
  where?: InputMaybe<MetadataProduitsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProduitsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProduitsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProduitsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProgramVersionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProgramVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProgramVersionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ProgramVersionCountArgs = {
  where?: InputMaybe<MetadataProgramVersionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PropertyReleaseIdArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPropertyReleaseIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PropertyReleaseIdCountArgs = {
  where?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PropertyReleaseStatusArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPropertyReleaseStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PropertyReleaseStatusCountArgs = {
  where?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PublisherArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublisherOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublisherWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PublisherCountArgs = {
  where?: InputMaybe<MetadataPublisherWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PublishingIssueArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublishingIssueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublishingIssueWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PublishingIssueCountArgs = {
  where?: InputMaybe<MetadataPublishingIssueWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PublishingSubjectArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublishingSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublishingSubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_PublishingSubjectCountArgs = {
  where?: InputMaybe<MetadataPublishingSubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_QualifiedUsePrismaByArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_QualifiedUsePrismaByCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_QualifiedUsePrismaDateArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_QualifiedUsePrismaDateCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_QualifiedUsePrismaDurationArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_QualifiedUsePrismaDurationCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_QualifiedUsePrismaSupportArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_QualifiedUsePrismaSupportCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_RatingArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRatingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRatingWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_RatingCountArgs = {
  where?: InputMaybe<MetadataRatingWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ReferenceDateArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceDateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ReferenceDateCountArgs = {
  where?: InputMaybe<MetadataReferenceDateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ReferenceNumberArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceNumberWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ReferenceNumberCountArgs = {
  where?: InputMaybe<MetadataReferenceNumberWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ReferenceServiceArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceServiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceServiceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ReferenceServiceCountArgs = {
  where?: InputMaybe<MetadataReferenceServiceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ReleaseDateArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReleaseDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReleaseDateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ReleaseDateCountArgs = {
  where?: InputMaybe<MetadataReleaseDateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ReleaseTimeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReleaseTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReleaseTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ReleaseTimeCountArgs = {
  where?: InputMaybe<MetadataReleaseTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_RequiredPermissionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRequiredPermissionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRequiredPermissionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_RequiredPermissionCountArgs = {
  where?: InputMaybe<MetadataRequiredPermissionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ResolutionKindArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataResolutionKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataResolutionKindWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ResolutionKindCountArgs = {
  where?: InputMaybe<MetadataResolutionKindWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_RightCountArgs = {
  where?: InputMaybe<MetadataRightsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_RightsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRightsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_RoyaltyfreeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRoyaltyfreeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_RoyaltyfreeCountArgs = {
  where?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SceneArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSceneOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSceneWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SceneCountArgs = {
  where?: InputMaybe<MetadataSceneWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SectionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSectionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSectionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SectionCountArgs = {
  where?: InputMaybe<MetadataSectionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SentToArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSentToOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSentToWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SentToCountArgs = {
  where?: InputMaybe<MetadataSentToWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SerialNumberArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSerialNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSerialNumberWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SerialNumberCountArgs = {
  where?: InputMaybe<MetadataSerialNumberWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SeriesDateTimeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SeriesDateTimeCountArgs = {
  where?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SeriesDescriptionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SeriesDescriptionCountArgs = {
  where?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SeriesModalityArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesModalityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesModalityWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SeriesModalityCountArgs = {
  where?: InputMaybe<MetadataSeriesModalityWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SeriesNumberArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesNumberWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SeriesNumberCountArgs = {
  where?: InputMaybe<MetadataSeriesNumberWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ShortUniqueIdArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataShortUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataShortUniqueIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ShortUniqueIdCountArgs = {
  where?: InputMaybe<MetadataShortUniqueIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SourceArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSourceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSourceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SourceCountArgs = {
  where?: InputMaybe<MetadataSourceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_StateArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_StateCountArgs = {
  where?: InputMaybe<MetadataStateWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_StudyDateTimeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyDateTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_StudyDateTimeCountArgs = {
  where?: InputMaybe<MetadataStudyDateTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_StudyDescriptionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyDescriptionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_StudyDescriptionCountArgs = {
  where?: InputMaybe<MetadataStudyDescriptionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_StudyIdArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_StudyIdCountArgs = {
  where?: InputMaybe<MetadataStudyIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_StudyPhysicianArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyPhysicianOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyPhysicianWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_StudyPhysicianCountArgs = {
  where?: InputMaybe<MetadataStudyPhysicianWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SubjectArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SubjectCodeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectCodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectCodeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SubjectCodeCountArgs = {
  where?: InputMaybe<MetadataSubjectCodeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SubjectCountArgs = {
  where?: InputMaybe<MetadataSubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SubjectPrismaArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectPrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SubjectPrismaCountArgs = {
  where?: InputMaybe<MetadataSubjectPrismaWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SupplementalCategoriesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSupplementalCategoriesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_SupplementalCategoryCountArgs = {
  where?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TargetVersionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTargetVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTargetVersionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TargetVersionCountArgs = {
  where?: InputMaybe<MetadataTargetVersionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TitleArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTitleOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTitleWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TitleCountArgs = {
  where?: InputMaybe<MetadataTitleWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ToneCurveArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataToneCurveOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataToneCurveWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ToneCurveCountArgs = {
  where?: InputMaybe<MetadataToneCurveWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TransferredByArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TransferredByCountArgs = {
  where?: InputMaybe<MetadataTransferredByWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TransferredByEmailArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByEmailOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByEmailWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TransferredByEmailCountArgs = {
  where?: InputMaybe<MetadataTransferredByEmailWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TransferredByFullNameArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByFullNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TransferredByFullNameCountArgs = {
  where?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TransmissionReferenceArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransmissionReferenceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TransmissionReferenceCountArgs = {
  where?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TypeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTypeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_TypeCountArgs = {
  where?: InputMaybe<MetadataTypeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UndersubjectArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUndersubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUndersubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UndersubjectCountArgs = {
  where?: InputMaybe<MetadataUndersubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UnderUnderSubjectArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnderUnderSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UnderUnderSubjectCountArgs = {
  where?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UniqueIdArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUniqueIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UniqueIdCountArgs = {
  where?: InputMaybe<MetadataUniqueIdWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UnitArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnitOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnitWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UnitCountArgs = {
  where?: InputMaybe<MetadataUnitWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UnitShortNameArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnitShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnitShortNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UnitShortNameCountArgs = {
  where?: InputMaybe<MetadataUnitShortNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UploadedByArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadedByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadedByWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UploadedByCountArgs = {
  where?: InputMaybe<MetadataUploadedByWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UploadedByFullNameArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadedByFullNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UploadedByFullNameCountArgs = {
  where?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UploadTimeArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UploadTimeCountArgs = {
  where?: InputMaybe<MetadataUploadTimeWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UrgencyArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUrgencyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUrgencyWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UrgencyCountArgs = {
  where?: InputMaybe<MetadataUrgencyWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UsageTermCountArgs = {
  where?: InputMaybe<MetadataUsageTermsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UsageTermsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUsageTermsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUsageTermsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserCommentArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserCommentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserCommentWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserCommentCountArgs = {
  where?: InputMaybe<MetadataUserCommentWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined195Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined195OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined195WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined195CountArgs = {
  where?: InputMaybe<MetadataUserDefined195WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined237Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined237OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined237WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined237CountArgs = {
  where?: InputMaybe<MetadataUserDefined237WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined238Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined238OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined238WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined238CountArgs = {
  where?: InputMaybe<MetadataUserDefined238WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined239Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined239OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined239WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined239CountArgs = {
  where?: InputMaybe<MetadataUserDefined239WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined242Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined242OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined242WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined242CountArgs = {
  where?: InputMaybe<MetadataUserDefined242WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined62Args = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined62OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined62WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_UserDefined62CountArgs = {
  where?: InputMaybe<MetadataUserDefined62WhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ValidArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataValidOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataValidWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_ValidCountArgs = {
  where?: InputMaybe<MetadataValidWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_VersionArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataVersionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_VersionCountArgs = {
  where?: InputMaybe<MetadataVersionWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_WebStatementArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataWebStatementOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWebStatementWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_WebStatementCountArgs = {
  where?: InputMaybe<MetadataWebStatementWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_WorkflowKindArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataWorkflowKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWorkflowKindWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_WorkflowKindCountArgs = {
  where?: InputMaybe<MetadataWorkflowKindWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_XmpFileStampCountArgs = {
  where?: InputMaybe<MetadataXmpFileStampsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_XmpFileStampsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataXmpFileStampsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataXmpFileStampsWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_XmpHistoryArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataXmpHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataXmpHistoryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadata_XmpHistoryCountArgs = {
  where?: InputMaybe<MetadataXmpHistoryWhereInput>;
};


/** "Photo" resource's node */
export type PhotoMetadataByNameArgs = {
  names?: InputMaybe<Array<InputMaybe<MetadataNameEnumType>>>;
};


/** "Photo" resource's node */
export type PhotoPhotoMetadataCountArgs = {
  where?: InputMaybe<PhotoMetadataWhereInput>;
};


/** "Photo" resource's node */
export type PhotoPhotoMetadatasArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoMetadataOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoMetadataWhereInput>;
};


/** "Photo" resource's node */
export type PhotoPhotoPublicationCountArgs = {
  where?: InputMaybe<PhotoPublicationWhereInput>;
};


/** "Photo" resource's node */
export type PhotoPhotoPublicationsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoPublicationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoPublicationWhereInput>;
};

export type PhotoCreateInput = {
  /** Actions for the "Photo.agency" relation */
  agency?: InputMaybe<PhotoNestedAgencyCreateInput>;
  /** Actions for the "Photo.archive" relation */
  archive: PhotoNestedArchiveCreateInput;
  /** Actions for the "Photo.category" relation */
  category?: InputMaybe<PhotoNestedCategoryCreateInput>;
  /** asset content type */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.deliverySubject" relation */
  deliverySubject?: InputMaybe<PhotoNestedDeliverySubjectCreateInput>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The date of the photo's importation */
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Actions for the "Photo.importQueue" relation: "ImportQueue.photo"'s inverse relation */
  importQueue?: InputMaybe<PhotoNestedImportQueueCreateInput>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** Actions for the "Photo.Metadata_AccessRights" relation: "MetadataAccessRights.photo"'s inverse relation */
  Metadata_AccessRights?: InputMaybe<PhotoNestedMetadataAccessrightsCreateInput>;
  /** Actions for the "Photo.Metadata_AddlModelInfo" relation: "MetadataAddlModelInfo.photo"'s inverse relation */
  Metadata_AddlModelInfo?: InputMaybe<PhotoNestedMetadataAddlmodelinfoCreateInput>;
  /** Actions for the "Photo.Metadata_Archive_child" relation: "MetadataArchiveChild.photo"'s inverse relation */
  Metadata_Archive_child?: InputMaybe<PhotoNestedMetadataArchiveChildCreateInput>;
  /** Actions for the "Photo.Metadata_Archive_parent" relation: "MetadataArchiveParent.photo"'s inverse relation */
  Metadata_Archive_parent?: InputMaybe<PhotoNestedMetadataArchiveParentCreateInput>;
  /** Actions for the "Photo.Metadata_AssetState" relation: "MetadataAssetState.photo"'s inverse relation */
  Metadata_AssetState?: InputMaybe<PhotoNestedMetadataAssetstateCreateInput>;
  /** Actions for the "Photo.Metadata_attributionName" relation: "MetadataAttributionName.photo"'s inverse relation */
  Metadata_attributionName?: InputMaybe<PhotoNestedMetadataAttributionnameCreateInput>;
  /** Actions for the "Photo.Metadata_attributionURL" relation: "MetadataAttributionUrl.photo"'s inverse relation */
  Metadata_attributionURL?: InputMaybe<PhotoNestedMetadataAttributionurlCreateInput>;
  /** Actions for the "Photo.Metadata_Audience" relation: "MetadataAudience.photo"'s inverse relation */
  Metadata_Audience?: InputMaybe<PhotoNestedMetadataAudienceCreateInput>;
  /** Actions for the "Photo.Metadata_AuthorsPosition" relation: "MetadataAuthorsPosition.photo"'s inverse relation */
  Metadata_AuthorsPosition?: InputMaybe<PhotoNestedMetadataAuthorspositionCreateInput>;
  /** Actions for the "Photo.Metadata_BackupName" relation: "MetadataBackupName.photo"'s inverse relation */
  Metadata_BackupName?: InputMaybe<PhotoNestedMetadataBackupnameCreateInput>;
  /** Actions for the "Photo.Metadata_BitmapGrayscalePicture" relation: "MetadataBitmapGrayscalePicture.photo"'s inverse relation */
  Metadata_BitmapGrayscalePicture?: InputMaybe<PhotoNestedMetadataBitmapgrayscalepictureCreateInput>;
  /** Actions for the "Photo.Metadata_Brand" relation: "MetadataBrand.photo"'s inverse relation */
  Metadata_Brand?: InputMaybe<PhotoNestedMetadataBrandCreateInput>;
  /** Actions for the "Photo.Metadata_BrandPrisma" relation: "MetadataBrandPrisma.photo"'s inverse relation */
  Metadata_BrandPrisma?: InputMaybe<PhotoNestedMetadataBrandprismaCreateInput>;
  /** Actions for the "Photo.Metadata_BrandShortName" relation: "MetadataBrandShortName.photo"'s inverse relation */
  Metadata_BrandShortName?: InputMaybe<PhotoNestedMetadataBrandshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_CaptionWriter" relation: "MetadataCaptionWriter.photo"'s inverse relation */
  Metadata_CaptionWriter?: InputMaybe<PhotoNestedMetadataCaptionwriterCreateInput>;
  /** Actions for the "Photo.Metadata_Category" relation: "MetadataCategory.photo"'s inverse relation */
  Metadata_Category?: InputMaybe<PhotoNestedMetadataCategoryCreateInput>;
  /** Actions for the "Photo.Metadata_CategoryPrisma" relation: "MetadataCategoryPrisma.photo"'s inverse relation */
  Metadata_CategoryPrisma?: InputMaybe<PhotoNestedMetadataCategoryprismaCreateInput>;
  /** Actions for the "Photo.Metadata_Certificate" relation: "MetadataCertificate.photo"'s inverse relation */
  Metadata_Certificate?: InputMaybe<PhotoNestedMetadataCertificateCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCity" relation: "MetadataCiAdrCity.photo"'s inverse relation */
  Metadata_CiAdrCity?: InputMaybe<PhotoNestedMetadataCiadrcityCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCtry" relation: "MetadataCiAdrCtry.photo"'s inverse relation */
  Metadata_CiAdrCtry?: InputMaybe<PhotoNestedMetadataCiadrctryCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrExtadr" relation: "MetadataCiAdrExtadr.photo"'s inverse relation */
  Metadata_CiAdrExtadr?: InputMaybe<PhotoNestedMetadataCiadrextadrCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrPcode" relation: "MetadataCiAdrPcode.photo"'s inverse relation */
  Metadata_CiAdrPcode?: InputMaybe<PhotoNestedMetadataCiadrpcodeCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrRegion" relation: "MetadataCiAdrRegion.photo"'s inverse relation */
  Metadata_CiAdrRegion?: InputMaybe<PhotoNestedMetadataCiadrregionCreateInput>;
  /** Actions for the "Photo.Metadata_CiEmailWork" relation: "MetadataCiEmailWork.photo"'s inverse relation */
  Metadata_CiEmailWork?: InputMaybe<PhotoNestedMetadataCiemailworkCreateInput>;
  /** Actions for the "Photo.Metadata_CiTelWork" relation: "MetadataCiTelWork.photo"'s inverse relation */
  Metadata_CiTelWork?: InputMaybe<PhotoNestedMetadataCitelworkCreateInput>;
  /** Actions for the "Photo.Metadata_City" relation: "MetadataCity.photo"'s inverse relation */
  Metadata_City?: InputMaybe<PhotoNestedMetadataCityCreateInput>;
  /** Actions for the "Photo.Metadata_CiUrlWork" relation: "MetadataCiUrlWork.photo"'s inverse relation */
  Metadata_CiUrlWork?: InputMaybe<PhotoNestedMetadataCiurlworkCreateInput>;
  /** Actions for the "Photo.Metadata_Classify" relation: "MetadataClassify.photo"'s inverse relation */
  Metadata_Classify?: InputMaybe<PhotoNestedMetadataClassifyCreateInput>;
  /** Actions for the "Photo.Metadata_Company" relation: "MetadataCompany.photo"'s inverse relation */
  Metadata_Company?: InputMaybe<PhotoNestedMetadataCompanyCreateInput>;
  /** Actions for the "Photo.Metadata_CompanyShortName" relation: "MetadataCompanyShortName.photo"'s inverse relation */
  Metadata_CompanyShortName?: InputMaybe<PhotoNestedMetadataCompanyshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_Confidentiality" relation: "MetadataConfidentiality.photo"'s inverse relation */
  Metadata_Confidentiality?: InputMaybe<PhotoNestedMetadataConfidentialityCreateInput>;
  /** Actions for the "Photo.Metadata_ContainedIn" relation: "MetadataContainedIn.photo"'s inverse relation */
  Metadata_ContainedIn?: InputMaybe<PhotoNestedMetadataContainedinCreateInput>;
  /** Actions for the "Photo.Metadata_ContentMediaKind" relation: "MetadataContentMediaKind.photo"'s inverse relation */
  Metadata_ContentMediaKind?: InputMaybe<PhotoNestedMetadataContentmediakindCreateInput>;
  /** Actions for the "Photo.Metadata_ContentValue" relation: "MetadataContentValue.photo"'s inverse relation */
  Metadata_ContentValue?: InputMaybe<PhotoNestedMetadataContentvalueCreateInput>;
  /** Actions for the "Photo.Metadata_CopyrightLayer" relation: "MetadataCopyrightLayer.photo"'s inverse relation */
  Metadata_CopyrightLayer?: InputMaybe<PhotoNestedMetadataCopyrightlayerCreateInput>;
  /** Actions for the "Photo.Metadata_Country" relation: "MetadataCountry.photo"'s inverse relation */
  Metadata_Country?: InputMaybe<PhotoNestedMetadataCountryCreateInput>;
  /** Actions for the "Photo.Metadata_CountryCode" relation: "MetadataCountryCode.photo"'s inverse relation */
  Metadata_CountryCode?: InputMaybe<PhotoNestedMetadataCountrycodeCreateInput>;
  /** Actions for the "Photo.Metadata_CreatedTime" relation: "MetadataCreatedTime.photo"'s inverse relation */
  Metadata_CreatedTime?: InputMaybe<PhotoNestedMetadataCreatedtimeCreateInput>;
  /** Actions for the "Photo.Metadata_creator" relation: "MetadataCreator.photo"'s inverse relation */
  Metadata_creator?: InputMaybe<PhotoNestedMetadataCreatorCreateInput>;
  /** Actions for the "Photo.Metadata_CreatorTool" relation: "MetadataCreatorTool.photo"'s inverse relation */
  Metadata_CreatorTool?: InputMaybe<PhotoNestedMetadataCreatortoolCreateInput>;
  /** Actions for the "Photo.Metadata_Credit" relation: "MetadataCredit.photo"'s inverse relation */
  Metadata_Credit?: InputMaybe<PhotoNestedMetadataCreditCreateInput>;
  /** Actions for the "Photo.Metadata_CustomField14" relation: "MetadataCustomField14.photo"'s inverse relation */
  Metadata_CustomField14?: InputMaybe<PhotoNestedMetadataCustomfield14CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField15" relation: "MetadataCustomField15.photo"'s inverse relation */
  Metadata_CustomField15?: InputMaybe<PhotoNestedMetadataCustomfield15CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField16" relation: "MetadataCustomField16.photo"'s inverse relation */
  Metadata_CustomField16?: InputMaybe<PhotoNestedMetadataCustomfield16CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField17" relation: "MetadataCustomField17.photo"'s inverse relation */
  Metadata_CustomField17?: InputMaybe<PhotoNestedMetadataCustomfield17CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField20" relation: "MetadataCustomField20.photo"'s inverse relation */
  Metadata_CustomField20?: InputMaybe<PhotoNestedMetadataCustomfield20CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField5" relation: "MetadataCustomField5.photo"'s inverse relation */
  Metadata_CustomField5?: InputMaybe<PhotoNestedMetadataCustomfield5CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField6" relation: "MetadataCustomField6.photo"'s inverse relation */
  Metadata_CustomField6?: InputMaybe<PhotoNestedMetadataCustomfield6CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField7" relation: "MetadataCustomField7.photo"'s inverse relation */
  Metadata_CustomField7?: InputMaybe<PhotoNestedMetadataCustomfield7CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField9" relation: "MetadataCustomField9.photo"'s inverse relation */
  Metadata_CustomField9?: InputMaybe<PhotoNestedMetadataCustomfield9CreateInput>;
  /** Actions for the "Photo.Metadata_DateCreated" relation: "MetadataDateCreated.photo"'s inverse relation */
  Metadata_DateCreated?: InputMaybe<PhotoNestedMetadataDatecreatedCreateInput>;
  /** Actions for the "Photo.Metadata_DateTimeDigitized" relation: "MetadataDateTimeDigitized.photo"'s inverse relation */
  Metadata_DateTimeDigitized?: InputMaybe<PhotoNestedMetadataDatetimedigitizedCreateInput>;
  /** Actions for the "Photo.Metadata_DateTimeOriginal" relation: "MetadataDateTimeOriginal.photo"'s inverse relation */
  Metadata_DateTimeOriginal?: InputMaybe<PhotoNestedMetadataDatetimeoriginalCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryAccount" relation: "MetadataDeliveryAccount.photo"'s inverse relation */
  Metadata_DeliveryAccount?: InputMaybe<PhotoNestedMetadataDeliveryaccountCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCompany" relation: "MetadataDeliveryCompany.photo"'s inverse relation */
  Metadata_DeliveryCompany?: InputMaybe<PhotoNestedMetadataDeliverycompanyCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCopyright" relation: "MetadataDeliveryCopyright.photo"'s inverse relation */
  Metadata_DeliveryCopyright?: InputMaybe<PhotoNestedMetadataDeliverycopyrightCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateFolder" relation: "MetadataDeliveryDateFolder.photo"'s inverse relation */
  Metadata_DeliveryDateFolder?: InputMaybe<PhotoNestedMetadataDeliverydatefolderCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateTime" relation: "MetadataDeliveryDateTime.photo"'s inverse relation */
  Metadata_DeliveryDateTime?: InputMaybe<PhotoNestedMetadataDeliverydatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcCreator" relation: "MetadataDeliveryDcCreator.photo"'s inverse relation */
  Metadata_DeliveryDcCreator?: InputMaybe<PhotoNestedMetadataDeliverydccreatorCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcRights" relation: "MetadataDeliveryDcRights.photo"'s inverse relation */
  Metadata_DeliveryDcRights?: InputMaybe<PhotoNestedMetadataDeliverydcrightsCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFileType" relation: "MetadataDeliveryFileType.photo"'s inverse relation */
  Metadata_DeliveryFileType?: InputMaybe<PhotoNestedMetadataDeliveryfiletypeCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFolder" relation: "MetadataDeliveryFolder.photo"'s inverse relation */
  Metadata_DeliveryFolder?: InputMaybe<PhotoNestedMetadataDeliveryfolderCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryKind" relation: "MetadataDeliveryKind.photo"'s inverse relation */
  Metadata_DeliveryKind?: InputMaybe<PhotoNestedMetadataDeliverykindCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPath" relation: "MetadataDeliveryPath.photo"'s inverse relation */
  Metadata_DeliveryPath?: InputMaybe<PhotoNestedMetadataDeliverypathCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPersonShownintheImage" relation: "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation */
  Metadata_DeliveryPersonShownintheImage?: InputMaybe<PhotoNestedMetadataDeliverypersonshownintheimageCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopCredit" relation: "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopCredit?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopcreditCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopSource" relation: "MetadataDeliveryPhotoshopSource.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopSource?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopsourceCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryService" relation: "MetadataDeliveryService.photo"'s inverse relation */
  Metadata_DeliveryService?: InputMaybe<PhotoNestedMetadataDeliveryserviceCreateInput>;
  /** Actions for the "Photo.Metadata_DeliverySubject" relation: "MetadataDeliverySubject.photo"'s inverse relation */
  Metadata_DeliverySubject?: InputMaybe<PhotoNestedMetadataDeliverysubjectCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryUnderSubject" relation: "MetadataDeliveryUnderSubject.photo"'s inverse relation */
  Metadata_DeliveryUnderSubject?: InputMaybe<PhotoNestedMetadataDeliveryundersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_Department" relation: "MetadataDepartment.photo"'s inverse relation */
  Metadata_Department?: InputMaybe<PhotoNestedMetadataDepartmentCreateInput>;
  /** Actions for the "Photo.Metadata_description" relation: "MetadataDescription.photo"'s inverse relation */
  Metadata_description?: InputMaybe<PhotoNestedMetadataDescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_DigitalAssetURL" relation: "MetadataDigitalAssetUrl.photo"'s inverse relation */
  Metadata_DigitalAssetURL?: InputMaybe<PhotoNestedMetadataDigitalasseturlCreateInput>;
  /** Actions for the "Photo.Metadata_Division" relation: "MetadataDivision.photo"'s inverse relation */
  Metadata_Division?: InputMaybe<PhotoNestedMetadataDivisionCreateInput>;
  /** Actions for the "Photo.Metadata_DocumentText" relation: "MetadataDocumentText.photo"'s inverse relation */
  Metadata_DocumentText?: InputMaybe<PhotoNestedMetadataDocumenttextCreateInput>;
  /** Actions for the "Photo.Metadata_Edition" relation: "MetadataEdition.photo"'s inverse relation */
  Metadata_Edition?: InputMaybe<PhotoNestedMetadataEditionCreateInput>;
  /** Actions for the "Photo.Metadata_EditorialVersion" relation: "MetadataEditorialVersion.photo"'s inverse relation */
  Metadata_EditorialVersion?: InputMaybe<PhotoNestedMetadataEditorialversionCreateInput>;
  /** Actions for the "Photo.Metadata_EditStatus" relation: "MetadataEditStatus.photo"'s inverse relation */
  Metadata_EditStatus?: InputMaybe<PhotoNestedMetadataEditstatusCreateInput>;
  /** Actions for the "Photo.Metadata_EnvironnementPhoto" relation: "MetadataEnvironnementPhoto.photo"'s inverse relation */
  Metadata_EnvironnementPhoto?: InputMaybe<PhotoNestedMetadataEnvironnementphotoCreateInput>;
  /** Actions for the "Photo.Metadata_EquipmentInstitution" relation: "MetadataEquipmentInstitution.photo"'s inverse relation */
  Metadata_EquipmentInstitution?: InputMaybe<PhotoNestedMetadataEquipmentinstitutionCreateInput>;
  /** Actions for the "Photo.Metadata_EquipmentManufacturer" relation: "MetadataEquipmentManufacturer.photo"'s inverse relation */
  Metadata_EquipmentManufacturer?: InputMaybe<PhotoNestedMetadataEquipmentmanufacturerCreateInput>;
  /** Actions for the "Photo.Metadata_Event" relation: "MetadataEvent.photo"'s inverse relation */
  Metadata_Event?: InputMaybe<PhotoNestedMetadataEventCreateInput>;
  /** Actions for the "Photo.Metadata_Exif" relation: "MetadataExif.photo"'s inverse relation */
  Metadata_Exif?: InputMaybe<PhotoNestedMetadataExifCreateInput>;
  /** Actions for the "Photo.Metadata_FabStorage" relation: "MetadataFabStorage.photo"'s inverse relation */
  Metadata_FabStorage?: InputMaybe<PhotoNestedMetadataFabstorageCreateInput>;
  /** Actions for the "Photo.Metadata_FileCheckFor" relation: "MetadataFileCheckFor.photo"'s inverse relation */
  Metadata_FileCheckFor?: InputMaybe<PhotoNestedMetadataFilecheckforCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoBitsPerPixel" relation: "MetadataFileInfoBitsPerPixel.photo"'s inverse relation */
  Metadata_FileInfoBitsPerPixel?: InputMaybe<PhotoNestedMetadataFileinfobitsperpixelCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoFrames" relation: "MetadataFileInfoFrames.photo"'s inverse relation */
  Metadata_FileInfoFrames?: InputMaybe<PhotoNestedMetadataFileinfoframesCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoPhotometricInterpretation" relation: "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation */
  Metadata_FileInfoPhotometricInterpretation?: InputMaybe<PhotoNestedMetadataFileinfophotometricinterpretationCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoTransferSyntax" relation: "MetadataFileInfoTransferSyntax.photo"'s inverse relation */
  Metadata_FileInfoTransferSyntax?: InputMaybe<PhotoNestedMetadataFileinfotransfersyntaxCreateInput>;
  /** Actions for the "Photo.Metadata_FileStage" relation: "MetadataFileStage.photo"'s inverse relation */
  Metadata_FileStage?: InputMaybe<PhotoNestedMetadataFilestageCreateInput>;
  /** Actions for the "Photo.Metadata_FileStatus" relation: "MetadataFileStatus.photo"'s inverse relation */
  Metadata_FileStatus?: InputMaybe<PhotoNestedMetadataFilestatusCreateInput>;
  /** Actions for the "Photo.Metadata_FileType" relation: "MetadataFileType.photo"'s inverse relation */
  Metadata_FileType?: InputMaybe<PhotoNestedMetadataFiletypeCreateInput>;
  /** Actions for the "Photo.Metadata_FixtureIdentifier" relation: "MetadataFixtureIdentifier.photo"'s inverse relation */
  Metadata_FixtureIdentifier?: InputMaybe<PhotoNestedMetadataFixtureidentifierCreateInput>;
  /** Actions for the "Photo.Metadata_Format" relation: "MetadataFormat.photo"'s inverse relation */
  Metadata_Format?: InputMaybe<PhotoNestedMetadataFormatCreateInput>;
  /** Actions for the "Photo.Metadata_GPSLatitude" relation: "MetadataGpsLatitude.photo"'s inverse relation */
  Metadata_GPSLatitude?: InputMaybe<PhotoNestedMetadataGpslatitudeCreateInput>;
  /** Actions for the "Photo.Metadata_GPSLongitude" relation: "MetadataGpsLongitude.photo"'s inverse relation */
  Metadata_GPSLongitude?: InputMaybe<PhotoNestedMetadataGpslongitudeCreateInput>;
  /** Actions for the "Photo.Metadata_GPSProcessingMethod" relation: "MetadataGpsProcessingMethod.photo"'s inverse relation */
  Metadata_GPSProcessingMethod?: InputMaybe<PhotoNestedMetadataGpsprocessingmethodCreateInput>;
  /** Actions for the "Photo.Metadata_Header" relation: "MetadataHeader.photo"'s inverse relation */
  Metadata_Header?: InputMaybe<PhotoNestedMetadataHeaderCreateInput>;
  /** Actions for the "Photo.Metadata_HeaderPrisma" relation: "MetadataHeaderPrisma.photo"'s inverse relation */
  Metadata_HeaderPrisma?: InputMaybe<PhotoNestedMetadataHeaderprismaCreateInput>;
  /** Actions for the "Photo.Metadata_Headline" relation: "MetadataHeadline.photo"'s inverse relation */
  Metadata_Headline?: InputMaybe<PhotoNestedMetadataHeadlineCreateInput>;
  /** Actions for the "Photo.Metadata_History" relation: "MetadataHistory.photo"'s inverse relation */
  Metadata_History?: InputMaybe<PhotoNestedMetadataHistoryCreateInput>;
  /** Actions for the "Photo.Metadata_ICCProfile" relation: "MetadataIccProfile.photo"'s inverse relation */
  Metadata_ICCProfile?: InputMaybe<PhotoNestedMetadataIccprofileCreateInput>;
  /** Actions for the "Photo.Metadata_ID_subject" relation: "MetadataIdSubject.photo"'s inverse relation */
  Metadata_ID_subject?: InputMaybe<PhotoNestedMetadataIdSubjectCreateInput>;
  /** Actions for the "Photo.Metadata_IdAssignment" relation: "MetadataIdAssignment.photo"'s inverse relation */
  Metadata_IdAssignment?: InputMaybe<PhotoNestedMetadataIdassignmentCreateInput>;
  /** Actions for the "Photo.Metadata_IdMediaContent" relation: "MetadataIdMediaContent.photo"'s inverse relation */
  Metadata_IdMediaContent?: InputMaybe<PhotoNestedMetadataIdmediacontentCreateInput>;
  /** Actions for the "Photo.Metadata_IdPhotoPrisma" relation: "MetadataIdPhotoPrisma.photo"'s inverse relation */
  Metadata_IdPhotoPrisma?: InputMaybe<PhotoNestedMetadataIdphotoprismaCreateInput>;
  /** Actions for the "Photo.Metadata_ImageNotes" relation: "MetadataImageNotes.photo"'s inverse relation */
  Metadata_ImageNotes?: InputMaybe<PhotoNestedMetadataImagenotesCreateInput>;
  /** Actions for the "Photo.Metadata_ImageUniqueID" relation: "MetadataImageUniqueId.photo"'s inverse relation */
  Metadata_ImageUniqueID?: InputMaybe<PhotoNestedMetadataImageuniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Instructions" relation: "MetadataInstructions.photo"'s inverse relation */
  Metadata_Instructions?: InputMaybe<PhotoNestedMetadataInstructionsCreateInput>;
  /** Actions for the "Photo.Metadata_IntellectualGenre" relation: "MetadataIntellectualGenre.photo"'s inverse relation */
  Metadata_IntellectualGenre?: InputMaybe<PhotoNestedMetadataIntellectualgenreCreateInput>;
  /** Actions for the "Photo.Metadata_ISOSpeedRatings" relation: "MetadataIsoSpeedRatings.photo"'s inverse relation */
  Metadata_ISOSpeedRatings?: InputMaybe<PhotoNestedMetadataIsospeedratingsCreateInput>;
  /** Actions for the "Photo.Metadata_Issue" relation: "MetadataIssue.photo"'s inverse relation */
  Metadata_Issue?: InputMaybe<PhotoNestedMetadataIssueCreateInput>;
  /** Actions for the "Photo.Metadata_JobId" relation: "MetadataJobId.photo"'s inverse relation */
  Metadata_JobId?: InputMaybe<PhotoNestedMetadataJobidCreateInput>;
  /** Actions for the "Photo.Metadata_JobProcessing" relation: "MetadataJobProcessing.photo"'s inverse relation */
  Metadata_JobProcessing?: InputMaybe<PhotoNestedMetadataJobprocessingCreateInput>;
  /** Actions for the "Photo.Metadata_LayoutPictureEditing" relation: "MetadataLayoutPictureEditing.photo"'s inverse relation */
  Metadata_LayoutPictureEditing?: InputMaybe<PhotoNestedMetadataLayoutpictureeditingCreateInput>;
  /** Actions for the "Photo.Metadata_LayoutStorage" relation: "MetadataLayoutStorage.photo"'s inverse relation */
  Metadata_LayoutStorage?: InputMaybe<PhotoNestedMetadataLayoutstorageCreateInput>;
  /** Actions for the "Photo.Metadata_license" relation: "MetadataLicense.photo"'s inverse relation */
  Metadata_license?: InputMaybe<PhotoNestedMetadataLicenseCreateInput>;
  /** Actions for the "Photo.Metadata_Links" relation: "MetadataLinks.photo"'s inverse relation */
  Metadata_Links?: InputMaybe<PhotoNestedMetadataLinksCreateInput>;
  /** Actions for the "Photo.Metadata_LocalCaption" relation: "MetadataLocalCaption.photo"'s inverse relation */
  Metadata_LocalCaption?: InputMaybe<PhotoNestedMetadataLocalcaptionCreateInput>;
  /** Actions for the "Photo.Metadata_Location" relation: "MetadataLocation.photo"'s inverse relation */
  Metadata_Location?: InputMaybe<PhotoNestedMetadataLocationCreateInput>;
  /** Actions for the "Photo.Metadata_Make" relation: "MetadataMake.photo"'s inverse relation */
  Metadata_Make?: InputMaybe<PhotoNestedMetadataMakeCreateInput>;
  /** Actions for the "Photo.Metadata_Manifest" relation: "MetadataManifest.photo"'s inverse relation */
  Metadata_Manifest?: InputMaybe<PhotoNestedMetadataManifestCreateInput>;
  /** Actions for the "Photo.Metadata_Marked" relation: "MetadataMarked.photo"'s inverse relation */
  Metadata_Marked?: InputMaybe<PhotoNestedMetadataMarkedCreateInput>;
  /** Actions for the "Photo.Metadata_MasterDocumentId" relation: "MetadataMasterDocumentId.photo"'s inverse relation */
  Metadata_MasterDocumentId?: InputMaybe<PhotoNestedMetadataMasterdocumentidCreateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailHeight" relation: "MetadataMaxAvailHeight.photo"'s inverse relation */
  Metadata_MaxAvailHeight?: InputMaybe<PhotoNestedMetadataMaxavailheightCreateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailWidth" relation: "MetadataMaxAvailWidth.photo"'s inverse relation */
  Metadata_MaxAvailWidth?: InputMaybe<PhotoNestedMetadataMaxavailwidthCreateInput>;
  /** Actions for the "Photo.Metadata_MinorModelAgeDisclosure" relation: "MetadataMinorModelAgeDisclosure.photo"'s inverse relation */
  Metadata_MinorModelAgeDisclosure?: InputMaybe<PhotoNestedMetadataMinormodelagedisclosureCreateInput>;
  /** Actions for the "Photo.Metadata_Model" relation: "MetadataModel.photo"'s inverse relation */
  Metadata_Model?: InputMaybe<PhotoNestedMetadataModelCreateInput>;
  /** Actions for the "Photo.Metadata_ModelAge" relation: "MetadataModelAge.photo"'s inverse relation */
  Metadata_ModelAge?: InputMaybe<PhotoNestedMetadataModelageCreateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseID" relation: "MetadataModelReleaseId.photo"'s inverse relation */
  Metadata_ModelReleaseID?: InputMaybe<PhotoNestedMetadataModelreleaseidCreateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseStatus" relation: "MetadataModelReleaseStatus.photo"'s inverse relation */
  Metadata_ModelReleaseStatus?: InputMaybe<PhotoNestedMetadataModelreleasestatusCreateInput>;
  /** Actions for the "Photo.Metadata_morePermissions" relation: "MetadataMorePermissions.photo"'s inverse relation */
  Metadata_morePermissions?: InputMaybe<PhotoNestedMetadataMorepermissionsCreateInput>;
  /** Actions for the "Photo.Metadata_ObjectCycle" relation: "MetadataObjectCycle.photo"'s inverse relation */
  Metadata_ObjectCycle?: InputMaybe<PhotoNestedMetadataObjectcycleCreateInput>;
  /** Actions for the "Photo.Metadata_OrganisationInImageName" relation: "MetadataOrganisationInImageName.photo"'s inverse relation */
  Metadata_OrganisationInImageName?: InputMaybe<PhotoNestedMetadataOrganisationinimagenameCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalColor" relation: "MetadataOriginalColor.photo"'s inverse relation */
  Metadata_OriginalColor?: InputMaybe<PhotoNestedMetadataOriginalcolorCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalICCProfile" relation: "MetadataOriginalIccProfile.photo"'s inverse relation */
  Metadata_OriginalICCProfile?: InputMaybe<PhotoNestedMetadataOriginaliccprofileCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalName" relation: "MetadataOriginalName.photo"'s inverse relation */
  Metadata_OriginalName?: InputMaybe<PhotoNestedMetadataOriginalnameCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalWeigth" relation: "MetadataOriginalWeigth.photo"'s inverse relation */
  Metadata_OriginalWeigth?: InputMaybe<PhotoNestedMetadataOriginalweigthCreateInput>;
  /** Actions for the "Photo.Metadata_Owner" relation: "MetadataOwner.photo"'s inverse relation */
  Metadata_Owner?: InputMaybe<PhotoNestedMetadataOwnerCreateInput>;
  /** Actions for the "Photo.Metadata_OwnerId" relation: "MetadataOwnerId.photo"'s inverse relation */
  Metadata_OwnerId?: InputMaybe<PhotoNestedMetadataOwneridCreateInput>;
  /** Actions for the "Photo.Metadata_Page" relation: "MetadataPage.photo"'s inverse relation */
  Metadata_Page?: InputMaybe<PhotoNestedMetadataPageCreateInput>;
  /** Actions for the "Photo.Metadata_PagePrisma" relation: "MetadataPagePrisma.photo"'s inverse relation */
  Metadata_PagePrisma?: InputMaybe<PhotoNestedMetadataPageprismaCreateInput>;
  /** Actions for the "Photo.Metadata_PaintBasedCorrections" relation: "MetadataPaintBasedCorrections.photo"'s inverse relation */
  Metadata_PaintBasedCorrections?: InputMaybe<PhotoNestedMetadataPaintbasedcorrectionsCreateInput>;
  /** Actions for the "Photo.Metadata_PatientDOB" relation: "MetadataPatientDob.photo"'s inverse relation */
  Metadata_PatientDOB?: InputMaybe<PhotoNestedMetadataPatientdobCreateInput>;
  /** Actions for the "Photo.Metadata_PatientID" relation: "MetadataPatientId.photo"'s inverse relation */
  Metadata_PatientID?: InputMaybe<PhotoNestedMetadataPatientidCreateInput>;
  /** Actions for the "Photo.Metadata_PatientName" relation: "MetadataPatientName.photo"'s inverse relation */
  Metadata_PatientName?: InputMaybe<PhotoNestedMetadataPatientnameCreateInput>;
  /** Actions for the "Photo.Metadata_PatientSex" relation: "MetadataPatientSex.photo"'s inverse relation */
  Metadata_PatientSex?: InputMaybe<PhotoNestedMetadataPatientsexCreateInput>;
  /** Actions for the "Photo.Metadata_PersonInImage" relation: "MetadataPersonInImage.photo"'s inverse relation */
  Metadata_PersonInImage?: InputMaybe<PhotoNestedMetadataPersoninimageCreateInput>;
  /** Actions for the "Photo.Metadata_PhotoStorage" relation: "MetadataPhotoStorage.photo"'s inverse relation */
  Metadata_PhotoStorage?: InputMaybe<PhotoNestedMetadataPhotostorageCreateInput>;
  /** Actions for the "Photo.Metadata_PrepressPictureEditing" relation: "MetadataPrepressPictureEditing.photo"'s inverse relation */
  Metadata_PrepressPictureEditing?: InputMaybe<PhotoNestedMetadataPrepresspictureeditingCreateInput>;
  /** Actions for the "Photo.Metadata_PriceLevel" relation: "MetadataPriceLevel.photo"'s inverse relation */
  Metadata_PriceLevel?: InputMaybe<PhotoNestedMetadataPricelevelCreateInput>;
  /** Actions for the "Photo.Metadata_PrintingProfile" relation: "MetadataPrintingProfile.photo"'s inverse relation */
  Metadata_PrintingProfile?: InputMaybe<PhotoNestedMetadataPrintingprofileCreateInput>;
  /** Actions for the "Photo.Metadata_PrismaProduction" relation: "MetadataPrismaProduction.photo"'s inverse relation */
  Metadata_PrismaProduction?: InputMaybe<PhotoNestedMetadataPrismaproductionCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessHistory" relation: "MetadataProcessHistory.photo"'s inverse relation */
  Metadata_ProcessHistory?: InputMaybe<PhotoNestedMetadataProcesshistoryCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessParameter" relation: "MetadataProcessParameter.photo"'s inverse relation */
  Metadata_ProcessParameter?: InputMaybe<PhotoNestedMetadataProcessparameterCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessStatus" relation: "MetadataProcessStatus.photo"'s inverse relation */
  Metadata_ProcessStatus?: InputMaybe<PhotoNestedMetadataProcessstatusCreateInput>;
  /** Actions for the "Photo.Metadata_Product" relation: "MetadataProduct.photo"'s inverse relation */
  Metadata_Product?: InputMaybe<PhotoNestedMetadataProductCreateInput>;
  /** Actions for the "Photo.Metadata_Products" relation: "MetadataProducts.photo"'s inverse relation */
  Metadata_Products?: InputMaybe<PhotoNestedMetadataProductsCreateInput>;
  /** Actions for the "Photo.Metadata_ProductShortName" relation: "MetadataProductShortName.photo"'s inverse relation */
  Metadata_ProductShortName?: InputMaybe<PhotoNestedMetadataProductshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_Produits" relation: "MetadataProduits.photo"'s inverse relation */
  Metadata_Produits?: InputMaybe<PhotoNestedMetadataProduitsCreateInput>;
  /** Actions for the "Photo.Metadata_ProgramVersion" relation: "MetadataProgramVersion.photo"'s inverse relation */
  Metadata_ProgramVersion?: InputMaybe<PhotoNestedMetadataProgramversionCreateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseID" relation: "MetadataPropertyReleaseId.photo"'s inverse relation */
  Metadata_PropertyReleaseID?: InputMaybe<PhotoNestedMetadataPropertyreleaseidCreateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseStatus" relation: "MetadataPropertyReleaseStatus.photo"'s inverse relation */
  Metadata_PropertyReleaseStatus?: InputMaybe<PhotoNestedMetadataPropertyreleasestatusCreateInput>;
  /** Actions for the "Photo.Metadata_Publisher" relation: "MetadataPublisher.photo"'s inverse relation */
  Metadata_Publisher?: InputMaybe<PhotoNestedMetadataPublisherCreateInput>;
  /** Actions for the "Photo.Metadata_publishingIssue" relation: "MetadataPublishingIssue.photo"'s inverse relation */
  Metadata_publishingIssue?: InputMaybe<PhotoNestedMetadataPublishingissueCreateInput>;
  /** Actions for the "Photo.Metadata_publishingSubject" relation: "MetadataPublishingSubject.photo"'s inverse relation */
  Metadata_publishingSubject?: InputMaybe<PhotoNestedMetadataPublishingsubjectCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaBy" relation: "MetadataQualifiedUsePrismaBy.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaBy?: InputMaybe<PhotoNestedMetadataQualifieduseprismabyCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDate" relation: "MetadataQualifiedUsePrismaDate.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDate?: InputMaybe<PhotoNestedMetadataQualifieduseprismadateCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDuration" relation: "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDuration?: InputMaybe<PhotoNestedMetadataQualifieduseprismadurationCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaSupport" relation: "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaSupport?: InputMaybe<PhotoNestedMetadataQualifieduseprismasupportCreateInput>;
  /** Actions for the "Photo.Metadata_Rating" relation: "MetadataRating.photo"'s inverse relation */
  Metadata_Rating?: InputMaybe<PhotoNestedMetadataRatingCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceDate" relation: "MetadataReferenceDate.photo"'s inverse relation */
  Metadata_ReferenceDate?: InputMaybe<PhotoNestedMetadataReferencedateCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceNumber" relation: "MetadataReferenceNumber.photo"'s inverse relation */
  Metadata_ReferenceNumber?: InputMaybe<PhotoNestedMetadataReferencenumberCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceService" relation: "MetadataReferenceService.photo"'s inverse relation */
  Metadata_ReferenceService?: InputMaybe<PhotoNestedMetadataReferenceserviceCreateInput>;
  /** Actions for the "Photo.Metadata_ReleaseDate" relation: "MetadataReleaseDate.photo"'s inverse relation */
  Metadata_ReleaseDate?: InputMaybe<PhotoNestedMetadataReleasedateCreateInput>;
  /** Actions for the "Photo.Metadata_ReleaseTime" relation: "MetadataReleaseTime.photo"'s inverse relation */
  Metadata_ReleaseTime?: InputMaybe<PhotoNestedMetadataReleasetimeCreateInput>;
  /** Actions for the "Photo.Metadata_RequiredPermission" relation: "MetadataRequiredPermission.photo"'s inverse relation */
  Metadata_RequiredPermission?: InputMaybe<PhotoNestedMetadataRequiredpermissionCreateInput>;
  /** Actions for the "Photo.Metadata_ResolutionKind" relation: "MetadataResolutionKind.photo"'s inverse relation */
  Metadata_ResolutionKind?: InputMaybe<PhotoNestedMetadataResolutionkindCreateInput>;
  /** Actions for the "Photo.Metadata_rights" relation: "MetadataRights.photo"'s inverse relation */
  Metadata_rights?: InputMaybe<PhotoNestedMetadataRightsCreateInput>;
  /** Actions for the "Photo.Metadata_Royaltyfree" relation: "MetadataRoyaltyfree.photo"'s inverse relation */
  Metadata_Royaltyfree?: InputMaybe<PhotoNestedMetadataRoyaltyfreeCreateInput>;
  /** Actions for the "Photo.Metadata_Scene" relation: "MetadataScene.photo"'s inverse relation */
  Metadata_Scene?: InputMaybe<PhotoNestedMetadataSceneCreateInput>;
  /** Actions for the "Photo.Metadata_Section" relation: "MetadataSection.photo"'s inverse relation */
  Metadata_Section?: InputMaybe<PhotoNestedMetadataSectionCreateInput>;
  /** Actions for the "Photo.Metadata_SentTo" relation: "MetadataSentTo.photo"'s inverse relation */
  Metadata_SentTo?: InputMaybe<PhotoNestedMetadataSenttoCreateInput>;
  /** Actions for the "Photo.Metadata_SerialNumber" relation: "MetadataSerialNumber.photo"'s inverse relation */
  Metadata_SerialNumber?: InputMaybe<PhotoNestedMetadataSerialnumberCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesDateTime" relation: "MetadataSeriesDateTime.photo"'s inverse relation */
  Metadata_SeriesDateTime?: InputMaybe<PhotoNestedMetadataSeriesdatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesDescription" relation: "MetadataSeriesDescription.photo"'s inverse relation */
  Metadata_SeriesDescription?: InputMaybe<PhotoNestedMetadataSeriesdescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesModality" relation: "MetadataSeriesModality.photo"'s inverse relation */
  Metadata_SeriesModality?: InputMaybe<PhotoNestedMetadataSeriesmodalityCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesNumber" relation: "MetadataSeriesNumber.photo"'s inverse relation */
  Metadata_SeriesNumber?: InputMaybe<PhotoNestedMetadataSeriesnumberCreateInput>;
  /** Actions for the "Photo.Metadata_ShortUniqueId" relation: "MetadataShortUniqueId.photo"'s inverse relation */
  Metadata_ShortUniqueId?: InputMaybe<PhotoNestedMetadataShortuniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Source" relation: "MetadataSource.photo"'s inverse relation */
  Metadata_Source?: InputMaybe<PhotoNestedMetadataSourceCreateInput>;
  /** Actions for the "Photo.Metadata_State" relation: "MetadataState.photo"'s inverse relation */
  Metadata_State?: InputMaybe<PhotoNestedMetadataStateCreateInput>;
  /** Actions for the "Photo.Metadata_StudyDateTime" relation: "MetadataStudyDateTime.photo"'s inverse relation */
  Metadata_StudyDateTime?: InputMaybe<PhotoNestedMetadataStudydatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_StudyDescription" relation: "MetadataStudyDescription.photo"'s inverse relation */
  Metadata_StudyDescription?: InputMaybe<PhotoNestedMetadataStudydescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_StudyID" relation: "MetadataStudyId.photo"'s inverse relation */
  Metadata_StudyID?: InputMaybe<PhotoNestedMetadataStudyidCreateInput>;
  /** Actions for the "Photo.Metadata_StudyPhysician" relation: "MetadataStudyPhysician.photo"'s inverse relation */
  Metadata_StudyPhysician?: InputMaybe<PhotoNestedMetadataStudyphysicianCreateInput>;
  /** Actions for the "Photo.Metadata_subject" relation: "MetadataSubject.photo"'s inverse relation */
  Metadata_subject?: InputMaybe<PhotoNestedMetadataSubjectCreateInput>;
  /** Actions for the "Photo.Metadata_SubjectCode" relation: "MetadataSubjectCode.photo"'s inverse relation */
  Metadata_SubjectCode?: InputMaybe<PhotoNestedMetadataSubjectcodeCreateInput>;
  /** Actions for the "Photo.Metadata_SubjectPrisma" relation: "MetadataSubjectPrisma.photo"'s inverse relation */
  Metadata_SubjectPrisma?: InputMaybe<PhotoNestedMetadataSubjectprismaCreateInput>;
  /** Actions for the "Photo.Metadata_SupplementalCategories" relation: "MetadataSupplementalCategories.photo"'s inverse relation */
  Metadata_SupplementalCategories?: InputMaybe<PhotoNestedMetadataSupplementalcategoriesCreateInput>;
  /** Actions for the "Photo.Metadata_TargetVersion" relation: "MetadataTargetVersion.photo"'s inverse relation */
  Metadata_TargetVersion?: InputMaybe<PhotoNestedMetadataTargetversionCreateInput>;
  /** Actions for the "Photo.Metadata_title" relation: "MetadataTitle.photo"'s inverse relation */
  Metadata_title?: InputMaybe<PhotoNestedMetadataTitleCreateInput>;
  /** Actions for the "Photo.Metadata_ToneCurve" relation: "MetadataToneCurve.photo"'s inverse relation */
  Metadata_ToneCurve?: InputMaybe<PhotoNestedMetadataTonecurveCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredBy" relation: "MetadataTransferredBy.photo"'s inverse relation */
  Metadata_TransferredBy?: InputMaybe<PhotoNestedMetadataTransferredbyCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredByEmail" relation: "MetadataTransferredByEmail.photo"'s inverse relation */
  Metadata_TransferredByEmail?: InputMaybe<PhotoNestedMetadataTransferredbyemailCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredByFullName" relation: "MetadataTransferredByFullName.photo"'s inverse relation */
  Metadata_TransferredByFullName?: InputMaybe<PhotoNestedMetadataTransferredbyfullnameCreateInput>;
  /** Actions for the "Photo.Metadata_TransmissionReference" relation: "MetadataTransmissionReference.photo"'s inverse relation */
  Metadata_TransmissionReference?: InputMaybe<PhotoNestedMetadataTransmissionreferenceCreateInput>;
  /** Actions for the "Photo.Metadata_Type" relation: "MetadataType.photo"'s inverse relation */
  Metadata_Type?: InputMaybe<PhotoNestedMetadataTypeCreateInput>;
  /** Actions for the "Photo.Metadata_Undersubject" relation: "MetadataUndersubject.photo"'s inverse relation */
  Metadata_Undersubject?: InputMaybe<PhotoNestedMetadataUndersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_UnderUnderSubject" relation: "MetadataUnderUnderSubject.photo"'s inverse relation */
  Metadata_UnderUnderSubject?: InputMaybe<PhotoNestedMetadataUnderundersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_UniqueId" relation: "MetadataUniqueId.photo"'s inverse relation */
  Metadata_UniqueId?: InputMaybe<PhotoNestedMetadataUniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Unit" relation: "MetadataUnit.photo"'s inverse relation */
  Metadata_Unit?: InputMaybe<PhotoNestedMetadataUnitCreateInput>;
  /** Actions for the "Photo.Metadata_UnitShortName" relation: "MetadataUnitShortName.photo"'s inverse relation */
  Metadata_UnitShortName?: InputMaybe<PhotoNestedMetadataUnitshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_UploadedBy" relation: "MetadataUploadedBy.photo"'s inverse relation */
  Metadata_UploadedBy?: InputMaybe<PhotoNestedMetadataUploadedbyCreateInput>;
  /** Actions for the "Photo.Metadata_UploadedByFullName" relation: "MetadataUploadedByFullName.photo"'s inverse relation */
  Metadata_UploadedByFullName?: InputMaybe<PhotoNestedMetadataUploadedbyfullnameCreateInput>;
  /** Actions for the "Photo.Metadata_UploadTime" relation: "MetadataUploadTime.photo"'s inverse relation */
  Metadata_UploadTime?: InputMaybe<PhotoNestedMetadataUploadtimeCreateInput>;
  /** Actions for the "Photo.Metadata_Urgency" relation: "MetadataUrgency.photo"'s inverse relation */
  Metadata_Urgency?: InputMaybe<PhotoNestedMetadataUrgencyCreateInput>;
  /** Actions for the "Photo.Metadata_UsageTerms" relation: "MetadataUsageTerms.photo"'s inverse relation */
  Metadata_UsageTerms?: InputMaybe<PhotoNestedMetadataUsagetermsCreateInput>;
  /** Actions for the "Photo.Metadata_UserComment" relation: "MetadataUserComment.photo"'s inverse relation */
  Metadata_UserComment?: InputMaybe<PhotoNestedMetadataUsercommentCreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined195" relation: "MetadataUserDefined195.photo"'s inverse relation */
  Metadata_UserDefined195?: InputMaybe<PhotoNestedMetadataUserdefined195CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined237" relation: "MetadataUserDefined237.photo"'s inverse relation */
  Metadata_UserDefined237?: InputMaybe<PhotoNestedMetadataUserdefined237CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined238" relation: "MetadataUserDefined238.photo"'s inverse relation */
  Metadata_UserDefined238?: InputMaybe<PhotoNestedMetadataUserdefined238CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined239" relation: "MetadataUserDefined239.photo"'s inverse relation */
  Metadata_UserDefined239?: InputMaybe<PhotoNestedMetadataUserdefined239CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined242" relation: "MetadataUserDefined242.photo"'s inverse relation */
  Metadata_UserDefined242?: InputMaybe<PhotoNestedMetadataUserdefined242CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined62" relation: "MetadataUserDefined62.photo"'s inverse relation */
  Metadata_UserDefined62?: InputMaybe<PhotoNestedMetadataUserdefined62CreateInput>;
  /** Actions for the "Photo.Metadata_Valid" relation: "MetadataValid.photo"'s inverse relation */
  Metadata_Valid?: InputMaybe<PhotoNestedMetadataValidCreateInput>;
  /** Actions for the "Photo.Metadata_Version" relation: "MetadataVersion.photo"'s inverse relation */
  Metadata_Version?: InputMaybe<PhotoNestedMetadataVersionCreateInput>;
  /** Actions for the "Photo.Metadata_WebStatement" relation: "MetadataWebStatement.photo"'s inverse relation */
  Metadata_WebStatement?: InputMaybe<PhotoNestedMetadataWebstatementCreateInput>;
  /** Actions for the "Photo.Metadata_WorkflowKind" relation: "MetadataWorkflowKind.photo"'s inverse relation */
  Metadata_WorkflowKind?: InputMaybe<PhotoNestedMetadataWorkflowkindCreateInput>;
  /** Actions for the "Photo.Metadata_XMPFileStamps" relation: "MetadataXmpFileStamps.photo"'s inverse relation */
  Metadata_XMPFileStamps?: InputMaybe<PhotoNestedMetadataXmpfilestampsCreateInput>;
  /** Actions for the "Photo.Metadata_XMPHistory" relation: "MetadataXmpHistory.photo"'s inverse relation */
  Metadata_XMPHistory?: InputMaybe<PhotoNestedMetadataXmphistoryCreateInput>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.photoMetadatas" relation: "PhotoMetadata.photo"'s inverse relation */
  photoMetadatas?: InputMaybe<PhotoNestedPhotoMetadatasCreateInput>;
  /** Actions for the "Photo.photoPublications" relation: "PhotoPublication.photo"'s inverse relation */
  photoPublications?: InputMaybe<PhotoNestedPhotoPublicationsCreateInput>;
  synced?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date of the photo's capture */
  takenAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** "PhotoMetadata" resource's node */
export type PhotoMetadata = {
  __typename?: 'PhotoMetadata';
  archive: Archive;
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  /** The ID */
  id: Scalars['Int']['output'];
  metadata: Metadata;
  photo: Photo;
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type PhotoMetadataCreateInput = {
  /** Actions for the "PhotoMetadata.archive" relation */
  archive: PhotoMetadataNestedArchiveCreateInput;
  /** Actions for the "PhotoMetadata.metadata" relation */
  metadata: PhotoMetadataNestedMetadataCreateInput;
  /** Actions for the "PhotoMetadata.photo" relation */
  photo: PhotoMetadataNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoMetadataNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "PhotoMetadata" node, through the "PhotoMetadata.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "PhotoMetadata" node, through the "PhotoMetadata.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "PhotoMetadata" node, through the "PhotoMetadata.archive" relation. */
  update?: InputMaybe<PhotoMetadataNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "PhotoMetadata" node, through the "PhotoMetadata.archive" relation. */
  upsert?: InputMaybe<PhotoMetadataNestedUpsertArchiveCreateInput>;
};

export type PhotoMetadataNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "PhotoMetadata" node, through the "PhotoMetadata.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "PhotoMetadata" node, through the "PhotoMetadata.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "PhotoMetadata" node, through the "PhotoMetadata.archive" relation. */
  update?: InputMaybe<PhotoMetadataNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "PhotoMetadata" node, through the "PhotoMetadata.archive" relation. */
  upsert?: InputMaybe<PhotoMetadataNestedUpsertArchiveUpdateInput>;
};

export type PhotoMetadataNestedMetadataCreateInput = {
  /** Connect an existing "Metadata" node to the new "PhotoMetadata" node, through the "PhotoMetadata.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the new "PhotoMetadata" node, through the "PhotoMetadata.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the new "PhotoMetadata" node, through the "PhotoMetadata.metadata" relation. */
  update?: InputMaybe<PhotoMetadataNestedUpdateMetadataCreateInput>;
  /** Create or update a "Metadata" node and connect it to the new "PhotoMetadata" node, through the "PhotoMetadata.metadata" relation. */
  upsert?: InputMaybe<PhotoMetadataNestedUpsertMetadataCreateInput>;
};

export type PhotoMetadataNestedMetadataUpdateInput = {
  /** Connect an existing "Metadata" node to the existing "PhotoMetadata" node, through the "PhotoMetadata.metadata" relation. */
  connect?: InputMaybe<MetadataWhereUniqueInput>;
  /** Create a new "Metadata" node and connect it to the existing "PhotoMetadata" node, through the "PhotoMetadata.metadata" relation. */
  create?: InputMaybe<MetadataCreateInput>;
  /** Update an existing "Metadata" node and connect it to the existing "PhotoMetadata" node, through the "PhotoMetadata.metadata" relation. */
  update?: InputMaybe<PhotoMetadataNestedUpdateMetadataUpdateInput>;
  /** Create or update a "Metadata" node and connect it to the existing "PhotoMetadata" node, through the "PhotoMetadata.metadata" relation. */
  upsert?: InputMaybe<PhotoMetadataNestedUpsertMetadataUpdateInput>;
};

export type PhotoMetadataNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "PhotoMetadata" node, through the "PhotoMetadata.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "PhotoMetadata" node, through the "PhotoMetadata.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "PhotoMetadata" node, through the "PhotoMetadata.photo" relation. */
  update?: InputMaybe<PhotoMetadataNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "PhotoMetadata" node, through the "PhotoMetadata.photo" relation. */
  upsert?: InputMaybe<PhotoMetadataNestedUpsertPhotoCreateInput>;
};

export type PhotoMetadataNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type PhotoMetadataNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type PhotoMetadataNestedUpdateMetadataCreateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type PhotoMetadataNestedUpdateMetadataUpdateInput = {
  data: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type PhotoMetadataNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type PhotoMetadataNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type PhotoMetadataNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type PhotoMetadataNestedUpsertMetadataCreateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type PhotoMetadataNestedUpsertMetadataUpdateInput = {
  create: MetadataCreateInput;
  update: MetadataUpdateInput;
  where: MetadataWhereUniqueInput;
};

export type PhotoMetadataNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum PhotoMetadataOrderByInput {
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  value_ASC = 'value_ASC',
  value_DESC = 'value_DESC'
}

export type PhotoMetadataUpdateInput = {
  /** Actions for the "PhotoMetadata.archive" relation */
  archive?: InputMaybe<PhotoMetadataNestedArchiveUpdateInput>;
  /** Actions for the "PhotoMetadata.metadata" relation */
  metadata?: InputMaybe<PhotoMetadataNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "PhotoMetadata" nodes by specifying some conditions */
export type PhotoMetadataWhereInput = {
  AND?: InputMaybe<Array<PhotoMetadataWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  id?: InputMaybe<Scalars['Int']['input']>;
  id_gt?: InputMaybe<Scalars['Int']['input']>;
  id_gte?: InputMaybe<Scalars['Int']['input']>;
  id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  id_lt?: InputMaybe<Scalars['Int']['input']>;
  id_lte?: InputMaybe<Scalars['Int']['input']>;
  id_not?: InputMaybe<Scalars['Int']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  metadata?: InputMaybe<MetadataWhereInput>;
  NOT?: InputMaybe<PhotoMetadataWhereInput>;
  OR?: InputMaybe<Array<PhotoMetadataWhereInput>>;
  photo?: InputMaybe<PhotoWhereInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  value?: InputMaybe<Scalars['String']['input']>;
  value_contains?: InputMaybe<Scalars['String']['input']>;
  value_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_gt?: InputMaybe<Scalars['String']['input']>;
  value_gte?: InputMaybe<Scalars['String']['input']>;
  value_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  value_lt?: InputMaybe<Scalars['String']['input']>;
  value_lte?: InputMaybe<Scalars['String']['input']>;
  value_not?: InputMaybe<Scalars['String']['input']>;
  value_not_contains?: InputMaybe<Scalars['String']['input']>;
  value_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  value_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  value_starts_with?: InputMaybe<Scalars['String']['input']>;
};

/** Identifies exactly one "PhotoMetadata" node. */
export type PhotoMetadataWhereUniqueInput = {
  id: Scalars['Int']['input'];
};

export type PhotoMetadataWithForcedArchiveCreateInput = {
  /** Actions for the "PhotoMetadata.metadata" relation */
  metadata: PhotoMetadataNestedMetadataCreateInput;
  /** Actions for the "PhotoMetadata.photo" relation */
  photo: PhotoMetadataNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoMetadataWithForcedArchiveUpdateInput = {
  /** Actions for the "PhotoMetadata.metadata" relation */
  metadata?: InputMaybe<PhotoMetadataNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoMetadataWithForcedMetadataCreateInput = {
  /** Actions for the "PhotoMetadata.archive" relation */
  archive: PhotoMetadataNestedArchiveCreateInput;
  /** Actions for the "PhotoMetadata.photo" relation */
  photo: PhotoMetadataNestedPhotoCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoMetadataWithForcedMetadataUpdateInput = {
  /** Actions for the "PhotoMetadata.archive" relation */
  archive?: InputMaybe<PhotoMetadataNestedArchiveUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoMetadataWithForcedPhotoCreateInput = {
  /** Actions for the "PhotoMetadata.archive" relation */
  archive: PhotoMetadataNestedArchiveCreateInput;
  /** Actions for the "PhotoMetadata.metadata" relation */
  metadata: PhotoMetadataNestedMetadataCreateInput;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoMetadataWithForcedPhotoUpdateInput = {
  /** Actions for the "PhotoMetadata.archive" relation */
  archive?: InputMaybe<PhotoMetadataNestedArchiveUpdateInput>;
  /** Actions for the "PhotoMetadata.metadata" relation */
  metadata?: InputMaybe<PhotoMetadataNestedMetadataUpdateInput>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoNestedAgencyCreateInput = {
  /** Connect an existing "Agency" node to the new "Photo" node, through the "Photo.agency" relation. */
  connect?: InputMaybe<AgencyWhereUniqueInput>;
  /** Create a new "Agency" node and connect it to the new "Photo" node, through the "Photo.agency" relation. */
  create?: InputMaybe<AgencyCreateInput>;
  /** Update an existing "Agency" node and connect it to the new "Photo" node, through the "Photo.agency" relation. */
  update?: InputMaybe<PhotoNestedUpdateAgencyCreateInput>;
  /** Create or update a "Agency" node and connect it to the new "Photo" node, through the "Photo.agency" relation. */
  upsert?: InputMaybe<PhotoNestedUpsertAgencyCreateInput>;
};

export type PhotoNestedAgencyUpdateInput = {
  /** Connect an existing "Agency" node to the existing "Photo" node, through the "Photo.agency" relation. */
  connect?: InputMaybe<AgencyWhereUniqueInput>;
  /** Create a new "Agency" node and connect it to the existing "Photo" node, through the "Photo.agency" relation. */
  create?: InputMaybe<AgencyCreateInput>;
  /** Disconnect the current connected "Agency" node, if any, of the "Photo.agency" relation. */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update an existing "Agency" node and connect it to the existing "Photo" node, through the "Photo.agency" relation. */
  update?: InputMaybe<PhotoNestedUpdateAgencyUpdateInput>;
  /** Create or update a "Agency" node and connect it to the existing "Photo" node, through the "Photo.agency" relation. */
  upsert?: InputMaybe<PhotoNestedUpsertAgencyUpdateInput>;
};

export type PhotoNestedArchiveCreateInput = {
  /** Connect an existing "Archive" node to the new "Photo" node, through the "Photo.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the new "Photo" node, through the "Photo.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the new "Photo" node, through the "Photo.archive" relation. */
  update?: InputMaybe<PhotoNestedUpdateArchiveCreateInput>;
  /** Create or update a "Archive" node and connect it to the new "Photo" node, through the "Photo.archive" relation. */
  upsert?: InputMaybe<PhotoNestedUpsertArchiveCreateInput>;
};

export type PhotoNestedArchiveUpdateInput = {
  /** Connect an existing "Archive" node to the existing "Photo" node, through the "Photo.archive" relation. */
  connect?: InputMaybe<ArchiveWhereUniqueInput>;
  /** Create a new "Archive" node and connect it to the existing "Photo" node, through the "Photo.archive" relation. */
  create?: InputMaybe<ArchiveCreateInput>;
  /** Update an existing "Archive" node and connect it to the existing "Photo" node, through the "Photo.archive" relation. */
  update?: InputMaybe<PhotoNestedUpdateArchiveUpdateInput>;
  /** Create or update a "Archive" node and connect it to the existing "Photo" node, through the "Photo.archive" relation. */
  upsert?: InputMaybe<PhotoNestedUpsertArchiveUpdateInput>;
};

export type PhotoNestedCategoryCreateInput = {
  /** Connect an existing "Category" node to the new "Photo" node, through the "Photo.category" relation. */
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  /** Create a new "Category" node and connect it to the new "Photo" node, through the "Photo.category" relation. */
  create?: InputMaybe<CategoryCreateInput>;
  /** Update an existing "Category" node and connect it to the new "Photo" node, through the "Photo.category" relation. */
  update?: InputMaybe<PhotoNestedUpdateCategoryCreateInput>;
  /** Create or update a "Category" node and connect it to the new "Photo" node, through the "Photo.category" relation. */
  upsert?: InputMaybe<PhotoNestedUpsertCategoryCreateInput>;
};

export type PhotoNestedCategoryUpdateInput = {
  /** Connect an existing "Category" node to the existing "Photo" node, through the "Photo.category" relation. */
  connect?: InputMaybe<CategoryWhereUniqueInput>;
  /** Create a new "Category" node and connect it to the existing "Photo" node, through the "Photo.category" relation. */
  create?: InputMaybe<CategoryCreateInput>;
  /** Disconnect the current connected "Category" node, if any, of the "Photo.category" relation. */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update an existing "Category" node and connect it to the existing "Photo" node, through the "Photo.category" relation. */
  update?: InputMaybe<PhotoNestedUpdateCategoryUpdateInput>;
  /** Create or update a "Category" node and connect it to the existing "Photo" node, through the "Photo.category" relation. */
  upsert?: InputMaybe<PhotoNestedUpsertCategoryUpdateInput>;
};

export type PhotoNestedDeliverySubjectCreateInput = {
  /** Connect an existing "DeliverySubject" node to the new "Photo" node, through the "Photo.deliverySubject" relation. */
  connect?: InputMaybe<DeliverySubjectWhereUniqueInput>;
  /** Create a new "DeliverySubject" node and connect it to the new "Photo" node, through the "Photo.deliverySubject" relation. */
  create?: InputMaybe<DeliverySubjectCreateInput>;
  /** Update an existing "DeliverySubject" node and connect it to the new "Photo" node, through the "Photo.deliverySubject" relation. */
  update?: InputMaybe<PhotoNestedUpdateDeliverySubjectCreateInput>;
  /** Create or update a "DeliverySubject" node and connect it to the new "Photo" node, through the "Photo.deliverySubject" relation. */
  upsert?: InputMaybe<PhotoNestedUpsertDeliverySubjectCreateInput>;
};

export type PhotoNestedDeliverySubjectUpdateInput = {
  /** Connect an existing "DeliverySubject" node to the existing "Photo" node, through the "Photo.deliverySubject" relation. */
  connect?: InputMaybe<DeliverySubjectWhereUniqueInput>;
  /** Create a new "DeliverySubject" node and connect it to the existing "Photo" node, through the "Photo.deliverySubject" relation. */
  create?: InputMaybe<DeliverySubjectCreateInput>;
  /** Disconnect the current connected "DeliverySubject" node, if any, of the "Photo.deliverySubject" relation. */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update an existing "DeliverySubject" node and connect it to the existing "Photo" node, through the "Photo.deliverySubject" relation. */
  update?: InputMaybe<PhotoNestedUpdateDeliverySubjectUpdateInput>;
  /** Create or update a "DeliverySubject" node and connect it to the existing "Photo" node, through the "Photo.deliverySubject" relation. */
  upsert?: InputMaybe<PhotoNestedUpsertDeliverySubjectUpdateInput>;
};

export type PhotoNestedImportQueueCreateInput = {
  /** Connect an existing "ImportQueue" node to the new "Photo" node, through the "Photo.importQueue" relation. */
  connect?: InputMaybe<ImportQueueWhereUniqueInput>;
  /** Create a new "ImportQueue" node and connect it to the new "Photo" node, through the "Photo.importQueue" relation. */
  create?: InputMaybe<ImportQueueWithForcedPhotoCreateInput>;
};

export type PhotoNestedImportQueueUpdateInput = {
  /** Connect an existing "ImportQueue" node to the existing "Photo" node, through the "Photo.importQueue" relation. */
  connect?: InputMaybe<ImportQueueWhereUniqueInput>;
  /** Create a new "ImportQueue" node and connect it to the existing "Photo" node, through the "Photo.importQueue" relation. */
  create?: InputMaybe<ImportQueueWithForcedPhotoCreateInput>;
  /** Delete an existing "ImportQueue" node from the "Photo.importQueue" relation. */
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Disconnect an existing "ImportQueue" node of the "Photo.importQueue" relation. */
  disconnect?: InputMaybe<Scalars['Boolean']['input']>;
  /** Update an existing "ImportQueue" node and connect it to the existing "Photo" node, through the "Photo.importQueue" relation. */
  update?: InputMaybe<PhotoNestedUpdateImportQueueUpdateInput>;
  /** Upsert an existing "ImportQueue" node and connect it to the existing "Photo" node, through the "Photo.importQueue" relation. */
  upsert?: InputMaybe<PhotoNestedUpsertImportQueueUpdateInput>;
};

export type PhotoNestedMetadataAccessrightsCreateInput = {
  /** Connect existing "MetadataAccessRights" nodes to the new "Photo" node, through the "Photo.Metadata_AccessRights" relation. */
  connect?: InputMaybe<Array<MetadataAccessRightsWhereUniqueInput>>;
  /** Create new "MetadataAccessRights" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_AccessRights" relation. */
  create?: InputMaybe<Array<MetadataAccessRightsWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataAccessrightsUpdateInput = {
  /** Connect existing "MetadataAccessRights" nodes to the existing "Photo" node, through the "Photo.Metadata_AccessRights" relation. */
  connect?: InputMaybe<Array<MetadataAccessRightsWhereUniqueInput>>;
  /** Create new "MetadataAccessRights" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AccessRights" relation. */
  create?: InputMaybe<Array<MetadataAccessRightsWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataAccessRights" nodes from the "Photo.Metadata_AccessRights" relation. */
  delete?: InputMaybe<Array<MetadataAccessRightsWhereUniqueInput>>;
  /** Update existing "MetadataAccessRights" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AccessRights" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataAccessrightsUpdateInput>>;
  /** Upsert existing "MetadataAccessRights" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AccessRights" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataAccessrightsUpdateInput>>;
};

export type PhotoNestedMetadataAddlmodelinfoCreateInput = {
  /** Connect existing "MetadataAddlModelInfo" nodes to the new "Photo" node, through the "Photo.Metadata_AddlModelInfo" relation. */
  connect?: InputMaybe<Array<MetadataAddlModelInfoWhereUniqueInput>>;
  /** Create new "MetadataAddlModelInfo" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_AddlModelInfo" relation. */
  create?: InputMaybe<Array<MetadataAddlModelInfoWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataAddlmodelinfoUpdateInput = {
  /** Connect existing "MetadataAddlModelInfo" nodes to the existing "Photo" node, through the "Photo.Metadata_AddlModelInfo" relation. */
  connect?: InputMaybe<Array<MetadataAddlModelInfoWhereUniqueInput>>;
  /** Create new "MetadataAddlModelInfo" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AddlModelInfo" relation. */
  create?: InputMaybe<Array<MetadataAddlModelInfoWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataAddlModelInfo" nodes from the "Photo.Metadata_AddlModelInfo" relation. */
  delete?: InputMaybe<Array<MetadataAddlModelInfoWhereUniqueInput>>;
  /** Update existing "MetadataAddlModelInfo" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AddlModelInfo" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataAddlmodelinfoUpdateInput>>;
  /** Upsert existing "MetadataAddlModelInfo" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AddlModelInfo" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataAddlmodelinfoUpdateInput>>;
};

export type PhotoNestedMetadataArchiveChildCreateInput = {
  /** Connect existing "MetadataArchiveChild" nodes to the new "Photo" node, through the "Photo.Metadata_Archive_child" relation. */
  connect?: InputMaybe<Array<MetadataArchiveChildWhereUniqueInput>>;
  /** Create new "MetadataArchiveChild" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Archive_child" relation. */
  create?: InputMaybe<Array<MetadataArchiveChildWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataArchiveChildUpdateInput = {
  /** Connect existing "MetadataArchiveChild" nodes to the existing "Photo" node, through the "Photo.Metadata_Archive_child" relation. */
  connect?: InputMaybe<Array<MetadataArchiveChildWhereUniqueInput>>;
  /** Create new "MetadataArchiveChild" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Archive_child" relation. */
  create?: InputMaybe<Array<MetadataArchiveChildWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataArchiveChild" nodes from the "Photo.Metadata_Archive_child" relation. */
  delete?: InputMaybe<Array<MetadataArchiveChildWhereUniqueInput>>;
  /** Update existing "MetadataArchiveChild" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Archive_child" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataArchiveChildUpdateInput>>;
  /** Upsert existing "MetadataArchiveChild" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Archive_child" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataArchiveChildUpdateInput>>;
};

export type PhotoNestedMetadataArchiveParentCreateInput = {
  /** Connect existing "MetadataArchiveParent" nodes to the new "Photo" node, through the "Photo.Metadata_Archive_parent" relation. */
  connect?: InputMaybe<Array<MetadataArchiveParentWhereUniqueInput>>;
  /** Create new "MetadataArchiveParent" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Archive_parent" relation. */
  create?: InputMaybe<Array<MetadataArchiveParentWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataArchiveParentUpdateInput = {
  /** Connect existing "MetadataArchiveParent" nodes to the existing "Photo" node, through the "Photo.Metadata_Archive_parent" relation. */
  connect?: InputMaybe<Array<MetadataArchiveParentWhereUniqueInput>>;
  /** Create new "MetadataArchiveParent" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Archive_parent" relation. */
  create?: InputMaybe<Array<MetadataArchiveParentWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataArchiveParent" nodes from the "Photo.Metadata_Archive_parent" relation. */
  delete?: InputMaybe<Array<MetadataArchiveParentWhereUniqueInput>>;
  /** Update existing "MetadataArchiveParent" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Archive_parent" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataArchiveParentUpdateInput>>;
  /** Upsert existing "MetadataArchiveParent" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Archive_parent" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataArchiveParentUpdateInput>>;
};

export type PhotoNestedMetadataAssetstateCreateInput = {
  /** Connect existing "MetadataAssetState" nodes to the new "Photo" node, through the "Photo.Metadata_AssetState" relation. */
  connect?: InputMaybe<Array<MetadataAssetStateWhereUniqueInput>>;
  /** Create new "MetadataAssetState" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_AssetState" relation. */
  create?: InputMaybe<Array<MetadataAssetStateWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataAssetstateUpdateInput = {
  /** Connect existing "MetadataAssetState" nodes to the existing "Photo" node, through the "Photo.Metadata_AssetState" relation. */
  connect?: InputMaybe<Array<MetadataAssetStateWhereUniqueInput>>;
  /** Create new "MetadataAssetState" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AssetState" relation. */
  create?: InputMaybe<Array<MetadataAssetStateWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataAssetState" nodes from the "Photo.Metadata_AssetState" relation. */
  delete?: InputMaybe<Array<MetadataAssetStateWhereUniqueInput>>;
  /** Update existing "MetadataAssetState" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AssetState" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataAssetstateUpdateInput>>;
  /** Upsert existing "MetadataAssetState" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AssetState" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataAssetstateUpdateInput>>;
};

export type PhotoNestedMetadataAttributionnameCreateInput = {
  /** Connect existing "MetadataAttributionName" nodes to the new "Photo" node, through the "Photo.Metadata_attributionName" relation. */
  connect?: InputMaybe<Array<MetadataAttributionNameWhereUniqueInput>>;
  /** Create new "MetadataAttributionName" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_attributionName" relation. */
  create?: InputMaybe<Array<MetadataAttributionNameWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataAttributionnameUpdateInput = {
  /** Connect existing "MetadataAttributionName" nodes to the existing "Photo" node, through the "Photo.Metadata_attributionName" relation. */
  connect?: InputMaybe<Array<MetadataAttributionNameWhereUniqueInput>>;
  /** Create new "MetadataAttributionName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_attributionName" relation. */
  create?: InputMaybe<Array<MetadataAttributionNameWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataAttributionName" nodes from the "Photo.Metadata_attributionName" relation. */
  delete?: InputMaybe<Array<MetadataAttributionNameWhereUniqueInput>>;
  /** Update existing "MetadataAttributionName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_attributionName" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataAttributionnameUpdateInput>>;
  /** Upsert existing "MetadataAttributionName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_attributionName" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataAttributionnameUpdateInput>>;
};

export type PhotoNestedMetadataAttributionurlCreateInput = {
  /** Connect existing "MetadataAttributionUrl" nodes to the new "Photo" node, through the "Photo.Metadata_attributionURL" relation. */
  connect?: InputMaybe<Array<MetadataAttributionUrlWhereUniqueInput>>;
  /** Create new "MetadataAttributionUrl" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_attributionURL" relation. */
  create?: InputMaybe<Array<MetadataAttributionUrlWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataAttributionurlUpdateInput = {
  /** Connect existing "MetadataAttributionUrl" nodes to the existing "Photo" node, through the "Photo.Metadata_attributionURL" relation. */
  connect?: InputMaybe<Array<MetadataAttributionUrlWhereUniqueInput>>;
  /** Create new "MetadataAttributionUrl" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_attributionURL" relation. */
  create?: InputMaybe<Array<MetadataAttributionUrlWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataAttributionUrl" nodes from the "Photo.Metadata_attributionURL" relation. */
  delete?: InputMaybe<Array<MetadataAttributionUrlWhereUniqueInput>>;
  /** Update existing "MetadataAttributionUrl" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_attributionURL" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataAttributionurlUpdateInput>>;
  /** Upsert existing "MetadataAttributionUrl" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_attributionURL" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataAttributionurlUpdateInput>>;
};

export type PhotoNestedMetadataAudienceCreateInput = {
  /** Connect existing "MetadataAudience" nodes to the new "Photo" node, through the "Photo.Metadata_Audience" relation. */
  connect?: InputMaybe<Array<MetadataAudienceWhereUniqueInput>>;
  /** Create new "MetadataAudience" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Audience" relation. */
  create?: InputMaybe<Array<MetadataAudienceWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataAudienceUpdateInput = {
  /** Connect existing "MetadataAudience" nodes to the existing "Photo" node, through the "Photo.Metadata_Audience" relation. */
  connect?: InputMaybe<Array<MetadataAudienceWhereUniqueInput>>;
  /** Create new "MetadataAudience" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Audience" relation. */
  create?: InputMaybe<Array<MetadataAudienceWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataAudience" nodes from the "Photo.Metadata_Audience" relation. */
  delete?: InputMaybe<Array<MetadataAudienceWhereUniqueInput>>;
  /** Update existing "MetadataAudience" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Audience" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataAudienceUpdateInput>>;
  /** Upsert existing "MetadataAudience" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Audience" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataAudienceUpdateInput>>;
};

export type PhotoNestedMetadataAuthorspositionCreateInput = {
  /** Connect existing "MetadataAuthorsPosition" nodes to the new "Photo" node, through the "Photo.Metadata_AuthorsPosition" relation. */
  connect?: InputMaybe<Array<MetadataAuthorsPositionWhereUniqueInput>>;
  /** Create new "MetadataAuthorsPosition" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_AuthorsPosition" relation. */
  create?: InputMaybe<Array<MetadataAuthorsPositionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataAuthorspositionUpdateInput = {
  /** Connect existing "MetadataAuthorsPosition" nodes to the existing "Photo" node, through the "Photo.Metadata_AuthorsPosition" relation. */
  connect?: InputMaybe<Array<MetadataAuthorsPositionWhereUniqueInput>>;
  /** Create new "MetadataAuthorsPosition" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AuthorsPosition" relation. */
  create?: InputMaybe<Array<MetadataAuthorsPositionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataAuthorsPosition" nodes from the "Photo.Metadata_AuthorsPosition" relation. */
  delete?: InputMaybe<Array<MetadataAuthorsPositionWhereUniqueInput>>;
  /** Update existing "MetadataAuthorsPosition" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AuthorsPosition" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataAuthorspositionUpdateInput>>;
  /** Upsert existing "MetadataAuthorsPosition" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_AuthorsPosition" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataAuthorspositionUpdateInput>>;
};

export type PhotoNestedMetadataBackupnameCreateInput = {
  /** Connect existing "MetadataBackupName" nodes to the new "Photo" node, through the "Photo.Metadata_BackupName" relation. */
  connect?: InputMaybe<Array<MetadataBackupNameWhereUniqueInput>>;
  /** Create new "MetadataBackupName" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_BackupName" relation. */
  create?: InputMaybe<Array<MetadataBackupNameWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataBackupnameUpdateInput = {
  /** Connect existing "MetadataBackupName" nodes to the existing "Photo" node, through the "Photo.Metadata_BackupName" relation. */
  connect?: InputMaybe<Array<MetadataBackupNameWhereUniqueInput>>;
  /** Create new "MetadataBackupName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BackupName" relation. */
  create?: InputMaybe<Array<MetadataBackupNameWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataBackupName" nodes from the "Photo.Metadata_BackupName" relation. */
  delete?: InputMaybe<Array<MetadataBackupNameWhereUniqueInput>>;
  /** Update existing "MetadataBackupName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BackupName" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataBackupnameUpdateInput>>;
  /** Upsert existing "MetadataBackupName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BackupName" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataBackupnameUpdateInput>>;
};

export type PhotoNestedMetadataBitmapgrayscalepictureCreateInput = {
  /** Connect existing "MetadataBitmapGrayscalePicture" nodes to the new "Photo" node, through the "Photo.Metadata_BitmapGrayscalePicture" relation. */
  connect?: InputMaybe<Array<MetadataBitmapGrayscalePictureWhereUniqueInput>>;
  /** Create new "MetadataBitmapGrayscalePicture" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_BitmapGrayscalePicture" relation. */
  create?: InputMaybe<Array<MetadataBitmapGrayscalePictureWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataBitmapgrayscalepictureUpdateInput = {
  /** Connect existing "MetadataBitmapGrayscalePicture" nodes to the existing "Photo" node, through the "Photo.Metadata_BitmapGrayscalePicture" relation. */
  connect?: InputMaybe<Array<MetadataBitmapGrayscalePictureWhereUniqueInput>>;
  /** Create new "MetadataBitmapGrayscalePicture" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BitmapGrayscalePicture" relation. */
  create?: InputMaybe<Array<MetadataBitmapGrayscalePictureWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataBitmapGrayscalePicture" nodes from the "Photo.Metadata_BitmapGrayscalePicture" relation. */
  delete?: InputMaybe<Array<MetadataBitmapGrayscalePictureWhereUniqueInput>>;
  /** Update existing "MetadataBitmapGrayscalePicture" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BitmapGrayscalePicture" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataBitmapgrayscalepictureUpdateInput>>;
  /** Upsert existing "MetadataBitmapGrayscalePicture" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BitmapGrayscalePicture" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataBitmapgrayscalepictureUpdateInput>>;
};

export type PhotoNestedMetadataBrandCreateInput = {
  /** Connect existing "MetadataBrand" nodes to the new "Photo" node, through the "Photo.Metadata_Brand" relation. */
  connect?: InputMaybe<Array<MetadataBrandWhereUniqueInput>>;
  /** Create new "MetadataBrand" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Brand" relation. */
  create?: InputMaybe<Array<MetadataBrandWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataBrandprismaCreateInput = {
  /** Connect existing "MetadataBrandPrisma" nodes to the new "Photo" node, through the "Photo.Metadata_BrandPrisma" relation. */
  connect?: InputMaybe<Array<MetadataBrandPrismaWhereUniqueInput>>;
  /** Create new "MetadataBrandPrisma" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_BrandPrisma" relation. */
  create?: InputMaybe<Array<MetadataBrandPrismaWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataBrandprismaUpdateInput = {
  /** Connect existing "MetadataBrandPrisma" nodes to the existing "Photo" node, through the "Photo.Metadata_BrandPrisma" relation. */
  connect?: InputMaybe<Array<MetadataBrandPrismaWhereUniqueInput>>;
  /** Create new "MetadataBrandPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BrandPrisma" relation. */
  create?: InputMaybe<Array<MetadataBrandPrismaWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataBrandPrisma" nodes from the "Photo.Metadata_BrandPrisma" relation. */
  delete?: InputMaybe<Array<MetadataBrandPrismaWhereUniqueInput>>;
  /** Update existing "MetadataBrandPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BrandPrisma" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataBrandprismaUpdateInput>>;
  /** Upsert existing "MetadataBrandPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BrandPrisma" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataBrandprismaUpdateInput>>;
};

export type PhotoNestedMetadataBrandshortnameCreateInput = {
  /** Connect existing "MetadataBrandShortName" nodes to the new "Photo" node, through the "Photo.Metadata_BrandShortName" relation. */
  connect?: InputMaybe<Array<MetadataBrandShortNameWhereUniqueInput>>;
  /** Create new "MetadataBrandShortName" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_BrandShortName" relation. */
  create?: InputMaybe<Array<MetadataBrandShortNameWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataBrandshortnameUpdateInput = {
  /** Connect existing "MetadataBrandShortName" nodes to the existing "Photo" node, through the "Photo.Metadata_BrandShortName" relation. */
  connect?: InputMaybe<Array<MetadataBrandShortNameWhereUniqueInput>>;
  /** Create new "MetadataBrandShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BrandShortName" relation. */
  create?: InputMaybe<Array<MetadataBrandShortNameWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataBrandShortName" nodes from the "Photo.Metadata_BrandShortName" relation. */
  delete?: InputMaybe<Array<MetadataBrandShortNameWhereUniqueInput>>;
  /** Update existing "MetadataBrandShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BrandShortName" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataBrandshortnameUpdateInput>>;
  /** Upsert existing "MetadataBrandShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_BrandShortName" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataBrandshortnameUpdateInput>>;
};

export type PhotoNestedMetadataBrandUpdateInput = {
  /** Connect existing "MetadataBrand" nodes to the existing "Photo" node, through the "Photo.Metadata_Brand" relation. */
  connect?: InputMaybe<Array<MetadataBrandWhereUniqueInput>>;
  /** Create new "MetadataBrand" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Brand" relation. */
  create?: InputMaybe<Array<MetadataBrandWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataBrand" nodes from the "Photo.Metadata_Brand" relation. */
  delete?: InputMaybe<Array<MetadataBrandWhereUniqueInput>>;
  /** Update existing "MetadataBrand" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Brand" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataBrandUpdateInput>>;
  /** Upsert existing "MetadataBrand" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Brand" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataBrandUpdateInput>>;
};

export type PhotoNestedMetadataCaptionwriterCreateInput = {
  /** Connect existing "MetadataCaptionWriter" nodes to the new "Photo" node, through the "Photo.Metadata_CaptionWriter" relation. */
  connect?: InputMaybe<Array<MetadataCaptionWriterWhereUniqueInput>>;
  /** Create new "MetadataCaptionWriter" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CaptionWriter" relation. */
  create?: InputMaybe<Array<MetadataCaptionWriterWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCaptionwriterUpdateInput = {
  /** Connect existing "MetadataCaptionWriter" nodes to the existing "Photo" node, through the "Photo.Metadata_CaptionWriter" relation. */
  connect?: InputMaybe<Array<MetadataCaptionWriterWhereUniqueInput>>;
  /** Create new "MetadataCaptionWriter" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CaptionWriter" relation. */
  create?: InputMaybe<Array<MetadataCaptionWriterWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCaptionWriter" nodes from the "Photo.Metadata_CaptionWriter" relation. */
  delete?: InputMaybe<Array<MetadataCaptionWriterWhereUniqueInput>>;
  /** Update existing "MetadataCaptionWriter" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CaptionWriter" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCaptionwriterUpdateInput>>;
  /** Upsert existing "MetadataCaptionWriter" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CaptionWriter" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCaptionwriterUpdateInput>>;
};

export type PhotoNestedMetadataCategoryCreateInput = {
  /** Connect existing "MetadataCategory" nodes to the new "Photo" node, through the "Photo.Metadata_Category" relation. */
  connect?: InputMaybe<Array<MetadataCategoryWhereUniqueInput>>;
  /** Create new "MetadataCategory" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Category" relation. */
  create?: InputMaybe<Array<MetadataCategoryWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCategoryprismaCreateInput = {
  /** Connect existing "MetadataCategoryPrisma" nodes to the new "Photo" node, through the "Photo.Metadata_CategoryPrisma" relation. */
  connect?: InputMaybe<Array<MetadataCategoryPrismaWhereUniqueInput>>;
  /** Create new "MetadataCategoryPrisma" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CategoryPrisma" relation. */
  create?: InputMaybe<Array<MetadataCategoryPrismaWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCategoryprismaUpdateInput = {
  /** Connect existing "MetadataCategoryPrisma" nodes to the existing "Photo" node, through the "Photo.Metadata_CategoryPrisma" relation. */
  connect?: InputMaybe<Array<MetadataCategoryPrismaWhereUniqueInput>>;
  /** Create new "MetadataCategoryPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CategoryPrisma" relation. */
  create?: InputMaybe<Array<MetadataCategoryPrismaWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCategoryPrisma" nodes from the "Photo.Metadata_CategoryPrisma" relation. */
  delete?: InputMaybe<Array<MetadataCategoryPrismaWhereUniqueInput>>;
  /** Update existing "MetadataCategoryPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CategoryPrisma" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCategoryprismaUpdateInput>>;
  /** Upsert existing "MetadataCategoryPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CategoryPrisma" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCategoryprismaUpdateInput>>;
};

export type PhotoNestedMetadataCategoryUpdateInput = {
  /** Connect existing "MetadataCategory" nodes to the existing "Photo" node, through the "Photo.Metadata_Category" relation. */
  connect?: InputMaybe<Array<MetadataCategoryWhereUniqueInput>>;
  /** Create new "MetadataCategory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Category" relation. */
  create?: InputMaybe<Array<MetadataCategoryWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCategory" nodes from the "Photo.Metadata_Category" relation. */
  delete?: InputMaybe<Array<MetadataCategoryWhereUniqueInput>>;
  /** Update existing "MetadataCategory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Category" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCategoryUpdateInput>>;
  /** Upsert existing "MetadataCategory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Category" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCategoryUpdateInput>>;
};

export type PhotoNestedMetadataCertificateCreateInput = {
  /** Connect existing "MetadataCertificate" nodes to the new "Photo" node, through the "Photo.Metadata_Certificate" relation. */
  connect?: InputMaybe<Array<MetadataCertificateWhereUniqueInput>>;
  /** Create new "MetadataCertificate" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Certificate" relation. */
  create?: InputMaybe<Array<MetadataCertificateWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCertificateUpdateInput = {
  /** Connect existing "MetadataCertificate" nodes to the existing "Photo" node, through the "Photo.Metadata_Certificate" relation. */
  connect?: InputMaybe<Array<MetadataCertificateWhereUniqueInput>>;
  /** Create new "MetadataCertificate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Certificate" relation. */
  create?: InputMaybe<Array<MetadataCertificateWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCertificate" nodes from the "Photo.Metadata_Certificate" relation. */
  delete?: InputMaybe<Array<MetadataCertificateWhereUniqueInput>>;
  /** Update existing "MetadataCertificate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Certificate" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCertificateUpdateInput>>;
  /** Upsert existing "MetadataCertificate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Certificate" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCertificateUpdateInput>>;
};

export type PhotoNestedMetadataCiadrcityCreateInput = {
  /** Connect existing "MetadataCiAdrCity" nodes to the new "Photo" node, through the "Photo.Metadata_CiAdrCity" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCityWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCity" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CiAdrCity" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCityWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCiadrcityUpdateInput = {
  /** Connect existing "MetadataCiAdrCity" nodes to the existing "Photo" node, through the "Photo.Metadata_CiAdrCity" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCityWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCity" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrCity" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCityWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCiAdrCity" nodes from the "Photo.Metadata_CiAdrCity" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrCityWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrCity" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrCity" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCiadrcityUpdateInput>>;
  /** Upsert existing "MetadataCiAdrCity" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrCity" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCiadrcityUpdateInput>>;
};

export type PhotoNestedMetadataCiadrctryCreateInput = {
  /** Connect existing "MetadataCiAdrCtry" nodes to the new "Photo" node, through the "Photo.Metadata_CiAdrCtry" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCtryWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCtry" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CiAdrCtry" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCtryWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCiadrctryUpdateInput = {
  /** Connect existing "MetadataCiAdrCtry" nodes to the existing "Photo" node, through the "Photo.Metadata_CiAdrCtry" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrCtryWhereUniqueInput>>;
  /** Create new "MetadataCiAdrCtry" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrCtry" relation. */
  create?: InputMaybe<Array<MetadataCiAdrCtryWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCiAdrCtry" nodes from the "Photo.Metadata_CiAdrCtry" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrCtryWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrCtry" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrCtry" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCiadrctryUpdateInput>>;
  /** Upsert existing "MetadataCiAdrCtry" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrCtry" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCiadrctryUpdateInput>>;
};

export type PhotoNestedMetadataCiadrextadrCreateInput = {
  /** Connect existing "MetadataCiAdrExtadr" nodes to the new "Photo" node, through the "Photo.Metadata_CiAdrExtadr" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrExtadrWhereUniqueInput>>;
  /** Create new "MetadataCiAdrExtadr" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CiAdrExtadr" relation. */
  create?: InputMaybe<Array<MetadataCiAdrExtadrWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCiadrextadrUpdateInput = {
  /** Connect existing "MetadataCiAdrExtadr" nodes to the existing "Photo" node, through the "Photo.Metadata_CiAdrExtadr" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrExtadrWhereUniqueInput>>;
  /** Create new "MetadataCiAdrExtadr" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrExtadr" relation. */
  create?: InputMaybe<Array<MetadataCiAdrExtadrWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCiAdrExtadr" nodes from the "Photo.Metadata_CiAdrExtadr" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrExtadrWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrExtadr" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrExtadr" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCiadrextadrUpdateInput>>;
  /** Upsert existing "MetadataCiAdrExtadr" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrExtadr" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCiadrextadrUpdateInput>>;
};

export type PhotoNestedMetadataCiadrpcodeCreateInput = {
  /** Connect existing "MetadataCiAdrPcode" nodes to the new "Photo" node, through the "Photo.Metadata_CiAdrPcode" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrPcodeWhereUniqueInput>>;
  /** Create new "MetadataCiAdrPcode" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CiAdrPcode" relation. */
  create?: InputMaybe<Array<MetadataCiAdrPcodeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCiadrpcodeUpdateInput = {
  /** Connect existing "MetadataCiAdrPcode" nodes to the existing "Photo" node, through the "Photo.Metadata_CiAdrPcode" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrPcodeWhereUniqueInput>>;
  /** Create new "MetadataCiAdrPcode" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrPcode" relation. */
  create?: InputMaybe<Array<MetadataCiAdrPcodeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCiAdrPcode" nodes from the "Photo.Metadata_CiAdrPcode" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrPcodeWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrPcode" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrPcode" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCiadrpcodeUpdateInput>>;
  /** Upsert existing "MetadataCiAdrPcode" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrPcode" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCiadrpcodeUpdateInput>>;
};

export type PhotoNestedMetadataCiadrregionCreateInput = {
  /** Connect existing "MetadataCiAdrRegion" nodes to the new "Photo" node, through the "Photo.Metadata_CiAdrRegion" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrRegionWhereUniqueInput>>;
  /** Create new "MetadataCiAdrRegion" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CiAdrRegion" relation. */
  create?: InputMaybe<Array<MetadataCiAdrRegionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCiadrregionUpdateInput = {
  /** Connect existing "MetadataCiAdrRegion" nodes to the existing "Photo" node, through the "Photo.Metadata_CiAdrRegion" relation. */
  connect?: InputMaybe<Array<MetadataCiAdrRegionWhereUniqueInput>>;
  /** Create new "MetadataCiAdrRegion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrRegion" relation. */
  create?: InputMaybe<Array<MetadataCiAdrRegionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCiAdrRegion" nodes from the "Photo.Metadata_CiAdrRegion" relation. */
  delete?: InputMaybe<Array<MetadataCiAdrRegionWhereUniqueInput>>;
  /** Update existing "MetadataCiAdrRegion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrRegion" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCiadrregionUpdateInput>>;
  /** Upsert existing "MetadataCiAdrRegion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiAdrRegion" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCiadrregionUpdateInput>>;
};

export type PhotoNestedMetadataCiemailworkCreateInput = {
  /** Connect existing "MetadataCiEmailWork" nodes to the new "Photo" node, through the "Photo.Metadata_CiEmailWork" relation. */
  connect?: InputMaybe<Array<MetadataCiEmailWorkWhereUniqueInput>>;
  /** Create new "MetadataCiEmailWork" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CiEmailWork" relation. */
  create?: InputMaybe<Array<MetadataCiEmailWorkWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCiemailworkUpdateInput = {
  /** Connect existing "MetadataCiEmailWork" nodes to the existing "Photo" node, through the "Photo.Metadata_CiEmailWork" relation. */
  connect?: InputMaybe<Array<MetadataCiEmailWorkWhereUniqueInput>>;
  /** Create new "MetadataCiEmailWork" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiEmailWork" relation. */
  create?: InputMaybe<Array<MetadataCiEmailWorkWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCiEmailWork" nodes from the "Photo.Metadata_CiEmailWork" relation. */
  delete?: InputMaybe<Array<MetadataCiEmailWorkWhereUniqueInput>>;
  /** Update existing "MetadataCiEmailWork" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiEmailWork" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCiemailworkUpdateInput>>;
  /** Upsert existing "MetadataCiEmailWork" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiEmailWork" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCiemailworkUpdateInput>>;
};

export type PhotoNestedMetadataCitelworkCreateInput = {
  /** Connect existing "MetadataCiTelWork" nodes to the new "Photo" node, through the "Photo.Metadata_CiTelWork" relation. */
  connect?: InputMaybe<Array<MetadataCiTelWorkWhereUniqueInput>>;
  /** Create new "MetadataCiTelWork" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CiTelWork" relation. */
  create?: InputMaybe<Array<MetadataCiTelWorkWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCitelworkUpdateInput = {
  /** Connect existing "MetadataCiTelWork" nodes to the existing "Photo" node, through the "Photo.Metadata_CiTelWork" relation. */
  connect?: InputMaybe<Array<MetadataCiTelWorkWhereUniqueInput>>;
  /** Create new "MetadataCiTelWork" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiTelWork" relation. */
  create?: InputMaybe<Array<MetadataCiTelWorkWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCiTelWork" nodes from the "Photo.Metadata_CiTelWork" relation. */
  delete?: InputMaybe<Array<MetadataCiTelWorkWhereUniqueInput>>;
  /** Update existing "MetadataCiTelWork" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiTelWork" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCitelworkUpdateInput>>;
  /** Upsert existing "MetadataCiTelWork" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiTelWork" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCitelworkUpdateInput>>;
};

export type PhotoNestedMetadataCityCreateInput = {
  /** Connect existing "MetadataCity" nodes to the new "Photo" node, through the "Photo.Metadata_City" relation. */
  connect?: InputMaybe<Array<MetadataCityWhereUniqueInput>>;
  /** Create new "MetadataCity" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_City" relation. */
  create?: InputMaybe<Array<MetadataCityWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCityUpdateInput = {
  /** Connect existing "MetadataCity" nodes to the existing "Photo" node, through the "Photo.Metadata_City" relation. */
  connect?: InputMaybe<Array<MetadataCityWhereUniqueInput>>;
  /** Create new "MetadataCity" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_City" relation. */
  create?: InputMaybe<Array<MetadataCityWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCity" nodes from the "Photo.Metadata_City" relation. */
  delete?: InputMaybe<Array<MetadataCityWhereUniqueInput>>;
  /** Update existing "MetadataCity" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_City" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCityUpdateInput>>;
  /** Upsert existing "MetadataCity" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_City" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCityUpdateInput>>;
};

export type PhotoNestedMetadataCiurlworkCreateInput = {
  /** Connect existing "MetadataCiUrlWork" nodes to the new "Photo" node, through the "Photo.Metadata_CiUrlWork" relation. */
  connect?: InputMaybe<Array<MetadataCiUrlWorkWhereUniqueInput>>;
  /** Create new "MetadataCiUrlWork" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CiUrlWork" relation. */
  create?: InputMaybe<Array<MetadataCiUrlWorkWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCiurlworkUpdateInput = {
  /** Connect existing "MetadataCiUrlWork" nodes to the existing "Photo" node, through the "Photo.Metadata_CiUrlWork" relation. */
  connect?: InputMaybe<Array<MetadataCiUrlWorkWhereUniqueInput>>;
  /** Create new "MetadataCiUrlWork" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiUrlWork" relation. */
  create?: InputMaybe<Array<MetadataCiUrlWorkWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCiUrlWork" nodes from the "Photo.Metadata_CiUrlWork" relation. */
  delete?: InputMaybe<Array<MetadataCiUrlWorkWhereUniqueInput>>;
  /** Update existing "MetadataCiUrlWork" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiUrlWork" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCiurlworkUpdateInput>>;
  /** Upsert existing "MetadataCiUrlWork" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CiUrlWork" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCiurlworkUpdateInput>>;
};

export type PhotoNestedMetadataClassifyCreateInput = {
  /** Connect existing "MetadataClassify" nodes to the new "Photo" node, through the "Photo.Metadata_Classify" relation. */
  connect?: InputMaybe<Array<MetadataClassifyWhereUniqueInput>>;
  /** Create new "MetadataClassify" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Classify" relation. */
  create?: InputMaybe<Array<MetadataClassifyWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataClassifyUpdateInput = {
  /** Connect existing "MetadataClassify" nodes to the existing "Photo" node, through the "Photo.Metadata_Classify" relation. */
  connect?: InputMaybe<Array<MetadataClassifyWhereUniqueInput>>;
  /** Create new "MetadataClassify" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Classify" relation. */
  create?: InputMaybe<Array<MetadataClassifyWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataClassify" nodes from the "Photo.Metadata_Classify" relation. */
  delete?: InputMaybe<Array<MetadataClassifyWhereUniqueInput>>;
  /** Update existing "MetadataClassify" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Classify" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataClassifyUpdateInput>>;
  /** Upsert existing "MetadataClassify" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Classify" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataClassifyUpdateInput>>;
};

export type PhotoNestedMetadataCompanyCreateInput = {
  /** Connect existing "MetadataCompany" nodes to the new "Photo" node, through the "Photo.Metadata_Company" relation. */
  connect?: InputMaybe<Array<MetadataCompanyWhereUniqueInput>>;
  /** Create new "MetadataCompany" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Company" relation. */
  create?: InputMaybe<Array<MetadataCompanyWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCompanyshortnameCreateInput = {
  /** Connect existing "MetadataCompanyShortName" nodes to the new "Photo" node, through the "Photo.Metadata_CompanyShortName" relation. */
  connect?: InputMaybe<Array<MetadataCompanyShortNameWhereUniqueInput>>;
  /** Create new "MetadataCompanyShortName" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CompanyShortName" relation. */
  create?: InputMaybe<Array<MetadataCompanyShortNameWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCompanyshortnameUpdateInput = {
  /** Connect existing "MetadataCompanyShortName" nodes to the existing "Photo" node, through the "Photo.Metadata_CompanyShortName" relation. */
  connect?: InputMaybe<Array<MetadataCompanyShortNameWhereUniqueInput>>;
  /** Create new "MetadataCompanyShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CompanyShortName" relation. */
  create?: InputMaybe<Array<MetadataCompanyShortNameWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCompanyShortName" nodes from the "Photo.Metadata_CompanyShortName" relation. */
  delete?: InputMaybe<Array<MetadataCompanyShortNameWhereUniqueInput>>;
  /** Update existing "MetadataCompanyShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CompanyShortName" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCompanyshortnameUpdateInput>>;
  /** Upsert existing "MetadataCompanyShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CompanyShortName" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCompanyshortnameUpdateInput>>;
};

export type PhotoNestedMetadataCompanyUpdateInput = {
  /** Connect existing "MetadataCompany" nodes to the existing "Photo" node, through the "Photo.Metadata_Company" relation. */
  connect?: InputMaybe<Array<MetadataCompanyWhereUniqueInput>>;
  /** Create new "MetadataCompany" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Company" relation. */
  create?: InputMaybe<Array<MetadataCompanyWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCompany" nodes from the "Photo.Metadata_Company" relation. */
  delete?: InputMaybe<Array<MetadataCompanyWhereUniqueInput>>;
  /** Update existing "MetadataCompany" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Company" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCompanyUpdateInput>>;
  /** Upsert existing "MetadataCompany" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Company" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCompanyUpdateInput>>;
};

export type PhotoNestedMetadataConfidentialityCreateInput = {
  /** Connect existing "MetadataConfidentiality" nodes to the new "Photo" node, through the "Photo.Metadata_Confidentiality" relation. */
  connect?: InputMaybe<Array<MetadataConfidentialityWhereUniqueInput>>;
  /** Create new "MetadataConfidentiality" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Confidentiality" relation. */
  create?: InputMaybe<Array<MetadataConfidentialityWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataConfidentialityUpdateInput = {
  /** Connect existing "MetadataConfidentiality" nodes to the existing "Photo" node, through the "Photo.Metadata_Confidentiality" relation. */
  connect?: InputMaybe<Array<MetadataConfidentialityWhereUniqueInput>>;
  /** Create new "MetadataConfidentiality" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Confidentiality" relation. */
  create?: InputMaybe<Array<MetadataConfidentialityWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataConfidentiality" nodes from the "Photo.Metadata_Confidentiality" relation. */
  delete?: InputMaybe<Array<MetadataConfidentialityWhereUniqueInput>>;
  /** Update existing "MetadataConfidentiality" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Confidentiality" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataConfidentialityUpdateInput>>;
  /** Upsert existing "MetadataConfidentiality" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Confidentiality" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataConfidentialityUpdateInput>>;
};

export type PhotoNestedMetadataContainedinCreateInput = {
  /** Connect existing "MetadataContainedIn" nodes to the new "Photo" node, through the "Photo.Metadata_ContainedIn" relation. */
  connect?: InputMaybe<Array<MetadataContainedInWhereUniqueInput>>;
  /** Create new "MetadataContainedIn" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ContainedIn" relation. */
  create?: InputMaybe<Array<MetadataContainedInWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataContainedinUpdateInput = {
  /** Connect existing "MetadataContainedIn" nodes to the existing "Photo" node, through the "Photo.Metadata_ContainedIn" relation. */
  connect?: InputMaybe<Array<MetadataContainedInWhereUniqueInput>>;
  /** Create new "MetadataContainedIn" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ContainedIn" relation. */
  create?: InputMaybe<Array<MetadataContainedInWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataContainedIn" nodes from the "Photo.Metadata_ContainedIn" relation. */
  delete?: InputMaybe<Array<MetadataContainedInWhereUniqueInput>>;
  /** Update existing "MetadataContainedIn" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ContainedIn" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataContainedinUpdateInput>>;
  /** Upsert existing "MetadataContainedIn" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ContainedIn" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataContainedinUpdateInput>>;
};

export type PhotoNestedMetadataContentmediakindCreateInput = {
  /** Connect existing "MetadataContentMediaKind" nodes to the new "Photo" node, through the "Photo.Metadata_ContentMediaKind" relation. */
  connect?: InputMaybe<Array<MetadataContentMediaKindWhereUniqueInput>>;
  /** Create new "MetadataContentMediaKind" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ContentMediaKind" relation. */
  create?: InputMaybe<Array<MetadataContentMediaKindWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataContentmediakindUpdateInput = {
  /** Connect existing "MetadataContentMediaKind" nodes to the existing "Photo" node, through the "Photo.Metadata_ContentMediaKind" relation. */
  connect?: InputMaybe<Array<MetadataContentMediaKindWhereUniqueInput>>;
  /** Create new "MetadataContentMediaKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ContentMediaKind" relation. */
  create?: InputMaybe<Array<MetadataContentMediaKindWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataContentMediaKind" nodes from the "Photo.Metadata_ContentMediaKind" relation. */
  delete?: InputMaybe<Array<MetadataContentMediaKindWhereUniqueInput>>;
  /** Update existing "MetadataContentMediaKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ContentMediaKind" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataContentmediakindUpdateInput>>;
  /** Upsert existing "MetadataContentMediaKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ContentMediaKind" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataContentmediakindUpdateInput>>;
};

export type PhotoNestedMetadataContentvalueCreateInput = {
  /** Connect existing "MetadataContentValue" nodes to the new "Photo" node, through the "Photo.Metadata_ContentValue" relation. */
  connect?: InputMaybe<Array<MetadataContentValueWhereUniqueInput>>;
  /** Create new "MetadataContentValue" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ContentValue" relation. */
  create?: InputMaybe<Array<MetadataContentValueWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataContentvalueUpdateInput = {
  /** Connect existing "MetadataContentValue" nodes to the existing "Photo" node, through the "Photo.Metadata_ContentValue" relation. */
  connect?: InputMaybe<Array<MetadataContentValueWhereUniqueInput>>;
  /** Create new "MetadataContentValue" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ContentValue" relation. */
  create?: InputMaybe<Array<MetadataContentValueWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataContentValue" nodes from the "Photo.Metadata_ContentValue" relation. */
  delete?: InputMaybe<Array<MetadataContentValueWhereUniqueInput>>;
  /** Update existing "MetadataContentValue" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ContentValue" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataContentvalueUpdateInput>>;
  /** Upsert existing "MetadataContentValue" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ContentValue" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataContentvalueUpdateInput>>;
};

export type PhotoNestedMetadataCopyrightlayerCreateInput = {
  /** Connect existing "MetadataCopyrightLayer" nodes to the new "Photo" node, through the "Photo.Metadata_CopyrightLayer" relation. */
  connect?: InputMaybe<Array<MetadataCopyrightLayerWhereUniqueInput>>;
  /** Create new "MetadataCopyrightLayer" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CopyrightLayer" relation. */
  create?: InputMaybe<Array<MetadataCopyrightLayerWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCopyrightlayerUpdateInput = {
  /** Connect existing "MetadataCopyrightLayer" nodes to the existing "Photo" node, through the "Photo.Metadata_CopyrightLayer" relation. */
  connect?: InputMaybe<Array<MetadataCopyrightLayerWhereUniqueInput>>;
  /** Create new "MetadataCopyrightLayer" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CopyrightLayer" relation. */
  create?: InputMaybe<Array<MetadataCopyrightLayerWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCopyrightLayer" nodes from the "Photo.Metadata_CopyrightLayer" relation. */
  delete?: InputMaybe<Array<MetadataCopyrightLayerWhereUniqueInput>>;
  /** Update existing "MetadataCopyrightLayer" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CopyrightLayer" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCopyrightlayerUpdateInput>>;
  /** Upsert existing "MetadataCopyrightLayer" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CopyrightLayer" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCopyrightlayerUpdateInput>>;
};

export type PhotoNestedMetadataCountrycodeCreateInput = {
  /** Connect existing "MetadataCountryCode" nodes to the new "Photo" node, through the "Photo.Metadata_CountryCode" relation. */
  connect?: InputMaybe<Array<MetadataCountryCodeWhereUniqueInput>>;
  /** Create new "MetadataCountryCode" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CountryCode" relation. */
  create?: InputMaybe<Array<MetadataCountryCodeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCountrycodeUpdateInput = {
  /** Connect existing "MetadataCountryCode" nodes to the existing "Photo" node, through the "Photo.Metadata_CountryCode" relation. */
  connect?: InputMaybe<Array<MetadataCountryCodeWhereUniqueInput>>;
  /** Create new "MetadataCountryCode" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CountryCode" relation. */
  create?: InputMaybe<Array<MetadataCountryCodeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCountryCode" nodes from the "Photo.Metadata_CountryCode" relation. */
  delete?: InputMaybe<Array<MetadataCountryCodeWhereUniqueInput>>;
  /** Update existing "MetadataCountryCode" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CountryCode" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCountrycodeUpdateInput>>;
  /** Upsert existing "MetadataCountryCode" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CountryCode" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCountrycodeUpdateInput>>;
};

export type PhotoNestedMetadataCountryCreateInput = {
  /** Connect existing "MetadataCountry" nodes to the new "Photo" node, through the "Photo.Metadata_Country" relation. */
  connect?: InputMaybe<Array<MetadataCountryWhereUniqueInput>>;
  /** Create new "MetadataCountry" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Country" relation. */
  create?: InputMaybe<Array<MetadataCountryWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCountryUpdateInput = {
  /** Connect existing "MetadataCountry" nodes to the existing "Photo" node, through the "Photo.Metadata_Country" relation. */
  connect?: InputMaybe<Array<MetadataCountryWhereUniqueInput>>;
  /** Create new "MetadataCountry" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Country" relation. */
  create?: InputMaybe<Array<MetadataCountryWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCountry" nodes from the "Photo.Metadata_Country" relation. */
  delete?: InputMaybe<Array<MetadataCountryWhereUniqueInput>>;
  /** Update existing "MetadataCountry" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Country" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCountryUpdateInput>>;
  /** Upsert existing "MetadataCountry" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Country" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCountryUpdateInput>>;
};

export type PhotoNestedMetadataCreatedtimeCreateInput = {
  /** Connect existing "MetadataCreatedTime" nodes to the new "Photo" node, through the "Photo.Metadata_CreatedTime" relation. */
  connect?: InputMaybe<Array<MetadataCreatedTimeWhereUniqueInput>>;
  /** Create new "MetadataCreatedTime" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CreatedTime" relation. */
  create?: InputMaybe<Array<MetadataCreatedTimeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCreatedtimeUpdateInput = {
  /** Connect existing "MetadataCreatedTime" nodes to the existing "Photo" node, through the "Photo.Metadata_CreatedTime" relation. */
  connect?: InputMaybe<Array<MetadataCreatedTimeWhereUniqueInput>>;
  /** Create new "MetadataCreatedTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CreatedTime" relation. */
  create?: InputMaybe<Array<MetadataCreatedTimeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCreatedTime" nodes from the "Photo.Metadata_CreatedTime" relation. */
  delete?: InputMaybe<Array<MetadataCreatedTimeWhereUniqueInput>>;
  /** Update existing "MetadataCreatedTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CreatedTime" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCreatedtimeUpdateInput>>;
  /** Upsert existing "MetadataCreatedTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CreatedTime" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCreatedtimeUpdateInput>>;
};

export type PhotoNestedMetadataCreatorCreateInput = {
  /** Connect existing "MetadataCreator" nodes to the new "Photo" node, through the "Photo.Metadata_creator" relation. */
  connect?: InputMaybe<Array<MetadataCreatorWhereUniqueInput>>;
  /** Create new "MetadataCreator" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_creator" relation. */
  create?: InputMaybe<Array<MetadataCreatorWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCreatortoolCreateInput = {
  /** Connect existing "MetadataCreatorTool" nodes to the new "Photo" node, through the "Photo.Metadata_CreatorTool" relation. */
  connect?: InputMaybe<Array<MetadataCreatorToolWhereUniqueInput>>;
  /** Create new "MetadataCreatorTool" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CreatorTool" relation. */
  create?: InputMaybe<Array<MetadataCreatorToolWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCreatortoolUpdateInput = {
  /** Connect existing "MetadataCreatorTool" nodes to the existing "Photo" node, through the "Photo.Metadata_CreatorTool" relation. */
  connect?: InputMaybe<Array<MetadataCreatorToolWhereUniqueInput>>;
  /** Create new "MetadataCreatorTool" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CreatorTool" relation. */
  create?: InputMaybe<Array<MetadataCreatorToolWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCreatorTool" nodes from the "Photo.Metadata_CreatorTool" relation. */
  delete?: InputMaybe<Array<MetadataCreatorToolWhereUniqueInput>>;
  /** Update existing "MetadataCreatorTool" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CreatorTool" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCreatortoolUpdateInput>>;
  /** Upsert existing "MetadataCreatorTool" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CreatorTool" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCreatortoolUpdateInput>>;
};

export type PhotoNestedMetadataCreatorUpdateInput = {
  /** Connect existing "MetadataCreator" nodes to the existing "Photo" node, through the "Photo.Metadata_creator" relation. */
  connect?: InputMaybe<Array<MetadataCreatorWhereUniqueInput>>;
  /** Create new "MetadataCreator" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_creator" relation. */
  create?: InputMaybe<Array<MetadataCreatorWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCreator" nodes from the "Photo.Metadata_creator" relation. */
  delete?: InputMaybe<Array<MetadataCreatorWhereUniqueInput>>;
  /** Update existing "MetadataCreator" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_creator" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCreatorUpdateInput>>;
  /** Upsert existing "MetadataCreator" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_creator" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCreatorUpdateInput>>;
};

export type PhotoNestedMetadataCreditCreateInput = {
  /** Connect existing "MetadataCredit" nodes to the new "Photo" node, through the "Photo.Metadata_Credit" relation. */
  connect?: InputMaybe<Array<MetadataCreditWhereUniqueInput>>;
  /** Create new "MetadataCredit" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Credit" relation. */
  create?: InputMaybe<Array<MetadataCreditWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCreditUpdateInput = {
  /** Connect existing "MetadataCredit" nodes to the existing "Photo" node, through the "Photo.Metadata_Credit" relation. */
  connect?: InputMaybe<Array<MetadataCreditWhereUniqueInput>>;
  /** Create new "MetadataCredit" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Credit" relation. */
  create?: InputMaybe<Array<MetadataCreditWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCredit" nodes from the "Photo.Metadata_Credit" relation. */
  delete?: InputMaybe<Array<MetadataCreditWhereUniqueInput>>;
  /** Update existing "MetadataCredit" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Credit" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCreditUpdateInput>>;
  /** Upsert existing "MetadataCredit" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Credit" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCreditUpdateInput>>;
};

export type PhotoNestedMetadataCustomfield14CreateInput = {
  /** Connect existing "MetadataCustomField14" nodes to the new "Photo" node, through the "Photo.Metadata_CustomField14" relation. */
  connect?: InputMaybe<Array<MetadataCustomField14WhereUniqueInput>>;
  /** Create new "MetadataCustomField14" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CustomField14" relation. */
  create?: InputMaybe<Array<MetadataCustomField14WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCustomfield14UpdateInput = {
  /** Connect existing "MetadataCustomField14" nodes to the existing "Photo" node, through the "Photo.Metadata_CustomField14" relation. */
  connect?: InputMaybe<Array<MetadataCustomField14WhereUniqueInput>>;
  /** Create new "MetadataCustomField14" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField14" relation. */
  create?: InputMaybe<Array<MetadataCustomField14WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCustomField14" nodes from the "Photo.Metadata_CustomField14" relation. */
  delete?: InputMaybe<Array<MetadataCustomField14WhereUniqueInput>>;
  /** Update existing "MetadataCustomField14" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField14" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCustomfield14UpdateInput>>;
  /** Upsert existing "MetadataCustomField14" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField14" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCustomfield14UpdateInput>>;
};

export type PhotoNestedMetadataCustomfield15CreateInput = {
  /** Connect existing "MetadataCustomField15" nodes to the new "Photo" node, through the "Photo.Metadata_CustomField15" relation. */
  connect?: InputMaybe<Array<MetadataCustomField15WhereUniqueInput>>;
  /** Create new "MetadataCustomField15" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CustomField15" relation. */
  create?: InputMaybe<Array<MetadataCustomField15WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCustomfield15UpdateInput = {
  /** Connect existing "MetadataCustomField15" nodes to the existing "Photo" node, through the "Photo.Metadata_CustomField15" relation. */
  connect?: InputMaybe<Array<MetadataCustomField15WhereUniqueInput>>;
  /** Create new "MetadataCustomField15" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField15" relation. */
  create?: InputMaybe<Array<MetadataCustomField15WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCustomField15" nodes from the "Photo.Metadata_CustomField15" relation. */
  delete?: InputMaybe<Array<MetadataCustomField15WhereUniqueInput>>;
  /** Update existing "MetadataCustomField15" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField15" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCustomfield15UpdateInput>>;
  /** Upsert existing "MetadataCustomField15" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField15" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCustomfield15UpdateInput>>;
};

export type PhotoNestedMetadataCustomfield16CreateInput = {
  /** Connect existing "MetadataCustomField16" nodes to the new "Photo" node, through the "Photo.Metadata_CustomField16" relation. */
  connect?: InputMaybe<Array<MetadataCustomField16WhereUniqueInput>>;
  /** Create new "MetadataCustomField16" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CustomField16" relation. */
  create?: InputMaybe<Array<MetadataCustomField16WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCustomfield16UpdateInput = {
  /** Connect existing "MetadataCustomField16" nodes to the existing "Photo" node, through the "Photo.Metadata_CustomField16" relation. */
  connect?: InputMaybe<Array<MetadataCustomField16WhereUniqueInput>>;
  /** Create new "MetadataCustomField16" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField16" relation. */
  create?: InputMaybe<Array<MetadataCustomField16WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCustomField16" nodes from the "Photo.Metadata_CustomField16" relation. */
  delete?: InputMaybe<Array<MetadataCustomField16WhereUniqueInput>>;
  /** Update existing "MetadataCustomField16" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField16" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCustomfield16UpdateInput>>;
  /** Upsert existing "MetadataCustomField16" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField16" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCustomfield16UpdateInput>>;
};

export type PhotoNestedMetadataCustomfield17CreateInput = {
  /** Connect existing "MetadataCustomField17" nodes to the new "Photo" node, through the "Photo.Metadata_CustomField17" relation. */
  connect?: InputMaybe<Array<MetadataCustomField17WhereUniqueInput>>;
  /** Create new "MetadataCustomField17" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CustomField17" relation. */
  create?: InputMaybe<Array<MetadataCustomField17WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCustomfield17UpdateInput = {
  /** Connect existing "MetadataCustomField17" nodes to the existing "Photo" node, through the "Photo.Metadata_CustomField17" relation. */
  connect?: InputMaybe<Array<MetadataCustomField17WhereUniqueInput>>;
  /** Create new "MetadataCustomField17" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField17" relation. */
  create?: InputMaybe<Array<MetadataCustomField17WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCustomField17" nodes from the "Photo.Metadata_CustomField17" relation. */
  delete?: InputMaybe<Array<MetadataCustomField17WhereUniqueInput>>;
  /** Update existing "MetadataCustomField17" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField17" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCustomfield17UpdateInput>>;
  /** Upsert existing "MetadataCustomField17" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField17" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCustomfield17UpdateInput>>;
};

export type PhotoNestedMetadataCustomfield20CreateInput = {
  /** Connect existing "MetadataCustomField20" nodes to the new "Photo" node, through the "Photo.Metadata_CustomField20" relation. */
  connect?: InputMaybe<Array<MetadataCustomField20WhereUniqueInput>>;
  /** Create new "MetadataCustomField20" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CustomField20" relation. */
  create?: InputMaybe<Array<MetadataCustomField20WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCustomfield20UpdateInput = {
  /** Connect existing "MetadataCustomField20" nodes to the existing "Photo" node, through the "Photo.Metadata_CustomField20" relation. */
  connect?: InputMaybe<Array<MetadataCustomField20WhereUniqueInput>>;
  /** Create new "MetadataCustomField20" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField20" relation. */
  create?: InputMaybe<Array<MetadataCustomField20WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCustomField20" nodes from the "Photo.Metadata_CustomField20" relation. */
  delete?: InputMaybe<Array<MetadataCustomField20WhereUniqueInput>>;
  /** Update existing "MetadataCustomField20" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField20" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCustomfield20UpdateInput>>;
  /** Upsert existing "MetadataCustomField20" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField20" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCustomfield20UpdateInput>>;
};

export type PhotoNestedMetadataCustomfield5CreateInput = {
  /** Connect existing "MetadataCustomField5" nodes to the new "Photo" node, through the "Photo.Metadata_CustomField5" relation. */
  connect?: InputMaybe<Array<MetadataCustomField5WhereUniqueInput>>;
  /** Create new "MetadataCustomField5" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CustomField5" relation. */
  create?: InputMaybe<Array<MetadataCustomField5WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCustomfield5UpdateInput = {
  /** Connect existing "MetadataCustomField5" nodes to the existing "Photo" node, through the "Photo.Metadata_CustomField5" relation. */
  connect?: InputMaybe<Array<MetadataCustomField5WhereUniqueInput>>;
  /** Create new "MetadataCustomField5" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField5" relation. */
  create?: InputMaybe<Array<MetadataCustomField5WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCustomField5" nodes from the "Photo.Metadata_CustomField5" relation. */
  delete?: InputMaybe<Array<MetadataCustomField5WhereUniqueInput>>;
  /** Update existing "MetadataCustomField5" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField5" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCustomfield5UpdateInput>>;
  /** Upsert existing "MetadataCustomField5" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField5" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCustomfield5UpdateInput>>;
};

export type PhotoNestedMetadataCustomfield6CreateInput = {
  /** Connect existing "MetadataCustomField6" nodes to the new "Photo" node, through the "Photo.Metadata_CustomField6" relation. */
  connect?: InputMaybe<Array<MetadataCustomField6WhereUniqueInput>>;
  /** Create new "MetadataCustomField6" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CustomField6" relation. */
  create?: InputMaybe<Array<MetadataCustomField6WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCustomfield6UpdateInput = {
  /** Connect existing "MetadataCustomField6" nodes to the existing "Photo" node, through the "Photo.Metadata_CustomField6" relation. */
  connect?: InputMaybe<Array<MetadataCustomField6WhereUniqueInput>>;
  /** Create new "MetadataCustomField6" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField6" relation. */
  create?: InputMaybe<Array<MetadataCustomField6WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCustomField6" nodes from the "Photo.Metadata_CustomField6" relation. */
  delete?: InputMaybe<Array<MetadataCustomField6WhereUniqueInput>>;
  /** Update existing "MetadataCustomField6" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField6" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCustomfield6UpdateInput>>;
  /** Upsert existing "MetadataCustomField6" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField6" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCustomfield6UpdateInput>>;
};

export type PhotoNestedMetadataCustomfield7CreateInput = {
  /** Connect existing "MetadataCustomField7" nodes to the new "Photo" node, through the "Photo.Metadata_CustomField7" relation. */
  connect?: InputMaybe<Array<MetadataCustomField7WhereUniqueInput>>;
  /** Create new "MetadataCustomField7" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CustomField7" relation. */
  create?: InputMaybe<Array<MetadataCustomField7WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCustomfield7UpdateInput = {
  /** Connect existing "MetadataCustomField7" nodes to the existing "Photo" node, through the "Photo.Metadata_CustomField7" relation. */
  connect?: InputMaybe<Array<MetadataCustomField7WhereUniqueInput>>;
  /** Create new "MetadataCustomField7" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField7" relation. */
  create?: InputMaybe<Array<MetadataCustomField7WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCustomField7" nodes from the "Photo.Metadata_CustomField7" relation. */
  delete?: InputMaybe<Array<MetadataCustomField7WhereUniqueInput>>;
  /** Update existing "MetadataCustomField7" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField7" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCustomfield7UpdateInput>>;
  /** Upsert existing "MetadataCustomField7" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField7" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCustomfield7UpdateInput>>;
};

export type PhotoNestedMetadataCustomfield9CreateInput = {
  /** Connect existing "MetadataCustomField9" nodes to the new "Photo" node, through the "Photo.Metadata_CustomField9" relation. */
  connect?: InputMaybe<Array<MetadataCustomField9WhereUniqueInput>>;
  /** Create new "MetadataCustomField9" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_CustomField9" relation. */
  create?: InputMaybe<Array<MetadataCustomField9WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataCustomfield9UpdateInput = {
  /** Connect existing "MetadataCustomField9" nodes to the existing "Photo" node, through the "Photo.Metadata_CustomField9" relation. */
  connect?: InputMaybe<Array<MetadataCustomField9WhereUniqueInput>>;
  /** Create new "MetadataCustomField9" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField9" relation. */
  create?: InputMaybe<Array<MetadataCustomField9WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataCustomField9" nodes from the "Photo.Metadata_CustomField9" relation. */
  delete?: InputMaybe<Array<MetadataCustomField9WhereUniqueInput>>;
  /** Update existing "MetadataCustomField9" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField9" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataCustomfield9UpdateInput>>;
  /** Upsert existing "MetadataCustomField9" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_CustomField9" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataCustomfield9UpdateInput>>;
};

export type PhotoNestedMetadataDatecreatedCreateInput = {
  /** Connect existing "MetadataDateCreated" nodes to the new "Photo" node, through the "Photo.Metadata_DateCreated" relation. */
  connect?: InputMaybe<Array<MetadataDateCreatedWhereUniqueInput>>;
  /** Create new "MetadataDateCreated" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DateCreated" relation. */
  create?: InputMaybe<Array<MetadataDateCreatedWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDatecreatedUpdateInput = {
  /** Connect existing "MetadataDateCreated" nodes to the existing "Photo" node, through the "Photo.Metadata_DateCreated" relation. */
  connect?: InputMaybe<Array<MetadataDateCreatedWhereUniqueInput>>;
  /** Create new "MetadataDateCreated" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DateCreated" relation. */
  create?: InputMaybe<Array<MetadataDateCreatedWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDateCreated" nodes from the "Photo.Metadata_DateCreated" relation. */
  delete?: InputMaybe<Array<MetadataDateCreatedWhereUniqueInput>>;
  /** Update existing "MetadataDateCreated" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DateCreated" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDatecreatedUpdateInput>>;
  /** Upsert existing "MetadataDateCreated" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DateCreated" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDatecreatedUpdateInput>>;
};

export type PhotoNestedMetadataDatetimedigitizedCreateInput = {
  /** Connect existing "MetadataDateTimeDigitized" nodes to the new "Photo" node, through the "Photo.Metadata_DateTimeDigitized" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeDigitizedWhereUniqueInput>>;
  /** Create new "MetadataDateTimeDigitized" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DateTimeDigitized" relation. */
  create?: InputMaybe<Array<MetadataDateTimeDigitizedWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDatetimedigitizedUpdateInput = {
  /** Connect existing "MetadataDateTimeDigitized" nodes to the existing "Photo" node, through the "Photo.Metadata_DateTimeDigitized" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeDigitizedWhereUniqueInput>>;
  /** Create new "MetadataDateTimeDigitized" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DateTimeDigitized" relation. */
  create?: InputMaybe<Array<MetadataDateTimeDigitizedWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDateTimeDigitized" nodes from the "Photo.Metadata_DateTimeDigitized" relation. */
  delete?: InputMaybe<Array<MetadataDateTimeDigitizedWhereUniqueInput>>;
  /** Update existing "MetadataDateTimeDigitized" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DateTimeDigitized" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDatetimedigitizedUpdateInput>>;
  /** Upsert existing "MetadataDateTimeDigitized" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DateTimeDigitized" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDatetimedigitizedUpdateInput>>;
};

export type PhotoNestedMetadataDatetimeoriginalCreateInput = {
  /** Connect existing "MetadataDateTimeOriginal" nodes to the new "Photo" node, through the "Photo.Metadata_DateTimeOriginal" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeOriginalWhereUniqueInput>>;
  /** Create new "MetadataDateTimeOriginal" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DateTimeOriginal" relation. */
  create?: InputMaybe<Array<MetadataDateTimeOriginalWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDatetimeoriginalUpdateInput = {
  /** Connect existing "MetadataDateTimeOriginal" nodes to the existing "Photo" node, through the "Photo.Metadata_DateTimeOriginal" relation. */
  connect?: InputMaybe<Array<MetadataDateTimeOriginalWhereUniqueInput>>;
  /** Create new "MetadataDateTimeOriginal" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DateTimeOriginal" relation. */
  create?: InputMaybe<Array<MetadataDateTimeOriginalWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDateTimeOriginal" nodes from the "Photo.Metadata_DateTimeOriginal" relation. */
  delete?: InputMaybe<Array<MetadataDateTimeOriginalWhereUniqueInput>>;
  /** Update existing "MetadataDateTimeOriginal" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DateTimeOriginal" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDatetimeoriginalUpdateInput>>;
  /** Upsert existing "MetadataDateTimeOriginal" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DateTimeOriginal" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDatetimeoriginalUpdateInput>>;
};

export type PhotoNestedMetadataDeliveryaccountCreateInput = {
  /** Connect existing "MetadataDeliveryAccount" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryAccount" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryAccountWhereUniqueInput>>;
  /** Create new "MetadataDeliveryAccount" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryAccount" relation. */
  create?: InputMaybe<Array<MetadataDeliveryAccountWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliveryaccountUpdateInput = {
  /** Connect existing "MetadataDeliveryAccount" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryAccount" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryAccountWhereUniqueInput>>;
  /** Create new "MetadataDeliveryAccount" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryAccount" relation. */
  create?: InputMaybe<Array<MetadataDeliveryAccountWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryAccount" nodes from the "Photo.Metadata_DeliveryAccount" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryAccountWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryAccount" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryAccount" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliveryaccountUpdateInput>>;
  /** Upsert existing "MetadataDeliveryAccount" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryAccount" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliveryaccountUpdateInput>>;
};

export type PhotoNestedMetadataDeliverycompanyCreateInput = {
  /** Connect existing "MetadataDeliveryCompany" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryCompany" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCompanyWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCompany" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryCompany" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCompanyWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliverycompanyUpdateInput = {
  /** Connect existing "MetadataDeliveryCompany" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryCompany" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCompanyWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCompany" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryCompany" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCompanyWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryCompany" nodes from the "Photo.Metadata_DeliveryCompany" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryCompanyWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryCompany" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryCompany" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliverycompanyUpdateInput>>;
  /** Upsert existing "MetadataDeliveryCompany" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryCompany" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliverycompanyUpdateInput>>;
};

export type PhotoNestedMetadataDeliverycopyrightCreateInput = {
  /** Connect existing "MetadataDeliveryCopyright" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryCopyright" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCopyrightWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCopyright" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryCopyright" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCopyrightWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliverycopyrightUpdateInput = {
  /** Connect existing "MetadataDeliveryCopyright" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryCopyright" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryCopyrightWhereUniqueInput>>;
  /** Create new "MetadataDeliveryCopyright" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryCopyright" relation. */
  create?: InputMaybe<Array<MetadataDeliveryCopyrightWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryCopyright" nodes from the "Photo.Metadata_DeliveryCopyright" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryCopyrightWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryCopyright" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryCopyright" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliverycopyrightUpdateInput>>;
  /** Upsert existing "MetadataDeliveryCopyright" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryCopyright" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliverycopyrightUpdateInput>>;
};

export type PhotoNestedMetadataDeliverydatefolderCreateInput = {
  /** Connect existing "MetadataDeliveryDateFolder" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryDateFolder" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateFolder" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryDateFolder" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateFolderWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliverydatefolderUpdateInput = {
  /** Connect existing "MetadataDeliveryDateFolder" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryDateFolder" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateFolder" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDateFolder" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateFolderWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryDateFolder" nodes from the "Photo.Metadata_DeliveryDateFolder" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDateFolderWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDateFolder" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDateFolder" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliverydatefolderUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDateFolder" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDateFolder" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliverydatefolderUpdateInput>>;
};

export type PhotoNestedMetadataDeliverydatetimeCreateInput = {
  /** Connect existing "MetadataDeliveryDateTime" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryDateTime" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateTimeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateTime" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryDateTime" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateTimeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliverydatetimeUpdateInput = {
  /** Connect existing "MetadataDeliveryDateTime" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryDateTime" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDateTimeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDateTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDateTime" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDateTimeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryDateTime" nodes from the "Photo.Metadata_DeliveryDateTime" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDateTimeWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDateTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDateTime" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliverydatetimeUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDateTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDateTime" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliverydatetimeUpdateInput>>;
};

export type PhotoNestedMetadataDeliverydccreatorCreateInput = {
  /** Connect existing "MetadataDeliveryDcCreator" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryDcCreator" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcCreatorWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcCreator" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryDcCreator" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcCreatorWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliverydccreatorUpdateInput = {
  /** Connect existing "MetadataDeliveryDcCreator" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryDcCreator" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcCreatorWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcCreator" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDcCreator" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcCreatorWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryDcCreator" nodes from the "Photo.Metadata_DeliveryDcCreator" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDcCreatorWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDcCreator" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDcCreator" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliverydccreatorUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDcCreator" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDcCreator" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliverydccreatorUpdateInput>>;
};

export type PhotoNestedMetadataDeliverydcrightsCreateInput = {
  /** Connect existing "MetadataDeliveryDcRights" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryDcRights" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcRightsWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcRights" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryDcRights" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcRightsWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliverydcrightsUpdateInput = {
  /** Connect existing "MetadataDeliveryDcRights" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryDcRights" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryDcRightsWhereUniqueInput>>;
  /** Create new "MetadataDeliveryDcRights" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDcRights" relation. */
  create?: InputMaybe<Array<MetadataDeliveryDcRightsWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryDcRights" nodes from the "Photo.Metadata_DeliveryDcRights" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryDcRightsWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryDcRights" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDcRights" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliverydcrightsUpdateInput>>;
  /** Upsert existing "MetadataDeliveryDcRights" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryDcRights" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliverydcrightsUpdateInput>>;
};

export type PhotoNestedMetadataDeliveryfiletypeCreateInput = {
  /** Connect existing "MetadataDeliveryFileType" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryFileType" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFileTypeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFileType" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryFileType" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFileTypeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliveryfiletypeUpdateInput = {
  /** Connect existing "MetadataDeliveryFileType" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryFileType" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFileTypeWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFileType" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryFileType" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFileTypeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryFileType" nodes from the "Photo.Metadata_DeliveryFileType" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryFileTypeWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryFileType" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryFileType" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliveryfiletypeUpdateInput>>;
  /** Upsert existing "MetadataDeliveryFileType" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryFileType" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliveryfiletypeUpdateInput>>;
};

export type PhotoNestedMetadataDeliveryfolderCreateInput = {
  /** Connect existing "MetadataDeliveryFolder" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryFolder" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFolder" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryFolder" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFolderWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliveryfolderUpdateInput = {
  /** Connect existing "MetadataDeliveryFolder" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryFolder" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryFolderWhereUniqueInput>>;
  /** Create new "MetadataDeliveryFolder" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryFolder" relation. */
  create?: InputMaybe<Array<MetadataDeliveryFolderWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryFolder" nodes from the "Photo.Metadata_DeliveryFolder" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryFolderWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryFolder" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryFolder" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliveryfolderUpdateInput>>;
  /** Upsert existing "MetadataDeliveryFolder" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryFolder" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliveryfolderUpdateInput>>;
};

export type PhotoNestedMetadataDeliverykindCreateInput = {
  /** Connect existing "MetadataDeliveryKind" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryKind" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryKindWhereUniqueInput>>;
  /** Create new "MetadataDeliveryKind" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryKind" relation. */
  create?: InputMaybe<Array<MetadataDeliveryKindWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliverykindUpdateInput = {
  /** Connect existing "MetadataDeliveryKind" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryKind" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryKindWhereUniqueInput>>;
  /** Create new "MetadataDeliveryKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryKind" relation. */
  create?: InputMaybe<Array<MetadataDeliveryKindWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryKind" nodes from the "Photo.Metadata_DeliveryKind" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryKindWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryKind" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliverykindUpdateInput>>;
  /** Upsert existing "MetadataDeliveryKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryKind" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliverykindUpdateInput>>;
};

export type PhotoNestedMetadataDeliverypathCreateInput = {
  /** Connect existing "MetadataDeliveryPath" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryPath" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPathWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPath" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryPath" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPathWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliverypathUpdateInput = {
  /** Connect existing "MetadataDeliveryPath" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryPath" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPathWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPath" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPath" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPathWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryPath" nodes from the "Photo.Metadata_DeliveryPath" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPathWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPath" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPath" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliverypathUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPath" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPath" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliverypathUpdateInput>>;
};

export type PhotoNestedMetadataDeliverypersonshownintheimageCreateInput = {
  /** Connect existing "MetadataDeliveryPersonShownintheImage" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryPersonShownintheImage" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPersonShownintheImage" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryPersonShownintheImage" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput = {
  /** Connect existing "MetadataDeliveryPersonShownintheImage" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryPersonShownintheImage" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPersonShownintheImage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPersonShownintheImage" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryPersonShownintheImage" nodes from the "Photo.Metadata_DeliveryPersonShownintheImage" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPersonShownintheImage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPersonShownintheImage" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliverypersonshownintheimageUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPersonShownintheImage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPersonShownintheImage" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliverypersonshownintheimageUpdateInput>>;
};

export type PhotoNestedMetadataDeliveryphotoshopcreditCreateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopCredit" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopCredit" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopCredit" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopCredit" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopCredit" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopCredit" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopCredit" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopCredit" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryPhotoshopCredit" nodes from the "Photo.Metadata_DeliveryPhotoshopCredit" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPhotoshopCredit" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopCredit" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliveryphotoshopcreditUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPhotoshopCredit" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopCredit" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliveryphotoshopcreditUpdateInput>>;
};

export type PhotoNestedMetadataDeliveryphotoshopsourceCreateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopSource" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopSource" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopSource" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopSource" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput = {
  /** Connect existing "MetadataDeliveryPhotoshopSource" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopSource" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryPhotoshopSource" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopSource" relation. */
  create?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryPhotoshopSource" nodes from the "Photo.Metadata_DeliveryPhotoshopSource" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryPhotoshopSource" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopSource" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliveryphotoshopsourceUpdateInput>>;
  /** Upsert existing "MetadataDeliveryPhotoshopSource" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryPhotoshopSource" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliveryphotoshopsourceUpdateInput>>;
};

export type PhotoNestedMetadataDeliveryserviceCreateInput = {
  /** Connect existing "MetadataDeliveryService" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryService" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryServiceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryService" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryService" relation. */
  create?: InputMaybe<Array<MetadataDeliveryServiceWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliveryserviceUpdateInput = {
  /** Connect existing "MetadataDeliveryService" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryService" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryServiceWhereUniqueInput>>;
  /** Create new "MetadataDeliveryService" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryService" relation. */
  create?: InputMaybe<Array<MetadataDeliveryServiceWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryService" nodes from the "Photo.Metadata_DeliveryService" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryServiceWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryService" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryService" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliveryserviceUpdateInput>>;
  /** Upsert existing "MetadataDeliveryService" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryService" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliveryserviceUpdateInput>>;
};

export type PhotoNestedMetadataDeliverysubjectCreateInput = {
  /** Connect existing "MetadataDeliverySubject" nodes to the new "Photo" node, through the "Photo.Metadata_DeliverySubject" relation. */
  connect?: InputMaybe<Array<MetadataDeliverySubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliverySubject" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliverySubject" relation. */
  create?: InputMaybe<Array<MetadataDeliverySubjectWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliverysubjectUpdateInput = {
  /** Connect existing "MetadataDeliverySubject" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliverySubject" relation. */
  connect?: InputMaybe<Array<MetadataDeliverySubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliverySubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliverySubject" relation. */
  create?: InputMaybe<Array<MetadataDeliverySubjectWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliverySubject" nodes from the "Photo.Metadata_DeliverySubject" relation. */
  delete?: InputMaybe<Array<MetadataDeliverySubjectWhereUniqueInput>>;
  /** Update existing "MetadataDeliverySubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliverySubject" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliverysubjectUpdateInput>>;
  /** Upsert existing "MetadataDeliverySubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliverySubject" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliverysubjectUpdateInput>>;
};

export type PhotoNestedMetadataDeliveryundersubjectCreateInput = {
  /** Connect existing "MetadataDeliveryUnderSubject" nodes to the new "Photo" node, through the "Photo.Metadata_DeliveryUnderSubject" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliveryUnderSubject" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DeliveryUnderSubject" relation. */
  create?: InputMaybe<Array<MetadataDeliveryUnderSubjectWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDeliveryundersubjectUpdateInput = {
  /** Connect existing "MetadataDeliveryUnderSubject" nodes to the existing "Photo" node, through the "Photo.Metadata_DeliveryUnderSubject" relation. */
  connect?: InputMaybe<Array<MetadataDeliveryUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataDeliveryUnderSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryUnderSubject" relation. */
  create?: InputMaybe<Array<MetadataDeliveryUnderSubjectWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDeliveryUnderSubject" nodes from the "Photo.Metadata_DeliveryUnderSubject" relation. */
  delete?: InputMaybe<Array<MetadataDeliveryUnderSubjectWhereUniqueInput>>;
  /** Update existing "MetadataDeliveryUnderSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryUnderSubject" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDeliveryundersubjectUpdateInput>>;
  /** Upsert existing "MetadataDeliveryUnderSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DeliveryUnderSubject" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDeliveryundersubjectUpdateInput>>;
};

export type PhotoNestedMetadataDepartmentCreateInput = {
  /** Connect existing "MetadataDepartment" nodes to the new "Photo" node, through the "Photo.Metadata_Department" relation. */
  connect?: InputMaybe<Array<MetadataDepartmentWhereUniqueInput>>;
  /** Create new "MetadataDepartment" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Department" relation. */
  create?: InputMaybe<Array<MetadataDepartmentWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDepartmentUpdateInput = {
  /** Connect existing "MetadataDepartment" nodes to the existing "Photo" node, through the "Photo.Metadata_Department" relation. */
  connect?: InputMaybe<Array<MetadataDepartmentWhereUniqueInput>>;
  /** Create new "MetadataDepartment" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Department" relation. */
  create?: InputMaybe<Array<MetadataDepartmentWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDepartment" nodes from the "Photo.Metadata_Department" relation. */
  delete?: InputMaybe<Array<MetadataDepartmentWhereUniqueInput>>;
  /** Update existing "MetadataDepartment" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Department" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDepartmentUpdateInput>>;
  /** Upsert existing "MetadataDepartment" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Department" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDepartmentUpdateInput>>;
};

export type PhotoNestedMetadataDescriptionCreateInput = {
  /** Connect existing "MetadataDescription" nodes to the new "Photo" node, through the "Photo.Metadata_description" relation. */
  connect?: InputMaybe<Array<MetadataDescriptionWhereUniqueInput>>;
  /** Create new "MetadataDescription" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_description" relation. */
  create?: InputMaybe<Array<MetadataDescriptionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDescriptionUpdateInput = {
  /** Connect existing "MetadataDescription" nodes to the existing "Photo" node, through the "Photo.Metadata_description" relation. */
  connect?: InputMaybe<Array<MetadataDescriptionWhereUniqueInput>>;
  /** Create new "MetadataDescription" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_description" relation. */
  create?: InputMaybe<Array<MetadataDescriptionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDescription" nodes from the "Photo.Metadata_description" relation. */
  delete?: InputMaybe<Array<MetadataDescriptionWhereUniqueInput>>;
  /** Update existing "MetadataDescription" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_description" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDescriptionUpdateInput>>;
  /** Upsert existing "MetadataDescription" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_description" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDescriptionUpdateInput>>;
};

export type PhotoNestedMetadataDigitalasseturlCreateInput = {
  /** Connect existing "MetadataDigitalAssetUrl" nodes to the new "Photo" node, through the "Photo.Metadata_DigitalAssetURL" relation. */
  connect?: InputMaybe<Array<MetadataDigitalAssetUrlWhereUniqueInput>>;
  /** Create new "MetadataDigitalAssetUrl" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DigitalAssetURL" relation. */
  create?: InputMaybe<Array<MetadataDigitalAssetUrlWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDigitalasseturlUpdateInput = {
  /** Connect existing "MetadataDigitalAssetUrl" nodes to the existing "Photo" node, through the "Photo.Metadata_DigitalAssetURL" relation. */
  connect?: InputMaybe<Array<MetadataDigitalAssetUrlWhereUniqueInput>>;
  /** Create new "MetadataDigitalAssetUrl" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DigitalAssetURL" relation. */
  create?: InputMaybe<Array<MetadataDigitalAssetUrlWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDigitalAssetUrl" nodes from the "Photo.Metadata_DigitalAssetURL" relation. */
  delete?: InputMaybe<Array<MetadataDigitalAssetUrlWhereUniqueInput>>;
  /** Update existing "MetadataDigitalAssetUrl" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DigitalAssetURL" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDigitalasseturlUpdateInput>>;
  /** Upsert existing "MetadataDigitalAssetUrl" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DigitalAssetURL" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDigitalasseturlUpdateInput>>;
};

export type PhotoNestedMetadataDivisionCreateInput = {
  /** Connect existing "MetadataDivision" nodes to the new "Photo" node, through the "Photo.Metadata_Division" relation. */
  connect?: InputMaybe<Array<MetadataDivisionWhereUniqueInput>>;
  /** Create new "MetadataDivision" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Division" relation. */
  create?: InputMaybe<Array<MetadataDivisionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDivisionUpdateInput = {
  /** Connect existing "MetadataDivision" nodes to the existing "Photo" node, through the "Photo.Metadata_Division" relation. */
  connect?: InputMaybe<Array<MetadataDivisionWhereUniqueInput>>;
  /** Create new "MetadataDivision" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Division" relation. */
  create?: InputMaybe<Array<MetadataDivisionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDivision" nodes from the "Photo.Metadata_Division" relation. */
  delete?: InputMaybe<Array<MetadataDivisionWhereUniqueInput>>;
  /** Update existing "MetadataDivision" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Division" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDivisionUpdateInput>>;
  /** Upsert existing "MetadataDivision" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Division" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDivisionUpdateInput>>;
};

export type PhotoNestedMetadataDocumenttextCreateInput = {
  /** Connect existing "MetadataDocumentText" nodes to the new "Photo" node, through the "Photo.Metadata_DocumentText" relation. */
  connect?: InputMaybe<Array<MetadataDocumentTextWhereUniqueInput>>;
  /** Create new "MetadataDocumentText" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_DocumentText" relation. */
  create?: InputMaybe<Array<MetadataDocumentTextWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataDocumenttextUpdateInput = {
  /** Connect existing "MetadataDocumentText" nodes to the existing "Photo" node, through the "Photo.Metadata_DocumentText" relation. */
  connect?: InputMaybe<Array<MetadataDocumentTextWhereUniqueInput>>;
  /** Create new "MetadataDocumentText" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DocumentText" relation. */
  create?: InputMaybe<Array<MetadataDocumentTextWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataDocumentText" nodes from the "Photo.Metadata_DocumentText" relation. */
  delete?: InputMaybe<Array<MetadataDocumentTextWhereUniqueInput>>;
  /** Update existing "MetadataDocumentText" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DocumentText" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataDocumenttextUpdateInput>>;
  /** Upsert existing "MetadataDocumentText" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_DocumentText" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataDocumenttextUpdateInput>>;
};

export type PhotoNestedMetadataEditionCreateInput = {
  /** Connect existing "MetadataEdition" nodes to the new "Photo" node, through the "Photo.Metadata_Edition" relation. */
  connect?: InputMaybe<Array<MetadataEditionWhereUniqueInput>>;
  /** Create new "MetadataEdition" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Edition" relation. */
  create?: InputMaybe<Array<MetadataEditionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataEditionUpdateInput = {
  /** Connect existing "MetadataEdition" nodes to the existing "Photo" node, through the "Photo.Metadata_Edition" relation. */
  connect?: InputMaybe<Array<MetadataEditionWhereUniqueInput>>;
  /** Create new "MetadataEdition" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Edition" relation. */
  create?: InputMaybe<Array<MetadataEditionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataEdition" nodes from the "Photo.Metadata_Edition" relation. */
  delete?: InputMaybe<Array<MetadataEditionWhereUniqueInput>>;
  /** Update existing "MetadataEdition" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Edition" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataEditionUpdateInput>>;
  /** Upsert existing "MetadataEdition" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Edition" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataEditionUpdateInput>>;
};

export type PhotoNestedMetadataEditorialversionCreateInput = {
  /** Connect existing "MetadataEditorialVersion" nodes to the new "Photo" node, through the "Photo.Metadata_EditorialVersion" relation. */
  connect?: InputMaybe<Array<MetadataEditorialVersionWhereUniqueInput>>;
  /** Create new "MetadataEditorialVersion" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_EditorialVersion" relation. */
  create?: InputMaybe<Array<MetadataEditorialVersionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataEditorialversionUpdateInput = {
  /** Connect existing "MetadataEditorialVersion" nodes to the existing "Photo" node, through the "Photo.Metadata_EditorialVersion" relation. */
  connect?: InputMaybe<Array<MetadataEditorialVersionWhereUniqueInput>>;
  /** Create new "MetadataEditorialVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EditorialVersion" relation. */
  create?: InputMaybe<Array<MetadataEditorialVersionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataEditorialVersion" nodes from the "Photo.Metadata_EditorialVersion" relation. */
  delete?: InputMaybe<Array<MetadataEditorialVersionWhereUniqueInput>>;
  /** Update existing "MetadataEditorialVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EditorialVersion" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataEditorialversionUpdateInput>>;
  /** Upsert existing "MetadataEditorialVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EditorialVersion" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataEditorialversionUpdateInput>>;
};

export type PhotoNestedMetadataEditstatusCreateInput = {
  /** Connect existing "MetadataEditStatus" nodes to the new "Photo" node, through the "Photo.Metadata_EditStatus" relation. */
  connect?: InputMaybe<Array<MetadataEditStatusWhereUniqueInput>>;
  /** Create new "MetadataEditStatus" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_EditStatus" relation. */
  create?: InputMaybe<Array<MetadataEditStatusWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataEditstatusUpdateInput = {
  /** Connect existing "MetadataEditStatus" nodes to the existing "Photo" node, through the "Photo.Metadata_EditStatus" relation. */
  connect?: InputMaybe<Array<MetadataEditStatusWhereUniqueInput>>;
  /** Create new "MetadataEditStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EditStatus" relation. */
  create?: InputMaybe<Array<MetadataEditStatusWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataEditStatus" nodes from the "Photo.Metadata_EditStatus" relation. */
  delete?: InputMaybe<Array<MetadataEditStatusWhereUniqueInput>>;
  /** Update existing "MetadataEditStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EditStatus" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataEditstatusUpdateInput>>;
  /** Upsert existing "MetadataEditStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EditStatus" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataEditstatusUpdateInput>>;
};

export type PhotoNestedMetadataEnvironnementphotoCreateInput = {
  /** Connect existing "MetadataEnvironnementPhoto" nodes to the new "Photo" node, through the "Photo.Metadata_EnvironnementPhoto" relation. */
  connect?: InputMaybe<Array<MetadataEnvironnementPhotoWhereUniqueInput>>;
  /** Create new "MetadataEnvironnementPhoto" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_EnvironnementPhoto" relation. */
  create?: InputMaybe<Array<MetadataEnvironnementPhotoWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataEnvironnementphotoUpdateInput = {
  /** Connect existing "MetadataEnvironnementPhoto" nodes to the existing "Photo" node, through the "Photo.Metadata_EnvironnementPhoto" relation. */
  connect?: InputMaybe<Array<MetadataEnvironnementPhotoWhereUniqueInput>>;
  /** Create new "MetadataEnvironnementPhoto" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EnvironnementPhoto" relation. */
  create?: InputMaybe<Array<MetadataEnvironnementPhotoWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataEnvironnementPhoto" nodes from the "Photo.Metadata_EnvironnementPhoto" relation. */
  delete?: InputMaybe<Array<MetadataEnvironnementPhotoWhereUniqueInput>>;
  /** Update existing "MetadataEnvironnementPhoto" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EnvironnementPhoto" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataEnvironnementphotoUpdateInput>>;
  /** Upsert existing "MetadataEnvironnementPhoto" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EnvironnementPhoto" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataEnvironnementphotoUpdateInput>>;
};

export type PhotoNestedMetadataEquipmentinstitutionCreateInput = {
  /** Connect existing "MetadataEquipmentInstitution" nodes to the new "Photo" node, through the "Photo.Metadata_EquipmentInstitution" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentInstitutionWhereUniqueInput>>;
  /** Create new "MetadataEquipmentInstitution" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_EquipmentInstitution" relation. */
  create?: InputMaybe<Array<MetadataEquipmentInstitutionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataEquipmentinstitutionUpdateInput = {
  /** Connect existing "MetadataEquipmentInstitution" nodes to the existing "Photo" node, through the "Photo.Metadata_EquipmentInstitution" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentInstitutionWhereUniqueInput>>;
  /** Create new "MetadataEquipmentInstitution" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EquipmentInstitution" relation. */
  create?: InputMaybe<Array<MetadataEquipmentInstitutionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataEquipmentInstitution" nodes from the "Photo.Metadata_EquipmentInstitution" relation. */
  delete?: InputMaybe<Array<MetadataEquipmentInstitutionWhereUniqueInput>>;
  /** Update existing "MetadataEquipmentInstitution" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EquipmentInstitution" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataEquipmentinstitutionUpdateInput>>;
  /** Upsert existing "MetadataEquipmentInstitution" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EquipmentInstitution" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataEquipmentinstitutionUpdateInput>>;
};

export type PhotoNestedMetadataEquipmentmanufacturerCreateInput = {
  /** Connect existing "MetadataEquipmentManufacturer" nodes to the new "Photo" node, through the "Photo.Metadata_EquipmentManufacturer" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentManufacturerWhereUniqueInput>>;
  /** Create new "MetadataEquipmentManufacturer" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_EquipmentManufacturer" relation. */
  create?: InputMaybe<Array<MetadataEquipmentManufacturerWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataEquipmentmanufacturerUpdateInput = {
  /** Connect existing "MetadataEquipmentManufacturer" nodes to the existing "Photo" node, through the "Photo.Metadata_EquipmentManufacturer" relation. */
  connect?: InputMaybe<Array<MetadataEquipmentManufacturerWhereUniqueInput>>;
  /** Create new "MetadataEquipmentManufacturer" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EquipmentManufacturer" relation. */
  create?: InputMaybe<Array<MetadataEquipmentManufacturerWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataEquipmentManufacturer" nodes from the "Photo.Metadata_EquipmentManufacturer" relation. */
  delete?: InputMaybe<Array<MetadataEquipmentManufacturerWhereUniqueInput>>;
  /** Update existing "MetadataEquipmentManufacturer" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EquipmentManufacturer" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataEquipmentmanufacturerUpdateInput>>;
  /** Upsert existing "MetadataEquipmentManufacturer" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_EquipmentManufacturer" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataEquipmentmanufacturerUpdateInput>>;
};

export type PhotoNestedMetadataEventCreateInput = {
  /** Connect existing "MetadataEvent" nodes to the new "Photo" node, through the "Photo.Metadata_Event" relation. */
  connect?: InputMaybe<Array<MetadataEventWhereUniqueInput>>;
  /** Create new "MetadataEvent" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Event" relation. */
  create?: InputMaybe<Array<MetadataEventWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataEventUpdateInput = {
  /** Connect existing "MetadataEvent" nodes to the existing "Photo" node, through the "Photo.Metadata_Event" relation. */
  connect?: InputMaybe<Array<MetadataEventWhereUniqueInput>>;
  /** Create new "MetadataEvent" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Event" relation. */
  create?: InputMaybe<Array<MetadataEventWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataEvent" nodes from the "Photo.Metadata_Event" relation. */
  delete?: InputMaybe<Array<MetadataEventWhereUniqueInput>>;
  /** Update existing "MetadataEvent" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Event" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataEventUpdateInput>>;
  /** Upsert existing "MetadataEvent" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Event" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataEventUpdateInput>>;
};

export type PhotoNestedMetadataExifCreateInput = {
  /** Connect existing "MetadataExif" nodes to the new "Photo" node, through the "Photo.Metadata_Exif" relation. */
  connect?: InputMaybe<Array<MetadataExifWhereUniqueInput>>;
  /** Create new "MetadataExif" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Exif" relation. */
  create?: InputMaybe<Array<MetadataExifWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataExifUpdateInput = {
  /** Connect existing "MetadataExif" nodes to the existing "Photo" node, through the "Photo.Metadata_Exif" relation. */
  connect?: InputMaybe<Array<MetadataExifWhereUniqueInput>>;
  /** Create new "MetadataExif" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Exif" relation. */
  create?: InputMaybe<Array<MetadataExifWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataExif" nodes from the "Photo.Metadata_Exif" relation. */
  delete?: InputMaybe<Array<MetadataExifWhereUniqueInput>>;
  /** Update existing "MetadataExif" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Exif" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataExifUpdateInput>>;
  /** Upsert existing "MetadataExif" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Exif" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataExifUpdateInput>>;
};

export type PhotoNestedMetadataFabstorageCreateInput = {
  /** Connect existing "MetadataFabStorage" nodes to the new "Photo" node, through the "Photo.Metadata_FabStorage" relation. */
  connect?: InputMaybe<Array<MetadataFabStorageWhereUniqueInput>>;
  /** Create new "MetadataFabStorage" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_FabStorage" relation. */
  create?: InputMaybe<Array<MetadataFabStorageWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataFabstorageUpdateInput = {
  /** Connect existing "MetadataFabStorage" nodes to the existing "Photo" node, through the "Photo.Metadata_FabStorage" relation. */
  connect?: InputMaybe<Array<MetadataFabStorageWhereUniqueInput>>;
  /** Create new "MetadataFabStorage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FabStorage" relation. */
  create?: InputMaybe<Array<MetadataFabStorageWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataFabStorage" nodes from the "Photo.Metadata_FabStorage" relation. */
  delete?: InputMaybe<Array<MetadataFabStorageWhereUniqueInput>>;
  /** Update existing "MetadataFabStorage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FabStorage" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataFabstorageUpdateInput>>;
  /** Upsert existing "MetadataFabStorage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FabStorage" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataFabstorageUpdateInput>>;
};

export type PhotoNestedMetadataFilecheckforCreateInput = {
  /** Connect existing "MetadataFileCheckFor" nodes to the new "Photo" node, through the "Photo.Metadata_FileCheckFor" relation. */
  connect?: InputMaybe<Array<MetadataFileCheckForWhereUniqueInput>>;
  /** Create new "MetadataFileCheckFor" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_FileCheckFor" relation. */
  create?: InputMaybe<Array<MetadataFileCheckForWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataFilecheckforUpdateInput = {
  /** Connect existing "MetadataFileCheckFor" nodes to the existing "Photo" node, through the "Photo.Metadata_FileCheckFor" relation. */
  connect?: InputMaybe<Array<MetadataFileCheckForWhereUniqueInput>>;
  /** Create new "MetadataFileCheckFor" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileCheckFor" relation. */
  create?: InputMaybe<Array<MetadataFileCheckForWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataFileCheckFor" nodes from the "Photo.Metadata_FileCheckFor" relation. */
  delete?: InputMaybe<Array<MetadataFileCheckForWhereUniqueInput>>;
  /** Update existing "MetadataFileCheckFor" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileCheckFor" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataFilecheckforUpdateInput>>;
  /** Upsert existing "MetadataFileCheckFor" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileCheckFor" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataFilecheckforUpdateInput>>;
};

export type PhotoNestedMetadataFileinfobitsperpixelCreateInput = {
  /** Connect existing "MetadataFileInfoBitsPerPixel" nodes to the new "Photo" node, through the "Photo.Metadata_FileInfoBitsPerPixel" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWhereUniqueInput>>;
  /** Create new "MetadataFileInfoBitsPerPixel" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_FileInfoBitsPerPixel" relation. */
  create?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataFileinfobitsperpixelUpdateInput = {
  /** Connect existing "MetadataFileInfoBitsPerPixel" nodes to the existing "Photo" node, through the "Photo.Metadata_FileInfoBitsPerPixel" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWhereUniqueInput>>;
  /** Create new "MetadataFileInfoBitsPerPixel" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoBitsPerPixel" relation. */
  create?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataFileInfoBitsPerPixel" nodes from the "Photo.Metadata_FileInfoBitsPerPixel" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoBitsPerPixelWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoBitsPerPixel" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoBitsPerPixel" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataFileinfobitsperpixelUpdateInput>>;
  /** Upsert existing "MetadataFileInfoBitsPerPixel" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoBitsPerPixel" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataFileinfobitsperpixelUpdateInput>>;
};

export type PhotoNestedMetadataFileinfoframesCreateInput = {
  /** Connect existing "MetadataFileInfoFrames" nodes to the new "Photo" node, through the "Photo.Metadata_FileInfoFrames" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoFramesWhereUniqueInput>>;
  /** Create new "MetadataFileInfoFrames" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_FileInfoFrames" relation. */
  create?: InputMaybe<Array<MetadataFileInfoFramesWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataFileinfoframesUpdateInput = {
  /** Connect existing "MetadataFileInfoFrames" nodes to the existing "Photo" node, through the "Photo.Metadata_FileInfoFrames" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoFramesWhereUniqueInput>>;
  /** Create new "MetadataFileInfoFrames" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoFrames" relation. */
  create?: InputMaybe<Array<MetadataFileInfoFramesWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataFileInfoFrames" nodes from the "Photo.Metadata_FileInfoFrames" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoFramesWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoFrames" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoFrames" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataFileinfoframesUpdateInput>>;
  /** Upsert existing "MetadataFileInfoFrames" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoFrames" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataFileinfoframesUpdateInput>>;
};

export type PhotoNestedMetadataFileinfophotometricinterpretationCreateInput = {
  /** Connect existing "MetadataFileInfoPhotometricInterpretation" nodes to the new "Photo" node, through the "Photo.Metadata_FileInfoPhotometricInterpretation" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWhereUniqueInput>>;
  /** Create new "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_FileInfoPhotometricInterpretation" relation. */
  create?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput = {
  /** Connect existing "MetadataFileInfoPhotometricInterpretation" nodes to the existing "Photo" node, through the "Photo.Metadata_FileInfoPhotometricInterpretation" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWhereUniqueInput>>;
  /** Create new "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoPhotometricInterpretation" relation. */
  create?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataFileInfoPhotometricInterpretation" nodes from the "Photo.Metadata_FileInfoPhotometricInterpretation" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoPhotometricInterpretation" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataFileinfophotometricinterpretationUpdateInput>>;
  /** Upsert existing "MetadataFileInfoPhotometricInterpretation" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoPhotometricInterpretation" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataFileinfophotometricinterpretationUpdateInput>>;
};

export type PhotoNestedMetadataFileinfotransfersyntaxCreateInput = {
  /** Connect existing "MetadataFileInfoTransferSyntax" nodes to the new "Photo" node, through the "Photo.Metadata_FileInfoTransferSyntax" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWhereUniqueInput>>;
  /** Create new "MetadataFileInfoTransferSyntax" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_FileInfoTransferSyntax" relation. */
  create?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataFileinfotransfersyntaxUpdateInput = {
  /** Connect existing "MetadataFileInfoTransferSyntax" nodes to the existing "Photo" node, through the "Photo.Metadata_FileInfoTransferSyntax" relation. */
  connect?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWhereUniqueInput>>;
  /** Create new "MetadataFileInfoTransferSyntax" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoTransferSyntax" relation. */
  create?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataFileInfoTransferSyntax" nodes from the "Photo.Metadata_FileInfoTransferSyntax" relation. */
  delete?: InputMaybe<Array<MetadataFileInfoTransferSyntaxWhereUniqueInput>>;
  /** Update existing "MetadataFileInfoTransferSyntax" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoTransferSyntax" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataFileinfotransfersyntaxUpdateInput>>;
  /** Upsert existing "MetadataFileInfoTransferSyntax" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileInfoTransferSyntax" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataFileinfotransfersyntaxUpdateInput>>;
};

export type PhotoNestedMetadataFilestageCreateInput = {
  /** Connect existing "MetadataFileStage" nodes to the new "Photo" node, through the "Photo.Metadata_FileStage" relation. */
  connect?: InputMaybe<Array<MetadataFileStageWhereUniqueInput>>;
  /** Create new "MetadataFileStage" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_FileStage" relation. */
  create?: InputMaybe<Array<MetadataFileStageWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataFilestageUpdateInput = {
  /** Connect existing "MetadataFileStage" nodes to the existing "Photo" node, through the "Photo.Metadata_FileStage" relation. */
  connect?: InputMaybe<Array<MetadataFileStageWhereUniqueInput>>;
  /** Create new "MetadataFileStage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileStage" relation. */
  create?: InputMaybe<Array<MetadataFileStageWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataFileStage" nodes from the "Photo.Metadata_FileStage" relation. */
  delete?: InputMaybe<Array<MetadataFileStageWhereUniqueInput>>;
  /** Update existing "MetadataFileStage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileStage" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataFilestageUpdateInput>>;
  /** Upsert existing "MetadataFileStage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileStage" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataFilestageUpdateInput>>;
};

export type PhotoNestedMetadataFilestatusCreateInput = {
  /** Connect existing "MetadataFileStatus" nodes to the new "Photo" node, through the "Photo.Metadata_FileStatus" relation. */
  connect?: InputMaybe<Array<MetadataFileStatusWhereUniqueInput>>;
  /** Create new "MetadataFileStatus" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_FileStatus" relation. */
  create?: InputMaybe<Array<MetadataFileStatusWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataFilestatusUpdateInput = {
  /** Connect existing "MetadataFileStatus" nodes to the existing "Photo" node, through the "Photo.Metadata_FileStatus" relation. */
  connect?: InputMaybe<Array<MetadataFileStatusWhereUniqueInput>>;
  /** Create new "MetadataFileStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileStatus" relation. */
  create?: InputMaybe<Array<MetadataFileStatusWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataFileStatus" nodes from the "Photo.Metadata_FileStatus" relation. */
  delete?: InputMaybe<Array<MetadataFileStatusWhereUniqueInput>>;
  /** Update existing "MetadataFileStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileStatus" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataFilestatusUpdateInput>>;
  /** Upsert existing "MetadataFileStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileStatus" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataFilestatusUpdateInput>>;
};

export type PhotoNestedMetadataFiletypeCreateInput = {
  /** Connect existing "MetadataFileType" nodes to the new "Photo" node, through the "Photo.Metadata_FileType" relation. */
  connect?: InputMaybe<Array<MetadataFileTypeWhereUniqueInput>>;
  /** Create new "MetadataFileType" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_FileType" relation. */
  create?: InputMaybe<Array<MetadataFileTypeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataFiletypeUpdateInput = {
  /** Connect existing "MetadataFileType" nodes to the existing "Photo" node, through the "Photo.Metadata_FileType" relation. */
  connect?: InputMaybe<Array<MetadataFileTypeWhereUniqueInput>>;
  /** Create new "MetadataFileType" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileType" relation. */
  create?: InputMaybe<Array<MetadataFileTypeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataFileType" nodes from the "Photo.Metadata_FileType" relation. */
  delete?: InputMaybe<Array<MetadataFileTypeWhereUniqueInput>>;
  /** Update existing "MetadataFileType" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileType" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataFiletypeUpdateInput>>;
  /** Upsert existing "MetadataFileType" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FileType" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataFiletypeUpdateInput>>;
};

export type PhotoNestedMetadataFixtureidentifierCreateInput = {
  /** Connect existing "MetadataFixtureIdentifier" nodes to the new "Photo" node, through the "Photo.Metadata_FixtureIdentifier" relation. */
  connect?: InputMaybe<Array<MetadataFixtureIdentifierWhereUniqueInput>>;
  /** Create new "MetadataFixtureIdentifier" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_FixtureIdentifier" relation. */
  create?: InputMaybe<Array<MetadataFixtureIdentifierWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataFixtureidentifierUpdateInput = {
  /** Connect existing "MetadataFixtureIdentifier" nodes to the existing "Photo" node, through the "Photo.Metadata_FixtureIdentifier" relation. */
  connect?: InputMaybe<Array<MetadataFixtureIdentifierWhereUniqueInput>>;
  /** Create new "MetadataFixtureIdentifier" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FixtureIdentifier" relation. */
  create?: InputMaybe<Array<MetadataFixtureIdentifierWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataFixtureIdentifier" nodes from the "Photo.Metadata_FixtureIdentifier" relation. */
  delete?: InputMaybe<Array<MetadataFixtureIdentifierWhereUniqueInput>>;
  /** Update existing "MetadataFixtureIdentifier" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FixtureIdentifier" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataFixtureidentifierUpdateInput>>;
  /** Upsert existing "MetadataFixtureIdentifier" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_FixtureIdentifier" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataFixtureidentifierUpdateInput>>;
};

export type PhotoNestedMetadataFormatCreateInput = {
  /** Connect existing "MetadataFormat" nodes to the new "Photo" node, through the "Photo.Metadata_Format" relation. */
  connect?: InputMaybe<Array<MetadataFormatWhereUniqueInput>>;
  /** Create new "MetadataFormat" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Format" relation. */
  create?: InputMaybe<Array<MetadataFormatWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataFormatUpdateInput = {
  /** Connect existing "MetadataFormat" nodes to the existing "Photo" node, through the "Photo.Metadata_Format" relation. */
  connect?: InputMaybe<Array<MetadataFormatWhereUniqueInput>>;
  /** Create new "MetadataFormat" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Format" relation. */
  create?: InputMaybe<Array<MetadataFormatWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataFormat" nodes from the "Photo.Metadata_Format" relation. */
  delete?: InputMaybe<Array<MetadataFormatWhereUniqueInput>>;
  /** Update existing "MetadataFormat" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Format" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataFormatUpdateInput>>;
  /** Upsert existing "MetadataFormat" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Format" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataFormatUpdateInput>>;
};

export type PhotoNestedMetadataGpslatitudeCreateInput = {
  /** Connect existing "MetadataGpsLatitude" nodes to the new "Photo" node, through the "Photo.Metadata_GPSLatitude" relation. */
  connect?: InputMaybe<Array<MetadataGpsLatitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLatitude" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_GPSLatitude" relation. */
  create?: InputMaybe<Array<MetadataGpsLatitudeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataGpslatitudeUpdateInput = {
  /** Connect existing "MetadataGpsLatitude" nodes to the existing "Photo" node, through the "Photo.Metadata_GPSLatitude" relation. */
  connect?: InputMaybe<Array<MetadataGpsLatitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLatitude" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_GPSLatitude" relation. */
  create?: InputMaybe<Array<MetadataGpsLatitudeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataGpsLatitude" nodes from the "Photo.Metadata_GPSLatitude" relation. */
  delete?: InputMaybe<Array<MetadataGpsLatitudeWhereUniqueInput>>;
  /** Update existing "MetadataGpsLatitude" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_GPSLatitude" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataGpslatitudeUpdateInput>>;
  /** Upsert existing "MetadataGpsLatitude" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_GPSLatitude" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataGpslatitudeUpdateInput>>;
};

export type PhotoNestedMetadataGpslongitudeCreateInput = {
  /** Connect existing "MetadataGpsLongitude" nodes to the new "Photo" node, through the "Photo.Metadata_GPSLongitude" relation. */
  connect?: InputMaybe<Array<MetadataGpsLongitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLongitude" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_GPSLongitude" relation. */
  create?: InputMaybe<Array<MetadataGpsLongitudeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataGpslongitudeUpdateInput = {
  /** Connect existing "MetadataGpsLongitude" nodes to the existing "Photo" node, through the "Photo.Metadata_GPSLongitude" relation. */
  connect?: InputMaybe<Array<MetadataGpsLongitudeWhereUniqueInput>>;
  /** Create new "MetadataGpsLongitude" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_GPSLongitude" relation. */
  create?: InputMaybe<Array<MetadataGpsLongitudeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataGpsLongitude" nodes from the "Photo.Metadata_GPSLongitude" relation. */
  delete?: InputMaybe<Array<MetadataGpsLongitudeWhereUniqueInput>>;
  /** Update existing "MetadataGpsLongitude" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_GPSLongitude" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataGpslongitudeUpdateInput>>;
  /** Upsert existing "MetadataGpsLongitude" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_GPSLongitude" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataGpslongitudeUpdateInput>>;
};

export type PhotoNestedMetadataGpsprocessingmethodCreateInput = {
  /** Connect existing "MetadataGpsProcessingMethod" nodes to the new "Photo" node, through the "Photo.Metadata_GPSProcessingMethod" relation. */
  connect?: InputMaybe<Array<MetadataGpsProcessingMethodWhereUniqueInput>>;
  /** Create new "MetadataGpsProcessingMethod" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_GPSProcessingMethod" relation. */
  create?: InputMaybe<Array<MetadataGpsProcessingMethodWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataGpsprocessingmethodUpdateInput = {
  /** Connect existing "MetadataGpsProcessingMethod" nodes to the existing "Photo" node, through the "Photo.Metadata_GPSProcessingMethod" relation. */
  connect?: InputMaybe<Array<MetadataGpsProcessingMethodWhereUniqueInput>>;
  /** Create new "MetadataGpsProcessingMethod" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_GPSProcessingMethod" relation. */
  create?: InputMaybe<Array<MetadataGpsProcessingMethodWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataGpsProcessingMethod" nodes from the "Photo.Metadata_GPSProcessingMethod" relation. */
  delete?: InputMaybe<Array<MetadataGpsProcessingMethodWhereUniqueInput>>;
  /** Update existing "MetadataGpsProcessingMethod" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_GPSProcessingMethod" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataGpsprocessingmethodUpdateInput>>;
  /** Upsert existing "MetadataGpsProcessingMethod" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_GPSProcessingMethod" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataGpsprocessingmethodUpdateInput>>;
};

export type PhotoNestedMetadataHeaderCreateInput = {
  /** Connect existing "MetadataHeader" nodes to the new "Photo" node, through the "Photo.Metadata_Header" relation. */
  connect?: InputMaybe<Array<MetadataHeaderWhereUniqueInput>>;
  /** Create new "MetadataHeader" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Header" relation. */
  create?: InputMaybe<Array<MetadataHeaderWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataHeaderprismaCreateInput = {
  /** Connect existing "MetadataHeaderPrisma" nodes to the new "Photo" node, through the "Photo.Metadata_HeaderPrisma" relation. */
  connect?: InputMaybe<Array<MetadataHeaderPrismaWhereUniqueInput>>;
  /** Create new "MetadataHeaderPrisma" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_HeaderPrisma" relation. */
  create?: InputMaybe<Array<MetadataHeaderPrismaWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataHeaderprismaUpdateInput = {
  /** Connect existing "MetadataHeaderPrisma" nodes to the existing "Photo" node, through the "Photo.Metadata_HeaderPrisma" relation. */
  connect?: InputMaybe<Array<MetadataHeaderPrismaWhereUniqueInput>>;
  /** Create new "MetadataHeaderPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_HeaderPrisma" relation. */
  create?: InputMaybe<Array<MetadataHeaderPrismaWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataHeaderPrisma" nodes from the "Photo.Metadata_HeaderPrisma" relation. */
  delete?: InputMaybe<Array<MetadataHeaderPrismaWhereUniqueInput>>;
  /** Update existing "MetadataHeaderPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_HeaderPrisma" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataHeaderprismaUpdateInput>>;
  /** Upsert existing "MetadataHeaderPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_HeaderPrisma" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataHeaderprismaUpdateInput>>;
};

export type PhotoNestedMetadataHeaderUpdateInput = {
  /** Connect existing "MetadataHeader" nodes to the existing "Photo" node, through the "Photo.Metadata_Header" relation. */
  connect?: InputMaybe<Array<MetadataHeaderWhereUniqueInput>>;
  /** Create new "MetadataHeader" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Header" relation. */
  create?: InputMaybe<Array<MetadataHeaderWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataHeader" nodes from the "Photo.Metadata_Header" relation. */
  delete?: InputMaybe<Array<MetadataHeaderWhereUniqueInput>>;
  /** Update existing "MetadataHeader" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Header" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataHeaderUpdateInput>>;
  /** Upsert existing "MetadataHeader" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Header" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataHeaderUpdateInput>>;
};

export type PhotoNestedMetadataHeadlineCreateInput = {
  /** Connect existing "MetadataHeadline" nodes to the new "Photo" node, through the "Photo.Metadata_Headline" relation. */
  connect?: InputMaybe<Array<MetadataHeadlineWhereUniqueInput>>;
  /** Create new "MetadataHeadline" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Headline" relation. */
  create?: InputMaybe<Array<MetadataHeadlineWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataHeadlineUpdateInput = {
  /** Connect existing "MetadataHeadline" nodes to the existing "Photo" node, through the "Photo.Metadata_Headline" relation. */
  connect?: InputMaybe<Array<MetadataHeadlineWhereUniqueInput>>;
  /** Create new "MetadataHeadline" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Headline" relation. */
  create?: InputMaybe<Array<MetadataHeadlineWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataHeadline" nodes from the "Photo.Metadata_Headline" relation. */
  delete?: InputMaybe<Array<MetadataHeadlineWhereUniqueInput>>;
  /** Update existing "MetadataHeadline" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Headline" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataHeadlineUpdateInput>>;
  /** Upsert existing "MetadataHeadline" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Headline" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataHeadlineUpdateInput>>;
};

export type PhotoNestedMetadataHistoryCreateInput = {
  /** Connect existing "MetadataHistory" nodes to the new "Photo" node, through the "Photo.Metadata_History" relation. */
  connect?: InputMaybe<Array<MetadataHistoryWhereUniqueInput>>;
  /** Create new "MetadataHistory" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_History" relation. */
  create?: InputMaybe<Array<MetadataHistoryWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataHistoryUpdateInput = {
  /** Connect existing "MetadataHistory" nodes to the existing "Photo" node, through the "Photo.Metadata_History" relation. */
  connect?: InputMaybe<Array<MetadataHistoryWhereUniqueInput>>;
  /** Create new "MetadataHistory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_History" relation. */
  create?: InputMaybe<Array<MetadataHistoryWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataHistory" nodes from the "Photo.Metadata_History" relation. */
  delete?: InputMaybe<Array<MetadataHistoryWhereUniqueInput>>;
  /** Update existing "MetadataHistory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_History" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataHistoryUpdateInput>>;
  /** Upsert existing "MetadataHistory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_History" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataHistoryUpdateInput>>;
};

export type PhotoNestedMetadataIccprofileCreateInput = {
  /** Connect existing "MetadataIccProfile" nodes to the new "Photo" node, through the "Photo.Metadata_ICCProfile" relation. */
  connect?: InputMaybe<Array<MetadataIccProfileWhereUniqueInput>>;
  /** Create new "MetadataIccProfile" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ICCProfile" relation. */
  create?: InputMaybe<Array<MetadataIccProfileWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataIccprofileUpdateInput = {
  /** Connect existing "MetadataIccProfile" nodes to the existing "Photo" node, through the "Photo.Metadata_ICCProfile" relation. */
  connect?: InputMaybe<Array<MetadataIccProfileWhereUniqueInput>>;
  /** Create new "MetadataIccProfile" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ICCProfile" relation. */
  create?: InputMaybe<Array<MetadataIccProfileWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataIccProfile" nodes from the "Photo.Metadata_ICCProfile" relation. */
  delete?: InputMaybe<Array<MetadataIccProfileWhereUniqueInput>>;
  /** Update existing "MetadataIccProfile" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ICCProfile" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataIccprofileUpdateInput>>;
  /** Upsert existing "MetadataIccProfile" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ICCProfile" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataIccprofileUpdateInput>>;
};

export type PhotoNestedMetadataIdassignmentCreateInput = {
  /** Connect existing "MetadataIdAssignment" nodes to the new "Photo" node, through the "Photo.Metadata_IdAssignment" relation. */
  connect?: InputMaybe<Array<MetadataIdAssignmentWhereUniqueInput>>;
  /** Create new "MetadataIdAssignment" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_IdAssignment" relation. */
  create?: InputMaybe<Array<MetadataIdAssignmentWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataIdassignmentUpdateInput = {
  /** Connect existing "MetadataIdAssignment" nodes to the existing "Photo" node, through the "Photo.Metadata_IdAssignment" relation. */
  connect?: InputMaybe<Array<MetadataIdAssignmentWhereUniqueInput>>;
  /** Create new "MetadataIdAssignment" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IdAssignment" relation. */
  create?: InputMaybe<Array<MetadataIdAssignmentWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataIdAssignment" nodes from the "Photo.Metadata_IdAssignment" relation. */
  delete?: InputMaybe<Array<MetadataIdAssignmentWhereUniqueInput>>;
  /** Update existing "MetadataIdAssignment" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IdAssignment" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataIdassignmentUpdateInput>>;
  /** Upsert existing "MetadataIdAssignment" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IdAssignment" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataIdassignmentUpdateInput>>;
};

export type PhotoNestedMetadataIdmediacontentCreateInput = {
  /** Connect existing "MetadataIdMediaContent" nodes to the new "Photo" node, through the "Photo.Metadata_IdMediaContent" relation. */
  connect?: InputMaybe<Array<MetadataIdMediaContentWhereUniqueInput>>;
  /** Create new "MetadataIdMediaContent" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_IdMediaContent" relation. */
  create?: InputMaybe<Array<MetadataIdMediaContentWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataIdmediacontentUpdateInput = {
  /** Connect existing "MetadataIdMediaContent" nodes to the existing "Photo" node, through the "Photo.Metadata_IdMediaContent" relation. */
  connect?: InputMaybe<Array<MetadataIdMediaContentWhereUniqueInput>>;
  /** Create new "MetadataIdMediaContent" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IdMediaContent" relation. */
  create?: InputMaybe<Array<MetadataIdMediaContentWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataIdMediaContent" nodes from the "Photo.Metadata_IdMediaContent" relation. */
  delete?: InputMaybe<Array<MetadataIdMediaContentWhereUniqueInput>>;
  /** Update existing "MetadataIdMediaContent" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IdMediaContent" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataIdmediacontentUpdateInput>>;
  /** Upsert existing "MetadataIdMediaContent" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IdMediaContent" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataIdmediacontentUpdateInput>>;
};

export type PhotoNestedMetadataIdphotoprismaCreateInput = {
  /** Connect existing "MetadataIdPhotoPrisma" nodes to the new "Photo" node, through the "Photo.Metadata_IdPhotoPrisma" relation. */
  connect?: InputMaybe<Array<MetadataIdPhotoPrismaWhereUniqueInput>>;
  /** Create new "MetadataIdPhotoPrisma" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_IdPhotoPrisma" relation. */
  create?: InputMaybe<Array<MetadataIdPhotoPrismaWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataIdphotoprismaUpdateInput = {
  /** Connect existing "MetadataIdPhotoPrisma" nodes to the existing "Photo" node, through the "Photo.Metadata_IdPhotoPrisma" relation. */
  connect?: InputMaybe<Array<MetadataIdPhotoPrismaWhereUniqueInput>>;
  /** Create new "MetadataIdPhotoPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IdPhotoPrisma" relation. */
  create?: InputMaybe<Array<MetadataIdPhotoPrismaWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataIdPhotoPrisma" nodes from the "Photo.Metadata_IdPhotoPrisma" relation. */
  delete?: InputMaybe<Array<MetadataIdPhotoPrismaWhereUniqueInput>>;
  /** Update existing "MetadataIdPhotoPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IdPhotoPrisma" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataIdphotoprismaUpdateInput>>;
  /** Upsert existing "MetadataIdPhotoPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IdPhotoPrisma" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataIdphotoprismaUpdateInput>>;
};

export type PhotoNestedMetadataIdSubjectCreateInput = {
  /** Connect existing "MetadataIdSubject" nodes to the new "Photo" node, through the "Photo.Metadata_ID_subject" relation. */
  connect?: InputMaybe<Array<MetadataIdSubjectWhereUniqueInput>>;
  /** Create new "MetadataIdSubject" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ID_subject" relation. */
  create?: InputMaybe<Array<MetadataIdSubjectWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataIdSubjectUpdateInput = {
  /** Connect existing "MetadataIdSubject" nodes to the existing "Photo" node, through the "Photo.Metadata_ID_subject" relation. */
  connect?: InputMaybe<Array<MetadataIdSubjectWhereUniqueInput>>;
  /** Create new "MetadataIdSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ID_subject" relation. */
  create?: InputMaybe<Array<MetadataIdSubjectWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataIdSubject" nodes from the "Photo.Metadata_ID_subject" relation. */
  delete?: InputMaybe<Array<MetadataIdSubjectWhereUniqueInput>>;
  /** Update existing "MetadataIdSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ID_subject" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataIdSubjectUpdateInput>>;
  /** Upsert existing "MetadataIdSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ID_subject" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataIdSubjectUpdateInput>>;
};

export type PhotoNestedMetadataImagenotesCreateInput = {
  /** Connect existing "MetadataImageNotes" nodes to the new "Photo" node, through the "Photo.Metadata_ImageNotes" relation. */
  connect?: InputMaybe<Array<MetadataImageNotesWhereUniqueInput>>;
  /** Create new "MetadataImageNotes" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ImageNotes" relation. */
  create?: InputMaybe<Array<MetadataImageNotesWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataImagenotesUpdateInput = {
  /** Connect existing "MetadataImageNotes" nodes to the existing "Photo" node, through the "Photo.Metadata_ImageNotes" relation. */
  connect?: InputMaybe<Array<MetadataImageNotesWhereUniqueInput>>;
  /** Create new "MetadataImageNotes" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ImageNotes" relation. */
  create?: InputMaybe<Array<MetadataImageNotesWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataImageNotes" nodes from the "Photo.Metadata_ImageNotes" relation. */
  delete?: InputMaybe<Array<MetadataImageNotesWhereUniqueInput>>;
  /** Update existing "MetadataImageNotes" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ImageNotes" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataImagenotesUpdateInput>>;
  /** Upsert existing "MetadataImageNotes" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ImageNotes" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataImagenotesUpdateInput>>;
};

export type PhotoNestedMetadataImageuniqueidCreateInput = {
  /** Connect existing "MetadataImageUniqueId" nodes to the new "Photo" node, through the "Photo.Metadata_ImageUniqueID" relation. */
  connect?: InputMaybe<Array<MetadataImageUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataImageUniqueId" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ImageUniqueID" relation. */
  create?: InputMaybe<Array<MetadataImageUniqueIdWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataImageuniqueidUpdateInput = {
  /** Connect existing "MetadataImageUniqueId" nodes to the existing "Photo" node, through the "Photo.Metadata_ImageUniqueID" relation. */
  connect?: InputMaybe<Array<MetadataImageUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataImageUniqueId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ImageUniqueID" relation. */
  create?: InputMaybe<Array<MetadataImageUniqueIdWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataImageUniqueId" nodes from the "Photo.Metadata_ImageUniqueID" relation. */
  delete?: InputMaybe<Array<MetadataImageUniqueIdWhereUniqueInput>>;
  /** Update existing "MetadataImageUniqueId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ImageUniqueID" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataImageuniqueidUpdateInput>>;
  /** Upsert existing "MetadataImageUniqueId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ImageUniqueID" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataImageuniqueidUpdateInput>>;
};

export type PhotoNestedMetadataInstructionsCreateInput = {
  /** Connect existing "MetadataInstructions" nodes to the new "Photo" node, through the "Photo.Metadata_Instructions" relation. */
  connect?: InputMaybe<Array<MetadataInstructionsWhereUniqueInput>>;
  /** Create new "MetadataInstructions" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Instructions" relation. */
  create?: InputMaybe<Array<MetadataInstructionsWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataInstructionsUpdateInput = {
  /** Connect existing "MetadataInstructions" nodes to the existing "Photo" node, through the "Photo.Metadata_Instructions" relation. */
  connect?: InputMaybe<Array<MetadataInstructionsWhereUniqueInput>>;
  /** Create new "MetadataInstructions" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Instructions" relation. */
  create?: InputMaybe<Array<MetadataInstructionsWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataInstructions" nodes from the "Photo.Metadata_Instructions" relation. */
  delete?: InputMaybe<Array<MetadataInstructionsWhereUniqueInput>>;
  /** Update existing "MetadataInstructions" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Instructions" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataInstructionsUpdateInput>>;
  /** Upsert existing "MetadataInstructions" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Instructions" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataInstructionsUpdateInput>>;
};

export type PhotoNestedMetadataIntellectualgenreCreateInput = {
  /** Connect existing "MetadataIntellectualGenre" nodes to the new "Photo" node, through the "Photo.Metadata_IntellectualGenre" relation. */
  connect?: InputMaybe<Array<MetadataIntellectualGenreWhereUniqueInput>>;
  /** Create new "MetadataIntellectualGenre" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_IntellectualGenre" relation. */
  create?: InputMaybe<Array<MetadataIntellectualGenreWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataIntellectualgenreUpdateInput = {
  /** Connect existing "MetadataIntellectualGenre" nodes to the existing "Photo" node, through the "Photo.Metadata_IntellectualGenre" relation. */
  connect?: InputMaybe<Array<MetadataIntellectualGenreWhereUniqueInput>>;
  /** Create new "MetadataIntellectualGenre" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IntellectualGenre" relation. */
  create?: InputMaybe<Array<MetadataIntellectualGenreWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataIntellectualGenre" nodes from the "Photo.Metadata_IntellectualGenre" relation. */
  delete?: InputMaybe<Array<MetadataIntellectualGenreWhereUniqueInput>>;
  /** Update existing "MetadataIntellectualGenre" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IntellectualGenre" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataIntellectualgenreUpdateInput>>;
  /** Upsert existing "MetadataIntellectualGenre" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_IntellectualGenre" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataIntellectualgenreUpdateInput>>;
};

export type PhotoNestedMetadataIsospeedratingsCreateInput = {
  /** Connect existing "MetadataIsoSpeedRatings" nodes to the new "Photo" node, through the "Photo.Metadata_ISOSpeedRatings" relation. */
  connect?: InputMaybe<Array<MetadataIsoSpeedRatingsWhereUniqueInput>>;
  /** Create new "MetadataIsoSpeedRatings" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ISOSpeedRatings" relation. */
  create?: InputMaybe<Array<MetadataIsoSpeedRatingsWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataIsospeedratingsUpdateInput = {
  /** Connect existing "MetadataIsoSpeedRatings" nodes to the existing "Photo" node, through the "Photo.Metadata_ISOSpeedRatings" relation. */
  connect?: InputMaybe<Array<MetadataIsoSpeedRatingsWhereUniqueInput>>;
  /** Create new "MetadataIsoSpeedRatings" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ISOSpeedRatings" relation. */
  create?: InputMaybe<Array<MetadataIsoSpeedRatingsWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataIsoSpeedRatings" nodes from the "Photo.Metadata_ISOSpeedRatings" relation. */
  delete?: InputMaybe<Array<MetadataIsoSpeedRatingsWhereUniqueInput>>;
  /** Update existing "MetadataIsoSpeedRatings" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ISOSpeedRatings" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataIsospeedratingsUpdateInput>>;
  /** Upsert existing "MetadataIsoSpeedRatings" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ISOSpeedRatings" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataIsospeedratingsUpdateInput>>;
};

export type PhotoNestedMetadataIssueCreateInput = {
  /** Connect existing "MetadataIssue" nodes to the new "Photo" node, through the "Photo.Metadata_Issue" relation. */
  connect?: InputMaybe<Array<MetadataIssueWhereUniqueInput>>;
  /** Create new "MetadataIssue" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Issue" relation. */
  create?: InputMaybe<Array<MetadataIssueWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataIssueUpdateInput = {
  /** Connect existing "MetadataIssue" nodes to the existing "Photo" node, through the "Photo.Metadata_Issue" relation. */
  connect?: InputMaybe<Array<MetadataIssueWhereUniqueInput>>;
  /** Create new "MetadataIssue" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Issue" relation. */
  create?: InputMaybe<Array<MetadataIssueWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataIssue" nodes from the "Photo.Metadata_Issue" relation. */
  delete?: InputMaybe<Array<MetadataIssueWhereUniqueInput>>;
  /** Update existing "MetadataIssue" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Issue" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataIssueUpdateInput>>;
  /** Upsert existing "MetadataIssue" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Issue" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataIssueUpdateInput>>;
};

export type PhotoNestedMetadataJobidCreateInput = {
  /** Connect existing "MetadataJobId" nodes to the new "Photo" node, through the "Photo.Metadata_JobId" relation. */
  connect?: InputMaybe<Array<MetadataJobIdWhereUniqueInput>>;
  /** Create new "MetadataJobId" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_JobId" relation. */
  create?: InputMaybe<Array<MetadataJobIdWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataJobidUpdateInput = {
  /** Connect existing "MetadataJobId" nodes to the existing "Photo" node, through the "Photo.Metadata_JobId" relation. */
  connect?: InputMaybe<Array<MetadataJobIdWhereUniqueInput>>;
  /** Create new "MetadataJobId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_JobId" relation. */
  create?: InputMaybe<Array<MetadataJobIdWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataJobId" nodes from the "Photo.Metadata_JobId" relation. */
  delete?: InputMaybe<Array<MetadataJobIdWhereUniqueInput>>;
  /** Update existing "MetadataJobId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_JobId" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataJobidUpdateInput>>;
  /** Upsert existing "MetadataJobId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_JobId" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataJobidUpdateInput>>;
};

export type PhotoNestedMetadataJobprocessingCreateInput = {
  /** Connect existing "MetadataJobProcessing" nodes to the new "Photo" node, through the "Photo.Metadata_JobProcessing" relation. */
  connect?: InputMaybe<Array<MetadataJobProcessingWhereUniqueInput>>;
  /** Create new "MetadataJobProcessing" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_JobProcessing" relation. */
  create?: InputMaybe<Array<MetadataJobProcessingWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataJobprocessingUpdateInput = {
  /** Connect existing "MetadataJobProcessing" nodes to the existing "Photo" node, through the "Photo.Metadata_JobProcessing" relation. */
  connect?: InputMaybe<Array<MetadataJobProcessingWhereUniqueInput>>;
  /** Create new "MetadataJobProcessing" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_JobProcessing" relation. */
  create?: InputMaybe<Array<MetadataJobProcessingWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataJobProcessing" nodes from the "Photo.Metadata_JobProcessing" relation. */
  delete?: InputMaybe<Array<MetadataJobProcessingWhereUniqueInput>>;
  /** Update existing "MetadataJobProcessing" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_JobProcessing" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataJobprocessingUpdateInput>>;
  /** Upsert existing "MetadataJobProcessing" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_JobProcessing" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataJobprocessingUpdateInput>>;
};

export type PhotoNestedMetadataLayoutpictureeditingCreateInput = {
  /** Connect existing "MetadataLayoutPictureEditing" nodes to the new "Photo" node, through the "Photo.Metadata_LayoutPictureEditing" relation. */
  connect?: InputMaybe<Array<MetadataLayoutPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataLayoutPictureEditing" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_LayoutPictureEditing" relation. */
  create?: InputMaybe<Array<MetadataLayoutPictureEditingWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataLayoutpictureeditingUpdateInput = {
  /** Connect existing "MetadataLayoutPictureEditing" nodes to the existing "Photo" node, through the "Photo.Metadata_LayoutPictureEditing" relation. */
  connect?: InputMaybe<Array<MetadataLayoutPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataLayoutPictureEditing" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_LayoutPictureEditing" relation. */
  create?: InputMaybe<Array<MetadataLayoutPictureEditingWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataLayoutPictureEditing" nodes from the "Photo.Metadata_LayoutPictureEditing" relation. */
  delete?: InputMaybe<Array<MetadataLayoutPictureEditingWhereUniqueInput>>;
  /** Update existing "MetadataLayoutPictureEditing" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_LayoutPictureEditing" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataLayoutpictureeditingUpdateInput>>;
  /** Upsert existing "MetadataLayoutPictureEditing" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_LayoutPictureEditing" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataLayoutpictureeditingUpdateInput>>;
};

export type PhotoNestedMetadataLayoutstorageCreateInput = {
  /** Connect existing "MetadataLayoutStorage" nodes to the new "Photo" node, through the "Photo.Metadata_LayoutStorage" relation. */
  connect?: InputMaybe<Array<MetadataLayoutStorageWhereUniqueInput>>;
  /** Create new "MetadataLayoutStorage" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_LayoutStorage" relation. */
  create?: InputMaybe<Array<MetadataLayoutStorageWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataLayoutstorageUpdateInput = {
  /** Connect existing "MetadataLayoutStorage" nodes to the existing "Photo" node, through the "Photo.Metadata_LayoutStorage" relation. */
  connect?: InputMaybe<Array<MetadataLayoutStorageWhereUniqueInput>>;
  /** Create new "MetadataLayoutStorage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_LayoutStorage" relation. */
  create?: InputMaybe<Array<MetadataLayoutStorageWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataLayoutStorage" nodes from the "Photo.Metadata_LayoutStorage" relation. */
  delete?: InputMaybe<Array<MetadataLayoutStorageWhereUniqueInput>>;
  /** Update existing "MetadataLayoutStorage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_LayoutStorage" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataLayoutstorageUpdateInput>>;
  /** Upsert existing "MetadataLayoutStorage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_LayoutStorage" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataLayoutstorageUpdateInput>>;
};

export type PhotoNestedMetadataLicenseCreateInput = {
  /** Connect existing "MetadataLicense" nodes to the new "Photo" node, through the "Photo.Metadata_license" relation. */
  connect?: InputMaybe<Array<MetadataLicenseWhereUniqueInput>>;
  /** Create new "MetadataLicense" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_license" relation. */
  create?: InputMaybe<Array<MetadataLicenseWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataLicenseUpdateInput = {
  /** Connect existing "MetadataLicense" nodes to the existing "Photo" node, through the "Photo.Metadata_license" relation. */
  connect?: InputMaybe<Array<MetadataLicenseWhereUniqueInput>>;
  /** Create new "MetadataLicense" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_license" relation. */
  create?: InputMaybe<Array<MetadataLicenseWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataLicense" nodes from the "Photo.Metadata_license" relation. */
  delete?: InputMaybe<Array<MetadataLicenseWhereUniqueInput>>;
  /** Update existing "MetadataLicense" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_license" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataLicenseUpdateInput>>;
  /** Upsert existing "MetadataLicense" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_license" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataLicenseUpdateInput>>;
};

export type PhotoNestedMetadataLinksCreateInput = {
  /** Connect existing "MetadataLinks" nodes to the new "Photo" node, through the "Photo.Metadata_Links" relation. */
  connect?: InputMaybe<Array<MetadataLinksWhereUniqueInput>>;
  /** Create new "MetadataLinks" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Links" relation. */
  create?: InputMaybe<Array<MetadataLinksWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataLinksUpdateInput = {
  /** Connect existing "MetadataLinks" nodes to the existing "Photo" node, through the "Photo.Metadata_Links" relation. */
  connect?: InputMaybe<Array<MetadataLinksWhereUniqueInput>>;
  /** Create new "MetadataLinks" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Links" relation. */
  create?: InputMaybe<Array<MetadataLinksWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataLinks" nodes from the "Photo.Metadata_Links" relation. */
  delete?: InputMaybe<Array<MetadataLinksWhereUniqueInput>>;
  /** Update existing "MetadataLinks" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Links" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataLinksUpdateInput>>;
  /** Upsert existing "MetadataLinks" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Links" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataLinksUpdateInput>>;
};

export type PhotoNestedMetadataLocalcaptionCreateInput = {
  /** Connect existing "MetadataLocalCaption" nodes to the new "Photo" node, through the "Photo.Metadata_LocalCaption" relation. */
  connect?: InputMaybe<Array<MetadataLocalCaptionWhereUniqueInput>>;
  /** Create new "MetadataLocalCaption" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_LocalCaption" relation. */
  create?: InputMaybe<Array<MetadataLocalCaptionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataLocalcaptionUpdateInput = {
  /** Connect existing "MetadataLocalCaption" nodes to the existing "Photo" node, through the "Photo.Metadata_LocalCaption" relation. */
  connect?: InputMaybe<Array<MetadataLocalCaptionWhereUniqueInput>>;
  /** Create new "MetadataLocalCaption" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_LocalCaption" relation. */
  create?: InputMaybe<Array<MetadataLocalCaptionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataLocalCaption" nodes from the "Photo.Metadata_LocalCaption" relation. */
  delete?: InputMaybe<Array<MetadataLocalCaptionWhereUniqueInput>>;
  /** Update existing "MetadataLocalCaption" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_LocalCaption" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataLocalcaptionUpdateInput>>;
  /** Upsert existing "MetadataLocalCaption" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_LocalCaption" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataLocalcaptionUpdateInput>>;
};

export type PhotoNestedMetadataLocationCreateInput = {
  /** Connect existing "MetadataLocation" nodes to the new "Photo" node, through the "Photo.Metadata_Location" relation. */
  connect?: InputMaybe<Array<MetadataLocationWhereUniqueInput>>;
  /** Create new "MetadataLocation" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Location" relation. */
  create?: InputMaybe<Array<MetadataLocationWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataLocationUpdateInput = {
  /** Connect existing "MetadataLocation" nodes to the existing "Photo" node, through the "Photo.Metadata_Location" relation. */
  connect?: InputMaybe<Array<MetadataLocationWhereUniqueInput>>;
  /** Create new "MetadataLocation" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Location" relation. */
  create?: InputMaybe<Array<MetadataLocationWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataLocation" nodes from the "Photo.Metadata_Location" relation. */
  delete?: InputMaybe<Array<MetadataLocationWhereUniqueInput>>;
  /** Update existing "MetadataLocation" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Location" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataLocationUpdateInput>>;
  /** Upsert existing "MetadataLocation" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Location" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataLocationUpdateInput>>;
};

export type PhotoNestedMetadataMakeCreateInput = {
  /** Connect existing "MetadataMake" nodes to the new "Photo" node, through the "Photo.Metadata_Make" relation. */
  connect?: InputMaybe<Array<MetadataMakeWhereUniqueInput>>;
  /** Create new "MetadataMake" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Make" relation. */
  create?: InputMaybe<Array<MetadataMakeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataMakeUpdateInput = {
  /** Connect existing "MetadataMake" nodes to the existing "Photo" node, through the "Photo.Metadata_Make" relation. */
  connect?: InputMaybe<Array<MetadataMakeWhereUniqueInput>>;
  /** Create new "MetadataMake" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Make" relation. */
  create?: InputMaybe<Array<MetadataMakeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataMake" nodes from the "Photo.Metadata_Make" relation. */
  delete?: InputMaybe<Array<MetadataMakeWhereUniqueInput>>;
  /** Update existing "MetadataMake" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Make" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataMakeUpdateInput>>;
  /** Upsert existing "MetadataMake" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Make" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataMakeUpdateInput>>;
};

export type PhotoNestedMetadataManifestCreateInput = {
  /** Connect existing "MetadataManifest" nodes to the new "Photo" node, through the "Photo.Metadata_Manifest" relation. */
  connect?: InputMaybe<Array<MetadataManifestWhereUniqueInput>>;
  /** Create new "MetadataManifest" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Manifest" relation. */
  create?: InputMaybe<Array<MetadataManifestWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataManifestUpdateInput = {
  /** Connect existing "MetadataManifest" nodes to the existing "Photo" node, through the "Photo.Metadata_Manifest" relation. */
  connect?: InputMaybe<Array<MetadataManifestWhereUniqueInput>>;
  /** Create new "MetadataManifest" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Manifest" relation. */
  create?: InputMaybe<Array<MetadataManifestWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataManifest" nodes from the "Photo.Metadata_Manifest" relation. */
  delete?: InputMaybe<Array<MetadataManifestWhereUniqueInput>>;
  /** Update existing "MetadataManifest" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Manifest" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataManifestUpdateInput>>;
  /** Upsert existing "MetadataManifest" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Manifest" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataManifestUpdateInput>>;
};

export type PhotoNestedMetadataMarkedCreateInput = {
  /** Connect existing "MetadataMarked" nodes to the new "Photo" node, through the "Photo.Metadata_Marked" relation. */
  connect?: InputMaybe<Array<MetadataMarkedWhereUniqueInput>>;
  /** Create new "MetadataMarked" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Marked" relation. */
  create?: InputMaybe<Array<MetadataMarkedWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataMarkedUpdateInput = {
  /** Connect existing "MetadataMarked" nodes to the existing "Photo" node, through the "Photo.Metadata_Marked" relation. */
  connect?: InputMaybe<Array<MetadataMarkedWhereUniqueInput>>;
  /** Create new "MetadataMarked" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Marked" relation. */
  create?: InputMaybe<Array<MetadataMarkedWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataMarked" nodes from the "Photo.Metadata_Marked" relation. */
  delete?: InputMaybe<Array<MetadataMarkedWhereUniqueInput>>;
  /** Update existing "MetadataMarked" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Marked" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataMarkedUpdateInput>>;
  /** Upsert existing "MetadataMarked" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Marked" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataMarkedUpdateInput>>;
};

export type PhotoNestedMetadataMasterdocumentidCreateInput = {
  /** Connect existing "MetadataMasterDocumentId" nodes to the new "Photo" node, through the "Photo.Metadata_MasterDocumentId" relation. */
  connect?: InputMaybe<Array<MetadataMasterDocumentIdWhereUniqueInput>>;
  /** Create new "MetadataMasterDocumentId" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_MasterDocumentId" relation. */
  create?: InputMaybe<Array<MetadataMasterDocumentIdWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataMasterdocumentidUpdateInput = {
  /** Connect existing "MetadataMasterDocumentId" nodes to the existing "Photo" node, through the "Photo.Metadata_MasterDocumentId" relation. */
  connect?: InputMaybe<Array<MetadataMasterDocumentIdWhereUniqueInput>>;
  /** Create new "MetadataMasterDocumentId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MasterDocumentId" relation. */
  create?: InputMaybe<Array<MetadataMasterDocumentIdWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataMasterDocumentId" nodes from the "Photo.Metadata_MasterDocumentId" relation. */
  delete?: InputMaybe<Array<MetadataMasterDocumentIdWhereUniqueInput>>;
  /** Update existing "MetadataMasterDocumentId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MasterDocumentId" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataMasterdocumentidUpdateInput>>;
  /** Upsert existing "MetadataMasterDocumentId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MasterDocumentId" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataMasterdocumentidUpdateInput>>;
};

export type PhotoNestedMetadataMaxavailheightCreateInput = {
  /** Connect existing "MetadataMaxAvailHeight" nodes to the new "Photo" node, through the "Photo.Metadata_MaxAvailHeight" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailHeightWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailHeight" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_MaxAvailHeight" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailHeightWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataMaxavailheightUpdateInput = {
  /** Connect existing "MetadataMaxAvailHeight" nodes to the existing "Photo" node, through the "Photo.Metadata_MaxAvailHeight" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailHeightWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailHeight" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MaxAvailHeight" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailHeightWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataMaxAvailHeight" nodes from the "Photo.Metadata_MaxAvailHeight" relation. */
  delete?: InputMaybe<Array<MetadataMaxAvailHeightWhereUniqueInput>>;
  /** Update existing "MetadataMaxAvailHeight" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MaxAvailHeight" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataMaxavailheightUpdateInput>>;
  /** Upsert existing "MetadataMaxAvailHeight" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MaxAvailHeight" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataMaxavailheightUpdateInput>>;
};

export type PhotoNestedMetadataMaxavailwidthCreateInput = {
  /** Connect existing "MetadataMaxAvailWidth" nodes to the new "Photo" node, through the "Photo.Metadata_MaxAvailWidth" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailWidthWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailWidth" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_MaxAvailWidth" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailWidthWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataMaxavailwidthUpdateInput = {
  /** Connect existing "MetadataMaxAvailWidth" nodes to the existing "Photo" node, through the "Photo.Metadata_MaxAvailWidth" relation. */
  connect?: InputMaybe<Array<MetadataMaxAvailWidthWhereUniqueInput>>;
  /** Create new "MetadataMaxAvailWidth" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MaxAvailWidth" relation. */
  create?: InputMaybe<Array<MetadataMaxAvailWidthWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataMaxAvailWidth" nodes from the "Photo.Metadata_MaxAvailWidth" relation. */
  delete?: InputMaybe<Array<MetadataMaxAvailWidthWhereUniqueInput>>;
  /** Update existing "MetadataMaxAvailWidth" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MaxAvailWidth" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataMaxavailwidthUpdateInput>>;
  /** Upsert existing "MetadataMaxAvailWidth" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MaxAvailWidth" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataMaxavailwidthUpdateInput>>;
};

export type PhotoNestedMetadataMinormodelagedisclosureCreateInput = {
  /** Connect existing "MetadataMinorModelAgeDisclosure" nodes to the new "Photo" node, through the "Photo.Metadata_MinorModelAgeDisclosure" relation. */
  connect?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWhereUniqueInput>>;
  /** Create new "MetadataMinorModelAgeDisclosure" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_MinorModelAgeDisclosure" relation. */
  create?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataMinormodelagedisclosureUpdateInput = {
  /** Connect existing "MetadataMinorModelAgeDisclosure" nodes to the existing "Photo" node, through the "Photo.Metadata_MinorModelAgeDisclosure" relation. */
  connect?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWhereUniqueInput>>;
  /** Create new "MetadataMinorModelAgeDisclosure" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MinorModelAgeDisclosure" relation. */
  create?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataMinorModelAgeDisclosure" nodes from the "Photo.Metadata_MinorModelAgeDisclosure" relation. */
  delete?: InputMaybe<Array<MetadataMinorModelAgeDisclosureWhereUniqueInput>>;
  /** Update existing "MetadataMinorModelAgeDisclosure" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MinorModelAgeDisclosure" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataMinormodelagedisclosureUpdateInput>>;
  /** Upsert existing "MetadataMinorModelAgeDisclosure" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_MinorModelAgeDisclosure" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataMinormodelagedisclosureUpdateInput>>;
};

export type PhotoNestedMetadataModelageCreateInput = {
  /** Connect existing "MetadataModelAge" nodes to the new "Photo" node, through the "Photo.Metadata_ModelAge" relation. */
  connect?: InputMaybe<Array<MetadataModelAgeWhereUniqueInput>>;
  /** Create new "MetadataModelAge" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ModelAge" relation. */
  create?: InputMaybe<Array<MetadataModelAgeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataModelageUpdateInput = {
  /** Connect existing "MetadataModelAge" nodes to the existing "Photo" node, through the "Photo.Metadata_ModelAge" relation. */
  connect?: InputMaybe<Array<MetadataModelAgeWhereUniqueInput>>;
  /** Create new "MetadataModelAge" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ModelAge" relation. */
  create?: InputMaybe<Array<MetadataModelAgeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataModelAge" nodes from the "Photo.Metadata_ModelAge" relation. */
  delete?: InputMaybe<Array<MetadataModelAgeWhereUniqueInput>>;
  /** Update existing "MetadataModelAge" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ModelAge" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataModelageUpdateInput>>;
  /** Upsert existing "MetadataModelAge" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ModelAge" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataModelageUpdateInput>>;
};

export type PhotoNestedMetadataModelCreateInput = {
  /** Connect existing "MetadataModel" nodes to the new "Photo" node, through the "Photo.Metadata_Model" relation. */
  connect?: InputMaybe<Array<MetadataModelWhereUniqueInput>>;
  /** Create new "MetadataModel" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Model" relation. */
  create?: InputMaybe<Array<MetadataModelWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataModelreleaseidCreateInput = {
  /** Connect existing "MetadataModelReleaseId" nodes to the new "Photo" node, through the "Photo.Metadata_ModelReleaseID" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseId" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ModelReleaseID" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseIdWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataModelreleaseidUpdateInput = {
  /** Connect existing "MetadataModelReleaseId" nodes to the existing "Photo" node, through the "Photo.Metadata_ModelReleaseID" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ModelReleaseID" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseIdWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataModelReleaseId" nodes from the "Photo.Metadata_ModelReleaseID" relation. */
  delete?: InputMaybe<Array<MetadataModelReleaseIdWhereUniqueInput>>;
  /** Update existing "MetadataModelReleaseId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ModelReleaseID" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataModelreleaseidUpdateInput>>;
  /** Upsert existing "MetadataModelReleaseId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ModelReleaseID" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataModelreleaseidUpdateInput>>;
};

export type PhotoNestedMetadataModelreleasestatusCreateInput = {
  /** Connect existing "MetadataModelReleaseStatus" nodes to the new "Photo" node, through the "Photo.Metadata_ModelReleaseStatus" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseStatus" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ModelReleaseStatus" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseStatusWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataModelreleasestatusUpdateInput = {
  /** Connect existing "MetadataModelReleaseStatus" nodes to the existing "Photo" node, through the "Photo.Metadata_ModelReleaseStatus" relation. */
  connect?: InputMaybe<Array<MetadataModelReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataModelReleaseStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ModelReleaseStatus" relation. */
  create?: InputMaybe<Array<MetadataModelReleaseStatusWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataModelReleaseStatus" nodes from the "Photo.Metadata_ModelReleaseStatus" relation. */
  delete?: InputMaybe<Array<MetadataModelReleaseStatusWhereUniqueInput>>;
  /** Update existing "MetadataModelReleaseStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ModelReleaseStatus" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataModelreleasestatusUpdateInput>>;
  /** Upsert existing "MetadataModelReleaseStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ModelReleaseStatus" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataModelreleasestatusUpdateInput>>;
};

export type PhotoNestedMetadataModelUpdateInput = {
  /** Connect existing "MetadataModel" nodes to the existing "Photo" node, through the "Photo.Metadata_Model" relation. */
  connect?: InputMaybe<Array<MetadataModelWhereUniqueInput>>;
  /** Create new "MetadataModel" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Model" relation. */
  create?: InputMaybe<Array<MetadataModelWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataModel" nodes from the "Photo.Metadata_Model" relation. */
  delete?: InputMaybe<Array<MetadataModelWhereUniqueInput>>;
  /** Update existing "MetadataModel" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Model" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataModelUpdateInput>>;
  /** Upsert existing "MetadataModel" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Model" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataModelUpdateInput>>;
};

export type PhotoNestedMetadataMorepermissionsCreateInput = {
  /** Connect existing "MetadataMorePermissions" nodes to the new "Photo" node, through the "Photo.Metadata_morePermissions" relation. */
  connect?: InputMaybe<Array<MetadataMorePermissionsWhereUniqueInput>>;
  /** Create new "MetadataMorePermissions" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_morePermissions" relation. */
  create?: InputMaybe<Array<MetadataMorePermissionsWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataMorepermissionsUpdateInput = {
  /** Connect existing "MetadataMorePermissions" nodes to the existing "Photo" node, through the "Photo.Metadata_morePermissions" relation. */
  connect?: InputMaybe<Array<MetadataMorePermissionsWhereUniqueInput>>;
  /** Create new "MetadataMorePermissions" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_morePermissions" relation. */
  create?: InputMaybe<Array<MetadataMorePermissionsWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataMorePermissions" nodes from the "Photo.Metadata_morePermissions" relation. */
  delete?: InputMaybe<Array<MetadataMorePermissionsWhereUniqueInput>>;
  /** Update existing "MetadataMorePermissions" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_morePermissions" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataMorepermissionsUpdateInput>>;
  /** Upsert existing "MetadataMorePermissions" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_morePermissions" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataMorepermissionsUpdateInput>>;
};

export type PhotoNestedMetadataObjectcycleCreateInput = {
  /** Connect existing "MetadataObjectCycle" nodes to the new "Photo" node, through the "Photo.Metadata_ObjectCycle" relation. */
  connect?: InputMaybe<Array<MetadataObjectCycleWhereUniqueInput>>;
  /** Create new "MetadataObjectCycle" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ObjectCycle" relation. */
  create?: InputMaybe<Array<MetadataObjectCycleWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataObjectcycleUpdateInput = {
  /** Connect existing "MetadataObjectCycle" nodes to the existing "Photo" node, through the "Photo.Metadata_ObjectCycle" relation. */
  connect?: InputMaybe<Array<MetadataObjectCycleWhereUniqueInput>>;
  /** Create new "MetadataObjectCycle" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ObjectCycle" relation. */
  create?: InputMaybe<Array<MetadataObjectCycleWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataObjectCycle" nodes from the "Photo.Metadata_ObjectCycle" relation. */
  delete?: InputMaybe<Array<MetadataObjectCycleWhereUniqueInput>>;
  /** Update existing "MetadataObjectCycle" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ObjectCycle" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataObjectcycleUpdateInput>>;
  /** Upsert existing "MetadataObjectCycle" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ObjectCycle" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataObjectcycleUpdateInput>>;
};

export type PhotoNestedMetadataOrganisationinimagenameCreateInput = {
  /** Connect existing "MetadataOrganisationInImageName" nodes to the new "Photo" node, through the "Photo.Metadata_OrganisationInImageName" relation. */
  connect?: InputMaybe<Array<MetadataOrganisationInImageNameWhereUniqueInput>>;
  /** Create new "MetadataOrganisationInImageName" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_OrganisationInImageName" relation. */
  create?: InputMaybe<Array<MetadataOrganisationInImageNameWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataOrganisationinimagenameUpdateInput = {
  /** Connect existing "MetadataOrganisationInImageName" nodes to the existing "Photo" node, through the "Photo.Metadata_OrganisationInImageName" relation. */
  connect?: InputMaybe<Array<MetadataOrganisationInImageNameWhereUniqueInput>>;
  /** Create new "MetadataOrganisationInImageName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OrganisationInImageName" relation. */
  create?: InputMaybe<Array<MetadataOrganisationInImageNameWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataOrganisationInImageName" nodes from the "Photo.Metadata_OrganisationInImageName" relation. */
  delete?: InputMaybe<Array<MetadataOrganisationInImageNameWhereUniqueInput>>;
  /** Update existing "MetadataOrganisationInImageName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OrganisationInImageName" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataOrganisationinimagenameUpdateInput>>;
  /** Upsert existing "MetadataOrganisationInImageName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OrganisationInImageName" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataOrganisationinimagenameUpdateInput>>;
};

export type PhotoNestedMetadataOriginalcolorCreateInput = {
  /** Connect existing "MetadataOriginalColor" nodes to the new "Photo" node, through the "Photo.Metadata_OriginalColor" relation. */
  connect?: InputMaybe<Array<MetadataOriginalColorWhereUniqueInput>>;
  /** Create new "MetadataOriginalColor" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_OriginalColor" relation. */
  create?: InputMaybe<Array<MetadataOriginalColorWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataOriginalcolorUpdateInput = {
  /** Connect existing "MetadataOriginalColor" nodes to the existing "Photo" node, through the "Photo.Metadata_OriginalColor" relation. */
  connect?: InputMaybe<Array<MetadataOriginalColorWhereUniqueInput>>;
  /** Create new "MetadataOriginalColor" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalColor" relation. */
  create?: InputMaybe<Array<MetadataOriginalColorWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataOriginalColor" nodes from the "Photo.Metadata_OriginalColor" relation. */
  delete?: InputMaybe<Array<MetadataOriginalColorWhereUniqueInput>>;
  /** Update existing "MetadataOriginalColor" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalColor" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataOriginalcolorUpdateInput>>;
  /** Upsert existing "MetadataOriginalColor" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalColor" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataOriginalcolorUpdateInput>>;
};

export type PhotoNestedMetadataOriginaliccprofileCreateInput = {
  /** Connect existing "MetadataOriginalIccProfile" nodes to the new "Photo" node, through the "Photo.Metadata_OriginalICCProfile" relation. */
  connect?: InputMaybe<Array<MetadataOriginalIccProfileWhereUniqueInput>>;
  /** Create new "MetadataOriginalIccProfile" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_OriginalICCProfile" relation. */
  create?: InputMaybe<Array<MetadataOriginalIccProfileWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataOriginaliccprofileUpdateInput = {
  /** Connect existing "MetadataOriginalIccProfile" nodes to the existing "Photo" node, through the "Photo.Metadata_OriginalICCProfile" relation. */
  connect?: InputMaybe<Array<MetadataOriginalIccProfileWhereUniqueInput>>;
  /** Create new "MetadataOriginalIccProfile" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalICCProfile" relation. */
  create?: InputMaybe<Array<MetadataOriginalIccProfileWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataOriginalIccProfile" nodes from the "Photo.Metadata_OriginalICCProfile" relation. */
  delete?: InputMaybe<Array<MetadataOriginalIccProfileWhereUniqueInput>>;
  /** Update existing "MetadataOriginalIccProfile" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalICCProfile" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataOriginaliccprofileUpdateInput>>;
  /** Upsert existing "MetadataOriginalIccProfile" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalICCProfile" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataOriginaliccprofileUpdateInput>>;
};

export type PhotoNestedMetadataOriginalnameCreateInput = {
  /** Connect existing "MetadataOriginalName" nodes to the new "Photo" node, through the "Photo.Metadata_OriginalName" relation. */
  connect?: InputMaybe<Array<MetadataOriginalNameWhereUniqueInput>>;
  /** Create new "MetadataOriginalName" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_OriginalName" relation. */
  create?: InputMaybe<Array<MetadataOriginalNameWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataOriginalnameUpdateInput = {
  /** Connect existing "MetadataOriginalName" nodes to the existing "Photo" node, through the "Photo.Metadata_OriginalName" relation. */
  connect?: InputMaybe<Array<MetadataOriginalNameWhereUniqueInput>>;
  /** Create new "MetadataOriginalName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalName" relation. */
  create?: InputMaybe<Array<MetadataOriginalNameWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataOriginalName" nodes from the "Photo.Metadata_OriginalName" relation. */
  delete?: InputMaybe<Array<MetadataOriginalNameWhereUniqueInput>>;
  /** Update existing "MetadataOriginalName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalName" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataOriginalnameUpdateInput>>;
  /** Upsert existing "MetadataOriginalName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalName" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataOriginalnameUpdateInput>>;
};

export type PhotoNestedMetadataOriginalweigthCreateInput = {
  /** Connect existing "MetadataOriginalWeigth" nodes to the new "Photo" node, through the "Photo.Metadata_OriginalWeigth" relation. */
  connect?: InputMaybe<Array<MetadataOriginalWeigthWhereUniqueInput>>;
  /** Create new "MetadataOriginalWeigth" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_OriginalWeigth" relation. */
  create?: InputMaybe<Array<MetadataOriginalWeigthWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataOriginalweigthUpdateInput = {
  /** Connect existing "MetadataOriginalWeigth" nodes to the existing "Photo" node, through the "Photo.Metadata_OriginalWeigth" relation. */
  connect?: InputMaybe<Array<MetadataOriginalWeigthWhereUniqueInput>>;
  /** Create new "MetadataOriginalWeigth" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalWeigth" relation. */
  create?: InputMaybe<Array<MetadataOriginalWeigthWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataOriginalWeigth" nodes from the "Photo.Metadata_OriginalWeigth" relation. */
  delete?: InputMaybe<Array<MetadataOriginalWeigthWhereUniqueInput>>;
  /** Update existing "MetadataOriginalWeigth" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalWeigth" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataOriginalweigthUpdateInput>>;
  /** Upsert existing "MetadataOriginalWeigth" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OriginalWeigth" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataOriginalweigthUpdateInput>>;
};

export type PhotoNestedMetadataOwnerCreateInput = {
  /** Connect existing "MetadataOwner" nodes to the new "Photo" node, through the "Photo.Metadata_Owner" relation. */
  connect?: InputMaybe<Array<MetadataOwnerWhereUniqueInput>>;
  /** Create new "MetadataOwner" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Owner" relation. */
  create?: InputMaybe<Array<MetadataOwnerWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataOwneridCreateInput = {
  /** Connect existing "MetadataOwnerId" nodes to the new "Photo" node, through the "Photo.Metadata_OwnerId" relation. */
  connect?: InputMaybe<Array<MetadataOwnerIdWhereUniqueInput>>;
  /** Create new "MetadataOwnerId" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_OwnerId" relation. */
  create?: InputMaybe<Array<MetadataOwnerIdWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataOwneridUpdateInput = {
  /** Connect existing "MetadataOwnerId" nodes to the existing "Photo" node, through the "Photo.Metadata_OwnerId" relation. */
  connect?: InputMaybe<Array<MetadataOwnerIdWhereUniqueInput>>;
  /** Create new "MetadataOwnerId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OwnerId" relation. */
  create?: InputMaybe<Array<MetadataOwnerIdWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataOwnerId" nodes from the "Photo.Metadata_OwnerId" relation. */
  delete?: InputMaybe<Array<MetadataOwnerIdWhereUniqueInput>>;
  /** Update existing "MetadataOwnerId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OwnerId" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataOwneridUpdateInput>>;
  /** Upsert existing "MetadataOwnerId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_OwnerId" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataOwneridUpdateInput>>;
};

export type PhotoNestedMetadataOwnerUpdateInput = {
  /** Connect existing "MetadataOwner" nodes to the existing "Photo" node, through the "Photo.Metadata_Owner" relation. */
  connect?: InputMaybe<Array<MetadataOwnerWhereUniqueInput>>;
  /** Create new "MetadataOwner" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Owner" relation. */
  create?: InputMaybe<Array<MetadataOwnerWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataOwner" nodes from the "Photo.Metadata_Owner" relation. */
  delete?: InputMaybe<Array<MetadataOwnerWhereUniqueInput>>;
  /** Update existing "MetadataOwner" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Owner" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataOwnerUpdateInput>>;
  /** Upsert existing "MetadataOwner" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Owner" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataOwnerUpdateInput>>;
};

export type PhotoNestedMetadataPageCreateInput = {
  /** Connect existing "MetadataPage" nodes to the new "Photo" node, through the "Photo.Metadata_Page" relation. */
  connect?: InputMaybe<Array<MetadataPageWhereUniqueInput>>;
  /** Create new "MetadataPage" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Page" relation. */
  create?: InputMaybe<Array<MetadataPageWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPageprismaCreateInput = {
  /** Connect existing "MetadataPagePrisma" nodes to the new "Photo" node, through the "Photo.Metadata_PagePrisma" relation. */
  connect?: InputMaybe<Array<MetadataPagePrismaWhereUniqueInput>>;
  /** Create new "MetadataPagePrisma" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PagePrisma" relation. */
  create?: InputMaybe<Array<MetadataPagePrismaWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPageprismaUpdateInput = {
  /** Connect existing "MetadataPagePrisma" nodes to the existing "Photo" node, through the "Photo.Metadata_PagePrisma" relation. */
  connect?: InputMaybe<Array<MetadataPagePrismaWhereUniqueInput>>;
  /** Create new "MetadataPagePrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PagePrisma" relation. */
  create?: InputMaybe<Array<MetadataPagePrismaWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPagePrisma" nodes from the "Photo.Metadata_PagePrisma" relation. */
  delete?: InputMaybe<Array<MetadataPagePrismaWhereUniqueInput>>;
  /** Update existing "MetadataPagePrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PagePrisma" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPageprismaUpdateInput>>;
  /** Upsert existing "MetadataPagePrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PagePrisma" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPageprismaUpdateInput>>;
};

export type PhotoNestedMetadataPageUpdateInput = {
  /** Connect existing "MetadataPage" nodes to the existing "Photo" node, through the "Photo.Metadata_Page" relation. */
  connect?: InputMaybe<Array<MetadataPageWhereUniqueInput>>;
  /** Create new "MetadataPage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Page" relation. */
  create?: InputMaybe<Array<MetadataPageWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPage" nodes from the "Photo.Metadata_Page" relation. */
  delete?: InputMaybe<Array<MetadataPageWhereUniqueInput>>;
  /** Update existing "MetadataPage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Page" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPageUpdateInput>>;
  /** Upsert existing "MetadataPage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Page" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPageUpdateInput>>;
};

export type PhotoNestedMetadataPaintbasedcorrectionsCreateInput = {
  /** Connect existing "MetadataPaintBasedCorrections" nodes to the new "Photo" node, through the "Photo.Metadata_PaintBasedCorrections" relation. */
  connect?: InputMaybe<Array<MetadataPaintBasedCorrectionsWhereUniqueInput>>;
  /** Create new "MetadataPaintBasedCorrections" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PaintBasedCorrections" relation. */
  create?: InputMaybe<Array<MetadataPaintBasedCorrectionsWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPaintbasedcorrectionsUpdateInput = {
  /** Connect existing "MetadataPaintBasedCorrections" nodes to the existing "Photo" node, through the "Photo.Metadata_PaintBasedCorrections" relation. */
  connect?: InputMaybe<Array<MetadataPaintBasedCorrectionsWhereUniqueInput>>;
  /** Create new "MetadataPaintBasedCorrections" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PaintBasedCorrections" relation. */
  create?: InputMaybe<Array<MetadataPaintBasedCorrectionsWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPaintBasedCorrections" nodes from the "Photo.Metadata_PaintBasedCorrections" relation. */
  delete?: InputMaybe<Array<MetadataPaintBasedCorrectionsWhereUniqueInput>>;
  /** Update existing "MetadataPaintBasedCorrections" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PaintBasedCorrections" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPaintbasedcorrectionsUpdateInput>>;
  /** Upsert existing "MetadataPaintBasedCorrections" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PaintBasedCorrections" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPaintbasedcorrectionsUpdateInput>>;
};

export type PhotoNestedMetadataPatientdobCreateInput = {
  /** Connect existing "MetadataPatientDob" nodes to the new "Photo" node, through the "Photo.Metadata_PatientDOB" relation. */
  connect?: InputMaybe<Array<MetadataPatientDobWhereUniqueInput>>;
  /** Create new "MetadataPatientDob" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PatientDOB" relation. */
  create?: InputMaybe<Array<MetadataPatientDobWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPatientdobUpdateInput = {
  /** Connect existing "MetadataPatientDob" nodes to the existing "Photo" node, through the "Photo.Metadata_PatientDOB" relation. */
  connect?: InputMaybe<Array<MetadataPatientDobWhereUniqueInput>>;
  /** Create new "MetadataPatientDob" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientDOB" relation. */
  create?: InputMaybe<Array<MetadataPatientDobWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPatientDob" nodes from the "Photo.Metadata_PatientDOB" relation. */
  delete?: InputMaybe<Array<MetadataPatientDobWhereUniqueInput>>;
  /** Update existing "MetadataPatientDob" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientDOB" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPatientdobUpdateInput>>;
  /** Upsert existing "MetadataPatientDob" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientDOB" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPatientdobUpdateInput>>;
};

export type PhotoNestedMetadataPatientidCreateInput = {
  /** Connect existing "MetadataPatientId" nodes to the new "Photo" node, through the "Photo.Metadata_PatientID" relation. */
  connect?: InputMaybe<Array<MetadataPatientIdWhereUniqueInput>>;
  /** Create new "MetadataPatientId" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PatientID" relation. */
  create?: InputMaybe<Array<MetadataPatientIdWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPatientidUpdateInput = {
  /** Connect existing "MetadataPatientId" nodes to the existing "Photo" node, through the "Photo.Metadata_PatientID" relation. */
  connect?: InputMaybe<Array<MetadataPatientIdWhereUniqueInput>>;
  /** Create new "MetadataPatientId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientID" relation. */
  create?: InputMaybe<Array<MetadataPatientIdWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPatientId" nodes from the "Photo.Metadata_PatientID" relation. */
  delete?: InputMaybe<Array<MetadataPatientIdWhereUniqueInput>>;
  /** Update existing "MetadataPatientId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientID" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPatientidUpdateInput>>;
  /** Upsert existing "MetadataPatientId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientID" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPatientidUpdateInput>>;
};

export type PhotoNestedMetadataPatientnameCreateInput = {
  /** Connect existing "MetadataPatientName" nodes to the new "Photo" node, through the "Photo.Metadata_PatientName" relation. */
  connect?: InputMaybe<Array<MetadataPatientNameWhereUniqueInput>>;
  /** Create new "MetadataPatientName" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PatientName" relation. */
  create?: InputMaybe<Array<MetadataPatientNameWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPatientnameUpdateInput = {
  /** Connect existing "MetadataPatientName" nodes to the existing "Photo" node, through the "Photo.Metadata_PatientName" relation. */
  connect?: InputMaybe<Array<MetadataPatientNameWhereUniqueInput>>;
  /** Create new "MetadataPatientName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientName" relation. */
  create?: InputMaybe<Array<MetadataPatientNameWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPatientName" nodes from the "Photo.Metadata_PatientName" relation. */
  delete?: InputMaybe<Array<MetadataPatientNameWhereUniqueInput>>;
  /** Update existing "MetadataPatientName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientName" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPatientnameUpdateInput>>;
  /** Upsert existing "MetadataPatientName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientName" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPatientnameUpdateInput>>;
};

export type PhotoNestedMetadataPatientsexCreateInput = {
  /** Connect existing "MetadataPatientSex" nodes to the new "Photo" node, through the "Photo.Metadata_PatientSex" relation. */
  connect?: InputMaybe<Array<MetadataPatientSexWhereUniqueInput>>;
  /** Create new "MetadataPatientSex" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PatientSex" relation. */
  create?: InputMaybe<Array<MetadataPatientSexWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPatientsexUpdateInput = {
  /** Connect existing "MetadataPatientSex" nodes to the existing "Photo" node, through the "Photo.Metadata_PatientSex" relation. */
  connect?: InputMaybe<Array<MetadataPatientSexWhereUniqueInput>>;
  /** Create new "MetadataPatientSex" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientSex" relation. */
  create?: InputMaybe<Array<MetadataPatientSexWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPatientSex" nodes from the "Photo.Metadata_PatientSex" relation. */
  delete?: InputMaybe<Array<MetadataPatientSexWhereUniqueInput>>;
  /** Update existing "MetadataPatientSex" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientSex" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPatientsexUpdateInput>>;
  /** Upsert existing "MetadataPatientSex" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PatientSex" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPatientsexUpdateInput>>;
};

export type PhotoNestedMetadataPersoninimageCreateInput = {
  /** Connect existing "MetadataPersonInImage" nodes to the new "Photo" node, through the "Photo.Metadata_PersonInImage" relation. */
  connect?: InputMaybe<Array<MetadataPersonInImageWhereUniqueInput>>;
  /** Create new "MetadataPersonInImage" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PersonInImage" relation. */
  create?: InputMaybe<Array<MetadataPersonInImageWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPersoninimageUpdateInput = {
  /** Connect existing "MetadataPersonInImage" nodes to the existing "Photo" node, through the "Photo.Metadata_PersonInImage" relation. */
  connect?: InputMaybe<Array<MetadataPersonInImageWhereUniqueInput>>;
  /** Create new "MetadataPersonInImage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PersonInImage" relation. */
  create?: InputMaybe<Array<MetadataPersonInImageWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPersonInImage" nodes from the "Photo.Metadata_PersonInImage" relation. */
  delete?: InputMaybe<Array<MetadataPersonInImageWhereUniqueInput>>;
  /** Update existing "MetadataPersonInImage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PersonInImage" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPersoninimageUpdateInput>>;
  /** Upsert existing "MetadataPersonInImage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PersonInImage" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPersoninimageUpdateInput>>;
};

export type PhotoNestedMetadataPhotostorageCreateInput = {
  /** Connect existing "MetadataPhotoStorage" nodes to the new "Photo" node, through the "Photo.Metadata_PhotoStorage" relation. */
  connect?: InputMaybe<Array<MetadataPhotoStorageWhereUniqueInput>>;
  /** Create new "MetadataPhotoStorage" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PhotoStorage" relation. */
  create?: InputMaybe<Array<MetadataPhotoStorageWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPhotostorageUpdateInput = {
  /** Connect existing "MetadataPhotoStorage" nodes to the existing "Photo" node, through the "Photo.Metadata_PhotoStorage" relation. */
  connect?: InputMaybe<Array<MetadataPhotoStorageWhereUniqueInput>>;
  /** Create new "MetadataPhotoStorage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PhotoStorage" relation. */
  create?: InputMaybe<Array<MetadataPhotoStorageWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPhotoStorage" nodes from the "Photo.Metadata_PhotoStorage" relation. */
  delete?: InputMaybe<Array<MetadataPhotoStorageWhereUniqueInput>>;
  /** Update existing "MetadataPhotoStorage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PhotoStorage" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPhotostorageUpdateInput>>;
  /** Upsert existing "MetadataPhotoStorage" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PhotoStorage" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPhotostorageUpdateInput>>;
};

export type PhotoNestedMetadataPrepresspictureeditingCreateInput = {
  /** Connect existing "MetadataPrepressPictureEditing" nodes to the new "Photo" node, through the "Photo.Metadata_PrepressPictureEditing" relation. */
  connect?: InputMaybe<Array<MetadataPrepressPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataPrepressPictureEditing" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PrepressPictureEditing" relation. */
  create?: InputMaybe<Array<MetadataPrepressPictureEditingWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPrepresspictureeditingUpdateInput = {
  /** Connect existing "MetadataPrepressPictureEditing" nodes to the existing "Photo" node, through the "Photo.Metadata_PrepressPictureEditing" relation. */
  connect?: InputMaybe<Array<MetadataPrepressPictureEditingWhereUniqueInput>>;
  /** Create new "MetadataPrepressPictureEditing" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PrepressPictureEditing" relation. */
  create?: InputMaybe<Array<MetadataPrepressPictureEditingWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPrepressPictureEditing" nodes from the "Photo.Metadata_PrepressPictureEditing" relation. */
  delete?: InputMaybe<Array<MetadataPrepressPictureEditingWhereUniqueInput>>;
  /** Update existing "MetadataPrepressPictureEditing" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PrepressPictureEditing" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPrepresspictureeditingUpdateInput>>;
  /** Upsert existing "MetadataPrepressPictureEditing" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PrepressPictureEditing" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPrepresspictureeditingUpdateInput>>;
};

export type PhotoNestedMetadataPricelevelCreateInput = {
  /** Connect existing "MetadataPriceLevel" nodes to the new "Photo" node, through the "Photo.Metadata_PriceLevel" relation. */
  connect?: InputMaybe<Array<MetadataPriceLevelWhereUniqueInput>>;
  /** Create new "MetadataPriceLevel" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PriceLevel" relation. */
  create?: InputMaybe<Array<MetadataPriceLevelWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPricelevelUpdateInput = {
  /** Connect existing "MetadataPriceLevel" nodes to the existing "Photo" node, through the "Photo.Metadata_PriceLevel" relation. */
  connect?: InputMaybe<Array<MetadataPriceLevelWhereUniqueInput>>;
  /** Create new "MetadataPriceLevel" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PriceLevel" relation. */
  create?: InputMaybe<Array<MetadataPriceLevelWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPriceLevel" nodes from the "Photo.Metadata_PriceLevel" relation. */
  delete?: InputMaybe<Array<MetadataPriceLevelWhereUniqueInput>>;
  /** Update existing "MetadataPriceLevel" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PriceLevel" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPricelevelUpdateInput>>;
  /** Upsert existing "MetadataPriceLevel" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PriceLevel" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPricelevelUpdateInput>>;
};

export type PhotoNestedMetadataPrintingprofileCreateInput = {
  /** Connect existing "MetadataPrintingProfile" nodes to the new "Photo" node, through the "Photo.Metadata_PrintingProfile" relation. */
  connect?: InputMaybe<Array<MetadataPrintingProfileWhereUniqueInput>>;
  /** Create new "MetadataPrintingProfile" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PrintingProfile" relation. */
  create?: InputMaybe<Array<MetadataPrintingProfileWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPrintingprofileUpdateInput = {
  /** Connect existing "MetadataPrintingProfile" nodes to the existing "Photo" node, through the "Photo.Metadata_PrintingProfile" relation. */
  connect?: InputMaybe<Array<MetadataPrintingProfileWhereUniqueInput>>;
  /** Create new "MetadataPrintingProfile" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PrintingProfile" relation. */
  create?: InputMaybe<Array<MetadataPrintingProfileWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPrintingProfile" nodes from the "Photo.Metadata_PrintingProfile" relation. */
  delete?: InputMaybe<Array<MetadataPrintingProfileWhereUniqueInput>>;
  /** Update existing "MetadataPrintingProfile" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PrintingProfile" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPrintingprofileUpdateInput>>;
  /** Upsert existing "MetadataPrintingProfile" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PrintingProfile" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPrintingprofileUpdateInput>>;
};

export type PhotoNestedMetadataPrismaproductionCreateInput = {
  /** Connect existing "MetadataPrismaProduction" nodes to the new "Photo" node, through the "Photo.Metadata_PrismaProduction" relation. */
  connect?: InputMaybe<Array<MetadataPrismaProductionWhereUniqueInput>>;
  /** Create new "MetadataPrismaProduction" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PrismaProduction" relation. */
  create?: InputMaybe<Array<MetadataPrismaProductionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPrismaproductionUpdateInput = {
  /** Connect existing "MetadataPrismaProduction" nodes to the existing "Photo" node, through the "Photo.Metadata_PrismaProduction" relation. */
  connect?: InputMaybe<Array<MetadataPrismaProductionWhereUniqueInput>>;
  /** Create new "MetadataPrismaProduction" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PrismaProduction" relation. */
  create?: InputMaybe<Array<MetadataPrismaProductionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPrismaProduction" nodes from the "Photo.Metadata_PrismaProduction" relation. */
  delete?: InputMaybe<Array<MetadataPrismaProductionWhereUniqueInput>>;
  /** Update existing "MetadataPrismaProduction" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PrismaProduction" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPrismaproductionUpdateInput>>;
  /** Upsert existing "MetadataPrismaProduction" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PrismaProduction" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPrismaproductionUpdateInput>>;
};

export type PhotoNestedMetadataProcesshistoryCreateInput = {
  /** Connect existing "MetadataProcessHistory" nodes to the new "Photo" node, through the "Photo.Metadata_ProcessHistory" relation. */
  connect?: InputMaybe<Array<MetadataProcessHistoryWhereUniqueInput>>;
  /** Create new "MetadataProcessHistory" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ProcessHistory" relation. */
  create?: InputMaybe<Array<MetadataProcessHistoryWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataProcesshistoryUpdateInput = {
  /** Connect existing "MetadataProcessHistory" nodes to the existing "Photo" node, through the "Photo.Metadata_ProcessHistory" relation. */
  connect?: InputMaybe<Array<MetadataProcessHistoryWhereUniqueInput>>;
  /** Create new "MetadataProcessHistory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProcessHistory" relation. */
  create?: InputMaybe<Array<MetadataProcessHistoryWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataProcessHistory" nodes from the "Photo.Metadata_ProcessHistory" relation. */
  delete?: InputMaybe<Array<MetadataProcessHistoryWhereUniqueInput>>;
  /** Update existing "MetadataProcessHistory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProcessHistory" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataProcesshistoryUpdateInput>>;
  /** Upsert existing "MetadataProcessHistory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProcessHistory" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataProcesshistoryUpdateInput>>;
};

export type PhotoNestedMetadataProcessparameterCreateInput = {
  /** Connect existing "MetadataProcessParameter" nodes to the new "Photo" node, through the "Photo.Metadata_ProcessParameter" relation. */
  connect?: InputMaybe<Array<MetadataProcessParameterWhereUniqueInput>>;
  /** Create new "MetadataProcessParameter" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ProcessParameter" relation. */
  create?: InputMaybe<Array<MetadataProcessParameterWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataProcessparameterUpdateInput = {
  /** Connect existing "MetadataProcessParameter" nodes to the existing "Photo" node, through the "Photo.Metadata_ProcessParameter" relation. */
  connect?: InputMaybe<Array<MetadataProcessParameterWhereUniqueInput>>;
  /** Create new "MetadataProcessParameter" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProcessParameter" relation. */
  create?: InputMaybe<Array<MetadataProcessParameterWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataProcessParameter" nodes from the "Photo.Metadata_ProcessParameter" relation. */
  delete?: InputMaybe<Array<MetadataProcessParameterWhereUniqueInput>>;
  /** Update existing "MetadataProcessParameter" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProcessParameter" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataProcessparameterUpdateInput>>;
  /** Upsert existing "MetadataProcessParameter" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProcessParameter" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataProcessparameterUpdateInput>>;
};

export type PhotoNestedMetadataProcessstatusCreateInput = {
  /** Connect existing "MetadataProcessStatus" nodes to the new "Photo" node, through the "Photo.Metadata_ProcessStatus" relation. */
  connect?: InputMaybe<Array<MetadataProcessStatusWhereUniqueInput>>;
  /** Create new "MetadataProcessStatus" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ProcessStatus" relation. */
  create?: InputMaybe<Array<MetadataProcessStatusWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataProcessstatusUpdateInput = {
  /** Connect existing "MetadataProcessStatus" nodes to the existing "Photo" node, through the "Photo.Metadata_ProcessStatus" relation. */
  connect?: InputMaybe<Array<MetadataProcessStatusWhereUniqueInput>>;
  /** Create new "MetadataProcessStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProcessStatus" relation. */
  create?: InputMaybe<Array<MetadataProcessStatusWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataProcessStatus" nodes from the "Photo.Metadata_ProcessStatus" relation. */
  delete?: InputMaybe<Array<MetadataProcessStatusWhereUniqueInput>>;
  /** Update existing "MetadataProcessStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProcessStatus" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataProcessstatusUpdateInput>>;
  /** Upsert existing "MetadataProcessStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProcessStatus" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataProcessstatusUpdateInput>>;
};

export type PhotoNestedMetadataProductCreateInput = {
  /** Connect existing "MetadataProduct" nodes to the new "Photo" node, through the "Photo.Metadata_Product" relation. */
  connect?: InputMaybe<Array<MetadataProductWhereUniqueInput>>;
  /** Create new "MetadataProduct" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Product" relation. */
  create?: InputMaybe<Array<MetadataProductWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataProductsCreateInput = {
  /** Connect existing "MetadataProducts" nodes to the new "Photo" node, through the "Photo.Metadata_Products" relation. */
  connect?: InputMaybe<Array<MetadataProductsWhereUniqueInput>>;
  /** Create new "MetadataProducts" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Products" relation. */
  create?: InputMaybe<Array<MetadataProductsWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataProductshortnameCreateInput = {
  /** Connect existing "MetadataProductShortName" nodes to the new "Photo" node, through the "Photo.Metadata_ProductShortName" relation. */
  connect?: InputMaybe<Array<MetadataProductShortNameWhereUniqueInput>>;
  /** Create new "MetadataProductShortName" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ProductShortName" relation. */
  create?: InputMaybe<Array<MetadataProductShortNameWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataProductshortnameUpdateInput = {
  /** Connect existing "MetadataProductShortName" nodes to the existing "Photo" node, through the "Photo.Metadata_ProductShortName" relation. */
  connect?: InputMaybe<Array<MetadataProductShortNameWhereUniqueInput>>;
  /** Create new "MetadataProductShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProductShortName" relation. */
  create?: InputMaybe<Array<MetadataProductShortNameWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataProductShortName" nodes from the "Photo.Metadata_ProductShortName" relation. */
  delete?: InputMaybe<Array<MetadataProductShortNameWhereUniqueInput>>;
  /** Update existing "MetadataProductShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProductShortName" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataProductshortnameUpdateInput>>;
  /** Upsert existing "MetadataProductShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProductShortName" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataProductshortnameUpdateInput>>;
};

export type PhotoNestedMetadataProductsUpdateInput = {
  /** Connect existing "MetadataProducts" nodes to the existing "Photo" node, through the "Photo.Metadata_Products" relation. */
  connect?: InputMaybe<Array<MetadataProductsWhereUniqueInput>>;
  /** Create new "MetadataProducts" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Products" relation. */
  create?: InputMaybe<Array<MetadataProductsWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataProducts" nodes from the "Photo.Metadata_Products" relation. */
  delete?: InputMaybe<Array<MetadataProductsWhereUniqueInput>>;
  /** Update existing "MetadataProducts" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Products" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataProductsUpdateInput>>;
  /** Upsert existing "MetadataProducts" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Products" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataProductsUpdateInput>>;
};

export type PhotoNestedMetadataProductUpdateInput = {
  /** Connect existing "MetadataProduct" nodes to the existing "Photo" node, through the "Photo.Metadata_Product" relation. */
  connect?: InputMaybe<Array<MetadataProductWhereUniqueInput>>;
  /** Create new "MetadataProduct" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Product" relation. */
  create?: InputMaybe<Array<MetadataProductWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataProduct" nodes from the "Photo.Metadata_Product" relation. */
  delete?: InputMaybe<Array<MetadataProductWhereUniqueInput>>;
  /** Update existing "MetadataProduct" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Product" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataProductUpdateInput>>;
  /** Upsert existing "MetadataProduct" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Product" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataProductUpdateInput>>;
};

export type PhotoNestedMetadataProduitsCreateInput = {
  /** Connect existing "MetadataProduits" nodes to the new "Photo" node, through the "Photo.Metadata_Produits" relation. */
  connect?: InputMaybe<Array<MetadataProduitsWhereUniqueInput>>;
  /** Create new "MetadataProduits" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Produits" relation. */
  create?: InputMaybe<Array<MetadataProduitsWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataProduitsUpdateInput = {
  /** Connect existing "MetadataProduits" nodes to the existing "Photo" node, through the "Photo.Metadata_Produits" relation. */
  connect?: InputMaybe<Array<MetadataProduitsWhereUniqueInput>>;
  /** Create new "MetadataProduits" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Produits" relation. */
  create?: InputMaybe<Array<MetadataProduitsWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataProduits" nodes from the "Photo.Metadata_Produits" relation. */
  delete?: InputMaybe<Array<MetadataProduitsWhereUniqueInput>>;
  /** Update existing "MetadataProduits" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Produits" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataProduitsUpdateInput>>;
  /** Upsert existing "MetadataProduits" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Produits" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataProduitsUpdateInput>>;
};

export type PhotoNestedMetadataProgramversionCreateInput = {
  /** Connect existing "MetadataProgramVersion" nodes to the new "Photo" node, through the "Photo.Metadata_ProgramVersion" relation. */
  connect?: InputMaybe<Array<MetadataProgramVersionWhereUniqueInput>>;
  /** Create new "MetadataProgramVersion" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ProgramVersion" relation. */
  create?: InputMaybe<Array<MetadataProgramVersionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataProgramversionUpdateInput = {
  /** Connect existing "MetadataProgramVersion" nodes to the existing "Photo" node, through the "Photo.Metadata_ProgramVersion" relation. */
  connect?: InputMaybe<Array<MetadataProgramVersionWhereUniqueInput>>;
  /** Create new "MetadataProgramVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProgramVersion" relation. */
  create?: InputMaybe<Array<MetadataProgramVersionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataProgramVersion" nodes from the "Photo.Metadata_ProgramVersion" relation. */
  delete?: InputMaybe<Array<MetadataProgramVersionWhereUniqueInput>>;
  /** Update existing "MetadataProgramVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProgramVersion" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataProgramversionUpdateInput>>;
  /** Upsert existing "MetadataProgramVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ProgramVersion" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataProgramversionUpdateInput>>;
};

export type PhotoNestedMetadataPropertyreleaseidCreateInput = {
  /** Connect existing "MetadataPropertyReleaseId" nodes to the new "Photo" node, through the "Photo.Metadata_PropertyReleaseID" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseId" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PropertyReleaseID" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseIdWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPropertyreleaseidUpdateInput = {
  /** Connect existing "MetadataPropertyReleaseId" nodes to the existing "Photo" node, through the "Photo.Metadata_PropertyReleaseID" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseIdWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PropertyReleaseID" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseIdWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPropertyReleaseId" nodes from the "Photo.Metadata_PropertyReleaseID" relation. */
  delete?: InputMaybe<Array<MetadataPropertyReleaseIdWhereUniqueInput>>;
  /** Update existing "MetadataPropertyReleaseId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PropertyReleaseID" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPropertyreleaseidUpdateInput>>;
  /** Upsert existing "MetadataPropertyReleaseId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PropertyReleaseID" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPropertyreleaseidUpdateInput>>;
};

export type PhotoNestedMetadataPropertyreleasestatusCreateInput = {
  /** Connect existing "MetadataPropertyReleaseStatus" nodes to the new "Photo" node, through the "Photo.Metadata_PropertyReleaseStatus" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseStatus" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_PropertyReleaseStatus" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseStatusWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPropertyreleasestatusUpdateInput = {
  /** Connect existing "MetadataPropertyReleaseStatus" nodes to the existing "Photo" node, through the "Photo.Metadata_PropertyReleaseStatus" relation. */
  connect?: InputMaybe<Array<MetadataPropertyReleaseStatusWhereUniqueInput>>;
  /** Create new "MetadataPropertyReleaseStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PropertyReleaseStatus" relation. */
  create?: InputMaybe<Array<MetadataPropertyReleaseStatusWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPropertyReleaseStatus" nodes from the "Photo.Metadata_PropertyReleaseStatus" relation. */
  delete?: InputMaybe<Array<MetadataPropertyReleaseStatusWhereUniqueInput>>;
  /** Update existing "MetadataPropertyReleaseStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PropertyReleaseStatus" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPropertyreleasestatusUpdateInput>>;
  /** Upsert existing "MetadataPropertyReleaseStatus" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_PropertyReleaseStatus" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPropertyreleasestatusUpdateInput>>;
};

export type PhotoNestedMetadataPublisherCreateInput = {
  /** Connect existing "MetadataPublisher" nodes to the new "Photo" node, through the "Photo.Metadata_Publisher" relation. */
  connect?: InputMaybe<Array<MetadataPublisherWhereUniqueInput>>;
  /** Create new "MetadataPublisher" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Publisher" relation. */
  create?: InputMaybe<Array<MetadataPublisherWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPublisherUpdateInput = {
  /** Connect existing "MetadataPublisher" nodes to the existing "Photo" node, through the "Photo.Metadata_Publisher" relation. */
  connect?: InputMaybe<Array<MetadataPublisherWhereUniqueInput>>;
  /** Create new "MetadataPublisher" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Publisher" relation. */
  create?: InputMaybe<Array<MetadataPublisherWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPublisher" nodes from the "Photo.Metadata_Publisher" relation. */
  delete?: InputMaybe<Array<MetadataPublisherWhereUniqueInput>>;
  /** Update existing "MetadataPublisher" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Publisher" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPublisherUpdateInput>>;
  /** Upsert existing "MetadataPublisher" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Publisher" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPublisherUpdateInput>>;
};

export type PhotoNestedMetadataPublishingissueCreateInput = {
  /** Connect existing "MetadataPublishingIssue" nodes to the new "Photo" node, through the "Photo.Metadata_publishingIssue" relation. */
  connect?: InputMaybe<Array<MetadataPublishingIssueWhereUniqueInput>>;
  /** Create new "MetadataPublishingIssue" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_publishingIssue" relation. */
  create?: InputMaybe<Array<MetadataPublishingIssueWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPublishingissueUpdateInput = {
  /** Connect existing "MetadataPublishingIssue" nodes to the existing "Photo" node, through the "Photo.Metadata_publishingIssue" relation. */
  connect?: InputMaybe<Array<MetadataPublishingIssueWhereUniqueInput>>;
  /** Create new "MetadataPublishingIssue" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_publishingIssue" relation. */
  create?: InputMaybe<Array<MetadataPublishingIssueWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPublishingIssue" nodes from the "Photo.Metadata_publishingIssue" relation. */
  delete?: InputMaybe<Array<MetadataPublishingIssueWhereUniqueInput>>;
  /** Update existing "MetadataPublishingIssue" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_publishingIssue" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPublishingissueUpdateInput>>;
  /** Upsert existing "MetadataPublishingIssue" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_publishingIssue" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPublishingissueUpdateInput>>;
};

export type PhotoNestedMetadataPublishingsubjectCreateInput = {
  /** Connect existing "MetadataPublishingSubject" nodes to the new "Photo" node, through the "Photo.Metadata_publishingSubject" relation. */
  connect?: InputMaybe<Array<MetadataPublishingSubjectWhereUniqueInput>>;
  /** Create new "MetadataPublishingSubject" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_publishingSubject" relation. */
  create?: InputMaybe<Array<MetadataPublishingSubjectWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataPublishingsubjectUpdateInput = {
  /** Connect existing "MetadataPublishingSubject" nodes to the existing "Photo" node, through the "Photo.Metadata_publishingSubject" relation. */
  connect?: InputMaybe<Array<MetadataPublishingSubjectWhereUniqueInput>>;
  /** Create new "MetadataPublishingSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_publishingSubject" relation. */
  create?: InputMaybe<Array<MetadataPublishingSubjectWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataPublishingSubject" nodes from the "Photo.Metadata_publishingSubject" relation. */
  delete?: InputMaybe<Array<MetadataPublishingSubjectWhereUniqueInput>>;
  /** Update existing "MetadataPublishingSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_publishingSubject" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataPublishingsubjectUpdateInput>>;
  /** Upsert existing "MetadataPublishingSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_publishingSubject" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataPublishingsubjectUpdateInput>>;
};

export type PhotoNestedMetadataQualifieduseprismabyCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaBy" nodes to the new "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaBy" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaByWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaBy" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaBy" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaByWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataQualifieduseprismabyUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaBy" nodes to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaBy" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaByWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaBy" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaBy" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaByWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaBy" nodes from the "Photo.Metadata_QualifiedUsePrismaBy" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaByWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaBy" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaBy" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataQualifieduseprismabyUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaBy" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaBy" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataQualifieduseprismabyUpdateInput>>;
};

export type PhotoNestedMetadataQualifieduseprismadateCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDate" nodes to the new "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDate" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDate" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDate" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataQualifieduseprismadateUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDate" nodes to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDate" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDate" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaDate" nodes from the "Photo.Metadata_QualifiedUsePrismaDate" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaDateWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaDate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDate" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataQualifieduseprismadateUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaDate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDate" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataQualifieduseprismadateUpdateInput>>;
};

export type PhotoNestedMetadataQualifieduseprismadurationCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDuration" nodes to the new "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDuration" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDuration" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDuration" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataQualifieduseprismadurationUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaDuration" nodes to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDuration" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaDuration" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDuration" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaDuration" nodes from the "Photo.Metadata_QualifiedUsePrismaDuration" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaDuration" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDuration" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataQualifieduseprismadurationUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaDuration" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaDuration" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataQualifieduseprismadurationUpdateInput>>;
};

export type PhotoNestedMetadataQualifieduseprismasupportCreateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaSupport" nodes to the new "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaSupport" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaSupport" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaSupport" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataQualifieduseprismasupportUpdateInput = {
  /** Connect existing "MetadataQualifiedUsePrismaSupport" nodes to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaSupport" relation. */
  connect?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWhereUniqueInput>>;
  /** Create new "MetadataQualifiedUsePrismaSupport" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaSupport" relation. */
  create?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataQualifiedUsePrismaSupport" nodes from the "Photo.Metadata_QualifiedUsePrismaSupport" relation. */
  delete?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportWhereUniqueInput>>;
  /** Update existing "MetadataQualifiedUsePrismaSupport" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaSupport" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataQualifieduseprismasupportUpdateInput>>;
  /** Upsert existing "MetadataQualifiedUsePrismaSupport" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_QualifiedUsePrismaSupport" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataQualifieduseprismasupportUpdateInput>>;
};

export type PhotoNestedMetadataRatingCreateInput = {
  /** Connect existing "MetadataRating" nodes to the new "Photo" node, through the "Photo.Metadata_Rating" relation. */
  connect?: InputMaybe<Array<MetadataRatingWhereUniqueInput>>;
  /** Create new "MetadataRating" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Rating" relation. */
  create?: InputMaybe<Array<MetadataRatingWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataRatingUpdateInput = {
  /** Connect existing "MetadataRating" nodes to the existing "Photo" node, through the "Photo.Metadata_Rating" relation. */
  connect?: InputMaybe<Array<MetadataRatingWhereUniqueInput>>;
  /** Create new "MetadataRating" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Rating" relation. */
  create?: InputMaybe<Array<MetadataRatingWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataRating" nodes from the "Photo.Metadata_Rating" relation. */
  delete?: InputMaybe<Array<MetadataRatingWhereUniqueInput>>;
  /** Update existing "MetadataRating" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Rating" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataRatingUpdateInput>>;
  /** Upsert existing "MetadataRating" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Rating" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataRatingUpdateInput>>;
};

export type PhotoNestedMetadataReferencedateCreateInput = {
  /** Connect existing "MetadataReferenceDate" nodes to the new "Photo" node, through the "Photo.Metadata_ReferenceDate" relation. */
  connect?: InputMaybe<Array<MetadataReferenceDateWhereUniqueInput>>;
  /** Create new "MetadataReferenceDate" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ReferenceDate" relation. */
  create?: InputMaybe<Array<MetadataReferenceDateWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataReferencedateUpdateInput = {
  /** Connect existing "MetadataReferenceDate" nodes to the existing "Photo" node, through the "Photo.Metadata_ReferenceDate" relation. */
  connect?: InputMaybe<Array<MetadataReferenceDateWhereUniqueInput>>;
  /** Create new "MetadataReferenceDate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReferenceDate" relation. */
  create?: InputMaybe<Array<MetadataReferenceDateWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataReferenceDate" nodes from the "Photo.Metadata_ReferenceDate" relation. */
  delete?: InputMaybe<Array<MetadataReferenceDateWhereUniqueInput>>;
  /** Update existing "MetadataReferenceDate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReferenceDate" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataReferencedateUpdateInput>>;
  /** Upsert existing "MetadataReferenceDate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReferenceDate" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataReferencedateUpdateInput>>;
};

export type PhotoNestedMetadataReferencenumberCreateInput = {
  /** Connect existing "MetadataReferenceNumber" nodes to the new "Photo" node, through the "Photo.Metadata_ReferenceNumber" relation. */
  connect?: InputMaybe<Array<MetadataReferenceNumberWhereUniqueInput>>;
  /** Create new "MetadataReferenceNumber" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ReferenceNumber" relation. */
  create?: InputMaybe<Array<MetadataReferenceNumberWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataReferencenumberUpdateInput = {
  /** Connect existing "MetadataReferenceNumber" nodes to the existing "Photo" node, through the "Photo.Metadata_ReferenceNumber" relation. */
  connect?: InputMaybe<Array<MetadataReferenceNumberWhereUniqueInput>>;
  /** Create new "MetadataReferenceNumber" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReferenceNumber" relation. */
  create?: InputMaybe<Array<MetadataReferenceNumberWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataReferenceNumber" nodes from the "Photo.Metadata_ReferenceNumber" relation. */
  delete?: InputMaybe<Array<MetadataReferenceNumberWhereUniqueInput>>;
  /** Update existing "MetadataReferenceNumber" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReferenceNumber" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataReferencenumberUpdateInput>>;
  /** Upsert existing "MetadataReferenceNumber" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReferenceNumber" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataReferencenumberUpdateInput>>;
};

export type PhotoNestedMetadataReferenceserviceCreateInput = {
  /** Connect existing "MetadataReferenceService" nodes to the new "Photo" node, through the "Photo.Metadata_ReferenceService" relation. */
  connect?: InputMaybe<Array<MetadataReferenceServiceWhereUniqueInput>>;
  /** Create new "MetadataReferenceService" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ReferenceService" relation. */
  create?: InputMaybe<Array<MetadataReferenceServiceWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataReferenceserviceUpdateInput = {
  /** Connect existing "MetadataReferenceService" nodes to the existing "Photo" node, through the "Photo.Metadata_ReferenceService" relation. */
  connect?: InputMaybe<Array<MetadataReferenceServiceWhereUniqueInput>>;
  /** Create new "MetadataReferenceService" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReferenceService" relation. */
  create?: InputMaybe<Array<MetadataReferenceServiceWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataReferenceService" nodes from the "Photo.Metadata_ReferenceService" relation. */
  delete?: InputMaybe<Array<MetadataReferenceServiceWhereUniqueInput>>;
  /** Update existing "MetadataReferenceService" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReferenceService" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataReferenceserviceUpdateInput>>;
  /** Upsert existing "MetadataReferenceService" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReferenceService" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataReferenceserviceUpdateInput>>;
};

export type PhotoNestedMetadataReleasedateCreateInput = {
  /** Connect existing "MetadataReleaseDate" nodes to the new "Photo" node, through the "Photo.Metadata_ReleaseDate" relation. */
  connect?: InputMaybe<Array<MetadataReleaseDateWhereUniqueInput>>;
  /** Create new "MetadataReleaseDate" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ReleaseDate" relation. */
  create?: InputMaybe<Array<MetadataReleaseDateWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataReleasedateUpdateInput = {
  /** Connect existing "MetadataReleaseDate" nodes to the existing "Photo" node, through the "Photo.Metadata_ReleaseDate" relation. */
  connect?: InputMaybe<Array<MetadataReleaseDateWhereUniqueInput>>;
  /** Create new "MetadataReleaseDate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReleaseDate" relation. */
  create?: InputMaybe<Array<MetadataReleaseDateWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataReleaseDate" nodes from the "Photo.Metadata_ReleaseDate" relation. */
  delete?: InputMaybe<Array<MetadataReleaseDateWhereUniqueInput>>;
  /** Update existing "MetadataReleaseDate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReleaseDate" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataReleasedateUpdateInput>>;
  /** Upsert existing "MetadataReleaseDate" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReleaseDate" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataReleasedateUpdateInput>>;
};

export type PhotoNestedMetadataReleasetimeCreateInput = {
  /** Connect existing "MetadataReleaseTime" nodes to the new "Photo" node, through the "Photo.Metadata_ReleaseTime" relation. */
  connect?: InputMaybe<Array<MetadataReleaseTimeWhereUniqueInput>>;
  /** Create new "MetadataReleaseTime" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ReleaseTime" relation. */
  create?: InputMaybe<Array<MetadataReleaseTimeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataReleasetimeUpdateInput = {
  /** Connect existing "MetadataReleaseTime" nodes to the existing "Photo" node, through the "Photo.Metadata_ReleaseTime" relation. */
  connect?: InputMaybe<Array<MetadataReleaseTimeWhereUniqueInput>>;
  /** Create new "MetadataReleaseTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReleaseTime" relation. */
  create?: InputMaybe<Array<MetadataReleaseTimeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataReleaseTime" nodes from the "Photo.Metadata_ReleaseTime" relation. */
  delete?: InputMaybe<Array<MetadataReleaseTimeWhereUniqueInput>>;
  /** Update existing "MetadataReleaseTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReleaseTime" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataReleasetimeUpdateInput>>;
  /** Upsert existing "MetadataReleaseTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ReleaseTime" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataReleasetimeUpdateInput>>;
};

export type PhotoNestedMetadataRequiredpermissionCreateInput = {
  /** Connect existing "MetadataRequiredPermission" nodes to the new "Photo" node, through the "Photo.Metadata_RequiredPermission" relation. */
  connect?: InputMaybe<Array<MetadataRequiredPermissionWhereUniqueInput>>;
  /** Create new "MetadataRequiredPermission" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_RequiredPermission" relation. */
  create?: InputMaybe<Array<MetadataRequiredPermissionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataRequiredpermissionUpdateInput = {
  /** Connect existing "MetadataRequiredPermission" nodes to the existing "Photo" node, through the "Photo.Metadata_RequiredPermission" relation. */
  connect?: InputMaybe<Array<MetadataRequiredPermissionWhereUniqueInput>>;
  /** Create new "MetadataRequiredPermission" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_RequiredPermission" relation. */
  create?: InputMaybe<Array<MetadataRequiredPermissionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataRequiredPermission" nodes from the "Photo.Metadata_RequiredPermission" relation. */
  delete?: InputMaybe<Array<MetadataRequiredPermissionWhereUniqueInput>>;
  /** Update existing "MetadataRequiredPermission" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_RequiredPermission" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataRequiredpermissionUpdateInput>>;
  /** Upsert existing "MetadataRequiredPermission" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_RequiredPermission" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataRequiredpermissionUpdateInput>>;
};

export type PhotoNestedMetadataResolutionkindCreateInput = {
  /** Connect existing "MetadataResolutionKind" nodes to the new "Photo" node, through the "Photo.Metadata_ResolutionKind" relation. */
  connect?: InputMaybe<Array<MetadataResolutionKindWhereUniqueInput>>;
  /** Create new "MetadataResolutionKind" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ResolutionKind" relation. */
  create?: InputMaybe<Array<MetadataResolutionKindWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataResolutionkindUpdateInput = {
  /** Connect existing "MetadataResolutionKind" nodes to the existing "Photo" node, through the "Photo.Metadata_ResolutionKind" relation. */
  connect?: InputMaybe<Array<MetadataResolutionKindWhereUniqueInput>>;
  /** Create new "MetadataResolutionKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ResolutionKind" relation. */
  create?: InputMaybe<Array<MetadataResolutionKindWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataResolutionKind" nodes from the "Photo.Metadata_ResolutionKind" relation. */
  delete?: InputMaybe<Array<MetadataResolutionKindWhereUniqueInput>>;
  /** Update existing "MetadataResolutionKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ResolutionKind" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataResolutionkindUpdateInput>>;
  /** Upsert existing "MetadataResolutionKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ResolutionKind" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataResolutionkindUpdateInput>>;
};

export type PhotoNestedMetadataRightsCreateInput = {
  /** Connect existing "MetadataRights" nodes to the new "Photo" node, through the "Photo.Metadata_rights" relation. */
  connect?: InputMaybe<Array<MetadataRightsWhereUniqueInput>>;
  /** Create new "MetadataRights" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_rights" relation. */
  create?: InputMaybe<Array<MetadataRightsWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataRightsUpdateInput = {
  /** Connect existing "MetadataRights" nodes to the existing "Photo" node, through the "Photo.Metadata_rights" relation. */
  connect?: InputMaybe<Array<MetadataRightsWhereUniqueInput>>;
  /** Create new "MetadataRights" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_rights" relation. */
  create?: InputMaybe<Array<MetadataRightsWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataRights" nodes from the "Photo.Metadata_rights" relation. */
  delete?: InputMaybe<Array<MetadataRightsWhereUniqueInput>>;
  /** Update existing "MetadataRights" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_rights" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataRightsUpdateInput>>;
  /** Upsert existing "MetadataRights" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_rights" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataRightsUpdateInput>>;
};

export type PhotoNestedMetadataRoyaltyfreeCreateInput = {
  /** Connect existing "MetadataRoyaltyfree" nodes to the new "Photo" node, through the "Photo.Metadata_Royaltyfree" relation. */
  connect?: InputMaybe<Array<MetadataRoyaltyfreeWhereUniqueInput>>;
  /** Create new "MetadataRoyaltyfree" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Royaltyfree" relation. */
  create?: InputMaybe<Array<MetadataRoyaltyfreeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataRoyaltyfreeUpdateInput = {
  /** Connect existing "MetadataRoyaltyfree" nodes to the existing "Photo" node, through the "Photo.Metadata_Royaltyfree" relation. */
  connect?: InputMaybe<Array<MetadataRoyaltyfreeWhereUniqueInput>>;
  /** Create new "MetadataRoyaltyfree" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Royaltyfree" relation. */
  create?: InputMaybe<Array<MetadataRoyaltyfreeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataRoyaltyfree" nodes from the "Photo.Metadata_Royaltyfree" relation. */
  delete?: InputMaybe<Array<MetadataRoyaltyfreeWhereUniqueInput>>;
  /** Update existing "MetadataRoyaltyfree" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Royaltyfree" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataRoyaltyfreeUpdateInput>>;
  /** Upsert existing "MetadataRoyaltyfree" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Royaltyfree" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataRoyaltyfreeUpdateInput>>;
};

export type PhotoNestedMetadataSceneCreateInput = {
  /** Connect existing "MetadataScene" nodes to the new "Photo" node, through the "Photo.Metadata_Scene" relation. */
  connect?: InputMaybe<Array<MetadataSceneWhereUniqueInput>>;
  /** Create new "MetadataScene" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Scene" relation. */
  create?: InputMaybe<Array<MetadataSceneWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSceneUpdateInput = {
  /** Connect existing "MetadataScene" nodes to the existing "Photo" node, through the "Photo.Metadata_Scene" relation. */
  connect?: InputMaybe<Array<MetadataSceneWhereUniqueInput>>;
  /** Create new "MetadataScene" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Scene" relation. */
  create?: InputMaybe<Array<MetadataSceneWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataScene" nodes from the "Photo.Metadata_Scene" relation. */
  delete?: InputMaybe<Array<MetadataSceneWhereUniqueInput>>;
  /** Update existing "MetadataScene" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Scene" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSceneUpdateInput>>;
  /** Upsert existing "MetadataScene" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Scene" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSceneUpdateInput>>;
};

export type PhotoNestedMetadataSectionCreateInput = {
  /** Connect existing "MetadataSection" nodes to the new "Photo" node, through the "Photo.Metadata_Section" relation. */
  connect?: InputMaybe<Array<MetadataSectionWhereUniqueInput>>;
  /** Create new "MetadataSection" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Section" relation. */
  create?: InputMaybe<Array<MetadataSectionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSectionUpdateInput = {
  /** Connect existing "MetadataSection" nodes to the existing "Photo" node, through the "Photo.Metadata_Section" relation. */
  connect?: InputMaybe<Array<MetadataSectionWhereUniqueInput>>;
  /** Create new "MetadataSection" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Section" relation. */
  create?: InputMaybe<Array<MetadataSectionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSection" nodes from the "Photo.Metadata_Section" relation. */
  delete?: InputMaybe<Array<MetadataSectionWhereUniqueInput>>;
  /** Update existing "MetadataSection" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Section" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSectionUpdateInput>>;
  /** Upsert existing "MetadataSection" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Section" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSectionUpdateInput>>;
};

export type PhotoNestedMetadataSenttoCreateInput = {
  /** Connect existing "MetadataSentTo" nodes to the new "Photo" node, through the "Photo.Metadata_SentTo" relation. */
  connect?: InputMaybe<Array<MetadataSentToWhereUniqueInput>>;
  /** Create new "MetadataSentTo" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_SentTo" relation. */
  create?: InputMaybe<Array<MetadataSentToWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSenttoUpdateInput = {
  /** Connect existing "MetadataSentTo" nodes to the existing "Photo" node, through the "Photo.Metadata_SentTo" relation. */
  connect?: InputMaybe<Array<MetadataSentToWhereUniqueInput>>;
  /** Create new "MetadataSentTo" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SentTo" relation. */
  create?: InputMaybe<Array<MetadataSentToWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSentTo" nodes from the "Photo.Metadata_SentTo" relation. */
  delete?: InputMaybe<Array<MetadataSentToWhereUniqueInput>>;
  /** Update existing "MetadataSentTo" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SentTo" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSenttoUpdateInput>>;
  /** Upsert existing "MetadataSentTo" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SentTo" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSenttoUpdateInput>>;
};

export type PhotoNestedMetadataSerialnumberCreateInput = {
  /** Connect existing "MetadataSerialNumber" nodes to the new "Photo" node, through the "Photo.Metadata_SerialNumber" relation. */
  connect?: InputMaybe<Array<MetadataSerialNumberWhereUniqueInput>>;
  /** Create new "MetadataSerialNumber" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_SerialNumber" relation. */
  create?: InputMaybe<Array<MetadataSerialNumberWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSerialnumberUpdateInput = {
  /** Connect existing "MetadataSerialNumber" nodes to the existing "Photo" node, through the "Photo.Metadata_SerialNumber" relation. */
  connect?: InputMaybe<Array<MetadataSerialNumberWhereUniqueInput>>;
  /** Create new "MetadataSerialNumber" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SerialNumber" relation. */
  create?: InputMaybe<Array<MetadataSerialNumberWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSerialNumber" nodes from the "Photo.Metadata_SerialNumber" relation. */
  delete?: InputMaybe<Array<MetadataSerialNumberWhereUniqueInput>>;
  /** Update existing "MetadataSerialNumber" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SerialNumber" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSerialnumberUpdateInput>>;
  /** Upsert existing "MetadataSerialNumber" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SerialNumber" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSerialnumberUpdateInput>>;
};

export type PhotoNestedMetadataSeriesdatetimeCreateInput = {
  /** Connect existing "MetadataSeriesDateTime" nodes to the new "Photo" node, through the "Photo.Metadata_SeriesDateTime" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDateTimeWhereUniqueInput>>;
  /** Create new "MetadataSeriesDateTime" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_SeriesDateTime" relation. */
  create?: InputMaybe<Array<MetadataSeriesDateTimeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSeriesdatetimeUpdateInput = {
  /** Connect existing "MetadataSeriesDateTime" nodes to the existing "Photo" node, through the "Photo.Metadata_SeriesDateTime" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDateTimeWhereUniqueInput>>;
  /** Create new "MetadataSeriesDateTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesDateTime" relation. */
  create?: InputMaybe<Array<MetadataSeriesDateTimeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSeriesDateTime" nodes from the "Photo.Metadata_SeriesDateTime" relation. */
  delete?: InputMaybe<Array<MetadataSeriesDateTimeWhereUniqueInput>>;
  /** Update existing "MetadataSeriesDateTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesDateTime" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSeriesdatetimeUpdateInput>>;
  /** Upsert existing "MetadataSeriesDateTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesDateTime" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSeriesdatetimeUpdateInput>>;
};

export type PhotoNestedMetadataSeriesdescriptionCreateInput = {
  /** Connect existing "MetadataSeriesDescription" nodes to the new "Photo" node, through the "Photo.Metadata_SeriesDescription" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDescriptionWhereUniqueInput>>;
  /** Create new "MetadataSeriesDescription" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_SeriesDescription" relation. */
  create?: InputMaybe<Array<MetadataSeriesDescriptionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSeriesdescriptionUpdateInput = {
  /** Connect existing "MetadataSeriesDescription" nodes to the existing "Photo" node, through the "Photo.Metadata_SeriesDescription" relation. */
  connect?: InputMaybe<Array<MetadataSeriesDescriptionWhereUniqueInput>>;
  /** Create new "MetadataSeriesDescription" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesDescription" relation. */
  create?: InputMaybe<Array<MetadataSeriesDescriptionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSeriesDescription" nodes from the "Photo.Metadata_SeriesDescription" relation. */
  delete?: InputMaybe<Array<MetadataSeriesDescriptionWhereUniqueInput>>;
  /** Update existing "MetadataSeriesDescription" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesDescription" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSeriesdescriptionUpdateInput>>;
  /** Upsert existing "MetadataSeriesDescription" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesDescription" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSeriesdescriptionUpdateInput>>;
};

export type PhotoNestedMetadataSeriesmodalityCreateInput = {
  /** Connect existing "MetadataSeriesModality" nodes to the new "Photo" node, through the "Photo.Metadata_SeriesModality" relation. */
  connect?: InputMaybe<Array<MetadataSeriesModalityWhereUniqueInput>>;
  /** Create new "MetadataSeriesModality" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_SeriesModality" relation. */
  create?: InputMaybe<Array<MetadataSeriesModalityWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSeriesmodalityUpdateInput = {
  /** Connect existing "MetadataSeriesModality" nodes to the existing "Photo" node, through the "Photo.Metadata_SeriesModality" relation. */
  connect?: InputMaybe<Array<MetadataSeriesModalityWhereUniqueInput>>;
  /** Create new "MetadataSeriesModality" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesModality" relation. */
  create?: InputMaybe<Array<MetadataSeriesModalityWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSeriesModality" nodes from the "Photo.Metadata_SeriesModality" relation. */
  delete?: InputMaybe<Array<MetadataSeriesModalityWhereUniqueInput>>;
  /** Update existing "MetadataSeriesModality" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesModality" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSeriesmodalityUpdateInput>>;
  /** Upsert existing "MetadataSeriesModality" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesModality" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSeriesmodalityUpdateInput>>;
};

export type PhotoNestedMetadataSeriesnumberCreateInput = {
  /** Connect existing "MetadataSeriesNumber" nodes to the new "Photo" node, through the "Photo.Metadata_SeriesNumber" relation. */
  connect?: InputMaybe<Array<MetadataSeriesNumberWhereUniqueInput>>;
  /** Create new "MetadataSeriesNumber" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_SeriesNumber" relation. */
  create?: InputMaybe<Array<MetadataSeriesNumberWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSeriesnumberUpdateInput = {
  /** Connect existing "MetadataSeriesNumber" nodes to the existing "Photo" node, through the "Photo.Metadata_SeriesNumber" relation. */
  connect?: InputMaybe<Array<MetadataSeriesNumberWhereUniqueInput>>;
  /** Create new "MetadataSeriesNumber" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesNumber" relation. */
  create?: InputMaybe<Array<MetadataSeriesNumberWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSeriesNumber" nodes from the "Photo.Metadata_SeriesNumber" relation. */
  delete?: InputMaybe<Array<MetadataSeriesNumberWhereUniqueInput>>;
  /** Update existing "MetadataSeriesNumber" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesNumber" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSeriesnumberUpdateInput>>;
  /** Upsert existing "MetadataSeriesNumber" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SeriesNumber" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSeriesnumberUpdateInput>>;
};

export type PhotoNestedMetadataShortuniqueidCreateInput = {
  /** Connect existing "MetadataShortUniqueId" nodes to the new "Photo" node, through the "Photo.Metadata_ShortUniqueId" relation. */
  connect?: InputMaybe<Array<MetadataShortUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataShortUniqueId" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ShortUniqueId" relation. */
  create?: InputMaybe<Array<MetadataShortUniqueIdWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataShortuniqueidUpdateInput = {
  /** Connect existing "MetadataShortUniqueId" nodes to the existing "Photo" node, through the "Photo.Metadata_ShortUniqueId" relation. */
  connect?: InputMaybe<Array<MetadataShortUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataShortUniqueId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ShortUniqueId" relation. */
  create?: InputMaybe<Array<MetadataShortUniqueIdWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataShortUniqueId" nodes from the "Photo.Metadata_ShortUniqueId" relation. */
  delete?: InputMaybe<Array<MetadataShortUniqueIdWhereUniqueInput>>;
  /** Update existing "MetadataShortUniqueId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ShortUniqueId" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataShortuniqueidUpdateInput>>;
  /** Upsert existing "MetadataShortUniqueId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ShortUniqueId" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataShortuniqueidUpdateInput>>;
};

export type PhotoNestedMetadataSourceCreateInput = {
  /** Connect existing "MetadataSource" nodes to the new "Photo" node, through the "Photo.Metadata_Source" relation. */
  connect?: InputMaybe<Array<MetadataSourceWhereUniqueInput>>;
  /** Create new "MetadataSource" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Source" relation. */
  create?: InputMaybe<Array<MetadataSourceWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSourceUpdateInput = {
  /** Connect existing "MetadataSource" nodes to the existing "Photo" node, through the "Photo.Metadata_Source" relation. */
  connect?: InputMaybe<Array<MetadataSourceWhereUniqueInput>>;
  /** Create new "MetadataSource" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Source" relation. */
  create?: InputMaybe<Array<MetadataSourceWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSource" nodes from the "Photo.Metadata_Source" relation. */
  delete?: InputMaybe<Array<MetadataSourceWhereUniqueInput>>;
  /** Update existing "MetadataSource" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Source" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSourceUpdateInput>>;
  /** Upsert existing "MetadataSource" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Source" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSourceUpdateInput>>;
};

export type PhotoNestedMetadataStateCreateInput = {
  /** Connect existing "MetadataState" nodes to the new "Photo" node, through the "Photo.Metadata_State" relation. */
  connect?: InputMaybe<Array<MetadataStateWhereUniqueInput>>;
  /** Create new "MetadataState" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_State" relation. */
  create?: InputMaybe<Array<MetadataStateWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataStateUpdateInput = {
  /** Connect existing "MetadataState" nodes to the existing "Photo" node, through the "Photo.Metadata_State" relation. */
  connect?: InputMaybe<Array<MetadataStateWhereUniqueInput>>;
  /** Create new "MetadataState" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_State" relation. */
  create?: InputMaybe<Array<MetadataStateWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataState" nodes from the "Photo.Metadata_State" relation. */
  delete?: InputMaybe<Array<MetadataStateWhereUniqueInput>>;
  /** Update existing "MetadataState" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_State" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataStateUpdateInput>>;
  /** Upsert existing "MetadataState" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_State" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataStateUpdateInput>>;
};

export type PhotoNestedMetadataStudydatetimeCreateInput = {
  /** Connect existing "MetadataStudyDateTime" nodes to the new "Photo" node, through the "Photo.Metadata_StudyDateTime" relation. */
  connect?: InputMaybe<Array<MetadataStudyDateTimeWhereUniqueInput>>;
  /** Create new "MetadataStudyDateTime" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_StudyDateTime" relation. */
  create?: InputMaybe<Array<MetadataStudyDateTimeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataStudydatetimeUpdateInput = {
  /** Connect existing "MetadataStudyDateTime" nodes to the existing "Photo" node, through the "Photo.Metadata_StudyDateTime" relation. */
  connect?: InputMaybe<Array<MetadataStudyDateTimeWhereUniqueInput>>;
  /** Create new "MetadataStudyDateTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyDateTime" relation. */
  create?: InputMaybe<Array<MetadataStudyDateTimeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataStudyDateTime" nodes from the "Photo.Metadata_StudyDateTime" relation. */
  delete?: InputMaybe<Array<MetadataStudyDateTimeWhereUniqueInput>>;
  /** Update existing "MetadataStudyDateTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyDateTime" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataStudydatetimeUpdateInput>>;
  /** Upsert existing "MetadataStudyDateTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyDateTime" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataStudydatetimeUpdateInput>>;
};

export type PhotoNestedMetadataStudydescriptionCreateInput = {
  /** Connect existing "MetadataStudyDescription" nodes to the new "Photo" node, through the "Photo.Metadata_StudyDescription" relation. */
  connect?: InputMaybe<Array<MetadataStudyDescriptionWhereUniqueInput>>;
  /** Create new "MetadataStudyDescription" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_StudyDescription" relation. */
  create?: InputMaybe<Array<MetadataStudyDescriptionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataStudydescriptionUpdateInput = {
  /** Connect existing "MetadataStudyDescription" nodes to the existing "Photo" node, through the "Photo.Metadata_StudyDescription" relation. */
  connect?: InputMaybe<Array<MetadataStudyDescriptionWhereUniqueInput>>;
  /** Create new "MetadataStudyDescription" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyDescription" relation. */
  create?: InputMaybe<Array<MetadataStudyDescriptionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataStudyDescription" nodes from the "Photo.Metadata_StudyDescription" relation. */
  delete?: InputMaybe<Array<MetadataStudyDescriptionWhereUniqueInput>>;
  /** Update existing "MetadataStudyDescription" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyDescription" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataStudydescriptionUpdateInput>>;
  /** Upsert existing "MetadataStudyDescription" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyDescription" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataStudydescriptionUpdateInput>>;
};

export type PhotoNestedMetadataStudyidCreateInput = {
  /** Connect existing "MetadataStudyId" nodes to the new "Photo" node, through the "Photo.Metadata_StudyID" relation. */
  connect?: InputMaybe<Array<MetadataStudyIdWhereUniqueInput>>;
  /** Create new "MetadataStudyId" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_StudyID" relation. */
  create?: InputMaybe<Array<MetadataStudyIdWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataStudyidUpdateInput = {
  /** Connect existing "MetadataStudyId" nodes to the existing "Photo" node, through the "Photo.Metadata_StudyID" relation. */
  connect?: InputMaybe<Array<MetadataStudyIdWhereUniqueInput>>;
  /** Create new "MetadataStudyId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyID" relation. */
  create?: InputMaybe<Array<MetadataStudyIdWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataStudyId" nodes from the "Photo.Metadata_StudyID" relation. */
  delete?: InputMaybe<Array<MetadataStudyIdWhereUniqueInput>>;
  /** Update existing "MetadataStudyId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyID" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataStudyidUpdateInput>>;
  /** Upsert existing "MetadataStudyId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyID" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataStudyidUpdateInput>>;
};

export type PhotoNestedMetadataStudyphysicianCreateInput = {
  /** Connect existing "MetadataStudyPhysician" nodes to the new "Photo" node, through the "Photo.Metadata_StudyPhysician" relation. */
  connect?: InputMaybe<Array<MetadataStudyPhysicianWhereUniqueInput>>;
  /** Create new "MetadataStudyPhysician" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_StudyPhysician" relation. */
  create?: InputMaybe<Array<MetadataStudyPhysicianWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataStudyphysicianUpdateInput = {
  /** Connect existing "MetadataStudyPhysician" nodes to the existing "Photo" node, through the "Photo.Metadata_StudyPhysician" relation. */
  connect?: InputMaybe<Array<MetadataStudyPhysicianWhereUniqueInput>>;
  /** Create new "MetadataStudyPhysician" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyPhysician" relation. */
  create?: InputMaybe<Array<MetadataStudyPhysicianWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataStudyPhysician" nodes from the "Photo.Metadata_StudyPhysician" relation. */
  delete?: InputMaybe<Array<MetadataStudyPhysicianWhereUniqueInput>>;
  /** Update existing "MetadataStudyPhysician" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyPhysician" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataStudyphysicianUpdateInput>>;
  /** Upsert existing "MetadataStudyPhysician" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_StudyPhysician" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataStudyphysicianUpdateInput>>;
};

export type PhotoNestedMetadataSubjectcodeCreateInput = {
  /** Connect existing "MetadataSubjectCode" nodes to the new "Photo" node, through the "Photo.Metadata_SubjectCode" relation. */
  connect?: InputMaybe<Array<MetadataSubjectCodeWhereUniqueInput>>;
  /** Create new "MetadataSubjectCode" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_SubjectCode" relation. */
  create?: InputMaybe<Array<MetadataSubjectCodeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSubjectcodeUpdateInput = {
  /** Connect existing "MetadataSubjectCode" nodes to the existing "Photo" node, through the "Photo.Metadata_SubjectCode" relation. */
  connect?: InputMaybe<Array<MetadataSubjectCodeWhereUniqueInput>>;
  /** Create new "MetadataSubjectCode" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SubjectCode" relation. */
  create?: InputMaybe<Array<MetadataSubjectCodeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSubjectCode" nodes from the "Photo.Metadata_SubjectCode" relation. */
  delete?: InputMaybe<Array<MetadataSubjectCodeWhereUniqueInput>>;
  /** Update existing "MetadataSubjectCode" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SubjectCode" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSubjectcodeUpdateInput>>;
  /** Upsert existing "MetadataSubjectCode" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SubjectCode" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSubjectcodeUpdateInput>>;
};

export type PhotoNestedMetadataSubjectCreateInput = {
  /** Connect existing "MetadataSubject" nodes to the new "Photo" node, through the "Photo.Metadata_subject" relation. */
  connect?: InputMaybe<Array<MetadataSubjectWhereUniqueInput>>;
  /** Create new "MetadataSubject" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_subject" relation. */
  create?: InputMaybe<Array<MetadataSubjectWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSubjectprismaCreateInput = {
  /** Connect existing "MetadataSubjectPrisma" nodes to the new "Photo" node, through the "Photo.Metadata_SubjectPrisma" relation. */
  connect?: InputMaybe<Array<MetadataSubjectPrismaWhereUniqueInput>>;
  /** Create new "MetadataSubjectPrisma" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_SubjectPrisma" relation. */
  create?: InputMaybe<Array<MetadataSubjectPrismaWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSubjectprismaUpdateInput = {
  /** Connect existing "MetadataSubjectPrisma" nodes to the existing "Photo" node, through the "Photo.Metadata_SubjectPrisma" relation. */
  connect?: InputMaybe<Array<MetadataSubjectPrismaWhereUniqueInput>>;
  /** Create new "MetadataSubjectPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SubjectPrisma" relation. */
  create?: InputMaybe<Array<MetadataSubjectPrismaWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSubjectPrisma" nodes from the "Photo.Metadata_SubjectPrisma" relation. */
  delete?: InputMaybe<Array<MetadataSubjectPrismaWhereUniqueInput>>;
  /** Update existing "MetadataSubjectPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SubjectPrisma" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSubjectprismaUpdateInput>>;
  /** Upsert existing "MetadataSubjectPrisma" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SubjectPrisma" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSubjectprismaUpdateInput>>;
};

export type PhotoNestedMetadataSubjectUpdateInput = {
  /** Connect existing "MetadataSubject" nodes to the existing "Photo" node, through the "Photo.Metadata_subject" relation. */
  connect?: InputMaybe<Array<MetadataSubjectWhereUniqueInput>>;
  /** Create new "MetadataSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_subject" relation. */
  create?: InputMaybe<Array<MetadataSubjectWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSubject" nodes from the "Photo.Metadata_subject" relation. */
  delete?: InputMaybe<Array<MetadataSubjectWhereUniqueInput>>;
  /** Update existing "MetadataSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_subject" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSubjectUpdateInput>>;
  /** Upsert existing "MetadataSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_subject" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSubjectUpdateInput>>;
};

export type PhotoNestedMetadataSupplementalcategoriesCreateInput = {
  /** Connect existing "MetadataSupplementalCategories" nodes to the new "Photo" node, through the "Photo.Metadata_SupplementalCategories" relation. */
  connect?: InputMaybe<Array<MetadataSupplementalCategoriesWhereUniqueInput>>;
  /** Create new "MetadataSupplementalCategories" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_SupplementalCategories" relation. */
  create?: InputMaybe<Array<MetadataSupplementalCategoriesWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataSupplementalcategoriesUpdateInput = {
  /** Connect existing "MetadataSupplementalCategories" nodes to the existing "Photo" node, through the "Photo.Metadata_SupplementalCategories" relation. */
  connect?: InputMaybe<Array<MetadataSupplementalCategoriesWhereUniqueInput>>;
  /** Create new "MetadataSupplementalCategories" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SupplementalCategories" relation. */
  create?: InputMaybe<Array<MetadataSupplementalCategoriesWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataSupplementalCategories" nodes from the "Photo.Metadata_SupplementalCategories" relation. */
  delete?: InputMaybe<Array<MetadataSupplementalCategoriesWhereUniqueInput>>;
  /** Update existing "MetadataSupplementalCategories" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SupplementalCategories" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataSupplementalcategoriesUpdateInput>>;
  /** Upsert existing "MetadataSupplementalCategories" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_SupplementalCategories" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataSupplementalcategoriesUpdateInput>>;
};

export type PhotoNestedMetadataTargetversionCreateInput = {
  /** Connect existing "MetadataTargetVersion" nodes to the new "Photo" node, through the "Photo.Metadata_TargetVersion" relation. */
  connect?: InputMaybe<Array<MetadataTargetVersionWhereUniqueInput>>;
  /** Create new "MetadataTargetVersion" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_TargetVersion" relation. */
  create?: InputMaybe<Array<MetadataTargetVersionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataTargetversionUpdateInput = {
  /** Connect existing "MetadataTargetVersion" nodes to the existing "Photo" node, through the "Photo.Metadata_TargetVersion" relation. */
  connect?: InputMaybe<Array<MetadataTargetVersionWhereUniqueInput>>;
  /** Create new "MetadataTargetVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TargetVersion" relation. */
  create?: InputMaybe<Array<MetadataTargetVersionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataTargetVersion" nodes from the "Photo.Metadata_TargetVersion" relation. */
  delete?: InputMaybe<Array<MetadataTargetVersionWhereUniqueInput>>;
  /** Update existing "MetadataTargetVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TargetVersion" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataTargetversionUpdateInput>>;
  /** Upsert existing "MetadataTargetVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TargetVersion" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataTargetversionUpdateInput>>;
};

export type PhotoNestedMetadataTitleCreateInput = {
  /** Connect existing "MetadataTitle" nodes to the new "Photo" node, through the "Photo.Metadata_title" relation. */
  connect?: InputMaybe<Array<MetadataTitleWhereUniqueInput>>;
  /** Create new "MetadataTitle" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_title" relation. */
  create?: InputMaybe<Array<MetadataTitleWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataTitleUpdateInput = {
  /** Connect existing "MetadataTitle" nodes to the existing "Photo" node, through the "Photo.Metadata_title" relation. */
  connect?: InputMaybe<Array<MetadataTitleWhereUniqueInput>>;
  /** Create new "MetadataTitle" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_title" relation. */
  create?: InputMaybe<Array<MetadataTitleWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataTitle" nodes from the "Photo.Metadata_title" relation. */
  delete?: InputMaybe<Array<MetadataTitleWhereUniqueInput>>;
  /** Update existing "MetadataTitle" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_title" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataTitleUpdateInput>>;
  /** Upsert existing "MetadataTitle" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_title" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataTitleUpdateInput>>;
};

export type PhotoNestedMetadataTonecurveCreateInput = {
  /** Connect existing "MetadataToneCurve" nodes to the new "Photo" node, through the "Photo.Metadata_ToneCurve" relation. */
  connect?: InputMaybe<Array<MetadataToneCurveWhereUniqueInput>>;
  /** Create new "MetadataToneCurve" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_ToneCurve" relation. */
  create?: InputMaybe<Array<MetadataToneCurveWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataTonecurveUpdateInput = {
  /** Connect existing "MetadataToneCurve" nodes to the existing "Photo" node, through the "Photo.Metadata_ToneCurve" relation. */
  connect?: InputMaybe<Array<MetadataToneCurveWhereUniqueInput>>;
  /** Create new "MetadataToneCurve" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ToneCurve" relation. */
  create?: InputMaybe<Array<MetadataToneCurveWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataToneCurve" nodes from the "Photo.Metadata_ToneCurve" relation. */
  delete?: InputMaybe<Array<MetadataToneCurveWhereUniqueInput>>;
  /** Update existing "MetadataToneCurve" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ToneCurve" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataTonecurveUpdateInput>>;
  /** Upsert existing "MetadataToneCurve" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_ToneCurve" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataTonecurveUpdateInput>>;
};

export type PhotoNestedMetadataTransferredbyCreateInput = {
  /** Connect existing "MetadataTransferredBy" nodes to the new "Photo" node, through the "Photo.Metadata_TransferredBy" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByWhereUniqueInput>>;
  /** Create new "MetadataTransferredBy" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_TransferredBy" relation. */
  create?: InputMaybe<Array<MetadataTransferredByWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataTransferredbyemailCreateInput = {
  /** Connect existing "MetadataTransferredByEmail" nodes to the new "Photo" node, through the "Photo.Metadata_TransferredByEmail" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByEmailWhereUniqueInput>>;
  /** Create new "MetadataTransferredByEmail" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_TransferredByEmail" relation. */
  create?: InputMaybe<Array<MetadataTransferredByEmailWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataTransferredbyemailUpdateInput = {
  /** Connect existing "MetadataTransferredByEmail" nodes to the existing "Photo" node, through the "Photo.Metadata_TransferredByEmail" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByEmailWhereUniqueInput>>;
  /** Create new "MetadataTransferredByEmail" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransferredByEmail" relation. */
  create?: InputMaybe<Array<MetadataTransferredByEmailWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataTransferredByEmail" nodes from the "Photo.Metadata_TransferredByEmail" relation. */
  delete?: InputMaybe<Array<MetadataTransferredByEmailWhereUniqueInput>>;
  /** Update existing "MetadataTransferredByEmail" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransferredByEmail" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataTransferredbyemailUpdateInput>>;
  /** Upsert existing "MetadataTransferredByEmail" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransferredByEmail" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataTransferredbyemailUpdateInput>>;
};

export type PhotoNestedMetadataTransferredbyfullnameCreateInput = {
  /** Connect existing "MetadataTransferredByFullName" nodes to the new "Photo" node, through the "Photo.Metadata_TransferredByFullName" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByFullNameWhereUniqueInput>>;
  /** Create new "MetadataTransferredByFullName" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_TransferredByFullName" relation. */
  create?: InputMaybe<Array<MetadataTransferredByFullNameWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataTransferredbyfullnameUpdateInput = {
  /** Connect existing "MetadataTransferredByFullName" nodes to the existing "Photo" node, through the "Photo.Metadata_TransferredByFullName" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByFullNameWhereUniqueInput>>;
  /** Create new "MetadataTransferredByFullName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransferredByFullName" relation. */
  create?: InputMaybe<Array<MetadataTransferredByFullNameWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataTransferredByFullName" nodes from the "Photo.Metadata_TransferredByFullName" relation. */
  delete?: InputMaybe<Array<MetadataTransferredByFullNameWhereUniqueInput>>;
  /** Update existing "MetadataTransferredByFullName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransferredByFullName" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataTransferredbyfullnameUpdateInput>>;
  /** Upsert existing "MetadataTransferredByFullName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransferredByFullName" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataTransferredbyfullnameUpdateInput>>;
};

export type PhotoNestedMetadataTransferredbyUpdateInput = {
  /** Connect existing "MetadataTransferredBy" nodes to the existing "Photo" node, through the "Photo.Metadata_TransferredBy" relation. */
  connect?: InputMaybe<Array<MetadataTransferredByWhereUniqueInput>>;
  /** Create new "MetadataTransferredBy" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransferredBy" relation. */
  create?: InputMaybe<Array<MetadataTransferredByWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataTransferredBy" nodes from the "Photo.Metadata_TransferredBy" relation. */
  delete?: InputMaybe<Array<MetadataTransferredByWhereUniqueInput>>;
  /** Update existing "MetadataTransferredBy" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransferredBy" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataTransferredbyUpdateInput>>;
  /** Upsert existing "MetadataTransferredBy" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransferredBy" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataTransferredbyUpdateInput>>;
};

export type PhotoNestedMetadataTransmissionreferenceCreateInput = {
  /** Connect existing "MetadataTransmissionReference" nodes to the new "Photo" node, through the "Photo.Metadata_TransmissionReference" relation. */
  connect?: InputMaybe<Array<MetadataTransmissionReferenceWhereUniqueInput>>;
  /** Create new "MetadataTransmissionReference" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_TransmissionReference" relation. */
  create?: InputMaybe<Array<MetadataTransmissionReferenceWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataTransmissionreferenceUpdateInput = {
  /** Connect existing "MetadataTransmissionReference" nodes to the existing "Photo" node, through the "Photo.Metadata_TransmissionReference" relation. */
  connect?: InputMaybe<Array<MetadataTransmissionReferenceWhereUniqueInput>>;
  /** Create new "MetadataTransmissionReference" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransmissionReference" relation. */
  create?: InputMaybe<Array<MetadataTransmissionReferenceWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataTransmissionReference" nodes from the "Photo.Metadata_TransmissionReference" relation. */
  delete?: InputMaybe<Array<MetadataTransmissionReferenceWhereUniqueInput>>;
  /** Update existing "MetadataTransmissionReference" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransmissionReference" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataTransmissionreferenceUpdateInput>>;
  /** Upsert existing "MetadataTransmissionReference" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_TransmissionReference" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataTransmissionreferenceUpdateInput>>;
};

export type PhotoNestedMetadataTypeCreateInput = {
  /** Connect existing "MetadataType" nodes to the new "Photo" node, through the "Photo.Metadata_Type" relation. */
  connect?: InputMaybe<Array<MetadataTypeWhereUniqueInput>>;
  /** Create new "MetadataType" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Type" relation. */
  create?: InputMaybe<Array<MetadataTypeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataTypeUpdateInput = {
  /** Connect existing "MetadataType" nodes to the existing "Photo" node, through the "Photo.Metadata_Type" relation. */
  connect?: InputMaybe<Array<MetadataTypeWhereUniqueInput>>;
  /** Create new "MetadataType" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Type" relation. */
  create?: InputMaybe<Array<MetadataTypeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataType" nodes from the "Photo.Metadata_Type" relation. */
  delete?: InputMaybe<Array<MetadataTypeWhereUniqueInput>>;
  /** Update existing "MetadataType" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Type" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataTypeUpdateInput>>;
  /** Upsert existing "MetadataType" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Type" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataTypeUpdateInput>>;
};

export type PhotoNestedMetadataUndersubjectCreateInput = {
  /** Connect existing "MetadataUndersubject" nodes to the new "Photo" node, through the "Photo.Metadata_Undersubject" relation. */
  connect?: InputMaybe<Array<MetadataUndersubjectWhereUniqueInput>>;
  /** Create new "MetadataUndersubject" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Undersubject" relation. */
  create?: InputMaybe<Array<MetadataUndersubjectWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUndersubjectUpdateInput = {
  /** Connect existing "MetadataUndersubject" nodes to the existing "Photo" node, through the "Photo.Metadata_Undersubject" relation. */
  connect?: InputMaybe<Array<MetadataUndersubjectWhereUniqueInput>>;
  /** Create new "MetadataUndersubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Undersubject" relation. */
  create?: InputMaybe<Array<MetadataUndersubjectWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUndersubject" nodes from the "Photo.Metadata_Undersubject" relation. */
  delete?: InputMaybe<Array<MetadataUndersubjectWhereUniqueInput>>;
  /** Update existing "MetadataUndersubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Undersubject" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUndersubjectUpdateInput>>;
  /** Upsert existing "MetadataUndersubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Undersubject" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUndersubjectUpdateInput>>;
};

export type PhotoNestedMetadataUnderundersubjectCreateInput = {
  /** Connect existing "MetadataUnderUnderSubject" nodes to the new "Photo" node, through the "Photo.Metadata_UnderUnderSubject" relation. */
  connect?: InputMaybe<Array<MetadataUnderUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataUnderUnderSubject" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UnderUnderSubject" relation. */
  create?: InputMaybe<Array<MetadataUnderUnderSubjectWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUnderundersubjectUpdateInput = {
  /** Connect existing "MetadataUnderUnderSubject" nodes to the existing "Photo" node, through the "Photo.Metadata_UnderUnderSubject" relation. */
  connect?: InputMaybe<Array<MetadataUnderUnderSubjectWhereUniqueInput>>;
  /** Create new "MetadataUnderUnderSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UnderUnderSubject" relation. */
  create?: InputMaybe<Array<MetadataUnderUnderSubjectWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUnderUnderSubject" nodes from the "Photo.Metadata_UnderUnderSubject" relation. */
  delete?: InputMaybe<Array<MetadataUnderUnderSubjectWhereUniqueInput>>;
  /** Update existing "MetadataUnderUnderSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UnderUnderSubject" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUnderundersubjectUpdateInput>>;
  /** Upsert existing "MetadataUnderUnderSubject" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UnderUnderSubject" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUnderundersubjectUpdateInput>>;
};

export type PhotoNestedMetadataUniqueidCreateInput = {
  /** Connect existing "MetadataUniqueId" nodes to the new "Photo" node, through the "Photo.Metadata_UniqueId" relation. */
  connect?: InputMaybe<Array<MetadataUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataUniqueId" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UniqueId" relation. */
  create?: InputMaybe<Array<MetadataUniqueIdWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUniqueidUpdateInput = {
  /** Connect existing "MetadataUniqueId" nodes to the existing "Photo" node, through the "Photo.Metadata_UniqueId" relation. */
  connect?: InputMaybe<Array<MetadataUniqueIdWhereUniqueInput>>;
  /** Create new "MetadataUniqueId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UniqueId" relation. */
  create?: InputMaybe<Array<MetadataUniqueIdWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUniqueId" nodes from the "Photo.Metadata_UniqueId" relation. */
  delete?: InputMaybe<Array<MetadataUniqueIdWhereUniqueInput>>;
  /** Update existing "MetadataUniqueId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UniqueId" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUniqueidUpdateInput>>;
  /** Upsert existing "MetadataUniqueId" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UniqueId" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUniqueidUpdateInput>>;
};

export type PhotoNestedMetadataUnitCreateInput = {
  /** Connect existing "MetadataUnit" nodes to the new "Photo" node, through the "Photo.Metadata_Unit" relation. */
  connect?: InputMaybe<Array<MetadataUnitWhereUniqueInput>>;
  /** Create new "MetadataUnit" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Unit" relation. */
  create?: InputMaybe<Array<MetadataUnitWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUnitshortnameCreateInput = {
  /** Connect existing "MetadataUnitShortName" nodes to the new "Photo" node, through the "Photo.Metadata_UnitShortName" relation. */
  connect?: InputMaybe<Array<MetadataUnitShortNameWhereUniqueInput>>;
  /** Create new "MetadataUnitShortName" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UnitShortName" relation. */
  create?: InputMaybe<Array<MetadataUnitShortNameWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUnitshortnameUpdateInput = {
  /** Connect existing "MetadataUnitShortName" nodes to the existing "Photo" node, through the "Photo.Metadata_UnitShortName" relation. */
  connect?: InputMaybe<Array<MetadataUnitShortNameWhereUniqueInput>>;
  /** Create new "MetadataUnitShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UnitShortName" relation. */
  create?: InputMaybe<Array<MetadataUnitShortNameWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUnitShortName" nodes from the "Photo.Metadata_UnitShortName" relation. */
  delete?: InputMaybe<Array<MetadataUnitShortNameWhereUniqueInput>>;
  /** Update existing "MetadataUnitShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UnitShortName" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUnitshortnameUpdateInput>>;
  /** Upsert existing "MetadataUnitShortName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UnitShortName" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUnitshortnameUpdateInput>>;
};

export type PhotoNestedMetadataUnitUpdateInput = {
  /** Connect existing "MetadataUnit" nodes to the existing "Photo" node, through the "Photo.Metadata_Unit" relation. */
  connect?: InputMaybe<Array<MetadataUnitWhereUniqueInput>>;
  /** Create new "MetadataUnit" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Unit" relation. */
  create?: InputMaybe<Array<MetadataUnitWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUnit" nodes from the "Photo.Metadata_Unit" relation. */
  delete?: InputMaybe<Array<MetadataUnitWhereUniqueInput>>;
  /** Update existing "MetadataUnit" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Unit" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUnitUpdateInput>>;
  /** Upsert existing "MetadataUnit" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Unit" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUnitUpdateInput>>;
};

export type PhotoNestedMetadataUploadedbyCreateInput = {
  /** Connect existing "MetadataUploadedBy" nodes to the new "Photo" node, through the "Photo.Metadata_UploadedBy" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByWhereUniqueInput>>;
  /** Create new "MetadataUploadedBy" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UploadedBy" relation. */
  create?: InputMaybe<Array<MetadataUploadedByWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUploadedbyfullnameCreateInput = {
  /** Connect existing "MetadataUploadedByFullName" nodes to the new "Photo" node, through the "Photo.Metadata_UploadedByFullName" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByFullNameWhereUniqueInput>>;
  /** Create new "MetadataUploadedByFullName" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UploadedByFullName" relation. */
  create?: InputMaybe<Array<MetadataUploadedByFullNameWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUploadedbyfullnameUpdateInput = {
  /** Connect existing "MetadataUploadedByFullName" nodes to the existing "Photo" node, through the "Photo.Metadata_UploadedByFullName" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByFullNameWhereUniqueInput>>;
  /** Create new "MetadataUploadedByFullName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UploadedByFullName" relation. */
  create?: InputMaybe<Array<MetadataUploadedByFullNameWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUploadedByFullName" nodes from the "Photo.Metadata_UploadedByFullName" relation. */
  delete?: InputMaybe<Array<MetadataUploadedByFullNameWhereUniqueInput>>;
  /** Update existing "MetadataUploadedByFullName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UploadedByFullName" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUploadedbyfullnameUpdateInput>>;
  /** Upsert existing "MetadataUploadedByFullName" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UploadedByFullName" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUploadedbyfullnameUpdateInput>>;
};

export type PhotoNestedMetadataUploadedbyUpdateInput = {
  /** Connect existing "MetadataUploadedBy" nodes to the existing "Photo" node, through the "Photo.Metadata_UploadedBy" relation. */
  connect?: InputMaybe<Array<MetadataUploadedByWhereUniqueInput>>;
  /** Create new "MetadataUploadedBy" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UploadedBy" relation. */
  create?: InputMaybe<Array<MetadataUploadedByWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUploadedBy" nodes from the "Photo.Metadata_UploadedBy" relation. */
  delete?: InputMaybe<Array<MetadataUploadedByWhereUniqueInput>>;
  /** Update existing "MetadataUploadedBy" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UploadedBy" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUploadedbyUpdateInput>>;
  /** Upsert existing "MetadataUploadedBy" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UploadedBy" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUploadedbyUpdateInput>>;
};

export type PhotoNestedMetadataUploadtimeCreateInput = {
  /** Connect existing "MetadataUploadTime" nodes to the new "Photo" node, through the "Photo.Metadata_UploadTime" relation. */
  connect?: InputMaybe<Array<MetadataUploadTimeWhereUniqueInput>>;
  /** Create new "MetadataUploadTime" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UploadTime" relation. */
  create?: InputMaybe<Array<MetadataUploadTimeWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUploadtimeUpdateInput = {
  /** Connect existing "MetadataUploadTime" nodes to the existing "Photo" node, through the "Photo.Metadata_UploadTime" relation. */
  connect?: InputMaybe<Array<MetadataUploadTimeWhereUniqueInput>>;
  /** Create new "MetadataUploadTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UploadTime" relation. */
  create?: InputMaybe<Array<MetadataUploadTimeWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUploadTime" nodes from the "Photo.Metadata_UploadTime" relation. */
  delete?: InputMaybe<Array<MetadataUploadTimeWhereUniqueInput>>;
  /** Update existing "MetadataUploadTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UploadTime" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUploadtimeUpdateInput>>;
  /** Upsert existing "MetadataUploadTime" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UploadTime" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUploadtimeUpdateInput>>;
};

export type PhotoNestedMetadataUrgencyCreateInput = {
  /** Connect existing "MetadataUrgency" nodes to the new "Photo" node, through the "Photo.Metadata_Urgency" relation. */
  connect?: InputMaybe<Array<MetadataUrgencyWhereUniqueInput>>;
  /** Create new "MetadataUrgency" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Urgency" relation. */
  create?: InputMaybe<Array<MetadataUrgencyWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUrgencyUpdateInput = {
  /** Connect existing "MetadataUrgency" nodes to the existing "Photo" node, through the "Photo.Metadata_Urgency" relation. */
  connect?: InputMaybe<Array<MetadataUrgencyWhereUniqueInput>>;
  /** Create new "MetadataUrgency" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Urgency" relation. */
  create?: InputMaybe<Array<MetadataUrgencyWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUrgency" nodes from the "Photo.Metadata_Urgency" relation. */
  delete?: InputMaybe<Array<MetadataUrgencyWhereUniqueInput>>;
  /** Update existing "MetadataUrgency" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Urgency" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUrgencyUpdateInput>>;
  /** Upsert existing "MetadataUrgency" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Urgency" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUrgencyUpdateInput>>;
};

export type PhotoNestedMetadataUsagetermsCreateInput = {
  /** Connect existing "MetadataUsageTerms" nodes to the new "Photo" node, through the "Photo.Metadata_UsageTerms" relation. */
  connect?: InputMaybe<Array<MetadataUsageTermsWhereUniqueInput>>;
  /** Create new "MetadataUsageTerms" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UsageTerms" relation. */
  create?: InputMaybe<Array<MetadataUsageTermsWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUsagetermsUpdateInput = {
  /** Connect existing "MetadataUsageTerms" nodes to the existing "Photo" node, through the "Photo.Metadata_UsageTerms" relation. */
  connect?: InputMaybe<Array<MetadataUsageTermsWhereUniqueInput>>;
  /** Create new "MetadataUsageTerms" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UsageTerms" relation. */
  create?: InputMaybe<Array<MetadataUsageTermsWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUsageTerms" nodes from the "Photo.Metadata_UsageTerms" relation. */
  delete?: InputMaybe<Array<MetadataUsageTermsWhereUniqueInput>>;
  /** Update existing "MetadataUsageTerms" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UsageTerms" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUsagetermsUpdateInput>>;
  /** Upsert existing "MetadataUsageTerms" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UsageTerms" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUsagetermsUpdateInput>>;
};

export type PhotoNestedMetadataUsercommentCreateInput = {
  /** Connect existing "MetadataUserComment" nodes to the new "Photo" node, through the "Photo.Metadata_UserComment" relation. */
  connect?: InputMaybe<Array<MetadataUserCommentWhereUniqueInput>>;
  /** Create new "MetadataUserComment" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UserComment" relation. */
  create?: InputMaybe<Array<MetadataUserCommentWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUsercommentUpdateInput = {
  /** Connect existing "MetadataUserComment" nodes to the existing "Photo" node, through the "Photo.Metadata_UserComment" relation. */
  connect?: InputMaybe<Array<MetadataUserCommentWhereUniqueInput>>;
  /** Create new "MetadataUserComment" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserComment" relation. */
  create?: InputMaybe<Array<MetadataUserCommentWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUserComment" nodes from the "Photo.Metadata_UserComment" relation. */
  delete?: InputMaybe<Array<MetadataUserCommentWhereUniqueInput>>;
  /** Update existing "MetadataUserComment" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserComment" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUsercommentUpdateInput>>;
  /** Upsert existing "MetadataUserComment" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserComment" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUsercommentUpdateInput>>;
};

export type PhotoNestedMetadataUserdefined195CreateInput = {
  /** Connect existing "MetadataUserDefined195" nodes to the new "Photo" node, through the "Photo.Metadata_UserDefined195" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined195WhereUniqueInput>>;
  /** Create new "MetadataUserDefined195" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UserDefined195" relation. */
  create?: InputMaybe<Array<MetadataUserDefined195WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUserdefined195UpdateInput = {
  /** Connect existing "MetadataUserDefined195" nodes to the existing "Photo" node, through the "Photo.Metadata_UserDefined195" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined195WhereUniqueInput>>;
  /** Create new "MetadataUserDefined195" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined195" relation. */
  create?: InputMaybe<Array<MetadataUserDefined195WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUserDefined195" nodes from the "Photo.Metadata_UserDefined195" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined195WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined195" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined195" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUserdefined195UpdateInput>>;
  /** Upsert existing "MetadataUserDefined195" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined195" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUserdefined195UpdateInput>>;
};

export type PhotoNestedMetadataUserdefined237CreateInput = {
  /** Connect existing "MetadataUserDefined237" nodes to the new "Photo" node, through the "Photo.Metadata_UserDefined237" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined237WhereUniqueInput>>;
  /** Create new "MetadataUserDefined237" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UserDefined237" relation. */
  create?: InputMaybe<Array<MetadataUserDefined237WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUserdefined237UpdateInput = {
  /** Connect existing "MetadataUserDefined237" nodes to the existing "Photo" node, through the "Photo.Metadata_UserDefined237" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined237WhereUniqueInput>>;
  /** Create new "MetadataUserDefined237" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined237" relation. */
  create?: InputMaybe<Array<MetadataUserDefined237WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUserDefined237" nodes from the "Photo.Metadata_UserDefined237" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined237WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined237" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined237" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUserdefined237UpdateInput>>;
  /** Upsert existing "MetadataUserDefined237" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined237" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUserdefined237UpdateInput>>;
};

export type PhotoNestedMetadataUserdefined238CreateInput = {
  /** Connect existing "MetadataUserDefined238" nodes to the new "Photo" node, through the "Photo.Metadata_UserDefined238" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined238WhereUniqueInput>>;
  /** Create new "MetadataUserDefined238" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UserDefined238" relation. */
  create?: InputMaybe<Array<MetadataUserDefined238WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUserdefined238UpdateInput = {
  /** Connect existing "MetadataUserDefined238" nodes to the existing "Photo" node, through the "Photo.Metadata_UserDefined238" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined238WhereUniqueInput>>;
  /** Create new "MetadataUserDefined238" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined238" relation. */
  create?: InputMaybe<Array<MetadataUserDefined238WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUserDefined238" nodes from the "Photo.Metadata_UserDefined238" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined238WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined238" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined238" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUserdefined238UpdateInput>>;
  /** Upsert existing "MetadataUserDefined238" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined238" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUserdefined238UpdateInput>>;
};

export type PhotoNestedMetadataUserdefined239CreateInput = {
  /** Connect existing "MetadataUserDefined239" nodes to the new "Photo" node, through the "Photo.Metadata_UserDefined239" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined239WhereUniqueInput>>;
  /** Create new "MetadataUserDefined239" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UserDefined239" relation. */
  create?: InputMaybe<Array<MetadataUserDefined239WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUserdefined239UpdateInput = {
  /** Connect existing "MetadataUserDefined239" nodes to the existing "Photo" node, through the "Photo.Metadata_UserDefined239" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined239WhereUniqueInput>>;
  /** Create new "MetadataUserDefined239" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined239" relation. */
  create?: InputMaybe<Array<MetadataUserDefined239WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUserDefined239" nodes from the "Photo.Metadata_UserDefined239" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined239WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined239" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined239" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUserdefined239UpdateInput>>;
  /** Upsert existing "MetadataUserDefined239" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined239" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUserdefined239UpdateInput>>;
};

export type PhotoNestedMetadataUserdefined242CreateInput = {
  /** Connect existing "MetadataUserDefined242" nodes to the new "Photo" node, through the "Photo.Metadata_UserDefined242" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined242WhereUniqueInput>>;
  /** Create new "MetadataUserDefined242" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UserDefined242" relation. */
  create?: InputMaybe<Array<MetadataUserDefined242WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUserdefined242UpdateInput = {
  /** Connect existing "MetadataUserDefined242" nodes to the existing "Photo" node, through the "Photo.Metadata_UserDefined242" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined242WhereUniqueInput>>;
  /** Create new "MetadataUserDefined242" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined242" relation. */
  create?: InputMaybe<Array<MetadataUserDefined242WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUserDefined242" nodes from the "Photo.Metadata_UserDefined242" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined242WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined242" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined242" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUserdefined242UpdateInput>>;
  /** Upsert existing "MetadataUserDefined242" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined242" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUserdefined242UpdateInput>>;
};

export type PhotoNestedMetadataUserdefined62CreateInput = {
  /** Connect existing "MetadataUserDefined62" nodes to the new "Photo" node, through the "Photo.Metadata_UserDefined62" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined62WhereUniqueInput>>;
  /** Create new "MetadataUserDefined62" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_UserDefined62" relation. */
  create?: InputMaybe<Array<MetadataUserDefined62WithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataUserdefined62UpdateInput = {
  /** Connect existing "MetadataUserDefined62" nodes to the existing "Photo" node, through the "Photo.Metadata_UserDefined62" relation. */
  connect?: InputMaybe<Array<MetadataUserDefined62WhereUniqueInput>>;
  /** Create new "MetadataUserDefined62" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined62" relation. */
  create?: InputMaybe<Array<MetadataUserDefined62WithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataUserDefined62" nodes from the "Photo.Metadata_UserDefined62" relation. */
  delete?: InputMaybe<Array<MetadataUserDefined62WhereUniqueInput>>;
  /** Update existing "MetadataUserDefined62" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined62" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataUserdefined62UpdateInput>>;
  /** Upsert existing "MetadataUserDefined62" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_UserDefined62" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataUserdefined62UpdateInput>>;
};

export type PhotoNestedMetadataValidCreateInput = {
  /** Connect existing "MetadataValid" nodes to the new "Photo" node, through the "Photo.Metadata_Valid" relation. */
  connect?: InputMaybe<Array<MetadataValidWhereUniqueInput>>;
  /** Create new "MetadataValid" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Valid" relation. */
  create?: InputMaybe<Array<MetadataValidWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataValidUpdateInput = {
  /** Connect existing "MetadataValid" nodes to the existing "Photo" node, through the "Photo.Metadata_Valid" relation. */
  connect?: InputMaybe<Array<MetadataValidWhereUniqueInput>>;
  /** Create new "MetadataValid" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Valid" relation. */
  create?: InputMaybe<Array<MetadataValidWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataValid" nodes from the "Photo.Metadata_Valid" relation. */
  delete?: InputMaybe<Array<MetadataValidWhereUniqueInput>>;
  /** Update existing "MetadataValid" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Valid" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataValidUpdateInput>>;
  /** Upsert existing "MetadataValid" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Valid" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataValidUpdateInput>>;
};

export type PhotoNestedMetadataVersionCreateInput = {
  /** Connect existing "MetadataVersion" nodes to the new "Photo" node, through the "Photo.Metadata_Version" relation. */
  connect?: InputMaybe<Array<MetadataVersionWhereUniqueInput>>;
  /** Create new "MetadataVersion" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_Version" relation. */
  create?: InputMaybe<Array<MetadataVersionWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataVersionUpdateInput = {
  /** Connect existing "MetadataVersion" nodes to the existing "Photo" node, through the "Photo.Metadata_Version" relation. */
  connect?: InputMaybe<Array<MetadataVersionWhereUniqueInput>>;
  /** Create new "MetadataVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Version" relation. */
  create?: InputMaybe<Array<MetadataVersionWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataVersion" nodes from the "Photo.Metadata_Version" relation. */
  delete?: InputMaybe<Array<MetadataVersionWhereUniqueInput>>;
  /** Update existing "MetadataVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Version" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataVersionUpdateInput>>;
  /** Upsert existing "MetadataVersion" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_Version" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataVersionUpdateInput>>;
};

export type PhotoNestedMetadataWebstatementCreateInput = {
  /** Connect existing "MetadataWebStatement" nodes to the new "Photo" node, through the "Photo.Metadata_WebStatement" relation. */
  connect?: InputMaybe<Array<MetadataWebStatementWhereUniqueInput>>;
  /** Create new "MetadataWebStatement" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_WebStatement" relation. */
  create?: InputMaybe<Array<MetadataWebStatementWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataWebstatementUpdateInput = {
  /** Connect existing "MetadataWebStatement" nodes to the existing "Photo" node, through the "Photo.Metadata_WebStatement" relation. */
  connect?: InputMaybe<Array<MetadataWebStatementWhereUniqueInput>>;
  /** Create new "MetadataWebStatement" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_WebStatement" relation. */
  create?: InputMaybe<Array<MetadataWebStatementWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataWebStatement" nodes from the "Photo.Metadata_WebStatement" relation. */
  delete?: InputMaybe<Array<MetadataWebStatementWhereUniqueInput>>;
  /** Update existing "MetadataWebStatement" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_WebStatement" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataWebstatementUpdateInput>>;
  /** Upsert existing "MetadataWebStatement" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_WebStatement" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataWebstatementUpdateInput>>;
};

export type PhotoNestedMetadataWorkflowkindCreateInput = {
  /** Connect existing "MetadataWorkflowKind" nodes to the new "Photo" node, through the "Photo.Metadata_WorkflowKind" relation. */
  connect?: InputMaybe<Array<MetadataWorkflowKindWhereUniqueInput>>;
  /** Create new "MetadataWorkflowKind" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_WorkflowKind" relation. */
  create?: InputMaybe<Array<MetadataWorkflowKindWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataWorkflowkindUpdateInput = {
  /** Connect existing "MetadataWorkflowKind" nodes to the existing "Photo" node, through the "Photo.Metadata_WorkflowKind" relation. */
  connect?: InputMaybe<Array<MetadataWorkflowKindWhereUniqueInput>>;
  /** Create new "MetadataWorkflowKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_WorkflowKind" relation. */
  create?: InputMaybe<Array<MetadataWorkflowKindWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataWorkflowKind" nodes from the "Photo.Metadata_WorkflowKind" relation. */
  delete?: InputMaybe<Array<MetadataWorkflowKindWhereUniqueInput>>;
  /** Update existing "MetadataWorkflowKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_WorkflowKind" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataWorkflowkindUpdateInput>>;
  /** Upsert existing "MetadataWorkflowKind" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_WorkflowKind" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataWorkflowkindUpdateInput>>;
};

export type PhotoNestedMetadataXmpfilestampsCreateInput = {
  /** Connect existing "MetadataXmpFileStamps" nodes to the new "Photo" node, through the "Photo.Metadata_XMPFileStamps" relation. */
  connect?: InputMaybe<Array<MetadataXmpFileStampsWhereUniqueInput>>;
  /** Create new "MetadataXmpFileStamps" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_XMPFileStamps" relation. */
  create?: InputMaybe<Array<MetadataXmpFileStampsWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataXmpfilestampsUpdateInput = {
  /** Connect existing "MetadataXmpFileStamps" nodes to the existing "Photo" node, through the "Photo.Metadata_XMPFileStamps" relation. */
  connect?: InputMaybe<Array<MetadataXmpFileStampsWhereUniqueInput>>;
  /** Create new "MetadataXmpFileStamps" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_XMPFileStamps" relation. */
  create?: InputMaybe<Array<MetadataXmpFileStampsWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataXmpFileStamps" nodes from the "Photo.Metadata_XMPFileStamps" relation. */
  delete?: InputMaybe<Array<MetadataXmpFileStampsWhereUniqueInput>>;
  /** Update existing "MetadataXmpFileStamps" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_XMPFileStamps" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataXmpfilestampsUpdateInput>>;
  /** Upsert existing "MetadataXmpFileStamps" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_XMPFileStamps" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataXmpfilestampsUpdateInput>>;
};

export type PhotoNestedMetadataXmphistoryCreateInput = {
  /** Connect existing "MetadataXmpHistory" nodes to the new "Photo" node, through the "Photo.Metadata_XMPHistory" relation. */
  connect?: InputMaybe<Array<MetadataXmpHistoryWhereUniqueInput>>;
  /** Create new "MetadataXmpHistory" nodes and connect them to the new "Photo" node, through the "Photo.Metadata_XMPHistory" relation. */
  create?: InputMaybe<Array<MetadataXmpHistoryWithForcedPhotoCreateInput>>;
};

export type PhotoNestedMetadataXmphistoryUpdateInput = {
  /** Connect existing "MetadataXmpHistory" nodes to the existing "Photo" node, through the "Photo.Metadata_XMPHistory" relation. */
  connect?: InputMaybe<Array<MetadataXmpHistoryWhereUniqueInput>>;
  /** Create new "MetadataXmpHistory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_XMPHistory" relation. */
  create?: InputMaybe<Array<MetadataXmpHistoryWithForcedPhotoCreateInput>>;
  /** Delete existing "MetadataXmpHistory" nodes from the "Photo.Metadata_XMPHistory" relation. */
  delete?: InputMaybe<Array<MetadataXmpHistoryWhereUniqueInput>>;
  /** Update existing "MetadataXmpHistory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_XMPHistory" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdateMetadataXmphistoryUpdateInput>>;
  /** Upsert existing "MetadataXmpHistory" nodes and connect them to the existing "Photo" node, through the "Photo.Metadata_XMPHistory" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertMetadataXmphistoryUpdateInput>>;
};

export type PhotoNestedPhotoMetadatasCreateInput = {
  /** Create new "PhotoMetadata" nodes and connect them to the new "Photo" node, through the "Photo.photoMetadatas" relation. */
  create?: InputMaybe<Array<PhotoMetadataWithForcedPhotoCreateInput>>;
};

export type PhotoNestedPhotoMetadatasUpdateInput = {
  /** Create new "PhotoMetadata" nodes and connect them to the existing "Photo" node, through the "Photo.photoMetadatas" relation. */
  create?: InputMaybe<Array<PhotoMetadataWithForcedPhotoCreateInput>>;
  /** Delete existing "PhotoMetadata" nodes from the "Photo.photoMetadatas" relation. */
  delete?: InputMaybe<Array<PhotoMetadataWhereUniqueInput>>;
  /** Update existing "PhotoMetadata" nodes and connect them to the existing "Photo" node, through the "Photo.photoMetadatas" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdatePhotoMetadatasUpdateInput>>;
  /** Upsert existing "PhotoMetadata" nodes and connect them to the existing "Photo" node, through the "Photo.photoMetadatas" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertPhotoMetadatasUpdateInput>>;
};

export type PhotoNestedPhotoPublicationsCreateInput = {
  /** Connect existing "PhotoPublication" nodes to the new "Photo" node, through the "Photo.photoPublications" relation. */
  connect?: InputMaybe<Array<PhotoPublicationWhereUniqueInput>>;
  /** Create new "PhotoPublication" nodes and connect them to the new "Photo" node, through the "Photo.photoPublications" relation. */
  create?: InputMaybe<Array<PhotoPublicationWithForcedPhotoCreateInput>>;
};

export type PhotoNestedPhotoPublicationsUpdateInput = {
  /** Connect existing "PhotoPublication" nodes to the existing "Photo" node, through the "Photo.photoPublications" relation. */
  connect?: InputMaybe<Array<PhotoPublicationWhereUniqueInput>>;
  /** Create new "PhotoPublication" nodes and connect them to the existing "Photo" node, through the "Photo.photoPublications" relation. */
  create?: InputMaybe<Array<PhotoPublicationWithForcedPhotoCreateInput>>;
  /** Delete existing "PhotoPublication" nodes from the "Photo.photoPublications" relation. */
  delete?: InputMaybe<Array<PhotoPublicationWhereUniqueInput>>;
  /** Update existing "PhotoPublication" nodes and connect them to the existing "Photo" node, through the "Photo.photoPublications" relation. */
  update?: InputMaybe<Array<PhotoNestedUpdatePhotoPublicationsUpdateInput>>;
  /** Upsert existing "PhotoPublication" nodes and connect them to the existing "Photo" node, through the "Photo.photoPublications" relation. */
  upsert?: InputMaybe<Array<PhotoNestedUpsertPhotoPublicationsUpdateInput>>;
};

export type PhotoNestedUpdateAgencyCreateInput = {
  data: AgencyUpdateInput;
  where: AgencyWhereUniqueInput;
};

export type PhotoNestedUpdateAgencyUpdateInput = {
  data: AgencyUpdateInput;
  where: AgencyWhereUniqueInput;
};

export type PhotoNestedUpdateArchiveCreateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type PhotoNestedUpdateArchiveUpdateInput = {
  data: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type PhotoNestedUpdateCategoryCreateInput = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type PhotoNestedUpdateCategoryUpdateInput = {
  data: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type PhotoNestedUpdateDeliverySubjectCreateInput = {
  data: DeliverySubjectUpdateInput;
  where: DeliverySubjectWhereUniqueInput;
};

export type PhotoNestedUpdateDeliverySubjectUpdateInput = {
  data: DeliverySubjectUpdateInput;
  where: DeliverySubjectWhereUniqueInput;
};

export type PhotoNestedUpdateImportQueueUpdateInput = {
  data: ImportQueueWithForcedPhotoUpdateInput;
  where: ImportQueueWithOptionalPhotoWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataAccessrightsUpdateInput = {
  data: MetadataAccessRightsWithForcedPhotoUpdateInput;
  where: MetadataAccessRightsWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataAddlmodelinfoUpdateInput = {
  data: MetadataAddlModelInfoWithForcedPhotoUpdateInput;
  where: MetadataAddlModelInfoWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataArchiveChildUpdateInput = {
  data: MetadataArchiveChildWithForcedPhotoUpdateInput;
  where: MetadataArchiveChildWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataArchiveParentUpdateInput = {
  data: MetadataArchiveParentWithForcedPhotoUpdateInput;
  where: MetadataArchiveParentWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataAssetstateUpdateInput = {
  data: MetadataAssetStateWithForcedPhotoUpdateInput;
  where: MetadataAssetStateWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataAttributionnameUpdateInput = {
  data: MetadataAttributionNameWithForcedPhotoUpdateInput;
  where: MetadataAttributionNameWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataAttributionurlUpdateInput = {
  data: MetadataAttributionUrlWithForcedPhotoUpdateInput;
  where: MetadataAttributionUrlWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataAudienceUpdateInput = {
  data: MetadataAudienceWithForcedPhotoUpdateInput;
  where: MetadataAudienceWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataAuthorspositionUpdateInput = {
  data: MetadataAuthorsPositionWithForcedPhotoUpdateInput;
  where: MetadataAuthorsPositionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataBackupnameUpdateInput = {
  data: MetadataBackupNameWithForcedPhotoUpdateInput;
  where: MetadataBackupNameWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataBitmapgrayscalepictureUpdateInput = {
  data: MetadataBitmapGrayscalePictureWithForcedPhotoUpdateInput;
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataBrandprismaUpdateInput = {
  data: MetadataBrandPrismaWithForcedPhotoUpdateInput;
  where: MetadataBrandPrismaWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataBrandshortnameUpdateInput = {
  data: MetadataBrandShortNameWithForcedPhotoUpdateInput;
  where: MetadataBrandShortNameWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataBrandUpdateInput = {
  data: MetadataBrandWithForcedPhotoUpdateInput;
  where: MetadataBrandWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCaptionwriterUpdateInput = {
  data: MetadataCaptionWriterWithForcedPhotoUpdateInput;
  where: MetadataCaptionWriterWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCategoryprismaUpdateInput = {
  data: MetadataCategoryPrismaWithForcedPhotoUpdateInput;
  where: MetadataCategoryPrismaWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCategoryUpdateInput = {
  data: MetadataCategoryWithForcedPhotoUpdateInput;
  where: MetadataCategoryWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCertificateUpdateInput = {
  data: MetadataCertificateWithForcedPhotoUpdateInput;
  where: MetadataCertificateWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCiadrcityUpdateInput = {
  data: MetadataCiAdrCityWithForcedPhotoUpdateInput;
  where: MetadataCiAdrCityWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCiadrctryUpdateInput = {
  data: MetadataCiAdrCtryWithForcedPhotoUpdateInput;
  where: MetadataCiAdrCtryWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCiadrextadrUpdateInput = {
  data: MetadataCiAdrExtadrWithForcedPhotoUpdateInput;
  where: MetadataCiAdrExtadrWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCiadrpcodeUpdateInput = {
  data: MetadataCiAdrPcodeWithForcedPhotoUpdateInput;
  where: MetadataCiAdrPcodeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCiadrregionUpdateInput = {
  data: MetadataCiAdrRegionWithForcedPhotoUpdateInput;
  where: MetadataCiAdrRegionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCiemailworkUpdateInput = {
  data: MetadataCiEmailWorkWithForcedPhotoUpdateInput;
  where: MetadataCiEmailWorkWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCitelworkUpdateInput = {
  data: MetadataCiTelWorkWithForcedPhotoUpdateInput;
  where: MetadataCiTelWorkWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCityUpdateInput = {
  data: MetadataCityWithForcedPhotoUpdateInput;
  where: MetadataCityWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCiurlworkUpdateInput = {
  data: MetadataCiUrlWorkWithForcedPhotoUpdateInput;
  where: MetadataCiUrlWorkWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataClassifyUpdateInput = {
  data: MetadataClassifyWithForcedPhotoUpdateInput;
  where: MetadataClassifyWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCompanyshortnameUpdateInput = {
  data: MetadataCompanyShortNameWithForcedPhotoUpdateInput;
  where: MetadataCompanyShortNameWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCompanyUpdateInput = {
  data: MetadataCompanyWithForcedPhotoUpdateInput;
  where: MetadataCompanyWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataConfidentialityUpdateInput = {
  data: MetadataConfidentialityWithForcedPhotoUpdateInput;
  where: MetadataConfidentialityWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataContainedinUpdateInput = {
  data: MetadataContainedInWithForcedPhotoUpdateInput;
  where: MetadataContainedInWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataContentmediakindUpdateInput = {
  data: MetadataContentMediaKindWithForcedPhotoUpdateInput;
  where: MetadataContentMediaKindWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataContentvalueUpdateInput = {
  data: MetadataContentValueWithForcedPhotoUpdateInput;
  where: MetadataContentValueWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCopyrightlayerUpdateInput = {
  data: MetadataCopyrightLayerWithForcedPhotoUpdateInput;
  where: MetadataCopyrightLayerWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCountrycodeUpdateInput = {
  data: MetadataCountryCodeWithForcedPhotoUpdateInput;
  where: MetadataCountryCodeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCountryUpdateInput = {
  data: MetadataCountryWithForcedPhotoUpdateInput;
  where: MetadataCountryWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCreatedtimeUpdateInput = {
  data: MetadataCreatedTimeWithForcedPhotoUpdateInput;
  where: MetadataCreatedTimeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCreatortoolUpdateInput = {
  data: MetadataCreatorToolWithForcedPhotoUpdateInput;
  where: MetadataCreatorToolWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCreatorUpdateInput = {
  data: MetadataCreatorWithForcedPhotoUpdateInput;
  where: MetadataCreatorWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCreditUpdateInput = {
  data: MetadataCreditWithForcedPhotoUpdateInput;
  where: MetadataCreditWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCustomfield14UpdateInput = {
  data: MetadataCustomField14WithForcedPhotoUpdateInput;
  where: MetadataCustomField14WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCustomfield15UpdateInput = {
  data: MetadataCustomField15WithForcedPhotoUpdateInput;
  where: MetadataCustomField15WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCustomfield16UpdateInput = {
  data: MetadataCustomField16WithForcedPhotoUpdateInput;
  where: MetadataCustomField16WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCustomfield17UpdateInput = {
  data: MetadataCustomField17WithForcedPhotoUpdateInput;
  where: MetadataCustomField17WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCustomfield20UpdateInput = {
  data: MetadataCustomField20WithForcedPhotoUpdateInput;
  where: MetadataCustomField20WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCustomfield5UpdateInput = {
  data: MetadataCustomField5WithForcedPhotoUpdateInput;
  where: MetadataCustomField5WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCustomfield6UpdateInput = {
  data: MetadataCustomField6WithForcedPhotoUpdateInput;
  where: MetadataCustomField6WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCustomfield7UpdateInput = {
  data: MetadataCustomField7WithForcedPhotoUpdateInput;
  where: MetadataCustomField7WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataCustomfield9UpdateInput = {
  data: MetadataCustomField9WithForcedPhotoUpdateInput;
  where: MetadataCustomField9WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDatecreatedUpdateInput = {
  data: MetadataDateCreatedWithForcedPhotoUpdateInput;
  where: MetadataDateCreatedWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDatetimedigitizedUpdateInput = {
  data: MetadataDateTimeDigitizedWithForcedPhotoUpdateInput;
  where: MetadataDateTimeDigitizedWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDatetimeoriginalUpdateInput = {
  data: MetadataDateTimeOriginalWithForcedPhotoUpdateInput;
  where: MetadataDateTimeOriginalWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliveryaccountUpdateInput = {
  data: MetadataDeliveryAccountWithForcedPhotoUpdateInput;
  where: MetadataDeliveryAccountWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliverycompanyUpdateInput = {
  data: MetadataDeliveryCompanyWithForcedPhotoUpdateInput;
  where: MetadataDeliveryCompanyWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliverycopyrightUpdateInput = {
  data: MetadataDeliveryCopyrightWithForcedPhotoUpdateInput;
  where: MetadataDeliveryCopyrightWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliverydatefolderUpdateInput = {
  data: MetadataDeliveryDateFolderWithForcedPhotoUpdateInput;
  where: MetadataDeliveryDateFolderWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliverydatetimeUpdateInput = {
  data: MetadataDeliveryDateTimeWithForcedPhotoUpdateInput;
  where: MetadataDeliveryDateTimeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliverydccreatorUpdateInput = {
  data: MetadataDeliveryDcCreatorWithForcedPhotoUpdateInput;
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliverydcrightsUpdateInput = {
  data: MetadataDeliveryDcRightsWithForcedPhotoUpdateInput;
  where: MetadataDeliveryDcRightsWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliveryfiletypeUpdateInput = {
  data: MetadataDeliveryFileTypeWithForcedPhotoUpdateInput;
  where: MetadataDeliveryFileTypeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliveryfolderUpdateInput = {
  data: MetadataDeliveryFolderWithForcedPhotoUpdateInput;
  where: MetadataDeliveryFolderWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliverykindUpdateInput = {
  data: MetadataDeliveryKindWithForcedPhotoUpdateInput;
  where: MetadataDeliveryKindWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliverypathUpdateInput = {
  data: MetadataDeliveryPathWithForcedPhotoUpdateInput;
  where: MetadataDeliveryPathWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliverypersonshownintheimageUpdateInput = {
  data: MetadataDeliveryPersonShownintheImageWithForcedPhotoUpdateInput;
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliveryphotoshopcreditUpdateInput = {
  data: MetadataDeliveryPhotoshopCreditWithForcedPhotoUpdateInput;
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliveryphotoshopsourceUpdateInput = {
  data: MetadataDeliveryPhotoshopSourceWithForcedPhotoUpdateInput;
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliveryserviceUpdateInput = {
  data: MetadataDeliveryServiceWithForcedPhotoUpdateInput;
  where: MetadataDeliveryServiceWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliverysubjectUpdateInput = {
  data: MetadataDeliverySubjectWithForcedPhotoUpdateInput;
  where: MetadataDeliverySubjectWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDeliveryundersubjectUpdateInput = {
  data: MetadataDeliveryUnderSubjectWithForcedPhotoUpdateInput;
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDepartmentUpdateInput = {
  data: MetadataDepartmentWithForcedPhotoUpdateInput;
  where: MetadataDepartmentWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDescriptionUpdateInput = {
  data: MetadataDescriptionWithForcedPhotoUpdateInput;
  where: MetadataDescriptionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDigitalasseturlUpdateInput = {
  data: MetadataDigitalAssetUrlWithForcedPhotoUpdateInput;
  where: MetadataDigitalAssetUrlWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDivisionUpdateInput = {
  data: MetadataDivisionWithForcedPhotoUpdateInput;
  where: MetadataDivisionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataDocumenttextUpdateInput = {
  data: MetadataDocumentTextWithForcedPhotoUpdateInput;
  where: MetadataDocumentTextWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataEditionUpdateInput = {
  data: MetadataEditionWithForcedPhotoUpdateInput;
  where: MetadataEditionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataEditorialversionUpdateInput = {
  data: MetadataEditorialVersionWithForcedPhotoUpdateInput;
  where: MetadataEditorialVersionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataEditstatusUpdateInput = {
  data: MetadataEditStatusWithForcedPhotoUpdateInput;
  where: MetadataEditStatusWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataEnvironnementphotoUpdateInput = {
  data: MetadataEnvironnementPhotoWithForcedPhotoUpdateInput;
  where: MetadataEnvironnementPhotoWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataEquipmentinstitutionUpdateInput = {
  data: MetadataEquipmentInstitutionWithForcedPhotoUpdateInput;
  where: MetadataEquipmentInstitutionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataEquipmentmanufacturerUpdateInput = {
  data: MetadataEquipmentManufacturerWithForcedPhotoUpdateInput;
  where: MetadataEquipmentManufacturerWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataEventUpdateInput = {
  data: MetadataEventWithForcedPhotoUpdateInput;
  where: MetadataEventWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataExifUpdateInput = {
  data: MetadataExifWithForcedPhotoUpdateInput;
  where: MetadataExifWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataFabstorageUpdateInput = {
  data: MetadataFabStorageWithForcedPhotoUpdateInput;
  where: MetadataFabStorageWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataFilecheckforUpdateInput = {
  data: MetadataFileCheckForWithForcedPhotoUpdateInput;
  where: MetadataFileCheckForWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataFileinfobitsperpixelUpdateInput = {
  data: MetadataFileInfoBitsPerPixelWithForcedPhotoUpdateInput;
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataFileinfoframesUpdateInput = {
  data: MetadataFileInfoFramesWithForcedPhotoUpdateInput;
  where: MetadataFileInfoFramesWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataFileinfophotometricinterpretationUpdateInput = {
  data: MetadataFileInfoPhotometricInterpretationWithForcedPhotoUpdateInput;
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataFileinfotransfersyntaxUpdateInput = {
  data: MetadataFileInfoTransferSyntaxWithForcedPhotoUpdateInput;
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataFilestageUpdateInput = {
  data: MetadataFileStageWithForcedPhotoUpdateInput;
  where: MetadataFileStageWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataFilestatusUpdateInput = {
  data: MetadataFileStatusWithForcedPhotoUpdateInput;
  where: MetadataFileStatusWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataFiletypeUpdateInput = {
  data: MetadataFileTypeWithForcedPhotoUpdateInput;
  where: MetadataFileTypeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataFixtureidentifierUpdateInput = {
  data: MetadataFixtureIdentifierWithForcedPhotoUpdateInput;
  where: MetadataFixtureIdentifierWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataFormatUpdateInput = {
  data: MetadataFormatWithForcedPhotoUpdateInput;
  where: MetadataFormatWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataGpslatitudeUpdateInput = {
  data: MetadataGpsLatitudeWithForcedPhotoUpdateInput;
  where: MetadataGpsLatitudeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataGpslongitudeUpdateInput = {
  data: MetadataGpsLongitudeWithForcedPhotoUpdateInput;
  where: MetadataGpsLongitudeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataGpsprocessingmethodUpdateInput = {
  data: MetadataGpsProcessingMethodWithForcedPhotoUpdateInput;
  where: MetadataGpsProcessingMethodWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataHeaderprismaUpdateInput = {
  data: MetadataHeaderPrismaWithForcedPhotoUpdateInput;
  where: MetadataHeaderPrismaWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataHeaderUpdateInput = {
  data: MetadataHeaderWithForcedPhotoUpdateInput;
  where: MetadataHeaderWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataHeadlineUpdateInput = {
  data: MetadataHeadlineWithForcedPhotoUpdateInput;
  where: MetadataHeadlineWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataHistoryUpdateInput = {
  data: MetadataHistoryWithForcedPhotoUpdateInput;
  where: MetadataHistoryWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataIccprofileUpdateInput = {
  data: MetadataIccProfileWithForcedPhotoUpdateInput;
  where: MetadataIccProfileWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataIdassignmentUpdateInput = {
  data: MetadataIdAssignmentWithForcedPhotoUpdateInput;
  where: MetadataIdAssignmentWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataIdmediacontentUpdateInput = {
  data: MetadataIdMediaContentWithForcedPhotoUpdateInput;
  where: MetadataIdMediaContentWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataIdphotoprismaUpdateInput = {
  data: MetadataIdPhotoPrismaWithForcedPhotoUpdateInput;
  where: MetadataIdPhotoPrismaWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataIdSubjectUpdateInput = {
  data: MetadataIdSubjectWithForcedPhotoUpdateInput;
  where: MetadataIdSubjectWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataImagenotesUpdateInput = {
  data: MetadataImageNotesWithForcedPhotoUpdateInput;
  where: MetadataImageNotesWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataImageuniqueidUpdateInput = {
  data: MetadataImageUniqueIdWithForcedPhotoUpdateInput;
  where: MetadataImageUniqueIdWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataInstructionsUpdateInput = {
  data: MetadataInstructionsWithForcedPhotoUpdateInput;
  where: MetadataInstructionsWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataIntellectualgenreUpdateInput = {
  data: MetadataIntellectualGenreWithForcedPhotoUpdateInput;
  where: MetadataIntellectualGenreWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataIsospeedratingsUpdateInput = {
  data: MetadataIsoSpeedRatingsWithForcedPhotoUpdateInput;
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataIssueUpdateInput = {
  data: MetadataIssueWithForcedPhotoUpdateInput;
  where: MetadataIssueWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataJobidUpdateInput = {
  data: MetadataJobIdWithForcedPhotoUpdateInput;
  where: MetadataJobIdWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataJobprocessingUpdateInput = {
  data: MetadataJobProcessingWithForcedPhotoUpdateInput;
  where: MetadataJobProcessingWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataLayoutpictureeditingUpdateInput = {
  data: MetadataLayoutPictureEditingWithForcedPhotoUpdateInput;
  where: MetadataLayoutPictureEditingWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataLayoutstorageUpdateInput = {
  data: MetadataLayoutStorageWithForcedPhotoUpdateInput;
  where: MetadataLayoutStorageWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataLicenseUpdateInput = {
  data: MetadataLicenseWithForcedPhotoUpdateInput;
  where: MetadataLicenseWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataLinksUpdateInput = {
  data: MetadataLinksWithForcedPhotoUpdateInput;
  where: MetadataLinksWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataLocalcaptionUpdateInput = {
  data: MetadataLocalCaptionWithForcedPhotoUpdateInput;
  where: MetadataLocalCaptionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataLocationUpdateInput = {
  data: MetadataLocationWithForcedPhotoUpdateInput;
  where: MetadataLocationWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataMakeUpdateInput = {
  data: MetadataMakeWithForcedPhotoUpdateInput;
  where: MetadataMakeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataManifestUpdateInput = {
  data: MetadataManifestWithForcedPhotoUpdateInput;
  where: MetadataManifestWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataMarkedUpdateInput = {
  data: MetadataMarkedWithForcedPhotoUpdateInput;
  where: MetadataMarkedWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataMasterdocumentidUpdateInput = {
  data: MetadataMasterDocumentIdWithForcedPhotoUpdateInput;
  where: MetadataMasterDocumentIdWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataMaxavailheightUpdateInput = {
  data: MetadataMaxAvailHeightWithForcedPhotoUpdateInput;
  where: MetadataMaxAvailHeightWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataMaxavailwidthUpdateInput = {
  data: MetadataMaxAvailWidthWithForcedPhotoUpdateInput;
  where: MetadataMaxAvailWidthWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataMinormodelagedisclosureUpdateInput = {
  data: MetadataMinorModelAgeDisclosureWithForcedPhotoUpdateInput;
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataModelageUpdateInput = {
  data: MetadataModelAgeWithForcedPhotoUpdateInput;
  where: MetadataModelAgeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataModelreleaseidUpdateInput = {
  data: MetadataModelReleaseIdWithForcedPhotoUpdateInput;
  where: MetadataModelReleaseIdWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataModelreleasestatusUpdateInput = {
  data: MetadataModelReleaseStatusWithForcedPhotoUpdateInput;
  where: MetadataModelReleaseStatusWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataModelUpdateInput = {
  data: MetadataModelWithForcedPhotoUpdateInput;
  where: MetadataModelWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataMorepermissionsUpdateInput = {
  data: MetadataMorePermissionsWithForcedPhotoUpdateInput;
  where: MetadataMorePermissionsWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataObjectcycleUpdateInput = {
  data: MetadataObjectCycleWithForcedPhotoUpdateInput;
  where: MetadataObjectCycleWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataOrganisationinimagenameUpdateInput = {
  data: MetadataOrganisationInImageNameWithForcedPhotoUpdateInput;
  where: MetadataOrganisationInImageNameWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataOriginalcolorUpdateInput = {
  data: MetadataOriginalColorWithForcedPhotoUpdateInput;
  where: MetadataOriginalColorWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataOriginaliccprofileUpdateInput = {
  data: MetadataOriginalIccProfileWithForcedPhotoUpdateInput;
  where: MetadataOriginalIccProfileWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataOriginalnameUpdateInput = {
  data: MetadataOriginalNameWithForcedPhotoUpdateInput;
  where: MetadataOriginalNameWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataOriginalweigthUpdateInput = {
  data: MetadataOriginalWeigthWithForcedPhotoUpdateInput;
  where: MetadataOriginalWeigthWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataOwneridUpdateInput = {
  data: MetadataOwnerIdWithForcedPhotoUpdateInput;
  where: MetadataOwnerIdWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataOwnerUpdateInput = {
  data: MetadataOwnerWithForcedPhotoUpdateInput;
  where: MetadataOwnerWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPageprismaUpdateInput = {
  data: MetadataPagePrismaWithForcedPhotoUpdateInput;
  where: MetadataPagePrismaWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPageUpdateInput = {
  data: MetadataPageWithForcedPhotoUpdateInput;
  where: MetadataPageWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPaintbasedcorrectionsUpdateInput = {
  data: MetadataPaintBasedCorrectionsWithForcedPhotoUpdateInput;
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPatientdobUpdateInput = {
  data: MetadataPatientDobWithForcedPhotoUpdateInput;
  where: MetadataPatientDobWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPatientidUpdateInput = {
  data: MetadataPatientIdWithForcedPhotoUpdateInput;
  where: MetadataPatientIdWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPatientnameUpdateInput = {
  data: MetadataPatientNameWithForcedPhotoUpdateInput;
  where: MetadataPatientNameWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPatientsexUpdateInput = {
  data: MetadataPatientSexWithForcedPhotoUpdateInput;
  where: MetadataPatientSexWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPersoninimageUpdateInput = {
  data: MetadataPersonInImageWithForcedPhotoUpdateInput;
  where: MetadataPersonInImageWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPhotostorageUpdateInput = {
  data: MetadataPhotoStorageWithForcedPhotoUpdateInput;
  where: MetadataPhotoStorageWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPrepresspictureeditingUpdateInput = {
  data: MetadataPrepressPictureEditingWithForcedPhotoUpdateInput;
  where: MetadataPrepressPictureEditingWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPricelevelUpdateInput = {
  data: MetadataPriceLevelWithForcedPhotoUpdateInput;
  where: MetadataPriceLevelWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPrintingprofileUpdateInput = {
  data: MetadataPrintingProfileWithForcedPhotoUpdateInput;
  where: MetadataPrintingProfileWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPrismaproductionUpdateInput = {
  data: MetadataPrismaProductionWithForcedPhotoUpdateInput;
  where: MetadataPrismaProductionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataProcesshistoryUpdateInput = {
  data: MetadataProcessHistoryWithForcedPhotoUpdateInput;
  where: MetadataProcessHistoryWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataProcessparameterUpdateInput = {
  data: MetadataProcessParameterWithForcedPhotoUpdateInput;
  where: MetadataProcessParameterWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataProcessstatusUpdateInput = {
  data: MetadataProcessStatusWithForcedPhotoUpdateInput;
  where: MetadataProcessStatusWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataProductshortnameUpdateInput = {
  data: MetadataProductShortNameWithForcedPhotoUpdateInput;
  where: MetadataProductShortNameWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataProductsUpdateInput = {
  data: MetadataProductsWithForcedPhotoUpdateInput;
  where: MetadataProductsWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataProductUpdateInput = {
  data: MetadataProductWithForcedPhotoUpdateInput;
  where: MetadataProductWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataProduitsUpdateInput = {
  data: MetadataProduitsWithForcedPhotoUpdateInput;
  where: MetadataProduitsWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataProgramversionUpdateInput = {
  data: MetadataProgramVersionWithForcedPhotoUpdateInput;
  where: MetadataProgramVersionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPropertyreleaseidUpdateInput = {
  data: MetadataPropertyReleaseIdWithForcedPhotoUpdateInput;
  where: MetadataPropertyReleaseIdWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPropertyreleasestatusUpdateInput = {
  data: MetadataPropertyReleaseStatusWithForcedPhotoUpdateInput;
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPublisherUpdateInput = {
  data: MetadataPublisherWithForcedPhotoUpdateInput;
  where: MetadataPublisherWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPublishingissueUpdateInput = {
  data: MetadataPublishingIssueWithForcedPhotoUpdateInput;
  where: MetadataPublishingIssueWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataPublishingsubjectUpdateInput = {
  data: MetadataPublishingSubjectWithForcedPhotoUpdateInput;
  where: MetadataPublishingSubjectWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataQualifieduseprismabyUpdateInput = {
  data: MetadataQualifiedUsePrismaByWithForcedPhotoUpdateInput;
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataQualifieduseprismadateUpdateInput = {
  data: MetadataQualifiedUsePrismaDateWithForcedPhotoUpdateInput;
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataQualifieduseprismadurationUpdateInput = {
  data: MetadataQualifiedUsePrismaDurationWithForcedPhotoUpdateInput;
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataQualifieduseprismasupportUpdateInput = {
  data: MetadataQualifiedUsePrismaSupportWithForcedPhotoUpdateInput;
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataRatingUpdateInput = {
  data: MetadataRatingWithForcedPhotoUpdateInput;
  where: MetadataRatingWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataReferencedateUpdateInput = {
  data: MetadataReferenceDateWithForcedPhotoUpdateInput;
  where: MetadataReferenceDateWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataReferencenumberUpdateInput = {
  data: MetadataReferenceNumberWithForcedPhotoUpdateInput;
  where: MetadataReferenceNumberWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataReferenceserviceUpdateInput = {
  data: MetadataReferenceServiceWithForcedPhotoUpdateInput;
  where: MetadataReferenceServiceWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataReleasedateUpdateInput = {
  data: MetadataReleaseDateWithForcedPhotoUpdateInput;
  where: MetadataReleaseDateWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataReleasetimeUpdateInput = {
  data: MetadataReleaseTimeWithForcedPhotoUpdateInput;
  where: MetadataReleaseTimeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataRequiredpermissionUpdateInput = {
  data: MetadataRequiredPermissionWithForcedPhotoUpdateInput;
  where: MetadataRequiredPermissionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataResolutionkindUpdateInput = {
  data: MetadataResolutionKindWithForcedPhotoUpdateInput;
  where: MetadataResolutionKindWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataRightsUpdateInput = {
  data: MetadataRightsWithForcedPhotoUpdateInput;
  where: MetadataRightsWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataRoyaltyfreeUpdateInput = {
  data: MetadataRoyaltyfreeWithForcedPhotoUpdateInput;
  where: MetadataRoyaltyfreeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSceneUpdateInput = {
  data: MetadataSceneWithForcedPhotoUpdateInput;
  where: MetadataSceneWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSectionUpdateInput = {
  data: MetadataSectionWithForcedPhotoUpdateInput;
  where: MetadataSectionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSenttoUpdateInput = {
  data: MetadataSentToWithForcedPhotoUpdateInput;
  where: MetadataSentToWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSerialnumberUpdateInput = {
  data: MetadataSerialNumberWithForcedPhotoUpdateInput;
  where: MetadataSerialNumberWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSeriesdatetimeUpdateInput = {
  data: MetadataSeriesDateTimeWithForcedPhotoUpdateInput;
  where: MetadataSeriesDateTimeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSeriesdescriptionUpdateInput = {
  data: MetadataSeriesDescriptionWithForcedPhotoUpdateInput;
  where: MetadataSeriesDescriptionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSeriesmodalityUpdateInput = {
  data: MetadataSeriesModalityWithForcedPhotoUpdateInput;
  where: MetadataSeriesModalityWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSeriesnumberUpdateInput = {
  data: MetadataSeriesNumberWithForcedPhotoUpdateInput;
  where: MetadataSeriesNumberWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataShortuniqueidUpdateInput = {
  data: MetadataShortUniqueIdWithForcedPhotoUpdateInput;
  where: MetadataShortUniqueIdWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSourceUpdateInput = {
  data: MetadataSourceWithForcedPhotoUpdateInput;
  where: MetadataSourceWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataStateUpdateInput = {
  data: MetadataStateWithForcedPhotoUpdateInput;
  where: MetadataStateWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataStudydatetimeUpdateInput = {
  data: MetadataStudyDateTimeWithForcedPhotoUpdateInput;
  where: MetadataStudyDateTimeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataStudydescriptionUpdateInput = {
  data: MetadataStudyDescriptionWithForcedPhotoUpdateInput;
  where: MetadataStudyDescriptionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataStudyidUpdateInput = {
  data: MetadataStudyIdWithForcedPhotoUpdateInput;
  where: MetadataStudyIdWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataStudyphysicianUpdateInput = {
  data: MetadataStudyPhysicianWithForcedPhotoUpdateInput;
  where: MetadataStudyPhysicianWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSubjectcodeUpdateInput = {
  data: MetadataSubjectCodeWithForcedPhotoUpdateInput;
  where: MetadataSubjectCodeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSubjectprismaUpdateInput = {
  data: MetadataSubjectPrismaWithForcedPhotoUpdateInput;
  where: MetadataSubjectPrismaWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSubjectUpdateInput = {
  data: MetadataSubjectWithForcedPhotoUpdateInput;
  where: MetadataSubjectWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataSupplementalcategoriesUpdateInput = {
  data: MetadataSupplementalCategoriesWithForcedPhotoUpdateInput;
  where: MetadataSupplementalCategoriesWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataTargetversionUpdateInput = {
  data: MetadataTargetVersionWithForcedPhotoUpdateInput;
  where: MetadataTargetVersionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataTitleUpdateInput = {
  data: MetadataTitleWithForcedPhotoUpdateInput;
  where: MetadataTitleWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataTonecurveUpdateInput = {
  data: MetadataToneCurveWithForcedPhotoUpdateInput;
  where: MetadataToneCurveWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataTransferredbyemailUpdateInput = {
  data: MetadataTransferredByEmailWithForcedPhotoUpdateInput;
  where: MetadataTransferredByEmailWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataTransferredbyfullnameUpdateInput = {
  data: MetadataTransferredByFullNameWithForcedPhotoUpdateInput;
  where: MetadataTransferredByFullNameWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataTransferredbyUpdateInput = {
  data: MetadataTransferredByWithForcedPhotoUpdateInput;
  where: MetadataTransferredByWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataTransmissionreferenceUpdateInput = {
  data: MetadataTransmissionReferenceWithForcedPhotoUpdateInput;
  where: MetadataTransmissionReferenceWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataTypeUpdateInput = {
  data: MetadataTypeWithForcedPhotoUpdateInput;
  where: MetadataTypeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUndersubjectUpdateInput = {
  data: MetadataUndersubjectWithForcedPhotoUpdateInput;
  where: MetadataUndersubjectWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUnderundersubjectUpdateInput = {
  data: MetadataUnderUnderSubjectWithForcedPhotoUpdateInput;
  where: MetadataUnderUnderSubjectWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUniqueidUpdateInput = {
  data: MetadataUniqueIdWithForcedPhotoUpdateInput;
  where: MetadataUniqueIdWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUnitshortnameUpdateInput = {
  data: MetadataUnitShortNameWithForcedPhotoUpdateInput;
  where: MetadataUnitShortNameWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUnitUpdateInput = {
  data: MetadataUnitWithForcedPhotoUpdateInput;
  where: MetadataUnitWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUploadedbyfullnameUpdateInput = {
  data: MetadataUploadedByFullNameWithForcedPhotoUpdateInput;
  where: MetadataUploadedByFullNameWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUploadedbyUpdateInput = {
  data: MetadataUploadedByWithForcedPhotoUpdateInput;
  where: MetadataUploadedByWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUploadtimeUpdateInput = {
  data: MetadataUploadTimeWithForcedPhotoUpdateInput;
  where: MetadataUploadTimeWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUrgencyUpdateInput = {
  data: MetadataUrgencyWithForcedPhotoUpdateInput;
  where: MetadataUrgencyWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUsagetermsUpdateInput = {
  data: MetadataUsageTermsWithForcedPhotoUpdateInput;
  where: MetadataUsageTermsWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUsercommentUpdateInput = {
  data: MetadataUserCommentWithForcedPhotoUpdateInput;
  where: MetadataUserCommentWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUserdefined195UpdateInput = {
  data: MetadataUserDefined195WithForcedPhotoUpdateInput;
  where: MetadataUserDefined195WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUserdefined237UpdateInput = {
  data: MetadataUserDefined237WithForcedPhotoUpdateInput;
  where: MetadataUserDefined237WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUserdefined238UpdateInput = {
  data: MetadataUserDefined238WithForcedPhotoUpdateInput;
  where: MetadataUserDefined238WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUserdefined239UpdateInput = {
  data: MetadataUserDefined239WithForcedPhotoUpdateInput;
  where: MetadataUserDefined239WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUserdefined242UpdateInput = {
  data: MetadataUserDefined242WithForcedPhotoUpdateInput;
  where: MetadataUserDefined242WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataUserdefined62UpdateInput = {
  data: MetadataUserDefined62WithForcedPhotoUpdateInput;
  where: MetadataUserDefined62WhereUniqueInput;
};

export type PhotoNestedUpdateMetadataValidUpdateInput = {
  data: MetadataValidWithForcedPhotoUpdateInput;
  where: MetadataValidWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataVersionUpdateInput = {
  data: MetadataVersionWithForcedPhotoUpdateInput;
  where: MetadataVersionWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataWebstatementUpdateInput = {
  data: MetadataWebStatementWithForcedPhotoUpdateInput;
  where: MetadataWebStatementWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataWorkflowkindUpdateInput = {
  data: MetadataWorkflowKindWithForcedPhotoUpdateInput;
  where: MetadataWorkflowKindWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataXmpfilestampsUpdateInput = {
  data: MetadataXmpFileStampsWithForcedPhotoUpdateInput;
  where: MetadataXmpFileStampsWhereUniqueInput;
};

export type PhotoNestedUpdateMetadataXmphistoryUpdateInput = {
  data: MetadataXmpHistoryWithForcedPhotoUpdateInput;
  where: MetadataXmpHistoryWhereUniqueInput;
};

export type PhotoNestedUpdatePhotoMetadatasUpdateInput = {
  data: PhotoMetadataWithForcedPhotoUpdateInput;
  where: PhotoMetadataWhereUniqueInput;
};

export type PhotoNestedUpdatePhotoPublicationsUpdateInput = {
  data: PhotoPublicationWithForcedPhotoUpdateInput;
  where: PhotoPublicationWhereUniqueInput;
};

export type PhotoNestedUpsertAgencyCreateInput = {
  create: AgencyCreateInput;
  update: AgencyUpdateInput;
  where: AgencyWhereUniqueInput;
};

export type PhotoNestedUpsertAgencyUpdateInput = {
  create: AgencyCreateInput;
  update: AgencyUpdateInput;
  where: AgencyWhereUniqueInput;
};

export type PhotoNestedUpsertArchiveCreateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type PhotoNestedUpsertArchiveUpdateInput = {
  create: ArchiveCreateInput;
  update: ArchiveUpdateInput;
  where: ArchiveWhereUniqueInput;
};

export type PhotoNestedUpsertCategoryCreateInput = {
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type PhotoNestedUpsertCategoryUpdateInput = {
  create: CategoryCreateInput;
  update: CategoryUpdateInput;
  where: CategoryWhereUniqueInput;
};

export type PhotoNestedUpsertDeliverySubjectCreateInput = {
  create: DeliverySubjectCreateInput;
  update: DeliverySubjectUpdateInput;
  where: DeliverySubjectWhereUniqueInput;
};

export type PhotoNestedUpsertDeliverySubjectUpdateInput = {
  create: DeliverySubjectCreateInput;
  update: DeliverySubjectUpdateInput;
  where: DeliverySubjectWhereUniqueInput;
};

export type PhotoNestedUpsertImportQueueUpdateInput = {
  create: ImportQueueWithForcedPhotoCreateInput;
  update: ImportQueueWithForcedPhotoUpdateInput;
  where: ImportQueueWithOptionalPhotoWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataAccessrightsUpdateInput = {
  create: MetadataAccessRightsWithForcedPhotoCreateInput;
  update: MetadataAccessRightsWithForcedPhotoUpdateInput;
  where: MetadataAccessRightsWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataAddlmodelinfoUpdateInput = {
  create: MetadataAddlModelInfoWithForcedPhotoCreateInput;
  update: MetadataAddlModelInfoWithForcedPhotoUpdateInput;
  where: MetadataAddlModelInfoWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataArchiveChildUpdateInput = {
  create: MetadataArchiveChildWithForcedPhotoCreateInput;
  update: MetadataArchiveChildWithForcedPhotoUpdateInput;
  where: MetadataArchiveChildWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataArchiveParentUpdateInput = {
  create: MetadataArchiveParentWithForcedPhotoCreateInput;
  update: MetadataArchiveParentWithForcedPhotoUpdateInput;
  where: MetadataArchiveParentWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataAssetstateUpdateInput = {
  create: MetadataAssetStateWithForcedPhotoCreateInput;
  update: MetadataAssetStateWithForcedPhotoUpdateInput;
  where: MetadataAssetStateWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataAttributionnameUpdateInput = {
  create: MetadataAttributionNameWithForcedPhotoCreateInput;
  update: MetadataAttributionNameWithForcedPhotoUpdateInput;
  where: MetadataAttributionNameWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataAttributionurlUpdateInput = {
  create: MetadataAttributionUrlWithForcedPhotoCreateInput;
  update: MetadataAttributionUrlWithForcedPhotoUpdateInput;
  where: MetadataAttributionUrlWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataAudienceUpdateInput = {
  create: MetadataAudienceWithForcedPhotoCreateInput;
  update: MetadataAudienceWithForcedPhotoUpdateInput;
  where: MetadataAudienceWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataAuthorspositionUpdateInput = {
  create: MetadataAuthorsPositionWithForcedPhotoCreateInput;
  update: MetadataAuthorsPositionWithForcedPhotoUpdateInput;
  where: MetadataAuthorsPositionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataBackupnameUpdateInput = {
  create: MetadataBackupNameWithForcedPhotoCreateInput;
  update: MetadataBackupNameWithForcedPhotoUpdateInput;
  where: MetadataBackupNameWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataBitmapgrayscalepictureUpdateInput = {
  create: MetadataBitmapGrayscalePictureWithForcedPhotoCreateInput;
  update: MetadataBitmapGrayscalePictureWithForcedPhotoUpdateInput;
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataBrandprismaUpdateInput = {
  create: MetadataBrandPrismaWithForcedPhotoCreateInput;
  update: MetadataBrandPrismaWithForcedPhotoUpdateInput;
  where: MetadataBrandPrismaWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataBrandshortnameUpdateInput = {
  create: MetadataBrandShortNameWithForcedPhotoCreateInput;
  update: MetadataBrandShortNameWithForcedPhotoUpdateInput;
  where: MetadataBrandShortNameWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataBrandUpdateInput = {
  create: MetadataBrandWithForcedPhotoCreateInput;
  update: MetadataBrandWithForcedPhotoUpdateInput;
  where: MetadataBrandWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCaptionwriterUpdateInput = {
  create: MetadataCaptionWriterWithForcedPhotoCreateInput;
  update: MetadataCaptionWriterWithForcedPhotoUpdateInput;
  where: MetadataCaptionWriterWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCategoryprismaUpdateInput = {
  create: MetadataCategoryPrismaWithForcedPhotoCreateInput;
  update: MetadataCategoryPrismaWithForcedPhotoUpdateInput;
  where: MetadataCategoryPrismaWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCategoryUpdateInput = {
  create: MetadataCategoryWithForcedPhotoCreateInput;
  update: MetadataCategoryWithForcedPhotoUpdateInput;
  where: MetadataCategoryWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCertificateUpdateInput = {
  create: MetadataCertificateWithForcedPhotoCreateInput;
  update: MetadataCertificateWithForcedPhotoUpdateInput;
  where: MetadataCertificateWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCiadrcityUpdateInput = {
  create: MetadataCiAdrCityWithForcedPhotoCreateInput;
  update: MetadataCiAdrCityWithForcedPhotoUpdateInput;
  where: MetadataCiAdrCityWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCiadrctryUpdateInput = {
  create: MetadataCiAdrCtryWithForcedPhotoCreateInput;
  update: MetadataCiAdrCtryWithForcedPhotoUpdateInput;
  where: MetadataCiAdrCtryWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCiadrextadrUpdateInput = {
  create: MetadataCiAdrExtadrWithForcedPhotoCreateInput;
  update: MetadataCiAdrExtadrWithForcedPhotoUpdateInput;
  where: MetadataCiAdrExtadrWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCiadrpcodeUpdateInput = {
  create: MetadataCiAdrPcodeWithForcedPhotoCreateInput;
  update: MetadataCiAdrPcodeWithForcedPhotoUpdateInput;
  where: MetadataCiAdrPcodeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCiadrregionUpdateInput = {
  create: MetadataCiAdrRegionWithForcedPhotoCreateInput;
  update: MetadataCiAdrRegionWithForcedPhotoUpdateInput;
  where: MetadataCiAdrRegionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCiemailworkUpdateInput = {
  create: MetadataCiEmailWorkWithForcedPhotoCreateInput;
  update: MetadataCiEmailWorkWithForcedPhotoUpdateInput;
  where: MetadataCiEmailWorkWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCitelworkUpdateInput = {
  create: MetadataCiTelWorkWithForcedPhotoCreateInput;
  update: MetadataCiTelWorkWithForcedPhotoUpdateInput;
  where: MetadataCiTelWorkWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCityUpdateInput = {
  create: MetadataCityWithForcedPhotoCreateInput;
  update: MetadataCityWithForcedPhotoUpdateInput;
  where: MetadataCityWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCiurlworkUpdateInput = {
  create: MetadataCiUrlWorkWithForcedPhotoCreateInput;
  update: MetadataCiUrlWorkWithForcedPhotoUpdateInput;
  where: MetadataCiUrlWorkWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataClassifyUpdateInput = {
  create: MetadataClassifyWithForcedPhotoCreateInput;
  update: MetadataClassifyWithForcedPhotoUpdateInput;
  where: MetadataClassifyWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCompanyshortnameUpdateInput = {
  create: MetadataCompanyShortNameWithForcedPhotoCreateInput;
  update: MetadataCompanyShortNameWithForcedPhotoUpdateInput;
  where: MetadataCompanyShortNameWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCompanyUpdateInput = {
  create: MetadataCompanyWithForcedPhotoCreateInput;
  update: MetadataCompanyWithForcedPhotoUpdateInput;
  where: MetadataCompanyWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataConfidentialityUpdateInput = {
  create: MetadataConfidentialityWithForcedPhotoCreateInput;
  update: MetadataConfidentialityWithForcedPhotoUpdateInput;
  where: MetadataConfidentialityWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataContainedinUpdateInput = {
  create: MetadataContainedInWithForcedPhotoCreateInput;
  update: MetadataContainedInWithForcedPhotoUpdateInput;
  where: MetadataContainedInWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataContentmediakindUpdateInput = {
  create: MetadataContentMediaKindWithForcedPhotoCreateInput;
  update: MetadataContentMediaKindWithForcedPhotoUpdateInput;
  where: MetadataContentMediaKindWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataContentvalueUpdateInput = {
  create: MetadataContentValueWithForcedPhotoCreateInput;
  update: MetadataContentValueWithForcedPhotoUpdateInput;
  where: MetadataContentValueWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCopyrightlayerUpdateInput = {
  create: MetadataCopyrightLayerWithForcedPhotoCreateInput;
  update: MetadataCopyrightLayerWithForcedPhotoUpdateInput;
  where: MetadataCopyrightLayerWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCountrycodeUpdateInput = {
  create: MetadataCountryCodeWithForcedPhotoCreateInput;
  update: MetadataCountryCodeWithForcedPhotoUpdateInput;
  where: MetadataCountryCodeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCountryUpdateInput = {
  create: MetadataCountryWithForcedPhotoCreateInput;
  update: MetadataCountryWithForcedPhotoUpdateInput;
  where: MetadataCountryWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCreatedtimeUpdateInput = {
  create: MetadataCreatedTimeWithForcedPhotoCreateInput;
  update: MetadataCreatedTimeWithForcedPhotoUpdateInput;
  where: MetadataCreatedTimeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCreatortoolUpdateInput = {
  create: MetadataCreatorToolWithForcedPhotoCreateInput;
  update: MetadataCreatorToolWithForcedPhotoUpdateInput;
  where: MetadataCreatorToolWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCreatorUpdateInput = {
  create: MetadataCreatorWithForcedPhotoCreateInput;
  update: MetadataCreatorWithForcedPhotoUpdateInput;
  where: MetadataCreatorWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCreditUpdateInput = {
  create: MetadataCreditWithForcedPhotoCreateInput;
  update: MetadataCreditWithForcedPhotoUpdateInput;
  where: MetadataCreditWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCustomfield14UpdateInput = {
  create: MetadataCustomField14WithForcedPhotoCreateInput;
  update: MetadataCustomField14WithForcedPhotoUpdateInput;
  where: MetadataCustomField14WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCustomfield15UpdateInput = {
  create: MetadataCustomField15WithForcedPhotoCreateInput;
  update: MetadataCustomField15WithForcedPhotoUpdateInput;
  where: MetadataCustomField15WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCustomfield16UpdateInput = {
  create: MetadataCustomField16WithForcedPhotoCreateInput;
  update: MetadataCustomField16WithForcedPhotoUpdateInput;
  where: MetadataCustomField16WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCustomfield17UpdateInput = {
  create: MetadataCustomField17WithForcedPhotoCreateInput;
  update: MetadataCustomField17WithForcedPhotoUpdateInput;
  where: MetadataCustomField17WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCustomfield20UpdateInput = {
  create: MetadataCustomField20WithForcedPhotoCreateInput;
  update: MetadataCustomField20WithForcedPhotoUpdateInput;
  where: MetadataCustomField20WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCustomfield5UpdateInput = {
  create: MetadataCustomField5WithForcedPhotoCreateInput;
  update: MetadataCustomField5WithForcedPhotoUpdateInput;
  where: MetadataCustomField5WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCustomfield6UpdateInput = {
  create: MetadataCustomField6WithForcedPhotoCreateInput;
  update: MetadataCustomField6WithForcedPhotoUpdateInput;
  where: MetadataCustomField6WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCustomfield7UpdateInput = {
  create: MetadataCustomField7WithForcedPhotoCreateInput;
  update: MetadataCustomField7WithForcedPhotoUpdateInput;
  where: MetadataCustomField7WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataCustomfield9UpdateInput = {
  create: MetadataCustomField9WithForcedPhotoCreateInput;
  update: MetadataCustomField9WithForcedPhotoUpdateInput;
  where: MetadataCustomField9WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDatecreatedUpdateInput = {
  create: MetadataDateCreatedWithForcedPhotoCreateInput;
  update: MetadataDateCreatedWithForcedPhotoUpdateInput;
  where: MetadataDateCreatedWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDatetimedigitizedUpdateInput = {
  create: MetadataDateTimeDigitizedWithForcedPhotoCreateInput;
  update: MetadataDateTimeDigitizedWithForcedPhotoUpdateInput;
  where: MetadataDateTimeDigitizedWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDatetimeoriginalUpdateInput = {
  create: MetadataDateTimeOriginalWithForcedPhotoCreateInput;
  update: MetadataDateTimeOriginalWithForcedPhotoUpdateInput;
  where: MetadataDateTimeOriginalWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliveryaccountUpdateInput = {
  create: MetadataDeliveryAccountWithForcedPhotoCreateInput;
  update: MetadataDeliveryAccountWithForcedPhotoUpdateInput;
  where: MetadataDeliveryAccountWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliverycompanyUpdateInput = {
  create: MetadataDeliveryCompanyWithForcedPhotoCreateInput;
  update: MetadataDeliveryCompanyWithForcedPhotoUpdateInput;
  where: MetadataDeliveryCompanyWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliverycopyrightUpdateInput = {
  create: MetadataDeliveryCopyrightWithForcedPhotoCreateInput;
  update: MetadataDeliveryCopyrightWithForcedPhotoUpdateInput;
  where: MetadataDeliveryCopyrightWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliverydatefolderUpdateInput = {
  create: MetadataDeliveryDateFolderWithForcedPhotoCreateInput;
  update: MetadataDeliveryDateFolderWithForcedPhotoUpdateInput;
  where: MetadataDeliveryDateFolderWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliverydatetimeUpdateInput = {
  create: MetadataDeliveryDateTimeWithForcedPhotoCreateInput;
  update: MetadataDeliveryDateTimeWithForcedPhotoUpdateInput;
  where: MetadataDeliveryDateTimeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliverydccreatorUpdateInput = {
  create: MetadataDeliveryDcCreatorWithForcedPhotoCreateInput;
  update: MetadataDeliveryDcCreatorWithForcedPhotoUpdateInput;
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliverydcrightsUpdateInput = {
  create: MetadataDeliveryDcRightsWithForcedPhotoCreateInput;
  update: MetadataDeliveryDcRightsWithForcedPhotoUpdateInput;
  where: MetadataDeliveryDcRightsWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliveryfiletypeUpdateInput = {
  create: MetadataDeliveryFileTypeWithForcedPhotoCreateInput;
  update: MetadataDeliveryFileTypeWithForcedPhotoUpdateInput;
  where: MetadataDeliveryFileTypeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliveryfolderUpdateInput = {
  create: MetadataDeliveryFolderWithForcedPhotoCreateInput;
  update: MetadataDeliveryFolderWithForcedPhotoUpdateInput;
  where: MetadataDeliveryFolderWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliverykindUpdateInput = {
  create: MetadataDeliveryKindWithForcedPhotoCreateInput;
  update: MetadataDeliveryKindWithForcedPhotoUpdateInput;
  where: MetadataDeliveryKindWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliverypathUpdateInput = {
  create: MetadataDeliveryPathWithForcedPhotoCreateInput;
  update: MetadataDeliveryPathWithForcedPhotoUpdateInput;
  where: MetadataDeliveryPathWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliverypersonshownintheimageUpdateInput = {
  create: MetadataDeliveryPersonShownintheImageWithForcedPhotoCreateInput;
  update: MetadataDeliveryPersonShownintheImageWithForcedPhotoUpdateInput;
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliveryphotoshopcreditUpdateInput = {
  create: MetadataDeliveryPhotoshopCreditWithForcedPhotoCreateInput;
  update: MetadataDeliveryPhotoshopCreditWithForcedPhotoUpdateInput;
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliveryphotoshopsourceUpdateInput = {
  create: MetadataDeliveryPhotoshopSourceWithForcedPhotoCreateInput;
  update: MetadataDeliveryPhotoshopSourceWithForcedPhotoUpdateInput;
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliveryserviceUpdateInput = {
  create: MetadataDeliveryServiceWithForcedPhotoCreateInput;
  update: MetadataDeliveryServiceWithForcedPhotoUpdateInput;
  where: MetadataDeliveryServiceWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliverysubjectUpdateInput = {
  create: MetadataDeliverySubjectWithForcedPhotoCreateInput;
  update: MetadataDeliverySubjectWithForcedPhotoUpdateInput;
  where: MetadataDeliverySubjectWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDeliveryundersubjectUpdateInput = {
  create: MetadataDeliveryUnderSubjectWithForcedPhotoCreateInput;
  update: MetadataDeliveryUnderSubjectWithForcedPhotoUpdateInput;
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDepartmentUpdateInput = {
  create: MetadataDepartmentWithForcedPhotoCreateInput;
  update: MetadataDepartmentWithForcedPhotoUpdateInput;
  where: MetadataDepartmentWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDescriptionUpdateInput = {
  create: MetadataDescriptionWithForcedPhotoCreateInput;
  update: MetadataDescriptionWithForcedPhotoUpdateInput;
  where: MetadataDescriptionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDigitalasseturlUpdateInput = {
  create: MetadataDigitalAssetUrlWithForcedPhotoCreateInput;
  update: MetadataDigitalAssetUrlWithForcedPhotoUpdateInput;
  where: MetadataDigitalAssetUrlWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDivisionUpdateInput = {
  create: MetadataDivisionWithForcedPhotoCreateInput;
  update: MetadataDivisionWithForcedPhotoUpdateInput;
  where: MetadataDivisionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataDocumenttextUpdateInput = {
  create: MetadataDocumentTextWithForcedPhotoCreateInput;
  update: MetadataDocumentTextWithForcedPhotoUpdateInput;
  where: MetadataDocumentTextWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataEditionUpdateInput = {
  create: MetadataEditionWithForcedPhotoCreateInput;
  update: MetadataEditionWithForcedPhotoUpdateInput;
  where: MetadataEditionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataEditorialversionUpdateInput = {
  create: MetadataEditorialVersionWithForcedPhotoCreateInput;
  update: MetadataEditorialVersionWithForcedPhotoUpdateInput;
  where: MetadataEditorialVersionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataEditstatusUpdateInput = {
  create: MetadataEditStatusWithForcedPhotoCreateInput;
  update: MetadataEditStatusWithForcedPhotoUpdateInput;
  where: MetadataEditStatusWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataEnvironnementphotoUpdateInput = {
  create: MetadataEnvironnementPhotoWithForcedPhotoCreateInput;
  update: MetadataEnvironnementPhotoWithForcedPhotoUpdateInput;
  where: MetadataEnvironnementPhotoWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataEquipmentinstitutionUpdateInput = {
  create: MetadataEquipmentInstitutionWithForcedPhotoCreateInput;
  update: MetadataEquipmentInstitutionWithForcedPhotoUpdateInput;
  where: MetadataEquipmentInstitutionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataEquipmentmanufacturerUpdateInput = {
  create: MetadataEquipmentManufacturerWithForcedPhotoCreateInput;
  update: MetadataEquipmentManufacturerWithForcedPhotoUpdateInput;
  where: MetadataEquipmentManufacturerWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataEventUpdateInput = {
  create: MetadataEventWithForcedPhotoCreateInput;
  update: MetadataEventWithForcedPhotoUpdateInput;
  where: MetadataEventWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataExifUpdateInput = {
  create: MetadataExifWithForcedPhotoCreateInput;
  update: MetadataExifWithForcedPhotoUpdateInput;
  where: MetadataExifWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataFabstorageUpdateInput = {
  create: MetadataFabStorageWithForcedPhotoCreateInput;
  update: MetadataFabStorageWithForcedPhotoUpdateInput;
  where: MetadataFabStorageWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataFilecheckforUpdateInput = {
  create: MetadataFileCheckForWithForcedPhotoCreateInput;
  update: MetadataFileCheckForWithForcedPhotoUpdateInput;
  where: MetadataFileCheckForWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataFileinfobitsperpixelUpdateInput = {
  create: MetadataFileInfoBitsPerPixelWithForcedPhotoCreateInput;
  update: MetadataFileInfoBitsPerPixelWithForcedPhotoUpdateInput;
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataFileinfoframesUpdateInput = {
  create: MetadataFileInfoFramesWithForcedPhotoCreateInput;
  update: MetadataFileInfoFramesWithForcedPhotoUpdateInput;
  where: MetadataFileInfoFramesWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataFileinfophotometricinterpretationUpdateInput = {
  create: MetadataFileInfoPhotometricInterpretationWithForcedPhotoCreateInput;
  update: MetadataFileInfoPhotometricInterpretationWithForcedPhotoUpdateInput;
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataFileinfotransfersyntaxUpdateInput = {
  create: MetadataFileInfoTransferSyntaxWithForcedPhotoCreateInput;
  update: MetadataFileInfoTransferSyntaxWithForcedPhotoUpdateInput;
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataFilestageUpdateInput = {
  create: MetadataFileStageWithForcedPhotoCreateInput;
  update: MetadataFileStageWithForcedPhotoUpdateInput;
  where: MetadataFileStageWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataFilestatusUpdateInput = {
  create: MetadataFileStatusWithForcedPhotoCreateInput;
  update: MetadataFileStatusWithForcedPhotoUpdateInput;
  where: MetadataFileStatusWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataFiletypeUpdateInput = {
  create: MetadataFileTypeWithForcedPhotoCreateInput;
  update: MetadataFileTypeWithForcedPhotoUpdateInput;
  where: MetadataFileTypeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataFixtureidentifierUpdateInput = {
  create: MetadataFixtureIdentifierWithForcedPhotoCreateInput;
  update: MetadataFixtureIdentifierWithForcedPhotoUpdateInput;
  where: MetadataFixtureIdentifierWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataFormatUpdateInput = {
  create: MetadataFormatWithForcedPhotoCreateInput;
  update: MetadataFormatWithForcedPhotoUpdateInput;
  where: MetadataFormatWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataGpslatitudeUpdateInput = {
  create: MetadataGpsLatitudeWithForcedPhotoCreateInput;
  update: MetadataGpsLatitudeWithForcedPhotoUpdateInput;
  where: MetadataGpsLatitudeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataGpslongitudeUpdateInput = {
  create: MetadataGpsLongitudeWithForcedPhotoCreateInput;
  update: MetadataGpsLongitudeWithForcedPhotoUpdateInput;
  where: MetadataGpsLongitudeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataGpsprocessingmethodUpdateInput = {
  create: MetadataGpsProcessingMethodWithForcedPhotoCreateInput;
  update: MetadataGpsProcessingMethodWithForcedPhotoUpdateInput;
  where: MetadataGpsProcessingMethodWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataHeaderprismaUpdateInput = {
  create: MetadataHeaderPrismaWithForcedPhotoCreateInput;
  update: MetadataHeaderPrismaWithForcedPhotoUpdateInput;
  where: MetadataHeaderPrismaWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataHeaderUpdateInput = {
  create: MetadataHeaderWithForcedPhotoCreateInput;
  update: MetadataHeaderWithForcedPhotoUpdateInput;
  where: MetadataHeaderWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataHeadlineUpdateInput = {
  create: MetadataHeadlineWithForcedPhotoCreateInput;
  update: MetadataHeadlineWithForcedPhotoUpdateInput;
  where: MetadataHeadlineWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataHistoryUpdateInput = {
  create: MetadataHistoryWithForcedPhotoCreateInput;
  update: MetadataHistoryWithForcedPhotoUpdateInput;
  where: MetadataHistoryWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataIccprofileUpdateInput = {
  create: MetadataIccProfileWithForcedPhotoCreateInput;
  update: MetadataIccProfileWithForcedPhotoUpdateInput;
  where: MetadataIccProfileWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataIdassignmentUpdateInput = {
  create: MetadataIdAssignmentWithForcedPhotoCreateInput;
  update: MetadataIdAssignmentWithForcedPhotoUpdateInput;
  where: MetadataIdAssignmentWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataIdmediacontentUpdateInput = {
  create: MetadataIdMediaContentWithForcedPhotoCreateInput;
  update: MetadataIdMediaContentWithForcedPhotoUpdateInput;
  where: MetadataIdMediaContentWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataIdphotoprismaUpdateInput = {
  create: MetadataIdPhotoPrismaWithForcedPhotoCreateInput;
  update: MetadataIdPhotoPrismaWithForcedPhotoUpdateInput;
  where: MetadataIdPhotoPrismaWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataIdSubjectUpdateInput = {
  create: MetadataIdSubjectWithForcedPhotoCreateInput;
  update: MetadataIdSubjectWithForcedPhotoUpdateInput;
  where: MetadataIdSubjectWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataImagenotesUpdateInput = {
  create: MetadataImageNotesWithForcedPhotoCreateInput;
  update: MetadataImageNotesWithForcedPhotoUpdateInput;
  where: MetadataImageNotesWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataImageuniqueidUpdateInput = {
  create: MetadataImageUniqueIdWithForcedPhotoCreateInput;
  update: MetadataImageUniqueIdWithForcedPhotoUpdateInput;
  where: MetadataImageUniqueIdWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataInstructionsUpdateInput = {
  create: MetadataInstructionsWithForcedPhotoCreateInput;
  update: MetadataInstructionsWithForcedPhotoUpdateInput;
  where: MetadataInstructionsWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataIntellectualgenreUpdateInput = {
  create: MetadataIntellectualGenreWithForcedPhotoCreateInput;
  update: MetadataIntellectualGenreWithForcedPhotoUpdateInput;
  where: MetadataIntellectualGenreWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataIsospeedratingsUpdateInput = {
  create: MetadataIsoSpeedRatingsWithForcedPhotoCreateInput;
  update: MetadataIsoSpeedRatingsWithForcedPhotoUpdateInput;
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataIssueUpdateInput = {
  create: MetadataIssueWithForcedPhotoCreateInput;
  update: MetadataIssueWithForcedPhotoUpdateInput;
  where: MetadataIssueWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataJobidUpdateInput = {
  create: MetadataJobIdWithForcedPhotoCreateInput;
  update: MetadataJobIdWithForcedPhotoUpdateInput;
  where: MetadataJobIdWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataJobprocessingUpdateInput = {
  create: MetadataJobProcessingWithForcedPhotoCreateInput;
  update: MetadataJobProcessingWithForcedPhotoUpdateInput;
  where: MetadataJobProcessingWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataLayoutpictureeditingUpdateInput = {
  create: MetadataLayoutPictureEditingWithForcedPhotoCreateInput;
  update: MetadataLayoutPictureEditingWithForcedPhotoUpdateInput;
  where: MetadataLayoutPictureEditingWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataLayoutstorageUpdateInput = {
  create: MetadataLayoutStorageWithForcedPhotoCreateInput;
  update: MetadataLayoutStorageWithForcedPhotoUpdateInput;
  where: MetadataLayoutStorageWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataLicenseUpdateInput = {
  create: MetadataLicenseWithForcedPhotoCreateInput;
  update: MetadataLicenseWithForcedPhotoUpdateInput;
  where: MetadataLicenseWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataLinksUpdateInput = {
  create: MetadataLinksWithForcedPhotoCreateInput;
  update: MetadataLinksWithForcedPhotoUpdateInput;
  where: MetadataLinksWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataLocalcaptionUpdateInput = {
  create: MetadataLocalCaptionWithForcedPhotoCreateInput;
  update: MetadataLocalCaptionWithForcedPhotoUpdateInput;
  where: MetadataLocalCaptionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataLocationUpdateInput = {
  create: MetadataLocationWithForcedPhotoCreateInput;
  update: MetadataLocationWithForcedPhotoUpdateInput;
  where: MetadataLocationWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataMakeUpdateInput = {
  create: MetadataMakeWithForcedPhotoCreateInput;
  update: MetadataMakeWithForcedPhotoUpdateInput;
  where: MetadataMakeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataManifestUpdateInput = {
  create: MetadataManifestWithForcedPhotoCreateInput;
  update: MetadataManifestWithForcedPhotoUpdateInput;
  where: MetadataManifestWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataMarkedUpdateInput = {
  create: MetadataMarkedWithForcedPhotoCreateInput;
  update: MetadataMarkedWithForcedPhotoUpdateInput;
  where: MetadataMarkedWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataMasterdocumentidUpdateInput = {
  create: MetadataMasterDocumentIdWithForcedPhotoCreateInput;
  update: MetadataMasterDocumentIdWithForcedPhotoUpdateInput;
  where: MetadataMasterDocumentIdWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataMaxavailheightUpdateInput = {
  create: MetadataMaxAvailHeightWithForcedPhotoCreateInput;
  update: MetadataMaxAvailHeightWithForcedPhotoUpdateInput;
  where: MetadataMaxAvailHeightWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataMaxavailwidthUpdateInput = {
  create: MetadataMaxAvailWidthWithForcedPhotoCreateInput;
  update: MetadataMaxAvailWidthWithForcedPhotoUpdateInput;
  where: MetadataMaxAvailWidthWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataMinormodelagedisclosureUpdateInput = {
  create: MetadataMinorModelAgeDisclosureWithForcedPhotoCreateInput;
  update: MetadataMinorModelAgeDisclosureWithForcedPhotoUpdateInput;
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataModelageUpdateInput = {
  create: MetadataModelAgeWithForcedPhotoCreateInput;
  update: MetadataModelAgeWithForcedPhotoUpdateInput;
  where: MetadataModelAgeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataModelreleaseidUpdateInput = {
  create: MetadataModelReleaseIdWithForcedPhotoCreateInput;
  update: MetadataModelReleaseIdWithForcedPhotoUpdateInput;
  where: MetadataModelReleaseIdWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataModelreleasestatusUpdateInput = {
  create: MetadataModelReleaseStatusWithForcedPhotoCreateInput;
  update: MetadataModelReleaseStatusWithForcedPhotoUpdateInput;
  where: MetadataModelReleaseStatusWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataModelUpdateInput = {
  create: MetadataModelWithForcedPhotoCreateInput;
  update: MetadataModelWithForcedPhotoUpdateInput;
  where: MetadataModelWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataMorepermissionsUpdateInput = {
  create: MetadataMorePermissionsWithForcedPhotoCreateInput;
  update: MetadataMorePermissionsWithForcedPhotoUpdateInput;
  where: MetadataMorePermissionsWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataObjectcycleUpdateInput = {
  create: MetadataObjectCycleWithForcedPhotoCreateInput;
  update: MetadataObjectCycleWithForcedPhotoUpdateInput;
  where: MetadataObjectCycleWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataOrganisationinimagenameUpdateInput = {
  create: MetadataOrganisationInImageNameWithForcedPhotoCreateInput;
  update: MetadataOrganisationInImageNameWithForcedPhotoUpdateInput;
  where: MetadataOrganisationInImageNameWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataOriginalcolorUpdateInput = {
  create: MetadataOriginalColorWithForcedPhotoCreateInput;
  update: MetadataOriginalColorWithForcedPhotoUpdateInput;
  where: MetadataOriginalColorWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataOriginaliccprofileUpdateInput = {
  create: MetadataOriginalIccProfileWithForcedPhotoCreateInput;
  update: MetadataOriginalIccProfileWithForcedPhotoUpdateInput;
  where: MetadataOriginalIccProfileWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataOriginalnameUpdateInput = {
  create: MetadataOriginalNameWithForcedPhotoCreateInput;
  update: MetadataOriginalNameWithForcedPhotoUpdateInput;
  where: MetadataOriginalNameWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataOriginalweigthUpdateInput = {
  create: MetadataOriginalWeigthWithForcedPhotoCreateInput;
  update: MetadataOriginalWeigthWithForcedPhotoUpdateInput;
  where: MetadataOriginalWeigthWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataOwneridUpdateInput = {
  create: MetadataOwnerIdWithForcedPhotoCreateInput;
  update: MetadataOwnerIdWithForcedPhotoUpdateInput;
  where: MetadataOwnerIdWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataOwnerUpdateInput = {
  create: MetadataOwnerWithForcedPhotoCreateInput;
  update: MetadataOwnerWithForcedPhotoUpdateInput;
  where: MetadataOwnerWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPageprismaUpdateInput = {
  create: MetadataPagePrismaWithForcedPhotoCreateInput;
  update: MetadataPagePrismaWithForcedPhotoUpdateInput;
  where: MetadataPagePrismaWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPageUpdateInput = {
  create: MetadataPageWithForcedPhotoCreateInput;
  update: MetadataPageWithForcedPhotoUpdateInput;
  where: MetadataPageWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPaintbasedcorrectionsUpdateInput = {
  create: MetadataPaintBasedCorrectionsWithForcedPhotoCreateInput;
  update: MetadataPaintBasedCorrectionsWithForcedPhotoUpdateInput;
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPatientdobUpdateInput = {
  create: MetadataPatientDobWithForcedPhotoCreateInput;
  update: MetadataPatientDobWithForcedPhotoUpdateInput;
  where: MetadataPatientDobWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPatientidUpdateInput = {
  create: MetadataPatientIdWithForcedPhotoCreateInput;
  update: MetadataPatientIdWithForcedPhotoUpdateInput;
  where: MetadataPatientIdWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPatientnameUpdateInput = {
  create: MetadataPatientNameWithForcedPhotoCreateInput;
  update: MetadataPatientNameWithForcedPhotoUpdateInput;
  where: MetadataPatientNameWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPatientsexUpdateInput = {
  create: MetadataPatientSexWithForcedPhotoCreateInput;
  update: MetadataPatientSexWithForcedPhotoUpdateInput;
  where: MetadataPatientSexWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPersoninimageUpdateInput = {
  create: MetadataPersonInImageWithForcedPhotoCreateInput;
  update: MetadataPersonInImageWithForcedPhotoUpdateInput;
  where: MetadataPersonInImageWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPhotostorageUpdateInput = {
  create: MetadataPhotoStorageWithForcedPhotoCreateInput;
  update: MetadataPhotoStorageWithForcedPhotoUpdateInput;
  where: MetadataPhotoStorageWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPrepresspictureeditingUpdateInput = {
  create: MetadataPrepressPictureEditingWithForcedPhotoCreateInput;
  update: MetadataPrepressPictureEditingWithForcedPhotoUpdateInput;
  where: MetadataPrepressPictureEditingWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPricelevelUpdateInput = {
  create: MetadataPriceLevelWithForcedPhotoCreateInput;
  update: MetadataPriceLevelWithForcedPhotoUpdateInput;
  where: MetadataPriceLevelWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPrintingprofileUpdateInput = {
  create: MetadataPrintingProfileWithForcedPhotoCreateInput;
  update: MetadataPrintingProfileWithForcedPhotoUpdateInput;
  where: MetadataPrintingProfileWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPrismaproductionUpdateInput = {
  create: MetadataPrismaProductionWithForcedPhotoCreateInput;
  update: MetadataPrismaProductionWithForcedPhotoUpdateInput;
  where: MetadataPrismaProductionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataProcesshistoryUpdateInput = {
  create: MetadataProcessHistoryWithForcedPhotoCreateInput;
  update: MetadataProcessHistoryWithForcedPhotoUpdateInput;
  where: MetadataProcessHistoryWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataProcessparameterUpdateInput = {
  create: MetadataProcessParameterWithForcedPhotoCreateInput;
  update: MetadataProcessParameterWithForcedPhotoUpdateInput;
  where: MetadataProcessParameterWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataProcessstatusUpdateInput = {
  create: MetadataProcessStatusWithForcedPhotoCreateInput;
  update: MetadataProcessStatusWithForcedPhotoUpdateInput;
  where: MetadataProcessStatusWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataProductshortnameUpdateInput = {
  create: MetadataProductShortNameWithForcedPhotoCreateInput;
  update: MetadataProductShortNameWithForcedPhotoUpdateInput;
  where: MetadataProductShortNameWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataProductsUpdateInput = {
  create: MetadataProductsWithForcedPhotoCreateInput;
  update: MetadataProductsWithForcedPhotoUpdateInput;
  where: MetadataProductsWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataProductUpdateInput = {
  create: MetadataProductWithForcedPhotoCreateInput;
  update: MetadataProductWithForcedPhotoUpdateInput;
  where: MetadataProductWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataProduitsUpdateInput = {
  create: MetadataProduitsWithForcedPhotoCreateInput;
  update: MetadataProduitsWithForcedPhotoUpdateInput;
  where: MetadataProduitsWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataProgramversionUpdateInput = {
  create: MetadataProgramVersionWithForcedPhotoCreateInput;
  update: MetadataProgramVersionWithForcedPhotoUpdateInput;
  where: MetadataProgramVersionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPropertyreleaseidUpdateInput = {
  create: MetadataPropertyReleaseIdWithForcedPhotoCreateInput;
  update: MetadataPropertyReleaseIdWithForcedPhotoUpdateInput;
  where: MetadataPropertyReleaseIdWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPropertyreleasestatusUpdateInput = {
  create: MetadataPropertyReleaseStatusWithForcedPhotoCreateInput;
  update: MetadataPropertyReleaseStatusWithForcedPhotoUpdateInput;
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPublisherUpdateInput = {
  create: MetadataPublisherWithForcedPhotoCreateInput;
  update: MetadataPublisherWithForcedPhotoUpdateInput;
  where: MetadataPublisherWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPublishingissueUpdateInput = {
  create: MetadataPublishingIssueWithForcedPhotoCreateInput;
  update: MetadataPublishingIssueWithForcedPhotoUpdateInput;
  where: MetadataPublishingIssueWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataPublishingsubjectUpdateInput = {
  create: MetadataPublishingSubjectWithForcedPhotoCreateInput;
  update: MetadataPublishingSubjectWithForcedPhotoUpdateInput;
  where: MetadataPublishingSubjectWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataQualifieduseprismabyUpdateInput = {
  create: MetadataQualifiedUsePrismaByWithForcedPhotoCreateInput;
  update: MetadataQualifiedUsePrismaByWithForcedPhotoUpdateInput;
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataQualifieduseprismadateUpdateInput = {
  create: MetadataQualifiedUsePrismaDateWithForcedPhotoCreateInput;
  update: MetadataQualifiedUsePrismaDateWithForcedPhotoUpdateInput;
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataQualifieduseprismadurationUpdateInput = {
  create: MetadataQualifiedUsePrismaDurationWithForcedPhotoCreateInput;
  update: MetadataQualifiedUsePrismaDurationWithForcedPhotoUpdateInput;
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataQualifieduseprismasupportUpdateInput = {
  create: MetadataQualifiedUsePrismaSupportWithForcedPhotoCreateInput;
  update: MetadataQualifiedUsePrismaSupportWithForcedPhotoUpdateInput;
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataRatingUpdateInput = {
  create: MetadataRatingWithForcedPhotoCreateInput;
  update: MetadataRatingWithForcedPhotoUpdateInput;
  where: MetadataRatingWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataReferencedateUpdateInput = {
  create: MetadataReferenceDateWithForcedPhotoCreateInput;
  update: MetadataReferenceDateWithForcedPhotoUpdateInput;
  where: MetadataReferenceDateWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataReferencenumberUpdateInput = {
  create: MetadataReferenceNumberWithForcedPhotoCreateInput;
  update: MetadataReferenceNumberWithForcedPhotoUpdateInput;
  where: MetadataReferenceNumberWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataReferenceserviceUpdateInput = {
  create: MetadataReferenceServiceWithForcedPhotoCreateInput;
  update: MetadataReferenceServiceWithForcedPhotoUpdateInput;
  where: MetadataReferenceServiceWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataReleasedateUpdateInput = {
  create: MetadataReleaseDateWithForcedPhotoCreateInput;
  update: MetadataReleaseDateWithForcedPhotoUpdateInput;
  where: MetadataReleaseDateWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataReleasetimeUpdateInput = {
  create: MetadataReleaseTimeWithForcedPhotoCreateInput;
  update: MetadataReleaseTimeWithForcedPhotoUpdateInput;
  where: MetadataReleaseTimeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataRequiredpermissionUpdateInput = {
  create: MetadataRequiredPermissionWithForcedPhotoCreateInput;
  update: MetadataRequiredPermissionWithForcedPhotoUpdateInput;
  where: MetadataRequiredPermissionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataResolutionkindUpdateInput = {
  create: MetadataResolutionKindWithForcedPhotoCreateInput;
  update: MetadataResolutionKindWithForcedPhotoUpdateInput;
  where: MetadataResolutionKindWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataRightsUpdateInput = {
  create: MetadataRightsWithForcedPhotoCreateInput;
  update: MetadataRightsWithForcedPhotoUpdateInput;
  where: MetadataRightsWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataRoyaltyfreeUpdateInput = {
  create: MetadataRoyaltyfreeWithForcedPhotoCreateInput;
  update: MetadataRoyaltyfreeWithForcedPhotoUpdateInput;
  where: MetadataRoyaltyfreeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSceneUpdateInput = {
  create: MetadataSceneWithForcedPhotoCreateInput;
  update: MetadataSceneWithForcedPhotoUpdateInput;
  where: MetadataSceneWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSectionUpdateInput = {
  create: MetadataSectionWithForcedPhotoCreateInput;
  update: MetadataSectionWithForcedPhotoUpdateInput;
  where: MetadataSectionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSenttoUpdateInput = {
  create: MetadataSentToWithForcedPhotoCreateInput;
  update: MetadataSentToWithForcedPhotoUpdateInput;
  where: MetadataSentToWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSerialnumberUpdateInput = {
  create: MetadataSerialNumberWithForcedPhotoCreateInput;
  update: MetadataSerialNumberWithForcedPhotoUpdateInput;
  where: MetadataSerialNumberWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSeriesdatetimeUpdateInput = {
  create: MetadataSeriesDateTimeWithForcedPhotoCreateInput;
  update: MetadataSeriesDateTimeWithForcedPhotoUpdateInput;
  where: MetadataSeriesDateTimeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSeriesdescriptionUpdateInput = {
  create: MetadataSeriesDescriptionWithForcedPhotoCreateInput;
  update: MetadataSeriesDescriptionWithForcedPhotoUpdateInput;
  where: MetadataSeriesDescriptionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSeriesmodalityUpdateInput = {
  create: MetadataSeriesModalityWithForcedPhotoCreateInput;
  update: MetadataSeriesModalityWithForcedPhotoUpdateInput;
  where: MetadataSeriesModalityWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSeriesnumberUpdateInput = {
  create: MetadataSeriesNumberWithForcedPhotoCreateInput;
  update: MetadataSeriesNumberWithForcedPhotoUpdateInput;
  where: MetadataSeriesNumberWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataShortuniqueidUpdateInput = {
  create: MetadataShortUniqueIdWithForcedPhotoCreateInput;
  update: MetadataShortUniqueIdWithForcedPhotoUpdateInput;
  where: MetadataShortUniqueIdWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSourceUpdateInput = {
  create: MetadataSourceWithForcedPhotoCreateInput;
  update: MetadataSourceWithForcedPhotoUpdateInput;
  where: MetadataSourceWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataStateUpdateInput = {
  create: MetadataStateWithForcedPhotoCreateInput;
  update: MetadataStateWithForcedPhotoUpdateInput;
  where: MetadataStateWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataStudydatetimeUpdateInput = {
  create: MetadataStudyDateTimeWithForcedPhotoCreateInput;
  update: MetadataStudyDateTimeWithForcedPhotoUpdateInput;
  where: MetadataStudyDateTimeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataStudydescriptionUpdateInput = {
  create: MetadataStudyDescriptionWithForcedPhotoCreateInput;
  update: MetadataStudyDescriptionWithForcedPhotoUpdateInput;
  where: MetadataStudyDescriptionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataStudyidUpdateInput = {
  create: MetadataStudyIdWithForcedPhotoCreateInput;
  update: MetadataStudyIdWithForcedPhotoUpdateInput;
  where: MetadataStudyIdWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataStudyphysicianUpdateInput = {
  create: MetadataStudyPhysicianWithForcedPhotoCreateInput;
  update: MetadataStudyPhysicianWithForcedPhotoUpdateInput;
  where: MetadataStudyPhysicianWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSubjectcodeUpdateInput = {
  create: MetadataSubjectCodeWithForcedPhotoCreateInput;
  update: MetadataSubjectCodeWithForcedPhotoUpdateInput;
  where: MetadataSubjectCodeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSubjectprismaUpdateInput = {
  create: MetadataSubjectPrismaWithForcedPhotoCreateInput;
  update: MetadataSubjectPrismaWithForcedPhotoUpdateInput;
  where: MetadataSubjectPrismaWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSubjectUpdateInput = {
  create: MetadataSubjectWithForcedPhotoCreateInput;
  update: MetadataSubjectWithForcedPhotoUpdateInput;
  where: MetadataSubjectWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataSupplementalcategoriesUpdateInput = {
  create: MetadataSupplementalCategoriesWithForcedPhotoCreateInput;
  update: MetadataSupplementalCategoriesWithForcedPhotoUpdateInput;
  where: MetadataSupplementalCategoriesWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataTargetversionUpdateInput = {
  create: MetadataTargetVersionWithForcedPhotoCreateInput;
  update: MetadataTargetVersionWithForcedPhotoUpdateInput;
  where: MetadataTargetVersionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataTitleUpdateInput = {
  create: MetadataTitleWithForcedPhotoCreateInput;
  update: MetadataTitleWithForcedPhotoUpdateInput;
  where: MetadataTitleWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataTonecurveUpdateInput = {
  create: MetadataToneCurveWithForcedPhotoCreateInput;
  update: MetadataToneCurveWithForcedPhotoUpdateInput;
  where: MetadataToneCurveWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataTransferredbyemailUpdateInput = {
  create: MetadataTransferredByEmailWithForcedPhotoCreateInput;
  update: MetadataTransferredByEmailWithForcedPhotoUpdateInput;
  where: MetadataTransferredByEmailWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataTransferredbyfullnameUpdateInput = {
  create: MetadataTransferredByFullNameWithForcedPhotoCreateInput;
  update: MetadataTransferredByFullNameWithForcedPhotoUpdateInput;
  where: MetadataTransferredByFullNameWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataTransferredbyUpdateInput = {
  create: MetadataTransferredByWithForcedPhotoCreateInput;
  update: MetadataTransferredByWithForcedPhotoUpdateInput;
  where: MetadataTransferredByWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataTransmissionreferenceUpdateInput = {
  create: MetadataTransmissionReferenceWithForcedPhotoCreateInput;
  update: MetadataTransmissionReferenceWithForcedPhotoUpdateInput;
  where: MetadataTransmissionReferenceWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataTypeUpdateInput = {
  create: MetadataTypeWithForcedPhotoCreateInput;
  update: MetadataTypeWithForcedPhotoUpdateInput;
  where: MetadataTypeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUndersubjectUpdateInput = {
  create: MetadataUndersubjectWithForcedPhotoCreateInput;
  update: MetadataUndersubjectWithForcedPhotoUpdateInput;
  where: MetadataUndersubjectWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUnderundersubjectUpdateInput = {
  create: MetadataUnderUnderSubjectWithForcedPhotoCreateInput;
  update: MetadataUnderUnderSubjectWithForcedPhotoUpdateInput;
  where: MetadataUnderUnderSubjectWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUniqueidUpdateInput = {
  create: MetadataUniqueIdWithForcedPhotoCreateInput;
  update: MetadataUniqueIdWithForcedPhotoUpdateInput;
  where: MetadataUniqueIdWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUnitshortnameUpdateInput = {
  create: MetadataUnitShortNameWithForcedPhotoCreateInput;
  update: MetadataUnitShortNameWithForcedPhotoUpdateInput;
  where: MetadataUnitShortNameWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUnitUpdateInput = {
  create: MetadataUnitWithForcedPhotoCreateInput;
  update: MetadataUnitWithForcedPhotoUpdateInput;
  where: MetadataUnitWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUploadedbyfullnameUpdateInput = {
  create: MetadataUploadedByFullNameWithForcedPhotoCreateInput;
  update: MetadataUploadedByFullNameWithForcedPhotoUpdateInput;
  where: MetadataUploadedByFullNameWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUploadedbyUpdateInput = {
  create: MetadataUploadedByWithForcedPhotoCreateInput;
  update: MetadataUploadedByWithForcedPhotoUpdateInput;
  where: MetadataUploadedByWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUploadtimeUpdateInput = {
  create: MetadataUploadTimeWithForcedPhotoCreateInput;
  update: MetadataUploadTimeWithForcedPhotoUpdateInput;
  where: MetadataUploadTimeWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUrgencyUpdateInput = {
  create: MetadataUrgencyWithForcedPhotoCreateInput;
  update: MetadataUrgencyWithForcedPhotoUpdateInput;
  where: MetadataUrgencyWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUsagetermsUpdateInput = {
  create: MetadataUsageTermsWithForcedPhotoCreateInput;
  update: MetadataUsageTermsWithForcedPhotoUpdateInput;
  where: MetadataUsageTermsWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUsercommentUpdateInput = {
  create: MetadataUserCommentWithForcedPhotoCreateInput;
  update: MetadataUserCommentWithForcedPhotoUpdateInput;
  where: MetadataUserCommentWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUserdefined195UpdateInput = {
  create: MetadataUserDefined195WithForcedPhotoCreateInput;
  update: MetadataUserDefined195WithForcedPhotoUpdateInput;
  where: MetadataUserDefined195WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUserdefined237UpdateInput = {
  create: MetadataUserDefined237WithForcedPhotoCreateInput;
  update: MetadataUserDefined237WithForcedPhotoUpdateInput;
  where: MetadataUserDefined237WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUserdefined238UpdateInput = {
  create: MetadataUserDefined238WithForcedPhotoCreateInput;
  update: MetadataUserDefined238WithForcedPhotoUpdateInput;
  where: MetadataUserDefined238WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUserdefined239UpdateInput = {
  create: MetadataUserDefined239WithForcedPhotoCreateInput;
  update: MetadataUserDefined239WithForcedPhotoUpdateInput;
  where: MetadataUserDefined239WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUserdefined242UpdateInput = {
  create: MetadataUserDefined242WithForcedPhotoCreateInput;
  update: MetadataUserDefined242WithForcedPhotoUpdateInput;
  where: MetadataUserDefined242WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataUserdefined62UpdateInput = {
  create: MetadataUserDefined62WithForcedPhotoCreateInput;
  update: MetadataUserDefined62WithForcedPhotoUpdateInput;
  where: MetadataUserDefined62WhereUniqueInput;
};

export type PhotoNestedUpsertMetadataValidUpdateInput = {
  create: MetadataValidWithForcedPhotoCreateInput;
  update: MetadataValidWithForcedPhotoUpdateInput;
  where: MetadataValidWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataVersionUpdateInput = {
  create: MetadataVersionWithForcedPhotoCreateInput;
  update: MetadataVersionWithForcedPhotoUpdateInput;
  where: MetadataVersionWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataWebstatementUpdateInput = {
  create: MetadataWebStatementWithForcedPhotoCreateInput;
  update: MetadataWebStatementWithForcedPhotoUpdateInput;
  where: MetadataWebStatementWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataWorkflowkindUpdateInput = {
  create: MetadataWorkflowKindWithForcedPhotoCreateInput;
  update: MetadataWorkflowKindWithForcedPhotoUpdateInput;
  where: MetadataWorkflowKindWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataXmpfilestampsUpdateInput = {
  create: MetadataXmpFileStampsWithForcedPhotoCreateInput;
  update: MetadataXmpFileStampsWithForcedPhotoUpdateInput;
  where: MetadataXmpFileStampsWhereUniqueInput;
};

export type PhotoNestedUpsertMetadataXmphistoryUpdateInput = {
  create: MetadataXmpHistoryWithForcedPhotoCreateInput;
  update: MetadataXmpHistoryWithForcedPhotoUpdateInput;
  where: MetadataXmpHistoryWhereUniqueInput;
};

export type PhotoNestedUpsertPhotoMetadatasUpdateInput = {
  create: PhotoMetadataWithForcedPhotoCreateInput;
  update: PhotoMetadataWithForcedPhotoUpdateInput;
  where: PhotoMetadataWhereUniqueInput;
};

export type PhotoNestedUpsertPhotoPublicationsUpdateInput = {
  create: PhotoPublicationWithForcedPhotoCreateInput;
  update: PhotoPublicationWithForcedPhotoUpdateInput;
  where: PhotoPublicationWhereUniqueInput;
};

export enum PhotoOrderByInput {
  _id_ASC = '_id_ASC',
  _id_DESC = '_id_DESC',
  contentType_ASC = 'contentType_ASC',
  contentType_DESC = 'contentType_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  filesize_ASC = 'filesize_ASC',
  filesize_DESC = 'filesize_DESC',
  height_ASC = 'height_ASC',
  height_DESC = 'height_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  importedAt_ASC = 'importedAt_ASC',
  importedAt_DESC = 'importedAt_DESC',
  isLegacy_ASC = 'isLegacy_ASC',
  isLegacy_DESC = 'isLegacy_DESC',
  isUploaded_ASC = 'isUploaded_ASC',
  isUploaded_DESC = 'isUploaded_DESC',
  originalName_ASC = 'originalName_ASC',
  originalName_DESC = 'originalName_DESC',
  synced_ASC = 'synced_ASC',
  synced_DESC = 'synced_DESC',
  takenAt_ASC = 'takenAt_ASC',
  takenAt_DESC = 'takenAt_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC',
  width_ASC = 'width_ASC',
  width_DESC = 'width_DESC'
}

/** "PhotoPublication" resource's node */
export type PhotoPublication = {
  __typename?: 'PhotoPublication';
  /** The internal ID */
  _id: Scalars['Int']['output'];
  /** get the description of the photoPublication */
  caption: Scalars['String']['output'];
  /** get the rights of the photoPublication */
  copyright: Scalars['String']['output'];
  /** The date, fixed, of the document's creation */
  createdAt: Scalars['DateTime']['output'];
  credit?: Maybe<Scalars['String']['output']>;
  dimensions?: Maybe<Scalars['String']['output']>;
  filename?: Maybe<Scalars['String']['output']>;
  /** get the idPhotoPrisma of the photoPublication */
  fileName: Scalars['String']['output'];
  /** The public ID, an UUID v4 */
  id: Scalars['ID']['output'];
  /** get the originalName of the photoPublication */
  originalName?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  page?: Maybe<Scalars['Int']['output']>;
  photo: Photo;
  /** The photo internal ID */
  photoIntId?: Maybe<Scalars['Int']['output']>;
  /** get the priceLevel of the photoPublication */
  priceLevel: Scalars['String']['output'];
  printIssueTitle?: Maybe<Scalars['String']['output']>;
  printPublicationKey?: Maybe<Scalars['String']['output']>;
  /** get the source of the photoPublication */
  source: Scalars['String']['output'];
  /** The date of the document's last update */
  updatedAt: Scalars['DateTime']['output'];
};

export type PhotoPublicationCreateInput = {
  credit?: InputMaybe<Scalars['String']['input']>;
  dimensions?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Actions for the "PhotoPublication.photo" relation */
  photo: PhotoPublicationNestedPhotoCreateInput;
  /** The photo internal ID */
  photoIntId?: InputMaybe<Scalars['Int']['input']>;
  printIssueTitle?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoPublicationNestedPhotoCreateInput = {
  /** Connect an existing "Photo" node to the new "PhotoPublication" node, through the "PhotoPublication.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the new "PhotoPublication" node, through the "PhotoPublication.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the new "PhotoPublication" node, through the "PhotoPublication.photo" relation. */
  update?: InputMaybe<PhotoPublicationNestedUpdatePhotoCreateInput>;
  /** Create or update a "Photo" node and connect it to the new "PhotoPublication" node, through the "PhotoPublication.photo" relation. */
  upsert?: InputMaybe<PhotoPublicationNestedUpsertPhotoCreateInput>;
};

export type PhotoPublicationNestedPhotoUpdateInput = {
  /** Connect an existing "Photo" node to the existing "PhotoPublication" node, through the "PhotoPublication.photo" relation. */
  connect?: InputMaybe<PhotoWhereUniqueInput>;
  /** Create a new "Photo" node and connect it to the existing "PhotoPublication" node, through the "PhotoPublication.photo" relation. */
  create?: InputMaybe<PhotoCreateInput>;
  /** Update an existing "Photo" node and connect it to the existing "PhotoPublication" node, through the "PhotoPublication.photo" relation. */
  update?: InputMaybe<PhotoPublicationNestedUpdatePhotoUpdateInput>;
  /** Create or update a "Photo" node and connect it to the existing "PhotoPublication" node, through the "PhotoPublication.photo" relation. */
  upsert?: InputMaybe<PhotoPublicationNestedUpsertPhotoUpdateInput>;
};

export type PhotoPublicationNestedUpdatePhotoCreateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type PhotoPublicationNestedUpdatePhotoUpdateInput = {
  data: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type PhotoPublicationNestedUpsertPhotoCreateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export type PhotoPublicationNestedUpsertPhotoUpdateInput = {
  create: PhotoCreateInput;
  update: PhotoUpdateInput;
  where: PhotoWhereUniqueInput;
};

export enum PhotoPublicationOrderByInput {
  _id_ASC = '_id_ASC',
  _id_DESC = '_id_DESC',
  createdAt_ASC = 'createdAt_ASC',
  createdAt_DESC = 'createdAt_DESC',
  credit_ASC = 'credit_ASC',
  credit_DESC = 'credit_DESC',
  dimensions_ASC = 'dimensions_ASC',
  dimensions_DESC = 'dimensions_DESC',
  filename_ASC = 'filename_ASC',
  filename_DESC = 'filename_DESC',
  id_ASC = 'id_ASC',
  id_DESC = 'id_DESC',
  page_ASC = 'page_ASC',
  page_DESC = 'page_DESC',
  photoIntId_ASC = 'photoIntId_ASC',
  photoIntId_DESC = 'photoIntId_DESC',
  printIssueTitle_ASC = 'printIssueTitle_ASC',
  printIssueTitle_DESC = 'printIssueTitle_DESC',
  printPublicationKey_ASC = 'printPublicationKey_ASC',
  printPublicationKey_DESC = 'printPublicationKey_DESC',
  updatedAt_ASC = 'updatedAt_ASC',
  updatedAt_DESC = 'updatedAt_DESC'
}

export type PhotoPublicationUpdateInput = {
  credit?: InputMaybe<Scalars['String']['input']>;
  dimensions?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  /** Actions for the "PhotoPublication.photo" relation */
  photo?: InputMaybe<PhotoPublicationNestedPhotoUpdateInput>;
  /** The photo internal ID */
  photoIntId?: InputMaybe<Scalars['Int']['input']>;
  printIssueTitle?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey?: InputMaybe<Scalars['String']['input']>;
};

/** Filters the "PhotoPublication" nodes by specifying some conditions */
export type PhotoPublicationWhereInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  _id_gt?: InputMaybe<Scalars['Int']['input']>;
  _id_gte?: InputMaybe<Scalars['Int']['input']>;
  _id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _id_lt?: InputMaybe<Scalars['Int']['input']>;
  _id_lte?: InputMaybe<Scalars['Int']['input']>;
  _id_not?: InputMaybe<Scalars['Int']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  AND?: InputMaybe<Array<PhotoPublicationWhereInput>>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  credit?: InputMaybe<Scalars['String']['input']>;
  credit_contains?: InputMaybe<Scalars['String']['input']>;
  credit_ends_with?: InputMaybe<Scalars['String']['input']>;
  credit_gt?: InputMaybe<Scalars['String']['input']>;
  credit_gte?: InputMaybe<Scalars['String']['input']>;
  credit_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  credit_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  credit_lt?: InputMaybe<Scalars['String']['input']>;
  credit_lte?: InputMaybe<Scalars['String']['input']>;
  credit_not?: InputMaybe<Scalars['String']['input']>;
  credit_not_contains?: InputMaybe<Scalars['String']['input']>;
  credit_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  credit_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  credit_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  credit_starts_with?: InputMaybe<Scalars['String']['input']>;
  dimensions?: InputMaybe<Scalars['String']['input']>;
  dimensions_contains?: InputMaybe<Scalars['String']['input']>;
  dimensions_ends_with?: InputMaybe<Scalars['String']['input']>;
  dimensions_gt?: InputMaybe<Scalars['String']['input']>;
  dimensions_gte?: InputMaybe<Scalars['String']['input']>;
  dimensions_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dimensions_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  dimensions_lt?: InputMaybe<Scalars['String']['input']>;
  dimensions_lte?: InputMaybe<Scalars['String']['input']>;
  dimensions_not?: InputMaybe<Scalars['String']['input']>;
  dimensions_not_contains?: InputMaybe<Scalars['String']['input']>;
  dimensions_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  dimensions_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  dimensions_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  dimensions_starts_with?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  filename_contains?: InputMaybe<Scalars['String']['input']>;
  filename_ends_with?: InputMaybe<Scalars['String']['input']>;
  filename_gt?: InputMaybe<Scalars['String']['input']>;
  filename_gte?: InputMaybe<Scalars['String']['input']>;
  filename_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  filename_lt?: InputMaybe<Scalars['String']['input']>;
  filename_lte?: InputMaybe<Scalars['String']['input']>;
  filename_not?: InputMaybe<Scalars['String']['input']>;
  filename_not_contains?: InputMaybe<Scalars['String']['input']>;
  filename_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  filename_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filename_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  filename_starts_with?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  NOT?: InputMaybe<PhotoPublicationWhereInput>;
  OR?: InputMaybe<Array<PhotoPublicationWhereInput>>;
  page?: InputMaybe<Scalars['Int']['input']>;
  page_gt?: InputMaybe<Scalars['Int']['input']>;
  page_gte?: InputMaybe<Scalars['Int']['input']>;
  page_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  page_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  page_lt?: InputMaybe<Scalars['Int']['input']>;
  page_lte?: InputMaybe<Scalars['Int']['input']>;
  page_not?: InputMaybe<Scalars['Int']['input']>;
  page_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  photo?: InputMaybe<PhotoWhereInput>;
  photoIntId?: InputMaybe<Scalars['Int']['input']>;
  photoIntId_gt?: InputMaybe<Scalars['Int']['input']>;
  photoIntId_gte?: InputMaybe<Scalars['Int']['input']>;
  photoIntId_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  photoIntId_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  photoIntId_lt?: InputMaybe<Scalars['Int']['input']>;
  photoIntId_lte?: InputMaybe<Scalars['Int']['input']>;
  photoIntId_not?: InputMaybe<Scalars['Int']['input']>;
  photoIntId_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  printIssueTitle?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_contains?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_ends_with?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_gt?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_gte?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  printIssueTitle_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  printIssueTitle_lt?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_lte?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_not?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_not_contains?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  printIssueTitle_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  printIssueTitle_starts_with?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_contains?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_ends_with?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_gt?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_gte?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  printPublicationKey_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  printPublicationKey_lt?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_lte?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_not?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_not_contains?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  printPublicationKey_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey_starts_with?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

/** Identifies exactly one "PhotoPublication" node. */
export type PhotoPublicationWhereUniqueInput = {
  _id: Scalars['Int']['input'];
};

export type PhotoPublicationWithForcedPhotoCreateInput = {
  credit?: InputMaybe<Scalars['String']['input']>;
  dimensions?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  /** The photo internal ID */
  photoIntId?: InputMaybe<Scalars['Int']['input']>;
  printIssueTitle?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoPublicationWithForcedPhotoUpdateInput = {
  credit?: InputMaybe<Scalars['String']['input']>;
  dimensions?: InputMaybe<Scalars['String']['input']>;
  filename?: InputMaybe<Scalars['String']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  /** The photo internal ID */
  photoIntId?: InputMaybe<Scalars['Int']['input']>;
  printIssueTitle?: InputMaybe<Scalars['String']['input']>;
  printPublicationKey?: InputMaybe<Scalars['String']['input']>;
};

export type PhotoUpdateInput = {
  /** Actions for the "Photo.agency" relation */
  agency?: InputMaybe<PhotoNestedAgencyUpdateInput>;
  /** Actions for the "Photo.archive" relation */
  archive?: InputMaybe<PhotoNestedArchiveUpdateInput>;
  /** Actions for the "Photo.category" relation */
  category?: InputMaybe<PhotoNestedCategoryUpdateInput>;
  /** asset content type */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.deliverySubject" relation */
  deliverySubject?: InputMaybe<PhotoNestedDeliverySubjectUpdateInput>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The date of the photo's importation */
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Actions for the "Photo.importQueue" relation: "ImportQueue.photo"'s inverse relation */
  importQueue?: InputMaybe<PhotoNestedImportQueueUpdateInput>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** Actions for the "Photo.Metadata_AccessRights" relation: "MetadataAccessRights.photo"'s inverse relation */
  Metadata_AccessRights?: InputMaybe<PhotoNestedMetadataAccessrightsUpdateInput>;
  /** Actions for the "Photo.Metadata_AddlModelInfo" relation: "MetadataAddlModelInfo.photo"'s inverse relation */
  Metadata_AddlModelInfo?: InputMaybe<PhotoNestedMetadataAddlmodelinfoUpdateInput>;
  /** Actions for the "Photo.Metadata_Archive_child" relation: "MetadataArchiveChild.photo"'s inverse relation */
  Metadata_Archive_child?: InputMaybe<PhotoNestedMetadataArchiveChildUpdateInput>;
  /** Actions for the "Photo.Metadata_Archive_parent" relation: "MetadataArchiveParent.photo"'s inverse relation */
  Metadata_Archive_parent?: InputMaybe<PhotoNestedMetadataArchiveParentUpdateInput>;
  /** Actions for the "Photo.Metadata_AssetState" relation: "MetadataAssetState.photo"'s inverse relation */
  Metadata_AssetState?: InputMaybe<PhotoNestedMetadataAssetstateUpdateInput>;
  /** Actions for the "Photo.Metadata_attributionName" relation: "MetadataAttributionName.photo"'s inverse relation */
  Metadata_attributionName?: InputMaybe<PhotoNestedMetadataAttributionnameUpdateInput>;
  /** Actions for the "Photo.Metadata_attributionURL" relation: "MetadataAttributionUrl.photo"'s inverse relation */
  Metadata_attributionURL?: InputMaybe<PhotoNestedMetadataAttributionurlUpdateInput>;
  /** Actions for the "Photo.Metadata_Audience" relation: "MetadataAudience.photo"'s inverse relation */
  Metadata_Audience?: InputMaybe<PhotoNestedMetadataAudienceUpdateInput>;
  /** Actions for the "Photo.Metadata_AuthorsPosition" relation: "MetadataAuthorsPosition.photo"'s inverse relation */
  Metadata_AuthorsPosition?: InputMaybe<PhotoNestedMetadataAuthorspositionUpdateInput>;
  /** Actions for the "Photo.Metadata_BackupName" relation: "MetadataBackupName.photo"'s inverse relation */
  Metadata_BackupName?: InputMaybe<PhotoNestedMetadataBackupnameUpdateInput>;
  /** Actions for the "Photo.Metadata_BitmapGrayscalePicture" relation: "MetadataBitmapGrayscalePicture.photo"'s inverse relation */
  Metadata_BitmapGrayscalePicture?: InputMaybe<PhotoNestedMetadataBitmapgrayscalepictureUpdateInput>;
  /** Actions for the "Photo.Metadata_Brand" relation: "MetadataBrand.photo"'s inverse relation */
  Metadata_Brand?: InputMaybe<PhotoNestedMetadataBrandUpdateInput>;
  /** Actions for the "Photo.Metadata_BrandPrisma" relation: "MetadataBrandPrisma.photo"'s inverse relation */
  Metadata_BrandPrisma?: InputMaybe<PhotoNestedMetadataBrandprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_BrandShortName" relation: "MetadataBrandShortName.photo"'s inverse relation */
  Metadata_BrandShortName?: InputMaybe<PhotoNestedMetadataBrandshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_CaptionWriter" relation: "MetadataCaptionWriter.photo"'s inverse relation */
  Metadata_CaptionWriter?: InputMaybe<PhotoNestedMetadataCaptionwriterUpdateInput>;
  /** Actions for the "Photo.Metadata_Category" relation: "MetadataCategory.photo"'s inverse relation */
  Metadata_Category?: InputMaybe<PhotoNestedMetadataCategoryUpdateInput>;
  /** Actions for the "Photo.Metadata_CategoryPrisma" relation: "MetadataCategoryPrisma.photo"'s inverse relation */
  Metadata_CategoryPrisma?: InputMaybe<PhotoNestedMetadataCategoryprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_Certificate" relation: "MetadataCertificate.photo"'s inverse relation */
  Metadata_Certificate?: InputMaybe<PhotoNestedMetadataCertificateUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCity" relation: "MetadataCiAdrCity.photo"'s inverse relation */
  Metadata_CiAdrCity?: InputMaybe<PhotoNestedMetadataCiadrcityUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCtry" relation: "MetadataCiAdrCtry.photo"'s inverse relation */
  Metadata_CiAdrCtry?: InputMaybe<PhotoNestedMetadataCiadrctryUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrExtadr" relation: "MetadataCiAdrExtadr.photo"'s inverse relation */
  Metadata_CiAdrExtadr?: InputMaybe<PhotoNestedMetadataCiadrextadrUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrPcode" relation: "MetadataCiAdrPcode.photo"'s inverse relation */
  Metadata_CiAdrPcode?: InputMaybe<PhotoNestedMetadataCiadrpcodeUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrRegion" relation: "MetadataCiAdrRegion.photo"'s inverse relation */
  Metadata_CiAdrRegion?: InputMaybe<PhotoNestedMetadataCiadrregionUpdateInput>;
  /** Actions for the "Photo.Metadata_CiEmailWork" relation: "MetadataCiEmailWork.photo"'s inverse relation */
  Metadata_CiEmailWork?: InputMaybe<PhotoNestedMetadataCiemailworkUpdateInput>;
  /** Actions for the "Photo.Metadata_CiTelWork" relation: "MetadataCiTelWork.photo"'s inverse relation */
  Metadata_CiTelWork?: InputMaybe<PhotoNestedMetadataCitelworkUpdateInput>;
  /** Actions for the "Photo.Metadata_City" relation: "MetadataCity.photo"'s inverse relation */
  Metadata_City?: InputMaybe<PhotoNestedMetadataCityUpdateInput>;
  /** Actions for the "Photo.Metadata_CiUrlWork" relation: "MetadataCiUrlWork.photo"'s inverse relation */
  Metadata_CiUrlWork?: InputMaybe<PhotoNestedMetadataCiurlworkUpdateInput>;
  /** Actions for the "Photo.Metadata_Classify" relation: "MetadataClassify.photo"'s inverse relation */
  Metadata_Classify?: InputMaybe<PhotoNestedMetadataClassifyUpdateInput>;
  /** Actions for the "Photo.Metadata_Company" relation: "MetadataCompany.photo"'s inverse relation */
  Metadata_Company?: InputMaybe<PhotoNestedMetadataCompanyUpdateInput>;
  /** Actions for the "Photo.Metadata_CompanyShortName" relation: "MetadataCompanyShortName.photo"'s inverse relation */
  Metadata_CompanyShortName?: InputMaybe<PhotoNestedMetadataCompanyshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_Confidentiality" relation: "MetadataConfidentiality.photo"'s inverse relation */
  Metadata_Confidentiality?: InputMaybe<PhotoNestedMetadataConfidentialityUpdateInput>;
  /** Actions for the "Photo.Metadata_ContainedIn" relation: "MetadataContainedIn.photo"'s inverse relation */
  Metadata_ContainedIn?: InputMaybe<PhotoNestedMetadataContainedinUpdateInput>;
  /** Actions for the "Photo.Metadata_ContentMediaKind" relation: "MetadataContentMediaKind.photo"'s inverse relation */
  Metadata_ContentMediaKind?: InputMaybe<PhotoNestedMetadataContentmediakindUpdateInput>;
  /** Actions for the "Photo.Metadata_ContentValue" relation: "MetadataContentValue.photo"'s inverse relation */
  Metadata_ContentValue?: InputMaybe<PhotoNestedMetadataContentvalueUpdateInput>;
  /** Actions for the "Photo.Metadata_CopyrightLayer" relation: "MetadataCopyrightLayer.photo"'s inverse relation */
  Metadata_CopyrightLayer?: InputMaybe<PhotoNestedMetadataCopyrightlayerUpdateInput>;
  /** Actions for the "Photo.Metadata_Country" relation: "MetadataCountry.photo"'s inverse relation */
  Metadata_Country?: InputMaybe<PhotoNestedMetadataCountryUpdateInput>;
  /** Actions for the "Photo.Metadata_CountryCode" relation: "MetadataCountryCode.photo"'s inverse relation */
  Metadata_CountryCode?: InputMaybe<PhotoNestedMetadataCountrycodeUpdateInput>;
  /** Actions for the "Photo.Metadata_CreatedTime" relation: "MetadataCreatedTime.photo"'s inverse relation */
  Metadata_CreatedTime?: InputMaybe<PhotoNestedMetadataCreatedtimeUpdateInput>;
  /** Actions for the "Photo.Metadata_creator" relation: "MetadataCreator.photo"'s inverse relation */
  Metadata_creator?: InputMaybe<PhotoNestedMetadataCreatorUpdateInput>;
  /** Actions for the "Photo.Metadata_CreatorTool" relation: "MetadataCreatorTool.photo"'s inverse relation */
  Metadata_CreatorTool?: InputMaybe<PhotoNestedMetadataCreatortoolUpdateInput>;
  /** Actions for the "Photo.Metadata_Credit" relation: "MetadataCredit.photo"'s inverse relation */
  Metadata_Credit?: InputMaybe<PhotoNestedMetadataCreditUpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField14" relation: "MetadataCustomField14.photo"'s inverse relation */
  Metadata_CustomField14?: InputMaybe<PhotoNestedMetadataCustomfield14UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField15" relation: "MetadataCustomField15.photo"'s inverse relation */
  Metadata_CustomField15?: InputMaybe<PhotoNestedMetadataCustomfield15UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField16" relation: "MetadataCustomField16.photo"'s inverse relation */
  Metadata_CustomField16?: InputMaybe<PhotoNestedMetadataCustomfield16UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField17" relation: "MetadataCustomField17.photo"'s inverse relation */
  Metadata_CustomField17?: InputMaybe<PhotoNestedMetadataCustomfield17UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField20" relation: "MetadataCustomField20.photo"'s inverse relation */
  Metadata_CustomField20?: InputMaybe<PhotoNestedMetadataCustomfield20UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField5" relation: "MetadataCustomField5.photo"'s inverse relation */
  Metadata_CustomField5?: InputMaybe<PhotoNestedMetadataCustomfield5UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField6" relation: "MetadataCustomField6.photo"'s inverse relation */
  Metadata_CustomField6?: InputMaybe<PhotoNestedMetadataCustomfield6UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField7" relation: "MetadataCustomField7.photo"'s inverse relation */
  Metadata_CustomField7?: InputMaybe<PhotoNestedMetadataCustomfield7UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField9" relation: "MetadataCustomField9.photo"'s inverse relation */
  Metadata_CustomField9?: InputMaybe<PhotoNestedMetadataCustomfield9UpdateInput>;
  /** Actions for the "Photo.Metadata_DateCreated" relation: "MetadataDateCreated.photo"'s inverse relation */
  Metadata_DateCreated?: InputMaybe<PhotoNestedMetadataDatecreatedUpdateInput>;
  /** Actions for the "Photo.Metadata_DateTimeDigitized" relation: "MetadataDateTimeDigitized.photo"'s inverse relation */
  Metadata_DateTimeDigitized?: InputMaybe<PhotoNestedMetadataDatetimedigitizedUpdateInput>;
  /** Actions for the "Photo.Metadata_DateTimeOriginal" relation: "MetadataDateTimeOriginal.photo"'s inverse relation */
  Metadata_DateTimeOriginal?: InputMaybe<PhotoNestedMetadataDatetimeoriginalUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryAccount" relation: "MetadataDeliveryAccount.photo"'s inverse relation */
  Metadata_DeliveryAccount?: InputMaybe<PhotoNestedMetadataDeliveryaccountUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCompany" relation: "MetadataDeliveryCompany.photo"'s inverse relation */
  Metadata_DeliveryCompany?: InputMaybe<PhotoNestedMetadataDeliverycompanyUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCopyright" relation: "MetadataDeliveryCopyright.photo"'s inverse relation */
  Metadata_DeliveryCopyright?: InputMaybe<PhotoNestedMetadataDeliverycopyrightUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateFolder" relation: "MetadataDeliveryDateFolder.photo"'s inverse relation */
  Metadata_DeliveryDateFolder?: InputMaybe<PhotoNestedMetadataDeliverydatefolderUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateTime" relation: "MetadataDeliveryDateTime.photo"'s inverse relation */
  Metadata_DeliveryDateTime?: InputMaybe<PhotoNestedMetadataDeliverydatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcCreator" relation: "MetadataDeliveryDcCreator.photo"'s inverse relation */
  Metadata_DeliveryDcCreator?: InputMaybe<PhotoNestedMetadataDeliverydccreatorUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcRights" relation: "MetadataDeliveryDcRights.photo"'s inverse relation */
  Metadata_DeliveryDcRights?: InputMaybe<PhotoNestedMetadataDeliverydcrightsUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFileType" relation: "MetadataDeliveryFileType.photo"'s inverse relation */
  Metadata_DeliveryFileType?: InputMaybe<PhotoNestedMetadataDeliveryfiletypeUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFolder" relation: "MetadataDeliveryFolder.photo"'s inverse relation */
  Metadata_DeliveryFolder?: InputMaybe<PhotoNestedMetadataDeliveryfolderUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryKind" relation: "MetadataDeliveryKind.photo"'s inverse relation */
  Metadata_DeliveryKind?: InputMaybe<PhotoNestedMetadataDeliverykindUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPath" relation: "MetadataDeliveryPath.photo"'s inverse relation */
  Metadata_DeliveryPath?: InputMaybe<PhotoNestedMetadataDeliverypathUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPersonShownintheImage" relation: "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation */
  Metadata_DeliveryPersonShownintheImage?: InputMaybe<PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopCredit" relation: "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopCredit?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopSource" relation: "MetadataDeliveryPhotoshopSource.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopSource?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryService" relation: "MetadataDeliveryService.photo"'s inverse relation */
  Metadata_DeliveryService?: InputMaybe<PhotoNestedMetadataDeliveryserviceUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliverySubject" relation: "MetadataDeliverySubject.photo"'s inverse relation */
  Metadata_DeliverySubject?: InputMaybe<PhotoNestedMetadataDeliverysubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryUnderSubject" relation: "MetadataDeliveryUnderSubject.photo"'s inverse relation */
  Metadata_DeliveryUnderSubject?: InputMaybe<PhotoNestedMetadataDeliveryundersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_Department" relation: "MetadataDepartment.photo"'s inverse relation */
  Metadata_Department?: InputMaybe<PhotoNestedMetadataDepartmentUpdateInput>;
  /** Actions for the "Photo.Metadata_description" relation: "MetadataDescription.photo"'s inverse relation */
  Metadata_description?: InputMaybe<PhotoNestedMetadataDescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_DigitalAssetURL" relation: "MetadataDigitalAssetUrl.photo"'s inverse relation */
  Metadata_DigitalAssetURL?: InputMaybe<PhotoNestedMetadataDigitalasseturlUpdateInput>;
  /** Actions for the "Photo.Metadata_Division" relation: "MetadataDivision.photo"'s inverse relation */
  Metadata_Division?: InputMaybe<PhotoNestedMetadataDivisionUpdateInput>;
  /** Actions for the "Photo.Metadata_DocumentText" relation: "MetadataDocumentText.photo"'s inverse relation */
  Metadata_DocumentText?: InputMaybe<PhotoNestedMetadataDocumenttextUpdateInput>;
  /** Actions for the "Photo.Metadata_Edition" relation: "MetadataEdition.photo"'s inverse relation */
  Metadata_Edition?: InputMaybe<PhotoNestedMetadataEditionUpdateInput>;
  /** Actions for the "Photo.Metadata_EditorialVersion" relation: "MetadataEditorialVersion.photo"'s inverse relation */
  Metadata_EditorialVersion?: InputMaybe<PhotoNestedMetadataEditorialversionUpdateInput>;
  /** Actions for the "Photo.Metadata_EditStatus" relation: "MetadataEditStatus.photo"'s inverse relation */
  Metadata_EditStatus?: InputMaybe<PhotoNestedMetadataEditstatusUpdateInput>;
  /** Actions for the "Photo.Metadata_EnvironnementPhoto" relation: "MetadataEnvironnementPhoto.photo"'s inverse relation */
  Metadata_EnvironnementPhoto?: InputMaybe<PhotoNestedMetadataEnvironnementphotoUpdateInput>;
  /** Actions for the "Photo.Metadata_EquipmentInstitution" relation: "MetadataEquipmentInstitution.photo"'s inverse relation */
  Metadata_EquipmentInstitution?: InputMaybe<PhotoNestedMetadataEquipmentinstitutionUpdateInput>;
  /** Actions for the "Photo.Metadata_EquipmentManufacturer" relation: "MetadataEquipmentManufacturer.photo"'s inverse relation */
  Metadata_EquipmentManufacturer?: InputMaybe<PhotoNestedMetadataEquipmentmanufacturerUpdateInput>;
  /** Actions for the "Photo.Metadata_Event" relation: "MetadataEvent.photo"'s inverse relation */
  Metadata_Event?: InputMaybe<PhotoNestedMetadataEventUpdateInput>;
  /** Actions for the "Photo.Metadata_Exif" relation: "MetadataExif.photo"'s inverse relation */
  Metadata_Exif?: InputMaybe<PhotoNestedMetadataExifUpdateInput>;
  /** Actions for the "Photo.Metadata_FabStorage" relation: "MetadataFabStorage.photo"'s inverse relation */
  Metadata_FabStorage?: InputMaybe<PhotoNestedMetadataFabstorageUpdateInput>;
  /** Actions for the "Photo.Metadata_FileCheckFor" relation: "MetadataFileCheckFor.photo"'s inverse relation */
  Metadata_FileCheckFor?: InputMaybe<PhotoNestedMetadataFilecheckforUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoBitsPerPixel" relation: "MetadataFileInfoBitsPerPixel.photo"'s inverse relation */
  Metadata_FileInfoBitsPerPixel?: InputMaybe<PhotoNestedMetadataFileinfobitsperpixelUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoFrames" relation: "MetadataFileInfoFrames.photo"'s inverse relation */
  Metadata_FileInfoFrames?: InputMaybe<PhotoNestedMetadataFileinfoframesUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoPhotometricInterpretation" relation: "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation */
  Metadata_FileInfoPhotometricInterpretation?: InputMaybe<PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoTransferSyntax" relation: "MetadataFileInfoTransferSyntax.photo"'s inverse relation */
  Metadata_FileInfoTransferSyntax?: InputMaybe<PhotoNestedMetadataFileinfotransfersyntaxUpdateInput>;
  /** Actions for the "Photo.Metadata_FileStage" relation: "MetadataFileStage.photo"'s inverse relation */
  Metadata_FileStage?: InputMaybe<PhotoNestedMetadataFilestageUpdateInput>;
  /** Actions for the "Photo.Metadata_FileStatus" relation: "MetadataFileStatus.photo"'s inverse relation */
  Metadata_FileStatus?: InputMaybe<PhotoNestedMetadataFilestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_FileType" relation: "MetadataFileType.photo"'s inverse relation */
  Metadata_FileType?: InputMaybe<PhotoNestedMetadataFiletypeUpdateInput>;
  /** Actions for the "Photo.Metadata_FixtureIdentifier" relation: "MetadataFixtureIdentifier.photo"'s inverse relation */
  Metadata_FixtureIdentifier?: InputMaybe<PhotoNestedMetadataFixtureidentifierUpdateInput>;
  /** Actions for the "Photo.Metadata_Format" relation: "MetadataFormat.photo"'s inverse relation */
  Metadata_Format?: InputMaybe<PhotoNestedMetadataFormatUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSLatitude" relation: "MetadataGpsLatitude.photo"'s inverse relation */
  Metadata_GPSLatitude?: InputMaybe<PhotoNestedMetadataGpslatitudeUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSLongitude" relation: "MetadataGpsLongitude.photo"'s inverse relation */
  Metadata_GPSLongitude?: InputMaybe<PhotoNestedMetadataGpslongitudeUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSProcessingMethod" relation: "MetadataGpsProcessingMethod.photo"'s inverse relation */
  Metadata_GPSProcessingMethod?: InputMaybe<PhotoNestedMetadataGpsprocessingmethodUpdateInput>;
  /** Actions for the "Photo.Metadata_Header" relation: "MetadataHeader.photo"'s inverse relation */
  Metadata_Header?: InputMaybe<PhotoNestedMetadataHeaderUpdateInput>;
  /** Actions for the "Photo.Metadata_HeaderPrisma" relation: "MetadataHeaderPrisma.photo"'s inverse relation */
  Metadata_HeaderPrisma?: InputMaybe<PhotoNestedMetadataHeaderprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_Headline" relation: "MetadataHeadline.photo"'s inverse relation */
  Metadata_Headline?: InputMaybe<PhotoNestedMetadataHeadlineUpdateInput>;
  /** Actions for the "Photo.Metadata_History" relation: "MetadataHistory.photo"'s inverse relation */
  Metadata_History?: InputMaybe<PhotoNestedMetadataHistoryUpdateInput>;
  /** Actions for the "Photo.Metadata_ICCProfile" relation: "MetadataIccProfile.photo"'s inverse relation */
  Metadata_ICCProfile?: InputMaybe<PhotoNestedMetadataIccprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_ID_subject" relation: "MetadataIdSubject.photo"'s inverse relation */
  Metadata_ID_subject?: InputMaybe<PhotoNestedMetadataIdSubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_IdAssignment" relation: "MetadataIdAssignment.photo"'s inverse relation */
  Metadata_IdAssignment?: InputMaybe<PhotoNestedMetadataIdassignmentUpdateInput>;
  /** Actions for the "Photo.Metadata_IdMediaContent" relation: "MetadataIdMediaContent.photo"'s inverse relation */
  Metadata_IdMediaContent?: InputMaybe<PhotoNestedMetadataIdmediacontentUpdateInput>;
  /** Actions for the "Photo.Metadata_IdPhotoPrisma" relation: "MetadataIdPhotoPrisma.photo"'s inverse relation */
  Metadata_IdPhotoPrisma?: InputMaybe<PhotoNestedMetadataIdphotoprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_ImageNotes" relation: "MetadataImageNotes.photo"'s inverse relation */
  Metadata_ImageNotes?: InputMaybe<PhotoNestedMetadataImagenotesUpdateInput>;
  /** Actions for the "Photo.Metadata_ImageUniqueID" relation: "MetadataImageUniqueId.photo"'s inverse relation */
  Metadata_ImageUniqueID?: InputMaybe<PhotoNestedMetadataImageuniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Instructions" relation: "MetadataInstructions.photo"'s inverse relation */
  Metadata_Instructions?: InputMaybe<PhotoNestedMetadataInstructionsUpdateInput>;
  /** Actions for the "Photo.Metadata_IntellectualGenre" relation: "MetadataIntellectualGenre.photo"'s inverse relation */
  Metadata_IntellectualGenre?: InputMaybe<PhotoNestedMetadataIntellectualgenreUpdateInput>;
  /** Actions for the "Photo.Metadata_ISOSpeedRatings" relation: "MetadataIsoSpeedRatings.photo"'s inverse relation */
  Metadata_ISOSpeedRatings?: InputMaybe<PhotoNestedMetadataIsospeedratingsUpdateInput>;
  /** Actions for the "Photo.Metadata_Issue" relation: "MetadataIssue.photo"'s inverse relation */
  Metadata_Issue?: InputMaybe<PhotoNestedMetadataIssueUpdateInput>;
  /** Actions for the "Photo.Metadata_JobId" relation: "MetadataJobId.photo"'s inverse relation */
  Metadata_JobId?: InputMaybe<PhotoNestedMetadataJobidUpdateInput>;
  /** Actions for the "Photo.Metadata_JobProcessing" relation: "MetadataJobProcessing.photo"'s inverse relation */
  Metadata_JobProcessing?: InputMaybe<PhotoNestedMetadataJobprocessingUpdateInput>;
  /** Actions for the "Photo.Metadata_LayoutPictureEditing" relation: "MetadataLayoutPictureEditing.photo"'s inverse relation */
  Metadata_LayoutPictureEditing?: InputMaybe<PhotoNestedMetadataLayoutpictureeditingUpdateInput>;
  /** Actions for the "Photo.Metadata_LayoutStorage" relation: "MetadataLayoutStorage.photo"'s inverse relation */
  Metadata_LayoutStorage?: InputMaybe<PhotoNestedMetadataLayoutstorageUpdateInput>;
  /** Actions for the "Photo.Metadata_license" relation: "MetadataLicense.photo"'s inverse relation */
  Metadata_license?: InputMaybe<PhotoNestedMetadataLicenseUpdateInput>;
  /** Actions for the "Photo.Metadata_Links" relation: "MetadataLinks.photo"'s inverse relation */
  Metadata_Links?: InputMaybe<PhotoNestedMetadataLinksUpdateInput>;
  /** Actions for the "Photo.Metadata_LocalCaption" relation: "MetadataLocalCaption.photo"'s inverse relation */
  Metadata_LocalCaption?: InputMaybe<PhotoNestedMetadataLocalcaptionUpdateInput>;
  /** Actions for the "Photo.Metadata_Location" relation: "MetadataLocation.photo"'s inverse relation */
  Metadata_Location?: InputMaybe<PhotoNestedMetadataLocationUpdateInput>;
  /** Actions for the "Photo.Metadata_Make" relation: "MetadataMake.photo"'s inverse relation */
  Metadata_Make?: InputMaybe<PhotoNestedMetadataMakeUpdateInput>;
  /** Actions for the "Photo.Metadata_Manifest" relation: "MetadataManifest.photo"'s inverse relation */
  Metadata_Manifest?: InputMaybe<PhotoNestedMetadataManifestUpdateInput>;
  /** Actions for the "Photo.Metadata_Marked" relation: "MetadataMarked.photo"'s inverse relation */
  Metadata_Marked?: InputMaybe<PhotoNestedMetadataMarkedUpdateInput>;
  /** Actions for the "Photo.Metadata_MasterDocumentId" relation: "MetadataMasterDocumentId.photo"'s inverse relation */
  Metadata_MasterDocumentId?: InputMaybe<PhotoNestedMetadataMasterdocumentidUpdateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailHeight" relation: "MetadataMaxAvailHeight.photo"'s inverse relation */
  Metadata_MaxAvailHeight?: InputMaybe<PhotoNestedMetadataMaxavailheightUpdateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailWidth" relation: "MetadataMaxAvailWidth.photo"'s inverse relation */
  Metadata_MaxAvailWidth?: InputMaybe<PhotoNestedMetadataMaxavailwidthUpdateInput>;
  /** Actions for the "Photo.Metadata_MinorModelAgeDisclosure" relation: "MetadataMinorModelAgeDisclosure.photo"'s inverse relation */
  Metadata_MinorModelAgeDisclosure?: InputMaybe<PhotoNestedMetadataMinormodelagedisclosureUpdateInput>;
  /** Actions for the "Photo.Metadata_Model" relation: "MetadataModel.photo"'s inverse relation */
  Metadata_Model?: InputMaybe<PhotoNestedMetadataModelUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelAge" relation: "MetadataModelAge.photo"'s inverse relation */
  Metadata_ModelAge?: InputMaybe<PhotoNestedMetadataModelageUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseID" relation: "MetadataModelReleaseId.photo"'s inverse relation */
  Metadata_ModelReleaseID?: InputMaybe<PhotoNestedMetadataModelreleaseidUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseStatus" relation: "MetadataModelReleaseStatus.photo"'s inverse relation */
  Metadata_ModelReleaseStatus?: InputMaybe<PhotoNestedMetadataModelreleasestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_morePermissions" relation: "MetadataMorePermissions.photo"'s inverse relation */
  Metadata_morePermissions?: InputMaybe<PhotoNestedMetadataMorepermissionsUpdateInput>;
  /** Actions for the "Photo.Metadata_ObjectCycle" relation: "MetadataObjectCycle.photo"'s inverse relation */
  Metadata_ObjectCycle?: InputMaybe<PhotoNestedMetadataObjectcycleUpdateInput>;
  /** Actions for the "Photo.Metadata_OrganisationInImageName" relation: "MetadataOrganisationInImageName.photo"'s inverse relation */
  Metadata_OrganisationInImageName?: InputMaybe<PhotoNestedMetadataOrganisationinimagenameUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalColor" relation: "MetadataOriginalColor.photo"'s inverse relation */
  Metadata_OriginalColor?: InputMaybe<PhotoNestedMetadataOriginalcolorUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalICCProfile" relation: "MetadataOriginalIccProfile.photo"'s inverse relation */
  Metadata_OriginalICCProfile?: InputMaybe<PhotoNestedMetadataOriginaliccprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalName" relation: "MetadataOriginalName.photo"'s inverse relation */
  Metadata_OriginalName?: InputMaybe<PhotoNestedMetadataOriginalnameUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalWeigth" relation: "MetadataOriginalWeigth.photo"'s inverse relation */
  Metadata_OriginalWeigth?: InputMaybe<PhotoNestedMetadataOriginalweigthUpdateInput>;
  /** Actions for the "Photo.Metadata_Owner" relation: "MetadataOwner.photo"'s inverse relation */
  Metadata_Owner?: InputMaybe<PhotoNestedMetadataOwnerUpdateInput>;
  /** Actions for the "Photo.Metadata_OwnerId" relation: "MetadataOwnerId.photo"'s inverse relation */
  Metadata_OwnerId?: InputMaybe<PhotoNestedMetadataOwneridUpdateInput>;
  /** Actions for the "Photo.Metadata_Page" relation: "MetadataPage.photo"'s inverse relation */
  Metadata_Page?: InputMaybe<PhotoNestedMetadataPageUpdateInput>;
  /** Actions for the "Photo.Metadata_PagePrisma" relation: "MetadataPagePrisma.photo"'s inverse relation */
  Metadata_PagePrisma?: InputMaybe<PhotoNestedMetadataPageprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_PaintBasedCorrections" relation: "MetadataPaintBasedCorrections.photo"'s inverse relation */
  Metadata_PaintBasedCorrections?: InputMaybe<PhotoNestedMetadataPaintbasedcorrectionsUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientDOB" relation: "MetadataPatientDob.photo"'s inverse relation */
  Metadata_PatientDOB?: InputMaybe<PhotoNestedMetadataPatientdobUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientID" relation: "MetadataPatientId.photo"'s inverse relation */
  Metadata_PatientID?: InputMaybe<PhotoNestedMetadataPatientidUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientName" relation: "MetadataPatientName.photo"'s inverse relation */
  Metadata_PatientName?: InputMaybe<PhotoNestedMetadataPatientnameUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientSex" relation: "MetadataPatientSex.photo"'s inverse relation */
  Metadata_PatientSex?: InputMaybe<PhotoNestedMetadataPatientsexUpdateInput>;
  /** Actions for the "Photo.Metadata_PersonInImage" relation: "MetadataPersonInImage.photo"'s inverse relation */
  Metadata_PersonInImage?: InputMaybe<PhotoNestedMetadataPersoninimageUpdateInput>;
  /** Actions for the "Photo.Metadata_PhotoStorage" relation: "MetadataPhotoStorage.photo"'s inverse relation */
  Metadata_PhotoStorage?: InputMaybe<PhotoNestedMetadataPhotostorageUpdateInput>;
  /** Actions for the "Photo.Metadata_PrepressPictureEditing" relation: "MetadataPrepressPictureEditing.photo"'s inverse relation */
  Metadata_PrepressPictureEditing?: InputMaybe<PhotoNestedMetadataPrepresspictureeditingUpdateInput>;
  /** Actions for the "Photo.Metadata_PriceLevel" relation: "MetadataPriceLevel.photo"'s inverse relation */
  Metadata_PriceLevel?: InputMaybe<PhotoNestedMetadataPricelevelUpdateInput>;
  /** Actions for the "Photo.Metadata_PrintingProfile" relation: "MetadataPrintingProfile.photo"'s inverse relation */
  Metadata_PrintingProfile?: InputMaybe<PhotoNestedMetadataPrintingprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_PrismaProduction" relation: "MetadataPrismaProduction.photo"'s inverse relation */
  Metadata_PrismaProduction?: InputMaybe<PhotoNestedMetadataPrismaproductionUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessHistory" relation: "MetadataProcessHistory.photo"'s inverse relation */
  Metadata_ProcessHistory?: InputMaybe<PhotoNestedMetadataProcesshistoryUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessParameter" relation: "MetadataProcessParameter.photo"'s inverse relation */
  Metadata_ProcessParameter?: InputMaybe<PhotoNestedMetadataProcessparameterUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessStatus" relation: "MetadataProcessStatus.photo"'s inverse relation */
  Metadata_ProcessStatus?: InputMaybe<PhotoNestedMetadataProcessstatusUpdateInput>;
  /** Actions for the "Photo.Metadata_Product" relation: "MetadataProduct.photo"'s inverse relation */
  Metadata_Product?: InputMaybe<PhotoNestedMetadataProductUpdateInput>;
  /** Actions for the "Photo.Metadata_Products" relation: "MetadataProducts.photo"'s inverse relation */
  Metadata_Products?: InputMaybe<PhotoNestedMetadataProductsUpdateInput>;
  /** Actions for the "Photo.Metadata_ProductShortName" relation: "MetadataProductShortName.photo"'s inverse relation */
  Metadata_ProductShortName?: InputMaybe<PhotoNestedMetadataProductshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_Produits" relation: "MetadataProduits.photo"'s inverse relation */
  Metadata_Produits?: InputMaybe<PhotoNestedMetadataProduitsUpdateInput>;
  /** Actions for the "Photo.Metadata_ProgramVersion" relation: "MetadataProgramVersion.photo"'s inverse relation */
  Metadata_ProgramVersion?: InputMaybe<PhotoNestedMetadataProgramversionUpdateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseID" relation: "MetadataPropertyReleaseId.photo"'s inverse relation */
  Metadata_PropertyReleaseID?: InputMaybe<PhotoNestedMetadataPropertyreleaseidUpdateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseStatus" relation: "MetadataPropertyReleaseStatus.photo"'s inverse relation */
  Metadata_PropertyReleaseStatus?: InputMaybe<PhotoNestedMetadataPropertyreleasestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_Publisher" relation: "MetadataPublisher.photo"'s inverse relation */
  Metadata_Publisher?: InputMaybe<PhotoNestedMetadataPublisherUpdateInput>;
  /** Actions for the "Photo.Metadata_publishingIssue" relation: "MetadataPublishingIssue.photo"'s inverse relation */
  Metadata_publishingIssue?: InputMaybe<PhotoNestedMetadataPublishingissueUpdateInput>;
  /** Actions for the "Photo.Metadata_publishingSubject" relation: "MetadataPublishingSubject.photo"'s inverse relation */
  Metadata_publishingSubject?: InputMaybe<PhotoNestedMetadataPublishingsubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaBy" relation: "MetadataQualifiedUsePrismaBy.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaBy?: InputMaybe<PhotoNestedMetadataQualifieduseprismabyUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDate" relation: "MetadataQualifiedUsePrismaDate.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDate?: InputMaybe<PhotoNestedMetadataQualifieduseprismadateUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDuration" relation: "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDuration?: InputMaybe<PhotoNestedMetadataQualifieduseprismadurationUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaSupport" relation: "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaSupport?: InputMaybe<PhotoNestedMetadataQualifieduseprismasupportUpdateInput>;
  /** Actions for the "Photo.Metadata_Rating" relation: "MetadataRating.photo"'s inverse relation */
  Metadata_Rating?: InputMaybe<PhotoNestedMetadataRatingUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceDate" relation: "MetadataReferenceDate.photo"'s inverse relation */
  Metadata_ReferenceDate?: InputMaybe<PhotoNestedMetadataReferencedateUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceNumber" relation: "MetadataReferenceNumber.photo"'s inverse relation */
  Metadata_ReferenceNumber?: InputMaybe<PhotoNestedMetadataReferencenumberUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceService" relation: "MetadataReferenceService.photo"'s inverse relation */
  Metadata_ReferenceService?: InputMaybe<PhotoNestedMetadataReferenceserviceUpdateInput>;
  /** Actions for the "Photo.Metadata_ReleaseDate" relation: "MetadataReleaseDate.photo"'s inverse relation */
  Metadata_ReleaseDate?: InputMaybe<PhotoNestedMetadataReleasedateUpdateInput>;
  /** Actions for the "Photo.Metadata_ReleaseTime" relation: "MetadataReleaseTime.photo"'s inverse relation */
  Metadata_ReleaseTime?: InputMaybe<PhotoNestedMetadataReleasetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_RequiredPermission" relation: "MetadataRequiredPermission.photo"'s inverse relation */
  Metadata_RequiredPermission?: InputMaybe<PhotoNestedMetadataRequiredpermissionUpdateInput>;
  /** Actions for the "Photo.Metadata_ResolutionKind" relation: "MetadataResolutionKind.photo"'s inverse relation */
  Metadata_ResolutionKind?: InputMaybe<PhotoNestedMetadataResolutionkindUpdateInput>;
  /** Actions for the "Photo.Metadata_rights" relation: "MetadataRights.photo"'s inverse relation */
  Metadata_rights?: InputMaybe<PhotoNestedMetadataRightsUpdateInput>;
  /** Actions for the "Photo.Metadata_Royaltyfree" relation: "MetadataRoyaltyfree.photo"'s inverse relation */
  Metadata_Royaltyfree?: InputMaybe<PhotoNestedMetadataRoyaltyfreeUpdateInput>;
  /** Actions for the "Photo.Metadata_Scene" relation: "MetadataScene.photo"'s inverse relation */
  Metadata_Scene?: InputMaybe<PhotoNestedMetadataSceneUpdateInput>;
  /** Actions for the "Photo.Metadata_Section" relation: "MetadataSection.photo"'s inverse relation */
  Metadata_Section?: InputMaybe<PhotoNestedMetadataSectionUpdateInput>;
  /** Actions for the "Photo.Metadata_SentTo" relation: "MetadataSentTo.photo"'s inverse relation */
  Metadata_SentTo?: InputMaybe<PhotoNestedMetadataSenttoUpdateInput>;
  /** Actions for the "Photo.Metadata_SerialNumber" relation: "MetadataSerialNumber.photo"'s inverse relation */
  Metadata_SerialNumber?: InputMaybe<PhotoNestedMetadataSerialnumberUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesDateTime" relation: "MetadataSeriesDateTime.photo"'s inverse relation */
  Metadata_SeriesDateTime?: InputMaybe<PhotoNestedMetadataSeriesdatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesDescription" relation: "MetadataSeriesDescription.photo"'s inverse relation */
  Metadata_SeriesDescription?: InputMaybe<PhotoNestedMetadataSeriesdescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesModality" relation: "MetadataSeriesModality.photo"'s inverse relation */
  Metadata_SeriesModality?: InputMaybe<PhotoNestedMetadataSeriesmodalityUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesNumber" relation: "MetadataSeriesNumber.photo"'s inverse relation */
  Metadata_SeriesNumber?: InputMaybe<PhotoNestedMetadataSeriesnumberUpdateInput>;
  /** Actions for the "Photo.Metadata_ShortUniqueId" relation: "MetadataShortUniqueId.photo"'s inverse relation */
  Metadata_ShortUniqueId?: InputMaybe<PhotoNestedMetadataShortuniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Source" relation: "MetadataSource.photo"'s inverse relation */
  Metadata_Source?: InputMaybe<PhotoNestedMetadataSourceUpdateInput>;
  /** Actions for the "Photo.Metadata_State" relation: "MetadataState.photo"'s inverse relation */
  Metadata_State?: InputMaybe<PhotoNestedMetadataStateUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyDateTime" relation: "MetadataStudyDateTime.photo"'s inverse relation */
  Metadata_StudyDateTime?: InputMaybe<PhotoNestedMetadataStudydatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyDescription" relation: "MetadataStudyDescription.photo"'s inverse relation */
  Metadata_StudyDescription?: InputMaybe<PhotoNestedMetadataStudydescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyID" relation: "MetadataStudyId.photo"'s inverse relation */
  Metadata_StudyID?: InputMaybe<PhotoNestedMetadataStudyidUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyPhysician" relation: "MetadataStudyPhysician.photo"'s inverse relation */
  Metadata_StudyPhysician?: InputMaybe<PhotoNestedMetadataStudyphysicianUpdateInput>;
  /** Actions for the "Photo.Metadata_subject" relation: "MetadataSubject.photo"'s inverse relation */
  Metadata_subject?: InputMaybe<PhotoNestedMetadataSubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_SubjectCode" relation: "MetadataSubjectCode.photo"'s inverse relation */
  Metadata_SubjectCode?: InputMaybe<PhotoNestedMetadataSubjectcodeUpdateInput>;
  /** Actions for the "Photo.Metadata_SubjectPrisma" relation: "MetadataSubjectPrisma.photo"'s inverse relation */
  Metadata_SubjectPrisma?: InputMaybe<PhotoNestedMetadataSubjectprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_SupplementalCategories" relation: "MetadataSupplementalCategories.photo"'s inverse relation */
  Metadata_SupplementalCategories?: InputMaybe<PhotoNestedMetadataSupplementalcategoriesUpdateInput>;
  /** Actions for the "Photo.Metadata_TargetVersion" relation: "MetadataTargetVersion.photo"'s inverse relation */
  Metadata_TargetVersion?: InputMaybe<PhotoNestedMetadataTargetversionUpdateInput>;
  /** Actions for the "Photo.Metadata_title" relation: "MetadataTitle.photo"'s inverse relation */
  Metadata_title?: InputMaybe<PhotoNestedMetadataTitleUpdateInput>;
  /** Actions for the "Photo.Metadata_ToneCurve" relation: "MetadataToneCurve.photo"'s inverse relation */
  Metadata_ToneCurve?: InputMaybe<PhotoNestedMetadataTonecurveUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredBy" relation: "MetadataTransferredBy.photo"'s inverse relation */
  Metadata_TransferredBy?: InputMaybe<PhotoNestedMetadataTransferredbyUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredByEmail" relation: "MetadataTransferredByEmail.photo"'s inverse relation */
  Metadata_TransferredByEmail?: InputMaybe<PhotoNestedMetadataTransferredbyemailUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredByFullName" relation: "MetadataTransferredByFullName.photo"'s inverse relation */
  Metadata_TransferredByFullName?: InputMaybe<PhotoNestedMetadataTransferredbyfullnameUpdateInput>;
  /** Actions for the "Photo.Metadata_TransmissionReference" relation: "MetadataTransmissionReference.photo"'s inverse relation */
  Metadata_TransmissionReference?: InputMaybe<PhotoNestedMetadataTransmissionreferenceUpdateInput>;
  /** Actions for the "Photo.Metadata_Type" relation: "MetadataType.photo"'s inverse relation */
  Metadata_Type?: InputMaybe<PhotoNestedMetadataTypeUpdateInput>;
  /** Actions for the "Photo.Metadata_Undersubject" relation: "MetadataUndersubject.photo"'s inverse relation */
  Metadata_Undersubject?: InputMaybe<PhotoNestedMetadataUndersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_UnderUnderSubject" relation: "MetadataUnderUnderSubject.photo"'s inverse relation */
  Metadata_UnderUnderSubject?: InputMaybe<PhotoNestedMetadataUnderundersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_UniqueId" relation: "MetadataUniqueId.photo"'s inverse relation */
  Metadata_UniqueId?: InputMaybe<PhotoNestedMetadataUniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Unit" relation: "MetadataUnit.photo"'s inverse relation */
  Metadata_Unit?: InputMaybe<PhotoNestedMetadataUnitUpdateInput>;
  /** Actions for the "Photo.Metadata_UnitShortName" relation: "MetadataUnitShortName.photo"'s inverse relation */
  Metadata_UnitShortName?: InputMaybe<PhotoNestedMetadataUnitshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadedBy" relation: "MetadataUploadedBy.photo"'s inverse relation */
  Metadata_UploadedBy?: InputMaybe<PhotoNestedMetadataUploadedbyUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadedByFullName" relation: "MetadataUploadedByFullName.photo"'s inverse relation */
  Metadata_UploadedByFullName?: InputMaybe<PhotoNestedMetadataUploadedbyfullnameUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadTime" relation: "MetadataUploadTime.photo"'s inverse relation */
  Metadata_UploadTime?: InputMaybe<PhotoNestedMetadataUploadtimeUpdateInput>;
  /** Actions for the "Photo.Metadata_Urgency" relation: "MetadataUrgency.photo"'s inverse relation */
  Metadata_Urgency?: InputMaybe<PhotoNestedMetadataUrgencyUpdateInput>;
  /** Actions for the "Photo.Metadata_UsageTerms" relation: "MetadataUsageTerms.photo"'s inverse relation */
  Metadata_UsageTerms?: InputMaybe<PhotoNestedMetadataUsagetermsUpdateInput>;
  /** Actions for the "Photo.Metadata_UserComment" relation: "MetadataUserComment.photo"'s inverse relation */
  Metadata_UserComment?: InputMaybe<PhotoNestedMetadataUsercommentUpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined195" relation: "MetadataUserDefined195.photo"'s inverse relation */
  Metadata_UserDefined195?: InputMaybe<PhotoNestedMetadataUserdefined195UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined237" relation: "MetadataUserDefined237.photo"'s inverse relation */
  Metadata_UserDefined237?: InputMaybe<PhotoNestedMetadataUserdefined237UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined238" relation: "MetadataUserDefined238.photo"'s inverse relation */
  Metadata_UserDefined238?: InputMaybe<PhotoNestedMetadataUserdefined238UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined239" relation: "MetadataUserDefined239.photo"'s inverse relation */
  Metadata_UserDefined239?: InputMaybe<PhotoNestedMetadataUserdefined239UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined242" relation: "MetadataUserDefined242.photo"'s inverse relation */
  Metadata_UserDefined242?: InputMaybe<PhotoNestedMetadataUserdefined242UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined62" relation: "MetadataUserDefined62.photo"'s inverse relation */
  Metadata_UserDefined62?: InputMaybe<PhotoNestedMetadataUserdefined62UpdateInput>;
  /** Actions for the "Photo.Metadata_Valid" relation: "MetadataValid.photo"'s inverse relation */
  Metadata_Valid?: InputMaybe<PhotoNestedMetadataValidUpdateInput>;
  /** Actions for the "Photo.Metadata_Version" relation: "MetadataVersion.photo"'s inverse relation */
  Metadata_Version?: InputMaybe<PhotoNestedMetadataVersionUpdateInput>;
  /** Actions for the "Photo.Metadata_WebStatement" relation: "MetadataWebStatement.photo"'s inverse relation */
  Metadata_WebStatement?: InputMaybe<PhotoNestedMetadataWebstatementUpdateInput>;
  /** Actions for the "Photo.Metadata_WorkflowKind" relation: "MetadataWorkflowKind.photo"'s inverse relation */
  Metadata_WorkflowKind?: InputMaybe<PhotoNestedMetadataWorkflowkindUpdateInput>;
  /** Actions for the "Photo.Metadata_XMPFileStamps" relation: "MetadataXmpFileStamps.photo"'s inverse relation */
  Metadata_XMPFileStamps?: InputMaybe<PhotoNestedMetadataXmpfilestampsUpdateInput>;
  /** Actions for the "Photo.Metadata_XMPHistory" relation: "MetadataXmpHistory.photo"'s inverse relation */
  Metadata_XMPHistory?: InputMaybe<PhotoNestedMetadataXmphistoryUpdateInput>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.photoMetadatas" relation: "PhotoMetadata.photo"'s inverse relation */
  photoMetadatas?: InputMaybe<PhotoNestedPhotoMetadatasUpdateInput>;
  /** Actions for the "Photo.photoPublications" relation: "PhotoPublication.photo"'s inverse relation */
  photoPublications?: InputMaybe<PhotoNestedPhotoPublicationsUpdateInput>;
  synced?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date of the photo's capture */
  takenAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

/** Filters the "Photo" nodes by specifying some conditions */
export type PhotoWhereInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  _id_gt?: InputMaybe<Scalars['Int']['input']>;
  _id_gte?: InputMaybe<Scalars['Int']['input']>;
  _id_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _id_lt?: InputMaybe<Scalars['Int']['input']>;
  _id_lte?: InputMaybe<Scalars['Int']['input']>;
  _id_not?: InputMaybe<Scalars['Int']['input']>;
  _id_not_in?: InputMaybe<Array<Scalars['Int']['input']>>;
  agency?: InputMaybe<AgencyWhereInput>;
  agency_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  AND?: InputMaybe<Array<PhotoWhereInput>>;
  archive?: InputMaybe<ArchiveWhereInput>;
  category?: InputMaybe<CategoryWhereInput>;
  category_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentType_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_ends_with?: InputMaybe<Scalars['String']['input']>;
  contentType_gt?: InputMaybe<Scalars['String']['input']>;
  contentType_gte?: InputMaybe<Scalars['String']['input']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  contentType_lt?: InputMaybe<Scalars['String']['input']>;
  contentType_lte?: InputMaybe<Scalars['String']['input']>;
  contentType_not?: InputMaybe<Scalars['String']['input']>;
  contentType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  contentType_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  contentType_starts_with?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  createdAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  createdAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  deliverySubject?: InputMaybe<DeliverySubjectWhereInput>;
  deliverySubject_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  filesize_gt?: InputMaybe<Scalars['Int']['input']>;
  filesize_gte?: InputMaybe<Scalars['Int']['input']>;
  filesize_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  filesize_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  filesize_lt?: InputMaybe<Scalars['Int']['input']>;
  filesize_lte?: InputMaybe<Scalars['Int']['input']>;
  filesize_not?: InputMaybe<Scalars['Int']['input']>;
  filesize_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_gt?: InputMaybe<Scalars['Int']['input']>;
  height_gte?: InputMaybe<Scalars['Int']['input']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  height_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  height_lt?: InputMaybe<Scalars['Int']['input']>;
  height_lte?: InputMaybe<Scalars['Int']['input']>;
  height_not?: InputMaybe<Scalars['Int']['input']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  importedAt_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  importedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  importedAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  importQueue?: InputMaybe<ImportQueueWhereInput>;
  importQueue_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isLegacy_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  isLegacy_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  isLegacy_not?: InputMaybe<Scalars['Boolean']['input']>;
  isLegacy_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  isUploaded_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded_not?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  Metadata_AccessRights_some?: InputMaybe<MetadataAccessRightsWhereInput>;
  Metadata_AddlModelInfo_some?: InputMaybe<MetadataAddlModelInfoWhereInput>;
  Metadata_Archive_child_some?: InputMaybe<MetadataArchiveChildWhereInput>;
  Metadata_Archive_parent_some?: InputMaybe<MetadataArchiveParentWhereInput>;
  Metadata_AssetState_some?: InputMaybe<MetadataAssetStateWhereInput>;
  Metadata_attributionName_some?: InputMaybe<MetadataAttributionNameWhereInput>;
  Metadata_attributionURL_some?: InputMaybe<MetadataAttributionUrlWhereInput>;
  Metadata_Audience_some?: InputMaybe<MetadataAudienceWhereInput>;
  Metadata_AuthorsPosition_some?: InputMaybe<MetadataAuthorsPositionWhereInput>;
  Metadata_BackupName_some?: InputMaybe<MetadataBackupNameWhereInput>;
  Metadata_BitmapGrayscalePicture_some?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
  Metadata_Brand_some?: InputMaybe<MetadataBrandWhereInput>;
  Metadata_BrandPrisma_some?: InputMaybe<MetadataBrandPrismaWhereInput>;
  Metadata_BrandShortName_some?: InputMaybe<MetadataBrandShortNameWhereInput>;
  Metadata_CaptionWriter_some?: InputMaybe<MetadataCaptionWriterWhereInput>;
  Metadata_Category_some?: InputMaybe<MetadataCategoryWhereInput>;
  Metadata_CategoryPrisma_some?: InputMaybe<MetadataCategoryPrismaWhereInput>;
  Metadata_Certificate_some?: InputMaybe<MetadataCertificateWhereInput>;
  Metadata_CiAdrCity_some?: InputMaybe<MetadataCiAdrCityWhereInput>;
  Metadata_CiAdrCtry_some?: InputMaybe<MetadataCiAdrCtryWhereInput>;
  Metadata_CiAdrExtadr_some?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
  Metadata_CiAdrPcode_some?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
  Metadata_CiAdrRegion_some?: InputMaybe<MetadataCiAdrRegionWhereInput>;
  Metadata_CiEmailWork_some?: InputMaybe<MetadataCiEmailWorkWhereInput>;
  Metadata_CiTelWork_some?: InputMaybe<MetadataCiTelWorkWhereInput>;
  Metadata_City_some?: InputMaybe<MetadataCityWhereInput>;
  Metadata_CiUrlWork_some?: InputMaybe<MetadataCiUrlWorkWhereInput>;
  Metadata_Classify_some?: InputMaybe<MetadataClassifyWhereInput>;
  Metadata_Company_some?: InputMaybe<MetadataCompanyWhereInput>;
  Metadata_CompanyShortName_some?: InputMaybe<MetadataCompanyShortNameWhereInput>;
  Metadata_Confidentiality_some?: InputMaybe<MetadataConfidentialityWhereInput>;
  Metadata_ContainedIn_some?: InputMaybe<MetadataContainedInWhereInput>;
  Metadata_ContentMediaKind_some?: InputMaybe<MetadataContentMediaKindWhereInput>;
  Metadata_ContentValue_some?: InputMaybe<MetadataContentValueWhereInput>;
  Metadata_CopyrightLayer_some?: InputMaybe<MetadataCopyrightLayerWhereInput>;
  Metadata_Country_some?: InputMaybe<MetadataCountryWhereInput>;
  Metadata_CountryCode_some?: InputMaybe<MetadataCountryCodeWhereInput>;
  Metadata_CreatedTime_some?: InputMaybe<MetadataCreatedTimeWhereInput>;
  Metadata_creator_some?: InputMaybe<MetadataCreatorWhereInput>;
  Metadata_CreatorTool_some?: InputMaybe<MetadataCreatorToolWhereInput>;
  Metadata_Credit_some?: InputMaybe<MetadataCreditWhereInput>;
  Metadata_CustomField14_some?: InputMaybe<MetadataCustomField14WhereInput>;
  Metadata_CustomField15_some?: InputMaybe<MetadataCustomField15WhereInput>;
  Metadata_CustomField16_some?: InputMaybe<MetadataCustomField16WhereInput>;
  Metadata_CustomField17_some?: InputMaybe<MetadataCustomField17WhereInput>;
  Metadata_CustomField20_some?: InputMaybe<MetadataCustomField20WhereInput>;
  Metadata_CustomField5_some?: InputMaybe<MetadataCustomField5WhereInput>;
  Metadata_CustomField6_some?: InputMaybe<MetadataCustomField6WhereInput>;
  Metadata_CustomField7_some?: InputMaybe<MetadataCustomField7WhereInput>;
  Metadata_CustomField9_some?: InputMaybe<MetadataCustomField9WhereInput>;
  Metadata_DateCreated_some?: InputMaybe<MetadataDateCreatedWhereInput>;
  Metadata_DateTimeDigitized_some?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
  Metadata_DateTimeOriginal_some?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
  Metadata_DeliveryAccount_some?: InputMaybe<MetadataDeliveryAccountWhereInput>;
  Metadata_DeliveryCompany_some?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
  Metadata_DeliveryCopyright_some?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
  Metadata_DeliveryDateFolder_some?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
  Metadata_DeliveryDateTime_some?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
  Metadata_DeliveryDcCreator_some?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
  Metadata_DeliveryDcRights_some?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
  Metadata_DeliveryFileType_some?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
  Metadata_DeliveryFolder_some?: InputMaybe<MetadataDeliveryFolderWhereInput>;
  Metadata_DeliveryKind_some?: InputMaybe<MetadataDeliveryKindWhereInput>;
  Metadata_DeliveryPath_some?: InputMaybe<MetadataDeliveryPathWhereInput>;
  Metadata_DeliveryPersonShownintheImage_some?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
  Metadata_DeliveryPhotoshopCredit_some?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
  Metadata_DeliveryPhotoshopSource_some?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
  Metadata_DeliveryService_some?: InputMaybe<MetadataDeliveryServiceWhereInput>;
  Metadata_DeliverySubject_some?: InputMaybe<MetadataDeliverySubjectWhereInput>;
  Metadata_DeliveryUnderSubject_some?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
  Metadata_Department_some?: InputMaybe<MetadataDepartmentWhereInput>;
  Metadata_description_some?: InputMaybe<MetadataDescriptionWhereInput>;
  Metadata_DigitalAssetURL_some?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
  Metadata_Division_some?: InputMaybe<MetadataDivisionWhereInput>;
  Metadata_DocumentText_some?: InputMaybe<MetadataDocumentTextWhereInput>;
  Metadata_Edition_some?: InputMaybe<MetadataEditionWhereInput>;
  Metadata_EditorialVersion_some?: InputMaybe<MetadataEditorialVersionWhereInput>;
  Metadata_EditStatus_some?: InputMaybe<MetadataEditStatusWhereInput>;
  Metadata_EnvironnementPhoto_some?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
  Metadata_EquipmentInstitution_some?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
  Metadata_EquipmentManufacturer_some?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
  Metadata_Event_some?: InputMaybe<MetadataEventWhereInput>;
  Metadata_Exif_some?: InputMaybe<MetadataExifWhereInput>;
  Metadata_FabStorage_some?: InputMaybe<MetadataFabStorageWhereInput>;
  Metadata_FileCheckFor_some?: InputMaybe<MetadataFileCheckForWhereInput>;
  Metadata_FileInfoBitsPerPixel_some?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
  Metadata_FileInfoFrames_some?: InputMaybe<MetadataFileInfoFramesWhereInput>;
  Metadata_FileInfoPhotometricInterpretation_some?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
  Metadata_FileInfoTransferSyntax_some?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
  Metadata_FileStage_some?: InputMaybe<MetadataFileStageWhereInput>;
  Metadata_FileStatus_some?: InputMaybe<MetadataFileStatusWhereInput>;
  Metadata_FileType_some?: InputMaybe<MetadataFileTypeWhereInput>;
  Metadata_FixtureIdentifier_some?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
  Metadata_Format_some?: InputMaybe<MetadataFormatWhereInput>;
  Metadata_GPSLatitude_some?: InputMaybe<MetadataGpsLatitudeWhereInput>;
  Metadata_GPSLongitude_some?: InputMaybe<MetadataGpsLongitudeWhereInput>;
  Metadata_GPSProcessingMethod_some?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
  Metadata_Header_some?: InputMaybe<MetadataHeaderWhereInput>;
  Metadata_HeaderPrisma_some?: InputMaybe<MetadataHeaderPrismaWhereInput>;
  Metadata_Headline_some?: InputMaybe<MetadataHeadlineWhereInput>;
  Metadata_History_some?: InputMaybe<MetadataHistoryWhereInput>;
  Metadata_ICCProfile_some?: InputMaybe<MetadataIccProfileWhereInput>;
  Metadata_ID_subject_some?: InputMaybe<MetadataIdSubjectWhereInput>;
  Metadata_IdAssignment_some?: InputMaybe<MetadataIdAssignmentWhereInput>;
  Metadata_IdMediaContent_some?: InputMaybe<MetadataIdMediaContentWhereInput>;
  Metadata_IdPhotoPrisma_some?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
  Metadata_ImageNotes_some?: InputMaybe<MetadataImageNotesWhereInput>;
  Metadata_ImageUniqueID_some?: InputMaybe<MetadataImageUniqueIdWhereInput>;
  Metadata_Instructions_some?: InputMaybe<MetadataInstructionsWhereInput>;
  Metadata_IntellectualGenre_some?: InputMaybe<MetadataIntellectualGenreWhereInput>;
  Metadata_ISOSpeedRatings_some?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
  Metadata_Issue_some?: InputMaybe<MetadataIssueWhereInput>;
  Metadata_JobId_some?: InputMaybe<MetadataJobIdWhereInput>;
  Metadata_JobProcessing_some?: InputMaybe<MetadataJobProcessingWhereInput>;
  Metadata_LayoutPictureEditing_some?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
  Metadata_LayoutStorage_some?: InputMaybe<MetadataLayoutStorageWhereInput>;
  Metadata_license_some?: InputMaybe<MetadataLicenseWhereInput>;
  Metadata_Links_some?: InputMaybe<MetadataLinksWhereInput>;
  Metadata_LocalCaption_some?: InputMaybe<MetadataLocalCaptionWhereInput>;
  Metadata_Location_some?: InputMaybe<MetadataLocationWhereInput>;
  Metadata_Make_some?: InputMaybe<MetadataMakeWhereInput>;
  Metadata_Manifest_some?: InputMaybe<MetadataManifestWhereInput>;
  Metadata_Marked_some?: InputMaybe<MetadataMarkedWhereInput>;
  Metadata_MasterDocumentId_some?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
  Metadata_MaxAvailHeight_some?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
  Metadata_MaxAvailWidth_some?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
  Metadata_MinorModelAgeDisclosure_some?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
  Metadata_Model_some?: InputMaybe<MetadataModelWhereInput>;
  Metadata_ModelAge_some?: InputMaybe<MetadataModelAgeWhereInput>;
  Metadata_ModelReleaseID_some?: InputMaybe<MetadataModelReleaseIdWhereInput>;
  Metadata_ModelReleaseStatus_some?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
  Metadata_morePermissions_some?: InputMaybe<MetadataMorePermissionsWhereInput>;
  Metadata_ObjectCycle_some?: InputMaybe<MetadataObjectCycleWhereInput>;
  Metadata_OrganisationInImageName_some?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
  Metadata_OriginalColor_some?: InputMaybe<MetadataOriginalColorWhereInput>;
  Metadata_OriginalICCProfile_some?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
  Metadata_OriginalName_some?: InputMaybe<MetadataOriginalNameWhereInput>;
  Metadata_OriginalWeigth_some?: InputMaybe<MetadataOriginalWeigthWhereInput>;
  Metadata_Owner_some?: InputMaybe<MetadataOwnerWhereInput>;
  Metadata_OwnerId_some?: InputMaybe<MetadataOwnerIdWhereInput>;
  Metadata_Page_some?: InputMaybe<MetadataPageWhereInput>;
  Metadata_PagePrisma_some?: InputMaybe<MetadataPagePrismaWhereInput>;
  Metadata_PaintBasedCorrections_some?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
  Metadata_PatientDOB_some?: InputMaybe<MetadataPatientDobWhereInput>;
  Metadata_PatientID_some?: InputMaybe<MetadataPatientIdWhereInput>;
  Metadata_PatientName_some?: InputMaybe<MetadataPatientNameWhereInput>;
  Metadata_PatientSex_some?: InputMaybe<MetadataPatientSexWhereInput>;
  Metadata_PersonInImage_some?: InputMaybe<MetadataPersonInImageWhereInput>;
  Metadata_PhotoStorage_some?: InputMaybe<MetadataPhotoStorageWhereInput>;
  Metadata_PrepressPictureEditing_some?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
  Metadata_PriceLevel_some?: InputMaybe<MetadataPriceLevelWhereInput>;
  Metadata_PrintingProfile_some?: InputMaybe<MetadataPrintingProfileWhereInput>;
  Metadata_PrismaProduction_some?: InputMaybe<MetadataPrismaProductionWhereInput>;
  Metadata_ProcessHistory_some?: InputMaybe<MetadataProcessHistoryWhereInput>;
  Metadata_ProcessParameter_some?: InputMaybe<MetadataProcessParameterWhereInput>;
  Metadata_ProcessStatus_some?: InputMaybe<MetadataProcessStatusWhereInput>;
  Metadata_Product_some?: InputMaybe<MetadataProductWhereInput>;
  Metadata_Products_some?: InputMaybe<MetadataProductsWhereInput>;
  Metadata_ProductShortName_some?: InputMaybe<MetadataProductShortNameWhereInput>;
  Metadata_Produits_some?: InputMaybe<MetadataProduitsWhereInput>;
  Metadata_ProgramVersion_some?: InputMaybe<MetadataProgramVersionWhereInput>;
  Metadata_PropertyReleaseID_some?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
  Metadata_PropertyReleaseStatus_some?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
  Metadata_Publisher_some?: InputMaybe<MetadataPublisherWhereInput>;
  Metadata_publishingIssue_some?: InputMaybe<MetadataPublishingIssueWhereInput>;
  Metadata_publishingSubject_some?: InputMaybe<MetadataPublishingSubjectWhereInput>;
  Metadata_QualifiedUsePrismaBy_some?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
  Metadata_QualifiedUsePrismaDate_some?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
  Metadata_QualifiedUsePrismaDuration_some?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
  Metadata_QualifiedUsePrismaSupport_some?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
  Metadata_Rating_some?: InputMaybe<MetadataRatingWhereInput>;
  Metadata_ReferenceDate_some?: InputMaybe<MetadataReferenceDateWhereInput>;
  Metadata_ReferenceNumber_some?: InputMaybe<MetadataReferenceNumberWhereInput>;
  Metadata_ReferenceService_some?: InputMaybe<MetadataReferenceServiceWhereInput>;
  Metadata_ReleaseDate_some?: InputMaybe<MetadataReleaseDateWhereInput>;
  Metadata_ReleaseTime_some?: InputMaybe<MetadataReleaseTimeWhereInput>;
  Metadata_RequiredPermission_some?: InputMaybe<MetadataRequiredPermissionWhereInput>;
  Metadata_ResolutionKind_some?: InputMaybe<MetadataResolutionKindWhereInput>;
  Metadata_rights_some?: InputMaybe<MetadataRightsWhereInput>;
  Metadata_Royaltyfree_some?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
  Metadata_Scene_some?: InputMaybe<MetadataSceneWhereInput>;
  Metadata_Section_some?: InputMaybe<MetadataSectionWhereInput>;
  Metadata_SentTo_some?: InputMaybe<MetadataSentToWhereInput>;
  Metadata_SerialNumber_some?: InputMaybe<MetadataSerialNumberWhereInput>;
  Metadata_SeriesDateTime_some?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
  Metadata_SeriesDescription_some?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
  Metadata_SeriesModality_some?: InputMaybe<MetadataSeriesModalityWhereInput>;
  Metadata_SeriesNumber_some?: InputMaybe<MetadataSeriesNumberWhereInput>;
  Metadata_ShortUniqueId_some?: InputMaybe<MetadataShortUniqueIdWhereInput>;
  Metadata_Source_some?: InputMaybe<MetadataSourceWhereInput>;
  Metadata_State_some?: InputMaybe<MetadataStateWhereInput>;
  Metadata_StudyDateTime_some?: InputMaybe<MetadataStudyDateTimeWhereInput>;
  Metadata_StudyDescription_some?: InputMaybe<MetadataStudyDescriptionWhereInput>;
  Metadata_StudyID_some?: InputMaybe<MetadataStudyIdWhereInput>;
  Metadata_StudyPhysician_some?: InputMaybe<MetadataStudyPhysicianWhereInput>;
  Metadata_subject_some?: InputMaybe<MetadataSubjectWhereInput>;
  Metadata_SubjectCode_some?: InputMaybe<MetadataSubjectCodeWhereInput>;
  Metadata_SubjectPrisma_some?: InputMaybe<MetadataSubjectPrismaWhereInput>;
  Metadata_SupplementalCategories_some?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
  Metadata_TargetVersion_some?: InputMaybe<MetadataTargetVersionWhereInput>;
  Metadata_title_some?: InputMaybe<MetadataTitleWhereInput>;
  Metadata_ToneCurve_some?: InputMaybe<MetadataToneCurveWhereInput>;
  Metadata_TransferredBy_some?: InputMaybe<MetadataTransferredByWhereInput>;
  Metadata_TransferredByEmail_some?: InputMaybe<MetadataTransferredByEmailWhereInput>;
  Metadata_TransferredByFullName_some?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
  Metadata_TransmissionReference_some?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
  Metadata_Type_some?: InputMaybe<MetadataTypeWhereInput>;
  Metadata_Undersubject_some?: InputMaybe<MetadataUndersubjectWhereInput>;
  Metadata_UnderUnderSubject_some?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
  Metadata_UniqueId_some?: InputMaybe<MetadataUniqueIdWhereInput>;
  Metadata_Unit_some?: InputMaybe<MetadataUnitWhereInput>;
  Metadata_UnitShortName_some?: InputMaybe<MetadataUnitShortNameWhereInput>;
  Metadata_UploadedBy_some?: InputMaybe<MetadataUploadedByWhereInput>;
  Metadata_UploadedByFullName_some?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
  Metadata_UploadTime_some?: InputMaybe<MetadataUploadTimeWhereInput>;
  Metadata_Urgency_some?: InputMaybe<MetadataUrgencyWhereInput>;
  Metadata_UsageTerms_some?: InputMaybe<MetadataUsageTermsWhereInput>;
  Metadata_UserComment_some?: InputMaybe<MetadataUserCommentWhereInput>;
  Metadata_UserDefined195_some?: InputMaybe<MetadataUserDefined195WhereInput>;
  Metadata_UserDefined237_some?: InputMaybe<MetadataUserDefined237WhereInput>;
  Metadata_UserDefined238_some?: InputMaybe<MetadataUserDefined238WhereInput>;
  Metadata_UserDefined239_some?: InputMaybe<MetadataUserDefined239WhereInput>;
  Metadata_UserDefined242_some?: InputMaybe<MetadataUserDefined242WhereInput>;
  Metadata_UserDefined62_some?: InputMaybe<MetadataUserDefined62WhereInput>;
  Metadata_Valid_some?: InputMaybe<MetadataValidWhereInput>;
  Metadata_Version_some?: InputMaybe<MetadataVersionWhereInput>;
  Metadata_WebStatement_some?: InputMaybe<MetadataWebStatementWhereInput>;
  Metadata_WorkflowKind_some?: InputMaybe<MetadataWorkflowKindWhereInput>;
  Metadata_XMPFileStamps_some?: InputMaybe<MetadataXmpFileStampsWhereInput>;
  Metadata_XMPHistory_some?: InputMaybe<MetadataXmpHistoryWhereInput>;
  NOT?: InputMaybe<PhotoWhereInput>;
  OR?: InputMaybe<Array<PhotoWhereInput>>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  originalName_contains?: InputMaybe<Scalars['String']['input']>;
  originalName_ends_with?: InputMaybe<Scalars['String']['input']>;
  originalName_gt?: InputMaybe<Scalars['String']['input']>;
  originalName_gte?: InputMaybe<Scalars['String']['input']>;
  originalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  originalName_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  originalName_lt?: InputMaybe<Scalars['String']['input']>;
  originalName_lte?: InputMaybe<Scalars['String']['input']>;
  originalName_not?: InputMaybe<Scalars['String']['input']>;
  originalName_not_contains?: InputMaybe<Scalars['String']['input']>;
  originalName_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  originalName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  originalName_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  originalName_starts_with?: InputMaybe<Scalars['String']['input']>;
  photoMetadatas_some?: InputMaybe<PhotoMetadataWhereInput>;
  photoPublications_some?: InputMaybe<PhotoPublicationWhereInput>;
  synced?: InputMaybe<Scalars['Boolean']['input']>;
  synced_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  synced_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  synced_not?: InputMaybe<Scalars['Boolean']['input']>;
  synced_not_in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  takenAt?: InputMaybe<Scalars['DateTime']['input']>;
  takenAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  takenAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  takenAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  takenAt_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  takenAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  takenAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  takenAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  takenAt_not_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  updatedAt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  updatedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  updatedAt_not_in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  width?: InputMaybe<Scalars['Int']['input']>;
  width_gt?: InputMaybe<Scalars['Int']['input']>;
  width_gte?: InputMaybe<Scalars['Int']['input']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  width_is_null?: InputMaybe<Scalars['Boolean']['input']>;
  width_lt?: InputMaybe<Scalars['Int']['input']>;
  width_lte?: InputMaybe<Scalars['Int']['input']>;
  width_not?: InputMaybe<Scalars['Int']['input']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

/**
 * Identifies exactly one "Photo" node with one of these unique values:
 * - _id
 * - id
 */
export type PhotoWhereUniqueInput = {
  _id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type PhotoWithForcedAgencyCreateInput = {
  /** Actions for the "Photo.archive" relation */
  archive: PhotoNestedArchiveCreateInput;
  /** Actions for the "Photo.category" relation */
  category?: InputMaybe<PhotoNestedCategoryCreateInput>;
  /** asset content type */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.deliverySubject" relation */
  deliverySubject?: InputMaybe<PhotoNestedDeliverySubjectCreateInput>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The date of the photo's importation */
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Actions for the "Photo.importQueue" relation: "ImportQueue.photo"'s inverse relation */
  importQueue?: InputMaybe<PhotoNestedImportQueueCreateInput>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** Actions for the "Photo.Metadata_AccessRights" relation: "MetadataAccessRights.photo"'s inverse relation */
  Metadata_AccessRights?: InputMaybe<PhotoNestedMetadataAccessrightsCreateInput>;
  /** Actions for the "Photo.Metadata_AddlModelInfo" relation: "MetadataAddlModelInfo.photo"'s inverse relation */
  Metadata_AddlModelInfo?: InputMaybe<PhotoNestedMetadataAddlmodelinfoCreateInput>;
  /** Actions for the "Photo.Metadata_Archive_child" relation: "MetadataArchiveChild.photo"'s inverse relation */
  Metadata_Archive_child?: InputMaybe<PhotoNestedMetadataArchiveChildCreateInput>;
  /** Actions for the "Photo.Metadata_Archive_parent" relation: "MetadataArchiveParent.photo"'s inverse relation */
  Metadata_Archive_parent?: InputMaybe<PhotoNestedMetadataArchiveParentCreateInput>;
  /** Actions for the "Photo.Metadata_AssetState" relation: "MetadataAssetState.photo"'s inverse relation */
  Metadata_AssetState?: InputMaybe<PhotoNestedMetadataAssetstateCreateInput>;
  /** Actions for the "Photo.Metadata_attributionName" relation: "MetadataAttributionName.photo"'s inverse relation */
  Metadata_attributionName?: InputMaybe<PhotoNestedMetadataAttributionnameCreateInput>;
  /** Actions for the "Photo.Metadata_attributionURL" relation: "MetadataAttributionUrl.photo"'s inverse relation */
  Metadata_attributionURL?: InputMaybe<PhotoNestedMetadataAttributionurlCreateInput>;
  /** Actions for the "Photo.Metadata_Audience" relation: "MetadataAudience.photo"'s inverse relation */
  Metadata_Audience?: InputMaybe<PhotoNestedMetadataAudienceCreateInput>;
  /** Actions for the "Photo.Metadata_AuthorsPosition" relation: "MetadataAuthorsPosition.photo"'s inverse relation */
  Metadata_AuthorsPosition?: InputMaybe<PhotoNestedMetadataAuthorspositionCreateInput>;
  /** Actions for the "Photo.Metadata_BackupName" relation: "MetadataBackupName.photo"'s inverse relation */
  Metadata_BackupName?: InputMaybe<PhotoNestedMetadataBackupnameCreateInput>;
  /** Actions for the "Photo.Metadata_BitmapGrayscalePicture" relation: "MetadataBitmapGrayscalePicture.photo"'s inverse relation */
  Metadata_BitmapGrayscalePicture?: InputMaybe<PhotoNestedMetadataBitmapgrayscalepictureCreateInput>;
  /** Actions for the "Photo.Metadata_Brand" relation: "MetadataBrand.photo"'s inverse relation */
  Metadata_Brand?: InputMaybe<PhotoNestedMetadataBrandCreateInput>;
  /** Actions for the "Photo.Metadata_BrandPrisma" relation: "MetadataBrandPrisma.photo"'s inverse relation */
  Metadata_BrandPrisma?: InputMaybe<PhotoNestedMetadataBrandprismaCreateInput>;
  /** Actions for the "Photo.Metadata_BrandShortName" relation: "MetadataBrandShortName.photo"'s inverse relation */
  Metadata_BrandShortName?: InputMaybe<PhotoNestedMetadataBrandshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_CaptionWriter" relation: "MetadataCaptionWriter.photo"'s inverse relation */
  Metadata_CaptionWriter?: InputMaybe<PhotoNestedMetadataCaptionwriterCreateInput>;
  /** Actions for the "Photo.Metadata_Category" relation: "MetadataCategory.photo"'s inverse relation */
  Metadata_Category?: InputMaybe<PhotoNestedMetadataCategoryCreateInput>;
  /** Actions for the "Photo.Metadata_CategoryPrisma" relation: "MetadataCategoryPrisma.photo"'s inverse relation */
  Metadata_CategoryPrisma?: InputMaybe<PhotoNestedMetadataCategoryprismaCreateInput>;
  /** Actions for the "Photo.Metadata_Certificate" relation: "MetadataCertificate.photo"'s inverse relation */
  Metadata_Certificate?: InputMaybe<PhotoNestedMetadataCertificateCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCity" relation: "MetadataCiAdrCity.photo"'s inverse relation */
  Metadata_CiAdrCity?: InputMaybe<PhotoNestedMetadataCiadrcityCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCtry" relation: "MetadataCiAdrCtry.photo"'s inverse relation */
  Metadata_CiAdrCtry?: InputMaybe<PhotoNestedMetadataCiadrctryCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrExtadr" relation: "MetadataCiAdrExtadr.photo"'s inverse relation */
  Metadata_CiAdrExtadr?: InputMaybe<PhotoNestedMetadataCiadrextadrCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrPcode" relation: "MetadataCiAdrPcode.photo"'s inverse relation */
  Metadata_CiAdrPcode?: InputMaybe<PhotoNestedMetadataCiadrpcodeCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrRegion" relation: "MetadataCiAdrRegion.photo"'s inverse relation */
  Metadata_CiAdrRegion?: InputMaybe<PhotoNestedMetadataCiadrregionCreateInput>;
  /** Actions for the "Photo.Metadata_CiEmailWork" relation: "MetadataCiEmailWork.photo"'s inverse relation */
  Metadata_CiEmailWork?: InputMaybe<PhotoNestedMetadataCiemailworkCreateInput>;
  /** Actions for the "Photo.Metadata_CiTelWork" relation: "MetadataCiTelWork.photo"'s inverse relation */
  Metadata_CiTelWork?: InputMaybe<PhotoNestedMetadataCitelworkCreateInput>;
  /** Actions for the "Photo.Metadata_City" relation: "MetadataCity.photo"'s inverse relation */
  Metadata_City?: InputMaybe<PhotoNestedMetadataCityCreateInput>;
  /** Actions for the "Photo.Metadata_CiUrlWork" relation: "MetadataCiUrlWork.photo"'s inverse relation */
  Metadata_CiUrlWork?: InputMaybe<PhotoNestedMetadataCiurlworkCreateInput>;
  /** Actions for the "Photo.Metadata_Classify" relation: "MetadataClassify.photo"'s inverse relation */
  Metadata_Classify?: InputMaybe<PhotoNestedMetadataClassifyCreateInput>;
  /** Actions for the "Photo.Metadata_Company" relation: "MetadataCompany.photo"'s inverse relation */
  Metadata_Company?: InputMaybe<PhotoNestedMetadataCompanyCreateInput>;
  /** Actions for the "Photo.Metadata_CompanyShortName" relation: "MetadataCompanyShortName.photo"'s inverse relation */
  Metadata_CompanyShortName?: InputMaybe<PhotoNestedMetadataCompanyshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_Confidentiality" relation: "MetadataConfidentiality.photo"'s inverse relation */
  Metadata_Confidentiality?: InputMaybe<PhotoNestedMetadataConfidentialityCreateInput>;
  /** Actions for the "Photo.Metadata_ContainedIn" relation: "MetadataContainedIn.photo"'s inverse relation */
  Metadata_ContainedIn?: InputMaybe<PhotoNestedMetadataContainedinCreateInput>;
  /** Actions for the "Photo.Metadata_ContentMediaKind" relation: "MetadataContentMediaKind.photo"'s inverse relation */
  Metadata_ContentMediaKind?: InputMaybe<PhotoNestedMetadataContentmediakindCreateInput>;
  /** Actions for the "Photo.Metadata_ContentValue" relation: "MetadataContentValue.photo"'s inverse relation */
  Metadata_ContentValue?: InputMaybe<PhotoNestedMetadataContentvalueCreateInput>;
  /** Actions for the "Photo.Metadata_CopyrightLayer" relation: "MetadataCopyrightLayer.photo"'s inverse relation */
  Metadata_CopyrightLayer?: InputMaybe<PhotoNestedMetadataCopyrightlayerCreateInput>;
  /** Actions for the "Photo.Metadata_Country" relation: "MetadataCountry.photo"'s inverse relation */
  Metadata_Country?: InputMaybe<PhotoNestedMetadataCountryCreateInput>;
  /** Actions for the "Photo.Metadata_CountryCode" relation: "MetadataCountryCode.photo"'s inverse relation */
  Metadata_CountryCode?: InputMaybe<PhotoNestedMetadataCountrycodeCreateInput>;
  /** Actions for the "Photo.Metadata_CreatedTime" relation: "MetadataCreatedTime.photo"'s inverse relation */
  Metadata_CreatedTime?: InputMaybe<PhotoNestedMetadataCreatedtimeCreateInput>;
  /** Actions for the "Photo.Metadata_creator" relation: "MetadataCreator.photo"'s inverse relation */
  Metadata_creator?: InputMaybe<PhotoNestedMetadataCreatorCreateInput>;
  /** Actions for the "Photo.Metadata_CreatorTool" relation: "MetadataCreatorTool.photo"'s inverse relation */
  Metadata_CreatorTool?: InputMaybe<PhotoNestedMetadataCreatortoolCreateInput>;
  /** Actions for the "Photo.Metadata_Credit" relation: "MetadataCredit.photo"'s inverse relation */
  Metadata_Credit?: InputMaybe<PhotoNestedMetadataCreditCreateInput>;
  /** Actions for the "Photo.Metadata_CustomField14" relation: "MetadataCustomField14.photo"'s inverse relation */
  Metadata_CustomField14?: InputMaybe<PhotoNestedMetadataCustomfield14CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField15" relation: "MetadataCustomField15.photo"'s inverse relation */
  Metadata_CustomField15?: InputMaybe<PhotoNestedMetadataCustomfield15CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField16" relation: "MetadataCustomField16.photo"'s inverse relation */
  Metadata_CustomField16?: InputMaybe<PhotoNestedMetadataCustomfield16CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField17" relation: "MetadataCustomField17.photo"'s inverse relation */
  Metadata_CustomField17?: InputMaybe<PhotoNestedMetadataCustomfield17CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField20" relation: "MetadataCustomField20.photo"'s inverse relation */
  Metadata_CustomField20?: InputMaybe<PhotoNestedMetadataCustomfield20CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField5" relation: "MetadataCustomField5.photo"'s inverse relation */
  Metadata_CustomField5?: InputMaybe<PhotoNestedMetadataCustomfield5CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField6" relation: "MetadataCustomField6.photo"'s inverse relation */
  Metadata_CustomField6?: InputMaybe<PhotoNestedMetadataCustomfield6CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField7" relation: "MetadataCustomField7.photo"'s inverse relation */
  Metadata_CustomField7?: InputMaybe<PhotoNestedMetadataCustomfield7CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField9" relation: "MetadataCustomField9.photo"'s inverse relation */
  Metadata_CustomField9?: InputMaybe<PhotoNestedMetadataCustomfield9CreateInput>;
  /** Actions for the "Photo.Metadata_DateCreated" relation: "MetadataDateCreated.photo"'s inverse relation */
  Metadata_DateCreated?: InputMaybe<PhotoNestedMetadataDatecreatedCreateInput>;
  /** Actions for the "Photo.Metadata_DateTimeDigitized" relation: "MetadataDateTimeDigitized.photo"'s inverse relation */
  Metadata_DateTimeDigitized?: InputMaybe<PhotoNestedMetadataDatetimedigitizedCreateInput>;
  /** Actions for the "Photo.Metadata_DateTimeOriginal" relation: "MetadataDateTimeOriginal.photo"'s inverse relation */
  Metadata_DateTimeOriginal?: InputMaybe<PhotoNestedMetadataDatetimeoriginalCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryAccount" relation: "MetadataDeliveryAccount.photo"'s inverse relation */
  Metadata_DeliveryAccount?: InputMaybe<PhotoNestedMetadataDeliveryaccountCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCompany" relation: "MetadataDeliveryCompany.photo"'s inverse relation */
  Metadata_DeliveryCompany?: InputMaybe<PhotoNestedMetadataDeliverycompanyCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCopyright" relation: "MetadataDeliveryCopyright.photo"'s inverse relation */
  Metadata_DeliveryCopyright?: InputMaybe<PhotoNestedMetadataDeliverycopyrightCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateFolder" relation: "MetadataDeliveryDateFolder.photo"'s inverse relation */
  Metadata_DeliveryDateFolder?: InputMaybe<PhotoNestedMetadataDeliverydatefolderCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateTime" relation: "MetadataDeliveryDateTime.photo"'s inverse relation */
  Metadata_DeliveryDateTime?: InputMaybe<PhotoNestedMetadataDeliverydatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcCreator" relation: "MetadataDeliveryDcCreator.photo"'s inverse relation */
  Metadata_DeliveryDcCreator?: InputMaybe<PhotoNestedMetadataDeliverydccreatorCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcRights" relation: "MetadataDeliveryDcRights.photo"'s inverse relation */
  Metadata_DeliveryDcRights?: InputMaybe<PhotoNestedMetadataDeliverydcrightsCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFileType" relation: "MetadataDeliveryFileType.photo"'s inverse relation */
  Metadata_DeliveryFileType?: InputMaybe<PhotoNestedMetadataDeliveryfiletypeCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFolder" relation: "MetadataDeliveryFolder.photo"'s inverse relation */
  Metadata_DeliveryFolder?: InputMaybe<PhotoNestedMetadataDeliveryfolderCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryKind" relation: "MetadataDeliveryKind.photo"'s inverse relation */
  Metadata_DeliveryKind?: InputMaybe<PhotoNestedMetadataDeliverykindCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPath" relation: "MetadataDeliveryPath.photo"'s inverse relation */
  Metadata_DeliveryPath?: InputMaybe<PhotoNestedMetadataDeliverypathCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPersonShownintheImage" relation: "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation */
  Metadata_DeliveryPersonShownintheImage?: InputMaybe<PhotoNestedMetadataDeliverypersonshownintheimageCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopCredit" relation: "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopCredit?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopcreditCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopSource" relation: "MetadataDeliveryPhotoshopSource.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopSource?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopsourceCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryService" relation: "MetadataDeliveryService.photo"'s inverse relation */
  Metadata_DeliveryService?: InputMaybe<PhotoNestedMetadataDeliveryserviceCreateInput>;
  /** Actions for the "Photo.Metadata_DeliverySubject" relation: "MetadataDeliverySubject.photo"'s inverse relation */
  Metadata_DeliverySubject?: InputMaybe<PhotoNestedMetadataDeliverysubjectCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryUnderSubject" relation: "MetadataDeliveryUnderSubject.photo"'s inverse relation */
  Metadata_DeliveryUnderSubject?: InputMaybe<PhotoNestedMetadataDeliveryundersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_Department" relation: "MetadataDepartment.photo"'s inverse relation */
  Metadata_Department?: InputMaybe<PhotoNestedMetadataDepartmentCreateInput>;
  /** Actions for the "Photo.Metadata_description" relation: "MetadataDescription.photo"'s inverse relation */
  Metadata_description?: InputMaybe<PhotoNestedMetadataDescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_DigitalAssetURL" relation: "MetadataDigitalAssetUrl.photo"'s inverse relation */
  Metadata_DigitalAssetURL?: InputMaybe<PhotoNestedMetadataDigitalasseturlCreateInput>;
  /** Actions for the "Photo.Metadata_Division" relation: "MetadataDivision.photo"'s inverse relation */
  Metadata_Division?: InputMaybe<PhotoNestedMetadataDivisionCreateInput>;
  /** Actions for the "Photo.Metadata_DocumentText" relation: "MetadataDocumentText.photo"'s inverse relation */
  Metadata_DocumentText?: InputMaybe<PhotoNestedMetadataDocumenttextCreateInput>;
  /** Actions for the "Photo.Metadata_Edition" relation: "MetadataEdition.photo"'s inverse relation */
  Metadata_Edition?: InputMaybe<PhotoNestedMetadataEditionCreateInput>;
  /** Actions for the "Photo.Metadata_EditorialVersion" relation: "MetadataEditorialVersion.photo"'s inverse relation */
  Metadata_EditorialVersion?: InputMaybe<PhotoNestedMetadataEditorialversionCreateInput>;
  /** Actions for the "Photo.Metadata_EditStatus" relation: "MetadataEditStatus.photo"'s inverse relation */
  Metadata_EditStatus?: InputMaybe<PhotoNestedMetadataEditstatusCreateInput>;
  /** Actions for the "Photo.Metadata_EnvironnementPhoto" relation: "MetadataEnvironnementPhoto.photo"'s inverse relation */
  Metadata_EnvironnementPhoto?: InputMaybe<PhotoNestedMetadataEnvironnementphotoCreateInput>;
  /** Actions for the "Photo.Metadata_EquipmentInstitution" relation: "MetadataEquipmentInstitution.photo"'s inverse relation */
  Metadata_EquipmentInstitution?: InputMaybe<PhotoNestedMetadataEquipmentinstitutionCreateInput>;
  /** Actions for the "Photo.Metadata_EquipmentManufacturer" relation: "MetadataEquipmentManufacturer.photo"'s inverse relation */
  Metadata_EquipmentManufacturer?: InputMaybe<PhotoNestedMetadataEquipmentmanufacturerCreateInput>;
  /** Actions for the "Photo.Metadata_Event" relation: "MetadataEvent.photo"'s inverse relation */
  Metadata_Event?: InputMaybe<PhotoNestedMetadataEventCreateInput>;
  /** Actions for the "Photo.Metadata_Exif" relation: "MetadataExif.photo"'s inverse relation */
  Metadata_Exif?: InputMaybe<PhotoNestedMetadataExifCreateInput>;
  /** Actions for the "Photo.Metadata_FabStorage" relation: "MetadataFabStorage.photo"'s inverse relation */
  Metadata_FabStorage?: InputMaybe<PhotoNestedMetadataFabstorageCreateInput>;
  /** Actions for the "Photo.Metadata_FileCheckFor" relation: "MetadataFileCheckFor.photo"'s inverse relation */
  Metadata_FileCheckFor?: InputMaybe<PhotoNestedMetadataFilecheckforCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoBitsPerPixel" relation: "MetadataFileInfoBitsPerPixel.photo"'s inverse relation */
  Metadata_FileInfoBitsPerPixel?: InputMaybe<PhotoNestedMetadataFileinfobitsperpixelCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoFrames" relation: "MetadataFileInfoFrames.photo"'s inverse relation */
  Metadata_FileInfoFrames?: InputMaybe<PhotoNestedMetadataFileinfoframesCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoPhotometricInterpretation" relation: "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation */
  Metadata_FileInfoPhotometricInterpretation?: InputMaybe<PhotoNestedMetadataFileinfophotometricinterpretationCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoTransferSyntax" relation: "MetadataFileInfoTransferSyntax.photo"'s inverse relation */
  Metadata_FileInfoTransferSyntax?: InputMaybe<PhotoNestedMetadataFileinfotransfersyntaxCreateInput>;
  /** Actions for the "Photo.Metadata_FileStage" relation: "MetadataFileStage.photo"'s inverse relation */
  Metadata_FileStage?: InputMaybe<PhotoNestedMetadataFilestageCreateInput>;
  /** Actions for the "Photo.Metadata_FileStatus" relation: "MetadataFileStatus.photo"'s inverse relation */
  Metadata_FileStatus?: InputMaybe<PhotoNestedMetadataFilestatusCreateInput>;
  /** Actions for the "Photo.Metadata_FileType" relation: "MetadataFileType.photo"'s inverse relation */
  Metadata_FileType?: InputMaybe<PhotoNestedMetadataFiletypeCreateInput>;
  /** Actions for the "Photo.Metadata_FixtureIdentifier" relation: "MetadataFixtureIdentifier.photo"'s inverse relation */
  Metadata_FixtureIdentifier?: InputMaybe<PhotoNestedMetadataFixtureidentifierCreateInput>;
  /** Actions for the "Photo.Metadata_Format" relation: "MetadataFormat.photo"'s inverse relation */
  Metadata_Format?: InputMaybe<PhotoNestedMetadataFormatCreateInput>;
  /** Actions for the "Photo.Metadata_GPSLatitude" relation: "MetadataGpsLatitude.photo"'s inverse relation */
  Metadata_GPSLatitude?: InputMaybe<PhotoNestedMetadataGpslatitudeCreateInput>;
  /** Actions for the "Photo.Metadata_GPSLongitude" relation: "MetadataGpsLongitude.photo"'s inverse relation */
  Metadata_GPSLongitude?: InputMaybe<PhotoNestedMetadataGpslongitudeCreateInput>;
  /** Actions for the "Photo.Metadata_GPSProcessingMethod" relation: "MetadataGpsProcessingMethod.photo"'s inverse relation */
  Metadata_GPSProcessingMethod?: InputMaybe<PhotoNestedMetadataGpsprocessingmethodCreateInput>;
  /** Actions for the "Photo.Metadata_Header" relation: "MetadataHeader.photo"'s inverse relation */
  Metadata_Header?: InputMaybe<PhotoNestedMetadataHeaderCreateInput>;
  /** Actions for the "Photo.Metadata_HeaderPrisma" relation: "MetadataHeaderPrisma.photo"'s inverse relation */
  Metadata_HeaderPrisma?: InputMaybe<PhotoNestedMetadataHeaderprismaCreateInput>;
  /** Actions for the "Photo.Metadata_Headline" relation: "MetadataHeadline.photo"'s inverse relation */
  Metadata_Headline?: InputMaybe<PhotoNestedMetadataHeadlineCreateInput>;
  /** Actions for the "Photo.Metadata_History" relation: "MetadataHistory.photo"'s inverse relation */
  Metadata_History?: InputMaybe<PhotoNestedMetadataHistoryCreateInput>;
  /** Actions for the "Photo.Metadata_ICCProfile" relation: "MetadataIccProfile.photo"'s inverse relation */
  Metadata_ICCProfile?: InputMaybe<PhotoNestedMetadataIccprofileCreateInput>;
  /** Actions for the "Photo.Metadata_ID_subject" relation: "MetadataIdSubject.photo"'s inverse relation */
  Metadata_ID_subject?: InputMaybe<PhotoNestedMetadataIdSubjectCreateInput>;
  /** Actions for the "Photo.Metadata_IdAssignment" relation: "MetadataIdAssignment.photo"'s inverse relation */
  Metadata_IdAssignment?: InputMaybe<PhotoNestedMetadataIdassignmentCreateInput>;
  /** Actions for the "Photo.Metadata_IdMediaContent" relation: "MetadataIdMediaContent.photo"'s inverse relation */
  Metadata_IdMediaContent?: InputMaybe<PhotoNestedMetadataIdmediacontentCreateInput>;
  /** Actions for the "Photo.Metadata_IdPhotoPrisma" relation: "MetadataIdPhotoPrisma.photo"'s inverse relation */
  Metadata_IdPhotoPrisma?: InputMaybe<PhotoNestedMetadataIdphotoprismaCreateInput>;
  /** Actions for the "Photo.Metadata_ImageNotes" relation: "MetadataImageNotes.photo"'s inverse relation */
  Metadata_ImageNotes?: InputMaybe<PhotoNestedMetadataImagenotesCreateInput>;
  /** Actions for the "Photo.Metadata_ImageUniqueID" relation: "MetadataImageUniqueId.photo"'s inverse relation */
  Metadata_ImageUniqueID?: InputMaybe<PhotoNestedMetadataImageuniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Instructions" relation: "MetadataInstructions.photo"'s inverse relation */
  Metadata_Instructions?: InputMaybe<PhotoNestedMetadataInstructionsCreateInput>;
  /** Actions for the "Photo.Metadata_IntellectualGenre" relation: "MetadataIntellectualGenre.photo"'s inverse relation */
  Metadata_IntellectualGenre?: InputMaybe<PhotoNestedMetadataIntellectualgenreCreateInput>;
  /** Actions for the "Photo.Metadata_ISOSpeedRatings" relation: "MetadataIsoSpeedRatings.photo"'s inverse relation */
  Metadata_ISOSpeedRatings?: InputMaybe<PhotoNestedMetadataIsospeedratingsCreateInput>;
  /** Actions for the "Photo.Metadata_Issue" relation: "MetadataIssue.photo"'s inverse relation */
  Metadata_Issue?: InputMaybe<PhotoNestedMetadataIssueCreateInput>;
  /** Actions for the "Photo.Metadata_JobId" relation: "MetadataJobId.photo"'s inverse relation */
  Metadata_JobId?: InputMaybe<PhotoNestedMetadataJobidCreateInput>;
  /** Actions for the "Photo.Metadata_JobProcessing" relation: "MetadataJobProcessing.photo"'s inverse relation */
  Metadata_JobProcessing?: InputMaybe<PhotoNestedMetadataJobprocessingCreateInput>;
  /** Actions for the "Photo.Metadata_LayoutPictureEditing" relation: "MetadataLayoutPictureEditing.photo"'s inverse relation */
  Metadata_LayoutPictureEditing?: InputMaybe<PhotoNestedMetadataLayoutpictureeditingCreateInput>;
  /** Actions for the "Photo.Metadata_LayoutStorage" relation: "MetadataLayoutStorage.photo"'s inverse relation */
  Metadata_LayoutStorage?: InputMaybe<PhotoNestedMetadataLayoutstorageCreateInput>;
  /** Actions for the "Photo.Metadata_license" relation: "MetadataLicense.photo"'s inverse relation */
  Metadata_license?: InputMaybe<PhotoNestedMetadataLicenseCreateInput>;
  /** Actions for the "Photo.Metadata_Links" relation: "MetadataLinks.photo"'s inverse relation */
  Metadata_Links?: InputMaybe<PhotoNestedMetadataLinksCreateInput>;
  /** Actions for the "Photo.Metadata_LocalCaption" relation: "MetadataLocalCaption.photo"'s inverse relation */
  Metadata_LocalCaption?: InputMaybe<PhotoNestedMetadataLocalcaptionCreateInput>;
  /** Actions for the "Photo.Metadata_Location" relation: "MetadataLocation.photo"'s inverse relation */
  Metadata_Location?: InputMaybe<PhotoNestedMetadataLocationCreateInput>;
  /** Actions for the "Photo.Metadata_Make" relation: "MetadataMake.photo"'s inverse relation */
  Metadata_Make?: InputMaybe<PhotoNestedMetadataMakeCreateInput>;
  /** Actions for the "Photo.Metadata_Manifest" relation: "MetadataManifest.photo"'s inverse relation */
  Metadata_Manifest?: InputMaybe<PhotoNestedMetadataManifestCreateInput>;
  /** Actions for the "Photo.Metadata_Marked" relation: "MetadataMarked.photo"'s inverse relation */
  Metadata_Marked?: InputMaybe<PhotoNestedMetadataMarkedCreateInput>;
  /** Actions for the "Photo.Metadata_MasterDocumentId" relation: "MetadataMasterDocumentId.photo"'s inverse relation */
  Metadata_MasterDocumentId?: InputMaybe<PhotoNestedMetadataMasterdocumentidCreateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailHeight" relation: "MetadataMaxAvailHeight.photo"'s inverse relation */
  Metadata_MaxAvailHeight?: InputMaybe<PhotoNestedMetadataMaxavailheightCreateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailWidth" relation: "MetadataMaxAvailWidth.photo"'s inverse relation */
  Metadata_MaxAvailWidth?: InputMaybe<PhotoNestedMetadataMaxavailwidthCreateInput>;
  /** Actions for the "Photo.Metadata_MinorModelAgeDisclosure" relation: "MetadataMinorModelAgeDisclosure.photo"'s inverse relation */
  Metadata_MinorModelAgeDisclosure?: InputMaybe<PhotoNestedMetadataMinormodelagedisclosureCreateInput>;
  /** Actions for the "Photo.Metadata_Model" relation: "MetadataModel.photo"'s inverse relation */
  Metadata_Model?: InputMaybe<PhotoNestedMetadataModelCreateInput>;
  /** Actions for the "Photo.Metadata_ModelAge" relation: "MetadataModelAge.photo"'s inverse relation */
  Metadata_ModelAge?: InputMaybe<PhotoNestedMetadataModelageCreateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseID" relation: "MetadataModelReleaseId.photo"'s inverse relation */
  Metadata_ModelReleaseID?: InputMaybe<PhotoNestedMetadataModelreleaseidCreateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseStatus" relation: "MetadataModelReleaseStatus.photo"'s inverse relation */
  Metadata_ModelReleaseStatus?: InputMaybe<PhotoNestedMetadataModelreleasestatusCreateInput>;
  /** Actions for the "Photo.Metadata_morePermissions" relation: "MetadataMorePermissions.photo"'s inverse relation */
  Metadata_morePermissions?: InputMaybe<PhotoNestedMetadataMorepermissionsCreateInput>;
  /** Actions for the "Photo.Metadata_ObjectCycle" relation: "MetadataObjectCycle.photo"'s inverse relation */
  Metadata_ObjectCycle?: InputMaybe<PhotoNestedMetadataObjectcycleCreateInput>;
  /** Actions for the "Photo.Metadata_OrganisationInImageName" relation: "MetadataOrganisationInImageName.photo"'s inverse relation */
  Metadata_OrganisationInImageName?: InputMaybe<PhotoNestedMetadataOrganisationinimagenameCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalColor" relation: "MetadataOriginalColor.photo"'s inverse relation */
  Metadata_OriginalColor?: InputMaybe<PhotoNestedMetadataOriginalcolorCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalICCProfile" relation: "MetadataOriginalIccProfile.photo"'s inverse relation */
  Metadata_OriginalICCProfile?: InputMaybe<PhotoNestedMetadataOriginaliccprofileCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalName" relation: "MetadataOriginalName.photo"'s inverse relation */
  Metadata_OriginalName?: InputMaybe<PhotoNestedMetadataOriginalnameCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalWeigth" relation: "MetadataOriginalWeigth.photo"'s inverse relation */
  Metadata_OriginalWeigth?: InputMaybe<PhotoNestedMetadataOriginalweigthCreateInput>;
  /** Actions for the "Photo.Metadata_Owner" relation: "MetadataOwner.photo"'s inverse relation */
  Metadata_Owner?: InputMaybe<PhotoNestedMetadataOwnerCreateInput>;
  /** Actions for the "Photo.Metadata_OwnerId" relation: "MetadataOwnerId.photo"'s inverse relation */
  Metadata_OwnerId?: InputMaybe<PhotoNestedMetadataOwneridCreateInput>;
  /** Actions for the "Photo.Metadata_Page" relation: "MetadataPage.photo"'s inverse relation */
  Metadata_Page?: InputMaybe<PhotoNestedMetadataPageCreateInput>;
  /** Actions for the "Photo.Metadata_PagePrisma" relation: "MetadataPagePrisma.photo"'s inverse relation */
  Metadata_PagePrisma?: InputMaybe<PhotoNestedMetadataPageprismaCreateInput>;
  /** Actions for the "Photo.Metadata_PaintBasedCorrections" relation: "MetadataPaintBasedCorrections.photo"'s inverse relation */
  Metadata_PaintBasedCorrections?: InputMaybe<PhotoNestedMetadataPaintbasedcorrectionsCreateInput>;
  /** Actions for the "Photo.Metadata_PatientDOB" relation: "MetadataPatientDob.photo"'s inverse relation */
  Metadata_PatientDOB?: InputMaybe<PhotoNestedMetadataPatientdobCreateInput>;
  /** Actions for the "Photo.Metadata_PatientID" relation: "MetadataPatientId.photo"'s inverse relation */
  Metadata_PatientID?: InputMaybe<PhotoNestedMetadataPatientidCreateInput>;
  /** Actions for the "Photo.Metadata_PatientName" relation: "MetadataPatientName.photo"'s inverse relation */
  Metadata_PatientName?: InputMaybe<PhotoNestedMetadataPatientnameCreateInput>;
  /** Actions for the "Photo.Metadata_PatientSex" relation: "MetadataPatientSex.photo"'s inverse relation */
  Metadata_PatientSex?: InputMaybe<PhotoNestedMetadataPatientsexCreateInput>;
  /** Actions for the "Photo.Metadata_PersonInImage" relation: "MetadataPersonInImage.photo"'s inverse relation */
  Metadata_PersonInImage?: InputMaybe<PhotoNestedMetadataPersoninimageCreateInput>;
  /** Actions for the "Photo.Metadata_PhotoStorage" relation: "MetadataPhotoStorage.photo"'s inverse relation */
  Metadata_PhotoStorage?: InputMaybe<PhotoNestedMetadataPhotostorageCreateInput>;
  /** Actions for the "Photo.Metadata_PrepressPictureEditing" relation: "MetadataPrepressPictureEditing.photo"'s inverse relation */
  Metadata_PrepressPictureEditing?: InputMaybe<PhotoNestedMetadataPrepresspictureeditingCreateInput>;
  /** Actions for the "Photo.Metadata_PriceLevel" relation: "MetadataPriceLevel.photo"'s inverse relation */
  Metadata_PriceLevel?: InputMaybe<PhotoNestedMetadataPricelevelCreateInput>;
  /** Actions for the "Photo.Metadata_PrintingProfile" relation: "MetadataPrintingProfile.photo"'s inverse relation */
  Metadata_PrintingProfile?: InputMaybe<PhotoNestedMetadataPrintingprofileCreateInput>;
  /** Actions for the "Photo.Metadata_PrismaProduction" relation: "MetadataPrismaProduction.photo"'s inverse relation */
  Metadata_PrismaProduction?: InputMaybe<PhotoNestedMetadataPrismaproductionCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessHistory" relation: "MetadataProcessHistory.photo"'s inverse relation */
  Metadata_ProcessHistory?: InputMaybe<PhotoNestedMetadataProcesshistoryCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessParameter" relation: "MetadataProcessParameter.photo"'s inverse relation */
  Metadata_ProcessParameter?: InputMaybe<PhotoNestedMetadataProcessparameterCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessStatus" relation: "MetadataProcessStatus.photo"'s inverse relation */
  Metadata_ProcessStatus?: InputMaybe<PhotoNestedMetadataProcessstatusCreateInput>;
  /** Actions for the "Photo.Metadata_Product" relation: "MetadataProduct.photo"'s inverse relation */
  Metadata_Product?: InputMaybe<PhotoNestedMetadataProductCreateInput>;
  /** Actions for the "Photo.Metadata_Products" relation: "MetadataProducts.photo"'s inverse relation */
  Metadata_Products?: InputMaybe<PhotoNestedMetadataProductsCreateInput>;
  /** Actions for the "Photo.Metadata_ProductShortName" relation: "MetadataProductShortName.photo"'s inverse relation */
  Metadata_ProductShortName?: InputMaybe<PhotoNestedMetadataProductshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_Produits" relation: "MetadataProduits.photo"'s inverse relation */
  Metadata_Produits?: InputMaybe<PhotoNestedMetadataProduitsCreateInput>;
  /** Actions for the "Photo.Metadata_ProgramVersion" relation: "MetadataProgramVersion.photo"'s inverse relation */
  Metadata_ProgramVersion?: InputMaybe<PhotoNestedMetadataProgramversionCreateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseID" relation: "MetadataPropertyReleaseId.photo"'s inverse relation */
  Metadata_PropertyReleaseID?: InputMaybe<PhotoNestedMetadataPropertyreleaseidCreateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseStatus" relation: "MetadataPropertyReleaseStatus.photo"'s inverse relation */
  Metadata_PropertyReleaseStatus?: InputMaybe<PhotoNestedMetadataPropertyreleasestatusCreateInput>;
  /** Actions for the "Photo.Metadata_Publisher" relation: "MetadataPublisher.photo"'s inverse relation */
  Metadata_Publisher?: InputMaybe<PhotoNestedMetadataPublisherCreateInput>;
  /** Actions for the "Photo.Metadata_publishingIssue" relation: "MetadataPublishingIssue.photo"'s inverse relation */
  Metadata_publishingIssue?: InputMaybe<PhotoNestedMetadataPublishingissueCreateInput>;
  /** Actions for the "Photo.Metadata_publishingSubject" relation: "MetadataPublishingSubject.photo"'s inverse relation */
  Metadata_publishingSubject?: InputMaybe<PhotoNestedMetadataPublishingsubjectCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaBy" relation: "MetadataQualifiedUsePrismaBy.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaBy?: InputMaybe<PhotoNestedMetadataQualifieduseprismabyCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDate" relation: "MetadataQualifiedUsePrismaDate.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDate?: InputMaybe<PhotoNestedMetadataQualifieduseprismadateCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDuration" relation: "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDuration?: InputMaybe<PhotoNestedMetadataQualifieduseprismadurationCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaSupport" relation: "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaSupport?: InputMaybe<PhotoNestedMetadataQualifieduseprismasupportCreateInput>;
  /** Actions for the "Photo.Metadata_Rating" relation: "MetadataRating.photo"'s inverse relation */
  Metadata_Rating?: InputMaybe<PhotoNestedMetadataRatingCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceDate" relation: "MetadataReferenceDate.photo"'s inverse relation */
  Metadata_ReferenceDate?: InputMaybe<PhotoNestedMetadataReferencedateCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceNumber" relation: "MetadataReferenceNumber.photo"'s inverse relation */
  Metadata_ReferenceNumber?: InputMaybe<PhotoNestedMetadataReferencenumberCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceService" relation: "MetadataReferenceService.photo"'s inverse relation */
  Metadata_ReferenceService?: InputMaybe<PhotoNestedMetadataReferenceserviceCreateInput>;
  /** Actions for the "Photo.Metadata_ReleaseDate" relation: "MetadataReleaseDate.photo"'s inverse relation */
  Metadata_ReleaseDate?: InputMaybe<PhotoNestedMetadataReleasedateCreateInput>;
  /** Actions for the "Photo.Metadata_ReleaseTime" relation: "MetadataReleaseTime.photo"'s inverse relation */
  Metadata_ReleaseTime?: InputMaybe<PhotoNestedMetadataReleasetimeCreateInput>;
  /** Actions for the "Photo.Metadata_RequiredPermission" relation: "MetadataRequiredPermission.photo"'s inverse relation */
  Metadata_RequiredPermission?: InputMaybe<PhotoNestedMetadataRequiredpermissionCreateInput>;
  /** Actions for the "Photo.Metadata_ResolutionKind" relation: "MetadataResolutionKind.photo"'s inverse relation */
  Metadata_ResolutionKind?: InputMaybe<PhotoNestedMetadataResolutionkindCreateInput>;
  /** Actions for the "Photo.Metadata_rights" relation: "MetadataRights.photo"'s inverse relation */
  Metadata_rights?: InputMaybe<PhotoNestedMetadataRightsCreateInput>;
  /** Actions for the "Photo.Metadata_Royaltyfree" relation: "MetadataRoyaltyfree.photo"'s inverse relation */
  Metadata_Royaltyfree?: InputMaybe<PhotoNestedMetadataRoyaltyfreeCreateInput>;
  /** Actions for the "Photo.Metadata_Scene" relation: "MetadataScene.photo"'s inverse relation */
  Metadata_Scene?: InputMaybe<PhotoNestedMetadataSceneCreateInput>;
  /** Actions for the "Photo.Metadata_Section" relation: "MetadataSection.photo"'s inverse relation */
  Metadata_Section?: InputMaybe<PhotoNestedMetadataSectionCreateInput>;
  /** Actions for the "Photo.Metadata_SentTo" relation: "MetadataSentTo.photo"'s inverse relation */
  Metadata_SentTo?: InputMaybe<PhotoNestedMetadataSenttoCreateInput>;
  /** Actions for the "Photo.Metadata_SerialNumber" relation: "MetadataSerialNumber.photo"'s inverse relation */
  Metadata_SerialNumber?: InputMaybe<PhotoNestedMetadataSerialnumberCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesDateTime" relation: "MetadataSeriesDateTime.photo"'s inverse relation */
  Metadata_SeriesDateTime?: InputMaybe<PhotoNestedMetadataSeriesdatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesDescription" relation: "MetadataSeriesDescription.photo"'s inverse relation */
  Metadata_SeriesDescription?: InputMaybe<PhotoNestedMetadataSeriesdescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesModality" relation: "MetadataSeriesModality.photo"'s inverse relation */
  Metadata_SeriesModality?: InputMaybe<PhotoNestedMetadataSeriesmodalityCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesNumber" relation: "MetadataSeriesNumber.photo"'s inverse relation */
  Metadata_SeriesNumber?: InputMaybe<PhotoNestedMetadataSeriesnumberCreateInput>;
  /** Actions for the "Photo.Metadata_ShortUniqueId" relation: "MetadataShortUniqueId.photo"'s inverse relation */
  Metadata_ShortUniqueId?: InputMaybe<PhotoNestedMetadataShortuniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Source" relation: "MetadataSource.photo"'s inverse relation */
  Metadata_Source?: InputMaybe<PhotoNestedMetadataSourceCreateInput>;
  /** Actions for the "Photo.Metadata_State" relation: "MetadataState.photo"'s inverse relation */
  Metadata_State?: InputMaybe<PhotoNestedMetadataStateCreateInput>;
  /** Actions for the "Photo.Metadata_StudyDateTime" relation: "MetadataStudyDateTime.photo"'s inverse relation */
  Metadata_StudyDateTime?: InputMaybe<PhotoNestedMetadataStudydatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_StudyDescription" relation: "MetadataStudyDescription.photo"'s inverse relation */
  Metadata_StudyDescription?: InputMaybe<PhotoNestedMetadataStudydescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_StudyID" relation: "MetadataStudyId.photo"'s inverse relation */
  Metadata_StudyID?: InputMaybe<PhotoNestedMetadataStudyidCreateInput>;
  /** Actions for the "Photo.Metadata_StudyPhysician" relation: "MetadataStudyPhysician.photo"'s inverse relation */
  Metadata_StudyPhysician?: InputMaybe<PhotoNestedMetadataStudyphysicianCreateInput>;
  /** Actions for the "Photo.Metadata_subject" relation: "MetadataSubject.photo"'s inverse relation */
  Metadata_subject?: InputMaybe<PhotoNestedMetadataSubjectCreateInput>;
  /** Actions for the "Photo.Metadata_SubjectCode" relation: "MetadataSubjectCode.photo"'s inverse relation */
  Metadata_SubjectCode?: InputMaybe<PhotoNestedMetadataSubjectcodeCreateInput>;
  /** Actions for the "Photo.Metadata_SubjectPrisma" relation: "MetadataSubjectPrisma.photo"'s inverse relation */
  Metadata_SubjectPrisma?: InputMaybe<PhotoNestedMetadataSubjectprismaCreateInput>;
  /** Actions for the "Photo.Metadata_SupplementalCategories" relation: "MetadataSupplementalCategories.photo"'s inverse relation */
  Metadata_SupplementalCategories?: InputMaybe<PhotoNestedMetadataSupplementalcategoriesCreateInput>;
  /** Actions for the "Photo.Metadata_TargetVersion" relation: "MetadataTargetVersion.photo"'s inverse relation */
  Metadata_TargetVersion?: InputMaybe<PhotoNestedMetadataTargetversionCreateInput>;
  /** Actions for the "Photo.Metadata_title" relation: "MetadataTitle.photo"'s inverse relation */
  Metadata_title?: InputMaybe<PhotoNestedMetadataTitleCreateInput>;
  /** Actions for the "Photo.Metadata_ToneCurve" relation: "MetadataToneCurve.photo"'s inverse relation */
  Metadata_ToneCurve?: InputMaybe<PhotoNestedMetadataTonecurveCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredBy" relation: "MetadataTransferredBy.photo"'s inverse relation */
  Metadata_TransferredBy?: InputMaybe<PhotoNestedMetadataTransferredbyCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredByEmail" relation: "MetadataTransferredByEmail.photo"'s inverse relation */
  Metadata_TransferredByEmail?: InputMaybe<PhotoNestedMetadataTransferredbyemailCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredByFullName" relation: "MetadataTransferredByFullName.photo"'s inverse relation */
  Metadata_TransferredByFullName?: InputMaybe<PhotoNestedMetadataTransferredbyfullnameCreateInput>;
  /** Actions for the "Photo.Metadata_TransmissionReference" relation: "MetadataTransmissionReference.photo"'s inverse relation */
  Metadata_TransmissionReference?: InputMaybe<PhotoNestedMetadataTransmissionreferenceCreateInput>;
  /** Actions for the "Photo.Metadata_Type" relation: "MetadataType.photo"'s inverse relation */
  Metadata_Type?: InputMaybe<PhotoNestedMetadataTypeCreateInput>;
  /** Actions for the "Photo.Metadata_Undersubject" relation: "MetadataUndersubject.photo"'s inverse relation */
  Metadata_Undersubject?: InputMaybe<PhotoNestedMetadataUndersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_UnderUnderSubject" relation: "MetadataUnderUnderSubject.photo"'s inverse relation */
  Metadata_UnderUnderSubject?: InputMaybe<PhotoNestedMetadataUnderundersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_UniqueId" relation: "MetadataUniqueId.photo"'s inverse relation */
  Metadata_UniqueId?: InputMaybe<PhotoNestedMetadataUniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Unit" relation: "MetadataUnit.photo"'s inverse relation */
  Metadata_Unit?: InputMaybe<PhotoNestedMetadataUnitCreateInput>;
  /** Actions for the "Photo.Metadata_UnitShortName" relation: "MetadataUnitShortName.photo"'s inverse relation */
  Metadata_UnitShortName?: InputMaybe<PhotoNestedMetadataUnitshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_UploadedBy" relation: "MetadataUploadedBy.photo"'s inverse relation */
  Metadata_UploadedBy?: InputMaybe<PhotoNestedMetadataUploadedbyCreateInput>;
  /** Actions for the "Photo.Metadata_UploadedByFullName" relation: "MetadataUploadedByFullName.photo"'s inverse relation */
  Metadata_UploadedByFullName?: InputMaybe<PhotoNestedMetadataUploadedbyfullnameCreateInput>;
  /** Actions for the "Photo.Metadata_UploadTime" relation: "MetadataUploadTime.photo"'s inverse relation */
  Metadata_UploadTime?: InputMaybe<PhotoNestedMetadataUploadtimeCreateInput>;
  /** Actions for the "Photo.Metadata_Urgency" relation: "MetadataUrgency.photo"'s inverse relation */
  Metadata_Urgency?: InputMaybe<PhotoNestedMetadataUrgencyCreateInput>;
  /** Actions for the "Photo.Metadata_UsageTerms" relation: "MetadataUsageTerms.photo"'s inverse relation */
  Metadata_UsageTerms?: InputMaybe<PhotoNestedMetadataUsagetermsCreateInput>;
  /** Actions for the "Photo.Metadata_UserComment" relation: "MetadataUserComment.photo"'s inverse relation */
  Metadata_UserComment?: InputMaybe<PhotoNestedMetadataUsercommentCreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined195" relation: "MetadataUserDefined195.photo"'s inverse relation */
  Metadata_UserDefined195?: InputMaybe<PhotoNestedMetadataUserdefined195CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined237" relation: "MetadataUserDefined237.photo"'s inverse relation */
  Metadata_UserDefined237?: InputMaybe<PhotoNestedMetadataUserdefined237CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined238" relation: "MetadataUserDefined238.photo"'s inverse relation */
  Metadata_UserDefined238?: InputMaybe<PhotoNestedMetadataUserdefined238CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined239" relation: "MetadataUserDefined239.photo"'s inverse relation */
  Metadata_UserDefined239?: InputMaybe<PhotoNestedMetadataUserdefined239CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined242" relation: "MetadataUserDefined242.photo"'s inverse relation */
  Metadata_UserDefined242?: InputMaybe<PhotoNestedMetadataUserdefined242CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined62" relation: "MetadataUserDefined62.photo"'s inverse relation */
  Metadata_UserDefined62?: InputMaybe<PhotoNestedMetadataUserdefined62CreateInput>;
  /** Actions for the "Photo.Metadata_Valid" relation: "MetadataValid.photo"'s inverse relation */
  Metadata_Valid?: InputMaybe<PhotoNestedMetadataValidCreateInput>;
  /** Actions for the "Photo.Metadata_Version" relation: "MetadataVersion.photo"'s inverse relation */
  Metadata_Version?: InputMaybe<PhotoNestedMetadataVersionCreateInput>;
  /** Actions for the "Photo.Metadata_WebStatement" relation: "MetadataWebStatement.photo"'s inverse relation */
  Metadata_WebStatement?: InputMaybe<PhotoNestedMetadataWebstatementCreateInput>;
  /** Actions for the "Photo.Metadata_WorkflowKind" relation: "MetadataWorkflowKind.photo"'s inverse relation */
  Metadata_WorkflowKind?: InputMaybe<PhotoNestedMetadataWorkflowkindCreateInput>;
  /** Actions for the "Photo.Metadata_XMPFileStamps" relation: "MetadataXmpFileStamps.photo"'s inverse relation */
  Metadata_XMPFileStamps?: InputMaybe<PhotoNestedMetadataXmpfilestampsCreateInput>;
  /** Actions for the "Photo.Metadata_XMPHistory" relation: "MetadataXmpHistory.photo"'s inverse relation */
  Metadata_XMPHistory?: InputMaybe<PhotoNestedMetadataXmphistoryCreateInput>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.photoMetadatas" relation: "PhotoMetadata.photo"'s inverse relation */
  photoMetadatas?: InputMaybe<PhotoNestedPhotoMetadatasCreateInput>;
  /** Actions for the "Photo.photoPublications" relation: "PhotoPublication.photo"'s inverse relation */
  photoPublications?: InputMaybe<PhotoNestedPhotoPublicationsCreateInput>;
  synced?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date of the photo's capture */
  takenAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type PhotoWithForcedAgencyUpdateInput = {
  /** Actions for the "Photo.archive" relation */
  archive?: InputMaybe<PhotoNestedArchiveUpdateInput>;
  /** Actions for the "Photo.category" relation */
  category?: InputMaybe<PhotoNestedCategoryUpdateInput>;
  /** asset content type */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.deliverySubject" relation */
  deliverySubject?: InputMaybe<PhotoNestedDeliverySubjectUpdateInput>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The date of the photo's importation */
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Actions for the "Photo.importQueue" relation: "ImportQueue.photo"'s inverse relation */
  importQueue?: InputMaybe<PhotoNestedImportQueueUpdateInput>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** Actions for the "Photo.Metadata_AccessRights" relation: "MetadataAccessRights.photo"'s inverse relation */
  Metadata_AccessRights?: InputMaybe<PhotoNestedMetadataAccessrightsUpdateInput>;
  /** Actions for the "Photo.Metadata_AddlModelInfo" relation: "MetadataAddlModelInfo.photo"'s inverse relation */
  Metadata_AddlModelInfo?: InputMaybe<PhotoNestedMetadataAddlmodelinfoUpdateInput>;
  /** Actions for the "Photo.Metadata_Archive_child" relation: "MetadataArchiveChild.photo"'s inverse relation */
  Metadata_Archive_child?: InputMaybe<PhotoNestedMetadataArchiveChildUpdateInput>;
  /** Actions for the "Photo.Metadata_Archive_parent" relation: "MetadataArchiveParent.photo"'s inverse relation */
  Metadata_Archive_parent?: InputMaybe<PhotoNestedMetadataArchiveParentUpdateInput>;
  /** Actions for the "Photo.Metadata_AssetState" relation: "MetadataAssetState.photo"'s inverse relation */
  Metadata_AssetState?: InputMaybe<PhotoNestedMetadataAssetstateUpdateInput>;
  /** Actions for the "Photo.Metadata_attributionName" relation: "MetadataAttributionName.photo"'s inverse relation */
  Metadata_attributionName?: InputMaybe<PhotoNestedMetadataAttributionnameUpdateInput>;
  /** Actions for the "Photo.Metadata_attributionURL" relation: "MetadataAttributionUrl.photo"'s inverse relation */
  Metadata_attributionURL?: InputMaybe<PhotoNestedMetadataAttributionurlUpdateInput>;
  /** Actions for the "Photo.Metadata_Audience" relation: "MetadataAudience.photo"'s inverse relation */
  Metadata_Audience?: InputMaybe<PhotoNestedMetadataAudienceUpdateInput>;
  /** Actions for the "Photo.Metadata_AuthorsPosition" relation: "MetadataAuthorsPosition.photo"'s inverse relation */
  Metadata_AuthorsPosition?: InputMaybe<PhotoNestedMetadataAuthorspositionUpdateInput>;
  /** Actions for the "Photo.Metadata_BackupName" relation: "MetadataBackupName.photo"'s inverse relation */
  Metadata_BackupName?: InputMaybe<PhotoNestedMetadataBackupnameUpdateInput>;
  /** Actions for the "Photo.Metadata_BitmapGrayscalePicture" relation: "MetadataBitmapGrayscalePicture.photo"'s inverse relation */
  Metadata_BitmapGrayscalePicture?: InputMaybe<PhotoNestedMetadataBitmapgrayscalepictureUpdateInput>;
  /** Actions for the "Photo.Metadata_Brand" relation: "MetadataBrand.photo"'s inverse relation */
  Metadata_Brand?: InputMaybe<PhotoNestedMetadataBrandUpdateInput>;
  /** Actions for the "Photo.Metadata_BrandPrisma" relation: "MetadataBrandPrisma.photo"'s inverse relation */
  Metadata_BrandPrisma?: InputMaybe<PhotoNestedMetadataBrandprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_BrandShortName" relation: "MetadataBrandShortName.photo"'s inverse relation */
  Metadata_BrandShortName?: InputMaybe<PhotoNestedMetadataBrandshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_CaptionWriter" relation: "MetadataCaptionWriter.photo"'s inverse relation */
  Metadata_CaptionWriter?: InputMaybe<PhotoNestedMetadataCaptionwriterUpdateInput>;
  /** Actions for the "Photo.Metadata_Category" relation: "MetadataCategory.photo"'s inverse relation */
  Metadata_Category?: InputMaybe<PhotoNestedMetadataCategoryUpdateInput>;
  /** Actions for the "Photo.Metadata_CategoryPrisma" relation: "MetadataCategoryPrisma.photo"'s inverse relation */
  Metadata_CategoryPrisma?: InputMaybe<PhotoNestedMetadataCategoryprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_Certificate" relation: "MetadataCertificate.photo"'s inverse relation */
  Metadata_Certificate?: InputMaybe<PhotoNestedMetadataCertificateUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCity" relation: "MetadataCiAdrCity.photo"'s inverse relation */
  Metadata_CiAdrCity?: InputMaybe<PhotoNestedMetadataCiadrcityUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCtry" relation: "MetadataCiAdrCtry.photo"'s inverse relation */
  Metadata_CiAdrCtry?: InputMaybe<PhotoNestedMetadataCiadrctryUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrExtadr" relation: "MetadataCiAdrExtadr.photo"'s inverse relation */
  Metadata_CiAdrExtadr?: InputMaybe<PhotoNestedMetadataCiadrextadrUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrPcode" relation: "MetadataCiAdrPcode.photo"'s inverse relation */
  Metadata_CiAdrPcode?: InputMaybe<PhotoNestedMetadataCiadrpcodeUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrRegion" relation: "MetadataCiAdrRegion.photo"'s inverse relation */
  Metadata_CiAdrRegion?: InputMaybe<PhotoNestedMetadataCiadrregionUpdateInput>;
  /** Actions for the "Photo.Metadata_CiEmailWork" relation: "MetadataCiEmailWork.photo"'s inverse relation */
  Metadata_CiEmailWork?: InputMaybe<PhotoNestedMetadataCiemailworkUpdateInput>;
  /** Actions for the "Photo.Metadata_CiTelWork" relation: "MetadataCiTelWork.photo"'s inverse relation */
  Metadata_CiTelWork?: InputMaybe<PhotoNestedMetadataCitelworkUpdateInput>;
  /** Actions for the "Photo.Metadata_City" relation: "MetadataCity.photo"'s inverse relation */
  Metadata_City?: InputMaybe<PhotoNestedMetadataCityUpdateInput>;
  /** Actions for the "Photo.Metadata_CiUrlWork" relation: "MetadataCiUrlWork.photo"'s inverse relation */
  Metadata_CiUrlWork?: InputMaybe<PhotoNestedMetadataCiurlworkUpdateInput>;
  /** Actions for the "Photo.Metadata_Classify" relation: "MetadataClassify.photo"'s inverse relation */
  Metadata_Classify?: InputMaybe<PhotoNestedMetadataClassifyUpdateInput>;
  /** Actions for the "Photo.Metadata_Company" relation: "MetadataCompany.photo"'s inverse relation */
  Metadata_Company?: InputMaybe<PhotoNestedMetadataCompanyUpdateInput>;
  /** Actions for the "Photo.Metadata_CompanyShortName" relation: "MetadataCompanyShortName.photo"'s inverse relation */
  Metadata_CompanyShortName?: InputMaybe<PhotoNestedMetadataCompanyshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_Confidentiality" relation: "MetadataConfidentiality.photo"'s inverse relation */
  Metadata_Confidentiality?: InputMaybe<PhotoNestedMetadataConfidentialityUpdateInput>;
  /** Actions for the "Photo.Metadata_ContainedIn" relation: "MetadataContainedIn.photo"'s inverse relation */
  Metadata_ContainedIn?: InputMaybe<PhotoNestedMetadataContainedinUpdateInput>;
  /** Actions for the "Photo.Metadata_ContentMediaKind" relation: "MetadataContentMediaKind.photo"'s inverse relation */
  Metadata_ContentMediaKind?: InputMaybe<PhotoNestedMetadataContentmediakindUpdateInput>;
  /** Actions for the "Photo.Metadata_ContentValue" relation: "MetadataContentValue.photo"'s inverse relation */
  Metadata_ContentValue?: InputMaybe<PhotoNestedMetadataContentvalueUpdateInput>;
  /** Actions for the "Photo.Metadata_CopyrightLayer" relation: "MetadataCopyrightLayer.photo"'s inverse relation */
  Metadata_CopyrightLayer?: InputMaybe<PhotoNestedMetadataCopyrightlayerUpdateInput>;
  /** Actions for the "Photo.Metadata_Country" relation: "MetadataCountry.photo"'s inverse relation */
  Metadata_Country?: InputMaybe<PhotoNestedMetadataCountryUpdateInput>;
  /** Actions for the "Photo.Metadata_CountryCode" relation: "MetadataCountryCode.photo"'s inverse relation */
  Metadata_CountryCode?: InputMaybe<PhotoNestedMetadataCountrycodeUpdateInput>;
  /** Actions for the "Photo.Metadata_CreatedTime" relation: "MetadataCreatedTime.photo"'s inverse relation */
  Metadata_CreatedTime?: InputMaybe<PhotoNestedMetadataCreatedtimeUpdateInput>;
  /** Actions for the "Photo.Metadata_creator" relation: "MetadataCreator.photo"'s inverse relation */
  Metadata_creator?: InputMaybe<PhotoNestedMetadataCreatorUpdateInput>;
  /** Actions for the "Photo.Metadata_CreatorTool" relation: "MetadataCreatorTool.photo"'s inverse relation */
  Metadata_CreatorTool?: InputMaybe<PhotoNestedMetadataCreatortoolUpdateInput>;
  /** Actions for the "Photo.Metadata_Credit" relation: "MetadataCredit.photo"'s inverse relation */
  Metadata_Credit?: InputMaybe<PhotoNestedMetadataCreditUpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField14" relation: "MetadataCustomField14.photo"'s inverse relation */
  Metadata_CustomField14?: InputMaybe<PhotoNestedMetadataCustomfield14UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField15" relation: "MetadataCustomField15.photo"'s inverse relation */
  Metadata_CustomField15?: InputMaybe<PhotoNestedMetadataCustomfield15UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField16" relation: "MetadataCustomField16.photo"'s inverse relation */
  Metadata_CustomField16?: InputMaybe<PhotoNestedMetadataCustomfield16UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField17" relation: "MetadataCustomField17.photo"'s inverse relation */
  Metadata_CustomField17?: InputMaybe<PhotoNestedMetadataCustomfield17UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField20" relation: "MetadataCustomField20.photo"'s inverse relation */
  Metadata_CustomField20?: InputMaybe<PhotoNestedMetadataCustomfield20UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField5" relation: "MetadataCustomField5.photo"'s inverse relation */
  Metadata_CustomField5?: InputMaybe<PhotoNestedMetadataCustomfield5UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField6" relation: "MetadataCustomField6.photo"'s inverse relation */
  Metadata_CustomField6?: InputMaybe<PhotoNestedMetadataCustomfield6UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField7" relation: "MetadataCustomField7.photo"'s inverse relation */
  Metadata_CustomField7?: InputMaybe<PhotoNestedMetadataCustomfield7UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField9" relation: "MetadataCustomField9.photo"'s inverse relation */
  Metadata_CustomField9?: InputMaybe<PhotoNestedMetadataCustomfield9UpdateInput>;
  /** Actions for the "Photo.Metadata_DateCreated" relation: "MetadataDateCreated.photo"'s inverse relation */
  Metadata_DateCreated?: InputMaybe<PhotoNestedMetadataDatecreatedUpdateInput>;
  /** Actions for the "Photo.Metadata_DateTimeDigitized" relation: "MetadataDateTimeDigitized.photo"'s inverse relation */
  Metadata_DateTimeDigitized?: InputMaybe<PhotoNestedMetadataDatetimedigitizedUpdateInput>;
  /** Actions for the "Photo.Metadata_DateTimeOriginal" relation: "MetadataDateTimeOriginal.photo"'s inverse relation */
  Metadata_DateTimeOriginal?: InputMaybe<PhotoNestedMetadataDatetimeoriginalUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryAccount" relation: "MetadataDeliveryAccount.photo"'s inverse relation */
  Metadata_DeliveryAccount?: InputMaybe<PhotoNestedMetadataDeliveryaccountUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCompany" relation: "MetadataDeliveryCompany.photo"'s inverse relation */
  Metadata_DeliveryCompany?: InputMaybe<PhotoNestedMetadataDeliverycompanyUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCopyright" relation: "MetadataDeliveryCopyright.photo"'s inverse relation */
  Metadata_DeliveryCopyright?: InputMaybe<PhotoNestedMetadataDeliverycopyrightUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateFolder" relation: "MetadataDeliveryDateFolder.photo"'s inverse relation */
  Metadata_DeliveryDateFolder?: InputMaybe<PhotoNestedMetadataDeliverydatefolderUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateTime" relation: "MetadataDeliveryDateTime.photo"'s inverse relation */
  Metadata_DeliveryDateTime?: InputMaybe<PhotoNestedMetadataDeliverydatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcCreator" relation: "MetadataDeliveryDcCreator.photo"'s inverse relation */
  Metadata_DeliveryDcCreator?: InputMaybe<PhotoNestedMetadataDeliverydccreatorUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcRights" relation: "MetadataDeliveryDcRights.photo"'s inverse relation */
  Metadata_DeliveryDcRights?: InputMaybe<PhotoNestedMetadataDeliverydcrightsUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFileType" relation: "MetadataDeliveryFileType.photo"'s inverse relation */
  Metadata_DeliveryFileType?: InputMaybe<PhotoNestedMetadataDeliveryfiletypeUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFolder" relation: "MetadataDeliveryFolder.photo"'s inverse relation */
  Metadata_DeliveryFolder?: InputMaybe<PhotoNestedMetadataDeliveryfolderUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryKind" relation: "MetadataDeliveryKind.photo"'s inverse relation */
  Metadata_DeliveryKind?: InputMaybe<PhotoNestedMetadataDeliverykindUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPath" relation: "MetadataDeliveryPath.photo"'s inverse relation */
  Metadata_DeliveryPath?: InputMaybe<PhotoNestedMetadataDeliverypathUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPersonShownintheImage" relation: "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation */
  Metadata_DeliveryPersonShownintheImage?: InputMaybe<PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopCredit" relation: "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopCredit?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopSource" relation: "MetadataDeliveryPhotoshopSource.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopSource?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryService" relation: "MetadataDeliveryService.photo"'s inverse relation */
  Metadata_DeliveryService?: InputMaybe<PhotoNestedMetadataDeliveryserviceUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliverySubject" relation: "MetadataDeliverySubject.photo"'s inverse relation */
  Metadata_DeliverySubject?: InputMaybe<PhotoNestedMetadataDeliverysubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryUnderSubject" relation: "MetadataDeliveryUnderSubject.photo"'s inverse relation */
  Metadata_DeliveryUnderSubject?: InputMaybe<PhotoNestedMetadataDeliveryundersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_Department" relation: "MetadataDepartment.photo"'s inverse relation */
  Metadata_Department?: InputMaybe<PhotoNestedMetadataDepartmentUpdateInput>;
  /** Actions for the "Photo.Metadata_description" relation: "MetadataDescription.photo"'s inverse relation */
  Metadata_description?: InputMaybe<PhotoNestedMetadataDescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_DigitalAssetURL" relation: "MetadataDigitalAssetUrl.photo"'s inverse relation */
  Metadata_DigitalAssetURL?: InputMaybe<PhotoNestedMetadataDigitalasseturlUpdateInput>;
  /** Actions for the "Photo.Metadata_Division" relation: "MetadataDivision.photo"'s inverse relation */
  Metadata_Division?: InputMaybe<PhotoNestedMetadataDivisionUpdateInput>;
  /** Actions for the "Photo.Metadata_DocumentText" relation: "MetadataDocumentText.photo"'s inverse relation */
  Metadata_DocumentText?: InputMaybe<PhotoNestedMetadataDocumenttextUpdateInput>;
  /** Actions for the "Photo.Metadata_Edition" relation: "MetadataEdition.photo"'s inverse relation */
  Metadata_Edition?: InputMaybe<PhotoNestedMetadataEditionUpdateInput>;
  /** Actions for the "Photo.Metadata_EditorialVersion" relation: "MetadataEditorialVersion.photo"'s inverse relation */
  Metadata_EditorialVersion?: InputMaybe<PhotoNestedMetadataEditorialversionUpdateInput>;
  /** Actions for the "Photo.Metadata_EditStatus" relation: "MetadataEditStatus.photo"'s inverse relation */
  Metadata_EditStatus?: InputMaybe<PhotoNestedMetadataEditstatusUpdateInput>;
  /** Actions for the "Photo.Metadata_EnvironnementPhoto" relation: "MetadataEnvironnementPhoto.photo"'s inverse relation */
  Metadata_EnvironnementPhoto?: InputMaybe<PhotoNestedMetadataEnvironnementphotoUpdateInput>;
  /** Actions for the "Photo.Metadata_EquipmentInstitution" relation: "MetadataEquipmentInstitution.photo"'s inverse relation */
  Metadata_EquipmentInstitution?: InputMaybe<PhotoNestedMetadataEquipmentinstitutionUpdateInput>;
  /** Actions for the "Photo.Metadata_EquipmentManufacturer" relation: "MetadataEquipmentManufacturer.photo"'s inverse relation */
  Metadata_EquipmentManufacturer?: InputMaybe<PhotoNestedMetadataEquipmentmanufacturerUpdateInput>;
  /** Actions for the "Photo.Metadata_Event" relation: "MetadataEvent.photo"'s inverse relation */
  Metadata_Event?: InputMaybe<PhotoNestedMetadataEventUpdateInput>;
  /** Actions for the "Photo.Metadata_Exif" relation: "MetadataExif.photo"'s inverse relation */
  Metadata_Exif?: InputMaybe<PhotoNestedMetadataExifUpdateInput>;
  /** Actions for the "Photo.Metadata_FabStorage" relation: "MetadataFabStorage.photo"'s inverse relation */
  Metadata_FabStorage?: InputMaybe<PhotoNestedMetadataFabstorageUpdateInput>;
  /** Actions for the "Photo.Metadata_FileCheckFor" relation: "MetadataFileCheckFor.photo"'s inverse relation */
  Metadata_FileCheckFor?: InputMaybe<PhotoNestedMetadataFilecheckforUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoBitsPerPixel" relation: "MetadataFileInfoBitsPerPixel.photo"'s inverse relation */
  Metadata_FileInfoBitsPerPixel?: InputMaybe<PhotoNestedMetadataFileinfobitsperpixelUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoFrames" relation: "MetadataFileInfoFrames.photo"'s inverse relation */
  Metadata_FileInfoFrames?: InputMaybe<PhotoNestedMetadataFileinfoframesUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoPhotometricInterpretation" relation: "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation */
  Metadata_FileInfoPhotometricInterpretation?: InputMaybe<PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoTransferSyntax" relation: "MetadataFileInfoTransferSyntax.photo"'s inverse relation */
  Metadata_FileInfoTransferSyntax?: InputMaybe<PhotoNestedMetadataFileinfotransfersyntaxUpdateInput>;
  /** Actions for the "Photo.Metadata_FileStage" relation: "MetadataFileStage.photo"'s inverse relation */
  Metadata_FileStage?: InputMaybe<PhotoNestedMetadataFilestageUpdateInput>;
  /** Actions for the "Photo.Metadata_FileStatus" relation: "MetadataFileStatus.photo"'s inverse relation */
  Metadata_FileStatus?: InputMaybe<PhotoNestedMetadataFilestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_FileType" relation: "MetadataFileType.photo"'s inverse relation */
  Metadata_FileType?: InputMaybe<PhotoNestedMetadataFiletypeUpdateInput>;
  /** Actions for the "Photo.Metadata_FixtureIdentifier" relation: "MetadataFixtureIdentifier.photo"'s inverse relation */
  Metadata_FixtureIdentifier?: InputMaybe<PhotoNestedMetadataFixtureidentifierUpdateInput>;
  /** Actions for the "Photo.Metadata_Format" relation: "MetadataFormat.photo"'s inverse relation */
  Metadata_Format?: InputMaybe<PhotoNestedMetadataFormatUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSLatitude" relation: "MetadataGpsLatitude.photo"'s inverse relation */
  Metadata_GPSLatitude?: InputMaybe<PhotoNestedMetadataGpslatitudeUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSLongitude" relation: "MetadataGpsLongitude.photo"'s inverse relation */
  Metadata_GPSLongitude?: InputMaybe<PhotoNestedMetadataGpslongitudeUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSProcessingMethod" relation: "MetadataGpsProcessingMethod.photo"'s inverse relation */
  Metadata_GPSProcessingMethod?: InputMaybe<PhotoNestedMetadataGpsprocessingmethodUpdateInput>;
  /** Actions for the "Photo.Metadata_Header" relation: "MetadataHeader.photo"'s inverse relation */
  Metadata_Header?: InputMaybe<PhotoNestedMetadataHeaderUpdateInput>;
  /** Actions for the "Photo.Metadata_HeaderPrisma" relation: "MetadataHeaderPrisma.photo"'s inverse relation */
  Metadata_HeaderPrisma?: InputMaybe<PhotoNestedMetadataHeaderprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_Headline" relation: "MetadataHeadline.photo"'s inverse relation */
  Metadata_Headline?: InputMaybe<PhotoNestedMetadataHeadlineUpdateInput>;
  /** Actions for the "Photo.Metadata_History" relation: "MetadataHistory.photo"'s inverse relation */
  Metadata_History?: InputMaybe<PhotoNestedMetadataHistoryUpdateInput>;
  /** Actions for the "Photo.Metadata_ICCProfile" relation: "MetadataIccProfile.photo"'s inverse relation */
  Metadata_ICCProfile?: InputMaybe<PhotoNestedMetadataIccprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_ID_subject" relation: "MetadataIdSubject.photo"'s inverse relation */
  Metadata_ID_subject?: InputMaybe<PhotoNestedMetadataIdSubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_IdAssignment" relation: "MetadataIdAssignment.photo"'s inverse relation */
  Metadata_IdAssignment?: InputMaybe<PhotoNestedMetadataIdassignmentUpdateInput>;
  /** Actions for the "Photo.Metadata_IdMediaContent" relation: "MetadataIdMediaContent.photo"'s inverse relation */
  Metadata_IdMediaContent?: InputMaybe<PhotoNestedMetadataIdmediacontentUpdateInput>;
  /** Actions for the "Photo.Metadata_IdPhotoPrisma" relation: "MetadataIdPhotoPrisma.photo"'s inverse relation */
  Metadata_IdPhotoPrisma?: InputMaybe<PhotoNestedMetadataIdphotoprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_ImageNotes" relation: "MetadataImageNotes.photo"'s inverse relation */
  Metadata_ImageNotes?: InputMaybe<PhotoNestedMetadataImagenotesUpdateInput>;
  /** Actions for the "Photo.Metadata_ImageUniqueID" relation: "MetadataImageUniqueId.photo"'s inverse relation */
  Metadata_ImageUniqueID?: InputMaybe<PhotoNestedMetadataImageuniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Instructions" relation: "MetadataInstructions.photo"'s inverse relation */
  Metadata_Instructions?: InputMaybe<PhotoNestedMetadataInstructionsUpdateInput>;
  /** Actions for the "Photo.Metadata_IntellectualGenre" relation: "MetadataIntellectualGenre.photo"'s inverse relation */
  Metadata_IntellectualGenre?: InputMaybe<PhotoNestedMetadataIntellectualgenreUpdateInput>;
  /** Actions for the "Photo.Metadata_ISOSpeedRatings" relation: "MetadataIsoSpeedRatings.photo"'s inverse relation */
  Metadata_ISOSpeedRatings?: InputMaybe<PhotoNestedMetadataIsospeedratingsUpdateInput>;
  /** Actions for the "Photo.Metadata_Issue" relation: "MetadataIssue.photo"'s inverse relation */
  Metadata_Issue?: InputMaybe<PhotoNestedMetadataIssueUpdateInput>;
  /** Actions for the "Photo.Metadata_JobId" relation: "MetadataJobId.photo"'s inverse relation */
  Metadata_JobId?: InputMaybe<PhotoNestedMetadataJobidUpdateInput>;
  /** Actions for the "Photo.Metadata_JobProcessing" relation: "MetadataJobProcessing.photo"'s inverse relation */
  Metadata_JobProcessing?: InputMaybe<PhotoNestedMetadataJobprocessingUpdateInput>;
  /** Actions for the "Photo.Metadata_LayoutPictureEditing" relation: "MetadataLayoutPictureEditing.photo"'s inverse relation */
  Metadata_LayoutPictureEditing?: InputMaybe<PhotoNestedMetadataLayoutpictureeditingUpdateInput>;
  /** Actions for the "Photo.Metadata_LayoutStorage" relation: "MetadataLayoutStorage.photo"'s inverse relation */
  Metadata_LayoutStorage?: InputMaybe<PhotoNestedMetadataLayoutstorageUpdateInput>;
  /** Actions for the "Photo.Metadata_license" relation: "MetadataLicense.photo"'s inverse relation */
  Metadata_license?: InputMaybe<PhotoNestedMetadataLicenseUpdateInput>;
  /** Actions for the "Photo.Metadata_Links" relation: "MetadataLinks.photo"'s inverse relation */
  Metadata_Links?: InputMaybe<PhotoNestedMetadataLinksUpdateInput>;
  /** Actions for the "Photo.Metadata_LocalCaption" relation: "MetadataLocalCaption.photo"'s inverse relation */
  Metadata_LocalCaption?: InputMaybe<PhotoNestedMetadataLocalcaptionUpdateInput>;
  /** Actions for the "Photo.Metadata_Location" relation: "MetadataLocation.photo"'s inverse relation */
  Metadata_Location?: InputMaybe<PhotoNestedMetadataLocationUpdateInput>;
  /** Actions for the "Photo.Metadata_Make" relation: "MetadataMake.photo"'s inverse relation */
  Metadata_Make?: InputMaybe<PhotoNestedMetadataMakeUpdateInput>;
  /** Actions for the "Photo.Metadata_Manifest" relation: "MetadataManifest.photo"'s inverse relation */
  Metadata_Manifest?: InputMaybe<PhotoNestedMetadataManifestUpdateInput>;
  /** Actions for the "Photo.Metadata_Marked" relation: "MetadataMarked.photo"'s inverse relation */
  Metadata_Marked?: InputMaybe<PhotoNestedMetadataMarkedUpdateInput>;
  /** Actions for the "Photo.Metadata_MasterDocumentId" relation: "MetadataMasterDocumentId.photo"'s inverse relation */
  Metadata_MasterDocumentId?: InputMaybe<PhotoNestedMetadataMasterdocumentidUpdateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailHeight" relation: "MetadataMaxAvailHeight.photo"'s inverse relation */
  Metadata_MaxAvailHeight?: InputMaybe<PhotoNestedMetadataMaxavailheightUpdateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailWidth" relation: "MetadataMaxAvailWidth.photo"'s inverse relation */
  Metadata_MaxAvailWidth?: InputMaybe<PhotoNestedMetadataMaxavailwidthUpdateInput>;
  /** Actions for the "Photo.Metadata_MinorModelAgeDisclosure" relation: "MetadataMinorModelAgeDisclosure.photo"'s inverse relation */
  Metadata_MinorModelAgeDisclosure?: InputMaybe<PhotoNestedMetadataMinormodelagedisclosureUpdateInput>;
  /** Actions for the "Photo.Metadata_Model" relation: "MetadataModel.photo"'s inverse relation */
  Metadata_Model?: InputMaybe<PhotoNestedMetadataModelUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelAge" relation: "MetadataModelAge.photo"'s inverse relation */
  Metadata_ModelAge?: InputMaybe<PhotoNestedMetadataModelageUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseID" relation: "MetadataModelReleaseId.photo"'s inverse relation */
  Metadata_ModelReleaseID?: InputMaybe<PhotoNestedMetadataModelreleaseidUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseStatus" relation: "MetadataModelReleaseStatus.photo"'s inverse relation */
  Metadata_ModelReleaseStatus?: InputMaybe<PhotoNestedMetadataModelreleasestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_morePermissions" relation: "MetadataMorePermissions.photo"'s inverse relation */
  Metadata_morePermissions?: InputMaybe<PhotoNestedMetadataMorepermissionsUpdateInput>;
  /** Actions for the "Photo.Metadata_ObjectCycle" relation: "MetadataObjectCycle.photo"'s inverse relation */
  Metadata_ObjectCycle?: InputMaybe<PhotoNestedMetadataObjectcycleUpdateInput>;
  /** Actions for the "Photo.Metadata_OrganisationInImageName" relation: "MetadataOrganisationInImageName.photo"'s inverse relation */
  Metadata_OrganisationInImageName?: InputMaybe<PhotoNestedMetadataOrganisationinimagenameUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalColor" relation: "MetadataOriginalColor.photo"'s inverse relation */
  Metadata_OriginalColor?: InputMaybe<PhotoNestedMetadataOriginalcolorUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalICCProfile" relation: "MetadataOriginalIccProfile.photo"'s inverse relation */
  Metadata_OriginalICCProfile?: InputMaybe<PhotoNestedMetadataOriginaliccprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalName" relation: "MetadataOriginalName.photo"'s inverse relation */
  Metadata_OriginalName?: InputMaybe<PhotoNestedMetadataOriginalnameUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalWeigth" relation: "MetadataOriginalWeigth.photo"'s inverse relation */
  Metadata_OriginalWeigth?: InputMaybe<PhotoNestedMetadataOriginalweigthUpdateInput>;
  /** Actions for the "Photo.Metadata_Owner" relation: "MetadataOwner.photo"'s inverse relation */
  Metadata_Owner?: InputMaybe<PhotoNestedMetadataOwnerUpdateInput>;
  /** Actions for the "Photo.Metadata_OwnerId" relation: "MetadataOwnerId.photo"'s inverse relation */
  Metadata_OwnerId?: InputMaybe<PhotoNestedMetadataOwneridUpdateInput>;
  /** Actions for the "Photo.Metadata_Page" relation: "MetadataPage.photo"'s inverse relation */
  Metadata_Page?: InputMaybe<PhotoNestedMetadataPageUpdateInput>;
  /** Actions for the "Photo.Metadata_PagePrisma" relation: "MetadataPagePrisma.photo"'s inverse relation */
  Metadata_PagePrisma?: InputMaybe<PhotoNestedMetadataPageprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_PaintBasedCorrections" relation: "MetadataPaintBasedCorrections.photo"'s inverse relation */
  Metadata_PaintBasedCorrections?: InputMaybe<PhotoNestedMetadataPaintbasedcorrectionsUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientDOB" relation: "MetadataPatientDob.photo"'s inverse relation */
  Metadata_PatientDOB?: InputMaybe<PhotoNestedMetadataPatientdobUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientID" relation: "MetadataPatientId.photo"'s inverse relation */
  Metadata_PatientID?: InputMaybe<PhotoNestedMetadataPatientidUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientName" relation: "MetadataPatientName.photo"'s inverse relation */
  Metadata_PatientName?: InputMaybe<PhotoNestedMetadataPatientnameUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientSex" relation: "MetadataPatientSex.photo"'s inverse relation */
  Metadata_PatientSex?: InputMaybe<PhotoNestedMetadataPatientsexUpdateInput>;
  /** Actions for the "Photo.Metadata_PersonInImage" relation: "MetadataPersonInImage.photo"'s inverse relation */
  Metadata_PersonInImage?: InputMaybe<PhotoNestedMetadataPersoninimageUpdateInput>;
  /** Actions for the "Photo.Metadata_PhotoStorage" relation: "MetadataPhotoStorage.photo"'s inverse relation */
  Metadata_PhotoStorage?: InputMaybe<PhotoNestedMetadataPhotostorageUpdateInput>;
  /** Actions for the "Photo.Metadata_PrepressPictureEditing" relation: "MetadataPrepressPictureEditing.photo"'s inverse relation */
  Metadata_PrepressPictureEditing?: InputMaybe<PhotoNestedMetadataPrepresspictureeditingUpdateInput>;
  /** Actions for the "Photo.Metadata_PriceLevel" relation: "MetadataPriceLevel.photo"'s inverse relation */
  Metadata_PriceLevel?: InputMaybe<PhotoNestedMetadataPricelevelUpdateInput>;
  /** Actions for the "Photo.Metadata_PrintingProfile" relation: "MetadataPrintingProfile.photo"'s inverse relation */
  Metadata_PrintingProfile?: InputMaybe<PhotoNestedMetadataPrintingprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_PrismaProduction" relation: "MetadataPrismaProduction.photo"'s inverse relation */
  Metadata_PrismaProduction?: InputMaybe<PhotoNestedMetadataPrismaproductionUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessHistory" relation: "MetadataProcessHistory.photo"'s inverse relation */
  Metadata_ProcessHistory?: InputMaybe<PhotoNestedMetadataProcesshistoryUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessParameter" relation: "MetadataProcessParameter.photo"'s inverse relation */
  Metadata_ProcessParameter?: InputMaybe<PhotoNestedMetadataProcessparameterUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessStatus" relation: "MetadataProcessStatus.photo"'s inverse relation */
  Metadata_ProcessStatus?: InputMaybe<PhotoNestedMetadataProcessstatusUpdateInput>;
  /** Actions for the "Photo.Metadata_Product" relation: "MetadataProduct.photo"'s inverse relation */
  Metadata_Product?: InputMaybe<PhotoNestedMetadataProductUpdateInput>;
  /** Actions for the "Photo.Metadata_Products" relation: "MetadataProducts.photo"'s inverse relation */
  Metadata_Products?: InputMaybe<PhotoNestedMetadataProductsUpdateInput>;
  /** Actions for the "Photo.Metadata_ProductShortName" relation: "MetadataProductShortName.photo"'s inverse relation */
  Metadata_ProductShortName?: InputMaybe<PhotoNestedMetadataProductshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_Produits" relation: "MetadataProduits.photo"'s inverse relation */
  Metadata_Produits?: InputMaybe<PhotoNestedMetadataProduitsUpdateInput>;
  /** Actions for the "Photo.Metadata_ProgramVersion" relation: "MetadataProgramVersion.photo"'s inverse relation */
  Metadata_ProgramVersion?: InputMaybe<PhotoNestedMetadataProgramversionUpdateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseID" relation: "MetadataPropertyReleaseId.photo"'s inverse relation */
  Metadata_PropertyReleaseID?: InputMaybe<PhotoNestedMetadataPropertyreleaseidUpdateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseStatus" relation: "MetadataPropertyReleaseStatus.photo"'s inverse relation */
  Metadata_PropertyReleaseStatus?: InputMaybe<PhotoNestedMetadataPropertyreleasestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_Publisher" relation: "MetadataPublisher.photo"'s inverse relation */
  Metadata_Publisher?: InputMaybe<PhotoNestedMetadataPublisherUpdateInput>;
  /** Actions for the "Photo.Metadata_publishingIssue" relation: "MetadataPublishingIssue.photo"'s inverse relation */
  Metadata_publishingIssue?: InputMaybe<PhotoNestedMetadataPublishingissueUpdateInput>;
  /** Actions for the "Photo.Metadata_publishingSubject" relation: "MetadataPublishingSubject.photo"'s inverse relation */
  Metadata_publishingSubject?: InputMaybe<PhotoNestedMetadataPublishingsubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaBy" relation: "MetadataQualifiedUsePrismaBy.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaBy?: InputMaybe<PhotoNestedMetadataQualifieduseprismabyUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDate" relation: "MetadataQualifiedUsePrismaDate.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDate?: InputMaybe<PhotoNestedMetadataQualifieduseprismadateUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDuration" relation: "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDuration?: InputMaybe<PhotoNestedMetadataQualifieduseprismadurationUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaSupport" relation: "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaSupport?: InputMaybe<PhotoNestedMetadataQualifieduseprismasupportUpdateInput>;
  /** Actions for the "Photo.Metadata_Rating" relation: "MetadataRating.photo"'s inverse relation */
  Metadata_Rating?: InputMaybe<PhotoNestedMetadataRatingUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceDate" relation: "MetadataReferenceDate.photo"'s inverse relation */
  Metadata_ReferenceDate?: InputMaybe<PhotoNestedMetadataReferencedateUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceNumber" relation: "MetadataReferenceNumber.photo"'s inverse relation */
  Metadata_ReferenceNumber?: InputMaybe<PhotoNestedMetadataReferencenumberUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceService" relation: "MetadataReferenceService.photo"'s inverse relation */
  Metadata_ReferenceService?: InputMaybe<PhotoNestedMetadataReferenceserviceUpdateInput>;
  /** Actions for the "Photo.Metadata_ReleaseDate" relation: "MetadataReleaseDate.photo"'s inverse relation */
  Metadata_ReleaseDate?: InputMaybe<PhotoNestedMetadataReleasedateUpdateInput>;
  /** Actions for the "Photo.Metadata_ReleaseTime" relation: "MetadataReleaseTime.photo"'s inverse relation */
  Metadata_ReleaseTime?: InputMaybe<PhotoNestedMetadataReleasetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_RequiredPermission" relation: "MetadataRequiredPermission.photo"'s inverse relation */
  Metadata_RequiredPermission?: InputMaybe<PhotoNestedMetadataRequiredpermissionUpdateInput>;
  /** Actions for the "Photo.Metadata_ResolutionKind" relation: "MetadataResolutionKind.photo"'s inverse relation */
  Metadata_ResolutionKind?: InputMaybe<PhotoNestedMetadataResolutionkindUpdateInput>;
  /** Actions for the "Photo.Metadata_rights" relation: "MetadataRights.photo"'s inverse relation */
  Metadata_rights?: InputMaybe<PhotoNestedMetadataRightsUpdateInput>;
  /** Actions for the "Photo.Metadata_Royaltyfree" relation: "MetadataRoyaltyfree.photo"'s inverse relation */
  Metadata_Royaltyfree?: InputMaybe<PhotoNestedMetadataRoyaltyfreeUpdateInput>;
  /** Actions for the "Photo.Metadata_Scene" relation: "MetadataScene.photo"'s inverse relation */
  Metadata_Scene?: InputMaybe<PhotoNestedMetadataSceneUpdateInput>;
  /** Actions for the "Photo.Metadata_Section" relation: "MetadataSection.photo"'s inverse relation */
  Metadata_Section?: InputMaybe<PhotoNestedMetadataSectionUpdateInput>;
  /** Actions for the "Photo.Metadata_SentTo" relation: "MetadataSentTo.photo"'s inverse relation */
  Metadata_SentTo?: InputMaybe<PhotoNestedMetadataSenttoUpdateInput>;
  /** Actions for the "Photo.Metadata_SerialNumber" relation: "MetadataSerialNumber.photo"'s inverse relation */
  Metadata_SerialNumber?: InputMaybe<PhotoNestedMetadataSerialnumberUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesDateTime" relation: "MetadataSeriesDateTime.photo"'s inverse relation */
  Metadata_SeriesDateTime?: InputMaybe<PhotoNestedMetadataSeriesdatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesDescription" relation: "MetadataSeriesDescription.photo"'s inverse relation */
  Metadata_SeriesDescription?: InputMaybe<PhotoNestedMetadataSeriesdescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesModality" relation: "MetadataSeriesModality.photo"'s inverse relation */
  Metadata_SeriesModality?: InputMaybe<PhotoNestedMetadataSeriesmodalityUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesNumber" relation: "MetadataSeriesNumber.photo"'s inverse relation */
  Metadata_SeriesNumber?: InputMaybe<PhotoNestedMetadataSeriesnumberUpdateInput>;
  /** Actions for the "Photo.Metadata_ShortUniqueId" relation: "MetadataShortUniqueId.photo"'s inverse relation */
  Metadata_ShortUniqueId?: InputMaybe<PhotoNestedMetadataShortuniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Source" relation: "MetadataSource.photo"'s inverse relation */
  Metadata_Source?: InputMaybe<PhotoNestedMetadataSourceUpdateInput>;
  /** Actions for the "Photo.Metadata_State" relation: "MetadataState.photo"'s inverse relation */
  Metadata_State?: InputMaybe<PhotoNestedMetadataStateUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyDateTime" relation: "MetadataStudyDateTime.photo"'s inverse relation */
  Metadata_StudyDateTime?: InputMaybe<PhotoNestedMetadataStudydatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyDescription" relation: "MetadataStudyDescription.photo"'s inverse relation */
  Metadata_StudyDescription?: InputMaybe<PhotoNestedMetadataStudydescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyID" relation: "MetadataStudyId.photo"'s inverse relation */
  Metadata_StudyID?: InputMaybe<PhotoNestedMetadataStudyidUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyPhysician" relation: "MetadataStudyPhysician.photo"'s inverse relation */
  Metadata_StudyPhysician?: InputMaybe<PhotoNestedMetadataStudyphysicianUpdateInput>;
  /** Actions for the "Photo.Metadata_subject" relation: "MetadataSubject.photo"'s inverse relation */
  Metadata_subject?: InputMaybe<PhotoNestedMetadataSubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_SubjectCode" relation: "MetadataSubjectCode.photo"'s inverse relation */
  Metadata_SubjectCode?: InputMaybe<PhotoNestedMetadataSubjectcodeUpdateInput>;
  /** Actions for the "Photo.Metadata_SubjectPrisma" relation: "MetadataSubjectPrisma.photo"'s inverse relation */
  Metadata_SubjectPrisma?: InputMaybe<PhotoNestedMetadataSubjectprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_SupplementalCategories" relation: "MetadataSupplementalCategories.photo"'s inverse relation */
  Metadata_SupplementalCategories?: InputMaybe<PhotoNestedMetadataSupplementalcategoriesUpdateInput>;
  /** Actions for the "Photo.Metadata_TargetVersion" relation: "MetadataTargetVersion.photo"'s inverse relation */
  Metadata_TargetVersion?: InputMaybe<PhotoNestedMetadataTargetversionUpdateInput>;
  /** Actions for the "Photo.Metadata_title" relation: "MetadataTitle.photo"'s inverse relation */
  Metadata_title?: InputMaybe<PhotoNestedMetadataTitleUpdateInput>;
  /** Actions for the "Photo.Metadata_ToneCurve" relation: "MetadataToneCurve.photo"'s inverse relation */
  Metadata_ToneCurve?: InputMaybe<PhotoNestedMetadataTonecurveUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredBy" relation: "MetadataTransferredBy.photo"'s inverse relation */
  Metadata_TransferredBy?: InputMaybe<PhotoNestedMetadataTransferredbyUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredByEmail" relation: "MetadataTransferredByEmail.photo"'s inverse relation */
  Metadata_TransferredByEmail?: InputMaybe<PhotoNestedMetadataTransferredbyemailUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredByFullName" relation: "MetadataTransferredByFullName.photo"'s inverse relation */
  Metadata_TransferredByFullName?: InputMaybe<PhotoNestedMetadataTransferredbyfullnameUpdateInput>;
  /** Actions for the "Photo.Metadata_TransmissionReference" relation: "MetadataTransmissionReference.photo"'s inverse relation */
  Metadata_TransmissionReference?: InputMaybe<PhotoNestedMetadataTransmissionreferenceUpdateInput>;
  /** Actions for the "Photo.Metadata_Type" relation: "MetadataType.photo"'s inverse relation */
  Metadata_Type?: InputMaybe<PhotoNestedMetadataTypeUpdateInput>;
  /** Actions for the "Photo.Metadata_Undersubject" relation: "MetadataUndersubject.photo"'s inverse relation */
  Metadata_Undersubject?: InputMaybe<PhotoNestedMetadataUndersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_UnderUnderSubject" relation: "MetadataUnderUnderSubject.photo"'s inverse relation */
  Metadata_UnderUnderSubject?: InputMaybe<PhotoNestedMetadataUnderundersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_UniqueId" relation: "MetadataUniqueId.photo"'s inverse relation */
  Metadata_UniqueId?: InputMaybe<PhotoNestedMetadataUniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Unit" relation: "MetadataUnit.photo"'s inverse relation */
  Metadata_Unit?: InputMaybe<PhotoNestedMetadataUnitUpdateInput>;
  /** Actions for the "Photo.Metadata_UnitShortName" relation: "MetadataUnitShortName.photo"'s inverse relation */
  Metadata_UnitShortName?: InputMaybe<PhotoNestedMetadataUnitshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadedBy" relation: "MetadataUploadedBy.photo"'s inverse relation */
  Metadata_UploadedBy?: InputMaybe<PhotoNestedMetadataUploadedbyUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadedByFullName" relation: "MetadataUploadedByFullName.photo"'s inverse relation */
  Metadata_UploadedByFullName?: InputMaybe<PhotoNestedMetadataUploadedbyfullnameUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadTime" relation: "MetadataUploadTime.photo"'s inverse relation */
  Metadata_UploadTime?: InputMaybe<PhotoNestedMetadataUploadtimeUpdateInput>;
  /** Actions for the "Photo.Metadata_Urgency" relation: "MetadataUrgency.photo"'s inverse relation */
  Metadata_Urgency?: InputMaybe<PhotoNestedMetadataUrgencyUpdateInput>;
  /** Actions for the "Photo.Metadata_UsageTerms" relation: "MetadataUsageTerms.photo"'s inverse relation */
  Metadata_UsageTerms?: InputMaybe<PhotoNestedMetadataUsagetermsUpdateInput>;
  /** Actions for the "Photo.Metadata_UserComment" relation: "MetadataUserComment.photo"'s inverse relation */
  Metadata_UserComment?: InputMaybe<PhotoNestedMetadataUsercommentUpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined195" relation: "MetadataUserDefined195.photo"'s inverse relation */
  Metadata_UserDefined195?: InputMaybe<PhotoNestedMetadataUserdefined195UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined237" relation: "MetadataUserDefined237.photo"'s inverse relation */
  Metadata_UserDefined237?: InputMaybe<PhotoNestedMetadataUserdefined237UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined238" relation: "MetadataUserDefined238.photo"'s inverse relation */
  Metadata_UserDefined238?: InputMaybe<PhotoNestedMetadataUserdefined238UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined239" relation: "MetadataUserDefined239.photo"'s inverse relation */
  Metadata_UserDefined239?: InputMaybe<PhotoNestedMetadataUserdefined239UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined242" relation: "MetadataUserDefined242.photo"'s inverse relation */
  Metadata_UserDefined242?: InputMaybe<PhotoNestedMetadataUserdefined242UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined62" relation: "MetadataUserDefined62.photo"'s inverse relation */
  Metadata_UserDefined62?: InputMaybe<PhotoNestedMetadataUserdefined62UpdateInput>;
  /** Actions for the "Photo.Metadata_Valid" relation: "MetadataValid.photo"'s inverse relation */
  Metadata_Valid?: InputMaybe<PhotoNestedMetadataValidUpdateInput>;
  /** Actions for the "Photo.Metadata_Version" relation: "MetadataVersion.photo"'s inverse relation */
  Metadata_Version?: InputMaybe<PhotoNestedMetadataVersionUpdateInput>;
  /** Actions for the "Photo.Metadata_WebStatement" relation: "MetadataWebStatement.photo"'s inverse relation */
  Metadata_WebStatement?: InputMaybe<PhotoNestedMetadataWebstatementUpdateInput>;
  /** Actions for the "Photo.Metadata_WorkflowKind" relation: "MetadataWorkflowKind.photo"'s inverse relation */
  Metadata_WorkflowKind?: InputMaybe<PhotoNestedMetadataWorkflowkindUpdateInput>;
  /** Actions for the "Photo.Metadata_XMPFileStamps" relation: "MetadataXmpFileStamps.photo"'s inverse relation */
  Metadata_XMPFileStamps?: InputMaybe<PhotoNestedMetadataXmpfilestampsUpdateInput>;
  /** Actions for the "Photo.Metadata_XMPHistory" relation: "MetadataXmpHistory.photo"'s inverse relation */
  Metadata_XMPHistory?: InputMaybe<PhotoNestedMetadataXmphistoryUpdateInput>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.photoMetadatas" relation: "PhotoMetadata.photo"'s inverse relation */
  photoMetadatas?: InputMaybe<PhotoNestedPhotoMetadatasUpdateInput>;
  /** Actions for the "Photo.photoPublications" relation: "PhotoPublication.photo"'s inverse relation */
  photoPublications?: InputMaybe<PhotoNestedPhotoPublicationsUpdateInput>;
  synced?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date of the photo's capture */
  takenAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type PhotoWithForcedArchiveCreateInput = {
  /** Actions for the "Photo.agency" relation */
  agency?: InputMaybe<PhotoNestedAgencyCreateInput>;
  /** Actions for the "Photo.category" relation */
  category?: InputMaybe<PhotoNestedCategoryCreateInput>;
  /** asset content type */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.deliverySubject" relation */
  deliverySubject?: InputMaybe<PhotoNestedDeliverySubjectCreateInput>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The date of the photo's importation */
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Actions for the "Photo.importQueue" relation: "ImportQueue.photo"'s inverse relation */
  importQueue?: InputMaybe<PhotoNestedImportQueueCreateInput>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** Actions for the "Photo.Metadata_AccessRights" relation: "MetadataAccessRights.photo"'s inverse relation */
  Metadata_AccessRights?: InputMaybe<PhotoNestedMetadataAccessrightsCreateInput>;
  /** Actions for the "Photo.Metadata_AddlModelInfo" relation: "MetadataAddlModelInfo.photo"'s inverse relation */
  Metadata_AddlModelInfo?: InputMaybe<PhotoNestedMetadataAddlmodelinfoCreateInput>;
  /** Actions for the "Photo.Metadata_Archive_child" relation: "MetadataArchiveChild.photo"'s inverse relation */
  Metadata_Archive_child?: InputMaybe<PhotoNestedMetadataArchiveChildCreateInput>;
  /** Actions for the "Photo.Metadata_Archive_parent" relation: "MetadataArchiveParent.photo"'s inverse relation */
  Metadata_Archive_parent?: InputMaybe<PhotoNestedMetadataArchiveParentCreateInput>;
  /** Actions for the "Photo.Metadata_AssetState" relation: "MetadataAssetState.photo"'s inverse relation */
  Metadata_AssetState?: InputMaybe<PhotoNestedMetadataAssetstateCreateInput>;
  /** Actions for the "Photo.Metadata_attributionName" relation: "MetadataAttributionName.photo"'s inverse relation */
  Metadata_attributionName?: InputMaybe<PhotoNestedMetadataAttributionnameCreateInput>;
  /** Actions for the "Photo.Metadata_attributionURL" relation: "MetadataAttributionUrl.photo"'s inverse relation */
  Metadata_attributionURL?: InputMaybe<PhotoNestedMetadataAttributionurlCreateInput>;
  /** Actions for the "Photo.Metadata_Audience" relation: "MetadataAudience.photo"'s inverse relation */
  Metadata_Audience?: InputMaybe<PhotoNestedMetadataAudienceCreateInput>;
  /** Actions for the "Photo.Metadata_AuthorsPosition" relation: "MetadataAuthorsPosition.photo"'s inverse relation */
  Metadata_AuthorsPosition?: InputMaybe<PhotoNestedMetadataAuthorspositionCreateInput>;
  /** Actions for the "Photo.Metadata_BackupName" relation: "MetadataBackupName.photo"'s inverse relation */
  Metadata_BackupName?: InputMaybe<PhotoNestedMetadataBackupnameCreateInput>;
  /** Actions for the "Photo.Metadata_BitmapGrayscalePicture" relation: "MetadataBitmapGrayscalePicture.photo"'s inverse relation */
  Metadata_BitmapGrayscalePicture?: InputMaybe<PhotoNestedMetadataBitmapgrayscalepictureCreateInput>;
  /** Actions for the "Photo.Metadata_Brand" relation: "MetadataBrand.photo"'s inverse relation */
  Metadata_Brand?: InputMaybe<PhotoNestedMetadataBrandCreateInput>;
  /** Actions for the "Photo.Metadata_BrandPrisma" relation: "MetadataBrandPrisma.photo"'s inverse relation */
  Metadata_BrandPrisma?: InputMaybe<PhotoNestedMetadataBrandprismaCreateInput>;
  /** Actions for the "Photo.Metadata_BrandShortName" relation: "MetadataBrandShortName.photo"'s inverse relation */
  Metadata_BrandShortName?: InputMaybe<PhotoNestedMetadataBrandshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_CaptionWriter" relation: "MetadataCaptionWriter.photo"'s inverse relation */
  Metadata_CaptionWriter?: InputMaybe<PhotoNestedMetadataCaptionwriterCreateInput>;
  /** Actions for the "Photo.Metadata_Category" relation: "MetadataCategory.photo"'s inverse relation */
  Metadata_Category?: InputMaybe<PhotoNestedMetadataCategoryCreateInput>;
  /** Actions for the "Photo.Metadata_CategoryPrisma" relation: "MetadataCategoryPrisma.photo"'s inverse relation */
  Metadata_CategoryPrisma?: InputMaybe<PhotoNestedMetadataCategoryprismaCreateInput>;
  /** Actions for the "Photo.Metadata_Certificate" relation: "MetadataCertificate.photo"'s inverse relation */
  Metadata_Certificate?: InputMaybe<PhotoNestedMetadataCertificateCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCity" relation: "MetadataCiAdrCity.photo"'s inverse relation */
  Metadata_CiAdrCity?: InputMaybe<PhotoNestedMetadataCiadrcityCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCtry" relation: "MetadataCiAdrCtry.photo"'s inverse relation */
  Metadata_CiAdrCtry?: InputMaybe<PhotoNestedMetadataCiadrctryCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrExtadr" relation: "MetadataCiAdrExtadr.photo"'s inverse relation */
  Metadata_CiAdrExtadr?: InputMaybe<PhotoNestedMetadataCiadrextadrCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrPcode" relation: "MetadataCiAdrPcode.photo"'s inverse relation */
  Metadata_CiAdrPcode?: InputMaybe<PhotoNestedMetadataCiadrpcodeCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrRegion" relation: "MetadataCiAdrRegion.photo"'s inverse relation */
  Metadata_CiAdrRegion?: InputMaybe<PhotoNestedMetadataCiadrregionCreateInput>;
  /** Actions for the "Photo.Metadata_CiEmailWork" relation: "MetadataCiEmailWork.photo"'s inverse relation */
  Metadata_CiEmailWork?: InputMaybe<PhotoNestedMetadataCiemailworkCreateInput>;
  /** Actions for the "Photo.Metadata_CiTelWork" relation: "MetadataCiTelWork.photo"'s inverse relation */
  Metadata_CiTelWork?: InputMaybe<PhotoNestedMetadataCitelworkCreateInput>;
  /** Actions for the "Photo.Metadata_City" relation: "MetadataCity.photo"'s inverse relation */
  Metadata_City?: InputMaybe<PhotoNestedMetadataCityCreateInput>;
  /** Actions for the "Photo.Metadata_CiUrlWork" relation: "MetadataCiUrlWork.photo"'s inverse relation */
  Metadata_CiUrlWork?: InputMaybe<PhotoNestedMetadataCiurlworkCreateInput>;
  /** Actions for the "Photo.Metadata_Classify" relation: "MetadataClassify.photo"'s inverse relation */
  Metadata_Classify?: InputMaybe<PhotoNestedMetadataClassifyCreateInput>;
  /** Actions for the "Photo.Metadata_Company" relation: "MetadataCompany.photo"'s inverse relation */
  Metadata_Company?: InputMaybe<PhotoNestedMetadataCompanyCreateInput>;
  /** Actions for the "Photo.Metadata_CompanyShortName" relation: "MetadataCompanyShortName.photo"'s inverse relation */
  Metadata_CompanyShortName?: InputMaybe<PhotoNestedMetadataCompanyshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_Confidentiality" relation: "MetadataConfidentiality.photo"'s inverse relation */
  Metadata_Confidentiality?: InputMaybe<PhotoNestedMetadataConfidentialityCreateInput>;
  /** Actions for the "Photo.Metadata_ContainedIn" relation: "MetadataContainedIn.photo"'s inverse relation */
  Metadata_ContainedIn?: InputMaybe<PhotoNestedMetadataContainedinCreateInput>;
  /** Actions for the "Photo.Metadata_ContentMediaKind" relation: "MetadataContentMediaKind.photo"'s inverse relation */
  Metadata_ContentMediaKind?: InputMaybe<PhotoNestedMetadataContentmediakindCreateInput>;
  /** Actions for the "Photo.Metadata_ContentValue" relation: "MetadataContentValue.photo"'s inverse relation */
  Metadata_ContentValue?: InputMaybe<PhotoNestedMetadataContentvalueCreateInput>;
  /** Actions for the "Photo.Metadata_CopyrightLayer" relation: "MetadataCopyrightLayer.photo"'s inverse relation */
  Metadata_CopyrightLayer?: InputMaybe<PhotoNestedMetadataCopyrightlayerCreateInput>;
  /** Actions for the "Photo.Metadata_Country" relation: "MetadataCountry.photo"'s inverse relation */
  Metadata_Country?: InputMaybe<PhotoNestedMetadataCountryCreateInput>;
  /** Actions for the "Photo.Metadata_CountryCode" relation: "MetadataCountryCode.photo"'s inverse relation */
  Metadata_CountryCode?: InputMaybe<PhotoNestedMetadataCountrycodeCreateInput>;
  /** Actions for the "Photo.Metadata_CreatedTime" relation: "MetadataCreatedTime.photo"'s inverse relation */
  Metadata_CreatedTime?: InputMaybe<PhotoNestedMetadataCreatedtimeCreateInput>;
  /** Actions for the "Photo.Metadata_creator" relation: "MetadataCreator.photo"'s inverse relation */
  Metadata_creator?: InputMaybe<PhotoNestedMetadataCreatorCreateInput>;
  /** Actions for the "Photo.Metadata_CreatorTool" relation: "MetadataCreatorTool.photo"'s inverse relation */
  Metadata_CreatorTool?: InputMaybe<PhotoNestedMetadataCreatortoolCreateInput>;
  /** Actions for the "Photo.Metadata_Credit" relation: "MetadataCredit.photo"'s inverse relation */
  Metadata_Credit?: InputMaybe<PhotoNestedMetadataCreditCreateInput>;
  /** Actions for the "Photo.Metadata_CustomField14" relation: "MetadataCustomField14.photo"'s inverse relation */
  Metadata_CustomField14?: InputMaybe<PhotoNestedMetadataCustomfield14CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField15" relation: "MetadataCustomField15.photo"'s inverse relation */
  Metadata_CustomField15?: InputMaybe<PhotoNestedMetadataCustomfield15CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField16" relation: "MetadataCustomField16.photo"'s inverse relation */
  Metadata_CustomField16?: InputMaybe<PhotoNestedMetadataCustomfield16CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField17" relation: "MetadataCustomField17.photo"'s inverse relation */
  Metadata_CustomField17?: InputMaybe<PhotoNestedMetadataCustomfield17CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField20" relation: "MetadataCustomField20.photo"'s inverse relation */
  Metadata_CustomField20?: InputMaybe<PhotoNestedMetadataCustomfield20CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField5" relation: "MetadataCustomField5.photo"'s inverse relation */
  Metadata_CustomField5?: InputMaybe<PhotoNestedMetadataCustomfield5CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField6" relation: "MetadataCustomField6.photo"'s inverse relation */
  Metadata_CustomField6?: InputMaybe<PhotoNestedMetadataCustomfield6CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField7" relation: "MetadataCustomField7.photo"'s inverse relation */
  Metadata_CustomField7?: InputMaybe<PhotoNestedMetadataCustomfield7CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField9" relation: "MetadataCustomField9.photo"'s inverse relation */
  Metadata_CustomField9?: InputMaybe<PhotoNestedMetadataCustomfield9CreateInput>;
  /** Actions for the "Photo.Metadata_DateCreated" relation: "MetadataDateCreated.photo"'s inverse relation */
  Metadata_DateCreated?: InputMaybe<PhotoNestedMetadataDatecreatedCreateInput>;
  /** Actions for the "Photo.Metadata_DateTimeDigitized" relation: "MetadataDateTimeDigitized.photo"'s inverse relation */
  Metadata_DateTimeDigitized?: InputMaybe<PhotoNestedMetadataDatetimedigitizedCreateInput>;
  /** Actions for the "Photo.Metadata_DateTimeOriginal" relation: "MetadataDateTimeOriginal.photo"'s inverse relation */
  Metadata_DateTimeOriginal?: InputMaybe<PhotoNestedMetadataDatetimeoriginalCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryAccount" relation: "MetadataDeliveryAccount.photo"'s inverse relation */
  Metadata_DeliveryAccount?: InputMaybe<PhotoNestedMetadataDeliveryaccountCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCompany" relation: "MetadataDeliveryCompany.photo"'s inverse relation */
  Metadata_DeliveryCompany?: InputMaybe<PhotoNestedMetadataDeliverycompanyCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCopyright" relation: "MetadataDeliveryCopyright.photo"'s inverse relation */
  Metadata_DeliveryCopyright?: InputMaybe<PhotoNestedMetadataDeliverycopyrightCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateFolder" relation: "MetadataDeliveryDateFolder.photo"'s inverse relation */
  Metadata_DeliveryDateFolder?: InputMaybe<PhotoNestedMetadataDeliverydatefolderCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateTime" relation: "MetadataDeliveryDateTime.photo"'s inverse relation */
  Metadata_DeliveryDateTime?: InputMaybe<PhotoNestedMetadataDeliverydatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcCreator" relation: "MetadataDeliveryDcCreator.photo"'s inverse relation */
  Metadata_DeliveryDcCreator?: InputMaybe<PhotoNestedMetadataDeliverydccreatorCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcRights" relation: "MetadataDeliveryDcRights.photo"'s inverse relation */
  Metadata_DeliveryDcRights?: InputMaybe<PhotoNestedMetadataDeliverydcrightsCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFileType" relation: "MetadataDeliveryFileType.photo"'s inverse relation */
  Metadata_DeliveryFileType?: InputMaybe<PhotoNestedMetadataDeliveryfiletypeCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFolder" relation: "MetadataDeliveryFolder.photo"'s inverse relation */
  Metadata_DeliveryFolder?: InputMaybe<PhotoNestedMetadataDeliveryfolderCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryKind" relation: "MetadataDeliveryKind.photo"'s inverse relation */
  Metadata_DeliveryKind?: InputMaybe<PhotoNestedMetadataDeliverykindCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPath" relation: "MetadataDeliveryPath.photo"'s inverse relation */
  Metadata_DeliveryPath?: InputMaybe<PhotoNestedMetadataDeliverypathCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPersonShownintheImage" relation: "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation */
  Metadata_DeliveryPersonShownintheImage?: InputMaybe<PhotoNestedMetadataDeliverypersonshownintheimageCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopCredit" relation: "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopCredit?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopcreditCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopSource" relation: "MetadataDeliveryPhotoshopSource.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopSource?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopsourceCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryService" relation: "MetadataDeliveryService.photo"'s inverse relation */
  Metadata_DeliveryService?: InputMaybe<PhotoNestedMetadataDeliveryserviceCreateInput>;
  /** Actions for the "Photo.Metadata_DeliverySubject" relation: "MetadataDeliverySubject.photo"'s inverse relation */
  Metadata_DeliverySubject?: InputMaybe<PhotoNestedMetadataDeliverysubjectCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryUnderSubject" relation: "MetadataDeliveryUnderSubject.photo"'s inverse relation */
  Metadata_DeliveryUnderSubject?: InputMaybe<PhotoNestedMetadataDeliveryundersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_Department" relation: "MetadataDepartment.photo"'s inverse relation */
  Metadata_Department?: InputMaybe<PhotoNestedMetadataDepartmentCreateInput>;
  /** Actions for the "Photo.Metadata_description" relation: "MetadataDescription.photo"'s inverse relation */
  Metadata_description?: InputMaybe<PhotoNestedMetadataDescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_DigitalAssetURL" relation: "MetadataDigitalAssetUrl.photo"'s inverse relation */
  Metadata_DigitalAssetURL?: InputMaybe<PhotoNestedMetadataDigitalasseturlCreateInput>;
  /** Actions for the "Photo.Metadata_Division" relation: "MetadataDivision.photo"'s inverse relation */
  Metadata_Division?: InputMaybe<PhotoNestedMetadataDivisionCreateInput>;
  /** Actions for the "Photo.Metadata_DocumentText" relation: "MetadataDocumentText.photo"'s inverse relation */
  Metadata_DocumentText?: InputMaybe<PhotoNestedMetadataDocumenttextCreateInput>;
  /** Actions for the "Photo.Metadata_Edition" relation: "MetadataEdition.photo"'s inverse relation */
  Metadata_Edition?: InputMaybe<PhotoNestedMetadataEditionCreateInput>;
  /** Actions for the "Photo.Metadata_EditorialVersion" relation: "MetadataEditorialVersion.photo"'s inverse relation */
  Metadata_EditorialVersion?: InputMaybe<PhotoNestedMetadataEditorialversionCreateInput>;
  /** Actions for the "Photo.Metadata_EditStatus" relation: "MetadataEditStatus.photo"'s inverse relation */
  Metadata_EditStatus?: InputMaybe<PhotoNestedMetadataEditstatusCreateInput>;
  /** Actions for the "Photo.Metadata_EnvironnementPhoto" relation: "MetadataEnvironnementPhoto.photo"'s inverse relation */
  Metadata_EnvironnementPhoto?: InputMaybe<PhotoNestedMetadataEnvironnementphotoCreateInput>;
  /** Actions for the "Photo.Metadata_EquipmentInstitution" relation: "MetadataEquipmentInstitution.photo"'s inverse relation */
  Metadata_EquipmentInstitution?: InputMaybe<PhotoNestedMetadataEquipmentinstitutionCreateInput>;
  /** Actions for the "Photo.Metadata_EquipmentManufacturer" relation: "MetadataEquipmentManufacturer.photo"'s inverse relation */
  Metadata_EquipmentManufacturer?: InputMaybe<PhotoNestedMetadataEquipmentmanufacturerCreateInput>;
  /** Actions for the "Photo.Metadata_Event" relation: "MetadataEvent.photo"'s inverse relation */
  Metadata_Event?: InputMaybe<PhotoNestedMetadataEventCreateInput>;
  /** Actions for the "Photo.Metadata_Exif" relation: "MetadataExif.photo"'s inverse relation */
  Metadata_Exif?: InputMaybe<PhotoNestedMetadataExifCreateInput>;
  /** Actions for the "Photo.Metadata_FabStorage" relation: "MetadataFabStorage.photo"'s inverse relation */
  Metadata_FabStorage?: InputMaybe<PhotoNestedMetadataFabstorageCreateInput>;
  /** Actions for the "Photo.Metadata_FileCheckFor" relation: "MetadataFileCheckFor.photo"'s inverse relation */
  Metadata_FileCheckFor?: InputMaybe<PhotoNestedMetadataFilecheckforCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoBitsPerPixel" relation: "MetadataFileInfoBitsPerPixel.photo"'s inverse relation */
  Metadata_FileInfoBitsPerPixel?: InputMaybe<PhotoNestedMetadataFileinfobitsperpixelCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoFrames" relation: "MetadataFileInfoFrames.photo"'s inverse relation */
  Metadata_FileInfoFrames?: InputMaybe<PhotoNestedMetadataFileinfoframesCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoPhotometricInterpretation" relation: "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation */
  Metadata_FileInfoPhotometricInterpretation?: InputMaybe<PhotoNestedMetadataFileinfophotometricinterpretationCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoTransferSyntax" relation: "MetadataFileInfoTransferSyntax.photo"'s inverse relation */
  Metadata_FileInfoTransferSyntax?: InputMaybe<PhotoNestedMetadataFileinfotransfersyntaxCreateInput>;
  /** Actions for the "Photo.Metadata_FileStage" relation: "MetadataFileStage.photo"'s inverse relation */
  Metadata_FileStage?: InputMaybe<PhotoNestedMetadataFilestageCreateInput>;
  /** Actions for the "Photo.Metadata_FileStatus" relation: "MetadataFileStatus.photo"'s inverse relation */
  Metadata_FileStatus?: InputMaybe<PhotoNestedMetadataFilestatusCreateInput>;
  /** Actions for the "Photo.Metadata_FileType" relation: "MetadataFileType.photo"'s inverse relation */
  Metadata_FileType?: InputMaybe<PhotoNestedMetadataFiletypeCreateInput>;
  /** Actions for the "Photo.Metadata_FixtureIdentifier" relation: "MetadataFixtureIdentifier.photo"'s inverse relation */
  Metadata_FixtureIdentifier?: InputMaybe<PhotoNestedMetadataFixtureidentifierCreateInput>;
  /** Actions for the "Photo.Metadata_Format" relation: "MetadataFormat.photo"'s inverse relation */
  Metadata_Format?: InputMaybe<PhotoNestedMetadataFormatCreateInput>;
  /** Actions for the "Photo.Metadata_GPSLatitude" relation: "MetadataGpsLatitude.photo"'s inverse relation */
  Metadata_GPSLatitude?: InputMaybe<PhotoNestedMetadataGpslatitudeCreateInput>;
  /** Actions for the "Photo.Metadata_GPSLongitude" relation: "MetadataGpsLongitude.photo"'s inverse relation */
  Metadata_GPSLongitude?: InputMaybe<PhotoNestedMetadataGpslongitudeCreateInput>;
  /** Actions for the "Photo.Metadata_GPSProcessingMethod" relation: "MetadataGpsProcessingMethod.photo"'s inverse relation */
  Metadata_GPSProcessingMethod?: InputMaybe<PhotoNestedMetadataGpsprocessingmethodCreateInput>;
  /** Actions for the "Photo.Metadata_Header" relation: "MetadataHeader.photo"'s inverse relation */
  Metadata_Header?: InputMaybe<PhotoNestedMetadataHeaderCreateInput>;
  /** Actions for the "Photo.Metadata_HeaderPrisma" relation: "MetadataHeaderPrisma.photo"'s inverse relation */
  Metadata_HeaderPrisma?: InputMaybe<PhotoNestedMetadataHeaderprismaCreateInput>;
  /** Actions for the "Photo.Metadata_Headline" relation: "MetadataHeadline.photo"'s inverse relation */
  Metadata_Headline?: InputMaybe<PhotoNestedMetadataHeadlineCreateInput>;
  /** Actions for the "Photo.Metadata_History" relation: "MetadataHistory.photo"'s inverse relation */
  Metadata_History?: InputMaybe<PhotoNestedMetadataHistoryCreateInput>;
  /** Actions for the "Photo.Metadata_ICCProfile" relation: "MetadataIccProfile.photo"'s inverse relation */
  Metadata_ICCProfile?: InputMaybe<PhotoNestedMetadataIccprofileCreateInput>;
  /** Actions for the "Photo.Metadata_ID_subject" relation: "MetadataIdSubject.photo"'s inverse relation */
  Metadata_ID_subject?: InputMaybe<PhotoNestedMetadataIdSubjectCreateInput>;
  /** Actions for the "Photo.Metadata_IdAssignment" relation: "MetadataIdAssignment.photo"'s inverse relation */
  Metadata_IdAssignment?: InputMaybe<PhotoNestedMetadataIdassignmentCreateInput>;
  /** Actions for the "Photo.Metadata_IdMediaContent" relation: "MetadataIdMediaContent.photo"'s inverse relation */
  Metadata_IdMediaContent?: InputMaybe<PhotoNestedMetadataIdmediacontentCreateInput>;
  /** Actions for the "Photo.Metadata_IdPhotoPrisma" relation: "MetadataIdPhotoPrisma.photo"'s inverse relation */
  Metadata_IdPhotoPrisma?: InputMaybe<PhotoNestedMetadataIdphotoprismaCreateInput>;
  /** Actions for the "Photo.Metadata_ImageNotes" relation: "MetadataImageNotes.photo"'s inverse relation */
  Metadata_ImageNotes?: InputMaybe<PhotoNestedMetadataImagenotesCreateInput>;
  /** Actions for the "Photo.Metadata_ImageUniqueID" relation: "MetadataImageUniqueId.photo"'s inverse relation */
  Metadata_ImageUniqueID?: InputMaybe<PhotoNestedMetadataImageuniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Instructions" relation: "MetadataInstructions.photo"'s inverse relation */
  Metadata_Instructions?: InputMaybe<PhotoNestedMetadataInstructionsCreateInput>;
  /** Actions for the "Photo.Metadata_IntellectualGenre" relation: "MetadataIntellectualGenre.photo"'s inverse relation */
  Metadata_IntellectualGenre?: InputMaybe<PhotoNestedMetadataIntellectualgenreCreateInput>;
  /** Actions for the "Photo.Metadata_ISOSpeedRatings" relation: "MetadataIsoSpeedRatings.photo"'s inverse relation */
  Metadata_ISOSpeedRatings?: InputMaybe<PhotoNestedMetadataIsospeedratingsCreateInput>;
  /** Actions for the "Photo.Metadata_Issue" relation: "MetadataIssue.photo"'s inverse relation */
  Metadata_Issue?: InputMaybe<PhotoNestedMetadataIssueCreateInput>;
  /** Actions for the "Photo.Metadata_JobId" relation: "MetadataJobId.photo"'s inverse relation */
  Metadata_JobId?: InputMaybe<PhotoNestedMetadataJobidCreateInput>;
  /** Actions for the "Photo.Metadata_JobProcessing" relation: "MetadataJobProcessing.photo"'s inverse relation */
  Metadata_JobProcessing?: InputMaybe<PhotoNestedMetadataJobprocessingCreateInput>;
  /** Actions for the "Photo.Metadata_LayoutPictureEditing" relation: "MetadataLayoutPictureEditing.photo"'s inverse relation */
  Metadata_LayoutPictureEditing?: InputMaybe<PhotoNestedMetadataLayoutpictureeditingCreateInput>;
  /** Actions for the "Photo.Metadata_LayoutStorage" relation: "MetadataLayoutStorage.photo"'s inverse relation */
  Metadata_LayoutStorage?: InputMaybe<PhotoNestedMetadataLayoutstorageCreateInput>;
  /** Actions for the "Photo.Metadata_license" relation: "MetadataLicense.photo"'s inverse relation */
  Metadata_license?: InputMaybe<PhotoNestedMetadataLicenseCreateInput>;
  /** Actions for the "Photo.Metadata_Links" relation: "MetadataLinks.photo"'s inverse relation */
  Metadata_Links?: InputMaybe<PhotoNestedMetadataLinksCreateInput>;
  /** Actions for the "Photo.Metadata_LocalCaption" relation: "MetadataLocalCaption.photo"'s inverse relation */
  Metadata_LocalCaption?: InputMaybe<PhotoNestedMetadataLocalcaptionCreateInput>;
  /** Actions for the "Photo.Metadata_Location" relation: "MetadataLocation.photo"'s inverse relation */
  Metadata_Location?: InputMaybe<PhotoNestedMetadataLocationCreateInput>;
  /** Actions for the "Photo.Metadata_Make" relation: "MetadataMake.photo"'s inverse relation */
  Metadata_Make?: InputMaybe<PhotoNestedMetadataMakeCreateInput>;
  /** Actions for the "Photo.Metadata_Manifest" relation: "MetadataManifest.photo"'s inverse relation */
  Metadata_Manifest?: InputMaybe<PhotoNestedMetadataManifestCreateInput>;
  /** Actions for the "Photo.Metadata_Marked" relation: "MetadataMarked.photo"'s inverse relation */
  Metadata_Marked?: InputMaybe<PhotoNestedMetadataMarkedCreateInput>;
  /** Actions for the "Photo.Metadata_MasterDocumentId" relation: "MetadataMasterDocumentId.photo"'s inverse relation */
  Metadata_MasterDocumentId?: InputMaybe<PhotoNestedMetadataMasterdocumentidCreateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailHeight" relation: "MetadataMaxAvailHeight.photo"'s inverse relation */
  Metadata_MaxAvailHeight?: InputMaybe<PhotoNestedMetadataMaxavailheightCreateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailWidth" relation: "MetadataMaxAvailWidth.photo"'s inverse relation */
  Metadata_MaxAvailWidth?: InputMaybe<PhotoNestedMetadataMaxavailwidthCreateInput>;
  /** Actions for the "Photo.Metadata_MinorModelAgeDisclosure" relation: "MetadataMinorModelAgeDisclosure.photo"'s inverse relation */
  Metadata_MinorModelAgeDisclosure?: InputMaybe<PhotoNestedMetadataMinormodelagedisclosureCreateInput>;
  /** Actions for the "Photo.Metadata_Model" relation: "MetadataModel.photo"'s inverse relation */
  Metadata_Model?: InputMaybe<PhotoNestedMetadataModelCreateInput>;
  /** Actions for the "Photo.Metadata_ModelAge" relation: "MetadataModelAge.photo"'s inverse relation */
  Metadata_ModelAge?: InputMaybe<PhotoNestedMetadataModelageCreateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseID" relation: "MetadataModelReleaseId.photo"'s inverse relation */
  Metadata_ModelReleaseID?: InputMaybe<PhotoNestedMetadataModelreleaseidCreateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseStatus" relation: "MetadataModelReleaseStatus.photo"'s inverse relation */
  Metadata_ModelReleaseStatus?: InputMaybe<PhotoNestedMetadataModelreleasestatusCreateInput>;
  /** Actions for the "Photo.Metadata_morePermissions" relation: "MetadataMorePermissions.photo"'s inverse relation */
  Metadata_morePermissions?: InputMaybe<PhotoNestedMetadataMorepermissionsCreateInput>;
  /** Actions for the "Photo.Metadata_ObjectCycle" relation: "MetadataObjectCycle.photo"'s inverse relation */
  Metadata_ObjectCycle?: InputMaybe<PhotoNestedMetadataObjectcycleCreateInput>;
  /** Actions for the "Photo.Metadata_OrganisationInImageName" relation: "MetadataOrganisationInImageName.photo"'s inverse relation */
  Metadata_OrganisationInImageName?: InputMaybe<PhotoNestedMetadataOrganisationinimagenameCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalColor" relation: "MetadataOriginalColor.photo"'s inverse relation */
  Metadata_OriginalColor?: InputMaybe<PhotoNestedMetadataOriginalcolorCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalICCProfile" relation: "MetadataOriginalIccProfile.photo"'s inverse relation */
  Metadata_OriginalICCProfile?: InputMaybe<PhotoNestedMetadataOriginaliccprofileCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalName" relation: "MetadataOriginalName.photo"'s inverse relation */
  Metadata_OriginalName?: InputMaybe<PhotoNestedMetadataOriginalnameCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalWeigth" relation: "MetadataOriginalWeigth.photo"'s inverse relation */
  Metadata_OriginalWeigth?: InputMaybe<PhotoNestedMetadataOriginalweigthCreateInput>;
  /** Actions for the "Photo.Metadata_Owner" relation: "MetadataOwner.photo"'s inverse relation */
  Metadata_Owner?: InputMaybe<PhotoNestedMetadataOwnerCreateInput>;
  /** Actions for the "Photo.Metadata_OwnerId" relation: "MetadataOwnerId.photo"'s inverse relation */
  Metadata_OwnerId?: InputMaybe<PhotoNestedMetadataOwneridCreateInput>;
  /** Actions for the "Photo.Metadata_Page" relation: "MetadataPage.photo"'s inverse relation */
  Metadata_Page?: InputMaybe<PhotoNestedMetadataPageCreateInput>;
  /** Actions for the "Photo.Metadata_PagePrisma" relation: "MetadataPagePrisma.photo"'s inverse relation */
  Metadata_PagePrisma?: InputMaybe<PhotoNestedMetadataPageprismaCreateInput>;
  /** Actions for the "Photo.Metadata_PaintBasedCorrections" relation: "MetadataPaintBasedCorrections.photo"'s inverse relation */
  Metadata_PaintBasedCorrections?: InputMaybe<PhotoNestedMetadataPaintbasedcorrectionsCreateInput>;
  /** Actions for the "Photo.Metadata_PatientDOB" relation: "MetadataPatientDob.photo"'s inverse relation */
  Metadata_PatientDOB?: InputMaybe<PhotoNestedMetadataPatientdobCreateInput>;
  /** Actions for the "Photo.Metadata_PatientID" relation: "MetadataPatientId.photo"'s inverse relation */
  Metadata_PatientID?: InputMaybe<PhotoNestedMetadataPatientidCreateInput>;
  /** Actions for the "Photo.Metadata_PatientName" relation: "MetadataPatientName.photo"'s inverse relation */
  Metadata_PatientName?: InputMaybe<PhotoNestedMetadataPatientnameCreateInput>;
  /** Actions for the "Photo.Metadata_PatientSex" relation: "MetadataPatientSex.photo"'s inverse relation */
  Metadata_PatientSex?: InputMaybe<PhotoNestedMetadataPatientsexCreateInput>;
  /** Actions for the "Photo.Metadata_PersonInImage" relation: "MetadataPersonInImage.photo"'s inverse relation */
  Metadata_PersonInImage?: InputMaybe<PhotoNestedMetadataPersoninimageCreateInput>;
  /** Actions for the "Photo.Metadata_PhotoStorage" relation: "MetadataPhotoStorage.photo"'s inverse relation */
  Metadata_PhotoStorage?: InputMaybe<PhotoNestedMetadataPhotostorageCreateInput>;
  /** Actions for the "Photo.Metadata_PrepressPictureEditing" relation: "MetadataPrepressPictureEditing.photo"'s inverse relation */
  Metadata_PrepressPictureEditing?: InputMaybe<PhotoNestedMetadataPrepresspictureeditingCreateInput>;
  /** Actions for the "Photo.Metadata_PriceLevel" relation: "MetadataPriceLevel.photo"'s inverse relation */
  Metadata_PriceLevel?: InputMaybe<PhotoNestedMetadataPricelevelCreateInput>;
  /** Actions for the "Photo.Metadata_PrintingProfile" relation: "MetadataPrintingProfile.photo"'s inverse relation */
  Metadata_PrintingProfile?: InputMaybe<PhotoNestedMetadataPrintingprofileCreateInput>;
  /** Actions for the "Photo.Metadata_PrismaProduction" relation: "MetadataPrismaProduction.photo"'s inverse relation */
  Metadata_PrismaProduction?: InputMaybe<PhotoNestedMetadataPrismaproductionCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessHistory" relation: "MetadataProcessHistory.photo"'s inverse relation */
  Metadata_ProcessHistory?: InputMaybe<PhotoNestedMetadataProcesshistoryCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessParameter" relation: "MetadataProcessParameter.photo"'s inverse relation */
  Metadata_ProcessParameter?: InputMaybe<PhotoNestedMetadataProcessparameterCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessStatus" relation: "MetadataProcessStatus.photo"'s inverse relation */
  Metadata_ProcessStatus?: InputMaybe<PhotoNestedMetadataProcessstatusCreateInput>;
  /** Actions for the "Photo.Metadata_Product" relation: "MetadataProduct.photo"'s inverse relation */
  Metadata_Product?: InputMaybe<PhotoNestedMetadataProductCreateInput>;
  /** Actions for the "Photo.Metadata_Products" relation: "MetadataProducts.photo"'s inverse relation */
  Metadata_Products?: InputMaybe<PhotoNestedMetadataProductsCreateInput>;
  /** Actions for the "Photo.Metadata_ProductShortName" relation: "MetadataProductShortName.photo"'s inverse relation */
  Metadata_ProductShortName?: InputMaybe<PhotoNestedMetadataProductshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_Produits" relation: "MetadataProduits.photo"'s inverse relation */
  Metadata_Produits?: InputMaybe<PhotoNestedMetadataProduitsCreateInput>;
  /** Actions for the "Photo.Metadata_ProgramVersion" relation: "MetadataProgramVersion.photo"'s inverse relation */
  Metadata_ProgramVersion?: InputMaybe<PhotoNestedMetadataProgramversionCreateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseID" relation: "MetadataPropertyReleaseId.photo"'s inverse relation */
  Metadata_PropertyReleaseID?: InputMaybe<PhotoNestedMetadataPropertyreleaseidCreateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseStatus" relation: "MetadataPropertyReleaseStatus.photo"'s inverse relation */
  Metadata_PropertyReleaseStatus?: InputMaybe<PhotoNestedMetadataPropertyreleasestatusCreateInput>;
  /** Actions for the "Photo.Metadata_Publisher" relation: "MetadataPublisher.photo"'s inverse relation */
  Metadata_Publisher?: InputMaybe<PhotoNestedMetadataPublisherCreateInput>;
  /** Actions for the "Photo.Metadata_publishingIssue" relation: "MetadataPublishingIssue.photo"'s inverse relation */
  Metadata_publishingIssue?: InputMaybe<PhotoNestedMetadataPublishingissueCreateInput>;
  /** Actions for the "Photo.Metadata_publishingSubject" relation: "MetadataPublishingSubject.photo"'s inverse relation */
  Metadata_publishingSubject?: InputMaybe<PhotoNestedMetadataPublishingsubjectCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaBy" relation: "MetadataQualifiedUsePrismaBy.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaBy?: InputMaybe<PhotoNestedMetadataQualifieduseprismabyCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDate" relation: "MetadataQualifiedUsePrismaDate.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDate?: InputMaybe<PhotoNestedMetadataQualifieduseprismadateCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDuration" relation: "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDuration?: InputMaybe<PhotoNestedMetadataQualifieduseprismadurationCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaSupport" relation: "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaSupport?: InputMaybe<PhotoNestedMetadataQualifieduseprismasupportCreateInput>;
  /** Actions for the "Photo.Metadata_Rating" relation: "MetadataRating.photo"'s inverse relation */
  Metadata_Rating?: InputMaybe<PhotoNestedMetadataRatingCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceDate" relation: "MetadataReferenceDate.photo"'s inverse relation */
  Metadata_ReferenceDate?: InputMaybe<PhotoNestedMetadataReferencedateCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceNumber" relation: "MetadataReferenceNumber.photo"'s inverse relation */
  Metadata_ReferenceNumber?: InputMaybe<PhotoNestedMetadataReferencenumberCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceService" relation: "MetadataReferenceService.photo"'s inverse relation */
  Metadata_ReferenceService?: InputMaybe<PhotoNestedMetadataReferenceserviceCreateInput>;
  /** Actions for the "Photo.Metadata_ReleaseDate" relation: "MetadataReleaseDate.photo"'s inverse relation */
  Metadata_ReleaseDate?: InputMaybe<PhotoNestedMetadataReleasedateCreateInput>;
  /** Actions for the "Photo.Metadata_ReleaseTime" relation: "MetadataReleaseTime.photo"'s inverse relation */
  Metadata_ReleaseTime?: InputMaybe<PhotoNestedMetadataReleasetimeCreateInput>;
  /** Actions for the "Photo.Metadata_RequiredPermission" relation: "MetadataRequiredPermission.photo"'s inverse relation */
  Metadata_RequiredPermission?: InputMaybe<PhotoNestedMetadataRequiredpermissionCreateInput>;
  /** Actions for the "Photo.Metadata_ResolutionKind" relation: "MetadataResolutionKind.photo"'s inverse relation */
  Metadata_ResolutionKind?: InputMaybe<PhotoNestedMetadataResolutionkindCreateInput>;
  /** Actions for the "Photo.Metadata_rights" relation: "MetadataRights.photo"'s inverse relation */
  Metadata_rights?: InputMaybe<PhotoNestedMetadataRightsCreateInput>;
  /** Actions for the "Photo.Metadata_Royaltyfree" relation: "MetadataRoyaltyfree.photo"'s inverse relation */
  Metadata_Royaltyfree?: InputMaybe<PhotoNestedMetadataRoyaltyfreeCreateInput>;
  /** Actions for the "Photo.Metadata_Scene" relation: "MetadataScene.photo"'s inverse relation */
  Metadata_Scene?: InputMaybe<PhotoNestedMetadataSceneCreateInput>;
  /** Actions for the "Photo.Metadata_Section" relation: "MetadataSection.photo"'s inverse relation */
  Metadata_Section?: InputMaybe<PhotoNestedMetadataSectionCreateInput>;
  /** Actions for the "Photo.Metadata_SentTo" relation: "MetadataSentTo.photo"'s inverse relation */
  Metadata_SentTo?: InputMaybe<PhotoNestedMetadataSenttoCreateInput>;
  /** Actions for the "Photo.Metadata_SerialNumber" relation: "MetadataSerialNumber.photo"'s inverse relation */
  Metadata_SerialNumber?: InputMaybe<PhotoNestedMetadataSerialnumberCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesDateTime" relation: "MetadataSeriesDateTime.photo"'s inverse relation */
  Metadata_SeriesDateTime?: InputMaybe<PhotoNestedMetadataSeriesdatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesDescription" relation: "MetadataSeriesDescription.photo"'s inverse relation */
  Metadata_SeriesDescription?: InputMaybe<PhotoNestedMetadataSeriesdescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesModality" relation: "MetadataSeriesModality.photo"'s inverse relation */
  Metadata_SeriesModality?: InputMaybe<PhotoNestedMetadataSeriesmodalityCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesNumber" relation: "MetadataSeriesNumber.photo"'s inverse relation */
  Metadata_SeriesNumber?: InputMaybe<PhotoNestedMetadataSeriesnumberCreateInput>;
  /** Actions for the "Photo.Metadata_ShortUniqueId" relation: "MetadataShortUniqueId.photo"'s inverse relation */
  Metadata_ShortUniqueId?: InputMaybe<PhotoNestedMetadataShortuniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Source" relation: "MetadataSource.photo"'s inverse relation */
  Metadata_Source?: InputMaybe<PhotoNestedMetadataSourceCreateInput>;
  /** Actions for the "Photo.Metadata_State" relation: "MetadataState.photo"'s inverse relation */
  Metadata_State?: InputMaybe<PhotoNestedMetadataStateCreateInput>;
  /** Actions for the "Photo.Metadata_StudyDateTime" relation: "MetadataStudyDateTime.photo"'s inverse relation */
  Metadata_StudyDateTime?: InputMaybe<PhotoNestedMetadataStudydatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_StudyDescription" relation: "MetadataStudyDescription.photo"'s inverse relation */
  Metadata_StudyDescription?: InputMaybe<PhotoNestedMetadataStudydescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_StudyID" relation: "MetadataStudyId.photo"'s inverse relation */
  Metadata_StudyID?: InputMaybe<PhotoNestedMetadataStudyidCreateInput>;
  /** Actions for the "Photo.Metadata_StudyPhysician" relation: "MetadataStudyPhysician.photo"'s inverse relation */
  Metadata_StudyPhysician?: InputMaybe<PhotoNestedMetadataStudyphysicianCreateInput>;
  /** Actions for the "Photo.Metadata_subject" relation: "MetadataSubject.photo"'s inverse relation */
  Metadata_subject?: InputMaybe<PhotoNestedMetadataSubjectCreateInput>;
  /** Actions for the "Photo.Metadata_SubjectCode" relation: "MetadataSubjectCode.photo"'s inverse relation */
  Metadata_SubjectCode?: InputMaybe<PhotoNestedMetadataSubjectcodeCreateInput>;
  /** Actions for the "Photo.Metadata_SubjectPrisma" relation: "MetadataSubjectPrisma.photo"'s inverse relation */
  Metadata_SubjectPrisma?: InputMaybe<PhotoNestedMetadataSubjectprismaCreateInput>;
  /** Actions for the "Photo.Metadata_SupplementalCategories" relation: "MetadataSupplementalCategories.photo"'s inverse relation */
  Metadata_SupplementalCategories?: InputMaybe<PhotoNestedMetadataSupplementalcategoriesCreateInput>;
  /** Actions for the "Photo.Metadata_TargetVersion" relation: "MetadataTargetVersion.photo"'s inverse relation */
  Metadata_TargetVersion?: InputMaybe<PhotoNestedMetadataTargetversionCreateInput>;
  /** Actions for the "Photo.Metadata_title" relation: "MetadataTitle.photo"'s inverse relation */
  Metadata_title?: InputMaybe<PhotoNestedMetadataTitleCreateInput>;
  /** Actions for the "Photo.Metadata_ToneCurve" relation: "MetadataToneCurve.photo"'s inverse relation */
  Metadata_ToneCurve?: InputMaybe<PhotoNestedMetadataTonecurveCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredBy" relation: "MetadataTransferredBy.photo"'s inverse relation */
  Metadata_TransferredBy?: InputMaybe<PhotoNestedMetadataTransferredbyCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredByEmail" relation: "MetadataTransferredByEmail.photo"'s inverse relation */
  Metadata_TransferredByEmail?: InputMaybe<PhotoNestedMetadataTransferredbyemailCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredByFullName" relation: "MetadataTransferredByFullName.photo"'s inverse relation */
  Metadata_TransferredByFullName?: InputMaybe<PhotoNestedMetadataTransferredbyfullnameCreateInput>;
  /** Actions for the "Photo.Metadata_TransmissionReference" relation: "MetadataTransmissionReference.photo"'s inverse relation */
  Metadata_TransmissionReference?: InputMaybe<PhotoNestedMetadataTransmissionreferenceCreateInput>;
  /** Actions for the "Photo.Metadata_Type" relation: "MetadataType.photo"'s inverse relation */
  Metadata_Type?: InputMaybe<PhotoNestedMetadataTypeCreateInput>;
  /** Actions for the "Photo.Metadata_Undersubject" relation: "MetadataUndersubject.photo"'s inverse relation */
  Metadata_Undersubject?: InputMaybe<PhotoNestedMetadataUndersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_UnderUnderSubject" relation: "MetadataUnderUnderSubject.photo"'s inverse relation */
  Metadata_UnderUnderSubject?: InputMaybe<PhotoNestedMetadataUnderundersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_UniqueId" relation: "MetadataUniqueId.photo"'s inverse relation */
  Metadata_UniqueId?: InputMaybe<PhotoNestedMetadataUniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Unit" relation: "MetadataUnit.photo"'s inverse relation */
  Metadata_Unit?: InputMaybe<PhotoNestedMetadataUnitCreateInput>;
  /** Actions for the "Photo.Metadata_UnitShortName" relation: "MetadataUnitShortName.photo"'s inverse relation */
  Metadata_UnitShortName?: InputMaybe<PhotoNestedMetadataUnitshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_UploadedBy" relation: "MetadataUploadedBy.photo"'s inverse relation */
  Metadata_UploadedBy?: InputMaybe<PhotoNestedMetadataUploadedbyCreateInput>;
  /** Actions for the "Photo.Metadata_UploadedByFullName" relation: "MetadataUploadedByFullName.photo"'s inverse relation */
  Metadata_UploadedByFullName?: InputMaybe<PhotoNestedMetadataUploadedbyfullnameCreateInput>;
  /** Actions for the "Photo.Metadata_UploadTime" relation: "MetadataUploadTime.photo"'s inverse relation */
  Metadata_UploadTime?: InputMaybe<PhotoNestedMetadataUploadtimeCreateInput>;
  /** Actions for the "Photo.Metadata_Urgency" relation: "MetadataUrgency.photo"'s inverse relation */
  Metadata_Urgency?: InputMaybe<PhotoNestedMetadataUrgencyCreateInput>;
  /** Actions for the "Photo.Metadata_UsageTerms" relation: "MetadataUsageTerms.photo"'s inverse relation */
  Metadata_UsageTerms?: InputMaybe<PhotoNestedMetadataUsagetermsCreateInput>;
  /** Actions for the "Photo.Metadata_UserComment" relation: "MetadataUserComment.photo"'s inverse relation */
  Metadata_UserComment?: InputMaybe<PhotoNestedMetadataUsercommentCreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined195" relation: "MetadataUserDefined195.photo"'s inverse relation */
  Metadata_UserDefined195?: InputMaybe<PhotoNestedMetadataUserdefined195CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined237" relation: "MetadataUserDefined237.photo"'s inverse relation */
  Metadata_UserDefined237?: InputMaybe<PhotoNestedMetadataUserdefined237CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined238" relation: "MetadataUserDefined238.photo"'s inverse relation */
  Metadata_UserDefined238?: InputMaybe<PhotoNestedMetadataUserdefined238CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined239" relation: "MetadataUserDefined239.photo"'s inverse relation */
  Metadata_UserDefined239?: InputMaybe<PhotoNestedMetadataUserdefined239CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined242" relation: "MetadataUserDefined242.photo"'s inverse relation */
  Metadata_UserDefined242?: InputMaybe<PhotoNestedMetadataUserdefined242CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined62" relation: "MetadataUserDefined62.photo"'s inverse relation */
  Metadata_UserDefined62?: InputMaybe<PhotoNestedMetadataUserdefined62CreateInput>;
  /** Actions for the "Photo.Metadata_Valid" relation: "MetadataValid.photo"'s inverse relation */
  Metadata_Valid?: InputMaybe<PhotoNestedMetadataValidCreateInput>;
  /** Actions for the "Photo.Metadata_Version" relation: "MetadataVersion.photo"'s inverse relation */
  Metadata_Version?: InputMaybe<PhotoNestedMetadataVersionCreateInput>;
  /** Actions for the "Photo.Metadata_WebStatement" relation: "MetadataWebStatement.photo"'s inverse relation */
  Metadata_WebStatement?: InputMaybe<PhotoNestedMetadataWebstatementCreateInput>;
  /** Actions for the "Photo.Metadata_WorkflowKind" relation: "MetadataWorkflowKind.photo"'s inverse relation */
  Metadata_WorkflowKind?: InputMaybe<PhotoNestedMetadataWorkflowkindCreateInput>;
  /** Actions for the "Photo.Metadata_XMPFileStamps" relation: "MetadataXmpFileStamps.photo"'s inverse relation */
  Metadata_XMPFileStamps?: InputMaybe<PhotoNestedMetadataXmpfilestampsCreateInput>;
  /** Actions for the "Photo.Metadata_XMPHistory" relation: "MetadataXmpHistory.photo"'s inverse relation */
  Metadata_XMPHistory?: InputMaybe<PhotoNestedMetadataXmphistoryCreateInput>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.photoMetadatas" relation: "PhotoMetadata.photo"'s inverse relation */
  photoMetadatas?: InputMaybe<PhotoNestedPhotoMetadatasCreateInput>;
  /** Actions for the "Photo.photoPublications" relation: "PhotoPublication.photo"'s inverse relation */
  photoPublications?: InputMaybe<PhotoNestedPhotoPublicationsCreateInput>;
  synced?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date of the photo's capture */
  takenAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type PhotoWithForcedArchiveUpdateInput = {
  /** Actions for the "Photo.agency" relation */
  agency?: InputMaybe<PhotoNestedAgencyUpdateInput>;
  /** Actions for the "Photo.category" relation */
  category?: InputMaybe<PhotoNestedCategoryUpdateInput>;
  /** asset content type */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.deliverySubject" relation */
  deliverySubject?: InputMaybe<PhotoNestedDeliverySubjectUpdateInput>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The date of the photo's importation */
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Actions for the "Photo.importQueue" relation: "ImportQueue.photo"'s inverse relation */
  importQueue?: InputMaybe<PhotoNestedImportQueueUpdateInput>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** Actions for the "Photo.Metadata_AccessRights" relation: "MetadataAccessRights.photo"'s inverse relation */
  Metadata_AccessRights?: InputMaybe<PhotoNestedMetadataAccessrightsUpdateInput>;
  /** Actions for the "Photo.Metadata_AddlModelInfo" relation: "MetadataAddlModelInfo.photo"'s inverse relation */
  Metadata_AddlModelInfo?: InputMaybe<PhotoNestedMetadataAddlmodelinfoUpdateInput>;
  /** Actions for the "Photo.Metadata_Archive_child" relation: "MetadataArchiveChild.photo"'s inverse relation */
  Metadata_Archive_child?: InputMaybe<PhotoNestedMetadataArchiveChildUpdateInput>;
  /** Actions for the "Photo.Metadata_Archive_parent" relation: "MetadataArchiveParent.photo"'s inverse relation */
  Metadata_Archive_parent?: InputMaybe<PhotoNestedMetadataArchiveParentUpdateInput>;
  /** Actions for the "Photo.Metadata_AssetState" relation: "MetadataAssetState.photo"'s inverse relation */
  Metadata_AssetState?: InputMaybe<PhotoNestedMetadataAssetstateUpdateInput>;
  /** Actions for the "Photo.Metadata_attributionName" relation: "MetadataAttributionName.photo"'s inverse relation */
  Metadata_attributionName?: InputMaybe<PhotoNestedMetadataAttributionnameUpdateInput>;
  /** Actions for the "Photo.Metadata_attributionURL" relation: "MetadataAttributionUrl.photo"'s inverse relation */
  Metadata_attributionURL?: InputMaybe<PhotoNestedMetadataAttributionurlUpdateInput>;
  /** Actions for the "Photo.Metadata_Audience" relation: "MetadataAudience.photo"'s inverse relation */
  Metadata_Audience?: InputMaybe<PhotoNestedMetadataAudienceUpdateInput>;
  /** Actions for the "Photo.Metadata_AuthorsPosition" relation: "MetadataAuthorsPosition.photo"'s inverse relation */
  Metadata_AuthorsPosition?: InputMaybe<PhotoNestedMetadataAuthorspositionUpdateInput>;
  /** Actions for the "Photo.Metadata_BackupName" relation: "MetadataBackupName.photo"'s inverse relation */
  Metadata_BackupName?: InputMaybe<PhotoNestedMetadataBackupnameUpdateInput>;
  /** Actions for the "Photo.Metadata_BitmapGrayscalePicture" relation: "MetadataBitmapGrayscalePicture.photo"'s inverse relation */
  Metadata_BitmapGrayscalePicture?: InputMaybe<PhotoNestedMetadataBitmapgrayscalepictureUpdateInput>;
  /** Actions for the "Photo.Metadata_Brand" relation: "MetadataBrand.photo"'s inverse relation */
  Metadata_Brand?: InputMaybe<PhotoNestedMetadataBrandUpdateInput>;
  /** Actions for the "Photo.Metadata_BrandPrisma" relation: "MetadataBrandPrisma.photo"'s inverse relation */
  Metadata_BrandPrisma?: InputMaybe<PhotoNestedMetadataBrandprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_BrandShortName" relation: "MetadataBrandShortName.photo"'s inverse relation */
  Metadata_BrandShortName?: InputMaybe<PhotoNestedMetadataBrandshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_CaptionWriter" relation: "MetadataCaptionWriter.photo"'s inverse relation */
  Metadata_CaptionWriter?: InputMaybe<PhotoNestedMetadataCaptionwriterUpdateInput>;
  /** Actions for the "Photo.Metadata_Category" relation: "MetadataCategory.photo"'s inverse relation */
  Metadata_Category?: InputMaybe<PhotoNestedMetadataCategoryUpdateInput>;
  /** Actions for the "Photo.Metadata_CategoryPrisma" relation: "MetadataCategoryPrisma.photo"'s inverse relation */
  Metadata_CategoryPrisma?: InputMaybe<PhotoNestedMetadataCategoryprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_Certificate" relation: "MetadataCertificate.photo"'s inverse relation */
  Metadata_Certificate?: InputMaybe<PhotoNestedMetadataCertificateUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCity" relation: "MetadataCiAdrCity.photo"'s inverse relation */
  Metadata_CiAdrCity?: InputMaybe<PhotoNestedMetadataCiadrcityUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCtry" relation: "MetadataCiAdrCtry.photo"'s inverse relation */
  Metadata_CiAdrCtry?: InputMaybe<PhotoNestedMetadataCiadrctryUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrExtadr" relation: "MetadataCiAdrExtadr.photo"'s inverse relation */
  Metadata_CiAdrExtadr?: InputMaybe<PhotoNestedMetadataCiadrextadrUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrPcode" relation: "MetadataCiAdrPcode.photo"'s inverse relation */
  Metadata_CiAdrPcode?: InputMaybe<PhotoNestedMetadataCiadrpcodeUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrRegion" relation: "MetadataCiAdrRegion.photo"'s inverse relation */
  Metadata_CiAdrRegion?: InputMaybe<PhotoNestedMetadataCiadrregionUpdateInput>;
  /** Actions for the "Photo.Metadata_CiEmailWork" relation: "MetadataCiEmailWork.photo"'s inverse relation */
  Metadata_CiEmailWork?: InputMaybe<PhotoNestedMetadataCiemailworkUpdateInput>;
  /** Actions for the "Photo.Metadata_CiTelWork" relation: "MetadataCiTelWork.photo"'s inverse relation */
  Metadata_CiTelWork?: InputMaybe<PhotoNestedMetadataCitelworkUpdateInput>;
  /** Actions for the "Photo.Metadata_City" relation: "MetadataCity.photo"'s inverse relation */
  Metadata_City?: InputMaybe<PhotoNestedMetadataCityUpdateInput>;
  /** Actions for the "Photo.Metadata_CiUrlWork" relation: "MetadataCiUrlWork.photo"'s inverse relation */
  Metadata_CiUrlWork?: InputMaybe<PhotoNestedMetadataCiurlworkUpdateInput>;
  /** Actions for the "Photo.Metadata_Classify" relation: "MetadataClassify.photo"'s inverse relation */
  Metadata_Classify?: InputMaybe<PhotoNestedMetadataClassifyUpdateInput>;
  /** Actions for the "Photo.Metadata_Company" relation: "MetadataCompany.photo"'s inverse relation */
  Metadata_Company?: InputMaybe<PhotoNestedMetadataCompanyUpdateInput>;
  /** Actions for the "Photo.Metadata_CompanyShortName" relation: "MetadataCompanyShortName.photo"'s inverse relation */
  Metadata_CompanyShortName?: InputMaybe<PhotoNestedMetadataCompanyshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_Confidentiality" relation: "MetadataConfidentiality.photo"'s inverse relation */
  Metadata_Confidentiality?: InputMaybe<PhotoNestedMetadataConfidentialityUpdateInput>;
  /** Actions for the "Photo.Metadata_ContainedIn" relation: "MetadataContainedIn.photo"'s inverse relation */
  Metadata_ContainedIn?: InputMaybe<PhotoNestedMetadataContainedinUpdateInput>;
  /** Actions for the "Photo.Metadata_ContentMediaKind" relation: "MetadataContentMediaKind.photo"'s inverse relation */
  Metadata_ContentMediaKind?: InputMaybe<PhotoNestedMetadataContentmediakindUpdateInput>;
  /** Actions for the "Photo.Metadata_ContentValue" relation: "MetadataContentValue.photo"'s inverse relation */
  Metadata_ContentValue?: InputMaybe<PhotoNestedMetadataContentvalueUpdateInput>;
  /** Actions for the "Photo.Metadata_CopyrightLayer" relation: "MetadataCopyrightLayer.photo"'s inverse relation */
  Metadata_CopyrightLayer?: InputMaybe<PhotoNestedMetadataCopyrightlayerUpdateInput>;
  /** Actions for the "Photo.Metadata_Country" relation: "MetadataCountry.photo"'s inverse relation */
  Metadata_Country?: InputMaybe<PhotoNestedMetadataCountryUpdateInput>;
  /** Actions for the "Photo.Metadata_CountryCode" relation: "MetadataCountryCode.photo"'s inverse relation */
  Metadata_CountryCode?: InputMaybe<PhotoNestedMetadataCountrycodeUpdateInput>;
  /** Actions for the "Photo.Metadata_CreatedTime" relation: "MetadataCreatedTime.photo"'s inverse relation */
  Metadata_CreatedTime?: InputMaybe<PhotoNestedMetadataCreatedtimeUpdateInput>;
  /** Actions for the "Photo.Metadata_creator" relation: "MetadataCreator.photo"'s inverse relation */
  Metadata_creator?: InputMaybe<PhotoNestedMetadataCreatorUpdateInput>;
  /** Actions for the "Photo.Metadata_CreatorTool" relation: "MetadataCreatorTool.photo"'s inverse relation */
  Metadata_CreatorTool?: InputMaybe<PhotoNestedMetadataCreatortoolUpdateInput>;
  /** Actions for the "Photo.Metadata_Credit" relation: "MetadataCredit.photo"'s inverse relation */
  Metadata_Credit?: InputMaybe<PhotoNestedMetadataCreditUpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField14" relation: "MetadataCustomField14.photo"'s inverse relation */
  Metadata_CustomField14?: InputMaybe<PhotoNestedMetadataCustomfield14UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField15" relation: "MetadataCustomField15.photo"'s inverse relation */
  Metadata_CustomField15?: InputMaybe<PhotoNestedMetadataCustomfield15UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField16" relation: "MetadataCustomField16.photo"'s inverse relation */
  Metadata_CustomField16?: InputMaybe<PhotoNestedMetadataCustomfield16UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField17" relation: "MetadataCustomField17.photo"'s inverse relation */
  Metadata_CustomField17?: InputMaybe<PhotoNestedMetadataCustomfield17UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField20" relation: "MetadataCustomField20.photo"'s inverse relation */
  Metadata_CustomField20?: InputMaybe<PhotoNestedMetadataCustomfield20UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField5" relation: "MetadataCustomField5.photo"'s inverse relation */
  Metadata_CustomField5?: InputMaybe<PhotoNestedMetadataCustomfield5UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField6" relation: "MetadataCustomField6.photo"'s inverse relation */
  Metadata_CustomField6?: InputMaybe<PhotoNestedMetadataCustomfield6UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField7" relation: "MetadataCustomField7.photo"'s inverse relation */
  Metadata_CustomField7?: InputMaybe<PhotoNestedMetadataCustomfield7UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField9" relation: "MetadataCustomField9.photo"'s inverse relation */
  Metadata_CustomField9?: InputMaybe<PhotoNestedMetadataCustomfield9UpdateInput>;
  /** Actions for the "Photo.Metadata_DateCreated" relation: "MetadataDateCreated.photo"'s inverse relation */
  Metadata_DateCreated?: InputMaybe<PhotoNestedMetadataDatecreatedUpdateInput>;
  /** Actions for the "Photo.Metadata_DateTimeDigitized" relation: "MetadataDateTimeDigitized.photo"'s inverse relation */
  Metadata_DateTimeDigitized?: InputMaybe<PhotoNestedMetadataDatetimedigitizedUpdateInput>;
  /** Actions for the "Photo.Metadata_DateTimeOriginal" relation: "MetadataDateTimeOriginal.photo"'s inverse relation */
  Metadata_DateTimeOriginal?: InputMaybe<PhotoNestedMetadataDatetimeoriginalUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryAccount" relation: "MetadataDeliveryAccount.photo"'s inverse relation */
  Metadata_DeliveryAccount?: InputMaybe<PhotoNestedMetadataDeliveryaccountUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCompany" relation: "MetadataDeliveryCompany.photo"'s inverse relation */
  Metadata_DeliveryCompany?: InputMaybe<PhotoNestedMetadataDeliverycompanyUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCopyright" relation: "MetadataDeliveryCopyright.photo"'s inverse relation */
  Metadata_DeliveryCopyright?: InputMaybe<PhotoNestedMetadataDeliverycopyrightUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateFolder" relation: "MetadataDeliveryDateFolder.photo"'s inverse relation */
  Metadata_DeliveryDateFolder?: InputMaybe<PhotoNestedMetadataDeliverydatefolderUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateTime" relation: "MetadataDeliveryDateTime.photo"'s inverse relation */
  Metadata_DeliveryDateTime?: InputMaybe<PhotoNestedMetadataDeliverydatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcCreator" relation: "MetadataDeliveryDcCreator.photo"'s inverse relation */
  Metadata_DeliveryDcCreator?: InputMaybe<PhotoNestedMetadataDeliverydccreatorUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcRights" relation: "MetadataDeliveryDcRights.photo"'s inverse relation */
  Metadata_DeliveryDcRights?: InputMaybe<PhotoNestedMetadataDeliverydcrightsUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFileType" relation: "MetadataDeliveryFileType.photo"'s inverse relation */
  Metadata_DeliveryFileType?: InputMaybe<PhotoNestedMetadataDeliveryfiletypeUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFolder" relation: "MetadataDeliveryFolder.photo"'s inverse relation */
  Metadata_DeliveryFolder?: InputMaybe<PhotoNestedMetadataDeliveryfolderUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryKind" relation: "MetadataDeliveryKind.photo"'s inverse relation */
  Metadata_DeliveryKind?: InputMaybe<PhotoNestedMetadataDeliverykindUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPath" relation: "MetadataDeliveryPath.photo"'s inverse relation */
  Metadata_DeliveryPath?: InputMaybe<PhotoNestedMetadataDeliverypathUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPersonShownintheImage" relation: "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation */
  Metadata_DeliveryPersonShownintheImage?: InputMaybe<PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopCredit" relation: "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopCredit?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopSource" relation: "MetadataDeliveryPhotoshopSource.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopSource?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryService" relation: "MetadataDeliveryService.photo"'s inverse relation */
  Metadata_DeliveryService?: InputMaybe<PhotoNestedMetadataDeliveryserviceUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliverySubject" relation: "MetadataDeliverySubject.photo"'s inverse relation */
  Metadata_DeliverySubject?: InputMaybe<PhotoNestedMetadataDeliverysubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryUnderSubject" relation: "MetadataDeliveryUnderSubject.photo"'s inverse relation */
  Metadata_DeliveryUnderSubject?: InputMaybe<PhotoNestedMetadataDeliveryundersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_Department" relation: "MetadataDepartment.photo"'s inverse relation */
  Metadata_Department?: InputMaybe<PhotoNestedMetadataDepartmentUpdateInput>;
  /** Actions for the "Photo.Metadata_description" relation: "MetadataDescription.photo"'s inverse relation */
  Metadata_description?: InputMaybe<PhotoNestedMetadataDescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_DigitalAssetURL" relation: "MetadataDigitalAssetUrl.photo"'s inverse relation */
  Metadata_DigitalAssetURL?: InputMaybe<PhotoNestedMetadataDigitalasseturlUpdateInput>;
  /** Actions for the "Photo.Metadata_Division" relation: "MetadataDivision.photo"'s inverse relation */
  Metadata_Division?: InputMaybe<PhotoNestedMetadataDivisionUpdateInput>;
  /** Actions for the "Photo.Metadata_DocumentText" relation: "MetadataDocumentText.photo"'s inverse relation */
  Metadata_DocumentText?: InputMaybe<PhotoNestedMetadataDocumenttextUpdateInput>;
  /** Actions for the "Photo.Metadata_Edition" relation: "MetadataEdition.photo"'s inverse relation */
  Metadata_Edition?: InputMaybe<PhotoNestedMetadataEditionUpdateInput>;
  /** Actions for the "Photo.Metadata_EditorialVersion" relation: "MetadataEditorialVersion.photo"'s inverse relation */
  Metadata_EditorialVersion?: InputMaybe<PhotoNestedMetadataEditorialversionUpdateInput>;
  /** Actions for the "Photo.Metadata_EditStatus" relation: "MetadataEditStatus.photo"'s inverse relation */
  Metadata_EditStatus?: InputMaybe<PhotoNestedMetadataEditstatusUpdateInput>;
  /** Actions for the "Photo.Metadata_EnvironnementPhoto" relation: "MetadataEnvironnementPhoto.photo"'s inverse relation */
  Metadata_EnvironnementPhoto?: InputMaybe<PhotoNestedMetadataEnvironnementphotoUpdateInput>;
  /** Actions for the "Photo.Metadata_EquipmentInstitution" relation: "MetadataEquipmentInstitution.photo"'s inverse relation */
  Metadata_EquipmentInstitution?: InputMaybe<PhotoNestedMetadataEquipmentinstitutionUpdateInput>;
  /** Actions for the "Photo.Metadata_EquipmentManufacturer" relation: "MetadataEquipmentManufacturer.photo"'s inverse relation */
  Metadata_EquipmentManufacturer?: InputMaybe<PhotoNestedMetadataEquipmentmanufacturerUpdateInput>;
  /** Actions for the "Photo.Metadata_Event" relation: "MetadataEvent.photo"'s inverse relation */
  Metadata_Event?: InputMaybe<PhotoNestedMetadataEventUpdateInput>;
  /** Actions for the "Photo.Metadata_Exif" relation: "MetadataExif.photo"'s inverse relation */
  Metadata_Exif?: InputMaybe<PhotoNestedMetadataExifUpdateInput>;
  /** Actions for the "Photo.Metadata_FabStorage" relation: "MetadataFabStorage.photo"'s inverse relation */
  Metadata_FabStorage?: InputMaybe<PhotoNestedMetadataFabstorageUpdateInput>;
  /** Actions for the "Photo.Metadata_FileCheckFor" relation: "MetadataFileCheckFor.photo"'s inverse relation */
  Metadata_FileCheckFor?: InputMaybe<PhotoNestedMetadataFilecheckforUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoBitsPerPixel" relation: "MetadataFileInfoBitsPerPixel.photo"'s inverse relation */
  Metadata_FileInfoBitsPerPixel?: InputMaybe<PhotoNestedMetadataFileinfobitsperpixelUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoFrames" relation: "MetadataFileInfoFrames.photo"'s inverse relation */
  Metadata_FileInfoFrames?: InputMaybe<PhotoNestedMetadataFileinfoframesUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoPhotometricInterpretation" relation: "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation */
  Metadata_FileInfoPhotometricInterpretation?: InputMaybe<PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoTransferSyntax" relation: "MetadataFileInfoTransferSyntax.photo"'s inverse relation */
  Metadata_FileInfoTransferSyntax?: InputMaybe<PhotoNestedMetadataFileinfotransfersyntaxUpdateInput>;
  /** Actions for the "Photo.Metadata_FileStage" relation: "MetadataFileStage.photo"'s inverse relation */
  Metadata_FileStage?: InputMaybe<PhotoNestedMetadataFilestageUpdateInput>;
  /** Actions for the "Photo.Metadata_FileStatus" relation: "MetadataFileStatus.photo"'s inverse relation */
  Metadata_FileStatus?: InputMaybe<PhotoNestedMetadataFilestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_FileType" relation: "MetadataFileType.photo"'s inverse relation */
  Metadata_FileType?: InputMaybe<PhotoNestedMetadataFiletypeUpdateInput>;
  /** Actions for the "Photo.Metadata_FixtureIdentifier" relation: "MetadataFixtureIdentifier.photo"'s inverse relation */
  Metadata_FixtureIdentifier?: InputMaybe<PhotoNestedMetadataFixtureidentifierUpdateInput>;
  /** Actions for the "Photo.Metadata_Format" relation: "MetadataFormat.photo"'s inverse relation */
  Metadata_Format?: InputMaybe<PhotoNestedMetadataFormatUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSLatitude" relation: "MetadataGpsLatitude.photo"'s inverse relation */
  Metadata_GPSLatitude?: InputMaybe<PhotoNestedMetadataGpslatitudeUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSLongitude" relation: "MetadataGpsLongitude.photo"'s inverse relation */
  Metadata_GPSLongitude?: InputMaybe<PhotoNestedMetadataGpslongitudeUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSProcessingMethod" relation: "MetadataGpsProcessingMethod.photo"'s inverse relation */
  Metadata_GPSProcessingMethod?: InputMaybe<PhotoNestedMetadataGpsprocessingmethodUpdateInput>;
  /** Actions for the "Photo.Metadata_Header" relation: "MetadataHeader.photo"'s inverse relation */
  Metadata_Header?: InputMaybe<PhotoNestedMetadataHeaderUpdateInput>;
  /** Actions for the "Photo.Metadata_HeaderPrisma" relation: "MetadataHeaderPrisma.photo"'s inverse relation */
  Metadata_HeaderPrisma?: InputMaybe<PhotoNestedMetadataHeaderprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_Headline" relation: "MetadataHeadline.photo"'s inverse relation */
  Metadata_Headline?: InputMaybe<PhotoNestedMetadataHeadlineUpdateInput>;
  /** Actions for the "Photo.Metadata_History" relation: "MetadataHistory.photo"'s inverse relation */
  Metadata_History?: InputMaybe<PhotoNestedMetadataHistoryUpdateInput>;
  /** Actions for the "Photo.Metadata_ICCProfile" relation: "MetadataIccProfile.photo"'s inverse relation */
  Metadata_ICCProfile?: InputMaybe<PhotoNestedMetadataIccprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_ID_subject" relation: "MetadataIdSubject.photo"'s inverse relation */
  Metadata_ID_subject?: InputMaybe<PhotoNestedMetadataIdSubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_IdAssignment" relation: "MetadataIdAssignment.photo"'s inverse relation */
  Metadata_IdAssignment?: InputMaybe<PhotoNestedMetadataIdassignmentUpdateInput>;
  /** Actions for the "Photo.Metadata_IdMediaContent" relation: "MetadataIdMediaContent.photo"'s inverse relation */
  Metadata_IdMediaContent?: InputMaybe<PhotoNestedMetadataIdmediacontentUpdateInput>;
  /** Actions for the "Photo.Metadata_IdPhotoPrisma" relation: "MetadataIdPhotoPrisma.photo"'s inverse relation */
  Metadata_IdPhotoPrisma?: InputMaybe<PhotoNestedMetadataIdphotoprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_ImageNotes" relation: "MetadataImageNotes.photo"'s inverse relation */
  Metadata_ImageNotes?: InputMaybe<PhotoNestedMetadataImagenotesUpdateInput>;
  /** Actions for the "Photo.Metadata_ImageUniqueID" relation: "MetadataImageUniqueId.photo"'s inverse relation */
  Metadata_ImageUniqueID?: InputMaybe<PhotoNestedMetadataImageuniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Instructions" relation: "MetadataInstructions.photo"'s inverse relation */
  Metadata_Instructions?: InputMaybe<PhotoNestedMetadataInstructionsUpdateInput>;
  /** Actions for the "Photo.Metadata_IntellectualGenre" relation: "MetadataIntellectualGenre.photo"'s inverse relation */
  Metadata_IntellectualGenre?: InputMaybe<PhotoNestedMetadataIntellectualgenreUpdateInput>;
  /** Actions for the "Photo.Metadata_ISOSpeedRatings" relation: "MetadataIsoSpeedRatings.photo"'s inverse relation */
  Metadata_ISOSpeedRatings?: InputMaybe<PhotoNestedMetadataIsospeedratingsUpdateInput>;
  /** Actions for the "Photo.Metadata_Issue" relation: "MetadataIssue.photo"'s inverse relation */
  Metadata_Issue?: InputMaybe<PhotoNestedMetadataIssueUpdateInput>;
  /** Actions for the "Photo.Metadata_JobId" relation: "MetadataJobId.photo"'s inverse relation */
  Metadata_JobId?: InputMaybe<PhotoNestedMetadataJobidUpdateInput>;
  /** Actions for the "Photo.Metadata_JobProcessing" relation: "MetadataJobProcessing.photo"'s inverse relation */
  Metadata_JobProcessing?: InputMaybe<PhotoNestedMetadataJobprocessingUpdateInput>;
  /** Actions for the "Photo.Metadata_LayoutPictureEditing" relation: "MetadataLayoutPictureEditing.photo"'s inverse relation */
  Metadata_LayoutPictureEditing?: InputMaybe<PhotoNestedMetadataLayoutpictureeditingUpdateInput>;
  /** Actions for the "Photo.Metadata_LayoutStorage" relation: "MetadataLayoutStorage.photo"'s inverse relation */
  Metadata_LayoutStorage?: InputMaybe<PhotoNestedMetadataLayoutstorageUpdateInput>;
  /** Actions for the "Photo.Metadata_license" relation: "MetadataLicense.photo"'s inverse relation */
  Metadata_license?: InputMaybe<PhotoNestedMetadataLicenseUpdateInput>;
  /** Actions for the "Photo.Metadata_Links" relation: "MetadataLinks.photo"'s inverse relation */
  Metadata_Links?: InputMaybe<PhotoNestedMetadataLinksUpdateInput>;
  /** Actions for the "Photo.Metadata_LocalCaption" relation: "MetadataLocalCaption.photo"'s inverse relation */
  Metadata_LocalCaption?: InputMaybe<PhotoNestedMetadataLocalcaptionUpdateInput>;
  /** Actions for the "Photo.Metadata_Location" relation: "MetadataLocation.photo"'s inverse relation */
  Metadata_Location?: InputMaybe<PhotoNestedMetadataLocationUpdateInput>;
  /** Actions for the "Photo.Metadata_Make" relation: "MetadataMake.photo"'s inverse relation */
  Metadata_Make?: InputMaybe<PhotoNestedMetadataMakeUpdateInput>;
  /** Actions for the "Photo.Metadata_Manifest" relation: "MetadataManifest.photo"'s inverse relation */
  Metadata_Manifest?: InputMaybe<PhotoNestedMetadataManifestUpdateInput>;
  /** Actions for the "Photo.Metadata_Marked" relation: "MetadataMarked.photo"'s inverse relation */
  Metadata_Marked?: InputMaybe<PhotoNestedMetadataMarkedUpdateInput>;
  /** Actions for the "Photo.Metadata_MasterDocumentId" relation: "MetadataMasterDocumentId.photo"'s inverse relation */
  Metadata_MasterDocumentId?: InputMaybe<PhotoNestedMetadataMasterdocumentidUpdateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailHeight" relation: "MetadataMaxAvailHeight.photo"'s inverse relation */
  Metadata_MaxAvailHeight?: InputMaybe<PhotoNestedMetadataMaxavailheightUpdateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailWidth" relation: "MetadataMaxAvailWidth.photo"'s inverse relation */
  Metadata_MaxAvailWidth?: InputMaybe<PhotoNestedMetadataMaxavailwidthUpdateInput>;
  /** Actions for the "Photo.Metadata_MinorModelAgeDisclosure" relation: "MetadataMinorModelAgeDisclosure.photo"'s inverse relation */
  Metadata_MinorModelAgeDisclosure?: InputMaybe<PhotoNestedMetadataMinormodelagedisclosureUpdateInput>;
  /** Actions for the "Photo.Metadata_Model" relation: "MetadataModel.photo"'s inverse relation */
  Metadata_Model?: InputMaybe<PhotoNestedMetadataModelUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelAge" relation: "MetadataModelAge.photo"'s inverse relation */
  Metadata_ModelAge?: InputMaybe<PhotoNestedMetadataModelageUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseID" relation: "MetadataModelReleaseId.photo"'s inverse relation */
  Metadata_ModelReleaseID?: InputMaybe<PhotoNestedMetadataModelreleaseidUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseStatus" relation: "MetadataModelReleaseStatus.photo"'s inverse relation */
  Metadata_ModelReleaseStatus?: InputMaybe<PhotoNestedMetadataModelreleasestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_morePermissions" relation: "MetadataMorePermissions.photo"'s inverse relation */
  Metadata_morePermissions?: InputMaybe<PhotoNestedMetadataMorepermissionsUpdateInput>;
  /** Actions for the "Photo.Metadata_ObjectCycle" relation: "MetadataObjectCycle.photo"'s inverse relation */
  Metadata_ObjectCycle?: InputMaybe<PhotoNestedMetadataObjectcycleUpdateInput>;
  /** Actions for the "Photo.Metadata_OrganisationInImageName" relation: "MetadataOrganisationInImageName.photo"'s inverse relation */
  Metadata_OrganisationInImageName?: InputMaybe<PhotoNestedMetadataOrganisationinimagenameUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalColor" relation: "MetadataOriginalColor.photo"'s inverse relation */
  Metadata_OriginalColor?: InputMaybe<PhotoNestedMetadataOriginalcolorUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalICCProfile" relation: "MetadataOriginalIccProfile.photo"'s inverse relation */
  Metadata_OriginalICCProfile?: InputMaybe<PhotoNestedMetadataOriginaliccprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalName" relation: "MetadataOriginalName.photo"'s inverse relation */
  Metadata_OriginalName?: InputMaybe<PhotoNestedMetadataOriginalnameUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalWeigth" relation: "MetadataOriginalWeigth.photo"'s inverse relation */
  Metadata_OriginalWeigth?: InputMaybe<PhotoNestedMetadataOriginalweigthUpdateInput>;
  /** Actions for the "Photo.Metadata_Owner" relation: "MetadataOwner.photo"'s inverse relation */
  Metadata_Owner?: InputMaybe<PhotoNestedMetadataOwnerUpdateInput>;
  /** Actions for the "Photo.Metadata_OwnerId" relation: "MetadataOwnerId.photo"'s inverse relation */
  Metadata_OwnerId?: InputMaybe<PhotoNestedMetadataOwneridUpdateInput>;
  /** Actions for the "Photo.Metadata_Page" relation: "MetadataPage.photo"'s inverse relation */
  Metadata_Page?: InputMaybe<PhotoNestedMetadataPageUpdateInput>;
  /** Actions for the "Photo.Metadata_PagePrisma" relation: "MetadataPagePrisma.photo"'s inverse relation */
  Metadata_PagePrisma?: InputMaybe<PhotoNestedMetadataPageprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_PaintBasedCorrections" relation: "MetadataPaintBasedCorrections.photo"'s inverse relation */
  Metadata_PaintBasedCorrections?: InputMaybe<PhotoNestedMetadataPaintbasedcorrectionsUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientDOB" relation: "MetadataPatientDob.photo"'s inverse relation */
  Metadata_PatientDOB?: InputMaybe<PhotoNestedMetadataPatientdobUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientID" relation: "MetadataPatientId.photo"'s inverse relation */
  Metadata_PatientID?: InputMaybe<PhotoNestedMetadataPatientidUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientName" relation: "MetadataPatientName.photo"'s inverse relation */
  Metadata_PatientName?: InputMaybe<PhotoNestedMetadataPatientnameUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientSex" relation: "MetadataPatientSex.photo"'s inverse relation */
  Metadata_PatientSex?: InputMaybe<PhotoNestedMetadataPatientsexUpdateInput>;
  /** Actions for the "Photo.Metadata_PersonInImage" relation: "MetadataPersonInImage.photo"'s inverse relation */
  Metadata_PersonInImage?: InputMaybe<PhotoNestedMetadataPersoninimageUpdateInput>;
  /** Actions for the "Photo.Metadata_PhotoStorage" relation: "MetadataPhotoStorage.photo"'s inverse relation */
  Metadata_PhotoStorage?: InputMaybe<PhotoNestedMetadataPhotostorageUpdateInput>;
  /** Actions for the "Photo.Metadata_PrepressPictureEditing" relation: "MetadataPrepressPictureEditing.photo"'s inverse relation */
  Metadata_PrepressPictureEditing?: InputMaybe<PhotoNestedMetadataPrepresspictureeditingUpdateInput>;
  /** Actions for the "Photo.Metadata_PriceLevel" relation: "MetadataPriceLevel.photo"'s inverse relation */
  Metadata_PriceLevel?: InputMaybe<PhotoNestedMetadataPricelevelUpdateInput>;
  /** Actions for the "Photo.Metadata_PrintingProfile" relation: "MetadataPrintingProfile.photo"'s inverse relation */
  Metadata_PrintingProfile?: InputMaybe<PhotoNestedMetadataPrintingprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_PrismaProduction" relation: "MetadataPrismaProduction.photo"'s inverse relation */
  Metadata_PrismaProduction?: InputMaybe<PhotoNestedMetadataPrismaproductionUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessHistory" relation: "MetadataProcessHistory.photo"'s inverse relation */
  Metadata_ProcessHistory?: InputMaybe<PhotoNestedMetadataProcesshistoryUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessParameter" relation: "MetadataProcessParameter.photo"'s inverse relation */
  Metadata_ProcessParameter?: InputMaybe<PhotoNestedMetadataProcessparameterUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessStatus" relation: "MetadataProcessStatus.photo"'s inverse relation */
  Metadata_ProcessStatus?: InputMaybe<PhotoNestedMetadataProcessstatusUpdateInput>;
  /** Actions for the "Photo.Metadata_Product" relation: "MetadataProduct.photo"'s inverse relation */
  Metadata_Product?: InputMaybe<PhotoNestedMetadataProductUpdateInput>;
  /** Actions for the "Photo.Metadata_Products" relation: "MetadataProducts.photo"'s inverse relation */
  Metadata_Products?: InputMaybe<PhotoNestedMetadataProductsUpdateInput>;
  /** Actions for the "Photo.Metadata_ProductShortName" relation: "MetadataProductShortName.photo"'s inverse relation */
  Metadata_ProductShortName?: InputMaybe<PhotoNestedMetadataProductshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_Produits" relation: "MetadataProduits.photo"'s inverse relation */
  Metadata_Produits?: InputMaybe<PhotoNestedMetadataProduitsUpdateInput>;
  /** Actions for the "Photo.Metadata_ProgramVersion" relation: "MetadataProgramVersion.photo"'s inverse relation */
  Metadata_ProgramVersion?: InputMaybe<PhotoNestedMetadataProgramversionUpdateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseID" relation: "MetadataPropertyReleaseId.photo"'s inverse relation */
  Metadata_PropertyReleaseID?: InputMaybe<PhotoNestedMetadataPropertyreleaseidUpdateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseStatus" relation: "MetadataPropertyReleaseStatus.photo"'s inverse relation */
  Metadata_PropertyReleaseStatus?: InputMaybe<PhotoNestedMetadataPropertyreleasestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_Publisher" relation: "MetadataPublisher.photo"'s inverse relation */
  Metadata_Publisher?: InputMaybe<PhotoNestedMetadataPublisherUpdateInput>;
  /** Actions for the "Photo.Metadata_publishingIssue" relation: "MetadataPublishingIssue.photo"'s inverse relation */
  Metadata_publishingIssue?: InputMaybe<PhotoNestedMetadataPublishingissueUpdateInput>;
  /** Actions for the "Photo.Metadata_publishingSubject" relation: "MetadataPublishingSubject.photo"'s inverse relation */
  Metadata_publishingSubject?: InputMaybe<PhotoNestedMetadataPublishingsubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaBy" relation: "MetadataQualifiedUsePrismaBy.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaBy?: InputMaybe<PhotoNestedMetadataQualifieduseprismabyUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDate" relation: "MetadataQualifiedUsePrismaDate.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDate?: InputMaybe<PhotoNestedMetadataQualifieduseprismadateUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDuration" relation: "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDuration?: InputMaybe<PhotoNestedMetadataQualifieduseprismadurationUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaSupport" relation: "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaSupport?: InputMaybe<PhotoNestedMetadataQualifieduseprismasupportUpdateInput>;
  /** Actions for the "Photo.Metadata_Rating" relation: "MetadataRating.photo"'s inverse relation */
  Metadata_Rating?: InputMaybe<PhotoNestedMetadataRatingUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceDate" relation: "MetadataReferenceDate.photo"'s inverse relation */
  Metadata_ReferenceDate?: InputMaybe<PhotoNestedMetadataReferencedateUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceNumber" relation: "MetadataReferenceNumber.photo"'s inverse relation */
  Metadata_ReferenceNumber?: InputMaybe<PhotoNestedMetadataReferencenumberUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceService" relation: "MetadataReferenceService.photo"'s inverse relation */
  Metadata_ReferenceService?: InputMaybe<PhotoNestedMetadataReferenceserviceUpdateInput>;
  /** Actions for the "Photo.Metadata_ReleaseDate" relation: "MetadataReleaseDate.photo"'s inverse relation */
  Metadata_ReleaseDate?: InputMaybe<PhotoNestedMetadataReleasedateUpdateInput>;
  /** Actions for the "Photo.Metadata_ReleaseTime" relation: "MetadataReleaseTime.photo"'s inverse relation */
  Metadata_ReleaseTime?: InputMaybe<PhotoNestedMetadataReleasetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_RequiredPermission" relation: "MetadataRequiredPermission.photo"'s inverse relation */
  Metadata_RequiredPermission?: InputMaybe<PhotoNestedMetadataRequiredpermissionUpdateInput>;
  /** Actions for the "Photo.Metadata_ResolutionKind" relation: "MetadataResolutionKind.photo"'s inverse relation */
  Metadata_ResolutionKind?: InputMaybe<PhotoNestedMetadataResolutionkindUpdateInput>;
  /** Actions for the "Photo.Metadata_rights" relation: "MetadataRights.photo"'s inverse relation */
  Metadata_rights?: InputMaybe<PhotoNestedMetadataRightsUpdateInput>;
  /** Actions for the "Photo.Metadata_Royaltyfree" relation: "MetadataRoyaltyfree.photo"'s inverse relation */
  Metadata_Royaltyfree?: InputMaybe<PhotoNestedMetadataRoyaltyfreeUpdateInput>;
  /** Actions for the "Photo.Metadata_Scene" relation: "MetadataScene.photo"'s inverse relation */
  Metadata_Scene?: InputMaybe<PhotoNestedMetadataSceneUpdateInput>;
  /** Actions for the "Photo.Metadata_Section" relation: "MetadataSection.photo"'s inverse relation */
  Metadata_Section?: InputMaybe<PhotoNestedMetadataSectionUpdateInput>;
  /** Actions for the "Photo.Metadata_SentTo" relation: "MetadataSentTo.photo"'s inverse relation */
  Metadata_SentTo?: InputMaybe<PhotoNestedMetadataSenttoUpdateInput>;
  /** Actions for the "Photo.Metadata_SerialNumber" relation: "MetadataSerialNumber.photo"'s inverse relation */
  Metadata_SerialNumber?: InputMaybe<PhotoNestedMetadataSerialnumberUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesDateTime" relation: "MetadataSeriesDateTime.photo"'s inverse relation */
  Metadata_SeriesDateTime?: InputMaybe<PhotoNestedMetadataSeriesdatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesDescription" relation: "MetadataSeriesDescription.photo"'s inverse relation */
  Metadata_SeriesDescription?: InputMaybe<PhotoNestedMetadataSeriesdescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesModality" relation: "MetadataSeriesModality.photo"'s inverse relation */
  Metadata_SeriesModality?: InputMaybe<PhotoNestedMetadataSeriesmodalityUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesNumber" relation: "MetadataSeriesNumber.photo"'s inverse relation */
  Metadata_SeriesNumber?: InputMaybe<PhotoNestedMetadataSeriesnumberUpdateInput>;
  /** Actions for the "Photo.Metadata_ShortUniqueId" relation: "MetadataShortUniqueId.photo"'s inverse relation */
  Metadata_ShortUniqueId?: InputMaybe<PhotoNestedMetadataShortuniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Source" relation: "MetadataSource.photo"'s inverse relation */
  Metadata_Source?: InputMaybe<PhotoNestedMetadataSourceUpdateInput>;
  /** Actions for the "Photo.Metadata_State" relation: "MetadataState.photo"'s inverse relation */
  Metadata_State?: InputMaybe<PhotoNestedMetadataStateUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyDateTime" relation: "MetadataStudyDateTime.photo"'s inverse relation */
  Metadata_StudyDateTime?: InputMaybe<PhotoNestedMetadataStudydatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyDescription" relation: "MetadataStudyDescription.photo"'s inverse relation */
  Metadata_StudyDescription?: InputMaybe<PhotoNestedMetadataStudydescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyID" relation: "MetadataStudyId.photo"'s inverse relation */
  Metadata_StudyID?: InputMaybe<PhotoNestedMetadataStudyidUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyPhysician" relation: "MetadataStudyPhysician.photo"'s inverse relation */
  Metadata_StudyPhysician?: InputMaybe<PhotoNestedMetadataStudyphysicianUpdateInput>;
  /** Actions for the "Photo.Metadata_subject" relation: "MetadataSubject.photo"'s inverse relation */
  Metadata_subject?: InputMaybe<PhotoNestedMetadataSubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_SubjectCode" relation: "MetadataSubjectCode.photo"'s inverse relation */
  Metadata_SubjectCode?: InputMaybe<PhotoNestedMetadataSubjectcodeUpdateInput>;
  /** Actions for the "Photo.Metadata_SubjectPrisma" relation: "MetadataSubjectPrisma.photo"'s inverse relation */
  Metadata_SubjectPrisma?: InputMaybe<PhotoNestedMetadataSubjectprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_SupplementalCategories" relation: "MetadataSupplementalCategories.photo"'s inverse relation */
  Metadata_SupplementalCategories?: InputMaybe<PhotoNestedMetadataSupplementalcategoriesUpdateInput>;
  /** Actions for the "Photo.Metadata_TargetVersion" relation: "MetadataTargetVersion.photo"'s inverse relation */
  Metadata_TargetVersion?: InputMaybe<PhotoNestedMetadataTargetversionUpdateInput>;
  /** Actions for the "Photo.Metadata_title" relation: "MetadataTitle.photo"'s inverse relation */
  Metadata_title?: InputMaybe<PhotoNestedMetadataTitleUpdateInput>;
  /** Actions for the "Photo.Metadata_ToneCurve" relation: "MetadataToneCurve.photo"'s inverse relation */
  Metadata_ToneCurve?: InputMaybe<PhotoNestedMetadataTonecurveUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredBy" relation: "MetadataTransferredBy.photo"'s inverse relation */
  Metadata_TransferredBy?: InputMaybe<PhotoNestedMetadataTransferredbyUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredByEmail" relation: "MetadataTransferredByEmail.photo"'s inverse relation */
  Metadata_TransferredByEmail?: InputMaybe<PhotoNestedMetadataTransferredbyemailUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredByFullName" relation: "MetadataTransferredByFullName.photo"'s inverse relation */
  Metadata_TransferredByFullName?: InputMaybe<PhotoNestedMetadataTransferredbyfullnameUpdateInput>;
  /** Actions for the "Photo.Metadata_TransmissionReference" relation: "MetadataTransmissionReference.photo"'s inverse relation */
  Metadata_TransmissionReference?: InputMaybe<PhotoNestedMetadataTransmissionreferenceUpdateInput>;
  /** Actions for the "Photo.Metadata_Type" relation: "MetadataType.photo"'s inverse relation */
  Metadata_Type?: InputMaybe<PhotoNestedMetadataTypeUpdateInput>;
  /** Actions for the "Photo.Metadata_Undersubject" relation: "MetadataUndersubject.photo"'s inverse relation */
  Metadata_Undersubject?: InputMaybe<PhotoNestedMetadataUndersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_UnderUnderSubject" relation: "MetadataUnderUnderSubject.photo"'s inverse relation */
  Metadata_UnderUnderSubject?: InputMaybe<PhotoNestedMetadataUnderundersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_UniqueId" relation: "MetadataUniqueId.photo"'s inverse relation */
  Metadata_UniqueId?: InputMaybe<PhotoNestedMetadataUniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Unit" relation: "MetadataUnit.photo"'s inverse relation */
  Metadata_Unit?: InputMaybe<PhotoNestedMetadataUnitUpdateInput>;
  /** Actions for the "Photo.Metadata_UnitShortName" relation: "MetadataUnitShortName.photo"'s inverse relation */
  Metadata_UnitShortName?: InputMaybe<PhotoNestedMetadataUnitshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadedBy" relation: "MetadataUploadedBy.photo"'s inverse relation */
  Metadata_UploadedBy?: InputMaybe<PhotoNestedMetadataUploadedbyUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadedByFullName" relation: "MetadataUploadedByFullName.photo"'s inverse relation */
  Metadata_UploadedByFullName?: InputMaybe<PhotoNestedMetadataUploadedbyfullnameUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadTime" relation: "MetadataUploadTime.photo"'s inverse relation */
  Metadata_UploadTime?: InputMaybe<PhotoNestedMetadataUploadtimeUpdateInput>;
  /** Actions for the "Photo.Metadata_Urgency" relation: "MetadataUrgency.photo"'s inverse relation */
  Metadata_Urgency?: InputMaybe<PhotoNestedMetadataUrgencyUpdateInput>;
  /** Actions for the "Photo.Metadata_UsageTerms" relation: "MetadataUsageTerms.photo"'s inverse relation */
  Metadata_UsageTerms?: InputMaybe<PhotoNestedMetadataUsagetermsUpdateInput>;
  /** Actions for the "Photo.Metadata_UserComment" relation: "MetadataUserComment.photo"'s inverse relation */
  Metadata_UserComment?: InputMaybe<PhotoNestedMetadataUsercommentUpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined195" relation: "MetadataUserDefined195.photo"'s inverse relation */
  Metadata_UserDefined195?: InputMaybe<PhotoNestedMetadataUserdefined195UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined237" relation: "MetadataUserDefined237.photo"'s inverse relation */
  Metadata_UserDefined237?: InputMaybe<PhotoNestedMetadataUserdefined237UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined238" relation: "MetadataUserDefined238.photo"'s inverse relation */
  Metadata_UserDefined238?: InputMaybe<PhotoNestedMetadataUserdefined238UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined239" relation: "MetadataUserDefined239.photo"'s inverse relation */
  Metadata_UserDefined239?: InputMaybe<PhotoNestedMetadataUserdefined239UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined242" relation: "MetadataUserDefined242.photo"'s inverse relation */
  Metadata_UserDefined242?: InputMaybe<PhotoNestedMetadataUserdefined242UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined62" relation: "MetadataUserDefined62.photo"'s inverse relation */
  Metadata_UserDefined62?: InputMaybe<PhotoNestedMetadataUserdefined62UpdateInput>;
  /** Actions for the "Photo.Metadata_Valid" relation: "MetadataValid.photo"'s inverse relation */
  Metadata_Valid?: InputMaybe<PhotoNestedMetadataValidUpdateInput>;
  /** Actions for the "Photo.Metadata_Version" relation: "MetadataVersion.photo"'s inverse relation */
  Metadata_Version?: InputMaybe<PhotoNestedMetadataVersionUpdateInput>;
  /** Actions for the "Photo.Metadata_WebStatement" relation: "MetadataWebStatement.photo"'s inverse relation */
  Metadata_WebStatement?: InputMaybe<PhotoNestedMetadataWebstatementUpdateInput>;
  /** Actions for the "Photo.Metadata_WorkflowKind" relation: "MetadataWorkflowKind.photo"'s inverse relation */
  Metadata_WorkflowKind?: InputMaybe<PhotoNestedMetadataWorkflowkindUpdateInput>;
  /** Actions for the "Photo.Metadata_XMPFileStamps" relation: "MetadataXmpFileStamps.photo"'s inverse relation */
  Metadata_XMPFileStamps?: InputMaybe<PhotoNestedMetadataXmpfilestampsUpdateInput>;
  /** Actions for the "Photo.Metadata_XMPHistory" relation: "MetadataXmpHistory.photo"'s inverse relation */
  Metadata_XMPHistory?: InputMaybe<PhotoNestedMetadataXmphistoryUpdateInput>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.photoMetadatas" relation: "PhotoMetadata.photo"'s inverse relation */
  photoMetadatas?: InputMaybe<PhotoNestedPhotoMetadatasUpdateInput>;
  /** Actions for the "Photo.photoPublications" relation: "PhotoPublication.photo"'s inverse relation */
  photoPublications?: InputMaybe<PhotoNestedPhotoPublicationsUpdateInput>;
  synced?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date of the photo's capture */
  takenAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type PhotoWithForcedCategoryCreateInput = {
  /** Actions for the "Photo.agency" relation */
  agency?: InputMaybe<PhotoNestedAgencyCreateInput>;
  /** Actions for the "Photo.archive" relation */
  archive: PhotoNestedArchiveCreateInput;
  /** asset content type */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.deliverySubject" relation */
  deliverySubject?: InputMaybe<PhotoNestedDeliverySubjectCreateInput>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The date of the photo's importation */
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Actions for the "Photo.importQueue" relation: "ImportQueue.photo"'s inverse relation */
  importQueue?: InputMaybe<PhotoNestedImportQueueCreateInput>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** Actions for the "Photo.Metadata_AccessRights" relation: "MetadataAccessRights.photo"'s inverse relation */
  Metadata_AccessRights?: InputMaybe<PhotoNestedMetadataAccessrightsCreateInput>;
  /** Actions for the "Photo.Metadata_AddlModelInfo" relation: "MetadataAddlModelInfo.photo"'s inverse relation */
  Metadata_AddlModelInfo?: InputMaybe<PhotoNestedMetadataAddlmodelinfoCreateInput>;
  /** Actions for the "Photo.Metadata_Archive_child" relation: "MetadataArchiveChild.photo"'s inverse relation */
  Metadata_Archive_child?: InputMaybe<PhotoNestedMetadataArchiveChildCreateInput>;
  /** Actions for the "Photo.Metadata_Archive_parent" relation: "MetadataArchiveParent.photo"'s inverse relation */
  Metadata_Archive_parent?: InputMaybe<PhotoNestedMetadataArchiveParentCreateInput>;
  /** Actions for the "Photo.Metadata_AssetState" relation: "MetadataAssetState.photo"'s inverse relation */
  Metadata_AssetState?: InputMaybe<PhotoNestedMetadataAssetstateCreateInput>;
  /** Actions for the "Photo.Metadata_attributionName" relation: "MetadataAttributionName.photo"'s inverse relation */
  Metadata_attributionName?: InputMaybe<PhotoNestedMetadataAttributionnameCreateInput>;
  /** Actions for the "Photo.Metadata_attributionURL" relation: "MetadataAttributionUrl.photo"'s inverse relation */
  Metadata_attributionURL?: InputMaybe<PhotoNestedMetadataAttributionurlCreateInput>;
  /** Actions for the "Photo.Metadata_Audience" relation: "MetadataAudience.photo"'s inverse relation */
  Metadata_Audience?: InputMaybe<PhotoNestedMetadataAudienceCreateInput>;
  /** Actions for the "Photo.Metadata_AuthorsPosition" relation: "MetadataAuthorsPosition.photo"'s inverse relation */
  Metadata_AuthorsPosition?: InputMaybe<PhotoNestedMetadataAuthorspositionCreateInput>;
  /** Actions for the "Photo.Metadata_BackupName" relation: "MetadataBackupName.photo"'s inverse relation */
  Metadata_BackupName?: InputMaybe<PhotoNestedMetadataBackupnameCreateInput>;
  /** Actions for the "Photo.Metadata_BitmapGrayscalePicture" relation: "MetadataBitmapGrayscalePicture.photo"'s inverse relation */
  Metadata_BitmapGrayscalePicture?: InputMaybe<PhotoNestedMetadataBitmapgrayscalepictureCreateInput>;
  /** Actions for the "Photo.Metadata_Brand" relation: "MetadataBrand.photo"'s inverse relation */
  Metadata_Brand?: InputMaybe<PhotoNestedMetadataBrandCreateInput>;
  /** Actions for the "Photo.Metadata_BrandPrisma" relation: "MetadataBrandPrisma.photo"'s inverse relation */
  Metadata_BrandPrisma?: InputMaybe<PhotoNestedMetadataBrandprismaCreateInput>;
  /** Actions for the "Photo.Metadata_BrandShortName" relation: "MetadataBrandShortName.photo"'s inverse relation */
  Metadata_BrandShortName?: InputMaybe<PhotoNestedMetadataBrandshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_CaptionWriter" relation: "MetadataCaptionWriter.photo"'s inverse relation */
  Metadata_CaptionWriter?: InputMaybe<PhotoNestedMetadataCaptionwriterCreateInput>;
  /** Actions for the "Photo.Metadata_Category" relation: "MetadataCategory.photo"'s inverse relation */
  Metadata_Category?: InputMaybe<PhotoNestedMetadataCategoryCreateInput>;
  /** Actions for the "Photo.Metadata_CategoryPrisma" relation: "MetadataCategoryPrisma.photo"'s inverse relation */
  Metadata_CategoryPrisma?: InputMaybe<PhotoNestedMetadataCategoryprismaCreateInput>;
  /** Actions for the "Photo.Metadata_Certificate" relation: "MetadataCertificate.photo"'s inverse relation */
  Metadata_Certificate?: InputMaybe<PhotoNestedMetadataCertificateCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCity" relation: "MetadataCiAdrCity.photo"'s inverse relation */
  Metadata_CiAdrCity?: InputMaybe<PhotoNestedMetadataCiadrcityCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCtry" relation: "MetadataCiAdrCtry.photo"'s inverse relation */
  Metadata_CiAdrCtry?: InputMaybe<PhotoNestedMetadataCiadrctryCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrExtadr" relation: "MetadataCiAdrExtadr.photo"'s inverse relation */
  Metadata_CiAdrExtadr?: InputMaybe<PhotoNestedMetadataCiadrextadrCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrPcode" relation: "MetadataCiAdrPcode.photo"'s inverse relation */
  Metadata_CiAdrPcode?: InputMaybe<PhotoNestedMetadataCiadrpcodeCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrRegion" relation: "MetadataCiAdrRegion.photo"'s inverse relation */
  Metadata_CiAdrRegion?: InputMaybe<PhotoNestedMetadataCiadrregionCreateInput>;
  /** Actions for the "Photo.Metadata_CiEmailWork" relation: "MetadataCiEmailWork.photo"'s inverse relation */
  Metadata_CiEmailWork?: InputMaybe<PhotoNestedMetadataCiemailworkCreateInput>;
  /** Actions for the "Photo.Metadata_CiTelWork" relation: "MetadataCiTelWork.photo"'s inverse relation */
  Metadata_CiTelWork?: InputMaybe<PhotoNestedMetadataCitelworkCreateInput>;
  /** Actions for the "Photo.Metadata_City" relation: "MetadataCity.photo"'s inverse relation */
  Metadata_City?: InputMaybe<PhotoNestedMetadataCityCreateInput>;
  /** Actions for the "Photo.Metadata_CiUrlWork" relation: "MetadataCiUrlWork.photo"'s inverse relation */
  Metadata_CiUrlWork?: InputMaybe<PhotoNestedMetadataCiurlworkCreateInput>;
  /** Actions for the "Photo.Metadata_Classify" relation: "MetadataClassify.photo"'s inverse relation */
  Metadata_Classify?: InputMaybe<PhotoNestedMetadataClassifyCreateInput>;
  /** Actions for the "Photo.Metadata_Company" relation: "MetadataCompany.photo"'s inverse relation */
  Metadata_Company?: InputMaybe<PhotoNestedMetadataCompanyCreateInput>;
  /** Actions for the "Photo.Metadata_CompanyShortName" relation: "MetadataCompanyShortName.photo"'s inverse relation */
  Metadata_CompanyShortName?: InputMaybe<PhotoNestedMetadataCompanyshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_Confidentiality" relation: "MetadataConfidentiality.photo"'s inverse relation */
  Metadata_Confidentiality?: InputMaybe<PhotoNestedMetadataConfidentialityCreateInput>;
  /** Actions for the "Photo.Metadata_ContainedIn" relation: "MetadataContainedIn.photo"'s inverse relation */
  Metadata_ContainedIn?: InputMaybe<PhotoNestedMetadataContainedinCreateInput>;
  /** Actions for the "Photo.Metadata_ContentMediaKind" relation: "MetadataContentMediaKind.photo"'s inverse relation */
  Metadata_ContentMediaKind?: InputMaybe<PhotoNestedMetadataContentmediakindCreateInput>;
  /** Actions for the "Photo.Metadata_ContentValue" relation: "MetadataContentValue.photo"'s inverse relation */
  Metadata_ContentValue?: InputMaybe<PhotoNestedMetadataContentvalueCreateInput>;
  /** Actions for the "Photo.Metadata_CopyrightLayer" relation: "MetadataCopyrightLayer.photo"'s inverse relation */
  Metadata_CopyrightLayer?: InputMaybe<PhotoNestedMetadataCopyrightlayerCreateInput>;
  /** Actions for the "Photo.Metadata_Country" relation: "MetadataCountry.photo"'s inverse relation */
  Metadata_Country?: InputMaybe<PhotoNestedMetadataCountryCreateInput>;
  /** Actions for the "Photo.Metadata_CountryCode" relation: "MetadataCountryCode.photo"'s inverse relation */
  Metadata_CountryCode?: InputMaybe<PhotoNestedMetadataCountrycodeCreateInput>;
  /** Actions for the "Photo.Metadata_CreatedTime" relation: "MetadataCreatedTime.photo"'s inverse relation */
  Metadata_CreatedTime?: InputMaybe<PhotoNestedMetadataCreatedtimeCreateInput>;
  /** Actions for the "Photo.Metadata_creator" relation: "MetadataCreator.photo"'s inverse relation */
  Metadata_creator?: InputMaybe<PhotoNestedMetadataCreatorCreateInput>;
  /** Actions for the "Photo.Metadata_CreatorTool" relation: "MetadataCreatorTool.photo"'s inverse relation */
  Metadata_CreatorTool?: InputMaybe<PhotoNestedMetadataCreatortoolCreateInput>;
  /** Actions for the "Photo.Metadata_Credit" relation: "MetadataCredit.photo"'s inverse relation */
  Metadata_Credit?: InputMaybe<PhotoNestedMetadataCreditCreateInput>;
  /** Actions for the "Photo.Metadata_CustomField14" relation: "MetadataCustomField14.photo"'s inverse relation */
  Metadata_CustomField14?: InputMaybe<PhotoNestedMetadataCustomfield14CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField15" relation: "MetadataCustomField15.photo"'s inverse relation */
  Metadata_CustomField15?: InputMaybe<PhotoNestedMetadataCustomfield15CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField16" relation: "MetadataCustomField16.photo"'s inverse relation */
  Metadata_CustomField16?: InputMaybe<PhotoNestedMetadataCustomfield16CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField17" relation: "MetadataCustomField17.photo"'s inverse relation */
  Metadata_CustomField17?: InputMaybe<PhotoNestedMetadataCustomfield17CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField20" relation: "MetadataCustomField20.photo"'s inverse relation */
  Metadata_CustomField20?: InputMaybe<PhotoNestedMetadataCustomfield20CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField5" relation: "MetadataCustomField5.photo"'s inverse relation */
  Metadata_CustomField5?: InputMaybe<PhotoNestedMetadataCustomfield5CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField6" relation: "MetadataCustomField6.photo"'s inverse relation */
  Metadata_CustomField6?: InputMaybe<PhotoNestedMetadataCustomfield6CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField7" relation: "MetadataCustomField7.photo"'s inverse relation */
  Metadata_CustomField7?: InputMaybe<PhotoNestedMetadataCustomfield7CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField9" relation: "MetadataCustomField9.photo"'s inverse relation */
  Metadata_CustomField9?: InputMaybe<PhotoNestedMetadataCustomfield9CreateInput>;
  /** Actions for the "Photo.Metadata_DateCreated" relation: "MetadataDateCreated.photo"'s inverse relation */
  Metadata_DateCreated?: InputMaybe<PhotoNestedMetadataDatecreatedCreateInput>;
  /** Actions for the "Photo.Metadata_DateTimeDigitized" relation: "MetadataDateTimeDigitized.photo"'s inverse relation */
  Metadata_DateTimeDigitized?: InputMaybe<PhotoNestedMetadataDatetimedigitizedCreateInput>;
  /** Actions for the "Photo.Metadata_DateTimeOriginal" relation: "MetadataDateTimeOriginal.photo"'s inverse relation */
  Metadata_DateTimeOriginal?: InputMaybe<PhotoNestedMetadataDatetimeoriginalCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryAccount" relation: "MetadataDeliveryAccount.photo"'s inverse relation */
  Metadata_DeliveryAccount?: InputMaybe<PhotoNestedMetadataDeliveryaccountCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCompany" relation: "MetadataDeliveryCompany.photo"'s inverse relation */
  Metadata_DeliveryCompany?: InputMaybe<PhotoNestedMetadataDeliverycompanyCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCopyright" relation: "MetadataDeliveryCopyright.photo"'s inverse relation */
  Metadata_DeliveryCopyright?: InputMaybe<PhotoNestedMetadataDeliverycopyrightCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateFolder" relation: "MetadataDeliveryDateFolder.photo"'s inverse relation */
  Metadata_DeliveryDateFolder?: InputMaybe<PhotoNestedMetadataDeliverydatefolderCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateTime" relation: "MetadataDeliveryDateTime.photo"'s inverse relation */
  Metadata_DeliveryDateTime?: InputMaybe<PhotoNestedMetadataDeliverydatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcCreator" relation: "MetadataDeliveryDcCreator.photo"'s inverse relation */
  Metadata_DeliveryDcCreator?: InputMaybe<PhotoNestedMetadataDeliverydccreatorCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcRights" relation: "MetadataDeliveryDcRights.photo"'s inverse relation */
  Metadata_DeliveryDcRights?: InputMaybe<PhotoNestedMetadataDeliverydcrightsCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFileType" relation: "MetadataDeliveryFileType.photo"'s inverse relation */
  Metadata_DeliveryFileType?: InputMaybe<PhotoNestedMetadataDeliveryfiletypeCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFolder" relation: "MetadataDeliveryFolder.photo"'s inverse relation */
  Metadata_DeliveryFolder?: InputMaybe<PhotoNestedMetadataDeliveryfolderCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryKind" relation: "MetadataDeliveryKind.photo"'s inverse relation */
  Metadata_DeliveryKind?: InputMaybe<PhotoNestedMetadataDeliverykindCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPath" relation: "MetadataDeliveryPath.photo"'s inverse relation */
  Metadata_DeliveryPath?: InputMaybe<PhotoNestedMetadataDeliverypathCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPersonShownintheImage" relation: "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation */
  Metadata_DeliveryPersonShownintheImage?: InputMaybe<PhotoNestedMetadataDeliverypersonshownintheimageCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopCredit" relation: "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopCredit?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopcreditCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopSource" relation: "MetadataDeliveryPhotoshopSource.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopSource?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopsourceCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryService" relation: "MetadataDeliveryService.photo"'s inverse relation */
  Metadata_DeliveryService?: InputMaybe<PhotoNestedMetadataDeliveryserviceCreateInput>;
  /** Actions for the "Photo.Metadata_DeliverySubject" relation: "MetadataDeliverySubject.photo"'s inverse relation */
  Metadata_DeliverySubject?: InputMaybe<PhotoNestedMetadataDeliverysubjectCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryUnderSubject" relation: "MetadataDeliveryUnderSubject.photo"'s inverse relation */
  Metadata_DeliveryUnderSubject?: InputMaybe<PhotoNestedMetadataDeliveryundersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_Department" relation: "MetadataDepartment.photo"'s inverse relation */
  Metadata_Department?: InputMaybe<PhotoNestedMetadataDepartmentCreateInput>;
  /** Actions for the "Photo.Metadata_description" relation: "MetadataDescription.photo"'s inverse relation */
  Metadata_description?: InputMaybe<PhotoNestedMetadataDescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_DigitalAssetURL" relation: "MetadataDigitalAssetUrl.photo"'s inverse relation */
  Metadata_DigitalAssetURL?: InputMaybe<PhotoNestedMetadataDigitalasseturlCreateInput>;
  /** Actions for the "Photo.Metadata_Division" relation: "MetadataDivision.photo"'s inverse relation */
  Metadata_Division?: InputMaybe<PhotoNestedMetadataDivisionCreateInput>;
  /** Actions for the "Photo.Metadata_DocumentText" relation: "MetadataDocumentText.photo"'s inverse relation */
  Metadata_DocumentText?: InputMaybe<PhotoNestedMetadataDocumenttextCreateInput>;
  /** Actions for the "Photo.Metadata_Edition" relation: "MetadataEdition.photo"'s inverse relation */
  Metadata_Edition?: InputMaybe<PhotoNestedMetadataEditionCreateInput>;
  /** Actions for the "Photo.Metadata_EditorialVersion" relation: "MetadataEditorialVersion.photo"'s inverse relation */
  Metadata_EditorialVersion?: InputMaybe<PhotoNestedMetadataEditorialversionCreateInput>;
  /** Actions for the "Photo.Metadata_EditStatus" relation: "MetadataEditStatus.photo"'s inverse relation */
  Metadata_EditStatus?: InputMaybe<PhotoNestedMetadataEditstatusCreateInput>;
  /** Actions for the "Photo.Metadata_EnvironnementPhoto" relation: "MetadataEnvironnementPhoto.photo"'s inverse relation */
  Metadata_EnvironnementPhoto?: InputMaybe<PhotoNestedMetadataEnvironnementphotoCreateInput>;
  /** Actions for the "Photo.Metadata_EquipmentInstitution" relation: "MetadataEquipmentInstitution.photo"'s inverse relation */
  Metadata_EquipmentInstitution?: InputMaybe<PhotoNestedMetadataEquipmentinstitutionCreateInput>;
  /** Actions for the "Photo.Metadata_EquipmentManufacturer" relation: "MetadataEquipmentManufacturer.photo"'s inverse relation */
  Metadata_EquipmentManufacturer?: InputMaybe<PhotoNestedMetadataEquipmentmanufacturerCreateInput>;
  /** Actions for the "Photo.Metadata_Event" relation: "MetadataEvent.photo"'s inverse relation */
  Metadata_Event?: InputMaybe<PhotoNestedMetadataEventCreateInput>;
  /** Actions for the "Photo.Metadata_Exif" relation: "MetadataExif.photo"'s inverse relation */
  Metadata_Exif?: InputMaybe<PhotoNestedMetadataExifCreateInput>;
  /** Actions for the "Photo.Metadata_FabStorage" relation: "MetadataFabStorage.photo"'s inverse relation */
  Metadata_FabStorage?: InputMaybe<PhotoNestedMetadataFabstorageCreateInput>;
  /** Actions for the "Photo.Metadata_FileCheckFor" relation: "MetadataFileCheckFor.photo"'s inverse relation */
  Metadata_FileCheckFor?: InputMaybe<PhotoNestedMetadataFilecheckforCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoBitsPerPixel" relation: "MetadataFileInfoBitsPerPixel.photo"'s inverse relation */
  Metadata_FileInfoBitsPerPixel?: InputMaybe<PhotoNestedMetadataFileinfobitsperpixelCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoFrames" relation: "MetadataFileInfoFrames.photo"'s inverse relation */
  Metadata_FileInfoFrames?: InputMaybe<PhotoNestedMetadataFileinfoframesCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoPhotometricInterpretation" relation: "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation */
  Metadata_FileInfoPhotometricInterpretation?: InputMaybe<PhotoNestedMetadataFileinfophotometricinterpretationCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoTransferSyntax" relation: "MetadataFileInfoTransferSyntax.photo"'s inverse relation */
  Metadata_FileInfoTransferSyntax?: InputMaybe<PhotoNestedMetadataFileinfotransfersyntaxCreateInput>;
  /** Actions for the "Photo.Metadata_FileStage" relation: "MetadataFileStage.photo"'s inverse relation */
  Metadata_FileStage?: InputMaybe<PhotoNestedMetadataFilestageCreateInput>;
  /** Actions for the "Photo.Metadata_FileStatus" relation: "MetadataFileStatus.photo"'s inverse relation */
  Metadata_FileStatus?: InputMaybe<PhotoNestedMetadataFilestatusCreateInput>;
  /** Actions for the "Photo.Metadata_FileType" relation: "MetadataFileType.photo"'s inverse relation */
  Metadata_FileType?: InputMaybe<PhotoNestedMetadataFiletypeCreateInput>;
  /** Actions for the "Photo.Metadata_FixtureIdentifier" relation: "MetadataFixtureIdentifier.photo"'s inverse relation */
  Metadata_FixtureIdentifier?: InputMaybe<PhotoNestedMetadataFixtureidentifierCreateInput>;
  /** Actions for the "Photo.Metadata_Format" relation: "MetadataFormat.photo"'s inverse relation */
  Metadata_Format?: InputMaybe<PhotoNestedMetadataFormatCreateInput>;
  /** Actions for the "Photo.Metadata_GPSLatitude" relation: "MetadataGpsLatitude.photo"'s inverse relation */
  Metadata_GPSLatitude?: InputMaybe<PhotoNestedMetadataGpslatitudeCreateInput>;
  /** Actions for the "Photo.Metadata_GPSLongitude" relation: "MetadataGpsLongitude.photo"'s inverse relation */
  Metadata_GPSLongitude?: InputMaybe<PhotoNestedMetadataGpslongitudeCreateInput>;
  /** Actions for the "Photo.Metadata_GPSProcessingMethod" relation: "MetadataGpsProcessingMethod.photo"'s inverse relation */
  Metadata_GPSProcessingMethod?: InputMaybe<PhotoNestedMetadataGpsprocessingmethodCreateInput>;
  /** Actions for the "Photo.Metadata_Header" relation: "MetadataHeader.photo"'s inverse relation */
  Metadata_Header?: InputMaybe<PhotoNestedMetadataHeaderCreateInput>;
  /** Actions for the "Photo.Metadata_HeaderPrisma" relation: "MetadataHeaderPrisma.photo"'s inverse relation */
  Metadata_HeaderPrisma?: InputMaybe<PhotoNestedMetadataHeaderprismaCreateInput>;
  /** Actions for the "Photo.Metadata_Headline" relation: "MetadataHeadline.photo"'s inverse relation */
  Metadata_Headline?: InputMaybe<PhotoNestedMetadataHeadlineCreateInput>;
  /** Actions for the "Photo.Metadata_History" relation: "MetadataHistory.photo"'s inverse relation */
  Metadata_History?: InputMaybe<PhotoNestedMetadataHistoryCreateInput>;
  /** Actions for the "Photo.Metadata_ICCProfile" relation: "MetadataIccProfile.photo"'s inverse relation */
  Metadata_ICCProfile?: InputMaybe<PhotoNestedMetadataIccprofileCreateInput>;
  /** Actions for the "Photo.Metadata_ID_subject" relation: "MetadataIdSubject.photo"'s inverse relation */
  Metadata_ID_subject?: InputMaybe<PhotoNestedMetadataIdSubjectCreateInput>;
  /** Actions for the "Photo.Metadata_IdAssignment" relation: "MetadataIdAssignment.photo"'s inverse relation */
  Metadata_IdAssignment?: InputMaybe<PhotoNestedMetadataIdassignmentCreateInput>;
  /** Actions for the "Photo.Metadata_IdMediaContent" relation: "MetadataIdMediaContent.photo"'s inverse relation */
  Metadata_IdMediaContent?: InputMaybe<PhotoNestedMetadataIdmediacontentCreateInput>;
  /** Actions for the "Photo.Metadata_IdPhotoPrisma" relation: "MetadataIdPhotoPrisma.photo"'s inverse relation */
  Metadata_IdPhotoPrisma?: InputMaybe<PhotoNestedMetadataIdphotoprismaCreateInput>;
  /** Actions for the "Photo.Metadata_ImageNotes" relation: "MetadataImageNotes.photo"'s inverse relation */
  Metadata_ImageNotes?: InputMaybe<PhotoNestedMetadataImagenotesCreateInput>;
  /** Actions for the "Photo.Metadata_ImageUniqueID" relation: "MetadataImageUniqueId.photo"'s inverse relation */
  Metadata_ImageUniqueID?: InputMaybe<PhotoNestedMetadataImageuniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Instructions" relation: "MetadataInstructions.photo"'s inverse relation */
  Metadata_Instructions?: InputMaybe<PhotoNestedMetadataInstructionsCreateInput>;
  /** Actions for the "Photo.Metadata_IntellectualGenre" relation: "MetadataIntellectualGenre.photo"'s inverse relation */
  Metadata_IntellectualGenre?: InputMaybe<PhotoNestedMetadataIntellectualgenreCreateInput>;
  /** Actions for the "Photo.Metadata_ISOSpeedRatings" relation: "MetadataIsoSpeedRatings.photo"'s inverse relation */
  Metadata_ISOSpeedRatings?: InputMaybe<PhotoNestedMetadataIsospeedratingsCreateInput>;
  /** Actions for the "Photo.Metadata_Issue" relation: "MetadataIssue.photo"'s inverse relation */
  Metadata_Issue?: InputMaybe<PhotoNestedMetadataIssueCreateInput>;
  /** Actions for the "Photo.Metadata_JobId" relation: "MetadataJobId.photo"'s inverse relation */
  Metadata_JobId?: InputMaybe<PhotoNestedMetadataJobidCreateInput>;
  /** Actions for the "Photo.Metadata_JobProcessing" relation: "MetadataJobProcessing.photo"'s inverse relation */
  Metadata_JobProcessing?: InputMaybe<PhotoNestedMetadataJobprocessingCreateInput>;
  /** Actions for the "Photo.Metadata_LayoutPictureEditing" relation: "MetadataLayoutPictureEditing.photo"'s inverse relation */
  Metadata_LayoutPictureEditing?: InputMaybe<PhotoNestedMetadataLayoutpictureeditingCreateInput>;
  /** Actions for the "Photo.Metadata_LayoutStorage" relation: "MetadataLayoutStorage.photo"'s inverse relation */
  Metadata_LayoutStorage?: InputMaybe<PhotoNestedMetadataLayoutstorageCreateInput>;
  /** Actions for the "Photo.Metadata_license" relation: "MetadataLicense.photo"'s inverse relation */
  Metadata_license?: InputMaybe<PhotoNestedMetadataLicenseCreateInput>;
  /** Actions for the "Photo.Metadata_Links" relation: "MetadataLinks.photo"'s inverse relation */
  Metadata_Links?: InputMaybe<PhotoNestedMetadataLinksCreateInput>;
  /** Actions for the "Photo.Metadata_LocalCaption" relation: "MetadataLocalCaption.photo"'s inverse relation */
  Metadata_LocalCaption?: InputMaybe<PhotoNestedMetadataLocalcaptionCreateInput>;
  /** Actions for the "Photo.Metadata_Location" relation: "MetadataLocation.photo"'s inverse relation */
  Metadata_Location?: InputMaybe<PhotoNestedMetadataLocationCreateInput>;
  /** Actions for the "Photo.Metadata_Make" relation: "MetadataMake.photo"'s inverse relation */
  Metadata_Make?: InputMaybe<PhotoNestedMetadataMakeCreateInput>;
  /** Actions for the "Photo.Metadata_Manifest" relation: "MetadataManifest.photo"'s inverse relation */
  Metadata_Manifest?: InputMaybe<PhotoNestedMetadataManifestCreateInput>;
  /** Actions for the "Photo.Metadata_Marked" relation: "MetadataMarked.photo"'s inverse relation */
  Metadata_Marked?: InputMaybe<PhotoNestedMetadataMarkedCreateInput>;
  /** Actions for the "Photo.Metadata_MasterDocumentId" relation: "MetadataMasterDocumentId.photo"'s inverse relation */
  Metadata_MasterDocumentId?: InputMaybe<PhotoNestedMetadataMasterdocumentidCreateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailHeight" relation: "MetadataMaxAvailHeight.photo"'s inverse relation */
  Metadata_MaxAvailHeight?: InputMaybe<PhotoNestedMetadataMaxavailheightCreateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailWidth" relation: "MetadataMaxAvailWidth.photo"'s inverse relation */
  Metadata_MaxAvailWidth?: InputMaybe<PhotoNestedMetadataMaxavailwidthCreateInput>;
  /** Actions for the "Photo.Metadata_MinorModelAgeDisclosure" relation: "MetadataMinorModelAgeDisclosure.photo"'s inverse relation */
  Metadata_MinorModelAgeDisclosure?: InputMaybe<PhotoNestedMetadataMinormodelagedisclosureCreateInput>;
  /** Actions for the "Photo.Metadata_Model" relation: "MetadataModel.photo"'s inverse relation */
  Metadata_Model?: InputMaybe<PhotoNestedMetadataModelCreateInput>;
  /** Actions for the "Photo.Metadata_ModelAge" relation: "MetadataModelAge.photo"'s inverse relation */
  Metadata_ModelAge?: InputMaybe<PhotoNestedMetadataModelageCreateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseID" relation: "MetadataModelReleaseId.photo"'s inverse relation */
  Metadata_ModelReleaseID?: InputMaybe<PhotoNestedMetadataModelreleaseidCreateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseStatus" relation: "MetadataModelReleaseStatus.photo"'s inverse relation */
  Metadata_ModelReleaseStatus?: InputMaybe<PhotoNestedMetadataModelreleasestatusCreateInput>;
  /** Actions for the "Photo.Metadata_morePermissions" relation: "MetadataMorePermissions.photo"'s inverse relation */
  Metadata_morePermissions?: InputMaybe<PhotoNestedMetadataMorepermissionsCreateInput>;
  /** Actions for the "Photo.Metadata_ObjectCycle" relation: "MetadataObjectCycle.photo"'s inverse relation */
  Metadata_ObjectCycle?: InputMaybe<PhotoNestedMetadataObjectcycleCreateInput>;
  /** Actions for the "Photo.Metadata_OrganisationInImageName" relation: "MetadataOrganisationInImageName.photo"'s inverse relation */
  Metadata_OrganisationInImageName?: InputMaybe<PhotoNestedMetadataOrganisationinimagenameCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalColor" relation: "MetadataOriginalColor.photo"'s inverse relation */
  Metadata_OriginalColor?: InputMaybe<PhotoNestedMetadataOriginalcolorCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalICCProfile" relation: "MetadataOriginalIccProfile.photo"'s inverse relation */
  Metadata_OriginalICCProfile?: InputMaybe<PhotoNestedMetadataOriginaliccprofileCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalName" relation: "MetadataOriginalName.photo"'s inverse relation */
  Metadata_OriginalName?: InputMaybe<PhotoNestedMetadataOriginalnameCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalWeigth" relation: "MetadataOriginalWeigth.photo"'s inverse relation */
  Metadata_OriginalWeigth?: InputMaybe<PhotoNestedMetadataOriginalweigthCreateInput>;
  /** Actions for the "Photo.Metadata_Owner" relation: "MetadataOwner.photo"'s inverse relation */
  Metadata_Owner?: InputMaybe<PhotoNestedMetadataOwnerCreateInput>;
  /** Actions for the "Photo.Metadata_OwnerId" relation: "MetadataOwnerId.photo"'s inverse relation */
  Metadata_OwnerId?: InputMaybe<PhotoNestedMetadataOwneridCreateInput>;
  /** Actions for the "Photo.Metadata_Page" relation: "MetadataPage.photo"'s inverse relation */
  Metadata_Page?: InputMaybe<PhotoNestedMetadataPageCreateInput>;
  /** Actions for the "Photo.Metadata_PagePrisma" relation: "MetadataPagePrisma.photo"'s inverse relation */
  Metadata_PagePrisma?: InputMaybe<PhotoNestedMetadataPageprismaCreateInput>;
  /** Actions for the "Photo.Metadata_PaintBasedCorrections" relation: "MetadataPaintBasedCorrections.photo"'s inverse relation */
  Metadata_PaintBasedCorrections?: InputMaybe<PhotoNestedMetadataPaintbasedcorrectionsCreateInput>;
  /** Actions for the "Photo.Metadata_PatientDOB" relation: "MetadataPatientDob.photo"'s inverse relation */
  Metadata_PatientDOB?: InputMaybe<PhotoNestedMetadataPatientdobCreateInput>;
  /** Actions for the "Photo.Metadata_PatientID" relation: "MetadataPatientId.photo"'s inverse relation */
  Metadata_PatientID?: InputMaybe<PhotoNestedMetadataPatientidCreateInput>;
  /** Actions for the "Photo.Metadata_PatientName" relation: "MetadataPatientName.photo"'s inverse relation */
  Metadata_PatientName?: InputMaybe<PhotoNestedMetadataPatientnameCreateInput>;
  /** Actions for the "Photo.Metadata_PatientSex" relation: "MetadataPatientSex.photo"'s inverse relation */
  Metadata_PatientSex?: InputMaybe<PhotoNestedMetadataPatientsexCreateInput>;
  /** Actions for the "Photo.Metadata_PersonInImage" relation: "MetadataPersonInImage.photo"'s inverse relation */
  Metadata_PersonInImage?: InputMaybe<PhotoNestedMetadataPersoninimageCreateInput>;
  /** Actions for the "Photo.Metadata_PhotoStorage" relation: "MetadataPhotoStorage.photo"'s inverse relation */
  Metadata_PhotoStorage?: InputMaybe<PhotoNestedMetadataPhotostorageCreateInput>;
  /** Actions for the "Photo.Metadata_PrepressPictureEditing" relation: "MetadataPrepressPictureEditing.photo"'s inverse relation */
  Metadata_PrepressPictureEditing?: InputMaybe<PhotoNestedMetadataPrepresspictureeditingCreateInput>;
  /** Actions for the "Photo.Metadata_PriceLevel" relation: "MetadataPriceLevel.photo"'s inverse relation */
  Metadata_PriceLevel?: InputMaybe<PhotoNestedMetadataPricelevelCreateInput>;
  /** Actions for the "Photo.Metadata_PrintingProfile" relation: "MetadataPrintingProfile.photo"'s inverse relation */
  Metadata_PrintingProfile?: InputMaybe<PhotoNestedMetadataPrintingprofileCreateInput>;
  /** Actions for the "Photo.Metadata_PrismaProduction" relation: "MetadataPrismaProduction.photo"'s inverse relation */
  Metadata_PrismaProduction?: InputMaybe<PhotoNestedMetadataPrismaproductionCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessHistory" relation: "MetadataProcessHistory.photo"'s inverse relation */
  Metadata_ProcessHistory?: InputMaybe<PhotoNestedMetadataProcesshistoryCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessParameter" relation: "MetadataProcessParameter.photo"'s inverse relation */
  Metadata_ProcessParameter?: InputMaybe<PhotoNestedMetadataProcessparameterCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessStatus" relation: "MetadataProcessStatus.photo"'s inverse relation */
  Metadata_ProcessStatus?: InputMaybe<PhotoNestedMetadataProcessstatusCreateInput>;
  /** Actions for the "Photo.Metadata_Product" relation: "MetadataProduct.photo"'s inverse relation */
  Metadata_Product?: InputMaybe<PhotoNestedMetadataProductCreateInput>;
  /** Actions for the "Photo.Metadata_Products" relation: "MetadataProducts.photo"'s inverse relation */
  Metadata_Products?: InputMaybe<PhotoNestedMetadataProductsCreateInput>;
  /** Actions for the "Photo.Metadata_ProductShortName" relation: "MetadataProductShortName.photo"'s inverse relation */
  Metadata_ProductShortName?: InputMaybe<PhotoNestedMetadataProductshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_Produits" relation: "MetadataProduits.photo"'s inverse relation */
  Metadata_Produits?: InputMaybe<PhotoNestedMetadataProduitsCreateInput>;
  /** Actions for the "Photo.Metadata_ProgramVersion" relation: "MetadataProgramVersion.photo"'s inverse relation */
  Metadata_ProgramVersion?: InputMaybe<PhotoNestedMetadataProgramversionCreateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseID" relation: "MetadataPropertyReleaseId.photo"'s inverse relation */
  Metadata_PropertyReleaseID?: InputMaybe<PhotoNestedMetadataPropertyreleaseidCreateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseStatus" relation: "MetadataPropertyReleaseStatus.photo"'s inverse relation */
  Metadata_PropertyReleaseStatus?: InputMaybe<PhotoNestedMetadataPropertyreleasestatusCreateInput>;
  /** Actions for the "Photo.Metadata_Publisher" relation: "MetadataPublisher.photo"'s inverse relation */
  Metadata_Publisher?: InputMaybe<PhotoNestedMetadataPublisherCreateInput>;
  /** Actions for the "Photo.Metadata_publishingIssue" relation: "MetadataPublishingIssue.photo"'s inverse relation */
  Metadata_publishingIssue?: InputMaybe<PhotoNestedMetadataPublishingissueCreateInput>;
  /** Actions for the "Photo.Metadata_publishingSubject" relation: "MetadataPublishingSubject.photo"'s inverse relation */
  Metadata_publishingSubject?: InputMaybe<PhotoNestedMetadataPublishingsubjectCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaBy" relation: "MetadataQualifiedUsePrismaBy.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaBy?: InputMaybe<PhotoNestedMetadataQualifieduseprismabyCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDate" relation: "MetadataQualifiedUsePrismaDate.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDate?: InputMaybe<PhotoNestedMetadataQualifieduseprismadateCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDuration" relation: "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDuration?: InputMaybe<PhotoNestedMetadataQualifieduseprismadurationCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaSupport" relation: "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaSupport?: InputMaybe<PhotoNestedMetadataQualifieduseprismasupportCreateInput>;
  /** Actions for the "Photo.Metadata_Rating" relation: "MetadataRating.photo"'s inverse relation */
  Metadata_Rating?: InputMaybe<PhotoNestedMetadataRatingCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceDate" relation: "MetadataReferenceDate.photo"'s inverse relation */
  Metadata_ReferenceDate?: InputMaybe<PhotoNestedMetadataReferencedateCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceNumber" relation: "MetadataReferenceNumber.photo"'s inverse relation */
  Metadata_ReferenceNumber?: InputMaybe<PhotoNestedMetadataReferencenumberCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceService" relation: "MetadataReferenceService.photo"'s inverse relation */
  Metadata_ReferenceService?: InputMaybe<PhotoNestedMetadataReferenceserviceCreateInput>;
  /** Actions for the "Photo.Metadata_ReleaseDate" relation: "MetadataReleaseDate.photo"'s inverse relation */
  Metadata_ReleaseDate?: InputMaybe<PhotoNestedMetadataReleasedateCreateInput>;
  /** Actions for the "Photo.Metadata_ReleaseTime" relation: "MetadataReleaseTime.photo"'s inverse relation */
  Metadata_ReleaseTime?: InputMaybe<PhotoNestedMetadataReleasetimeCreateInput>;
  /** Actions for the "Photo.Metadata_RequiredPermission" relation: "MetadataRequiredPermission.photo"'s inverse relation */
  Metadata_RequiredPermission?: InputMaybe<PhotoNestedMetadataRequiredpermissionCreateInput>;
  /** Actions for the "Photo.Metadata_ResolutionKind" relation: "MetadataResolutionKind.photo"'s inverse relation */
  Metadata_ResolutionKind?: InputMaybe<PhotoNestedMetadataResolutionkindCreateInput>;
  /** Actions for the "Photo.Metadata_rights" relation: "MetadataRights.photo"'s inverse relation */
  Metadata_rights?: InputMaybe<PhotoNestedMetadataRightsCreateInput>;
  /** Actions for the "Photo.Metadata_Royaltyfree" relation: "MetadataRoyaltyfree.photo"'s inverse relation */
  Metadata_Royaltyfree?: InputMaybe<PhotoNestedMetadataRoyaltyfreeCreateInput>;
  /** Actions for the "Photo.Metadata_Scene" relation: "MetadataScene.photo"'s inverse relation */
  Metadata_Scene?: InputMaybe<PhotoNestedMetadataSceneCreateInput>;
  /** Actions for the "Photo.Metadata_Section" relation: "MetadataSection.photo"'s inverse relation */
  Metadata_Section?: InputMaybe<PhotoNestedMetadataSectionCreateInput>;
  /** Actions for the "Photo.Metadata_SentTo" relation: "MetadataSentTo.photo"'s inverse relation */
  Metadata_SentTo?: InputMaybe<PhotoNestedMetadataSenttoCreateInput>;
  /** Actions for the "Photo.Metadata_SerialNumber" relation: "MetadataSerialNumber.photo"'s inverse relation */
  Metadata_SerialNumber?: InputMaybe<PhotoNestedMetadataSerialnumberCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesDateTime" relation: "MetadataSeriesDateTime.photo"'s inverse relation */
  Metadata_SeriesDateTime?: InputMaybe<PhotoNestedMetadataSeriesdatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesDescription" relation: "MetadataSeriesDescription.photo"'s inverse relation */
  Metadata_SeriesDescription?: InputMaybe<PhotoNestedMetadataSeriesdescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesModality" relation: "MetadataSeriesModality.photo"'s inverse relation */
  Metadata_SeriesModality?: InputMaybe<PhotoNestedMetadataSeriesmodalityCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesNumber" relation: "MetadataSeriesNumber.photo"'s inverse relation */
  Metadata_SeriesNumber?: InputMaybe<PhotoNestedMetadataSeriesnumberCreateInput>;
  /** Actions for the "Photo.Metadata_ShortUniqueId" relation: "MetadataShortUniqueId.photo"'s inverse relation */
  Metadata_ShortUniqueId?: InputMaybe<PhotoNestedMetadataShortuniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Source" relation: "MetadataSource.photo"'s inverse relation */
  Metadata_Source?: InputMaybe<PhotoNestedMetadataSourceCreateInput>;
  /** Actions for the "Photo.Metadata_State" relation: "MetadataState.photo"'s inverse relation */
  Metadata_State?: InputMaybe<PhotoNestedMetadataStateCreateInput>;
  /** Actions for the "Photo.Metadata_StudyDateTime" relation: "MetadataStudyDateTime.photo"'s inverse relation */
  Metadata_StudyDateTime?: InputMaybe<PhotoNestedMetadataStudydatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_StudyDescription" relation: "MetadataStudyDescription.photo"'s inverse relation */
  Metadata_StudyDescription?: InputMaybe<PhotoNestedMetadataStudydescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_StudyID" relation: "MetadataStudyId.photo"'s inverse relation */
  Metadata_StudyID?: InputMaybe<PhotoNestedMetadataStudyidCreateInput>;
  /** Actions for the "Photo.Metadata_StudyPhysician" relation: "MetadataStudyPhysician.photo"'s inverse relation */
  Metadata_StudyPhysician?: InputMaybe<PhotoNestedMetadataStudyphysicianCreateInput>;
  /** Actions for the "Photo.Metadata_subject" relation: "MetadataSubject.photo"'s inverse relation */
  Metadata_subject?: InputMaybe<PhotoNestedMetadataSubjectCreateInput>;
  /** Actions for the "Photo.Metadata_SubjectCode" relation: "MetadataSubjectCode.photo"'s inverse relation */
  Metadata_SubjectCode?: InputMaybe<PhotoNestedMetadataSubjectcodeCreateInput>;
  /** Actions for the "Photo.Metadata_SubjectPrisma" relation: "MetadataSubjectPrisma.photo"'s inverse relation */
  Metadata_SubjectPrisma?: InputMaybe<PhotoNestedMetadataSubjectprismaCreateInput>;
  /** Actions for the "Photo.Metadata_SupplementalCategories" relation: "MetadataSupplementalCategories.photo"'s inverse relation */
  Metadata_SupplementalCategories?: InputMaybe<PhotoNestedMetadataSupplementalcategoriesCreateInput>;
  /** Actions for the "Photo.Metadata_TargetVersion" relation: "MetadataTargetVersion.photo"'s inverse relation */
  Metadata_TargetVersion?: InputMaybe<PhotoNestedMetadataTargetversionCreateInput>;
  /** Actions for the "Photo.Metadata_title" relation: "MetadataTitle.photo"'s inverse relation */
  Metadata_title?: InputMaybe<PhotoNestedMetadataTitleCreateInput>;
  /** Actions for the "Photo.Metadata_ToneCurve" relation: "MetadataToneCurve.photo"'s inverse relation */
  Metadata_ToneCurve?: InputMaybe<PhotoNestedMetadataTonecurveCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredBy" relation: "MetadataTransferredBy.photo"'s inverse relation */
  Metadata_TransferredBy?: InputMaybe<PhotoNestedMetadataTransferredbyCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredByEmail" relation: "MetadataTransferredByEmail.photo"'s inverse relation */
  Metadata_TransferredByEmail?: InputMaybe<PhotoNestedMetadataTransferredbyemailCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredByFullName" relation: "MetadataTransferredByFullName.photo"'s inverse relation */
  Metadata_TransferredByFullName?: InputMaybe<PhotoNestedMetadataTransferredbyfullnameCreateInput>;
  /** Actions for the "Photo.Metadata_TransmissionReference" relation: "MetadataTransmissionReference.photo"'s inverse relation */
  Metadata_TransmissionReference?: InputMaybe<PhotoNestedMetadataTransmissionreferenceCreateInput>;
  /** Actions for the "Photo.Metadata_Type" relation: "MetadataType.photo"'s inverse relation */
  Metadata_Type?: InputMaybe<PhotoNestedMetadataTypeCreateInput>;
  /** Actions for the "Photo.Metadata_Undersubject" relation: "MetadataUndersubject.photo"'s inverse relation */
  Metadata_Undersubject?: InputMaybe<PhotoNestedMetadataUndersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_UnderUnderSubject" relation: "MetadataUnderUnderSubject.photo"'s inverse relation */
  Metadata_UnderUnderSubject?: InputMaybe<PhotoNestedMetadataUnderundersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_UniqueId" relation: "MetadataUniqueId.photo"'s inverse relation */
  Metadata_UniqueId?: InputMaybe<PhotoNestedMetadataUniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Unit" relation: "MetadataUnit.photo"'s inverse relation */
  Metadata_Unit?: InputMaybe<PhotoNestedMetadataUnitCreateInput>;
  /** Actions for the "Photo.Metadata_UnitShortName" relation: "MetadataUnitShortName.photo"'s inverse relation */
  Metadata_UnitShortName?: InputMaybe<PhotoNestedMetadataUnitshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_UploadedBy" relation: "MetadataUploadedBy.photo"'s inverse relation */
  Metadata_UploadedBy?: InputMaybe<PhotoNestedMetadataUploadedbyCreateInput>;
  /** Actions for the "Photo.Metadata_UploadedByFullName" relation: "MetadataUploadedByFullName.photo"'s inverse relation */
  Metadata_UploadedByFullName?: InputMaybe<PhotoNestedMetadataUploadedbyfullnameCreateInput>;
  /** Actions for the "Photo.Metadata_UploadTime" relation: "MetadataUploadTime.photo"'s inverse relation */
  Metadata_UploadTime?: InputMaybe<PhotoNestedMetadataUploadtimeCreateInput>;
  /** Actions for the "Photo.Metadata_Urgency" relation: "MetadataUrgency.photo"'s inverse relation */
  Metadata_Urgency?: InputMaybe<PhotoNestedMetadataUrgencyCreateInput>;
  /** Actions for the "Photo.Metadata_UsageTerms" relation: "MetadataUsageTerms.photo"'s inverse relation */
  Metadata_UsageTerms?: InputMaybe<PhotoNestedMetadataUsagetermsCreateInput>;
  /** Actions for the "Photo.Metadata_UserComment" relation: "MetadataUserComment.photo"'s inverse relation */
  Metadata_UserComment?: InputMaybe<PhotoNestedMetadataUsercommentCreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined195" relation: "MetadataUserDefined195.photo"'s inverse relation */
  Metadata_UserDefined195?: InputMaybe<PhotoNestedMetadataUserdefined195CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined237" relation: "MetadataUserDefined237.photo"'s inverse relation */
  Metadata_UserDefined237?: InputMaybe<PhotoNestedMetadataUserdefined237CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined238" relation: "MetadataUserDefined238.photo"'s inverse relation */
  Metadata_UserDefined238?: InputMaybe<PhotoNestedMetadataUserdefined238CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined239" relation: "MetadataUserDefined239.photo"'s inverse relation */
  Metadata_UserDefined239?: InputMaybe<PhotoNestedMetadataUserdefined239CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined242" relation: "MetadataUserDefined242.photo"'s inverse relation */
  Metadata_UserDefined242?: InputMaybe<PhotoNestedMetadataUserdefined242CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined62" relation: "MetadataUserDefined62.photo"'s inverse relation */
  Metadata_UserDefined62?: InputMaybe<PhotoNestedMetadataUserdefined62CreateInput>;
  /** Actions for the "Photo.Metadata_Valid" relation: "MetadataValid.photo"'s inverse relation */
  Metadata_Valid?: InputMaybe<PhotoNestedMetadataValidCreateInput>;
  /** Actions for the "Photo.Metadata_Version" relation: "MetadataVersion.photo"'s inverse relation */
  Metadata_Version?: InputMaybe<PhotoNestedMetadataVersionCreateInput>;
  /** Actions for the "Photo.Metadata_WebStatement" relation: "MetadataWebStatement.photo"'s inverse relation */
  Metadata_WebStatement?: InputMaybe<PhotoNestedMetadataWebstatementCreateInput>;
  /** Actions for the "Photo.Metadata_WorkflowKind" relation: "MetadataWorkflowKind.photo"'s inverse relation */
  Metadata_WorkflowKind?: InputMaybe<PhotoNestedMetadataWorkflowkindCreateInput>;
  /** Actions for the "Photo.Metadata_XMPFileStamps" relation: "MetadataXmpFileStamps.photo"'s inverse relation */
  Metadata_XMPFileStamps?: InputMaybe<PhotoNestedMetadataXmpfilestampsCreateInput>;
  /** Actions for the "Photo.Metadata_XMPHistory" relation: "MetadataXmpHistory.photo"'s inverse relation */
  Metadata_XMPHistory?: InputMaybe<PhotoNestedMetadataXmphistoryCreateInput>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.photoMetadatas" relation: "PhotoMetadata.photo"'s inverse relation */
  photoMetadatas?: InputMaybe<PhotoNestedPhotoMetadatasCreateInput>;
  /** Actions for the "Photo.photoPublications" relation: "PhotoPublication.photo"'s inverse relation */
  photoPublications?: InputMaybe<PhotoNestedPhotoPublicationsCreateInput>;
  synced?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date of the photo's capture */
  takenAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type PhotoWithForcedCategoryUpdateInput = {
  /** Actions for the "Photo.agency" relation */
  agency?: InputMaybe<PhotoNestedAgencyUpdateInput>;
  /** Actions for the "Photo.archive" relation */
  archive?: InputMaybe<PhotoNestedArchiveUpdateInput>;
  /** asset content type */
  contentType?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.deliverySubject" relation */
  deliverySubject?: InputMaybe<PhotoNestedDeliverySubjectUpdateInput>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The date of the photo's importation */
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Actions for the "Photo.importQueue" relation: "ImportQueue.photo"'s inverse relation */
  importQueue?: InputMaybe<PhotoNestedImportQueueUpdateInput>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** Actions for the "Photo.Metadata_AccessRights" relation: "MetadataAccessRights.photo"'s inverse relation */
  Metadata_AccessRights?: InputMaybe<PhotoNestedMetadataAccessrightsUpdateInput>;
  /** Actions for the "Photo.Metadata_AddlModelInfo" relation: "MetadataAddlModelInfo.photo"'s inverse relation */
  Metadata_AddlModelInfo?: InputMaybe<PhotoNestedMetadataAddlmodelinfoUpdateInput>;
  /** Actions for the "Photo.Metadata_Archive_child" relation: "MetadataArchiveChild.photo"'s inverse relation */
  Metadata_Archive_child?: InputMaybe<PhotoNestedMetadataArchiveChildUpdateInput>;
  /** Actions for the "Photo.Metadata_Archive_parent" relation: "MetadataArchiveParent.photo"'s inverse relation */
  Metadata_Archive_parent?: InputMaybe<PhotoNestedMetadataArchiveParentUpdateInput>;
  /** Actions for the "Photo.Metadata_AssetState" relation: "MetadataAssetState.photo"'s inverse relation */
  Metadata_AssetState?: InputMaybe<PhotoNestedMetadataAssetstateUpdateInput>;
  /** Actions for the "Photo.Metadata_attributionName" relation: "MetadataAttributionName.photo"'s inverse relation */
  Metadata_attributionName?: InputMaybe<PhotoNestedMetadataAttributionnameUpdateInput>;
  /** Actions for the "Photo.Metadata_attributionURL" relation: "MetadataAttributionUrl.photo"'s inverse relation */
  Metadata_attributionURL?: InputMaybe<PhotoNestedMetadataAttributionurlUpdateInput>;
  /** Actions for the "Photo.Metadata_Audience" relation: "MetadataAudience.photo"'s inverse relation */
  Metadata_Audience?: InputMaybe<PhotoNestedMetadataAudienceUpdateInput>;
  /** Actions for the "Photo.Metadata_AuthorsPosition" relation: "MetadataAuthorsPosition.photo"'s inverse relation */
  Metadata_AuthorsPosition?: InputMaybe<PhotoNestedMetadataAuthorspositionUpdateInput>;
  /** Actions for the "Photo.Metadata_BackupName" relation: "MetadataBackupName.photo"'s inverse relation */
  Metadata_BackupName?: InputMaybe<PhotoNestedMetadataBackupnameUpdateInput>;
  /** Actions for the "Photo.Metadata_BitmapGrayscalePicture" relation: "MetadataBitmapGrayscalePicture.photo"'s inverse relation */
  Metadata_BitmapGrayscalePicture?: InputMaybe<PhotoNestedMetadataBitmapgrayscalepictureUpdateInput>;
  /** Actions for the "Photo.Metadata_Brand" relation: "MetadataBrand.photo"'s inverse relation */
  Metadata_Brand?: InputMaybe<PhotoNestedMetadataBrandUpdateInput>;
  /** Actions for the "Photo.Metadata_BrandPrisma" relation: "MetadataBrandPrisma.photo"'s inverse relation */
  Metadata_BrandPrisma?: InputMaybe<PhotoNestedMetadataBrandprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_BrandShortName" relation: "MetadataBrandShortName.photo"'s inverse relation */
  Metadata_BrandShortName?: InputMaybe<PhotoNestedMetadataBrandshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_CaptionWriter" relation: "MetadataCaptionWriter.photo"'s inverse relation */
  Metadata_CaptionWriter?: InputMaybe<PhotoNestedMetadataCaptionwriterUpdateInput>;
  /** Actions for the "Photo.Metadata_Category" relation: "MetadataCategory.photo"'s inverse relation */
  Metadata_Category?: InputMaybe<PhotoNestedMetadataCategoryUpdateInput>;
  /** Actions for the "Photo.Metadata_CategoryPrisma" relation: "MetadataCategoryPrisma.photo"'s inverse relation */
  Metadata_CategoryPrisma?: InputMaybe<PhotoNestedMetadataCategoryprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_Certificate" relation: "MetadataCertificate.photo"'s inverse relation */
  Metadata_Certificate?: InputMaybe<PhotoNestedMetadataCertificateUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCity" relation: "MetadataCiAdrCity.photo"'s inverse relation */
  Metadata_CiAdrCity?: InputMaybe<PhotoNestedMetadataCiadrcityUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCtry" relation: "MetadataCiAdrCtry.photo"'s inverse relation */
  Metadata_CiAdrCtry?: InputMaybe<PhotoNestedMetadataCiadrctryUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrExtadr" relation: "MetadataCiAdrExtadr.photo"'s inverse relation */
  Metadata_CiAdrExtadr?: InputMaybe<PhotoNestedMetadataCiadrextadrUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrPcode" relation: "MetadataCiAdrPcode.photo"'s inverse relation */
  Metadata_CiAdrPcode?: InputMaybe<PhotoNestedMetadataCiadrpcodeUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrRegion" relation: "MetadataCiAdrRegion.photo"'s inverse relation */
  Metadata_CiAdrRegion?: InputMaybe<PhotoNestedMetadataCiadrregionUpdateInput>;
  /** Actions for the "Photo.Metadata_CiEmailWork" relation: "MetadataCiEmailWork.photo"'s inverse relation */
  Metadata_CiEmailWork?: InputMaybe<PhotoNestedMetadataCiemailworkUpdateInput>;
  /** Actions for the "Photo.Metadata_CiTelWork" relation: "MetadataCiTelWork.photo"'s inverse relation */
  Metadata_CiTelWork?: InputMaybe<PhotoNestedMetadataCitelworkUpdateInput>;
  /** Actions for the "Photo.Metadata_City" relation: "MetadataCity.photo"'s inverse relation */
  Metadata_City?: InputMaybe<PhotoNestedMetadataCityUpdateInput>;
  /** Actions for the "Photo.Metadata_CiUrlWork" relation: "MetadataCiUrlWork.photo"'s inverse relation */
  Metadata_CiUrlWork?: InputMaybe<PhotoNestedMetadataCiurlworkUpdateInput>;
  /** Actions for the "Photo.Metadata_Classify" relation: "MetadataClassify.photo"'s inverse relation */
  Metadata_Classify?: InputMaybe<PhotoNestedMetadataClassifyUpdateInput>;
  /** Actions for the "Photo.Metadata_Company" relation: "MetadataCompany.photo"'s inverse relation */
  Metadata_Company?: InputMaybe<PhotoNestedMetadataCompanyUpdateInput>;
  /** Actions for the "Photo.Metadata_CompanyShortName" relation: "MetadataCompanyShortName.photo"'s inverse relation */
  Metadata_CompanyShortName?: InputMaybe<PhotoNestedMetadataCompanyshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_Confidentiality" relation: "MetadataConfidentiality.photo"'s inverse relation */
  Metadata_Confidentiality?: InputMaybe<PhotoNestedMetadataConfidentialityUpdateInput>;
  /** Actions for the "Photo.Metadata_ContainedIn" relation: "MetadataContainedIn.photo"'s inverse relation */
  Metadata_ContainedIn?: InputMaybe<PhotoNestedMetadataContainedinUpdateInput>;
  /** Actions for the "Photo.Metadata_ContentMediaKind" relation: "MetadataContentMediaKind.photo"'s inverse relation */
  Metadata_ContentMediaKind?: InputMaybe<PhotoNestedMetadataContentmediakindUpdateInput>;
  /** Actions for the "Photo.Metadata_ContentValue" relation: "MetadataContentValue.photo"'s inverse relation */
  Metadata_ContentValue?: InputMaybe<PhotoNestedMetadataContentvalueUpdateInput>;
  /** Actions for the "Photo.Metadata_CopyrightLayer" relation: "MetadataCopyrightLayer.photo"'s inverse relation */
  Metadata_CopyrightLayer?: InputMaybe<PhotoNestedMetadataCopyrightlayerUpdateInput>;
  /** Actions for the "Photo.Metadata_Country" relation: "MetadataCountry.photo"'s inverse relation */
  Metadata_Country?: InputMaybe<PhotoNestedMetadataCountryUpdateInput>;
  /** Actions for the "Photo.Metadata_CountryCode" relation: "MetadataCountryCode.photo"'s inverse relation */
  Metadata_CountryCode?: InputMaybe<PhotoNestedMetadataCountrycodeUpdateInput>;
  /** Actions for the "Photo.Metadata_CreatedTime" relation: "MetadataCreatedTime.photo"'s inverse relation */
  Metadata_CreatedTime?: InputMaybe<PhotoNestedMetadataCreatedtimeUpdateInput>;
  /** Actions for the "Photo.Metadata_creator" relation: "MetadataCreator.photo"'s inverse relation */
  Metadata_creator?: InputMaybe<PhotoNestedMetadataCreatorUpdateInput>;
  /** Actions for the "Photo.Metadata_CreatorTool" relation: "MetadataCreatorTool.photo"'s inverse relation */
  Metadata_CreatorTool?: InputMaybe<PhotoNestedMetadataCreatortoolUpdateInput>;
  /** Actions for the "Photo.Metadata_Credit" relation: "MetadataCredit.photo"'s inverse relation */
  Metadata_Credit?: InputMaybe<PhotoNestedMetadataCreditUpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField14" relation: "MetadataCustomField14.photo"'s inverse relation */
  Metadata_CustomField14?: InputMaybe<PhotoNestedMetadataCustomfield14UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField15" relation: "MetadataCustomField15.photo"'s inverse relation */
  Metadata_CustomField15?: InputMaybe<PhotoNestedMetadataCustomfield15UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField16" relation: "MetadataCustomField16.photo"'s inverse relation */
  Metadata_CustomField16?: InputMaybe<PhotoNestedMetadataCustomfield16UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField17" relation: "MetadataCustomField17.photo"'s inverse relation */
  Metadata_CustomField17?: InputMaybe<PhotoNestedMetadataCustomfield17UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField20" relation: "MetadataCustomField20.photo"'s inverse relation */
  Metadata_CustomField20?: InputMaybe<PhotoNestedMetadataCustomfield20UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField5" relation: "MetadataCustomField5.photo"'s inverse relation */
  Metadata_CustomField5?: InputMaybe<PhotoNestedMetadataCustomfield5UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField6" relation: "MetadataCustomField6.photo"'s inverse relation */
  Metadata_CustomField6?: InputMaybe<PhotoNestedMetadataCustomfield6UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField7" relation: "MetadataCustomField7.photo"'s inverse relation */
  Metadata_CustomField7?: InputMaybe<PhotoNestedMetadataCustomfield7UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField9" relation: "MetadataCustomField9.photo"'s inverse relation */
  Metadata_CustomField9?: InputMaybe<PhotoNestedMetadataCustomfield9UpdateInput>;
  /** Actions for the "Photo.Metadata_DateCreated" relation: "MetadataDateCreated.photo"'s inverse relation */
  Metadata_DateCreated?: InputMaybe<PhotoNestedMetadataDatecreatedUpdateInput>;
  /** Actions for the "Photo.Metadata_DateTimeDigitized" relation: "MetadataDateTimeDigitized.photo"'s inverse relation */
  Metadata_DateTimeDigitized?: InputMaybe<PhotoNestedMetadataDatetimedigitizedUpdateInput>;
  /** Actions for the "Photo.Metadata_DateTimeOriginal" relation: "MetadataDateTimeOriginal.photo"'s inverse relation */
  Metadata_DateTimeOriginal?: InputMaybe<PhotoNestedMetadataDatetimeoriginalUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryAccount" relation: "MetadataDeliveryAccount.photo"'s inverse relation */
  Metadata_DeliveryAccount?: InputMaybe<PhotoNestedMetadataDeliveryaccountUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCompany" relation: "MetadataDeliveryCompany.photo"'s inverse relation */
  Metadata_DeliveryCompany?: InputMaybe<PhotoNestedMetadataDeliverycompanyUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCopyright" relation: "MetadataDeliveryCopyright.photo"'s inverse relation */
  Metadata_DeliveryCopyright?: InputMaybe<PhotoNestedMetadataDeliverycopyrightUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateFolder" relation: "MetadataDeliveryDateFolder.photo"'s inverse relation */
  Metadata_DeliveryDateFolder?: InputMaybe<PhotoNestedMetadataDeliverydatefolderUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateTime" relation: "MetadataDeliveryDateTime.photo"'s inverse relation */
  Metadata_DeliveryDateTime?: InputMaybe<PhotoNestedMetadataDeliverydatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcCreator" relation: "MetadataDeliveryDcCreator.photo"'s inverse relation */
  Metadata_DeliveryDcCreator?: InputMaybe<PhotoNestedMetadataDeliverydccreatorUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcRights" relation: "MetadataDeliveryDcRights.photo"'s inverse relation */
  Metadata_DeliveryDcRights?: InputMaybe<PhotoNestedMetadataDeliverydcrightsUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFileType" relation: "MetadataDeliveryFileType.photo"'s inverse relation */
  Metadata_DeliveryFileType?: InputMaybe<PhotoNestedMetadataDeliveryfiletypeUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFolder" relation: "MetadataDeliveryFolder.photo"'s inverse relation */
  Metadata_DeliveryFolder?: InputMaybe<PhotoNestedMetadataDeliveryfolderUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryKind" relation: "MetadataDeliveryKind.photo"'s inverse relation */
  Metadata_DeliveryKind?: InputMaybe<PhotoNestedMetadataDeliverykindUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPath" relation: "MetadataDeliveryPath.photo"'s inverse relation */
  Metadata_DeliveryPath?: InputMaybe<PhotoNestedMetadataDeliverypathUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPersonShownintheImage" relation: "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation */
  Metadata_DeliveryPersonShownintheImage?: InputMaybe<PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopCredit" relation: "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopCredit?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopSource" relation: "MetadataDeliveryPhotoshopSource.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopSource?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryService" relation: "MetadataDeliveryService.photo"'s inverse relation */
  Metadata_DeliveryService?: InputMaybe<PhotoNestedMetadataDeliveryserviceUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliverySubject" relation: "MetadataDeliverySubject.photo"'s inverse relation */
  Metadata_DeliverySubject?: InputMaybe<PhotoNestedMetadataDeliverysubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryUnderSubject" relation: "MetadataDeliveryUnderSubject.photo"'s inverse relation */
  Metadata_DeliveryUnderSubject?: InputMaybe<PhotoNestedMetadataDeliveryundersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_Department" relation: "MetadataDepartment.photo"'s inverse relation */
  Metadata_Department?: InputMaybe<PhotoNestedMetadataDepartmentUpdateInput>;
  /** Actions for the "Photo.Metadata_description" relation: "MetadataDescription.photo"'s inverse relation */
  Metadata_description?: InputMaybe<PhotoNestedMetadataDescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_DigitalAssetURL" relation: "MetadataDigitalAssetUrl.photo"'s inverse relation */
  Metadata_DigitalAssetURL?: InputMaybe<PhotoNestedMetadataDigitalasseturlUpdateInput>;
  /** Actions for the "Photo.Metadata_Division" relation: "MetadataDivision.photo"'s inverse relation */
  Metadata_Division?: InputMaybe<PhotoNestedMetadataDivisionUpdateInput>;
  /** Actions for the "Photo.Metadata_DocumentText" relation: "MetadataDocumentText.photo"'s inverse relation */
  Metadata_DocumentText?: InputMaybe<PhotoNestedMetadataDocumenttextUpdateInput>;
  /** Actions for the "Photo.Metadata_Edition" relation: "MetadataEdition.photo"'s inverse relation */
  Metadata_Edition?: InputMaybe<PhotoNestedMetadataEditionUpdateInput>;
  /** Actions for the "Photo.Metadata_EditorialVersion" relation: "MetadataEditorialVersion.photo"'s inverse relation */
  Metadata_EditorialVersion?: InputMaybe<PhotoNestedMetadataEditorialversionUpdateInput>;
  /** Actions for the "Photo.Metadata_EditStatus" relation: "MetadataEditStatus.photo"'s inverse relation */
  Metadata_EditStatus?: InputMaybe<PhotoNestedMetadataEditstatusUpdateInput>;
  /** Actions for the "Photo.Metadata_EnvironnementPhoto" relation: "MetadataEnvironnementPhoto.photo"'s inverse relation */
  Metadata_EnvironnementPhoto?: InputMaybe<PhotoNestedMetadataEnvironnementphotoUpdateInput>;
  /** Actions for the "Photo.Metadata_EquipmentInstitution" relation: "MetadataEquipmentInstitution.photo"'s inverse relation */
  Metadata_EquipmentInstitution?: InputMaybe<PhotoNestedMetadataEquipmentinstitutionUpdateInput>;
  /** Actions for the "Photo.Metadata_EquipmentManufacturer" relation: "MetadataEquipmentManufacturer.photo"'s inverse relation */
  Metadata_EquipmentManufacturer?: InputMaybe<PhotoNestedMetadataEquipmentmanufacturerUpdateInput>;
  /** Actions for the "Photo.Metadata_Event" relation: "MetadataEvent.photo"'s inverse relation */
  Metadata_Event?: InputMaybe<PhotoNestedMetadataEventUpdateInput>;
  /** Actions for the "Photo.Metadata_Exif" relation: "MetadataExif.photo"'s inverse relation */
  Metadata_Exif?: InputMaybe<PhotoNestedMetadataExifUpdateInput>;
  /** Actions for the "Photo.Metadata_FabStorage" relation: "MetadataFabStorage.photo"'s inverse relation */
  Metadata_FabStorage?: InputMaybe<PhotoNestedMetadataFabstorageUpdateInput>;
  /** Actions for the "Photo.Metadata_FileCheckFor" relation: "MetadataFileCheckFor.photo"'s inverse relation */
  Metadata_FileCheckFor?: InputMaybe<PhotoNestedMetadataFilecheckforUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoBitsPerPixel" relation: "MetadataFileInfoBitsPerPixel.photo"'s inverse relation */
  Metadata_FileInfoBitsPerPixel?: InputMaybe<PhotoNestedMetadataFileinfobitsperpixelUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoFrames" relation: "MetadataFileInfoFrames.photo"'s inverse relation */
  Metadata_FileInfoFrames?: InputMaybe<PhotoNestedMetadataFileinfoframesUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoPhotometricInterpretation" relation: "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation */
  Metadata_FileInfoPhotometricInterpretation?: InputMaybe<PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoTransferSyntax" relation: "MetadataFileInfoTransferSyntax.photo"'s inverse relation */
  Metadata_FileInfoTransferSyntax?: InputMaybe<PhotoNestedMetadataFileinfotransfersyntaxUpdateInput>;
  /** Actions for the "Photo.Metadata_FileStage" relation: "MetadataFileStage.photo"'s inverse relation */
  Metadata_FileStage?: InputMaybe<PhotoNestedMetadataFilestageUpdateInput>;
  /** Actions for the "Photo.Metadata_FileStatus" relation: "MetadataFileStatus.photo"'s inverse relation */
  Metadata_FileStatus?: InputMaybe<PhotoNestedMetadataFilestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_FileType" relation: "MetadataFileType.photo"'s inverse relation */
  Metadata_FileType?: InputMaybe<PhotoNestedMetadataFiletypeUpdateInput>;
  /** Actions for the "Photo.Metadata_FixtureIdentifier" relation: "MetadataFixtureIdentifier.photo"'s inverse relation */
  Metadata_FixtureIdentifier?: InputMaybe<PhotoNestedMetadataFixtureidentifierUpdateInput>;
  /** Actions for the "Photo.Metadata_Format" relation: "MetadataFormat.photo"'s inverse relation */
  Metadata_Format?: InputMaybe<PhotoNestedMetadataFormatUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSLatitude" relation: "MetadataGpsLatitude.photo"'s inverse relation */
  Metadata_GPSLatitude?: InputMaybe<PhotoNestedMetadataGpslatitudeUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSLongitude" relation: "MetadataGpsLongitude.photo"'s inverse relation */
  Metadata_GPSLongitude?: InputMaybe<PhotoNestedMetadataGpslongitudeUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSProcessingMethod" relation: "MetadataGpsProcessingMethod.photo"'s inverse relation */
  Metadata_GPSProcessingMethod?: InputMaybe<PhotoNestedMetadataGpsprocessingmethodUpdateInput>;
  /** Actions for the "Photo.Metadata_Header" relation: "MetadataHeader.photo"'s inverse relation */
  Metadata_Header?: InputMaybe<PhotoNestedMetadataHeaderUpdateInput>;
  /** Actions for the "Photo.Metadata_HeaderPrisma" relation: "MetadataHeaderPrisma.photo"'s inverse relation */
  Metadata_HeaderPrisma?: InputMaybe<PhotoNestedMetadataHeaderprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_Headline" relation: "MetadataHeadline.photo"'s inverse relation */
  Metadata_Headline?: InputMaybe<PhotoNestedMetadataHeadlineUpdateInput>;
  /** Actions for the "Photo.Metadata_History" relation: "MetadataHistory.photo"'s inverse relation */
  Metadata_History?: InputMaybe<PhotoNestedMetadataHistoryUpdateInput>;
  /** Actions for the "Photo.Metadata_ICCProfile" relation: "MetadataIccProfile.photo"'s inverse relation */
  Metadata_ICCProfile?: InputMaybe<PhotoNestedMetadataIccprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_ID_subject" relation: "MetadataIdSubject.photo"'s inverse relation */
  Metadata_ID_subject?: InputMaybe<PhotoNestedMetadataIdSubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_IdAssignment" relation: "MetadataIdAssignment.photo"'s inverse relation */
  Metadata_IdAssignment?: InputMaybe<PhotoNestedMetadataIdassignmentUpdateInput>;
  /** Actions for the "Photo.Metadata_IdMediaContent" relation: "MetadataIdMediaContent.photo"'s inverse relation */
  Metadata_IdMediaContent?: InputMaybe<PhotoNestedMetadataIdmediacontentUpdateInput>;
  /** Actions for the "Photo.Metadata_IdPhotoPrisma" relation: "MetadataIdPhotoPrisma.photo"'s inverse relation */
  Metadata_IdPhotoPrisma?: InputMaybe<PhotoNestedMetadataIdphotoprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_ImageNotes" relation: "MetadataImageNotes.photo"'s inverse relation */
  Metadata_ImageNotes?: InputMaybe<PhotoNestedMetadataImagenotesUpdateInput>;
  /** Actions for the "Photo.Metadata_ImageUniqueID" relation: "MetadataImageUniqueId.photo"'s inverse relation */
  Metadata_ImageUniqueID?: InputMaybe<PhotoNestedMetadataImageuniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Instructions" relation: "MetadataInstructions.photo"'s inverse relation */
  Metadata_Instructions?: InputMaybe<PhotoNestedMetadataInstructionsUpdateInput>;
  /** Actions for the "Photo.Metadata_IntellectualGenre" relation: "MetadataIntellectualGenre.photo"'s inverse relation */
  Metadata_IntellectualGenre?: InputMaybe<PhotoNestedMetadataIntellectualgenreUpdateInput>;
  /** Actions for the "Photo.Metadata_ISOSpeedRatings" relation: "MetadataIsoSpeedRatings.photo"'s inverse relation */
  Metadata_ISOSpeedRatings?: InputMaybe<PhotoNestedMetadataIsospeedratingsUpdateInput>;
  /** Actions for the "Photo.Metadata_Issue" relation: "MetadataIssue.photo"'s inverse relation */
  Metadata_Issue?: InputMaybe<PhotoNestedMetadataIssueUpdateInput>;
  /** Actions for the "Photo.Metadata_JobId" relation: "MetadataJobId.photo"'s inverse relation */
  Metadata_JobId?: InputMaybe<PhotoNestedMetadataJobidUpdateInput>;
  /** Actions for the "Photo.Metadata_JobProcessing" relation: "MetadataJobProcessing.photo"'s inverse relation */
  Metadata_JobProcessing?: InputMaybe<PhotoNestedMetadataJobprocessingUpdateInput>;
  /** Actions for the "Photo.Metadata_LayoutPictureEditing" relation: "MetadataLayoutPictureEditing.photo"'s inverse relation */
  Metadata_LayoutPictureEditing?: InputMaybe<PhotoNestedMetadataLayoutpictureeditingUpdateInput>;
  /** Actions for the "Photo.Metadata_LayoutStorage" relation: "MetadataLayoutStorage.photo"'s inverse relation */
  Metadata_LayoutStorage?: InputMaybe<PhotoNestedMetadataLayoutstorageUpdateInput>;
  /** Actions for the "Photo.Metadata_license" relation: "MetadataLicense.photo"'s inverse relation */
  Metadata_license?: InputMaybe<PhotoNestedMetadataLicenseUpdateInput>;
  /** Actions for the "Photo.Metadata_Links" relation: "MetadataLinks.photo"'s inverse relation */
  Metadata_Links?: InputMaybe<PhotoNestedMetadataLinksUpdateInput>;
  /** Actions for the "Photo.Metadata_LocalCaption" relation: "MetadataLocalCaption.photo"'s inverse relation */
  Metadata_LocalCaption?: InputMaybe<PhotoNestedMetadataLocalcaptionUpdateInput>;
  /** Actions for the "Photo.Metadata_Location" relation: "MetadataLocation.photo"'s inverse relation */
  Metadata_Location?: InputMaybe<PhotoNestedMetadataLocationUpdateInput>;
  /** Actions for the "Photo.Metadata_Make" relation: "MetadataMake.photo"'s inverse relation */
  Metadata_Make?: InputMaybe<PhotoNestedMetadataMakeUpdateInput>;
  /** Actions for the "Photo.Metadata_Manifest" relation: "MetadataManifest.photo"'s inverse relation */
  Metadata_Manifest?: InputMaybe<PhotoNestedMetadataManifestUpdateInput>;
  /** Actions for the "Photo.Metadata_Marked" relation: "MetadataMarked.photo"'s inverse relation */
  Metadata_Marked?: InputMaybe<PhotoNestedMetadataMarkedUpdateInput>;
  /** Actions for the "Photo.Metadata_MasterDocumentId" relation: "MetadataMasterDocumentId.photo"'s inverse relation */
  Metadata_MasterDocumentId?: InputMaybe<PhotoNestedMetadataMasterdocumentidUpdateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailHeight" relation: "MetadataMaxAvailHeight.photo"'s inverse relation */
  Metadata_MaxAvailHeight?: InputMaybe<PhotoNestedMetadataMaxavailheightUpdateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailWidth" relation: "MetadataMaxAvailWidth.photo"'s inverse relation */
  Metadata_MaxAvailWidth?: InputMaybe<PhotoNestedMetadataMaxavailwidthUpdateInput>;
  /** Actions for the "Photo.Metadata_MinorModelAgeDisclosure" relation: "MetadataMinorModelAgeDisclosure.photo"'s inverse relation */
  Metadata_MinorModelAgeDisclosure?: InputMaybe<PhotoNestedMetadataMinormodelagedisclosureUpdateInput>;
  /** Actions for the "Photo.Metadata_Model" relation: "MetadataModel.photo"'s inverse relation */
  Metadata_Model?: InputMaybe<PhotoNestedMetadataModelUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelAge" relation: "MetadataModelAge.photo"'s inverse relation */
  Metadata_ModelAge?: InputMaybe<PhotoNestedMetadataModelageUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseID" relation: "MetadataModelReleaseId.photo"'s inverse relation */
  Metadata_ModelReleaseID?: InputMaybe<PhotoNestedMetadataModelreleaseidUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseStatus" relation: "MetadataModelReleaseStatus.photo"'s inverse relation */
  Metadata_ModelReleaseStatus?: InputMaybe<PhotoNestedMetadataModelreleasestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_morePermissions" relation: "MetadataMorePermissions.photo"'s inverse relation */
  Metadata_morePermissions?: InputMaybe<PhotoNestedMetadataMorepermissionsUpdateInput>;
  /** Actions for the "Photo.Metadata_ObjectCycle" relation: "MetadataObjectCycle.photo"'s inverse relation */
  Metadata_ObjectCycle?: InputMaybe<PhotoNestedMetadataObjectcycleUpdateInput>;
  /** Actions for the "Photo.Metadata_OrganisationInImageName" relation: "MetadataOrganisationInImageName.photo"'s inverse relation */
  Metadata_OrganisationInImageName?: InputMaybe<PhotoNestedMetadataOrganisationinimagenameUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalColor" relation: "MetadataOriginalColor.photo"'s inverse relation */
  Metadata_OriginalColor?: InputMaybe<PhotoNestedMetadataOriginalcolorUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalICCProfile" relation: "MetadataOriginalIccProfile.photo"'s inverse relation */
  Metadata_OriginalICCProfile?: InputMaybe<PhotoNestedMetadataOriginaliccprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalName" relation: "MetadataOriginalName.photo"'s inverse relation */
  Metadata_OriginalName?: InputMaybe<PhotoNestedMetadataOriginalnameUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalWeigth" relation: "MetadataOriginalWeigth.photo"'s inverse relation */
  Metadata_OriginalWeigth?: InputMaybe<PhotoNestedMetadataOriginalweigthUpdateInput>;
  /** Actions for the "Photo.Metadata_Owner" relation: "MetadataOwner.photo"'s inverse relation */
  Metadata_Owner?: InputMaybe<PhotoNestedMetadataOwnerUpdateInput>;
  /** Actions for the "Photo.Metadata_OwnerId" relation: "MetadataOwnerId.photo"'s inverse relation */
  Metadata_OwnerId?: InputMaybe<PhotoNestedMetadataOwneridUpdateInput>;
  /** Actions for the "Photo.Metadata_Page" relation: "MetadataPage.photo"'s inverse relation */
  Metadata_Page?: InputMaybe<PhotoNestedMetadataPageUpdateInput>;
  /** Actions for the "Photo.Metadata_PagePrisma" relation: "MetadataPagePrisma.photo"'s inverse relation */
  Metadata_PagePrisma?: InputMaybe<PhotoNestedMetadataPageprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_PaintBasedCorrections" relation: "MetadataPaintBasedCorrections.photo"'s inverse relation */
  Metadata_PaintBasedCorrections?: InputMaybe<PhotoNestedMetadataPaintbasedcorrectionsUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientDOB" relation: "MetadataPatientDob.photo"'s inverse relation */
  Metadata_PatientDOB?: InputMaybe<PhotoNestedMetadataPatientdobUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientID" relation: "MetadataPatientId.photo"'s inverse relation */
  Metadata_PatientID?: InputMaybe<PhotoNestedMetadataPatientidUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientName" relation: "MetadataPatientName.photo"'s inverse relation */
  Metadata_PatientName?: InputMaybe<PhotoNestedMetadataPatientnameUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientSex" relation: "MetadataPatientSex.photo"'s inverse relation */
  Metadata_PatientSex?: InputMaybe<PhotoNestedMetadataPatientsexUpdateInput>;
  /** Actions for the "Photo.Metadata_PersonInImage" relation: "MetadataPersonInImage.photo"'s inverse relation */
  Metadata_PersonInImage?: InputMaybe<PhotoNestedMetadataPersoninimageUpdateInput>;
  /** Actions for the "Photo.Metadata_PhotoStorage" relation: "MetadataPhotoStorage.photo"'s inverse relation */
  Metadata_PhotoStorage?: InputMaybe<PhotoNestedMetadataPhotostorageUpdateInput>;
  /** Actions for the "Photo.Metadata_PrepressPictureEditing" relation: "MetadataPrepressPictureEditing.photo"'s inverse relation */
  Metadata_PrepressPictureEditing?: InputMaybe<PhotoNestedMetadataPrepresspictureeditingUpdateInput>;
  /** Actions for the "Photo.Metadata_PriceLevel" relation: "MetadataPriceLevel.photo"'s inverse relation */
  Metadata_PriceLevel?: InputMaybe<PhotoNestedMetadataPricelevelUpdateInput>;
  /** Actions for the "Photo.Metadata_PrintingProfile" relation: "MetadataPrintingProfile.photo"'s inverse relation */
  Metadata_PrintingProfile?: InputMaybe<PhotoNestedMetadataPrintingprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_PrismaProduction" relation: "MetadataPrismaProduction.photo"'s inverse relation */
  Metadata_PrismaProduction?: InputMaybe<PhotoNestedMetadataPrismaproductionUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessHistory" relation: "MetadataProcessHistory.photo"'s inverse relation */
  Metadata_ProcessHistory?: InputMaybe<PhotoNestedMetadataProcesshistoryUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessParameter" relation: "MetadataProcessParameter.photo"'s inverse relation */
  Metadata_ProcessParameter?: InputMaybe<PhotoNestedMetadataProcessparameterUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessStatus" relation: "MetadataProcessStatus.photo"'s inverse relation */
  Metadata_ProcessStatus?: InputMaybe<PhotoNestedMetadataProcessstatusUpdateInput>;
  /** Actions for the "Photo.Metadata_Product" relation: "MetadataProduct.photo"'s inverse relation */
  Metadata_Product?: InputMaybe<PhotoNestedMetadataProductUpdateInput>;
  /** Actions for the "Photo.Metadata_Products" relation: "MetadataProducts.photo"'s inverse relation */
  Metadata_Products?: InputMaybe<PhotoNestedMetadataProductsUpdateInput>;
  /** Actions for the "Photo.Metadata_ProductShortName" relation: "MetadataProductShortName.photo"'s inverse relation */
  Metadata_ProductShortName?: InputMaybe<PhotoNestedMetadataProductshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_Produits" relation: "MetadataProduits.photo"'s inverse relation */
  Metadata_Produits?: InputMaybe<PhotoNestedMetadataProduitsUpdateInput>;
  /** Actions for the "Photo.Metadata_ProgramVersion" relation: "MetadataProgramVersion.photo"'s inverse relation */
  Metadata_ProgramVersion?: InputMaybe<PhotoNestedMetadataProgramversionUpdateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseID" relation: "MetadataPropertyReleaseId.photo"'s inverse relation */
  Metadata_PropertyReleaseID?: InputMaybe<PhotoNestedMetadataPropertyreleaseidUpdateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseStatus" relation: "MetadataPropertyReleaseStatus.photo"'s inverse relation */
  Metadata_PropertyReleaseStatus?: InputMaybe<PhotoNestedMetadataPropertyreleasestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_Publisher" relation: "MetadataPublisher.photo"'s inverse relation */
  Metadata_Publisher?: InputMaybe<PhotoNestedMetadataPublisherUpdateInput>;
  /** Actions for the "Photo.Metadata_publishingIssue" relation: "MetadataPublishingIssue.photo"'s inverse relation */
  Metadata_publishingIssue?: InputMaybe<PhotoNestedMetadataPublishingissueUpdateInput>;
  /** Actions for the "Photo.Metadata_publishingSubject" relation: "MetadataPublishingSubject.photo"'s inverse relation */
  Metadata_publishingSubject?: InputMaybe<PhotoNestedMetadataPublishingsubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaBy" relation: "MetadataQualifiedUsePrismaBy.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaBy?: InputMaybe<PhotoNestedMetadataQualifieduseprismabyUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDate" relation: "MetadataQualifiedUsePrismaDate.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDate?: InputMaybe<PhotoNestedMetadataQualifieduseprismadateUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDuration" relation: "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDuration?: InputMaybe<PhotoNestedMetadataQualifieduseprismadurationUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaSupport" relation: "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaSupport?: InputMaybe<PhotoNestedMetadataQualifieduseprismasupportUpdateInput>;
  /** Actions for the "Photo.Metadata_Rating" relation: "MetadataRating.photo"'s inverse relation */
  Metadata_Rating?: InputMaybe<PhotoNestedMetadataRatingUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceDate" relation: "MetadataReferenceDate.photo"'s inverse relation */
  Metadata_ReferenceDate?: InputMaybe<PhotoNestedMetadataReferencedateUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceNumber" relation: "MetadataReferenceNumber.photo"'s inverse relation */
  Metadata_ReferenceNumber?: InputMaybe<PhotoNestedMetadataReferencenumberUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceService" relation: "MetadataReferenceService.photo"'s inverse relation */
  Metadata_ReferenceService?: InputMaybe<PhotoNestedMetadataReferenceserviceUpdateInput>;
  /** Actions for the "Photo.Metadata_ReleaseDate" relation: "MetadataReleaseDate.photo"'s inverse relation */
  Metadata_ReleaseDate?: InputMaybe<PhotoNestedMetadataReleasedateUpdateInput>;
  /** Actions for the "Photo.Metadata_ReleaseTime" relation: "MetadataReleaseTime.photo"'s inverse relation */
  Metadata_ReleaseTime?: InputMaybe<PhotoNestedMetadataReleasetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_RequiredPermission" relation: "MetadataRequiredPermission.photo"'s inverse relation */
  Metadata_RequiredPermission?: InputMaybe<PhotoNestedMetadataRequiredpermissionUpdateInput>;
  /** Actions for the "Photo.Metadata_ResolutionKind" relation: "MetadataResolutionKind.photo"'s inverse relation */
  Metadata_ResolutionKind?: InputMaybe<PhotoNestedMetadataResolutionkindUpdateInput>;
  /** Actions for the "Photo.Metadata_rights" relation: "MetadataRights.photo"'s inverse relation */
  Metadata_rights?: InputMaybe<PhotoNestedMetadataRightsUpdateInput>;
  /** Actions for the "Photo.Metadata_Royaltyfree" relation: "MetadataRoyaltyfree.photo"'s inverse relation */
  Metadata_Royaltyfree?: InputMaybe<PhotoNestedMetadataRoyaltyfreeUpdateInput>;
  /** Actions for the "Photo.Metadata_Scene" relation: "MetadataScene.photo"'s inverse relation */
  Metadata_Scene?: InputMaybe<PhotoNestedMetadataSceneUpdateInput>;
  /** Actions for the "Photo.Metadata_Section" relation: "MetadataSection.photo"'s inverse relation */
  Metadata_Section?: InputMaybe<PhotoNestedMetadataSectionUpdateInput>;
  /** Actions for the "Photo.Metadata_SentTo" relation: "MetadataSentTo.photo"'s inverse relation */
  Metadata_SentTo?: InputMaybe<PhotoNestedMetadataSenttoUpdateInput>;
  /** Actions for the "Photo.Metadata_SerialNumber" relation: "MetadataSerialNumber.photo"'s inverse relation */
  Metadata_SerialNumber?: InputMaybe<PhotoNestedMetadataSerialnumberUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesDateTime" relation: "MetadataSeriesDateTime.photo"'s inverse relation */
  Metadata_SeriesDateTime?: InputMaybe<PhotoNestedMetadataSeriesdatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesDescription" relation: "MetadataSeriesDescription.photo"'s inverse relation */
  Metadata_SeriesDescription?: InputMaybe<PhotoNestedMetadataSeriesdescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesModality" relation: "MetadataSeriesModality.photo"'s inverse relation */
  Metadata_SeriesModality?: InputMaybe<PhotoNestedMetadataSeriesmodalityUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesNumber" relation: "MetadataSeriesNumber.photo"'s inverse relation */
  Metadata_SeriesNumber?: InputMaybe<PhotoNestedMetadataSeriesnumberUpdateInput>;
  /** Actions for the "Photo.Metadata_ShortUniqueId" relation: "MetadataShortUniqueId.photo"'s inverse relation */
  Metadata_ShortUniqueId?: InputMaybe<PhotoNestedMetadataShortuniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Source" relation: "MetadataSource.photo"'s inverse relation */
  Metadata_Source?: InputMaybe<PhotoNestedMetadataSourceUpdateInput>;
  /** Actions for the "Photo.Metadata_State" relation: "MetadataState.photo"'s inverse relation */
  Metadata_State?: InputMaybe<PhotoNestedMetadataStateUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyDateTime" relation: "MetadataStudyDateTime.photo"'s inverse relation */
  Metadata_StudyDateTime?: InputMaybe<PhotoNestedMetadataStudydatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyDescription" relation: "MetadataStudyDescription.photo"'s inverse relation */
  Metadata_StudyDescription?: InputMaybe<PhotoNestedMetadataStudydescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyID" relation: "MetadataStudyId.photo"'s inverse relation */
  Metadata_StudyID?: InputMaybe<PhotoNestedMetadataStudyidUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyPhysician" relation: "MetadataStudyPhysician.photo"'s inverse relation */
  Metadata_StudyPhysician?: InputMaybe<PhotoNestedMetadataStudyphysicianUpdateInput>;
  /** Actions for the "Photo.Metadata_subject" relation: "MetadataSubject.photo"'s inverse relation */
  Metadata_subject?: InputMaybe<PhotoNestedMetadataSubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_SubjectCode" relation: "MetadataSubjectCode.photo"'s inverse relation */
  Metadata_SubjectCode?: InputMaybe<PhotoNestedMetadataSubjectcodeUpdateInput>;
  /** Actions for the "Photo.Metadata_SubjectPrisma" relation: "MetadataSubjectPrisma.photo"'s inverse relation */
  Metadata_SubjectPrisma?: InputMaybe<PhotoNestedMetadataSubjectprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_SupplementalCategories" relation: "MetadataSupplementalCategories.photo"'s inverse relation */
  Metadata_SupplementalCategories?: InputMaybe<PhotoNestedMetadataSupplementalcategoriesUpdateInput>;
  /** Actions for the "Photo.Metadata_TargetVersion" relation: "MetadataTargetVersion.photo"'s inverse relation */
  Metadata_TargetVersion?: InputMaybe<PhotoNestedMetadataTargetversionUpdateInput>;
  /** Actions for the "Photo.Metadata_title" relation: "MetadataTitle.photo"'s inverse relation */
  Metadata_title?: InputMaybe<PhotoNestedMetadataTitleUpdateInput>;
  /** Actions for the "Photo.Metadata_ToneCurve" relation: "MetadataToneCurve.photo"'s inverse relation */
  Metadata_ToneCurve?: InputMaybe<PhotoNestedMetadataTonecurveUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredBy" relation: "MetadataTransferredBy.photo"'s inverse relation */
  Metadata_TransferredBy?: InputMaybe<PhotoNestedMetadataTransferredbyUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredByEmail" relation: "MetadataTransferredByEmail.photo"'s inverse relation */
  Metadata_TransferredByEmail?: InputMaybe<PhotoNestedMetadataTransferredbyemailUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredByFullName" relation: "MetadataTransferredByFullName.photo"'s inverse relation */
  Metadata_TransferredByFullName?: InputMaybe<PhotoNestedMetadataTransferredbyfullnameUpdateInput>;
  /** Actions for the "Photo.Metadata_TransmissionReference" relation: "MetadataTransmissionReference.photo"'s inverse relation */
  Metadata_TransmissionReference?: InputMaybe<PhotoNestedMetadataTransmissionreferenceUpdateInput>;
  /** Actions for the "Photo.Metadata_Type" relation: "MetadataType.photo"'s inverse relation */
  Metadata_Type?: InputMaybe<PhotoNestedMetadataTypeUpdateInput>;
  /** Actions for the "Photo.Metadata_Undersubject" relation: "MetadataUndersubject.photo"'s inverse relation */
  Metadata_Undersubject?: InputMaybe<PhotoNestedMetadataUndersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_UnderUnderSubject" relation: "MetadataUnderUnderSubject.photo"'s inverse relation */
  Metadata_UnderUnderSubject?: InputMaybe<PhotoNestedMetadataUnderundersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_UniqueId" relation: "MetadataUniqueId.photo"'s inverse relation */
  Metadata_UniqueId?: InputMaybe<PhotoNestedMetadataUniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Unit" relation: "MetadataUnit.photo"'s inverse relation */
  Metadata_Unit?: InputMaybe<PhotoNestedMetadataUnitUpdateInput>;
  /** Actions for the "Photo.Metadata_UnitShortName" relation: "MetadataUnitShortName.photo"'s inverse relation */
  Metadata_UnitShortName?: InputMaybe<PhotoNestedMetadataUnitshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadedBy" relation: "MetadataUploadedBy.photo"'s inverse relation */
  Metadata_UploadedBy?: InputMaybe<PhotoNestedMetadataUploadedbyUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadedByFullName" relation: "MetadataUploadedByFullName.photo"'s inverse relation */
  Metadata_UploadedByFullName?: InputMaybe<PhotoNestedMetadataUploadedbyfullnameUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadTime" relation: "MetadataUploadTime.photo"'s inverse relation */
  Metadata_UploadTime?: InputMaybe<PhotoNestedMetadataUploadtimeUpdateInput>;
  /** Actions for the "Photo.Metadata_Urgency" relation: "MetadataUrgency.photo"'s inverse relation */
  Metadata_Urgency?: InputMaybe<PhotoNestedMetadataUrgencyUpdateInput>;
  /** Actions for the "Photo.Metadata_UsageTerms" relation: "MetadataUsageTerms.photo"'s inverse relation */
  Metadata_UsageTerms?: InputMaybe<PhotoNestedMetadataUsagetermsUpdateInput>;
  /** Actions for the "Photo.Metadata_UserComment" relation: "MetadataUserComment.photo"'s inverse relation */
  Metadata_UserComment?: InputMaybe<PhotoNestedMetadataUsercommentUpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined195" relation: "MetadataUserDefined195.photo"'s inverse relation */
  Metadata_UserDefined195?: InputMaybe<PhotoNestedMetadataUserdefined195UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined237" relation: "MetadataUserDefined237.photo"'s inverse relation */
  Metadata_UserDefined237?: InputMaybe<PhotoNestedMetadataUserdefined237UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined238" relation: "MetadataUserDefined238.photo"'s inverse relation */
  Metadata_UserDefined238?: InputMaybe<PhotoNestedMetadataUserdefined238UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined239" relation: "MetadataUserDefined239.photo"'s inverse relation */
  Metadata_UserDefined239?: InputMaybe<PhotoNestedMetadataUserdefined239UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined242" relation: "MetadataUserDefined242.photo"'s inverse relation */
  Metadata_UserDefined242?: InputMaybe<PhotoNestedMetadataUserdefined242UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined62" relation: "MetadataUserDefined62.photo"'s inverse relation */
  Metadata_UserDefined62?: InputMaybe<PhotoNestedMetadataUserdefined62UpdateInput>;
  /** Actions for the "Photo.Metadata_Valid" relation: "MetadataValid.photo"'s inverse relation */
  Metadata_Valid?: InputMaybe<PhotoNestedMetadataValidUpdateInput>;
  /** Actions for the "Photo.Metadata_Version" relation: "MetadataVersion.photo"'s inverse relation */
  Metadata_Version?: InputMaybe<PhotoNestedMetadataVersionUpdateInput>;
  /** Actions for the "Photo.Metadata_WebStatement" relation: "MetadataWebStatement.photo"'s inverse relation */
  Metadata_WebStatement?: InputMaybe<PhotoNestedMetadataWebstatementUpdateInput>;
  /** Actions for the "Photo.Metadata_WorkflowKind" relation: "MetadataWorkflowKind.photo"'s inverse relation */
  Metadata_WorkflowKind?: InputMaybe<PhotoNestedMetadataWorkflowkindUpdateInput>;
  /** Actions for the "Photo.Metadata_XMPFileStamps" relation: "MetadataXmpFileStamps.photo"'s inverse relation */
  Metadata_XMPFileStamps?: InputMaybe<PhotoNestedMetadataXmpfilestampsUpdateInput>;
  /** Actions for the "Photo.Metadata_XMPHistory" relation: "MetadataXmpHistory.photo"'s inverse relation */
  Metadata_XMPHistory?: InputMaybe<PhotoNestedMetadataXmphistoryUpdateInput>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.photoMetadatas" relation: "PhotoMetadata.photo"'s inverse relation */
  photoMetadatas?: InputMaybe<PhotoNestedPhotoMetadatasUpdateInput>;
  /** Actions for the "Photo.photoPublications" relation: "PhotoPublication.photo"'s inverse relation */
  photoPublications?: InputMaybe<PhotoNestedPhotoPublicationsUpdateInput>;
  synced?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date of the photo's capture */
  takenAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type PhotoWithForcedDeliverySubjectCreateInput = {
  /** Actions for the "Photo.agency" relation */
  agency?: InputMaybe<PhotoNestedAgencyCreateInput>;
  /** Actions for the "Photo.archive" relation */
  archive: PhotoNestedArchiveCreateInput;
  /** Actions for the "Photo.category" relation */
  category?: InputMaybe<PhotoNestedCategoryCreateInput>;
  /** asset content type */
  contentType?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The public ID, an UUID v4 */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The date of the photo's importation */
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Actions for the "Photo.importQueue" relation: "ImportQueue.photo"'s inverse relation */
  importQueue?: InputMaybe<PhotoNestedImportQueueCreateInput>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** Actions for the "Photo.Metadata_AccessRights" relation: "MetadataAccessRights.photo"'s inverse relation */
  Metadata_AccessRights?: InputMaybe<PhotoNestedMetadataAccessrightsCreateInput>;
  /** Actions for the "Photo.Metadata_AddlModelInfo" relation: "MetadataAddlModelInfo.photo"'s inverse relation */
  Metadata_AddlModelInfo?: InputMaybe<PhotoNestedMetadataAddlmodelinfoCreateInput>;
  /** Actions for the "Photo.Metadata_Archive_child" relation: "MetadataArchiveChild.photo"'s inverse relation */
  Metadata_Archive_child?: InputMaybe<PhotoNestedMetadataArchiveChildCreateInput>;
  /** Actions for the "Photo.Metadata_Archive_parent" relation: "MetadataArchiveParent.photo"'s inverse relation */
  Metadata_Archive_parent?: InputMaybe<PhotoNestedMetadataArchiveParentCreateInput>;
  /** Actions for the "Photo.Metadata_AssetState" relation: "MetadataAssetState.photo"'s inverse relation */
  Metadata_AssetState?: InputMaybe<PhotoNestedMetadataAssetstateCreateInput>;
  /** Actions for the "Photo.Metadata_attributionName" relation: "MetadataAttributionName.photo"'s inverse relation */
  Metadata_attributionName?: InputMaybe<PhotoNestedMetadataAttributionnameCreateInput>;
  /** Actions for the "Photo.Metadata_attributionURL" relation: "MetadataAttributionUrl.photo"'s inverse relation */
  Metadata_attributionURL?: InputMaybe<PhotoNestedMetadataAttributionurlCreateInput>;
  /** Actions for the "Photo.Metadata_Audience" relation: "MetadataAudience.photo"'s inverse relation */
  Metadata_Audience?: InputMaybe<PhotoNestedMetadataAudienceCreateInput>;
  /** Actions for the "Photo.Metadata_AuthorsPosition" relation: "MetadataAuthorsPosition.photo"'s inverse relation */
  Metadata_AuthorsPosition?: InputMaybe<PhotoNestedMetadataAuthorspositionCreateInput>;
  /** Actions for the "Photo.Metadata_BackupName" relation: "MetadataBackupName.photo"'s inverse relation */
  Metadata_BackupName?: InputMaybe<PhotoNestedMetadataBackupnameCreateInput>;
  /** Actions for the "Photo.Metadata_BitmapGrayscalePicture" relation: "MetadataBitmapGrayscalePicture.photo"'s inverse relation */
  Metadata_BitmapGrayscalePicture?: InputMaybe<PhotoNestedMetadataBitmapgrayscalepictureCreateInput>;
  /** Actions for the "Photo.Metadata_Brand" relation: "MetadataBrand.photo"'s inverse relation */
  Metadata_Brand?: InputMaybe<PhotoNestedMetadataBrandCreateInput>;
  /** Actions for the "Photo.Metadata_BrandPrisma" relation: "MetadataBrandPrisma.photo"'s inverse relation */
  Metadata_BrandPrisma?: InputMaybe<PhotoNestedMetadataBrandprismaCreateInput>;
  /** Actions for the "Photo.Metadata_BrandShortName" relation: "MetadataBrandShortName.photo"'s inverse relation */
  Metadata_BrandShortName?: InputMaybe<PhotoNestedMetadataBrandshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_CaptionWriter" relation: "MetadataCaptionWriter.photo"'s inverse relation */
  Metadata_CaptionWriter?: InputMaybe<PhotoNestedMetadataCaptionwriterCreateInput>;
  /** Actions for the "Photo.Metadata_Category" relation: "MetadataCategory.photo"'s inverse relation */
  Metadata_Category?: InputMaybe<PhotoNestedMetadataCategoryCreateInput>;
  /** Actions for the "Photo.Metadata_CategoryPrisma" relation: "MetadataCategoryPrisma.photo"'s inverse relation */
  Metadata_CategoryPrisma?: InputMaybe<PhotoNestedMetadataCategoryprismaCreateInput>;
  /** Actions for the "Photo.Metadata_Certificate" relation: "MetadataCertificate.photo"'s inverse relation */
  Metadata_Certificate?: InputMaybe<PhotoNestedMetadataCertificateCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCity" relation: "MetadataCiAdrCity.photo"'s inverse relation */
  Metadata_CiAdrCity?: InputMaybe<PhotoNestedMetadataCiadrcityCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCtry" relation: "MetadataCiAdrCtry.photo"'s inverse relation */
  Metadata_CiAdrCtry?: InputMaybe<PhotoNestedMetadataCiadrctryCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrExtadr" relation: "MetadataCiAdrExtadr.photo"'s inverse relation */
  Metadata_CiAdrExtadr?: InputMaybe<PhotoNestedMetadataCiadrextadrCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrPcode" relation: "MetadataCiAdrPcode.photo"'s inverse relation */
  Metadata_CiAdrPcode?: InputMaybe<PhotoNestedMetadataCiadrpcodeCreateInput>;
  /** Actions for the "Photo.Metadata_CiAdrRegion" relation: "MetadataCiAdrRegion.photo"'s inverse relation */
  Metadata_CiAdrRegion?: InputMaybe<PhotoNestedMetadataCiadrregionCreateInput>;
  /** Actions for the "Photo.Metadata_CiEmailWork" relation: "MetadataCiEmailWork.photo"'s inverse relation */
  Metadata_CiEmailWork?: InputMaybe<PhotoNestedMetadataCiemailworkCreateInput>;
  /** Actions for the "Photo.Metadata_CiTelWork" relation: "MetadataCiTelWork.photo"'s inverse relation */
  Metadata_CiTelWork?: InputMaybe<PhotoNestedMetadataCitelworkCreateInput>;
  /** Actions for the "Photo.Metadata_City" relation: "MetadataCity.photo"'s inverse relation */
  Metadata_City?: InputMaybe<PhotoNestedMetadataCityCreateInput>;
  /** Actions for the "Photo.Metadata_CiUrlWork" relation: "MetadataCiUrlWork.photo"'s inverse relation */
  Metadata_CiUrlWork?: InputMaybe<PhotoNestedMetadataCiurlworkCreateInput>;
  /** Actions for the "Photo.Metadata_Classify" relation: "MetadataClassify.photo"'s inverse relation */
  Metadata_Classify?: InputMaybe<PhotoNestedMetadataClassifyCreateInput>;
  /** Actions for the "Photo.Metadata_Company" relation: "MetadataCompany.photo"'s inverse relation */
  Metadata_Company?: InputMaybe<PhotoNestedMetadataCompanyCreateInput>;
  /** Actions for the "Photo.Metadata_CompanyShortName" relation: "MetadataCompanyShortName.photo"'s inverse relation */
  Metadata_CompanyShortName?: InputMaybe<PhotoNestedMetadataCompanyshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_Confidentiality" relation: "MetadataConfidentiality.photo"'s inverse relation */
  Metadata_Confidentiality?: InputMaybe<PhotoNestedMetadataConfidentialityCreateInput>;
  /** Actions for the "Photo.Metadata_ContainedIn" relation: "MetadataContainedIn.photo"'s inverse relation */
  Metadata_ContainedIn?: InputMaybe<PhotoNestedMetadataContainedinCreateInput>;
  /** Actions for the "Photo.Metadata_ContentMediaKind" relation: "MetadataContentMediaKind.photo"'s inverse relation */
  Metadata_ContentMediaKind?: InputMaybe<PhotoNestedMetadataContentmediakindCreateInput>;
  /** Actions for the "Photo.Metadata_ContentValue" relation: "MetadataContentValue.photo"'s inverse relation */
  Metadata_ContentValue?: InputMaybe<PhotoNestedMetadataContentvalueCreateInput>;
  /** Actions for the "Photo.Metadata_CopyrightLayer" relation: "MetadataCopyrightLayer.photo"'s inverse relation */
  Metadata_CopyrightLayer?: InputMaybe<PhotoNestedMetadataCopyrightlayerCreateInput>;
  /** Actions for the "Photo.Metadata_Country" relation: "MetadataCountry.photo"'s inverse relation */
  Metadata_Country?: InputMaybe<PhotoNestedMetadataCountryCreateInput>;
  /** Actions for the "Photo.Metadata_CountryCode" relation: "MetadataCountryCode.photo"'s inverse relation */
  Metadata_CountryCode?: InputMaybe<PhotoNestedMetadataCountrycodeCreateInput>;
  /** Actions for the "Photo.Metadata_CreatedTime" relation: "MetadataCreatedTime.photo"'s inverse relation */
  Metadata_CreatedTime?: InputMaybe<PhotoNestedMetadataCreatedtimeCreateInput>;
  /** Actions for the "Photo.Metadata_creator" relation: "MetadataCreator.photo"'s inverse relation */
  Metadata_creator?: InputMaybe<PhotoNestedMetadataCreatorCreateInput>;
  /** Actions for the "Photo.Metadata_CreatorTool" relation: "MetadataCreatorTool.photo"'s inverse relation */
  Metadata_CreatorTool?: InputMaybe<PhotoNestedMetadataCreatortoolCreateInput>;
  /** Actions for the "Photo.Metadata_Credit" relation: "MetadataCredit.photo"'s inverse relation */
  Metadata_Credit?: InputMaybe<PhotoNestedMetadataCreditCreateInput>;
  /** Actions for the "Photo.Metadata_CustomField14" relation: "MetadataCustomField14.photo"'s inverse relation */
  Metadata_CustomField14?: InputMaybe<PhotoNestedMetadataCustomfield14CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField15" relation: "MetadataCustomField15.photo"'s inverse relation */
  Metadata_CustomField15?: InputMaybe<PhotoNestedMetadataCustomfield15CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField16" relation: "MetadataCustomField16.photo"'s inverse relation */
  Metadata_CustomField16?: InputMaybe<PhotoNestedMetadataCustomfield16CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField17" relation: "MetadataCustomField17.photo"'s inverse relation */
  Metadata_CustomField17?: InputMaybe<PhotoNestedMetadataCustomfield17CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField20" relation: "MetadataCustomField20.photo"'s inverse relation */
  Metadata_CustomField20?: InputMaybe<PhotoNestedMetadataCustomfield20CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField5" relation: "MetadataCustomField5.photo"'s inverse relation */
  Metadata_CustomField5?: InputMaybe<PhotoNestedMetadataCustomfield5CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField6" relation: "MetadataCustomField6.photo"'s inverse relation */
  Metadata_CustomField6?: InputMaybe<PhotoNestedMetadataCustomfield6CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField7" relation: "MetadataCustomField7.photo"'s inverse relation */
  Metadata_CustomField7?: InputMaybe<PhotoNestedMetadataCustomfield7CreateInput>;
  /** Actions for the "Photo.Metadata_CustomField9" relation: "MetadataCustomField9.photo"'s inverse relation */
  Metadata_CustomField9?: InputMaybe<PhotoNestedMetadataCustomfield9CreateInput>;
  /** Actions for the "Photo.Metadata_DateCreated" relation: "MetadataDateCreated.photo"'s inverse relation */
  Metadata_DateCreated?: InputMaybe<PhotoNestedMetadataDatecreatedCreateInput>;
  /** Actions for the "Photo.Metadata_DateTimeDigitized" relation: "MetadataDateTimeDigitized.photo"'s inverse relation */
  Metadata_DateTimeDigitized?: InputMaybe<PhotoNestedMetadataDatetimedigitizedCreateInput>;
  /** Actions for the "Photo.Metadata_DateTimeOriginal" relation: "MetadataDateTimeOriginal.photo"'s inverse relation */
  Metadata_DateTimeOriginal?: InputMaybe<PhotoNestedMetadataDatetimeoriginalCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryAccount" relation: "MetadataDeliveryAccount.photo"'s inverse relation */
  Metadata_DeliveryAccount?: InputMaybe<PhotoNestedMetadataDeliveryaccountCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCompany" relation: "MetadataDeliveryCompany.photo"'s inverse relation */
  Metadata_DeliveryCompany?: InputMaybe<PhotoNestedMetadataDeliverycompanyCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCopyright" relation: "MetadataDeliveryCopyright.photo"'s inverse relation */
  Metadata_DeliveryCopyright?: InputMaybe<PhotoNestedMetadataDeliverycopyrightCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateFolder" relation: "MetadataDeliveryDateFolder.photo"'s inverse relation */
  Metadata_DeliveryDateFolder?: InputMaybe<PhotoNestedMetadataDeliverydatefolderCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateTime" relation: "MetadataDeliveryDateTime.photo"'s inverse relation */
  Metadata_DeliveryDateTime?: InputMaybe<PhotoNestedMetadataDeliverydatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcCreator" relation: "MetadataDeliveryDcCreator.photo"'s inverse relation */
  Metadata_DeliveryDcCreator?: InputMaybe<PhotoNestedMetadataDeliverydccreatorCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcRights" relation: "MetadataDeliveryDcRights.photo"'s inverse relation */
  Metadata_DeliveryDcRights?: InputMaybe<PhotoNestedMetadataDeliverydcrightsCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFileType" relation: "MetadataDeliveryFileType.photo"'s inverse relation */
  Metadata_DeliveryFileType?: InputMaybe<PhotoNestedMetadataDeliveryfiletypeCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFolder" relation: "MetadataDeliveryFolder.photo"'s inverse relation */
  Metadata_DeliveryFolder?: InputMaybe<PhotoNestedMetadataDeliveryfolderCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryKind" relation: "MetadataDeliveryKind.photo"'s inverse relation */
  Metadata_DeliveryKind?: InputMaybe<PhotoNestedMetadataDeliverykindCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPath" relation: "MetadataDeliveryPath.photo"'s inverse relation */
  Metadata_DeliveryPath?: InputMaybe<PhotoNestedMetadataDeliverypathCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPersonShownintheImage" relation: "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation */
  Metadata_DeliveryPersonShownintheImage?: InputMaybe<PhotoNestedMetadataDeliverypersonshownintheimageCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopCredit" relation: "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopCredit?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopcreditCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopSource" relation: "MetadataDeliveryPhotoshopSource.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopSource?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopsourceCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryService" relation: "MetadataDeliveryService.photo"'s inverse relation */
  Metadata_DeliveryService?: InputMaybe<PhotoNestedMetadataDeliveryserviceCreateInput>;
  /** Actions for the "Photo.Metadata_DeliverySubject" relation: "MetadataDeliverySubject.photo"'s inverse relation */
  Metadata_DeliverySubject?: InputMaybe<PhotoNestedMetadataDeliverysubjectCreateInput>;
  /** Actions for the "Photo.Metadata_DeliveryUnderSubject" relation: "MetadataDeliveryUnderSubject.photo"'s inverse relation */
  Metadata_DeliveryUnderSubject?: InputMaybe<PhotoNestedMetadataDeliveryundersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_Department" relation: "MetadataDepartment.photo"'s inverse relation */
  Metadata_Department?: InputMaybe<PhotoNestedMetadataDepartmentCreateInput>;
  /** Actions for the "Photo.Metadata_description" relation: "MetadataDescription.photo"'s inverse relation */
  Metadata_description?: InputMaybe<PhotoNestedMetadataDescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_DigitalAssetURL" relation: "MetadataDigitalAssetUrl.photo"'s inverse relation */
  Metadata_DigitalAssetURL?: InputMaybe<PhotoNestedMetadataDigitalasseturlCreateInput>;
  /** Actions for the "Photo.Metadata_Division" relation: "MetadataDivision.photo"'s inverse relation */
  Metadata_Division?: InputMaybe<PhotoNestedMetadataDivisionCreateInput>;
  /** Actions for the "Photo.Metadata_DocumentText" relation: "MetadataDocumentText.photo"'s inverse relation */
  Metadata_DocumentText?: InputMaybe<PhotoNestedMetadataDocumenttextCreateInput>;
  /** Actions for the "Photo.Metadata_Edition" relation: "MetadataEdition.photo"'s inverse relation */
  Metadata_Edition?: InputMaybe<PhotoNestedMetadataEditionCreateInput>;
  /** Actions for the "Photo.Metadata_EditorialVersion" relation: "MetadataEditorialVersion.photo"'s inverse relation */
  Metadata_EditorialVersion?: InputMaybe<PhotoNestedMetadataEditorialversionCreateInput>;
  /** Actions for the "Photo.Metadata_EditStatus" relation: "MetadataEditStatus.photo"'s inverse relation */
  Metadata_EditStatus?: InputMaybe<PhotoNestedMetadataEditstatusCreateInput>;
  /** Actions for the "Photo.Metadata_EnvironnementPhoto" relation: "MetadataEnvironnementPhoto.photo"'s inverse relation */
  Metadata_EnvironnementPhoto?: InputMaybe<PhotoNestedMetadataEnvironnementphotoCreateInput>;
  /** Actions for the "Photo.Metadata_EquipmentInstitution" relation: "MetadataEquipmentInstitution.photo"'s inverse relation */
  Metadata_EquipmentInstitution?: InputMaybe<PhotoNestedMetadataEquipmentinstitutionCreateInput>;
  /** Actions for the "Photo.Metadata_EquipmentManufacturer" relation: "MetadataEquipmentManufacturer.photo"'s inverse relation */
  Metadata_EquipmentManufacturer?: InputMaybe<PhotoNestedMetadataEquipmentmanufacturerCreateInput>;
  /** Actions for the "Photo.Metadata_Event" relation: "MetadataEvent.photo"'s inverse relation */
  Metadata_Event?: InputMaybe<PhotoNestedMetadataEventCreateInput>;
  /** Actions for the "Photo.Metadata_Exif" relation: "MetadataExif.photo"'s inverse relation */
  Metadata_Exif?: InputMaybe<PhotoNestedMetadataExifCreateInput>;
  /** Actions for the "Photo.Metadata_FabStorage" relation: "MetadataFabStorage.photo"'s inverse relation */
  Metadata_FabStorage?: InputMaybe<PhotoNestedMetadataFabstorageCreateInput>;
  /** Actions for the "Photo.Metadata_FileCheckFor" relation: "MetadataFileCheckFor.photo"'s inverse relation */
  Metadata_FileCheckFor?: InputMaybe<PhotoNestedMetadataFilecheckforCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoBitsPerPixel" relation: "MetadataFileInfoBitsPerPixel.photo"'s inverse relation */
  Metadata_FileInfoBitsPerPixel?: InputMaybe<PhotoNestedMetadataFileinfobitsperpixelCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoFrames" relation: "MetadataFileInfoFrames.photo"'s inverse relation */
  Metadata_FileInfoFrames?: InputMaybe<PhotoNestedMetadataFileinfoframesCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoPhotometricInterpretation" relation: "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation */
  Metadata_FileInfoPhotometricInterpretation?: InputMaybe<PhotoNestedMetadataFileinfophotometricinterpretationCreateInput>;
  /** Actions for the "Photo.Metadata_FileInfoTransferSyntax" relation: "MetadataFileInfoTransferSyntax.photo"'s inverse relation */
  Metadata_FileInfoTransferSyntax?: InputMaybe<PhotoNestedMetadataFileinfotransfersyntaxCreateInput>;
  /** Actions for the "Photo.Metadata_FileStage" relation: "MetadataFileStage.photo"'s inverse relation */
  Metadata_FileStage?: InputMaybe<PhotoNestedMetadataFilestageCreateInput>;
  /** Actions for the "Photo.Metadata_FileStatus" relation: "MetadataFileStatus.photo"'s inverse relation */
  Metadata_FileStatus?: InputMaybe<PhotoNestedMetadataFilestatusCreateInput>;
  /** Actions for the "Photo.Metadata_FileType" relation: "MetadataFileType.photo"'s inverse relation */
  Metadata_FileType?: InputMaybe<PhotoNestedMetadataFiletypeCreateInput>;
  /** Actions for the "Photo.Metadata_FixtureIdentifier" relation: "MetadataFixtureIdentifier.photo"'s inverse relation */
  Metadata_FixtureIdentifier?: InputMaybe<PhotoNestedMetadataFixtureidentifierCreateInput>;
  /** Actions for the "Photo.Metadata_Format" relation: "MetadataFormat.photo"'s inverse relation */
  Metadata_Format?: InputMaybe<PhotoNestedMetadataFormatCreateInput>;
  /** Actions for the "Photo.Metadata_GPSLatitude" relation: "MetadataGpsLatitude.photo"'s inverse relation */
  Metadata_GPSLatitude?: InputMaybe<PhotoNestedMetadataGpslatitudeCreateInput>;
  /** Actions for the "Photo.Metadata_GPSLongitude" relation: "MetadataGpsLongitude.photo"'s inverse relation */
  Metadata_GPSLongitude?: InputMaybe<PhotoNestedMetadataGpslongitudeCreateInput>;
  /** Actions for the "Photo.Metadata_GPSProcessingMethod" relation: "MetadataGpsProcessingMethod.photo"'s inverse relation */
  Metadata_GPSProcessingMethod?: InputMaybe<PhotoNestedMetadataGpsprocessingmethodCreateInput>;
  /** Actions for the "Photo.Metadata_Header" relation: "MetadataHeader.photo"'s inverse relation */
  Metadata_Header?: InputMaybe<PhotoNestedMetadataHeaderCreateInput>;
  /** Actions for the "Photo.Metadata_HeaderPrisma" relation: "MetadataHeaderPrisma.photo"'s inverse relation */
  Metadata_HeaderPrisma?: InputMaybe<PhotoNestedMetadataHeaderprismaCreateInput>;
  /** Actions for the "Photo.Metadata_Headline" relation: "MetadataHeadline.photo"'s inverse relation */
  Metadata_Headline?: InputMaybe<PhotoNestedMetadataHeadlineCreateInput>;
  /** Actions for the "Photo.Metadata_History" relation: "MetadataHistory.photo"'s inverse relation */
  Metadata_History?: InputMaybe<PhotoNestedMetadataHistoryCreateInput>;
  /** Actions for the "Photo.Metadata_ICCProfile" relation: "MetadataIccProfile.photo"'s inverse relation */
  Metadata_ICCProfile?: InputMaybe<PhotoNestedMetadataIccprofileCreateInput>;
  /** Actions for the "Photo.Metadata_ID_subject" relation: "MetadataIdSubject.photo"'s inverse relation */
  Metadata_ID_subject?: InputMaybe<PhotoNestedMetadataIdSubjectCreateInput>;
  /** Actions for the "Photo.Metadata_IdAssignment" relation: "MetadataIdAssignment.photo"'s inverse relation */
  Metadata_IdAssignment?: InputMaybe<PhotoNestedMetadataIdassignmentCreateInput>;
  /** Actions for the "Photo.Metadata_IdMediaContent" relation: "MetadataIdMediaContent.photo"'s inverse relation */
  Metadata_IdMediaContent?: InputMaybe<PhotoNestedMetadataIdmediacontentCreateInput>;
  /** Actions for the "Photo.Metadata_IdPhotoPrisma" relation: "MetadataIdPhotoPrisma.photo"'s inverse relation */
  Metadata_IdPhotoPrisma?: InputMaybe<PhotoNestedMetadataIdphotoprismaCreateInput>;
  /** Actions for the "Photo.Metadata_ImageNotes" relation: "MetadataImageNotes.photo"'s inverse relation */
  Metadata_ImageNotes?: InputMaybe<PhotoNestedMetadataImagenotesCreateInput>;
  /** Actions for the "Photo.Metadata_ImageUniqueID" relation: "MetadataImageUniqueId.photo"'s inverse relation */
  Metadata_ImageUniqueID?: InputMaybe<PhotoNestedMetadataImageuniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Instructions" relation: "MetadataInstructions.photo"'s inverse relation */
  Metadata_Instructions?: InputMaybe<PhotoNestedMetadataInstructionsCreateInput>;
  /** Actions for the "Photo.Metadata_IntellectualGenre" relation: "MetadataIntellectualGenre.photo"'s inverse relation */
  Metadata_IntellectualGenre?: InputMaybe<PhotoNestedMetadataIntellectualgenreCreateInput>;
  /** Actions for the "Photo.Metadata_ISOSpeedRatings" relation: "MetadataIsoSpeedRatings.photo"'s inverse relation */
  Metadata_ISOSpeedRatings?: InputMaybe<PhotoNestedMetadataIsospeedratingsCreateInput>;
  /** Actions for the "Photo.Metadata_Issue" relation: "MetadataIssue.photo"'s inverse relation */
  Metadata_Issue?: InputMaybe<PhotoNestedMetadataIssueCreateInput>;
  /** Actions for the "Photo.Metadata_JobId" relation: "MetadataJobId.photo"'s inverse relation */
  Metadata_JobId?: InputMaybe<PhotoNestedMetadataJobidCreateInput>;
  /** Actions for the "Photo.Metadata_JobProcessing" relation: "MetadataJobProcessing.photo"'s inverse relation */
  Metadata_JobProcessing?: InputMaybe<PhotoNestedMetadataJobprocessingCreateInput>;
  /** Actions for the "Photo.Metadata_LayoutPictureEditing" relation: "MetadataLayoutPictureEditing.photo"'s inverse relation */
  Metadata_LayoutPictureEditing?: InputMaybe<PhotoNestedMetadataLayoutpictureeditingCreateInput>;
  /** Actions for the "Photo.Metadata_LayoutStorage" relation: "MetadataLayoutStorage.photo"'s inverse relation */
  Metadata_LayoutStorage?: InputMaybe<PhotoNestedMetadataLayoutstorageCreateInput>;
  /** Actions for the "Photo.Metadata_license" relation: "MetadataLicense.photo"'s inverse relation */
  Metadata_license?: InputMaybe<PhotoNestedMetadataLicenseCreateInput>;
  /** Actions for the "Photo.Metadata_Links" relation: "MetadataLinks.photo"'s inverse relation */
  Metadata_Links?: InputMaybe<PhotoNestedMetadataLinksCreateInput>;
  /** Actions for the "Photo.Metadata_LocalCaption" relation: "MetadataLocalCaption.photo"'s inverse relation */
  Metadata_LocalCaption?: InputMaybe<PhotoNestedMetadataLocalcaptionCreateInput>;
  /** Actions for the "Photo.Metadata_Location" relation: "MetadataLocation.photo"'s inverse relation */
  Metadata_Location?: InputMaybe<PhotoNestedMetadataLocationCreateInput>;
  /** Actions for the "Photo.Metadata_Make" relation: "MetadataMake.photo"'s inverse relation */
  Metadata_Make?: InputMaybe<PhotoNestedMetadataMakeCreateInput>;
  /** Actions for the "Photo.Metadata_Manifest" relation: "MetadataManifest.photo"'s inverse relation */
  Metadata_Manifest?: InputMaybe<PhotoNestedMetadataManifestCreateInput>;
  /** Actions for the "Photo.Metadata_Marked" relation: "MetadataMarked.photo"'s inverse relation */
  Metadata_Marked?: InputMaybe<PhotoNestedMetadataMarkedCreateInput>;
  /** Actions for the "Photo.Metadata_MasterDocumentId" relation: "MetadataMasterDocumentId.photo"'s inverse relation */
  Metadata_MasterDocumentId?: InputMaybe<PhotoNestedMetadataMasterdocumentidCreateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailHeight" relation: "MetadataMaxAvailHeight.photo"'s inverse relation */
  Metadata_MaxAvailHeight?: InputMaybe<PhotoNestedMetadataMaxavailheightCreateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailWidth" relation: "MetadataMaxAvailWidth.photo"'s inverse relation */
  Metadata_MaxAvailWidth?: InputMaybe<PhotoNestedMetadataMaxavailwidthCreateInput>;
  /** Actions for the "Photo.Metadata_MinorModelAgeDisclosure" relation: "MetadataMinorModelAgeDisclosure.photo"'s inverse relation */
  Metadata_MinorModelAgeDisclosure?: InputMaybe<PhotoNestedMetadataMinormodelagedisclosureCreateInput>;
  /** Actions for the "Photo.Metadata_Model" relation: "MetadataModel.photo"'s inverse relation */
  Metadata_Model?: InputMaybe<PhotoNestedMetadataModelCreateInput>;
  /** Actions for the "Photo.Metadata_ModelAge" relation: "MetadataModelAge.photo"'s inverse relation */
  Metadata_ModelAge?: InputMaybe<PhotoNestedMetadataModelageCreateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseID" relation: "MetadataModelReleaseId.photo"'s inverse relation */
  Metadata_ModelReleaseID?: InputMaybe<PhotoNestedMetadataModelreleaseidCreateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseStatus" relation: "MetadataModelReleaseStatus.photo"'s inverse relation */
  Metadata_ModelReleaseStatus?: InputMaybe<PhotoNestedMetadataModelreleasestatusCreateInput>;
  /** Actions for the "Photo.Metadata_morePermissions" relation: "MetadataMorePermissions.photo"'s inverse relation */
  Metadata_morePermissions?: InputMaybe<PhotoNestedMetadataMorepermissionsCreateInput>;
  /** Actions for the "Photo.Metadata_ObjectCycle" relation: "MetadataObjectCycle.photo"'s inverse relation */
  Metadata_ObjectCycle?: InputMaybe<PhotoNestedMetadataObjectcycleCreateInput>;
  /** Actions for the "Photo.Metadata_OrganisationInImageName" relation: "MetadataOrganisationInImageName.photo"'s inverse relation */
  Metadata_OrganisationInImageName?: InputMaybe<PhotoNestedMetadataOrganisationinimagenameCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalColor" relation: "MetadataOriginalColor.photo"'s inverse relation */
  Metadata_OriginalColor?: InputMaybe<PhotoNestedMetadataOriginalcolorCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalICCProfile" relation: "MetadataOriginalIccProfile.photo"'s inverse relation */
  Metadata_OriginalICCProfile?: InputMaybe<PhotoNestedMetadataOriginaliccprofileCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalName" relation: "MetadataOriginalName.photo"'s inverse relation */
  Metadata_OriginalName?: InputMaybe<PhotoNestedMetadataOriginalnameCreateInput>;
  /** Actions for the "Photo.Metadata_OriginalWeigth" relation: "MetadataOriginalWeigth.photo"'s inverse relation */
  Metadata_OriginalWeigth?: InputMaybe<PhotoNestedMetadataOriginalweigthCreateInput>;
  /** Actions for the "Photo.Metadata_Owner" relation: "MetadataOwner.photo"'s inverse relation */
  Metadata_Owner?: InputMaybe<PhotoNestedMetadataOwnerCreateInput>;
  /** Actions for the "Photo.Metadata_OwnerId" relation: "MetadataOwnerId.photo"'s inverse relation */
  Metadata_OwnerId?: InputMaybe<PhotoNestedMetadataOwneridCreateInput>;
  /** Actions for the "Photo.Metadata_Page" relation: "MetadataPage.photo"'s inverse relation */
  Metadata_Page?: InputMaybe<PhotoNestedMetadataPageCreateInput>;
  /** Actions for the "Photo.Metadata_PagePrisma" relation: "MetadataPagePrisma.photo"'s inverse relation */
  Metadata_PagePrisma?: InputMaybe<PhotoNestedMetadataPageprismaCreateInput>;
  /** Actions for the "Photo.Metadata_PaintBasedCorrections" relation: "MetadataPaintBasedCorrections.photo"'s inverse relation */
  Metadata_PaintBasedCorrections?: InputMaybe<PhotoNestedMetadataPaintbasedcorrectionsCreateInput>;
  /** Actions for the "Photo.Metadata_PatientDOB" relation: "MetadataPatientDob.photo"'s inverse relation */
  Metadata_PatientDOB?: InputMaybe<PhotoNestedMetadataPatientdobCreateInput>;
  /** Actions for the "Photo.Metadata_PatientID" relation: "MetadataPatientId.photo"'s inverse relation */
  Metadata_PatientID?: InputMaybe<PhotoNestedMetadataPatientidCreateInput>;
  /** Actions for the "Photo.Metadata_PatientName" relation: "MetadataPatientName.photo"'s inverse relation */
  Metadata_PatientName?: InputMaybe<PhotoNestedMetadataPatientnameCreateInput>;
  /** Actions for the "Photo.Metadata_PatientSex" relation: "MetadataPatientSex.photo"'s inverse relation */
  Metadata_PatientSex?: InputMaybe<PhotoNestedMetadataPatientsexCreateInput>;
  /** Actions for the "Photo.Metadata_PersonInImage" relation: "MetadataPersonInImage.photo"'s inverse relation */
  Metadata_PersonInImage?: InputMaybe<PhotoNestedMetadataPersoninimageCreateInput>;
  /** Actions for the "Photo.Metadata_PhotoStorage" relation: "MetadataPhotoStorage.photo"'s inverse relation */
  Metadata_PhotoStorage?: InputMaybe<PhotoNestedMetadataPhotostorageCreateInput>;
  /** Actions for the "Photo.Metadata_PrepressPictureEditing" relation: "MetadataPrepressPictureEditing.photo"'s inverse relation */
  Metadata_PrepressPictureEditing?: InputMaybe<PhotoNestedMetadataPrepresspictureeditingCreateInput>;
  /** Actions for the "Photo.Metadata_PriceLevel" relation: "MetadataPriceLevel.photo"'s inverse relation */
  Metadata_PriceLevel?: InputMaybe<PhotoNestedMetadataPricelevelCreateInput>;
  /** Actions for the "Photo.Metadata_PrintingProfile" relation: "MetadataPrintingProfile.photo"'s inverse relation */
  Metadata_PrintingProfile?: InputMaybe<PhotoNestedMetadataPrintingprofileCreateInput>;
  /** Actions for the "Photo.Metadata_PrismaProduction" relation: "MetadataPrismaProduction.photo"'s inverse relation */
  Metadata_PrismaProduction?: InputMaybe<PhotoNestedMetadataPrismaproductionCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessHistory" relation: "MetadataProcessHistory.photo"'s inverse relation */
  Metadata_ProcessHistory?: InputMaybe<PhotoNestedMetadataProcesshistoryCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessParameter" relation: "MetadataProcessParameter.photo"'s inverse relation */
  Metadata_ProcessParameter?: InputMaybe<PhotoNestedMetadataProcessparameterCreateInput>;
  /** Actions for the "Photo.Metadata_ProcessStatus" relation: "MetadataProcessStatus.photo"'s inverse relation */
  Metadata_ProcessStatus?: InputMaybe<PhotoNestedMetadataProcessstatusCreateInput>;
  /** Actions for the "Photo.Metadata_Product" relation: "MetadataProduct.photo"'s inverse relation */
  Metadata_Product?: InputMaybe<PhotoNestedMetadataProductCreateInput>;
  /** Actions for the "Photo.Metadata_Products" relation: "MetadataProducts.photo"'s inverse relation */
  Metadata_Products?: InputMaybe<PhotoNestedMetadataProductsCreateInput>;
  /** Actions for the "Photo.Metadata_ProductShortName" relation: "MetadataProductShortName.photo"'s inverse relation */
  Metadata_ProductShortName?: InputMaybe<PhotoNestedMetadataProductshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_Produits" relation: "MetadataProduits.photo"'s inverse relation */
  Metadata_Produits?: InputMaybe<PhotoNestedMetadataProduitsCreateInput>;
  /** Actions for the "Photo.Metadata_ProgramVersion" relation: "MetadataProgramVersion.photo"'s inverse relation */
  Metadata_ProgramVersion?: InputMaybe<PhotoNestedMetadataProgramversionCreateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseID" relation: "MetadataPropertyReleaseId.photo"'s inverse relation */
  Metadata_PropertyReleaseID?: InputMaybe<PhotoNestedMetadataPropertyreleaseidCreateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseStatus" relation: "MetadataPropertyReleaseStatus.photo"'s inverse relation */
  Metadata_PropertyReleaseStatus?: InputMaybe<PhotoNestedMetadataPropertyreleasestatusCreateInput>;
  /** Actions for the "Photo.Metadata_Publisher" relation: "MetadataPublisher.photo"'s inverse relation */
  Metadata_Publisher?: InputMaybe<PhotoNestedMetadataPublisherCreateInput>;
  /** Actions for the "Photo.Metadata_publishingIssue" relation: "MetadataPublishingIssue.photo"'s inverse relation */
  Metadata_publishingIssue?: InputMaybe<PhotoNestedMetadataPublishingissueCreateInput>;
  /** Actions for the "Photo.Metadata_publishingSubject" relation: "MetadataPublishingSubject.photo"'s inverse relation */
  Metadata_publishingSubject?: InputMaybe<PhotoNestedMetadataPublishingsubjectCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaBy" relation: "MetadataQualifiedUsePrismaBy.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaBy?: InputMaybe<PhotoNestedMetadataQualifieduseprismabyCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDate" relation: "MetadataQualifiedUsePrismaDate.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDate?: InputMaybe<PhotoNestedMetadataQualifieduseprismadateCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDuration" relation: "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDuration?: InputMaybe<PhotoNestedMetadataQualifieduseprismadurationCreateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaSupport" relation: "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaSupport?: InputMaybe<PhotoNestedMetadataQualifieduseprismasupportCreateInput>;
  /** Actions for the "Photo.Metadata_Rating" relation: "MetadataRating.photo"'s inverse relation */
  Metadata_Rating?: InputMaybe<PhotoNestedMetadataRatingCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceDate" relation: "MetadataReferenceDate.photo"'s inverse relation */
  Metadata_ReferenceDate?: InputMaybe<PhotoNestedMetadataReferencedateCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceNumber" relation: "MetadataReferenceNumber.photo"'s inverse relation */
  Metadata_ReferenceNumber?: InputMaybe<PhotoNestedMetadataReferencenumberCreateInput>;
  /** Actions for the "Photo.Metadata_ReferenceService" relation: "MetadataReferenceService.photo"'s inverse relation */
  Metadata_ReferenceService?: InputMaybe<PhotoNestedMetadataReferenceserviceCreateInput>;
  /** Actions for the "Photo.Metadata_ReleaseDate" relation: "MetadataReleaseDate.photo"'s inverse relation */
  Metadata_ReleaseDate?: InputMaybe<PhotoNestedMetadataReleasedateCreateInput>;
  /** Actions for the "Photo.Metadata_ReleaseTime" relation: "MetadataReleaseTime.photo"'s inverse relation */
  Metadata_ReleaseTime?: InputMaybe<PhotoNestedMetadataReleasetimeCreateInput>;
  /** Actions for the "Photo.Metadata_RequiredPermission" relation: "MetadataRequiredPermission.photo"'s inverse relation */
  Metadata_RequiredPermission?: InputMaybe<PhotoNestedMetadataRequiredpermissionCreateInput>;
  /** Actions for the "Photo.Metadata_ResolutionKind" relation: "MetadataResolutionKind.photo"'s inverse relation */
  Metadata_ResolutionKind?: InputMaybe<PhotoNestedMetadataResolutionkindCreateInput>;
  /** Actions for the "Photo.Metadata_rights" relation: "MetadataRights.photo"'s inverse relation */
  Metadata_rights?: InputMaybe<PhotoNestedMetadataRightsCreateInput>;
  /** Actions for the "Photo.Metadata_Royaltyfree" relation: "MetadataRoyaltyfree.photo"'s inverse relation */
  Metadata_Royaltyfree?: InputMaybe<PhotoNestedMetadataRoyaltyfreeCreateInput>;
  /** Actions for the "Photo.Metadata_Scene" relation: "MetadataScene.photo"'s inverse relation */
  Metadata_Scene?: InputMaybe<PhotoNestedMetadataSceneCreateInput>;
  /** Actions for the "Photo.Metadata_Section" relation: "MetadataSection.photo"'s inverse relation */
  Metadata_Section?: InputMaybe<PhotoNestedMetadataSectionCreateInput>;
  /** Actions for the "Photo.Metadata_SentTo" relation: "MetadataSentTo.photo"'s inverse relation */
  Metadata_SentTo?: InputMaybe<PhotoNestedMetadataSenttoCreateInput>;
  /** Actions for the "Photo.Metadata_SerialNumber" relation: "MetadataSerialNumber.photo"'s inverse relation */
  Metadata_SerialNumber?: InputMaybe<PhotoNestedMetadataSerialnumberCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesDateTime" relation: "MetadataSeriesDateTime.photo"'s inverse relation */
  Metadata_SeriesDateTime?: InputMaybe<PhotoNestedMetadataSeriesdatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesDescription" relation: "MetadataSeriesDescription.photo"'s inverse relation */
  Metadata_SeriesDescription?: InputMaybe<PhotoNestedMetadataSeriesdescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesModality" relation: "MetadataSeriesModality.photo"'s inverse relation */
  Metadata_SeriesModality?: InputMaybe<PhotoNestedMetadataSeriesmodalityCreateInput>;
  /** Actions for the "Photo.Metadata_SeriesNumber" relation: "MetadataSeriesNumber.photo"'s inverse relation */
  Metadata_SeriesNumber?: InputMaybe<PhotoNestedMetadataSeriesnumberCreateInput>;
  /** Actions for the "Photo.Metadata_ShortUniqueId" relation: "MetadataShortUniqueId.photo"'s inverse relation */
  Metadata_ShortUniqueId?: InputMaybe<PhotoNestedMetadataShortuniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Source" relation: "MetadataSource.photo"'s inverse relation */
  Metadata_Source?: InputMaybe<PhotoNestedMetadataSourceCreateInput>;
  /** Actions for the "Photo.Metadata_State" relation: "MetadataState.photo"'s inverse relation */
  Metadata_State?: InputMaybe<PhotoNestedMetadataStateCreateInput>;
  /** Actions for the "Photo.Metadata_StudyDateTime" relation: "MetadataStudyDateTime.photo"'s inverse relation */
  Metadata_StudyDateTime?: InputMaybe<PhotoNestedMetadataStudydatetimeCreateInput>;
  /** Actions for the "Photo.Metadata_StudyDescription" relation: "MetadataStudyDescription.photo"'s inverse relation */
  Metadata_StudyDescription?: InputMaybe<PhotoNestedMetadataStudydescriptionCreateInput>;
  /** Actions for the "Photo.Metadata_StudyID" relation: "MetadataStudyId.photo"'s inverse relation */
  Metadata_StudyID?: InputMaybe<PhotoNestedMetadataStudyidCreateInput>;
  /** Actions for the "Photo.Metadata_StudyPhysician" relation: "MetadataStudyPhysician.photo"'s inverse relation */
  Metadata_StudyPhysician?: InputMaybe<PhotoNestedMetadataStudyphysicianCreateInput>;
  /** Actions for the "Photo.Metadata_subject" relation: "MetadataSubject.photo"'s inverse relation */
  Metadata_subject?: InputMaybe<PhotoNestedMetadataSubjectCreateInput>;
  /** Actions for the "Photo.Metadata_SubjectCode" relation: "MetadataSubjectCode.photo"'s inverse relation */
  Metadata_SubjectCode?: InputMaybe<PhotoNestedMetadataSubjectcodeCreateInput>;
  /** Actions for the "Photo.Metadata_SubjectPrisma" relation: "MetadataSubjectPrisma.photo"'s inverse relation */
  Metadata_SubjectPrisma?: InputMaybe<PhotoNestedMetadataSubjectprismaCreateInput>;
  /** Actions for the "Photo.Metadata_SupplementalCategories" relation: "MetadataSupplementalCategories.photo"'s inverse relation */
  Metadata_SupplementalCategories?: InputMaybe<PhotoNestedMetadataSupplementalcategoriesCreateInput>;
  /** Actions for the "Photo.Metadata_TargetVersion" relation: "MetadataTargetVersion.photo"'s inverse relation */
  Metadata_TargetVersion?: InputMaybe<PhotoNestedMetadataTargetversionCreateInput>;
  /** Actions for the "Photo.Metadata_title" relation: "MetadataTitle.photo"'s inverse relation */
  Metadata_title?: InputMaybe<PhotoNestedMetadataTitleCreateInput>;
  /** Actions for the "Photo.Metadata_ToneCurve" relation: "MetadataToneCurve.photo"'s inverse relation */
  Metadata_ToneCurve?: InputMaybe<PhotoNestedMetadataTonecurveCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredBy" relation: "MetadataTransferredBy.photo"'s inverse relation */
  Metadata_TransferredBy?: InputMaybe<PhotoNestedMetadataTransferredbyCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredByEmail" relation: "MetadataTransferredByEmail.photo"'s inverse relation */
  Metadata_TransferredByEmail?: InputMaybe<PhotoNestedMetadataTransferredbyemailCreateInput>;
  /** Actions for the "Photo.Metadata_TransferredByFullName" relation: "MetadataTransferredByFullName.photo"'s inverse relation */
  Metadata_TransferredByFullName?: InputMaybe<PhotoNestedMetadataTransferredbyfullnameCreateInput>;
  /** Actions for the "Photo.Metadata_TransmissionReference" relation: "MetadataTransmissionReference.photo"'s inverse relation */
  Metadata_TransmissionReference?: InputMaybe<PhotoNestedMetadataTransmissionreferenceCreateInput>;
  /** Actions for the "Photo.Metadata_Type" relation: "MetadataType.photo"'s inverse relation */
  Metadata_Type?: InputMaybe<PhotoNestedMetadataTypeCreateInput>;
  /** Actions for the "Photo.Metadata_Undersubject" relation: "MetadataUndersubject.photo"'s inverse relation */
  Metadata_Undersubject?: InputMaybe<PhotoNestedMetadataUndersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_UnderUnderSubject" relation: "MetadataUnderUnderSubject.photo"'s inverse relation */
  Metadata_UnderUnderSubject?: InputMaybe<PhotoNestedMetadataUnderundersubjectCreateInput>;
  /** Actions for the "Photo.Metadata_UniqueId" relation: "MetadataUniqueId.photo"'s inverse relation */
  Metadata_UniqueId?: InputMaybe<PhotoNestedMetadataUniqueidCreateInput>;
  /** Actions for the "Photo.Metadata_Unit" relation: "MetadataUnit.photo"'s inverse relation */
  Metadata_Unit?: InputMaybe<PhotoNestedMetadataUnitCreateInput>;
  /** Actions for the "Photo.Metadata_UnitShortName" relation: "MetadataUnitShortName.photo"'s inverse relation */
  Metadata_UnitShortName?: InputMaybe<PhotoNestedMetadataUnitshortnameCreateInput>;
  /** Actions for the "Photo.Metadata_UploadedBy" relation: "MetadataUploadedBy.photo"'s inverse relation */
  Metadata_UploadedBy?: InputMaybe<PhotoNestedMetadataUploadedbyCreateInput>;
  /** Actions for the "Photo.Metadata_UploadedByFullName" relation: "MetadataUploadedByFullName.photo"'s inverse relation */
  Metadata_UploadedByFullName?: InputMaybe<PhotoNestedMetadataUploadedbyfullnameCreateInput>;
  /** Actions for the "Photo.Metadata_UploadTime" relation: "MetadataUploadTime.photo"'s inverse relation */
  Metadata_UploadTime?: InputMaybe<PhotoNestedMetadataUploadtimeCreateInput>;
  /** Actions for the "Photo.Metadata_Urgency" relation: "MetadataUrgency.photo"'s inverse relation */
  Metadata_Urgency?: InputMaybe<PhotoNestedMetadataUrgencyCreateInput>;
  /** Actions for the "Photo.Metadata_UsageTerms" relation: "MetadataUsageTerms.photo"'s inverse relation */
  Metadata_UsageTerms?: InputMaybe<PhotoNestedMetadataUsagetermsCreateInput>;
  /** Actions for the "Photo.Metadata_UserComment" relation: "MetadataUserComment.photo"'s inverse relation */
  Metadata_UserComment?: InputMaybe<PhotoNestedMetadataUsercommentCreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined195" relation: "MetadataUserDefined195.photo"'s inverse relation */
  Metadata_UserDefined195?: InputMaybe<PhotoNestedMetadataUserdefined195CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined237" relation: "MetadataUserDefined237.photo"'s inverse relation */
  Metadata_UserDefined237?: InputMaybe<PhotoNestedMetadataUserdefined237CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined238" relation: "MetadataUserDefined238.photo"'s inverse relation */
  Metadata_UserDefined238?: InputMaybe<PhotoNestedMetadataUserdefined238CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined239" relation: "MetadataUserDefined239.photo"'s inverse relation */
  Metadata_UserDefined239?: InputMaybe<PhotoNestedMetadataUserdefined239CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined242" relation: "MetadataUserDefined242.photo"'s inverse relation */
  Metadata_UserDefined242?: InputMaybe<PhotoNestedMetadataUserdefined242CreateInput>;
  /** Actions for the "Photo.Metadata_UserDefined62" relation: "MetadataUserDefined62.photo"'s inverse relation */
  Metadata_UserDefined62?: InputMaybe<PhotoNestedMetadataUserdefined62CreateInput>;
  /** Actions for the "Photo.Metadata_Valid" relation: "MetadataValid.photo"'s inverse relation */
  Metadata_Valid?: InputMaybe<PhotoNestedMetadataValidCreateInput>;
  /** Actions for the "Photo.Metadata_Version" relation: "MetadataVersion.photo"'s inverse relation */
  Metadata_Version?: InputMaybe<PhotoNestedMetadataVersionCreateInput>;
  /** Actions for the "Photo.Metadata_WebStatement" relation: "MetadataWebStatement.photo"'s inverse relation */
  Metadata_WebStatement?: InputMaybe<PhotoNestedMetadataWebstatementCreateInput>;
  /** Actions for the "Photo.Metadata_WorkflowKind" relation: "MetadataWorkflowKind.photo"'s inverse relation */
  Metadata_WorkflowKind?: InputMaybe<PhotoNestedMetadataWorkflowkindCreateInput>;
  /** Actions for the "Photo.Metadata_XMPFileStamps" relation: "MetadataXmpFileStamps.photo"'s inverse relation */
  Metadata_XMPFileStamps?: InputMaybe<PhotoNestedMetadataXmpfilestampsCreateInput>;
  /** Actions for the "Photo.Metadata_XMPHistory" relation: "MetadataXmpHistory.photo"'s inverse relation */
  Metadata_XMPHistory?: InputMaybe<PhotoNestedMetadataXmphistoryCreateInput>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.photoMetadatas" relation: "PhotoMetadata.photo"'s inverse relation */
  photoMetadatas?: InputMaybe<PhotoNestedPhotoMetadatasCreateInput>;
  /** Actions for the "Photo.photoPublications" relation: "PhotoPublication.photo"'s inverse relation */
  photoPublications?: InputMaybe<PhotoNestedPhotoPublicationsCreateInput>;
  synced?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date of the photo's capture */
  takenAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type PhotoWithForcedDeliverySubjectUpdateInput = {
  /** Actions for the "Photo.agency" relation */
  agency?: InputMaybe<PhotoNestedAgencyUpdateInput>;
  /** Actions for the "Photo.archive" relation */
  archive?: InputMaybe<PhotoNestedArchiveUpdateInput>;
  /** Actions for the "Photo.category" relation */
  category?: InputMaybe<PhotoNestedCategoryUpdateInput>;
  /** asset content type */
  contentType?: InputMaybe<Scalars['String']['input']>;
  filesize?: InputMaybe<Scalars['Int']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  /** The date of the photo's importation */
  importedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Actions for the "Photo.importQueue" relation: "ImportQueue.photo"'s inverse relation */
  importQueue?: InputMaybe<PhotoNestedImportQueueUpdateInput>;
  isLegacy?: InputMaybe<Scalars['Boolean']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  /** Actions for the "Photo.Metadata_AccessRights" relation: "MetadataAccessRights.photo"'s inverse relation */
  Metadata_AccessRights?: InputMaybe<PhotoNestedMetadataAccessrightsUpdateInput>;
  /** Actions for the "Photo.Metadata_AddlModelInfo" relation: "MetadataAddlModelInfo.photo"'s inverse relation */
  Metadata_AddlModelInfo?: InputMaybe<PhotoNestedMetadataAddlmodelinfoUpdateInput>;
  /** Actions for the "Photo.Metadata_Archive_child" relation: "MetadataArchiveChild.photo"'s inverse relation */
  Metadata_Archive_child?: InputMaybe<PhotoNestedMetadataArchiveChildUpdateInput>;
  /** Actions for the "Photo.Metadata_Archive_parent" relation: "MetadataArchiveParent.photo"'s inverse relation */
  Metadata_Archive_parent?: InputMaybe<PhotoNestedMetadataArchiveParentUpdateInput>;
  /** Actions for the "Photo.Metadata_AssetState" relation: "MetadataAssetState.photo"'s inverse relation */
  Metadata_AssetState?: InputMaybe<PhotoNestedMetadataAssetstateUpdateInput>;
  /** Actions for the "Photo.Metadata_attributionName" relation: "MetadataAttributionName.photo"'s inverse relation */
  Metadata_attributionName?: InputMaybe<PhotoNestedMetadataAttributionnameUpdateInput>;
  /** Actions for the "Photo.Metadata_attributionURL" relation: "MetadataAttributionUrl.photo"'s inverse relation */
  Metadata_attributionURL?: InputMaybe<PhotoNestedMetadataAttributionurlUpdateInput>;
  /** Actions for the "Photo.Metadata_Audience" relation: "MetadataAudience.photo"'s inverse relation */
  Metadata_Audience?: InputMaybe<PhotoNestedMetadataAudienceUpdateInput>;
  /** Actions for the "Photo.Metadata_AuthorsPosition" relation: "MetadataAuthorsPosition.photo"'s inverse relation */
  Metadata_AuthorsPosition?: InputMaybe<PhotoNestedMetadataAuthorspositionUpdateInput>;
  /** Actions for the "Photo.Metadata_BackupName" relation: "MetadataBackupName.photo"'s inverse relation */
  Metadata_BackupName?: InputMaybe<PhotoNestedMetadataBackupnameUpdateInput>;
  /** Actions for the "Photo.Metadata_BitmapGrayscalePicture" relation: "MetadataBitmapGrayscalePicture.photo"'s inverse relation */
  Metadata_BitmapGrayscalePicture?: InputMaybe<PhotoNestedMetadataBitmapgrayscalepictureUpdateInput>;
  /** Actions for the "Photo.Metadata_Brand" relation: "MetadataBrand.photo"'s inverse relation */
  Metadata_Brand?: InputMaybe<PhotoNestedMetadataBrandUpdateInput>;
  /** Actions for the "Photo.Metadata_BrandPrisma" relation: "MetadataBrandPrisma.photo"'s inverse relation */
  Metadata_BrandPrisma?: InputMaybe<PhotoNestedMetadataBrandprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_BrandShortName" relation: "MetadataBrandShortName.photo"'s inverse relation */
  Metadata_BrandShortName?: InputMaybe<PhotoNestedMetadataBrandshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_CaptionWriter" relation: "MetadataCaptionWriter.photo"'s inverse relation */
  Metadata_CaptionWriter?: InputMaybe<PhotoNestedMetadataCaptionwriterUpdateInput>;
  /** Actions for the "Photo.Metadata_Category" relation: "MetadataCategory.photo"'s inverse relation */
  Metadata_Category?: InputMaybe<PhotoNestedMetadataCategoryUpdateInput>;
  /** Actions for the "Photo.Metadata_CategoryPrisma" relation: "MetadataCategoryPrisma.photo"'s inverse relation */
  Metadata_CategoryPrisma?: InputMaybe<PhotoNestedMetadataCategoryprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_Certificate" relation: "MetadataCertificate.photo"'s inverse relation */
  Metadata_Certificate?: InputMaybe<PhotoNestedMetadataCertificateUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCity" relation: "MetadataCiAdrCity.photo"'s inverse relation */
  Metadata_CiAdrCity?: InputMaybe<PhotoNestedMetadataCiadrcityUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrCtry" relation: "MetadataCiAdrCtry.photo"'s inverse relation */
  Metadata_CiAdrCtry?: InputMaybe<PhotoNestedMetadataCiadrctryUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrExtadr" relation: "MetadataCiAdrExtadr.photo"'s inverse relation */
  Metadata_CiAdrExtadr?: InputMaybe<PhotoNestedMetadataCiadrextadrUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrPcode" relation: "MetadataCiAdrPcode.photo"'s inverse relation */
  Metadata_CiAdrPcode?: InputMaybe<PhotoNestedMetadataCiadrpcodeUpdateInput>;
  /** Actions for the "Photo.Metadata_CiAdrRegion" relation: "MetadataCiAdrRegion.photo"'s inverse relation */
  Metadata_CiAdrRegion?: InputMaybe<PhotoNestedMetadataCiadrregionUpdateInput>;
  /** Actions for the "Photo.Metadata_CiEmailWork" relation: "MetadataCiEmailWork.photo"'s inverse relation */
  Metadata_CiEmailWork?: InputMaybe<PhotoNestedMetadataCiemailworkUpdateInput>;
  /** Actions for the "Photo.Metadata_CiTelWork" relation: "MetadataCiTelWork.photo"'s inverse relation */
  Metadata_CiTelWork?: InputMaybe<PhotoNestedMetadataCitelworkUpdateInput>;
  /** Actions for the "Photo.Metadata_City" relation: "MetadataCity.photo"'s inverse relation */
  Metadata_City?: InputMaybe<PhotoNestedMetadataCityUpdateInput>;
  /** Actions for the "Photo.Metadata_CiUrlWork" relation: "MetadataCiUrlWork.photo"'s inverse relation */
  Metadata_CiUrlWork?: InputMaybe<PhotoNestedMetadataCiurlworkUpdateInput>;
  /** Actions for the "Photo.Metadata_Classify" relation: "MetadataClassify.photo"'s inverse relation */
  Metadata_Classify?: InputMaybe<PhotoNestedMetadataClassifyUpdateInput>;
  /** Actions for the "Photo.Metadata_Company" relation: "MetadataCompany.photo"'s inverse relation */
  Metadata_Company?: InputMaybe<PhotoNestedMetadataCompanyUpdateInput>;
  /** Actions for the "Photo.Metadata_CompanyShortName" relation: "MetadataCompanyShortName.photo"'s inverse relation */
  Metadata_CompanyShortName?: InputMaybe<PhotoNestedMetadataCompanyshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_Confidentiality" relation: "MetadataConfidentiality.photo"'s inverse relation */
  Metadata_Confidentiality?: InputMaybe<PhotoNestedMetadataConfidentialityUpdateInput>;
  /** Actions for the "Photo.Metadata_ContainedIn" relation: "MetadataContainedIn.photo"'s inverse relation */
  Metadata_ContainedIn?: InputMaybe<PhotoNestedMetadataContainedinUpdateInput>;
  /** Actions for the "Photo.Metadata_ContentMediaKind" relation: "MetadataContentMediaKind.photo"'s inverse relation */
  Metadata_ContentMediaKind?: InputMaybe<PhotoNestedMetadataContentmediakindUpdateInput>;
  /** Actions for the "Photo.Metadata_ContentValue" relation: "MetadataContentValue.photo"'s inverse relation */
  Metadata_ContentValue?: InputMaybe<PhotoNestedMetadataContentvalueUpdateInput>;
  /** Actions for the "Photo.Metadata_CopyrightLayer" relation: "MetadataCopyrightLayer.photo"'s inverse relation */
  Metadata_CopyrightLayer?: InputMaybe<PhotoNestedMetadataCopyrightlayerUpdateInput>;
  /** Actions for the "Photo.Metadata_Country" relation: "MetadataCountry.photo"'s inverse relation */
  Metadata_Country?: InputMaybe<PhotoNestedMetadataCountryUpdateInput>;
  /** Actions for the "Photo.Metadata_CountryCode" relation: "MetadataCountryCode.photo"'s inverse relation */
  Metadata_CountryCode?: InputMaybe<PhotoNestedMetadataCountrycodeUpdateInput>;
  /** Actions for the "Photo.Metadata_CreatedTime" relation: "MetadataCreatedTime.photo"'s inverse relation */
  Metadata_CreatedTime?: InputMaybe<PhotoNestedMetadataCreatedtimeUpdateInput>;
  /** Actions for the "Photo.Metadata_creator" relation: "MetadataCreator.photo"'s inverse relation */
  Metadata_creator?: InputMaybe<PhotoNestedMetadataCreatorUpdateInput>;
  /** Actions for the "Photo.Metadata_CreatorTool" relation: "MetadataCreatorTool.photo"'s inverse relation */
  Metadata_CreatorTool?: InputMaybe<PhotoNestedMetadataCreatortoolUpdateInput>;
  /** Actions for the "Photo.Metadata_Credit" relation: "MetadataCredit.photo"'s inverse relation */
  Metadata_Credit?: InputMaybe<PhotoNestedMetadataCreditUpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField14" relation: "MetadataCustomField14.photo"'s inverse relation */
  Metadata_CustomField14?: InputMaybe<PhotoNestedMetadataCustomfield14UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField15" relation: "MetadataCustomField15.photo"'s inverse relation */
  Metadata_CustomField15?: InputMaybe<PhotoNestedMetadataCustomfield15UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField16" relation: "MetadataCustomField16.photo"'s inverse relation */
  Metadata_CustomField16?: InputMaybe<PhotoNestedMetadataCustomfield16UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField17" relation: "MetadataCustomField17.photo"'s inverse relation */
  Metadata_CustomField17?: InputMaybe<PhotoNestedMetadataCustomfield17UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField20" relation: "MetadataCustomField20.photo"'s inverse relation */
  Metadata_CustomField20?: InputMaybe<PhotoNestedMetadataCustomfield20UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField5" relation: "MetadataCustomField5.photo"'s inverse relation */
  Metadata_CustomField5?: InputMaybe<PhotoNestedMetadataCustomfield5UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField6" relation: "MetadataCustomField6.photo"'s inverse relation */
  Metadata_CustomField6?: InputMaybe<PhotoNestedMetadataCustomfield6UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField7" relation: "MetadataCustomField7.photo"'s inverse relation */
  Metadata_CustomField7?: InputMaybe<PhotoNestedMetadataCustomfield7UpdateInput>;
  /** Actions for the "Photo.Metadata_CustomField9" relation: "MetadataCustomField9.photo"'s inverse relation */
  Metadata_CustomField9?: InputMaybe<PhotoNestedMetadataCustomfield9UpdateInput>;
  /** Actions for the "Photo.Metadata_DateCreated" relation: "MetadataDateCreated.photo"'s inverse relation */
  Metadata_DateCreated?: InputMaybe<PhotoNestedMetadataDatecreatedUpdateInput>;
  /** Actions for the "Photo.Metadata_DateTimeDigitized" relation: "MetadataDateTimeDigitized.photo"'s inverse relation */
  Metadata_DateTimeDigitized?: InputMaybe<PhotoNestedMetadataDatetimedigitizedUpdateInput>;
  /** Actions for the "Photo.Metadata_DateTimeOriginal" relation: "MetadataDateTimeOriginal.photo"'s inverse relation */
  Metadata_DateTimeOriginal?: InputMaybe<PhotoNestedMetadataDatetimeoriginalUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryAccount" relation: "MetadataDeliveryAccount.photo"'s inverse relation */
  Metadata_DeliveryAccount?: InputMaybe<PhotoNestedMetadataDeliveryaccountUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCompany" relation: "MetadataDeliveryCompany.photo"'s inverse relation */
  Metadata_DeliveryCompany?: InputMaybe<PhotoNestedMetadataDeliverycompanyUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryCopyright" relation: "MetadataDeliveryCopyright.photo"'s inverse relation */
  Metadata_DeliveryCopyright?: InputMaybe<PhotoNestedMetadataDeliverycopyrightUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateFolder" relation: "MetadataDeliveryDateFolder.photo"'s inverse relation */
  Metadata_DeliveryDateFolder?: InputMaybe<PhotoNestedMetadataDeliverydatefolderUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDateTime" relation: "MetadataDeliveryDateTime.photo"'s inverse relation */
  Metadata_DeliveryDateTime?: InputMaybe<PhotoNestedMetadataDeliverydatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcCreator" relation: "MetadataDeliveryDcCreator.photo"'s inverse relation */
  Metadata_DeliveryDcCreator?: InputMaybe<PhotoNestedMetadataDeliverydccreatorUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryDcRights" relation: "MetadataDeliveryDcRights.photo"'s inverse relation */
  Metadata_DeliveryDcRights?: InputMaybe<PhotoNestedMetadataDeliverydcrightsUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFileType" relation: "MetadataDeliveryFileType.photo"'s inverse relation */
  Metadata_DeliveryFileType?: InputMaybe<PhotoNestedMetadataDeliveryfiletypeUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryFolder" relation: "MetadataDeliveryFolder.photo"'s inverse relation */
  Metadata_DeliveryFolder?: InputMaybe<PhotoNestedMetadataDeliveryfolderUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryKind" relation: "MetadataDeliveryKind.photo"'s inverse relation */
  Metadata_DeliveryKind?: InputMaybe<PhotoNestedMetadataDeliverykindUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPath" relation: "MetadataDeliveryPath.photo"'s inverse relation */
  Metadata_DeliveryPath?: InputMaybe<PhotoNestedMetadataDeliverypathUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPersonShownintheImage" relation: "MetadataDeliveryPersonShownintheImage.photo"'s inverse relation */
  Metadata_DeliveryPersonShownintheImage?: InputMaybe<PhotoNestedMetadataDeliverypersonshownintheimageUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopCredit" relation: "MetadataDeliveryPhotoshopCredit.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopCredit?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopcreditUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryPhotoshopSource" relation: "MetadataDeliveryPhotoshopSource.photo"'s inverse relation */
  Metadata_DeliveryPhotoshopSource?: InputMaybe<PhotoNestedMetadataDeliveryphotoshopsourceUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryService" relation: "MetadataDeliveryService.photo"'s inverse relation */
  Metadata_DeliveryService?: InputMaybe<PhotoNestedMetadataDeliveryserviceUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliverySubject" relation: "MetadataDeliverySubject.photo"'s inverse relation */
  Metadata_DeliverySubject?: InputMaybe<PhotoNestedMetadataDeliverysubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_DeliveryUnderSubject" relation: "MetadataDeliveryUnderSubject.photo"'s inverse relation */
  Metadata_DeliveryUnderSubject?: InputMaybe<PhotoNestedMetadataDeliveryundersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_Department" relation: "MetadataDepartment.photo"'s inverse relation */
  Metadata_Department?: InputMaybe<PhotoNestedMetadataDepartmentUpdateInput>;
  /** Actions for the "Photo.Metadata_description" relation: "MetadataDescription.photo"'s inverse relation */
  Metadata_description?: InputMaybe<PhotoNestedMetadataDescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_DigitalAssetURL" relation: "MetadataDigitalAssetUrl.photo"'s inverse relation */
  Metadata_DigitalAssetURL?: InputMaybe<PhotoNestedMetadataDigitalasseturlUpdateInput>;
  /** Actions for the "Photo.Metadata_Division" relation: "MetadataDivision.photo"'s inverse relation */
  Metadata_Division?: InputMaybe<PhotoNestedMetadataDivisionUpdateInput>;
  /** Actions for the "Photo.Metadata_DocumentText" relation: "MetadataDocumentText.photo"'s inverse relation */
  Metadata_DocumentText?: InputMaybe<PhotoNestedMetadataDocumenttextUpdateInput>;
  /** Actions for the "Photo.Metadata_Edition" relation: "MetadataEdition.photo"'s inverse relation */
  Metadata_Edition?: InputMaybe<PhotoNestedMetadataEditionUpdateInput>;
  /** Actions for the "Photo.Metadata_EditorialVersion" relation: "MetadataEditorialVersion.photo"'s inverse relation */
  Metadata_EditorialVersion?: InputMaybe<PhotoNestedMetadataEditorialversionUpdateInput>;
  /** Actions for the "Photo.Metadata_EditStatus" relation: "MetadataEditStatus.photo"'s inverse relation */
  Metadata_EditStatus?: InputMaybe<PhotoNestedMetadataEditstatusUpdateInput>;
  /** Actions for the "Photo.Metadata_EnvironnementPhoto" relation: "MetadataEnvironnementPhoto.photo"'s inverse relation */
  Metadata_EnvironnementPhoto?: InputMaybe<PhotoNestedMetadataEnvironnementphotoUpdateInput>;
  /** Actions for the "Photo.Metadata_EquipmentInstitution" relation: "MetadataEquipmentInstitution.photo"'s inverse relation */
  Metadata_EquipmentInstitution?: InputMaybe<PhotoNestedMetadataEquipmentinstitutionUpdateInput>;
  /** Actions for the "Photo.Metadata_EquipmentManufacturer" relation: "MetadataEquipmentManufacturer.photo"'s inverse relation */
  Metadata_EquipmentManufacturer?: InputMaybe<PhotoNestedMetadataEquipmentmanufacturerUpdateInput>;
  /** Actions for the "Photo.Metadata_Event" relation: "MetadataEvent.photo"'s inverse relation */
  Metadata_Event?: InputMaybe<PhotoNestedMetadataEventUpdateInput>;
  /** Actions for the "Photo.Metadata_Exif" relation: "MetadataExif.photo"'s inverse relation */
  Metadata_Exif?: InputMaybe<PhotoNestedMetadataExifUpdateInput>;
  /** Actions for the "Photo.Metadata_FabStorage" relation: "MetadataFabStorage.photo"'s inverse relation */
  Metadata_FabStorage?: InputMaybe<PhotoNestedMetadataFabstorageUpdateInput>;
  /** Actions for the "Photo.Metadata_FileCheckFor" relation: "MetadataFileCheckFor.photo"'s inverse relation */
  Metadata_FileCheckFor?: InputMaybe<PhotoNestedMetadataFilecheckforUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoBitsPerPixel" relation: "MetadataFileInfoBitsPerPixel.photo"'s inverse relation */
  Metadata_FileInfoBitsPerPixel?: InputMaybe<PhotoNestedMetadataFileinfobitsperpixelUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoFrames" relation: "MetadataFileInfoFrames.photo"'s inverse relation */
  Metadata_FileInfoFrames?: InputMaybe<PhotoNestedMetadataFileinfoframesUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoPhotometricInterpretation" relation: "MetadataFileInfoPhotometricInterpretation.photo"'s inverse relation */
  Metadata_FileInfoPhotometricInterpretation?: InputMaybe<PhotoNestedMetadataFileinfophotometricinterpretationUpdateInput>;
  /** Actions for the "Photo.Metadata_FileInfoTransferSyntax" relation: "MetadataFileInfoTransferSyntax.photo"'s inverse relation */
  Metadata_FileInfoTransferSyntax?: InputMaybe<PhotoNestedMetadataFileinfotransfersyntaxUpdateInput>;
  /** Actions for the "Photo.Metadata_FileStage" relation: "MetadataFileStage.photo"'s inverse relation */
  Metadata_FileStage?: InputMaybe<PhotoNestedMetadataFilestageUpdateInput>;
  /** Actions for the "Photo.Metadata_FileStatus" relation: "MetadataFileStatus.photo"'s inverse relation */
  Metadata_FileStatus?: InputMaybe<PhotoNestedMetadataFilestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_FileType" relation: "MetadataFileType.photo"'s inverse relation */
  Metadata_FileType?: InputMaybe<PhotoNestedMetadataFiletypeUpdateInput>;
  /** Actions for the "Photo.Metadata_FixtureIdentifier" relation: "MetadataFixtureIdentifier.photo"'s inverse relation */
  Metadata_FixtureIdentifier?: InputMaybe<PhotoNestedMetadataFixtureidentifierUpdateInput>;
  /** Actions for the "Photo.Metadata_Format" relation: "MetadataFormat.photo"'s inverse relation */
  Metadata_Format?: InputMaybe<PhotoNestedMetadataFormatUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSLatitude" relation: "MetadataGpsLatitude.photo"'s inverse relation */
  Metadata_GPSLatitude?: InputMaybe<PhotoNestedMetadataGpslatitudeUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSLongitude" relation: "MetadataGpsLongitude.photo"'s inverse relation */
  Metadata_GPSLongitude?: InputMaybe<PhotoNestedMetadataGpslongitudeUpdateInput>;
  /** Actions for the "Photo.Metadata_GPSProcessingMethod" relation: "MetadataGpsProcessingMethod.photo"'s inverse relation */
  Metadata_GPSProcessingMethod?: InputMaybe<PhotoNestedMetadataGpsprocessingmethodUpdateInput>;
  /** Actions for the "Photo.Metadata_Header" relation: "MetadataHeader.photo"'s inverse relation */
  Metadata_Header?: InputMaybe<PhotoNestedMetadataHeaderUpdateInput>;
  /** Actions for the "Photo.Metadata_HeaderPrisma" relation: "MetadataHeaderPrisma.photo"'s inverse relation */
  Metadata_HeaderPrisma?: InputMaybe<PhotoNestedMetadataHeaderprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_Headline" relation: "MetadataHeadline.photo"'s inverse relation */
  Metadata_Headline?: InputMaybe<PhotoNestedMetadataHeadlineUpdateInput>;
  /** Actions for the "Photo.Metadata_History" relation: "MetadataHistory.photo"'s inverse relation */
  Metadata_History?: InputMaybe<PhotoNestedMetadataHistoryUpdateInput>;
  /** Actions for the "Photo.Metadata_ICCProfile" relation: "MetadataIccProfile.photo"'s inverse relation */
  Metadata_ICCProfile?: InputMaybe<PhotoNestedMetadataIccprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_ID_subject" relation: "MetadataIdSubject.photo"'s inverse relation */
  Metadata_ID_subject?: InputMaybe<PhotoNestedMetadataIdSubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_IdAssignment" relation: "MetadataIdAssignment.photo"'s inverse relation */
  Metadata_IdAssignment?: InputMaybe<PhotoNestedMetadataIdassignmentUpdateInput>;
  /** Actions for the "Photo.Metadata_IdMediaContent" relation: "MetadataIdMediaContent.photo"'s inverse relation */
  Metadata_IdMediaContent?: InputMaybe<PhotoNestedMetadataIdmediacontentUpdateInput>;
  /** Actions for the "Photo.Metadata_IdPhotoPrisma" relation: "MetadataIdPhotoPrisma.photo"'s inverse relation */
  Metadata_IdPhotoPrisma?: InputMaybe<PhotoNestedMetadataIdphotoprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_ImageNotes" relation: "MetadataImageNotes.photo"'s inverse relation */
  Metadata_ImageNotes?: InputMaybe<PhotoNestedMetadataImagenotesUpdateInput>;
  /** Actions for the "Photo.Metadata_ImageUniqueID" relation: "MetadataImageUniqueId.photo"'s inverse relation */
  Metadata_ImageUniqueID?: InputMaybe<PhotoNestedMetadataImageuniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Instructions" relation: "MetadataInstructions.photo"'s inverse relation */
  Metadata_Instructions?: InputMaybe<PhotoNestedMetadataInstructionsUpdateInput>;
  /** Actions for the "Photo.Metadata_IntellectualGenre" relation: "MetadataIntellectualGenre.photo"'s inverse relation */
  Metadata_IntellectualGenre?: InputMaybe<PhotoNestedMetadataIntellectualgenreUpdateInput>;
  /** Actions for the "Photo.Metadata_ISOSpeedRatings" relation: "MetadataIsoSpeedRatings.photo"'s inverse relation */
  Metadata_ISOSpeedRatings?: InputMaybe<PhotoNestedMetadataIsospeedratingsUpdateInput>;
  /** Actions for the "Photo.Metadata_Issue" relation: "MetadataIssue.photo"'s inverse relation */
  Metadata_Issue?: InputMaybe<PhotoNestedMetadataIssueUpdateInput>;
  /** Actions for the "Photo.Metadata_JobId" relation: "MetadataJobId.photo"'s inverse relation */
  Metadata_JobId?: InputMaybe<PhotoNestedMetadataJobidUpdateInput>;
  /** Actions for the "Photo.Metadata_JobProcessing" relation: "MetadataJobProcessing.photo"'s inverse relation */
  Metadata_JobProcessing?: InputMaybe<PhotoNestedMetadataJobprocessingUpdateInput>;
  /** Actions for the "Photo.Metadata_LayoutPictureEditing" relation: "MetadataLayoutPictureEditing.photo"'s inverse relation */
  Metadata_LayoutPictureEditing?: InputMaybe<PhotoNestedMetadataLayoutpictureeditingUpdateInput>;
  /** Actions for the "Photo.Metadata_LayoutStorage" relation: "MetadataLayoutStorage.photo"'s inverse relation */
  Metadata_LayoutStorage?: InputMaybe<PhotoNestedMetadataLayoutstorageUpdateInput>;
  /** Actions for the "Photo.Metadata_license" relation: "MetadataLicense.photo"'s inverse relation */
  Metadata_license?: InputMaybe<PhotoNestedMetadataLicenseUpdateInput>;
  /** Actions for the "Photo.Metadata_Links" relation: "MetadataLinks.photo"'s inverse relation */
  Metadata_Links?: InputMaybe<PhotoNestedMetadataLinksUpdateInput>;
  /** Actions for the "Photo.Metadata_LocalCaption" relation: "MetadataLocalCaption.photo"'s inverse relation */
  Metadata_LocalCaption?: InputMaybe<PhotoNestedMetadataLocalcaptionUpdateInput>;
  /** Actions for the "Photo.Metadata_Location" relation: "MetadataLocation.photo"'s inverse relation */
  Metadata_Location?: InputMaybe<PhotoNestedMetadataLocationUpdateInput>;
  /** Actions for the "Photo.Metadata_Make" relation: "MetadataMake.photo"'s inverse relation */
  Metadata_Make?: InputMaybe<PhotoNestedMetadataMakeUpdateInput>;
  /** Actions for the "Photo.Metadata_Manifest" relation: "MetadataManifest.photo"'s inverse relation */
  Metadata_Manifest?: InputMaybe<PhotoNestedMetadataManifestUpdateInput>;
  /** Actions for the "Photo.Metadata_Marked" relation: "MetadataMarked.photo"'s inverse relation */
  Metadata_Marked?: InputMaybe<PhotoNestedMetadataMarkedUpdateInput>;
  /** Actions for the "Photo.Metadata_MasterDocumentId" relation: "MetadataMasterDocumentId.photo"'s inverse relation */
  Metadata_MasterDocumentId?: InputMaybe<PhotoNestedMetadataMasterdocumentidUpdateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailHeight" relation: "MetadataMaxAvailHeight.photo"'s inverse relation */
  Metadata_MaxAvailHeight?: InputMaybe<PhotoNestedMetadataMaxavailheightUpdateInput>;
  /** Actions for the "Photo.Metadata_MaxAvailWidth" relation: "MetadataMaxAvailWidth.photo"'s inverse relation */
  Metadata_MaxAvailWidth?: InputMaybe<PhotoNestedMetadataMaxavailwidthUpdateInput>;
  /** Actions for the "Photo.Metadata_MinorModelAgeDisclosure" relation: "MetadataMinorModelAgeDisclosure.photo"'s inverse relation */
  Metadata_MinorModelAgeDisclosure?: InputMaybe<PhotoNestedMetadataMinormodelagedisclosureUpdateInput>;
  /** Actions for the "Photo.Metadata_Model" relation: "MetadataModel.photo"'s inverse relation */
  Metadata_Model?: InputMaybe<PhotoNestedMetadataModelUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelAge" relation: "MetadataModelAge.photo"'s inverse relation */
  Metadata_ModelAge?: InputMaybe<PhotoNestedMetadataModelageUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseID" relation: "MetadataModelReleaseId.photo"'s inverse relation */
  Metadata_ModelReleaseID?: InputMaybe<PhotoNestedMetadataModelreleaseidUpdateInput>;
  /** Actions for the "Photo.Metadata_ModelReleaseStatus" relation: "MetadataModelReleaseStatus.photo"'s inverse relation */
  Metadata_ModelReleaseStatus?: InputMaybe<PhotoNestedMetadataModelreleasestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_morePermissions" relation: "MetadataMorePermissions.photo"'s inverse relation */
  Metadata_morePermissions?: InputMaybe<PhotoNestedMetadataMorepermissionsUpdateInput>;
  /** Actions for the "Photo.Metadata_ObjectCycle" relation: "MetadataObjectCycle.photo"'s inverse relation */
  Metadata_ObjectCycle?: InputMaybe<PhotoNestedMetadataObjectcycleUpdateInput>;
  /** Actions for the "Photo.Metadata_OrganisationInImageName" relation: "MetadataOrganisationInImageName.photo"'s inverse relation */
  Metadata_OrganisationInImageName?: InputMaybe<PhotoNestedMetadataOrganisationinimagenameUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalColor" relation: "MetadataOriginalColor.photo"'s inverse relation */
  Metadata_OriginalColor?: InputMaybe<PhotoNestedMetadataOriginalcolorUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalICCProfile" relation: "MetadataOriginalIccProfile.photo"'s inverse relation */
  Metadata_OriginalICCProfile?: InputMaybe<PhotoNestedMetadataOriginaliccprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalName" relation: "MetadataOriginalName.photo"'s inverse relation */
  Metadata_OriginalName?: InputMaybe<PhotoNestedMetadataOriginalnameUpdateInput>;
  /** Actions for the "Photo.Metadata_OriginalWeigth" relation: "MetadataOriginalWeigth.photo"'s inverse relation */
  Metadata_OriginalWeigth?: InputMaybe<PhotoNestedMetadataOriginalweigthUpdateInput>;
  /** Actions for the "Photo.Metadata_Owner" relation: "MetadataOwner.photo"'s inverse relation */
  Metadata_Owner?: InputMaybe<PhotoNestedMetadataOwnerUpdateInput>;
  /** Actions for the "Photo.Metadata_OwnerId" relation: "MetadataOwnerId.photo"'s inverse relation */
  Metadata_OwnerId?: InputMaybe<PhotoNestedMetadataOwneridUpdateInput>;
  /** Actions for the "Photo.Metadata_Page" relation: "MetadataPage.photo"'s inverse relation */
  Metadata_Page?: InputMaybe<PhotoNestedMetadataPageUpdateInput>;
  /** Actions for the "Photo.Metadata_PagePrisma" relation: "MetadataPagePrisma.photo"'s inverse relation */
  Metadata_PagePrisma?: InputMaybe<PhotoNestedMetadataPageprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_PaintBasedCorrections" relation: "MetadataPaintBasedCorrections.photo"'s inverse relation */
  Metadata_PaintBasedCorrections?: InputMaybe<PhotoNestedMetadataPaintbasedcorrectionsUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientDOB" relation: "MetadataPatientDob.photo"'s inverse relation */
  Metadata_PatientDOB?: InputMaybe<PhotoNestedMetadataPatientdobUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientID" relation: "MetadataPatientId.photo"'s inverse relation */
  Metadata_PatientID?: InputMaybe<PhotoNestedMetadataPatientidUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientName" relation: "MetadataPatientName.photo"'s inverse relation */
  Metadata_PatientName?: InputMaybe<PhotoNestedMetadataPatientnameUpdateInput>;
  /** Actions for the "Photo.Metadata_PatientSex" relation: "MetadataPatientSex.photo"'s inverse relation */
  Metadata_PatientSex?: InputMaybe<PhotoNestedMetadataPatientsexUpdateInput>;
  /** Actions for the "Photo.Metadata_PersonInImage" relation: "MetadataPersonInImage.photo"'s inverse relation */
  Metadata_PersonInImage?: InputMaybe<PhotoNestedMetadataPersoninimageUpdateInput>;
  /** Actions for the "Photo.Metadata_PhotoStorage" relation: "MetadataPhotoStorage.photo"'s inverse relation */
  Metadata_PhotoStorage?: InputMaybe<PhotoNestedMetadataPhotostorageUpdateInput>;
  /** Actions for the "Photo.Metadata_PrepressPictureEditing" relation: "MetadataPrepressPictureEditing.photo"'s inverse relation */
  Metadata_PrepressPictureEditing?: InputMaybe<PhotoNestedMetadataPrepresspictureeditingUpdateInput>;
  /** Actions for the "Photo.Metadata_PriceLevel" relation: "MetadataPriceLevel.photo"'s inverse relation */
  Metadata_PriceLevel?: InputMaybe<PhotoNestedMetadataPricelevelUpdateInput>;
  /** Actions for the "Photo.Metadata_PrintingProfile" relation: "MetadataPrintingProfile.photo"'s inverse relation */
  Metadata_PrintingProfile?: InputMaybe<PhotoNestedMetadataPrintingprofileUpdateInput>;
  /** Actions for the "Photo.Metadata_PrismaProduction" relation: "MetadataPrismaProduction.photo"'s inverse relation */
  Metadata_PrismaProduction?: InputMaybe<PhotoNestedMetadataPrismaproductionUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessHistory" relation: "MetadataProcessHistory.photo"'s inverse relation */
  Metadata_ProcessHistory?: InputMaybe<PhotoNestedMetadataProcesshistoryUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessParameter" relation: "MetadataProcessParameter.photo"'s inverse relation */
  Metadata_ProcessParameter?: InputMaybe<PhotoNestedMetadataProcessparameterUpdateInput>;
  /** Actions for the "Photo.Metadata_ProcessStatus" relation: "MetadataProcessStatus.photo"'s inverse relation */
  Metadata_ProcessStatus?: InputMaybe<PhotoNestedMetadataProcessstatusUpdateInput>;
  /** Actions for the "Photo.Metadata_Product" relation: "MetadataProduct.photo"'s inverse relation */
  Metadata_Product?: InputMaybe<PhotoNestedMetadataProductUpdateInput>;
  /** Actions for the "Photo.Metadata_Products" relation: "MetadataProducts.photo"'s inverse relation */
  Metadata_Products?: InputMaybe<PhotoNestedMetadataProductsUpdateInput>;
  /** Actions for the "Photo.Metadata_ProductShortName" relation: "MetadataProductShortName.photo"'s inverse relation */
  Metadata_ProductShortName?: InputMaybe<PhotoNestedMetadataProductshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_Produits" relation: "MetadataProduits.photo"'s inverse relation */
  Metadata_Produits?: InputMaybe<PhotoNestedMetadataProduitsUpdateInput>;
  /** Actions for the "Photo.Metadata_ProgramVersion" relation: "MetadataProgramVersion.photo"'s inverse relation */
  Metadata_ProgramVersion?: InputMaybe<PhotoNestedMetadataProgramversionUpdateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseID" relation: "MetadataPropertyReleaseId.photo"'s inverse relation */
  Metadata_PropertyReleaseID?: InputMaybe<PhotoNestedMetadataPropertyreleaseidUpdateInput>;
  /** Actions for the "Photo.Metadata_PropertyReleaseStatus" relation: "MetadataPropertyReleaseStatus.photo"'s inverse relation */
  Metadata_PropertyReleaseStatus?: InputMaybe<PhotoNestedMetadataPropertyreleasestatusUpdateInput>;
  /** Actions for the "Photo.Metadata_Publisher" relation: "MetadataPublisher.photo"'s inverse relation */
  Metadata_Publisher?: InputMaybe<PhotoNestedMetadataPublisherUpdateInput>;
  /** Actions for the "Photo.Metadata_publishingIssue" relation: "MetadataPublishingIssue.photo"'s inverse relation */
  Metadata_publishingIssue?: InputMaybe<PhotoNestedMetadataPublishingissueUpdateInput>;
  /** Actions for the "Photo.Metadata_publishingSubject" relation: "MetadataPublishingSubject.photo"'s inverse relation */
  Metadata_publishingSubject?: InputMaybe<PhotoNestedMetadataPublishingsubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaBy" relation: "MetadataQualifiedUsePrismaBy.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaBy?: InputMaybe<PhotoNestedMetadataQualifieduseprismabyUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDate" relation: "MetadataQualifiedUsePrismaDate.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDate?: InputMaybe<PhotoNestedMetadataQualifieduseprismadateUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaDuration" relation: "MetadataQualifiedUsePrismaDuration.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaDuration?: InputMaybe<PhotoNestedMetadataQualifieduseprismadurationUpdateInput>;
  /** Actions for the "Photo.Metadata_QualifiedUsePrismaSupport" relation: "MetadataQualifiedUsePrismaSupport.photo"'s inverse relation */
  Metadata_QualifiedUsePrismaSupport?: InputMaybe<PhotoNestedMetadataQualifieduseprismasupportUpdateInput>;
  /** Actions for the "Photo.Metadata_Rating" relation: "MetadataRating.photo"'s inverse relation */
  Metadata_Rating?: InputMaybe<PhotoNestedMetadataRatingUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceDate" relation: "MetadataReferenceDate.photo"'s inverse relation */
  Metadata_ReferenceDate?: InputMaybe<PhotoNestedMetadataReferencedateUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceNumber" relation: "MetadataReferenceNumber.photo"'s inverse relation */
  Metadata_ReferenceNumber?: InputMaybe<PhotoNestedMetadataReferencenumberUpdateInput>;
  /** Actions for the "Photo.Metadata_ReferenceService" relation: "MetadataReferenceService.photo"'s inverse relation */
  Metadata_ReferenceService?: InputMaybe<PhotoNestedMetadataReferenceserviceUpdateInput>;
  /** Actions for the "Photo.Metadata_ReleaseDate" relation: "MetadataReleaseDate.photo"'s inverse relation */
  Metadata_ReleaseDate?: InputMaybe<PhotoNestedMetadataReleasedateUpdateInput>;
  /** Actions for the "Photo.Metadata_ReleaseTime" relation: "MetadataReleaseTime.photo"'s inverse relation */
  Metadata_ReleaseTime?: InputMaybe<PhotoNestedMetadataReleasetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_RequiredPermission" relation: "MetadataRequiredPermission.photo"'s inverse relation */
  Metadata_RequiredPermission?: InputMaybe<PhotoNestedMetadataRequiredpermissionUpdateInput>;
  /** Actions for the "Photo.Metadata_ResolutionKind" relation: "MetadataResolutionKind.photo"'s inverse relation */
  Metadata_ResolutionKind?: InputMaybe<PhotoNestedMetadataResolutionkindUpdateInput>;
  /** Actions for the "Photo.Metadata_rights" relation: "MetadataRights.photo"'s inverse relation */
  Metadata_rights?: InputMaybe<PhotoNestedMetadataRightsUpdateInput>;
  /** Actions for the "Photo.Metadata_Royaltyfree" relation: "MetadataRoyaltyfree.photo"'s inverse relation */
  Metadata_Royaltyfree?: InputMaybe<PhotoNestedMetadataRoyaltyfreeUpdateInput>;
  /** Actions for the "Photo.Metadata_Scene" relation: "MetadataScene.photo"'s inverse relation */
  Metadata_Scene?: InputMaybe<PhotoNestedMetadataSceneUpdateInput>;
  /** Actions for the "Photo.Metadata_Section" relation: "MetadataSection.photo"'s inverse relation */
  Metadata_Section?: InputMaybe<PhotoNestedMetadataSectionUpdateInput>;
  /** Actions for the "Photo.Metadata_SentTo" relation: "MetadataSentTo.photo"'s inverse relation */
  Metadata_SentTo?: InputMaybe<PhotoNestedMetadataSenttoUpdateInput>;
  /** Actions for the "Photo.Metadata_SerialNumber" relation: "MetadataSerialNumber.photo"'s inverse relation */
  Metadata_SerialNumber?: InputMaybe<PhotoNestedMetadataSerialnumberUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesDateTime" relation: "MetadataSeriesDateTime.photo"'s inverse relation */
  Metadata_SeriesDateTime?: InputMaybe<PhotoNestedMetadataSeriesdatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesDescription" relation: "MetadataSeriesDescription.photo"'s inverse relation */
  Metadata_SeriesDescription?: InputMaybe<PhotoNestedMetadataSeriesdescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesModality" relation: "MetadataSeriesModality.photo"'s inverse relation */
  Metadata_SeriesModality?: InputMaybe<PhotoNestedMetadataSeriesmodalityUpdateInput>;
  /** Actions for the "Photo.Metadata_SeriesNumber" relation: "MetadataSeriesNumber.photo"'s inverse relation */
  Metadata_SeriesNumber?: InputMaybe<PhotoNestedMetadataSeriesnumberUpdateInput>;
  /** Actions for the "Photo.Metadata_ShortUniqueId" relation: "MetadataShortUniqueId.photo"'s inverse relation */
  Metadata_ShortUniqueId?: InputMaybe<PhotoNestedMetadataShortuniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Source" relation: "MetadataSource.photo"'s inverse relation */
  Metadata_Source?: InputMaybe<PhotoNestedMetadataSourceUpdateInput>;
  /** Actions for the "Photo.Metadata_State" relation: "MetadataState.photo"'s inverse relation */
  Metadata_State?: InputMaybe<PhotoNestedMetadataStateUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyDateTime" relation: "MetadataStudyDateTime.photo"'s inverse relation */
  Metadata_StudyDateTime?: InputMaybe<PhotoNestedMetadataStudydatetimeUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyDescription" relation: "MetadataStudyDescription.photo"'s inverse relation */
  Metadata_StudyDescription?: InputMaybe<PhotoNestedMetadataStudydescriptionUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyID" relation: "MetadataStudyId.photo"'s inverse relation */
  Metadata_StudyID?: InputMaybe<PhotoNestedMetadataStudyidUpdateInput>;
  /** Actions for the "Photo.Metadata_StudyPhysician" relation: "MetadataStudyPhysician.photo"'s inverse relation */
  Metadata_StudyPhysician?: InputMaybe<PhotoNestedMetadataStudyphysicianUpdateInput>;
  /** Actions for the "Photo.Metadata_subject" relation: "MetadataSubject.photo"'s inverse relation */
  Metadata_subject?: InputMaybe<PhotoNestedMetadataSubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_SubjectCode" relation: "MetadataSubjectCode.photo"'s inverse relation */
  Metadata_SubjectCode?: InputMaybe<PhotoNestedMetadataSubjectcodeUpdateInput>;
  /** Actions for the "Photo.Metadata_SubjectPrisma" relation: "MetadataSubjectPrisma.photo"'s inverse relation */
  Metadata_SubjectPrisma?: InputMaybe<PhotoNestedMetadataSubjectprismaUpdateInput>;
  /** Actions for the "Photo.Metadata_SupplementalCategories" relation: "MetadataSupplementalCategories.photo"'s inverse relation */
  Metadata_SupplementalCategories?: InputMaybe<PhotoNestedMetadataSupplementalcategoriesUpdateInput>;
  /** Actions for the "Photo.Metadata_TargetVersion" relation: "MetadataTargetVersion.photo"'s inverse relation */
  Metadata_TargetVersion?: InputMaybe<PhotoNestedMetadataTargetversionUpdateInput>;
  /** Actions for the "Photo.Metadata_title" relation: "MetadataTitle.photo"'s inverse relation */
  Metadata_title?: InputMaybe<PhotoNestedMetadataTitleUpdateInput>;
  /** Actions for the "Photo.Metadata_ToneCurve" relation: "MetadataToneCurve.photo"'s inverse relation */
  Metadata_ToneCurve?: InputMaybe<PhotoNestedMetadataTonecurveUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredBy" relation: "MetadataTransferredBy.photo"'s inverse relation */
  Metadata_TransferredBy?: InputMaybe<PhotoNestedMetadataTransferredbyUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredByEmail" relation: "MetadataTransferredByEmail.photo"'s inverse relation */
  Metadata_TransferredByEmail?: InputMaybe<PhotoNestedMetadataTransferredbyemailUpdateInput>;
  /** Actions for the "Photo.Metadata_TransferredByFullName" relation: "MetadataTransferredByFullName.photo"'s inverse relation */
  Metadata_TransferredByFullName?: InputMaybe<PhotoNestedMetadataTransferredbyfullnameUpdateInput>;
  /** Actions for the "Photo.Metadata_TransmissionReference" relation: "MetadataTransmissionReference.photo"'s inverse relation */
  Metadata_TransmissionReference?: InputMaybe<PhotoNestedMetadataTransmissionreferenceUpdateInput>;
  /** Actions for the "Photo.Metadata_Type" relation: "MetadataType.photo"'s inverse relation */
  Metadata_Type?: InputMaybe<PhotoNestedMetadataTypeUpdateInput>;
  /** Actions for the "Photo.Metadata_Undersubject" relation: "MetadataUndersubject.photo"'s inverse relation */
  Metadata_Undersubject?: InputMaybe<PhotoNestedMetadataUndersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_UnderUnderSubject" relation: "MetadataUnderUnderSubject.photo"'s inverse relation */
  Metadata_UnderUnderSubject?: InputMaybe<PhotoNestedMetadataUnderundersubjectUpdateInput>;
  /** Actions for the "Photo.Metadata_UniqueId" relation: "MetadataUniqueId.photo"'s inverse relation */
  Metadata_UniqueId?: InputMaybe<PhotoNestedMetadataUniqueidUpdateInput>;
  /** Actions for the "Photo.Metadata_Unit" relation: "MetadataUnit.photo"'s inverse relation */
  Metadata_Unit?: InputMaybe<PhotoNestedMetadataUnitUpdateInput>;
  /** Actions for the "Photo.Metadata_UnitShortName" relation: "MetadataUnitShortName.photo"'s inverse relation */
  Metadata_UnitShortName?: InputMaybe<PhotoNestedMetadataUnitshortnameUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadedBy" relation: "MetadataUploadedBy.photo"'s inverse relation */
  Metadata_UploadedBy?: InputMaybe<PhotoNestedMetadataUploadedbyUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadedByFullName" relation: "MetadataUploadedByFullName.photo"'s inverse relation */
  Metadata_UploadedByFullName?: InputMaybe<PhotoNestedMetadataUploadedbyfullnameUpdateInput>;
  /** Actions for the "Photo.Metadata_UploadTime" relation: "MetadataUploadTime.photo"'s inverse relation */
  Metadata_UploadTime?: InputMaybe<PhotoNestedMetadataUploadtimeUpdateInput>;
  /** Actions for the "Photo.Metadata_Urgency" relation: "MetadataUrgency.photo"'s inverse relation */
  Metadata_Urgency?: InputMaybe<PhotoNestedMetadataUrgencyUpdateInput>;
  /** Actions for the "Photo.Metadata_UsageTerms" relation: "MetadataUsageTerms.photo"'s inverse relation */
  Metadata_UsageTerms?: InputMaybe<PhotoNestedMetadataUsagetermsUpdateInput>;
  /** Actions for the "Photo.Metadata_UserComment" relation: "MetadataUserComment.photo"'s inverse relation */
  Metadata_UserComment?: InputMaybe<PhotoNestedMetadataUsercommentUpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined195" relation: "MetadataUserDefined195.photo"'s inverse relation */
  Metadata_UserDefined195?: InputMaybe<PhotoNestedMetadataUserdefined195UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined237" relation: "MetadataUserDefined237.photo"'s inverse relation */
  Metadata_UserDefined237?: InputMaybe<PhotoNestedMetadataUserdefined237UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined238" relation: "MetadataUserDefined238.photo"'s inverse relation */
  Metadata_UserDefined238?: InputMaybe<PhotoNestedMetadataUserdefined238UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined239" relation: "MetadataUserDefined239.photo"'s inverse relation */
  Metadata_UserDefined239?: InputMaybe<PhotoNestedMetadataUserdefined239UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined242" relation: "MetadataUserDefined242.photo"'s inverse relation */
  Metadata_UserDefined242?: InputMaybe<PhotoNestedMetadataUserdefined242UpdateInput>;
  /** Actions for the "Photo.Metadata_UserDefined62" relation: "MetadataUserDefined62.photo"'s inverse relation */
  Metadata_UserDefined62?: InputMaybe<PhotoNestedMetadataUserdefined62UpdateInput>;
  /** Actions for the "Photo.Metadata_Valid" relation: "MetadataValid.photo"'s inverse relation */
  Metadata_Valid?: InputMaybe<PhotoNestedMetadataValidUpdateInput>;
  /** Actions for the "Photo.Metadata_Version" relation: "MetadataVersion.photo"'s inverse relation */
  Metadata_Version?: InputMaybe<PhotoNestedMetadataVersionUpdateInput>;
  /** Actions for the "Photo.Metadata_WebStatement" relation: "MetadataWebStatement.photo"'s inverse relation */
  Metadata_WebStatement?: InputMaybe<PhotoNestedMetadataWebstatementUpdateInput>;
  /** Actions for the "Photo.Metadata_WorkflowKind" relation: "MetadataWorkflowKind.photo"'s inverse relation */
  Metadata_WorkflowKind?: InputMaybe<PhotoNestedMetadataWorkflowkindUpdateInput>;
  /** Actions for the "Photo.Metadata_XMPFileStamps" relation: "MetadataXmpFileStamps.photo"'s inverse relation */
  Metadata_XMPFileStamps?: InputMaybe<PhotoNestedMetadataXmpfilestampsUpdateInput>;
  /** Actions for the "Photo.Metadata_XMPHistory" relation: "MetadataXmpHistory.photo"'s inverse relation */
  Metadata_XMPHistory?: InputMaybe<PhotoNestedMetadataXmphistoryUpdateInput>;
  originalName?: InputMaybe<Scalars['String']['input']>;
  /** Actions for the "Photo.photoMetadatas" relation: "PhotoMetadata.photo"'s inverse relation */
  photoMetadatas?: InputMaybe<PhotoNestedPhotoMetadatasUpdateInput>;
  /** Actions for the "Photo.photoPublications" relation: "PhotoPublication.photo"'s inverse relation */
  photoPublications?: InputMaybe<PhotoNestedPhotoPublicationsUpdateInput>;
  synced?: InputMaybe<Scalars['Boolean']['input']>;
  /** The date of the photo's capture */
  takenAt?: InputMaybe<Scalars['DateTime']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type Preview = {
  __typename?: 'Preview';
  href?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** Retrieve a list of "Agency" nodes. */
  agencies: Array<Agency>;
  /** Retrieve a single "Agency" node. */
  agency?: Maybe<Agency>;
  /** Retrieve the number of "Agency" nodes. */
  agencyCount: Scalars['Int']['output'];
  /** Retrieve a single "Archive" node. */
  archive?: Maybe<Archive>;
  /** Retrieve a list of "ArchiveAgency" nodes. */
  archiveAgencies: Array<ArchiveAgency>;
  /** Retrieve a single "ArchiveAgency" node. */
  archiveAgency?: Maybe<ArchiveAgency>;
  /** Retrieve the number of "ArchiveAgency" nodes. */
  archiveAgencyCount: Scalars['Int']['output'];
  /** Retrieve the number of "Archive" nodes. */
  archiveCount: Scalars['Int']['output'];
  /** Retrieve a list of "Archive" nodes. */
  archives: Array<Archive>;
  /** Retrieve a list of "Category" nodes. */
  categories: Array<Category>;
  /** Retrieve a single "Category" node. */
  category?: Maybe<Category>;
  /** Retrieve the number of "Category" nodes. */
  categoryCount: Scalars['Int']['output'];
  /** Retrieve a single "DeliverySubject" node. */
  deliverySubject?: Maybe<DeliverySubject>;
  /** Retrieve the number of "DeliverySubject" nodes. */
  deliverySubjectCount: Scalars['Int']['output'];
  /** Retrieve a list of "DeliverySubject" nodes. */
  deliverySubjects: Array<DeliverySubject>;
  /** Retrieve a single "GcImportSetting" node. */
  gcImportSetting?: Maybe<GcImportSetting>;
  /** Retrieve the number of "GcImportSetting" nodes. */
  gcImportSettingCount: Scalars['Int']['output'];
  /** Retrieve a list of "GcImportSetting" nodes. */
  gcImportSettings: Array<GcImportSetting>;
  /** search metadata by archive, return their occurrence */
  getCenshareRailroad?: Maybe<Scalars['String']['output']>;
  /** search metadata by archive, return their occurrence */
  getMetadataOccurrenceByArchive: Array<MetadataOccurrenceQlType>;
  /** Retrieve a single "ImportQueue" node. */
  importQueue?: Maybe<ImportQueue>;
  /** Retrieve the number of "ImportQueue" nodes. */
  importQueueCount: Scalars['Int']['output'];
  /** Retrieve a list of "ImportQueue" nodes. */
  importQueues: Array<ImportQueue>;
  /** Retrieve a single "Metadata" node. */
  metadata?: Maybe<Metadata>;
  /** Retrieve a single "MetadataAccessRights" node. */
  metadataAccessRights?: Maybe<MetadataAccessRights>;
  /** Retrieve the number of "MetadataAccessRights" nodes. */
  metadataAccessRightsCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataAccessRights" nodes. */
  metadataAccessRightsList: Array<MetadataAccessRights>;
  /** Retrieve a single "MetadataAddlModelInfo" node. */
  metadataAddlModelInfo?: Maybe<MetadataAddlModelInfo>;
  /** Retrieve the number of "MetadataAddlModelInfo" nodes. */
  metadataAddlModelInfoCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataAddlModelInfo" nodes. */
  metadataAddlModelInfoList: Array<MetadataAddlModelInfo>;
  /** Retrieve a single "MetadataArchiveChild" node. */
  metadataArchiveChild?: Maybe<MetadataArchiveChild>;
  /** Retrieve the number of "MetadataArchiveChild" nodes. */
  metadataArchiveChildCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataArchiveChild" nodes. */
  metadataArchiveChildList: Array<MetadataArchiveChild>;
  /** Retrieve a single "MetadataArchiveParent" node. */
  metadataArchiveParent?: Maybe<MetadataArchiveParent>;
  /** Retrieve the number of "MetadataArchiveParent" nodes. */
  metadataArchiveParentCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataArchiveParent" nodes. */
  metadataArchiveParentList: Array<MetadataArchiveParent>;
  /** Retrieve a single "MetadataAssetState" node. */
  metadataAssetState?: Maybe<MetadataAssetState>;
  /** Retrieve the number of "MetadataAssetState" nodes. */
  metadataAssetStateCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataAssetState" nodes. */
  metadataAssetStateList: Array<MetadataAssetState>;
  /** Retrieve a single "MetadataAttributionName" node. */
  metadataAttributionName?: Maybe<MetadataAttributionName>;
  /** Retrieve the number of "MetadataAttributionName" nodes. */
  metadataAttributionNameCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataAttributionName" nodes. */
  metadataAttributionNameList: Array<MetadataAttributionName>;
  /** Retrieve a single "MetadataAttributionUrl" node. */
  metadataAttributionUrl?: Maybe<MetadataAttributionUrl>;
  /** Retrieve the number of "MetadataAttributionUrl" nodes. */
  metadataAttributionUrlCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataAttributionUrl" nodes. */
  metadataAttributionUrlList: Array<MetadataAttributionUrl>;
  /** Retrieve a single "MetadataAudience" node. */
  metadataAudience?: Maybe<MetadataAudience>;
  /** Retrieve the number of "MetadataAudience" nodes. */
  metadataAudienceCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataAudience" nodes. */
  metadataAudienceList: Array<MetadataAudience>;
  /** Retrieve a single "MetadataAuthorsPosition" node. */
  metadataAuthorsPosition?: Maybe<MetadataAuthorsPosition>;
  /** Retrieve the number of "MetadataAuthorsPosition" nodes. */
  metadataAuthorsPositionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataAuthorsPosition" nodes. */
  metadataAuthorsPositionList: Array<MetadataAuthorsPosition>;
  /** Retrieve a single "MetadataBackupName" node. */
  metadataBackupName?: Maybe<MetadataBackupName>;
  /** Retrieve the number of "MetadataBackupName" nodes. */
  metadataBackupNameCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataBackupName" nodes. */
  metadataBackupNameList: Array<MetadataBackupName>;
  /** Retrieve a single "MetadataBitmapGrayscalePicture" node. */
  metadataBitmapGrayscalePicture?: Maybe<MetadataBitmapGrayscalePicture>;
  /** Retrieve the number of "MetadataBitmapGrayscalePicture" nodes. */
  metadataBitmapGrayscalePictureCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataBitmapGrayscalePicture" nodes. */
  metadataBitmapGrayscalePictureList: Array<MetadataBitmapGrayscalePicture>;
  /** Retrieve a single "MetadataBrand" node. */
  metadataBrand?: Maybe<MetadataBrand>;
  /** Retrieve the number of "MetadataBrand" nodes. */
  metadataBrandCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataBrand" nodes. */
  metadataBrandList: Array<MetadataBrand>;
  /** Retrieve a single "MetadataBrandPrisma" node. */
  metadataBrandPrisma?: Maybe<MetadataBrandPrisma>;
  /** Retrieve the number of "MetadataBrandPrisma" nodes. */
  metadataBrandPrismaCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataBrandPrisma" nodes. */
  metadataBrandPrismaList: Array<MetadataBrandPrisma>;
  /** Retrieve a single "MetadataBrandShortName" node. */
  metadataBrandShortName?: Maybe<MetadataBrandShortName>;
  /** Retrieve the number of "MetadataBrandShortName" nodes. */
  metadataBrandShortNameCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataBrandShortName" nodes. */
  metadataBrandShortNameList: Array<MetadataBrandShortName>;
  /** Retrieve a single "MetadataCaptionWriter" node. */
  metadataCaptionWriter?: Maybe<MetadataCaptionWriter>;
  /** Retrieve the number of "MetadataCaptionWriter" nodes. */
  metadataCaptionWriterCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCaptionWriter" nodes. */
  metadataCaptionWriterList: Array<MetadataCaptionWriter>;
  /** Retrieve a single "MetadataCategory" node. */
  metadataCategory?: Maybe<MetadataCategory>;
  /** Retrieve the number of "MetadataCategory" nodes. */
  metadataCategoryCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCategory" nodes. */
  metadataCategoryList: Array<MetadataCategory>;
  /** Retrieve a single "MetadataCategoryPrisma" node. */
  metadataCategoryPrisma?: Maybe<MetadataCategoryPrisma>;
  /** Retrieve the number of "MetadataCategoryPrisma" nodes. */
  metadataCategoryPrismaCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCategoryPrisma" nodes. */
  metadataCategoryPrismaList: Array<MetadataCategoryPrisma>;
  /** Retrieve a single "MetadataCertificate" node. */
  metadataCertificate?: Maybe<MetadataCertificate>;
  /** Retrieve the number of "MetadataCertificate" nodes. */
  metadataCertificateCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCertificate" nodes. */
  metadataCertificateList: Array<MetadataCertificate>;
  /** Retrieve a single "MetadataCiAdrCity" node. */
  metadataCiAdrCity?: Maybe<MetadataCiAdrCity>;
  /** Retrieve the number of "MetadataCiAdrCity" nodes. */
  metadataCiAdrCityCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCiAdrCity" nodes. */
  metadataCiAdrCityList: Array<MetadataCiAdrCity>;
  /** Retrieve a single "MetadataCiAdrCtry" node. */
  metadataCiAdrCtry?: Maybe<MetadataCiAdrCtry>;
  /** Retrieve the number of "MetadataCiAdrCtry" nodes. */
  metadataCiAdrCtryCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCiAdrCtry" nodes. */
  metadataCiAdrCtryList: Array<MetadataCiAdrCtry>;
  /** Retrieve a single "MetadataCiAdrExtadr" node. */
  metadataCiAdrExtadr?: Maybe<MetadataCiAdrExtadr>;
  /** Retrieve the number of "MetadataCiAdrExtadr" nodes. */
  metadataCiAdrExtadrCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCiAdrExtadr" nodes. */
  metadataCiAdrExtadrList: Array<MetadataCiAdrExtadr>;
  /** Retrieve a single "MetadataCiAdrPcode" node. */
  metadataCiAdrPcode?: Maybe<MetadataCiAdrPcode>;
  /** Retrieve the number of "MetadataCiAdrPcode" nodes. */
  metadataCiAdrPcodeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCiAdrPcode" nodes. */
  metadataCiAdrPcodeList: Array<MetadataCiAdrPcode>;
  /** Retrieve a single "MetadataCiAdrRegion" node. */
  metadataCiAdrRegion?: Maybe<MetadataCiAdrRegion>;
  /** Retrieve the number of "MetadataCiAdrRegion" nodes. */
  metadataCiAdrRegionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCiAdrRegion" nodes. */
  metadataCiAdrRegionList: Array<MetadataCiAdrRegion>;
  /** Retrieve a single "MetadataCiEmailWork" node. */
  metadataCiEmailWork?: Maybe<MetadataCiEmailWork>;
  /** Retrieve the number of "MetadataCiEmailWork" nodes. */
  metadataCiEmailWorkCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCiEmailWork" nodes. */
  metadataCiEmailWorkList: Array<MetadataCiEmailWork>;
  /** Retrieve a single "MetadataCiTelWork" node. */
  metadataCiTelWork?: Maybe<MetadataCiTelWork>;
  /** Retrieve the number of "MetadataCiTelWork" nodes. */
  metadataCiTelWorkCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCiTelWork" nodes. */
  metadataCiTelWorkList: Array<MetadataCiTelWork>;
  /** Retrieve a single "MetadataCity" node. */
  metadataCity?: Maybe<MetadataCity>;
  /** Retrieve the number of "MetadataCity" nodes. */
  metadataCityCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCity" nodes. */
  metadataCityList: Array<MetadataCity>;
  /** Retrieve a single "MetadataCiUrlWork" node. */
  metadataCiUrlWork?: Maybe<MetadataCiUrlWork>;
  /** Retrieve the number of "MetadataCiUrlWork" nodes. */
  metadataCiUrlWorkCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCiUrlWork" nodes. */
  metadataCiUrlWorkList: Array<MetadataCiUrlWork>;
  /** Retrieve a single "MetadataClassify" node. */
  metadataClassify?: Maybe<MetadataClassify>;
  /** Retrieve the number of "MetadataClassify" nodes. */
  metadataClassifyCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataClassify" nodes. */
  metadataClassifyList: Array<MetadataClassify>;
  /** Retrieve a single "MetadataCompany" node. */
  metadataCompany?: Maybe<MetadataCompany>;
  /** Retrieve the number of "MetadataCompany" nodes. */
  metadataCompanyCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCompany" nodes. */
  metadataCompanyList: Array<MetadataCompany>;
  /** Retrieve a single "MetadataCompanyShortName" node. */
  metadataCompanyShortName?: Maybe<MetadataCompanyShortName>;
  /** Retrieve the number of "MetadataCompanyShortName" nodes. */
  metadataCompanyShortNameCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCompanyShortName" nodes. */
  metadataCompanyShortNameList: Array<MetadataCompanyShortName>;
  /** Retrieve a single "MetadataConfidentiality" node. */
  metadataConfidentiality?: Maybe<MetadataConfidentiality>;
  /** Retrieve the number of "MetadataConfidentiality" nodes. */
  metadataConfidentialityCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataConfidentiality" nodes. */
  metadataConfidentialityList: Array<MetadataConfidentiality>;
  /** Retrieve a single "MetadataContainedIn" node. */
  metadataContainedIn?: Maybe<MetadataContainedIn>;
  /** Retrieve the number of "MetadataContainedIn" nodes. */
  metadataContainedInCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataContainedIn" nodes. */
  metadataContainedInList: Array<MetadataContainedIn>;
  /** Retrieve a single "MetadataContentMediaKind" node. */
  metadataContentMediaKind?: Maybe<MetadataContentMediaKind>;
  /** Retrieve the number of "MetadataContentMediaKind" nodes. */
  metadataContentMediaKindCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataContentMediaKind" nodes. */
  metadataContentMediaKindList: Array<MetadataContentMediaKind>;
  /** Retrieve a single "MetadataContentValue" node. */
  metadataContentValue?: Maybe<MetadataContentValue>;
  /** Retrieve the number of "MetadataContentValue" nodes. */
  metadataContentValueCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataContentValue" nodes. */
  metadataContentValueList: Array<MetadataContentValue>;
  /** Retrieve a single "MetadataCopyrightLayer" node. */
  metadataCopyrightLayer?: Maybe<MetadataCopyrightLayer>;
  /** Retrieve the number of "MetadataCopyrightLayer" nodes. */
  metadataCopyrightLayerCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCopyrightLayer" nodes. */
  metadataCopyrightLayerList: Array<MetadataCopyrightLayer>;
  /** Retrieve the number of "Metadata" nodes. */
  metadataCount: Scalars['Int']['output'];
  /** Retrieve a single "MetadataCountry" node. */
  metadataCountry?: Maybe<MetadataCountry>;
  /** Retrieve a single "MetadataCountryCode" node. */
  metadataCountryCode?: Maybe<MetadataCountryCode>;
  /** Retrieve the number of "MetadataCountryCode" nodes. */
  metadataCountryCodeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCountryCode" nodes. */
  metadataCountryCodeList: Array<MetadataCountryCode>;
  /** Retrieve the number of "MetadataCountry" nodes. */
  metadataCountryCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCountry" nodes. */
  metadataCountryList: Array<MetadataCountry>;
  /** Retrieve a single "MetadataCreatedTime" node. */
  metadataCreatedTime?: Maybe<MetadataCreatedTime>;
  /** Retrieve the number of "MetadataCreatedTime" nodes. */
  metadataCreatedTimeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCreatedTime" nodes. */
  metadataCreatedTimeList: Array<MetadataCreatedTime>;
  /** Retrieve a single "MetadataCreator" node. */
  metadataCreator?: Maybe<MetadataCreator>;
  /** Retrieve the number of "MetadataCreator" nodes. */
  metadataCreatorCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCreator" nodes. */
  metadataCreatorList: Array<MetadataCreator>;
  /** Retrieve a single "MetadataCreatorTool" node. */
  metadataCreatorTool?: Maybe<MetadataCreatorTool>;
  /** Retrieve the number of "MetadataCreatorTool" nodes. */
  metadataCreatorToolCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCreatorTool" nodes. */
  metadataCreatorToolList: Array<MetadataCreatorTool>;
  /** Retrieve a single "MetadataCredit" node. */
  metadataCredit?: Maybe<MetadataCredit>;
  /** Retrieve the number of "MetadataCredit" nodes. */
  metadataCreditCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCredit" nodes. */
  metadataCreditList: Array<MetadataCredit>;
  /** Retrieve a single "MetadataCustomField14" node. */
  metadataCustomField14?: Maybe<MetadataCustomField14>;
  /** Retrieve the number of "MetadataCustomField14" nodes. */
  metadataCustomField14Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCustomField14" nodes. */
  metadataCustomField14List: Array<MetadataCustomField14>;
  /** Retrieve a single "MetadataCustomField15" node. */
  metadataCustomField15?: Maybe<MetadataCustomField15>;
  /** Retrieve the number of "MetadataCustomField15" nodes. */
  metadataCustomField15Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCustomField15" nodes. */
  metadataCustomField15List: Array<MetadataCustomField15>;
  /** Retrieve a single "MetadataCustomField16" node. */
  metadataCustomField16?: Maybe<MetadataCustomField16>;
  /** Retrieve the number of "MetadataCustomField16" nodes. */
  metadataCustomField16Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCustomField16" nodes. */
  metadataCustomField16List: Array<MetadataCustomField16>;
  /** Retrieve a single "MetadataCustomField17" node. */
  metadataCustomField17?: Maybe<MetadataCustomField17>;
  /** Retrieve the number of "MetadataCustomField17" nodes. */
  metadataCustomField17Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCustomField17" nodes. */
  metadataCustomField17List: Array<MetadataCustomField17>;
  /** Retrieve a single "MetadataCustomField20" node. */
  metadataCustomField20?: Maybe<MetadataCustomField20>;
  /** Retrieve the number of "MetadataCustomField20" nodes. */
  metadataCustomField20Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCustomField20" nodes. */
  metadataCustomField20List: Array<MetadataCustomField20>;
  /** Retrieve a single "MetadataCustomField5" node. */
  metadataCustomField5?: Maybe<MetadataCustomField5>;
  /** Retrieve the number of "MetadataCustomField5" nodes. */
  metadataCustomField5Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCustomField5" nodes. */
  metadataCustomField5List: Array<MetadataCustomField5>;
  /** Retrieve a single "MetadataCustomField6" node. */
  metadataCustomField6?: Maybe<MetadataCustomField6>;
  /** Retrieve the number of "MetadataCustomField6" nodes. */
  metadataCustomField6Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCustomField6" nodes. */
  metadataCustomField6List: Array<MetadataCustomField6>;
  /** Retrieve a single "MetadataCustomField7" node. */
  metadataCustomField7?: Maybe<MetadataCustomField7>;
  /** Retrieve the number of "MetadataCustomField7" nodes. */
  metadataCustomField7Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCustomField7" nodes. */
  metadataCustomField7List: Array<MetadataCustomField7>;
  /** Retrieve a single "MetadataCustomField9" node. */
  metadataCustomField9?: Maybe<MetadataCustomField9>;
  /** Retrieve the number of "MetadataCustomField9" nodes. */
  metadataCustomField9Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataCustomField9" nodes. */
  metadataCustomField9List: Array<MetadataCustomField9>;
  /** Retrieve a single "MetadataDateCreated" node. */
  metadataDateCreated?: Maybe<MetadataDateCreated>;
  /** Retrieve the number of "MetadataDateCreated" nodes. */
  metadataDateCreatedCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDateCreated" nodes. */
  metadataDateCreatedList: Array<MetadataDateCreated>;
  /** Retrieve a single "MetadataDateTimeDigitized" node. */
  metadataDateTimeDigitized?: Maybe<MetadataDateTimeDigitized>;
  /** Retrieve the number of "MetadataDateTimeDigitized" nodes. */
  metadataDateTimeDigitizedCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDateTimeDigitized" nodes. */
  metadataDateTimeDigitizedList: Array<MetadataDateTimeDigitized>;
  /** Retrieve a single "MetadataDateTimeOriginal" node. */
  metadataDateTimeOriginal?: Maybe<MetadataDateTimeOriginal>;
  /** Retrieve the number of "MetadataDateTimeOriginal" nodes. */
  metadataDateTimeOriginalCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDateTimeOriginal" nodes. */
  metadataDateTimeOriginalList: Array<MetadataDateTimeOriginal>;
  /** Retrieve a single "MetadataDeliveryAccount" node. */
  metadataDeliveryAccount?: Maybe<MetadataDeliveryAccount>;
  /** Retrieve the number of "MetadataDeliveryAccount" nodes. */
  metadataDeliveryAccountCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryAccount" nodes. */
  metadataDeliveryAccountList: Array<MetadataDeliveryAccount>;
  /** Retrieve a single "MetadataDeliveryCompany" node. */
  metadataDeliveryCompany?: Maybe<MetadataDeliveryCompany>;
  /** Retrieve the number of "MetadataDeliveryCompany" nodes. */
  metadataDeliveryCompanyCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryCompany" nodes. */
  metadataDeliveryCompanyList: Array<MetadataDeliveryCompany>;
  /** Retrieve a single "MetadataDeliveryCopyright" node. */
  metadataDeliveryCopyright?: Maybe<MetadataDeliveryCopyright>;
  /** Retrieve the number of "MetadataDeliveryCopyright" nodes. */
  metadataDeliveryCopyrightCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryCopyright" nodes. */
  metadataDeliveryCopyrightList: Array<MetadataDeliveryCopyright>;
  /** Retrieve a single "MetadataDeliveryDateFolder" node. */
  metadataDeliveryDateFolder?: Maybe<MetadataDeliveryDateFolder>;
  /** Retrieve the number of "MetadataDeliveryDateFolder" nodes. */
  metadataDeliveryDateFolderCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryDateFolder" nodes. */
  metadataDeliveryDateFolderList: Array<MetadataDeliveryDateFolder>;
  /** Retrieve a single "MetadataDeliveryDateTime" node. */
  metadataDeliveryDateTime?: Maybe<MetadataDeliveryDateTime>;
  /** Retrieve the number of "MetadataDeliveryDateTime" nodes. */
  metadataDeliveryDateTimeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryDateTime" nodes. */
  metadataDeliveryDateTimeList: Array<MetadataDeliveryDateTime>;
  /** Retrieve a single "MetadataDeliveryDcCreator" node. */
  metadataDeliveryDcCreator?: Maybe<MetadataDeliveryDcCreator>;
  /** Retrieve the number of "MetadataDeliveryDcCreator" nodes. */
  metadataDeliveryDcCreatorCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryDcCreator" nodes. */
  metadataDeliveryDcCreatorList: Array<MetadataDeliveryDcCreator>;
  /** Retrieve a single "MetadataDeliveryDcRights" node. */
  metadataDeliveryDcRights?: Maybe<MetadataDeliveryDcRights>;
  /** Retrieve the number of "MetadataDeliveryDcRights" nodes. */
  metadataDeliveryDcRightsCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryDcRights" nodes. */
  metadataDeliveryDcRightsList: Array<MetadataDeliveryDcRights>;
  /** Retrieve a single "MetadataDeliveryFileType" node. */
  metadataDeliveryFileType?: Maybe<MetadataDeliveryFileType>;
  /** Retrieve the number of "MetadataDeliveryFileType" nodes. */
  metadataDeliveryFileTypeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryFileType" nodes. */
  metadataDeliveryFileTypeList: Array<MetadataDeliveryFileType>;
  /** Retrieve a single "MetadataDeliveryFolder" node. */
  metadataDeliveryFolder?: Maybe<MetadataDeliveryFolder>;
  /** Retrieve the number of "MetadataDeliveryFolder" nodes. */
  metadataDeliveryFolderCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryFolder" nodes. */
  metadataDeliveryFolderList: Array<MetadataDeliveryFolder>;
  /** Retrieve a single "MetadataDeliveryKind" node. */
  metadataDeliveryKind?: Maybe<MetadataDeliveryKind>;
  /** Retrieve the number of "MetadataDeliveryKind" nodes. */
  metadataDeliveryKindCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryKind" nodes. */
  metadataDeliveryKindList: Array<MetadataDeliveryKind>;
  /** Retrieve a single "MetadataDeliveryPath" node. */
  metadataDeliveryPath?: Maybe<MetadataDeliveryPath>;
  /** Retrieve the number of "MetadataDeliveryPath" nodes. */
  metadataDeliveryPathCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryPath" nodes. */
  metadataDeliveryPathList: Array<MetadataDeliveryPath>;
  /** Retrieve a single "MetadataDeliveryPersonShownintheImage" node. */
  metadataDeliveryPersonShownintheImage?: Maybe<MetadataDeliveryPersonShownintheImage>;
  /** Retrieve the number of "MetadataDeliveryPersonShownintheImage" nodes. */
  metadataDeliveryPersonShownintheImageCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryPersonShownintheImage" nodes. */
  metadataDeliveryPersonShownintheImageList: Array<MetadataDeliveryPersonShownintheImage>;
  /** Retrieve a single "MetadataDeliveryPhotoshopCredit" node. */
  metadataDeliveryPhotoshopCredit?: Maybe<MetadataDeliveryPhotoshopCredit>;
  /** Retrieve the number of "MetadataDeliveryPhotoshopCredit" nodes. */
  metadataDeliveryPhotoshopCreditCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryPhotoshopCredit" nodes. */
  metadataDeliveryPhotoshopCreditList: Array<MetadataDeliveryPhotoshopCredit>;
  /** Retrieve a single "MetadataDeliveryPhotoshopSource" node. */
  metadataDeliveryPhotoshopSource?: Maybe<MetadataDeliveryPhotoshopSource>;
  /** Retrieve the number of "MetadataDeliveryPhotoshopSource" nodes. */
  metadataDeliveryPhotoshopSourceCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryPhotoshopSource" nodes. */
  metadataDeliveryPhotoshopSourceList: Array<MetadataDeliveryPhotoshopSource>;
  /** Retrieve a single "MetadataDeliveryService" node. */
  metadataDeliveryService?: Maybe<MetadataDeliveryService>;
  /** Retrieve the number of "MetadataDeliveryService" nodes. */
  metadataDeliveryServiceCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryService" nodes. */
  metadataDeliveryServiceList: Array<MetadataDeliveryService>;
  /** Retrieve a single "MetadataDeliverySubject" node. */
  metadataDeliverySubject?: Maybe<MetadataDeliverySubject>;
  /** Retrieve the number of "MetadataDeliverySubject" nodes. */
  metadataDeliverySubjectCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliverySubject" nodes. */
  metadataDeliverySubjectList: Array<MetadataDeliverySubject>;
  /** Retrieve a single "MetadataDeliveryUnderSubject" node. */
  metadataDeliveryUnderSubject?: Maybe<MetadataDeliveryUnderSubject>;
  /** Retrieve the number of "MetadataDeliveryUnderSubject" nodes. */
  metadataDeliveryUnderSubjectCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDeliveryUnderSubject" nodes. */
  metadataDeliveryUnderSubjectList: Array<MetadataDeliveryUnderSubject>;
  /** Retrieve a single "MetadataDepartment" node. */
  metadataDepartment?: Maybe<MetadataDepartment>;
  /** Retrieve the number of "MetadataDepartment" nodes. */
  metadataDepartmentCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDepartment" nodes. */
  metadataDepartmentList: Array<MetadataDepartment>;
  /** Retrieve a single "MetadataDescription" node. */
  metadataDescription?: Maybe<MetadataDescription>;
  /** Retrieve the number of "MetadataDescription" nodes. */
  metadataDescriptionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDescription" nodes. */
  metadataDescriptionList: Array<MetadataDescription>;
  /** Retrieve a single "MetadataDigitalAssetUrl" node. */
  metadataDigitalAssetUrl?: Maybe<MetadataDigitalAssetUrl>;
  /** Retrieve the number of "MetadataDigitalAssetUrl" nodes. */
  metadataDigitalAssetUrlCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDigitalAssetUrl" nodes. */
  metadataDigitalAssetUrlList: Array<MetadataDigitalAssetUrl>;
  /** Retrieve a single "MetadataDivision" node. */
  metadataDivision?: Maybe<MetadataDivision>;
  /** Retrieve the number of "MetadataDivision" nodes. */
  metadataDivisionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDivision" nodes. */
  metadataDivisionList: Array<MetadataDivision>;
  /** Retrieve a single "MetadataDocumentText" node. */
  metadataDocumentText?: Maybe<MetadataDocumentText>;
  /** Retrieve the number of "MetadataDocumentText" nodes. */
  metadataDocumentTextCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataDocumentText" nodes. */
  metadataDocumentTextList: Array<MetadataDocumentText>;
  /** Retrieve a single "MetadataEdition" node. */
  metadataEdition?: Maybe<MetadataEdition>;
  /** Retrieve the number of "MetadataEdition" nodes. */
  metadataEditionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataEdition" nodes. */
  metadataEditionList: Array<MetadataEdition>;
  /** Retrieve a single "MetadataEditorialVersion" node. */
  metadataEditorialVersion?: Maybe<MetadataEditorialVersion>;
  /** Retrieve the number of "MetadataEditorialVersion" nodes. */
  metadataEditorialVersionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataEditorialVersion" nodes. */
  metadataEditorialVersionList: Array<MetadataEditorialVersion>;
  /** Retrieve a single "MetadataEditStatus" node. */
  metadataEditStatus?: Maybe<MetadataEditStatus>;
  /** Retrieve the number of "MetadataEditStatus" nodes. */
  metadataEditStatusCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataEditStatus" nodes. */
  metadataEditStatusList: Array<MetadataEditStatus>;
  /** Retrieve a single "MetadataEnvironnementPhoto" node. */
  metadataEnvironnementPhoto?: Maybe<MetadataEnvironnementPhoto>;
  /** Retrieve the number of "MetadataEnvironnementPhoto" nodes. */
  metadataEnvironnementPhotoCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataEnvironnementPhoto" nodes. */
  metadataEnvironnementPhotoList: Array<MetadataEnvironnementPhoto>;
  /** Retrieve a single "MetadataEquipmentInstitution" node. */
  metadataEquipmentInstitution?: Maybe<MetadataEquipmentInstitution>;
  /** Retrieve the number of "MetadataEquipmentInstitution" nodes. */
  metadataEquipmentInstitutionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataEquipmentInstitution" nodes. */
  metadataEquipmentInstitutionList: Array<MetadataEquipmentInstitution>;
  /** Retrieve a single "MetadataEquipmentManufacturer" node. */
  metadataEquipmentManufacturer?: Maybe<MetadataEquipmentManufacturer>;
  /** Retrieve the number of "MetadataEquipmentManufacturer" nodes. */
  metadataEquipmentManufacturerCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataEquipmentManufacturer" nodes. */
  metadataEquipmentManufacturerList: Array<MetadataEquipmentManufacturer>;
  /** Retrieve a single "MetadataEvent" node. */
  metadataEvent?: Maybe<MetadataEvent>;
  /** Retrieve the number of "MetadataEvent" nodes. */
  metadataEventCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataEvent" nodes. */
  metadataEventList: Array<MetadataEvent>;
  /** Retrieve a single "MetadataExif" node. */
  metadataExif?: Maybe<MetadataExif>;
  /** Retrieve the number of "MetadataExif" nodes. */
  metadataExifCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataExif" nodes. */
  metadataExifList: Array<MetadataExif>;
  /** Retrieve a single "MetadataFabStorage" node. */
  metadataFabStorage?: Maybe<MetadataFabStorage>;
  /** Retrieve the number of "MetadataFabStorage" nodes. */
  metadataFabStorageCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataFabStorage" nodes. */
  metadataFabStorageList: Array<MetadataFabStorage>;
  /** Retrieve a single "MetadataFileCheckFor" node. */
  metadataFileCheckFor?: Maybe<MetadataFileCheckFor>;
  /** Retrieve the number of "MetadataFileCheckFor" nodes. */
  metadataFileCheckForCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataFileCheckFor" nodes. */
  metadataFileCheckForList: Array<MetadataFileCheckFor>;
  /** Retrieve a single "MetadataFileInfoBitsPerPixel" node. */
  metadataFileInfoBitsPerPixel?: Maybe<MetadataFileInfoBitsPerPixel>;
  /** Retrieve the number of "MetadataFileInfoBitsPerPixel" nodes. */
  metadataFileInfoBitsPerPixelCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataFileInfoBitsPerPixel" nodes. */
  metadataFileInfoBitsPerPixelList: Array<MetadataFileInfoBitsPerPixel>;
  /** Retrieve a single "MetadataFileInfoFrames" node. */
  metadataFileInfoFrames?: Maybe<MetadataFileInfoFrames>;
  /** Retrieve the number of "MetadataFileInfoFrames" nodes. */
  metadataFileInfoFramesCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataFileInfoFrames" nodes. */
  metadataFileInfoFramesList: Array<MetadataFileInfoFrames>;
  /** Retrieve a single "MetadataFileInfoPhotometricInterpretation" node. */
  metadataFileInfoPhotometricInterpretation?: Maybe<MetadataFileInfoPhotometricInterpretation>;
  /** Retrieve the number of "MetadataFileInfoPhotometricInterpretation" nodes. */
  metadataFileInfoPhotometricInterpretationCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataFileInfoPhotometricInterpretation" nodes. */
  metadataFileInfoPhotometricInterpretationList: Array<MetadataFileInfoPhotometricInterpretation>;
  /** Retrieve a single "MetadataFileInfoTransferSyntax" node. */
  metadataFileInfoTransferSyntax?: Maybe<MetadataFileInfoTransferSyntax>;
  /** Retrieve the number of "MetadataFileInfoTransferSyntax" nodes. */
  metadataFileInfoTransferSyntaxCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataFileInfoTransferSyntax" nodes. */
  metadataFileInfoTransferSyntaxList: Array<MetadataFileInfoTransferSyntax>;
  /** Retrieve a single "MetadataFileStage" node. */
  metadataFileStage?: Maybe<MetadataFileStage>;
  /** Retrieve the number of "MetadataFileStage" nodes. */
  metadataFileStageCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataFileStage" nodes. */
  metadataFileStageList: Array<MetadataFileStage>;
  /** Retrieve a single "MetadataFileStatus" node. */
  metadataFileStatus?: Maybe<MetadataFileStatus>;
  /** Retrieve the number of "MetadataFileStatus" nodes. */
  metadataFileStatusCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataFileStatus" nodes. */
  metadataFileStatusList: Array<MetadataFileStatus>;
  /** Retrieve a single "MetadataFileType" node. */
  metadataFileType?: Maybe<MetadataFileType>;
  /** Retrieve the number of "MetadataFileType" nodes. */
  metadataFileTypeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataFileType" nodes. */
  metadataFileTypeList: Array<MetadataFileType>;
  /** Retrieve a single "MetadataFixtureIdentifier" node. */
  metadataFixtureIdentifier?: Maybe<MetadataFixtureIdentifier>;
  /** Retrieve the number of "MetadataFixtureIdentifier" nodes. */
  metadataFixtureIdentifierCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataFixtureIdentifier" nodes. */
  metadataFixtureIdentifierList: Array<MetadataFixtureIdentifier>;
  /** Retrieve a single "MetadataFormat" node. */
  metadataFormat?: Maybe<MetadataFormat>;
  /** Retrieve the number of "MetadataFormat" nodes. */
  metadataFormatCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataFormat" nodes. */
  metadataFormatList: Array<MetadataFormat>;
  /** Retrieve a single "MetadataGpsLatitude" node. */
  metadataGpsLatitude?: Maybe<MetadataGpsLatitude>;
  /** Retrieve the number of "MetadataGpsLatitude" nodes. */
  metadataGpsLatitudeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataGpsLatitude" nodes. */
  metadataGpsLatitudeList: Array<MetadataGpsLatitude>;
  /** Retrieve a single "MetadataGpsLongitude" node. */
  metadataGpsLongitude?: Maybe<MetadataGpsLongitude>;
  /** Retrieve the number of "MetadataGpsLongitude" nodes. */
  metadataGpsLongitudeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataGpsLongitude" nodes. */
  metadataGpsLongitudeList: Array<MetadataGpsLongitude>;
  /** Retrieve a single "MetadataGpsProcessingMethod" node. */
  metadataGpsProcessingMethod?: Maybe<MetadataGpsProcessingMethod>;
  /** Retrieve the number of "MetadataGpsProcessingMethod" nodes. */
  metadataGpsProcessingMethodCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataGpsProcessingMethod" nodes. */
  metadataGpsProcessingMethodList: Array<MetadataGpsProcessingMethod>;
  /** Retrieve a single "MetadataHeader" node. */
  metadataHeader?: Maybe<MetadataHeader>;
  /** Retrieve the number of "MetadataHeader" nodes. */
  metadataHeaderCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataHeader" nodes. */
  metadataHeaderList: Array<MetadataHeader>;
  /** Retrieve a single "MetadataHeaderPrisma" node. */
  metadataHeaderPrisma?: Maybe<MetadataHeaderPrisma>;
  /** Retrieve the number of "MetadataHeaderPrisma" nodes. */
  metadataHeaderPrismaCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataHeaderPrisma" nodes. */
  metadataHeaderPrismaList: Array<MetadataHeaderPrisma>;
  /** Retrieve a single "MetadataHeadline" node. */
  metadataHeadline?: Maybe<MetadataHeadline>;
  /** Retrieve the number of "MetadataHeadline" nodes. */
  metadataHeadlineCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataHeadline" nodes. */
  metadataHeadlineList: Array<MetadataHeadline>;
  /** Retrieve a single "MetadataHistory" node. */
  metadataHistory?: Maybe<MetadataHistory>;
  /** Retrieve the number of "MetadataHistory" nodes. */
  metadataHistoryCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataHistory" nodes. */
  metadataHistoryList: Array<MetadataHistory>;
  /** Retrieve a single "MetadataIccProfile" node. */
  metadataIccProfile?: Maybe<MetadataIccProfile>;
  /** Retrieve the number of "MetadataIccProfile" nodes. */
  metadataIccProfileCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataIccProfile" nodes. */
  metadataIccProfileList: Array<MetadataIccProfile>;
  /** Retrieve a single "MetadataIdAssignment" node. */
  metadataIdAssignment?: Maybe<MetadataIdAssignment>;
  /** Retrieve the number of "MetadataIdAssignment" nodes. */
  metadataIdAssignmentCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataIdAssignment" nodes. */
  metadataIdAssignmentList: Array<MetadataIdAssignment>;
  /** Retrieve a single "MetadataIdMediaContent" node. */
  metadataIdMediaContent?: Maybe<MetadataIdMediaContent>;
  /** Retrieve the number of "MetadataIdMediaContent" nodes. */
  metadataIdMediaContentCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataIdMediaContent" nodes. */
  metadataIdMediaContentList: Array<MetadataIdMediaContent>;
  /** Retrieve a single "MetadataIdPhotoPrisma" node. */
  metadataIdPhotoPrisma?: Maybe<MetadataIdPhotoPrisma>;
  /** Retrieve the number of "MetadataIdPhotoPrisma" nodes. */
  metadataIdPhotoPrismaCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataIdPhotoPrisma" nodes. */
  metadataIdPhotoPrismaList: Array<MetadataIdPhotoPrisma>;
  /** Retrieve a single "MetadataIdSubject" node. */
  metadataIdSubject?: Maybe<MetadataIdSubject>;
  /** Retrieve the number of "MetadataIdSubject" nodes. */
  metadataIdSubjectCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataIdSubject" nodes. */
  metadataIdSubjectList: Array<MetadataIdSubject>;
  /** Retrieve a single "MetadataImageNotes" node. */
  metadataImageNotes?: Maybe<MetadataImageNotes>;
  /** Retrieve the number of "MetadataImageNotes" nodes. */
  metadataImageNotesCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataImageNotes" nodes. */
  metadataImageNotesList: Array<MetadataImageNotes>;
  /** Retrieve a single "MetadataImageUniqueId" node. */
  metadataImageUniqueId?: Maybe<MetadataImageUniqueId>;
  /** Retrieve the number of "MetadataImageUniqueId" nodes. */
  metadataImageUniqueIdCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataImageUniqueId" nodes. */
  metadataImageUniqueIdList: Array<MetadataImageUniqueId>;
  /** Retrieve a single "MetadataInstructions" node. */
  metadataInstructions?: Maybe<MetadataInstructions>;
  /** Retrieve the number of "MetadataInstructions" nodes. */
  metadataInstructionsCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataInstructions" nodes. */
  metadataInstructionsList: Array<MetadataInstructions>;
  /** Retrieve a single "MetadataIntellectualGenre" node. */
  metadataIntellectualGenre?: Maybe<MetadataIntellectualGenre>;
  /** Retrieve the number of "MetadataIntellectualGenre" nodes. */
  metadataIntellectualGenreCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataIntellectualGenre" nodes. */
  metadataIntellectualGenreList: Array<MetadataIntellectualGenre>;
  /** Retrieve a single "MetadataIsoSpeedRatings" node. */
  metadataIsoSpeedRatings?: Maybe<MetadataIsoSpeedRatings>;
  /** Retrieve the number of "MetadataIsoSpeedRatings" nodes. */
  metadataIsoSpeedRatingsCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataIsoSpeedRatings" nodes. */
  metadataIsoSpeedRatingsList: Array<MetadataIsoSpeedRatings>;
  /** Retrieve a single "MetadataIssue" node. */
  metadataIssue?: Maybe<MetadataIssue>;
  /** Retrieve the number of "MetadataIssue" nodes. */
  metadataIssueCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataIssue" nodes. */
  metadataIssueList: Array<MetadataIssue>;
  /** Retrieve a single "MetadataJobId" node. */
  metadataJobId?: Maybe<MetadataJobId>;
  /** Retrieve the number of "MetadataJobId" nodes. */
  metadataJobIdCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataJobId" nodes. */
  metadataJobIdList: Array<MetadataJobId>;
  /** Retrieve a single "MetadataJobProcessing" node. */
  metadataJobProcessing?: Maybe<MetadataJobProcessing>;
  /** Retrieve the number of "MetadataJobProcessing" nodes. */
  metadataJobProcessingCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataJobProcessing" nodes. */
  metadataJobProcessingList: Array<MetadataJobProcessing>;
  /** Retrieve a single "MetadataLayoutPictureEditing" node. */
  metadataLayoutPictureEditing?: Maybe<MetadataLayoutPictureEditing>;
  /** Retrieve the number of "MetadataLayoutPictureEditing" nodes. */
  metadataLayoutPictureEditingCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataLayoutPictureEditing" nodes. */
  metadataLayoutPictureEditingList: Array<MetadataLayoutPictureEditing>;
  /** Retrieve a single "MetadataLayoutStorage" node. */
  metadataLayoutStorage?: Maybe<MetadataLayoutStorage>;
  /** Retrieve the number of "MetadataLayoutStorage" nodes. */
  metadataLayoutStorageCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataLayoutStorage" nodes. */
  metadataLayoutStorageList: Array<MetadataLayoutStorage>;
  /** Retrieve a single "MetadataLicense" node. */
  metadataLicense?: Maybe<MetadataLicense>;
  /** Retrieve the number of "MetadataLicense" nodes. */
  metadataLicenseCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataLicense" nodes. */
  metadataLicenseList: Array<MetadataLicense>;
  /** Retrieve a single "MetadataLinks" node. */
  metadataLinks?: Maybe<MetadataLinks>;
  /** Retrieve the number of "MetadataLinks" nodes. */
  metadataLinksCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataLinks" nodes. */
  metadataLinksList: Array<MetadataLinks>;
  /** Retrieve a single "MetadataLocalCaption" node. */
  metadataLocalCaption?: Maybe<MetadataLocalCaption>;
  /** Retrieve the number of "MetadataLocalCaption" nodes. */
  metadataLocalCaptionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataLocalCaption" nodes. */
  metadataLocalCaptionList: Array<MetadataLocalCaption>;
  /** Retrieve a single "MetadataLocation" node. */
  metadataLocation?: Maybe<MetadataLocation>;
  /** Retrieve the number of "MetadataLocation" nodes. */
  metadataLocationCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataLocation" nodes. */
  metadataLocationList: Array<MetadataLocation>;
  /** Retrieve a single "MetadataMake" node. */
  metadataMake?: Maybe<MetadataMake>;
  /** Retrieve the number of "MetadataMake" nodes. */
  metadataMakeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataMake" nodes. */
  metadataMakeList: Array<MetadataMake>;
  /** Retrieve a single "MetadataManifest" node. */
  metadataManifest?: Maybe<MetadataManifest>;
  /** Retrieve the number of "MetadataManifest" nodes. */
  metadataManifestCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataManifest" nodes. */
  metadataManifestList: Array<MetadataManifest>;
  /** Retrieve a single "MetadataMarked" node. */
  metadataMarked?: Maybe<MetadataMarked>;
  /** Retrieve the number of "MetadataMarked" nodes. */
  metadataMarkedCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataMarked" nodes. */
  metadataMarkedList: Array<MetadataMarked>;
  /** Retrieve a single "MetadataMasterDocumentId" node. */
  metadataMasterDocumentId?: Maybe<MetadataMasterDocumentId>;
  /** Retrieve the number of "MetadataMasterDocumentId" nodes. */
  metadataMasterDocumentIdCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataMasterDocumentId" nodes. */
  metadataMasterDocumentIdList: Array<MetadataMasterDocumentId>;
  /** Retrieve a single "MetadataMaxAvailHeight" node. */
  metadataMaxAvailHeight?: Maybe<MetadataMaxAvailHeight>;
  /** Retrieve the number of "MetadataMaxAvailHeight" nodes. */
  metadataMaxAvailHeightCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataMaxAvailHeight" nodes. */
  metadataMaxAvailHeightList: Array<MetadataMaxAvailHeight>;
  /** Retrieve a single "MetadataMaxAvailWidth" node. */
  metadataMaxAvailWidth?: Maybe<MetadataMaxAvailWidth>;
  /** Retrieve the number of "MetadataMaxAvailWidth" nodes. */
  metadataMaxAvailWidthCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataMaxAvailWidth" nodes. */
  metadataMaxAvailWidthList: Array<MetadataMaxAvailWidth>;
  /** Retrieve a single "MetadataMinorModelAgeDisclosure" node. */
  metadataMinorModelAgeDisclosure?: Maybe<MetadataMinorModelAgeDisclosure>;
  /** Retrieve the number of "MetadataMinorModelAgeDisclosure" nodes. */
  metadataMinorModelAgeDisclosureCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataMinorModelAgeDisclosure" nodes. */
  metadataMinorModelAgeDisclosureList: Array<MetadataMinorModelAgeDisclosure>;
  /** Retrieve a single "MetadataModel" node. */
  metadataModel?: Maybe<MetadataModel>;
  /** Retrieve a single "MetadataModelAge" node. */
  metadataModelAge?: Maybe<MetadataModelAge>;
  /** Retrieve the number of "MetadataModelAge" nodes. */
  metadataModelAgeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataModelAge" nodes. */
  metadataModelAgeList: Array<MetadataModelAge>;
  /** Retrieve the number of "MetadataModel" nodes. */
  metadataModelCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataModel" nodes. */
  metadataModelList: Array<MetadataModel>;
  /** Retrieve a single "MetadataModelReleaseId" node. */
  metadataModelReleaseId?: Maybe<MetadataModelReleaseId>;
  /** Retrieve the number of "MetadataModelReleaseId" nodes. */
  metadataModelReleaseIdCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataModelReleaseId" nodes. */
  metadataModelReleaseIdList: Array<MetadataModelReleaseId>;
  /** Retrieve a single "MetadataModelReleaseStatus" node. */
  metadataModelReleaseStatus?: Maybe<MetadataModelReleaseStatus>;
  /** Retrieve the number of "MetadataModelReleaseStatus" nodes. */
  metadataModelReleaseStatusCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataModelReleaseStatus" nodes. */
  metadataModelReleaseStatusList: Array<MetadataModelReleaseStatus>;
  /** Retrieve a single "MetadataMorePermissions" node. */
  metadataMorePermissions?: Maybe<MetadataMorePermissions>;
  /** Retrieve the number of "MetadataMorePermissions" nodes. */
  metadataMorePermissionsCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataMorePermissions" nodes. */
  metadataMorePermissionsList: Array<MetadataMorePermissions>;
  /** Retrieve a single "MetadataObjectCycle" node. */
  metadataObjectCycle?: Maybe<MetadataObjectCycle>;
  /** Retrieve the number of "MetadataObjectCycle" nodes. */
  metadataObjectCycleCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataObjectCycle" nodes. */
  metadataObjectCycleList: Array<MetadataObjectCycle>;
  /** Retrieve a single "MetadataOrganisationInImageName" node. */
  metadataOrganisationInImageName?: Maybe<MetadataOrganisationInImageName>;
  /** Retrieve the number of "MetadataOrganisationInImageName" nodes. */
  metadataOrganisationInImageNameCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataOrganisationInImageName" nodes. */
  metadataOrganisationInImageNameList: Array<MetadataOrganisationInImageName>;
  /** Retrieve a single "MetadataOriginalColor" node. */
  metadataOriginalColor?: Maybe<MetadataOriginalColor>;
  /** Retrieve the number of "MetadataOriginalColor" nodes. */
  metadataOriginalColorCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataOriginalColor" nodes. */
  metadataOriginalColorList: Array<MetadataOriginalColor>;
  /** Retrieve a single "MetadataOriginalIccProfile" node. */
  metadataOriginalIccProfile?: Maybe<MetadataOriginalIccProfile>;
  /** Retrieve the number of "MetadataOriginalIccProfile" nodes. */
  metadataOriginalIccProfileCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataOriginalIccProfile" nodes. */
  metadataOriginalIccProfileList: Array<MetadataOriginalIccProfile>;
  /** Retrieve a single "MetadataOriginalName" node. */
  metadataOriginalName?: Maybe<MetadataOriginalName>;
  /** Retrieve the number of "MetadataOriginalName" nodes. */
  metadataOriginalNameCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataOriginalName" nodes. */
  metadataOriginalNameList: Array<MetadataOriginalName>;
  /** Retrieve a single "MetadataOriginalWeigth" node. */
  metadataOriginalWeigth?: Maybe<MetadataOriginalWeigth>;
  /** Retrieve the number of "MetadataOriginalWeigth" nodes. */
  metadataOriginalWeigthCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataOriginalWeigth" nodes. */
  metadataOriginalWeigthList: Array<MetadataOriginalWeigth>;
  /** Retrieve a single "MetadataOwner" node. */
  metadataOwner?: Maybe<MetadataOwner>;
  /** Retrieve the number of "MetadataOwner" nodes. */
  metadataOwnerCount: Scalars['Int']['output'];
  /** Retrieve a single "MetadataOwnerId" node. */
  metadataOwnerId?: Maybe<MetadataOwnerId>;
  /** Retrieve the number of "MetadataOwnerId" nodes. */
  metadataOwnerIdCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataOwnerId" nodes. */
  metadataOwnerIdList: Array<MetadataOwnerId>;
  /** Retrieve a list of "MetadataOwner" nodes. */
  metadataOwnerList: Array<MetadataOwner>;
  /** Retrieve a single "MetadataPage" node. */
  metadataPage?: Maybe<MetadataPage>;
  /** Retrieve the number of "MetadataPage" nodes. */
  metadataPageCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPage" nodes. */
  metadataPageList: Array<MetadataPage>;
  /** Retrieve a single "MetadataPagePrisma" node. */
  metadataPagePrisma?: Maybe<MetadataPagePrisma>;
  /** Retrieve the number of "MetadataPagePrisma" nodes. */
  metadataPagePrismaCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPagePrisma" nodes. */
  metadataPagePrismaList: Array<MetadataPagePrisma>;
  /** Retrieve a single "MetadataPaintBasedCorrections" node. */
  metadataPaintBasedCorrections?: Maybe<MetadataPaintBasedCorrections>;
  /** Retrieve the number of "MetadataPaintBasedCorrections" nodes. */
  metadataPaintBasedCorrectionsCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPaintBasedCorrections" nodes. */
  metadataPaintBasedCorrectionsList: Array<MetadataPaintBasedCorrections>;
  /** Retrieve a single "MetadataPatientDob" node. */
  metadataPatientDob?: Maybe<MetadataPatientDob>;
  /** Retrieve the number of "MetadataPatientDob" nodes. */
  metadataPatientDobCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPatientDob" nodes. */
  metadataPatientDobList: Array<MetadataPatientDob>;
  /** Retrieve a single "MetadataPatientId" node. */
  metadataPatientId?: Maybe<MetadataPatientId>;
  /** Retrieve the number of "MetadataPatientId" nodes. */
  metadataPatientIdCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPatientId" nodes. */
  metadataPatientIdList: Array<MetadataPatientId>;
  /** Retrieve a single "MetadataPatientName" node. */
  metadataPatientName?: Maybe<MetadataPatientName>;
  /** Retrieve the number of "MetadataPatientName" nodes. */
  metadataPatientNameCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPatientName" nodes. */
  metadataPatientNameList: Array<MetadataPatientName>;
  /** Retrieve a single "MetadataPatientSex" node. */
  metadataPatientSex?: Maybe<MetadataPatientSex>;
  /** Retrieve the number of "MetadataPatientSex" nodes. */
  metadataPatientSexCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPatientSex" nodes. */
  metadataPatientSexList: Array<MetadataPatientSex>;
  /** Retrieve a single "MetadataPersonInImage" node. */
  metadataPersonInImage?: Maybe<MetadataPersonInImage>;
  /** Retrieve the number of "MetadataPersonInImage" nodes. */
  metadataPersonInImageCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPersonInImage" nodes. */
  metadataPersonInImageList: Array<MetadataPersonInImage>;
  /** Retrieve a single "MetadataPhotoStorage" node. */
  metadataPhotoStorage?: Maybe<MetadataPhotoStorage>;
  /** Retrieve the number of "MetadataPhotoStorage" nodes. */
  metadataPhotoStorageCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPhotoStorage" nodes. */
  metadataPhotoStorageList: Array<MetadataPhotoStorage>;
  /** Retrieve a single "MetadataPrepressPictureEditing" node. */
  metadataPrepressPictureEditing?: Maybe<MetadataPrepressPictureEditing>;
  /** Retrieve the number of "MetadataPrepressPictureEditing" nodes. */
  metadataPrepressPictureEditingCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPrepressPictureEditing" nodes. */
  metadataPrepressPictureEditingList: Array<MetadataPrepressPictureEditing>;
  /** Retrieve a single "MetadataPriceLevel" node. */
  metadataPriceLevel?: Maybe<MetadataPriceLevel>;
  /** Retrieve the number of "MetadataPriceLevel" nodes. */
  metadataPriceLevelCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPriceLevel" nodes. */
  metadataPriceLevelList: Array<MetadataPriceLevel>;
  /** Retrieve a single "MetadataPrintingProfile" node. */
  metadataPrintingProfile?: Maybe<MetadataPrintingProfile>;
  /** Retrieve the number of "MetadataPrintingProfile" nodes. */
  metadataPrintingProfileCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPrintingProfile" nodes. */
  metadataPrintingProfileList: Array<MetadataPrintingProfile>;
  /** Retrieve a single "MetadataPrismaProduction" node. */
  metadataPrismaProduction?: Maybe<MetadataPrismaProduction>;
  /** Retrieve the number of "MetadataPrismaProduction" nodes. */
  metadataPrismaProductionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPrismaProduction" nodes. */
  metadataPrismaProductionList: Array<MetadataPrismaProduction>;
  /** Retrieve a single "MetadataProcessHistory" node. */
  metadataProcessHistory?: Maybe<MetadataProcessHistory>;
  /** Retrieve the number of "MetadataProcessHistory" nodes. */
  metadataProcessHistoryCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataProcessHistory" nodes. */
  metadataProcessHistoryList: Array<MetadataProcessHistory>;
  /** Retrieve a single "MetadataProcessParameter" node. */
  metadataProcessParameter?: Maybe<MetadataProcessParameter>;
  /** Retrieve the number of "MetadataProcessParameter" nodes. */
  metadataProcessParameterCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataProcessParameter" nodes. */
  metadataProcessParameterList: Array<MetadataProcessParameter>;
  /** Retrieve a single "MetadataProcessStatus" node. */
  metadataProcessStatus?: Maybe<MetadataProcessStatus>;
  /** Retrieve the number of "MetadataProcessStatus" nodes. */
  metadataProcessStatusCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataProcessStatus" nodes. */
  metadataProcessStatusList: Array<MetadataProcessStatus>;
  /** Retrieve a single "MetadataProduct" node. */
  metadataProduct?: Maybe<MetadataProduct>;
  /** Retrieve the number of "MetadataProduct" nodes. */
  metadataProductCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataProduct" nodes. */
  metadataProductList: Array<MetadataProduct>;
  /** Retrieve a single "MetadataProducts" node. */
  metadataProducts?: Maybe<MetadataProducts>;
  /** Retrieve the number of "MetadataProducts" nodes. */
  metadataProductsCount: Scalars['Int']['output'];
  /** Retrieve a single "MetadataProductShortName" node. */
  metadataProductShortName?: Maybe<MetadataProductShortName>;
  /** Retrieve the number of "MetadataProductShortName" nodes. */
  metadataProductShortNameCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataProductShortName" nodes. */
  metadataProductShortNameList: Array<MetadataProductShortName>;
  /** Retrieve a list of "MetadataProducts" nodes. */
  metadataProductsList: Array<MetadataProducts>;
  /** Retrieve a single "MetadataProduits" node. */
  metadataProduits?: Maybe<MetadataProduits>;
  /** Retrieve the number of "MetadataProduits" nodes. */
  metadataProduitsCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataProduits" nodes. */
  metadataProduitsList: Array<MetadataProduits>;
  /** Retrieve a single "MetadataProgramVersion" node. */
  metadataProgramVersion?: Maybe<MetadataProgramVersion>;
  /** Retrieve the number of "MetadataProgramVersion" nodes. */
  metadataProgramVersionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataProgramVersion" nodes. */
  metadataProgramVersionList: Array<MetadataProgramVersion>;
  /** Retrieve a single "MetadataPropertyReleaseId" node. */
  metadataPropertyReleaseId?: Maybe<MetadataPropertyReleaseId>;
  /** Retrieve the number of "MetadataPropertyReleaseId" nodes. */
  metadataPropertyReleaseIdCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPropertyReleaseId" nodes. */
  metadataPropertyReleaseIdList: Array<MetadataPropertyReleaseId>;
  /** Retrieve a single "MetadataPropertyReleaseStatus" node. */
  metadataPropertyReleaseStatus?: Maybe<MetadataPropertyReleaseStatus>;
  /** Retrieve the number of "MetadataPropertyReleaseStatus" nodes. */
  metadataPropertyReleaseStatusCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPropertyReleaseStatus" nodes. */
  metadataPropertyReleaseStatusList: Array<MetadataPropertyReleaseStatus>;
  /** Retrieve a single "MetadataPublisher" node. */
  metadataPublisher?: Maybe<MetadataPublisher>;
  /** Retrieve the number of "MetadataPublisher" nodes. */
  metadataPublisherCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPublisher" nodes. */
  metadataPublisherList: Array<MetadataPublisher>;
  /** Retrieve a single "MetadataPublishingIssue" node. */
  metadataPublishingIssue?: Maybe<MetadataPublishingIssue>;
  /** Retrieve the number of "MetadataPublishingIssue" nodes. */
  metadataPublishingIssueCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPublishingIssue" nodes. */
  metadataPublishingIssueList: Array<MetadataPublishingIssue>;
  /** Retrieve a single "MetadataPublishingSubject" node. */
  metadataPublishingSubject?: Maybe<MetadataPublishingSubject>;
  /** Retrieve the number of "MetadataPublishingSubject" nodes. */
  metadataPublishingSubjectCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataPublishingSubject" nodes. */
  metadataPublishingSubjectList: Array<MetadataPublishingSubject>;
  /** Retrieve a single "MetadataQualifiedUsePrismaBy" node. */
  metadataQualifiedUsePrismaBy?: Maybe<MetadataQualifiedUsePrismaBy>;
  /** Retrieve the number of "MetadataQualifiedUsePrismaBy" nodes. */
  metadataQualifiedUsePrismaByCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataQualifiedUsePrismaBy" nodes. */
  metadataQualifiedUsePrismaByList: Array<MetadataQualifiedUsePrismaBy>;
  /** Retrieve a single "MetadataQualifiedUsePrismaDate" node. */
  metadataQualifiedUsePrismaDate?: Maybe<MetadataQualifiedUsePrismaDate>;
  /** Retrieve the number of "MetadataQualifiedUsePrismaDate" nodes. */
  metadataQualifiedUsePrismaDateCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataQualifiedUsePrismaDate" nodes. */
  metadataQualifiedUsePrismaDateList: Array<MetadataQualifiedUsePrismaDate>;
  /** Retrieve a single "MetadataQualifiedUsePrismaDuration" node. */
  metadataQualifiedUsePrismaDuration?: Maybe<MetadataQualifiedUsePrismaDuration>;
  /** Retrieve the number of "MetadataQualifiedUsePrismaDuration" nodes. */
  metadataQualifiedUsePrismaDurationCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataQualifiedUsePrismaDuration" nodes. */
  metadataQualifiedUsePrismaDurationList: Array<MetadataQualifiedUsePrismaDuration>;
  /** Retrieve a single "MetadataQualifiedUsePrismaSupport" node. */
  metadataQualifiedUsePrismaSupport?: Maybe<MetadataQualifiedUsePrismaSupport>;
  /** Retrieve the number of "MetadataQualifiedUsePrismaSupport" nodes. */
  metadataQualifiedUsePrismaSupportCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataQualifiedUsePrismaSupport" nodes. */
  metadataQualifiedUsePrismaSupportList: Array<MetadataQualifiedUsePrismaSupport>;
  /** Retrieve a single "MetadataRating" node. */
  metadataRating?: Maybe<MetadataRating>;
  /** Retrieve the number of "MetadataRating" nodes. */
  metadataRatingCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataRating" nodes. */
  metadataRatingList: Array<MetadataRating>;
  /** Retrieve a single "MetadataReferenceDate" node. */
  metadataReferenceDate?: Maybe<MetadataReferenceDate>;
  /** Retrieve the number of "MetadataReferenceDate" nodes. */
  metadataReferenceDateCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataReferenceDate" nodes. */
  metadataReferenceDateList: Array<MetadataReferenceDate>;
  /** Retrieve a single "MetadataReferenceNumber" node. */
  metadataReferenceNumber?: Maybe<MetadataReferenceNumber>;
  /** Retrieve the number of "MetadataReferenceNumber" nodes. */
  metadataReferenceNumberCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataReferenceNumber" nodes. */
  metadataReferenceNumberList: Array<MetadataReferenceNumber>;
  /** Retrieve a single "MetadataReferenceService" node. */
  metadataReferenceService?: Maybe<MetadataReferenceService>;
  /** Retrieve the number of "MetadataReferenceService" nodes. */
  metadataReferenceServiceCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataReferenceService" nodes. */
  metadataReferenceServiceList: Array<MetadataReferenceService>;
  /** Retrieve a single "MetadataReleaseDate" node. */
  metadataReleaseDate?: Maybe<MetadataReleaseDate>;
  /** Retrieve the number of "MetadataReleaseDate" nodes. */
  metadataReleaseDateCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataReleaseDate" nodes. */
  metadataReleaseDateList: Array<MetadataReleaseDate>;
  /** Retrieve a single "MetadataReleaseTime" node. */
  metadataReleaseTime?: Maybe<MetadataReleaseTime>;
  /** Retrieve the number of "MetadataReleaseTime" nodes. */
  metadataReleaseTimeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataReleaseTime" nodes. */
  metadataReleaseTimeList: Array<MetadataReleaseTime>;
  /** Retrieve a single "MetadataRequiredPermission" node. */
  metadataRequiredPermission?: Maybe<MetadataRequiredPermission>;
  /** Retrieve the number of "MetadataRequiredPermission" nodes. */
  metadataRequiredPermissionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataRequiredPermission" nodes. */
  metadataRequiredPermissionList: Array<MetadataRequiredPermission>;
  /** Retrieve a single "MetadataResolutionKind" node. */
  metadataResolutionKind?: Maybe<MetadataResolutionKind>;
  /** Retrieve the number of "MetadataResolutionKind" nodes. */
  metadataResolutionKindCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataResolutionKind" nodes. */
  metadataResolutionKindList: Array<MetadataResolutionKind>;
  /** Retrieve a single "MetadataRights" node. */
  metadataRights?: Maybe<MetadataRights>;
  /** Retrieve the number of "MetadataRights" nodes. */
  metadataRightsCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataRights" nodes. */
  metadataRightsList: Array<MetadataRights>;
  /** Retrieve a single "MetadataRoyaltyfree" node. */
  metadataRoyaltyfree?: Maybe<MetadataRoyaltyfree>;
  /** Retrieve the number of "MetadataRoyaltyfree" nodes. */
  metadataRoyaltyfreeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataRoyaltyfree" nodes. */
  metadataRoyaltyfreeList: Array<MetadataRoyaltyfree>;
  /** Retrieve a list of "Metadata" nodes. */
  metadatas: Array<Metadata>;
  /** Retrieve a single "MetadataScene" node. */
  metadataScene?: Maybe<MetadataScene>;
  /** Retrieve the number of "MetadataScene" nodes. */
  metadataSceneCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataScene" nodes. */
  metadataSceneList: Array<MetadataScene>;
  /** Retrieve a single "MetadataSection" node. */
  metadataSection?: Maybe<MetadataSection>;
  /** Retrieve the number of "MetadataSection" nodes. */
  metadataSectionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSection" nodes. */
  metadataSectionList: Array<MetadataSection>;
  /** Retrieve a single "MetadataSentTo" node. */
  metadataSentTo?: Maybe<MetadataSentTo>;
  /** Retrieve the number of "MetadataSentTo" nodes. */
  metadataSentToCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSentTo" nodes. */
  metadataSentToList: Array<MetadataSentTo>;
  /** Retrieve a single "MetadataSerialNumber" node. */
  metadataSerialNumber?: Maybe<MetadataSerialNumber>;
  /** Retrieve the number of "MetadataSerialNumber" nodes. */
  metadataSerialNumberCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSerialNumber" nodes. */
  metadataSerialNumberList: Array<MetadataSerialNumber>;
  /** Retrieve a single "MetadataSeriesDateTime" node. */
  metadataSeriesDateTime?: Maybe<MetadataSeriesDateTime>;
  /** Retrieve the number of "MetadataSeriesDateTime" nodes. */
  metadataSeriesDateTimeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSeriesDateTime" nodes. */
  metadataSeriesDateTimeList: Array<MetadataSeriesDateTime>;
  /** Retrieve a single "MetadataSeriesDescription" node. */
  metadataSeriesDescription?: Maybe<MetadataSeriesDescription>;
  /** Retrieve the number of "MetadataSeriesDescription" nodes. */
  metadataSeriesDescriptionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSeriesDescription" nodes. */
  metadataSeriesDescriptionList: Array<MetadataSeriesDescription>;
  /** Retrieve a single "MetadataSeriesModality" node. */
  metadataSeriesModality?: Maybe<MetadataSeriesModality>;
  /** Retrieve the number of "MetadataSeriesModality" nodes. */
  metadataSeriesModalityCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSeriesModality" nodes. */
  metadataSeriesModalityList: Array<MetadataSeriesModality>;
  /** Retrieve a single "MetadataSeriesNumber" node. */
  metadataSeriesNumber?: Maybe<MetadataSeriesNumber>;
  /** Retrieve the number of "MetadataSeriesNumber" nodes. */
  metadataSeriesNumberCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSeriesNumber" nodes. */
  metadataSeriesNumberList: Array<MetadataSeriesNumber>;
  /** Retrieve a single "MetadataShortUniqueId" node. */
  metadataShortUniqueId?: Maybe<MetadataShortUniqueId>;
  /** Retrieve the number of "MetadataShortUniqueId" nodes. */
  metadataShortUniqueIdCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataShortUniqueId" nodes. */
  metadataShortUniqueIdList: Array<MetadataShortUniqueId>;
  /** Retrieve a single "MetadataSource" node. */
  metadataSource?: Maybe<MetadataSource>;
  /** Retrieve the number of "MetadataSource" nodes. */
  metadataSourceCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSource" nodes. */
  metadataSourceList: Array<MetadataSource>;
  /** Retrieve a single "MetadataState" node. */
  metadataState?: Maybe<MetadataState>;
  /** Retrieve the number of "MetadataState" nodes. */
  metadataStateCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataState" nodes. */
  metadataStateList: Array<MetadataState>;
  /** Retrieve a single "MetadataStudyDateTime" node. */
  metadataStudyDateTime?: Maybe<MetadataStudyDateTime>;
  /** Retrieve the number of "MetadataStudyDateTime" nodes. */
  metadataStudyDateTimeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataStudyDateTime" nodes. */
  metadataStudyDateTimeList: Array<MetadataStudyDateTime>;
  /** Retrieve a single "MetadataStudyDescription" node. */
  metadataStudyDescription?: Maybe<MetadataStudyDescription>;
  /** Retrieve the number of "MetadataStudyDescription" nodes. */
  metadataStudyDescriptionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataStudyDescription" nodes. */
  metadataStudyDescriptionList: Array<MetadataStudyDescription>;
  /** Retrieve a single "MetadataStudyId" node. */
  metadataStudyId?: Maybe<MetadataStudyId>;
  /** Retrieve the number of "MetadataStudyId" nodes. */
  metadataStudyIdCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataStudyId" nodes. */
  metadataStudyIdList: Array<MetadataStudyId>;
  /** Retrieve a single "MetadataStudyPhysician" node. */
  metadataStudyPhysician?: Maybe<MetadataStudyPhysician>;
  /** Retrieve the number of "MetadataStudyPhysician" nodes. */
  metadataStudyPhysicianCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataStudyPhysician" nodes. */
  metadataStudyPhysicianList: Array<MetadataStudyPhysician>;
  /** Retrieve a single "MetadataSubject" node. */
  metadataSubject?: Maybe<MetadataSubject>;
  /** Retrieve a single "MetadataSubjectCode" node. */
  metadataSubjectCode?: Maybe<MetadataSubjectCode>;
  /** Retrieve the number of "MetadataSubjectCode" nodes. */
  metadataSubjectCodeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSubjectCode" nodes. */
  metadataSubjectCodeList: Array<MetadataSubjectCode>;
  /** Retrieve the number of "MetadataSubject" nodes. */
  metadataSubjectCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSubject" nodes. */
  metadataSubjectList: Array<MetadataSubject>;
  /** Retrieve a single "MetadataSubjectPrisma" node. */
  metadataSubjectPrisma?: Maybe<MetadataSubjectPrisma>;
  /** Retrieve the number of "MetadataSubjectPrisma" nodes. */
  metadataSubjectPrismaCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSubjectPrisma" nodes. */
  metadataSubjectPrismaList: Array<MetadataSubjectPrisma>;
  /** Retrieve a single "MetadataSupplementalCategories" node. */
  metadataSupplementalCategories?: Maybe<MetadataSupplementalCategories>;
  /** Retrieve the number of "MetadataSupplementalCategories" nodes. */
  metadataSupplementalCategoriesCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataSupplementalCategories" nodes. */
  metadataSupplementalCategoriesList: Array<MetadataSupplementalCategories>;
  /** Retrieve a single "MetadataTargetVersion" node. */
  metadataTargetVersion?: Maybe<MetadataTargetVersion>;
  /** Retrieve the number of "MetadataTargetVersion" nodes. */
  metadataTargetVersionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataTargetVersion" nodes. */
  metadataTargetVersionList: Array<MetadataTargetVersion>;
  /** Retrieve a single "MetadataTitle" node. */
  metadataTitle?: Maybe<MetadataTitle>;
  /** Retrieve the number of "MetadataTitle" nodes. */
  metadataTitleCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataTitle" nodes. */
  metadataTitleList: Array<MetadataTitle>;
  /** Retrieve a single "MetadataToneCurve" node. */
  metadataToneCurve?: Maybe<MetadataToneCurve>;
  /** Retrieve the number of "MetadataToneCurve" nodes. */
  metadataToneCurveCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataToneCurve" nodes. */
  metadataToneCurveList: Array<MetadataToneCurve>;
  /** Retrieve a single "MetadataTransferredBy" node. */
  metadataTransferredBy?: Maybe<MetadataTransferredBy>;
  /** Retrieve the number of "MetadataTransferredBy" nodes. */
  metadataTransferredByCount: Scalars['Int']['output'];
  /** Retrieve a single "MetadataTransferredByEmail" node. */
  metadataTransferredByEmail?: Maybe<MetadataTransferredByEmail>;
  /** Retrieve the number of "MetadataTransferredByEmail" nodes. */
  metadataTransferredByEmailCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataTransferredByEmail" nodes. */
  metadataTransferredByEmailList: Array<MetadataTransferredByEmail>;
  /** Retrieve a single "MetadataTransferredByFullName" node. */
  metadataTransferredByFullName?: Maybe<MetadataTransferredByFullName>;
  /** Retrieve the number of "MetadataTransferredByFullName" nodes. */
  metadataTransferredByFullNameCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataTransferredByFullName" nodes. */
  metadataTransferredByFullNameList: Array<MetadataTransferredByFullName>;
  /** Retrieve a list of "MetadataTransferredBy" nodes. */
  metadataTransferredByList: Array<MetadataTransferredBy>;
  /** Retrieve a single "MetadataTransmissionReference" node. */
  metadataTransmissionReference?: Maybe<MetadataTransmissionReference>;
  /** Retrieve the number of "MetadataTransmissionReference" nodes. */
  metadataTransmissionReferenceCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataTransmissionReference" nodes. */
  metadataTransmissionReferenceList: Array<MetadataTransmissionReference>;
  /** Retrieve a single "MetadataType" node. */
  metadataType?: Maybe<MetadataType>;
  /** Retrieve the number of "MetadataType" nodes. */
  metadataTypeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataType" nodes. */
  metadataTypeList: Array<MetadataType>;
  /** Retrieve a single "MetadataUndersubject" node. */
  metadataUndersubject?: Maybe<MetadataUndersubject>;
  /** Retrieve the number of "MetadataUndersubject" nodes. */
  metadataUndersubjectCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUndersubject" nodes. */
  metadataUndersubjectList: Array<MetadataUndersubject>;
  /** Retrieve a single "MetadataUnderUnderSubject" node. */
  metadataUnderUnderSubject?: Maybe<MetadataUnderUnderSubject>;
  /** Retrieve the number of "MetadataUnderUnderSubject" nodes. */
  metadataUnderUnderSubjectCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUnderUnderSubject" nodes. */
  metadataUnderUnderSubjectList: Array<MetadataUnderUnderSubject>;
  /** Retrieve a single "MetadataUniqueId" node. */
  metadataUniqueId?: Maybe<MetadataUniqueId>;
  /** Retrieve the number of "MetadataUniqueId" nodes. */
  metadataUniqueIdCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUniqueId" nodes. */
  metadataUniqueIdList: Array<MetadataUniqueId>;
  /** Retrieve a single "MetadataUnit" node. */
  metadataUnit?: Maybe<MetadataUnit>;
  /** Retrieve the number of "MetadataUnit" nodes. */
  metadataUnitCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUnit" nodes. */
  metadataUnitList: Array<MetadataUnit>;
  /** Retrieve a single "MetadataUnitShortName" node. */
  metadataUnitShortName?: Maybe<MetadataUnitShortName>;
  /** Retrieve the number of "MetadataUnitShortName" nodes. */
  metadataUnitShortNameCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUnitShortName" nodes. */
  metadataUnitShortNameList: Array<MetadataUnitShortName>;
  /** Retrieve a single "MetadataUploadedBy" node. */
  metadataUploadedBy?: Maybe<MetadataUploadedBy>;
  /** Retrieve the number of "MetadataUploadedBy" nodes. */
  metadataUploadedByCount: Scalars['Int']['output'];
  /** Retrieve a single "MetadataUploadedByFullName" node. */
  metadataUploadedByFullName?: Maybe<MetadataUploadedByFullName>;
  /** Retrieve the number of "MetadataUploadedByFullName" nodes. */
  metadataUploadedByFullNameCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUploadedByFullName" nodes. */
  metadataUploadedByFullNameList: Array<MetadataUploadedByFullName>;
  /** Retrieve a list of "MetadataUploadedBy" nodes. */
  metadataUploadedByList: Array<MetadataUploadedBy>;
  /** Retrieve a single "MetadataUploadTime" node. */
  metadataUploadTime?: Maybe<MetadataUploadTime>;
  /** Retrieve the number of "MetadataUploadTime" nodes. */
  metadataUploadTimeCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUploadTime" nodes. */
  metadataUploadTimeList: Array<MetadataUploadTime>;
  /** Retrieve a single "MetadataUrgency" node. */
  metadataUrgency?: Maybe<MetadataUrgency>;
  /** Retrieve the number of "MetadataUrgency" nodes. */
  metadataUrgencyCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUrgency" nodes. */
  metadataUrgencyList: Array<MetadataUrgency>;
  /** Retrieve a single "MetadataUsageTerms" node. */
  metadataUsageTerms?: Maybe<MetadataUsageTerms>;
  /** Retrieve the number of "MetadataUsageTerms" nodes. */
  metadataUsageTermsCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUsageTerms" nodes. */
  metadataUsageTermsList: Array<MetadataUsageTerms>;
  /** Retrieve a single "MetadataUserComment" node. */
  metadataUserComment?: Maybe<MetadataUserComment>;
  /** Retrieve the number of "MetadataUserComment" nodes. */
  metadataUserCommentCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUserComment" nodes. */
  metadataUserCommentList: Array<MetadataUserComment>;
  /** Retrieve a single "MetadataUserDefined195" node. */
  metadataUserDefined195?: Maybe<MetadataUserDefined195>;
  /** Retrieve the number of "MetadataUserDefined195" nodes. */
  metadataUserDefined195Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUserDefined195" nodes. */
  metadataUserDefined195List: Array<MetadataUserDefined195>;
  /** Retrieve a single "MetadataUserDefined237" node. */
  metadataUserDefined237?: Maybe<MetadataUserDefined237>;
  /** Retrieve the number of "MetadataUserDefined237" nodes. */
  metadataUserDefined237Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUserDefined237" nodes. */
  metadataUserDefined237List: Array<MetadataUserDefined237>;
  /** Retrieve a single "MetadataUserDefined238" node. */
  metadataUserDefined238?: Maybe<MetadataUserDefined238>;
  /** Retrieve the number of "MetadataUserDefined238" nodes. */
  metadataUserDefined238Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUserDefined238" nodes. */
  metadataUserDefined238List: Array<MetadataUserDefined238>;
  /** Retrieve a single "MetadataUserDefined239" node. */
  metadataUserDefined239?: Maybe<MetadataUserDefined239>;
  /** Retrieve the number of "MetadataUserDefined239" nodes. */
  metadataUserDefined239Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUserDefined239" nodes. */
  metadataUserDefined239List: Array<MetadataUserDefined239>;
  /** Retrieve a single "MetadataUserDefined242" node. */
  metadataUserDefined242?: Maybe<MetadataUserDefined242>;
  /** Retrieve the number of "MetadataUserDefined242" nodes. */
  metadataUserDefined242Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUserDefined242" nodes. */
  metadataUserDefined242List: Array<MetadataUserDefined242>;
  /** Retrieve a single "MetadataUserDefined62" node. */
  metadataUserDefined62?: Maybe<MetadataUserDefined62>;
  /** Retrieve the number of "MetadataUserDefined62" nodes. */
  metadataUserDefined62Count: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataUserDefined62" nodes. */
  metadataUserDefined62List: Array<MetadataUserDefined62>;
  /** Retrieve a single "MetadataValid" node. */
  metadataValid?: Maybe<MetadataValid>;
  /** Retrieve the number of "MetadataValid" nodes. */
  metadataValidCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataValid" nodes. */
  metadataValidList: Array<MetadataValid>;
  /** Retrieve a single "MetadataVersion" node. */
  metadataVersion?: Maybe<MetadataVersion>;
  /** Retrieve the number of "MetadataVersion" nodes. */
  metadataVersionCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataVersion" nodes. */
  metadataVersionList: Array<MetadataVersion>;
  /** Retrieve a single "MetadataWebStatement" node. */
  metadataWebStatement?: Maybe<MetadataWebStatement>;
  /** Retrieve the number of "MetadataWebStatement" nodes. */
  metadataWebStatementCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataWebStatement" nodes. */
  metadataWebStatementList: Array<MetadataWebStatement>;
  /** Retrieve a single "MetadataWorkflowKind" node. */
  metadataWorkflowKind?: Maybe<MetadataWorkflowKind>;
  /** Retrieve the number of "MetadataWorkflowKind" nodes. */
  metadataWorkflowKindCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataWorkflowKind" nodes. */
  metadataWorkflowKindList: Array<MetadataWorkflowKind>;
  /** Retrieve a single "MetadataXmpFileStamps" node. */
  metadataXmpFileStamps?: Maybe<MetadataXmpFileStamps>;
  /** Retrieve the number of "MetadataXmpFileStamps" nodes. */
  metadataXmpFileStampsCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataXmpFileStamps" nodes. */
  metadataXmpFileStampsList: Array<MetadataXmpFileStamps>;
  /** Retrieve a single "MetadataXmpHistory" node. */
  metadataXmpHistory?: Maybe<MetadataXmpHistory>;
  /** Retrieve the number of "MetadataXmpHistory" nodes. */
  metadataXmpHistoryCount: Scalars['Int']['output'];
  /** Retrieve a list of "MetadataXmpHistory" nodes. */
  metadataXmpHistoryList: Array<MetadataXmpHistory>;
  /** Retrieve a single "Photo" node. */
  photo?: Maybe<Photo>;
  /** Retrieve the number of "Photo" nodes. */
  photoCount: Scalars['Int']['output'];
  /** Retrieve a single "PhotoMetadata" node. */
  photoMetadata?: Maybe<PhotoMetadata>;
  /** Retrieve the number of "PhotoMetadata" nodes. */
  photoMetadataCount: Scalars['Int']['output'];
  /** Retrieve a list of "PhotoMetadata" nodes. */
  photoMetadatas: Array<PhotoMetadata>;
  /** Retrieve a single "PhotoPublication" node. */
  photoPublication?: Maybe<PhotoPublication>;
  /** Retrieve the number of "PhotoPublication" nodes. */
  photoPublicationCount: Scalars['Int']['output'];
  /** Retrieve a list of "PhotoPublication" nodes. */
  photoPublications: Array<PhotoPublication>;
  /** Retrieve a list of "Photo" nodes. */
  photos: Array<Photo>;
  /** optimized for speed search photos query */
  searchPhotos: Array<Photo>;
  /** optimized for speed photos count query */
  searchPhotosCount: Scalars['Int']['output'];
};


export type QueryAgenciesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<AgencyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AgencyWhereInput>;
};


export type QueryAgencyArgs = {
  where: AgencyWhereUniqueInput;
};


export type QueryAgencyCountArgs = {
  where?: InputMaybe<AgencyWhereInput>;
};


export type QueryArchiveArgs = {
  where: ArchiveWhereUniqueInput;
};


export type QueryArchiveAgenciesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<ArchiveAgencyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchiveAgencyWhereInput>;
};


export type QueryArchiveAgencyArgs = {
  where: ArchiveAgencyWhereUniqueInput;
};


export type QueryArchiveAgencyCountArgs = {
  where?: InputMaybe<ArchiveAgencyWhereInput>;
};


export type QueryArchiveCountArgs = {
  where?: InputMaybe<ArchiveWhereInput>;
};


export type QueryArchivesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<ArchiveOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ArchiveWhereInput>;
};


export type QueryCategoriesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<CategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryCategoryArgs = {
  where: CategoryWhereUniqueInput;
};


export type QueryCategoryCountArgs = {
  where?: InputMaybe<CategoryWhereInput>;
};


export type QueryDeliverySubjectArgs = {
  where: DeliverySubjectWhereUniqueInput;
};


export type QueryDeliverySubjectCountArgs = {
  where?: InputMaybe<DeliverySubjectWhereInput>;
};


export type QueryDeliverySubjectsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<DeliverySubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<DeliverySubjectWhereInput>;
};


export type QueryGcImportSettingArgs = {
  where: GcImportSettingWhereUniqueInput;
};


export type QueryGcImportSettingCountArgs = {
  where?: InputMaybe<GcImportSettingWhereInput>;
};


export type QueryGcImportSettingsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<GcImportSettingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<GcImportSettingWhereInput>;
};


export type QueryGetCenshareRailroadArgs = {
  brandKey: Scalars['String']['input'];
  parution: Scalars['String']['input'];
};


export type QueryGetMetadataOccurrenceByArchiveArgs = {
  archiveId: Scalars['Int']['input'];
  first: Scalars['Int']['input'];
  metadata: MetadataNameEnumType;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type QueryImportQueueArgs = {
  where: ImportQueueWhereUniqueInput;
};


export type QueryImportQueueCountArgs = {
  where?: InputMaybe<ImportQueueWhereInput>;
};


export type QueryImportQueuesArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<ImportQueueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<ImportQueueWhereInput>;
};


export type QueryMetadataArgs = {
  where: MetadataWhereUniqueInput;
};


export type QueryMetadataAccessRightsArgs = {
  where: MetadataAccessRightsWhereUniqueInput;
};


export type QueryMetadataAccessRightsCountArgs = {
  where?: InputMaybe<MetadataAccessRightsWhereInput>;
};


export type QueryMetadataAccessRightsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAccessRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAccessRightsWhereInput>;
};


export type QueryMetadataAddlModelInfoArgs = {
  where: MetadataAddlModelInfoWhereUniqueInput;
};


export type QueryMetadataAddlModelInfoCountArgs = {
  where?: InputMaybe<MetadataAddlModelInfoWhereInput>;
};


export type QueryMetadataAddlModelInfoListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAddlModelInfoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAddlModelInfoWhereInput>;
};


export type QueryMetadataArchiveChildArgs = {
  where: MetadataArchiveChildWhereUniqueInput;
};


export type QueryMetadataArchiveChildCountArgs = {
  where?: InputMaybe<MetadataArchiveChildWhereInput>;
};


export type QueryMetadataArchiveChildListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataArchiveChildOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataArchiveChildWhereInput>;
};


export type QueryMetadataArchiveParentArgs = {
  where: MetadataArchiveParentWhereUniqueInput;
};


export type QueryMetadataArchiveParentCountArgs = {
  where?: InputMaybe<MetadataArchiveParentWhereInput>;
};


export type QueryMetadataArchiveParentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataArchiveParentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataArchiveParentWhereInput>;
};


export type QueryMetadataAssetStateArgs = {
  where: MetadataAssetStateWhereUniqueInput;
};


export type QueryMetadataAssetStateCountArgs = {
  where?: InputMaybe<MetadataAssetStateWhereInput>;
};


export type QueryMetadataAssetStateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAssetStateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAssetStateWhereInput>;
};


export type QueryMetadataAttributionNameArgs = {
  where: MetadataAttributionNameWhereUniqueInput;
};


export type QueryMetadataAttributionNameCountArgs = {
  where?: InputMaybe<MetadataAttributionNameWhereInput>;
};


export type QueryMetadataAttributionNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAttributionNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAttributionNameWhereInput>;
};


export type QueryMetadataAttributionUrlArgs = {
  where: MetadataAttributionUrlWhereUniqueInput;
};


export type QueryMetadataAttributionUrlCountArgs = {
  where?: InputMaybe<MetadataAttributionUrlWhereInput>;
};


export type QueryMetadataAttributionUrlListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAttributionUrlOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAttributionUrlWhereInput>;
};


export type QueryMetadataAudienceArgs = {
  where: MetadataAudienceWhereUniqueInput;
};


export type QueryMetadataAudienceCountArgs = {
  where?: InputMaybe<MetadataAudienceWhereInput>;
};


export type QueryMetadataAudienceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAudienceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAudienceWhereInput>;
};


export type QueryMetadataAuthorsPositionArgs = {
  where: MetadataAuthorsPositionWhereUniqueInput;
};


export type QueryMetadataAuthorsPositionCountArgs = {
  where?: InputMaybe<MetadataAuthorsPositionWhereInput>;
};


export type QueryMetadataAuthorsPositionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataAuthorsPositionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataAuthorsPositionWhereInput>;
};


export type QueryMetadataBackupNameArgs = {
  where: MetadataBackupNameWhereUniqueInput;
};


export type QueryMetadataBackupNameCountArgs = {
  where?: InputMaybe<MetadataBackupNameWhereInput>;
};


export type QueryMetadataBackupNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBackupNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBackupNameWhereInput>;
};


export type QueryMetadataBitmapGrayscalePictureArgs = {
  where: MetadataBitmapGrayscalePictureWhereUniqueInput;
};


export type QueryMetadataBitmapGrayscalePictureCountArgs = {
  where?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
};


export type QueryMetadataBitmapGrayscalePictureListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBitmapGrayscalePictureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBitmapGrayscalePictureWhereInput>;
};


export type QueryMetadataBrandArgs = {
  where: MetadataBrandWhereUniqueInput;
};


export type QueryMetadataBrandCountArgs = {
  where?: InputMaybe<MetadataBrandWhereInput>;
};


export type QueryMetadataBrandListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandWhereInput>;
};


export type QueryMetadataBrandPrismaArgs = {
  where: MetadataBrandPrismaWhereUniqueInput;
};


export type QueryMetadataBrandPrismaCountArgs = {
  where?: InputMaybe<MetadataBrandPrismaWhereInput>;
};


export type QueryMetadataBrandPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandPrismaWhereInput>;
};


export type QueryMetadataBrandShortNameArgs = {
  where: MetadataBrandShortNameWhereUniqueInput;
};


export type QueryMetadataBrandShortNameCountArgs = {
  where?: InputMaybe<MetadataBrandShortNameWhereInput>;
};


export type QueryMetadataBrandShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataBrandShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataBrandShortNameWhereInput>;
};


export type QueryMetadataCaptionWriterArgs = {
  where: MetadataCaptionWriterWhereUniqueInput;
};


export type QueryMetadataCaptionWriterCountArgs = {
  where?: InputMaybe<MetadataCaptionWriterWhereInput>;
};


export type QueryMetadataCaptionWriterListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCaptionWriterOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCaptionWriterWhereInput>;
};


export type QueryMetadataCategoryArgs = {
  where: MetadataCategoryWhereUniqueInput;
};


export type QueryMetadataCategoryCountArgs = {
  where?: InputMaybe<MetadataCategoryWhereInput>;
};


export type QueryMetadataCategoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCategoryWhereInput>;
};


export type QueryMetadataCategoryPrismaArgs = {
  where: MetadataCategoryPrismaWhereUniqueInput;
};


export type QueryMetadataCategoryPrismaCountArgs = {
  where?: InputMaybe<MetadataCategoryPrismaWhereInput>;
};


export type QueryMetadataCategoryPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCategoryPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCategoryPrismaWhereInput>;
};


export type QueryMetadataCertificateArgs = {
  where: MetadataCertificateWhereUniqueInput;
};


export type QueryMetadataCertificateCountArgs = {
  where?: InputMaybe<MetadataCertificateWhereInput>;
};


export type QueryMetadataCertificateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCertificateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCertificateWhereInput>;
};


export type QueryMetadataCiAdrCityArgs = {
  where: MetadataCiAdrCityWhereUniqueInput;
};


export type QueryMetadataCiAdrCityCountArgs = {
  where?: InputMaybe<MetadataCiAdrCityWhereInput>;
};


export type QueryMetadataCiAdrCityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrCityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrCityWhereInput>;
};


export type QueryMetadataCiAdrCtryArgs = {
  where: MetadataCiAdrCtryWhereUniqueInput;
};


export type QueryMetadataCiAdrCtryCountArgs = {
  where?: InputMaybe<MetadataCiAdrCtryWhereInput>;
};


export type QueryMetadataCiAdrCtryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrCtryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrCtryWhereInput>;
};


export type QueryMetadataCiAdrExtadrArgs = {
  where: MetadataCiAdrExtadrWhereUniqueInput;
};


export type QueryMetadataCiAdrExtadrCountArgs = {
  where?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
};


export type QueryMetadataCiAdrExtadrListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrExtadrOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrExtadrWhereInput>;
};


export type QueryMetadataCiAdrPcodeArgs = {
  where: MetadataCiAdrPcodeWhereUniqueInput;
};


export type QueryMetadataCiAdrPcodeCountArgs = {
  where?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
};


export type QueryMetadataCiAdrPcodeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrPcodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrPcodeWhereInput>;
};


export type QueryMetadataCiAdrRegionArgs = {
  where: MetadataCiAdrRegionWhereUniqueInput;
};


export type QueryMetadataCiAdrRegionCountArgs = {
  where?: InputMaybe<MetadataCiAdrRegionWhereInput>;
};


export type QueryMetadataCiAdrRegionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiAdrRegionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiAdrRegionWhereInput>;
};


export type QueryMetadataCiEmailWorkArgs = {
  where: MetadataCiEmailWorkWhereUniqueInput;
};


export type QueryMetadataCiEmailWorkCountArgs = {
  where?: InputMaybe<MetadataCiEmailWorkWhereInput>;
};


export type QueryMetadataCiEmailWorkListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiEmailWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiEmailWorkWhereInput>;
};


export type QueryMetadataCiTelWorkArgs = {
  where: MetadataCiTelWorkWhereUniqueInput;
};


export type QueryMetadataCiTelWorkCountArgs = {
  where?: InputMaybe<MetadataCiTelWorkWhereInput>;
};


export type QueryMetadataCiTelWorkListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiTelWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiTelWorkWhereInput>;
};


export type QueryMetadataCityArgs = {
  where: MetadataCityWhereUniqueInput;
};


export type QueryMetadataCityCountArgs = {
  where?: InputMaybe<MetadataCityWhereInput>;
};


export type QueryMetadataCityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCityWhereInput>;
};


export type QueryMetadataCiUrlWorkArgs = {
  where: MetadataCiUrlWorkWhereUniqueInput;
};


export type QueryMetadataCiUrlWorkCountArgs = {
  where?: InputMaybe<MetadataCiUrlWorkWhereInput>;
};


export type QueryMetadataCiUrlWorkListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCiUrlWorkOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCiUrlWorkWhereInput>;
};


export type QueryMetadataClassifyArgs = {
  where: MetadataClassifyWhereUniqueInput;
};


export type QueryMetadataClassifyCountArgs = {
  where?: InputMaybe<MetadataClassifyWhereInput>;
};


export type QueryMetadataClassifyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataClassifyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataClassifyWhereInput>;
};


export type QueryMetadataCompanyArgs = {
  where: MetadataCompanyWhereUniqueInput;
};


export type QueryMetadataCompanyCountArgs = {
  where?: InputMaybe<MetadataCompanyWhereInput>;
};


export type QueryMetadataCompanyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCompanyWhereInput>;
};


export type QueryMetadataCompanyShortNameArgs = {
  where: MetadataCompanyShortNameWhereUniqueInput;
};


export type QueryMetadataCompanyShortNameCountArgs = {
  where?: InputMaybe<MetadataCompanyShortNameWhereInput>;
};


export type QueryMetadataCompanyShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCompanyShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCompanyShortNameWhereInput>;
};


export type QueryMetadataConfidentialityArgs = {
  where: MetadataConfidentialityWhereUniqueInput;
};


export type QueryMetadataConfidentialityCountArgs = {
  where?: InputMaybe<MetadataConfidentialityWhereInput>;
};


export type QueryMetadataConfidentialityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataConfidentialityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataConfidentialityWhereInput>;
};


export type QueryMetadataContainedInArgs = {
  where: MetadataContainedInWhereUniqueInput;
};


export type QueryMetadataContainedInCountArgs = {
  where?: InputMaybe<MetadataContainedInWhereInput>;
};


export type QueryMetadataContainedInListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContainedInOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContainedInWhereInput>;
};


export type QueryMetadataContentMediaKindArgs = {
  where: MetadataContentMediaKindWhereUniqueInput;
};


export type QueryMetadataContentMediaKindCountArgs = {
  where?: InputMaybe<MetadataContentMediaKindWhereInput>;
};


export type QueryMetadataContentMediaKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContentMediaKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContentMediaKindWhereInput>;
};


export type QueryMetadataContentValueArgs = {
  where: MetadataContentValueWhereUniqueInput;
};


export type QueryMetadataContentValueCountArgs = {
  where?: InputMaybe<MetadataContentValueWhereInput>;
};


export type QueryMetadataContentValueListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataContentValueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataContentValueWhereInput>;
};


export type QueryMetadataCopyrightLayerArgs = {
  where: MetadataCopyrightLayerWhereUniqueInput;
};


export type QueryMetadataCopyrightLayerCountArgs = {
  where?: InputMaybe<MetadataCopyrightLayerWhereInput>;
};


export type QueryMetadataCopyrightLayerListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCopyrightLayerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCopyrightLayerWhereInput>;
};


export type QueryMetadataCountArgs = {
  where?: InputMaybe<MetadataWhereInput>;
};


export type QueryMetadataCountryArgs = {
  where: MetadataCountryWhereUniqueInput;
};


export type QueryMetadataCountryCodeArgs = {
  where: MetadataCountryCodeWhereUniqueInput;
};


export type QueryMetadataCountryCodeCountArgs = {
  where?: InputMaybe<MetadataCountryCodeWhereInput>;
};


export type QueryMetadataCountryCodeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCountryCodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCountryCodeWhereInput>;
};


export type QueryMetadataCountryCountArgs = {
  where?: InputMaybe<MetadataCountryWhereInput>;
};


export type QueryMetadataCountryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCountryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCountryWhereInput>;
};


export type QueryMetadataCreatedTimeArgs = {
  where: MetadataCreatedTimeWhereUniqueInput;
};


export type QueryMetadataCreatedTimeCountArgs = {
  where?: InputMaybe<MetadataCreatedTimeWhereInput>;
};


export type QueryMetadataCreatedTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatedTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatedTimeWhereInput>;
};


export type QueryMetadataCreatorArgs = {
  where: MetadataCreatorWhereUniqueInput;
};


export type QueryMetadataCreatorCountArgs = {
  where?: InputMaybe<MetadataCreatorWhereInput>;
};


export type QueryMetadataCreatorListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatorWhereInput>;
};


export type QueryMetadataCreatorToolArgs = {
  where: MetadataCreatorToolWhereUniqueInput;
};


export type QueryMetadataCreatorToolCountArgs = {
  where?: InputMaybe<MetadataCreatorToolWhereInput>;
};


export type QueryMetadataCreatorToolListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreatorToolOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreatorToolWhereInput>;
};


export type QueryMetadataCreditArgs = {
  where: MetadataCreditWhereUniqueInput;
};


export type QueryMetadataCreditCountArgs = {
  where?: InputMaybe<MetadataCreditWhereInput>;
};


export type QueryMetadataCreditListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCreditOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCreditWhereInput>;
};


export type QueryMetadataCustomField14Args = {
  where: MetadataCustomField14WhereUniqueInput;
};


export type QueryMetadataCustomField14CountArgs = {
  where?: InputMaybe<MetadataCustomField14WhereInput>;
};


export type QueryMetadataCustomField14ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField14OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField14WhereInput>;
};


export type QueryMetadataCustomField15Args = {
  where: MetadataCustomField15WhereUniqueInput;
};


export type QueryMetadataCustomField15CountArgs = {
  where?: InputMaybe<MetadataCustomField15WhereInput>;
};


export type QueryMetadataCustomField15ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField15OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField15WhereInput>;
};


export type QueryMetadataCustomField16Args = {
  where: MetadataCustomField16WhereUniqueInput;
};


export type QueryMetadataCustomField16CountArgs = {
  where?: InputMaybe<MetadataCustomField16WhereInput>;
};


export type QueryMetadataCustomField16ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField16OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField16WhereInput>;
};


export type QueryMetadataCustomField17Args = {
  where: MetadataCustomField17WhereUniqueInput;
};


export type QueryMetadataCustomField17CountArgs = {
  where?: InputMaybe<MetadataCustomField17WhereInput>;
};


export type QueryMetadataCustomField17ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField17OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField17WhereInput>;
};


export type QueryMetadataCustomField20Args = {
  where: MetadataCustomField20WhereUniqueInput;
};


export type QueryMetadataCustomField20CountArgs = {
  where?: InputMaybe<MetadataCustomField20WhereInput>;
};


export type QueryMetadataCustomField20ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField20OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField20WhereInput>;
};


export type QueryMetadataCustomField5Args = {
  where: MetadataCustomField5WhereUniqueInput;
};


export type QueryMetadataCustomField5CountArgs = {
  where?: InputMaybe<MetadataCustomField5WhereInput>;
};


export type QueryMetadataCustomField5ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField5OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField5WhereInput>;
};


export type QueryMetadataCustomField6Args = {
  where: MetadataCustomField6WhereUniqueInput;
};


export type QueryMetadataCustomField6CountArgs = {
  where?: InputMaybe<MetadataCustomField6WhereInput>;
};


export type QueryMetadataCustomField6ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField6OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField6WhereInput>;
};


export type QueryMetadataCustomField7Args = {
  where: MetadataCustomField7WhereUniqueInput;
};


export type QueryMetadataCustomField7CountArgs = {
  where?: InputMaybe<MetadataCustomField7WhereInput>;
};


export type QueryMetadataCustomField7ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField7OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField7WhereInput>;
};


export type QueryMetadataCustomField9Args = {
  where: MetadataCustomField9WhereUniqueInput;
};


export type QueryMetadataCustomField9CountArgs = {
  where?: InputMaybe<MetadataCustomField9WhereInput>;
};


export type QueryMetadataCustomField9ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataCustomField9OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataCustomField9WhereInput>;
};


export type QueryMetadataDateCreatedArgs = {
  where: MetadataDateCreatedWhereUniqueInput;
};


export type QueryMetadataDateCreatedCountArgs = {
  where?: InputMaybe<MetadataDateCreatedWhereInput>;
};


export type QueryMetadataDateCreatedListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateCreatedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateCreatedWhereInput>;
};


export type QueryMetadataDateTimeDigitizedArgs = {
  where: MetadataDateTimeDigitizedWhereUniqueInput;
};


export type QueryMetadataDateTimeDigitizedCountArgs = {
  where?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
};


export type QueryMetadataDateTimeDigitizedListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateTimeDigitizedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateTimeDigitizedWhereInput>;
};


export type QueryMetadataDateTimeOriginalArgs = {
  where: MetadataDateTimeOriginalWhereUniqueInput;
};


export type QueryMetadataDateTimeOriginalCountArgs = {
  where?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
};


export type QueryMetadataDateTimeOriginalListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDateTimeOriginalOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDateTimeOriginalWhereInput>;
};


export type QueryMetadataDeliveryAccountArgs = {
  where: MetadataDeliveryAccountWhereUniqueInput;
};


export type QueryMetadataDeliveryAccountCountArgs = {
  where?: InputMaybe<MetadataDeliveryAccountWhereInput>;
};


export type QueryMetadataDeliveryAccountListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryAccountOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryAccountWhereInput>;
};


export type QueryMetadataDeliveryCompanyArgs = {
  where: MetadataDeliveryCompanyWhereUniqueInput;
};


export type QueryMetadataDeliveryCompanyCountArgs = {
  where?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
};


export type QueryMetadataDeliveryCompanyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryCompanyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryCompanyWhereInput>;
};


export type QueryMetadataDeliveryCopyrightArgs = {
  where: MetadataDeliveryCopyrightWhereUniqueInput;
};


export type QueryMetadataDeliveryCopyrightCountArgs = {
  where?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
};


export type QueryMetadataDeliveryCopyrightListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryCopyrightOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryCopyrightWhereInput>;
};


export type QueryMetadataDeliveryDateFolderArgs = {
  where: MetadataDeliveryDateFolderWhereUniqueInput;
};


export type QueryMetadataDeliveryDateFolderCountArgs = {
  where?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
};


export type QueryMetadataDeliveryDateFolderListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDateFolderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDateFolderWhereInput>;
};


export type QueryMetadataDeliveryDateTimeArgs = {
  where: MetadataDeliveryDateTimeWhereUniqueInput;
};


export type QueryMetadataDeliveryDateTimeCountArgs = {
  where?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
};


export type QueryMetadataDeliveryDateTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDateTimeWhereInput>;
};


export type QueryMetadataDeliveryDcCreatorArgs = {
  where: MetadataDeliveryDcCreatorWhereUniqueInput;
};


export type QueryMetadataDeliveryDcCreatorCountArgs = {
  where?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
};


export type QueryMetadataDeliveryDcCreatorListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDcCreatorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDcCreatorWhereInput>;
};


export type QueryMetadataDeliveryDcRightsArgs = {
  where: MetadataDeliveryDcRightsWhereUniqueInput;
};


export type QueryMetadataDeliveryDcRightsCountArgs = {
  where?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
};


export type QueryMetadataDeliveryDcRightsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryDcRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryDcRightsWhereInput>;
};


export type QueryMetadataDeliveryFileTypeArgs = {
  where: MetadataDeliveryFileTypeWhereUniqueInput;
};


export type QueryMetadataDeliveryFileTypeCountArgs = {
  where?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
};


export type QueryMetadataDeliveryFileTypeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryFileTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryFileTypeWhereInput>;
};


export type QueryMetadataDeliveryFolderArgs = {
  where: MetadataDeliveryFolderWhereUniqueInput;
};


export type QueryMetadataDeliveryFolderCountArgs = {
  where?: InputMaybe<MetadataDeliveryFolderWhereInput>;
};


export type QueryMetadataDeliveryFolderListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryFolderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryFolderWhereInput>;
};


export type QueryMetadataDeliveryKindArgs = {
  where: MetadataDeliveryKindWhereUniqueInput;
};


export type QueryMetadataDeliveryKindCountArgs = {
  where?: InputMaybe<MetadataDeliveryKindWhereInput>;
};


export type QueryMetadataDeliveryKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryKindWhereInput>;
};


export type QueryMetadataDeliveryPathArgs = {
  where: MetadataDeliveryPathWhereUniqueInput;
};


export type QueryMetadataDeliveryPathCountArgs = {
  where?: InputMaybe<MetadataDeliveryPathWhereInput>;
};


export type QueryMetadataDeliveryPathListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPathOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPathWhereInput>;
};


export type QueryMetadataDeliveryPersonShownintheImageArgs = {
  where: MetadataDeliveryPersonShownintheImageWhereUniqueInput;
};


export type QueryMetadataDeliveryPersonShownintheImageCountArgs = {
  where?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
};


export type QueryMetadataDeliveryPersonShownintheImageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPersonShownintheImageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPersonShownintheImageWhereInput>;
};


export type QueryMetadataDeliveryPhotoshopCreditArgs = {
  where: MetadataDeliveryPhotoshopCreditWhereUniqueInput;
};


export type QueryMetadataDeliveryPhotoshopCreditCountArgs = {
  where?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
};


export type QueryMetadataDeliveryPhotoshopCreditListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPhotoshopCreditOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPhotoshopCreditWhereInput>;
};


export type QueryMetadataDeliveryPhotoshopSourceArgs = {
  where: MetadataDeliveryPhotoshopSourceWhereUniqueInput;
};


export type QueryMetadataDeliveryPhotoshopSourceCountArgs = {
  where?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
};


export type QueryMetadataDeliveryPhotoshopSourceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryPhotoshopSourceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryPhotoshopSourceWhereInput>;
};


export type QueryMetadataDeliveryServiceArgs = {
  where: MetadataDeliveryServiceWhereUniqueInput;
};


export type QueryMetadataDeliveryServiceCountArgs = {
  where?: InputMaybe<MetadataDeliveryServiceWhereInput>;
};


export type QueryMetadataDeliveryServiceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryServiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryServiceWhereInput>;
};


export type QueryMetadataDeliverySubjectArgs = {
  where: MetadataDeliverySubjectWhereUniqueInput;
};


export type QueryMetadataDeliverySubjectCountArgs = {
  where?: InputMaybe<MetadataDeliverySubjectWhereInput>;
};


export type QueryMetadataDeliverySubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliverySubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliverySubjectWhereInput>;
};


export type QueryMetadataDeliveryUnderSubjectArgs = {
  where: MetadataDeliveryUnderSubjectWhereUniqueInput;
};


export type QueryMetadataDeliveryUnderSubjectCountArgs = {
  where?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
};


export type QueryMetadataDeliveryUnderSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDeliveryUnderSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDeliveryUnderSubjectWhereInput>;
};


export type QueryMetadataDepartmentArgs = {
  where: MetadataDepartmentWhereUniqueInput;
};


export type QueryMetadataDepartmentCountArgs = {
  where?: InputMaybe<MetadataDepartmentWhereInput>;
};


export type QueryMetadataDepartmentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDepartmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDepartmentWhereInput>;
};


export type QueryMetadataDescriptionArgs = {
  where: MetadataDescriptionWhereUniqueInput;
};


export type QueryMetadataDescriptionCountArgs = {
  where?: InputMaybe<MetadataDescriptionWhereInput>;
};


export type QueryMetadataDescriptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDescriptionWhereInput>;
};


export type QueryMetadataDigitalAssetUrlArgs = {
  where: MetadataDigitalAssetUrlWhereUniqueInput;
};


export type QueryMetadataDigitalAssetUrlCountArgs = {
  where?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
};


export type QueryMetadataDigitalAssetUrlListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDigitalAssetUrlOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDigitalAssetUrlWhereInput>;
};


export type QueryMetadataDivisionArgs = {
  where: MetadataDivisionWhereUniqueInput;
};


export type QueryMetadataDivisionCountArgs = {
  where?: InputMaybe<MetadataDivisionWhereInput>;
};


export type QueryMetadataDivisionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDivisionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDivisionWhereInput>;
};


export type QueryMetadataDocumentTextArgs = {
  where: MetadataDocumentTextWhereUniqueInput;
};


export type QueryMetadataDocumentTextCountArgs = {
  where?: InputMaybe<MetadataDocumentTextWhereInput>;
};


export type QueryMetadataDocumentTextListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataDocumentTextOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataDocumentTextWhereInput>;
};


export type QueryMetadataEditionArgs = {
  where: MetadataEditionWhereUniqueInput;
};


export type QueryMetadataEditionCountArgs = {
  where?: InputMaybe<MetadataEditionWhereInput>;
};


export type QueryMetadataEditionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditionWhereInput>;
};


export type QueryMetadataEditorialVersionArgs = {
  where: MetadataEditorialVersionWhereUniqueInput;
};


export type QueryMetadataEditorialVersionCountArgs = {
  where?: InputMaybe<MetadataEditorialVersionWhereInput>;
};


export type QueryMetadataEditorialVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditorialVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditorialVersionWhereInput>;
};


export type QueryMetadataEditStatusArgs = {
  where: MetadataEditStatusWhereUniqueInput;
};


export type QueryMetadataEditStatusCountArgs = {
  where?: InputMaybe<MetadataEditStatusWhereInput>;
};


export type QueryMetadataEditStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEditStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEditStatusWhereInput>;
};


export type QueryMetadataEnvironnementPhotoArgs = {
  where: MetadataEnvironnementPhotoWhereUniqueInput;
};


export type QueryMetadataEnvironnementPhotoCountArgs = {
  where?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
};


export type QueryMetadataEnvironnementPhotoListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEnvironnementPhotoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEnvironnementPhotoWhereInput>;
};


export type QueryMetadataEquipmentInstitutionArgs = {
  where: MetadataEquipmentInstitutionWhereUniqueInput;
};


export type QueryMetadataEquipmentInstitutionCountArgs = {
  where?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
};


export type QueryMetadataEquipmentInstitutionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEquipmentInstitutionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEquipmentInstitutionWhereInput>;
};


export type QueryMetadataEquipmentManufacturerArgs = {
  where: MetadataEquipmentManufacturerWhereUniqueInput;
};


export type QueryMetadataEquipmentManufacturerCountArgs = {
  where?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
};


export type QueryMetadataEquipmentManufacturerListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEquipmentManufacturerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEquipmentManufacturerWhereInput>;
};


export type QueryMetadataEventArgs = {
  where: MetadataEventWhereUniqueInput;
};


export type QueryMetadataEventCountArgs = {
  where?: InputMaybe<MetadataEventWhereInput>;
};


export type QueryMetadataEventListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataEventOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataEventWhereInput>;
};


export type QueryMetadataExifArgs = {
  where: MetadataExifWhereUniqueInput;
};


export type QueryMetadataExifCountArgs = {
  where?: InputMaybe<MetadataExifWhereInput>;
};


export type QueryMetadataExifListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataExifOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataExifWhereInput>;
};


export type QueryMetadataFabStorageArgs = {
  where: MetadataFabStorageWhereUniqueInput;
};


export type QueryMetadataFabStorageCountArgs = {
  where?: InputMaybe<MetadataFabStorageWhereInput>;
};


export type QueryMetadataFabStorageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFabStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFabStorageWhereInput>;
};


export type QueryMetadataFileCheckForArgs = {
  where: MetadataFileCheckForWhereUniqueInput;
};


export type QueryMetadataFileCheckForCountArgs = {
  where?: InputMaybe<MetadataFileCheckForWhereInput>;
};


export type QueryMetadataFileCheckForListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileCheckForOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileCheckForWhereInput>;
};


export type QueryMetadataFileInfoBitsPerPixelArgs = {
  where: MetadataFileInfoBitsPerPixelWhereUniqueInput;
};


export type QueryMetadataFileInfoBitsPerPixelCountArgs = {
  where?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
};


export type QueryMetadataFileInfoBitsPerPixelListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoBitsPerPixelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoBitsPerPixelWhereInput>;
};


export type QueryMetadataFileInfoFramesArgs = {
  where: MetadataFileInfoFramesWhereUniqueInput;
};


export type QueryMetadataFileInfoFramesCountArgs = {
  where?: InputMaybe<MetadataFileInfoFramesWhereInput>;
};


export type QueryMetadataFileInfoFramesListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoFramesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoFramesWhereInput>;
};


export type QueryMetadataFileInfoPhotometricInterpretationArgs = {
  where: MetadataFileInfoPhotometricInterpretationWhereUniqueInput;
};


export type QueryMetadataFileInfoPhotometricInterpretationCountArgs = {
  where?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
};


export type QueryMetadataFileInfoPhotometricInterpretationListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoPhotometricInterpretationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoPhotometricInterpretationWhereInput>;
};


export type QueryMetadataFileInfoTransferSyntaxArgs = {
  where: MetadataFileInfoTransferSyntaxWhereUniqueInput;
};


export type QueryMetadataFileInfoTransferSyntaxCountArgs = {
  where?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
};


export type QueryMetadataFileInfoTransferSyntaxListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileInfoTransferSyntaxOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileInfoTransferSyntaxWhereInput>;
};


export type QueryMetadataFileStageArgs = {
  where: MetadataFileStageWhereUniqueInput;
};


export type QueryMetadataFileStageCountArgs = {
  where?: InputMaybe<MetadataFileStageWhereInput>;
};


export type QueryMetadataFileStageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileStageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileStageWhereInput>;
};


export type QueryMetadataFileStatusArgs = {
  where: MetadataFileStatusWhereUniqueInput;
};


export type QueryMetadataFileStatusCountArgs = {
  where?: InputMaybe<MetadataFileStatusWhereInput>;
};


export type QueryMetadataFileStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileStatusWhereInput>;
};


export type QueryMetadataFileTypeArgs = {
  where: MetadataFileTypeWhereUniqueInput;
};


export type QueryMetadataFileTypeCountArgs = {
  where?: InputMaybe<MetadataFileTypeWhereInput>;
};


export type QueryMetadataFileTypeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFileTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFileTypeWhereInput>;
};


export type QueryMetadataFixtureIdentifierArgs = {
  where: MetadataFixtureIdentifierWhereUniqueInput;
};


export type QueryMetadataFixtureIdentifierCountArgs = {
  where?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
};


export type QueryMetadataFixtureIdentifierListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFixtureIdentifierOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFixtureIdentifierWhereInput>;
};


export type QueryMetadataFormatArgs = {
  where: MetadataFormatWhereUniqueInput;
};


export type QueryMetadataFormatCountArgs = {
  where?: InputMaybe<MetadataFormatWhereInput>;
};


export type QueryMetadataFormatListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataFormatOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataFormatWhereInput>;
};


export type QueryMetadataGpsLatitudeArgs = {
  where: MetadataGpsLatitudeWhereUniqueInput;
};


export type QueryMetadataGpsLatitudeCountArgs = {
  where?: InputMaybe<MetadataGpsLatitudeWhereInput>;
};


export type QueryMetadataGpsLatitudeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsLatitudeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsLatitudeWhereInput>;
};


export type QueryMetadataGpsLongitudeArgs = {
  where: MetadataGpsLongitudeWhereUniqueInput;
};


export type QueryMetadataGpsLongitudeCountArgs = {
  where?: InputMaybe<MetadataGpsLongitudeWhereInput>;
};


export type QueryMetadataGpsLongitudeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsLongitudeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsLongitudeWhereInput>;
};


export type QueryMetadataGpsProcessingMethodArgs = {
  where: MetadataGpsProcessingMethodWhereUniqueInput;
};


export type QueryMetadataGpsProcessingMethodCountArgs = {
  where?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
};


export type QueryMetadataGpsProcessingMethodListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataGpsProcessingMethodOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataGpsProcessingMethodWhereInput>;
};


export type QueryMetadataHeaderArgs = {
  where: MetadataHeaderWhereUniqueInput;
};


export type QueryMetadataHeaderCountArgs = {
  where?: InputMaybe<MetadataHeaderWhereInput>;
};


export type QueryMetadataHeaderListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeaderOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeaderWhereInput>;
};


export type QueryMetadataHeaderPrismaArgs = {
  where: MetadataHeaderPrismaWhereUniqueInput;
};


export type QueryMetadataHeaderPrismaCountArgs = {
  where?: InputMaybe<MetadataHeaderPrismaWhereInput>;
};


export type QueryMetadataHeaderPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeaderPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeaderPrismaWhereInput>;
};


export type QueryMetadataHeadlineArgs = {
  where: MetadataHeadlineWhereUniqueInput;
};


export type QueryMetadataHeadlineCountArgs = {
  where?: InputMaybe<MetadataHeadlineWhereInput>;
};


export type QueryMetadataHeadlineListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHeadlineOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHeadlineWhereInput>;
};


export type QueryMetadataHistoryArgs = {
  where: MetadataHistoryWhereUniqueInput;
};


export type QueryMetadataHistoryCountArgs = {
  where?: InputMaybe<MetadataHistoryWhereInput>;
};


export type QueryMetadataHistoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataHistoryWhereInput>;
};


export type QueryMetadataIccProfileArgs = {
  where: MetadataIccProfileWhereUniqueInput;
};


export type QueryMetadataIccProfileCountArgs = {
  where?: InputMaybe<MetadataIccProfileWhereInput>;
};


export type QueryMetadataIccProfileListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIccProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIccProfileWhereInput>;
};


export type QueryMetadataIdAssignmentArgs = {
  where: MetadataIdAssignmentWhereUniqueInput;
};


export type QueryMetadataIdAssignmentCountArgs = {
  where?: InputMaybe<MetadataIdAssignmentWhereInput>;
};


export type QueryMetadataIdAssignmentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdAssignmentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdAssignmentWhereInput>;
};


export type QueryMetadataIdMediaContentArgs = {
  where: MetadataIdMediaContentWhereUniqueInput;
};


export type QueryMetadataIdMediaContentCountArgs = {
  where?: InputMaybe<MetadataIdMediaContentWhereInput>;
};


export type QueryMetadataIdMediaContentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdMediaContentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdMediaContentWhereInput>;
};


export type QueryMetadataIdPhotoPrismaArgs = {
  where: MetadataIdPhotoPrismaWhereUniqueInput;
};


export type QueryMetadataIdPhotoPrismaCountArgs = {
  where?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
};


export type QueryMetadataIdPhotoPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdPhotoPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdPhotoPrismaWhereInput>;
};


export type QueryMetadataIdSubjectArgs = {
  where: MetadataIdSubjectWhereUniqueInput;
};


export type QueryMetadataIdSubjectCountArgs = {
  where?: InputMaybe<MetadataIdSubjectWhereInput>;
};


export type QueryMetadataIdSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIdSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIdSubjectWhereInput>;
};


export type QueryMetadataImageNotesArgs = {
  where: MetadataImageNotesWhereUniqueInput;
};


export type QueryMetadataImageNotesCountArgs = {
  where?: InputMaybe<MetadataImageNotesWhereInput>;
};


export type QueryMetadataImageNotesListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataImageNotesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataImageNotesWhereInput>;
};


export type QueryMetadataImageUniqueIdArgs = {
  where: MetadataImageUniqueIdWhereUniqueInput;
};


export type QueryMetadataImageUniqueIdCountArgs = {
  where?: InputMaybe<MetadataImageUniqueIdWhereInput>;
};


export type QueryMetadataImageUniqueIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataImageUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataImageUniqueIdWhereInput>;
};


export type QueryMetadataInstructionsArgs = {
  where: MetadataInstructionsWhereUniqueInput;
};


export type QueryMetadataInstructionsCountArgs = {
  where?: InputMaybe<MetadataInstructionsWhereInput>;
};


export type QueryMetadataInstructionsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataInstructionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataInstructionsWhereInput>;
};


export type QueryMetadataIntellectualGenreArgs = {
  where: MetadataIntellectualGenreWhereUniqueInput;
};


export type QueryMetadataIntellectualGenreCountArgs = {
  where?: InputMaybe<MetadataIntellectualGenreWhereInput>;
};


export type QueryMetadataIntellectualGenreListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIntellectualGenreOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIntellectualGenreWhereInput>;
};


export type QueryMetadataIsoSpeedRatingsArgs = {
  where: MetadataIsoSpeedRatingsWhereUniqueInput;
};


export type QueryMetadataIsoSpeedRatingsCountArgs = {
  where?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
};


export type QueryMetadataIsoSpeedRatingsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIsoSpeedRatingsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIsoSpeedRatingsWhereInput>;
};


export type QueryMetadataIssueArgs = {
  where: MetadataIssueWhereUniqueInput;
};


export type QueryMetadataIssueCountArgs = {
  where?: InputMaybe<MetadataIssueWhereInput>;
};


export type QueryMetadataIssueListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataIssueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataIssueWhereInput>;
};


export type QueryMetadataJobIdArgs = {
  where: MetadataJobIdWhereUniqueInput;
};


export type QueryMetadataJobIdCountArgs = {
  where?: InputMaybe<MetadataJobIdWhereInput>;
};


export type QueryMetadataJobIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataJobIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataJobIdWhereInput>;
};


export type QueryMetadataJobProcessingArgs = {
  where: MetadataJobProcessingWhereUniqueInput;
};


export type QueryMetadataJobProcessingCountArgs = {
  where?: InputMaybe<MetadataJobProcessingWhereInput>;
};


export type QueryMetadataJobProcessingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataJobProcessingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataJobProcessingWhereInput>;
};


export type QueryMetadataLayoutPictureEditingArgs = {
  where: MetadataLayoutPictureEditingWhereUniqueInput;
};


export type QueryMetadataLayoutPictureEditingCountArgs = {
  where?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
};


export type QueryMetadataLayoutPictureEditingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLayoutPictureEditingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLayoutPictureEditingWhereInput>;
};


export type QueryMetadataLayoutStorageArgs = {
  where: MetadataLayoutStorageWhereUniqueInput;
};


export type QueryMetadataLayoutStorageCountArgs = {
  where?: InputMaybe<MetadataLayoutStorageWhereInput>;
};


export type QueryMetadataLayoutStorageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLayoutStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLayoutStorageWhereInput>;
};


export type QueryMetadataLicenseArgs = {
  where: MetadataLicenseWhereUniqueInput;
};


export type QueryMetadataLicenseCountArgs = {
  where?: InputMaybe<MetadataLicenseWhereInput>;
};


export type QueryMetadataLicenseListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLicenseOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLicenseWhereInput>;
};


export type QueryMetadataLinksArgs = {
  where: MetadataLinksWhereUniqueInput;
};


export type QueryMetadataLinksCountArgs = {
  where?: InputMaybe<MetadataLinksWhereInput>;
};


export type QueryMetadataLinksListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLinksOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLinksWhereInput>;
};


export type QueryMetadataLocalCaptionArgs = {
  where: MetadataLocalCaptionWhereUniqueInput;
};


export type QueryMetadataLocalCaptionCountArgs = {
  where?: InputMaybe<MetadataLocalCaptionWhereInput>;
};


export type QueryMetadataLocalCaptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLocalCaptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLocalCaptionWhereInput>;
};


export type QueryMetadataLocationArgs = {
  where: MetadataLocationWhereUniqueInput;
};


export type QueryMetadataLocationCountArgs = {
  where?: InputMaybe<MetadataLocationWhereInput>;
};


export type QueryMetadataLocationListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataLocationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataLocationWhereInput>;
};


export type QueryMetadataMakeArgs = {
  where: MetadataMakeWhereUniqueInput;
};


export type QueryMetadataMakeCountArgs = {
  where?: InputMaybe<MetadataMakeWhereInput>;
};


export type QueryMetadataMakeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMakeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMakeWhereInput>;
};


export type QueryMetadataManifestArgs = {
  where: MetadataManifestWhereUniqueInput;
};


export type QueryMetadataManifestCountArgs = {
  where?: InputMaybe<MetadataManifestWhereInput>;
};


export type QueryMetadataManifestListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataManifestOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataManifestWhereInput>;
};


export type QueryMetadataMarkedArgs = {
  where: MetadataMarkedWhereUniqueInput;
};


export type QueryMetadataMarkedCountArgs = {
  where?: InputMaybe<MetadataMarkedWhereInput>;
};


export type QueryMetadataMarkedListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMarkedOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMarkedWhereInput>;
};


export type QueryMetadataMasterDocumentIdArgs = {
  where: MetadataMasterDocumentIdWhereUniqueInput;
};


export type QueryMetadataMasterDocumentIdCountArgs = {
  where?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
};


export type QueryMetadataMasterDocumentIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMasterDocumentIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMasterDocumentIdWhereInput>;
};


export type QueryMetadataMaxAvailHeightArgs = {
  where: MetadataMaxAvailHeightWhereUniqueInput;
};


export type QueryMetadataMaxAvailHeightCountArgs = {
  where?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
};


export type QueryMetadataMaxAvailHeightListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMaxAvailHeightOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMaxAvailHeightWhereInput>;
};


export type QueryMetadataMaxAvailWidthArgs = {
  where: MetadataMaxAvailWidthWhereUniqueInput;
};


export type QueryMetadataMaxAvailWidthCountArgs = {
  where?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
};


export type QueryMetadataMaxAvailWidthListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMaxAvailWidthOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMaxAvailWidthWhereInput>;
};


export type QueryMetadataMinorModelAgeDisclosureArgs = {
  where: MetadataMinorModelAgeDisclosureWhereUniqueInput;
};


export type QueryMetadataMinorModelAgeDisclosureCountArgs = {
  where?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
};


export type QueryMetadataMinorModelAgeDisclosureListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMinorModelAgeDisclosureOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMinorModelAgeDisclosureWhereInput>;
};


export type QueryMetadataModelArgs = {
  where: MetadataModelWhereUniqueInput;
};


export type QueryMetadataModelAgeArgs = {
  where: MetadataModelAgeWhereUniqueInput;
};


export type QueryMetadataModelAgeCountArgs = {
  where?: InputMaybe<MetadataModelAgeWhereInput>;
};


export type QueryMetadataModelAgeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelAgeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelAgeWhereInput>;
};


export type QueryMetadataModelCountArgs = {
  where?: InputMaybe<MetadataModelWhereInput>;
};


export type QueryMetadataModelListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelWhereInput>;
};


export type QueryMetadataModelReleaseIdArgs = {
  where: MetadataModelReleaseIdWhereUniqueInput;
};


export type QueryMetadataModelReleaseIdCountArgs = {
  where?: InputMaybe<MetadataModelReleaseIdWhereInput>;
};


export type QueryMetadataModelReleaseIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelReleaseIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelReleaseIdWhereInput>;
};


export type QueryMetadataModelReleaseStatusArgs = {
  where: MetadataModelReleaseStatusWhereUniqueInput;
};


export type QueryMetadataModelReleaseStatusCountArgs = {
  where?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
};


export type QueryMetadataModelReleaseStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataModelReleaseStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataModelReleaseStatusWhereInput>;
};


export type QueryMetadataMorePermissionsArgs = {
  where: MetadataMorePermissionsWhereUniqueInput;
};


export type QueryMetadataMorePermissionsCountArgs = {
  where?: InputMaybe<MetadataMorePermissionsWhereInput>;
};


export type QueryMetadataMorePermissionsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataMorePermissionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataMorePermissionsWhereInput>;
};


export type QueryMetadataObjectCycleArgs = {
  where: MetadataObjectCycleWhereUniqueInput;
};


export type QueryMetadataObjectCycleCountArgs = {
  where?: InputMaybe<MetadataObjectCycleWhereInput>;
};


export type QueryMetadataObjectCycleListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataObjectCycleOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataObjectCycleWhereInput>;
};


export type QueryMetadataOrganisationInImageNameArgs = {
  where: MetadataOrganisationInImageNameWhereUniqueInput;
};


export type QueryMetadataOrganisationInImageNameCountArgs = {
  where?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
};


export type QueryMetadataOrganisationInImageNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOrganisationInImageNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOrganisationInImageNameWhereInput>;
};


export type QueryMetadataOriginalColorArgs = {
  where: MetadataOriginalColorWhereUniqueInput;
};


export type QueryMetadataOriginalColorCountArgs = {
  where?: InputMaybe<MetadataOriginalColorWhereInput>;
};


export type QueryMetadataOriginalColorListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalColorOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalColorWhereInput>;
};


export type QueryMetadataOriginalIccProfileArgs = {
  where: MetadataOriginalIccProfileWhereUniqueInput;
};


export type QueryMetadataOriginalIccProfileCountArgs = {
  where?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
};


export type QueryMetadataOriginalIccProfileListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalIccProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalIccProfileWhereInput>;
};


export type QueryMetadataOriginalNameArgs = {
  where: MetadataOriginalNameWhereUniqueInput;
};


export type QueryMetadataOriginalNameCountArgs = {
  where?: InputMaybe<MetadataOriginalNameWhereInput>;
};


export type QueryMetadataOriginalNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalNameWhereInput>;
};


export type QueryMetadataOriginalWeigthArgs = {
  where: MetadataOriginalWeigthWhereUniqueInput;
};


export type QueryMetadataOriginalWeigthCountArgs = {
  where?: InputMaybe<MetadataOriginalWeigthWhereInput>;
};


export type QueryMetadataOriginalWeigthListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOriginalWeigthOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOriginalWeigthWhereInput>;
};


export type QueryMetadataOwnerArgs = {
  where: MetadataOwnerWhereUniqueInput;
};


export type QueryMetadataOwnerCountArgs = {
  where?: InputMaybe<MetadataOwnerWhereInput>;
};


export type QueryMetadataOwnerIdArgs = {
  where: MetadataOwnerIdWhereUniqueInput;
};


export type QueryMetadataOwnerIdCountArgs = {
  where?: InputMaybe<MetadataOwnerIdWhereInput>;
};


export type QueryMetadataOwnerIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOwnerIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOwnerIdWhereInput>;
};


export type QueryMetadataOwnerListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOwnerOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataOwnerWhereInput>;
};


export type QueryMetadataPageArgs = {
  where: MetadataPageWhereUniqueInput;
};


export type QueryMetadataPageCountArgs = {
  where?: InputMaybe<MetadataPageWhereInput>;
};


export type QueryMetadataPageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPageWhereInput>;
};


export type QueryMetadataPagePrismaArgs = {
  where: MetadataPagePrismaWhereUniqueInput;
};


export type QueryMetadataPagePrismaCountArgs = {
  where?: InputMaybe<MetadataPagePrismaWhereInput>;
};


export type QueryMetadataPagePrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPagePrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPagePrismaWhereInput>;
};


export type QueryMetadataPaintBasedCorrectionsArgs = {
  where: MetadataPaintBasedCorrectionsWhereUniqueInput;
};


export type QueryMetadataPaintBasedCorrectionsCountArgs = {
  where?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
};


export type QueryMetadataPaintBasedCorrectionsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPaintBasedCorrectionsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPaintBasedCorrectionsWhereInput>;
};


export type QueryMetadataPatientDobArgs = {
  where: MetadataPatientDobWhereUniqueInput;
};


export type QueryMetadataPatientDobCountArgs = {
  where?: InputMaybe<MetadataPatientDobWhereInput>;
};


export type QueryMetadataPatientDobListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientDobOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientDobWhereInput>;
};


export type QueryMetadataPatientIdArgs = {
  where: MetadataPatientIdWhereUniqueInput;
};


export type QueryMetadataPatientIdCountArgs = {
  where?: InputMaybe<MetadataPatientIdWhereInput>;
};


export type QueryMetadataPatientIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientIdWhereInput>;
};


export type QueryMetadataPatientNameArgs = {
  where: MetadataPatientNameWhereUniqueInput;
};


export type QueryMetadataPatientNameCountArgs = {
  where?: InputMaybe<MetadataPatientNameWhereInput>;
};


export type QueryMetadataPatientNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientNameWhereInput>;
};


export type QueryMetadataPatientSexArgs = {
  where: MetadataPatientSexWhereUniqueInput;
};


export type QueryMetadataPatientSexCountArgs = {
  where?: InputMaybe<MetadataPatientSexWhereInput>;
};


export type QueryMetadataPatientSexListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPatientSexOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPatientSexWhereInput>;
};


export type QueryMetadataPersonInImageArgs = {
  where: MetadataPersonInImageWhereUniqueInput;
};


export type QueryMetadataPersonInImageCountArgs = {
  where?: InputMaybe<MetadataPersonInImageWhereInput>;
};


export type QueryMetadataPersonInImageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPersonInImageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPersonInImageWhereInput>;
};


export type QueryMetadataPhotoStorageArgs = {
  where: MetadataPhotoStorageWhereUniqueInput;
};


export type QueryMetadataPhotoStorageCountArgs = {
  where?: InputMaybe<MetadataPhotoStorageWhereInput>;
};


export type QueryMetadataPhotoStorageListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPhotoStorageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPhotoStorageWhereInput>;
};


export type QueryMetadataPrepressPictureEditingArgs = {
  where: MetadataPrepressPictureEditingWhereUniqueInput;
};


export type QueryMetadataPrepressPictureEditingCountArgs = {
  where?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
};


export type QueryMetadataPrepressPictureEditingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrepressPictureEditingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrepressPictureEditingWhereInput>;
};


export type QueryMetadataPriceLevelArgs = {
  where: MetadataPriceLevelWhereUniqueInput;
};


export type QueryMetadataPriceLevelCountArgs = {
  where?: InputMaybe<MetadataPriceLevelWhereInput>;
};


export type QueryMetadataPriceLevelListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPriceLevelOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPriceLevelWhereInput>;
};


export type QueryMetadataPrintingProfileArgs = {
  where: MetadataPrintingProfileWhereUniqueInput;
};


export type QueryMetadataPrintingProfileCountArgs = {
  where?: InputMaybe<MetadataPrintingProfileWhereInput>;
};


export type QueryMetadataPrintingProfileListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrintingProfileOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrintingProfileWhereInput>;
};


export type QueryMetadataPrismaProductionArgs = {
  where: MetadataPrismaProductionWhereUniqueInput;
};


export type QueryMetadataPrismaProductionCountArgs = {
  where?: InputMaybe<MetadataPrismaProductionWhereInput>;
};


export type QueryMetadataPrismaProductionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPrismaProductionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPrismaProductionWhereInput>;
};


export type QueryMetadataProcessHistoryArgs = {
  where: MetadataProcessHistoryWhereUniqueInput;
};


export type QueryMetadataProcessHistoryCountArgs = {
  where?: InputMaybe<MetadataProcessHistoryWhereInput>;
};


export type QueryMetadataProcessHistoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessHistoryWhereInput>;
};


export type QueryMetadataProcessParameterArgs = {
  where: MetadataProcessParameterWhereUniqueInput;
};


export type QueryMetadataProcessParameterCountArgs = {
  where?: InputMaybe<MetadataProcessParameterWhereInput>;
};


export type QueryMetadataProcessParameterListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessParameterOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessParameterWhereInput>;
};


export type QueryMetadataProcessStatusArgs = {
  where: MetadataProcessStatusWhereUniqueInput;
};


export type QueryMetadataProcessStatusCountArgs = {
  where?: InputMaybe<MetadataProcessStatusWhereInput>;
};


export type QueryMetadataProcessStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProcessStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProcessStatusWhereInput>;
};


export type QueryMetadataProductArgs = {
  where: MetadataProductWhereUniqueInput;
};


export type QueryMetadataProductCountArgs = {
  where?: InputMaybe<MetadataProductWhereInput>;
};


export type QueryMetadataProductListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductWhereInput>;
};


export type QueryMetadataProductsArgs = {
  where: MetadataProductsWhereUniqueInput;
};


export type QueryMetadataProductsCountArgs = {
  where?: InputMaybe<MetadataProductsWhereInput>;
};


export type QueryMetadataProductShortNameArgs = {
  where: MetadataProductShortNameWhereUniqueInput;
};


export type QueryMetadataProductShortNameCountArgs = {
  where?: InputMaybe<MetadataProductShortNameWhereInput>;
};


export type QueryMetadataProductShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductShortNameWhereInput>;
};


export type QueryMetadataProductsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProductsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProductsWhereInput>;
};


export type QueryMetadataProduitsArgs = {
  where: MetadataProduitsWhereUniqueInput;
};


export type QueryMetadataProduitsCountArgs = {
  where?: InputMaybe<MetadataProduitsWhereInput>;
};


export type QueryMetadataProduitsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProduitsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProduitsWhereInput>;
};


export type QueryMetadataProgramVersionArgs = {
  where: MetadataProgramVersionWhereUniqueInput;
};


export type QueryMetadataProgramVersionCountArgs = {
  where?: InputMaybe<MetadataProgramVersionWhereInput>;
};


export type QueryMetadataProgramVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataProgramVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataProgramVersionWhereInput>;
};


export type QueryMetadataPropertyReleaseIdArgs = {
  where: MetadataPropertyReleaseIdWhereUniqueInput;
};


export type QueryMetadataPropertyReleaseIdCountArgs = {
  where?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
};


export type QueryMetadataPropertyReleaseIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPropertyReleaseIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPropertyReleaseIdWhereInput>;
};


export type QueryMetadataPropertyReleaseStatusArgs = {
  where: MetadataPropertyReleaseStatusWhereUniqueInput;
};


export type QueryMetadataPropertyReleaseStatusCountArgs = {
  where?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
};


export type QueryMetadataPropertyReleaseStatusListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPropertyReleaseStatusOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPropertyReleaseStatusWhereInput>;
};


export type QueryMetadataPublisherArgs = {
  where: MetadataPublisherWhereUniqueInput;
};


export type QueryMetadataPublisherCountArgs = {
  where?: InputMaybe<MetadataPublisherWhereInput>;
};


export type QueryMetadataPublisherListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublisherOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublisherWhereInput>;
};


export type QueryMetadataPublishingIssueArgs = {
  where: MetadataPublishingIssueWhereUniqueInput;
};


export type QueryMetadataPublishingIssueCountArgs = {
  where?: InputMaybe<MetadataPublishingIssueWhereInput>;
};


export type QueryMetadataPublishingIssueListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublishingIssueOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublishingIssueWhereInput>;
};


export type QueryMetadataPublishingSubjectArgs = {
  where: MetadataPublishingSubjectWhereUniqueInput;
};


export type QueryMetadataPublishingSubjectCountArgs = {
  where?: InputMaybe<MetadataPublishingSubjectWhereInput>;
};


export type QueryMetadataPublishingSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataPublishingSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataPublishingSubjectWhereInput>;
};


export type QueryMetadataQualifiedUsePrismaByArgs = {
  where: MetadataQualifiedUsePrismaByWhereUniqueInput;
};


export type QueryMetadataQualifiedUsePrismaByCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
};


export type QueryMetadataQualifiedUsePrismaByListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaByWhereInput>;
};


export type QueryMetadataQualifiedUsePrismaDateArgs = {
  where: MetadataQualifiedUsePrismaDateWhereUniqueInput;
};


export type QueryMetadataQualifiedUsePrismaDateCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
};


export type QueryMetadataQualifiedUsePrismaDateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaDateWhereInput>;
};


export type QueryMetadataQualifiedUsePrismaDurationArgs = {
  where: MetadataQualifiedUsePrismaDurationWhereUniqueInput;
};


export type QueryMetadataQualifiedUsePrismaDurationCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
};


export type QueryMetadataQualifiedUsePrismaDurationListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaDurationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaDurationWhereInput>;
};


export type QueryMetadataQualifiedUsePrismaSupportArgs = {
  where: MetadataQualifiedUsePrismaSupportWhereUniqueInput;
};


export type QueryMetadataQualifiedUsePrismaSupportCountArgs = {
  where?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
};


export type QueryMetadataQualifiedUsePrismaSupportListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataQualifiedUsePrismaSupportOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataQualifiedUsePrismaSupportWhereInput>;
};


export type QueryMetadataRatingArgs = {
  where: MetadataRatingWhereUniqueInput;
};


export type QueryMetadataRatingCountArgs = {
  where?: InputMaybe<MetadataRatingWhereInput>;
};


export type QueryMetadataRatingListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRatingOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRatingWhereInput>;
};


export type QueryMetadataReferenceDateArgs = {
  where: MetadataReferenceDateWhereUniqueInput;
};


export type QueryMetadataReferenceDateCountArgs = {
  where?: InputMaybe<MetadataReferenceDateWhereInput>;
};


export type QueryMetadataReferenceDateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceDateWhereInput>;
};


export type QueryMetadataReferenceNumberArgs = {
  where: MetadataReferenceNumberWhereUniqueInput;
};


export type QueryMetadataReferenceNumberCountArgs = {
  where?: InputMaybe<MetadataReferenceNumberWhereInput>;
};


export type QueryMetadataReferenceNumberListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceNumberWhereInput>;
};


export type QueryMetadataReferenceServiceArgs = {
  where: MetadataReferenceServiceWhereUniqueInput;
};


export type QueryMetadataReferenceServiceCountArgs = {
  where?: InputMaybe<MetadataReferenceServiceWhereInput>;
};


export type QueryMetadataReferenceServiceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReferenceServiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReferenceServiceWhereInput>;
};


export type QueryMetadataReleaseDateArgs = {
  where: MetadataReleaseDateWhereUniqueInput;
};


export type QueryMetadataReleaseDateCountArgs = {
  where?: InputMaybe<MetadataReleaseDateWhereInput>;
};


export type QueryMetadataReleaseDateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReleaseDateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReleaseDateWhereInput>;
};


export type QueryMetadataReleaseTimeArgs = {
  where: MetadataReleaseTimeWhereUniqueInput;
};


export type QueryMetadataReleaseTimeCountArgs = {
  where?: InputMaybe<MetadataReleaseTimeWhereInput>;
};


export type QueryMetadataReleaseTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataReleaseTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataReleaseTimeWhereInput>;
};


export type QueryMetadataRequiredPermissionArgs = {
  where: MetadataRequiredPermissionWhereUniqueInput;
};


export type QueryMetadataRequiredPermissionCountArgs = {
  where?: InputMaybe<MetadataRequiredPermissionWhereInput>;
};


export type QueryMetadataRequiredPermissionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRequiredPermissionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRequiredPermissionWhereInput>;
};


export type QueryMetadataResolutionKindArgs = {
  where: MetadataResolutionKindWhereUniqueInput;
};


export type QueryMetadataResolutionKindCountArgs = {
  where?: InputMaybe<MetadataResolutionKindWhereInput>;
};


export type QueryMetadataResolutionKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataResolutionKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataResolutionKindWhereInput>;
};


export type QueryMetadataRightsArgs = {
  where: MetadataRightsWhereUniqueInput;
};


export type QueryMetadataRightsCountArgs = {
  where?: InputMaybe<MetadataRightsWhereInput>;
};


export type QueryMetadataRightsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRightsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRightsWhereInput>;
};


export type QueryMetadataRoyaltyfreeArgs = {
  where: MetadataRoyaltyfreeWhereUniqueInput;
};


export type QueryMetadataRoyaltyfreeCountArgs = {
  where?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
};


export type QueryMetadataRoyaltyfreeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataRoyaltyfreeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataRoyaltyfreeWhereInput>;
};


export type QueryMetadatasArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWhereInput>;
};


export type QueryMetadataSceneArgs = {
  where: MetadataSceneWhereUniqueInput;
};


export type QueryMetadataSceneCountArgs = {
  where?: InputMaybe<MetadataSceneWhereInput>;
};


export type QueryMetadataSceneListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSceneOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSceneWhereInput>;
};


export type QueryMetadataSectionArgs = {
  where: MetadataSectionWhereUniqueInput;
};


export type QueryMetadataSectionCountArgs = {
  where?: InputMaybe<MetadataSectionWhereInput>;
};


export type QueryMetadataSectionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSectionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSectionWhereInput>;
};


export type QueryMetadataSentToArgs = {
  where: MetadataSentToWhereUniqueInput;
};


export type QueryMetadataSentToCountArgs = {
  where?: InputMaybe<MetadataSentToWhereInput>;
};


export type QueryMetadataSentToListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSentToOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSentToWhereInput>;
};


export type QueryMetadataSerialNumberArgs = {
  where: MetadataSerialNumberWhereUniqueInput;
};


export type QueryMetadataSerialNumberCountArgs = {
  where?: InputMaybe<MetadataSerialNumberWhereInput>;
};


export type QueryMetadataSerialNumberListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSerialNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSerialNumberWhereInput>;
};


export type QueryMetadataSeriesDateTimeArgs = {
  where: MetadataSeriesDateTimeWhereUniqueInput;
};


export type QueryMetadataSeriesDateTimeCountArgs = {
  where?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
};


export type QueryMetadataSeriesDateTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesDateTimeWhereInput>;
};


export type QueryMetadataSeriesDescriptionArgs = {
  where: MetadataSeriesDescriptionWhereUniqueInput;
};


export type QueryMetadataSeriesDescriptionCountArgs = {
  where?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
};


export type QueryMetadataSeriesDescriptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesDescriptionWhereInput>;
};


export type QueryMetadataSeriesModalityArgs = {
  where: MetadataSeriesModalityWhereUniqueInput;
};


export type QueryMetadataSeriesModalityCountArgs = {
  where?: InputMaybe<MetadataSeriesModalityWhereInput>;
};


export type QueryMetadataSeriesModalityListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesModalityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesModalityWhereInput>;
};


export type QueryMetadataSeriesNumberArgs = {
  where: MetadataSeriesNumberWhereUniqueInput;
};


export type QueryMetadataSeriesNumberCountArgs = {
  where?: InputMaybe<MetadataSeriesNumberWhereInput>;
};


export type QueryMetadataSeriesNumberListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSeriesNumberOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSeriesNumberWhereInput>;
};


export type QueryMetadataShortUniqueIdArgs = {
  where: MetadataShortUniqueIdWhereUniqueInput;
};


export type QueryMetadataShortUniqueIdCountArgs = {
  where?: InputMaybe<MetadataShortUniqueIdWhereInput>;
};


export type QueryMetadataShortUniqueIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataShortUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataShortUniqueIdWhereInput>;
};


export type QueryMetadataSourceArgs = {
  where: MetadataSourceWhereUniqueInput;
};


export type QueryMetadataSourceCountArgs = {
  where?: InputMaybe<MetadataSourceWhereInput>;
};


export type QueryMetadataSourceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSourceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSourceWhereInput>;
};


export type QueryMetadataStateArgs = {
  where: MetadataStateWhereUniqueInput;
};


export type QueryMetadataStateCountArgs = {
  where?: InputMaybe<MetadataStateWhereInput>;
};


export type QueryMetadataStateListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStateOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStateWhereInput>;
};


export type QueryMetadataStudyDateTimeArgs = {
  where: MetadataStudyDateTimeWhereUniqueInput;
};


export type QueryMetadataStudyDateTimeCountArgs = {
  where?: InputMaybe<MetadataStudyDateTimeWhereInput>;
};


export type QueryMetadataStudyDateTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyDateTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyDateTimeWhereInput>;
};


export type QueryMetadataStudyDescriptionArgs = {
  where: MetadataStudyDescriptionWhereUniqueInput;
};


export type QueryMetadataStudyDescriptionCountArgs = {
  where?: InputMaybe<MetadataStudyDescriptionWhereInput>;
};


export type QueryMetadataStudyDescriptionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyDescriptionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyDescriptionWhereInput>;
};


export type QueryMetadataStudyIdArgs = {
  where: MetadataStudyIdWhereUniqueInput;
};


export type QueryMetadataStudyIdCountArgs = {
  where?: InputMaybe<MetadataStudyIdWhereInput>;
};


export type QueryMetadataStudyIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyIdWhereInput>;
};


export type QueryMetadataStudyPhysicianArgs = {
  where: MetadataStudyPhysicianWhereUniqueInput;
};


export type QueryMetadataStudyPhysicianCountArgs = {
  where?: InputMaybe<MetadataStudyPhysicianWhereInput>;
};


export type QueryMetadataStudyPhysicianListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataStudyPhysicianOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataStudyPhysicianWhereInput>;
};


export type QueryMetadataSubjectArgs = {
  where: MetadataSubjectWhereUniqueInput;
};


export type QueryMetadataSubjectCodeArgs = {
  where: MetadataSubjectCodeWhereUniqueInput;
};


export type QueryMetadataSubjectCodeCountArgs = {
  where?: InputMaybe<MetadataSubjectCodeWhereInput>;
};


export type QueryMetadataSubjectCodeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectCodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectCodeWhereInput>;
};


export type QueryMetadataSubjectCountArgs = {
  where?: InputMaybe<MetadataSubjectWhereInput>;
};


export type QueryMetadataSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectWhereInput>;
};


export type QueryMetadataSubjectPrismaArgs = {
  where: MetadataSubjectPrismaWhereUniqueInput;
};


export type QueryMetadataSubjectPrismaCountArgs = {
  where?: InputMaybe<MetadataSubjectPrismaWhereInput>;
};


export type QueryMetadataSubjectPrismaListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSubjectPrismaOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSubjectPrismaWhereInput>;
};


export type QueryMetadataSupplementalCategoriesArgs = {
  where: MetadataSupplementalCategoriesWhereUniqueInput;
};


export type QueryMetadataSupplementalCategoriesCountArgs = {
  where?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
};


export type QueryMetadataSupplementalCategoriesListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataSupplementalCategoriesOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataSupplementalCategoriesWhereInput>;
};


export type QueryMetadataTargetVersionArgs = {
  where: MetadataTargetVersionWhereUniqueInput;
};


export type QueryMetadataTargetVersionCountArgs = {
  where?: InputMaybe<MetadataTargetVersionWhereInput>;
};


export type QueryMetadataTargetVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTargetVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTargetVersionWhereInput>;
};


export type QueryMetadataTitleArgs = {
  where: MetadataTitleWhereUniqueInput;
};


export type QueryMetadataTitleCountArgs = {
  where?: InputMaybe<MetadataTitleWhereInput>;
};


export type QueryMetadataTitleListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTitleOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTitleWhereInput>;
};


export type QueryMetadataToneCurveArgs = {
  where: MetadataToneCurveWhereUniqueInput;
};


export type QueryMetadataToneCurveCountArgs = {
  where?: InputMaybe<MetadataToneCurveWhereInput>;
};


export type QueryMetadataToneCurveListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataToneCurveOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataToneCurveWhereInput>;
};


export type QueryMetadataTransferredByArgs = {
  where: MetadataTransferredByWhereUniqueInput;
};


export type QueryMetadataTransferredByCountArgs = {
  where?: InputMaybe<MetadataTransferredByWhereInput>;
};


export type QueryMetadataTransferredByEmailArgs = {
  where: MetadataTransferredByEmailWhereUniqueInput;
};


export type QueryMetadataTransferredByEmailCountArgs = {
  where?: InputMaybe<MetadataTransferredByEmailWhereInput>;
};


export type QueryMetadataTransferredByEmailListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByEmailOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByEmailWhereInput>;
};


export type QueryMetadataTransferredByFullNameArgs = {
  where: MetadataTransferredByFullNameWhereUniqueInput;
};


export type QueryMetadataTransferredByFullNameCountArgs = {
  where?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
};


export type QueryMetadataTransferredByFullNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByFullNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByFullNameWhereInput>;
};


export type QueryMetadataTransferredByListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransferredByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransferredByWhereInput>;
};


export type QueryMetadataTransmissionReferenceArgs = {
  where: MetadataTransmissionReferenceWhereUniqueInput;
};


export type QueryMetadataTransmissionReferenceCountArgs = {
  where?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
};


export type QueryMetadataTransmissionReferenceListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTransmissionReferenceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTransmissionReferenceWhereInput>;
};


export type QueryMetadataTypeArgs = {
  where: MetadataTypeWhereUniqueInput;
};


export type QueryMetadataTypeCountArgs = {
  where?: InputMaybe<MetadataTypeWhereInput>;
};


export type QueryMetadataTypeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataTypeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataTypeWhereInput>;
};


export type QueryMetadataUndersubjectArgs = {
  where: MetadataUndersubjectWhereUniqueInput;
};


export type QueryMetadataUndersubjectCountArgs = {
  where?: InputMaybe<MetadataUndersubjectWhereInput>;
};


export type QueryMetadataUndersubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUndersubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUndersubjectWhereInput>;
};


export type QueryMetadataUnderUnderSubjectArgs = {
  where: MetadataUnderUnderSubjectWhereUniqueInput;
};


export type QueryMetadataUnderUnderSubjectCountArgs = {
  where?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
};


export type QueryMetadataUnderUnderSubjectListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnderUnderSubjectOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnderUnderSubjectWhereInput>;
};


export type QueryMetadataUniqueIdArgs = {
  where: MetadataUniqueIdWhereUniqueInput;
};


export type QueryMetadataUniqueIdCountArgs = {
  where?: InputMaybe<MetadataUniqueIdWhereInput>;
};


export type QueryMetadataUniqueIdListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUniqueIdOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUniqueIdWhereInput>;
};


export type QueryMetadataUnitArgs = {
  where: MetadataUnitWhereUniqueInput;
};


export type QueryMetadataUnitCountArgs = {
  where?: InputMaybe<MetadataUnitWhereInput>;
};


export type QueryMetadataUnitListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnitOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnitWhereInput>;
};


export type QueryMetadataUnitShortNameArgs = {
  where: MetadataUnitShortNameWhereUniqueInput;
};


export type QueryMetadataUnitShortNameCountArgs = {
  where?: InputMaybe<MetadataUnitShortNameWhereInput>;
};


export type QueryMetadataUnitShortNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUnitShortNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUnitShortNameWhereInput>;
};


export type QueryMetadataUploadedByArgs = {
  where: MetadataUploadedByWhereUniqueInput;
};


export type QueryMetadataUploadedByCountArgs = {
  where?: InputMaybe<MetadataUploadedByWhereInput>;
};


export type QueryMetadataUploadedByFullNameArgs = {
  where: MetadataUploadedByFullNameWhereUniqueInput;
};


export type QueryMetadataUploadedByFullNameCountArgs = {
  where?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
};


export type QueryMetadataUploadedByFullNameListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadedByFullNameOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadedByFullNameWhereInput>;
};


export type QueryMetadataUploadedByListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadedByOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadedByWhereInput>;
};


export type QueryMetadataUploadTimeArgs = {
  where: MetadataUploadTimeWhereUniqueInput;
};


export type QueryMetadataUploadTimeCountArgs = {
  where?: InputMaybe<MetadataUploadTimeWhereInput>;
};


export type QueryMetadataUploadTimeListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUploadTimeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUploadTimeWhereInput>;
};


export type QueryMetadataUrgencyArgs = {
  where: MetadataUrgencyWhereUniqueInput;
};


export type QueryMetadataUrgencyCountArgs = {
  where?: InputMaybe<MetadataUrgencyWhereInput>;
};


export type QueryMetadataUrgencyListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUrgencyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUrgencyWhereInput>;
};


export type QueryMetadataUsageTermsArgs = {
  where: MetadataUsageTermsWhereUniqueInput;
};


export type QueryMetadataUsageTermsCountArgs = {
  where?: InputMaybe<MetadataUsageTermsWhereInput>;
};


export type QueryMetadataUsageTermsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUsageTermsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUsageTermsWhereInput>;
};


export type QueryMetadataUserCommentArgs = {
  where: MetadataUserCommentWhereUniqueInput;
};


export type QueryMetadataUserCommentCountArgs = {
  where?: InputMaybe<MetadataUserCommentWhereInput>;
};


export type QueryMetadataUserCommentListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserCommentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserCommentWhereInput>;
};


export type QueryMetadataUserDefined195Args = {
  where: MetadataUserDefined195WhereUniqueInput;
};


export type QueryMetadataUserDefined195CountArgs = {
  where?: InputMaybe<MetadataUserDefined195WhereInput>;
};


export type QueryMetadataUserDefined195ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined195OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined195WhereInput>;
};


export type QueryMetadataUserDefined237Args = {
  where: MetadataUserDefined237WhereUniqueInput;
};


export type QueryMetadataUserDefined237CountArgs = {
  where?: InputMaybe<MetadataUserDefined237WhereInput>;
};


export type QueryMetadataUserDefined237ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined237OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined237WhereInput>;
};


export type QueryMetadataUserDefined238Args = {
  where: MetadataUserDefined238WhereUniqueInput;
};


export type QueryMetadataUserDefined238CountArgs = {
  where?: InputMaybe<MetadataUserDefined238WhereInput>;
};


export type QueryMetadataUserDefined238ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined238OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined238WhereInput>;
};


export type QueryMetadataUserDefined239Args = {
  where: MetadataUserDefined239WhereUniqueInput;
};


export type QueryMetadataUserDefined239CountArgs = {
  where?: InputMaybe<MetadataUserDefined239WhereInput>;
};


export type QueryMetadataUserDefined239ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined239OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined239WhereInput>;
};


export type QueryMetadataUserDefined242Args = {
  where: MetadataUserDefined242WhereUniqueInput;
};


export type QueryMetadataUserDefined242CountArgs = {
  where?: InputMaybe<MetadataUserDefined242WhereInput>;
};


export type QueryMetadataUserDefined242ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined242OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined242WhereInput>;
};


export type QueryMetadataUserDefined62Args = {
  where: MetadataUserDefined62WhereUniqueInput;
};


export type QueryMetadataUserDefined62CountArgs = {
  where?: InputMaybe<MetadataUserDefined62WhereInput>;
};


export type QueryMetadataUserDefined62ListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUserDefined62OrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataUserDefined62WhereInput>;
};


export type QueryMetadataValidArgs = {
  where: MetadataValidWhereUniqueInput;
};


export type QueryMetadataValidCountArgs = {
  where?: InputMaybe<MetadataValidWhereInput>;
};


export type QueryMetadataValidListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataValidOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataValidWhereInput>;
};


export type QueryMetadataVersionArgs = {
  where: MetadataVersionWhereUniqueInput;
};


export type QueryMetadataVersionCountArgs = {
  where?: InputMaybe<MetadataVersionWhereInput>;
};


export type QueryMetadataVersionListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataVersionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataVersionWhereInput>;
};


export type QueryMetadataWebStatementArgs = {
  where: MetadataWebStatementWhereUniqueInput;
};


export type QueryMetadataWebStatementCountArgs = {
  where?: InputMaybe<MetadataWebStatementWhereInput>;
};


export type QueryMetadataWebStatementListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataWebStatementOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWebStatementWhereInput>;
};


export type QueryMetadataWorkflowKindArgs = {
  where: MetadataWorkflowKindWhereUniqueInput;
};


export type QueryMetadataWorkflowKindCountArgs = {
  where?: InputMaybe<MetadataWorkflowKindWhereInput>;
};


export type QueryMetadataWorkflowKindListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataWorkflowKindOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataWorkflowKindWhereInput>;
};


export type QueryMetadataXmpFileStampsArgs = {
  where: MetadataXmpFileStampsWhereUniqueInput;
};


export type QueryMetadataXmpFileStampsCountArgs = {
  where?: InputMaybe<MetadataXmpFileStampsWhereInput>;
};


export type QueryMetadataXmpFileStampsListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataXmpFileStampsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataXmpFileStampsWhereInput>;
};


export type QueryMetadataXmpHistoryArgs = {
  where: MetadataXmpHistoryWhereUniqueInput;
};


export type QueryMetadataXmpHistoryCountArgs = {
  where?: InputMaybe<MetadataXmpHistoryWhereInput>;
};


export type QueryMetadataXmpHistoryListArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataXmpHistoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<MetadataXmpHistoryWhereInput>;
};


export type QueryPhotoArgs = {
  where: PhotoWhereUniqueInput;
};


export type QueryPhotoCountArgs = {
  where?: InputMaybe<PhotoWhereInput>;
};


export type QueryPhotoMetadataArgs = {
  where: PhotoMetadataWhereUniqueInput;
};


export type QueryPhotoMetadataCountArgs = {
  where?: InputMaybe<PhotoMetadataWhereInput>;
};


export type QueryPhotoMetadatasArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoMetadataOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoMetadataWhereInput>;
};


export type QueryPhotoPublicationArgs = {
  where: PhotoPublicationWhereUniqueInput;
};


export type QueryPhotoPublicationCountArgs = {
  where?: InputMaybe<PhotoPublicationWhereInput>;
};


export type QueryPhotoPublicationsArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoPublicationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoPublicationWhereInput>;
};


export type QueryPhotosArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoWhereInput>;
};


export type QuerySearchPhotosArgs = {
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<PhotoWhereInput>;
};


export type QuerySearchPhotosCountArgs = {
  where?: InputMaybe<PhotoWhereInput>;
};

export type ArchiveFragment = {
  __typename?: 'Archive',
  id: string,
  archiveId?: number | null,
  type?: ArchiveType | null,
  title?: string | null,
  key?: ArchiveKeyType | null,
  accesses?: Array<string | null> | null,
  hasCategory?: boolean | null
};

export type CategoryFragment_Category_parent_Category = {
  __typename?: 'Category',
  id: string
};

export type CategoryFragment = {
  __typename?: 'Category',
  id: string,
  label?: string | null,
  parent?: CategoryFragment_Category_parent_Category | null
};

export type PhotoFragment_Photo_archive_Archive = {
  __typename?: 'Archive',
  id: string,
  accesses?: Array<string | null> | null
};

export type PhotoFragment_Photo_previews_Preview = {
  __typename?: 'Preview',
  href?: string | null,
  size?: number | null
};

export type PhotoFragment = {
  __typename?: 'Photo',
  id: string,
  createdAt: string,
  updatedAt: string,
  height?: number | null,
  width?: number | null,
  filesize?: number | null,
  originalName?: string | null,
  contentType?: string | null,
  publicUrl?: string | null,
  downloadUrl?: string | null,
  metadataByName?: { [key: string]: any } | null,
  archive: PhotoFragment_Photo_archive_Archive,
  previews?: Array<PhotoFragment_Photo_previews_Preview> | null
};

export type CreateCategoryMutation_createCategory_Category = {
  __typename?: 'Category',
  id: string,
  label?: string | null,
  parent?: CategoryFragment_Category_parent_Category | null
};

export type CreateCategoryMutation_Mutation = {
  __typename?: 'Mutation',
  createCategory: CreateCategoryMutation_createCategory_Category
};


export type CreateCategoryMutationVariables = Exact<{
  data: CategoryCreateInput;
}>;


export type CreateCategoryMutation = CreateCategoryMutation_Mutation;

export type CreatePhotoMutation_createPhoto_Photo = {
  __typename?: 'Photo',
  uploadUrl?: string | null,
  id: string,
  createdAt: string,
  updatedAt: string,
  height?: number | null,
  width?: number | null,
  filesize?: number | null,
  originalName?: string | null,
  contentType?: string | null,
  publicUrl?: string | null,
  downloadUrl?: string | null,
  metadataByName?: { [key: string]: any } | null,
  archive: PhotoFragment_Photo_archive_Archive,
  previews?: Array<PhotoFragment_Photo_previews_Preview> | null
};

export type CreatePhotoMutation_Mutation = {
  __typename?: 'Mutation',
  createPhoto: CreatePhotoMutation_createPhoto_Photo
};


export type CreatePhotoMutationVariables = Exact<{
  data: PhotoCreateInput;
}>;


export type CreatePhotoMutation = CreatePhotoMutation_Mutation;

export type UpdatePhotoMutation_updatePhoto_Photo = {
  __typename?: 'Photo',
  id: string,
  createdAt: string,
  updatedAt: string,
  height?: number | null,
  width?: number | null,
  filesize?: number | null,
  originalName?: string | null,
  contentType?: string | null,
  publicUrl?: string | null,
  downloadUrl?: string | null,
  metadataByName?: { [key: string]: any } | null,
  archive: PhotoFragment_Photo_archive_Archive,
  previews?: Array<PhotoFragment_Photo_previews_Preview> | null
};

export type UpdatePhotoMutation_Mutation = {
  __typename?: 'Mutation',
  updatePhoto?: UpdatePhotoMutation_updatePhoto_Photo | null
};


export type UpdatePhotoMutationVariables = Exact<{
  where: PhotoWhereUniqueInput;
  data: PhotoUpdateInput;
}>;


export type UpdatePhotoMutation = UpdatePhotoMutation_Mutation;

export type DeletePhotoMutation_deletePhoto_Photo = {
  __typename?: 'Photo',
  id: string
};

export type DeletePhotoMutation_Mutation = {
  __typename?: 'Mutation',
  deletePhoto?: DeletePhotoMutation_deletePhoto_Photo | null
};


export type DeletePhotoMutationVariables = Exact<{
  where: PhotoWhereUniqueInput;
}>;


export type DeletePhotoMutation = DeletePhotoMutation_Mutation;

export type UpdatePhotoFileMutation_updatePhotoFile_Photo = {
  __typename?: 'Photo',
  id: string,
  createdAt: string,
  updatedAt: string,
  height?: number | null,
  width?: number | null,
  filesize?: number | null,
  originalName?: string | null,
  contentType?: string | null,
  publicUrl?: string | null,
  downloadUrl?: string | null,
  metadataByName?: { [key: string]: any } | null,
  archive: PhotoFragment_Photo_archive_Archive,
  previews?: Array<PhotoFragment_Photo_previews_Preview> | null
};

export type UpdatePhotoFileMutation_Mutation = {
  __typename?: 'Mutation',
  updatePhotoFile?: UpdatePhotoFileMutation_updatePhotoFile_Photo | null
};


export type UpdatePhotoFileMutationVariables = Exact<{
  photoId: Scalars['String']['input'];
}>;


export type UpdatePhotoFileMutation = UpdatePhotoFileMutation_Mutation;

export type ClonePhotoMutation_clonePhoto_Photo = {
  __typename?: 'Photo',
  id: string,
  createdAt: string,
  updatedAt: string,
  height?: number | null,
  width?: number | null,
  filesize?: number | null,
  originalName?: string | null,
  contentType?: string | null,
  publicUrl?: string | null,
  downloadUrl?: string | null,
  metadataByName?: { [key: string]: any } | null,
  archive: PhotoFragment_Photo_archive_Archive,
  previews?: Array<PhotoFragment_Photo_previews_Preview> | null
};

export type ClonePhotoMutation_Mutation = {
  __typename?: 'Mutation',
  clonePhoto: ClonePhotoMutation_clonePhoto_Photo
};


export type ClonePhotoMutationVariables = Exact<{
  sourceId: Scalars['String']['input'];
  create: PhotoCreateInput;
}>;


export type ClonePhotoMutation = ClonePhotoMutation_Mutation;

export type GetArchivesQuery_archives_Archive = {
  __typename?: 'Archive',
  id: string,
  archiveId?: number | null,
  type?: ArchiveType | null,
  title?: string | null,
  key?: ArchiveKeyType | null,
  accesses?: Array<string | null> | null,
  hasCategory?: boolean | null
};

export type GetArchivesQuery_Query = {
  __typename?: 'Query',
  archives: Array<GetArchivesQuery_archives_Archive>
};


export type GetArchivesQueryVariables = Exact<{
  where?: InputMaybe<ArchiveWhereInput>;
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<ArchiveOrderByInput> | ArchiveOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetArchivesQuery = GetArchivesQuery_Query;

export type GetCategoriesQuery_categories_Category = {
  __typename?: 'Category',
  id: string,
  label?: string | null,
  parent?: CategoryFragment_Category_parent_Category | null
};

export type GetCategoriesQuery_Query = {
  __typename?: 'Query',
  categories: Array<GetCategoriesQuery_categories_Category>
};


export type GetCategoriesQueryVariables = Exact<{
  where?: InputMaybe<CategoryWhereInput>;
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<CategoryOrderByInput> | CategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCategoriesQuery = GetCategoriesQuery_Query;

export type GetUnderSubjectsQuery_metadataUndersubjectList_MetadataUndersubject = {
  __typename?: 'MetadataUndersubject',
  id: number,
  value?: string | null
};

export type GetUnderSubjectsQuery_Query = {
  __typename?: 'Query',
  metadataUndersubjectList: Array<GetUnderSubjectsQuery_metadataUndersubjectList_MetadataUndersubject>
};


export type GetUnderSubjectsQueryVariables = Exact<{
  where: MetadataUndersubjectWhereInput;
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<MetadataUndersubjectOrderByInput> | MetadataUndersubjectOrderByInput>;
}>;


export type GetUnderSubjectsQuery = GetUnderSubjectsQuery_Query;

export type SearchPhotosQuery_searchPhotos_Photo = {
  __typename?: 'Photo',
  id: string,
  createdAt: string,
  updatedAt: string,
  height?: number | null,
  width?: number | null,
  filesize?: number | null,
  originalName?: string | null,
  contentType?: string | null,
  publicUrl?: string | null,
  downloadUrl?: string | null,
  metadataByName?: { [key: string]: any } | null,
  archive: PhotoFragment_Photo_archive_Archive,
  previews?: Array<PhotoFragment_Photo_previews_Preview> | null
};

export type SearchPhotosQuery_Query = {
  __typename?: 'Query',
  searchPhotos: Array<SearchPhotosQuery_searchPhotos_Photo>
};


export type SearchPhotosQueryVariables = Exact<{
  where?: InputMaybe<PhotoWhereInput>;
  first: Scalars['Int']['input'];
  orderBy?: InputMaybe<Array<PhotoOrderByInput> | PhotoOrderByInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
}>;


export type SearchPhotosQuery = SearchPhotosQuery_Query;

export type GetPhotosCountQuery_Query = {
  __typename?: 'Query',
  photoCount: number
};


export type GetPhotosCountQueryVariables = Exact<{
  where?: InputMaybe<PhotoWhereInput>;
}>;


export type GetPhotosCountQuery = GetPhotosCountQuery_Query;

export const ArchiveFragmentDoc = gql`
    fragment Archive on Archive {
  id
  archiveId
  type
  title
  key
  accesses
  hasCategory
}
    `;
export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  id
  label
  parent {
    id
  }
}
    `;
export const PhotoFragmentDoc = gql`
    fragment Photo on Photo {
  id
  archive {
    id
    accesses
  }
  createdAt
  updatedAt
  height
  width
  filesize
  originalName
  contentType
  publicUrl
  downloadUrl
  previews {
    href
    size
  }
  metadataByName(names: [title, subject, Instructions, creator, Headline, Credit, Source, rights, description, CustomField14, ImageNotes, UploadedBy, IdPhotoPrisma, Product, Issue, Undersubject, DeliveryCompany, OriginalName, DeliveryKind, DeliverySubject, ResolutionKind, ProcessHistory, DeliveryPhotoshopCredit, BackupName, UserDefined239, PriceLevel, Urgency, EditStatus, UserDefined238, ProcessStatus, DateCreated])
}
    `;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($data: CategoryCreateInput!) {
  createCategory(data: $data) {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const CreatePhotoDocument = gql`
    mutation CreatePhoto($data: PhotoCreateInput!) {
  createPhoto(data: $data) {
    ...Photo
    uploadUrl
  }
}
    ${PhotoFragmentDoc}`;
export type CreatePhotoMutationFn = Apollo.MutationFunction<CreatePhotoMutation, CreatePhotoMutationVariables>;

/**
 * __useCreatePhotoMutation__
 *
 * To run a mutation, you first call `useCreatePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPhotoMutation, { data, loading, error }] = useCreatePhotoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePhotoMutation(baseOptions?: Apollo.MutationHookOptions<CreatePhotoMutation, CreatePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePhotoMutation, CreatePhotoMutationVariables>(CreatePhotoDocument, options);
      }
export type CreatePhotoMutationHookResult = ReturnType<typeof useCreatePhotoMutation>;
export type CreatePhotoMutationResult = Apollo.MutationResult<CreatePhotoMutation>;
export type CreatePhotoMutationOptions = Apollo.BaseMutationOptions<CreatePhotoMutation, CreatePhotoMutationVariables>;
export const UpdatePhotoDocument = gql`
    mutation UpdatePhoto($where: PhotoWhereUniqueInput!, $data: PhotoUpdateInput!) {
  updatePhoto(where: $where, data: $data) {
    ...Photo
  }
}
    ${PhotoFragmentDoc}`;
export type UpdatePhotoMutationFn = Apollo.MutationFunction<UpdatePhotoMutation, UpdatePhotoMutationVariables>;

/**
 * __useUpdatePhotoMutation__
 *
 * To run a mutation, you first call `useUpdatePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhotoMutation, { data, loading, error }] = useUpdatePhotoMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePhotoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhotoMutation, UpdatePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhotoMutation, UpdatePhotoMutationVariables>(UpdatePhotoDocument, options);
      }
export type UpdatePhotoMutationHookResult = ReturnType<typeof useUpdatePhotoMutation>;
export type UpdatePhotoMutationResult = Apollo.MutationResult<UpdatePhotoMutation>;
export type UpdatePhotoMutationOptions = Apollo.BaseMutationOptions<UpdatePhotoMutation, UpdatePhotoMutationVariables>;
export const DeletePhotoDocument = gql`
    mutation DeletePhoto($where: PhotoWhereUniqueInput!) {
  deletePhoto(where: $where) {
    id
  }
}
    `;
export type DeletePhotoMutationFn = Apollo.MutationFunction<DeletePhotoMutation, DeletePhotoMutationVariables>;

/**
 * __useDeletePhotoMutation__
 *
 * To run a mutation, you first call `useDeletePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePhotoMutation, { data, loading, error }] = useDeletePhotoMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeletePhotoMutation(baseOptions?: Apollo.MutationHookOptions<DeletePhotoMutation, DeletePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePhotoMutation, DeletePhotoMutationVariables>(DeletePhotoDocument, options);
      }
export type DeletePhotoMutationHookResult = ReturnType<typeof useDeletePhotoMutation>;
export type DeletePhotoMutationResult = Apollo.MutationResult<DeletePhotoMutation>;
export type DeletePhotoMutationOptions = Apollo.BaseMutationOptions<DeletePhotoMutation, DeletePhotoMutationVariables>;
export const UpdatePhotoFileDocument = gql`
    mutation UpdatePhotoFile($photoId: String!) {
  updatePhotoFile(photoId: $photoId) {
    ...Photo
  }
}
    ${PhotoFragmentDoc}`;
export type UpdatePhotoFileMutationFn = Apollo.MutationFunction<UpdatePhotoFileMutation, UpdatePhotoFileMutationVariables>;

/**
 * __useUpdatePhotoFileMutation__
 *
 * To run a mutation, you first call `useUpdatePhotoFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePhotoFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePhotoFileMutation, { data, loading, error }] = useUpdatePhotoFileMutation({
 *   variables: {
 *      photoId: // value for 'photoId'
 *   },
 * });
 */
export function useUpdatePhotoFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePhotoFileMutation, UpdatePhotoFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePhotoFileMutation, UpdatePhotoFileMutationVariables>(UpdatePhotoFileDocument, options);
      }
export type UpdatePhotoFileMutationHookResult = ReturnType<typeof useUpdatePhotoFileMutation>;
export type UpdatePhotoFileMutationResult = Apollo.MutationResult<UpdatePhotoFileMutation>;
export type UpdatePhotoFileMutationOptions = Apollo.BaseMutationOptions<UpdatePhotoFileMutation, UpdatePhotoFileMutationVariables>;
export const ClonePhotoDocument = gql`
    mutation ClonePhoto($sourceId: String!, $create: PhotoCreateInput!) {
  clonePhoto(sourceId: $sourceId, create: $create) {
    ...Photo
  }
}
    ${PhotoFragmentDoc}`;
export type ClonePhotoMutationFn = Apollo.MutationFunction<ClonePhotoMutation, ClonePhotoMutationVariables>;

/**
 * __useClonePhotoMutation__
 *
 * To run a mutation, you first call `useClonePhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClonePhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clonePhotoMutation, { data, loading, error }] = useClonePhotoMutation({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      create: // value for 'create'
 *   },
 * });
 */
export function useClonePhotoMutation(baseOptions?: Apollo.MutationHookOptions<ClonePhotoMutation, ClonePhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClonePhotoMutation, ClonePhotoMutationVariables>(ClonePhotoDocument, options);
      }
export type ClonePhotoMutationHookResult = ReturnType<typeof useClonePhotoMutation>;
export type ClonePhotoMutationResult = Apollo.MutationResult<ClonePhotoMutation>;
export type ClonePhotoMutationOptions = Apollo.BaseMutationOptions<ClonePhotoMutation, ClonePhotoMutationVariables>;
export const GetArchivesDocument = gql`
    query GetArchives($where: ArchiveWhereInput, $first: Int!, $orderBy: [ArchiveOrderByInput!], $skip: Int) {
  archives(where: $where, first: $first, orderBy: $orderBy, skip: $skip) {
    ...Archive
  }
}
    ${ArchiveFragmentDoc}`;

/**
 * __useGetArchivesQuery__
 *
 * To run a query within a React component, call `useGetArchivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetArchivesQuery(baseOptions: Apollo.QueryHookOptions<GetArchivesQuery, GetArchivesQueryVariables> & ({ variables: GetArchivesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArchivesQuery, GetArchivesQueryVariables>(GetArchivesDocument, options);
      }
export function useGetArchivesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArchivesQuery, GetArchivesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArchivesQuery, GetArchivesQueryVariables>(GetArchivesDocument, options);
        }
export function useGetArchivesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetArchivesQuery, GetArchivesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArchivesQuery, GetArchivesQueryVariables>(GetArchivesDocument, options);
        }
export type GetArchivesQueryHookResult = ReturnType<typeof useGetArchivesQuery>;
export type GetArchivesLazyQueryHookResult = ReturnType<typeof useGetArchivesLazyQuery>;
export type GetArchivesSuspenseQueryHookResult = ReturnType<typeof useGetArchivesSuspenseQuery>;
export type GetArchivesQueryResult = Apollo.QueryResult<GetArchivesQuery, GetArchivesQueryVariables>;
export const GetCategoriesDocument = gql`
    query GetCategories($where: CategoryWhereInput, $first: Int!, $orderBy: [CategoryOrderByInput!], $skip: Int) {
  categories(where: $where, first: $first, orderBy: $orderBy, skip: $skip) {
    ...Category
  }
}
    ${CategoryFragmentDoc}`;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables> & ({ variables: GetCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export function useGetCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesSuspenseQueryHookResult = ReturnType<typeof useGetCategoriesSuspenseQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetUnderSubjectsDocument = gql`
    query GetUnderSubjects($where: MetadataUndersubjectWhereInput!, $first: Int!, $orderBy: [MetadataUndersubjectOrderByInput!]) {
  metadataUndersubjectList(where: $where, first: $first, orderBy: $orderBy) {
    id
    value
  }
}
    `;

/**
 * __useGetUnderSubjectsQuery__
 *
 * To run a query within a React component, call `useGetUnderSubjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnderSubjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnderSubjectsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetUnderSubjectsQuery(baseOptions: Apollo.QueryHookOptions<GetUnderSubjectsQuery, GetUnderSubjectsQueryVariables> & ({ variables: GetUnderSubjectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnderSubjectsQuery, GetUnderSubjectsQueryVariables>(GetUnderSubjectsDocument, options);
      }
export function useGetUnderSubjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnderSubjectsQuery, GetUnderSubjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnderSubjectsQuery, GetUnderSubjectsQueryVariables>(GetUnderSubjectsDocument, options);
        }
export function useGetUnderSubjectsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUnderSubjectsQuery, GetUnderSubjectsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUnderSubjectsQuery, GetUnderSubjectsQueryVariables>(GetUnderSubjectsDocument, options);
        }
export type GetUnderSubjectsQueryHookResult = ReturnType<typeof useGetUnderSubjectsQuery>;
export type GetUnderSubjectsLazyQueryHookResult = ReturnType<typeof useGetUnderSubjectsLazyQuery>;
export type GetUnderSubjectsSuspenseQueryHookResult = ReturnType<typeof useGetUnderSubjectsSuspenseQuery>;
export type GetUnderSubjectsQueryResult = Apollo.QueryResult<GetUnderSubjectsQuery, GetUnderSubjectsQueryVariables>;
export const SearchPhotosDocument = gql`
    query SearchPhotos($where: PhotoWhereInput, $first: Int!, $orderBy: [PhotoOrderByInput!], $skip: Int) {
  searchPhotos(where: $where, first: $first, orderBy: $orderBy, skip: $skip) {
    ...Photo
  }
}
    ${PhotoFragmentDoc}`;

/**
 * __useSearchPhotosQuery__
 *
 * To run a query within a React component, call `useSearchPhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPhotosQuery({
 *   variables: {
 *      where: // value for 'where'
 *      first: // value for 'first'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useSearchPhotosQuery(baseOptions: Apollo.QueryHookOptions<SearchPhotosQuery, SearchPhotosQueryVariables> & ({ variables: SearchPhotosQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPhotosQuery, SearchPhotosQueryVariables>(SearchPhotosDocument, options);
      }
export function useSearchPhotosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPhotosQuery, SearchPhotosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPhotosQuery, SearchPhotosQueryVariables>(SearchPhotosDocument, options);
        }
export function useSearchPhotosSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchPhotosQuery, SearchPhotosQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchPhotosQuery, SearchPhotosQueryVariables>(SearchPhotosDocument, options);
        }
export type SearchPhotosQueryHookResult = ReturnType<typeof useSearchPhotosQuery>;
export type SearchPhotosLazyQueryHookResult = ReturnType<typeof useSearchPhotosLazyQuery>;
export type SearchPhotosSuspenseQueryHookResult = ReturnType<typeof useSearchPhotosSuspenseQuery>;
export type SearchPhotosQueryResult = Apollo.QueryResult<SearchPhotosQuery, SearchPhotosQueryVariables>;
export const GetPhotosCountDocument = gql`
    query GetPhotosCount($where: PhotoWhereInput) {
  photoCount: searchPhotosCount(where: $where)
}
    `;

/**
 * __useGetPhotosCountQuery__
 *
 * To run a query within a React component, call `useGetPhotosCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPhotosCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPhotosCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetPhotosCountQuery(baseOptions?: Apollo.QueryHookOptions<GetPhotosCountQuery, GetPhotosCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPhotosCountQuery, GetPhotosCountQueryVariables>(GetPhotosCountDocument, options);
      }
export function useGetPhotosCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPhotosCountQuery, GetPhotosCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPhotosCountQuery, GetPhotosCountQueryVariables>(GetPhotosCountDocument, options);
        }
export function useGetPhotosCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetPhotosCountQuery, GetPhotosCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetPhotosCountQuery, GetPhotosCountQueryVariables>(GetPhotosCountDocument, options);
        }
export type GetPhotosCountQueryHookResult = ReturnType<typeof useGetPhotosCountQuery>;
export type GetPhotosCountLazyQueryHookResult = ReturnType<typeof useGetPhotosCountLazyQuery>;
export type GetPhotosCountSuspenseQueryHookResult = ReturnType<typeof useGetPhotosCountSuspenseQuery>;
export type GetPhotosCountQueryResult = Apollo.QueryResult<GetPhotosCountQuery, GetPhotosCountQueryVariables>;