import { Image } from '@mui/icons-material';
import { Box, ButtonBase } from '@mui/material';
import { MetadataNameEnumType } from '__generated__/queries-photo';
import { useGetPhotosCount } from 'apollo/queries/photos.photo.graphql';
import { FC, MouseEventHandler } from 'react';
import { theme } from 'theme';
import { getColorByStatus, getLabelByStatus } from 'utils/mediaSearchStatus';
import { RowItem } from './Table';

export const PhotoStatus: FC<{
  rowData: RowItem;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}> = ({ rowData, onClick }) => {
  const { data } = useGetPhotosCount({
    where: {
      isUploaded: true,
      photoMetadatas_some: {
        value: rowData.assignment.subject.id,
        metadata: { name: MetadataNameEnumType.ID_subject },
      },
    },
  });

  const hasPhotos = !!data?.photoCount;

  return (
    <ButtonBase
      sx={{
        flex: 1,
        alignSelf: 'stretch',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        px: 2,
        fontSize: 14,
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          color: getColorByStatus(rowData.assignment.mediaSearchStatus),
          fontWeight: 'bold',
          textAlign: 'center',
          width: 80,
        }}
      >
        {hasPhotos
          ? getLabelByStatus(rowData.assignment.mediaSearchStatus)
          : ''}
      </Box>
      <Image
        sx={{
          color: hasPhotos
            ? getColorByStatus(rowData.assignment.mediaSearchStatus)
            : theme.palette.grey[400],
        }}
      />
    </ButtonBase>
  );
};
